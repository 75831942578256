import { MainResponse } from '../../../main-response.model';
import { BusinessPackageItem } from './business-package-item.model';


export class PackageObjectResponse extends MainResponse {
    constructor(obj = null){
        super();
        obj = obj || {};
        this.init(obj);
        this.object = new BusinessPackageItem(obj.object);
    }
}