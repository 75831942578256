import { Directive, ElementRef, EventEmitter, HostListener, Output, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[appScrollTracker]'
})
export class ScrollTrackerDirective {
  @Output() scrollingFinished = new EventEmitter<void>();
  
  constructor(
    private el: ElementRef,
  ) { }

  emitted = false;

  @HostListener('scroll', ['$event'])
  onScroll(): void {
    let scrollTop = this.el.nativeElement.scrollTop;
    let offsetHeight = this.el.nativeElement.offsetHeight;
    let scrollHeight = this.el.nativeElement.scrollHeight;

    if ((scrollTop + offsetHeight === scrollHeight) && !this.emitted) {
      this.emitted = true;
      this.scrollingFinished.emit();
    } else {
      this.emitted = false;
    }
  }
}
