<section class="card-body p-0">
    <div class="table-responsive bg-white">
        <table class="table mb-0">
            <thead>
                <tr class="bg-tb-header border-bottom text-left">
                    <th>{{ 'Idcard_stt' | translate }}</th>
                    <th>{{ 'Idcard_field' | translate }}</th>
                    <th style="min-width: 150px;">{{ 'Idcard_type' | translate }}</th>
                    <th>{{ 'Idcard_description' | translate }}</th>
                    <th>{{ 'Idcard_warning' | translate }}</th>
                    <th>{{ 'Idcard_success' | translate }}</th>
                    <th>{{ 'Idcard_status' | translate }}</th>
                </tr>
            </thead>
            <tbody *ngIf="ekycConfig">
                <tr *ngFor="let item of ekycConfig; let i = index" class="text-left border-bottom">
                    <td>{{ (page - 1)*maxSize + i + 1 }}</td>
                    <td>{{ item.nameField || "" }}</td>
                    <td>{{ item.typeField || "" }}</td>
                    <td>{{ item.description || "" }}</td>
                    <td>
                        <input appOnlyNumber class="form-control" type="text" placeholder="{{ 'UpdateConfigurationInformation_ph_1' | translate }}" [(ngModel)]="item.warning" [disabled]="!item.status" (input)="isEdited = true">
                    </td>
                    <td>
                        <input appOnlyNumber class="form-control" type="text" placeholder="{{ 'UpdateConfigurationInformation_ph_1' | translate }}" [(ngModel)]="item.success" [disabled]="!item.status" (input)="isEdited = true">
                    </td>
                    <td>
                        <ng-select 
                            [items]="status" bindValue="value" bindLabel="name"
                            [clearable]="false" [searchable]="false"
                            placeholder="{{ 'PartList_select_status' | translate }}"
                            (change)="changeSelected()"
                            [(ngModel)]="item.status"
                            [readonly]="isParamDisable(item.code)">
                            <ng-template ng-label-tmp let-item="item">
                                <span>{{ item.name | translate }}</span>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item">
                                <span>{{ item.name | translate }}</span>
                            </ng-template>
                            <!-- <ng-option *ngFor="let s of status" [value]="s.value" class="options">{{s.name}}</ng-option> -->
                        </ng-select>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</section>

