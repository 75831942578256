import { UsbTokenConfig } from "./usb-token-config";

export class UsbTokenItem {
    issuerCN: string = '';
    notAfter: string = '';
    notBefore: string = '';
    passport: string = '';
    personalId: string = '';
    serial: string = '';
    subjectCN: string = '';
    taxNumber: string = '';
    userId: string = '';
    signatureDefault: UsbTokenConfig;

    constructor(obj = null) {
        obj = obj || {};
        this.issuerCN = obj.issuerCN || '';
        this.notAfter = obj.notAfter || '';
        this.notBefore = obj.notBefore || '';
        this.passport = obj.passport || '';
        this.personalId = obj.personalId || '';
        this.serial = obj.serial || '';
        this.subjectCN = obj.subjectCN || '';
        this.taxNumber = obj.taxNumber || '';
        this.signatureDefault = new UsbTokenConfig(obj.signatureDefault || {});
    }
}