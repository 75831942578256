export class EinvoicesItem {
    uuid: string;
    soHoaDon: string;
    kyHieu: string;
    tenKhachHang: string;
    maSoThue: string;
    ngayPhatHanh: string;
    tongTien: string;
    trangThai: string;
    diaChi: string;
    email: string;
    fkey : string;
    taxCode : string;
    cusCode : string;
    constructor(obj = null) {
        this.uuid = obj.uuidInvoice || '';
        this.fkey = obj.fkey || '';
        this.taxCode = obj.companyTaxCode || '';
        this.soHoaDon = obj.invoiceNo || '';
        this.kyHieu = obj.pattern || '';
        this.tenKhachHang = obj.cusName || '';
        this.maSoThue = obj.cusTaxCode || '';
        this.ngayPhatHanh =  obj.createDate || '';
        this.tongTien = obj.amount || '';
        this.trangThai = obj.paymentStatus || '';
        this.diaChi = obj.cusAddress || '';
        this.email = obj.cusEmail || '';
        this.cusCode = obj.cusCode || '';
    }

    convertToDateObj(dataString) {
        let d = new Date(dataString);
        let localTiming = d.toLocaleDateString('en-GB');
        return localTiming;
      }

}
