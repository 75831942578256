import {ContractReportItem} from "./detail-info-item.model"
import { MainResponse } from '../../../../main-response.model';

export class ListContractReportResponse extends MainResponse {
    data : ContractReportItem[] 
    constructor(obj = null) {
        super();
        obj = obj || {};
        this.init(obj);
        this.data = [];
        if (obj.object && obj.object instanceof Array) {
            obj.object.forEach((item) => {
                this.data.push(new ContractReportItem(item));
            })
        }
    }

 
}