<div class="contact">
    <section class="contact-ss1">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="content-left">
                        <h3>{{ contactUs?.category?.categoryName }}</h3>
                        <span>
                            {{ contactUs?.category?.description }}
                        </span>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="form-dk">
                            <form>
                                <span class="title">
                                    {{ 'your_information' | translate }}
                                </span>
                                <div class="form-group">
                                    <select name="" class="form-control">
                                         <option value="">Tỉnh /Thành phố</option>
                                     </select>
                             </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="{{ 'full_name' | translate }}">
                                </div>
                                <div class="row">
                                        <div class="col-md-12 col-sm-12 col-lg-6">
                                        <div class="form-group">
                                            <input type="text" class="form-control"
                                                placeholder="{{ 'phone_number' | translate }}">
                                        </div>
                                    </div>
                                        <div class="col-md-12 col-sm-12 col-lg-6">
                                        <div class="form-group">
                                            <input type="text" class="form-control" placeholder="Email">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="form-group">
                                        <textarea type="text" class="form-control"
                                            placeholder="{{ 'message' | translate }}"></textarea>
                                    </div>
                                </div>
                                <div class="mt-5">
                                    <button type="submit" class="btn-dk btn-block">{{ 'send_information' | translate
                                        }}</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- <section class="contact-ss2">
        <div class="container">
            <div class="row">
                <h3 class="title-top block">
                    {{ address?.category?.categoryName }}
                </h3>
                <div class="item-list">
                    <div class="row">
                        <div class="col-md-4" *ngFor="let item of address?.blogs">
                            <div class="col-item">
                                <span class="title-item">
                                    {{ item?.title }}
                                </span>
                                <ul>
                                    <li>
                                        <img src="../../../../assets/img/home/v4/icon/Frame.png" alt="">
                                        <span>{{ item?.description }}
                                            <a href="" class="g-map block">{{ 'view_map' |translate }}</a>
                                        </span>
                                    </li>
                                    <li>
                                        <img src="../../../../assets/img/home/v4/icon/Frame (1).png" alt="">
                                        <span>{{ item?.subTitle }}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section> -->
</div>