import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Config } from 'src/app/config';
import { Constant } from '../../common/constant_videocall';
import { API } from '../api';

const GET_DEVICEID = '/get-one/';
const CREATE_CALL = '/create-call';
const ACCEPT_CALL = '/accept-call';
const REJECT_CALL = '/reject-call';
const END_CALL = '/end-call';

@Injectable({
  providedIn: 'root'
})
export class VideoCallService {

  constructor(
    private http: HttpClient,
    private api: API
  ) { }

  /**
   * Lay thong tin thiet bi
   * @param uuidCustomer 
   */
  getDeviceIdByCustomerId(uuidCustomer) {
    const url = Config.IDG_VIDEOCALL + '/get-one/' + Constant.TOKEN_ID + '/' + uuidCustomer
    return this.http.get(url);
  }

  /**
   * Tao cuoc goi
   * @param recevicerName 
   * @param deviceIds 
   * @param caller 
   */
  createCall(recevicerName, deviceIds, caller) {
    const param = {
      callerName: caller,
      deviceIdSrc: localStorage.getItem(Constant.UUID),
      deviceIds: deviceIds,
      idgTokenId: Constant.TOKEN_ID,
      recevicerName: recevicerName.ten,
    };
    const url = Config.IDG_VIDEOCALL + CREATE_CALL;

    return this.http.post(url, param);
  }

  acceptCall(data) {
    const deviceIds = data.deviceIds.split(';');
    const param = {
        deviceId: localStorage.getItem(Constant.UUID),
        deviceIds: deviceIds,
        idgTokenId: Constant.TOKEN_ID,
        roomId: data.roomId,
    };

    const url = Config.IDG_VIDEOCALL + CREATE_CALL;
    return this.http.post(url, param);
  }

  rejectCall(data) {
    const deviceIds = data.deviceIds.split(';');
    const param = {
        deviceId: localStorage.getItem(Constant.UUID),
        deviceIds: deviceIds,
        idgTokenId: Constant.TOKEN_ID,
        roomId: data.roomId,
    };

    const url = Config.IDG_VIDEOCALL + REJECT_CALL;
    return this.http.post(url, param);
  }

  endCall(data) {
    const url = Config.IDG_VIDEOCALL + END_CALL;
    return this.http.post(url, data);
  }
}
