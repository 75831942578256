<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-auto">
                <h1 class="m-0 text-dark"><label>Thông tin gói cước</label></h1>
            </div>
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>

<section class="content">
    <div class="container-fluid pb-3">
        <div class="card shadow-none">
            <div class="card-header" >
                <div class="d-flex align-items-center">
                    <label class="text-lg">{{ package.packageInfo.name}}</label>
                    <span *ngIf="this.statusPackage === 'APPROVE'" 
                    class="badge badge-success-new p-2  ml-5 ">Đang sử dụng</span>
                    <span *ngIf="this.statusPackage === 'WAIT'" class="badge badge-warning-new p-2 ml-5">Chờ kích hoạt</span>
                </div>
            </div>
            <div class="card-body">
                
                <div class="row">
                    <div class="col-md-6">
                        <label class="text-primary">Thông tin chung</label>
                        <hr class="mt-0 mb-2">
                        <div class="row mb-2">
                            <div class="col-md-5">
                                <label class="text-secondary">Tên gói cước</label>
                            </div>
                            <div class="col-md-7">
                                <span>{{ package.packageInfo.name }}</span>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-5">
                                <label class="text-secondary">Mô tả</label>
                            </div>
                            <div class="col-md-7">
                                <span></span>
                                <p>{{ package.packageInfo.description }}</p>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-5">
                                <label class="text-secondary">Số lượng hợp đồng</label>
                            </div>
                            <div class="col-md-7">
                                <span>{{ package.packageInfo.contractNumber }}</span>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-5">
                                <label class="text-secondary">Thời hạn sử dụng</label>
                            </div>
                            <div class="col-md-7">
                                <span>{{ package.packageInfo.duration }} tháng</span>
                            </div>
                        </div>
                        <div *ngIf="statusPackage === 'APPROVE'" class="row mb-2">
                            <div class="col-md-5">
                                <label class="text-secondary">Ngày bắt đầu</label>
                            </div>
                            <div class="col-md-7">
                                <span>{{ package.packageInfo.issueDate | date: 'dd/MM/yyyy' }}</span>
                            </div>
                        </div>
                        <div *ngIf="statusPackage === 'APPROVE'" class="row mb-2">
                            <div class="col-md-5">
                                <label class="text-secondary">Ngày hết hạn</label>
                            </div>
                            <div class="col-md-7">
                                <span>{{ package.packageInfo.expirationDate | date: 'dd/MM/yyyy' }}</span>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-5">
                                <label class="text-secondary">Giá gói cước</label>
                            </div>
                            <div class="col-md-7">
                                <span>{{ package.packageInfo.price }}VND / tháng</span>
                            </div>
                        </div>

                    </div>

                    <div class="col-md-6">
                        <label class="text-primary">Thông tin chức năng</label>
                        <hr class="mt-0 mb-2">
                        <ul *ngIf="package.packageInfo.userType === userTypeConst.ENTERPRISE; else noEnterprise" class="privilege-tree">
                            <li *ngFor="let privilegeType of service.privileges">
                                <div class="privilegeType">
                                    <span class="material-icons mr-2">expand_more</span><span>{{ privilegeType.name }}</span>
                                </div>
                                <ul>
                                    <!-- *ngIf="privilegeType.privileges.lenght > 0" -->
                                    <li *ngFor="let privilege of privilegeType.privileges">
                                        <span>{{ privilege.description }}</span>
                                    </li>
                                </ul>
                            </li>
                        </ul>

                        <ng-template #noEnterprise>
                            <ul class="privilege-tree">
                                <li *ngFor="let privilegeType of listPermissionGroup">
                                    <div class="privilegeType">
                                        <span class="material-icons mr-2">expand_more</span><span>{{ privilegeType.name }}</span>
                                    </div>
                                    <ul>
                                        <!-- *ngIf="privilegeType.privileges.lenght > 0" -->
                                        <li *ngFor="let privilege of privilegeType.privileges">
                                            <span>{{ privilege.description }}</span>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>