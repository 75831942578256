export const localize_contract_lifecycle = {
    clc_add_title: "Thêm mới luồng hợp đồng",
    clc_general_info: "Thông tin chung",
    clc_internal_employee_join: "Phân quyền nhân sự thẩm định nội bộ",
    clc_internal_employee_join_discuss: "Phân quyền nhân sự tham gia đàm phán",
    clc_internal_employee_join_sign: "Thông tin cấu hình luồng ký duyệt, ký nháy",
    clc_form_name_required: "Vui lòng nhập tên loại luồng hợp đồng!",
    clc_form_status_required: "Vui lòng chọn trạng thái!",
    clc_form_file_required: "Vui lòng thêm file hợp đồng mẫu!",
    clc_form_type_required: "Vui lòng chọn loại hợp đồng!",
    clc_form_discuss_required: "Vui lòng chọn đàm phán hợp đồng!",
    clc_tb_stt: "STT",
    clc_tb_party_join: "Bộ phận tham gia",
    clc_tb_employee_join: "Nhân sự tham gia",
    clc_tb_action: "Thao tác có thể thực hiện",
    clc_tb_sign_type: "Hình thức ký",
    clc_tb_sign_th: "Thứ tự ký",
    clc_tb_progress_time: "Thời gian xử lý",
    join_discuss: "Tham gia trao đổi",
    accept_deny_confirm: "Đồng ý/Từ chối thẩm định",
    add_remove_employee: "Thêm/xóa nhân sự",
    select_party_join: "Chọn bộ phận tham gia",
    select_employee_join: "Chọn nhân sự tham gia",
    input_sign_type: "Nhập hình thức ký",
    day: 'Ngày',
    sign_draft: "Ký nháy",
    sign_approve: "Ký duyệt",
    select_contract_template_file: "Chọn file hợp đồng mẫu",
    clc_file_invalid_type: "File hợp đồng mẫu không đúng định dạng!",
    add_flow_contract_success: "Thêm luồng hợp đồng thành công!",
    add_flow_contract_confirm: "Bạn chắc chắn muốn thêm mới luồng hợp đồng?",
    update_flow_contract_confirm: "Bạn chắc chắn muốn cập nhật thông tin luồng hợp đồng?",
    update_flow_contract_success: "Cập nhật thông tin luồng hợp đồng đồng thành công!",
    update_flow_contract_info: "Cập nhật thông tin luồng hợp đồng",
    contract_flow_name: "Tên loại luồng hợp đồng",
    active: "Đang hoạt động",
    deactive: "Ngưng hoạt động",
    status: "Trạng thái",
    contract_sample_v1: "File hợp đồng tham khảo",
    contract_type: "Hình thức hợp đồng",
    electronic_contract: "Hợp đồng điện tử",
    paper_contract: "Hợp đồng giấy",
    discuss_contract: "Đàm phán hợp đồng",
    contract_have_discuss: "Hợp đồng có đàm phán",
    contract_non_discuss: "Hợp đồng không đàm phán",
    description: "Mô tả",
    delete_contract_flow_confirm: "Bạn chắc chắn muốn xóa luồng hợp đồng này?",
    delele_contract_flow_success: "Xóa luồng hợp đồng thành công!",
    cannot_delete_contract_flow_active: "Bạn không thể xóa luồng hợp đồng đang được kích hoạt. Vui lòng thử lại!",
    please_select_contract_flow: "Vui lòng chọn luồng hợp đồng!",
    contract_creation_type: "Hình thức tạo hợp đồng",
    flow_contract: "Luồng hợp đồng",
    flow_contract_info: "Thông tin luồng hợp đồng",
    confirm_update_flow_contract_config: "Bạn chắc chắn muốn cập nhật cấu hình luồng hợp đồng?",
    update_flow_contract_config_success: "Cập nhật cấu hình luồng hợp đồng thành công!",
    confirm_update_partner_discuss_config: "Bạn chắc chắn muốn cập nhật thông tin nhân sự tham gia đàm phán?",
    update_partner_discuss_config_success: "Cập nhật nhân sự tham gia đàm phán thành công!",
    confirm_update_internal_discuss_config: "Bạn chắc chắn muốn cập nhật thông tin nhân sự tham gia thẩm định?",
    update_internal_discuss_config_success: "Cập nhật nhân sự tham gia nhân sự tham gia thẩm định thành công!",
    sign_stamp: "Ký dấu",
    signature_type: "Loại chữ ký",
    required_flow_and_stamp: "Bắt buộc theo luồng ký và phải đóng dấu",
    required_flow_and_stamp_desc: "Các nhân sự phải hoàn thành ký nháy theo luồng thì mới có thể ký duyệt lên hợp đồng và văn thư phải đóng dấu cuối cùng.",
    required_flow: "Bắt buộc theo luồng ký",
    required_flow_desc: "Các nhân sự phải hoàn thành ký nháy theo luồng thì mới có thể ký duyệt lên hợp đồng và văn thư không cần đóng dấu cuối cùng.",
    no_required_flow: "Không bắt buộc theo luồng ký",
    no_required_flow_desc: "Có thể kết thúc luồng ký nháy và thực hiện ký duyệt hợp đồng bất cứ lúc nào, văn thư không cần đóng dấu cuối cùng.",
    draft_approval_sign_type: "Hình thức ký duyệt, ký nháy",
    placeholder_signform: "Chọn loại chữ ký",
    confirm_allow_signform_level1_for_approval: "Không sử dụng loại chữ ký cấp 4 cho hình thức ký duyệt có thể làm hợp đồng không đảm bảo về mặt pháp lý. Bạn chắc chắn muốn lưu thông tin cấu hình này?",
    confirm_allow_signform_level1_for_business: "Không sử dụng loại chữ ký cấp 4 cho loại khách hàng doanh nghiệp có thể làm hợp đồng không đảm bảo về mặt pháp lý. Bạn chắc chắn muốn lưu thông tin cấu hình này?",
    required_internaldiscuss_step_by_step: "Thẩm định nội bộ theo tuần tự",
    required_internaldiscuss_step_by_step_desc: "Các nhân sự tham gia phải thẩm định hợp đồng theo thứ tự đã được cấu hình",
    required_internaldiscuss_no_step_by_step: "Thẩm định nội bộ không theo tuần tự",
    required_internaldiscuss_no_step_by_step_desc: "Các nhân sự tham gia có thể thẩm định hợp đồng cùng lúc, đồng thời.",
    no_internaldiscuss: "Không thẩm định nội bộ",
    no_internaldiscuss_desc: "Không áp dụng quá trình thẩm định hợp đồng",
    internaldiscuss_sequence: "Thứ tự thẩm định",
    internaldiscuss_sequence_placeholder: "Nhập thứ tự thẩm định",
    internaldiscuss_type: "Hình thức thẩm định"

}