
<div class="wrapper">
    <div class="preview-overlay">

        <div class="preview-container">
            <div class="preview-header d-flex">
                <div (click)="close()"
                    class="ct-header-btn-back d-flex justify-content-center align-items-center cursor-pointer">
                    <span class="material-icons">close</span>
                </div>
                <div class="ct-title flex-grow-1 d-flex align-items-center px-3">
                    <h4 class="mb-0">Thông tin các bên tham gia hợp đồng</h4>
                </div>
                <div class="ct-toolbar-btn d-flex align-items-center">
                    <button (click)="sendRequestResign()" class="btn btn-primary d-flex align-items-center mr-3">Yêu cầu ký lại</button>
                </div>
            </div>

            <div class="preview-body">
                <div class="pv ct">
                    <div class="pv-body">
                        <div class="ctpv-container">
                            <div class="ctpv-content">
                                <div class="ct-container">
                                    <div class="ct-content" style="left: 0;">
                                        <div class="ct-content-enable-scroll enable-scroll">
                                            <div class="card ec-card shadow-none p-3">
                                                <div class="table-responsive">
                                                    <div class="">
                                                        
                                                        <table class="table table-bordered custom-table">
                                                            <thead class="bg-light">
                                                                <tr>
                                                                    <th tyle="width: 100px;">STT</th>
                                                                    <th style="min-width: 200px;">Bên tham gia</th>
                                                                    <th>Loại khách hàng</th>
                                                                    <th>Hình thức xác minh</th>
                                                                    <th>Hình thức ký</th>
                                                                    <th style="width: 150px;">Thứ tự ký</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let item of parties; let i = index">
                                                                    <td>{{ i + 1 }}</td>
                                                                    <td [class.bg-danger-tb]="isSubmit && item.hasError('partyId')">
                                                                        <div class="form-group mb-0">
                                                                            <input disabled [(ngModel)]="item.email" class="form-control">
                                                                        </div>
                                                                    </td>
                                                                    <td [class.bg-danger-tb]="isSubmit && item.hasError('userType')">
                                                                        <input disabled *ngIf="item.userType == 'CONSUMER'" value="Cá nhân" class="form-control">
                                                                        <input disabled *ngIf="item.userType == 'BUSINESS'" value="Doanh nghiệp" class="form-control">
                                                                    </td>
                                                                    <td [class.bg-danger-tb]="isSubmit && item.hasError('verificationType')">
                                                                        <input disabled value="{{showHinhThucXacMinh(item.verificationType)}}" class="form-control">
                                                                    </td>
                                                                    <td [class.bg-danger-tb]="isSubmit && item.hasError('signForm')">
                                                                        <div class="form-group mb-0">
                                                                            <ng-select
                                                                                *ngIf="!item.isOwner"
                                                                                [disabled]="!item.allowEdit"
                                                                                [(ngModel)]="item.signForm"
                                                                                [multiple]="true"
                                                                                [items]="item.hinhThucKyData"
                                                                                bindLabel="text"
                                                                                bindValue="id"
                                                                                [ngModelOptions]="{ standalone: true }"
                                                                                [placeholder]="'Chọn hình thức ký'"
                                                                                (change)="item.changeSignForm($event)"
                                                                                appendTo=".wrapper"
                                                                            >
                                                                            </ng-select>
                                                                        </div>
                                                                    </td>
                                                                    <td [class.bg-danger-tb]="isSubmit && item.hasError('sequence')">
                                                                        <input disabled [(ngModel)]="item.sequence" placeholder="Nhập số thứ tự"
                                                                            class="form-control text-right mb-0" type="text">
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>