<span class="d-flex align-self-center align-items-center">
    {{ title }}
    <span *ngIf="sortColConfig == null" class="material-icons ml-auto">
        unfold_more
    </span>
    <span *ngIf="sortColConfig == true" class="material-icons ml-auto">
        expand_less
    </span>
    <span *ngIf="sortColConfig == false" class="material-icons ml-auto">
        expand_more
    </span>
</span>
