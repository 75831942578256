import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { delay } from 'rxjs/operators';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { SequenceLine } from 'src/app/utils/models/contract/contract-number-config/sequence-line.model';
import { ContractNumberConfigService } from 'src/app/utils/services/aas-network/contract-number-config/contract-number-config.service';

@Component({
  selector: 'app-edit-contract-number-config',
  templateUrl: './edit-contract-number-config.component.html',
  styleUrls: ['./edit-contract-number-config.component.scss']
})
export class EditContractNumberConfigComponent implements OnInit {

  id = this.activatedRoute.snapshot.paramMap.get('id') || null;

  constructor(
    private activatedRoute: ActivatedRoute,
    private cncService: ContractNumberConfigService,
    private alert: AlertControl,
    private router: Router,
    private translate: TranslateService

  ) { }

  // Option thời điểm phát sinh số hợp đồng
  action: string = '';
  actionOptions: Array<object> = [
    { value: 'CREATE', label: "ListContractNumberConfig_filter_2" },
    { value: 'SUBMIT', label: "ListContractNumberConfig_filter_3" },
    { value: 'SIGN', label: "ListContractNumberConfig_filter_4" }
  ];

  // Option trạng thái
  disable: FormControl = new FormControl(true);
  disableOptions: Array<object> = [
    { value: true, label: "status_stopped" },
    { value: false, label: "status_running" }
  ];

  // Option thành phần
  ingredientOptions: Array<object> = [
    { value: 'NUMBER', label: 'AddContractNumberConfig_ingredient_1' },
    { value: 'VARIABLE', label: 'AddContractNumberConfig_ingredient_2' },
    { value: 'STRING', label: 'AddContractNumberConfig_ingredient_3' }
  ];

  // Option dấu ngăn cách
  separatorOptions: Array<object> = [
    { value: 'SLASH', label: '/' },
    { value: 'DASH', label: '_' },
    { value: 'SPACE', label: 'AddContractNumberConfig_separator_space' }
  ];

  // Option cấu hình (Thành phần Số thứ tự)
  paddingOptions: Array<object> = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    { value: 8, label: "8" },
    { value: 9, label: "9" }
  ];
  startNumberFormat: Array<any> = [];

  // Option reset type
  resetTypeOptions: Array<object> = [
    { value: 'MONTH', label: "AddContractNumberConfig_auto_reset_month" },
    { value: 'YEAR', label: "AddContractNumberConfig_auto_reset_year" },
    { value: '', label: "AddContractNumberConfig_no_auto_reset" },
  ];

  // Option Biến (VARIABLE)
  variableOptions: Array<object> = [
    { value: 'ROOTPATYNAME', label: 'AddContractNumberConfig_variable_1' },
    { value: 'PARTYNAME', label: 'AddContractNumberConfig_variable_2' },
    { value: 'YEAR', label: 'AddContractNumberConfig_variable_3' },
    { value: 'MONTH', label: 'AddContractNumberConfig_variable_4' },
  ];

  // Table cấu trúc thành phần
  sequenceLine: Array<SequenceLine> = [];
  configDefault = false;
  originConfigDefault: boolean = false;

  ngOnInit(): void {
    this.getCncDetails();
    this.getSuffixValue();
  }

  /**
   * Lấy thông tin cấu hình số HĐ
   */
  getCncDetails() {
    this.cncService.getDetails(this.id).subscribe((res: any) => {
      const cncDetails = res.object ?? null;
      this.sequenceLine = [];
      this.startNumberFormat = []

      if (cncDetails != null) {
        // Thời điểm phát sinh số hợp đồng
        this.action = cncDetails.action ?? '';
        // Trạng thái
        this.disable.setValue(cncDetails.disable ?? true);
        // Mặc định
        this.originConfigDefault = cncDetails.default ?? false;
        this.configDefault = cncDetails.default ?? false;

        // Build mảng Sequence Line
        const tempArr = cncDetails.sequenceLine ?? [];
        if (tempArr.length > 0) {
          tempArr.forEach((value: any) => {
            this.sequenceLine.push(new SequenceLine(value));
          });
          this.sequenceLine.sort((a, b) => { return a.sequence - b.sequence });
        }
        // Build mảng Start Number Format
        if (this.sequenceLine.length > 0) {
          this.sequenceLine.forEach(value => {
            if (value.padding > 1) {
              this.startNumberFormat.push([
                { value: "1", label: "1" },
                { value: "1".padStart(value.padding, '0'), label: "1".padStart(value.padding, '0') }
              ]);
            }
            else {
              this.startNumberFormat.push([
                { value: "1", label: "1" }
              ]);
            }
          });
        }
      }

      this.cncSimulcast();
    }, err => {

    });
  }

  // Mô phỏng số hợp đồng hiện tại
  cncSimulator: string = "";
  mapPrefixType: object = {
    "SLASH": '/',
    "DASH": '_',
    "SPACE": ' ',
    "": ''
  };
  cncSimulcast() {
    this.cncSimulator = "";
    this.sequenceLine.forEach((value) => {
      this.cncSimulator = this.cncSimulator + this.mapPrefixType[value.prefixType];

      if (value.suffixType == 'NUMBER') {
        this.cncSimulator = this.cncSimulator + value.startNumber;
      }
      else if (value.suffixType == 'STRING') {
        this.cncSimulator = this.cncSimulator + value.suffix;
      }
      else if (value.suffixType == 'VARIABLE') {
        if (value.suffix == 'PARTYNAME') {
          this.cncSimulator = this.cncSimulator + this.translate.instant('AddContractNumberConfig_partA');
        }
        else {
          this.cncSimulator = this.cncSimulator + (this.suffixValue[value.suffix] ?? '');
        }
      }
    });
  }

  /**
  * lấy giá trị suffix
  */
  suffixValue: any = {
    ROOTPATYNAME: '',
    PARTYNAME: '',
    YEAR: '',
    MONTH: ''
  };
  getSuffixValue() {
    this.cncService.getSuffixValue("ROOTPATYNAME").subscribe((res: any) => {
      this.suffixValue.ROOTPATYNAME = res.object ?? '';
      console.log(1)
    });
    this.cncService.getSuffixValue("YEAR").subscribe((res: any) => {
      this.suffixValue.YEAR = res.object ?? '';
      console.log(2)

    });
    this.cncService.getSuffixValue("MONTH").subscribe((res: any) => {
      this.suffixValue.MONTH = res.object ?? '';
      console.log(3)

    });
  }

  /**
   * Event khi thay đổi Suffix Type
   * @param sequence 
   * @param suffixType 
   */
  changeSuffixType(sequence: number, suffixType: string) {
    if (suffixType == 'STRING') {
      this.sequenceLine[sequence - 1].suffix = "";
    }
    else if (suffixType == 'VARIABLE') {
      this.sequenceLine[sequence - 1].suffix = "ROOTPATYNAME";
    }
  }

  /**
   * Event when change padding
   * @param sequence 
   * @param padding 
   */
  changePadding(sequence: number, padding: number) {
    let newFormat;
    if (padding == 1) {
      newFormat = [
        { value: "1", label: "1" }
      ];
    }
    else {
      newFormat = [
        { value: "1", label: "1" },
        { value: "1".padStart(padding, '0'), label: "1".padStart(padding, '0') }
      ];
    }
    this.startNumberFormat[sequence - 1] = newFormat;
    this.sequenceLine[sequence - 1].startNumber = "1";
  }

  /**
  * Xóa 1 thành phần cấu hình
  * @param index 
  */
  removeIngredient(index: number) {
    this.sequenceLine.splice(index, 1);
    for (let i = index; i < this.sequenceLine.length; i++) {
      this.sequenceLine[i].sequence--;
    }
    this.startNumberFormat.splice(index, 1);
    if (index == 0 && this.sequenceLine.length > 0) {
      this.sequenceLine[0].prefixType = "";
    }
    this.cncSimulcast();
  }


  /**
     * Thêm mới thành phần
     */
  addIngredient() {
    const currentLength = this.sequenceLine.length;
    let prefixType = "SLASH";
    if (currentLength == 0) {
      prefixType = "";
    }
    this.startNumberFormat.push([
      { value: "1", label: "1" },
      { value: "001", label: "001" }
    ]);
    this.sequenceLine.push({
      sequence: currentLength + 1,
      prefixType: prefixType,
      suffixType: "NUMBER",
      suffix: "",
      padding: 3,
      startNumber: "1",
      resetType: ""
    });
    this.cncSimulcast();
  }

  // Reset về trạng thái mới khởi tạo
  cancel() {
    this.getCncDetails();
  }

  /**
   * Cập nhật cấu hình số hợp đồng
   */
  save() {
    this.alert.showAlert(this.translate.instant('EditContractNumberConfig_alert_1'), ConstantAlertType.BLANK, true, "",
      this.translate.instant('btn_update'), () => {
        if (this.sequenceLine.length <= 0) {
          this.alert.showAlertOnlyNoti(this.translate.instant("AddContractNumberConfig_alert_2"), ConstantAlertType.ERROR);
        }
        else {
          const data = {
            disable: this.disable.value,
            default: this.configDefault,
            action: this.action,
            sequenceLine: this.sequenceLine
          };
          this.cncService.updateContractNumberConfig(this.id, data).subscribe((res: any) => {
            this.router.navigate([`app/console/configure-contract-number/detail`, this.id])
          }, err => {
            this.alert.showErrorHandled(err);
          });
        }
      }, "", this.translate.instant('btn_close'), () => { }, this.translate.instant('btn_confirm'))
  }

  changeCheckConfigDefault() {
    this.configDefault = !this.configDefault;
  }

  changeStatus(status: any) {
    if (status.value == true) {
      if (this.originConfigDefault == true) {
        this.disable.setValue(false);
        let msg = "Bạn không thể chuyển trạng thái ngưng hoạt động cho cấu hình số hợp đồng mặc định. Vui lòng chọn 1 cấu hình số hợp đồng khác làm mặc định để thay đổi trạng thái của cấu hình số hợp đồng này.";
        this.alert.showAlertOnlyNoti(msg, ConstantAlertType.BLANK);
      }
      else {
        this.configDefault = false;
      }
    }
  }


}
