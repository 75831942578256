export class MainResponse {
  public message: string = '';
  public status: string = '';
  public statusCode: number = 200;
  public error: any = null;
  public object: any = null;

  init(obj = null) {
    this.message = obj.message || '';
    this.status = obj.status || '';
    this.statusCode = obj.statusCode || 200;
    this.error = obj.error || {};
    this.object = obj.object || {};
  }
}
