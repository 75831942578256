<div class="card shadow-none">
    <div class="card-body">
        <form [formGroup]="formSubmit">
            <div class="row">
                <div class="col-md-auto align-self-end">
                    <label class="text-lg">Bổ sung thông tin</label>
                </div>
            </div>
            <hr class="my-3">
            <div class="input-group input-custom mb-5">
                <span class="input-custom-label">Họ và tên <span class="text-danger">*</span></span>
                <input appTrimValue formControlName="hoVaTen" type="text"
                    class="form-control form-control-lg input-custom-control"
                    placeholder="Nhập họ và tên" />
                <app-show-validate-errors [isSubmit]="isSubmit"
                    [errorMessages]="validateMsg.hoVaTen" [control]="formSubmit.controls.hoVaTen"
                    [detail]="{ name: 'hoVaTen' }">
                </app-show-validate-errors>
            </div>

            <div class="input-group input-custom mb-5">
                <span class="input-custom-label">Email <span class="text-danger">*</span></span>
                <input appTrimValue formControlName="email" type="text"
                    class="form-control form-control-lg input-custom-control"
                    placeholder="Nhập email" />
                <app-show-validate-errors [isSubmit]="isSubmit"
                    [errorMessages]="validateMsg.email" [control]="formSubmit.controls.email"
                    [detail]="{ name: 'email' }">
                </app-show-validate-errors>
            </div>

            <div class="input-group input-custom mb-5">
                <span class="input-custom-label">Tên tài khoản <span class="text-danger">*</span></span>
                <input appTrimValue formControlName="username" type="text"
                    class="form-control form-control-lg input-custom-control"
                    placeholder="Nhập tên tài khoản" />
                <app-show-validate-errors [isSubmit]="isSubmit"
                    [errorMessages]="validateMsg.username" [control]="formSubmit.controls.username"
                    [detail]="{ name: 'username' }">
                </app-show-validate-errors>
            </div>

            <div class="input-group input-custom mb-5">
                <span class="input-custom-label">Mật khẩu <span class="text-danger">*</span></span>
                <input appInputPassword formControlName="password" type="password"
                    class="form-control form-control-lg input-custom-control"
                    placeholder="Nhập mật khẩu" />
                <app-show-validate-errors [isSubmit]="isSubmit"
                    [errorMessages]="validateMsg.password" [control]="formSubmit.controls.password"
                    [detail]="{ name: 'password' }">
                </app-show-validate-errors>
            </div>

            <div class="input-group input-custom mb-2">
                <span class="input-custom-label">Tên miền <span class="text-danger">*</span></span>
                <input appTrimValue formControlName="domain" type="text"
                    class="form-control form-control-lg input-custom-control"
                    placeholder="Nhập tên miền" />
                <app-show-validate-errors [isSubmit]="isSubmit"
                    [errorMessages]="validateMsg.domain" [control]="formSubmit.controls.domain"
                    [detail]="{ name: 'domain' }">
                </app-show-validate-errors>

                <p class="mt-3">Your LMS address will be: http://{{ formSubmit.value.domain }}.{{ config.DOMAIN_SUB }}</p>
            </div>
           

            <div class="d-flex">
                <a [routerLink]="[constantUrl.HOME]" routerLinkActive="router-link-active"  class="btn-lg flex-grow-1 d-flex align-items-center">
                    <span class="material-icons" style="margin-right: 8px">arrow_back</span> Quay về trang chủ
                </a>

                <button class="btn btn-lg btn-primary flex-grow-1" (click)="actNext()">Tiếp theo</button>
            </div>
        </form>
    </div>
</div>