import {PermissionGroup} from './../list-all-permission/permission-group'

export class CategoryPackageItem {
    serviceInfoId: string;
    name : string;
    roleId : string;
    status: string;
    typeService: string;
    privileges: PermissionGroup[] = [];
    constructor(obj = null) {
        obj = obj || {};
        this.serviceInfoId = obj.serviceInfoId || '';
        this.name = obj.name || '';
        this.roleId = obj.roleId || '';
        this.typeService = obj.typeService || '';
        this.typeService = obj.typeService || '';
        if (obj.privileges && obj.privileges instanceof Array) {
            obj.privileges.forEach((item) => {
                this.privileges.push(new PermissionGroup(item));
            });
        }
    }
}