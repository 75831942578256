import { Injectable } from '@angular/core';
import { API } from '../../api';
import { ServicePath } from 'src/app/utils/common/constant-service-api';

const PACKAGE_INFO_URL = ServicePath.MANAGEMENT_SERVICE + '/licenses/package-info';
const PACKAGE_HISTORY_URL = ServicePath.MANAGEMENT_SERVICE + '/licenses/package-history-of-user';
const QUANTITY_INFO_URL = ServicePath.REPORT_SERVICE + '/quantity-info';

@Injectable({
  providedIn: 'root'
})
export class CustomerManagerService {

  constructor(
    private api: API
  ) { }

  // Get package information detail of a customer.
  getPackageInfo(partyId: any) {
    const url = PACKAGE_INFO_URL + `?partyId=${partyId}`;
    return this.api.get(url);
  }

  // Get expansion history of customer's package.
  getPackageHistory(partyId: any) {
    const url = PACKAGE_HISTORY_URL + `?partyId=${partyId}`;
    return this.api.get(url);
  }

  // Get quantity info of a customer.
  getQuantityInfo(partyId: any) {
    const url = QUANTITY_INFO_URL + `/${partyId}`;
    return this.api.get(url);
  }
}
