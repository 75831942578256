import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConstantUrl } from 'src/app/utils/common/constant-url';

@Component({
  selector: 'app-configuration-information',
  templateUrl: './configuration-information.component.html',
  styleUrls: ['./configuration-information.component.scss']
})
export class ConfigurationInformationComponent implements OnInit {
  tabSelected = this.activedRoute.snapshot.queryParamMap.get('type') || "CMND"; //CCCD, PASSPORT

  constructor(
    private router: Router,
    private activedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
   
  }

  selectTab(value) {
    this.tabSelected = value;
  }
  
  edit() {
    this.router.navigate([ConstantUrl.enduser_ekyc_config_update], { queryParams: { 'type':  this.tabSelected }});
  }
  
}
