import { Injectable } from '@angular/core';
import { ServicePath } from 'src/app/utils/common/constant-service-api';
import { API } from '../../api';

const CHECK_TIME_EXPIRED = ServicePath.MANAGEMENT_SERVICE + "/parties";
const enum PACKAGE_TYPE {
  TRIAL_TYPE = "TRIAL_TYPE"
}

@Injectable({
  providedIn: 'root'
})
export class PackageSessionService {

  private packageExpired: number = 0;
  private packageName: string = '';
  public packageType: string = '';
  private hidden: boolean = false;

  constructor(
    private api: API
  ) { }

  setPackageSessionInfo(value: any) {
    this.packageExpired = value.packageExpired;
    this.packageName = value.packageName || '';
    this.packageType = value.packageType || "";
  }

  getPackageExpired() {
    return this.packageExpired;
  }

  getPackageName() {
    return this.packageName;
  }

  get timeExpired() {
    return this.packageExpired;
  }

  get isTrial() {
    return this.packageType === PACKAGE_TYPE.TRIAL_TYPE;
  }

  get isValid() {
    return this.packageExpired > 0 && this.packageName;
  }

  get isNotValid() {
    return this.packageExpired <= 0 && this.packageName;
  }

  get isWarning() {
    return this.packageExpired <= 10 && this.packageName;
  }

  setHidden(value: boolean) {
    this.hidden = value;
  }

  get isHidden() {
    return this.hidden;
  }


  /**
   * Check Package Session local class
   * @param partyId 
   */
  getCheckPackageSession(partyId) {
    const url = CHECK_TIME_EXPIRED + `/${partyId}/check-time-expired-package`;
    this.api.get(url).subscribe((res: any) => {
      this.packageExpired = res.object?.timeExpired || "";
      this.packageName = res.object?.packageName || "";
      this.packageType = res.object?.packageType || "";
    });
  }

  /**
   * GET Check time expired package
   * @param partyId 
   */
  getCheckTimeExpiredPackage(partyId) {
    const url = CHECK_TIME_EXPIRED + `/${partyId}/check-time-expired-package`;
    return this.api.get(url);
  }
}
