<app-spinner></app-spinner>
<div class="content-header">
	<div class="container-fluid">
		<div class="row mb-2">
			<div class="col-md-auto">
				<h1 class="m-0 text-dark"><label>Thêm mới gói dịch vụ</label></h1>
			</div>
			<!-- /.col -->
			<div class="col-md-auto ml-auto">
				<!-- <button (click)="addFlow()" class="btn btn-primary d-inline-flex mr-3"><span
                      class="material-icons mr-1">add</span> Thêm mới</button> -->
			</div>
			<!-- <!-- /.col -->
		</div>
		<!-- /.row -->
	</div>
	<!-- /.container-fluid -->
</div>

<section class="content">
	<div class="container-fluid">
		<div class="card shadow-none mb-4">
			<div class="card-body">

				<div class="d-flex align-items-center">
					<h5 class="text-primary font-weight-bold m-0">Thông tin chung</h5>
					<div class="col-md-auto ml-auto">
						<button class="btn btn-outline-primary d-inline-flex mr-3" (click)="clearData()">
							<em class="material-icons nav-icon mr-1">clear</em>
							Hủy
						</button>
						<button class="btn btn-primary d-inline-flex" (click)="actSaveInfo()">
							<em class="material-icons nav-icon mr-1">save</em>
							Lưu
						</button>
					</div>
				</div>
				<hr class="my-4">

				<div class="row">
					<div class="col-12">
						<form [formGroup]="form">
							<div class="row">
								<div class="col-md-3">
									<div class="form-group">
										<label>Tên gói dịch vụ <span class="text-danger">*</span></label>
										<input appTrimValue type="text" formControlName="name"
											class="form-control" placeholder="Nhập tên gói dịch vụ" />
										<app-show-validate-errors [useSubmit]="true"
											[isSubmit]="isSubmit" [errorMessages]="validateMsg.name"
											[control]="form.controls.name" [detail]="{ name: 'name' }">
										</app-show-validate-errors>
									</div>
								</div>
								<div class="col-md-3">
									<div class="form-group">
										<label>Mã gói dịch vụ <span class="text-danger">*</span></label>
										<input appTrimValue type="text" formControlName="packageCode"
											class="form-control" placeholder="Nhập mã gói dich vụ" />
										<app-show-validate-errors [useSubmit]="true"
											[isSubmit]="isSubmit" [errorMessages]="validateMsg.packageCode"
											[control]="form.controls.packageCode" [detail]="{ name: 'packageCode' }">
										</app-show-validate-errors>
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group">
										<label>Mô tả <span class="text-danger">*</span></label>
										<input appTrimValue type="text" formControlName="description"
											class="form-control"
											placeholder="Nhập thông tin mô tả của gói cước" />
										<app-show-validate-errors [useSubmit]="true"
											[isSubmit]="isSubmit"
											[errorMessages]="validateMsg.description"
											[control]="form.controls.description"
											[detail]="{ name: 'description' }">
										</app-show-validate-errors>
									</div>
								</div>
							</div>

							<div class="row">
								<div class="col-md-6">
									<div class="row">
										<div class="col-md-6">
											<div class="form-group">
												<label>Loại gói dịch vụ <span class="text-danger">*</span></label>
												<ng-select
													formControlName="packageType"
													[items]="packageTypeData"
													bindLabel="label"
													bindValue="value"
													placeholder="Vui lòng chọn loại gói dịch vụ"
													(change)="changePackageType($event)"
												>
												</ng-select>
												<app-show-validate-errors [useSubmit]="true"
													[isSubmit]="isSubmit"
													[errorMessages]="validateMsg.packageType"
													[control]="form.controls.packageType"
													[detail]="{ name: 'packageType' }">
												</app-show-validate-errors>
											</div>
										</div>
										<div class="col-md-6">
											<div class="form-group">
												<label>Loại khách hàng áp dụng <span
														class="text-danger">*</span></label>
												<ng-select
													formControlName="userType"
													[items]="dropListsUserType"
													bindLabel="text"
													bindValue="id"
													[placeholder]="'Chọn loại khách hàng áp dụng cho gói cước'"
												></ng-select>
												<app-show-validate-errors [useSubmit]="true"
													[isSubmit]="isSubmit" [errorMessages]="validateMsg.userType"
													[control]="form.controls.userType"
													[detail]="{ name: 'userType' }">
												</app-show-validate-errors>
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-6">
									<div class="row">
										<div class="col-md-6">
											<div class="form-group">
												<label>Thời hạn sử dụng (tháng) <span class="text-danger">*</span></label>
												<div class="input-group">
													<div class="input-group-prepend dropdown">
														<button [attr.disabled]="form.value.packageType == 'NORMAL_TYPE' || form.value.packageType == 'EXTEND_TYPE' ? true : null" 
															class="btn btn-primary dropdown-toggle" 
															type="button" 
															data-toggle="dropdown" aria-expanded="false" 
															id="dropdownDuration">
															{{ durationTypeLabel }}
														</button>
														<ul class="dropdown-menu" aria-labelledby="dropdownDuration">
															<li>
																<a class="dropdown-item cursor-pointer" (click)="changedDurationType(durationType.NOLIMIT)">
																	Không giới hạn
																</a>
															</li>
															<li>
																<a class="dropdown-item cursor-pointer" (click)="changedDurationType(durationType.MONTH)">
																	Tháng
																</a>
															</li>
														</ul>
													</div>
													
													<input appTrimValue formControlName="duration" 
														[attr.disabled]="form.value.packageType == 'NORMAL_TYPE' || form.value.packageType == 'EXTEND_TYPE' ? true : null" 
														type="text" 
														class="form-control" 
														aria-label="" 
														placeholder="Nhập thời hạn sử dụng">
												</div>
												<app-show-validate-errors [useSubmit]="true"
													[isSubmit]="isSubmit" [errorMessages]="validateMsg.duration"
													[control]="form.controls.duration"
													[detail]="{ name: 'duration' }">
												</app-show-validate-errors>
											</div>
										</div>
										<div class="col-md-6">
											<div class="form-group">
												<label>Thứ tự hiển thị <span class="text-danger">*</span></label>
												<input type="text" formControlName="orderNumber"
													class="form-control"
													[placeholder]="'Nhập thứ tự hiển thị'" />
												<a class="" routerLinkActive="router-link-active"
													[routerLink]="" (click)="openModal()"><u>Xem danh sách gói
														cước đang hiển thị</u></a>
												<app-show-validate-errors [useSubmit]="true"
													[isSubmit]="isSubmit"
													[errorMessages]="validateMsg.orderNumber"
													[control]="form.controls.orderNumber"
													[detail]="{ name: 'orderNumber' }">
												</app-show-validate-errors>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="row">
								<div class="col-md-6">
									<div class="row">
										<div class="col-md-6">
											<div class="form-group">
												<label>Giá tiền (VNĐ) <span class="text-danger">*</span></label>
												<input appInputMark type="text" 
													[options]="optionsCurrency"
													formControlName="price"
													class="form-control" placeholder="Nhập giá tiền" />
												<app-show-validate-errors [useSubmit]="true"
													[isSubmit]="isSubmit" [errorMessages]="validateMsg.price"
													[control]="form.controls.price" [detail]="{ name: 'price' }">
												</app-show-validate-errors>

												<div class="tcheck-primary">
													<input type="checkbox" id="same_infra" [checked]="sameInfra">
													<label for="same_infra" class="w-100" [class.checked]="sameInfra"
														(click)="changeSameInfra()">
														<em>Lấy theo giá tiền của hạ tầng</em>
													</label>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>

				<div class="d-flex mt-3">
					<h5 class="text-primary font-weight-bold m-0">Thông tin hạ tầng</h5>
					<div class="col-md-auto ml-auto">
					</div>
				</div>
				<hr class="my-3">
				<div class="row">
					<div class="col-md-12">
						<div class="row" [formGroup]="form">
							<div class="col-md-6">
								<div class="form-group">
									<label>Loại danh mục hạ tầng <span class="text-danger">*</span></label>
									<ng-select
										formControlName="dmHaTang"
										[items]="danhMucHaTang"
										bindLabel="name"
										bindValue="serviceInfoId"
										(change)="eventChangeDmHaTang($event)"
										[clearable]="true"
										[placeholder]="'Chọn loại danh mục hạ tầng'"
									></ng-select>
									<app-show-validate-errors [useSubmit]="true"
										[isSubmit]="isSubmit" [errorMessages]="validateMsg.dmHaTang"
										[control]="form.controls.dmHaTang" [detail]="{ name: 'dmHaTang' }">
									</app-show-validate-errors>
								</div>
							</div>
						</div>
                        <div *ngIf="form.value.dmHaTang != null && form.value.dmHaTang != ''" class="row">
                            <div class="col-md-12">
                                <div class="table-responsive">
                                    <table class="table mb-0">
                                        <thead>
                                            <tr class="bg-tb-header border-top text-left">
                                                <th>{{ localize.stt }}</th>
                                                <th>{{ localize.parameter }}</th>
                                                <th>{{ localize.unit }}</th>
                                                <th>{{ localize.quantity }}</th>
                                                <th>{{ localize.unit_price }} (VNĐ)</th>
                                                <th>{{ localize.into_money }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of infraConfigs; let i = index">
                                                <td>{{ item.stt }}</td>
                                                <td>{{ item.name }}</td>
                                                <td>{{ item.unit }}</td>
                                                <td>
                                                    <input appOnlyNumber class="form-control" type="number" [(ngModel)]="item.unitNumber" (input)="progressPrice(item); getTotalMoney()">
                                                </td>
                                                <td>
                                                    <span *ngIf="item.param !== 'STORAGE_TIME'">{{ item.unitPrice }}</span>
                                                </td>
                                                <td>
                                                    <span *ngIf="item.param !== 'STORAGE_TIME'">{{ item.price }}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td colspan="4">Tổng cộng</td>
                                                <td>{{ totalPrice }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
					</div>
				</div>

				<div class="d-flex mt-3">
					<h5 class="text-primary font-weight-bold m-0">Thông tin chức năng</h5>
					<div class="col-md-auto ml-auto">
					</div>
				</div>
				<hr class="my-3">
				<div class="row">
					<div class="col-md-12">
						<span *ngIf="form.value.packageType === 'EXTEND_TYPE'">Thông tin chức năng của gói mở rộng sẽ được lấy theo thông tin chức năng hiện tại của khách hàng </span>
						<div *ngIf="form.value.packageType != 'EXTEND_TYPE'" class="row">
							<div class="col-12">
								<app-dual-list #duallist 
									[items]="items_dualist" 
									[listAssigned]="items_dualist_assigned" 
									[onlySelectParent]="true"
									(changed)="getListAssigned($event)">
								</app-dual-list>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</section>


<ng-template #showInfoPackageOrder let-c="close" let-d="dismiss">
	<div class="modal-header border-0">
		<h5 class="modal-title" style="font-weight: 700; margin-right: auto;">Danh sách gói dịch vụ đang hiển thị</h5>
		<button type="button" class="close" aria-label="Close"  (click)="d('Cross click')">
			<span aria-hidden="true" class="btn-light btn-alert-exit bg-exit-btn d-inline-flex p-2 rounded">
				<img src="assets/img/icon/icon-close.svg" width="18" height="18">
			</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="table-responsive table table-scroll mb-0">
			<table class="table main-table border mb-0">
				<thead>
					<tr class="bg-tb-header">
						<th>STT</th>
						<th>Tên gói dịch vụ</th>
						<th>Thứ tự hiển thị</th>
					</tr>
				</thead>
				<tbody *ngIf="listBusinesPackage.length > 0">
					<tr *ngFor="let item of listBusinesPackage; let i = index">
						<td>{{i + 1}}</td>
						<td>{{item.name}}
							<span *ngIf="item.packageType === 'TRIAL_TYPE'">(mặc định)</span>
						</td>
						<td>{{item.orderNumber}}</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="bg-tb-header p-3">
			<div class="row align-items-center">
				<div class="col-md">
					<div class="d-flex justify-content-end">
						<div class="d-flex justify-content-end align-items-center">
						</div>
						<div class="pagination pages no-border-pagination ml-2">
							<ngb-pagination [collectionSize]="totalListIndex" [(page)]="pageListIndex" [maxSize]="5" [rotate]="true"
								[ellipses]="false" [pageSize]="maxSizeListIndex" (pageChange)="loadListIndex($event)" [boundaryLinks]="true"
								aria-label="Default pagination">
								<ng-template ngbPaginationFirst>Đầu</ng-template>
								<ng-template ngbPaginationLast>Cuối</ng-template>
								<ng-template ngbPaginationPrevious>&laquo;</ng-template>
								<ng-template ngbPaginationNext>&raquo;</ng-template>
								<ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
							</ngb-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>