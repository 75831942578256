export class ServiceCatalogItem {
    created: string = "";
    modified: string = "";
    name: string = "";
    roleId: string = "";
    serviceInfoId: string = "";
    status: string = "";
    typeService: string = "";

    constructor(d = null) {
        this.created = d.created || "";
        this.modified = d.modified || "";
        this.name = d.name || "";
        this.roleId = d.roleId || "";
        this.serviceInfoId = d.serviceInfoId || "";
        this.status = d.status || "";
        this.typeService = d.typeService || "";
    }
}