<div class="question">
    <section class="question_ss1">
        <div class="container">
            <div class="row">
                <h3 class="title block">
                    {{ commonQuestion?.category?.categoryName }}
                </h3>
                    <div class="col-md-12 col-lg-11 col-xl-8" style="margin: auto">
                    <div class="input-group mb-3">
                        <input type="text" class="form-control"
                            placeholder="{{ 'enter_your_question_here' | translate }}" aria-label="Username"
                            aria-describedby="basic-addon1">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1"><img
                                    src="../../../assets/img/home/v4/Image/ic_magnifiersearch.png" alt=""></span>
                        </div>
                    </div>
                    <div id="accordion">

                        <div class="card" *ngFor="let item of commonQuestion?.blogs; let i = index">
                            <div class="card-header" id="headingOne">
                                <h5>
                                    <button class="btn btn-link" [ngClass]="{'collapsed': i != 0}" data-toggle="collapse"
                                        [attr.data-target]="'#collapse' + i" aria-expanded="true"
                                        aria-controls="collapseOne">
                                        <span> {{item?.title}}</span>
                                        <span class="icon-right">
                                            <svg width="12" height="8" viewBox="0 0 12 8" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M11 1.16994C10.8126 0.983692 10.5592 0.87915 10.295 0.87915C10.0308 0.87915 9.77737 0.983692 9.59001 1.16994L6.00001 4.70994L2.46001 1.16994C2.27265 0.983692 2.0192 0.87915 1.75501 0.87915C1.49082 0.87915 1.23737 0.983692 1.05001 1.16994C0.956281 1.26291 0.881887 1.37351 0.831118 1.49537C0.780349 1.61723 0.754211 1.74793 0.754211 1.87994C0.754211 2.01195 0.780349 2.14266 0.831118 2.26452C0.881887 2.38638 0.956281 2.49698 1.05001 2.58994L5.29001 6.82994C5.38297 6.92367 5.49357 6.99806 5.61543 7.04883C5.73729 7.0996 5.868 7.12574 6.00001 7.12574C6.13202 7.12574 6.26273 7.0996 6.38459 7.04883C6.50645 6.99806 6.61705 6.92367 6.71001 6.82994L11 2.58994C11.0937 2.49698 11.1681 2.38638 11.2189 2.26452C11.2697 2.14266 11.2958 2.01195 11.2958 1.87994C11.2958 1.74793 11.2697 1.61723 11.2189 1.49537C11.1681 1.37351 11.0937 1.26291 11 1.16994Z"
                                                    fill="#1363B9"></path>
                                            </svg>
                                        </span>
                                    </button>
                                </h5>
                            </div>
                            <div [id]="'collapse' + i" class="collapse" [ngClass]="{'show': i == 0}"
                                aria-labelledby="headingOne" data-parent="#accordion">
                                <div class="card-body">
                                    <div _ngcontent-tln-c166="" class="expand-content" [innerHTML]="item?.description">

                                    </div>
                                </div>
                            </div>
                        </div>


                        <!-- <div class="card" *ngFor="let item of commonQuestion.blogs; let i = index">
                            <div class="card-header" id="headingOne">
                                <h5>
                                    <button class="btn btn-link" data-toggle="collapse"
                                        [attr.data-target]="'#collapse' + i" aria-expanded="true"
                                        aria-controls="collapseOne">
                                        {{item.title}}
                                        <span class="icon-right">
                                            <svg width="12" height="8" viewBox="0 0 12 8" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M11 1.16994C10.8126 0.983692 10.5592 0.87915 10.295 0.87915C10.0308 0.87915 9.77737 0.983692 9.59001 1.16994L6.00001 4.70994L2.46001 1.16994C2.27265 0.983692 2.0192 0.87915 1.75501 0.87915C1.49082 0.87915 1.23737 0.983692 1.05001 1.16994C0.956281 1.26291 0.881887 1.37351 0.831118 1.49537C0.780349 1.61723 0.754211 1.74793 0.754211 1.87994C0.754211 2.01195 0.780349 2.14266 0.831118 2.26452C0.881887 2.38638 0.956281 2.49698 1.05001 2.58994L5.29001 6.82994C5.38297 6.92367 5.49357 6.99806 5.61543 7.04883C5.73729 7.0996 5.868 7.12574 6.00001 7.12574C6.13202 7.12574 6.26273 7.0996 6.38459 7.04883C6.50645 6.99806 6.61705 6.92367 6.71001 6.82994L11 2.58994C11.0937 2.49698 11.1681 2.38638 11.2189 2.26452C11.2697 2.14266 11.2958 2.01195 11.2958 1.87994C11.2958 1.74793 11.2697 1.61723 11.2189 1.49537C11.1681 1.37351 11.0937 1.26291 11 1.16994Z"
                                                    fill="#1363B9"></path>
                                            </svg>
                                        </span>
                                    </button>

                                </h5>
                            </div>
                            <div [id]="'collapse' + i" class="collapse" [ngClass]="{'show': i == 0}"
                                aria-labelledby="headingOne" data-parent="#accordion">
                                <div class="card-body">
                                    <div _ngcontent-tln-c166="" class="expand-content" [innerHTML]="item.description">
                                    </div>
                                </div>
                            </div>
                        </div> -->

                        <!-- <div *ngFor="let item of commonQuestion.blogs; let i = index" class="card">
                            <div class="card-header" id="headingOne">
                                <h5>
                                    <button class="btn btn-link" data-toggle="collapse"
                                        data-target="'#collapse' + i" aria-controls="'collapse' + i">
                                        {{ item.title }}
                                        <span class="icon-right">
                                            <svg width="12" height="8" viewBox="0 0 12 8" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M11 1.16994C10.8126 0.983692 10.5592 0.87915 10.295 0.87915C10.0308 0.87915 9.77737 0.983692 9.59001 1.16994L6.00001 4.70994L2.46001 1.16994C2.27265 0.983692 2.0192 0.87915 1.75501 0.87915C1.49082 0.87915 1.23737 0.983692 1.05001 1.16994C0.956281 1.26291 0.881887 1.37351 0.831118 1.49537C0.780349 1.61723 0.754211 1.74793 0.754211 1.87994C0.754211 2.01195 0.780349 2.14266 0.831118 2.26452C0.881887 2.38638 0.956281 2.49698 1.05001 2.58994L5.29001 6.82994C5.38297 6.92367 5.49357 6.99806 5.61543 7.04883C5.73729 7.0996 5.868 7.12574 6.00001 7.12574C6.13202 7.12574 6.26273 7.0996 6.38459 7.04883C6.50645 6.99806 6.61705 6.92367 6.71001 6.82994L11 2.58994C11.0937 2.49698 11.1681 2.38638 11.2189 2.26452C11.2697 2.14266 11.2958 2.01195 11.2958 1.87994C11.2958 1.74793 11.2697 1.61723 11.2189 1.49537C11.1681 1.37351 11.0937 1.26291 11 1.16994Z"
                                                    fill="#1363B9"></path>
                                            </svg>
                                        </span>
                                    </button>
                                </h5>
                            </div>
                            <div id="'collapse' + i" class="collapse" aria-labelledby="headingOne"
                                data-parent="#accordion">
                                <div class="card-body">
                                    <div _ngcontent-tln-c166="" class="expand-content" [innerHTML]=" item.description ">

                                    </div>
                                </div>
                            </div>
                        </div> -->

                        <!-- -------- -->


                    </div>
                    <a class="btn-qus">
                        {{ 'no_question_found' | translate }}
                        <img src="../../../assets/img/home/v4/Image/Vector (1).png" alt="" />
                    </a>
                </div>
            </div>
        </div>
    </section>
    <section class="question_ss2">
        <div class="container">
            <div class="row">
                <h3 class="title-top block">
                    {{ guide?.category?.categoryName }}
                </h3>
               
            </div>
            <div class="list-item">
                <div class="row">

                    <div *ngFor="let item of guide?.blogs" class="col-md-6 col-sm-3  col-lg-3">
                        <div class="col-item">
                            <div class="img-title">
                                <img src="{{ item?.avatar }}" alt="">
                            </div>
                            <a  class="btn-reg block">{{ item?.subTitle }}</a>
                            <span style="cursor: pointer;"
                            [routerLink]='"."'
                            (click)="gotopost(item?.id);"
                            [fragment]="'post'"
                            class="title">{{ item?.title }}</span>
                        </div>
                    </div>

                    <!-- <div class="col-md-3">
                        <div class="col-item">
                            <div class="img-title">
                                <img src="../../../assets/img/home/v4/Image/Rectangle 18.png" alt="">
                            </div>
                            <a href="" class="btn-reg block">Đăng ký</a>
                            <span class="title">Cách đăng ký dùng thử VNPT-eContract</span>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="col-item">
                            <div class="img-title">
                                <img src="../../../assets/img/home/v4/Image/Rectangle 18.png" alt="">
                            </div>
                            <a href="" class="btn-reg block">Đăng ký</a>
                            <span class="title">Cách đăng ký dùng thử VNPT-eContract</span>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="col-item">
                            <div class="img-title">
                                <img src="../../../assets/img/home/v4/Image/Rectangle 18.png" alt="">
                            </div>
                            <a href="" class="btn-reg block">Đăng ký</a>
                            <span class="title">Cách đăng ký dùng thử VNPT-eContract</span>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="col-item">
                            <div class="img-title">
                                <img src="../../../assets/img/home/v4/Image/Rectangle 18.png" alt="">
                            </div>
                            <a href="" class="btn-reg block">Đăng ký</a>
                            <span class="title">Cách đăng ký dùng thử VNPT-eContract</span>
                        </div>
                    </div> -->
                </div>
            </div>
                <a class="btn-qus d-none-mobi767">
                {{ 'more' |translate }}
                <img src="assets/img/home/v4/icon/showmiti.png" alt="">
            </a>
        </div>
    </section>
    <section class="section-8">
        <div class="container" style="    position: relative;">
            <div class="row body">
                <div class="col-md-7">
                    <div class="row">
                        <div class="left-content">
                            <p class="title">
                                {{ experience?.category?.categoryName }}<br />
                                {{ experience?.category?.description }}

                            </p>
                            <a routerLink="/app/signup" class="btn-trial">
                                <span>{{ experience?.blogs[1]?.title }}</span>
                                <img src="{{ experience?.blogs[1]?.avatar }}" alt="">
                            </a>
                            <ul class="list-item d-none d-sm-block">
                                <li>
                                    <img src="{{ experience?.blogs[2]?.avatar }}" alt="">
                                    <span>{{ experience?.blogs[2]?.title }}</span>
                                </li>
                                <li>
                                    <img src="{{ experience?.blogs[3]?.avatar }}" alt="">
                                    <span>{{ experience?.blogs[3]?.title }}</span>
                                </li>
                                <li>
                                    <img src="{{ experience?.blogs[4]?.avatar }}" alt="">
                                    <span>{{ experience?.blogs[4]?.title }}</span>
                                </li>
                                <li>
                                    <img src="{{ experience?.blogs[5]?.avatar }}" alt="">
                                    <span>{{ experience?.blogs[5]?.title }}</span>
                                </li>
                            </ul>
                            <div class="list-button">
                                <a href="{{ link.iosLink }}">
                                    <div class="img">
                                        <img src="{{ appLink?.blogs[1]?.avatar }}" alt="">
                                    </div>
                                </a>
                                <a href="{{ link.androidLink }}">
                                    <div class="img">
                                        <img src="{{ appLink?.blogs[2]?.avatar }}" alt="">
                                    </div>
                                </a>
                                <!-- <a href="{{ configs[10]?.value }}">
                                    <img src="{{ appLink?.blogs[1]?.avatar }}" alt="">
                                </a>
                                <a href="{{ configs[3]?.value }}">
                                    <img src="{{ appLink?.blogs[2]?.avatar }}" alt="" style="margin: 0px 12px">
                                </a> -->
                                <a href="{{ link.qrcodeLink }}"  class="d-none d-xl-block">
                                    <!-- <img style="width: 150px;" src="{{ appLink?.blogs[3]?.avatar }}" alt=""> -->
                                    <img src="{{ appLink?.blogs[3]?.avatar }}" alt="">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <!--  <img src="./Image/Group 1773s6.png" alt="" class="img-right d-none d-sm-block"> -->
            </div>
        </div>
    </section>
</div>