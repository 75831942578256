import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { localize } from 'src/app/utils/localize/localize';
import { ContractObject } from 'src/app/utils/models/aas-models/contract/contract/contract-object.model';
import { FlowContract } from 'src/app/utils/models/aas-models/flow-contract/flow-contract.model';
import { InternalDiscuss } from 'src/app/utils/models/aas-models/flow-contract/internal-discuss.model';
import { PartnerDiscuss } from 'src/app/utils/models/aas-models/flow-contract/partner-discuss.model';
import { EmployeeService } from 'src/app/utils/services/aas-network/employee/employee.service';
import { FlowContractService } from 'src/app/utils/services/aas-network/flow-contract/flow-contract.service';
import { PartService } from 'src/app/utils/services/aas-network/part/part.service';
import { TemplateService } from 'src/app/utils/services/aas-network/template.service';
import { AuthService } from 'src/app/utils/services/auth.service';
import { LoadingService } from 'src/app/utils/services/loading.service';


@Component({
  selector: 'app-internal-discuss-modal',
  templateUrl: './internal-discuss-modal.component.html',
  styleUrls: ['./internal-discuss-modal.component.scss']
})
export class InternalDiscussModalComponent implements OnInit {
  @Input('id')
  set id(value) { this._id = value; }
  get id() { return this._id; }
  _id: string = "";

  @Input('flowContract')
  set flowContract(value) { this._flowContract = new FlowContract(value); }
  get flowContract(): FlowContract { return this._flowContract; }
  _flowContract: FlowContract = new FlowContract();

  @Input('contract')
  set contract(value) { this._contract = new ContractObject(value); }
  get contract() { return this._contract; }
  _contract: ContractObject = new ContractObject();
  
  @Input('internalEditable')
  set internalEditable(value) { this._internalEditable = value; }
  get internalEditable() { return this._internalEditable; }
  _internalEditable: boolean = false;
  
  @Output() closed = new EventEmitter();
  
  localize = localize;

  flowContractSelected: FlowContract = null;
  internalDiscuss: InternalDiscuss[] = [];
  internalDiscussError: any = null;
  isSubmit = false;

  constructor(
    private partService: PartService,
    private employeeService: EmployeeService,
    private templateService: TemplateService,
    private myAlert: AlertControl,
    private flowContractService: FlowContractService,
    private authService: AuthService,
    private router: Router,
    private activedRoute: ActivatedRoute,
    private loadingService: LoadingService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.viewDidLoad();
  }

  viewDidLoad() {
    this.flowContractSelected = new FlowContract(this.flowContract);
    
    this.setData(this.flowContract);
  }

  setData(param: FlowContract) {
    param.internalDiscuss.forEach((item) => {
      let i = new InternalDiscuss(item);
      i.setPerAction();
      this.internalDiscuss.push(i);
    });
    console.log(this.internalDiscuss);
  }

  addInternalDiscuss() {
    this.internalDiscuss.push(new InternalDiscuss());
  }

  removeInternalDiscuss(index) {
    this.internalDiscuss.splice(index, 1);
  }

  close() {
    this.closed.emit(null);
  }

  save() {
    this.isSubmit = true;
    this.checkValidate();

    if (this.internalDiscussError) return;

    let flowContract: FlowContract = new FlowContract(this.flowContractSelected);
    flowContract.internalDiscuss = this.internalDiscuss;

    this.myAlert.showAlert(this.translate.instant('confirm_update_internal_discuss_config'), ConstantAlertType.BLANK, true, "", this.translate.instant('btn_confirm'), () => {
      this.postUpdateInteralDiscuss(this.contract.contractId, this.getInternalDiscussParam());
    }, "", this.translate.instant('btn_cancel'));

    // this.closed.emit(flowContract);
  }

  getInternalDiscussParam() {
    let res = [];
    this.internalDiscuss.forEach((item) => {
      res.push({
        "userId": item.userId || "",
        "departmentId": item.partyId || "",
        "permissions": item.permissions || []
      });
    })
    return res;
  }

  /**
   * Kiem tra du lieu cac bang
   */
   checkValidate() {
    this.internalDiscussError = null;

    let arr = [];
    this.internalDiscuss.forEach((item, index) => {
      item.checkValidate();
      arr.push({
        ...item,
        index: index
      })
      this.internalDiscussError = {...this.internalDiscussError, ...item.generalErrorValidate};
    });
    this.internalDiscussError  && Object.keys(this.internalDiscussError).length === 0 ? this.internalDiscussError = null : this.internalDiscussError;
  }

  /**
   * Quyen edit luong ky noi bo
   */
  get isCreator() {
    let isAdmin = this.authService.isAdmin;
    let isAm = this.authService.userId == this.contract.amId;
    return isAdmin || isAm;
  }

  postUpdateInteralDiscuss(contractId, param) {
    this.flowContractService.postUpdateInteralDiscuss(contractId, param).subscribe((res: any) => {
      this.myAlert.showAlertOnlyNoti(this.translate.instant('update_internal_discuss_config_success'), ConstantAlertType.SUCCESS);

      let flowContract: FlowContract = new FlowContract(this.flowContractSelected);
      flowContract.internalDiscuss = this.internalDiscuss;
      this.closed.emit(flowContract);
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }
}
