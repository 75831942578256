<app-spinner></app-spinner>
<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0 text-dark"><label>Thống kê chi tiết hợp đồng</label></h1>
            </div>
            <!-- /.col -->
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>
<div class="content">
    <div class="container-fluid">
        <div class="bg-white p-3">
            <div class="row">
                <div class="col-lg-3 col-md-5">
                    <form class="input-group border rounded mb-3">
                        <div class="input-group-prepend border-0">
                            <button class="btn d-inline-flex pr-2 pl-2" type="submit"><span
                                    class="material-icons">search</span></button>
                        </div>
                        <input type="text" class="form-control border-0"
                            placeholder="Nhập tên khách hàng hoặc tên gói dịch vụ..." [formControl]="searchFields"
                            aria-describedby="basic-addon1" #searchFieldContractDetail>
                    </form>
                </div>
                <div class="col-md-auto ml-auto">
                    <div class="btn-group">
                        <button class="btn btn-outline-primary dropdown-toggle d-inline-flex align-items-center mr-3"
                            data-toggle="dropdown" id="configColTable"><span
                                class="material-icons mr-1">visibility</span>Hiển thị</button>
                        <div class="dropdown-menu border-0" aria-labelledby="configColTable" style="width: 190px;">
                            <div *ngFor="let item of listConfigColTable" class="tcheck-primary d-inline-block px-3">
                                <input type="checkbox" id="{{ item.name }}" [checked]="configColTable[item.name]">
                                <label for="{{ item.name }}" class="w-100" [class.checked]="configColTable[item.name]"
                                    (click)="actConfigColTableView($event, item.name)">
                                    {{ item.label }}
                                </label>
                            </div>
                        </div>
                    </div>
                    <!-- <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="downloadSample()">
                        <span class="material-icons mr-1">file_download</span>Xuất Excel
                    </button> -->
                    <div class="btn-group">
                        <button class="btn btn-outline-primary dropdown-toggle d-inline-flex align-items-center mr-3"
                            data-toggle="dropdown" id="configfilter"><span class="material-icons mr-1">tune</span>Bộ
                            lọc</button>
                        <div class="dropdown-menu border-0" aria-labelledby="configfilter" style="width: 170px;">
                            <div *ngFor="let item of listFitler" class="tcheck-primary d-inline-block px-3">
                                <input type="checkbox" id="{{ item.name }}" [checked]="configFilter[item.name]">
                                <label for="{{ item.name }}" class="w-100" [class.checked]="configFilter[item.name]"
                                    (click)="actConfigFilter($event, item.name)">
                                    {{ item.label }}
                                </label>
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="cleanFilter()"> Đặt lại</button>
                    <button class="btn btn-primary d-inline-flex" (click)="searchWithFilter()"> Áp dụng</button>
                </div>
            </div>
            <hr class="my-2">
            <div class="mt-3">
                <form [formGroup]="formFilter">
                    <div class="row">
                        <div class="col" [class.d-none]="!configFilter.loaiKhachHang">
                            <div class="form-group">
                                <label for="statusGCS">Loại khách hàng</label>
                                <ng-select2 formControlName="userType" [data]="dropListsUserType" [options]="options"
                                    [placeholder]="'Chọn loại khách hoàng'"></ng-select2>
                            </div>
                        </div>
                        <div class="col-md" [class.d-none]="!configFilter.goiDichVu">
                            <div class="form-group">
                                <label for="statusGCS">Gói dịch vụ</label>
                                <ng-select2 formControlName="packageType" [data]="packageData | async"
                                    [options]="options" [placeholder]="'Chọn loại gói dịch vụ'"></ng-select2>
                            </div>
                        </div>
                        <div class="col-md" [class.d-none]="!configFilter.trangThaiHopDong">
                            <div class="form-group">
                                <label for="statusGCS">Trạng thái hợp đồng</label>
                                <ng-select2 formControlName="status" [data]="dropListsContractStatus"
                                    [options]="options" [placeholder]="'Chọn trạng thái hợp đồng'"></ng-select2>
                            </div>
                        </div>
                        <div class="col-md" [class.d-none]="!configFilter.tuNgay">
                            <div class="form-group">
                                <label for="">Từ ngày</label>
                                <div class="input-group">
                                    <input id="fromDateReport" #fromDatePicker name="fromDatePicker"
                                        (input)="dateInput($event, 'fromDate')"
                                        (dateSelect)="changeDate($event, 'from')" class="form-control"
                                        placeholder="dd/MM/yyyy" ngbDatepicker #pickDate="ngbDatepicker"
                                        [minDate]="{year: 1970, month: 1, day: 1}">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary" (click)="pickDate.toggle()"
                                            type="button"><em class="fas fa-calendar-alt"></em></button>
                                    </div>
                                </div>
                                <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.fromDate"
                                    [control]="f_fromDate" [detail]="{ name: 'fromDate' }"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md" [class.d-none]="!configFilter.denNgay">
                            <div class="form-group">
                                <label for="">Đến ngày</label>
                                <div class="input-group">
                                    <input id="toDateReport" #toDatePicker name="toDatePicker"
                                        (input)="dateInput($event, 'toDate')" (dateSelect)="changeDate($event, 'to')"
                                        class="form-control" placeholder="dd/MM/yyyy" ngbDatepicker
                                        #pickDate1="ngbDatepicker" [minDate]="{year: 1970, month: 1, day: 1}">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary" (click)="pickDate1.toggle()"
                                            type="button"><em class="fas fa-calendar-alt"></em></button>
                                    </div>
                                </div>
                                <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.toDate"
                                    [control]="f_toDate" [detail]="{ name: 'toDate' }"></app-show-validate-errors>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="bg-white">
            <div id="table-scroll" class="table table-scroll table-responsive">
                <table id="main-table" class="table main-table border header-table-color mb-0">
                    <thead class="bg-light">
                        <tr>
                            <th class="table-sticky-col1" scope="col">STT</th>
                            <th scope="col" [class.d-none]="!configColTable.tenHopDong">Tên hợp đồng</th>
                            <th scope="col" [class.d-none]="!configColTable.nguoiTao">Bên tạo hợp đồng</th>
                            <th scope="col" [class.d-none]="!configColTable.loaiKhachHang">Loại khách hàng</th>
                            <th scope="col" [class.d-none]="!configColTable.goiDichVu">Gói dịch vụ</th>
                            <th scope="col" [class.d-none]="!configColTable.nguoiNhan">Bên nhận hợp đồng</th>
                            <th scope="col" [class.d-none]="!configColTable.trangThaiHopDong">Trạng thái hợp đồng</th>
                            <th scope="col" [class.d-none]="!configColTable.ngayCapNhap">Ngày cập nhập</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of listReportDetail, let i = index">
                            <td class="table-sticky-col1" scope="col">{{ (page - 1) * 10 + i + 1 }}</td>
                            <td [class.d-none]="!configColTable.tenHopDong">{{ item?.tenHopDong }}</td>
                            <td [class.d-none]="!configColTable.nguoiTao">{{ item?.benTao }}</td>
                            <td [class.d-none]="!configColTable.loaiKhachHang">{{ userTypes[item.loaiKhachHang]?.lable }}
                            </td>
                            <td [class.d-none]="!configColTable.goiDichVu">{{ item?.goiDichVu }}</td>
                            <td [class.d-none]="!configColTable.nguoiNhan">{{ item?.benNhan }}</td>
                            <td [class.d-none]="!configColTable.trangThaiHopDong">
                                <span class="badge {{ constractStatus[item.trangThai].style }} badge-pd">{{
                                    constractStatus[item.trangThai]?.label }}</span>
                            </td>
                            <td [class.d-none]="!configColTable.ngayCapNhap">{{ item?.ngayCapNhap | date : 'dd/MM/yyy' }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="d-flex justify-content-end mt-3">
            <span *ngIf="totalElement == 0" class="flex-grow-1 ml-1">Không tìm thấy kết quả</span>
            <!-- <span *ngIf="totalElement > 0" class="flex-grow-1 ml-1">Hiển thị 1 đến {{listContrat.length}} trong tổng số
                {{totalElement}} kết quả</span> -->
            <div *ngIf="totalElement > maxSize" class="pagination pages aas-pagination">
                <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="5" [rotate]="true"
                    [ellipses]="false" [pageSize]="maxSize" (pageChange)="loadPage($event)" [boundaryLinks]="true"
                    aria-label="Default pagination">
                    <ng-template ngbPaginationFirst>Đầu</ng-template>
                    <ng-template ngbPaginationLast>Cuối</ng-template>
                    <ng-template ngbPaginationPrevious>&laquo;</ng-template>
                    <ng-template ngbPaginationNext>&raquo;</ng-template>
                    <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                </ngb-pagination>
            </div>
        </div>
    </div>
</div>