<aside #mainSidebar class="main-sidebar sidebar-light-primary elevation-4">
    <a [routerLink]="['/']" class="brand-link navbar-primary" style="height: 50px;">
        <img src="assets/img/techlab-logo.png" alt="AdminLTE Logo" width="20" height="20"
            class="brand-image elevation-0" style="opacity: 1; border-radius: 4px" />
        <span class="brand-text sidebar-logo-text font-weight-light">eContract</span>
    </a>
    <div class="sidebar">
        <app-enduser-menu *ngIf="path === 'app'"></app-enduser-menu>
        <app-admin-menu *ngIf="path === 'console'"></app-admin-menu>
    </div>
    <a (click)="logout()" class="logout-link brand-link navbar-primary text-white" style="cursor: pointer;">
        <em class="fas fa-sign-out-alt"></em>
        <span class="brand-text">{{ 'logout' | translate }}</span>
    </a>
</aside>