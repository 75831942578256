import { AuthService } from 'src/app/utils/services/auth.service';
import { Directive, ViewContainerRef, TemplateRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[showWithRoles]'
})
export class ShowWithPermissionsDirective implements OnInit {

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService
  ) { }

  condition: string[];

  @Input('showWithRoles') set showWithRoles(condition: string[]) {
    this.condition = condition;
  }

  ngOnInit(): void {
    if(this.checkRoles) {
      this.viewContainer.clear();
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }

    this.subcribe();
  }

  get checkRoles() {
    const token = this.authService.getToken();
    const token_decode = this.authService.jwtDecode(token);

    let ok = false;

    if (this.condition.length <= 0) return true;

    token_decode.authorities.forEach((item) => {
      if (this.condition.includes(item)) {
        ok = true
      }
    })

    return ok;
  }

  subcribe() {
    this.authService.partyInfo.subscribe((res: any) => {
      if(this.checkRoles) {
        this.viewContainer.clear();
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    })

    this.authService.userInfo.subscribe((res: any) => {
      if(this.checkRoles) {
        this.viewContainer.clear();
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    })
  }
}
