import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ContractStatus } from 'src/app/utils/common/constant';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { DateUtils } from 'src/app/utils/common/DateUtils';
import { REGEXP_DATE_INPUT } from 'src/app/utils/common/regexp';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { UtilsFunc } from 'src/app/utils/common/utils-func';
import { AasValidateMessage } from 'src/app/utils/common/validate-msg-aas';
import { ContractListItem } from 'src/app/utils/models/aas-models/contract/contract-list/contract-list-item.model';
import { ContractListResponse } from 'src/app/utils/models/aas-models/contract/contract-list/contract-list-response.model';
import { AasContractService } from 'src/app/utils/services/aas-network/aas-contract.service';
import { ReportCecaService } from 'src/app/utils/services/aas-network/report-ceca/report-ceca.service';
import { AuthService } from 'src/app/utils/services/auth.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-sign-multi-download-contract',
  templateUrl: './sign-multi-download-contract.component.html',
  styleUrls: ['./sign-multi-download-contract.component.scss'],
})
export class SignMultiDownloadContractComponent implements OnInit {
  @ViewChild('fromDatePicker') _fromDate: ElementRef;
  @ViewChild('toDatePicker') _toDate: ElementRef;
  by = this.activatedRoute.snapshot.paramMap.get('by');
  formSearch: FormGroup;
  formFilter: FormGroup;
  dateType = {
    CREATED_FROM: 'CREATED_FROM',
    CREATED_TO: 'CREATED_TO',
  };
  optionsCleave = {
    date: true,
    delimiter: '/',
    datePattern: ['d', 'm', 'Y'],
  };
  validateSearch = {
    fromDate: {
      pattern: 'Sai định dạng ngày tháng năm!',
      minDate: 'Từ ngày không được lớn hơn đến ngày và không được là ngày tương lai!',
    },
    toDate: {
      pattern: 'Sai định dạng ngày tháng năm!',
    },
  };
  isCheckAll = false;
  maxFileSelect = 10;
  maxSizeFile = 200;
  contractsSelected: Array<ContractListItem> = [];
  requestedContracts: ContractListItem[] =
    JSON.parse(window.localStorage.getItem('requestedContracts')) || [];
  contractsSelectedPagination: ContractListItem[] = [];
  sortColConfig = {
    title: null,
    partnersCount: null,
    modified: false,
    currentStage: null,
  };
  partyId: string = this.auth.partyId;
  userId: string = this.auth.userId;
  currentDate = new Date();
  contractStatus: string = 'ALL';
  dataTableExpand: Array<any> = [];
  page: number = 1;
  maxSize: number = 10;
  sort: string = 'DESC';
  propertiesSort: string = 'modified';
  totalElement: number = 0;
  listContract: Array<ContractListItem> = [];
  currentStage = ContractStatus;
  partnerId: string = '';
  serviceId: string = '';

  customerTypeFilter: string = '';
  fromDateFilter: string = '';
  toDateFilter: string = '';
  saveKeySearch: string = '';
  isSubmit: boolean = false;
  ServiceMsg: any = AasValidateMessage.CONTRACT_MANAGER_FILTER;
  requestId: string = '';
  isRequested = false;
  currentSelectedContracts: Array<ContractListItem> = [];
  currentPage = this.page;
  constructor(
    private activatedRoute: ActivatedRoute,
    private myAlert: AlertControl,
    private fb: FormBuilder,
    private auth: AuthService,
    private router: Router,
    private aasContract: AasContractService,
    private reportCecaService: ReportCecaService
  ) {}

  ngOnInit(): void {
    this.makeFormSearch();
    this.makeFormFilter();
    this.loadData();
  }
  makeFormSearch() {
    this.formSearch = this.fb.group({
      search: new FormControl(''),
    });
  }
  get keySearch() {
    return this.formSearch.get('search');
  }
  /**
   * Tìm kiếm với key
   * @param event
   */
  actSearch(event) {
    this.page = 1;
    this.loadData();
  }
  makeFormFilter() {
    this.formFilter = this.fb.group({
      customerType: new FormControl(''),
      toDate: new FormControl('', [Validators.pattern(REGEXP_DATE_INPUT)]),
      fromDate: new FormControl('', [Validators.pattern(REGEXP_DATE_INPUT)]),
      status: new FormControl(''),
    });
  }
  /**
   * Tìm kiếm với bộ lọc
   */
  searchWithFilter() {
    this.isSubmit = true;

    if (this.formFilter.valid) {
      // Nếu ngày nhập vào đúng định dạng thì check thêm điều kiện từ ngày phải nhỏ hơn đến ngày và không đc là ngày tương lai
      let dateFrom =
        this.formFilter.value.fromDate.length > 0
          ? new Date(DateUtils.convertStringtoDateSearch(this.formFilter.value.fromDate)).getTime()
          : -1;
      let dateTo =
        this.formFilter.value.toDate.length > 0
          ? new Date(DateUtils.convertStringtoDateSearch(this.formFilter.value.toDate)).getTime()
          : -1;
      if (dateTo > 0 && dateFrom > 0 && dateFrom > dateTo) {
        this.formFilter.controls.fromDate.setErrors({ minDate: true });
      }
    }

    if (this.formFilter.valid) {
      console.log(this.formFilter.value);
      this.loadData();
    }
  }

  /**
   * Đặt lại bộ lọc, tìm kiếm
   */
  cleanFilter() {
    this.isSubmit = false;
    this._fromDate.nativeElement.value = '';
    this._toDate.nativeElement.value = '';
    this.formFilter.reset();
    this.formFilter.controls.customerType.setValue('');
    this.formFilter.controls.fromDate.setValue('');
    this.formFilter.controls.toDate.setValue('');
    this.formFilter.controls.status.setValue('');
    this.formSearch.reset();
    this.keySearch.setValue('');
    this.searchWithFilter();
  }
  get fromDate() {
    return DateUtils.convertInputToFormat(this.formFilter.value.fromDate, DateUtils.format.API);
  }
  get toDate() {
    return DateUtils.convertInputToFormat(this.formFilter.value.toDate, DateUtils.format.API);
  }
  get status() {
    return this.formFilter.value.status;
  }
  get customerType() {
    return this.formFilter.value.customerType;
  }
  get totalRecordSelected() {
    return this.contractsSelected.length;
  }
  changeDateFilter(event, type) {
    switch (type) {
      case this.dateType.CREATED_FROM:
        this.formFilter.controls.fromDate.setValue(DateUtils.convertObjtoDate(event));
        break;
      case this.dateType.CREATED_TO:
        this.formFilter.controls.toDate.setValue(DateUtils.convertObjtoDate(event));
        break;
    }
  }
  /**
   * Load lại dữ liệu hợp đồng đã được chọn khi chuyển trang
   */
  reupdateCheckboxListContract() {
    this.isCheckAll = false;
    this.contractsSelected.forEach((item: any) => {
      let x = this.listContract.find((e) => {
        return e.contractId == item.contractId;
      });
      if (x != null) {
        x.selected = true;
        this.isCheckAll = true;
        this.listContract.forEach((item: any) => {
          if (item.selected == false) {
            this.isCheckAll = false;
          }
        });
      }
    });
  }

  goToBack() {
    this.router.navigate([ConstantUrl.CONTRACT_MANAGER]);
  }
  sortCol(key) {
    if (this.sortColConfig[key] == null) {
      this.sortColConfig[key] = true;
    } else if (this.sortColConfig[key] == false) {
      this.sortColConfig[key] = null;
    } else {
      this.sortColConfig[key] = !this.sortColConfig[key];
    }
    this.loadData(this.page);
  }

  getListContactByAssign(
    partyId,
    partnerId,
    partnerType,
    serviceId,
    fromDate,
    toDate,
    status,
    keySearch,
    page,
    maxSize,
    sort,
    propertiesSort
  ) {
    this.aasContract
      .getListDownloadContractSignMultipleByAssign(
        partyId,
        partnerId,
        partnerType,
        serviceId,
        fromDate,
        toDate,
        status,
        keySearch,
        page,
        maxSize,
        sort,
        propertiesSort
      )
      .subscribe(
        (res) => {
          var response = new ContractListResponse(res);

          this.listContract = response.object.data;
          // if (response && response.object.data instanceof Array) {
          //   this.listContract = [];
          //   response.object.data.forEach((item) => {
          //     this.listContract.push(new ContractListItem(item));
          //   });
          // }
          // console.log(this.listContract);

          this.reupdateCheckboxListContract();
          // this.progressTableData(this.listContract);

          this.totalElement = response.object.totalElement;
        },
        (err) => {
          console.log(err);
          this.listContract = [];
          // this.dataTableExpand = [];
          this.totalElement = 0;
          // this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
        }
      );
  }
  getListContactByOwner(
    partyId,
    partnerId,
    partnerType,
    serviceId,
    fromDate,
    toDate,
    status,
    keySearch,
    page,
    maxSize,
    sort,
    propertiesSort
  ) {
    this.aasContract
      .getListDownloadContractSignMultipleByOwner(
        partyId,
        partnerId,
        partnerType,
        serviceId,
        fromDate,
        toDate,
        status,
        keySearch,
        page,
        maxSize,
        sort,
        propertiesSort
      )
      .subscribe(
        (res) => {
          var response = new ContractListResponse(res);

          this.listContract = response.object.data;
          // if (response && response.object.data instanceof Array) {
          //   this.listContract = [];
          //   response.object.data.forEach((item) => {
          //     this.listContract.push(new ContractListItem(item));
          //   });
          // console.log(this.listContract);
          //   console.log(response.object.data);

          // }
          this.reupdateCheckboxListContract();
          // this.progressTableData(this.listContract);

          this.totalElement = response.object.totalElement;
        },
        (err) => {
          console.log(err);
          this.listContract = [];
          this.dataTableExpand = [];
          this.totalElement = 0;
          // this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
        }
      );
  }
  //MARK: Loadpage
  loadData(page = 1) {
    // this.currentPage = this.page;
    this.page = page;
    console.log('pgae', this.page, 'crr', this.currentPage);

    // this.contractsSelected=[]
    this.getListContact(
      this.userId,
      this.partnerId,
      this.serviceId,
      this.fromDate,
      this.toDate,
      this.status,
      this.keySearch.value || '',
      this.page,
      this.maxSize,
      this.sort,
      UtilsFunc.getPropertiesSort(this.sortColConfig)
    );
  }
  //MARK: NETWORKING
  getListContact(
    partyId,
    partnerId,
    serviceId,
    fromDate,
    toDate,
    status,
    keySearch,
    page,
    maxSize,
    sort,
    propertiesSort
  ) {
    if (this.by === 'OWNER') {
      this.getListContactByOwner(
        partyId,
        partnerId,
        this.customerType,
        serviceId,
        fromDate,
        toDate,
        status,
        keySearch,
        page,
        maxSize,
        sort,
        propertiesSort
      );
    } else if (this.by === 'ASSIGN') {
      this.getListContactByAssign(
        partyId,
        partnerId,
        this.customerType,
        serviceId,
        fromDate,
        toDate,
        status,
        keySearch,
        page,
        maxSize,
        sort,
        propertiesSort
      );
    } else {
      this.aasContract
        .getListContact(
          partyId,
          partnerId,
          serviceId,
          fromDate,
          toDate,
          status,
          keySearch,
          page,
          maxSize,
          sort,
          propertiesSort
        )
        .subscribe(
          (res) => {
            var response = new ContractListResponse(res);

            this.listContract = response.object.data;
            // this.listContract.push(this.listContract[0]);
            // this.listContract.push(this.listContract[0]);
            // this.listContract.push(this.listContract[0]);
            // this.listContract.push(this.listContract[0]);

            this.totalElement = response.object.totalElement;
          },
          (err) => {
            console.log(err);
            this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
          }
        );
    }
  }
  //MARK: Handle Error
  handleError(err, errorMsg) {
    this.myAlert.showErrorHandled(err, errorMsg);
  }
  selectRecord(i) {
    this.currentPage = this.page;
    if (
      this.listContract[i].selected == false &&
      this.contractsSelected.length >= this.maxFileSelect
    ) {
      this.myAlert.showAlertOnlyNoti(
        'Bạn chỉ được phép chọn nhiều nhất 10 file trong một lượt ký hàng loạt!',
        ''
      );
      if (document.querySelector<HTMLInputElement>(`input[id='${i}']`).checked) {
        document.querySelector<HTMLInputElement>(`input[id='${i}']`).checked = false;
      }
    } else {
      this.listContract[i].selected = !this.listContract[i].selected;
      if (this.listContract[i].selected == true) {
        this.contractsSelected.push(this.listContract[i]);
      } else {
        this.contractsSelected = this.contractsSelected.filter((item: any) => {
          return item?.contractId != this.listContract[i].contractId;
        });
      }
      this.isCheckAll = true;
      this.listContract.forEach((item: any) => {
        if (item.selected == false) {
          this.isCheckAll = false;
        }
      });
      this.currentSelectedContracts = this.contractsSelected;
      console.log(this.currentSelectedContracts);
    }
  }
  checkAll() {
    this.listContract.forEach((item) => {
      item.selected = !this.isCheckAll;
    });
    this.isCheckAll = !this.isCheckAll;

    if (this.isCheckAll == true) {
      if (this.listContract.length <= 10 && this.contractsSelected.length == 0) {
        this.contractsSelected = this.listContract;
        this.currentSelectedContracts = this.contractsSelected;
        console.log(this.currentSelectedContracts);
      } else if (
        this.currentPage !== this.page &&
        this.contractsSelected.length <= this.maxFileSelect
      ) {
        console.log('khac page');
        if (this.contractsSelected.length == 0 && this.listContract.length == this.maxFileSelect) {
          this.contractsSelected = Array.from(this.listContract);
          this.currentSelectedContracts = this.contractsSelected;
          return;
        }
        this.contractsSelected = this.contractsSelected.concat(Array.from(this.listContract));
      } else if (this.page == this.currentPage) {
        console.log('cung page');
        if (this.contractsSelected.length == 0) {
          this.contractsSelected = Array.from(this.listContract);
          this.currentSelectedContracts = this.contractsSelected;
          console.log(this.currentSelectedContracts);
        } else if (
          this.contractsSelected.length > 0 &&
          this.contractsSelected.length <= this.maxFileSelect &&
          this.maxSize == 10
        ) {
          if (
            _.filter(this.listContract, (n) => {
              return _.some(this.contractsSelected, (kn) => {
                return n.contractId === kn.contractId;
              });
            }).length > 0
          ) {
            this.contractsSelected = this.contractsSelected.concat(Array.from(this.listContract));
            if (
              _.filter(this.contractsSelected, (n) => {
                return !_.some(this.listContract, (kn) => {
                  return n.contractId === kn.contractId;
                });
              }).length > 0
            ) {
              // this.contractsSelected = this.contractsSelected.concat(Array.from(this.listContract));
            } else {
              this.contractsSelected = Array.from(this.listContract);
              this.currentSelectedContracts = this.contractsSelected;
              console.log(this.currentSelectedContracts);
            }
          }
          // else if (this.contractsSelected.length == this.maxFileSelect) {
          //   this.contractsSelected = this.contractsSelected.concat(Array.from(this.listContract));
          else {
            this.contractsSelected = this.contractsSelected.concat(Array.from(this.listContract));
          }
        } else if (
          this.contractsSelected.length > 0 &&
          this.contractsSelected.length <= this.maxFileSelect &&
          this.maxSize > 10
        ) {
          this.contractsSelected = this.contractsSelected.concat(Array.from(this.listContract));
        }
      }
      if (this.contractsSelected.length > this.maxFileSelect) {
        this.isCheckAll = false;
        this.contractsSelected = this.currentSelectedContracts;
        this.listContract.forEach((item, idx) => {
          item.selected = false;
          if (this.currentSelectedContracts[idx]) {
            if (
              this.currentSelectedContracts[idx].contractId == item.contractId &&
              this.currentPage == this.page
            ) {
              item.selected = this.currentSelectedContracts[idx].selected;
            }
          }
        });
        this.reupdateCheckboxListContract();
        this.myAlert.showAlertOnlyNoti(
          'Bạn không thể tiếp tục chọn vì đã đạt đến giới hạn về số lượng file hợp đồng hoặc dung lượng file hợp đồng cho phép',
          ''
        );
        if (document.querySelector<HTMLInputElement>(`input[id='all']`).checked) {
          document.querySelector<HTMLInputElement>(`input[id='all']`).checked = false;
        }
        return;
      }
    } else {
      this.contractsSelected = [];
      this.currentSelectedContracts = [];
    }
  }
  get totalSizeFile() {
    let sum = 0;
    this.contractsSelected.forEach((item) => {
      sum = sum + item.sizeFile;
    });
    return sum.toFixed(1);
  }

  /**
   * Lấy số lượng file đã chọn
   */
  get progressValueNow() {
    return Number((Number(this.totalSizeFile) / this.maxSizeFile) * 100).toFixed(1);
  }
  requestDownload() {
    const listContract = this.contractsSelected.map((item) => {
      return item['contractId'];
    });
    this.requestedContracts = this.contractsSelected;
    window.localStorage.setItem('requestedContracts', JSON.stringify(this.contractsSelected));
    this.reportCecaService.requestDownload(listContract).subscribe(
      (res: any) => {
        if (res && res.object instanceof Object) {
          this.requestId = res.object.contractRequestDownloadId;
        }
        this.reportCecaService.runJobDownload(this.requestId, this.partyId).subscribe(
          (res: any) => {},
          (error) => {}
        );
        this.isRequested = !this.isRequested;
      },
      (error) => {
        console.log(error);
      }
    );
  }
  goToListDownload() {
    this.reportCecaService.runJobDownload(this.requestId, this.partyId).subscribe(
      (res: any) => {
        console.log('done request');
        this.reportCecaService
          .getDetailContractDownloadJob(this.requestId, this.partyId)
          .subscribe((res: any) => {
            console.log(res.object);
          });
      },
      (error) => {
        console.log(error);
      }
    );

    this.router.navigate([ConstantUrl.list_download_contract]);
  }
}
