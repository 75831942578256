import { MainResponse } from '../../models/main-response.model';
import { ListDocumentObject } from './list-document-object.model';

export class ListDocumentResponse extends MainResponse {
  constructor(obj = null) {
    super();
    this.init(obj);
    this.object = new ListDocumentObject(obj.object);
  }
}
