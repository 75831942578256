<h5 class="ec-tab-content-title mt-3">{{ 'AddDocument_title' | translate }}</h5>

<div class="form-adding mt-4">
    <form [formGroup]="documentForm">
        <div class="form-group">
            <label>{{ 'EditDocument_name' | translate }} <span class="text-danger">*</span></label>
            <input appTrimValue class="form-control" placeholder="{{ 'EditDocument_ph_name' | translate }}"
                formControlName="documentName">
            <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="validateMsgForm.documentName"
                [control]="documentForm.controls.documentName" [detail]="{ name: 'documentName' }">
            </app-show-validate-errors>
        </div>
        <div class="row mt-4 mb-3">
            <div class="col-sm-4">
                <div class="rcheck-primary d-inline">
                    <input [attr.checked]="fileOrLink == true ? '' : null" type="radio" name="type" id="rr0">
                    <label for="rr0" (click)="selectFileOrLink(true)">
                        Đính kèm file
                    </label>
                </div>
            </div>
            <div class="col-sm-8">
                <div class="rcheck-primary d-inline">
                    <input [attr.checked]="fileOrLink == false ? '' : null" type="radio" name="type" id="rr1">
                    <label for="rr1" (click)="selectFileOrLink(false)">
                        Đính kèm đường dẫn (Link)
                    </label>
                </div>
            </div>
        </div>
        <div *ngIf="fileOrLink == true" class="form-group">
            <label>{{ 'EditDocument_file' | translate }} <span class="text-danger">*</span></label>
            <div class="input-group">
                <div class="custom-file custom-file-icon">
                    <input #templateFile class="custom-file-input" id="inputGroupFile01"
                        placeholder="{{ 'EditDocument_inputFileLabel' | translate }}"
                        (input)="onAttachFileChanged($event)" type="file">
                    <label class="custom-file-label" for="inputGroupFile01">{{ inputFileLabel | translate }}</label>
                    <span class="icon-file material-icons" style="cursor: pointer;"
                        (click)="templateFile.click()">attach_file</span>
                </div>
            </div>
            <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="validateMsgForm.fileKey"
                [control]="documentForm.controls.fileKey" [detail]="{ name: 'fileKey' }"></app-show-validate-errors>
        </div>
        <div *ngIf="fileOrLink == false" class="form-group">
            <label>Đường dẫn đính kèm <span class="text-danger">*</span></label>
            <input appTrimValue class="form-control" placeholder="Nhập đường dẫn đính kèm" formControlName="originalName">
            <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="validateMsgForm.originalName"
                [control]="documentForm.controls.originalName" [detail]="{ name: 'originalName' }">
            </app-show-validate-errors>
        </div>
        <div class="form-group">
            <label>{{ 'EditDocument_type' | translate }} <span class="text-danger">*</span></label>
            <ng-select appTrimValue placeholder="{{ 'EditDocument_ph_type' | translate }}"
                formControlName="documentType" [items]="documentTypeOptions" bindValue="value" bindLabel="label"
                [clearable]="false" [searchable]="false">
                <ng-template ng-label-tmp let-item="item">
                    <span>{{ item.label | translate }}</span>
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                    <span>{{ item.label | translate }}</span>
                </ng-template>
            </ng-select>
            <!-- <input appTrimValue class="form-control" placeholder="Nhập loại tài liệu" formControlName="documentType"> -->
            <!-- <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="validateMsgForm.documentType"
                [control]="documentForm.controls.documentType" [detail]="{ name: 'documentType' }">
            </app-show-validate-errors> -->
        </div>
        <div class="form-group">
            <label>{{ 'EditDocument_note' | translate }}</label>
            <input appTrimValue class="form-control" placeholder="{{ 'EditDocument_ph_note' | translate }}"
                formControlName="creationNote">
        </div>
    </form>
</div>

<!-- <h5 *ngIf="documents.length > 0" class="ec-tab-content-title mt-4 mb-3">Danh sách tài liệu đính kèm</h5>

<div *ngIf="documents.length > 0" class="list-document">
    <div *ngFor="let document of documents">
        <div class="document px-3 py-2 mt-3">
            <label class="document-name">{{ document?.documentName }}</label>
            <div class="d-flex"><span class="material-icons-outlined mr-1">
                    attach_file
                </span><span class="file-name">{{ document?.originalName }}</span></div>
        </div>
    </div>
</div> -->