import { Component, OnInit, ElementRef, ViewChild, HostListener } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Options } from 'select2';
import { Select2OptionData } from 'ng-select2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgModalComponent } from 'src/app/layout/extensions/ng-modal/ng-modal.component';
import { DocumentService } from 'src/app/utils/services/aas-network/document.service';
import { DocumentItem } from 'src/app/utils/models/documents/document-item-object';
import { ListDocumentObject } from 'src/app/utils/models/documents/list-document-object.model';
import { DocumentResponse } from 'src/app/utils/models/documents/document-response.model'
import { fromEvent } from 'rxjs';
import { ListDocumentResponse } from 'src/app/utils/models/documents/list-document-response.model';
import { filter, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { REGEXP_ID } from 'src/app/utils/common/regexp';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { ConstantAlertBtn, ConstantAlertMsg } from 'src/app/utils/common/constant-alert-msg';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { DOCUMENT_MANAGER_FORM } from 'src/app/utils/common/constant-vaildate-msg';

@Component({
  selector: 'app-documents-manager',
  templateUrl: './documents-manager.component.html',
  styleUrls: ['./documents-manager.component.scss']
})
export class DocumentsManagerComponent implements OnInit {
  @ViewChild('searchField') searchField: ElementRef;
  @ViewChild('uploadFile') myUploadFile: ElementRef;


  documents: Array<DocumentItem> = [];
  form: FormGroup;
  validateMsg = DOCUMENT_MANAGER_FORM;
  documentResponse : DocumentResponse;
  public options: Options;

  // file
  file: File = null;
  formCreate: FormGroup;

  inputFileLabel = 'Chọn file';
  isEdit = false;

  isSubmit = false;
  fileErrorType = false;
  uploadFailed = false;
  currentDocument : DocumentItem = new DocumentItem();
  
  fileType = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ]
  //========

  keySearch = '';
  saveKeySearch = '';

  dropListDocumentType: Array<Select2OptionData> = [
    {
      id: '0',
      text: 'Giấy tờ đính kèm không cho phép ký'
    },
    {
      id: '1',
      text: 'Giấy tờ đính kèm cho phép ký'
    }
   
  ];
  page: number = 1;
  maxSize: number = 5;
  propertiesSort: string = 'dateUpload';
  sort: string = 'DESC';
  totalElement: number = 0;

  constructor(private fb: FormBuilder, private documentService: DocumentService, private modalService: NgbModal, private route: Router) {
    this.makeForm();
    this.setupSelect2();
  }

  ngOnInit(): void {
    this.isEdit = false;
    this.keySearch = sessionStorage.getItem('docmentListKeySearch') || '';
    this.saveKeySearch = this.keySearch;
    this.getListDocuments();
  }

  makeForm() {
    this.form = this.fb.group({
      id: [''],
      documentName: ['', [Validators.required]],
      documentId: ['', [Validators.required, Validators.pattern(REGEXP_ID)]],
      documentType: ['', Validators.required],
      documentNote: ['']
    });
  }

  setupSelect2() {
    this.options = {
      multiple: false,
      width: '100%',
      closeOnSelect: true,
      language: 'vi',
    };
  }

  /**
   * Getting list document
   */
  getListDocuments(page = 1) {
    this.documentService.getListDocuments(page, this.maxSize, this.propertiesSort, this.sort, this.keySearch).subscribe(
      (res) => {
        this.documentResponse = new ListDocumentResponse(res);
        this.documents = this.documentResponse.object.data;
        this.page = this.documentResponse.object.page;
        this.totalElement = this.documentResponse.object.totalElement;
        sessionStorage.setItem('docmentListKeySearch', this.keySearch);

        if(this.documents && this.documents.length > 0) {
          this.editDocument(this.documents[0]);
        }
      },
      (err) => {
        this.handleError(err, ResponseMsg.MESSAGES.DOCUMENT_MANAGER);
      }
    );
  }

  /**
   * search documents
   */
  clickSearchBtn() {
    this.page = 1;
    this.saveKeySearch = `${this.saveKeySearch}`.trim();
    this.keySearch = this.saveKeySearch;
    this.getListDocuments();
  }

  inputDocumentKeySearch(e) {
    this.saveKeySearch = e.target.value;
  }
 
  /**
  * Create document
  */
  saveDocument() {
    const data = this.form.value;
    const documentId = data.id;
    let form1 = this.fb.group({
      documentName: [data.documentName.trim()],
      documentId: [data.documentId.trim()],
      documentType: [[data.documentType]],
      documentNote: [data.documentNote],
      
    })
    let data1 = form1.value

    if (!!documentId) {
      this.updateDocument(documentId, data1);
    } else {
      
      this.createDocument(data1)
    }
  }
    
  /**
   * Xoa tai lieu
   * @param event 
   * @param document 
   */
  deleteDocument(event, document) {
    event.stopPropagation();
    this.alert(ConstantAlertMsg.DOCUMENT_DELETE_CONFIRM, ConstantAlertType.BLANK, true, '', ConstantAlertBtn.CONFIRM, () => {
      this.documentService.deleteDocument(document.id).subscribe(
        (res) => {
          console.log(res);
          this.alert(ConstantAlertMsg.DOCUMENT_DELETE_SUCCESS, ConstantAlertType.SUCCESS, false, '', ConstantAlertBtn.OK, () => {
            this.getListDocuments();
          });
        },
        (err) => {
          this.handleError(err, ResponseMsg.MESSAGES.DOCUMENT_MANAGER);
        }
      );
    });
  }
  
  /**
   * Create document
   * @param data
   */
  createDocument(data) {
    this.alert(ConstantAlertMsg.DOCUMENT_CREATE_CONFIRM, ConstantAlertType.BLANK, true, '', ConstantAlertBtn.CONFIRM, () => {
      this.documentService.postCreateDocument(data).subscribe(
        (res) => {
          this.documentResponse = new DocumentResponse(res);
          this.alert(ConstantAlertMsg.DOCUMENT_CREATE_SUCCESS, ConstantAlertType.SUCCESS, false, '', ConstantAlertBtn.OK);
          this.form.reset();
          this.getListDocuments(this.page);
        },
        (err) => {
          this.handleError(err, ResponseMsg.MESSAGES.DOCUMENT_MANAGER);
        }
      );
    });
  }

  //  MARK Edit document
  /**
   * Chinh sua thong tin tai lieu
   * @param document 
   */
  editDocument(document) {
    this.isEdit = true;
    this.currentDocument = document;
    
    this.form.patchValue({
      id: document.id,
      documentName: document.documentName.trim(),
      documentId: document.documentId.trim(),
      documentType: document.documentType[0],
      documentNote: document.documentNote
    })

    console.log(this.form.value);
  }

  /**
   * Huy chinh sua tai lieu
   */
  cancelAction(){
    if(this.isEdit){
      this.editDocument(this.currentDocument)
    }
    else{
      this.form.reset();
    }

  }

  /**
   * Update Document
   * @param documentId
   * @param data
   */
  updateDocument(documentId, data) {
    this.alert(ConstantAlertMsg.DOCUMENT_UPDATE_CONFIRM, ConstantAlertType.BLANK, true, '', ConstantAlertBtn.CONFIRM, () => {
      delete data.id;
      this.documentService.putUpdateDocument(documentId, data).subscribe(
        (res) => {
          this.documentResponse = new DocumentResponse(res);
          this.alert(ConstantAlertMsg.DOCUMENT_UPDATE_SUCCESS, ConstantAlertType.SUCCESS, false, '', ConstantAlertBtn.OK);
          this.form.reset();
          this.getListDocuments(this.page);
        },
        (err) => {
          this.handleError(err, ResponseMsg.MESSAGES.DOCUMENT_MANAGER);
        }
      );
    });
  }


  /**
   * Upload file excel khoi tao lo danh muc tai lieu
   * @param event
   */
  uploadFile(event) {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('attachFile', file);
      this.alert(ConstantAlertMsg.DOCUMENT_CREATE_PACKAGE_CONFIRM, ConstantAlertType.BLANK, true, '', ConstantAlertBtn.CONFIRM, () => {
        this.documentService.postUploadDocumentFile(formData).subscribe(
          (res: any) => {
            console.log(res);
            if (!!res && !!res.body && !!res.body.object) {
              if (res.body.object.error == 0 && res.body.object.success != 0) {
                this.alert(ConstantAlertMsg.DOCUMENT_CREATE_PACKAGE_SUCCESS, ConstantAlertType.SUCCESS, false, '', ConstantAlertBtn.OK);
              } else {
                if (!!res && !!res.body && !!res.body.object) {
                  if (res.body.object.error == 0) {
                    this.alert(
                      `Thành công! <br /> Success: ${res.body.object.success} <br /> Error: ${res.body.object.error}`,
                      ConstantAlertType.SUCCESS
                    );
                  } else {
                    const errorMessages = res.body.object.result.join('<br />');
                    let msg = `Thành công: ${res.body.object.success} <br /> Lỗi: ${res.body.object.error} <br /> Lý do: <br />`;
                    res.body.object.result.forEach(item => {
                      msg += `Dòng ${item.row}: `;
                      if (item.msg && item.msg === "ECT-00002204") msg += ResponseMsg.MESSAGES.DOCUMENT_MANAGER[item.msg];
                      else if (item.msg && item.msg === "ECT-00002205") msg += ResponseMsg.MESSAGES.DOCUMENT_MANAGER[item.msg];
                      else if (item.msg && item.msg === "ECT-00002206") msg += ResponseMsg.MESSAGES.DOCUMENT_MANAGER[item.msg];
                      else if (item.msg && item.msg === "ECT-00002207") msg += ResponseMsg.MESSAGES.DOCUMENT_MANAGER[item.msg];
                      else if (item.msg !== "") msg += item.msg;
                      else msg += `Lỗi dữ liệu nhập vào`;
                      msg += `<br>`;
                    });
                    this.alert(msg,  ConstantAlertType.ERROR);
                  }
                }
              }

              this.getListDocuments();
            }
            
          },
          (err) => {
            this.handleError(err, ResponseMsg.MESSAGES.DOCUMENT_MANAGER);
          }
        );
      });
    }
  }



   /**
   * Download sample excel file
   */
  downloadSample() {
    this.documentService.downloadSample().subscribe((fileData: any) => {
      const blob: any = new Blob([fileData], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      var url = window.URL.createObjectURL(fileData);
      var anchor = document.createElement('a');
      anchor.download = 'document_sample.xlsx';
      anchor.href = url;
      anchor.click();
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.DOCUMENT_MANAGER);
    });
  }

  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = ConstantAlertBtn.OK, actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = ConstantAlertBtn.CANCEL, actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], ConstantAlertType.ERROR);
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, ConstantAlertType.ERROR);
    } else {
      this.alert(ConstantAlertMsg.DEFAULT_ERROR_REQUEST, ConstantAlertType.ERROR);
    }
  }
}
