import { MainComponent } from 'src/app/layout/main/main.component';
import { SignupConsumerObject } from './signup-consumer-object';
import { MainResponse } from '../../main-response.model';

export class SignupConsumerResponse extends MainResponse {
    object: SignupConsumerObject;

    constructor(obj = null) {
        super();
        obj = obj || {};
        this.init(obj);
        this.object = new SignupConsumerObject(obj.object);
    }
}