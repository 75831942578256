import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, timer } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { localize } from 'src/app/utils/localize/localize';
import { ChangeEmailSdtService } from 'src/app/utils/services/aas-network/change-email-sdt.service'
import { REGEXP_EMAIL, REGEXP_PHONE_NUMBER, } from "src/app/utils/common/regexp";
import { ValidatorsExtension } from "src/app/utils/common/validators-extension";
import { SIGNUP_CONSUMER } from 'src/app/utils/common/constant-vaildate-msg';
import { ConstantAlertBtn, ConstantAlertMsg } from 'src/app/utils/common/constant-alert-msg';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { TranslateService } from '@ngx-translate/core';
import { ControlAccount } from 'src/app/utils/common/control-account';
import { AuthService } from 'src/app/utils/services/auth.service';
import { InfoPartyObject } from 'src/app/utils/models/aas-models/info-party/info-party-object';

@Component({
  selector: 'app-change-email-sdt',
  templateUrl: './change-email-sdt.component.html',
  styleUrls: ['./change-email-sdt.component.scss']
})
export class ChangeEmailSdtComponent implements OnInit, AfterViewInit {
  @Input()
  set oldValue(value) { this._oldValue = value; }
  get oldValue() { return this._oldValue;}
  _oldValue: string = "";

  @Input()
  set isVisible(value: boolean) { this._isVisible = value; }
  get isVisible() { return this._isVisible;}
  _isVisible: boolean = false;

  @Input()
  set idSign(value) { this._idSign = value; }
  get idSign() { return this._idSign;}
  _idSign: string = "";

  @Input()
  set updateForm(value) { this._updateForm = value; }
  get updateForm() { return this._updateForm;}

  _updateForm: string = "email";


  timesOtpEmail: number ;
  
  timesOtpPhone: number ;
  stage: string = 'input'

  @Output('confirmOTPSuccess') confirmOTPSuccess: EventEmitter<any> = new EventEmitter();
  @Output('limitFailed') limitFailedEvent: EventEmitter<any> = new EventEmitter();
  @Output() public onActionCancel: EventEmitter<any> = new EventEmitter<any>();

  localize = localize;

  //form confirm top e-sign
  formOTP: FormGroup;
  isOTPSubmit: Boolean = false;
  validMsgOTP = {
    otp: {
        required: localize.otp_required,
        minlength: localize.otp_minlength,
        maxlength: localize.otp_maxlength
    },
    
  };
  validateMsg = SIGNUP_CONSUMER;
  
  visabled = false;
  limitFailed = 3;
  limitResendOTP = 1;
  counter$: Observable<number>;
  count = 0;
  remainOtpEmail= 0;
  remainOtpPhone=0
  partyInfo: InfoPartyObject
  constructor(
    private fb: FormBuilder,
    private changeEmailSdtService: ChangeEmailSdtService,
    private modalService: NgbModal,
    private alertControl: AlertControl,
    private translate: TranslateService,
    private router: Router,
    private controlAccount: ControlAccount,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
    this.makeConfirmOTPForm();
    this.controlAccount.requestAPIGetAccountInfo(this.authService.userId, this.authService.partyId, ()=>{
      this.partyInfo= this.controlAccount.partyInfo
      this.timesOtpEmail= this.partyInfo.emailNumberSentTimes
      this.timesOtpPhone=this.partyInfo.phoneNumberNumberSentTimes
      this.remainOtpEmail= 3 -this.timesOtpEmail
      this.remainOtpPhone= 2- this.timesOtpPhone
      console.log('email', this.remainOtpEmail, 'phone', this.remainOtpPhone);
    })
    
  }
  ngAfterViewInit(): void {
    let valueInput= document.querySelector<HTMLInputElement>('#updateInput');
    console.log(valueInput);
    if(this.updateForm != 'email'){
      valueInput.maxLength= 10
    }
  }
  get value(){
    return this.formOTP.controls.value
  }

  get otp(){
    return this.formOTP.controls.otp
  }
  get lenghtOtp(){
    return this.formOTP.controls.otp.value.length
  }
  //OTP Form 
  makeConfirmOTPForm() {
    let partern = this.updateForm == 'email' ? [Validators.required, Validators.pattern(REGEXP_EMAIL) ] : [Validators.required, Validators.pattern(REGEXP_PHONE_NUMBER), ValidatorsExtension.phoneNumber ]
    
    this.formOTP = this.fb.group({
      otp: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]),
      value: new FormControl('', partern),
    });
   
  }

  // Send API new info need update
  updateInfo(rmEmail, rmPhone){
    // this.controlAccount.requestAPIGetAccountInfo(this.authService.userId, this.authService.partyId, ()=>{
    //   this.partyInfo= this.controlAccount.partyInfo
    //   this.timesOtpEmail= this.partyInfo.emailNumberSentTimes
    //   this.timesOtpPhone=this.partyInfo.phoneNumberNumberSentTimes
    //   this.remainOtpEmail= 3 -this.timesOtpEmail
    //   this.remainOtpPhone= 2- this.timesOtpPhone
    //   console.log('email', this.remainOtpEmail, 'phone', this.remainOtpPhone);
    // })
    if (this.updateForm == 'email'){
      this.changeEmailSdtService.updateEmail(this.value.value).subscribe((res:any)=>{
        if(res && res.object instanceof Object){
          this.remainOtpEmail= 3  - res.object.emailNumberSentTimes
        }
      },err=>{
        console.log(err)
        if(err.error && err.error.code && rmEmail != 0 && rmPhone !=0){
          this.handleError(err, ResponseMsg.MESSAGES.CHANGE_EMAIL_SDT);
        }
        if(err.error && err.error.code || rmEmail == 0 || rmPhone ==0){
          this.handleErrorNavigate(err, ResponseMsg.MESSAGES.CHANGE_EMAIL_SDT);
        }
      },()=>{
        // Chuyển sang giai đoạn xác nhận OTP
        this.stage = 'confirm'
        this.resetTimeout();
      })
    }
    else{
      this.changeEmailSdtService.updateSDT(this.value.value).subscribe((res:any)=>{
        this.remainOtpPhone= 2- res.object.phoneNumberNumberSentTimes
      },err=>{
        console.log(err)
        if(err.error && err.error.code && rmEmail != 0 && rmPhone !=0){
          this.handleError(err, ResponseMsg.MESSAGES.CHANGE_EMAIL_SDT);
        }
        if(err.error && err.error.code || rmEmail == 0 || rmPhone ==0){
          this.handleErrorNavigate(err, ResponseMsg.MESSAGES.CHANGE_EMAIL_SDT);
        }
      },()=>{
        // Chuyển sang giai đoạn xác nhận OTP
        this.stage = 'confirm'
        this.resetTimeout();
      })
    }
  }

  cancelAction(){
    this.alertControl.showAlert(`Bạn chắc chắn muốn thoát phiên cập nhật ${ this.updateForm == 'sdt' ? 'số điện thoại' : 'email' }? Toàn bộ dữ liệu bạn đã nhập sẽ không được lưu lại!`, '', true, '', this.translate.instant('btn_ok'), () => {
      this.formOTP.reset()
      this.onActionCancel.emit()
    }, '', this.translate.instant('btn_close'), () => { });
  }

  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = ConstantAlertBtn.OK, actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = ConstantAlertBtn.CANCEL, actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.code && errorMsg[err.error.code]) {
      this.alert(errorMsg[err.error.code], ConstantAlertType.ERROR);
    } else if (err.error && err.error.error) {
      this.alert(err.error.error.toString(), ConstantAlertType.ERROR);
    } else {
      this.alert(ConstantAlertMsg.DEFAULT_ERROR_REQUEST, ConstantAlertType.ERROR);
    }
  }
  handleErrorNavigate(err, errorMsg) {
    console.log('runnnn');
    
    if (err.error && err.error.code && errorMsg[err.error.code]) {
      this.alert(errorMsg[err.error.code], ConstantAlertType.ERROR,false,'', ConstantAlertBtn.OK,()=>{
        // this.router.navigate(['/app/console/user-settings'])
        window.location.reload()
      });
    } else if (err.error && err.error.error) {
      this.alert(err.error.error.toString(), ConstantAlertType.ERROR,false,'', ConstantAlertBtn.OK,()=>{
        // this.router.navigate(['/app/console/user-settings'])
        window.location.reload()
      });
    } else {
      this.alert(ConstantAlertMsg.DEFAULT_ERROR_REQUEST, ConstantAlertType.ERROR,false,'', ConstantAlertBtn.OK,()=>{
        // this.router.navigate(['/app/console/user-settings'])
        window.location.reload()
      } );
    }
  }

  confirmOTP(){
    if (this.updateForm == 'email'){
      this.changeEmailSdtService.verifyOTP_Email(this.value.value, this.otp.value).subscribe(res=>{
        this.alert("Bạn đã cập nhật thông tin email thành công!", ConstantAlertType.SUCCESS, false, '', ConstantAlertBtn.OK, ()=>{
          this.confirmOTPSuccess.emit();
        });
      },err=>{
        console.log(err)
        if(err.error && err.error.code){
          this.handleError(err, ResponseMsg.MESSAGES.CHANGE_EMAIL_SDT);
          if (err.error.code == 'WRONG_OTP_0'){
            // go to user-setting navigate and reload api replace reload page 
            this.confirmOTPSuccess.emit()
          }       

        }
      },()=>{
        
      })
    }
    else{
      this.changeEmailSdtService.verifyOTP_SDT(this.value.value, this.otp.value).subscribe(res=>{
        this.alert("Bạn đã cập nhật thông tin số điện thoại thành công!", ConstantAlertType.SUCCESS, false, '', ConstantAlertBtn.OK, ()=>{
          this.confirmOTPSuccess.emit();
        });
      },err=>{
        console.log(err)
        if(err.error && err.error.code){
          this.handleError(err, ResponseMsg.MESSAGES.CHANGE_EMAIL_SDT);
          if (err.error.code == 'WRONG_OTP_0'){
            // go to user-setting navigate and reload api replace reload page 
            this.confirmOTPSuccess.emit()
          }
          
        }
      },()=>{
        
      })
    }
  }

  /**
   * Gui lai OTP
   */
  resendOTP() {
    this.controlAccount.requestAPIGetAccountInfo(this.authService.userId, this.authService.partyId, ()=>{
      this.partyInfo= this.controlAccount.partyInfo
      this.timesOtpEmail= this.partyInfo.emailNumberSentTimes
      this.timesOtpPhone=this.partyInfo.phoneNumberNumberSentTimes
      this.remainOtpEmail= 3 -this.timesOtpEmail
      this.remainOtpPhone= 2- this.timesOtpPhone
      console.log('email', this.remainOtpEmail, 'phone', this.remainOtpPhone);
    })
    if(this.updateForm == 'email'){
      // this.remainOtpEmail-=this.timesOtpEmail
      if(this.remainOtpEmail == 0){
        this.alertControl.showAlert(`<p>Bạn có chắc chắn muốn nhận lại mã OTP khác ? </p> <i>Lưu ý: Mã OTP cũ mà bạn nhận được trước đó sẽ không còn hiệu lực. Bạn còn ${this.remainOtpEmail} lượt gửi lại mã OTP!</i>`, ConstantAlertType.BLANK, true,'','Gửi lại OTP', ()=>{
         this.updateInfo(this.remainOtpEmail, this.remainOtpPhone)
        }, '','Đóng', ()=>{})
      } else{
        this.alertControl.showAlert(`<p>Bạn có chắc chắn muốn nhận lại mã OTP khác ? </p> <i>Lưu ý: Mã OTP cũ mà bạn nhận được trước đó sẽ không còn hiệu lực. Bạn còn ${this.remainOtpEmail} lượt gửi lại mã OTP!</i>`, ConstantAlertType.BLANK, true,'','Gửi lại OTP', ()=>{
          this.updateInfo(this.remainOtpEmail, this.remainOtpPhone)
        }, '','Đóng', ()=>{})
      }
      
    }
    else if(this.updateForm == 'sdt'){
      // this.remainOtpPhone-= this.timesOtpPhone
      if(this.remainOtpPhone ==0){
        this.alertControl.showAlert(`<p>Bạn có chắc chắn muốn nhận lại mã OTP khác ? </p> <i>Lưu ý: Mã OTP cũ mà bạn nhận được trước đó sẽ không còn hiệu lực. Bạn còn ${this.remainOtpPhone} lượt gửi lại mã OTP!</i>`, ConstantAlertType.BLANK, true,'','Gửi lại OTP', ()=>{
          this.updateInfo(this.remainOtpEmail, this.remainOtpPhone)
        }, '','Đóng', ()=>{})
      }
      else {
        this.alertControl.showAlert(`<p>Bạn có chắc chắn muốn nhận lại mã OTP khác ? </p> <i>Lưu ý: Mã OTP cũ mà bạn nhận được trước đó sẽ không còn hiệu lực. Bạn còn ${this.remainOtpPhone} lượt gửi lại mã OTP!</i>`, ConstantAlertType.BLANK, true,'','Gửi lại OTP', ()=>{
          this.updateInfo(this.remainOtpEmail, this.remainOtpPhone)
        }, '','Đóng', ()=>{})
      }
      
    }
    
    
  }

  resetTimeout() {
    this.count = 30;
    this.counter$ = timer(0, 1000).pipe(
      take(this.count),
      map(() => --this.count)
    );
  }

}