import * as moment from 'moment'

export class PersonalDetailObject {
    ten: string = '';
    cmnd: string = '';
    gioiTinh: string = '';
    email: string = '';
    sdt: string = '';
    hktt: string = '';
    ngayCap: string = '';
    noiCap: string = '';
    username: string = '';
    ngaySinh: string = '';
    identityLevel: number;
    userId: string = '';
    loaiGtId: string = "";
    diaChi: string = "";
    noiSinh: string = "";
    expiryDate: string = "";
    packageName: string = "";
    disable: string = "";
    subscriptionCode: string = "";
    subscriptionType: string = "";
    provinceCode: string = "";
    requesterCode: string = "";
    creatorCode: string = "";


    constructor(obj = null) {
        obj = obj || {};
        this.ten = obj.ten || '';
        this.cmnd = obj.cmnd;
        this.gioiTinh = obj.gioiTinh || '';
        this.hktt = obj.hktt || '';
        this.ngayCap = obj.ngayCap || "";
        this.noiCap = obj.noiCap || '';
        this.email = obj.email || '';
        this.sdt = obj.sdt || '';
        this.username = obj.username || '';
        this.ngaySinh = obj.ngaySinh || "";
        this.identityLevel = obj.identityLevel;
        this.userId = obj.userId || '';
        this.loaiGtId = obj.loaiGtId || "";
        this.diaChi = obj.diaChi || "";
        this.noiSinh = obj.noiSinh || "";
        this.expiryDate = obj.expiryDate || "";
        this.packageName = obj.packageName || "";
        this.disable = obj.disable || "";
        this.subscriptionCode = obj.subscriptionCode || "";
        this.subscriptionType = obj.subscriptionType || "";
        this.provinceCode = obj.provinceCode || "";
        this.requesterCode = obj.requesterCode || "";
        this.creatorCode = obj.creatorCode || "";
    }
}