<app-spinner></app-spinner>
<div class="wrapper">
    <nav class="navbar navbar-expand navbar-primary navbar-dark">
        <a class="navbar-brand" href="#">
            <img src="assets/img/icon-logo.png" width="30" height="30" class="d-inline-block align-top" alt="">
            <span class="ml-3">VNPT</span>
        </a>

        <ul class="navbar-nav ml-auto">
            <!-- Messages Dropdown Menu -->
            <!-- <app-messages-dropdown-menu></app-messages-dropdown-menu> -->
            <!-- Notifications Dropdown Menu -->
            <!-- <app-notifications-dropdown-menu></app-notifications-dropdown-menu> -->
            <li class="nav-item">
              <img class="img-circle" src="assets/img/icon-avatar-default.png" width="30" height="30"
                style="margin-top: 5px;" />
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
                aria-haspopup="true" aria-expanded="false">
                {{ getUsername }}
              </a>
        
              <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
                <a class="dropdown-item cursor-pointer d-flex" (click)="logout()"><em class="material-icons mr-3">input</em>Đăng
                  xuất</a>
              </div>
            </li>
          </ul>
    </nav>

    <div #contentWrapper class="wrapper">
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-center">
                    <section class="col-md-11">
                        <div class="content d-none" [class.d-none]="miniLoader.isVisible() | async">
                            <div class="tab-bar-custom mb-4 mt-4">
                                <ul class="nav nav-tabs" id="tabSignAction" role="tablist">
                                    <li class="nav-item nav-material active">
                                        <a #step1tab class="nav-link d-flex" [class.done]="stepControl[0].done" [class.active]="currentStep === 0"
                                            [class.disabled]="stepControl[0].disabled" (click)="changedStep($event, 0)">
                                            <span class="ct-icon-font mr-2" [class.icon-circle-unchecked]="!stepControl[0].done"
                                                [class.icon-circle-checked]="stepControl[0].done"></span>
                                            Thông tin tài khoản
                                        </a>
                                    </li>
                                    <li class="nav-item nav-material">
                                        <a #step2tab class="nav-link d-flex" [class.done]="stepControl[1].done" [class.active]="currentStep === 1"
                                            [class.disabled]="stepControl[1].disabled" (click)="changedStep($event, 1)">
                                            <span class="ct-icon-font icon-circle-unchecked mr-2"
                                                [class.icon-circle-unchecked]="!stepControl[1].done"
                                                [class.icon-circle-checked]="stepControl[1].done"></span>
                                            Thông tin GPKD
                                        </a>
                                    </li>
                                    <li class="nav-item nav-material">
                                        <a #step3tab class="nav-link d-flex" [class.done]="stepControl[2].done" [class.active]="currentStep === 2"
                                            [class.disabled]="stepControl[2].disabled" (click)="changedStep($event, 2)">
                                            <span class="ct-icon-font icon-circle-unchecked mr-2"
                                                [class.icon-circle-unchecked]="!stepControl[2].done"
                                                [class.icon-circle-checked]="stepControl[2].done"></span>
                                            Chọn gói cước
                                        </a>
                                    </li>
                                    <li class="nav-item nav-material">
                                        <a #step4tab class="nav-link d-flex" [class.done]="stepControl[3].done" [class.active]="currentStep === 3"
                                            [class.disabled]="stepControl[3].disabled" (click)="changedStep($event, 3)">
                                            <span class="ct-icon-font icon-circle-unchecked mr-2"
                                                [class.icon-circle-unchecked]="!stepControl[3].done"
                                                [class.icon-circle-checked]="stepControl[3].done"></span>
                                            Kết quả phê duyệt
                                        </a>
                                    </li>
                                </ul>
                            </div>
            
                            <div class="tab-content contract-tab-body" id="tabSignAction">
                                <div class="position-relative tab-pane fade" [class.show]="currentStep === 0"
                                    [class.active]="currentStep === 0">
                                    <app-step-one-verify-vip *ngIf="!showStep(0)" (next)="handleNextEvent(0, $event)"></app-step-one-verify-vip>
                                </div>
            
                                <div class="position-relative tab-pane fade" [class.show]="currentStep === 1"
                                    [class.active]="currentStep === 1">
                                    <div class="content-step">
                                        <app-step-two-verify-vip *ngIf="!showStep(1)" (next)="handleNextEvent(1, $event)"></app-step-two-verify-vip>
                                    </div>
                                </div>
            
                                <div class="position-relative tab-pane fade" [class.show]="currentStep === 2"
                                    [class.active]="currentStep === 2">
                                    <app-step-three-verify-vip *ngIf="!showStep(2)" (next)="handleNextEvent(2, $event)" (back)="handleBackEvent(2, $event)"></app-step-three-verify-vip>
                                </div>
            
                                <div class="position-relative tab-pane fade" [class.show]="currentStep === 3"
                                    [class.active]="currentStep === 3">
                                    <app-step-four-verify-vip #stepfour></app-step-four-verify-vip>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </section>
    </div>
</div>

<div id="ppi" style="height: 1in; width: 1in; position: absolute; top: -100%; left: -100%;"></div>