import { MainResponse } from '../../../../main-response.model';
import { CategoryPackageItem } from './category-package-item.model';


export class ListCategoryPackageResponse extends MainResponse {
    data: CategoryPackageItem[];
    page: number;
    maxSize: number;
    propertiesSort: string;
    totalElement: number;
    sort: string;

    constructor(obj = null) {
        super();
        obj = obj || {};
        this.page = obj.page || 1;
        this.maxSize = obj.maxSize || 20;
        this.propertiesSort = obj.propertiesSort || '';
        this.totalElement = obj.totalElement || 0;
        this.sort = obj.sort || '';
        this.data = [];
        if (obj.data && obj.data instanceof Array) {
            obj.data.forEach((item) => {
                this.data.push(new CategoryPackageItem(item));
            });
        }
    }
}