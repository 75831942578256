<div class="content-header">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-sm-6">
        <h1 class="m-0 text-dark">
          <label for="">Danh sách loại tin bài</label>
        </h1>
      </div>
      <div class="col-12 col-sm-6">
        <a [routerLink]="['/console/landing-page/blog-type/add']" routerLinkActive="router-link-active" class="btn btn-primary float-sm-right d-inline-flex">
          <em class="material-icons">add</em>
          Thêm mới
        </a>
      </div>
    </div>
  </div>
</div>
<section class="content">
  <div class="container-fluid">
    <div class="bg-white">            
      <section>
        <div id="table-scroll" class="table table-scroll table-responsive">
          <table id="main-table" class="table main-table border table-striped">
            <thead class="bg-light">
              <tr>
                <th>TT</th>
                <th>Loại tin</th>
                <th>Chức năng</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of blogTypes; index as i">
                <td>{{(i+1)}}</td>
                <td>{{item.name}}</td>
                <td>
                  <div class="dropdown">
                    <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <em class="material-icons">more_vert</em>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <a class="dropdown-item" [routerLink]="['/console/landing-page/blog-type/edit/',item.id]" routerLinkActive="router-link-active"  >
                        <em class="material-icons">autorenew</em>
                        Chỉnh sửa
                      </a>
                      <a class="dropdown-item" (click)="onDeleteClick(item.id)">
                        <em class="material-icons-outlined">delete_outline</em>
                        Xóa
                      </a>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>            
          </table>
        </div>
      </section>
    </div>
    <div class="d-flex justify-content-end mt-3">
      <span *ngIf="totalElement == 0" class="flex-grow-1 ml-1">Không tìm thấy kết quả</span>
      <span *ngIf="totalElement > 0" class="flex-grow-1 ml-1">Hiển thị 1 đến {{ blogTypes.length }} trong tổng số
          {{ totalElement }} kết quả</span>      
  </div>
  </div>
</section>
