<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col">
        <h1 class="m-0 text-dark"><label>Thông báo</label></h1>
      </div>
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>

<section class="content">
  <div class="container-fluid">

    <div class="tab-bar-custom">
      <ul class="nav nav-tabs" id="tabNotiAccount" role="tablist">
        <li *ngIf="false" class="nav-item nav-material">
          <a class="nav-link active d-flex align-items-center" id="ott-tab" data-toggle="pill" href="#OTTnoti"
            role="tab" aria-controls="ott" aria-selected="true"><span class="material-icons mr-2">
              group
            </span>Thông báo OTT</a>
        </li>
        <li class="nav-item nav-material">
          <a class="nav-link active d-flex align-items-center" id="noti-user-tab" data-toggle="pill" href="#notiForUser"
            role="tab" aria-controls="noti-user" aria-selected="false"><span class="material-icons mr-2">
              group
            </span>Thông báo cho khách hàng</a>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div *ngIf="false" class="tab-pane fade show active" id="OTTnoti" role="tabpanel" aria-labelledby="ott">
          <!-- <label class="text-primary">Nhận thông báo qua kênh OTTs</label>
            <hr /> -->
          <div class="bg-white p-3">
            <ul class="list-unstyled">
              <li class="mb-3">
                <p>
                  <strong>Bước 1: </strong>Truy cập đường dẫn:
                  <a href="https://t.me/VNPT_eContract_bot" target="_blank" class="text-decoration-underline">https://t.me/VNPT_eContract_bot</a> hoặc Quét mã QR kênh bạn muốn nhận
                  thông báo:
                </p>
                <p>(Click vào mã QR để phóng to)</p>
                <div class="d-flex flex-column align-items-start">
                  <img
                    src="../../../../../../assets/img/photo_2022-07-19_11-01-32.jpg"
                    height="80px" width="80px" alt="telegram" />
                  <div class="d-flex flex-column align-items-center">
                    <p>Telegram</p>
                    <p class="text-success">(Đã đăng ký)</p>
                  </div>
                </div>
              </li>
              <li>
                <p>
                  <strong>Bước 2: </strong>Nhắn tin theo cú pháp sau trên ứng dụng
                  Telegram:
                </p>
                <ul class="pl-4">
                  <li class="mb-3">
                    <p>Đăng ký: <strong>DK aiaexchange 3917129574</strong></p>
                    <img
                      src="../../../../../../assets/img/photo_2022-07-19_10-52-02.jpg"
                      height="250px" width="116px" alt="" />
                  </li>
                  <li>
                    <p>
                      Cú pháp hủy nhận thông báo: <strong>HUY VNPTIT_IT01223</strong>
                    </p>
                  </li>
                </ul>
              </li>
            </ul>
          </div>

        </div>
        <div class="tab-pane fade show active" id="notiForUser" role="tabpanel" aria-labelledby="noti-user">
          <div class="bg-white p-3 container-fluid">
            <div class="row">
              <div class="col-md-5">
                <label for="">Thông báo cho khách hàng</label>
                <p>
                  Vui lòng chọn hình thức nhận thông báo cho khách hàng của bạn
                </p>
              </div>
              <div class="col-md-7">
                <div class="rcheck-primary">
                  <input type="radio" name="type" id="rr0" [(ngModel)]="notiType" value="EMAIL">
                  <label for="rr0" class="font-weight-bold" (click)="selectMethod('email')">
                    Email
                    <div class="font-weight-normal">
                      <label>Không phát sinh chi phí</label>
                      <label>Các đối tác tham gia ký kết hợp đồng phải có thông tin email</label>
                    </div>
                  </label>

                </div>
                <div class="rcheck-primary">
                  <input type="radio" name="type" id="rr1" [(ngModel)]="notiType" value="SMS">
                  <label for="rr1" class="font-weight-bold" (click)="selectMethod('sms')">
                    SMS
                    <div class="font-weight-normal">
                      <label>Phát sinh chi phí SMS tính theo sản lượng sử dụng</label>
                      <label>Chỉ yêu cầu thông tin số điện thoại của các đối tác tham gia ký kết hợp đồng</label>
                    </div>
                  </label>

                </div>
                
                <div class="rcheck-primary">
                  <input type="radio" name="type" id="rr2" [(ngModel)]="notiType" value="BOTH">
                  <label for="rr2" class="font-weight-bold" (click)="selectMethod('both')">
                    Email và SMS
                    <div class="font-weight-normal">
                      <label>Sử dụng cả 2 hình thức nhận thông báo</label>
                    </div>
                  </label>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <ul class="nav nav-tabs" id="tabSignAction" role="tablist">
        <li *ngIf="controlAccount.isAccLevel2" class="nav-item nav-material" [class.active]="controlAccount.isAccLevel2">
            <a class="nav-link d-flex" [class.active]="controlAccount.isAccLevel2" id="all-tab" data-toggle="pill"
                href="#all" role="tab" aria-controls="all" [attr.aria-selected]="controlAccount.isAccLevel2" (click)="selectTab(1)">
                Hợp đồng gửi
            </a>
        </li>
        <li class="nav-item nav-material" >
            <a class="nav-link d-flex" id="waitingForSign-tab" data-toggle="pill" href="#waitingForSign" role="tab"
                aria-controls="waitingForSign" aaria-selected="false" (click)="selectTab(2)">
                Hợp đồng nhận
            </a>
        </li>
    </ul> -->
  </div>
</section>