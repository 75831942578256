import { Component, OnInit } from '@angular/core';
import { HomeService } from 'src/app/utils/services/home/home.service';

@Component({
  selector: 'app-register-for-consumer',
  templateUrl: './register-for-consumer.component.html',
  styleUrls: ['./register-for-consumer.component.scss']
})
export class RegisterForConsumerComponent implements OnInit {

  constructor(
    private homeService: HomeService
  ) { }

  data: any = null;

  ngOnInit(): void {
    this.getData();
  }

  getData() {
    this.homeService.getDataByCodeCategory("esolManual", "vi").subscribe((res: any) => {
      this.data = res?.object?.item || {};
    });
  }

}
