<div class="h-100">
    <div class="card shadow-none">
        <div class="card-body p-3">
            <div class="row">
                <div class="col-md-auto">
                    <label class="grey-label">Hợp đồng theo thời gian</label><br>
    
                </div>
                <div class="col-md-auto ml-auto">
                    <ng-select [formControl]="yearForm" [items]="years" bindLabel="label" bindValue="value"
                        [clearable]="false" [searchable]="false" class="ng-select-year" (change)="changeYear($event)">
                        <ng-template ng-label-tmp let-item="item">
                            <span class="year-label"> {{ item.label }}</span>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item">
                            <span class="year-label"> {{ item.label }}</span>
                        </ng-template>
                    </ng-select>
                </div>
            </div>
            <div>
                <google-chart #chart [type]="type" [data]="data" [options]="options" style="width: 100%; height: 100%;"
                [dynamicResize]="dynamicResize">
                </google-chart>
    
            </div>
  
        </div>
    </div>
</div>
