import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { AccountManagerService } from "src/app/utils/services/aas-network/account-manager.service";
import { OrganizationDetailObject } from "src/app/utils/models/aas-models/account-manager/organization-manager/detail/organization-detail-object";
import { OrganizationDetailResponse } from "src/app/utils/models/aas-models/account-manager/organization-manager/detail/organization-detail-response";
import { localize } from "src/app/utils/localize/localize";
import { AasAuthService } from "src/app/utils/services/aas-network/aas-auth.service";
import { InfoPartyObject } from "src/app/utils/models/aas-models/info-party/info-party-object";
import { AlertControl } from "src/app/utils/alert/alert-control";

@Component({
  selector: 'app-service-organization-detail',
  templateUrl: './service-organization-detail.component.html',
  styleUrls: ['./service-organization-detail.component.scss']
})
export class ServiceOrganizationDetailComponent implements OnInit {

  partyId = this.router.snapshot.paramMap.get("id");
  personalDetailObject : InfoPartyObject;
  personalDetailResponse: OrganizationDetailResponse;

  localize = localize;
  fileGPKD = null;
  showPreviewGPKD = false;

  constructor(
    private router: ActivatedRoute,
    private accountManagerService: AccountManagerService,
    private aasAuth: AasAuthService,
    private myAlert: AlertControl,
    private route: Router
  ) {}

  ngOnInit(): void {
    // this.detailEnterprise();
  }

  detailEnterprise() {
    this.accountManagerService.getOrganizationById(this.partyId).subscribe(
      (res: any) => {
        this.personalDetailObject =  new InfoPartyObject(res?.object);
      },
      (err) => {
        this.myAlert.showErrorHandled(err);
      }
    );
  }

  actDownloadGPKD(id) {
    this.downloadGPKD(id);
  }

  handleClosePreview() {
    this.showPreviewGPKD = false;
  }

  /**
   * Tai file GPKD
   * @param partyId 
   */
  downloadGPKD(partyId) {
    this.aasAuth.downloadGPKD(partyId).subscribe((res: any) => {
      this.fileGPKD = new Blob([res], { type: 'application/pdf' });
      this.showPreviewGPKD = true;
    }, err => {

    })
  }

  // Move to assign-to-enduser.component.ts
  moveToAssignPackageScreen() {
    this.route.navigate(
      [`/console/package-manager/assign/${this.partyId}`],
      { queryParams: { 'userType': 'organization' } }
    );
  }
}
