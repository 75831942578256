import { Component, OnInit, AfterViewInit, Renderer2, ViewChild, ElementRef, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/utils/services/auth.service';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { AasContractService } from 'src/app/utils/services/aas-network/aas-contract.service';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { ContractListItem } from 'src/app/utils/models/aas-models/contract/contract-list/contract-list-item.model';
import { ContractListResponse } from 'src/app/utils/models/aas-models/contract/contract-list/contract-list-response.model';
import { ContractStatus } from 'src/app/utils/common/constant';
import { DateUtils } from 'src/app/utils/common/DateUtils';
import { AasValidateMessage } from 'src/app/utils/common/validate-msg-aas';
import { Router } from '@angular/router';

declare var Cleave: any;
declare var $: any;
@Component({
  selector: 'app-service-contract-list',
  templateUrl: './service-contract-list.component.html',
  styleUrls: ['./service-contract-list.component.scss']
})
export class ServiceContractListComponent implements OnInit, AfterViewInit {
  @Input('by') _by: String = 'OWNER';

  @ViewChild('fromDatePicker') _fromDate: ElementRef;
  @ViewChild('toDatePicker') _toDate: ElementRef;
  @ViewChild('customerTypePicker') _customerType: ElementRef;
  @ViewChild('statusPicker') _statusPicker: ElementRef;
  @ViewChild('contentCancel') contentCancel: ElementRef;

  formCancel: FormGroup;
  validMsgCancel = AasValidateMessage.CANCEL_CONTRACT;
  isCancelSubmit: boolean = false;
  contractIdSelected: string =  '';

  partyId: string = this.auth.partyId;
  userId: string = this.auth.userId;

  formSearch: FormGroup;
  formFilter: FormGroup;

  currentDate = new Date();
  contractStatus: string = 'ALL';

  listConfigColTable: Array<any> = [
    {
      label: "Trạng thái",
      name: "status"
    },
    {
      label: "Đơn vị quản lý",
      name: "donViQuanLy"
    },
    {
      label: "Ngày chỉnh sửa",
      name: "ngayChinhSua"
    },
    {
      label: "Ngày khởi tạo",
      name: "ngayKhoiTao"
    },
    {
      label: "Email",
      name: "nguoiPheDuyet"
    },
    // {
    //   label: "Người khởi tạo",
    //   name: "nguoiKhoiTao"
    // }
  ];
  configColTable: any = {
    status: true,
    donViQuanLy: true,
    ngayChinhSua: true,
    ngayKhoiTao: false,
    nguoiPheDuyet: true,
    nguoiKhoiTao: false
  }

  listFitler: Array<any> = [
    // {
    //   label: "Đơn vị quản lý",
    //   name: "donViQuanLy"
    // },
    // {
    //   label: "Loại hợp đồng",
    //   name: "loaiHopDong"
    // },
    {
      label: "Loại khách hàng",
      name: "loaiKhachHang"
    },
    {
      label: "Từ ngày",
      name: "tuNgay"
    },
    {
      label: "Đến ngày",
      name: "denNgay"
    },
    {
      label: "Trạng thái",
      name: "trangThai"
    },
    // {
    //   label: "Người phê quyệt",
    //   name: "nguoiPheDuyet"
    // }
  ];
  configFilter: any = {
    donViQuanLy: true,
    loaiHopDong: true,
    loaiKhachHang: true,
    tuNgay: true,
    denNgay: true,
    nguoiPheDuyet: false,
    trangThai: true
  }

  page: number = 1;
  maxSize: number = 10;
  sort: string = "DESC";
  propertiesSort: string = "modified";
  totalElement: number = 0;
  status: string = '';
  listContract: Array<ContractListItem> = [];
  currentStage = ContractStatus;

  partnerId: string = '';
  serviceId: string = '';
  fromDate: string = '';
  toDate: string = '';
  customerType: string = "";

  customerTypeFilter: string = '';
  fromDateFilter: string = '';
  toDateFilter: string = '';
  saveKeySearch: string = '';
  isSubmit: boolean = false;
  ServiceMsg: any = AasValidateMessage.CONTRACT_MANAGER_FILTER;

  selectedAll: boolean = false;

  zIndex = 5;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private aasAuth: AasAuthService,
    private aasContract: AasContractService,
    private modalService: NgbModal,
    private renderer2: Renderer2,
    private router: Router
  ) {
    this.renderer2.listen('window', 'click', (e: Event) => {
      var all = document.getElementsByClassName('table-sticky-col2');
      for (var i = 0; i < all.length; i++) {
        all[i].removeAttribute('style');
      }
    });
  }

  ngOnInit(): void {
    this.makeFormSearch();
    this.makeFormFilter();
    this.makeFormCancel();

    this.getSessionFilter();
    this.loadData()
  }

  ngAfterViewInit(): void {
    this.makeAutoFormatDate();

    this._fromDate.nativeElement.value = sessionStorage.getItem('aas-contract-from-date-' + this._by) || '';
    this._toDate.nativeElement.value = sessionStorage.getItem('aas-contract-to-date-' + this._by) || '';
    this._customerType.nativeElement.value = sessionStorage.getItem('aas-contract-customer-type-' + this._by) || '';
    this._statusPicker.nativeElement.value = sessionStorage.getItem('aas-contract-status-' + this._by) || '';
  }

  //MARK: khởi tạo form huỷ
  makeFormCancel() {
    this.formCancel = this.fb.group({
      reason: new FormControl('')
    });
  }

  get reason() { return this.formCancel.get('reason'); }

  //MARK: Make input date format
  makeAutoFormatDate() {
    let self = this;
    let idFromDate = '#fromDateReport-' + this._by;
    new Cleave(idFromDate, {
      date: true,
      delimiter: '/',
      datePattern: ['d', 'm', 'Y'],
      onValueChanged: function (e) {
        self.fromDate = e.target.value;
        self.f_fromDate.setValue(e.target.value);
      }
    });

    let idToDate = '#toDateReport-' + this._by;
    new Cleave(idToDate, {
      date: true,
      delimiter: '/',
      datePattern: ['d', 'm', 'Y'],
      onValueChanged: function (e) {
        self.toDate = e.target.value;
        self.f_toDate.setValue(e.target.value);
      }
    });
  }

  //MARK: GET session filter
  getSessionFilter() {
    this.keySearch.setValue(sessionStorage.getItem('aas-contract-key-search-' + this._by) || '');
    this.fromDate = sessionStorage.getItem('aas-contract-from-date-' + this._by) || '';
    this.toDate = sessionStorage.getItem('aas-contract-to-date-' + this._by) || '';
    this.customerType = sessionStorage.getItem('aas-contract-customer-type-' + this._by) || '';
    this.status = sessionStorage.getItem('aas-contract-status-' + this._by) || '';
  }

  //MARK: Khởi tạo form search
  makeFormSearch() {
    this.formSearch = this.fb.group({
      search: new FormControl('')
    });
  }
  get keySearch() { return this.formSearch.get('search'); }

  makeFormFilter() {
    this.formFilter = this.fb.group({
      toDate: new FormControl('', [Validators.pattern(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/)]),
      fromDate: new FormControl('', [Validators.pattern(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/)])
    });
  }

  get f_toDate() { return this.formFilter.get('toDate'); };
  get f_fromDate() { return this.formFilter.get('fromDate'); };

  //MARK: Chức năg tìm kiếm
  eventInputSearch(event) {

  }

  actSearch(event) {
    this.page = 1;
    this.saveKeySearch = this.keySearch.value.trim();
    sessionStorage.setItem('aas-contract-key-search-' + this._by, this.saveKeySearch);
    this.loadData();
  }

  //MARK: Chức năng lọc dữ liệu
  changeTtkdId(event) {

  }

  changeTypeId(event) {

  }

  changeCustomerType(event) {
    this.customerType = event.target.value;
    console.log(this.customerType);
  }

  dateInput(e, type) {
    if (e.target.value.length > 10) {
      var str = e.target.value;
      e.target.value = str.substring(0, str.length - 1);
      if (type === 'fromDate') {
        this.fromDate = e.target.value;
        this.f_fromDate.setValue(e.target.value);
      } else if (type === 'toDate') {
        this.toDate = e.target.value;
        this.f_toDate.setValue(e.target.value);
      }
    } else {
      if (type === 'fromDate') {
        this.fromDate = e.target.value;
        this.f_fromDate.setValue(e.target.value);
      } else if (type === 'toDate') {
        this.toDate = e.target.value;
        this.f_toDate.setValue(e.target.value);
      }
    }
  }

  changeDate(event, type) {
    if (type == 'to') this.toDate = DateUtils.convertObjtoDate(event);
    else this.fromDate = DateUtils.convertObjtoDate(event);
  }

  changeStatus(e) {
    this.status = e.target.value;
  }

  //MARK: Apply filter
  searchWithFilter() {
    this.isSubmit = true;
    this.page = 1;

    if (this.formFilter.valid) {
      let dateFrom = this.fromDate.length > 0 ? new Date(DateUtils.convertStringtoDateSearch(this.fromDate)).getTime() : -1;
      let dateTo = this.toDate.length > 0 ? new Date(DateUtils.convertStringtoDateSearch(this.toDate)).getTime() : -1;
      // console.log('from => ', dateFrom, ' to => ', dateTo);
      if ((dateTo > 0 && dateFrom > 0 && dateFrom > dateTo)) {
        this.f_fromDate.setErrors({ minDate: true });
      }
    }

    if (this.formFilter.valid) {
      sessionStorage.setItem('aas-contract-from-date-' + this._by, this.fromDate);
      sessionStorage.setItem('aas-contract-to-date-' + this._by, this.toDate);
      sessionStorage.setItem('aas-contract-status-' + this._by, this.status);
      sessionStorage.setItem('aas-contract-customer-type-' + this._by, this.customerType);
      this.loadData();
    }
  }

  cleanFilter() {
    this.isSubmit = false;
    this._fromDate.nativeElement.value = '';
    this._toDate.nativeElement.value = '';
    this._customerType.nativeElement.value = '';
    this.fromDate = '';
    this.f_fromDate.setValue('');
    this.toDate = '';
    this.f_toDate.setValue('');
    this.status = '';
    this.customerType = '';
    this.searchWithFilter()
  }

  //MARK: Loadpage
  loadData() {
    this.getListContact(
      this.userId,
      this.partnerId,
      this.serviceId,
      DateUtils.convertInputToFormat(this.fromDate, DateUtils.format.API),
      DateUtils.convertInputToFormat(this.toDate, DateUtils.format.API),
      this.status,
      this.keySearch.value,
      this.page,
      this.maxSize,
      this.sort,
      this.propertiesSort);
  }

  loadPage(page) {
    this.page = page;
    this.getListContact(
      this.userId,
      this.partnerId,
      this.serviceId,
      DateUtils.convertInputToFormat(this.fromDate, DateUtils.format.API),
      DateUtils.convertInputToFormat(this.toDate, DateUtils.format.API),
      this.status,
      this.keySearch.value,
      this.page,
      this.maxSize,
      this.sort,
      this.propertiesSort);
  }

  //MARK: Action cấu hình hiển thị các hình thức filter
  actConfigFilter(e, field) {
    e.stopPropagation();
    if (this.configFilter[field] !== null && this.configFilter[field] !== undefined) {
      this.configFilter[field] = !this.configFilter[field];
    }
  }

  //MARK: Action cấu hình hiển thị cột của bảng
  actConfigColTableView(e, field) {
    e.stopPropagation();
    if (this.configColTable[field] !== null && this.configColTable[field] !== undefined) {
      this.configColTable[field] = !this.configColTable[field];
    }
  }

  //MARK: Action dropdown từng dòng
  openDropdown(event) {
    console.log(event);
    // var all = document.getElementsByClassName('table-sticky-col2');
    // for (var i = 0; i < all.length; i++) {
    //   all[i].removeAttribute('style');
    // }

    event.path[3].setAttribute("style", "z-index: " + ++this.zIndex + ";");
    // event.path[3].children[1].classList.add('show');
  }

  //MARK: Chọn dòng
  selectRow(event, id) {
    if (event.toElement.classList.contains('checked')) {
      event.toElement.classList.remove('checked');
      this.selectedAll = false;
    } else {
      event.toElement.classList.add('checked');
    }
  }

  //MARK: Chọn tất cả các dòng
  selectAll(event) {
    this.selectedAll = !this.selectedAll;

    this.listContract.forEach((item, index) => {
      var el = document.getElementById('label-for-contract-' + item.contractId);
      this.selectedAll ? el.classList.add('checked') : el.classList.remove('checked');
    })
  }

  //Xem chi tiet
  goToDetailContract(id) {
    this.router.navigate(['/app/console/contract', id]);
  }

  //Ký hợp đồng
  goToSign(id) {
    this.router.navigate(['/app/console/contract', id], { queryParams: { 'action': 'SIGN' } })
  }

  //downloadFileContract
  getFileContract(id, status) {
    if (status === 'LC_CONTRACT_VALID') {
      this.downloadFileContract(id, 'CONTRACT');
    } else {
      this.downloadFileContract(id, 'DRAFT');
    }
  }

  //MARK: CHUC NANG HUY HOP DONG
  //Show popup confirm OTP
  openModalCancel(content, contractId) {
    this.contractIdSelected = contractId;
    this.reason.setValue('');
    this.modalService.open(content, {
      size: 'md',
      backdrop: 'static',
      keyboard: false
    });
  }

  //Submit huy hop dong
  submitCancel() {
    this.isCancelSubmit = true;
    console.log(this.formCancel.value);
    if (this.formCancel.valid) {
      let param = {
        "cancelReason": this.reason.value
      }
      this.postCancelContract(this.contractIdSelected, param);
    }
  }

  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], 'error');
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, 'error');
    } else {
      this.alert('Lỗi không xác định!', 'error');
    }
  }

  //MARK: NETWORKING
  getListContact(partyId, partnerId, serviceId, fromDate, toDate, status, keySearch, page, maxSize, sort, propertiesSort) {

    if (this._by === 'OWNER') {
      this.getListContactByOwner(
        this.userId,
        this.partnerId,
        this.customerType,
        this.serviceId,
        DateUtils.convertInputToFormat(this.fromDate, DateUtils.format.API),
        DateUtils.convertInputToFormat(this.toDate, DateUtils.format.API),
        this.status,
        this.keySearch.value,
        this.page,
        this.maxSize,
        this.sort,
        this.propertiesSort);
    } else if (this._by === 'ASSIGN') {
      this.getListContactByAssign(
        this.userId,
        this.partnerId,
        this.customerType,
        this.serviceId,
        DateUtils.convertInputToFormat(this.fromDate, DateUtils.format.API),
        DateUtils.convertInputToFormat(this.toDate, DateUtils.format.API),
        this.status,
        this.keySearch.value,
        this.page,
        this.maxSize,
        this.sort,
        this.propertiesSort);
    } else {
      this.aasContract.getListContact(partyId, partnerId, serviceId, fromDate, toDate, status, keySearch, page, maxSize, sort, propertiesSort).subscribe(res => {
        var response = new ContractListResponse(res);

        this.listContract = response.object.data;
        // this.listContract.push(this.listContract[0]);
        // this.listContract.push(this.listContract[0]);
        // this.listContract.push(this.listContract[0]);
        // this.listContract.push(this.listContract[0]);

        this.totalElement = response.object.totalElement;

      }, err => {
        console.log(err);
        this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
      });
    }
  }

  getListContactByOwner(partyId, partnerId, partnerType, serviceId, fromDate, toDate, status, keySearch, page, maxSize, sort, propertiesSort) {
    this.aasContract.getListContactByOwner(partyId, partnerId, partnerType, serviceId, fromDate, toDate, status, keySearch, page, maxSize, sort, propertiesSort).subscribe(res => {
      var response = new ContractListResponse(res);

      this.listContract = response.object.data;

      this.totalElement = response.object.totalElement;

    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    });
  }

  getListContactByAssign(partyId, partnerId, partnerType, serviceId, fromDate, toDate, status, keySearch, page, maxSize, sort, propertiesSort) {
    this.aasContract.getListContactByAssign(partyId, partnerId, partnerType, serviceId, fromDate, toDate, status, keySearch, page, maxSize, sort, propertiesSort).subscribe(res => {
      var response = new ContractListResponse(res);

      this.listContract = response.object.data;

      this.totalElement = response.object.totalElement;

    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    });
  }

  //Tải file hợp đồng 
  downloadFileContract(id, type) {
    this.aasContract.downloadFileContract(id, type).subscribe((res) => {
      const blob: any = new Blob([res], { type: 'application/pdf' });
      var url = window.URL.createObjectURL(blob);
      var anchor = document.createElement("a");
      anchor.download = `${id}.pdf`;
      anchor.href = url;
      anchor.click();
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    })
  }

  //Huỷ hợp đồng
  postCancelContract(contractId, param) {
    this.aasContract.postCancelContract(contractId, param).subscribe(res => {
      this.loadData();
      this.alert('Huỷ hợp đồng thành công!', 'success', false, '', 'OK', () => {
        this.modalService.dismissAll();
      });
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    })
  }
}
