export const localize_package_manager = {
    package_confirm_delete: "Bạn có chắc chắn xóa gói dịch vụ này?",
    package_cannot_delete_using: "Bạn không thể xóa gói dịch vụ đã có khách hàng sử dụng. Vui lòng khóa gói dịch vụ này nếu cần",
    package_delete_success: "Xóa dịch vụ thành công!",
    catalog_package_confirm_create: "Bạn chắc chắn muốn khởi tạo danh mục dịch vụ này?",
    catalog_package_create_success: "Tạo danh mục dịch vụ thành công!",
    catalog_package_cancel_edit: "Bạn chắc chắn muốn hủy phiên khởi tạo của danh mục dịch vụ này? <br /> (Toàn bộ thông tin đã nhập sẽ bị mất hết)",
    package_service_list: "Danh sách danh mục dịch vụ",
    add_new: "Thêm mới",
    set_again: "Đặt lại",
    apply: "Áp dụng",
    status: "Trạng thái",
    select_status: "Chọn trạng thái",
    active: "Đang hoạt động",
    deactive: "Ngưng hoạt động",
    all: "Tất cả",
    no_result_were_found: "Không tìm thấy kết quả",
    display_from_to_in_total_result: "Hiển thị {start} đến {end} trong tổng số {total} kết quả",
    number_of_lines_displayed: "Số dòng hiển thị",
    head: "Đầu",
    last: "Cuối",
    infrastructure: "Hạ tầng",
    feature: "Chức năng",
    catalog_type: "Loại danh mục",
    select_catalog_type: "Chọn loại danh mục dịch vụ",
    catalog_name: "Tên danh mục",
    action: "Thao tác",
    add_service_catalog: "Thêm mới danh mục dịch vụ",
    general_infomation: "Thông tin chung",
    catalog_name_placeholder: "Nhập tên danh mục",
    required_catalog_name: "Vui lòng nhập tên danh mục!",
    required_service_type: "Vui lòng chọn loại dịch vụ!",
    parameter: "Thông số",
    unit: "Đơn vị tính",
    quantity: "Số lượng",
    unit_price: "Đơn giá",
    into_money: "Thành tiền",
    permission_information: "Thông tin phân quyền",
    infrastructure_information: "Thông tin hạ tầng",
    confirm_create_service_catalog: "Bạn chắc chắn muốn thêm mới danh mục dịch vụ này?",
    create_service_catalog_success: "Danh mục dịch vụ đã được thêm mới thành công!",
    edit_service_catalog: "Chỉnh sửa danh mục dịch vụ",
    confirm_edit_service_catalog: "Bạn chắc chắn muốn cập nhật thông tin danh mục dịch vụ này?",
    edit_service_catalog_success: "Danh mục dịch vụ đã được cập nhật thành công!",
    service_catalog_info: "Thông tin danh mục dịch vụ",
    confirm_delete_service_catalog: "Bạn chắc chắn muốn xóa danh mục dịch vụ này?",
    delete_service_catalog_success: "Danh mục dịch vụ đã được xóa thành công!",
    confirm_add_package_service: "Bạn chắc chắn muốn thêm mới gói dịch vụ này?",
    success_add_package_service: "Tạo gói dịch vụ thành công!",
    title_detail_package_service: "Thông tin gói dịch vụ",
    confirm_update_package_service: "Bạn chắc chắn muốn cập nhật thông tin gói dịch vụ này?",
    success_update_package_service: "Cập nhật thông tin gói dịch vụ thành công!",
}