import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BusinessPackageService } from 'src/app/utils/services/aas-network/business-package.service';
import { AuthService } from 'src/app/utils/services/auth.service';

@Component({
  selector: 'app-enduser-package-detail-v2',
  templateUrl: './enduser-package-detail-v2.component.html',
  styleUrls: ['./enduser-package-detail-v2.component.scss']
})
export class EnduserPackageDetailV2Component implements OnInit {

  componentName: string = "EnduserPackageDetailV2";

  constructor(
    private packageService: BusinessPackageService,
    private auth: AuthService,
    private translate: TranslateService
  ) { }

  detail: any = {};
  listService: Array<any> = new Array();
  listPackageInfra: Array<any> = new Array();

  history: Array<any> = new Array();

  sortColConfig = {
    thongSo: null,
    donViTinh: null,
    soLuong: null,
    trangThai: null,
  }

  ngOnInit(): void {
    this.getDetailEnduserPackage();
  }

  // Get detail information of enduser package.
  getDetailEnduserPackage() {
    this.packageService.getDetailEnduserPackage().subscribe((res: any) => {
      const object = res?.object || {};
      // Get package info
      this.detail = object?.packageInfo || {};
      // Get extend infra history if package is not trial type.
      if (this.detail?.packageType != 'TRIAL_TYPE') {
        this.getUpgradeHistory();
      }
      // Build list service tree.
      this.listService = [];
      object?.listPackageService.forEach(content => {
        content.privilegesHidden = true;
        this.listService.push(content);
      });
      // Build list package infrastructure.
      // object?.listPackageInfrastructure.forEach(content => {
      //   content.used = 0;
      //   content.allowed = 0;
      //   content.rate = 0;
      //   this.listPackageInfra.push(content);
      // });
      this.listPackageInfra = object.listPackageInfrastructure ?? [];

      this.packageService.getQuantityInfo(this.auth.partyId).subscribe((res2: any) => {
        const object2 = res2?.object || {};

        this.listPackageInfra.forEach((content) => {
          switch (content.param) {
            case "contractNumber":
              this.setPackageInfraItem(content, object2.usedContract, object2.allowedContract);
              break;
            case "SMS_OTP":
              this.setPackageInfraItem(content, object2.usedSMSOTP, object2.allowedSMSOTP);
              break;
            case "EKYC":
              this.setPackageInfraItem(content, object2.usedeKYC, object2.allowedeKYC);
              break;
            case "SMS":
              this.setPackageInfraItem(content, object2.usedSMS, object2.allowedSMS);
              break;
            case "STORAGE_TIME":
              this.setPackageInfraItem(content, object2.numberOfMonthStorageUsed, object2.numberOfMonthStorage);
              break;
            case "STORAGE_CAPACITY":
              this.setPackageInfraItem(content, object2.usedData, object2.allowedData);
              break;
          }
        });

        // // 1st
        // this.listPackageInfra[0].used = object?.usedContract || 0;
        // this.listPackageInfra[0].allowed = object?.allowedContract || 0;

        // // 2nd
        // this.listPackageInfra[1].used = object?.usedSMSOTP || 0;
        // this.listPackageInfra[1].allowed = object?.allowedSMSOTP || 0;

        // // 3rd
        // this.listPackageInfra[2].used = object?.usedeKYC || 0;
        // this.listPackageInfra[2].allowed = object?.allowedeKYC || 0;

        // this.listPackageInfra[3].used = object?.usedSMS || 0;
        // this.listPackageInfra[3].allowed = object?.allowedSMS || 0;

        // // 4th
        // this.listPackageInfra[4].used = object?.numberOfMonthStorageUsed || 0;
        // this.listPackageInfra[4].allowed = object?.numberOfMonthStorage || 0;

        // // 5th
        // this.listPackageInfra[5].used = object?.usedData || 0;
        // this.listPackageInfra[5].allowed = object?.allowedData || 0;

        // Sort list infra.
        this.listPackageInfra.sort((a, b) => (a.stt > b.stt ? 1 : -1));

        // this.listPackageInfra.forEach((content, index) => {
        //   if (content.allowed != 0) {
        //     content.rate = Math.round((this.listPackageInfra[index].used / this.listPackageInfra[index].allowed) * 100);
        //   }
        //   else {
        //     content.rate = 0;
        //   }
        // });
      });
    });
  }

  /**
   * Put used and allowed value into corresponding infra item
   * then calculate rate of used.
   * @param infraItem 
   * @param used 
   * @param allowed 
   */
  setPackageInfraItem(infraItem: any, used: any, allowed: any) {
    infraItem.used = used ?? 0;
    infraItem.allowed = allowed ?? 0;
    if (infraItem.allowed != 0) {
      infraItem.rate = Math.round((infraItem.used / infraItem.allowed) * 100);
    }
    else {
      infraItem.rate = 0;
    }
  }

  // Get upgrade infrastructure history
  getUpgradeHistory() {
    this.packageService.getUpgradeHistory().subscribe((res: any) => {
      this.history = res?.object || [];
      this.history = this.history.filter(item => {
        return item.packageType === "EXTEND_TYPE";
      });
      this.history.reverse();
      this.history.forEach((content) => {
        content?.infrastructures.sort((a, b) => (a.stt > b.stt ? 1 : -1));
      });
    });
  }

  /**
   * Hiển thị giá tiền theo format
   * @param amount 
   * @returns 
   */
  formatPrice(amount) {
    const number: Number = new Number(amount);
    const amountStr: string = number.toString();
    let amountArr: Array<string> = amountStr.split('');
    let count: number = 0;
    for (let i = amountArr.length - 1; i >= 0; i--) {
      count++;
      if (count == 3) {
        amountArr.splice(i, 0, ',');
        count = 0;
      }
    }
    const res: string = amountArr.join("");
    return res;
  }

  toggleService(i) {
    this.listService[i].privilegesHidden = !this.listService[i].privilegesHidden;
  }

  getStyleOfRate(rate) {
    if (rate < 50) {
      return "success-color";
    }
    else if (rate >= 50 && rate <= 75) {
      return "warning-color";
    }
    else if (rate > 75) {
      return "danger-color";
    }
  }

  getStyleAndValueBadge(status) {
    if (status == "ENABLED") {
      return ['badge-success-new', this.translate.instant('status_running')];
    }
    return ['badge-primary-new', this.translate.instant('status_stopped')];
  }

  sortCol(key) {
    if (this.sortColConfig[key] == null) {
      this.sortColConfig[key] = true;
    } else if (this.sortColConfig[key] == false) {
      this.sortColConfig[key] = null;
    } else {
      this.sortColConfig[key] = !this.sortColConfig[key];
    }
  }
}
