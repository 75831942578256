import { Injectable } from '@angular/core';
import { ServicePath } from '../../common/constant-service-api';
import { API } from '../api';
const LIST_PERSONAL_ACC_URL = ServicePath.MANAGEMENT_SERVICE + '/user-informations/user-list';
const DETAIL_PERSONAL_URL = ServicePath.MANAGEMENT_SERVICE + '/v2/user-informations';
const UPDATE_PERSONAL_URL = ServicePath.MANAGEMENT_SERVICE + '/user-informations';

const LIST_ORGANIZATION_ACC_URL = ServicePath.MANAGEMENT_SERVICE + '/parties/party-list';
const DETAIL_ORGANIZATION_URL = ServicePath.MANAGEMENT_SERVICE + '/parties';

const DROPLIST_PACKAGE = ServicePath.MANAGEMENT_SERVICE + '/package-information/v1/list-view';
const ADD_ACCOUNT_CUSTOMER = ServicePath.MANAGEMENT_SERVICE + '/parties/create-partner-for-admin';

const DETAIL_PARTY = ServicePath.MANAGEMENT_SERVICE + '/parties';
const EDIT_ACCOUNT_CUSTOMER =
  ServicePath.MANAGEMENT_SERVICE + '/parties/{partyId}/update-partner-for-admin';
const GET_STOCK_PACKAGE = ServicePath.MANAGEMENT_SERVICE + '/licenses/package-info';
const USER_LIST_URL = ServicePath.MANAGEMENT_SERVICE + '/user-informations';
const RESEND_EMAIL = ServicePath.MANAGEMENT_SERVICE + '/parties';
const DISABLED_ACCOUNT = ServicePath.MANAGEMENT_SERVICE + '/parties/{partyId}/disable-customer-account';
const ENABLE_ACCOUNT = ServicePath.MANAGEMENT_SERVICE + '/parties/{partyId}/enable-customer-account';

const ADD_CONSUMER_TRUSTED_PARTNER =
  ServicePath.MANAGEMENT_SERVICE + '/trusted-partners/consumer/create';
const CONSUMER_TRUSTED_DETAIL = ServicePath.MANAGEMENT_SERVICE + '/trusted-partners/v1/consumer';
const EDIT_CONSUMER_TRUSTED_PARTNER= ServicePath.MANAGEMENT_SERVICE +'/trusted-partners/consumer'
const CHECK_PHONE_CONSUMER_TRUSTED_PARTNER= ServicePath.MANAGEMENT_SERVICE +'/trusted-partners/consumer/check-phone-number?phoneNumber='
const RESEND_INVITE= ServicePath.MANAGEMENT_SERVICE + '/trusted-partners/v1/resend-invite/'

@Injectable({
  providedIn: 'root',
})
export class AccountManagerService {
  constructor(private api: API) {}

  /**
   * Get list enterprise
   * @param page
   * @param maxSize
   * @param propertiesSort
   * @param sort
   * @param keySearch
   * @param userType
   */
  getListPersonalAcc(
    page,
    maxSize,
    propertiesSort,
    sort,
    keySearch = '',
    userType = '',
    identityLevel = '',
    locked = ''
  ) {
    const url =
      LIST_PERSONAL_ACC_URL +
      `?keySearch=${keySearch}&page=${page}&maxSize=${maxSize}&sort=${sort}&propertiesSort=${propertiesSort}&userType=${userType}&identityLevel=${identityLevel}&locked=${locked}`;
    return this.api.get(url);
  }

  /**
   * Get enterprise
   * @param id
   */
  getPersonalById(id) {
    const url = DETAIL_PERSONAL_URL + `/${id}`;
    return this.api.get(url);
  }

  /**
   * Update personal
   * @param data
   */
  postUpdatePerson(data) {
    return this.api.post(UPDATE_PERSONAL_URL + `/${data.partyId}/update-info-l1`, {
      username: data.username,
    });
  }

  // /**
  //  * POST create Employees
  //  * @param data
  //  */
  // postApproveEnterprise(partyId) {
  //   return this.api.post(REJECT_ENTERPRISE_URL + `/${partyId}/registration-approve`, null);
  // }

  /**
   * Get list Organzation account
   * @param page
   * @param maxSize
   * @param propertiesSort
   * @param sort
   * @param keySearch
   * @param userType
   */
  getListOrganizationAcc(
    page,
    maxSize,
    propertiesSort,
    sort,
    keySearch = '',
    userType = '',
    identityLevel = '',
    locked = ''
  ) {
    const url =
      LIST_ORGANIZATION_ACC_URL +
      `?keySearch=${keySearch}&page=${page}&maxSize=${maxSize}&sort=${sort}&propertiesSort=${propertiesSort}&userType=${userType}&identityLevel=${identityLevel}&locked=${locked}`;
    return this.api.get(url);
  }

  /**
   * Get organization
   * @param id
   */
  getOrganizationById(id) {
    const url = DETAIL_ORGANIZATION_URL + `/${id}`;
    return this.api.get(url);
  }

  /**
   * Update personal
   * @param data
   */
  postUpdateOrganization(partyId, data) {
    console.log(partyId);
    console.log(data);
    return this.api.post(DETAIL_ORGANIZATION_URL + `/${partyId}/update-info-l1`, data);
  }

  getDataPackage(page, maxSize, propertiesSort, packageType, status, userType) {
    const url =
      DROPLIST_PACKAGE +
      `?page=${page}&maxSize=${maxSize}&propertiesSort=${propertiesSort}&packageType=${packageType}&status=${status}&userType=${userType}`;
    return this.api.get(url);
  }

  postAddAccount(param) {
    const url = ADD_ACCOUNT_CUSTOMER;
    return this.api.postMultiPartJSON(url, param);
  }

  getDetailParty(id) {
    const url = DETAIL_PARTY + `/${id}`;
    return this.api.get(url);
  }

  postEditAccount(id, param) {
    const url = EDIT_ACCOUNT_CUSTOMER.replace('{partyId}', id);
    return this.api.postMultiPartJSON(url, param);
  }

  getStockPackage(partyId) {
    const url = GET_STOCK_PACKAGE + `?partyId=${partyId}`;
    return this.api.get(url);
  }

  /**
   * Tải ảnh người dùng cá nhân
   * @param id
   * @param type
   * @returns
   */
  downloadImage(id, type) {
    const url = USER_LIST_URL + `/${id}/download-asset?assetType=${type}`;
    return this.api.download(url);
  }

  reSendEmail(partyId) {
    const url = RESEND_EMAIL + `/${partyId}/send-email-reset-password`;
    return this.api.post(url, {});
  }

  /**
   * Ngưng hoạt động tài khoản khách hàng
   * @param partyId 
   * @returns 
   */
  disableAccount(partyId) {
    let url = DISABLED_ACCOUNT.replace("{partyId}", partyId);
    return this.api.post(url, null);
  }

  /**
   * Kích hoạt tài khoản 
   * @param partyId 
   * @returns 
   */
  enableAccount(partyId) {
    let url = ENABLE_ACCOUNT.replace("{partyId}", partyId);
    return this.api.post(url, null);
  }
  
  checkPhoneConsumerTrustedPartner(phone){
    const url= CHECK_PHONE_CONSUMER_TRUSTED_PARTNER + phone;
    return this.api.post(url, '')
  }
  postAddConsumerTrustedPartner(data) {
    const url = ADD_CONSUMER_TRUSTED_PARTNER;
    return this.api.postMultiPartJSON(url, data);
  }
  getConsumerTrustedPartnerDetails(id) {
    const url = CONSUMER_TRUSTED_DETAIL + `/${id}`;
    return this.api.get(url);
  }
  postEditConsumerTrustedPartner(id, data){
    const url= EDIT_CONSUMER_TRUSTED_PARTNER +`/${id}`
    return this.api.putMultiPart(url, data)
  }
  resendInvite(id){
    const url= RESEND_INVITE +`${id}`
    return this.api.post(url, '')
  }
}
