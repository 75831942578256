import { Component, OnInit, ViewChild, ElementRef,Renderer2,AfterViewInit } from '@angular/core';
import { Options } from 'select2';
import { Select2OptionData } from 'ng-select2';
import { Observable, from } from 'rxjs';
// import { ReportAnalyticsService } from "src/app/utils/services/report-analytics.service";
import { AuthService } from 'src/app/utils/services/auth.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { DateUtils } from 'src/app/utils/common/DateUtils';
import { ServiceMsg } from 'src/app/utils/common/validate-msg-aas';
import { ListReportDetailContractResponse} from 'src/app/utils/models/aas-models/quantity-report/report-contract-detail/list-report-detail-contract-response.model';
import { ReportDetailItem } from 'src/app/utils/models/aas-models/quantity-report/report-contract-detail/report-detail-contract-item.model'
import {
  map,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap
} from "rxjs/operators";
import { AasQuantityReportService } from 'src/app/utils/services/aas-network/aas-quantity-report.service';
import { ListDataPackageResponse } from 'src/app/utils/models/aas-models/quantity-report/data-droplist/list-data-package-response.model';
import { ContractStatus } from 'src/app/utils/common/constant'
import { Item } from 'src/app/utils/models/example/example-item.model';

declare var Cleave: any;
declare var $: any;
@Component({
  selector: 'app-report-detail-contract',
  templateUrl: './report-detail-contract.component.html',
  styleUrls: ['./report-detail-contract.component.scss']
})
export class ReportDetailContractComponent implements OnInit {
  @ViewChild('fromDatePicker') _fromDate: ElementRef;
  @ViewChild('toDatePicker') _toDate: ElementRef;
  @ViewChild("searchFieldContractDetail") searchField: ElementRef;

  partyId: string = this.auth.partyId;
  userId: string = this.auth.userId;

  formSearch: FormGroup;
  formFilter: FormGroup;
  searchFields: FormControl;

  dropListSignerName = [];
  listReportDetail : Array<ReportDetailItem> = [];
  listReportDetailRes: ListReportDetailContractResponse;

  currentDate = new Date();

  listConfigColTable: Array<any> = [
    {
      label: "Tên hợp đồng",
      name: "tenHopDong"
    },
    {
      label: "Bên tạo Hợp đồng",
      name: "nguoiTao"
    },
    {
      label: "Loại khách hàng",
      name: "loaiKhachHang"
    },
    {
      label: "Gói dịch vụ",
      name: "goiDichVu"
    },
    {
      label: "Bên nhận hợp đồng",
      name: "nguoiNhan"
    },
    {
      label: "Trạng thái hợp đồng",
      name: "trangThaiHopDong"
    },
    {
      label: "Ngày cập nhập",
      name: "ngayCapNhap"
    },
  ];
  configColTable: any = {
    tenHopDong: true,
    nguoiTao: true,
    nguoiNhan: true,
    loaiKhachHang: true,
    goiDichVu: true,
    trangThaiHopDong: true,
    ngayCapNhap: true,
  }

  listFitler: Array<any> = [
    {
      label: "Loại khách hàng",
      name: "loaiKhachHang"
    },
    {
      label: "Gói dịch vụ",
      name: "goiDichVu"
    },
    {
      label: "Trạng thái hợp đồng",
      name: "trangThaiHopDong"
    },
    {
      label: "Từ ngày",
      name: "tuNgay"
    },
    {
      label: "Đến ngày",
      name: "denNgay"
    },
  ];
  configFilter: any = {
    loaiKhachHang: true,
    goiDichVu: true,
    trangThaiHopDong: true,
    tuNgay: true,
    denNgay: true,
  }

  dropListsUserType:  Array<Select2OptionData> = [
    {
      id: 'ALL',
      text: 'Tất cả'
    },
    {
      id: 'CONSUMER',
      text: 'Cá nhân'
    },
    {
      id: 'BUSINESS',
      text: 'Tổ chức dịch vụ'
    },
    {
      id: 'ENTERPRISE',
      text: 'Tổ chức giải pháp'
    },
    
  ];

  dropListsPackageType:  Array<Select2OptionData> = [
    {
      id: 'ALL',
      text: 'Tất cả'
    },
    {
      id: 'TRIAL_TYPE',
      text: 'Gói dùng thử'
    },
    {
      id: 'NORMAL_TYPE',
      text: 'Gói cơ bản'
    }
    
  ];

  dropListsContractStatus:  Array<Select2OptionData> = [
    {
      id: 'ALL',
      text: 'Tất cả'
    },
    {
      id: 'LC_DRAFT_CREATE',
      text: 'Mới khởi tạo'
    },
    {
      id: 'LC_DRAFT_DENY',
      text: 'Từ chối'
    },
    {
      id: 'LC_DRAFT_SUBMIT',
      text: 'Đã gửi'
    },
    {
      id: 'LC_DRAFT_SUBMIT_SIGNED',
      text: 'Chờ ký'
    },
    {
      id: 'LC_DRAFT_SIGNED',
      text: 'Đã ký'
    },
    {
      id: 'LC_DRAFT_VALID',
      text: 'Chờ xử lý'
    },
    {
      id: 'LC_DRAFT_ONE_SIGN',
      text: 'Chờ ký'
    },
    {
      id: 'LC_CONTRACT_VALID',
      text: 'Có hiệu lực'
    },
    {
      id: 'LC_CONTRACT_EXPIRED_VALID',
      text: 'Hết hiệu lực'
    },
    {
      id: 'LC_CONTRACT_CANCEL',
      text: 'Đã hủy'
    },
  ];

  userTypes = {
    CONSUMER : {
      lable: 'Cá Nhân'
    },
    BUSINESS : {
      lable: 'Tổ chức dịch vụ'
    },
    ENTERPRISE : {
      lable: 'Tổ chức giải pháp'
    },

  }

  page: number = 1;
  maxSize: number = 10;
  sort: string = "DESC";
  propertiesSort: string = "createDate";
  totalElement: number = 0;

  fromDate: string = '';
  toDate: string = '';
  
  fromDateFilter: string = '';
  toDateFilter: string = '';
  keySearch = '';
  saveKeySearch= '';
  isSubmit: boolean = false;
  ServiceMsg: any = ServiceMsg.CONTRACT_MANAGER_FILTER;

  public packageData: Observable<Array<Select2OptionData>>;
  listDataPackageRes: ListDataPackageResponse;
  constractStatus = ContractStatus

  zIndex = 5;
  public options: Options;
  private results ;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private modalService: NgbModal,
    private renderer2: Renderer2,
    private quantityService: AasQuantityReportService
  ) {
    this.makeFormFilter();
    // config render bảng theo yc hiển thị bảng
    this.renderer2.listen('window', 'click', (e: Event) => {
      var all = document.getElementsByClassName('table-sticky-col2');
      for (var i = 0; i < all.length; i++) {
        all[i].removeAttribute('style');
      }
    });
  }

  ngOnInit(): void {
    this.saveKeySearch = this.keySearch;
    this.getPackageData();
    this.getListReport();
  
    this.makeFormSearch();
    this.makeAutoFormatDate();
    this.makeFormFilter();
    this.searchFields = new FormControl();
    // search với 5ms 1 lần khi người dùng typing
    this.results = this.searchFields.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(_ => (this.search(this.searchField.nativeElement.value))),
    ).subscribe();

  }


  // get danh sách báo cáo hợp đồng chi tiết
  getListReport(){
    let userType = this.userType.value === 'ALL'? '':  this.userType.value;
    let packageType = this.packageType.value === 'ALL'? '':  this.packageType.value;
    let status = this.status.value === 'ALL'? '':  this.status.value;
    let fromDate = this.convertStringToDate(this.fromDate)?this.convertStringToDate(this.fromDate):'';
    let toDate = this.convertStringToDate(this.toDate)?this.convertStringToDate(this.toDate):'';
    this.quantityService.getListContractDetail(this.page,this.maxSize,userType,this.sort,
      this.propertiesSort,packageType,status,fromDate, toDate, this.keySearch).subscribe(res=>{
        this.listReportDetailRes = new ListReportDetailContractResponse(res['object']);
        this.listReportDetail = this.listReportDetailRes.data;
        console.log(this.listReportDetail)
    })
  }
  convertStringToDate(str: string) {
    if(str !== '' && str !== null && str !== undefined) {
        var values = str.split('/');
        if(values.length >= 3) {
            return `${values[2]}-${values[1]}-${values[0]}`;
        } else {
            return null;
        }
    } else {
        return null;
    }
  }
  
  getPackageData(){
    this.quantityService.getDropListPackage().subscribe((res:any)=>{
      this.listDataPackageRes = new ListDataPackageResponse(res['object'])
      this.packageData = this.makePackageData();
    })
  }

  makePackageData(): Observable<Array<Select2OptionData>> {
    let data: Array<Select2OptionData> = [];
    data.push({ id: "ALL", text: "Tất cả" });
    if (this.listDataPackageRes.data && this.listDataPackageRes.data instanceof Array) {
      this.listDataPackageRes.data.forEach((item) => {
        data.push({ id: item.packageInfo.packInfoId.toString(), text: item.packageInfo.name });
      });
    }
    return Observable.create((obs) => {
      obs.next(data);
      obs.complete();
    });
  }

  //MARK: Action cấu hình hiển thị các hình thức filter
  actConfigFilter(e, field) {
    e.stopPropagation();
    if (this.configFilter[field] !== null && this.configFilter[field] !== undefined) {
      this.configFilter[field] = !this.configFilter[field];
    }
  }

  //MARK: Action cấu hình hiển thị cột của bảng
  actConfigColTableView(e, field) {
    e.stopPropagation();
    if (this.configColTable[field] !== null && this.configColTable[field] !== undefined) {
      this.configColTable[field] = !this.configColTable[field];
    }
  }


  // cover time to format dd/MM/YYYY
  convertToDateObj(dataString) {
    let d = new Date(dataString);
    let localTiming = d.toLocaleDateString('en-GB');
    return localTiming;
  }

  loadPage(page) {
    this.page = page;
    this.getListReport();
  }

  //MARK: Make input date format
  makeAutoFormatDate() {
    let self = this;
    new Cleave('#fromDateReport', {
      date: true,
      delimiter: '/',
      datePattern: ['d', 'm', 'Y'],
      onValueChanged: function(e) {
        self.fromDate = e.target.value;
        self.f_fromDate.setValue(e.target.value);
      }
    });

    new Cleave('#toDateReport', {
      date: true,
      delimiter: '/',
      datePattern: ['d', 'm', 'Y'],
      onValueChanged: function(e) {
        self.toDate = e.target.value;
        self.f_toDate.setValue(e.target.value);
      }
    });
  }


  //MARK: Khởi tạo form search
  makeFormSearch() {
    this.formSearch = this.fb.group({
      search: new FormControl('')
    });
  }

  makeFormFilter() {
    this.formFilter = this.fb.group({
      userType : ['ALL'],
      packageType: ['ALL'],
      status: ['ALL'],
      toDate: new FormControl('', [Validators.pattern(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/)]),
      fromDate: new FormControl('', [Validators.pattern(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/)])
    });
    this.options = {
      multiple: false,
      width: '100%',
      closeOnSelect: true,
      language: 'vi',
    };
  }

  get userType() {
    return this.formFilter.get('userType');
  }
  get packageType() {
    return this.formFilter.get('packageType');
  }
  get status() {
    return this.formFilter.get('status');
  }

  get f_toDate() { return this.formFilter.get('toDate'); };
  get f_fromDate() { return this.formFilter.get('fromDate'); };

  inputKeySearch(e) {
    this.saveKeySearch = e.target.value;
  }

  search(keyWord) {
    this.page = 1;
    this.keySearch = keyWord.trim();
    this.getListReport();
  }
 

  dateInput(e, type) {
    if (e.target.value.length > 10) {
      var str = e.target.value;
      e.target.value = str.substring(0, str.length - 1);
      if (type === 'fromDate') {
        this.fromDate = e.target.value;
        this.f_fromDate.setValue(e.target.value);
      } else if (type === 'toDate') {
        this.toDate = e.target.value;
        this.f_toDate.setValue(e.target.value);
      }
    } else {
      if (type === 'fromDate') {
        this.fromDate = e.target.value;
        this.f_fromDate.setValue(e.target.value);
      } else if (type === 'toDate') {
        this.toDate = e.target.value;
        this.f_toDate.setValue(e.target.value);
      }
    }
  }

  changeDate(event, type) {
    if (type == 'to') {
      this.toDate = DateUtils.convertObjtoDate(event);
      this.f_toDate.setValue(this.toDate);
      this._toDate.nativeElement.value = this.toDate;
    }
    else {
      this.fromDate = DateUtils.convertObjtoDate(event);
      this.f_fromDate.setValue(this.fromDate);
      this._fromDate.nativeElement.value = this.fromDate;
    }
  }

  //MARK: Apply filter
  searchWithFilter() {
    this.isSubmit = true;
    this.page = 1;
    console.log(this.formFilter.value, '==============this.formFilter.value')

    if (this.formFilter.valid) {
      let dateFrom = this.fromDate.length > 0 ? new Date(DateUtils.convertStringtoDateSearch(this.fromDate)).getTime() : -1;
      let dateTo = this.toDate.length > 0 ? new Date(DateUtils.convertStringtoDateSearch(this.toDate)).getTime() : -1;
      if ((dateTo > 0 && dateFrom > 0 && dateFrom > dateTo)) {
        this.f_fromDate.setErrors({minDate: true});
      }
    }

    if (this.formFilter.valid) {
      this.getListReport();
    }
  }

  cleanFilter() {
    this.isSubmit = false;
    this._fromDate.nativeElement.value = '';
    this._toDate.nativeElement.value = '';
    this.fromDate = '';
    this.f_fromDate.setValue('');
    this.toDate = '';
    this.f_toDate.setValue('');

    this.makeFormFilter();
    this.status.setValue('');
    this.packageType.setValue('');
    this.userType.setValue('');
    

    this.keySearch = '';
    this.saveKeySearch = this.keySearch
    this.searchFields.reset()
    this.searchWithFilter()
    this.configColTable = {
      tenHopDong: true,
      nguoiTao: true,
      nguoiNhan: true,
      loaiKhachHang: true,
      goiDichVu: true,
      trangThaiHopDong: true,
      ngayCapNhap: true,
    }

    this.configFilter = {
      loaiKhachHang: true,
      goiDichVu: true,
      trangThaiHopDong: true,
      tuNgay: true,
      denNgay: true,
    }
  }



  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], 'error');
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, 'error');
    } else {
      this.alert('Lỗi không xác định!', 'error');
    }
  }

}
