import { MainResponse } from '../../../main-response.model';
import { InfoCASignServer } from './info-ca-signserver-object';

export class InfoCASignServerResponse extends MainResponse {
    object: InfoCASignServer;

    constructor(obj = null) {
        super();
        obj = obj || {};
        this.init(obj);
        this.object = new InfoCASignServer(obj.object);
    }
}