import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

declare var PDFSign: any;

@Component({
  selector: 'app-preview-modal',
  templateUrl: './preview-modal.component.html',
  styleUrls: ['./preview-modal.component.scss']
})
export class PreviewModalComponent implements OnInit {
  @Input()
  get file() {
    return this._file
  }
  set file(value) {
    this._file = value;
  }
  _file: any = null;

  @Input('name') name: string = '';
  @Input('fileType') fileType: string = 'application/pdf';
  @Input('useDownloadEvent') useDownloadEvent: any = false;
  @Output('close') close: EventEmitter<any> = new EventEmitter<any>();
  @Output('download') download: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    this.previewFile();
  }

  closePreview() {
    this.close.emit({});
  }

  previewFile() {
    console.log(this.file);
    if (this.file) {
      PDFSign.preview(this.file);
    }
  }

  downloadFile() {
    if (this.useDownloadEvent) {
      this.download.emit(null);
    } else {
      const blob: any = new Blob([this.file], { type: this.fileType });
      var url = window.URL.createObjectURL(blob);
      var anchor = document.createElement("a");
      anchor.download = this.name || 'file';
      anchor.href = url;
      anchor.click();
    }
  }
}
