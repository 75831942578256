<app-spinner></app-spinner>
<div class="wrapper">
    <div class="preview-overlay">

        <div class="preview-container">
            <div class="preview-header d-flex">
                <div (click)="goToBack()"
                    class="ct-header-btn-back d-flex justify-content-center align-items-center cursor-pointer">
                    <span class="material-icons">arrow_back_ios</span>
                </div>
                <div class="ct-title flex-grow-1 d-flex align-items-center px-3">
                    <h4 class="mb-0">Hợp đồng điện tử</h4>
                </div>
                <div class="ct-toolbar-btn d-flex align-items-center">
                    <button (click)="saveAsContract()"
                        class="btn btn-outline-primary d-flex align-items-center mr-3"><span
                            class="material-icons mr-2">get_app</span> Tải xuống</button>
                    <button (click)="goToBack()"
                        class="btn btn-outline-primary d-flex align-items-center mr-3"><span
                            class="material-icons mr-2">clear</span> Hủy</button>
                    <button (click)="actBtnSave($event)" [disabled]="editedForm"
                        class="btn btn-outline-primary d-flex align-items-center mr-3"><span
                            class="material-icons mr-2">save</span> Lưu</button>
                    <!-- <button class="btn btn-primary d-flex align-items-center mr-3"><span
                            class="material-icons mr-2">send</span> Gửi</button> -->
                </div>
            </div>

            <div class="preview-body">
                <div class="pv ct">
                    <div class="pv-body">
                        <div class="ctpv-container">
                            <div class="ctpv-content">
                                <div class="ct-container">
                                    <div class="ct-thumb-page">
                                        <div class="ct-vs ct-enable-scroll">
                                            <ol id="thumbnail-page" class="ct-vs-list mt-3"
                                                [class.d-none]="typeSelected === 2">
                                            </ol>
                                        </div>
                                    </div>
                                    <div id="ppi"
                                        style="height: 1in; width: 1in; position: absolute; top: -100%; left: -100%;">
                                    </div>
                                    <div class="ct-content">
                                        <div id="parentView" class="ct-doc-loaded ct-enable-scroll"
                                            [class.d-none]="typeSelected === 2">

                                        </div>

                                        <div class="ct-doc-loaded ct-enable-scroll" [class.d-none]="typeSelected !== 2">
                                            <ckeditor #myEditor [editor]="Editor" data="<p>Nội dung hợp đồng!</p>">
                                            </ckeditor>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="ct ctpv-tool-bar">
                            <div class="ctpv-tool-sidebar">
                                <div class="ctpv-tabbar">
                                    <div class="ctpv-main">
                                        <button (click)="selectTab('INFO')" class="ctpv-btn"
                                            [class.active]="activeTab == 'INFO'"> <span
                                                class="material-icons-outlined">info</span></button>
                                        <!-- <button (click)="selectTab('GESTURE')" class="ctpv-btn"
                                            [class.active]="activeTab == 'GESTURE'"> <span
                                                class="material-icons">gesture</span></button> -->
                                    </div>
                                </div>
                                <div class="ctpv-footer">

                                </div>
                            </div>
                            <div class="ctpv-tool-content">
                                <div class="ctpv-content-wrapper px-3">
                                    <div id="tab-info" class="ctpv-scroll-content"
                                        [class.d-none]="!(activeTab === 'INFO')">
                                        <h5 class="mt-3">Thông tin hợp đồng</h5>

                                        <form [formGroup]="formContract">
                                            <div class="row mt-4">
                                                <div class="col-lg-4">
                                                    <div class="rcheck-primary d-inline">
                                                        <input checked type="radio" name="type" id="rr0">
                                                        <label for="rr0" (click)="selectType(1)">
                                                            Tải file đính kèm
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">
                                                    <div class="rcheck-primary d-inline">
                                                        <input type="radio" name="type" id="rr1">
                                                        <label for="rr1" (click)="selectType(2)">
                                                            Soạn hợp đồng trực tiếp
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4">
                                                    <div class="rcheck-primary d-inline">
                                                        <input type="radio" name="type" id="rr2">
                                                        <label for="rr2" (click)="selectType(3)">
                                                            Tải hợp đồng giấy
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row mt-3">
                                                <div class="col-xl-6">
                                                    <div class="form-group">
                                                        <label>Tên hợp đồng <span class="text-danger">*</span>: </label>
                                                        <input formControlName="tenHopDong" class="form-control"
                                                            type="text">

                                                        <app-show-validate-errors [isSubmit]="isSubmitContract"
                                                            [errorMessages]="validContractMsg.tenHopDong"
                                                            [control]="formContract.controls.tenHopDong"
                                                            [detail]="{ name: 'tenHopDong' }">
                                                        </app-show-validate-errors>
                                                    </div>
                                                </div>

                                                <div class="col-xl-6">
                                                    <div class="form-group">
                                                        <input #output id="output" class="" value="" type="hidden">
                                                        <input #filebase id="fileBase" class="" type="hidden">
                                                        <input #filename id="fileName" class="" value="{{ fileName }}"
                                                            type="hidden">
                                                        <label>File đính kèm <span class="text-danger">*</span>:</label>
                                                        <div class="input-group mb-1">
                                                            <div class="custom-file custom-file-icon">
                                                                <span
                                                                    class="icon-file material-icons">attach_file</span>
                                                                <input [attr.disabled]="isDisabledInputFile ? '' : null"
                                                                    formControlName="file"
                                                                    (input)="handleFileInput($event)" type="file"
                                                                    accept=".pdf,.docx" class="custom-file-input"
                                                                    id="inputGroupFile01"
                                                                    aria-describedby="inputGroupFileAddon01">
                                                                <label class="custom-file-label"
                                                                    for="inputGroupFile01">{{ inputFileLabel }}</label>
                                                            </div>
                                                        </div>
                                                        <app-show-validate-errors [isSubmit]="isSubmitContract"
                                                            [errorMessages]="validContractMsg.file"
                                                            [control]="formContract.controls.file"
                                                            [detail]="{ name: 'file' }"></app-show-validate-errors>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-xl-6">
                                                    <div class="form-group">
                                                        <label>Ngày có hiệu lực <span class="text-danger">*</span>:
                                                        </label>
                                                        <input id="ngayHieuLuc" formControlName="ngayHieuLuc"
                                                            class="form-control" type="text" placeholder="dd/MM/yyyy">
                                                        <app-show-validate-errors [isSubmit]="isSubmitContract"
                                                            [errorMessages]="validContractMsg.ngayHieuLuc"
                                                            [control]="formContract.controls.ngayHieuLuc"
                                                            [detail]="{ name: 'ngayHieuLuc' }">
                                                        </app-show-validate-errors>
                                                    </div>
                                                </div>

                                                <div class="col-xl-6">
                                                    <div class="form-group">
                                                        <label>Ngày hết hiệu lực <span class="text-danger">*</span>:
                                                        </label>
                                                        <input id="ngayHetHieuLuc" formControlName="ngayHetHieuLuc"
                                                            class="form-control" type="text" placeholder="dd/MM/yyyy">
                                                        <app-show-validate-errors [isSubmit]="isSubmitContract"
                                                            [errorMessages]="validContractMsg.ngayHetHieuLuc"
                                                            [control]="formContract.controls.ngayHetHieuLuc"
                                                            [detail]="{ name: 'ngayHetHieuLuc' }">
                                                        </app-show-validate-errors>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-xl-6">
                                                    <div class="form-group">
                                                        <label>Tự động gia hạn hợp đồng <span
                                                                class="text-danger">*</span>: </label>
                                                        <select formControlName="tuGiaHan" class="form-control">
                                                            <option value="true">Có</option>
                                                            <option value="false">Không</option>
                                                        </select>
                                                        <app-show-validate-errors [isSubmit]="isSubmitContract"
                                                            [errorMessages]="validContractMsg.tuGiaHan"
                                                            [control]="formContract.controls.tuGiaHan"
                                                            [detail]="{ name: 'tuGiaHan' }">
                                                        </app-show-validate-errors>
                                                    </div>
                                                </div>

                                                <div class="col-xl-6">
                                                    <div class="form-group">
                                                        <label>Giá trị hợp đồng:</label>
                                                        <input id="contractValueInput" formControlName="giaTriHd" class="form-control"
                                                            type="text">
                                                        <app-show-validate-errors [isSubmit]="isSubmitContract"
                                                            [errorMessages]="validContractMsg.giaTriHd"
                                                            [control]="formContract.controls.giaTriHd"
                                                            [detail]="{ name: 'giaTriHd' }"></app-show-validate-errors>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-xl-6">
                                                    <div class="form-group">
                                                        <label>Người nhận <span class="text-danger">*</span>: </label>
                                                        <ng-select2 formControlName="nguoiNhan" [data]="nguoiNhanData"
                                                            [options]="optionsTemplate"
                                                            (valueChanged)="changedNguoiNhan($event)">
                                                        </ng-select2>
                                                        <app-show-validate-errors [isSubmit]="isSubmitContract"
                                                            [errorMessages]="validContractMsg.nguoiNhan"
                                                            [control]="formContract.controls.nguoiNhan"
                                                            [detail]="{ name: 'nguoiNhan' }"></app-show-validate-errors>
                                                    </div>
                                                </div>

                                                <div class="col-xl-6">
                                                    <div class="form-group">
                                                        <label>Loại khách hàng<span class="text-danger">*</span>:
                                                        </label>
                                                        <select disabled formControlName="loaiKh" class="form-control"
                                                            (change)="changeLoaiKh($event)">
                                                            <option value="CONSUMER">Cá nhân</option>
                                                            <option value="BUSINESS">Tổ chức SME</option>
                                                            <option value="ENTERPRISE">Khách hàng tổ chức</option>
                                                        </select>
                                                        <app-show-validate-errors [isSubmit]="isSubmitContract"
                                                            [errorMessages]="validContractMsg.loaiKh"
                                                            [control]="formContract.controls.loaiKh"
                                                            [detail]="{ name: 'loaiKh' }"></app-show-validate-errors>
                                                    </div>
                                                </div>
                                            </div>


                                            <div class="row">
                                                <div class="col-xl-12">
                                                    <div *ngIf="!isUploadFinalContract" class="form-group">
                                                        <label>Hình thức ký <span class="text-danger">*</span>: </label>
                                                        <!-- <ng-select2 [data]="dropListTemplateGroupData | async" [options]="options"
                                                            (valueChanged)="valueTemplateGroupChanged($event)"></ng-select2> -->
                                                        <div class="d-flex">
                                                            <div class="flex-grow-1 mr-2">
                                                                <ng-select2 formControlName="hinhThucKy"
                                                                    [options]="optionsHinhThucKy"
                                                                    [data]="hinhThucKyData"
                                                                    (valueChanged)="changedHinhThucKy($event)">
                                                                </ng-select2>
                                                            </div>

                                                            <div class="ml-auto">
                                                                <div class="ccheck-primary w-100 d-inline-block">
                                                                    <input (change)="selelectAllHinhThucKy($event)" type="checkbox" id="getAll">
                                                                    <label for="getAll" class="w-100">
                                                                        Tất cả
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <app-show-validate-errors [isSubmit]="isSubmitContract"
                                                            [errorMessages]="validContractMsg.hinhThucKy"
                                                            [control]="formContract.controls.hinhThucKy"
                                                            [detail]="{ name: 'hinhThucKy' }">
                                                        </app-show-validate-errors>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div *ngIf="!isUploadFinalContract" class="col-xl-6">
                                                    <div class="form-group">
                                                        <label>Thời hạn ký <span class="text-danger">*</span>: </label>
                                                        <input id="thoiHanKy" formControlName="thoiHanKy"
                                                            class="form-control" type="text"
                                                            placeholder="Nhập thời hạn ký">
                                                        <app-show-validate-errors [isSubmit]="isSubmitContract"
                                                            [errorMessages]="validContractMsg.thoiHanKy"
                                                            [control]="formContract.controls.thoiHanKy"
                                                            [detail]="{ name: 'thoiHanKy' }"></app-show-validate-errors>
                                                    </div>
                                                </div>

                                                <div class="col-xl-6">
                                                    <div class="form-group">
                                                        <label>Ghi chú: </label>
                                                        <input formControlName="ghiChu" class="form-control"
                                                            type="text">
                                                        <app-show-validate-errors [isSubmit]="isSubmitContract"
                                                            [errorMessages]="validContractMsg.ghiChu"
                                                            [control]="formContract.controls.ghiChu"
                                                            [detail]="{ name: 'ghiChu' }"></app-show-validate-errors>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>