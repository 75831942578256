export const localize_request_service = {
    list_request_service: "Danh sách yêu cầu SPDV",
    company_name: "Tên doanh nghiệp",
    tax_number: "Mã số thuế",
    representative: "Người đại diện",
    handler: "Người xử lý",
    status: "Trạng thái",
    action: "Thao tác",
    index: "STT",
    reciever: "Người tiếp nhận",
    head: "Đầu",
    last: "Cuối",
    recieve_date: "Ngày tiếp nhận",
    sme_order_detail: "Chi tiết đơn hàng SME",
    order_information: "Thông tin đơn hàng",
    company_information: "Thông tin doanh nghiệp",
    pack_of_data_information: "Thông tin gói cước",
    employees_list: "Danh sách nhân viên",
    address: "Địa chỉ",
    email: "Email",
    representative_information: "Thông tin người đại diện",
    representative_name: "Tên người đại diện",
    identityNo: "Số giấy tờ",
    day_of_birth: "Ngày sinh",
    gender: "Giới tính",
    nation: "Quốc gia",
    hrm_code: "Mã HRM",
    package_code: "Mã gói cước",
    charge_model: "Mô hình tính cước",
    payment_cycle: "Chu kỳ thanh toán",
    package_type: "Loại gói cước",
    trial_expiration_date: "Ngày hết hạn dùng thử",
    use_status: "Trạng thái sử dụng",
    start_use_date: "Ngày bắt đầu tính phí",
    payment_cycle_expiration_date: "Ngày kết thúc chu kỳ tính cước",
    setup_cost: "Phí thiết lập",
    full_name: "Họ và tên",
    create_status: "Trạng thái khởi tạo",
    number_of_records_displayed: "Số dòng hiển thị",
    official: "Chính thức",
    trial: "Dùng thử",
    package_name: "Tên gói cước",
    active_use: "Đang sử dụng",
    deactive_use: "Ngưng sử dụng",
    day: "Ngày",
    month: "Tháng",
    year: "Năm",
    detail_information: "Thông tin chi tiết"
}