import { USER_PERMISSION } from 'src/app/utils/common/constant-contract';
import { ConstSignType, InternalDiscussTypes, SignFlowTypes } from 'src/app/utils/common/constants/sign-form';
import { UtilsFunc } from 'src/app/utils/common/utils-func';
import { AuthService } from 'src/app/utils/services/auth.service';
import { InternalDiscuss } from '../../flow-contract/internal-discuss.model';
import { PartnerDiscuss } from '../../flow-contract/partner-discuss.model';
import { SignFlow } from '../../flow-contract/sign-flow.model';
import { PartyJoinContract } from '../party-join-contract/party-join-contract';
import { ContractInternal } from './contract-internal.model';
import { ContractParty } from './contract-party.model';

export class ContractObject {
    contractId: string = '';
    contractNumber: string = '';
    title: string = '';
    type: string = '';
    validDate: string = '';
    legalStatus: string = '';
    currentStage: string = '';
    currentStageId: string = '';
    signatures: Array<SignatureItem> = [];
    documentHash: string = '';
    documents: Array<any> = [];
    denyReason: string = '';
    cancelReason: string = '';
    created: string = '';
    modified: string = '';
    templateGroupId: string = '';
    templateId: string = '';
    lifecycleId: string = '';
    aPartyId: string = '';
    bPartyId: string = '';
    ownerPartyId: string = '';
    amId: string = '';
    signerId: string = '';
    orderId: string = '';
    tokenId: string = '';
    customerType: string = '';
    endDate: string = '';
    contractValue: string = '';
    signInDays: string = '';
    signForm: Array<string> = [];
    creationNote: string = '';
    tenBhd: string = '';
    maBhd: string = '';
    required: string = '';
    expiredDate: string = '';
    effectiveDate: string = '';
    productId: string = '';
    productName: string = '';
    autoRenew: boolean;

    customer: Customer;
    contractPartysData: Array<ContractParty> = [];

    discuss: boolean = false;

    contractInternalData: Array<ContractInternal> = [];

    fieldConcated: any = {};
    readyforcancel: any = 0;
    readyfordeal: any = 0;
    readyfordelete: any = 0;
    readyfordeny: any = 0;
    readyforsign: any = 0;
    readyforupdatecontent: any = 0;
    readyforreturn: any = 0;

    creationType: string = "";
    contractFields: any = [];
    contractFlowTemplateId: string = "";
    signFlow: SignFlow[] = []
    internalDiscuss: InternalDiscuss[] = [];
    partnerDiscuss: PartnerDiscuss[] = [];
    templateGroupName: string = "";
    contractFlowName: string = "";
    fileName: string = "";

    userPermissions: any = {};

    partner: Array<ContractParty> = [];

    internalisDiscussed: any = null;
    signFlowType: string = "";

    internalDiscussType: string = "";
    previousStage: string = "";
    fixedPosition: any = false;
    sequenceId: string = "";
    contractNumberLocation = null;
    contractTypeId: string = "";
    typeName: string = "";

    constructor(data = null) {
        data = data || {};

        this.contractId = data.contractId || '';
        this.contractNumber = data.contractNumber || '';
        this.title = data.title || '';
        this.type = data.type || '';
        this.validDate = data.validDate || '';
        this.legalStatus = data.legalStatus || '';
        this.currentStage = data.currentStage || '';
        this.currentStageId = data.currentStageId || '';
        this.signatures = [];
        if (data.signatures && data.signatures instanceof Array) {
            data.signatures.forEach(item => {
                this.signatures.push(new SignatureItem(item));
            });
        }

        this.documentHash = data.documentHash || '';
        this.documents = [];
        if (data.documents && data.documents instanceof Array) {
            data.documents.forEach(item => {
                this.documents.push(item);
            });
        }

        this.denyReason = data.denyReason || '';
        this.cancelReason = data.cancelReason || '';
        this.created = data.created || '';
        this.modified = data.modified || '';
        this.templateGroupId = data.templateGroupId || '';
        this.templateId = data.templateId || '';
        this.lifecycleId = data.lifecycleId || '';
        this.aPartyId = data.aPartyId || '';
        this.bPartyId = data.bPartyId || '';
        this.ownerPartyId = data.ownerPartyId || '';
        this.amId = data.amId || '';
        this.signerId = data.signerId || '';
        this.orderId = data.orderId || '';
        this.tokenId = data.tokenId || '';
        this.customerType = data.customerType || '';
        this.endDate = data.endDate || '';
        this.contractValue = data.contractValue || '';
        this.signInDays = data.signInDays || '';
        this.signForm = [];
        if (data.signForm && data.signForm instanceof Array) {
            data.signForm.forEach(item => {
                this.signForm.push(item);
            })
        } 

        this.creationNote = data.creationNote || '';
        this.tenBhd = data.tenBhd || '';
        this.maBhd = data.maBhd || '';
        this.required = data.required || '';
        this.expiredDate = data.expiredDate || '';
        this.effectiveDate = data.effectiveDate || '';
        this.productId = data.productId || '';
        this.productName = data.productName || '';
        this.autoRenew = data.autoRenew;

        this.customer = new Customer(data.customer);

        if (data.contractPartysData && data.contractPartysData instanceof Array) {
            data.contractPartysData.forEach((item) => {
                this.contractPartysData.push(new ContractParty(item));
            })
        }

        this.discuss = data.discuss || false;

        if (data.contractInternalData && data.contractInternalData instanceof Array) {
            data.contractInternalData.forEach((item) => {
                this.contractInternalData.push(new ContractInternal(item));
            })
        }

        this.fieldConcated = data.fieldConcated || {};
        this.readyforcancel = data.readyforcancel;
        this.readyfordeal = data.readyfordeal;
        this.readyfordelete = data.readyfordelete;
        this.readyfordeny = data.readyfordeny;
        this.readyforsign = data.readyforsign;
        this.readyforupdatecontent = data.readyforupdatecontent;
        this.readyforreturn = data.readyforreturn;

        this.creationType = data.creationType || data.type || "";
        this.contractFields = data.contractFields || [];
        this.contractFlowTemplateId = data.contractFlowTemplateId || "";

        if (data.internalDiscuss && data.internalDiscuss instanceof Array) {
            data.internalDiscuss.forEach((item) => {
                this.internalDiscuss.push(new InternalDiscuss(item));
            })
        }

        if (data.partnerDiscuss && data.partnerDiscuss instanceof Array) {
            data.partnerDiscuss.forEach((item) => {
                this.partnerDiscuss.push(new PartnerDiscuss(item));
            })
        }

        if (data.signFlow && data.signFlow instanceof Array) {
            data.signFlow.forEach((item) => {
                this.signFlow.push(new SignFlow(item));
            })
        }
        this.signFlow = this.signFlow.sort((a, b) => { return a.sequence - b.sequence });

        this.templateGroupName = data.templateGroupName || "";
        this.contractFlowName = data.contractFlowName || "";
        this.fileName = data.fileName || "";
        this.userPermissions = data.userPermissions || "";

        if (data.partner && data.partner instanceof Array) {
            data.partner.forEach((item) => {
                this.partner.push(new ContractParty(item));
            })
        }

        this.internalisDiscussed = data.internalisDiscussed;
        this.signFlowType = data.signFlowType || "";
        this.internalDiscussType = data.internalDiscussType || "";
        this.previousStage = data.previousStage || "";

        this.fixedPosition = data.fixedPosition || false;
        this.sequenceId = data.sequenceId || "";
        this.contractNumberLocation = data.contractNumberLocation || null;
        this.contractTypeId = data.contractTypeId || '';
        this.typeName = data.typeName || '';
    }

    get thisPartyFinishedSign() {
        let currentParty = this.contractPartysData.find((item) => { return item.partyId === AuthService.instance.partyId});
        if (currentParty) {
          if (currentParty.signerId !== "" && currentParty.signDate !== "") return true;
        } 
        return false;
    }

    get thisUserAllowedFinishSignInternal() {
        if (!this.contractId) return false;

        if (this.isOwnerParty && (this.contractFlowTemplateId != null && this.contractFlowTemplateId != "")) return false;

        if (this.contractInternalData.length <= 0) return false;

        let rootPartyId = AuthService.instance.partyId || "";
        if (rootPartyId === "") return false;

        let partyAssignee = this.signerId;
        if (partyAssignee !== rootPartyId) return false;

        let currentParty = this.contractPartysData.find((item) => { return item.partyId === rootPartyId });
        if (!currentParty) return false;

        let currentPartyFinished = currentParty.internalFinish || false;
        if (currentPartyFinished) return false;

        let currentUserAssignee = currentParty?.internalSignerId || "";
        if (currentUserAssignee !== "" && currentUserAssignee !== AuthService.instance.userId) return false;

        this.contractInternalData.forEach((item, index) => {
        if (item.action === null || item.action === "") return false;
        });

        return true;
    }

    get isOwnerParty() {
        return this.ownerPartyId === AuthService.instance.partyId;
    }

    get isCreator() {
        return this.amId === AuthService.instance.userId;
    }

    get allowApproveInternal() {
        if (this.currentStage === "LC_DRAFT_CREATE") return false;    //Hop dong da bi huy
        if (this.currentStage === "LC_DRAFT_WAIT_CONFIRM") return false;
        // if (this.currentStage === "LC_DRAFT_CANCEL") return false;
        // if (this.contract.discuss) return false;
        let contractValid = false; // this.currentStage === "LC_CONTRACT_VALID";
        let isDealing = this.currentStage === "LC_DRAFT_DISCUSS" || this.currentStage === "LC_DRAFT_DEAL";
        if (contractValid || isDealing) return false;
        
        return true;
    }
    
    get isFinishedInternalSign() {
        if (this.contractInternalData.length > 0) {
            if (this.signFlowType === SignFlowTypes.REQUIRE_FLOW_STAMP) {
                let userApproval = this.contractInternalData.find(item => { return item.signType === "STAMP" });
                if (userApproval && userApproval.action !== null && userApproval.action !== "") return false;
            } else {
                let userApproval = this.contractInternalData.find(item => { return item.signType === "APPROVAL" });
                if (userApproval && userApproval.action !== null && userApproval.action !== "") return false;
            }

            this.contractInternalData.forEach((item) => {
                if (item.action == "" || item.action == null) return false;
            });
        }
        return true;
    }

    get isAllowedSign() {
        let userId = AuthService.instance.userId;
        //Hop dong da valid hoac moi khoi tao
        if (this.currentStage === "LC_CONTRACT_VALID" || this.currentStage === "LC_DRAFT_CREATE") return false;
    
        //Hop dong da bi huy
        if (this.currentStage === "LC_DRAFT_CANCEL") return false;
    
        //Hop dong dang dam phan
        if (this.currentStage === "LC_DRAFT_DISCUSS" || this.currentStage === "LC_DRAFT_DEAL") return false;

        // Hop dong dang tham dinh noi bo (hop dong khong dam phan)
        if (this.currentStage === "LC_DRAFT_WAIT_CONFIRM") return false;

        // Hop dong dang trong trang thai canh bao
        if (this.currentStage === "LC_DRAFT_WARNING") return false;
    

        if (this.thisPartyFinishedSign) return false;
    
        if (this.ownerPartyId === AuthService.instance.partyId) {//Neu la ben tao hop dong
            if (this.signerId != AuthService.instance.partyId) return false;
            if (this.signFlowType === SignFlowTypes.REQUIRE_FLOW_STAMP) {//Truong ky dau bat buoc theo luong
                let n = this.contractInternalData.length;
                if (n > 0) {//Truong hop co luong noi bo ky
                    let employeeStamp = this.contractInternalData[n - 1];
                    let isStampStep = employeeStamp?.signType == ConstSignType.STAMP;
                    let isCurrentUserSign = employeeStamp?.userId == AuthService.instance.userId;
                    let isNotSigned = employeeStamp.action == null || employeeStamp.action == "";

                    let currentParty = this.contractPartysData.find((x) => { return x.partyId == AuthService.instance.partyId });
                    let isAssignee = currentParty && currentParty.internalSignerId == AuthService.instance.userId;

                    //La user dc ky dau
                    let allowUserSign = isStampStep && isCurrentUserSign && isNotSigned && isAssignee;
                    //La admin den buoc dc ky dau
                    let allowAdminSign = AuthService.instance.isAdmin && isStampStep;
    
                    //Neu khong phai la admin va chua den luot ky dau
                    if (!allowUserSign && !allowAdminSign) {
                        return false;
                    }
                } else {//Truong hop chua co luong noi bo ky
                    let isStepForAdmin = this.signerId === userId && AuthService.instance.isAdmin;
                    if (!isStepForAdmin) return false;
                }
            } 
            else if (this.signFlowType === SignFlowTypes.REQUIRE_FLOW) {//Truong hop chi ky duyet va bat buoc theo luong
                let n = this.contractInternalData.length;
                if (n > 0) {//Truong hop co luong noi bo ky
                    let employeeApprove = this.contractInternalData[n - 1];
                    let isApprovalStep = employeeApprove?.signType == ConstSignType.APPROVAL;
                    let isCurrentUserSign = employeeApprove?.userId == AuthService.instance.userId;
                    let isNotSigned = employeeApprove.action == null || employeeApprove.action == "";
    
                    //La user dc ky duyet
                    let allowUserSign = isApprovalStep && isCurrentUserSign && isNotSigned;
                    //La admin den buoc dc ky duyet
                    let allowAdminSign = AuthService.instance.isAdmin && isApprovalStep;

                    //Neu khong phai la admin va chua den luot ky dau
                    if (!allowUserSign && !allowAdminSign) {
                        return false;
                    }
                } else {//Truong hop chua co luong noi bo ky
                    let isStepForAdmin = this.signerId === userId && AuthService.instance.isAdmin;
                    if (!isStepForAdmin) return false;
                }
            } 
            else if (this.signFlowType == "" || this.signFlowType === SignFlowTypes.NO_REQUIRE) {//Chi ky duyet khong bat buoc theo luong
                let notStepAdminSign = this.signerId !== userId;
                let lengthSignFlow = this.signFlow.length;
                if (lengthSignFlow > 0) {
                    let employeeApprove = this.signFlow[lengthSignFlow - 1];
                    let stepInternalApproveSign = employeeApprove?.signType != ConstSignType.APPROVAL;
                    let isCurrentUserSign = employeeApprove?.userId != AuthService.instance.userId;
    
                    if (notStepAdminSign && (stepInternalApproveSign || isCurrentUserSign)) {
                        return false;
                    }
                } else {
                    if (notStepAdminSign) return false;
                }
            }
        } else {
            //User hien tai chua toi luot ky
            //   (this.currentStage === "LC_DRAFT_SUBMIT" || this.currentStage === "LC_DRAFT_SIGNED") && 
            if (AuthService.instance.userId != AuthService.instance.partyId) return false;

            if (this.signerId !== userId) {
                return false;
            }
        }
        return true;
    }

    get checkAllowActionAssignInternal() {//Kiem tra dieu kien hien thi cac action lien quan den noi bo
        //Phai la hop dong da dc tao
        if (!this.contractId) return false;

        //Hop dong da valid hoac moi khoi tao
        if (this.currentStage === "LC_CONTRACT_VALID" || this.currentStage === "LC_DRAFT_CREATE") return false;

        //Hop dong da bi huy
        if (this.currentStage === "LC_DRAFT_CANCEL") return false;

        //Hop dong dang dam phan
        if (this.currentStage === "LC_DRAFT_DISCUSS" || this.currentStage === "LC_DRAFT_DEAL") return false;

        // Hop dong dang tham dinh noi bo (hop dong khong dam phan)
        if (this.currentStage === "LC_DRAFT_WAIT_CONFIRM") return false;

        //Khong tim thay rootPartyId
        let rootPartyId = AuthService.instance.partyId || "";
        if (rootPartyId === "") return false;

        //Dang den luoc thao tac cua party
        let partyAssignee = this.signerId;
        if (partyAssignee !== rootPartyId) return false;

        //Party cao trong ds cac ben tham gia
        let currentParty = this.contractPartysData.find((item) => { return item.partyId === rootPartyId });
        if (!currentParty) return false;

        //Party chua ket thuc kiem duyet
        let currentPartyFinished = currentParty.internalFinish || false;
        if (currentPartyFinished) return false;

        //Kiem tra dung nguoi duoc gan hay chua, hay la chua ai dc gan
        let currentUserAssignee = currentParty?.internalSignerId || "";
        if (currentUserAssignee !== "" && currentUserAssignee !== AuthService.instance.userId) return false;

        if (this.contractInternalData.length > 0) {
            let currentUserAssigneeInfo = this.contractInternalData.find((item) => { return item.userId === AuthService.instance.userId; });
            if (currentUserAssigneeInfo == null) return false;
            if (currentUserAssigneeInfo.action === "" || currentUserAssigneeInfo.action === null) return false;
        }
        return true;
    }

    //Kiểm tra quyền sử dụng phương thức ký
    allowActionSignForm(signForm: string) {
        //La ben nhan hop dong
        if (!this.isOwnerParty) return true;

        //La ben tao va hop dong k su dung luong cau hinh
        if (this.contractFlowTemplateId == "" || this.contractFlowTemplateId == null) return true;

        //Ton tai signForm
        if (signForm === '') return false;

        let currentParty = this.contractPartysData.find((item) => { return item.partyId === AuthService.instance.partyId });
        if (!currentParty) return false;

        let currentPartyFinished = currentParty.internalFinish;
        if (currentPartyFinished === true) return false;

        let currentUserAssignee = currentParty.internalSignerId || "";
        if (currentUserAssignee !== AuthService.instance.userId) return false;

        let indexAprrovalInternal = this.contractInternalData.findIndex((item) => {return item.userId === currentUserAssignee && (item.action == "" || item.action == null);});
        if (indexAprrovalInternal < 0) return false;

        let approveInternal = this.contractInternalData[indexAprrovalInternal] || null;
        console.log(approveInternal, signForm,approveInternal.signForm.includes(signForm));
        if (!approveInternal.signForm.includes(signForm)) return false;

        return true;
    }

    //Kiem tra dieu kien cho phep ky nhay
    allowDraftSign() {
        let n = this.contractInternalData.length;
        let userSign = this.contractInternalData[n - 1]
        let isDraftSignType = userSign?.signType == "DRAFT" 
                    || (userSign?.signType === ConstSignType.APPROVAL && this.signFlowType == SignFlowTypes.REQUIRE_FLOW_STAMP);
        let isCurrentUserSign = userSign?.userId == AuthService.instance.userId;

        if (isDraftSignType && isCurrentUserSign) {
            return true;
        }

        return false;
    }

    /**
     * Được phép thẩm định hợp đồng
     */
    get allowShowInternalDiscuss() {
        if (this.discuss === false) {
            if (this.internalDiscussType == InternalDiscussTypes.NO_REQUIRE) {
                return false;
            }
        };

        if (this.currentStage === "LC_DRAFT_CREATE" && this.previousStage == "") return false;
    
        let isCreator = this.isCreator;
        let isDiscuss = this.userPermissions[USER_PERMISSION.INTERNAL_DISCUSSABLE];
        let isAcceptDeny = this.userPermissions[USER_PERMISSION.INTERNAL_ACCEPTABLE];
        let isEditable = this.userPermissions[USER_PERMISSION.INTERNAL_EDITABLE];
        if (!isCreator && !isDiscuss && !isAcceptDeny && !isEditable) return false;
    
        return true;
    }

    /**
     * Điều kiện được phép thêm 
     */
    get allowInternalDiscussConfig() {
        if (this.discuss == false) return false;

        let allowStatusContract = this.currentStage == "LC_CONTRACT_VALID";
        if (allowStatusContract) return false;

        let endInternalDiscuss = true;
        for(let i = 0; i < this.internalDiscuss.length; i++) {
        if (this.internalDiscuss[i].deal == null) {
            endInternalDiscuss = false;
        }
        }
        if (this.internalDiscuss.length <= 0) endInternalDiscuss = false; 
        if (endInternalDiscuss) return false;

        let isCreator = this.isCreator;
        let isEditable = this.userPermissions[USER_PERMISSION.INTERNAL_EDITABLE];
    
        return isCreator || isEditable;
    }

    /**
     * Điều kiện hiển thị nút đồng ý từ chối thẩm định
     */
    get allowConfirmInternalDiscuss() {
        if (this.discuss === false && this.internalDiscussType === InternalDiscussTypes.NO_REQUIRE) return false;

        if (this.currentStage !== "LC_DRAFT_DISCUSS" && this.currentStage !== "LC_DRAFT_DEAL" && this.currentStage != "LC_DRAFT_WAIT_CONFIRM") return false;

        let isDiscuss = this.userPermissions[USER_PERMISSION.INTERNAL_ACCEPTABLE];
        if (!isDiscuss) return false;

        if (this.discuss == false && this.internalDiscussType == InternalDiscussTypes.REQUIRE_FLOW) {
            // Trường hợp thẩm định theo tuần tự
            let userAssignee = this.currentUserIdConfirmInternalDiscuss;
            if (userAssignee != AuthService.instance.userId) return false;
        } else {
            // Các trường hợp hông theo tuần tự
            let cloneInternalDiscuss: InternalDiscuss[] = this.internalDiscuss.map((x) => {return x;});
            let currentUser: InternalDiscuss = null;
            currentUser = cloneInternalDiscuss.find((x) => { return x.userId === AuthService.instance.userId; });
            if (currentUser === null) return false;
            if (currentUser) {
              if (currentUser.deal === true || currentUser.deal === false) { return false; }
              // if (currentUser.per_action.INTERNAL_ACCEPTABLE === false) return false;
            }
        }
    
        return true;
    }

    /**
     * Tìm userId đến lượt thẩm định
     */
    get currentUserIdConfirmInternalDiscuss() {
        if (this.internalDiscussType != InternalDiscussTypes.REQUIRE_FLOW) return null;

        for (var i = 0; i < this.internalDiscuss.length; i++) {
            if (this.internalDiscuss[i].deal == null && this.internalDiscuss[i].permissions.includes(USER_PERMISSION.INTERNAL_ACCEPTABLE)) {
                return this.internalDiscuss[i].userId;
            }
        }

        return null;
    }

    /**
     * Tìm user đến lượt thẩm định
     */
    get currentUserInternalDiscuss() {
        if (this.internalDiscussType != InternalDiscussTypes.REQUIRE_FLOW) return null;

        for (var i = 0; i < this.internalDiscuss.length; i++) {
            if (this.internalDiscuss[i].deal == null && this.internalDiscuss[i].permissions.includes(USER_PERMISSION.INTERNAL_ACCEPTABLE)) {
                return this.internalDiscuss[i];
            }
        }

        return null;
    }

    /**
     * Sắp xếp lại internalDiscuss
     * @returns 
     */
    sortInternalDiscussData() {
        if (this.internalDiscussType != InternalDiscussTypes.REQUIRE_FLOW) return;
        if (this.internalDiscuss.length <= 0) return;
        this.internalDiscuss.forEach((x) => {
            if (x.sequence == null || x.sequence == "") return;
        });

        this.internalDiscuss.sort((a, b) => { return a.sequence - b.sequence; });
    }

    
    //MARK Goi y vi tri ky

    /**
     * Lấy thông tin 
     * @returns 
     */
    getSignFrameAssignee() {
        const internalSign = this.contractInternalData;
        let assignee: ContractInternal = null;
        
        for (var i = 0; i < internalSign.length; i++) {
        //Tim kiem nguoi dc assign ky hop dong
        if (internalSign[i].action == "" || internalSign[i].action == null) {
            assignee = internalSign[i];
            break;
        }
        }

        if (assignee != null && assignee.userId == AuthService.instance.userId) {
        return assignee.signFrame;
        } else {
        if (AuthService.instance.isAdmin) {
            const party = this.contractPartysData.find((item) => { return item.partyId == AuthService.instance.partyId });
            if (party && party.signFrame.length > 0) {
            return party.signFrame;
            }
        }
        return [];
        }
    }

    /**
     * Tra ve thong tin hinh thuc ky nhay hay ky duyet cua user hien tai
     * @returns 
     */
    getSignTypeOfCurrentUser() {
        let n = this.contractInternalData.length;
        if (n > 0 && this.contractInternalData[n - 1].userId === AuthService.instance.userId) {
            return this.contractInternalData[n - 1].signType;
        }
        return "";
    }

    get allowAddSignBox() {
        let signFrame = this.getSignFrameAssignee();
        return signFrame.length == 0 || this.fixedPosition == false; 
    }
    
    /**
     * Kiểm tra quyền xoá hợp đồng
     * @returns 
     */
    allowDelete() {
        if (!AuthService.instance.checkRoles(['ESO2007'])) return false;
        if (!AuthService.instance.isAdmin && !this.isCreator) return false;
        return true;
    }

    /**
     * Kiểm tra quyền được thêm bình luận và trả lời bình luận
     */
    get allowComment() {
        if (this.currentStage !== "LC_DRAFT_CREATE" && this.currentStage !== "LC_DRAFT_WAIT_CONFIRM" && this.currentStage !== "LC_DRAFT_DISCUSS") {
            return false;
        }

        let userDiscuss = this.partnerDiscuss.find((x) => { return x.userId == AuthService.instance.userId; });
        let userInternalDiscuss = this.internalDiscuss.find((x) => { return x.userId == AuthService.instance.userId; });
        let userSignFlow = this.signFlow.find((x) => { return x.userId == AuthService.instance.userId; });
        let allowedEmployee = (userDiscuss || userInternalDiscuss || userSignFlow);

        return (this.isCreator || AuthService.instance.isAdmin || allowedEmployee);
    }

    /**
     * Kiểm tra quyền được sửa tên, xoá phiên bản
     */
    get allowEditVersionInfo() {
        if (this.currentStage !== "LC_DRAFT_CREATE" && this.currentStage !== "LC_DRAFT_WAIT_CONFIRM" && this.currentStage !== "LC_DRAFT_DISCUSS") {
            return false;
        }

        return this.isCreator;
    }
    
    /**
     * Điều kiện cho phép gọi api lấy thông tin cấu hình số hợp đồng
     * @returns 
     */
    allowGetDetailContractNumber() {
        if (this.currentStage == "LC_DRAFT_CREATE") {
            return this.isOwnerParty && this.isCreator;
        } else {
            return this.isAllowedSign && this.isOwnerParty;
        }
    }

    /**
     * Lấy thông tin chữ ký bị cảnh báo
     */
    getSignatureWarningId() {
        for (let element of this.contractPartysData) {
            if (element.signatures.length > 0) {
                for (let e of element.signatures) {
                    if (e.status == "WARNING") {
                        return e.signId;
                    }
                }
            }
        }
    }

    /**
     * Điều kiện Gen và hêm Contract vào PDF
     * @returns 
     */
    allowGenAddContractNumber() {
        if (this.sequenceId == "" || this.sequenceId == null) {
            return false;
        }
        return this.allowGetDetailContractNumber();
    }

    /**
     * Lấy thông tin bên tham gia có chữ ký lỗi
     * @returns Trả về partyId của bên tham gia
     */
    getPartyHaveWarningSignature() {
        for (let element of this.contractPartysData) {
            if (element.signatures.length > 0) {
                for (let e of element.signatures) {
                    if (e.status == "WARNING") {
                        return element.partyId;
                    }
                }
            }
        }
    }

    /**
     * Lấy danh sách các bên tham gia hợp đồng
     * @returns partiesJoin: ds thông tin các bên tham gia hợp đồng
     */
    getPartyJoin() {
        let partiesJoin: Array<PartyJoinContract> = this.contractPartysData.map((item) => {
            var party = new PartyJoinContract({
                partyId: item.partyId,
                tenToChuc: item.tenToChuc,
                userType: item.userType,
                email: item.email,
                tenDaiDien: item.tenDaiDien,
                endDate: item.endDate,
                isTrustedPartner: item.isTrustedPartner,
                signForm: item.signForm,
                verificationType: item.verificationType,
                nguoiNhanData: [
                    {
                        id: item.partyId,
                        text: item.email,
                        additional: {
                            partyId: item.partyId,
                            type_customer: item.userType === 'CONSUMER' ? 'Cá nhân' : 'Doanh nghiệp',
                            type_cutomer_label: item.userType,
                            email: item.email,
                            isTrustedPartner: item.isTrustedPartner
                        }
                    }
                ],
                sequence: item.sequence
            });

            if (item.partyId === AuthService.instance.partyId) {
                party.allowDetete = false;
                party.allowEditUserType = false;
                party.allowEditPartyId = false;
                party.isOwner = true;
            }

            return party;
        })

        return partiesJoin;
    }

    get allowSendRequestResign() {
        return this.currentStage == 'LC_DRAFT_SIGN_FAIL' && this.isCreator;
    }

    get partySignFailed() {
        for (let i = 0; i < this.contractPartysData.length; i++) {
            const partyData = this.contractPartysData[i];
            if (partyData.signDate && partyData.signerId) {
                const signature = partyData.signatures.findIndex((x) => { return x.status == "FAIL" });
                if (signature >= 0) return partyData;
            }
        }

        return null;
    }
}

export class SignatureItem {
    signId: string = '';
    userId: string = '';
    contractId: string = '';
    documentId: string = '';
    signForm: string = '';
    role: string = '';
    signHash: string = '';
    signDate: string = '';
    tranId: string = '';
    partyId: string = '';

    ekycInfo: any = null;
    identifierCode: any = null;
    name: any = null;
    pathImg: any = null;
    
    constructor(data = null) {
        data = data || {};
        this.signId = data.signId || '';
        this.userId = data.userId || '';
        this.documentId = data.documentId || '';
        this.contractId = data.contractId || '';
        this.signForm = data.signForm || '';
        this.role = data.role || '';
        this.signHash = data.signHash || '';
        this.signDate = data.signDate || '';
        this.tranId = data.tranId || '';
        this.partyId = data.partyId || '';
    }
}

export class Customer {
    userId: string = '';
    email: string = '';
    
    constructor(data = null) {
        data = data || {};
        this.userId = data.userId || '';
        this.email = data.email || '';
    }
}

