<input #output id="output" class="" value="" type="hidden">
<input #filebase id="fileBase" class="" type="hidden">
<input #filename id="fileName" class="" value="{{ fileName }}">
<div class="wrapper">
    <div class="preview-overlay">

        <div class="preview-container">
            <div class="preview-header d-flex">
                <div (click)="goToBack()"
                    class="ct-header-btn-back d-flex justify-content-center align-items-center cursor-pointer">
                    <span class="material-icons">arrow_back_ios</span>
                </div>
                <div class="ct-title flex-grow-1 d-flex align-items-center px-3">
                    <h4 class="mb-0">Hợp đồng điện tử</h4>
                </div>
                <div class="ct-toolbar-btn d-flex align-items-center">
                    <button (click)="saveContract()"
                        class="btn btn-outline-primary d-flex align-items-center mr-3"><span
                            class="material-icons mr-2">get_app</span> Tải xuống</button>
                    <!-- <button class="btn btn-outline-primary d-flex align-items-center mr-3"><span
                            class="material-icons mr-2">clear</span> Hủy</button> -->
                    <button *ngIf="checkAllowEdit()" (click)="goToEdit()"
                        class="btn btn-outline-primary d-flex align-items-center mr-3"><span
                            class="material-icons mr-2">create</span> Chỉnh sửa</button>
                    <!-- <button (click)="actBtnSave($event)" [disabled]="editedForm"
                        class="btn btn-outline-primary d-flex align-items-center mr-3"><span
                            class="material-icons mr-2">save</span> Lưu</button> -->
                    <button *ngIf="allowSendContract && !notHaveSignTypeAllow" (click)="sendContract()"
                        class="btn btn-primary d-flex align-items-center mr-3"><span
                            class="material-icons mr-2">send</span> Gửi</button>
                </div>
            </div>

            <div class="preview-body">
                <div class="pv ct">
                    <div class="pv-body">
                        <div class="ctpv-container">
                            <div class="ctpv-content">
                                <div class="ct-container">
                                    <div class="ct-thumb-page">
                                        <div class="ct-vs ct-enable-scroll">
                                            <ol id="thumbnail-page" class="ct-vs-list mt-3">
                                            </ol>
                                        </div>
                                    </div>
                                    <div id="ppi"
                                        style="height: 1in; width: 1in; position: absolute; top: -100%; left: -100%;">
                                    </div>
                                    <div class="ct-content">
                                        <div id="parentView" class="ct-doc-loaded ct-enable-scroll">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="ct ctpv-tool-bar">
                            <div class="ctpv-tool-sidebar">
                                <div class="ctpv-tabbar">
                                    <div class="ctpv-main">
                                        <button (click)="selectTab('INFO')" class="ctpv-btn"
                                            [class.active]="activeTab == 'INFO'"> <span
                                                class="material-icons-outlined">info</span></button>
                                        <button (click)="selectTab('SIGN')" class="ctpv-btn"
                                            [class.active]="activeTab == 'SIGN'"> <span
                                                class="material-icons">gesture</span></button>
                                    </div>
                                </div>
                                <div class="ctpv-footer">

                                </div>
                            </div>
                            <div class="ctpv-tool-content">
                                <div class="ctpv-content-wrapper px-3">
                                    <div id="tab-info" class="ctpv-scroll-content"
                                        [class.d-none]="!(activeTab === 'INFO')">
                                        <h5 class="mt-3">Thông tin hợp đồng</h5>
                                        <div class="row mt-3">
                                            <div class="col-lg-6">
                                                <p><label>Tên hợp đồng</label></p>
                                                <p>{{ contract.title }}</p>
                                            </div>
                                            <!-- <div class="col-lg-6">
                                                <p><label>File hợp đồng</label></p>
                                                <p>{{ contract.title }}</p>
                                            </div> -->
                                        </div>

                                        <div class="row mt-3">
                                            <div class="col-lg-6">
                                                <p><label>Ngày có hiệu lực</label></p>
                                                <p>{{ contract.validDate | date: 'dd/MM/yyyy'}}</p>
                                            </div>
                                            <div class="col-lg-6">
                                                <p><label>Ngày hết hiệu lực</label></p>
                                                <p>{{ contract.endDate | date: 'dd/MM/yyyy' }}</p>
                                            </div>
                                        </div>

                                        <div class="row mt-3">
                                            <div class="col-lg-6">
                                                <p><label>Tự động gia hạn hợp đồng</label></p>
                                                <p>{{ contract.autoRenew ? 'Có' : 'Không' }}</p>
                                            </div>
                                            <div class="col-lg-6">
                                                <p><label>Giá trị hợp đồng</label></p>
                                                <p>{{ contract.contractValue }}</p>
                                            </div>
                                        </div>

                                        <div class="row mt-3">
                                            <div class="col-lg-6">
                                                <p><label>Người nhận</label></p>
                                                <p>{{ contract.customer.email }}</p>
                                            </div>
                                            <div class="col-lg-6">
                                                <p><label>Loại khách hàng</label></p>
                                                <p>{{ contract.customerType === 'CONSUMER' ? 'Cá nhân' : 'Doanh nghiệp' }}
                                                </p>
                                            </div>
                                        </div>

                                        <div class="row mt-3">
                                            <div class="col-lg-12">
                                                <p><label>Hình thức ký</label></p>
                                                <p>
                                                    <span *ngFor="let item of contract.signForm; let i = index">
                                                        {{ item === 'OTP' ? 'OTP' : '' }}
                                                        {{ item === 'ONE_TIME_CA' ? 'Ký số 1 lần' : '' }}
                                                        {{ item === 'EKYC' ? 'OTP và EKYC' : '' }}
                                                        {{ item === 'USB_TOKEN' ? 'USB-Token' : '' }}
                                                        {{ item === 'SIGN_SERVER' ? 'VNPT ký số' : '' }}
                                                        {{ i < contract.signForm.length - 1 ? ', ' : ''}}
                                                    </span>
                                                </p>

                                            </div>
                                        </div>

                                        <div class="row mt-3">
                                            <div class="col-lg-6">
                                                <p><label>Thời hạn ký</label></p>
                                                <p>{{ contract.signInDays }}</p>
                                            </div>
                                            <div class="col-lg-6">
                                                <p><label>Ghi chú</label></p>
                                                <p>{{ contract.creationNote }}</p>
                                            </div>
                                        </div>

                                        <div class="row mt-3">
                                            <div class="col-lg-12">
                                                <p><label>Trạng thái</label></p>
                                                <p [innerHtml]="getStatusContract"></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="tab-sign" class="ctpv-scroll-content"
                                        [class.d-none]="!(activeTab === 'SIGN')">
                                        <div *ngIf="checkAllowSign(); else notAllowSign" id="action-SIGN" class="">
                                            <div class="w-100 d-flex mt-3">
                                                <h5 class="">Ký hợp đồng</h5>
                                                <button *ngIf="selectedWayOfSign == 'SIGN_SERVER'"
                                                    (click)="finishSignServer()"
                                                    class="btn btn-outline-primary d-flex align-items-center ml-auto"><span
                                                        class="material-icons">done</span>Hoàn thành</button>
                                                <button *ngIf="selectedWayOfSign == 'E_SIGN'"
                                                    (click)="sendRequestElectronicSign()"
                                                    class="btn btn-outline-primary d-flex align-items-center ml-auto"><span
                                                        class="material-icons">done</span>Hoàn thành</button>
                                            </div>

                                            <p>Vui lòng thực hiện cấu hình chữ ký</p>

                                            <div class="row">
                                                <div class="col-12 d-flex-block">
                                                    <div *ngIf="userType === 'BUSINESS' || userType === 'ENTERPRISE'" class="w-100 d-flex">
                                                        <button *ngIf="allowActionSignType('USB_TOKEN')"
                                                            (click)="selectWayOfSign('USB_TOKEN')"
                                                            [class.btn-outline-primary]="selectedWayOfSign !== 'USB_TOKEN'"
                                                            [class.btn-primary]="selectedWayOfSign === 'USB_TOKEN'"
                                                            class="btn btn-outline-primary d-flex align-items-center mr-1"><span
                                                                class="material-icons mr-1">usb</span>Ký số
                                                            USB-Token</button>
                                                        <button *ngIf="allowActionSignType('SIGN_SERVER')"
                                                            (click)="selectWayOfSign('SIGN_SERVER')"
                                                            [class.btn-outline-primary]="selectedWayOfSign !== 'SIGN_SERVER'"
                                                            [class.btn-primary]="selectedWayOfSign === 'SIGN_SERVER'"
                                                            class="btn btn-outline-primary d-flex align-items-center mr-1"><span
                                                                class="material-icons mr-1">gesture</span>Ký số
                                                            sign-server</button>
                                                    </div>

                                                    <div *ngIf="userType === 'CONSUMER'" class="w-100 d-flex">
                                                        <button *ngIf="allowActionSignType('OTP')"
                                                            (click)="selectWayOfSign('E_SIGN')"
                                                            [class.btn-outline-primary]="selectedWayOfSign !== 'E_SIGN'"
                                                            [class.btn-primary]="selectedWayOfSign === 'E_SIGN'"
                                                            class="btn btn-outline-primary d-flex align-items-center mr-1"><span
                                                                class="material-icons mr-1">lock</span>Ký điện
                                                            tử</button>
                                                    </div>
                                                    <div *ngIf="notHaveSignTypeAllow" class="w-100 d-flex">
                                                        <p class="text-danger">Bạn không có phương thức ký nào khả dụng
                                                            đối với hợp đồng này!</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="content-tab">
                                                <div id="usb-token" [class.d-none]="selectedWayOfSign !== 'USB_TOKEN'">
                                                    <!--- USB TOKEN -->
                                                </div>

                                                <div id="sign-server"
                                                    [class.d-none]="selectedWayOfSign !== 'SIGN_SERVER'">
                                                    <div class="mt-3">
                                                        <label class="text-primary">Danh sách chữ ký số:</label>
                                                        <hr class="mb-3">
                                                        <table id="list-signbox"
                                                            class="list-signature table table-sm table-bordered header-freeze">
                                                            <thead>
                                                                <tr>
                                                                    <th>STT</th>
                                                                    <th class="th_sig_rectangle">Thông số toạ độ</th>
                                                                    <th>Trang hiển thị chữ ký</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            </tbody>
                                                        </table>
                                                        <button role="button"
                                                            class="btn d-flex align-items-center text-primary px-0"
                                                            (click)="addSignBox()">
                                                            <span class="material-icons">add</span>Thêm chữ
                                                            ký</button>
                                                    </div>

                                                    <div class="row mt-3">
                                                        <form [formGroup]="formSign" class="col-md-12 row">
                                                            <div class="col-xl-6">
                                                                <div class="infor-box-header d-flex">
                                                                    <label class="text-primary flex-grow-1 mb-0">Nội
                                                                        dung mô
                                                                        tả chữ
                                                                        ký số</label>
                                                                </div>
                                                                <hr>
                                                                <div class="row">
                                                                    <div class="col-xl-12">
                                                                        <div
                                                                            class="ccheck-primary w-100 d-inline-block">
                                                                            <input (change)="changedDateSign($event)"
                                                                                formControlName="signDate"
                                                                                type="checkbox" id="signDate">
                                                                            <label for="signDate" class="w-100">
                                                                                Thời gian ký
                                                                            </label>
                                                                        </div>
                                                                        <div
                                                                            class="ccheck-primary w-100 d-inline-block">
                                                                            <input (change)="changedSignBy($event)"
                                                                                formControlName="signBy" type="checkbox"
                                                                                id="signBy">
                                                                            <label for="signBy" class="w-100">
                                                                                Ký bởi
                                                                            </label>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                                <div class="row mt-3">
                                                                    <div class="col-xl-12 align-items-end d-flex">
                                                                        <div class="form-group w-100">
                                                                            <label>Cỡ chữ:</label>
                                                                            <input formControlName="fontSize"
                                                                                (input)="inputFontSize($event)"
                                                                                type="text" class="form-control"
                                                                                placeholder="Nhập cỡ chữ">
                                                                            <app-show-validate-errors
                                                                                [isSubmit]="isSubmit"
                                                                                [errorMessages]="validMsg.fontSize"
                                                                                [control]="formSign.controls.fontSize"
                                                                                [detail]="{ name: 'fontSize' }">
                                                                            </app-show-validate-errors>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div class="infor-box-header d-flex mt-3">
                                                                    <label class="text-primary flex-grow-1 mb-0">Hình
                                                                        thức hiển thị chữ ký số</label>
                                                                </div>
                                                                <hr>
                                                                <div class="row">
                                                                    <div class="col-xl-12">
                                                                        <label for="signType">Chọn hình thức hiển
                                                                            thị:</label>
                                                                        <select (change)="selectStyleSignBox($event)"
                                                                            #visibleType name="visibleType"
                                                                            id="visibleType" class="form-control"
                                                                            formControlName="visibleType">
                                                                            <option value="" disabled>Chọn hình thức
                                                                                hiển
                                                                                thị
                                                                            </option>
                                                                            <option *ngFor="let opt of hinhThucHienThi"
                                                                                value="{{ opt.value }}">
                                                                                {{ opt.name }}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-6">
                                                                <div class="infor-box-header">
                                                                    <label class="text-primary flex-grow-1 mb-0">Hình
                                                                        ảnh
                                                                        chữ ký
                                                                        số</label>
                                                                </div>
                                                                <hr>
                                                                <div class="row">
                                                                    <div class="col-md-12">
                                                                        <div class="form-group">
                                                                            <label>File đính kèm:</label>
                                                                            <div class="input-group mb-1">
                                                                                <div
                                                                                    class="custom-file custom-file-icon">
                                                                                    <span
                                                                                        class="icon-file material-icons">attach_file</span>
                                                                                    <label class="custom-file-label"
                                                                                        for="inputFileImage">{{ inputFileImageLabel }}</label>
                                                                                    <input formControlName="imageFile"
                                                                                        (input)="handleFileImageInput($event)"
                                                                                        type="file" accept="image/*"
                                                                                        class="custom-file-input"
                                                                                        id="inputFileImage"
                                                                                        aria-describedby="inputGroupFileAddon01">
                                                                                </div>
                                                                            </div>
                                                                            <app-show-validate-errors
                                                                                [isSubmit]="isSubmit"
                                                                                [errorMessages]="validMsg.imageFile"
                                                                                [control]="formSign.controls.imageFile"
                                                                                [detail]="{ name: 'imageFile' }">
                                                                            </app-show-validate-errors>
                                                                        </div>
                                                                        <img *ngIf="imgContent" class="w-100"
                                                                            [src]="imgContent">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>

                                                <div id="digital-sign" [class.d-none]="selectedWayOfSign !== 'E_SIGN'">
                                                    <!--- DIGITAL SIGN -->
                                                    <div class="mt-3">
                                                        <label class="text-primary">Danh sách chữ ký số:</label>
                                                        <hr class="mb-3">
                                                        <table id="list-signbox"
                                                            class="list-signature table table-sm table-bordered header-freeze">
                                                            <thead>
                                                                <tr>
                                                                    <th>STT</th>
                                                                    <th class="th_sig_rectangle">Thông số toạ độ</th>
                                                                    <th>Trang</th>
                                                                    <th>Thao tác</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            </tbody>
                                                        </table>
                                                        <button role="button"
                                                            class="btn d-flex align-items-center text-primary px-0"
                                                            (click)="addSignBox()">
                                                            <span class="material-icons">add</span>Thêm chữ
                                                            ký</button>
                                                    </div>

                                                    <div class="row mt-3">
                                                        <div class="col-md-6">
                                                            <form [formGroup]="formDSign">
                                                                <div class="form-group">
                                                                    <label>Hình ảnh chữ ký số <span
                                                                            class="text-danger">*</span>:</label>
                                                                    <div class="input-group mb-1">
                                                                        <div class="custom-file custom-file-icon">
                                                                            <span
                                                                                class="icon-file material-icons">attach_file</span>
                                                                            <label class="custom-file-label"
                                                                                for="inputGroupFile03">{{ inputImgESignLabel }}</label>
                                                                            <input formControlName="imageFile"
                                                                                (input)="handleFileImgESignInput($event)"
                                                                                type="file" accept="image/*"
                                                                                class="custom-file-input"
                                                                                id="inputGroupFile03"
                                                                                aria-describedby="inputGroupFileAddon01">
                                                                        </div>
                                                                    </div>
                                                                    <app-show-validate-errors [isSubmit]="isESignSubmit"
                                                                        [errorMessages]="validMsgESign.imageFile"
                                                                        [control]="formDSign.controls.imageFile"
                                                                        [detail]="{ name: 'imageFile' }">
                                                                    </app-show-validate-errors>
                                                                </div>
                                                            </form>

                                                            <img *ngIf="imgESignContent" class="w-100"
                                                                [src]="imgESignContent">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <ng-template #notAllowSign>
                                            <div id="detail-SIGN" class="">
                                                <div class="mt-3">
                                                    <label class="text-primary">Thông tin chữ ký điện tử</label>
                                                    <hr class="mb-3">
                                                    <p *ngIf="listSignature.kyDienTu.length <= 0">Không có thông tin chữ
                                                        ký điện tử</p>
                                                    <div *ngFor="let item of listSignature.kyDienTu"
                                                        class="border-bottom mb-3">
                                                        <div class="row">
                                                            <div class="col-xl-6">
                                                                <p><label>Tên người ký</label></p>
                                                                <p>{{ item.fullname }}</p>
                                                            </div>
                                                            <div class="col-xl-6">
                                                                <p><label>Số CMND/CCCD/Hộ chiếu</label></p>
                                                                <p>{{ item.idCard }}</p>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-xl-6">
                                                                <p><label>Ngày ký</label></p>
                                                                <p>{{ item.signDate }}</p>
                                                            </div>
                                                            <div class="col-xl-6">
                                                                <p><label>Hình ảnh</label></p>
                                                                <img *ngIf="item.base64Image !== ''" class="w-100"
                                                                    [src]="img_prefix + item.base64Image">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="mt-3">
                                                    <label class="text-primary">Thông tin chữ ký số</label>
                                                    <hr class="mb-3">
                                                    <p *ngIf="listSignature.kySo.length <= 0">Không có thông tin chữ ký
                                                        số</p>
                                                    <div *ngFor="let item of listSignature.kySo"
                                                        class="border-bottom mb-3">
                                                        <div class="row">
                                                            <div class="col-xl-6">
                                                                <p><label>Chủ chứng thư</label></p>
                                                                <p>{{ item.subject.common_name }}</p>
                                                            </div>
                                                            <!-- <div class="col-xl-6">
                                                                <p><label>Chức vụ</label></p>
                                                                <p>{{ item.idCard }}</p>
                                                            </div> -->
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-xl-6">
                                                                <p><label>Số giấy tờ</label></p>
                                                                <p>{{ item.subject.user_id }}</p>
                                                            </div>
                                                            <div class="col-xl-6">
                                                                <p><label>Nhà cung cấp</label></p>
                                                                <p>{{ item.issuer.common_name }}</p>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-xl-6">
                                                                <p><label>Ngày hiệu lực</label></p>
                                                                <p>{{ item.not_before }}</p>
                                                            </div>
                                                            <div class="col-xl-6">
                                                                <p><label>Ngày hết hạn</label></p>
                                                                <p>{{ item.not_after }}</p>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-xl-6">
                                                                <p><label>Ngày ký</label></p>
                                                                <p>{{ item.signDate }}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

<ng-template #contentConfirmOTP let-c="close" let-d="dismiss">
    <div class="modal-header border-0 pb-0 mb-0">
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span class="material-icons">
                cancel
            </span>
        </button>
    </div>
    <div class="modal-body py-0">
        <h5 class="modal-title w-100 text-center mb-3" id="modal-basic-title">Nhập mã xác nhận OTP</h5>
        <form [formGroup]="formOTP">
            <div class="form-group">
                <label>Mã OTP <span class="text-danger">*</span>: </label>
                <input formControlName="otp" class="form-control" type="text" placeholder="Nhập mã OTP">
                <app-show-validate-errors [isSubmit]="isOTPSubmit" [errorMessages]="validMsgOTP.otp"
                    [control]="formOTP.controls.otp" [detail]="{ name: 'isOTPSubmit' }"></app-show-validate-errors>
            </div>
        </form>
    </div>
    <div class="modal-footer border-0 d-flex justify-content-center">
        <button type="button" class="btn btn-primary" (click)="submitOTP()">Xác nhận</button>
    </div>
</ng-template>