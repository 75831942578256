import { LocationExtension } from './../../../utils/common/LocationExtension';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { GlobalConfig } from 'src/app/utils/common/global-config/global-config';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/utils/services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AasValidateMessage } from 'src/app/utils/common/validate-msg-aas';
import { NgModalComponent } from 'src/app/layout/extensions/ng-modal/ng-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { StatusCode } from 'src/app/utils/common/status-code';
import { REGEXP_EMAIL } from 'src/app/utils/common/regexp';

@Component({
    selector: 'app-service-forgot-password',
    templateUrl: './service-forgot-password.component.html',
    styleUrls: ['./service-forgot-password.component.scss']
})
export class ServiceForgotPasswordComponent implements OnInit, AfterViewInit {


    inputEmailForm: FormGroup;
    // inputSdtForm: FormGroup;

    sendEmailFailed = false;
    // sendSdtFailed = false;

    isSubmit = false;
    // activeConsumer = true;
    // activeBusiness = false;

    // isSubmitConsumer = false;
    // isSubmitBusiness = false;

    ServiceMsg = AasValidateMessage.FORGOT_PASSWORD;
    createSendEmailSuccess: Boolean = false;

    constructor(
        private router: Router,
        private authService: AuthService,
        private aasAuth: AasAuthService,
        private modalService: NgbModal
    ) { }

    ngOnInit(): void {
        // if (!GlobalConfig.ORIGIN_SERVICE.includes(location.hostname)) {
        //     this.router.navigate(['/forgot-password']);
        // }

        this.makeSendEmailForm();
        // this.makeSendSdtForm();
    }

    ngAfterViewInit(): void {
        var videos = document.getElementsByTagName("video");
        for (var i = 0; i < videos.length; i++) {
          videos[i].muted = true;
          videos[i].play();
        } 
    }    

    //MARK: Khởi tạo form send email
    makeSendEmailForm() {
        this.inputEmailForm = new FormGroup({
            email: new FormControl(null, [Validators.required, Validators.pattern(REGEXP_EMAIL)]),
        });
    }
    // /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    // /^[a-zA-Z][a-zA-Z0-9_\.]{5,32}@[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,4}){1,2}$/

    get email() { return this.inputEmailForm.get('email') }

    //MARK: Khởi tạo form send sdt
    // makeSendSdtForm() {
    //     this.inputSdtForm = new FormGroup({
    //         sdt: new FormControl(null, [Validators.required, Validators.pattern(/^0([0-9]{9})$/)]),
    //     });
    // }

    // get sdt() { return this.inputSdtForm.get('sdt') }

    //MARK: Action chức năng quên mật khẩu
    sendEmail() {
        this.isSubmit = true;
        this.sendEmailFailed = false;
        this.clearSpace();
        if (this.inputEmailForm.valid) {
            this.postCreateForgotPasswordV2(this.inputEmailForm.value.email);
        }
    }

    // sendSdt() {
    //     this.isSubmitConsumer = true;
    //     this.sendSdtFailed = false;
    //     this.clearSpace();
    //     if (this.inputSdtForm.valid) {
    //         this.postCreateForgotPassword('CONSUMER', this.inputSdtForm.value.sdt);
    //     }
    // }

    //MARK: Chức năng chuyển đổi giữa đăng ký cá nhân và tổ chức
    // changeFormActive(type: string) {
    //     this.resetAllForm();
    //     this.createSendEmailSuccess = false;
    //     switch (type) {
    //         case 'BUSINESS':
    //             this.activeBusiness = true;
    //             break;
    //         case 'CONSUMER':
    //             this.activeConsumer = true;
    //             break;
    //         default:
    //             this.activeConsumer = true;
    //             break;
    //     }
    // }

    // resetAllForm() {
    //     this.activeBusiness = false;
    //     this.activeConsumer = false;
    //     this.isSubmitConsumer = false;
    //     this.isSubmitBusiness = false
    // }

    //Handle nhập số điện thoại
    // handleInputSdt(event) {
    //     const regex = new RegExp(/^\d+$/);
    //     if (regex.test(event.target.value) && event.target.value.length <= 10) {
    //         return;
    //     } else {
    //         this.sdt.setValue(event.target.value.slice(0, -1));
    //     }
    // }

    //MARK: Trim value formControl
    clearSpace() {
        let str = `${this.email.value || ''}`;
        this.email.setValue(`${str.trim()}`);
    }

    //MARK: Popup thông báo
    modal(msg, callBack = () => { }) {
        const modalRef = this.modalService.open(NgModalComponent);
        modalRef.componentInstance.message = msg;
        modalRef.componentInstance.callback = callBack;
    }

    alert(msg, type, callBack = () => { }) {
        const modalRef = this.modalService.open(NgModalComponent);
        modalRef.componentInstance.message = msg;
        modalRef.componentInstance.typeAlert = type;
        modalRef.componentInstance.notification = true;
        modalRef.componentInstance.callback = callBack;
    }

    //MARK: NETWORKING
    // postCreateForgotPassword(type, user, domain = LocationExtension.HOSTNAME) {
    //     this.aasAuth.postCreateForgotPassword(type, user, domain).subscribe(res => {
    //         if (type === 'CONSUMER') {
    //             this.router.navigate(['/app/request-otp'], { queryParams: { 'type': 'CONSUMER', 'redirectTo': 'FORGOTPASSWORD', 'user': user } });
    //         } else if (type === 'BUSINESS') {
    //             this.createSendEmailSuccess = true;
    //         }
    //     }, err => {
    //         console.log(err);
    //         if (err.error.message && ResponseMsg.MESSAGES.FORGOT_PASSWORD[err.error.message]) {
    //             this.alert(ResponseMsg.MESSAGES.FORGOT_PASSWORD[err.error.message], 'error');
    //         } else {
    //             this.alert(err.error.message, 'error');
    //         }
    //     }, () => {

    //     });
    // }
    postCreateForgotPasswordV2(email, domain = LocationExtension.HOSTNAME) {
        this.aasAuth.postCreateForgotPasswordV2(email, domain).subscribe((res: any) => {
            this.router.navigate(['/app/request-otp'], { queryParams: {'redirectTo': 'FORGOTPASSWORD', 'user': email} });
        }, err => {
            console.log(err);
            if (err.error.message && ResponseMsg.MESSAGES.FORGOT_PASSWORD[err.error.message]) {
                this.alert(ResponseMsg.MESSAGES.FORGOT_PASSWORD[err.error.message], 'error');
            } else {
                this.alert(err.error.message, 'error');
            }
        }, () => {});
    }
}
