import { SignBox } from "./sign-box";

declare var PDFSign: any;
declare var $: any;
export class ContractNumberBox {

    action: string = "";
    box: SignBox = null;
    text: string;

    showSignBoxes = false;
    isAddedBox = false;

    constructor(data = null) {
        data = data || {};
        this.action = data.action || "";
        this.text = data.text || data.name || "";
        // this.addSignBox();
    }

    /**
     * Ẩn danh sách khung ký
     */
    // hideListSignBox() {
    //     this.showSignBoxes = false;
    //     this.hideAllSignBox();
    // }
    /**
     * Hiển thị danh sách khung ký
     */
    // showListSignBox() {
    //     this.showSignBoxes = true;
    //     this.showAllSignBox();

    // }
    /**
     * thêm khung ký
     */
    addSignBox(box = null) {
        let bbox = box || this.box || null;
        console.log("bbox: ", bbox);
        if (bbox == null || bbox == undefined) return;
        PDFSign.addSignBox(bbox);
        PDFSign.setStyleSelectedSignBox(7);
        PDFSign.setCustomizeText(this.text);
        let listSignBox = PDFSign.getListSignBox();
        if (listSignBox && listSignBox.length > 0) 
            this.box = listSignBox[listSignBox.length - 1];
        this.isAddedBox = true;
        this.updateStyleSignBox(this.box.id);
    }

    /**
     * Cập nhật css cho signbox của số hợp đồng
     * @param id Id của signbox
     */
    updateStyleSignBox(id) {
        var self = 'div.dragSignature[data-id="' + id + '"]';
        var signBox = $(self);
        signBox.find('.signbox-content').css({ "padding": 0 });
        signBox.find('.signbox-text').css({ "vertical-align": "unset", "text-align": "left"});
    }

    /**
     * Cập nhật lại toạ độ của khung ký
     */
    updateSignBox() {
        let listSignBox = PDFSign.getListSignBox();
        if (listSignBox && listSignBox.length > 0) {
            let box = listSignBox.find((x) => { return x.id == this.box.id})
            if (box) {
                this.box = box;
            }
        }
    }

    changeText(text: string) {
        this.text = text;
        PDFSign.setCustomizeText(this.text);
    }

    showBox() {
        PDFSign.addSignBox(this.box);
        PDFSign.setCustomizeText(this.text);
    }

    removeBox() {
        if (this.box && this.box.id) {
            PDFSign.deleteSigBoxById(this.box.id);
        } else {
            PDFSign.removeAllSignBox();
        }
        this.isAddedBox = false;
    }

    getBoxValue(): any[] {
        if (this.box == null || this.box == undefined) return null;

        let x = this.box.x + this.box.w;
        let y = this.box.y;
        let x2 = this.box.x;
        let y2 = this.box.y - this.box.h;
        let page = this.box.page;
        
        return [{
            pageSign: page - 1,
            bboxSign: [x2, y2, x, y]
        }];
    }
    /**
     * Xóa khung kí
     * @param index 
     */
    // removeSignBox(index: any) {
    //     let idSignBox = this.signBoxes[index].id;
    //     PDFSign.deleteTemplateSignBox(idSignBox);
    //     this.signBoxes.splice(index, 1);
    //     if (this.signBoxes.length === 0) {
    //         this.hideListSignBox();
    //     }
    // }

    /**
     * Đồng bộ danh sách từ pdfsign plugin và dữ liệu trên site
     */
    // syncListSignBox() {
    //     let listSignBox = PDFSign.getListSignBox();
    //     this.signBoxes = [];
    //     if (listSignBox && listSignBox instanceof Array) {
    //         listSignBox.forEach((item: any) => {
    //             this.signBoxes.push(new SignBox({
    //                 x: item.x,
    //                 y: item.y,
    //                 w: item.w,
    //                 h: item.h,
    //                 page: item.page,
    //                 id: item.id
    //             }))
    //         })
    //     }
    // }

    /**
     * Ẩn tất cả khung chứ ký
     */
    // hideAllSignBox() {
    //     PDFSign.removeAllSignBox();
    // }

    // showAllSignBox() {
    //     console.log(this.signBoxes);
    //     this.signBoxes.forEach((item: SignBox) => {
    //         PDFSign.addTemplateSignBox(item.changedSizeSignBox.bind(item), item);
    //     })
    // }
}