import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-guide-tour',
  templateUrl: './guide-tour.component.html',
  styleUrls: ['./guide-tour.component.scss'],
})
export class GuideTourComponent implements OnInit {
  steps = [];
  currentIdx = 0;
  currentStep: any;
  guide: any;
  modal: any;
  btnForward: any;
  btnBack: any;
  box: any;
  constructor() {}

  ngOnInit(): void {}
  ngAfterViewInit(): void {
    this.guide = document.querySelector<HTMLElement>('.guide-tour');
    this.modal = document.querySelector<HTMLElement>('.modal');
    // this.btnForward = document.querySelector<HTMLButtonElement>('.modal-footer_btn-forward');
    // this.btnBack = document.querySelector<HTMLButtonElement>('.modal-footer_btn-back');
    this.box = document.querySelector('#box');
  }
  recieveData(data: any) {
    this.steps = data;
    this.currentStep = this.steps[this.currentIdx];
    this.createModal(this.currentStep);
    this.guide.style.display = 'block';
  }
  closeGuide() {
    this.guide.style.display = 'none';
    this.steps = [];
    this.currentStep = null;
    this.currentIdx = 0;
    this.deleteTransparentBox();
    this.introduceModal();
    window.localStorage.setItem('firstCreate', '0');
  }
  nextStep() {
    if (this.currentIdx > 0) {
      let prevElement = document.querySelector<HTMLElement>(
        `[data-guide=${this.currentStep.elementAttribute}]`
      );
      if (prevElement) prevElement.style.zIndex = '0';
    }
    this.currentIdx += 1;
    this.currentStep = this.steps[this.currentIdx];
    // if (this.currentIdx == 1) {
    //   this.btnBack.style.visibility = 'visible';
    // }
    if (this.currentIdx < this.steps.length) {
      let element = document.querySelector<HTMLElement>(
        `[data-guide=${this.currentStep.elementAttribute}]`
      );
      // if (this.currentIdx >= this.steps.length - 1) {
      //   this.btnForward.style.visibility = 'hidden';
      // } else this.btnForward.style.visibility = 'visible';
      if (element) {
        // setTimeout(() => {
        //   this.moveModal(element);
        // }, 300);
        this.moveModal(element);
      }
    } else {
      this.btnForward.style.visibility = 'hidden';
      this.deleteTransparentBox();
    }
  }
  backStep() {
    if (this.currentIdx == 0) {
      let prevElement = document.querySelector<HTMLElement>(
        `[data-guide=${this.currentStep.elementAttribute}]`
      );
      if (prevElement) prevElement.style.zIndex = '0';
    }
    this.currentIdx -= 1;
    console.log(this.currentIdx);

    this.currentStep = this.steps[this.currentIdx];
    // if (this.currentIdx == 1) {
    //   this.btnBack.style.visibility = 'hidden';
    // }
    // if (this.currentIdx >= this.steps.length - 1) {
    //   this.btnForward.style.visibility = 'hidden';
    // } else this.btnForward.style.visibility = 'visible';
    if (this.currentStep.elementAttribute !== '') {
      let element = document.querySelector<HTMLElement>(
        `[data-guide=${this.currentStep.elementAttribute}]`
      );

      console.log(element.offsetTop);

      if (element) {
        // setTimeout(() => {
        //   this.moveModal(element);
        // }, 300);
        this.moveModal(element);
      }
    } else {
      this.createModal(this.currentStep);
      this.introduceModal();
      this.deleteTransparentBox();
    }
  }
  createModal(currentStep: any) {
    let modalTitle = document.querySelector<HTMLElement>('.modal-title');
    if (modalTitle) {
      modalTitle.innerHTML = `
        ${currentStep.title}`;
    }
    let modalContent = document.querySelector<HTMLElement>('.modal-body');
    if (modalContent) {
      modalContent.innerHTML = `<p>${currentStep.content}</p>`;
    }
  }
  moveModal(element) {
    if (!this.isInViewport(element)) {
      element.scrollIntoView();
    }
    this.createModal(this.currentStep);
    let position = element.getBoundingClientRect();
    console.log(position);

    this.modal.style.transform = 'none';
    //top-left
    if (position.left < screen.width / 3 && position.top < screen.height / 3) {
      console.log('top-left');
      this.modal.style.top = `${position.top - 2.5}px`;
      this.modal.style.left = `${position.width + 20}px`;
    }
    //center-left
    if (
      position.left < screen.width / 3 &&
      position.top > screen.height / 3 &&
      position.top < screen.height * (2 / 3)
    ) {
      console.log('center-left');
      this.modal.style.top = `${position.top - 2.5}px`;
      this.modal.style.left = `${position.width + 20}px`;
    }
    //bottom-left
    if (position.left < screen.width / 3 && position.top > screen.height * (2 / 3)) {
      console.log('bottom-left');
      this.modal.style.top = `${position.top + position.height - this.modal.offsetHeight + 2.5}px`;
      this.modal.style.left = `${position.width + 20}px`;
    }
    //top-center
    if (
      position.left > screen.width / 3 &&
      position.left < screen.width * (2 / 3) &&
      position.top < screen.height / 3
    ) {
      console.log('top-center');
      this.modal.style.top = `${position.top + position.height + 20}px`;
      this.modal.style.left = `${position.width + 20}px`;
    }
    //center
    if (
      position.left > screen.width / 3 &&
      position.left < screen.width * (2 / 3) &&
      position.top > screen.height / 3 &&
      position.top < screen.height * (2 / 3)
    ) {
      console.log('center');
      this.modal.style.top = `${position.top + position.height + 20}px`;
      this.modal.style.left = `${
        position.left + position.width / 2 - this.modal.offsetWidth / 2
      }px`;
    }
    //bottom-center
    if (
      position.left > screen.width / 3 &&
      position.left < screen.width * (2 / 3) &&
      position.top > screen.height * (2 / 3)
    ) {
      console.log('bottom-center');
      this.modal.style.top = `${position.top - this.modal.offsetHeight - 20}px`;
      this.modal.style.left = `${
        position.left + position.width / 2 - this.modal.offsetWidth / 2
      }px`;
    }
    //top-right
    if (position.left > screen.width * (2 / 3) && position.top < screen.height / 3) {
      console.log('top-right');
      this.modal.style.top = `${position.top - 2.5}px`;
      this.modal.style.left = `${position.left - this.modal.offsetWidth - 20}px`;
    }
    //center-right
    if (
      position.left > screen.width * (2 / 3) &&
      position.top > screen.height / 3 &&
      position.top < screen.height * (2 / 3)
    ) {
      console.log('center-right');
      this.modal.style.top = `${position.top - 2.5}px`;
      this.modal.style.left = `${position.left - this.modal.offsetWidth - 20}px`;
    }
    //bottom-right
    if (position.left > screen.width * (2 / 3) && position.top > screen.height * (2 / 3)) {
      console.log('bottom-right');
      this.modal.style.top = `${position.top + position.height - this.modal.offsetHeight + 2.5}px`;
      this.modal.style.left = `${position.left - this.modal.offsetWidth - 20}px`;
    }
    this.createTransparentBox(position);
  }
  //center modal
  introduceModal() {
    this.modal.style.top = '50%';
    this.modal.style.left = '50%';
    this.modal.style.transform = 'translate(-50%, -50%)';
  }
  createTransparentBox(position: any) {
    this.box.setAttribute('y', `${position.top - 2.5 / 2}`);
    this.box.setAttribute('x', `${position.left - 5}`);
    this.box.setAttribute('width', `${position.width + 10}`);
    this.box.setAttribute('height', `${position.height + 2.5}`);
  }
  deleteTransparentBox() {
    this.box.setAttribute('y', '0');
    this.box.setAttribute('x', '0');
    this.box.setAttribute('width', '0');
    this.box.setAttribute('height', '0');
  }
  isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }
}
