<div class="card shadow-none">
    <div class="card-body position-relative">
        <div class="row">
            <div class="col-md-auto align-self-end">
                <label class="text-lg">Chọn gói cước</label>
            </div>
        </div>
        <hr class="my-3">
        
        <div class=''>
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th></th>
                        <th *ngFor="let item of listHeader" class="align-top text-center">
                            <h4><label>{{ item.name || '' }}</label></h4>
                            <p>{{ item.price || 0 }} VNĐ / tháng</p>
                            <button class="btn btn-outline-primary" (click)="purchasePackage(item.packInfoId)">Purchase</button>
                            <!-- <p class="mt-1"><span>or <a class="text-primary">try for free</a></span></p> -->
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td colspan="4"><h5><label>eContract</label></h5></td>
                    </tr>
                    <tr *ngFor="let item of listBody">
                        <td><h5> {{ item.name || '' }}</h5></td>
                        <td *ngFor="let active of item.activeRole"><span *ngIf="active" class="ct-icon-font icon-circle-checked text-primary"></span></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- <app-mini-spinner *ngIf="loadingService.isVisible() | async"></app-mini-spinner> -->
    </div>
</div>