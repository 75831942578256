
<app-spinner></app-spinner>
<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-auto">
                <h1 class="m-0 text-dark"><label>Thông tin chi tiết</label></h1>
            </div>
            <!-- /.col -->
            <div class="col-md-auto ml-auto">
            </div>
            <!-- <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>

<section class="content">
  <div class="container-fluid">
    <div class="card shadow-none mb-4">
      <div class="card-body">
        <div class="row mb-3">
          <div class="col-lg-3 col-md-5">
            <form   class="input-group border rounded mb-3">
            <div class="input-group-prepend border-0">
                <button class="btn d-inline-flex pr-2 pl-2" type="submit"><span
                        class="material-icons">search</span></button>
            </div>
            <input type="text" class="form-control border-0"
            placeholder="Nhập tên khách hàng hoặc tên gói dịch vụ..."   [formControl]="searchFields"
                aria-describedby="basic-addon1" #searchFieldLicense>
          </form>
            </div>
          <div class="ml-auto ">
            <button class="btn btn-outline-primary  mr-3" (click)="cleanFilter()">
              Đặt lại
            </button>
            <button class="btn btn-primary  " (click)="searchWithFilter()">
              Áp dụng
            </button>
          </div>
        </div>
        <hr>
        <div class="form-group d-flex">
          <div class="col col-md-3">
            <label for="statusGCS">
              Loại khách hàng
            </label>
            <select #customerTypeCopyright name="customerTypeCopyright" id="customerTypeCopyright"
              (change)="changeCustomerType($event)" class="form-control">
              <option value="" [selected]="customerType === ''">Tất cả</option>
              <option value="CONSUMER" [selected]="customerType === 'CONSUMER'">Cá nhân</option>
              <option value="ENTERPRISE" [selected]="customerType === 'ENTERPRISE'">Tổ chức</option>
              <option value="BUSINESS" [selected]="customerType === 'BUSINESS'">Tổ chức SME</option>
            </select>
          </div>
          <div class="col col-md-3">
            <label for="statusGCS">
              Trạng thái
            </label>
            <select #customerStatusPicker name="customerStatusPicker" id="customerStatusPicker"
              (change)="changeStatusCustomer($event)" class="form-control">
              <option value="" [selected]="customerStatus === ''">Tất cả</option>
              <option value="ENABLED" [selected]="customerStatus === 'ENABLED'">Đang hoạt động</option>
              <option value="DISABLED" [selected]="customerStatus === 'DISABLED'">Ngưng hoạt động</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="card shadow-none mb-4">
      <div class="table-responsive table table-scroll">
        <table class="table main-table border">
          <thead>
            <tr>
              <th>STT</th>
              <th>Tên khách hàng</th>
              <th>Loại khách hàng</th>
              <th>Tên gói dịch vụ</th>
              <th>Ngày triển khai</th>
              <th>Trạng thái</th>
              <th>Thao tác</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of listCopyright; let i = index">
              <td>{{ (page - 1)*10 + i + 1 }}</td>
              <td>
                <a class="nav-link" (click)="goToDetail(item.licenseId, item.packInfoId, item.partyId)" routerLinkActive="router-link-active"
                 ><u>{{ item.tenKhachHang }}</u></a>
              </td>
              <td>{{ partyType[item.partyType]}}</td>
              <td>{{ item.packageName }}</td>
              <td>{{ item.issueDate }}</td>
              <td><span class="badge {{ status[item.status].style }} badge-pd">{{ status[item.status].label }}</span>
              </td>
              <td>
                <div class="dropdown">
                  <a class="btn btn-dropdown dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <em class="material-icons">more_vert</em>
                  </a>

                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                    <!-- [routerLink]="['/console/licenses-manager/detail/', item.licenseId]" -->
                    <a class="dropdown-item nav-material" style="cursor: pointer;" (click)="goToDetail(item.licenseId, item.packInfoId, item.partyId)"
                      routerLinkActive="router-link-active">
                      <em class="material-icons">info</em>
                      Thông tin chi tiết</a>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="d-flex">
      <span *ngIf="totalElement == 0" class="flex-grow-1 ml-1">Không tìm thấy kết quả</span>
      <span *ngIf="totalElement > 0" class="flex-grow-1 ml-1">Hiển thị 1 đến {{listCopyright.length}} trong tổng số
        {{totalElement}} kết quả</span>
      <div *ngIf="totalElement > maxSize" class="pagination justify-content-end pages">
        <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="10" [rotate]="true" [ellipses]="false"
          [pageSize]="maxSize" (pageChange)="loadPage($event)" [boundaryLinks]="true" aria-label="Default pagination">
          <ng-template ngbPaginationFirst>Đầu</ng-template>
          <ng-template ngbPaginationLast>Cuối</ng-template>
          <ng-template ngbPaginationPrevious>&laquo;</ng-template>
          <ng-template ngbPaginationNext>&raquo;</ng-template>
          <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
      </div>
    </div>

  </div>
</section>