export class EnterpriseManagerItem {
  tenToChuc: string = "";
  maSoThue: string = "";
  partyId: string = "";
  sdt: string = "";
  ten: string = "";
  email: string = "";
  created: string = "";
  verificationStatus: string = "";
  identityLevel: number = 0;

  tenRutGon:string="";
  giayPhepKinhDoanh: string = "";
  hoTenDaiDien: string="";
  ngaySinhDaiDien: string="";
  gioiTinhDaiDien: string="";
  diachiDaiDien: string="";
  ngayDangKy: string="";
  approveDate: string ="";
  approverId: string="";
  rejectReason: string ="";
  gpkdFilename: string = '';
  username: string = "";

  packageId: string = "";
  packageName: string = "";
  packageTempId: string = "";

  userInformations: any = {};
  
  soNha: string = "";
  duong: string = "";
  xa: string = "";
  huyen: string = "";
  tinh: string = "";

  constructor(obj = null) {
    obj = obj || {};
    this.created = obj.created || "";
    this.tenToChuc = obj.tenToChuc || "";
    this.maSoThue = obj.maSoThue || "";
    this.partyId = obj.partyId || "";
    this.sdt = obj.sdt || "";
    this.ten = obj.ten || "";
    this.email = obj.email || "";
    this.created = obj.created || "";
    this.verificationStatus = obj.verificationStatus || "";
    this.identityLevel = obj.identityLevel || 0;
    this.gpkdFilename = obj.gpkdFilename || '';
    this.username = obj.username || "";
    this.packageId = obj.packageId || "";
    this.packageName = obj.packageName || "";
    this.packageTempId = obj.packageTempId || "";

    this.userInformations = obj.userInformations || {};

    this.hoTenDaiDien = obj.userInformations?.ten;
    this.email = obj.userInformations?.email;
    this.sdt = obj.userInformations?.sdt;
    this.ngaySinhDaiDien = obj.userInformations?.ngaySinh || "";
    this.gioiTinhDaiDien = obj.userInformations?.gioiTinh;
    this.diachiDaiDien =
    (obj.userInformations?.soNha ? obj.userInformations?.soNha + ", " : "") +
    (obj.userInformations?.duong ? obj.userInformations?.duong + ", " : "") +
    (obj.userInformations?.xa ? obj.userInformations?.xa + ", " : "" ) +
    (obj.userInformations?.huyen ? obj.userInformations?.huyen + ", ": "") +
    (obj.userInformations?.tinh || "");
    this.username = obj.userInformations?.username || "";

    this.soNha = obj.userInformations?.soNha || "";
    this.duong = obj.userInformations?.duong || "";
    this.xa = obj.userInformations?.xa || "";
    this.huyen = obj.userInformations?.huyen || "";
    this.tinh = obj.userInformations?.tinh || "";
  }
}
