import { SignBox } from "../../signbox/sign-box";

export class ContractSignMultipleItem {
    contractId: string = "";
    created: string = "";
    currentStage: string = "";
    discuss: any = false;
    modified: string = "";
    ownerPartyId: string = "";
    partnerTotal: any = 0;
    signForm: string[] = [];
    signerEmail: string = "";
    signerId: string = "";
    signerSignForm: string[] = [];
    signerSignFrame: SignBox[] = [];
    signerUserId: string = "";
    title: string = "";
    selected = false;
    fileKey: string = "";
    partyTotal = 0;
    sizeFile = 0;

    constructor(d = null) {
        d = d || {};
        this.contractId = d.contractId || "";
        this.created = d.created || "";
        this.currentStage = d.currentStage || "";
        this.discuss = d.discuss;
        this.modified = d.modified || "";
        this.ownerPartyId = d.ownerPartyId || "";
        this.partnerTotal = d.partnerTotal;
        this.signForm = d.signForm || [];
        this.signerEmail = d.signerEmail || "";
        this.signerSignForm = d.signerSignForm || [];
        this.signerSignFrame = [];
        if (d.signerSignFrame && d.signerSignFrame instanceof Array) {
            d.signerSignFrame.forEach((item) => {
                this.signerSignFrame.push(new SignBox(item));
            })
        }
        this.signerUserId = d.signerUserId || "";
        this.title = d.title || "";
        this.fileKey = d.fileKey || "";
        this.partyTotal = d.partyTotal;
        this.sizeFile = d.sizeFile || 0;
    }

    get listPageHaveSignBox() {
        let listPage = [];
        let res = "";

        this.signerSignFrame.forEach((item: any) => {
            if (!listPage.includes(item.page)) {
                listPage.push(item.page);
            }
        })

        listPage.forEach((item: any, index) => {
            res = res + ` ${item} ${ index == (listPage.length - 1) ? '' : ','}`;
        })

        return res;
    }
}