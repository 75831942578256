import { MainResponse } from '../../main-response.model';
import { SignUpBusinessObject } from './signup-business.object';

export class SignUpBusinessResponse extends MainResponse{
    object: SignUpBusinessObject;
    constructor(obj = null) {
        super();
        obj = obj || {};
        this.init(obj);
        this.object = new SignUpBusinessObject(obj.object);
    }
}