import { SignBox } from "../../signbox/sign-box";

export class ContractInSignJobItem {
    contractId: string = "";
    title: string = "";
    status: string = "";
    inprogress = false;
    error: string = "";
    signFlag = false;
    multiSign = [];

    constructor(d = null) {
        d = d || {};
        this.contractId = d.contractId || "";
        this.title = d.title || "";
        this.status = d.status || "";
        this.inprogress = d.inprogress;
        this.error = d.error || "";
        this.signFlag = d.signFlag;
        this.multiSign = d.multiSign || [];
    }

    get listPageHaveSignBox() {
        let listPage = [];
        let res = "";

        this.multiSign.forEach((item: any) => {
            if (!listPage.includes(item.pageSign)) {
                listPage.push(item.pageSign);
            }
        })

        listPage.forEach((item: any, index) => {
            res = res + ` ${item} ${ index == (listPage.length - 1) ? '' : ','}`;
        })

        return res;
    }
}