import { UsbTokenCertItem } from './usb-token-cert-item.model';
import { MainResponse } from '../../main-response.model';

export class UsbTokenCertResponse extends MainResponse {

    object: UsbTokenCertItem;
    
    constructor(obj = null) {
        super();
        obj = obj || {};
        this.init(obj);
        this.object = new UsbTokenCertItem(obj);
    }
}