import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { DateUtils } from 'src/app/utils/common/DateUtils';
import { REGEXP_DATE_INPUT, REGEXP_EMAIL, REGEXP_PHONE_NUMBER, REGEXP_USERNAME } from 'src/app/utils/common/regexp';
import { ValidatorsExtension } from 'src/app/utils/common/validators-extension';
import { localize } from 'src/app/utils/localize/localize';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { CatalogService } from 'src/app/utils/services/aas-network/catalog.service';
import { PartnerService } from 'src/app/utils/services/aas-network/partner.service';
import { TemplateService } from 'src/app/utils/services/aas-network/template.service';

@Component({
  selector: 'app-update-business-trusted-partner',
  templateUrl: './update-business-trusted-partner.component.html',
  styleUrls: ['./update-business-trusted-partner.component.scss']
})
export class UpdateBusinessTrustedPartnerComponent implements OnInit {

  id = this.activatedRoute.snapshot.paramMap.get('id') ?? null;

  constructor(
    private catalogService: CatalogService,
    private fb: FormBuilder,
    private alert: AlertControl,
    private templateApi: TemplateService,
    private partnerService: PartnerService,
    private router: Router,
    private translate: TranslateService,
    private activatedRoute: ActivatedRoute,
    private aasAuth: AasAuthService,
    private modalService: NgbModal,

  ) { }

  // Error message for validation
  validateMsg = {
    username: {
      required: "RegisterBusiness_err_16",
      pattern: 'EmployeeEdit_ts_2',
      unique: 'ECT-00001202'
    },
    orgName: {
      required: "RegisterBusiness_err_1",
      pattern: "AddBusinessTrustedPartner_ts_1"
    },
    taxNumber: {
      required: "RegisterBusiness_err_5",
      pattern: "ProfileConsumer_msg_7"
    },
    shortName: {
      required: "RegisterBusiness_err_3",
      pattern: "AddBusinessTrustedPartner_ts_2"
    },
    email: {
      required: "RegisterBusiness_err_9",
      pattern: "AddBusinessTrustedPartner_ts_3"
    },
    provinceId: {
      required: "AddBusinessTrustedPartner_ts_4"
    },
    districtId: {
      required: "AddBusinessTrustedPartner_ts_5"
    },
    wardId: {
      required: "AddBusinessTrustedPartner_ts_6"
    },
    streetName: {
      required: "AddBusinessTrustedPartner_ts_7"
    },
    apartmentNumber: {
      required: "AddBusinessTrustedPartner_ts_8"
    },
    file: {
      required: "AddBusinessTrustedPartner_ts_9",
      type: "ServiceContractListV2_validMsgUpdateContractFile_2"
    },
    fullName: {
      required: "AddBusinessTrustedPartner_ts_10",
      pattern: "AddBusinessTrustedPartner_ts_11"
    },
    phoneNumber: {
      required: "EmployeeAdd_ts_6",
      pattern: "AddBusinessTrustedPartner_ts_12"
    },
    birthday: {
      required: "AddBusinessTrustedPartner_ts_13",
      pattern: "AddBusinessTrustedPartner_ts_14"
    },
    sexId: {
      required: "AddBusinessTrustedPartner_ts_15"
    }
  };

  // Check submitted or not
  isSubmit: boolean = false;

  // List of Types are accepted
  fileType = [
    'image/jpeg',
    'image/png',
    'image/jpg',
    'application/pdf',
    // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ];
  inputFileLabel: string = "ContractTemplateAdd_ph_attachedFile";
  pdfFile = null;

  sexOptions: Array<any> = [
    { id: 1, label: "ProfileBusiness_agent_female" },
    { id: 2, label: "ProfileBusiness_agent_male" }
  ];

  optionsCleave = {
    date: true,
    delimiter: '/',
    datePattern: ['d', 'm', 'Y']
  };

  partnerForm: FormGroup = null;

  currentDate = new Date();

  detailInformation: any = {};

  originTax: string = "";
  originPhone: string = "";
  modalUpdatePartner: any = null;


  ngOnInit(): void {
    this.initPartnerForm();
    this.getListOfProvinces();
    this.initTaxNumberPipeLine();
  }

  initPartnerForm() {
    this.partnerForm = this.fb.group({
      username: ["", [Validators.required, Validators.pattern(REGEXP_USERNAME)]],
      orgName: ["", [Validators.required, Validators.pattern(/^[%&,().\-+.\\/;"\p{L}\s\d]*$/u)]],
      taxNumber: ["", [Validators.required, Validators.pattern(/^\d{10}-\d{3}$|^\d{10}$/i)]],
      shortName: ["", [Validators.required, Validators.pattern(/^[ \w\%\&\,\(\)\.\-\+\:\\\/\;\"]*$/)]],
      email: ["", [Validators.required, Validators.pattern(REGEXP_EMAIL)]],
      provinceId: [null, [Validators.required]],
      districtId: [null, [Validators.required]],
      wardId: [null, [Validators.required]],
      streetName: ["", [Validators.required]],
      apartmentNumber: ["", [Validators.required]],
      file: [null, []],
      fullName: ["", [Validators.required, ValidatorsExtension.validateFullName]],
      phoneNumber: ["", [Validators.required, Validators.pattern(REGEXP_PHONE_NUMBER)]],
      birthday: ["", [Validators.required, Validators.pattern(REGEXP_DATE_INPUT)]],
      sexId: [null, [Validators.required]]
    });

    this.getDetails();
  }

  getDetails() {
    if (this.id != null) {
      this.partnerService.getDetailsOfBussinessTrustedPartner(this.id).subscribe((res: any) => {
        this.detailInformation = res?.object ?? {};
        this.assemblePartnerForm();
        this.downloadGPKD();
      }, err => {
        this.detailInformation = {};
      });
    }
  }

  assemblePartnerForm() {
    this.partnerForm.controls.username.setValue(this.detailInformation.username ?? "");
    this.partnerForm.controls.orgName.setValue(this.detailInformation.tenToChuc ?? "");
    this.partnerForm.controls.taxNumber.setValue(this.detailInformation.maSoThue ?? "");
    this.originTax = this.detailInformation.maSoThue ?? "";
    this.partnerForm.controls.shortName.setValue(this.detailInformation.tenRutGon ?? "");
    this.partnerForm.controls.email.setValue(this.detailInformation.email ?? "");
    this.partnerForm.controls.provinceId.setValue(this.detailInformation.tinhId ?? null);
    this.getListOfDistricts('');
    this.partnerForm.controls.districtId.setValue(this.detailInformation.huyenId ?? null);
    this.getListOfWards('');
    this.partnerForm.controls.wardId.setValue(this.detailInformation.xaId ?? null);
    this.partnerForm.controls.streetName.setValue(this.detailInformation.duong ?? "");
    this.partnerForm.controls.apartmentNumber.setValue(this.detailInformation.soNha ?? "");
    this.partnerForm.controls.file.setValue(this.detailInformation.gpkdFilename ?? "");
    this.inputFileLabel = this.detailInformation.gpkdFilename ?? 'ContractTemplateAdd_ph_attachedFile';
    this.partnerForm.controls.fullName.setValue(this.detailInformation.ten ?? "");
    this.partnerForm.controls.phoneNumber.setValue(this.detailInformation.sdt ?? "");
    this.originPhone = this.detailInformation.sdt ?? "";
    this.partnerForm.controls.birthday.setValue(DateUtils.convert(this.detailInformation.ngaySinh ?? "", DateUtils.format.INPUT));
    this.partnerForm.controls.sexId.setValue(this.detailInformation.gioiTinhId ?? null);
  }

  /**
  * Tai file GPKD
  * @param partyId 
  */
  fileGPKD: Blob = null;
  downloadGPKD() {
    if (!this.detailInformation.partyId || this.detailInformation.partyId == null || this.detailInformation.partyId == undefined) return;
    this.aasAuth.downloadGPKD(this.detailInformation.partyId).subscribe((res: any) => {
      this.fileGPKD = new Blob([res], { type: 'application/pdf' });
      this.partnerForm.controls.file.setValue(this.fileGPKD);
    }, err => {

    })
  }

  /**
   * Get list of provinces
   */
  listOfProvinces: Array<any> = [];
  getListOfProvinces() {
    this.catalogService.getListTinh().subscribe((res: any) => {
      this.listOfProvinces = res?.object ?? [];
    }, err => {
      this.listOfProvinces = [];
    });
  }

  /**
   * Get list of districts
   */
  listOfDistricts: Array<any> = [];
  getListOfDistricts(event) {
    this.catalogService.getListHuyen(this.partnerForm.controls.provinceId.value).subscribe((res: any) => {
      this.listOfDistricts = res?.object ?? [];
    }, err => {
      this.listOfDistricts = [];
    })
    if (event && event !== '') {
      this.listOfDistricts = []
      this.partnerForm.controls.districtId.setValue(null);
      this.listOfWards = [];
      this.partnerForm.controls.wardId.setValue(null);
    }
  }

  /**
   * Get list of wards
   */
  listOfWards: Array<any> = [];
  getListOfWards(event) {
    this.catalogService.getListXa(this.partnerForm.controls.districtId.value).subscribe((res: any) => {
      this.listOfWards = res?.object ?? [];
    }, err => {
      this.listOfWards = []
    });
    if (event && event !== '') {
      this.listOfWards = [];
      this.partnerForm.controls.wardId.setValue(null);
    }
  }

  /**
   * Action when attach file
   * @param event 
   */
  onAttachFileChanged(event) {
    let file = event.target.files && event.target.files.item(0);
    if (file && file !== null && file !== undefined) {
      if (file.size > 5000000) {
        this.alert.showAlertOnlyNoti(this.translate.instant('ServiceContract_FILE_SIZE_TOO_LARGE'), "");
      }
      else if (!this.fileType.includes(file.type)) {
        this.partnerForm.controls.file.setErrors({ type: true });
        this.inputFileLabel = file.name
      }
      else {
        this.partnerForm.controls.file.setValue(file);
        this.inputFileLabel = file.name;

        if (file.type === 'application/pdf') {
          this.pdfFile = file;
        } else {
          let data = new FormData();
          data.append('attachFile', file);
          this.postConvertToPDF(data);
        }
      }
    } else {
      this.pdfFile = null;
      this.partnerForm.controls.file.setValue(null);
      this.partnerForm.controls.file.setErrors(null);
      this.inputFileLabel = "ContractTemplateAdd_ph_attachedFile";
    }
  }

  postConvertToPDF(data: FormData) {
    this.templateApi.postConvertToPdf(data).subscribe(fileData => {
      const blob: any = new Blob([fileData], { type: 'application/pdf' });
      this.pdfFile = blob;
    }, err => {
      this.alert.showErrorHandled(err);
    });
  }

  /**
   * Action when change date
   * @param event 
   */
  changeDateFilter(event) {
    this.partnerForm.controls.birthday.setValue(DateUtils.convertObjtoDate(event));
  }

  /**
   * Action save button
   */
  save() {
    this.isSubmit = true;
    if (this.partnerForm.valid) {
      let msg = this.translate.instant('AddBusinessTrustedPartner_ts_16');
      this.alert.showAlert(msg, "", true, "", this.translate.instant('AddBusinessTrustedPartner_ts_17'), () => {
        this.initPartner();
      }, "", this.translate.instant('btn_close'), null, this.translate.instant('btn_confirm'));
    }
  }

  initPartner() {
    let data = new FormData();

    // if (this.partnerForm.value.file) {
    //   data.append("GPKD", this.partnerForm.value.file, this.partnerForm.value.file.name || this.inputFileLabel);
    // }
    if (this.pdfFile && this.partnerForm.value.file) {
      data.append("GPKD", this.pdfFile, this.partnerForm.value.file.name || this.inputFileLabel);
    }

    let daiDien = {
      "username": this.partnerForm.value.username ?? "",
      "ten": this.partnerForm.value.fullName ?? "",
      "sdt": this.partnerForm.value.phoneNumber ?? "",
      "ngaySinh": this.partnerForm.value.birthday ?? "",
      "gioiTinhId": this.partnerForm.value.sexId ?? ""
    }
    let toChuc = {
      "maSoThue": this.partnerForm.value.taxNumber ?? "",
      "tenToChuc": this.partnerForm.value.orgName ?? "",
      "tenRutGon": this.partnerForm.value.shortName ?? "",
      "email": this.partnerForm.value.email ?? "",
      "tinhId": this.partnerForm.value.provinceId ?? null,
      "huyenId": this.partnerForm.value.districtId ?? null,
      "xaId": this.partnerForm.value.wardId ?? null,
      "duong": this.partnerForm.value.streetName ?? "",
      "soNha": this.partnerForm.value.apartmentNumber ?? ""
    }
    data.append("daiDien", JSON.stringify(daiDien));
    data.append("toChuc", JSON.stringify(toChuc));

    this.partnerService.updateBusinessTrustedPartner(this.id, data).subscribe((res: any) => {
      if (this.resendPartnerInforChecked) {
        this.partnerService.resendPartnerInfor(this.id).subscribe((res2: any) => {

        }, err => {
          
        });
      }

      this.alert.showAlert(this.translate.instant('UpdateBusinessTrustedPartner_modal_3'), "", false, "", this.translate.instant('btn_close'), () => {
        
        this.modalUpdatePartner.close();
        this.router.navigate([`app/console/partner/trusted`]);
      });

    }, err => {
      this.alert.showErrorHandled(err);
    });
  }

  cancel() {
    this.isSubmit = false;
    this.partnerForm.controls.file.setErrors(null);
    this.inputFileLabel = "ContractTemplateAdd_ph_attachedFile";

    this.partnerForm.controls.username.setValue("");
    this.partnerForm.controls.orgName.setValue("");
    this.partnerForm.controls.taxNumber.setValue("");
    this.partnerForm.controls.shortName.setValue("");
    this.partnerForm.controls.email.setValue("");
    this.partnerForm.controls.provinceId.setValue(null);
    this.partnerForm.controls.districtId.setValue(null);
    this.partnerForm.controls.wardId.setValue(null);
    this.partnerForm.controls.streetName.setValue("");
    this.partnerForm.controls.apartmentNumber.setValue("");
    this.partnerForm.controls.file.setValue(null);
    this.partnerForm.controls.fullName.setValue("");
    this.partnerForm.controls.phoneNumber.setValue("");
    this.partnerForm.controls.birthday.setValue("");
    this.partnerForm.controls.sexId.setValue(null);
    this.partnerForm.controls.username.setValue("");
    this.partnerForm.controls.username.setValue("");
    this.router.navigate(['/app/console/partner/business-trusted-partner-detail/', this.id])
  }

  /**
   * Check if tax number already exists when valid.
   */
  taxNumberPipeLine = new Subject<string>();
  initTaxNumberPipeLine() {
    this.taxNumberPipeLine.pipe(
      debounceTime(400),
      distinctUntilChanged()
    ).subscribe(value => {
      if (this.partnerForm.controls.taxNumber.valid && this.partnerForm.controls.taxNumber.value != this.originTax) {
        this.partnerService.checkTaxNumber(this.partnerForm.controls.taxNumber.value).subscribe((res: any) => {
          if (res.object.tenToChuc) {
            this.alert.showAlert(this.translate.instant('AddBusinessTrustedPartner_ts_19', { name: res.object.tenToChuc }), ConstantAlertType.BLANK, true, "",
              this.translate.instant('ServiceContract_title2'), () => {
                window.open('/app/console/contract/create', "_blank");
              }, "", this.translate.instant('btn_close'), null, this.translate.instant('btn_confirm'));
          }
        }, err => {
          this.alert.showErrorHandled(err);
        });
      }
    });
  }

  checkPhoneNumber() {
    if (this.partnerForm.controls.phoneNumber.valid && this.partnerForm.controls.phoneNumber.value != this.originPhone) {
      this.partnerService.checkPhoneNumber(this.partnerForm.controls.phoneNumber.value).subscribe((res: any) => {
        if (res.object.ten) {
          this.alert.showAlert(this.translate.instant('AddBusinessTrustedPartner_ts_20', { name: res.object.ten }), ConstantAlertType.BLANK, true, "",
            this.translate.instant('ServiceContract_title2'), () => {
              window.open('/app/console/contract/create', "_blank");
            }, "", this.translate.instant('btn_close'), null, this.translate.instant('btn_confirm'));
        }
      }, err => {
        this.alert.showErrorHandled(err);
      });
    }
  }


  /**
  * Mo popup update doi tac
  * @param content 
  */
  openUpdatePopup(content) {
    this.isSubmit = true;
    if (this.partnerForm.valid) {
      this.modalUpdatePartner = this.modalService
      .open(content, { backdrop: "static" });
    }
    
  }

  checkResendPartnerInfor() {
    this.resendPartnerInforChecked = !this.resendPartnerInforChecked;
  }
  resendPartnerInforChecked: boolean = false;
}
