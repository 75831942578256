
<app-spinner></app-spinner>
<input #output id="output" class="" value="" type="hidden">
<input #filebase id="fileBase" class="" type="hidden">
<input #filename id="fileName" class="" value="{{ fileName }}">
<div class="wrapper">
    <div class="preview-overlay">
        <div class="preview-container">
            <div class="preview-header d-flex">
                <div (click)="goToBack()"
                    class="ct-header-btn-back d-flex justify-content-center align-items-center cursor-pointer">
                    <span class="material-icons">clear</span>
                </div>
                <div class="ct-title flex-grow-1 d-flex align-items-center px-3">
                    <h4 class="mb-0">Hợp đồng điện tử</h4>
                </div>
                <div class="ct-toolbar-btn d-flex align-items-center">
                    <button (click)="saveFileContract()"
                        class="btn btn-outline-primary d-flex align-items-center mr-3"><span
                            class="material-icons mr-2">get_app</span> Tải xuống</button>
                </div>
            </div>
            <div class="preview-body">
                <div class="pv ct">
                    <div class="pv-body">
                        <div class="ctpv-container">
                            <div class="ctpv-content">
                                <div class="ct-container">
                                    <div class="ct-thumb-page">
                                        <div class="ct-vs ct-enable-scroll">
                                            <ol id="thumbnail-page" class="ct-vs-list mt-3">
                                            </ol>
                                        </div>
                                    </div>
                                    <div class="ct-content">
                                        <div id="parentView" class="ct-doc-loaded ct-enable-scroll">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="ct ctpv-tool-bar">
                            <div class="ctpv-tool-sidebar">
                                <div class="ctpv-tabbar">
                                    <div class="ctpv-main">
                                        <button (click)="selectTab('INFO')" class="ctpv-btn"
                                            [class.active]="activeTab == 'INFO'"> <span
                                                class="material-icons-outlined">info</span></button>
                                        <!-- <button (click)="selectTab('SIGN')" class="ctpv-btn"
                                            [class.active]="activeTab == 'SIGN'"> <span
                                                class="material-icons">gesture</span></button> -->
                                    </div>
                                </div>
                                <div class="ctpv-footer">
                                </div>
                            </div>
                            <div class="ctpv-tool-content">
                                <div class="ctpv-content-wrapper px-3">
                                    <div id="tab-info" class="ctpv-scroll-content"
                                        [class.d-none]="!(activeTab === 'INFO')">
                                        <h5 class="mt-3">Thông tin chi tiết hợp đồng</h5>
                                        <div class="row mt-3">
                                            <div class="col-lg-6">
                                                <p><label>Tên hợp đồng</label></p>
                                                <p>{{ this.templateName }}</p>
                                            </div>
                                            <div class="col-lg-6">
                                                <p><label>Ghi chú</label></p>
                                                <p></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

