<div class="tab-pane fade show active " id="waiting" role="tabpanel" aria-labelledby="waiting">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-4">
                <div class="card shadow-none">
                    <div class="card-body pb-0">
                        <div class="row pt-2 pb-2">
                            <div class="col-md-auto ">
                                <label class="text-lg">Danh sách từ chối phê duyệt</label>
                            </div>

                            <div class="input-group mt-3">
                                <!-- <form class="input-group"> -->
                                <div class="input-group-prepend">
                                    <button class="btn btn-primary d-inline-flex pr-2 pl-2"
                                        type="submit" (click)="clickSearchBtn()">
                                        <span class="material-icons">search</span>
                                    </button>
                                </div>
                                <input type="text" class="form-control" placeholder="Nhập tên đơn vị..."
                                    value="{{ keySearch }}" (input)="inputKeySearch($event)"
                                    #searchField aria-label="" aria-describedby="basic-addon1" (keyup.enter)="clickSearchBtn()">
                                <!-- </form> -->
                            </div>
                        </div>
                    </div>

                    <div class="position-relative">
                        <ng-template #noData>
                            <div class="no-content-list">
                                <img
                                    class="mb-2"
                                    src="assets/img/icon/icon-no-user-group.png"
                                    width="100"
                                />
                                <p class="mb-1">
                                    Không có dữ liệu.
                                </p>
                            </div>
                        </ng-template>

                        <div>
                            <div *ngIf="!!enterprises && enterprises.length; else noData" class="content-list fade show">
                                <div *ngFor="let item of enterprises; let i = index"
                                    (click)="detailEnterprise(item)"
                                    class="user-group-item content-list-item px-4 pt-2"
                                    [class.active]="idRowActive === item.partyId">
                                    <div class="border-bottom d-flex">
                                        <span class="material-icons icon-item pr-3">business</span>
                                        <div class="flex-grow-1 mb-2">
                                            <label
                                                class="title-item">{{ item.tenToChuc || 'NULL' }}</label>
                                            <div class="row-sub hide-text">
                                                <span class="badge badge-light-new">{{item.maSoThue}} </span>
                                                <span class="badge badge-light-new">{{item.email}} </span>
                                            </div>
                                        </div>
                                        <!-- <a *ngIf="!item.isUsed"
                                            class="text-decoration-underline text-primary pl-4"
                                            (click)="deleteDocument($event, item)"
                                            style="cursor: pointer;">Xóa</a> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- ====================================
        tạo page tài liệu
        ==================================== -->
                <div *ngIf="totalElement > maxSize" class="pagination justify-content-end pages mt-3">
                    <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="5"
                        [rotate]="true" [ellipses]="false" [pageSize]="maxSize"
                        (pageChange)="getListEnterprises($event)" [boundaryLinks]="true"
                        aria-label="Default pagination">
                        <ng-template ngbPaginationFirst>Đầu</ng-template>
                        <ng-template ngbPaginationLast>Cuối</ng-template>
                        <ng-template ngbPaginationPrevious>&laquo;</ng-template>
                        <ng-template ngbPaginationNext>&raquo;</ng-template>
                        <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                    </ngb-pagination>
                </div>
            </div>
            <div class="col-md-8">
                <div class="card shadow-none">
                    <div class="card-body position-relative">
                        <div class="row pt-2 pb-2">
                            <div class="col-md-auto align-self-end">
                                <label class="text-lg">Thông tin tài khoản</label>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="sub-title">Thông tin tổ chức</div>
                                <div>
                                    <table class="table-info-enterprise">
                                        <tr>
                                            <td>Tên doanh nghiệp</td>
                                            <td>{{enterpriseDetail?.tenToChuc}}</td>
                                        </tr>
                                        <tr>
                                            <td>Tên viết tắt</td>
                                            <td>{{enterpriseDetail?.tenRutGon}}</td>
                                        </tr>
                                        <tr>
                                            <td>Mã số thuế</td>
                                            <td>{{enterpriseDetail?.maSoThue}}</td>
                                        </tr>
                                        <tr>
                                            <td>Giấy phép kinh doanh</td>
                                            <td>
                                                <a class="text-decoration-underline text-primary" style="cursor: pointer;" (click)="actDownloadGPKD(enterpriseDetail.partyId)">
                                                    {{enterpriseDetail?.gpkdFilename}}
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Gói cước</td>
                                            <td>{{ enterpriseDetail?.packageName }}</td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="sub-title mt-3">Thông tin người đại diện</div>
                                <div>
                                    <table class="table-info-enterprise">
                                        <tr>
                                            <td>Họ và tên</td>
                                            <td>{{enterpriseDetail?.hoTenDaiDien}}</td>
                                        </tr>
                                        <tr>
                                            <td>Email</td>
                                            <td>{{enterpriseDetail?.email}}</td>
                                        </tr>
                                        <tr>
                                            <td>Số điện thoại</td>
                                            <td>{{enterpriseDetail?.sdt}}</td>
                                        </tr>
                                        <tr>
                                            <td>Ngày sinh</td>
                                            <td>
                                                {{enterpriseDetail?.ngaySinhDaiDien | date: 'dd/MM/yyyy'}}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Giới tính</td>
                                            <td>{{enterpriseDetail?.gioiTinhDaiDien}}</td>
                                        </tr>
                                        <tr>
                                            <td>Địa chỉ</td>
                                            <td>{{enterpriseDetail?.diachiDaiDien}}</td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="sub-title mt-3">Thông tin tài khoản</div>
                                <div>
                                    <table class="table-info-enterprise">
                                        <tr>
                                            <td>Tên tài khoản</td>
                                            <td>{{enterpriseDetail?.username}}</td>
                                        </tr>
                                    </table>
                                </div>
                                <div class="sub-title mt-3">Thông tin đăng ký</div>
                                <div>
                                    <table class="table-info-enterprise">
                                        <tr>
                                            <td>Ngày đăng ký</td>
                                            <td>{{enterpriseDetail?.created}}</td>
                                        </tr>
                                        <tr>
                                            <td>Người từ chối</td>
                                            <td>{{enterpriseDetail?.approverId}}</td>
                                        </tr>
                                        <tr>
                                            <td>Ngày từ chối</td>
                                            <td>{{enterpriseDetail?.approveDate}}</td>
                                        </tr>
                                        <tr>
                                            <td>Lý do từ chối</td>
                                            <td>{{enterpriseDetail?.rejectReason}}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <!-- <div class="col-md-6">
                                <p>Video đã ghi lại</p>
                                <div class="row">
                                    <div class="col-md-6">
                                        <video class="img-cmnd" controls>
                                            <source
                                                src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4"
                                                type="video/mp4">
                                            <source
                                                src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4"
                                                type="video/ogg">
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                        <!-- </form> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-preview-modal *ngIf="showPreviewGPKD" [file]="fileGPKD" [name]="enterpriseDetail.gpkdFilename" (close)="handleClosePreview()"></app-preview-modal>