import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { ContractTypeService } from '../../services/contract-type.service';

@Component({
  selector: 'app-contract-type-detail',
  templateUrl: './contract-type-detail.component.html',
  styleUrls: ['./contract-type-detail.component.scss']
})
export class ContractTypeDetailComponent implements OnInit {

  id = this.activatedRoute.snapshot.paramMap.get('id') || null;
  // Option trạng thái
  status: boolean = true;
  statusOptions: Array<object> = [
    { value: false, label: "status_stopped" },
    { value: true, label: "status_running" }
  ];
  nameType: string = null;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alert: AlertControl,
    private contractTypeService: ContractTypeService
  ) { }

  ngOnInit(): void {
    this.getTypeDetails();
  }

  getTypeDetails() {
    this.contractTypeService.getTypeDetails(this.id).subscribe((res: any) => {
      const typeDetails = res.object ?? null;
      if (typeDetails != null) {
        this.nameType = typeDetails.name ?? '';
        this.status = typeDetails.status ?? false;
      }
    }, err => {
      this.alert.showErrorHandled(err);
    });
  }

  edit(id) {
    this.router.navigate([ConstantUrl.edit_contract_type, id]);
  }

}
