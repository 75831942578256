<app-spinner></app-spinner>
<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-auto">
                <h1 class="m-0 text-dark"><label>{{ 'ListContractNumberConfig_title' | translate }}</label></h1>
            </div>
            <div class="col-md-auto ml-auto">
                <button class="btn btn-primary d-inline-flex" routerLinkActive="router-link-active"
                    [routerLink]="['/app/console/configure-contract-number/add']"><span
                        class="material-icons mr-1">add</span> {{ 'btn_addnew' | translate }}</button>
            </div>
        </div>
    </div>
</div>

<section class="content">
    <div class="container-fluid">
        <div class="bg-white">
            <section class="p-3">
                <div class="row">
                    <div class="col-md-6">
                        <div class="input-group border rounded">
                            <div class="input-group-prepend border-0">
                                <button class="btn d-inline-flex pr-2 pl-2" type="submit" (click)="applyFilter()"><span
                                        class="material-icons">search</span></button>
                            </div>
                            <input type="text" class="form-control border-0" [(ngModel)]="searchString"
                                placeholder="{{ 'ListContractNumberConfig_ph_search' | translate }}" aria-label=""
                                aria-describedby="basic-addon1" (keydown.enter)="applyFilter()">
                        </div>
                    </div>
                </div>
            </section>

            <hr class="mt-0">

            <section class="px-3">
                <div class="row">
                    <div class="col-md-3">
                        <div class="form-group">
                            <label>{{ 'ListContractNumberConfig_label_filter' | translate }}</label>
                            <ng-select [(ngModel)]="action" [items]="actionOptions" bindValue="value"
                                [searchable]="false" [clearable]="false">
                                <ng-template ng-label-tmp let-item="item">
                                    <span>{{ item.label | translate }}</span>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item">
                                    <span>{{ item.label | translate }}</span>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <div class="align-self-end mb-3 ml-auto mr-2">
                        <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="resetFilter()">{{
                            'btn_reset' | translate }}</button>
                        <button class="btn btn-primary d-inline-flex" (click)="applyFilter()">{{
                            'btn_apply' | translate }}</button>
                    </div>
                </div>
            </section>

            <section class="card-body p-0">
                <div class="table-responsive enable-scroll">
                    <table class="table mb-0">
                        <thead>
                            <tr class="bg-tb-header border-top text-left">
                                <th class="td-content-centered" style="min-width: 30px;">{{
                                    'ListContractNumberConfig_table_header_1' | translate }}</th>
                                <th class="cursor-pointer" (click)="sortCol('name')" style="min-width: 260px;">
                                    <span class="d-flex align-self-center align-items-center">
                                        {{ 'ListContractNumberConfig_table_header_2' | translate }}
                                        <span *ngIf="sortColConfig['name'] == null" class="material-icons ml-auto">
                                            unfold_more
                                        </span>
                                        <span *ngIf="sortColConfig['name'] == true" class="material-icons ml-auto">
                                            expand_less
                                        </span>
                                        <span *ngIf="sortColConfig['name'] == false" class="material-icons ml-auto">
                                            expand_more
                                        </span>
                                    </span>
                                </th>
                                <th class="cursor-pointer" (click)="sortCol('action')" style="min-width: 280px;">
                                    <span class="d-flex align-self-center align-items-center">
                                        {{ 'ListContractNumberConfig_table_header_3' | translate }}
                                        <span *ngIf="sortColConfig['action'] == null" class="material-icons ml-auto">
                                            unfold_more
                                        </span>
                                        <span *ngIf="sortColConfig['action'] == true" class="material-icons ml-auto">
                                            expand_less
                                        </span>
                                        <span *ngIf="sortColConfig['action'] == false" class="material-icons ml-auto">
                                            expand_more
                                        </span>
                                    </span>
                                </th>
                                <th class="cursor-pointer" (click)="sortCol('disable')" style="min-width: 150px;">
                                    <span class="d-flex align-self-center align-items-center">
                                        {{ 'ListContractNumberConfig_table_header_4' | translate }}
                                        <span *ngIf="sortColConfig['disable'] == null" class="material-icons ml-auto">
                                            unfold_more
                                        </span>
                                        <span *ngIf="sortColConfig['disable'] == true" class="material-icons ml-auto">
                                            expand_less
                                        </span>
                                        <span *ngIf="sortColConfig['disable'] == false" class="material-icons ml-auto">
                                            expand_more
                                        </span>
                                    </span>
                                </th>
                                <th class="td-content-centered" style="min-width: 100px;">{{
                                    'ListContractNumberConfig_table_header_5' | translate }}</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="listSequence.length > 0">
                            <tr *ngFor="let item of listSequence; let i = index">
                                <td class="td-content-centered"><span
                                        *ngIf="(page - 1) * maxSize + i + 1 < 10">0</span>{{ (page - 1) * maxSize + i +
                                    1 }}</td>
                                <td>
                                    {{ item?.name }}
                                    <br *ngIf="item.default && item.default == 'Y'">
                                    <span *ngIf="item.default && item.default == 'Y'"
                                        class="text-primary font-italic">Cấu hình mặc định</span>
                                </td>
                                <td>{{ getActionLabel(item?.action) }}</td>
                                <td>
                                    <span class="badge {{ getStyleAndLabelBadge(item?.disable)[0] }} p-2">{{
                                        getStyleAndLabelBadge(item?.disable)[1] }}</span>
                                </td>
                                <td class="td-content-centered">
                                    <div class="dropdown">
                                        <a class="btn btn-dropdown dropdown-toggle" href="#" role="button"
                                            id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                            aria-expanded="false">
                                            <em class="material-icons">more_vert</em>
                                        </a>
                                        <!-- [routerLink]="['/console/report-customer/detail', item.userId]" -->
                                        <div class="dropdown-menu dropdown-menu-right"
                                            aria-labelledby="dropdownMenuLink">
                                            <!-- [routerLink]="['/console/licenses-manager/detail/', item.licenseId]" -->
                                            <a class="dropdown-item nav-material d-inline-flex"
                                                (click)="details(item?.id)" style="cursor: pointer;">
                                                <em class="material-icons-outlined">info</em>
                                                {{ 'ListContractNumberConfig_details' | translate }}</a>
                                            <a class="dropdown-item nav-material d-inline-flex"
                                                (click)="directToEdit(item?.id)" style="cursor: pointer;">
                                                <em class="material-icons">edit</em>
                                                {{ 'ListContractNumberConfig_edit' | translate }}</a>
                                            <a *ngIf="item.default && item.default == 'N' && item.disable && item.disable != 'Y'"
                                                class="dropdown-item nav-material d-inline-flex"
                                                style="cursor: pointer;" (click)="setToDefault(item?.id)">
                                                <span class="material-icons">
                                                    star
                                                </span>
                                                {{ 'ContractNumberDetail_setDefault' | translate }}
                                            </a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="bg-tb-header p-3">
                    <div class="row align-items-center">
                        <span *ngIf="totalElement == 0" class="col-md">{{ 'text_no_results' | translate }}</span>
                        <span *ngIf="totalElement > 0" class="col-md">{{ 'text_number_of_results_displayed' | translate:
                            {from: 1, to: listSequence.length, total: totalElement} }}</span>
                        <div class="col-md" *ngIf="totalElement > maxSize">
                            <div class="d-flex justify-content-end">
                                <div class="d-flex justify-content-end align-items-center">
                                    <span class="mr-2">{{ 'text_number_of_lines' | translate }}:</span>
                                    <ng-select [(ngModel)]="maxSize" [searchable]="false" [clearable]="false"
                                        placeholder="..." (change)="loadPage(1)">
                                        <ng-option [value]="10">10</ng-option>
                                        <ng-option [value]="20">20</ng-option>
                                        <ng-option [value]="30">30</ng-option>
                                    </ng-select>
                                </div>
                                <div *ngIf="totalElement > maxSize" class="pagination pages no-border-pagination ml-2">
                                    <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="5"
                                        [rotate]="true" [ellipses]="false" [pageSize]="maxSize"
                                        (pageChange)="loadPage($event)" [boundaryLinks]="true"
                                        aria-label="Default pagination">
                                        <ng-template ngbPaginationFirst>{{ 'text_first' | translate }}</ng-template>
                                        <ng-template ngbPaginationLast>{{ 'text_last' | translate }}</ng-template>
                                        <ng-template ngbPaginationPrevious>&laquo;</ng-template>
                                        <ng-template ngbPaginationNext>&raquo;</ng-template>
                                        <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                                    </ngb-pagination>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</section>