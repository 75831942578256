import { Component, ElementRef, OnInit, ViewChild, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateUtils } from 'src/app/utils/common/DateUtils';
import { REGEXP_DATE_INPUT } from 'src/app/utils/common/regexp';
import { UtilsFunc } from 'src/app/utils/common/utils-func';
import { ReportService } from 'src/app/utils/services/aas-network/report/report.service';
import { Options } from 'select2';
import { CatalogService } from 'src/app/utils/services/aas-network/catalog.service';
import { ListTinhResponse } from 'src/app/utils/models/catalog/list-tinh/list-tinh-response.model';
import { ReportQuantityV1Component } from '../../report-quantity-v1.component';
@Component({
  selector: 'app-report-quantity-v1-enterprise',
  templateUrl: './report-quantity-v1-enterprise.component.html',
  styleUrls: ['./report-quantity-v1-enterprise.component.scss']
})
export class ReportQuantityV1EnterpriseComponent implements OnInit {
  @ViewChild('fromDatePicker') _fromDate: ElementRef;
  @ViewChild('toDatePicker') _toDate: ElementRef;
  constructor(
    private fb: FormBuilder,
    private reportService: ReportService,
    private catalog: CatalogService,
    @Inject(ReportQuantityV1Component) private parent: ReportQuantityV1Component
  ) { }

  ngOnInit(): void {
    this.makeFormFilter();
    this.getListCustomers();
    this.getDmTinh();
  }

  selectedStatus: any[] = [];
  selectedAllStatus = false;
  statusOptions = ['Đã hết số lượng hợp đồng',
                    'Đã hết số lượng SMS OTP',
                    'Đã hết số lượng eKYC',
                    'Đã hết thời gian lưu trữ',
                    'Đã hết dung lượng lưu trữ',
                    'Còn lại'];
  changeStatus(e) {
    if (this.selectedStatus.length == this.statusOptions.length) this.selectedAllStatus = true;
    else this.selectedAllStatus = false;
  }

  selectAllStatus() {
    if (this.selectedAllStatus == false) {
      this.selectedStatus = [].concat(this.statusOptions);
      this.selectedAllStatus = true;
    } else {
      this.selectedStatus = [].concat([]);
      this.selectedAllStatus = false;
    }
  }

  selectedAllTinh = false;
  tinhBindData = [];

  selectedTinh: any[] = [];
  // selectedAllTinh = false;
  // statusOptions = ['Đã hết số lượng hợp đồng',
  //                   'Đã hết số lượng SMS OTP',
  //                   'Đã hết số lượng eKYC',
  //                   'Đã hết thời gian lưu trữ',
  //                   'Đã hết dung lượng lưu trữ',
  //                   'Còn lại'];
  // changeTinh(e) {
  //   if (this.selectedTinh.length == this.tinhBindData.length) this.selectedAllTinh = true;
  //   else this.selectedAllTinh = false;
  // }

  selectAllTinh() {
    if (this.selectedAllTinh == false) {
      // this.selectedTinh = [].concat(this.tinhBindData);
      
      var idAllTinh = this.tinhBindData.map(function(item) {
        return item['id'];
      });
      

      this.formFilter.get("tinhId").setValue(idAllTinh);
      this.selectedAllTinh = true;
    } else {
      this.formFilter.get("tinhId").setValue([]);
      this.selectedAllTinh = false;
    }
  }
  // selectAllTinh() {
  //   this.formFilter.get("tinhId").setValue(this.tinhBindData);
  //   // this.formFilter.value.tinhId = this.tinhBindData;
  // }

  // unselectAllTinh() {
  //   this.formFilter.get("tinhId").setValue([]);
  // }

  // List Customers
  listCustomers: Array<any> = [];
  // Đối số getListCustomers()
  keySearch = ""
  page: number = 1;
  maxSize: number = 10;
  sort = "DESC"
  // propertiesSort = "created"
  userType = "CONSUMER"
  totalElement: number = 0;

  // config col table
  listConfigColTable: Array<any> = [
    {
      label: "Tên thuê bao",
      name: "tenThueBao"
    },
    {
      label: "Mã số thuế",
      name: "maSoThue"
    },
    {
      label: "Địa chỉ",
      name: "diaChi"
    },
    {
      label: "Hình thức sử dụng",
      name: "hinhThucSuDung"
    },
    {
      label: "Nhân viên bán hàng",
      name: "nhanVienBanHang"
    },
    {
      label: "Tỉnh/TP",
      name: "tinhTP"
    },
    {
      label: "Ngày khởi tạo",
      name: "ngayKhoiTao"
    },
    {
      label: "Trạng thái",
      name: "trangThai"
    },
    {
      label: "Số lượng hợp đồng",
      name: "soLuongHopDong"
    },
    {
      label: "Số lượt SMS OTP",
      name: "soLuotSMSOTP"
    },
    {
      label: "Số lượt eKYC",
      name: "soLuotEKYC"
    },
    {
      label: "Thời gian lưu trữ(Tháng)",
      name: "thoiGianLuuTru"
    },
    {
      label: "Dung lượng lưu trữ(GB)",
      name: "dungLuongLuuTru"
    },
    {
      label: "Số lượt SMS",
      name: "soLuotSMS"
    }
  ];

  // statusOptions = ['adam', 'nicole', 'alba'];

  optionsStatus: Options;

  configColTable: any = {
    tenThueBao: true,
    maSoThue: true,
    diaChi: true,
    hinhThucSuDung: true,
    nhanVienBanHang: true,
    tinhTP: true,
    ngayKhoiTao: true,
    trangThai: true,
    soLuongHopDong: true,
    soLuotSMSOTP: true,
    soLuotEKYC: true,
    thoiGianLuuTru: true,
    dungLuongLuuTru: true,
    soLuotSMS: true
  }

  formFilter: FormGroup;
  dateType = {
    CREATED_FROM: 'CREATED_FROM',
    CREATED_TO: 'CREATED_TO'
  };
  currentDate = new Date();
  optionsCleave = {
    date: true,
    delimiter: '/',
    datePattern: ['d', 'm', 'Y']
  };
  validateSearch = {
    fromDate: {
      pattern: "Sai định dạng ngày tháng năm!",
      minDate: 'Từ ngày không được lớn hơn đến ngày và không được là ngày tương lai!'
    },
    toDate: {
      pattern: "Sai định dạng ngày tháng năm!"
    }
  };
  isSubmit: boolean = false;

  
  getDmTinh() {
    this.catalog.getListTinh().subscribe((res) => {
      let dmTinh = new ListTinhResponse(res);
      this.tinhBindData = dmTinh.object.map(item => {
        return { id: item.tinhId, label: item.tenTinh };
      });
    }, err => {
      console.log(err);
    });
  };

  sortColConfig = {
    ten: null,
    cmnd: null,
    created: null,
    duration: null,
    AMPartyName: null,
    maNv: null,
    packageNum: null,
    sms: null
  }



  // keySearch = "", page = 1, maxSize = 10, sort = "DESC", propertiesSort = "created",
  // userType = "CONSUMER", fromDate = "", toDate = "", packageNum = "-1"
  // -----------------------------------------------------

  // Lấy dữ liệu từ BE
  getListCustomers(page = 1) {
    this.reportService.getListOrgInfo(this.formFilter.value.tinhId, this.formFilter.value.fromDate, this.formFilter.value.toDate,
      this.keySearch,"ENTERPRISE",
       this.page, this.maxSize, this.sort, UtilsFunc.getPropertiesSort(this.sortColConfig),
       this.formFilter.value.disabled, this.formFilter.value.statusUse, this.formFilter.value.packageType
    ).subscribe((res: any) => {
      this.listCustomers = res.object?.data || [];
      this.totalElement = res.object?.totalElement || 0;
      this.parent.totalElementEnterprise = this.totalElement;
    })
 // GET 'http://localhost:3000//org-info?
  // tinhId=&fromDate=&toDate=&keySearch=&partyType=ENTERPRISE&
  // page=1&maxSize=10&sort=DESC&propertiesSort=createdDate&
  // disabled=N&statusUse=[0,0,0,0,0,0]&packageType'
  }
  /**
   * Tim kiem voi bo loc
   */
  searchWithFilter() {
    this.loadPage(1);
  }

  /**
   * Tai trang
   * @param page 
   */
   loadPage(page) {
    this.page = page;
    this.getListCustomers(this.page);
  }

  /**
   * Tra ve style cho span method
   * @param packageNum 
   */
   getStyleAndValueBadge(packageNum): string[] {
    if (packageNum == "0") {
      return ['badge-primary-new', 'Dùng thử'];
    }
    return ['badge-success-new', 'Chính thức'];
  }

  //MARK: Action cấu hình hiển thị cột của bảng
  actConfigColTableView(e, field) {
    e.stopPropagation();
    if (this.configColTable[field] !== null && this.configColTable[field] !== undefined) {
      this.configColTable[field] = !this.configColTable[field];
    }
  }

 /**
   * Khoi tao form filter
   */
  makeFormFilter() {
    this.formFilter = this.fb.group({
      fromDate: new FormControl('', [Validators.pattern(REGEXP_DATE_INPUT)]),
      toDate: new FormControl('', [Validators.pattern(REGEXP_DATE_INPUT)]),
      disabled: new FormControl(''),
      statusUse: new FormControl([]),
      packageType: new FormControl(''),
      tinhId: new FormControl([]),
    });
  }

  getPackageTypeLabel(packageType) {
    if (packageType === "TRIAL_TYPE") {
      return "Gói thử nghiệm";
    } else if (packageType === "NORMAL_TYPE") {
      return "Gói chính thức";
    } else if (packageType === "EXTEND_TYPE") {
      return "Gói hạ tầng mở rộng";
    }
    return "";
  }

  getClassStatus(status) {
    var st = `${status}`;

    if (st === "Y") return "badge-danger-new";
    if (st === "N") return "badge-success-new";
    return "";
  }

  getTextStatus(status) {
    var st = `${status}`;

    if (st === "Y") return "Ngưng hoạt động";
    if (st === "N") return "Đang hoạt động";
    return "";
  }

  get fromDate() {
    return DateUtils.convertInputToFormat(this.formFilter.value.fromDate, DateUtils.format.API);
  }
  get toDate() {
    return DateUtils.convertInputToFormat(this.formFilter.value.toDate, DateUtils.format.API);
  }
  get status() {
    return this.formFilter.value.status;
  }

  /**
  * Thay doi ngay tren filter
  * @param value 
  * @param type 
  */
    changeDateFilter(event, type) {
    switch (type) {
      case this.dateType.CREATED_FROM: 
        this.formFilter.controls.fromDate.setValue(DateUtils.convertObjtoDate(event));
        break;
      case this.dateType.CREATED_TO:
        this.formFilter.controls.toDate.setValue(DateUtils.convertObjtoDate(event));
        break;
    }
  }

  // Thay doi trang thai tren filter
  changePackageType(event){
    this.formFilter.controls.packageType.setValue(event.target.value)
  }

  changeDisabled(event){
    this.formFilter.controls.disabled.setValue(event.target.value)
  }

  submitFilter() {
    this.formFilter.value.statusUse = [];
    // this.formFilter.value.statusUse.push("[");
    
    for (var i = 0; i < this.statusOptions.length; i++) {
      if (
        this.selectedStatus.findIndex((x) => {
          return x == this.statusOptions[i];
        }) >= 0
      ) {
        // if(i== 0) {
        //   this.formFilter.value.statusUse.push("[1");
        // }
        // else if(i == 5) {
        //   this.formFilter.value.statusUse.push("1]");
        // }  
        // else {
          this.formFilter.value.statusUse.push("1");
        // }
        
      } else {
      //   if(i== 0) {
      //     this.formFilter.value.statusUse.push("[0");
      //   } else if(i==5) {
      //     this.formFilter.value.statusUse.push("0]");
        // } else {
          this.formFilter.value.statusUse.push(0);
        // }
      }
    }

    this.isSubmit = true;

    if (this.formFilter.valid) {
        let dateFrom = this.formFilter.value.fromDate.length > 0 ? new Date(DateUtils.convertStringtoDateSearch(this.formFilter.value.fromDate)).getTime() : -1;
        let dateTo = this.formFilter.value.toDate.length > 0 ? new Date(DateUtils.convertStringtoDateSearch(this.formFilter.value.toDate)).getTime() : -1;
      if ((dateTo > 0 && dateFrom > 0 && dateFrom > dateTo)) {
        this.formFilter.controls.fromDate.setErrors({ minDate: true });
        }
    }

    if (this.formFilter.valid) {
      console.log(this.formFilter.value);
      this.searchWithFilter();
    }
  }

  cleanFilter() {
    this.isSubmit = false;
    this._fromDate.nativeElement.value = '';
    this._toDate.nativeElement.value = '';
    this.formFilter.reset();
    this.formFilter.controls.fromDate.setValue('');
    this.formFilter.controls.toDate.setValue('');
    this.formFilter.controls.packageType.setValue('');
    this.formFilter.controls.disabled.setValue('');
    this.formFilter.controls.statusUse.setValue(this.statusOptions);
    var idAllTinh = this.tinhBindData.map(function(item) {
      return item['id'];
    });
    this.formFilter.controls.tinhId.setValue(idAllTinh);
    this.searchWithFilter()
  }

  /**
   * Sap xem theo cot
   * @param key 
   */
   sortCol(key) {
    if (this.sortColConfig[key] == null) {
      this.sortColConfig[key] = true;
    } else if (this.sortColConfig[key] == false) {
      this.sortColConfig[key] = null;
    } else {
      this.sortColConfig[key] = !this.sortColConfig[key];
    }

    this.loadPage(this.page);
  }

  
  downloadSample() {
    this.reportService.exportListOrgInfo(this.formFilter.value.tinhId, this.formFilter.value.fromDate, this.formFilter.value.toDate,
      this.keySearch,"ENTERPRISE",
       this.formFilter.value.disabled, this.formFilter.value.statusUse , this.formFilter.value.packageType
    ).subscribe((res: any) => {
      const blob: any = new Blob([res], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      var url = window.URL.createObjectURL(blob);
      var anchor = document.createElement("a");
      anchor.download = `document.xlsx`;
      anchor.href = url;
      anchor.click();
      })   
  }

  checkColor(divide,total){
    let green = '#29D395'
    let red = '#FF7474'
    if (divide == 0 || total == 0){
      return green
    }
    if (divide/total*100 >= 75){
      return red
    }
    else if (divide/total*100 > 50){
      return 'yellow'
    }
    return green
  }

}
