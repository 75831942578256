<div class="bg-white">
    <section class="p-3">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <form class="input-group border rounded">
                    <div class="input-group-prepend border-0">
                        <button class="btn d-inline-flex pr-2 pl-2" type="submit"><span
                                class="material-icons">search</span></button>
                    </div>
                    <input [(ngModel)]="keySearch" [ngModelOptions]="{standalone: true}" type="text"
                    class="form-control border-0" placeholder="Tìm kiếm theo họ và tên, số giấy tờ, thời gian sử dụng, đơn vị yêu cầu, mã HRM nhân viên" aria-describedby="basic-addon1" #searchField
                    (keydown.enter)="searchWithFilter()">
                </form>
            </div>
            <div class="col-md-auto ml-auto">
                <div class="btn-group">
                    <div ngbDropdown container="body" [autoClose]="'outside'" [placement]="'bottom-right'" [display]="'dynamic'">
                        <button ngbDropdownToggle class="btn btn-outline-primary dropdown-toggle d-inline-flex align-items-center">
                            <span class="material-icons mr-1">visibility</span>Hiển thị
                        </button>
                        <div ngbDropdownMenu>
                            <div ngbDropdownItem *ngFor="let item of listConfigColTable" class="p-0">
                                <div  class="tcheck-primary px-3">
                                    <input type="checkbox" id="{{ item.name }}" [checked]="configColTable[item.name]">
                                    <label for="{{ item.name }}" class="w-100" [class.checked]="configColTable[item.name]"
                                        (click)="actConfigColTableView($event, item.name)">
                                        {{ item.label }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </section>
    <hr class="">

    <section class="px-3 my-2">
        <form [formGroup]="formFilter">
            <div class="row">
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="">Từ ngày yêu cầu</label>
                        <div class="input-group">
                            <input #fromDatePicker class="form-control" [control]="formFilter.controls.fromDate"
                                [value]="formFilter.value.fromDate"
                                (dateSelect)="changeDateFilter($event, dateType.CREATED_FROM)"
                                placeholder="dd/MM/yyyy" ngbDatepicker #createdFromDate="ngbDatepicker"
                                [maxDate]="{year: currentDate.getFullYear(), month: currentDate.getMonth()+1, day: currentDate.getDate()}"
                                appInputMark [options]="optionsCleave">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" (click)="createdFromDate.toggle()"
                                    type="button"><em class="fas fa-calendar-alt"></em></button>
                            </div>
                            <app-show-validate-errors [useSubmit]="false"
                                [errorMessages]="validateSearch.fromDate"
                                [control]="formFilter.controls.fromDate"
                                [detail]="{ name: 'fromDate' }">
                            </app-show-validate-errors>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="">Đến ngày yêu cầu</label>
                        <div class="input-group">
                            <input #toDatePicker class="form-control" [control]="formFilter.controls.toDate"
                                [value]="formFilter.value.toDate"
                                (dateSelect)="changeDateFilter($event, dateType.CREATED_TO)"
                                placeholder="dd/MM/yyyy" ngbDatepicker #createdToDate="ngbDatepicker"
                                [maxDate]="{year: currentDate.getFullYear(), month: currentDate.getMonth()+1, day: currentDate.getDate()}"
                                appInputMark [options]="optionsCleave">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" (click)="createdToDate.toggle()"
                                    type="button"><em class="fas fa-calendar-alt"></em></button>
                            </div>
                            <app-show-validate-errors [useSubmit]="false"
                                [errorMessages]="validateSearch.toDate"
                                [control]="formFilter.controls.toDate"
                                [detail]="{ name: 'toDate' }">
                            </app-show-validate-errors>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="status">Trạng thái sử dụng</label>
                        <select formControlName="status" class="form-control"
                        (change)="changeStatus($event)">
                            <option value="-1" selected>Tất cả</option>
                            <option value="0">Dùng thử</option>
                            <option value="1">Chính thức</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-auto ml-auto mt-auto pb-3">
                    <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="cleanFilter()"> Đặt lại</button>
                    <button class="btn btn-primary d-inline-flex" (click)="submitFilter()"> Áp dụng</button>
                </div>
            </div>
        </form>
        
    </section>
    <hr class="">

    <section class="card-body p-0">
        <div class="table-responsive">
            <table class="table table-sort-col mb-0">
                <thead>
                    <tr class="bg-tb-header">
                        <th>STT</th>
                        <th [class.d-none]="!configColTable.hoVaTen" (click)="sortCol('ten')">
                            <span class="d-flex align-self-center align-items-center">
                                Họ và tên
                                <span *ngIf="this.sortColConfig['ten'] == null" class="material-icons ml-auto">
                                    unfold_more
                                </span>
                                <span *ngIf="this.sortColConfig['ten'] == true" class="material-icons ml-auto">
                                    expand_less
                                </span>
                                <span *ngIf="this.sortColConfig['ten'] == false" class="material-icons ml-auto">
                                    expand_more
                                </span>
                            </span>
                        </th>
                        <th [class.d-none]="!configColTable.soGiayTo" (click)="sortCol('cmnd')">
                            <span class="d-flex align-self-center align-items-center">
                                Số giấy tờ
                                <span *ngIf="this.sortColConfig['cmnd'] == null" class="material-icons ml-auto">
                                    unfold_more
                                </span>
                                <span *ngIf="this.sortColConfig['cmnd'] == true" class="material-icons ml-auto">
                                    expand_less
                                </span>
                                <span *ngIf="this.sortColConfig['cmnd'] == false" class="material-icons ml-auto">
                                    expand_more
                                </span>
                            </span>
                        </th>
                        <th [class.d-none]="!configColTable.ngayYeuCau" (click)="sortCol('created')">
                            <span class="d-flex align-self-center align-items-center">
                                Ngày yêu cầu
                                <span *ngIf="this.sortColConfig['created'] == null" class="material-icons ml-auto">
                                    unfold_more
                                </span>
                                <span *ngIf="this.sortColConfig['created'] == true" class="material-icons ml-auto">
                                    expand_less
                                </span>
                                <span *ngIf="this.sortColConfig['created'] == false" class="material-icons ml-auto">
                                    expand_more
                                </span>
                            </span>
                        </th>
                        <th [class.d-none]="!configColTable.thoiGianSuDung" (click)="sortCol('duration')">
                            <span class="d-flex align-self-center align-items-center">
                                Thời gian sử dụng
                                <span *ngIf="this.sortColConfig['duration'] == null" class="material-icons ml-auto">
                                    unfold_more
                                </span>
                                <span *ngIf="this.sortColConfig['duration'] == true" class="material-icons ml-auto">
                                    expand_less
                                </span>
                                <span *ngIf="this.sortColConfig['duration'] == false" class="material-icons ml-auto">
                                    expand_more
                                </span>
                            </span>
                        </th>
                        <th [class.d-none]="!configColTable.donViYeuCau" (click)="sortCol('AMPartyName')">
                            <span class="d-flex align-self-center align-items-center">
                                Đơn vị yêu cầu
                                <span *ngIf="this.sortColConfig['AMPartyName'] == null" class="material-icons ml-auto">
                                    unfold_more
                                </span>
                                <span *ngIf="this.sortColConfig['AMPartyName'] == true" class="material-icons ml-auto">
                                    expand_less
                                </span>
                                <span *ngIf="this.sortColConfig['AMPartyName'] == false" class="material-icons ml-auto">
                                    expand_more
                                </span>
                            </span>
                        </th>
                        <th [class.d-none]="!configColTable.maHrmNhanVien" (click)="sortCol('maNv')">
                            <span class="d-flex align-self-center align-items-center">
                                Mã HRM nhân viên
                                <span *ngIf="this.sortColConfig['maNv'] == null" class="material-icons ml-auto">
                                    unfold_more
                                </span>
                                <span *ngIf="this.sortColConfig['maNv'] == true" class="material-icons ml-auto">
                                    expand_less
                                </span>
                                <span *ngIf="this.sortColConfig['maNv'] == false" class="material-icons ml-auto">
                                    expand_more
                                </span>
                            </span>
                        </th>
                        <th [class.d-none]="!configColTable.trangThaiSuDung" (click)="sortCol('packageNum')">
                            <span class="d-flex align-self-center align-items-center">
                                Trạng thái sử dụng
                                <span *ngIf="this.sortColConfig['packageNum'] == null" class="material-icons ml-auto">
                                    unfold_more
                                </span>
                                <span *ngIf="this.sortColConfig['packageNum'] == true" class="material-icons ml-auto">
                                    expand_less
                                </span>
                                <span *ngIf="this.sortColConfig['packageNum'] == false" class="material-icons ml-auto">
                                    expand_more
                                </span>
                            </span>
                        </th>
                        
                        <th>Thao tác</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of listCustomers; let i = index">
                        <td>
                            {{ ((page - 1) * maxSize) + i + 1 }}
                        </td>
                        <td [class.d-none]="!configColTable.hoVaTen">
                            <a class="text-primary text-decoration-underline cursor-pointer"
                                    [routerLink]="['/console/report-customer/detail', item.userId]">{{ item.ten || 'NULL' }}</a>
                        </td>
                        <td [class.d-none]="!configColTable.soGiayTo">
                            {{ item.cmnd }}
                        </td>
                        <td [class.d-none]="!configColTable.ngayYeuCau">
                            {{ item.created }}
                        </td>
                        <td [class.d-none]="!configColTable.thoiGianSuDung">
                            {{ item.duration }} tháng
                        </td>
                        <td [class.d-none]="!configColTable.donViYeuCau">
                            {{ item.AMPartyName}}
                        </td>
                        <td [class.d-none]="!configColTable.maHrmNhanVien">
                            {{ item.maNv}}
                        </td>
                        <td [class.d-none]="!configColTable.trangThaiSuDung">
                            <span class="badge {{ getStyleAndValueBadge(item.packageNum)[0] }} p-2">{{ getStyleAndValueBadge(item.packageNum)[1] }}</span>
                        </td>
                        <td>
                            <div class="dropdown">
                                <a class="btn btn-dropdown dropdown-toggle" href="#" role="button"
                                    id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    <em class="material-icons">more_vert</em>
                                </a>

                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                                    <!-- [routerLink]="['/console/licenses-manager/detail/', item.licenseId]" -->
                                    <a class="dropdown-item nav-material" style="cursor: pointer;"
                                    [routerLink]="['/console/report-customer/detail', item.userId]"
                                        routerLinkActive="router-link-active">
                                        <em class="material-icons">info</em>
                                        Thông tin chi tiết</a>
                                    
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        
        <div class="bg-tb-header p-3">
            <div class="row align-items-center">
                <span *ngIf="totalElement == 0" class="col-md">Không tìm thấy kết quả</span>
                <span *ngIf="totalElement > 0" class="col-md">Hiển thị 1 đến {{ listCustomers.length }} trong tổng số
                    {{totalElement}} kết quả</span>
                <div class="col-md">
                    <div class="d-flex justify-content-end">
                        <div *ngIf="totalElement > maxSize" class="d-flex justify-content-end align-items-center">
                            <span class="mr-2">Số dòng hiển thị:</span>
                            <ng-select class="no-bg no-border" [(ngModel)]="maxSize" [searchable]="false" [clearable]="false" placeholder="..." (change)="getListCustomers()">
                                <ng-option [value]="10">10</ng-option>
                                <ng-option [value]="20">20</ng-option>
                                <ng-option [value]="30">30</ng-option>
                            </ng-select>
                        </div>
                        <div *ngIf="totalElement > maxSize" class="pagination pages no-border-pagination ml-2">
                            <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="false"
                                [pageSize]="maxSize" (pageChange)="getListCustomers($event)" [boundaryLinks]="true" aria-label="Default pagination">
                                <ng-template ngbPaginationFirst>Đầu</ng-template>
                                <ng-template ngbPaginationLast>Cuối</ng-template>
                                <ng-template ngbPaginationPrevious>&laquo;</ng-template>
                                <ng-template ngbPaginationNext>&raquo;</ng-template>
                                <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                            </ngb-pagination>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        
    </section> 
    
   
     
</div>

