import { Component, OnInit, AfterViewInit, Renderer2, ViewChild, ElementRef, Input } from '@angular/core';
import { CopyrightItem } from 'src/app/utils/models/aas-models/copyright-manager/copyright-list/copyright-item.model';
import { ListCopyrightResponse } from 'src/app/utils/models/aas-models/copyright-manager/copyright-list/list-copyright-response.model'
import { from } from 'rxjs';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { AuthService } from 'src/app/utils/services/auth.service';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { Router } from '@angular/router';
import { AasLicensesService } from 'src/app/utils/services/aas-network/aas-licenses-manager.service'
import {
  map,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap
} from "rxjs/operators";

@Component({
  selector: 'app-copyright-list',
  templateUrl: './copyright-list.component.html',
  styleUrls: ['./copyright-list.component.scss']
})
export class CopyrightListComponent implements OnInit {
  @ViewChild("searchFieldLicense") searchField: ElementRef;

  copyrightItem : CopyrightItem;
  listCopyrightRes : ListCopyrightResponse;
  listCopyright : CopyrightItem[] = [];

  page = 1
  maxSize = 10
  propertiesSort = 'created';
  sort = 'DESC';
  totalElement = 0;

  customerStatus = '';
  customerStatusFilter = '';
  customerType = '';
  customerTypeFilter = '';
  saveKeySearch = '';
  keySearch = "";

  filterDateError = false;
  formSearch: FormGroup;
  formFilter: FormGroup;

  searchFields: FormControl;
  private results ;


  status = {
    ENABLED: {
      style: 'badge-success-new',
      label: 'Đang hoạt động',
    },
    DISABLED: {
      style: 'badge-danger-new',
      label: 'Ngưng hoạt động',
    },
  }
  

  partyType = {
    BUSINESS: 'Tổ chức SME',
    ENTERPRISE: 'Tổ chức',
    CONSUMER: 'Cá nhân'
  }

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private renderer2: Renderer2,
    private router: Router,
    private auth: AuthService,
    private aasAuth: AasAuthService,
    private licenseService: AasLicensesService,

  ) { }

  ngOnInit(): void {
    this.makeFormSearch()
    this.customerStatus = ''
    this.customerStatusFilter = this.customerStatus;
    this.customerType = '';
    this,this.customerTypeFilter = this.customerType;
    this.getListLicenses();
    this.searchFields = new FormControl();
    this.results = this.searchFields.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(_ => (this.search(this.searchField.nativeElement.value))),
    ).subscribe();

  }
  // get danh sách bản quyền
  getListLicenses(){
   this.licenseService.getListLicenses(this.page, this.maxSize, this.propertiesSort, this.sort,this.customerType, this.customerStatus, this.keySearch.normalize('NFC')).subscribe((res)=>{
    this.listCopyrightRes = new ListCopyrightResponse(res['object'])
    this.listCopyright = this.listCopyrightRes.data
    this.page = this.listCopyrightRes.page;
    this.totalElement = this.listCopyrightRes.totalElement;
    sessionStorage.setItem('licensesListKeySearch', this.keySearch);
    
  }, err => {
    console.log(err);
    this.handleError(err, ResponseMsg.MESSAGES.ALL_IN_ONE);
  })
  }



  //MARK: Khởi tạo form search
  makeFormSearch() {
    this.formSearch = this.fb.group({
      search: new FormControl('')
    });
  }

  // input thông tin search
  inputKeySearch(e) {
    this.saveKeySearch = e.target.value;
  }

  // search và get lại dánh bản quyền
  search(keyWord) {
    this.page = 1;
    this.keySearch = keyWord.trim();
    this.getListLicenses();
  }

  changeStatusCustomer(e){
    this.customerStatus = e.target.value;
  }

  changeCustomerType(e){
    this.customerType = e.target.value;
  }

   //MARK: Tìm kiếm với filter
   searchWithFilter() {
    if (!this.filterDateError) {
      sessionStorage.setItem('customerTypeList', this.customerType);
      sessionStorage.setItem('customerStatusList', this.customerStatus);

      // Lưu lại để hiển thị
      this.customerTypeFilter = this.customerType;
      this.customerStatusFilter = this.customerStatus;
      this.page = 1;
      this.getListLicenses();
    }
  }

  // hủy filter khi bấm nút hủy bộ lọc
  cleanFilter(){
    this.customerStatus = '';
    this.customerType='';
    this.keySearch = '';
    this.saveKeySearch = this.keySearch
    this.searchFields.reset()

    this.customerStatusFilter = this.customerStatus;
    this.customerTypeFilter = this.customerType;
    this.page = 1;
    this.searchWithFilter();
  }

  
  loadPage(page){
    this.page = page;
    this.getListLicenses();
  }

  goToDetail(licenseId, packageId, partyID) {
    console.log(licenseId, packageId, partyID); 
    this.router.navigate(['/console/licenses-manager/detail/', licenseId, packageId], { queryParams: {partyId: partyID}});
  }
  
   //MARK: Popup thông báo
   alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }


  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], 'error');
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, 'error');
    } else {
      this.alert('Lỗi không xác định!', 'error');
    }
  }
}
