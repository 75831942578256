import { MainResponse } from '../../../main-response.model';
import { ApiListAllObject } from './api-list-all-object.model';

export class ApiListAllResponse extends MainResponse {
    object: ApiListAllObject;

    constructor(data = null) {
        super();
        data = data || {};

        this.init(data);
        this.object = new ApiListAllObject(data.object);
    }
}