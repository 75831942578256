<div class="card shadow-none" id="edit-config-usb-token">
    <div class="card-body">
        <div class="row">
            <div class="col-md-auto align-self-end">
                <h4><label>{{ 'SmartcaConfig_title' | translate }}</label></h4>
            </div>
            <div class="col-md-auto ml-auto">
                <div class="d-flex justify-content-end mb-2">
                    <button class="btn btn-outline-primary d-inline-flex mr-4" (click)="actCancel()"><span
                            class="material-icons mr-2">clear</span>{{ 'btn_cancel' | translate }}</button>
                    <button class="btn btn-primary d-inline-flex" (click)="submit()"><span class="material-icons mr-2">save</span>{{ 'ServiceSignatureConfig_label_1' | translate }}</button>
                </div>
            </div>
        </div>
        <hr>
        <form [formGroup]="formConfig">
            <div class="row">
                <div class="col-md-12">
                    <label class="text-primary font-weight-bold">{{ 'SmartcaManager_label_17' | translate }}</label>
                </div>
            </div>
            <hr>
            <div class="row mb-3">
                <div class="col-lg-6 col-md-12">
                    <div class="ccheck-primary w-100 d-inline-block">
                        <input formControlName="signDate" type="checkbox" id="signDate">
                        <label for="signDate" class="w-100">
                            {{ 'SmartcaManager_label_19' | translate }}
                        </label>
                    </div>
                    <div class="ccheck-primary w-100 d-inline-block">
                        <input formControlName="signBy" type="checkbox" id="signBy">
                        <label for="signBy" class="w-100">
                            {{ 'SmartcaManager_label_20' | translate }}
                        </label>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12 align-items-end d-flex">
                    <div class="form-group w-100">
                        <label>{{ 'ContractCensorship_label_19' | translate }}:</label>
                        <input formControlName="fontSize" type="text"
                            class="form-control" placeholder="{{ 'ContractCensorship_ph_1' | translate }}">
                        <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="validMsg.fontSize"
                            [control]="formConfig.controls.fontSize" [detail]="{ name: 'fontSize' }">
                        </app-show-validate-errors>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <label class="text-primary font-weight-bold">{{ 'ContractCensorship_label_20' | translate }}</label>
                </div>
            </div>
            <hr>
            <div class="row mb-3">
                <div class="col-md-6">
                    <label for="signType">{{ 'ContractCensorship_label_21' | translate }}:</label>
                    <ng-select formControlName="visibleType" 
                        placeholder="{{ 'ContractCensorship_label_21' | translate }}" 
                        [items]="options" bindLabel="name" bindValue="id">
                    </ng-select>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <label class="text-primary font-weight-bold">{{ 'ContractCensorship_label_22' | translate }}</label>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ 'ContractCensorship_label_23' | translate }}:</label>
                        <div class="input-group mb-1">
                            <div class="custom-file custom-file-icon">
                                <span class="icon-file material-icons">attach_file</span>
                                <label class="custom-file-label">{{ inputFileLabel | translate }}</label>
                                <input formControlName="imageFile" (input)="handleFileInput($event)" type="file"
                                    accept="image/*" class="custom-file-input">
                            </div>
                        </div>
                        <app-show-validate-errors [useSubmit]="false" [isSubmit]="isSubmit" [errorMessages]="validMsg.imageFile"
                            [control]="formConfig.controls.imageFile" [detail]="{ name: 'imageFile' }">
                        </app-show-validate-errors>
                    </div>
                    <img *ngIf="imgContent" class="w-50" [src]="imgContent">
                </div>
            </div>
        </form>
    </div>
</div>
