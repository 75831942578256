import { Component, OnInit } from '@angular/core';
import { UsbTokenCertItem } from 'src/app/utils/models/signature/user-info/usb-token-cert-item.model';
import { UsbTokenCertResponse } from 'src/app/utils/models/signature/user-info/usb-token-cert-item-response.model';
import { Config } from 'src/app/config';
import { AuthService } from 'src/app/utils/services/auth.service';
import { SignatureService } from 'src/app/utils/services/aas-network/signature.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { share, delay } from 'rxjs/operators';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { UsbTokenItem } from 'src/app/utils/models/aas-models/signature/list-usb-token/usb-token-item';
import { ListUsbTokenResponse } from 'src/app/utils/models/aas-models/signature/list-usb-token/list-usb-token-response';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { DateUtils } from 'src/app/utils/common/DateUtils';
import { ConstantAlertBtn } from 'src/app/utils/common/constant-alert-msg';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { TranslateService } from '@ngx-translate/core';

declare var vnpt_plugin: any;
@Component({
  selector: 'app-usb-token-manager',
  templateUrl: './usb-token-manager.component.html',
  styleUrls: ['./usb-token-manager.component.scss']
})
export class UsbTokenManagerComponent implements OnInit {
  spinnerR = new BehaviorSubject<boolean>(false);
  spinnerL = new BehaviorSubject<boolean>(false);
  spinnerLg = new BehaviorSubject<boolean>(false);

  userId: string = this.authService.userId;
  keyPluginCA = Config.KEY_PLUGIN_CA;

  listUsbToken: UsbTokenItem[] = [];
  listUsbTokenBase: UsbTokenItem[] = [];
  usbTokenSelected: UsbTokenItem = new UsbTokenItem();
  isfilter: boolean = false;

  usbTokenCertResponse = new UsbTokenCertResponse;
  itemUsbTokenCert = new UsbTokenCertItem();

  totalElement: number = 0;
  maxSize: number = 5;
  page: number = 1;

  formSearch: FormGroup;
  isEditConfig = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private aasAuth: AasAuthService,
    private signatureService: SignatureService,
    private modalService: NgbModal,
    private alert: AlertControl,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.spinnerLg.next(true);
    this.makeFormSearch();
    this.checkPlugin();
    this.getListUsbToken(this.authService.userId);
  }

  //MARK: Spinner
  spinnerRShow(): Observable<boolean> {
    return this.spinnerR.asObservable().pipe(share());
  }

  spinnerLShow(): Observable<boolean> {
    return this.spinnerL.asObservable().pipe(share());
  }

  spinnerLgShow(): Observable<boolean> {
    return this.spinnerLg.asObservable().pipe(share());
  }

  //MARK: Make form
  makeFormSearch() {
    this.formSearch = this.fb.group({
      search: new FormControl('')
    });
  }

  get keySearch() { return this.formSearch.get('search'); }

  //MARK: Plugin CA (usb-token)
  checkPlugin() {
    const key = this.keyPluginCA;
    var self = this;
    vnpt_plugin.checkPlugin().then(function (data) {
      if (data === "1") {
        vnpt_plugin.setLicenseKey(key).then((data) => {
          try {
            var jsonParse = JSON.parse(data);
            if (jsonParse && jsonParse.code === -1) {
              self.alert.showAlertOnlyNoti(this.translate.instant('ContractCensorship_ts_23'), "");
            }
          } catch (e) {
            console.log(e);
            return;
          }
        });
        console.log("ok");
      }
    }).catch(function (e) {
      self.alert.showAlertOnlyNoti(this.translate.instant('ContractCensorship_ts_24'), "");
    });
  }

  //MARK: Action Search
  eventInputSearch(event) {
    let key = event.target.value || '';
    this.isfilter = true;
    this.listUsbToken = this.listUsbTokenBase.filter(x => {
      return x.subjectCN.toUpperCase().match(new RegExp(key.toUpperCase(), "g"))
    })
  }

  searchData(keySearch: string) {
    let key = keySearch;
    this.isfilter = true;
    this.listUsbToken = this.listUsbTokenBase.filter(x => {
      return x.subjectCN.toUpperCase().match(new RegExp(key.toUpperCase(), "g"))
    })
  }

  loadPage(page) {

  }

  // MARK: Chức năng xem thông tin chi tiết của usb token
  selectItem(item: UsbTokenItem) {
    this.usbTokenSelected = item;
    console.log(this.usbTokenSelected);
  }

  //MARK: Chức năng cập nhật thông tin usb token
  actUpdateUsbToken() {
    vnpt_plugin.CheckToken().then((data) => {
      console.log("check token ==> ", data);
      if (data === "1") {
        vnpt_plugin.getCertInfo(null, true).then((data) => {
          let dataJSON = JSON.parse(data);
          this.usbTokenCertResponse = new UsbTokenCertResponse(dataJSON);

          console.log(this.usbTokenCertResponse);

          let param = {
            userId: this.userId,
            serial: this.usbTokenCertResponse.object.serialNumber,
            subjectCN: this.usbTokenCertResponse.object.subject,
            issuerCN: this.usbTokenCertResponse.object.issuer,
            notBefore: DateUtils.convert(this.usbTokenCertResponse.object.validFrom.toString(), DateUtils.format.API),
            notAfter: DateUtils.convert(this.usbTokenCertResponse.object.validTo.toString(), DateUtils.format.API),
            taxNumber: this.usbTokenCertResponse.object.taxNumber,
            personalId: this.usbTokenCertResponse.object.personalId,
            passport: this.usbTokenCertResponse.object.passport
          };

          this.postUpdateUsbToken(param);
        })
      } else {
        this.alert.showAlert(this.translate.instant('UsbTokenManager_ts_1'), '', true, 'replay', this.translate.instant('UsbTokenManager_ts_2'), () => {
          this.actUpdateUsbToken()
        }, '', this.translate.instant('btn_cancel'), () => { });
      }
    });
  }

  //MARK: Thêm mới usb token
  addSignUsbToken() {
    // let param = {
    //   userId: this.userId,
    //   serial: this.uuidv4(),
    //   subjectCN: "Thanh thanh",
    //   issuerCN: "thanh thanh",
    //   notBefore: DateUtils.convertInputToFormat('20/12/2019', DateUtils.format.API),
    //   notAfter: DateUtils.convertInputToFormat('20/12/2020', DateUtils.format.API),
    //   taxNumber: '0120120123',
    //   personalId: '205123123',
    //   passport: 'BA123123'
    // };

    // console.log(param);
    // this.postUpdateUsbToken(param);

    // vnpt_plugin.CheckToken().then((data) => {
    //   console.log("check token ==> ", data);
    //   if (data === "1") {
        vnpt_plugin.getCertInfo(null, true).then((data) => {
          let dataJSON = JSON.parse(data);
          console.log(dataJSON);
          this.usbTokenCertResponse = new UsbTokenCertResponse(dataJSON);

          console.log(this.usbTokenCertResponse);

          let param = {
            userId: this.userId,
            serial: this.usbTokenCertResponse.object.serialNumber,
            subjectCN: this.usbTokenCertResponse.object.subject,
            issuerCN: this.usbTokenCertResponse.object.issuer,
            notBefore: DateUtils.convert(this.usbTokenCertResponse.object.validFrom.toString(), DateUtils.format.API),
            notAfter: DateUtils.convert(this.usbTokenCertResponse.object.validTo.toString(), DateUtils.format.API),
            taxNumber: this.usbTokenCertResponse.object.taxNumber,
            personalId: this.usbTokenCertResponse.object.personalId,
            passport: this.usbTokenCertResponse.object.passport
          };

          console.log(param);
          this.postAddUsbToken(param);
        })
    //   } else {
    //     this.alert("Bạn cần cắm USB-Token ký số để thực hiện chức năng này", '', true, 'replay', 'Thử lại', () => {
    //       this.addSignUsbToken()
    //     }, '', 'Hủy', () => { });
    //   }
    // });
  }

  //MARK: Xóa thông tin usb token
  actDeleteUsbToken(usbToken: UsbTokenItem) {
    this.alert.showAlert(this.translate.instant('UsbTokenManager_ts_3'), '', true, '', this.translate.instant('btn_confirm'), () => {
      this.putDeleteUsbToken(usbToken.serial);
    }, '', this.translate.instant('btn_cancel'), () => { });
  }

  actSettings() {
    this.isEditConfig = true;
  }

  handleCancelEdit(event) {
    this.isEditConfig = false;
  }

  handleUpdatedConfig(event) {
    this.isEditConfig = false;
  }

  // //MARK: Popup thông báo
  // alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
  //   const modalRef = this.modalService.open(AlertComponent);
  //   modalRef.componentInstance.message = msg;
  //   modalRef.componentInstance.typeAlert = type;
  //   modalRef.componentInstance.twoBtn = twoBtn;
  //   modalRef.componentInstance.iconBtn1 = iconBtn1;
  //   modalRef.componentInstance.iconBtn2 = iconBtn2;
  //   modalRef.componentInstance.titleButton1 = titleBtn1;
  //   modalRef.componentInstance.titleButton2 = titleBtn2;
  //   modalRef.componentInstance.callback1 = actBtn1;
  //   modalRef.componentInstance.callback2 = actBtn2;
  // }

  // //MARK: Handle Error
  // handleError(err, errorMsg) {
  //   if (err.error && err.error.message && errorMsg[err.error.message]) {
  //     this.alert(errorMsg[err.error.message], 'error');
  //   } else if (err.error && err.error.error) {
  //     this.alert(err.error.error, 'error');
  //   } else {
  //     this.alert('Lỗi không xác định!', 'error');
  //   }
  // }

  //MARK: NETWORKING
  getListUsbToken(id) {
    this.spinnerL.next(true);
    this.signatureService.getUsbTokenOfUser(id).subscribe(res => {
      this.spinnerL.next(false);
      this.spinnerLg.next(false);
      this.listUsbToken = new ListUsbTokenResponse(res).data;
      console.log(this.listUsbToken);
      this.listUsbTokenBase = this.listUsbToken;

      if (this.listUsbToken.length > 0 && this.usbTokenSelected.serial === '') {
        this.selectItem(this.listUsbToken[0]);
      }
    }, err => {
      this.spinnerL.next(false);
      this.spinnerLg.next(false);
      console.log(err);
      this.alert.showErrorHandled(err, ResponseMsg.MESSAGES.SIGN_SERVER);
    }, () => {
      this.spinnerL.next(false);
      this.spinnerLg.next(false);
    })
  }

  postAddUsbToken(param) {
    this.spinnerR.next(true);
    this.signatureService.postUpdateUsbToken(param).subscribe(res => {
      this.spinnerR.next(false);
      console.log(res);

      this.alert.showAlertOnlyNoti(this.translate.instant('UsbTokenManager_ts_4'), 'success');
      this.getListUsbToken(this.userId);
    }, err => {
      this.spinnerR.next(false);
      console.log(err);
      this.alert.showErrorHandled(err, ResponseMsg.MESSAGES.SIGN_SERVER);
    }, () => {
      this.spinnerR.next(false);
    });
  }

  postUpdateUsbToken(param) {
    this.spinnerR.next(true);
    this.signatureService.postUpdateUsbToken(param).subscribe(res => {
      this.spinnerR.next(false);
      console.log(res);

      this.alert.showAlertOnlyNoti(this.translate.instant('UsbTokenManager_ts_5'), 'success');
      this.getListUsbToken(this.userId);
    }, err => {
      this.spinnerR.next(false);
      console.log(err);
      this.alert.showErrorHandled(err, ResponseMsg.MESSAGES.SIGN_SERVER);
    }, () => {
      this.spinnerR.next(false);
    });
  }

  putDeleteUsbToken(id) {
    this.spinnerR.next(true);
    this.signatureService.putDeleteUsbToken(id).subscribe(res => {
      this.spinnerR.next(false);

      this.alert.showAlertOnlyNoti(this.translate.instant('UsbTokenManager_ts_6'), 'success');
      this.usbTokenSelected = new UsbTokenItem();
      this.getListUsbToken(this.userId);
    }, err => {
      this.spinnerR.next(false);
      console.log(err);
      this.alert.showErrorHandled(err, ResponseMsg.MESSAGES.SIGN_SERVER);
    }, () => {
      this.spinnerR.next(false);
    });
  }

  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }
}
