<app-spinner></app-spinner>
<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-auto">
                <h1 class="m-0 text-dark"><label>{{ 'DetailContractNumberConfig_title' | translate }}</label></h1>
            </div>
            <div class="col-md-auto ml-auto">
                <button *ngIf="configDefault == false && disable != true" class="btn btn-outline-primary d-inline-flex align-item-center mr-2" (click)="setDefault()">
                    <span class="material-icons mr-1">
                        star
                    </span>
                    {{ 'ContractNumberDetail_setDefault' | translate }}
                </button>
                <button class="btn btn-primary d-inline-flex align-item-center" (click)="directToEdit()">
                    <span class="material-icons mr-1">
                        edit
                    </span>{{ 'btn_edit' | translate }}</button>
            </div>
        </div>
    </div>
</div>

<section class="content">
    <div class="container-fluid">
        <div class="bg-white">
            <section class="p-3">

                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label for="timeGen">{{ 'ListContractNumberConfig_label_filter' | translate }} <span
                                    class="text-danger">*</span></label>
                            <ng-select [(ngModel)]="action" [items]="actionOptions" bindValue="value"
                                [searchable]="false" [clearable]="false" [disabled]="true">
                                <ng-template ng-label-tmp let-item="item">
                                    <span>{{ item.label | translate }}</span>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item">
                                    <span>{{ item.label | translate }}</span>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>{{ 'status' | translate }} <span class="text-danger">*</span></label>
                            <ng-select [(ngModel)]="disable" [items]="disableOptions" bindValue="value"
                                [searchable]="false" [clearable]="false" [readonly]="true">
                                <ng-template ng-label-tmp let-item="item">
                                    <span>{{ item.label | translate }}</span>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item">
                                    <span>{{ item.label | translate }}</span>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                </div>

                <div *ngIf="configDefault == true" class="row">
                    <div class="col-md-4">
                        <span class="font-italic text-black">{{ 'ContractNumberDetail_isDefault' | translate }}</span>
                    </div>
                </div>

            </section>

            <section class="p-3">
                <label class="text-primary">{{ 'AddContractNumberConfig_struct' | translate }}</label>
                <hr>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ 'AddContractNumberConfig_simulation' | translate }}</label>
                            <input class="form-control" [(ngModel)]="cncSimulator" type="text" disabled>
                        </div>
                    </div>
                </div>
                <div class="table-responsive enable-scroll">
                    <table class="table table-bordered mb-0">
                        <thead>
                            <tr class="bg-tb-header border-top text-left">
                                <th style="min-width: 30px; text-align: center;">{{ 'AddContractNumberConfig_table_header_1' | translate }}</th>
                                <th style="min-width: 230px;">{{ 'AddContractNumberConfig_table_header_2' | translate }}</th>
                                <th style="min-width: 220px;">{{ 'AddContractNumberConfig_table_header_3' | translate }}</th>
                                <th style="min-width: 450px;">{{ 'AddContractNumberConfig_table_header_4' | translate }}</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="sequenceLine && sequenceLine.length > 0">
                            <tr *ngFor="let item of sequenceLine; let i = index">
                                <td style="text-align: center;"><span *ngIf="item.sequence < 10">0</span>{{
                                    item.sequence }}</td>
                                <td>
                                    <div *ngIf="item.suffixType">
                                        <ng-select appendTo="body" [(ngModel)]="item.suffixType" [searchable]="false"
                                            [clearable]="false" [disabled]="true" [items]="ingredientOptions"
                                            bindValue="value">
                                            <ng-template ng-label-tmp let-item="item">
                                                <span>{{ item.label | translate }}</span>
                                            </ng-template>
                                            <ng-template ng-option-tmp let-item="item">
                                                <span>{{ item.label | translate }}</span>
                                            </ng-template>
                                        </ng-select>
                                    </div>
                                </td>
                                <td>
                                    <div *ngIf="i < sequenceLine.length - 1">
                                        <div *ngIf="sequenceLine[i + 1].prefixType">
                                            <ng-select appendTo="body" [(ngModel)]="sequenceLine[i + 1].prefixType"
                                                [searchable]="false" [clearable]="false" [disabled]="true"
                                                [items]="separatorOptions" bindValue="value">
                                                <ng-template ng-label-tmp let-item="item">
                                                    <span>{{ item.label | translate }}</span>
                                                </ng-template>
                                                <ng-template ng-option-tmp let-item="item">
                                                    <span>{{ item.label | translate }}</span>
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <!-- NUMBER -->
                                    <div *ngIf="item.suffixType == 'NUMBER'">
                                        <div class="td-container">
                                            <span *ngIf="item.padding">{{ 'AddContractNumberConfig_have' | translate }}</span>
                                            &nbsp;
                                            <div *ngIf="item.padding" style="max-width: 100px;">
                                                <ng-select style="min-width: 60px;" appendTo="body" [searchable]="false"
                                                    [clearable]="false" [(ngModel)]="item.padding" [disabled]="true">
                                                    <ng-option [value]="item.padding">{{ item.padding }}</ng-option>
                                                </ng-select>
                                            </div>
                                            &nbsp;
                                            <span *ngIf="item.padding">{{ 'DetailContractNumberConfig_number' | translate }}</span>
                                            <span *ngIf="item.startNumber">, {{ 'DetailContractNumberConfig_format' | translate }} </span>
                                            &nbsp;
                                            <div *ngIf="item.startNumber">
                                                <ng-select style="min-width: 150px;" [(ngModel)]="item.startNumber"
                                                    [searchable]="false" appendTo="body" [clearable]="false"
                                                    [disabled]="true">
                                                    <ng-option [value]="item.startNumber">{{ item.startNumber }}
                                                    </ng-option>
                                                </ng-select>
                                            </div>
                                        </div>
                                        <div *ngIf="item.resetType" class="td-container mt-2">
                                            <span>{{ 'AddContractNumberConfig_auto_reset' | translate }}</span>
                                            &nbsp;
                                            <div>
                                                <ng-select style="min-width: 150px;" appendTo="body" [disabled]="true"
                                                    [(ngModel)]="item.resetType" [searchable]="false"
                                                    [clearable]="false" [items]="resetTypeOptions" bindValue="value"
                                                    >
                                                    <ng-template ng-label-tmp let-item="item">
                                                        <span>{{ item.label | translate }}</span>
                                                    </ng-template>
                                                    <ng-template ng-option-tmp let-item="item">
                                                        <span>{{ item.label | translate }}</span>
                                                    </ng-template>
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- VARIABLE -->
                                    <div *ngIf="item.suffixType == 'VARIABLE'">
                                        <div *ngIf="item.suffix">
                                            <ng-select appendTo="body" [(ngModel)]="item.suffix"
                                                [items]="variableOptions" bindValue="value"
                                                [searchable]="false" [clearable]="false" [disabled]="true">
                                                <ng-template ng-label-tmp let-item="item">
                                                    <span>{{ item.label | translate }}</span>
                                                </ng-template>
                                                <ng-template ng-option-tmp let-item="item">
                                                    <span>{{ item.label | translate }}</span>
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <!-- STRING -->
                                    <div *ngIf="item.suffixType == 'STRING'">
                                        <input [(ngModel)]="item.suffix" class="form-control" type="text" disabled>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </div>
    </div>
</section>