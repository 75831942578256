<div class="content-header">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 col-sm-6">
        <h1 class="m-0 text-dark">
          <label for="">Danh sách tin bài</label>
        </h1>
      </div>
      <div class="col-12 col-sm-6">
        <a [routerLink]="['/console/landing-page/blog/add']" routerLinkActive="router-link-active"
          class="btn btn-primary float-sm-right d-inline-flex">
          <em class="material-icons">add</em>
          Thêm mới
        </a>
      </div>
    </div>
  </div>
</div>
<section class="content">
  <div class="container-fluid">
    <div class="bg-white">
      <section class="p-3">
        <div class="row">
          <div class="col-12 col-md-5">
            <form class="input-group border rounded mb-3" [formGroup]="searchForm" (ngSubmit)="onSearchFrmSubmit()">
              <div class="input-group-prepend border-0">
                <button class="btn d-inline-flex pr-2 pl-2" type="submit"><span
                    class="material-icons">search</span></button>
              </div>
              <input type="text" class="form-control border-0" placeholder="Tìm kiếm theo tiêu đề..."
                formControlName="search" aria-describedby="basic-addon1">
            </form>
          </div>
        </div>
        <hr class="my-2">
      </section>
      <section class="px-3 my-2">
        <form [formGroup]="filterForm" (ngSubmit)="onFilterSubmit()">
          <div class="row">
            <div class="col-12 col-md-5">
              <div class="form-row">
                <div class="form-group col-12 col-sm-6">
                  <label for="">Danh mục</label>
                  <select class="form-control" formControlName="category">
                    <option value="">Tất cả</option>
                    <option *ngFor="let item of categories" [value]="item.id">{{item.categoryName}}</option>
                  </select>
                </div>
                <div class="form-group col-12 col-sm-6">
                  <label for="">Loại tin</label>
                  <select class="form-control" formControlName="blogType">
                    <option value="-1">Tất cả</option>
                    <option *ngFor="let item of blogTypes" [value]="item.id">{{item.name}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-md-auto ml-auto align-self-end">
              <button class="btn btn-outline-primary mr-3" type="button" (click)="resetForm()"> Đặt lại</button>
              <button class="btn btn-primary mr-3" type="submit"> Áp dụng</button>
            </div>
          </div>
        </form>
      </section>
      <section>
        <div id="table-scroll" class="table table-scroll table-responsive">
          <table id="main-table" class="table main-table border table-striped">
            <thead class="bg-light">
              <tr>
                <th>TT</th>
                <th>Ngôn ngữ</th>
                <th>Tiêu đề</th>
                <th>Danh mục</th>
                <th>Loại tin</th>
                <th>Ngày tạo</th>
                <th>Ngày cập nhật</th>
                <th>Chức năng</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of blogItems; index as i">
                <td>{{i+1}}</td>
                <td>
                  {{item.language}}
                </td>
                <td>{{item.title}}</td>
                <td>{{item.categoryName}}</td>
                <td>{{item.blogTypeName}}</td>
                <td>{{item.createDate | date:'dd/MM/yyyy HH:mm'}}</td>
                <td>{{item.editDate | date:'dd/MM/yyyy HH:mm'}}</td>
                <td>
                  <div class="dropdown">
                    <button class="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown"
                      aria-haspopup="true" aria-expanded="false">
                      <em class="material-icons">more_vert</em>
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <a class="dropdown-item" [routerLink]="['/console/landing-page/blog/edit/',item.id]"
                        routerLinkActive="router-link-active">
                        <em class="material-icons">edit</em>
                        Chỉnh sửa
                      </a>
                      <a class="dropdown-item" (click)="onDeleteClick(item.id)">
                        <em class="material-icons-outlined">delete_outline</em>
                        Xóa
                      </a>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>
    <div class="d-flex justify-content-end mt-3">
      <span *ngIf="totalElement == 0" class="flex-grow-1 ml-1">Không tìm thấy kết quả</span>
      <span *ngIf="totalElement > 0" class="flex-grow-1 ml-1">Hiển thị 1 đến {{ blogItems.length }} trong tổng số
        {{ totalElement }} kết quả</span>
      <div *ngIf="totalElement > maxSize" class="pagination pages aas-pagination">
        <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="false"
          [pageSize]="maxSize" (pageChange)="getBlogs($event)" [boundaryLinks]="true" aria-label="Default pagination">
          <ng-template ngbPaginationFirst>Đầu</ng-template>
          <ng-template ngbPaginationLast>Cuối</ng-template>
          <ng-template ngbPaginationPrevious>&laquo;</ng-template>
          <ng-template ngbPaginationNext>&raquo;</ng-template>
          <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
      </div>
    </div>
  </div>
</section>