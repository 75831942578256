import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertBtn, ConstantAlertMsg } from 'src/app/utils/common/constant-alert-msg';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { REGEXP_PHONE_NUMBER } from 'src/app/utils/common/regexp';
import { ValidateMessage } from 'src/app/utils/common/validate-msg-aas';
import { localize } from 'src/app/utils/localize/localize';
import { PartService } from 'src/app/utils/services/aas-network/part/part.service';

@Component({
  selector: 'app-part-add',
  templateUrl: './part-add.component.html',
  styleUrls: ['./part-add.component.scss']
})
export class PartAddComponent implements OnInit {

  localize = localize;
  partForm: FormGroup;
  isSubmit = false;
  validatePartForm = {
    partyName: {
      required: "PartEdit_FORM_PART_NAME_REQUIRED"
    },
    phoneNumber: {
      pattern: "PartEdit_FORM_PART_SDT_PATTERN"
    }
  };

  constructor(
    private fb: FormBuilder,
    private partService: PartService,
    private router: Router,
    private myAlert: AlertControl,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.viewDidLoad();
  }

  viewDidLoad() {
    this.makePartForm();
  }

  makePartForm() {
    this.partForm = this.fb.group({
      partyName: ['', [Validators.required]],
      descr: [''],
      phoneNumber: ['', [Validators.pattern(REGEXP_PHONE_NUMBER)]],
      fax: ['']
    })
  }

  save() {
    this.isSubmit = true;

    if (this.partForm.valid) {
      this.myAlert.showAlert(this.translate.instant('PartAdd_PART_ADD_CONFIRM'), ConstantAlertType.BLANK, true, '', this.translate.instant('btn_confirm'), () => {
        this.postAddPart(this.partForm.value);
      }, '', this.translate.instant('btn_close'), null, this.translate.instant('btn_confirm'));
    }
  }

  cancel() {
    this.router.navigate(['/app/console/parts'])
  }
  reset(){
    this.isSubmit = false;
    this.partForm.reset();
    this.partForm.patchValue({
      partyName: '',
      descr: '',
      phoneNumber: '',
      fax: ''
    })
  }
  //MARK: NETWORK
  postAddPart(param) {
    this.partService.postAddPart(param).subscribe((res: any) => {
      this.myAlert.showAlert(this.translate.instant('PartAdd_PART_ADD_SUCCESS'), ConstantAlertType.SUCCESS,false, '', this.translate.instant('btn_confirm'), () => {
        this.router.navigate([ConstantUrl.PART_DETAIL, res?.object?.partyId])
      });
    }, err => {
      this.myAlert.showAlert(this.translate.instant('PartAdd_PART_ADD_FAILED'), ConstantAlertType.ERROR);
    })
  }
}
