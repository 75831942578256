// import * as crypto from 'crypto-browserify';
import { privateDecrypt, constants } from 'crypto-browserify';
import { createDecipheriv } from 'browserify-aes';
// import * as crypto from 'crypto';
import { Buffer } from 'buffer/';
// import { createDecipheriv } from 'crypto';

export class EncodeService {

    constructor() {
    }

    public static rsa = {
        decrypt: function (privateKey, plainText): any {
            var bufferSecrectKey = Buffer.from(plainText, "base64");

            let secretKey = privateDecrypt({ key: privateKey, padding: constants.RSA_PKCS1_PADDING }, bufferSecrectKey);
            // console.log(secretKey.toString('hex'));

            return secretKey;
        }
    }

    public static aes = {
        decrypt: function (ecryptData, key, iv) {
            const algorithm = 'aes-256-cbc';
            console.log(ecryptData, iv);

            var decipher = createDecipheriv(algorithm, key, iv);
            decipher.setAutoPadding(false);

            let decrypted = decipher.update(ecryptData); //, 
            // decrypted += decipher.final();

            // console.log(decrypted);
            return decrypted;
        }
    }
    
    /**
     * Convert base64 to arraybuffer
     * @param base64 
     */
    public static _base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    }
}