import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PackageServiceService } from 'src/app/utils/services/aas-network/package-service/package-service.service';
import { AccountManagerService } from "src/app/utils/services/aas-network/account-manager.service";


@Component({
  selector: 'app-assign-to-enduser',
  templateUrl: './assign-to-enduser.component.html',
  styleUrls: ['./assign-to-enduser.component.scss']
})
export class AssignToEnduserComponent implements OnInit {

  partyId = this.router.snapshot.paramMap.get("id");
  userType: string = this.router.snapshot.queryParamMap.get("userType");


  constructor(
    private router: ActivatedRoute,
    private route: Router,
    private packageService: PackageServiceService,
    private fb: FormBuilder,
    private accountManagerService: AccountManagerService,
  ) { }

  packageOptions: Array<any> = new Array();
  packageSpecified: any = null;
  userInfo: any = null;
  infraInfo: any = null;

  selectedPackage: number;

  infraForm: FormGroup;

  total_price: number = 0;

  ngOnInit(): void {
    this.makeInfraForm();
    this.getListPackage();
    this.getUserInfo();
    this.getInfraInfor();
  }

  // Make infraForm
  makeInfraForm() {
    this.infraForm = this.fb.group({
      contractNumber: new FormControl(0),
      SMS_OTP: new FormControl(0),
      EKYC: new FormControl(0),
      STORAGE_TIME: new FormControl(0),
      STORAGE_CAPACITY: new FormControl(0),
    });
  }

  // Get list of package
  getListPackage() {
    this.packageService.getListPackage().subscribe((res: any) => {
      const data = res?.object?.data;
      this.packageOptions = [];
      data.forEach(content => {
        const value = {
          id: content.packInfoId || "",
          label: content.name || "",
        };
        this.packageOptions.push(value);
      });
      
      if (this.packageOptions && this.packageOptions.length > 0) {
        this.selectedPackage = this.packageOptions[0].id;

        this.packageOptions.forEach((content) => {
          this.packageService.getDetailPackage(content.id).subscribe((res: any) => {
            content.info = res?.object || {};
            if (content.info.listPackageInfrastructure.infrastructures
              && content.info.listPackageInfrastructure.infrastructures.length > 0) {
              content.info.listPackageInfrastructure.infrastructures.sort((a, b) => (a.stt > b.stt ? 1 : -1));
              content.info.listPackageInfrastructure.infrastructures.forEach((content, index) => {
                this.priceCal(index);
              });
              this.totalPrice();
            }
          });
        });
        this.changePackage();

      }
    })
  }

  // Get information of user (both consumer and business).
  getUserInfo() {
    if (this.userType == 'individual') {
      this.accountManagerService.getPersonalById(this.partyId).subscribe((res: any) => {
        const object = res?.object || {};
        this.userInfo = {
          name: object?.ten || "",
          taxCode: "",
          email: object?.email || "",
          phoneNumber: object?.sdt || "",
          province: object?.tinh || "",
          account: object?.username || ""
        };
      })
    }
    else if (this.userType == 'organization') {
      this.accountManagerService.getOrganizationById(this.partyId).subscribe((res: any) => {
        const object = res?.object || {};
        this.userInfo = {
          name: object?.tenToChuc || "",
          taxCode: object?.maSoThue || "",
          email: object?.email || "",
          phoneNumber: object?.sdt || "",
          province: object?.diaChiTruSoTinh || "",
          account: object?.userInformations?.username || ""
        };
      })

    }
  }

  // Get infor of infra is using.
  getInfraInfor() {
    this.packageService.getInfraInfor(this.partyId).subscribe((res: any) => {
      this.infraInfo = res?.object || {};
      console.log(this.infraInfo);
    });
  }

  // Assign package to enduser
  assignPackage() {
    if (this.packageSpecified.info.listPackageInfrastructure.infrastructures
      && this.packageSpecified.info.listPackageInfrastructure.infrastructures.length > 0) {
      const infrastructures = [
        {
          "param": this.packageSpecified.info.listPackageInfrastructure.infrastructures[0].param,
          "unitNumber": this.packageSpecified.info.listPackageInfrastructure.infrastructures[0].unitNumber
        },
        {
          "param": this.packageSpecified.info.listPackageInfrastructure.infrastructures[1].param,
          "unitNumber": this.packageSpecified.info.listPackageInfrastructure.infrastructures[1].unitNumber
        },
        {
          "param": this.packageSpecified.info.listPackageInfrastructure.infrastructures[2].param,
          "unitNumber": this.packageSpecified.info.listPackageInfrastructure.infrastructures[2].unitNumber
        },
        {
          "param": this.packageSpecified.info.listPackageInfrastructure.infrastructures[3].param,
          "unitNumber": this.packageSpecified.info.listPackageInfrastructure.infrastructures[3].unitNumber
        },
        {
          "param": this.packageSpecified.info.listPackageInfrastructure.infrastructures[4].param,
          "unitNumber": this.packageSpecified.info.listPackageInfrastructure.infrastructures[4].unitNumber
        }
      ];
      this.packageService.assignPackage(this.partyId, this.packageSpecified.id, this.total_price, infrastructures).subscribe((res: any) => {
        if (this.userType == 'individual') {
          this.route.navigate([`/console/account-manager/consumer/${this.partyId}`]);
        }
        else if (this.userType == 'organization') {
          this.route.navigate([`/console/account-manager/business/${this.partyId}`]);
        }
      });
    }

  }

  // Get style of rate
  getStyleAndRate(used: number, total: number) {
    let rate: number = 0;
    if (total != 0 && total >= used) {
      rate = Math.round((used / total) * 100);
    }
    if (rate < 50) {
      return [rate, 'success'];
    }
    else if (rate >= 50 && rate <= 75) {
      return [rate, 'warning'];
    }
    else if (rate > 75) {
      return [rate, 'danger'];
    }
  }

  // Format price (ex: 1,000,000)
  formatPrice(amount) {
    const number: Number = new Number(amount);
    const amountStr: string = number.toString();
    let amountArr: Array<string> = amountStr.split('');
    let count: number = 0;
    for (let i = amountArr.length - 1; i >= 0; i--) {
      count++;
      if (count == 3 && i > 0) {
        amountArr.splice(i, 0, ',');
        count = 0;
      }
    }
    const res: string = amountArr.join("");
    return res;
  }

  // Function to calculating price.
  priceCal(index: number) {
    if (this.packageSpecified.info.listPackageInfrastructure.infrastructures[index]!.param == "STORAGE_TIME"
      || this.packageSpecified.info.listPackageInfrastructure.infrastructures[index]!.param == "STORAGE_CAPACITY") {
      let storage_time = this.packageSpecified.info.listPackageInfrastructure.infrastructures.find((item) => {
        return item.param === "STORAGE_TIME";
      });
      let storage_capacity = this.packageSpecified.info.listPackageInfrastructure.infrastructures.find((item) => {
        return item.param === "STORAGE_CAPACITY";
      });
      let price = 0;
      if (!isNaN(parseInt(storage_time.unitNumber)) && !isNaN(parseInt(storage_capacity.unitNumber)) && !isNaN(parseInt(storage_capacity.unitPrice))) {
        price = Number(storage_time.unitNumber) * Number(storage_capacity.unitNumber) * Number(storage_capacity.unitPrice)
      }
      storage_capacity.price = price;
    }
    else {
      if (!isNaN(parseInt(this.packageSpecified.info.listPackageInfrastructure.infrastructures[index].unitNumber)) && !isNaN(parseInt(this.packageSpecified.info.listPackageInfrastructure.infrastructures[index].unitPrice))) {
        this.packageSpecified.info.listPackageInfrastructure.infrastructures[index].price =
          Number(this.packageSpecified.info.listPackageInfrastructure.infrastructures[index].unitNumber) * Number(this.packageSpecified.info.listPackageInfrastructure.infrastructures[index].unitPrice);
      } else {
        this.packageSpecified.info.listPackageInfrastructure.infrastructures[index].price = 0;
      }
    }
  }

  // Function for calculating total price.
  totalPrice() {
    this.total_price = 0;
    this.packageSpecified.info.listPackageInfrastructure.infrastructures.forEach(content => {
      this.total_price += Number(content.price);
    });
  }

  // Action when change package on droplist.
  changePackage() {
    const packageIndex = this.packageOptions.findIndex(res => {
      return res.id === this.selectedPackage;
    });
    this.packageSpecified = this.packageOptions[packageIndex];
    this.packageSpecified.info!.listPackageInfrastructure!.infrastructures.forEach((content, index) => {
      this.priceCal(index);
    });
    this.totalPrice();
    // this.infraForm = this.fb.group({
    //   contractNumber: new FormControl(this.packageSpecified.info.listPackageInfrastructure.infrastructures[0].unitNumber),
    //   SMS_OTP: new FormControl(this.packageSpecified?.info?.listPackageInfrastructure?.infrastructures[1]?.unitNumber),
    //   EKYC: new FormControl(this.packageSpecified?.info?.listPackageInfrastructure?.infrastructures[2]?.unitNumber),
    //   STORAGE_TIME: new FormControl(this.packageSpecified?.info?.listPackageInfrastructure?.infrastructures[3]?.unitNumber),
    //   STORAGE_CAPACITY: new FormControl(this.packageSpecified?.info?.listPackageInfrastructure?.infrastructures[4]?.unitNumber),
    // });
  }


}
