import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, Input, ChangeDetectorRef, AfterViewChecked, AfterViewInit } from '@angular/core';
import { Subject } from 'rxjs';
import { LoadingService } from 'src/app/utils/services/loading.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  animations: [
    trigger(
      'inOutAnimation', 
      [
        state(
          'visible',
          style({
            opacity: '1'
          })
        ),
        state(
          'hidden',
          style({
            opacity: '0'
          })
        ),
        transition('* => visible', [animate('1000ms ease-out')]),
        transition('visible => hidden', [animate('1000ms ease-out')])
      ]
    )
  ]
})
export class SpinnerComponent implements OnInit, AfterViewChecked, AfterViewInit {
  @Input('message') message: string;

  constructor(public loadingService: LoadingService, private changeDetectorRef: ChangeDetectorRef,){}

  ngOnInit(): void {
  }

  ngAfterViewChecked() {
    // this.changeDetectorRef.detectChanges()
  }

  ngAfterViewInit() {
    // this.changeDetectorRef.detectChanges();
  }
}
