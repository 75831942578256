import { EnterpriseVipService } from 'src/app/utils/services/aas-network/enterprise-vip.service';
import { ListSignatureResponse } from './../../../../../../utils/models/aas-models/contract/list-signature/list-signature-response.model';
import { ListSignatureObject } from './../../../../../../utils/models/aas-models/contract/list-signature/list-signature-object.model';
import { SignatureService } from 'src/app/utils/services/aas-network/signature.service';
import { HttpResponse } from '@angular/common/http';
import { Component, OnInit, ElementRef, ViewChild, Input, EventEmitter, Output, ChangeDetectorRef, AfterViewChecked, AfterViewInit } from '@angular/core';
import { Options, Select2AjaxOptions } from 'select2';
import { Select2OptionData } from 'ng-select2';
import { FormGroup, FormBuilder, FormControl, Validators, Form, NgModel } from '@angular/forms';
import { ValidateMessage, AasValidateMessage } from 'src/app/utils/common/validate-msg-aas';
import { AasContractService } from 'src/app/utils/services/aas-network/aas-contract.service';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { DateUtils } from 'src/app/utils/common/DateUtils';
import { TemplateService } from 'src/app/utils/services/aas-network/template.service';
import { LoadingService } from 'src/app/utils/services/loading.service';
import { Config } from 'src/app/config';
import { AuthService } from 'src/app/utils/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location, DatePipe } from '@angular/common';
import { ContractObject } from 'src/app/utils/models/aas-models/contract/contract/contract-object.model';
import { ContractResponse } from 'src/app/utils/models/aas-models/contract/contract/contract-response.model';
import { debug } from 'console';
import { InfoSignatureConfigResponse } from 'src/app/utils/models/aas-models/signature/signature-config/info-signature-config-response';
import { InfoSignatureConfigObject } from 'src/app/utils/models/aas-models/signature/signature-config/info-signature-config-object';
import { InfoCASignServerResponse } from 'src/app/utils/models/aas-models/signature/info-ca-signserver/info-ca-signserver-response';
import { ContractStatus } from 'src/app/utils/common/constant';
import { DigitalSignatureResponse } from 'src/app/utils/models/aas-models/signature/digital-signature/digital-signature-response';
import { DigitalSignatureItem } from 'src/app/utils/models/aas-models/signature/digital-signature/digital-signature-item';
import { UserInformationsResponse } from 'src/app/utils/models/profile/user-info-response.model';
import { UserInformationsItem } from 'src/app/utils/models/profile/user-info-item.model';

declare var PDFLoader: any;
declare var PDFSign: any;
declare var $: any;
declare var Cleave: any;
declare var vnpt_plugin: any;
declare var PdfSigner: any;
@Component({
  selector: 'app-detail-contract-approve',
  templateUrl: './detail-contract-approve.component.html',
  styleUrls: ['./detail-contract-approve.component.scss'],
  providers: [DatePipe]
})
export class DetailContractApproveComponent implements OnInit, AfterViewInit {
  @ViewChild('output') output: ElementRef;
  @ViewChild('filename') filename: ElementRef;
  @ViewChild('filebase') filebase: ElementRef;
  @ViewChild('contentConfirmOTP') contentConfirmOTP: ElementRef;

  @Input('contractId') contractId: string = '';
  @Input('userId') userId: string = '';
  @Output('close') close: EventEmitter<any> = new EventEmitter<any>();

  public exampleData: Array<Select2OptionData> = [];
  public nguoiNhanData: Array<Select2OptionData> = [];
  public hinhThucKyData: Array<Select2OptionData> = [];
  public options: Options;
  public optionsTemplate: Options;
  public optionsHinhThucKy: Options;
  public ajaxOptions: Select2AjaxOptions;

  // action: string = this.activeRoute.snapshot.queryParamMap.get('action') || '';
  // contractId: string = this.activeRoute.snapshot.paramMap.get('id') || '';
  contract: ContractObject = new ContractObject();

  //mark: user
  userInfo: UserInformationsItem = new UserInformationsItem();
  userType: string = '';
  hinhThucKy_consumer = [
    { id: 'OTP', text: 'OTP' },
    // { id: 'ONE_TIME_CA', text: 'Ký số 1 lần' },
    // { id: 'EKYC', text: 'OTP và eKYC' }
  ];

  hinhThucKy_business = [
    { id: 'USB_TOKEN', text: 'Usb-Token' },
    { id: 'SIGN_SERVER', text: 'VNPT ký số' }
  ]

  //mark: nhóm chức năng khởi tạo thông tin hợp đồng
  filePDF: File = null;
  inputFileLabel: string = 'Chọn file';
  fileType: Array<string> = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
  selectedFile: boolean = false;
  fileName: string = '';
  typeSelected: number = 1;

  formContract: FormGroup;
  validContractMsg: any = AasValidateMessage.FORM_CREATE_CONTRACT;
  isSubmitContract: boolean = false;

  currentStage = ContractStatus;

  //mark: nhóm chức năng ký usb-token
  timeCheck: any;

  //mark: nhóm chức năng ký hợp đồng bằng sign server
  formSign: FormGroup;
  isSubmit: boolean = false;
  validMsg: any = AasValidateMessage.SIGNATURE_CONFIG;
  inputFileImageLabel: string = 'Chọn file';
  img_prefix = "data:image/png;base64,";
  imgContent: string = '';
  imgSignServer: File = null;
  imgType = ['image/jpeg', 'image/png', 'image/jpg'];
  pdfSigned: Blob = null;
  keyTest: string = Config.KEY_PLUGIN_CA;
  //mark: phương thức ký
  selectedWayOfSign: string = '';
  //Config CA
  currentSignBoxStyle: number = 5;
  configCA: InfoSignatureConfigObject = new InfoSignatureConfigObject();


  //mark: nhóm chức năng ký điện tử
  formDSign: FormGroup;
  isESignSubmit: boolean = false;
  validMsgESign = AasValidateMessage.E_SIGNATURE_CONFIG;
  imgESignContent: string = '';
  inputImgESignLabel: string = 'Chọn file';
  imgESign: File = null;
  //cert ký điện tử đã chọn
  eCertSelected: DigitalSignatureItem = new DigitalSignatureItem();
  //form confirm top e-sign
  formOTP: FormGroup;
  isOTPSubmit: Boolean = false;
  validMsgOTP = AasValidateMessage.CONFIRM_OTP_E_SIGN;
  idSign: string = '';

  //Chi tiet chu ky
  listSignature: ListSignatureObject = new ListSignatureObject();

  hinhThucHienThi = [
    { value: 0, name: "Không hiển thị" },
    { value: 1, name: "Chỉ hiển thị mô tả" },
    { value: 2, name: "Hiển thị mô tả và hình ảnh bên trái" },
    { value: 3, name: "Chỉ hiển thị hình ảnh" },
    { value: 4, name: "Hiển thị mô tả và hình ảnh phía trên" },
    { value: 5, name: "Hiển thị mô tả và hình ảnh dạng hình nền" }
  ]

  //Tab controll
  activeTab: string = 'INFO';

  constructor(
    private _location: Location,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private fb: FormBuilder,
    private contractService: AasContractService,
    private authService: AuthService,
    private signatureService: SignatureService,
    private enterpriseVipService: EnterpriseVipService,
    private configModal: NgbModalConfig,
    private modalService: NgbModal,
    private loaderSerive: LoadingService,
    private datePipe: DatePipe
  ) {
    this.timeCheck = this.datePipe.transform(new Date(), "yyyy-MM-dd'T'HH:mm:ss");
  }

  ngOnInit(): void {
    PDFSign.removeAllSignBox();

    this.makeFormSign();
    this.makeFormDSign();
    this.makeConfirmOTPForm();

    this.checkPlugin();
    this.imgContent = this.img_prefix + PDFSign.getData().imageSrc; 
  }

  ngAfterViewInit() {
    this.getUserInfo(this.authService.userId);
    this.getContract(this.contractId);
  }

  //MARK: KHỞI TẠO FORM KÝ SIGN SERVER
  makeFormSign() {
    this.formSign = this.fb.group({
      signerName: new FormControl('',),
      textColor: new FormControl('',),
      visibleType: new FormControl(5),
      base64Image: new FormControl(''),
      fontSize: new FormControl(13, [Validators.min(8), Validators.max(20), Validators.pattern(/^[0-9]+$/)]),
      signBy: new FormControl(true),
      signDate: new FormControl(true),
      imageFile: new FormControl(''),
    })
  }

  get signerName() { return this.formSign.get('signerName'); }
  get textColor() { return this.formSign.get('textColor'); }
  get visibleType() { return this.formSign.get('visibleType'); }
  get base64Image() { return this.formSign.get('base64Image'); }
  get fontSize() { return this.formSign.get('fontSize'); }
  get signBy() { return this.formSign.get('signBy'); }
  get signDate() { return this.formSign.get('signDate'); }
  get imageFile() { return this.formSign.get('imageFile'); }

  //MARK: KHỞI TẠO FORM KÝ ĐIỆN TỬ
  makeFormDSign() {
    this.formDSign = this.fb.group({
      imageFile: new FormControl(''),
    })
  }

  get imageFileESign() { return this.formDSign.get('imageFile'); }

  //MARK: KHỞI TẠO FORM OTP
  makeConfirmOTPForm() {
    this.formOTP = this.fb.group({
      otp: new FormControl('', [Validators.required]),
      documentHash: new FormControl('')
    });
  }

  get otp() { return this.formOTP.get('otp'); }

  //MARK: CHỨC NĂNG ĐIỀU KHIỂN TAB
  //Action chọn tab
  selectTab(nameTab: string) {
    this.activeTab = nameTab;

    // if (nameTab == 'SIGN') {
    //   this.selectWayOfSign('SIGN_SERVER');
    // }
  }

  //MARK: THAY ĐỔI GIÁ TRỊ DROPLIST
  changedHinhThucKy(event: Array<any>) {
  }

  //Lấy và hiển thị trạng thái hợp đồng
  get getStatusContract() {
    if (this.currentStage[this.contract.currentStage] !== undefined && this.currentStage[this.contract.currentStage] !== null) {
      return `<span class="badge ${this.currentStage[this.contract.currentStage].style || ''} p-2">${this.currentStage[this.contract.currentStage].label || ''}</span>`
    } else {
      return '';
    }
  }

  //MARK: CHỨC NĂNG KÝ SỐ SIGN SERVER
  //Chọn phương thức ký
  selectWayOfSign(value: string) {
    this.selectedWayOfSign = value;
    if (value === 'USB_TOKEN') {
      this.sign();
    } else if (value === 'SIGN_SERVER') {
      if (this.configCA.certId === '') {
        this.getInfoCASignServer(this.authService.userId);
      }

      PDFSign.setStyleSelectedSignBox(this.currentSignBoxStyle);
      if (this.imgContent == '') {
        PDFSign.setImage(this.imgContent.split(',')[1]);
      } else {
        PDFSign.setImageDefault();
      }
    } else if (value === 'E_SIGN') {
      if (this.eCertSelected.id === '') {
        this.getListESignature();
      }

      PDFSign.setStyleSelectedSignBox(3);
      if (this.imgESignContent !== '') {
        PDFSign.setImage(this.imgESignContent.split(',')[1]);
      } else {
        PDFSign.setImageDefault();
      }
    }
  }

  //Set dữ liệu config mặc định
  setConfigSign(signConfig: InfoSignatureConfigObject) {
    this.signerName.setValue(signConfig.signerName);

    this.visibleType.setValue(signConfig.visibleType);
    this.currentSignBoxStyle = Number(this.visibleType.value);
    PDFSign.setStyleSelectedSignBox(Number(this.visibleType.value));

    this.base64Image.setValue(signConfig.base64Image);
    PDFSign.setImage(this.base64Image.value);
    this.imgContent = this.img_prefix + signConfig.base64Image;

    this.fontSize.setValue(signConfig.fontSize);
    PDFSign.setFontSize(this.fontSize.value)

    this.signBy.setValue(signConfig.signBy);
    PDFSign.setShowSignBy(this.signBy.value);

    this.signDate.setValue(signConfig.signDate);
    PDFSign.setShowDateSign(this.signDate.value);

    console.log(this.formSign.value);
  }

  //Upload ảnh chữ ký
  handleFileImageInput(e) {
    this.imgSignServer = e.target.files && e.target.files.item(0);
    if (this.imgSignServer && this.imgSignServer !== null && this.imgSignServer !== undefined) {
      this.inputFileImageLabel = this.imgSignServer.name;

      if (!this.imgType.includes(this.imgSignServer.type)) {
        this.imageFile.setErrors({ type: true });
        this.imgContent = '';
      } else if (this.imgSignServer.size > 2000000) {
        this.imageFile.setErrors({ size: true });
        this.getBase64String(this.imgSignServer, this.handleImgSignServer);
      } else {
        this.getBase64String(this.imgSignServer, this.handleImgSignServer);

        var self = this;
        var u = URL.createObjectURL(this.imgSignServer);
        var img = new Image;
        img.src = u;
        img.onload = function () {
          self.resizeImage(img, self.imgSignServer, 100, 100, '');
        }
      }

    } else {
      // this.imageFile.setErrors({ required: true });
      this.imgContent = '';
      this.inputFileImageLabel = 'Chọn hình ảnh';
    }
  }

  //thêm chữ ký
  addSignBox() {
    PDFSign.addSignBox();
  }

  //Nhập font size chữ ký
  inputFontSize(event) {
    PDFSign.setFontSize(event.target.value)
  }

  //Check box ngày ký
  changedDateSign(event) {
    PDFSign.setShowDateSign(this.signDate.value);
  }

  //Check box ký bởi
  changedSignBy(event) {
    PDFSign.setShowSignBy(this.signBy.value);
  }

  //Chọn hình thức hiển thị chữ ký
  selectStyleSignBox(e) {
    this.currentSignBoxStyle = Number(e.target.value);
    PDFSign.setStyleSelectedSignBox(Number(e.target.value));
  }

  //Hoàn thanh ký số sign server
  finishSignServer() {
    let options: Array<any> = PDFSign.getOptions();
    let listPosition: Array<any> = [];

    if (!this.checkAllowSign()) {
      this.alert('Bạn không thể ký hợp đồng này!', 'error', false);
      return;
    }

    if (options.length <= 0) {
      this.alert('Bạn cần thêm ít nhất 1 chữ ký!', 'error', false);
      return;
    }

    for (var i = 0; i < options.length; i++) {
      let x = options[i].x;
      let y = options[i].y - options[i].h;
      let w = options[i].w + x;
      let h = options[i].h + y;
      let page = options[i].page;

      listPosition.push({
        page: page,
        rectangle: `${x},${y},${w},${h}`
      })
    }

    console.log(this.formSign.value);
    let param =
    {
      "userId": this.authService.userId,
      "base64file": this.filebase.nativeElement.value,
      "listPosition": listPosition,
      "signerBy": this.signBy.value,
      "signerDate": this.signDate.value,
      "fontSize": Number(this.fontSize.value),
      "visibleType": Number(this.visibleType.value),
      "base64Image": this.base64Image.value
    }
    this.postSignServer(param);
  }

  //MARK: CHỨC NĂNG KÝ SỐ USB TOKEN
  //Check plugin ký usb token
  checkPlugin() {
    const key = this.keyTest;
    vnpt_plugin.checkPlugin().then(function (data) {
      if (data === "1") {
        vnpt_plugin.setLicenseKey(key);
        console.log("ok");
      }
    }).catch(function (e) {
      console.log(e);
      //alert("VNPT-CA Plugin not running! Turn on plugin or install!");
    });
  }

  //Action ký usb token
  sign() {
    this.loaderSerive.show();
    // if (!this.isSigned) {

    // }
    this.output.nativeElement.value = "";
    var dataInput = this.filebase.nativeElement.value;
    if (dataInput.length === 0) {
      this.loaderSerive.hide();
      this.alert('Không tìm thấy file cần kí!', 'error');
      return;
    }
    var fileName = this.filename.nativeElement.value;
    console.log(fileName);
    var fileSplit = fileName.split(".");
    var fileExtension = fileSplit[fileSplit.length - 1].toLowerCase();
    if (fileExtension !== "xml" && fileExtension !== "pdf" && fileExtension !== "docx"
      && fileExtension !== "xlsx" && fileExtension !== "pptx" && fileExtension !== "txt") {
      this.loaderSerive.hide();
      this.alert('Định dạng file không được hỗ trợ kí số!', 'error');
      return;
    }
    var sigOptions = null;
    if (fileExtension === "pdf") {
      sigOptions = new PdfSigner();
      sigOptions.AdvancedCustom = true;
    }
    this.SignAdvanced(dataInput, fileExtension, sigOptions, this.showMessage);
  }

  //Bắt đầu kí
  SignAdvanced(data, type, option, callback) {
    var dataJS = {};
    var arrData = [];
    // 1			
    dataJS["data"] = data;
    dataJS["type"] = type;
    dataJS["sigOptions"] = JSON.stringify(option);

    var jsData = "";
    jsData += JSON.stringify(dataJS);
    //
    arrData.push(jsData);

    var vm = this;
    vnpt_plugin.CheckToken().then((data) => {
      console.log("check token ==> ", data);
      if (data === "1") {
        vnpt_plugin.getCertInfo(null, true).then((data) => {
          // var serial = data["serial"];
          // vnpt_plugin.signArrDataAdvanced(arrData, serial, true).then((data) => {
          //   vm.showMessage(data);
          // }).catch(function (e) {
          //   alert(e);
          //   this.loaderSerive.hide();
          // });
          try {
            var certData = JSON.parse(data);
          } catch (error) {
            var certData = null
          }
          console.log(certData)
          if (certData) {
            var serial = certData["serial"];
            vnpt_plugin.CheckOCSP(serial, vm.timeCheck, null, null).then((result) => {
              console.log(result)
              console.log(serial, "    ", vm.timeCheck);
              
              if (result === "0") {
                vnpt_plugin.signArrDataAdvanced(arrData, serial, true).then((signData) => {
                  vm.showMessage(signData);
                }).catch(function (e) {
                  alert(e);
                  this.loaderSerive.hide();
                });
              } else {
                vm.showMessage(result);
              }
            });
          } else {
            this.loaderSerive.hide();
          }
        })
      } else {
        this.loaderSerive.hide();
        this.alert("Bạn cần cắm token trước khi ký số.", 'error');
      }
    });
  }

  //Show message response
  showMessage(data) {
    this.loaderSerive.hide();
    var jsOb = JSON.parse(JSON.parse(data)[0]);
    var err = '';
    switch (jsOb.code) {
      case 0:
        this.pdfSigned = this.converBase64toBlob(jsOb.data, 'application/pdf');

        err = "Ký tài liệu thành công!";
        break;
      case 1:
        err = "Data input format incorrect";
        break;
      case 2:
        err = "Không thể lấy thông tin chứng thư số!";
        break;
      case 3:
        err = "Ký thất bại! Đã xảy ra lỗi trong quá trình ký!";
        break;
      case 4:
        err = "Not found private key";
        break;
      case 5:
        err = "Unknown error";
        break;
      case 6:
        err = "Pdf signing: not found page number parameter";
        break;
      case 7:
        err = "Pdf signing: page number illegal";
        break;
      case 8:
        err = "Xml signing: not found signing tag";
        break;
      case 9:
        err = "Xml signing: not found if of signing tag";
        break;
      case 10:
        err = "Data contain one or more signatures invalid";
        break;
      case 11:
        err = "Bạn đã hủy phiên ký!";
        break;
      case 13:
        err = "Data null";
        break;
      default:
        err = "Unknown error";
        break;
    }
    if (jsOb.code !== 0) {
      this.output.nativeElement.value = err;
      this.alert(err, 'error');
    } else {
      this.alert(err, 'success', false, '', 'OK', () => {
        //Reupload 
        PDFSign.preview(this.pdfSigned);
      });
    }
  }

  //MARK: CHỨC NĂNG KÝ ĐIỆN TỬ
  //handle file hình chữ ký điện tử
  handleFileImgESignInput(e) {
    this.imgESign = e.target.files && e.target.files.item(0);
    if (this.imgESign && this.imgESign !== null && this.imgESign !== undefined) {
      this.inputImgESignLabel = this.imgESign.name;

      if (!this.imgType.includes(this.imgESign.type)) {
        this.imageFileESign.setErrors({ type: true });
        this.imgESignContent = '';
      } else if (this.imgESign.size > 2000000) {
        this.imageFileESign.setErrors({ size: true });
        this.getBase64String(this.imgESign, this.handelimgESign);
      } else {
        this.getBase64String(this.imgESign, this.handelimgESign);

        var self = this;
        var u = URL.createObjectURL(this.imgESign);
        var img = new Image;
        img.src = u;
        img.onload = function () {
          self.resizeImage(img, self.imgESign, 500, 500, 'DSIGN');
        }

        let data = new FormData();
        data.append('image', this.imgESign);
        this.postDigitalSignature(data);
      }
    } else {
      this.imageFileESign.setErrors({ required: true });
      this.imgESignContent = '';
      this.inputImgESignLabel = 'Chọn hình ảnh';
    }
    // this._inputFile.nativeElement.value = '';
  }

  handelimgESign(str, self) {
    self.imgESignContent = str;
    PDFSign.setImage(str.split(',')[1]);
  }

  //lấy thông tin cấu hình chữ ký điện tử
  selectCert(item: DigitalSignatureItem) {
    this.eCertSelected = item;
    this.imgESignContent = 'data:image/jpg;base64,' + item.base64Image;

    let blob: any = this.converBase64toBlob(item.base64Image, 'image/jpg');
    blob.lastModifiedDate = new Date();
    blob.name = 'e_sign';
    this.imgESign = <File>blob;

    PDFSign.setImage(item.base64Image);
  }

  //Gửi yêu cầu ký điện tử
  sendRequestElectronicSign() {
    this.isOTPSubmit = false;
    let options: Array<any> = PDFSign.getOptions();
    let listPosition: Array<any> = [];

    if (!this.checkAllowSign()) {
      this.alert('Bạn không thể ký hợp đồng này!', 'error', false);
      return;
    }

    if (options.length <= 0) {
      this.alert('Bạn cần thêm ít nhất 1 chữ ký!', 'error', false);
      return;
    }

    this.postRequestElectronicSign(this.contractId);
  }

  //Show popup confirm OTP
  openModalConfirmOTP(content) {
    this.otp.setValue('');
    this.modalService.open(content, {
      size: 'md',
      backdrop: 'static',
      keyboard: false
    });
  }

  //Submit opt
  submitOTP() {
    this.isOTPSubmit = true;
    if (this.idSign === '') {
      this.alert('Không tìm thấy phiên yêu cầu ký! Vui lòng gửi yêu cầu mới!', 'error');
      return;
    }
    if (this.formOTP.valid) {
      const param = {
        otp: this.otp.value,
        documentHash: ''
      }

      this.postConfirmOTPESign(this.idSign, param);
    }
  }

  //Ký điện tử
  actESign() {
    let options: Array<any> = PDFSign.getOptions();
    let multiSign: Array<any> = [];
    let data: FormData = new FormData();

    for (var i = 0; i < options.length; i++) {
      let x = options[i].x + options[i].w;
      let y = options[i].y;
      let x2 = options[i].x;
      let y2 = options[i].y - options[i].h;
      let page = options[i].page;

      multiSign.push({
        pageSign: page - 1,
        bboxSign: [x2, y2, x, y]
      })
    }

    data.append('signImg1', this.imgESign);
    data.append('multiSign', JSON.stringify(multiSign));
    data.append('documentHash', "");

    this.postESign(this.idSign, data);

  }

  //MARK: CHỨC NĂNG GỬI HỢP ĐỒNG
  //Gửi hợp đồng ký usbtoken
  sendContract() {
    if (this.pdfSigned !== null && this.pdfSigned !== undefined) {
      let data = new FormData();
      data.append('file', this.pdfSigned);
      this.postSendContract(this.contractId, data);
    } else {
      this.alert('Không tìm thấy file hợp đồng đã ký!', 'error', false);
    }
  }

  //Tải xuống file hợp đồng
  saveContract() {
    if (this.contract.currentStage === 'LC_CONTRACT_VALID') {
      this.saveFileContract(this.contractId, 'CONTRACT');
    } else {
      this.saveFileContract(this.contractId, 'DRAFT');
    }
  }

  //MARK: NHÓM CHỨC NĂNG CHECK QUYỀN THAO TÁC VÀ ĐIỀU HƯỚNG
  //Kiểm tra quyền gửi hợp đồng
  get allowSendContract() {
    if (this.contract.currentStage === 'LC_CONTRACT_VALID') return false;
    return this.checkAllowSign();
  }

  //Kiểm tra quyền cập nhật
  checkAllowEdit() {
    let userId = this.authService.userId;
    let ownerPartyId = this.contract.ownerPartyId;
    let status = this.contract.currentStage;

    for (var i = 0; i < this.contract.signatures.length; i++) {
      if (this.contract.signatures[i].userId === userId) {
        return false;
      }
    }

    if (userId === ownerPartyId && status === 'LC_DRAFT_CREATE') {
      return true;
    }

    return false;
  }

  //Kiểm tra quyền ký
  checkAllowSign() {
    let userId = this.authService.userId;
    //hợp đồng đã valid
    if (this.contract.currentStage !== "LC_DRAFT_SUBMIT_SIGNED" && this.contract.currentStage !== "LC_DRAFT_CREATE") return false;
    //hợp đồng bản nháp và k phải của user hiện tại
    if (this.contract.ownerPartyId !== this.userInfo.partyId && this.contract.currentStage === "LC_DRAFT_CREATE") return false;
    //hợp đồng chưa ký bởi user hiện tại
    for (var i = 0; i < this.contract.signatures.length; i++) {
      if (this.contract.signatures[i].userId === userId) {
        return false;
      }
    }

    return true;
  }

  //Kiểm tra quyền sử dụng phương thức ký
  allowActionSignType(signType: string) {
    if (signType === '') return false;
    if (this.contract.ownerPartyId === this.userInfo.partyId) return true;

    if (this.contract.signForm.includes(signType)) return true;

    return false;
  }

  get notHaveSignTypeAllow() {
    let ok = true;
    if (this.contract.ownerPartyId === this.userInfo.partyId) return false;
    if (this.userType === 'CONSUMER') {
      this.hinhThucKy_consumer.forEach(item => {
        if (this.contract.signForm.includes(item.id)) ok = false;
      })
    } else if (this.userType === 'BUSINESS' || this.userType === 'ENTERPRISE') {
      this.hinhThucKy_business.forEach(item => {
        if (this.contract.signForm.includes(item.id)) ok = false;
      })
    }
    return ok;
  }

  //Trở lại trang trước
  goToBack() {
    // this._location.back();
    // this.router.navigate(['/app/console/contract-manager']);
    this.close.emit({});
  }

  //Đến cập nhật hợp đồng
  goToEdit() {
    // this.router.navigate(['/app/console/contract/update', this.contractId]);
  }

  //MARK: COMMON FUNC
  //Kiểm tra form đã có dữ liệu chưa
  get editedForm() {
    return false;
  }

  //convert imgUrl to file object
  urltoFile(url, filename, mimeType) {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  // resize image 
  resizeImage(img, fileImg, max_width, max_height, resize_for = 'SIGN_SERVER') {
    var width = img.width;
    var height = img.height;

    // calculate the width and height, constraining the proportions
    if (width > height) {
      if (width > max_width) {
        //height *= max_width / width;
        height = Math.round(height *= max_width / width);
        width = max_width;
      }
    } else {
      if (height > max_height) {
        //width *= max_height / height;
        width = Math.round(width *= max_height / height);
        height = max_height;
      }
    }
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');
    canvas.width = width;
    canvas.height = height;
    ctx.drawImage(img, 0, 0, width, height);
    var self = this;
    let imgURL = ctx.canvas.toDataURL(fileImg.type, 1);
    this.urltoFile(imgURL, fileImg.name, fileImg.type).then(function (file) {
      if (resize_for === 'SIGN_SERVER') {
        self.imgSignServer = file;
      } else {
        self.imgESign = file;
      }

    });
    canvas.remove();
  }

  converBase64toBlob(content, contentType): Blob {
    //blob = converBase64toBlob(response.content, 'application/pdf');
    contentType = contentType || '';
    var sliceSize = 512;
    var byteCharacters = window.atob(content); //method which converts base64 to binary
    var byteArrays = [
    ];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, {
      type: contentType,
    }); //statement which creates the blob
    return blob;
  }

  getBase64String(blob, callback) {
    var self = this;
    var reader = new FileReader();
    reader.onload = function (e) {
      callback(reader.result, self);
    }

    reader.readAsDataURL(blob);
  }

  //handel cho file pdf
  handle(str, self) {
    $('#fileBase').val(str.split(',')[1]);
  }

  //Handle cho file hình sign server
  handleImgSignServer(str, self) {
    self.imgContent = str;
    self.base64Image.setValue(str.split(',')[1]);
    PDFSign.setImage(str.split(',')[1]);
  }

  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], 'error');
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, 'error');
    } else {
      this.alert('Lỗi không xác định! Vui lòng thử lại', 'error');
    }
  }

  //MARK: NETWORKING
  //Khởi tạo hợp đồng
  postCreateDraftOnlyFile(data: FormData) {
    this.contractService.postCreateDraftOnlyFile(data).subscribe(res => {
      this.alert('Lưu hợp đồng thành công!', 'success', false, '', 'OK', () => {
      });
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    }, () => {

    });
  }

  //Convert docx sang pdf
  postConvertToPDF(data: FormData) {
    this.contractService.postConvertToPdf(data).subscribe(fileData => {
      const blob: any = new Blob([fileData], { type: 'application/pdf' });
      PDFSign.preview(blob);
      this.filePDF = blob;

      this.getBase64String(blob, this.handle);
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.ESIGN_SERVICE);
    });
  }

  //Tìm kiếm khách hàng
  searchCustomer(key) {
    this.contractService.searchCustomer(key).subscribe(res => {

    }, er => {

    })
  }

  //Lấy thông tin hợp đồng
  getContract(id) {
    this.enterpriseVipService.getContract(id).subscribe(res => {
      this.contract = new ContractResponse(res).object;
      if (this.contract.currentStage === 'LC_CONTRACT_VALID') {
        this.downloadFileContract(id, 'DRAFT');
        this.getListSignatureByContract(id, 'DRAFT');
      } else {
        this.downloadFileContract(id, 'DRAFT');
        this.getListSignatureByContract(id, 'DRAFT');
      }

      // if (this.action === 'SIGN') {
      //   this.selectTab(this.action);
      // }
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    })
  }

  //Tải file hợp đồng 
  downloadFileContract(id, type) {
    this.enterpriseVipService.downloadFileWithHeader(id, type).subscribe((res: HttpResponse<any>) => {
      if (res instanceof HttpResponse) {
        // let headers = res.headers.get('X-Token');
        // console.log(headers);

        const blob: any = new Blob([res['body']], { type: 'application/pdf' });
        this.filePDF = blob;
        PDFSign.preview(blob);
        this.fileName = `${id}.pdf`;
        this.getBase64String(this.filePDF, this.handle);
      }

    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    })
  }

  //Tải và lưu file hợp đồng 
  saveFileContract(id, type) {
    this.enterpriseVipService.downloadFileContract(id, type).subscribe((res) => {
      const blob: any = new Blob([res], { type: 'application/pdf' });
      var url = window.URL.createObjectURL(blob);
      var anchor = document.createElement("a");
      anchor.download = `${id}.pdf`;
      anchor.href = url;
      anchor.click();
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    })
  }

  //gửi Hợp đồng đã ký
  postSendContract(id, data) {
    this.enterpriseVipService.postSendContract(id, data).subscribe(res => {
      this.alert('Gửi hợp đồng thành công!', 'success', false, '', 'OK', () => {
        this.getContract(id);
        // this.router.navigate(['/app/console/contract', id]);
      });
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    })
  }

  //Lấy thông tin chữ ký số user
  getInfoCASignServer(id) {
    if (this.userType === 'BUSINESS' || this.userType === 'ENTERPRISE') {
      this.signatureService.getInfoCASignServer(id).subscribe(res => {
        console.log(res);
        if (res['object'] == null) {
          this.alert('Bạn chưa cấu hình tài khoản Sign Server! Vui lòng cấu hình tài khoản!', 'error', true, '', 'Đến cấu hình', () => {
            // this.router.navigate(['/app/console/signature-manager']);
            window.open(location.origin + '/console/signature-manager', "_blank");
          }, '', 'Huỷ');
        } else {
          let infoCA = new InfoCASignServerResponse(res).object;
          this.getInfoSignatureConfig(infoCA.certificateDefault.id);
        }
      }, err => {
        console.log(err);
        this.handleError(err, ResponseMsg.MESSAGES.SIGN_SERVER);
      }, () => {
      })
    }
  }

  //Lấy thông tin config chữ ký số sign server
  getInfoSignatureConfig(id) {
    this.signatureService.getInfoSignatureConfig(id).subscribe(res => {
      console.log(res);
      this.configCA = new InfoSignatureConfigResponse(res).object;
      this.setConfigSign(this.configCA);
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.SIGN_SERVER);
    })
  }

  //Hoàn thánh ký sign server
  postSignServer(data) {
    this.contractService.postSignServer(data).subscribe(res => {
      let fileBase64 = res['object']['fileBase64'];
      let blob = this.converBase64toBlob(fileBase64, 'application/pdf');
      this.pdfSigned = blob;
      PDFSign.preview(blob);
      PDFSign.removeAllSignBox();
      this.alert('Ký hợp đồng thành công!', 'success', false, '', 'OK', () => {
        // this.router.navigate(['/app/console/contract', id]);
      });
    }, err => {
      console.log(err);
      if (err.error && err.error.message && err.error.message === 'ECT-00000016') {
        this.alert('VNPT ký số: Phiên đăng nhập ký số hết hiệu lực!', 'error', true, '', 'Đăng nhập lại', () => {
          // this.router.navigate(['/app/console/signature-manager']);
          window.open(location.origin + '/console/signature-manager', "_blank");
        }, '', 'Huỷ');
      } else {
        this.handleError(err, ResponseMsg.MESSAGES.SIGN_SERVER);
      }
    })
  }

  //lấy thông tin chử ký điện thử
  getListESignature() {
    if (this.userType === 'CONSUMER') {
      this.signatureService.getListDigitalSignature().subscribe(res => {
        let digitalSignList = new DigitalSignatureResponse(res).object;
        if (digitalSignList.length > 0) {
          this.selectCert(digitalSignList[0]);
        }
      }, err => {
        console.log(err);
        this.handleError(err, ResponseMsg.MESSAGES.ESIGN_SERVICE);
      });
    }
  }

  //Gửi yêu cầu ký điện tử
  postRequestElectronicSign(contractId) {
    this.contractService.postRequestElectronicSign(contractId).subscribe((res: any) => {
      this.idSign = res && res.object && res.object.idSign ? res.object.idSign : '';
      if (this.idSign !== '') {
        this.openModalConfirmOTP(this.contentConfirmOTP);
      } else {
        this.alert('Gửi yêu cầu ký thất bại! Vui lòng thử lại!', 'error');
      }
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.MANAGEMENT_SERVICE);
    })
  }

  //Confirm OTP để ký điện tử
  postConfirmOTPESign(idSign, param) {
    this.contractService.postConfirmOTPESign(idSign, param).subscribe(res => {
      this.modalService.dismissAll();
      this.actESign();
    }, err => {
      console.log(err);
      if (err.error && err.error.message && ResponseMsg.MESSAGES.ESIGN_SERVICE[err.error.message] && ResponseMsg.MESSAGES.ESIGN_SERVICE[err.error.message] === 'ECT-00001203') {
        this.otp.setErrors({ notTrue: true });
      }
      this.handleError(err, ResponseMsg.MESSAGES.ESIGN_SERVICE);
    })
  }

  //Ký điện tử
  postESign(idSign, data: FormData) {
    this.contractService.postESign(idSign, data).subscribe((res: any) => {
      PDFSign.removeAllSignBox();
      const blob: any = new Blob([res], { type: 'application/pdf' });
      this.pdfSigned = blob;
      PDFSign.preview(blob);
      PDFSign.removeAllSignBox();
      this.alert('Ký điện tử thành công!', 'success', false, '', 'OK', () => {
      });
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.ESIGN_SERVICE);
    })
  }

  downloadFile(url) {
    this.contractService.dowloadFile(url).subscribe(res => {
      const blob: any = new Blob([res], { type: 'application/pdf' });
      this.pdfSigned = blob;
      PDFSign.preview(blob);
      this.fileName = `${this.contractId}_signed.pdf`;
      // this.getBase64String(this.filePDF, this.handle);
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.MANAGEMENT_SERVICE);
    })
  }

  //Lấy danh sách chữ ký trên hđ
  getListSignatureByContract(contractId, documentType) {
    this.contractService.getListSignatureByContract(contractId, documentType).subscribe(res => {
      console.log(res);
      this.listSignature = new ListSignatureResponse(res).object;
    }, err => {
      console.log(err);
      // this.handleError(err, ResponseMsg.MESSAGES.ESIGN_SERVICE);
    })
  }

  //Lấy thông tin user đăng nhập
  getUserInfo(id) {
    this.authService.getUserInfo(id).subscribe((res) => {
      this.userInfo = new UserInformationsResponse(res).object;
      this.userType = this.userInfo.userType || 'ENTERPRISE';
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.AUTH_SERVICE);
    }, () => {
    })
  }

  //Cập nhật ảnh chữ ký điện tử
  postDigitalSignature(data: FormData) {
    this.signatureService.postDigitalSignature(data).subscribe(res => {
      // this.alert('Lưu thông tin thành công!', 'success');
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.ESIGN_SERVICE);
    })
  }
}
