import { Injectable } from '@angular/core';
import { ServicePath } from 'src/app/utils/common/constant-service-api';
import { API } from '../../api';

const CREATE_POST = ServicePath.COMMENT_SERVICE + "/contract/{{contractId}}/trace/{{versionId}}/posts/create";
const EDIT_POST = ServicePath.COMMENT_SERVICE + "/contract/{{contractId}}/trace/{{versionId}}/post/{{postId}}";
const DELETE_POST = ServicePath.COMMENT_SERVICE + "/contract/{{contractId}}/trace/{{versionId}}/post/{{postId}}";
const CREATE_REPLY = ServicePath.COMMENT_SERVICE + "/contract/{{contractId}}/trace/{{versionId}}/post/{{postId}}/comments/create";
const EDIT_REPLY = ServicePath.COMMENT_SERVICE + "/contract/{{contractId}}/trace/{{versionId}}/post/{{postId}}/comment/{{replyId}}"
const DELETE_REPLY = ServicePath.COMMENT_SERVICE + "/contract/{{contractId}}/trace/{{versionId}}/post/{{postId}}/comment/{{replyId}}";
const GET_LIST_POST = ServicePath.COMMENT_SERVICE + "/contract/{{contractId}}/trace/{{versionId}}/get-list-post";
const POST_DETAIL = ServicePath.COMMENT_SERVICE + "/contract/{{contractId}}/trace/{{versionId}}/post/{{postId}}";

@Injectable({
  providedIn: 'root'
})
export class CommentService {

  constructor(private api: API) { }

  /**
   * Khởi tạo comment trên một version của hợp đồng
   * @param contractId 
   * @param versionId 
   * @param data 
   */
  postCreatPost(contractId, versionId, data: FormData) {
    var url = CREATE_POST.replace("{{contractId}}", contractId).replace("{{versionId}}", versionId);
    return this.api.postMultiPartJSON(url, data);
  }

  /**
   * Chỉnh sửa bình luận
   * @param contractId 
   * @param versionId 
   * @param postId 
   * @param data 
   * @returns 
   */
  postEditPost(contractId, versionId, postId, data: FormData) {
    var url = EDIT_POST.replace("{{contractId}}", contractId)
      .replace("{{versionId}}", versionId)
      .replace("{{postId}}", postId);
    return this.api.postMultiPartJSON(url, data);
  }

  /**
   * Xóa bình luận
   * @param contractId 
   * @param versionId 
   * @param postId 
   * @returns 
   */
  deleltePost(contractId, versionId, postId, data) {
    var url = DELETE_POST.replace("{{contractId}}", contractId)
      .replace("{{versionId}}", versionId)
      .replace("{{postId}}", postId);
    return this.api.request("DELETE", url, data);
  }

  /**
   * Lấy thông tin chi tiết post
   * @param postId 
   * @returns 
   */
  getDetailPost(contractId, versionId, postId) {
    var url = POST_DETAIL.replace("{{contractId}}", contractId)
    .replace("{{versionId}}", versionId)
    .replace("{{postId}}", postId);
    return this.api.get(url);
  }

  /**
   * Lấy danh sách comment trong version
   * @param contractId 
   * @param versionId 
   * @returns 
   */
  getListPost(contractId, versionId) {
    var url = GET_LIST_POST.replace("{{contractId}}", contractId).replace("{{versionId}}", versionId);
    return this.api.get(url);
  }


  /**
   * Khởi tạo phản hồi cho bình luận
   * @param contractId 
   * @param versionId 
   * @param postId 
   * @param data 
   * @returns 
   */
  postCreatReply(contractId, versionId, postId, data: FormData) {
    var url = CREATE_REPLY.replace("{{contractId}}", contractId)
      .replace("{{versionId}}", versionId)
      .replace("{{postId}}", postId);
    return this.api.postMultiPartJSON(url, data);
  }

  /**
   * Chỉnh sửa phản hồi cho bình luận
   * @param contractId 
   * @param versionId 
   * @param postId 
   * @param replyId 
   * @param data 
   * @returns 
   */
  postEditReply(contractId, versionId, postId, replyId, data: FormData) {
    var url = EDIT_REPLY.replace("{{contractId}}", contractId)
      .replace("{{versionId}}", versionId)
      .replace("{{postId}}", postId)
      .replace("{{replyId}}", replyId);
    return this.api.postMultiPartJSON(url, data);
  }

  /**
   * Xóa phản hồi cho bình luận
   * @param contractId 
   * @param versionId 
   * @param postId 
   * @param replyId 
   * @returns 
   */
  deleteReply(contractId, versionId, postId, replyId, data) {
    var url = DELETE_REPLY.replace("{{contractId}}", contractId)
      .replace("{{versionId}}", versionId)
      .replace("{{postId}}", postId)
      .replace("{{replyId}}", replyId);
    return this.api.delete(url, data);
  }
}
