<app-spinner></app-spinner>
<div class="container-fluid">
    <div class="row login-grid">
        <div class="col-md-7 left-view-bg">

        </div>
        <div class="col-md-5 right-view-login p-4 bg-white">
            <div class="row justify-content-center mt-5 pt-5 pb-2 mb-2">
                <div class="col-md-10">
                    <div class="text-center">
                        <h1 class="font-weight-bold">Chào mừng bạn đến với hệ thống VNPT eContract</h1>
                        <h5 class="text-muted">Chào mừng bạn đến với hệ thống VNPT eContract</h5>
                    </div>
                </div>
            </div>
            <div class="row justify-content-center my-3 py-5">
                <div class="col-xl-6 col-lg-7 col-md-10 col-sm-11">
                    <h2 class="font-weight-bold text-center mb-5">Đăng ký</h2>

                    <div class="row mb-4">
                        <ul class="n-nav-tabs">
                            <li class="n-nav-item" [class.active]="activeConsumer" (click)="changeFormActive('CONSUMER')">
                                <a class="n-nav-link">Cá nhân</a>
                            </li>
                            <li class="n-nav-item" [class.active]="activeBusiness" (click)="changeFormActive('BUSINESS')">
                                <a class="n-nav-link">Tổ chức</a>
                            </li>
                            <li class="n-nav-item" [class.active]="activeBusinessVIP" (click)="changeFormActive('BUSINESSVIP')">
                                <a class="n-nav-link">Tổ chức VIP</a>
                            </li>
                        </ul>

                        <!-- <div class="col-6">
                            <button (click)="changeFormActive('CONSUMER')" class="btn btn-lg d-inline-flex w-100"
                                [class.btn-outline-secondary]="!activeConsumer"
                                [class.btn-outline-primary]="activeConsumer" [class.active]="activeConsumer">
                                <span class="material-icons material-icons-lg">person</span>
                                <span class="mx-auto">Cá nhân</span>
                                <span class="material-icons material-icons-lg ml-1 text-white">check_circle</span>
                            </button>
                        </div>
                        <div class="col-6">
                            <button (click)="changeFormActive('BUSINESS')" class="btn btn-lg d-inline-flex w-100"
                                [class.btn-outline-secondary]="!activeBusiness"
                                [class.btn-outline-primary]="activeBusiness" [class.active]="activeBusiness">
                                <span class="material-icons material-icons-lg">domain</span>
                                <span class="mx-auto">Tổ chức</span>
                                <span class="material-icons material-icons-lg ml-1 text-white">check_circle</span>
                            </button>
                        </div> -->
                    </div>

                    <!-- Cá nhân -->
                    <form *ngIf="activeConsumer" [formGroup]="formConsumer" class="py-2">
                        <div class="input-group input-custom mb-4">
                            <span class="input-custom-label">Email <span class="text-danger">*</span></span>
                            <input appTrimValue formControlName="email" type="email"
                                class="form-control form-control-lg input-custom-control" style="position: relative;"
                                placeholder="Nhập email" />
                            <app-show-validate-errors [isSubmit]="isSubmitConsumer" [errorMessages]="ValidMsg.email"
                                [control]="formConsumer.controls.email" [detail]="{ name: 'email' }">
                            </app-show-validate-errors>
                        </div>

                        <div class="input-group input-custom mb-4">
                            <span class="input-custom-label">Số điện thoại <span class="text-danger">*</span></span>
                            <input formControlName="sdt" type="text"
                                class="form-control form-control-lg input-custom-control" style="position: relative;"
                                placeholder="Nhập số điện thoại" (input)="handleInputSdt($event)" />
                            <app-show-validate-errors [isSubmit]="isSubmitConsumer" [errorMessages]="ValidMsg.sdt"
                                [control]="sdt" [detail]="{ name: 'sdt' }"></app-show-validate-errors>
                        </div>

                        <div class="input-group input-custom mb-4">
                            <span class="input-custom-label">Tên tài khoản <span class="text-danger">*</span></span>
                            <input appTrimValue formControlName="username" type="text"
                                class="form-control form-control-lg input-custom-control" style="position: relative;"
                                placeholder="Nhập tên tài khoản" />
                            <app-show-validate-errors [isSubmit]="isSubmitConsumer" [errorMessages]="ValidMsg.username"
                                [control]="username" [detail]="{ name: 'username' }"></app-show-validate-errors>
                        </div>

                        <div class="input-group input-custom mb-4">
                            <span class="input-custom-label">Mật khẩu <span class="text-danger">*</span></span>
                            <input appInputPassword formControlName="password" type="password"
                                class="form-control form-control-lg input-custom-control" style="position: relative;"
                                placeholder="Nhập mật khẩu" />
                            <app-show-validate-errors [isSubmit]="isSubmitConsumer" [errorMessages]="ValidMsg.password"
                                [control]="password" [detail]="{ name: 'password' }"></app-show-validate-errors>
                        </div>
                        <div class="row">
                            <!-- <div class="col-12 d-flex mb-4">
                                <a class="text-primary align-self-center font-weight-bold ml-auto"
                                    [routerLink]="['/app/forgot-password']">Quên mật
                                    khẩu?</a>
                            </div> -->
                            <div class="col-12 mb-4">
                                <button (click)="submitConsumer()" type="submit"
                                    class="btn btn-lg btn-primary btn-block b-radius-5px">
                                    Đăng ký
                                </button>
                            </div>
                            <div class="col-12 text-center">
                                <span>Đã có tài khoản? <a class="text-primary text-center font-weight-bold"
                                        [routerLink]="[constantUrl.LOGIN]">Đăng nhập</a></span>
                            </div>
                        </div>
                    </form>

                    <!-- Tổ chức thường -->
                    <form *ngIf="activeBusiness" [formGroup]="formBusiness" class="py-2">
                        <div class="input-group input-custom mb-4">
                            <span class="input-custom-label">Tên doanh nghiệp <span class="text-danger">*</span></span>
                            <input appTrimValue formControlName="tenDoanhNghiep" type="text"
                                class="form-control form-control-lg input-custom-control" style="position: relative;"
                                placeholder="Nhập tên doanh nghiệp" />
                            <app-show-validate-errors [isSubmit]="isSubmitBusiness"
                                [errorMessages]="ValidMsg.tenDoanhNghiep" [control]="tenDoanhNghiep"
                                [detail]="{ name: 'tenDoanhNghiep' }"></app-show-validate-errors>
                        </div>

                        <div class="input-group input-custom mb-4">
                            <span class="input-custom-label">Tên viết tắt <span class="text-danger">*</span></span>
                            <input appTrimValue formControlName="tenVietTat" type="text"
                                class="form-control form-control-lg input-custom-control" style="position: relative;"
                                placeholder="Nhập tên viết tắt" />
                            <app-show-validate-errors [isSubmit]="isSubmitBusiness"
                                [errorMessages]="ValidMsg.tenVietTat" [control]="tenVietTat"
                                [detail]="{ name: 'tenVietTat' }"></app-show-validate-errors>
                        </div>

                        <div class="input-group input-custom mb-4">
                            <span class="input-custom-label">Mã số thuế <span class="text-danger">*</span></span>
                            <input appTrimValue formControlName="maSoThue" type="text"
                                class="form-control form-control-lg input-custom-control" style="position: relative;"
                                placeholder="Nhập mã số thuế" />
                            <app-show-validate-errors [isSubmit]="isSubmitBusiness" [errorMessages]="ValidMsg.maSoThue"
                                [control]="maSoThue" [detail]="{ name: 'maSoThue' }"></app-show-validate-errors>
                        </div>
                        <div class="row">
                            <div class="col-12 mb-4">
                                <button (click)="submitBusiness()" type="submit"
                                    class="btn btn-lg btn-primary btn-block b-radius-5px">
                                    Đăng ký
                                </button>
                            </div>
                            <div class="col-12 text-center">
                                <span>Đã có tài khoản? <a class="text-primary text-center font-weight-bold"
                                        [routerLink]="[constantUrl.LOGIN]">Đăng nhập</a></span>
                            </div>
                        </div>
                    </form>

                    <!-- Tổ chức vip -->
                    <form *ngIf="activeBusinessVIP" [formGroup]="formBusiness" class="py-2">
                        <div class="input-group input-custom mb-4">
                            <span class="input-custom-label">Tên doanh nghiệp <span class="text-danger">*</span></span>
                            <input appTrimValue formControlName="tenDoanhNghiep" type="text"
                                class="form-control form-control-lg input-custom-control" style="position: relative;"
                                placeholder="Nhập tên doanh nghiệp" />
                            <app-show-validate-errors [isSubmit]="isSubmitBusiness"
                                [errorMessages]="ValidMsg.tenDoanhNghiep" [control]="tenDoanhNghiep"
                                [detail]="{ name: 'tenDoanhNghiep' }"></app-show-validate-errors>
                        </div>

                        <div class="input-group input-custom mb-4">
                            <span class="input-custom-label">Tên viết tắt <span class="text-danger">*</span></span>
                            <input appTrimValue formControlName="tenVietTat" type="text"
                                class="form-control form-control-lg input-custom-control" style="position: relative;"
                                placeholder="Nhập tên viết tắt" />
                            <app-show-validate-errors [isSubmit]="isSubmitBusiness"
                                [errorMessages]="ValidMsg.tenVietTat" [control]="tenVietTat"
                                [detail]="{ name: 'tenVietTat' }"></app-show-validate-errors>
                        </div>

                        <div class="input-group input-custom mb-4">
                            <span class="input-custom-label">Mã số thuế <span class="text-danger">*</span></span>
                            <input appTrimValue formControlName="maSoThue" type="text"
                                class="form-control form-control-lg input-custom-control" style="position: relative;"
                                placeholder="Nhập mã số thuế" />
                            <app-show-validate-errors [isSubmit]="isSubmitBusiness" [errorMessages]="ValidMsg.maSoThue"
                                [control]="maSoThue" [detail]="{ name: 'maSoThue' }"></app-show-validate-errors>
                        </div>
                        <div class="row">
                            <div class="col-12 mb-4">
                                <button (click)="submitBusiness()" type="submit"
                                    class="btn btn-lg btn-primary btn-block b-radius-5px">
                                    Đăng ký
                                </button>
                            </div>
                            <div class="col-12 text-center">
                                <span>Đã có tài khoản? <a class="text-primary text-center font-weight-bold"
                                        [routerLink]="[constantUrl.LOGIN]">Đăng nhập</a></span>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>