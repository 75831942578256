import { Component, OnInit } from '@angular/core';
import { EkycService } from 'src/app/utils/services/aas-service/ekyc-service/ekyc.service';
import { AuthService } from 'src/app/utils/services/auth.service';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { localize } from 'src/app/utils/localize/localize';
import { EkycConfig } from 'src/app/utils/models/aas-models/ekyc-config/ekyc-config';
import { Router } from '@angular/router';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-update-citizenid',
  templateUrl: './update-citizenid.component.html',
  styleUrls: ['./update-citizenid.component.scss']
})
export class UpdateCitizenidComponent implements OnInit {
  identityType = "CCCD";
  partyId: any;
  ekycConfig: EkycConfig[] = [];

  page = 1;
  maxSize = 10;

  isEdited = false;

  constructor(
    private ekycService: EkycService,
    private authService: AuthService,
    private myAlert: AlertControl,
    private router: Router,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.partyId = this.authService.partyId;
    this.getEkycConfig();
  }

  isParamDisable(code) {
    return code === "compareFace" || code === "compareID" || code === "compareName"
  }

  // getClassStatus(status) {
  //   if (status === "Đang hoạt động") return "badge-success-new";
  //   if (status === "Ngưng hoạt động") return "badge-danger-new";
  //   return "";
  // }

  status = [
    {value: true, name: "status_running"},
    {value: false, name: "status_stopped"}
  ];
  
  changeSelected(){
    this.isEdited = true;
  }

  getEkycConfig() {
    this.ekycService.getEkycConfig(this.identityType, this.partyId).subscribe((res: any) => {
      if (res.object && res.object.config && res.object.config instanceof Array){
        res.object.config.forEach((item) => {
          this.ekycConfig.push(new EkycConfig(item));
          console.log(this.ekycConfig);
        })
      }
    })
  }

  save() {
    if (this.checkValid()) {
      this.myAlert.showAlert(this.translate.instant('UpdatePassport_ts_1'), "", true, "", this.translate.instant('btn_update'), () => {
        this.ekycService.updateEkycConfig(this.identityType, this.partyId, this.ekycConfig).subscribe((res: any) => {
          this.myAlert.showAlertOnlyNoti(this.translate.instant('UpdatePassport_ts_2'), ConstantAlertType.BLANK, "", this.translate.instant('btn_close'), null, this.translate.instant('UpdatePassport_ts_3'));
          this.router.navigate([ConstantUrl.enduser_ekyc_config], { queryParams: { 'type':  "CCCD" }});
        }, err => {
          this.myAlert.showErrorHandled(err);
        })
      }, "", this.translate.instant('btn_close'), null, this.translate.instant('btn_confirm'));
    }
  }

  checkValid() {
    for (let i = 0; i < this.ekycConfig.length; i++) {
      if (this.ekycConfig[i].warning === "" || this.ekycConfig[i].warning == null
        || this.ekycConfig[i].success === "" || this.ekycConfig[i].success == null) {
        this.myAlert.showAlertOnlyNoti(this.translate.instant('UpdatePassport_ts_4'), ConstantAlertType.BLANK, "", this.translate.instant('UpdatePassport_btn_understood'), null, this.translate.instant('UpdatePassport_ts_5'));
        return false;
      }
      
      const warning = +this.ekycConfig[i].warning;
      const success = +this.ekycConfig[i].success;
      if (0 <= warning && warning < success && success <= 100) {

      } else {
        this.myAlert.showAlertOnlyNoti(this.translate.instant('UpdatePassport_ts_4'), ConstantAlertType.BLANK, "", this.translate.instant('UpdatePassport_btn_understood'), null, this.translate.instant('UpdatePassport_ts_5'));
        return false;
      }
    }

    return true;
  }

}
