import { Injectable } from '@angular/core';
import { ServicePath } from '../../common/constant-service-api';
import { API } from '../api';

const LIST_DOCUMENT_URL = ServicePath.TEMPATE_SERVICE + '/documents/list';
const CREATE_DOCUMENT_URL = ServicePath.TEMPATE_SERVICE + '/documents/';
const UPDATE_DOCUMENT_URL = ServicePath.TEMPATE_SERVICE + '/documents/';
const DELETE_DOCUMENT_URL = ServicePath.TEMPATE_SERVICE + '/documents/';
const DOWNLOAD_SAMPLE = ServicePath.TEMPATE_SERVICE + '/documents/download-sample';
const UPLOAD_FILE_DOCUMENT_URL = ServicePath.TEMPATE_SERVICE + '/documents/create-from-file'

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  constructor(private api: API) {}

  postUploadDocumentFile(data) {
    const url = UPLOAD_FILE_DOCUMENT_URL;
    return this.api.requestPostMultiPart(url, data);
  }
  /**
   * Get list document
   * @param page
   * @param maxSize
   * @param propertiesSort
   * @param sort
   * @param keySearch
   * @param fieldSearch
   */
  getListDocuments(page, maxSize, propertiesSort, sort, keySearch = '', fieldSearch = 'documentName') {
    const url =
      LIST_DOCUMENT_URL +
      `?page=${page}&maxSize=${maxSize}&sort=${sort}&propertiesSort=${propertiesSort}&keySearch=${keySearch}&fieldSearch=${fieldSearch}`;
    return this.api.get(url);
  }

  /**
   * POST create Document
   * @param data
   */
  postCreateDocument(data) {
    return this.api.post(CREATE_DOCUMENT_URL, data);
  }

  /**
   * PUT create Document
   * @param documentId
   * @param data
   */
  putUpdateDocument(documentId, data) {
    return this.api.put(UPDATE_DOCUMENT_URL + documentId, data);
  }

  /**
   * Delete document
   * @param documentId
   */
  deleteDocument(documentId) {
    return this.api.delete(DELETE_DOCUMENT_URL + documentId);
  }

  /**
   * Download sample excel file
   */
  downloadSample() {
    const url = DOWNLOAD_SAMPLE;
    return this.api.download(url);
  }
}
