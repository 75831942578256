export class Privilege {
    uuidPrivilege: string = '';
    name: string = '';
    description: string = '';
    privilegeNumber: number;
    serviceId: string = '';

    constructor(data = null) {
        data = data || {};

        this.uuidPrivilege = data.uuidPrivilege || '';
        this.name = data.name || '';
        this.description = data.description || '';
        this.privilegeNumber = data.privilegeNumber;
        this.serviceId = data.serviceId || '';
    }
}