<div [class.d-none]="!visabled" >
    <span class="text-grey-darkest mb-3" [innerHTML]="getDecription()"></span><br>
    <form [formGroup]="formOTP">
        <div class="form-group">
            <label>{{'ESignOto_otp_code' | translate}} <span class="text-danger">*</span>: </label>
            <input formControlName="otp" class="form-control" type="text" placeholder="{{ 'ESignOtp_otp_placdeholder' | translate }}" (input)="handleInputOTP($event)">
            <app-show-validate-errors [isSubmit]="isOTPSubmit" [errorMessages]="validMsgOTP.otp"
                [control]="formOTP.controls.otp" [detail]="{ name: 'isOTPSubmit' }"></app-show-validate-errors>
            <a *ngIf="count === 0" class="cursor-pointer text-decoration-underline text-primary mt-3" (click)="resendOTP()">{{ 'ESignOtp_a_resend_otp_esign' | translate }}</a>
            <span class="text-muted mt-3" *ngIf="count > 0">{{'ESignOtp_resend_otp_after' | translate}}
                {{counter$ | async}}s</span>
        </div>
    </form>
</div>