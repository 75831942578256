import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { AccountManagerService } from "src/app/utils/services/aas-network/account-manager.service";
import { OrganizationDetailObject } from "src/app/utils/models/aas-models/account-manager/organization-manager/detail/organization-detail-object";
import { OrganizationDetailResponse } from "src/app/utils/models/aas-models/account-manager/organization-manager/detail/organization-detail-response";
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { NgModalComponent } from "src/app/layout/extensions/ng-modal/ng-modal.component";
import { Select2OptionData } from 'ng-select2';
import { Options } from 'select2';
import { Observable, forkJoin } from 'rxjs';
import { ListTinhResponse } from 'src/app/utils/models/catalog/list-tinh/list-tinh-response.model';
import { ListHuyenResponse } from 'src/app/utils/models/catalog/list-huyen/list-huyen-response.model';
import { ListXaResponse } from 'src/app/utils/models/catalog/list-xa/list-xa-response.model';
import { CatalogService } from 'src/app/utils/services/aas-network/catalog.service';
import {Router} from "@angular/router";
import { ValidatorsExtension } from 'src/app/utils/common/validators-extension';
import { AlertControl } from "src/app/utils/alert/alert-control";
import { ConstantAlertType } from "src/app/utils/common/constant-alert-type";
import { ConstantAlertBtn, ConstantAlertMsg } from "src/app/utils/common/constant-alert-msg";
import { REGEXP_COMPANY_NAME, REGEXP_DATE_INPUT, REGEXP_PHONE_NUMBER } from "src/app/utils/common/regexp";
import { localize } from "src/app/utils/localize/localize";

@Component({
  selector: 'app-service-organization-update',
  templateUrl: './service-organization-update.component.html',
  styleUrls: ['./service-organization-update.component.scss']
})
export class ServiceOrganizationUpdateComponent implements OnInit {

  partyId = this.router.snapshot.paramMap.get("id");
  personalDetailObject : OrganizationDetailObject;
  personalDetailResponse: OrganizationDetailResponse;
  form: FormGroup;
  isSubmit: boolean = false;

  public tinhData: Observable<Array<Select2OptionData>>;
  public huyenData: Observable<Array<Select2OptionData>>;
  public xaData: Observable<Array<Select2OptionData>>;
  public options: Options;

  catalogTinhRes = new ListTinhResponse();
  catalogHuyenRes = new ListHuyenResponse();
  catalogXaRes = new ListXaResponse();

  firstLoadTinh = true;
  firstLoadHuyen = true;
  firstLoadDCTSTinh = true;
  firstLoadDCTSHuyen = true;

  tinhBindData = [];
  huyenBindData = [];
  xaBindData = [];

  validateMsg = {
    tenToChuc: {
      required: "Vui lòng nhập tên tổ chức!",
      pattern: "Sai định dạng tên tổ chức!"
    },
    tenRutGon: {
      required: "Vui lòng nhập tên viết tắt!",
      pattern: "Sai định dạng tên rút gọn!"
    },
    maSoThue: {
      required: "Vui lòng nhập mã số thuế!"
    },
    email: {
      required: "Vui lòng nhập email!"
    },
    username: {
      required: "Vui lòng nhập tên đăng nhập!",
      pattern: "Sai định dạng tên đăng nhập!"
    },
    ten: {
      required: "Vui lòng nhập họ và tên!",
      pattern: "Sai định dạng họ và tên!"
    },
    sdt: {
      required: "Vui lòng nhập số điện thoại!",
      pattern: "Sai định dạng số điện thoại!"
    },
    ngaySinh: {
      required: "Vui lòng nhập ngày sinh!",
      pattern: "Sai định dạng ngày tháng năm"
    },
    gioiTinhId: {
      required: "Vui lòng nhập giới tính!"
    },
    tinhId: {
      required: "Vui lòng nhập tỉnh/thành phố!"
    },
    huyenId: {
      required: "Vui lòng nhập quận/huyện"
    },
    xaId: {
      required: "Vui lòng nhập xã!"
    },
    duong: {
      required: "Vui lòng nhập đường!"
    },
    soNha: {
      required: "Vui lòng nhập số nhà!"
    }
  };

  optionsDateFormat = {
    date: true,
    delimiter: '/',
    datePattern: ['d', 'm', 'Y']
  };

  constructor(
    private fb: FormBuilder,
    private router: ActivatedRoute,
    private accountManagerService: AccountManagerService,
    private modalService: NgbModal,
    private catalog: CatalogService,
    private routerA: Router,
    private myAlert: AlertControl
  ) {}

  ngOnInit(): void {
    this.viewDidLoad();
  }

  viewDidLoad() {
    this.makeForm();
    this.detailEnterprise();
    this.getListTinh();
  }

  makeForm() {
    this.form =this.fb.group({
      toChuc:  this.fb.group({
        tenToChuc: ['', [Validators.required, Validators.pattern(REGEXP_COMPANY_NAME)]],
        tenRutGon: ['', [Validators.required, ValidatorsExtension.validateShortCompanyName]],
      }),
      // maSoThue: ['', Validators.required],
      // email: ['', [Validators.required, Validators.email]],
      maSoThue: ['', ],
      email: ['', ],
      daiDien: this.fb.group({
        username: ['', Validators.required],
        ten: ['', [Validators.required, ValidatorsExtension.validateFullName]],
        sdt: ['', [Validators.required,  Validators.pattern(REGEXP_PHONE_NUMBER)]],
        ngaySinh: [
          '',
          [ Validators.required,
            Validators.pattern(REGEXP_DATE_INPUT)]
        ],
        gioiTinhId:[null, Validators.required],
        tinhId:[null, Validators.required],
        huyenId:[null, Validators.required],
        xaId:[null, Validators.required],
        duong:['', Validators.required],
        soNha:['', Validators.required],
      })
    });
  }
  
  get daiDien() { return this.form.controls.daiDien as FormGroup; }
  get toChuc() { return this.form.controls.toChuc as FormGroup; }

  get f() { return this.form.controls; }
  get tenToChuc() { return this.form.get('toChuc.tenToChuc'); }
  get tenRutGon() { return this.form.get('toChuc.tenRutGon'); }
  get username() { return this.form.get('daiDien.username'); }
  get ten() { return this.form.get('daiDien.ten'); }
  get sdt() { return this.form.get('daiDien.sdt'); }
  get email() { return this.form.get('email'); }
  get ngaySinh() { return this.form.get('daiDien.ngaySinh'); }
  get gioiTinhId() { return this.form.get('daiDien.gioiTinhId'); }
  get tinhId() { return this.form.get('daiDien.tinhId'); }
  get huyenId() { return this.form.get('daiDien.huyenId') }
  get xaId() { return this.form.get('daiDien.xaId') }
  get duong() { return this.form.get('daiDien.duong') }
  get soNha() { return this.form.get('daiDien.soNha') }

  tinhChange(event) {
    if (event && event !== '') {
      this.getListHuyen(event?.id);
        let daiDien = this.form.controls.daiDien as FormGroup;
        this.huyenBindData = [];
        daiDien.controls.huyenId.setValue(null);
        this.xaBindData = [];
        daiDien.controls.xaId.setValue(null);
    }
  }

  huyenChange(event) {
    if (event && event !== '') {
      this.getListXa(event?.id);
      this.xaBindData = [];
      let daiDien = this.form.controls.daiDien as FormGroup;
      daiDien.controls.xaId.setValue(null);
    }
  }

  xaChange(event) {
    // this.xaId.setValue(event);
  }

  initForm(data: OrganizationDetailObject) {
    this.form.patchValue({
      toChuc:  {
        tenToChuc: data.tenToChuc,
        tenRutGon:data.tenRutGon,
      },
      maSoThue: data.maSoThue,
      email: data.userInformations.email,
      daiDien: {
        username: data.username,
        ten: data.userInformations.ten,
        sdt: data.userInformations.sdt,
        ngaySinh: data.userInformations.ngaySinh,
        gioiTinhId: data.userInformations.gioiTinhId || null,
        tinhId: data.userInformations.tinhId || null,
        huyenId: data.userInformations.huyenId || null,
        xaId: data.userInformations.xaId || null,
        duong: data.userInformations.duong,
        soNha: data.userInformations.soNha,
      }
    })

    console.log(this.form.value);
  }

  onClickSave() {
    this.isSubmit = true;
    if (this.form.invalid) {
      return;
    }
    if (this.form.valid) {
      const data ={
        daiDien: this.form.value.daiDien,
        toChuc:this.form.value.toChuc,
      }
      data.toChuc.tenRutGon = data.toChuc.tenRutGon.trim();
      data.toChuc.tenToChuc = data.toChuc.tenToChuc.trim();
      data.daiDien.ten = data.daiDien.ten.trim();
      data.daiDien.sdt = data.daiDien.sdt.trim();
      data.daiDien.ngaySinh = data.daiDien.ngaySinh.split('/').reverse().join('-');
      data.daiDien.xaId = parseInt(data.daiDien.xaId);
      data.daiDien.huyenId = parseInt(data.daiDien.huyenId);
      data.daiDien.tinhId = parseInt(data.daiDien.tinhId);
      // console.log(data)
      this.updateAccount(data)
    } else {
      this.isSubmit = false;
    }
  }

  //MARK: NETWORK
  getListTinh() {
    this.catalog.getListTinh().subscribe((res) => {
      this.catalogTinhRes = new ListTinhResponse(res);

      this.tinhBindData = this.catalogTinhRes.object.map(item => {
        return { id: item.tinhId, label: item.tenTinh };
      });
    }, err => {
      console.log(err);
    }, () => {
    });
  }

  getListHuyen(idTinh) {
    this.catalog.getListHuyen(idTinh).subscribe((res) => {
      this.catalogHuyenRes = new ListHuyenResponse(res);

      this.huyenBindData = this.catalogHuyenRes.object.map(item => {
        return { id: item.huyenId, label: item.tenHuyen };
      });
    }, err => {
      console.log(err);
    }, () => {
    });
  }

  getListXa(idHuyen) {
    this.catalog.getListXa(idHuyen).subscribe((res) => {
      this.catalogXaRes = new ListXaResponse(res);

      this.xaBindData = this.catalogXaRes.object.map(item => {
        return { id: item.xaId, label: item.tenXa };
      });
    }, err => {
      console.log(err);
    }, () => {
    });
  }

  detailEnterprise() {
    this.accountManagerService.getOrganizationById(this.partyId).subscribe(
      (res) => {
        this.personalDetailResponse= new OrganizationDetailResponse(res);
        this.personalDetailObject = this.personalDetailResponse.object;

        if (this.personalDetailObject.userInformations.tinhId) this.getListHuyen(this.personalDetailObject.userInformations.tinhId);
        if (this.personalDetailObject.userInformations.huyenId) this.getListXa(this.personalDetailObject.userInformations.huyenId);

        this.initForm(this.personalDetailObject);
      },
      (err) => {
        if (!!err.error.error) {
          console.log(err.error.error[0]);
        }
      }
    );
  }

  updateAccount(data) {
    this.myAlert.showAlert(ConstantAlertMsg.ADMIN_UPDATE_ACC_CONFIRM, ConstantAlertType.BLANK, true, "", ConstantAlertBtn.CONFIRM, () => {
      this.accountManagerService
        .postUpdateOrganization(this.partyId,data)
        .subscribe(
          (res) => {
            this.myAlert.showAlertOnlyNoti(ConstantAlertMsg.ADMIN_UPDATE_ACC_SUCCESS, ConstantAlertType.SUCCESS, "", ConstantAlertBtn.CLOSE, () => {
              // this.detailEnterprise();
              this.routerA.navigate(['/console/account-manager/organization', this.partyId])
            });
          },
          (err) => {
          }
        );
    }, "", ConstantAlertBtn.CANCEL);
  }
}
