import { AfterViewInit, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { Component, ElementRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { concat, Observable, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, startWith, switchMap, tap } from 'rxjs/operators';
import { Config } from 'src/app/config';
import { ESignComponent } from 'src/app/service-pages/service-console/service-enduser-console/contract-manager/layout/e-sign/e-sign.component';
import { SmartCaComponent } from 'src/app/service-pages/service-console/service-enduser-console/contract-manager/layout/smart-ca/smart-ca.component';
import { ESignOtpComponent } from 'src/app/service-pages/service-console/service-enduser-console/contract-manager/service-contract-detail/layout/e-sign-otp/e-sign-otp.component';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { CensorshipStatus, ConstLocalStorage } from 'src/app/utils/common/constant';
import { ConstantAlertBtn, ConstantAlertMsg } from 'src/app/utils/common/constant-alert-msg';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { OTP_CONFIRM_SIGN } from 'src/app/utils/common/constant-vaildate-msg';
import { ConstSignType, SignFlowTypes } from 'src/app/utils/common/constants/sign-form';
import { ImageProgress } from 'src/app/utils/common/image-progress';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { StatusCode } from 'src/app/utils/common/status-code';
import { UtilsFunc } from 'src/app/utils/common/utils-func';
import { AasValidateMessage } from 'src/app/utils/common/validate-msg-aas';
import { localize } from 'src/app/utils/localize/localize';
import { ContractInternal } from 'src/app/utils/models/aas-models/contract/contract/contract-internal.model';
import { ContractObject } from 'src/app/utils/models/aas-models/contract/contract/contract-object.model';
import { DigitalSignatureItem } from 'src/app/utils/models/aas-models/signature/digital-signature/digital-signature-item';
import { DigitalSignatureResponse } from 'src/app/utils/models/aas-models/signature/digital-signature/digital-signature-response';
import { InfoCASignServerResponse } from 'src/app/utils/models/aas-models/signature/info-ca-signserver/info-ca-signserver-response';
import { ListUsbTokenResponse } from 'src/app/utils/models/aas-models/signature/list-usb-token/list-usb-token-response';
import { UsbTokenItem } from 'src/app/utils/models/aas-models/signature/list-usb-token/usb-token-item';
import { InfoSignatureConfigObject } from 'src/app/utils/models/aas-models/signature/signature-config/info-signature-config-object';
import { InfoSignatureConfigResponse } from 'src/app/utils/models/aas-models/signature/signature-config/info-signature-config-response';
import { SignBox } from 'src/app/utils/models/aas-models/signbox/sign-box';
import { AasContractService } from 'src/app/utils/services/aas-network/aas-contract.service';
import { EmployeeService } from 'src/app/utils/services/aas-network/employee/employee.service';
import { PartService } from 'src/app/utils/services/aas-network/part/part.service';
import { SignatureService } from 'src/app/utils/services/aas-network/signature.service';
import { AuthService } from 'src/app/utils/services/auth.service';
import { LoadingService } from 'src/app/utils/services/loading.service';

declare var PDFSign: any;
declare var default_img: any;
declare var vnpt_plugin: any;
declare var PdfSigner: any;
declare var $: any;
@Component({
  selector: 'app-wv-contract-censorship',
  templateUrl: './wv-contract-censorship.component.html',
  styleUrls: ['./wv-contract-censorship.component.scss']
})
export class WvContractCensorshipComponent implements OnInit {
  @ViewChild('popupSendCensorship') popupSendCensorship: ElementRef;
  @ViewChild('otpConfirmSignInternal') otpConfirmSign: ElementRef;
  @ViewChild('modalRejectInternal') modalRejectInternal: ElementRef;
  @ViewChild('eSignOTP') set eSignOTP(content: ESignOtpComponent) {
    if (content) {
      this._eSignOTP = content;
    }
  }
  private _eSignOTP: ESignOtpComponent;
  @ViewChild('eSignComponent') set content(content: ESignComponent) {
    if (content) { // initially setter gets called with undefined
      this.eSignComponent = content;
    }
  }
  private eSignComponent: ESignComponent;
  
  public smartCASign!: SmartCaComponent;
  @ViewChild('smartCASign', { static: false }) set AppSmartCASign(content: SmartCaComponent) {
    if (content) {
      this.smartCASign = content;
    }
  }
  
  @Input() 
  get contractData() { return this.contract; }
  set contractData(value) { this.contract = new ContractObject(value); }
  private contract: ContractObject = new ContractObject();
  @Input()
  get infoCAConfig() { return this.configCA; }
  set infoCAConfig(value) { this.configCA = value; this.setConfigSign(this.configCA) }; 
  configCA: InfoSignatureConfigObject = new InfoSignatureConfigObject();
  @Input()
  get fileContract() { return this.filePDF; }
  set fileContract(value) { this.filePDF = value; }
  filePDF: File = null;
  @Input()
  get sdt() { return this._sdt; }
  set sdt(value) { this._sdt = value; }
  _sdt: any = "";

  @Output() reload: EventEmitter<any> = new EventEmitter();
  @Output() signStepChanged: EventEmitter<any> = new EventEmitter();
  @Output() haveSessionEsign: EventEmitter<any> = new EventEmitter();
  @Output() changedSignType: EventEmitter<any> = new EventEmitter();
  @Output() changedSignForm: EventEmitter<any> = new EventEmitter();
  @Output() expiredSessionSmartCA: EventEmitter<any> = new EventEmitter();
  @Output() isWaitingConfirmSmartCA: EventEmitter<any> = new EventEmitter();

  get output(): HTMLInputElement {
    return  document.getElementById("output") as HTMLInputElement;
  }
  get filebase(): HTMLInputElement {
    return document.getElementById("fileBase")  as HTMLInputElement;
  }
  get filename(): HTMLInputElement {
    return document.getElementById("fileName")  as HTMLInputElement;
  }

  contractInternalData: ContractInternal[] = [];

  //Kiem duyet hop dong
  formCensorship: FormGroup;
  isSubmitFormCensoership = false;
  validFormCensorshipMsg = {
    partyId: {
      required: "ContractCensorship_ts_1"
    },
    userId: {
      required: "ContractCensorship_ts_2"
    }
  };
  
  dropListUserData: Observable<any>;
  usersLoading = false;
  usersInputs = new Subject<string>();

  dropListPartData : Observable<any>;
  partsLoading = false;
  partsInput$ = new Subject<string>();
  minLengthTerm = 3;
  
  modalSend: NgbModalRef = null;

  CensorshipStatus = CensorshipStatus;
  localize = localize;

  //MARK: Ky
  public signType = "";
  checkboxDk1 = false;
  checkboxDk2 = false;
  currentStepSignInternal: number = 0;
  img_prefix = "data:image/png;base64,";
  imgContent: string = '';
  imgSignServer: File = null;
  imgType = ['image/jpeg', 'image/png', 'image/jpg'];
  pdfSigned: Blob = null;
  keyTest: string = Config.KEY_PLUGIN_CA;

  currentSignBoxStyle: number = 5;

  formSign: FormGroup;
  isSubmit: boolean = false;
  validMsg: any =  {
    signerName: {},
    textColor: {},
    visibleType: {},
    base64Image: {},
    fontSize: {
      min: 'ContractCensorship_ts_3',
      max: 'ContractCensorship_ts_3',
      pattern: 'ContractCensorship_ts_4'
    },
    signBy: {},
    signDate: {},
    imageFile: {
      type: 'ContractCensorship_ts_5',
      size: 'ContractCensorship_ts_6'
    }
  };
  inputFileImageLabel: string = "ContractCensorship_ts_7";

  //OTP modal confirm sign
  otpSignForm: FormGroup;
  otpSignIsSubmit: boolean = false;
  modalOtpSign: any;
  validateMsgOTPSign = {
    otp: {
        required: "ContractCensorship_ts_8",
        minlength: "ContractCensorship_ts_9",
        maxlength: "ContractCensorship_ts_9"
    }
  };
  hinhThucHienThi = [
    { value: 0, name: this.translate.instant("ContractCensorship_ts_10") },
    { value: 1, name: this.translate.instant("ContractCensorship_ts_11") },
    { value: 2, name: this.translate.instant("ContractCensorship_ts_12") },
    { value: 3, name: this.translate.instant("ContractCensorship_ts_13") },
    { value: 4, name: this.translate.instant("ContractCensorship_ts_14") },
    { value: 5, name: this.translate.instant("ContractCensorship_ts_15") }
  ];

  formRejectInternal: FormGroup;
  isSubmitFormRejectInternal = false;
  validFormRejectInternal = {
    reason: {
      required: "ContractCensorship_ts_16"
    }
  }

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private contractService: AasContractService,
    private partService: PartService,
    private employeeService: EmployeeService,
    private authService: AuthService,
    private signatureService: SignatureService,
    private modalService: NgbModal,
    public myLoader: LoadingService,
    private myAlert: AlertControl,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.viewDidLoad();
  } 

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  viewDidLoad() {
    this.makeFormCensorship();
    this.makeFormSign();
    this.makeFormRejectInternal();
    this.makeOTPSignForm();
    this.loadPart();
    this.progressData_v2(this.contractData);
    this.getListUsbToken(this.authService.userId);
  }

  makeFormRejectInternal() {
    this.formRejectInternal = this.fb.group({
      reason: ['', [Validators.required]]
    });
  }

  makeFormCensorship() {
    this.formCensorship = this.fb.group({
      partyId: [null, [Validators.required]],
      userId: [null, [Validators.required]]
    })
  }
  
  public openPopupSendCensorship() {
    this.isSubmitFormCensoership = false;
    this.formCensorship.reset();
    this.formCensorship.patchValue({
      partyId: null,
      userId: null
    });
    this.modalSend = this.modalService.open(this.popupSendCensorship, { backdrop: "static" });
  }

  /**
   * Ket thuc kiem duyet
   */
  finishCensorship() {
    if (this.checkAllowFinishCensorship()) {
      this.myAlert.showAlert(this.translate.instant('ContractCensorship_ts_17'), "", true, "", this.translate.instant('btn_confirm'),
      () => {
        this.postFinishCensorshipInternal(this.contract.contractId);
      }, "", this.translate.instant('btn_cancel'), null, this.translate.instant('btn_confirm'));
    } else {
      this.myAlert.showAlertOnlyNoti(this.translate.instant('ContractCensorship_ts_18'), ConstantAlertType.ERROR);
    }
  }

  /**
   * Kiem tra co quyen ket thuc kiem duyet hay khong
   * @returns 
   */
  checkAllowFinishCensorship() {
    return this.checkAllowFinishCensorship_v2();
    if (!this.contract.contractId) return false;

    if (this.contract.contractInternalData.length <= 0) return false;

    let rootPartyId = this.authService.partyId || "";
    if (rootPartyId === "") return false;

    let partyAssignee = this.contract.signerId;
    if (partyAssignee !== rootPartyId) return false;

    let currentParty = this.contract.contractPartysData.find((item) => { return item.partyId === rootPartyId });
    if (!currentParty) return false;

    let currentPartyFinished = currentParty.internalFinish || false;
    if (currentPartyFinished) return false;

    let currentUserAssignee = currentParty?.internalSignerId || "";
    if (currentUserAssignee !== "" && currentUserAssignee !== this.authService.userId) return false;

    let allApprove = true;
    this.contract.contractInternalData.forEach((item, index) => {
      if (item.action === null || item.action === "") allApprove = false;
    });
    if (!allApprove) return false;

    return true;
  }

  changeUser($event) {
  }

  trackByFn(item: any) {
    return item.partyId;
  }

  trackByFn1(item: any) {
    return item.userId;
  } 

  /**
   * Tim kiem realtime droplist bo phan
   */
  loadPart() {
    this.dropListPartData = concat(
      this.partsInput$.pipe(
      startWith(''),
      debounceTime(200),
      distinctUntilChanged(),
      tap(() => { this.partsLoading = true; this.myLoader.disabledLoader(this.partsLoading); }),
      switchMap((term) => {
        let res = this.partService.getPartsV2(1, 20, "", "", "0", term).pipe(
          map((res: any) => res?.object?.data || []),
          catchError(() => of([])), // empty list on error
          tap(() => { this.partsLoading = false; this.myLoader.disabledLoader(this.partsLoading); })
        );
        return res;
      })
    ));
  }

  /**
   * Tim kiem realtiem droplist nhan su
   */
  loadEmployee() {
    this.usersLoading = true; this.myLoader.disabledLoader(this.usersLoading);
    this.employeeService.getListUserByPartyId(this.contract.contractId, this.formCensorship.value.partyId).subscribe((res: any) => {
      console.log(res.object.data);
      this.dropListUserData = of(res?.object?.data || []);
      this.usersLoading = false; this.myLoader.disabledLoader(this.usersLoading);
    }, err => {
      this.dropListUserData = of([]);
      this.usersLoading = false; this.myLoader.disabledLoader(this.usersLoading);
    });
  }

  /**
   * Gui hop dong cho ben kiem duyet
   */
  sendContractInternal() {
    this.isSubmitFormCensoership = true;
    if (this.formCensorship.valid) {
      this.myAlert.showAlert(this.translate.instant('ContractCensorship_ts_19'), ConstantAlertType.BLANK, true, "", this.translate.instant('btn_confirm'), () => {
        this.postSendCensorshipInternal(this.contract.contractId, this.formCensorship.value.userId);
      }, "", this.translate.instant('btn_cancel')); 
    }
  }

  //MARK: Duyet hop dong
  /**
   * Lay link anh icon status
   * @param status 
   * @returns 
   */
  imgStatus(status = null) {
    let msg = "assets/img/icon/icon-pending.svg";
    switch (status) {
      case "": 
        msg =  "assets/img/icon/icon-pending.svg";
        break;
      case CensorshipStatus.ACT_DRAFT_DSIGN_INTERNAL:
        msg = "assets/img/icon/icon-success.svg";
        break;
      case CensorshipStatus.ACT_DRAFT_ESIGN_INTERNAL:
        msg = "assets/img/icon/icon-success.svg";
        break;
      case CensorshipStatus.ACT_APPROVE_NO_SIGN_INTERNAL:
        msg = "assets/img/icon/icon-success.svg";
        break;
      case CensorshipStatus.ACT_DRAFT_TSIGN_INTERNAL:
        msg = "assets/img/icon/icon-success.svg";
        break;
      case CensorshipStatus.ACT_REJECT_INTERNAL:
        msg = "assets/img/icon/icon-danger.svg";
        break;
    }

    return msg;
  }

  /**
   * Trang thai da phe duyet noi bo
   * @param status 
   * @returns 
   */
  isApprovedInternal(status = null) {
    if (status && status !== CensorshipStatus.ACT_REJECT_INTERNAL && status !== CensorshipStatus.ACT_APPROVE_NO_SIGN_INTERNAL) return true; else return false;
  }

  /**
   * Trang thai da tu choi phe duyet no bo
   * @param status 
   * @returns 
   */
  isRejectInternal(status = null) {
    if (status && status === CensorshipStatus.ACT_REJECT_INTERNAL) return true; else return false;
  }

  /**
   * Lay class block trang thai phe duyet
   * @param status 
   * @returns 
   */
  getClassBorder(status = null, index) {
    let msg = "";
    switch (status) {
      case "": 
        if (this.contractInternalData[index].isCurrentUserSign) {
          msg = "border-primary";
        } else {
          msg = "border-secondary";
        }
        break;
      case CensorshipStatus.ACT_DRAFT_DSIGN_INTERNAL:
        msg = "border-success";
        break;
      case CensorshipStatus.ACT_DRAFT_ESIGN_INTERNAL:
        msg = "border-success";
        break;
      case CensorshipStatus.ACT_APPROVE_NO_SIGN_INTERNAL:
        msg = "border-success";
        break;
      case CensorshipStatus.ACT_DRAFT_TSIGN_INTERNAL:
        msg = "border-success";
        break;
      case CensorshipStatus.ACT_REJECT_INTERNAL:
        msg = "border-danger";
        break;
    }

    return msg;
  }

  descriptionStatus(status = null, signType = "") {
    let msg = "";
    if (signType == ConstSignType.DRAFT) {
      msg = this.translate.instant('internal_no_draft_sign');
    } else if (signType === ConstSignType.APPROVAL) {
      msg = this.translate.instant('internal_no_approve_sign');
    } else if (signType === ConstSignType.STAMP) {
      msg = this.translate.instant('internal_no_stamp_sign');
    }
    switch (status) {
      case CensorshipStatus.ACT_DRAFT_DSIGN_INTERNAL:
        if (signType === ConstSignType.APPROVAL)
          msg = this.translate.instant('signed_approve');
        else if (signType === ConstSignType.STAMP)
          msg = this.translate.instant('internal_stamp_signed');
        else 
          msg = this.translate.instant('INTERNAL_SIGN_APPROVED_CONTRACT');
        break;
      case CensorshipStatus.ACT_DRAFT_ESIGN_INTERNAL:
        if (signType === ConstSignType.APPROVAL)
          msg = this.translate.instant('signed_approve');
        else if (signType === ConstSignType.STAMP)
          msg = this.translate.instant('internal_stamp_signed');
        else
          msg = this.translate.instant('INTERNAL_SIGN_APPROVED_CONTRACT');
        break;
      case CensorshipStatus.ACT_APPROVE_NO_SIGN_INTERNAL:
        msg = this.translate.instant('INTERNAL_NO_SIGN_APPROVED_CONTRACT');
        break;
      case CensorshipStatus.ACT_DRAFT_TSIGN_INTERNAL:
        if (signType === ConstSignType.APPROVAL)
          msg = this.translate.instant('signed_approve');
        else if (signType === ConstSignType.STAMP)
          msg = this.translate.instant('internal_stamp_signed');
        else 
          msg = this.translate.instant('INTERNAL_SIGN_APPROVED_CONTRACT');
        break;
      case CensorshipStatus.ACT_REJECT_INTERNAL:
        msg = this.translate.instant('INTERNAL_REJECT_CONTRACT');
        break;
    }

    return msg;
  }

  //MARK: Action duyet
  kyNhayUsbToken() {
    // this.signType = "USB_TOKEN";
    // this.sign();
    this.removeSignBoxUsbtoken();
    this.getCertInforUsbtoken();
    PDFSign.setStyleSelectedSignBox(2);
  }

  kyNhayVNPTKySo() {
    this.signType = "SIGN_SERVER";
    this.signFormSelected = "SIGN_SERVER";
    this.changedSignForm.emit(this.signType);

    // this.nextStepSign(this.currentStepSignInternal);

    //gan mac dinh cho imgContent (Hinh anh chu ky mac dinh)
    this.imgContent = this.img_prefix + PDFSign.getData().imageSrc;

    if (this.configCA.certId === '') {
      this.getInfoCASignServer(this.authService.userId);
    } else {
      this.nextStepSign(this.currentStepSignInternal);
    }

    //Goi plugin cai dat thong so hinh chu ky, hinh anh chu ky, remove tat ca hinh chu ky tren man hinh
    PDFSign.setStyleSelectedSignBox(this.currentSignBoxStyle);
    if (this.imgContent !== '') {
      PDFSign.setImage(this.imgContent.split(',')[1]);
    } else {
      PDFSign.setImageDefault();
    }
    PDFSign.removeAllSignBox();

    this.showTemplateSignBox(this.contract.getSignFrameAssignee());
  }

  duyetKhongKy() {
    this.myAlert.showAlert(this.translate.instant('ContractCensorship_ts_20'), ConstantAlertType.BLANK, true, "", this.translate.instant('btn_confirm'),
    () => {
      this.postApproveInternalContract(this.contract.contractId);
    }, "", this.translate.instant('btn_cancel'));
  }

  modalRejectContractInternal: NgbModalRef = null;
  /**
   * Mo popup tu choi kiem duyet hop dong
   */
  tuChoiDuyet() {
    this.isSubmitFormRejectInternal = false;
    this.formRejectInternal.reset();
    this.modalRejectContractInternal = this.modalService.open(this.modalRejectInternal);
  }

  /**
   * Xac nhan tu choi kiem duyet hop dong
   */
  confirmRejectInternal() {
    this.isSubmitFormRejectInternal = true;
    if (this.formRejectInternal.valid) {
      this.postRejectedInternalContract(this.contract.contractId, this.formRejectInternal.value.reason);
    }
  }

  //MARK: Chuc nang ky so VNPT Ky so
  /**
   * Khoi tao Form ky
   */
  makeFormSign() {
    this.formSign = this.fb.group({
      signerName: new FormControl('',),
      textColor: new FormControl('',),
      visibleType: new FormControl(5),
      base64Image: new FormControl(""),
      fontSize: new FormControl("13", [Validators.min(8), Validators.max(20), Validators.pattern(/^[0-9]+$/)]),
      signBy: new FormControl(true),
      signDate: new FormControl(true),
      imageFile: new FormControl(""),
    });
  }

  /**
   * Khoi tao form OTP khi ky so VNPT ky so
   */
  makeOTPSignForm() {
    this.otpSignForm = this.fb.group({
      tranId: [''],
      otp: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]]
    });
  }

  /**
   * Gan lai buoc cua phien ky
   * @param index 
   */
  setStepSign(index) {
    console.log(index);
    this.currentStepSignInternal = index;
    if (this.signType === 'E_SIGN') {
      this.eSignComponent && this.eSignComponent.setStepSign(index);
    }
    if (index === 0) {
      // this.nextStepSign(this.currentStepSignInternal);
      this.signType = "";
      this.signFormSelected = "";
      this.changedSignType.emit(this.signType);
    };
    PDFSign.removeAllSignBox();

    this.signStepChanged.emit(this.currentStepSignInternal);
  }

  /**
   * Tro lai buoc ky truoc
   * @param index 
   */
  backStepSign(index) {
    if ((this.currentStepSignInternal === 0 || this.currentStepSignInternal === 1) && this.signType === "E_SIGN") {
      this.signType = "";
      this.signFormSelected = "";
      this.changedSignType.emit(this.signType);
      this.haveSessionEsignInternal = false;
      this.haveSessionEsign.emit(false);
    }
    if (index === 2) {
      PDFSign.preview(this.fileContract);
      this.getBase64String(this.fileContract, this.handle);
      
      if (this.signType === "E_SIGN") {
        this.kyDienTu(this.signFormSelected);
        this.currentStepSignInternal = 0;
        this.signStepChanged.emit(this.currentStepSignInternal);
        return;
      }

      if (this.signType == "USB_TOKEN") {
        this.removeSignBoxUsbtoken();
      }

      if (this.signType === "SMARTCA") {
        this.smartCASign && this.smartCASign.resign();
        this.showLayoutConfirmSmartCA = false;
        this.timeoutSmartCa = false;
      }

      if (this.signType == "SIGN_SERVER") {
        this.showTemplateSignBox(this.contract.getSignFrameAssignee());
      }
    };
    if (index - 1 >= 0) this.setStepSign(index - 1);
    if (index - 1 == 0) { 
      this.signType = ""; 
      this.signFormSelected = "";
      this.changedSignType.emit(this.signType); 
    }
    if (index - 1 < 0) {
      this.setStepSign(0);
    }
  }

  /**
   * Chuyen sang buoc ky tiep theo
   * @param index 
   */
  nextStepSign(index) {
    this.setStepSign(index + 1);
  }

  /**
   * Luu phien them chu ky va chuyen sang buoc tiep theo
   */
  savedSignature() {
    this.nextStepSign(this.currentStepSignInternal);
  }

  /**
   * Gan du lieu form ky tu he thong
   * @param signConfig 
   */
  setConfigSign(signConfig: InfoSignatureConfigObject) {
    console.log(signConfig);
    this.formSign.controls.signerName.setValue(signConfig.signerName || "");

    this.formSign.controls.visibleType.setValue(signConfig.visibleType || 5);
    this.currentSignBoxStyle = Number(this.formSign.controls.visibleType.value) || 5 ;
    PDFSign.setStyleSelectedSignBox(Number(this.formSign.controls.visibleType.value) || "5");

    this.formSign.controls.base64Image.setValue(signConfig.base64Image || default_img);
    PDFSign.setImage(this.formSign.controls.base64Image.value || default_img);
    this.imgContent = this.img_prefix + (signConfig.base64Image || default_img);

    this.formSign.controls.fontSize.setValue(signConfig.fontSize || "13");
    PDFSign.setFontSize(this.formSign.controls.fontSize.value || "13")

    this.formSign.controls.signBy.setValue(signConfig.signBy || false);
    PDFSign.setShowSignBy(this.formSign.controls.signBy.value || false);

    this.formSign.controls.signDate.setValue(signConfig.signDate || false);
    PDFSign.setShowDateSign(this.formSign.controls.signDate.value || false);
  }

  /**
   * Su kien tai len anh chu ky (Sign server)
   * @param e 
   */
  handleFileImageInput(e) {
    this.imgSignServer = e.target.files && e.target.files.item(0);
    if (this.imgSignServer && this.imgSignServer !== null && this.imgSignServer !== undefined) {
      this.inputFileImageLabel = this.imgSignServer.name;

      if (!this.imgType.includes(this.imgSignServer.type)) {
        this.formSign.controls.imageFile.setErrors({ type: true });
        this.imgContent = '';
      } else if (this.imgSignServer.size > 2000000) {
        this.formSign.controls.imageFile.setErrors({ size: true });
        // this.getBase64String(this.imgSignServer, this.handleImgSignServer);
      } else {
        this.getBase64String(this.imgSignServer, this.handleImgSignServer);

        var self = this;
        var u = URL.createObjectURL(this.imgSignServer);
        var img = new Image;
        img.src = u;
        img.onload = function () {
          ImageProgress.resizeImage(img, self.imgSignServer, 999, 999, (file) => {
            self.imgSignServer = file;
          });
        }
      }
    } else {
      this.imgContent = '';
      this.inputFileImageLabel = "ContractCensorship_ts_7";
    }
  }

  /**
   * CallBack nhan file sau khi resize
   * @param file 
   */
  callBackResize(file) {
    this.imgSignServer = file;
  }

  /**
   * Doc file chuyen sang base64
   * @param blob 
   * @param callback 
   */
  getBase64String(blob, callback) {
    var self = this;
    var reader = new FileReader();
    reader.onload = function (e) {
      callback(reader.result, self);
    }
    reader.readAsDataURL(blob);
  }

  /**
   * Ham callback khi doc noi dung file de chuyen sang base64
   * @param str 
   * @param self 
   */
  handleImgSignServer(str, self) {
    self.imgContent = str;
    self.formSign.controls.base64Image.setValue(str.split(',')[1]);
    PDFSign.setImage(str.split(',')[1]);
  }

  /**
   * Them 1 chu ky len preview
   */
  addSignBox() {
    PDFSign.addSignBox();
  }

  /**
   * Su kien thay doi fontSize chu ky
   * @param event 
   */
  inputFontSize(event) {
    PDFSign.setFontSize(event.target.value)
  }

  /**
   * Su kien thay doi gia tri CheckBox Ngay ky
   * @param event 
   */
  changedDateSign(event) {
    PDFSign.setShowDateSign(this.formSign.value.signDate);
  }

  /**
   * Su kien thay doi gia tri CheckBox Ky boi
   * @param event 
   */
  changedSignBy(event) {
    PDFSign.setShowSignBy(this.formSign.value.signBy);
  }

  //Chọn hình thức hiển thị chữ ký
  selectStyleSignBox(e) {
    this.currentSignBoxStyle = Number(e.value);
    if (this.signType === "USB_TOKEN") {
      PDFSign.setStyleSelectedSignBox(Number(e.value) + 1);
    } else {
      PDFSign.setStyleSelectedSignBox(Number(e.value));
    }
  }

  //Hoàn thanh ký số sign server
  finishSignServer() {
    let options: Array<any> = PDFSign.getOptions();
    let listPosition: Array<any> = [];

    if (!this.checkAllowSign()) {
      this.myAlert.showAlertOnlyNoti(this.translate.instant('ContractCensorship_ts_21'), ConstantAlertType.ERROR);
      return;
    }

    if (options.length <= 0) {
      this.myAlert.showAlertOnlyNoti(this.translate.instant('ContractCensorship_ts_22'), ConstantAlertType.ERROR);
      return;
    }

    for (var i = 0; i < options.length; i++) {
      let x = options[i].x;
      let y = options[i].y - options[i].h;
      let w = options[i].w + x;
      let h = options[i].h + y;
      let page = options[i].page;

      listPosition.push({
        page: page,
        rectangle: `${x},${y},${w},${h}`
      })
    }

    let param =
    {
      "userId": this.authService.userId,
      "base64file": this.filebase.value,
      "listPosition": listPosition,
      "signerBy": this.formSign.value.signBy,
      "signerDate": this.formSign.value.signDate,
      "fontSize": Number(this.formSign.value.fontSize),
      "visibleType": Number(this.formSign.value.visibleType),
      "base64Image": this.formSign.value.base64Image
    }

    this.postSignServer(param);
  }

  /**
   * Kiem co quyen ky hay khong
   * @returns 
   */
  checkAllowSign() {
    return true;
  }

  //MARK: CHỨC NĂNG KÝ SỐ USB TOKEN
  /**
   * Kiem tra licenseKy Token
   */
  checkPlugin() {
    const key = this.keyTest;
    var self = this;
    vnpt_plugin.checkPlugin().then(function (data) {
      if (data === "1") {
        vnpt_plugin.setLicenseKey(key).then((data) => {
          try {
            var jsonParse = JSON.parse(data);
            if (jsonParse && jsonParse.code === -1) {
              self.myAlert.showAlertOnlyNoti(this.translate.instant('ContractCensorship_ts_23'), "");
            }
          } catch (e) {
            return;
          }
        });
      }
    }).catch(function (e) {
      console.log(e);
      self.myAlert.showAlertOnlyNoti(this.translate.instant('ContractCensorship_ts_24'), "");
    });
  }

  //Action ký usb token
  sign() {
    this.showLoader();
    this.output.value = "";
    var dataInput = this.filebase.value;
    if (dataInput.length === 0) {
      this.hideLoader();
      this.myAlert.showAlertOnlyNoti(this.translate.instant('ContractCensorship_ts_25'), ConstantAlertType.ERROR);
      return;
    }
    var fileName = this.filename.value;
    console.log(fileName);
    var fileSplit = fileName.split(".");
    var fileExtension = fileSplit[fileSplit.length - 1].toLowerCase();
    if (fileExtension !== "xml" && fileExtension !== "pdf" && fileExtension !== "docx"
      && fileExtension !== "xlsx" && fileExtension !== "pptx" && fileExtension !== "txt") {
      this.hideLoader();
      this.myAlert.showAlertOnlyNoti(this.translate.instant('ContractCensorship_ts_26'), ConstantAlertType.ERROR);
      return;
    }
    var sigOptions = null;
    if (fileExtension === "pdf") {
      sigOptions = new PdfSigner();
      sigOptions.AdvancedCustom = true;
    }
    this.SignAdvanced(dataInput, fileExtension, sigOptions, this.showMessage);
  }

  //Bắt đầu kí
  SignAdvanced(data, type, option, callback) {
    var dataJS = {};
    var arrData = [];
    // 1			
    dataJS["data"] = data;
    dataJS["type"] = type;
    dataJS["sigOptions"] = JSON.stringify(option);

    var jsData = "";
    jsData += JSON.stringify(dataJS);
    //
    arrData.push(jsData);

    var vm = this;
    // vnpt_plugin.CheckToken().then((data) => {
    //   console.log("check token ==> ", data);
    //   if (data === "1") {
        vnpt_plugin.getCertInfo(null, true).then((data) => {
          if (data) {
            var certData = JSON.parse(data)
            console.log(certData)
            var serial = certData["serial"];
            vnpt_plugin.signArrDataAdvanced(arrData, serial, true).then((signData) => {
              vm.showMessage(signData);
            }).catch(function (e) {
              alert(e);
              vm.hideLoader();
            });
          } else {
            this.showLoader();
            this.myAlert.showAlertOnlyNoti(this.translate.instant('ContractCensorship_ts_27'), ConstantAlertType.ERROR);
          }
        })
    //   } else {
    //     this.hideLoader();
    //     this.myAlert.showAlertOnlyNoti(localize.not_found_usb_token, ConstantAlertType.ERROR);
    //   }
    // });
  }

  //Show message response
  showMessage(data) {
    this.hideLoader();
    var jsOb = JSON.parse(JSON.parse(data)[0]);
    var err = '';
    switch (jsOb.code) {
      case 0:
        this.pdfSigned = ImageProgress.converBase64toBlob(jsOb.data, 'application/pdf');

        err = "ContractCensorship_vnptplugin_error_1";
        break;
      case 1:
        err = "ContractCensorship_vnptplugin_error_2";
        break;
      case 2:
        err = "ContractCensorship_vnptplugin_error_3";
        break;
      case 3:
        err = "ContractCensorship_vnptplugin_error_4";
        break;
      case 4:
        err = "ContractCensorship_vnptplugin_error_5";
        break;
      case 5:
        err = "ContractCensorship_vnptplugin_error_6";
        break;
      case 6:
        err = "ContractCensorship_vnptplugin_error_7";
        break;
      case 7:
        err = "ContractCensorship_vnptplugin_error_8";
        break;
      case 8:
        err = "ContractCensorship_vnptplugin_error_9";
        break;
      case 9:
        err = "ContractCensorship_vnptplugin_error_10";
        break;
      case 10:
        err = "ContractCensorship_vnptplugin_error_11";
        break;
      case 11:
        err = "ContractCensorship_vnptplugin_error_12";
        break;
      case 13:
        err = "ContractCensorship_vnptplugin_error_13";
        break;
      default:
        err = "ContractCensorship_vnptplugin_error_6";
        break;
    }
    if (jsOb.code !== 0) {
      this.output.value = err;
      this.myAlert.showAlertOnlyNoti(this.translate.instant(err), ConstantAlertType.ERROR);
    } else {
      PDFSign.preview(this.pdfSigned);
      this.getBase64String(this.pdfSigned, this.handle);
      this.setStepSign(2);
      this.myAlert.showAlert(this.translate.instant(err), ConstantAlertType.SUCCESS, false, '', this.translate.instant('btn_ok'), () => {
      });
    }
  }

  //MARK: Ky so VNPT ky so co OTP
  /*
  * Tạo modal form otp sign-server
  */
   createModalOTPSignForm() {
    this.otpSignIsSubmit = false;
    this.modalOtpSign = this.modalService.open(this.otpConfirmSign, { backdrop: 'static' });
  }

  /**
   * Đóng modal form otp sign-server
   */
  closeModalOTPSignForm() {
    this.modalOtpSign && this.modalOtpSign.close();
  }

  /**
   * Submit comfirm OTP sign server
   */
  submitConfirmOTPSign() {
    this.otpSignIsSubmit = true;

    if (this.otpSignForm.valid) {
      this.closeModalOTPSignForm();
      this.postConfirmOTPSignServer(this.otpSignForm.value);
    }
  }

  /**
   * Bắt sự kiện nhập OTP
   * @param event 
   */
   handleInputOTP(event) {
    const regex = new RegExp(/^\d+$/);
    if (regex.test(event.target.value) && event.target.value.length <= 6) {
      return;
    } else {
      this.otpSignForm.controls.otp.setValue(event.target.value.slice(0, -1));
    }
  }

  /**
   * Hoan thanh sumit file hop dong vua ky
   */
  endSignInternal() {
    if (this.pdfSigned !== null && this.pdfSigned !== undefined) {
      let data = new FormData();
      data.append('file', this.pdfSigned, this.contract.contractId + ".pdf");
      data.append('data', JSON.stringify({ SignForm: this.signFormSelected }));
      this.postSubmitSignInternal(this.contract.contractId, data);
    } else {
      this.myAlert.showAlertOnlyNoti(this.translate.instant('ContractCensorship_ts_28'), ConstantAlertType.ERROR);
    }
  }

  /**
   * Hien loader
   */
  showLoader() {
    this.myLoader.setVisible(true);
  }

  /**
   * 
   * An loader
   */
  hideLoader() {
    this.myLoader.setVisible(false);
  }

  //MARK: NETWORKING
  postSendCensorshipInternal(contractId, userId) {
    this.contractService.postSendCensorshipInternal(contractId, userId).subscribe((res: any) => {
      this.myAlert.showAlertOnlyNoti(this.translate.instant('ContractCensorship_ts_29'), ConstantAlertType.SUCCESS);
      this.reload.emit(null);
      this.modalSend && this.modalSend.close();
    }, err => {
      this.myAlert.showAlertOnlyNoti(this.translate.instant('ContractCensorship_ts_30'), ConstantAlertType.ERROR);
      this.modalSend && this.modalSend.close();
    })
  }

  //Lấy thông tin chữ ký số user
  getInfoCASignServer(id) {
    this.signatureService.getInfoCASignServer(id).subscribe(res => {
      console.log(res);
      if (res['object'] == null) {
        this.myAlert.showAlert(this.translate.instant('ContractCensorship_ts_31'), ConstantAlertType.BLANK, true, '', this.translate.instant('btn_goto_vnpt_sign_config'), 
        () => {
          window.open(this.router.parseUrl(ConstantUrl.CA_MANAGER).toString(), "_blank");
          // this.router.navigate(['/app/console/signature-manager']);
        }, '', this.translate.instant('btn_cancel'));
      } else {
        let infoCA = new InfoCASignServerResponse(res).object;
        this.getInfoSignatureConfig(infoCA.certificateDefault.id);
        this.nextStepSign(this.currentStepSignInternal);
      }
    }, err => {
      console.log(err);
      this.myAlert.showErrorHandled(err);
    }, () => {
    })
  }

  //Lấy thông tin config chữ ký số sign server
  getInfoSignatureConfig(id) {
    this.signatureService.getInfoSignatureConfig(id).subscribe(res => {
      console.log(res);
      this.configCA = new InfoSignatureConfigResponse(res).object;
      this.setConfigSign(this.configCA);
    }, err => {
      console.log(err);
      this.myAlert.showErrorHandled(err);
    })
  }

  /**
   * Tien hanh ky so
   * @param data 
   */
  postSignServer(data) {
    this.contractService.postSignServer(data).subscribe((res: any) => {
      if (res && res.object && res.object.status && res.object.status === 'OTP_CONFIRM') {
        this.otpSignForm.reset();
        this.otpSignForm.patchValue({
          tranId: res.object.tranId || ''
        });

        this.createModalOTPSignForm();
      } else {
        let fileBase64 = res['object']['fileBase64'];
        let blob = ImageProgress.converBase64toBlob(fileBase64, 'application/pdf');
        this.pdfSigned = blob;
        PDFSign.preview(blob);
        PDFSign.removeAllSignBox();
        this.savedSignature();
        this.myAlert.showAlertOnlyNoti(this.translate.instant('ContractCensorship_ts_32'), ConstantAlertType.SUCCESS);
      }
    }, err => {
      console.log(err);
      if (err.error && err.error.message && err.error.message === 'ECT-00000016') {
        this.myAlert.showAlert(this.translate.instant('ContractCensorship_ts_33'), ConstantAlertType.SUCCESS, true, '', 
        this.translate.instant('btn_relogin'), 
        () => {
          window.open(location.origin + '/app/console/signature-manager', "_blank");
        }, '', this.translate.instant('btn_cancel'));
      } else {
        this.myAlert.showErrorHandled(err);
      }
    })
  }

  /**
   * Confirm OTP khi ky sign server
   * @param param 
   */
   postConfirmOTPSignServer(param) {
    this.contractService.postConfirmOTPSignServer(param).subscribe((res: any) => {
      let fileBase64 = res['object']['fileBase64']
      let blob = ImageProgress.converBase64toBlob(fileBase64, 'application/pdf')
      this.pdfSigned = blob
      PDFSign.preview(blob)
      PDFSign.removeAllSignBox()
      this.savedSignature();
      this.myAlert.showAlertOnlyNoti(this.translate.instant('ContractCensorship_ts_32'), ConstantAlertType.SUCCESS);
    }, err => {
      console.log(err);
      this.createModalOTPSignForm();
      this.myAlert.showErrorHandled(err);
    })
  }

  /**
   * Gui yeu cau duyet khong ky hop dong (noi bo)
   * @param contractId 
   */
  postApproveInternalContract(contractId) {
    this.contractService.postApproveInternalContract(contractId).subscribe((res: any) => {
      this.myAlert.showAlertOnlyNoti(this.translate.instant('ContractCensorship_ts_34'), ConstantAlertType.SUCCESS);
      // this.reload.emit(null);
      // this.currentStepSignInternal = 0; this.signStepChanged.emit(0);
      // this.signType = "";
      // this.changedSignType.emit(this.signType);
      this.resetSignSession();
    }, err => {
      console.log(err);
      this.myAlert.showErrorHandled(err);
    })
  }

  /**
   * gui yeu cau tu choi kiem duyethop dong
   * @param contractId 
   * @param param 
   */
  postRejectedInternalContract(contractId, param) {
    this.contractService.postRejectedInternalContract(contractId, param).subscribe((res: any) => {
      this.myAlert.showAlertOnlyNoti(this.translate.instant('ContractCensorship_ts_35'), ConstantAlertType.SUCCESS);
      // this.reload.emit(null);
      this.resetSignSession();
      this.modalRejectContractInternal && this.modalRejectContractInternal.close();
    }, err => {
      console.log(err);
      this.modalRejectContractInternal && this.modalRejectContractInternal.close();
      this.myAlert.showErrorHandled(err);
    })
  }

  /**
   * gui yeu cau submit file hop dong da ky nhay noi bo
   * @param contractId 
   * @param formData 
   */
  postSubmitSignInternal(contractId, formData: FormData) {
    this.contractService.postSubmitSignInternal(contractId, formData).subscribe((res: any) => {
      let msg = this.translate.instant('ContractCensorship_ts_36');
      if (this.contractData.getSignTypeOfCurrentUser() === ConstSignType.APPROVAL) {
        msg = this.translate.instant('ContractCensorship_ts_37');
      }
      this.myAlert.showAlertOnlyNoti(msg, ConstantAlertType.SUCCESS);
      // this.reload.emit(null);
      // this.currentStepSignInternal = 0; this.signStepChanged.emit(0);
      // this.signType = "";
      // this.changedSignType.emit(this.signType);
      this.resetSignSession();
    }, err => {
      console.log(err);
      this.myAlert.showErrorHandled(err);
    })
  }

  /**
   * Gui yeu cau ket thuc kiem duyet hop dong
   * @param contractId 
   */
  postFinishCensorshipInternal(contractId) {
    this.contractService.postFinishCensorshipInternal(contractId).subscribe((res: any) => {
      this.myAlert.showAlertOnlyNoti(this.translate.instant('ContractCensorship_ts_38'), ConstantAlertType.SUCCESS);
      this.reload.emit(null);
    }, err => {
      console.log(err);
      this.myAlert.showErrorHandled(err);
    })
  }

  //UPDATE V2
  currentInternalSign = null;
  yourSequenceSign: any[] = [];
  progressData() {
    this.yourSequenceSign = [];
    this.contractInternalData = Array.from(this.contractData.contractInternalData);
    let lengthInternalData = this.contractInternalData.length;

    //Danh dau nguoi ky hien tai
    if (lengthInternalData > 0 && this.contractInternalData[lengthInternalData - 1].action == "") {
      this.contractInternalData[lengthInternalData - 1].isCurrentUserSign = true;
      this.currentInternalSign = this.contractInternalData[lengthInternalData - 1];
    }

    for (let i = 0; i < lengthInternalData; i++) {
      let x = this.contractInternalData[i];
      x.sequence = i + 1; 

      if (x.userId == this.authService.userId) {
        this.yourSequenceSign.push(x.sequence);
      }
    }

    this.contractInternalData = this.contractInternalData.sort((a, b) => { return a.sequence - b.sequence });
  }

  // get currentUserSign() {
  //   let lengthInternalData = this.contractData.contractInternalData.length;
  //   let lastUserSign = this.contractData.contractInternalData[lengthInternalData - 1];
  //   return lastUserSign.action == "" && lastUserSign.userId == this.authService.userId;
  // }

  // isCurrentUserSign(index) {
  //   let lengthInternalData = this.contractData.contractInternalData.length;
  //   return index == lengthInternalData - 1;
  // }

  progressData_v2(contractData: ContractObject) {
    this.yourSequenceSign = [];
    this.contractInternalData = [];
    
    if (contractData.signFlow.length > 0) {    
      let signFlow = contractData.signFlow.sort((a, b) => { return a.sequence - b.sequence });
      let internalDataClone = Array.from(contractData.contractInternalData);
      let isNotRequiredFlow = contractData.signFlowType == SignFlowTypes.NO_REQUIRE;

      let index_approved = internalDataClone.findIndex((x) => { 
        return (x.signType === ConstSignType.APPROVAL && isNotRequiredFlow && x.action !== "");
      })
      let approved = null;
      if (index_approved >= 0) {
        //Loại bỏ record ký duyệt giữa chừng
        approved = new ContractInternal(internalDataClone[index_approved]);
        let x = signFlow.find((i) => { 
          return (i.signType === ConstSignType.APPROVAL && isNotRequiredFlow); 
        })
        if (x) {
          approved.limitDate = x.limitDate || 0;
          approved.sequence = x.sequence || 0;
          approved.signType = x.signType || "";
          approved.fullName = x.fullName || "";
          approved.signForm = x.signForm || [];
        }
        internalDataClone.splice(index_approved, 1);
      }
    
      let n_internalDataClone = internalDataClone.length;
      //Danh dau nguoi ky hien tai
      if (index_approved < 0 && n_internalDataClone > 0 && internalDataClone[n_internalDataClone - 1].action == "") {
        internalDataClone[n_internalDataClone - 1].isCurrentUserSign = true;
        this.currentInternalSign = internalDataClone[n_internalDataClone - 1];
      }      

      for (let i = 0; i < n_internalDataClone; i++) {
        let x = internalDataClone[i];
        let y = signFlow[i];
  
        x.limitDate = y.limitDate || 0;
        x.sequence = y.sequence || 0;
        x.signType = y.signType || "";
        x.fullName = y.fullName || "";
        x.signForm = y.signForm || [];
  
        if (y.userId == this.authService.userId) {
          this.yourSequenceSign.push(y.sequence);
        }
      }
  
      for (let i = n_internalDataClone; i < signFlow.length; i++) {
        let x = signFlow[i];
        internalDataClone.push(new ContractInternal(x));
        console.log(x);
        if (x.userId == this.authService.userId) {
          this.yourSequenceSign.push(x.sequence);
        }
      }

      // Gán lại bước user ký duyệt
      if (index_approved >= 0) {
        console.log(approved)
        internalDataClone[internalDataClone.length - 1] = new ContractInternal(approved);
      }
      
      this.contractInternalData = Array.from(internalDataClone.sort((a, b) => { return a.sequence - b.sequence }));
      console.log(this.contractInternalData);
    } else {
      this.progressData();
    }
  }

  checkAllowFinishCensorship_v2() {
    return this.contractData.thisUserAllowedFinishSignInternal;
  }

  get isOwnerParty() {
    return this.contract.ownerPartyId === this.authService.partyId;
  }


  //UPDATE CAC HINH THUC KI MOI CHO NOI BO

  haveSessionEsignInternal = false;
  signFormSelected: any = "";

  //Hanh dong ky dien tu
  kyDienTu(signForm) {
    this.signType = "E_SIGN";
    this.signFormSelected = signForm;
    this.changedSignType.emit(this.signType);
    this.haveSessionEsignInternal = true;
    this.haveSessionEsign.emit(true);
    setTimeout(() => {//Settimeout dam bao du thoi gian de init view eSignConmponent
      this.eSignComponent && (this.eSignComponent.signFrame = this.contract.getSignFrameAssignee())
      this.eSignComponent && this.eSignComponent.kyDienTu(signForm);
    }, 100)
  }

  //Bat su kien thay doi buoc thuc hien
  eSignChangedStep(event) {
    this.currentStepSignInternal = event;
    this.signStepChanged.emit(event);
  }

  //Xac nhan OTP
  confirmOTPInternalSign() {
    this.eSignComponent && this.eSignComponent.callActionSubmitOTP();
  }

  //Bat su kien co phien ky moi
  haveSessionEsignEvent(e) {
    this.haveSessionEsignInternal = e;
    this.haveSessionEsign.emit(e);
  }

  //Gui yeu cau phien ky dien tu
  sendRequestElectronicSign() {
    this.eSignComponent && this.eSignComponent.sendRequestElectronicSign();
  }

  //Ban su kien pdf da ky ve component cha
  returnSignedFileEvent(e) {
    this.pdfSigned = e || null;
  }

  //Lam moi lai phien ky
  resetSignSession() {
    this.currentStepSignInternal = 0; this.signStepChanged.emit(0);
    this.signType = "";
    this.signFormSelected = "";
    this.haveSessionEsignInternal = false;
    this.changedSignType.emit(this.signType);
    this.reload.emit(null);
  }
  //MARK Ky usbtoken cho Macos
  listSignatureUsb = [];
  signatureUsb = { 
    x: 0,
    y: 0,
    w: 0,
    h: 0,
    page: 1,
    addedSignBox: false 
  };
  currentPage = 1;
  certSerialSelected = "";
  hinhThucHienThi_usbtoken = [
    { value: 0, name: this.translate.instant("ServiceContractDetail_usbtoken_signType_onlyDescrip") },
    { value: 1, name: this.translate.instant("ServiceContractDetail_usbtoken_signType_imageDescrip") },
    { value: 2, name: this.translate.instant("ServiceContractDetail_usbtoken_signType_onlyImage") }
  ];

  usbtokenAddSignBox() {
    PDFSign.addSignBox();
    let options: Array<any> = PDFSign.getOptions();
    console.log(options);
    this.listSignatureUsb = options;
    if (this.listSignatureUsb.length > 0) {
      this.signatureUsb.x = this.listSignatureUsb[0].x || 0;
      this.signatureUsb.y = this.listSignatureUsb[0].y || 0;
      this.signatureUsb.w = this.listSignatureUsb[0].w || 0;
      this.signatureUsb.h = this.listSignatureUsb[0].h || 0;
      this.signatureUsb.page = this.listSignatureUsb[0].page || 0;
      this.signatureUsb.addedSignBox = true;
    }
  }

  getCurrentPage() {
    this.currentPage = PDFSign.getCurrentPage();
    return this.currentPage;
  }

  removeSignBoxUsbtoken() {
    PDFSign.removeAllSignBox();
    this.signatureUsb.addedSignBox = false;
  }

  pushSigUsbToken() {
    this.removeSignBoxUsbtoken();
    if (this.currentStepSignInternal - 1 >= 0) this.setStepSign(this.currentStepSignInternal - 1);
  }

  signUsbToken() {
    this.showLoader();
    this.output.value = "";
    var dataInput = this.filebase.value;
    if (dataInput.length === 0) {
      this.hideLoader();
      this.myAlert.showAlertOnlyNoti(this.translate.instant('ContractCensorship_ts_25'), 'error');
      return;
    }
    var fileName = this.filename.value;
    console.log(fileName);
    var fileSplit = fileName.split(".");
    var fileExtension = fileSplit[fileSplit.length - 1].toLowerCase();
    if (fileExtension !== "xml" && fileExtension !== "pdf" && fileExtension !== "docx"
      && fileExtension !== "xlsx" && fileExtension !== "pptx" && fileExtension !== "txt") {
      this.hideLoader();
      this.myAlert.showAlertOnlyNoti(this.translate.instant('ContractCensorship_ts_26'), 'error');
      return;
    }
    var sigOptions = null;
    if (fileExtension === "pdf") {
      let options: Array<any> = PDFSign.getOptions();
      this.listSignatureUsb = options;
      if (this.listSignatureUsb.length > 0) {
        this.signatureUsb.x = this.listSignatureUsb[0].x || 0;
        this.signatureUsb.y = this.listSignatureUsb[0].y || 0;
        this.signatureUsb.w = this.listSignatureUsb[0].w || 0;
        this.signatureUsb.h = this.listSignatureUsb[0].h || 0;
        this.signatureUsb.page = this.listSignatureUsb[0].page || 0;
        this.signatureUsb.addedSignBox = true;
      } else {
        this.myAlert.showAlertOnlyNoti(this.translate.instant('ContractCensorship_ts_39'), ConstantAlertType.ERROR);
        this.hideLoader();
        return;
      }

      sigOptions = new PdfSigner();
      sigOptions.AdvancedCustom = false;
      sigOptions.ValidationOption = false;
      sigOptions.SigTextSize = Number(this.formSign.value.fontSize);
      sigOptions.ImageBase64 = this.formSign.value.base64Image || default_img;
      sigOptions.SigType = Number(this.formSign.value.visibleType) || 0;
      sigOptions.SigSignerVisible = this.formSign.value.signBy || false;
      sigOptions.SigSigningTimeVisible = this.formSign.value.signDate || false;
      sigOptions.page = this.signatureUsb.page;
      sigOptions.llx = this.signatureUsb.x;
      sigOptions.lly = this.signatureUsb.y - this.signatureUsb.h;
      sigOptions.urx = this.signatureUsb.x + this.signatureUsb.w;
      sigOptions.ury = this.signatureUsb.y;
      sigOptions.SigEmailVisible = false;
      // sigOptions.ValidationOption = true;

      console.log(sigOptions);
    }
    this.SignAdvanced_V2(dataInput, fileExtension, sigOptions, this.showMessage);
  }

  //Bắt đầu kí usbtoken
  SignAdvanced_V2(data, type, option, callback) { console.log("signAdvanced_v2 - option: ", option)
    var dataJS = {};
    var arrData = [];
    // 1			
    dataJS["data"] = data;
    dataJS["type"] = type;
    dataJS["sigOptions"] = JSON.stringify(option);

    var jsData = "";
    jsData += JSON.stringify(dataJS);
    //
    arrData.push(jsData);

    var vm = this;
    vnpt_plugin.signArrDataAdvanced(arrData, this.certSerialSelected, true).then((signData) => {
      vm.showMessage(signData);
    }).catch(function (e) {
      alert(e);
      vm.hideLoader();
    });
  }

  getCertInforUsbtoken() {//Lay thong tin chung thu so
    let vm = this;
    this.myLoader.showElement();
    vnpt_plugin.getCertInfo(null, true).then((data) => {
      if (data) {
        var certData = JSON.parse(data)
        console.log(certData);
        var serial = certData["serial"];
        vm.certSerialSelected = serial;
        vm.signType = "USB_TOKEN";
        vm.signFormSelected = "USB_TOKEN";
        vm.changedSignForm.emit(vm.signType);
        vm.nextStepSign(vm.currentStepSignInternal);

        //Set cac gia trị mac dinh, khi chua co config
        this.imgContent = this.img_prefix + PDFSign.getData().imageSrc;
        this.formSign.patchValue({
          visibleType: 1,
          base64Image: default_img,
          fontSize: 8,
          signBy: true,
          signDate:true
        });
        // PDFSign.setStyleSelectedSignBox(Number(this.formSign.controls.visibleType.value) + 1);

        //Lay thong tin cau hinh da cai dat cho usbtoken
        var usbConfig = vm.listUsbToken.find((item, index) => {
          return (item.serial === serial);
        });
        console.log(usbConfig);

        if (usbConfig) {
          //Neu ton tai cai hinh thi set cau hinh hien thi
          this.formSign.patchValue({
            visibleType: usbConfig.signatureDefault.visibleType || 0,
            base64Image: usbConfig.signatureDefault.base64Image || PDFSign.getData().imageSrc,
            fontSize: usbConfig.signatureDefault.fontSize || 8,
            signBy: usbConfig.signatureDefault.signBy || false,
            signDate: usbConfig.signatureDefault.signDate || false
          });
          usbConfig.signatureDefault.base64Image && (this.imgContent = this.img_prefix + usbConfig.signatureDefault.base64Image);
        }
        
        PDFSign.setImage(this.imgContent.split(',')[1]);
        PDFSign.setFontSize(this.formSign.value.fontSize);
        PDFSign.setShowSignBy(this.formSign.value.signBy);
        PDFSign.setShowDateSign(this.formSign.value.signDate);
        PDFSign.setStyleSelectedSignBox(Number(this.formSign.controls.visibleType.value) + 1);
      } else {
        vm.myAlert.showAlertOnlyNoti(this.translate.instant('ContractCensorship_ts_27'), 'error');
        vm.backStepSign(vm.currentStepSignInternal);
      }
      this.myLoader.hideElement();
    })
  }

  //handel cho file pdf
  handle(str, self) {
    $('#fileBase').val(str.split(',')[1]);
  }

  saveSig() {
    if (this.signType == "SIGN_SERVER") {
      this.finishSignServer();
    }

    if (this.signType == "USB_TOKEN") {
      this.signUsbToken();
    }

    if (this.signType == "SMARTCA") {
      this.signBySmartCA();
    }
  }

  cancelSign(currentStepSignInternal) {
    if (this.signType == "USB_TOKEN") {
      this.signType = "";
      this.signFormSelected = ""
      this.currentStepSignInternal = 0;
      this.signStepChanged.emit(this.currentStepSignInternal);
      PDFSign.preview(this.fileContract);
      this.getBase64String(this.fileContract, this.handle);
    } else {
      this.backStepSign(currentStepSignInternal);
    }
  }

  //usb token config
  listUsbToken: UsbTokenItem[] = [];
  listUsbTokenBase: UsbTokenItem[] = [];
  usbTokenSelected: UsbTokenItem = new UsbTokenItem();
  
  getListUsbToken(id) {
    this.signatureService.getUsbTokenOfUser(id).subscribe(res => {
      this.listUsbToken = new ListUsbTokenResponse(res).data;
    }, err => {
      console.log(err);
    });
  }

  //Ky lai
  resign(step) {
    if (this.signType == "USB_TOKEN" && step === 2) {
      //Neu la buoc 2 cua ky = usbtoken
      //Confirm truoc khi thao tac
      this.myAlert.showAlert(this.translate.instant('ContractCensorship_ts_40'), "", true, "", this.translate.instant('ContractCensorship_ts_41'), () => {
        this.backStepSign(step);
      }, "", this.translate.instant('btn_close'), null, this.translate.instant('btn_confirm'))
    } else {
      this.backStepSign(step);
    }
  }

  //SMART CA
  showLayoutConfirmSmartCA = false;
  timeoutSmartCa = false;

  kySmartCA() {
    this.signType = "SMARTCA";
    this.signFormSelected = "SMART_CA"
    this.currentStepSignInternal = 1;
    this.showLayoutConfirmSmartCA = false;
    this.timeoutSmartCa = false;
    this.signStepChanged.emit(this.currentStepSignInternal);
    this.changedSignForm.emit(this.signType);
    setTimeout(() => {
      this.smartCASign && this.smartCASign.startSignSmartCA();
      this.smartCASign && this.smartCASign.showTemplateSignBox(this.contract.getSignFrameAssignee());
    }, 1);
  }

  //Bat su kien da confirmSmartCA
  handleEventIsWaitingConfirmSmartCA(e) {
    console.log("isWaitingconfirm: ", e);
    if (e !== null && e !== "" && e !== undefined) {
      this.showLayoutConfirmSmartCA = e;
      this.isWaitingConfirmSmartCA.emit(e);
    }
  }

  //Bat su kien het hang phien ky smartCA
  handleEventExpiredSessionSmartCA(e) {
    console.log("expiredSessionSmartCA: ", e);
    if (e !== null && e !== "" && e !== undefined) {
      this.timeoutSmartCa = e;
      this.expiredSessionSmartCA.emit(e);
    }
  }

  //Bat su kien da luu thanh cong chu ky = hinh thuc smartCA
  savedSignatureFromSmartCA(e) {
    if (e !== null && e !== "" && e !== undefined) {
      this.pdfSigned = e;
      PDFSign.preview(this.pdfSigned);
      PDFSign.removeAllSignBox();
      this.nextStepSign(this.currentStepSignInternal);
    }
  }

  //Gui yeu cau ky smartca
  signBySmartCA() {
    this.smartCASign && this.smartCASign.signBySmartCA();
  }

  /**
   * Hien thi goi ky signbox
   * @param signFrame 
   */
  showTemplateSignBox(signFrame = []) {
    console.log("kyDienTu ", signFrame);
    if (signFrame.length > 0) {
      signFrame.forEach((item: SignBox) => {
        PDFSign.addSignBox(item, this.contract.allowAddSignBox);
      })
    }
    setTimeout(() => {
      PDFSign.reloadTable(null, this.contract.allowAddSignBox);
    }, 100)
  }

}
