<div class="row">
    <div class="col-md-7">
        <div class="card shadow-none">
            <div class="card-body position-relative">
                <label class="text-primary">Thông tin cá nhân</label>
                <hr>
                <div>
                    <div class="e-block-field row">
                        <div class="col-md-4 col-xl-3">
                            <label class="e-lb-field">Họ và tên</label>
                        </div>
                        <div class="col-md-8">
                            <span class="">{{personalDetailObject?.ten}}</span>
                        </div>
                    </div>
                    <div class="e-block-field row">
                        <div class="col-md-4 col-xl-3">
                            <label class="e-lb-field">Số giấy tờ</label>
                        </div>
                        <div class="col-md-8">
                            <span class="">{{personalDetailObject?.cmnd}}</span>
                        </div>
                    </div>
                    <div class="e-block-field row">
                        <div class="col-md-4 col-xl-3">
                            <label class="e-lb-field">Ngày sinh</label>
                        </div>
                        <div class="col-md-8">
                            <span class="">{{ personalDetailObject?.ngaySinh | date: 'dd/MM/yyyy' }}</span>
                        </div>
                    </div>
                    <div class="e-block-field row">
                        <div class="col-md-4 col-xl-3">
                            <label class="e-lb-field">Nơi sinh</label>
                        </div>
                        <div class="col-md-8">
                            <span class="">{{personalDetailObject?.noiSinh}}</span>
                        </div>
                    </div>
                    <div class="e-block-field row">
                        <div class="col-md-4 col-xl-3">
                            <label class="e-lb-field">Giới tính</label>
                        </div>
                        <div class="col-md-8">
                            <span class="">{{personalDetailObject?.gioiTinh}}</span>
                        </div>
                    </div>
                    <div class="e-block-field row">
                        <div class="col-md-4 col-xl-3">
                            <label class="e-lb-field">Nơi ĐK HKTT</label>
                        </div>
                        <div class="col-md-8">
                            <span class="">{{personalDetailObject?.hktt}}</span>
                        </div>
                    </div>
                    <div class="e-block-field row">
                        <div class="col-md-4 col-xl-3">
                            <label class="e-lb-field">Ngày cấp</label>
                        </div>
                        <div class="col-md-8">
                            <span class="">{{ personalDetailObject?.ngayCap | date: 'dd/MM/yyyy' }}</span>
                        </div>
                    </div>
                    <div class="e-block-field row">
                        <div class="col-md-4 col-xl-3">
                            <label class="e-lb-field">Nơi cấp</label>
                        </div>
                        <div class="col-md-8">
                            <span class="">{{personalDetailObject?.noiCap}}</span>
                        </div>
                    </div>
                    <div class="e-block-field row">
                        <div class="col-md-4 col-xl-3">
                            <label class="e-lb-field">Có giá trị đến</label>
                        </div>
                        <div class="col-md-8">
                            <span class="">{{ personalDetailObject?.expiryDate | date: 'dd/MM/yyyy' }}</span>
                        </div>
                    </div>
                    <div class="e-block-field row">
                        <div class="col-md-4 col-xl-3">
                            <label class="e-lb-field">Số điện thoại</label>
                        </div>
                        <div class="col-md-8">
                            <span class="">{{personalDetailObject?.sdt}}</span>
                        </div>
                    </div>
                    <div class="e-block-field row">
                        <div class="col-md-4 col-xl-3">
                            <label class="e-lb-field">Email</label>
                        </div>
                        <div class="col-md-8">
                            <span class="">{{personalDetailObject?.email}}</span>
                        </div>
                    </div>
                    <div class="e-block-field row">
                        <div class="col-md-4 col-xl-3">
                            <label class="e-lb-field">Địa chỉ</label>
                        </div>
                        <div class="col-md-8">
                            <span class="">{{personalDetailObject?.diaChi}}</span>
                        </div>
                    </div>
                </div>

                <label class="text-primary">Ảnh chụp</label>
                <hr>
                <div class="row">
                    <div class="col-md-10">
                        <div class="row">
                            <div class="col-md-4 col-sm-8 col-xs-8">
                                <div class="card shadow-none">
                                    <div *ngIf="imageFirst">
                                        <label class="e-lb-field">Ảnh giấy tờ mặt trước</label>
                                        <div>
                                            <img class="img-cmnd" [src]="imageFirst" style="max-width: 100%" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-8 col-xs-8">
                                <div class="card shadow-none">
                                    <div *ngIf="imageSecond">
                                        <label class="e-lb-field">Ảnh giấy tờ mặt sau</label>
                                        <div>
                                            <img class="img-cmnd" [src]="imageSecond" style="max-width: 100%" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 col-sm-8 col-xs-8">
                                <div class="card shadow-none">
                                    <div *ngIf="url">
                                        <label class="e-lb-field">Ảnh chân dung</label>
                                        <div>
                                            <img class="img-cmnd" [src]="url" style="max-width: 100%" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="col-md-5">
        <div class="card shadow-none">
            <div class="card-body position-relative">
                <label class="text-primary">Thông tin kinh doanh</label>
                <hr>
                <div>
                    <div class="e-block-field row">
                        <div class="col-md-5">
                            <label class="e-lb-field">Mã thuê bao</label>
                        </div>
                        <div class="col-md-7">
                            <span class="">{{personalDetailObject?.subscriptionCode}}</span>
                        </div>
                    </div>
                    <div class="e-block-field row">
                        <div class="col-md-5">
                            <label class="e-lb-field">Loại hình thuê bao</label>
                        </div>
                        <div class="col-md-7">
                            <span class="">{{personalDetailObject?.subscriptionType}}</span>
                        </div>
                    </div>
                    <div class="e-block-field row">
                        <div class="col-md-5">
                            <label class="e-lb-field">Mã tỉnh</label>
                        </div>
                        <div class="col-md-7">
                            <span class="">{{personalDetailObject?.provinceCode}}</span>
                        </div>
                    </div>
                    <div class="e-block-field row">
                        <div class="col-md-5">
                            <label class="e-lb-field">Mã HRM AM</label>
                        </div>
                        <div class="col-md-7">
                            <span class="">{{personalDetailObject?.requesterCode}}</span>
                        </div>
                    </div>
                    <div class="e-block-field row">
                        <div class="col-md-5">
                            <label class="e-lb-field">Mã HRM người nhập phiếu</label>
                        </div>
                        <div class="col-md-7">
                            <span class="">{{personalDetailObject?.creatorCode}}</span>
                        </div>
                    </div>
                </div>

                <label class="text-primary">Thông tin tài khoản</label>
                <hr>
                <div>
                    <div class="e-block-field row">
                        <div class="col-md-5">
                            <label class="e-lb-field">Tên tài khoản</label>
                        </div>
                        <div class="col-md-7">
                            <span class="">{{personalDetailObject?.username}}</span>
                        </div>
                    </div>
                    <div class="e-block-field row">
                        <div class="col-md-5">
                            <label class="e-lb-field">Loại tài khoản</label>
                        </div>
                        <div class="col-md-7">
                            <span class="">Tài khoản định danh cấp {{personalDetailObject?.identityLevel}}</span>
                        </div>
                    </div>
                    <div class="e-block-field row">
                        <div class="col-md-5">
                            <label class="e-lb-field">Gói dịch vụ</label>
                        </div>
                        <div class="col-md-7">
                            <span class="">{{personalDetailObject?.packageName}}</span>
                        </div>
                    </div>
                    <div class="e-block-field row">
                        <div class="col-md-5">
                            <label class="e-lb-field">Trạng thái</label>
                        </div>
                        <div class="col-md-7">
                            <span
                                class="badge {{getStyleAndValueBadge(personalDetailObject?.disable)[0]}}">{{getStyleAndValueBadge(personalDetailObject?.disable)[1]}}</span>
                        </div>
                    </div>

                </div>
                <!-- <div class="mt-5">
                            <label class="text-primary">Ảnh chụp</label>
                            <hr>
                            <div class="row">
                                <div *ngIf="imageFirst" class="col-xl-6 col-lg-6 col-md-10 col-sm-10 col-10 pb-3">
                                    <div>
                                        <label class="e-lb-field">Ảnh giấy tờ mặt trước</label>
                                        <div>
                                            <img class="img-cmnd" [src]="imageFirst" />
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="imageSecond" class="col-xl-6 col-lg-6 col-md-10 col-sm-10 col-10 pb-3">
                                    <div>
                                        <label class="e-lb-field">Ảnh giấy tờ mặt sau</label>
                                        <div>
                                            <img class="img-cmnd" [src]="imageSecond" />
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="url" class="col-xl-6 col-lg-6 col-md-10 col-sm-10 col-10 pb-3">
                                    <div>
                                        <label class="e-lb-field">Ảnh chân dung</label>
                                        <div>
                                            <img class="img-cmnd" [src]="url" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> -->
            </div>
        </div>
    </div>
</div>