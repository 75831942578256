import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "app-ng-modal",
    templateUrl: "./ng-modal.component.html",
    styleUrls: ["./ng-modal.component.scss"],
})
export class NgModalComponent implements OnInit {
    @Input() notification = false;
    @Input() typeAlert = "success";
    @Input() message = "";
    @Input() callback;
    @Input() callback1;

    imgAlert = {
        success: "/assets/img/icon/success-icon.png",
        error: "/assets/img/icon/error-icon.png",
    };

    constructor(public activeModal: NgbActiveModal) {}

    ngOnInit() {
        // console.log('messs', this.message);
    }

    action2() {
        if (this.callback1 instanceof Function) {
            this.callback1();
        }
    }

    action1() {
        if (this.callback instanceof Function) {
            this.callback();
        }
    }
}
