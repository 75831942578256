import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/utils/services/aas-network/dashboard/dashboard.service';

@Component({
  selector: 'app-used-capacity-admin',
  templateUrl: './used-capacity-admin.component.html',
  styleUrls: ['./used-capacity-admin.component.scss'],
})
export class UsedCapacityAdminComponent implements OnInit {
  constructor(private dashboardService: DashboardService) {}

  ngOnInit(): void {
    this.initData();
  }
  usedData = 0;
  timeData = 0;
  mountEKYC = 0;
  mountSMSOTP = 0;
  mountContract = 0;
  mountSMSNoti = 0;
  type = 'BarChart';
  dataTemp = [];
  data = [
    // ['Dung lượng lưu trữ (GB)', 0.02],
    // ['Thời gian lưu trữ (Tháng)', 0.032],
    // ['Số lượt eKYC', 23 / 100],
    // ['Số lượt SMS OTP', 44 / 100],
    // ['Số lượng hợp đồng', 0],
    // ['Số lượt SMS thông báo', 150 / 100],
  ];
  columnNames = ['Loại dung lượng', 'Giá trị', { role: 'annotation' }];
  options = {
    legend: 'none',
    colors: ['#29D395'],
    chartArea: {
      left: 150,
      right: 20,
    },
    hAxis: {
      gridlines: { count: 3 },
      format: 'percent',
      viewWindow: {
        max: 1,
      },
    },
    vAxis: {
      colors: ['E4E4E4'],
    },
  };
  width = 550;
  height = 400;
  dynamicResize = true;
  initData() {
    this.dashboardService.getUsedCapacity().subscribe(
      (res: any) => {
        if (res && res.object instanceof Array) {
          if (res.object.length == 0) {
            this.dataTemp.push(['Dung lượng lưu trữ (GB)', {v: 0, f:0}, `0`]);
            this.dataTemp.push(['Thời gian lưu trữ (Tháng)', {v:0, f:0}, `0`]);
            this.dataTemp.push(['Số lượt eKYC',{v: 0, f:0} , `0`]);
            this.dataTemp.push(['Số lượt SMS OTP', {v: 0, f:0}, `0`]);
            this.dataTemp.push(['Số lượng hợp đồng',{v: 0, f:0}, `0`]);
            this.dataTemp.push(['Số lượt SMS thông báo', {v: 0, f:0}, `0`]);
          } else {
            this.dataTemp.push(['Dung lượng lưu trữ (GB)', {v: res.object[0].usedData /res.object[0].totalData, f:res.object[0].usedData}, `${res.object[0].usedData}/${res.object[0].totalData}`]);
            this.dataTemp.push(['Thời gian lưu trữ (Tháng)', {v: res.object[0].issueDate /res.object[0].numberOfMonthStorage, f:res.object[0].issueDate}, `${res.object[0].issueDate} /${res.object[0].numberOfMonthStorage}`]);
            this.dataTemp.push(['Số lượt eKYC',{v: res.object[0].ekyc/res.object[0].totalEkyc, f:res.object[0].ekyc} , `${res.object[0].ekyc}/${res.object[0].totalEkyc}`]);
            this.dataTemp.push(['Số lượt SMS OTP', {v: res.object[0].smsOTP/res.object[0].totalSMSOTP, f:res.object[0].smsOTP}, `${res.object[0].smsOTP}/${res.object[0].totalSMSOTP}`]);
            this.dataTemp.push(['Số lượt SMS thông báo',{v: res.object[0].sms/res.object[0].totalSMS, f:res.object[0].sms }, `${res.object[0].sms}/${res.object[0].totalSMS}`]);
            this.dataTemp.push(['Số lượng hợp đồng', {v: res.object[0].contracts/res.object[0].totalContract, f:res.object[0].contracts}, `${res.object[0].contracts}/${res.object[0].totalContract}`]);
          }
          this.data = this.dataTemp;
          // console.log(this.data);
        }
      },
      (error) => {}
    );
  }
}
