<app-spinner></app-spinner>
<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0 text-dark">Quản lý chữ ký số</h1>
            </div>
            <!-- /.col -->
            <!-- <div class="col-sm-6">
                <button class="btn btn-primary float-sm-right" [routerLink]="['/portal/customers/create']"
                    *showWithRoles="['TTKD_AM']">Tạo khách hàng</button>
            </div> -->
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>

<section class="content">
    <div class="text-center">
        <img src="assets/img/services/emty-signature-img.png" style="margin-top: 5px;" class="col-md-3">
        <div class="text-lg mt-2">
            <p>Hệ thống đang xử lý. Vui lòng đợi trong giây lát!</p>
        </div>
    </div>
</section>