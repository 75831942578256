import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, timer } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstLocalStorage } from 'src/app/utils/common/constant';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { LocationExtension } from 'src/app/utils/common/LocationExtension';
import { AasValidateMessage } from 'src/app/utils/common/validate-msg-aas';
import { InfoPartyObject } from 'src/app/utils/models/aas-models/info-party/info-party-object';
import { AuthLoginResponse } from 'src/app/utils/models/auth/auth-login/auth-login-response.model';
import { UserInformationsItem } from 'src/app/utils/models/profile/user-info-item.model';
import { UserInformationsResponse } from 'src/app/utils/models/profile/user-info-response.model';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { FirebaseService } from 'src/app/utils/services/aas-network/firebase/firebase.service';
import { PackageSessionService } from 'src/app/utils/services/aas-network/package-session/package-session.service';
import { AuthService } from 'src/app/utils/services/auth.service';
import { LocalStorageKey, QueryParamKey } from '../../../wv-ultils/wv-const';
import { WebviewURL } from '../../../wv-ultils/wv-const-url';

@Component({
  selector: 'app-wv-verify-otp-login',
  templateUrl: './wv-verify-otp-login.component.html',
  styleUrls: ['./wv-verify-otp-login.component.scss']
})
export class WvVerifyOtpLoginComponent implements OnInit {

  redirectUrl = this.activedRoute.snapshot.queryParamMap.get('redirectUrl') || '';
  contractId = this.activedRoute.snapshot.queryParamMap.get("contractId") || "";
  action = this.activedRoute.snapshot.queryParamMap.get("action") || "";
  

  formOTP: FormGroup;
  isSubmit = false;
  ServiceMsg = AasValidateMessage.REQUEST_OTP;
  counter$: Observable<string>;
  count = 0;
  wrong = 0

  emailOrPhoneNumber: boolean = null;
  username: string = null;
  destinationOfOtp: string = null;
  resend: boolean = false;

  token: AuthLoginResponse = null;

  userInfo = new UserInformationsResponse();

  constructor(
    private router: Router,
    private aasAuthService: AasAuthService,
    private alert: AlertControl,
    private authService: AuthService,
    public packageSessionService: PackageSessionService,
    private activedRoute: ActivatedRoute,
    private firebaseService: FirebaseService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.checkValidFlow();
    this.makeSendOTPForm();
    this.resetTimeout();
  }

  /**
   * Check if current page is redirected from login-with-otp page.
   * If yes, It will have the value in sessionStorage like below.
   */
  checkValidFlow() {
    if (sessionStorage.getItem('email') != null && sessionStorage.getItem('username') != null) {
      this.emailOrPhoneNumber = true;
      this.username = sessionStorage.getItem('username');
      this.destinationOfOtp = sessionStorage.getItem('email');
    }
    else if (sessionStorage.getItem('phoneNumber') != null && sessionStorage.getItem('username') != null) {
      this.emailOrPhoneNumber = false;
      this.username = sessionStorage.getItem('username');
      this.destinationOfOtp = sessionStorage.getItem('phoneNumber');
    }
    else {
      this.router.navigate([`app/login-with-OTP`]);
    }
  }

  @HostListener("window:beforeunload", ["$event"]) unloadHandler(event: Event) {
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('phoneNumber');
    sessionStorage.removeItem('username');
  }

  ngOnDestroy(): void {
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('phoneNumber');
    sessionStorage.removeItem('username');
  }

  //MARK: Khởi tạo form send email
  makeSendOTPForm() {
    this.formOTP = new FormGroup({
      otp: new FormControl(null, [Validators.required, Validators.minLength(6), Validators.maxLength(6)]),
    });
  }

  get otp() { return this.formOTP.get('otp') }

  /**
   * Action when click button
   */
  verifyOTP() {
    if (this.formOTP.valid) {
      this.aasAuthService.verifyLoginOtp(this.username, LocationExtension.HOSTNAME, this.otp.value, "clientapp")
        .subscribe((res: any) => {
          if (res.object) {
            let response = new AuthLoginResponse(res.object);
            this.token = new AuthLoginResponse(res.object);
            let jwtToken = this.authService.jwtDecode(this.token.access_token);
            this.authService.setToken(this.token.access_token);
            this.authService.setUserId(jwtToken.idUser);
            this.authService.setUsername(jwtToken.user_name);
            localStorage.setItem(ConstLocalStorage.REFRESH_TOKEN, response.refresh_token);

            this.getUserInfo(this.authService.jwtDecode(this.authService.getToken()).idUser);
          }
        }, err => {
          if (err.error && err.error.message && err.error.message === 'ECT-00001122') {
            this.alert.showAlertOnlyNoti(this.translate.instant('ECT-00001122'), ConstantAlertType.ERROR);
          }
          else {
            this.wrong +=1;
            this.alert.showErrorHandled(err);
            if( this.wrong == 1){
              this.router.navigate(['/wv/login'])
            }
          }
        });
    }
  }

  /**
   * Action when click button theo kiểu SDK
   */
  verifyOTPSDK(clientId: string, clientSecret: string) {
    if (this.formOTP.valid) {
      this.aasAuthService.sdkVerifyLoginOtp(this.username, LocationExtension.HOSTNAME, this.otp.value, clientId, clientSecret)
        .subscribe((res: any) => {
          if (res.object) {
            let response = new AuthLoginResponse(res.object);
            this.token = new AuthLoginResponse(res.object);
            let jwtToken = this.authService.jwtDecode(this.token.access_token);
            this.authService.setToken(this.token.access_token);
            this.authService.setUserId(jwtToken.idUser);
            this.authService.setUsername(jwtToken.user_name);
            localStorage.setItem(ConstLocalStorage.REFRESH_TOKEN, response.refresh_token);

            this.getUserInfo(this.authService.jwtDecode(this.authService.getToken()).idUser);
          }
        }, err => {
          if (err.error && err.error.message && err.error.message === 'ECT-00001122') {
            this.alert.showAlertOnlyNoti(this.translate.instant('ECT-00001122'), ConstantAlertType.ERROR);
          }
          else {
            this.wrong +=1;
            this.alert.showErrorHandled(err);
            if( this.wrong == 1){
              this.router.navigate(['/wv/login'])
            }
          }
        });
    }
  }

  /**
   * Lấy thông tin user đăng nhập
   * @param id 
   */
  getUserInfo(id) {
    this.authService.getUserInfo(id).subscribe((res: any) => {
      this.userInfo = new UserInformationsResponse(res);
      this.authService.setUserInfo(new UserInformationsItem(res.object || {}));
      this.authService.internalPartyId = this.userInfo.object.partyId;
      this.authService.setPartyId(this.userInfo.object.referenceId || this.userInfo.object.partyId);

      this.getCheckTimeExpiredPackage(this.userInfo.object.partyId);
      this.getInternalPartyInfo(this.userInfo.object.partyId);
      this.getPartyInfo(this.userInfo.object.referenceId);

      this.loginSuccess();
    }, err => {
      this.alert.showErrorHandled(err);
      this.loginSuccess();
    });
  }

  loginSuccess() {
    var urlSplit = this.redirectUrl.split('/');
    urlSplit.splice(0, 3);
    const uuid = this.authService.jwtDecode(this.authService.getToken()).idUser;
    this.firebaseService.requestRegisterDevive(uuid);
    // this.firebaseService.requestPermissionAndRegisterDevice(uuid);
    this.redirectUrl !== '' ? this.router.navigateByUrl(urlSplit.join('/')) : this.router.navigate(['/app/console']);
    // const username = this.authService.jwtDecode(this.authService.getToken()).user_name;
    if (this.contractId !== "") {
      this.router.navigate([WebviewURL.contract_detail, this.contractId], { queryParams: { action: this.action }});
    } else {
      this.router.navigate([WebviewURL.contract_list]);
    }
  }

  /**
   * Thong tin party dang login
   * @param partyId 
   */
  getPartyInfo(partyId) {
    this.authService.getPartyInfo(partyId).subscribe((res: any) => {
      this.authService.setPartyInfo(new InfoPartyObject(res.object || {}));
    }, err => {
    });
  }

  /**
   * Thong tin party dang login
   * @param partyId 
   */
  getInternalPartyInfo(partyId) {
    this.authService.getPartyInfo(partyId).subscribe((res: any) => {
    }, err => {
    });
  }

  /**
   * GET Check time expired package
   * @param partyId 
   */
  getCheckTimeExpiredPackage(partyId) {
    this.packageSessionService.getCheckTimeExpiredPackage(partyId).subscribe((res: any) => {
      this.packageSessionService.setPackageSessionInfo({
        packageExpired: res.object?.timeExpired || "",
        packageName: res.object?.packageName || "",
        packageType: res.object?.packageType || ""
      })
    }, err => {

    });
  }

  resendOTP() {
    this.alert.showAlert(this.translate.instant('VerifyOTPLogin_ts_1'), "", true,
      "refresh", this.translate.instant('OtpForgotPassword_h_2'), () => {
        this.aasAuthService.loginByOtp(this.username, LocationExtension.HOSTNAME).subscribe((res: any) => {
          this.resend = true;
        }, err => {
          this.alert.showErrorHandled(err);
        })
      }, "", this.translate.instant('btn_cancel'), null, this.translate.instant('VerifyOTPLogin_ts_2'));
  }


  /**
   * Start the countdown, beforn allowing OTP resend.
   */
  resetTimeout() {
    this.count = 30;
    this.counter$ = timer(0, 1000).pipe(
      take(this.count),
      map(() => { --this.count; return this.convertToMmss(this.count) })
    );
  }

  /**
    * Chuyển đổi giây sang phút:giây
    * @param seconds 
    * @returns 
    */
  convertToMmss(seconds) {
    return Math.floor(seconds / 60) + ":" + (seconds % 60 ? seconds % 60 : '00');
  }

  /**
     * Check valid button
     * Allow click if username form is valid.
     */
  get disabledButton() {
    if (this.formOTP.valid) {
      return false;
    }
    return true;
  }

  /**
   * Action click button xác nhận OTP
   */
  submitOTP() {
    try {
      const data: any = JSON.parse(localStorage.getItem(LocalStorageKey.queryParam));

      if (data[QueryParamKey.CLIENTID]) {
        this.verifyOTPSDK(data[QueryParamKey.CLIENTID], '' );
      } else {
        this.verifyOTP();
      }
    } catch (error) {
      alert(error);
    }
  }
}
