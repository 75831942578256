<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2 d-flex justify-content-between ml-2 mr-1">
            <div class="">
                <h1 class="m-0 text-dark"><label> {{ updateForm == 'sdt' ? 'Cập nhật số điện thoại' : 'Cập nhật email' }}</label></h1>
            </div>
            <div class="">
                <button class="btn btn-outline-primary d-inline-flex align-items-center mr-3" (click)="cancelAction()"><span class="material-icons mr-2">close</span>
                    Hủy</button>
                <button *ngIf="stage == 'input'" class="btn btn-primary float-sm-right d-inline-flex align-items-center" type="submit" (click)="updateInfo(remainOtpEmail, remainOtpPhone)" [disabled]="formOTP.controls.value.invalid">
                    Gửi OTP <em class="material-icons pl-2">send</em></button>
                <button [disabled]="lenghtOtp < 6" *ngIf="stage == 'confirm'" class="btn btn-primary float-sm-right d-inline-flex hide" type="submit" (click)="confirmOTP()"><em class="material-icons mr-2">done</em>
                    Xác nhận</button>
            </div>
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>

<section class="content">
    <div class="container-fluid">
        <div class="bg-white p-3">
            <div [formGroup]="formOTP">
                <h2 class="m-0 text-dark">
                    <label *ngIf="stage == 'input'"> {{ updateForm == 'sdt' ? 'Nhập số điện thoại' : 'Nhập email' }} mới</label>
                    <label *ngIf="stage == 'confirm'">Nhập mã OTP</label>
                </h2>
                <div *ngIf="stage == 'input'">
                    <p>Vui lòng nhập {{ updateForm == 'sdt' ? 'số điện thoại' : 'email' }} của bạn. Một mã OTP sẽ được gửi về {{ updateForm == 'sdt' ? 'số điện thoại' : 'email' }} này để thực hiện xác thực</p>
                    <label for="updateInput" class="text-capitalize">{{ updateForm == 'sdt' ? 'số điện thoại' : 'email' }}<span class="text-danger"> *</span></label>
                    <input id="updateInput" formControlName="value" appTrimValue type="text" class="form-control" placeholder=" {{ updateForm == 'sdt' ? 'Nhập số điện thoại' : 'Nhập email' }}">
                    <div class="pt-1">
                        <app-show-validate-errors [isSubmit]="true" [errorMessages]="validateMsg[updateForm]"
                        [control]="formOTP.controls.value" [detail]="{ name: updateForm }"></app-show-validate-errors>
                    </div>
                    
                </div>
                <div *ngIf="stage == 'confirm'">
                    <p>Vui lòng nhập mã OTP gồm 6 chữ số đã được gửi đến {{ updateForm == 'sdt' ? 'số điện thoại' : 'email' }} {{ value.value }} của bạn</p>
                    <label for="confirmOTP">Mã OTP<span class="text-danger">*</span></label>
                    <input id="confirmOTP" formControlName="otp" appOnlyNumber type="text" class="form-control" placeholder="Nhập mã OTP" maxlength="6">
                    <div class="pt-1">
                        <app-show-validate-errors [isSubmit]="true" [errorMessages]="validMsgOTP.otp"
                        [control]="formOTP.controls.otp" [detail]="{ name: 'otp' }"></app-show-validate-errors>
                    </div>
                </div>

                <a *ngIf="count === 0 && stage == 'confirm'" class="cursor-pointer text-decoration-underline text-primary mt-3" (click)="resendOTP()">{{ localize.a_resend_otp_esign }}</a>
                <span class="text-muted mt-3" *ngIf="count > 0">Gửi lại mã OTP sau
                    {{counter$ | async}}s</span>
                
                <!-- <div *ngIf="stage == 'confirm'" class="text-primary text-decoration-underline mt-2">Gửi lại mã OTP</div> -->

            </div>

        </div>
    </div>
</section>