export class ESignItem {
    fullName: string = "";
    cmnd: string = "";
    signDate: string = "";
    sdt: string = "";
    imageSign: any;
    signatureok: any;
    certok: any;
    expanded = false;
    compareok: any;
    imageSigner: string = "";
    type: string = "";
    signImg: SignImg[] = [];
    imageSigners: SignImg[] = [];
    
    constructor(d = null) {
        d = d || {};
        this.fullName = d.fullName || "";
        this.cmnd = d.idCard || "";
        this.signDate =d.signDate || "";
        this.sdt = d.phoneNumber || "";
        this.imageSign = d.imageSign || "";
        this.signatureok = d.signatureok || false;
        this.certok = d.certok || false;
        this.compareok = d.compareok || false;
        this.imageSigner = d.imageSigner || "";
        this.type = d.type || "";

        if (d.signImg && d.signImg instanceof Array) {
            d.signImg.forEach((item) => {
                this.signImg.push(new SignImg(item));
            })

            if (this.signImg.length > 0)
                this.imageSign = this.signImg[0];
            if (this.signImg.length > 1) 
                for (let i = 1; i < this.signImg.length; i++) {
                    this.imageSigners.push(this.signImg[i]);
                }
        }
    }
}

export class SignImg {
    keyName: string = "";
    link: string = "";

    constructor(d = null) {
        d = d || {};
        this.keyName = d.keyName || "";
        this.link = d.link || "";
    }
}