import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertBtn, ConstantAlertMsg } from 'src/app/utils/common/constant-alert-msg';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { REGEXP_PHONE_NUMBER } from 'src/app/utils/common/regexp';
import { StatusCode } from 'src/app/utils/common/status-code';
import { localize } from 'src/app/utils/localize/localize';
import { Part } from 'src/app/utils/models/part/part.model';
import { PartService } from 'src/app/utils/services/aas-network/part/part.service';
import { EmployeeInPartComponent } from '../employee-in-part/employee-in-part.component';

@Component({
  selector: 'app-part-edit',
  templateUrl: './part-edit.component.html',
  styleUrls: ['./part-edit.component.scss']
})
export class PartEditComponent implements OnInit {
  @ViewChild('employeeInPart') employeeInPart: ElementRef;
  @ViewChild('appEmployeeInPart') appEmployeeInPart: EmployeeInPartComponent;

  id = this.activedRoute.snapshot.paramMap.get('id'); 
  localize = localize;
  part: Part = new Part();
  partForm: FormGroup;
  isSubmit = false;
  validatePartForm = {
    partyName: {
      required: "PartEdit_FORM_PART_NAME_REQUIRED"
    },
    phoneNumber: {
      pattern: "PartEdit_FORM_PART_SDT_PATTERN"
    },
    disable: {
      required: "PartEdit_FORM_STATUS_REQUIRED"
    }
  };

  constructor(
    private fb: FormBuilder,
    private activedRoute: ActivatedRoute,
    private partService: PartService,
    private router: Router,
    private myAlert: AlertControl,
    private ngbModal: NgbModal,
    public translate: TranslateService,

  ) { }

  ngOnInit(): void {
    this.viewDidLoad();
  }

  viewDidLoad() {
    this.makePartForm();
    this.getPart(this.id);
  }

  makePartForm() {
    this.partForm = this.fb.group({
      partyName: ['', [Validators.required]],
      descr: [''],
      phoneNumber: ['', [Validators.pattern(REGEXP_PHONE_NUMBER)]],
      fax: [''],
      disable: [null, [Validators.required]]
    })
  }

  setData(data: Part) {
    this.partForm.patchValue({
      partyName: data.partyName || "",
      descr: data.descr || "",
      phoneNumber: data.phoneNumber || "",
      fax: data.fax || "",
      disable: data.disable
    })
  }

  save() {
    this.isSubmit = true;

    if (this.partForm.valid) {
      this.myAlert.showAlert(this.translate.instant('PartEdit_PART_UPDATE_CONFIRM'), ConstantAlertType.BLANK, true, '', this.translate.instant('btn_confirm'), () => {
        this.putUpdatePart(this.id, this.partForm.value);
      }, '', this.translate.instant('btn_close'), null, this.translate.instant('btn_confirm'));
    }
  }

  cancel() {
    // this.isSubmit = false;
    // this.partForm.reset();
    // this.setData(this.part);
    this.router.navigate(['/app/console/parts'])
  }

  showListEmployee(event, id) { 
    event.stopPropagation();
    let modelRef = this.ngbModal.open(this.employeeInPart, { size: 'xl' });
    this.appEmployeeInPart && this.appEmployeeInPart.reload();
  }

  //MARL: UTILS

  //MARK: NETWORK
  putUpdatePart(id, param) {
    this.partService.putUpdatePart(id, param).subscribe((res: any) => {
      this.myAlert.showAlert(this.translate.instant('PartEdit_PART_UPDATE_SUCCESS'), ConstantAlertType.SUCCESS,false, '', this.translate.instant('btn_confirm'), () => {
        this.router.navigate([ConstantUrl.PART_DETAIL, id])
      });
    }, err => {
      if (err.error && err.error.code && err.error.code === "EMPLOYEE_STILL_ACTIVE") {
        let msg: string = this.translate.instant('PartEdit_employee_still_active', {namePart: this.part.partyName});
        // msg = msg.replace("${namePart}", this.part.partyName);
        this.myAlert.showAlert(msg, ConstantAlertType.ERROR);
      } else {
        this.myAlert.showErrorHandled(err);
      }
    })
  }

  getPart(id) {
    this.partService.getPart(id).subscribe((res: any) => {
      this.part = new Part(res?.object?.data);
      this.setData(this.part);
      console.log(res);
    })
  }
}
