import {EinvoicesDetailObject} from "./einvoices-object.model"
import { MainResponse } from '../../../main-response.model';

export class EinvoicesObjectResponse extends MainResponse {
    
    constructor(obj = null) {
        super();
        obj = obj || {};
        this.init(obj);
        this.object = new EinvoicesDetailObject(obj.object);

      
    }
}