import { Component, OnInit } from '@angular/core';
import { ControlAccount } from "src/app/utils/common/control-account";
import { InfoPartyObject } from 'src/app/utils/models/aas-models/info-party/info-party-object';
import { UserInformationsItem } from 'src/app/utils/models/profile/user-info-item.model';
import { AuthService } from 'src/app/utils/services/auth.service';

@Component({
  selector: 'app-profile-manager',
  templateUrl: './profile-manager.component.html',
  styleUrls: ['./profile-manager.component.scss']
})
export class ProfileManagerComponent implements OnInit {
  userInfo: UserInformationsItem = new UserInformationsItem()
  partyInfo: InfoPartyObject
  constructor(
    public controlAccount: ControlAccount,
    public authService: AuthService
  ) { }

  ngOnInit(): void {
    this.controlAccount.requestAPIGetAccountInfo(this.authService.userId, this.authService.partyId, ()=>{
      this.userInfo= this.controlAccount.userInfo
      this.partyInfo= this.controlAccount.partyInfo
      console.log('info',this.userInfo, this.partyInfo);
    })
  
    
  }


  actionSave(event) {
    console.log(event);
    // this.controlAccount.requestAPIGetAccountInfo(event.id, event.party, ()=>{
    //   console.log(this.controlAccount.userInfo);
    // })
  }
}
