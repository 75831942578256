import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { InternalDiscussTypes, SignFlowTypes } from 'src/app/utils/common/constants/sign-form';
import { localize } from 'src/app/utils/localize/localize';
import { FlowContract } from 'src/app/utils/models/aas-models/flow-contract/flow-contract.model';
import { InternalDiscuss } from 'src/app/utils/models/aas-models/flow-contract/internal-discuss.model';
import { PartnerDiscuss } from 'src/app/utils/models/aas-models/flow-contract/partner-discuss.model';
import { SignFlow } from 'src/app/utils/models/aas-models/flow-contract/sign-flow.model';
import { EmployeeService } from 'src/app/utils/services/aas-network/employee/employee.service';
import { FlowContractService } from 'src/app/utils/services/aas-network/flow-contract/flow-contract.service';
import { PartService } from 'src/app/utils/services/aas-network/part/part.service';
import { TemplateService } from 'src/app/utils/services/aas-network/template.service';
import { AuthService } from 'src/app/utils/services/auth.service';


@Component({
  selector: 'app-contract-flow-detail',
  templateUrl: './contract-flow-detail.component.html',
  styleUrls: ['./contract-flow-detail.component.scss']
})
export class ContractFlowDetailComponent implements OnInit {
  @ViewChild('templateFile') templateFile: ElementRef;

  id = this.activedRoute.snapshot.paramMap.get('id');

  localize = localize;

  form: FormGroup;
  validateError = {
    name: {
      required: "ContractFlowAdd_clc_form_name_required"
    },
    disable: {
      required: "ContractFlowAdd_clc_form_status_required"
    },
    file: {
      required: "ContractFlowAdd_clc_form_file_required",
      pattern: "ContractFlowAdd_clc_file_invalid_type"
    },
    type: {
      required: "ContractFlowAdd_clc_form_type_required"
    },
    discuss: {
      required: "ContractFlowAdd_clc_form_discuss_required"
    },
    description: {

    }
  };
  isSubmit = false;
  dropListStatus = [
    { value: false, text: "Đang hoạt động" },
    { value: true, text: "Ngưng hoạt động" }
  ];
  dropListDiscuss = [
    { value: true, text: "Hợp đồng có đàm phán"},
    { value: false, text: "Hợp đồng không đàm phán"}
  ];
  dropListType = [
    { value: "ELECTRONIC", text: "Hợp đồng điện tử"},
    { value: "PAPER", text: "Hợp đồng giấy"}
  ];
  attachFile: any = null;
  inputFileLabel = "ContractFlowAdd_ph_file";
  pdfFile = null;
  showFile = false;
  fileType: Array<string> = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

  internalDiscuss: any[] = [];
  partnerDiscuss: any[] = [];
  signFlow: any[] = [];
  internalDiscussError: any = null;
  partnerDiscussError: any = null;
  signFlowError: any = null;

  flowContract: FlowContract = new FlowContract();

  signFlowTypes = SignFlowTypes;

  /**
   * Thêm luồng thẩm định nội bộ đối với hợp đồng không đàm phán
   */
  internalDiscussTypes = InternalDiscussTypes;

  constructor(
    private fb: FormBuilder,
    private partService: PartService,
    private employeeService: EmployeeService,
    private templateService: TemplateService,
    private myAlert: AlertControl,
    private flowContractService: FlowContractService,
    private authService: AuthService,
    private router: Router,
    private activedRoute: ActivatedRoute,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.viewDidLoad();
  }

  viewDidLoad() {
    this.getDetailFlowContract(this.id);
  }

  setData(param: FlowContract) {
    this.inputFileLabel = param.fileName;

    param.internalDiscuss.forEach((item) => {
      let i = new InternalDiscuss(item);
      i.setPerAction();
      this.internalDiscuss.push(i);
    });

    param.partnerDiscuss.forEach((item) => {
      let i = new PartnerDiscuss(item);
      i.setPerAction();
      this.partnerDiscuss.push(i);
    });

    param.signFlow.forEach((item) => {
      let i = new SignFlow(item);
      this.signFlow.push(item);
    });
  }

  showFilePreview() {
    this.getPreviewContractSample(this.id);
  }

  /**
   * Convert docx sang PDF
   * @param data 
   */
  postConvertToPDF(data: FormData) {
    this.templateService.postConvertToPdf(data).subscribe(fileData => {
      const blob: any = new Blob([fileData], { type: 'application/pdf' });
      this.pdfFile = blob;
      this.showFile = true;
    }, err => {
      this.myAlert.showAlertOnlyNoti(this.translate.instant('ContractFlowDetail_convert_pdf_failed'), ConstantAlertType.ERROR);
    });
  }

  /**
   * Dong man hinh preview file
   * @param data 
   */
  onClosePreview(data) {
    this.showFile = false;
    this.pdfFile = null;
  }

  delete() {
    if (this.flowContract.disable === false) {
      this.myAlert.showAlertOnlyNoti(this.translate.instant('ContractFlowDetail_cannot_delete_contract_flow_active'), ConstantAlertType.ERROR);
      return;
    }

    this.myAlert.showAlert(this.translate.instant('ContractFlowDetail_delete_contract_flow_confirm'), ConstantAlertType.BLANK, true, "", this.translate.instant('btn_delete'), () => {
      this.postDelete(this.id);
    }, "", this.translate.instant('btn_close'));
  }

  edit() {
    this.router.navigate([ConstantUrl.contract_flow_edit, this.id]);
  }


  /**
   * Gui yeu cau lay thong tin luong hop dong
   * @param id 
   */
  getDetailFlowContract(id: string) {
    this.flowContractService.getDetailFlowContract(id).subscribe((res: any) => {
      console.log(res);
      this.flowContract = new FlowContract(res?.object || {});
      this.setData(this.flowContract);
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }

  getPreviewContractSample(id) {
    this.flowContractService.getPreviewContractSample(id).subscribe((res: any) => {
      const blob: any = new Blob([res], { type: 'application/pdf' });
      this.pdfFile = blob;
      this.showFile = true;
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }

  postDelete(id) {
    this.flowContractService.postDelete(id).subscribe((res: any) => {
      this.myAlert.showAlertOnlyNoti(this.translate.instant('ContractFlowDetail_delele_contract_flow_success'), ConstantAlertType.SUCCESS);
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }

  downloadStockFile(event) {
    this.flowContractService.getUrltockFile(this.id).subscribe((res: any) => {
      if (res.object && res.object.minio_path) {
        this.flowContractService.downloadWithURL(res.object.minio_path).subscribe((data) => {
          const blob: any = new Blob([data]);
          var url = window.URL.createObjectURL(blob);
          var anchor = document.createElement("a");
          anchor.download = this.flowContract.fileName || res.object.fileName || 'file';
          anchor.href = url;
          anchor.click();
        }, err => {
          this.myAlert.showErrorHandled(err);
        })
      }
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }

  /**
   * Check điều kiện hiển thị bản nhân sự thẩm định
   */
  get showTableInternalDiscuss() {
    return (this.flowContract.internalDiscussType !== this.internalDiscussTypes.NO_REQUIRE && this.flowContract.internalDiscussType !== "") || this.flowContract.discuss === true;
  }
}
