import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { share } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MiniLoaderService {
  miniLoader: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {
    this.miniLoader = new BehaviorSubject<boolean>(false); 
   }

  /**
   * Hiện loader
   */
  showLoader() {
    setTimeout(() => {
      this.miniLoader.next(true);
    });
  };

  /**
   * Ẩn loader
   */
  hideLoader() {
    setTimeout(() => {
      this.miniLoader.next(false);
    });
  }

  /**
   * Check loader có đang hiển thị
   */
  isVisible(): Observable<boolean> {
    return this.miniLoader.asObservable().pipe(share());
  }
}
