<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-auto">
                <h1 class="m-0 text-dark"><label>{{ localize.add_new_customer }} </label></h1>
            </div>
            <!-- /.col -->
            <div class="col-md-auto ml-auto">
                <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="cancel()">
                    <span class="material-icons mr-1">close</span> {{ localize.btn_cancel }}
                </button>
                <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="reset()"><span class="material-icons-outlined mr-1">
                    refresh
                    </span>{{ 'btn_reset' | translate }}</button>
                <button class="btn btn-primary d-inline-flex mr-3" (click)="save()">
                    <span class="material-icons mr-1">save</span> {{ localize.btn_save }}
                </button>
            </div>
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>

<section class="content">
    <div class="container-fluid">
        <div class="card shadow-none">
            <div class="card-body">
                <form [formGroup]="form">
                    <label class="text-primary font-weight-bold m-0">Thông tin kinh doanh</label>
                    <hr class="my-3">
                    <div class="row">
                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                                <label>{{ localize.tb_code }} <span class="text-danger">*</span></label>
                                <input appTrimValue class="form-control" placeholder="{{ localize.fill_tb_code }}"
                                    formControlName="tb_code">
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="form.controls.tb_code" [detail]="{ name: 'tb_code'}"
                                    [errorMessages]="validateMsg.tb_code"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                                <label>{{ localize.tb_type }} <span class="text-danger">*</span></label>
                                <input appTrimValue class="form-control" placeholder="{{ localize.fill_tb_type }}"
                                    formControlName="tb_type">
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="form.controls.tb_type" [detail]="{ name: 'tb_type'}"
                                    [errorMessages]="validateMsg.tb_type"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                                <label>{{ localize.tinh_code }} <span class="text-danger">*</span></label>
                                <input appTrimValue class="form-control" placeholder="{{ localize.fill_tinh_code }}"
                                    formControlName="tinh_code">
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="form.controls.tinh_code" [detail]="{ name: 'tinh_code'}"
                                    [errorMessages]="validateMsg.tinh_code"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                                <label>Mã HRM AM <span class="text-danger">*</span></label>
                                <input appTrimValue class="form-control" placeholder="Nhập mã HRM AM"
                                    formControlName="hrm_am">
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="form.controls.hrm_am" [detail]="{ name: 'hrm_am'}"
                                    [errorMessages]="validateMsg.hrm_am"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                                <label>Mã HRM người tạo phiếu</label>
                                <input appTrimValue class="form-control" placeholder="Nhập mã HRM người tạo phiếu"
                                    formControlName="hrm_creator">
                                <!-- <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="form.controls.tinh_code" [detail]="{ name: 'tinh_code'}"
                                    [errorMessages]="validateMsg.tinh_code"></app-show-validate-errors> -->
                            </div>
                        </div>
                    </div>

                    <label class="text-primary font-weight-bold m-0">{{ localize.account_information }}</label>
                    <hr class="my-3">
                    <div class="row">
                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                                <label>{{ localize.username }} <span class="text-danger">*</span></label>
                                <input appTrimValue class="form-control" placeholder="{{ localize.fill_username }}"
                                    formControlName="username">
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="form.controls.username" [detail]="{ name: 'username'}"
                                    [errorMessages]="validateMsg.username"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                                <label class="d-flex align-items-center">
                                    <span>{{ localize.account_type }} <span class="text-danger">*</span></span>
                                    <span class="info-note d-flex ml-auto">
                                        <span class="material-icons text-grey-darkest">info</span>
                                        <span class="info-tooltip-desc">
                                            <ul>
                                                <li>
                                                    Cấp 1: Chỉ được chọn Gói dùng thử,
                                                    không bắt buộc upload GPKD.
                                                </li>
                                                <li>
                                                    Cấp 2: Tùy chọn gói dịch vụ,
                                                    bắt buộc upload GPKD.
                                                </li>
                                            </ul>
                                        </span>
                                    </span>
                                </label>
                                <ng-select formControlName="identityLevel" [items]="identityLevels" bindValue="id"
                                    bindLabel="label" [placeholder]="localize.fill_account_type"
                                    (change)="changedIdentityLevel($event)">
                                </ng-select>
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="form.controls.identityLevel" [detail]="{ name: 'identityLevel'}"
                                    [errorMessages]="validateMsg.identityLevel"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                                <div class="d-flex align-content-center">
                                    <label>{{ localize.package_service }} <span class="text-danger">*</span></label>
                                    <a class="link-click ml-auto" (click)="customizeInfraConfig()">Tuỳ chỉnh thông tin hạ tầng</a>
                                </div>
                                <ng-select formControlName="packageId" [items]="packageIds" bindValue="packInfoId"
                                    bindLabel="name"
                                    [placeholder]="localize.select_package_service"
                                    (change)="selectedPackage($event)">
                                </ng-select>
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="form.controls.packageId" [detail]="{ name: 'packageId'}"
                                    [errorMessages]="validateMsg.packageId"></app-show-validate-errors>
                            </div>
                        </div>
                    </div>

                    <label class="text-primary font-weight-bold m-0">{{ localize.business_information }}</label>
                    <hr class="my-3">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ localize.company_name }} <span class="text-danger">*</span></label>
                                <input appTrimValue class="form-control" placeholder="{{ localize.fill_company_name }}"
                                    formControlName="partyName">
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="form.controls.partyName" [detail]="{ name: 'partyName'}"
                                    [errorMessages]="validateMsg.partyName"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ localize.email }} <span class="text-danger">*</span></label>
                                <input appTrimValue class="form-control" placeholder="{{ localize.fill_email }}"
                                    formControlName="email">
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="form.controls.email" [detail]="{ name: 'email'}"
                                    [errorMessages]="validateMsg.email"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                                <label>{{ localize.short_name }} <span class="text-danger">*</span></label>
                                <input appTrimValue class="form-control" placeholder="{{ localize.fill_short_name }}"
                                    formControlName="shortName">
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="form.controls.shortName" [detail]="{ name: 'shortName'}"
                                    [errorMessages]="validateMsg.shortName"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                                <label>{{ localize.tax_number }} <span class="text-danger">*</span></label>
                                <input appTrimValue class="form-control" placeholder="{{ localize.fill_tax_number }}"
                                    formControlName="taxNumber">
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="form.controls.taxNumber" [detail]="{ name: 'taxNumber'}"
                                    [errorMessages]="validateMsg.taxNumber"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                                <label>{{ localize.tinh }} <span class="text-danger">*</span></label>
                                <ng-select formControlName="tinhId" [items]="tinhs" bindValue="id" bindLabel="label"
                                    [placeholder]="localize.select_tinh" (change)="changedTinh($event)">
                                </ng-select>
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="form.controls.tinhId" [detail]="{ name: 'tinhId'}"
                                    [errorMessages]="validateMsg.tinhId"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                                <label>{{ localize.huyen }} <span class="text-danger">*</span></label>
                                <ng-select formControlName="huyenId" [items]="huyens" bindValue="id" bindLabel="label"
                                    [placeholder]="localize.select_huyen" (change)="changedHuyen($event)">
                                </ng-select>
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="form.controls.huyenId" [detail]="{ name: 'huyenId'}"
                                    [errorMessages]="validateMsg.huyenId"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                                <label>{{ localize.xa }} <span class="text-danger">*</span></label>
                                <ng-select formControlName="xaId" [items]="xas" bindValue="id" bindLabel="label"
                                    [placeholder]="localize.select_xa">
                                </ng-select>
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="form.controls.xaId" [detail]="{ name: 'xaId'}"
                                    [errorMessages]="validateMsg.xaId"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                                <label>{{ localize.street }} <span class="text-danger">*</span></label>
                                <input appTrimValue class="form-control" placeholder="{{ localize.fill_street }}"
                                    formControlName="duong">
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="form.controls.duong" [detail]="{ name: 'duong'}"
                                    [errorMessages]="validateMsg.duong"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                                <label>{{ localize.sonha }} <span class="text-danger">*</span></label>
                                <input appTrimValue class="form-control" placeholder="{{ localize.fill_sonha }}"
                                    formControlName="sonha">
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="form.controls.sonha" [detail]="{ name: 'sonha'}"
                                    [errorMessages]="validateMsg.sonha"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                                <label class="d-flex align-items-center">
                                    <span>{{ localize.gpkd_full }} <span *ngIf="form.value.identityLevel === 2"
                                            class="text-danger"> *</span></span>
                                    <a class="cursor-pointer text-primary text-decoration-underline ml-auto"
                                        (click)="showFilePreview()">{{ localize.see_file }}</a>
                                </label>
                                <div class="input-group">
                                    <div class="custom-file custom-file-icon">
                                        <input #gpkdFile class="custom-file-input" id="inputGroupFile01"
                                            placeholder="{{ localize.select_attach_file }}"
                                            (input)="onAttachFileChanged($event)" type="file"
                                            accept=".pdf,.png,.jpeg,.jpg">
                                        <label class="custom-file-label" for="inputGroupFile01">{{ inputFileLabel
                                            }}</label>
                                        <span class="icon-file material-icons">attach_file</span>
                                    </div>
                                </div>
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="form.controls.file" [detail]="{ name: 'file'}"
                                    [errorMessages]="validateMsg.file"></app-show-validate-errors>
                            </div>
                        </div>
                    </div>

                    <label class="text-primary font-weight-bold m-0">{{ localize.represent_person_information }}</label>
                    <hr class="my-3">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ localize.fullname }} <span class="text-danger">*</span></label>
                                <input appTrimValue id="fullNameInput" class="form-control"
                                    placeholder="{{ localize.fill_fullName }}" formControlName="fullName">
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="form.controls.fullName" [detail]="{ name: 'fullName'}"
                                    [errorMessages]="validateMsg.fullName"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                                <label>{{ localize.phone_number }} <span class="text-danger">*</span></label>
                                <input appTrimValue appTrackerPhoneNumber class="form-control"
                                    placeholder="{{ localize.fill_phone_number }}" formControlName="phoneNumber">
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="form.controls.phoneNumber" [detail]="{ name: 'phoneNumber'}"
                                    [errorMessages]="validateMsg.phoneNumber"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                                <label>{{ localize.birthday }} <span class="text-danger">*</span></label>
                                <div class="input-group">
                                    <input class="form-control" ngbDatepicker #birthday="ngbDatepicker"
                                        [control]="form.controls.birthday" [value]="form.value.birthday"
                                        (dateSelect)="changeDateFilter($event)" placeholder="dd/MM/yyyy"
                                        [maxDate]="{year: currentDate.getFullYear(), month: currentDate.getMonth()+1, day: currentDate.getDate()}"
                                        appInputMark [options]="optionsCleave">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary" (click)="birthday.toggle()"
                                            type="button"><em class="fas fa-calendar-alt"></em></button>
                                    </div>
                                    <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                        [control]="form.controls.birthday" [detail]="{ name: 'birthday'}"
                                        [errorMessages]="validateMsg.birthday"></app-show-validate-errors>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                                <label>{{ localize.gender }} <span class="text-danger">*</span></label>
                                <ng-select formControlName="gioiTinhId" [items]="gioiTinhs" bindValue="id"
                                    bindLabel="label" [placeholder]="localize.select_gender" [clearable]="false">
                                </ng-select>
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="form.controls.gioiTinhId" [detail]="{ name: 'gioiTinhId'}"
                                    [errorMessages]="validateMsg.gioiTinhId"></app-show-validate-errors>
                            </div>
                        </div>
                        
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>

<app-spinner></app-spinner>

<app-preview-modal *ngIf="showFile" [file]="pdfFile" [name]="inputFileLabel" [useDownloadEvent]="attachFile == null"
    (close)="closePreview()"></app-preview-modal>

<app-customize-infra-config 
    *ngIf="showCustomizeInfraInfo"
    [packageInfo]="packageInfo"
    [dropListPackageData]="packageIds"
    (backEvent)="handleBackEvent($event)"
    (savedData)="handleSavedData($event)">
</app-customize-infra-config>