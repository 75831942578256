import {AccountItem} from "./account-item.model"
import { MainResponse } from '../../../main-response.model';

export class AccountObjectResponse extends MainResponse {
    
    constructor(obj = null) {
        super();
        obj = obj || {};
        this.init(obj);
        if (obj.object && obj.object instanceof Array) {
            obj.object.forEach((item) => {
                this.object = new AccountItem(item);

            })
        }
    }
}