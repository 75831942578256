<app-mini-spinner *ngIf="spinnerLgShow() | async"></app-mini-spinner>
<!-- MARK màn hình khi chưa có chữ kí số -->
<section *ngIf="infoCA.id === ''" class="content">
    <div class="text-center">
        <img src="assets/img/services/emty-signature-img.png" style="margin-top: 5px;" class="col-md-3">
        <div class="mb-2">
            <p class="mb-1">{{ 'SignServerManager_label_1' | translate }}</p>
            <p>{{ 'SignServerManager_label_2' | translate }}</p>
        </div>
        <div class="px-md-5 p-3 ">
            <a class="text-primary cursor-pointer" (click)="addCASignServer()">{{ 'SmartcaManager_label_3' | translate }}</a>
        </div>
    </div>
</section>

<!-- MARK đã có dữ liệu chữ ký số USB-Token -->
<section *ngIf="infoCA.id != ''">
    <div class="row">
        <div class="col-md-4">
            <div class="card shadow-none pb-2">
                <div class="card-body">
                    <div class="row pt-2 pb-2">
                        <div class="col-md-auto align-self-end">
                            <label class="text-lg">{{ 'SmartcaManager_label_4' | translate }}</label>
                        </div>
                        <div class="col-md-auto ml-auto">
                            <a (click)="logoutSignServer()" class="text-primary cursor-pointer d-inline-flex"><u>{{ 'SmartcaManager_label_5' | translate }}</u> <span
                                    class="material-icons text-primary ml-2 ">exit_to_app</span></a>
                        </div>
                    </div>
                    <div class="input-group mt-3">
                        <form [formGroup]="formSearch" (ngSubmit)="searchData(formSearch.value.search)"
                            class="input-group">
                            <div class="input-group-prepend">
                                <button class="btn btn-primary d-inline-flex pr-2 pl-2" type="submit"><span
                                        class="material-icons">search</span></button>
                            </div>
                            <input (input)="eventInputSearch($event)" type="text" class="form-control"
                                formControlName="search" placeholder="{{ 'SignServerManager_ph_1' | translate }}" aria-label=""
                                aria-describedby="basic-addon1">
                        </form>
                    </div>

                </div>
                <div class="position-relative">
                    <app-mini-spinner *ngIf="spinnerLShow() | async"></app-mini-spinner>
                    <ng-template #noData>
                        <div class="no-content-list">
                            <img class="mb-2" src="assets/img/icon/icon-no-user-group.png" width="100">
                            <p class="mb-1">{{ 'SignServerManager_label_5' | translate }}</p>
                        </div>
                    </ng-template>

                    <div *ngIf="infoCA.certificateList.length > 0; else noData" class="content-list fade show">
                        <div *ngFor="let item of infoCA.certificateList; let i = index" (click)="selectCert(item)"
                            class="user-group-item content-list-item px-4 pt-2"
                            [class.active]="selectedCert.id === item.id">
                            <div class="border-bottom d-flex">
                                <span class="material-icons icon-item pr-3">gesture</span>
                                <div class="flex-grow-1">
                                    <label class="title-item">{{ item.accountEmail }}</label>
                                </div>
                                <span *ngIf="item.serialNumber === infoCA.certificateDefault.serialNumber"
                                    class="text-black pl-4">{{ 'SignServerManager_label_3' | translate }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="totalElement > maxSize" class="pagination justify-content-end pages">
                <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="5" [rotate]="true"
                    [ellipses]="false" [pageSize]="maxSize" (pageChange)="loadPage($event)" [boundaryLinks]="true"
                    aria-label="Default pagination">
                    <ng-template ngbPaginationFirst>{{ 'text_first' | translate }}</ng-template>
                    <ng-template ngbPaginationLast>{{ 'text_last' | translate }}</ng-template>
                    <ng-template ngbPaginationPrevious>&laquo;</ng-template>
                    <ng-template ngbPaginationNext>&raquo;</ng-template>
                    <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                </ngb-pagination>
            </div>
        </div>
        <div class="col-md-8">
            <div *ngIf="selectedCert.id != '' && !editSignatureConfig">
                <div class="card shadow-none">
                    <div class="card-body position-relative">
                        <div class="row">
                            <div class="col-md-auto align-self-end">
                                <label class="text-lg">{{ 'DigitalSignatureManager_label_3' | translate }}</label>
                            </div>
                            <div class="col-md-auto ml-auto">
                            </div>
                        </div>
                        <hr>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="infor-box-header d-flex">
                                    <label class="text-primary flex-grow-1 mb-0">{{ 'SignServerManager_label_6' | translate }}</label>
                                </div>
                                <hr>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="mb-3">
                                            <label>{{ 'SignServerManager_label_7' | translate }}:&nbsp;</label>
                                            <span>{{ infoCA.email }}</span>
                                        </div>
                                        <div class="mb-3">
                                            <label>{{ 'SignServerManager_label_8' | translate }}:&nbsp;</label>
                                            <span>{{ infoCA.accessTokenExpiredTime | date: "dd/MM/yyyy" }}</span>
                                        </div>
                                        <div class="mb-3">
                                            <label>{{ 'SignServerManager_label_9' | translate }}:&nbsp;</label>
                                            <span>{{ infoCA.refreshTokenExpiredTime | date: "dd/MM/yyyy" }}</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="infor-box-header d-flex">
                                    <label class="text-primary flex-grow-1 mb-0">{{ 'SignServerManager_label_4' | translate }}</label>
                                </div>
                                <hr>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="mb-3">
                                            <label>{{ 'SignServerManager_label_10' | translate }}:&nbsp;</label>
                                            <span>{{ selectedCert.accountEmail }}</span>
                                        </div>
                                        <div class="mb-3">
                                            <label>{{ 'SignServerManager_label_8' | translate }}:&nbsp;</label>
                                            <span>{{ selectedCert.validFrom | date: "dd/MM/yyyy" }}</span>
                                        </div>
                                        <div class="mb-3">
                                            <label>{{ 'SignServerManager_label_9' | translate }}:&nbsp;</label>
                                            <span>{{ selectedCert.validTo | date: "dd/MM/yyyy" }}</span>
                                        </div>
                                        <div class="mb-3">
                                            <label>{{ 'status' | translate }}:&nbsp;</label>
                                            <span *ngIf="selectedCert.status == 'GOOD'"
                                                class="badge badge-success-new p-2">{{ 'SignServerManager_label_11' | translate }}</span>
                                            <span *ngIf="selectedCert.status != 'GOOD'"
                                                class="badge badge-warning-new p-2">{{ 'SignServerManager_label_12' | translate }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="infor-box-header">
                                    <label class="text-primary flex-grow-1 mb-0">{{ 'SignServerManager_label_13' | translate }}</label>
                                </div>
                                <hr>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="mb-3" [ngSwitch]="configCA.visibleType">
                                            <label>{{ 'SmartcaManager_label_16' | translate }}:&nbsp;</label>
                                            <span *ngSwitchCase="0">{{ 'ContractCensorship_ts_10' | translate }}</span>
                                            <span *ngSwitchCase="1">{{ 'ContractCensorship_ts_11' | translate }}</span>
                                            <span *ngSwitchCase="2">{{ 'ContractCensorship_ts_12' | translate }}</span>
                                            <span *ngSwitchCase="3">{{ 'ContractCensorship_ts_13' | translate }}</span>
                                            <span *ngSwitchCase="4">{{ 'ContractCensorship_ts_14' | translate }}</span>
                                            <span *ngSwitchCase="5">{{ 'ContractCensorship_ts_15' | translate }}</span>
                                            <span *ngSwitchDefault>{{ 'ContractCensorship_ts_15' | translate }}</span>
                                        </div>
                                        <div class="mb-3">
                                            <label>{{ 'SmartcaManager_label_17' | translate }}:&nbsp;</label>
                                            <span *ngIf="configCA.signBy && configCA.signDate">{{ 'SmartcaManager_label_18' | translate }}</span>
                                            <span *ngIf="!configCA.signBy && configCA.signDate">{{ 'SmartcaManager_label_19' | translate }}</span>
                                            <span *ngIf="configCA.signBy && !configCA.signDate">{{ 'SmartcaManager_label_20' | translate }}</span>
                                        </div>
                                        <div class="mb-3">
                                            <label>{{ 'ContractCensorship_label_19' | translate }}:&nbsp;</label>
                                            <span>{{configCA.fontSize || "13"}}</span>
                                        </div>
                                        <div class="mb-3">
                                            <label>{{ 'USBTokenManager_hinh_anh' | translate }}:&nbsp;</label>
                                            <p>
                                                <img class="img img-sign w-50" *ngIf="configCA.base64Image || _default_img"
                                                    [src]="'data:image/png;base64,' + (configCA.base64Image || _default_img)">
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <app-mini-spinner *ngIf="spinnerRShow() | async"></app-mini-spinner>
                    </div>
                </div>
                <div class="d-flex justify-content-end mb-2">
                    <button *ngIf="infoCA.certificateDefault.id !== selectedCert.id"
                        class="btn btn-outline-primary d-inline-flex mr-4" (click)="setDefault(selectedCert.id)"><span
                            class="material-icons mr-2">book</span>{{ 'SignServerManager_label_14' | translate }}</button>
                    <button class="btn btn-primary d-inline-flex" (click)="actEditSignatureConfig(selectedCert.id)"><span class="material-icons mr-2">edit</span>{{ 'btn_edit' | translate }}</button>
                </div>
            </div>

            <app-service-signature-config #config (onCancel)="actCancelUpdate($event)" *ngIf="editSignatureConfig && editIdSignaTure !== ''" [id]="editIdSignaTure"></app-service-signature-config> 
        </div>
    </div>
</section>

<ng-template #contractLoginSignServer let-c="close" let-d="dismiss">
    <div class="modal-header border-0 pb-0 mb-0">
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span class="material-icons">
                cancel
            </span>
        </button>
    </div>
    <form [formGroup]="formLoginSignServer">
        <div class="modal-body py-0">
            <h5 class="modal-title w-100 text-center mb-3" id="modal-basic-title"><label>{{ 'SignServerManager_label_15' | translate }}</label></h5>
        
            <div class="form-group">
                <label>{{ 'SignServerManager_label_10' | translate }}<span class="text-danger">*</span>: </label>
                <input formControlName="email" class="form-control form-control-lg" type="text" placeholder="{{ 'SignServerManager_label_10' | translate }}">
                <app-show-validate-errors [isSubmit]="isSubmitLoginSignServer" [errorMessages]="validateMsgLoginSignServer.email"
                    [control]="formLoginSignServer.controls.email" [detail]="{ name: 'email' }"></app-show-validate-errors>
            </div>
            <div class="form-group">
                <label>{{ 'SmartcaManager_label_24' | translate }}<span class="text-danger">*</span>: </label>
                <div class="position-relative">
                    <input appInputPassword formControlName="password" class="form-control form-control-lg" type="password" placeholder="{{ 'SmartcaManager_label_24' | translate }}">
                </div>
                <app-show-validate-errors [isSubmit]="isSubmitLoginSignServer" [errorMessages]="validateMsgLoginSignServer.password"
                    [control]="formLoginSignServer.controls.password" [detail]="{ name: 'password' }"></app-show-validate-errors>
            </div>
        </div>
        <div class="modal-footer border-0 d-flex justify-content-center">
            <button type="submit" class="btn btn-lg btn-primary" (click)="submitLoginSignServer()">{{ 'SmartcaManager_label_25' | translate }}</button>
        </div>
    </form>
</ng-template>