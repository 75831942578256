<div class="row">
    <div class="col-md-7">
        <div class="card shadow-none">
            <div class="card-body position-relative">
                <label class="text-primary">Thông tin doanh nghiệp</label>
                <hr>
                <div>
                    <!-- Ten doanh nghiep -->
                    <div class="e-block-field row">
                        <div class="col-md-4 col-xl-3">
                            <label class="e-lb-field">Tên doanh nghiệp</label>
                        </div>
                        <div class="col-md-8">
                            <span class="">{{ personalDetailObject?.tenToChuc }}</span>
                        </div>
                    </div>
                    <!-- Ten viet tat -->
                    <div class="e-block-field row">
                        <div class="col-md-4 col-xl-3">
                            <label class="e-lb-field">Tên viết tắt</label>
                        </div>
                        <div class="col-md-8">
                            <span class="">{{ personalDetailObject?.tenRutGon }}</span>
                        </div>
                    </div>
                    <!-- Ma so thue -->
                    <div class="e-block-field row">
                        <div class="col-md-4 col-xl-3">
                            <label class="e-lb-field">Mã số thuế</label>
                        </div>
                        <div class="col-md-8">
                            <span class="">{{ personalDetailObject?.maSoThue }}</span>
                        </div>
                    </div>
                    <!-- GPKD -->
                    <div class="e-block-field row">
                        <div class="col-md-4 col-xl-3">
                            <label class="e-lb-field">GPKD</label>
                        </div>
                        <div class="col-md-8">
                            <span class="">
                                <a class="text-decoration-underline text-primary" style="cursor: pointer;" (click)="actDownloadGPKD(personalDetailObject?.partyId)">
                                    {{personalDetailObject?.gpkdFilename}}
                                </a>
                            </span>
                        </div>
                    </div>
                    <!-- Dia chi -->
                    <div class="e-block-field row">
                        <div class="col-md-4 col-xl-3">
                            <label class="e-lb-field">Địa chỉ</label>
                        </div>
                        <div class="col-md-8">
                            <span class="">{{ personalDetailObject?.userInformations.diaChi }}</span>
                        </div>
                    </div>
                </div>

                <label class="text-primary">Thông tin người đại diện</label>
                <hr>
                <div>
                    <!-- Ho va ten -->
                    <div class="e-block-field row">
                        <div class="col-md-4 col-xl-3">
                            <label class="e-lb-field">Họ và tên</label>
                        </div>
                        <div class="col-md-8">
                            <span class="">{{ personalDetailObject?.userInformations.ten }}</span>
                        </div>
                    </div>
                    <!-- Email -->
                    <div class="e-block-field row">
                        <div class="col-md-4 col-xl-3">
                            <label class="e-lb-field">Email</label>
                        </div>
                        <div class="col-md-8">
                            <span class="">{{ personalDetailObject?.userInformations.email }}</span>
                        </div>
                    </div>
                    <!-- So dien thoai -->
                    <div class="e-block-field row">
                        <div class="col-md-4 col-xl-3">
                            <label class="e-lb-field">Số điện thoại</label>
                        </div>
                        <div class="col-md-8">
                            <span class="">{{ personalDetailObject?.userInformations.sdt }}</span>
                        </div>
                    </div>
                    <!-- Ngay sinh -->
                    <div class="e-block-field row">
                        <div class="col-md-4 col-xl-3">
                            <label class="e-lb-field">Ngày sinh</label>
                        </div>
                        <div class="col-md-8">
                            <span class="">{{ personalDetailObject?.userInformations.ngaySinh | date: 'dd/MM/yyyy' }}</span>
                        </div>
                    </div>
                    <!-- Gioi tinh -->
                    <div class="e-block-field row">
                        <div class="col-md-4 col-xl-3">
                            <label class="e-lb-field">Giới tính</label>
                        </div>
                        <div class="col-md-8">
                            <span class="">{{ personalDetailObject?.userInformations.gioiTinh }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-5">
        <div class="card shadow-none">
            <div class="card-body position-relative">
                <label class="text-primary">Thông tin kinh doanh</label>
                <hr>
                <div>
                    <div class="e-block-field row">
                        <div class="col-md-5">
                            <label class="e-lb-field">Mã thuê bao</label>
                        </div>
                        <div class="col-md-7">
                            <span class="">{{ personalDetailObject?.subscriptionCode }}</span>
                        </div>
                    </div>
                    <div class="e-block-field row">
                        <div class="col-md-5">
                            <label class="e-lb-field">Loại hình thuê bao</label>
                        </div>
                        <div class="col-md-7">
                            <span class="">{{ personalDetailObject?.subscriptionType }}</span>
                        </div>
                    </div>
                    <div class="e-block-field row">
                        <div class="col-md-5">
                            <label class="e-lb-field">Mã tỉnh</label>
                        </div>
                        <div class="col-md-7">
                            <span class="">{{ personalDetailObject?.provinceCode }}</span>
                        </div>
                    </div>
                    <div class="e-block-field row">
                        <div class="col-md-5">
                            <label class="e-lb-field">Mã HRM AM</label>
                        </div>
                        <div class="col-md-7">
                            <span class="">{{personalDetailObject?.requesterCode}}</span>
                        </div>
                    </div>
                    <div class="e-block-field row">
                        <div class="col-md-5">
                            <label class="e-lb-field">Mã HRM người nhập phiếu</label>
                        </div>
                        <div class="col-md-7">
                            <span class="">{{personalDetailObject?.creatorCode}}</span>
                        </div>
                    </div>
                </div>

                <label class="text-primary">Thông tin tài khoản</label>
                <hr>
                <div>
                    <div class="e-block-field row">
                        <div class="col-md-5">
                            <label class="e-lb-field">Tên tài khoản</label>
                        </div>
                        <div class="col-md-7">
                            <span class="">{{ personalDetailObject?.userInformations.username }}</span>
                        </div>
                    </div>
                    <div class="e-block-field row">
                        <div class="col-md-5">
                            <label class="e-lb-field">Loại tài khoản</label>
                        </div>
                        <div class="col-md-7">
                            <span class="">Tài khoản định danh cấp {{ personalDetailObject?.identityLevel }}</span>
                        </div>
                    </div>
                    <div class="e-block-field row">
                        <div class="col-md-5">
                            <label class="e-lb-field">Gói dịch vụ</label>
                        </div>
                        <div class="col-md-7">
                            <span class="">{{ personalDetailObject?.packageName }}</span>
                        </div>
                    </div>
                    <div class="e-block-field row">
                        <div class="col-md-5">
                            <label class="e-lb-field">Trạng thái</label>
                        </div>
                        <div class="col-md-7">
                            <span *ngIf="personalDetailObject?.disable !== ''" class="badge p-2" [class.badge-success-new]="personalDetailObject?.disable === 'N'" [class.badge-danger-new]="personalDetailObject?.disable === 'Y'">
                                {{ personalDetailObject?.disable == 'Y' ? "Ngưng hoạt động" : "Đang hoạt động" }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-preview-modal *ngIf="showPreviewGPKD" [file]="fileGPKD" [name]="personalDetailObject?.gpkdFilename" (close)="handleClosePreview()"></app-preview-modal>
