import { Component, Input, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { HomeService } from 'src/app/utils/services/home/home.service';
import { HomeV4Component } from '../home-v4/home-v4.component';
import * as $ from "jquery";

@Component({
  selector: 'app-pricelist',
  templateUrl: './pricelist.component.html',
  styleUrls: ['./pricelist.component.scss']
})
export class PricelistComponent implements OnInit {

  // _experience: any = null;
  // get experience() {
  //   return this._experience;
  // }
  // @Input() set experience(value) {
  //   this._experience = value;
  // }
  
  // get appLink() {
  //   return this._appLink;
  // }
  // _appLink: any = null;
  // @Input() set appLink(value) {
  //   this._appLink = value;
  // }

  @Input() experience: any = null;
  @Input() appLink: any = null;
  @Input() configs: any = [];
  @Input() link: any = null;

  constructor(
    private homeV4: HomeV4Component,
    private homeService: HomeService
  ) { }

  // individualFreePackage: any = null;
  // individualContactPackage: any = null;
  // individualAdvancedPackage: any = null;

  individualData: any = null;
  organizationData: any = null;

  // organizationFreePackage: any = null;
  // organizationContactPackage: any = null;
  // organizationAdvancedPackage: any = null;

  registerToBuy: any;
  ngAfterViewInit() {
    $(".btn-register-package").click(function() {
      $('html,body').animate({
              scrollTop: $(".price_list-ss2").offset().top
          },
          'slow');
  });

  }

  ngOnInit(): void {
    // this.getIndividualData(this.homeV4.translate.currentLang);
    // this.getOrganizationData(this.homeV4.translate.currentLang);
    this.getData(this.homeV4.translate.currentLang);
    this.getRegisterToBuy(this.homeV4.translate.currentLang);



  }

  getData(language) {
    forkJoin([
      this.homeService.getDataByCodeCategory("individual", language),
      this.homeService.getDataByCodeCategory("organization", language)
    ]).subscribe((res: any) => {
      this.individualData = res[0]?.object?.item;
      this.organizationData = res[1]?.object?.item;
    })
  }

  // getIndividualData(language) {
  //   forkJoin([
  //     this.homeService.getDataByCodeCategory("individualFreePackage", language),
  //     this.homeService.getDataByCodeCategory("individualContactPackage", language),
  //     this.homeService.getDataByCodeCategory("individualAdvancedPackage", language)
  //   ]).subscribe((res: any) => {
  //     this.individualFreePackage = res[0]?.object?.item;
  //     this.individualContactPackage = res[1]?.object?.item;
  //     this.individualAdvancedPackage = res[2]?.object?.item;
  //   });
  // }

  // getOrganizationData(language) {
  //   forkJoin([
  //     this.homeService.getDataByCodeCategory("organizationFreePackage", language),
  //     this.homeService.getDataByCodeCategory("organizationContactPackage", language),
  //     this.homeService.getDataByCodeCategory("organizationAdvancedPackage", language)
  //   ]).subscribe((res: any) => {
  //     this.organizationFreePackage = res[0]?.object?.item;
  //     this.organizationContactPackage = res[1]?.object?.item;
  //     this.organizationAdvancedPackage = res[2]?.object?.item;
  //   });
  // }

  getRegisterToBuy(language) {
    this.homeService.getDataByCodeCategory("registerToBuy", language).subscribe((res: any) => {
      this.registerToBuy = res?.object?.item;
    });
  }

  reload(language) {
    // this.getIndividualData(language);
    // this.getOrganizationData(language);
    this.getData(language);
    this.getRegisterToBuy(language);
  }

}
