<app-spinner></app-spinner>
<div class="content-header">
    <div class="container-fluid">
    <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </div>
  <div class="content">
        <div class="container-fluid">
            <div class="card shadow-none mb-4">
                <div class="card-body">
                    <section class="p-3">
                        <div class="row">
                            <div class="col-lg-3 col-md-5">
                                <h5 class="m-0 "><label>Thống kê số lượng</label></h5>
                            </div>
                            <div class="col-md-auto ml-auto">
                                <!-- <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="downloadSample()">
                                    <span class="material-icons mr-1">file_download</span>Xuất Excel
                                </button> -->
                                <div  class="btn-group">
                                    <button class="btn btn-outline-primary dropdown-toggle d-inline-flex align-items-center mr-3"
                                        data-toggle="dropdown" id="configColTable"><span class="material-icons mr-1">visibility</span>Hiển thị</button>
                                    <div class="dropdown-menu border-0" aria-labelledby="configColTable" style="width: 190px;">
                                        <div *ngFor="let item of listConfigColTable" class="tcheck-primary d-inline-block px-3">
                                            <input type="checkbox" id="{{ item.name }}" [checked]="configColTable[item.name]">
                                            <label for="{{ item.name }}" class="w-100" [class.checked]="configColTable[item.name]"
                                                (click)="actConfigColTableView($event, item.name)">
                                                {{ item.label }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="btn-group">
                                    <button class="btn btn-outline-primary dropdown-toggle d-inline-flex align-items-center mr-3"
                                        data-toggle="dropdown" id="configfilter"><span class="material-icons mr-1">tune</span>Bộ
                                        lọc</button>
                                    <div class="dropdown-menu border-0" aria-labelledby="configfilter" style="width: 170px;">
                                        <div *ngFor="let item of listFitler" class="tcheck-primary d-inline-block px-3">
                                            <input type="checkbox" id="{{ item.name }}" [checked]="configFilter[item.name]">
                                            <label for="{{ item.name }}" class="w-100" [class.checked]="configFilter[item.name]"
                                                (click)="actConfigFilter($event, item.name)">
                                                {{ item.label }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="cleanFilter()"> Đặt lại</button>
                                <button class="btn btn-primary d-inline-flex mr-3" (click)="searchWithFilter()"> Áp dụng</button>
                            </div>
                        </div>
                        <hr class="my-2">
                    </section>
                    <section class="px-3 my-2">
                        <div class="row">
                            <div class="col-md-2" [class.d-none]="!configFilter.tuNgay">
                                <div class="form-group">
                                    <label for="">Từ ngày phát hành</label>
                                    <div class="input-group">
                                        <input id="fromDateReport" #fromDateFilter name="fromDatePicker"
                                            (input)="dateInput($event, 'fromDate')" (dateSelect)="changeDate($event, 'from')"
                                            class="form-control" placeholder="dd/MM/yyyy" ngbDatepicker #pickDate="ngbDatepicker"
                                            [minDate]="{year: 1970, month: 1, day: 1}"
                                            >
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary" (click)="pickDate.toggle()" type="button"><em
                                                    class="fas fa-calendar-alt"></em></button>
                                        </div>
                                    </div>
                                    <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.fromDate"
                                        [control]="f_fromDate" [detail]="{ name: 'fromDate' }"></app-show-validate-errors>
                                </div>
                            </div>
                            <div class="col-md-2" [class.d-none]="!configFilter.denNgay">
                                <div class="form-group">
                                    <label for="">Đến ngày phát hành</label>
                                    <div class="input-group">
                                        <input id="toDateReport" #toDateFilter name="toDatePicker" (input)="dateInput($event, 'toDate')"
                                            (dateSelect)="changeDate($event, 'to')" class="form-control" placeholder="dd/MM/yyyy"
                                            ngbDatepicker #pickDate1="ngbDatepicker" [minDate]="{year: 1970, month: 1, day: 1}"
                                            >
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary" (click)="pickDate1.toggle()" type="button"><em
                                                    class="fas fa-calendar-alt"></em></button>
                                        </div>
                                    </div>
                                    <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.toDate"
                                        [control]="f_toDate" [detail]="{ name: 'toDate' }"></app-show-validate-errors>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-9 col-lg-8 col-md-7">
                    <div class="card shadow-none mb-4">
                        <h5 class="m-0 ml-3 mt-3 "><label>Số lượng theo danh mục chức năng</label></h5>
                        <div class="card-body">
                            <table id="header-table" class="table header-table border">
                                <thead>
                                    <tr >
                                        <th class="table-sticky-col1" scope="col">STT</th>
                                        <th scope="col" [class.d-none]="!configColTable.danhMucChucNang">Danh mục chức năng</th>
                                        <th scope="col" [class.d-none]="!configColTable.caNhan">Cá nhân</th>
                                        <th scope="col" [class.d-none]="!configColTable.dichVu">Dịch vụ</th>
                                        <th scope="col" [class.d-none]="!configColTable.giaiPhap">Giải pháp</th>
                                        <th scope="col" [class.d-none]="!configColTable.tongSo">Tổng số</th>
                                    </tr>
                                </thead>
                            </table>
                            <div id="table-scroll" class="table table-scroll table-responsive">
                                <table id="main-table" class="table main-table border">
                                <tbody >
                                    <tr  *ngFor="let item of listReportService, let i = index">
                                        <td class="table-sticky-col1" scope="col">{{i+1}}</td>
                                        <td [class.d-none]="!configColTable.danhMucChucNang">{{item.danhMucChucNang}}</td>
                                        <td  [class.d-none]="!configColTable.caNhan">{{item.caNhan}}</td>
                                        <td  [class.d-none]="!configColTable.dichVu">{{item.dichVu}}</td>
                                        <td  [class.d-none]="!configColTable.giaiPhap">{{item.giaiPhap}}</td>
                                        <td  [class.d-none]="!configColTable.tongSo">{{item.tongSo}}</td>
                                    </tr>
                                </tbody>
                                </table>
                            </div>
                            <table id="header-table" class="table main-table ">
                                <tbody>
                                    <tr >
                                        <td ></td>
                                        <td >Tổng số</td>
                                        <td style="text-align:center">{{totalBusiness}}</td>
                                        <td style="text-align:center">{{totalConsumer}}</td>
                                        <td style="text-align:center">{{totalEnterprise}}</td>
                                        <td style="text-align:center">{{total}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3 col-lg-4 col-md-">
                    <div class="card shadow-none mb-4">
                        <div class="card-header">
                            <h5 class="m-0 mb-3 mt-1 "><label>Số lượng theo khách hàng</label></h5>
                        </div>
                        <div class="card-body"  *ngIf="dataDoughnutChar.length > 0">
                            <canvas baseChart [chartType]="doughnutChartType" [datasets]="doughnutChartDatasets"
                            [labels]="doughnutChartLabels" [options]="doughnutCharOption" 
                            (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)">
                            </canvas>
                        </div>
                        <div *ngIf="dataDoughnutChar.length == 0">
                            <app-empty-block></app-empty-block>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  </div>
