export const localize_modal = {
    modal_title_noti: "Thông báo",
    btn_confirm: 'Xác nhận',
    btn_cancel: 'Hủy',
    btn_ok: 'Đồng ý',
    btn_close: 'Đóng',
    btn_save: 'Lưu',
    btn_login: "Đăng nhập",
    btn_relogin: "Đăng nhập lại",
    btn_goto_vnpt_sign_config: "Đến cấu hình",
    btn_approved: "Phê duyệt",
    btn_back_to_dashboard: "Quay lại trang chủ",
    btn_update: "Cập nhật",
    btn_add: "Thêm",
    btn_delete: "Xóa",
    btn_edit: "Chỉnh sửa",
    model_title_confirm: "Xác nhận",
    btn_not_now: "Để sau",
    btn_continue: "Tiếp tục",
    btn_save_changed: "Lưu thay đổi",
    btn_download: "Tải xuống"
}