import { MainResponse } from "../main-response.model";
import { BlogTypeItem } from "./blog-type-item.model";

export class BlogTypeResponse extends MainResponse{
  constructor(obj = null) {
    super();
    obj = obj || {};
    this.init(obj);
    if (obj.object && obj.object instanceof Array) {
        obj.object.forEach((item) => {
            this.object = new BlogTypeItem(item);

        })
    }
 }

}