<h5 class="ec-tab-content-title mt-3">{{ 'ContractCensorship_title' | translate }}</h5>

<section *ngIf="contractInternalData.length <= 0 && signType === ''" class="ec-dont-have-censorship mt-3">
    <p class="text-grey-darkest mt-3">{{ 'ContractCensorship_msg_1' | translate }}</p>
</section>

<section *ngIf="contractInternalData.length > 0 && signType === ''" class="ec-dont-have-censorship mt-3">
    <p class="text-grey-darkest mt-3">{{ 'ContractCensorship_msg_2' | translate: { amount: contractInternalData.length } }}</p>
</section>

<section *ngIf="contractInternalData.length > 0 && signType === ''" class="ec-dont-have-censorship mt-3">
    <p *ngIf="currentInternalSign" class="mt-3">
        <span>{{ 'ContractCensorship_msg_3' | translate: { signType: (currentInternalSign.signType === "DRAFT" ? "ContractCensorship_signType_1" : "ContractCensorship_signType_2") | translate } }} <b>{{ currentInternalSign.email || currentInternalSign.ten || currentInternalSign.fullName }}</b></span>
    </p>
    <p><span *ngIf="yourSequenceSign.length > 0">{{ 'ContractCensorship_msg_4' | translate }} <span *ngFor="let ii of yourSequenceSign; let i = index">{{ ii }}{{ i < yourSequenceSign.length - 1 ? ', ' : ' ' }}</span></span></p>
</section>

<section *ngIf="contractInternalData.length > 0 && signType === ''" class="ec-log-censorship mt-3">
    <div *ngFor="let item of contractInternalData; let i = index" class="row">
        <div class="col-12">
            <div class="ec-block-status {{ getClassBorder(item.action, i) }}">
                <div class="ec-block-status-icon">
                    <img *ngIf="item.action != ''" src="{{ imgStatus(item.action) }}" width="24" height="24">
                    <span *ngIf="item.action == ''" class="span-status mr-2"
                    [class.bg-prỉmary]="item.isCurrentUserSign" 
                    [class.bg-secondary]="!item.isCurrentUserSign" 
                    style="width: 24px; height: 24px; border-radius: 50%;">
                        <span class="">{{ item.sequence }}</span>
                    </span>
                </div>
                <div class="ec-block-status-content">
                    <p><span class="font-weight-bold">{{ item.email || item.ten || item.fullName }}</span> {{ descriptionStatus(item.action, item.signType) }}</p>
                    <span class="ec-part-name">{{ item.departmentName }}</span> 
                    <!-- isApprovedInternal(item.action) -->
                    <div *ngIf="false" class="col-12">
                        <hr class="my-2">
                        <div class="row">
                            <div class="e-block-field col-lg-6 mb-2">
                                <label class="e-lb-field">{{ 'ContractCensorship_label_1' | translate }}</label>
                                <span>{{ item.ten }}</span>
                            </div>
                            <div class="e-block-field col-lg-6 mb-2">
                                <label class="e-lb-field">{{ 'ContractCensorship_label_2' | translate }}</label>
                                <span></span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="e-block-field col-lg-6 mb-2">
                                <label class="e-lb-field">{{ 'ContractCensorship_label_3' | translate }}</label>
                                <span></span>
                            </div>
                            <div class="e-block-field col-lg-6 mb-2">
                                <label class="e-lb-field">{{ 'ContractCensorship_label_4' | translate }}</label>
                                <span>{{ item.signDate }}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="e-block-field col-lg-6 mb-2">
                                <label class="e-lb-field">{{ 'ContractCensorship_label_5' | translate }}</label>
                                <span></span>
                            </div>
                            <div class="e-block-field col-lg-6 mb-2">
                                <label class="e-lb-field">{{ 'ContractCensorship_label_6' | translate }}</label>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="isRejectInternal(item.action)" class="col-12">
                        <hr class="my-2">
                        <div class="row">
                            <div class="e-block-field reason-deny col-12 mb-2">
                                <label class="e-lb-field">{{ 'ContractCensorship_label_7' | translate }}</label>
                                <span>{{ item.reason || ('ContractCensorship_label_8' | translate) }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div *ngIf="i < contractData.contractInternalData.length - 1" class="col-12">
            <hr class="w-100 border">
        </div> -->
    </div>
</section>

<section *ngIf="signType !== ''" id="action-SIGN" class="">
    <div class="w-100 d-flex mt-3">
        <div *ngIf="signType !== 'SMARTCA' || (signType === 'SMARTCA' && !showLayoutConfirmSmartCA && currentStepSignInternal != 2)" class="sign-back" (click)="backStepSign(currentStepSignInternal)">
            <span class="material-icons">
                arrow_back
            </span>
        </div>
        <h5 *ngIf="signType !== 'SMARTCA' || (signType === 'SMARTCA' && !showLayoutConfirmSmartCA)" class="font-weight-bold">{{ 'ContractCensorship_label_9' | translate }}</h5>
    </div>
    <div *ngIf="signType === 'USB_TOKEN' || signType === 'SIGN_SERVER' || signType === 'SMARTCA'">
        <div *ngIf="currentStepSignInternal === 1" class="sign-step">
            <p *ngIf="signType !== 'SMARTCA'">{{ 'ContractCensorship_label_10' | translate }}</p>
            <div class="content-tab">
                <div id="usb-token" [class.d-none]="signType !== 'USB_TOKEN'">
                    <!--- USB TOKEN -->
                    <div class="">
                        <table class="table table-bordered header-freeze">
                            <thead class="bg-tb-header">
                                <tr>
                                    <th>{{ 'ContractCensorship_label_11' | translate }}</th>
                                    <th> {{ 'ContractCensorship_label_12' | translate }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        {{ 'ContractCensorship_label_13' | translate }} {{ signatureUsb.addedSignBox === true ? signatureUsb.page : getCurrentPage() }}
                                    </td>
                                    <td>
                                        <button *ngIf="!signatureUsb.addedSignBox" class="btn btn-primary" (click)="usbtokenAddSignBox()">{{ 'ContractCensorship_label_14' | translate }}</button>
                                        <button *ngIf="signatureUsb.addedSignBox" class="btn btn-danger btn-icon text-white" (click)="removeSignBoxUsbtoken()">
                                            <span class="material-icons" style="font-size: 1.4rem;">delete</span>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <em class="mt-1">{{ 'ContractCensorship_label_15' | translate }}</em>
                    </div>
    
                    <div class="row mt-3">
                        <form [formGroup]="formSign" class="col-md-12 row">
                            <div class="col-12">
                                <div class="infor-box-header d-flex">
                                    <label class="text-primary flex-grow-1 mb-0">
                                        {{ 'ContractCensorship_label_16' | translate }}</label>
                                </div>
                                <hr>
                                <div class="row">
                                    <div class="col-xl-6">
                                        <div
                                            class="ccheck-primary d-inline-block">
                                            <input (change)="changedSignBy($event)"
                                                formControlName="signBy" type="checkbox"
                                                id="signBy">
                                            <label for="signBy" class="w-100">
                                                {{ 'ContractCensorship_label_17' | translate }}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-xl-6">
                                        <div
                                            class="ccheck-primary d-inline-block">
                                            <input (change)="changedDateSign($event)"
                                                formControlName="signDate"
                                                type="checkbox" id="signDate">
                                            <label for="signDate" class="w-100">
                                                {{ 'ContractCensorship_label_18' | translate }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="row mt-3">
                                    <div class="col-xl-12 align-items-end d-flex">
                                        <div class="form-group w-100">
                                            <label>{{ 'ContractCensorship_label_19' | translate }}:</label>
                                            <input formControlName="fontSize"
                                                (input)="inputFontSize($event)"
                                                type="text" class="form-control"
                                                placeholder="{{ 'ContractCensorship_ph_1' | translate }}">
                                            <app-show-validate-errors
                                                [isSubmit]="isSubmit"
                                                [errorMessages]="validMsg.fontSize"
                                                [control]="formSign.controls.fontSize"
                                                [detail]="{ name: 'fontSize' }">
                                            </app-show-validate-errors>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="infor-box-header d-flex mt-3">
                                    <label class="text-primary flex-grow-1 mb-0">
                                        {{ 'ContractCensorship_label_20' | translate }}
                                    </label>
                                </div>
                                <hr>
                                <div class="row">
                                    <div class="col-xl-12">
                                        <label for="signType">
                                            {{ 'ContractCensorship_label_21' | translate }}:
                                        </label>
                                        <ng-select 
                                            [items]="hinhThucHienThi_usbtoken"
                                            bindLabel="name"
                                            bindValue="value"
                                            [clearable]="false"
                                            (change)="selectStyleSignBox($event)"
                                            placeholder="{{ 'ContractCensorship_label_29' | translate }}"
                                            formControlName="visibleType">
                                        </ng-select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="infor-box-header mt-3">
                                    <label class="text-primary flex-grow-1 mb-0">
                                        {{ 'ContractCensorship_label_22' | translate }}
                                    </label>
                                </div>
                                <hr>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>{{ 'ContractCensorship_label_23' | translate }}:</label>
                                            <div class="input-group mb-1">
                                                <div class="custom-file custom-file-icon">
                                                    <label class="custom-file-label"
                                                        for="inputFileImage">{{ inputFileImageLabel | translate }}</label>
                                                    <input formControlName="imageFile"
                                                        (input)="handleFileImageInput($event)"
                                                        type="file" accept="image/*"
                                                        class="custom-file-input"
                                                        id="inputFileImage"
                                                        aria-describedby="inputGroupFileAddon01">
                                                    <span class="icon-file material-icons">attach_file</span>
                                                </div>
                                            </div>
                                            <app-show-validate-errors
                                                [useSubmit]="false"
                                                [isSubmit]="isSubmit"
                                                [errorMessages]="validMsg.imageFile"
                                                [control]="formSign.controls.imageFile"
                                                [detail]="{ name: 'imageFile' }">
                                            </app-show-validate-errors>
                                        </div>
                                        <img *ngIf="imgContent" class="border border-primary w-100" style="max-width: 200px;"
                                            [src]="imgContent">
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
    
                <div id="sign-server" [class.d-none]="signType !== 'SIGN_SERVER'">
                    <!-- VNPT CA -->
                    <div class="mt-3">
                        <label class="text-primary">{{ 'ContractCensorship_label_24' | translate }}:</label>
                        <hr class="mb-3">
                        <table id="list-signbox" class="list-signature table table-sm table-bordered header-freeze">
                            <thead>
                                <tr>
                                    <th>{{ 'ContractCensorship_label_25' | translate }}</th>
                                    <th class="th_sig_rectangle">{{ 'ContractCensorship_label_26' | translate }}</th>
                                    <th>{{ 'ContractCensorship_label_11' | translate }}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                            </tbody>
                        </table>
                        <button *ngIf="contractData.allowAddSignBox" role="button" class="btn d-flex align-items-center text-primary px-0"
                            (click)="addSignBox()">
                            <span class="material-icons">add</span>{{ 'ContractCensorship_label_27' | translate }}</button>
                    </div>
    
                    <div class="row mt-3">
                        <form [formGroup]="formSign" class="col-md-12 row">
                            <div class="col-xl-6">
                                <div class="infor-box-header d-flex">
                                    <label class="text-primary flex-grow-1 mb-0">{{ 'ContractCensorship_label_28' | translate }}</label>
                                </div>
                                <hr>
                                <div class="row">
                                    <div class="col-xl-12">
                                        <div class="ccheck-primary w-100 d-inline-block">
                                            <input (change)="changedDateSign($event)" formControlName="signDate"
                                                type="checkbox" id="signDate">
                                            <label for="signDate" class="w-100">
                                                {{ 'ContractCensorship_label_18' | translate }}
                                            </label>
                                        </div>
                                        <div class="ccheck-primary w-100 d-inline-block">
                                            <input (change)="changedSignBy($event)" formControlName="signBy" type="checkbox"
                                                id="signBy">
                                            <label for="signBy" class="w-100">
                                                {{ 'ContractCensorship_label_17' | translate }}
                                            </label>
                                        </div>
                                    </div>
    
                                </div>
    
                                <div class="row mt-3">
                                    <div class="col-xl-12 align-items-end d-flex">
                                        <div class="form-group w-100">
                                            <label>{{ 'ContractCensorship_label_19' | translate }}:</label>
                                            <input formControlName="fontSize" (input)="inputFontSize($event)" type="text"
                                                class="form-control" placeholder="{{ 'ContractCensorship_ph_1' | translate }}">
                                            <app-show-validate-errors [isSubmit]="isSubmit"
                                                [errorMessages]="validMsg.fontSize" [control]="formSign.controls.fontSize"
                                                [detail]="{ name: 'fontSize' }">
                                            </app-show-validate-errors>
                                        </div>
                                    </div>
                                </div>
    
    
                                <div class="infor-box-header d-flex mt-3">
                                    <label class="text-primary flex-grow-1 mb-0">{{ 'ContractCensorship_label_20' | translate }}</label>
                                </div>
                                <hr>
                                <div class="row">
                                    <div class="col-xl-12">
                                        <label for="signType">{{ 'ContractCensorship_label_21' | translate }}:</label>
                                        <ng-select [items]="hinhThucHienThi" bindValue="value"
                                            [clearable]="false" (change)="selectStyleSignBox($event)"
                                            placeholder="{{ 'ContractCensorship_label_29' | translate }}" formControlName="visibleType">
                                            <ng-template ng-label-tmp let-item="item">
                                                <span>{{ item.name | translate }}</span>
                                            </ng-template>
                                            <ng-template ng-option-tmp let-item="item">
                                                <span>{{ item.name | translate }}</span>
                                            </ng-template>
                                        </ng-select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="infor-box-header">
                                    <label class="text-primary flex-grow-1 mb-0">{{ 'ContractCensorship_label_22' | translate }}</label>
                                </div>
                                <hr>
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-group">
                                            <label>{{ 'ContractCensorship_label_23' | translate }}:</label>
                                            <div class="input-group mb-1">
                                                <div class="custom-file custom-file-icon">
                                                    <label class="custom-file-label" for="inputFileImage">{{
                                                        inputFileImageLabel | translate }}</label>
                                                    <input formControlName="imageFile"
                                                        (input)="handleFileImageInput($event)" type="file" accept="image/*"
                                                        class="custom-file-input" id="inputFileImage"
                                                        aria-describedby="inputGroupFileAddon01">
                                                    <span class="icon-file material-icons">attach_file</span>
                                                </div>
                                            </div>
                                            <app-show-validate-errors [isSubmit]="isSubmit"
                                                [errorMessages]="validMsg.imageFile" [control]="formSign.controls.imageFile"
                                                [detail]="{ name: 'imageFile' }">
                                            </app-show-validate-errors>
                                        </div>
                                        <img *ngIf="imgContent" class="w-100" style="max-width: 200px;" [src]="imgContent">
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div id="smartca" [class.d-none]="signType !== 'SMARTCA'">
                <!-- VNPT CA -->
                <app-smart-ca
                    #smartCASign
                    [contractData]="contractData"
                    [contractId]="contractData.contractId"
                    (savedSignature)="savedSignatureFromSmartCA($event)"
                    (expiredSessionSmartCA)="handleEventExpiredSessionSmartCA($event)"
                    (isWaitingConfirmSmartCA)="handleEventIsWaitingConfirmSmartCA($event)"
                ></app-smart-ca>
            </div>
        </div>
        <div *ngIf="currentStepSignInternal === 2" class="sign-step mt-3">
            <p *ngIf="!(signType == 'SMARTCA')">{{ 'ContractCensorship_label_30' | translate }}</p>
            <p *ngIf="signType == 'SMARTCA'">{{ 'ContractCensorship_label_31' | translate }}</p>
        </div>
    </div>

    <app-e-sign
        #eSignComponent
        [hidden]="signType !== 'E_SIGN'"
        [contractData]="contractData" 
        [fileContract]="fileContract"
        [sdt]="sdt"
        (haveSessionEsign)="haveSessionEsignEvent($event)"
        (signStepChanged)="eSignChangedStep($event)"
        (returnSignedFile)="returnSignedFileEvent($event)">
    </app-e-sign>

</section>

<ng-template #popupSendCensorship let-c="close" let-d="dismiss">
    <div class="modal-header border-0">
        <h5 class="modal-title" style="font-weight: 600; margin-right: auto;">{{ 'ServiceContractDetail_send_department_sign' | translate }}</h5>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="btn btn-light btn-alert-exit bg-exit-btn d-inline-flex">
                <img src="assets/img/icon/icon-close.svg" width="18" height="18">
            </span>
        </button>
    </div>
    <div class="modal-body text-left py-0 enable-scroll">
        <div class="mb-0">
            <form [formGroup]="formCensorship">
                <p>{{ 'ContractCensorship_label_32' | translate }}</p>
                <div class="form-group">
                    <label>{{ 'ContractCensorship_label_33' | translate }} <span class="text-danger">*</span>:</label>
                    <ng-select formControlName="partyId" [items]="dropListPartData | async" bindLabel="partyName"
                        bindValue="partyId" placeholder="{{ 'ContractCensorship_ph_2' | translate }}" [trackByFn]="trackByFn" [loading]="partsLoading"
                        [typeahead]="partsInput$" (change)="loadEmployee()" notFoundText="{{ 'text_no_results' | translate }}">
                    </ng-select>
                    <app-show-validate-errors [isSubmit]="isSubmitFormCensoership"
                        [errorMessages]="validFormCensorshipMsg.partyId" [control]="formCensorship.controls.partyId"
                        [detail]="{ name: 'partyId' }"></app-show-validate-errors>
                </div>

                <div class="form-group">
                    <label>{{ 'ContractCensorship_label_34' | translate }} <span class="text-danger">*</span>:</label>
                    <ng-select formControlName="userId" [items]="dropListUserData | async" bindValue="userId"
                        bindLabel="ten" placeholder="{{ 'ContractCensorship_ph_3' | translate }}" [trackByFn]="trackByFn1" [loading]="usersLoading"
                        (change)="changeUser($event)" notFoundText="{{ 'text_no_results' | translate }}">
                        <ng-template ng-option-tmp let-item="item" let-index="index">
                            <div class="ec-template-user-item">
                                <p class="font-weight-bold mb-1">{{ item?.username || "" }} - {{ item?.email || "" }}
                                </p>
                                <p class="ec-user-item-name">{{item.ten}}</p>
                            </div>
                        </ng-template>
                    </ng-select>
                    <app-show-validate-errors [isSubmit]="isSubmitFormCensoership"
                        [errorMessages]="validFormCensorshipMsg.userId" [control]="formCensorship.controls.userId"
                        [detail]="{ name: 'userId' }"></app-show-validate-errors>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer border-0 d-flex justify-content-end">
        <button type="button" class="btn btn-outline-primary d-inline-flex" (click)="c('Cross click')">{{ 'btn_cancel' | translate }}</button>
        <button type="button" class="btn btn-primary d-inline-flex ml-3" ngbAutofocus
            (click)="sendContractInternal()">{{ 'btn_confirm' |translate }}</button>
    </div>
</ng-template>

<ng-template #otpConfirmSignInternal let-c="close" let-d="dismiss">
    <div class="modal-header border-0 pb-0 mb-0">
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span class="material-icons">
                cancel
            </span>
        </button>
    </div>
    <form [formGroup]="otpSignForm">
        <div class="modal-body py-0">
            <h5 class="modal-title w-100 text-center mb-3" id="modal-basic-title"><label>{{ 'ContractCensorship_label_35' | translate }}</label></h5>

            <div class="form-group">
                <label>{{ 'ContractCensorship_label_36' | translate }}<span class="text-danger">*</span>: </label>
                <!-- appInputPassword -->
                <input formControlName="otp" class="form-control form-control-lg" type="text" placeholder="{{ 'ContractCensorship_ph_4' | translate }}"
                    (input)="handleInputOTP($event)">
                <app-show-validate-errors [isSubmit]="otpSignIsSubmit" [errorMessages]="validateMsgOTPSign.otp"
                    [control]="otpSignForm.controls.otp" [detail]="{ name: 'otp' }"></app-show-validate-errors>
            </div>
        </div>
        <div class="modal-footer border-0 d-flex justify-content-center">
            <button [disabled]="this.otpSignForm.invalid" type="submit" class="btn btn-lg btn-primary"
                (click)="submitConfirmOTPSign()">{{ localize.btn_confirm }}</button>
        </div>
    </form>
</ng-template>

<ng-template #modalRejectInternal let-c="close" let-d="dismiss">
    <div class="modal-header border-0">
        <h5 class="modal-title" style="font-weight: 600; margin-right: auto;">{{ 'reject_contract_modal_title' | translate }}
        </h5>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="btn btn-light bg-exit-btn d-inline-flex"><span class="material-icons">
                    close
                </span></span>
        </button>
    </div>
    <div class="modal-body text-left py-0">
        <p>{{ 'reject_contract_internal_confirm' | translate }}</p>
        <form [formGroup]="formRejectInternal">
            <div class="form-group">
                <label>{{ 'reject_contract_field_reason' | translate }} <span class="text-danger">*</span>:</label>
                <textarea formControlName="reason" class="form-control" rows="5"
                    placeholder="{{ 'ContractCensorship_ph_5' | translate }}"></textarea>
                <app-show-validate-errors [isSubmit]="isSubmitFormRejectInternal" [errorMessages]="validFormRejectInternal.reason"
                    [control]="formRejectInternal.controls.reason" [detail]="{ name: 'reason' }">
                </app-show-validate-errors>
            </div>
        </form>
    </div>
    <div class="modal-footer border-0 d-flex justify-content-end">
        <button type="button" class="btn btn-outline-primary" (click)="c('Close')">{{ 'btn_cancel' | translate }}</button>
        <button type="button" class="btn btn-primary" (click)="confirmRejectInternal()">{{ 'btn_confirm' | translate }}</button>
    </div>
</ng-template>
