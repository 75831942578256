import {
    Component,
    OnInit,
    Input,
    ViewChild,
    ElementRef,
    ApplicationRef,
    TemplateRef,
} from '@angular/core';
import { Constant } from './../../../../../../../utils/common/constant_videocall';
// import { NzMessageService } from 'Ng-zorro-antd/message';
import { NgModalComponent } from 'src/app/layout/extensions/ng-modal/ng-modal.component';
import {
    NgbModal,
    NgbActiveModal,
    NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import { Observable, BehaviorSubject } from 'rxjs';
import { AasConsumerManagerService } from 'src/app/utils/services/aas-network/aas-consumer-manager.service';
import { AuthService } from './../../../../../../../utils/services/auth.service';
import { ListUserItem } from './../../../../../../../utils/models/aas-models/list-register-user/list-user-item.model';
import { ListUserResponse } from './../../../../../../../utils/models/aas-models/list-register-user/list-user-response.model';
import { share } from 'rxjs/operators';
import { UserDetailObject } from 'src/app/utils/models/aas-models/list-register-user/user-detail-object.model';
import { UserDetailResponse } from 'src/app/utils/models/aas-models/list-register-user/user-detail-response.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { FirebaseService } from 'src/app/utils/services/aas-network/firebase/firebase.service';
import { VideoCallFirebaseMassageService } from 'src/app/utils/services/aas-network/video-call-firebase-massage.service';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertBtn, ConstantAlertMsg } from 'src/app/utils/common/constant-alert-msg';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { localize } from 'src/app/utils/localize/localize';

@Component({
	selector: 'app-consumer-signup-wait',
	templateUrl: './consumer-signup-wait.component.html',
	styleUrls: ['./consumer-signup-wait.component.scss']
})
export class ConsumerSignupWaitComponent implements OnInit {
	@Input() callback: any;
	@ViewChild('content') public content: TemplateRef<any>;
	@ViewChild('calling') public calling: TemplateRef<any>;
	@ViewChild('approve') public approve: TemplateRef<any>;

	default_ava_img: string = 'assets/img/services/user-incall-ava-default.png';
	ava_img: string = '';

	loading: boolean = false;
	loading2: boolean = false;
	users = [];
	message: any;
	isVisible: boolean = false;
	modal_confirm: boolean = false;
	reponseCreateCall: any;
	flag_modal: string = '';
	modal_width: number = 520;
	imageUrls: string[] = [];
	imageFaceUrl: string = '';
	customerInfomations: [];
	faceCICs: any = [];
	userCached: any = {};
	firebaseMessageOld: any;

	url: any = null;
	imageFirst: any = null;
	imageSecond: any = null;
	modalReference: any;
	closeResult: any;
	page = 1;
	maxSize = 5;
	search = '';
	totalElement = 0;
	propertiesSort = 'created';
	sort = 'DESC';
	status = 'WAIT';
	listUserGroup: ListUserItem[] = [];
	selectedUser: ListUserItem = new ListUserItem();
	loadingListUserGroup = new BehaviorSubject<boolean>(false);
	detailUser: UserDetailObject = new UserDetailObject();
	inputDenyReason: FormGroup;
	searchForm: FormGroup;
	personalInfo: any;

	roomId: string = '';
	pendingCallModal: any = null;

	constructor(
		private aasConsumerManagerService: AasConsumerManagerService,
		private firebaseService: FirebaseService,
		private videoCallFirebaseMassageService: VideoCallFirebaseMassageService,
		private authService: AuthService,
		private modalService: NgbModal,
		public activeModal: NgbActiveModal,
		private appRef: ApplicationRef,
		private sanitizer: DomSanitizer,
		private myAlert: AlertControl
	) { }

	ngOnInit(): void {
		this.viewDidLoad();
	}

	viewDidLoad() {
		this.getListUserGroup(
            this.status,
            this.search,
            this.maxSize,
            this.page
        );
        this.makeDenyReasonForm();
        this.makeSearchForm();
        // this.handleMessageFireBase();
        // this.handleReloadData();
        this.handleMessagesFromVideoCall();
	}

	/**
     * Bắt sự kiện cuộc gọi từ firebase
     */
	 handleMessagesFromVideoCall() {
        this.firebaseService.currentMessage.subscribe((message) => {
            console.log('waiting screen...')
            if (message) {
                switch (message["data"]["title"]) {
                    case Constant.ACCEPTED:
                        console.log("waiting screen...ACCEPTED");
                        this.pendingCallModal && this.pendingCallModal.close()

                        localStorage.setItem(
                            Constant.CALLER,
                            JSON.stringify(this.authService.username)
                        );
                        localStorage.setItem(
                            Constant.ROOM_INFO,
                            JSON.stringify({
                                roomId: message.data.roomId,
                                token: message.data.token,
                                domain: message.data.domain,
                            })
                        );
                    // console.log(
                    //     "consumer",
                    //     localStorage.getItem(Constant.CONSUMER)
                    // );
                    // if (localStorage.getItem(Constant.CONSUMER)) {
                    //     return;
                    // }
                    // return this.videoCallFirebaseMassageService.openWindowCall();
                    case Constant.PENDING:
                        // console.log("PENDING");
                        // this.data = message.data;
                        // element.click();
                        return;
                    case Constant.REJECTED:
                        console.log("waiting screen...REJECTED");
                        this.pendingCallModal && this.pendingCallModal.close()
                        return;
                    case Constant.FINISHED:
                        console.log("waiting screen...FINISHED");
                        this.pendingCallModal && this.pendingCallModal.close()
                        // localStorage.removeItem(Constant.ROOM_INFO);
                        // localStorage.removeItem(Constant.CONSUMER);
                        // localStorage.removeItem(Constant.USER_DETAIL);
                        // element2.click();
                        // this.videoCallFirebaseMassageService.closeWindow();
                        return;
                    case Constant.TIMEOUT:
                        console.log("waiting screen...timeout");
                        localStorage.removeItem(Constant.CONSUMER);
                        this.pendingCallModal && this.pendingCallModal.close()
                        return;
                    default:
                        return;
                }
            }
        });
    }

    // handleReloadData() {
    //     this.aasConsumerManagerService.reloadData.subscribe((value) => {
    //         console.log('value', value);
    //         if (value) {
    //             this.getListUserGroup(this.status, this.search, this.maxSize);
    //             this.callback();
    //             this.aasConsumerManagerService.setReloadData(false);
    //         }
    //     });
    // }

    // handleMessageFireBase() {
    //     this.aasConsumerManagerService.currentMessage.subscribe((message) => {
    //         console.log('message', message);
    //         // this.appRef.tick();
    //         if (message) {
    //             switch (message['data']['title']) {
    //                 case Constant.ACCEPTED:
    //                     localStorage.setItem(
    //                         Constant.ROOM_INFO,
    //                         JSON.stringify(message['data'])
    //                     );
    //                     this.close();
    //                     localStorage.setItem(
    //                         Constant.IMAGE_URL,
    //                         JSON.stringify(this.imageUrls)
    //                     );
    //                     return this.openWindowCall();
    //                 case Constant.PENDING:
    //                     console.log('pendign');
    //                     // return this.openCalling();
    //                     // this.alert("testtttttttttttttttttt", "");
    //                     // this.acceptCall(message.data);
    //                     return;
    //                 case Constant.REJECTED:
    //                     this.alert('Người dùng từ chối nhận cuộc gọi', '');
    //                     this.close();
    //                     return;
    //                 case Constant.FINISHED:
    //                     localStorage.removeItem(Constant.ROOM_INFO);
    //                     localStorage.removeItem(Constant.IMAGE_FACE);
    //                     localStorage.removeItem(Constant.IMAGE_URL);
    //                     this.close();
    //                     window.close();
    //                     return;
    //                 case Constant.TIMEOUT:
    //                     console.log('timeout');
    //                     this.close();
    //                     this.alert('Người dùng không phản hồi', '');
    //                     return;
    //                 default:
    //                     return;
    //             }
    //         }
    //     });
    // }

    // openWindowCall() {
    //     const dualScreenLeft =
    //         window.screenLeft !== undefined
    //             ? window.screenLeft
    //             : window.screenX;
    //     const dualScreenTop =
    //         window.screenTop !== undefined ? window.screenTop : window.screenY;

    //     const width = window.innerWidth
    //         ? window.innerWidth
    //         : document.documentElement.clientWidth
    //         ? document.documentElement.clientWidth
    //         : screen.width;
    //     const height = window.innerHeight
    //         ? window.innerHeight
    //         : document.documentElement.clientHeight
    //         ? document.documentElement.clientHeight
    //         : screen.height;

    //     const systemZoom = width / window.screen.availWidth;
    //     const left = (width - 980) / 2 / systemZoom + dualScreenLeft;
    //     const top = (height - 500) / 2 / systemZoom + dualScreenTop;

    //     console.log('openvideo call');
    //     window.open(
    //         'console/video-call',
    //         '',
    //         `width=1280,height=600,top=${top},left=${left}`,
    //         true
    //     );
    // }

    //MARK: Loading
    loadingListUserGroupIsVisiable(): Observable<boolean> {
        return this.loadingListUserGroup.asObservable().pipe(share());
    }

    searchData(value) {
        this.page = 1;
        this.getListUserGroup(this.status, value || '', this.maxSize, this.page);
        console.log('data', value);
    }

    makeSearchForm() {
        this.searchForm = new FormGroup({
            searchInput: new FormControl(),
        });
    }

    makeDenyReasonForm() {
        this.inputDenyReason = new FormGroup({
            denyReason: new FormControl(null, [Validators.required]),
        });
    }

    // close() {
    //     if (this.modalReference) {
    //         this.modalReference.close('close');
    //     }
    // }

    approveRegistration(item) {
        let msg = ConstantAlertMsg.ADMIN_APPROVE_CONSUMER_CONFIRM;
        if (this.detailUser.videocall == "N" || this.detailUser.videocall == false) {
            msg = ConstantAlertMsg.ADMIN_APPROVE_CONSUMER_NO_VIDEOCALL;
        }
        this.myAlert.showAlert(msg, ConstantAlertType.BLANK, true, "", localize.btn_approved, () => {
            this.aasConsumerManagerService
            .approveRegistration(item.userId)
            .subscribe(
                (res) => {
                    this.getListUserGroup(
                        this.status,
                        this.search,
                        this.maxSize,
                        this.page
                    );
                    this.callback();
                    this.myAlert.showAlertOnlyNoti(ConstantAlertMsg.ADMIN_APPROVE_CONSUMER_SUCCESS, ConstantAlertType.SUCCESS);
                },
                (err) => {
                    this.myAlert.showAlertOnlyNoti(ConstantAlertMsg.ADMIN_APPROVE_CONSUNER_FAILED , ConstantAlertType.ERROR);
                }
            );
        }, "", localize.btn_close, () => {

        }, ConstantAlertMsg.ADMIN_APPROVE_TITLE_CONFIRM);
    }

    rejectRegistration(item) {
        this.aasConsumerManagerService
            .rejectRegistration(item.userId, {
                rejectReason: this.reason.value,
            })
            .subscribe(
                (res) => {
                    this.getListUserGroup(
                        this.status,
                        this.search,
                        this.maxSize,
                        this.page
                    );
                    this.callback();
                    this.myAlert.showAlertNoClose(ConstantAlertMsg.ADMIN_REJECT_CONSUNER_SUCCESS, ConstantAlertType.SUCCESS);
                    console.log('reject', res);
                },
                (err) => {
                    this.myAlert.showAlertNoClose(ConstantAlertMsg.ADMIN_REJECT_CONSUNER_FAILED, ConstantAlertType.SUCCESS);
                }
            );
    }

    loadPage(page: number) {
        this.url = null;
        this.imageFirst = null;
        this.imageSecond = null;
        this.imageUrls = [];
        this.page = page;
        this.getListUserGroup(
            this.status,
            this.searchForm.value.searchInput || '',
            this.maxSize,
            this.page
        );
    }

    get reason() {
        return this.inputDenyReason.get('denyReason');
    }

    async getImage(id, type) {
        this.aasConsumerManagerService.downloadImage(id, type).subscribe(
            (res) => {
                console.log('image', res);
                if (res) {
                    const blob: any = new Blob([res], {
                        type: 'image/png',
                    });
                    var reader = new FileReader();
                    let base64data: any;
                    reader.readAsDataURL(blob);
                    reader.onloadend = () => {
                        if (type === 'EKYC_CHANDUNG') {
                            this.url = reader.result;
                            this.ava_img = this.url || this.default_ava_img;
                            localStorage.setItem(Constant.IMAGE_FACE, this.url);
                        } else if (type === 'EKYC_MATTRUOC') {
                            this.imageFirst = reader.result;
                            this.imageUrls.push(this.imageFirst);
                        } else {
                            this.imageSecond = reader.result;
                            this.imageUrls.push(this.imageSecond);
                        }
                    };
                }
            },
            (err) => {
                if (type === 'EKYC_CHANDUNG') {
                    this.url = null;
                } else if (type === 'EKYC_MATTRUOC') {
                    this.imageFirst = null;
                } else {
                    this.imageSecond = null;
                }
            },
            () => { }
        );
    }

    getListUserGroup(status, search, maxSize, page) {
        this.loadingListUserGroup.next(true);
        var data = new ListUserResponse();
        this.aasConsumerManagerService
            .getListAccountAM(
                this.authService.userId,
                status,
                search,
                maxSize,
                page
            )
            .subscribe(
                (res) => {
                    this.loadingListUserGroup.next(false);
                    data = new ListUserResponse(res);
                    this.listUserGroup = data.object.data;
                    if (this.listUserGroup.length > 0) {
                        // this.detailUser = this.listUserGroup[0];
                        this.selectUserGroup(this.listUserGroup[0]);
                    }
                    this.totalElement = data.object.totalElement;
                },
                (err) => {
                    this.loadingListUserGroup.next(false);
                },
                () => {
                    this.loadingListUserGroup.next(false);
                }
            );
    }

    selectUserGroup(item) {
        // this.loadingListUserGroup.next(true);
        var data = new UserDetailResponse();
        this.aasConsumerManagerService.getDetailUser(item.userId).subscribe(
            (res) => {
                // this.loadingListUserGroup.next(false);
                this.url = null;
                this.imageFirst = null;
                this.imageSecond = null;
                this.imageUrls = [];
                this.getImage(item.userId, 'EKYC_CHANDUNG');
                this.getImage(item.userId, 'EKYC_MATTRUOC');
                this.getImage(item.userId, 'EKYC_MATSAU');
                data = new UserDetailResponse(res);
                console.log('detailUser', data.object.customerId);
                this.detailUser = data.object;
                console.log('response', this.detailUser);
            },
            (err) => {
                // console.log(err);
                // this.loadingListUserGroup.next(false);
            },
            () => {
                // this.loadingListUserGroup.next(false);
            }
        );
        localStorage.setItem(
            Constant.USER_DETAIL,
            JSON.stringify(this.detailUser)
        );
        localStorage.setItem(Constant.CONSUMER, Constant.CONSUMER);
        this.selectedUser = item;
    }

    open(content) {
        this.inputDenyReason.reset();
        console.log(content);
        this.modalService
            .open(content, { ariaLabelledBy: 'modal-basic-title' })
            .result.then(
                (result) => {
                    console.log('result', result);
                },
                (reason) => { }
            );
    }

    modal(msg, callBack = () => { }) {
        const modalRef = this.modalService.open(NgModalComponent);
        modalRef.componentInstance.message = msg;
        modalRef.componentInstance.callback = callBack;
    }

    handleCallVideo() {
        const deviceIds = this.personalInfo['DeviceIdSet'].split(';');
        console.log('deviceIds', deviceIds);
        this.createCall(deviceIds);
    }

    acceptCall(data) {
        this.aasConsumerManagerService.acceptCall(data).subscribe(
            (res) => {
                console.log('accept', res);
            },
            (error) => {
                this.myAlert.showErrorHandled(error, ResponseMsg.MESSAGES.VIDEO_CALL);
            }
        );
    }

    createCall(deviceIds) {
        this.aasConsumerManagerService
            .createCall(this.detailUser, deviceIds, this.authService.username)
            .subscribe(
                (response: any) => {
                    console.log('create call', response);
                    if (response) {
                        if (response.object && response.object.roomId) {
                            this.roomId = response.object.roomId;
                        }
                    }
                    this.openWaitingCallPopup();
                    // this.reponseCreateCall = response;
                },
                (error) => {
                    this.myAlert.showErrorHandled(error, ResponseMsg.MESSAGES.VIDEO_CALL);
                    // this.modal_confirm = false;
                }
            );
    }

    callVideo(uuidCustomer) {
        return;
        // this.openWindowCall();
        console.log('customerId', uuidCustomer);
        // this.openWaitingCallPopup();
        this.aasConsumerManagerService
            .getDeviceIdByCustomerId(uuidCustomer)
            .subscribe(
                (response) => {
                    console.log('aa', response);
                    if (response['message'] === 'IDG-00000000') {
                        if (!response['object']['DeviceIdSet']) {
                            // this.alert(
                            //     `Khách hàng này chưa đăng ký thiết bị!!!`,
                            //     'error'
                            // );
                            return;
                        }
                        this.personalInfo = response['object'];
                        this.handleCallVideo();
                    }
                },
                (error) => {
                    this.myAlert.showErrorHandled(error, ResponseMsg.MESSAGES.VIDEO_CALL);
                }
            );
    }

    //MARK: KẾT THÚC CUỘC GỌI
    /**
     * Action khi nhấn nút kết thúc cuộc gọi tại màn hình chờ
     */
    actBtnEndCall() {
        //Ẩn modal màn hình chờ
        this.pendingCallModal && this.pendingCallModal.close();

        const param = {
            "deviceId": localStorage.getItem(Constant.UUID),
            "idgTokenId": Constant.TOKEN_ID,
            "roomId": this.roomId
        }
        this.endCall(param);
    }

    //MARK: NETWORKING
    /**
     * Kết thúc cuộc gọi
     * @param data 
     */
    endCall(data) {
        this.aasConsumerManagerService.endCall(data).subscribe(res => {

        }, err => {
            this.myAlert.showErrorHandled(err, ResponseMsg.MESSAGES.VIDEO_CALL);
        })
    }

    //MARK: POPUP THÔNG BÁO
    /**
     * Hiển thị modal đang gọi
     */
    openWaitingCallPopup() {
        this.pendingCallModal = this.modalService.open(this.calling, { backdrop: 'static', size: 'sm' });
        this.pendingCallModal.result.then(
            (result) => {
                // this.closeResult = `Closed with: ${result}`;
            },
            (reason) => {
                // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );
    }
}
