<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-auto">
                <h1 class="m-0 text-dark"><label>{{ 'AddBusinessTrustedPartner_title' | translate }}</label></h1>
            </div>
            <!-- /.col -->
            <div class="col-md-auto ml-auto">
                <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="cancel()">
                    <span class="material-icons mr-1">close</span>{{ 'btn_cancel' | translate }}
                </button>
                <button class="btn btn-primary d-inline-flex" (click)="save()">
                    <span class="material-icons mr-1">save</span>{{ 'btn_save' | translate }}
                </button>
            </div>
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>

<section class="content">
    <div class="container-fluid">
        <div class="card shadow-none">
            <div class="card-body">
                <form [formGroup]="partnerForm">
                    <label class="text-primary font-weight-bold m-0">{{ 'ProfileManager_account_info' | translate
                        }}</label>
                    <hr class="mb-3">
                    <div class="row">
                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                                <label>{{ 'ProfileBusiness_account_name' | translate }} <span
                                        class="text-danger">*</span></label>
                                <input formControlName="username" appTrimValue class="form-control"
                                    placeholder="{{ 'AddBusinessTrustedPartner_pl_1' | translate }}">
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="partnerForm.controls.username" [detail]="{ name: 'username'}"
                                    [errorMessages]="validateMsg.username"></app-show-validate-errors>
                            </div>
                        </div>

                    </div>

                    <label class="text-primary font-weight-bold m-0">{{ 'TrustedPartnerDetail_party_info' | translate
                        }}</label>
                    <hr class="mb-3">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ 'TrustedPartnerDetail_party_name' | translate }} <span
                                        class="text-danger">*</span></label>
                                <input formControlName="orgName" appTrimValue class="form-control"
                                    placeholder="{{ 'RegisterBusiness_place_1' | translate }}">
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="partnerForm.controls.orgName" [detail]="{ name: 'orgName'}"
                                    [errorMessages]="validateMsg.orgName"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>{{ 'ProfileBusiness_party_taxcode' | translate }} <span
                                        class="text-danger">*</span></label>
                                <input formControlName="taxNumber" appTrimValue class="form-control"
                                    placeholder="{{ 'ProfileBusiness_party_input_taxcode' | translate }}"
                                    (input)="taxNumberPipeLine.next($event)">
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="partnerForm.controls.taxNumber" [detail]="{ name: 'taxNumber'}"
                                    [errorMessages]="validateMsg.taxNumber"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>{{ 'SignUpBussiness_ten_viet_tat' | translate }} <span
                                        class="text-danger">*</span></label>
                                <input formControlName="shortName" appTrimValue class="form-control"
                                    placeholder="{{ 'RegisterBusiness_place_2' | translate }}">
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="partnerForm.controls.shortName" [detail]="{ name: 'shortName'}"
                                    [errorMessages]="validateMsg.shortName"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ 'RegisterBusiness_h_5' | translate }} <span
                                        class="text-danger">*</span></label>
                                <input formControlName="email" appTrimValue class="form-control"
                                    placeholder="{{ 'RegisterBusiness_place_4' | translate }}">
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="partnerForm.controls.email" [detail]="{ name: 'email'}"
                                    [errorMessages]="validateMsg.email"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>{{ 'SignUpEnterprise_tinh_thanh_pho' | translate }} <span
                                        class="text-danger">*</span></label>
                                <ng-select formControlName="provinceId" [items]="listOfProvinces" bindValue="tinhId"
                                    bindLabel="tenTinh"
                                    placeholder="{{ 'ProfileBusiness_agent_input_city' | translate }}"
                                    [clearable]="false" (change)="getListOfDistricts($event)">
                                </ng-select>
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="partnerForm.controls.provinceId" [detail]="{ name: 'provinceId'}"
                                    [errorMessages]="validateMsg.provinceId"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>{{ 'AddBusinessTrustedPartner_label_1' | translate }} <span
                                        class="text-danger">*</span></label>
                                <ng-select formControlName="districtId" [items]="listOfDistricts" bindValue="huyenId"
                                    bindLabel="tenHuyen"
                                    placeholder="{{ 'AddBusinessTrustedPartner_pl_2' | translate }}" [clearable]="false"
                                    (change)="getListOfWards($event)">
                                </ng-select>
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="partnerForm.controls.districtId" [detail]="{ name: 'districtId'}"
                                    [errorMessages]="validateMsg.districtId"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>{{ 'AddBusinessTrustedPartner_label_2' | translate }} <span
                                        class="text-danger">*</span></label>
                                <ng-select formControlName="wardId" [items]="listOfWards" bindValue="xaId"
                                    bindLabel="tenXa"
                                    placeholder="{{ 'ProfileBusiness_agent_input_wards' | translate }}">
                                </ng-select>
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="partnerForm.controls.wardId" [detail]="{ name: 'wardId'}"
                                    [errorMessages]="validateMsg.wardId"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>{{ 'AddBusinessTrustedPartner_label_3' | translate }} <span
                                        class="text-danger">*</span></label>
                                <input formControlName="streetName" appTrimValue class="form-control"
                                    placeholder="{{ 'ProfileBusiness_agent_input_road' | translate }}">
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="partnerForm.controls.streetName" [detail]="{ name: 'streetName'}"
                                    [errorMessages]="validateMsg.streetName"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>{{ 'AddBusinessTrustedPartner_label_4' | translate }} <span
                                        class="text-danger">*</span></label>
                                <input formControlName="apartmentNumber" appTrimValue class="form-control"
                                    placeholder="{{ 'ProfileBusiness_agent_input_number' | translate }}">
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="partnerForm.controls.apartmentNumber"
                                    [detail]="{ name: 'apartmentNumber'}" [errorMessages]="validateMsg.apartmentNumber">
                                </app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>{{ 'TrustedPartnerDetail_party_license' | translate }}</label>
                                <div class="input-group">
                                    <div class="custom-file custom-file-icon">
                                        <input #gpkdFile class="custom-file-input" id="inputGroupFile01" type="file"
                                            (input)="onAttachFileChanged($event)" accept=".pdf,.png,.jpeg,.jpg">
                                        <label class="custom-file-label" for="inputGroupFile01">{{ inputFileLabel |
                                            translate }}</label>
                                        <span class="icon-file material-icons">attach_file</span>
                                    </div>
                                </div>
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="partnerForm.controls.file" [detail]="{ name: 'file'}"
                                    [errorMessages]="validateMsg.file"></app-show-validate-errors>
                                <div>
                                    <span class="gpkd-note">
                                        {{ 'AddBusinessTrustedPartner_label_5' | translate }}
                                    </span>
                                </div>
                            </div>
                        </div>

                    </div>

                    <label class="text-primary font-weight-bold m-0">{{ 'ProfileBusiness_agent' | translate }}</label>
                    <hr class="mb-3">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ 'ProfileBusiness_agent_name' | translate }} <span
                                        class="text-danger">*</span></label>
                                <input appTrimValue formControlName="fullName" class="form-control"
                                    placeholder="{{ 'AddBusinessTrustedPartner_pl_3' | translate }}">
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="partnerForm.controls.fullName" [detail]="{ name: 'fullName'}"
                                    [errorMessages]="validateMsg.fullName"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>{{ 'phone_number' | translate }} <span class="text-danger">*</span></label>
                                <input appTrimValue appTrackerPhoneNumber formControlName="phoneNumber"
                                    class="form-control" (input)="checkPhoneNumber()"
                                    placeholder="{{ 'ProfileBusiness_agent_input_phone' | translate }}">
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="partnerForm.controls.phoneNumber" [detail]="{ name: 'phoneNumber'}"
                                    [errorMessages]="validateMsg.phoneNumber"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>{{ 'TrustedPartnerList_ngay_sinh' | translate }} <span
                                        class="text-danger">*</span></label>
                                <div class="input-group">
                                    <input class="form-control" ngbDatepicker #birthday="ngbDatepicker"
                                        placeholder="dd/MM/yyyy" [control]="partnerForm.controls.birthday"
                                        [value]="partnerForm.value.birthday" (dateSelect)="changeDateFilter($event)"
                                        [maxDate]="{year: currentDate.getFullYear(), month: currentDate.getMonth()+1, day: currentDate.getDate()}"
                                        appInputMark [options]="optionsCleave">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary" (click)="birthday.toggle()"
                                            type="button"><em class="fas fa-calendar-alt"></em></button>
                                    </div>
                                    <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                        [control]="partnerForm.controls.birthday" [detail]="{ name: 'birthday'}"
                                        [errorMessages]="validateMsg.birthday"></app-show-validate-errors>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>{{ 'WaitConsumerPartner_gender' | translate }} <span class="text-danger">*</span></label>
                                <ng-select placeholder="{{ 'ProfileBusiness_agent_input_gender' | translate }}"
                                    [items]="sexOptions" bindLabel="label" bindValue="id" [clearable]="false"
                                    [searchable]="false" formControlName="sexId">
                                    <ng-template ng-label-tmp let-item="item">
                                        {{item.label | translate}}
                                    </ng-template>
                                    <ng-template ng-option-tmp let-item="item" let-index="index"
                                        let-search="searchTerm">
                                        <span>{{item.label | translate}}</span>
                                    </ng-template>
                                </ng-select>
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="partnerForm.controls.sexId" [detail]="{ name: 'sexId'}"
                                    [errorMessages]="validateMsg.sexId"></app-show-validate-errors>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
<app-spinner></app-spinner>

<!-- <app-preview-modal *ngIf="showFile" [file]="pdfFile" [name]="inputFileLabel" [useDownloadEvent]="attachFile == null"
    (close)="closePreview()"></app-preview-modal>

<app-customize-infra-config 
    *ngIf="showCustomizeInfraInfo"
    [packageInfo]="packageInfo"
    [dropListPackageData]="packageIds"
    (backEvent)="handleBackEvent($event)"
    (savedData)="handleSavedData($event)">
</app-customize-infra-config> -->