import { PrivilegeType } from './privileges/privilege-type.model';

export class ServicePackage {

    // "serviceInfoId": "803bac19-9cdc-4ece-b5e4-584919f15a32",
    //     "name": "eContract cơ bản",
    //     "roleId": "f5bd3290-18bb-11eb-a581-43dd15527a1e",
    //     "created": "2020-10-28T01:21:52.000Z",
    //     "modified": "2020-10-29T01:16:57.000Z",
    //     "status": "ENABLED",
    //     "typeService": "CHUCNANG",
    //     "privileges"

    serviceInfoId: string = '';
    name: string = '';
    roleId: string = '';
    created: string = '';
    modified: string = '';
    status: string = '';
    typeService: string = '';
    privileges: Array<PrivilegeType> = [];

    constructor(data = null) {
        data = data || {};

        this.serviceInfoId = data.serviceInfoId || '';
        this.name = data.name || '';
        this.roleId = data.roleId || '';
        this.created = data.created || '';
        this.modified = data.modified || '';
        this.status = data.status || '';
        this.typeService = data.typeService || '';
        
        this.privileges = [];
        if (data.privileges && data.privileges instanceof Array) {
            data.privileges.forEach(item => {
                this.privileges.push(new PrivilegeType(item));
            });
        }
    }
}