import { Component, OnInit,  ViewChild, ElementRef  } from "@angular/core";
import { Router } from "@angular/router";
import { localize } from "src/app/utils/localize/localize";

@Component({
  selector: "app-service-account-manager",
  templateUrl: "./service-account-manager.component.html",
  styleUrls: ["./service-account-manager.component.scss"],
  
})
export class ServiceAccountManagerComponent {
  localize = localize;

  totalElementConsumer = 0;
  totalElementBusiness = 0;

  constructor(
    private router: Router
  ) {}

  ngOnInit() {
  }

  addCustomer(type = "BUSINESS") {
    switch (type) {
      case "BUSINESS": 
        this.router.navigate(['console/account-manager/add/business'])
        break;
      case "CONSUMER": 
        this.router.navigate(['console/account-manager/add/consumer'])
        break;
    }
  }
}
