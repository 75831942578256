import { Injectable } from '@angular/core';
import { max } from 'rxjs/operators';
import { ServicePath } from '../../common/constant-service-api';
import { API } from '../api';

const GET_API_KEY_LIST = ServicePath.MANAGEMENT_SERVICE + '/licenses';
const GET_API_KEY_DETAIL = ServicePath.MANAGEMENT_SERVICE + '/licenses';
const POST_SEND_MAIL = ServicePath.MANAGEMENT_SERVICE + '/licenses';
const PUT_UPDATE_STATUS = ServicePath.MANAGEMENT_SERVICE + '/licenses/update-status';

@Injectable({
  providedIn: 'root'
})
export class ApiKeyService {

  constructor(
    private api: API
  ) { }

  /**
   * Lay danh sach api key (admin VNPT)
   * @param page 
   * @param maxSize 
   * @param sort 
   * @param propertiesSort 
   * @param searchString 
   * @param issueDate 
   * @param expirationDate 
   * @param status 
   * @param customerType 
   */
  getListApiKeyAdmin(page, maxSize, sort, propertiesSort, searchString, issueDate, expirationDate, status, customerType) {
    const url = GET_API_KEY_LIST + `?page=${page}&maxSize=${maxSize}&sort=${sort}&propertiesSort=${propertiesSort}` 
    + `&searchString=${searchString}&issueDate=${issueDate}&expirationDate=${expirationDate}&status=${status}&customerType=${customerType}`;

    return this.api.get(url);
  }

  /**
   * Thong tin chi tiet api key
   * @param id 
   */
  getDetailApiKey(id) {
    const url = GET_API_KEY_DETAIL + `/${id}`;
    return this.api.get(url);
  }

  /**
   * Gui mail api key
   * @param id 
   * @param email 
   */
  postSendMail(id, email, username) {
    const url = POST_SEND_MAIL + `/${id}/${username}/${email}/send-email`;
    return this.api.post(url, null);
  }

  /**
   * Doi trang thai api key
   * @param id 
   * @param status 
   */
  putChangeStatusApiKey(id, status) {
    const url = PUT_UPDATE_STATUS + `/${id}/${status}`;
    return this.api.put(url, null);
  }
}
