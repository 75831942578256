<app-spinner></app-spinner>
<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card ec-card shadow-none">
                    <div class="card-header">
                        <div class="d-flex">
                            <div class="card-title-path flex-grow-1">
                                <h1 class="m-0 text-dark card-title"><label>{{ 'ContractFlowEdit_title' | translate }}</label></h1>
                            </div>
                            <div class="card-btn-path ml-auto text-right">
                                <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="cancel()"><span
                                        class="material-icons mr-1">close</span>{{ 'btn_cancel' | translate }}</button>
                                <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="setData(flowContract)"><span class="material-icons-outlined mr-1">
                                            refresh
                                            </span>{{ 'btn_reset' | translate }}</button>
                                <button class="btn btn-primary d-inline-flex" (click)="save()"><span
                                        class="material-icons mr-1">save</span>{{ 'btn_save' | translate }}</button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="text-primary">{{ 'ContractFlowAdd_generalInfor' | translate }}</label>
                                <hr class="mt-0 mb-2">
                                <form [formGroup]="form">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>{{ 'ContractFlowAdd_type' | translate }} <span class="text-danger">*</span></label>
                                                <input appTrimValue class="form-control" formControlName="name" placeholder="{{ 'ContractFlowAdd_ph_type' | translate }}">
                                                <app-show-validate-errors [isSubmit]="isSubmit"
                                                    [errorMessages]="validateError.name" [control]="form.controls.name"
                                                    [detail]="{ name: 'name' }"></app-show-validate-errors>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>{{ 'ContractFlowAdd_nego' | translate }} <span class="text-danger">*</span></label>
                                                <ng-select
                                                    [items]="dropListDiscuss"
                                                    bindLabel="text"
                                                    bindValue="value"
                                                    placeholder="{{ 'ContractFlowAdd_ph_nego' | translate }}"
                                                    formControlName="discuss">
                                                    <ng-template ng-label-tmp let-item="item">
                                                        <span>{{ item.text | translate }}</span>
                                                    </ng-template>
                                                    <ng-template ng-option-tmp let-item="item">
                                                        <span>{{ item.text | translate }}</span>
                                                    </ng-template>
                                                </ng-select>
                                                <app-show-validate-errors [isSubmit]="isSubmit"
                                                    [errorMessages]="validateError.discuss" [control]="form.controls.discuss"
                                                    [detail]="{ name: 'discuss' }"></app-show-validate-errors>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>{{ 'ContractFlowAdd_form' | translate }} <span class="text-danger">*</span></label>
                                                <ng-select
                                                    [items]="dropListType"
                                                    bindLabel="text"
                                                    bindValue="value"
                                                    placeholder="{{ 'ContractFlowAdd_ph_form' | translate }}"
                                                    formControlName="type">
                                                    <ng-template ng-label-tmp let-item="item">
                                                        <span>{{ item.text | translate }}</span>
                                                    </ng-template>
                                                    <ng-template ng-option-tmp let-item="item">
                                                        <span>{{ item.text | translate }}</span>
                                                    </ng-template>
                                                </ng-select>
                                                <app-show-validate-errors [isSubmit]="isSubmit"
                                                    [errorMessages]="validateError.type" [control]="form.controls.type"
                                                    [detail]="{ name: 'type' }"></app-show-validate-errors>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label class="d-inline-block w-100">
                                                    {{ 'ContractFlowAdd_file' | translate }} <span class="text-danger">*</span>
                                                    <a class="cursor-pointer text-primary text-decoration-underline font-weight-normal float-right" (click)="showFilePreview()">{{ 'ContractFlowAdd_viewFile' | translate }}</a>
                                                </label>
                                                <div class="input-group">
                                                    <div class="custom-file custom-file-icon">
                                                        <input #templateFile class="custom-file-input" id="inputGroupFile01"
                                                            placeholder="{{ 'ContractFlowAdd_ph_file' | translate }}" 
                                                            (input)="onAttachFileChanged($event)"
                                                            formControlName="file" type="file" accept=".docx,.pdf">
                                                        <label class="custom-file-label"
                                                                for="inputGroupFile01">{{ inputFileLabel | translate }}</label>
                                                        <span class="icon-file material-icons">attach_file</span>
                                                    </div>
                                                </div>
                                                <app-show-validate-errors [isSubmit]="isSubmit"
                                                    [errorMessages]="validateError.file" [control]="form.controls.file"
                                                    [detail]="{ name: 'file' }"></app-show-validate-errors>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>Cấu hình số hợp đồng tự động</label>
                                                <ng-select
                                                    [items]="dropListContractNumberConfig | async"
                                                    bindLabel="name"
                                                    bindValue="id"
                                                    placeholder="Chọn cấu hình số hợp đồng tự động"
                                                    [loading]="contractNumberConfigLoading"
                                                    [typeahead]="contractNumberConfigInput$"
                                                    (scrollToEnd)="onScrollToEndNgSelectContractNumber($event)"
                                                    formControlName="sequenceId">
                                                </ng-select>
                                            </div>
                                        </div>
                                        <div class="col-md-3">
                                            <div class="form-group">
                                                <label>{{ 'status' | translate }} <span class="text-danger">*</span></label>
                                                <ng-select
                                                    [items]="dropListStatus"
                                                    bindLabel="text"
                                                    bindValue="value"
                                                    placeholder="{{ 'ContractFlowAdd_ph_status' | translate }}"
                                                    formControlName="disable">
                                                    <ng-template ng-label-tmp let-item="item">
                                                        <span>{{ item.text | translate }}</span>
                                                    </ng-template>
                                                    <ng-template ng-option-tmp let-item="item">
                                                        <span>{{ item.text | translate }}</span>
                                                    </ng-template>
                                                </ng-select>
                                                <app-show-validate-errors [isSubmit]="isSubmit"
                                                    [errorMessages]="validateError.disable" [control]="form.controls.disable"
                                                    [detail]="{ name: 'disable' }"></app-show-validate-errors>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>{{ 'ContractFlowAdd_desc' | translate }}</label>
                                                <input appTrimValue class="form-control" formControlName="description" placeholder="{{ 'ContractFlowAdd_ph_desc' | translate }}">
                                                <app-show-validate-errors [isSubmit]="isSubmit"
                                                    [errorMessages]="validateError.description" [control]="form.controls.description"
                                                    [detail]="{ name: 'description' }"></app-show-validate-errors>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="col-md-12">
                                <div *ngIf="form.value.discuss === true"  class="row">
                                    <div class="col">
                                        <label class="text-primary">{{ 'ContractFlowAdd_employeePermission' | translate }}</label>
                                        <hr class="mt-0 mb-2">
                                        <table class="table table-bordered">
                                            <thead class="bg-tb-header">
                                                <tr>
                                                    <th>{{ 'ContractFlowAdd_stt' | translate }}</th>
                                                    <th>{{ 'ContractFlowAdd_party' | translate }}</th>
                                                    <th>{{ 'ContractFlowAdd_personel' | translate }}</th>
                                                    <th>{{ 'ContractFlowAdd_action' | translate }}</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of internalDiscuss; let i = index">
                                                    <td>{{ i + 1 }}</td>
                                                    <td [class.bg-danger-tb]="item.hasError('partyId')">
                                                        <ng-select
                                                            [items]="item.dropListPartData | async"
                                                            bindLabel="partyName"
                                                            bindValue="partyId"
                                                            [trackByFn]="item.trackByFn" 
                                                            [loading]="item.partsLoading"
                                                            [typeahead]="item.partsInput$"
                                                            [(ngModel)]="item.partyId"
                                                            (change)="item.changedParty($event)"
                                                            (scrollToEnd)="item.onScrollToEndDropListPart()"
                                                            placeholder="{{ 'ContractFlowAdd_ph_party' | translate }}"
                                                        >
                                                        </ng-select>
                                                    </td>
                                                    <td [class.bg-danger-tb]="item.hasError('userId')">
                                                        <ng-select
                                                            [items]="item.dropListEmployeeData | async"
                                                            bindLabel="fullName"
                                                            bindValue="userId"
                                                            [trackByFn]="item.trackByFnEmployee" 
                                                            [loading]="item.employeesLoading"
                                                            [typeahead]="item.employeesInput$"
                                                            [(ngModel)]="item.userId"
                                                            (change)="item.changedEmployee($event)"
                                                            (scrollToEnd)="item.onScrollToEndDropListEmployee()"
                                                            placeholder="{{ 'ContractFlowAdd_ph_personel' | translate }}"
                                                        >
                                                        </ng-select>
                                                    </td>
                                                    <td [class.bg-danger-tb]="item.hasError('permissions')">
                                                        <div class="icheck-primary mb-3" style="width: fit-content;">
                                                            <input [checked]="item.per_action.INTERNAL_DISCUSSABLE" type="checkbox"
                                                                id="checkboxPrimary-INTERNAL_DISCUSSABLE-{{i}}"
                                                                (change)="item.changedAction('INTERNAL_DISCUSSABLE')">
                                                            <label for="checkboxPrimary-INTERNAL_DISCUSSABLE-{{i}}">
                                                                {{ 'ContractFlowAdd_joinDiscuss' | translate }}
                                                            </label>
                                                        </div>
                                                        <div class="icheck-primary mb-3" style="width: fit-content;">
                                                            <input [checked]="item.per_action.INTERNAL_ACCEPTABLE" type="checkbox"
                                                                id="checkboxPrimary-INTERNAL_ACCEPTABLE-{{i}}"
                                                                (change)="item.changedAction('INTERNAL_ACCEPTABLE')">
                                                            <label for="checkboxPrimary-INTERNAL_ACCEPTABLE-{{i}}">
                                                                {{ 'ContractFlowAdd_acceptDenyConfirm' | translate }}
                                                            </label>
                                                        </div>
                                                        <div class="icheck-primary mb-3" style="width: fit-content;">
                                                            <input [checked]="item.per_action.INTERNAL_EDITABLE" type="checkbox"
                                                                id="checkboxPrimary-INTERNAL_EDITABLE-{{i}}"
                                                                (change)="item.changedAction('INTERNAL_EDITABLE')">
                                                            <label for="checkboxPrimary-INTERNAL_EDITABLE-{{i}}">
                                                                {{ 'ContractFlowAdd_addRemoveEmployee' | translate }}
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <a class="btn text-primary text-decoration-underline" (click)="removeInternalDiscuss(i)">{{ 'ContractFlowAdd_label_remove' | translate }}</a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div *ngIf="internalDiscussError" class="">
                                            <ol class="pl-3 text-danger">
                                                <li *ngIf="internalDiscussError['required']">
                                                    <p class="text-danger">{{ 'ContractFlowAdd_required' | translate }}</p>
                                                </li>
                                            </ol>
                                        </div>
                                        <p>
                                            <a class="btn text-primary text-decoration-underline" (click)="addInternalDiscuss()">+ {{ 'ContractFlowAdd_label_addNew' | translate }}</a>
                                        </p>
                                    </div>
                                </div>
                                
                                <div class="row">
                                    <div class="col">
                                        <label class="text-primary">{{ 'ContractFlowAdd_clc_internal_employee_join_discuss' | translate }}</label>
                                        <hr class="mt-0 mb-2">
                                        <div *ngIf="form.value.discuss === false" class="row my-3">
                                            <div class="col-md-4">
                                                <div class="cover-form-check h-100">
                                                    <div class="form-check">
                                                        <input [(ngModel)]="internalDiscussType" [value]="internalDiscussTypes.REQUIRE_FLOW" 
                                                            class="form-check-input" type="radio" 
                                                            name="internalDiscussType" 
                                                            id="internalDiscussType1" 
                                                            (change)="chooseInternalDiscussType($event)">
                                                        <label class="form-check-label ml-2" for="internalDiscussType1">
                                                            <p class="font-weight-bold">{{ 'ContractFlowAdd_sequentially' | translate }}</p>
                                                            <span>{{ 'ContractFlowAdd_sequentiallyEx' | translate }}</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="cover-form-check h-100">
                                                    <div class="form-check">
                                                        <input [(ngModel)]="internalDiscussType" [value]="internalDiscussTypes.NO_REQUIRE_FLOW" 
                                                            class="form-check-input" type="radio" 
                                                            name="internalDiscussType" 
                                                            id="internalDiscussType2"
                                                            (change)="chooseInternalDiscussType($event)">
                                                        <label class="form-check-label ml-2" for="internalDiscussType2">
                                                            <p class="font-weight-bold">{{ 'ContractFlowAdd_nonSequentially' | translate }}</p>
                                                            <span>{{ 'ContractFlowAdd_nonSequentiallyEx' | translate }}</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="cover-form-check h-100">
                                                    <div class="form-check">
                                                        <input [(ngModel)]="internalDiscussType" [value]="internalDiscussTypes.NO_REQUIRE" 
                                                            class="form-check-input" type="radio" 
                                                            name="internalDiscussType" 
                                                            id="internalDiscussType3"
                                                            (change)="chooseInternalDiscussType($event)">
                                                        <label class="form-check-label ml-2" for="internalDiscussType3">
                                                            <p class="font-weight-bold">{{ 'ContractFlowAdd_noAppraisal' | translate }}</p>
                                                            <span>{{ 'ContractFlowAdd_noAppraisalEx' | translate }}</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <table *ngIf="showTableInternalDiscuss" class="table table-bordered">
                                            <thead class="bg-tb-header">
                                                <tr>
                                                    <th>{{ 'ContractFlowAdd_stt' | translate }}</th>
                                                    <th>{{ 'ContractFlowAdd_party' | translate }}</th>
                                                    <th>{{ 'ContractFlowAdd_personel' | translate }}</th>
                                                    <th *ngIf="this.form.value.discuss === false">{{ 'ContractFlowAdd_appraisalOrder' | translate }}</th>
                                                    <th>{{ 'ContractFlowAdd_action' | translate }}</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of internalDiscuss; let i = index">
                                                    <td>{{ i + 1 }}</td>
                                                    <td [class.bg-danger-tb]="item.hasError('partyId')">
                                                        <ng-select
                                                            [items]="item.dropListPartData | async"
                                                            bindLabel="partyName"
                                                            bindValue="partyId"
                                                            [trackByFn]="item.trackByFn" 
                                                            [loading]="item.partsLoading"
                                                            [typeahead]="item.partsInput$"
                                                            [(ngModel)]="item.partyId"
                                                            (change)="item.changedParty($event)"
                                                            (scrollToEnd)="item.onScrollToEndDropListPart()"
                                                            placeholder="{{ 'ContractFlowAdd_ph_party' | translate }}"
                                                        >
                                                        </ng-select>
                                                    </td>
                                                    <td [class.bg-danger-tb]="item.hasError('userId')">
                                                        <ng-select
                                                            [items]="item.dropListEmployeeData | async"
                                                            bindLabel="fullName"
                                                            bindValue="userId"
                                                            [trackByFn]="item.trackByFnEmployee" 
                                                            [loading]="item.employeesLoading"
                                                            [typeahead]="item.employeesInput$"
                                                            [(ngModel)]="item.userId"
                                                            (change)="item.changedEmployee($event)"
                                                            (scrollToEnd)="item.onScrollToEndDropListEmployee()"
                                                            placeholder="{{ 'ContractFlowAdd_ph_personel' | translate }}"
                                                        >
                                                        </ng-select>
                                                    </td>
                                                    <td *ngIf="this.form.value.discuss === false" [class.bg-danger-tb]="item.hasError('sequence')">
                                                        <input *ngIf="internalDiscussType != internalDiscussTypes.NO_REQUIRE_FLOW" appTrimValue appOnlyNumber [(ngModel)]="item.sequence" class="form-control" placeholder="{{ 'ContractFlowAdd_ph_appraisalOrder' | translate }}">
                                                        <input *ngIf="internalDiscussType == internalDiscussTypes.NO_REQUIRE_FLOW" class="form-control" placeholder="{{ 'ContractFlowAdd_ph_appraisalOrder' | translate }}" disabled>
                                                    </td>
                                                    <td [class.bg-danger-tb]="item.hasError('permissions')">
                                                        <div class="icheck-primary mb-3" style="width: fit-content;">
                                                            <input [checked]="item.per_action.INTERNAL_DISCUSSABLE" type="checkbox"
                                                                id="checkboxPrimary-INTERNAL_DISCUSSABLE-{{i}}"
                                                                (change)="item.changedAction('INTERNAL_DISCUSSABLE')">
                                                            <label for="checkboxPrimary-INTERNAL_DISCUSSABLE-{{i}}">
                                                                {{ 'ContractFlowAdd_joinDiscuss' | translate }}
                                                            </label>
                                                        </div>
                                                        <div class="icheck-primary mb-3" style="width: fit-content;">
                                                            <input [checked]="item.per_action.INTERNAL_ACCEPTABLE" type="checkbox"
                                                                id="checkboxPrimary-INTERNAL_ACCEPTABLE-{{i}}"
                                                                (change)="item.changedAction('INTERNAL_ACCEPTABLE')">
                                                            <label for="checkboxPrimary-INTERNAL_ACCEPTABLE-{{i}}">
                                                                {{ 'ContractFlowAdd_acceptDenyConfirm' | translate }}
                                                            </label>
                                                        </div>
                                                        <div *ngIf="this.form.value.discuss === true" class="icheck-primary mb-3" style="width: fit-content;">
                                                            <input [checked]="item.per_action.INTERNAL_EDITABLE" type="checkbox"
                                                                id="checkboxPrimary-INTERNAL_EDITABLE-{{i}}"
                                                                (change)="item.changedAction('INTERNAL_EDITABLE')">
                                                            <label for="checkboxPrimary-INTERNAL_EDITABLE-{{i}}">
                                                                {{ 'ContractFlowAdd_addRemoveEmployee' | translate }}
                                                            </label>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <a class="btn text-primary text-decoration-underline" (click)="removeInternalDiscuss(i)">{{ 'ContractFlowAdd_label_remove' | translate }}</a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div *ngIf="showTableInternalDiscuss && internalDiscussError" class="">
                                            <ol class="pl-3 text-danger">
                                                <li *ngIf="internalDiscussError['required']">
                                                    <p class="text-danger">{{ 'ContractFlowAdd_required' | translate }}</p>
                                                </li>
                                                <li *ngIf="internalDiscussError['minSequence'] || internalDiscussError['maxSequence'] || internalDiscussError['uniqueSequence']">
                                                    <p class="text-danger">{{ 'ContractFlowAdd_required2' | translate }}</p>
                                                </li>
                                                <li *ngIf="internalDiscussError['requireAcceptPermission']">
                                                    <p class="text-danger">{{ 'ContractFlowAdd_required3' | translate }}</p>
                                                </li>
                                            </ol>
                                        </div>
                                        <p *ngIf="showTableInternalDiscuss">
                                            <a class="btn text-primary text-decoration-underline" (click)="addInternalDiscuss()">+ {{ 'ContractFlowAdd_label_addNew' | translate }}</a>
                                        </p>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <label class="text-primary">{{ 'ContractFlowAdd_clc_internal_employee_join_sign' | translate }}</label>
                                        <hr class="mt-0 mb-2">
                                        <div class="row my-3">
                                            <div class="col-md-4">
                                                <div class="cover-form-check">
                                                    <div class="form-check">
                                                        <input [(ngModel)]="signFlowType" [value]="signFlowTypes.REQUIRE_FLOW_STAMP" 
                                                            class="form-check-input" type="radio" 
                                                            name="flexRadioDefault" 
                                                            id="flexRadioDefault1" 
                                                            (change)="chooseSignFlowType($event)">
                                                        <label class="form-check-label ml-2" for="flexRadioDefault1">
                                                            <p class="font-weight-bold">{{ 'ContractFlowAdd_required_flow_and_stamp' | translate }}</p>
                                                            <span>{{ 'ContractFlowAdd_required_flow_and_stamp_desc' | translate }}</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="cover-form-check">
                                                    <div class="form-check">
                                                        <input [(ngModel)]="signFlowType" [value]="signFlowTypes.REQUIRE_FLOW" 
                                                            class="form-check-input" type="radio" 
                                                            name="flexRadioDefault" 
                                                            id="flexRadioDefault2"
                                                            (change)="chooseSignFlowType($event)">
                                                        <label class="form-check-label ml-2" for="flexRadioDefault2">
                                                            <p class="font-weight-bold">{{ 'ContractFlowAdd_required_flow' | translate }}</p>
                                                            <span>{{ 'ContractFlowAdd_required_flow_desc' | translate }}</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="cover-form-check">
                                                    <div class="form-check">
                                                        <input [(ngModel)]="signFlowType" [value]="signFlowTypes.NO_REQUIRE" 
                                                            class="form-check-input" type="radio" 
                                                            name="flexRadioDefault" 
                                                            id="flexRadioDefault3"
                                                            (change)="chooseSignFlowType($event)">
                                                        <label class="form-check-label ml-2" for="flexRadioDefault3">
                                                            <p class="font-weight-bold">{{ 'ContractFlowAdd_no_required_flow' | translate }}</p>
                                                            <span>{{ 'ContractFlowAdd_no_required_flow_desc' | translate }}</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <table class="table table-bordered">
                                            <thead class="bg-tb-header">
                                                <tr>
                                                    <th>{{ 'ContractFlowAdd_stt' | translate }}</th>
                                                    <th>{{ 'ContractFlowAdd_clc_tb_sign_type' | translate }}</th>
                                                    <th>{{ 'ContractFlowAdd_signature_type' | translate }}</th>
                                                    <th>{{ 'ContractFlowAdd_party' | translate }}</th>
                                                    <th>{{ 'ContractFlowAdd_personel' | translate }}</th>
                                                    <th>{{ 'ContractFlowAdd_clc_tb_sign_th' | translate }}</th>
                                                    <th>{{ 'ContractFlowAdd_clc_tb_progress_time' | translate }}</th>
                                                    <th>{{ 'ContractFlowAdd_actions' | translate }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of signFlow; let i = index">
                                                    <td>{{ i + 1 }}</td>
                                                    <td>
                                                        <input appTrimValue class="form-control" disabled placeholder="" value="{{ item.signTypeLabel  | translate}}">
                                                    </td>
                                                    <td [class.bg-danger-tb]="item.hasError('signForm')">
                                                        <ng-select
                                                            [items]="item.signFormData"
                                                            bindLabel="text"
                                                            bindValue="id"
                                                            [(ngModel)]="item.signForm"
                                                            [multiple]="true"
                                                            placeholder="{{ 'ContractFlowAdd_placeholder_signform' | translate }}"
                                                        >
                                                            <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                                                <span class="ng-value-label">{{item.text | translate}}</span>
                                                                <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                                                            </ng-template>
                                                            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                                                <span>{{item.text | translate}}</span>
                                                            </ng-template>
                                                        </ng-select>
                                                    </td>
                                                    <td [class.bg-danger-tb]="item.hasError('partyId')">
                                                        <ng-select
                                                            [items]="item.dropListPartData | async"
                                                            bindLabel="partyName"
                                                            bindValue="partyId"
                                                            [trackByFn]="item.trackByFn" 
                                                            [loading]="item.partsLoading"
                                                            [typeahead]="item.partsInput$"
                                                            [(ngModel)]="item.partyId"
                                                            (change)="item.changedParty($event)"
                                                            (scrollToEnd)="item.onScrollToEndDropListPart()"
                                                            placeholder="{{ 'ContractFlowAdd_ph_party' | translate }}"
                                                        >
                                                        </ng-select>
                                                    </td>
                                                    <td [class.bg-danger-tb]="item.hasError('userId')">
                                                        <ng-select
                                                            [items]="item.dropListEmployeeData | async"
                                                            bindLabel="fullName"
                                                            bindValue="userId"
                                                            [trackByFn]="item.trackByFnEmployee" 
                                                            [loading]="item.employeesLoading"
                                                            [typeahead]="item.employeesInput$"
                                                            [(ngModel)]="item.userId"
                                                            (change)="item.changedEmployee($event)"
                                                            (scrollToEnd)="item.onScrollToEndDropListEmployee()"
                                                            placeholder="{{ 'ContractFlowAdd_ph_personel' | translate }}"
                                                        >
                                                        </ng-select>
                                                    </td>
                                                    <td [class.bg-danger-tb]="item.hasError('sequence')">
                                                        <input appTrimValue *ngIf="item.signType === 'APPROVAL'" appOnlyNumber class="form-control" placeholder="{{ 'ContractFlowAdd_clc_tb_sign_th' | translate }}" disabled [value]="item.sequence">
                                                        <input appTrimValue *ngIf="item.signType === 'DRAFT'" appOnlyNumber class="form-control" placeholder="{{ 'ContractFlowAdd_clc_tb_sign_th' | translate }}" [(ngModel)]="item.sequence">
                                                        <input appTrimValue *ngIf="item.signType === 'STAMP'" appOnlyNumber class="form-control" placeholder="{{ 'ContractFlowAdd_clc_tb_sign_th' | translate }}" disabled [value]="item.sequence">
                                                    </td>
                                                    <td class="d-flex" [class.bg-danger-tb]="item.hasError('limitDate')">
                                                        <input appOnlyNumber class="form-control" placeholder="{{ localize.clc_tb_progress_time }}" [(ngModel)]="item.limitDate"> <span class="align-self-center pl-2"> {{ 'ContractFlowAdd_day' | translate }}</span>
                                                    </td>
                                                    <td>
                                                        <a *ngIf="item.delete" class="btn text-primary text-decoration-underline" (click)="removeSignFlow(i)">{{ 'ContractFlowAdd_label_remove' | translate }}</a>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div *ngIf="signFlowError" class="">
                                            <ol class="pl-3 text-danger">
                                                <li *ngIf="signFlowError['required']">
                                                    <p class="text-danger">{{ 'ContractFlowAdd_required' | translate }}</p>
                                                </li>
                                                <li *ngIf="signFlowError['minSequence'] || signFlowError['maxSequence'] || signFlowError['uniqueSequence']">
                                                    <p class="text-danger">{{ 'ContractFlowAdd_required2' | translate }}</p>
                                                </li>
                                                <li *ngIf="signFlowError['minLimitDate']">
                                                    <p class="text-danger">{{ 'ContractFlowAdd_required4' | translate }}</p>
                                                </li>
                                            </ol>
                                        </div>
                                        <p>
                                            <a class="btn text-primary text-decoration-underline" (click)="addSignFlow()">+ {{ 'ContractFlowAdd_label_addNew' | translate }}</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>
<app-preview-modal *ngIf="showFile" [file]="pdfFile" [name]="inputFileLabel" [useDownloadEvent]="attachFile == null" (close)="onClosePreview($event)" (download)="downloadStockFile($event)"></app-preview-modal>