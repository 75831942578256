import { MainResponse } from '../../main-response.model';
import { InfoPartyObject } from './info-party-object';

export class InfoPartyResponse extends MainResponse{
    object: InfoPartyObject;

    constructor(obj = null) {
        super();
        obj = obj || {};
        this.init(obj);
        this.object = new InfoPartyObject(obj.object);
    }
}