import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ReportService } from 'src/app/utils/services/aas-network/report/report.service';

@Component({
  selector: 'app-report-customer-detail',
  templateUrl: './report-customer-detail.component.html',
  styleUrls: ['./report-customer-detail.component.scss']
})
export class ReportCustomerDetailComponent implements OnInit {

  userId = this.router.snapshot.paramMap.get("id");
  

  constructor(
    private router: ActivatedRoute,
    private reportService: ReportService
  ) { }

  customer: any;
  consumerType: string = "CONSUMER";
  businessType: string = "BUSINESS";

  ngOnInit(): void {
    this.getCustomerDetail(this.userId);
  }


  getCustomerDetail(userId){
    this.reportService.getCustomerDetail(userId).subscribe((res: any) => {
      this.customer = res?.object[0] || "";
    },
    (err) => {
      if (!!err.error.error) {
        // this.alert(err.error.error[0], 'error');
        console.log(err.error.error[0]);
      }
    })
  }

  /**
   * Tra ve style cho span method
   * @param packageNum 
   */
   getStyleAndValueBadge(packageNum): string[] {
    if (packageNum == "0") {
      return ['badge-primary-new', 'Dùng thử'];
    }
    return ['badge-success-new', 'Chính thức'];
  }
}
