<!-- -------------------------------------------------------------------->

<app-spinner></app-spinner>
<div [class.d-none]="filled">
    <div class="landingpage">
        <div class="register">
            <section class="register-ss1">
                <img src="assets/img/home/v4/Image/Logo eContract_change color.png" alt="" class="img-logo-ipadpro">
                <div class="content col-12 p-0">
                    <div class="row swap-col">
                        <div class="col-auto-new p-0">
                            <div class="row">
                                <div class="col-12">
                                    <div class="content-left">
                                        <div class="row">
                                            <div class="col-left-auto">
                                                <div class="body">
                                                    <span class="logo block">
                                                        MỘT SẢN PHẨM CỦA TẬP ĐOÀN
                                                        <img src="../../../assets/img/home/v4/Image/logo vnpt.png" alt="">
                                                    </span>
                                                    <p class="title">
                                                        Hợp đồng điện tử<br />
                                                        VNPT eContract
                                                    </p>
                                                    <span class="dec">
                                                        Cung cấp dịch vụ hợp đồng điện tử cho phép các bên giao kết các loại
                                                        hợp đồng
                                                        bằng phương tiện điện tử.
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-left-auto">
                                                <img src="assets/img/home/v4/Image/Group 177362.png" alt=""
                                                    class="d-xl-block imgbg">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-auto-new p-0">
                            <div class="content-right">
                                <div class="row justify-content-center">
                                    <div class="col-10 col-md-6 col-xl-9">
                                        <div class="body">
                                            <img src="assets/img/home/v4/Image/LOGO261.png" alt="" class="img-logo">
                                            <h3 class="title">{{ 'Register_title' | translate }}</h3>
                                            <label for="">{{ 'Register_h_1' | translate }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="row justify-content-center">
                                    <div class="col-10 col-md-6 col-xl-9">
                                        <ul class="nav nav-tabs" role="tablist">
                                            <li class="nav-item">
                                                <a #navConsumer class="nav-link active" href="#navConsumer" role="tab" data-toggle="tab">
                                                    <img src="../../../assets/img/home/v4/icon/user.png" alt="">
                                                    {{ 'Register_h_2' | translate }}</a>
                                            </li>
                                            <li class="nav-item">
                                                <a #navBusiness class="nav-link" href="#navBusiness" role="tab" data-toggle="tab">
                                                    <img src="../../../assets/img/home/v4/icon/Group.png" alt="">
                                                    {{ 'Register_h_3' | translate }}</a>
                                            </li>
                                        </ul>
                                        <!-- Tab panes -->
                                        <div class="tab-content">
                                            <!-- tab cá nhân -->
                                            <div role="tabpanel" class="tab-pane active" id="navConsumer">
                                                <app-register-consumer (dataFilled)="dataFilled($event)"></app-register-consumer>
                                            </div>
                                            <!-- tab tổ chức -->
                                            <div role="tabpanel" class="tab-pane fade" id="navBusiness">
                                                <app-register-business (dataFilled)="dataFilled($event)"></app-register-business>
                                            </div>
                                        </div>
                                        <!-- <div class="login-form">
                                            <form [formGroup]="loginForm" (ngSubmit)="login()">
                                                <div class="form-group">
                                                    <label for="">Tài khoản<span class="text-danger">*</span></label>
                                                    <input appTrimValue formControlName="email" type="text"
                                                        class="form-control" placeholder="Tài khoản">
                                                    <app-show-validate-errors [isSubmit]="isSubmit"
                                                        [errorMessages]="validateMsg.username" [control]="email"
                                                        [detail]="{ name: 'email' }"></app-show-validate-errors>
                                                </div>
                                                <div class="form-group">
                                                    <label for="">Mật khẩu<span class="text-danger">*</span></label>
                                                    <div class="d-block position-relative">
                                                        <input appInputPassword formControlName="password" type="password"
                                                            class="form-control" placeholder="Mật khẩu">
                                                    </div>
                                                    <app-show-validate-errors [isSubmit]="isSubmit"
                                                        [errorMessages]="validateMsg.password" [control]="password"
                                                        [detail]="{ name: 'password' }"></app-show-validate-errors>
                                                    <p class="error text-danger" *ngIf="loginFailed && loginForm.valid">Tài
                                                        khoản hoặc mật khẩu
                                                        không đúng!</p>
                                                </div>
                                                <button [disabled]="checkEdited" (click)="login()" type="submit"
                                                    class="btn_reg">Đăng nhập</button>
                                            </form>
                                            <div class="text-left">
                                                <span>Bạn chưa có tài khoản? Vui lòng <a class="text-primary text-center text-decoration-underline"
                                                        [routerLink]="['/app/signup']">đăng ký</a></span>
                                            </div>
                                        </div> -->
    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
    <a href="" class="btn-backhome">
        <img src="assets/img/home/v4/icon/backicon.png" alt="">
    </a>
</div>

<div [class.d-none]="!filled">
    <app-verify-otp [userId]="userId" [userType]="userType"></app-verify-otp>
</div>
