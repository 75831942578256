import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HomeService } from 'src/app/utils/services/home/home.service';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss']
})
export class PostComponent implements OnInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private homeService: HomeService
  ) { }

  // ID take from list guide in priceList page.
  id = this.activatedRoute.snapshot.paramMap.get('id');

  // Content of post.
  postContent: any = null;

  ngOnInit(): void {
    if (!!this.id) this.getPostById();
  }

  // Call api get post by id with id is this.id
  getPostById() {
    this.homeService.getPostById(this.id).subscribe((res: any) => {
      this.postContent = res?.object || {};
    });
  }

}
