import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { Options, Select2AjaxOptions } from 'select2';
import { Select2OptionData } from 'ng-select2';
import { FormGroup, FormBuilder, FormControl, Validators, Form, NgModel, AbstractControl, FormArray } from '@angular/forms';
import { ValidateMessage, AasValidateMessage } from 'src/app/utils/common/validate-msg-aas';
import { AasContractService } from 'src/app/utils/services/aas-network/aas-contract.service';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { DateUtils } from 'src/app/utils/common/DateUtils';
import { LoadingService } from 'src/app/utils/services/loading.service';
import { Config } from 'src/app/config';
import { AuthService } from 'src/app/utils/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ContractObject } from 'src/app/utils/models/aas-models/contract/contract/contract-object.model';
import { ContractResponse } from 'src/app/utils/models/aas-models/contract/contract/contract-response.model';
import * as ClassicEditor from '../../../../../../assets/js/editor/ckeditor';
import { UserTypeConstant, VerificationType } from 'src/app/utils/common/constant';
import { InfoPartyObject } from 'src/app/utils/models/aas-models/info-party/info-party-object';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { PartyJoinContract } from 'src/app/utils/models/aas-models/contract/party-join-contract/party-join-contract';
import { REGEXP_DATE_INPUT, REGEXP_NUMBER } from 'src/app/utils/common/regexp';
import { PackageSessionService } from 'src/app/utils/services/aas-network/package-session/package-session.service';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertBtn, ConstantAlertMsg } from 'src/app/utils/common/constant-alert-msg';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { TemplateService } from 'src/app/utils/services/aas-network/template.service';
import { ConfigItem, ListCheckBox, ListTable, TemplateManager } from 'src/app/utils/common/TemplateManager';
import { ContractTemplateItem } from 'src/app/utils/models/contract-template/contract-template-item.model';
import { TemplateVar } from 'src/app/utils/models/aas-models/template/template-var';
import { FillDataContractTemplateComponent } from '../layout/fill-data-contract-template/fill-data-contract-template.component';
import { localize } from 'src/app/utils/localize/localize';
import { StringUtils } from 'src/app/utils/common/StringUtils';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';
import { FlowContractService } from 'src/app/utils/services/aas-network/flow-contract/flow-contract.service';
import { FlowContract } from 'src/app/utils/models/aas-models/flow-contract/flow-contract.model';
import { InternalDiscuss } from 'src/app/utils/models/aas-models/flow-contract/internal-discuss.model';
import { PartnerDiscuss } from 'src/app/utils/models/aas-models/flow-contract/partner-discuss.model';
import { SignFlow } from 'src/app/utils/models/aas-models/flow-contract/sign-flow.model';
import { UtilsFunc } from 'src/app/utils/common/utils-func';
import { ControlAccount } from 'src/app/utils/common/control-account';
import { RoleCode } from 'src/app/utils/common/constants/roles-code';
import { InternalDiscussTypes } from 'src/app/utils/common/constants/sign-form';
import { PartySignBox } from 'src/app/utils/models/aas-models/signbox/party-sign-box';
import { UserSignBox } from 'src/app/utils/models/aas-models/signbox/user-sign-box';
import { SignBox } from 'src/app/utils/models/aas-models/signbox/sign-box';
import { TranslateService } from '@ngx-translate/core';
import { SignatureService } from 'src/app/utils/services/aas-network/signature.service';
import { ContractNumberConfigService } from 'src/app/utils/services/aas-network/contract-number-config/contract-number-config.service';
import { ContractNumberBox } from 'src/app/utils/models/aas-models/signbox/contract-number-box';
import { GuideTourComponent } from 'src/app/layout/extensions/guide-tour/guide-tour.component';
import { ContractTypeService } from '../../contract-type-management/services/contract-type.service';

declare var PDFSign: any;
declare var $: any;
declare var Cleave: any;
declare var vnpt_plugin: any;
declare var PdfSigner: any;
@Component({
  selector: 'app-service-contract',
  templateUrl: './service-contract.component.html',
  styleUrls: ['./service-contract.component.scss']
})
export class ServiceContractComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('output') output: ElementRef;
  @ViewChild('filename') filename: ElementRef;
  @ViewChild('filebase') filebase: ElementRef;
  @ViewChild('myEditor') myEditor: any;
  @ViewChild('check_non_discuss') set content(content: ElementRef) {
    if (content) { // initially setter gets called with undefined
      this.check_non_discuss = content;
    }
  }
  check_non_discuss: ElementRef;
  @ViewChild('contentFillData') contentFillData: FillDataContractTemplateComponent;
  @ViewChild('hdsd') hdsd: GuideTourComponent;

  public Editor = ClassicEditor;
  public exampleData: Array<Select2OptionData> = [];
  public nguoiNhanData: Array<Select2OptionData> = [];
  public hinhThucKyData: Array<Select2OptionData> = [];
  public options: Options;
  public optionsTemplate: Options;
  public optionsHinhThucKy: Options;
  public ajaxOptions: Select2AjaxOptions;
  public optionsContractTemplate: Options;
  public contractTemplateData: Array<any> = [];

  contractId: string = this.activeRoute.snapshot.paramMap.get('id') || '';
  isUpdate: boolean = false;
  contract: ContractObject = new ContractObject();
  isFirstCreate= window.localStorage.getItem('firstCreate');
  //mark: nhóm chức năng khởi tạo thông tin hợp đồng
  filePDF: File = null;
  inputFileLabel: string = 'ContractTemplateAdd_attachedFile';
  fileType: Array<string> = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
  selectedFile: boolean = false;
  fileName: string = '';
  typeSelected: number = 1;

  configContractNumber: boolean = false;

  formContract: FormGroup;
  validContractMsg: any = AasValidateMessage.FORM_CREATE_CONTRACT;
  isSubmitContract: boolean = false;
  contractNumberAction: String= "";

  hinhThucHienThi = [
    { value: 0, name: "Không hiển thị" },
    { value: 1, name: "Chỉ hiển thị mô tả" },
    { value: 2, name: "Hiển thị mô tả và hình ảnh bên trái" },
    { value: 3, name: "Chỉ hiển thị hình ảnh" },
    { value: 4, name: "Hiển thị mô tả và hình ảnh phía trên" },
    { value: 5, name: "Hiển thị mô tả và hình ảnh dạng hình nền" }
  ]

  selectedAllHinhThucKy: boolean = false;
  hinhThucKy_consumer = [
    { id: 'NO_AUTHEN', text: 'Cấp 1: Ký ảnh' },
    { id: 'OTP', text: 'Cấp 2: SMS OTP' },
    { id: 'OTP_EMAIL', text: "Cấp 2: Email OTP" },
    // { id: 'EKYC', text: 'Cấp 3: SMS OTP và eKYC' },
    // { id: 'EKYC_EMAIL', text: "Cấp 3: Email OTP và eKYC" },
    { id: 'USB_TOKEN', text: 'Cấp 4: Usb-Token' },
    { id: 'SIGN_SERVER', text: 'Cấp 4: VNPT ký số' },
  ];

  hinhThucKy_business = [
    { id: 'NO_AUTHEN', text: 'Cấp 1: Ký ảnh' },
    { id: 'OTP', text: 'Cấp 2: SMS OTP' },
    { id: 'OTP_EMAIL', text: "Cấp 2: Email OTP" },
    // { id: 'EKYC', text: 'Cấp 3: SMS OTP và eKYC' },
    // { id: 'EKYC_EMAIL', text: "Cấp 3: Email OTP và eKYC" },
    { id: 'USB_TOKEN', text: 'Cấp 4: Usb-Token' },
    { id: 'SIGN_SERVER', text: 'Cấp 4: VNPT ký số' },
  ]

  hinhThucXacMinh = [
    { id: VerificationType.VIDEO_CALL, text: 'Xác minh' },
    { id: VerificationType.NONE, text: 'Không xác minh' }
  ]

  //Tab controll
  activeTab: string = 'INFO';

  currentDate = new Date();
  dateType = {
    ISSUE: 'ISSUE',
    EXPRIRED: 'EXPRIRED'
  }

  partyInfo: InfoPartyObject = new InfoPartyObject();
  partiesJoin: Array<PartyJoinContract> = [];
  showPartyJoin: boolean = false;

  userTypeConst = UserTypeConstant;

  optionsCleave: any;
  optionsCleaveCost: any;

  partiesFormArray: FormArray;

  localize = localize;

  dropListDiscuss = [
    { id: true, text: "ServiceContract_yes" },
    { id: false, text: "ServiceContract_no" }
  ];

  rolesCode = RoleCode;

  fixedPosition: any = true;
  attachNewFile = false;
  contractNumberBox: ContractNumberBox = null;
  steps = [
    {
      type: 'introduce',
      title: 'Chào mừng quý khách đến với dịch vụ NBW eContract. ',
      content: 'Đây là giao diện khởi tạo hợp đồng của quý khách. Click vào nút “Bắt đầu” để xem các bước khởi tạo hợp đồng trên hệ thống NBW eContract',
      elementAttribute: '',
    },
    {
      type: 'step',
      title: 'Bước 1. Chọn hình thức tạo hợp đồng',
      content: 'Sử dụng 1 trong 3 hình thức để bắt đầu tạo hợp đồng.',
      elementAttribute: 'type-contract',
    },
    {
      type: 'step',
      title: 'Bước 2. Chọn luồng hợp đồng',
      content:
        'Chọn luồng xử lý hợp đồng phù hợp với từng loại hợp đồng. Tùy chỉnh luồng hợp đồng đã chọn bằng cách click chuột vào biểu tượng cài đặt.',
      elementAttribute: 'contract-flow',
    },
    {
      type: 'step',
      title: 'Bước 3. Điền tên hợp đồng',
      content: 'Nhập tên hợp đồng của quý khách tại đây.',
      elementAttribute: 'contract-name',
    },
    {
      type: 'step',
      title: 'Bước 4. Thêm bên tham gia hợp đồng',
      content: 'Thêm bên tham gia ký kết hợp đồng bằng cách click vào nút bên cạnh',
      elementAttribute: 'contract-partner',
    },
    {
      type: 'end',
      title: 'Xem lại hướng dẫn tạo hợp đồng',
      content: 'Quý khách có thể xem lại hướng dẫn các bước tạo hợp đồng ở đây bất cứ lúc nào',
      elementAttribute: 'contract-end',
    }
  ];

    // Loai hop dong ||  Contract type
    dropListTypeData: Observable<any>;
    typesLoading = false;
    typeInput$ = new Subject<string>();
    dropListTypePage = 1;
    endOfDropListTypePage = false;
    keySearchDropListType = "";

  constructor(
    private _location: Location,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private fb: FormBuilder,
    private contractService: AasContractService,
    private authService: AuthService,
    private modalService: NgbModal,
    private loaderSerive: LoadingService,
    private aasAuth: AasAuthService,
    private packageSession: PackageSessionService,
    private templateService: TemplateService,
    private myAlert: AlertControl,
    private flowContractService: FlowContractService,
    private userControl: ControlAccount,
    private translate: TranslateService,
    private signatureApi: SignatureService,
    private cncService: ContractNumberConfigService,
    private contractTypeService: ContractTypeService
  ) {
    // if (this.packageSession.packageType === "TRIAL_TYPE") {
    //   this.hinhThucXacMinh.shift();
    // }
    this.userControl.getAccountInfo();
  }

  ngOnInit(): void {
    this.makeFormContract();
    this.makeAutoFormatDate();
    this.makeAutoFormatCost();

    this.partiesFormArray = this.fb.array([]);

    this.getPartyInfo(this.authService.partyId);

    console.log(this.contractId);
    if (this.contractId !== '') {
      this.isUpdate = true;
      this.getContract(this.contractId);
    }

    this.getDropListTemplateV1(1, 1000000, "");

    if (this.userControl.checkRoles(RoleCode.rolesQuanlyLuong)) {
      //Ton tai quyen su dung luong
      this.lazyLoadFlow();
    }
    
    this.lazyLoadDropListType();
  }
  ngAfterViewInit(): void {
    console.log(document.querySelector<HTMLElement>(
      `[data-guide="type-contract"]`))
      console.log(this.isFirstCreate);
    if(this.isFirstCreate === null){
      this.isFirstCreate= '1'
      this.hdsd.recieveData(this.steps)
    }
  }
  ngOnDestroy(): void {
    window.localStorage.setItem('firstCreate', '1')
  }

  //MARK: KHỞI TẠO FORM TẠO HỢP ĐỒNG
  makeFormContract() {
    this.formContract = this.fb.group({
      tenHopDong: new FormControl('', [Validators.required]),
      file: new FormControl(''),
      ngayHieuLuc: new FormControl('', [Validators.pattern(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i)]),
      ngayHetHieuLuc: new FormControl('', [Validators.pattern(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i)]),
      tuGiaHan: new FormControl(true, [Validators.required]),
      giaTriHd: new FormControl('', []),
      ghiChu: new FormControl(''),
      discuss: new FormControl(null, [Validators.required]),
      flowTemplateId: new FormControl(null),
      sequenceName: new FormControl(""),
      sequenceId: new FormControl(""),
      typeId: [null]
    });
  }

  get sequenceName() { return this.formContract.get('sequenceName'); }
  get sequenceId() { return this.formContract.get('sequenceId');}

  get tenHopDong() { return this.formContract.get('tenHopDong'); }
  get file() { return this.formContract.get('file'); }
  get ngayHieuLuc() { return this.formContract.get('ngayHieuLuc'); }
  get ngayHetHieuLuc() { return this.formContract.get('ngayHetHieuLuc'); }
  get tuGiaHan() { return this.formContract.get('tuGiaHan'); }
  get giaTriHd() { return this.formContract.get('giaTriHd'); }
  get thoiHanKy() { return this.formContract.get('thoiHanKy'); }
  get ghiChu() { return this.formContract.get('ghiChu'); }
  get typeId() {
    return this.formContract.get('typeId');
  }

  /**
   * Set du lieu cu
   */
  setData() {
    this.tenHopDong.setValue(this.contract.title);
    this.ngayHieuLuc.setValue(DateUtils.convert(this.contract.validDate, DateUtils.format.INPUT));
    this.ngayHetHieuLuc.setValue(DateUtils.convert(this.contract.endDate, DateUtils.format.INPUT))
    this.giaTriHd.setValue(this.contract.contractValue);
    this.ghiChu.setValue(this.contract.creationNote);
    this.tuGiaHan.setValue(this.contract.autoRenew);

    if (this.contract.templateId !== "") {
      this.formContract.addControl("templateId", this.fb.control(null, [Validators.required]));
      this.formContract.controls.templateId.setValue(this.contract.templateId);

      this.selectType(0);
    }

    this.inputFileLabel = `${this.contractId}.pdf`;

    console.log(this.contract.contractPartysData);
    this.contract.contractPartysData.forEach((item) => {
      var party: FormGroup;
      var isTrustedPartner = '';

      if (item.partyId === this.authService.partyId) {
        party = this.initFormNoValid();
        isTrustedPartner = 'Y';
        party.patchValue({
          allowDetete: false,
          allowEditUserType: false,
          allowEditPartyId: false,
          isOwner: true
        });
      } else {
        party = this.initForm();
        isTrustedPartner = item.isTrustedPartner;
      }

      party.patchValue({
        partyId: item.partyId,
        tenToChuc: item.tenToChuc,
        userType: item.userType,
        email: item.email,
        tenDaiDien: item.tenDaiDien,
        endDate: DateUtils.convert(item.endDate, DateUtils.format.INPUT),
        signForm: item.signForm,
        isTrustedPartner: isTrustedPartner,
        verificationType: item.verificationType,
        sequence: item.sequence,
        hinhThucKyData: (item.partyType === this.userTypeConst.CONSUMER || item.userType === this.userTypeConst.CONSUMER) ? this.hinhThucKy_consumer : this.hinhThucKy_business,
        nguoiNhanData: [
          {
            id: item.partyId,
            text: item.email,
            additional: {
              partyId: item.partyId,
              type_customer: item.partyType === this.userTypeConst.CONSUMER ? this.translate.instant('individual') : this.translate.instant('organization'),
              type_cutomer_label: item.userType,
              email: item.email,
              isTrustedPartner: isTrustedPartner,
              sdt: item.sdt
            }
          }
        ],
        sdt: item.sdt
      })

      this.partiesFormArray.push(party);
    });

    console.log(this.partiesFormArray.value);

    this.typeId.setValue(this.contract.contractTypeId);  
  }

  //MARK: CHỨC NĂNG INPUT NGÀY THÁNG NĂM 
  /**
   * Cai data option Cleave nhap ngay thang nam
   */
  makeAutoFormatDate() {
    this.optionsCleave = {
      date: true,
      delimiter: '/',
      datePattern: ['d', 'm', 'Y'],
    }
  }

  /**
   * Cai dat option Cleave nhap so tien
   */
  makeAutoFormatCost() {
    this.optionsCleaveCost = {
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
    }
  }

  convertCurency(value: any) {
    let curency: any

    console.log(value);
    value = value.replace(/,/g, '')
    curency = value.replace(/VNĐ/g, '')
    curency = Math.abs(curency);
    return curency;
  }

  //MARK: CHỨC NĂNG ĐIỀU KHIỂN TAB
  //Action chọn tab
  selectTab(nameTab: string) {
    this.activeTab = nameTab;
  }

  //MARK: NHÓM CHỨC NĂNG SOẠN THẢO HỢP ĐỒNG
  public getEditorContent() {
    console.log(this.myEditor.editorInstance.getData());
  }

  //MARK: NHÓM CHỨC NĂNG KHỞI TẠO HỢP ĐỒNG
  //Lưu hợp đồng
  actBtnSave(event) {
    this.isSubmitContract = true;
    switch (this.typeSelected) {
      case 0:
        console.log(this.formContract.value);
        if (this.formContract.valid && this.filePDF !== null && this.filePDF !== undefined) {
          this.myAlert.showAlert(this.translate.instant('ServiceContract_ts_1'), '', true, '', this.translate.instant('btn_ok'), () => {
            this.saveContract();
          }, '', this.translate.instant('btn_cancel'));
        }
        break;
      case 1:
        if (this.filePDF == null || this.filePDF == undefined) {
          this.formContract.get('file').setErrors({ required: true });
        }
        if (this.formContract.valid && this.filePDF !== null && this.filePDF !== undefined) {
          this.myAlert.showAlert(this.translate.instant('ServiceContract_ts_1'), '', true, '', this.translate.instant('btn_ok'), () => {
            this.saveContract();
          }, '', this.translate.instant('btn_cancel'));
        }
        break;
      case 2:
        if (this.formContract.valid) {
          this.myAlert.showAlert(this.translate.instant('ServiceContract_ts_1'), '', true, '', this.translate.instant('btn_ok'), () => {
            this.convertHtmlToPdf();
          }, '', this.translate.instant('btn_cancel'));
        }
        break;
    }
  }

  //Convert html to pdf
  convertHtmlToPdf(andSend: boolean = false) {
    let html = this.myEditor.editorInstance.getData();
    let formData = new FormData();
    formData.append('htmlString', html);

    this.postConvertHTMLToPdf(formData, andSend);
  }

  //Lưu và gửi hợp đồng
  saveAndSendContract() {
    // this.postSubmitSendDraft(this.contractId);
    this.isSubmitContract = true;

    if (this.typeSelected === 1) {
      if (this.filePDF == null || this.filePDF == undefined) {
        this.formContract.get('file').setErrors({ required: true });
      }

      console.log(this.formContract.value);
      if (this.formContract.valid && this.filePDF !== null && this.filePDF !== undefined) {
        this.myAlert.showAlert(this.translate.instant('ServiceContract_ts_2'), '', true, '', this.translate.instant('btn_ok'), () => {
          this.saveContract(true);
        }, '', this.translate.instant('btn_cancel'));
      }
    } else {
      if (this.formContract.valid) {
        this.myAlert.showAlert(this.translate.instant('ServiceContract_ts_2'), '', true, '', this.translate.instant('btn_ok'), () => {
          this.convertHtmlToPdf(true);
        }, '', this.translate.instant('btn_cancel'));
      }
    }
  }

  /**
   * Call Api save contract
   */
  saveContract(andSend: boolean = false) {

    return this.saveContractV2(andSend);

    // if (this.partiesFormArray.value instanceof Array && this.partiesFormArray.value.length < 2) {
    //   this.myAlert.showAlertOnlyNoti(localize.contract_not_enough_partner, ConstantAlertType.ERROR, "", localize.btn_close);
    //   return;
    // }

    // let data = new FormData();
    // data.append('title', this.tenHopDong.value || '');
    // data.append('validDate', DateUtils.convertInputToFormat(this.ngayHieuLuc.value, DateUtils.format.API) || "");
    // data.append('endDate', DateUtils.convertInputToFormat(this.ngayHetHieuLuc.value, DateUtils.format.API) || "");
    // data.append('contractValue', this.convertCurency(this.giaTriHd.value || '') || '');
    // data.append('creationNote', this.ghiChu.value);
    // data.append('autoRenew', this.tuGiaHan.value);
    // data.append('discuss', this.formContract.value.discuss);
    
    // var paritesParam = [];
    // this.partiesFormArray.value.forEach((item) => {
    //   if (item.partyId !== this.partyInfo.partyId) {
    //     paritesParam.push({
    //       customerIdOrEmail: item.partyId, 
    //       customerType: item.userType, 
    //       verificationType: item.verificationType,
    //       signForm: item.signForm, 
    //       // endDate: DateUtils.convertInputToFormat(item.endDate, DateUtils.format.API),
    //       sequence: Number(item.sequence)
    //     })
    //   } else {
    //     paritesParam.push({
    //       customerIdOrEmail: item.partyId,
    //       customerType: this.partyInfo.partyType,
    //       sequence: Number(item.sequence)
    //     })
    //   }
    // })

    // data.append('partys', JSON.stringify(paritesParam));

    // if (this.typeSelected === 0 && this.templatedSelected) {
    //   this.createContractWithTemplate(data, andSend);
    // } else {
    //   data.append('draftFile', this.filePDF, this.filePDF.name);

    //   if (this.contractId !== '') {
    //     if (this.checkAllowEdit()) {
    //       data.append('templateId', null);
    //       data.append('templateGroupId', null);
    //       data.append('templateGroupName', null);
    //       data.append('contractFields', null);
          
    //       this.postUpdateContract(this.contractId, data, andSend);
    //     } else {
    //       this.alert('Bạn không có quyền chỉnh sửa hợp đồng này!', 'error');
    //     }
    //   } else {
    //     this.postCreateDraftOnlyFile(data, andSend);
    //   }
    // }
  }

  /**
   * Action huy khoi tao hop dong
   * @param event 
   */
  actCancelBtn(event) {
    if (this.contractId === '') {
      this.myAlert.showAlert(this.translate.instant('ServiceContract_ts_3'), '', true, '', this.translate.instant('btn_ok'), () => {
        this.router.navigate(['/app/console/contract-manager']);
      }, '', this.translate.instant('btn_cancel'));
    } else {
      this.myAlert.showAlert(this.translate.instant('ServiceContract_ts_4'), '', true, '', this.translate.instant('btn_ok'), () => {
        this.router.navigate(['/app/console/contract-manager']);
      }, '', this.translate.instant('btn_cancel'));
    }
  }

  /**
   * Chon phuong thuc tao file hop dong
   * @param value 
   */
  selectType(value) {
    this.typeSelected = value;
    if (value == 0 || value == 2) {
      this.changeDiscussStatus(false);
    } else {
      this.changeDiscussStatus(true);
    }

    if (value == 0) {
      this.formContract.addControl("templateId", this.fb.control(null, [Validators.required]));
    } else {
      this.formContract.controls.templateId && this.formContract.removeControl("templateId");
    }

    this.lazyLoadFlow();
  }

  /**
   * Chon file tu hop dong
   * @param e 
   */
  handleFileInput(e) {
    this.filePDF = e.target.files && e.target.files.item(0);
    console.log(this.filePDF);
    this.formContract.get('file').setErrors(null);
    if (this.filePDF && this.filePDF !== null && this.filePDF !== undefined) {
      if (!this.fileType.includes(this.filePDF.type)) {
        this.formContract.get('file').setErrors({ type: true });
        this.inputFileLabel = this.filePDF.name
      } else if (this.filePDF.size > 5000000) {
        this.myAlert.showAlertOnlyNoti(this.translate.instant('ServiceContract_FILE_SIZE_TOO_LARGE'), ConstantAlertType.ERROR);
      } else {
        this.attachNewFile = true; // Trường hợp attach file mới khi edit tính 1 phiên bản
        this.inputFileLabel = this.filePDF.name;
        if (this.filePDF.type === 'application/pdf') {
          this.fileName = this.filePDF.name;
          PDFSign.preview(this.filePDF);

          this.getBase64String(this.filePDF, this.handle);
        } else {
          let fileSplit = this.filePDF.name.split('.');
          fileSplit.pop();
          this.fileName = `${fileSplit.join('.')}.pdf`;

          let data = new FormData();
          data.append('attachFile', this.filePDF);
          this.postConvertToPDF(data);
        }

      }
      
      this.loaderSerive.showElement();
      setTimeout(() => {
        if (this.formContract.controls.sequenceId.value
          && typeof (this.formContract.controls.sequenceId.value) === 'string'
          && this.formContract.controls.sequenceId.value.length > 0) {
            if (this.contractNumberBox) {
              this.contractNumberBox.removeBox();
              this.contractNumberBox.addSignBox(this.contractNumberBox.box);
            }
        }
        this.loaderSerive.hideElement();
      }, 1000)
    } else {
      // this.fileForm.get('file').setErrors({ required: true });
      this.inputFileLabel = 'ContractTemplateAdd_attachedFile';
    }
  }

  /**
   * Thay doi gia tri ngay input
   * @param event 
   * @param type 
   */
  changeDate(event, type) {
    switch (type) {
      case this.dateType.ISSUE:
        this.formContract.controls.ngayHieuLuc.setValue(DateUtils.convertObjtoDate(event));
        break;
      case this.dateType.EXPRIRED:
        this.formContract.controls.ngayHetHieuLuc.setValue(DateUtils.convertObjtoDate(event));
        break;
    }
  }

  /**
   * 
   * @param value 
   */
  changeDiscussStatus(value) {
    this.formContract.controls.discuss.setValue(value);
  }

  /**
   * Diabled input truong dam phan hop dong
   */
  get disableDiscussStatus() {
    // return true;
    return this.typeSelected == 0 || this.typeSelected == 2;
  }

  //Kiểm tra form đã có dữ liệu chưa
  get editedForm() {
    if (this.tenHopDong.value !== '' && this.tenHopDong.value !== null && this.tenHopDong.value !== undefined) return false;
    if (this.ngayHieuLuc.value !== '' && this.ngayHieuLuc.value !== null && this.ngayHieuLuc.value !== undefined) return false;
    if (this.ngayHetHieuLuc.value !== '' && this.ngayHetHieuLuc.value !== null && this.ngayHetHieuLuc.value !== undefined) return false;
    if (this.giaTriHd.value !== '' && this.giaTriHd.value !== null && this.giaTriHd.value !== undefined) return false;
    if (this.ghiChu.value !== '' && this.ghiChu.value !== null && this.ghiChu.value !== undefined) return false;
    if (JSON.stringify(this.tuGiaHan.value) !== '' && this.tuGiaHan.value !== null && this.tuGiaHan.value !== undefined) return false;
    if (this.filePDF !== null && this.filePDF !== undefined) return false;
    return true;
  }

  getBase64String(blob, callback) {
    var self = this;
    var reader = new FileReader();
    reader.onload = function (e) {
      callback(reader.result, self);
    }

    reader.readAsDataURL(blob);
  }

  //handel cho file pdf
  handle(str, self) {
    $('#fileBase').val(str.split(',')[1]);
  }

  //Handle cho file hình sign server
  handleImgSignServer(str, self) {
    self.imgContent = str;
    PDFSign.setImage(str.split(',')[1]);
  }

  //Kiểm tra quyền cập nhật
  checkAllowEdit() {
    let userId = this.authService.userId;
    let partyId = this.authService.partyId;
    let ownerPartyId = this.contract.ownerPartyId;
    let amId = this.contract.amId;
    let status = this.contract.currentStage;

    for (var i = 0; i < this.contract.signatures.length; i++) {
      if (this.contract.signatures[i].userId === userId) {
        return false;
      }
    }

    if (partyId === ownerPartyId && amId === userId && status === 'LC_DRAFT_CREATE') {
      return true;
    }

    return false;
  }

  //Trở về trang trước
  goToBack() {
    // this._location.back();
    this.router.navigate(['/app/console/contract-manager']);
  }

  get allowSendContract() {
    return true;
  }

  //Tải xuống file hợp đồng
  saveAsContract() {
    if (this.contractId !== '') {
      if (this.contract.currentStage === 'LC_CONTRACT_VALID') {
        this.saveFileContract(this.contractId, 'CONTRACT');
      } else {
        this.saveFileContract(this.contractId, 'DRAFT');
      }
    } else {
      var url = window.URL.createObjectURL(this.filePDF);
      var anchor = document.createElement("a");
      anchor.download = this.fileName;
      anchor.href = url;
      anchor.click();
    }
  }

  addPartyJoin() {
    this.showPartyJoin = true;
  }

  closedPartyJoinModal(e) {
    this.showPartyJoin = false;
  }

  callBackSetDataPartyJoin() {
    var party = new PartyJoinContract({
      partyId: this.partyInfo.partyId,
      tenToChuc: this.partyInfo.tenToChuc,
      userType: this.partyInfo.partyType,
      partyType: this.partyInfo.partyType,
      email: this.partyInfo.userInformations.email,
      username: this.partyInfo.userInformations.username,
      tenDaiDien: this.partyInfo.userInformations.ten,
      endDate: '',
      isTrustedPartner: 'Y',
      nguoiNhanData: [
        {
          cmnd: this.partyInfo.userInformations.cmnd || "",
          email: this.partyInfo.userInformations.email || "",
          isTrustedPartner: "Y",
          maSoThue: this.partyInfo.maSoThue,
          partyId: this.partyInfo.partyId,
          partyType: this.partyInfo.partyType,
          tenDaiDien: this.partyInfo.userInformations.ten,
          tenToChuc: this.partyInfo.tenToChuc,
          sdt: this.partyInfo.userInformations.sdt
        }
      ],
      dropListPartData: concat([
        {
          cmnd: this.partyInfo.userInformations.cmnd || "",
          email: this.partyInfo.userInformations.email || "",
          isTrustedPartner: "Y",
          maSoThue: this.partyInfo.maSoThue,
          partyId: this.partyInfo.partyId,
          partyType: this.partyInfo.partyType,
          tenDaiDien: this.partyInfo.userInformations.ten,
          tenToChuc: this.partyInfo.tenToChuc,
        }
      ]),
      sequence: 1,

    });
    party.allowDetete = false;
    party.allowEditUserType = false;
    party.allowEditPartyId = false;
    party.isOwner = true;

    console.log(party);

    this.partiesJoin.push(party);
  }

  /**
   * Set du lieu cho FormArray
   */
  callbackSetFormArray() {
    this.partiesFormArray = this.fb.array([]) as FormArray;
    var party = this.initFormNoValid();

    party.patchValue({
      partyId: this.partyInfo.partyId,
      tenToChuc: this.partyInfo.tenToChuc,
      userType: this.partyInfo.partyType,
      email: this.partyInfo.userInformations.email,
      username: this.partyInfo.userInformations.username,
      tenDaiDien: this.partyInfo.userInformations.ten,
      endDate: '',
      isTrustedPartner: 'Y',
      sequence: 1,
      nguoiNhanData: [
        {
          id: this.partyInfo.partyId,
          text: this.partyInfo.userInformations.email,
          additional: {
            partyId: this.partyInfo.partyId,
            type_customer: this.partyInfo.partyType === this.userTypeConst.CONSUMER ? this.translate.instant('individual') : this.translate.instant('organization'),
            type_cutomer_label: this.partyInfo.partyType,
            email: this.partyInfo.userInformations.email,
            isTrustedPartner: 'Y',
            sdt: this.partyInfo.userInformations.sdt
          }
        }
      ],
      allowDetete: false,
      allowEditUserType: false,
      allowEditPartyId: false,
      isOwner: true,
      sdt: this.partyInfo.userInformations.sdt
    })

    this.partiesFormArray.push(party);
  }

  /**
   * Init FormGroup
   */
  initForm(): FormGroup {
    return this.fb.group({
      partyId: ['', [Validators.required]],
      tenToChuc: [''],
      email: [''],
      userType: ['', [Validators.required]],
      verificationType: ['', [Validators.required]],
      signForm: ['', [Validators.required]],
      username: [''],
      tenDaiDien: [''],
      endDate: ['', [Validators.pattern(REGEXP_DATE_INPUT)]],
      isTrustedPartner: [false],
      allowDetete: [true],
      allowEdit: [true],
      allowEditUserType: [true],
      allowEditPartyId: [true],
      isOwner: [false],
      sequence: ['', [Validators.pattern(REGEXP_NUMBER)]],

      hinhThucKyData: [[]],
      nguoiNhanData: [[]],
      sdt: [""]
    })
  }

  /**
   * Init FormGroup
   */
  initFormNoValid(): FormGroup {
    return this.fb.group({
      partyId: [''],
      tenToChuc: [''],
      email: [''],
      userType: [''],
      verificationType: [''],
      signForm: [''],
      username: [''],
      tenDaiDien: [''],
      endDate: [''],
      isTrustedPartner: [false],
      allowDetete: [true],
      allowEdit: [true],
      allowEditUserType: [true],
      allowEditPartyId: [true],
      isOwner: [false],
      sequence: [''],

      hinhThucKyData: [[]],
      nguoiNhanData: [[]],
      sdt: [""]
    })
  }

  /**
   * Nhan event du lieu tu modal nhap thong tin cac ben
   * @param $event 
   */
  changePartyJoin($event) {
    // this.partiesJoin = $event || [];
    console.log($event);
    this.partiesFormArray = $event as FormArray;
    this.showPartyJoin = false;

    this.myAlert.showAlertOnlyNoti(this.translate.instant('ServiceContract_save_part_join_success'), ConstantAlertType.SUCCESS, "", this.translate.instant('btn_close'), () => {}, this.translate.instant('ServiceContract_update_success'));
  }

  // //MARK: Popup thông báo
  // alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
  //   const modalRef = this.modalService.open(AlertComponent);
  //   modalRef.componentInstance.message = msg;
  //   modalRef.componentInstance.typeAlert = type;
  //   modalRef.componentInstance.twoBtn = twoBtn;
  //   modalRef.componentInstance.iconBtn1 = iconBtn1;
  //   modalRef.componentInstance.iconBtn2 = iconBtn2;
  //   modalRef.componentInstance.titleButton1 = titleBtn1;
  //   modalRef.componentInstance.titleButton2 = titleBtn2;
  //   modalRef.componentInstance.callback1 = actBtn1;
  //   modalRef.componentInstance.callback2 = actBtn2;
  // }

  // //MARK: Handle Error
  // handleError(err, errorMsg) {
  //   if (err.error && err.error.message && errorMsg[err.error.message]) {
  //     this.alert(errorMsg[err.error.message], 'error');
  //   } else if (err.error && err.error.error) {
  //     this.alert(err.error.error, 'error');
  //   } else {
  //     this.alert('Lỗi không xác định!', 'error');
  //   }
  // }

  //MARK: NETWORKING
  postCreateDraftOnlyFile(data: FormData, andSend: boolean = false) {
    this.contractService.postCreateDraftOnlyFile(data).subscribe((res: any) => {
      if (andSend) {
        this.loaderSerive.setVisible(true);
        setTimeout(() => {
          this.postSubmitSendDraft(res.object.contractId);
        }, 2000);
      } else {
        if (this.formContract.value.flowTemplateId && this.contractNumberBox?.action == "CREATE") {
          this.checkContractNumberAction(res?.object?.contractId, (() => {
            this.myAlert.showAlert(this.translate.instant('ServiceContractDetail_saveContractSuccess'), 'success', false, '', 'OK', () => {
              this.router.navigate(['/app/console/contract', res['object'].contractId])
            });
          }).bind(this));
        } else {
          this.myAlert.showAlert(this.translate.instant('ServiceContractDetail_saveContractSuccess'), 'success', false, '', 'OK', () => {
            this.router.navigate(['/app/console/contract', res['object'].contractId])
          });
        }
      }
    }, err => {
      console.log(err);
      if (err.error && err.error.error
        && err.error.error instanceof Array
        && err.error.error.length > 0
        && err.error.error[0] === "Cannot verify signature contract") {
        this.myAlert.showAlertOnlyNoti(this.translate.instant('ServiceContract_ts_5'), 'error');
      } else {
        this.myAlert.showErrorHandled(err);
      }
    }, () => {

    });
  }

  //Convert docx to pdf
  postConvertToPDF(data: FormData) {
    this.contractService.postConvertToPdf(data).subscribe(fileData => {
      const blob: any = new Blob([fileData], { type: 'application/pdf' });
      PDFSign.preview(blob);
      this.filePDF = blob;

      this.getBase64String(blob, this.handle);
    }, err => {
      console.log(err);
      this.myAlert.showAlertOnlyNoti(this.translate.instant('ServiceContract_CONVERT_PDF_FAILED'), ConstantAlertType.ERROR);
      // this.handleError(err, ResponseMsg.MESSAGES.ESIGN_SERVICE);
    });
  }

  //Tim kiem khach hang
  searchCustomer(key) {
    this.contractService.searchCustomer(key).subscribe(res => {

    }, err => {

    })
  }

  //Lay thong tin hop dong
  getContract(id) {
    this.contractService.getContract(id).subscribe((res: any) => {
      this.contract = new ContractResponse(res).object;
      this.setDataV2();
      // this.downloadFileContract(id, 'DRAFT');
      // if (!this.checkAllowEdit()) {
      //   this.alert('Bạn không thể chỉnh sửa hợp đồng này!', 'error');
      // }

      // //Lấy lại data render cũ
      // this.contractFields = res.object?.contractFields;
      // this.oldcontractFields = [];
      // if (res.object?.contractFields && res.object?.contractFields instanceof Array) {
      //   res.object?.contractFields.forEach(item => {
      //     this.oldcontractFields.push(new TemplateVar(item));
      //   })
      // }

      // //Lấy thông tin tin data Render cũ (phương thức mới)
      // this.oldFieldConcated = res.object?.fieldConcated;
    }, err => {
      console.log(err);
      this.myAlert.showErrorHandled(err);
    })
  }

  //Tai file hop dong
  downloadFileContract(id, type) {
    this.contractService.downloadFileContract(id, type).subscribe(fileData => {
      const blob: any = new Blob([fileData], { type: 'application/pdf' });
      this.filePDF = blob;
      PDFSign.preview(blob);

      setTimeout(() => {
        if (this.contractNumberBox && this.contractNumberBox.box) {
          this.contractNumberBox.removeBox();
          this.contractNumberBox.addSignBox(this.contractNumberBox.box);
        }
      }, 500);
    }, err => {
      console.log(err);
      this.myAlert.showErrorHandled(err);
    })
  }

  //Cap nhat hop dong
  postUpdateContract(id, data, andSend: boolean = false) {
    this.contractService.postUpdateContract(id, data).subscribe(res => {
      if (andSend) {
        this.postSubmitSendDraft(id);
      } else {
        this.myAlert.showAlert(this.translate.instant('ServiceContractDetail_saveContractSuccess'), 'success', false, '', 'OK', () => {
          this.router.navigate(['/app/console/contract', id])
        });
      }
    }, err => {
      console.log(err);
      if (err.error && err.error.error
        && err.error.error instanceof Array
        && err.error.error.length > 0
        && err.error.error[0] === "Cannot verify signature contract") {
        this.myAlert.showAlertOnlyNoti(this.translate.instant('ServiceContract_ts_5'), 'error');
      } else {
        this.myAlert.showErrorHandled(err);
      }
    }, () => {

    });
  }

  //Tải file hợp đồng 
  saveFileContract(id, type) {
    this.contractService.downloadFileContract(id, type).subscribe((res) => {
      const blob: any = new Blob([res], { type: 'application/pdf' });
      var url = window.URL.createObjectURL(blob);
      var anchor = document.createElement("a");
      anchor.download = `${id}.pdf`;
      anchor.href = url;
      anchor.click();
    }, err => {
      console.log(err);
      this.myAlert.showErrorHandled(err);
    })
  }

  //convert html sang pdf
  postConvertHTMLToPdf(data, andSend: boolean = false) {
    this.contractService.postConvertHTMLToPdf(data).subscribe(res => {
      const blob: any = new Blob([res], { type: 'application/pdf' });
      this.filePDF = blob;
      this.getBase64String(blob, this.handle);
      this.saveContract(andSend);
    }, err => {
      console.log(err);
      this.myAlert.showErrorHandled(err);
    });
  }

  /**
   * Thong tin party dang login
   * @param partyId 
   */
  getPartyInfo(partyId) {
    this.aasAuth.getPartyInfo(partyId).subscribe((res: any) => {
      this.partyInfo = new InfoPartyObject(res.object || {});
      if (this.contractId == '') {
        this.callBackSetDataPartyJoin();
        this.callbackSetFormArray();
      }
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }

  /**
 * Submit gui ban nhap hop dong den cac ben
 * @param contractId 
 */
  postSubmitSendDraft(contractId) {
    this.contractService.postSubmitSendDraft(contractId).subscribe((res) => {
      this.myAlert.showAlert(this.translate.instant('ServiceContract_ts_6'), 'success', false, '', 'OK', () => {
        // this.getContract(contractId);
        this.router.navigate(['/app/console/contract', contractId]);
      });
      this.loaderSerive.setVisible(false);
    }, err => {
      console.log(err);
      this.loaderSerive.setVisible(false);
      this.myAlert.showErrorHandled(err);
    })
  }


  //MARK: Hop dong mau
  allVariables: Array<any> = [];

  paramRenders: Array<Object> = [new Object(), new Object()];
  paramRenderByStep: Object[] = [new Object(), new Object()];
  listConfig: Array<TemplateVar> = [];
  textBoxList: Array<ConfigItem> = [];
  tableList: string[] = [];
  tableObjectList: ListTable = {};
  checkBoxes: string[] = [];
  checkBoxList: ListCheckBox = {};

  tableListNoFilled: string[] = [];
  textBoxListNoFilled: Array<ConfigItem> = [];
  tableObjectListNoFilled: ListTable = {};
  checkBoxesNoFilled: string[] = [];
  checkBoxListNoFilled: ListCheckBox = {};

  contractFields: Array<TemplateVar> = [];
  templateFile: any = null;

  oldcontractFields = [];
  oldFieldConcated = {};

  templatedSelected: ContractTemplateItem = new ContractTemplateItem();
  showFillData = false;

  selectTemplate(e) {
    console.log(e);
    if (e) {
      this.templatedSelected = new ContractTemplateItem(e);
      this.getFieldsTemplate(this.templatedSelected.templateFields);
      this.getPreviewTemplateById(this.templatedSelected.id);

      if (this.contractId && this.templatedSelected.id == this.contract.templateId) {
        // Trường hợp chọn lại mẫu hđ mới khác với hiện tại => 1 phiên bản
        this.attachNewFile = true;
      }
    } else {
      this.templatedSelected = null;
    }
  }

  startFillData() {
    this.showFillData = true;
  }

  getFieldsTemplate(templateFields) {
    TemplateManager.shared.reset();
    this.templateService.getFieldsTemplate(templateFields).subscribe((res: any) => {
      this.allVariables = res.object;
      this.listConfig = [];
      if (res.object && res.object instanceof Array) {
        res.object.forEach(element => {
          this.listConfig.push(new TemplateVar(element));
        });
      }
      this.contractFields = this.listConfig;

      //Setup cho màn hình fill form mẫu hợp đồng
      this.setUpFillFormTemplate();
      this.fillContractNumber();
    }, err => {

    })
  }

  /**
   * Điền contractNumber cho hợp đồng
   */
  fillContractNumber() {
    //Fill tên số hợp đòng vào
    this.listConfig.filter((item) => {
      return item.nameMap === 'contractNumber';
    }).forEach(res => {
      this.paramRenders.forEach(paramRender => {
        paramRender[res.name] = this.formContract.value.contractNumber || '';
      });
    });
    console.log("fillContractNumber ==> ", this.cloneObject(this.paramRenders));
  }

  /**
   * Clone object JSON
   * @param from 
   */
  cloneObject(from) {
    return JSON.parse(JSON.stringify(from));
  }

  /**
   * Setup form điền dữ liệu còn thiếu
   */
  setUpFillFormTemplate() {
    console.log("handleFieldValue ==> ", this.listConfig);

    TemplateManager.shared.reset();
    TemplateManager.shared.configList = this.listConfig;
    TemplateManager.shared.getAllTypeList();

    this.textBoxList = TemplateManager.shared.textBoxList;
    // this.checkBoxList = TemplateManager.shared.checkBoxList;
    this.tableList = TemplateManager.shared.tableList;
    this.tableObjectList = TemplateManager.shared.tableObjectList;
    this.checkBoxes = TemplateManager.shared.checkBoxes;
    this.checkBoxList = TemplateManager.shared.checkBoxList;

    // console.log("textBoxList => ", this.textBoxList);
    // console.log("tableList => ", this.tableList);
    // console.log("tableObjectList => ", this.tableObjectList);

    this.textBoxListNoFilled = this.textBoxList;
    this.tableListNoFilled = this.tableList;
    this.tableObjectListNoFilled = this.tableObjectList;
    this.checkBoxesNoFilled = this.checkBoxes;
    this.checkBoxListNoFilled = this.checkBoxList;

    TemplateManager.shared.makeConfigParam();
    this.paramRenders[0] = this.cloneObject(TemplateManager.shared.configParam);
    this.paramRenders[1] = this.cloneObject(TemplateManager.shared.configParam);

    // console.log("Setup config param => ", this.cloneObject(this.paramRenders));

    //Làm đẹp lại data cũ
    Object.keys(this.oldFieldConcated).forEach((key) => {
      this.oldFieldConcated[key] === null ? this.oldFieldConcated[key] = "" : this.oldFieldConcated[key] = this.oldFieldConcated[key];
    });

    //Gán lại data cũ vào paramRender
    let paramRender = TemplateManager.shared.setData(this.oldFieldConcated);
    this.paramRenders[0] = this.cloneObject(paramRender);
    this.paramRenders[1] = this.cloneObject(paramRender);

    return;
  }

  /**
   * Lấy file mẫu hợp đồng
   * @param templateId 
   */
  getPreviewTemplateById(templateId) {
    this.templateService.getPreviewTemplateById(templateId).subscribe((fileData) => {
      this.templateFile = new Blob([fileData], { type: fileData.type });
      this.filePDF = this.templateFile;

      PDFSign.preview(this.templateFile);
    }, (err) => {
      console.log(err);
      this.myAlert.showErrorHandled(err)
    });
  }

  onActionNextButton(event: any) {
    console.log(event);
    this.showFillData = false;

    this.paramRenders[0] = this.cloneObject(event.paramRender);
    this.paramRenders[1] = this.cloneObject(event.paramRender);
    console.log("AFTER FILLED NEXT => ", this.paramRenders)

    let x = this.contractFields.find((item) => { return item.nameMap === "contractNumber" });
    if (x) {
      this.formContract.controls.contractNumber.setValue(this.paramRenders[1][x.name] || this.formContract.value.contractNumber || '');
    }

    this.renderTemplateById(this.paramRenders[1]);
  }

  onActionBackButton(e) {
    console.log(e);
    this.showFillData = false;
  }

  /**
   * Render Template Only
   * @param document 
   */
  renderTemplateById(paramRender = null) {
    console.log('renderTemplateById...');
    const param = TemplateManager.shared.makeParamRender(paramRender);
    let req: any;

    if (this.templatedSelected.id) {
      req = this.templateService.postRenderTemplate(this.templatedSelected.id, param);
      req.subscribe((fileData: Blob) => {
        this.attachNewFile = true;// Trường hợp render lại hđ tính là 1 phiên bản

        const blob: any = new Blob([fileData], { type: fileData.type })
        blob.lastModifiedDate = new Date();
        blob.name = `temp_${this.contractId || (new Date().getTime().toString())}.pdf`;
        console.log(`temp_${this.contractId || (new Date().getTime().toString())}.pdf`);

        this.filePDF = blob;
        PDFSign.preview(blob);

        this.getBase64String(blob, this.handle);
      }, (err) => {
        this.myAlert.showErrorHandled(err);
      });
    }
  }

  /**
   * Render Template Only
   * @param document 
   */
  renderContract(paramRender = null) {
    console.log('renderContract...');
    const param = TemplateManager.shared.makeParamRender(paramRender);
    let req: any = null;

    if (this.templatedSelected.id) {
      req = this.templateService.postRenderTemplate(this.templatedSelected.id, param).toPromise();
      return req;
    }
    return req;
  }

  /**
   * Khoi tao hop dong voi mau
   * @param data 
   * @param andSend 
   */
  postCreateContractWithTemplate(data: FormData, andSend: boolean = false) {
    this.contractService.postCreateContractWithTemplate(data).subscribe((res: any) => {
      this.myAlert.showAlert(this.translate.instant('ServiceContractDetail_saveContractSuccess'), 'success', false, '', 'OK', () => {
        this.router.navigate(['/app/console/contract', res['object'].contractId])
      });
    }, err => {
      console.log(err);
      if (err.error && err.error.error
        && err.error.error instanceof Array
        && err.error.error.length > 0
        && err.error.error[0] === "Cannot verify signature contract") {
        this.myAlert.showAlertOnlyNoti(this.translate.instant('ServiceContract_ts_5'), 'error');
      } else {
        this.myAlert.showErrorHandled(err);
      }
    }, () => {

    });
  }

  /**
   * Xu ly case tao va cap nhat hop dong co su dung template
   * @param data 
   * @param andSend 
   */
  createContractWithTemplate(data: FormData, andSend: boolean = false) {
    const contractFieldPostData = TemplateManager.shared.makeParamSubmit(this.paramRenders[1]);

    data.append('templateId', this.formContract.value.templateId);
    data.append('templateGroupId', "");
    data.append('templateGroupName', "");
    data.append('fields', JSON.stringify(contractFieldPostData || {}));

    let res = this.renderContract(TemplateManager.shared.makeParamSubmit(this.paramRenders[1]));
    res.then((fileData: any) => {
      const blob: any = new Blob([fileData], { type: fileData.type })
      blob.lastModifiedDate = new Date();
      blob.name = `temp_${this.templatedSelected.id || ""}_${(new Date().getTime().toString())}.pdf`;

      data.append('draftFile', blob, blob.name);
      if (this.contractId !== '') {
        if (this.checkAllowEdit()) {
          this.postUpdateContract(this.contractId, data, andSend);
        } else {
          this.myAlert.showAlertOnlyNoti(this.translate.instant('ServiceContract_access_denied_edit_this_contract'), 'error');
        }
      } else {
        this.postCreateDraftOnlyFile(data, false);
      }
    }).catch((err: any) => {
      console.log(err);
      this.myAlert.showErrorHandled(err);
    });
  }

  /**
   * Lay ds droplist hop dong mau
   * @param page 
   * @param maxSize 
   * @param keySearch 
   */
  getDropListTemplateV1(page, maxSize, keySearch) {
    this.templateService.getDropListTemplateV1(page, maxSize, keySearch).subscribe((res: any) => {
      this.contractTemplateData = res.object?.data || [];
    });
  }

  //CHON LUONG HOP DONG
  dropListFlow: Observable<any>;
  flowLoading = false;
  flowInput$ = new Subject<string>();
  flowContractSelected: FlowContract = null;
  flowPage = 1;
  endDataFlow = false;
  flowKeySearch = "";

  showFlowSetting = false;

  creationType = {
    '1': 'FILE',
    '0': 'TEMPLATE',
    '2': 'DIRECT'
  }

  changeDiscussType(e) {
    this.formContract.controls.flowTemplateId.setValue(null);
    this.flowContractSelected = null;
    this.lazyLoadFlow();
  }

  trackByFnFlow($event) {
    return $event;
  }

  changeFlow(event) {
    console.log("changed");
    this.contractNumberBox = null;
    this.sequenceId.setValue("");
    this.sequenceName.setValue("");

    if (event && event.contractFlowTemplateId) {
      this.getDetailFlowContract(event.contractFlowTemplateId);
    }
    else
      this.flowContractSelected = null;
  }

  lazyLoadFlow() {
    this.dropListFlow = concat(
      this.flowInput$.pipe(
        startWith(''),
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => { this.flowLoading = true; LoadingService.instance.disabledLoader(this.flowLoading); }),
        switchMap((term) => {
          this.flowPage = 1;
          this.endDataFlow = false;
          this.flowKeySearch = term;
          let discuss = this.formContract.value.discuss == null ? "" : this.formContract.value.discuss == true ? 1 : 0;
          let res = this.flowContractService.getDataDropListFlowContract(1, 20, "created", "DESC", 0, term || "", discuss).pipe(
            map((res: any) => {
              let param = null;
              if (this.flowContractSelected && this.flowContractSelected.contractFlowTemplateId) {
                param = {
                  contractFlowTemplateId: this.flowContractSelected.contractFlowTemplateId,
                  name: this.flowContractSelected.name
                }
              }
              return UtilsFunc.addToDropList(res?.object?.data, param, "contractFlowTemplateId");
            }),
            catchError(() => of([])), // empty list on error
            tap(() => { this.flowLoading = false; LoadingService.instance.disabledLoader(this.flowLoading); })
          );
          return res;
        })
      ));
  }

  /**
   * Tai them du lieu droplist Luong hop dong
   * @param page 
   */
  loadMoreFlow(page) {
    this.flowLoading = true; LoadingService.instance.disabledLoader(this.flowLoading);
    let discuss = this.formContract.value.discuss == null ? "" : this.formContract.value.discuss == true ? 1 : 0;
    this.flowContractService.getDataDropListFlowContract(page, 20, "created", "DESC", 0, this.flowKeySearch || "", discuss).subscribe((res: any) => {
      console.log(res);
      if (res?.object?.data.length <= 0) {
        this.endDataFlow = true;
      }

      this.dropListFlow.subscribe((value) => {
        this.dropListFlow = of(value.concat(res?.object?.data || []));
      });

      this.flowLoading = false; LoadingService.instance.disabledLoader(this.flowLoading);
    }, err => {
      this.flowLoading = false; LoadingService.instance.disabledLoader(this.flowLoading);
    })
  }

  /**
  * Su kien scroll xuong duoi cung cua droplist
  * @returns 
  */
  onScrollToEndDropListFlow() {
    if (this.endDataFlow) return;

    var nextPage = this.flowPage + 1;
    this.flowPage = nextPage;
    this.loadMoreFlow(this.flowPage);
  }

  /**
   * Gui yeu cau lay thong tin luong hop dong
   * @param id 
   */
  getDetailFlowContract(id: string) {
    this.flowContractService.getDetailFlowContract(id).subscribe((res: any) => {
      this.flowContractSelected = new FlowContract(res?.object || {});
      // Check luồng HĐ có tồn tại cấu hình số HĐ hay không
      if (this.flowContractSelected.sequenceId.length > 0) {
        /**
         * Nếu luồng hợp đồng có thông tin cấu hình số hợp đồng
         * Lấy thông tin chi tiết cấu hình số hợp đồng
         */
        this.sequenceId.setValue(this.flowContractSelected.sequenceId);
        this.getDetailContractNumberConfig(this.flowContractSelected.sequenceId);
      }
      else {
        /**
         * Nếu luồng hợp đồng không có thông tin cấu hình số hợp đồng
         * Lấy thông tin chi tiết cấu hình số hợp đồng mặc định
         */
        this.contractNumberAction = "";
        this.getDefaultContractNumberConfig();
      }
      // this.setDataFlow(this.flowContractSelected);
      if (this.typeSelected === 1) {
        this.formContract.controls.discuss.setValue(this.flowContractSelected.discuss || false)
      }

      let param = {
        contractFlowTemplateId: this.flowContractSelected.contractFlowTemplateId,
        name: this.flowContractSelected.name
      }
      this.dropListFlow.subscribe((data) => {
        this.dropListFlow = of(UtilsFunc.addToDropList(data, param, "contractFlowTemplateId"));
      });

    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }

  /**
   * Mo man hinh popup setting flow
   */
  settingFlow() {
    setTimeout(() => {
      this.showFlowSetting = true;
    }, 200)
  }

  /**
   * Bat su kien dong popup setting flow
   * @param event 
   */
  flowSettingsClosed(event) {
    setTimeout(() => {
      this.showFlowSetting = false;
    }, 200);

    if (event !== null) {
      this.flowContractSelected = event;
      this.formContract.controls.flowTemplateId.setValue(this.flowContractSelected.contractFlowTemplateId);

      if (this.typeSelected === 1) {
        this.formContract.controls.discuss.setValue(this.flowContractSelected.discuss || false)
      }

      let param = {
        contractFlowTemplateId: this.flowContractSelected.contractFlowTemplateId,
        name: this.flowContractSelected.name
      }
      this.dropListFlow.subscribe((data) => {
        this.dropListFlow = of([...data, param]);
      });
    }
  }

  /**
   * Tao param internalDiscuss
   * @param internalDiscuss 
   * @returns 
   */
  getInternalDiscussParam(internalDiscuss: InternalDiscuss[] = []) {
    let res = [];

    if (this.formContract.value.discuss == false && this.flowContractSelected?.internalDiscussType == InternalDiscussTypes.NO_REQUIRE) {
      return res;
    }

    internalDiscuss.forEach((item) => {
      let p = {
        "userId": item.userId || "",
        "departmentId": item.partyId || "",
        "permissions": item.permissions || []
      };
      if (this.formContract.value.discuss == false && this.flowContractSelected?.internalDiscussType == InternalDiscussTypes.REQUIRE_FLOW) {
        p['sequence'] = +item.sequence;
      } else {
        p['sequence'] = null;
      }
      res.push(p);
    })
    return res;
  }

  /**
   * Tao param partnerDiscuss
   * @param partnerDiscuss 
   * @returns 
   */
  getPartnerDiscussParam(partnerDiscuss: PartnerDiscuss[] = []) {
    let res = [];
    partnerDiscuss.forEach((item) => {
      res.push({
        "userId": item.userId || "",
        "departmentId": item.partyId || "",
        "permissions": item.permissions || []
      });
    })
    return res;
  }

  /**
   * Tao param signFlow
   * @param signFlow 
   * @returns 
   */
  getSignFlowParam(signFlow: SignFlow[] = []) {
    let res = [];
    signFlow.forEach((item) => {
      res.push({
        "signType": item.signType || "",
        "departmentId": item.partyId || "",
        "userId": item.userId || "",
        "sequence": +item.sequence,
        "limitDate": +item.limitDate,
        "signForm": item.signForm
      });
    })

    return res;
  }

  /**
   * Call Api save contract
   */
  saveContractV2(andSend: boolean = false) {

    if (this.partiesFormArray.value instanceof Array && this.partiesFormArray.value.length < 2) {
      this.myAlert.showAlertOnlyNoti(this.translate.instant('ServiceContract_contract_not_enough_partner'), ConstantAlertType.ERROR, "", this.translate.instant('btn_close'));
      return;
    }

    let data = new FormData();
    data.append('title', this.tenHopDong.value || '');
    data.append('validDate', DateUtils.convertInputToFormat(this.ngayHieuLuc.value, DateUtils.format.API) || "");
    data.append('endDate', DateUtils.convertInputToFormat(this.ngayHetHieuLuc.value, DateUtils.format.API) || "");
    data.append('contractValue', this.convertCurency(this.giaTriHd.value || '') || '');
    data.append('creationNote', this.ghiChu.value);
    data.append('autoRenew', this.tuGiaHan.value);
    data.append('discuss', this.formContract.value.discuss);
    data.append('creationType', this.creationType[this.typeSelected]);
    data.append('internalDiscuss', JSON.stringify(this.getInternalDiscussParam(this.flowContractSelected?.internalDiscuss || [])));
    data.append('partnerDiscuss', JSON.stringify(this.getPartnerDiscussParam(this.flowContractSelected?.partnerDiscuss || [])));
    data.append('flowTemplateId', this.formContract.value.flowTemplateId || "")
    this.flowContractSelected ? data.append('signFlowType', this.flowContractSelected.signFlowType || "") : null;
    data.append('internalDiscussType', this.flowContractSelected?.internalDiscussType || "");
    data.append('fixedPosition', this.fixedPosition);

    // data.append('sequenceId', "");
    // data.append('contractNumber', "");


    /** Tạo param signFlow */
    let signFlow = this.getSignFlowParam(this.flowContractSelected?.signFlow || []);
    for (var i = 0; i < signFlow.length; i++) {
      signFlow[i].signFrame = [];
      this.ownerSignBoxs[i].signBoxes.forEach((item: SignBox) => {
        signFlow[i].signFrame.push({
          x: item.x,
          y: item.y,
          w: item.w,
          h: item.h,
          page: item.page
        })
      })

      if (signFlow[i].signFrame.length <= 0) signFlow[i].signFrame = null;
    }
    data.append('signFlow', JSON.stringify(signFlow));

    if (this.formContract.value.flowTemplateId !== null && this.formContract.value.flowTemplateId != "") {
      let n = this.flowContractSelected.signFlow.length;
      for (let i = 0; i < n; i++) {
        let item = this.flowContractSelected.signFlow[i];
        console.log(item);
        item.checkValidate(true);
        if (item.inValid) {
          this.myAlert.showAlertOnlyNoti(this.translate.instant('ServiceContract_add_employee_into_signflow_please'), ConstantAlertType.ERROR);
          return;
        }
      }
    }

    var paritesParam = [];
    this.partiesFormArray.value.forEach((item) => {
      if (item.partyId !== this.partyInfo.partyId) {
        const partnerUser = this.partnerSignBox.find((x: any) => { return x.partyId == item.partyId });
        let signBoxs = [];

        if (partnerUser) {
          partnerUser.signBoxes.forEach((item: SignBox) => {
            signBoxs.push({
              x: item.x,
              y: item.y,
              w: item.w,
              h: item.h,
              page: item.page
            })
          })
        }

        paritesParam.push({
          customerIdOrEmail: item.partyId,
          customerType: item.userType,
          verificationType: item.verificationType,
          signForm: item.signForm,
          // endDate: DateUtils.convertInputToFormat(item.endDate, DateUtils.format.API),
          sequence: Number(item.sequence),
          signFrame: signBoxs
        })
      } else {
        const adminUser = this.ownerSignBoxs.find((x: any) => { return x.userId == this.authService.partyId });
        let signBoxs = [];
        if (adminUser) {
          adminUser.signBoxes.forEach((item: SignBox) => {
            signBoxs.push({
              x: item.x,
              y: item.y,
              w: item.w,
              h: item.h,
              page: item.page
            })
          })
        }
        paritesParam.push({
          customerIdOrEmail: item.partyId,
          customerType: this.partyInfo.partyType,
          sequence: Number(item.sequence),
          signFrame: signBoxs
        })
      }
    })

    data.append('partys', JSON.stringify(paritesParam));
    if (this.configContractNumber){
      this.contractNumberBox.updateSignBox();
      data.append('contractNumberData', JSON.stringify({
        contractNumber: this.sequenceName.value ,
        sequenceId: this.sequenceId.value,
        contractNumberLocation: this.contractNumberBox.box ?? {}
      }))
    }

    data.append('contractTypeId', this.typeId.value || '');

    if (this.typeSelected === 0 && this.templatedSelected) {
      this.createContractWithTemplate(data, andSend);
    } else {
      data.append('draftFile', this.filePDF, `${this.filePDF.name}.pdf`);
      data.append('templateId', "");
      data.append('templateGroupId', "");
      data.append('templateGroupName', "");
      data.append('fields', "{}");

      if (this.contractId !== '' && this.contractId != null && this.contractId != undefined) {
        if (this.checkAllowEdit()) {
          if (this.attachNewFile == false) data.delete("draftFile");
          this.postUpdateContract(this.contractId, data, andSend);
        } else {
          this.myAlert.showAlertOnlyNoti(this.translate.instant('ServiceContract_access_denied_edit_this_contract'), ConstantAlertType.ERROR);
        }
      } else {
        this.postCreateDraftOnlyFile(data, andSend);
      }
    }
  }

  /**
   * Gan du lieu hop dong cu
   */
  setDataV2() {
    switch (this.contract.creationType) {
      case "TEMPLATE": this.selectType(0); break;
      case "FILE": this.selectType(1); break;
      case "DIRECT": this.selectType(2); break;
    }

    this.tenHopDong.setValue(this.contract.title);
    if (this.contract.sequenceId != null && this.contract.sequenceId.length > 0) {
      this.configContractNumber = true
      this.sequenceId.setValue(this.contract.sequenceId)
      this.sequenceName.setValue(this.contract.contractNumber)

      this.getDetailContractNumberConfig(this.contract.sequenceId);
    }

    this.ngayHieuLuc.setValue(DateUtils.convert(this.contract.validDate, DateUtils.format.INPUT));
    this.ngayHetHieuLuc.setValue(DateUtils.convert(this.contract.endDate, DateUtils.format.INPUT))
    this.giaTriHd.setValue(this.contract.contractValue);
    this.ghiChu.setValue(this.contract.creationNote);
    this.tuGiaHan.setValue(this.contract.autoRenew);
    this.formContract.controls.flowTemplateId.setValue(this.contract.contractFlowTemplateId || null);
    this.formContract.controls.discuss.setValue(this.contract.discuss);
    this.fixedPosition = this.contract.fixedPosition;

    if (this.contract.templateId !== "") {
      this.formContract.addControl("templateId", this.fb.control(null, [Validators.required]));
      this.formContract.controls.templateId.setValue(this.contract.templateId);

      this.selectType(0);
    }

    this.inputFileLabel = `${this.contractId}.pdf`;

    this.contract.contractPartysData.forEach((item) => {
      var party: FormGroup;
      var isTrustedPartner = '';

      if (item.partyId === this.authService.partyId) {
        party = this.initFormNoValid();
        isTrustedPartner = 'Y';
        party.patchValue({
          allowDetete: false,
          allowEditUserType: false,
          allowEditPartyId: false,
          isOwner: true
        });
      } else {
        party = this.initForm();
        isTrustedPartner = item.isTrustedPartner;
      }

      party.patchValue({
        partyId: item.partyId,
        tenToChuc: item.tenToChuc,
        userType: item.userType,
        email: item.email,
        tenDaiDien: item.tenDaiDien,
        endDate: DateUtils.convert(item.endDate, DateUtils.format.INPUT),
        signForm: item.signForm,
        isTrustedPartner: isTrustedPartner,
        verificationType: item.verificationType,
        sequence: item.sequence,
        hinhThucKyData: (item.partyType === this.userTypeConst.CONSUMER || item.userType === this.userTypeConst.CONSUMER) ? this.hinhThucKy_consumer : this.hinhThucKy_business,
        nguoiNhanData: [
          {
            id: item.partyId,
            text: item.email,
            additional: {
              partyId: item.partyId,
              type_customer: item.partyType === this.userTypeConst.CONSUMER ? this.translate.instant('individual') : this.translate.instant('organization'),
              type_cutomer_label: item.userType,
              email: item.email,
              isTrustedPartner: isTrustedPartner,
              sdt: item.sdt
            }
          }
        ],
        sdt: item.sdt
      })

      this.partiesFormArray.push(party);
    });

    if (!this.checkAllowEdit()) {
      this.myAlert.showAlertOnlyNoti(this.translate.instant('ServiceContract_access_denied_edit_this_contract'), ConstantAlertType.ERROR);
    }

    //Lấy thông tin tin data Render cũ (phương thức mới)
    this.oldFieldConcated = this.contract.fieldConcated;

    //Lấy lại data render cũ
    this.contractFields = this.contract.contractFields;
    this.oldcontractFields = [];
    if (this.contract.contractFields && this.contract.contractFields instanceof Array) {
      this.contract.contractFields.forEach(item => {
        this.oldcontractFields.push(new TemplateVar(item));
      })
    }

    this.downloadFileContract(this.contractId, 'DRAFT');
    this.contract.contractFlowTemplateId && this.getOldFlowContract(this.contract.contractFlowTemplateId);
    this.contract.creationType === "TEMPLATE" && this.contract.templateId && this.getDetailTemplate(this.contract.templateId);
  }

  getOldFlowContract(id) {
    this.flowContractService.getDetailFlowContract(id).subscribe((res: any) => {
      this.flowContractSelected = new FlowContract(res?.object || {});
      if (this.typeSelected === 1) {
        this.formContract.controls.discuss.setValue(this.flowContractSelected.discuss || false)
      }

      let param = {
        contractFlowTemplateId: this.flowContractSelected.contractFlowTemplateId,
        name: this.flowContractSelected.name
      }
      this.dropListFlow.subscribe((data) => {
        this.dropListFlow = of([...data, param]);
      });

      this.flowContractSelected.internalDiscuss = this.contract.internalDiscuss.concat([]);
      this.flowContractSelected.partnerDiscuss = this.contract.partnerDiscuss.concat([]);
      this.flowContractSelected.signFlow = this.contract.signFlow.concat([]);

      this.flowContractSelected.signFlow = this.flowContractSelected.signFlow.sort((a, b) => { return a.sequence - b.sequence; });
      this.flowContractSelected.internalDiscussType = this.contract.internalDiscussType || "";

      console.log(this.contract);
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }

  /**
   * Lay thong tin chi tiet hop dong mau
   * @param id 
   */
  getDetailTemplate(id: string) {
    this.templateService.getDetailTemplate(id).subscribe((res: any) => {
      let template = this.templatedSelected = new ContractTemplateItem(res?.object);
      this.getFieldsTemplate(template.templateFields);

      //Gan du lieu template cu vao droplist
      let param = {
        id: id,
        templateName: template.templateName
      }
      this.contractTemplateData = this.contractTemplateData.concat([param])
    }, err => {
      console.log(err);
    })
  }

  /**
   * Dieu kien edit luong hop dong
   */
  get allowEditFlowInfo() {
    if (this.contractId == "" || this.contractId == null || this.contractId == undefined) return true;

    if (this.contract.currentStage == "LC_DRAFT_CREATE" && this.contract.amId == this.authService.userId) return true;

    return false;
  }


  //MARK: TINH NANG THEM VI TRI KY
  /** Quản lý các bước trong form tạo hợp đồng */
  stepControl: Array<any> = [
    {
      active: true,
      done: false,
      disabled: false
    },
    {
      active: true,
      done: false,
      disabled: true
    }
  ];
  /** Lưu vét step by step trên form */
  currentStepCreate = 0;
  ownerSignBoxs: UserSignBox[] = [];
  partnerSignBox: UserSignBox[] = [];

  /**
   * Nhấn nút tiếp theo sau khi nhập đầy đủ thông tin
   */
  actNext() {
    this.isSubmitContract = true;
    if (this.disabledNext == false) {
      this.stepControl[this.currentStepCreate].done = true;
      this.currentStepCreate = 1;
      PDFSign.removeAllSignBox();
      this.makeSignBoxData();

      if (this.typeSelected !== 2) {
        this.getPositionSignature(this.filePDF);
      }
    }
  }

  /**
   * Khởi tạo dữ liệu cho màn hình thêm vị trí chữ ký
   */
  makeSignBoxData() {
    /** Gán dữ liệu mặc định */
    this.ownerSignBoxs = [];
    this.partnerSignBox = [];

    this.partiesFormArray.value.forEach((item) => {
      if (item.partyId !== this.authService.partyId) {
        /** Nếu không phải lại bên tạo hợp đồng */
        let signBoxes: SignBox[] = [];
        if (this.contractId) {
          // Nếu đang edit hợp đồng
          let party = this.contract.contractPartysData.find((item2) => { return item2.partyId == item.partyId });
          if (party && party.signFrame) {
            party.signFrame.forEach((x) => {
              signBoxes.push(new SignBox({ ...x, id: UtilsFunc.uuidv4() }));
            })
          }
        }
        this.partnerSignBox.push(new UserSignBox({
          partyId: item.partyId,
          userId: item.partyId,
          email: item.email || "",
          partyName: item.tenToChuc || "",
          fullName: item.tenDaiDien || "",
          signBoxes: signBoxes,
          sequence: item.sequence,
          sdt: item.sdt
        }))
      }
    });

    if (this.formContract.value.flowTemplateId) {
      this.flowContractSelected.signFlow.forEach((x: SignFlow, index) => {
        let signBoxes: SignBox[] = [];
        if (this.contractId) {
          // Nếu đang edit hợp đồng
          if (this.contract.signFlow.length > index) {
            // Nếu index nằm trong độ lớn nôi dung signFlow
            if (this.contract.signFlow[index].userId == x.userId) {
              // Nếu cùng index và cùng user
              this.contract.signFlow[index].signFrame.forEach((y) => {
                signBoxes.push(new SignBox({ ...y, id: UtilsFunc.uuidv4() }));
              })
            }
          }
        }
        this.ownerSignBoxs.push(new UserSignBox({
          partyId: x.partyId,
          userId: x.userId,
          email: x.email || "",
          partyName: x.partyName || "",
          fullName: x.fullName || "",
          signBoxes: signBoxes,
        }))
      });
    } else {
      let signBoxes: SignBox[] = [];
      if (this.contractId) {
        // Nếu đang edit hợp đồng
        let party = this.contract.contractPartysData.find((item) => { return item.partyId == this.authService.partyId });
        if (party && party.signFrame) {
          party.signFrame.forEach((x) => {
            signBoxes.push(new SignBox({ ...x, id: UtilsFunc.uuidv4() }));
          })
        }
      }
      this.ownerSignBoxs.push(new UserSignBox({
        partyId: this.partyInfo.partyId,
        userId: this.partyInfo.partyId,
        email: this.partyInfo.email || this.partyInfo.userInformations.email || "",
        partyName: this.partyInfo.tenToChuc || "",
        fullName: this.partyInfo.userInformations.ten || "",
        signBoxes: signBoxes,
        sdt: this.partyInfo.sdt || this.partyInfo.userInformations.sdt || ""
      }))
    }

    console.log(this.ownerSignBoxs);
    console.log(this.partnerSignBox);
  }

  /**
   * Nhấn nút quay lại khi cần trở về bước trước để nhập liệu
   */
  actBack() {
    this.isSubmitContract = false;
    this.currentStepCreate = 0;
    this.stepControl[this.currentStepCreate].done = false;
    PDFSign.removeAllSignBox();
    if (this.contractNumberBox != null) {
      this.contractNumberBox.showBox();
    }
  }

  /**
   * Trả ra trạng thái cho phép tiếp tục hay không
   */
  get disabledNext() {
    switch (this.typeSelected) {
      case 0:
        if (this.formContract.valid && this.filePDF !== null && this.filePDF !== undefined) {
          return false;
        } else {
          return true;
        }
      case 1:
        if (this.filePDF == null || this.filePDF == undefined) {
          this.formContract.get('file').setErrors({ required: true });
        }
        if (this.formContract.valid && this.filePDF !== null && this.filePDF !== undefined) {
          return false;
        } else {
          return true;
        }
      case 2:
        if (this.formContract.valid) {
          return false;
        } else {
          return true;
        }
    }

    return true;
  }

  /**
   * Thêm vị trí chữ ký
   */
  addTemplateSignBox(userSignBox: UserSignBox) {
    /** Ẩn các chữ ký của người khác */
    this.ownerSignBoxs.forEach((user: UserSignBox) => {
      user.hideListSignBox();
    });
    this.partnerSignBox.forEach((user: UserSignBox) => {
      user.hideListSignBox();
    });

    /** Hiển thị danh sách chữ ký */
    userSignBox.showListSignBox();
    /** Thêm chữ ký mới */
    userSignBox.addSignBox();
  }

  /**
   * Ẩn các vị trí ký
   */
  hideTemplateSignBox(userSignBox: UserSignBox) {
    userSignBox.hideListSignBox();
  }

  /**
   * Hiển thị các vị trí ký
   */
  showTemplateSignBox(userSignBox: UserSignBox) {
    /** Ẩn các chữ ký của người khác */
    this.ownerSignBoxs.forEach((user: UserSignBox) => {
      user.hideListSignBox();
    });
    this.partnerSignBox.forEach((user: UserSignBox) => {
      user.hideListSignBox();
    });
    /** Hiển thị danh sách chữ ký của hàng được chọn */
    userSignBox.showListSignBox();
  }

  /**
   * Cuộn đến vị trí chữ ký cần xem
   * @param signBox 
   */
  viewPositionSignBox(signBox: SignBox) {
    var e = document.getElementById("dragSignature_" + signBox.id);
    e.scrollIntoView();
    e.classList.add('position-signbox');
    setTimeout(() => {
      e.classList.remove('position-signbox');
    }, 1400)
  }

  /**
   * Bổ sung thêm tính năng cho phần định vị vị trí ký
   * Nếu sử dụng mẫu, hoặc file hợp đồng có các @{1} @{2}
   * Hệ thống tự động lấy thông tin vị trí và gán trực tiếp vào thông tin vị trí ký của người tham gia
   * Người dùng không cần phải sử dụng thao tác thêm bằng tay
   */

  getPositionSignature(file) {
    let data = new FormData();
    data.append("attachFile", file);
    this.signatureApi.getListPosition(data).subscribe((res: any) => {
      console.log(res);
      this.progressListPossition(res?.object?.result)
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }

  /**
   * Xử lý dữ liệu vị trí @{x} trả về
   * Gắn dữ liệu vị trí @{x} với các thành phần tham gia ký hợp đồng
   * @param listPosition 
   */
  progressListPossition(listPosition: any) {
    this.partiesFormArray.value.forEach((item) => {
      if (item.partyId == this.authService.partyId) {
        /** Nếu là bên tạo hợp đồng */
        console.log(item.partyId, item.sequence);
        let position = listPosition.find((x) => { return x.key == `@{${item.sequence}}`});
        if (position) {
          position.position.forEach((y) => {
            this.ownerSignBoxs.forEach((yy) => {
              let pos = {
                x: Math.floor(y?.bboxSign[0]),
                y: Math.floor(y?.bboxSign[3]),
                w: Math.floor(y?.bboxSign[2] - y?.bboxSign[0]),
                h: Math.floor(y?.bboxSign[3] - y?.bboxSign[1]),
                page: y?.pageSign + 1
              }
              yy.addSignBox(pos);
            })
          })
        }
      } else {
        /** Nếu là bên nhận hợp đồng */
        let partner = this.partnerSignBox.find((x) => { return x.sequence == item.sequence; });
        let position = listPosition.find((x) => { return x.key == `@{${item.sequence}}`});
        if (partner && position) {
          position.position.forEach((y) => {
            let pos = {
              x: Math.floor(y?.bboxSign[0]),
              y: Math.floor(y?.bboxSign[3]),
              w: Math.floor(y?.bboxSign[2] - y?.bboxSign[0]),
              h: Math.floor(y?.bboxSign[3] - y?.bboxSign[1]),
              page: y?.pageSign + 1
            }
            partner.addSignBox(pos);
          })
        }
      }
    });
  }

  //huong dan su dung
  startGuideTour(){
    this.hdsd.recieveData(this.steps)
    // let element = document.querySelector<HTMLElement>(
    //   `[data-guide=contract-partner]`
    // );
    // element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
  }

  /* ---------------------------------------- */

  // MARK: SO HOP DONG TU DONG
  /**
   * Lấy thông tin chi tiết cấu hình số hợp đồng
   * @param contractNumberId 
   */
   getDetailContractNumberConfig(contractNumberId) {
    this.cncService.getDetails(contractNumberId).subscribe((res: any) => {
      let box = { page: 1, w: 200, x: 10, y: 831, h: 24 };// Gắn toạ độ mặc định

      this.sequenceId.setValue(contractNumberId);
      this.formContract.controls.sequenceName.setValue(res?.object?.name ?? "");

      if (this.contractId && this.contract.contractNumberLocation) {
        // Lấy thông tin toạ độ cũ nếu đang edit hợp đồng và tồn tại toạ độ đã lưu trước đó
        console.log("Lay thong tin toa do tu chi tiet hop dong")
        box = new SignBox(this.contract.contractNumberLocation);
      }

      this.contractNumberBox = new ContractNumberBox(res?.object);
      this.contractNumberBox.box = new SignBox(box);
      this.contractNumberBox.removeBox();
      this.contractNumberBox.addSignBox(this.contractNumberBox.box);

      if (this.contractNumberBox.action == "CREATE") {
        // Nếu cấu hình là gắn số hợp đồng lúc tạo hđ thì gen ra số hợp đồng mới
        this.getNextContractNumber();
      }

      this.configContractNumber = true;
    }, err => {
      console.log(err);
    })
  }

  /**
   * Lấy thông tin cấu hình số hợp đồng mặc định nếu có
   */
  getDefaultContractNumberConfig() {
    this.cncService.getDefaultSequence().subscribe((res: any) => {
      if (res && res?.object && typeof (res?.object?.id) === 'string' && res?.object?.id?.length > 0) {
        let box = { page: 1, w: 200, x: 10, y: 831, h: 24 };

        this.sequenceId.setValue(res.object.id);
        this.formContract.controls.sequenceName.setValue(res?.object?.name ?? "");
        this.contractNumberBox = new ContractNumberBox({ ...res?.object });
        this.contractNumberBox.box = new SignBox(box);
        this.contractNumberBox.removeBox();
        this.contractNumberBox.addSignBox(this.contractNumberBox.box);

        if (this.contractNumberBox.action == "CREATE") {
          // Nếu cấu hình là gắn số hợp đồng lúc tạo hđ thì gen ra số hợp đồng mới
          this.getNextContractNumber();
        }

        this.configContractNumber = true;
      }
    }, err => {

    });
  }

  // Lấy số hợp đồng tiếp theo
  getNextContractNumber() {
    if (this.sequenceId.value && typeof (this.sequenceId.value) === 'string' && this.sequenceId.value.length > 0) {
      if (this.contractNumberBox?.action == "CREATE") {
        this.cncService.getNextCodeBySequenceId(this.sequenceId.value).subscribe((res: any) => {
          this.formContract.controls.sequenceName.setValue(res?.object ?? "");
          this.changeText();
        });
      }
    }
  }

  // Thay đổi số hợp đồng hiển thị trên khung mô phỏng
  changeText() {
    this.contractNumberBox.changeText(this.formContract.controls.sequenceName.value);
  }

  /**
   * Gắn số hợp đồng vào file PDF
   * @param callback Hàm gọi lại sau khi đã thực hiện thành công
   */
  addContractNumberIntoPDF(contractId, callback = null) {
    this.contractNumberBox.updateSignBox();

    let boxData = new FormData();
    boxData.append('contractNumber', this.contractNumberBox.text);
    boxData.append('multiSign', JSON.stringify(this.contractNumberBox.getBoxValue()));
    boxData.append('contractId', contractId);
    boxData.append('documentType', "DRAFT");

    this.cncService.addContractNumberBox(boxData).subscribe((res) => {
      if (callback) {
        callback(res)
      }
    }, err => {

    });
  }

  /**
   * submit file đã gắn số hợp đồng
   * @param contractNumber Giá trị số hợp đồng đã được gen
   * @param file File hợp đồng đã được gắn số hợp đồng
   * @param callback Hàm gọi lại khi hoàn thành request
   */
  submitFileAddedContractNumber(contractId, contractNumber, file, callback = null) {
    let data = new FormData();
    data.append('contractNumber', contractNumber);
    data.append('file', file);

    this.cncService.submitFileAddedContractNumber(data, contractId).subscribe((res: any) => {
      if (callback) {
        callback();
      }
    }, err => {
      console.log(err);
      this.myAlert.showErrorHandled(err);
    })
  }

  /** 
   * Kiểm tra trạng thái bước thực hiện gắn số hợp đồng để xử lý
   * @param callback Hàm gọi lại sau khi đã hoàn thành việc gắn số hợp đồng
   */
  checkContractNumberAction(contractId, callback = null) {
    this.contractNumberAction = this.contractNumberBox.action || "";
    if (this.contractNumberAction == "CREATE"){
      this.addContractNumberIntoPDF(contractId, (res: any) => {
        const blob: any = new Blob([res]);

        this.submitFileAddedContractNumber(contractId, this.contractNumberBox.text, blob, callback);
      })
    }
  }

  /**
   * Event scroll to end contract type droplist
   * @returns 
   */
  onScrollToEnd() {
    var nextPage = this.dropListTypePage + 1;
    if (this.endOfDropListTypePage) return;
    this.dropListTypePage = nextPage;
    this.getTypeList(nextPage, 10);
  }

  /**
   * Get list of types 
   * @param page 
   * @param maxSize 
   * @param propertiesSort 
   * @param sort 
   * @param status 
   */
  getTypeList(page, maxSize, propertiesSort = "modified", sort = "DESC", status = "") {
    this.typesLoading = true;
    this.contractTypeService.getListContractTypes(page, maxSize, propertiesSort, sort, status, this.keySearchDropListType).subscribe((res: any) => {
      this.dropListTypeData.pipe(tap((data: any) => {
        return [...data, (res?.object?.data || [])];
      }))
      this.typesLoading = false;
    }, err => {
      this.typesLoading = false;
    });
  }

  lazyLoadDropListType() {
    this.dropListTypeData = concat(
      this.typeInput$.pipe(
        startWith(''),
        debounceTime(400),
        distinctUntilChanged(),
        tap(() => { this.typesLoading = true; this.loaderSerive.disabledLoader(this.typesLoading); }),
        switchMap((term) => {
          this.keySearchDropListType = term || "";
          this.dropListTypePage = 1;
          this.endOfDropListTypePage = false;
          let res = this.contractTypeService.getListContractTypes(this.dropListTypePage, -1, "", "DESC", "", term || "").pipe(
            map((res: any) => {
              if (res?.object?.data == null) this.endOfDropListTypePage = true;
              return res?.object?.data.filter((x) => {
                return x.status == 'Y'
              }) || [];
            }),
            catchError(() => of([])), // empty list on error
            tap(() => { this.typesLoading = false; this.loaderSerive.disabledLoader(this.typesLoading); })
          );
          return res;
        })
      ));
  }

  trackByFn1(item: any) {
    return item;
  }

  changeType($event) {
  }
}
