<app-spinner></app-spinner>
<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-auto">
                <h1 class="m-0 text-dark"><label>{{ localize.list_request_service }}</label></h1>
            </div>
        </div>
    </div>
</div>

<section class="content">
    <div class="container-fluid">
        <div class="tab-content contract-tab-body" id="tabSignAction">
            <!--MARK Hợp đồng đã gửi -->
            <div class="bg-white">
                <section class="p-3">
                    <div class="row">
                        <div class="col-lg-3 col-md-5">
                            <form [formGroup]="formSearch" (ngSubmit)="actSearch(formSearch.value.search)"
                                class="input-group border rounded mb-3">
                                <div class="input-group-prepend border-0">
                                    <button class="btn d-inline-flex pr-2 pl-2" type="submit"><span
                                            class="material-icons">search</span></button>
                                </div>
                                <input appTrimValue type="text" class="form-control border-0"
                                    formControlName="search" placeholder="Tìm kiếm..." aria-label=""
                                    aria-describedby="basic-addon1">
                            </form>
                        </div>
                        <div class="col-md-auto ml-auto">
                            <div class="btn-group">
                                <div ngbDropdown container="body" [autoClose]="'outside'" [placement]="'bottom-right'" [display]="'dynamic'">
                                    <button ngbDropdownToggle class="btn btn-outline-primary dropdown-toggle d-inline-flex align-items-center mr-3">
                                        <span class="material-icons mr-1">visibility</span>Hiển thị
                                    </button>
                                    <div ngbDropdownMenu>
                                        <div ngbDropdownItem *ngFor="let item of listConfigColTable" class="p-0">
                                            <div  class="tcheck-primary px-3">
                                                <input type="checkbox" id="{{ item.name }}" [checked]="configColTable[item.name]">
                                                <label for="{{ item.name }}" class="w-100" [class.checked]="configColTable[item.name]"
                                                    (click)="actConfigColTableView($event, item.name)">
                                                    {{ item.label }}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="my-2">
                </section>
                <section class="px-3 my-2">
                    <form [formGroup]="formFilter">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="">Từ ngày</label>
                                    <div class="input-group">
                                        <input #fromDatePicker class="form-control" [control]="formFilter.controls.fromDate"
                                            [value]="formFilter.value.fromDate"
                                            (dateSelect)="changeDateFilter($event, dateType.CREATED_FROM)"
                                            placeholder="dd/MM/yyyy" ngbDatepicker #createdFromDate="ngbDatepicker"
                                            [maxDate]="{year: currentDate.getFullYear(), month: currentDate.getMonth()+1, day: currentDate.getDate()}"
                                            appInputMark [options]="optionsCleave">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary" (click)="createdFromDate.toggle()"
                                                type="button"><em class="fas fa-calendar-alt"></em></button>
                                        </div>
                                        <app-show-validate-errors [useSubmit]="false"
                                            [errorMessages]="validateSearch.fromDate"
                                            [control]="formFilter.controls.fromDate"
                                            [detail]="{ name: 'fromDate' }">
                                        </app-show-validate-errors>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="">Đến ngày</label>
                                    <div class="input-group">
                                        <input #toDatePicker class="form-control" [control]="formFilter.controls.toDate"
                                            [value]="formFilter.value.toDate"
                                            (dateSelect)="changeDateFilter($event, dateType.CREATED_TO)"
                                            placeholder="dd/MM/yyyy" ngbDatepicker #createdToDate="ngbDatepicker"
                                            [maxDate]="{year: currentDate.getFullYear(), month: currentDate.getMonth()+1, day: currentDate.getDate()}"
                                            appInputMark [options]="optionsCleave">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary" (click)="createdToDate.toggle()"
                                                type="button"><em class="fas fa-calendar-alt"></em></button>
                                        </div>
                                        <app-show-validate-errors [useSubmit]="false"
                                            [errorMessages]="validateSearch.toDate"
                                            [control]="formFilter.controls.toDate"
                                            [detail]="{ name: 'toDate' }">
                                        </app-show-validate-errors>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="status">Trạng thái</label>
                                    <ng-select
                                        [items]="statusList"
                                        bindLabel="name"
                                        bindValue="id"
                                        formControlName="status"
                                        [clearable]="false">
                                    </ng-select>
                                </div>
                            </div>
                            <div class="align-self-end mb-3 ml-auto">
                                <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="cleanFilter()"> Đặt lại</button>
                                <button class="btn btn-primary d-inline-flex" (click)="searchWithFilter()"> Áp dụng</button>
                            </div>
                        </div>
                    </form>
                </section>
               
                <section class="card-body p-0">
                    <div class="table-responsive">
                        <table class="table table-sort-col mb-0">
                            <thead>
                                <tr class="bg-tb-header">
                                    <th>{{ localize.index }}</th>
                                    <th [class.d-none]="!configColTable.tenDoanhNghiep" (click)="sortCol('title')">
                                        <span class="d-flex align-self-center align-items-center">
                                            {{ localize.company_name }}
                                            <!-- <span *ngIf="this.sortColConfig['title'] == null" class="material-icons ml-auto">
                                                unfold_more
                                            </span>
                                            <span *ngIf="this.sortColConfig['title'] == true" class="material-icons ml-auto">
                                                expand_less
                                            </span>
                                            <span *ngIf="this.sortColConfig['title'] == false" class="material-icons ml-auto">
                                                expand_more
                                            </span> -->
                                        </span>
                                    </th>
                                    <th [class.d-none]="!configColTable.maSoThue" (click)="sortCol('title')">
                                        <span class="d-flex align-self-center align-items-center">
                                            {{ localize.tax_number }}
                                            <!-- <span *ngIf="this.sortColConfig['title'] == null" class="material-icons ml-auto">
                                                unfold_more
                                            </span>
                                            <span *ngIf="this.sortColConfig['title'] == true" class="material-icons ml-auto">
                                                expand_less
                                            </span>
                                            <span *ngIf="this.sortColConfig['title'] == false" class="material-icons ml-auto">
                                                expand_more
                                            </span> -->
                                        </span>
                                    </th>
                                    <th [class.d-none]="!configColTable.nguoiDaiDien" (click)="sortCol('title')">
                                        <span class="d-flex align-self-center align-items-center">
                                            {{ localize.representative }}
                                            <!-- <span *ngIf="this.sortColConfig['title'] == null" class="material-icons ml-auto">
                                                unfold_more
                                            </span>
                                            <span *ngIf="this.sortColConfig['title'] == true" class="material-icons ml-auto">
                                                expand_less
                                            </span>
                                            <span *ngIf="this.sortColConfig['title'] == false" class="material-icons ml-auto">
                                                expand_more
                                            </span> -->
                                        </span>
                                    </th>
                                    <th [class.d-none]="!configColTable.ngayTiepNhan" (click)="sortCol('title')">
                                        <span class="d-flex align-self-center align-items-center">
                                            {{ localize.recieve_date }}
                                            <!-- <span *ngIf="this.sortColConfig['title'] == null" class="material-icons ml-auto">
                                                unfold_more
                                            </span>
                                            <span *ngIf="this.sortColConfig['title'] == true" class="material-icons ml-auto">
                                                expand_less
                                            </span>
                                            <span *ngIf="this.sortColConfig['title'] == false" class="material-icons ml-auto">
                                                expand_more
                                            </span> -->
                                        </span>
                                    </th>
                                    <th [class.d-none]="!configColTable.nguoiXuLy" (click)="sortCol('title')">
                                        <span class="d-flex align-self-center align-items-center">
                                            {{ localize.handler }}
                                            <!-- <span *ngIf="this.sortColConfig['title'] == null" class="material-icons ml-auto">
                                                unfold_more
                                            </span>
                                            <span *ngIf="this.sortColConfig['title'] == true" class="material-icons ml-auto">
                                                expand_less
                                            </span>
                                            <span *ngIf="this.sortColConfig['title'] == false" class="material-icons ml-auto">
                                                expand_more
                                            </span> -->
                                        </span>
                                    </th>
                                    <th [class.d-none]="!configColTable.status" (click)="sortCol('title')">
                                        <span class="d-flex align-self-center align-items-center">
                                            {{ localize.status }}
                                            <!-- <span *ngIf="this.sortColConfig['title'] == null" class="material-icons ml-auto">
                                                unfold_more
                                            </span>
                                            <span *ngIf="this.sortColConfig['title'] == true" class="material-icons ml-auto">
                                                expand_less
                                            </span>
                                            <span *ngIf="this.sortColConfig['title'] == false" class="material-icons ml-auto">
                                                expand_more
                                            </span> -->
                                        </span>
                                    </th>
                                    <th class="text-center" style="width: 80px">{{ localize.action }}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of listRequest; let i = index">
                                    <td>{{ (page - 1)*maxSize + i + 1 }}</td>
                                    <td [class.d-none]="!configColTable.tenDoanhNghiep"><a class="cursor-pointer text-decoration-underline text-primary" (click)="gotoDetail(item.orderId)">{{ item.smeName || "" }}</a></td>
                                    <td [class.d-none]="!configColTable.maSoThue">{{ item.taxCode || "" }}</td>
                                    <td [class.d-none]="!configColTable.nguoiDaiDien">{{ item.data?.represent?.nationalityName || "" }}</td>
                                    <td [class.d-none]="!configColTable.ngayTiepNhan">{{ item.createdAt | date: 'dd/MM/yyyy' }}</td>
                                    <td [class.d-none]="!configColTable.nguoiXuLy">
                                        <p>{{ item.data?.sme?.amName || "" }}</p>
                                        <span class="badge badge-light text-weight-bold p-2">{{ item.data?.sme?.amCode }}</span>
                                    </td>
                                    <td [class.d-none]="!configColTable.status">
                                        <span class="badge {{ getStyleStatus(item.status) }} p-2">{{ getNameStatus(item.status) }}</span>
                                    </td>
                                    <td class="text-center">
                                        <div ngbDropdown container="body" placement="top-right">
                                            <span class="material-icons cursor-pointer" ngbDropdownToggle id="settingTable">more_vert</span>
                                            <div ngbDropdownMenu class="border-0">
                                                <a ngbDropdownItem (click)="gotoDetail(item.orderId)"
                                                    class="dropdown-item cursor-pointer d-flex">
                                                    <span class="material-icons-outlined mr-3">info</span>
                                                    {{ localize.detail_information }}    
                                                </a>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="bg-tb-header p-3">
                        <div class="row align-items-center">
                            <span *ngIf="totalElement == 0" class="col-md">Không tìm thấy kết quả</span>
                            <span *ngIf="totalElement > 0" class="col-md">Hiển thị 1 đến {{listRequest.length}} trong tổng số
                                {{totalElement}} kết quả</span>
                            <div class="col-md">
                                <div class="d-flex justify-content-end">
                                    <div *ngIf="totalElement > 0" class="d-flex justify-content-end align-items-center">
                                        <span class="mr-2">Số dòng hiển thị:</span>
                                        <ng-select class="no-bg no-border" [(ngModel)]="maxSize" [searchable]="false" [clearable]="false" placeholder="..." (change)="loadData(1)">
                                            <ng-option [value]="10">10</ng-option>
                                            <ng-option [value]="20">20</ng-option>
                                            <ng-option [value]="30">30</ng-option>
                                        </ng-select>
                                    </div>
                                    <div *ngIf="totalElement > maxSize" class="pagination pages no-border-pagination ml-2">
                                        <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="false"
                                            [pageSize]="maxSize" (pageChange)="loadData($event)" [boundaryLinks]="true" aria-label="Default pagination">
                                            <ng-template ngbPaginationFirst>{{ localize.head }}</ng-template>
                                            <ng-template ngbPaginationLast>{{ localize.last }}</ng-template>
                                            <ng-template ngbPaginationPrevious>&laquo;</ng-template>
                                            <ng-template ngbPaginationNext>&raquo;</ng-template>
                                            <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                                        </ngb-pagination>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </section> 
            </div>
        </div>
    </div>
</section>