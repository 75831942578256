import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { AasValidateMessage } from 'src/app/utils/common/validate-msg-aas';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Config } from 'src/app/config';
import { InfoSignatureConfigObject } from 'src/app/utils/models/aas-models/signature/signature-config/info-signature-config-object';
import { Router, ActivatedRoute } from '@angular/router';
import { AasContractService } from 'src/app/utils/services/aas-network/aas-contract.service';
import { AuthService } from 'src/app/utils/services/auth.service';
import { SignatureService } from 'src/app/utils/services/aas-network/signature.service';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoadingService } from 'src/app/utils/services/loading.service';
import { DatePipe } from '@angular/common';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { UploadGpkdObject } from 'src/app/utils/models/aas-models/upload-gpkd/upload-gpkd-object';
import { UploadGpkdResponse } from 'src/app/utils/models/aas-models/upload-gpkd/upload-gpkd-response';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { TemplateService } from 'src/app/utils/services/aas-network/template.service';

declare var PDFSign: any;
declare var $: any;
declare var Cleave: any;
declare var vnpt_plugin: any;
declare var PdfSigner: any;
@Component({
  selector: 'app-step-two-verify-vip',
  templateUrl: './step-two-verify-vip.component.html',
  styleUrls: ['./step-two-verify-vip.component.scss'],
  providers: [DatePipe]
})
export class StepTwoVerifyVipComponent implements OnInit {
  @ViewChild('output') output: ElementRef;
  @ViewChild('filename') filename: ElementRef;
  @ViewChild('filebase') filebase: ElementRef;
  @Output('next') next = new EventEmitter<any>();
  @Output('back') back = new EventEmitter<any>();

  validMsgGPKD = AasValidateMessage.VERIFY_BUSINESS;

  activeTab = 'INFO';

  //mark: upload giấy phép kinh doanh
  fileForm: FormGroup;
  inputFileLabel: string = 'Chọn file';
  fileType: Array<string> = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
  selectedFile: boolean = false;
  fileGPKD: File;
  fileName: string = '';
  hasSignature: boolean = false;
  maSoThueStatus: boolean = false;
  uploadFileRes: UploadGpkdObject = new UploadGpkdObject();
  
  //mark: nhóm chức năng ký usb-token
  timeCheck: any;

  //mark: nhóm chức năng ký hợp đồng bằng sign server
  formSign: FormGroup;
  isSubmit: boolean = false;
  validMsg: any = AasValidateMessage.SIGNATURE_CONFIG;
  inputFileImageLabel: string = 'Chọn file';
  img_prefix = "data:image/png;base64,";
  imgContent: string = '';
  imgSignServer: File = null;
  imgType = ['image/jpeg', 'image/png', 'image/jpg'];
  pdfSigned: Blob = null;
  keyTest: string = Config.KEY_PLUGIN_CA;
  //mark: phương thức ký
  selectedWayOfSign: string = '';
  //Config CA
  currentSignBoxStyle: number = 5;
  configCA: InfoSignatureConfigObject = new InfoSignatureConfigObject();

  hinhThucHienThi = [
    { value: 0, name: "Không hiển thị" },
    { value: 1, name: "Chỉ hiển thị mô tả" },
    { value: 2, name: "Hiển thị mô tả và hình ảnh bên trái" },
    { value: 3, name: "Chỉ hiển thị hình ảnh" },
    { value: 4, name: "Hiển thị mô tả và hình ảnh phía trên" },
    { value: 5, name: "Hiển thị mô tả và hình ảnh dạng hình nền" }
  ]

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private fb: FormBuilder,
    private contractService: AasContractService,
    private authService: AuthService,
    private aasAuth: AasAuthService,
    private signatureService: SignatureService,
    private templateSerive: TemplateService,
    private modalService: NgbModal,
    public loadingService: LoadingService,
    private datePipe: DatePipe
  ) {
    this.timeCheck = this.datePipe.transform(new Date(), "yyyy-MM-dd'T'HH:mm:ss");
  }

  ngOnInit(): void {
    PDFSign.removeAllSignBox();

    this.makeFormSign();
    this.makeFileForm();
  }

  //MARK: KHỞI TẠO FORM KÝ SIGN SERVER
  /**
   * Khởi tạo form ký sign server
   */
  makeFormSign() {
    this.formSign = this.fb.group({
      signerName: new FormControl('',),
      textColor: new FormControl('',),
      visibleType: new FormControl(5),
      base64Image: new FormControl(''),
      fontSize: new FormControl(13, [Validators.min(8), Validators.max(20), Validators.pattern(/^[0-9]+$/)]),
      signBy: new FormControl(true),
      signDate: new FormControl(true),
      imageFile: new FormControl(''),
    })
  }

  get signerName() { return this.formSign.get('signerName'); }
  get textColor() { return this.formSign.get('textColor'); }
  get visibleType() { return this.formSign.get('visibleType'); }
  get base64Image() { return this.formSign.get('base64Image'); }
  get fontSize() { return this.formSign.get('fontSize'); }
  get signBy() { return this.formSign.get('signBy'); }
  get signDate() { return this.formSign.get('signDate'); }
  get imageFile() { return this.formSign.get('imageFile'); }

  /**
   * Chọn tab
   * @param nameTab 
   */
  selectTab(nameTab: string) {
    this.activeTab = nameTab;
  }

  /**
   * Chọn phương thức ký
   * @param value 
   */
  selectWayOfSign(value: string) {
    this.selectedWayOfSign = value;
    if (value === 'USB_TOKEN') {
      this.signByUsbToken();
    } else if (value === 'SIGN_SERVER') {
      if (this.configCA.certId === '') {
        this.getInfoCASignServer(this.authService.userId);
      }

      PDFSign.setStyleSelectedSignBox(this.currentSignBoxStyle);
      if (this.imgContent !== '') {
        PDFSign.setImage(this.imgContent.split(',')[1]);
      } else {
        // this.imgContent = 
        PDFSign.setImageDefault();
      }
    }
  }
  
  //MARK: UPLOAD FILE GPKD
  /**
   * Khởi tạo form upload file gpkd
   */
  makeFileForm() {
    this.fileForm = this.fb.group({
      file: new FormControl('', [Validators.required])
    });
  }

  get file() { return this.fileForm.get('file'); }

  /**
   * Handle File gpkd được chọn
   * @param e 
   */
  handleFileInput(e) {
    this.selectedFile = true;
    this.hasSignature = false;
    this.maSoThueStatus = false;
    this.fileGPKD = e.target.files && e.target.files.item(0);
    this.fileForm.get('file').setErrors(null);
    console.log(this.fileGPKD);
    if (this.fileGPKD && this.fileGPKD !== null && this.fileGPKD !== undefined) {
      if (this.fileType.includes(this.fileGPKD.type)) {
        this.inputFileLabel = this.fileGPKD.name;

        if (this.fileGPKD.type === 'application/pdf') {
          this.fileName = this.fileGPKD.name;
          PDFSign.preview(this.fileGPKD);

          this.getBase64String(this.fileGPKD, this.handle);

          let data = new FormData();
          data.append('file', this.fileGPKD);
          this.postUploadGPKD(this.authService.partyId, data);
        } else {
          let fileSplit = this.fileGPKD.name.split('.');
          fileSplit.pop();
          this.fileName = `${fileSplit.join('.')}.pdf`;

          let data = new FormData();
          data.append('attachFile', this.fileGPKD);
          this.postConvertToPDF(data);
        }

      } else {
        this.fileForm.get('file').setErrors({ type: true });
        this.inputFileLabel = this.fileGPKD.name
      }
    } else {
      this.fileForm.get('file').setErrors({ required: true });
      this.inputFileLabel = 'Chọn file';
    }
  }

  //MARK: KÝ BẰNG SIGN SERVER
  /**
   * Set dữ liệu config mặc định
   * @param signConfig 
   */
  setConfigSign(signConfig: InfoSignatureConfigObject) {
    this.signerName.setValue(signConfig.signerName);

    this.visibleType.setValue(signConfig.visibleType);
    this.currentSignBoxStyle = Number(this.visibleType.value);
    PDFSign.setStyleSelectedSignBox(Number(this.visibleType.value));

    this.base64Image.setValue(signConfig.base64Image);
    PDFSign.setImage(this.base64Image.value);
    this.imgContent = this.img_prefix + signConfig.base64Image;

    this.fontSize.setValue(signConfig.fontSize);
    PDFSign.setFontSize(this.fontSize.value)

    this.signBy.setValue(signConfig.signBy);
    PDFSign.setShowSignBy(this.signBy.value);

    this.signDate.setValue(signConfig.signDate);
    PDFSign.setShowDateSign(this.signDate.value);

    console.log(this.formSign.value);
  }

  //Upload ảnh chữ ký
  handleFileImageInput(e) {
    this.imgSignServer = e.target.files && e.target.files.item(0);
    if (this.imgSignServer && this.imgSignServer !== null && this.imgSignServer !== undefined) {
      this.inputFileImageLabel = this.imgSignServer.name;

      if (!this.imgType.includes(this.imgSignServer.type)) {
        this.imageFile.setErrors({ type: true });
        this.imgContent = '';
      } else if (this.imgSignServer.size > 2000000) {
        this.imageFile.setErrors({ size: true });
        this.getBase64String(this.imgSignServer, this.handleImgSignServer);
      } else {
        this.getBase64String(this.imgSignServer, this.handleImgSignServer);

        var self = this;
        var u = URL.createObjectURL(this.imgSignServer);
        var img = new Image;
        img.src = u;
        img.onload = function () {
          self.resizeImage(img, self.imgSignServer, 100, 100, '');
        }
      }

    } else {
      // this.imageFile.setErrors({ required: true });
      this.imgContent = '';
      this.inputFileImageLabel = 'Chọn hình ảnh';
    }
  }

  /**
   * Handle cho file hình sign server
   * @param str 
   * @param self 
   */
  handleImgSignServer(str, self) {
    self.imgContent = str;
    self.base64Image.setValue(str.split(',')[1]);
    PDFSign.setImage(str.split(',')[1]);
  }

  //thêm chữ ký
  addSignBox() {
    PDFSign.addSignBox();
  }

  //Nhập font size chữ ký
  inputFontSize(event) {
    PDFSign.setFontSize(event.target.value)
  }

  //Check box ngày ký
  changedDateSign(event) {
    PDFSign.setShowDateSign(this.signDate.value);
  }

  //Check box ký bởi
  changedSignBy(event) {
    PDFSign.setShowSignBy(this.signBy.value);
  }

  //Chọn hình thức hiển thị chữ ký
  selectStyleSignBox(e) {
    this.currentSignBoxStyle = Number(e.target.value);
    PDFSign.setStyleSelectedSignBox(Number(e.target.value));
  }

  //Hoàn thanh ký số sign server
  finishSignServer() {
    let options: Array<any> = PDFSign.getOptions();
    let listPosition: Array<any> = [];

    // if (!this.checkAllowSign()) {
    //   this.alert('Bạn không thể ký hợp đồng này!', 'error', false);
    //   return;
    // }

    if (options.length <= 0) {
      this.alert('Bạn cần thêm ít nhất 1 chữ ký!', 'error', false);
      return;
    }

    for (var i = 0; i < options.length; i++) {
      let x = options[i].x;
      let y = options[i].y - options[i].h;
      let w = options[i].w + x;
      let h = options[i].h + y;
      let page = options[i].page;

      listPosition.push({
        page: page,
        rectangle: `${x},${y},${w},${h}`
      })
    }

    console.log(this.formSign.value);
    let param =
    {
      "userId": this.authService.userId,
      "base64file": this.filebase.nativeElement.value,
      "listPosition": listPosition,
      "signerBy": this.signBy.value,
      "signerDate": this.signDate.value,
      "fontSize": Number(this.fontSize.value),
      "visibleType": Number(this.visibleType.value),
      "base64Image": this.base64Image.value
    }
    this.postSignServer(param);
  }

  //MARK: KÝ TÀI LIỆU BẰNG USB-TOKEN
  /**
   * Check plugin ký usb token
   */
  checkPlugin() {
    const key = this.keyTest;
    vnpt_plugin.checkPlugin().then(function (data) {
      if (data === "1") {
        vnpt_plugin.setLicenseKey(key);
        console.log("ok");
      }
    }).catch(function (e) {
      console.log(e);
      //alert("VNPT-CA Plugin not running! Turn on plugin or install!");
    });
  }

  /**
   * Ký bằng usb token
   */
  signByUsbToken() {
    // this.loaderSerive.show();
    // if (!this.isSigned) {

    // }
    this.output.nativeElement.value = "";
    var dataInput = this.filebase.nativeElement.value;
    if (dataInput.length === 0) {
      // this.loaderSerive.hide();
      this.alert('Không tìm thấy file cần kí!', 'error');
      return;
    }
    var fileName = this.filename.nativeElement.value;
    console.log(fileName);
    var fileSplit = fileName.split(".");
    var fileExtension = fileSplit[fileSplit.length - 1].toLowerCase();
    if (fileExtension !== "xml" && fileExtension !== "pdf" && fileExtension !== "docx"
      && fileExtension !== "xlsx" && fileExtension !== "pptx" && fileExtension !== "txt") {
      // this.loaderSerive.hide();
      this.alert('Định dạng file không được hỗ trợ kí số!', 'error');
      return;
    }
    var sigOptions = null;
    if (fileExtension === "pdf") {
      sigOptions = new PdfSigner();
      sigOptions.AdvancedCustom = true;
    }
    this.SignAdvanced(dataInput, fileExtension, sigOptions, this.showMessage);
  }

  /**
   * Tiến hành ký file
   * @param data 
   * @param type 
   * @param option 
   * @param callback 
   */
  SignAdvanced(data, type, option, callback) {
    var dataJS = {};
    var arrData = [];
    // 1			
    dataJS["data"] = data;
    dataJS["type"] = type;
    dataJS["sigOptions"] = JSON.stringify(option);

    var jsData = "";
    jsData += JSON.stringify(dataJS);
    //
    arrData.push(jsData);

    var vm = this;
    vnpt_plugin.CheckToken().then((data) => {
      console.log("check token ==> ", data);
      if (data === "1") {
        vnpt_plugin.getCertInfo(null, true).then((data) => {
          // var serial = data["serial"];
          // vnpt_plugin.signArrDataAdvanced(arrData, serial, true).then((data) => {
          //   vm.showMessage(data);
          // }).catch(function (e) {
          //   alert(e);
          //   this.loaderSerive.hide();
          // });

          var certData = JSON.parse(data)
          console.log(certData)
          var serial = certData["serial"];
          vnpt_plugin.CheckOCSP(serial, vm.timeCheck, null, null).then((result) => {
            console.log(result)
            console.log(serial, "    ", vm.timeCheck);

            if (result === "0") {
              vnpt_plugin.signArrDataAdvanced(arrData, serial, true).then((signData) => {
                vm.showMessage(signData);
              }).catch(function (e) {
                alert(e);
                this.loaderSerive.hide();
              });
            } else {
              vm.showMessage(result);
            }
          });

        })
      } else {
        // this.loaderSerive.hide();
        this.alert("Bạn cần cắm token trước khi ký số.", 'error');
      }
    });
  }

  /**
   * Handle message ký usb token
   * @param data 
   */
  showMessage(data) {
    // this.loaderSerive.hide();
    var jsOb = JSON.parse(JSON.parse(data)[0]);
    var err = '';
    switch (jsOb.code) {
      case 0:
        this.pdfSigned = this.converBase64toBlob(jsOb.data, 'application/pdf');
        let blob: any = new Blob([this.pdfSigned], { type: 'application/pdf' });
        this.fileGPKD = blob;

        err = "Ký tài liệu thành công!";
        break;
      case 1:
        err = "Data input format incorrect";
        break;
      case 2:
        err = "Không thể lấy thông tin chứng thư số!";
        break;
      case 3:
        err = "Ký thất bại! Đã xảy ra lỗi trong quá trình ký!";
        break;
      case 4:
        err = "Not found private key";
        break;
      case 5:
        err = "Unknown error";
        break;
      case 6:
        err = "Pdf signing: not found page number parameter";
        break;
      case 7:
        err = "Pdf signing: page number illegal";
        break;
      case 8:
        err = "Xml signing: not found signing tag";
        break;
      case 9:
        err = "Xml signing: not found if of signing tag";
        break;
      case 10:
        err = "Data contain one or more signatures invalid";
        break;
      case 11:
        err = "Bạn đã hủy phiên ký!";
        break;
      case 13:
        err = "Data null";
        break;
      default:
        err = "Unknown error";
        break;
    }
    if (jsOb.code !== 0) {
      this.output.nativeElement.value = err;
      this.alert(err, 'error');
    } else {
      this.alert(err, 'success', false, '', 'OK', () => {
        //Reupload 
        let data = new FormData();
        data.append('file', this.pdfSigned);
        PDFSign.preview(this.pdfSigned);
        this.postUploadGPKD(this.authService.partyId, data);
      });
    }
  }

  //MARK: COMMON
  /**
   * convert imgUrl to file object
   * @param url 
   * @param filename 
   * @param mimeType 
   */
  urltoFile(url, filename, mimeType) {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  /**
   * Resize ảnh
   * @param img 
   * @param fileImg 
   * @param max_width 
   * @param max_height 
   * @param resize_for 
   */
  resizeImage(img, fileImg, max_width, max_height, resize_for = 'SIGN_SERVER') {
    var width = img.width;
    var height = img.height;

    // calculate the width and height, constraining the proportions
    if (width > height) {
      if (width > max_width) {
        //height *= max_width / width;
        height = Math.round(height *= max_width / width);
        width = max_width;
      }
    } else {
      if (height > max_height) {
        //width *= max_height / height;
        width = Math.round(width *= max_height / height);
        height = max_height;
      }
    }
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');
    canvas.width = width;
    canvas.height = height;
    ctx.drawImage(img, 0, 0, width, height);
    var self = this;
    let imgURL = ctx.canvas.toDataURL(fileImg.type, 1);
    this.urltoFile(imgURL, fileImg.name, fileImg.type).then(function (file) {
      if (resize_for === 'SIGN_SERVER') {
        self.imgSignServer = file;
      }
    });
    canvas.remove();
  }

  /**
   * Convert Base65 to Blob (file data)
   * @param content 
   * @param contentType 
   */
  converBase64toBlob(content, contentType): Blob {
    //blob = converBase64toBlob(response.content, 'application/pdf');
    contentType = contentType || '';
    var sliceSize = 512;
    var byteCharacters = window.atob(content); //method which converts base64 to binary
    var byteArrays = [
    ];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, {
      type: contentType,
    }); //statement which creates the blob
    return blob;
  }

  /**
   * Get Base64 from Blob
   * @param blob 
   * @param callback 
   */
  getBase64String(blob, callback) {
    var self = this;
    var reader = new FileReader();
    reader.onload = function (e) {
      callback(reader.result, self);
    }

    reader.readAsDataURL(blob);
  }

  /**
   * Handle file pdf để chuẩn bị ký
   * @param str 
   * @param self 
   */
  handle(str, self) {
    $('#fileBase').val(str.split(',')[1]);
  }

  //MARK: HANDLE OUPUT
  onNext() {
    let formData = new FormData();
    formData.append('file', this.fileGPKD);

    console.log(this.fileGPKD);

    this.postCompleteVerifyGPKD(this.authService.partyId, formData);
  }

  onBack() {
    this.back.emit("ok back");
  }

  //MARK: NETWORKING
  /**
   * Upload file GPKD
   * @param id 
   * @param data 
   */
  postUploadGPKD(id, data: FormData) {
    this.aasAuth.postUploadGPKD(id, data).subscribe(res => {
      this.uploadFileRes = new UploadGpkdResponse(res).object;
      console.log(this.uploadFileRes);
      this.hasSignature = this.uploadFileRes.signatureStatus.toString() === "VALID";
      this.maSoThueStatus = this.uploadFileRes.maSoThueStatus.toString() === "VALID";
      console.log(this.hasSignature);
      console.log(this.maSoThueStatus);
    }, err => {
      console.log(err);
      if (!this.hasSignature) {
        this.selectTab('SIGN');
      }
      this.handleError(err, ResponseMsg.MESSAGES.VERIFY_BUSINESS);
    }, () => {

    })
  }

  /**
   * Hoàn thành upload GPKD
   * @param id 
   * @param data 
   */
  postCompleteVerifyGPKD(id, data: FormData) {
    this.aasAuth.postCompleteVerifyGPKD(id, data).subscribe(res => {
      // this.get(this.auth.userId);
      this.next.emit({
        done: true
      })
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.VERIFY_BUSINESS);
    }, () => {
    });
  }

  /**
   * Convert doc, docx to PDF
   * @param data 
   */
  postConvertToPDF(data: FormData) {
    this.templateSerive.postConvertToPdf(data).subscribe(fileData => {
      const blob: any = new Blob([fileData], { type: 'application/pdf' });
      PDFSign.preview(blob);
      this.fileGPKD = blob; 

      this.getBase64String(blob, this.handle);

      let data = new FormData();
      data.append('file', blob);
      this.postUploadGPKD(this.authService.partyId, data);
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.ESIGN_SERVICE);
    });
  }

  /**
   * Lấy thông tin chữ ký số user
   * @param id 
   */
  getInfoCASignServer(id) {
    // if (this.userType === 'BUSINESS') {
    //   this.signatureService.getInfoCASignServer(id).subscribe(res => {
    //     console.log(res);
    //     if (res['object'] == null) {
    //       this.alert('Bạn chưa cấu hình tài khoản Sign Server! Vui lòng cấu hình tài khoản!', 'error', true, '', 'Đến cấu hình', () => {
    //         this.router.navigate(['/app/console/signature-manager']);
    //       }, '', 'Huỷ');
    //     } else {
    //       let infoCA = new InfoCASignServerResponse(res).object;
    //       this.getInfoSignatureConfig(infoCA.certificateDefault.id);
    //     }
    //   }, err => {
    //     console.log(err);
    //     this.handleError(err, ResponseMsg.MESSAGES.SIGN_SERVER);
    //   }, () => {
    //   })
    // }
  }

  /**
   * Hoàn thánh ký sign server
   * @param data 
   */
  postSignServer(data) {
    this.contractService.postSignServer(data).subscribe(res => {
      let fileBase64 = res['object']['fileBase64'];
      let blob = this.converBase64toBlob(fileBase64, 'application/pdf');
      this.pdfSigned = blob;
      PDFSign.preview(blob);
      PDFSign.removeAllSignBox();
      this.alert('Ký tài liệu thành công!', 'success', false, '', 'OK', () => {
        // this.router.navigate(['/app/console/contract', id]);
      });
    }, err => {
      console.log(err);
      if (err.error && err.error.message && err.error.message === 'ECT-00000016') {
        this.alert('VNPT ký số: Phiên đăng nhập ký số hết hiệu lực!', 'error', true, '', 'Đăng nhập lại', () => {
          // this.router.navigate(['/app/console/signature-manager']);
          window.open(location.origin + '/app/console/signature-manager', "_blank");
        }, '', 'Huỷ');
      } else {
        this.handleError(err, ResponseMsg.MESSAGES.SIGN_SERVER);
      }
    })
  }

  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], 'error');
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, 'error');
    } else {
      this.alert('Lỗi không xác định! Vui lòng thử lại', 'error');
    }
  }
}
