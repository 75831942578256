import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { LANDING_BLOG_FORM } from 'src/app/utils/common/constant-vaildate-msg';
import { BlogItem } from 'src/app/utils/models/landing-page/blog-item.model';
import { BlogResponse } from 'src/app/utils/models/landing-page/blog-response.model';
import { BlogTypeCollectionResponse } from 'src/app/utils/models/landing-page/blog-type-collection-response.model';
import { BlogTypeItem } from 'src/app/utils/models/landing-page/blog-type-item.model';
import { LandingCategoryItem } from 'src/app/utils/models/landing-page/landing-category-item.model';
import { LandingCategoryCollectionResponse } from 'src/app/utils/models/landing-page/landing-page-category-collection-response.model';
import { LanguageItem } from 'src/app/utils/models/landing-page/language-item.model';
import { LandingpageService } from 'src/app/utils/services/aas-network/landingpage.service';

@Component({
  selector: 'app-service-landing-page-blog-edit',
  templateUrl: './service-landing-page-blog-edit.component.html',
  styleUrls: ['./service-landing-page-blog-edit.component.scss']
})
export class ServiceLandingPageBlogEditComponent implements OnInit {

  //data
  blogId = this.activatedRoute.snapshot.paramMap.get('id');
  blogItem: BlogItem;
  categories: Array<LandingCategoryItem> = [];
  blogTypes: Array<BlogTypeItem> = [];
  languages: Array<LanguageItem> = [];
  fileToUpload: File = null;
  //form
  form: FormGroup;
  isSubmit = false;
  validMsg: any = LANDING_BLOG_FORM;
  
  constructor(
    private modalService: NgbModal,
    private landingService: LandingpageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    this.makeForm();
    this.getCategories();
    this.getBlogTypes();
    this.getLanguage();
    this.getBlog();
  }

  getBlog(){
    this.landingService.getBlogById(this.blogId)
    .subscribe(
      (res) => {
        let response = new BlogResponse(res);
        this.blogItem = response.object;

        this.form.patchValue({
          title: this.blogItem.title,
          subTitle: this.blogItem.subTitle,
          description: this.blogItem.description,
          avatar: this.blogItem.avatar,
          blogTypeId: this.blogItem.blogTypeId,
          categoryId: this.blogItem.categoryId,
          sortOrder: this.blogItem.sortOrder,
          language: this.blogItem.language,
        });
      },
      (err) =>{
        this.handleError(err, err);
      }
    )
  }

  getCategories(){
    this.landingService.getCategories()
    .subscribe(
      (res) => {
        let response = new LandingCategoryCollectionResponse(res);
        this.categories = response.data;
      },
      (err) => {
        this.handleError(err, err);
      }
    )
  }

  getBlogTypes(){
    this.landingService.getBlogTypes()
    .subscribe(
      (res) => {
        let response = new BlogTypeCollectionResponse(res);
        this.blogTypes = response.data;
      },
      (err) => {
        this.handleError(err, err);
      }
    )
  }
  
  makeForm(){
    this.form = this.fb.group({
      title: ['', Validators.required],
      subTitle: ['', Validators.required],
      description: ['', Validators.required],
      avatar: ['', Validators.required],
      blogTypeId: ['', Validators.required],
      categoryId: ['', Validators.required],
      language: ['', Validators.required],
      sortOrder: ['', [Validators.required, Validators.min(1)]],
    });
  }

  onSubmit(){
    this.isSubmit = true;
    if(this.form.valid){
      let data = this.form.value;
      data.id = this.blogId;
      this.landingService.editBlog(data)
      .subscribe(
        (res) => {
          this.isSubmit = false;
          this.alert('Cập nhật thành công', 'success', false, '', 'OK', ()=>{
            this.router.navigate(['/console/landing-page/blog']);
          });
        },
        (err) => {
          this.isSubmit = false;
          this.handleError(err, err);
        }
      )
    }
  }

  //upload file 
  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    this.uploadFileToActivity();
    
  }
  uploadFileToActivity() {
    this.landingService.uploadFile(this.fileToUpload).subscribe(
      (res: any) => {      
        console.log(res)  
        this.form.patchValue({
          avatar: res?.object.url
        });
      },
      (err) => {
        this.handleError(err, err);
      }
    );
  }

  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], 'error');
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, 'error');
    } else {
      this.alert('Lỗi không xác định! Vui lòng thử lại', 'error');
    }
  }
  getLanguage(){
    this.languages = this.landingService.getLanguage();
  }

}
