import { UploadGpkdObject } from './upload-gpkd-object';
import { MainResponse } from '../../main-response.model';

export class UploadGpkdResponse extends MainResponse {
    object: UploadGpkdObject;

    constructor(obj = null) {
        super();
        obj = obj || {};
        this.init(obj);

        this.object = new UploadGpkdObject(obj.object);
    }
}