<app-spinner></app-spinner>
<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-auto">
        <h1 class="m-0 text-dark"><label>Danh sách tải xuống</label></h1>
      </div>
      <!-- /.col -->
    </div>
  </div>
  <!-- /.container-fluid -->
</div>
<section class="content">
  <div class="container-fluid">
    <section class="card-body p-0">
      <div class="table-responsive">
        <table class="table table-sort-col mb-0">
          <thead>
            <tr class="bg-tb-header">
              <th>STT</th>
              <th>
                <span class="d-flex align-self-center align-items-center">
                  Thời điểm gửi yêu cầu tải xuống
                  <!-- <span
                          *ngIf="
                            this.sortColConfig['title'] ==
                            null
                          "
                          class="material-icons ml-auto"
                        >
                          unfold_more
                        </span>
                        <span
                          *ngIf="
                            this.sortColConfig['title'] ==
                            true
                          "
                          class="material-icons ml-auto"
                        >
                          expand_less
                        </span>
                        <span
                          *ngIf="
                            this.sortColConfig['title'] ==
                            false
                          "
                          class="material-icons ml-auto"
                        >
                          expand_more
                        </span> -->
                </span>
              </th>
              <th>
                <span class="d-flex align-self-center align-items-center">
                  Chức năng yêu cầu
                  <!-- <span
                          *ngIf="
                            this.sortColConfig[
                              'partnersCount'
                            ] == null
                          "
                          class="material-icons ml-auto"
                        >
                          unfold_more
                        </span>
                        <span
                          *ngIf="
                            this.sortColConfig[
                              'partnersCount'
                            ] == true
                          "
                          class="material-icons ml-auto"
                        >
                          expand_less
                        </span>
                        <span
                          *ngIf="
                            this.sortColConfig[
                              'partnersCount'
                            ] == false
                          "
                          class="material-icons ml-auto"
                        >
                          expand_more
                        </span> -->
                </span>
              </th>
              <th>
                <span class="d-flex align-self-center align-items-center">
                  Thời hạn tải xuống
                  <!-- <span
                          *ngIf="
                            this.sortColConfig[
                              'modified'
                            ] == null
                          "
                          class="material-icons ml-auto"
                        >
                          unfold_more
                        </span>
                        <span
                          *ngIf="
                            this.sortColConfig[
                              'modified'
                            ] == true
                          "
                          class="material-icons ml-auto"
                        >
                          expand_less
                        </span>
                        <span
                          *ngIf="
                            this.sortColConfig[
                              'modified'
                            ] == false
                          "
                          class="material-icons ml-auto"
                        >
                          expand_more
                        </span> -->
                </span>
              </th>
              <th>
                <span class="d-flex align-self-center align-items-center">
                  Trạng thái
                  <!-- <span
                          *ngIf="
                            this.sortColConfig[
                              'currentStage'
                            ] == null
                          "
                          class="material-icons ml-auto"
                        >
                          unfold_more
                        </span>
                        <span
                          *ngIf="
                            this.sortColConfig[
                              'currentStage'
                            ] == true
                          "
                          class="material-icons ml-auto"
                        >
                          expand_less
                        </span>
                        <span
                          *ngIf="
                            this.sortColConfig[
                              'currentStage'
                            ] == false
                          "
                          class="material-icons ml-auto"
                        >
                          expand_more
                        </span> -->
                </span>
              </th>
              <th>
                <span class="d-flex align-self-center align-items-center">
                  Thao tác
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of listDownloadContract; let i = index">
              <td>
                {{ (page - 1) * maxSize + i + 1 }}
              </td>
              <td>
                {{item.created| date: 'HH:mm:ss'}}
                {{ item.created | date: 'dd/MM/yyyy' }}
              </td>
              <td>Danh sách hợp đồng</td>
              <td>
                {{item.expiredDate| date: 'HH:mm:ss'}}
                {{ item.expiredDate | date: 'dd/MM/yyyy' }}
              </td>
              <td>
                <span class="badge {{ currentStage[item.status].style || '' }} p-2">{{ currentStage[item.status].label
                  || '' }}</span>
              </td>
              <td>
                <a *ngIf="currentStage[item.status].code === 'DONE'" href="{{item.url}}"
                  class="text-decoration-underline">Tải
                  xuống</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="bg-tb-header p-3">
        <div class="row align-items-center">
          <span class="col-md text-left" *ngIf="totalElement == 0">Không tìm thấy kết quả</span>
          <span class="col-md text-left" *ngIf="totalElement > 0">Hiển thị 1 đến {{ listDownloadContract.length }} trong
            tổng số
            {{ totalElement }} kết quả</span>
          <div class="col-md">
            <div class="d-flex justify-content-end">
              <div class="d-flex justify-content-end align-items-center" *ngIf="totalElement > 0">
                <span class="mr-2">Số dòng hiển thị:</span>
                <ng-select class="no-bg no-border" [(ngModel)]="maxSize" [searchable]="false" [clearable]="false"
                  placeholder="..." (change)="loadData(1)">
                  <ng-option [value]="10">10</ng-option>
                  <ng-option [value]="20">20</ng-option>
                  <ng-option [value]="30">30</ng-option>
                </ng-select>
              </div>
              <div class="pagination pages no-border-pagination ml-2" *ngIf="totalElement > maxSize">
                <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="5" [rotate]="true"
                  [ellipses]="false" [pageSize]="maxSize" (pageChange)="loadData($event)" [boundaryLinks]="true"
                  aria-label="Default pagination">
                  <ng-template ngbPaginationFirst>Đầu</ng-template>
                  <ng-template ngbPaginationLast>Cuối</ng-template>
                  <ng-template ngbPaginationPrevious>&laquo;</ng-template>
                  <ng-template ngbPaginationNext>&raquo;</ng-template>
                  <ng-template ngbPaginationNumber let-page>{{
                    page
                    }}</ng-template>
                </ngb-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</section>