<section class="content">
    <div class="container-fluid">

        <div class="row">
            <div class="col-md-3">
                <div class="content-header">
                    <div class="container-fluid">
                        <div class="row mb-2">
                            <div class="col-md-auto">
                                <h1 class="m-0 text-dark"><label>{{ 'ProfileManager_title' | translate }}</label></h1>
                            </div>
                        </div>
                        <!-- /.row -->
                    </div>
                    <!-- /.container-fluid -->
                </div>
                <section class="content">
                    <div class="container-fluid">
                        <div class="bg-white">

                            <ul class="nav flex-column nav-pills" id="v-pills-tab" role="tablist"
                                aria-orientation="vertical">
                                <li class="border-bottom" *ngIf="!controlAccount.userInfo.isViewer">
                                    <a class="nav-link d-flex" id="account-infor-tab" data-toggle="pill" href="#account-infor"
                                        role="tab" aria-controls="account-infor" aria-selected="false">
                                        <span class="material-icons mr-2">person</span>
                                        {{ 'ProfileManager_account_info' | translate }}</a>
                                </li>
                                <li class="border-bottom">
                                    <a class="nav-link d-flex active" id="change-password-tab" data-toggle="pill"
                                        href="#change-password" role="tab" aria-controls="change-password"
                                        aria-selected="true">
                                        <span class="material-icons mr-2">vpn_key</span>
                                        {{ 'ProfileManager_change_password' | translate }}</a>
                                </li>
                                <li *ngIf="!controlAccount.userInfo.isViewer">
                                    <a class="nav-link d-flex" id="notification-tab" data-toggle="pill"
                                        href="#notification" role="tab" aria-controls="notification"
                                        aria-selected="false">
                                        <span class="material-icons mr-2">
                                            notifications
                                        </span>
                                        Thông báo</a>
                                </li>
                            </ul>
                        </div>

                    </div>
                </section>
            </div>
            <div class="col-md-9">
                <div class="tab-content contract-tab-body" id="v-pills-tabContent">
                    <!--MARK Thông tin tài khoản -->
                    <div class="tab-pane fade show" id="account-infor" role="tabpanel" aria-labelledby="account-infor">
                        <app-profile-business-v2 *ngIf="userInfo.userType === 'BUSINESS' || userInfo.userType === 'ENTERPRISE'" [userInfo]="userInfo"  [partyInfo]="partyInfo" (onActionSave)='actionSave($event)'></app-profile-business-v2>
                        <app-profile-consumer-v2 *ngIf="userInfo.userType === 'CONSUMER'" [userInfo]="userInfo"  [partyInfo]="partyInfo" (onActionSave)='actionSave($event)'></app-profile-consumer-v2>
                        <!-- <app-profile-viewer *ngIf="userInfo.userType === 'INTERNAL'" [userInfo]="userInfo"  [partyInfo]="partyInfo" (onActionSave)='actionSave($event)'></app-profile-viewer> -->
                    </div>

                    <!--MARK Đổi mật khẩu-->
                    <div class="tab-pane fade show active" id="change-password" role="tabpanel"
                        aria-labelledby="change-password">
                        <app-change-password></app-change-password>
                        <!-- <h1>Tab2</h1> -->
                    </div>
                    <div class="tab-pane fade show" id="notification" role="tabpanel" aria-labelledby="notification">
                        <app-notification></app-notification>
                        <!-- <h1>Tab2</h1> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
