<app-spinner></app-spinner>
<input #output id="output" class="" value="" type="hidden">
<input #filebase id="fileBase" class="" type="hidden">
<input #filename id="fileName" class="" value="{{ fileName }}">
<div class="wrapper">
    <div class="preview-overlay">

        <div class="preview-container">
            <div class="preview-header d-flex">
                <div (click)="goToBack()"
                    class="ct-header-btn-back d-flex justify-content-center align-items-center cursor-pointer">
                    <span class="material-icons">clear</span>
                </div>
                <div class="ct-title flex-grow-1 d-flex align-items-center px-3">
                    <h4 class="mb-0">Hợp đồng điện tử</h4>
                </div>
                <div class="ct-toolbar-btn d-flex align-items-center">
                    <button (click)="saveContract()"
                        class="btn btn-outline-primary d-flex align-items-center mr-3"><span
                            class="material-icons mr-2">get_app</span> Tải xuống</button>
                </div>
            </div>

            <div class="preview-body">
                <div class="pv ct">
                    <div class="pv-body">
                        <div class="ctpv-container">
                            <div class="ctpv-content">
                                <div class="ct-container">
                                    <div class="ct-thumb-page">
                                        <div class="ct-vs ct-enable-scroll">
                                            <ol id="thumbnail-page" class="ct-vs-list mt-3">
                                            </ol>
                                        </div>
                                    </div>
                                    <div class="ct-content">
                                        <div id="parentView" class="ct-doc-loaded ct-enable-scroll">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="ct ctpv-tool-bar">
                            <div class="ctpv-tool-sidebar">
                                <div class="ctpv-tabbar">
                                    <div class="ctpv-main">
                                        <button (click)="selectTab('INFO')" class="ctpv-btn"
                                            [class.active]="activeTab == 'INFO'"> <span
                                                class="material-icons-outlined">info</span></button>
                                        <button (click)="selectTab('SIGN')" class="ctpv-btn"
                                            [class.active]="activeTab == 'SIGN'"> <span
                                                class="material-icons">gesture</span></button>
                                    </div>
                                </div>
                                <div class="ctpv-footer">

                                </div>
                            </div>
                            <div class="ctpv-tool-content">
                                <div class="ctpv-content-wrapper px-3">
                                    <div id="tab-info" class="ctpv-scroll-content"
                                        [class.d-none]="!(activeTab === 'INFO')">
                                        <h5 class="mt-3">Thông tin chi tiết hợp đồng</h5>
                                        <div class="row mt-3">
                                            <div class="col-lg-6">
                                                <p><label>Tên hợp đồng</label></p>
                                                <p>{{ contract.title }}</p>
                                            </div>
                                            <!-- <div class="col-lg-6">
                                                <p><label>File hợp đồng</label></p>
                                                <p>{{ contract.title }}</p>
                                            </div> -->
                                        </div>

                                        <div class="row mt-3">
                                            <div class="col-lg-6">
                                                <p><label>Ngày có hiệu lực</label></p>
                                                <p>{{ contract.validDate | date: 'dd/MM/yyyy'}}</p>
                                            </div>
                                            <div class="col-lg-6">
                                                <p><label>Ngày hết hiệu lực</label></p>
                                                <p>{{ contract.endDate | date: 'dd/MM/yyyy' }}</p>
                                            </div>
                                        </div>

                                        <div class="row mt-3">
                                            <div class="col-lg-6">
                                                <p><label>Tự động gia hạn hợp đồng</label></p>
                                                <p>{{ contract.autoRenew ? 'Có' : 'Không' }}</p>
                                            </div>
                                            <div class="col-lg-6">
                                                <p><label>Giá trị hợp đồng</label></p>
                                                <p>{{ contract.contractValue }}</p>
                                            </div>
                                        </div>

                                        <div class="row mt-3">
                                            <div class="col-lg-6">
                                                <p><label>Người nhận</label></p>
                                                <p>{{ contract.customer.email }}</p>
                                            </div>
                                            <div class="col-lg-6">
                                                <p><label>Loại khách hàng</label></p>
                                                <p>{{ contract.customerType === 'CONSUMER' ? 'Cá nhân' : 'Doanh nghiệp' }}
                                                </p>
                                            </div>
                                        </div>

                                        <div class="row mt-3">
                                            <div class="col-lg-12">
                                                <p><label>Hình thức ký</label></p>
                                                <p>
                                                    <span *ngFor="let item of contract.signForm; let i = index">
                                                        {{ item === 'OTP' ? 'OTP' : '' }}
                                                        {{ item === 'ONE_TIME_CA' ? 'Ký số 1 lần' : '' }}
                                                        {{ item === 'EKYC' ? 'OTP và EKYC' : '' }}
                                                        {{ item === 'USB_TOKEN' ? 'USB-Token' : '' }}
                                                        {{ item === 'SIGN_SERVER' ? 'VNPT ký số' : '' }}
                                                        {{ i < contract.signForm.length - 1 ? ', ' : ''}}
                                                    </span>
                                                </p>

                                            </div>
                                        </div>

                                        <div class="row mt-3">
                                            <div class="col-lg-6">
                                                <p><label>Thời hạn ký</label></p>
                                                <p>{{ contract.signInDays }}</p>
                                            </div>
                                            <div class="col-lg-6">
                                                <p><label>Ghi chú</label></p>
                                                <p>{{ contract.creationNote }}</p>
                                            </div>
                                        </div>

                                        <div class="row mt-3">
                                            <div class="col-lg-12">
                                                <p><label>Trạng thái</label></p>
                                                <p [innerHtml]="getStatusContract"></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="tab-sign" class="ctpv-scroll-content"
                                        [class.d-none]="!(activeTab === 'SIGN')">
                                        <div id="detail-SIGN" class="">
                                            <div class="mt-3">
                                                <label class="text-primary">Thông tin chữ ký điện tử</label>
                                                <hr class="mb-3">
                                                <p *ngIf="listSignature.kyDienTu.length <= 0">Không có thông tin chữ
                                                    ký điện tử</p>
                                                <div *ngFor="let item of listSignature.kyDienTu"
                                                    class="border-bottom mb-3">
                                                    <div class="row">
                                                        <div class="col-xl-6">
                                                            <p><label>Tên người ký</label></p>
                                                            <p>{{ item.fullname }}</p>
                                                        </div>
                                                        <div class="col-xl-6">
                                                            <p><label>Số CMND/CCCD/Hộ chiếu</label></p>
                                                            <p>{{ item.idCard }}</p>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-xl-6">
                                                            <p><label>Ngày ký</label></p>
                                                            <p>{{ item.signDate }}</p>
                                                        </div>
                                                        <div class="col-xl-6">
                                                            <p><label>Hình ảnh</label></p>
                                                            <img *ngIf="item.base64Image !== ''" class="w-100"
                                                                [src]="img_prefix + item.base64Image">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="mt-3">
                                                <label class="text-primary">Thông tin chữ ký số</label>
                                                <hr class="mb-3">
                                                <p *ngIf="listSignature.kySo.length <= 0">Không có thông tin chữ ký
                                                    số</p>
                                                <div *ngFor="let item of listSignature.kySo"
                                                    class="border-bottom mb-3">
                                                    <div class="row">
                                                        <div class="col-xl-6">
                                                            <p><label>Chủ chứng thư</label></p>
                                                            <p>{{ item.subject.common_name }}</p>
                                                        </div>
                                                        <!-- <div class="col-xl-6">
                                                            <p><label>Chức vụ</label></p>
                                                            <p>{{ item.idCard }}</p>
                                                        </div> -->
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-xl-6">
                                                            <p><label>Số giấy tờ</label></p>
                                                            <p>{{ item.subject.user_id }}</p>
                                                        </div>
                                                        <div class="col-xl-6">
                                                            <p><label>Nhà cung cấp</label></p>
                                                            <p>{{ item.issuer.common_name }}</p>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-xl-6">
                                                            <p><label>Ngày hiệu lực</label></p>
                                                            <p>{{ item.not_before }}</p>
                                                        </div>
                                                        <div class="col-xl-6">
                                                            <p><label>Ngày hết hạn</label></p>
                                                            <p>{{ item.not_after }}</p>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-xl-6">
                                                            <p><label>Ngày ký</label></p>
                                                            <p>{{ item.signDate }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

