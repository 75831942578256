import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { ContractTypeService } from '../../services/contract-type.service';

@Component({
  selector: 'app-edit-contract-type',
  templateUrl: './edit-contract-type.component.html',
  styleUrls: ['./edit-contract-type.component.scss']
})
export class EditContractTypeComponent implements OnInit {

  id = this.activatedRoute.snapshot.paramMap.get('id') || null;
  form: FormGroup;
  name: string;
  isSubmit = false;
  // Option trạng thái
  status: boolean = true;
  validateError = {
    name: {
      required: 'ContractTypes_clc_form_name_required'
    },
  };
  dropListStatus = [
    { value: true, text: 'status_running' },
    { value: false, text: 'status_stopped' },
  ];

  contractTypeOriginalName: string = "";

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private alert: AlertControl,
    private fb: FormBuilder,
    private contractTypeService: ContractTypeService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.makeForm();
    this.getTypeDetails(this.id);
  }

  getTypeDetails(id) {
    this.contractTypeService.getTypeDetails(id).subscribe((res: any) => {
      const typeDetails = res.object ?? null;
      if (typeDetails != null) {
        this.name = typeDetails.name ?? '';
        this.status = typeDetails.status;

        // Keep contract type original name
        this.contractTypeOriginalName = typeDetails?.name ?? "";
      }
    }, err => {
      this.alert.showErrorHandled(err);
    });
  }

  makeForm() {
    this.form = this.fb.group({
      name: ['', [Validators.required]],
      status: [true, [Validators.required]],
    });
  }

  cancel() {
    this.router.navigate([ConstantUrl.contract_type_management]);
  }

  save() {
    this.isSubmit = true;
    if (this.form.valid) {
      this.alert.showAlert(this.translate.instant('ContractTypes_edit_title'), ConstantAlertType.BLANK, true, "",
        this.translate.instant('btn_update'), () => {
          this.postCheckNameExists(this.form.controls.name.value);
        }, "", this.translate.instant('btn_close'), () => { }, this.translate.instant('btn_confirm'))
    }
  }

  postCheckNameExists(name) {
    if (name === this.contractTypeOriginalName) {
      this.savingExecute();
    } else {
      // Only check if name has changed
      var data = {
        "name" : name
      }
      this.contractTypeService.postCheckNameExists(data).subscribe((res: any) => {
          if (res.object.exists && res.object.exists == true) {
            this.alert.showAlert(this.translate.instant('ContractTypes_edit_type_exist_confirm'), ConstantAlertType.BLANK, true, '', this.translate.instant('btn_update'),
              () => {
                this.savingExecute();
              }, '', this.translate.instant('btn_cancel'));
          } else {
            this.savingExecute();
          }
        }, (err) => {
          this.alert.showErrorHandled(err);
        }
      );
    }
  }

  savingExecute() {
    this.contractTypeService.editType(this.id, this.form.value).subscribe((res: any) => {

      // Success alert
      this.alert.showAlertOnlyNoti(
        this.translate.instant('ContractTypes_update_type_success'),
        ConstantAlertType.SUCCESS,
        '',
        this.translate.instant('btn_close')
      );

      // Navigate to list screen
      this.router.navigate([ConstantUrl.contract_type_management]);

    }, err => {

      this.alert.showErrorHandled(err);
      
    });
  }
}
