export class ContractListItem {
    // "contractId": "00830716-75a2-4049-9d87-32c7cfa265eb",
    // "title": "HD DV di dong tra truoc(chính th?c)",
    // "currentStage": "LC_DRAFT_UPDATE",
    // "created": "2020-06-29T00:00:00.000Z",
    // "modified": "2020-06-29T00:00:00.000Z",
    // "doiTac": "Công ty G",
    // "donViQuanLy": "Công ty G",
    // "nguoiPheDuyet": "Nguyễn EA",
    // "idNguoiPheDuyet": "3e5162f3-7fe9-11ea-8652-27e370aa8cdd"

    contractId: string = '';
    title: string = '';
    currentStage: string = '';
    created: string = '';
    modified: string = '';
    doiTac: string = '';
    donViQuanLy: string = '';
    nguoiPheDuyet: string = '';
    idNguoiPheDuyet: string = '';
    loaiKhachHang: string = '';
    isShow: false;
    partners: PartnerItem[] = [];
    readyforcancel: any = 0;
    readyfordeal: any = 0;
    readyfordelete: any = 0;
    readyfordeny: any = 0;
    readyforsign: any = 0;
    readyforupdatecontent: any = 0;
    readyforreturn: any = 0;
    signerEmail: string = "";
    readyfordealInternal: any = 0;
    attachment: number = 0;
    isVerificated: number = 0;
    contractNumber = "";
    selected = false;
    sizeFile: number = 0;
    ownerPartyId: string = '';

    constructor(data = null) {
        data = data || {};
        this.contractId = data.contractId || '';
        this.title = data.title || '';
        this.currentStage = data.currentStage || '';
        this.created = data.created || '';
        this.modified = data.modified || '';
        this.doiTac = data.doiTac || '';
        this.donViQuanLy = data.donViQuanLy || '';
        this.nguoiPheDuyet = data.nguoiPheDuyet || '';
        this.idNguoiPheDuyet = data.idNguoiPheDuyet || '';
        this.loaiKhachHang = data.loaiKhachHang || '';
        this.readyforsign = Number(data.readyforsign);
        this.isShow = data.isShow || false;
        this.selected= data.selected || false;
        this.sizeFile= data.sizeFile||0;
        if (data.partner && data.partner instanceof Array) {
            data.partner.forEach((item) => {
                this.partners.push(new PartnerItem(item));
            });
        }

        this.readyforcancel = data.readyforcancel;
        this.readyfordeal = data.readyfordeal;
        this.readyfordelete = data.readyfordelete;
        this.readyfordeny = data.readyfordeny;
        this.readyforsign = data.readyforsign;
        this.readyforupdatecontent = data.readyforupdatecontent;
        this.readyforreturn = data.readyforreturn;

        this.signerEmail = data.signerEmail || "";
        this.readyfordealInternal = data.readyfordealInternal;

        this.attachment = data.attachment || 0;
        this.isVerificated = data.isVerificated || 0;
        this.contractNumber = data.contractNumber || "";
        this.ownerPartyId = data.ownerPartyId || '';
    }
}

export class PartnerItem {
    partyId: string = '';
    doiTac: string = '';
    donViQuanLy: string = '';
    email: string = '';
    modified: string = '';
    sdt: string = '';
    maSoThue: string = '';
    loaiKhachHang: string = '';
    cmnd: string = '';

    constructor(data = null) {
        data = data || {};
        this.partyId = data.partyId || '';
        this.doiTac = data.doiTac || '';
        this.donViQuanLy = data.donViQuanLy || '';
        this.email = data.email || '';
        this.modified = data.modified || '';
        this.sdt = data.sdt || '';
        this.maSoThue = data.maSoThue || '';
        this.loaiKhachHang = data.loaiKhachHang || '';
        this.cmnd = data.cmnd || '';
    }
}