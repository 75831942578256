import { Injectable } from '@angular/core';
import { ServicePath } from 'src/app/utils/common/constant-service-api';
import { API } from '../../api';
const REQUEST_DOWNLOAD =
  ServicePath.MANAGEMENT_SERVICE + '/contracts/request-download';
const REQUEST_DOWNLOAD_CONTRACT =
  ServicePath.MANAGEMENT_SERVICE + '/contracts/request-download-contract';
const RUN_JOB_DOWNLOAD_CECA = ServicePath.ESIGNATURE_SERVICE + '/api';
const DETAIL_CONTRACT_DOWNLOAD_JOB = ServicePath.ESIGNATURE_SERVICE + '/api';
@Injectable({
  providedIn: 'root',
})
export class ReportCecaService {
  constructor(private api: API) {}
  requestDownload(data) {
    const url = REQUEST_DOWNLOAD;
    return this.api.post(url, data);
  }
  requestDownloadCOntract(
    page = 1,
    maxSize = 10,
    sort = 'DESC',
    propertiesSort
  ) {
    const url =
      REQUEST_DOWNLOAD_CONTRACT +
      `?page=${page}&maxSize=${maxSize}&sort=${sort}&propertiesSort=${propertiesSort}`;
    return this.api.get(url);
  }
  runJobDownload(requestId, partyId) {
    const url =
      RUN_JOB_DOWNLOAD_CECA +
      `/${requestId}/v1/download-contract-job?partyId=${partyId}`;
    return this.api.post(url, '');
  }
  getDetailContractDownloadJob(requestId, partyId) {
    const url =
      DETAIL_CONTRACT_DOWNLOAD_JOB +
      `/${requestId}/v1/contract-download-job-info?partyId=${partyId}`;
    return this.api.get(url);
  }
}
