
export class CustomerInfo {
    id: string;
    tenDN : string;
    tenVietTat : string;
    mst: string;
    file: File;
    ngayDK : string;
    nguoiPheDuyet : string;
    ngayPheDuyet: string;
    userInfo: UserInfo;
    partyId: string = "";

    constructor(obj = null) {
        this.id = obj.id || '';
        this.tenDN = obj.tenToChuc || '';
        this.tenVietTat = obj.tenRutGon || '';
        this.mst = obj.maSoThue || '';
        this.file = obj.gpkdFilename || '';
        this.ngayDK = obj.created? this.convertToDateObj(obj.created) : '';
        this.nguoiPheDuyet = obj.approverName || '';
        this.ngayPheDuyet = obj.approveDate? this.convertToDateObj(obj.approveDate) : '';
        this.userInfo = new UserInfo(obj.userInformations || {});
        this.partyId = obj.partyId || "";
    }

    convertToDateObj(dataString) {
        let d = new Date(dataString);
        let localTiming = d.toLocaleDateString('en-GB');
        return localTiming;
      }
}



export class UserInfo {
    id: string;
    hoTen : string;
    email : string;
    sdt: string;
    ngaySinh : string;
    gioiTinh: string;
    diaChi : string;
    huyen: string;
    tinh: string;
    xa: string;

    constructor(obj = null) {
        this.id = obj.id || '';
        this.hoTen = obj.ten || '';
        this.email = obj.email || '';
        this.sdt = obj.sdt || '';
        this.ngaySinh = obj.ngaySinh?this.convertToDateObj(obj.ngaySinh) : '';
        this.gioiTinh = obj.gioiTinh || '';
        this.huyen = obj.huyen || '';
        this.tinh = obj.tinh || '';
        this.xa = obj.xa || '';
        this.diaChi = this.xa + ', ' + this.huyen + ', ' + this.tinh;

    }

    convertToDateObj(dataString) {
        let d = new Date(dataString);
        let localTiming = d.toLocaleDateString('en-GB');
        return localTiming;
      }
}

