import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertBtn, ConstantAlertMsg } from 'src/app/utils/common/constant-alert-msg';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { AuthService } from 'src/app/utils/services/auth.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  partyId = this.authService.partyId;
  notifyTypes = [];
  notiType = '';
  prevNotiType: any;

  constructor(private myAlert: AlertControl, private authService: AuthService) {}

  ngOnInit(): void {
    this.authService.getThongTinToChuc().subscribe(
      (res: any) => {
        if (res && res.object.notifyPushType instanceof Array) {
          this.notifyTypes = res.object.notifyPushType;
        }
        if (this.notifyTypes.includes("EMAIL") && this.notifyTypes.includes("SMS")) {
          this.notiType = "BOTH";
        } else {
          this.notiType = this.notifyTypes[0] ?? "";
        }
      },
      (error) => console.log(error)
    );
  }
  selectMethod(type: any) {
    this.prevNotiType = this.notiType;
    if (type === 'sms') {
      this.myAlert.showAlert(
        '<p>Số lượt SMS của bạn thấp hơn mức tối thiểu. Bạn chắc chắn muốn sử dụng hình thức thông báo cho khách hàng qua kênh tin nhắn SMS?</p><em class="text-secondary mt-2">Khi số lượt SMS của bạn đã hết, khách hàng của bạn sẽ không nhận được thông báo từ hệ thống.</em> ',
        ConstantAlertType.BLANK,
        true,
        '',
        'Nhận thông báo qua SMS',
        () => {
          this.pushNotify(this.partyId, { notifyPushType: ['SMS'] });
        },
        '',
        'Đóng',
        () => {
          this.notiType = this.prevNotiType;
        },
        'Xác nhận',
        null,
        ()=>{},
        () => {
          console.log('alo');

          this.notiType = this.prevNotiType;
        }
      );
    }
    if (type === 'email') {
      this.myAlert.showAlert(
        '<p>Một số khách hàng của bạn chưa có thông tin email. Bạn chắc chắn muốn sử dụng hình thức thông báo cho khách hàng qua kênh email?</p><em class="text-secondary mt-2">Những khách hàng chưa có thông tin email sẽ không nhận được thông báo từ hệ thống</em> ',
        ConstantAlertType.BLANK,
        true,
        '',
        'Nhận thông báo qua email',
        () => {
          this.pushNotify(this.partyId, { notifyPushType: ['EMAIL'] });
        },
        '',
        'Đóng',
        () => {
          this.notiType = this.prevNotiType;
        },
        'Xác nhận',
        null,
        ()=>{},
        () => {
          console.log('alo');

          this.notiType = this.prevNotiType;
        }
      );
    }
    if (type === 'both') {
      this.myAlert.showAlert(
        'Bạn chắc chắn muốn sử dụng hình thức thông báo cho khách hàng qua kênh email và sms?',
        ConstantAlertType.BLANK,
        true,
        '',
        'Nhận thông báo qua email và sms',
        () => {
          this.pushNotify(this.partyId, { notifyPushType: ['EMAIL', 'SMS'] });
        },
        '',
        'Đóng',
        () => {
          this.notiType = this.prevNotiType;
        },
        'Xác nhận',
        null,
        ()=>{},
        () => {
          console.log('alo');

          this.notiType = this.prevNotiType;
        }
      );
    }
  }
  pushNotify(id, type) {
    this.authService.pushNotify(id, type).subscribe(
      (res: any) => {
        this.myAlert.showAlertOnlyNoti(
          'Cập nhật cấu hình thông báo thành công !',
          ConstantAlertType.BLANK,
          '',
          'Đóng',
          () => {},
          'Thông báo',
          {},
          () => {}
        );
      },
      (error) => {
        this.myAlert.showErrorHandled(error);
        this.notiType = this.prevNotiType;
      }
    );
  }
}
