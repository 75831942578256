import { Component, OnInit,ViewChild,ElementRef,Directive } from '@angular/core';
import { Options } from 'select2';
import { Select2OptionData } from 'ng-select2';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { PermissionGroup } from 'src/app/utils/models/aas-models/business-package-manager/business-package-list/list-all-permission/permission-group'
import { ListAllPermisionReponse } from 'src/app/utils/models/aas-models/business-package-manager/business-package-list/list-all-permission/list-all-permission-response';
import { DualListItem } from 'src/app/utils/models/aas-models/business-package-manager/business-package-list/duaList-item.model'
import { StringUtils } from 'src/app/utils/common/StringUtils';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { share } from 'rxjs/operators';
import { NgModalComponent } from 'src/app/layout/extensions/ng-modal/ng-modal.component';
import { LoadingService } from 'src/app/utils/services/loading.service';
import { BusinessPackageService } from 'src/app/utils/services/aas-network/business-package.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { CategoryPackageItem } from 'src/app/utils/models/aas-models/business-package-manager/business-package-list/category-package-manager/category-package-item.model'
import { ListCategoryPackageResponse } from 'src/app/utils/models/aas-models/business-package-manager/business-package-list/category-package-manager/list-category-package-response.model'
import { ListTemplateContractResponse } from 'src/app/utils/models/aas-models/business-package-manager/business-package-list/template-contract/list-template-contract-response.model'
import { TemplateContract } from 'src/app/utils/models/aas-models/business-package-manager/business-package-list/template-contract/template-contract-item.model';
import { BusinessPackageItem,PackageService } from 'src/app/utils/models/aas-models/business-package-manager/business-package-list/business-package-item.model'
import { ListPackageOrderResponse } from 'src/app/utils/models/aas-models/business-package-manager/business-package-list/list-package-in-order-response.model'
import { ActivatedRoute, Router } from '@angular/router';
import { PackageObjectResponse } from 'src/app/utils/models/aas-models/business-package-manager/business-package-list/business-package-object-response.model'
import {  REGEXP_COST, REGEXP_EMAIL,REGEXP_MST,REGEXP_PHONE_NUMBER } from 'src/app/utils/common/regexp';
import { PACKAGE_MANAGER } from 'src/app/utils/common/constant-vaildate-msg';
import { constants } from 'buffer';

declare var Cleave: any;

@Component({
  selector: 'app-update-service-package',
  templateUrl: './update-service-package.component.html',
  styleUrls: ['./update-service-package.component.scss']
})
export class UpdateServicePackageComponent implements OnInit {
  packInfoId = this.router.snapshot.paramMap.get('id');
  @ViewChild('searchNotAssigned') searchNotAssigned: ElementRef;
  @ViewChild('searchAssigned') searchAssigned: ElementRef;
  @ViewChild('showInfoPackageOrder') showPackageOrder: ElementRef;

  dropListStatus: Array<Select2OptionData> = [
    {
      id: 'ENABLED',
      text: 'Đang hoạt động'
    },
    {
      id: 'DISABLED',
      text: 'Ngưng hoạt động'
    }
  ];

  dropListsUserType:  Array<Select2OptionData> = [
    {
      id: 'CONSUMER',
      text: 'Cá nhân'
    },
    {
      id: 'BUSINESS',
      text: 'Tổ chức dịch vụ'
    },
    {
      id: 'ENTERPRISE',
      text: 'Tổ chức giải pháp'
    },
    
  ];

  dropListContractTemplate: Observable<Array<Select2OptionData>>;

  listdua : Array<any> =[]
  form: FormGroup;

  page = 1;
  maxSize = 5;
  search = "";
  totalElement = 0;
  propertiesSort = "";
  sort = "";
  loadingServiceIsVisiable = new BehaviorSubject<boolean>(false);
  loadingListUserGroup = new BehaviorSubject<boolean>(false);
  loadingForm = new BehaviorSubject<boolean>(false);

  listPermissionGroup: PermissionGroup[] = [];
  listAssigned: PermissionGroup[] = [];

  formSearch: FormGroup;

  dualList1: DualListItem[] = [];
  dualList2: DualListItem[] = [];
  selectedAll1: boolean = false;
  selectedAll2: boolean = false;

  baseList: DualListItem[] = new Array<DualListItem>();
  dualList: DualListItem[][] = [new Array<DualListItem>(), new Array<DualListItem>()];   

  oriList: DualListItem[][] = [new Array<DualListItem>(), new Array<DualListItem>()];
  selectedAll = [false, false];
  filter = [false, false];
  assignedAll = [false, false]


  selectAllItem = false;
  selectChild = false;

  public optionUserType: Options;
  public options: Options;

  validateMsg = PACKAGE_MANAGER;
  isSubmit: boolean = false;

  listCategoryPackageRes = new ListCategoryPackageResponse()
  listCategoryPackage : CategoryPackageItem[] = []
  businesPackageItem : CategoryPackageItem

  listTemplateContractRes : ListTemplateContractResponse;
  listTemplateContract : TemplateContract[] = []

  listBusinessPackageRes = new ListPackageOrderResponse()
  listBusinesPackage : BusinessPackageItem[] = []
  packageItem : BusinessPackageItem = new BusinessPackageItem();
  packageObjectResponse:  PackageObjectResponse;

  isSelectPackage = false;

  optionsCleaveCost = {
    numeral: true,
    numeralThousandsGroupStyle: 'thousand',
  };  

  formInfra: FormGroup;
  listFieldInfra: CategoryPackageItem[] = [];
  validateMsgInfra: any = {};
  constructor(
    private fb: FormBuilder,
    private loadingService: LoadingService,
    private modalService: NgbModal,
    private packageService: BusinessPackageService,
    private router: ActivatedRoute,
    private route: Router,
  ) { }

  ngOnInit(): void {
    this.makeForm()
    this.getAllTemplateContract();
    this.getPackage()
    this.options = {
      multiple: false,
      width: '100%',
      closeOnSelect: true,
      language: 'vi',
    };
  }

  makeForm() {
    this.form = this.fb.group({
      name : ['',[Validators.required]],
      description: ['',[Validators.required]],
      status: ['ENABLED',[Validators.required]],
      price: ['',[Validators.required]],
      packageType: [''],
      duration: ['',[Validators.pattern(REGEXP_COST)]],
      contractNumber: ['',[Validators.pattern(REGEXP_COST)]],
      userType: ['', Validators.required],
      formContractId: [''],
      orderNumber: ['',[Validators.pattern(REGEXP_COST)]],
      userAmount: ['',[Validators.pattern(REGEXP_COST)]],
      capacity: ['',[Validators.pattern(REGEXP_COST)]],
    })
  }

  get name() {
    return this.form.get('name');
  }
  get price() {
    return this.form.get('price');
  }
  get status() {
    return this.form.get('status');
  }
  get description() {
    return this.form.get('description');
  }
  get packageType() {
    return this.form.get('packageType');
  }
  get duration() {
    return this.form.get('duration');
  }
  get contractNumber() {
    return this.form.get('contractNumber');
  }

  get userType(){
    return this.form.get('userType')
  }

  get formContractId(){
    return this.form.get('formContractId')
  }
  get userAmount(){
    return this.form.get('userAmount')
  }
  get capacity(){
    return this.form.get('capacity')
  }

  get orderNumber(){
    return this.form.get('orderNumber')
  }

  getPackage(){
    var data: ListAllPermisionReponse;
    this.packageService.getPackage(this.packInfoId).subscribe((res)=>{
      this.packageObjectResponse = new PackageObjectResponse(res);
      this.packageItem = this.packageObjectResponse.object
      console.log(this.packageItem);
      this.form.patchValue({
        "name" : this.packageItem.name,
        "price": this.packageItem.price,
        "status": this.packageItem.status,
        "description": this.packageItem.description,
        "packageType": this.packageItem.packageType === "TRIAL_TYPE"? true: false,
        "duration": this.packageItem.duration,
        "contractNumber": this.packageItem.contractNumber,
        "userType": this.packageItem.userType,
        "formContractId": this.packageItem.formContractId,
        "orderNumber": this.packageItem.orderNumber,
        "capacity": this.packageItem.capacity,
        "userAmount": this.packageItem.userAmount,
      })
      this.isSelectPackage = this.packageItem.packageType === "TRIAL_TYPE"? true:false;
      // new Cleave('#currencyValue', {
      //   numeral: true,
      //   numeralThousandsGroupStyle: 'thousand',
      // })
      //Gan lai du lieu cu vao form
      let group = {};
      console.log(this.packageItem.listPackageInfrastructure);
      this.packageItem.listPackageInfrastructure.forEach((item) => {
        group[item.serviceInfoId] = new FormControl(item.value, Validators.required)
        this.validateMsgInfra[item.serviceInfoId] = {
          required: "Vui lòng nhập thông tin " + item.name + "!"
        }
      });
      this.formInfra = this.fb.group(group);
      this.loadingForm.next(false);
      this.getAllPrivileges(this.packageItem.userType)
      // if(this.packageItem.userType === "ENTERPRISE"){
      //   console.log(this.packageItem.listPackageService, '=========this.packageItem.listPackageService')
      //   this.setupDualList(this.packageItem.listPackageService);
      // }
    }, err => {
      this.loadingForm.next(false);
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.AUTH_SERVICE);
    }, () => {
      this.loadingForm.next(false);
    });
  }

  deletePackage(id){
    this.modal('Bạn có chắc chắn xóa gói dịch vụ này?', ()=>{
      this.packageService.deletePackage(id).subscribe((res)=>{
        console.log(res);
        this.alert('Xóa dịch vụ thành công!','success', false, '', 'OK', ()=>{
          this.route.navigate(['/console/service-package-manager/list']);
        })
      }, (err) => {
        console.log(err);
       this.handleError(err, ResponseMsg.MESSAGES.ALL_IN_ONE)
      })
    })
   
  }


  getAllTemplateContract(){
    this.packageService.getAllTemplateContract().subscribe(res=>{
      this.listTemplateContractRes = new ListTemplateContractResponse(res['object']);
      this.dropListContractTemplate = this.makeSeriesData()
    })
  }

  makeSeriesData(): Observable<Array<Select2OptionData>> {
    let data: Array<Select2OptionData> = [];
    if (this.listTemplateContractRes.data && this.listTemplateContractRes.data instanceof Array) {
      this.listTemplateContractRes.data.forEach((item) => {
        data.push({ id: item.id.toString(), text: item.templateName });
      });
    }
    return Observable.create((obs) => {
      obs.next(data);
      obs.complete();
    });
  }


  loadingListUserGroupIsVisiable(): Observable<boolean> {
    return this.loadingListUserGroup.asObservable().pipe(share());
  }

  loadingFormIsVisiable(): Observable<boolean> {
    return this.loadingForm.asObservable().pipe(share());
  }

  loadingIsVisiable(): Observable<boolean> {
    return this.loadingServiceIsVisiable.asObservable().pipe(share());
  }

  //MARK: Chức năng danh sách, tìm kiếm nhóm người dùng
  /**
   * Tạo from tìm kiếm
   */
  makeFormSearch() {
    this.formSearch = this.fb.group({
      search: new FormControl('')
    });
  }

  get keySearch() { return this.formSearch.get('search'); }

  /**
   * EventEmitter: Sự kiện nhập key trên form search
   * @param event 
   */
  eventInputSearch(event) {
  }
  
  //MARK: Chức năng gán quyền DualList
  setupDualList(listAssigned: PackageService[]) {
    this.dualList[0] = this.cloneObject(this.baseList);
    this.dualList[1] = [];
    this.filter = [false, false];
    this.selectedAll = [false, false];
    
    this.backupDualList();

    listAssigned.forEach((item) => {
      const indexGroup = this.dualList[0].findIndex(res => { return res.id === item.id });
      if(indexGroup >= 0)
      {
        this.selectedParentItem(indexGroup, 0);
      }
    });
    this.assignMoreSelected(0, 1);
  }

  /**
   * Set base list tất cả các quyền
   */
  setBaseList() {
    this.baseList = new Array<DualListItem>()
    this.listPermissionGroup.forEach((item, index) => {
      this.baseList.push(new DualListItem({
        id: item.id,
        name: item.name,
        index: index
      }))
      this.baseList[index].child = [];

      item.privileges.forEach((item1, index1) => {
        this.baseList[index].child.push(new DualListItem({
          id: item1.id,
          name: item1.description,
          index: index1
        }))
      })
    });
  }

  selectedParentItem(indexGroup, indexList, manualSet = false, defaultValue = false) {
    //show treeview
    console.log("clicked: ", this.dualList[indexList][indexGroup].selected);
    this.selectAllItem = true
    // this.dualList[indexList][indexGroup].showChild = true;
    if (manualSet) {
      this.dualList[indexList][indexGroup].selected = defaultValue;
    } else {
      console.log("inside");
      this.dualList[indexList][indexGroup].selected = !this.dualList[indexList][indexGroup].selected;
      console.log("clicked: ", this.dualList[indexList][indexGroup].selected);
    }

    this.dualList[indexList][indexGroup].child.forEach((item, index) => {
      item.selected = this.dualList[indexList][indexGroup].selected;
    });
    if (!this.dualList[indexList][indexGroup].selected) 
    {
      this.selectedAll[indexList] = false;
      this.selectAllItem = false;
    }
    this.resetStatusItem(indexGroup, indexList);
    console.log("clicked: ", this.dualList[indexList][indexGroup].selected);
  }

  selectedChildItem(indexGroup, indexChild, indexList) {
    this.dualList[indexList][indexGroup].child[indexChild].selected = !this.dualList[indexList][indexGroup].child[indexChild].selected;
    if (!this.dualList[indexList][indexGroup].child[indexChild].selected) 
    {
      this.selectedAll[indexList] = false;
      this.selectAllItem = false;
      this.selectChild = true;
    }
    this.resetStatusItem(indexGroup, indexList);
  }

  selectAllBtn(indexList) {
    this.selectedAll[indexList] = !this.selectedAll[indexList];
    this.dualList[indexList].forEach((item, index) => {
      item.selected = this.selectedAll[indexList];
      item.child.forEach((x, index) => {
        x.selected = this.selectedAll[indexList];
      });
    })

    console.log(this.dualList)
  }

  get countAssigned() {
    var count = 0;
    this.dualList[1].forEach((a, i) => {
      a.child.forEach((b, j) => {
        count++;
      });
    });
    return count;
  }

  get countNotAssigned() {
    var count = 0;
    this.dualList[0].forEach((a, i) => {
      a.child.forEach((b, j) => {
        count++;
      });
    });
    return count;
  }

  assign(indexGroup, indexChild, from, to) {
    const parent = this.dualList[from][indexGroup];
    const child = parent.child[indexChild];

    const iParent = this.dualList[to].findIndex(res => {
      return res.id === parent.id;
    })
   
    if(from == 0){
      if (iParent >= 0) {
        child.selected = true;
        const iChild = this.dualList[to][iParent].child.findIndex(res => {
          return res.id === child.id;
        })
        if(iChild < 0){
          this.dualList[to][iParent].child.push(child);          
          this.dualList[from][indexGroup].child[indexChild].assigned = true;
          this.dualList[from][indexGroup].child[indexChild].selected = true;        
        }
        else{           
          this.dualList[from][indexGroup].child[indexChild].assigned = true;
          this.dualList[from][indexGroup].child[indexChild].selected = true;    
        }
      } else {
        this.dualList[to].push(new DualListItem({
          id: parent.id,
          name: parent.name,
          index: parent.index,
          showChild: parent.showChild,
        }));

        this.dualList[to][this.dualList[to].length - 1].child.push(child);        
        this.dualList[from][indexGroup].child[indexChild].assigned = true;
        this.dualList[from][indexGroup].child[indexChild].selected = true;    
      }   
      let item = this.dualList[from][indexGroup].child;
      let numSelected = 0;
      let numAssigned = 0;
      for (var i = item.length - 1; i >= 0; i--) {
        if(this.dualList[from][indexGroup].child[indexChild].selected == true)
        {
          numSelected ++;
        }
        if(this.dualList[from][indexGroup].child[indexChild].assigned == false)
        {
          numAssigned ++;
        }
      }    
      if(numSelected == 0)  
      {
        this.dualList[from][indexGroup].selected = false;
      }
      if(numAssigned == 0)  
      {        
        this.dualList[from][indexGroup].assigned = true;
      } 
    }

   this.logAssigned(parent.id, child.id, from, to);
    
    if(from == 1)
    {  
      this.selectedAll[to] = false    
      const checkGroupID = this.dualList[to].findIndex(res => {
        return res.id === this.dualList[from][indexGroup].id;
      })
      const checkChildID = this.dualList[to][checkGroupID].child.findIndex(res => {
        return res.id === this.dualList[from][indexGroup].child[indexChild].id;
      })
      if((checkGroupID >= 0) && (checkChildID >= 0))
      {
        this.dualList[to][checkGroupID].child[checkChildID].assigned = false;
        this.dualList[to][checkGroupID].child[checkChildID].selected = false;
        this.dualList[from][indexGroup].child.splice(indexChild, 1);
        this.dualList[to][checkGroupID].assigned = false;
        // this.dualList[to][checkGroupID].selected = false;
        if (this.dualList[from][indexGroup].child.length <= 0) {
          this.dualList[from].splice(indexGroup, 1);   
          this.dualList[to][checkGroupID].assigned = false;
          this.dualList[to][checkGroupID].selected = false;
        }
      }
    }
    this.sortList();
  }

  assignMoreSelected(from, to) {
    for (var i = this.dualList[from].length - 1; i >= 0; i--) {
      let item = this.dualList[from][i];
      if (item.selected && !item.assigned) {
        if(from == 0){
          this.dualList[to].push(new DualListItem({
            id: item.id,
            name: item.name,
            index: item.index
          }))
          item.assigned = true;
          this.oriList[to].push(new DualListItem({
            id: item.id,
            name: item.name,
            index: item.index
          }))

        }else{
          item.assigned = false;
          item.selected = false;
          this.dualList[from].splice(i,1)
          this.oriList[from].splice(i,1)
        }
      }

    }

    // this.sortList();
  }

  assignGroup(indexGroup, from, to) {
    let item = this.dualList[from][indexGroup];
    item.selected = true;

    if(from == 0)
    {
      this.dualList[to].push(new DualListItem({
        id: item.id,
        name: item.name,
        index: item.index
      }))
      item.assigned = true;
      this.oriList[to].push(new DualListItem({
        id: item.id,
        name: item.name,
        index: item.index
      }))
    }
    else{
      this.dualList[from].splice(indexGroup,1)
      this.oriList[from].splice(indexGroup,1)
      this.dualList[to][item.index].assigned = false;
      this.dualList[to][item.index].selected = false;
      this.selectedAll[to] = false;
    }
    this.resetStatusItem(indexGroup, 0)
  }

  logAssigned(idGroup, idChild, from, to) {
    console.log("log Assign => idGroup: ", idGroup, "; idChild: ", idChild);
    const indexParent = this.oriList[from].findIndex(res => { return res.id === idGroup; });
    const indexChild = this.oriList[from][indexParent].child.findIndex(res => { return res.id === idChild; });
    const parent = this.oriList[from][indexParent];
    const child = parent.child[indexChild];
    const iParent = this.oriList[to].findIndex(res => { return res.id === idGroup; })

    if (iParent >= 0) {
      child.selected = false;
      this.oriList[to][iParent].child.push(child);
    } else {
      this.oriList[to].push(new DualListItem({
        id: parent.id,
        name: parent.name,
        index: parent.index
      }));

      child.selected = false;
      this.oriList[to][this.oriList[to].length - 1].child.push(child);
    }

    this.sortList();
    console.log("ORI AFTER ASSIGNED: ", this.oriList);
  }

  resetStatusItem(indexGroup, indexList) {
    let ok = false;
    if (this.dualList[indexList][indexGroup].child.length > 0) {
      this.dualList[indexList][indexGroup].child.forEach((item, index) => {
        if (item.selected) ok = true;
      });
      this.dualList[indexList][indexGroup].selected = ok;
    }

    ok = true;
    this.dualList[indexList].forEach((item, index) => {
      if (!item.selected) ok = false;
      item.child.forEach((x, index) => {
        if (!x.selected) ok = false;
      });
    })

    this.selectedAll[indexList] = ok;
  }

  sortList() {
    this.dualList.forEach((item, index) => {
      item.sort((a, b) => {
        return a.index - b.index;
      });

      item.forEach((x, y) => {
        x.child.sort((a, b) => {
          return a.index - b.index;
        });
      })
    })

    this.oriList.forEach((item, index) => {
      item.sort((a, b) => {
        return a.index - b.index;
      });

      item.forEach((x, y) => {
        x.child.sort((a, b) => {
          return a.index - b.index;
        });
      })
    })
  }

  backupDualList() {
    this.oriList = this.cloneObject(this.dualList);
  }

  cloneObject(object: any) {
    return JSON.parse(JSON.stringify(object));
  }

  enableBtnAssign(indexList): boolean {
    var disable = true;
    this.dualList[indexList].forEach((item) => {
      if (item.selected) disable = false;
    })
    return disable
  }

  filterSearch($event, indexList) {
    let value = StringUtils.removeAccents($event.target.value);
    let filter = [];
    this.filter[indexList] = $event.target.value !== "";
    let self = this;
    console.log(this.oriList[indexList], '==========this.oriList[indexList]')

    if (this.filter[indexList]) {
      this.oriList[indexList].forEach(function (x) {
        let parentOK = false;
        let childOK = false;
        let tmp = self.cloneObject(x);

        let tmp_name = StringUtils.removeAccents(tmp.name);
        if (tmp_name.toUpperCase().match(new RegExp(value.toUpperCase(), "g"))) {
          parentOK = true;
        };

        for (var i = tmp.child.length - 1; i >= 0; i--) {
          let tmp_child_name = StringUtils.removeAccents(tmp.child[i].name);
          if (!(tmp_child_name.toUpperCase().match(new RegExp(value.toUpperCase(), "g")))) {
            tmp.child.splice(i, 1);
          } else {
            childOK = true;
          }
        }
        if (parentOK && !childOK) filter.push(self.cloneObject(x));
        if (parentOK && childOK) filter.push(tmp);
        if (!parentOK && childOK) filter.push(tmp);
      });
      this.dualList[indexList] = this.cloneObject(filter);
    } else {
      this.dualList[indexList] = this.cloneObject(this.oriList[indexList]);
    }

    console.log("-------------------------------------");
    console.log("searched: ", filter);
    console.log("filtered ori: ", this.oriList);
    console.log("filter array: ", this.dualList);
    console.log("-------------------------------------");
  }

  showHideTreeView($event, indexParent, indexList) {
    $event.stopPropagation();
    this.dualList[indexList][indexParent].showChild = !this.dualList[indexList][indexParent].showChild
  }


  /**
   * Tạo param cập nhật quyền cho nhóm người dùng
   * @param id 
   * @param listRole 
   */
  makeParamUpdate( listRole: DualListItem[] = []) {
    var listPackageService: any[] = [];

    console.log(listRole);

    listRole.forEach((a, i) => {
        let data = {
          serviceInfoId : a.id
        }
        listPackageService.push(data)
    }
    
    );
    return listPackageService;
  }



  /**
   * Action lưu thông tin cập nhật
   */
  actSaveInfo() {
    let data = this.form.value
    this.modal("Bạn chắc chắn muốn khởi tạo gói dịch vụ này?", () => {
      console.log(this.dualList[1], 'vvvvvvvvvvvvv')
      var listPackageService = this.makeParamUpdate(this.dualList[1]);
      var listPackageInfrastructure = [];
      this.listFieldInfra.forEach((item) => {
        if (this.formInfra.controls[item.serviceInfoId]) {
          listPackageInfrastructure.push({
            "serviceInfoId": item.serviceInfoId,
            "value": this.formInfra.controls[item.serviceInfoId].value
          })
        }
      });
      var param = 
      {
        "packageInfo" : {
        "name" : data.name,
        "description": data.description,
        "status": data.status,
        "price" : data.price ? Number(data.price) : 0,
        "packageType": data.packageType? 'TRIAL_TYPE':'',
        "duration":  data.duration ? Number(data.duration) : 0,
        "contractNumber": data.contractNumber ? Number(data.contractNumber) : 0,
        "userType": data.userType,
        "formContractId": data.formContractId,
        "userAmount": data.userAmount? Number(data.userAmount) : 0,
        "capacity": data.capacity ? Number(data.capacity) : 0,
        "orderNumber": data.orderNumber? Number(data.orderNumber) : 0,
      },
      "listPackageService" : listPackageService,
      "listPackageInfrastructure": listPackageInfrastructure
    }
      console.log(param, '===========')

      if(this.form.valid && this.formInfra.valid && data.userType === "ENTERPRISE"){
       this.updatePackage(param)
      }
      else{
        this.updatePackage(param)
      }
    });
  }


  convertCurency(value: any) {
    let curency: any
    value = value.replace(/,/g, '')
    curency = value.replace(/VNĐ/g, '')
    curency = Math.abs(curency);
    return curency;
  } 

  openModal(){
    this.openModalShowInfo(this.showPackageOrder)
  }

  openModalShowInfo(content) {
    if(this.form.value.orderNumber != "" && this.form.value.orderNumber != null ){
      this.getListPackageInOrder()
      this.modalService.open(content, {
        size: 'md',
        backdrop: 'static',
        keyboard: false
      });
    }
    
  }

  getListPackageInOrder(){
    this.packageService.getListPackageInOrder(this.userType.value).subscribe((res)=>{
        this.listBusinessPackageRes = new ListPackageOrderResponse(res['object']);
        this.listBusinesPackage = this.listBusinessPackageRes.data
      })

  }

  // hủy dữ liệu vừa chọn
  clearData()
  { let msg = `Bạn chắc chắn muốn hủy phiên khởi tạo của dịch vụ này? <br/> (Toàn bộ thông tin đã nhập sẽ bị mất hết)`;
    this.modal(msg,()=>{
      this.form.reset();
      this.form.patchValue({
        name : "",
        description: "",
        status: "ENABLED",
        price: "",
        packageType: "",
        duration: "",
        contractNumber: "",
        userType: "",
        formContractId: "",
        orderNumber: "",
        userAmount: "",
        capacity: "",
      })
      this.getPackage();
    })
  }

  
  changeUserType(type){
    this.getAllPrivileges(type)
  }

  changeCurrency(){
    // new Cleave('#currencyValue', {
    //   numeral: true,
    //   numeralThousandsGroupStyle: 'thousand',
    // })
  }

  selectPackageType(value){
    this.isSelectPackage = !this.isSelectPackage;
  }

  /**
   * Get danh sách tất cả các quyền
   */
  getAllPrivileges(type) {
    var data: ListAllPermisionReponse;
    this.dualList = [new Array<DualListItem>(), new Array<DualListItem>()];  
    if(type === 'CONSUMER' ||  type === 'BUSINESS'){
      this.packageService.getAllPrivileges().subscribe(res => {
        this.loadingServiceIsVisiable.next(false);
        data = new ListAllPermisionReponse(res);
        this.listPermissionGroup = data.object;
        this.setBaseList();
        // this.dualList[0] = this.cloneObject(this.baseList);
        // this.dualList[1] = [];
        // this.backupDualList()
        this.setupDualList(this.packageItem.listPackageService);
      }, err => {
        console.log(err);
        this.handleError(err, ResponseMsg.MESSAGES.AUTH_SERVICE);
      });
    }
    if(type === 'ENTERPRISE'){
      this.packageService.getAllCategoryPackage().subscribe(res=>{
        this.listCategoryPackageRes = new ListCategoryPackageResponse(res['object']);
        this.listCategoryPackage = this.listCategoryPackageRes.data;
        this.setBaseListCategory()
        // this.dualList[0] = this.cloneObject(this.baseList);
        // this.backupDualList()
        // this.dualList[1].forEach((item,i)=>{
        //   const indexGroup = this.dualList[0].findIndex(res => { return res.id === item.id })
        //   this.dualList[0][indexGroup].selected = true;
        //   this.dualList[0][indexGroup].assigned = true;
        // })
        this.setupDualList(this.packageItem.listPackageService);

        this.packageService.getAllCategoryPackage("HATANG").subscribe((res: any) => {
          let list = new ListCategoryPackageResponse(res?.object);
          this.listFieldInfra = list.data;
          this.setFormInfra(this.listFieldInfra);
        }, err => {
          console.log(err);
        })
      }, err => {
        console.log(err);
        this.handleError(err, ResponseMsg.MESSAGES.AUTH_SERVICE);
      })
    }
    
  }

  setBaseListCategory() {
    this.baseList = new Array<DualListItem>()
    this.listCategoryPackage.forEach((item, index) => {
      this.baseList.push(new DualListItem({
        id: item.serviceInfoId,
        name: item.name,
        index: index
      }))
    });
  }

  setFormInfra(data: CategoryPackageItem[]) {
    console.log(data);
    let group: any = this.formInfra.controls || {};
    data.forEach((item, index) => {
      if (!group[item.serviceInfoId]) {
        group[item.serviceInfoId] = new FormControl("", Validators.required)
        this.validateMsgInfra[item.serviceInfoId] = {
          required: "Vui lòng nhập thông tin " + item.name + "!"
        }
      }
    });

    this.formInfra = this.fb.group(group);
  }

  setDataInfra() {

  }

  isEmptyObj(obj: any) {
    return Object.keys(obj).length === 0;
  }

  get isUsing() {
    return this.packageItem.status === "USING";
  }

   /**
   * tạo gói cho nhóm người dùng
   * @param param 
   */
  updatePackage(param) {
    this.isSubmit = true;
    this.loadingForm.next(true);
    this.packageService.putUpdatePackage(param,this.packInfoId).subscribe(res => {
      this.loadingForm.next(false);
      this.alert("Cập nhật gói dịch vụ thành công!", 'success', false, '', 'OK', () => {
        this.isSubmit = false;
        this.route.navigate(['/console/service-package-manager/list'])
      });
      

    }, err => {
      this.loadingForm.next(false);
      this.handleError(err, ResponseMsg.MESSAGES.ALL_IN_ONE);
    }, () => {
      this.loadingForm.next(false);
    });
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], 'error');
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, 'error');
    } else {
      this.alert('Lỗi không xác định! Vui lòng thử lại', 'error');
    }
  }

  //MARK: Popup thông báo
  modal(msg, callBack = () => { }) {
    const modalRef = this.modalService.open(NgModalComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.callback = callBack;
  }

   //MARK: Popup thông báo
   alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }



}
