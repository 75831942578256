import { MainResponse } from '../../../main-response.model';
import { PaymentMethodItem } from './payment-method-item.model';


export class ListPaymentMethodResponse extends MainResponse {
    data: PaymentMethodItem[];
    constructor(obj = null) {
        super();
        obj = obj || {};
        this.data = [];
        if (obj.object && obj.object instanceof Array) {
            obj.object.forEach((item) => {
                this.data.push(new PaymentMethodItem(item));
            });
        }
    }

}