import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstLocalStorage, ContractStatus, SmartCAJobStatus } from 'src/app/utils/common/constant';
import { ConstantAlertBtn, ConstantAlertMsg } from 'src/app/utils/common/constant-alert-msg';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { DateUtils } from 'src/app/utils/common/DateUtils';
import { ImageProgress } from 'src/app/utils/common/image-progress';
import { REGEXP_DATE_INPUT } from 'src/app/utils/common/regexp';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { UtilsFunc } from 'src/app/utils/common/utils-func';
import { AasValidateMessage } from 'src/app/utils/common/validate-msg-aas';
import { localize } from 'src/app/utils/localize/localize';
import { ContractFileInfo } from 'src/app/utils/models/aas-models/contract/contract-file/contract-file.model';
import { ContractListItem } from 'src/app/utils/models/aas-models/contract/contract-list/contract-list-item.model';
import { ContractInSignJobItem } from 'src/app/utils/models/aas-models/contract/sign-multiple-contracts/contract-in-signjob-item';
import { ContractSignMultipleItem } from 'src/app/utils/models/aas-models/contract/sign-multiple-contracts/contract-sign-multiple-item';
import { DigitalSignatureItem } from 'src/app/utils/models/aas-models/signature/digital-signature/digital-signature-item';
import { DigitalSignatureResponse } from 'src/app/utils/models/aas-models/signature/digital-signature/digital-signature-response';
import { AasContractService } from 'src/app/utils/services/aas-network/aas-contract.service';
import { SignatureService } from 'src/app/utils/services/aas-network/signature.service';
import { AuthService } from 'src/app/utils/services/auth.service';
import { LoadingService } from 'src/app/utils/services/loading.service';
import { ContractTypeService } from '../../contract-type-management/services/contract-type.service';
import { SmartCaComponent } from '../layout/smart-ca/smart-ca.component';
import { SmartCaMultipleComponent } from './layout/smart-ca-multiple/smart-ca-multiple.component';

declare var PDFSign: any;
declare var default_img: any;
@Component({
  selector: 'app-sign-multiple-contracts',
  templateUrl: './sign-multiple-contracts.component.html',
  styleUrls: ['./sign-multiple-contracts.component.scss']
})
export class SignMultipleContractsComponent implements OnInit, OnDestroy {
  @ViewChild('fromDatePicker') _fromDate: ElementRef;
  @ViewChild('toDatePicker') _toDate: ElementRef;
  @ViewChild('popupsignjob') popupSignJob: ElementRef;  public smartCASign!: SmartCaMultipleComponent;
  @ViewChild('smartCASign', { static: false }) set AppSmartCASign(content: SmartCaMultipleComponent) {
    if (content) {
      this.smartCASign = content;
    }
  }
  
  signForm = this.activatedRoute.snapshot.paramMap.get('signForm');

  partyId: string = this.auth.partyId;
  userId: string = this.auth.userId;
  localize = localize;
  allowSignForm = ['IMAGE', 'SMARTCA'];
  steps = [
    { active: true, done: false },
    { active: false, done: false },
    { active: false, done: false },
    { active: false, done: false }
  ];
  currentStep = 0;

  formSearch: FormGroup;
  formFilter: FormGroup;
  isSubmit = false;
  dateType = {
    CREATED_FROM: 'CREATED_FROM',
    CREATED_TO: 'CREATED_TO'
  };
  validateSearch = {
    fromDate: {
      pattern: "ProfileConsumer_msg_4",
      minDate: 'ServiceContractListV2_validateSearch_date_2'
    },
    toDate: {
      pattern: "ProfileConsumer_msg_4"
    }
  };
  optionsCleave = {
    date: true,
    delimiter: '/',
    datePattern: ['d', 'm', 'Y']
  };
  currentDate = new Date();

  currentStage = ContractStatus;
  page: number = 1;
  maxSize: number = 10;
  sort: string = "DESC";
  propertiesSort: string = "modified";
  totalElement: number = 0;
  sortColConfig = {
    title: null,
    partnersCount: null,
    modified: false,
    currentStage: null,
  }
  listContract: Array<ContractSignMultipleItem> = [];
  isCheckAll = false;
  maxFileSelect = 50;

  // Check box điều khoản
  checkboxDk1 = false;
  checkboxDk2 = false;
  dieuKhoan = "";

  // Danh sách hợp đồng chuẩn bị ký
  _page: number = 1;
  _maxSize: number = 10;
  _sort: string = "DESC";
  _propertiesSort: string = "modified";
  _totalElement: number = 0;
  contractsSelected: Array<ContractSignMultipleItem> = [];
  contractsSelectedPagination: ContractSignMultipleItem[] = [];

  //mark: nhóm chức năng ký điện tử
  formDSign: FormGroup;
  isESignSubmit: boolean = false;
  validMsgESign = AasValidateMessage.E_SIGNATURE_CONFIG;
  imgESignContent: string = '';
  imgEsignConfig: string = "";
  inputImgESignLabel: string = 'ESign_ts_1';
  imgESign: any = null;
  //cert ký điện tử đã chọn
  eCertSelected: DigitalSignatureItem = new DigitalSignatureItem();
  imgType = ['image/jpeg', 'image/png', 'image/jpg'];

  // review sign box
  filePDF = null;
  showReviewSignBox = false;
  contractReview: ContractSignMultipleItem = null;

  inProcessSignJob = null;
  endSignJob = null;
  progressSignJobValue = 0;
  contractsInSignJob: ContractInSignJobItem[] = [];
  modalSignJob = null;
  intervalSignJob = null;

  /** SmartCA */
  showLayoutConfirmSmartCA = false;
  timeoutSmartCa = false;

  sessionId = "";
  __page: number = 1;
  __maxSize: number = 10;
  __sort: string = "DESC";
  __propertiesSort: string = "modified";
  __totalElement: number = 0;

  maxSizeFile = 250;

  constructor(
    private activatedRoute: ActivatedRoute,
    private myAlert: AlertControl,
    private fb: FormBuilder,
    private contractService: AasContractService,
    private auth: AuthService,
    private router: Router,
    private signatureService: SignatureService,
    private modal: NgbModal,
    private translate: TranslateService,
    private contractTypeService: ContractTypeService,
    private myLoader: LoadingService
  ) { }

  ngOnInit(): void {
    if (this.signForm == "IMAGE") {
      this.sessionId = localStorage.getItem(ConstLocalStorage.SESSION_SIGN_JOB) || "";
    } else if (this.signForm == "SMARTCA") {
      this.sessionId = localStorage.getItem(ConstLocalStorage.SESSION_SIGN_JOB_SMARTCA) || "";
    }

    if (!this.allowSignForm.includes(this.signForm)) {
      this.myAlert.showAlertNoClose(this.translate.instant('SignMultipleContracts_ts_1'), ConstantAlertType.ERROR, "", this.translate.instant('btn_ok'));
    } else {

    }

    this.makeFormSearch();
    this.makeFormFilter();
    this.checkSessionSignJob();
    this.loadData();
    this.lazyLoadDropListType();
  }

  ngOnDestroy(): void {
    clearInterval(this.intervalSignJob);
  }

  /**
   * Kiểm tra session ký hàng loạt
   */
  checkSessionSignJob() {
    if (this.signForm == "IMAGE") {
      /**
       * Đang chọn Ký ảnh hàng loạt
       */
      let sessionId = localStorage.getItem(ConstLocalStorage.SESSION_SIGN_JOB);
      if (sessionId == null || sessionId == undefined || sessionId == "") return;

      this.signatureService.getInfoSignJob(sessionId).subscribe((res: any) => {
        if (res?.object?.documentInfo && res?.object?.documentInfo instanceof Array) {
          this.contractsInSignJob = [];
          res?.object?.documentInfo.forEach((item: any) => {
            this.contractsInSignJob.push(new ContractInSignJobItem({
              contractId: item.documentId || "",
              title: item.signature?.documentName || "",
              inprogress: item.signature?.inprogress,
              error: item.signature?.error,
              signFlag: item.signature?.signFlag,
              multiSign: item.signature?.multiSign
            }));
          });
          
          if (this.countContractSigned == this.contractsInSignJob.length) {
          } else {
            this.showPopupSignJob();
            this.reloadCheckStatusSignJob();
          }
        }
      }, err => {
        console.log(err);
      })
    } else if (this.signForm == "SMARTCA") {
      /**
       * Đang chọn Ký SmartCA hàng loạt
       */
      let sessionId = localStorage.getItem(ConstLocalStorage.SESSION_SIGN_JOB_SMARTCA);
      if (sessionId == null || sessionId == undefined || sessionId == "") return;

      this.signatureService.getInfoSignJobSmartCAV2(sessionId, this.__page, this.__propertiesSort, this.__sort, this.__maxSize).subscribe((res: any) => {
        console.log(res);
        if (res?.object?.data && res?.object?.data instanceof Array) {
          this.contractsInSignJob = [];
          res?.object?.data.forEach((item: any) => {
            this.contractsInSignJob.push(new ContractInSignJobItem(this.convertDataSmartCAJob(item)));
          });
          
          if (this.countContractSigned == this.contractsInSignJob.length) {
          } else {
            this.showPopupSignJob();
            this.reloadCheckStatusSignJob();
          }
        }
      }, err => {
        console.log(err);
      })
    }
  }

  /**
   * Khởi tạo form tìm kiếm
   */
  makeFormSearch() {
    this.formSearch = this.fb.group({
      search: new FormControl('')
    });
  }
  get keySearch() { return this.formSearch.get('search'); }

  /**
   * Khoi tao form filter
   */
  makeFormFilter() {
    this.formFilter = this.fb.group({
      customerType: new FormControl(''),
      toDate: new FormControl('', [Validators.pattern(REGEXP_DATE_INPUT)]),
      fromDate: new FormControl('', [Validators.pattern(REGEXP_DATE_INPUT)]),
      status: new FormControl(''),
      typeId: new FormControl('')
    });
  }
  get fromDate() {
    return DateUtils.convertInputToFormat(this.formFilter.value.fromDate, DateUtils.format.API);
  }
  get toDate() {
    return DateUtils.convertInputToFormat(this.formFilter.value.toDate, DateUtils.format.API);
  }
  get status() {
    return this.formFilter.value.status;
  }

  get typeId() {
    return this.formFilter.value.typeId;
  }

  /**
   * Thay doi ngay tren filter
   * @param value 
   * @param type 
   */
  changeDateFilter(event, type) {
    switch (type) {
      case this.dateType.CREATED_FROM: 
        this.formFilter.controls.fromDate.setValue(DateUtils.convertObjtoDate(event));
        break;
      case this.dateType.CREATED_TO:
        this.formFilter.controls.toDate.setValue(DateUtils.convertObjtoDate(event));
        break;
    }
  }

  /**
   * Tìm kiếm với key
   * @param event 
   */
  actSearch(event) {
    this.page = 1;
    this.loadData();
  }

  /**
   * Tìm kiếm với bộ lọc
   */
  searchWithFilter() {
    this.isSubmit = true;

    if (this.formFilter.valid) {
      // Nếu ngày nhập vào đúng định dạng thì check thêm điều kiện từ ngày phải nhỏ hơn đến ngày và không đc là ngày tương lai
      let dateFrom = this.formFilter.value.fromDate.length > 0 ? new Date(DateUtils.convertStringtoDateSearch(this.formFilter.value.fromDate)).getTime() : -1;
      let dateTo = this.formFilter.value.toDate.length > 0 ? new Date(DateUtils.convertStringtoDateSearch(this.formFilter.value.toDate)).getTime() : -1;
      if ((dateTo > 0 && dateFrom > 0 && dateFrom > dateTo)) {
        this.formFilter.controls.fromDate.setErrors({ minDate: true });
      }
    }

    if (this.formFilter.valid) {
      console.log(this.formFilter.value);
      this.isCheckAll = false;
      this.contractsSelected = [];
      this.loadData();
    }
  }

  /**
   * Đặt lại bộ lọc, tìm kiếm
   */
  cleanFilter() {
    this.isSubmit = false;
    this._fromDate.nativeElement.value = '';
    this._toDate.nativeElement.value = '';
    this.formFilter.reset();
    this.formFilter.controls.customerType.setValue('');
    this.formFilter.controls.fromDate.setValue('');
    this.formFilter.controls.toDate.setValue('');
    this.formFilter.controls.status.setValue('');
    this.formFilter.controls.typeId.setValue('');
    this.formSearch.reset();
    this.keySearch.setValue('');
    this.searchWithFilter()
  }

  /**
   * Tải trang hợp đồng
   * @param page 
   */
  loadData(page = 1) {
    this.page = page;
    this.getListContactSignMultiple(
      this.userId,
      this.formFilter.value.customerType || "",
      this.fromDate,
      this.toDate,
      this.formFilter.value.status,
      this.keySearch.value,
      this.page,
      this.maxSize,
      this.sort,
      UtilsFunc.getPropertiesSort(this.sortColConfig),
      this.signForm == 'SMARTCA' ? "SMART_CA" : this.signForm == 'IMAGE' ? "NO_AUTHEN" : "",
      this.typeId || ""
    )
  }

  /**
   * Gửi yêu cầu lấy danh sách hợp đồng được phép ký tự động hàng loạt
   * @param partyId 
   * @param partnerType 
   * @param fromDate 
   * @param toDate 
   * @param status 
   * @param keySearch 
   * @param page 
   * @param maxSize 
   * @param sort 
   * @param propertiesSort 
   */
  getListContactSignMultiple(partyId, partnerType, fromDate, toDate, status, keySearch, page, maxSize, sort, propertiesSort, signForm, typeId) {
    this.contractService.getListContactSignMultiple(partyId, partnerType, fromDate, toDate, status, keySearch, page, maxSize, sort, propertiesSort, signForm, typeId).subscribe((res: any) => {
      console.log(res);
      if (res && res.object.data && res.object.data instanceof Array) {
        this.listContract = [];
        res.object.data.forEach((item: any) => {
          this.listContract.push(new ContractSignMultipleItem(item));     
        })
        this.reupdateCheckboxListContract();
      }
      console.log(this.listContract);
      
      this.totalElement = res?.object?.totalElement;
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }

  /**
   * Load lại dữ liệu hợp đồng đã được chọn khi chuyển trang
   */
  reupdateCheckboxListContract() {
    this.isCheckAll = false;
    this.contractsSelected.forEach((item: any) => {
      let x = this.listContract.find((e) => { return e.contractId == item.contractId; });
      if (x != null) {
        x.selected = true;
        this.isCheckAll = true;
        this.listContract.forEach((item: any) => {
          if (item.selected == false) {
            this.isCheckAll = false;
          }
        })
      }
    })
  }

  /**
   * Sắp xếp theo cột
   * @param key 
   */
  sortCol(key) {
    if (this.sortColConfig[key] == null) {
      this.sortColConfig[key] = true;
    } else if (this.sortColConfig[key] == false) {
      this.sortColConfig[key] = null;
    } else {
      this.sortColConfig[key] = !this.sortColConfig[key];
    }

    this.loadData(this.page);
  }

  /**
   * Chọn tất cả hợp đồng
   */
  checkAll(e: any) {
    // Kiểm tra nếu số lượng chọn tất cả hợp đồng vượt quá tối đa 
    let contractsCheckAll =  this.listContract.filter((x) => {return x.selected == false}).length;
    if (this.isCheckAll === false && (this.contractsSelected.length + contractsCheckAll > this.maxFileSelect)) {
      this.myAlert.showAlertOnlyNoti(this.translate.instant('SignMultipleContracts_ts_2'), "");
      e.preventDefault();
      return;
    }

    this.listContract.forEach((item) => {
      item.selected = !this.isCheckAll;
    });
    this.isCheckAll = !this.isCheckAll;

    if (this.isCheckAll == true) {
      this.contractsSelected = this.contractsSelected.concat(this.listContract.filter((x) => {
        if (this.contractsSelected.findIndex((y) => { return y.contractId == x.contractId; }) < 0) {
          return x;
        }
      }));
    } else {
      this.contractsSelected = [].concat(this.contractsSelected.filter((x) => {
        if (this.listContract.findIndex((y) => { return y.contractId == x.contractId; }) < 0) {
          return x;
        }
      }));
    }
  }

  /**
   * Chọn 1 dòng
   * @param i 
   */
  selectRecord(i, e: any) {
    if (this.listContract[i].selected == false && this.contractsSelected.length >= this.maxFileSelect) {
      this.myAlert.showAlertOnlyNoti(this.translate.instant('SignMultipleContracts_ts_2'), "");
      e.preventDefault();
      return;
    }
    
    this.listContract[i].selected = !this.listContract[i].selected;
    if (this.listContract[i].selected == true) {
      this.contractsSelected.push(this.listContract[i]);
    } else {
      this.contractsSelected = this.contractsSelected.filter((item: any) => {
        return item?.contractId != this.listContract[i].contractId;
      })
    }

    this.isCheckAll = true;
    this.listContract.forEach((item: any) => {
      if (item.selected == false) {
        this.isCheckAll = false;
      }
    })
  }
  

  /**
   * Đếm số lượng dòng đã chọn
   */
  get totalRecordSelected() {
    return this.contractsSelected.length;
  }

  /**
   * Lấy số lượng file đã chọn
   */
  get progressValueNow() {
    return Number(Number(this.totalSizeFile) / this.maxSizeFile * 100).toFixed(1);
  }

  /**
   * Lấy thông số dung lượng file đã chọn
   */
  get totalSizeFile() {
    let sum = 0;
    this.contractsSelected.forEach((item) => {
      sum = sum + item.sizeFile;
    })

    return sum.toFixed(1);
  }

  /**
   * Trở về bước trước
   */
  backStep() {
    this.steps[this.currentStep].active = false;
    this.steps[this.currentStep].done = false;
    this.currentStep = this.currentStep - 1;
    this.steps[this.currentStep].active = true;
    this.steps[this.currentStep].done = false;

    if (this.currentStep == 0) {
      this.loadData(this.page);
    }
  }

  /**
   * Sang buoc 2
   */
  nextStep2() {
    if (this.totalRecordSelected > this.maxFileSelect) {
      this.myAlert.showAlertOnlyNoti(this.translate.instant('SignMultipleContracts_ts_2'), ConstantAlertType.BLANK, "", this.translate.instant('btn_ok'));
      return;
    }

    if (Number(this.progressValueNow) > 100) {
      this.myAlert.showAlertOnlyNoti(this.translate.instant('SignMultipleContracts_ts_3'), ConstantAlertType.BLANK, "", this.translate.instant('btn_ok'));
      return;
    }

    this.currentStep = 1;
    this.steps[0].active = false;
    this.steps[0].done = true;

    this.steps[1].active = true;
    this.steps[1].done = false;

    this.getDieuKhoan();
  }

  getDieuKhoan() {
    var self = this;
    this.contractService.getDieuKhoan().subscribe((res: any) => {
      res.text().then((result) => {
        this.dieuKhoan = "<div class='e-dieukhoan'>" + result + "</div>";
      }).catch((err) => {
      });
    }, err => {
      console.log(err);
    })
  }

  /**
   * Sang buoc 3
   */
  nextStep3() {
    this.currentStep = 2;
    this.steps[1].active = false;
    this.steps[1].done = true;

    this.steps[2].active = true;
    this.steps[2].done = false;

    this.__page = 1;
    this.__maxSize = 10;
    this.__sort = "DESC";
    this.__propertiesSort = "modified";
    this.__totalElement = 0;

    if (this.signForm == 'SMARTCA') {
      setTimeout(() => {
        this.smartCASign && this.smartCASign.startSignSmartCA();
      }, 100)
      this.postSessionSmartCA();
    } else if (this.signForm == 'IMAGE') {
      this.makeFormDSign();
      this.getListESignature();
    }
  }

  /**
   * Gửi yêu cầu tạo session ký ảnh
   */
  postSessionEsign() {
    let param = {
      contractIds: [],
      signForm: "NO_AUTHEN"
    };

    // Lấy ds contractId truyền vào param api
    this.contractsSelected.forEach((item) => {
      param.contractIds.push(item.contractId);
    })

    this.signatureService.postRequestSessionMultiSign(param).subscribe((res: any) => {
      console.log(res);
      if (res?.object?.idSign) {
        this.sessionId = res?.object?.idSign;
        this.startSign();
      }
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }

  /**
   * REview sigbox hợp đồng
   * @param contract 
   */
  reviewSignBox(contract: ContractSignMultipleItem) {
    this.contractReview = contract;
    console.log(this.contractReview);
    this.getContractFileInfo(contract.contractId, 'DRAFT', contract.contractId);
  }

  /**
   * Lay thogn tin file hop dong
   * @param id 
   * @param type 
   * @param fileKey 
   */
  getContractFileInfo(id, type, fileKey) {
    this.contractService.getContractFileInfo(id, type, fileKey).subscribe((res: any) => {
      let contractFileInfo = new ContractFileInfo(res.object);
      let keySend = contractFileInfo.keySend;

      this.downloadContractFileByUrl(contractFileInfo.url, type, keySend);
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }

  /**
   * Tai file hop dong tu url
   * @param url 
   */
  downloadContractFileByUrl(url, type, keySend) {
    this.contractService.downloadContractFileByUrl(url).subscribe((res: any) => {
      const blob: any = new Blob([res]);

      if (keySend) {
        //Neu ton tai keySend
        //Tien hanh giai ma
        // let secretKey = EncodeService.rsa.decrypt(this.authService.key, this.keySend);
        // this.loadFile(secretKey, blob);
      } else {
        //Neu khong ton tai keySend
        //Tien hanh hien thi file PDF
        this.filePDF = blob;
        this.showReviewSignBox = true;
      }
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }

  /**
   * Xóa 1 hợp đồng khi preview hợp đồng ở bước 3
   * @param i 
   */
  removeContract(i) {
    let index = this.contractsSelected.findIndex((item) => {return this.contractsSelected[i].contractId == item.contractId});
    if (index >= 0) {
      this.contractsSelected.splice(i, 1);
    }
  } 

  /**
   * Bắt sự kiện lưu vị trí chữ ký
   * @param e 
   */
  handleSaveChanged(e) {
    console.log(e);
    if (e && e instanceof Array) {
      this.contractReview.signerSignFrame = Array.from(e);
      this.showReviewSignBox = false;
      this.filePDF = null;
      this.contractReview = null;
    }
  }

  /**
   * Bắt sự kiện hủy phiên review signbox
   * @param e 
   */
  handleCancel(e) {
    this.showReviewSignBox = false;
    this.filePDF = null;
    this.contractReview = null;
  }

  //MARK KÝ ĐIỆN TỬ KÝ ẢNH
  /**
   * Tạo form ký điện tử
   */
  makeFormDSign() {
    this.formDSign = this.fb.group({
      imageFile: new FormControl(''),
    })
  }
  get imageFileESign() { return this.formDSign.get('imageFile'); }

  /**
   * lấy thông tin chử ký điện thử
   */
  getListESignature() {
    this.signatureService.getListDigitalSignature().subscribe(res => {
      let digitalSignList = new DigitalSignatureResponse(res).object;
      if (digitalSignList.length > 0) {
        this.selectCert(digitalSignList[0]);
      } else {
        this.selectDefaultEsign();
      }
    }, err => {
      console.log(err);
      this.myAlert.showErrorHandled(err);
    });
  }

  /**
   * lấy thông tin cấu hình chữ ký điện tử
   * @param item 
   */
  selectCert(item: DigitalSignatureItem) {
    this.eCertSelected = item;
    this.imgESignContent = 'data:image/jpg;base64,' + item.base64Image;
    this.imgEsignConfig = 'data:image/jpg;base64,' + item.base64Image;

    let blob: any = ImageProgress.converBase64toBlob(item.base64Image, 'image/jpg');
    blob.lastModifiedDate = new Date();
    blob.name = 'signImg1.jpeg';
    this.imgESign = <File>blob;

    // PDFSign.setImage(item.base64Image);
    // let formData = new FormData();
    // formData.append("signImg1", blob, "signImg1.png");
    // this.postGetImageSign(this.idSign, formData);
  }

  /**
   * Set hình ảnh ý default
   */
  selectDefaultEsign() {
    this.imgESignContent = 'data:image/jpg;base64,' + default_img;
    this.imgEsignConfig = 'data:image/jpg;base64,' + default_img;

    let blob: any = ImageProgress.converBase64toBlob(default_img, 'image/jpg');
    blob.lastModifiedDate = new Date();
    blob.name = 'signImg1.jpeg';
    this.imgESign = <File>blob;

    // PDFSign.setImage(item.base64Image);
    // let formData = new FormData();
    // formData.append("signImg1", blob, "signImg1.png");
    // this.postGetImageSign(this.idSign, formData);
  }

  /**
   * handle file hình chữ ký điện tử
   * @param e 
   */
  handleFileImgESignInput(e) {
    this.imgESign = e.target.files && e.target.files.item(0);
    if (this.imgESign && this.imgESign !== null && this.imgESign !== undefined) {

      this.myAlert.showAlert(this.translate.instant('SignMultipleContracts_ts_4'), "", true, "", this.translate.instant('btn_save'), () => {
        this.inputImgESignLabel = this.imgESign.name;

        if (!this.imgType.includes(this.imgESign.type)) {
          this.imageFileESign.setErrors({ type: true });
          // this.imgESignContent = '';
        } else if (this.imgESign.size > 2000000) {
          this.imageFileESign.setErrors({ size: true });
          this.getBase64String(this.imgESign, this.handelimgESign.bind(this));
        } else {
          this.getBase64String(this.imgESign, this.handelimgESign.bind(this));
          // Lấy ảnh chữ ký thật
          // let formData = new FormData();
          // formData.append("signImg1", this.imgESign, "signImg1.png");
          // this.postGetImageSign(this.idSign, formData);
        }
      }, "", localize.btn_not_now);
    } else {
      this.imageFileESign.setErrors({ required: true });
      this.imgESignContent = '';
      this.imgEsignConfig = '';
      this.inputImgESignLabel = 'ESign_ts_1';
    }
  }

  /**
   * Get base64 tu anh
   * @param blob 
   * @param callback 
   */
  getBase64String(blob, callback) {
    var self = this;
    var reader = new FileReader();
    reader.onload = function (e) {
      callback(reader.result, self);
    }

    reader.readAsDataURL(blob);
  }

  /**
   * Callback luu chuoi base64
   * @param str 
   * @param self 
   */
  handelimgESign(str) {
    this.imgESignContent = str;
    this.imgEsignConfig = str;
  }

  /**
   * Click button ky anh
   */
  clickBtnSignImage() {
    this.postSessionEsign();
  }

  /**
   * Bắt đầu ký hàng loạt
   */
  startSign() {
    if (this.signForm == 'IMAGE') {
      let sessionId = this.sessionId;
      let param = [];
      // Tao param du lieu submit
      this.contractsSelected.forEach((item, index) => {
        let multiSign = [];
        item.signerSignFrame.forEach((item1, index1) => {
          let x = item1.x + item1.w;
          let y = item1.y;
          let x2 = item1.x;
          let y2 = item1.y - item1.h;
          let page = item1.page;
          multiSign.push({
            pageSign: Number(page),
            bboxSign: [x2, y2, x, y]
          })
        });
        
        param.push({
          documentId: item.contractId,
          multiSign: multiSign
        })
      })
      // Tạo form gửi yêu cầu ký
      let formData = new FormData();
      formData.append('signImg1', this.imgESign, "signImg1.png");
      formData.append('signInfo', JSON.stringify(param));
      this.signatureService.postMultiSignImage(sessionId, formData).subscribe((res: any) => {
        console.log(res);
        localStorage.setItem(ConstLocalStorage.SESSION_SIGN_JOB, this.sessionId);
        this.showPopupSignJob();
        this.reloadCheckStatusSignJob();
      }, err => {
        this.myAlert.showErrorHandled(err);
      })
    }
  }

  //MARK: SMART CA
  /**
   * Gửi yêu cầu tạo sessionId cho phiên ký hàng loạt bằng SmartCA
   */
  postSessionSmartCA() {
    // let param = {
    //   contractIds: [],
    //   signForm: "SMART_CA"
    // };

    // // Lấy ds contractId truyền vào param api
    // this.contractsSelected.forEach((item) => {
    //   param.contractIds.push(item.contractId);
    // })

    // this.signatureService.postRequestSessionMultiSignSmartCA(param).subscribe((res: any) => {
    //   console.log(res);
    //   if (res?.object?.tranId) {
    //     this.sessionId = res?.object?.tranId;
    //     this.smartCASign && this.smartCASign.setSessionId(this.sessionId);
    //   }
    // }, err => {
    //   this.myAlert.showErrorHandled(err);
    // })

  }

  /**
   * Lấy thông tin sessionId từ localStorage
   */
  get sessionSmartCA() {
    return this.sessionId || localStorage.getItem(ConstLocalStorage.SESSION_SIGN_JOB_SMARTCA) || null;
  }

  /**
   * Bắt sự kiện đã hoàn thành xác nhận phiên ký SmartCA
   * @param e dữ liệu sự kiện trả về
   */
  isWaitingConfirmSmartCA(e) {
    console.log("isWaitingconfirm: ", e);
    if (e !== null && e !== "" && e !== undefined) {
      this.showLayoutConfirmSmartCA = e;
      if (e == true) {
        this.currentStep = 3;
        this.steps[3].active = true;

        this.steps[2].active = false;
        this.steps[2].done = true;
      }
    }
  }

  /**
   * Bắt sự kiện hết hạn phiên xác nhận ký smartCA
   * @param e dữ liệu sự kiện trả về
   */
  expiredSessionSmartCA(e) {
    console.log("expiredSessionSmartCA: ", e);
    if (e !== null && e !== "" && e !== undefined) {
      this.timeoutSmartCa = e;
    }
  }

  /**
   * Bắt sự kiện hoàn thành submit ký hàng loạt SMARTCA
   * @param e dữ liệu sự kiện trả về
   */
  savedSignatureFromSmartCA(e) {
    console.log("savedSignatureFromSmartCA: ", e);
    if (e !== null && e !== "" && e !== undefined) {
      this.showLayoutConfirmSmartCA = null;
      // localStorage.setItem(ConstLocalStorage.SESSION_SIGN_JOB_SMARTCA, this.sessionId);
      this.showPopupSignJob();
      this.reloadCheckStatusSignJob();
    }
  }

  /**
   * Click button ky smartca
   */
  clickBtnSignSmartCA() {
    // this.postSessionSmartCA();
    this.signBySmartCA();
  }

  /**
   * Gửi yêu cầu ký SmartCA
   * @returns 
   */
  signBySmartCA() {
    this.smartCASign && this.smartCASign.signBySmartCA();
    return;
  }

  /**
   * Khởi động lại vòng lặp check trạng thái hợp đồng đang ký
   */
  reloadCheckStatusSignJob() {
    clearInterval(this.intervalSignJob);
    this.intervalSignJob = setInterval(() => { 
      if (this.signForm == 'SMARTCA') {
        let sessionInfo = localStorage.getItem(ConstLocalStorage.SESSION_SIGN_JOB_SMARTCA);
        this.getInfoSignJobSmartCA(sessionInfo);
      } else if (this.signForm == "IMAGE") {
        let sessionInfo = localStorage.getItem(ConstLocalStorage.SESSION_SIGN_JOB);
        this.getInfoSignJob(sessionInfo); 
      }
    }, 2000);
  }

  /**
   * Hiển thị popup job ký
   */
  showPopupSignJob() {
    this.modalSignJob = this.modal.open(this.popupSignJob, { backdrop: 'static' });
  }
  
  /**
   * Đếm số lượng hợp đồng đã ký
   */
  get countContractSigned() {
    let res = 0;
    this.contractsInSignJob.forEach((item: any) => {
      if (item.signFlag == true) {
        res = res + 1;
      }
    })
    return res;
  }

  /**
   * Lấy thông tin sign job đang chạy
   * @param sessionId id phiên ký hàng loạt
   */
  getInfoSignJob(sessionId) {
    this.signatureService.getInfoSignJob(sessionId).subscribe((res: any) => {
      console.log(res);
      if (res?.object?.documentInfo && res?.object?.documentInfo instanceof Array) {
        this.contractsInSignJob = [];
        res?.object?.documentInfo.forEach((item: any) => {
          this.contractsInSignJob.push(new ContractInSignJobItem({
            contractId: item.documentId || "",
            title: item.signature?.documentName || "",
            inprogress: item.signature?.inprogress,
            error: item.signature?.error,
            signFlag: item.signature?.signFlag,
            multiSign: item.signature?.multiSign
          }));
        });
        
        if (this.countContractSigned == this.contractsInSignJob.length) {
          this.progressSignJobValue = 100;
          setTimeout(() => {
            this.endSignJob = true;
            clearInterval(this.intervalSignJob);
            this.modalSignJob && this.modalSignJob.close();
            localStorage.removeItem(ConstLocalStorage.SESSION_SIGN_JOB);
            this.steps[0].done = true;
            this.steps[1].done = true;
            this.steps[2].done = true;
            this.steps[2].active = true;

            this.myAlert.showAlertOnlyNoti(this.translate.instant('SignMultipleContracts_ts_5'), ConstantAlertType.SUCCESS);
          }, 1000);
        } else {
          this.progressSignJobValue = Number((this.countContractSigned / this.contractsInSignJob.length * 100).toFixed(2));
        }
      }
    }, err => {
      console.log(err);
    })
  }

  /**
   * Lấy thông tin sign job đang chạy
   * @param sessionId id phiên ký hàng loạt
   */
  getInfoSignJobSmartCA(sessionId, page = 1, propertiesSort = "", sort = "", maxSize = this.maxFileSelect) {
    this.signatureService.getInfoSignJobSmartCAV2(sessionId, page, propertiesSort, sort, maxSize).subscribe((res: any) => {
      console.log(res);

      if (res?.object?.data && res?.object?.data instanceof Array) {
        this.contractsInSignJob = [];
        res?.object?.data.forEach((item: any) => {
          this.contractsInSignJob.push(new ContractInSignJobItem(this.convertDataSmartCAJob(item)));
        });

        if (this.countContractSigned == this.contractsInSignJob.length) {
          this.progressSignJobValue = 100;
          setTimeout(() => {
            this.endSignJob = true;
            clearInterval(this.intervalSignJob);
            this.modalSignJob && this.modalSignJob.close();
            localStorage.removeItem(ConstLocalStorage.SESSION_SIGN_JOB_SMARTCA);
            this.steps[0].done = true;
            this.steps[1].done = true;
            this.steps[2].done = true;
            this.steps[3].done = true;
            this.steps[3].active = true;
            this.myAlert.showAlertOnlyNoti(this.translate.instant('SignMultipleContracts_ts_6'), ConstantAlertType.SUCCESS);
          }, 1000);
        } else {
          this.progressSignJobValue = Number((this.countContractSigned / this.contractsInSignJob.length * 100).toFixed(2));
        }
      }
    }, err => {
      console.log(err);
    })
  }

  /**
   * Chuyển đổi dữ liệu smartca về dữ liệu chung
   * @param obj 
   * @returns 
   */
  convertDataSmartCAJob(obj: any = null) {
    let param = {
      contractId: obj.contractId || "",
      title: obj.contractName || "",
      inprogress: true,
      error: obj.signMessage || "",
      signFlag: false,
      multiSign: [],
      status: obj.signStatus
    }

    if (obj.signatures && obj.signatures instanceof Array) {
      obj.signatures.forEach((x) => {
        let bboxs = [];
        if (x.position && x.position instanceof String) {
          bboxs = x.position.split(',');
        }
        param.multiSign.push({
          bboxSign: bboxs,
          pageSign: x.page
        })
      })
    }

    if (obj.signStatus == SmartCAJobStatus.INPROCESS) {
      param.inprogress = true;
      param.signFlag = false;
      param.error = "";
    }

    if (obj.signStatus == SmartCAJobStatus.DONE) {
      param.inprogress = false;
      param.signFlag = true;
      param.error = "";
    }

    if (obj.signStatus == SmartCAJobStatus.ERROR) {
      param.inprogress = false;
      param.signFlag = true;
      param.error = obj.signMessage || "error";
    }

    return param;
  }

  loadPageSignJob(page) {
    this.__page = page;
    this.getInfoSignJobSmartCA(this.sessionId, this.__page, this.__propertiesSort, this.__sort, this.__maxSize);
  }

  /**
   * Hien thi loi ky
   * @param error 
   */
  showErrorSign(error) {
    this.myAlert.showAlertOnlyNoti(error, ConstantAlertType.ERROR, "", this.translate.instant('btn_close'), null, this.translate.instant('SignMultipleContracts_ts_7'));
  }

  /**
   * Tổng số hợp đồng đã submit ký
   */
  get totalContractInSignJob() {
    return this.contractsInSignJob.length || this.contractsSelected.length;
  }

  /**
   * Số hợp đong đã ký thành công
   */
  get totalSignedContractInSignJob() {
    let res = 0;
    this.contractsInSignJob.forEach((item) => {
      if (item.signFlag === true && item.error == "") {
        res = res + 1;
      }
    });

    return res;
  }

  /**
   * Số hợp đồng ký thất bại
   */
  get totalSignedFailedContractInSignJob() {
    let res = 0;
    this.contractsInSignJob.forEach((item) => {
      if (item.signFlag === true && item.error != "") {
        res = res + 1;
      }
    });

    return res;
  }

  /**
   * Thoát khỏi màn hình chức năng
   */
  goToBack() {
    this.router.navigate([ConstantUrl.CONTRACT_MANAGER]);
  }
  
  /**
   * Kết thúc 
   */
  finish() {
    location.reload();
    // this.router.navigate([ConstantUrl.CONTRACT_MANAGER]);
  }

  dropListTypeData: Observable<any>;
  typesLoading = false;
  
  /**
   * Lay du lieu loai hop dong ||  Get list of types
   * @param page 
   * @param maxSize 
   * @param propertiesSort 
   * @param sort 
   * @param status 
   */
  getTypeList(page, maxSize, propertiesSort = "modified", sort = "DESC", status = "") {
    this.typesLoading = true;
    this.contractTypeService.getListContractTypes(page, maxSize, propertiesSort, sort, status, this.keySearchDropListEmployee).subscribe((res: any) => {
      this.dropListTypeData.pipe(tap((data: any) => {
        return [...data, (res?.object?.data || [])];
      }))
      this.typesLoading = false;
    }, err => {
      this.typesLoading = false;
    });
  }

  dropListTypePage = 1;
  endOfDropListTypePage = false;
  keySearchDropListEmployee = "";
  typeInput$ = new Subject<string>();
  allLabel: Array<object> = [
    { contractTypeId: '', name: 'Tất cả' }
  ];
  lazyLoadDropListType() {
    this.dropListTypeData = concat(
      this.typeInput$.pipe(
        startWith(''),
        debounceTime(400),
        distinctUntilChanged(),
        tap(() => { this.typesLoading = true; this.myLoader.disabledLoader(this.typesLoading); }),
        switchMap((term) => {
          this.keySearchDropListEmployee = term || "";
          this.dropListTypePage = 1;
          this.endOfDropListTypePage = false;
          let res = this.contractTypeService.getListContractTypes(this.dropListTypePage, -1, "", "DESC", "", term || "").pipe(
            map((res: any) => {
              if (res?.object?.data == null) this.endOfDropListTypePage = true;
              return this.allLabel.concat(res?.object?.data) || [];
            }),
            catchError(() => of([])), // empty list on error
            tap(() => { this.typesLoading = false; this.myLoader.disabledLoader(this.typesLoading); })
          );
          return res;
        })
      ));
  }

  trackByFn1(item: any) {
    return item;
  }

  changeType($event) {
  }

  /**
   * Su kien scroll xuong duoi cung cua droplist
   * @returns 
   */
  onScrollToEnd() {
    var nextPage = this.dropListTypePage + 1;
    if (this.endOfDropListTypePage) return;
    this.dropListTypePage = nextPage;
    this.getTypeList(nextPage, 10);
  }

}
