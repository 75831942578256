export class DualListItem {
    public id: string = "";
    public name: string = "";
    public index: number = -1;
    public assigned: boolean = false;
    public selected: boolean = false;
    public child: DualListItem[] = [];
    public showChild: boolean = false;

    constructor(obj = null) {
        obj = obj || {};
        this.id = obj.id || "";
        this.name = obj.name || "";
        this.index = obj.index;
        this.assigned = obj.assigned !== undefined ? obj.assigned : false;
        this.selected = obj.selected !== undefined ? obj.selected : false;
        this.showChild = obj.showChild !== undefined ? obj.showChild : false;
        this.child = [];
        if (obj.child && obj.child instanceof Array) {
            obj.child.forEach(item => {
                this.child.push(new DualListItem(item));
            });
        }
    }
}