import { MainComponent } from 'src/app/layout/main/main.component';
import { OrganizationDetailObject } from './organization-detail-object';
import { MainResponse } from '../../../../main-response.model';
import {UserInformationsItem} from 'src/app/utils/models/customer/info-by-partyid/user-informations-item.model';
import * as moment from 'moment';

export class OrganizationDetailResponse extends MainResponse {
    object: OrganizationDetailObject;

    constructor(obj = null) {
        super();
        obj = obj || {};
        this.init(obj);
        this.object = new OrganizationDetailObject(obj.object);
    }
}