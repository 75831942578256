<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-auto">
                <h1 class="m-0 text-dark"><label>Thông tin chi tiết</label></h1>
            </div>
            <!-- /.col -->
            <div class="col-md-auto ml-auto">
            </div>
            <!-- <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>

<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-10 col-sm-12 col-xs-12">
                <div class="card shadow-none">
                    <div class="card-body position-relative">
                        <div class="row pt-2 pb-2">
                            <div class="col-md-auto align-self-end">
                                <label class="text-lg">Tài khoản định danh cấp
                                    {{personalDetailObject?.identityLevel}}</label>
                            </div>
                            <div class="ml-auto d-flex">
                                <a [routerLink]="['/console/account-manager/organization', partyId]"
                                    class="btn btn-with-icon btn-outline-primary mr-3">
                                    <em class="material-icons nav-icon">clear</em>
                                    Hủy
                                </a>
                                <button class="btn btn-primary btn-with-icon" (click)="onClickSave()">
                                    <span class="material-icons pr-2">save</span>Lưu
                                </button>
                            </div>
                        </div>
                        <hr>
                        <form [formGroup]="form" (ngSubmit)="onClickSave()">
                            <div class="row">
                                <div class="col-md-6 col-xs-12">
                                    <div class="sub-title mb-2">Thông tin tổ chức</div>
                                    <form formGroupName="toChuc">
                                        <div class="form-group">
                                            <label>Tên doanh nghiệp <span class="text-danger">*</span>:</label>
                                            <input type="text" formControlName="tenToChuc" id="tenToChuc"
                                                class="form-control"
                                                [ngClass]="{ 'is-invalid1': isSubmit && tenToChuc.errors }" 
                                                placeholder="Nhập tên doanh nghiệp"/>
                                            <app-show-validate-errors
                                                [isSubmit]="isSubmit"
                                                [errorMessages]="validateMsg.tenToChuc"
                                                [control]="toChuc.controls.tenToChuc"
                                                [detail]="{ name: 'tenToChuc' }">
                                            </app-show-validate-errors>
                                        </div>
                                        <div class="form-group">
                                            <label>Tên viết tắt <span class="text-danger">*</span>:</label>
                                            <input type="text" formControlName="tenRutGon" id="tenRutGon"
                                                class="form-control"
                                                [ngClass]="{ 'is-invalid1': isSubmit && tenRutGon.errors }" 
                                                placeholder="Nhập tên viết tắt"/>
                                            <app-show-validate-errors
                                                [isSubmit]="isSubmit"
                                                [errorMessages]="validateMsg.tenRutGon"
                                                [control]="toChuc.controls.tenRutGon"
                                                [detail]="{ name: 'tenRutGon' }">
                                            </app-show-validate-errors>
                                        </div>
                                    </form>
                                    <div class="form-group">
                                        <label>Mã số thuế <span class="text-danger">*</span>:</label>
                                        <input type="text" formControlName="maSoThue" id="maSoThue" class="form-control"
                                            disabled [ngClass]="{ 'is-invalid1': isSubmit && f.maSoThue.errors }" 
                                            placeholder="Nhập mã số thuế"/>
                                        <app-show-validate-errors
                                            [isSubmit]="isSubmit"
                                            [errorMessages]="validateMsg.maSoThue"
                                            [control]="toChuc.controls.maSoThue"
                                            [detail]="{ name: 'maSoThue' }">
                                        </app-show-validate-errors>
                                    </div>
                                    <form formGroupName="daiDien">
                                        <div class="sub-title mb-2">Thông tin đăng ký</div>
                                        <div class="form-group">
                                            <label>Tên tài khoản <span class="text-danger">*</span>:</label>
                                            <input type="text" formControlName="username" id="username"
                                                class="form-control"
                                                [ngClass]="{ 'is-invalid1': isSubmit && username.errors }" 
                                                placeholder="Nhập tên tài khoản"/>
                                            <app-show-validate-errors
                                                [isSubmit]="isSubmit"
                                                [errorMessages]="validateMsg.username"
                                                [control]="form.controls.username"
                                                [detail]="{ name: 'username' }">
                                            </app-show-validate-errors>
                                        </div>
                                    </form>
                                </div>
                                <div class="col-md-6 col-xs-12">
                                    <form formGroupName="daiDien">
                                        <div class="sub-title mb-2">Thông tin người đại diện</div>
                                        <div class="form-group">
                                            <label>Họ và tên <span class="text-danger">*</span>:</label>
                                            <input type="text" formControlName="ten" id="ten" class="form-control"
                                                [ngClass]="{ 'is-invalid1': isSubmit && ten.errors }" 
                                                placeholder="Nhập họ và tên"/>
                                            <app-show-validate-errors
                                                [isSubmit]="isSubmit"
                                                [errorMessages]="validateMsg.tenRutGon"
                                                [control]="daiDien.controls.ten"
                                                [detail]="{ name: 'ten' }">
                                            </app-show-validate-errors>
                                        </div>
                                    </form>
                                    <div class="form-group">
                                        <label>Email <span class="text-danger">*</span>:</label>
                                        <input type="email" disabled formControlName="email" id="email"
                                            class="form-control"
                                            [ngClass]="{ 'is-invalid1': isSubmit && email.errors }" 
                                            placeholder="Nhập email"/>
                                        <app-show-validate-errors
                                            [isSubmit]="isSubmit"
                                            [errorMessages]="validateMsg.email"
                                            [control]="daiDien.controls.email"
                                            [detail]="{ name: 'email' }">
                                        </app-show-validate-errors>
                                    </div>
                                    <form formGroupName="daiDien">
                                        <div class="form-group">
                                            <label>Số điện thoại <span class="text-danger">*</span>:</label>
                                            <input appTrackerPhoneNumber onKeyPress="if(this.value.length==10) return false;" type="text" formControlName="sdt" id="sdt" class="form-control"
                                                [ngClass]="{ 'is-invalid1': isSubmit && sdt.errors }" 
                                                placeholder="Nhập số điện thoại"/>
                                            <app-show-validate-errors
                                                [isSubmit]="isSubmit"
                                                [errorMessages]="validateMsg.sdt"
                                                [control]="daiDien.controls.sdt"
                                                [detail]="{ name: 'sdt' }">
                                            </app-show-validate-errors>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 col-xs-12">
                                                <div class="form-group">
                                                    <label>Ngày sinh <span class="text-danger">*</span>:</label>
                                                    <input appInputMark [options]="optionsDateFormat" type="text" formControlName="ngaySinh" class="form-control"
                                                        id="ngaySinh" placeholder="dd/MM/yyyy" />
                                                    <app-show-validate-errors
                                                        [isSubmit]="isSubmit"
                                                        [errorMessages]="validateMsg.ngaySinh"
                                                        [control]="daiDien.controls.ngaySinh"
                                                        [detail]="{ name: 'ngaySinh' }">
                                                    </app-show-validate-errors>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-xs-12">
                                                <div class="form-group">
                                                    <label>Giới tính <span class="text-danger">*</span>:</label>
                                                    <ng-select [searchable]="false" placeholder="Chọn giới tính"
                                                        formControlName="gioiTinhId">
                                                        <ng-option [value]="2">Nam</ng-option>
                                                        <ng-option [value]="1">Nữ</ng-option>
                                                    </ng-select>
                                                    <app-show-validate-errors
                                                        [isSubmit]="isSubmit"
                                                        [errorMessages]="validateMsg.gioiTinhId"
                                                        [control]="daiDien.controls.gioiTinhId"
                                                        [detail]="{ name: 'gioiTinhId' }">
                                                    </app-show-validate-errors>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label>Tỉnh/Thành phố (Trực thuộc TW) <span
                                                    class="text-danger">*</span>:</label>
                                            <ng-select 
                                                    [items]="tinhBindData"
                                                    bindLabel="label"
                                                    bindValue="id"
                                                    placeholder="Chọn tỉnh/thành phố"
                                                    (change)="tinhChange($event)"
                                                    formControlName="tinhId">
                                            </ng-select>
                                            <app-show-validate-errors
                                                [isSubmit]="isSubmit"
                                                [errorMessages]="validateMsg.tinhId"
                                                [control]="daiDien.controls.tinhId"
                                                [detail]="{ name: 'tinhId' }">
                                            </app-show-validate-errors>
                                        </div>
                                        <div class="form-group">
                                            <label>Huyện <span class="text-danger">*</span>:</label>
                                            <ng-select 
                                                    [items]="huyenBindData"
                                                    bindLabel="label"
                                                    bindValue="id"
                                                    placeholder="Chọn huyện/quận"
                                                    (change)="huyenChange($event)"
                                                    formControlName="huyenId">
                                            </ng-select>
                                            <app-show-validate-errors
                                                [isSubmit]="isSubmit"
                                                [errorMessages]="validateMsg.huyenId"
                                                [control]="daiDien.controls.huyenId"
                                                [detail]="{ name: 'huyenId' }">
                                            </app-show-validate-errors>
                                        </div>
                                        <div class="form-group">
                                            <label>Xã <span class="text-danger">*</span>:</label>
                                            <ng-select 
                                                    [items]="xaBindData"
                                                    bindLabel="label"
                                                    bindValue="id"
                                                    placeholder="Chọn xã/phường"
                                                    (change)="xaChange($event)"
                                                    formControlName="xaId">
                                            </ng-select>
                                            <app-show-validate-errors
                                                [isSubmit]="isSubmit"
                                                [errorMessages]="validateMsg.xaId"
                                                [control]="daiDien.controls.xaId"
                                                [detail]="{ name: 'xaId' }">
                                            </app-show-validate-errors>
                                        </div>
                                        <div class="form-group">
                                            <label>Đường <span class="text-danger">*</span>:</label>
                                            <input type="text" formControlName="duong" id="duong"
                                                class="form-control" placeholder="Nhập đường" />
                                            <app-show-validate-errors
                                                [isSubmit]="isSubmit"
                                                [errorMessages]="validateMsg.duong"
                                                [control]="daiDien.controls.duong"
                                                [detail]="{ name: 'duong' }">
                                            </app-show-validate-errors>
                                        </div>
                                        <div class="form-group">
                                            <label>Số nhà <span class="text-danger">*</span>:</label>
                                            <input type="text" formControlName="soNha" id="soNha"
                                                class="form-control" placeholder="Nhập số nhà" />
                                            <app-show-validate-errors
                                                [isSubmit]="isSubmit"
                                                [errorMessages]="validateMsg.soNha"
                                                [control]="daiDien.controls.soNha"
                                                [detail]="{ name: 'soNha' }">
                                            </app-show-validate-errors>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>