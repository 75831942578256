<div class="row" [formGroup]="formSubmit" [hidden]="isUpdateEmailSDT">
    <div class="content-header col-md-12 d-flex justify-content-between">
        <div class="mb-2">
            <div class="col-md-auto">
                <h1 class="m-0 text-dark"><label>{{ 'ServiceProfileManager_title1' | translate }}</label></h1>
            </div>
        </div>
        <div class="d-flex align-items-center mr-4">
            <button *ngIf="isEdit == false && userInfo.identityLevel <= 1" (click)="updateVerifyAccount()" class="btn btn-primary d-inline-flex "><span class="material-icons mr-1">how_to_reg</span> Nâng cấp định danh</button>

            <button *ngIf="isEdit == false" (click)="isEdit = true" class="btn btn-outline-primary d-inline-flex ml-3"><span
                class="material-icons mr-1">edit</span> {{ 'btn_edit' | translate }}</button>

            <button *ngIf="isEdit == true" (click)="isEdit = false;actBtnCancel($event)" class="btn btn-outline-primary btn-light d-inline-flex mr-4"><span
                class="material-icons">clear</span> {{ 'btn_cancel' | translate }}</button>
            <button *ngIf="isEdit == true" (click)="submit($event)" class="btn btn-primary d-inline-flex"><span
                    class="material-icons mr-1">save</span> {{ 'btn_save' | translate }}</button>
        </div>
        <!-- /.container-fluid -->
    </div>
    <div class="content w-100">
        <div class="card mr-4">
            <div *ngIf="!isEdit" class="card-body row">
                <div class="col-md-3">
                    <label class="text-primary">Ảnh đại diện</label>
                    <hr class="my-0">
                    <div>
                        <img class="img-circle d-block m-auto" src="assets/img/icon-avatar-default.png" width="150" height="150" />
                    </div>
                </div>
                <div class="col-md-9">
                    <!-- Thông tin tài khoản -->
                    <label class="text-primary">{{ 'ProfileBusiness_account' | translate }}</label>
                    <hr class="mt-0">
                    <div class="row">
                        <div class="col-md-3"><label>Tên tài khoản</label></div>
                        <div class="col-md-9"> {{ username.value }}</div>
                    </div>
                    <div class="row">
                        <div class="col-md-3"><label>Loại tài khoản</label></div>
                        <div class="col-md-9"> {{ 'ServiceProfileManager_title3' | translate }} {{ userInfo.identityLevel }} </div>
                    </div>
                    <!-- Thông tin doanh nghiệp -->
                    <label class="text-primary mt-3">{{ 'ProfileBusiness_party' | translate }}</label>
                    <hr class="mt-0">

                    <div class="row">
                        <div class="col-md-3"><label>{{ 'ProfileBusiness_party_name' | translate }}</label></div>
                        <div class="col-md-9">{{ tenDoanhNghiep.value}}</div>
                    </div>
                    <div class="row">
                        <div class="col-md-3"><label>{{ 'ProfileBusiness_agent_email' | translate }}</label></div>
                        <div class="col-md-9 d-flex justify-content-between">
                            <div>{{ email.value }}</div>
                            <a *ngIf="partyInfo.isActiveUpdateEmail" (click)="updateEmailSDT('email')" class="text-primary text-decoration-underline cursor-pointer mr-4">Cập nhật</a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3"><label>{{ 'ProfileBusiness_party_short' | translate }}</label></div>
                        <div class="col-md-9">{{ tenVietTat.value }}</div>
                    </div>
                    <div class="row">
                        <div class="col-md-3"><label>{{ 'ProfileBusiness_party_taxcode' | translate }}</label></div>
                        <div class="col-md-9">{{ maSoThue.value }}</div>
                    </div>
                    <div class="row">
                        <div class="col-md-3"><label>Địa chỉ</label></div>
                        <div class="col-md-9">{{ userInfo.diaChi }}</div>
                    </div>
                    <div class="row">
                        <div class="col-md-3"><label>{{ 'ProfileBusiness_party_license' | translate }}</label></div>
                        <div class="col-md-9">
                            <a class="text-decoration-underline text-primary" style="cursor: pointer; font-weight: 600;" (click)="actDownloadGPKD(partyInfo.partyId)">
                            {{partyInfo?.gpkdFilename}}
                            </a>
                        </div>
                    </div>

                    <!-- Thông tin người đại diện -->
                    <label class="text-primary mt-3">{{ 'ProfileBusiness_agent' | translate }}</label>
                    <hr class="mt-0">
                    
                    <div class="row">
                        <div class="col-md-3"><label>{{ 'ProfileBusiness_agent_name' | translate }}</label></div>
                        <div class="col-md-9">{{ hoVaTen.value }}</div>
                    </div>
                    <div class="row">
                        <div class="col-md-3"><label>{{ 'ProfileBusiness_agent_phone' | translate }}</label></div>
                        <div class="col-md-9 d-flex justify-content-between">
                            <div>{{ sdt.value }}</div>
                            <a *ngIf="partyInfo.isActiveUpdatePhoneNumber" (click)="updateEmailSDT('sdt')" class="text-primary text-decoration-underline cursor-pointer mr-4">Cập nhật</a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3"><label>{{ 'ProfileBusiness_agent_birth' | translate }}</label></div>
                        <div class="col-md-9">{{ ngaySinh.value }}</div>
                    </div>
                    <div class="row">
                        <div class="col-md-3"><label>{{ 'ProfileBusiness_agent_gender' | translate }}</label></div>
                        <div class="col-md-9">
                            <span *ngIf="gender.value == 2">{{ 'ProfileBusiness_agent_male' | translate }}</span>
                            <span *ngIf="gender.value == 1">{{ 'ProfileBusiness_agent_female' | translate }}</span>
                        </div>
                    </div>

                </div>
            </div>
            <div [hidden]="!isEdit" class="card-body row">
                <!-- Ảnh đại diện -->
                <div class="col-md-12">
                    <label class="text-primary">Ảnh đại diện</label>
                    <hr class="my-0">
                    <div class="row">
                        <div>
                            <img class="img-circle" src="assets/img/icon-avatar-default.png" width="150" height="150" />
                        </div>
                        <div class="d-flex flex-column justify-content-center">
                            <div>
                                <button class="btn btn-outline-primary d-inline-flex ml-3"><span
                                    class="material-icons mr-1">file_upload</span> Tải ảnh lên
                                </button>
                            </div>
                            <div class="mt-3 ml-3">
                                <p class="font-italic text-grey-darkest">File đính kèm phải có định dạng .jpeg hoặc .png và dung lượng không vượt quá 5MB</p>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Thông tin tài khoản -->
                <div class="col-md-12">
                    <label class="text-primary">{{ 'ProfileBusiness_account' | translate }}</label>
                    <hr class="mt-0">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ 'ProfileBusiness_account_name' | translate }} <span class="text-danger">*</span>:</label>
                                <input class="form-control" formControlName="username" placeholder="{{ 'ProfileBusiness_account_input_name' | translate }}">
                                <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.username"
                                    [control]="username" [detail]="{ name: 'username' }"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Loại tài khoản <span class="text-danger">*</span>:</label>
                                <input class="form-control" value="{{ 'ServiceProfileManager_title3' | translate }} {{ userInfo.identityLevel }}" disabled>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Thông tin doanh nghiệp -->
                <div class="col-md-12">
                    <label class="text-primary">{{ 'ProfileBusiness_party' | translate }}</label>
                    <hr class="mt-0">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ 'ProfileBusiness_party_name' | translate }} <span class="text-danger">*</span>:</label>
                                <input class="form-control" formControlName="tenDoanhNghiep" placeholder="{{ 'ProfileBusiness_party_input_name' | translate }}">
                                <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.tenDoanhNghiep"
                                    [control]="tenDoanhNghiep" [detail]="{ name: 'tenDoanhNghiep' }"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ 'ProfileBusiness_agent_email' | translate }} <span class="text-danger">*</span>:</label>
                                <div class="input-group">
                                    <input disabled class="form-control" formControlName="email"
                                    placeholder="{{ 'ProfileBusiness_agent_input_email' | translate }}">
                                    <div class="input-group-append">
                                        <button *ngIf="partyInfo.isActiveUpdateEmail" (click)="updateEmailSDT('email')" class="btn btn-outline-primary d-inline-flex ml-3" style="border-radius: 4px !important;"><span
                                            class="material-icons mr-1">edit</span> Cập nhật</button>
                                    </div>
                                </div>
                                <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.email"
                                    [control]="email" [detail]="{ name: 'email' }"></app-show-validate-errors>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ 'ProfileBusiness_party_short' | translate }} <span class="text-danger">*</span>:</label>
                                <input class="form-control" formControlName="tenVietTat" placeholder="{{ 'ProfileBusiness_party_input_short' | translate }}">
                                <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.tenVietTat"
                                    [control]="tenVietTat" [detail]="{ name: 'tenVietTat' }"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ 'ProfileBusiness_party_taxcode' | translate }} <span class="text-danger">*</span>:</label>
                                <input disabled class="form-control" formControlName="maSoThue" placeholder="{{ 'ProfileBusiness_party_input_taxcode' | translate }}">
                                <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.maSoThue"
                                    [control]="maSoThue" [detail]="{ name: 'maSoThue' }"></app-show-validate-errors>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="tinhId">{{ 'ProfileBusiness_agent_city' | translate }}</label>
                                <ng-select labelForId="tinhId" placeholder="{{ 'ProfileBusiness_agent_input_city' | translate }}" formControlName="tinhId"
                                    (change)="tinhChange($event)">
                                    <ng-option *ngFor="let tinh of tinhBindData" [value]="tinh.id">{{ tinh.label }}</ng-option>
                                </ng-select>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="huyenId">{{ 'ProfileBusiness_agent_district' | translate }}</label>
                                <ng-select labelForId="huyenId" placeholder="{{ 'ProfileBusiness_agent_input_district' | translate }}" formControlName="huyenId"
                                    (change)="huyenChange($event)">
                                    <ng-option *ngFor="let huyen of huyenBindData" [value]="huyen.id">{{ huyen.label }}
                                    </ng-option>
                                </ng-select>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="xaId">{{ 'ProfileBusiness_agent_wards' | translate }}</label>
                                <ng-select labelForId="xaId" placeholder="{{ 'ProfileBusiness_agent_input_wards' | translate }}" formControlName="xaId"
                                    (change)="xaChange($event)">
                                    <ng-option *ngFor="let xa of xaBindData" [value]="xa.id">{{ xa.label }}</ng-option>
                                </ng-select>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ 'ProfileBusiness_agent_road' | translate }}</label>
                                <input formControlName="duong" type="text" class="form-control" placeholder="{{ 'ProfileBusiness_agent_input_road' | translate }}" />
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ 'ProfileBusiness_agent_number' | translate }}</label>
                                <input formControlName="soNha" type="text" class="form-control" placeholder="{{ 'ProfileBusiness_agent_number' | translate }}" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ 'ProfileBusiness_party_license' | translate }}</label>
                                <p class="text-decoration-underline text-primary" style="cursor: pointer; font-weight: 600;" (click)="actDownloadGPKD(partyInfo.partyId)">
                                    {{partyInfo?.gpkdFilename}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Thông tin người đại diện -->
                <div class="col-md-12">
                    <label class="text-primary">{{ 'ProfileBusiness_agent' | translate }}</label>
                    <hr class="mt-0">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ 'ProfileBusiness_agent_name' | translate }} <span class="text-danger">*</span>:</label>
                                <input class="form-control" formControlName="hoVaTen" placeholder="{{ 'ProfileBusiness_agent_input_name' | translate }}">
                                <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.hoVaTen"
                                    [control]="hoVaTen" [detail]="{ name: 'hoVaTen' }"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ 'ProfileBusiness_agent_phone' | translate }}</label>
                                <div class="input-group">
                                    <input class="form-control" formControlName="sdt" placeholder="{{ 'ProfileBusiness_agent_input_phone' | translate }}"
                                    (input)="handleInputSdt($event)" disabled>
                                    <div class="input-group-append">
                                        <button *ngIf="partyInfo.isActiveUpdatePhoneNumber" (click)="updateEmailSDT('sdt')" class="btn btn-outline-primary d-inline-flex ml-3" style="border-radius: 4px !important;"><span
                                            class="material-icons mr-1">edit</span> Cập nhật</button>
                                    </div>
                                </div>
                                <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.sdt" [control]="sdt"
                                    [detail]="{ name: 'sdt' }"></app-show-validate-errors>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="gender">{{ 'ProfileBusiness_agent_gender' | translate }}</label>
                                <ng-select labelForId="gender" [searchable]="false" placeholder="{{ 'ProfileBusiness_agent_input_gender' | translate }}"
                                    formControlName="gender">
                                    <ng-option [value]="2">{{ 'ProfileBusiness_agent_male' | translate }}</ng-option>
                                    <ng-option [value]="1">{{ 'ProfileBusiness_agent_female' | translate }}</ng-option>
                                </ng-select>
                                <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.gender"
                                    [control]="gender" [detail]="{ name: 'gender' }">
                                </app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label>{{ 'ProfileBusiness_agent_birth' | translate }}</label>
                                <!-- (input)="dateInput($event, ngaySinh)" -->
                                <input appInputMark [options]="optionsDateFormat" 
                                    [control]="formSubmit.controls.ngaySinh"
                                    [value]="formSubmit.value.ngaySinh" type="text" class="form-control date"
                                    placeholder="{{ 'ProfileBusiness_agent_input_birth' | translate }}" id="ngaySinh">
                                <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.ngaySinh"
                                    [control]="ngaySinh" [detail]="{ name: 'ngaySinh' }"></app-show-validate-errors>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

<app-change-email-sdt 
    *ngIf="isUpdateEmailSDT"
    [oldValue]="oldValue" 
    [updateForm]="updateForm"
    (onActionCancel)="actionCancel()"
    (confirmOTPSuccess)="actionSuccess()"
    >
</app-change-email-sdt>
<!-- <app-mini-spinner *ngIf="loadingService.isVisible() | async"></app-mini-spinner> -->
<app-preview-modal *ngIf="showPreviewGPKD" [file]="fileGPKD" [name]="partyInfo.gpkdFilename" (close)="handleClosePreview()"></app-preview-modal>