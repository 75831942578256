<!-- MARK màn hình khi chưa có chữ kí số -->
<app-mini-spinner *ngIf="spinnerLgShow() | async"></app-mini-spinner>
<section *ngIf="listUsbToken.length == 0 && !isfilter" class="content">
    <div class="text-center">
        <img src="assets/img/services/emty-signature-img.png" style="margin-top: 5px;" class="col-md-3">
        <div class="mb-2">
            <p class="mb-1">{{ 'USBTokenManager_ban_chua_co_thong_tin' | translate }}</p>
            <p>{{ 'USBTokenManager_vui_long_cam_usb' | translate }}</p>
        </div>
        <div class="px-md-5 p-3 ">
            <a class="text-primary cursor-pointer" (click)="addSignUsbToken()">{{ 'USBTokenManager_them_chu_ky' | translate }}</a>
        </div>
    </div>
</section>

<!-- MARK đã có dữ liệu chữ ký số USB-Token -->
<section *ngIf="listUsbToken.length > 0 || isfilter">
    <div class="row">
        <div class="col-md-4">
            <div class="card shadow-none pb-2">
                <div class="card-body">
                    <div class="row pt-2 pb-2">
                        <div class="col-md-auto align-self-end">
                            <label class="text-lg">{{ 'USBTokenManager_danh_sach_chu_ky_so' | translate }}</label>
                        </div>
                        <div class="col-md-auto ml-auto">
                            <button class="btn btn-primary d-inline-flex" (click)="addSignUsbToken()"><span
                                    class="material-icons mr-2">add</span>
                                    {{ 'USBTokenManager_them_chu_ky' | translate }}</button>
                        </div>
                    </div>
                    <div class="input-group mt-3">
                        <form [formGroup]="formSearch" (ngSubmit)="searchData(formSearch.value.search)"
                            class="input-group">
                            <div class="input-group-prepend">
                                <button class="btn btn-primary d-inline-flex pr-2 pl-2" type="submit"><span
                                        class="material-icons">search</span></button>
                            </div>
                            <input (input)="eventInputSearch($event)" type="text" class="form-control"
                                formControlName="search" placeholder="{{ 'USBTokenManager_nhap_ten_chu_so_huu' | translate }}" aria-label=""
                                aria-describedby="basic-addon1">
                        </form>
                    </div>

                </div>
                <div class="position-relative">
                    <app-mini-spinner *ngIf="spinnerLShow() | async"></app-mini-spinner>
                    <ng-template #noData>
                        <div class="no-content-list">
                            <img class="mb-2" src="assets/img/icon/icon-no-user-group.png" width="100">
                            <p class="mb-1">{{ 'USBTokenManager_ban_chua_co_thong_tin' | translate }}</p>
                        </div>
                    </ng-template>

                    <div *ngIf="listUsbToken.length > 0; else noData" class="content-list fade show">
                        <div *ngFor="let item of listUsbToken;" (click)="selectItem(item)"
                            class="user-group-item content-list-item px-4 pt-2"
                            [class.active]="usbTokenSelected.serial === item.serial">
                            <div class="border-bottom d-flex">
                                <span class="material-icons icon-item pr-3">gesture</span>
                                <div class="flex-grow-1">
                                    <label class="title-item">{{ item.subjectCN }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="totalElement > maxSize" class="pagination justify-content-end pages">
                <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="5" [rotate]="true"
                    [ellipses]="false" [pageSize]="maxSize" (pageChange)="loadPage($event)" [boundaryLinks]="true"
                    aria-label="Default pagination">
                    <ng-template ngbPaginationFirst>{{ 'text_first' | translate }}</ng-template>
                    <ng-template ngbPaginationLast>{{ 'text_last' | translate }}</ng-template>
                    <ng-template ngbPaginationPrevious>&laquo;</ng-template>
                    <ng-template ngbPaginationNext>&raquo;</ng-template>
                    <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                </ngb-pagination>
            </div>
        </div>
        <div class="col-md-8">
            <div *ngIf="usbTokenSelected.serial !== ''" class="">
                <div *ngIf="!isEditConfig" class="card shadow-none">
                    <div class="card-body position-relative">
                        <form>
                            <div class="row">
                                <div class="col-md-auto align-self-end">
                                    <h4><label>{{ 'USBTokenManager_thong_tin_chu_ky_so' | translate }}</label></h4>
                                </div>
                                <div class="col-md-auto ml-auto">
                                    <div class="d-flex justify-content-end mb-2">
                                        <button class="btn btn-outline-primary d-inline-flex mr-4" (click)="actDeleteUsbToken(usbTokenSelected)"><span
                                                class="material-icons mr-2">delete</span>{{ 'USBTokenManager_xoa' | translate }}</button>
                                        <button class="btn btn-outline-primary d-inline-flex mr-4" (click)="actSettings()"><span
                                                class="material-icons mr-2">settings</span>{{ 'USBTokenManager_cau_hinh' | translate }}</button>
                                        <button class="btn btn-primary d-inline-flex" (click)="actUpdateUsbToken()"><span
                                                class="material-icons mr-2">autorenew</span>{{ 'USBTokenManager_cap_nhat' | translate }}</button>
                                    </div>
                                </div>
                            </div>
                            <hr>

                            <div class="row">
                                <div class="col-md-auto align-self-end">
                                    <label class="text-primary">{{ 'USBTokenManager_thong_tin_chi_tiet' | translate }}</label>
                                </div>
                                <div class="col-md-auto ml-auto">
                                </div>
                            </div>
                            <hr>
                            <div class="row mb-3">
                                <div class="col-md-4 col-lg-3">
                                    <label>{{ 'USBTokenManager_ten_chu_so_huu' | translate }}</label>
                                </div>
                                <div class="col-md-8 col-lg-9">
                                    <span>{{ usbTokenSelected.subjectCN }}</span>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-4 col-lg-3">
                                    <label>{{ 'USBTokenManager_serial_cert' | translate }}</label>
                                </div>
                                <div class="col-md-8 col-lg-9">
                                    <span>{{ usbTokenSelected.serial }}</span>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-4 col-lg-3">
                                    <label>{{ 'USBTokenManager_ten_to_chuc_so_huu' | translate }}</label>
                                </div>
                                <div class="col-md-8 col-lg-9">
                                    <span>{{ usbTokenSelected.issuerCN }}</span>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-4 col-lg-3">
                                    <label *ngIf="usbTokenSelected.passport || usbTokenSelected.personalId">{{ 'USBTokenManager_ma_nguoi_dung' | translate }}</label>
                                    <label *ngIf="usbTokenSelected.taxNumber">{{ 'USBTokenManager_ma_so_thue' | translate }}</label>
                                </div>
                                <div class="col-md-8 col-lg-9">
                                    <span>{{ usbTokenSelected.passport || usbTokenSelected.personalId || usbTokenSelected.taxNumber }}</span>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-4 col-lg-3">
                                    <label>{{ 'USBTokenManager_tu_ngay' | translate }}</label>
                                </div>
                                <div class="col-md-8 col-lg-9">
                                    <span>{{ usbTokenSelected.notBefore | date: "dd/MM/yyyy" }}</span>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-4 col-lg-3">
                                    <label>{{ 'USBTokenManager_ngay_het_han' | translate }}</label>
                                </div>
                                <div class="col-md-8 col-lg-9">
                                    <span>{{ usbTokenSelected.notAfter | date: "dd/MM/yyyy" }}</span>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-auto align-self-end">
                                    <label class="text-primary">{{ 'USBTokenManager_thong_tin_cau_hinh' | translate }}</label>
                                </div>
                                <div class="col-md-auto ml-auto">
                                </div>
                            </div>
                            <hr>
                            <div class="row mb-3">
                                <div class="col-md-4 col-lg-3">
                                    <label>{{ 'USBTokenManager_hinh_thuc_hien_thi' | translate }}</label>
                                </div>
                                <div class="col-md-8 col-lg-9">
                                    <div class="mb-3" [ngSwitch]="usbTokenSelected.signatureDefault.visibleType">
                                        <span *ngSwitchCase="0">{{ 'ContractCensorship_ts_11' | translate }}</span>
                                        <span *ngSwitchCase="1">{{ 'ServiceContractDetail_usbtoken_signType_imageDescrip' | translate }}</span>
                                        <span *ngSwitchCase="2">{{ 'ServiceContractDetail_usbtoken_signType_onlyImage' | translate }}</span>
                                        <span *ngSwitchDefault></span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-4 col-lg-3">
                                    <label>{{ 'USBTokenManager_noi_dung_mo_ta' | translate }}</label>
                                </div>
                                <div class="col-md-8 col-lg-9">
                                    <span *ngIf="usbTokenSelected.signatureDefault.signBy && usbTokenSelected.signatureDefault.signDate">{{ 'VerifySignature_sign_by_sign_time' | translate }}</span>
                                    <span *ngIf="!usbTokenSelected.signatureDefault.signBy && usbTokenSelected.signatureDefault.signDate">{{ 'VerifySignature_sign_time' | translate }}</span>
                                    <span *ngIf="usbTokenSelected.signatureDefault.signBy && !usbTokenSelected.signatureDefault.signDate">{{ 'VerifySignature_sign_by' | translate }}</span>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-4 col-lg-3">
                                    <label>{{ 'USBTokenManager_co_chu' | translate }}</label>
                                </div>
                                <div class="col-md-8 col-lg-9">
                                    <span>{{ usbTokenSelected.signatureDefault.fontSize }}</span>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-md-4 col-lg-3">
                                    <label>{{ 'USBTokenManager_hinh_anh' | translate }}</label>
                                </div>
                                <div class="col-md-8 col-lg-9">
                                    <img *ngIf="usbTokenSelected.signatureDefault.base64Image" src="{{ 'data:image/png;base64,' +  usbTokenSelected.signatureDefault.base64Image}}" width="200" height="auto" style="max-width: 100%;" >
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <app-usb-token-config *ngIf="isEditConfig" 
                    [config]="usbTokenSelected.signatureDefault" 
                    [configId]="usbTokenSelected.serial"
                    (cancel)="handleCancelEdit($event)"
                    (save)="handleUpdatedConfig($event)">
                </app-usb-token-config>
            </div>
        </div>
    </div>
</section>