import { Injectable } from '@angular/core';
import { ServicePath } from 'src/app/utils/common/constant-service-api';
import { API } from 'src/app/utils/services/api';

const GET_REQUEST_PREODER = ServicePath.EPAYMENT_SERVICE + "/orders";
const GET_DETAIL_ORDER = ServicePath.EPAYMENT_SERVICE + "/orders";
const GET_EMPLOYEES = ServicePath.EPAYMENT_SERVICE + "/orders/employees";

@Injectable({
  providedIn: 'root'
})
export class OneSmeService {

  constructor(
    private api: API
  ) { }

  getRequestPreoder(page, maxSize, propertiesSort, sort, fullText, fromDate, toDate, orderStatus) {
    const url = GET_REQUEST_PREODER + `?fullText=${fullText}&fromDate=${fromDate}&toDate${toDate}&orderStatus=${orderStatus}&page=${page}&maxSize=${maxSize}&sort=${sort}&propertiesSort=${propertiesSort}`;
    return this.api.get(url);
  }

  getOrder(id) {
    const url = GET_DETAIL_ORDER + `/${id}`;
    return this.api.get(url);
  }

  getEmployees(id, page, maxSize, sort, propertiesSort) {
    const url = GET_EMPLOYEES + `/${id}?page=${page}&maxSize=${maxSize}&sort=${sort}&propertiesSort=${propertiesSort}`;
    return this.api.get(url);
  }
}
