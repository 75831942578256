import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { API } from './api';
import * as jwt_decode from 'jwt-decode';
import { Token } from '../models/token.model';
import { UserInformationsResponse } from '../models/profile/user-info-response.model';
import { timeStamp } from 'console';
import { GlobalConfig } from '../common/global-config/global-config';
import { AasConsumerManagerService } from './aas-network/aas-consumer-manager.service';
import { Constant } from '../common/constant_videocall';
import { HttpHeaders, HttpClient, HttpRequest, HttpParams } from '@angular/common/http';
import { ConstLocalStorage } from '../common/constant';
import { ConstantUrl } from '../common/constant-url';
import { NotificationsService } from './aas-network/notifications.service';
import { FirebaseService } from './aas-network/firebase/firebase.service';
import { ServicePath } from '../common/constant-service-api';
import { InfoPartyObject } from '../models/aas-models/info-party/info-party-object';
import { UserInformationsItem } from '../models/profile/user-info-item.model';
import { BehaviorSubject } from 'rxjs';
import { WebviewURL } from 'src/app/webview/wv-app/wv-ultils/wv-const-url';

const LOGIN_URL = ServicePath.MANAGEMENT_SERVICE + '/users/login';
const LOGIN_OAUTH = ServicePath.AUTH_SERVICE + '/oauth/token'
const USER_INFO = ServicePath.MANAGEMENT_SERVICE + '/v2/user-informations';
const GET_INFO_CUSTOMER_URL = ServicePath.MANAGEMENT_SERVICE + '/parties';
const RESET_PASSWORD = ServicePath.AUTH_SERVICE + '/confirm-forgot-password';
const RESET_PASSWORD_REQUEST = ServicePath.AUTH_SERVICE + '/create-forgot-password';
const CONFIRM_TOKEN_RESET_PASSWORD = ServicePath.MANAGEMENT_SERVICE + '/users/check-reset-password-token';
const CHANGE_PASSWORD = ServicePath.AUTH_SERVICE + '/change-password';
const DS_NOIBO = ServicePath.MANAGEMENT_SERVICE + '/parties';
const UPDATE_NOIBO = ServicePath.MANAGEMENT_SERVICE + 'e/parties';
const REVOKE_TOKEN = ServicePath.AUTH_SERVICE + '/oauth/token/revoke_token';
const CREATE_FORGOT_PASSWORD = ServicePath.AUTH_SERVICE + '/create-forgot-password';
const CONFIRM_CHANGE_PASSWORD = ServicePath.AUTH_SERVICE + '/confirm-change-password';
const SERVICE_GET_PARTY_INFO = ServicePath.MANAGEMENT_SERVICE + '/parties';

const base_url = 'https://explorer.idg.vnpt.vn/router-service/api/';
const TOKEN =
    'bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOlsicmVzdHNlcnZpY2UiXSwidXNlcl9uYW1lIjoiZG9hbnBoYW50aGFuaDEzMDVAZ21haWwuY29tIiwiYXV0aG9yaXRpZXMiOlsiVVNFUiJdLCJqdGkiOiI3OTEzNjRjZi01OTFjLTQ2YmYtOGQ0Ny0yOTYzOTdiYWQ1NTQiLCJjbGllbnRfaWQiOiJhZG1pbmFwcCIsInNjb3BlIjpbInJlYWQiXX0.6EwQhluXY9ak1SLEqAvLxnFtqzBUXl-Dz3AdgIBuwmZwvQwTijm3GNbXmDvv0dAilk1_BUzKJDGyb8uvjgEv63NbCt22m04ox2RfejfiOO6_I7GqjD8HaQKYV8NyinluxObECBV7pUHzSY9oo9XtER1p0zoG5p4CdB2W_3rd1i-uxkSiKrOK_ifx4UPHxMP-2owrtPb9mK3ds-mfvO7bE_AMSrpykA35yXafPwOYOcgbnUWUSmVlhasqSDyan4EQHJ66l477P2uQXpA8sEF_oV3vfJ9daJlOyVVmoal2KuoU1pUGlG0lhdJJGmjxkGyXnyaKUxz58PSHZ_mJzq9qTw';
const TOKEN_ID = 'aaa17433-e3c2-064f-e053-604fc10aab28';
const TOKEN_KEY =
    'MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAMlGceImXOf3AUE800nc/NfpIBNHT4nR1FaRSFADcyyMyXI8P9N8QWTsQnS5QrOGr5Sp2tP22xOfk+dpyRhlNzsCAwEAAQ==';
const PUSH_NOTIFY= ServicePath.MANAGEMENT_SERVICE
const THONG_TIN_TO_CHUC= ServicePath.MANAGEMENT_SERVICE +'/parties'

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    public partyInfo: BehaviorSubject<InfoPartyObject> = new BehaviorSubject<InfoPartyObject>(new InfoPartyObject());
    public userInfo: BehaviorSubject<UserInformationsItem> = new BehaviorSubject<UserInformationsItem>(new UserInformationsItem());

    public set internalPartyId(value) { localStorage.setItem(ConstLocalStorage.INTERNAL_PARTY, value); }
    public get internalPartyId() { return localStorage.getItem(ConstLocalStorage.INTERNAL_PARTY) || ""; }
    public get partyId() {
        return localStorage.getItem(ConstLocalStorage.PARTYID) || "";
    }

    public static instance: AuthService;

    constructor(private router: Router,
        private http: HttpClient,
        private api: API,
        private firebaseService: FirebaseService) {
            if (!AuthService.instance) {
                AuthService.instance = this;
            }
            return AuthService.instance;
        }

    /**
     * Đăng nhập
     * @param data 
     */
    login(data) {
        return this.api.post(LOGIN_URL, data);
    }

    /**
     * Đăng xuất
     */
    logout() {
        this.getToken && this.postRevokeToken();
        //localStorage.getItem(Constant.UUID) && this.firebaseService.removeDevice(localStorage.getItem(Constant.UUID));
        //this.removeAllLocalStorage();
        this.removeTokenLocal()
    }

    /**
     * Webview logout
     */
    wvLogout() {
        this.getToken && this.postRevokeToken();
        this.removeAllLocalStorage();
        this.router.navigate([WebviewURL.login]);
    }

    removeDevice() {
        console.log('uuid', localStorage.getItem(Constant.UUID));
        let param = {
            deviceId: localStorage.getItem(Constant.UUID),
            idgTokenId: TOKEN_ID
        }

        let options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify(param)
        };

        this.http.delete(base_url + 'remove-device', options).subscribe(res => {

        }, err => {

        });
    }

    postRevokeToken() {
        const url = REVOKE_TOKEN;
        this.api.post(url, null).subscribe(res => {
            // this.removeTokenLocal();
        }, err => {
            // this.removeTokenLocal();
        });
    }

    /**
     * Xoá phiên đăng nhập
     */
    removeTokenLocal() {
        this.removeAllLocalStorage();
        this.router.navigate([ConstantUrl.LOGIN]);
    }

    loginOauthToken(data) {
        return this.api.post(LOGIN_OAUTH, data);
    }

    postOauthToken(data) {
        return this.api.post(LOGIN_OAUTH, data);
    }

    getUserInfo(id) {
        const url = USER_INFO + `/${id}`;
        return this.api.get(url);
    }

    getUserInfoNew(id) {
        const url = GET_INFO_CUSTOMER_URL + `/${id}`;
        return this.api.get(url);
    }

    /**
     * Request lấy thông tin tổ chức
     * @param partyId 
     */
    getPartyInfo(partyId) {
        const url = SERVICE_GET_PARTY_INFO + `/${partyId}`;
        return this.api.get(url);
    }

    resetPasswordRequest(email, domain = '') {
        const url = RESET_PASSWORD_REQUEST + `?email=${email}&domain=${domain}`;
        return this.api.request('POST', url, null);
    }

    /**
     * Request tạo yêu cầu quên mật khẩu
     * @param type //CONSUMER - BUSINESS
     * @param user 
     * @param domain 
     */
    postCreateForgotPassword(type, user, domain = '') {
        let url = '';
        switch (type) {
            case 'CONSUMER':
                url = CREATE_FORGOT_PASSWORD + `?phoneNumber=${user}&domain=${domain}`;
                break;
            case 'BUSINESS':
                url = CREATE_FORGOT_PASSWORD + `?email=${user}&domain=${domain}`;
                break;
            case 'WITH_USERNAME':
                url = CREATE_FORGOT_PASSWORD + `?userName=${user}&domain=${domain}&phoneNumber=&email=`;
                break;
        }

        return this.api.post(url, null);
    }

    /**
     * Request đổi mật khẩu mới
     * @param type 
     * @param user 
     * @param token 
     * @param param 
     */
    postConfirmChangePassword(userName, email, phoneNumber, token, param = null) {
        const url = CONFIRM_CHANGE_PASSWORD + `?token=${token}&email=${email}&phoneNumber=${phoneNumber}&userName=${userName}`;
        return this.api.post(url, param);
    }

    confirmTokenResetPassword(data) {
        const url = CONFIRM_TOKEN_RESET_PASSWORD;
        return this.api.request('POST', url, data);
    }

    postNewPassword(email, token, data) {
        const url = RESET_PASSWORD + `?email=${email}&token=${token}`;
        return this.api.request('POST', url, data);
    }

    postChangePassword(data) {
        const url = CHANGE_PASSWORD;
        return this.api.put(url, data);
    }

    getListToChucNoiBo(partyId) {
        const url = DS_NOIBO + `/${partyId}/party-search?string=*`;
        return this.api.get(url);
    }

    updateThongTinToChuc(partyId, data) {
        const url = UPDATE_NOIBO + `/${partyId}/update-info`;
        return this.api.request('POST', url, data);
    }

    setPartyId(partyId) {
        return localStorage.setItem(ConstLocalStorage.PARTYID, partyId);
    }

    //Save partyid on session
    setSessionPartyId(partyId) {
        return sessionStorage.setItem(ConstLocalStorage.PARTYID, partyId);
    }

    setUsername(username) {
        return localStorage.setItem(ConstLocalStorage.USERNAME, username);
    }

    //Save username on session
    setSessionUsername(username) {
        return sessionStorage.setItem(ConstLocalStorage.USERNAME, username);
    }

    setUserId(userId) {
        return localStorage.setItem(ConstLocalStorage.USERID, userId);
    }

    //Save userid on session
    setSessionUserId(userId) {
        return sessionStorage.setItem(ConstLocalStorage.USERID, userId);
    }

    get username() {
        return localStorage.getItem(ConstLocalStorage.USERNAME) ? localStorage.getItem(ConstLocalStorage.USERNAME) : sessionStorage.getItem(ConstLocalStorage.USERNAME);
    }

    get userId() {
        return localStorage.getItem(ConstLocalStorage.USERID) ? localStorage.getItem(ConstLocalStorage.USERID) : sessionStorage.getItem(ConstLocalStorage.USERID);
    }

    setToken(token) {
        return localStorage.setItem(ConstLocalStorage.ACCESS_TOKEN, token);
    }

    setSessionToken(token) {
        return sessionStorage.setItem(ConstLocalStorage.ACCESS_TOKEN, token);
    }

    getToken() {
        return localStorage.getItem(ConstLocalStorage.ACCESS_TOKEN) ? localStorage.getItem(ConstLocalStorage.ACCESS_TOKEN) : sessionStorage.getItem(ConstLocalStorage.ACCESS_TOKEN);
    }

    setKey(key) {
        return localStorage.setItem(ConstLocalStorage.KEY, key);
    }

    get key() {
        return localStorage.getItem(ConstLocalStorage.KEY) || '';
    }

    setPartyInfo(party: InfoPartyObject) {
        this.partyInfo.next(party);
    }

    setUserInfo(user: UserInformationsItem) {
        this.userInfo.next(user);
    }

    removeAllLocalStorage() {
        localStorage.removeItem(ConstLocalStorage.ACCESS_TOKEN);
        localStorage.removeItem(ConstLocalStorage.PARTYID);
        localStorage.removeItem(ConstLocalStorage.USERNAME);
        localStorage.removeItem(ConstLocalStorage.USERID);
        localStorage.removeItem(ConstLocalStorage.USERTYPE);
        localStorage.removeItem(ConstLocalStorage.KEY);
        localStorage.removeItem(ConstLocalStorage.ROOT_PARTY);
        localStorage.removeItem(ConstLocalStorage.INTERNAL_PARTY);
        localStorage.removeItem(ConstLocalStorage.REFRESH_TOKEN);
        localStorage.removeItem(ConstLocalStorage.SESSION_SIGN_JOB);
        localStorage.removeItem(ConstLocalStorage.USER_INFO);
        localStorage.removeItem(ConstLocalStorage.PARTY_INFO);
        // localStorage.clear();
        sessionStorage.clear();
    }

    jwtDecode(token) {
        if (token === "" || token === null || token === undefined) return;
        try {
            var decoded = jwt_decode(token);
        } catch (error) {
            this.removeAllLocalStorage();
            this.router.navigate([ConstantUrl.LOGIN]);
        }

        return new Token(decoded);
    }

    //UPDATE V2
    get isAdmin() {
        return this.userId === this.partyId;
    }

    /**
     * Kiểm tra quyền có trong jwt của accessToken hay không
     * @param condition 
     * @returns 
     */
    checkRoles(condition) {
        const token = this.getToken();
        const token_decode = this.jwtDecode(token);
    
        let ok = false;
    
        if (condition.length <= 0) return true;
    
        token_decode.authorities.forEach((item) => {
          if (condition.includes(item)) {
            ok = true
          }
        })
    
        return ok;
    }
    pushNotify(id, data){
        let url= PUSH_NOTIFY + `/party/${id}/update-notify-push-type`
        return this.api.post(url, data)
    }
    getThongTinToChuc(){
        const url= THONG_TIN_TO_CHUC +`/${this.partyId}`
        return this.api.get(url)
    }
       
    get user(): any {
        const user = localStorage.getItem(ConstLocalStorage.USER_INFO);
        try {
            return JSON.parse(user);
        } catch (error) {
            return null;
        }
    }

    get party(): any {
        const party = localStorage.getItem(ConstLocalStorage.PARTY_INFO);
        try {
            return JSON.parse(party);
        } catch (error) {
            return null;
        }
    }
}
