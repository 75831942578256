<div class="row">
    <div class="col-md-6 px-3 py-2">
        <div class="row pt-2 pb-2">
            <div class="col-md-auto align-self-end">
                <p class="mb-2"><label class="mb-0">Quyền chưa gán ({{countNotAssigned}})</label></p>
            </div>
            <div *ngIf="onlyView == false" class="col-md-auto ml-auto">
                <button (click)="assignMoreSelected(0, 1)" class="btn btn-primary d-inline-flex"
                    [attr.disabled]="enableBtnAssign(0) || disabled ? '' : null">Gán<span
                        class="material-icons ml-1">arrow_forward</span></button>
            </div>
        </div>

        <div class="input-group mb-3">
            <div class="input-group-prepend bg-white">
                <span class="input-group-text bg-white border-right-0 px-1 py-0"><span
                        class="material-icons">search</span></span>
            </div>
            <input #searchNotAssigned (input)="filterSearch($event, 0)" type="text" class="form-control border-left-0 pl-1"
                placeholder="Nhập tên quyền">

        </div>

        <div class="list-permission enable-scroll">
            <ul class="content-list-permisson">
                <li *ngIf="dualList[0].length > 0 && onlyView == false">
                    <div class="permission-group">
                        <span class="material-icons text-white p-2">check</span>
                        <div class="tcheck-primary w-100 d-inline-block">
                            <input [disabled]="disabled" (input)="selectAllBtn(0)" type="checkbox" id="select-all-assign" [attr.checked]="selectedAll[0] ? '' : null">
                            <label for="select-all-assign" class="w-100" [class.checked]="selectedAll[0]">
                                Tất cả
                            </label>
                        </div>
                        <span
                            class="material-icons icon-set-permission text-white p-2">arrow_forward</span>
                    </div>
                </li>
                <li *ngFor="let li of dualList[0]; let i = index">
                    <div class="permission-group">
                        <span (click)="showHideTreeView($event, i, 0)" class="material-icons p-2" [class.text-white]="li.child.length <= 0" style="cursor: pointer;">chevron_right</span>
                        <label *ngIf="onlyView == true" class="w-100 d-inline-block align-self-center">{{li.name}}</label>
                        <div *ngIf="onlyView == false" class="tcheck-primary w-100 d-inline-block">
                            <input [disabled]="disabled" (input)="selectedParentItem(i, 0)"
                                [attr.checked]="li.selected ? '' : null" type="checkbox"
                                id="parent-{{li.id}}">
                            <label for="parent-{{li.id}}" class="w-100"
                                [class.checked]="li.selected">
                                {{li.name}}
                            </label>
                        </div>
                        <span *ngIf="!disabled && onlyView == false" (click)="assignGroup(i, 0, 1)"
                            class="material-icons icon-set-permission p-2" style="cursor: pointer;">arrow_forward</span>
                    </div>
                    <ul *ngIf="li.child.length > 0 && li.showChild" class="permission-child">
                        <li *ngFor="let subli of li.child; let j = index">
                            <div *ngIf="onlySelectParent || onlyView == true; else allowSelectChill" class="w-100 d-inline-block align-self-center">
                                <span id="child-{{subli.id}}" class="w-100 mb-0 py-2 d-inline-block">
                                    {{subli.name}}
                                </span>
                            </div>
                            <ng-template #allowSelectChill>
                                <div class="tcheck-primary w-100 d-inline-block">
                                    <input [disabled]="disabled" (input)="selectedChildItem(i, j, 0)"
                                        [attr.checked]="subli.selected ? '' : null" type="checkbox"
                                        id="child-{{subli.id}}">
                                    <label for="child-{{subli.id}}" class="w-100"
                                        [class.checked]="subli.selected">
                                        {{subli.name}}
                                    </label>
                                </div>
                                <span *ngIf="!disabled" (click)="assign(i, j, 0, 1)"
                                    class="material-icons icon-set-permission p-2">arrow_forward</span>
                            </ng-template>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
    <div class="col-md-6 px-3 py-2">
        <div class="row pt-2 pb-2">
            <div class="col-md-auto align-self-end">
                <p class="mb-2"><label class="mb-0">Quyền đã gán ({{countAssigned}})</label></p>
            </div>
            <div *ngIf="onlyView == false" class="col-md-auto ml-auto">
                <button [disabled]="disabled" (click)="assignMoreSelected(1, 0)" class="btn btn-primary d-inline-flex"
                    [attr.disabled]="enableBtnAssign(1) ? '' : null">Hủy gán<span
                        class="material-icons ml-1">clear</span> </button>
            </div>
        </div>

        <div class="input-group mb-3">
            <div class="input-group-prepend bg-white">
                <span class="input-group-text bg-white border-right-0 px-1 py-0"><span
                        class="material-icons">search</span></span>
            </div>
            <input #searchAssigned (input)="filterSearch($event, 1)" type="text" class="form-control border-left-0 pl-1"
                placeholder="Nhập tên quyền">
        </div>

        <div class="list-permission">
            <ul class="content-list-permisson">
                <li *ngIf="dualList[1].length > 0 && onlyView == false">
                    <div class="permission-group">
                        <span class="material-icons text-white p-2">check</span>
                        <div class="tcheck-primary w-100 d-inline-block">
                            <input [disabled]="disabled" (input)="selectAllBtn(1)" type="checkbox" id="select-all-unassign" [attr.checked]="selectedAll[1] ? '' : null">
                            <label for="select-all-unassign" class="w-100" [class.checked]="selectedAll[1]">
                                Tất cả
                            </label>
                        </div>
                        <span
                            class="material-icons icon-set-permission text-white p-2">arrow_forward</span>
                    </div>
                </li>
                <li *ngFor="let li of dualList[1]; let i = index">
                    <div class="permission-group">
                        <span (click)="showHideTreeView($event, i, 1)" class="material-icons p-2" [class.text-white]="li.child.length <= 0" style="cursor: pointer;">chevron_right</span>
                        <label *ngIf="onlyView == true" class="w-100 d-inline-block align-self-center">{{li.name}}</label>
                        <div *ngIf="onlyView == false" class="tcheck-primary w-100 d-inline-block">
                            <input [disabled]="disabled" (input)="selectedParentItem(i, 1)"
                                [attr.checked]="li.selected ? '' : null" type="checkbox"
                                id="assigned-parent-{{li.id}}">
                            <label for="assigned-parent-{{li.id}}" class="w-100"
                                [class.checked]="li.selected">
                                {{li.name}}
                            </label>
                        </div>
                        <span *ngIf="!disabled && onlyView == false" (click)="assignGroup(i, 1, 0)"
                            class="material-icons icon-set-permission p-2" style="cursor: pointer;">clear</span>
                    </div>
                    <ul *ngIf="li.child.length > 0 && li.showChild" class="permission-child">
                        <li *ngFor="let subli of li.child; let j = index">
                            <div *ngIf="onlySelectParent || onlyView == true; else allowSelectChill" class="w-100 d-inline-block align-self-center">
                                <span id="child-{{subli.id}}" class="w-100 py-2 d-inline-block">
                                    {{subli.name}}
                                </span>
                            </div>
                            <ng-template #allowSelectChill>
                                <div class="tcheck-primary w-100 d-inline-block">
                                    <input [disabled]="disabled" (input)="selectedChildItem(i, j, 1)"
                                        [attr.checked]="subli.selected ? '' : null" type="checkbox"
                                        id="assigned-child-{{subli.id}}">
                                    <label for="assigned-child-{{subli.id}}" class="w-100"
                                        [class.checked]="subli.selected">
                                        {{subli.name}}
                                    </label>
                                </div>
                                <span *ngIf="!disabled" (click)="assign(i, j, 1, 0)"
                                    class="material-icons icon-set-permission p-2">clear</span>
                            </ng-template>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</div>
