import { ApiListAllItem } from './api-list-all-item.model';

export class ApiListAllObject {
    data: ApiListAllItem[] = [];
    maxSize: number;
    page: number
    propertiesSort: string = '';
    sort: string = '';
    totalElement: number

    constructor(data = null) {
        data = data || {};

        this.data = [];
        if (data.data && data.data instanceof Array) {
            data.data.forEach(item => {
                this.data.push(new ApiListAllItem(item))
            });
        };

        this.maxSize = data.maxSize;
        this.page = data.page;
        this.propertiesSort = data.propertiesSort || '';
        this.sort = data.sort || '';
        this.totalElement = data.totalElement;
    }
}