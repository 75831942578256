import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/utils/services/auth.service';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { SignatureService } from 'src/app/utils/services/aas-network/signature.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InfoSignatureConfigResponse } from 'src/app/utils/models/aas-models/signature/signature-config/info-signature-config-response';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { InfoSignatureConfigObject } from 'src/app/utils/models/aas-models/signature/signature-config/info-signature-config-object';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { AasValidateMessage } from 'src/app/utils/common/validate-msg-aas';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { TranslateService } from '@ngx-translate/core';

declare var default_img: any;
@Component({
  selector: 'app-service-signature-config',
  templateUrl: './service-signature-config.component.html',
  styleUrls: ['./service-signature-config.component.scss']
})
export class ServiceSignatureConfigComponent implements OnInit {
  @Input() id: string = '';
  @Output() public onCancel: EventEmitter<any> = new EventEmitter<any>();

  spinnerR = new BehaviorSubject<boolean>(false);

  configCA: InfoSignatureConfigObject = new InfoSignatureConfigObject();

  formUpdateConfig: FormGroup;
  isSubmit: boolean = false;
  inputFileLabel: string = 'ESign_ts_1';
  fileType = ['image/jpeg', 'image/png', 'image/jpg'];
  fileImg: File;
  imgContent: string = '';
  img_prefix = "data:image/png;base64,";

  validMsg = AasValidateMessage.SIGNATURE_CONFIG;
  options = [
    { value: 0, name: "ContractCensorship_ts_10" },
    { value: 1, name: "ContractCensorship_ts_11" },
    { value: 2, name: "ContractCensorship_ts_12" },
    { value: 3, name: "ContractCensorship_ts_13" },
    { value: 4, name: "ContractCensorship_ts_14" },
    { value: 5, name: "ContractCensorship_ts_15" }
  ]

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private aasAuth: AasAuthService,
    private signatureService: SignatureService,
    private modalService: NgbModal,
    private alert: AlertControl,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.makeFormUpdateconfig();

    this.getInfoSignatureConfig(this.id);
  }

  //MARK: Spinner
  spinnerRShow(): Observable<boolean> {
    return this.spinnerR.asObservable().pipe(share());
  }

  //MARK: Khởi tạo form cập nhật config
  makeFormUpdateconfig() {
    this.formUpdateConfig = this.fb.group({
      signerName: new FormControl('',),
      textColor: new FormControl('',),
      visibleType: new FormControl('5'),
      base64Image: new FormControl(""),
      fontSize: new FormControl("13", [Validators.min(8), Validators.max(20), Validators.pattern(/^[0-9]+$/)]),
      signBy: new FormControl(false),
      signDate: new FormControl(false),
      imageFile: new FormControl(""),
    })
  }

  get signerName() { return this.formUpdateConfig.get('signerName'); }
  get textColor() { return this.formUpdateConfig.get('textColor'); }
  get visibleType() { return this.formUpdateConfig.get('visibleType'); }
  get base64Image() { return this.formUpdateConfig.get('base64Image'); }
  get fontSize() { return this.formUpdateConfig.get('fontSize'); }
  get signBy() { return this.formUpdateConfig.get('signBy'); }
  get signDate() { return this.formUpdateConfig.get('signDate'); }
  get imageFile() { return this.formUpdateConfig.get('imageFile'); }

  //MARK: Set data cu
  setData(signConfig: InfoSignatureConfigObject) {
    this.signerName.setValue(signConfig.signerName || "");
    this.textColor.setValue('#' + signConfig.textColor);
    // this.colorPicker = '#' + signConfig.textColor;
    this.visibleType.setValue(signConfig.visibleType || 5);
    this.base64Image.setValue(signConfig.base64Image || default_img);
    this.fontSize.setValue(signConfig.fontSize || "13");
    this.signBy.setValue(signConfig.signBy || false);
    this.signDate.setValue(signConfig.signDate || false);

    this.imgContent = this.img_prefix + (signConfig.base64Image || default_img);
    let blob: any = this.converBase64toBlob(signConfig.base64Image, 'image/jpg');
    blob.lastModifiedDate = new Date();
    blob.name = 'e_sign';
    this.fileImg = <File>blob;
  }

  //Convert base64 sang blob
  converBase64toBlob(content, contentType): Blob {
    //blob = converBase64toBlob(response.content, 'application/pdf');
    contentType = contentType || '';
    var sliceSize = 512;
    var byteCharacters = window.atob(content); //method which converts base64 to binary
    var byteArrays = [
    ];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, {
      type: contentType,
    }); //statement which creates the blob
    return blob;
  }

  //MARK: Input fontSize
  inputFontSize(event) {

  }

  //MARK: Input file
  handleFileInput(e) {
    this.fileImg = e.target.files && e.target.files.item(0);
    console.log(this.fileImg);
    if (this.fileImg && this.fileImg !== null && this.fileImg !== undefined) {
      this.inputFileLabel = this.fileImg.name;

      if (!this.fileType.includes(this.fileImg.type)) {
        this.imageFile.setErrors({ type: true });
        this.imgContent = '';
      } else if (this.fileImg.size > 2000000) {
        this.imageFile.setErrors({ size: true });
        this.getBase64String(this.fileImg, this.handle);
      } else {
        this.getBase64String(this.fileImg, this.handle);

        var self = this;
        var u = URL.createObjectURL(this.fileImg);
        var img = new Image;
        img.src = u;
        img.onload = function () {
          self.resizeImage(img, 999, 999);
        }
      }

    } else {
      // this.imageFile.setErrors({ required: true });
      this.imgContent = '';
      this.inputFileLabel = 'ESign_ts_1';
    }
  }

  getBase64String(blob, callback) {
    let self = this;
    var reader = new FileReader();
    reader.onload = function (e) {
      callback(reader.result, self);
    }

    reader.readAsDataURL(blob);
  }

  handle(str, self) {
    console.log(str);
    self.imgContent = str;
  }

  // convert imgUrl to file object
  urltoFile(url, filename, mimeType) {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  // resize image 
  resizeImage(img, max_width, max_height) {
    var width = img.width;
    var height = img.height;

    // calculate the width and height, constraining the proportions
    if (width > height) {
      if (width > max_width) {
        //height *= max_width / width;
        height = Math.round(height *= max_width / width);
        width = max_width;
      }
    } else {
      if (height > max_height) {
        //width *= max_height / height;
        width = Math.round(width *= max_height / height);
        height = max_height;
      }
    }
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');
    canvas.width = width;
    canvas.height = height;
    ctx.drawImage(img, 0, 0, width, height);
    var self = this;
    let imgURL = ctx.canvas.toDataURL(this.fileImg.type, 1);
    this.urltoFile(imgURL, this.fileImg.name, this.fileImg.type).then(function (file) {
      self.fileImg = file;
    });
    canvas.remove();
  }

  //MARK: Submit update config
  submit() {
    this.isSubmit = true;
    console.log(this.formUpdateConfig);
    if (this.formUpdateConfig.valid) {
      let data = new FormData();
      data.append('certId', this.id);
      data.append('signBy', this.signBy.value);
      data.append('signDate', this.signDate.value);
      data.append('textColor', "#000");
      data.append('fontSize', this.fontSize.value);
      data.append('visibleType', this.visibleType.value);
      data.append('image', this.fileImg);

      this.alert.showAlert(this.translate.instant('SmartcaConfig_ts_1'), '', true, '', this.translate.instant('btn_confirm'), () => {
        this.postUpdateSignatureConfig(data);
      }, '', this.translate.instant('btn_cancel'));
      
    } else {

    }
  }

  //MAKR: Action cancel
  actCancel() {
    this.onCancel.emit();
  }

  //MARK: Nhóm chức năng ký hợp đồng
  handleFileImageInput(event) {
    
  }

  // //MARK: Popup thông báo
  // alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
  //   const modalRef = this.modalService.open(AlertComponent);
  //   modalRef.componentInstance.message = msg;
  //   modalRef.componentInstance.typeAlert = type;
  //   modalRef.componentInstance.twoBtn = twoBtn;
  //   modalRef.componentInstance.iconBtn1 = iconBtn1;
  //   modalRef.componentInstance.iconBtn2 = iconBtn2;
  //   modalRef.componentInstance.titleButton1 = titleBtn1;
  //   modalRef.componentInstance.titleButton2 = titleBtn2;
  //   modalRef.componentInstance.callback1 = actBtn1;
  //   modalRef.componentInstance.callback2 = actBtn2;
  // }

  // //MARK: Handle Error
  // handleError(err, errorMsg) {
  //   if (err.error && err.error.message && errorMsg[err.error.message]) {
  //     this.alert(errorMsg[err.error.message], 'error');
  //   } else if (err.error && err.error.error) {
  //     this.alert(err.error.error, 'error');
  //   } else {
  //     this.alert('Lỗi không xác định!', 'error');
  //   }
  // }

  //MARK: NETWORKING
  getInfoSignatureConfig(id) {
    this.spinnerR.next(true);
    this.signatureService.getInfoSignatureConfig(id).subscribe(res => {
      this.spinnerR.next(false);
      this.configCA = new InfoSignatureConfigResponse(res).object;
      this.setData(this.configCA);

      console.log(this.configCA);
    }, err => {
      this.spinnerR.next(false);
      console.log(err);
      this.alert.showErrorHandled(err, ResponseMsg.MESSAGES.SIGN_SERVER);
    })
  }

  postUpdateSignatureConfig(data: FormData) {
    this.spinnerR.next(true);
    this.signatureService.postUpdateSignatureConfig(data).subscribe(res => {
      this.spinnerR.next(false);

      this.alert.showAlertOnlyNoti(this.translate.instant('SmartcaConfig_ts_2'), 'success');
      this.getInfoSignatureConfig(this.id);
    }, err => {
      this.spinnerR.next(false);
      console.log(err);
      this.alert.showErrorHandled(err, ResponseMsg.MESSAGES.SIGN_SERVER);
    }, () => {

    });
  }
}
