import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { BLOG_TYPE_FORM } from 'src/app/utils/common/constant-vaildate-msg';
import { LandingpageService } from 'src/app/utils/services/aas-network/landingpage.service';

@Component({
  selector: 'app-service-landing-page-blog-type-add',
  templateUrl: './service-landing-page-blog-type-add.component.html',
  styleUrls: ['./service-landing-page-blog-type-add.component.scss']
})
export class ServiceLandingPageBlogTypeAddComponent implements OnInit {

  //form data
  formBlogTypeGroup: FormGroup;
  //validate
  validBlogTypeMsg: any = BLOG_TYPE_FORM.CREATE;
  //property
  isSubmit = false;

  constructor(
    private fb: FormBuilder,
    private landingService: LandingpageService,
    private modalService: NgbModal,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.makeFormBlogTypeGroup();
  }

  makeFormBlogTypeGroup(){
    this.formBlogTypeGroup = this.fb.group({
      name: ['', Validators.required]
    });
  }

  addBlogType(){
    if(!this.formBlogTypeGroup.valid){
      return false;
    }
    this.isSubmit = true;
    //post data
    this.landingService.addBlogType(this.formBlogTypeGroup.value)
    .subscribe(
      (res) => {
        this.isSubmit = false;
        this.alert('Thêm mới thành công!', 'success', false, '', 'OK', () =>{
          //reset form
          this.makeFormBlogTypeGroup();
          //chuyển trang
          this.router.navigate(['/console/landing-page/blog-type']);
          
        });
      },
      (err) => {
        this.isSubmit = false;
        this.handleError(err, err)
      }
    )
  }

  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], 'error');
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, 'error');
    } else {
      this.alert('Lỗi không xác định! Vui lòng thử lại', 'error');
    }
  }

}
