import { Component, Input, OnInit, AfterViewInit, AfterViewChecked, ViewChild, ElementRef, EventEmitter, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

declare var Prism: any;
@Component({
  selector: 'app-codeblock-editor',
  templateUrl: './codeblock-editor.component.html',
  styleUrls: ['./codeblock-editor.component.scss']
})
export class CodeblockEditorComponent implements OnInit {
  @ViewChild('codeContent') codeContent: ElementRef;
  @ViewChild('preCode') preCode: ElementRef;
  @ViewChild('textareaCode') textareaCode: ElementRef;

  @Input('url') url: string = '';
  @Input('method') method: string = 'RESPONSE';
  @Input('code') _code: BehaviorSubject<string> = new BehaviorSubject<string>("");
  @Input('type') type: string = 'javascript';

  @Output('onGetData') onGetData: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  set code(value) {
    this._code.next(value);
  };
  get code() {
    return this._code.getValue();
  }

  highlighted: boolean = false;
  mytext: any;

  constructor() { }

  ngOnInit(): void {
    this._code.subscribe((res) => {
      this.mytext = res;
    })
  }

  ngAfterViewInit(): void {
    this.allowTabTextarea();
  }

  /**
   * Highlight blog post when it's ready
   */
  ngAfterViewChecked() {
    // if (!this.highlighted) {
    //   this.initPrism();
    //   this.highlighted = true;
    // }
  }

  /**
   * Iniit PrismJs
   */
  initPrism() {
    Prism.highlightAll();
  }

  /**
   * HighLight code
   * @param code 
   * @param type 
   */
  highLightCode(code: string, type: string = "javascript"): string {
    const html_string = Prism.highlight(code, Prism.languages[type], type);
    return html_string;
  }

  /**
   * Copy Clipboard
   * @param el 
   */
  copyClipboard(e, el: Element) {
    const copyText = el.textContent;
    const textArea = document.createElement('textarea');
    console.log(copyText);
    textArea.textContent = copyText;
    document.body.append(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    e.target.innerHTML = "Đã sao chép!"; //Đã lưu vào bộ nhớ tạm
    this.resetStatusCopy(e);
  }

  resetStatusCopy(e) {
    setTimeout(function() {
      e.target.innerHTML = "Sao chép"
    }, 1000);
  }

  /**
   * Nhap lieu vao textarea
   * @param e 
   */
  inputTextarea(e) {
    // console.log(this.mytext);
    this.code = this.mytext;
    this.onGetData.emit(this.mytext);
  }

  /**
   * Cho phép Tab trong textarea
   */
  allowTabTextarea() {
    this.textareaCode.nativeElement.addEventListener('keydown', function(e) {
      if (e.key == 'Tab') {
        e.preventDefault();
        var start = this.selectionStart;
        var end = this.selectionEnd;
    
        // set textarea value to: text before caret + tab + text after caret
        this.value = this.value.substring(0, start) +
          "\t" + this.value.substring(end);
    
        // put caret at right position again
        this.selectionStart =
          this.selectionEnd = start + 1;
      }
    });
  }

  /**
   * Them su kien scroll textearea
   */
  addEventScroll() {
    var pre = this.preCode.nativeElement;
    var textarea = this.textareaCode.nativeElement;

    this.textareaCode.nativeElement.addEventListener("scroll", function() {
      if (pre.clientWidth === 0 && pre.clientHeight === 0) {
        return;
      }
  
      pre.scrollTop = textarea.scrollTop;
      pre.scrollLeft = textarea.scrollLeft;
      console.log("scrolled");
    }, {passive: true});
  }

  /**
   * Dong bo scroll cho pre
   */
  syncScroll() {
		if (this.preCode.nativeElement.clientWidth === 0 && this.preCode.nativeElement.clientHeight === 0) {
			return;
		}

		this.preCode.nativeElement.scrollTop = this.textareaCode.nativeElement.scrollTop;
		this.preCode.nativeElement.scrollLeft = this.textareaCode.nativeElement.scrollLeft;
	}
}
