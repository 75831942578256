<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-auto">
                <h1 class="m-0 text-dark"><label>Chi tiết khách hàng</label></h1>
            </div>

            <div class="col-md-auto ml-auto">
                <button class="btn btn-primary d-inline-flex mr-3"
                    (click)="moveToAssignPackageScreen()">
                    <img class="mr-2 mt-1" src="..\..\..\..\..\..\assets\img\icon\database_upgrade_24px.svg" alt="">
                    Gán gói hạ tầng mở rộng
                </button>

                <button class="btn btn-primary d-inline-flex" [routerLink]="['/console/account-manager/edit/consumer', partyId]">
                    <span class="material-icons mr-2">edit</span>
                    Chỉnh sửa
                </button>
            </div>

        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>

<section class="content">
    <div class="container-fluid">
        <div class="content-right-sidebar border-tab">
            <ul class="nav nav-tabs" id="tabSignAction" role="tablist">
                <li class="nav-item nav-material active">
                    <a class="nav-link d-flex active" id="customer-info-tab" data-toggle="pill" href="#customer-info"
                        role="tab" aria-controls="customer-info" aria-selected="true">
                        <em class="material-icons-outlined">info</em>
                        Thông tin khách hàng
                    </a>
                </li>

                <li class="nav-item nav-material">
                    <a class="nav-link d-flex align-items-center" id="package-info-tab" data-toggle="pill"
                        href="#package-info" role="tab" aria-controls="package-info" aria-selected="false">
                        <span class="ct-icon-font icon-custom_bill_outlined"></span>
                        Thông tin gói dịch vụ
                    </a>
                </li>

                <li class="nav-item nav-material">
                    <a class="nav-link d-flex" id="expansion-history-tab" data-toggle="pill" href="#expansion-history"
                        role="tab" aria-controls="expansion-history" aria-selected="false">
                        <em class="material-icons">history</em>
                        Lịch sử mở rộng hạ tầng
                    </a>
                </li>
            </ul>
        </div>
        <div class="tab-content contract-tab-body mt-3" id="tabSignAction">
            <div class="tab-pane fade show active" id="customer-info" role="tabpanel" aria-labelledby="customer-info">
                <app-customer-info-tab></app-customer-info-tab>
            </div>
            <div class="tab-pane fade show" id="package-info" role="tabpanel" aria-labelledby="package-info">
                <app-package-info-tab></app-package-info-tab>
            </div>
            <div class="tab-pane fade show" id="expansion-history" role="tabpanel" aria-labelledby="expansion-history">
                <app-expension-history-tab></app-expension-history-tab>
            </div>
        </div>
    </div>
</section>


<!-- <section class="content">
    <div class="container-fluid">
        
        <div class="row">
            <div class="col-md-7">
                <div class="card shadow-none">
                    <div class="card-body position-relative">
                        <label class="text-primary">Thông tin cá nhân</label>
                        <hr>
                        <div>
                            <div class="e-block-field row">
                                <div class="col-md-4 col-xl-3">
                                    <label class="e-lb-field">Họ và tên</label>
                                </div>
                                <div class="col-md-8">
                                    <span class="">{{personalDetailObject?.ten}}</span>
                                </div>
                            </div>
                            <div class="e-block-field row">
                                <div class="col-md-4 col-xl-3">
                                    <label class="e-lb-field">Số giấy tờ</label>
                                </div>
                                <div class="col-md-8">
                                    <span class="">{{personalDetailObject?.cmnd}}</span>
                                </div>
                            </div>
                            <div class="e-block-field row">
                                <div class="col-md-4 col-xl-3">
                                    <label class="e-lb-field">Ngày sinh</label>
                                </div>
                                <div class="col-md-8">
                                    <span class="">{{ personalDetailObject?.ngaySinh | date: 'dd/MM/yyyy' }}</span>
                                </div>
                            </div>
                            <div class="e-block-field row">
                                <div class="col-md-4 col-xl-3">
                                    <label class="e-lb-field">Nơi sinh</label>
                                </div>
                                <div class="col-md-8">
                                    <span class="">{{personalDetailObject?.noiSinh}}</span>
                                </div>
                            </div>
                            <div class="e-block-field row">
                                <div class="col-md-4 col-xl-3">
                                    <label class="e-lb-field">Giới tính</label>
                                </div>
                                <div class="col-md-8">
                                    <span class="">{{personalDetailObject?.gioiTinh}}</span>
                                </div>
                            </div>
                            <div class="e-block-field row">
                                <div class="col-md-4 col-xl-3">
                                    <label class="e-lb-field">Nơi ĐK HKTT</label>
                                </div>
                                <div class="col-md-8">
                                    <span class="">{{personalDetailObject?.hktt}}</span>
                                </div>
                            </div>
                            <div class="e-block-field row">
                                <div class="col-md-4 col-xl-3">
                                    <label class="e-lb-field">Ngày cấp</label>
                                </div>
                                <div class="col-md-8">
                                    <span class="">{{ personalDetailObject?.ngayCap | date: 'dd/MM/yyyy' }}</span>
                                </div>
                            </div>
                            <div class="e-block-field row">
                                <div class="col-md-4 col-xl-3">
                                    <label class="e-lb-field">Nơi cấp</label>
                                </div>
                                <div class="col-md-8">
                                    <span class="">{{personalDetailObject?.noiCap}}</span>
                                </div>
                            </div>
                            <div class="e-block-field row">
                                <div class="col-md-4 col-xl-3">
                                    <label class="e-lb-field">Có giá trị đến</label>
                                </div>
                                <div class="col-md-8">
                                    <span class="">{{ personalDetailObject?.expiryDate | date: 'dd/MM/yyyy' }}</span>
                                </div>
                            </div>
                            <div class="e-block-field row">
                                <div class="col-md-4 col-xl-3">
                                    <label class="e-lb-field">Số điện thoại</label>
                                </div>
                                <div class="col-md-8">
                                    <span class="">{{personalDetailObject?.sdt}}</span>
                                </div>
                            </div>
                            <div class="e-block-field row">
                                <div class="col-md-4 col-xl-3">
                                    <label class="e-lb-field">Email</label>
                                </div>
                                <div class="col-md-8">
                                    <span class="">{{personalDetailObject?.email}}</span>
                                </div>
                            </div>
                            <div class="e-block-field row">
                                <div class="col-md-4 col-xl-3">
                                    <label class="e-lb-field">Địa chỉ</label>
                                </div>
                                <div class="col-md-8">
                                    <span class="">{{personalDetailObject?.diaChi}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="card shadow-none">
                    <div class="card-body position-relative">
                        <label class="text-primary">Thông tin tài khoản</label>
                        <hr>
                        <div>
                            <div class="e-block-field row">
                                <div class="col-md-5">
                                    <label class="e-lb-field">Tên tài khoản</label>
                                </div>
                                <div class="col-md-7">
                                    <span class="">{{personalDetailObject?.username}}</span>
                                </div>
                            </div>
                            <div class="e-block-field row">
                                <div class="col-md-5">
                                    <label class="e-lb-field">Loại tài khoản</label>
                                </div>
                                <div class="col-md-7">
                                    <span class="">Tài khoản định danh cấp
                                        {{personalDetailObject?.identityLevel}}</span>
                                </div>
                            </div>
                            <div class="e-block-field row">
                                <div class="col-md-5">
                                    <label class="e-lb-field">Gói dịch vụ</label>
                                </div>
                                <div class="col-md-7">
                                    <span class="">{{personalDetailObject?.packageName}}</span>
                                </div>
                            </div>
                            <div class="e-block-field row">
                                <div class="col-md-5">
                                    <label class="e-lb-field">Trạng thái</label>
                                </div>
                                <div class="col-md-7">
                                    <span
                                        class="badge {{getStyleAndValueBadge(personalDetailObject?.disable)[0]}}">{{getStyleAndValueBadge(personalDetailObject?.disable)[1]}}</span>
                                </div>
                            </div>
                            <div class="e-block-field row">
                                <div class="col-md-5">
                                    <label class="e-lb-field">Mã thuê bao</label>
                                </div>
                                <div class="col-md-7">
                                    <span class="">{{personalDetailObject?.subscriptionCode}}</span>
                                </div>
                            </div>
                            <div class="e-block-field row">
                                <div class="col-md-5">
                                    <label class="e-lb-field">Loại hình thuê bao</label>
                                </div>
                                <div class="col-md-7">
                                    <span class="">{{personalDetailObject?.subscriptionType}}</span>
                                </div>
                            </div>
                            <div class="e-block-field row">
                                <div class="col-md-5">
                                    <label class="e-lb-field">Mã tỉnh</label>
                                </div>
                                <div class="col-md-7">
                                    <span class="">{{personalDetailObject?.provinceCode}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="mt-5">
                            <label class="text-primary">Ảnh chụp</label>
                            <hr>
                            <div class="row">
                                <div *ngIf="imageFirst" class="col-xl-6 col-lg-6 col-md-10 col-sm-10 col-10 pb-3">
                                    <div>
                                        <label class="e-lb-field">Ảnh giấy tờ mặt trước</label>
                                        <div>
                                            <img class="img-cmnd" [src]="imageFirst" />
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="imageSecond" class="col-xl-6 col-lg-6 col-md-10 col-sm-10 col-10 pb-3">
                                    <div>
                                        <label class="e-lb-field">Ảnh giấy tờ mặt sau</label>
                                        <div>
                                            <img class="img-cmnd" [src]="imageSecond" />
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="url" class="col-xl-6 col-lg-6 col-md-10 col-sm-10 col-10 pb-3">
                                    <div>
                                        <label class="e-lb-field">Ảnh chân dung</label>
                                        <div>
                                            <img class="img-cmnd" [src]="url" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->


<!-- <div class="col-md-6">
    <div *ngIf="imageFirst || imageSecond" class="e-block-field row">
        <div class="col-12 mb-2">
            <label class="e-lb-field mb-2">Ảnh chụp CMT</label>
        </div>
        <div *ngIf="imageFirst" class="col-md-6">
            <img class="img-cmnd" [src]="imageFirst" />
        </div>
        <div *ngIf="imageSecond" class="col-md-6">
            <img class="img-cmnd" [src]="imageSecond" />
        </div>
    </div>
    <div *ngIf="url" class="e-block-field row">
        <div class="col-12 mb-2">
            <label class="e-lb-field mb-2">Ảnh chụp khuôn mặt</label>
        </div>
        <div *ngIf="url" class="col-md-6">
            <img class="img-cmnd" [src]="url" />
        </div>
    </div>
</div> -->