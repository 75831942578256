import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-confirm-assign',
  templateUrl: './confirm-assign.component.html',
  styleUrls: ['./confirm-assign.component.scss']
})
export class ConfirmAssignComponent implements OnInit {

  
  constructor() { }

  packageOptions = [
    {value: 1, label: "Gói nâng cao"},
    {value: 2, label: "Gói nâng cấp nâng cao"},
    {value: 3, label: "Gói nâng cấp số lượng hợp đồng"},
    {value: 4, label: "Gói nâng cấp dung lượng lưu trữ"},
    {value: 5, label: "Gói nâng cấp cơ bản"}
  ];

  selectedPackage = this.packageOptions[0].label;

  infraInformation = [
    {thongSo: "Số lượng hợp đồng", donViTinh: "Hợp đồng", soLuong: 12, thanhTien: "250,000 đ"},
    {thongSo: "Số lượt SMS OTP", donViTinh: "Lượt", soLuong: 20, thanhTien: "100,000 đ"},
    {thongSo: "Số lượt eKYC", donViTinh: "Lượt", soLuong: 20, thanhTien: "100,000 đ"},
    {thongSo: "Thời gian lưu trữ", donViTinh: "Tháng", soLuong: 10, thanhTien: "450,000 đ"},
    {thongSo: "Dung lượng lưu trữ", donViTinh: "GB", soLuong: 10, thanhTien: "234,000"},
  ]

  soLuong: FormControl = new FormControl(0);

  ngOnInit(): void {
    this.soLuong.setValue(10);
  }

  // Get style of rate
  getStyleOfRate(rate) {
    if (rate < 50) {
      return 'success';
    }
    else if (rate >= 50 && rate <= 75) {
      return 'warning';
    }
    else if (rate > 75) {
      return 'danger';
    }
  }

}
