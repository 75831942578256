import { Injectable } from '@angular/core';
import { ServicePath } from '../../common/constant-service-api';
import { API } from '../api';

const LIST_CATEGORY_URL = ServicePath.TEMPATE_SERVICE + '/products/list';
const CREATE_CATEGORY_URL = ServicePath.TEMPATE_SERVICE + '/products';
const UPDATE_CATEGORY_URL = ServicePath.TEMPATE_SERVICE + '/products/';
const DELETE_CATEGORY_URL = ServicePath.TEMPATE_SERVICE + '/products/';
const DOWNLOAD_SAMPLE = ServicePath.TEMPATE_SERVICE + '/products/download-sample';
const UPLOAD_CATEGORY_URL = ServicePath.TEMPATE_SERVICE + '/products/create-from-file';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  constructor(private api: API) {}

  /**
   * Get list category
   * @param page
   * @param maxSize
   * @param propertiesSort
   * @param sort
   * @param keySearch
   * @param fieldSearch
   */
  getListCategory(page, maxSize, propertiesSort, sort, keySearch = '', fieldSearch = 'productName') {
    const url =
      LIST_CATEGORY_URL +
      `?page=${page}&maxSize=${maxSize}&propertiesSort=${propertiesSort}&sort=${sort}&keySearch=${keySearch}&fieldSearch=${fieldSearch}`;
    return this.api.get(url);
  }

  /**
   * POST create category
   * @param data
   */
  postCreateCategory(data) {
    return this.api.post(CREATE_CATEGORY_URL, data);
  }

  /**
   * PUT create category
   * @param categoryId
   * @param data
   */
  putUpdateCategory(categoryId, data) {
    return this.api.put(UPDATE_CATEGORY_URL + categoryId, data);
  }

  /**
   * Delete category
   * @param categoryId
   */
  deleteCategory(categoryId) {
    return this.api.delete(DELETE_CATEGORY_URL + categoryId);
  }

  /**
   * Download sample excel file
   */
  downloadSample() {
    const url = DOWNLOAD_SAMPLE;
    return this.api.download(url);
  }

  /**
   * Upload file excel
   * @param formData 
   */
  uploadExcel(formData: FormData) {
    return this.api.requestPostMultiPart(UPLOAD_CATEGORY_URL, formData);
  }
}
