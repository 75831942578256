import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { CustomerToastComponent } from "./layout/customer-toast.toast";

@Injectable({
    providedIn: 'root'
})
export class ToastControl {

    public toastType = {
        blank: "ec-toast-blank",
        success: "ec-toast-success",
        danger: "toast-error",
        warning: "ec-toast-warnig"
    };

    constructor(
        private toastr: ToastrService
    ) {

    }

    toast(msg: string, title: string, type: string) {
        this.toastr.toastrConfig.toastComponent = CustomerToastComponent;
        this.toastr.toastrConfig.timeOut = 5000;
        this.toastr.toastrConfig.positionClass = "toast-top-right"
        this.toastr.toastrConfig.closeButton = true;
        this.toastr.show(msg, title, null, type);
    }
}