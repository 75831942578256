import { Component, OnInit } from '@angular/core';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { HomeService } from 'src/app/utils/services/home/home.service';

@Component({
  selector: 'app-user-manual',
  templateUrl: './user-manual.component.html',
  styleUrls: ['./user-manual.component.scss']
})
export class UserManualComponent implements OnInit {

  
  constructor(
    private homeService: HomeService,
    private alert: AlertControl
  ) { }

  ngOnInit(): void {
    this.getListManual();
  }

  data: Array<any> = new Array();

  // Serve for show pdf
  // showPdf: boolean = false;
  // fileName: string = "";
  // pdfFile: any = null;

  getListManual() {
    this.homeService.getDataByCodeCategory("eSolUserManual", "vi").subscribe((res: any) => {
      this.data = [];
      this.data = res?.object?.item?.blogs || [];
    });
  }

  onShowFile(item) {
    // show pdf on new tab
    // window.open(item.avatar, "_blank");

    // download popup

    if (item.avatar.includes('storage-econtract')) {
      this.homeService.downloadFile(item.avatar).subscribe((res: any) => {
        const blob = new Blob([res]);
        var url = window.URL.createObjectURL(blob);
        var anchor = document.createElement('a');
        let path = item.avatar.split('/');
        let nameFile = path.length > 0 ? path[path.length - 1] : "noname";
        anchor.download = nameFile;
        anchor.href = url;
        anchor.click();
      }, err => {
        this.alert.showErrorHandled(err);
      })
    } else {
      var anchor = document.createElement('a');
      anchor.href = item.avatar;
      anchor.target = "_blank";
      anchor.click();
    }
  }

  // closePdf() {
  //   this.showPdf = false;
  // }


}
