<div class="card shadow-none">
    <div class="card-body p-3">
        <div class="row">
            <div class="col-md-auto">
                <label class="grey-label">HĐ theo trạng thái</label><br>

            </div>
            <div class="col-md-auto ml-auto">
                <ng-select [formControl]="yearForm" [items]="years" bindLabel="label" bindValue="value"
                [clearable]="false" [searchable]="false" class="ng-select-year" (change)="yearChange($event)">
                    <ng-template ng-label-tmp let-item="item">
                        <span class="year-label"> {{ item.label }}</span>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                        <span class="year-label"> {{ item.label }}</span>
                    </ng-template>
                </ng-select>
            </div>
        </div>
        <div class="cc-gg">
            <div class="total d-flex justify-content-center align-items-center ">
                <div class="d-flex flex-column">
                    <span class="total-data">{{total}}</span>
                    <span class="total-percent">{{total == 0 ? 0 : 100}} %</span>
                </div>
            </div>
            <google-chart #chart [type]="type" [data]="data" [options]="options" style="width: 100%;"
            [dynamicResize]="dynamicResize">
            </google-chart>
            <!-- <canvas baseChart [data]="doughnutChartData" [labels]="doughnutChartLabels" [chartType]="doughnutChartType"
            [options]="doptions" >
            </canvas> -->
        </div>
        <div class="chart-legend">
            <div *ngFor="let item of data; let i = index">
                <div class="row">
                    <div class="col-md-auto">
                        <span class="dot" [ngStyle]="{'backgroundColor': color[i]}"></span>
                        <label>{{ item[0] }}</label>
                    </div>
                    <div class="col-md-auto ml-auto"><label>{{ item[1] }}</label> ({{percentData[i]}}%)</div>
                </div>
            </div>
            
        </div>

        
        
    </div>
</div>
