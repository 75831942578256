import { Injectable } from "@angular/core";
import { ServicePath } from '../../common/constant-service-api';
import { API } from "../api";
const LIST_ENTERPRISE_URL = ServicePath.MANAGEMENT_SERVICE + "/parties/020b0321-7f67-4891-a55c-5a9ac1c12906/registration-list";
const DETAIL_ENTERPRISE_URL = ServicePath.MANAGEMENT_SERVICE + "/parties";
const REJECT_ENTERPRISE_URL = ServicePath.MANAGEMENT_SERVICE + "/parties"

@Injectable({
  providedIn: "root",
})
export class EnterpriseManagerService {
  constructor(private api: API) {}

  /**
   * Get list enterprise
   * @param page
   * @param maxSize
   * @param propertiesSort
   * @param sort
   * @param searchString
   * @param status
   */
  getListEnterprises(
    status,
    page,
    maxSize,
    propertiesSort,
    sort,
    searchString = ""
  ) {
    const url =
      LIST_ENTERPRISE_URL +
      `?verificationStatus=${status}&page=${page}&maxSize=${maxSize}&sort=${sort}&propertiesSort=${propertiesSort}&searchString=${searchString}`;
    return this.api.get(url);
  }

  /**
   * Get enterprise
   * @param id
   */
  getEnterpriseById(id) {
    const url = DETAIL_ENTERPRISE_URL + `/${id}`;
    return this.api.get(url);
  }

   /**
   * POST create Employees
   * @param data
   */
  postRejectEnterprise(data) {
    return this.api.post(REJECT_ENTERPRISE_URL + `/${data.partyId}/registration-reject`, {rejectReason: data.rejectReason});
  }

  /**
   * POST create Employees
   * @param data
   */
  postApproveEnterprise(partyId) {
    return this.api.post(REJECT_ENTERPRISE_URL + `/${partyId}/registration-approve`, null);
  }

    /**
   * POST create Employees
   * @param partyId
   */
  downLoadGPKD(partyId) {
    return this.api.get(REJECT_ENTERPRISE_URL + `/${partyId}/download-gpkd`);
  }

}
