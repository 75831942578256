import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { DateUtils } from 'src/app/utils/common/DateUtils';
import {
  REGEXP_DATE_INPUT,
  REGEXP_EMAIL,
  REGEXP_PHONE_NUMBER,
  REGEXP_USERNAME,
} from 'src/app/utils/common/regexp';
import { localize } from 'src/app/utils/localize/localize';
import { PartnerConsumer } from 'src/app/utils/models/aas-models/partner/partner-verification/partner-consumer/partner-consumer';
import { AasConsumerManagerService } from 'src/app/utils/services/aas-network/aas-consumer-manager.service';
import { AccountManagerService } from 'src/app/utils/services/aas-network/account-manager.service';
import { PartnerService } from 'src/app/utils/services/aas-network/partner.service';
import { AuthService } from 'src/app/utils/services/auth.service';

@Component({
  selector: 'app-trusted-partner-edit',
  templateUrl: './trusted-partner-edit.component.html',
  styleUrls: ['./trusted-partner-edit.component.scss'],
})
export class TrustedPartnerEditComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private myAlert: AlertControl,
    private activedRoute: ActivatedRoute,
    private accApi: AccountManagerService,
    private router: Router,
    private aasConsumerManagerService: AasConsumerManagerService,
    private authService: AuthService,
    private modalService: NgbModal,
    private partnerService: PartnerService
  ) {}
  partyId = this.authService.partyId;
  detail: PartnerConsumer = null;
  id = this.activedRoute.snapshot.paramMap.get('id');
  form: FormGroup;
  isSubmit = false;
  hasIndentifyInfo = false;
  validateMsg = {
    ten: {
      required: 'Vui lòng nhập họ và tên',
    },
    username: {
      required: 'Vui lòng nhập tên tài khoản',
      pattern:
        'Tên tài khoản phải viết liền không dấu, có ít nhất 6-50 ký tự, không chứa ký tự khoảng trống',
      unique: 'Tên đăng nhập đã tồn tại',
    },
    sdt: {
      required: 'Vui lòng nhập số điện thoại',
      pattern: 'Số điện thoại sai định dạng',
    },
    email: {
      required: 'Vui lòng nhập email',
      pattern: 'Email sai định dạng',
    },
    ngaysinh: {
      required: 'Vui lòng nhập ngày sinh',
      pattern: 'Ngày sinh sai định dạng',
    },
    gioitinh: {
      required: 'Vui lòng chọn giới tính',
    },
    cmnd: {
      required: 'Vui lòng nhập số giấy tờ',
    },
    noisinh: {
      required: 'Vui lòng nhập nơi sinh',
    },
    ngaycap: {
      required: 'Vui lòng nhập ngày cấp',
      pattern: 'Ngày cấp sai định dạng',
    },
    noicap: {
      required: 'Vui lòng nhập nơi cấp',
    },
    expiryDate: {
      required: 'Vui lòng nhập có giá trị đến',
      pattern: 'Có giá trị đến sai định dạng',
    },
    frontImage: {
      required: 'Vui lòng đính kèm ảnh mặt trước',
    },
    backImage: {
      required: 'Vui lòng đính kèm ảnh mặt sau',
    },
    portraitImage: {
      required: 'Vui lòng đính kèm ảnh chân dung',
    },
  };
  gioiTinhs = [
    { id: 1, label: 'Nữ' },
    { id: 2, label: 'Nam' },
  ];
  optionsCleave = {
    date: true,
    delimiter: '/',
    datePattern: ['d', 'm', 'Y'],
  };
  currentDate = new Date();
  prefix = 'data:image/png;base64,';
  base64FrontImage = null;
  base64BackImage = null;
  base64PortraitImage = null;
  listIndentifyFiled = [
    'ten',
    'cmnd',
    'gioitinhId',
    'ngaycap',
    'noicap',
    'ngaysinh',
    'frontImage',
    'backImage',
    'portraitImage',
  ];
  hasEmail= false;
  modalUpdatePartner: any = null;
  resendPartnerInforChecked: boolean = false;

  ngOnInit(): void {
    this.makeForm();
    this.getConsumerTrustedPartnerDetails(this.id);
    this.authService.getUserInfoNew(this.partyId).subscribe((res: any) => {
      if (res && res.object.notifyPushType.includes('EMAIL')) {
        // this.notiTypes = res.object.notifyPushType;
        this.hasEmail = true;
        // if (this.notiTypes.includes('EMAIL')) {
          this.form.controls['email'].setValidators([
            Validators.required,
            Validators.pattern(REGEXP_EMAIL),
          ]);
          this.form.controls['email'].updateValueAndValidity();
        // }
      }
    });
  }
  getConsumerTrustedPartnerDetails(id) {
    this.accApi.getConsumerTrustedPartnerDetails(id).subscribe(
      (res: any) => {
        if (res && res.object instanceof Object) {
          this.detail = new PartnerConsumer(res.object);
          console.log(this.detail);

          this.getImage(this.detail.userId, 'EKYC_CHANDUNG');
          this.getImage(this.detail.userId, 'EKYC_MATTRUOC');
          this.getImage(this.detail.userId, 'EKYC_MATSAU');
          this.setValue(
            this.detail,
            this.base64FrontImage,
            this.base64BackImage,
            this.base64PortraitImage
          );
          if (this.detail.ten) {
            document.querySelector<HTMLInputElement>('.form-check-input').checked = true;
            this.hasIndentifyInfo = true;
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  async getImage(id, type) {
    this.aasConsumerManagerService.downloadImage(id, type).subscribe(
      (res: any) => {
        if (res) {
          const blob: any = new Blob([res], {
            type: 'image/png',
          });
          var reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            switch (type) {
              case 'EKYC_CHANDUNG':
                this.base64PortraitImage = reader.result;
                this.form.controls.portraitImage.setValue(blob);
                break;
              case 'EKYC_MATTRUOC':
                this.base64FrontImage = reader.result;
                this.form.controls.frontImage.setValue(blob);
                break;
              case 'EKYC_MATSAU':
                this.base64BackImage = reader.result;
                this.form.controls.backImage.setValue(blob);
                break;
              default:
            }
          };
        }
      },
      (err) => {
        if (type === 'EKYC_CHANDUNG') {
          this.base64PortraitImage = null;
        } else if (type === 'EKYC_MATTRUOC') {
          this.base64FrontImage = null;
        } else {
          this.base64BackImage = null;
        }
      },
      () => {}
    );
  }
  makeForm() {
    this.form = this.fb.group({
      username: ['', [Validators.required, Validators.pattern(REGEXP_USERNAME)]],
      email: ['', [Validators.pattern(REGEXP_EMAIL)]],
      sdt: ['', [Validators.required, Validators.pattern(REGEXP_PHONE_NUMBER)]],
      ten: ['', []],
      cmnd: ['', []],
      gioitinhId: [1, []],
      dkhktt: ['', []],
      noisinh: ['', []],
      ngaycap: ['', [Validators.pattern(REGEXP_DATE_INPUT)]],
      noicap: ['', []],
      ngaysinh: ['', [Validators.pattern(REGEXP_DATE_INPUT)]],
      expiryDate: ['', [Validators.pattern(REGEXP_DATE_INPUT)]],
      frontImage: [null, []],
      backImage: [null, []],
      portraitImage: [null, []],
    });
  }
  setValue(detail: PartnerConsumer, frontImage: any, backImage: any, portraitImage: any) {
    this.form.patchValue({
      ten: detail.ten,
      cmnd: detail.cmnd,
      gioitinhId: detail.gioiTinhId,
      dkhktt: detail.dkhktt,
      noisinh: detail.noiSinh,
      ngaycap: DateUtils.convert(detail.ngayCap, DateUtils.format.INPUT),
      noicap: detail.noiCap,
      ngaysinh: DateUtils.convert(detail.ngaySinh, DateUtils.format.INPUT),
      username: detail.username,
      email: detail.email,
      sdt: detail.sdt,
      expiryDate: DateUtils.convert(detail.expiryDate, DateUtils.format.INPUT),
    });
  }
  async uploadFile(event, type = '') {
    if (!event || event == null || event == undefined) return;

    for (var i = 0; i < event.length; i++) {
      const fileItem = event[i];
      let item = {
        name: fileItem.name,
        size: fileItem.size,
        type: fileItem.type,
      };

      console.log(fileItem);

      if (type === 'FRONT') {
        this.form.controls.frontImage.setValue(fileItem);
        var self = this;
        var reader = new FileReader();
        reader.onload = function (e) {
          self.base64FrontImage = reader.result;
        };
        reader.readAsDataURL(fileItem);
      } else if (type === 'BACK') {
        this.form.controls.backImage.setValue(fileItem);
        var self = this;
        var reader = new FileReader();
        reader.onload = function (e) {
          self.base64BackImage = reader.result;
        };
        reader.readAsDataURL(fileItem);
      } else if (type === 'PORTRAIT') {
        this.form.controls.portraitImage.setValue(fileItem);
        var self = this;
        var reader = new FileReader();
        reader.onload = function (e) {
          self.base64PortraitImage = reader.result;
        };
        reader.readAsDataURL(fileItem);
      }
    }
  }
  removeImage(type = '') {
    if (type === 'FRONT') {
      this.form.controls.frontImage.setValue(null);
      this.base64FrontImage = '';
    } else if (type === 'BACK') {
      this.form.controls.backImage.setValue(null);
      this.base64BackImage = '';
    } else if (type === 'PORTRAIT') {
      this.form.controls.portraitImage.setValue(null);
      this.base64PortraitImage = '';
    }
  }
  changeDateFilter(event, type) {
    switch (type) {
      case 'ngaysinh':
        this.form.controls.ngaysinh.setValue(DateUtils.convertObjtoDate(event));
        console.log(this.form.controls.ngaysinh.value);

        break;
      case 'ngaycap':
        this.form.controls.ngaycap.setValue(DateUtils.convertObjtoDate(event));
        console.log(this.form.controls.ngaycap.value);

        break;
      case 'expiryDate':
        this.form.controls.expiryDate.setValue(DateUtils.convertObjtoDate(event));
        console.log(this.form.value.expiryDate);

        break;
    }
  }
  toggleForm() {
    if (!this.hasIndentifyInfo) {
      this.listIndentifyFiled.forEach((item) => {
        if (item == 'ngaycap' || item == 'ngaysinh') {
          this.form
            .get(item)
            ?.setValidators([Validators.required, Validators.pattern(REGEXP_DATE_INPUT)]);
        } else if (item == 'gioitinhId') {
          this.form.get(item)?.setValidators([Validators.required]);
          this.form.get(item)?.setValue(1);
        } else {
          this.form.get(item)?.setValidators([Validators.required]);
        }
        this.form.get(item)?.updateValueAndValidity();
      });
    } else {
      this.listIndentifyFiled.forEach((item) => {
        this.form.get(item)?.clearValidators();
        this.form.get(item)?.updateValueAndValidity();
      });
    }
  }
  cancel() {
    this.router.navigate(['/app/console/partner/trusted/consumer-detail/', this.id]);
  }
  save() {
    console.log('submit');

    this.isSubmit = true;
    if (this.form.valid) {
      this.myAlert.showAlert(
        localize.confirm_edit_account_customer,
        '',
        true,
        '',
        localize.btn_confirm,
        () => {
          this.editConsumerTrustedPartner();
        },
        '',
        localize.btn_cancel,
        null,
        localize.confirm
      );
    }
  }
  editConsumerTrustedPartner() {
    if (!this.hasIndentifyInfo) {
      let data = new FormData();
      let username = this.form.value.username || '';
      let sdt = this.form.value.sdt || '';
      let email = this.form.value.email || '';
      data.append('username', username);
      data.append('sdt', sdt);
      data.append('email', email);
      this.postEditConsumerTrustedPartner(this.detail.trustedPartnerId, data);
    } else {
      let data = new FormData();
      let username = this.form.value.username || '';
      let sdt = this.form.value.sdt || '';
      let email = this.form.value.email || '';
      let info = {
        ten: this.form.value.ten || '',
        cmnd: this.form.value.cmnd || '',
        ngaySinh: this.form.value.ngaysinh || '',
        noiSinh: this.form.value.noisinh || '',
        gioiTinhId: this.form.value.gioitinhId || '',
        ngayCap: this.form.value.ngaycap || '',
        noiCap: this.form.value.noicap || '',
        dkhktt: this.form.value.dkhktt || '',
        expiryDate: this.form.value.expiryDate || '',
      };
      data.append('username', username);
      data.append('sdt', sdt);
      data.append('email', email);
      data.append('info', JSON.stringify(info));
      if (this.form.value.frontImage)
        data.append(
          'EKYC_MATTRUOC',
          this.form.value.frontImage,
          this.form.value.frontImage.name || 'EKYC_MATTRUOC.png'
        );
      if (this.form.value.backImage)
        data.append(
          'EKYC_MATSAU',
          this.form.value.backImage,
          this.form.value.backImage.name || 'EKYC_MATSAU.png'
        );
      if (this.form.value.portraitImage)
        data.append(
          'EKYC_CHANDUNG',
          this.form.value.portraitImage,
          this.form.value.portraitImage.name || ' EKYC_CHANDUNG.png'
        );
      console.log(data);

      this.postEditConsumerTrustedPartner(this.detail.trustedPartnerId, data);
    }
  }
  postEditConsumerTrustedPartner(id, data) {
    this.accApi.postEditConsumerTrustedPartner(id, data).subscribe(
      (res: any) => {
        if (this.resendPartnerInforChecked) {
          this.partnerService.resendPartnerInfor(id).subscribe((res2: any) => { }, err => { });
        }
        this.myAlert.showAlertOnlyNoti(
          localize.edit_account_customer_success,
          ConstantAlertType.SUCCESS
        );
        this.modalUpdatePartner.close();
        this.router.navigate(['app/console/partner/trusted']);
      },
      (err) => {
        console.log(err);
        this.myAlert.showErrorHandled(err);
      }
    );
  }

  openUpdatePopup(content) {
    this.isSubmit = true;
    if (this.form.valid) {
      this.modalUpdatePartner = this.modalService.open(content, { backdrop: "static" });
    } 
  }

  checkResendPartnerInfor() {
    this.resendPartnerInforChecked = !this.resendPartnerInforChecked;
  }
}
