<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-auto">
                <h1 class="m-0 text-dark"><label>{{ localize.account_detail_title }}</label></h1>
            </div>
            <!-- /.col -->
            <div class="col-md-auto ml-auto">
            </div>
            <!-- <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>

<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-8 col-sm-12 col-xs-12">
                <div class="card shadow-none">
                    <div class="card-body position-relative">
                        <div class="row pt-2 pb-2">
                            <div class="col-md-auto align-self-end">
                                <label class="text-lg">{{ localize.account_identity_level_detail }} {{personalDetailObject?.identityLevel}}</label>
                            </div>
                            <div class="ml-auto d-flex">
                                <a [routerLink]="['/console/account-manager/detail', partyId]"
                                class="btn btn-with-icon btn-outline-primary mr-3">
                                    <em class="material-icons nav-icon" >clear</em>  
                                    {{ localize.btn_cancel }}
                                </a>
                                <button class="btn btn-primary btn-with-icon" (click)="onClickSave()">
                                    <span class="material-icons pr-2">save</span> 
                                    {{ localize.btn_save }}
                                </button>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-md-6">
                                <form [formGroup]="form" (ngSubmit)="onClickSave()">
                                    <div class="e-block-field row">
                                        <div class="col-md-4 col-xl-3">
                                            <label class="e-lb-field">{{ localize.fullname }}</label>
                                        </div>
                                        <div class="col-md-8">
                                            <span class="e-txt-field">{{ personalDetailObject?.ten }}</span>
                                        </div>
                                    </div>
    
                                    <div class="e-block-field row">
                                        <div class="col-md-4 col-xl-3">
                                            <label  *ngIf="personalDetailObject.loaiGtId === '0' || personalDetailObject.loaiGtId === '1'" class="e-lb-field">{{ localize.cmnd_cccd}}</label>
                                            <label  *ngIf="personalDetailObject.loaiGtId === '2'" class="e-lb-field">{{ localize.passport }}</label>
                                            <label  *ngIf="!personalDetailObject.loaiGtId" class="e-lb-field">{{ localize.personal_id }}</label>
                                        </div>
                                        <div class="col-md-8">
                                            <span class="e-txt-field">{{ personalDetailObject.cmnd }}</span>
                                        </div>
                                    </div>
    
                                    <div class="e-block-field row">
                                        <div class="col-md-4 col-xl-3">
                                            <label class="e-lb-field">{{ localize.birthday }}</label>
                                        </div>
                                        <div class="col-md-8">
                                            <span class="e-txt-field">{{ personalDetailObject?.ngaySinh | date: 'dd/MM/yyyy' }}</span>
                                        </div>
                                    </div>
    
                                    <div *ngIf="!personalDetailObject.loaiGtId || personalDetailObject.loaiGtId === '2'" class="e-block-field row">
                                        <div class="col-md-4 col-xl-3">
                                            <label class="e-lb-field">{{ localize.sex }}</label>
                                        </div>
                                        <div class="col-md-8">
                                            <span class="e-txt-field">{{ personalDetailObject.gioiTinh || '' }}</span>
                                        </div>
                                    </div>
    
                                    <div class="e-block-field row">
                                        <div class="col-md-4 col-xl-3">
                                            <label class="e-lb-field">{{ localize.address_signup }}</label>
                                        </div>
                                        <div class="col-md-8">
                                            <span class="e-txt-field">{{ personalDetailObject.diaChi }}</span>
                                        </div>
                                    </div>
    
                                    <div class="e-block-field row">
                                        <div class="col-md-4 col-xl-3">
                                            <label class="e-lb-field">{{ localize.issue_date }}</label>
                                        </div>
                                        <div class="col-md-8">
                                            <span class="e-txt-field">{{ personalDetailObject?.ngayCap | date: 'dd/MM/yyyy' }}</span>
                                        </div>
                                    </div>
    
                                    <div class="e-block-field row">
                                        <div class="col-md-4 col-xl-3">
                                            <label class="e-lb-field">{{ localize.issue_place }}</label>
                                        </div>
                                        <div class="col-md-8">
                                            <span class="e-txt-field">{{ personalDetailObject?.noiCap }}</span>
                                        </div>
                                    </div>
    
                                    <div class="e-block-field row">
                                        <div class="col-md-4 col-xl-3">
                                            <label class="e-lb-field">{{ localize.phone_number }}</label>
                                        </div>
                                        <div class="col-md-8">
                                            <span class="e-txt-field">{{ personalDetailObject?.sdt }}</span>
                                        </div>
                                    </div>
    
                                    <div class="e-block-field row">
                                        <div class="col-md-4 col-xl-3">
                                            <label class="e-lb-field">{{ localize.email }}</label>
                                        </div>
                                        <div class="col-md-8">
                                            <span class="e-txt-field">{{ personalDetailObject?.email }}</span>
                                        </div>
                                    </div>
    
                                    <div class="e-block-field row">
                                        <div class="col-md-4 col-xl-3">
                                            <label class="e-lb-field">{{ localize.username }} <span class="text-danger">*</span></label>
                                        </div>
                                        <div class="col-md-8">
                                            <div class="form-group">
                                                <input type="text" formControlName="tenTaiKhoan" id="tenTaiKhoan" class="form-control"
                                                [ngClass]="{ 'is-invalid1': isSubmit && f.tenTaiKhoan.errors }" placeholder="{{ localize.username_placeholder }}"/>
                                                <app-show-validate-errors
                                                    [isSubmit]="isSubmit"
                                                    [errorMessages]="validateMsg.tenTaiKhoan"
                                                    [control]="form.controls.tenTaiKhoan"
                                                    [detail]="{ name: 'tenTaiKhoan' }">
                                                </app-show-validate-errors>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- <table class="table-info-enterprise">
                                        <tr>
                                            <td>Họ và tên</td>
                                            <td>{{personalDetailObject?.ten}}</td>
                                        </tr>
                                        <tr>
                                            <td>Số CMND </td>
                                            <td>{{personalDetailObject?.cmnd}}</td>
                                        </tr>
                                        <tr>
                                            <td>Ngày sinh</td>
                                            <td>{{personalDetailObject?.ngaySinh}}</td>
                                        </tr>
                                        <tr>
                                            <td>Giới tính</td>
                                            <td>{{personalDetailObject?.gioiTinh}}</td>
                                        </tr>
                                        <tr>
                                            <td>Nơi đăng ký HKTT</td>
                                            <td>{{personalDetailObject?.hktt}}</td>
                                        </tr>
                                        <tr>
                                            <td>Ngày cấp</td>
                                            <td>{{personalDetailObject?.ngayCap}}</td>
                                        </tr>
                                        <tr>
                                            <td>Nơi cấp</td>
                                            <td>{{personalDetailObject?.noiCap}}</td>
                                        </tr>
                                        <tr>
                                            <td>Số điện thoại</td>
                                            <td>{{personalDetailObject?.sdt}}</td>
                                        </tr>
                                        <tr>
                                            <td>Email</td>
                                            <td>{{personalDetailObject?.email}}</td>
                                        </tr>
                                        <tr>
                                            <td>Tên tài khoản <span class="text-danger">*</span></td>
                                            <td>
                                                <div class="form-group">
                                                    <input type="text" formControlName="tenTaiKhoan" id="tenTaiKhoan" class="form-control"
                                                    [ngClass]="{ 'is-invalid1': isSubmit && f.tenTaiKhoan.errors }"/>
                                                    <div *ngIf="isSubmit && f.tenTaiKhoan.errors" class="error text-danger">
                                                        <div *ngIf=" isSubmit && f.tenTaiKhoan.errors.required">Bắt buộc nhập</div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </table> -->
                                </form>
                            </div>
                            <div class="col-md-6">
                                <p *ngIf="imageSecond || imageFirst"> Ảnh chụp CMT</p>
                                <div class="row">
                                    <div *ngIf="imageFirst" class="col-md-6">
                                        <img class="img-cmnd" [src]="imageFirst" />
                                    </div>
                                    <div *ngIf="imageSecond" class="col-md-6">
                                        <img class="img-cmnd" [src]="imageSecond" />
                                    </div>
                                </div>
                                <p *ngIf="url"> Ảnh chụp khuôn mặt</p>
                                <div class="row">
                                    <div *ngIf="url" class="col-md-6">
                                        <img class="img-cmnd" [src]="url" />
                                    </div>
                                </div>
                                <!-- <p> Ảnh chữ ký điện tử</p>
                                <div class="row">
                                    <div class="col-md-6">
                                        <img class="img-cmnd"
                                            src="https://boatingnz.co.nz/wp-content/uploads/2019/11/boating-nz-image-1075-underway.jpg" />
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
