import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { exit, title } from 'process';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { SIGNUP_CONSUMER } from 'src/app/utils/common/constant-vaildate-msg';
import { Constant } from 'src/app/utils/common/constant_videocall';
import { REGEXP_PASSWORD } from 'src/app/utils/common/regexp';
import { AasValidateMessage } from 'src/app/utils/common/validate-msg-aas';
import { localize } from 'src/app/utils/localize/localize';
import { FirebaseService } from 'src/app/utils/services/aas-network/firebase/firebase.service';
import { AuthService } from 'src/app/utils/services/auth.service';
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private myAlert: AlertControl,
    private firebaseService: FirebaseService,
    public translate: TranslateService,
  ) { }

  form: FormGroup;
  isSubmit = false;
  isFailed = {
    wrongPasswordOld: false,
    sameAsPasswordOld: false,
    passwordConfirmNotMatch: false
  };
  validateMsg = AasValidateMessage.CHANGE_PASSWORD;


  ngOnInit(): void {
    this.makeForm();
    // this.getData(this.translate.currentLang);
  }

  makeForm() {
    this.form = this.fb.group({
      passwordOld: ['', Validators.required],
      password: ['', [Validators.required, Validators.pattern(REGEXP_PASSWORD)]],
      passwordConfirm: ['', Validators.required]
    })
  }

  get passwordOld() { return this.form.get('passwordOld') };
  get password() { return this.form.get('password') };
  get passwordConfirm() { return this.form.get('passwordConfirm') };

  //check Allow click
  get checkEdited() {
    if (this.passwordOld.value === '') return true;
    if (this.password.value === '') return true;
    if (this.passwordConfirm.value === '') return true;
    return false;
  }

  save() {
    this.isSubmit = true;
    this.isFailed.wrongPasswordOld = false;
    this.isFailed.sameAsPasswordOld = false;
    this.isFailed.passwordConfirmNotMatch = false;

    if (this.form.valid) {
      if (this.password.value === this.passwordOld.value) {
        this.isFailed.sameAsPasswordOld = true;
        return;
      }
      if (this.password.value !== this.passwordConfirm.value) {
        this.isFailed.passwordConfirmNotMatch = true;
        return;
      }
      var param = {
        passwordOld: this.passwordOld.value,
        password: this.password.value,
        passwordConfirm: this.passwordConfirm.value
      }

      this.authService.postChangePassword(param).subscribe((res: any) => {
        this.myAlert.showAlert(this.translate.instant('ChangePassword_note_2'), ConstantAlertType.BLANK, false, "", this.translate.instant('btn_login'), () => {
          this.authService.logout();
        },"", "", () => {}, this.translate.instant('ChangePassword_note_3'));
      }, err => {
        if (err['status'] === 400 || err['status'] === 401) {
          this.isFailed.wrongPasswordOld = true;
          // this.password.setErrors({notTrue: true});
        }
      })
    }
  }


  forgotPassword() {
    localStorage.getItem(Constant.UUID) && this.firebaseService.removeDevice(localStorage.getItem(Constant.UUID));
    this.authService.removeAllLocalStorage();
    this.router.navigate(['/app/forgot-password']);
  }
}
