import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select2OptionData } from 'ng-select2';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { EnterpriseVipItem } from 'src/app/utils/models/aas-models/enterprise-vip-manager/list-enterprise-vip/enterprise-vip-item.model';
import { ListEnterpriseVipResponse } from 'src/app/utils/models/aas-models/enterprise-vip-manager/list-enterprise-vip/list-enterprise-vip-response.model';
import { InfoPartyObject } from 'src/app/utils/models/aas-models/info-party/info-party-object';
import { InfoPartyResponse } from 'src/app/utils/models/aas-models/info-party/info-party-response';
import { PartyItemResponse } from 'src/app/utils/models/party-manager/party-item-response.model';
import { EnterpriseVipService } from 'src/app/utils/services/aas-network/enterprise-vip.service';
import { Options } from 'select2'
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';

@Component({
  selector: 'app-enterprise-vip-approved',
  templateUrl: './enterprise-vip-approved.component.html',
  styleUrls: ['./enterprise-vip-approved.component.scss']
})
export class EnterpriseVipApprovedComponent implements OnInit {
  keySearch: string = '';
  enterprises: Array<EnterpriseVipItem> = [];
  totalElement: number = 0;
  maxSize: number = 5;
  page: number = 1;
  status: string = 'APPROVE_NON_CONTRACT';
  propertiesSort: string = 'created';
  sort: string = 'DESC';
  searchString: string = '';

  enterpriseSelected: EnterpriseVipItem = new EnterpriseVipItem();
  partyInfo: InfoPartyObject = new InfoPartyObject();

  formReject: FormGroup;

  
  dropListStatus: Array<Select2OptionData> = [];
  options: Options;

  fileGPKD: Blob = null;
  showPreviewGPKD: boolean = false;

  constructor(
    private fb: FormBuilder,
    private aasAuth: AasAuthService,
    private modalService: NgbModal,
    private enterpriseVipService: EnterpriseVipService
  ) { }

  ngOnInit(): void {
    this.formReject = this.fb.group({
      rejectReason: new FormControl('')
    });

    this.dropListStatus = [
      {
        id: 'APPROVE_CONTRACT',
        text: 'Phê duyệt có HĐ'
      },
      {
        id: 'APPROVE_NON_CONTRACT',
        text: 'Phê duyệt không HĐ'
      }
    ]

    this.options = {
      minimumResultsForSearch: Infinity,
      multiple: false,
      dropdownAutoWidth: true,
      width: '100%',
      closeOnSelect: true,
      language: 'vi'
    }

    this.loadPage(this.page = 1);
  }


  clickSearchBtn() {
    this.loadPage(this.page = 1);
  }

  inputKeySearch(event) {
    this.searchString = event.target.value;
  }

  /**
   * Chọn trạng thái tài khoản
   * @param event 
   */
  selectStatus(event) {
    if (event) {
      this.status = event;
      this.loadPage(1);
    }
  }

  /**
   * Tải trang dữ liệu tài khoản tổ chức sử dụng giải pháp
   * @param page 
   */
  loadPage(page) {
    this.page = page;
    this.partyInfo = new InfoPartyObject();
    this.getListUserEnterpriseVip(this.status, this.page, this.maxSize, this.propertiesSort, this.sort, this.searchString);
  }

  /**
   * Thao tác chọn tổ chức từ danh sách
   * @param enterprise 
   */
  selectEnterprise(enterprise: EnterpriseVipItem) {
    this.enterpriseSelected = enterprise;
    this.getDetailEnterpriseVip(enterprise.partyId);
  }

  actDownloadGPKD(id) {
    this.downloadGPKD(id);
  }

  handleClosePreview() {
    this.showPreviewGPKD = false;
  }

  //MARK: NETWORKING
  /**
   * Lấy danh sách tài khoản tổ chức sử dụng giải pháp
   * @param status 
   * @param page 
   * @param maxSize 
   * @param propertiesSort 
   * @param sort 
   * @param searchString 
   */
  getListUserEnterpriseVip(status, page, maxSize, propertiesSort, sort, searchString) {
    this.enterpriseVipService.getListUserEnterpriseVip(status, page, maxSize, propertiesSort, sort, searchString).subscribe((res) => {
      console.log(res);
      let respo = new ListEnterpriseVipResponse(res);
      this.enterprises = respo.object.data;
      if (this.enterprises.length > 0) {
        this.selectEnterprise(this.enterprises[0]);
      }
    }, err => {
      this.handleError(err, ResponseMsg.MESSAGES.ALL_IN_ONE);
    })
  }

  /**
   * Lấy thông tin chi tiết tổ chức sử dụng giải pháp
   * @param partId 
   */
  getDetailEnterpriseVip(partId) {
    this.enterpriseVipService.getDetailEnterpriseVip(partId).subscribe((res) => {
      console.log(res);
      let respo = new InfoPartyResponse(res);
      this.partyInfo = respo.object;
    }, err => {
      // this.handleError(err, ResponseMsg.MESSAGES.ALL_IN_ONE);
    })
  }

  /**
   * Từ chối phê duyệt tổ chức sử dụng giải pháp
   * @param partyId 
   * @param param 
   */
  postRejectEnterpriseVip(partyId, param) {
    this.enterpriseVipService.postRejectEnterpriseVip(partyId, param).subscribe((res) => {
      console.log(res);

    }, err => {
      this.handleError(err, ResponseMsg.MESSAGES.ALL_IN_ONE);
    })
  }

  /**
   * Tai file GPKD
   * @param partyId 
   */
  downloadGPKD(partyId) {
    this.aasAuth.downloadGPKD(partyId).subscribe((res: any) => {
      this.fileGPKD = new Blob([res], { type: 'application/pdf' });
      this.showPreviewGPKD = true;
    }, err => {

    })
  }

  /**
   * Custom modal alert
   * @param msg 
   * @param type 
   * @param twoBtn 
   * @param iconBtn2 
   * @param titleBtn2 
   * @param actBtn2 
   * @param iconBtn1 
   * @param titleBtn1 
   * @param actBtn1 
   */
  customAlert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.customAlert(errorMsg[err.error.message], 'error');
    } else if (err.error && err.error.error) {
      this.customAlert(err.error.error, 'error');
    } else {
      this.customAlert('Lỗi không xác định! Vui lòng thử lại', 'error');
    }
  }
}
