import { Injectable } from '@angular/core';
import { API } from "../api";
import { ServicePath } from '../../common/constant-service-api';

const LIST_LICENSES_URL = ServicePath.MANAGEMENT_SERVICE + '/licenses?';
const CONTRACT_YEAR_URL = ServicePath.MANAGEMENT_SERVICE + '/licenses/';
const CONTRACR_REPORT_URL = ServicePath.MANAGEMENT_SERVICE + '/licenses/';
const LICENSE_INFO_URL = ServicePath.MANAGEMENT_SERVICE + '/licenses/';
const LICENSE_CUSTOMER_INFO_URL = ServicePath.MANAGEMENT_SERVICE + '/licenses/';
const LICENSE_PACKAGE_INFO_URL = ServicePath.MANAGEMENT_SERVICE + '/licenses/';
const LICENSE_CONTRACT_INFO_URL = ServicePath.MANAGEMENT_SERVICE + '/licenses/';

@Injectable({
  providedIn: 'root'
})

export class AasLicensesService {

  constructor(
    private api: API
  ) { }

    /**
   * GET Request lấy danh sách banre quyền
   * @param page 
   * @param maxSize 
   * @param propertiesSort 
   * @param sort 
   * @param packageType 
   * @param status 
   * @param searchString 
   */
  getListLicenses(page=1, maxSize=10, propertiesSort='created', sort='DESC', customerType, status, searchString) {
    const url = LIST_LICENSES_URL + `page=${page}&maxSize=${maxSize}&propertiesSort=${propertiesSort}&sort=${sort}&customerType=${customerType}&status=${status}&searchString=${searchString}`;
    return this.api.get(url);
  }


    /**
   * GET Request thống kê chi tiết giá trị hợp đồng 12 tháng trong năm
   * @param licenseId 
   * @param year 
   */
  getContractYear(licenseId, year){
    const url = CONTRACT_YEAR_URL + `${licenseId}/contract-value-statistic?` + `year=${year}`
    return this.api.get(url)
  }

   /**
   * GET Request thống kê số lượng hợp đồng
   * @param licenseId 
   */
  getContractReport(licenseId){
    const url = CONTRACR_REPORT_URL + `${licenseId}/contract-type-statistic` 
    return this.api.get(url)
  }

   /**
   * GET Request thông tin bản quyền gói
   * @param licenseId 
   */
  getLicensesInfo(licenseId){
    const url = LICENSE_INFO_URL + `${licenseId}` 
    return this.api.get(url)
  }

  /**
   * GET Request thông tin bản quyền khách hàng
   * @param licenseId 
   */
  getCustomerInfo(licenseId){
    const url = LICENSE_CUSTOMER_INFO_URL + `${licenseId}/customer-info` 
    return this.api.get(url)
  }

    /**
   * GET Request thông tin bản quyền gói dịch vụ
   * @param licenseId 
   */
  getPackageInfo(licenseId){
    const url = LICENSE_PACKAGE_INFO_URL + `${licenseId}/package-info` 
    return this.api.get(url)
  }


      /**
   * GET Request thông tin bản quyền hợp đồng
   * @param licenseId 
   */
  getContractInfo(licenseId){
    const url = LICENSE_PACKAGE_INFO_URL + `${licenseId}/contract-info` 
    return this.api.get(url)
  }

}