import { Injectable } from '@angular/core';
import { ServicePath } from 'src/app/utils/common/constant-service-api';
import { API } from '../../api';

const CONTRACT_NUMBER_CONFIG = ServicePath.MANAGEMENT_SERVICE + "/sequences";
const SUFFIX_VALUE = ServicePath.MANAGEMENT_SERVICE + "/sequences/suffix?prefix=";
const GET_NEXT_CODE_BY_SEQUENCE_ID = ServicePath.MANAGEMENT_SERVICE + "/sequences/$sequenceId/next-code";
const GET_DEFAULT_SEQUENCE = ServicePath.MANAGEMENT_SERVICE + "/sequences/default";
const ADD_CONTRACT_NUMBER_BOX = ServicePath.ESIGNATURE_SERVICE + '/dsign/sign-contract-number';
const SUBMIT_FILE_ADDED_CONTRACTNUMBER = ServicePath.MANAGEMENT_SERVICE + '/contracts/{{contractId}}/submit-sequence';
const SET_CONFIG_TO_DEFAULT = ServicePath.MANAGEMENT_SERVICE + "/sequences/{{id}}/set-default"

@Injectable({
  providedIn: 'root'
})
export class ContractNumberConfigService {

  constructor(private api: API) { }

  /**
   * Lấy danh sách các cấu hình số hợp đồng tự động
   * @param page 
   * @param maxSize 
   * @param searchString 
   * @param action 
   * @param propertiesSort
   * @param sort 
   * @returns 
   */
  getListContractNumberConfig(page: number = 1, maxSize: number = 10, searchString: string, action: string, propertiesSort: string, sort: string) {
    const url = CONTRACT_NUMBER_CONFIG
      + `?page=${page}`
      + `&maxSize=${maxSize}`
      + `&action=${action}`
      + `&propertiesSort=${propertiesSort}`
      + `&sort=${sort}`
      + `&searchString=${searchString}`;
    return this.api.get(url);
  }

  /**
   * Thêm mới cấu hình số hợp đồng
   * @param data 
   * @returns 
   */
  createContractNumberConfig(data: any) {
    const url = CONTRACT_NUMBER_CONFIG;
    return this.api.post(url, data);
  }

  /**
   * Cập nhật cấu hình số hợp đồng
   * @param id 
   * @param data 
   * @returns 
   */
  updateContractNumberConfig(id: string, data: any) {
    const url = CONTRACT_NUMBER_CONFIG + `/${id}`;
    return this.api.patch(url, data);
  }

  /**
   * Lấy thông tin chi tiết cấu hình số HĐ
   * @param id 
   * @returns
   */
  getDetails(id: string) {
    const url = CONTRACT_NUMBER_CONFIG + `/${id}`;
    return this.api.get(url);
  }

  /**
   * Lấy thông tin biến
   * @param suffix 
   * @returns 
   */
  getSuffixValue(suffix: string) {
    const url = SUFFIX_VALUE + `${suffix}`;
    return this.api.get(url);
  }

  getNextCodeBySequenceId(sequenceId: string) {
    return this.api.get(GET_NEXT_CODE_BY_SEQUENCE_ID.replace('$sequenceId', sequenceId));
  }

  getDefaultSequence() {
    return this.api.get(GET_DEFAULT_SEQUENCE);
  }

  /**
   * Gắn số hợp đồng vào file
   * @param data 
   * @returns 
   */
  addContractNumberBox(data: any) {
    return this.api.postMultiPart(ADD_CONTRACT_NUMBER_BOX, data);
  }

  /**
   * Gửi file đã gắn số hợp đồng lên hệ thống
   * @param data 
   * @param contractId 
   * @returns 
   */
  submitFileAddedContractNumber(data, contractId) {
    const url = SUBMIT_FILE_ADDED_CONTRACTNUMBER.replace("{{contractId}}", contractId);
    return this.api.postMultiPart(url, data);
  }

  /**
   * Chuyển cấu hình số hợp đồng thành mặc định (bằng Id)
   * @param id 
   * @returns 
   */
  setConfigToDefault(id: string) {
    return this.api.post(SET_CONFIG_TO_DEFAULT.replace("{{id}}", id), null);
  }
}
