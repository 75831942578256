import { Component, OnInit } from '@angular/core';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { localize } from 'src/app/utils/localize/localize';

@Component({
  selector: 'app-admin-menu',
  templateUrl: './admin-menu.component.html',
  styleUrls: ['./admin-menu.component.scss']
})
export class AdminMenuComponent implements OnInit {
  constantUrl = ConstantUrl;
  localize = localize;

  constructor() { }

  ngOnInit(): void {
  }

}
