export class ImageProgress {

    public static resizeImage(img, fileImg, max_width, max_height, callBack = null) {
        var width = img.width;
        var height = img.height;

        if (width > height) {
            if (width > max_width) {
                height = Math.round(height *= max_width / width);
                width = max_width;
            }
        } else {
            if (height > max_height) {
                width = Math.round(width *= max_height / height);
                height = max_height;
            }
        }
        var canvas = document.createElement('canvas');
        var ctx = canvas.getContext('2d');
        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(img, 0, 0, width, height);
        var self = this;
        let imgURL = ctx.canvas.toDataURL(fileImg.type, 1);
        this.urltoFile(imgURL, fileImg.name, fileImg.type).then(function (file) {
            callBack && callBack(file);
        });
        canvas.remove();
    }

    static urltoFile(url, filename, mimeType) {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }

    public static converBase64toBlob(content, contentType): Blob {
        contentType = contentType || '';
        var sliceSize = 512;
        var byteCharacters = window.atob(content); //method which converts base64 to binary
        var byteArrays = [
        ];
        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);
            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }
            var byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        var blob = new Blob(byteArrays, {
            type: contentType,
        }); //statement which creates the blob
        return blob;
    }

    /**
    * Chuyen PDF sang base64
    * @param blob 
    * @param callback 
    */
    public static getBase64String(self, blob, callback) {
        var reader = new FileReader();
        reader.onload = function (e) {
            callback(reader.result, self);
        }
        reader.readAsDataURL(blob);
    }
}