<div *ngIf="show" class="signature-body-block px-3 pt-3">
    <!-- Ký đuyệt -->
    <div *ngIf="approvalSign">
        <!-- Ký số, USB Token -->
        <div *ngIf="approvalSign.signForm == 'USB_TOKEN' || approvalSign.signForm == 'SIGN_SERVER' || approvalSign.signForm == 'SMART_CA'" class="signature-body">
            <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-10">
                    <div class="signature-information">
                        <div class="row">
                            <div class="col-md-6">
                                <label>Hình thức ký duyệt</label>
                                <p class="word-break">{{ getSignFormLabel(approvalSign?.signForm) }}</p>
                                <label>Chủ sở hữu</label>
                                <p class="word-break">{{ approvalSign?.name }}</p>
                                <label>Nhà cung cấp</label>
                                <p class="word-break">{{ approvalSign?.provider }}</p>
                            </div>
                            <div class="col-md-6">
                                <label>Trạng thái chữ ký</label>
                                <p><span
                                        class="badge {{getStyleAndLabelStatus(approvalSign?.status)[0]}} p-2">{{getStyleAndLabelStatus(approvalSign?.status)[1]}}</span>
                                </p>
                                <div *ngIf="approvalSign?.taxCode">
                                    <label>Mã số thuế</label>
                                    <p class="word-break">{{ approvalSign?.taxCode }}</p>
                                </div>
                                <div *ngIf="approvalSign?.identifierCode && userType != 'BUSINESS'">
                                    <label>Số giấy tờ</label>
                                    <p class="word-break">{{ approvalSign?.identifierCode }}</p>
                                </div>
                                <label>Ngày ký</label>
                                <p class="word-break">{{ approvalSign?.signDate | date: 'dd/MM/yyyy' }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Ký ảnh -->
        <div *ngIf="approvalSign.signForm == 'NO_AUTHEN'" class="signature-body">
            <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-10">
                    <div class="signature-information">
                        <div class="row">
                            <div class="col-md-6">
                                <label>Hình thức ký duyệt</label>
                                <p class="word-break">{{ getSignFormLabel(approvalSign?.signForm) }}</p>
                                <label>Ký bởi</label>
                                <p class="word-break">{{ approvalSign?.name }}</p>
                                <label>Ảnh chữ ký</label>
                                <img src="{{ approvalSign.getSignImage() }}" alt="" class="signature-image pb-3">
                            </div>
                            <div class="col-md-6">
                                <label>Trạng thái chữ ký</label>
                                <p><span
                                        class="badge {{getStyleAndLabelStatus(approvalSign?.status)[0]}} p-2">{{getStyleAndLabelStatus(approvalSign?.status)[1]}}</span>
                                </p>
                                <label>Ngày ký</label>
                                <p class="word-break">{{ approvalSign?.signDate | date: 'dd/MM/yyyy' }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- SMS OTP -->
        <div *ngIf="approvalSign.signForm == 'OTP'" class="signature-body">
            <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-10">
                    <div class="signature-information">
                        <div class="row">
                            <div class="col-md-6">
                                <label>Hình thức ký duyệt</label>
                                <p class="word-break">{{ getSignFormLabel(approvalSign?.signForm) }}</p>
                                <label>Ký bởi</label>
                                <p class="word-break">{{ approvalSign?.name }}</p>
                                <label>Ngày ký</label>
                                <p class="word-break">{{ approvalSign?.signDate | date: 'dd/MM/yyyy' }}</p>
                            </div>
                            <div class="col-md-6">
                                <label>Trạng thái chữ ký</label>
                                <p><span
                                        class="badge {{getStyleAndLabelStatus(approvalSign?.status)[0]}} p-2">{{getStyleAndLabelStatus(approvalSign?.status)[1]}}</span>
                                </p>
                                <label>Số điện thoại</label>
                                <p class="word-break">{{ approvalSign?.phone }}</p>
                                <label>Ảnh chữ ký</label>
                                <img src="{{ approvalSign.getSignImage() }}" alt="" class="signature-image pb-3">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Email OTP -->
        <div *ngIf="approvalSign.signForm == 'OTP_EMAIL'" class="signature-body">
            <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-10">
                    <div class="signature-information">
                        <div class="row">
                            <div class="col-md-6">
                                <label>Hình thức ký duyệt</label>
                                <p class="word-break">{{ getSignFormLabel(approvalSign?.signForm) }}</p>
                                <label>Ký bởi</label>
                                <p class="word-break">{{ approvalSign?.name }}</p>
                                <label>Ngày ký</label>
                                <p class="word-break">{{ approvalSign?.signDate | date: 'dd/MM/yyyy' }}</p>
                            </div>
                            <div class="col-md-6">
                                <label>Trạng thái chữ ký</label>
                                <p><span
                                        class="badge {{getStyleAndLabelStatus(approvalSign?.status)[0]}} p-2">{{getStyleAndLabelStatus(approvalSign?.status)[1]}}</span>
                                </p>
                                <label>Email</label>
                                <p class="word-break">{{ approvalSign?.email }}</p>
                                <label>Ảnh chữ ký</label>
                                <img src="{{ approvalSign.getSignImage() }}" alt="" class="signature-image pb-3">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- SMS OTP + eKYC -->
        <div *ngIf="approvalSign.signForm == 'EKYC'" class="signature-body">
            <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-10">
                    <div class="signature-information">
                        <div class="row">
                            <div class="col-md-6">
                                <label>Hình thức ký duyệt</label>
                                <p class="word-break">{{ getSignFormLabel(approvalSign?.signForm) }}</p>
                                <label>Ký bởi</label>
                                <p class="word-break">{{ approvalSign?.name?.value || approvalSign?.name || "" }}</p>
                                <label>Số giấy tờ</label>
                                <p class="word-break">{{ approvalSign?.identifierCode?.value || approvalSign?.identifierCode || "" }}</p>
                                <label>Kết quả định danh eKYC</label>
                                <p class="hyperlink-view-detail" (click)="viewDetail(approvalSign.ekycInfo, approvalSign.status, approvalSign.contractId, approvalSign.signId, approvalSign.partyId)">Xem chi tiết</p>
                            </div>
                            <div class="col-md-6">
                                <label>Trạng thái chữ ký</label>
                                <p><span
                                        class="badge {{getStyleAndLabelStatus(approvalSign?.status)[0]}} p-2">{{getStyleAndLabelStatus(approvalSign?.status)[1]}}</span>
                                </p>
                                <label>Số điện thoại</label>
                                <p class="word-break">{{ approvalSign?.phone }}</p>
                                <label>Ngày ký</label>
                                <p class="word-break">{{ approvalSign?.signDate | date: 'dd/MM/yyyy' }}</p>
                                <label>Ảnh chữ ký</label>
                                <img src="{{ approvalSign.getSignImage() }}" alt="" class="signature-image pb-3">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- Email OTP + eKYC -->
        <div *ngIf="approvalSign.signForm == 'EKYC_EMAIL'" class="signature-body">
            <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-10">
                    <div class="signature-information">
                        <div class="row">
                            <div class="col-md-6">
                                <label>Hình thức ký duyệt</label>
                                <p class="word-break">{{ getSignFormLabel(approvalSign?.signForm) }}</p>
                                <label>Ký bởi</label>
                                <p class="word-break">{{ approvalSign?.name?.value || approvalSign?.name || "" }}</p>
                                <label>Số giấy tờ</label>
                                <p class="word-break">{{ approvalSign?.identifierCode?.value || approvalSign?.identifierCode || "" }}</p>
                                <label>Kết quả định danh eKYC</label>
                                <p class="hyperlink-view-detail" (click)="viewDetail(approvalSign.ekycInfo, approvalSign.status, approvalSign.contractId, approvalSign.signId, approvalSign.partyId)">Xem chi tiết</p>
                            </div>
                            <div class="col-md-6">
                                <label>Trạng thái chữ ký</label>
                                <p><span
                                        class="badge {{getStyleAndLabelStatus(approvalSign?.status)[0]}} p-2">{{getStyleAndLabelStatus(approvalSign?.status)[1]}}</span>
                                </p>
                                <label>Email</label>
                                <p class="word-break">{{ approvalSign?.email }}</p>
                                <label>Ngày ký</label>
                                <p class="word-break">{{ approvalSign?.signDate | date: 'dd/MM/yyyy' }}</p>
                                <label>Ảnh chữ ký</label>
                                <img src="{{ approvalSign.getSignImage() }}" alt="" class="signature-image pb-3">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Ký nháy -->
    <div *ngIf="signatures && signatures.length > 0">
        <div *ngFor="let signature of signatures; let i = index" class="signature-body">
            <div class="row">
                <div class="col-md-1">
                </div>
                <div *ngIf="i > 0 || approvalSign" class="col-md-11">
                    <hr class="break-sign-infor">
                </div>
            </div>
            <div class="row">
                <div class="col-md-1"></div>
                <div class="col-md-10">
                    <div class="signature-information">
                        <div class="row">
                            <div class="col-md-6">
                                <label>Hình thức ký nháy</label>
                                <p class="word-break">{{ getSignFormLabel(signature?.signForm) }}</p>
                                <label>Chủ sở hữu</label>
                                <p class="word-break">{{ signature?.name }}</p>
                                <label>Nhà cung cấp</label>
                                <p class="word-break">{{ signature?.provider }}</p>
                            </div>
                            <div class="col-md-6">
                                <label>Trạng thái chữ ký</label>
                                <p><span
                                        class="badge {{getStyleAndLabelStatus(signature?.status)[0]}} p-2">{{getStyleAndLabelStatus(signature?.status)[1]}}</span>
                                </p>
                                <div *ngIf="signature?.taxCode">
                                    <label>Mã số thuế</label>
                                    <p class="word-break">{{ signature?.taxCode }}</p>
                                </div>
                                <div *ngIf="signature?.phone">
                                    <label>Số điện thoại</label>
                                    <p class="word-break">{{ signature?.phone }}</p>
                                </div>
                                <div *ngIf="signature?.email">
                                    <label>Email</label>
                                    <p class="word-break">{{ signature?.email }}</p>
                                </div>
                                <label>Ngày ký</label>
                                <p class="word-break">{{ signature?.signDate | date: 'dd/MM/yyyy' }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>