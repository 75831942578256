<div class="modal-overlay">
    <div class="">
        <div class="modal-editable rounded">
            <div class="modal-header border-0">
                <h5 class="modal-title" style="font-weight: 700; margin-right: auto;">{{'info_party_join_partner_discuss' | translate}}</h5>
                <button type="button" class="close" aria-label="Close" (click)="close()">
                    <span aria-hidden="true" class="btn-light btn-alert-exit bg-exit-btn d-inline-flex p-2 rounded">
                        <img src="assets/img/icon/icon-close.svg" width="18" height="18">
                    </span>
                </button>
            </div>

            <div class="modal-body text-center py-0 table-responsive enable-scroll">
                <div class="row">
                    <div *ngIf="partnerEditable || isCreator" class="col">
                        <table class="table table-bordered">
                            <thead class="bg-tb-header">
                                <tr>
                                    <th style="width: 80px;">{{ 'clc_tb_stt' | translate }}</th>
                                    <th>{{ 'clc_tb_party_join' | translate }}</th>
                                    <th>{{ 'clc_tb_employee_join' | translate }}</th>
                                    <th>{{ 'clc_tb_action' | translate }}</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of partnerDiscuss; let i = index">
                                    <td>{{ i + 1 }}</td>
                                    <td [class.bg-danger-tb]="item.hasError('partyId')">
                                        <ng-select
                                            [items]="item.dropListPartData | async"
                                            bindLabel="partyName"
                                            bindValue="partyId"
                                            [trackByFn]="item.trackByFn" 
                                            [loading]="item.partsLoading"
                                            [typeahead]="item.partsInput$"
                                            [(ngModel)]="item.partyId"
                                            (change)="item.changedParty($event)"
                                            appendTo="body"
                                            placeholder="{{ 'select_party_join' | translate }}"
                                            typeToSearchText="{{ 'PartnerDiscussModal_typeToSearchText' | translate }}"
                                            notFoundText="{{ 'EmployeeInPart_no_results' | translate }}"
                                        >
                                        </ng-select>
                                    </td>
                                    <td [class.bg-danger-tb]="item.hasError('userId')">
                                        <ng-select
                                            [items]="item.dropListEmployeeData | async"
                                            bindLabel="fullName"
                                            bindValue="userId"
                                            [trackByFn]="item.trackByFnEmployee" 
                                            [loading]="item.employeesLoading"
                                            [typeahead]="item.employeesInput$"
                                            [(ngModel)]="item.userId"
                                            appendTo="body"
                                            (change)="item.changedEmployee($event)"
                                            placeholder="{{ 'select_employee_join' | translate }}"
                                            typeToSearchText="{{ 'PartnerDiscussModal_typeToSearchText' | translate }}"
                                            notFoundText="{{ 'EmployeeInPart_no_results' | translate }}"
                                        >
                                        </ng-select>
                                    </td>
                                    <td [class.bg-danger-tb]="item.hasError('permissions')">
                                        <div class="icheck-primary mb-3" style="width: fit-content;">
                                            <input [checked]="item.per_action.PARTNER_DISCUSSABLE" type="checkbox"
                                                id="checkboxPrimary-PARTNER_DISCUSSABLE-{{i}}"
                                                (change)="item.changedAction('PARTNER_DISCUSSABLE')">
                                            <label for="checkboxPrimary-PARTNER_DISCUSSABLE-{{i}}">
                                                {{ 'join_discuss' | translate }}
                                            </label>
                                        </div>
                                        <div class="icheck-primary mb-3" style="width: fit-content;">
                                            <input [checked]="item.per_action.PARTNER_ACCEPTABLE" type="checkbox"
                                                id="checkboxPrimary-PARTNER_ACCEPTABLE-{{i}}"
                                                (change)="item.changedAction('PARTNER_ACCEPTABLE')">
                                            <label for="checkboxPrimary-PARTNER_ACCEPTABLE-{{i}}">
                                                {{ 'accept_deny_confirm' | translate }}
                                            </label>
                                        </div>
                                        <div class="icheck-primary mb-3" style="width: fit-content;">
                                            <input [checked]="item.per_action.PARTNER_EDITABLE" type="checkbox"
                                                id="checkboxPrimary-PARTNER_EDITABLE-{{i}}"
                                                (change)="item.changedAction('PARTNER_EDITABLE')">
                                            <label for="checkboxPrimary-PARTNER_EDITABLE-{{i}}">
                                                {{ 'add_remove_employee' | translate }}
                                            </label>
                                        </div>
                                    </td>
                                    <td>
                                        <a class="btn text-primary text-decoration-underline" (click)="removePartnerDiscuss(i)">{{'btn_delete' | translate}}</a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <!-- <div *ngIf="isSubmit" class="d-flex flex-column w-100 border-top pt-3 px-3">
                <span *ngIf="!validRequired" class="w-100 text-danger">• Vui lòng nhập đầy đủ thông tin của các bên tham gia hợp đồng</span>
                <span *ngIf="!validSequence" class="w-100 text-danger">• Thứ tự ký không được trùng nhau, phải lớn hơn 0 và phải nhỏ hơn số bên tham gia hợp đồng </span>
            </div> -->

            <!-- <div class="d-flex w-100 modal-toolbar-table">
                <a (click)="addRowPartyJoin(); addRow()" class="cursor-pointer text-primary mr-auto mt-3">
                    <span>+ Thêm mới</span>
                </a>
            </div> -->

            
            <div *ngIf="partnerDiscussError" class="d-flex flex-column w-100 border-top pt-3 px-3">
                <ol class="w-100 pl-3 text-danger mb-2">
                    <li *ngIf="partnerDiscussError['required']">
                        <p class="text-danger mb-2">{{'FlowSettings_fill_full_info' | translate}}</p>
                    </li>
                </ol>
            </div>
            <div class="d-flex w-100">
                <a class="btn text-primary text-decoration-underline" (click)="addPartnerDiscuss()">+ {{'btn_addnew' | translate}}</a>
            </div>

            <div class="modal-footer border-0 d-flex">
                <hr class="w-100">
                <button (click)="close()" type="button" class="btn btn-outline-primary d-inline-flex mr-3"><span
                        class="material-icons mr-2">close</span>{{'btn_cancel' | translate}}</button>
                <button (click)="save()" type="button" class="btn btn-primary d-inline-flex"><span
                        class="material-icons mr-2">save</span>{{'btn_save' | translate}}</button>
            </div>

        </div>
    </div>
</div>