<div class="spinner-place">
    <div *ngIf="!loadingService.disabled" id="ect-loader" style="display: none;" [@inOutAnimation] class="ect-loader app-spinner">
        <!-- *ngIf="loadingService.isVisible() | async" -->
        <div id="overlay-bg">
            <!-- <div id="modal-spinner">
            </div> -->
        </div>
        <!-- <div class="fix-spinner-style-1">
            <div class="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div> -->
        <!-- <div class="fix-spinner-style-2">
            <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
        </div> -->
    
        <div class="spinner-dual-ring">
            <img src="assets/img/loading-icon.svg" height="60" width="60">
        </div>
    </div>
</div>
