<div class="pb-3">
    <div class="card card-body">
       
                <div *ngFor="let item of history; let i =index" class="pb-3">
                    <div class="row">
                        <div class="col-md-auto ">
                            <div class="icon-background">
                                <img class="icon-point"
                                    src="..\..\..\..\..\..\..\..\assets\img\icon\database_upgrade_24px.svg"
                                    alt="">
                                <div *ngIf="i + 1 < history.length" class="vertical-line"></div>
                                <!-- <div  class="vertical-line"></div> -->

                            </div>

                        </div>
                        <div class="col-md-auto">
                            <label>{{ item?.name }} - <span class="text-primary">{{ formatPrice(item?.price) }} VNĐ</span></label>
                            <br>
                            <span>{{ item?.created | date: 'dd/mm/yyyy' }}</span>
                            <div class="table-responsive mt-3" style="max-width: 600px">
                                <table class="table infra-table">
                                    <thead>
                                        <tr class="table-header">
                                            <th>STT</th>
                                            <th>Thông số</th>
                                            <th>Đơn vị tính</th>
                                            <th>Số lượng</th>
                                            <th>Tổng cộng</th>
                                        </tr>                                                        
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let infra of item?.infrastructures; let j = index">
                                            <td><span *ngIf="j < 9">0</span>{{ j + 1 }}</td>
                                            <td>{{ infra?.name }}</td>
                                            <td>{{ infra?.unit }}</td>
                                            <td class="td-number">{{ infra?.unitNumber }}</td>
                                            <td class="td-number">{{ infra?.total }} </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
    </div>
</div>
