import { MainResponse } from '../../main-response.model';
import { EnterpriseManagerObject } from './enterprise-manager-object';

export class EnterpriseManagerResponse extends MainResponse {
    object: EnterpriseManagerObject;
    constructor(obj = null) {
        super();
        obj = obj || {};
        this.init(obj);
        this.object = new EnterpriseManagerObject(obj.object);
    }
}