export class InfoUserObject {
    userId: string;
    disable: boolean;
    daiDien: boolean;
    created: string;
    modified: string;
    ten: string;
    chucVu: string;
    donVi: string;
    cmnd: string;
    ngayCap: string;
    noiCap: string;
    ngaySinh: string;
    gioiTinh: string;
    gioiTinhId: number;
    soNha: string;
    duong: string;
    xa: string;
    xaId: number;
    huyen: string;
    huyenId: number;
    tinh: string;
    tinhId: number;
    thanhPho: string;
    diaChiThuongTru: string;
    email: string;
    sdt: string;
    partyId: string;
    party: Party;
    identityLevel: number = 0;
    username: string = "";
    userType: string = "";
    videocall: boolean = false;
    verificationStatus: string = "";

    constructor(obj = null) {
        obj = obj || {};
        this.userId = obj.userId || '';

        if (obj.disable === 'Y' || obj.disable === true) {
            this.disable = true;
        } else {
            this.disable = false;
        }

        this.daiDien = obj.daiDien || '';
        this.created = obj.created || '';
        this.modified = obj.modified || '';
        this.ten = obj.ten || '';
        this.chucVu = obj.chucVu || '';
        this.donVi = obj.donVi || '';
        this.cmnd = obj.cmnd || '';
        this.ngayCap = obj.ngayCap || '';
        this.noiCap = obj.noiCap || '';
        this.ngaySinh = obj.ngaySinh || '';
        this.gioiTinh = obj.gioiTinh || '';
        this.gioiTinhId = Number(obj.gioiTinhId) || null;
        this.soNha = obj.soNha || '';
        this.duong = obj.duong || '';
        this.xa = obj.xa || '';
        this.xaId = Number(obj.xaId) || null;
        this.huyen = obj.xa || '';
        this.huyenId = Number(obj.huyenId) || null;
        this.tinh = obj.tinh || '';
        this.tinhId = Number(obj.tinhId) || null;
        this.thanhPho = obj.thanhPho || '';
        this.diaChiThuongTru = obj.diaChiThuongTru || '';
        this.email = obj.email || '';
        this.sdt = obj.sdt || '';
        this.partyId = obj.partyId || '';
        this.party = new Party(obj.party);
        this.identityLevel = Number(obj.identityLevel) || 0;
        this.username = obj.username || "";
        this.userType = obj.userType || '';

        if (obj.videocall === 'Y' || obj.videocall === true) {
            this.videocall = true;
        } else {
            this.videocall = false;
        }

        this.verificationStatus = obj.verificationStatus || '';
    }

    get diaChi() {
        var diaChi = '';
        if (this.soNha !== '') {
            diaChi += this.soNha + ' ';
        }

        if (this.duong !== '') {
            diaChi += this.duong + ', ';
        }

        if (this.xa !== '') {
            diaChi += this.xa + ', ';
        }

        if (this.huyen !== '') {
            diaChi += this.huyen + ', ';
        }

        if (this.tinh !== '') {
            diaChi += this.tinh + '.';
        }

        return diaChi;
    }
}

export class Party {
    partyId: string;
    partyNumber: string;
    disable: boolean;
    type: string;
    partyLevel: number;
    partyLevelSize: number;
    created: string;
    modified: string;
    referenceId: string;
    tenToChuc: string;
    soDkdn: string;
    ngayCapSoDkdn: string;
    noiCapSoDkdn: string;
    diaChiThanhToanTinh: string;
    diaChiThanhToanHuyen: string;
    diaChiThanhToanXa: string;
    diaChiThanhToanSoNha: string;
    diaChiThanhToanDuong: string;
    diaChiTruSoTinhId: number;
    diaChiTruSoHuyenId: number;
    diaChiTruSoXaId: number;
    diaChiTruSoTinh: string;
    diaChiTruSoHuyen: string;
    diaChiTruSoXa: string;
    diaChiTruSoSoNha: string;
    diaChiTruSoDuong: string;
    maSoThue: string;
    soTaiKhoanGiaoDich: string;
    nganHangGiaoDich: string;
    sdt: string;
    email: string;
    thongTinKhac: string;

    constructor(obj = null) {
        obj = obj || {};
        this.partyId = obj.partyId || '';
        this.partyNumber = obj.partyNumber || '';
        if (obj.disable === 'Y' || obj.disable === true) {
            this.disable = true;
        } else {
            this.disable = false;
        }
        this.type = obj.type || '';
        this.partyLevel = obj.partyLevel || '';
        this.partyLevelSize = obj.partyLevelSize || '';
        this.created = obj.created || '';
        this.modified = obj.modified || '';
        this.referenceId = obj.referenceId || '';
        this.tenToChuc = obj.tenToChuc || '';
        this.soDkdn = obj.soDkdn || '';
        this.ngayCapSoDkdn = obj.ngayCapSoDkdn || '';
        this.noiCapSoDkdn = obj.noiCapSoDkdn || '';
        this.diaChiThanhToanTinh = obj.diaChiThanhToanTinh || '';
        this.diaChiThanhToanHuyen = obj.diaChiThanhToanHuyen || '';
        this.diaChiThanhToanXa = obj.diaChiThanhToanXa || '';
        this.diaChiThanhToanSoNha = obj.diaChiThanhToanSoNha || '';
        this.diaChiThanhToanDuong = obj.diaChiThanhToanDuong || '';
        this.diaChiTruSoTinh = obj.diaChiTruSoTinh || '';
        this.diaChiTruSoHuyen = obj.diaChiTruSoHuyen || '';
        this.diaChiTruSoXa = obj.diaChiTruSoXa || '';
        this.diaChiTruSoTinhId = Number(obj.diaChiTruSoTinhId) || null;
        this.diaChiTruSoHuyenId = Number(obj.diaChiTruSoHuyenId) || null;
        this.diaChiTruSoXaId = Number(obj.diaChiTruSoXaId) || null;
        this.diaChiTruSoSoNha = obj.diaChiTruSoSoNha || '';
        this.diaChiTruSoDuong = obj.diaChiTruSoDuong || '';
        this.maSoThue = obj.maSoThue || '';
        this.soTaiKhoanGiaoDich = obj.soTaiKhoanGiaoDich || '';
        this.nganHangGiaoDich = obj.nganHangGiaoDich || '';
        this.sdt = obj.sdt || '';
        this.email = obj.email || '';
        this.thongTinKhac = obj.thongTinKhac || '';
    }

    get diaChiTruSo() {
        var diaChi = '';
        if (this.diaChiTruSoSoNha !== '') {
            diaChi += this.diaChiTruSoSoNha + ' ';
        }

        if (this.diaChiTruSoDuong !== '') {
            diaChi += this.diaChiTruSoDuong + ', ';
        }

        if (this.diaChiTruSoXa !== '') {
            diaChi += this.diaChiTruSoXa + ', ';
        }

        if (this.diaChiTruSoHuyen !== '') {
            diaChi += this.diaChiTruSoHuyen + ', ';
        }

        if (this.diaChiTruSoTinh !== '') {
            diaChi += this.diaChiTruSoTinh + '.';
        }

        return diaChi;
    }
}