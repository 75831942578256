import { PartnerService } from 'src/app/utils/services/aas-network/partner.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ConstantAlertBtn, ConstantAlertMsg } from 'src/app/utils/common/constant-alert-msg';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { UserTypeConstant } from 'src/app/utils/common/constant';
import { Constant } from 'src/app/utils/common/constant_videocall';
import { PartnerBusiness } from 'src/app/utils/models/aas-models/partner/partner-verification/partner-business/partner-business';
import { PartnerConsumer } from 'src/app/utils/models/aas-models/partner/partner-verification/partner-consumer/partner-consumer';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { DigitalSignatureResponse } from 'src/app/utils/models/aas-models/signature/digital-signature/digital-signature-response';
import { DigitalSignatureItem } from 'src/app/utils/models/aas-models/signature/digital-signature/digital-signature-item';
import { SignatureService } from 'src/app/utils/services/aas-network/signature.service';
import { AlertControl } from 'src/app/utils/alert/alert-control';

@Component({
  selector: 'app-trusted-partner-detail',
  templateUrl: './trusted-partner-detail.component.html',
  styleUrls: ['./trusted-partner-detail.component.scss']
})
export class TrustedPartnerDetailComponent implements OnInit {

  userType = this.activedRoute.snapshot.paramMap.get('userType');
  id = this.activedRoute.snapshot.paramMap.get('id');

  img_prefix = "data:image/png;base64,";
  imgFace: any = null;
  imgFront: any = null;
  imgBack: any = null;
  imgCks: any = null;

  partnerConsumer: PartnerConsumer = new PartnerConsumer();
  partnerBusiness: PartnerBusiness = new PartnerBusiness();

  gender = ["WaitPartyPartner_agent_gender_0", "WaitPartyPartner_agent_gender_1", "WaitPartyPartner_agent_gender_2"];


  fileGPKD: Blob = null;
  showPreviewGPKD: boolean = false;

  esign: DigitalSignatureItem = new DigitalSignatureItem();

  constructor(
    private activedRoute: ActivatedRoute,
    private partnerService: PartnerService,
    private signatureService: SignatureService,
    private modalService: NgbModal,
    private alert: AlertControl
  ) { }

  ngOnInit(): void {
    switch(this.userType) {
      case UserTypeConstant.BUSINESS:
        this.getDetailTrustedBusinessPartner(this.id);
        break;
      case UserTypeConstant.CONSUMER:
        this.getDetailTrustedConsumerPartner(this.id);
        this.getListDigitalSignature();
        break;
    }
  }

  actDownloadGPKD(id) {
    this.downloadGPKD(id);
  }

  handleClosePreview() {
    this.showPreviewGPKD = false;
  }

  // //MARK: Popup thông báo
  // alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = ConstantAlertBtn.OK, actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = ConstantAlertBtn.CANCEL, actBtn1 = () => { }) {
  //   const modalRef = this.modalService.open(AlertComponent);
  //   modalRef.componentInstance.message = msg;
  //   modalRef.componentInstance.typeAlert = type;
  //   modalRef.componentInstance.twoBtn = twoBtn;
  //   modalRef.componentInstance.iconBtn1 = iconBtn1;
  //   modalRef.componentInstance.iconBtn2 = iconBtn2;
  //   modalRef.componentInstance.titleButton1 = titleBtn1;
  //   modalRef.componentInstance.titleButton2 = titleBtn2;
  //   modalRef.componentInstance.callback1 = actBtn1;
  //   modalRef.componentInstance.callback2 = actBtn2;
  // }

  // //MARK: Handle Error
  // handleError(err, errorMsg) {
  //   if (err.error && err.error.message && errorMsg[err.error.message]) {
  //     this.alert(errorMsg[err.error.message], ConstantAlertType.ERROR);
  //   } else if (err.error && err.error.error) {
  //     this.alert(err.error.error, ConstantAlertType.ERROR);
  //   } else {
  //     this.alert(ConstantAlertMsg.DEFAULT_ERROR_REQUEST, ConstantAlertType.ERROR);
  //   }
  // }

  //MARK: NETWORKING
  /**
   * Lay thong tin doi tac tin cay la ca nhan
   * @param id 
   */
  getDetailTrustedConsumerPartner(id) {
    this.partnerService.getDetailTrustedConsumerPartner(id).subscribe((res: any) => {
      this.partnerConsumer = new PartnerConsumer(res.object || {});
      this.getImage(this.partnerConsumer.userId, 'EKYC_CHANDUNG');
      this.getImage(this.partnerConsumer.userId, 'EKYC_MATTRUOC');
      this.getImage(this.partnerConsumer.userId, 'EKYC_MATSAU');
    }, err => {

    });
  }

  /**
   * Lay thong tin doi tac tin cay la to chuc
   * @param id 
   */
  getDetailTrustedBusinessPartner(id) {
    this.partnerService.getDetailTrustedBusinessPartner(id).subscribe((res: any) => {
      this.partnerBusiness = new PartnerBusiness(res.object || {});
    }, err => {

    })
  }

    /**
   * Download Hinh anh
   * @param id 
   * @param type 
   */
  getImage(id, type) {
    this.partnerService.downloadImage(id, type).subscribe(
        (res) => {
            console.log('image', res);
            if (res) {
                const blob: any = new Blob([res], {
                    type: 'image/png',
                });
                var reader = new FileReader();
                let base64data: any;
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    if (type === 'EKYC_CHANDUNG') {
                        this.imgFace = reader.result;
                    } else if (type === 'EKYC_MATTRUOC') {
                        this.imgFront = reader.result;
                    } else {
                        this.imgBack = reader.result;
                    }
                };
            }
        },
        (err) => {
            if (type === 'EKYC_CHANDUNG') {
                this.imgFace = null;
            } else if (type === 'EKYC_MATTRUOC') {
                this.imgFront = null;
            } else {
                this.imgBack = null;
            }
        },
        () => { }
    );
  }

  /**
   * Tai file GPKD
   * @param partyId 
   */
  downloadGPKD(partyId) {
    this.partnerService.downloadGPKD(partyId).subscribe((res: any) => {
      this.fileGPKD = new Blob([res], { type: 'application/pdf' });
      this.showPreviewGPKD = true;
    }, err => {

    })
  }

  /**
   * Lay thong tin chu ky dien tu
   */
  getListDigitalSignature() {
    this.signatureService.getListDigitalSignature().subscribe((res: any) => {
      this.esign = new DigitalSignatureItem(res.object);
      this.imgCks = this.img_prefix + this.esign.base64Image;
    }, err => {
      this.alert.showErrorHandled(err);
    });
  }
}
