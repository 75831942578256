import { MainResponse } from '../../models/main-response.model';
import { CategoryItem } from './category-item.model';

export class CategoryResponse extends MainResponse {
  constructor(obj = null) {
    super();
    this.init(obj);
    this.object = new CategoryItem(obj.object);
  }
}
