export class ResquestConvertItem{
    tenKhachHang: string;
    goiDangSuDung: string;
    goiDangKyMoi: string;
    ngayYeuCau: string;
    trangThai: string;
    contractId : string;
    partyId: string;
    packageId: string;
    // verificationStatus
    packageTempId: string = "";
    packageTempName: string = "";
    packageName: string = "";
    loaiKhachHang: string;

    constructor(obj = null) {
        obj = obj || {};
        this.tenKhachHang = obj.ten || '';
        this.goiDangSuDung = obj.packageName || '';
        this.goiDangKyMoi = obj.packageTempName || '';
        this.ngayYeuCau = obj.modified || '';
        this.trangThai = obj.packageStatus || '';
        this.contractId = obj.contractVipId || '';
        this.partyId = obj.partyId || '';
        this.packageId = obj.packageId || '';

        this.packageTempId = obj.packageTempId || "";
        this.packageTempName = obj.packageTempName || "";
        this.packageName = obj.packageName || "";
        this.loaiKhachHang = obj.partyType || "";
    }

}