export const ConstantAlertMsg = {
    CONFIRM: "Xác nhận",
    NOTIFICATION: "Thông báo",
    DEFAULT_ERROR_REQUEST: 'Dịch vụ lỗi! Vui lòng thử lại!',
    CREAT_API_CONFIRM: 'Bạn chắc chắn muốn lưu thông tin api?',
    CREATED_API_SUCCESS: 'Lưu thông tin api thành công!',
    UPDATE_API_CONFIRM: 'Bạn chắc chắn muốn lưu thông tin api?',
    UPDATED_API_SUCCESS: 'Lưu thông tin api thành công!',
    DELETE_API_CONFIRM: 'Bạn chắc chắn muốn xóa thông tin api?',
    DELETE_API_SUCCESS: 'Xóa thông tin api thành công!',
    
    SIGNUP_VIP_CONFIRM_SELECT_PACKAGE: 'Bạn chắc chắn muốn sử dụng gói dịch vụ này?',

    OTP_VERIFY_MAXIMUM: 'Bạn đã nhập sai OTP quá 3 lần. Vui lòng thử lại sau!',
    ENDUSER_SELECT_PACKAGE_CONFIRM: 'Bạn có chắc muốn chọn gói dịch vụ này!',
    ENDUSER_SELECT_PACKAGE_SUCCESS: 'Chọn gói dịch vụ thành công!',
    DOCUMENT_DELETE_CONFIRM: 'Bạn có chắc chắn muốn xoá tài liệu này?',
    DOCUMENT_DELETE_SUCCESS: 'Xoá tài liệu thành công!',
    DOCUMENT_CREATE_CONFIRM: 'Bạn chắc chắn muốn khởi tạo tài liệu này?',
    DOCUMENT_CREATE_SUCCESS: 'Khởi tạo tài liệu thành công!',
    DOCUMENT_UPDATE_CONFIRM: 'Bạn chắc chắn muốn lưu thay đổi cho loại tài liệu này?',
    DOCUMENT_UPDATE_SUCCESS: 'Chỉnh sửa tài liệu thành công!',
    DOCUMENT_CREATE_PACKAGE_CONFIRM: "Bạn chắc chắn muốn khởi tạo lô danh mục tài liệu?",
    DOCUMENT_CREATE_PACKAGE_SUCCESS: 'Khởi tạo danh mục tài iệu thành công!',
    PARTNER_BUSINESS_APPROVE_CONFIRM: 'Bạn chắc chắn muốn phê duyệt tài khoản này?',
    PARTNER_BUSINESS_APPROVE_SUCCESS: 'Phê duyệt tài khoản thành công!',
    PARNTER_BUSINESS_APPROVE_NOVIDEOCALL_CONFIRM: 'Tài khoản này chưa thực hiện Video KYC. Bạn vẫn muốn tiếp tục phê duyệt?',
    PARTNER_BUSINESS_REJECT_CONFIRM: 'Bạn chắc chắn muốn từ chối tài khoản này?',
    PARTNER_BUSINESS_REJECT_SUCCESS: 'Từ chối phê duyệt tài khoản thành công!',
    PARTNER_CONSUMER_APPROVE_CONFIRM: 'Bạn chắc chắn muốn phê duyệt tài khoản này?',
    PARTNER_CONSUMER_APPROVE_SUCCESS: 'Phê duyệt tài khoản thành công!',
    PARNTER_CONSUMER_APPROVE_NOVIDEOCALL_CONFIRM: 'Tài khoản này chưa thực hiện Video KYC. Bạn vẫn muốn tiếp tục phê duyệt?',
    PARTNER_CONSUMER_REJECT_CONFIRM: 'Bạn chắc chắn muốn từ chối tài khoản này?',
    PARTNER_CONSUMER_REJECT_SUCCESS: 'Từ chối phê duyệt tài khoản thành công!',
    VIDEOCALL_NODEVICE: 'Khách hàng này chưa đăng ký thiết bị!',

    API_KEY_SEND_MAIL_CONFIRM: 'Bạn chắc chắn muốn gửi thông báo đến email này?',
    API_KEY_SEND_MAIL_SUCCESS: 'Gửi email thông báo thành công!',
    API_KEY_LOCK: 'Bạn chắc chắn muốn khóa API Key này?',
    API_KEY_LOCK_SUCCESS: 'Khóa API Key thành công!',
    API_KEY_UNLOCK: 'Bạn chắc chắn muốn mở khóa API Key này?',
    API_KEY_UNLOCK_SUCCESS: 'Mở khóa API Key thành công!',

    CONTRACT_CONFIRM_ACCEPT_DRAFT: 'Bạn chắc chắn đồng ý thỏa thuận cho hợp đồng này?',
    CONTRACT_CONFIRM_DENY_DRAFT: 'Bạn chắc chắn từ chối thỏa thuận cho hợp đồng này?',
    CONTRACT_CONFIRM_SEND_DRAFT: "Bạn chắc chắn muốn gửi hợp đồng cho các bên?",

    PROFILE_UPDATE_SUCCESS: "Cập nhật thông tin thành công!",
    PROFILE_UPDATE_CONFIRM: "Bạn chắc chắn muốn lưu thông tin thay đổi cho tài khoản này?",

    REQUEST_TIMEOUT: "Yêu cầu hết hạn! Vui lòng thử lại!",
    TOKEN_TIMEOUT: "Phiên đăng nhập đã hết hạn! Vui lòng đăng nhập lại!",

    UP_IDENTITY: "Vui lòng kiểm tra email và cài đặt ứng dụng di động để thực hiện các bước xác minh tài khoản và trải nghiệm ngay bây giờ",

    PART_ADD_CONFIRM: "Bạn chắc chắn muốn khởi tạo bộ phận này?",
    PART_ADD_SUCCESS: "Khởi tạo bộ phận thành công!",
    PART_ADD_FAILED: "Khởi tạo bộ phận thất bại!",
    PART_UPDATE_CONFIRM: "Bạn chắc chắn muốn cập nhật thông tin của bộ phận này?",
    PART_UPDATE_SUCCESS: "Cập nhật thông tin bộ phận thành công!",
    PART_UPDATE_FAILED: "Cập nhật thông tin bộ phận không thành công!",
    PART_UPDATE_FAILED_STATUS: "Bạn không thể chuyển trạng thái của bộ phận này sang <b>Ngưng hoạt động</b> vì có tài khoản nhân sự thuộc bộ phận đang ở trạng thái <b>Đang hoạt động</b>. Vui lòng kiểm tra lại.",

    EMPLOYEE_ADD_CONFIRM: "Bạn chắc chắn muốn khởi tạo nhân sự này?",
    EMPLOYEE_ADD_SUCCESS: "Khởi tạo nhân sự thành công!",
    EMPLOYEE_ADD_FAILED: "Khởi tạo nhân sự thất bại!",
    EMPLOYEE_UPDATE_CONFIRM: "Bạn chắc chắn muốn cập nhật thông tin của nhân sự này?",
    EMPLOYEE_UPDATE_SUCCESS: "Cập nhật thông tin nhân sự thành công!",
    PEMPLOYEE_UPDATE_FAILED: "Cập nhật thông tin nhân sự không thành công!",
    EMPLOYEE_UPDATE_FAILED_STATUS_CONTRACT: "Nhân sự này đang tham gia duyệt hợp đồng cho khách hàng cá nhân. Bạn không thể thực hiện cập nhật trạng thái nhân sự này thành Ngưng hoạt động.",
    EMPLOYEE_UPDATE_FAILED_STATUS_BY_PART_STATUS: "$partName toán đang trong trạng thái Ngưng hoạt động. Vui lòng kích hoạt lại bộ phận trước khi thay đổi trạng thái của nhân sự này.",
    EMPLOYEE_FINISHED_SIGNUP_SUCCESS: "Đăng ký tài khoản thành công!",
    ADMIN_APPROVE_TITLE_CONFIRM: "Xác nhận phê duyệt",
    ADMIN_APPROVE_CONSUMER_CONFIRM: "Bạn chắc muốn phê duyệt tài khoản này?",
    ADMIN_APPROVE_CONSUMER_NO_VIDEOCALL: "Tài khoản này chưa thực hiện Video KYC. Bạn vẫn muốn tiếp tục phê duyệt?",
    ADMIN_APPROVE_CONSUMER_SUCCESS: "Phê duyệt tài khoản thành công!",
    ADMIN_APPROVE_CONSUNER_FAILED: "Phê duyệt tài khoản thất bại!",
    ADMIN_REJECT_CONSUNER_SUCCESS: "Từ chối phê duyệt tài khoản thành công!",
    ADMIN_REJECT_CONSUNER_FAILED: "Từ chối phê duyệt tài khoản không thành công!",

    FILE_SIZE_TOO_LARGE: "File đính kèm dung lượng không được vượt quá 5Mb!",
    CONVERT_PDF_FAILED: "Chuyển định dạng PDF không thành công! Vui lòng kiểm tra lại nội dung tệp hoặc chọn tệp khác.",

    ADMIN_UPDATE_ACC_CONFIRM: "Bạn có chắc chắn muốn lưu dữ liệu?",
    ADMIN_UPDATE_ACC_SUCCESS: "Cập nhật thành công thành công!",

    DIEU_KHOAN_MODAL_TITLE: "Điều khoản pháp lý",

    UPDATE_CONTRACT_FILE_CONFIRM: "Bạn chắc chắn muốn thay đổi nội dung hợp đồng?",
    UPDATE_CONTRACT_FILE_SUCCESS: "Thay đổi nội dung hợp đồng thành công!",
    UPDATE_CONTRACT_FILE_FAILED: "Thay đổi nội dung hợp đồng không thành công!",

    SEND_CENSORSHIP_INTERNAL_CONFIRM: "Bạn chắc chắn muốn chuyển bộ phận này kiểm duyệt nội bộ?",
    SEND_CENSORSHIP_INTERNAL_SUCCESS: "Chuyển kiểm duyệt thành công!",
    SEND_CENSORSHIP_INTERNAL_FAILED: "Chuyển kiểm duyệt không thành công! Vui lòng thử lại.",

    CONTRACT_NOT_ALLOW_SIGN: "Bạn không có quyền ký hợp đồng này! Vui lòng kiểm tra lại!",
    CONTRACT_DONT_HAVE_SIGN_BOX: "'Bạn cần thêm ít nhất 1 chữ ký!'",
    CONTRACT_SAVE_SIGN_BOX_SUCCESS: 'Lưu chữ ký thành công!',
    CONTRACT_TIMEOUT_VNPT_SIGN_SERVER: 'VNPT ký số: Phiên đăng nhập ký số hết hiệu lực!',

    VNPT_SIGN_DONT_CONFIG: "Bạn chưa cấu hình tài khoản Sign Server! Vui lòng cấu hình tài khoản!",
    SAVE_SIGN_BOX_SUCCESS: "Lưu chữ ký thành công!"
}

export const ConstantAlertBtn = {
    CONFIRM: 'Xác nhận',
    CANCEL: 'Hủy',
    OK: 'Đồng ý',
    CLOSE: 'Đóng',
    SAVE: 'Lưu',
    BTN_LOGIN: "Đăng nhập",
    REMOVE: 'Xóa'
}