import { FormControl, Form } from '@angular/forms';
import { StringUtils } from './StringUtils';
import { REGEXP_PHONE_NUMBER } from './regexp';

const REGEX_SUB_COMPANY_NAME = new RegExp(/^[A-Za-z\d\s%&,().+:\/;"\s-]+$/);
const REGEX_SUB_SHORT_COMPANY_NAME = new RegExp(/^[A-Za-z\d\s%&,().+:\/;"\s-]+$/);

export class ValidatorsExtension {

    /**
     * Validate họ và tên
     * @param control 
     */
    public static validateFullName(control: FormControl) {
        let value = control.value;

        if (value === null || value === undefined) return;

        value = StringUtils.replaceUnicodeChar(value);
    
        // /^[a-zA-Z!@#\$%\^\&*\)\(+=._-]{2,}$/g
        var re = /^[a-zA-Z0-9\s]+$/g // regex here
        if (!re.test(value)) {
          return {
            pattern: true
          }
        }
    }

    /**
     * Validate tên doanh nghiệp
     * @param control 
     */
    public static validateCompanyName(control: FormControl) {
      let value = control.value;
      
      if (value === null || value === undefined) return;

      value = StringUtils.replaceUnicodeChar(value);
      if (!REGEX_SUB_COMPANY_NAME.test(value)) {
        return {
          pattern: true
        }
      }
    }

    /**
     * Validate tên rút gọn của doanh nghiệp
     * @param control 
     */
    public static validateShortCompanyName(control: FormControl) {
      let value = control.value;

      if (value === null || value === undefined) return;

      value = StringUtils.replaceUnicodeChar(value);
      if (!REGEX_SUB_SHORT_COMPANY_NAME.test(value)) {
        return {
          pattern: true
        }
      }
    }

    /**
     * Validate số điện thoại
     * @param control 
     */
    public static phoneNumber(control: FormControl) {
      let value = control.value;

      if (value === null || value === undefined) return;

      if (value.length <= 0) return;

      if (value === '0000000000' || value === '00000000000') return { pattern: true };

      if (!REGEXP_PHONE_NUMBER.test(value)) {
        return { pattern: true }
      }
    }
}