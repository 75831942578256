import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, timer } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { ControlAccount } from 'src/app/utils/common/control-account';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { UtilsFunc } from 'src/app/utils/common/utils-func';
import { localize } from 'src/app/utils/localize/localize';
import { AasContractService } from 'src/app/utils/services/aas-network/aas-contract.service';
import { SignatureService } from 'src/app/utils/services/aas-network/signature.service';

@Component({
  selector: 'app-e-sign-otp',
  templateUrl: './e-sign-otp.component.html',
  styleUrls: ['./e-sign-otp.component.scss']
})
export class ESignOtpComponent implements OnInit {
  @Input()
  set sdt(value) { this._sdt = value; }
  get sdt() { return this._sdt;}
  _sdt: string = "";
  @Input()
  set idSign(value) { this._idSign = value; }
  get idSign() { return this._idSign;}
  _idSign: string = "";
  @Input()
  set signForm(value) { this._signForm = value; }
  get signForm() { return this._signForm;}
  _signForm: string = "";
  @Input()
  set limitResendOTP(value) { this._limitResendOTP = value; }
  get limitResendOTP() { return this._limitResendOTP;}
  _limitResendOTP = 1;

  @Output('confirmOTPSuccess') confirmOTPSuccess: EventEmitter<any> = new EventEmitter();
  @Output('limitFailed') limitFailedEvent: EventEmitter<any> = new EventEmitter();
  @Output('minusResendOTP') minusResendOTP: EventEmitter<any> = new EventEmitter();

  contractId = this.activeRoute.snapshot.paramMap.get('id') || '';

  localize = localize;

  //form confirm top e-sign
  formOTP: FormGroup;
  isOTPSubmit: Boolean = false;
  validMsgOTP = {
    otp: {
        required: this.translate.instant('ESignOtp_otp_required'),
        minlength: this.translate.instant('ESignOtp_otp_minlength'),
        maxlength: this.translate.instant('ESignOtp_otp_maxlength')
    }
  };
  
  visabled = false;
  limitFailed = 3;
  counter$: Observable<string>;
  count = 0;
  
  constructor(
    private fb: FormBuilder,
    private contractService: AasContractService,
    private signatureService: SignatureService,
    private myAlert: AlertControl,
    private router: Router,
    private translate: TranslateService,
    private activeRoute: ActivatedRoute,
    private controlAccount: ControlAccount,
  ) { }

  ngOnInit(): void {
    this.makeConfirmOTPForm();
  }

  show() {
    this.formOTP.reset();
    this.limitFailed = 3;
    setTimeout(() => {
      this.visabled = true;
    })
  }

  hide() {
    setTimeout(() => {
      this.visabled = false;
    })
  }

  //OTP Form 
  makeConfirmOTPForm() {
    this.formOTP = this.fb.group({
      otp: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]),
      documentHash: new FormControl('')
    });
  }

  /**
   * Bắt sự kiện nhập OTP
   * @param event 
   */
  handleInputOTP(event) {
    const regex = new RegExp(/^\d+$/);
    if (regex.test(event.target.value) && event.target.value.length <= 6) {
      return;
    } else {
      this.formOTP.controls.otp.setValue(event.target.value.slice(0, -1));
    }
  }

  //Submit opt
  submitOTP() {
    if (this.limitFailed <= 0) {
      let msg = this.translate.instant('ESignOtp_confirm_otp_failed_limit');
      this.myAlert.showAlertOnlyNoti(msg, ConstantAlertType.ERROR, "", this.translate.instant('btn_close'), () => {
        this.router.navigate([ConstantUrl.CONTRACT_MANAGER]);
      });
    } else {
      this.isOTPSubmit = true;
      if (this.idSign === '') {
        this.myAlert.showAlertOnlyNoti(this.translate.instant('ESignOtp_not_found_sign_session'), ConstantAlertType.ERROR);
        return;
      }
      if (this.formOTP.valid) {
        const param = {
          otp: this.formOTP.value.otp,
          documentHash: ''
        }
  
        this.postConfirmOTPESign(this.idSign, param);
      }
    }
  }

  /**
   * Gui lai OTP
   */
  resendOTP() {
    if (this.limitResendOTP > 0) {
      this.myAlert.showAlert(`Bạn chắc chắn muốn nhận lại mã OTP khác?<br><em class="text-grey-darkest">Mã OTP cũ mà bạn nhận trước đó sẽ không còn hiệu lực.</em><br><em class="text-grey-darkest">Bạn còn ${this.limitResendOTP} lượt gửi lại mã OTP!</em>`, 
        "", true, "", "Gửi lại OTP", 
        () => {
          this.getEsignResendOTP(this.idSign);
        }, "", "Đóng");
    } else {
      this.myAlert.showAlertOnlyNoti("Bạn đã hết lượt gửi lại mã OTP! Vui lòng thử lại phiên ký mới!", ConstantAlertType.ERROR, "", localize.btn_ok, () => {
        this.router.navigate([ConstantUrl.CONTRACT_DETAIL, this.contractId], { queryParams: { action: 'SIGN' } })
        .then(() => {
          window.location.reload();
        });
      })
    }
  }

  resetTimeout() {
    this.count = 120;
    this.counter$ = timer(0, 1000).pipe(
      take(this.count),
      map(() => { --this.count; return UtilsFunc.convertToMmss(this.count); })
    );
  }

  //Confirm OTP để ký điện tử
  postConfirmOTPESign(idSign, param) {
    this.contractService.postConfirmOTPESign(idSign, param).subscribe(res => {

      // //Chuyen man hinh ky dien tu khi OTP thanh cong
      // this.selectedWayOfSign = "E_SIGN";
      // this.nextStepSign(this.currentStepSign);

      // if (this.eCertSelected.id === '') {
      //   this.getListESignature();
      // }

      // //Setup ca thong so default cho ky dien tu
      // PDFSign.setStyleSelectedSignBox(6);
      // PDFSign.removeAllSignBox();
      this.confirmOTPSuccess.emit({});
    }, err => {
      console.log(err);
      if (err.error && ((err.error.message && err.error.message === "ECT-00001203") || (err.error.code && err.error.code === "OTP_INVALID"))) {
        // this.formOTP.controls.otp.setErrors({ notTrue: true });
        this.limitFailed = this.limitFailed - 1;
        let msg = this.translate.instant('ESignOtp_confirm_otp_failed').replace("{{solan}}", this.limitFailed.toString());
        // if (this.limitFailed == 0) {
        //   msg = localize.confirm_otp_failed_limit;
        // }
        this.myAlert.showAlertOnlyNoti(msg, ConstantAlertType.ERROR, "", this.translate.instant('btn_close'), null, this.translate.instant('modal_title_noti'), {}, () => {
          if (this.limitFailed === 0) {
            // this.router.navigate([ConstantUrl.CONTRACT_MANAGER])
            this.limitFailedEvent.emit(true);
          }
        }, () => {
          if (this.limitFailed === 0) {
            // this.router.navigate([ConstantUrl.CONTRACT_MANAGER])
            this.limitFailedEvent.emit(true);
          }
        });
      } else {
        this.myAlert.showErrorHandled(err);
      }
    })
  }

  getEsignResendOTP(idSign) {
    this.signatureService.getEsignResendOTP(idSign).subscribe((res: any) => {
      this.limitResendOTP = this.limitResendOTP - 1;
      this.minusResendOTP.emit(true);
      this.resetTimeout();
      this.limitFailed = 3;
    }, err => {
      this.myAlert.showErrorHandled(err);
    });
  }

  getDecription() {
    let signForm = "";
    let res = "";
    let sdtEmail = "";

    if (this.signForm === 'OTP') {
      signForm = this.translate.instant('ESignOtp_sms_signForm') ?? "";
      sdtEmail = this.controlAccount.userInfo.sdt || this.controlAccount.partyInfo.sdt;
    } else if (this.signForm === "OTP_EMAIL") {
      signForm = this.translate.instant('ESignOtp_email_signForm') ?? "";
      sdtEmail = this.controlAccount.userInfo.email || this.controlAccount.partyInfo.email;
    }

    res = this.translate.instant('ESignOtp_otp_description').replace('${{signForm}}', signForm);
    res = res.replace('${{sdtEmail}', sdtEmail);

    return res;
  }
}
