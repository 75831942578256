import { MainResponse } from '../../../../main-response.model';
import { OrganizationManagerObject } from './organization-manager-object';

export class OrganizationManagerResponse extends MainResponse {
    object: OrganizationManagerObject;
    constructor(obj = null) {
        super();
        obj = obj || {};
        this.init(obj);
        this.object = new OrganizationManagerObject(obj.object);
    }
}