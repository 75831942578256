import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgModalComponent } from 'src/app/layout/extensions/ng-modal/ng-modal.component';
import { UsbTokenCertItem } from 'src/app/utils/models/signature/user-info/usb-token-cert-item.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { UsbTokenCertResponse } from 'src/app/utils/models/signature/user-info/usb-token-cert-item-response.model';
import { AuthService } from 'src/app/utils/services/auth.service';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { SignatureService } from 'src/app/utils/services/aas-network/signature.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { share } from 'rxjs/operators';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { CertificateItem, InfoCASignServer } from 'src/app/utils/models/aas-models/signature/info-ca-signserver/info-ca-signserver-object';
import { InfoCASignServerResponse } from 'src/app/utils/models/aas-models/signature/info-ca-signserver/info-ca-signserver-response';
import { GlobalConfig } from 'src/app/utils/common/global-config/global-config';
import { InfoSignatureConfigObject } from 'src/app/utils/models/aas-models/signature/signature-config/info-signature-config-object';
import { InfoSignatureConfigResponse } from 'src/app/utils/models/aas-models/signature/signature-config/info-signature-config-response';
import { AasValidateMessage } from 'src/app/utils/common/validate-msg-aas';
import { REGEXP_EMAIL } from 'src/app/utils/common/regexp';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { SmartCACert } from 'src/app/utils/models/aas-models/signature/smart-ca/smart-ca-cert';
import { SmartCAAuth } from 'src/app/utils/models/aas-models/signature/smart-ca/smart-ca-auth';
import { localize } from 'src/app/utils/localize/localize';
import { TranslateService } from '@ngx-translate/core';

declare var default_img: any;
@Component({
  selector: 'app-smartca-manager',
  templateUrl: './smartca-manager.component.html',
  styleUrls: ['./smartca-manager.component.scss']
})
export class SmartcaManagerComponent implements OnInit {
  @ViewChild('contractLoginSignServer') contractLoginSignServer: ElementRef;
  
  _default_img = default_img;

  spinnerR = new BehaviorSubject<boolean>(false);
  spinnerL = new BehaviorSubject<boolean>(false);
  spinnerLg = new BehaviorSubject<boolean>(false);

  userId = this.authService.userId;
  // listUsbTokenCertificate: UsbTokenCertItem[] = [];

  infoCA: InfoCASignServer = new InfoCASignServer();
  infoCABase: InfoCASignServer = new InfoCASignServer();
  listCABase: Array<CertificateItem> = [];

  selectedCert: SmartCACert = new SmartCACert();
  isFilter: boolean = false;
  configCA: InfoSignatureConfigObject = new InfoSignatureConfigObject();

  //Edit config
  editSignatureConfig: boolean = false;
  editIdSignaTure: string = '';

  totalElement: number = 0;
  maxSize: number = 5;
  page: number = 1;

  formSearch: FormGroup;
  isfilter: boolean = false;

  //Modal
  ngModalLoginSignServer: any;
  formLoginSignServer: FormGroup;
  validateMsgLoginSignServer = {
    email: {
      required: 'SmartcaManager_ts_1',
      pattern: 'SmartcaManager_ts_2'
    },
    password: {
        required: 'SmartcaManager_ts_3'
    }
  }
  isSubmitLoginSignServer: boolean = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private aasAuth: AasAuthService,
    private signatureService: SignatureService,
    private modalService: NgbModal,
    private myAlert: AlertControl,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.createFormLoginSignServer();
    this.makeFormSearch();
    // this.getInfoCASignServer(this.userId);
    this.getSessionSmartCAV2();
  }

  //MARK: Make form
  makeFormSearch() {
    this.formSearch = this.fb.group({
      search: new FormControl('')
    });
  }

  get keySearch() { return this.formSearch.get('search'); }

  //MARK: Action Search
  eventInputSearch(event) {
    // let key = event.target.value || '';
    // this.isfilter = true;
    // this.infoCA.certificateList = this.listCABase.filter(x => {
    //   return x.accountEmail.toUpperCase().match(new RegExp(key.toUpperCase(), "g"))
    // })
    let key = event.target.value;
    this.isfilter = true;
    if(key){
      this.listCert = this.listTempCert.filter(x => {
        return x.CN.toUpperCase().match(new RegExp(key.toUpperCase(), "g")) || x.serialNumber.match(new RegExp(key, "g"))
      })
    } else this.listCert = this.listTempCert

  }

  searchData(keySearch: string) {
    // let key = keySearch;
    // this.isfilter = true;
    // this.infoCA.certificateList = this.listCABase.filter(x => {
    //   return x.accountEmail.toUpperCase().match(new RegExp(key.toUpperCase(), "g"))
    // })
    let key = keySearch;
    this.isfilter = true;
    if(key){
      this.listCert = this.listTempCert.filter(x => {
        return x.CN.toUpperCase().match(new RegExp(key.toUpperCase(), "g")) || x.serialNumber.match(new RegExp(key, "g"))
      })
    } else this.listCert = this.listTempCert
  }


  loadPage(page) {

  }

  //MARK: ĐĂNG NHẬP VNPT CA
  addCASignServer() {
    this.createModalLoginSignServer();
  }

  /**
   * Khởi tạo form đăng nhập sign-server
   */
  createFormLoginSignServer() {
    this.formLoginSignServer = this.fb.group({
      email: new FormControl('', [Validators.required]),
      password: new FormControl('', [Validators.required])
    })
  }

  get email() { return this.formLoginSignServer.controls.email; } 
  get password() { return this.formLoginSignServer.controls.password; }

  /**
   * Tạo modal form login sign-server
   */
  createModalLoginSignServer() {
    this.isSubmitLoginSignServer = false;
    this.ngModalLoginSignServer = this.modalService.open(this.contractLoginSignServer, { backdrop: 'static' });
  }

  /**
   * Đóng modal form login sign-server
   */
  closeModalLoginSignServer() {
    this.ngModalLoginSignServer && this.ngModalLoginSignServer.close();
  }

  /**
   * Submit form login sign server
   */
   submitLoginCA() {
    this.isSubmitLoginSignServer = true;
    if (this.formLoginSignServer.valid) {
      this.closeModalLoginSignServer();
      const param = {
        username: this.email.value,
        password: this.password.value
      };

      this.postLoginSmartCA(param);
    }
  }

  //MARK: Xem thông tin CA
  selectCert(item: SmartCACert) {
    this.resetEditForm();

    this.selectedCert = item;
    this.smartCert = item
    this.getInfoSignatureConfig(item.id);
  }

  // MARK đăng xuất phiên chữ kí  
  logoutSignServer() {
    this.myAlert.showAlert(this.translate.instant('SmartcaManager_ts_4'), "", true, "", this.translate.instant('btn_confirm'), () => {
      this.postSignOutSmartCAV2();
    }, "", this.translate.instant('btn_cancel'));
  }

  //MARK: Chức năg cập nhật cấu hình chữ ký số
  resetEditForm() {
    this.editSignatureConfig = false;
    this.editIdSignaTure = '';
  }

  actEditSignatureConfig(certId: string) {
    console.log(certId);
    if (certId !== '') {
      this.resetEditForm();

      this.editSignatureConfig = true;
      this.editIdSignaTure = certId;
    } else {

    }
  }

  actCancelUpdate($event) {
    this.resetEditForm();
    this.getInfoSignatureConfig(this.smartCert.id);
  }

  //MARK: SmartCA

  postLoginSmartCA(param) {
    this.signatureService.postLoginSmartCAV2(param).subscribe((res: any) => {
      this.myAlert.showAlertOnlyNoti(this.translate.instant('SmartcaManager_ts_5'), ConstantAlertType.SUCCESS);
      this.closeModalLoginSignServer();
      this.getSessionSmartCAV2();
    }, err => {
      this.createFormLoginSignServer();
      this.myAlert.showErrorHandled(err);
    })
  }
  
  getCredential() {
    this.signatureService.getCredential().subscribe((res: any) => {
      console.log(res);
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }

  getCertificate() {
    this.signatureService.getCertificate().subscribe((res: any) => {
      console.log(res);
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }

  // listCert: SmartCAAuth[] = [];
  // smartCert: SmartCACert = new SmartCACert();
  // getSessionSmartCA() {
  //   this.signatureService.getSessionSmartCA().subscribe((res: any) => {
  //     console.log(res);
  //     if (res.object?.oauth !== null) {
  //       this.listCert.push(new SmartCAAuth(res.object?.oauth));
  //       this.smartCert = new SmartCACert(res.object?.certificate);
  //       this.selectCert(this.listCert[0]);
  //       this.getInfoSignatureConfig(this.smartCert.id);
  //     }
  //   }, err => {
  //     // this.myAlert.showErrorHandled(err);
  //   })
  // }

  /**
   * Lấy thông tin cấu hình chữ ký số
   * @param id 
   */
  getInfoSignatureConfig(id) {
    this.signatureService.getInfoSignatureConfig(id).subscribe(res => {
      this.configCA = new InfoSignatureConfigResponse(res).object;
    }, err => {
      console.log(err);
      this.myAlert.showErrorHandled(err);
    })
  }

  postSignOutSmartCA(username) {
    this.signatureService.postSignOutSmartCA(username).subscribe((res: any) => {
      this.getSessionSmartCAV2();
      this.listCert = [];
      this.smartCert = null;
      this.selectCert = null;
    }, err=> {
      this.myAlert.showErrorHandled(err); 
    })
  }

  //smartCA V2
  listCert: SmartCACert[] = [];
  listTempCert: SmartCACert[] = [];
  smartCert: SmartCACert = new SmartCACert();
  isLogin = false
  usernameSmartCa: any
  getSessionSmartCAV2() {
    this.listCert = []
    this.signatureService.getSessionSmartCAV2().subscribe((res: any) => {
      if (res && res.object instanceof Array && res.object.length > 0) {
        res.object.forEach(item => {
          this.listCert.push(new SmartCACert(item));
        })
        this.selectCert(this.listCert[0]);
        // if(this.listCert.length > 1){
        //   this.getDefaultCert()
        // }
        this.listTempCert = this.listCert
      }
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }

  postSignOutSmartCAV2() {
    this.signatureService.postSignOutSmartCAV2().subscribe((res: any) => {
      this.listCert = [];
      this.smartCert = null;
      this.selectedCert = null;
      // this.defaultCert = null;
      this.isLogin = false
      this.keySearch.setValue('')
      this.getSessionSmartCAV2();
    }, err=> {
      this.myAlert.showErrorHandled(err); 
    })
  }

  getSmartCAName(subjectDN){
    if(subjectDN){
      return subjectDN.split(',').find(item => item.includes('CN=')).slice(3)
    } else return ''
  }

  getSmartCAOwner(subjectDN){
    if(subjectDN){
      return subjectDN.split(',').find(item => item.includes('CN=')).slice(3)
    } else return ''
  }


}
