import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { REGEXP_PASSWORD } from 'src/app/utils/common/regexp';
import { AasValidateMessage } from 'src/app/utils/common/validate-msg-aas';
import { localize } from 'src/app/utils/localize/localize';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { AuthService } from 'src/app/utils/services/auth.service';

@Component({
  selector: 'app-renew-password',
  templateUrl: './renew-password.component.html',
  styleUrls: ['./renew-password.component.scss']
})
export class RenewPasswordComponent implements OnInit {
  
  phoneNumber = this.activedRoute.snapshot.queryParamMap.get('phoneNumber') || '';
  email = this.activedRoute.snapshot.queryParamMap.get('email') || '';
  token = this.activedRoute.snapshot.queryParamMap.get('token') || '';

  renewForm: FormGroup;
  isSubmit = false;
  loginFailed = false;
  isValid= false;
  ValidateMsg = AasValidateMessage.RENEW_PASSWORD;

  constructor(
      private router: Router,
      private activedRoute: ActivatedRoute,
      private authService: AuthService,
      private aasAuth: AasAuthService,
      // private modalService: NgbModal,
      private myAlert: AlertControl,
      private translate: TranslateService
  ) { }

  ngOnInit(): void {
      // if (!GlobalConfig.ORIGIN_SERVICE.includes(location.hostname)) {
      //     this.router.navigate(['/reset-new-password'], { queryParams: { 'email': this.email, 'token': this.token } });
      // }
      this.aasAuth.checkRenewPasswordToken(this.token).subscribe((res:any)=>{
        if(res && res.object == "VALID"){
            console.log("valid");
            
            this.isValid= true;
            this.makeRenewForm();
        }
        else {
            console.log("invalid");
            this.isValid= false;
            this.myAlert.showAlertNoClose('Link đã hết hiệu lực, Vui lòng liên hệ admin để được hỗ trợ!', ConstantAlertType.BLANK, '', 'Đóng', ()=>{
                this.router.navigate(['/home'])
            })
        }
      }, error=>{
        console.log(error);
      })
      
  }

  // ngAfterViewInit(): void {
  //     var videos = document.getElementsByTagName("video");
  //     for (var i = 0; i < videos.length; i++) {
  //         videos[i].muted = true;
  //         videos[i].play();
  //     }
  // }

  //MARK: Khởi tạo form login
  makeRenewForm() {
      this.renewForm = new FormGroup({
          password: new FormControl('', [Validators.required, Validators.pattern(REGEXP_PASSWORD)]),
          passwordConfirm: new FormControl('', Validators.required)
      });
  }

  get password() { return this.renewForm.get('password') }
  get passwordConfirm() { return this.renewForm.get('passwordConfirm') }

  //MARK: Chức năng đặt lại mật khẩu mới
  actConfirm() {
      this.isSubmit = true;
      this.checkValid();
      if (this.renewForm.valid) {
          // var param = {
          //     "emailOrPhone": this.email || this.phoneNumber || '',
          //     "password": this.password.value,
          //     "passwordConfirm": this.passwordConfirm.value
          // };

        this.postConfirmForgotPasswordV2(this.email || this.phoneNumber, this.token, this.password.value, this.passwordConfirm.value);
      }
  }

  checkValid() {
      if (this.password.value !== this.passwordConfirm.value) {
          this.passwordConfirm.setErrors({ notSame: true });
      }
  }

  get checkEdited() {
      if (this.password.value === '') return true;
      if (this.passwordConfirm.value === '') return true;
      return false;
  }

  //MARK: Trim value formControl
  clearSpace() {
      let str = `${this.password.value || ''}`;
      this.password.setValue(`${str.trim()}`);
  }

  // //MARK: Popup thông báo
  // modal(msg, callBack = () => { }) {
  //     const modalRef = this.modalService.open(NgModalComponent);
  //     modalRef.componentInstance.message = msg;
  //     modalRef.componentInstance.callback = callBack;
  // }

  // alert(msg, type, callBack = () => { }) {
  //     const modalRef = this.modalService.open(NgModalComponent);
  //     modalRef.componentInstance.message = msg;
  //     modalRef.componentInstance.typeAlert = type;
  //     modalRef.componentInstance.notification = true;
  //     modalRef.componentInstance.callback = callBack;
  // }

  //MARK: NETWORKING
  // postConfirmForgotPassword(email, phoneNumber, token, param) {
  //     this.aasAuth.postConfirmChangePassword(email, phoneNumber, token, param).subscribe(res => {
  //         console.log(res);
  //         this.alert('Đặt lại mật khẩu mới thành công!', 'success', () => {
  //             this.router.navigate([ConstantUrl.LOGIN]);
  //         })
  //     }, err => {
  //         console.log(err);
  //         this.alert(err.error.error, 'error');
  //     }, () => {

  //     })
  // }

  //MARK: NETWORKING
  postConfirmForgotPasswordV2(email, token, password, passwordConfirm) {
      this.aasAuth.postConfirmChangePasswordV2(email, token, password, passwordConfirm).subscribe(res => {
          this.myAlert.showAlert(this.translate.instant('RenewPassword_ts_1'), ConstantAlertType.BLANK, false, "", this.translate.instant('btn_login'), () => {
              this.router.navigate([ConstantUrl.LOGIN]);
            },"", "", () => {}, this.translate.instant('RenewPassword_ts_2'));
      }, err => {
          this.myAlert.showErrorHandled(err);
      }, () => {

      })
  }
}
