export class InfoPartyObject {
    partyId: string;
    partyNumber: string;
    disable: boolean;
    type: string;
    created: string;
    modified: string;
    referenceId: string;
    tenToChuc: string;
    soDkdn: string;
    ngayCapSoDkdn: string;
    noiCapSoDkdn: string;
    diaChiThanhToan: string;
    diaChiTruSo: string;
    maSoThue: string;
    soTaiKhoanGiaoDich: string;
    nganHangGiaoDich: string;
    sdt: string;
    email: string;
    thongTinKhac: string;
    userInformations: UserInformations;

    diaChiThanhToanDuong: string;
    diaChiThanhToanHuyen: string;
    diaChiThanhToanSoNha: string;
    diaChiThanhToanTinh: string;
    diaChiThanhToanXa: string;
    diaChiTruSoDuong: string;
    diaChiTruSoHuyen: string;
    diaChiTruSoHuyenId: number;
    diaChiTruSoSoNha: string;
    diaChiTruSoTinh: string;
    diaChiTruSoTinhId: number;
    diaChiTruSoXa: string;
    diaChiTruSoXaId: number;

    constructor(obj = null) {
        obj = obj || {};
        this.partyId = obj.partyId || '';
        this.partyNumber = obj.partyNumber || '';
        if (obj.disable === 'Y' || obj.disable === true) {
            this.disable = true;
        } else {
            this.disable = false;
        }

        this.type = obj.type || '';
        this.created = obj.created || '';
        this.modified = obj.modified || '';
        this.referenceId = obj.referenceId || '';
        this.tenToChuc = obj.tenToChuc || '';
        this.soDkdn = obj.soDkdn || '';
        this.ngayCapSoDkdn = obj.ngayCapSoDkdn || '';
        this.noiCapSoDkdn = obj.noiCapSoDkdn || '';
        this.diaChiThanhToan = obj.diaChiThanhToan || '';
        this.maSoThue = obj.maSoThue || '';
        this.soTaiKhoanGiaoDich = obj.soTaiKhoanGiaoDich || '';
        this.nganHangGiaoDich = obj.nganHangGiaoDich || '';
        this.sdt = obj.sdt || '';
        this.email = obj.email || '';
        this.thongTinKhac = obj.thongTinKhac || '';
        this.userInformations = new UserInformations(obj.userInformations || {});
        // if (obj.userInformations && obj.userInformations instanceof Array) {
        //     obj.userInformations.forEach((item) => {
        //         this.userInformations.push(new UserInformationsItem(item));
        //     });
        // }
        this.diaChiThanhToanDuong = obj.diaChiThanhToanDuong || '';
        this.diaChiThanhToanHuyen = obj.diaChiThanhToanHuyen || '';
        this.diaChiThanhToanSoNha = obj.diaChiThanhToanSoNha || '';
        this.diaChiThanhToanTinh = obj.diaChiThanhToanTinh || '';
        this.diaChiThanhToanXa = obj.diaChiThanhToanXa || '';
        this.diaChiTruSoDuong = obj.diaChiTruSoDuong || '';
        this.diaChiTruSoHuyen = obj.diaChiTruSoHuyen || '';
        this.diaChiTruSoHuyenId = obj.diaChiTruSoHuyenId || null;
        this.diaChiTruSoSoNha = obj.diaChiTruSoSoNha || '';
        this.diaChiTruSoTinh = obj.diaChiTruSoTinh || '';
        this.diaChiTruSoTinhId = obj.diaChiTruSoTinhId || '';
        this.diaChiTruSoXa = obj.diaChiTruSoXa || '';
        this.diaChiTruSoXaId = obj.diaChiTruSoXaId || null;
        this.diaChiTruSo = obj.diaChiTruSo || this._diaChiTruSo || '';
    }

    get _diaChiTruSo() {
        var diaChi = '';
        if (this.diaChiTruSoSoNha !== '') {
            diaChi += this.diaChiTruSoSoNha + ' ';
        }

        if (this.diaChiTruSoDuong !== '') {
            diaChi += this.diaChiTruSoDuong + ', ';
        }

        if (this.diaChiTruSoXa !== '') {
            diaChi += this.diaChiTruSoXa + ', ';
        }

        if (this.diaChiTruSoHuyen !== '') {
            diaChi += this.diaChiTruSoHuyen + ', ';
        }

        if (this.diaChiTruSoTinh !== '') {
            diaChi += this.diaChiTruSoTinh + '.';
        }

        return diaChi;
    }
}

export class UserInformations {
    userId: string;
    disable: string;
    daiDien: boolean;
    created: string;
    modified: string;
    ten: string;
    chucVu: string;
    cmnd: string;
    ngayCap: string;
    noiCap: string;
    ngaySinh: string;
    gioiTinh: string;
    gioiTinhId: number;
    soNha: string;
    duong: string;
    thanhPho: string;
    diaChiThuongTru: string;
    email: string;
    sdt: string;
    partyId: string;
    tinh: string;
    tinhId: number;
    huyen: string;
    huyenId: number;
    xa: string;
    xaId: number;

    constructor(obj = null) {
        obj = obj || {};
        this.userId = obj.userId || '';
        this.disable = obj.disable || false;
        this.daiDien = obj.daiDien || '';
        this.created = obj.created || '';
        this.modified = obj.modified || '';
        this.ten = obj.ten || '';
        this.chucVu = obj.chucVu || '';
        this.cmnd = obj.cmnd || '';
        this.ngayCap = obj.ngayCap || '';
        this.noiCap = obj.noiCap || '';
        this.ngaySinh = obj.ngaySinh || '';
        this.gioiTinh = obj.gioiTinh || '';
        this.gioiTinhId = Number(obj.gioiTinhId) || null;
        this.soNha = obj.soNha || '';
        this.duong = obj.duong || '';
        this.thanhPho = obj.thanhPho || '';
        this.email = obj.email || '';
        this.sdt = obj.sdt || '';
        this.partyId = obj.partyId || '';
        this.tinhId = Number(obj.tinhId) || null;
        this.tinh = obj.tinh || '';
        this.huyen = obj.huyen || '';
        this.huyenId = Number(obj.huyenId) || null;
        this.xa = obj.xa || '';
        this.xaId = Number(obj.xaId) || null;

        this.diaChiThuongTru = obj.diaChiThuongTru || this.diaChi || '';
    }

    get diaChi() {
        var diaChi = '';
        if (this.soNha !== '') {
            diaChi += this.soNha + ' ';
        }

        if (this.duong !== '') {
            diaChi += this.duong + ', ';
        }

        if (this.xa !== '') {
            diaChi += this.xa + ', ';
        }

        if (this.huyen !== '') {
            diaChi += this.huyen + ', ';
        }

        if (this.tinh !== '') {
            diaChi += this.tinh + '.';
        }

        return diaChi;
    }
}