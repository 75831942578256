<header id="myheader">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <nav class="navbar navbar-expand-lg navbar-lightrounded">
                        <a class="navbar-brand" href="#">
                            <div class="logo">
                                <img src="assets/img/home/v4/img/LOGO.png">
                            </div>
                        </a>
                        <button class="navbar-toggler collapsed" type="button" data-toggle="collapse"
                            data-target="#navbarsExample09" aria-controls="navbarsExample09" aria-expanded="false"
                            aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-justify"
                                    viewBox="0 0 16 16" id="justify">
                                    <path fill-rule="evenodd"
                                        d="M2 12.5a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5zm0-3a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5zm0-3a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5zm0-3a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5z">
                                    </path>
                                </svg>
                            </span>
                        </button>
                        <div class="menu-btn">
                            <img src="assets/img/home/v4/icon/close.png" alt="">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-justify"
                                viewBox="0 0 16 16" id="justify">
                                <path fill-rule="evenodd"
                                    d="M2 12.5a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5zm0-3a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5zm0-3a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5zm0-3a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5z">
                                </path>
                            </svg>
                        </div>
                        <div class="navbar-collapse collapse pushy pushy-right" id="navbarsExample09">
                            <ul class="navbar-nav mr-auto" id="menu_portal">
                                <li [ngClass]="{'active': homeMode == 0}" class="nav-item active pushy-link">
                                    <a [ngClass]="{'active': homeMode == 0}" class="nav-link active"
                                        [routerLink]='"."' (click)="changeMode(0)">{{ 'home' | translate }}</a>
                                </li>
                                <li [ngClass]="{'active': homeMode == 4}" class="nav-item pushy-link">
                                    <a [ngClass]="{'active': homeMode == 4}" class="nav-link" [routerLink]='"."'
                                        (click)="changeMode(4);"
                                        [fragment]="'feature'">{{ 'features' | translate }}</a>
                                </li>
                                <li [ngClass]="{'active': homeMode == 2}" class="nav-item pushy-link">
                                    <a [ngClass]="{'active': homeMode == 2}" class="nav-link" [routerLink]='"."'
                                        (click)="changeMode(2);"
                                        [fragment]="'pricelist'">{{ 'price_list' | translate }}</a>
                                </li>
                                <li [ngClass]="{'active': homeMode == 3 || homeMode == 5}" class="nav-item pushy-link">
                                    <!-- <a class="nav-link" routerLink="/contact">Hỗ trợ</a> -->
                                    <a [ngClass]="{'active': homeMode == 3 || homeMode == 5}" class="nav-link" [routerLink]='"."'
                                        (click)="changeMode(3);"
                                        [fragment]="'support'">{{ 'support' | translate }}</a>
                                </li>
                                <li [ngClass]="{'active': homeMode == 1}" class="nav-item pushy-link">
                                    <!-- <a class="nav-link" >Liên hệ</a> -->
                                    <a [ngClass]="{'active': homeMode == 1}" class="nav-link" [routerLink]='"."'
                                        (click)="changeMode(1);"
                                        [fragment]="'contact'">{{ 'contact' | translate }}</a>
                                </li>
                            </ul>
                            <div class="login">
                                <span class="selectlanguage  selectlanguagemobi">
                                    <img *ngIf="translate.currentLang === 'vi'"
                                        src=assets/img/home/v4/Image/image 119.png" alt="" width="26"
                                        height="24">
                                    <img *ngIf="translate.currentLang === 'en'"
                                        src="assets/img/home/v4/Image/icon-en.png" alt="" width="26"
                                        height="24">
                                    <span *ngIf="translate.currentLang === 'vi'"> Việt Nam</span>
                                    <span *ngIf="translate.currentLang === 'en'"> English</span>
                                    <img src="assets/img/home/v4/icon/showmenu.png" alt=""
                                        class="iconshow">

                                    <ul class="mutilanguage">
                                        <li
                                            (click)="changeLanguage('vi');">
                                            <img src="assets/img/home/v4/Image/image 119.png" width="26"
                                                height="24" alt=""> Việt Nam</li>
                                        <li
                                            (click)="changeLanguage('en');">
                                            <img src="assets/img/home/v4/Image/icon-en.png" width="26"
                                                height="24" alt=""> English</li>
                                    </ul>
                                </span>
                                <a routerLink="/app/login" class="btn_login">{{ 'signin' | translate }}</a>
                                <a routerLink="/app/signup" class="btn_reg">{{ 'signup_trial' | translate }}</a>
                                <span class="selectlanguage d-none-mobi991">
                                    <img *ngIf="translate.currentLang === 'vi'"
                                        src="assets/img/home/v4/Image/image 119.png" alt="" width="26"
                                        height="24">
                                    <img *ngIf="translate.currentLang === 'en'"
                                        src="assets/img/home/v4/Image/icon-en.png" alt="" width="26"
                                        height="24">
                                    <img src="assets/img/home/v4/icon/showmenu.png" alt="">
                                    <ul class="mutilanguage">
                                        <li
                                            (click)="changeLanguage('vi');">
                                            <img src="assets/img/home/v4/Image/image 119.png" width="26"
                                                height="24" alt=""> Việt Nam</li>
                                        <li
                                            (click)="changeLanguage('en');">
                                            <img src="assets/img/home/v4/Image/icon-en.png" width="26"
                                                height="24" alt=""> English</li>
                                    </ul>
                                </span>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</header>