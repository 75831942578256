import { MainResponse } from '../../../main-response.model';
import { StatusItem } from './payment-status-object.model';


export class ListStatusResponse extends MainResponse {
    data: StatusItem[];
 

    constructor(obj = null) {
        super();
        obj = obj || {};
        this.data = [];
        if (obj.object && obj.object instanceof Array) {
            obj.object.forEach((item) => {
                this.data.push(new StatusItem(item));
            });
        }
    }

}