import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { ConstantAlertBtn, ConstantAlertMsg } from 'src/app/utils/common/constant-alert-msg';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { ApiListAllItem } from 'src/app/utils/models/aas-models/api-manager/api-list-all/api-list-all-item.model';
import { ApiListAllResponse } from 'src/app/utils/models/aas-models/api-manager/api-list-all/api-list-all-response.model';
import { ApiManagerService } from 'src/app/utils/services/aas-network/api-manager.service';
import { AuthService } from 'src/app/utils/services/auth.service';

const METHOD_POST = 'POST';
const METHOD_GET = 'GET';
const METHOD_PUT = 'PUT';
const METHOD_DELETE = 'DELETE';

@Component({
  selector: 'app-service-api-list',
  templateUrl: './service-api-list.component.html',
  styleUrls: ['./service-api-list.component.scss']
})
export class ServiceApiListComponent implements OnInit {
  constantUrl = ConstantUrl;
  userId: string = '';
  searchForm: FormGroup;

  page: number = 1;
  maxSize: number = 10;
  propertiesSort: string = 'created';
  sort: string = 'DESC';
  totalElement: number = 0;

  apis: ApiListAllItem[] = [];

  constructor(
    private fb: FormBuilder,
    private apiService: ApiManagerService,
    private authService: AuthService,
    private router: Router,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.userId = this.authService.userId;
    this.makeSearchForm();

    this.loadPage(this.page);
  }

  /**
   * Khoi tao form search
   */
  makeSearchForm() {
    this.searchForm = this.fb.group({
      searchString: ['']
    });
  }

  /**
   * Tim kiem
   * @param event 
   */
  submitSearch(event) {
    this.page = 1;
    this.loadPage(this.page);
  }

  /**
   * Nut them moi api
   */
  addApi() {
    this.router.navigate([ConstantUrl.ADMIN_API_CREATE]);
  }

  /**
   * Xoa api
   * @param id 
   */
  actionDeleteApi(id) {
    console.log(id);
    this.alert(ConstantAlertMsg.DELETE_API_CONFIRM, '', true, '', ConstantAlertBtn.CONFIRM, () => {
      this.deleteApi(id);
    }, '', ConstantAlertBtn.CANCEL, () => {});
  }

  /**
   * Tai trang danh sach api
   * @param page 
   */
  loadPage(page) {
    this.page = page;
    this.getListAllApi(this.userId, page, this.maxSize, this.propertiesSort, this.sort, this.searchForm.value.searchString);
  }

  /**
   * Tra ve style cho span method
   * @param method 
   */
  getStyleBadge(method): string {
    switch(method) {
      case METHOD_POST: 
        return 'badge-success-new';
      case METHOD_GET:
        return 'badge-primary-new';
      case METHOD_PUT:
        return 'badge-warning-new';
      case METHOD_DELETE:
        return 'badge-danger-new';
    }
  }

  //MARK: NETWORK
  /**
   * Lay danh sach api
   * @param page 
   * @param maxSize 
   * @param searchString 
   */
  getListAllApi(userId, page = 1, maxSize = 10, propertiesSort: string = 'created', sort: string = 'DESC', searchString = '') {
    this.apiService.getListAllApi(userId, page, maxSize, propertiesSort, sort, searchString).subscribe((res: any) => {
      console.log(res);
      let obj = new ApiListAllResponse(res);
      this.apis = obj.object.data;
      this.totalElement = obj.object.totalElement;
    }, err => {
      this.handleError(err, ResponseMsg.MESSAGES.ALL_IN_ONE);
    })
  }

  /**
   * Xoa thong tin api
   * @param id 
   */
  deleteApi(id) {
    this.apiService.deleteApi(id).subscribe((res: any) => {
      this.loadPage(this.page);
      this.alert(ConstantAlertMsg.DELETE_API_SUCCESS, ConstantAlertType.SUCCESS);
    }, err => {
      this.handleError(err, ResponseMsg.MESSAGES.ALL_IN_ONE);
    })
  }

  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], ConstantAlertType.ERROR);
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, ConstantAlertType.ERROR);
    } else {
      this.alert(ConstantAlertMsg.DEFAULT_ERROR_REQUEST, ConstantAlertType.ERROR);
    }
  }
}
