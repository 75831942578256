import { SignBox } from "./sign-box";

declare var PDFSign: any;
export class UserSignBox {
    partyId: string = "";
    userId: string = "";
    documentId: string = "";
    email: string = "";
    partyName: string = "";
    fullName: string = "";
    signBoxes: SignBox[] = [];

    showSignBoxes = false;
    sequence = null;
    sdt: string = "";

    constructor(d = null) {
        d = d || {};
        this.partyId = d.partyId || "";
        this.userId = d.userId || "";
        this.documentId = d.documentId || "";
        this.signBoxes = [];
        if (d.signBoxes && d.signBoxes instanceof Array) {
            /** Nếu có trường signBoxes và có type là Array */
            d.signBoxes.forEach((x) => {
                this.signBoxes.push(new SignBox(x));
            })
        }
        this.partyName = d.partyName || "";
        this.fullName = d.fullName || "";
        this.email = d.email || "";
        this.sequence = d.sequence || null;
        this.sdt = d.sdt || "";
    }
    /**
     * Ẩn danh sách khung ký
     */
    hideListSignBox() {
        this.showSignBoxes = false;
        this.hideAllSignBox();
    }
    /**
     * Hiển thị danh sách khung ký
     */
    showListSignBox() {
        this.showSignBoxes = true;
        this.showAllSignBox();
    }
    /**
     * thêm khung ký
     */
    addSignBox(box: any = null) {
        let signBox = new SignBox();
        PDFSign.addTemplateSignBox(signBox.changedSizeSignBox.bind(signBox), box);
        let listSignBox = PDFSign.getListSignBox();
        signBox.id = listSignBox[listSignBox.length - 1].id;
        signBox.x = listSignBox[listSignBox.length - 1].x;
        signBox.y = listSignBox[listSignBox.length - 1].y;
        signBox.w = listSignBox[listSignBox.length - 1].w;
        signBox.h = listSignBox[listSignBox.length - 1].h;
        signBox.page = Number(listSignBox[listSignBox.length - 1].page);
        this.signBoxes.push(signBox);
    }
    /**
     * Xóa khung kí
     * @param index 
     */
    removeSignBox(index: any) {
        let idSignBox = this.signBoxes[index].id;
        PDFSign.deleteTemplateSignBox(idSignBox);
        this.signBoxes.splice(index, 1);
        if (this.signBoxes.length === 0) {
            this.hideListSignBox();
        }
    }

    /**
     * Đồng bộ danh sách từ pdfsign plugin và dữ liệu trên site
     */
    syncListSignBox() {
        let listSignBox = PDFSign.getListSignBox();
        this.signBoxes = [];
        if (listSignBox && listSignBox instanceof Array) {
            listSignBox.forEach((item: any) => {
                this.signBoxes.push(new SignBox({
                    x: item.x,
                    y: item.y,
                    w: item.w,
                    h: item.h,
                    page: item.page,
                    id: item.id
                }))
            })
        }
    }

    /**
     * Ẩn tất cả khung chứ ký
     */
    hideAllSignBox() {
        PDFSign.removeAllSignBox();
    }

    showAllSignBox() {
        console.log(this.signBoxes);
        this.signBoxes.forEach((item: SignBox) => {
            PDFSign.addTemplateSignBox(item.changedSizeSignBox.bind(item), item);
        })
    }
}