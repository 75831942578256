<app-spinner></app-spinner>
<form [formGroup]="formAddCategory" (ngSubmit)="onSubmit()">
  <section class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6">
          <div class="card ec-card shadow-none">
            <div class="card-header">
              <div class="d-flex">
                <div class="card-title-path flex-grow-1">
                  <h1 class="m-0 text-dark card-title"><label>Thêm mới danh mục tin bài</label></h1>
                </div>
                <div class="card-btn-path ml-auto text-right">
                  <a [routerLink]="['/console/landing-page/category']" routerLinkActive="router-link-active"
                    class="btn btn-outline-primary d-inline-flex mr-3">
                    <span class="material-icons mr-1">close</span>
                    Hủy
                  </a>
                  <button class="btn btn-primary d-inline-flex" type="submit"><span
                      class="material-icons mr-1">save</span>Lưu</button>
                </div>
              </div>
            </div>

            <div class="card-body">
              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label for="">Tên danh mục<span class="text-danger">*</span>:</label>
                    <input type="text" class="form-control" formControlName="categoryName"
                      placeholder="Nhập tên danh mục">
                    <app-show-validate-errors [useSubmit]="false" [isSubmit]="isSubmit"
                      [errorMessages]="validCategoryMsg.categoryName" [control]="formAddCategory.controls.categoryName"
                      [detail]="{ name: 'categoryName' }">
                    </app-show-validate-errors>
                  </div>
                  <div class="form-row">
                    <div class="form-group col-12 col-md-6">
                      <label for="">Danh mục cấp trên</label>
                      <select class="form-control" formControlName="parentId">
                        <option [ngValue]="''" disabled>Chọn danh mục cấp trên</option>
                        <option *ngFor="let item of categories" [ngValue]="item.id">{{item.categoryName}}</option>
                      </select>
                    </div>
                    <div class="form-group col-12 col-md-6">
                      <label for="">Ngôn ngữ<span class="text-danger">*</span>:</label>
                      <select class="form-control" formControlName="language">
                        <option [ngValue]="''" disabled>Chọn ngôn ngữ</option>
                        <option *ngFor="let item of languages" [value]="item.key">{{item.value}}</option>
                      </select>
                      <app-show-validate-errors [useSubmit]="false" [isSubmit]="isSubmit"
                        [errorMessages]="validCategoryMsg.language" [control]="formAddCategory.controls.language"
                        [detail]="{ name: 'language' }">
                      </app-show-validate-errors>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="">Mã định danh:</label>
                    <input type="text" class="form-control" formControlName="categoryCode" placeholder="Mã định danh">
                    <app-show-validate-errors [useSubmit]="false" [isSubmit]="isSubmit"
                      [errorMessages]="validCategoryMsg.categoryCode" [control]="formAddCategory.controls.categoryCode"
                      [detail]="{ name: 'categoryCode' }">
                    </app-show-validate-errors>
                    <p class="text-muted">
                      Mã định danh dùng để nhóm các danh mục lại với nhau. Nếu danh mục cấp trên đã có mã định danh, vui
                      lòng không nhập mã định danh cho danh mục con.
                    </p>
                  </div>
                  <div class="form-group">
                    <label for="">Mô tả danh mục:</label>
                    <textarea type="text" class="form-control" formControlName="description"
                      placeholder="Mô tả chi tiết danh mục" rows="5"></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</form>