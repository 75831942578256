import { MainComponent } from 'src/app/layout/main/main.component';
import { PersonalDetailObject } from './personal-detail-object';
import { MainResponse } from '../../../main-response.model';

export class PersonalDetailResponse extends MainResponse {
    object: PersonalDetailObject;

    constructor(obj = null) {
        super();
        obj = obj || {};
        this.init(obj);
        this.object = new PersonalDetailObject(obj.object);
    }
}