<div class="card shadow-none">
    <div class="card-body position-relative">
        <div class="row">
            <div class="col-md-auto align-self-end">
                <label class="text-lg">{{ 'SmartcaConfig_title' | translate }}</label>
            </div>
            <div class="col-md-auto ml-auto">
            </div>
        </div>
        <hr>

        <div class="row">
            <form [formGroup]="formUpdateConfig" class="col-md-12 row">
                <div class="col-md-6">
                    <div class="infor-box-header d-flex">
                        <label class="text-primary flex-grow-1 mb-0">{{ 'SmartcaManager_label_17' | translate }}</label>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <div class="ccheck-primary w-100 d-inline-block">
                                <input formControlName="signDate" type="checkbox" id="signDate">
                                <label for="signDate" class="w-100">
                                    {{ 'SmartcaManager_label_19' | translate }}
                                </label>
                            </div>
                            <div class="ccheck-primary w-100 d-inline-block">
                                <input formControlName="signBy" type="checkbox" id="signBy">
                                <label for="signBy" class="w-100">
                                    {{ 'SmartcaManager_label_20' | translate }}
                                </label>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12 align-items-end d-flex">
                            <div class="form-group w-100">
                                <label>{{ 'ContractCensorship_label_19' | translate }}:</label>
                                <input formControlName="fontSize" (input)="inputFontSize($event)" type="text"
                                    class="form-control" placeholder="{{ 'ContractCensorship_ph_1' | translate }}">
                                <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="validMsg.fontSize"
                                    [control]="formUpdateConfig.controls.fontSize" [detail]="{ name: 'fontSize' }">
                                </app-show-validate-errors>
                            </div>
                        </div>
                    </div>

                    <div class="infor-box-header d-flex mt-3">
                        <label class="text-primary flex-grow-1 mb-0">{{ 'ContractCensorship_label_20' | translate }}</label>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-12">
                            <label for="signType">{{ 'ContractCensorship_label_21' | translate }}:</label>
                            <select #visibleType name="visibleType" id="visibleType" class="form-control"
                                formControlName="visibleType">
                                <option value="" disabled>{{ 'ContractCensorship_label_21' | translate }}</option>
                                <option *ngFor="let opt of options" [ngValue]="opt.value">
                                    {{ opt.name | translate }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="infor-box-header">
                        <label class="text-primary flex-grow-1 mb-0">{{ 'ContractCensorship_label_22' | translate }}</label>
                    </div>
                    <hr>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>{{ 'ContractCensorship_label_23' | translate }}:</label>
                                <div class="input-group mb-1">
                                    <div class="custom-file custom-file-icon">
                                        <span class="icon-file material-icons">attach_file</span>
                                        <label class="custom-file-label"
                                            for="inputGroupFile01">{{ inputFileLabel | translate }}</label>
                                        <input formControlName="imageFile" (input)="handleFileInput($event)" type="file"
                                            accept="image/*" class="custom-file-input" id="inputGroupFile01"
                                            aria-describedby="inputGroupFileAddon01">
                                    </div>
                                </div>
                                <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="validMsg.imageFile"
                                    [control]="formUpdateConfig.controls.imageFile" [detail]="{ name: 'imageFile' }">
                                </app-show-validate-errors>
                            </div>
                            <img *ngIf="imgContent" class="w-100" style="max-width: 200px;" [src]="imgContent">
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <app-mini-spinner *ngIf="spinnerRShow() | async"></app-mini-spinner>
    </div>
</div>
<div class="d-flex justify-content-end mb-2">
    <button class="btn btn-outline-primary d-inline-flex mr-4" (click)="actCancel()"><span
            class="material-icons mr-2">cancel</span>{{ 'btn_cancel' | translate }}</button>
    <button class="btn btn-primary d-inline-flex" (click)="submit()"><span class="material-icons mr-2">save</span>{{ 'ServiceSignatureConfig_label_1' | translate }}</button>
</div>