import { TrustedPartnerDetailComponent } from './service-pages/service-console/service-enduser-console/partner/trusted-partner-detail/trusted-partner-detail.component';
import { ServiceSignupBusinessVipStepComponent } from './service-pages/service-auth/service-register/service-signup-business-vip-step/service-signup-business-vip-step.component'; import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// document Manager
import { ServiceProfileManagerComponent } from './service-pages/service-console/service-enduser-console/profile-manager/service-profile-manager/service-profile-manager.component';
import { ServiceLoginComponent } from './service-pages/service-auth/service-login/service-login.component';
import { ServiceDashboardComponent } from './service-pages/service-console/service-dashboard/service-dashboard.component';
import { ServiceSignupComponent } from './service-pages/service-auth/service-register/service-signup/service-signup.component';
import { ServiceSignupBusinessComponent } from './service-pages/service-auth/service-register/service-signup-business/service-signup-business.component';
import { ppid } from 'process';
import { ServiceForgotPasswordComponent } from './service-pages/service-auth/service-forgot-password/service-forgot-password.component';
import { ServiceRequestOtpComponent } from './service-pages/service-auth/service-request-otp/service-request-otp.component';
import { ServiceRenewPasswordComponent } from 'src/app/service-pages/service-auth/service-forgot-password/service-renew-password/service-renew-password.component';
import { AasNonAuthGuard } from './utils/guards/aas-non-auth.guard';
import { AasAuthGuard } from './utils/guards/aas-auth.guard';
import { ServiceSignupSuccessComponent } from './service-pages/service-auth/service-register/service-signup-success/service-signup-success.component';
import { ServiceRegisterManagerComponent } from 'src/app/service-pages/service-console/service-admin-console/service-register-manager/service-register-manager.component';
import { from } from 'rxjs';
import { ServiveVerifyBusinessComponent } from './service-pages/service-console/service-enduser-console/profile-manager/servive-verify-business/servive-verify-business.component';
import { ServiceSignatureManagerComponent } from './service-pages/service-console/service-enduser-console/signature-manager/service-signature-manager/service-signature-manager.component';
import { ServiceRedirectVnptcaLoginComponent } from './service-pages/service-console/service-enduser-console/signature-manager/service-signature-manager/service-redirect-vnptca-login/service-redirect-vnptca-login.component';


// Organi Manager
import { EnterpriseManagerComponent } from 'src/app/service-pages/service-console/service-admin-console/enterprise-manager/enterprise-manager.component';
import { ServiceAccountManagerComponent } from 'src/app/service-pages/service-console/service-admin-console/service-account-manager/service-account-manager.component';
import { ServiceAccountDetailComponent } from 'src/app/service-pages/service-console/service-admin-console/service-account-manager/service-account-detail/service-account-detail.component';
import { ServiceContractManagerComponent } from './service-pages/service-console/service-enduser-console/contract-manager/service-contract-manager/service-contract-manager.component';
import { ServiceAccountEditComponent } from 'src/app/service-pages/service-console/service-admin-console/service-account-manager/service-account-edit/service-account-edit.component';
import { ServiceOrganizationDetailComponent } from 'src/app/service-pages/service-console/service-admin-console/service-account-manager/service-organization/service-organization-detail/service-organization-detail.component';
import { ServiceOrganizationUpdateComponent } from 'src/app/service-pages/service-console/service-admin-console/service-account-manager/service-organization/service-organization-update/service-organization-update.component';

// report analytics
import { HomeComponentV3 } from './service-pages/home/home-v3.component';
import { ContactComponent } from './service-pages/home/contact/contact.component';
import { VideoCallComponent } from './service-pages/service-console/service-admin-console/service-register-manager/video-call/video-call.component';
import { Web2webVideocallComponent } from './service-pages/service-console/service-admin-console/enterprise-manager/web2web-videocall/web2web-videocall.component';
import { LandingMainComponent } from './layout/landing-main/landing-main.component';
import { AasMainComponent } from './layout/aas-main/aas-main.component';
import { ServiceContractDetailComponent } from './service-pages/service-console/service-enduser-console/contract-manager/service-contract-detail/service-contract-detail.component';

import { ServiceContractComponent } from './service-pages/service-console/service-enduser-console/contract-manager/service-contract/service-contract.component';
import { ServiceVerifyBusinessVipComponent } from './service-pages/service-console/service-enduser-console/service-verify-business-vip/service-verify-business-vip.component';
import { ServiceEnterpriseVipManagerComponent } from './service-pages/service-console/service-admin-console/service-enterprise-vip-manager/service-enterprise-vip-manager.component';

// einvoices manager 
import { ServiceEinvoicesListComponent } from './service-pages/service-console/service-enduser-console/einvoices-manager/service-einvoices-list/service-einvoices-list.component'
import { ServiceEnvoicesDetailComponent } from './service-pages/service-console/service-enduser-console/einvoices-manager/service-envoices-detail/service-envoices-detail.component'
import { ServiceEnvoicesCreateComponent } from './service-pages/service-console/service-enduser-console/einvoices-manager/service-envoices-create/service-envoices-create.component'
import { AccoutEinvoicesListComponent } from './service-pages/service-console/service-enduser-console/einvoices-manager/accout-einvoices-list/accout-einvoices-list.component'
import { AccountEinvoicesCreateComponent } from './service-pages/service-console/service-enduser-console/einvoices-manager/account-einvoices-create/account-einvoices-create.component'
import { AccountEnvoicesDetailComponent } from './service-pages/service-console/service-enduser-console/einvoices-manager/account-envoices-detail/account-envoices-detail.component'
import { AccountEcvoicesUpdateComponent } from './service-pages/service-console/service-enduser-console/einvoices-manager/account-ecvoices-update/account-ecvoices-update.component'

import { ServiceApiManagerComponent } from './service-pages/service-console/service-enduser-console/api-manager/service-api-manager/service-api-manager.component';
import { ServiceApiListComponent } from './service-pages/service-console/service-admin-console/service-api-manager/service-api-list/service-api-list.component';
import { ServiceApiEditorComponent } from './service-pages/service-console/service-admin-console/service-api-manager/service-api-editor/service-api-editor.component';
import { ServiceApiDetailComponent } from './service-pages/service-console/service-admin-console/service-api-manager/service-api-detail/service-api-detail.component';
import { WrongPageComponent } from './service-pages/wrong-page/wrong-page.component';
import { ServiceSignupV2Component } from './service-pages/service-auth/service-register/service-signup-v2/service-signup-v2.component';
import { ServiceProductsManagerComponent } from './service-pages/service-console/service-enduser-console/products-manager/service-products-manager/service-products-manager.component';
import { EnduserPackagesManagerComponent } from './service-pages/service-console/service-enduser-console/packages-manager/enduser-packages-manager/enduser-packages-manager.component';
import { EnduserPackageDetailComponent } from './service-pages/service-console/service-enduser-console/packages-manager/enduser-package-detail/enduser-package-detail.component';

// package
import { ServicePackageListComponent } from './service-pages/service-console/service-admin-console/service-package-manager/service-package-list/service-package-list.component'
import { CreateServicePakageComponent } from './service-pages/service-console/service-admin-console/service-package-manager/create-service-pakage/create-service-pakage.component'
import { ServicePackageDetailComponent } from './service-pages/service-console/service-admin-console/service-package-manager/service-package-detail/service-package-detail.component'
import { UpdateServicePackageComponent } from './service-pages/service-console/service-admin-console/service-package-manager/update-service-package/update-service-package.component'
import { ServiceContractTemplateGroupComponent } from './service-pages/service-console/service-admin-console/service-package-manager/service-contract-template-group/service-contract-template-group.component'

import { ListCategoryPackageComponent } from './service-pages/service-console/service-admin-console/service-package-manager/service-category-package/list-category-package/list-category-package.component';
import { CreateCategoryPackageComponent } from './service-pages/service-console/service-admin-console/service-package-manager/service-category-package/create-category-package/create-category-package.component';
import { DetailCategoryPackageComponent } from './service-pages/service-console/service-admin-console/service-package-manager/service-category-package/detail-category-package/detail-category-package.component';
import { UpdateCategoryPackageComponent } from './service-pages/service-console/service-admin-console/service-package-manager/service-category-package/update-category-package/update-category-package.component';

// license
import { CopyrightListComponent } from './service-pages/service-console/service-admin-console/service-copyright-manager/copyright-list/copyright-list.component'
import { CopyrightDetailComponent } from './service-pages/service-console/service-admin-console/service-copyright-manager/copyright-detail/copyright-detail.component'
import { DocumentsManagerComponent } from './service-pages/service-console/service-enduser-console/documents-manager/documents-manager.component';
import { ServiceDashboardEnduserComponent } from './service-pages/service-console/service-enduser-console/dashboard/service-dashboard-enduser/service-dashboard-enduser.component';
import { ConsumerPartnerVerificationComponent } from './service-pages/service-console/service-enduser-console/partner/consumer-partner-verification/consumer-partner-verification.component';
import { PartyPartnerVerificationComponent } from './service-pages/service-console/service-enduser-console/partner/party-partner-verification/party-partner-verification.component';
import { TrustedPartnerManagerComponent } from './service-pages/service-console/service-enduser-console/partner/trusted-partner-manager/trusted-partner-manager.component';

// quantity report
import { ReportDetailContractComponent } from './service-pages/service-console/service-admin-console/service-quantity-report/report-detail-contract/report-detail-contract.component';
import { ReportContractComponent } from './service-pages/service-console/service-admin-console/service-quantity-report/report-contract/report-contract.component'
import { ReportServiceComponent } from './service-pages/service-console/service-admin-console/service-quantity-report/report-service/report-service.component';
import { ReportPackageComponent } from './service-pages/service-console/service-admin-console/service-quantity-report/report-package/report-package.component';
import { ApiKeyManagerComponent } from './service-pages/service-console/service-admin-console/service-api-key-manager/api-key-manager/api-key-manager.component';
import { ApiKeyDetailComponent } from './service-pages/service-console/service-admin-console/service-api-key-manager/api-key-detail/api-key-detail.component';
import { ChatManagerComponent } from './service-pages/service-console/service-enduser-console/chat-manager/chat-manager.component';
import { ServicePackageConversionListComponent } from './service-pages/service-console/service-admin-console/service-package-manager/service-package-conversion-list/service-package-conversion-list.component';
import { AdminContractManagerComponent } from './service-pages/service-console/service-admin-console/admin-contract/admin-contract-manager/admin-contract-manager.component';
import { AdminContractComponent } from './service-pages/service-console/service-admin-console/admin-contract/admin-contract/admin-contract.component';
import { AdminContractDetailComponent } from './service-pages/service-console/service-admin-console/admin-contract/admin-contract-detail/admin-contract-detail.component';
import { PartListComponent } from './service-pages/service-console/service-enduser-console/part-manager/part-list/part-list.component';
import { PartAddComponent } from './service-pages/service-console/service-enduser-console/part-manager/part-add/part-add.component';
import { PartEditComponent } from './service-pages/service-console/service-enduser-console/part-manager/part-edit/part-edit.component';
import { PartDetailComponent } from './service-pages/service-console/service-enduser-console/part-manager/part-detail/part-detail.component';
import { EmployeeListComponent } from './service-pages/service-console/service-enduser-console/employee/employee-list/employee-list.component';
import { EmployeeAddComponent } from './service-pages/service-console/service-enduser-console/employee/employee-add/employee-add.component';
import { EmployeeEditComponent } from './service-pages/service-console/service-enduser-console/employee/employee-edit/employee-edit.component';
import { EmployeeDetailComponent } from './service-pages/service-console/service-enduser-console/employee/employee-detail/employee-detail.component';
import { EmployeeFinishSignupComponent } from './service-pages/service-console/service-enduser-console/employee/employee-finish-signup/employee-finish-signup.component';
import { ServiceLandingPageCategoryComponent } from './service-pages/service-console/service-landing-page/service-landing-page-category/service-landing-page-category.component';
import { ServiceLandingPageBlogComponent } from './service-pages/service-console/service-landing-page/service-landing-page-blog/service-landing-page-blog.component';
import { ServiceLandingPageBlogTypeComponent } from './service-pages/service-console/service-landing-page/service-landing-page-blog-type/service-landing-page-blog-type.component';
import { ServiceLandingPageConfigComponent } from './service-pages/service-console/service-landing-page/service-landing-page-config/service-landing-page-config.component';
import { ServiceLandingPageCategoryAddComponent } from './service-pages/service-console/service-landing-page/service-landing-page-category/service-landing-page-category-add/service-landing-page-category-add/service-landing-page-category-add.component';
import { ServiceLandingPageCategoryEditComponent } from './service-pages/service-console/service-landing-page/service-landing-page-category/service-landing-page-category-edit/service-landing-page-category-edit/service-landing-page-category-edit.component';
import { ServiceLandingPageBlogAddComponent } from './service-pages/service-console/service-landing-page/service-landing-page-blog/service-landing-page-blog-add/service-landing-page-blog-add/service-landing-page-blog-add.component';
import { ServiceLandingPageBlogEditComponent } from './service-pages/service-console/service-landing-page/service-landing-page-blog/service-landing-page-blog-edit/service-landing-page-blog-edit/service-landing-page-blog-edit.component';
import { ServiceLandingPageBlogTypeAddComponent } from './service-pages/service-console/service-landing-page/service-landing-page-blog-type/service-landing-page-blog-type-add/service-landing-page-blog-type-add/service-landing-page-blog-type-add.component';
import { ServiceLandingPageBlogTypeEditComponent } from './service-pages/service-console/service-landing-page/service-landing-page-blog-type/service-landing-page-blog-type-edit/service-landing-page-blog-type-edit/service-landing-page-blog-type-edit.component';
import { ServiceLandingPageConfigAddComponent } from './service-pages/service-console/service-landing-page/service-landing-page-config/service-landing-page-config-add/service-landing-page-config-add/service-landing-page-config-add.component';
import { ServiceLandingPageConfigEditComponent } from './service-pages/service-console/service-landing-page/service-landing-page-config/service-landing-page-config-edit/service-landing-page-config-edit/service-landing-page-config-edit.component';
import { ConsumerSignupManagerComponent } from './service-pages/service-console/service-admin-console/admin-account-signup-manager/consumer-signup/consumer-signup-manager/consumer-signup-manager.component';
import { WvAppComponent } from './webview/wv-app/wv-app.component';
import { WvLoginComponent } from './webview/wv-app/wv-page/wv-login/wv-login.component';
import { WvContractsManagerComponent } from './webview/wv-app/wv-page/wv-contracts/wv-contracts-manager/wv-contracts-manager.component';
import { WvContractAddComponent } from './webview/wv-app/wv-page/wv-contracts/wv-contract-add/wv-contract-add.component';
import { WvContractEditComponent } from './webview/wv-app/wv-page/wv-contracts/wv-contract-edit/wv-contract-edit.component';
import { WvContractDetailComponent } from './webview/wv-app/wv-page/wv-contracts/wv-contract-detail/wv-contract-detail.component';
import { ContractTemplateListComponent } from './service-pages/service-console/service-enduser-console/contract-template-manager/contract-template-list/contract-template-list.component';
import { ContractTemplateAddComponent } from './service-pages/service-console/service-enduser-console/contract-template-manager/contract-template-add/contract-template-add.component';
import { ContractTemplateDetailComponent } from './service-pages/service-console/service-enduser-console/contract-template-manager/contract-template-detail/contract-template-detail.component';
import { ContractTemplateEditComponent } from './service-pages/service-console/service-enduser-console/contract-template-manager/contract-template-edit/contract-template-edit.component';
import { DieuKhoanComponent } from './service-pages/service-console/ec/dieu-khoan/dieu-khoan.component';
import { VerifySignatureComponent } from './service-pages/service-console/service-enduser-console/signature-manager/verify-signature/verify-signature.component';
import { GroupPermissionsComponent } from './service-pages/service-console/service-enduser-console/permission-manager/group-permissions/group-permissions.component';
import { ContractFlowListComponent } from './service-pages/service-console/service-enduser-console/contract-flow-manager/contract-flow-list/contract-flow-list.component';
import { ContractFlowAddComponent } from './service-pages/service-console/service-enduser-console/contract-flow-manager/contract-flow-add/contract-flow-add.component';
import { ContractFlowEditComponent } from './service-pages/service-console/service-enduser-console/contract-flow-manager/contract-flow-edit/contract-flow-edit.component';
import { ContractFlowDetailComponent } from './service-pages/service-console/service-enduser-console/contract-flow-manager/contract-flow-detail/contract-flow-detail.component';
import { SignAgreementComponent } from './service-pages/service-console/service-enduser-console/contract-manager/sign-agreement/sign-agreement.component';
import { RequestsServiceComponent } from './service-pages/service-console/service-admin-console/requests-service/requests-service/requests-service.component';
import { RequestsServiceDetailComponent } from './service-pages/service-console/service-admin-console/requests-service/requests-service-detail/requests-service-detail.component';
import { CatalogServiceListComponent } from './service-pages/service-console/service-admin-console/package-service-manager/catalog-service/catalog-service-list/catalog-service-list.component';
import { CatalogServiceAddComponent } from './service-pages/service-console/service-admin-console/package-service-manager/catalog-service/catalog-service-add/catalog-service-add.component';
import { CatalogServiceEditComponent } from './service-pages/service-console/service-admin-console/package-service-manager/catalog-service/catalog-service-edit/catalog-service-edit.component';
import { CatalogServiceDetailComponent } from './service-pages/service-console/service-admin-console/package-service-manager/catalog-service/catalog-service-detail/catalog-service-detail.component';
import { AddPackageComponent } from './service-pages/service-console/service-admin-console/package-service-manager/package-manager/add-package/add-package.component';
import { RoleCode } from './utils/common/constants/roles-code';
import { ReportQuantityCustomerComponent } from './service-pages/service-console/service-admin-console/report/report-quantity-customer/report-quantity-customer.component';
import { ReportCustomerDetailComponent } from './service-pages/service-console/service-admin-console/report/report-customer-detail/report-customer-detail.component';
import { ProfileManagerComponent } from './service-pages/service-auth/profile-manager/profile-manager/profile-manager.component';
import { AddBusinessCustomerComponent } from './service-pages/service-console/service-admin-console/service-account-manager/add-business-customer/add-business-customer.component';
import { EditBusinessCustomerComponent } from './service-pages/service-console/service-admin-console/service-account-manager/edit-business-customer/edit-business-customer.component';
import { AddConsumerCustomerComponent } from './service-pages/service-console/service-admin-console/service-account-manager/add-consumer-customer/add-consumer-customer.component';
import { EditConsumerCustomerComponent } from './service-pages/service-console/service-admin-console/service-account-manager/edit-consumer-customer/edit-consumer-customer.component';
import { HomeV4Component } from './service-pages/home/home-v4/home-v4.component';
import { PricelistComponent } from './service-pages/home/pricelist/pricelist.component';
import { RegisterComponent } from './service-pages/home/register/register.component';
import { RegisterForBusinessComponent } from './service-pages/service-console/service-enduser-console/user-manual/register-for-business/register-for-business.component';
import { RegisterForConsumerComponent } from './service-pages/service-console/service-enduser-console/user-manual/register-for-consumer/register-for-consumer.component';
import { LoginComponent } from './service-pages/home/login/login.component';
import { SignMultipleContractsComponent } from './service-pages/service-console/service-enduser-console/contract-manager/sign-multiple-contracts/sign-multiple-contracts.component';
import { AssignToEnduserComponent } from './service-pages/service-console/service-admin-console/package-service-manager/package-manager/assign-to-enduser/assign-to-enduser.component';
import { ConfirmAssignComponent } from './service-pages/service-console/service-admin-console/package-service-manager/package-manager/confirm-assign/confirm-assign.component';
import { DetailPackageComponent } from './service-pages/service-console/service-admin-console/package-service-manager/package-manager/detail-package/detail-package.component';
import { ListPackageComponent } from './service-pages/service-console/service-admin-console/package-service-manager/package-manager/list-package/list-package.component';
import { EditPackageComponent } from './service-pages/service-console/service-admin-console/package-service-manager/package-manager/edit-package/edit-package.component';
import { HomeMainComponent } from './layout/main-home/home-main/home-main.component';
import { PostComponent } from './service-pages/home/post/post.component';
import { VerifyOTPComponent } from './service-pages/home/verify-otp/verify-otp.component';
import { ForgotPasswordComponent } from './service-pages/home/forgot-password/forgot-password.component';


import { ConfigurationInformationComponent } from './service-pages/service-console/service-enduser-console/ekyc-specification/configuration-information/configuration-information.component';
import { UpdateConfigurationInformationComponent } from './service-pages/service-console/service-enduser-console/ekyc-specification/update-configuration-information/update-configuration-information.component';
import { EnduserPackageDetailV2Component } from './service-pages/service-console/service-enduser-console/packages-manager/enduser-package-detail-v2/enduser-package-detail-v2.component';
import { AdministrativeDirectoryComponent } from './service-pages/service-console/service-admin-console/administrative-directory/administrative-directory.component';
import { UserManualComponent } from './service-pages/service-console/service-enduser-console/user-manual/user-manual.component';
import { OtpForgotPasswordComponent } from './service-pages/home/forgot-password/otp-forgot-password/otp-forgot-password.component';
import { RenewPasswordComponent } from './service-pages/home/forgot-password/renew-password/renew-password.component';
import { AppComponent } from './app.component';
import { NosignMultipleComponent } from './service-pages/service-console/service-enduser-console/contract-manager/nosign-multiple/nosign-multiple.component';
import { LoginWithOTPComponent } from './service-pages/home/login-with-otp/login-with-otp.component';
import { VerifyOTPLoginComponent } from './service-pages/home/verify-otp-login/verify-otp-login.component';
import { ListContractNumberConfigComponent } from './service-pages/service-console/service-enduser-console/contract-number-config/list-contract-number-config/list-contract-number-config.component';
import { AddContractNumberConfigComponent } from './service-pages/service-console/service-enduser-console/contract-number-config/add-contract-number-config/add-contract-number-config.component';
import { EditContractNumberConfigComponent } from './service-pages/service-console/service-enduser-console/contract-number-config/edit-contract-number-config/edit-contract-number-config.component';
import { DetailContractNumberConfigComponent } from './service-pages/service-console/service-enduser-console/contract-number-config/detail-contract-number-config/detail-contract-number-config.component';
import { ReportQuantityV1Component } from './service-pages/service-console/service-admin-console/report/report-quantity-v1/report-quantity-v1.component';
import { WvLoginOtpComponent } from './webview/wv-app/wv-page/wv-login-otp/wv-login-otp/wv-login-otp.component';
import { WvVerifyOtpLoginComponent } from './webview/wv-app/wv-page/wv-login-otp/wv-verify-otp-login/wv-verify-otp-login.component';
import { SignMultiDownloadContractComponent } from './service-pages/service-console/service-enduser-console/contract-manager/sign-multi-download-contract/sign-multi-download-contract.component';
import { ListDownloadContractComponent } from './service-pages/service-console/service-enduser-console/contract-manager/list-download-contract/list-download-contract.component';
import { ServiceDashboardAdminComponent } from './service-pages/service-console/service-admin-console/dashboard/service-dashboard-admin/service-dashboard-admin.component';
import { TrustedPartnerAddComponent } from './service-pages/service-console/service-enduser-console/partner/trusted-partner-manager/trusted-partner-add/trusted-partner-add.component';
import { TrustedConsumerPartnerDetailComponent } from './service-pages/service-console/service-enduser-console/partner/trusted-partner-manager/trusted-consumer-partner-detail/trusted-consumer-partner-detail.component';
import { TrustedPartnerEditComponent } from './service-pages/service-console/service-enduser-console/partner/trusted-partner-manager/trusted-partner-edit/trusted-partner-edit.component';
import { AddBusinessTrustedPartnerComponent } from './service-pages/service-console/service-enduser-console/partner/business-trusted-partner/add-business-trusted-partner/add-business-trusted-partner.component';
import { DetailsBusinessTrustedPartnerComponent } from './service-pages/service-console/service-enduser-console/partner/business-trusted-partner/details-business-trusted-partner/details-business-trusted-partner.component';
import { UpdateBusinessTrustedPartnerComponent } from './service-pages/service-console/service-enduser-console/partner/business-trusted-partner/update-business-trusted-partner/update-business-trusted-partner.component';
import { WvProfileManagerComponent } from './webview/wv-app/wv-page/wv-profile-manager/wv-profile-manager.component';
import { ContractTypesRoutes } from './service-pages/service-console/service-enduser-console/contract-type-management/contract-type.routes';

const routes: Routes = [
    {//Portal quản lý cho enduser (As a service)
        path: 'app/console',
        component: AasMainComponent,
        canActivate: [AasAuthGuard],
        canActivateChild: [AasAuthGuard],
        children: [
            {
                path: 'dashboard',
                component: ServiceDashboardEnduserComponent,
                data: { roles: [] } //'ACCOR', 'ACCPR', 'ACCPUL1', 'ACCPUL2'
            },
            {
                path: 'profile-manager',
                component: ServiceProfileManagerComponent,
                data: { roles: [] } //'ACCOR', 'ACCPR', 'ACCPUL1', 'ACCPUL2'
            },
            {
                path: 'profile-manager/update-gpkd',
                component: ServiveVerifyBusinessComponent,
                data: { roles: [] } //'ACCOUG'
            },
            {
                path: 'signature-manager',
                component: ServiceSignatureManagerComponent,
                data: { roles: [] }
            },
            {
                path: 'signature-manager/sign-server-login',
                component: ServiceRedirectVnptcaLoginComponent,
                data: { roles: [] }
            },
            {
                path: 'contract-manager',
                component: ServiceContractManagerComponent,
                data: { roles: [] }
            },
            // {
            //     path: 'contract-download',
            //     component: ListDownloadContractComponent,
            //     data: { roles: [] }
            // },
            {
                path: 'api/documents',
                component: ServiceApiManagerComponent,
                data: { roles: [] }
            },
            {
                path: 'products',
                component: ServiceProductsManagerComponent,
                data: { roles: [] } //'ECT2100', 'ECT2101', 'ECT2102', 'ECT2103'
            },
            {
                path: 'documents',
                component: DocumentsManagerComponent,
                data: { roles: [] } //'ECT2200', 'ECT2201', ' ECT2202','ECT2203'
            },
            {
                path: 'package',
                component: EnduserPackagesManagerComponent,
                data: { roles: [] }
            },
            {
                path: 'package/detail',
                component: EnduserPackageDetailV2Component,
                data: { roles: [] }
            },
            {
                path: 'package/detail/:id/:status',
                component: EnduserPackageDetailComponent,
                data: { roles: [] }
            },
            {
                path: 'partner/add-business-trusted-partner',
                component: AddBusinessTrustedPartnerComponent,
                data: { roles: [] }
            },
            {
                path: 'partner/update-business-trusted-partner/:id',
                component: UpdateBusinessTrustedPartnerComponent,
                data: { roles: [] }
            },
            {
                path: 'partner/business-trusted-partner-detail/:id',
                component: DetailsBusinessTrustedPartnerComponent,
                data: { roles: [] }
            },
            {
                path: 'partner/consumer-verify',
                component: ConsumerPartnerVerificationComponent,
                data: { roles: [] }
            },
            {
                path: 'partner/party-verify',
                component: PartyPartnerVerificationComponent,
                data: { roles: [] }
            },
            {
                path: 'partner/trusted',
                component: TrustedPartnerManagerComponent,
                data: { roles: [] }
            },
            // {
            //     path: 'partner/trusted/:userType/:id',
            //     component: TrustedPartnerDetailComponent,
            //     data: { roles: [] }
            // },
            {
                path:'partner/trusted/consumer-detail/:id',
                component:TrustedConsumerPartnerDetailComponent,
                data:{ roles:[] }
            },
            {
                path:'partner/trusted/add-consumer',
                component: TrustedPartnerAddComponent,
                data:{ roles:[] }
            },
            {
                path:'partner/trusted/edit-consumer/:id',
                component: TrustedPartnerEditComponent,
                data:{ roles:[] }
            },
            {
                path: 'partner',
                redirectTo: 'partner/trusted',
                pathMatch: 'full'
            },
            {
                path: 'parts',
                component: PartListComponent,
                data: { roles: [] }
            },
            {
                path: 'parts/add',
                component: PartAddComponent,
                data: { roles: [] }
            },
            {
                path: 'parts/edit/:id',
                component: PartEditComponent,
                data: { roles: [] }
            },
            {
                path: 'parts/detail/:id',
                component: PartDetailComponent,
                data: { roles: [] }
            },
            {
                path: 'employees',
                component: EmployeeListComponent,
                data: { roles: [] }
            },
            {
                path: 'employees/add',
                component: EmployeeAddComponent,
                data: { roles: [] }
            },
            {
                path: 'employees/edit/:id',
                component: EmployeeEditComponent,
                data: { roles: [] }
            },
            {
                path: 'employees/detail/:id',
                component: EmployeeDetailComponent,
                data: { roles: [] }
            },
            {
                path: 'contract-templates',
                component: ContractTemplateListComponent,
                data: { roles: ['ECT2300'] }
            },
            {
                path: 'contract-templates/add',
                component: ContractTemplateAddComponent,
                data: { roles: ['ECT2301'] }
            },
            {
                path: 'contract-templates/detail/:id',
                component: ContractTemplateDetailComponent,
                data: { roles: ['ECT2300'] }
            },
            {
                path: 'contract-templates/edit/:id',
                component: ContractTemplateEditComponent,
                data: { roles: ['ECT2301'] }
            },
            {
                path: 'verify-signature',
                component: VerifySignatureComponent, 
                data: { roles: [] }
            },
            {
                path: 'group-users',
                component: GroupPermissionsComponent,
                data: { roles: [] }
            },
            {
                path: 'contract-flow/list',
                component: ContractFlowListComponent,
                data: { roles: ['ESO2005'] }
            },
            {
                path: 'contract-flow/add',
                component: ContractFlowAddComponent,
                data: { roles: ['ESO2005'] }
            },
            {
                path: 'contract-flow/edit/:id',
                component: ContractFlowEditComponent,
                data: { roles: ['ESO2005'] }
            },
            {
                path: 'contract-flow/detail/:id',
                component: ContractFlowDetailComponent,
                data: { roles: ['ESO2005'] }
            },
            {
                path: '',
                redirectTo: 'dashboard',
                pathMatch: 'full'
            },
            {
                path: 'user-settings',
                component: ProfileManagerComponent,
                data: { roles: [] }
            },
            // Tuan's path
            {
                path: 'ekyc-config',
                component: ConfigurationInformationComponent,
                data: { role: RoleCode.rolesELYCConfig }
            },
            {
                path: 'ekyc-config/update',
                component: UpdateConfigurationInformationComponent,
                data: { role: RoleCode.rolesELYCConfig }
            }
        ]
    },
    {
        path: 'app/console/employees/finish-signup',
        canActivate: [AasNonAuthGuard],
        component: EmployeeFinishSignupComponent,
        data: { roles: [] }
    },
    {//Tạo, xem sửa hợp đồng
        path: 'app/console/contract',
        canActivate: [AasAuthGuard],
        canActivateChild: [AasAuthGuard],
        children: [
            {
                path: 'create',
                component: ServiceContractComponent
            },
            {
                path: 'chat',
                component: ChatManagerComponent
            },
            {
                path: 'update/:id',
                component: ServiceContractComponent
            },
            {
                path: ':id',
                component: ServiceContractDetailComponent
            },
            {
                path: 'sign-agreement/:id',
                component: SignAgreementComponent,
                data: { roles: [] }
            },
            {
                path: '',
                redirectTo: 'create',
                pathMatch: 'full'
            }
        ]
    },
    {// Ký hàng loạt
        path: 'app/console/sign-multiple-contracts',
        canActivate: [AasAuthGuard],
        canActivateChild: [AasAuthGuard],
        // component: AppComponent,
        children: [
            {
                path: ':signForm',
                component: SignMultipleContractsComponent,
                data: { roles: RoleCode.rolesSignManyContracts }
            }
        ]
    },
    {// Duyệt không ký hàng loạt
        path: 'app/console/approve-without-sign',
        canActivate: [AasAuthGuard],
        component: NosignMultipleComponent,
        data: { roles: [RoleCode.rolesApproveWithoutSign] }
    },
    {
        path:'app/console/sign-multi-download-contract/:by',
        component: SignMultiDownloadContractComponent,
        canActivate:[AasAuthGuard],
        data: {roles: [RoleCode.rolesDanhSachTaiXuong]}
    },
    {// hóa đơn điện tử
        path: 'app/console/einvoices',
        component: AasMainComponent,
        canActivate: [AasAuthGuard],
        canActivateChild: [AasAuthGuard],
        children: [
            {
                path: 'list',
                component: ServiceEinvoicesListComponent
            },
            {
                path: 'detail/:id/:taxCode',
                component: ServiceEnvoicesDetailComponent
            },
            {
                path: 'create',
                component: ServiceEnvoicesCreateComponent
            },
            {
                path: 'invoicesCustomer/create',
                component: AccountEinvoicesCreateComponent
            },
            {
                path: 'invoicesCustomer/list',
                component: AccoutEinvoicesListComponent
            },
            {
                path: 'invoicesCustomer/detail/:id',
                component: AccountEnvoicesDetailComponent
            },
            {
                path: 'invoicesCustomer/update/:id',
                component: AccountEcvoicesUpdateComponent
            },
            {
                path: '',
                redirectTo: 'create',
                pathMatch: 'full'
            }
        ]
    },
    {
        path: 'app/console/user-manual',
        component: AasMainComponent,
        canActivate: [AasAuthGuard],
        canActivateChild: [AasAuthGuard],
        children: [
            {
                path: '',
                component: UserManualComponent
            }
            // {
            //     path: 'register-for-business',
            //     component: RegisterForBusinessComponent
            // },
            // {
            //     path: 'register-for-consumer',
            //     component: RegisterForConsumerComponent
            // }
        ]
    },
    // Cấu hình số hợp đồng
    {
        path: 'app/console/configure-contract-number',
        component: AasMainComponent,
        canActivate: [AasAuthGuard],
        children: [
            {
                path: '',
                component: ListContractNumberConfigComponent,
                data: { roles: RoleCode.rolesContractNumberConfig }
            },
            {
                path: 'add',
                component: AddContractNumberConfigComponent,
                data: { roles: RoleCode.rolesContractNumberConfig }
            },
            {
                path: 'edit/:id',
                component: EditContractNumberConfigComponent,
                data: { roles: RoleCode.rolesContractNumberConfig }
            },
            {
                path: 'detail/:id',
                component: DetailContractNumberConfigComponent,
                data: { roles: RoleCode.rolesContractNumberConfig }
            }
        ]
    },
    {
        path: 'console',
        component: AasMainComponent,
        canActivate: [AasAuthGuard],
        children: [
            {
                path: 'video-call',
                component: VideoCallComponent
            },
            {
                path: 'register-manager',
                component: ConsumerSignupManagerComponent
            },
            {
                path: 'enterprise-manager',
                component: EnterpriseManagerComponent
            },
            {
                path: 'enterprise-vip-manager',
                component: ServiceEnterpriseVipManagerComponent
            },
            {
                path: 'account-manager',
                component: ServiceAccountManagerComponent,
            },
            // {
            //     path: 'account-manager/detail/:id',
            //     component: ServiceAccountDetailComponent
            // },
            {
                path: 'account-manager/edit/:id',
                component: ServiceAccountEditComponent
            },
            // {
            //     path: 'account-manager/organization/:id',
            //     component: ServiceOrganizationDetailComponent
            // },
            // {
            //     path: 'account-manager/organization/update/:id',
            //     component: ServiceOrganizationUpdateComponent
            // },
            // {
            //     path: 'service-package-manager/list',
            //     component: ServicePackageListComponent,
            // },
            // {
            //     path: 'service-package-manager/create',
            //     component: CreateServicePakageComponent,
            // },
            {
                path: 'service-package-manager/detail/:id',
                component: ServicePackageDetailComponent,
            },
            {
                path: 'service-package-manager/update/:id',
                component: UpdateServicePackageComponent,
            },
            {
                path: 'service-package-manager/convert',
                component: ServicePackageConversionListComponent,
            },
            {
                path: 'category-package/list',
                component: ListCategoryPackageComponent,
            },
            {
                path: 'category-package/create',
                component: CreateCategoryPackageComponent,
            },
            {
                path: 'category-package/detail/:id',
                component: DetailCategoryPackageComponent,
            },
            {
                path: 'category-package/update/:id',
                component: UpdateCategoryPackageComponent,
            },
            {
                path: 'template-contract/detail/:id',
                component: ServiceContractTemplateGroupComponent,
            },

            {
                path: 'licenses-manager/list',
                component: CopyrightListComponent,
            },
            {
                path: 'licenses-manager/detail/:id/:packInfoId',
                component: CopyrightDetailComponent,
            },
            {
                path: 'quantity-report/package',
                component: ReportPackageComponent,
            },
            {
                path: 'quantity-report/service',
                component: ReportServiceComponent,
            },
            {
                path: 'quantity-report/contract',
                component: ReportContractComponent,
            },
            {
                path: 'quantity-report/contract-detail',
                component: ReportDetailContractComponent,
            },
            {
                path: 'api/manager/list',
                component: ServiceApiListComponent,
                data: { roles: [] }
            },
            {
                path: 'api/manager/create',
                component: ServiceApiEditorComponent,
                data: { roles: [] }
            },
            {
                path: 'api/manager/detail/:id',
                component: ServiceApiDetailComponent,
                data: { roles: [] }
            },
            {
                path: 'api/manager/edit/:id',
                component: ServiceApiEditorComponent,
                data: { roles: [] }
            },
            {
                path: 'api/manager',
                redirectTo: 'api/manager/list',
                pathMatch: 'full'
            },
            {
                path: 'api-key/list',
                component: ApiKeyManagerComponent,
                data: { roles: [] }
            },
            {
                path: 'api-key/detail/:id',
                component: ApiKeyDetailComponent,
                data: { roles: [] }
            },
            {
                path: 'contract-manager',
                component: AdminContractManagerComponent,
                data: { roles: [] }
            },
            {
                path: 'requests-service',
                component: RequestsServiceComponent,
                data: { roles: [] }
            },
            {
                path: 'requests-service/:id',
                component: RequestsServiceDetailComponent,
            },
            {
                path: 'service-catalog/list',
                component: CatalogServiceListComponent,
                data: { roles: [] }
            },
            {
                path: 'service-catalog/add',
                component: CatalogServiceAddComponent,
                data: { roles: [] }
            },
            {
                path: 'service-catalog/edit/:id',
                component: CatalogServiceEditComponent,
                data: { roles: [] }
            },
            {
                path: 'service-catalog/:id',
                component: CatalogServiceDetailComponent,
                data: { roles: [] }
            },
            {
                path: 'report-customer',
                component: ReportQuantityCustomerComponent,
                data: { roles: [] }
            },
            {
                path: 'report-customer/detail/:id',
                component: ReportCustomerDetailComponent,
                data: { roles: [] }
            },
            {
                path: 'administrative-directory',
                component: AdministrativeDirectoryComponent,
                data: { roles: [] }
            },
            {
                path: 'report-quantity-v1',
                component: ReportQuantityV1Component,
                data: { roles: [] }
            },
        ]
    },
    {
        path: 'console/package-manager',
        component: AasMainComponent,
        canActivate: [AasAuthGuard],
        children: [
            {
                path: 'list',
                component: ListPackageComponent,
            },
            {
                path: 'add',
                component: AddPackageComponent,
            },
            {
                path: 'assign/:id',
                component: AssignToEnduserComponent,
            },
            {
                path: 'confirm-assign',
                component: ConfirmAssignComponent,
            },
            {
                path: 'detail/:id',
                component: DetailPackageComponent,
            },
            {
                path: 'edit/:id',
                component: EditPackageComponent
            },
            {
                path: '',
                redirectTo: 'list',
                pathMatch: 'full'
            }
        ]
    },
    {//Tạo, xem sửa hợp đồng dich vụ cho admin
        path: 'console/contract',
        canActivate: [AasAuthGuard],
        children: [
            {
                path: 'create',
                component: AdminContractComponent,
                data: { roles: [RoleCode.createContract] }
            },
            {
                path: 'edit/:id',
                component: AdminContractComponent,
                data: { roles: [] }
            },
            {
                path: ':id',
                component: AdminContractDetailComponent,
                data: { roles: [] }
            },
            {
                path: '',
                redirectTo: 'create',
                pathMatch: 'full'
            }
        ]
    },
    {
        path: 'call',
        component: Web2webVideocallComponent
    },
    {
        path: 'app',
        canActivate: [AasNonAuthGuard],
        children: [
            {
                path: 'login',
                component: LoginComponent
            },
            {
                path: 'login-with-OTP',
                component: LoginWithOTPComponent
            },
            {
                path: 'verify-OTP-login',
                component: VerifyOTPLoginComponent
            },
            // {
            //     path: 'signup',
            //     // component: ServiceSignupComponent
            //     // component: ServiceSignupV2Component
            //     component: RegisterComponent
            // },
            {
                path: 'register-business-account',
                component: ServiceSignupBusinessComponent
            },
            {
                path: 'signup-business-vip',
                component: ServiceSignupBusinessVipStepComponent
            },
            // {
            //     path: 'renew-password',
            //     component: ServiceRenewPasswordComponent
            // },
            {
                path: 'renew-password',
                component: RenewPasswordComponent
            },
            // {
            //     path: 'forgot-password',
            //     component: ForgotPasswordComponent
            // },
            // {
            //     path: 'forgot-password/request-otp',
            //     component: OtpForgotPasswordComponent
            // },
            
            {
                path: 'request-otp',
                component: ServiceRequestOtpComponent
            },
            {
                path: 'signup-success',
                component: ServiceSignupSuccessComponent
            },
            {
                path: '',
                redirectTo: 'login',
                pathMatch: 'full'
            }
        ]
    },
    {
        path: 'contact',
        component: ContactComponent
    },
    {
        path: 'wv',
        component: WvAppComponent,
        children: [
            {
                path: "login",
                canActivate: [],
                component: WvLoginComponent
            },
            {
                path: "login-with-otp",
                canActivate: [],
                component: WvLoginOtpComponent
            },
            {
                path: "verify-otp-login",
                canActivate: [],
                component: WvVerifyOtpLoginComponent
            },
            {
                path: "contracts/list",
                canActivate: [],
                component: WvContractsManagerComponent
            },
            {
                path: "contracts/add",
                canActivate: [],
                component: WvContractAddComponent
            },
            {
                path: "contracts/edit/:id",
                canActivate: [],
                component: WvContractEditComponent
            },
            {
                path: "contracts/:id",
                canActivate: [],
                component: WvContractDetailComponent
            },
            {
                path: "user-settings",
                canActivate: [],
                component: WvProfileManagerComponent
            }
        ]
    },
    {
        path: 'ec/dieu-khoan',
        component: DieuKhoanComponent
    },
    {
        path: '',
        redirectTo: '/app/login',
        pathMatch: 'full'
    },
    {
        path: '**',
        pathMatch: 'full',
        component: WrongPageComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
