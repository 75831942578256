<!-- <section class="content">
  <div class="container-fluid"> -->
    <div class="card shadow-none">
        <div class="card-header">
          <div class="row ">
            <label class="text-primary mt-3">Thông tin chung</label>
          </div>
        </div>
       
        <div class="card-body">
            <form [formGroup]="form">
              <div class="row mb-3">
                <div class="col-6">
                  <div class="form-group">
                    <label>Tên tổ chức <span class="text-danger">*</span>:</label>
                    <input appTrimValue type="text" formControlName="tenKhachHang" disabled
                    class="form-control" placeholder="Nhập tên tổ chức" />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                  <div class="form-group">
                    <label>Tên gói dịch vụ <span class="text-danger">*</span>:</label>
                    <input appTrimValue type="text" formControlName="packageName" disabled
                    class="form-control" placeholder="Nhập tên gói dịch vụ" />
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <label>Ngày triển khai <span class="text-danger">*</span>:</label>
                    <input appTrimValue type="text" formControlName="issueDate" disabled
                    class="form-control" placeholder="Nhập ngày triển khai" />
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <label>Ngày hết hạn <span class="text-danger">*</span>:</label>
                    <input appTrimValue type="text" formControlName="expirationDate" disabled
                    class="form-control" placeholder="Nhập ngày hết hạn" />
                  </div>
                </div>
                <div class="col-3">
                  <div class="form-group">
                    <label>Trạng thái <span class="text-danger">*</span>:</label>
                    <ng-select2 formControlName="disable" [data]="dropListStatus" formControlName="status"  [disabled]="true"
                      [options]="options" [placeholder]="'Nhập thái'"> 
                    </ng-select2>
                  </div>
                </div>
              </div>
            </form>
           
        </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-8">
        <div class="card shadow-none pb-2 h-100">
          <div class="card-header">
            <label>
              Tổng giá trị hợp đồng
            </label>
          </div>
          <div class="card-body"  *ngIf="chartDatasets.length > 0 ">
            <div style="display: block">
              <canvas baseChart [chartType]="chartType" [datasets]="chartDatasets" [labels]="chartLabels"
                [colors]="chartColors" [options]="chartOptions" [legend]="true"
                (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)">
              </canvas>
            </div>
          </div>
          <div  *ngIf="listContractYear.length == 0">
            <app-empty-block></app-empty-block>
          </div>  
          
        </div>
      </div>
      <div class="col-md-4">
        <div class="px-2 h-100">
          <div class="card shadow-none pb-2 h-100">
            <div class="card-header">
              <label>
                Thống kê hợp đồng
              </label>
            </div>
            <div class="card-body" *ngIf="listContractReport.length > 0 && doughnutChartDatasets" >
              <div style="display: block">
                <div class="container mt-5" >
                  <canvas baseChart [chartType]="doughnutChartType" [datasets]="doughnutChartDatasets"
                    [labels]="doughnutChartLabels" [options]="doughnutCharOption" 
                    (chartHover)="chartHovered($event)" (chartClick)="chartClicked($event)">
                  </canvas>
                </div>
              </div>
            </div>
            <div class="container mt-5" *ngIf="listContractReport.length == 0">
              <app-empty-block></app-empty-block>
            </div>
          </div>
        </div>
      </div>
    </div>
  <!-- </div>
</section> -->
