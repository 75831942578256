import { MainResponse } from '../../../main-response.model';
import { InfoSignatureConfigObject } from './info-signature-config-object';

export class InfoSignatureConfigResponse extends MainResponse{
    object: InfoSignatureConfigObject;
    
    constructor(obj = null) {
        super();
        obj = obj || {};
        this.init(obj);
        this.object = new InfoSignatureConfigObject(obj.object);
    }
}