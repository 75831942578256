<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-auto">
                <h1 class="m-0 text-dark"><label>Tài liệu hướng dẫn sử dụng</label></h1>
            </div>
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>

<section class="content">
    <div class="container-fluid">
        <div class="bg-white">
            <section class="card-body p-0">
                <div class="table-responsive">
                    <table class="table mb-0">
                        <thead>
                            <tr class="bg-tb-header border-top text-left">
                                <th>STT</th>
                                <th>Tên tài liệu</th>
                                <th>Thao tác</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of data; let i = index">
                                <td><span *ngIf="i < 9">0</span>{{i + 1}}</td>
                                <td>{{item?.title}}</td>
                                <td><a class="text-primary" style="cursor: pointer;" (click)="onShowFile(item)">Tải xuống</a></td>
                                
                            </tr>
                        </tbody>
                    </table>
                    
                </div>
            </section>
        </div>
    </div>
</section>

<!-- <app-preview-modal *ngIf="showPdf" [file]="pdfFile" [name]="fileName" (close)="closePdf()"></app-preview-modal> -->
