import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { emit } from 'process';

declare var PDFSign: any;

@Component({
  selector: 'app-preview-gpkd',
  templateUrl: './preview-gpkd.component.html',
  styleUrls: ['./preview-gpkd.component.scss']
})
export class PreviewGpkdComponent implements OnInit {
  @Input('file') file: any = null;
  @Input('gpkdName') gpkdName: string = '';
  @Output('close') close: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    this.previewFile();
  }

  closePreview() {
    this.close.emit({});
  }

  previewFile() {
    PDFSign.preview(this.file);
  }

  downloadFile() {
    const blob: any = new Blob([this.file], { type: 'application/pdf' });
    var url = window.URL.createObjectURL(blob);
    var anchor = document.createElement("a");
    anchor.download = this.gpkdName || 'gpkd.pdf';
    anchor.href = url;
    anchor.click();
  }
}
