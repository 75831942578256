<app-spinner></app-spinner>
<div class="wrapper">
    <div class="preview-overlay">

        <div class="preview-container">
            <div class="preview-header d-flex">
                <div (click)="goToBack()"
                    class="ct-header-btn-back d-flex justify-content-center align-items-center cursor-pointer">
                    <span class="material-icons">arrow_back_ios</span>
                </div>
                <div class="ct-title flex-grow-1 d-flex align-items-center px-3">
                    <h4 class="mb-0">Tuỳ chỉnh thông tin hạ tầng</h4>
                </div>
                <div class="ct-toolbar-btn d-flex align-items-center">
                    <button class="btn btn-outline-primary d-flex align-items-center mr-3" (click)="goToBack()"><span
                        class="material-icons mr-2">close</span> Huỷ </button>
                    <button class="btn btn-primary d-flex align-items-center mr-3" (click)="btnSaveInfra()"><span
                        class="material-icons mr-2">save</span> Lưu </button>
                </div>
            </div>

            <div class="preview-body">
                <div class="pv ct">
                    <div class="pv-body">
                        <div class="ctpv-container">
                            <div class="ctpv-content">
                                <div class="ct-container">
                                    <div class="ct-content">
                                        <div id="parentView" class="ct-doc-loaded ct-enable-scroll">
                                            
                                            <section class="content">
                                                <div class="container-fluid">
                                                    <div class="card shadow-none mb-4">
                                                        <div class="card-body">
                                                            <div class="d-flex mt-3">
                                                                <h6 class="text-primary font-weight-bold m-0">Thông tin chung</h6>
                                                                <div class="col-md-auto ml-auto">
                                                                </div>
                                                            </div>
                                                            <hr class="my-3">
                                                            <div class="row mb-2">
                                                                <div class="col-md-4 col-lg-3">
                                                                    <label class="font-weight-bold">Tên gói dịch vụ</label>
                                                                </div>
                                                                <div class="col-md-4">
                                                                    <!-- <span>{{ packageInfo?.packageInfo?.name }}</span> -->
                                                                    <div class="form-group mb-0">
                                                                        <ng-select
                                                                            [(ngModel)]="packInfoId"
                                                                            [items]="dropListPackageData"
                                                                            bindLabel="name"
                                                                            bindValue="packInfoId"
                                                                            placeholder="Chọn gói dịch vụ"
                                                                            (change)="changedPackage($event)"
                                                                        ></ng-select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4 col-lg-3">
                                                                    <label class="font-weight-bold">Loại gói dịch vụ</label>
                                                                </div>
                                                                <div class="col-md-8 col-lg-9">
                                                                    <span>{{ constPackageType[packageInfo?.packageInfo?.packageType] || "" }}</span>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4 col-lg-3">
                                                                    <label class="font-weight-bold">Loại khách hàng áp dụng</label>
                                                                </div>
                                                                <div class="col-md-8 col-lg-9">
                                                                    <span>{{ constCustomerType[packageInfo?.packageInfo?.userType] || "" }}</span>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4 col-lg-3">
                                                                    <label class="font-weight-bold">Thời hạn sử dụng</label>
                                                                </div>
                                                                <div class="col-md-8 col-lg-9">
                                                                    <span>{{ packageInfo?.packageInfo?.duration || 0 }} tháng</span>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4 col-lg-3">
                                                                    <label class="font-weight-bold">Thứ tự hiển thị</label>
                                                                </div>
                                                                <div class="col-md-8 col-lg-9">
                                                                    <span>{{ packageInfo?.packageInfo?.orderNumber }}</span>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4 col-lg-3">
                                                                    <label class="font-weight-bold">Giá tiền</label>
                                                                </div>
                                                                <div class="col-md-8 col-lg-9">
                                                                    <span>{{ packageInfo?.packageInfo?.price | number }}</span>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4 col-lg-3">
                                                                    <label class="font-weight-bold">Mô tả</label>
                                                                </div>
                                                                <div class="col-md-8 col-lg-9">
                                                                    <span>{{ packageInfo?.packageInfo?.description }}</span>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4 col-lg-3">
                                                                    <label class="font-weight-bold">Trạng thái</label>
                                                                </div>
                                                                <div class="col-md-8 col-lg-9">
                                                                    <span [innerHTML]="getStatusSpan(packageInfo?.packageInfo?.status)"></span>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4 col-lg-3">
                                                                    <label class="font-weight-bold">Thời gian cập nhật</label>
                                                                </div>
                                                                <div class="col-md-8 col-lg-9">
                                                                    <span>{{ packageInfo?.packageInfo?.modified | date: "dd/MM/yyyy" }}</span>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-2">
                                                                <div class="col-md-4 col-lg-3">
                                                                    <label class="font-weight-bold">Người cập nhật</label>
                                                                </div>
                                                                <div class="col-md-8 col-lg-9">
                                                                    <span>{{ packageInfo?.packageInfo?.createUser }}</span>
                                                                </div>
                                                            </div>
                                            
                                                            <div class="d-flex mt-3">
                                                                <h6 class="text-primary font-weight-bold m-0">Thông tin hạ tầng</h6>
                                                                <div class="col-md-auto ml-auto">
                                                                </div>
                                                            </div>
                                                            <hr class="my-3">
                                                            <div class="row mb-2">
                                                                <div class="col-md-4 col-lg-3">
                                                                    <label class="font-weight-bold">Loại danh mục hạ tầng</label>
                                                                </div>
                                                                <div class="col-md-8 col-lg-9">
                                                                    <span>{{ packageInfo?.listPackageInfrastructure?.name }}</span>
                                                                </div>
                                                            </div>

                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <div class="row">
                                                                        <div class="col-md-12">
                                                                            <div class="table-responsive">
                                                                                <table class="table mb-0">
                                                                                    <thead>
                                                                                        <tr class="bg-tb-header border-top text-left">
                                                                                            <th>{{ localize.stt }}</th>
                                                                                            <th>{{ localize.parameter }}</th>
                                                                                            <th>{{ localize.unit }}</th>
                                                                                            <th>{{ localize.quantity }}</th>
                                                                                            <th>{{ localize.unit_price }} (VNĐ)</th>
                                                                                            <th>{{ localize.into_money }}</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        <tr *ngFor="let item of infraConfigs; let i = index">
                                                                                            <td>{{ item.stt }}</td>
                                                                                            <td>{{ item.name }}</td>
                                                                                            <td>{{ item.unit }}</td>
                                                                                            <td>
                                                                                                <input appOnlyNumber class="form-control" type="number" step="0.1" [(ngModel)]="item.unitNumber" (input)="progressPrice(item); getTotalMoney()">
                                                                                            </td>
                                                                                            <td>
                                                                                                <span *ngIf="item.param !== 'STORAGE_TIME'">{{ item.unitPrice }}</span>
                                                                                            </td>
                                                                                            <td>
                                                                                                <span *ngIf="item.param !== 'STORAGE_TIME'">{{ item.price }}</span>
                                                                                            </td>
                                                                                        </tr>
                                                                                        <tr>
                                                                                            <td></td>
                                                                                            <td colspan="4">Tổng cộng</td>
                                                                                            <td>{{ totalPrice }}</td>
                                                                                        </tr>
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                            
                                                            <div class="d-flex mt-3">
                                                                <h6 class="text-primary font-weight-bold m-0">Thông tin chức năng</h6>
                                                                <div class="col-md-auto ml-auto">
                                                                </div>
                                                            </div>
                                                            <hr class="my-3">
                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <span *ngIf="packageInfo?.packageInfo?.packageType === 'EXTEND_TYPE'">Thông tin chức năng của gói mở rộng sẽ được lấy theo thông tin chức năng hiện tại của khách hàng </span>
                                                                    <div *ngIf="packageInfo?.packageInfo?.packageType !== 'EXTEND_TYPE'" class="row">
                                                                        <div class="col-12">
                                                                            <app-dual-list #duallist 
                                                                                [items]="items_dualist" 
                                                                                [listAssigned]="items_dualist_assigned" 
                                                                                [disabled]="true"
                                                                                [onlyView]="true">
                                                                            </app-dual-list>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            
                                            </section>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>