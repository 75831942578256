import { ListSignatureObject } from 'src/app/utils/models/aas-models/contract/list-signature/list-signature-object.model';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Options, Select2AjaxOptions } from 'select2';
import { Select2OptionData } from 'ng-select2';
import { FormGroup, FormBuilder, FormControl, Validators, Form, NgModel } from '@angular/forms';
import { ValidateMessage, ServiceMsg } from 'src/app/utils/common/validate-msg-aas';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { Config } from 'src/app/config';
import { Router, ActivatedRoute } from '@angular/router';
import { Location, DatePipe } from '@angular/common';
import { ContractObject } from 'src/app/utils/models/aas-models/contract/contract/contract-object.model';
import { InfoSignatureConfigObject } from 'src/app/utils/models/aas-models/signature/signature-config/info-signature-config-object';
import { ContractStatus } from 'src/app/utils/common/constant';
import { UserInformationsItem } from 'src/app/utils/models/profile/user-info-item.model';
import { BusinessPackageService } from 'src/app/utils/services/aas-network/business-package.service'
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ConstantAlertBtn } from 'src/app/utils/common/constant-alert-msg';

declare var PDFSign: any;
declare var $: any;
@Component({
  selector: 'app-service-contract-template-group',
  templateUrl: './service-contract-template-group.component.html',
  styleUrls: ['./service-contract-template-group.component.scss']
})
export class ServiceContractTemplateGroupComponent implements OnInit {
  @ViewChild('output') output: ElementRef;
  @ViewChild('filename') filename: ElementRef;
  @ViewChild('filebase') filebase: ElementRef;

  templateId = this.activeRoute.snapshot.paramMap.get('id');

  //mark: user
  filePDF: File = null;
  fileType: Array<string> = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
  fileName: string = '';

  formDSign: FormGroup;
  isESignSubmit: boolean = false;
  templateName : string = ""

  //Tab controll
  activeTab: string = 'INFO';

  constructor(
    private _location: Location,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private packageService: BusinessPackageService
  ) {
  }

  ngOnInit(): void {
    this.getContract();
    this.downloadFileContract(this.templateId)
  }

  selectTab(nameTab: string) {
    this.activeTab = nameTab;
  }

  //Trở lại trang trước
  goToBack() {
    this._location.back();
  }

  getBase64String(blob, callback) {
    var self = this;
    var reader = new FileReader();
    reader.onload = function (e) {
      callback(reader.result, self);
    }

    reader.readAsDataURL(blob);
  }

  //handel cho file pdf
  handle(str, self) {
    $('#fileBase').val(str.split(',')[1]);
  }

  getContract() {
    this.packageService.getInfoContract(this.templateId).subscribe((res)=>{
      this.templateName = res['object'].templateName
    }, err => {
      // this.handleError(err, ResponseMsg.MESSAGES.TEMPLATE);
      this.alert("Không tìm thấy thông tin hợp đồng mẫu!", ConstantAlertType.ERROR, false, "", ConstantAlertBtn.OK);
    })
  }

  //Tải file hợp đồng 
  downloadFileContract(id) {
    this.packageService.downloadTemplateContract(id).subscribe((res) => {
      const blob: any = new Blob([res], { type: 'application/pdf' });
      this.filePDF = blob;
      PDFSign.preview(blob);
      this.fileName = `${id}.pdf`;
      this.getBase64String(this.filePDF, this.handle);
    }, err => {
      console.log(err);
      // this.handleError(err, ResponseMsg.MESSAGES.TEMPLATE);
    })
  }

  //Tải xuống file hợp đồng
  saveFileContract() {
    this.packageService.downloadTemplateContract(this.templateId).subscribe((res) => {
      const blob: any = new Blob([res], { type: 'application/pdf' });
      var url = window.URL.createObjectURL(blob);
      var anchor = document.createElement("a");
      anchor.download = `${this.templateId}.pdf`;
      anchor.href = url;
      anchor.click();
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    })
  }
  


  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], 'error');
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, 'error');
    } else {
      this.alert('Lỗi không xác định! Vui lòng thử lại', 'error');
    }
    }
  

}
