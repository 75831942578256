<app-spinner></app-spinner>
<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-auto">
                <h1 class="m-0 text-dark"><label>{{ 'ContractFlowList_title' | translate }}</label></h1>
            </div>
            <!-- /.col -->
            <div class="col-md-auto ml-auto">
                <button (click)="addFlow()" class="btn btn-primary d-inline-flex mr-3"><span
                        class="material-icons mr-1">add</span> {{ 'btn_addnew' | translate }}</button>
            </div>
            <!-- <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>

<!--MARK top bar -->
<section class="content">
    <div class="container-fluid">
        <div class="bg-white">
            <section class="p-3">
                <div class="row">
                    <div class="col-lg-3 col-md-5">
                        <div class="input-group border rounded mb-3">
                            <div class="input-group-prepend border-0">
                                <button class="btn d-inline-flex pr-2 pl-2" type="submit"><span
                                        class="material-icons">search</span></button>
                            </div>
                            <input (keyup.enter)="enterSearch()" type="text" class="form-control border-0"
                                [(ngModel)]="keySearch" placeholder="{{ 'ContractFlowList_search' | translate }}" aria-label=""
                                aria-describedby="basic-addon1">
                        </div>
                    </div>
                    <div class="col-md-auto ml-auto">
                        
                    </div>
                </div>
            </section>
            <hr class="my-2">
            <section class="px-3 my-2">
                <form [formGroup]="formFilter">
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label for="status">{{ 'status' | translate }}</label>
                                        <ng-select labelForId="status" formControlName="status" 
                                            [items]="statusList"
                                            bindValue="value" bindLabel="label"
                                            [searchable]="false"
                                            [clearable]="false" 
                                            placeholder="{{ 'PartList_select_status' | translate }}">
                                            <ng-template ng-label-tmp let-item="item">
                                                <span>{{ item.label | translate }}</span>
                                            </ng-template>
                                            <ng-template ng-option-tmp let-item="item">
                                                <span>{{ item.label | translate }}</span>
                                            </ng-template>
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="align-self-end mb-3 ml-auto">
                                    <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="cleanFilter()">
                                        {{ 'btn_reset' | translate }}</button>
                                    <button class="btn btn-primary d-inline-flex mr-3" (click)="searchWithFilter()"> {{ 'btn_apply' | translate }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </section>

            <section class="card-body p-0">
                <div class="table-responsive">
                    <table class="table mb-0">
                        <thead>
                            <tr class="bg-tb-header border-top text-left">
                                <th>{{ 'ContractFlowList_stt' | translate }}</th>
                                <th class="cursor-pointer" (click)="sortCol('name')">
                                    <span class="d-flex align-self-center align-items-center">
                                        {{ 'ContractFlowList_name' | translate }}
                                        <span *ngIf="this.sortColConfig['name'] == null" class="material-icons ml-auto">
                                            unfold_more
                                        </span>
                                        <span *ngIf="this.sortColConfig['name'] == true" class="material-icons ml-auto">
                                            expand_less
                                        </span>
                                        <span *ngIf="this.sortColConfig['name'] == false" class="material-icons ml-auto">
                                            expand_more
                                        </span>
                                    </span>
                                </th>
                                <th class="cursor-pointer" (click)="sortCol('description')">
                                    <span class="d-flex align-self-center align-items-center">
                                        {{ 'ContractFlowList_desc' | translate }}
                                        <span *ngIf="this.sortColConfig['description'] == null" class="material-icons ml-auto">
                                            unfold_more
                                        </span>
                                        <span *ngIf="this.sortColConfig['description'] == true" class="material-icons ml-auto">
                                            expand_less
                                        </span>
                                        <span *ngIf="this.sortColConfig['description'] == false" class="material-icons ml-auto">
                                            expand_more
                                        </span>
                                    </span>
                                </th>
                                <th class="cursor-pointer" (click)="sortCol('status')">
                                    <span class="d-flex align-self-center align-items-center">
                                        {{ 'status' | translate }}
                                        <span *ngIf="this.sortColConfig['status'] == null" class="material-icons ml-auto">
                                            unfold_more
                                        </span>
                                        <span *ngIf="this.sortColConfig['status'] == true" class="material-icons ml-auto">
                                            expand_less
                                        </span>
                                        <span *ngIf="this.sortColConfig['status'] == false" class="material-icons ml-auto">
                                            expand_more
                                        </span>
                                    </span>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of flows; let i = index">
                                <td>{{ (page - 1) * maxSize + i + 1 }}</td>
                                <td>{{ item.name }}</td>
                                <td>{{ item.description }}</td>
                                <td>
                                    <span *ngIf="item.disable == false" class="badge badge-success-new p-2">{{ 'status_running' | translate }}</span>
                                    <span *ngIf="item.disable == true" class="badge badge-danger-new p-2">{{ 'status_stopped' | translate }}</span>
                                </td>
                                <td class="text-center">
                                    <div ngbDropdown container="body">
                                        <span class="material-icons cursor-pointer" ngbDropdownToggle
                                            id="settingTable">more_vert</span>
                                        <div ngbDropdownMenu class="border-0">
                                            <a ngbDropdownItem class="dropdown-item align-items-center cursor-pointer d-flex" (click)="detail(item.contractFlowTemplateId)">
                                                <span class="material-icons text-icon-dropdown-size mr-2">info</span>
                                                {{ 'ContractFlowList_details' | translate }}</a>

                                            <a ngbDropdownItem *ngIf="controlAcc.checkRoles(['ECT2301'])" class="dropdown-item align-items-center cursor-pointer d-flex" (click)="edit(item.contractFlowTemplateId)"><span
                                                    class="material-icons text-icon-dropdown-size mr-2">edit</span>{{ 'ContractFlowList_edit' | translate }}</a>
                                            <a ngbDropdownItem *ngIf="controlAcc.checkRoles(['ECT2301'])" class="dropdown-item align-items-center cursor-pointer d-flex" (click)="delete(item)"><span
                                                        class="material-icons text-icon-dropdown-size mr-2">delete</span>{{ 'ContractFlowList_remove' | translate }}</a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="bg-tb-header p-3">
                    <div class="row align-items-center">
                        <span *ngIf="totalElement == 0" class="col-md">{{ 'text_no_results' | translate }}</span>
                        <span *ngIf="totalElement > 0" class="col-md">{{ 'text_number_of_results_displayed' | translate : {from: 1, to: flows.length, total: totalElement} }}</span>
                        <div class="col-md">
                            <div class="d-flex justify-content-end">
                                <div class="d-flex justify-content-end align-items-center">
                                    <span class="mr-2">{{ 'text_number_of_lines' | translate }}</span>
                                    <ng-select [(ngModel)]="maxSize" [searchable]="false" [clearable]="false"
                                        placeholder="..." (change)="loadPage(1)">
                                        <ng-option [value]="10">10</ng-option>
                                        <ng-option [value]="20">20</ng-option>
                                        <ng-option [value]="30">30</ng-option>
                                    </ng-select>
                                </div>
                                <div *ngIf="totalElement > maxSize" class="pagination pages no-border-pagination ml-2">
                                    <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="5"
                                        [rotate]="true" [ellipses]="false" [pageSize]="maxSize"
                                        (pageChange)="loadPage($event)" [boundaryLinks]="true"
                                        aria-label="Default pagination">
                                        <ng-template ngbPaginationFirst>{{ 'text_first' | translate }}</ng-template>
                                        <ng-template ngbPaginationLast>{{ 'text_last' | translate }}</ng-template>
                                        <ng-template ngbPaginationPrevious>&laquo;</ng-template>
                                        <ng-template ngbPaginationNext>&raquo;</ng-template>
                                        <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                                    </ngb-pagination>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    </div>
</section>