
<app-spinner></app-spinner>
<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1 class="m-0 text-dark">Danh sách danh mục dịch vụ</h1>
      </div>
      <!-- /.col -->
      <div class="col-sm-6" >
        <button class="btn btn-primary float-sm-right d-inline-flex" routerLinkActive="router-link-active" [routerLink]="['/console/category-package/create']"
          ><em class="material-icons">add</em>
          <!-- [routerLink]="['/portal/customers/create']" -->
          Thêm mới</button>
      </div>
      <!-- /.col -->
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>

<section class="content">
  <div class="container-fluid">
    <div class="card shadow-none mb-4">
      <div class="card-body">
        <div class="row mb-3">
          <div class="col-lg-3 col-md-5">
            <form   class="input-group border rounded mb-3">
              <div class="input-group-prepend border-0">
                  <button class="btn d-inline-flex pr-2 pl-2" type="submit"><span
                          class="material-icons">search</span></button>
              </div>
              <input type="text" class="form-control border-0"
              placeholder="Nhập tên gói dịch vụ hoặc mô tả..."   [formControl]="searchFields"
                  aria-describedby="basic-addon1" #searchFieldCategoryPackage>
            </form>
            </div>
          <div class="ml-auto ">
            <button class="btn btn-outline-primary  mr-3" (click)="cleanFilter()">
              Đặt lại
            </button>
            <button class="btn btn-primary  " (click)="searchWithFilter()">
              Áp dụng
            </button>
          </div>
        </div>
        <hr>
        <div class="form-group d-flex">
          <div class="col col-md-3">
            <label for="statusGCS">
              Loại gói dịch vụ
            </label>
            <select #categoryPackageTypePicker name="packageTypePicker" id="packageTypePicker"
              (change)="changePackageType($event)" class="form-control">
              <option value="" [selected]="packageType === ''">Tất cả</option>
              <option value="CHUCNANG" [selected]="packageType === 'CHUCNANG'">Chức năng</option>
              <option value="HATANG" [selected]="packageType === 'HATANG'">Hạ Tầng</option>
            </select>
          </div>
          <div class="col col-md-3">
            <label for="statusGCS">
              Trạng thái
            </label>
            <select #categoryPackageStatusPicker name="packageStatusPicker" id="packageStatusPicker"
              (change)="changeStatuspackage($event)" class="form-control">
              <option value="" [selected]="packageStatus === ''">Tất cả</option>
              <option value="ENABLED" [selected]="packageStatus === 'ENABLED'">Đang hoạt động</option>
              <option value="DISABLED" [selected]="packageStatus === 'DISABLED'">Ngưng hoạt động</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div class="card shadow-none mb-4">
      <div class="table-responsive table table-scroll">
        <table class="table main-table border">
          <thead>
            <tr>
              <th>STT</th>
              <th>Tên danh mục</th>
              <th>Loại danh mục</th>
              <th>Thao tác</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of listCategoryPackage; let i = index">
              <td>{{ (page - 1)*10 + i + 1 }}</td>
              <td>
                <a class="nav-link" routerLinkActive="router-link-active" [routerLink]="['/console/category-package/detail/',item.serviceInfoId]"><u>{{ item.name }}</u></a>
              </td>
              <td>{{ PackageType[item.typeService]}}</td>
              <td>
                <div class="dropdown">
                  <a class="btn btn-dropdown dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <em class="material-icons">more_vert</em>
                  </a>

                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                    <a class="dropdown-item nav-material" style="cursor: pointer;" [routerLink]="['/console/category-package/detail/', item.serviceInfoId]"
                      routerLinkActive="router-link-active">
                      <em class="material-icons">info_outline</em>
                      Thông tin chi tiết</a>
                    <a class="dropdown-item nav-material" style="cursor: pointer;" [routerLink]="['/console/category-package/update/', item.serviceInfoId]"
                       routerLinkActive="router-link-active">
                      <em class="material-icons">autorenew</em>
                      Cập nhập</a>
                    <a class="dropdown-item nav-material" style="cursor: pointer;"
                    (click)="deletePackage(item.serviceInfoId)">
                      <em class="material-icons">delete_outline</em>
                      Xóa danh mục</a>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="d-flex">
      <span *ngIf="totalElement == 0" class="flex-grow-1 ml-1">Không tìm thấy kết quả</span>
      <span *ngIf="totalElement > 0" class="flex-grow-1 ml-1">Hiển thị 1 đến {{listCategoryPackage.length}} trong tổng số
        {{totalElement}} kết quả</span>
      <div *ngIf="totalElement > maxsize" class="pagination justify-content-end pages">
        <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="10" [rotate]="true" [ellipses]="false"
          [pageSize]="maxsize" (pageChange)="loadPage($event)" [boundaryLinks]="true" aria-label="Default pagination">
          <ng-template ngbPaginationFirst>Đầu</ng-template>
          <ng-template ngbPaginationLast>Cuối</ng-template>
          <ng-template ngbPaginationPrevious>&laquo;</ng-template>
          <ng-template ngbPaginationNext>&raquo;</ng-template>
          <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
      </div>
    </div>

  </div>
</section>