import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-main',
  templateUrl: './home-main.component.html',
  styleUrls: ['./home-main.component.scss']
})
export class HomeMainComponent implements OnInit {

  footerData = null;

  constructor() { }

  ngOnInit(): void {
  }

  changedMode(x: any) {

  }

  changedLanguage(language) {

  }
}
