<div class="pt-3">
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-7">
                    <div class="card shadow-none">
                        <div class="card-body border-0">
                            <div class="row">
                                <div class="col-md-auto align-self-end">
                                    <label class="text-lg">Đăng ký gói hạ tầng mở rộng</label>
                                </div>
                                <div class="col-md-auto ml-auto">
                                    <button class="btn btn-primary d-inline-flex" (click)="assignPackage()">
                                        <em class="material-icons nav-icon mr-2">done</em>
                                        Xác nhận
                                    </button>
                                </div>
                            </div>
                            <div class="form-group mt-3">
                                <ng-select [items]="packageOptions" [(ngModel)]="selectedPackage" bindLabel="label"
                                    bindValue="id" [searchable]='false' [clearable]='false' (change)="changePackage()">
                                    <ng-template ng-option-tmp let-item="item">
                                        <div class="pt-2">
                                            <label>{{ item.label }} - <span class="text-primary">{{
                                                    formatPrice(item?.info?.packageInfo?.price) }}đ</span></label>
                                            <br>
                                            <label class="more">
                                                <div *ngIf="item.info.listPackageInfrastructure.infrastructures">
                                                    <span
                                                        *ngIf="item?.info?.listPackageInfrastructure?.infrastructures[0]?.unitNumber > 0">Số
                                                        HĐ: <span class="text-primary">{{
                                                            item?.info?.listPackageInfrastructure?.infrastructures[0]?.unitNumber
                                                            }}</span></span>
                                                    <span style="color: #A0AEC0;"
                                                        *ngIf="item?.info?.listPackageInfrastructure?.infrastructures[0]?.unitNumber > 0 && item?.info?.listPackageInfrastructure?.infrastructures[1]?.unitNumber > 0">
                                                        | </span>
                                                    <span
                                                        *ngIf="item?.info?.listPackageInfrastructure?.infrastructures[1]?.unitNumber > 0">Số
                                                        lượt SMS OTP: <span class="text-primary">{{
                                                            item?.info?.listPackageInfrastructure?.infrastructures[1]?.unitNumber
                                                            }}</span></span>
                                                    <span style="color: #A0AEC0;"
                                                        *ngIf="(
                                                        item?.info?.listPackageInfrastructure?.infrastructures[0]?.unitNumber > 0 ||
                                                     item?.info?.listPackageInfrastructure?.infrastructures[1]?.unitNumber > 0
                                                     ) &&
                                                    item?.info?.listPackageInfrastructure?.infrastructures[2]?.unitNumber > 0">
                                                        | </span>
                                                    <span
                                                        *ngIf="item?.info?.listPackageInfrastructure?.infrastructures[2]?.unitNumber > 0">Số
                                                        lượt eKYC: <span class="text-primary">{{
                                                            item?.info?.listPackageInfrastructure?.infrastructures[2]?.unitNumber
                                                            }}</span></span>
                                                    <span style="color: #A0AEC0;"
                                                        *ngIf="(
                                                        item?.info?.listPackageInfrastructure?.infrastructures[0]?.unitNumber > 0 ||
                                                     item?.info?.listPackageInfrastructure?.infrastructures[1]?.unitNumber > 0 ||
                                                     item?.info?.listPackageInfrastructure?.infrastructures[2]?.unitNumber > 0
                                                     ) &&
                                                    item?.info?.listPackageInfrastructure?.infrastructures[3]?.unitNumber > 0">
                                                        | </span>
                                                    <span
                                                        *ngIf="item?.info?.listPackageInfrastructure?.infrastructures[3]?.unitNumber > 0">Thời
                                                        gian lưu trữ: <span class="text-primary">{{
                                                            item?.info?.listPackageInfrastructure?.infrastructures[3]?.unitNumber
                                                            }} tháng</span></span>
                                                    <span style="color: #A0AEC0;"
                                                        *ngIf="(
                                                        item?.info?.listPackageInfrastructure?.infrastructures[0]?.unitNumber > 0 ||
                                                     item?.info?.listPackageInfrastructure?.infrastructures[1]?.unitNumber > 0 ||
                                                     item?.info?.listPackageInfrastructure?.infrastructures[2]?.unitNumber > 0 ||
                                                     item?.info?.listPackageInfrastructure?.infrastructures[3]?.unitNumber > 0
                                                     ) &&
                                                    item?.info?.listPackageInfrastructure?.infrastructures[4]?.unitNumber > 0">
                                                        | </span>
                                                    <span
                                                        *ngIf="item?.info?.listPackageInfrastructure?.infrastructures[4]?.unitNumber > 0">Dung
                                                        lượng: <span class="text-primary">{{
                                                            item?.info?.listPackageInfrastructure?.infrastructures[4]?.unitNumber
                                                            }} GB</span></span>
                                                </div>

                                            </label>
                                        </div>
                                    </ng-template>
                                </ng-select>
                            </div>
                            <div class="general-information">
                                <label class="text-primary">Thông tin chung</label>
                                <hr>
                                <div class="e-block-field row">
                                    <div class="col-md-5">
                                        <label class="e-lb-field">Tên gói dịch vụ</label>
                                    </div>
                                    <div class="col-md-7">
                                        <span class="">{{ packageSpecified?.label }}</span>
                                    </div>
                                </div>
                                <div class="e-block-field row">
                                    <div class="col-md-5">
                                        <label class="e-lb-field">Mô tả</label>
                                    </div>
                                    <div class="col-md-7">
                                        <span class="">{{ packageSpecified?.info?.packageInfo?.description }}</span>
                                    </div>
                                </div>
                                <div class="e-block-field row">
                                    <div class="col-md-5">
                                        <label class="e-lb-field">Giá tạm tính</label>
                                    </div>
                                    <div class="col-md-7">
                                        <span class="">{{ formatPrice(packageSpecified?.info?.packageInfo?.price) }}
                                            đ</span>
                                    </div>
                                </div>
                                <div class="e-block-field row">
                                    <div class="col-md-5">
                                        <label class="e-lb-field">Giá tiền</label>
                                    </div>
                                    <div class="col-md-7">
                                        <span class="">{{ formatPrice(total_price) }} đ</span>
                                    </div>
                                </div>
                            </div>
                            <div class="infra-information">
                                <label class="text-primary">Thông tin hạ tầng</label>
                                <hr>
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr class="table-header">
                                                <th>STT</th>
                                                <th>Thông số</th>
                                                <th>Đơn vị tính</th>
                                                <th>Số lượng</th>
                                                <th>Thành tiền</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="tr-body"
                                                *ngFor="let item of packageSpecified?.info?.listPackageInfrastructure?.infrastructures; let i = index">
                                                <td><span *ngIf="i < 9">0</span>{{ i + 1 }}</td>
                                                <td>{{ item?.name }}</td>
                                                <td>{{ item?.unit }}</td>
                                                <td>
                                                    <div class="input-group border rounded" style="max-width: 120px;">
                                                        <input class="form-control border-0" type="text"
                                                            [ngModelOptions]="{standalone: true}"
                                                            (input)="priceCal(i); totalPrice()"
                                                            style="text-align: right;" placeholder=""
                                                            [(ngModel)]="item.unitNumber">
                                                    </div>
                                                </td>
                                                <td *ngIf="item.param != 'STORAGE_TIME'">{{ formatPrice(item?.price) }}
                                                </td>
                                            </tr>
                                            <tr class="tr-body">
                                                <td></td>
                                                <td>Tổng cộng</td>
                                                <td></td>
                                                <td></td>
                                                <td>{{ formatPrice(total_price) }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="card shadow-none">
                        <div class="card-body border-0">
                            <div class="customer-information">
                                <label class="text-primary">Thông tin khách hàng</label>
                                <hr>
                                <div class="e-block-field row">
                                    <div class="col-md-5">
                                        <label class="e-lb-field">Tên tổ chức</label>
                                    </div>
                                    <div class="col-md-7">
                                        <span class="">{{ userInfo?.name }}</span>
                                    </div>
                                </div>
                                <div class="e-block-field row">
                                    <div class="col-md-5">
                                        <label class="e-lb-field">Mã số thuế</label>
                                    </div>
                                    <div class="col-md-7">
                                        <span class="">{{ userInfo?.taxCode }}</span>
                                    </div>
                                </div>
                                <div class="e-block-field row">
                                    <div class="col-md-5">
                                        <label class="e-lb-field">Email</label>
                                    </div>
                                    <div class="col-md-7">
                                        <span class="">{{ userInfo?.email }}</span>
                                    </div>
                                </div>
                                <div class="e-block-field row">
                                    <div class="col-md-5">
                                        <label class="e-lb-field">Số điện thoại</label>
                                    </div>
                                    <div class="col-md-7">
                                        <span class="">{{ userInfo?.phoneNumber }}</span>
                                    </div>
                                </div>
                                <div class="e-block-field row">
                                    <div class="col-md-5">
                                        <label class="e-lb-field">Tỉnh/Thành phố</label>
                                    </div>
                                    <div class="col-md-7">
                                        <span class="">{{ userInfo?.province}}</span>
                                    </div>
                                </div>
                                <div class="e-block-field row">
                                    <div class="col-md-5">
                                        <label class="e-lb-field">Tài khoản</label>
                                    </div>
                                    <div class="col-md-7">
                                        <span class="">{{ userInfo?.account }}</span>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="infraInfo" class="using-infra-information">
                                <label class="text-primary">Thông tin hạ tầng đang sử dụng</label>
                                <hr>
                                <div class="e-block-field row">
                                    <div class="col-md-5">
                                        <label class="e-lb-field">Số lượng hợp đồng</label>
                                    </div>
                                    <div class="col-md-7">
                                        <span class="">{{ infraInfo?.usedContract }} / {{ infraInfo?.allowedContract }}
                                            <span
                                                class="{{ getStyleAndRate(infraInfo?.usedContract, infraInfo?.allowedContract)[1] }}">({{
                                                getStyleAndRate(infraInfo?.usedContract, infraInfo?.allowedContract)[0]
                                                }}%)</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="e-block-field row">
                                    <div class="col-md-5">
                                        <label class="e-lb-field">Số lượng SMS</label>
                                    </div>
                                    <div class="col-md-7">
                                        <span class="">{{ infraInfo?.usedSMS }} / {{ infraInfo?.allowedSMS }}
                                            <span
                                                class="{{ getStyleAndRate(infraInfo?.usedSMS, infraInfo?.allowedSMS)[1] }}">({{
                                                getStyleAndRate(infraInfo?.usedSMS, infraInfo?.allowedSMS)[0]
                                                }}%)</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="e-block-field row">
                                    <div class="col-md-5">
                                        <label class="e-lb-field">Số lượng SMS OTP</label>
                                    </div>
                                    <div class="col-md-7">
                                        <span class="">{{ infraInfo?.usedSMSOTP }} / {{ infraInfo?.allowedSMSOTP }}
                                            <span
                                                class="{{ getStyleAndRate(infraInfo?.usedSMSOTP, infraInfo?.allowedSMSOTP)[1] }}">({{
                                                getStyleAndRate(infraInfo?.usedSMSOTP, infraInfo?.allowedSMSOTP)[0]
                                                }}%)</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="e-block-field row">
                                    <div class="col-md-5">
                                        <label class="e-lb-field">Số lượng eKYC</label>
                                    </div>
                                    <div class="col-md-7">
                                        <span class="">{{ infraInfo?.usedeKYC }} / {{ infraInfo?.allowedeKYC }}
                                            <span
                                                class="{{ getStyleAndRate(infraInfo?.usedeKYC, infraInfo?.allowedeKYC)[1] }}">({{
                                                getStyleAndRate(infraInfo?.usedeKYC, infraInfo?.allowedeKYC)[0]
                                                }}%)</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="e-block-field row">
                                    <div class="col-md-5">
                                        <label class="e-lb-field">Thời gian lưu trữ (Tháng)</label>
                                    </div>
                                    <div class="col-md-7">
                                        <span class="">{{ infraInfo?.numberOfMonthStorageUsed }} / {{
                                            infraInfo?.numberOfMonthStorage }}
                                            <span
                                                class="{{ getStyleAndRate(infraInfo?.numberOfMonthStorageUsed, infraInfo?.numberOfMonthStorage)[1] }}">({{
                                                getStyleAndRate(infraInfo?.numberOfMonthStorageUsed,
                                                infraInfo?.numberOfMonthStorage)[0] }}%)</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="e-block-field row">
                                    <div class="col-md-5">
                                        <label class="e-lb-field">Dung lượng lưu trữ (GB)</label>
                                    </div>
                                    <div class="col-md-7">
                                        <span class="">{{ infraInfo?.usedData }} / {{ infraInfo?.allowedData }}
                                            <span
                                                class="{{ getStyleAndRate(infraInfo?.usedData, infraInfo?.allowedData)[1] }}">({{
                                                getStyleAndRate(infraInfo?.usedData, infraInfo?.allowedData)[0]
                                                }}%)</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>
</div>