import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-home-header',
  templateUrl: './home-header.component.html',
  styleUrls: ['./home-header.component.scss']
})
export class HomeHeaderComponent implements OnInit {
  @Output() changedMode: EventEmitter<any> = new EventEmitter();
  @Output() changedLanguage: EventEmitter<any> = new EventEmitter();
  
  fragment: string = "";
  homeMode = 0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.fragment = this.route.snapshot.fragment;

    if (this.fragment && this.fragment == "contact") {
      this.changeMode(1);
    }
    if (this.fragment && this.fragment == "pricelist") {
      this.changeMode(2);
    }
    if (this.fragment && this.fragment == "support") {
      this.changeMode(3);
    }
    if (this.fragment && this.fragment == "feature") {
      this.changeMode(4);
    }
    if (this.fragment && this.fragment == "post") {
      this.changeMode(5);
    }
  }

  changeMode(x: any) {
    this.homeMode = x;
    this.changedMode.emit(x);
  }

  changeLanguage(language: any) {
    this.translate.use(language);
    this.changedLanguage.emit(language);
  }
}
