export class ApiMenusItem {
    // role: RoleGroup;
    role: string = "";
    roleName: string = "";
    listApi: ApiItem[] = [];

    constructor(data = null) {
        data = data || {};

        // this.role = new RoleGroup(data.role || {});

        this.role = data.role || "";
        this.roleName = data.roleName || "";

        this.listApi = [];
        if (data.listApi && data.listApi instanceof Array) {
            data.listApi.forEach((res) => {
                this.listApi.push(new ApiItem(res));
            })
        }
    }
}

export class ApiItem {
    apiId: string = '';
    pathName: string = '';
    method: string = '';
    description: string = '';
    createDate: string = '';
    updateDate: string = '';
    roleId: string = '';
    exampleBody: any = '';
    exampleHeader: any = '';
    exampleOutput: any = '';

    constructor(data = null) {
        data = data || {};
        this.apiId = data.apiId || '';
        this.pathName = data.pathName || '';
        this.method = data.method || '';
        this.description = data.description || '';
        this.createDate = data.createDate || '';
        this.updateDate = data.updateDate || '';
        this.roleId = data.roleId || '';
        this.exampleBody = data.exampleBody || '';
        this.exampleHeader = data.exampleHeader || '';
        this.exampleOutput = data.exampleOutput || '';
    }
}

export class RoleGroup {
    uuidRole: string = '';
    name: string = '';
    description: string = '';
    applicationName: string = '';
    roleCode: string = '';
    disable: number;
    partyId: string = '';
    createdBy: string = '';
    createdDate: string = '';
    modifiedDate: string = '';

    constructor(data = null) {
        data = data || {};
        this.uuidRole = data.uuidRole || '';
        this.name = data.name || '';
        this.description = data.description || '';
        this.applicationName = data.applicationName || '';
        this.roleCode = data.roleCode || '';
        this.disable = data.disable;
        this.partyId = data.partyId || '';
        this.createdBy = data.createdBy || '';
        this.modifiedDate = data.modifiedDate || '';
    }
}