export class Param {
    apiId: string = '';
    paramId: string = '';
    paramName: string = '';
    type: string = '';
    description: string = '';
    obligatory: string = '';
    element: string = '';
    addition: ParamAddition;

    constructor(data = null) {
        data = data || {};
        this.apiId = data.apiId || '';
        this.paramId = data.paramId || '';
        this.paramName = data.paramName || '';
        this.type = data.type || '';
        this.description = data.description || '';
        this.obligatory = data.obligatory || '';
        this.element = data.element || '';

        this.addition = new ParamAddition();
    }
}

export class ParamAddition {
    editParamName: boolean = false;
    editType: boolean = false;
    editDescription: boolean = false;
    editObligatory: boolean = false;

    constructor() {
    }
}