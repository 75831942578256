<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-auto">
                <h1 class="m-0 text-dark"><label>Danh sách API Key</label></h1>
            </div>
        </div>
    </div>
</div>

<section class="content">
    <div class="container-fluid">
        <div class="bg-white">
            <section class="p-3">
                <div class="row">
                    <div class="col-lg-3 col-md-5">
                        <form [formGroup]="formFilter" (ngSubmit)="actSearch(searchString)"
                            class="input-group border rounded mb-3">
                            <div class="input-group-prepend border-0">
                                <button class="btn d-inline-flex pr-2 pl-2" type="submit"><span
                                        class="material-icons">search</span></button>
                            </div>
                            <input type="text" class="form-control border-0" formControlName="search"
                                placeholder="Nhập tên tổ chức, email, API Key, tên gói cước" aria-label=""
                                aria-describedby="basic-addon1">
                        </form>
                    </div>
                    <div class="col-md-auto ml-auto">
                        <div class="btn-group">
                            <button
                                class="btn btn-outline-primary dropdown-toggle d-inline-flex align-items-center mr-3"
                                data-toggle="dropdown" id="configTable"><span
                                    class="material-icons mr-1">visibility</span>Hiển thị</button>
                            <div class="dropdown-menu border-0" aria-labelledby="configTable" style="width: 170px;">
                                <div *ngFor="let item of dropdownConfigTable"
                                    class="tcheck-primary d-inline-block px-3">
                                    <input type="checkbox" id="{{ item.id }}" [checked]="configTable[item.id]">
                                    <label for="{{ item.id }}" class="w-100" [class.checked]="configTable[item.id]"
                                        (click)="actConfigTable($event, item.id)">
                                        {{ item.text }}
                                    </label>
                                </div>
                            </div>
                        </div>
                        <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="cleanFilter()"> Đặt
                            lại</button>
                        <button class="btn btn-primary d-inline-flex mr-3" (click)="searchWithFilter()"> Áp
                            dụng</button>
                    </div>
                </div>
                <hr class="my-2">
            </section>
            <section class="px-3 my-2">
                <form [formGroup]="formFilter">
                    <div class="row">
                        <div class="col-md-2">
                            <div class="form-group">
                                <label for="">Ngày bắt đầu</label>
                                <div class="input-group">
                                    <input id="issueDate-api-key-admin" #issueDateForm name="issueDate-api-key-admin"
                                        (dateSelect)="changeDate($event, dateType.ISSUE)" class="form-control"
                                        placeholder="dd/MM/yyyy" ngbDatepicker #issueDateFormPickDate="ngbDatepicker"
                                        [minDate]="{year: 1970, month: 1, day: 1}"
                                        >
                                        <!-- [maxDate]="{year: currentDate.getFullYear(), month: currentDate.getMonth()+1, day: currentDate.getDate()}" -->
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary"
                                            (click)="issueDateFormPickDate.toggle()" type="button"><em
                                                class="fas fa-calendar-alt"></em></button>
                                    </div>
                                </div>
                                <app-show-validate-errors [isSubmit]="isSubmitFilterForm"
                                    [errorMessages]="formFilterValidateMsg.issueDate"
                                    [control]="formFilter.controls.issueDate" [detail]="{ name: 'issueDate' }">
                                </app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label for="">Ngày hết hạn</label>
                                <div class="input-group">
                                    <input id="expriationDate-api-key-admin" #expriationDateForm
                                        name="texpriationDate-api-key-admin"
                                        (dateSelect)="changeDate($event, dateType.EXPRIRED)" class="form-control"
                                        placeholder="dd/MM/yyyy" ngbDatepicker
                                        #expriationDateFormPickDate="ngbDatepicker"
                                        [minDate]="{year: 1970, month: 1, day: 1}"
                                        >
                                        <!-- [maxDate]="{year: currentDate.getFullYear(), month: currentDate.getMonth()+1, day: currentDate.getDate()}" -->
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary"
                                            (click)="expriationDateFormPickDate.toggle()" type="button"><em
                                                class="fas fa-calendar-alt"></em></button>
                                    </div>
                                </div>
                                <app-show-validate-errors [isSubmit]="isSubmitFilterForm"
                                    [errorMessages]="formFilterValidateMsg.expirationDate"
                                    [control]="formFilter.controls.expirationDate"
                                    [detail]="{ name: 'expirationDate' }"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group">
                                <label for="status">Trạng thái</label>
                                <!-- <select formControlName="status" class="form-control">
                                    <option *ngFor="let item of statusOptions" value="{{ item.id }}">{{ item.text }}</option>
                                </select> -->

                                <ng-select2 formControlName="status" [data]="statusOptions" [options]="optionsStatus"
                                    [placeholder]="'Chọn trạng thái'">
                                </ng-select2>
                            </div>
                        </div>
                    </div>
                </form>
            </section>
            <section>
                <div id="table-scroll" class="table table-scroll table-responsive">
                    <table id="main-table" class="table main-table border table-striped">
                        <thead class="bg-light">
                            <tr>
                                <th>STT</th>
                                <th *ngIf="configTable.tenKhachHang">Tên tổ chức</th>
                                <th *ngIf="configTable.email">Email tài khoản tổ chức</th>
                                <th *ngIf="configTable.apiKey">API Key</th>
                                <th *ngIf="configTable.packageName">Gói cước</th>
                                <th *ngIf="configTable.issueDate">Ngày bắt đầu</th>
                                <th *ngIf="configTable.expirationDate">Ngày hết hạn</th>
                                <th *ngIf="configTable.status">Trạng thái</th>
                                <th>Thao tác</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of apiKeys; let i = index">
                                <td>{{ i + 1 }}</td>
                                <td *ngIf="configTable.tenKhachHang">
                                    <a (click)="goToDetailApiKey(item.licenseId)"
                                        class="text-primary text-decoration-underline cursor-pointer">{{ item.tenKhachHang }}</a>
                                </td>
                                <td *ngIf="configTable.email">{{ item.email }}</td>
                                <td *ngIf="configTable.apiKey" class="break-word-all">{{ item.licenseId }}</td>
                                <td *ngIf="configTable.packageName">{{ item.packageName }}</td>
                                <td *ngIf="configTable.issueDate">{{ item.issueDate | date: 'dd/MM/yyyy' }}</td>
                                <td *ngIf="configTable.expirationDate">{{ item.expirationDate | date: 'dd/MM/yyyy' }}</td>
                                <td *ngIf="configTable.status">
                                    <span class="badge {{ constantStatus[item.status || 'DEFAULT'].class }}">{{ constantStatus[item.status || 'DEFAULT'].text }}</span>
                                </td>
                                <td>
                                    <div class="dropdown">
                                        <span class="material-icons cursor-pointer dropdown-toggle"
                                            data-toggle="dropdown" id="settingTable">more_vert</span>
                                        <div class="dropdown-menu border-0" aria-labelledby="dropdownMenuButton">
                                            <a (click)="goToDetailApiKey(item.licenseId)"
                                                class="dropdown-item cursor-pointer d-flex"><span
                                                    class="material-icons mr-3">info_outline</span>Thông tin chi
                                                tiết</a>
                                            <a (click)="sendMail(item.licenseId, item.email, item.username || null)" class="dropdown-item cursor-pointer d-flex"><span
                                                    class="material-icons mr-3">mail_outline</span>Gửi email thông
                                                báo</a>
                                            <a (click)="changeStatus(item.licenseId, item.status)" class="dropdown-item cursor-pointer d-flex">
                                                <span class="material-icons-outlined mr-3" >lock</span>
                                                    {{ item.status ? lockButtonTitle[item.status] : lockButtonTitle[''] }}</a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </div>

        <div class="d-flex justify-content-end mt-3">
            <span *ngIf="totalElement == 0" class="flex-grow-1 ml-1">Không tìm thấy kết quả</span>
            <span *ngIf="totalElement > 0" class="flex-grow-1 ml-1">Hiển thị 1 đến {{ apiKeys.length }} trong tổng số
                {{ totalElement }} kết quả</span>
            <div *ngIf="totalElement > maxSize" class="pagination pages aas-pagination">
                <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="5" [rotate]="true"
                    [ellipses]="false" [pageSize]="maxSize" (pageChange)="loadPage($event)" [boundaryLinks]="true"
                    aria-label="Default pagination">
                    <ng-template ngbPaginationFirst>Đầu</ng-template>
                    <ng-template ngbPaginationLast>Cuối</ng-template>
                    <ng-template ngbPaginationPrevious>&laquo;</ng-template>
                    <ng-template ngbPaginationNext>&raquo;</ng-template>
                    <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                </ngb-pagination>
            </div>
        </div>
    </div>
</section>

<ng-template #sendMailContent let-c="close" let-d="dismiss">
    <div class="modal-header border-0 pb-0 mb-0">
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span class="material-icons">
                cancel
            </span>
        </button>
    </div>
    <div class="modal-body py-0">
        <h5 class="modal-title w-100 text-center mb-3" id="modal-basic-title">Gửi email thông báo</h5>
        <p>Thông tin API Key sẽ được gửi đến email của khách hàng. Vui lòng kiểm tra lại thông tin.</p>
        <form [formGroup]="formSendMail">
            <div class="form-group">
                <label>Email khách hàng <span class="text-danger">*</span>: </label>
                <input formControlName="email" class="form-control" type="text" placeholder="Nhập email">
                <app-show-validate-errors [isSubmit]="isSubmitFormSendMail" [errorMessages]="validateSendMailMsg.email"
                    [control]="formSendMail.controls.email" [detail]="{ name: 'email' }"></app-show-validate-errors>
            </div>
        </form>
    </div>
    <div class="modal-footer border-0 d-flex justify-content-center">
        <button type="button" class="btn btn-outline-primary" (click)="d('Cross click')">Hủy</button>
        <button type="button" class="btn btn-primary" (click)="confirmSendMail()">Xác nhận</button>
    </div>
</ng-template>