import { Privilege } from './privilege.model';

export class PrivilegeType {
    uuidPType: string = '';
    name: string = '';
    partyLevel: number;
    privileges: Array<Privilege> = [];

    constructor(data = null) {
        data = data || {};

        this.uuidPType = data.uuidPType || '';
        this.name = data.name || '';
        this.partyLevel = data.partyLevel;
        this.privileges = [];

        if (data.privileges && data.privileges instanceof Array) {
            data.privileges.forEach(item => {
                this.privileges.push(new Privilege(item));
            });
        }
    }
}