<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-auto">
                <h1 class="m-0 text-dark"><label>Chi tiết bản quyền</label></h1>
            </div>
            <!-- /.col -->
            <div class="col-md-auto ml-auto">
            </div>
            <!-- <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>
<section class="content">
    <div class="container-fluid">
        <div class="content-right-sidebar tab-bar-custom">
            <ul class="nav nav-tabs" id="tabSignAction" role="tablist">
                <li class="nav-item nav-material active">
                    <a class="nav-link d-flex active" id="tabDetail-tab" data-toggle="pill"
                        href="#tabDetail" role="tab" aria-controls="tabDetail" aria-selected="true">
                        Thông tin chi tiết
                    </a>
                </li>
                <li class="nav-item nav-material">
                    <a class="nav-link d-flex" id="tabCustomer-tab" data-toggle="pill" href="#tabCustomer" role="tab"
                        aria-controls="tabCustomer" aria-selected="false">
                        Thông tin khách hàng
                    </a>
                </li>
                <li class="nav-item nav-material">
                    <a class="nav-link d-flex" id="tabBusiness-tab" data-toggle="pill" href="#tabBusiness" role="tab"
                        aria-controls="tabBusiness" aria-selected="false">
                        Thông tin gói dịch vụ
                    </a>
                </li>
                <li class="nav-item nav-material">
                    <a class="nav-link d-flex" id="tabContract-tab" data-toggle="pill" href="#tabContract" role="tab"
                        aria-controls="tabContract" aria-selected="false">
                        Thông tin hợp đồng
                    </a>
                </li>
            </ul>
        </div>
        <div class="tab-content contract-tab-body" id="tabSignAction">
            <!--MARK thông tin chi tiết -->
            <div class="position-relative tab-pane fade show active " id="tabDetail" role="tabpanel" aria-labelledby="tabDetail">
                <app-detail-info></app-detail-info>
            </div>
        
            <!--MARK thông tin khách hàng  -->
            <div class="position-relative tab-pane fade" id="tabCustomer" role="tabpanel" aria-labelledby="tabCustomer">
                <app-customer-info></app-customer-info>
            </div>
             <!--MARK thông tin dịch vụ -->
            <div class="position-relative tab-pane fade  " id="tabBusiness" role="tabpanel" aria-labelledby="tabBusiness">
                <app-business-info></app-business-info>
            </div>
        
            <!--MARK thông hợp đồng  -->
            <div class="position-relative tab-pane fade" id="tabContract" role="tabpanel" aria-labelledby="tabContract">
                <app-contract-info></app-contract-info>
            </div>
        </div>
    </div>
</section>