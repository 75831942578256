import * as moment from "moment";
export class AccountManagerItem {
    userId: string = "";
    ten: string = "";
    username: string = "";
    email: string = "";
    sdt: string = "";
    cmnd: string = "";
    ngaysinh: string = "";
    chucvu: string = "";
    identityLevel: number = 0;
    modified: string = "";
    locked: string = "";
    referenceName: string = "";
    referenceId: string = "";
  
    constructor(obj = null) {
      obj = obj || {};
      this.userId = obj.userId || "";
      this.ten = obj.ten || "";
      this.username = obj.username || "";
      this.email = obj.email || "";
      this.sdt = obj.sdt || "";
      this.cmnd = obj.cmnd || "";
      this.ngaysinh = obj.ngaysinh || "" ;
      this.chucvu = obj.chucvu || "";
      this.identityLevel = obj.identitylevel || "";
      this.modified = obj.modified || "";
      this.locked = obj.locked || "";
      this.referenceName = obj.referenceName || "";
      this.referenceId = obj.referenceId || "";
    }
  }
  