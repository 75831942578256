<app-spinner></app-spinner>
<div class="container-fluid">
    <div class="row login-grid">
        <div class="col-md-7 left-view-bg">
            <video #videobg id="videobg" loop="" muted="" playsinline="" oncanplay="this.play()">
                <source [attr.data-src]="'assets/img/home/vid-bg.mp4'" [attr.video-src]="'assets/img/home/vid-bg.mp4'" src="assets/img/home/vid-bg.mp4" type="video/mp4">
                    <source [attr.data-src]="'assets/img/home/vid-bg.mp4'" [attr.video-src]="'assets/img/home/vid-bg.webm'" src="assets/img/home/vid-bg.mp4" type="video/mp4">
            </video>
            <div class="content-on-video">
                <div class="col-12 text-center">
                    <div class="mb-4">
                        <img src="assets/img/econtract-logo.svg" width="100" height="100" style="background: white; border-radius: 16px">
                        <h1 class="headline-1">NBW eContract</h1>
                        <p class="description-1">Tiên phong nền tảng hợp đồng số</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-5 right-view-login p-4 bg-white">
            <div class="row justify-content-center mt-5 pt-5 pb-2 mb-2">
                <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10">
                    <div class="text-center mb-5">
                        <h2 class="font-weight-bold">Quên mật khẩu</h2>
                    </div>
                    <div class="text-center" >
                        <h6>Vui lòng nhập mã OTP gồm 6 chữ số đã được gửi đến email của bạn</h6>
                    </div>
                    <!-- <div class="text-center mb-2">
                        <h2 class="font-weight-bold">Nhập mã OTP</h2>
                        <h5 *ngIf="type === '' || type === 'CONSUMER'" class="text-muted mt-5">Vui lòng nhập mã OTP gồm
                            6 chữ số đã được gửi đến số điện thoại của bạn</h5>
                        <h5 class="text-muted mt-5">Vui lòng nhập mã OTP gồm 6 chữ số đã
                            được gửi đến email của bạn</h5>
                    </div> -->
                </div>
            </div>

            <div class="row justify-content-center py-4">
                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9">
                    <form [formGroup]="formOTP">
                        <div class="input-group input-custom mb-4">
                            <span class="input-custom-label blue-label">Mã OTP <span class="text-danger">*</span></span>
                            <input appOnlyNumber formControlName="otp" type="text" maxlength="6"
                                class="form-control form-control-lg input-custom-control" (keyup)="verifyOTP($event)"
                                placeholder="Nhập mã OTP" />
                            <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.otp"
                                [control]="otp" [detail]="{ name: 'otp' }">
                            </app-show-validate-errors>
                        </div>
                        <div class="row">
                            <div class="col-12 mb-4">
                                <!-- <button (click)="verifyOTP()" [disabled]="enableBtnSubmit" type="submit"
                                    class="btn btn-lg btn-primary btn-block b-radius-5px">Xác nhận</button> -->
                                <div class="text-center mt-3">
                                    <h6>Chưa nhận được mã OTP đặt lại mật khẩu? <a *ngIf="count === 0" (click)="resendOTP()"
                                        class="cursor-pointer text-primary text-decoration-underline">Gửi lại</a></h6>
                                    <span class="text-muted" *ngIf="count > 0">Có thể gửi lại sau
                                        {{counter$ | async}}s</span>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>