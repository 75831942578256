import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/utils/services/auth.service';
import { EkycService } from 'src/app/utils/services/aas-service/ekyc-service/ekyc.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-idcard',
  templateUrl: './idcard.component.html',
  styleUrls: ['./idcard.component.scss']
})
export class IdcardComponent implements OnInit {

  componentName: string = "Idcard";

  constructor(
    private ekycService: EkycService,
    private authService: AuthService,
    private translate: TranslateService
    ) { }

  ngOnInit(): void {
    this.partyId = this.authService.partyId;
    this.getEkycConfig();
  }
  identityType = "CMND";
  partyId: any;
  ekycConfig: any;

  page = 1;
  maxSize = 10;

  getClassStatus(statusBoolean) {
    if (statusBoolean) return "badge-success-new";
    return "badge-danger-new";
  }

  getStatus(statusBoolean) {
    if (statusBoolean) return this.translate.instant('status_running');
    return this.translate.instant('status_stopped');
  }

  getEkycConfig() {
    this.ekycService.getEkycConfig(this.identityType, this.partyId).subscribe((res: any) => {
      if (res.object && res.object.config && res.object.config instanceof Array){
        this.ekycConfig = res.object.config;
      }
    })
  }

}
