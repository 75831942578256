export class InfoPartyObject {
    created: string = '';
    diaChiTruSoDuong: string = '';
    diaChiTruSoHuyen: string = '';
    diaChiTruSoHuyenId: string = '';
    diaChiTruSoSoNha: string = '';
    diaChiTruSoTinh: string = '';
    diaChiTruSoTinhId: string = '';
    diaChiTruSoXa: string = '';
    diaChiTruSoXaId: string = '';
    disable: any;
    email: string = '';
    maSoThue: string = '';
    modified: string = '';
    nganHangGiaoDich: string = '';
    ngayCapSoDkdn: string = '';
    noiCapSoDkdn: string = '';
    partyId: string = '';
    partyType: string = '';
    sdt: string = '';
    soDkdn: string = '';
    soTaiKhoanGiaoDich: string = '';
    tenRutGon: string = '';
    tenToChuc: string = '';
    userInformations: UserInformations;
    identityLevel: number = 0;

    gpkdFilename: string = '';
    packageId: string = '';
    packageName: string = '';
    rejectReason: string = '';
    subMail: string = '';
    verificationStatus: string = '';
    videocall: string = '';

    approveDate: string = '';
    approverId: string = '';
    approverName: string = '';
    contractVipId: string = '';
    
    packageTempId: string = '';
    referenceId: string = "";
    
    provinceCode: string = "";
    subscriptionCode: string = "";
    subscriptionType: string = "";

    requesterCode: string = "";
    creatorCode: string = "";

    locked: any = null;
    emailNumberSentTimes: number=0;
    phoneNumberNumberSentTimes: number= 0;
    isActiveUpdateEmail: boolean = false;
    isActiveUpdatePhoneNumber: boolean = false;

    constructor(obj = null) {
        obj = obj || {};
        this.created = obj.created || '';
        this.diaChiTruSoDuong = obj.diaChiTruSoDuong || '';
        this.diaChiTruSoHuyen = obj.diaChiTruSoHuyen || '';
        this.diaChiTruSoHuyenId = obj.diaChiTruSoHuyenId || '';
        this.diaChiTruSoSoNha = obj.diaChiTruSoSoNha || '';
        this.diaChiTruSoTinh = obj.diaChiTruSoTinh || '';
        this.diaChiTruSoTinhId = obj.diaChiTruSoTinhId || '';
        this.diaChiTruSoXa = obj.diaChiTruSoXa || '';
        this.diaChiTruSoXaId = obj.diaChiTruSoXaId || '';

        this.disable = obj.disable;
        this.email = obj.email || '';
        this.maSoThue = obj.maSoThue || '';
        this.modified = obj.modified || '';
        this.nganHangGiaoDich = obj.nganHangGiaoDich || '';
        this.ngayCapSoDkdn = obj.ngayCapSoDkdn || '';
        this.noiCapSoDkdn = obj.noiCapSoDkdn || '';
        this.partyId = obj.partyId || '';
        this.partyType = obj.partyType || '';
        this.sdt = obj.sdt || '';
        this.soDkdn = obj.soDkdn || '';
        this.soTaiKhoanGiaoDich = obj.soTaiKhoanGiaoDich || '';
        this.tenRutGon = obj.tenRutGon || '';
        this.tenToChuc = obj.tenToChuc || '';

        this.userInformations = new UserInformations(obj.userInformations);
        
        this.identityLevel = obj.identityLevel;
        this.gpkdFilename = obj.gpkdFilename || '';
        this.packageId = obj.packageId || '';
        this.packageName = obj.packageName || '';
        this.rejectReason = obj.rejectReason || '';
        this.subMail = obj.subMail || '';
        this.verificationStatus = obj.verificationStatus || '';
        this.videocall = obj.videocall || 'N';

        this.approveDate = obj.approveDate || '';
        this.approverId = obj.approverId || '';
        this.approverName = obj.approverName || '';
        this.contractVipId = obj.contractVipId || '';

        this.packageTempId = obj.packageTempId || '';
        this.referenceId = obj.referenceId || "";

        this.provinceCode = obj.provinceCode || "";
        this.subscriptionCode = obj.subscriptionCode || "";
        this.subscriptionType = obj.subscriptionType || "";

        this.locked = obj.locked || null;

        this.requesterCode = obj.requesterCode || "";
        this.creatorCode = obj.creatorCode || "";
        this.phoneNumberNumberSentTimes= obj.phoneNumberNumberSentTimes || 0;
        this.emailNumberSentTimes= obj.emailNumberSentTimes || 0
        this.isActiveUpdateEmail = obj.isActiveUpdateEmail || false
        this.isActiveUpdatePhoneNumber = obj.isActiveUpdatePhoneNumber || false
    }
}

export class UserInformations {
    chucVu: string = '';
    cmnd: string = '';
    created: string = '';
    daiDien: any;
    disable: any;
    duong: string = '';
    email: string = '';
    gioiTinh: string = '';
    gioiTinhId: any = '';
    huyen: string = '';
    huyenId: string = '';
    identityLevel: number = 0;
    modified: string = '';
    ngayCap: string = '';
    ngaySinh: string = '';
    noiCap: string = '';
    partyId: string = '';
    sdt: string = '';
    soNha: string = '';
    ten: string = '';
    tinh: string = '';
    tinhId: string = '';
    userId: string = '';
    xa: string = '';
    xaId: string = '';
    username: string = '';
    domain: string = '';
    locked: any = null;
    validTo: any = "";
    noiDangKy: any = "";
    noiSinh: any = "";
    expiryDate: string = '';

    constructor(obj = null) {
        obj = obj || {};
        this.chucVu = obj.chucVu || '';
        this.cmnd = obj.cmnd || '';
        this.created = obj.created || '';
        this.daiDien = obj.daiDien;
        this.disable = obj.disable;
        this.duong = obj.duong || '';
        this.email = obj.email || '';
        this.gioiTinh = obj.gioiTinh || '';
        this.gioiTinhId = obj.gioiTinhId || '';
        this.huyen = obj.huyen || '';
        this.huyenId = obj.huyenId || '';
        this.identityLevel = obj.identityLevel || '';
        this.modified = obj.modified || '';
        this.ngayCap = obj.ngayCap || '';
        this.ngaySinh = obj.ngaySinh || '';
        this.noiCap = obj.noiCap || '';
        this.partyId = obj.partyId || '';
        this.sdt = obj.sdt || '';
        this.soNha = obj.soNha || '';
        this.ten = obj.ten || '';
        this.tinh = obj.tinh || '';
        this.tinhId = obj.tinhId || '';
        this.userId = obj.userId || '';
        this.xa = obj.xa || '';
        this.xaId = obj.xaId || '';
        this.username = obj.username;
        this.domain = obj.domain || '';
        this.locked = obj.locked || null;
        this.validTo = obj.validTo || "";
        this.noiDangKy = obj.noiDangKy || "";
        this.noiSinh = obj.noiSinh || "";
        this.expiryDate = obj.expiryDate || '';
    }

    get diaChi() {
        var diaChi = "";
        if (this.soNha !== "") {
            diaChi += this.soNha + " ";
        }

        if (this.duong !== "") {
            diaChi += this.duong + ", ";
        }

        if (this.xa !== "") {
            diaChi += this.xa + ", ";
        }

        if (this.huyen !== "") {
            diaChi += this.huyen + ", ";
        }

        if (this.tinh !== "") {
            diaChi += this.tinh + ".";
        }

        return diaChi;
    }
}
