import { MainResponse } from '../../../../main-response.model';
import { TemplateContract } from './template-contract-item.model';


export class ListTemplateContractResponse extends MainResponse {
    data: TemplateContract[];
    constructor(obj = null) {
        super();
        obj = obj || {};
        this.data = [];
        if (obj.data && obj.data instanceof Array) {
            obj.data.forEach((item) => {
                this.data.push(new TemplateContract(item));
            });
        }
    }

}