<div class="bg-white p-3">
    <div class="row">
        <div class="col col-md-3">
            <form class="input-group border rounded mb-3">
                <div class="input-group-prepend border-0">
                    <button class="btn d-inline-flex pr-2 pl-2" type="submit"><span
                            class="material-icons">search</span></button>
                </div>
                <input [(ngModel)]="searchString" [ngModelOptions]="{standalone: true}" type="text"
                    class="form-control border-0" placeholder="Tìm kiếm..." aria-describedby="basic-addon1" #searchField
                    (keydown.enter)="searchWithFilter()">
            </form>
        </div>
        <div class="ml-auto d-flex align-items-center">
            <div class="btn-group">
                <div ngbDropdown container="body" [autoClose]="'outside'" [placement]="'bottom-right'"
                    [display]="'dynamic'">
                    <button ngbDropdownToggle
                        class="btn btn-outline-primary dropdown-toggle d-inline-flex align-items-center mr-3">
                        <span class="material-icons mr-1">visibility</span>Hiển thị
                    </button>
                    <div ngbDropdownMenu>
                        <div ngbDropdownItem *ngFor="let item of listConfigColTable" class="p-0">
                            <div class="tcheck-primary px-3">
                                <input type="checkbox" id="{{ item.name }}" [checked]="configColTable[item.name]">
                                <label for="{{ item.name }}" class="w-100" [class.checked]="configColTable[item.name]"
                                    (click)="actConfigColTableView($event, item.name)">
                                    {{ item.label }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="cleanFilter()"> Đặt
                lại</button>
            <button class="btn btn-primary d-inline-flex mr-3" (click)="searchWithFilter()"> Áp
                dụng</button>
        </div>
    </div>
    <hr>
    <div class="form-group">
        <div class="row">
            <div class="col col-md-2">
                <label for="statusGCS">
                    Loại định danh tài khoản
                </label>
                <ng-select [(ngModel)]="identityLevel" [items]="identityLevelData" bindLabel="label" bindValue="value"
                    placeholder="Chọn loại định danh tài khoản" [clearable]="false">
                </ng-select>
            </div>
            <div class="col col-md-2">
                <label for="statusGCS">
                    Trạng thái
                </label>
                <ng-select [(ngModel)]="locked" [items]="lockedData" bindLabel="label" bindValue="value"
                    placeholder="Trạng thái" [clearable]="false">
                </ng-select>
            </div>
        </div>
    </div>

</div>

<div class="bg-white px-0 pt-2">
    <div id="table-scroll" class="table table-scroll table-responsive mb-0">
        <table id="main-table" class="table main-table border header-table-color mb-0 border-0">
            <thead class="bg-tb-header">
                <tr>
                    <th>STT</th>
                    <th *ngIf="configColTable.tenDoanhNghiep">Tên doanh nghiệp</th>
                    <th *ngIf="configColTable.maSoThue">Mã số thuế</th>
                    <th *ngIf="configColTable.nguoDaiDien">Người đại diện</th>
                    <th *ngIf="configColTable.tenTaiKhoan">Tên tài khoản</th>
                    <th *ngIf="configColTable.loaiDinhDanhTaiKhoan">Loại tài khoản</th>
                    <th *ngIf="configColTable.trangThai">Trạng thái</th>
                    <th *ngIf="configColTable.loiMoi">Nhận lời mời/Thêm mới từ</th>
                    <th>Thao tác</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of accountPersonLst, let i = index">
                    <td>{{ ((page - 1) * maxSize) + i + 1 }}</td>
                    <td *ngIf="configColTable.tenDoanhNghiep">
                        <a class="text-decoration-underline" style="cursor: pointer;"
                            [routerLink]="['/console/account-manager/business/', item.partyId]"
                            routerLinkActive="router-link-active">
                            {{ item.tenToChuc || "" }}
                        </a>
                    </td>
                    <td *ngIf="configColTable.maSoThue">{{ item.maSoThue || "" }}</td>
                    <td *ngIf="configColTable.nguoDaiDien">{{ item.tenDaiDien || "" }}</td>
                    <td *ngIf="configColTable.tenTaiKhoan">{{ item.username || "" }}</td>
                    <td *ngIf="configColTable.loaiDinhDanhTaiKhoan">Tài khoản định danh cấp {{ item.identityLevel || "" }}</td>
                    <td *ngIf="configColTable.trangThai">
                        <span *ngIf="item.locked !== ''" class="badge p-2" [class.badge-success-new]="item.locked === 'N'" [class.badge-danger-new]="item.locked === 'Y'">
                            {{ item.locked == 'Y' ? "Ngưng hoạt động" : "Đang hoạt động" }}
                        </span>
                    </td>
                    <td *ngIf="configColTable.loiMoi">{{ item.referenceName || "" }}</td>

                    <td>
                        <div class="dropdown">
                            <a class="btn" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown"
                                aria-haspopup="true" aria-expanded="false">
                                <em class="material-icons">more_vert</em>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                                <a class="dropdown-item d-inline-flex" style="cursor: pointer;"
                                    [routerLink]="['/console/account-manager/business/', item.partyId]"
                                    routerLinkActive="router-link-active">
                                    <span class="material-icons-outlined mr-2">info</span>
                                    Xem chi tiết
                                </a>
                                <a class="dropdown-item d-inline-flex" style="cursor: pointer;"
                                    (click)="gotoEdit(item.partyId)"
                                    routerLinkActive="router-link-active">
                                    <span class="material-icons mr-2">edit</span>
                                    Chỉnh sửa
                                </a>
                                <a *ngIf="item.locked == 'N'" class="dropdown-item d-inline-flex" style="cursor: pointer;"
                                    (click)="disableAccount(item.partyId)"><em
                                        class="material-icons-outlined mr-2">lock</em>Ngưng hoạt động</a>
                                <a *ngIf="item.locked == 'Y'" class="dropdown-item d-inline-flex" style="cursor: pointer;"
                                    (click)="enableAccount(item.partyId)"><em
                                        class="material-icons-outlined mr-2">lock</em>Kích hoạt tài khoản</a>
                                <a class="dropdown-item d-inline-flex" style="cursor: pointer;"
                                    (click)="reSendEmail(item.partyId)"
                                    ><em
                                        class="material-icons mr-2">{{item.email ? 'email' : 'sms'}}</em>{{item.email ? 'Gửi lại email thông báo tài khoản' : 'Gửi lại SMS thông báo tài khoản'}}</a>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="bg-tb-header p-3">
        <div class="row align-items-center">
            <span *ngIf="totalElement == 0" class="col-md">Không tìm thấy kết quả</span>
            <span *ngIf="totalElement > 0" class="col-md">Hiển thị 1 đến {{accountPersonLst.length}} trong tổng số
                {{totalElement}} kết quả</span>
            <div class="col-md">
                <div class="d-flex justify-content-end">
                    <div *ngIf="totalElement > maxSize" class="d-flex justify-content-end align-items-center">
                        <span class="mr-2">Số dòng hiển thị:</span>
                        <ng-select class="no-bg no-border" [(ngModel)]="maxSize" [searchable]="false" [clearable]="false" placeholder="..." (change)="getListPersonalAcc(1)">
                            <ng-option [value]="10">10</ng-option>
                            <ng-option [value]="20">20</ng-option>
                            <ng-option [value]="30">30</ng-option>
                        </ng-select>
                    </div>
                    <div *ngIf="totalElement > maxSize" class="pagination pages no-border-pagination ml-2">
                        <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="false"
                            [pageSize]="maxSize" (pageChange)="getListPersonalAcc($event)" [boundaryLinks]="true" aria-label="Default pagination">
                            <ng-template ngbPaginationFirst>Đầu</ng-template>
                            <ng-template ngbPaginationLast>Cuối</ng-template>
                            <ng-template ngbPaginationPrevious>&laquo;</ng-template>
                            <ng-template ngbPaginationNext>&raquo;</ng-template>
                            <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                        </ngb-pagination>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</div>

<!-- <div class="d-flex justify-content-end mt-3">
    <span *ngIf="totalElement == 0" class="flex-grow-1 ml-1 mb-3">Không tìm thấy kết quả</span>
    <span *ngIf="totalElement > 0" class="flex-grow-1 ml-1 mb-3">Hiển thị 1 đến {{accountPersonLst.length}} trong
        tổng số
        {{totalElement}} kết quả</span>
    <div *ngIf="totalElement > maxSize" class="pagination pages aas-pagination">
        <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="3" [rotate]="true" [ellipses]="false"
            [pageSize]="maxSize" (pageChange)="getListPersonalAcc($event)" [boundaryLinks]="true"
            aria-label="Default pagination">
            <ng-template ngbPaginationFirst>Đầu</ng-template>
            <ng-template ngbPaginationLast>Cuối</ng-template>
            <ng-template ngbPaginationPrevious>&laquo;</ng-template>
            <ng-template ngbPaginationNext>&raquo;</ng-template>
            <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
    </div>
</div> -->