import { MainResponse } from '../../models/main-response.model';
import { ListCategoryObject } from './list-category-object.model';

export class ListCategoryResponse extends MainResponse {
  constructor(obj = null) {
    super();
    this.init(obj);
    this.object = new ListCategoryObject(obj.object);
  }
}
