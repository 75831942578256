import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { EnterpriseManagerItem } from "src/app/utils/models/aas-models/enterprise-manager/enterprise-manager-item";
import { EnterpriseManagerResponse } from "src/app/utils/models/aas-models/enterprise-manager/enterprise-manager-response";
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { EnterpriseManagerService } from "src/app/utils/services/aas-network/enterprise-manager.service";

@Component({
  selector: 'app-approve-enterprise',
  templateUrl: './approve.component.html',
  styleUrls: ['./approve.component.scss']
})
export class ApproveComponent implements OnInit {
  @ViewChild("searchField") searchField: ElementRef;

  enterprises: Array<EnterpriseManagerItem> = [];
  enterpriseResponse: EnterpriseManagerResponse;
  enterpriseDetail: EnterpriseManagerItem = new EnterpriseManagerItem();
  modalReference: NgbModalRef;

  form: FormGroup;
  idRowActive: string = "";

  page: number = 1;
  maxSize: number = 5;
  propertiesSort: string = "created";
  sort: string = "DESC";
  totalElement: number = 0;
  keySearch = "";
  saveKeySearch = "";
  status: string = "APPROVE";

  fileGPKD: Blob = null;
  showPreviewGPKD: boolean = false;

  constructor(
    private enterpriseService: EnterpriseManagerService,
    private aasAuth: AasAuthService
  ) {}

  ngOnInit(): void {
    this.getListEnterprises();
  }

  /**
   * Getting list document
   */
  getListEnterprises(page = 1) {
    this.enterpriseService
      .getListEnterprises(
        this.status,
        this.page,
        this.maxSize,
        this.propertiesSort,
        this.sort,
        this.keySearch
      )
      .subscribe(
        (res) => {
          this.enterpriseResponse = new EnterpriseManagerResponse(res);
          this.enterprises = this.enterpriseResponse.object.data;
          this.page = this.enterpriseResponse.object.page;
          this.totalElement = this.enterpriseResponse.object.totalElement;
          sessionStorage.setItem("employeeListKeySearch", this.keySearch);
          this.enterprises.length > 0 &&
            this.detailEnterprise(this.enterprises[0]);
        },
        (err) => {
          if (!!err.error.error) {
            console.log(err.error.error[0]);
          }
        }
      );
  }

  /**
   * search employees
   */
  clickSearchBtn() {
    this.page = 1;
    this.saveKeySearch = `${this.saveKeySearch}`.trim();
    this.keySearch = this.saveKeySearch;
    this.getListEnterprises();
  }

  inputKeySearch(e) {
    this.saveKeySearch = e.target.value;
  }

  detailEnterprise(data) {
    this.idRowActive = data.partyId;
    this.enterpriseService.getEnterpriseById(data.partyId).subscribe(
      (res) => {
        console.log(res)
        const jsonRes = JSON.stringify(res);
        const jsonResOb = JSON.parse(jsonRes);
        const data = jsonResOb.object;
        const dataUser = data.userInformations;

        this.enterpriseDetail.created =
          !!data.created &&
          moment(data.created, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY");
        this.enterpriseDetail.approveDate =
          !!data.approveDate &&
          moment(data.approveDate, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY");

        this.enterpriseDetail.approverId = data.approverName;
          
        this.enterpriseDetail.partyId = data.partyId;
        this.enterpriseDetail.tenToChuc = data.tenToChuc;
        this.enterpriseDetail.tenRutGon = data.tenRutGon;
        this.enterpriseDetail.maSoThue = data.maSoThue;
        this.enterpriseDetail.giayPhepKinhDoanh = data.gpkdFilename;
        this.enterpriseDetail.gpkdFilename = data.gpkdFilename;
        this.enterpriseDetail.hoTenDaiDien = dataUser.ten;
        this.enterpriseDetail.email = dataUser.email;
        this.enterpriseDetail.sdt = dataUser.sdt;
        this.enterpriseDetail.ngaySinhDaiDien = dataUser.ngaySinh || "";
        this.enterpriseDetail.gioiTinhDaiDien = dataUser.gioiTinh;
        this.enterpriseDetail.diachiDaiDien =
        (dataUser.soNha ? dataUser.soNha + ", " : "") +
        (dataUser.duong ? dataUser.duong + ", " : "") +
        (dataUser.xa ? dataUser.xa + ", " : "" ) +
        (dataUser.huyen ? dataUser.huyen + ", ": "") +
        (dataUser.tinh || "");
        this.enterpriseDetail.username = dataUser.username || "";
        this.enterpriseDetail.packageName = data.packageName || "";
      },
      (err) => {
        if (!!err.error.error) {
          console.log(err.error.error[0]);
        }
      }
    );
  }

  dowloadFile() {
    this.enterpriseService
    .downLoadGPKD(this.enterpriseDetail.partyId)
    .subscribe();
  }

  actDownloadGPKD(id) {
    this.downloadGPKD(id);
  }

  handleClosePreview() {
    this.showPreviewGPKD = false;
  }

  /**
   * Tai file GPKD
   * @param partyId 
   */
  downloadGPKD(partyId) {
    this.aasAuth.downloadGPKD(partyId).subscribe((res: any) => {
      this.fileGPKD = new Blob([res], { type: 'application/pdf' });
      this.showPreviewGPKD = true;
    }, err => {

    })
  }
}
