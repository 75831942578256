import { MainResponse } from '../../models/main-response.model';
import { TokenItem } from './token-item.model';

export class TokenResponse extends MainResponse {
    constructor(obj = null){
        super();
        obj = obj || {};
        this.init(obj);
        this.object = new TokenItem(obj.object);
    }
}