export const StatusCode = {
    "": "ECT-empty",
    "ECT-00000401": "ECT-00000401",
    "ECT-00000403": "ECT-00000403",
    "IDG-00000500": "IDG-00000500",
    "ECT-00000500": "ECT-00000500",
    "ECT-00000001": "ECT-00000001",
    "ECT-00000002": "ECT-00000002",
    "ECT-00000003": "ECT-00000003",
    "ECT-00000004": "ECT-00000004",
    "ECT-00000005": "ECT-00000005",
    "ECT-00000006": "ECT-00000006",
    "ECT-00000007": "ECT-00000007",
    "ECT-00000008": "ECT-00000008",
    "ECT-00000009": "ECT-00000009",
    "ECT-00000010": "ECT-00000010",
    "ECT-00000011": "ECT-00000011",
    "ECT-00000012": "ECT-00000012",
    "ECT-00000013": "ECT-00000013",
    "ECT-00000014": "ECT-00000014",
    "ECT-00000015": "ECT-00000015",
    "ECT-00000016": "ECT-00000016",
    "ECT-00000017": "ECT-00000017",
    "ECT-00000018": "ECT-00000018",
    "ECT-00000019": "ECT-00000019",
    "ECT-00000020": "ECT-00000020",
    "ECT-00000030": "ECT-00000030",
    "ECT-00003000": "ECT-00003000",
    "ECT-00003001": "ECT-00003001",
    "ECT-00003002": "ECT-00003002",
    "ECT-00003003": "ECT-00003003",
    "ECT-00003004": "ECT-00003004",
    "ECT-00003005": "ECT-00003005",
    "ECT-00001203": "ECT-00001203",
    "ECT-00001204": "ECT-00001204",
    "ECT-00001205": "ECT-00001205",
    "ECT-00001100": "ECT-00001100",
    "ECT-00001101": "ECT-00001101",
    "ECT-00001102": "ECT-00001102",
    "ECT-00001103": "ECT-00001103",
    "ECT-00001104": "ECT-00001104",
    "ECT-00001111": "ECT-00001111",
    "ECT-00001113": "ECT-00001113",
    "ECT-00001200": "ECT-00001200",
    "ECT-00001201": "ECT-00001201",
    "ECT-00001202": "ECT-00001202",
    "ECT-00002000": "ECT-00002000",
    "ECT-00000071": "ECT-00000071",
    "ECT-00000072": "ECT-00000072",
    "ECT-00002403": "ECT-00002403",
    "ECT-00002100": "ECT-00002100",
    "ECT-00002101": "ECT-00002101",
    "ECT-00002102": "ECT-00002102",
    "ECT-00002103": "ECT-00002103",
    "ECT-00002104": "ECT-00002104",
    "ECT-00002105": "ECT-00002105",
    "ECT-00002106": "ECT-00002106",
    "ECT-00002107": "ECT-00002107",
    "ECT-00002200": "ECT-00002200",
    "ECT-00002202": "ECT-00002202",
    "ECT-00002203": "ECT-00002203",
    "ECT-00001123": "ECT-00001123",
    "ECT-00003009": "ECT-00003009",
    "ECT-00001416": "ECT-00001416",
    "ECT-00001208": "ECT-00001208",

    "DUPLICATE_USER_EMAIL": "DUPLICATE_USER_EMAIL",
    "DUPLICATE_USER_PHONE_NUMBER": "DUPLICATE_USER_PHONE_NUMBER",
    "DUPLICATE_USER_NAME": "DUPLICATE_USER_NAME",
    "EMPLOYEE_STILL_ACTIVE": "EMPLOYEE_STILL_ACTIVE",
    "DEPARTMENT_STILL_DISABLE": "DEPARTMENT_STILL_DISABLE",
    "SIGNATURE_NOT_FOUND": "SIGNATURE_NOT_FOUND",
    "SIGNATURE_NOT_VERIFY": "SIGNATURE_NOT_VERIFY",
    "DUPLICATE_TAX_NUMBER": "DUPLICATE_TAX_NUMBER",

    "ECT-00000035": "ECT-00000035",
    "ECT-00000042": "ECT-00000042",
    "INVALID_INPUT_MULTISIGN": "INVALID_INPUT_MULTISIGN",
    "SIGNATURE_EXPIRED": "SIGNATURE_EXPIRED",
    "SERVICE_INFOMATION_USED": "SERVICE_INFOMATION_USED",

    "SCA-10000": "SCA-10000",
    "SCA-10001": "SCA-10001",
    "SCA-10002": "SCA-10002",
    "SCA-10003": "SCA-10003",
    "SCA-10011": "SCA-10011",
    "SCA-11002": "SCA-11002",
    "SCA-11003": "SCA-11003",
    "SCA-12001": "SCA-12001",
    "SCA-13001": "SCA-13001",
    "SCA-13002": "SCA-13002",
    "SCA-13003": "SCA-13003",
    "SCA-14001": "SCA-14001",
    "SCA-14002": "SCA-14002",
    "SCA-14003": "SCA-14003",
    "SCA-15001": "SCA-15001",
    "SCA-16001": "SCA-16001",
    "SCA-30000": "SCA-30000",
    "SCA-30001": "SCA-30001",
    "SCA-30002": "SCA-30002",
    "SCA-30003": "SCA-30003",
    "SCA-30005": "SCA-30005",
    "SCA-30006": "SCA-30006",
    "SCA-31002": "SCA-31002",
    "SCA-32001": "SCA-32001",
    "SCA-50000": "SCA-50000",
    "BRE-00000003":"BRE-00000003",
    "BRE-00000011": "BRE-00000011",

    "OUT_SIGN_TURN": "StatusCode_OUT_SIGN_TURN",
    "SIGNATURE_OTP_INCORRECT": "Bạn đã sử dụng quá số lần xác nhận OTP.<br>Vui lòng thực hiện lại quá trình ký điện tử.",
    "OTP_TIMEOUT": "Mã OTP đã hết hiệu lực. Vui lòng chọn gửi lại mã OTP và nhập mã OTP mới để tiếp tục phiên ký hợp đồng.",
    "OTP_CONFIRMED": "Mã OTP đã xác nhận trước đó!",
    "OTP_INVALID": "Mã OTP không chính xác. Vui lòng thử lại!",
    "TYPE_OTP_SMS_LIMIT": "Bạn đã hết lượt gửi lại OTP!",
    "LIFECYCLE_NOT_ALLOW_V2": "StatusCode_LIFECYCLE_NOT_ALLOW_V2",

    "COE-00000005": "COE-00000005",
    "PACKAGE_USED": "PACKAGE_USED",
    //Thông báo lỗi chức năng Quên Mật Khẩu
    "BRE-00000001": "BRE-00000001",
    "BRE-00000007": "BRE-00000007",
    "BRE-00000009": "BRE-00000009",
    "BRE-00000010": "BRE-00000010",
    "BRE-00000014": "BRE-00000014",
    "BRE-00000021": "BRE-00000021",
    "BRE-00000030": "BRE-00000030",
    "BRE-00000031": "BRE-00000031",
    "BRE-00000037": "BRE-00000037",
    "BRE-00000038": "BRE-00000038",
    "BRE-00000034": "BRE-00000034",
    "BRE-00000002": "BRE-00000002",
    "BRE-00000004": "BRE-00000004",
    "BRE-00000005": "BRE-00000005",
    "BRE-00000006": "BRE-00000006",
    "BRE-00000008": "BRE-00000008",
    "BRE-00000012": "BRE-00000012",
    "BRE-00000013": "BRE-00000013",
    "BRE-00000015": "BRE-00000015",
    "BRE-00000016": "BRE-00000016",
    "BRE-00000017": "BRE-00000017",
    "BRE-00000018": "BRE-00000018",
    "BRE-00000019": "BRE-00000019",
    "BRE-00000020": "BRE-00000020",
    "BRE-00000022": "BRE-00000022",
    "BRE-00000023": "BRE-00000023",
    "BRE-00000024": "BRE-00000024",
    "BRE-00000025": "BRE-00000025",
    "BRE-00000026": "BRE-00000026",
    "BRE-00000027": "BRE-00000027",
    "BRE-00000028": "BRE-00000028",
    "BRE-00000029": "BRE-00000029",
    "BRE-00000032": "BRE-00000032",
    "BRE-00000033": "BRE-00000033",
    "BRE-00000039": "BRE-00000039",
    "BRE-00000040": "BRE-00000040",
    
    "ECT-00001224": "ECT-00001224"
}