<app-spinner></app-spinner>
<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-auto">
                <h1 class="m-0 text-dark title-page"><label>{{ localize.sme_order_detail }}</label></h1>
            </div>
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>

<section class="content">
    <div class="container-fluid">
        <div class="content-right-sidebar tab-bar-custom">
            <ul class="nav nav-tabs" id="tab-request-detail" role="tablist">
                <li class="nav-item nav-material active">
                    <a class="nav-link d-flex align-items-center active" data-toggle="pill" href="#tab1" role="tab"
                        aria-controls="tab1">
                        <span class="ct-icon-font icon-custom_file_bookmark mr-3"></span>
                        {{ localize.order_information }}
                    </a>
                </li>
                <li class="nav-item nav-material active">
                    <a class="nav-link d-flex align-items-center" data-toggle="pill" href="#tab2" role="tab"
                        aria-controls="tab2">
                        <span class="ct-icon-font icon-domain mr-3"></span>
                        {{ localize.company_information }}
                    </a>
                </li>
                <li class="nav-item nav-material active">
                    <a class="nav-link d-flex align-items-center" data-toggle="pill" href="#tab3" role="tab"
                        aria-controls="tab3">
                        <span class="ct-icon-font icon-custom_billing mr-3"></span>
                        {{ localize.pack_of_data_information}}
                    </a>
                </li>
                <li class="nav-item nav-material active">
                    <a class="nav-link d-flex align-items-center" data-toggle="pill" href="#tab4" role="tab"
                        aria-controls="tab4">
                        <span class="material-icons mr-3">group</span>
                        {{ localize.employees_list }}
                    </a>
                </li>
            </ul>
        </div>
        <div class="tab-content contract-tab-body mt-4" id="tab-request-detail">
            <div class="position-relative tab-pane show active fade" id="tab1" role="tabpanel" aria-labelledby="tab1">
                <div class="card shadow-none">
                    <div class="card-body">
                        <div class="card-block">
                            <label class="text-primary font-weight-bold">{{ localize.order_information }}</label>
                            <hr>
                            <div class="row mt-2">
                                <div class="col-md-3">
                                    <label class="label-info">{{ localize.handler }}</label>
                                </div>
                                <div class="col-md">
                                    <span class="info">{{ requestOrder.data.sme?.amName || "" }}</span>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-3">
                                    <label class="label-info">{{ localize.hrm_code }}</label>
                                </div>
                                <div class="col-md">
                                    <span class="info">{{ requestOrder.data.sme?.amCode || "" }}</span>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-3">
                                    <label class="label-info">{{ localize.recieve_date }}</label>
                                </div>
                                <div class="col-md">
                                    <span class="info">{{ requestOrder.createdAt | date: 'dd/MM/yyyy' }}</span>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-3">
                                    <label class="label-info">{{ localize.status }}</label>
                                </div>
                                <div class="col-md">
                                    <span class="badge {{ getStyleStatus(requestOrder.status) }} p-2">{{ getNameStatus(requestOrder.status) }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="position-relative tab-pane fade" id="tab2" role="tabpanel" aria-labelledby="tab2">
                <div class="card shadow-none">
                    <div class="card-body">
                        <div class="card-block">
                            <label class="text-primary font-weight-bold">{{ localize.company_information }}</label>
                            <hr>
                            <div class="row mt-2">
                                <div class="col-md-3">
                                    <label class="label-info">{{ localize.company_name }}</label>
                                </div>
                                <div class="col-md">
                                    <span class="info">{{ requestOrder.smeName || "" }}</span>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-3">
                                    <label class="label-info">{{ localize.tax_number }}</label>
                                </div>
                                <div class="col-md">
                                    <span class="info">{{ requestOrder.taxCode || "" }}</span>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-3">
                                    <label class="label-info">{{ localize.address }}</label>
                                </div>
                                <div class="col-md">
                                    <span class="info">{{ getAddress(requestOrder.data.sme || {}) }}</span>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-3">
                                    <label class="label-info">{{ localize.email }}</label>
                                </div>
                                <div class="col-md">
                                    <span class="info">{{ requestOrder.data.sme?.email || "" }}</span>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-3">
                                    <label class="label-info">{{ localize.phone_number }}</label>
                                </div>
                                <div class="col-md">
                                    <span class="info">{{ requestOrder.data.sme?.phoneNumber || "" }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="card-block mt-3">
                            <label class="text-primary font-weight-bold">{{ localize.representative_information
                                }}</label>
                            <hr>
                            <div class="row mt-2">
                                <div class="col-md-3">
                                    <label class="label-info">{{ localize.representative_name }}</label>
                                </div>
                                <div class="col-md">
                                    <span class="info">{{ requestOrder.data.represent?.name || "" }}</span>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-3">
                                    <label class="label-info">{{ localize.email }}</label>
                                </div>
                                <div class="col-md">
                                    <span class="info">{{ requestOrder.data.represent?.email || "" }}</span>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-3">
                                    <label class="label-info">{{ localize.identityNo }}</label>
                                </div>
                                <div class="col-md">
                                    <span class="info">{{ requestOrder.data.represent?.identityNo || "" }}</span>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-3">
                                    <label class="label-info">{{ localize.day_of_birth }}</label>
                                </div>
                                <div class="col-md">
                                    <span class="info">{{ requestOrder.data.represent?.birthDate || "" }}</span>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-3">
                                    <label class="label-info">{{ localize.address }}</label>
                                </div>
                                <div class="col-md">
                                    <span class="info">{{ requestOrder.data.represent?.position || "" }}</span>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-3">
                                    <label class="label-info">{{ localize.gender }}</label>
                                </div>
                                <div class="col-md">
                                    <span class="info">{{ requestOrder.data.represent?.gender || "" }}</span>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-3">
                                    <label class="label-info">{{ localize.nation }}</label>
                                </div>
                                <div class="col-md">
                                    <span class="info">{{ requestOrder.data.represent?.nationalityName || "" }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="position-relative tab-pane fade" id="tab3" role="tabpanel" aria-labelledby="tab3">
                <div class="card shadow-none">
                    <div class="card-body">
                        <div class="card-block">
                            <label class="text-primary font-weight-bold">{{ localize.pack_of_data_information }}</label>
                            <hr>
                            <div class="row mt-2">
                                <div class="col-md-3">
                                    <label class="label-info">{{ localize.package_code }}</label>
                                </div>
                                <div class="col-md">
                                    <span class="info">{{ requestOrder.data.subscription?.pricingCode }}</span>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-3">
                                    <label class="label-info">{{ localize.package_name }}</label>
                                </div>
                                <div class="col-md">
                                    <span class="info">{{ requestOrder.data.subscription?.pricingName }}</span>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-3">
                                    <label class="label-info">{{ localize.payment_cycle }}</label>
                                </div>
                                <div class="col-md">
                                    <span class="info">{{ getperiodType(requestOrder.data.subscription?.periodType) }}</span>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-3">
                                    <label class="label-info">{{ localize.package_type }}</label>
                                </div>
                                <div class="col-md">
                                    <span class="info">{{ requestOrder.data.subscription?.type === "OFFICIAL" ? localize.official : localize.trial }}</span>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-3">
                                    <label class="label-info">{{ localize.trial_expiration_date }}</label>
                                </div>
                                <div class="col-md">
                                    <span class="info">{{ requestOrder.data.subscription?.trialEndDate }}</span>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-3">
                                    <label class="label-info">{{ localize.use_status }}</label>
                                </div>
                                <div class="col-md">
                                    <span *ngIf="requestOrder.data.subscription?.status === 'ACTIVE'; else deactive" class="badge badge-success-new p-2">{{ localize.active_use }}</span>
                                    <ng-template #deactive>
                                        <span class="badge badge-danger-new p-2">{{ localize.deactive_use }}</span>
                                    </ng-template>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-3">
                                    <label class="label-info">{{ localize.start_use_date }}</label>
                                </div>
                                <div class="col-md">
                                    <span class="info">{{ requestOrder.data.subscription?.startDate }}</span>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-3">
                                    <label class="label-info">{{ localize.payment_cycle_expiration_date }}</label>
                                </div>
                                <div class="col-md">
                                    <span class="info">{{ requestOrder.data.subscription?.endDatePeriod }}</span>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-3">
                                    <label class="label-info">{{ localize.setup_cost }}</label>
                                </div>
                                <div class="col-md">
                                    <span class="info">{{ requestOrder.data.subscription?.unitPrice }} {{ requestOrder.data.subscription?.unitCurrencyName }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="position-relative tab-pane fade" id="tab4" role="tabpanel" aria-labelledby="tab4">
                <div class="card shadow-none">
                    <section class="card-body p-0">
                        <div class="table-responsive">
                            <table class="table table-sort-col mb-0">
                                <thead>
                                    <tr class="bg-tb-header">
                                        <th>{{ localize.index }}</th>
                                        <th>
                                            <span class="d-flex align-self-center align-items-center">
                                                {{ localize.full_name }}
                                            </span>
                                        </th>
                                        <th>
                                            <span class="d-flex align-self-center align-items-center">
                                                {{ localize.identityNo }}
                                            </span>
                                        </th>
                                        <th>
                                            <span class="d-flex align-self-center align-items-center">
                                                {{ localize.email }}
                                            </span>
                                        </th>
                                        <th>
                                            <span class="d-flex align-self-center align-items-center">
                                                {{ localize.phone_number }}
                                            </span>
                                        </th>
                                        <th>
                                            <span class="d-flex align-self-center align-items-center">
                                                {{ localize.create_status }}
                                            </span>
                                        </th>
                                        <th>
                                            <span class="d-flex align-self-center align-items-center">
                                                {{ localize.status }}
                                            </span>
                                        </th>
                                        <th>{{ localize.action }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <!-- <tr *ngFor="let item of listRequest; let i = index">
                                        <td>{{ (page - 1)*maxSize + i + 1 }}</td>
                                        <td [class.d-none]="!configColTable.tenDoanhNghiep"><a class="cursor-pointer text-decoration-underline text-primary" (click)="gotoDetail(item.orderId)">{{ item.smeName || "" }}</a></td>
                                        <td [class.d-none]="!configColTable.maSoThue">{{ item.taxCode || "" }}</td>
                                        <td [class.d-none]="!configColTable.nguoiDaiDien">{{ item.data?.represent?.nationalityName || "" }}</td>
                                        <td [class.d-none]="!configColTable.ngayTiepNhan">{{ item.createdAt | date: 'dd/MM/yyyy' }}</td>
                                        <td [class.d-none]="!configColTable.nguoiXuLy">
                                            <p>{{ item.data?.sme?.amName || "" }}</p>
                                            <span class="badge badge-light text-weight-bold p-2">{{ item.data?.sme?.amCode }}</span>
                                        </td>
                                        <td [class.d-none]="!configColTable.status">
                                            <span class="badge {{ getStyleStatus(item.status) }} p-2">{{ getNameStatus(item.status) }}</span>
                                        </td>
                                        <td></td>
                                    </tr> -->
                                    <tr *ngFor="let item of employees; let i = index">
                                        <td>{{ (page - 1)*maxSize + i + 1 }}</td>
                                        <td>{{ item.name }}</td>
                                        <td>{{ item.identityNo }}</td>
                                        <td>{{ item.email }}</td>
                                        <td>{{ item.phoneNumber }}</td>
                                        <td>{{ item.status }}</td>
                                        <td>{{ item.status }}</td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="bg-tb-header p-3">
                            <div class="row align-items-center">
                                <span *ngIf="totalElement == 0" class="col-md">Không tìm thấy kết quả</span>
                                <span *ngIf="totalElement > 0" class="col-md">Hiển thị 1 đến {{employees.length}} trong tổng số
                                    {{totalElement}} kết quả</span>
                                <div class="col-md">
                                    <div class="d-flex justify-content-end">
                                        <div *ngIf="totalElement > 0" class="d-flex justify-content-end align-items-center">
                                            <span class="mr-2">{{ localize.number_of_records_displayed }}:</span>
                                            <ng-select class="no-bg no-border" [(ngModel)]="maxSize" [searchable]="false" [clearable]="false" placeholder="..." (change)="loadData(1)">
                                                <ng-option [value]="10">10</ng-option>
                                                <ng-option [value]="20">20</ng-option>
                                                <ng-option [value]="30">30</ng-option>
                                            </ng-select>
                                        </div>
                                        <div *ngIf="totalElement > maxSize" class="pagination pages no-border-pagination ml-2">
                                            <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="false"
                                                [pageSize]="maxSize" (pageChange)="loadData($event)" [boundaryLinks]="true" aria-label="Default pagination">
                                                <ng-template ngbPaginationFirst>{{ localize.head }}</ng-template>
                                                <ng-template ngbPaginationLast>{{ localize.last }}</ng-template>
                                                <ng-template ngbPaginationPrevious>&laquo;</ng-template>
                                                <ng-template ngbPaginationNext>&raquo;</ng-template>
                                                <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                                            </ngb-pagination>
                                        </div>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </section> 
                </div>
            </div>
        </div>
    </div>
</section>