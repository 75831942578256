<div class="card shadow-none">
    <div class="card-body">
        <div *ngIf="registerSuccess; else notsuccess">
            <div  class="row">
                <div class="col-md-auto align-self-end">
                    <label class="text-lg">Đăng ký thành công</label>
                </div>
            </div>
            <hr class="my-3">
            <div class="row">
                <div class="col-md-12">
                    <p>Chào Công ty {{ dataForm.tenDoanhNghiep || '' }},</p>
                    <p>Cảm ơn bạn đã đăng ký tham gia sử dụng dịch vụ VNPT eContract. Vui lòng truy cập đường dẫn
                        <a class="text-primary" href="https://{{ dataForm.domain }}.{{ config.DOMAIN_SUB }}" target="_blank">https://{{ dataForm.domain }}.{{ config.DOMAIN_SUB }}</a> để sử dụng dịch vụ.</p>
                </div>
            </div>
        </div>

        <ng-template #notsuccess>
            <div class="row">
                <div class="col-md-auto align-self-end">
                    <label class="text-lg">Xác minh tài khoản</label>
                </div>
            </div>
            <hr class="my-3">
            <h5 class="text-muted mb-5">Vui lòng nhập mã OTP gồm 6 chữ số đã
                được gửi đến email của bạn</h5>
            <div class="row justify-content-center">
                <div class="col-md-12">
                    <form [formGroup]="formOTP">
                        <div class="input-group input-custom mb-5">
                            <span class="input-custom-label"><span class="text-danger">*</span> Mã OTP</span>
                            <input (input)="handleInputOTP($event)" formControlName="otp" type="text"
                                class="form-control form-control-lg input-custom-control"
                                placeholder="Vui lòng mã OTP" />
                            <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.otp"
                                [control]="otp" [detail]="{ name: 'otp' }">
                            </app-show-validate-errors>
                        </div>
                        <div class="row justify-content-center">
                            <div class="col-md-8">
                                <button (click)="verifyOTP()" [disabled]="enableBtnSubmit" type="submit"
                                    class="btn btn-lg btn-primary btn-block b-radius-5px">Hoàn thành</button>
                                <!-- <div class="text-center mt-3">
                                        <span class="text-muted" *ngIf="count > 0">Có thể gửi lại sau
                                            {{counter$ | async}}s</span>
                                        <a *ngIf="count === 0" (click)="resendOTP()"
                                            class="cursor-pointer text-primary text-decoration-underline">Gửi lại OTP</a>
                                    </div> -->
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </ng-template>
    </div>
</div>