import { Component, OnInit, AfterViewInit, Renderer2, ViewChild, ElementRef, Input } from '@angular/core';
import { CategoryPackageItem } from 'src/app/utils/models/aas-models/business-package-manager/business-package-list/category-package-manager/category-package-item.model'
import { ListCategoryPackageResponse } from 'src/app/utils/models/aas-models/business-package-manager/business-package-list/category-package-manager/list-category-package-response.model'
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { AuthService } from 'src/app/utils/services/auth.service';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { Router } from '@angular/router';
import { NgModalComponent } from 'src/app/layout/extensions/ng-modal/ng-modal.component';
import { BusinessPackageService } from 'src/app/utils/services/aas-network/business-package.service'
import {
  map,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap
} from "rxjs/operators";


@Component({
  selector: 'app-list-category-package',
  templateUrl: './list-category-package.component.html',
  styleUrls: ['./list-category-package.component.scss']
})
export class ListCategoryPackageComponent implements OnInit {
  @ViewChild("searchFieldCategoryPackage") searchField: ElementRef;
  @ViewChild('categoryPackageTypePicker') _packageTypePicker: ElementRef;
  @ViewChild('categoryPackageStatusPicker') _packageStatusPicker: ElementRef; 

  saveKeySearch = '';
  keySearch = "";
  packageType = '';
  packageStatus = '';

  packageTypeFilter = '';
  packageStatusFilter = ''
  page = 1;
  maxsize = 10;
  propertiesSort = 'created';
  sort = 'DESC';
  totalElement= 0;

  formSearch: FormGroup;
  formFilter: FormGroup;

  filterDateError = false

  listCategoryPackageRes = new ListCategoryPackageResponse()
  listCategoryPackage : CategoryPackageItem[] = []
  businesPackageItem : CategoryPackageItem

  searchFields: FormControl;
  private results ;

  PackageType = {
    CHUCNANG: 'Chức năng',
    HATANG: 'Hạ tầng'
  }

  status = {
    ENABLED: {
      style: 'badge-success-new',
      label: 'Đang hoạt động',
    },
    DISABLED: {
      style: 'badge-danger-new',
      label: 'Ngưng hoạt động',
    },
  }


  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private renderer2: Renderer2,
    private router: Router,
    private auth: AuthService,
    private aasAuth: AasAuthService,
    private packageService: BusinessPackageService,

  ) { }

  ngOnInit(): void {
    this.makeFormSearch()
    this.packageTypeFilter = this.packageType;
    this.packageStatusFilter = this.packageStatus;
    this.getListBusiness()
    this.searchFields = new FormControl();
    this.results = this.searchFields.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(_ => (this.search(this.searchField.nativeElement.value))),
    ).subscribe();

  }

  //MARK: Khởi tạo form search
  makeFormSearch() {
    this.formSearch = this.fb.group({
      search: new FormControl('')
    });
  }
  // get keySearch() { return this.formSearch.get('search'); }

  //  //MARK: GET session filter
  //  getSessionFilter() {
  //   this.keySearch.setValue(sessionStorage.getItem('aas-packaget-key-search-' ) || '');
  
  // }

  getListBusiness(){
    this.packageService.getListCategoryPackage(this.page, this.maxsize, this.propertiesSort,
      this.sort, this.packageType,this.packageStatus,this.keySearch.normalize('NFC')).subscribe((res)=>{
        this.listCategoryPackageRes = new ListCategoryPackageResponse(res['object']);
        this.listCategoryPackage = this.listCategoryPackageRes.data
        this.totalElement = this.listCategoryPackageRes.totalElement;
        this.page = this.listCategoryPackageRes.page;
        sessionStorage.setItem('CategoryPackageListKeySearch', this.keySearch);
      })

  }

  deletePackage(id){
    this.modal('Bạn có chắc chán xóa gói dịch vụ này?', ()=>{
      this.packageService.deleteCategoryPackage(id).subscribe((res)=>{
        console.log(res);
        this.alert('Xóa dịch vụ thành công!','success', false, '', 'OK', ()=>{
          this.getListBusiness()
        })
      }, (err) => {
        console.log(err);
       this.handleError(err, ResponseMsg.MESSAGES.ALL_IN_ONE)
      })
    })
   
  }

  // actSearch(event) {
  //   this.page = 1;
  //   this.saveKeySearch = this.keySearch.value.trim();
  //   sessionStorage.setItem('aas-package-key-search-' , this.saveKeySearch);
  //   this.getListBusiness();
  // }

  inputKeySearch(e) {
    this.saveKeySearch = e.target.value;
  }

  search(keyWord) {
    this.page = 1;
    this.keySearch = keyWord.trim();
    this.getListBusiness();
  }

  loadPage(page){
    this.page = page;
    this.getListBusiness();
  }

  cleanFilter(){
    this.packageType = '';
    this.packageStatus='';
    this.keySearch = '';
    this.saveKeySearch = this.keySearch
    this.searchFields.reset()

    this.packageTypeFilter = this.packageType;
    this.packageStatusFilter = this.packageStatus;
    this.page = 1;
    this.searchWithFilter();
  }

  //MARK: Tìm kiếm với filter
  searchWithFilter() {
    if (!this.filterDateError) {
      sessionStorage.setItem('CategoryPackageTypeList', this.packageType);
      sessionStorage.setItem('CategoryPackageStatusList', this.packageStatus);

      // Lưu lại để hiển thị
      this.packageStatusFilter = this.packageStatus;
      this.packageTypeFilter = this.packageType;
      this.page = 1;
      this.getListBusiness();
    }
  }

  changeStatuspackage(e){
    this.packageStatus = e.target.value;

  }

  changePackageType(e){
    this.packageType = e.target.value;

  }

  putStatusAcountCustomer(data, status){
    let msg = 'Bạn có chắc chắn muốn chuyển trạng thái cho danh mục dịch vụ này?';
    this.modal(msg, () => {
      this.packageService.updateStatusPackage(data.packInfoId, status).subscribe(
        (res) => {
          console.log(res);
          this.alert('Chuyển trạng thái danh mục dịch vụ thành công!','success', false, '', 'OK', ()=>{
            this.getListBusiness()
          })
        },
        (err) => {
          console.log(err);
         this.handleError(err, ResponseMsg.MESSAGES.ALL_IN_ONE)
        }
      );
    });
  }

  // Confirm modal
  modal(msg, callBack = () => {}) {
    const modalRef = this.modalService.open(NgModalComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.callback = callBack;
  }

   //MARK: Popup thông báo
   alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], 'error');
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, 'error');
    } else {
      this.alert('Lỗi không xác định!', 'error');
    }
  }

}
