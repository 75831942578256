import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { concat, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import {
  ConstSignType,
  InternalDiscussTypes,
  SignFlowTypes,
} from 'src/app/utils/common/constants/sign-form';
import { localize } from 'src/app/utils/localize/localize';
import { InternalDiscuss } from 'src/app/utils/models/aas-models/flow-contract/internal-discuss.model';
import { PartnerDiscuss } from 'src/app/utils/models/aas-models/flow-contract/partner-discuss.model';
import { SignFlow } from 'src/app/utils/models/aas-models/flow-contract/sign-flow.model';
import { ContractNumberConfigService } from 'src/app/utils/services/aas-network/contract-number-config/contract-number-config.service';
import { EmployeeService } from 'src/app/utils/services/aas-network/employee/employee.service';
import { FlowContractService } from 'src/app/utils/services/aas-network/flow-contract/flow-contract.service';
import { PartService } from 'src/app/utils/services/aas-network/part/part.service';
import { TemplateService } from 'src/app/utils/services/aas-network/template.service';
import { AuthService } from 'src/app/utils/services/auth.service';
import { LoadingService } from 'src/app/utils/services/loading.service';

const signFormsApproval = ['USB_TOKEN', 'SIGN_SERVER', 'SMART_CA'];
@Component({
  selector: 'app-contract-flow-add',
  templateUrl: './contract-flow-add.component.html',
  styleUrls: ['./contract-flow-add.component.scss'],
})
export class ContractFlowAddComponent implements OnInit {
  @ViewChild('templateFile') templateFile: ElementRef;

  localize = localize;

  form: FormGroup;
  validateError = {
    name: {
      required: 'ContractFlowAdd_clc_form_name_required',
    },
    status: {
      required: 'ContractFlowAdd_clc_form_status_required',
    },
    file: {
      required: 'ContractFlowAdd_clc_form_file_required',
      pattern: 'ContractFlowAdd_clc_file_invalid_type',
    },
    type: {
      required: 'ContractFlowAdd_clc_form_type_required',
    },
    discuss: {
      required: 'ContractFlowAdd_clc_form_discuss_required',
    },
    description: {},
  };
  isSubmit = false;
  dropListStatus = [
    { value: false, text: 'status_running' },
    { value: true, text: 'status_stopped' },
  ];
  dropListDiscuss = [
    { value: true, text: 'ContractFlowAdd_label_1' },
    { value: false, text: 'ContractFlowAdd_label_2' },
  ];
  dropListType = [
    { value: 'ELECTRONIC', text: 'ContractFlowAdd_label_3' },
    // { value: "PAPER", text: "Hợp đồng giấy"}
  ];
  attachFile: any = null;
  inputFileLabel = 'ContractFlowAdd_ph_file';
  pdfFile = null;
  showFile = false;
  fileType: Array<string> = [
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ];

  internalDiscuss: any[] = [];
  partnerDiscuss: any[] = [];
  signFlow: any[] = [];
  internalDiscussError: any = null;
  partnerDiscussError: any = null;
  signFlowError: any = null;

  /**
   * Thêm luồng thẩm định nội bộ đối với hợp đồng không đàm phán
   */
  internalDiscussTypes = InternalDiscussTypes;
  internalDiscussType = this.internalDiscussTypes.NO_REQUIRE_FLOW;

  // Dữ liệu cho dropList cấu hình số hợp đồng tự động
  dropListContractNumberConfig: Observable<any> = new Observable();
  contractNumberConfigLoading = false;
  contractNumberConfigInput$ = new Subject<string>();
  contractNumberConfigKeySearch = '';
  endDataContractNumberConfig = false;
  contractNumberConfigPage = 1;

  constructor(
    private fb: FormBuilder,
    private partService: PartService,
    private employeeService: EmployeeService,
    private templateService: TemplateService,
    private myAlert: AlertControl,
    private flowContractService: FlowContractService,
    private authService: AuthService,
    private router: Router,
    private translate: TranslateService,
    private loader: LoadingService,
    private contractNumberApi: ContractNumberConfigService
  ) {}

  ngOnInit(): void {
    this.viewDidLoad();
  }

  viewDidLoad() {
    this.makeForm();
    this.internalDiscuss.push(new InternalDiscuss({ sequence: 1 }));
    this.partnerDiscuss.push(new PartnerDiscuss());
    this.signFlow.push(new SignFlow({ signType: 'APPROVAL', delete: false, sequence: 1 }));
    this.lazyLoadContractNumberList();
  }

  makeForm() {
    this.form = this.fb.group({
      name: ['', [Validators.required]],
      disable: [false, [Validators.required]],
      file: [null, [Validators.required]],
      type: ['ELECTRONIC', [Validators.required]],
      discuss: [false, [Validators.required]],
      description: [''],
      sequenceId: [null],
    });
  }

  onAttachFileChanged(event) {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      if (this.fileType.includes(file.type)) {
        this.attachFile = file;
        this.inputFileLabel = file.name;

        this.templateFile.nativeElement.value = '';
        this.form.controls.file.setErrors(null);
      } else {
        this.form.controls.file.setErrors({ pattern: true });
      }
    } else {
      this.attachFile = null;
      this.inputFileLabel = 'ContractFlowAdd_ph_file';
      this.form.controls.file.setErrors({ required: true });
    }
  }

  showFilePreview() {
    if (this.attachFile.type === this.fileType[1]) {
      var data = new FormData();
      data.append('attachFile', this.attachFile);
      this.postConvertToPDF(data);
    } else {
      this.pdfFile = this.attachFile;
      this.showFile = true;
    }
  }

  /**
   * Convert docx sang PDF
   * @param data
   */
  postConvertToPDF(data: FormData) {
    this.templateService.postConvertToPdf(data).subscribe(
      (fileData) => {
        const blob: any = new Blob([fileData], { type: 'application/pdf' });
        this.pdfFile = blob;
        this.showFile = true;
      },
      (err) => {
        this.myAlert.showAlertOnlyNoti(
          this.translate.instant('ContractFlowAdd_convert_pdf_failed'),
          ConstantAlertType.ERROR
        );
      }
    );
  }

  /**
   * Dong man hinh preview file
   * @param data
   */
  onClosePreview(data) {
    this.showFile = false;
    this.pdfFile = null;
  }

  addInternalDiscuss() {
    this.internalDiscuss.push(new InternalDiscuss({ sequence: this.internalDiscuss.length + 1 }));
  }

  removeInternalDiscuss(index) {
    let minSequence = this.internalDiscuss[index].sequence;
    this.internalDiscuss.splice(index, 1);
    this.internalDiscuss.forEach((item) => {
      if (item.sequence >= minSequence) {
        item.sequence = item.sequence - 1;
      }
    });
  }

  addPartnerDiscuss() {
    this.partnerDiscuss.push(new PartnerDiscuss());
  }

  removePartnerDiscuss(index) {
    this.partnerDiscuss.splice(index, 1);
  }

  addSignFlow() {
    if (this.signFlowType === this.signFlowTypes.REQUIRE_FLOW_STAMP) {
      this.addSignFlowStamp();
      return;
    }

    let endItem = this.signFlow[this.signFlow.length - 1];
    let topItems: any[] = this.signFlow.splice(0, this.signFlow.length - 1);
    let newItem = new SignFlow({ signType: 'DRAFT' });
    newItem.sequence = endItem.sequence;
    endItem.sequence++;

    this.signFlow = [...topItems, newItem, endItem];
  }

  removeSignFlow(index) {
    let minSequence = this.signFlow[index].sequence;
    this.signFlow.splice(index, 1);
    this.signFlow.forEach((item) => {
      if (item.sequence >= minSequence) {
        if (this.signFlowType === this.signFlowTypes.REQUIRE_FLOW_STAMP) {
          if (item.signType === ConstSignType.APPROVAL) {
            item.sequence = this.signFlow.length - 1;
          } else if (item.signType === ConstSignType.STAMP) {
            item.sequence = this.signFlow.length;
          } else {
            item.sequence = item.sequence - 1;
          }
        } else {
          if (item.signType === ConstSignType.APPROVAL) {
            item.sequence = this.signFlow.length;
          } else {
            item.sequence = item.sequence - 1;
          }
        }
      }
    });
  }

  cancel() {
    this.router.navigate([ConstantUrl.contract_lifecycle_list]);
  }
  reset() {
    this.form.controls.name.setValue('');
    this.form.controls.disable.setValue(false);
    // this.form.controls.file.setValue(null);
    this.attachFile = null;
    this.inputFileLabel = 'ContractFlowAdd_ph_file';
    this.form.controls.file.setErrors({ required: true });
    this.form.controls.type.setValue('ELECTRONIC');
    this.form.controls.discuss.setValue(false);
    this.form.controls.description.setValue('');
    this.form.controls.sequenceId.setValue(null);
    this.internalDiscussType = this.internalDiscussTypes.NO_REQUIRE_FLOW;
    this.signFlowType = 'REQUIRE_FLOW';
    this.internalDiscuss = [];
    this.partnerDiscuss = [];
    this.signFlow = [];
    // this.internalDiscuss.push(new InternalDiscuss({ sequence: 1 }));
    // this.partnerDiscuss.push(new PartnerDiscuss());
    // this.signFlow.push(new SignFlow({ signType: 'APPROVAL', delete: false, sequence: 1 }));
  }

  save() {
    this.isSubmit = true;
    this.checkValidate();

    console.log(this.internalDiscussError);
    console.log(this.partnerDiscussError);
    console.log(this.signFlowError);

    if (this.form.invalid) return;
    if (this.attachFile === null) return;
    if (this.signFlowError) return;
    if (this.form.value.discuss === true && (this.internalDiscussError || this.partnerDiscussError))
      return;
    if (this.form.value.discuss === false && this.internalDiscussError) return;

    let data = new FormData();
    data.append('name', this.form.value.name || '');
    data.append('description', this.form.value.description || '');
    data.append('file', this.attachFile, this.attachFile.name || '');
    data.append('type', this.form.value.type || '');
    data.append('disable', this.form.value.disable || false);
    data.append('discuss', this.form.value.discuss);
    data.append('internalDiscuss', JSON.stringify(this.getInternalDiscussParam()));
    data.append('partnerDiscuss', JSON.stringify(this.getPartnerDiscussParam()));
    data.append('signFlow', JSON.stringify(this.getSignFlowParam()));
    data.append('signFlowType', this.signFlowType);
    data.append('internalDiscussType', this.internalDiscussType);
    data.append('sequenceId', this.form.value.sequenceId || '');

    let approve_sign_type = false;
    let approve_sign = this.signFlow.find((x: any) => {
      return x.signType === 'APPROVAL';
    });
    if (approve_sign) {
      approve_sign.signForm.forEach((x: any) => {
        if (signFormsApproval.includes(x)) {
          approve_sign_type = true;
        }
      });
    } else {
      approve_sign_type = true;
    }

    let stamp_sign_type = false;
    let stamp_sign = this.signFlow.find((x: any) => {
      return x.signType === 'STAMP';
    });
    if (stamp_sign) {
      stamp_sign.signForm.forEach((x: any) => {
        if (signFormsApproval.includes(x)) {
          stamp_sign_type = true;
        }
      });
    } else {
      stamp_sign_type = true;
    }

    if (approve_sign_type && stamp_sign_type) {
      this.myAlert.showAlert(
        this.translate.instant('ContractFlowAdd_add_flow_contract_confirm'),
        ConstantAlertType.BLANK,
        true,
        '',
        this.translate.instant('btn_confirm'),
        () => {
          this.postAddFlowContract(
            this.authService.internalPartyId || this.authService.partyId,
            data
          );
        },
        '',
        this.translate.instant('btn_cancel')
      );
    } else {
      this.myAlert.showAlert(
        this.translate.instant('ContractFlowAdd_confirm_allow_signform_level1_for_approval'),
        ConstantAlertType.BLANK,
        true,
        '',
        this.translate.instant('btn_save'),
        () => {
          this.myAlert.showAlert(
            this.translate.instant('ContractFlowAdd_add_flow_contract_confirm'),
            ConstantAlertType.BLANK,
            true,
            '',
            this.translate.instant('btn_confirm'),
            () => {
              this.postAddFlowContract(
                this.authService.internalPartyId || this.authService.partyId,
                data
              );
            },
            '',
            this.translate.instant('btn_cancel')
          );
        },
        '',
        this.translate.instant('btn_cancel'),
        null,
        this.translate.instant('btn_confirm')
      );
    }
  }

  getInternalDiscussParam() {
    let res = [];

    if (
      this.form.value.discuss == false &&
      this.internalDiscussType == this.internalDiscussTypes.NO_REQUIRE
    ) {
      return res;
    }

    this.internalDiscuss.forEach((item) => {
      let p = {
        userId: item.userId || '',
        permissions: item.permissions || [],
      };
      if (
        this.form.value.discuss == false &&
        this.internalDiscussType == this.internalDiscussTypes.REQUIRE_FLOW
      ) {
        p['sequence'] = +item.sequence;
      }
      res.push(p);
    });
    return res;
  }

  getPartnerDiscussParam() {
    let res = [];
    this.partnerDiscuss.forEach((item) => {
      res.push({
        userId: item.userId || '',
        permissions: item.permissions || [],
      });
    });
    return res;
  }

  getSignFlowParam() {
    let res = [];
    this.signFlow.forEach((item) => {
      res.push({
        signType: item.signType || '',
        departmentId: item.partyId || '',
        userId: item.userId || '',
        sequence: +item.sequence,
        limitDate: +item.limitDate,
        signForm: item.signForm,
      });
    });

    return res;
  }

  /**
   * Kiem tra du lieu cac bang
   */
  checkValidate() {
    this.internalDiscussError = null;
    this.partnerDiscussError = null;
    this.signFlowError = null;

    if (this.form.value.discuss === true) {
      this.internalDiscuss.forEach((item) => {
        item.checkValidate();
        this.internalDiscussError = { ...this.internalDiscussError, ...item.generalErrorValidate };
      });

      let arr = [];
      this.partnerDiscuss.forEach((item, index) => {
        item.checkValidate();
        arr.push({
          ...item,
          index: index,
        });
        this.partnerDiscussError = { ...this.partnerDiscussError, ...item.generalErrorValidate };
      });
    }

    if (this.form.value.discuss == false) {
      // Nếu luồng không đàm phán
      if (this.internalDiscussType == this.internalDiscussTypes.REQUIRE_FLOW) {
        // Nếu có sử dụng thẩm định nội bộ cho luồng không đàm phán

        /**
         * Kiểm tra valid dữ liệu từng dòng
         */
        let arr = [];
        this.internalDiscuss.forEach((item, index) => {
          item.checkValidate(true);
          arr.push({
            ...item,
            index: index,
          });
          this.internalDiscussError = {
            ...this.internalDiscussError,
            ...item.generalErrorValidate,
          };
        });

        if (this.internalDiscuss.length < 0) {
          // Thêm lỗi không có người có quyền đồng ý thẩm định
          let err = { requireAcceptPermission: true };
          this.internalDiscussError = { ...this.internalDiscussError, ...err };
        }
        let ok = this.internalDiscuss.findIndex((x) => {
          return x.per_action['INTERNAL_ACCEPTABLE'] == true;
        });
        if (ok < 0) {
          // Thêm lỗi không có người có quyền đồng ý thẩm định
          let err = { requireAcceptPermission: true };
          this.internalDiscussError = { ...this.internalDiscussError, ...err };
        }

        /**
         * Kiểm tra valid dữ liệu sequence từng dòng
         */
        arr.sort((a, b) => {
          return a.sequence - b.sequence;
        });
        for (let i = 0; i < arr.length; i++) {
          if (/[^0-9]*/g.test(arr[i].sequence)) {
            let s = Number(arr[i].sequence);
            if (s > arr.length) {
              this.internalDiscuss[arr[i].index].setError({ sequence: { max: true } });
              this.internalDiscuss[arr[i].index].setGeneralError({ maxSequence: true });
            }

            if (i - 1 >= 0 && /[^0-9]*/g.test(arr[i - 1].sequence)) {
              let prev_s = Number(arr[i - 1].sequence);
              if (s === prev_s) {
                this.internalDiscuss[arr[i].index].setError({ sequence: { unique: true } });
                this.internalDiscuss[arr[i].index].setGeneralError({ uniqueSequence: true });
              }
            }
          }
          this.internalDiscussError = {
            ...this.internalDiscussError,
            ...this.internalDiscuss[arr[i].index].generalErrorValidate,
          };
        }
      }
      /**
       * Kiểm tra thẩm định đã có người có quyền "đồng ý/ từ chối" chưa
       */
      if (this.internalDiscussType != this.internalDiscussTypes.NO_REQUIRE) {
        /**
         * Kiểm tra valid dữ liệu từng dòng
         */
        let arr = [];
        this.internalDiscuss.forEach((item, index) => {
          let isRequireFlow = this.internalDiscussType === this.internalDiscussTypes.REQUIRE_FLOW ? true : false;
          item.checkValidate(isRequireFlow);
          arr.push({
            ...item,
            index: index,
          });
          this.internalDiscussError = {
            ...this.internalDiscussError,
            ...item.generalErrorValidate,
          };
        });

        if (this.internalDiscuss.length < 0) {
          // Thêm lỗi không có người có quyền đồng ý thẩm định
          let err = { requireAcceptPermission: true };
          this.internalDiscussError = { ...this.internalDiscussError, ...err };
        }
        let ok = this.internalDiscuss.findIndex((x) => {
          return x.per_action['INTERNAL_ACCEPTABLE'] == true;
        });
        if (ok < 0) {
          // Thêm lỗi không có người có quyền đồng ý thẩm định
          let err = { requireAcceptPermission: true };
          this.internalDiscussError = { ...this.internalDiscussError, ...err };
        }
      }
    }

    let arr = [];
    this.signFlow.forEach((item, index) => {
      item.checkValidate();
      arr.push({
        ...item,
        index: index,
      });
      this.signFlowError = { ...this.signFlowError, ...item.generalErrorValidate };
    });

    arr.sort((a, b) => {
      return a.sequence - b.sequence;
    });
    for (let i = 0; i < arr.length; i++) {
      if (/[^0-9]*/g.test(arr[i].sequence)) {
        let s = Number(arr[i].sequence);
        if (s > arr.length) {
          this.signFlow[arr[i].index].setError({ sequence: { max: true } });
          this.signFlow[arr[i].index].setGeneralError({ maxSequence: true });
        }

        if (this.signFlowType === this.signFlowTypes.REQUIRE_FLOW_STAMP) {
          if (s === arr.length && arr[i].signType !== ConstSignType.STAMP) {
            this.signFlow[arr[i].index].setError({ sequence: { unique: true } });
            this.signFlow[arr[i].index].setGeneralError({ uniqueSequence: true });
          }
          if (s === arr.length - 1 && arr[i].signType !== ConstSignType.APPROVAL) {
            this.signFlow[arr[i].index].setError({ sequence: { unique: true } });
            this.signFlow[arr[i].index].setGeneralError({ uniqueSequence: true });
          }
        } else {
          if (s === arr.length && arr[i].signType !== ConstSignType.APPROVAL) {
            this.signFlow[arr[i].index].setError({ sequence: { unique: true } });
            this.signFlow[arr[i].index].setGeneralError({ uniqueSequence: true });
          }
        }

        if (i - 1 >= 0 && /[^0-9]*/g.test(arr[i - 1].sequence)) {
          let prev_s = Number(arr[i - 1].sequence);
          if (s === prev_s) {
            if (
              arr[i].signType === ConstSignType.APPROVAL ||
              arr[i].signType === ConstSignType.STAMP
            ) {
              this.signFlow[arr[i - 1].index].setError({ sequence: { unique: true } });
              this.signFlow[arr[i - 1].index].setGeneralError({ uniqueSequence: true });
            } else {
              this.signFlow[arr[i].index].setError({ sequence: { unique: true } });
              this.signFlow[arr[i].index].setGeneralError({ uniqueSequence: true });
            }
          }
        }
      }
      this.signFlowError = {
        ...this.signFlowError,
        ...this.signFlow[arr[i].index].generalErrorValidate,
      };
    }

    this.internalDiscussError && Object.keys(this.internalDiscussError).length === 0
      ? (this.internalDiscussError = null)
      : this.internalDiscussError;
    this.partnerDiscussError && Object.keys(this.partnerDiscussError).length === 0
      ? (this.partnerDiscussError = null)
      : this.partnerDiscussError;
    this.signFlowError && Object.keys(this.signFlowError).length === 0
      ? (this.signFlowError = null)
      : this.signFlowError;
  }

  /**
   * Gui request them moi luong hop dong
   * @param userId
   * @param data
   */
  postAddFlowContract(userId, data: FormData) {
    this.flowContractService.postAddFlowContract(userId, data).subscribe(
      (res: any) => {
        this.myAlert.showAlertOnlyNoti(
          this.translate.instant('ContractFlowAdd_add_flow_contract_success'),
          ConstantAlertType.SUCCESS,
          '',
          this.translate.instant('btn_close')
        );
        this.router.navigate([ConstantUrl.contract_lifecycle_list]);
      },
      (err) => {
        this.myAlert.showErrorHandled(err);
      }
    );
  }

  //UPDATE Lan 1
  signFlowType = 'REQUIRE_FLOW';
  signFlowTypes = SignFlowTypes;
  chooseSignFlowType(e) {
    console.log(this.signFlowType);
    if (this.signFlowType === this.signFlowTypes.REQUIRE_FLOW_STAMP) {
      let endItem = this.signFlow[this.signFlow.length - 1];
      let newItem = new SignFlow({ signType: ConstSignType.STAMP });
      newItem.sequence = endItem.sequence + 1;

      this.signFlow = [...this.signFlow, newItem];
    } else {
      let stampItem = this.signFlow.findIndex((x) => {
        return x.signType === ConstSignType.STAMP;
      });
      if (stampItem >= 0) {
        this.signFlow.splice(stampItem, 1);
      }
    }
  }

  addSignFlowStamp() {
    let endIndexItem = this.signFlow.length - 1;
    let newItem = new SignFlow({ signType: ConstSignType.DRAFT });
    this.signFlow[endIndexItem].sequence++;
    this.signFlow[endIndexItem - 1].sequence++;
    newItem.sequence = this.signFlow[endIndexItem - 1].sequence - 1;

    this.signFlow.splice(this.signFlow.length - 2, 0, newItem);
  }

  /**
   * MARK: THẨM ĐỊNH HỢP ĐỒNG TRƯỚC KÝ
   */

  /**
   * Chọn loại thẩm định
   * @param e
   */
  chooseInternalDiscussType(e) {
    console.log(e);
  }

  /**
   * Check điều kiện hiển thị bản nhân sự thẩm định
   */
  get showTableInternalDiscuss() {
    return (
      this.internalDiscussType !== this.internalDiscussTypes.NO_REQUIRE ||
      this.form.value.discuss === true
    );
  }

  // MARK: Số hợp đồng tự động

  /**
   * Lazy load dữ liệu dropList Cấu hình số hợp đồng tự động
   */
  lazyLoadContractNumberList() {
    this.dropListContractNumberConfig = concat(
      this.contractNumberConfigInput$.pipe(
        startWith(''),
        debounceTime(400),
        distinctUntilChanged(),
        tap(() => {
          this.contractNumberConfigLoading = true;
          this.loader.disabledLoader(this.contractNumberConfigLoading);
        }),
        switchMap((term) => {
          this.contractNumberConfigPage = 1;
          this.endDataContractNumberConfig = false;
          this.contractNumberConfigKeySearch = term;
          let res = this.contractNumberApi
            .getListContractNumberConfig(1, 10, term || '', '', '', '')
            .pipe(
              map((res: any) => {
                console.log(res?.object?.data);
                return res?.object?.data;
              }),
              catchError(() => of([])), // empty list on error
              tap(() => {
                this.contractNumberConfigLoading = false;
                this.loader.disabledLoader(this.contractNumberConfigLoading);
              })
            );
          return res;
        })
      )
    );
  }

  /**
   * Thêm dữ liệu vào droplist data
   * @param data
   * @param param
   * @param key
   * @returns
   */
  addToDropList(data = [], param, key = 'id') {
    key = key || 'id';
    if (param === null) return data;
    let index = data.findIndex((item) => {
      return param[key] === item[key];
    });
    if (index >= 0) return data;
    return [...data, param];
  }

  /**
   * Bắt sự kiện lăn đến cuối trang droplist
   * @param e
   */
  onScrollToEndNgSelectContractNumber(e) {
    if (this.endDataContractNumberConfig) return;

    var nextPage = this.contractNumberConfigPage + 1;
    this.contractNumberConfigPage = nextPage;
    this.loadMoreContractNumberConfig(this.contractNumberConfigPage);
  }

  /**
   * Tải thêm dữ liệu dropList cấu hình số hợp đồng tự động
   * @param page
   */
  loadMoreContractNumberConfig(page) {
    this.contractNumberConfigLoading = true;
    this.loader.disabledLoader(this.contractNumberConfigLoading);
    this.contractNumberApi
      .getListContractNumberConfig(1, 10, this.contractNumberConfigKeySearch || '', '', '', '')
      .subscribe(
        (res: any) => {
          console.log(res);
          if (res?.object?.data.length <= 0) {
            this.endDataContractNumberConfig = true;
          }

          this.dropListContractNumberConfig.subscribe((value) => {
            this.dropListContractNumberConfig = of(value.concat(res?.object?.data || []));
          });

          this.contractNumberConfigLoading = false;
          this.loader.disabledLoader(this.contractNumberConfigLoading);
        },
        (err) => {
          this.contractNumberConfigLoading = false;
          this.loader.disabledLoader(this.contractNumberConfigLoading);
        }
      );
  }
}
