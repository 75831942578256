import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ValidatorsExtension } from 'src/app/utils/common/validators-extension';
import { REGEXP_EMAIL, REGEXP_MST, REGEXP_PASSWORD, REGEXP_USERNAME } from 'src/app/utils/common/regexp';
import { SIGN_ON_BUSINESS } from 'src/app/utils/common/constant-vaildate-msg';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { Config } from 'src/app/config';
import { BehaviorSubject } from 'rxjs';
import { UserTypeConstant } from 'src/app/utils/common/constant';
import { TemplateService } from 'src/app/utils/services/aas-network/template.service';

@Component({
  selector: 'app-signup-v2-step-two',
  templateUrl: './signup-v2-step-two.component.html',
  styleUrls: ['./signup-v2-step-two.component.scss']
})
export class SignupV2StepTwoComponent implements OnInit {
  @Output('next') next: EventEmitter<any> = new EventEmitter<any>();
  @Output('back') back = new EventEmitter<any>();
  @Input('userType') userType: string;
  constantUserType = UserTypeConstant;

  customerType = this.activedRoute.snapshot.queryParamMap.get('userType') || '';
  email = this.activedRoute.snapshot.queryParamMap.get('email') || '';

  notBack = false;

  dieuKhoan: any = "";

  constructor(
    private fb: FormBuilder,
    private activedRoute: ActivatedRoute,
    private templateSv: TemplateService
  ) { }

  ngOnInit(): void {
    if (this.customerType && this.email) {
      this.notBack = true;
    }

    this.getDieuKhoan();
  }

  /**
   * Handle su kien next tu viewchile
   * @param $event 
   */
  handleNext($event) {
    var param = $event;
    param['userType'] = this.userType;

    this.next.emit(param);
  }

  /**
   * Handle su kien back tu viewchile
   * @param $event 
   */
  handleBack($event) {
    this.back.emit($event);
  }

  /**
   * Kierm tra user type
   * @param type 
   * @param str 
   */
  isUserType(type: string, str) {
    return type == str;
  }

  getDieuKhoan() {
    this.templateSv.getDieuKhoan().subscribe((res: any) => {
      res.text().then((result) => {
        this.dieuKhoan = result
      }).catch((err) => {
      });
    }, err => {
      console.log(err);
    })
  }

}
