export enum LocalStorageKey {
    'userType' = "_uType",
    'queryParam' = "_qrParam",
}

export enum QueryParamKey {
    "CONTRACTGROUPID" = "contractGroupId",
    "DOCUMENTID" = "documentId",
    "ACTION" = "action",
    "CLIENTID" = "clientId",
    "CLIENT_SECRET" = "clientSecret",
    "LOGIN_TYPE" = "loginType"
}

export enum LoginType {
    "NORMAL" = "NORMAL",
    "OTP" = "OTP"
}