import { HttpClient, HttpHeaders } from "@angular/common/http";
import {
    Component,
    OnInit,
    HostListener,
    AfterViewInit,
    ViewChild,
    ElementRef,
} from "@angular/core";
import { GlobalConfig } from "src/app/utils/common/global-config/global-config";
import { Router, ActivatedRoute } from "@angular/router";
import {
    FormGroup,
    FormBuilder,
    Validators,
    FormControl,
} from "@angular/forms";
import { Config } from "src/app/config";
import { ConstantUrl } from "src/app/utils/common/constant-url";
import { AlertControl } from "src/app/utils/alert/alert-control";
import { ConstantAlertType } from "src/app/utils/common/constant-alert-type";
import { REGEXP_EMAIL, REGEXP_PHONE_NUMBER } from "./../../utils/common/regexp";
import { API } from "../../../app/utils/services/api";

const GET_DATA_LDP = "/landingpage-service/api/Catalog/get-data";

declare var $: any;
@Component({
    selector: "app-home-v3",
    templateUrl: "./home-v3.component.html",
    styleUrls: ["./home-v3.component.scss"],
})
export class HomeComponentV3 implements OnInit, AfterViewInit {
    @ViewChild("videobg") videobg: ElementRef;
    isSubmit = false;

    constantUrl = ConstantUrl;

    formTrial: FormGroup;
    ValidMsg = {
        email: {
            required: "Vui lòng nhập email!",
            email: "Email không đúng định dạng!",
            pattern: "Email không đúng định dạng!",
            unique: "Email tài khoản đã tồn tại!",
        },
    };
    listCauHoi = {
        title: "Câu hỏi thường gặp",
        subTitle: "Câu hỏi thường gặp",
        avatar: "https://storage-econtract.vnpt.vn/landingpage-dev/2021-08-26/bg-cau-hoi.png",
        description: "Câu hỏi thường gặp",
        danhSach: [
            {
                title: "Hợp đồng điện tử là gì?",
                subTitle: "Hợp đồng điện tử là gì?",
                avatar: "https://storage-econtract.vnpt.vn/landingpage-dev/2021-08-26/cau-hoi.png",
                description:
                    "Theo điều 33 của Luật giao dịch điện tử số 51/2005/QH11 ngày 29/11/2005\n\nĐiều 33: Hợp đồng điện tử\nHợp đồng điện tử là hợp đồng được thiết lập dưới dạng thông điệp dữ liệu theo quy định của Luật này.\n\nĐiều 34: Thừa nhận giá trị pháp lý của hợp đồng điện tử\nGiá trị pháp lý của hợp đồng điện tử không thể bị phủ nhận chỉ vì hợp đồng đó được thể hiện dưới dạng thông điệp dữ liệu.",
            },
            {
                title: "Chữ ký điện tử là gì?",
                subTitle: "Chữ ký điện tử là gì?",
                avatar: "https://storage-econtract.vnpt.vn/landingpage-dev/2021-08-26/cau-hoi.png",
                description:
                    "Theo điều 33 của Luật giao dịch điện tử số 51/2005/QH11 ngày 29/11/2005\n\nĐiều 33: Hợp đồng điện tử\nHợp đồng điện tử là hợp đồng được thiết lập dưới dạng thông điệp dữ liệu theo quy định của Luật này.\n\nĐiều 34: Thừa nhận giá trị pháp lý của hợp đồng điện tử\nGiá trị pháp lý của hợp đồng điện tử không thể bị phủ nhận chỉ vì hợp đồng đó được thể hiện dưới dạng thông điệp dữ liệu.",
            },
            {
                title: "Cơ sở pháp lý của hợp đồng điện tử?",
                subTitle: "Cơ sở pháp lý của hợp đồng điện tử?",
                avatar: "https://storage-econtract.vnpt.vn/landingpage-dev/2021-08-26/cau-hoi.png",
                description:
                    "Chữ ký số (digital signature) là một dạng chữ ký điện tử được tạo ra bằng sự biến đổi một thông điệp dữ liệu sử dụng hệ thống mật mã không đối xứng",
            },
            {
                title: "Chữ ký số là gì?",
                subTitle: "Chữ ký số là gì?",
                avatar: "https://storage-econtract.vnpt.vn/landingpage-dev/2021-08-26/cau-hoi.png",
                description:
                    "Chữ ký số (digital signature) là một dạng chữ ký điện tử được tạo ra bằng sự biến đổi một thông điệp dữ liệu sử dụng hệ thống mật mã không đối xứng",
            },
            {
                title: "Giá trị pháp lý của chữ ký điện tử như thế nào?",
                subTitle: "Giá trị pháp lý của chữ ký điện tử như thế nào?",
                avatar: "https://storage-econtract.vnpt.vn/landingpage-dev/2021-08-26/cau-hoi.png",
                description:
                    "Chữ ký số (digital signature) là một dạng chữ ký điện tử được tạo ra bằng sự biến đổi một thông điệp dữ liệu sử dụng hệ thống mật mã không đối xứng",
            },
            {
                title: "Làm thế nào để ký điện tử?",
                subTitle: "Làm thế nào để ký điện tử?",
                avatar: "https://storage-econtract.vnpt.vn/landingpage-dev/2021-08-26/cau-hoi.png",
                description:
                    "Chữ ký số (digital signature) là một dạng chữ ký điện tử được tạo ra bằng sự biến đổi một thông điệp dữ liệu sử dụng hệ thống mật mã không đối xứng",
            },
            {
                title: "Giá trị pháp lý của chữ ký số như thế nào?",
                subTitle: "Giá trị pháp lý của chữ ký số như thế nào?",
                avatar: "https://storage-econtract.vnpt.vn/landingpage-dev/2021-08-26/cau-hoi.png",
                description:
                    "Chữ ký số (digital signature) là một dạng chữ ký điện tử được tạo ra bằng sự biến đổi một thông điệp dữ liệu sử dụng hệ thống mật mã không đối xứng",
            },
            {
                title: "Làm thế nào để ký số?",
                subTitle: "Làm thế nào để ký số?",
                avatar: "https://storage-econtract.vnpt.vn/landingpage-dev/2021-08-26/cau-hoi.png",
                description:
                    "Chữ ký số (digital signature) là một dạng chữ ký điện tử được tạo ra bằng sự biến đổi một thông điệp dữ liệu sử dụng hệ thống mật mã không đối xứng",
            },
        ],
    };
    listCauHoiLeft = [];
    listCauHoiRight = [];
    dataLocal = {
        banner: {
            title: "VNPT CONTRACT",
            subTitle:
                "Quản lý hợp đồng, ký kết hợp đồng điện tử cho các bên tham gia ở bất cứ đâu tại bất cứ thời điểm nào",
            avatar: "../../img/home/v3/Image/banner_background.png",
            description:
                "../../../assets/img/home/v3/Image/eSolution_1080p.mp4",
        },
        gioiThieu: {
            title: "Giới thiệu",
            subTitle:
                "VNPT eContract - Hợp đồng không giấy tờ, dành cho tất cả mọi người",
            avatar: "../../../assets/img/home/v3/Image/video_gioithieu_thumbnail.png",
            description:
                "../../../assets/img/home/v3/Image/eSolution_1080p.mp4",
            giaiPhap: [
                {
                    title: "Cá nhân",
                    subTitle: "Cá nhân",
                    avatar: "../../../assets/img/home/v3/icon/canhan_icon.png",
                    description:
                        "Cá nhân không có chứng thư số nhưng có nhu cầu về hợp đồng điện tử có thể thực hiện ký kết hợp đồng",
                },
                {
                    title: "Tổ chức dịch vụ",
                    subTitle: "Tổ chức dịch vụ",
                    avatar: "../../../assets/img/home/v3/icon/tochucdichvu_icon.png",
                    description:
                        "Tổ chức của bạn có thể đăng ký dịch vụ và sử dụng ngay trên hệ thống eContract.",
                },
                {
                    title: "Tổ chức giải pháp",
                    subTitle: "Tổ chức giải pháp",
                    avatar: "../../../assets/img/home/v3/icon/tochucgiaiphap_icon.png",
                    description:
                        "Tổ chức đã có hệ thống quản lý riêng có thể tích hợp các giải pháp của eContract ngay trên hệ thống.",
                },
            ],
        },
        dichVu: {
            title: "Dịch vụ eContract ",
            subTitle:
                "VNPT eContract có thể áp dụng linh hoạt để giải quyết những vấn đề hóc búa cho doanh nghiệp",
            avatar: "https://storage-econtract.vnpt.vn/landingpage-dev/2021-08-26/bg-dich-vu.png",
            danhSach: [
                "Xác minh đối tác trực tuyến",
                "Đàm phán hợp đồng trực tuyến",
                "Ký kết bằng mọi loại chữ ký số",
                "Chữ ký số dùng một lần",
                "Chữ ký điện tử dành cho cá nhân",
                "Xuất hóa đơn điện tử",
                "Không chi phí đầu tư hạ tầng",
            ],
        },
        giaiPhap: {
            title: "Giải pháp eContract",
            subTitle: "Giải pháp eContract",
            avatar: "https://storage-econtract.vnpt.vn/landingpage-dev/2021-08-26/bg-giai-phap.png",
            description:
                "VNPT eContract có thể áp dụng linh hoạt để giải quyết những vấn đề hóc búa cho doanh nghiệp ",
            chiTiet: {
                title: "VNPT eContract - Hợp đồng điện tử",
                subTitle:
                    "Quản lý hợp đồng, ký kết hợp đồng điện tử cho các bên tham gia ở bất cứ đâu tại bất cứ thời điểm nào.",
                avatar: "https://storage-econtract.vnpt.vn/landingpage-dev/2021-08-26/hopdongdientu_icon.png",
                description: "Thông tin chi tiết giải pháp econtract",
            },
        },
        loiIch: {
            title: "Lợi ích",
            subTitle: "eContract giúp bạn những gì?",
            avatar: "../../../assets/img/home/v3/Image/loiich_img.png",
            description: "Thông tin chi tiết eContract giúp bạn những gì?",
            danhSach: [
                {
                    title: "Tiết kiệm chi phí in ấn",
                    subTitle: "Tiết kiệm chi phí in ấn",
                    avatar: "../../../assets/img/home/v3/icon/tiet_kiem_chi_phi_icon.png",
                    description:
                        "Tiết kiệm chi phí in ấn, lưu trữ, sao lưu, tìm kiếm hợp đồng giấy, cũng như các loại tài liệu kèm theo trong bộ hồ sơ hợp đồng.",
                },
                {
                    title: "Tiết kiệm thời gian",
                    subTitle: "Tiết kiệm thời gian",
                    avatar: "../../../assets/img/home/v3/icon/tiet_kiem_thoi_gian_icon.png",
                    description:
                        "Tiết kiệm thời gian, chi phí đi lại, giảm thiểu rủi ro khi gặp mặt trực tiếp đối tác ký",
                },
                {
                    title: "Đầy đủ căn cứ pháp lý",
                    subTitle: "Đầy đủ căn cứ pháp lý",
                    avatar: "../../../assets/img/home/v3/icon/can_cu_phap_ly_icon.png",
                    description:
                        "Đáp ứng đầy đủ các căn cứ pháp lý theo luật Giao dịch điện tử, luật Dân sự và các luật chuyên ngành liên quan",
                },
                {
                    title: "Tài khoản trực tuyến",
                    subTitle: "Tài khoản trực tuyến",
                    avatar: "../../../assets/img/home/v3/icon/tai_khoan_truc_tuyen_icon.png",
                    description:
                        "Thực hiện đăng ký tài khoản trực tuyến một cách dễ dàng, không bị giới hạn về địa lý và tiết kiệm thời gian.",
                },
                {
                    title: "Tính xác thực cao",
                    subTitle: "Tính xác thực cao",
                    avatar: "../../../assets/img/home/v3/icon/tinh_xac_thuc_cao_icon.png",
                    description:
                        "Tiết kiệm thời gian, chi phí đi lại, giảm thiểu rủi ro khi gặp mặt trực tiếp đối tác ký",
                },
                {
                    title: "Quản lý chữ ký số",
                    subTitle: "Quản lý chữ ký số",
                    avatar: "../../../assets/img/home/v3/icon/quan_ly_cks_icon.png",
                    description:
                        "Quản lý chữ ký số đối với doanh nghiệp và chữ ký điện tử đối với cá nhân. Hỗ trợ cấu hình nhiều luồng ký.",
                },
                {
                    title: "Đơn giản hóa quy trình",
                    subTitle: "Đơn giản hóa quy trình",
                    avatar: "../../../assets/img/home/v3/icon/don_gian_quy_trinh_icon.png",
                    description:
                        "Quy trình ký điện tử đơn giản, đáng tin cậy và linh hoạt trên máy tính, máy tính bảng, điện thoại thông minh.",
                },
                {
                    title: "Thông báo nhanh",
                    subTitle: "Thông báo nhanh",
                    avatar: "../../../assets/img/home/v3/icon/thong_bao_nhanh.png",
                    description:
                        "Thông báo cho các bên khi có hợp đồng mới được gửi tới, hoặc ký kết hợp đồng thành công.",
                },
                {
                    title: "Quản lý bộ hợp đồng",
                    subTitle: "Quản lý bộ hợp đồng",
                    avatar: "../../../assets/img/home/v3/icon/quan_ly_bhd_icon.png",
                    description:
                        "Chức năng quản lý bộ hợp đồng giúp số hóa toàn bộ hồ sơ hợp đồng điện tử.",
                },
            ],
        },
        hopDong: {
            title: "Áp dụng eContract với mọi loại hợp đồng",
            subTitle: "Áp dụng eContract với mọi loại hợp đồng",
            avatar: "https://storage-econtract.vnpt.vn/landingpage-dev/2021-08-26/apdung_background.png",
            description: "Áp dụng eContract với mọi loại hợp đồng",
            danhSach: [
                {
                    title: "Hợp đồng cung cấp dịch vụ",
                    subTitle: "Hợp đồng cung cấp dịch vụ",
                    avatar: "../../../assets/img/home/v3/icon/star.png",
                    description: "Hợp đồng cung cấp dịch vụ",
                },
                {
                    title: "Hợp đồng thuê khoán",
                    subTitle: "Hợp đồng thuê khoán",
                    avatar: "../../../assets/img/home/v3/icon/star.png",
                    description: "Hợp đồng thuê khoán",
                },
                {
                    title: "Hợp đồng đại lý",
                    subTitle: "Hợp đồng đại lý",
                    avatar: "../../../assets/img/home/v3/icon/star.png",
                    description: "Hợp đồng đại lý",
                },
                {
                    title: "Hợp đồng vận chuyển hành khách",
                    subTitle: "Hợp đồng vận chuyển hành khách",
                    avatar: "../../../assets/img/home/v3/icon/star.png",
                    description: "Hợp đồng vận chuyển hành khách",
                },
                {
                    title: "Hợp đồng vay vốn",
                    subTitle: "Hợp đồng vay vốn",
                    avatar: "../../../assets/img/home/v3/icon/star.png",
                    description: "Hợp đồng vay vốn",
                },
                {
                    title: "Hợp đồng bảo hiểm",
                    subTitle: "Hợp đồng bảo hiểm",
                    avatar: "../../../assets/img/home/v3/icon/star.png",
                    description: "Hợp đồng bảo hiểm",
                },
                {
                    title: "Hợp đồng lao động",
                    subTitle: "Hợp đồng lao động",
                    avatar: "../../../assets/img/home/v3/icon/star.png",
                    description: "Hợp đồng lao động",
                },
                {
                    title: "Hợp đồng mua bán",
                    subTitle: "Hợp đồng mua bán",
                    avatar: "../../../assets/img/home/v3/icon/star.png",
                    description: "Hợp đồng mua bán",
                },
                {
                    title: "Hợp đồng thương mại",
                    subTitle: "Hợp đồng thương mại",
                    avatar: "../../../assets/img/home/v3/icon/star.png",
                    description: "Hợp đồng thương mại",
                },
            ],
        },
        goiCuoc: {
            title: "Gói cước",
            subTitle: "Chúng tôi cung cấp gì ?",
            avatar: "https://storage-econtract.vnpt.vn/landingpage-dev/2021-08-26/dichvu_econtract_image.png",
            description: "Chúng tôi cung cấp gì ?",
            caNhan: [
                {
                    title: "GÓI DÙNG THỬ",
                    subTitle:
                        "Trải nghiệm đầy đủ tính năng ưu việt ngay trên điện thoại của bạn, đem lại sự hài lòng khi tương tác với sản phẩm",
                    avatar: "https://storage-econtract.vnpt.vn/landingpage-dev/2021-08-26/loiich_img.png",
                    description:
                        "<ul>\n<li>Số lượng hợp đồng: <span>5</span></li>\n<li>Thời hạn sử dụng: <span>1 tháng</span></li>\n</ul>",
                },
                {
                    title: "GÓI CƠ BẢN",
                    subTitle:
                        "Hỗ trợ các chuyên gia với những tính năng thích hợp, giúp rút ngắn thời gian trong quá trình vận hành",
                    avatar: "https://storage-econtract.vnpt.vn/landingpage-dev/2021-08-26/loiich_img.png",
                    description:
                        "<ul>\n<li>Số lượng hợp đồng: <span>50</span></li>\n<li>Thời hạn sử dụng: <span>6 tháng</span></li>\n</ul>",
                },
                {
                    title: "GÓI NÂNG CAO",
                    subTitle:
                        "Giải pháp toàn diện dành cho doanh nghiệp, tự động hoá quy trình thủ công, tạo lợi thế cạnh tranh trên thị trường",
                    avatar: "https://storage-econtract.vnpt.vn/landingpage-dev/2021-08-26/loiich_img.png",
                    description:
                        "<ul>\n<li>Số lượng hợp đồng: <span>200</span></li>\n<li>Thời hạn sử dụng: <span>6 tháng</span></li>\n</ul>",
                },
            ],
            dichVu: [
                {
                    title: "GÓI DÙNG THỬ",
                    subTitle:
                        "Trải nghiệm đầy đủ tính năng ưu việt ngay trên điện thoại của bạn, đem lại sự hài lòng khi tương tác với sản phẩm",
                    avatar: "https://storage-econtract.vnpt.vn/landingpage-dev/2021-08-26/loiich_img.png",
                    description:
                        "<ul>\n<li>Số lượng hợp đồng: <span>5</span></li>\n<li>Thời hạn sử dụng: <span>1 tháng</span></li>\n</ul>",
                },
                {
                    title: "GÓI CƠ BẢN",
                    subTitle:
                        "Hỗ trợ các chuyên gia với những tính năng thích hợp, giúp rút ngắn thời gian trong quá trình vận hành",
                    avatar: "https://storage-econtract.vnpt.vn/landingpage-dev/2021-08-26/loiich_img.png",
                    description:
                        "<ul>\n<li>Số lượng hợp đồng: <span>50</span></li>\n<li>Thời hạn sử dụng: <span>6 tháng</span></li>\n</ul>",
                },
                {
                    title: "GÓI NÂNG CAO",
                    subTitle:
                        "Giải pháp toàn diện dành cho doanh nghiệp, tự động hoá quy trình thủ công, tạo lợi thế cạnh tranh trên thị trường",
                    avatar: "https://storage-econtract.vnpt.vn/landingpage-dev/2021-08-26/loiich_img.png",
                    description:
                        "<ul>\n<li>Số lượng hợp đồng: <span>200</span></li>\n<li>Thời hạn sử dụng: <span>6 tháng</span></li>\n</ul>",
                },
            ],
            giaiPhap: [
                {
                    title: "GÓI DÙNG THỬ",
                    subTitle:
                        "Trải nghiệm đầy đủ tính năng ưu việt ngay trên điện thoại của bạn, đem lại sự hài lòng khi tương tác với sản phẩm",
                    avatar: "https://storage-econtract.vnpt.vn/landingpage-dev/2021-08-26/loiich_img.png",
                    description:
                        "<ul>\n<li>Số lượng hợp đồng: <span>5</span></li>\n<li>Thời hạn sử dụng: <span>1 tháng</span></li>\n</ul>",
                },
                {
                    title: "GÓI CƠ BẢN",
                    subTitle:
                        "Hỗ trợ các chuyên gia với những tính năng thích hợp, giúp rút ngắn thời gian trong quá trình vận hành",
                    avatar: "https://storage-econtract.vnpt.vn/landingpage-dev/2021-08-26/loiich_img.png",
                    description:
                        "<ul>\n<li>Số lượng hợp đồng: <span>50</span></li>\n<li>Thời hạn sử dụng: <span>6 tháng</span></li>\n</ul>",
                },
                {
                    title: "GÓI NÂNG CAO",
                    subTitle:
                        "Giải pháp toàn diện dành cho doanh nghiệp, tự động hoá quy trình thủ công, tạo lợi thế cạnh tranh trên thị trường",
                    avatar: "https://storage-econtract.vnpt.vn/landingpage-dev/2021-08-26/loiich_img.png",
                    description:
                        "<ul>\n<li>Số lượng hợp đồng: <span>200</span></li>\n<li>Thời hạn sử dụng: <span>6 tháng</span></li>\n</ul>",
                },
            ],
        },
        doiTac: {
            title: "Đối tác",
            subTitle: "Chúng tôi được tin tưởng bởi các đối tác hàng đầu",
            avatar: "https://storage-econtract.vnpt.vn/landingpage-dev/2021-08-26/bg-doi-tac.png",
            description: "Chúng tôi được tin tưởng bởi các đối tác hàng đầu",
            danhSach: [
                {
                    title: "VNPT POST",
                    subTitle: "Bưu chính Việt Nam",
                    avatar: "https://storage-econtract.vnpt.vn/landingpage-dev/2021-08-26/vnpost.png",
                    description: "Bưu chính Việt Nam",
                },
                {
                    title: "Vinaphone",
                    subTitle: "Vinaphone",
                    avatar: "https://storage-econtract.vnpt.vn/landingpage-dev/2021-08-26/vinaphone.png",
                    description: "Vinaphone",
                },
            ],
        },
        configs: [
            {
                key: "instagram",
                value: "https://www.instagram.com/vanvuong2610/",
            },
            {
                key: "phone",
                value: "0243.553.3388",
            },
            {
                key: "address",
                value: "344 2 Tháng 9, Quận Hải Châu, TP Đà Nẵng",
            },
            {
                key: "android",
                value: "CH PLAY",
            },
            {
                key: "facebook",
                value: "https://www.facebook.com/vuongnv.ued/",
            },
            {
                key: "logo",
                value: "https://storage-econtract.vnpt.vn/landingpage-dev/2021-09-17/logo-landing.png",
            },
            {
                key: "web",
                value: "https://vnpt.vn",
            },
            {
                key: "youtube",
                value: "https://www.youtube.com/channel/UC06rw36nhapbfULHs88YN2A",
            },
            {
                key: "qrcode",
                value: "../../../assets/img/home/v3/Image/qr.png",
            },
            {
                key: "email",
                value: "vnptit@vnpt.vn",
            },
            {
                key: "ios",
                value: "App Store",
            },
        ],
    };

    data = this.dataLocal;
    showModalVideo: boolean = false;
    fragment: string = "";
    homeMode: number = 0;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private fb: FormBuilder,
        private http: HttpClient,
        private myAlert: AlertControl,
        private api: API
    ) {
        if (location.hostname !== Config.HOME_DOMAIN) {
            this.router.navigate([ConstantUrl.LOGIN]);
        }
        this.fragment = this.route.snapshot.fragment;
    }

    ngOnInit(): void {
        this.makeFormTrail();
        this.getData();
        this.listCauHoiLeft = this.listCauHoi.danhSach.slice(0, 2);
        this.listCauHoiRight = this.listCauHoi.danhSach.slice(2, 8);
        if (!!this.fragment && this.fragment == "contact") {
            this.changeMode(2);
        }
    }
    changeMode(mode) {
        this.homeMode = mode;
        setTimeout(() => {
            const el = document.getElementById("section-introduce");
            const y = el.getBoundingClientRect().top + window.pageYOffset - 58;
            window.scrollTo({ top: y, behavior: "smooth" });
        }, 200);
    }

    getData() {
        this.api.get(GET_DATA_LDP).subscribe((res: any) => {
            if (res.state) {
                this.data = res.object;
                this.listCauHoi = res.object.cauHoi;
                this.listCauHoiLeft = this.listCauHoi.danhSach.slice(0, 2);
                this.listCauHoiRight = this.listCauHoi.danhSach.slice(2, 8);
                console.log({ res: this.data });
            }
        });
    }

    ngAfterViewInit() {
        var _this = this;
        var pushy = $(".pushy"), //menu css class
            body = $("div.landingpage"),
            container = $("#container"),
            push = $(".push"),
            pushyRight = "pushy-right",
            pushyOpenLeft = "pushy-open-left",
            pushyOpenRight = "pushy-open-right",
            siteOverlay = $(".site-overlay"),
            menuBtn = $(".menu-btn"),
            menuItem = $(".pushy-link"),
            menuLinkFocus = $(pushy.data("focus")),
            menuBtnFocus = $(".menu-btn");

        menuBtn.on("click", function () {
            _this.togglePushy();
        });
        menuItem.on("click", function () {
            $("#menu_portal li").removeClass("active");
            $("#menu_portal li a").removeClass("active");
            $(this).children("a").addClass("active");
            $(this).addClass("active");
            _this.togglePushy();
        });

        //close menu when clicking site overlay
        siteOverlay.on("click", function () {
            _this.togglePushy();
        });
        setTimeout(function () {
            $(".autoplayslide").slick({
                slidesToShow: 2,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 3000,
                dots: true,
                vertical: true,
                verticalSwiping: true,
                responsive: [
                    {
                        breakpoint: 767,
                        settings: "unslick",
                    },
                ],
            });
            $("div#slidegoicuoc").slick({
                slidesToShow: 3,
                slidesToScroll: 1,
                autoplay: false,
                autoplaySpeed: 3000,
                dots: true,
                verticalSwiping: true,
                responsive: [
                    {
                        breakpoint: 767,
                        settings: "unslick",
                    },
                ],
            });
        }, 1500);

        $(".slide_partner").slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 3000,
            dots: true,
        });

        $(".owl-carousel").owlCarousel({
            stagePadding: 0,
            loop: true,
            margin: 30,
            nav: true,
            autoplay: true,
            center: true,
            autoWidth: true,
            items: 3,
            responsive: {
                0: {
                    items: 1,
                },
                600: {
                    items: 3,
                },
                1000: {
                    items: 5,
                },
            },
        });

        var videos = document.getElementsByTagName("video");
        for (var i = 0; i < videos.length; i++) {
            videos[i].muted = true;
            videos[i].play();
        }
    }

    makeFormTrail() {
        this.formTrial = this.fb.group({
            email: new FormControl("", [
                Validators.required,
                Validators.pattern(REGEXP_EMAIL),
            ]),
        });
    }
    get email() {
        return this.formTrial.get("email");
    }

    anyFunction() {
        console.log("called from parent");
    }

    scroll(el: HTMLElement) {
        el.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    scrollTo(name_el: string) {
        this.homeMode = 0;
        setTimeout(() => {
            const el = document.getElementById(name_el);
            const y = el.getBoundingClientRect().top + window.pageYOffset - 58;
            window.scrollTo({ top: y, behavior: "smooth" });
        }, 200);
    }

    @HostListener("window:scroll", ["$event"])
    onWindowScroll(e) {
        // if (window.pageYOffset > 0) {
        //           let element = document.getElementById('navbar');
        //           element.classList.add('shadow');
        //       } else {
        //           let element = document.getElementById('navbar');
        //           element.classList.remove('shadow');
        //       }
    }
    togglePushy() {
        var pushy = $(".pushy"), //menu css class
            body = $("div.landingpage"),
            container = $("#container"),
            push = $(".push"),
            pushyRight = "pushy-right",
            pushyOpenLeft = "pushy-open-left",
            pushyOpenRight = "pushy-open-right",
            siteOverlay = $(".site-overlay"),
            menuBtn = $(".menu-btn"),
            menuItem = $(".pushy-link"),
            menuLinkFocus = $(pushy.data("focus")),
            menuBtnFocus = $(".menu-btn");

        body.toggleClass(pushyOpenRight);

        if (menuLinkFocus) {
            pushy.one("transitionend", function () {
                menuLinkFocus.focus();
            });
        }
    }

    goToSignUp() {
        console.log({ form: this.formTrial.value.email });
        this.isSubmit = true;
        if (this.formTrial.valid) {
            this.api
                .post(
                    "/landingpage-service/api/Catalog/register-email",
                    { email: this.formTrial.value.email }
                )
                .subscribe(
                    (res: any) => {
                        if (res.message === "IDG-00000000") {
                            this.myAlert.showAlert(
                                "Chúng tôi đã ghi nhận thông tin của Quý khách và sẽ phản hồi trong thời gian sớm nhất!",
                                ConstantAlertType.SUCCESS
                            );
                            this.makeFormTrail();
                            this.isSubmit = false;
                        }
                    },
                    (error) => {
                        if (error.error.message === "NCO-00000011") {
                            this.myAlert.showAlert(
                                "Quý khách đã gửi một liên hệ trong hôm nay, vui lòng đợi chúng tôi liên hệ lại. Xin cảm ơn!",
                                ConstantAlertType.ERROR
                            );
                        } else {
                            this.myAlert.showAlert(
                                "Đã có lỗi xảy ra trong quá trình gửi tin!",
                                ConstantAlertType.ERROR
                            );
                        }
                    }
                );
        }
    }

    navigateToBusiness() {
        this.router.navigate(["/app/signup"], {
            queryParams: {
                userType: "BUSINESS",
            },
        });
    }
    navigateToConsumer() {
        this.router.navigate(["/app/signup"], {
            queryParams: {
                userType: "CONSUMER",
            },
        });
    }

    selectTab(index) {
        console.log(index);
    }

    openVideo() {
        this.showModalVideo = true;
    }

    closeVideo() {
        this.showModalVideo = false;
    }
}
