import { Component, OnInit } from '@angular/core';
import { CodeInfoResponse } from 'src/app/utils/models/signature/code-info/code-info-response.model';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/utils/services/auth.service';
import { SignatureService } from 'src/app/utils/services/aas-network/signature.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MessageRequest } from 'src/app/utils/common/MessageRequest';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { VerifyCodeResponse } from 'src/app/utils/models/aas-models/signature/verify-code-response/verify-code-response';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';

@Component({
  selector: 'app-service-redirect-vnptca-login',
  templateUrl: './service-redirect-vnptca-login.component.html',
  styleUrls: ['./service-redirect-vnptca-login.component.scss']
})
export class ServiceRedirectVnptcaLoginComponent implements OnInit {

  userId = '';
  codeInfo: VerifyCodeResponse = new VerifyCodeResponse();
  isLoading = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private signatureService: SignatureService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    // this.userId = '6d073dd2-97b7-4574-933e-9756c36f2026';
    this.userId = this.authService.userId;
    this.postSignServerCode();
  }

  postSignServerCode() {
    let code = this.route.snapshot.queryParams["code"];
    this.signatureService.postCASignServerCode(code, this.userId).subscribe((res) => {
      this.codeInfo = new VerifyCodeResponse(res);
      this.router.navigate(['/app/console/signature-manager']);
    }, err => {
      console.log(err);
      if (err.error && err.error.message && ResponseMsg.MESSAGES.SIGN_SERVER[err.error.message]) {
        this.alert(ResponseMsg.MESSAGES.SIGN_SERVER[err.error.message], 'error', false, '', 'OK', () => {
          this.router.navigate(['/app/console/signature-manager']);
        });
      } else {
        this.alert(err.error.error || 'error', '', false, '', 'OK', () => {
          this.router.navigate(['/app/console/signature-manager']);
        });
      }
    }, () => {
      
    });
  }

  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }
}
