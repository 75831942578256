import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { localize } from 'src/app/utils/localize/localize';
import { RequestPreoder } from 'src/app/utils/models/aas-models/one-sme/request-preorder';
import { RequestPreoderEmployee } from 'src/app/utils/models/aas-models/one-sme/request-preorder-employee';
import { OneSmeService } from 'src/app/utils/services/aas-network/one-sme/one-sme.service';

@Component({
  selector: 'app-requests-service-detail',
  templateUrl: './requests-service-detail.component.html',
  styleUrls: ['./requests-service-detail.component.scss']
})
export class RequestsServiceDetailComponent implements OnInit {

  id = this.activatedRoute.snapshot.paramMap.get('id');
  
  localize = localize;

  listEmployees = [];
  page = 1;
  maxSize = 10;
  totalElement = 0;

  requestOrder: RequestPreoder = new RequestPreoder();

  employees: RequestPreoderEmployee[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private oneSmeApi: OneSmeService,
    private myAlert: AlertControl
  ) { }

  ngOnInit(): void {
    this.getOrder(this.id);
    this.getEmployees(this.id, 1, 10, "DESC", "modifiedAt");
  }

  loadData(page) {
    this.page = page;
    this.getEmployees(this.id, this.page, this.maxSize,"DESC", "modifiedAt");
  }

  getOrder(id) {
    this.oneSmeApi.getOrder(id).subscribe((res: any) => {
      console.log(res);
      this.requestOrder = new RequestPreoder(res.object);
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }

  getAddress(sme: any) {
    return (sme?.streetName || "") + ", " + (sme?.wardName || "") + ", " + (sme?.districtName || "") + ", " + (sme?.provinceName || "") + ", " + (sme?.countryName || ""); 
  }

  getEmployees(id, page, maxSize, sort, propertiesSort) {
    this.oneSmeApi.getEmployees(id, page, maxSize, sort, propertiesSort).subscribe((res: any) => {
      console.log(res);
      this.employees = [];
      if (res.object?.data && res.object?.data instanceof Array) {
        res.object?.data.forEach(x => {
          this.employees.push(new RequestPreoderEmployee(x));
        })
      }

      this.totalElement = res.object?.totalElement;
    }, err => {

    });
  }
  
  getperiodType(type) {
    switch (type) {
      case "YEARLY": return localize.year;
      case "DAILY": return localize.day;
      case "MONTHLY": return localize.month;
      default: return "";
    }
  }

  getNameStatus(status) {
    switch (status) {
      case "SME_RECEIVERED":
        return "Đã tiếp nhận";
      case "SME_INPROCESS":
        return "Đang xử lý";
      case "SME_DONE":
        return "Hoàn thành";
      case "SME_ERROR":
        return "Lỗi kích hoạt";
      default:
        return "";
    }
  }

  getStyleStatus(status) {
    switch (status) {
      case "SME_RECEIVERED":
        return "badge-primary-new";
      case "SME_INPROCESS":
        return "badge-warning-new";
      case "SME_DONE":
        return "badge-success-new";
      case "SME_ERROR":
        return "badge-danger-new";
      default:
        return "badge-light";
    }
  }
}
