import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ValidatorsExtension } from 'src/app/utils/common/validators-extension';
import { REGEXP_EMAIL, REGEXP_MST, REGEXP_PASSWORD, REGEXP_USERNAME } from 'src/app/utils/common/regexp';
import { SIGN_ON_BUSINESS } from 'src/app/utils/common/constant-vaildate-msg';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { Config } from 'src/app/config';
import { LocationExtension } from 'src/app/utils/common/LocationExtension';
import { SignUpBusinessResponse } from 'src/app/utils/models/aas-models/signup-business/signup-business.response';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { ConstantAlertBtn, ConstantAlertMsg } from 'src/app/utils/common/constant-alert-msg';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { CatalogService } from 'src/app/utils/services/aas-network/catalog.service';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ListTinhResponse } from 'src/app/utils/models/catalog/list-tinh/list-tinh-response.model';
import { localize } from 'src/app/utils/localize/localize';
import { TemplateService } from 'src/app/utils/services/aas-network/template.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-register-business',
  templateUrl: './register-business.component.html',
  styleUrls: ['./register-business.component.scss']
})
export class RegisterBusinessComponent implements OnInit {

  customerType = this.activedRoute.snapshot.queryParamMap.get('userType') || '';
  email = this.activedRoute.snapshot.queryParamMap.get('email') || '';
  id = this.activedRoute.snapshot.queryParamMap.get('id') || "";

  @Output() dataFilled = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private activedRoute: ActivatedRoute,
    private route: Router,
    private aasAuth: AasAuthService,
    private modalService: NgbModal,
    private catalog: CatalogService,
    private myAlert: AlertControl,
    private templateSv: TemplateService,
    private translate: TranslateService
  ) { }

  formSubmit: FormGroup;
  validateMsg = SIGN_ON_BUSINESS;
  tinhBindData = [];
  checkbox_dieukhoan = false;
  isSubmit: boolean = false;
  provinceSelected: any = null;



  dieuKhoan: any = "";



  ngOnInit(): void {
    this.makeFormSubmit();
    this.getDieuKhoan();

    if (this.email) {
      this.formSubmit.controls.email.setValue(this.email);
    }
  }

  getDieuKhoan() {
    this.templateSv.getDieuKhoan().subscribe((res: any) => {
      res.text().then((result) => {
        this.dieuKhoan = result
      }).catch((err) => {
      });
    }, err => {
      console.log(err);
    })
  }

  makeFormSubmit() {
    this.formSubmit = this.fb.group({
      tenDoanhNghiep: new FormControl('', [Validators.required, ValidatorsExtension.validateCompanyName]),
      tenVietTat: new FormControl('', [Validators.required, ValidatorsExtension.validateShortCompanyName]),
      maSoThue: new FormControl('', [Validators.required, Validators.pattern(REGEXP_MST)]),

      hoVaTen: new FormControl('', [Validators.required, ValidatorsExtension.validateFullName]),
      email: new FormControl('', [Validators.required, Validators.pattern(REGEXP_EMAIL)]),

      username: new FormControl('', [Validators.required, Validators.pattern(REGEXP_USERNAME)]),
      password: new FormControl('', [Validators.required, Validators.pattern(REGEXP_PASSWORD)]),
      tinhId: [null]
    });

    this.getDmTinh();
  }

  submit() {
    this.isSubmit = true;
    if (this.formSubmit.valid) {
      if (!this.checkbox_dieukhoan) {
        this.myAlert.showAlertOnlyNoti(this.translate.instant('RegisterBusiness_ts_1'), "", "", this.translate.instant('btn_close'));
        return;
      }
      if (this.id) {
        this.postRegisterBusinessByInvited(this.id, this.makeParam());
      } else {
        this.postRegisterBusiness(this.makeParam());
      }
    }
  }

  /**
   * Tao param submit
   */
  makeParam(): Object {
    return {
      "daiDien": {
        "domain": LocationExtension.HOSTNAME,
        "ten": this.formSubmit.controls.hoVaTen.value,
        "email": this.formSubmit.controls.email.value,
        "username": this.formSubmit.controls.username.value,
        "password": this.formSubmit.controls.password.value,
        "tinhId": parseInt(this.formSubmit.controls.tinhId.value, 10)  || null
      },
      "toChuc": {
        "tenToChuc": this.formSubmit.controls.tenDoanhNghiep.value,
        "tenRutGon": this.formSubmit.controls.tenVietTat.value,
        "maSoThue": this.formSubmit.controls.maSoThue.value
      }
    }
  }

  // //MARK: Popup thông báo
  // alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = ConstantAlertBtn.OK, actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = ConstantAlertBtn.CANCEL, actBtn1 = () => { }) {
  //   const modalRef = this.modalService.open(AlertComponent);
  //   modalRef.componentInstance.message = msg;
  //   modalRef.componentInstance.typeAlert = type;
  //   modalRef.componentInstance.twoBtn = twoBtn;
  //   modalRef.componentInstance.iconBtn1 = iconBtn1;
  //   modalRef.componentInstance.iconBtn2 = iconBtn2;
  //   modalRef.componentInstance.titleButton1 = titleBtn1;
  //   modalRef.componentInstance.titleButton2 = titleBtn2;
  //   modalRef.componentInstance.callback1 = actBtn1;
  //   modalRef.componentInstance.callback2 = actBtn2;
  // }

  // //MARK: Handle Error
  // handleError(err, errorMsg) {
  //   if (err.error && err.error.message && errorMsg[err.error.message]) {
  //     this.alert(errorMsg[err.error.message], ConstantAlertType.ERROR);
  //   } else if (err.error && err.error.error) {
  //     this.alert(err.error.error, ConstantAlertType.ERROR);
  //   } else {
  //     this.alert(ConstantAlertMsg.DEFAULT_ERROR_REQUEST, ConstantAlertType.ERROR);
  //   }
  // }

  //MARK: NETWORKING
  /**
   * Gui yeu cau dang ky tai khoan to chuc SME
   * @param param 
   */
  postRegisterBusiness(param) {
    this.aasAuth.postRegisterBusiness(param).subscribe((res: any) => {
      // const data = new SignUpBusinessResponse(res);

      // var emitParam = this.formSubmit.value;
      // emitParam['userId'] = data.object.userId;
      const userId = res.object.userId;
      const data = {
        userId: userId,
        userType: 'BUSINESS'
      };
      this.dataFilled.emit(data);
      // this.next.emit(emitParam);
    }, err => {
      console.log(err);
      if (err.error && err.error.message) {
        switch (err.error.message) {
          case 'ECT-00001200':
            this.formSubmit.controls.email.setErrors({ unique: true });
            break;
          case 'ECT-00001201':
            this.formSubmit.controls.sdt.setErrors({ unique: true });
            break
          case 'ECT-00001202':
            this.formSubmit.controls.username.setErrors({ unique: true });
            break;
          case 'ECT-00001208':
            this.formSubmit.controls.maSoThue.setErrors({ unique: true });
          default:
            this.myAlert.showErrorHandled(err);
        }
      }
    }, () => {

    });
  }

  /**
     * Gui yeu cau dang ky tai khoan to chuc SME dc moi
     * @param param 
     */
  postRegisterBusinessByInvited(id, param) {
    this.aasAuth.postRegisterBusinessByInvited(id, param).subscribe((res: any) => {
      // const data = new SignUpBusinessResponse(res);

      // var emitParam = this.formSubmit.value;
      // emitParam['userId'] = data.object.userId;
      const userId = res.object.userId;
      const data = {
        userId: userId,
        userType: 'BUSINESS'
      };
      this.dataFilled.emit(data);
      // this.next.emit(emitParam);
    }, err => {
      console.log(err);
      if (err.error && err.error.message) {
        switch (err.error.message) {
          case 'ECT-00001200':
            this.formSubmit.controls.email.setErrors({ unique: true });
            break;
          case 'ECT-00001201':
            this.formSubmit.controls.sdt.setErrors({ unique: true });
            break
          case 'ECT-00001202':
            this.formSubmit.controls.username.setErrors({ unique: true });
            break;
          case 'ECT-00001208':
            this.formSubmit.controls.maSoThue.setErrors({ unique: true });
          default:
            this.myAlert.showErrorHandled(err);
        }
      }
    }, () => {

    });
  }

  getDmTinh() {
    this.catalog.getListTinh().subscribe((res) => {
      let dmTinh = new ListTinhResponse(res);
      this.tinhBindData = dmTinh.object.map(item => {
        return { id: item.tinhId, label: item.tenTinh };
      });
      
      this.formSubmit.controls.tinhId.setValue(this.tinhBindData[0].id)
    }, err => {
      console.log(err);
    });
  }

  /**
   * Hien thi dieu khoan su dung
   */
  showDieuKhoan() {
    let self = this;
    const content = "<div class='e-dieukhoan'>" + this.dieuKhoan + "</div>";
    setTimeout(() => {
      self.myAlert.showAlertOnlyNoti(
        content,
        ConstantAlertType.BLANK,
        "",
        this.translate.instant('btn_close'),
        null,
        this.translate.instant('RegisterBusiness_ts_2'),
        { size: 'lg' }
      );
    });
  }
}
