import { AuthService } from 'src/app/utils/services/auth.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-dashboard',
  templateUrl: './service-dashboard.component.html',
  styleUrls: ['./service-dashboard.component.scss']
})
export class ServiceDashboardComponent implements OnInit {

  constructor(
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    // if (this.checkRoles) {
    //   this.router.navigate(['/console/register-manager']);
    // } else {
    //   this.router.navigate(['/app/console/contract-manager']);
    // }
    this.redirectPage();
  }

  get checkRoles() {
    const token = this.authService.getToken();
    const token_decode = this.authService.jwtDecode(token);
    const allowRoles = ['AAS_ADMIN']; //'KH_USER'

    let ok = false;

    token_decode.authorities.forEach((item) => {
      if (allowRoles.includes(item)) {
        ok = true;
      }      
    })

    return ok;
  }

  redirectPage() {
    const token = this.authService.getToken();
    const token_decode = this.authService.jwtDecode(token);
    if (token_decode.authorities.includes('ASSADM')) {
      this.router.navigate(['/console/register-manager']);
    } else {
      this.router.navigate(['/app/console/profile-manager']);
    }
  }
}
