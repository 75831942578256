import { REGEXP_PASSWORD, REGEXP_USERNAME, REGEXP_DATE_INPUT, REGEXP_PHONE_NUMBER, REGEXP_EMAIL, REGEXP_MST } from './../../../../utils/common/regexp';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AasValidateMessage } from 'src/app/utils/common/validate-msg-aas';
import { ActivatedRoute, Router } from '@angular/router';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { Observable } from 'rxjs';
import { Select2OptionData } from 'ng-select2';
import { ListTinhResponse } from 'src/app/utils/models/catalog/list-tinh/list-tinh-response.model';
import { ListHuyenResponse } from 'src/app/utils/models/catalog/list-huyen/list-huyen-response.model';
import { ListXaResponse } from 'src/app/utils/models/catalog/list-xa/list-xa-response.model';
import { CatalogService } from 'src/app/utils/services/aas-network/catalog.service';
import { Options } from 'select2';
import { GlobalConfig } from 'src/app/utils/common/global-config/global-config';
import { DateUtils } from 'src/app/utils/common/DateUtils';
import { LocationExtension } from 'src/app/utils/common/LocationExtension';
import { SignUpBusinessResponse } from 'src/app/utils/models/aas-models/signup-business/signup-business.response';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { StringUtils } from 'src/app/utils/common/StringUtils';
import { ValidatorsExtension } from 'src/app/utils/common/validators-extension';

declare var Cleave: any;
@Component({
  selector: 'app-service-signup-business',
  templateUrl: './service-signup-business.component.html',
  styleUrls: ['./service-signup-business.component.scss']
})
export class ServiceSignupBusinessComponent implements OnInit {
  public tinhData: Observable<Array<Select2OptionData>>;
  public huyenData: Observable<Array<Select2OptionData>>;
  public xaData: Observable<Array<Select2OptionData>>;
  public options: Options;

  dmTinh = new ListTinhResponse();
  dmHuyen = new ListHuyenResponse();
  dmXa = new ListXaResponse();

  formBusiness: FormGroup
  validMsg = AasValidateMessage.SING_ON_BUSINESS;
  isSubmit = false;

  constructor(
    private fb: FormBuilder,
    private route: Router,
    private activedRoute: ActivatedRoute,
    private aasAuth: AasAuthService,
    private catalog: CatalogService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.makeFormBusiness();
    this.makeAutoFormatDate();
    this.getDataFromQueryParams();

    this.getDmTinh();

    this.options = {
      containerCssClass: 'select2-lg-custom',
      dropdownAutoWidth: true,
      width: '100%',
      closeOnSelect: true,
      language: "vi"
    };
  }

  /**
   * Lấy thông tin truyền từ queryParam
   */
  getDataFromQueryParams() {
    this.tenDoanhNghiep.setValue(this.activedRoute.snapshot.queryParamMap.get('tenDoanhNghiep') || '');
    this.tenVietTat.setValue(this.activedRoute.snapshot.queryParamMap.get('tenVietTat') || '');
    this.maSoThue.setValue(this.activedRoute.snapshot.queryParamMap.get('maSoThue') || '');
  }

  //MARK: FORM ĐĂNG KÝ DOANH NGHIỆP
  /**
   * Khởi tạo formgroup
   */
  makeFormBusiness() {
    this.formBusiness = this.fb.group({
      tenDoanhNghiep: new FormControl('', [Validators.required, ValidatorsExtension.validateCompanyName]),
      tenVietTat: new FormControl('', [Validators.required, ValidatorsExtension.validateShortCompanyName]),
      maSoThue: new FormControl('', [Validators.required, Validators.pattern(REGEXP_MST)]),

      hoVaTen: new FormControl('', [Validators.required, ValidatorsExtension.validateFullName]),
      email: new FormControl('', [Validators.required, Validators.pattern(REGEXP_EMAIL)]),
      sdt: new FormControl('', [ValidatorsExtension.phoneNumber]),
      ngaySinh: new FormControl('', [Validators.pattern(REGEXP_DATE_INPUT)]),
      gioiTinh: new FormControl('1', []),
      diaChi: new FormControl('', []),
      tinhId: new FormControl('', []),
      huyenId: new FormControl('', []),
      xaId: new FormControl('', []),
      soNha: new FormControl('', []),
      duong: new FormControl('', []),

      username: new FormControl('', [Validators.required, Validators.pattern(REGEXP_USERNAME)]),
      password: new FormControl('', [Validators.required, Validators.pattern(REGEXP_PASSWORD)])
    });
  }

  /**
   * Validate Tên người đại diện
   * @param control 
   */
  validateFullName(control: FormControl) {
    let fullname = control.value;

    fullname = StringUtils.replaceUnicodeChar(fullname);

    // /^[a-zA-Z!@#\$%\^\&*\)\(+=._-]{2,}$/g
    var re = /^[a-zA-Z\s]+$/g // regex here
    if (!re.test(fullname)) {
      return {
        pattern: true
      }
    }
  }

  //mark: Get control từ formgroup
  get tenDoanhNghiep() { return this.formBusiness.get('tenDoanhNghiep'); }
  get tenVietTat() { return this.formBusiness.get('tenVietTat'); }
  get maSoThue() { return this.formBusiness.get('maSoThue'); }
  get hoVaTen() { return this.formBusiness.get('hoVaTen'); }
  get email() { return this.formBusiness.get('email'); }
  get sdt() { return this.formBusiness.get('sdt'); }
  get ngaySinh() { return this.formBusiness.get('ngaySinh'); }
  get gioiTinh() { return this.formBusiness.get('gioiTinh'); }
  get diaChi() { return this.formBusiness.get('diaChi'); }
  get tinhId() { return this.formBusiness.get('tinhId'); }
  get huyenId() { return this.formBusiness.get('huyenId'); }
  get xaId() { return this.formBusiness.get('xaId'); }
  get soNha() { return this.formBusiness.get('soNha'); }
  get duong() { return this.formBusiness.get('duong'); }

  get username() { return this.formBusiness.get('username'); }
  get password() { return this.formBusiness.get('password'); }

  /**
   * Bắt sự kiện nhập Số điện thoại
   * @param event 
   */
  handleInputSdt(event) {
    const regex = new RegExp(/^\d+$/);
    if (regex.test(event.target.value) && event.target.value.length <= 10) {
      return;
    } else {
      this.sdt.setValue(event.target.value.slice(0, -1));
    }
  }

  /**
   * Check valid Họ và tên
   * @param fullname 
   */
  isValidFullName(fullname) {
    if (fullname === null || fullname === undefined || fullname === '') return;
    fullname = fullname.toLowerCase();
    fullname = fullname.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    fullname = fullname.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    fullname = fullname.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    fullname = fullname.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    fullname = fullname.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    fullname = fullname.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    fullname = fullname.replace(/đ/g, "d");

    var re = /^[a-zA-Z\s]+$/g // regex here
    return re.test(fullname);
  }

  /**
   * Bắt sự kiện nhập Họ và tên
   * @param event 
   */
  handleInputFullname(event) {
    let value = event.target.value;
    console.log(value);

    // if (this.isValidFullName(event.target.value)) {
    //   return;
    // } else {
    //   this.hoVaTen.setValue(value.substring(0, value.length - 1), { emitEvent: false });
    // }
  }

  omit_special_char(e) {
    var k;
    console.log(e);
    console.log(this.isValidFullName(e.key));
    document.all ? k = e.keyCode : k = e.which;
    return this.isValidFullName(e.key);
  }

  //MARK: Khởi tạo input ngày sinh
  makeAutoFormatDate() {
    let ngaySinh = this.ngaySinh
    var cleaveNgaySinh = new Cleave('#ngaySinh', {
      date: true,
      datePattern: ['d', 'm', 'Y'],
      onValueChanged: function (e) {
        ngaySinh.setValue(e.target.value);
      }
    });
  }

  /**
   * Bắt sự kiện nhập ngày
   * @param e 
   * @param fc 
   */
  dateInput(e, fc) {
    if (e.target.value.length > 10) {
      var str = e.target.value;
      e.target.value = str.substring(0, str.length - 1);
      fc.setValue(e.target.value);
      console.log(e.target.value);
    }
  }

  //MARK: Action đăng kí
  submitRegister() {
    this.isSubmit = true;
    if (this.formBusiness.valid) {
      this.postRegisterBusiness(this.makeParam());
    }
  }

  makeParam(): Object {
    return {
      "daiDien": {
        "domain": LocationExtension.HOSTNAME,
        "ten": this.hoVaTen.value,
        "email": this.email.value,
        "sdt": this.sdt.value == "" ? null : this.sdt.value,
        "ngaySinh": this.ngaySinh.value == "" ? null : DateUtils.convertInputToFormat(this.ngaySinh.value, DateUtils.format.API),
        "gioiTinhId": Number(this.gioiTinh.value),
        "soNha": this.soNha.value == "" ? null : this.soNha.value,
        "duong": this.duong.value == "" ? null : this.duong.value,
        "xaId": this.xaId.value == "" ? null : Number(this.xaId.value),
        "huyenId": this.huyenId.value == "" ? null : Number(this.huyenId.value),
        "tinhId": this.tinhId.value == "" ? null : Number(this.tinhId.value),
        "username": this.username.value,
        "password": this.password.value
      },
      "toChuc": {
        "tenToChuc": this.tenDoanhNghiep.value,
        "tenRutGon": this.tenVietTat.value,
        "maSoThue": this.maSoThue.value
      }

    }
  }

  //MARK: Make Data Select2
  makeTinhData(): Observable<Array<Select2OptionData>> {
    let data: Array<Select2OptionData> = [];
    if (this.dmTinh.object && this.dmTinh.object instanceof Array) {
      this.dmTinh.object.forEach((item) => {
        data.push({ id: item.tinhId.toString(), text: item.tenTinh });
      });
    }
    return Observable.create((obs) => {
      obs.next(data);
      obs.complete();
    });
  }

  makeHuyenData(huyenRes: ListHuyenResponse): Observable<Array<Select2OptionData>> {
    let data: Array<Select2OptionData> = [];
    if (huyenRes.object && huyenRes.object instanceof Array) {
      huyenRes.object.forEach((item) => {
        data.push({ id: item.huyenId.toString(), text: item.tenHuyen });
      });
    }
    return Observable.create((obs) => {
      obs.next(data);
      obs.complete();
    });
  }

  makeXaData(xaRes: ListXaResponse): Observable<Array<Select2OptionData>> {
    let data: Array<Select2OptionData> = [];
    if (xaRes.object && xaRes.object instanceof Array) {
      xaRes.object.forEach((item) => {
        data.push({ id: item.xaId.toString(), text: item.tenXa });
      });
    }
    return Observable.create((obs) => {
      obs.next(data);
      obs.complete();
    });
  }

  //MARK: Action chon select2
  tinhChange(event) {
    this.tinhId.setValue(event);
    if (event !== '') {
      this.getDmHuyen(event);
      this.huyenId.setValue('');
      this.xaId.setValue('');
    }
  }

  huyenChange(event) {
    this.huyenId.setValue(event);
    if (event !== '') {
      this.getDmXa(event);
      this.xaId.setValue('');
    }
  }

  xaChange(event) {
    this.xaId.setValue(event);
  }

  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], 'error');
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, 'error');
    } else {
      this.alert('Lỗi không xác định!', 'error');
    }
  }

  //MARK: Networking
  postRegisterBusiness(param) {
    this.aasAuth.postRegisterBusiness(param).subscribe(res => {
      const data = new SignUpBusinessResponse(res);
      this.route.navigate(['/app/request-otp'], { queryParams: { 'type': 'BUSINESS', 'redirectTo': 'REGISTERACCOUNT', 'user': data.object.username, 'id': data.object.userId } });
    }, err => {
      console.log(err);
      if (err.error && err.error.message) {
        switch (err.error.message) {
          case 'ECT-00001200':
            this.email.setErrors({ unique: true });
            break;
          case 'ECT-00001201':
            this.sdt.setErrors({ unique: true });
            break
          case 'ECT-00001202':
            this.username.setErrors({ unique: true });
            break;
          default:
            this.handleError(err, ResponseMsg.MESSAGES.PARTY_USER);
        }
      }
    }, () => {

    });
  }

  getDmTinh() {
    this.catalog.getListTinh().subscribe((res) => {
      console.log(res);
      this.dmTinh = new ListTinhResponse(res);
      this.tinhData = this.makeTinhData();
    }, err => {
      console.log(err);
    }, () => {
    });
  }

  getDmHuyen(idTinh, forForm = 1) {
    this.catalog.getListHuyen(idTinh).subscribe((res) => {
      console.log(res);
      this.dmHuyen = new ListHuyenResponse(res);
      this.huyenData = this.makeHuyenData(new ListHuyenResponse(res));
    }, err => {
      console.log(err);
    }, () => {
    });
  }

  getDmXa(idHuyen, forForm = 1) {
    this.catalog.getListXa(idHuyen).subscribe((res) => {
      console.log(res);
      this.dmXa = new ListXaResponse(res);
      this.xaData = this.makeXaData(new ListXaResponse(res));
    }, err => {
      console.log(err);
    }, () => {
    });
  }
}