<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-auto">
                <h1 class="m-0 text-dark"><label>Báo cáo sản lượng </label></h1>
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>

<section class="content">
    <div class="container-fluid">
        <div class="content-right-sidebar tab-bar-custom">
            <ul class="nav nav-tabs" id="tabSignAction" role="tablist">
                <li class="nav-item nav-material">
                    <a class="nav-link d-flex " id="consumer-tab" data-toggle="pill" href="#consumer" role="tab"
                        aria-controls="consumer" aria-selected="false">
                        {{ localize.account_manager_tab_consumer }} 
                         ({{totalElementConsumer}}) 
                    </a>
                </li>
                <li class="nav-item nav-material">
                    <a class="nav-link d-flex active" id="business-tab" data-toggle="pill" href="#business" role="tab"
                        aria-controls="business" aria-selected="true">
                        {{ localize.account_manager_tab_business }} 
                        ({{totalElementBusiness}}) 
                    </a>
                </li>
                <!-- <li class="nav-item nav-material">
                    <a class="nav-link d-flex" id="enterprise-tab" data-toggle="pill" href="#enterprise" role="tab"
                        aria-controls="enterprise" aria-selected="true"> -->
                        <!-- {{ localize.account_manager_tab_enterprise }}  -->
                        <!-- Tích hợp
                        ({{totalElementEnterprise}})
                    </a>
                </li> -->
            </ul>
        </div>

        <div class="tab-content contract-tab-body" id="tabSignAction">
            <div class="tab-pane fade " id="consumer" role="tabpanel" aria-labelledby="consumer">
                <app-report-quantity-v1-consumer></app-report-quantity-v1-consumer>
            </div>
            <div class="tab-pane fade show active" id="business" role="tabpanel" aria-labelledby="business">
                <app-report-quantity-v1-business></app-report-quantity-v1-business>
            </div>
            <div class="tab-pane fade" id="enterprise" role="tabpanel" aria-labelledby="enterprise">
                <app-report-quantity-v1-enterprise></app-report-quantity-v1-enterprise>
            </div>
        </div>
    </div>
</section>