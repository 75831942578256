import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { API_KEY_STATUS } from 'src/app/utils/common/constant';
import { ConstantAlertBtn, ConstantAlertMsg } from 'src/app/utils/common/constant-alert-msg';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { API_KEY_SEND_MAIL_FORM } from 'src/app/utils/common/constant-vaildate-msg';
import { REGEXP_EMAIL } from 'src/app/utils/common/regexp';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { ApiKey } from 'src/app/utils/models/api-key/api-key-detail/api-key.model';
import { ApiKeyService } from 'src/app/utils/services/aas-network/api-key.service';

@Component({
  selector: 'app-api-key-detail',
  templateUrl: './api-key-detail.component.html',
  styleUrls: ['./api-key-detail.component.scss']
})
export class ApiKeyDetailComponent implements OnInit {
  @ViewChild('sendMailContent') _sendMail: ElementRef;

  id = this.activedRoute.snapshot.paramMap.get('id');

  formSendMail: FormGroup;
  isSubmitFormSendMail: boolean = false;
  validateSendMailMsg = API_KEY_SEND_MAIL_FORM;
  modalSendMail: any;

  apiKey: ApiKey = new ApiKey();

  lockButtonTitle = {
    ENABLED: 'Khóa',
    DISABLED: 'Mở khóa',
    '': 'Khóa'
  }

  constantStatus = {
    ENABLED: {
      text: 'Đang hoạt động',
      class: 'badge-success-new'
    },
    DISABLED: {
      text: 'Đã khóa',
      class: 'badge-danger-new'
    },
    DEFAULT: {
      text: '',
      class: 'd-none'
    }
  }

  constructor(
    private fb: FormBuilder,
    private activedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private apiKeyService: ApiKeyService
  ) { }

  ngOnInit(): void {
    this.makeFormSendMail();
    this.getDetailApiKey(this.id);
  }

  /**
   * Khoi tao form gui mail
   */
  makeFormSendMail() {
    this.formSendMail = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(REGEXP_EMAIL)]]
    });
  }

  sendMail() {
    this.createPopupSendMail();
  }

  /**
   * Tao popup gui mail
   */
  createPopupSendMail() {
    // this.formSendMail.controls.email.setValue('');
    this.isSubmitFormSendMail = false;
    this.formSendMail.reset();
    this.formSendMail.controls.email.setValue(this.apiKey.email);
    this.modalSendMail = this.modalService.open(this._sendMail, {
      size: 'md',
      backdrop: 'static',
      keyboard: false
    });
  }

  confirmSendMail() {
    this.isSubmitFormSendMail = true;
    if (this.formSendMail.valid) {
      this.alert(ConstantAlertMsg.API_KEY_SEND_MAIL_CONFIRM, ConstantAlertType.BLANK, true, '', ConstantAlertBtn.CONFIRM, () => {
        this.modalSendMail && this.modalSendMail.close();
        this.postSendMail(this.id, this.formSendMail.value.email, this.apiKey.username);
      })
    }
  }

  /**
   * Khoa
   */
  lockApiKey() {
    this.alert(ConstantAlertMsg.API_KEY_LOCK, ConstantAlertType.BLANK, true, '', ConstantAlertBtn.CONFIRM, () => {
      this.putChangeStatusApiKey(this.id, API_KEY_STATUS.DISABLED);
    })
  }

  /**
   * Mo khoa
   */
  unlockApiKey() {
    this.alert(ConstantAlertMsg.API_KEY_UNLOCK, ConstantAlertType.BLANK, true, '', ConstantAlertBtn.CONFIRM, () => {
      this.putChangeStatusApiKey(this.id, API_KEY_STATUS.ENABLED);
    })
  }

  /**
   * Doi trang thai api key
   * @param oldStatus 
   */
  changeStatus(oldStatus) {
    console.log("Change status");
    switch(oldStatus) {
      case API_KEY_STATUS.ENABLED:
        this.lockApiKey();
        break;
      case API_KEY_STATUS.DISABLED:
        this.unlockApiKey();
        break;
    }
  }

  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = ConstantAlertBtn.OK, actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = ConstantAlertBtn.CANCEL, actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], ConstantAlertType.ERROR);
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, ConstantAlertType.ERROR);
    } else {
      this.alert(ConstantAlertMsg.DEFAULT_ERROR_REQUEST, ConstantAlertType.ERROR);
    }
  }
  
  //MARK: NETWORKING
  /**
   * Thong tin chi tiet api key
   * @param id 
   */
  getDetailApiKey(id) {
    this.apiKeyService.getDetailApiKey(id).subscribe((res: any) => {
      this.apiKey = new ApiKey(res.object || {});
      this.formSendMail.controls.email.setValue(this.apiKey.email);
    }, err => {
      this.handleError(err, ResponseMsg.MESSAGES.MANAGEMENT_SERVICE);
    })
  }

  /**
   * Gui mail api key
   * @param id 
   * @param email 
   */
  postSendMail(id, email, username) {
    this.apiKeyService.postSendMail(id, email, username).subscribe((res: any) => {
      this.alert(ConstantAlertMsg.API_KEY_SEND_MAIL_SUCCESS, ConstantAlertType.SUCCESS);
    }, err => {
      this.handleError(err, ResponseMsg.MESSAGES.MANAGEMENT_SERVICE);
    });
  }

  /**
   * Doi trang thai api key
   * @param id 
   * @param status 
   */
  putChangeStatusApiKey(id, status) {
    this.apiKeyService.putChangeStatusApiKey(id, status).subscribe((res: any) => {
      switch(status) {
        case API_KEY_STATUS.ENABLED:
          this.alert(ConstantAlertMsg.API_KEY_UNLOCK_SUCCESS, ConstantAlertType.SUCCESS);
          break;
        case API_KEY_STATUS.DISABLED:
          this.alert(ConstantAlertMsg.API_KEY_LOCK_SUCCESS, ConstantAlertType.SUCCESS);
          break;
      }
      this.getDetailApiKey(id);
    }, err => {
      this.handleError(err, ResponseMsg.MESSAGES.MANAGEMENT_SERVICE);
    })
  }
}
