<div class="container-fluid">
    <div class="row">
        <div class="col-md-4">
            <div class="card shadow-none">
                <div class="card-body pb-0">
                    <div class="row pt-2 pb-2">
                        <div class="col-md-auto">
                            <label class="text-lg">{{ 'WaitConsumerPartner_title' | translate }}</label>
                        </div>

                        <div class="input-group mt-3">
                            <form [formGroup]="searchForm" class="input-group"
                                (ngSubmit)="searchData(searchForm.value.searchInput)">
                                <div class="input-group-prepend">
                                    <button class="btn btn-primary d-inline-flex pr-2 pl-2" type="submit">
                                        <span class="material-icons">search</span>
                                    </button>
                                </div>
                                <input type="text" class="form-control" formControlName="searchInput" id="searchInput"
                                    placeholder="{{ 'WaitConsumerPartner_search_placeholder' | translate }}" aria-label=""
                                    aria-describedby="basic-addon1" />
                            </form>
                        </div>
                    </div>
                </div>

                <div class="position-relative">
                    <ng-template #noData>
                        <app-empty-block></app-empty-block>
                    </ng-template>

                    <div>
                        <div class="user-group-item content-list-item px-0 pt-2">
                            <div *ngIf="partners.length > 0; else noData" class="content-list fade show">
                                <div *ngFor="let item of partners" (click)="selectPartner(item.partnerVerificationId)"
                                    class="user-group-item content-list-item px-4 pt-2"
                                    [class.active]="item.partnerVerificationId === partner.partnerVerificationId">
                                    <div class="border-bottom d-flex">
                                        <span class="material-icons icon-item pr-3">group</span>
                                        <div class="flex-grow-1">
                                            <label class="title-item">{{ item.ten !== '' ? item.ten : item.email
                                                }}</label>
                                            <p class="text-secondary">
                                                {{ 'WaitConsumerPartner_item' | translate }}: {{ item.cmnd }}
                                            </p>
                                        </div>
                                        <!-- <a class="text-decoration-underline text-primary pl-4" style="cursor: pointer;" (click)="callVideo(item.userId)">Gọi video</a> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="totalElement > maxSize" class="pagination justify-content-end pages">
                <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="5" [rotate]="true"
                    [ellipses]="false" [pageSize]="maxSize" (pageChange)="loadPage($event)" [boundaryLinks]="true"
                    aria-label="Default pagination">
                    <ng-template ngbPaginationFirst>{{ 'text_first' | translate }}</ng-template>
                    <ng-template ngbPaginationLast>{{ 'text_last' | translate }}</ng-template>
                    <ng-template ngbPaginationPrevious>&laquo;</ng-template>
                    <ng-template ngbPaginationNext>&raquo;</ng-template>
                    <ng-template ngbPaginationNumber let-page>{{ page }}
                    </ng-template>
                </ngb-pagination>
            </div>
        </div>
        <div class="col-md-8">
            <div *ngIf="partnerVerificationId" class="card shadow-none">
                <div class="card-body position-relative">
                    <div class="row pt-2 pb-0">
                        <div class="col-md-auto align-self-end">
                            <label class="text-lg">{{ 'WaitConsumerPartner_partner_info' | translate }}</label>
                        </div>
                        <div *ngIf="partner.partnerVerificationId" class="col-md-auto ml-auto">
                            <button class="btn btn-outline-primary d-inline-flex mr-3"
                                (click)="callVideo(partner.userId)" disabled placement="bottom"
                                ngbTooltip="{{ 'WaitConsumerPartner_note_3' | translate }}">
                                <span class="material-icons pr-2">videocam</span>{{ 'btn_video' | translate }}
                            </button>
                            <button class="btn btn-outline-primary d-inline-flex mr-3"
                                (click)="openRejectPopup(rejectContent)">
                                <span class="material-icons pr-2">clear</span>
                                {{ 'btn_reject' | translate }}
                            </button>
                            <button class="btn btn-primary d-inline-flex"
                                (click)="approvePartner(partner.partnerVerificationId)">
                                <span class="material-icons pr-2">done</span>
                                {{ 'btn_approved' | translate }}
                            </button>
                        </div>
                    </div>
                    <hr />
                    <!-- <label class="text-primary">{{ 'WaitConsumerPartner_info' | translate }}</label>
                    <hr class="mt-0"> -->
                    <div class="row">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-4">
                                    <label>{{ 'WaitConsumerPartner_full_name' | translate}}</label>
                                </div>
                                <div class="col-md-8">
                                    {{ partner.ten }}
                                </div>
                            </div>

                            <!-- CMND -->
                            <div *ngIf="partner.loaiGtId == '0' && !partner.gioiTinhId">
                                <div  class="row">
                                    <div class="col-md-4">
                                        <label>{{ 'WaitConsumerPartner_id_card' | translate}}</label>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partner.cmnd }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>{{ 'WaitConsumerPartner_birth' | translate}}</label>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partner.ngaySinh | date: 'dd/MM/yyy' }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>{{ 'WaitConsumerPartner_place_register' | translate}}</label>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partner.dkhktt }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>{{ 'WaitConsumerPartner_date_range' | translate}}</label>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partner.ngayCap | date: 'dd/MM/yyy' }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>{{ 'WaitConsumerPartner_issue' | translate}}</label>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partner.noiCap }}
                                    </div>
                                </div>
                            </div>

                            <!-- CCCD -->
                            <div *ngIf="partner.loaiGtId == '0' && partner.gioiTinhId">
                                <div  class="row">
                                    <div class="col-md-4">
                                        <label>{{ 'WaitConsumerPartner_id_ccdc' | translate}}</label>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partner.cmnd }}
                                    </div>
                                </div>
                                <!-- <div class="row">
                                    <div class="col-md-4">
                                        <label>{{ 'WaitConsumerPartner_gender' | translate }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        <span *ngIf="partner.gioiTinhId == 1">{{ 'female' | translate }}</span>
                                        <span *ngIf="partner.gioiTinhId == 2">{{ 'male' | translate }}</span>
                                    </div>
                                </div> -->
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>{{ 'WaitConsumerPartner_birth' | translate}}</label>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partner.ngaySinh | date: 'dd/MM/yyy' }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>{{ 'WaitConsumerPartner_place_register' | translate }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partner.dkhktt }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>{{ 'WaitConsumerPartner_date_range' | translate }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partner.ngayCap | date: 'dd/MM/yyy' }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>{{ 'WaitConsumerPartner_issue' | translate }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partner.noiCap }}
                                    </div>
                                </div>
                                
                            </div>

                            <!-- PASSPORT -->
                            <div *ngIf="partner.loaiGtId == '1'">
                                <div  class="row">
                                    <div class="col-md-4">
                                        <label>{{ 'WaitConsumerPartner_id_passport' | translate }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partner.cmnd }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>{{ 'WaitConsumerPartner_birth' | translate}}</label>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partner.ngaySinh | date: 'dd/MM/yyy' }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>{{ 'WaitConsumerPartner_place_of_birth' | translate }}Nơi sinh</label>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partner.noiSinh }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>{{ 'WaitConsumerPartner_gender' | translate }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        <span *ngIf="partner.gioiTinhId == 1">{{ 'female' | translate }}</span>
                                        <span *ngIf="partner.gioiTinhId == 2">{{ 'male' | translate }}</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>{{ 'WaitConsumerPartner_date_range' | translate }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partner.ngayCap | date: 'dd/MM/yyy' }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>{{ 'WaitConsumerPartner_issue' | translate }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partner.noiCap }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>{{ 'WaitConsumerPartner_expired_date' | translate }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partner.expiryDate | date: 'dd/MM/yyy' }}
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-4">
                                    <label>{{ 'WaitConsumerPartner_phone_number' | translate }}</label>
                                </div>
                                <div class="col-md-8">
                                    {{ partner.sdt }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <label>{{ 'WaitConsumerPartner_email' | translate }}</label>
                                </div>
                                <div class="col-md-8">
                                    {{ partner.email }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <label>{{ 'WaitConsumerPartner_account_name' | translate }}</label>
                                </div>
                                <div class="col-md-8">
                                    {{ partner.username }}
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-6">
                                    <div *ngIf="imgFront">
                                        <label>{{ 'WaitConsumerPartner_front_photo' | translate }}</label>
                                        <div>
                                            <img class="img-cmnd" [src]="imgFront" />
                                        </div>
                                    </div>
                                    <div *ngIf="imgFace">
                                        <label>{{ 'WaitConsumerPartner_face' | translate }}</label>
                                        <div>
                                            <img class="img-cmnd" [src]="imgFace" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div *ngIf="imgBack">
                                        <label>{{ 'WaitConsumerPartner_back_photo' | translate }}</label>
                                        <div>
                                            <img class="img-cmnd" [src]="imgBack" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <label class="text-primary mt-3">{{ 'WaitConsumerPartner_contract_info' | translate}}</label>
                    <hr class="mt-0">
                    <div class="row">
                        <div class="col-md-2">
                            <label>{{ 'WaitConsumerPartner_contract_name' | translate}}</label>
                        </div>
                        <div class="col-md-10">
                            {{ partner.title }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2">
                            <label>{{ 'WaitConsumerPartner_contract_file' | translate}}</label>
                        </div>
                        <div class="col-md-10">
                            <span class="file-name" (click)="detailContract(partner.contractId)">
                                {{ partner.fileName }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #rejectContent let-modal>
    <div #content class="modal-header border-0">
        <h5 class="modal-title" style="font-weight: 700; margin-right: auto;">{{ 'model_title_confirm' | translate }}</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true" class="btn-light btn-alert-exit bg-exit-btn d-inline-flex p-2 rounded">
                <img src="assets/img/icon/icon-close.svg" width="18" height="18">
            </span>
        </button>
    </div>
    <div class="modal-body text-left py-0 enable-scroll">
        <p>{{ 'WaitConsumerPartner_note_1' | translate}}</p>
        <form [formGroup]="denyForm">
            <div class="form-group">
                <label for="denyReason">{{ 'RejectedConsumerPartner_rejected_reason' | translate}} <span class="text-danger">*</span></label>
                <textarea formControlName="denyReason" id="denyReason" #denyReason
                    placeholder="{{ 'WaitConsumerPartner_note_4' | translate}}" name="denyReason" rows="5"
                    class="form-control"></textarea>
                <app-show-validate-errors [isSubmit]="isSubmitDenyForm" [errorMessages]="validateDenyMsg.denyReason"
                    [control]="denyForm.controls.denyReason" [detail]="{ name: 'denyReason' }">
                </app-show-validate-errors>
            </div>
        </form>
    </div>
    <div class="modal-footer border-0 d-flex justify-content-end">
        <button type="button" class="btn btn-outline-primary mr-2" (click)="modal.dismiss('Cross click')">
            {{ 'btn_close' | translate}}
        </button>
        <button ngbAutofocus type="button" class="btn btn-primary"
            (click)="rejectRegistration(partner.partnerVerificationId)"
            [disabled]="denyReason.invalid || denyReason.pending">
            {{ 'WaitConsumerPartner_note_2' | translate}}
        </button>
    </div>
</ng-template>

<ng-template #calling let-modal>
    <div class="modal-body">
        <div class="row justify-content-center mb-3">
            <div class="">
                <div class="avater-call-modal" [ngStyle]="{backgroundImage: 'url(' + ava_img + ')'}"></div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mb-2 text-center">
                <h4><label>{{ partner.ten }}</label></h4>
            </div>
            <div class="col-12 mb-2 text-center">
                <span><i>{{ 'WaitPartyPartner_calling_act' | translate}}</i></span>
            </div>
            <div class="col-12 mb-2 text-center">
                <span class="text-danger">{{ 'WaitPartyPartner_calling_note' | translate}}</span>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="">
                <a class="end-call-modal" (click)="actBtnEndCall()">
                    <div class="ring">
                        <div class="vdc-alo-phone vdc-alo-green vdc-alo-show">
                            <div class="vdc-alo-ph-circle"></div>
                            <div class="vdc-alo-ph-circle-fill"></div>
                            <div class="vdc-alo-ph-img-circle"></div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</ng-template>

<!-- <ng-template #approve let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Phê duyệt yêu cầu</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <label for="denyReason">Bạn cần thực hiện cuộc gọi video trước khi phê duyệt yêu cầu
                đăng ký của khách hàng, vẫn tiến hành phê duyệt?</label
            >
        </div>
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-outline-danger mr-2"
            (click)="modal.dismiss('Cross click')">Huỷ</button>
        <button
            ngbAutofocus
            type="button"
            class="btn btn-primary"
            (click)="modal.close('Save click'); approveRegistration(partner)">
            Phê duyệt
        </button>
    </div>
</ng-template> -->