import { Injectable } from '@angular/core';
import { ServicePath } from '../../common/constant-service-api';
import { API } from "../api";

const LIST_PACKAGE_URL = ServicePath.MANAGEMENT_SERVICE + "/package-information-service";
const LIST_PACKAGE_URL_V2 = ServicePath.MANAGEMENT_SERVICE + "/package-information/v1/list";
const CREATE_PACKAGE_URL = ServicePath.MANAGEMENT_SERVICE + "/package-information/";
const UPDATE_PACKAGE_URL = ServicePath.MANAGEMENT_SERVICE + "/package-information/";
const UPDATE_PACKAGE_URL_V2 = ServicePath.MANAGEMENT_SERVICE + "/package-information/v1/update/";
const GET_ALL_PRIVILEGE = ServicePath.AUTH_SERVICE + "/privilege/all-privileges";
const GET_PACKAGE_URL = ServicePath.MANAGEMENT_SERVICE + "/package-information/";
const GET_PACKAGE_URL_V2 = ServicePath.MANAGEMENT_SERVICE + "/package-information/v1/";
const DELETE_PACKAGE_URL = ServicePath.MANAGEMENT_SERVICE + "/package-information/"
const DELETE_PACKAGE_URL_V2 = ServicePath.MANAGEMENT_SERVICE + "/package-information/v1/"
const UPDATE_STATUS_URL = ServicePath.MANAGEMENT_SERVICE + "/package-information/update-status/"
const LIST_PACKAGE_ENDUSER_URL = ServicePath.MANAGEMENT_SERVICE + "/package-information";
const LIST_PACKAGE_ORDER = ServicePath.MANAGEMENT_SERVICE + "/package-information-service-order";
const PACKAGE_INFO_ENDUSER = ServicePath.MANAGEMENT_SERVICE + "/parties";
const LIST_PACKAGE_ORDER_V2 = ServicePath.MANAGEMENT_SERVICE + "/package-information-service-order-by-party?";
const DETAIL_ENDUSER_PACKAGE_V2 = ServicePath.MANAGEMENT_SERVICE + "/package-information/v1/package-of-user";
const USED_NUMBER_URL = ServicePath.REPORT_SERVICE + "/quantity-info";
const GET_UPGRADE_HISTORY_URL = ServicePath.MANAGEMENT_SERVICE + "/package-information/v1/package-history-of-user";

const LIST_CATEGORY_PACKAGE_URL = ServicePath.MANAGEMENT_SERVICE +  "/service-informations";
const DELETE_CATEGORY_PACKAGE_URL = ServicePath.MANAGEMENT_SERVICE +  "/service-informations/";
const UPDATE_CATEGORY_PACKAGE_URL = ServicePath.MANAGEMENT_SERVICE + "/service-informations/";
const GET_CATEGORY_PACKAGE_URL =  ServicePath.MANAGEMENT_SERVICE + "/service-informations/";
const CREATE_CATEGORY_PACKAGE_URL = ServicePath.MANAGEMENT_SERVICE +  "/service-informations/";

const LIST_TEMPLATE_CONTRACT_URL = ServicePath.TEMPATE_SERVICE + "/api/templates/list";
const LIST_PACKAGE_ORDER_NUMBER_URL = ServicePath.MANAGEMENT_SERVICE + "/package-information-service-order";

const LIST_PACKAGE_ORDER_BY_USER = ServicePath.MANAGEMENT_SERVICE + "/package-information/v1/list-order-by-user";

const SELECT_PACKAGE = ServicePath.MANAGEMENT_SERVICE + "/parties/register-waiting-approve";
const GET_PACKAGE_BY_PARTY = ServicePath.MANAGEMENT_SERVICE + "/parties";

const GET_TEMPLATE_CONTRACT = ServicePath.TEMPATE_SERVICE + "/api/templates/";
const GET_DETAIL_SERVICE_PACKAGE = ServicePath.MANAGEMENT_SERVICE + '/service-informations';

const GET_TEMPLATE_INFO = ServicePath.TEMPATE_SERVICE + "/api/templates/"
const LIST_CHANGE_PACKAGE_URL = ServicePath.MANAGEMENT_SERVICE +  "/parties/list-change-serivce?";
const CANCEL_REQUEST_PACKAGE = ServicePath.MANAGEMENT_SERVICE ;

const ADD_PACKAGE_SERVICE = ServicePath.MANAGEMENT_SERVICE + "/package-information/v1/create";

@Injectable({
  providedIn: 'root'
})
export class BusinessPackageService {

  constructor(private api: API) { }

  /**
   * Get list package
   * @param page
   * @param maxSize
   * @param propertiesSort
   * @param sort
   * @param keySearch
   * @param userType
   */
  getListPackage(
    page,
    maxSize,
    propertiesSort,
    sort,
    packageType = "",
    status = "",
    userType = "",
    keySearch = "",

  ) {
    const url =
      LIST_PACKAGE_URL +
      `?page=${page}&maxSize=${maxSize}&sort=${sort}&propertiesSort=${propertiesSort}&packageType=${packageType}&status=${status}&userType=${userType}&searchString=${keySearch}`;
    return this.api.get(url);
  }

  /**
   * Get list package V2
   * @param page
   * @param maxSize
   * @param propertiesSort
   * @param sort
   * @param keySearch
   * @param userType
   */
   getListPackageV2(
    page,
    maxSize,
    propertiesSort,
    packageType,
    status,
    userType,
    sort,
    searchString
  ) {
    const url = LIST_PACKAGE_URL_V2 + `?page=${page}&maxSize=${maxSize}&propertiesSort=${propertiesSort}&packageType=${packageType}&status=${status}&userType=${userType}&sort=${sort}&searchString=${searchString}`;
    return this.api.get(url);
  }

  /**
   * Get one package
   */
  getPackage(packageId) {
    const url = GET_PACKAGE_URL + `${packageId}`;
    return this.api.get(url);
  }

  getPackageV2(packInfoId) {
    const url = GET_PACKAGE_URL_V2 + `${packInfoId}`;
    return this.api.get(url);
  }

  /**
   * Get Danh sách tất cả các quyền
   */
  getAllPrivileges() {
    const url = GET_ALL_PRIVILEGE;
    return this.api.get(url);
  }


  /**
 *update package
 * @param data
 * @param packageId
 */
  putUpdatePackage(data, packageId) {
    return this.api.put(UPDATE_PACKAGE_URL + `${packageId}`, data);
  }

  putUpdatePackageV2(data, packInfoId) {
    const url = UPDATE_PACKAGE_URL_V2 + `${packInfoId}`;
    return this.api.put(url, data);
  }

  /**
  *create package
  * @param data
  */
  postCreatePackage(data) {
    return this.api.post(CREATE_PACKAGE_URL, data);
  }

  /**
  *xóa package
  * @param data
  */
  deletePackage(packageId) {
    const url = DELETE_PACKAGE_URL + `${packageId}`;
    return this.api.delete(url)
  }

  deletePackageV2(packInfoId) {
    const url = DELETE_PACKAGE_URL_V2 + `${packInfoId}`;
    return this.api.delete(url);
  }

  /**
  *thay đôi trạng thái package
  * @param data
  */
  updateStatusPackage(packageId, status) {
    const url = UPDATE_STATUS_URL + `${packageId}/${status}`;
    return this.api.put(url, null)
  }

  /**
   * Lấy danh sách gói dịch vụ cung cấp cho khác hàng  (end user)
   * Phục vụ cho màn hình chọ gói dịch vụ
   * @param page 
   * @param maxSize 
   * @param propertiesSort 
   * @param sort 
   * @param packageType 
   * @param status 
   * @param searchString 
   */
  getListPackageForEndUser(page = 1, maxSize = 5, propertiesSort = 'created', sort = 'DESC', packageType = 'NUMBER_CONTRACT_TYPE', status = 'ENABLED', userType = 'ENTERPRISE', searchString) {
    const url = LIST_PACKAGE_ENDUSER_URL + `?page=${page}&maxSize=${maxSize}&propertiesSort=${propertiesSort}&sort=${sort}&packageType=${packageType}&status=${status}&userType=${userType}&searchString=${searchString}`;
    return this.api.get(url);
  }

  //  ==============================
  //  danh mục gói dịch vụ
  //  ==============================
  getListCategoryPackage(
    page,
    maxSize,
    propertiesSort,
    sort,
    packageType = "",
    status = "",
    keySearch = "",
  ) {
    const url =
      LIST_CATEGORY_PACKAGE_URL +
      `?page=${page}&maxSize=${maxSize}&propertiesSort=${propertiesSort}&sort=${sort}&status=${status}&searchString=${keySearch}&serviceType=${packageType}`;
    return this.api.get(url);
  }

  /**
  * Get one category package
  */
  getOneCategoryPackage(serviceInfoId) {
    const url = GET_CATEGORY_PACKAGE_URL + `${serviceInfoId}`;
    return this.api.get(url);
  }


  /**
  *update category package
  * @param data
  * @param serviceInfoId
  */
  putUpdateCategoryPackage(data, serviceInfoId) {
    return this.api.put(UPDATE_CATEGORY_PACKAGE_URL + `${serviceInfoId}`, data);
  }

  /**
  *create category package
  * @param data
  */
  postCreateCategoryPackage(data) {
    return this.api.post(CREATE_CATEGORY_PACKAGE_URL, data);
  }

  /**
  *xóa category package
  * @param data
  * @param serviceInfoId
  */
  deleteCategoryPackage(serviceInfoId) {
    const url = DELETE_CATEGORY_PACKAGE_URL + `${serviceInfoId}`;
    return this.api.delete(url)
  }

  /**
   *get all list category package
   */
  getAllCategoryPackage(serviceType='CHUCNANG') {
    const url =
      LIST_CATEGORY_PACKAGE_URL + `?serviceType=${serviceType}`
    return this.api.get(url);
  }

  /**
   *  get all list template contract
   */
  getAllTemplateContract(page = 1, maxSize = 1000) {
    const url = LIST_TEMPLATE_CONTRACT_URL + `?page=${page}&maxSize=${maxSize}`;
    return this.api.get(url);
  }

  /**
   *get all list package in order number
   */
  getListPackageInOrder(userType, page = 0){
    const url = LIST_PACKAGE_ORDER_NUMBER_URL + `?userType=${userType}&page=${page}`;
    return this.api.get(url);
  }


  /**
   * Lay danh sach goi dich vu duoc quyen mua cho enduser
   * @param userType 
   */
  getListPackageActiveEndUser(userType) {
    const url = LIST_PACKAGE_ORDER + `?userType=${userType}`;
    return this.api.get(url);
  }

  /**
   * Lay danh sach goi dich vu duoc quyen mua cho enduser
   * @param userType 
   */
  getListPackageActiveEndUserV2(partyId, userType) {
    const url = LIST_PACKAGE_ORDER_V2 + `partyId=${partyId}&userType=${userType}`;
    return this.api.get(url);
  }

  /**
   * Lay thong tin goi dich vu khach hang dang su dung
   * @param userId 
  */
  getPackageInfoIsUsed(userId) {
    const url = PACKAGE_INFO_ENDUSER + `/${userId}/package-info`;
    return this.api.get(url);
  }

  /**
   * Chon mua goi dich vu
   * @param data 
   */
  postSelectPackage(data) {
    const url = SELECT_PACKAGE;
    return this.api.post(url, data);
  }

  /**
   * Lay thong tin goi dich vu bang partyid
   * @param partyId 
   */
  getPackageByPartyId(partyId, status) {
    const url = GET_PACKAGE_BY_PARTY + `/${partyId}/package-info/${status}`;
    return this.api.get(url);
  }


   /**
   * render pdf hợp đồng mẫu
   * @param templateId 
   */
  downloadTemplateContract(templateId) {
    const url = GET_TEMPLATE_CONTRACT + `${templateId}/preview`;
    return this.api.download(url);
  }

   /**
   * Lay thong tin dich vu cua goi dich vu
   * @param templateId 
   */
  getInfoContract(templateId) {
    const url = GET_TEMPLATE_INFO + `${templateId}`;
    return this.api.get(url);
  }

  /**
   * Lay thong tin dich vu cua goi dich vu
   * @param serviceId 
   */
  getInfoPackageService(serviceId) {
    const url = GET_DETAIL_SERVICE_PACKAGE + `/${serviceId}`;
    return this.api.get(url);
  }

   //  ==============================
  //  danh mục gói dịch vụ
  //  ==============================
  getListChangePackage(
    maxSize = 10 ,
    page= 1 ,
    propertiesSort= 'created',
    sort = 'DESC',
    packageId = "",
    partyType = "",
    keySearch = "",
    fromDate = '',
    toDate = ''

  ) {
    const url =
    LIST_CHANGE_PACKAGE_URL +
      `searchString=${keySearch}&partyType=${partyType}&packageId=${packageId}&fromDate=${fromDate}&toDate=${toDate}&page=${page}&maxSize=${maxSize}&propertiesSort=${propertiesSort}&sort=${sort}`;
    return this.api.get(url);
  }


  /**
   * từ chối đổi gói dv
   * @param serviceId 
   */
  cancelChange(partyId, data) {
    const url = CANCEL_REQUEST_PACKAGE + `/parties/${partyId}/change-package-reject`;
    return this.api.post(url, data);
  }

  //Them moi goi dich vu v1
  postAddPackageService(param) {
    const url = ADD_PACKAGE_SERVICE;
    return this.api.post(url, param);
  }
  
  getListPackageOrderByUser(packageType, page, maxSize) {
    const url = LIST_PACKAGE_ORDER_BY_USER + `?packageType=${packageType}&page=${page}&maxSize=${maxSize}`;
    return this.api.get(url);
  }

  // Lấy thông tin chi tiết gói dịch vụ enduser.
  // enduser-package-detail-v2.component
  getDetailEnduserPackage() {
    const url = DETAIL_ENDUSER_PACKAGE_V2;
    return this.api.get(url);
  }

  // Lấy thông tin số lượng hợp đồng đã sử dụng
  // enduser-package-detail-v2.component
  getQuantityInfo(partyId) {
    const url = USED_NUMBER_URL + `/${partyId}`;
    return this.api.get(url);
  }

  // Lấy thông tin lịch sử mở rộng hạ tầng
  // enduser-package-detail-v2.component
  getUpgradeHistory() {
    const url = GET_UPGRADE_HISTORY_URL;
    return this.api.get(url);
  }
}
