import { Injectable } from '@angular/core';
import { ServicePath } from 'src/app/utils/common/constant-service-api';
import { API } from '../../api';

const PART_LIST = ServicePath.MANAGEMENT_SERVICE + '/parties/departments';
const PART_ADD = ServicePath.MANAGEMENT_SERVICE + '/parties/create-department';
const PART_DETAIL = ServicePath.MANAGEMENT_SERVICE + '/parties/departments';
const PART_UPDATE = ServicePath.MANAGEMENT_SERVICE + '/parties';
const PART_LIST_V2 = ServicePath.MANAGEMENT_SERVICE + '/parties/v2/departments';

@Injectable({
  providedIn: 'root'
})
export class PartService {

  public static instance: PartService;

  constructor(
    private api: API
  ) { 
    if (!PartService.instance) {
      PartService.instance = this;
    }
    return PartService.instance;
  }


  public getParts(page, maxSize, propertiesSort, sort, status, keySearch) {
    const url = PART_LIST + `?page=${page}&maxSize=${maxSize}&=propertiesSort=${propertiesSort}&sort=${sort}&status=${status}&keySearch=${keySearch}`;
    return this.api.get(url);
  }

  postAddPart(param) {
    const url = PART_ADD;
    return this.api.post(url, param);
  }

  getPart(id) {
    const url = PART_DETAIL + `/${id}`;
    return this.api.get(url);
  }

  putUpdatePart(id, param) {
    const url = PART_UPDATE + `/${id}/update-department`;
    return this.api.put(url, param);
  }

  public getPartsV2(page, maxSize, propertiesSort, sort, status, keySearch) {
    const url = PART_LIST_V2 + `?page=${page}&maxSize=${maxSize}&=propertiesSort=${propertiesSort}&sort=${sort}&status=${status}&keySearch=${keySearch}`;
    return this.api.get(url);
  }
}
