export class BlogItem{
  public id: string;
  public title: string;
  public subTitle: string;
  public description: string;
  public avatar: string;
  public blogTypeId: number;
  public categoryId: string;
  public sortOrder: number;
  public blogTypeName: string;
  public categoryName: string;
  public createBy: string;
  public createDate: string;
  public editBy: string;
  public editDate: string;
  public language: string;

  constructor(obj = null){
    obj = obj || {};
    this.id = obj.id || '';
    this.title = obj.title || '';
    this.subTitle = obj.subTitle || '';
    this.description = obj.description || '';
    this.avatar = obj.avatar || '';
    this.blogTypeId = obj.blogTypeId || 0;
    this.categoryId = obj.categoryId || '';
    this.sortOrder = obj.sortOrder || 0;
    this.blogTypeName = obj.blogTypeName || '';
    this.categoryName = obj.categoryName || '';
    this.createBy = obj.createBy || '';
    this.createDate = obj.createDate || '';
    this.editBy = obj.editBy || '';
    this.editDate = obj.editDate || '';
    this.language = obj.language || 'vi';
  }

}