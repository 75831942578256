import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ListBusinessPackageResponse } from 'src/app/utils/models/aas-models/business-package-manager/business-package-list/list-business-package-response.model';
import { PackageService } from 'src/app/utils/models/aas-models/business-package-manager/business-package-list/business-package-item.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { Options, Select2AjaxOptions } from 'select2';
import { Select2OptionData } from 'ng-select2';
import { BusinessPackageService } from 'src/app/utils/services/aas-network/business-package.service';
import { AasValidateMessage } from 'src/app/utils/common/validate-msg-aas';

@Component({
  selector: 'app-admin-contract-manager',
  templateUrl: './admin-contract-manager.component.html',
  styleUrls: ['./admin-contract-manager.component.scss']
})
export class AdminContractManagerComponent implements OnInit {
  @ViewChild('modalCreateContract') _modalCreateContract: ElementRef;
  
  modalCreateContract: any;

  public packageData: Array<Select2OptionData> = [];
  public options: Options;

  page = 1;
  maxsize = 10;
  propertiesSort = 'created';
  sort = 'DESC';
  totalElement= 0;
  packageStatus = 'ENABLED';

  formGetPackage: FormGroup;
  isSubmit = false;
  validContractMsg = AasValidateMessage.CONTRACT_FORM_SELECT_PACKAGE;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private packageService: BusinessPackageService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.options = {
      multiple: false,
      dropdownAutoWidth: true,
      width: '100%',
      closeOnSelect: true,
      language: 'vi'
    };

    this.formGetPackage  = this.fb.group({
      packageId: new FormControl('', [Validators.required])
    });

    this.getListBusiness();
  }

  addContract() {
    this.isSubmit = false;
    this.modalCreateContract = this.modalService.open(this._modalCreateContract, {
      size: 'md',
      backdrop: 'static',
      keyboard: false
    });
  }

  submitReturn() {
    this.isSubmit = true;
    if (this.formGetPackage.valid) {
      this.modalCreateContract && this.modalCreateContract.close();
      this.router.navigate([ConstantUrl.ADMIN_CONTRACT_CREATE], { queryParams: { packageId: this.formGetPackage.value.packageId }});
    }
  }

  getListBusiness(){
    this.packageService.getListPackage(this.page, this.maxsize, this.propertiesSort,
      this.sort, '',this.packageStatus, '', '').subscribe((res)=>{
        let listBusinessPackageRes = new ListBusinessPackageResponse(res['object']);
        let listBusinesPackage = listBusinessPackageRes.data;

        this.packageData = listBusinesPackage.map((x) => {
          return {
            id: x.packInfoId,
            text: x.name
          }
        })
      })
  }
}
