import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChartDataSets, ChartType } from 'chart.js';
import { Label } from 'ng2-charts';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { ContractStatus, UserTypeConstant, VerifyStatusConst } from 'src/app/utils/common/constant';
import { ConstantAlertBtn, ConstantAlertMsg } from 'src/app/utils/common/constant-alert-msg';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { ControlAccount } from 'src/app/utils/common/control-account';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { ContractListItem } from 'src/app/utils/models/aas-models/contract/contract-list/contract-list-item.model';
import { ContractListResponse } from 'src/app/utils/models/aas-models/contract/contract-list/contract-list-response.model';
import { InfoPartyObject } from 'src/app/utils/models/aas-models/info-party/info-party-object';
import { QuantityInfoItem } from 'src/app/utils/models/aas-models/quantity-report/quantity-info/quantity-info-item.model';
import { QuantityInfoObjectResponse } from 'src/app/utils/models/aas-models/quantity-report/quantity-info/quantity-info-response.model';
import { UserInformationsItem } from 'src/app/utils/models/profile/user-info-item.model';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { AasContractService } from 'src/app/utils/services/aas-network/aas-contract.service';
import { AasQuantityReportService } from 'src/app/utils/services/aas-network/aas-quantity-report.service';
import { PackageSessionService } from 'src/app/utils/services/aas-network/package-session/package-session.service';
import { AuthService } from 'src/app/utils/services/auth.service';
import { ToastControl } from 'src/app/utils/toastr/toast-control';

@Component({
  selector: 'app-service-dashboard-admin',
  templateUrl: './service-dashboard-admin.component.html',
  styleUrls: ['./service-dashboard-admin.component.scss']
})
export class ServiceDashboardAdminComponent implements OnInit {

  constUrl = ConstantUrl;

  userInfo: UserInformationsItem = new UserInformationsItem();
  partyInfo: InfoPartyObject = new InfoPartyObject();
  quantityInfo : QuantityInfoItem;
  quantityInfoObjectRes: QuantityInfoObjectResponse;

    // bar char
    barChartLabels: Label[] = [''];
    barChartType: ChartType = 'bar';
    backgroundColor1 : Array<any> = ['#567DF4']
    backgroundColor2 : Array<any> = ['#29D395']
  
    barChartData: ChartDataSets[] = [
    ];
    barChartOption = {}

    // số lượng tài khoản
    dataAccountChart: Array<number> = []
    backgroundAccountColor : Array<any> = ['#29D395','#567DF4']
    hoverAccountColor : Array<any> = ['#cccdd6','#cccdd6' ]
    accountChartLabels = ['Còn lại', 'Đã dùng'];
    accountChartType: string = 'doughnut';
    accountChartData: ChartDataSets[] = []
    accountChartOption = {}

    // Dung lượng lưu trữ
    dataCapacity: Array<number> = []
    backgroundColor : Array<any> = ['#29D395','#567DF4']
    hoverCapacityColor : Array<any> = ['#cccdd6','#cccdd6' ]
    capacityChartLabels = ['Còn lại','Đã dùng'];
    capacityChartType: string = 'doughnut';
    capacityChartData: ChartDataSets[] = []
    capacityChartOption = {}

    
    partyId: string = this.auth.partyId;
    userId: string = this.auth.userId;

    listContract: Array<ContractListItem> = [];
    currentStage = ContractStatus;

  constructor(
    private auth: AuthService,
    private aasAuth: AasAuthService,
    private router: Router,
    private modalService: NgbModal,
    private quantityService: AasQuantityReportService,
    public packageSessionService: PackageSessionService,
    public controlAccount: ControlAccount,
    private aasContract: AasContractService,
    private myToast: ToastControl
  ) { }

  ngOnInit(): void {
    this.controlAccount.requestAPIGetAccountInfo();
    this.getAccountInfo();
    this.getListContactByOwner(this.userId, '','','','','','LC_DRAFT_SUBMIT','',1,10,'DESC','-modified','')
  }

  showToart() {
    this.myToast.toast("alo alo", "thong bao", this.myToast.toastType.blank)
  }

  getAccountInfo() {
    this.auth.userInfo.subscribe((res: UserInformationsItem) => {
      this.userInfo = res;
      console.log(this.userInfo);
      
    });

    this.auth.partyInfo.subscribe((res: InfoPartyObject) => {
      this.partyInfo = res;
      console.log(this.partyInfo);
      
    })
  }

  goToUploadGPKD() {
    this.router.navigate([this.constUrl.ACC_UPLOAD_GPKD]);
  }

  /**
   * Đến trang chi tiết hợp đòng để ký
   * @param contractId 
   */
  signContract(contractId) {
    this.router.navigate([this.constUrl.CONTRACT_DETAIL, contractId], { queryParams: { 'action': 'SIGN', 'isContractVip': 'Y' } });
  }

  /**
   * La khach hang doanh nghiep => true
   */
  get isCompany(): boolean {
    let userType = this.userInfo.userType;
    return userType === UserTypeConstant.BUSINESS || userType === UserTypeConstant.ENTERPRISE;
  }
  /**
   * La admin => true
   */
   get isAdmin(): boolean {
    let userType = this.userInfo.userType;
    return userType === UserTypeConstant.SYSADM 
  }

  /**
   * La khach hang ca nhan
   */
  get isConsumer(): boolean {
    let userType = this.userInfo.userType;
    return userType === UserTypeConstant.CONSUMER;
  }

  /**
   * Khach hang la to chuc SME hoac giai phap chua upload GPKD => true
   */
  get isStepUploadGPKD(): boolean {
    if (this.partyInfo.partyType === UserTypeConstant.BUSINESS || this.partyInfo.partyType == UserTypeConstant.ENTERPRISE) {
      if (this.partyInfo.verificationStatus === VerifyStatusConst.WAIT && !this.partyInfo.gpkdFilename) {
        return true;
      }
    }

    return false;
  }

  get isStepEkyc(): boolean {
    let status = this.userInfo.verificationStatus === VerifyStatusConst.WAIT;
    let eKyc = this.userInfo.eKyc === "N";
    let isConsumer = this.userInfo.userType === UserTypeConstant.CONSUMER;

    return status && eKyc && isConsumer;
  }

  /**
   * Khach hang la to chuc giai phap co hop dong can ky de mo dich vu => true
   */
  get haveContract(): boolean {
    if (!this.isStepUploadGPKD) {
      if (this.partyInfo.partyType === UserTypeConstant.BUSINESS || this.partyInfo.partyType == UserTypeConstant.ENTERPRISE) {
        if (this.partyInfo.verificationStatus === VerifyStatusConst.WAIT && this.partyInfo.contractVipId) {
          return true;
        }
      }
    }
    
    return false;
  }

  /**
   * Tai khoan da duoc approve => true
   */
  get approvedBlock(): boolean {
    let status = this.partyInfo.verificationStatus;
    let isApprivedBusiness = status === VerifyStatusConst.APPROVE || status === VerifyStatusConst.APPROVE_CONTRACT || status === VerifyStatusConst.APPROVE_NON_CONTRACT;
    let status1 = this.userInfo.verificationStatus;
    let isApprovedConsumer = status1 === VerifyStatusConst.APPROVE || status1 === VerifyStatusConst.APPROVE_CONTRACT || status1 === VerifyStatusConst.APPROVE_NON_CONTRACT;
    let isConsumer = this.userInfo.userType === UserTypeConstant.CONSUMER;
    let isBusiness = this.partyInfo.partyType === UserTypeConstant.BUSINESS || this.partyInfo.partyType === UserTypeConstant.ENTERPRISE;
    return (isConsumer && isApprovedConsumer) || (isBusiness && isApprivedBusiness);
  }


  get waitApproveBlock(): boolean {
    let status = this.partyInfo.verificationStatus === VerifyStatusConst.WAIT && this.userInfo.verificationStatus === VerifyStatusConst.WAIT;
    let uploadedGPKD = this.partyInfo.gpkdFilename !== "";
    let level = this.partyInfo.userInformations.identityLevel === 1 || true;
    let eKYC = this.userInfo.eKyc == "Y";
    let isConsumer = this.userInfo.userType === UserTypeConstant.CONSUMER;
    let isBusiness = this.partyInfo.partyType === UserTypeConstant.BUSINESS || this.partyInfo.partyType === UserTypeConstant.ENTERPRISE;

    return status && ((uploadedGPKD && isBusiness) || (eKYC && isConsumer)) && level;
  }

  totalContract = 0
  totalAccount = 0
  totalData = 0
  getDataDash( partyId){
    this.quantityService.getDashboarEnduser(partyId).subscribe((res)=>{
      this.quantityInfoObjectRes = new QuantityInfoObjectResponse(res)
      this.quantityInfo = this.quantityInfoObjectRes.data

      // char thể hiện hợp đồng đã sử dụng và còn lại
      let data1 = []
      let data2 = []
      data1.push(this.quantityInfo?.allowedNumber)
      data2.push(this.quantityInfo?.usedNumber)
      this.totalContract = this.quantityInfo?.allowedNumber + this.quantityInfo?.usedNumber;
      this.barChartData.push(
        { data: data1, label: 'Còn lại', backgroundColor: this.backgroundColor2 }
      )
      this.barChartData.push(
        { data: data2, label: 'Đã dùng', backgroundColor: this.backgroundColor1 }
      )

      this.barChartOption = {
        scaleShowVerticalLines: true,
        responsive: true,
        legend: {
          display: true,
          position: 'bottom'
        },
        scales: {
          xAxes: [{
              barThickness : 20,
              barDatasetSpacing : 10,
              barValueSpacing : 10
          }],
          yAxes: [{
            barPercentage: 1.0,
            categoryPercentage: 1.0,
        }],
    
      }
      }

      
      // char thể hiện số account đẫ dùng và còn lại
      this.dataAccountChart.push(this.quantityInfo?.allowedAccount);
      this.dataAccountChart.push(this.quantityInfo?.usedAccount);
      console.log(this.dataCapacity,'this.dataCapacity========')
      this.totalAccount = this.quantityInfo?.allowedAccount + this.quantityInfo?.usedAccount;
      this.accountChartData = [
        { data: this.dataAccountChart, label: 'Dung lượng lưu trữ', backgroundColor: this.backgroundAccountColor,
          hoverBackgroundColor: this.hoverAccountColor }
      ];
      this.accountChartOption = {
        // scaleShowVerticalLines: true,
      responsive: true,
      legend: {
        display: true,
        position: 'bottom'
      },
      tooltips: {
        callbacks: {
          label: (tooltipItems, data) => {
              return data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index] + ' users';
          }
      },
      },
      }

      // char thể hiện dung lượng lưu trữ đã dùng và còn lại
      this.dataCapacity.push(this.quantityInfo?.allowedData);
      this.dataCapacity.push(this.quantityInfo?.usedData);
      this.totalData = this.quantityInfo?.allowedData + this.quantityInfo?.usedData;
      this.capacityChartData = [
        { data: this.dataCapacity, label: 'Dung lượng lưu trữ', backgroundColor: this.backgroundColor,
          hoverBackgroundColor: this.hoverCapacityColor }
      ];
      this.capacityChartOption = {
        // scaleShowVerticalLines: true,
        responsive: true,
        legend: {
          display: true,
          position: 'bottom'
        },
        tooltips: {
          callbacks: {
            label: (tooltipItems, data) => {
                return data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index] + ' GB';
            }
        },
        },
      }
      })
  }

  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = ConstantAlertBtn.OK, actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = ConstantAlertBtn.CANCEL, actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], ConstantAlertType.ERROR);
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, ConstantAlertType.ERROR);
    } else {
      this.alert(ConstantAlertMsg.DEFAULT_ERROR_REQUEST, ConstantAlertType.ERROR);
    }
  }

  //MARK: NETWORKING
    /**
   * Thong tin user dang login
   * @param userId 
   */
  getUserInfo(userId) {
    this.aasAuth.getUserInfo(userId).subscribe((res: any) => {
      this.userInfo = new UserInformationsItem(res.object || {});
    }, err => {
      this.handleError(err, ResponseMsg.MESSAGES.PARTY_USER);
    })
  }

  /**
   * Thong tin party dang login
   * @param partyId 
   */
  getPartyInfo(partyId) {
    this.aasAuth.getPartyInfo(partyId).subscribe((res: any) => {
      this.partyInfo = new InfoPartyObject(res.object || {});
    }, err => {
      this.handleError(err, ResponseMsg.MESSAGES.PARTY_USER);
    })
  }
  getListContactByOwner(partyId, partnerId, partnerType, serviceId, fromDate, toDate, status, keySearch, page, maxSize, sort, propertiesSort, signTurn) {
    this.aasContract.getListContactByOwner(partyId, partnerId, partnerType, serviceId, fromDate, toDate, status, keySearch, page, maxSize, sort, propertiesSort, signTurn).subscribe(res => {
      var response = new ContractListResponse(res);

      this.listContract = response.object.data;
      console.log(this.listContract);

    }, err => {
      console.log(err);
      this.listContract = [];
      
    });
  }
   //Ký hợp đồng
   goToSign(id) {
    this.router.navigate(['/app/console/contract', id], { queryParams: { 'action': 'SIGN' } })
  }

}
