<!-- <app-spinner></app-spinner> -->
<div class="ct-wv-content ct-enable-scroll ">
    <div class="ct-wv-container">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <!-- <div class="row justify-content-center mt-5 pt-5 pb-2 mb-2">
                    <div class="col-md-10">
                        <div class="text-center">
                            <h1 class="font-weight-bold">Chào mừng bạn đến với hệ thống VNPT eContract</h1>
                            <h5 class="text-muted">Chào mừng bạn đến với hệ thống VNPT eContract</h5>
                        </div>
                    </div>
                </div> -->
                <div class="row justify-content-center my-3 py-5">
                    <div class="col-xl-8 col-lg-9 col-md-10 col-sm-11">
                        <h2 class="font-weight-bold text-center mb-3">Xác nhận OTP</h2>
                        <div class="card bg-white shadow-lg rounded-2">
                            <div class="card-body">
                                <div *ngIf="destinationOfOtp != null" class="decr-wv-verify-otp mb-4">
                                    <span *ngIf="emailOrPhoneNumber" class="note pb-4">
                                        {{ 'ServiceDashboardEnduser_greeting' | translate }} <span
                                            class="username font-weight-bold">{{ username }}</span>! <br>
                                        {{ 'VerifyOTPLogin_label_3' | translate : {email: destinationOfOtp} }}
                                    </span>
                                    <span *ngIf="!emailOrPhoneNumber" class="note pb-4">
                                        {{ 'ServiceDashboardEnduser_greeting' | translate }} <span
                                            class="username font-weight-bold">{{ username }}</span>! <br>
                                        {{ 'VerifyOTPLogin_label_4' | translate : {phoneNumber:
                                        destinationOfOtp} }}
                                    </span>
                                </div>
        
                                <form [formGroup]="formOTP">
                                    <div class="form-group">
                                        <!-- <label for="">{{ 'Login_account' | translate }}<span class="text-danger">*</span></label> -->
                                        <input appOnlyNumber class="form-control" formControlName="otp"
                                            placeholder="{{ 'OtpForgotPassword_h_4' | translate }}" type="text"
                                            maxlength="6">
                                        <!-- <app-show-validate-errors [isSubmit]="isSubmit"
                                            [errorMessages]="ServiceMsg.otp" [control]="otp"
                                            [detail]="{ name: 'otp' }">
                                        </app-show-validate-errors> -->
                                    </div>
                            
                                    <h6 *ngIf="resend == false" class="footer-otp mb-3">{{ 'OtpForgotPassword_h_1' |
                                        translate }} <a *ngIf="count === 0" (click)="resendOTP()"
                                            class="cursor-pointer text-primary text-decoration-underline">{{
                                            'OtpForgotPassword_h_2' | translate }}</a>
                                        <span class="text-muted" *ngIf="count > 0">{{ 'VerifyOTPLogin_label_1' |
                                            translate: {counter: counter$ | async} }}</span>
                                    </h6>
                                    <span *ngIf="resend == true" class="note">
                                        {{ 'VerifyOTPLogin_label_2' | translate }}
                                    </span>
                            
                            
                                    <button [disabled]="disabledButton" (click)="submitOTP()"
                                        [ngClass]="{'disabled-button': disabledButton}" class="btn btn-lg btn-primary btn-block b-radius-5px mt-2">{{
                                        'btn_continue' | translate }}</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
