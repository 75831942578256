import {ContractYearItem} from "./detail-info-item.model"
import { MainResponse } from '../../../../main-response.model';

export class ContractYearItemResponse extends MainResponse {
    
    constructor(obj = null) {
        super();
        obj = obj || {};
        this.init(obj);
        this.object = new ContractYearItem(obj.object || {});
    }
       
}