import { AfterViewInit, Component, OnInit } from '@angular/core';
import { GlobalConfig } from 'src/app/utils/common/global-config/global-config';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/utils/services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthLoginResponse } from 'src/app/utils/models/auth/auth-login/auth-login-response.model';
import { TokenResponse } from 'src/app/utils/models/token-response/token-response.model';
import { UserInformationsResponse } from 'src/app/utils/models/profile/user-info-response.model';
import { LocationExtension } from 'src/app/utils/common/LocationExtension';
import { AasValidateMessage } from 'src/app/utils/common/validate-msg-aas';
import { AasConsumerManagerService } from 'src/app/utils/services/aas-network/aas-consumer-manager.service';
import { FirebaseService } from 'src/app/utils/services/aas-network/firebase/firebase.service';
import { NotificationsService } from 'src/app/utils/services/aas-network/notifications.service';
import { PackageSessionService } from 'src/app/utils/services/aas-network/package-session/package-session.service';
import { ConstantAlertBtn, ConstantAlertMsg } from 'src/app/utils/common/constant-alert-msg';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { InfoPartyObject } from 'src/app/utils/models/aas-models/info-party/info-party-object';
import { UserInformationsItem } from 'src/app/utils/models/profile/user-info-item.model';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { WebviewLocalize } from '../../wv-ultils/wv-localize';
import { WebviewURL } from '../../wv-ultils/wv-const-url';
import { WvAppComponent } from '../../wv-app.component';
import { Inject } from '@angular/core';
import { REGEXP_USERNAME } from 'src/app/utils/common/regexp';

@Component({
  selector: 'app-wv-login',
  templateUrl: './wv-login.component.html',
  styleUrls: ['./wv-login.component.scss']
})
export class WvLoginComponent implements OnInit {
  redirectUrl = this.activedRoute.snapshot.queryParamMap.get('redirectUrl') || '';
  domain = this.activedRoute.snapshot.queryParamMap.get('domainname') || "";

  accessToken = this.activedRoute.snapshot.queryParamMap.get("accessToken") || "";
  contractId = this.activedRoute.snapshot.queryParamMap.get("contractId") || "";
  documentId = this.activedRoute.snapshot.queryParamMap.get("documentId") || "";
  action = this.activedRoute.snapshot.queryParamMap.get("action") || "";

  loginForm: FormGroup;
  isSubmit = false;
  loginFailed = false;

  token = new AuthLoginResponse();
  tokenResponse = new TokenResponse();
  userInfo = new UserInformationsResponse();
  partyId = '';
  isLoading = false;
  saveLogin = true;

  validateMsg = AasValidateMessage.LOGIN;

  constructor(
    private router: Router,
    private aasConsumerManagerService: AasConsumerManagerService,
    private activedRoute: ActivatedRoute,
    private authService: AuthService,
    private firebaseService: FirebaseService,
    private notiService: NotificationsService,
    public packageSessionService: PackageSessionService,
    private modalService: NgbModal,
    private myAlert: AlertControl,
    @Inject(WvAppComponent) private parent: WvAppComponent
  ) {
    this.makeLoginForm();
  }

  ngOnInit(): void {
    this.parent.backFn = () => {
      window.opener.postMessage({
        status: "CANCEL"
      }, "*");
      var daddy = window.self;
      daddy.opener = window.self;
      daddy.close();
    }
    this.parent.isExit = true;

    this.viewDidLoad();
  }

  ngAfterViewInit(): void {
    var videos = document.getElementsByTagName("video");
    for (var i = 0; i < videos.length; i++) {
      videos[i].muted = true;
      videos[i].play();
    } 
  }

  viewDidLoad() {
    // if (this.key) {
    //   this.authService.setKey(atob(this.key) || "");
    // }

    if (this.accessToken) {
      this.processToken(this.accessToken);
    }
  }

  //MARK: Khởi tạo form login
  makeLoginForm() {
    this.loginForm = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.pattern(REGEXP_USERNAME)]),
      password: new FormControl(null, Validators.required)
    });
  }

  get email() { return this.loginForm.get('email') }
  get password() { return this.loginForm.get('password') }

  //MARK: Chức năng đăng nhập
  /**
   * Action đăng nhập
   */
  login() {
    this.isSubmit = true;
    this.loginFailed = false;
    if (this.loginForm.valid) {
      let domain = '';

      if (this.domain) domain = `${this.domain}.${GlobalConfig.HOST_SUBDOMAIN}`;
      else domain = LocationExtension.HOSTNAME;

      var param = {
        domain: domain,
        username: this.loginForm.value.email,
        password: this.loginForm.value.password,
        grant_type: "password",
        client_id: "clientapp",
        client_secret: "password"
      }

      this.postOauthToken(param);
    }
  }

  /**
   * Đăng nhập
   * @param param 
   */
  postOauthToken(param) {
    this.authService.postOauthToken(param).subscribe((res: any) => {
      var result: any;
      result = new AuthLoginResponse(res);
      this.authService.setKey(result.key);
      this.token = new AuthLoginResponse(res);

      this.processToken(this.token.access_token);
    }, err => {
      if (err['status'] === 401 || err['status'] === 400) {
        if (err.error.message && ['COE-00000008','COE-00000009'].includes(err.error.message)) {
          let errSplit = err.error.error?.toString().split('|');
          this.myAlert.showAlertOnlyNoti(errSplit[0],'error');
        } else {
          this.myAlert.showAlertOnlyNoti(WebviewLocalize.login_failed, ConstantAlertType.ERROR, "", ConstantAlertBtn.OK);
        }
      }
      this.authService.removeAllLocalStorage();
    });
  }

  processToken(access_token: any) {
    let jwtToken = this.authService.jwtDecode(access_token);
    this.authService.setToken(access_token);
    this.authService.setUserId(jwtToken.idUser);
    this.authService.setUsername(jwtToken.user_name);
    this.authService.setKey(jwtToken.key);
    console.log(this.authService.jwtDecode(this.authService.getToken()))

    this.getUserInfo(this.authService.jwtDecode(this.authService.getToken()).idUser);
  }

  /**
   * Lấy thông tin user đăng nhập
   * @param id 
   */
  getUserInfo(id) {
    this.authService.getUserInfo(id).subscribe((res: any) => {
      this.userInfo = new UserInformationsResponse(res);
      this.authService.setUserInfo(new UserInformationsItem(res.object || {}));
      this.authService.internalPartyId = this.userInfo.object.partyId;
      this.authService.setPartyId(this.userInfo.object.referenceId || this.userInfo.object.partyId);

      this.getPartyInfo(this.authService.partyId);

      this.loginSuccess();
    }, err => {
      this.handleError(err, ResponseMsg.MESSAGES.MANAGEMENT_SERVICE);
      this.loginSuccess();
    });
  }

  /**
   * Thong tin party dang login
   * @param partyId 
   */
  getPartyInfo(partyId) {
    this.authService.getPartyInfo(partyId).subscribe((res: any) => {
      this.authService.setPartyInfo(new InfoPartyObject(res.object || {}));
    }, err => {
    });
  }

  loginSuccess() {
    const uuid = this.authService.jwtDecode(this.authService.getToken()).idUser;
    var urlSplit = this.redirectUrl.split('/').splice(0, 3);

    this.firebaseService.requestRegisterDevive(uuid);
    this.redirectUrl !== '' ? this.router.navigateByUrl(urlSplit.join('/')) : this.router.navigate([WebviewURL.contract_list]);

    if (this.contractId !== "") {
      this.router.navigate([WebviewURL.contract_detail, this.contractId], { queryParams: { action: this.action }});
    } else {
      this.router.navigate([WebviewURL.contract_list]);
    }
  }


  get checkRoles() {
    const token = this.authService.getToken();
    const token_decode = this.authService.jwtDecode(token);
    const allowRoles = ['AAS_ADMIN']; //'KH_USER'
    const scope = ["AASADMIN"];

    let ok = false;

    token_decode.scope.forEach((item) => {
      if (scope.includes(item)) {
        ok = true;
      }
    })

    return ok;
  }

  //MARK: Trim value formControl

  //check Allow click
  get checkEdited() {
    if (this.password.value === '') return true;
    if (this.email.value === '') return true;
    return false;
  }

  /**
   * GET Check time expired package
   * @param partyId 
   */
  getCheckTimeExpiredPackage(partyId) {
    this.packageSessionService.getCheckTimeExpiredPackage(partyId).subscribe((res: any) => {
      this.packageSessionService.setPackageSessionInfo({
        packageExpired: res.object?.timeExpired || "",
        packageName: res.object?.packageName || "",
        packageType: res.object?.packageType || ""
      })
    }, err => {

    });
  }

  //MARK: Popup thông báo
  customAlert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = ConstantAlertBtn.OK, actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = ConstantAlertBtn.CANCEL, actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.customAlert(errorMsg[err.error.message], ConstantAlertType.ERROR);
    } else if (err.error && err.error.error) {
      this.customAlert(err.error.error, ConstantAlertType.ERROR);
    } else {
      this.customAlert(ConstantAlertMsg.DEFAULT_ERROR_REQUEST, ConstantAlertType.ERROR);
    }
  }
}
