import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  Input,
  OnDestroy
} from '@angular/core'
import { Options } from 'select2'
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { NgModalComponent } from 'src/app/layout/extensions/ng-modal/ng-modal.component'
import { AuthService } from 'src/app/utils/services/auth.service'
import { ContractPostObj } from 'src/app/utils/models/contract/detail-contract/detail-contract-item.model'
import { ContractPostResponse} from 'src/app/utils/models/contract/detail-contract/detail-contract-response.model'
import { Router } from '@angular/router'
import { AasWebsocketService } from 'src/app/utils/services/aas-network/aas-web-socket.service'
import { FileItem } from 'src/app/utils/models/file/file.model'
import { HttpEventType, HttpResponse } from '@angular/common/http'
import { UploadFileResponse } from 'src/app/utils/models/file/fileupload-response/fileupload-response.modet'
// import { ResponseErrMsg } from 'src/app/utils/common/response-msg'
import { InfoPartyObject } from 'src/app/utils/models/auth/info-party/info-party-object'
import { AasCommentService } from 'src/app/utils/services/aas-network/aas-comment.service'
import {  map } from 'rxjs/operators'
import { UserInformationsItem } from 'src/app/utils/models/profile/user-info-item.model';
import { UserInformationsResponse } from 'src/app/utils/models/profile/user-info-response.model';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { BehaviorSubject } from 'rxjs';
import { ContractObject } from 'src/app/utils/models/aas-models/contract/contract/contract-object.model'
import { LoadingService } from 'src/app/utils/services/loading.service'

@Component({
  selector: 'app-chat-manager',
  templateUrl: './chat-manager.component.html',
  styleUrls: ['./chat-manager.component.scss']
})
export class ChatManagerComponent implements OnInit, OnDestroy {
  @ViewChild('fileInputCustomerReply', { static: false }) fileInputCustomerReply: ElementRef;
  @ViewChild('fileInputCustomerFB', { static: false }) fileInputCustomerFB: ElementRef;
  @ViewChild('chat_box') set content(content: ElementRef) {
    if(content) { this.chat_box = content; }
  }
  chat_box: ElementRef;

  @Input('currentTab') _currentTab: BehaviorSubject<string> = new BehaviorSubject<string>('');
  // @Input('aasWebsocketService') aasWebsocketService: AasWebsocketService;
  set currentTab(value) {
    this._currentTab.next(value);
  };

  get currentTab() {
    return this._currentTab.getValue();
  }

  @Input() 
  get contractData() { return this.contract; }
  set contractData(value) { this.contract = new ContractObject(value); }
  private contract: ContractObject = new ContractObject();
  

  @Input() contractId: any;

  @Input() 
  get isDiscuss() { return this._isDiscuss; }
  set isDiscuss(value) { this._isDiscuss = value }
  private _isDiscuss: boolean = false;

  @Input() 
  get typeDiscuss() { return this._type; }
  set typeDiscuss(value) { this._type = value }
  private _type: string = "customer"; //internal

  partyId: string = ''
  documents: Array<any> = []
  checkedList: any
  form: FormGroup
  contractForm: FormGroup
  formDeny: FormGroup
  contractPostForm: FormGroup
  options: Options
  customerOptions: Options
  contractFields: Array<any> = []
  contractPost: ContractPostObj[] = []

  directorCommentFileObj: File[] = []
  customerCommentFileObj: File[] = []
  directorPostFileObj: File[] = []
  customerPostFileObj: File[] = []
  arrFile: FileItem[] = []
  img_prefix = 'data:image/png;base64,'
  selectedDocumentId = ''
  selectedGroupId = ''
  savedPreview = false
  selectedTab = 1
 

  imgType = ['image/jpeg', 'image/png', 'image/jpg']
  imgDSign: File = null
  isClickEdit = false
  type = ''
  maxFile = false
  countMaxFile = 10
  userId = ''
  uploadingFile = false
  isCustomer = true
  currentSide = 'SIDE_A'
  typeFile = [
    'image/png',
    'image/jpeg',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword',
    'application/vnd.ms-excel'
  ]

  //mark: điều kiện action
  allowEdit: boolean = false;

  isSubmit: boolean = false;

  username: string = '';
  email: string = '';

  //mark: Infor B
  partyInfoB: InfoPartyObject = new InfoPartyObject();
  userInfo: UserInformationsItem = new UserInformationsItem();
  userInformationsRes : UserInformationsResponse
  aasWebsocketService: AasWebsocketService;
  
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private changeDetectorRef: ChangeDetectorRef,
    private router: Router,
    // private websocketService: AasWebsocketService,
    private modalService: NgbModal,
    private commentService: AasCommentService,
    private myLoader: LoadingService
  ) {
    this.partyId = this.authService.partyId
    this.userId = this.authService.userId
    
   
    // this.websocketService = new AasWebsocketService();
  }

  ngOnInit(): void {
    this.getUserInfo(this.userId)
    this.makeFormPost()
    this.aasWebsocketService = new AasWebsocketService(this.authService);
    this.connectedHub(this.contractId)
    this.reconnectedConnection();
    this.joinSuccessNotify();
    this.messageReceivedNotify();
    // connect chat khi click vào tab chat
    // this._currentTab
    //   .subscribe(async x => {
    //     if (this.currentTab != 'CHAT' ) {
          // if (this.websocketService && !this.websocketService.available) {
          //   var vm = this
          //   // turn on websocket
          //   await this.websocketService.openSocket();
          //   this.cleanFileItemInput();
          //   // sync mesg to othes user same room
          //   this.websocketService.emit('room', { contractId: vm.contractId })
          //   this.websocketService.listen('response').subscribe((data) => {
          //     console.log(data);
          //     vm.getPost();
          //   })
          // }
        // }
        // else {
          // this.websocketService && this.websocketService.available ? this.websocketService.disconnect() : '';
      //   }
      // });

    this.getPost(true);
  }

  ngOnDestroy(){
    this.aasWebsocketService?.leaveRoom(this.contractId);
  }

  // thông báo kết nối thành công tới signalR, sau đó joinRoom với contractId
  connectedHub(contractId){
      this.aasWebsocketService?.connectionEstablished.subscribe((res)=>{
        this.aasWebsocketService?.joinRoom(contractId);
      })
  }

  // thông báo kết nối thành công tới group contractId
  joinSuccessNotify(){
    this.aasWebsocketService?.joinSuccessNotify.subscribe(res=>{
      
    })
  }

  // thông báo nhận tin nhắn từ các bên khác
  messageReceivedNotify(){
    this.aasWebsocketService?.messageReceivedNotify.subscribe(res=>{

      this.getPost(true);
    })
  }

  // thông báo reconnected thành công
  reconnectedConnection(){
    this.aasWebsocketService?.reconnectedNotify.subscribe(res=>{
      this.aasWebsocketService?.joinRoom(this.contractId);
      this.getPost();
    })
  }

  

  // check các file form
  cleanFileItemInput() {
    // reset post
    this.contractPostForm.controls.directorFb.reset();
    this.directorPostFileItem.clear();
    this.directorPostFileObj = [];
    this.contractPostForm.controls.customerFb.reset();
    this.customerPostFileItem.clear();
    this.customerPostFileObj = [];
    // reset comment
    this.contractPostForm.controls.directorReply.reset();
    this.directorCommentFileItem.clear();
    this.directorCommentFileObj = [];
    this.contractPostForm.controls.customerReply.reset();
    this.customerCommentFileItem.clear();
    this.customerCommentFileObj = [];
    // reset file input
    
    this.fileInputCustomerReply ? this.fileInputCustomerReply.nativeElement.value = '' : '';
    this.fileInputCustomerFB ? this.fileInputCustomerFB.nativeElement.value = '' : '';
  }

  // make form chat
  makeFormPost() {
    this.contractPostForm = this.fb.group({
      directorFb: new FormControl(null),
      customerFb: new FormControl(null),
      directorReply: new FormControl(null),
      customerReply: new FormControl(null),
      directorEdit: new FormControl(null),
      customerEdit: new FormControl(null),

      directorPostFileItem: this.fb.array([]),
      customerPostFileItem: this.fb.array([]),
      directorCommentFileItem: this.fb.array([]),
      customerCommentFileItem: this.fb.array([])
    })

  }

  ngAfterViewInit() {
  }

  ngAfterViewChecked() {
    this.changeDetectorRef.detectChanges()
  }
  // get thông tin khách hàng
  getUserInfo(userId){
    this.commentService.getUserInfo(userId).subscribe(res=>{
      this.userInfo = new UserInformationsItem(res['object'] || {});
      this.username = this.userInfo.username
      this.email = this.userInfo.email
      this.getPost();
    }, err => {
      this.handleError(err, ResponseMsg.MESSAGES.PARTY_USER);
    })
  }
  // checkDirectoryPost() {
  //   if (this.contractPost && this.contractPost.filter((i) => i.type == 'director').length) {
  //     return true
  //   } else {
  //     return false
  //   }
  // }

  // gửi bình luận vào DB đồng thời kết nối socket để tự động cập nhập cho người chat
  sendPost(value, type) {
    if ( (value && value != null) || this.customerPostFileObj.length ) {
      var postInfo = new FormData()
      let createdDate = new Date()
      postInfo.append('createdDate', createdDate.toISOString());
      postInfo.append('contractId', this.contractId);
      postInfo.append('userId', this.authService.userId);
      postInfo.append('username', this.username);
      postInfo.append('email', this.email);
      postInfo.append('value', value || '');
      postInfo.append('type', type);
      if (type === 'internal') {
        postInfo.append('partyId', this.authService.partyId);
      }
     
      for (let i = 0; i < this.customerPostFileObj.length; i++) {
        postInfo.append(i.toString(), this.customerPostFileObj[i]);
      }
      

      // create post
      this.postPost(postInfo);
      this.isClickEdit = false;
      this.type = type;
     
      this.contractPostForm.controls.customerFb.reset();
      this.customerPostFileItem.clear();
      this.customerPostFileObj = [];
      this.fileInputCustomerFB.nativeElement.value = '';

      // this.aasWebsocketService.sendMessage([this.contractId,value || '']);

    }
  }

  sendComment(item, value) {
    if ( (value && value != null) || this.customerCommentFileObj.length) {
      let createdDate = new Date()
      var replyInfo = new FormData()
      replyInfo.append('createdDate', createdDate.toISOString())
      replyInfo.append('contractId', this.contractId)
      replyInfo.append('userId', this.authService.userId)
      replyInfo.append('username', this.username)
      replyInfo.append('email', this.email)
      replyInfo.append('value', value || '')
      replyInfo.append('postId', item.id)
      replyInfo.append('tagEmail', item.email || '')
      replyInfo.append('tagName', item.username)
      replyInfo.append('type', this.typeDiscuss);
      if (this.typeDiscuss === "internal") {
        replyInfo.append('partyId', this.authService.partyId);
      }
     
      for (let i = 0; i < this.customerCommentFileObj.length; i++) {
        replyInfo.append(i.toString(), this.customerCommentFileObj[i])
      }
      // create Comment
      this.postComment(replyInfo)
     
      this.contractPostForm.controls.customerReply.reset();
      this.customerCommentFileItem.clear();
      this.customerCommentFileObj = [];
      this.fileInputCustomerReply.nativeElement.value = '';
      
      item.isReply = false

    }
  }

  // post bình luậ và DB => send socket nhận tín hiệu cho người nhận
  postPost(data) {
    var result: any
    this.myLoader.disabledLoader(true);
    this.commentService.postContractPost(data).subscribe(
      (res) => {
        this.myLoader.disabledLoader(false);
      },
      (err) => {
        this.alert('Đã xảy ra lỗi! Gửi phản hồi thất bại!', 'error')
        this.myLoader.disabledLoader(false);
      },
      ()=>{
        this.loadChat(data.get('value',''), true);
      }
    )
  }

  postComment(data) {
    var result: any
    this.myLoader.disabledLoader(true);
    this.commentService.postContractComment(data).subscribe(
      (res) => {
        
        this.myLoader.disabledLoader(false);
      },
      (err) => {
        this.alert('Đã xảy ra lỗi! Gửi phản hồi thất bại!', 'error')
        this.myLoader.disabledLoader(false);
      },
      ()=>{
        this.loadChat(data.get('value',''))
      }
    )
  }

  checkCustomerPost() {
    if (this.contractPost && this.contractPost.filter((i) => i.type == 'customer').length) {
      return true
    } else {
      return false
    }
  }

  putPost(data, postId) {
    var result: any
    this.myLoader.disabledLoader(true);
    this.commentService.postEditPost(data, postId).subscribe(
      (res) => {
        this.myLoader.disabledLoader(false); },
      (err) => {
        this.alert('Đã xảy ra lỗi! Gửi phản hồi thất bại!', 'error')
        this.myLoader.disabledLoader(false);
      },
      ()=>{
        this.loadChat(data.get('value',''));
      }
    )
  }

  // cập nhập trả lời vào db
  putComment(data, commentId) {
    var result: any
    this.myLoader.disabledLoader(true);
    this.commentService.postEditComment(data, commentId).subscribe(
      (res) => {
        result = res;
        this.myLoader.disabledLoader(false);
      },
      (err) => {
        this.myLoader.disabledLoader(false);
        // this.handleError(err, ResponseErrMsg.MESSAGES.COMMENT_MANAGER);
      },
      ()=>{
        this.loadChat(data.get('value',''))
      }
    )
  }

  async loadChat(data='', scrollToEnd = false){
    await this.aasWebsocketService?.sendMessage([this.contractId, data]);
    let scroll = this.chat_box.nativeElement.scrollTop == this.chat_box.nativeElement.scrollHeight;
    this.getPost(scrollToEnd || scroll);
  }
  
// gét trạng thái thành công khi upfile
  get getCountFileSuccess() {
    var count = 0
    this.arrFile.forEach((item) => {
      if (!item.noSuccess) {
        count++
      }
    })
    return count
  }

  // upload file  đính kèm vào bình luận
  async uploadFileAttachment(event, isPost) {
    console.log(event)
    console.log(isPost)
    var self = this
    if (!event || event == null || event == undefined) return
    if (this.getCountFileSuccess == this.countMaxFile) this.maxFile = true
    else {
      this.arrFile = []
      for (var i = 0; i < event.length; i++) {
        const fileItem = event[i]
        console.log(fileItem)
        let item = {
          name: fileItem.name,
          size: fileItem.size,
          type: fileItem.type
        }
        this.arrFile.push(new FileItem(item))
        console.log(this.arrFile)
        let lg = this.arrFile.length - 1
        if (!this.typeFile.includes(fileItem.type)) {
          this.arrFile[lg].error = 'File không đúng định dạng!'
        } else if (fileItem.size > 2000000) {
          this.arrFile[lg].error = 'File vượt quá dung lượng 2Mb!'
        } else {
          let formData = new FormData()
          formData.append('file', fileItem)
          this.uploadingFile = true;
          this.arrFile[lg].process = 100;
          if ( isPost == 'post') {
            this.customerPostFileItem.push(this.fb.group(this.arrFile[lg]))
            this.customerPostFileObj.push(event[lg])
          } else if ( isPost == 'comment') {
            this.customerCommentFileItem.push(this.fb.group(this.arrFile[lg]))
            this.customerCommentFileObj.push(event[lg])
          }
          this.uploadingFile = false;
          // this.commentService
          //   .uploadFileContract(formData)
          //   .pipe(
          //     map((event) => {
          //       switch (event.type) {
          //         case HttpEventType.UploadProgress:
          //           const percentDone = Math.round((100 * event.loaded) / event.total)
          //           this.arrFile[lg].process = percentDone
          //         case HttpEventType.Response:
          //           return event
          //       }
          //     })
          //   )
          //   .subscribe(
          //     (res: any) => {
          //       if (res instanceof HttpResponse) {
          //         console.log(res)
          //         const fileRes = new UploadFileResponse(res['body'])
          //         this.arrFile[lg].id = fileRes.object.fileKey
          //         this.arrFile[lg].fileUpload = fileRes.object
          //         // set fileitem list and fileobject
                 
          //        if ( isPost == 'post') {
          //           this.customerPostFileItem.push(this.fb.group(this.arrFile[lg]))
          //           this.customerPostFileObj.push(event[lg])
          //         } else if ( isPost == 'comment') {
          //           this.customerCommentFileItem.push(this.fb.group(this.arrFile[lg]))
          //           this.customerCommentFileObj.push(event[lg])
          //         }
          //         console.log(this.arrFile)
          //       }
          //     },
          //     (err) => {
          //       this.uploadingFile = false
          //       this.arrFile[lg].error = 'Tải lên không thành công!'
          //     },
          //     () => {
          //       this.uploadingFile = false
          //     }
          //   )
        }
      }
    }
  }

  addAttachment(el) {
    el.click()
  }

  


  saveEdit(item, value, isPost) {
    if (value && value != null) {
      let data = new FormData()
      data.append('value', value || '')
      data.append('lastUpdate', new Date().toISOString())
      data.append('type', this.typeDiscuss);
      if (this.typeDiscuss === "internal") {
        data.append('partyId', this.authService.partyId);
      }

      item.isEdit = false
      this.isClickEdit = true
      this.type = item.type
      isPost == 'post' ? this.putPost(data, item.id) : this.putComment(data, item.id);
    }
  }

  // action hủy edit 
  cancelEditFb(item) {
    item.isEdit = false
    this.isClickEdit = true
    this.type = item.type
  }
// download file tài liệu khi đã tải lên
  downloadDocument(contractId, fileObj) {
    this.commentService.downloadFile_v2(contractId, fileObj.originalName, fileObj.fileKey).subscribe(
      (fileData) => {
        const blob: any = new Blob([fileData], { type: fileObj.mimeType })
        var url = window.URL.createObjectURL(blob)
        var anchor = document.createElement('a')
        anchor.download = fileObj.originalName
        anchor.href = url
        anchor.click()
      },
      (err) => { },
      () => { }
    )
  }

  clickEdit(item, isPost) {
    isPost == 'post'
      ? this.contractPost.forEach((i) => (i.isEdit = false))
      : this.contractPost.forEach((i) => i.comments.forEach((comment) => (comment.isEdit = false)))
    item.isEdit = true
    this.isClickEdit = true
    this.type = item.type
    this.contractPostForm.controls.customerEdit.setValue(item.value)
  }

  clickReply(item) {
    this.contractPost.forEach((i) => (i.isReply = false))
    item.isReply = true
  }

  getUserId() {
    return this.authService.userId
  }

  checkReplyDisable(item) {
    if (item.userId != this.userId){
      return true
    }

    //Hop dong dang dam phan
    if (this.contractData.currentStage != "LC_DRAFT_DISCUSS" && this.contractData.currentStage != "LC_DRAFT_DEAL") return true;

    return false
    // if (this.contractItem.currentStage != 'LC_CONTRACT_VALID') {
    //   return true
    // }
    // return false
  }


   /**
   * REQUEST: Lấy tin nhắn đàm phán
   */
  getPost(scrollToEnd = false) {
    this.myLoader.disabledLoader(true);
    if (this.typeDiscuss == 'customer') {
      this.commentService.getCommentDetail(this.contractId).subscribe((res:any) => {
        this.contractPost = []
        if (res.object && res.object instanceof Array) {
          res.object.forEach((item) => {
            const contractPostRes = new ContractPostResponse(item)
            this.contractPost.push(contractPostRes.object)
          })
        } else {
        }
  
        if (scrollToEnd) this.scrollToEnd();
        
        this.myLoader.disabledLoader(false);
      }, err => {
        this.myLoader.disabledLoader(false);
      })
    } else if (this.typeDiscuss == 'internal') {
      this.commentService.getPostDataInternal(this.contractId, this.authService.partyId).subscribe((res: any) => {
        this.contractPost = []
        if (res.object && res.object instanceof Array) {
          res.object.forEach((item) => {
            const contractPostRes = new ContractPostResponse(item)
            this.contractPost.push(contractPostRes.object)
          })
        } else {
        }
  
        if (scrollToEnd) this.scrollToEnd();
        
        this.myLoader.disabledLoader(false);
      }, err => {
        this.myLoader.disabledLoader(false);
      })
    }
  }


  /**
   * Get customerPostFileItem
   */
  get customerPostFileItem(): any {
    return this.contractPostForm.get('customerPostFileItem') as FormArray
  }

  /**
   * Get directorPostFileItem
   */
  get directorPostFileItem(): any {
    return this.contractPostForm.get('directorPostFileItem') as FormArray
  }

  /**
   * Get customerCommentFileItem
   */
  get customerCommentFileItem(): any {
    return this.contractPostForm.get('customerCommentFileItem') as FormArray
  }

  /**
   * Get directorCommentFileItem
   */
  get directorCommentFileItem(): any {
    return this.contractPostForm.get('directorCommentFileItem') as FormArray
  }

    /**
   * Alert message
   */
  alert(msg, type, callBack = () => { }) {
    const modalRef = this.modalService.open(NgModalComponent)
    modalRef.componentInstance.message = msg
    modalRef.componentInstance.typeAlert = type
    modalRef.componentInstance.notification = true
    modalRef.componentInstance.callback = callBack
  }

    //MARK: HANDLE ERROR
  /**
   * Handle Error from request
   * @param err 
   * @param errorMsg 
   */
  handleError(err, errorMsg) {
    console.log(err);
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], 'error');
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, 'error');
    } else {
      this.alert('Lỗi không xác định!', 'error');
    }
  }

   //MARK: MODAL, ALERT THÔNG BÁO
  // Confirm modal
  modal(msg, callBack = () => { }) {
    const modalRef = this.modalService.open(NgModalComponent)
    modalRef.componentInstance.message = msg
    modalRef.componentInstance.callback = callBack
  }

  get allowSendMess() {
    //Hop dong dang dam phan
    if (this.contractData.currentStage != "LC_DRAFT_DISCUSS" && this.contractData.currentStage != "LC_DRAFT_DEAL" 
      && this.contractData.currentStage != "LC_DRAFT_WAIT_CONFIRM") return false;
  
    if (!this.isDiscuss) return false;

    return true
  }

  scrollToEnd() {
    setTimeout(() => {
      this.chat_box.nativeElement.scrollTop = this.chat_box.nativeElement.scrollHeight;
    }, 200);
  }

  isCurrentUser(userId) {
    return userId == this.authService.userId;
  }
}
