import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ValidatorsExtension } from 'src/app/utils/common/validators-extension';
import { REGEXP_EMAIL, REGEXP_MST, REGEXP_PASSWORD, REGEXP_PHONE_NUMBER, REGEXP_USERNAME } from 'src/app/utils/common/regexp';
import { SIGNUP_CONSUMER, SIGN_ON_BUSINESS } from 'src/app/utils/common/constant-vaildate-msg';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { Config } from 'src/app/config';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ConstantAlertBtn, ConstantAlertMsg } from 'src/app/utils/common/constant-alert-msg';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocationExtension } from 'src/app/utils/common/LocationExtension';
import { SignupConsumerResponse } from 'src/app/utils/models/aas-models/signup-consumer/signup-consumer-response';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { CatalogService } from 'src/app/utils/services/aas-network/catalog.service';
import { ListTinhResponse } from 'src/app/utils/models/catalog/list-tinh/list-tinh-response.model';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { localize } from 'src/app/utils/localize/localize';
import { TemplateService } from 'src/app/utils/services/aas-network/template.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-register-consumer',
  templateUrl: './register-consumer.component.html',
  styleUrls: ['./register-consumer.component.scss']
})
export class RegisterConsumerComponent implements OnInit {

  customerType = this.activedRoute.snapshot.queryParamMap.get('userType') || '';
  email = this.activedRoute.snapshot.queryParamMap.get('email') || '';
  id = this.activedRoute.snapshot.queryParamMap.get('id') || "";

  @Output() dataFilled = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    private activedRoute: ActivatedRoute,
    private aasAuth: AasAuthService,
    private modalService: NgbModal,
    private catalog: CatalogService,
    private myAlert: AlertControl,
    private templateSv: TemplateService,
    private route: Router,
    private translate: TranslateService
  ) { }

  // business

  // consumer
  formConsumerSubmit: FormGroup;
  tinhBindData = [];
  provinceSelected: any = null;
  validateMsg = SIGNUP_CONSUMER;
  checkbox_dieukhoan = false;
  isSubmit: boolean = false;

  dieuKhoan: any = "";


  ngOnInit(): void {
    this.makeForm();
    this.getDieuKhoan();

    if (this.email) {
      this.formConsumerSubmit.controls.email.setValue(this.email);
    }
  }

  /**
   * Init form thong tin ca nhan
   */
  makeForm() {
    this.formConsumerSubmit = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.pattern(REGEXP_EMAIL)]),
      sdt: new FormControl('', [Validators.required, Validators.pattern(REGEXP_PHONE_NUMBER), ValidatorsExtension.phoneNumber]),
      username: new FormControl('', [Validators.required, Validators.pattern(REGEXP_USERNAME)]),
      password: new FormControl('', [Validators.required, Validators.pattern(REGEXP_PASSWORD)]),
      tinhId: [null]
    });
    this.getDmTinh();
  }

  getDmTinh() {
    this.catalog.getListTinh().subscribe((res) => {
      let dmTinh = new ListTinhResponse(res);
      this.tinhBindData = dmTinh.object.map(item => {
        return { id: item.tinhId, label: item.tenTinh };
      });
      this.formConsumerSubmit.controls.tinhId.setValue(this.tinhBindData[0].id);
    }, err => {
      console.log(err);
    });
  }

  getDieuKhoan() {
    this.templateSv.getDieuKhoan().subscribe((res: any) => {
      res.text().then((result) => {
        this.dieuKhoan = result
      }).catch((err) => {
      });
    }, err => {
      console.log(err);
    })
  }

  /**
   * Handle input sdt
   * @param event 
   */
  handleInputSdt(event) {
    const regex = new RegExp(/^\d+$/);
    if (regex.test(event.target.value) && event.target.value.length <= 10) {
      return;
    } else {
      this.formConsumerSubmit.controls.sdt.setValue(event.target.value.slice(0, -1));
    }
  }

  submit() {
    this.isSubmit = true;
    if (this.formConsumerSubmit.valid) {
      if (!this.checkbox_dieukhoan) {
        this.myAlert.showAlertOnlyNoti(this.translate.instant('RegisterBusiness_ts_1'), "", "", this.translate.instant('btn_close'));
        return;
      }
      const param = {
        domain: LocationExtension.HOSTNAME,
        email: this.formConsumerSubmit.value.email,
        sdt: this.formConsumerSubmit.value.sdt,
        username: this.formConsumerSubmit.value.username,
        password: this.formConsumerSubmit.value.password,
        tinhId: parseInt(this.formConsumerSubmit.value.tinhId, 10)  || null
      }

      if (this.id) {
        this.postRegisterConsumerByInvited(this.id, param);
      } else {
        this.postRegisterConsumer(param);
      }
    }
  }

  //MARK: NETWORKING
  /**
   * Gui yeu dang ky khach hang ca nhan
   * @param param 
   */
  postRegisterConsumer(param) {
    this.aasAuth.postRegisterConsumer(param).subscribe((res: any) => {
      // const data = new SignupConsumerResponse(res);
      // console.log(data);

      // var emitParam = this.formConsumerSubmit.value;
      // emitParam['userId'] = data.object.userId;
      const userId = res.object.userId;
      // this.route.navigate(['/verifyotp'], { queryParams: {'id': userId, 'userType': 'CONSUMER'}});
      const data = {
        userId: userId,
        userType: 'CONSUMER'
      };
      this.dataFilled.emit(data);
      // this.next.emit(emitParam);
    }, err => {
      console.log(err);
      if (err.error && err.error.message) {
        switch (err.error.message) {
          case 'ECT-00001200':
            this.formConsumerSubmit.controls.email.setErrors({ unique: true });
            break;
          case 'ECT-00001201':
            this.formConsumerSubmit.controls.sdt.setErrors({ unique: true });
            break
          case 'ECT-00001202':
            this.formConsumerSubmit.controls.username.setErrors({ unique: true });
            break;
          default:
            this.myAlert.showErrorHandled(err);
        }
      }
    });
  }


  /**
     * Gui yeu dang ky khach hang ca nhan duoc moi
     * @param param 
     */
  postRegisterConsumerByInvited(id, param) {
    this.aasAuth.postRegisterConsumerByInvited(id, param).subscribe((res:any) => {
      // const data = new SignupConsumerResponse(res);
      // console.log(data);

      // var emitParam = this.formConsumerSubmit.value;
      // emitParam['userId'] = data.object.userId;
      const userId = res.object.userId;
      // this.route.navigate(['/verifyotp'], { queryParams: {'id': userId, 'userType': 'CONSUMER'}});
      const data = {
        userId: userId,
        userType: 'CONSUMER'
      };
      this.dataFilled.emit(data);
      // this.next.emit(emitParam);
    }, err => {
      console.log(err);
      if (err.error && err.error.message) {
        switch (err.error.message) {
          case 'ECT-00001200':
            this.formConsumerSubmit.controls.email.setErrors({ unique: true });
            break;
          case 'ECT-00001201':
            this.formConsumerSubmit.controls.sdt.setErrors({ unique: true });
            break
          case 'ECT-00001202':
            this.formConsumerSubmit.controls.username.setErrors({ unique: true });
            break;
          default:
            this.myAlert.showErrorHandled(err);
        }
      }
    });
  }

  // //MARK: Handle Error
  // handleError(err, errorMsg) {
  //   if (err.error && err.error.message && errorMsg[err.error.message]) {
  //     this.alert(errorMsg[err.error.message], ConstantAlertType.ERROR);
  //   } else if (err.error && err.error.error) {
  //     this.alert(err.error.error, ConstantAlertType.ERROR);
  //   } else {
  //     this.alert(ConstantAlertMsg.DEFAULT_ERROR_REQUEST, ConstantAlertType.ERROR);
  //   }
  // }

  // //MARK: Popup thông báo
  // alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = ConstantAlertBtn.OK, actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = ConstantAlertBtn.CANCEL, actBtn1 = () => { }) {
  //   const modalRef = this.modalService.open(AlertComponent);
  //   modalRef.componentInstance.message = msg;
  //   modalRef.componentInstance.typeAlert = type;
  //   modalRef.componentInstance.twoBtn = twoBtn;
  //   modalRef.componentInstance.iconBtn1 = iconBtn1;
  //   modalRef.componentInstance.iconBtn2 = iconBtn2;
  //   modalRef.componentInstance.titleButton1 = titleBtn1;
  //   modalRef.componentInstance.titleButton2 = titleBtn2;
  //   modalRef.componentInstance.callback1 = actBtn1;
  //   modalRef.componentInstance.callback2 = actBtn2;
  // }

  showDieuKhoan() {
    let self = this;
    const content = "<div class='e-dieukhoan'>" + this.dieuKhoan + "</div>";
      setTimeout(() => {
        self.myAlert.showAlertOnlyNoti(
          content,
          ConstantAlertType.BLANK,
          "",
          this.translate.instant('btn_close'),
          null,
          this.translate.instant('RegisterBusiness_ts_2'),
          { size: 'lg' }
        );
      });
  }
}
