import { Component } from "@angular/core";
import { AasConsumerManagerService } from "src/app/utils/services/aas-network/aas-consumer-manager.service";
import { VideoCallFirebaseMassageService } from "src/app/utils/services/aas-network/video-call-firebase-massage.service";
import { Constant } from "./utils/common/constant_videocall";
import { v4 as uuidv4 } from "uuid";
import { NgbActiveModal, NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { NgModalComponent } from "src/app/layout/extensions/ng-modal/ng-modal.component";
import { AuthService } from "./utils/services/auth.service";
import { FirebaseService } from './utils/services/aas-network/firebase/firebase.service';
import { PackageSessionService } from "./utils/services/aas-network/package-session/package-session.service";
import { PlatformLocation } from "@angular/common";
import { InfoPartyObject } from "./utils/models/aas-models/info-party/info-party-object";
import { UserInformationsItem } from "./utils/models/profile/user-info-item.model";
import { ControlAccount } from "./utils/common/control-account";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
})
export class AppComponent {
    title = "econtract";
    message;
    data;
    token;
    
    constructor(
        private authService: AuthService,
        private aasConsumerManagerService: AasConsumerManagerService,
        private videoCallFirebaseMassageService: VideoCallFirebaseMassageService,
        private firebaseService: FirebaseService,
        private packageSession: PackageSessionService,
        private modalService: NgbModal,
        public controlAccount: ControlAccount
    ) {
        this.token = this.authService.jwtDecode(this.authService.getToken());

        //Close popup when click back button browser
        window.onpopstate = () => {
            try {
                modalService.dismissAll();
            } catch (err) {

            }
        }
    }

    ngOnInit() {
        if (this.token && !this.checkExp(this.token.exp)) {
            this.authService.logout();
        } else if (this.token && this.checkExp(this.token.exp)) {
            this.controlAccount.requestAPIGetAccountInfo();
            // this.packageSession.getCheckPackageSession(this.authService.partyId);
        } 
        
        this.firebaseService.requestPermission();
        this.firebaseService.receiveMessage();
        this.message = this.firebaseService.currentMessage;

        this.createUuid();
        this.receiveMessageCall();
    }

    /**
     * Check exp jwt
     * @param exp 
     */
    checkExp(exp) {
        let now = new Date();
        let startDate = new Date(0);
        let _exp = startDate.setUTCSeconds(exp);
        
        if (_exp <= now.getTime()) return false;
        return true;
    }

    receiveMessageCall() {
        let element: HTMLElement = document.getElementById(
            "auto_trigger"
        ) as HTMLElement;
        let element2: HTMLElement = document.getElementById(
            "finishcall_trigger"
        ) as HTMLElement;
        let element3: HTMLElement = document.getElementById(
            "timeout_trigger"
        ) as HTMLElement;
        this.firebaseService.currentMessage.subscribe((message) => {
            if (message) {
                switch (message["data"]["title"]) {
                    case Constant.ACCEPTED:
                        console.log("ACCEPTED");
                        localStorage.setItem(
                            Constant.CALLER,
                            JSON.stringify(this.authService.username)
                        );
                        localStorage.setItem(
                            Constant.ROOM_INFO,
                            JSON.stringify({
                                roomId: message.data.roomId,
                                token: message.data.token,
                                domain: message.data.domain,
                            })
                        );
                        return this.videoCallFirebaseMassageService.openWindowCall();
                    case Constant.PENDING:
                        console.log("PENDING");
                        this.data = message.data;
                        element.click();
                        return;
                    case Constant.REJECTED:
                        console.log("REJECTED");
                        this.videoCallFirebaseMassageService.closeModel();
                        return;
                    case Constant.FINISHED:
                        console.log("FINISHED");
                        localStorage.removeItem(Constant.ROOM_INFO);
                        localStorage.removeItem(Constant.CONSUMER);
                        localStorage.removeItem(Constant.USER_DETAIL);
                        this.videoCallFirebaseMassageService.closeModel();
                        element2.click();
                        this.videoCallFirebaseMassageService.closeWindow();
                        return;
                    case Constant.TIMEOUT:
                        console.log("timeout");
                        localStorage.removeItem(Constant.CONSUMER);
                        this.videoCallFirebaseMassageService.closeModel();
                        element3.click();
                        return;
                    default:
                        return;
                }
            }
        });
    }

    showModalConfirm() {
        this.videoCallFirebaseMassageService.modal(
            `Bạn có một cuộc gọi video từ admin hệ thống, Bạn có muốn nhận cuộc gọi ngay lúc này?`,
            () => {
                this.videoCallFirebaseMassageService.acceptCall(this.data);
            },
            () => {
                this.videoCallFirebaseMassageService.rejectCall(this.data);
            }
        );
    }

    showFinishAlert() {
        this.videoCallFirebaseMassageService.alert(
            "Cuộc gọi đã kết thúc",
            "error"
        );
    }

    showTimeoutAlert() {
        this.videoCallFirebaseMassageService.alert(
            "Phiên cuộc gọi hết hạn",
            "error"
        );
    }

    createUuid() {
        if (!localStorage.getItem(Constant.UUID)) {
            localStorage.setItem(Constant.UUID, uuidv4());
        }
    }
}
