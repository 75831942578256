<li ngbDropdown #notiDrop="ngbDropdown" class="noti-nav nav-item dropdown" display="dynamic" [autoClose]="'outside'">
    <a ngbDropdownToggle class="nav-link" (click)="toggleDropdownMenu()" id="navbarDropdown3">
        <!-- <i class="far fa-bell"></i> -->
        <span class="material-icons text-white">
            notifications
        </span>
        <span *ngIf="countNoti > 0" class="badge badge-danger navbar-badge text-white">{{ countNoti }}</span>
    </a>
    <div #dropdownMenu ngbDropdownMenu aria-labelledby="navbarDropdown2" class="dropdown-menu dropdown-menu-xl">
        <!-- <span class="dropdown-item dropdown-header">15 Notifications</span> -->
        <div class="noti-header">
            <span class="noti-header-title"><label>{{ 'NotificationsDropdownMenu_title' | translate }}</label></span>

            <div class="dropdown">
                <a class="btn btn-dropdown dropdown-toggle hide-after options-icon-header text-secondary" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="material-icons">more_horiz</span>
                </a>

                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                    <!-- [routerLink]="['/console/licenses-manager/detail/', item.licenseId]" -->
                    <a (click)="markAllAsRead()" class="dropdown-item nav-material" style="cursor: pointer;">
                        <em class="material-icons">playlist_add_check</em> {{ 'NotificationsDropdownMenu_allRead' | translate }}</a>
                    <a (click)="deleteAllNoti()" class="dropdown-item nav-material" style="cursor: pointer;">
                        <em class="material-icons">delete_sweep</em> {{ 'NotificationsDropdownMenu_removeAll' | translate }}</a>
                </div>
            </div>
        </div>
        <div class="dropdown-divider"></div>
        <!-- (scroll)="scrollHandler($event)" -->
        <div #listnoti class="list-noti" appScrollTracker (scrollingFinished)="checkFinishedScroll()">
            <ng-template #noNoti>
                <div class="py-3 w-100 text-center no-noti">
                    <p>{{ 'NotificationsDropdownMenu_noNoti' | translate }}</p>
                </div>
            </ng-template>
            <div *ngIf="notifications.length > 0; else noNoti" id="notification-list" class="list-item">
                <div ngbDropdownItem *ngFor="let notification of notifications" class="dropdown-item">
                    <a class="noti-row cursor-pointer">
                        <div class="noti-row-content">
                            <div (click)="clickNoti(notification); notiDrop.close()" [class.read]="notification.status === 'READ'" class="l-options">
                                <div class="noti-row-title">
                                    <label>{{ notification.titleDetail }}</label>
                                </div>
                                <div class="noti-row-meta">
                                    <p class="noti-time text-primary mb-1">{{ notification.sentAt | date: 'dd/MM/yyyy - HH:mm:ss' }}</p>
                                    <p class="noti-desc">{{ notification.body }}</p>
                                </div>
                            </div>
        
                            <div class="r-options">
                                <div class="dropdown">
                                    <a class="btn btn-dropdown dropdown-toggle hide-after text-secondary" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <span class="material-icons">more_horiz</span>
                                    </a>
        
                                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                                        <a *ngIf="notification.status === 'UNREAD'" (click)="markAsRead(notification.notificationId)" class="dropdown-item nav-material" style="cursor: pointer;">
                                            <em class="material-icons">check</em> {{ 'NotificationsDropdownMenu_markRead' | translate }}</a>
                                        <a *ngIf="notification.status === 'READ'" (click)="markAsUnRead(notification.notificationId)" class="dropdown-item nav-material" style="cursor: pointer;">
                                                <em class="material-icons">undo</em> {{ 'NotificationsDropdownMenu_markUnread' | translate }}</a>
                                        <a (click)="deleteNoti(notification.notificationId)" class="dropdown-item nav-material" style="cursor: pointer;">
                                            <em class="material-icons">delete</em> {{ 'NotificationsDropdownMenu_removeNoti' | translate }}</a>
                                    </div>
                                </div>
                                <div class="status-noti" [class.d-none]="notification.status === 'READ'">
                                    <span class="material-icons text-primary">fiber_manual_record</span>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
        </div>

        <!-- <div class="dropdown-divider"></div>
        <a href="#" class="dropdown-item dropdown-footer">See All Notifications</a> -->
    </div>
</li>

<ng-template #notification>
    <a href="#" class="dropdown-item">
        <i class="fas fa-envelope mr-2"></i> 4 new messages
        <span class="float-right text-muted text-sm">3 mins</span>
    </a>
    <div class="dropdown-divider"></div>
</ng-template>