export class TemplateVar {
    inMap: string = "";
    label: string = "";
    maxLength: any = null;
    name: string = "";
    nameMap: string = "";
    required: any;
    type: string = "";
    varMaps: any[] = [];
    listVar: TemplateVar[] = [];

    constructor(d = null) {
        d = d || {};
        this.inMap = d.inMap || "";
        this.label = d.label || "";
        this.maxLength = d.maxLength;
        this.name = d.name || "";
        this.nameMap = d.nameMap || null;
        this.required = d.required;
        this.type = d.type || "";
        this.varMaps = [];
        if (d.listVar && d.listVar instanceof Array) {
            d.listVar.forEach((item) => { this.listVar.push(new TemplateVar(item)) });
        }
    }

    get isTextBox() {
        // if (this.type === 'string') return true;
        // return false;
        if (this.name.charAt(0) === '$') return true;
        return false;
    }

    get isCheckBox() {
        if (this.name.charAt(0) === '!') return true;
        return false;
    }

    get isTable() {
        // if (this.type === 'table') return true;
        // return false;
        if (this.name.charAt(0) === '#') return true;
        return false;
    }

    get makeLabel() {
        var label = '';
        if (this.name !== '' && this.name.split('{').length > 1) {
            label = this.name.split('{')[1];
            label = label.replace('}', '');
        }
        return label;
    }
}