import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';
import { StringUtils } from 'src/app/utils/common/StringUtils';
import { DualListItem } from 'src/app/utils/models/duallist/duallist-item';

@Component({
  selector: 'app-dual-list',
  templateUrl: './dual-list.component.html',
  styleUrls: ['./dual-list.component.scss']
})
export class DualListComponent implements OnInit {
  @Input('items')
  set items(value) { this._items = value; }
  get items() { return this._items; }
  _items = [];

  @Input('listAssigned')
  set listAssigned(value) { this._listAssigned = value; }
  get listAssigned() { return this._listAssigned; }
  _listAssigned = [];

  @Input('disabled')
  set disabled(value) { this._disabled = value; }
  get disabled() { return this._disabled; }
  _disabled = false;

  @Input('onlySelectParent')
  set onlySelectParent(value) { this._onlySelectParent = value; }
  get onlySelectParent() { return this._onlySelectParent; }
  _onlySelectParent = false;

  @Input('onlyView')
  set onlyView(value) { this._onlyView = value; }
  get onlyView() { return this._onlyView; }
  _onlyView = false;

  @Output('changed') changed: EventEmitter<any> = new EventEmitter();

  listdua : Array<any> =[]
  dualList1: DualListItem[] = [];
  dualList2: DualListItem[] = [];
  selectedAll1: boolean = false;
  selectedAll2: boolean = false;
  baseList: DualListItem[] = new Array<DualListItem>();
  dualList: DualListItem[][] = [new Array<DualListItem>(), new Array<DualListItem>()];   
  oriList: DualListItem[][] = [new Array<DualListItem>(), new Array<DualListItem>()];
  selectedAll = [false, false];
  filter = [false, false];
  assignedAll = [false, false]
  selectAllItem = false;
  selectchild = false;

  constructor() { }

  ngOnInit(): void {
    this.setBaseList();
    this.setupDualList(this.listAssigned);
  }

  //MARK: DUAL LIST ASSIGN PERMISSION

  /**
   * Set base list tất cả các quyền
   */
  setBaseList() {
    this.items.forEach((item, index) => {
      this.baseList.push(new DualListItem({
        id: item.id,
        name: item.name,
        index: index
      }))
      this.baseList[index].child = [];

      item.child.forEach((item1, index1) => {
        this.baseList[index].child.push(new DualListItem({
          id: item1.id,
          name: item1.name,
          index: index1
        }))
      })
    });

    console.log("baseList: ", this.baseList);
  }
  
  /**
   * Cai dai dual list truoc khi su dung
   * @param listAssigned 
   */
  setupDualList(listAssigned: any[]) {
    this.dualList[0] = this.baseList.concat([]);
    this.dualList[1] = [];
    this.backupDualList();

    listAssigned.forEach((a, i) => {
      const indexGroup = this.dualList[0].findIndex(res => { return res.id === a.id });
      if (indexGroup >= 0) {
        a.child.forEach((b, j) => {
          const indexChild = this.dualList[0][indexGroup].child.findIndex(res => { return res.id === b.id });
          if (indexGroup >= 0 && indexChild >= 0) {
            console.log("OK => idGroup: ", a.id, "; idChild: ", b.id);
            this.assign(indexGroup, indexChild, 0, 1);
          }
        })

        if (a.child.length <= 0) {
          this.assign(indexGroup, -1, 0, 1);
        }
      }
    });

    this.filter = [false, false];
    this.selectedAll = [false, false];
  }

  /**
   * An hien danh sach con
   * @param $event 
   * @param indexParent 
   * @param indexList 
   */
  showHideTreeView($event, indexParent, indexList) {
    $event.stopPropagation();
    this.dualList[indexList][indexParent].showChild = !this.dualList[indexList][indexParent].showChild
  }

  /**
   * Thao tac chon tat ca
   * @param indexList 
   */
  selectAllBtn(indexList) {
    this.selectedAll[indexList] = !this.selectedAll[indexList];
    this.dualList[indexList].forEach((item, index) => {
      item.selected = this.selectedAll[indexList];
      item.child.forEach((x, index) => {
        x.selected = this.selectedAll[indexList];
      });
    })

    // console.log(this.dualList)
  }

  /**
   * Thao tac chon goc cua cay
   * @param indexGroup 
   * @param indexList 
   * @param manualSet 
   * @param defaultValue 
   */
  selectedParentItem(indexGroup, indexList, manualSet = false, defaultValue = false) {
    //show treeview
    this.dualList[indexList][indexGroup].showChild = true;
    if (manualSet) {
      this.dualList[indexList][indexGroup].selected = defaultValue;
    } else {
      this.dualList[indexList][indexGroup].selected = !this.dualList[indexList][indexGroup].selected;
    }

    this.dualList[indexList][indexGroup].child.forEach((item, index) => {
      item.selected = this.dualList[indexList][indexGroup].selected;
    });
    if (!this.dualList[indexList][indexGroup].selected) this.selectedAll[indexList] = false;

    // console.log("selectedParentItem");
    this.resetStatusItem(indexGroup, indexList);
  }

  /**
   * Cap nhat la trang thai cua dong (da chon hay chua)
   * @param indexGroup 
   * @param indexList 
   */
  resetStatusItem(indexGroup, indexList) {
    if (this.dualList[indexList][indexGroup].child.length > 0) {
      let ok = false;
      this.dualList[indexList][indexGroup].child.forEach((item, index) => {
        if (item.selected) ok = true;
      });
      this.dualList[indexList][indexGroup].selected = ok
    }

    let ok = true;
    this.dualList[indexList].forEach((item, index) => {
      if (!item.selected) ok = false;
      item.child.forEach((x, index) => {
        if (!x.selected) ok = false;
      });
    })

    this.selectedAll[indexList] = ok;
  }

  /**
   * Thao tac chon dong con
   * @param indexGroup 
   * @param indexChild 
   * @param indexList 
   */
  selectedChildItem(indexGroup, indexChild, indexList) {
    this.dualList[indexList][indexGroup].child[indexChild].selected = !this.dualList[indexList][indexGroup].child[indexChild].selected;
    if (!this.dualList[indexList][indexGroup].child[indexChild].selected) this.selectedAll[indexList] = false;
    this.resetStatusItem(indexGroup, indexList);
  }

  /**
   * Kiem tra dieu kien enable nut gan
   * @param indexList 
   * @returns 
   */
  enableBtnAssign(indexList): boolean {
    var disable = true;
    this.dualList[indexList].forEach((item) => {
      if (item.selected) disable = false;
    })
    return disable
  }

  /**
   * Gan tung dong
   * @param indexGroup 
   * @param indexChild 
   * @param from 
   * @param to 
   */
  assign(indexGroup, indexChild, from, to) {
    const parent = this.dualList[from][indexGroup];

    if (parent.child.length <= 0) {
      if (this.dualList[from][indexGroup].child.length <= 0) {
        this.dualList[to].push(new DualListItem({
          id: parent.id,
          name: parent.name,
          index: parent.index,
          showChild: parent.showChild,
        }));
        this.dualList[from].splice(indexGroup, 1);

        this.sortList();
        this.logAssigned(parent.id, -1, from, to);
        return;
      }
    } else {
      const child = parent.child[indexChild];
      const iParent = this.dualList[to].findIndex(res => {
        return res.id === parent.id;
      })
  
      if (iParent >= 0) {
        child.selected = false;
        this.dualList[to][iParent].child.push(child);
      } else {
        this.dualList[to].push(new DualListItem({
          id: parent.id,
          name: parent.name,
          index: parent.index,
          showChild: parent.showChild,
        }));
  
        child.selected = false;
        this.dualList[to][this.dualList[to].length - 1].child.push(child);
      }
  
      this.logAssigned(parent.id, child.id, from, to);
  
      this.dualList[from][indexGroup].child.splice(indexChild, 1);
      if (this.dualList[from][indexGroup].child.length <= 0) {
        this.dualList[from].splice(indexGroup, 1);
      }
  
      console.log(this.dualList);
  
      this.sortList();  
    }

    this.changed.emit(this.dualList[1]);
  }

  /**
   * Gan nhieu hon 1 dong
   * @param from 
   * @param to 
   */
  assignMoreSelected(from, to) {
    this.selectedAll[from] = this.selectedAll[to] = false;

    for (var i = this.dualList[from].length - 1; i >= 0; i--) {
      let item = this.dualList[from][i];
      if (item.selected) {
        if (item.child.length <= 0) {
          this.assign(i, -1, from, to);
        } else {
          for (var j = item.child.length - 1; j >= 0; j--) {
            let x = item.child[j];
            if (x.selected) {
              x.selected = false;
              this.assign(i, j, from, to);
            }
          }
        }

        item.selected = false;
      }
    }

    this.sortList();

    this.changed.emit(this.dualList[1]);
  }

  /**
   * Gan 1 group
   * @param indexGroup 
   * @param from 
   * @param to 
   */
  assignGroup(indexGroup, from, to) {
    // this.selectedParentItem(indexGroup, from, true, true);
    // this.assignMoreSelected(from, to);
    let item = this.dualList[from][indexGroup].child;
    if (item.length <= 0) {
      this.assign(indexGroup, -1, from, to);
    } else {
      for (var i = item.length - 1; i >= 0; i--) {
        item[i].selected = false;
        this.assign(indexGroup, i, from, to);
      }
    }
    
    this.changed.emit(this.dualList[1]);  
  }

  /**
   * Tim kiem trong dual list
   * @param $event 
   * @param indexList 
   */
  filterSearch($event, indexList) {
    let value = StringUtils.removeAccents($event.target.value);
    let filter = [];
    this.filter[indexList] = $event.target.value !== "";
    let self = this;

    if (this.filter[indexList]) {
      this.oriList[indexList].forEach(function (x) {
        let parentOK = false;
        let childOK = false;
        let tmp = self.cloneObject(x);

        let tmp_name = StringUtils.removeAccents(tmp.name);
        if (tmp_name.toUpperCase().match(new RegExp(value.toUpperCase(), "g"))) {
          parentOK = true;
        };

        for (var i = tmp.child.length - 1; i >= 0; i--) {
          let tmp_child_name = StringUtils.removeAccents(tmp.child[i].name);
          if (!(tmp_child_name.toUpperCase().match(new RegExp(value.toUpperCase(), "g")))) {
            tmp.child.splice(i, 1);
          } else {
            childOK = true;
          }
        }
        if (parentOK && !childOK) filter.push(self.cloneObject(x));
        if (parentOK && childOK) filter.push(tmp);
        if (!parentOK && childOK) filter.push(tmp);
      });
      this.dualList[indexList] = this.cloneObject(filter);
    } else {
      this.dualList[indexList] = this.cloneObject(this.oriList[indexList]);
    }

    // console.log("-------------------------------------");
    // console.log("searched: ", filter);
    // console.log("filtered ori: ", this.oriList);
    // console.log("filter array: ", this.dualList);
    // console.log("-------------------------------------");
  }

  /**
   * Sao luu list mac dinh
   */
  backupDualList() {
    this.oriList = this.cloneObject(this.dualList);
  }

  /**
   * Sap xem lai list
   */
  sortList() {
    this.dualList.forEach((item, index) => {
      item.sort((a, b) => {
        return a.index - b.index;
      });

      item.forEach((x, y) => {
        x.child.sort((a, b) => {
          return a.index - b.index;
        });
      })
    })

    this.oriList.forEach((item, index) => {
      item.sort((a, b) => {
        return a.index - b.index;
      });

      item.forEach((x, y) => {
        x.child.sort((a, b) => {
          return a.index - b.index;
        });
      })
    })
  }

  /**
   * Log lai nhung dong da assign
   * @param idGroup 
   * @param idChild 
   * @param from 
   * @param to 
   */
  logAssigned(idGroup, idChild, from, to) {
    console.log("log Assign => idGroup: ", idGroup, "; idChild: ", idChild);
    const indexParent = this.oriList[from].findIndex(res => { return res.id === idGroup; });

    if (this.oriList[from][indexParent].child.length <= 0) {
      this.oriList[to].push(new DualListItem({
        id: this.oriList[from][indexParent].id,
        name: this.oriList[from][indexParent].name,
        index: this.oriList[from][indexParent].index,
        showChild: this.oriList[from][indexParent].showChild,
      }));
      
      this.oriList[from].splice(indexParent, 1);
      return;
    }

    const indexChild = this.oriList[from][indexParent].child.findIndex(res => { return res.id === idChild; });
    const parent = this.oriList[from][indexParent];
    const child = parent.child[indexChild];
    const iParent = this.oriList[to].findIndex(res => { return res.id === idGroup; })

    if (iParent >= 0) {
      child.selected = false;
      this.oriList[to][iParent].child.push(child);
    } else {
      this.oriList[to].push(new DualListItem({
        id: parent.id,
        name: parent.name,
        index: parent.index
      }));

      child.selected = false;
      this.oriList[to][this.oriList[to].length - 1].child.push(child);
    }

    this.oriList[from][indexParent].child.splice(indexChild, 1);
    if (this.oriList[from][indexParent].child.length <= 0) {
      this.oriList[from].splice(indexParent, 1);
    }

    this.sortList();
    console.log("ORI AFTER ASSIGNED: ", this.oriList);
  }

  /**
   * Clone lai 1 object (tao vung du lieu moi k anh huong bien hien tai)
   * @param object 
   * @returns 
   */
  cloneObject(object: any) {
    return JSON.parse(JSON.stringify(object));
  }

  /**
   * Dem so dong da gan
   */
  get countAssigned() {
    var count = 0;
    this.dualList[1].forEach((a, i) => {
      a.child.forEach((b, j) => {
        count++;
      });
    });
    return count;
  }

  /**
   * Dem so dong chua gan
   */
  get countNotAssigned() {
    var count = 0;
    this.dualList[0].forEach((a, i) => {
      a.child.forEach((b, j) => {
        count++;
      });
    });
    return count;
  }

}
