<app-spinner></app-spinner>
<div class="container-fluid">
    <div class="row login-grid">
        <div class="col-md-7 left-view-bg">

        </div>
        <div class="col-md-5 right-view-login p-4 bg-white">
            <div class="row justify-content-center mt-5 pt-5 pb-2 mb-2">
                <div class="col-xl-6 col-lg-7 col-md-10 col-sm-11">
                    <div class="text-center mb-2">
                        <h1 class="font-weight-bold">Đăng ký thành công!</h1>
                    </div>
                </div>
            </div>

            <div class="row justify-content-center py-4">
                <div class="col-xl-8 col-lg-9 col-md-10 col-sm-11">
                    <div class="content text-lg font-weight-bold mb-5">
                        <p>Chào {{ user !== '' ? user : 'bạn'}},</p>
                        <div *ngIf="typeSignUp === ''">
                            <p>Cảm ơn bạn đã đăng ký tham gia sử dụng dịch vụ VNPT eContract.</p>
                            <p>Tài khoản của bạn đã sẵn sàng và có thể sử dụng ngay bất cứ lúc nào.</p>
                            <p></p>
                            <p>Vui lòng cài đặt ứng dụng di động để thực hiện các bước xác minh tài khoản và trải nghiệm
                                ngay bây giờ</p>
                            <p>Hoặc bạn có thể theo dõi email để biết thông tin từ chúng tôi</p>
                            <a class="text-decoration-underline text-primary" href="">Link cài đặt app</a>
                        </div>
                        
                        <div *ngIf="typeSignUp === 'CONSUMER'">
                            <p>Cảm ơn bạn đã đăng ký tham gia sử dụng dịch vụ VNPT eContract.</p>
                            <p>Tài khoản của bạn đã sẵn sàng và có thể sử dụng ngay bất cứ lúc nào.</p>
                            <p>Vui lòng cài đặt ứng dụng di động để thực hiện các bước xác minh tài khoản và trải nghiệm
                                ngay bây giờ</p>
                            <p>Hoặc bạn có thể theo dõi email để biết thông tin từ chúng tôi</p>
                            <a class="text-decoration-underline text-primary" href="">Link cài đặt app</a>
                        </div>

                        <div *ngIf="typeSignUp === 'BUSINESS'">
                            <p>Cảm ơn bạn đã đăng ký tham gia sử dụng ngay bất cứ lúc nào.</p>
                            <p>Vui lòng cài đặt ứng dụng di động để thực hiện các bước xác minh tài khoản và trải nghiệm
                                ngay bây giờ</p>
                            <p>Hoặc bạn có thể theo dõi email để biết thông tin từ chúng tôi</p>
                            <a class="text-decoration-underline text-primary" href="">Link cài đặt app</a>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-12 mb-4">
                            <button (click)="goToHome()" type="submit"
                                class="btn btn-lg btn-primary btn-block b-radius-5px">
                                Quay về trang chủ
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>