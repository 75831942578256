import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UserTypeConstant } from 'src/app/utils/common/constant';

@Component({
  selector: 'app-signup-v2-step-one',
  templateUrl: './signup-v2-step-one.component.html',
  styleUrls: ['./signup-v2-step-one.component.scss']
})
export class SignupV2StepOneComponent implements OnInit {
  @Output('next') next: EventEmitter<any> = new EventEmitter<any>();

  userType = UserTypeConstant;
  userTypeSelected: string = '';

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * Chon loai khach hang
   * @param type 
   */
  selectType(type: string) {
    // if (type === this.userType.ENTERPRISE) return;
    this.userTypeSelected = type;
  }

  /**
   * Nhan nut tiep tuc
   */
  nextAction() {
    this.next.emit({
      userType: this.userTypeSelected
    })
  }
}
