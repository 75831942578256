import { Component, Inject, OnInit } from '@angular/core';
import { AccountManagerItem } from 'src/app/utils/models/aas-models/account-manager/account-manager-item';
import { AccountManagerResponse } from 'src/app/utils/models/aas-models/account-manager/account-manager-response';
import { AccountManagerService } from 'src/app/utils/services/aas-network/account-manager.service';
import { ServiceAccountManagerComponent } from '../../../service-account-manager.component';
import { NgModalComponent } from "src/app/layout/extensions/ng-modal/ng-modal.component";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';

@Component({
  selector: 'app-account-consumer-list',
  templateUrl: './account-consumer-list.component.html',
  styleUrls: ['./account-consumer-list.component.scss']
})
export class AccountConsumerListComponent implements OnInit {

  page: number = 1;
  maxSize: number = 10;
  propertiesSort: string = "modified";
  sort: string = "DESC";
  totalElement: number = 0;
  keySearch = "";
  saveKeySearch = "";
  userType:string="CONSUMER";
  accountPersonLst: Array<AccountManagerItem> = [];

  searchString: string = "";
  identityLevel = "";
  identityLevelData = [
    { value: "", label: "Tất cả" },
    { value: "1", label: "Cấp 1" },
    { value: "2", label: "Cấp 2" }
  ];

  locked = "";
  lockedData = [
    { value: "", label: "Tất cả" },
    { value: "N", label: "Đang hoạt động" },
    { value: "Y", label: "Ngưng hoạt động" }
  ];

  listConfigColTable: Array<any> = [
    { label: "Họ và tên", name: "hoVaTen"},
    { label: "Số giấy tờ", name: "soGiayTo" },
    { label: "Email", name: "email" },
    { label: "Số điện thoại", name: "sdt" },
    { label: "Ngày sinh", name: "ngaySinh" },
    { label: "Tên tài khoản", name: "tenTaiKhoan" },
    { label: "Loại định danh tài khoản", name: "loaiDinhDanhTaiKhoan"},
    { label: "Trạng thái", name: "trangThai" },
    { label: "Nhận lời mời/Thêm mới từ", name: "loiMoi"}
  ];
  configColTable = {
    hoVaTen: true,
    email: true,
    sdt: true,
    soGiayTo: true,
    ngaySinh: true,
    tenTaiKhoan: true,
    loaiDinhDanhTaiKhoan: true,
    trangThai: true,
    loiMoi: true
  }

  constructor(
    private accountManagerService: AccountManagerService,
    private modalService: NgbModal,
    @Inject(ServiceAccountManagerComponent) private parent: ServiceAccountManagerComponent,
    private myAlert: AlertControl) {}

  ngOnInit() {
    this.viewDidLoad();
  }

  viewDidLoad() {
    this.loadPage(1);
  }

  /**
   * An hien cot bang ds
   * @param e 
   * @param name 
   */
  actConfigColTableView(e, name) {
    this.configColTable[name] = !this.configColTable[name];
  }

  /**
   * Xoa bo loc
   */
  cleanFilter() {
    this.identityLevel = "";
    this.locked = "";
    this.searchString = "";
    this.configColTable = {
      hoVaTen: true,
      email: true,
      sdt: true,
      soGiayTo: true,
      ngaySinh: true,
      tenTaiKhoan: true,
      loaiDinhDanhTaiKhoan: true,
      trangThai: true,
      loiMoi: true
    }
    this.loadPage(1);
  }

  /**
   * Tim kiem voi bo loc
   */
  searchWithFilter() {
    this.loadPage(1);
  }

  /**
   * Tai trang
   * @param page 
   */
  loadPage(page) {
    this.page = page;
    this.getListPersonalAcc(this.page);
  }

  /**
   * Lay danh sach tai khoan ca nhan
   * @param page 
   */
  getListPersonalAcc(page = 1) {
    this.accountManagerService
      .getListPersonalAcc(
        this.page,
        this.maxSize,
        this.propertiesSort,
        this.sort,
        this.searchString,
        this.userType,
        this.identityLevel,
        this.locked
      )
      .subscribe(
        (res) => {
          let accountManagerResponse = new AccountManagerResponse(res);
          this.accountPersonLst = accountManagerResponse.object.data;
          this.totalElement = accountManagerResponse.object.totalElement;
          this.parent.totalElementConsumer = accountManagerResponse.object.totalElement;
          console.log(accountManagerResponse);
        },
        (err) => {
          if (!!err.error.error) {
            console.log(err.error.error[0]);
          }
        }
      );
  }
  
  reSendEmail(partyId){
    this.accountManagerService.reSendEmail(partyId).subscribe(res=>{
      this.alert("Gửi lại thông báo tài khoản thành công!","success" );
    },error=>{ 
      console.log(error)
      this.alert("Gửi lại thông báo tài khoản thất bại! Vui lòng thử lại!", "error");
    })
  }

  alert(msg, type, callBack = () => {}) {
    console.log(msg);
    const modalRef = this.modalService.open(NgModalComponent, {
        backdrop: "static",
        size: "sm",
        keyboard: false,
        centered: true,
    });
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.notification = true;
    modalRef.componentInstance.callback = callBack;
  }

  /**
   * Ngưng hoạt động tài khoản khách hàng
   * @param partyId 
   */
  disableAccount(partyId) {
    this.myAlert.showAlert("Bạn chắc chắn muốn ngưng hoạt động tài khoản này?", "", true, "", "btn_confirm", () => {
      this.accountManagerService.disableAccount(partyId).subscribe((res: any) => {
        this.loadPage(this.page);
        this.myAlert.showAlertOnlyNoti("Ngưng hoạt động tài khoản thành công!", ConstantAlertType.SUCCESS);
      }, err => {
        this.myAlert.showErrorHandled(err);
      })
    })
  }

  /**
   * Kích hoạt tài khoản
   * @param partyId 
   */
  enableAccount(partyId) {
    this.myAlert.showAlert("Bạn chắc chắn muốn kích hoạt tài khoản này?", "", true, "", "btn_confirm", () => {
      this.accountManagerService.enableAccount(partyId).subscribe((res: any) => {
        this.loadPage(this.page);
        this.myAlert.showAlertOnlyNoti("Kích hoạt tài khoản thành công!", ConstantAlertType.SUCCESS);
      }, err => {
        this.myAlert.showErrorHandled(err);
      })
    })
  }
}
