export const RoleCode =  {
    rolesQuanlyBoPhan : ['ESO4002', 'ESO4003'],
    rolesQuanLyNhanSu : ['ESO4004', 'ESO4004'],
    rolesQuanLyNhomNguoidung : ['ESO4001'],
    rolesQuanlyLuong : ['ESO2005'],
    rolesQuanlyHopDong : ['CTLIST', 'CTLSO', 'CTLSA', 'CTVDE', 'CTDOW', 'CTSIGN', 'ESO2001', 'ESO2002', 'CMT0001', 'ESO2003', 'ESO2004'],
    rolesQuanLyChuKy : ['ECT1049', 'ECT1050', 'ECT1053', 'DSIGN', 'ESIGN'],
    rolesQuanLyDoiTac : ['CMS2001', 'ESO3002', 'ESO3003', 'ESO3004', 'ESO3005'],
    rolesTrustPartner : ['CMS2002', 'ESO3001'],
    rolesDichVuEnduser : ['ESO1002'],
    rolesContractTemplate : ['ECT2300', 'ECT2301'],

    createContract: ['ESO2002'],
    rolesSignManyContracts: [],
    rolesApproveWithoutSign: [],


    rolesELYCConfig: ['ESO0003'],
    rolesContractNumberConfig: ['ESO2010'],
    rolesDanhSachTaiXuong: ['ESO2011']
}