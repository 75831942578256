<nav class="mt-2">
  <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
      <li *ngIf="!controlAccount.userInfo.isViewer" class="nav-item">
          <a [routerLink]="[constantUrl.DASHBOARD_ENDUSER]" routerLinkActive="active"
              class="nav-link nav-material">
              <em class="material-icons">dashboard</em>
              <p>
                  {{ 'AasMenuSidebar_dashboard' | translate }}
              </p>
          </a>
      </li>

      <li *showWithRoles="rolesContractTemplate" class="nav-item">
          <a [routerLink]="[constantUrl.contract_template_list]" routerLinkActive="active"
              class="nav-link nav-material">
              <span class="material-icons nav-icon">
                  description
              </span>
              <p>{{ 'AasMenuSidebar_contractSampleManager' | translate }}</p>
          </a>
      </li>

      <li *showWithRoles="rolesQuanlyChungHopDong" class="nav-item has-treeview">
          <a href="#" class="nav-link nav-material">
              <em class="material-icons">folder_shared</em>
              <p>
                  {{ 'AasMenuSidebar_contractManager' | translate }}
                  <i class="right fas fa-angle-left"></i>
              </p>
          </a>
          <ul *showWithRoles="rolesQuanlyChungHopDong" class="nav nav-treeview">
              <!-- <li *ngIf="isAccLevel2 && !controlAccount.userInfo.isViewer" class="nav-item">
                  <a [routerLink]="[constantUrl.contract_type_management]" routerLinkActive="active"
                      class="nav-link nav-material">
                      <em class="material-icons nav-icon">fiber_manual_record</em>
                      <p>
                          {{ 'ServiceContract_typeManagement' | translate }}
                      </p>
                  </a>
              </li> -->
              <li *showWithRoles="rolesContractNumberConfig" class="nav-item">
                  <a [routerLink]="[constantUrl.contract_number_config]" routerLinkActive="active"
                      class="nav-link nav-material">
                      <em class="material-icons nav-icon">fiber_manual_record</em>
                      <p>
                          {{ 'ServiceContract_contractNumberConfig' | translate }}
                      </p>
                  </a>
              </li>
              <li *showWithRoles="rolesQuanlyLuong" class="nav-item">
                  <a [routerLink]="[constantUrl.contract_lifecycle_list]" routerLinkActive="active"
                      class="nav-link nav-material">
                      <em class="material-icons">folder_shared</em>
                      <p>
                          {{ 'AasMenuSidebar_contractFlow' | translate }}
                      </p>
                  </a>
              </li>
              <li *showWithRoles="rolesQuanlyHopDong">
                  <a [routerLink]="[constantUrl.CONTRACT_MANAGER]" routerLinkActive="active"
                      class="nav-link nav-material">
                      <em class="material-icons">folder_shared</em>
                      <p>
                          {{ 'AasMenuSidebar_contract' | translate }}
                      </p>
                  </a>
              </li>
              <!-- <li *showWithRoles="rolesDanhSachTaiXuong">
                  <a [routerLink]="[constantUrl.DOWNLOAD_CONTRACT]" routerLinkActive="active"
                      class="nav-link nav-material">
                      <em class="material-icons">folder_shared</em>
                      <p>
                          Danh sách tải xuống 
                      </p>
                  </a>
              </li> -->
          </ul>
      </li>
      
      <li *showWithRoles="rolesQuanLyChuKy" class="nav-item">
          <a [routerLink]="[constantUrl.CA_MANAGER]" routerLinkActive="active" class="nav-link nav-material">
              <em class="material-icons">gesture</em>
              <p>
                  {{ 'AasMenuSidebar_signatureManager' | translate }}
              </p>
          </a>
      </li>

      <li *showWithRoles="rolesQuanLyToChuc" class="nav-item has-treeview">
          <a *ngIf="controlAccount.isAccLevel2 && !controlAccount.isConsumer" href="#"
              class="nav-link nav-material">
              <span class="material-icons nav-icon">
                  store
              </span>
              <p>
                  {{ 'AasMenuSidebar_organizationManager' | translate }}
                  <i class="right fas fa-angle-left"></i>
              </p>
          </a>
          <ul *showWithRoles="rolesQuanLyToChuc" class="nav nav-treeview">
              <li *showWithRoles="rolesQuanLyNhomNguoidung" class="nav-item">
                  <a [routerLink]="[constantUrl.group_permission_manager]" routerLinkActive="active"
                      class="nav-link nav-material">
                      <span class="material-icons nav-icon">
                          group
                      </span>
                      <p>{{ 'AasMenuSidebar_userGroupManager' | translate }}</p>
                  </a>
              </li>
              <li *showWithRoles="rolesQuanlyBoPhan" class="nav-item">
                  <a [routerLink]="[constantUrl.PART_LIST]" routerLinkActive="active"
                      class="nav-link nav-material">
                      <span class="material-icons nav-icon">
                          fiber_manual_record
                      </span>
                      <p>{{ 'AasMenuSidebar_partManager' | translate }}</p>
                  </a>
              </li>
              <li *showWithRoles="rolesQuanLyNhanSu" class="nav-item">
                  <a [routerLink]="[constantUrl.EMPLOYEE_LIST]" routerLinkActive="active"
                      class="nav-link nav-material">
                      <span class="material-icons nav-icon">
                          fiber_manual_record
                      </span>
                      <p>{{ 'AasMenuSidebar_personelManager' | translate }}</p>
                  </a>
              </li>
          </ul>
      </li>

      <li *showWithRoles="rolesQuanLyDoiTac" class="nav-item has-treeview">
          <a *ngIf="controlAccount.isAccLevel2" href="#" class="nav-link nav-material">
              <span class="material-icons nav-icon">
                  recent_actors
              </span>
              <p>
                  {{ 'AasMenuSidebar_verificationManager' | translate }}
                  <i class="right fas fa-angle-left"></i>
              </p>
          </a>
          <ul *showWithRoles="rolesQuanLyDoiTac" class="nav nav-treeview">
              <li class="nav-item">
                  <a [routerLink]="[constantUrl.PARTNER_CONSUMER_VERIFY]" routerLinkActive="active"
                      class="nav-link nav-material">
                      <span class="material-icons nav-icon">
                          person
                      </span>
                      <p>{{ 'AasMenuSidebar_consumerParty' | translate }}</p>
                  </a>
              </li>
              <li class="nav-item">
                  <a [routerLink]="[constantUrl.PARTNER_PARTY_VERIFY]" routerLinkActive="active"
                      class="nav-link nav-material">
                      <span class="material-icons nav-icon">
                          business
                      </span>
                      <p>{{ 'AasMenuSidebar_businessParty' | translate }}</p>
                  </a>
              </li>
          </ul>
      </li>

      <li *showWithRoles="rolesTrustPartner" class="nav-item">
          <a *ngIf="controlAccount.isAccLevel2" [routerLink]="[constantUrl.PARTNER_TRUSTED]"
              routerLinkActive="active" class="nav-link nav-material">
              <span class="material-icons">recent_actors</span>
              <p>
                  {{ 'AasMenuSidebar_trustParty' | translate }}
              </p>
          </a>
      </li>

      <li *showWithRoles="[]" class="nav-item">
          <a *ngIf="!controlAccount.userInfo.isViewer" [routerLink]="[constantUrl.verify_signature]" routerLinkActive="active"
              class="nav-link nav-material">
              <span class="ct-icon-font icon-doc-accept nav-icon mr-2"></span>
              <p>{{ 'AasMenuSidebar_validateSignature' | translate }}</p>
          </a>
      </li>

      <li *showWithRoles="rolesDichVuEnduser" class="nav-item">
          <a *ngIf="controlAccount.isAccLevel2" [routerLink]="[constantUrl.ENDUSER_PACKAGE_DETAIL]"
              routerLinkActive="active" class="nav-link nav-material">
              <span class="material-icons">dns</span>
              <p>{{ 'AasMenuSidebar_packageManager' | translate }}</p>
          </a>
      </li>
      <li *showWithRoles="rolesELYCConfig" class="nav-item">
          <a *ngIf="controlAccount.isAccLevel2" [routerLink]="[constantUrl.enduser_ekyc_config]"
              routerLinkActive="active" class="nav-link nav-material">
              <span class="material-icons">settings</span>
              <p>{{ 'AasMenuSidebar_ekycConfig' | translate }}</p>
          </a>
      </li>
      <li *ngIf="!controlAccount.userInfo.isViewer" class="nav-item">
          <a [routerLink]="[constantUrl.user_manual]" routerLinkActive="active"
              class="nav-link nav-material">
              <span class="material-icons-outlined">
                  help
              </span>
              <p>Tài liệu hướng dẫn sử dụng</p>
          </a>
      </li>
  </ul>
</nav>