import {ResquestConvertItem} from './request-convert-item.model';

export class ListRequestConvertResponse {
    page: number;
    maxSize: number;
    sort: string;
    propertiesSort: string;
    totalElement: number;
    data: ResquestConvertItem[] = [];

    constructor(obj = null) {
        obj = obj || {};
        this.page = obj.page || 1;
        this.maxSize = obj.maxSize || 10;
        this.sort = obj.sort || '';
        this.propertiesSort = obj.propertiesSort || '';
        this.totalElement = obj.totalElement || 0;

        this.data = [];
        if (obj.data && obj.data instanceof Array) {
            obj.data.forEach((item) => {
                this.data.push(new ResquestConvertItem(item));
            })
        }
    }
}
