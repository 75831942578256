import { MainResponse } from "../../../main-response.model"
import { ListPackageObject } from './list-package-object.model'

export class ListPackageResponse extends MainResponse {
    object: ListPackageObject;

    constructor(res = null) {
        super();
        res = res || {};
        this.init(res);
        this.object = new ListPackageObject(res.object);
    }
}