<div class="card shadow-none">
    <div class="card-body p-3">
        <div class="row">
            <div class="col-md-auto">
                <label class="grey-label">Thông tin số lượng đối tác</label><br>
            </div>
            <!-- <div class="col-md-auto ml-auto">
                <ng-select [formControl]="yearForm" [items]="years" bindLabel="label" bindValue="value"
                    [clearable]="false" [searchable]="false" class="ng-select-year">
                    <ng-template ng-label-tmp let-item="item">
                        <span class="year-label"> {{ item.label }}</span>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item">
                        <span class="year-label"> {{ item.label }}</span>
                    </ng-template>
                </ng-select>
            </div> -->
        </div>
        <div>
            <google-chart #chart [type]="type" [data]="data" [options]="options" style="width: 100%; height: 100%;"
            [dynamicResize]="dynamicResize">
            </google-chart>
        </div>
        <div class="chart-legend">
            <div class="row">
                <div class="col-md-4 legend">
                    <span class="dot"></span>
                    <label class="mb-0">Doanh nghiệp</label>
                    <br>
                    <span>{{totalOfEnterprise}}</span>
                </div>
                <!-- <div class="col-md-4 legend boder-left">
                    <span class="square"></span>
                    <label class="mb-0">Tổ chức</label>
                    <br>
                    <span>{{totalOfBussiness}}</span>
                </div> -->
                <!-- <div class="col-md-4 legend boder-left">
                    <span class="triangle"></span>
                    <label class="mb-0">Tích hợp</label>
                    <br>
                    <span>1,000</span>
                </div> -->
            </div>
        </div>
    </div>
</div>
