<app-spinner></app-spinner>
<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1 class="m-0 text-dark">Thêm mới danh mục dịch vụ</h1>
      </div>
      
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>

<section class="content">
  <div class="container-fluid">
    <div class="card shadow-none mb-4">
        <div class="card-header">
          <div class="row py-1">
            <label>Thông tin chi tiết</label>
            <div class="col-md-auto ml-auto">
              <button class="btn btn-outline-primary d-inline-flex mr-4" (click)="clearData()" >
                <em class="material-icons nav-icon mr-1">clear</em>
                Hủy
              </button>
              <button class="btn btn-primary d-inline-flex" (click)="actSaveInfo()" [disabled]="!form.valid">
                <em class="material-icons nav-icon mr-1">save</em>
                Lưu
              </button>
            </div>
          </div>
        </div>

        <div class="card-body">
            <form [formGroup]="form">
              <div class="row">
                <div class="col-md-6">
                  <label class="text-primary">Thông tin danh mục</label>
                  <hr>
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group">
                        <label>Tên danh mục <span class="text-danger">*</span>:</label>
                        <input appTrimValue type="text" formControlName="name"
                        class="form-control" placeholder="Nhập tên nhập tên danh mục" />
                        <app-show-validate-errors [useSubmit]="false" [isSubmit]="isSubmit" [errorMessages]="validateMsg.name"
                        [control]="form.controls.name" [detail]="{ name: 'name' }">
                        </app-show-validate-errors>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <label>Loại danh mục <span class="text-danger">*</span>:</label>
                        <ng-select2 formControlName="typeService" [data]="dropListCategoryPackage"
                          [options]="options" [placeholder]="'Chọn loại danh mục'"></ng-select2>
                        <app-show-validate-errors [useSubmit]="false" [isSubmit]="isSubmit" [errorMessages]="validateMsg.typeService"
                          [control]="form.controls.typeService" [detail]="{ name: 'typeService' }">
                        </app-show-validate-errors>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          <div class="row mt-3" *ngIf ="typeService.value === 'CHUCNANG'">
            <div class="col-md-12">
              <label class="text-primary">
              Thông tin phân quyền chức năng
              </label>
              <hr>
            </div>
            <div class="card-body pt-0">
              <div class="row">
                <div class="col-md-6 border px-3 py-2">
                    <div class="row pt-2 pb-2">
                        <div class="col-md-auto align-self-end">
                            <!-- <p class="mb-2"><label class="mb-0">Quyền chưa gán ({{countNotAssigned}})</label></p> -->
                            <p class="mb-2"><label class="mb-0">Danh sách chức năng</label></p>
                        </div>
                        <div class="col-md-auto ml-auto">
                            <button (click)="assignMoreSelected(0, 1)" class="btn btn-primary d-inline-flex"
                                [attr.disabled]="enableBtnAssign(0) ? '' : null">Gán<span
                                    class="material-icons ml-1">arrow_forward</span></button>
                        </div>
                    </div>

                    <div class="input-group mb-3">
                        <div class="input-group-prepend bg-white">
                            <span class="input-group-text bg-white border-right-0 pl-1 pr-1"><span
                                    class="material-icons">search</span></span>
                        </div>
                        <input #searchNotAssigned (input)="filterSearch($event, 0)" type="text" class="form-control border-left-0 pl-1"
                            placeholder="Nhập tên chức năng">

                    </div>

                    <div class="list-permission enable-scroll">
                        <ul class="content-list-permisson">
                            <li *ngIf="dualList[0].length > 0">
                                <div class="permission-group">
                                    <span class="material-icons text-white p-2">check</span>
                                    <div class="tcheck-primary w-100 d-inline-block">
                                        <input (input)="selectAllBtn(0)" type="checkbox" id="select-all-assign" [attr.checked]="selectedAll[0]">
                                        <label for="select-all-assign" class="w-100" [class.checked]="selectedAll[0]">
                                            Tất cả
                                        </label>
                                    </div>
                                    <span
                                        class="material-icons icon-set-permission text-white p-2">arrow_forward</span>
                                </div>
                            </li>
                            <li *ngFor="let li of dualList[0]; let i = index">
                              <div class="permission-group">
                                  <span  (click)="showHideTreeView($event, i, 0)" class="material-icons p-2 input-group-prepend" style="cursor: pointer;"
                                  [style.transform]="isExpandedFrom.checked || li.showChild  ? 'rotate(90deg)' : 'rotate(0deg)'">
                                  <input type="checkbox" #isExpandedFrom hidden>
                                  chevron_right</span>

                                  <div class="tcheck-primary w-100 d-inline-block">
                                    <input (input)="selectedParentItem(i, 0)" data-checkbox-permission-parent-checked-if="li.selected"
                                    data-checkbox-permission-parent-indeterminate-if="li.child.selected" 
                                    [attr.checked]="li.selected"
                                          type="checkbox" 
                                          id="parent-{{li.id}}">
                                      <label for="parent-{{li.id}}" class="w-100" style="cursor: auto;"
                                          [class.checked]="li.selected">
                                          {{li.name}}
                                      </label>
                                  </div>
                                  <span *ngIf="li.assigned == false" (click)="assignGroup(i, 0, 1)"
                                      class="material-icons icon-set-permission p-2 " style="cursor: pointer;">arrow_forward</span>
                                </div>

                                <ul *ngIf="li.child.length > 0 && li.showChild" class="permission-child " data-target="checkbox-permission">
                                    <li *ngFor="let subli of li.child; let j = index">
                                      <div class="tcheck-primary w-100 d-inline-block">
                                          <input (input)="selectedChildItem(i, j, 0)"
                                              [attr.checked]="subli.selected" type="checkbox"
                                              id="child-{{subli.id}}">
                                          <label for="child-{{subli.id}}" class="w-100" style="cursor: auto;"
                                              [class.checked]="subli.selected">
                                              {{subli.name}}
                                          </label>
                                      </div>
                                      <span *ngIf="subli.assigned == false" (click)="assign(i, j, 0, 1)"
                                          class="material-icons icon-set-permission p-2">arrow_forward</span>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-6 border px-3 py-2">
                  <div class="row pt-2 pb-2">
                    <div class="col-md-auto align-self-end">
                      <!-- <p class="mb-2"><label class="mb-0">Quyền đã gán ({{countAssigned}})</label></p> -->
                      <p class="mb-2"><label class="mb-0">Chức năng đã chọn</label></p>
                     </div>
                    <!-- <div class="col-md-auto ml-auto">
                      <button (click)="assignMoreSelected(1, 0)" class="btn btn-primary d-inline-flex"
                          [attr.disabled]="enableBtnAssign(1) ? '' : null">Hủy gán<span
                        class="material-icons ml-1">clear</span>
                      </button>
                    </div> -->
                  </div>

                    <div class="input-group mb-3">
                      <div class="input-group-prepend bg-white">
                        <span class="input-group-text bg-white border-right-0 pl-1 pr-1">
                          <span class="material-icons">search
                          </span>
                        </span>
                      </div>
                      <input #searchAssigned (input)="filterSearch($event, 1)" type="text" class="form-control border-left-0 pl-1"
                          placeholder="Nhập tên chức năng">
                    </div>

                    <div class="list-permission">
                      <ul class="content-list-permisson">
                        <!-- <li *ngIf="dualList[1].length > 0">
                            <div class="permission-group">
                                <span class="material-icons text-white p-2">check</span>
                                <div class="tcheck-primary w-100 d-inline-block">
                                    <input (input)="selectAllBtn(1)" type="checkbox" id="select-all-unassign" [attr.checked]="selectedAll[1] ? '' : null">
                                    <label for="select-all-unassign" class="w-100" [class.checked]="selectedAll[1]">
                                        Tất cả
                                    </label>
                                </div>
                                <span
                                    class="material-icons icon-set-permission text-white p-2">arrow_forward</span>
                            </div>
                        </li> -->
                        <li *ngFor="let li of dualList[1]; let i = index">
                          <div class="permission-group">
                            <span (click)="showHideTreeView($event, i, 1)" class="material-icons p-2 input-group-prepend" style="cursor: pointer;"
                            [style.transform]="isExpandedTo.checked || li.showChild ? 'rotate(90deg)' : 'rotate(0deg)'">
                            <input type="checkbox" #isExpandedTo hidden>
                            chevron_right</span>
                            <div class="tcheck-primary w-100 d-inline-block" >
                              <!-- <input (input)="selectedParentItem(i, 1)"
                                  [attr.checked]="li.selected ? '' : null" type="checkbox"
                                  id="assigned-parent-{{li.id}}"> -->
                              <label for="assigned-parent-{{li.id}}" class="w-100" style="cursor: auto;"
                                  [class.checked]="li.selected">
                                  {{li.name}}
                              </label>
                            </div>
                            <span (click)="assignGroup(i, 1, 0)" 
                                class="material-icons icon-set-permission p-2" style="cursor: pointer;" >clear</span>
                          </div>
                          <ul *ngIf="li.child.length > 0 && li.showChild" class="permission-child">
                            <li *ngFor="let subli of li.child; let j = index">
                              <div class="tcheck-primary w-100 d-inline-block">
                                <!-- <input (input)="selectedChildItem(i, j, 1)"
                                    [attr.checked]="subli.selected ? '' : null" type="checkbox"
                                    id="assigned-child-{{subli.id}}"> -->
                                <label for="assigned-child-{{subli.id}}" class="w-100" style="cursor: auto;"
                                    [class.checked]="subli.selected">
                                    {{subli.name}}
                                </label>
                              </div>
                              <span (click)="assign(i, j, 1, 0)"
                                  class="material-icons icon-set-permission p-2" style="cursor: pointer;">clear</span>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</section>