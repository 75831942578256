export const ContractStatus = {
  LC_DRAFT_CREATE: {
    label: 'LC_DRAFT_CREATE',
    style: 'badge-light-new',
    color: '#0062FF'
  },
  LC_DRAFT_UPDATE: {
    label: 'LC_DRAFT_UPDATE',
    style: 'badge-light-new',
    color: '#FF4C61'
  },
  LC_DRAFT_DENY: {
    label: 'LC_DRAFT_DENY',
    style: 'badge-danger-new',
    color: '#dc3545'
  },
  LC_DRAFT_CANCEL: {
    label: 'LC_DRAFT_CANCEL',
    style: 'badge-danger-new',
    color: '#dc3545'
  },
  LC_DRAFT_SUBMIT: {
    label: 'LC_DRAFT_SUBMIT',
    style: 'badge-warning-new',
    color: '#FFB800'
  },
  LC_DRAFT_SUBMIT_EXTERNAL: {
    label: 'LC_DRAFT_SUBMIT_EXTERNAL',
    style: 'badge-warning-new',
    color: '#FFB800'
  },
  LC_DRAFT_SUBMIT_INTERNAL: {
    label: 'LC_DRAFT_SUBMIT_INTERNAL',
    style: 'badge-warning-new',
    color: '#FF4C61'
  },
  LC_DRAFT_ONE_SIGN: {
    label: 'LC_DRAFT_ONE_SIGN',
    style: 'badge-warning-new',
    color: '#FF4C61'
  },
  LC_CONTRACT_VALID: {
    label: 'LC_CONTRACT_VALID',
    style: 'badge-success-new',
    color: '#29D395'
  },
  LC_DRAFT_SUBMIT_SIGNED: {
    label: 'LC_DRAFT_SUBMIT_SIGNED',
    style: 'badge-warning-new',
    color: '#FF4C61'
  },
  LC_DRAFT_VALID: {
    style: 'badge-success-new',
    label: 'LC_DRAFT_VALID',
    color: '#17a2b8'
  },
  LC_DRAFT_SIGNED: {
    label: 'LC_DRAFT_SIGNED',
    style: 'badge-warning-new',
    color: '#FF4C61'
  },
  LC_DRAFT_CHANGE_REQUEST: {
    label: 'LC_DRAFT_CHANGE_REQUEST',
    style: 'badge-warning-new',
    color: '#ff4700'
  },
  LC_DRAFT_SUBMIT_THIRD_PARTY: {
    label: 'LC_DRAFT_SUBMIT_THIRD_PARTY',
    style: 'badge-warning-new',
    color: '#ff4700'
  },
  LC_CONTRACT_EXPIRED_VALID: {
    label: 'LC_CONTRACT_EXPIRED_VALID',
    style: 'badge-danger-new',
    color: '#dc3545'
  },
  LC_CONTRACT_CANCEL: {
    label: 'LC_CONTRACT_CANCEL',
    style: 'badge-danger-new',
    color: '#ff4700'
  },
  LC_DRAFT_DISCUSS:{
    label: 'LC_DRAFT_DISCUSS',
    style: 'badge-warning-new',
    color: '#ff4700'
  },
  LC_DRAFT_DEAL:{
    label: 'LC_DRAFT_DEAL',
    style: 'badge-info-new',
    color: '#0062FF'
  },
  LC_DRAFT_EXPIRED:{
    lable: 'LC_DRAFT_EXPIRED',
    style: 'badge-danger-new',
    color: '#0062FF'
  },
  LC_DRAFT_WAIT_CONFIRM: {
    label: 'LC_DRAFT_WAIT_CONFIRM',
    style: 'badge-warning-new',
    color: '#ff4700'
  },
  LC_DRAFT_SIGN_FAIL: {
    label: 'LC_DRAFT_SIGN_FAIL',
    style: 'badge-danger-new',
    color: '#0062FF'
  },
  LC_DRAFT_WARNING: {
    label: 'LC_DRAFT_WARNING',
    style: 'badge-warning-new',
    color: '#ff4700'
  },
  '': {
    style: '',
    label: '',
    label_SIDE_A: '',
    label_SIDE_B: '',
    style_SIDE_A: '',
    style_SIDE_B: ''
  }
}
export const ContractDownloadStatus={
  PROCESS: {
    code:'PROCESS',
    label: 'Tiếp nhận yêu cầu',
    style: 'badge-info-new',
    color: '#0062FF'
  },
  DONE: {
    code:'DONE',
    label: 'Hoàn thành',
    style: 'badge-success-new',
    color: '#29D395'
  },
}
export enum ErrorCode {
  // SDK
  LIFECYCLE_NOT_FOUND = 'LIFECYCLE_NOT_FOUND',
  START_STAGE_NOT_FOUND = 'START_STAGE_NOT_FOUND',
  CONTRACT_SIGNED = 'CONTRACT_SIGNED',
  CONTRACT_FILE_NOT_FOUND = 'CONTRACT_FILE_NOT_FOUND',
  ORDER_EXISTS = 'ORDER_EXISTS',
  LIFECYCLE_NOT_ALLOW = 'LIFECYCLE_NOT_ALLOW',
  PROVINCE_NOT_FOUND = 'PROVINCE_NOT_FOUND',
  TEMPLATE_GROUP_NOT_FOUND = 'TEMPLATE_GROUP_NOT_FOUND',
  TEMPLATE_GROUP_DEACTIVED = 'TEMPLATE_GROUP_DEACTIVED',
  // User/Party
  DUPLICATE_USER_EMAIL = 'DUPLICATE_USER_EMAIL',
  DUPLICATE_USER_PHONE_NUMBER = 'DUPLICATE_USER_PHONE_NUMBER',
  DUPLICATE_USER_NAME = 'DUPLICATE_USER_NAME',
  DUPLICATE_USER_CMND = 'DUPLICATE_USER_CMND',
  DUPLICATE_USER_MANV = 'DUPLICATE_USER_MANV',
  OTP_INVALID = 'OTP_INVALID',
  OTP_CONFIRMED = 'OTP_CONFIRMED',
  OTP_TIMEOUT = 'OTP_TIMEOUT',
  ASSET_NOT_ALLOW = 'ASSET_NOT_ALLOW',
  VERIFICATION_STATUS_NOT_ALLOW = 'VERIFICATION_STATUS_NOT_ALLOW',
  DUPLICATE_TAX_NUMBER = 'DUPLICATE_TAX_NUMBER',
  DUPLICATE_USER_EMAIL_ORG = 'DUPLICATE_USER_EMAIL_ORG',
  DUPLICATE_SUBMAIL_ORG = 'DUPLICATE_SUBMAIL_ORG',
  DUPLICATE_PARTY_SDT = 'DUPLICATE_PARTY_SDT',
  USER_CONFRIMED = 'USER_CONFRIMED',
  // Contract
  LIFECYCLE_NOT_ALLOW_V2 = 'LIFECYCLE_NOT_ALLOW_V2',
  // General
  INFO_NOT_FOUND = 'INFO_NOT_FOUND',
  FILE_NOT_FOUND = 'FILE_NOT_FOUND',
  FILE_NOT_ALLOW_TYPE = 'FILE_NOT_ALLOW_TYPE',
  FILE_NOT_ALLOW_SIZE = 'FILE_NOT_ALLOW_SIZE',
  EKYC_NOT_ALLOW = 'EKYC_NOT_ALLOW'
}

export const UserTypeConstant = {
  CONSUMER: 'CONSUMER',
  BUSINESS: 'BUSINESS',
  ENTERPRISE: 'ENTERPRISE',
  INTERNAL: 'INTERNAL',
  SYSADM:'SYSADM'
}

export const ConstLocalStorage = {
  USERID: 'userId',
  USERNAME: 'username',
  PARTYID: 'partyId',
  ACCESS_TOKEN: 'token',
  USERTYPE: 'userType',
  KEY: 'key',
  ENCODE: '_eclist',
  ROOT_PARTY: '_root_parytId',
  INTERNAL_PARTY: '_internal_partyId',
  REFRESH_TOKEN: '_refresh_token',
  SESSION_SIGN_JOB: '_x001',
  SESSION_SIGN_JOB_SMARTCA: '_x002',
  USER_INFO: "userInfo",
  PARTY_INFO: "partyInfo",
}

export enum NotificationType {
  VERIFIED_EKYC = 'VERIFIED_EKYC',// đã xác thực ekyc
  REGISTERED_SMART_OTP = 'REGISTERED_SMART_OTP', //đã đk smart otp
  REGISTERED_ONETIME_CA = 'REGISTERED_ONETIME_CA', //đã đk tk onetime ca
  NEW_CONTRACT = 'NEW_CONTRACT', // có hđ mới
  SIGNED_CONTRACT = 'SIGNED_CONTRACT', // hđ đã ký thành công
  CANCEL_CONTRACT = 'CANCEL_CONTRACT',// đối tác đã hủy hđ
  ABOUT_TO_EXPIRE_CONTRACT = 'ABOUT_TO_EXPIRE_CONTRACT',// hđ gần hết hạn ký kết
  APPROVED_SECOND_LEVEL = 'APPROVED_SECOND_LEVEL',//tk đã được phê duyệt cấp 2
  TIME_EXPIRE_PACKAGE = "TIME_EXPIRE_PACKAGE",
  DOWNLOAD_MULTI_CONTRACT_SUCESSS='DOWNLOAD_MULTI_CONTRACT_SUCESSS'
}

export enum VerifyStatusConst {
  WAIT = 'WAIT',
  APPROVE = 'APPROVE',
  REJECT = 'REJECT',
  APPROVE_CONTRACT = 'APPROVE_CONTRACT',
  APPROVE_NON_CONTRACT = 'APPROVE_NON_CONTRACT',
  REJECT_VIP = 'REJECT_VIP',
  WAIT_APPROVE = 'WAIT_APPROVE'
}

export enum VerificationType {
  VIDEO_CALL = 'VIDEO_CALL',
  NONE = 'NONE',
}

export enum API_KEY_STATUS {
  ENABLED = 'ENABLED',
  DISABLED = 'DISABLED'
}

export const Package = {
  WAIT: {
    label: 'Chờ xử lý',
    style: 'badge-warning-new',
    color: '#FFB800'
  },
  WAIT_VIP: {
    label: 'Chờ xử lý',
    style: 'badge-warning-new',
    color: '#FFB800'
  },
  APPROVE: {
    label: 'Đã xử lý',
    style: 'badge-success-new',
    color: '#17a2b8'
  },
  APPROVE_CONTRACT: {
    label: 'Đã xử lý',
    style: 'badge-success-new',
    color: '#17a2b8'
  },
  APPROVE_NON_CONTRACT: {
    label: 'Đã xử lý',
    style: 'badge-success-new',
    color: '#17a2b8'
  },
  REJECT: {
    label: 'Đã từ chối',
    style: 'badge-danger-new',
    color: '#0062FF'
  },
  REJECT_VIP: {
    label: 'Đã từ chối',
    style: 'badge-danger-new',
    color: '#0062FF'
  },
  '':{
    label: 'Chờ xử lý',
    style: 'badge-warning-new',
    color: '#FFB800'
  }
}

export enum CensorshipStatus {
  ACT_DRAFT_DSIGN_INTERNAL ='ACT_DRAFT_DSIGN_INTERNAL',  // Ký số nội bộ
  ACT_DRAFT_ESIGN_INTERNAL = 'ACT_DRAFT_ESIGN_INTERNAL', // Ký điện tử nội bộ
  ACT_REJECT_INTERNAL = 'ACT_REJECT_INTERNAL', // Từ chối nội bộ
  ACT_APPROVE_NO_SIGN_INTERNAL = 'ACT_APPROVE_NO_SIGN_INTERNAL', // Duyệt không ký nội bộ,
  ACT_DRAFT_TSIGN_INTERNAL = 'ACT_DRAFT_TSIGN_INTERNAL'
}

export const UserTypeCompany = ['BUSINESS', 'ENTERPRISE', 'INTERNAL'];

export enum SmartCAJobStatus {
  INPROCESS = "INPROCESS",
  DONE = "DONE",
  ERROR = "ERROR"
}