<div class="wrapper" class="verifyv3">
    <div class="container">
        <div class="row rounded py-3" style="background-color: white;">
            <div class="col-md-4">
                <div class="bg-white rounded">
                    <div class="p-3 border-bottom">
                        <h5 class="font-weight-bold"><label>{{ localize.file_have_signature }}</label></h5>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="group-upload" (click)="fileInput.click()" appDragDrop (onFileDropped)="uploadFile($event)">
                                    <em class="fas fa-cloud-upload-alt text-grey-darkest" style="font-size: 50px;"></em>
                                    <p class="text-grey-darkest font-weight-bold mt-4 mb-0">Kéo thả file vào ô hoặc</p>
                                    <button class="btn mt-4 text-primary text-decoration-underline font-weight-bold">Chọn file</button>
                                    <input id="fileInput" hidden type="file" #fileInput (input)="uploadFile($event.target.files)">
                                </div>
                            </div>
                            <div class="col-md-12 mt-4">
                                <div id="filePreview" style="overflow: hidden;">
                                    <div class="blockUpload group-uploaded-file">
                                        <div *ngFor="let item of arrFile; let i = index" class="preview-file rounded border {{ item.process == 100 && item.error == ''? 'border-success' : item.error != '' ? 'border-danger' : item.process < 100 ? 'border-primary' : '' }} p-2 mb-2">
                                            <span class="name-file">{{item.name}}</span>
                                            <div class="d-flex align-items-center">
                                                <div class="progress flex-grow-1 mr-2" style="height: 4px; border-radius: 4px;">
                                                    <div class="progress-bar {{ item.process == 100 && item.error == ''? 'bg-success' : item.error != '' ? 'bg-danger' : item.process < 100 ? 'bg-primary' : '' }}" role="progressbar" [style.width.%]="item.process" attr.aria-valuenow="{{item.process}}" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                                <em class="fa fa-times-circle text-muted" (click)="removeFile(i)"></em>
                                            </div>
                                            <span *ngIf="item.error === ''">{{item.process}}%</span>
                                            <span *ngIf="item.error != ''" class="text-danger">{{item.error}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 p-4">
                            <p class="font-italic text-grey-darkest">{{ localize.verify_tutorials }}</p>
                            <p class="font-italic text-grey-darkest">{{ localize.verify_tutorials_line_1 }}</p>
                            <p class="font-italic text-grey-darkest">{{ localize.verify_tutorials_line_2 }}</p>
                            <p class="font-italic text-grey-darkest">{{ localize.verify_tutorials_line_3 }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-8 border-left">
                <div class="bg-white rounded" style="min-height: 100%;">
                    <div class="p-3">
                        <div class="d-flex align-items-center">
                            <h5 class="font-weight-bold"><label>{{ localize.info_verify }}</label></h5>
                            <div class="d-flex ml-auto">
                                <button *ngIf="isVerifyAction" class="btn btn-outline-primary btn-compare d-flex align-items-center mr-2" (click)="compare()">
                                    {{ localize.btn_commpare_cert }}
                                </button>
                                <button class="btn btn-outline-primary btn-verify d-flex align-items-center" (click)="verify()">
                                    {{ localize.btn_verify }}
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div *ngIf="isVerifyAction">
                                    <div class="row mb-3">
                                        <div class="col-md-3">
                                            <label class="font-weight-600">{{ localize.verify_name_file
                                                }}</label>
                                        </div>
                                        <div class="col-md-9">
                                            <a class="text-primary text-decoration-underline">{{
                                                fileName || "" }}</a>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-3">
                                            <label class="font-weight-600">{{ localize.verify_toan_ven_file
                                                }}</label>
                                        </div>
                                        <div class="col-md-9">
                                            <span *ngIf="fileInfo.hashok; else hashnotok" class="font-weight-600 text-success">{{ localize.verify_toan_ven_decrip
                                                }}</span>
                                            <ng-template #hashnotok>
                                                <span class="font-weight-600 text-danger">{{ localize.verify_ko_toan_ven_decrip
                                                    }}</span>
                                            </ng-template>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div *ngFor="let item of fileInfo.kySo; let i = index" class="col-12">
                                            <div class="signature-item mt-3">
                                                <div class="signature-item-header" [class.active]="item.expanded" (click)="expand('DSIGN', i)" data-toggle="collapse" [attr.data-target]="'#collapseExample_kyso_' + i" aria-expanded="false" [attr.aria-controls]="'collapseExample_kyso_' + i">
                                                    <div class="signature-item-info">
                                                        <label>{{ item.subject?.common_name }}</label>
                                                        <span>{{ localize.d_sign_type }}</span>
                                                    </div>
                                                    <div class="signature-action-expand">
                                                        <span *ngIf="item.expanded; else expandedno" class="material-icons">expand_more</span>
                                                        <ng-template #expandedno>
                                                            <span class="material-icons">expand_less</span>
                                                        </ng-template>
                                                    </div>
                                                </div>
                                                <div class="signature-item-body collapse" [attr.id]="'collapseExample_kyso_' + i">
                                                    <div class="row mb-3">
                                                        <div class="col-md-3">
                                                            <label class="font-weight-600">{{ localize.sign_type
                                                                }}</label>
                                                        </div>
                                                        <div class="col-md-9">
                                                            <span class="font-weight-600 text-grey-darkest">{{
                                                                localize.d_sign_type }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-3">
                                                        <div class="col-md-3">
                                                            <label class="font-weight-600">{{ localize.sign_by
                                                                }}</label>
                                                        </div>
                                                        <div class="col-md-9">
                                                            <span class="font-weight-600 text-grey-darkest">
                                                                {{ item.subject?.common_name }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-3">
                                                        <div class="col-md-3">
                                                            <label class="font-weight-600">{{ localize.signer_taxnumber
                                                                }}</label>
                                                        </div>
                                                        <div class="col-md-9">
                                                            <span class="font-weight-600 text-grey-darkest">
                                                                {{ item.subject?.taxnumber || item.subject?.user_id }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-3">
                                                        <div class="col-md-3">
                                                            <label class="font-weight-600">
                                                                {{ localize.sign_date }}
                                                            </label>
                                                        </div>
                                                        <div class="col-md-9">
                                                            <span class="font-weight-600 text-grey-darkest">{{ item.signDate
                                                                }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-3">
                                                        <div class="col-md-3">
                                                            <label class="font-weight-600">
                                                                {{ localize.verify_toan_ven }}
                                                            </label>
                                                        </div>
                                                        <div class="col-md-9">
                                                            <span *ngIf="item.signatureok; else signaturenotok" class="font-weight-600 text-success">{{
                                                                localize.verify_signature_ok }}</span>
                                                            <ng-template #signaturenotok>
                                                                <span class="font-weight-600 text-danger">{{
                                                                    localize.verify_signature_not_ok }}</span>
                                                            </ng-template>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-3">
                                                        <div class="col-md-3">
                                                            <label class="font-weight-600">
                                                                {{ localize.signature_trust }}
                                                            </label>
                                                        </div>
                                                        <div class="col-md-9">
                                                            <span *ngIf="item.certok; else certnotok" class="font-weight-600 text-success">{{
                                                                localize.verify_signature_trusted }}</span>
                                                            <ng-template #certnotok>
                                                                <span class="font-weight-600 text-danger">{{
                                                                    localize.verify_signature_untrust }}</span>
                                                            </ng-template>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="isCompared" class="row mb-3">
                                                        <div class="col-md-3">
                                                            <label class="font-weight-600">
                                                                {{ localize.result_compare_signature }}
                                                            </label>
                                                        </div>
                                                        <div class="col-md-9">
                                                            <span *ngIf="item.compareok; else comparenotok" class="font-weight-600 text-success">{{
                                                                localize.result_compare_ok }}</span>
                                                            <ng-template #comparenotok>
                                                                <span class="font-weight-600 text-danger">{{
                                                                    localize.result_compare_notok }}</span>
                                                            </ng-template>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div *ngFor="let item of fileInfo.kyDienTu; let i = index" class="col-12">
                                            <div class="signature-item mt-3">
                                                <div class="signature-item-header" [class.active]="item.expanded" (click)="expand('ESIGN', i)" data-toggle="collapse" [attr.data-target]="'#collapseExample_kydientu_' + i" aria-expanded="false" [attr.aria-controls]="'collapseExample_kydientu_' + i">
                                                    <div class="signature-item-info">
                                                        <label>{{ item.subject?.common_name }}</label>
                                                        <span>{{ item.type === "EKYC" ? localize.e_sign_ekyc_type :
                                                            localize.e_sign_otp_type }}</span>
                                                    </div>
                                                    <div class="signature-action-expand">
                                                        <span *ngIf="item.expanded; else expandedno" class="material-icons">expand_more</span>
                                                        <ng-template #expandedno>
                                                            <span class="material-icons">expand_less</span>
                                                        </ng-template>
                                                    </div>
                                                </div>
                                                <div class="signature-item-body collapse" [attr.id]="'collapseExample_kydientu_' + i">
                                                    <div class="row">
                                                        <div class="col-md-8">
                                                            <div class="row mb-3">
                                                                <div class="col-md-3">
                                                                    <label class="font-weight-600">{{ localize.sign_type
                                                                        }}</label>
                                                                </div>
                                                                <div class="col-md-9">
                                                                    <span class="font-weight-600 text-grey-darkest">
                                                                        {{ item.type === "EKYC" ? localize.e_sign_ekyc_type :
                                                                        localize.e_sign_otp_type }}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-3">
                                                                <div class="col-md-3">
                                                                    <label class="font-weight-600">{{ localize.signer_name
                                                                        }}</label>
                                                                </div>
                                                                <div class="col-md-9">
                                                                    <span class="font-weight-600 text-grey-darkest">
                                                                        {{ item.fullname }}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-3">
                                                                <div class="col-md-3">
                                                                    <label class="font-weight-600">{{
                                                                        localize.signer_phone_number
                                                                        }}</label>
                                                                </div>
                                                                <div class="col-md-9">
                                                                    <span class="font-weight-600 text-grey-darkest">
                                                                        {{ item.sdt }}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-3">
                                                                <div class="col-md-3">
                                                                    <label class="font-weight-600">{{
                                                                        localize.signer_personal_id
                                                                        }}</label>
                                                                </div>
                                                                <div class="col-md-9">
                                                                    <span class="font-weight-600 text-grey-darkest">
                                                                        {{ item.cmnd }}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-3">
                                                                <div class="col-md-3">
                                                                    <label class="font-weight-600">
                                                                        {{ localize.sign_date }}
                                                                    </label>
                                                                </div>
                                                                <div class="col-md-9">
                                                                    <span class="font-weight-600 text-grey-darkest">{{
                                                                        item.signDate }}</span>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-3">
                                                                <div class="col-md-3">
                                                                    <label class="font-weight-600">
                                                                        {{ localize.verify_toan_ven }}
                                                                    </label>
                                                                </div>
                                                                <div class="col-md-9">
                                                                    <span *ngIf="item.signatureok; else signaturenotok" class="font-weight-600 text-success">{{
                                                                        localize.verify_signature_ok }}</span>
                                                                    <ng-template #signaturenotok>
                                                                        <span class="font-weight-600 text-danger">{{
                                                                            localize.verify_signature_not_ok }}</span>
                                                                    </ng-template>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-3">
                                                                <div class="col-md-3">
                                                                    <label class="font-weight-600">
                                                                        {{ localize.signature_trust }}
                                                                    </label>
                                                                </div>
                                                                <div class="col-md-9">
                                                                    <span *ngIf="item.certok; else certnotok" class="font-weight-600 text-success">{{
                                                                        localize.verify_signature_trusted }}</span>
                                                                    <ng-template #certnotok>
                                                                        <span class="font-weight-600 text-danger">{{
                                                                            localize.verify_signature_untrust }}</span>
                                                                    </ng-template>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="isCompared" class="row mb-3">
                                                                <div class="col-md-3">
                                                                    <label class="font-weight-600">
                                                                        {{ localize.result_compare_signature }}
                                                                    </label>
                                                                </div>
                                                                <div class="col-md-9">
                                                                    <span *ngIf="item.compareok; else comparenotok" class="font-weight-600 text-success">{{
                                                                        localize.result_compare_ok }}</span>
                                                                    <ng-template #comparenotok>
                                                                        <span class="font-weight-600 text-danger">{{
                                                                            localize.result_compare_notok }}</span>
                                                                    </ng-template>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-4">
                                                            <div *ngIf="item.imageSign" class="row mb-3">
                                                                <div class="col-md-12">
                                                                    <label class="font-weight-600">{{ localize.image_signature
                                                                        }}</label>
                                                                </div>
                                                                <div class="col-md-12 mt-2">
                                                                    <img class="border-primary" [src]="item.imageSign?.link" style="width: 200px; max-width: 100%; height: auto;" />
                                                                </div>
                                                            </div>
                                                            <div *ngIf="item.imageSigners" class="row mb-3">
                                                                <div class="col-md-12">
                                                                    <label class="font-weight-600">{{ localize.image_signer
                                                                        }}</label>
                                                                </div>
                                                                <div class="col-md-12 mt-2">
                                                                    <img *ngFor="let item of item.imageSigners" class="rounded mr-2 mb-2" [src]="item.link" style="width: 200px; max-width: 100%; height: auto;" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>