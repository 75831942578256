import { MainResponse } from './../../../main-response.model';
import { ListSignatureObject } from './list-signature-object.model';
export class ListSignatureResponse extends MainResponse {
    object: ListSignatureObject;

    constructor(data = null) {
        super();
        data = data || {};
        this.init(data);
        this.object = new ListSignatureObject(data.object);
    }
}