import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-sort-header',
  templateUrl: './table-sort-header.component.html',
  styleUrls: ['./table-sort-header.component.scss']
})
export class TableSortHeaderComponent implements OnInit {
  @Input('sortColConfig')
  set sortColConfig(value) { this._sortColConfig = value; }
  get sortColConfig() { return this._sortColConfig; };
  _sortColConfig = null;

  @Input('title')
  set title(value) { this._title = value; }
  get title() { return this._title; };
  _title = "";

  constructor() { }

  ngOnInit(): void {
  }

}
