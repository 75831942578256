<app-spinner></app-spinner>
<form [formGroup]="form" (ngSubmit)="onSubmit()">
  <section class="content">
    <div class="container-fluid">
      <div class="card card ec-card shadow-none">
        <div class="card-header">
          <div class="d-flex">
            <div class="card-title-path flex-grow-1">
              <h1 class="m-0 text-dark card-title"><label>Thêm mới tin bài</label></h1>
            </div>
            <div class="card-btn-path ml-auto text-right">
              <a [routerLink]="['/console/landing-page/blog']" routerLinkActive="router-link-active"
                class="btn btn-outline-primary d-inline-flex mr-3">
                <span class="material-icons mr-1">close</span>
                Hủy
              </a>
              <button class="btn btn-primary d-inline-flex" type="submit" [disabled]="isSubmit || !form.valid"><span
                  class="material-icons mr-1">save</span>Lưu</button>
            </div>
          </div>
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="">Tiêu đề chính <span class="text-danger">*</span>:</label>
                <input type="text" class="form-control" formControlName="title"
                  placeholder="Nhập nội dung tiêu đề chính">
                <app-show-validate-errors [useSubmit]="false" [isSubmit]="isSubmit" [errorMessages]="validMsg.title"
                  [control]="form.controls.title" [detail]="{ name: 'title' }">
                </app-show-validate-errors>
              </div>
              <div class="form-row">
                <div class="form-group col-12 col-md-6">
                  <label for="">Tiêu đề phụ <span class="text-danger">*</span>:</label>
                  <input type="text" class="form-control" formControlName="subTitle"
                    placeholder="Nhập nội dung tiêu đề phụ">
                  <app-show-validate-errors [useSubmit]="false" [isSubmit]="isSubmit"
                    [errorMessages]="validMsg.subTitle" [control]="form.controls.subTitle"
                    [detail]="{ name: 'subTitle' }">
                  </app-show-validate-errors>
                </div>
                <div class="form-group col-12 col-md-6">
                  <label for="">Ngôn ngữ<span class="text-danger">*</span>:</label>
                  <select class="form-control" formControlName="language">
                    <option [ngValue]="''" disabled>Chọn ngôn ngữ</option>
                    <option *ngFor="let item of languages" [value]="item.key">{{item.value}}</option>
                  </select>
                  <app-show-validate-errors [useSubmit]="false" [isSubmit]="isSubmit"
                    [errorMessages]="validMsg.language" [control]="form.controls.language"
                    [detail]="{ name: 'language' }">
                  </app-show-validate-errors>
                </div>
              </div>
              <div class="form-group">
                <label for="">Danh mục tin <span class="text-danger">*</span>:</label>
                <select class="form-control" formControlName="categoryId">
                  <option [ngValue]="''" disabled>Chọn danh mục tin</option>
                  <option *ngFor="let item of categories" [value]="item.id">{{item.categoryName}}</option>
                </select>
                <app-show-validate-errors [useSubmit]="false" [isSubmit]="isSubmit"
                  [errorMessages]="validMsg.categoryId" [control]="form.controls.categoryId"
                  [detail]="{ name: 'categoryId' }">
                </app-show-validate-errors>
              </div>
              <div class="form-row">
                <div class="form-group col-12 col-md-6">
                  <label for="">Kiểu tin <span class="text-danger">*</span>:</label>
                  <select class="form-control" formControlName="blogTypeId">
                    <option [ngValue]="''" disabled>Chọn kiểu tin</option>
                    <option *ngFor="let item of blogTypes" [value]="item.id">{{item.name}}</option>
                  </select>
                  <app-show-validate-errors [useSubmit]="false" [isSubmit]="isSubmit"
                    [errorMessages]="validMsg.blogTypeId" [control]="form.controls.blogTypeId"
                    [detail]="{ name: 'blogTypeId' }">
                  </app-show-validate-errors>
                </div>
                <div class="form-group col-12 col-md-6">
                  <label for="">Thứ tự <span class="text-danger">*</span>:</label>
                  <input type="number" class="form-control" formControlName="sortOrder" min="1"
                    placeholder="Nhập thứ tự">
                  <app-show-validate-errors [useSubmit]="false" [isSubmit]="isSubmit"
                    [errorMessages]="validMsg.sortOrder" [control]="form.controls.sortOrder"
                    [detail]="{ name: 'sortOrder' }">
                  </app-show-validate-errors>
                </div>
              </div>
              <div class="form-group">
                <label for="">Nội dung <span class="text-danger">*</span>:</label>
                <textarea name="" id="" cols="30" rows="10" class="form-control" formControlName="description"
                  placeholder="Nhập nội dung"></textarea>
                <app-show-validate-errors [useSubmit]="false" [isSubmit]="isSubmit"
                  [errorMessages]="validMsg.description" [control]="form.controls.description"
                  [detail]="{ name: 'description' }">
                </app-show-validate-errors>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <div class="form-group">
                <label for="">Hình ảnh/Video <span class="text-danger">*</span>:</label>
                <div class="input-group mb-3 upload-input">
                  <input type="text" class="form-control" placeholder="Link hình hoặc video" aria-label=""
                    aria-describedby="button-addon2" formControlName="avatar">
                  <div class="input-group-append">
                    <button class="btn btn-sm btn-outline-secondary" type="button" id="button-addon2"
                      (click)="file.click()">
                      <span class="material-icons">attach_file</span>
                    </button>
                  </div>
                </div>
                <app-show-validate-errors [useSubmit]="false" [isSubmit]="isSubmit" [errorMessages]="validMsg.avatar"
                  [control]="form.controls.avatar" [detail]="{ name: 'avatar' }">
                </app-show-validate-errors>
                <input type="file"
                  accept="image/*,video/mp4,application/pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/msword,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation,.zip,.rar,.7zip"
                  #file class="d-none" (change)="handleFileInput($event.target.files)">
              </div>
              <div class="form-group">
                <div *ngIf="form.value.blogTypeId == 1">
                  <video [src]="form.value.avatar" controls></video>
                </div>
                <div *ngIf="form.value.blogTypeId == 2">
                  <img [src]="form.value.avatar" alt="" class="img-fluid" width="200px">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</form>