import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AasValidateMessage } from 'src/app/utils/common/validate-msg-aas';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, BehaviorSubject, forkJoin } from 'rxjs';
import { share, delay } from 'rxjs/operators';
import { UserInformationsItem } from 'src/app/utils/models/profile/user-info-item.model';
import { AuthService } from 'src/app/utils/services/auth.service';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { InfoPartyObject, UserInformations } from 'src/app/utils/models/aas-models/info-party/info-party-object';
import { Select2OptionData } from 'ng-select2';
import { Options } from 'select2';
import { ListTinhResponse } from 'src/app/utils/models/catalog/list-tinh/list-tinh-response.model';
import { ListHuyenResponse } from 'src/app/utils/models/catalog/list-huyen/list-huyen-response.model';
import { ListXaResponse } from 'src/app/utils/models/catalog/list-xa/list-xa-response.model';
import { CatalogService } from 'src/app/utils/services/aas-network/catalog.service';
import { DateUtils } from 'src/app/utils/common/DateUtils';
import { InfoPartyResponse } from 'src/app/utils/models/aas-models/info-party/info-party-response'
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { LoadingService } from 'src/app/utils/services/loading.service';
import { ValidatorsExtension } from 'src/app/utils/common/validators-extension';
import { ConstantAlertBtn, ConstantAlertMsg } from 'src/app/utils/common/constant-alert-msg';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { ControlAccount } from "src/app/utils/common/control-account";

declare var Cleave: any;
@Component({
  selector: 'app-profile-business-v2',
  templateUrl: './profile-business-v2.component.html',
  styleUrls: ['./profile-business-v2.component.scss']
})
export class ProfileBusinessV2Component implements OnInit {
  @Input('data') _data: BehaviorSubject<UserInformationsItem> = new BehaviorSubject<UserInformationsItem>(new UserInformationsItem());
  @Input('userInfo') userInfo: UserInformationsItem = new UserInformationsItem();
  @Input('partyInfo') partyInfo: InfoPartyObject = new InfoPartyObject();
  @Output() public onActionSave: EventEmitter<any> = new EventEmitter<any>();

  // mode chỉnh sửa
  isEdit: boolean = false
  // mode cập nhật email sdt
  isUpdateEmailSDT: boolean = false
  updateForm: string = ''
  oldValue: string = ''

  public tinhData: Array<Select2OptionData> = [];
  public huyenData: Array<Select2OptionData> = [];
  public xaData: Array<Select2OptionData> = [];
  public options: Options = {
    dropdownAutoWidth: true,
    width: '100%',
    closeOnSelect: true,
    language: "vi"
  };
  public optionsHuyen: Options = {
    dropdownAutoWidth: true,
    width: '100%',
    closeOnSelect: true,
    language: "vi"
  };
  public optionsXa: Options = {
    dropdownAutoWidth: true,
    width: '100%',
    closeOnSelect: true,
    language: "vi"
  };

  private requests: Observable<Object>[] = [];

  fileGPKD: Blob = null;
  showPreviewGPKD: boolean = false;

  dmTinh = new ListTinhResponse();
  dmHuyen = new ListHuyenResponse();
  dmXa = new ListXaResponse();

  id: string = this.auth.userId;
  partyId: string = this.auth.partyId;

  loadingForm = new BehaviorSubject<boolean>(false);
  // userInfo: UserInformationsItem = new UserInformationsItem();
  // partyInfo: InfoPartyObject = new InfoPartyObject();

  formSubmit: FormGroup;
  isSubmit: boolean = false;
  ServiceMsg = AasValidateMessage.PROFILE_MANAGER_BUSINESS;
  loaded: Array<boolean> = [false, false, false];

  genderBindDataList = [
    { value: 1, label: 'female' },
    { value: 2, label: 'male' }
  ];

  tinhBindData = [];
  huyenBindData = [];
  xaBindData = [];

  optionsDateFormat = {
    date: true,
    delimiter: '/',
    datePattern: ['d', 'm', 'Y']
  };

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private auth: AuthService,
    private aasAuth: AasAuthService,
    private catalog: CatalogService,
    public loadingService: LoadingService,
    private alertControl: AlertControl,
    private translate: TranslateService,
    private controlAccount: ControlAccount,
    private router: Router,
  ) {
  }

  @Input()
  set data(value) {
    this._data.next(value);
  };

  get data() {
    return this._data.getValue();
  }

  ngOnInit(): void {
    this.makeFormGroup();
    this.makeAutoFormatDate();
    // this._data
    //   .subscribe(x => {
    //     this.userInfo = this.data;
    //     this.getOldData();
    //   });
  }

  //MARK: Loading
  loadingFormIsVisiable(): Observable<boolean> {
    return this.loadingForm.asObservable().pipe(share());
  }

  //MARK: Khởi tạo form
  makeFormGroup() {
    this.formSubmit = this.fb.group({
      tenDoanhNghiep: new FormControl('', [Validators.required, ValidatorsExtension.validateCompanyName]),
      tenVietTat: new FormControl('', [Validators.required, ValidatorsExtension.validateShortCompanyName]),
      maSoThue: new FormControl('', [Validators.required, Validators.pattern(/^\d{10}-\d{3}$|^\d{10}$/i)]),
      hoVaTen: new FormControl('', [Validators.required, ValidatorsExtension.validateFullName]),
      email: new FormControl('', [Validators.required, Validators.email]),
      sdt: new FormControl('', [ValidatorsExtension.phoneNumber]),
      ngaySinh: new FormControl('', [Validators.pattern(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i)]),
      gender: [null],

      tinhId: [],
      huyenId: [],
      xaId: [],
      soNha: new FormControl(''),
      duong: new FormControl(''),

      username: new FormControl('', [Validators.required])
    });
    this.getOldData();
  }

  setValue(data: InfoPartyObject) {
    this.loaded = [false, false, false];

    console.log(this.loaded);
    this.tenDoanhNghiep.setValue(data.tenToChuc);
    this.tenVietTat.setValue(data.tenRutGon);
    this.maSoThue.setValue(data.maSoThue);
    this.hoVaTen.setValue(data.userInformations.ten);
    this.email.setValue(data.userInformations.email);
    this.sdt.setValue(data.userInformations.sdt);
    this.ngaySinh.setValue(DateUtils.convert(data.userInformations.ngaySinh, DateUtils.format.INPUT));
    data.userInformations.gioiTinhId && this.gender.setValue(data.userInformations.gioiTinhId);
    data.userInformations.tinhId && this.tinhId.setValue(data.userInformations.tinhId.toString());
    data.userInformations.huyenId && this.huyenId.setValue(data.userInformations.huyenId.toString());
    data.userInformations.xaId && this.xaId.setValue(data.userInformations.xaId.toString());
    this.soNha.setValue(data.userInformations.soNha);
    this.duong.setValue(data.userInformations.duong);
    this.username.setValue(data.userInformations.username);

    console.log("setValue => ", this.formSubmit);
  }

  get tenDoanhNghiep() { return this.formSubmit.get('tenDoanhNghiep'); }
  get tenVietTat() { return this.formSubmit.get('tenVietTat'); }
  get maSoThue() { return this.formSubmit.get('maSoThue'); }
  get hoVaTen() { return this.formSubmit.get('hoVaTen'); }
  get email() { return this.formSubmit.get('email'); }
  get sdt() { return this.formSubmit.get('sdt'); }
  get ngaySinh() { return this.formSubmit.get('ngaySinh'); }
  get gender() { return this.formSubmit.get('gender'); }
  get tinhId() { return this.formSubmit.get('tinhId'); }
  get huyenId() { return this.formSubmit.get('huyenId'); }
  get xaId() { return this.formSubmit.get('xaId'); }
  get soNha() { return this.formSubmit.get('soNha'); }
  get duong() { return this.formSubmit.get('duong'); }
  get username() { return this.formSubmit.get('username'); }

  //MARK: Chức năng input ngày sinh, ngày cấp giấy tờ
  makeAutoFormatDate() {
    // let ngaySinh = this.ngaySinh
    // var cleaveNgaySinh = new Cleave('#ngaySinh', {
    //   date: true,
    //   // delimiter: '/',
    //   datePattern: ['d', 'm', 'Y'],
    //   onValueChanged: function (e) {
    //     ngaySinh.setValue(e.target.value);
    //   }
    // });
  }

  /**
   * Bắt sự kiện nhập ngày
   * @param e 
   * @param fc 
   */
  dateInput(e, fc) {
    if (e.target.value.length > 10) {
      var str = e.target.value;
      e.target.value = str.substring(0, str.length - 1);
      fc.setValue(e.target.value);
      console.log(e.target.value);
    }
  }

  /**
   * Bắt sự kiện nhập Số điện thoại
   * @param event 
   */
  handleInputSdt(event) {
    const regex = new RegExp(/^\d+$/);
    if (regex.test(event.target.value) && event.target.value.length <= 10) {
      return;
    } else {
      this.sdt.setValue(event.target.value.slice(0, -1));
    }
  }

  actDownloadGPKD(id) {
    this.downloadGPKD(id);
  }

  handleClosePreview() {
    this.showPreviewGPKD = false;
  }

  //MARK: Chức năng cập nhật dữ liệu profile
  actBtnCancel(e) {
    e.stopPropagation();
    this.isSubmit = false;
    this.resetData();
    this.getOldData();
  }

  resetData() {
    this.formSubmit.reset();
    this.huyenData = [];
    this.xaData = [];

    console.log("resetData => ", this.formSubmit.value);
  }

  actBtnSave(e) {
  }

  /**
   * Action save btn
   * @param e 
   */
  submit(e) {
    // e.stopPropagation();
    this.isSubmit = true;
    console.log(this.formSubmit);

    if (this.formSubmit.valid) {
      this.alertControl.showAlert(this.translate.instant('ProfileConsumer_ts_1'), '', true, '', this.translate.instant('btn_save'), () => {
        this.postUpdateBusiness(this.partyId, this.makeParam());
      }, '', this.translate.instant('btn_close'), () => { });
    }
  }

  makeParam(): any {
    return {
      "daiDien": {
        "username": this.username.value,
        "ten": this.hoVaTen.value,
        "sdt": this.sdt.value || null,
        "ngaySinh": DateUtils.convertInputToFormat(this.ngaySinh.value, DateUtils.format.API) || null,
        "gioiTinhId": Number(this.gender.value) || null,
        "soNha": this.soNha.value,
        "duong": this.duong.value,
        "xaId": Number(this.xaId.value) || null,
        "huyenId": Number(this.huyenId.value) || null,
        "tinhId": Number(this.tinhId.value) || null
      },
      "toChuc": {
        "tenToChuc": this.tenDoanhNghiep.value,
        "tenRutGon": this.tenVietTat.value
      }
    }
  }

  //MARK: Action chon select2
  tinhChange(event) {
    console.log("tinh => ", event);
    if (event && event !== '') {
      this.getDmHuyen(event);
        this.huyenData = [];
        this.huyenId.setValue(null);
        this.xaData = [];
        this.xaId.setValue(null);
    }
  }

  huyenChange(event) {
    console.log("huyen => ", event);
    if (event && event !== '') {
      this.getDmXa(event);
      this.xaData = [];
      this.xaId.setValue(null);
    }
  }

  xaChange(event) {
    console.log("xa => ", event);
  }

  //MARK: NETWORKING
  /**
   * Tải dữ liệu cũ
   */
  getOldData() {
    this.loadingForm.next(true);

    this.getDmTinh();

    this.aasAuth.getPartyInfo(this.partyId).subscribe(res => {
      this.partyInfo = new InfoPartyResponse(res).object;

      if (this.partyInfo.userInformations.tinhId) this.getDmHuyen(this.partyInfo.userInformations.tinhId);
      if (this.partyInfo.userInformations.huyenId) this.getDmXa(this.partyInfo.userInformations.huyenId);

      this.setValue(this.partyInfo);
    })
  }

  getPartyInfo(partyId) {
    this.loadingForm.next(true);
    this.aasAuth.getPartyInfo(partyId).subscribe(res => {
      this.loadingForm.next(true);
      this.partyInfo = new InfoPartyResponse(res).object;
    }, err => {
      this.loadingForm.next(false);
      this.alertControl.showErrorHandled(err);
    }, () => {
      this.loadingForm.next(false);
    })
  }

  postUpdateBusiness(id, param) {
    this.loadingForm.next(true);
    this.aasAuth.postUpdateBusiness(id, param).subscribe(res => {
      this.loadingForm.next(false);
      this.getPartyInfo(this.partyId);
      this.reloadPage()
      this.actionSuccess()
      this.alertControl.showAlert(this.translate.instant('ProfileConsumer_ts_2'), ConstantAlertType.SUCCESS);
    }, err => {
      this.loadingForm.next(false);
      if (err.error && err.error.message) {
        switch (err.error.message) {
          case 'ECT-00001200':
            this.email.setErrors({ unique: true });
            break;
          case 'ECT-00001201':
            this.sdt.setErrors({ unique: true });
            break
          case 'ECT-00001202':
            this.username.setErrors({ unique: true });
            break;
          default:
            this.alertControl.showErrorHandled(err);
        }
      }
    }, () => {
      this.loadingForm.next(false);
    })
  }

  getDmTinh() {
    this.loadingForm.next(true);
    this.catalog.getListTinh().subscribe((res) => {
      this.loadingForm.next(false);

      this.dmTinh = new ListTinhResponse(res);
      this.tinhBindData = this.dmTinh.object.map(item => {
        return { id: item.tinhId.toString(), label: item.tenTinh };
      });
    }, err => {
      this.loadingForm.next(false);
      console.log(err);
      this.alertControl.showErrorHandled(err);
    }, () => {
    });
  }

  getDmHuyen(idTinh) {
    this.loadingForm.next(true);
    this.catalog.getListHuyen(idTinh).subscribe((res) => {
      this.loadingForm.next(false);

      this.dmHuyen = new ListHuyenResponse(res);
      this.huyenBindData = this.dmHuyen.object.map(item => {
        return { id: item.huyenId.toString(), label: item.tenHuyen };
      });
    }, err => {
      this.loadingForm.next(false);
      console.log(err);
      this.alertControl.showErrorHandled(err);
    }, () => {
    });
  }

  getDmXa(idHuyen) {
    this.loadingForm.next(true);
    this.catalog.getListXa(idHuyen).subscribe((res) => {
      this.loadingForm.next(false);

      this.dmXa = new ListXaResponse(res);
      this.xaBindData = this.dmXa.object.map(item => {
        return { id: item.xaId.toString(), label: item.tenXa };
      });
    }, err => {
      this.loadingForm.next(false);
      console.log(err);
      this.alertControl.showErrorHandled(err);
    }, () => {
    });
  }

  /**
 * Tai file GPKD
 * @param partyId 
 */
  downloadGPKD(partyId) {
    this.aasAuth.downloadGPKD(partyId).subscribe((res: any) => {
      this.fileGPKD = new Blob([res], { type: 'application/pdf' });
      this.showPreviewGPKD = true;
    }, err => {

    })
  }

  //MARK: Chức năng nâng cấp định danh
  updateVerifyAccount() {
    if (this.userInfo.userType === 'CONSUMER') {
      this.alertControl.showAlertOnlyNoti(ConstantAlertMsg.UP_IDENTITY, '');
    } else if (this.userInfo.userType === 'BUSINESS') {
      this.router.navigate([ConstantUrl.ACC_UPLOAD_GPKD]);
    } else if (this.userInfo.userType === 'ENTERPRISE') {
      this.router.navigate([ConstantUrl.ACC_UPLOAD_GPKD]);
    }
  }

  updateEmailSDT(value){
    this.updateForm = value
    if (value == 'email'){
      this.oldValue = this.email.value
    }
    else{
      this.oldValue = this.sdt.value
    }
    this.isUpdateEmailSDT = true
  }

  // emit trigger Hủy cập nhật
  actionCancel(){
    this.isUpdateEmailSDT = false
    this.isEdit = false
  }

  // emit trigger Cập nhật thành công  => load api thông tin 
  actionSuccess(){
    this.isUpdateEmailSDT = false
    this.isEdit = false    
    this.refreshControls()
  }

  reloadPage(){
    this.controlAccount.requestAPIGetAccountInfo(this.auth.userId, this.auth.partyId, () => {
      this.userInfo = this.controlAccount.userInfo
      this.partyInfo= this.controlAccount.partyInfo
    });
  }

  refreshControls(){
    this.controlAccount.requestAPIGetAccountInfo(this.auth.userId, this.auth.partyId, () => {
      this.userInfo = this.controlAccount.userInfo
      this.partyInfo.userInformations = new UserInformations(this.userInfo)
      this.setValue(this.partyInfo)
    });
  }
}
