
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Options, Select2AjaxOptions } from 'select2';
import { Select2OptionData } from 'ng-select2';
import { FormGroup, FormBuilder, FormControl, Validators, Form, NgModel } from '@angular/forms';
import { ValidateMessage, ServiceMsg } from 'src/app/utils/common/validate-msg-aas';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { Config } from 'src/app/config';
import { AuthService } from 'src/app/utils/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location, DatePipe } from '@angular/common';
import { ContractObject } from 'src/app/utils/models/aas-models/contract/contract/contract-object.model';
import { InfoSignatureConfigObject } from 'src/app/utils/models/aas-models/signature/signature-config/info-signature-config-object';
import { ContractStatus } from 'src/app/utils/common/constant';
import { UserInformationsItem } from 'src/app/utils/models/profile/user-info-item.model';

import { EinvoicesObjectResponse } from 'src/app/utils/models/aas-models/einvoices-manager/einvoices-detail/einvoices-object-response.model'
import { EinvoicesDetailObject } from  'src/app/utils/models/aas-models/einvoices-manager/einvoices-detail/einvoices-object.model'
import { from } from 'rxjs';
import { AasEinvoicesService } from 'src/app/utils/services/aas-network/aas-einvoices.service'
import { throwIfEmpty } from 'rxjs/operators';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ConstantAlertBtn, ConstantAlertMsg } from 'src/app/utils/common/constant-alert-msg';
import { constants } from 'buffer';


declare var PDFSign: any;
declare var $: any;
@Component({
  selector: 'app-service-envoices-detail',
  templateUrl: './service-envoices-detail.component.html',
  styleUrls: ['./service-envoices-detail.component.scss']
})
export class ServiceEnvoicesDetailComponent implements OnInit {
  @ViewChild('output') output: ElementRef;
  @ViewChild('filename') filename: ElementRef;
  @ViewChild('filebase') filebase: ElementRef;
  @ViewChild('iframe') iframe: ElementRef;
  @ViewChild('sendEmailDetail') sendEmail: ElementRef;

  public exampleData: Array<Select2OptionData> = [];
  public nguoiNhanData: Array<Select2OptionData> = [];
  public hinhThucKyData: Array<Select2OptionData> = [];
  public options: Options;
  public optionsTemplate: Options;
  public optionsHinhThucKy: Options;
  public ajaxOptions: Select2AjaxOptions;

  einvoicesObjectResponse : EinvoicesObjectResponse;
  einvoicesDetailObject : EinvoicesDetailObject;

  action: string = this.activeRoute.snapshot.queryParamMap.get('action') || '';
  contractId: string;
  uuid = this.activeRoute.snapshot.paramMap.get('id');
  taxCode = this.activeRoute.snapshot.paramMap.get('taxCode');

  contract: ContractObject = new ContractObject();

  //mark: user
  userInfo: UserInformationsItem = new UserInformationsItem();
  userType: string = '';

  //mark: nhóm chức năng khởi tạo thông tin hợp đồng
  filePDF: File = null;
  inputFileLabel: string = 'Chọn file';
  fileType: Array<string> = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
  selectedFile: boolean = false;
  fileName: string = '';
  typeSelected: number = 1;

  formContract: FormGroup;
  validContractMsg: any = ServiceMsg.FORM_CREATE_CONTRACT;
  isSubmitContract: boolean = false;

  currentStage = ContractStatus;

  //mark: nhóm chức năng ký usb-token
  timeCheck: any;

  //mark: nhóm chức năng ký hợp đồng bằng sign server
  formSign: FormGroup;
  isSubmit: boolean = false;
  validMsg: any = ServiceMsg.SIGNATURE_CONFIG;
  inputFileImageLabel: string = 'Chọn file';
  img_prefix = "data:image/png;base64,";
  imgContent: string = '';
  imgSignServer: File = null;
  imgType = ['image/jpeg', 'image/png', 'image/jpg'];
  pdfSigned: Blob = null;

  
  status = {
    CREATED: {
      style: 'badge-danger-new',
      label: 'Chưa thanh toán',
    },
    PAID: {
      style: 'badge-success-new',
      label: 'Đã thanh toán',
    },
  }

  //Tab controll
  activeTab: string = 'INFO';

  innerHTML: string = '';
  formEmail : FormGroup;

  constructor(
    private _location: Location,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private fb: FormBuilder,
    private authService: AuthService,
    private modalService: NgbModal,
    private einvoicesService: AasEinvoicesService,
  ) {
    // this.timeCheck = this.datePipe.transform(new Date(), "yyyy-MM-dd'T'HH:mm:ss");
  }

  ngOnInit(): void {
    PDFSign.removeAllSignBox();
    this.imgContent = this.img_prefix + PDFSign.getData().imageSrc;
    this.getInfoEinvoces()
    this.downloadFileEinvoice(this.taxCode, this.uuid)
    this.makeFormEmail()
  }

  makeFormEmail(){
    this.formEmail = this.fb.group({
      email : ['', [Validators.required,Validators.email ]]
    })
  }

  get email(){
    return this.formEmail.get('email')
  }


  //MARK: CHỨC NĂNG ĐIỀU KHIỂN TAB
  //Action chọn tab
  selectTab(nameTab: string) {
    this.activeTab = nameTab;

    // if (nameTab == 'SIGN') {
    //   this.selectWayOfSign('SIGN_SERVER');
    // }
  }

  //MARK: THAY ĐỔI GIÁ TRỊ DROPLIST
  changedHinhThucKy(event: Array<any>) {
  }

  //Trở lại trang trước
  goToBack() {
    this._location.back();
  }
  
  //MARK: COMMON FUNC
  //Kiểm tra form đã có dữ liệu chưa
  get editedForm() {
    return false;
  }

  getBase64String(blob, callback) {
    var self = this;
    var reader = new FileReader();
    reader.onload = function (e) {
      callback(reader.result, self);
    }

    reader.readAsDataURL(blob);
  }

  //handel cho file pdf
  handle(str, self) {
    $('#fileBase').val(str.split(',')[1]);
  }

  //Handle cho file hình sign server
  handleImgSignServer(str, self) {
    self.imgContent = str;
    self.base64Image.setValue(str.split(',')[1]);
    PDFSign.setImage(str.split(',')[1]);
  }

  //Lấy thông tin hợp đồng
  getInfoEinvoces() {
    this.einvoicesService.getEinvoiceInfoDetail(this.uuid).subscribe(res=>{
      this.einvoicesObjectResponse = new EinvoicesObjectResponse(res);
      this.einvoicesDetailObject = this.einvoicesObjectResponse.object;

    }, err => {
        console.log(err);
        this.handleError(err, ResponseMsg.MESSAGES.ALL_IN_ONE);
      })
  }

  //Tải file hợp đồng 
  downloadFileEinvoice(taxCode, uuid) {
    this.einvoicesService.downloadFileWithHeader(taxCode, uuid).subscribe((res:any) => {
      const blob: any = new Blob([res], { type: 'text/html' });
      var url = window.URL.createObjectURL(blob);
      this.iframe.nativeElement.src = url;
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.ALL_IN_ONE);
    })
    
  }

  downloadFile() {
    this.einvoicesService.downloadEinvoices(this.einvoicesDetailObject.cusCode, this.einvoicesDetailObject.fkey).subscribe((res:any) => {
      const blob: any = new Blob([res], { type: 'application/pdf' });
      var url = window.URL.createObjectURL(blob);
      var anchor = document.createElement("a");
      anchor.download = `${this.einvoicesDetailObject.fkey}.pdf`;
      anchor.href = url;
      anchor.click();
    }, err => {
      console.log(err);
      this.handleError(err, err);
    })
  }

  
  //Show popup confirm OTP
  openModalConfirmEmail(content) {
    this.modalService.open(content, {
      size: 'md',
      backdrop: 'static',
      keyboard: false
    });
  }

  showPopupMail(email){
    this.email.setValue(email);
    this.openModalConfirmEmail(this.sendEmail)
  }

  btnClickResendEmail(email){
    let data = {
      email : email
    }
    this.einvoicesService.postSendEmail(this.uuid, data).subscribe(res=>{
      this.modalService.dismissAll();
      this.alert("Gửi thông báo thành công!", ConstantAlertType.SUCCESS);
    }, err => {
      console.log(err);
      this.handleError(err, err);
    })
  }

  

  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = ConstantAlertBtn.OK, actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = ConstantAlertBtn.CANCEL, actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

   //MARK: Handle Error
   handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], ConstantAlertType.ERROR);
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, ConstantAlertType.ERROR);
    } else {
      this.alert(ConstantAlertMsg.DEFAULT_ERROR_REQUEST, ConstantAlertType.ERROR);
    }
  }
  

}
