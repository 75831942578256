<div class="modal-header border-0">
    <h5 class="modal-title" style="font-weight: 700; margin-right: auto;">Kết quả định danh eKYC</h5>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close('Close click')">
        <span aria-hidden="true" class="btn-light btn-alert-exit bg-exit-btn d-inline-flex p-2 rounded">
            <img src="assets/img/icon/icon-close.svg" width="18" height="18">
        </span>
    </button>
</div>
<div class="modal-body text-left py-0 enable-scroll">
    <div class="field">
        <div class="row">
            <div class="col-md-4">
                <label for="">Trạng thái giấy tờ</label>
            </div>
            <div class="col-md-8">
                <div class="d-flex">
                    <p>{{geStatusLabel(status)}}</p>

                    <span *ngIf="!(status === 'VALID' || status === 'valid')" class="span-status ml-auto bg-warning-new">
                        <span>i</span>
                    </span>
                    <span *ngIf="status === 'VALID' || status === 'valid'" class="span-status ml-auto bg-success-new">
                        <span class="material-icons">done</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="field">
        <div class="row">
            <div class="col-md-4">
                <label *ngIf="ekycInfo?.loaiGtId == 'CMND'" for="">Số CMND</label>
                <label *ngIf="ekycInfo?.loaiGtId == 'CCCD'" for="">Số CCCD</label>
                <label *ngIf="ekycInfo?.loaiGtId == 'PASSPORT'" for="">Số hộ chiếu</label>
            </div>
            <div class="col-md-8">
                <div class="d-flex">
                    <p>{{ ekycInfo?.idCard?.value }}</p>

                    <span *ngIf="!(ekycInfo?.idCard?.status === 'ByPass' || ekycInfo?.idCard?.status === 'Pass')" class="span-status ml-auto bg-warning-new">
                        <span>i</span>
                    </span>
                    <span *ngIf="ekycInfo?.idCard?.status === 'ByPass' || ekycInfo?.idCard?.status === 'Pass'" class="span-status ml-auto bg-success-new">
                        <span class="material-icons">done</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="field">
        <div class="row">
            <div class="col-md-4">
                <label for="">Họ và tên</label>
            </div>
            <div class="col-md-8">
                <div class="d-flex">
                    <p>{{ ekycInfo?.name?.value }}</p>

                    <span *ngIf="!(ekycInfo?.name?.status === 'ByPass' || ekycInfo?.name?.status === 'Pass')" class="span-status ml-auto bg-warning-new">
                        <span>i</span>
                    </span>
                    <span *ngIf="ekycInfo?.name?.status === 'ByPass' || ekycInfo?.name?.status === 'Pass'" class="span-status ml-auto bg-success-new">
                        <span class="material-icons">done</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="ekycInfo?.loaiGtId == 'CCCD' || ekycInfo?.loaiGtId == 'PASSPORT'" class="field">
        <div class="row">
            <div class="col-md-4">
                <label for="">Giới tính</label>
            </div>
            <div class="col-md-8">
                <div class="d-flex">
                    <p>{{ ekycInfo?.gender?.value }}</p>

                    <span *ngIf="!(ekycInfo?.gender?.status === 'ByPass' || ekycInfo?.gender?.status === 'Pass')" class="span-status ml-auto bg-warning-new">
                        <span>i</span>
                    </span>
                    <span *ngIf="ekycInfo?.gender?.status === 'ByPass' || ekycInfo?.gender?.status === 'Pass'" class="span-status ml-auto bg-success-new">
                        <span class="material-icons">done</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="field">
        <div class="row">
            <div class="col-md-4">
                <label for="">Ngày sinh</label>
            </div>
            <div class="col-md-8">
                <div class="d-flex">
                    <p>{{ ekycInfo?.birthDay?.value }}</p>

                    <span *ngIf="!(ekycInfo?.birthDay?.status === 'ByPass' || ekycInfo?.birthDay?.status === 'Pass')" class="span-status ml-auto bg-warning-new">
                        <span>i</span>
                    </span>
                    <span *ngIf="ekycInfo?.birthDay?.status === 'ByPass' || ekycInfo?.birthDay?.status === 'Pass'" class="span-status ml-auto bg-success-new">
                        <span class="material-icons">done</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="ekycInfo?.loaiGtId == 'PASSPORT'" class="field">
        <div class="row">
            <div class="col-md-4">
                <label for="">Nơi sinh</label>
            </div>
            <div class="col-md-8">
                <div class="d-flex">
                    <p>{{ ekycInfo?.birthPlace?.value }}</p>

                    <span *ngIf="!(ekycInfo?.birthPlace?.status === 'ByPass' || ekycInfo?.birthPlace?.status === 'Pass')" class="span-status ml-auto bg-warning-new">
                        <span>i</span>
                    </span>
                    <span *ngIf="ekycInfo?.birthPlace?.status === 'ByPass' || ekycInfo?.birthPlace?.status === 'Pass'" class="span-status ml-auto bg-success-new">
                        <span class="material-icons">done</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="ekycInfo?.loaiGtId == 'CCCD' || ekycInfo?.loaiGtId == 'CMND'" class="field">
        <div class="row">
            <div class="col-md-4">
                <label for="">Nơi ĐK HKTT</label>
            </div>
            <div class="col-md-8">
                <div class="d-flex">
                    <p>{{ ekycInfo?.recentLocation?.value }}</p>

                    <span *ngIf="!(ekycInfo?.recentLocation?.status === 'ByPass' || ekycInfo?.recentLocation?.status === 'Pass')" class="span-status ml-auto bg-warning-new">
                        <span>i</span>
                    </span>
                    <span *ngIf="ekycInfo?.recentLocation?.status === 'ByPass' || ekycInfo?.recentLocation?.status === 'Pass'" class="span-status ml-auto bg-success-new">
                        <span class="material-icons">done</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="field">
        <div class="row">
            <div class="col-md-4">
                <label for="">Nơi cấp</label>
            </div>
            <div class="col-md-8">
                <div class="d-flex">
                    <p>{{ ekycInfo?.issuePlace?.value }}</p>

                    <span *ngIf="!(ekycInfo?.issuePlace?.status === 'ByPass' || ekycInfo?.issuePlace?.status === 'Pass')" class="span-status ml-auto bg-warning-new">
                        <span>i</span>
                    </span>
                    <span *ngIf="ekycInfo?.issuePlace?.status === 'ByPass' || ekycInfo?.issuePlace?.status === 'Pass'" class="span-status ml-auto bg-success-new">
                        <span class="material-icons">done</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="field">
        <div class="row">
            <div class="col-md-4">
                <label for="">Ngày cấp</label>
            </div>
            <div class="col-md-8">
                <div class="d-flex">
                    <p>{{ ekycInfo?.issueDate?.value }}</p>

                    <span *ngIf="!(ekycInfo?.issueDate?.status === 'ByPass' || ekycInfo?.issueDate?.status === 'Pass')" class="span-status ml-auto bg-warning-new">
                        <span>i</span>
                    </span>
                    <span *ngIf="ekycInfo?.issueDate?.status === 'ByPass' || ekycInfo?.issueDate?.status === 'Pass'" class="span-status ml-auto bg-success-new">
                        <span class="material-icons">done</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="ekycInfo?.loaiGtId == 'CCCD'" class="field">
        <div class="row">
            <div class="col-md-4">
                <label for="">Có giá trị đến</label>
            </div>
            <div class="col-md-8">
                <div class="d-flex">
                    <p>{{ ekycInfo?.validDate?.value }}</p>

                    <span *ngIf="!(ekycInfo?.validDate?.status === 'ByPass' || ekycInfo?.validDate?.status === 'Pass')" class="span-status ml-auto bg-warning-new">
                        <span>i</span>
                    </span>
                    <span *ngIf="ekycInfo?.validDate?.status === 'ByPass' || ekycInfo?.validDate?.status === 'Pass'" class="span-status ml-auto bg-success-new">
                        <span class="material-icons">done</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="field">
        <div class="row">
            <div class="col-md-4">
                <label for="">So sánh</label>
            </div>
            <div class="col-md-8">
                <div class="d-flex">
                    <p>{{ ekycInfo?.compareFace?.value }}</p>

                    <span *ngIf="!(ekycInfo?.compareFace?.status === 'ByPass' || ekycInfo?.compareFace?.status === 'Pass')" class="span-status ml-auto bg-warning-new">
                        <span>i</span>
                    </span>
                    <span *ngIf="ekycInfo?.compareFace?.status === 'ByPass' || ekycInfo?.compareFace?.status === 'Pass'" class="span-status ml-auto bg-success-new">
                        <span class="material-icons">done</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="field">
        <div class="row">
            <div class="col-md-4">
                <label for="">Xác thực</label>
            </div>
            <div class="col-md-8">
                <div class="d-flex">
                    <p>Người thật</p>
                    <span class="span-status ml-auto bg-success-new">
                        <span class="material-icons">done</span>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="field my-3">
        <div class="row">
            <div class="col-md-4">
                <p><label>Ảnh giấy tờ mặt trước</label></p>
                <img style="max-width: 100%; border-radius: 8px" src="{{ ekycInfo?.pathImg?.signImg1 }}">
            </div>
            <div *ngIf="ekycInfo?.loaiGtId != 'PASSPORT'" class="col-md-4">
                <p><label>Ảnh giấy tờ mặt sau</label></p>
                <img style="max-width: 100%; border-radius: 8px" src="{{ ekycInfo?.pathImg?.signImg3 }}">
            </div>
            <div class="col-md-4">
                <p><label>Ảnh chân dung</label></p>
                <img style="max-width: 100%; border-radius: 8px" src="{{ ekycInfo?.pathImg?.signImg2 }}">
            </div>
        </div>
    </div>
</div>
<hr *ngIf="allowActionWhenWarningEKYC(status)" class="mb-0 mt-0">
<div *ngIf="allowActionWhenWarningEKYC(status)" class="modal-footer border-0 d-flex justify-content-end">
    <button type="button" class="btn btn-outline-primary d-flex align-items-center" (click)="cancelContract(modalCancelContract)">
        <span class="material-icons mr-2">clear</span>
        Hủy hợp đồng
    </button>
    <button type="button" class="btn btn-outline-primary d-flex align-items-center" (click)="resignRequest()">
        <span class="material-icons-outlined mr-2">replay</span>
        Yêu cầu ký lại
    </button>
    <button type="button" class="btn btn-primary d-flex align-items-center" (click)="allowSignature()">
        <span class="material-icons mr-2">done</span>
        Chấp nhận chữ ký
    </button>
</div>


<ng-template #modalCancelContract let-c="close" let-d="dismiss">
    <div class="modal-header border-0">
        <h5 class="modal-title" style="font-weight: 600; margin-right: auto;">Hủy hợp đồng</h5>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="btn btn-light bg-exit-btn d-inline-flex"><span class="material-icons">
                    close
                </span></span>
        </button>
    </div>
    <div class="modal-body text-left py-0 enable-scroll">
        <form [formGroup]="formReturn">
            <div class="form-group">
                <label>Lý do hủy hợp đồng: </label>
                <textarea formControlName="reasonReturn" class="form-control" rows="5"
                    placeholder="Vui lòng nhập lý do hủy hợp đồng"></textarea>
                <app-show-validate-errors [isSubmit]="isCancelSubmit"
                    [errorMessages]="validateMsgFormDenyDiscuss.reason" [control]="formReturn.controls.reasonReturn"
                    [detail]="{ name: 'reasonReturn' }"></app-show-validate-errors>
            </div>
        </form>
    </div>
    <div class="modal-footer border-0 d-flex justify-content-end">
        <button type="button" class="btn btn-outline-primary" (click)="c('Close')">Huỷ</button>
        <button type="button" class="btn btn-primary" (click)="submitReturn()">Xác nhận</button>
    </div>
</ng-template>