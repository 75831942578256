<div class="card shadow-none">
    <div class="card-body position-relative">
        <div class="row">
            <div class="col-md-auto align-self-end">
                <label class="text-lg">{{ 'SignUp_chon_loai_khach_hang' | translate }}</label>
            </div>
        </div>
        <hr class="my-3">
        
        <div class=''>
            <div class="row mb-4 justify-content-center">
                <div class="col-md-4 row-eq-height">
                    <div class="card type-customer-card" (click)="selectType(userType.CONSUMER)" [class.active]="userTypeSelected == userType.CONSUMER">
                        <div class="card-body">
                            <div class="row justify-content-center">
                                <div class="col-md-8 col-sm-6">
                                    <img src="assets/img/canhan-dangky.svg" style="width: 100%; height: auto;">
                                </div>
                            </div>
                            <div class="text-center pt-4">
                                <h2><label>{{ 'individual' | translate }}</label></h2>
                                <p>{{ 'SignUp_khong_co_cts' | translate }}</p>
                            </div>
                        </div>
                        <!-- <span class="active-card material-icons text-success">stop_circle</span> -->
                    </div>
                </div>

                <div class="col-md-4 row-eq-height">
                    <div class="card type-customer-card" (click)="selectType(userType.BUSINESS)" [class.active]="userTypeSelected == userType.BUSINESS">
                        <div class="card-body">
                            <div class="row justify-content-center">
                                <div class="col-md-8 col-sm-6">
                                    <img src="assets/img/tochuc-sme-dangky.svg" style="width: 100%; height: auto;">
                                </div>
                            </div>
                            <div class="text-center pt-4">
                                <h2><label>{{ 'SignUp_to_chuc' | translate }}</label></h2>
                                <p>{{ 'SignUp_to_chuc_dk' | translate }}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="col-md-4 row-eq-height">
                    <div class="card type-customer-card" (click)="selectType(userType.ENTERPRISE)" [class.active]="userTypeSelected == userType.ENTERPRISE">
                        <div class="card-body">
                            <div class="row justify-content-center">
                                <div class="col-md-8 col-sm-6">
                                    <img src="assets/img/tochuc-dangky.svg" style="width: 100%; height: auto;">
                                </div>
                            </div>
                            <div class="text-center pt-4">
                                <h2><label>Giải pháp</label></h2>
                                <p>Tổ chức của bạn đã có hệ thống quản lý riêng và muốn trở nên chuyên nghiệp hơn, mạnh mẽ hơn bằng cách tích hợp các giải pháp của eContract ngay trên hệ thống của mình.</p>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="row justify-content-center">
                <button [disabled]="userTypeSelected == ''" class="btn btn-primary btn-lg col-md-3" (click)="nextAction()">{{ 'btn_continue' | translate }}</button>
            </div>
        </div>
        <!-- <app-mini-spinner *ngIf="loadingService.isVisible() | async"></app-mini-spinner> -->
    </div>
</div>