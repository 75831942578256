<aside #mainSidebar class="main-sidebar sidebar-light-primary elevation-4">
  <!-- Brand Logo -->
  <a [routerLink]="['/']" class="brand-link navbar-primary">
    <img src="assets/img/icon-logo.png" alt="AdminLTE Logo" class="brand-image elevation-0" style="opacity: 1;" />
    <span class="brand-text sidebar-logo-text font-weight-light">VNPT</span>
  </a>

  <!-- Sidebar -->
  <div class="sidebar">
    <!-- Sidebar user (optional) -->
    <!-- <div class="user-panel mt-3 pb-3 mb-3 d-flex">
      <div class="image">
        <img src="assets/img/user2-160x160.jpg" class="img-circle elevation-2" alt="User Image" />
      </div>
      <div class="info">
        <a [routerLink]="['/profile']" class="d-block">Alexander Pierce</a>
      </div>
    </div> -->

    <!-- Sidebar Menu -->
    <nav *ngIf="path === 'portal'" class="mt-2 mb-2">
      <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
        <li class="nav-item">
          <!-- *showWithRoles="['VNP_ADMIN', 'TTKD_ADMIN', 'TTKD_AM']" -->
          <a  *showWithRoles="['ECT1060']" [routerLink]="['/portal/dashboard']" routerLinkActive="active" class="nav-link nav-material">
            <em class="material-icons">dashboard</em>
            <p>
              Dashboard
            </p>
          </a>
        </li>

        <!-- <li *showWithRoles="['VNP_ADMIN']" class="nav-item has-treeview">
          <a href="#" class="nav-link nav-material">
            <span class="nav-icon material-icons">
              class
            </span>
            <p>
              Quản lý mẫu hợp đồng
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item">
              <a [routerLink]="['/portal/contract-templates/create']" routerLinkActive="active"
                class="nav-link nav-material">
                <span class="material-icons nav-icon">
                  library_add
                </span>
                <p>Tạo mẫu hợp đồng</p>
              </a>
            </li>
            <li class="nav-item">
              <a [routerLink]="['/portal/contract-templates/list']" routerLinkActive="active"
                class="nav-link nav-material">
                <span class="material-icons nav-icon">
                  library_books
                </span>
                <p>Danh sách mẫu hợp đồng</p>
              </a>
            </li>
          </ul>
        </li> -->

        <li class="nav-item">
        <!-- ['PMCM', 'PMSTP', 'PMP1', 'PMDL'] -->
          <a  *showWithRoles="['PMCM', 'PMSTP', 'PMP1', 'PMDL']"  [routerLink]="['/portal/party-management']"
            routerLinkActive="active" class="nav-link">
            <span class="material-icons nav-icon">
              people
            </span>
            <p>Quản lý đơn vị</p>
          </a>
        </li>

        <li *showWithRoles="['ECT1000', 'ECT1001', 'ECT1002', 'ECT1003', 'ECT1004', 'ECT1005', 'ECT1006']" class="nav-item has-treeview">
          <a href="#" class="nav-link nav-material">
            <span class="nav-icon material-icons">
              assignment_ind
            </span>
            <p>
              Quản lý nhóm người dùng
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li *showWithRoles="['ECT1000', 'ECT1001', 'ECT1002', 'ECT1003']" class="nav-item">
              <a [routerLink]="['/portal/user-manager/user-group']" routerLinkActive="active"
                class="nav-link nav-material">
                <em class="material-icons">group</em>
                <p>
                  Danh sách nhóm
                </p>
              </a>
            </li>
            <li *showWithRoles="['ECT1004', 'ECT1005', 'ECT1006']" class="nav-item">
              <a [routerLink]="['/portal/user-manager/set-group-permission']" routerLinkActive="active"
                class="nav-link nav-material">
                <em class="material-icons">assignment_ind</em>
                <p>
                  Phân quyền nhóm
                </p>
              </a>
            </li>
          </ul>
        </li>

        <!-- Quản lí nhân viên -->
        <!-- ['USMC1', 'USMCM', 'USMUP','USMDL', 'USMDTL', 'USMDT'] -->
        <li class="nav-item">
          <a *showWithRoles="['USMC1', 'USMCM', 'USMUP','USMDL', 'USMDTL', 'USMDT']"
          [routerLink]="['/portal/employee-manager']"
            routerLinkActive="active" class="nav-link">
            <span class="material-icons nav-icon">
              folder_shared
            </span>
            <p>Quản lý nhân viên</p>
          </a>
        </li>

        <li *showWithRoles="['ECT2100', 'ECT2101', 'ECT2102','ECT2103']" class="nav-item">
          <a [routerLink]="['/portal/categories/manage']" routerLinkActive="active" class="nav-link">
            <em class="nav-icon far fa-list-alt"></em>
            <p>Quản lý danh mục SPDV</p>
          </a>
        </li>

        <li *showWithRoles="['ECT2300', 'ECT2301', 'ECT2305','ECT2302','ECT2304','ECT2303']" class="nav-item">
          <a [routerLink]="['/portal/contract-templates/manage']" routerLinkActive="active" class="nav-link">
            <em class="nav-icon far fa-file-word"></em>
            <p>Quản lý hợp đồng mẫu</p>
          </a>
        </li>

        <li class="nav-item">
          <!-- ['ECT2200', 'ECT2201', ' ECT2202','ECT2203'] -->
          <a  *showWithRoles="['ECT2200', 'ECT2201', ' ECT2202','ECT2203']" [routerLink]="['/portal/document-manager']"
            routerLinkActive="active" class="nav-link">
            <span class="material-icons nav-icon">
              description
            </span>
            <p>Quản lý tài liệu</p>
          </a>
        </li>

        <li *showWithRoles="['ECT2000', 'ECT2001', 'ECT2002','ECT2004',' ECT2003']" class="nav-item">
          <a [routerLink]="['/portal/contract-template-groups/manage']" routerLinkActive="active" class="nav-link">
            <em class="nav-icon fas fa-file-contract"></em>
            <p>Quản lý bộ hồ sơ mẫu</p>
          </a>
        </li>

        <li *showWithRoles="['KH_USER','ECT1044','ECT1048']" class="nav-item has-treeview">
          <a href="#" class="nav-link nav-material">
            <span class="material-icons nav-icon">
              all_inbox
            </span>
            <p>
              Quản lý nội bộ
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
          <ul *showWithRoles="['KH_USER','ECT1044','ECT1048']" class="nav nav-treeview">
            <li class="nav-item" >
              <a [routerLink]="['/portal/internal/info']" routerLinkActive="active" class="nav-link nav-material">
                <span class="material-icons nav-icon">
                  store_mall_directory
                </span>
                <p>Thông tin đơn vị</p>
              </a>
            </li>
            <!-- <li class="nav-item">
              <a [routerLink]="['/portal/internal/cert']" routerLinkActive="active" class="nav-link nav-material">
                <span class="material-icons nav-icon">
                  layers
                </span>
                <p>Quản lý chứng thư số</p>
              </a>
            </li> -->
            <!-- <li *showWithRoles="['VNP_ADMIN']" class="nav-item">
              <a [routerLink]="['/portal/internal/contract-party-report']" routerLinkActive="active"
                class="nav-link nav-material">
                <span class="material-icons nav-icon">
                  equalizer
                </span>
                <p>Báo cáo nội bộ</p>
              </a>
            </li> -->
            <!-- <li *showWithRoles="['TTKD_ADMIN']" class="nav-item">
              <a [routerLink]="['/portal/internal/contract-customer-report']" routerLinkActive="active"
                class="nav-link nav-material">
                <span class="material-icons nav-icon">
                  equalizer
                </span>
                <p>Báo cáo nội bộ</p>
              </a>
            </li> -->
          </ul>
        </li>

        <li *showWithRoles="['ECT1049', 'ECT1050', 'ECT1051', 'ECT1052', 'ECT1053', 'ECT1054']" class="nav-item has-treeview">
          <a href="#" class="nav-link nav-material">
            <span class="nav-icon material-icons">
              gesture
            </span>
            <p>
              Quản lý chữ ký số
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li *showWithRoles="['ECT1049', 'ECT1050', 'ECT1052', 'ECT1053', 'ECT1054','ECT1070','ECT1071']" class="nav-item">
              <a [routerLink]="['/portal/signature/list']" routerLinkActive="active" class="nav-link">
                <span class="material-icons nav-icon">
                  gesture
                </span>
                <p>Danh sách chữ ký số</p>
              </a>
            </li>
            <li *showWithRoles="['ECT1072','ECT1073']" class="nav-item">
              <a [routerLink]="['/portal/config/signature/sign']" routerLinkActive="active" class="nav-link">
                <span class="material-icons nav-icon">
                  gesture
                </span>
                <p>Cấu hình tài khoản ký</p>
              </a>
            </li>
             <li *showWithRoles="['ECT1070','ECT1071']" class="nav-item">
              <a [routerLink]="['/portal/config/stamped']" routerLinkActive="active" class="nav-link">
                <span class="material-icons nav-icon">
                  gesture
                </span>
                <p>Cấu hình tài khoản đóng dấu</p>
              </a>
            </li>
          </ul>
        </li>
        <li *showWithRoles="['ECT1031', 'ECT1032', 'ECT1033','ECT1034','ECT1035','ECT1074']" class="nav-item has-treeview">
          <a href="#" class="nav-link nav-material">
            <span class="nav-icon material-icons">
              assignment_ind
            </span>
            <p>
              Quản lý khách hàng
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li *showWithRoles="['ECT1032']" class="nav-item">
              <a [routerLink]="['/portal/customers/create']" routerLinkActive="active" class="nav-link nav-material">
                <span class="material-icons nav-icon">
                  person_add
                </span>
                <p>Tạo khách hàng</p>
              </a>
            </li>
            <li *showWithRoles="['ECT1031']" class="nav-item">
              <a [routerLink]="['/portal/customers/list']" routerLinkActive="active" class="nav-link nav-material">
                <span class="material-icons nav-icon">
                  supervised_user_circle
                </span>
                <p>Danh sách khách hàng</p>
              </a>
            </li>
          </ul>
        </li>

        <!-- <li *showWithRoles="['TTKD_AM', 'USER', 'KH_USER']" class="nav-item has-treeview">
          <a href="#" class="nav-link nav-material">
            <span class="nav-icon material-icons">
              style
            </span>
            <p>
              Quản lý hợp đồng
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li *showWithRoles="['TTKD_AM']" class="nav-item">
              <a [routerLink]="['/portal/contracts/create']" routerLinkActive="active" class="nav-link nav-material">
                <span class="material-icons nav-icon">
                  add_box
                </span>
                <p>Khởi tạo hợp đồng</p>
              </a>
            </li>
            <li *showWithRoles="['TTKD_AM', 'USER', 'KH_USER', 'TTKD_ADMIN']" class="nav-item">
              <a [routerLink]="['/portal/contracts/list']" routerLinkActive="active" class="nav-link">
                <em class="nav-icon far fa-file-alt"></em>
                <p>Danh sách hợp đồng</p>
              </a>
            </li>

            <li *showWithRoles="['VNP_ADMIN']" class="nav-item">
              <a [routerLink]="['/portal/contracts/list-vnp']" routerLinkActive="active" class="nav-link">
                <em class="nav-icon far fa-file-alt"></em>
                <p>Danh sách hợp đồng</p>
              </a>
            </li>
          </ul>
        </li> -->

<!--
        <li *showWithRoles="['KH_USER','USER']" class="nav-item">
          <a [routerLink]="['/portal/signature-usb-token']" routerLinkActive="active" class="nav-link">
            <span class="material-icons nav-icon">
              gesture
            </span>
            <p>Quản lý USB-Token</p>
          </a>
        </li> -->

        <li *showWithRoles="['KH_USER', 'CTMCCT', 'CTMUCT', 'CTMLCT','CTMSIC', 'CTMSEC', 'CTMDCT','ECT1042', 'CTMDDM', 'CTMADM','CTMHCT', 'CTMANS', 'CTMDZI', 'CTMLIS']" class="nav-item">
          <a [routerLink]="['/portal/contracts/manage']" routerLinkActive="active" class="nav-link">
            <em class="nav-icon far fa-list-alt"></em>
            <p>Quản lý bộ hồ sơ hợp đồng</p>
          </a>
        </li>

        <li class="nav-item">
          <a *showWithRoles="['ECT1068','ECT1069']" [routerLink]="['/portal/report-analytics/list']" routerLinkActive="active"
            class="nav-link">
            <span class="material-icons nav-icon">
              assessment
            </span>
            <p>Báo cáo thống kê KHDN</p>
          </a>
        </li>

        <!-- <li *showWithRoles="['TTKD_ADMIN']" class="nav-item has-treeview">
          <a href="#" class="nav-link nav-material">
            <span class="material-icons nav-icon">
              recent_actors
            </span>
            <p>
              Quản lý tài khoản
              <i class="right fas fa-angle-left"></i>
            </p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item" *showWithRoles="['TTKD_ADMIN']">
              <a [routerLink]="['/portal/accounts/batch-create']" routerLinkActive="active"
                class="nav-link nav-material">
                <span class="material-icons nav-icon">
                  person_add
                </span>
                <p>Thêm tài khoản</p>
              </a>
            </li>
            <li *showWithRoles="['TTKD_ADMIN']" class="nav-item">
              <a [routerLink]="['/portal/accounts/list']" routerLinkActive="active" class="nav-link nav-material">
                <span class="material-icons nav-icon">
                  contacts
                </span>
                <p>Danh sách tài khoản</p>
              </a>
            </li>
          </ul>
        </li> -->
      </ul>
    </nav>
    <!-- /.sidebar-menu -->
  </div>
  <a (click)="logout()" class="logout-link brand-link navbar-primary text-white" style="cursor: pointer;">
    <em class="fas fa-sign-out-alt"></em>
    <span class="brand-text">Đăng xuất</span>
  </a>
  <!-- /.sidebar -->
</aside>
