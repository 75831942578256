import { ListUserGroupItem } from './list-user-group-item';

export class ListUserGroupObject {
    data: ListUserGroupItem[];
    page: number;
    maxSize: number;
    propertiesSort: string;
    totalElement: number;

    constructor(obj = null) {
        obj = obj || {};
        this.page = obj.page || 1;
        this.maxSize = obj.maxSize || 20;
        this.propertiesSort = obj.propertiesSort || '';
        this.totalElement = obj.totalElement || 0;
        this.data = [];
        if (obj.data && obj.data instanceof Array) {
            obj.data.forEach((item) => {
                this.data.push(new ListUserGroupItem(item));
            });
        }
    }
}