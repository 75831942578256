import { Injectable } from '@angular/core';
import { ServicePath } from 'src/app/utils/common/constant-service-api';
import { API } from 'src/app/utils/services/api';

const LIST_TYPES = ServicePath.MANAGEMENT_SERVICE + "/contract-types";
const ADD_CONTRACT_TYPE = ServicePath.MANAGEMENT_SERVICE + "/contract-type";
const DETAIL_CONTRACT_TYPE = ServicePath.MANAGEMENT_SERVICE + "/contract-type";
const EDIT_CONTRACT_TYPE = ServicePath.MANAGEMENT_SERVICE + "/contract-type";
const CHECK_NAME_EXISTS = ServicePath.MANAGEMENT_SERVICE + "/contract-type/check-exists";

@Injectable({
  providedIn: 'root'
})
export class ContractTypeService {

  constructor(
    private api: API
  ) { }

  getListContractTypes(page = 1, maxSize = 10, propertiesSort = "modified", sort = "DESC", status = "", searchString = "") {
    const url = LIST_TYPES + `/?searchString=${searchString}&status=${status}&page=${page}&maxSize=${maxSize}&propertiesSort=${propertiesSort}&sort=${sort}`;
    return this.api.get(url);
  }

  postAddContractType(data) {
    const url = ADD_CONTRACT_TYPE;
    return this.api.post(url, data);
  }

  getTypeDetails(id: string) {
    const url = DETAIL_CONTRACT_TYPE + `/${id}`;
    return this.api.get(url);
  }

  editType(id: string, data) {
    const url = EDIT_CONTRACT_TYPE + `/${id}`;
    return this.api.post(url, data);
  }

  postCheckNameExists(data) {
    const url = CHECK_NAME_EXISTS;
    return this.api.post(url, data)
  }
  
}
