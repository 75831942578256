import { MainResponse } from '../../../main-response.model';
import { UsbTokenItem } from './usb-token-item';

export class ListUsbTokenResponse extends MainResponse {
    data: UsbTokenItem[] = [];

    constructor(obj = null) {
        super();
        obj = obj || {};

        if (obj.object && obj.object instanceof Array) {
            obj.object.forEach(item => {
                this.data.push(new UsbTokenItem(item));
            });
        }
    }
}