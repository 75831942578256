<div [formGroup]="contractPostForm">
    <!-- *ngIf="checkCustomerPost()" -->
    <div #chat_box class="ct-enable-scroll chat-box history chat border border rounded" #scrollCustom>
        <div class="cmt-list" *ngFor="let item of contractPost; let i = index">
            <!-- *ngIf="item.type == 'customer'" -->
            <ul>
                <li *ngIf="!['ACCEPT','DENY'].includes(item.type)" class="">
                    <div class="cmt-item" [class.is-currentuser]="isCurrentUser(item.userId)">
                        <div class="cmt-meta">
                            <span class="cmt-username">{{ item.email }}</span>
                            <span class="cmt-dot material-icons">
                                fiber_manual_record
                            </span>
                            <span class="cmt-time mr-4" style="font-size: 11px;">{{
                                item.lastUpdate
                                ? (item.lastUpdate | date: 'dd/MM/yyyy, HH:mm')
                                : (item.createdDate | date: 'dd/MM/yyyy, HH:mm')
                                }}</span>
                        </div>

                        <div class="cmt-body">
                            <div class="cmt-content flex-grow-1 rounded">
                                <p *ngIf="!item.isEdit">
                                    <span>{{ item.value }} </span>
                                </p>
                                <div *ngIf="!item.isEdit">
                                    <p *ngFor="let document of item.documents">
                                        <em 
                                            class="fileclass cursor-pointer text-decoration-underline text-primary pr-3" 
                                            (click)="downloadDocument(item.contractId, document)">
                                            {{ document.originalName }}
                                        </em>
                                    </p>
                                </div>

                                <input appTrimValue class="form-control flex-grow-1 border-0 my-2" *ngIf="item.isEdit"
                                    formControlName="customerEdit" disabled="{{ !item.isEdit }}"
                                    value="{{ item.value }}" />
                            </div>
                            <div *ngIf="allowSendMess" class="cmt-tool d-flex">
                                <!-- *ngIf="checkPostDisable() && getUserId() != item.userId" -->
                                <a class="cmt-btn-reply mr-auto" (click)="clickReply(item)"
                                    *ngIf="checkReplyDisable(item) && allowSendMess">{{ 'CommentPopup_input_placeholder_reply' | translate }}</a>
                                <!-- *ngIf="checkPostDisable() && !item.isEdit && getUserId() == item.userId" -->
                                <a class="cmt-btn-reply mr-auto" *ngIf="!item.isEdit && !checkReplyDisable(item)"
                                    (click)="clickEdit(item, 'post')">{{ 'btn_edit' | translate }}</a>
                                <a class="cmt-btn-reply mr-3" *ngIf="item.isEdit" (click)="cancelEditFb(item)">{{ 'btn_cancel' | translate }}</a>
                                <a class="cmt-btn-reply mr-auto" *ngIf="item.isEdit"
                                    (click)="saveEdit(item, contractPostForm.controls.customerEdit.value, 'post')"
                                    #editCustomerFb>{{ 'btn_save' | translate }}</a>
                            </div>
                        </div>
                    </div>

                    <ul class="cmt-reply">
                        <li *ngFor="let reply of item.comments; let j = index">
                            <div class="cmt-item" [class.is-currentuser]="isCurrentUser(reply.userId)">
                                <div class="cmt-meta">
                                    <span class="cmt-username">{{ reply.email }}</span>
                                    <span class="cmt-dot material-icons">
                                        fiber_manual_record
                                    </span>
                                    <span class="cmt-time mr-4" style="font-size: 11px;">{{
                                        reply.lastUpdate
                                        ? (reply.lastUpdate | date: 'dd/MM/yyyy, HH:mm')
                                        : (reply.createdDate | date: 'dd/MM/yyyy, HH:mm')
                                        }}</span>
                                </div>
                                <div class="cmt-body">
                                    <div class="cmt-content flex-grow-1 rounded">
                                        <!-- hiển thị file đính kèm lên mục reply khách hàng-->
                                        <!-- <div class="text-primary">@{{reply.tagEmail}}</div> -->

                                        <p *ngIf="!reply.isEdit">
                                            <span>{{ reply.value }} </span>
                                        </p>
                                        <div *ngIf="!reply.isEdit">
                                            <p *ngFor="let document of reply.documents">
                                                <em 
                                                    class="fileclass cursor-pointer text-decoration-underline text-primary pr-3" 
                                                    (click)="downloadDocument(item.contractId, document)">
                                                    {{ document.originalName }}
                                                </em>
                                            </p>
                                        </div>

                                        <input appTrimValue class="form-control flex-grow-1 border-0 my-2"
                                            *ngIf="reply.isEdit" formControlName="customerEdit"
                                            [disabled]="!reply.isEdit" value=" {{ reply.value }}" />

                                    </div>
                                    <div *ngIf="allowSendMess" class="cmt-tool d-flex">
                                        <a class="cmt-btn-reply mr-auto" (click)="clickReply(reply)"
                                            *ngIf="checkReplyDisable(reply) && allowSendMess">{{ 'CommentPopup_input_placeholder_reply' | translate }}</a>
                                        <!-- *ngIf="checkPostDisable() && !reply.isEdit && getUserId() == reply.userId" -->
                                        <a class="cmt-btn-reply mr-auto"
                                            *ngIf="!reply.isEdit && !checkReplyDisable(reply)"
                                            (click)="clickEdit(reply, 'comment')">{{ 'btn_edit' | translate }}</a>
                                        <a class="cmt-btn-reply mr-3" *ngIf="reply.isEdit"
                                            (click)="cancelEditFb(reply)">{{ 'btn_cancel' | translate }}</a>
                                        <a class="cmt-btn-reply mr-auto" *ngIf="reply.isEdit"
                                            (click)="saveEdit(reply, contractPostForm.controls.customerEdit.value, 'comment')">{{ 'btn_save' | translate }}</a>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li *ngIf="item.isReply && allowSendMess">
                            <div class="d-flex rounded pt-2">
                                <div class="tb-comment flex-grow-1 d-flex rounded border mr-3">
                                    <input appTrimValue class="form-control flex-grow-1" formControlName="customerReply"
                                        id="customerReply"
                                        (keyup.enter)="sendComment(item, contractPostForm.controls.customerReply.value)"
                                        placeholder="{{ 'ChatManager_enter_reponse' | translate }}"
                                        style="box-sizing: border-box; border-radius: 8px; box-shadow: 0 0 black;" />
                                    <input class="material-icons text-center" type="file" multiple id="fileInputCustomerReply"
                                        (input)="uploadFileAttachment($event.target.files,  'comment')"
                                        #fileInputCustomerReply hidden />
                                    <em class="material-icons cursor-pointer text-center"
                                        (click)="addAttachment(fileInputCustomerReply)">attach_file</em>
                                </div>

                                <button class="btn btn-primary"
                                    (click)="sendComment(item, contractPostForm.controls.customerReply.value)"
                                    type="submit">
                                    {{ 'ChatManager_label_2' | translate }}
                                </button>
                            </div>
                            <div class="pt-2">
                                <app-attach-file [postFileItem]="customerCommentFileItem"
                                    [postFileObj]="customerCommentFileObj" [fileInput]="fileInputCustomerReply">
                                </app-attach-file>
                            </div>
                        </li>
                    </ul>
                </li>
                <li *ngIf="['ACCEPT','DENY'].includes(item.type)">
                    <div class="pt-3 pb-3 pl-2 rounded" style="background-color:#F5F5F5;border-radius:4px">
                        <div>{{item.email}} {{item.value}} ({{item.createdDate | date: 'dd/MM/yyyy'}})</div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <!-- Input nhập phản hồi vs khách hàng -->

    <div *ngIf="allowSendMess" class="d-flex mt-3">
        <div class="tb-comment flex-grow-1 d-flex rounded border mr-2">
            <input appTrimValue class="form-control flex-grow-1" formControlName="customerFb" id="customerFb"
                (keyup.enter)="sendPost(contractPostForm.controls.customerFb.value, typeDiscuss)"
                placeholder="{{ 'ChatManager_label_1' | translate }}"
                style="box-sizing: border-box; border-radius: 8px; box-shadow: 0 0 black;" />
            <input class="material-icons" type="file" multiple id="fileInputCustomerFB"
                (input)="uploadFileAttachment($event.target.files, 'post')" #fileInputCustomerFB hidden />
            <em class="material-icons cursor-pointer text-center" (click)="addAttachment(fileInputCustomerFB)">attach_file</em>
            <em class="material-icons" hidden>touch_app</em>
        </div>

        <button class="btn btn-primary" (click)="sendPost(contractPostForm.controls.customerFb.value, typeDiscuss)"
            type="submit">
            {{ 'ChatManager_label_2' | translate }}
        </button>
    </div>
    <app-attach-file [postFileItem]="customerPostFileItem" [postFileObj]="customerPostFileObj"
        [fileInput]="fileInputCustomerFB">
    </app-attach-file>
</div>