<app-spinner></app-spinner>
<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0 text-dark"><label>Người dùng tổ chức</label></h1>
            </div>
        </div>
    </div>
</div>

<!--MARK top bar -->
<section class="content">
    <div class="container-fluid">
        <!-- <div class="row">
                <div class="col-md-24"> -->
        <div class="container-fluid">
            <div class="content-right-sidebar border-tab mb-4">
                <ul class="nav nav-tabs" id="tabSignAction" role="tablist">
                    <li class="nav-item nav-material">
                        <a class="nav-link d-flex active" id="waiting-tab" data-toggle="pill" href="#waiting" role="tab"
                            aria-controls="waiting" aria-selected="true">
                            <span class="material-icons">query_builder</span>
                            Chờ phê duyệt
                        </a>
                    </li>
                    <li class="nav-item nav-material">
                        <a class="nav-link d-flex" id="approved-tab" data-toggle="pill" href="#approved" role="tab"
                            aria-controls="approved" aria-selected="false">
                            <span class="material-icons">check_circle_outline</span>
                            Đã phê duyệt
                        </a>
                    </li>

                    <li class="nav-item nav-material">
                        <a class="nav-link d-flex" id="reject-tab" data-toggle="pill" href="#reject" role="tab"
                            aria-controls="reject" aria-selected="false">
                            <span class="material-icons">cancel_outline</span>
                            Từ chối phê duyệt
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <!-- </div>
            </div> -->

        <div class="tab-content contract-tab-body" id="tabSignAction">
            <!--MARK chữ kí số USB-Token -->
            <div class="tab-pane fade show active " id="waiting" role="tabpanel" aria-labelledby="waiting">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="card shadow-none">
                                <div class="card-body pb-0">
                                    <div class="row pt-2 pb-2">
                                        <div class="col-md-auto ">
                                            <label class="text-lg">Danh sách chờ phê duyệt</label>
                                        </div>

                                        <div class="input-group mt-3">
                                            <!-- <form class="input-group"> -->
                                            <div class="input-group-prepend">
                                                <button class="btn btn-primary d-inline-flex pr-2 pl-2" type="submit"
                                                    (click)="clickSearchBtn()">
                                                    <span class="material-icons">search</span>
                                                </button>
                                            </div>
                                            <input type="text" class="form-control" placeholder="Nhập tên đơn vị..."
                                                value="{{ keySearch }}" (input)="inputKeySearch($event)" #searchField
                                                aria-label="" aria-describedby="basic-addon1"
                                                (keyup.enter)="clickSearchBtn()">
                                            <!-- </form> -->
                                        </div>
                                    </div>
                                </div>

                                <div class="position-relative">
                                    <!-- <app-mini-spinner *ngIf="loadingListUserGroupIsVisiable() | async"></app-mini-spinner> -->
                                    <ng-template #noData>
                                        <div class="no-content-list">
                                            <img
                                                class="mb-2"
                                                src="assets/img/icon/icon-no-user-group.png"
                                                width="100"
                                            />
                                            <p class="mb-1">
                                                Không có dữ liệu.
                                            </p>
                                        </div>
                                    </ng-template>

                                    <div>
                                        <div class="user-group-item content-list-item px-0 pt-2">
                                            <div *ngIf="!!enterprises && enterprises.length; else noData" class="content-list fade show">
                                                <div *ngFor="let item of enterprises; let i = index"
                                                    (click)="detailEnterprise(item)"
                                                    class="user-group-item content-list-item px-4 pt-2"
                                                    [class.active]="idRowActive === item.partyId">
                                                    <div class="border-bottom d-flex">
                                                        <span class="material-icons icon-item pr-3">business</span>
                                                        <div class="flex-grow-1 mb-2">
                                                            <label
                                                                class="title-item">{{ item.tenToChuc || 'Không tên' }}</label>
                                                            <div class="row-sub hide-text">
                                                                <span class="badge badge-light-new">{{item.maSoThue}} </span>
                                                                <span class="badge badge-light-new">{{item.email}} </span>
                                                            </div>
                                                        </div>
                                                        <!-- <a *ngIf="!item.isUsed"
                                                            class="text-decoration-underline text-primary pl-4"
                                                            (click)="deleteDocument($event, item)"
                                                            style="cursor: pointer;">Xóa</a> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- ====================================
                      tạo page tài liệu
                      ==================================== -->
                            <div *ngIf="totalElement > maxSize" class="pagination justify-content-end pages mt-3">
                                <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="5"
                                    [rotate]="true" [ellipses]="false" [pageSize]="maxSize"
                                    (pageChange)="getListEnterprises($event)" [boundaryLinks]="true"
                                    aria-label="Default pagination">
                                    <ng-template ngbPaginationFirst>Đầu</ng-template>
                                    <ng-template ngbPaginationLast>Cuối</ng-template>
                                    <ng-template ngbPaginationPrevious>&laquo;</ng-template>
                                    <ng-template ngbPaginationNext>&raquo;</ng-template>
                                    <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                                </ngb-pagination>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="card shadow-none">
                                <div class="card-body position-relative">
                                    <!-- <form > -->
                                    <!-- <form [formGroup]="form"> -->
                                    <div class="row pt-2 pb-2">
                                        <div class="col-md-auto align-self-end">
                                            <label class="text-lg">Thông tin tài khoản</label>
                                        </div>
                                        <div class="col-md-auto ml-auto">
                                            <button *ngIf="idRowActive" class="btn btn-outline-primary btn-light d-inline-flex mr-3"
                                                (click)="onClickCancel(cancelModalContent)">
                                                <span class="material-icons pr-2">clear</span> Từ chối</button>
                                            <button *ngIf="idRowActive" class="btn btn-outline-primary btn-light d-inline-flex mr-3"
                                                (click)="onClickAccept()">
                                                <span class="material-icons pr-2">done_outline</span> Phê duyệt
                                            </button>
                                            <button *ngIf="idRowActive" disabled class="btn btn-primary d-inline-flex"
                                                (click)="onClickCallVideo(enterpriseDetail?.partyId)"
                                                disabled>
                                                <span class="material-icons pr-2">videocam</span>Gọi video
                                            </button>
                                        </div>
                                    </div>
                                    <hr>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="sub-title">Thông tin tổ chức</div>
                                            <div>
                                                <table class="table-info-enterprise">
                                                    <tr>
                                                        <td>Tên doanh nghiệp</td>
                                                        <td>{{enterpriseDetail?.tenToChuc}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Tên viết tắt</td>
                                                        <td>{{enterpriseDetail?.tenRutGon}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Mã số thuế</td>
                                                        <td>{{enterpriseDetail?.maSoThue}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Giấy phép kinh doanh</td>
                                                        <td>
                                                            <a class="text-decoration-underline text-primary" style="cursor: pointer;" (click)="actDownloadGPKD(enterpriseDetail.partyId)">
                                                                {{enterpriseDetail?.gpkdFilename}}
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Gói cước</td>
                                                        <td>{{ enterpriseDetail?.packageName }}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                            <div class="sub-title mt-3">Thông tin người đại diện</div>
                                            <div>
                                                <table class="table-info-enterprise">
                                                    <tr>
                                                        <td>Họ và tên</td>
                                                        <td>{{enterpriseDetail?.hoTenDaiDien}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Email</td>
                                                        <td>{{enterpriseDetail?.email}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Số điện thoại</td>
                                                        <td>{{enterpriseDetail?.sdt}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Ngày sinh</td>
                                                        <td>
                                                            {{enterpriseDetail?.ngaySinhDaiDien | date: 'dd/MM/yyyy'}}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>Giới tính</td>
                                                        <td>{{enterpriseDetail?.gioiTinhDaiDien}}</td>
                                                    </tr>
                                                    <tr>
                                                        <td>Địa chỉ</td>
                                                        <td>{{enterpriseDetail?.diachiDaiDien}}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                            <div class="sub-title mt-3">Thông tin tài khoản</div>
                                            <div>
                                                <table class="table-info-enterprise">
                                                    <tr>
                                                        <td>Tên tài khoản</td>
                                                        <td>{{enterpriseDetail?.username}}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                            <div class="sub-title mt-3">Thông tin đăng ký</div>
                                            <div>
                                                <table class="table-info-enterprise">
                                                    <tr>
                                                        <td>Ngày đăng ký</td>
                                                        <td>{{enterpriseDetail?.created}}</td>
                                                    </tr>
                                                </table>
                                            </div>
                                        </div>
                                        <!-- <div class="col-md-6">
                                                <p>Video đã ghi lại</p>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <video class="img-cmnd" controls>
                                                            <source
                                                                src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4"
                                                                type="video/mp4">
                                                            <source
                                                                src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4"
                                                                type="video/ogg">
                                                            Your browser does not support the video tag.
                                                        </video>
                                                    </div>
                                                </div>
                                            </div> -->
                                    </div>
                                    <!-- </form> -->
                                    <!-- <app-mini-spinner *ngIf="loadingFormIsVisiable() | async"></app-mini-spinner> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!--MARK chữ kí sign-server  -->
            <div class="tab-pane fade" id="approved" role="tabpanel" aria-labelledby="approved">
                <app-approve-enterprise></app-approve-enterprise>
            </div>
            <div class="tab-pane fade" id="reject" role="tabpanel" aria-labelledby="reject">
                <app-reject-enterprise></app-reject-enterprise>
            </div>
        </div>
    </div>
</section>


<!-- ====================================
    Chưa có thông tin
==================================== -->
<ng-template #emptyBlock>
    <div class="no-content-list">
        <img
            class="mb-2"
            src="assets/img/icon/icon-no-user-group.png"
            width="100"
        />
        <p class="mb-1">
            Không có dữ liệu.
        </p>
    </div>
</ng-template>

<ng-template #cancelModalContent let-modal>
    <div class="modal-header text-center border-0">
        <h4 class="modal-title" style="margin: 0 auto;" id="modal-basic-title">Từ chối yêu cầu</h4>
        <button type="button" class="btn" style="color: #DADADA" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">
                <span class="material-icons">
                    cancel
                </span>
            </span>
        </button>
    </div>
    <div class="modal-body py-0" style="padding: 20px;">
        <form [formGroup]="form">
            <div class="form-group">
                <label for="reason">Lý do từ chối <span class="text-danger">*</span>:</label>
                <textarea formControlName="reason" id="reason" #reson
                    placeholder="Vui lòng nhập lý do từ chối phê duyệt" name="reson" rows="5"
                    class="form-control"></textarea>
                <app-show-validate-errors [useSubmit]="false"
                    [errorMessages]="validateMsgFormReject.reason" [control]="form.controls.reason"
                    [detail]="{ name: 'reason' }">
                </app-show-validate-errors>
            </div>
        </form>
    </div>
    <div class="modal-footer border-0 text-center">
        <button type="button" class="btn btn-outline-primary" #closebutton
            (click)="modal.close('Close click')">Huỷ</button>
        <button type="button" [disabled]="form.invalid || form.pending" class="btn btn-primary" ngbAutofocus
            (click)="confirmCancel(reson.value)">Xác nhận</button>
        <!-- <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button> -->
    </div>
</ng-template>

<ng-template #callInfo let-modal>
    <div class="modal-header text-center border-0">
        <!-- <h4 class="modal-title" style="margin: 0 auto;" id="modal-basic-title">Từ chối yêu cầu</h4>
            <button type="button" class="btn" style="color: #DADADA" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">
                    <span class="material-icons">
                        cancel
                    </span>
                </span>
            </button> -->
    </div>
    <div class="modal-body py-0" style="padding: 20px;">
        <h2>Đang gọi {{enterpriseDetail?.hoTenDaiDien}}</h2>
        <p>acc:thanhduy02; pass: DaNang2020!</p>
    </div>
    <div class="modal-footer border-0 text-center">
        <!-- <button type="button" class="btn btn-outline-primary" #closebutton (click)="modal.close('Close click')">Huỷ</button>
            <button type="button" [disabled]="form.invalid || form.pending" class="btn btn-primary" ngbAutofocus (click)="confirmCancel(reson.value)">Xác nhận</button> -->
    </div>
</ng-template>

<ng-template #calling let-modal>
    <div class="modal-body">
        <div class="row justify-content-center mb-3">
            <div class="">
                <div class="avater-call-modal"  [ngStyle]="{backgroundImage: 'url(' + ava_img + ')'}" ></div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mb-2 text-center">
                <h4><label>{{ enterpriseDetail?.tenToChuc }}</label></h4>
            </div>
            <div class="col-12 mb-2 text-center">
                <span><i>Đang gọi...</i></span>
            </div>
            <div class="col-12 mb-2 text-center">
                <span class="text-danger">Cuộc gọi sẽ được tự động ghi lại</span>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="">
                <a class="end-call-modal" (click)="actBtnEndCall()">
                    <!-- <img src="assets/img/services/end-call.png"> -->
                    <div class="ring">
                        <div class="vdc-alo-phone vdc-alo-green vdc-alo-show">
                            <div class="vdc-alo-ph-circle"></div>
                            <div class="vdc-alo-ph-circle-fill"></div>
                            <div class="vdc-alo-ph-img-circle"></div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</ng-template>

<app-preview-modal *ngIf="showPreviewGPKD" [file]="fileGPKD" [name]="enterpriseDetail.gpkdFilename" (close)="handleClosePreview()"></app-preview-modal>