import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { map } from 'rxjs/operators';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { localize } from 'src/app/utils/localize/localize';
import { DocumentSignatures } from 'src/app/utils/models/aas-models/verify-signatures/document-signatures.model';
import { FileItem } from 'src/app/utils/models/file/file.model';
import { UploadFileResponse } from 'src/app/utils/models/file/fileupload-response/fileupload-response.modet';
import { SignatureService } from 'src/app/utils/services/aas-network/signature.service';

@Component({
  selector: 'app-verify-signature',
  templateUrl: './verify-signature.component.html',
  styleUrls: ['./verify-signature.component.scss']
})
export class VerifySignatureComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;

  localize = localize;
  public img_prefix = "data:image/png;base64,";

  arrFile: FileItem[] = [];
  typeFile = [
    'application/pdf'
  ];
  fileName: string = "";

  isVerifyAction = false;
  isCompared = false;
  signatureok = false;

  fileInfo: DocumentSignatures = new DocumentSignatures();
  fileUploaded: any;

  constructor(
    private signatureService: SignatureService,
    private myAlert: AlertControl
  ) { }

  ngOnInit(): void {
  }

  async uploadFile(event) {
    if (!event || event == null || event == undefined) return;

    this.resetData();

    for (var i = 0; i < event.length; i++) {
      const fileItem = event[i];
      let item = {
        name: fileItem.name,
        size: fileItem.size,
        type: fileItem.type,
      }
      this.fileName = fileItem.name;
      this.arrFile = [];
      this.arrFile.push(new FileItem(item));
      this.fileUploaded = fileItem;
      let end_index = this.arrFile.length - 1;
      console.log(this.arrFile);

      if (!this.typeFile.includes(fileItem.type)) { //Khong dung dinh dang
        this.arrFile[end_index].error = localize.not_allow_file_type;
        return;
      } else {
        let formData = new FormData();
        formData.append("attachFile", fileItem, fileItem.name);

        this.signatureService.postVerifySignatureL1(formData)
          .pipe(map((event) => {
            switch (event.type) {
              case HttpEventType.UploadProgress:
                const percentDone = Math.round(100 * event.loaded / event.total);
                this.arrFile[end_index].process = percentDone;
              case HttpEventType.Response:
                return event;
            }
          }))
          .subscribe((res: any) => {
            if (res instanceof HttpResponse) {
              console.log(res);
              this.fileInfo = new DocumentSignatures(res.body.object || {});
            }
          }, (err) => {
            // this.arrFile[end_index].error = "Tải lên không thành công!";
            this.myAlert.showErrorHandled(err)
          })
      }
    }
  }

  removeFile(i) {
    this.arrFile = [];
    this.fileInput.nativeElement.value = "";
    this.isVerifyAction = false;
  }

  resetData() {
    this.arrFile = [];
    this.fileName = "";
    this.isVerifyAction = false;
    this.signatureok = false;
    this.isVerifyAction = false;
    this.fileInfo = new DocumentSignatures();
    this.isCompared = false;
  }

  verify() {
    if (this.fileInfo.kySo.length > 0 || this.fileInfo.kyDienTu.length > 0)
      this.isVerifyAction = true;
  }

  compare() {
    this.verifyLv2();
  }

  verifyLv2() {
    let end_index = this.arrFile.length - 1;
    if (end_index < 0) return;

    let formData = new FormData();
    formData.append("attachFile", this.fileUploaded, this.fileName);

    this.signatureService.postVerifySignatureL2(formData).subscribe((res: any) => {
      console.log(res);
      this.fileInfo = new DocumentSignatures(res.object || {});
      this.isCompared = true;
    }, err => {
      // this.myAlert.showErrorHandled(err)
    })
  }

  expand(type: string = "", index) {
    if (type == "DSIGN") {
      this.fileInfo.kySo[index].expanded = !this.fileInfo.kySo[index].expanded
    } else if (type == "ESIGN") {
      this.fileInfo.kyDienTu[index].expanded = !this.fileInfo.kyDienTu[index].expanded
    }
 }
}
