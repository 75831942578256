import { ErrorService } from './../services/error/error.service';
import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoadingService } from '../services/loading.service';
import { map, timeout, catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { RequestErrorType } from 'src/app/layout/extensions/request-error/request-error.component';
import { ConstantAlertMsg } from '../common/constant-alert-msg';

const TIME_OUT = 120000;

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
    private requests: HttpRequest<unknown>[] = [];
    constructor(
        private loaderService: LoadingService,
        public router: Router,
        private errorService: ErrorService) { }

    removeRequest(req: HttpRequest<unknown>) {
        const i = this.requests.indexOf(req);
        if (i >= 0) {
            this.requests.splice(i, 1); // This removes the request from our array
        }
        // Let's tell our service of the updated status
        this.loaderService.setVisible(this.requests.length > 0);
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.requests.push(req);
        this.loaderService.setVisible(true);
        // We create a new observable which we return instead of the original
        return new Observable((observer) => {
            // And subscribe to the original observable to ensure the HttpRequest is made
            const subscription = next.handle(req)
                .pipe(
                    timeout(TIME_OUT),
                    map((event: HttpEvent<any>) => {
                        if (event instanceof HttpResponse) {
                            // finihsed request
                        } else {
                            // loading
                        }
                        observer.next(event);
                    })
                )
                .subscribe((res: any) => {
                    // if (res instanceof HttpResponse) {
                    //     observer.next(res);
                    // }
                }, err => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.error && ((err.error.message && err.error.message === "ECT-00001110") || err.error.error === 'invalid_token')) {
                            this.errorService.setVisible({
                                type: RequestErrorType.TOKEN_TIME_OUT,
                                message: ConstantAlertMsg.TOKEN_TIMEOUT
                            });
                        } else {
                            observer.error(err);
                        }
                    } else if (err && err.name === "TimeoutError") {
                        console.log(this.requests);
                        if (this.requests.length === 1) {
                            this.errorService.setVisible({
                                type: RequestErrorType.REQUEST_TIME_OUT,
                                message: ConstantAlertMsg.REQUEST_TIMEOUT
                            });
                        }
                    } 
                    this.removeRequest(req);
                }, () => {
                    this.removeRequest(req); observer.complete();
                });
            // return teardown logic in case of cancelled requests
            return () => {
                this.removeRequest(req);
                subscription.unsubscribe();
            };
        });
    }
}
