<div class="landingpage">
    <app-home-header (changedMode)="changedMode($event)" (changedLanguage)="changedLanguage($event)"></app-home-header>
    <div class="site-overlay"></div>
    <div>
        <router-outlet></router-outlet>
    </div>

    <section class="section-11">
        <div class="container">
            <app-home-footer [data]="footerData"></app-home-footer>
        </div>
        <div class="copy-right">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <p class="Reserved">© VNPT-IT 2020. All Rights Reserved.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>