<footer>
    <div class="">
        <div class="row">
            <div class="col-md-12 col-lg-7">
                <div class="row">
                    <div class="col-md-6 col-6 col-lg-7">
                        <div class="row">
                            <div class="left">
                                <img src="assets/img/home/v4/Image/vnpt_logo.png" alt=""
                                    class="logo-footer">
                                <div class="right-address">
                                    <div>
                                        <img src="assets/img/home/v4/Image/location.svg"
                                            alt="">
                                        <!-- <span>57 Huỳnh Thúc Kháng, Đống Đa, Hà Nội</span> -->
                                        <span>{{ data?.description }}</span>
                                    </div>
                                    <div>
                                        <img src="assets/img/home/v4/icon/call icon.png" alt="">
                                        <span>Hotline: 18001260</span>
                                    </div>
                                    <div>
                                        <img src="assets/img/home/v4/icon/message_icon.png" alt="">
                                        <span>econtract@vnpt.vn</span>
                                    </div>
                                    <div>
                                        <img src="assets/img/home/v4/icon/internet_icon.png"
                                            alt="">
                                        <span>https://vnpt.vn</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-5 col-6 col-md-6">
                        <div class="row">
                            <div class="col-footer">
                                <span class="title">{{ 'policy' | translate }}</span>
                                <div>
                                    <span>{{ 'shopping_guide' | translate }}</span>
                                </div>
                                <div>
                                    <span>{{ 'trading_conditions' | translate }}</span>
                                </div>
                                <div>
                                    <span>{{ 'terms_of_use' | translate }}</span>
                                </div>
                                <div>
                                    <span>{{ 'payment_methods' | translate }}</span>
                                </div>
                                <div>
                                    <span>{{ 'privacy_policy' | translate }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="right">
                    <div class="row">
                        <div class="col-lg-6 col-6">
                            <div class="row">
                                <div class="col-footer">
                                    <span class="title">{{ 'download' | translate }}</span>
                                    <div>
                                        <a href="{{ data?.androidLink }}">
                                            <img src="assets/img/home/v4/Image/Google Play Badge.png"
                                                alt="">
                                        </a>

                                    </div>
                                    <div>
                                        <a href="{{ data?.iosLink }}">
                                            <img src="assets/img/home/v4/Image/appstort.png" alt=""
                                                style="margin-top: 5px;">
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-6">
                            <div class="row">
                                <div class="col-footer">
                                    <span class="title">{{ 'media' | translate }}</span>
                                    <div class="list-img">
                                        <img src="assets/img/home/v4/Image/Social Icons.png"
                                            alt="">
                                        <img src="assets/img/home/v4/Image/Social Icons (2).png"
                                            alt="">
                                        <img src="assets/img/home/v4/Image/Social Icons (1).png"
                                            alt="">
                                    </div>
                                    <div>
                                        <img src="assets/img/home/v4/Image/image 117.png" class="bct" alt=""
                                            style="margin-top: 12px;">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>