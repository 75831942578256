import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { localize } from 'src/app/utils/localize/localize';
import { ServiceCatalogItem } from 'src/app/utils/models/aas-models/service-catalog/service-catalog-list';
import { PackageServiceService } from 'src/app/utils/services/package-service/package-service.service';

@Component({
  selector: 'app-catalog-service-list',
  templateUrl: './catalog-service-list.component.html',
  styleUrls: ['./catalog-service-list.component.scss']
})
export class CatalogServiceListComponent implements OnInit {
  localize = localize;

  statusList = [
    { value: "", label: localize.all },
    { value: "ENABLED", label: localize.active },
    { value: "DISABLED", label: localize.deactive}
  ];

  serviceType = [
    { value: "", label: localize.all },
    { value: "HATANG", label: localize.infrastructure },
    { value: "CHUCNANG", label: localize.feature}
  ];

  formFilter: FormGroup;
  keySearch: string = "";

  page = 1;
  maxSize = 10;
  totalElement = 0;
  propertiesSort = "created";
  sort = "DESC";

  sortColConfig = {
    name: null,
    serviceType: null,
    status: null,
    created: true
  }

  catalogs = [];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private myAlert: AlertControl,
    private packageApi: PackageServiceService
  ) { }

  ngOnInit(): void {
    this.makeForm();
    this.loadPage(1);
  }

  makeForm() {
    this.formFilter = this.fb.group({
      status: [''],
      serviceType: ['']
    })
  }

  enterSearch() {
    this.loadPage(1);
  }

  cleanFilter() {
    this.formFilter.reset();
    this.formFilter.controls.status.setValue("");
    this.formFilter.controls.serviceType.setValue("");
    this.keySearch = "";
    this.loadPage(1);
  }

  searchWithFilter() {
    this.loadPage(1);
  }

  loadPage(page) {
    this.page = page;
    this.getListCatalogService(this.page, this.maxSize, this.getPropertiesSort(), "DESC", this.formFilter.value.status || "", this.formFilter.value.serviceType || "", this.keySearch);
  }

  sortCol(key) {
    if (this.sortColConfig[key] == null) {
      this.sortColConfig[key] = true;
    } else if (this.sortColConfig[key] == false) {
      this.sortColConfig[key] = null;
    } else {
      this.sortColConfig[key] = !this.sortColConfig[key];
    }

    this.loadPage(this.page);
  }

  getPropertiesSort() {
    let arr = [];
    Object.keys(this.sortColConfig).forEach((item: string) => {
      let p = this.sortColConfig[item];
      let a = "";
      if (p == true) a = `${item}`;
      if (p == false) a = `-${item}`;
      if (p == null) a = "";
      if (p != null) {
        arr.push(a);
      }
    });
    return arr;
  }

  getIndexStartRecord(page, maxSize, countRecordOfPage) {
    return (page - 1) * maxSize + 1;
  }

  getIndexEndRecord(page, maxSize, countRecordOfPage) {
    return (page - 1) * maxSize + countRecordOfPage;
  }

  getResultStatus() {
    let msg = localize.display_from_to_in_total_result;
    msg = msg.replace('{start}', "" + this.getIndexStartRecord(this.page, this.maxSize, 0));
    msg = msg.replace('{end}', "" + this.getIndexEndRecord(this.page, this.maxSize, this.catalogs.length));
    msg = msg.replace('{total}', "" + this.totalElement);
    return msg;
  }

  addCatalogService() {
    this.router.navigate([ConstantUrl.service_catalog_add]);
  }

  gotoEdit(id) {
    this.router.navigate([ConstantUrl.service_catalog_edit, id]);
  }

  gotoDetail(id) {
    this.router.navigate([ConstantUrl.service_catalog_detail, id])
  }

  delete(id) {
    this.myAlert.showAlert(localize.confirm_delete_service_catalog, "", true, "", localize.btn_delete, () => {
      this.deleteServiceCatalog(id);
    }, "", localize.btn_cancel, null, localize.confirm);
  }

  getListCatalogService(page, maxSize, propertiesSort, sort, status, serviceType, searchString) {
    this.catalogs = [];
    this.packageApi.getListCatalogService(page, maxSize, propertiesSort, sort, status, serviceType, searchString).subscribe((res: any) => {
      if (res.object?.data && res.object?.data instanceof Array) {
        res.object?.data.forEach((item) => {
          this.catalogs.push(new ServiceCatalogItem(item));
        })
      }

      this.totalElement = res.object?.totalElement;
    }, err => {

    })
  }

  deleteServiceCatalog(id) {
    this.packageApi.deleteServiceCalalog(id).subscribe((res: any) => {
      this.myAlert.showAlertOnlyNoti(localize.delete_service_catalog_success, "", "", localize.btn_close);
      this.loadPage(this.page);
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }
}
