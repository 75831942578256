 <!-- VNPT CA -->
 <div *ngIf="!showLayoutConfirmSmartCA" class="">
    <div class="row mt-3">
        <div class="col-md-12">
            <div class="infor-box-header">
                <label class="text-primary flex-grow-1 mb-0">
                    {{'SmartCA_signTypeSmartCA' | translate }}
                </label>
            </div>
            <div class="row mt-3">
                <div class="col-md-6">
                    <div class="eradio-group mb-3" style="width: fit-content;">
                        <input [(ngModel)]="signTypeSmartCA" class="eradio-input" type="radio"
                            id="radio-signTypeSmartCA-hash"
                            name="signTypeSmartCA" [value]="'HASH'">
                        <label class="eradio-label" for="radio-signTypeSmartCA-hash">
                            {{ 'SmartCA_signTypeHash' | translate }}
                        </label>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="eradio-group mb-3" style="width: fit-content;">
                        <input [(ngModel)]="signTypeSmartCA" class="eradio-input" type="radio"
                            id="radio-signTypeSmartCA-file" 
                            name="signTypeSmartCA" [value]="'FILE'">
                        <label class="eradio-label" for="radio-signTypeSmartCA-file">
                            {{ 'SmartCA_signTypeFile' | translate }}
                        </label>
                    </div>
                </div>
            </div>
            <span class="text-grey-darkest"><em>{{ "SmartCA_signTypeNote" | translate }}</em></span>
        </div>
    </div>
    <div class="mt-3">
        <label class="text-primary">{{'SmartCa_signatureList' | translate}}:</label>
        <hr class="mb-3">
        <table id="list-signbox"
            class="list-signature table header-freeze">
            <thead>
                <tr class="bg-tb-header">
                    <th>{{'SmartCa_stt' | translate}}</th>
                    <th class="th_sig_rectangle">{{'SmartCa_thongtintoado' | translate}}</th>
                    <th>{{'SmartCa_signaturePageShow' | translate}}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
            </tbody>
        </table>
        <button *ngIf="contractData.allowAddSignBox" role="button"
            class="btn d-flex align-items-center text-primary px-0"
            (click)="addSignBox()">
            <span class="material-icons">add</span>
            {{'SmartCa_addSignature' | translate}}
        </button>
    </div>

    <div class="row mt-3">
        <form [formGroup]="formSign" class="col-md-12 row">
            <div class="col-12">
                <div class="infor-box-header d-flex">
                    <label class="text-primary flex-grow-1 mb-0">
                        {{'SmartCa_descripSignature' | translate}}
                    </label>
                </div>
                <hr>
                <div class="row">
                    <div class="col-xl-6">
                        <div
                            class="ccheck-primary w-100 d-inline-block">
                            <input (change)="changedDateSign($event)"
                                formControlName="signDate"
                                type="checkbox" id="smartca_signDate">
                            <label for="smartca_signDate" class="w-100">
                                {{'SmartCa_signData' | translate}}
                            </label>
                        </div>
                    </div>
                    <div class="col-xl-6">
                        <div
                            class="ccheck-primary w-100 d-inline-block">
                            <input (change)="changedSignBy($event)"
                                formControlName="signBy" type="checkbox"
                                id="smartca_signBy">
                            <label for="smartca_signBy" class="w-100">
                                {{'SmartCa_signBy' | translate}}
                            </label>
                        </div>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-xl-12 align-items-end d-flex">
                        <div class="form-group w-100">
                            <label>{{'SmartCa_fontSize' | translate}}:</label>
                            <input formControlName="fontSize"
                                (input)="inputFontSize($event)"
                                type="text" class="form-control"
                                placeholder="{{'SmartCa_fontSizePlaceholder' | translate}}">
                            <app-show-validate-errors
                                [isSubmit]="isSubmit"
                                [errorMessages]="validMsg.fontSize"
                                [control]="formSign.controls.fontSize"
                                [detail]="{ name: 'fontSize' }">
                            </app-show-validate-errors>
                        </div>
                    </div>
                </div>


                <div class="infor-box-header d-flex mt-3">
                    <label class="text-primary flex-grow-1 mb-0">
                        {{'SmartCa_displaySignatureType' | translate}}
                    </label>
                </div>
                <hr>
                <div class="row">
                    <div class="col-xl-12">
                        <label for="signType">{{'SmartCa_displaySignatureTypeSelecte' | translate}}:</label>
                        <ng-select 
                            [items]="hinhThucHienThi"
                            bindLabel="name"
                            bindValue="value"
                            [clearable]="false"
                            (change)="selectStyleSignBox($event)"
                            placeholder="{{'SmartCa_displaySignatureTypeSelecte' | translate}}"
                            formControlName="visibleType">
                            <ng-template ng-label-tmp let-item="item">
                                <span>{{ item.name | translate }}</span>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item">
                                <span>{{ item.name | translate }}</span>
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="col-12 mt-3">
                <div class="infor-box-header">
                    <label class="text-primary flex-grow-1 mb-0">
                        {{'SmartCa_imgSignature' | translate}}
                    </label>
                </div>
                <hr>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>{{'SmartCa_fileAttachment' | translate}}:</label>
                            <div class="input-group mb-1">
                                <div
                                    class="custom-file custom-file-icon">
                                    <span
                                        class="icon-file material-icons">attach_file</span>
                                    <label class="custom-file-label"
                                        for="inputFileImage">{{ inputFileImageLabel }}</label>
                                    <input formControlName="imageFile"
                                        (input)="handleFileImageInput($event)"
                                        type="file" accept="image/*"
                                        class="custom-file-input"
                                        id="inputFileImage"
                                        aria-describedby="inputGroupFileAddon01">
                                </div>
                            </div>
                            <app-show-validate-errors
                                [useSubmit]="false"
                                [isSubmit]="isSubmit"
                                [errorMessages]="validMsg.imageFile"
                                [control]="formSign.controls.imageFile"
                                [detail]="{ name: 'imageFile' }">
                            </app-show-validate-errors>
                        </div>
                        <img id="img-smart-ca" [hidden]="imgBase64 == '' || imgBase64 == null || imgBase64 == undefined" class="w-100" style="max-width: 200px;"
                            [src]="imgBase64">
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<div *ngIf="showLayoutConfirmSmartCA" class="">
    <div *ngIf="count > 0" class="ticker-warning mb-3">
        <h5 class="font-weight-bold">{{'SmartCa_verifySmartCA' | translate}}</h5>
        <p >{{'SmartCa_verifySmartCADescrip' | translate}}</p>
        <p>{{'SmartCa_transactionExpireAfter' | translate}}</p>
        <span class="font-weight-bold" style="font-size: 2.5rem;">{{ counter$ | async }}</span>
    </div>
    <div *ngIf="count == 0" class="ticker-danger mb-3">
        <h5 class="font-weight-bold">{{ msgError }}</h5>
        <p class="mb-0">{{'SmartCa_resign_pls' | translate}}</p>
    </div>
    <div class="ticker-primary mb-3">
        <div class="d-flex justify-content-center align-items-center">
            <img src="assets/img/qrcode-app-smart-ca.png" width="80" height="80">
            <p class="mb-0 ml-3">{{'SmartCa_qrcodeSmartCaAppDescrip' | translate}}</p>
        </div>
    </div>
</div>