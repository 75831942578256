<app-spinner></app-spinner>
<div class="wrapper">
    <div class="preview-overlay">

        <div class="preview-container">
            <div class="preview-header d-flex">
                <div (click)="goToBack()" 
                    class="ct-header-btn-back d-flex justify-content-center align-items-center cursor-pointer">
                    <span class="material-icons">close</span>
                </div>
                <div class="ct-title d-flex align-items-start justify-content-center flex-column px-3">
                    <h4 class="mb-0">Duyệt không ký hàng loạt</h4>
                    
                </div>
                <div class="ct-toolbar-btn d-flex align-items-center ml-auto">
                    <div class="d-flex align-items-center">
                        <!-- <button
                            class="btn btn-primary d-flex align-items-center mr-3" placement="left" ngbTooltip="Gửi hợp đồng cho các bên"><span
                            class="material-icons mr-2">send</span> Nút</button> -->
                    </div>
                </div>
            </div>

            <div class="preview-body">
                <div class="pv ct">
                    <div class="pv-body">
                        <div class="ctpv-container">
                            <div class="ctpv-content">
                                <div class="ct-container">
                                    <div class="ct-content ct-enable-scroll">
                                        <div class="ct-doc-loaded">
                                            
                                            <div class="ec-tab-sign-multiple-content">
                                                <div *ngIf="endSignJob != true" class="ec-tab-step">
                                                    <div class="row">
                                                        <div class="col-md-8">
                                                            <div class="bg-white">
                                                                <section class="p-3">
                                                                    <div class="row">
                                                                        <div class="col-md-6">
                                                                            <form [formGroup]="formSearch" (ngSubmit)="actSearch(formSearch.value.search)"
                                                                                class="input-group border rounded mb-3">
                                                                                <div class="input-group-prepend border-0">
                                                                                    <button class="btn d-inline-flex pr-2 pl-2" type="submit"><span
                                                                                            class="material-icons" style="color: #DADADA;">search</span></button>
                                                                                </div>
                                                                                <input type="text" class="form-control border-0"
                                                                                    formControlName="search" placeholder="Tìm theo Tên hợp đồng, loại hợp đồng, tên file" aria-label=""
                                                                                    aria-describedby="basic-addon1">
                                                                            </form>
                                                                        </div>
                                                                        <div class="col-md-auto ml-auto">
                                                                            <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="cleanFilter()"> Đặt lại</button>
                                                                            <button class="btn btn-primary d-inline-flex" (click)="searchWithFilter()"> Áp dụng</button>
                                                                        </div>
                                                                    </div>
                                                                    <hr class="my-2">
                                                                </section>

                                                                <section class="px-3 my-2">
                                                                    <form [formGroup]="formFilter">
                                                                        <div class="row">
                                                                            <div class="col-md-3" >
                                                                                <div class="form-group">
                                                                                    <label for="statusGCS">Loại khách hàng</label>
                                                                                    <select formControlName="customerType" class="form-control">
                                                                                        <option value="">Tất cả</option>
                                                                                        <option value="CONSUMER" >Cá nhân</option>
                                                                                        <option value="BUSINESS">Doanh nghiệp</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-3">
                                                                                <div class="form-group">
                                                                                    <label for="">Từ ngày</label>
                                                                                    <div class="input-group">
                                                                                        <input #fromDatePicker class="form-control" [control]="formFilter.controls.fromDate"
                                                                                            [value]="formFilter.value.fromDate"
                                                                                            (dateSelect)="changeDateFilter($event, dateType.CREATED_FROM)"
                                                                                            placeholder="dd/MM/yyyy" ngbDatepicker #createdFromDate="ngbDatepicker"
                                                                                            [maxDate]="{year: currentDate.getFullYear(), month: currentDate.getMonth()+1, day: currentDate.getDate()}"
                                                                                            appInputMark [options]="optionsCleave">
                                                                                        <div class="input-group-append">
                                                                                            <button class="btn btn-outline-secondary" (click)="createdFromDate.toggle()"
                                                                                                type="button"><em class="fas fa-calendar-alt"></em></button>
                                                                                        </div>
                                                                                        <app-show-validate-errors [useSubmit]="false"
                                                                                            [errorMessages]="validateSearch.fromDate"
                                                                                            [control]="formFilter.controls.fromDate"
                                                                                            [detail]="{ name: 'fromDate' }">
                                                                                        </app-show-validate-errors>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-3">
                                                                                <div class="form-group">
                                                                                    <label for="">Đến ngày</label>
                                                                                    <div class="input-group">
                                                                                        <input #toDatePicker class="form-control" [control]="formFilter.controls.toDate"
                                                                                            [value]="formFilter.value.toDate"
                                                                                            (dateSelect)="changeDateFilter($event, dateType.CREATED_TO)"
                                                                                            placeholder="dd/MM/yyyy" ngbDatepicker #createdToDate="ngbDatepicker"
                                                                                            [maxDate]="{year: currentDate.getFullYear(), month: currentDate.getMonth()+1, day: currentDate.getDate()}"
                                                                                            appInputMark [options]="optionsCleave">
                                                                                        <div class="input-group-append">
                                                                                            <button class="btn btn-outline-secondary" (click)="createdToDate.toggle()"
                                                                                                type="button"><em class="fas fa-calendar-alt"></em></button>
                                                                                        </div>
                                                                                        <app-show-validate-errors [useSubmit]="false"
                                                                                            [errorMessages]="validateSearch.toDate"
                                                                                            [control]="formFilter.controls.toDate"
                                                                                            [detail]="{ name: 'toDate' }">
                                                                                        </app-show-validate-errors>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-3">
                                                                                <div class="form-group">
                                                                                    <label for="status">Trạng thái</label>
                                                                                    <select formControlName="status" class="form-control">
                                                                                        <option value="" selected>Tất cả</option>
                                                                                        <option value="LC_DRAFT_CREATE">Bản nháp</option>
                                                                                        <option value="LC_DRAFT_DISCUSS">Đàm phán (Chưa đồng ý thỏa thuận)</option>
                                                                                        <option value="LC_DRAFT_DEAL">Đàm phán (Đã có bên đồng ý thỏa thuận)</option>
                                                                                        <option value="LC_DRAFT_SUBMIT">Chờ ký</option>
                                                                                        <option value="LC_DRAFT_CANCEL">Đã từ chối</option>
                                                                                        <option value="LC_CONTRACT_VALID">Có hiệu lực</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <!-- Chọn loại hợp đồng -->
                                                                            <!-- <div class="col-md-3">
                                                                                <div class="form-group">
                                                                                    <label>{{ 'ContractTypes_list_title' | translate }}</label>
                                                                                    <ng-select formControlName="typeId" [items]="dropListTypeData | async" bindValue="contractTypeId" bindLabel="name"
                                                                                        [clearable]="false" [trackByFn]="trackByFn1"
                                                                                        [loading]="typesLoading" [typeahead]="typeInput$" (change)="changeType($event)" (scrollToEnd)="onScrollToEnd()">
                                                                                    </ng-select>
                                                                                </div>
                                                                            </div> -->
                                                                        </div>
                                                                    </form>
                                                                </section>
                                                                
                                                                
                                                               
                                                                <section class="card-body p-0">
                                                                    <div class="table-responsive">
                                                                        <table class="table table-sort-col mb-0">
                                                                            <thead>
                                                                                <tr class="bg-tb-header">
                                                                                    <th>
                                                                                        <div class="form-check">
                                                                                            <input class="form-check-input" [(ngModel)]="isCheckAll" type="checkbox" value="" id="contractNoSignAll" (click)="checkAll()">
                                                                                            <label class="form-check-label" for="all">
                                                                                            </label>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th>STT</th>
                                                                                    <th (click)="sortCol('title')">
                                                                                        <span class="d-flex align-self-center align-items-center">
                                                                                            Tên hợp đồng
                                                                                            <span *ngIf="this.sortColConfig['title'] == null" class="material-icons ml-auto">
                                                                                                unfold_more
                                                                                            </span>
                                                                                            <span *ngIf="this.sortColConfig['title'] == true" class="material-icons ml-auto">
                                                                                                expand_less
                                                                                            </span>
                                                                                            <span *ngIf="this.sortColConfig['title'] == false" class="material-icons ml-auto">
                                                                                                expand_more
                                                                                            </span>
                                                                                        </span>
                                                                                    </th>
                                                                                    <th>
                                                                                        <span class="d-flex align-self-center align-items-center">
                                                                                            Số bên tham gia
                                                                                            <!-- <span *ngIf="this.sortColConfig['partnersCount'] == null" class="material-icons ml-auto">
                                                                                                unfold_more
                                                                                            </span>
                                                                                            <span *ngIf="this.sortColConfig['partnersCount'] == true" class="material-icons ml-auto">
                                                                                                expand_less
                                                                                            </span>
                                                                                            <span *ngIf="this.sortColConfig['partnersCount'] == false" class="material-icons ml-auto">
                                                                                                expand_more
                                                                                            </span> -->
                                                                                        </span>
                                                                                    </th>
                                                                                    <th (click)="sortCol('modified')">
                                                                                        <span class="d-flex align-self-center align-items-center" >
                                                                                            Ngày chỉnh sửa
                                                                                            <span *ngIf="this.sortColConfig['modified'] == null" class="material-icons ml-auto">
                                                                                                unfold_more
                                                                                            </span>
                                                                                            <span *ngIf="this.sortColConfig['modified'] == true" class="material-icons ml-auto">
                                                                                                expand_less
                                                                                            </span>
                                                                                            <span *ngIf="this.sortColConfig['modified'] == false" class="material-icons ml-auto">
                                                                                                expand_more
                                                                                            </span>
                                                                                        </span>
                                                                                    </th>
                                                                                    <th (click)="sortCol('currentStage')">
                                                                                        <span class="d-flex align-self-center align-items-center" >
                                                                                            Trạng thái
                                                                                            <span *ngIf="this.sortColConfig['currentStage'] == null" class="material-icons ml-auto">
                                                                                                unfold_more
                                                                                            </span>
                                                                                            <span *ngIf="this.sortColConfig['currentStage'] == true" class="material-icons ml-auto">
                                                                                                expand_less
                                                                                            </span>
                                                                                            <span *ngIf="this.sortColConfig['currentStage'] == false" class="material-icons ml-auto">
                                                                                                expand_more
                                                                                            </span>
                                                                                        </span>
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr *ngFor="let item of listContract; let i = index">
                                                                                    <td>
                                                                                        <div class="form-check">
                                                                                            <input class="form-check-input" [(ngModel)]="item.selected" type="checkbox" value="" id="contractNoSign-{{i}}" (click)="selectRecord(i)">
                                                                                            <label class="form-check-label" for="all">
                                                                                            </label>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        {{ (page - 1)*maxSize + i + 1 }}
                                                                                    </td>
                                                                                    <td>
                                                                                        {{ item?.title }}
                                                                                    </td>
                                                                                    <td>
                                                                                        <span *ngIf="item.totalParty && item.totalParty < 10 && item.totalParty > 0">0</span>{{ item?.totalParty }}
                                                                                    </td>
                                                                                    <td>
                                                                                        {{ item?.modified | date: 'dd/MM/yyyy' }}
                                                                                    </td>
                                                                                    <td>
                                                                                        <span class="badge {{ currentStage[item.currentStage].style || '' }} p-2">{{ (currentStage[item?.currentStage].label || '') | translate }}</span>
                                                                                        <!-- <br><span class="d-block mt-1">{{ item.signerEmail }}</span> -->
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div class="bg-tb-header p-3">
                                                                        <div class="row align-items-center">
                                                                            <span *ngIf="totalElement == 0" class="col-md text-left">Không tìm thấy kết quả</span>
                                                                            <span *ngIf="totalElement > 0" class="col-md text-left">Hiển thị 1 đến {{listContract.length}} trong tổng số
                                                                                {{totalElement}} kết quả</span>
                                                                            <div class="col-md">
                                                                                <div class="d-flex justify-content-end">
                                                                                    <div *ngIf="totalElement > 0" class="d-flex justify-content-end align-items-center">
                                                                                        <span class="mr-2">Số dòng hiển thị:</span>
                                                                                        <ng-select class="no-bg no-border" [(ngModel)]="maxSize" [searchable]="false" [clearable]="false" placeholder="..." (change)="loadData(1)">
                                                                                            <ng-option [value]="10">10</ng-option>
                                                                                            <ng-option [value]="20">20</ng-option>
                                                                                            <ng-option [value]="30">30</ng-option>
                                                                                        </ng-select>
                                                                                    </div>
                                                                                    <div *ngIf="totalElement > maxSize" class="pagination pages no-border-pagination ml-2">
                                                                                        <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="false"
                                                                                            [pageSize]="maxSize" (pageChange)="loadData($event)" [boundaryLinks]="true" aria-label="Default pagination">
                                                                                            <ng-template ngbPaginationFirst>Đầu</ng-template>
                                                                                            <ng-template ngbPaginationLast>Cuối</ng-template>
                                                                                            <ng-template ngbPaginationPrevious>&laquo;</ng-template>
                                                                                            <ng-template ngbPaginationNext>&raquo;</ng-template>
                                                                                            <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                                                                                        </ngb-pagination>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                </section> 
                                                            </div>
                                                        </div>

                                                        <div class="col-md-4">
                                                            <div class="bg-white">
                                                                <div class="block-action p-3">
                                                                    <div class="d-flex align-items-center justify-content-start mb-2">
                                                                        <h5 class="title-block">Chọn hợp đồng cần duyệt không ký hàng loạt</h5>
                                                                    </div>
                                                                    <p class="mt-3">Vui lòng chọn các hợp đồng cần thực hiện duyệt không ký</p>
                                                                    <label class="text-weight-bold mt-3">Số lượng file hợp đồng đã chọn</label>
                                                                    <p>{{ totalRecordSelected}}</p>
                                                                    <!-- <label class="text-weight-bold mt-3">Tổng dung lượng file hợp đồng đã chọn</label>
                                                                    <div class="progress rounded">
                                                                        <div class="progress-bar" role="progressbar" [attr.aria-valuenow]="progressValueNow" aria-valuemin="0" aria-valuemax="100"
                                                                            [style]="{ 'width': progressValueNow + '%'}"
                                                                        ></div>
                                                                    </div>
                                                                    <div class="d-flex align-items-center my-2">
                                                                        <span class="mr-auto">{{ progressValueNow }}%</span>
                                                                        <span class="ml-auto">{{ totalSizeFile }} / {{ maxSizeFile }} MB</span>
                                                                    </div> -->
                                                                    <button [disabled]="totalRecordSelected <= 0" class="btn btn-lg btn-primary w-100 d-flex align-items-center justify-content-center"
                                                                    (click)="approve()">
                                                                        Duyệt hợp đồng
                                                                        <!-- <span class="material-icons ml-3">arrow_forward</span> -->
                                                                    </button>      
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div *ngIf="endSignJob == true" class="row">
                                                    <div class="col-md-8">
                                                        <div class="bg-white">
                                                            <div class="p-3">
                                                                <h4><label>Danh sách hợp đồng ký hàng loạt ({{ approvedTotalElement }})</label></h4>
                                                            </div>
                                                            <section class="card-body p-0">
                                                                <div class="table-responsive">
                                                                    <table class="table table-sort-col mb-0">
                                                                        <thead>
                                                                            <tr class="bg-tb-header">
                                                                                <th>STT</th>
                                                                                <th>Tên hợp đồng</th>
                                                                                <th>Trạng thái xử lý</th>
                                                                                <th>Thao tác</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr *ngFor="let item of listNoSignContracts; let i = index">
                                                                                <td>{{ (approvedPage - 1) * approvedMaxSize + i + 1 }}</td>
                                                                                <td>{{ item?.title }}</td>
                                                                                <td>
                                                                                    <span *ngIf="item.status && item.status == 'SUCCESS'" class="badge badge-success-new">Hợp đồng đã ký</span>
                                                                                    <span *ngIf="item.status && item.status == 'FAILED'" class="badge badge-danger-new">Hợp đồng ký lỗi</span>
                                                                                    <!-- <span *ngIf="item.error == '' && item.signFlag == true" class="badge badge-success-new">Hợp đồng đã ký</span>
                                                                                    <span *ngIf="item.error != '' && item.signFlag == true" class="badge badge-danger-new">Hợp đồng ký lỗi</span> -->
                                                                                </td>
                                                                                <td>
                                                                                    <button *ngIf="item.status && item.status == 'FAILED'" class="btn btn-outline-primary mr-3" (click)="viewErrorDetail(i)">Chi tiết lỗi</button>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                

                                                                <div class="bg-tb-header p-3">
                                                                    <div class="row align-items-center">
                                                                        <span *ngIf="approvedTotalElement == 0" class="col-md">Không tìm thấy kết quả</span>
                                                                        <span *ngIf="approvedTotalElement > 0" class="col-md">Hiển thị 1 đến {{ listNoSignContracts.length }} trong tổng số
                                                                            {{approvedTotalElement}} kết quả</span>
                                                                        <div class="col-md" *ngIf="approvedTotalElement > 0">
                                                                            <div class="d-flex justify-content-end">
                                                                                <div class="d-flex justify-content-end align-items-center">
                                                                                    <span class="mr-2">Số dòng hiển thị:</span>
                                                                                    <ng-select class="no-bg no-border" [(ngModel)]="approvedMaxSize" [searchable]="false"
                                                                                        [clearable]="false" placeholder="..." (change)="getListNoSignContract()">
                                                                                        <ng-option [value]="10">10</ng-option>
                                                                                        <ng-option [value]="20">20</ng-option>
                                                                                        <ng-option [value]="30">30</ng-option>
                                                                                    </ng-select>
                                                                                </div>
                                                                                <div class="pagination pages no-border-pagination ml-2">
                                                                                    <ngb-pagination [collectionSize]="approvedTotalElement" [(page)]="approvedPage" [maxSize]="5"
                                                                                        [rotate]="true" [ellipses]="false" [pageSize]="approvedMaxSize"
                                                                                        (pageChange)="getListNoSignContract()" [boundaryLinks]="true"
                                                                                        aria-label="Default pagination">
                                                                                        <ng-template ngbPaginationFirst>Đầu</ng-template>
                                                                                        <ng-template ngbPaginationLast>Cuối</ng-template>
                                                                                        <ng-template ngbPaginationPrevious>&laquo;</ng-template>
                                                                                        <ng-template ngbPaginationNext>&raquo;</ng-template>
                                                                                        <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                                                                                    </ngb-pagination>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        
                                                            </section> 
                                                        </div>
                                                    </div>

                                                    <div class="col-md-4">
                                                        <div class="bg-white">
                                                            <div *ngIf="endSignJob == true" class="block-action p-3">
                                                                <div class="d-flex align-items-center justify-content-start mb-2">
                                                                    <h5 class="title-block">Kết quả duyệt không ký hàng loạt</h5>
                                                                </div>
                                                                
                                                                <p>Quá trình ký hợp đồng hàng loạt đã hoàn tất!</p>
                                                                <div class="row mt-3">
                                                                    <div class="col-md-4">
                                                                        <label class="text-weight-bold">Tổng số hợp đồng</label>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <label class="text-weight-bold">{{ approvedTotalElement }}</label>
                                                                    </div>
                                                                </div>
                                                                <div class="row mt-2">
                                                                    <div class="col-md-4">
                                                                        <label class="text-weight-bold">Hợp đồng đã ký</label>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <label class="text-weight-bold">{{ approvedSuccessContracts }}</label>
                                                                    </div>
                                                                </div>
                                                                <div class="row mt-2">
                                                                    <div class="col-md-4">
                                                                        <label class="text-weight-bold">Hợp đồng ký lỗi</label>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <label class="text-weight-bold">{{ approvedErrorContracts }}</label>
                                                                    </div>
                                                                </div>
                                                                <button class="btn btn-lg btn-primary w-100 d-flex align-items-center justify-content-center mt-3" (click)="finish()">
                                                                    Hoàn thành
                                                                </button>      
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>