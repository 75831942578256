import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UserTypeConstant, VerificationType } from 'src/app/utils/common/constant';
import { PartyJoinContract } from 'src/app/utils/models/aas-models/contract/party-join-contract/party-join-contract';
import { InfoPartyObject } from 'src/app/utils/models/aas-models/info-party/info-party-object';
import { Options, Select2AjaxOptions } from 'select2';
import { Select2OptionData } from 'ng-select2';
import { Config } from 'src/app/config';
import { ServicePath } from 'src/app/utils/common/constant-service-api';
import { AuthService } from 'src/app/utils/services/auth.service';
import { stat } from 'fs';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { REGEXP_DATE_INPUT, REGEXP_NUMBER } from 'src/app/utils/common/regexp';
import { ROW_PARTY_JOIN_CONTRACT } from 'src/app/utils/common/constant-vaildate-msg';

declare var $: any;
declare var Cleave: any;
@Component({
  selector: 'app-admin-contract-partyjoin',
  templateUrl: './admin-contract-partyjoin.component.html',
  styleUrls: ['./admin-contract-partyjoin.component.scss']
})
export class AdminContractPartyjoinComponent implements OnInit, AfterViewInit {
  @Input('partyInfo') partyInfo: InfoPartyObject = new InfoPartyObject();
  @Input('partiesJoin') partiesJoin: PartyJoinContract[] = [];
  @Input('partiesFormArray') partiesFormArray: FormArray;
  @Input('partyType') partyType: string = "";

  @Output('changed') changed: EventEmitter<any> = new EventEmitter<{}>();
  @Output('closed') closed: EventEmitter<any> = new EventEmitter<{}>();

  @ViewChild('pickParty') pickParty: HTMLElement;

  public hinhThucKyData: Array<Select2OptionData> = [];
  public optionsHinhThucKy: Options;

  public nguoiNhanData: Array<Select2OptionData> = [];
  public optionsTemplate: Options;
  public ajaxOptions: Select2AjaxOptions;

  public options: Options;

  optionsCleave: any;

  hinhThucXacMinh = [
    { id: VerificationType.VIDEO_CALL, text: 'Video Call' },
    { id: VerificationType.NONE, text: 'Không xác minh' }
  ]
  userTypeData = [
    { id: UserTypeConstant.CONSUMER, text: 'Cá nhân' },
    { id: UserTypeConstant.BUSINESS, text: 'Doanh nghiệp' },
    { id: UserTypeConstant.ENTERPRISE, text: 'Doanh nghiệp GP' }
  ]
  hinhThucKy_consumer = [
    { id: 'OTP', text: 'OTP' },
    // { id: 'ONE_TIME_CA', text: 'Ký số 1 lần' },
    { id: 'EKYC', text: 'OTP và eKYC' }
  ];
  hinhThucKy_business = [
    { id: 'USB_TOKEN', text: 'Usb-Token' },
    { id: 'SIGN_SERVER', text: 'VNPT ký số' }
  ]

  parties: PartyJoinContract[] = [];
  selectedParty: Select2OptionData;

  userTable: FormGroup;
  control: FormArray;
  validateTable = ROW_PARTY_JOIN_CONTRACT;

  isSubmit: boolean = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.makeSelect2HinhThucKy();
    this.makeSelect2PartyJoin();
    this.makeSelect2Default();
    this.makeCleave();

    this.createTableForm();

    this.setData();
    this.setDataFormArray();
  }

  ngAfterOnInit() {
  }

  ngAfterViewInit(): void {
  }

  /**
   * Gan du lieu 
   */
  setData() {
    this.parties = this.partiesJoin;
    this.parties.forEach((item) => {
      item.nguoiNhanData = [
        {
          id: item.partyId,
          text: item.email,
          additional: {
            partyId: item.partyId,
            type_customer: item.userType === 'CONSUMER' ? 'Cá nhân' : 'Doanh nghiệp',
            type_customer_label: item.userType,
            email: item.email,
            tenToChuc: item.tenToChuc,
            tenDaiDien: item.tenDaiDien,
            isTrustedPartner: item.isTrustedPartner
          }
        }
      ]
    })
  }

  /**
   * Setup Cleave cho date input
   */
  makeCleave() {
    this.optionsCleave = {
      date: true,
      delimiter: '/',
      datePattern: ['d', 'm', 'Y'],
    }
  }

  /**
   * Cai dat select2 default
   */
  makeSelect2Default() {
    this.options = {
      minimumResultsForSearch: Infinity,
      dropdownAutoWidth: true,
      containerCssClass: 'select2-customer-party-selector',
      width: '100%',
      multiple: false,
      closeOnSelect: true,
      language: "vi"
    }
  }

  /**
   * Cai dat select2 cho hinh thuc ky
   */
  makeSelect2HinhThucKy() {
    this.optionsHinhThucKy = {
      dropdownAutoWidth: true,
      width: '100%',
      containerCssClass: 'select2-customer-party-selector',
      multiple: true,
      closeOnSelect: true,
      language: "vi"
    }
  }

  /**
   * Cai dat select2 cho truong chon ben tham gia
   */
  makeSelect2PartyJoin() {
    const self = this;
    this.ajaxOptions = {
      url: Config.API_URL + ServicePath.MANAGEMENT_SERVICE + '/parties/customer-search-all?partyType=' + self.partyType,
      dataType: 'json',
      type: "GET",
      delay: 250,
      cache: false,
      data: (params: any) => {
        console.log("AA", params);
        return {
          string: params.term
        }
      },
      // processData: false,
      beforeSend: function (xhr) {
        xhr.setRequestHeader('Authorization', `Bearer ${self.authService.getToken()}`);
      },
      processResults: (data: any, params: any) => {
        return {
          results: $.map(data.object, function (item) {
            console.log("item: ", item);

            for(var i = 0; i < self.parties.length; i++) {
              //Loai bo user da duoc chon truoc do
              if (item.partyId === self.parties[i].partyId) return null;
            }

            return {
              id: item.partyId,
              text: item.email,
              additional: {
                partyId: item.partyId,
                email: item.email,
                name: item.tenToChuc && item.tenToChuc !== '' ? item.tenToChuc : item.tenDaiDien || '',
                tenToChuc: item.tenToChuc || '',
                tenDaiDien: item.tenDaiDien || '',
                type_customer: item.partyType === 'CONSUMER' ? 'Cá nhân' : 'Doanh nghiệp',
                type_customer_label: item.partyType,
                sgt: item.maSoThue || item.cmnd || '',
                isTrustedPartner: item.isTrustedPartner || 'N'
              }
            };
          })
        };
      },
    };
    
    this.optionsTemplate = {
      width: '100%',
      dropdownAutoWidth: true,
      templateResult: this.templateResult,
      templateSelection: this.templateSelection,
      containerCssClass: 'select2-customer-party-selector',
      closeOnSelect: true,
      language: "vi",
      tags: true,
      createTag: function (params) {
        for(var i = 0; i < self.parties.length; i++) {
          //Tag da duoc tao truoc do thi tra ve null
          console.log(params.term, '-', self.parties[i].partyId, '-', self.parties[i].email)
          if (params.term === self.parties[i].partyId || params.term === self.parties[i].email) return null;
        }

        return {
          id: params.term,
          text: params.term,
          additional: {
            tenDaiDien: params.term,
            tenToChuc: params.terms,
            isTrustedPartner: 'N'
          },
          newOption: false
        }
      },

      ajax: this.ajaxOptions
    };
  }

  // function for result template
  public templateResult = (state: Select2OptionData): any => {
    if (!state.id || !state.additional) {
      return state.text;
    }

    var name_el = state.additional.name ? '  <span class="select-name-cutomner">' + state.additional.name + '</span><br>' : '';
    var customer_type_el = state.additional.type_customer ? '  <span class="badge badge-light-new p-2">' + state.additional.type_customer + '</span>' : '';
    var sgt_el = state.additional.sgt ? '  <span class="badge badge-light-new p-2">' + state.additional.sgt + '</span>' : '';
    let el =
      '<div class="">' +
      ' <div class="select-customer">' +
      '  <span>' + state.text + '</span><br>' +
      name_el +
      customer_type_el +
      sgt_el +
      '  <hr  class="mt-2 mb-0" >' +
      ' </div>' +
      '</div>';

    return $(el);
  }

  // function for selection template
  public templateSelection = (state: Select2OptionData): any => {
    if (!state.id) {
      return state.text;
    }

    this.selectedParty = state;

    var _class = state.additional?.isTrustedPartner === 'Y' ? 'select-tag select-tag-primary' : '';

    return $('<span class="' + _class + ' select2-custom-label-selected">' + state.text + '</span>');
  }

  changePartySelect2($event, i) {
    console.log(this.selectedParty);
    this.parties[i].email = this.selectedParty.additional.email;
    this.parties[i].tenToChuc = this.selectedParty.additional.tenToChuc;
    this.parties[i].tenDaiDien = this.selectedParty.additional.tenDaiDien;
    this.parties[i].userType = this.selectedParty.additional.type_customer_label;

    //Neu da doi tac da co userType thi khong cho chinh sua
    if (this.parties[i].userType) {
      this.parties[i].allowEditUserType = false;
    }
    
    this.parties[i].changedHinhThucKy(this.parties[i].userType);
  }

  changeUserType($event, i) {
    var value = $event;
    console.log(value);
    this.parties[i].signForm = '';
    this.parties[i].changedHinhThucKy(value);
  }

  onChangedDate($event, i) {
    //   this.parties[i].endDate = $event.target.value || '';
  }

  addRowPartyJoin() {
    this.parties.push(new PartyJoinContract({
    }))
  }

  close() {
    this.closed.emit(null);
  }

  save() {
    console.log(this.parties);
    this.changed.emit(this.parties);
  }

  //MARK: USING TABLE
  /**
   * Gan du lieu 
   */
  setDataFormArray() {
    var control = this.userTable.get('tableRows') as FormArray;
    control.setValue([]);
    this.partiesFormArray.controls.forEach((item) => {
      const item_value = item.value;
      var party: FormGroup;

      if (item.value.partyId === this.partyInfo.partyId) {
        party = this.initFormForOwner();
      } else {
        party = this.initForm();
      }

      party.patchValue(item.value);
      party.patchValue({
        nguoiNhanData: [
          {
            id: item_value.partyId,
            text: item_value.email,
            additional: {
              partyId: item_value.partyId,
              type_customer: item_value.userType === 'CONSUMER' ? 'Cá nhân' : 'Doanh nghiệp',
              type_customer_label: item_value.userType,
              email: item_value.email,
              tenToChuc: item_value.tenToChuc,
              tenDaiDien: item_value.tenDaiDien,
              isTrustedPartner: item_value.isTrustedPartner
            }
          }
        ]
      });

      control.push(party);
    });
  }

  /**
   * Tao Form cho table
   */
  createTableForm() {
    this.userTable = this.fb.group({
      tableRows: this.fb.array([])
    });
    this.control = this.userTable.get('tableRows') as FormArray;
  }

  get getFormControls() {
    const control = this.userTable.get('tableRows') as FormArray;
    return control;
  }

  fromGroup(i): FormGroup {
    const control = this.userTable.get('tableRows') as FormArray;
    return control.at(i) as FormGroup;
  }

  /**
   * Khoi toi FormGroup cho 1 dong
   */
  initForm(): FormGroup {
    return this.fb.group({
      partyId: ['', [Validators.required]],
      tenToChuc: [''],
      email: [''],
      userType: ['', [Validators.required]],
      verificationType: ['', [Validators.required]],
      signForm: ['', [Validators.required]],
      username: [''],
      tenDaiDien: [''],
      endDate: ['', [Validators.pattern(REGEXP_DATE_INPUT)]],
      isTrustedPartner: [false],
      allowDetete: [true],
      allowEdit: [true],
      allowEditUserType: [true],
      allowEditPartyId: [true],
      isOwner: [false],
      sequence: ['', [Validators.required, Validators.pattern(REGEXP_NUMBER)]],

      hinhThucKyData: [[]],
      nguoiNhanData: [[]]
    })
  }

  /**
   * Init FormGroup
   */
  initFormForOwner(): FormGroup {
    return this.fb.group({
      partyId: [''],
      tenToChuc: [''],
      email: [''],
      userType: [''],
      verificationType: [''],
      signForm: [''],
      username: [''],
      tenDaiDien: [''],
      endDate: [''],
      isTrustedPartner: [false],
      allowDetete: [true],
      allowEdit: [true],
      allowEditUserType: [true],
      allowEditPartyId: [true],
      isOwner: [false],
      sequence: ['', [Validators.required]],

      hinhThucKyData: [[]],
      nguoiNhanData: [[]]
    })
  }

  /**
   * Them dong
   */
  addRow() {
    const control =  this.userTable.get('tableRows') as FormArray;
    var partner = this.initForm();
    partner.controls.sequence.setValue(control.length + 1);
    control.push(partner);
  }

  /**
   * Xoa dong
   * @param index 
   */
  deleteRow(index: number) {
    const control =  this.userTable.get('tableRows') as FormArray;
    control.removeAt(index);
  }

  /**
   * Thay doi loai khach hang
   * @param $event 
   * @param group 
   */
  changeUserTypeForm($event, group: FormGroup) {
    var value = $event;
    group.controls.signForm.reset();
    console.log(group.controls.signForm);
    group.controls.hinhThucKyData.setValue(this.changedHinhThucKy(value));
  }

  /**
   * Thay doi hinh thuc ky
   * @param value 
   */
  changedHinhThucKy(value) {
    if (value === 'CONSUMER') {
        return [
          { id: 'OTP', text: 'OTP' },
          // { id: 'ONE_TIME_CA', text: 'Ký số 1 lần' },
          { id: 'EKYC', text: 'OTP và eKYC' }
       ];
    } else {
        return [
          { id: 'USB_TOKEN', text: 'Usb-Token' },
          { id: 'SIGN_SERVER', text: 'VNPT ký số' }
        ]
    }
  }

  /**
   * Bat su kien thay doi ben tham gia hop dong
   * @param $event 
   * @param i 
   */
  changePartySelect2Form($event, i) {
    if ($event) {
      const control =  this.userTable.get('tableRows') as FormArray;
      var group = control.at(i) as FormGroup;
      group.controls.email.setValue(this.selectedParty.additional.email);
      group.controls.tenToChuc.setValue(this.selectedParty.additional.tenToChuc);
      group.controls.tenDaiDien.setValue(this.selectedParty.additional.tenDaiDien);
      group.controls.userType.setValue(this.selectedParty.additional.type_customer_label);
  
      // //Neu da doi tac da co userType thi khong cho chinh sua
      if (group.controls.userType.value) {
        group.controls.allowEditUserType.setValue(false);
      } else {
        group.controls.allowEditUserType.setValue(true);
      }
  
      group.controls.hinhThucKyData.setValue(this.changedHinhThucKy(group.value.userType));
    } else {
      group.controls.allowEditUserType.setValue(true);
    }
  }

  get validRequired() {
    const control =  this.userTable.get('tableRows') as FormArray;
    for (var i = 0; i < control.length; i++) {
      const x = control.at(i) as FormGroup;
      if (x.controls.partyId.hasError("required")) return false;
      if (x.controls.userType.hasError("required")) return false;
      if (x.controls.verificationType.hasError("required")) return false;
      if (x.controls.signForm.hasError("required")) return false;
      if (x.controls.sequence.hasError("required")) return false;
    }
    return true;
  }

  get validSequence() {
    const control =  this.userTable.get('tableRows') as FormArray;
    var arrNumber: Array<number> = [];
    var ok = true;
    
    for (var i = 0; i < control.length; i++) {
      var x = control.at(i) as FormGroup;
      if (x.value.sequence !== null && x.value.sequence !== "") {
        let sub_ok = true;
        let n = +x.value.sequence;

        if (arrNumber.includes(n)) sub_ok = false;
        if (n > control.length || n <= 0) sub_ok = false;
        ok = sub_ok && ok ? true : false ;

        if (!sub_ok) x.controls.sequence.setErrors({ sequenceInValid: true });
        if (sub_ok && !x.controls.sequence.hasError("required") && !x.controls.sequence.hasError("pattern")) x.controls.sequence.setErrors(null);
        
        arrNumber.push(n);
      }
    }

    // console.log(arrNumber);
    return ok;
  }

  /**
   * Check valid after change value
   */
  checkValidSequence() {
    return this.validSequence;
  }

  saveForm() {
    console.log(this.control);
    this.isSubmit = true;
    if (this.validRequired && this.validSequence) {
      this.changed.emit(this.control);
    }
  }
}

