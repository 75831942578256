import { UtilsFunc } from "src/app/utils/common/utils-func";
import { AuthService } from "src/app/utils/services/auth.service";
import { CommentNotePoint } from "./comment-note-point.model";

export class NotePoint {
    page = null;
    x = 0;
    y = 0;
    id = "";
    saved = false;
    contractId = "";
    traceId = "";
    replies = [];
    createdDate = "";
    email = "";
    fullName = "";
    lastUpdate = "";
    orgId = "";
    type = "";
    userId = "";
    value = "";
    comment: CommentNotePoint = null;

    constructor(d = null) {
        d = d || {};
        this.page = d.page || null;
        this.x = d.x || 0;
        this.y = d.y || 0;
        this.id = d.id || UtilsFunc.uuidv4();
        this.saved = d.saved || false;
        this.contractId = d.contractId || "";
        this.traceId = d.traceId || "";
        this.replies = d.replies || d.comments || [];
        if (this.replies instanceof Array) {
            this.replies.forEach((x) => {
                x = new CommentNotePoint(x);
            })
        }
        this.createdDate = d.createdDate || "";
        this.email = d.email || "";
        this.fullName = d.fullName || "";
        this.lastUpdate = d.lastUpdate || "";
        this.orgId = d.orgId || "";
        this.type = d.type || "";
        this.userId = d.userId || "";
        this.value = d.value || "";
        this.comment = new CommentNotePoint(d.comment || null);
    }

    get isOwnerUser() {
        return this.userId == AuthService.instance.userId;
    }
}