import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  private visible$ = new BehaviorSubject<any>(null);
  private disabled = false;

  constructor() {
  }

  setVisible(value: any) {
    console.log("ErrorService: ", value);
    if (this.disabled == true) return;
    this.visible$.next(value);
  }

  isVisible() {
    return this.visible$.asObservable();
  }

  disable() {
    this.disabled = true;
  }

  enable() {
    this.disabled = false;
  }

}
