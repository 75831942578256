<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-auto">
                <h1 class="m-0 text-dark"><label>{{ 'EnduserPackageDetailV2_title' | translate }}</label></h1>
            </div>
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>

<section class="content">
    <div class="container-fluid">
        <div class="content-right-sidebar tab-bar-custom">
            <ul class="nav nav-tabs" id="tabSignAction" role="tablist">
                <li class="nav-item nav-material active">
                    <a class="nav-link d-flex active" id="detail-tab" data-toggle="pill" href="#detail" role="tab"
                        aria-controls="detail" aria-selected="true">
                        {{ 'EnduserPackageDetailV2_tabDetail' | translate }}
                    </a>
                </li>
                <li *ngIf="detail?.packageType != 'TRIAL_TYPE'" class="nav-item nav-material">
                    <a class="nav-link d-flex" id="history-tab" data-toggle="pill" href="#history" role="tab"
                        aria-controls="history" aria-selected="false">
                        {{ 'EnduserPackageDetailV2_tabHistory' | translate }}
                    </a>
                </li>
            </ul>
        </div>
        <div class="tab-content contract-tab-body" id="tabSignAction">
            <!--MARK Chi tiết -->
            <div class="tab-pane fade show active" id="detail" role="tabpanel" aria-labelledby="detail">
                <div class="pb-3">
                    <div class="card card-body">
                        <div class="row">
                            <div class="col-md-8">
                                <label class="text-primary">{{ 'EnduserPackageDetailV2_generalInfor' | translate }}</label>
                                <hr>
                                <div class="e-block-field row">
                                    <div class="col-md-5 col-xl-4">
                                        <label class="e-lb-field">{{ 'EnduserPackageDetailV2_packageName' | translate }}</label>
                                    </div>
                                    <div class="col-md-7">
                                        <span class=""> {{detail?.name}} </span>
                                    </div>
                                </div>
                                <div class="e-block-field row">
                                    <div class="col-md-5 col-xl-4">
                                        <label class="e-lb-field">{{ 'EnduserPackageDetailV2_desc' | translate }}</label>
                                    </div>
                                    <div class="col-md-7">
                                        <span class=""> {{ detail?.description }} </span>
                                    </div>
                                </div>
                                <div class="e-block-field row">
                                    <div class="col-md-5 col-xl-4">
                                        <label class="e-lb-field">{{ 'EnduserPackageDetailV2_duration' | translate }}</label>
                                    </div>
                                    <div class="col-md-7">
                                        <span *ngIf="detail?.packageType == 'NORMAL_TYPE'" class="">{{ 'EnduserPackageDetailV2_forever' | translate }}</span>
                                        <span *ngIf="detail?.packageType != 'NORMAL_TYPE'" class="">
                                            {{ detail?.duration }} {{ 'EnduserPackageDetailV2_month' | translate }} </span>
                                    </div>
                                </div>
                                <div class="e-block-field row">
                                    <div class="col-md-5 col-xl-4">
                                        <label class="e-lb-field">{{ 'EnduserPackageDetailV2_price' | translate }}</label>
                                    </div>
                                    <div class="col-md-7">
                                        <span class=""> {{ detail?.price | number }} {{ 'EnduserPackageDetailV2_vnd' | translate }} </span>
                                    </div>
                                </div>
                                <div class="e-block-field row">
                                    <div class="col-md-5 col-xl-4">
                                        <label class="e-lb-field">{{ 'EnduserPackageDetailV2_status' | translate }}</label>
                                    </div>
                                    <div class="col-md-7">
                                        <span class="badge {{ getStyleAndValueBadge(detail?.status)[0] }} p-2">
                                            {{ getStyleAndValueBadge(detail?.status)[1] }}
                                        </span>
                                    </div>
                                </div>

                                <label class="text-primary">{{ 'EnduserPackageDetailV2_infraInfor' | translate }}</label>
                                <hr>
                                <div class="table-responsive" style="max-width: 600px"
                                    *ngIf="listPackageInfra && listPackageInfra.length > 0">
                                    <table class="table table-sort-col mb-0">
                                        <thead>
                                            <tr class="table-header">
                                                <th>{{ 'EnduserPackageDetailV2_stt' | translate }}</th>
                                                <th>
                                                    <span class="d-flex align-self-center align-items-center"
                                                        (click)="sortCol('thongSo')">
                                                        {{ 'EnduserPackageDetailV2_spec' | translate }}
                                                        <span *ngIf="this.sortColConfig['thongSo'] == null"
                                                            class="material-icons ml-auto">
                                                            unfold_more
                                                        </span>
                                                        <span *ngIf="this.sortColConfig['thongSo'] == true"
                                                            class="material-icons ml-auto">
                                                            expand_less
                                                        </span>
                                                        <span *ngIf="this.sortColConfig['thongSo'] == false"
                                                            class="material-icons ml-auto">
                                                            expand_more
                                                        </span>
                                                    </span>
                                                </th>
                                                <th>
                                                    <span class="d-flex align-self-center align-items-center"
                                                        (click)="sortCol('donViTinh')">
                                                        {{ 'EnduserPackageDetailV2_unit' | translate }}
                                                        <span *ngIf="this.sortColConfig['donViTinh'] == null"
                                                            class="material-icons ml-auto">
                                                            unfold_more
                                                        </span>
                                                        <span *ngIf="this.sortColConfig['donViTinh'] == true"
                                                            class="material-icons ml-auto">
                                                            expand_less
                                                        </span>
                                                        <span *ngIf="this.sortColConfig['donViTinh'] == false"
                                                            class="material-icons ml-auto">
                                                            expand_more
                                                        </span>
                                                    </span>
                                                </th>
                                                <th>
                                                    <span class="d-flex align-self-center align-items-center"
                                                        (click)="sortCol('soLuong')">
                                                        {{ 'EnduserPackageDetailV2_amount' | translate }}
                                                        <span *ngIf="this.sortColConfig['soLuong'] == null"
                                                            class="material-icons ml-auto">
                                                            unfold_more
                                                        </span>
                                                        <span *ngIf="this.sortColConfig['soLuong'] == true"
                                                            class="material-icons ml-auto">
                                                            expand_less
                                                        </span>
                                                        <span *ngIf="this.sortColConfig['soLuong'] == false"
                                                            class="material-icons ml-auto">
                                                            expand_more
                                                        </span>
                                                    </span>
                                                </th>
                                                <th>
                                                    <span class="d-flex align-self-center align-items-center"
                                                        (click)="sortCol('trangThai')">
                                                        {{ 'EnduserPackageDetailV2_usingStatus' | translate }}
                                                        <span *ngIf="this.sortColConfig['trangThai'] == null"
                                                            class="material-icons ml-auto">
                                                            unfold_more
                                                        </span>
                                                        <span *ngIf="this.sortColConfig['trangThai'] == true"
                                                            class="material-icons ml-auto">
                                                            expand_less
                                                        </span>
                                                        <span *ngIf="this.sortColConfig['trangThai'] == false"
                                                            class="material-icons ml-auto">
                                                            expand_more
                                                        </span>
                                                    </span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of listPackageInfra; let i = index">
                                                <td>
                                                    <span *ngIf="i < 9">0</span>{{ i + 1 }}
                                                </td>
                                                <td>
                                                    {{ (componentName + '_' + item?.param + '_' + 'infra_name') | translate }}
                                                </td>
                                                <td>
                                                    {{ (componentName + '_' + item?.param + '_' + 'infra_unit') | translate }}
                                                </td>
                                                <td style="text-align: center;">
                                                    {{ item?.allowed }}
                                                </td>
                                                <td style="text-align: center;">
                                                    {{ item?.used }}/{{ item?.allowed }} <span
                                                        class="{{getStyleOfRate(item?.rate)}}">
                                                        ({{ item?.rate }}%)</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <label class="text-primary pt-4">{{ 'EnduserPackageDetailV2_featureInfor' | translate }}</label>
                                <hr>
                                <div class="row">
                                    <div class="col-md-9">
                                        <div *ngIf="listService.length > 0">
                                            <div *ngFor="let item of listService; let i = index">
                                                <div class="parent pt-1">
                                                    <span class="material-icons expand-icon p-2"
                                                        [style.transform]="item.privilegesHidden ? 'rotate(0deg)' : 'rotate(90deg)'"
                                                        style="cursor: pointer;"
                                                        (click)="toggleService(i)">chevron_right</span>

                                                    <p class="d-inline-block service-label">{{ item?.name }}</p>
                                                </div>
                                                <div *ngIf="!item.privilegesHidden">
                                                    <div *ngFor="let child of item.privileges">
                                                        <div class="children">
                                                            <p class="d-inline-block service-label child-label pt-3">{{
                                                                child?.description }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>
                </div>

            </div>

            <!--MARK Lịch sử mở rộng hạ tầng -->
            <div *ngIf="detail?.packageType != 'TRIAL_TYPE'" class="tab-pane fade show" id="history" role="tabpanel" aria-labelledby="history">
                <div class="pb-3">
                    <div class="card card-body">
                       
                                <div *ngFor="let item of history; let i =index" class="pb-3">
                                    <div class="row">
                                        <div class="col-md-auto ">
                                            <div class="icon-background">
                                                <img class="icon-point"
                                                    src="..\..\..\..\..\..\assets\img\icon\database_upgrade_24px.svg"
                                                    alt="">
                                                <div *ngIf="i + 1 < history.length" class="vertical-line"></div>
                                            </div>

                                        </div>
                                        <div class="col-md-auto">
                                            <label>{{ item?.name }} - <span class="text-primary">{{ formatPrice(item?.price) }} VNĐ</span></label>
                                            <br>
                                            <span>{{ item?.created | date: 'dd/mm/yyyy' }}</span>
                                            <div class="table-responsive mt-3" style="max-width: 600px">
                                                <table class="table infra-table">
                                                    <thead>
                                                        <tr class="table-header">
                                                            <th>{{ 'EnduserPackageDetailV2_stt' | translate }}</th>
                                                            <th>{{ 'EnduserPackageDetailV2_spec' | translate }}</th>
                                                            <th>{{ 'EnduserPackageDetailV2_unit' | translate }}</th>
                                                            <th>{{ 'EnduserPackageDetailV2_amount' | translate }}</th>
                                                            <th>{{ 'EnduserPackageDetailV2_total' | translate }}</th>
                                                        </tr>                                                        
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let infra of item?.infrastructures; let j = index">
                                                            <td><span *ngIf="j < 9">0</span>{{ j + 1 }}</td>
                                                            <td>{{ (componentName + '_' + infra?.param + '_' + 'infra_name') | translate }}</td>
                                                            <td>{{ (componentName + '_' + infra?.param + '_' + 'infra_unit') | translate }}</td>
                                                            <td class="td-number">{{ infra?.unitNumber }}</td>
                                                            <td class="td-number">{{ infra?.total }} </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    </div>
                </div>

            </div>

        </div>


    </div>
</section>