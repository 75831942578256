import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertBtn, ConstantAlertMsg } from 'src/app/utils/common/constant-alert-msg';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { REGEXP_PASSWORD, REGEXP_USERNAME } from 'src/app/utils/common/regexp';
import { localize } from 'src/app/utils/localize/localize';
import { EmployeeService } from 'src/app/utils/services/aas-network/employee/employee.service';

@Component({
  selector: 'app-employee-finish-signup',
  templateUrl: './employee-finish-signup.component.html',
  styleUrls: ['./employee-finish-signup.component.scss']
})
export class EmployeeFinishSignupComponent implements OnInit {

  token = this.activedRoute.snapshot.queryParamMap.get('token');
  email = this.activedRoute.snapshot.queryParamMap.get("email");
  sdt = this.activedRoute.snapshot.queryParamMap.get("phoneNumber");

  localize = localize;
  constantUrl = ConstantUrl;
  form: FormGroup;
  isSubmit = false;

  vatidateForm = {
    email: {
      required: "EmployeeAdd_ts_3"
    },
    sdt: {
      required: "EmployeeAdd_ts_6"
    },
    username: {
      required: "EmployeeEdit_ts_1",
      pattern: "EmployeeEdit_ts_2"
    },
    password: {
      required: "EmployeeFinishSignup_label_4",
      pattern: "EmployeeFinishSignup_label_3"
    }
  }

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activedRoute: ActivatedRoute,
    private employeeService: EmployeeService,
    private myAlert: AlertControl,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.viewDidLoad();
  }

  viewDidLoad() {
    console.log(this.email);
    console.log(this.sdt);
    if (this.email == null || this.email == "" || this.sdt == null || this.sdt == "") this.router.navigate([ConstantUrl.HOME]);
    this.makeForm();
    this.setData();
  }

  makeForm() {
    this.form = this.fb.group({
      "email": ["", [Validators.required]],
      "sdt": ["", [Validators.required]],
      "username": ["", [Validators.required, Validators.pattern(REGEXP_USERNAME)]],
      "password": ["", [Validators.required, Validators.pattern(REGEXP_PASSWORD)]],
    })
  }

  setData() {
    this.form.patchValue({
      email: this.email || "",
      sdt: this.sdt || ""
    })
  }

  signup() {
    this.isSubmit = true;
    if (this.form.valid) {
      let param = {
        email: this.form.value.email,
        phoneNumber: this.form.value.sdt,
        username: this.form.value.username,
        password: this.form.value.password
      }
      this.postFinishSignup(this.token, param);
    }
  }

  postFinishSignup(token, param) {
    this.employeeService.postFinishSignup(token, param).subscribe((res: any) => {
      this.myAlert.showAlertNoClose(this.translate.instant('EmployeeFinishSignup_label_5'), ConstantAlertType.SUCCESS, "", this.translate.instant('btn_login'), () => {
        this.router.navigate([ConstantUrl.LOGIN]);
      })
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }
}
