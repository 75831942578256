import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { BlogCollectionResponse } from 'src/app/utils/models/landing-page/blog-collection-response.model';
import { BlogItem } from 'src/app/utils/models/landing-page/blog-item.model';
import { BlogTypeCollectionResponse } from 'src/app/utils/models/landing-page/blog-type-collection-response.model';
import { BlogTypeItem } from 'src/app/utils/models/landing-page/blog-type-item.model';
import { LandingCategoryItem } from 'src/app/utils/models/landing-page/landing-category-item.model';
import { LandingCategoryCollectionResponse } from 'src/app/utils/models/landing-page/landing-page-category-collection-response.model';
import { LandingpageService } from 'src/app/utils/services/aas-network/landingpage.service';

@Component({
  selector: 'app-service-landing-page-blog',
  templateUrl: './service-landing-page-blog.component.html',
  styleUrls: ['./service-landing-page-blog.component.scss']
})
export class ServiceLandingPageBlogComponent implements OnInit {

  @ViewChild("searchField") searchField: ElementRef;
  
  blogItems: Array<BlogItem> = [];
  categories: Array<LandingCategoryItem> = [];
  blogTypes: Array<BlogTypeItem> = [];

  page: number = 1;
  maxSize: number = 10;
  propertiesSort: string = "modified";
  sort: string = "DESC";
  totalElement: number = 0;  
  //
  keySearch = "";
  categoryId = "";
  blogTypeId = -1;

  saveKeySearch = "";
  userType:string="";
  searchForm: FormGroup;
  filterForm: FormGroup;
  private loading: boolean = false;
  private results ;

  constructor(
    private landingService: LandingpageService,
    private modalService: NgbModal,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    //make form
    this.makeFormSearch();
    this.makeFormFilter();

    //init data
    this.getCategories();
    this.getBlogTypes();
    this.getBlogs();
  }

  makeFormSearch() {
    this.searchForm = this.fb.group({
      search:['', Validators.required]
    })
  }

  makeFormFilter(){
    this.filterForm = this.fb.group({
      category:[''],
      blogType:['-1']
    });
  }

  onSearchFrmSubmit() {        
    if (this.searchForm.valid) {
      this.keySearch = encodeURIComponent(this.searchForm.value.search);
      this.getBlogs(1);
    }else{
      this.keySearch = '';
      this.getBlogs(1);
    }
  }  

  onFilterSubmit() {
    this.categoryId = this.filterForm.value.category || '';
    this.blogTypeId = this.filterForm.value.blogType || -1;
    this.getBlogs(1);
  }

  resetForm() {
    this.searchForm.patchValue({
      'search':''
    });
    this.filterForm.patchValue({
      'category':'',
      'blogType':'-1'
    })
  }

  getBlogs(page = 1){
    this.page = page;
    this.landingService.getBlogs(this.page, this.maxSize, this.keySearch, this.categoryId, this.blogTypeId)
    .subscribe(
      (res) =>{
        let response = new BlogCollectionResponse(res);        
        this.blogItems = response.data;
        this.page = response.page;
        this.totalElement = response.totalElement;
      },
      (err) => {
        this.handleError(err, err);
      }
    )
  }

  getCategories(){
    this.landingService.getCategories()
    .subscribe(
      (res) => {
        let response = new LandingCategoryCollectionResponse(res);
        this.categories = response.data;
      },
      (err) => {
        this.handleError(err, err);
      }
    )
  }

  getBlogTypes(){
    this.landingService.getBlogTypes()
    .subscribe(
      (res) => {
        let response = new BlogTypeCollectionResponse(res);
        this.blogTypes = response.data;
      },
      (err) => {
        this.handleError(err, err);
      }
    )
  }

  onSelectCategory(){
    this.page = 1;
    this.getBlogs();
  }

  onSelectBlogType(){
    this.page = 1;
    this.getBlogs();
  }

  search(keyWord) {
    this.page = 1;
    this.keySearch = keyWord.trim();
    this.getBlogs();
  }

  onDeleteClick(id){
    this.alert(
      "Bạn chắc chắn muốn xóa tin bài này?", 
      "", 
      true, 
      "", 
      "Xác nhận", 
      () => {
        this.deleteBlog(id)
      },
      "",
      "Hủy",
      () => {
        console.log('Người dùng hủy hành động');
      })
  }

  deleteBlog(id){
    this.landingService.deleteBlog(id)
    .subscribe(
      (res) => {
         this.alert("Xóa thành công", "success");
         this.getBlogs();
      },
      (err) => {
        this.handleError(err, err);
      }
    )
  }

  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], 'error');
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, 'error');
    } else {
      this.alert('Lỗi không xác định! Vui lòng thử lại', 'error');
    }
  }

}
