<app-spinner></app-spinner>
<div class="content-header sticky-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1 class="m-0 text-dark font-weight-bold">Xác minh tổ chức</h1>
      </div>
      <!-- /.col -->
      <div class="col-sm-6">
        <!-- <ol class="breadcrumb float-sm-right">
            <li class="breadcrumb-item"><a href="" [routerLink]="['/portal/dashboard']"
                routerLinkActive="active">Dashboard</a></li>
            <li class="breadcrumb-item active">Danh sách hợp đồng</li>
          </ol> -->
      </div>
      <!-- /.col -->
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>

<section class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-7">
        <input #output id="output" class="" value="" type="hidden">
        <input #filebase id="fileBase" class="" type="hidden">
        <input #filename id="fileName" class="" value="{{ fileName }}" type="hidden">
        <div class="sticky-content">
          <div id="ppi" style="height: 1in; width: 1in; position: absolute; top: -100%; left: -100%;"></div>
          <div id="parentView" class="page-viewer enable-scroll if-previewpdf">
          </div>
        </div>
      </div>
      <div class="col-md-5">

        <div class="content-right-sidebar">
          <div id="stepCreate1" class="card shadow-none b-radius-10px">
            <div class="card-body">
              <div class="contract-form-bound">
                <label class="text-primary mb-0">Thông tin giấy phép kinh doanh:</label>
                <hr class="mt-2">
                <form [formGroup]="fileForm">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label>File đính kèm <span class="text-danger">*</span>:</label>
                        <div class="input-group mb-1">
                          <div class="custom-file custom-file-icon">
                            <span class="icon-file material-icons">attach_file</span>
                            <label class="custom-file-label" for="inputGroupFile01">{{ inputFileLabel }}</label>
                            <input formControlName="file" (input)="handleFileInput($event)" type="file" accept=".pdf,.docx"
                              class="custom-file-input" id="inputGroupFile01" aria-describedby="inputGroupFileAddon01">
                          </div>
                        </div>
                        <app-show-validate-errors [isSubmit]="selectedFile" [errorMessages]="validMsg.file" [control]="fileForm.controls.file"
                          [detail]="{ name: 'file' }"></app-show-validate-errors>
                        <p class="text-muted">File đính kèm phải có định dạng .docx hoặc .pdf</p>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div class="d-flex justify-content-end mb-2">
            <button [disabled]="hasSignature || !fileValid" class="btn btn-outline-primary d-inline-flex mr-4" (click)="sign()"><span class="material-icons mr-2">gesture</span>Ký số</button>
            <button [disabled]="!hasSignature || !maSoThueStatus" class="btn btn-primary d-inline-flex" (click)="actBtnComplete()"><span class="material-icons mr-2">done</span>Hoàn thành</button>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>