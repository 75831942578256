import {
    Component,
    OnInit,
    ElementRef,
    ViewChild,
    HostListener,
} from "@angular/core";
import { Router } from "@angular/router";
// import { MessagingService, ApiService } from '../../service';
import { AasConsumerManagerService } from "src/app/utils/services/aas-network/aas-consumer-manager.service";
import { Constant } from "./../../../../../utils/common/constant_videocall";
// import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpClient } from "@angular/common/http";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { NgModalComponent } from "src/app/layout/extensions/ng-modal/ng-modal.component";
import { FormGroup, FormControl, Validators } from "@angular/forms";

declare var JitsiMeetExternalAPI: any;
declare let L;

@Component({
    selector: "app-video-call",
    templateUrl: "./video-call.component.html",
    styleUrls: ["./video-call.component.scss"],
})
export class VideoCallComponent implements OnInit {
    @ViewChild("screenVideoShot", { static: false }) screen: ElementRef;

    domain: string = "https://conference-01.vnpt.vn";
    options: any;
    api: any;
    message;
    params;
    logo: string = "assets/image/logo_acb.svg";
    userDetail: any = {};
    cardImages = [];
    imageFace: string = "";
    imageCapture: string = "";
    imageScreenShot: string = "";
    result_compare = {
        result: "",
        prob: "",
    };
    flag_icon: string = "";
    flag_class: string = "";
    hashImageCapture: any;
    bank = "acb";
    loading: boolean = false;
    isCollapsed: boolean = false;
    base64ScreenShot: string;
    flagCheck = false;
    flagButton: string = "0";
    inputDenyReason: FormGroup;

    @HostListener("window:message", ["$event"])
    onMessage(event) {
        if (event.data.id == "capture") {
            console.log("aaa", event.data.data);
            this.base64ScreenShot = event.data.data;
            if (this.base64ScreenShot)
                this.imageCapture = this.base64ScreenShot;
            const arr = event.data.data.split(",");
            const mime = arr[0].match(/:(.*?);/)[1];
            const bstr = atob(arr[1]);
            let n = bstr.length;
            let u8arr = new Uint8Array(n);

            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            const file = new File([u8arr], "captureScreen", { type: mime });
            this.uploadFile(file);
        }
    }

    constructor(
        // private messagingService: MessagingService,
        private modalService: NgbModal,
        private router: Router,
        private http: HttpClient,
        private aasConsumerManagerService: AasConsumerManagerService
    ) {}

    ngOnInit(): void {
        // if (!localStorage.getItem(Constant.ROOM_INFO))
        // this.router.navigate(['']);
        this.getStatusVideoCall();
        this.getUserDetail();
        this.makeDenyReasonForm();
        // this.flagButton = localStorage.getItem(Constant.APPROVE);
    }

    get reason() {
        return this.inputDenyReason.get("denyReason");
    }

    makeDenyReasonForm() {
        this.inputDenyReason = new FormGroup({
            denyReason: new FormControl(null, [Validators.required]),
        });
    }
    
    getStatusVideoCall() {
        this.aasConsumerManagerService.currentMessage.subscribe((value) => {
            console.log("video", value);
            if (value && value["data"]["title"] === Constant.FINISHED) {
                this.updatePersonInfo();
                this.router.navigate(["/console/register-manager"]);
            }
        });
    }

    getUserDetail() {
        this.userDetail = JSON.parse(
            localStorage.getItem(Constant.USER_DETAIL)
        );
        this.cardImages = JSON.parse(localStorage.getItem(Constant.IMAGE_URL));
        console.log("card", this.cardImages);
        this.imageFace = localStorage.getItem(Constant.IMAGE_FACE);
    }

    ngAfterViewInit(): void {
        // this.initMap();
        this.initVideoCall();
    }

    initMap() {
        var mymap = L.map("streetMap").setView([21.019104, 105.8072043], 20);
        L.tileLayer("https//{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
            minZoom: 12,
            maxZoom: 19,
            attribution:
                '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        }).addTo(mymap);
        L.marker([21.019104, 105.8072043]).addTo(mymap);
    }

    initVideoCall() {
        const roomInfo = JSON.parse(localStorage.getItem(Constant.ROOM_INFO));
        this.params = roomInfo;
        const options = {
            roomName: roomInfo["roomId"] ? roomInfo["roomId"] : "vnptit3",
            width: "100%",
            height: 590,
            jwt: roomInfo["token"] ? roomInfo["token"] : "",
            configOverwrite: { subject: " " }, // set roomName
            userInfo: {
                email: localStorage.getItem(Constant.UUID),
                displayName: this.userDetail.ten,
            },
            parentNode: document.querySelector("#meet"),
        };
        this.api = new JitsiMeetExternalAPI(
            roomInfo["domain"].replace("https://", ""),
            options
        );

        console.log(options);
        // this.postMessageChangeLogo();
        this.api.addEventListener("readyToClose", () => {
            localStorage.removeItem(Constant.ROOM_INFO);
            // this.apiService.removeLocalStorage();
            // call api endcall
            // const body = {
            //     deviceId: localStorage.getItem(Constant.UUID),
            //     idgTokenId: Constant.IDG_TOKEN_ID,
            //     roomId: this.params['roomId'],
            // };
            this.updatePersonInfo();
        });
    }

    postMessageChangeLogo() {
        setTimeout(() => {
            let urlLogo = Constant.ASSET_MSBBANK;
            switch (localStorage.getItem(Constant.BANK)) {
                case Constant.VIETCOMBANK:
                    urlLogo = Constant.ASSET_VIETCOMBANK;
                    break;
                case Constant.SHBBANK:
                    urlLogo = Constant.ASSET_SHBBANK;
                    break;
                case Constant.HDBANK:
                    urlLogo = Constant.ASSET_HDBANK;
                    break;
                case Constant.SCBBANK:
                    urlLogo = Constant.ASSET_SCBBANK;
                    break;
                default:
                    urlLogo = Constant.ASSET_HDBANK;
                    break;
            }
            this.http
                .get(urlLogo, { responseType: "arraybuffer" })
                .subscribe((res) => {
                    let base64String = btoa(
                        String.fromCharCode(...new Uint8Array(res))
                    );
                    const message = {
                        id: "logo",
                        data: "data:image/png;base64," + base64String,
                    };
                    const iframe = this.api.getIFrame();
                    console.log("iframe", iframe);
                    iframe.contentWindow.postMessage(message, "*");
                });
        }, 1000);
    }

    rejectRegistration() {
        this.aasConsumerManagerService
            .rejectRegistration(this.userDetail.userId, {
                rejectReason: this.reason.value,
            })
            .subscribe(
                (res) => {
                    // this.getListUserGroup(
                    //     this.status,
                    //     this.search,
                    //     this.maxSize
                    // );
                    // this.callback();
                    this.alert("Từ chối thành công!", "success");
                    console.log("reject", res);
                },
                (err) => {
                    this.alert("Từ chối không thành công!", "error");
                }
            );
    }

    approveRegistration() {
        console.log("app", this.userDetail);
        this.modal("Bạn có chắc chắn muốn phê duyệt người dùng này?", () => {
            this.aasConsumerManagerService
                .approveRegistration(this.userDetail.userId)
                .subscribe(
                    (res) => {
                        this.alert("Phê duyệt thành công!", "success");
                        console.log("appove", res);
                    },
                    (err) => {
                        this.alert("Phê duyệt không thành công!", "error");
                    }
                );
        });
    }

    updatePersonInfo() {
        const body = {
            hashImageCapture: this.hashImageCapture,
            videoLink: this.params["roomId"],
        };
        console.log("body", body);
        this.aasConsumerManagerService.endCall(body).subscribe(
            (response) => {
                window.close();
            },
            (err) => {
                // this.nzMessage.create(
                //     'error',
                //     'Endcall thất bại!!!'
                // );
                window.close();
            }
        );
        // this.apiService
        //     .updatePersonInfo(this.userDetail['uuidCustomer'], body)
        //     .subscribe(
        //         (res) => {
        //             this.apiService.endCall(body).subscribe(
        //                 (response) => {
        //                     window.close();
        //                 },
        //                 (err) => {
        //                     // this.nzMessage.create(
        //                     //     'error',
        //                     //     'Endcall thất bại!!!'
        //                     // );
        //                     window.close();
        //                 }
        //             );
        //         },
        //         (error) => {
        //             // this.nzMessage.create(
        //             //     'error',
        //             //     'Xảy ra lỗi trong quá trình lưu trữ thông tin!!!'
        //             // );
        //             window.close();
        //         }
        //     );
    }

    approve() {
        // if (!this.hashImageCapture) {
        //     this.alert("Vui  lòng chụp ảnh chân dung khách hàng", "error");
        //     return;
        // }
        this.alert("Xác nhận phê duyệt người dùng này?", "", () => {
            this.approveRegistration();
        });
        // this.apiService
        //     .approve(this.userDetail['uuidCustomer'], {
        //         videoLink: this.params['roomId'],
        //         hashImageCapture: this.hashImageCapture,
        //     })
        //     .subscribe(
        //         (rs) => {
        //             this.flagCheck = true;
        //             // this.nzMessage.create('success', `Phê duyệt thành công!!!`);
        //         },
        //         (error) => {
        //             // this.nzMessage.create('error', `Không thành công!!!`);
        //         }
        //     );
    }

    reject() {
        // if (!this.hashImageCapture) {
        //     this.alert("Vui lòng chụp ảnh chân dung khách hàng", "error");
        //     return;
        // }
        this.rejectRegistration();
        // this.apiService
        //     .refuse(this.userDetail['uuidCustomer'], {
        //         videoLink: this.params['roomId'],
        //         hashImageCapture: this.hashImageCapture,
        //     })
        //     .subscribe(
        //         (rs) => {
        //             this.flagCheck = true;
        //             // this.nzMessage.create('success', `Đã từ chối`);
        //             console.log('rs', rs);
        //         },
        //         (error) => {
        //             // this.nzMessage.create('error', `Xử lý không thành công`);
        //         }
        //     );
    }

    uploadFile(file: File) {
        const formData = new FormData();
        formData.append("CHANDUNG", file);
        // formData.append('title', 'upload file');
        // formData.append('description', 'upload file');
        this.loading = true;
        console.log("file", file);
        console.log(JSON.stringify(formData));

        formData.forEach((value, key) => {
            console.log(value);
        });

        this.aasConsumerManagerService
            .uploadFile(formData, this.userDetail.userId)
            .subscribe(
                (res) => {
                    console.log("res", res);
                    this.hashImageCapture = file;
                    // if (res['message'] === Constant.RES_SUCCESS) {
                    //     this.hashImageCapture = res['object']['hash'];
                    //     // this.compareFace(res['object']['hash']);
                    // }
                },
                (error) => {
                    this.loading = false;
                }
            );
    }

    compareFace(hash) {
        const body = {
            img_face: this.userDetail["hashFace"],
            img_face2: hash,
            client_session: "test",
            token: "sdfsdf",
        };
        // this.apiService.compareFace(body).subscribe(
        //     (response) => {
        //         console.log('res', response);
        //         this.loading = false;
        //         if (response['message'] === Constant.RES_SUCCESS) {
        //             this.result_compare = response['object'];
        //             if (response['object']['msg'] === 'NOMATCH') {
        //                 this.flag_icon = 'assets/image/icon_fail.png';
        //                 this.flag_class = 'compare_fail';
        //             } else {
        //                 this.flag_icon = 'assets/image/icon_ok.png';
        //                 this.flag_class = 'compare_success';
        //             }
        //         }
        //         this.hideNotification();
        //     },
        //     (error) => {
        //         this.loading = false;
        //         this.result_compare = error['object'];
        //         this.flag_icon = 'assets/image/icon_fail.png';
        //         this.flag_class = 'compare_fail';
        //         this.hideNotification();
        //     }
        // );
    }

    capture() {
        const iframe = this.api.getIFrame();
        const data = {
            id: "capture",
        };
        console.log("capture", data);
        iframe.contentWindow.postMessage(data, "*");
    }

    hideNotification() {
        setTimeout(() => {
            this.result_compare = {
                result: "",
                prob: "",
            };
            this.base64ScreenShot = "";
        }, 5000);
    }

    alert(msg, type, callBack = () => {}) {
        console.log(msg);
        const modalRef = this.modalService.open(NgModalComponent, {
            backdrop: "static",
            size: "lg",
            keyboard: false,
            centered: true,
        });
        modalRef.componentInstance.message = msg;
        modalRef.componentInstance.typeAlert = type;
        modalRef.componentInstance.notification = true;
        modalRef.componentInstance.callback = callBack;
    }

    open(content) {
        console.log(content);
        this.modalService
            .open(content, { ariaLabelledBy: "modal-basic-title" })
            .result.then(
                (result) => {
                    console.log("result", result);
                },
                (reason) => {}
            );
    }

    modal(msg, callBack = () => {}) {
        const modalRef = this.modalService.open(NgModalComponent);
        modalRef.componentInstance.message = msg;
        modalRef.componentInstance.callback = callBack;
    }
}
