
import { Select2OptionData } from 'ng-select2';
import { concat, Observable, of, Subject } from 'rxjs';
import { UserTypeConstant, VerificationType } from 'src/app/utils/common/constant';
import { PartnerService } from 'src/app/utils/services/aas-network/partner.service';
import { startWith, debounceTime, distinctUntilChanged, tap, switchMap, map, catchError } from 'rxjs/operators';
import { AasContractService } from 'src/app/utils/services/aas-network/aas-contract.service';
import { PartService } from 'src/app/utils/services/aas-network/part/part.service';
import { LoadingService } from 'src/app/utils/services/loading.service';
import { Item } from '../../../example/example-item.model';

export class PartyJoinContract {
    partyId: string = '';
    tenToChuc: string = '';
    email: string = '';
    // customerType: string = '';
    userType: string = null;
    verificationType: string = ''; //VIDEO_CALL, BLANK
    signForm: any;
    signInDays: string = ''; //Thời hạn kí
    username: string = '';
    tenDaiDien: string = '';
    endDate: string = '';
    isTrustedPartner: string = 'N';
    partyType: string = "";
    cmnd: string = "";
    maSoThue: string = "";
    sequence = 0;

    hinhThucKyData: Array<any> = [];
    nguoiNhanData: Array<any> = [];
    
    dropListPartData: Observable<any>;
    partsLoading = false;
    partsInput$ = new Subject<string>();
    partKeySearch = "";
    endDataPart = false;
    partPage = 1;
    myLoader: LoadingService;

    allowDetete: boolean = true;
    allowEdit: boolean = true;
    allowEditUserType: boolean = true;
    allowEditPartyId: boolean = true;

    isOwner: boolean = false;
    

    hinhThucKy_consumer = [
        { id: 'NO_AUTHEN', text: 'Cấp 1: Ký ảnh' },
        { id: 'OTP', text: 'Cấp 2: SMS OTP' },
        { id: 'OTP_EMAIL', text: "Cấp 2: Email OTP"},
        // { id: 'EKYC', text: 'Cấp 3: SMS OTP và eKYC' },
        // { id: 'EKYC_EMAIL', text: "Cấp 3: Email OTP và eKYC"},
        { id: 'USB_TOKEN', text: 'Cấp 4: Usb-Token' },
        { id: 'SIGN_SERVER', text: 'Cấp 4: VNPT ký số' },
        { id: 'SMART_CA', text: 'Cấp 4: VNPT SmartCA' }
    ];
    
    hinhThucKy_business = [
        { id: 'NO_AUTHEN', text: 'Cấp 1: Ký ảnh' },
        { id: 'OTP', text: 'Cấp 2: SMS OTP' },
        { id: 'OTP_EMAIL', text: "Cấp 2: Email OTP"},
        // { id: 'EKYC', text: 'Cấp 3: SMS OTP và eKYC' },
        // { id: 'EKYC_EMAIL', text: "Cấp 3: Email OTP và eKYC"},
        { id: 'USB_TOKEN', text: 'Cấp 4: Usb-Token' },
        { id: 'SIGN_SERVER', text: 'Cấp 4: VNPT ký số' },
        { id: 'SMART_CA', text: 'Cấp 4: VNPT SmartCA' }
    ]

    hinhThucXacMinh = [
        { id: VerificationType.VIDEO_CALL, text: 'Xác minh' },
        { id: VerificationType.NONE, text: 'Không xác minh' }
    ];

    userTypeData = [
        { id: UserTypeConstant.CONSUMER, text: 'Cá nhân' },
        { id: UserTypeConstant.BUSINESS, text: 'Doanh nghiệp' },
    ];

    errorValidate: any = null;
    generalErrorValidate: any = null;

    sdt = "";

    noResultParty = false;

    constructor(object = null) {
        object = object || {};

        this.partyId = object.partyId || null;
        this.tenToChuc = object.tenToChuc || '';
        this.tenDaiDien = object.tenDaiDien || '';
        this.email = object.email || '';
        // this.customerType = object.customerType || '';
        this.userType = object.userType || null;
        this.verificationType = object.verificationType || null;
        this.signForm = object.signForm || null;
        this.signInDays = object.signInDays || '';
        this.endDate = object.endDate || '';
        this.isTrustedPartner = object.isTrustedPartner || 'N';
        this.partyType = object.partyType || "";
        this.cmnd = object.cmnd || "";
        this.maSoThue = object.maSoThue || "";
        this.sequence = object.sequence;

        if (this.verificationType === 'CONSUMER') {
            this.hinhThucKyData = this.hinhThucKy_consumer;
        } else {
            this.hinhThucKyData = this.hinhThucKy_business;
        }

        if (object.nguoiNhanData && object.nguoiNhanData instanceof Array) {
            this.nguoiNhanData = object.nguoiNhanData;
        }

        // this.lazyLoadPartner();
        this.sdt = object.sdt || "";

        this.lazyLoadPart();
    }

    /**
     * Thay doi loai khach hang
     * @param event 
     */
    changeUserType(event) {
        if (event) {
            this.partyType = event.id;
            this.changedHinhThucKy(event.id);
        }
        // this.checkValidate();
    }

    /**
     * Thay doi hinh thuc ky theo loai khach hang
     * @param value 
     */
    changedHinhThucKy(value) {
        if (value === 'CONSUMER') {
            this.hinhThucKyData = this.hinhThucKy_consumer;
        } else {
            this.hinhThucKyData = this.hinhThucKy_business;
        }
    }

    /**
     * Thay doi doi tac
     * @param $event 
     */
    changeParty($event) {
        console.log($event);
        if ($event) {
            this.email = $event.email;
            this.tenToChuc = $event.tenToChuc;
            this.tenDaiDien = $event.tenDaiDien;
            this.userType = $event.partyType || null;
            this.partyType = $event.partyType;
            this.isTrustedPartner = $event.isTrustedPartner || 'N';
        
            //Neu da doi tac da co userType thi khong cho chinh sua
            if (this.userType) {
              this.allowEditUserType = false;
            }
            
            this.changedHinhThucKy($event.userType);
            if (this.isTrustedPartner == 'Y') {
                this.verificationType = VerificationType.NONE;
            } else {
                this.verificationType = VerificationType.VIDEO_CALL;
            }
        }
        // this.checkValidate();
    }

    changeVerificationType(event) {
        // this.checkValidate();
    }

    changeSignForm(event) {
        // this.checkValidate();
    }

    // trackByFn(item: any) {
    //     return item;
    // }

    /**
     * Tim kiem realtime droplist doi tac
     */
    lazyLoadPartner() {
        this.dropListPartData = concat(
            this.partsInput$.pipe(
                startWith(''),
                debounceTime(400),
                distinctUntilChanged(),
                tap(() => { this.partsLoading = true; LoadingService.instance.disabledLoader(this.partsLoading); }),
                switchMap((term) => {
                    this.partPage = 1;
                    this.endDataPart = false;
                    this.partKeySearch = term;
                    let pp = {
                        cmnd: "",
                        email: this.email,
                        isTrustedPartner: this.isTrustedPartner,
                        maSoThue: "",
                        partyId: this.partyId,
                        partyType: this.userType,
                        tenDaiDien: this.tenDaiDien,
                        tenToChuc: this.tenToChuc,
                    };
                    let tag = {
                        cmnd: "",
                        email: term,
                        isTrustedPartner: 'N',
                        maSoThue: "",
                        partyId: term,
                        partyType: "",
                        tenDaiDien: "",
                        tenToChuc: "",
                    }
                    let res = PartnerService?.instance?.getListPartner(term || "").pipe(
                        map((res: any) => {
                            let aa = this.addToDropList(res?.object, this.partyId ? pp : null);
                            aa = this.addToDropList(aa, term ? tag : null)
                            return aa;
                        }),
                        catchError(() => of(this.addToDropList(this.addToDropList([],  this.partyId ? pp : null), term ? tag : null))), // empty list on error
                        tap(() => { this.partsLoading = false; LoadingService.instance.disabledLoader(this.partsLoading); })
                    );
                    return res;
                })
            ));
    }

    // /**
    //  * Them item vao droplist
    //  * @param data 
    //  * @param param 
    //  * @param key 
    //  * @returns 
    //  */
    // addToDropList(data = [] ,param, key = "partyId") {
    //     key = key || "partyId";
    //     if (param === null) return data;
    //     let index = data.findIndex((item) => {
    //         return param[key] === item[key];
    //     });
    //     if (index >= 0) return data;
    //     return [...data, param]; 
    // }


    // MARK: ERROR control
    setGeneralError(error) {
        if (error === null) { this.generalErrorValidate = null; return; }
        this.generalErrorValidate = { ...this.generalErrorValidate, ...error };
        this.generalErrorValidate && Object.keys(this.generalErrorValidate).length === 0 ? this.generalErrorValidate = null : this.generalErrorValidate;
    }

    hasGeneralError(error = "") {
        if (error != "") {
            return this.generalErrorValidate && this.generalErrorValidate[error];
        }

        return this.generalErrorValidate !== null && this.generalErrorValidate !== undefined && this.generalErrorValidate !== {};
    }

    setError(error) {
        if (error === null) { this.errorValidate = null; return; }
        this.errorValidate = { ...this.errorValidate, ...error };
        this.errorValidate && Object.keys(this.errorValidate).length === 0 ? this.errorValidate = null : this.errorValidate;
    }

    hasError(control, error = "") {
        if (error != "") {
            return this.errorValidate && this.errorValidate[control] && this.errorValidate[control][error];
        }
        return this.errorValidate && this.errorValidate[control];
    }

    get inValid() {
        return this.errorValidate !== null && this.errorValidate !== undefined && this.errorValidate !== {};
    }

    get valid() {
        return this.errorValidate == null || this.errorValidate == undefined || this.errorValidate !== {};
    }

    checkValidate() {
        console.log("checkValidate called");
        this.setError(null);
        this.setGeneralError(null);

        if (this.partyId === null || this.partyId === "") {
            this.setError({ partyId: { required: true }});
            this.setGeneralError({ required: true });
        }

        if (this.userType === null || this.userType === "") {
            this.setError({ userType: { required: true }});
            this.setGeneralError({ required: true });
        }

        if (this.verificationType == null || this.verificationType == "") {
            this.setError({ verificationType: { required: true }});
            this.setGeneralError({ required: true });
        }

        if (this.signForm == null || this.signForm == "" || this.signForm == []) {
            this.setError({ signForm: { required: true }});
            this.setGeneralError({ required: true });
        }

        if (this.sequence == null || this.sequence === undefined) {
            this.setError({ sequence: { required: true }});
            this.setGeneralError({ required: true });
        } else {
            if (/[^0-9]*/g.test(this.sequence.toString())) {
                let s = Number(this.sequence);
                if (s <= 0) {
                    this.setError({ sequence: { min: true }});
                    this.setGeneralError({ minSequence: true });
                }
            } else {
                this.setError({ sequence: { pattern: true }});
                this.setGeneralError({ patternSequence: true });
            }
        }
    }

    trackByFn(item: any) {
        return item;
    }

    lazyLoadPart() {
        this.dropListPartData = concat(
            this.partsInput$.pipe(
                startWith(''),
                debounceTime(400),
                distinctUntilChanged(),
                tap(() => { this.partsLoading = true; LoadingService.instance.disabledLoader(this.partsLoading); }),
                switchMap((term) => {
                    this.noResultParty = false;
                    let res = AasContractService.instance.searchCustomer(term ?? "").pipe(
                        map((res: any) => {
                            if (res?.object.length > 0) {
                                return this.convertDataObj(res?.object);
                            } else if (term != "") {
                                this.noResultParty = true;
                                return this.createDefaultResult(term);
                            } else {
                                this.noResultParty = true;
                                return [];
                            }
                        }),
                        catchError(() => of([])), // empty list on error
                        tap(() => { this.partsLoading = false; LoadingService.instance.disabledLoader(this.partsLoading); })
                    );
                    return res;
                })
            ));
    }

    addToDropList(data = [], param, key = "partyId") {
        key = key || "id";
        if (param === null) return data;
        let index = data.findIndex((item) => {
            return param[key] === item[key];
        });
        if (index >= 0) return data;
        return [...data, param];
    }

    changedParty(e) {
        console.log(e)
        this.setNewData(e ? {partyId: e!.id, ...e.additional} : null);
    }

    convertDataObj(data) {
        let res = data.map(function (item) {
            return {
                id: item.partyId,
                text: item.email,
                additional: {
                    partyId: item.partyId,
                    email: item.email,
                    name: item.tenToChuc && item.tenToChuc !== '' ? item.tenToChuc : item.tenDaiDien || '',
                    tenToChuc: item.tenToChuc || '',
                    tenDaiDien: item.tenDaiDien || '',
                    type_customer: item.partyType === 'CONSUMER' ? 'Cá nhân' : 'Doanh nghiệp',
                    type_customer_label: item.partyType,
                    sgt: item.maSoThue || item.cmnd || '',
                    isTrustedPartner: item.isTrustedPartner || 'N',
                    sdt: item.sdt
                }
            };
        })

        if (this.partyId) {
            let param = {
                id: this.partyId,
                text: this.email,
                additional: {
                  partyId: this.partyId,
                  type_customer: this.userType === 'CONSUMER' ? 'Cá nhân' : 'Doanh nghiệp',
                  type_customer_label: this.userType,
                  email: this.email,
                  tenToChuc: this.tenToChuc,
                  tenDaiDien: this.tenDaiDien,
                  isTrustedPartner: this.isTrustedPartner,
                  sdt: this.sdt
                }
            }
    
            res = this.addToDropList(res, param, "id");
        }

        return res;
    }

    createDefaultResult(term) {
        return [{
            id: term,
            text: term,
            additional: {
              email: term,
              tenDaiDien: term,
              tenToChuc: term,
              isTrustedPartner: false,
              sdt: term
            }
        }];
    }

    setNewData(object = null) {
        object = object || {};

        this.partyId = object.id || '';
        this.tenToChuc = object.tenToChuc || '';
        this.tenDaiDien = object.tenDaiDien || '';
        this.email = object.email || '';
        // this.customerType = object.customerType || '';
        this.userType = object.userType || '';
        this.verificationType = object.verificationType || '';
        this.signForm = object.signForm || '';
        this.signInDays = object.signInDays || '';
        this.endDate = object.endDate || '';
        this.isTrustedPartner = object.isTrustedPartner || 'N';

        if (this.verificationType === 'CONSUMER') {
            this.hinhThucKyData = [
                { id: 'OTP', text: 'OTP' },
                // { id: 'ONE_TIME_CA', text: 'Ký số 1 lần' },
                { id: 'EKYC', text: 'OTP và eKYC' }
            ];
        } else {
            this.hinhThucKyData = [
                { id: 'USB_TOKEN', text: 'Usb-Token' },
                { id: 'SIGN_SERVER', text: 'VNPT ký số' }
            ]
        }

        if (object.nguoiNhanData && object.nguoiNhanData instanceof Array) {
            this.nguoiNhanData = object.nguoiNhanData;
        }

        this.sdt = object.sdt || "";
    }
}
