<div class="landingpage">
    <header id="myheader">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <nav class="navbar navbar-expand-lg navbar-lightrounded">
                            <a class="navbar-brand" href="#">
                                <div class="logo">
                                    <img [src]="data?.configs[5]?.value">
                                    <!-- <span>VNPT eContract</span> -->
                                </div>
                            </a>
                            <button class="navbar-toggler collapsed" type="button" data-toggle="collapse"
                                data-target="#navbarsExample09" aria-controls="navbarsExample09" aria-expanded="false"
                                aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-justify"
                                        viewBox="0 0 16 16" id="justify">
                                        <path fill-rule="evenodd"
                                            d="M2 12.5a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5zm0-3a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5zm0-3a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5zm0-3a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5z">
                                        </path>
                                    </svg>
                                </span>
                            </button>
                            <div class="menu-btn">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-justify"
                                    viewBox="0 0 16 16" id="justify">
                                    <path fill-rule="evenodd"
                                        d="M2 12.5a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5zm0-3a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5zm0-3a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5zm0-3a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5z">
                                    </path>
                                </svg>
                            </div>
                            <div class="navbar-collapse collapse pushy pushy-right" id="navbarsExample09" style="">
                                <ul class="navbar-nav mr-auto" id="menu_portal">
                                    <li class="nav-item active pushy-link">
                                        <a class="nav-link" (click)="scrollTo('section2')" [routerLink]='"."'
                                            [fragment]="">Giới thiệu</a>
                                    </li>
                                    <li class="nav-item pushy-link">
                                        <a class="nav-link" (click)="scrollTo('section5')" [routerLink]='"."'
                                            [fragment]="">Lợi ích</a>
                                    </li>
                                    <li class="nav-item pushy-link">
                                        <a class="nav-link" (click)="scrollTo('section3')" [routerLink]='"."'
                                            [fragment]="">Dịch vụ</a>
                                    </li>
                                    <li class="nav-item pushy-link">
                                        <a class="nav-link" (click)="scrollTo('section7')" [routerLink]='"."'
                                            [fragment]="">Gói cước</a>
                                    </li>
                                    <li class="nav-item pushy-link">
                                        <a class="nav-link" (click)="scrollTo('section9')" [routerLink]='"."'
                                            [fragment]="">Câu hỏi</a>
                                    </li>
                                    <li class="nav-item pushy-link">
                                        <a class="nav-link" [routerLink]='"."' (click)="changeMode(1)"
                                            [fragment]="'contact'">Liên hệ</a>
                                    </li>
                                    <li class="nav-item pushy-link">
                                        <a class="nav-link" [routerLink]='"."' (click)="changeMode(2)"
                                            [fragment]="'verify'">Xác minh</a>
                                    </li>
                                </ul>
                                <div class="login">
                                    <a [routerLink]="[constantUrl.SIGNUP_V2]" class="btn_reg">Đăng ký</a>
                                    <a [routerLink]="[constantUrl.LOGIN]" class="btn_login">Đăng nhập</a>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <div class="site-overlay"></div>
    <div [hidden]="homeMode != 0">
        <section id="section1" class="block-section">
            <!-- <video #videobg id="videobg" loop="" muted="" playsinline="" oncanplay="this.play()">
          <source [attr.data-src]="'assets/img/home/vid-bg.mp4'" [attr.video-src]="'assets/img/home/vid-bg.mp4'" src="assets/img/home/vid-bg.mp4" type="video/mp4">
              <source [attr.data-src]="'assets/img/home/vid-bg.mp4'" [attr.video-src]="'assets/img/home/vid-bg.webm'" src="assets/img/home/vid-bg.mp4" type="video/mp4">
      </video> -->
            <div class="banner-video">
                <div class="video">
                    <div class="stop" onclick="return stopVideo1(this)">
                        <img src="../../../assets/img/home/v3/icon/Ellipse 1.png" alt="" class="img-1">
                        <img src="../../../assets/img/home/v3/icon/Ellipse 2.png" alt="" class="img-2">
                        <img src="../../../assets/img/home/v3/icon/24px.png" alt="" class="img-3">
                    </div>
                    <div class="pattern-video">
                        <video id="myVideo" controls muted autoplay>
                            <source [src]="data.banner.description" type="video/mp4">
                        </video>
                        <div class="startMobi">
                            <img src="../../../assets/img/home/v3/icon/Play button.png" alt=""
                                onclick="return playvideo1Mobi(this)">
                        </div>
                    </div>
                    <div class="content-banner">
                        <div class="bg" [ngStyle]="{'background-image': data.banner.avatar}"></div>
                        <div class="container-full">
                            <div class="container">
                                <div class="row" style="    height: 100%">
                                    <div class="col-md-5">
                                    </div>
                                    <div class="col-md-7">
                                        <div class="content" id="content-banner-video">
                                            <div class="start" id="startvideo1">
                                                <img src="../../../assets/img/home/v3/icon/Play button.png" alt=""
                                                    onclick="return play(this)">
                                            </div>
                                            <div class="content-banner-right">
                                                <h1 class="headline-1">
                                                    VNPT
                                                </h1>
                                                <h1 class="headline-2">
                                                    <svg width="37" viewBox="0 0 37 34" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <g clip-path="url(#clip0)">
                                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                                d="M3.74053 14.5614C4.89863 7.42472 11.0654 2 18.5279 2C24.6876 2 29.9564 5.67261 32.2663 11.0081C32.4857 11.5149 33.0745 11.7479 33.5813 11.5285C34.0881 11.3091 34.3211 10.7203 34.1017 10.2135C31.4842 4.16775 25.5082 0 18.5279 0C10.07 0 3.07984 6.15107 1.76676 14.2368C0.748522 14.5146 0 15.4461 0 16.5524C0 17.8778 1.0745 18.9524 2.4 18.9524C3.7255 18.9524 4.8 17.8778 4.8 16.5524C4.8 15.7234 4.37969 14.9926 3.74053 14.5614Z"
                                                                fill="white" />
                                                            <path
                                                                d="M25.8627 13.4616V17.2553C25.8627 17.6979 25.7364 18.014 25.4206 18.3302C25.1047 18.6463 24.7256 18.7728 24.3466 18.7728H13.2913V19.658C13.2913 20.1638 13.544 20.6697 13.9862 21.049L14.8706 21.8078C15.376 22.1871 15.8182 22.3768 16.2605 22.3768H24.2834C24.7256 22.3768 25.0415 22.5033 25.3574 22.8194C25.6732 23.1356 25.7996 23.5149 25.7996 23.9575C25.7996 24.4001 25.6101 24.7163 25.2942 25.0324C24.9783 25.3486 24.5993 25.475 24.2203 25.475H16.0077C15.4392 25.475 14.8706 25.3486 14.3021 25.1589C13.6703 24.906 13.2281 24.7163 12.9754 24.4634L11.5856 23.262C11.1434 22.9459 10.7644 22.4401 10.5117 21.8078C10.1958 21.1755 10.0695 20.6064 10.0695 20.1006V12.9557C10.0695 12.5764 10.259 12.0073 10.5749 11.3118C10.8907 10.6163 11.2698 10.0472 11.7751 9.66785L13.1018 8.65619C13.9862 7.96067 14.9338 7.64453 15.9446 7.64453H20.3667C21.4406 7.64453 22.5146 8.08713 23.4622 9.03556L24.6625 10.1737C25.4837 10.8692 25.8627 12.0073 25.8627 13.4616ZM13.2913 13.2719V15.6746H22.7673V13.5248C22.7673 12.9557 22.5777 12.5131 22.1987 12.197L21.3143 11.375C20.8721 10.9957 20.4299 10.806 20.0508 10.806H16.1341C15.6287 10.806 15.1865 10.9956 14.8074 11.3118L13.923 12.0705C13.4808 12.3235 13.2913 12.7661 13.2913 13.2719Z"
                                                                fill="white" />
                                                            <path fill-rule="evenodd" clip-rule="evenodd"
                                                                d="M35.4367 18.7176C36.2431 18.3308 36.8 17.5066 36.8 16.5524C36.8 15.2269 35.7255 14.1523 34.4 14.1523C33.0745 14.1523 32 15.2269 32 16.5524C32 17.5296 32.5841 18.3705 33.4222 18.7449C32.5637 26.2211 26.2415 32.0008 18.5388 32.0008C12.5633 32.0008 7.46888 28.5095 5.0362 23.4618C4.79643 22.9642 4.19874 22.7553 3.70122 22.9951C3.20369 23.2348 2.99475 23.8325 3.23452 24.3301C5.98204 30.0311 11.7534 34.0008 18.5388 34.0008C27.3579 34.0008 34.5812 27.313 35.4367 18.7176Z"
                                                                fill="white" />
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0">
                                                                <rect width="37" height="34" fill="white" />
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                    <span> CONTRACT</span>
                                                </h1>
                                                <span class="dec">
                                                    {{this.data.banner.subTitle}}
                                                </span>
                                                <br />
                                                <a href="" class="btn-join" [routerLink]="[constantUrl.SIGNUP_V2]">Trải
                                                    nghiệm
                                                    ngay <img class="icon"
                                                        src="../../../assets/img/home/v3/icon/right_arrow_blue_icon.png"
                                                        width="24" height="24"></a>
                                                <div class="download-qr">
                                                    <a href="">
                                                        <img [src]="data.configs[8].value" alt="">
                                                    </a>
                                                    <a [href]="data.configs[3].value">
                                                        <img src="../../../assets/img/home/v3/icon/google_button.png"
                                                            alt="">
                                                    </a>
                                                    <a [href]="data.configs[10].value">
                                                        <img src="../../../assets/img/home/v3/icon/appstore_button.png"
                                                            alt="">
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section-2" id="section2">
            <div class="container">
                <div class="row">
                    <h3 class="title-ssection">Giới thiệu</h3>
                    <span class="dec">{{this.data.gioiThieu.subTitle }}
                    </span>
                    <div class="video">
                        <video id="myVideo2" loop controls autoplay="false">
                            <source [src]="data.gioiThieu.description" type="video/mp4">
                        </video>
                        <img class="img-introduce" [src]="data.gioiThieu.avatar" alt="">
                        <div class="start">
                            <img onclick="return videoGioiThieu(this)"
                                src="../../../assets/img/home/v3/icon/play_button_blue.png" alt="">
                        </div>
                    </div>
                    <div class="col-md-4" *ngFor="let item of data.gioiThieu.giaiPhap">
                        <div class="col-introduce">
                            <img [src]="item.avatar" alt="canhan_icon.png">
                            <div class="right">
                                <h3>
                                    {{item.title}}
                                </h3>
                                <span>
                                    {{item.description}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section-3" id="section3">
            <div class="bg"></div>
            <div class="container">
                <div class="body">
                    <div class="row" style="align-items: center;">
                        <div class="col-md-1 col-lg-5">
                            <div class="row">
                                <img src="../../../assets/img/home/v3/Image/dichvu_econtract_image.png"
                                    class="img-banner-left" alt="">
                                <!--  <img [src]="data.dichVu.avatar"
                                class="img-banner-left" alt=""> -->
                                <div class="mobi">
                                    <img src="../../../assets/img/home/v3/Image/dichvu_econtract_mobile.png" class=""
                                        alt="">
                                </div>
                            </div>
                        </div>
                        <div class="col-md-11 col-lg-7">
                            <div class="content">
                                <h3 class="title-ssection">{{this.data.dichVu.title}}</h3>
                                <span>
                                    {{this.data.dichVu.subTitle}}
                                </span>
                                <ul class="list-detail">
                                    <li *ngFor="let item of data.dichVu.danhSach">
                                        <img src="../../../assets/img/home/v3/icon/check.png" alt="">
                                        <span>{{item}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section-4" id="section4">
            <div class="container">
                <div class="row row-mobi">
                    <div class="col-md-6">
                        <div class="left-content">
                            <h3 class="title-ssection">{{this.data.giaiPhap.title}}</h3>
                            <span>
                                {{this.data.giaiPhap.description}}
                            </span>
                            <div class="mobi">
                                <div class="right">
                                    <img class="img-bag"
                                        src="https://econtract-dev.vnptit3.vn/assets/img/home/slider-img1.svg" alt="">
                                </div>
                            </div>
                            <div class="col-introduce">
                                <img src="../../../assets/img/home/v3/icon/tochucdichvu_icon.png" alt="canhan_icon.png">
                                <div class="right">
                                    <h3>
                                        {{this.data.giaiPhap.chiTiet.title}}
                                    </h3>
                                    <span>
                                        {{this.data.giaiPhap.chiTiet.subTitle}}
                                    </span>
                                    <a href="" class="more">
                                        Xem chi tiết
                                        <img src="../../../assets/img/home/v3/icon/right_arrow_blue_icon.png" alt="">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 pc">
                        <div class="right">
                            <img class="img-bag" [src]="data.giaiPhap.avatar" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section-5" id="section5">
            <div class="container">
                <div class="row body">
                    <div class="col-md-0 col-lg-6 d-sm-none d-md-block mobi">
                        <img class="img-bag" [src]="data.loiIch.avatar" alt="">
                    </div>
                    <div class="col-md-12 col-lg-6">
                        <div class="row">
                            <div class="right-content">
                                <h3 class="title-ssection">{{this.data.loiIch.title}}</h3>
                                <span class="dec">
                                    {{this.data.loiIch.subTitle}}
                                </span>
                                <img class="img-bag mobi" src="../../../assets/img/home/v3/Image/loiich_img.png" alt="">
                                <div class="list-content autoplayslide">
                                    <div style="padding: 10px 0px" *ngFor="let item of data.loiIch.danhSach">
                                        <div class="col-introduce">
                                            <img [src]="item.avatar" alt="loiich_icon.png">
                                            <div class="right">
                                                <h3>
                                                    {{item.title}}
                                                </h3>
                                                <span>
                                                    {{item.description}}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section-6" id="section6">
            <h3 class="title-ssection">{{this.data.hopDong.title}}</h3>
            <div class="container">
                <div class="">
                    <div class="body-item">
                        <ul class="list-item">
                            <li *ngFor="let item of data.hopDong.danhSach">
                                <img [src]="item.avatar" alt="">
                                <span>{{item.title}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <section class="section-7" id="section7">
            <div class="container">
                <div class="row">
                    <div class="body">
                        <h3 class="title-ssection">{{this.data.goiCuoc.title}}</h3>
                        <span class="dec">
                            {{this.data.goiCuoc.subTitle}}
                        </span>
                        <div class="content">
                            <ul class="nav nav-tabs" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" onclick="setslick(this)" href="#home"
                                        *ngIf="!(data.goiCuoc.caNhan.length === 0)"> Cá nhân</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link active" data-toggle="tab" onclick="setslick(this)" href="#menu1"
                                        *ngIf="!(data.goiCuoc.dichVu.length === 0)"> Tổ chức</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-toggle="tab" onclick="setslick(this)" href="#menu2"
                                        *ngIf="!(data.goiCuoc.giaiPhap.length === 0)">Giải pháp</a>
                                </li>
                            </ul>
                            <div class="tab-content">
                                <div id="home" class="container tab-pane fade">
                                    <div class="row" id="slidegoicuoc">
                                        <div class="col-md-4" *ngFor="let item of data.goiCuoc.caNhan; let i = index">
                                            <div class="package" [ngClass]="{'active': i == 1}">
                                                <h3>{{item.title}}</h3>
                                                <span class="desc">
                                                    {{item.subTitle}}
                                                </span>
                                                <a (click)="navigateToConsumer()" class="btn-reg"> Đăng ký
                                                    <img src="../../../assets/img/home/v3/icon/right_arrow_white_icon.png"
                                                        alt="">
                                                </a>
                                                <hr />
                                                <ul class="list-item" [innerHTML]="item.description">
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="menu1" class="container tab-pane active">
                                    <div class="row" id="slidegoicuoc">
                                        <div class="col-md-4" *ngFor="let item of data.goiCuoc.dichVu; let i = index">
                                            <div class="package" [ngClass]="{'active': i == 1}">
                                                <h3>{{item.title}}</h3>
                                                <span class="desc">
                                                    {{item.subTitle}}
                                                </span>
                                                <a (click)="navigateToBusiness()" class="btn-reg"> Đăng ký
                                                    <img src="../../../assets/img/home/v3/icon/right_arrow_white_icon.png"
                                                        alt="">
                                                </a>
                                                <hr />
                                                <ul class="list-item" [innerHTML]="item.description">
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="menu2" class="container tab-pane fade">
                                    <div class="row">
                                        <div class="col-md-4" *ngFor="let item of data.goiCuoc.giaiPhap; let i = index">
                                            <div class="package" [ngClass]="{'active': i == 1}">
                                                <h3>{{item.title}}</h3>
                                                <span class="desc">
                                                    {{item.subTitle}}
                                                </span>
                                                <a [routerLink]="[constantUrl.SIGNUP_V2]" class="btn-reg"> Đăng ký
                                                    <img src="../../../assets/img/home/v3/icon/right_arrow_white_icon.png"
                                                        alt="">
                                                </a>
                                                <hr />
                                                <ul class="list-item" [innerHTML]="item.description">
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section-8" id="section8">
            <div class="body">
                <div class="container">
                    <div class="row reverse-col">
                        <div class="col-md-8 col-lg-8">
                            <div class="slide_partner">
                                <div class="content-left">
                                    <div class="img-icon mr-4" *ngFor="let item of data.doiTac.danhSach">
                                        <img [src]="item.avatar" alt="">
                                    </div>
                                </div>
                                <div class="content-left">
                                    <div class="img-icon mr-4" *ngFor="let item of data.doiTac.danhSach">
                                        <img [src]="item.avatar" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-4">
                            <div class="content-right">
                                <h3 class="title-ssection">{{this.data.doiTac.title}}</h3>
                                <span>
                                    {{this.data.doiTac.subTitle}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section-9" id="section9">
            <div class="container">
                <div class="row">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="bg"></div>
                            <h3 class="title-ssection">{{this.listCauHoi.title}}</h3>
                            <div id="accordion">
                                <div class="card" *ngFor="let item of listCauHoiLeft; let i = index">
                                    <div class="card-header" id="headingOne">
                                        <h5>
                                            <button class="btn btn-link" data-toggle="collapse"
                                                [attr.data-target]="'#collapse' + i" aria-expanded="true"
                                                aria-controls="collapseOne">
                                                {{item.title}}
                                                <img src="../../../assets/img/home/v3/icon/arrow_down_white_icon.png"
                                                    alt="">
                                            </button>

                                        </h5>
                                    </div>
                                    <div [id]="'collapse' + i" class="collapse" [ngClass]="{'show': i == 0}"
                                        aria-labelledby="headingOne" data-parent="#accordion">
                                        <div class="card-body">
                                            <div _ngcontent-tln-c166="" class="expand-content"
                                                [innerHTML]="item.description">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div id="accordion2">
                            <div class="card" *ngFor="let item of listCauHoiRight; let i = index">
                                <div class="card-header" id="headingOne">
                                    <h5>
                                        <button class="btn btn-link" data-toggle="collapse"
                                            [attr.data-target]="'#cls' + i" aria-expanded="true"
                                            aria-controls="collapse3">
                                            {{item.title}}

                                            <img src="../../../assets/img/home/v3/icon/arrow_down_white_icon.png"
                                                alt="">
                                        </button>
                                    </h5>
                                </div>
                                <div [id]="'cls' + i" class="collapse" [ngClass]="{'show': i == 0}"
                                    aria-labelledby="headingOne" data-parent="#accordion2">
                                    <div class="card-body" [innerHTML]="item.description">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="send-mail">
                        <div class="row" style="align-items: center;">
                            <div class="col-md-6">
                                <h3 class="title">Hãy nhập email để được nhận các thông tin mới nhất !</h3>
                            </div>
                            <div class="col-md-6">
                                <form [formGroup]="formTrial">
                                    <div class="input-group">
                                        <input type="text" class="form-control" formControlName="email"
                                            placeholder="Nhập email">
                                        <div class="input-group-append">
                                            <span class="input-group-text" (click)="goToSignUp()">Đăng ký
                                                <img src="../../../assets/img/home/v3/icon/right_arrow_white_icon.png"
                                                    alt="">
                                            </span>
                                        </div>

                                    </div>
                                    <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ValidMsg.email"
                                        [control]="email" [detail]="{ name: 'email' }"></app-show-validate-errors>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section-10" id="section10">
            <div class="container">
                <footer>
                    <div class="">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="left">
                                        <img src="../../../assets/img/home/v3/Image/vnpt_logo.png" alt=""
                                            class="logo-footer">
                                        <div class="left-address">
                                            <img src="../../../assets/img/home/v3/icon/location_icon.png" alt="">
                                            <span>{{this.data.configs[2].value}}</span>
                                        </div>
                                        <div class="right-address">
                                            <div>
                                                <img src="../../../assets/img/home/v3/icon/call icon.png" alt="">
                                                <span>{{this.data.configs[1].value}}</span>
                                            </div>
                                            <div>
                                                <img src="../../../assets/img/home/v3/icon/message_icon.png" alt="">
                                                <span>{{this.data.configs[9].value}}</span>
                                            </div>
                                            <div>
                                                <img src="../../../assets/img/home/v3/icon/internet_icon.png" alt="">
                                                <span>{{this.data.configs[6].value}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="right">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <h3 class="title">Trợ giúp</h3>
                                            <span>Điều khoản sử dụng</span>
                                        </div>
                                        <div class="col-md-6">
                                            <h3 class="title">Tải ứng dụng</h3>
                                            <div class="list-dow">
                                                <a [href]="data.configs[3].value">
                                                    <img src="../../../assets/img/home/v3/icon/Google.png" alt="">
                                                </a>
                                                <a [href]="data.configs[10].value">
                                                    <img src="../../../assets/img/home/v3/icon/App Store.png" alt="">
                                                </a>
                                                <!-- <img src="../../../assets/img/home/v3/icon/App Store.png" alt="">
                                            <img src="../../../assets/img/home/v3/icon/Google.png" alt=""> -->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
            <div class="copy-right">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6">
                            <p class="Reserved">© VNPT-IT 2020. All Rights Reserved.</p>
                        </div>
                        <div class="col-md-6">
                            <div class="right">
                                <p>Kênh truyền thông: </p>
                                <a [href]="data.configs[4].value">
                                    <img src="../../../assets/img/home/v3/icon/facebook_icon.png" alt="">
                                </a>
                                <a [href]="data.configs[7].value">
                                    <img src="../../../assets/img/home/v3/icon/youtube_icon.png" alt="">
                                </a>
                                <a href="">
                                    <img src="../../../assets/img/home/v3/icon/linkedin_icon.png" alt="">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div [hidden]="homeMode !=1">
        <app-contact></app-contact>
    </div>
    <div [hidden]="homeMode !=2">
        <app-home-verify-signature></app-home-verify-signature>
    </div>
</div>