export class SequenceLine {

    public sequence: number;
    public prefixType: string;
    public suffixType: string;
    public suffix: string;
    public padding: number;
    public startNumber: string;
    public resetType: string;


    // public id: string;
    // public sequenceId: string;
    // public created: string;
    // public modified: string;
    // public prefix: any;
    // public suffixValue: any;
    // public numberStep: number;
    // public nextNumber: number;
    // public name: string;

    constructor(obj = null) {
        this.sequence = obj.sequence ?? 0;
        this.prefixType = obj.prefixType ?? '';
        this.suffixType = obj.suffixType ?? '';
        this.suffix = obj.suffix ?? '';
        this.padding = obj.padding ?? 1;
        this.startNumber = obj.startNumber ?? "";
        this.resetType = obj.resetType ?? '';

        // this.id = obj.id ?? '';
        // this.sequenceId = obj.sequenceId ?? '';
        // this.created = obj.created ?? '';
        // this.modified = obj.modified ?? '';
        // this.prefix = obj.prefix ?? null;
        // this.suffixValue = obj.suffixValue ?? null;
        // this.numberStep = obj.numberStep ?? null;
        // this.nextNumber = obj.nextNumber ?? null;
        // this.name = obj.name ?? '';
    }
}
