export const localize_econtract = {
    contract_confirm_save_img_esign: "Bạn muốn lưu hình ảnh chữ ký mới này làm hình ảnh chữ ký mặc định?",
    contract_alert_no_image_esign: "Vui lòng thực hiện đính kèm hình ảnh chữ ký",
    select_flow: "Chọn luồng hợp đồng",
    access_denied_edit_this_contract: "Bạn không có quyền chỉnh sửa hợp đồng này!",
    draft_sign: "Ký nháy",
    sign_contract: "Ký hợp đồng",
    sign_by_usb_token: "Ký USB-Token",
    sign_by_vnpt_ds: "VNPT ký số",
    sign_by_esign: "Ký điện tử",
    return: "Trả lại",
    confirm: "Xác nhận",
    contract_name: "Tên hợp đồng",
    contract_file_name: "File hợp đồng",
    contract_info: "Thông tin chi tiết hợp đồng",
    partner_join_contract: "Bên tham gia hợp đồng",
    config_flow: "Cấu hình luồng",
    add_employee_into_signflow_please: "Vui lòng thêm nhân sự vào luồng ký nội bộ tại chức năng cấu hình luồng hợp đồng!",
    info_party_join_partner_discuss: "Thông tin nhân sự tham gia đàm phán",
    info_employee_join_internal_discuss: "Thông tin nhân sự tham gia thẩm định",
    contract_appraisal: "Thẩm định hợp đồng",
    contract_confirm_accept_internal_discuss: 'Bạn chắc chắn đồng ý thẩm định hợp đồng này?',
    contract_confirm_deny_internal_discuss: 'Bạn chắc chắn từ chối thẩm định hợp đồng này?',
    confirm_save_part_join: "Bạn chắc chắn muốn lưu thay đổi thông tin các bên tham gia hợp đồng?",
    save_part_join_success: "Thông tin các bên tham gia hợp đồng đã được cập nhật thành công!",
    contract_appraisal_result: "Kết quả thẩm định",
    contract_partner_discuss_result: "Kết quả đàm phán",
    send_department_sign: "Chuyển bộ phận ký nháy",
    end_department_sign: "Kết thúc ký nội bộ",
    sign_by_smart_ca: "Cấp 4: VNPT SmartCA", // SMART_CA
    sms_otp_sign: "Cấp 2: SMS OTP", // OTP
    email_otp_sign: "Cấp 2: Email OTP", // OTP_EMAIL
    sign_no_authen: "Cấp 1: Ký ảnh", // NO_AUTHEN
    lv4_usb_token: "Cấp 4: USB-Token", // USB_TOKEN
    lv4_vnpt_sign: "Cấp 4: VNPT Ký số", // SIGN_SERVER
    lv4_smart_ca: "Cấp 4: SmartCA", 
    ekyc: "Cấp 3: SMS OTP & eKYC", // EKYC
    ekyc_email: "Cấp 3: EMAIL OTP & eKYC", // EKYC_EMAIL
    sign_no_ca: "Duyệt không ký",
    confirm_resign: "Tất cả chữ ký trên file hợp đồng sẽ bị mất nếu bạn thực hiện ký lại. Bạn chắc chắn muốn thực hiện thao tác này?",
    resign: "Ký lại"
}