export class InfraConfig {
    stt: any = 0;
    param: string = "";
    name: string = "";
    unit: string = "";
    unitContract: string = "0";
    unitNumber: string = "0";
    unitPrice: string = "0";
    price: string = "0";

    constructor(d = null) {
        d = d || {};
        this.stt = d.stt;
        this.param = d.param || "";
        this.name = d.name || "";
        this.unit = d.unit || "";
        this.unitContract  = d.unitContract || "0";
        this.unitNumber = d.unitNumber || "0";
        this.unitPrice = d.unitPrice || "0";
        this.price = d.price || "0";

        this.getPrice();
    }

    getPrice() {
        console.log("getPrice");
        if (!isNaN(parseInt(this.unitNumber)) && !isNaN(parseInt(this.unitPrice))) {
            this.price = `${Number(this.unitNumber) * Number(this.unitPrice)}`;
        } else {
            this.price = "0";
        }

        return this.price
    }

    getUnitPrice() {
        if (!isNaN(parseInt(this.unitPrice))) {
            return `${Number(this.unitPrice)}`;
        } else {
            return "0";
        }
    }
}