export class Notification {
    notificationId: string = '';
    titleDetail: string = '';
    body: string = '';
    notificationType: string = '';
    data: string = '';
    sentAt: string = '';
    readAt: string = '';
    status: string = '';
    userId: string = '';

    constructor(data = null) {
        data = data || {};

        this.notificationId = data.notificationId || '';
        this.titleDetail = data.titleDetail || '';
        this.body = data.body || '';
        this.notificationType = data.notificationType || '';
        this.data = data.data || '';
        this.sentAt = data.sentAt || '';
        this.readAt = data.readAt || '';
        this.status = data.status || '';
        this.userId = data.userId || '';
    }
}