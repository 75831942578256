import { Component, OnInit } from '@angular/core';
import { ContractDownloadStatus } from 'src/app/utils/common/constant';
import { UtilsFunc } from 'src/app/utils/common/utils-func';
import { ContractListResponse } from 'src/app/utils/models/aas-models/contract/contract-list/contract-list-response.model';
import { ReportCecaService } from 'src/app/utils/services/aas-network/report-ceca/report-ceca.service';
import { DownloadContract } from '../../../../../utils/models/aas-models/dowload-contract/download-contract.model';

@Component({
  selector: 'app-list-download-contract',
  templateUrl: './list-download-contract.component.html',
  styleUrls: ['./list-download-contract.component.scss'],
})
export class ListDownloadContractComponent implements OnInit {
  currentStage = ContractDownloadStatus;
  page: number = 1;
  maxSize: number = 10;
  sort: string = 'DESC';
  propertiesSort: string = 'modified';
  totalElement: number = 0;
  listDownloadContract: DownloadContract[] = [];
  sortColConfig = {
    title: null,
    partnersCount: null,
    modified: false,
    currentStage: null,
  };
  constructor(private reportCecaService: ReportCecaService) {}

  ngOnInit(): void {
    this.getDownloadContracts();
  }
  //MARK: Loadpage
  loadData(page = 1) {
    this.page = page;
    this.getDownloadContracts();
  }

  getDownloadContracts() {
    this.reportCecaService
      .requestDownloadCOntract(
        this.page,
        this.maxSize,
        this.sort,
        UtilsFunc.getPropertiesSort(this.sortColConfig)
      )
      .subscribe(
        (res: any) => {
          this.listDownloadContract = [];
          if (res && res.object.data instanceof Array) {
            res.object.data.forEach((item) => {
              this.listDownloadContract.push(new DownloadContract(item));
            });
          }
          console.log(this.listDownloadContract);

          this.totalElement = res.object.totalElement;
        },
        (error) => {
          console.log(error);
        }
      );
  }
}
