import { UserTypeConstant } from './../../../../../utils/common/constant';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { UserInformationsItem } from 'src/app/utils/models/profile/user-info-item.model';
import { AuthService } from 'src/app/utils/services/auth.service';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { UserInformationsResponse } from 'src/app/utils/models/profile/user-info-response.model';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { ControlAccount } from 'src/app/utils/common/control-account';
import { InfoPartyObject } from 'src/app/utils/models/aas-models/info-party/info-party-object';
import { ActivatedRoute } from '@angular/router';
import { AlertControl } from 'src/app/utils/alert/alert-control';

@Component({
  selector: 'app-service-signature-manager',
  templateUrl: './service-signature-manager.component.html',
  styleUrls: ['./service-signature-manager.component.scss']
})
export class ServiceSignatureManagerComponent implements OnInit, AfterViewInit {
  @ViewChild('usbtokentab') usbtokenTab: ElementRef;
  @ViewChild('smartcatab') smartcaTab: ElementRef;
  @ViewChild('vnptsigntab') vnptsignTab: ElementRef;
  @ViewChild('esigntab') esignTab: ElementRef;

  tabName = this.activedRoute.snapshot.queryParamMap.get('tabName');

  SIGN_SERVER: boolean = true;
  USB_TOKEN = true;
  E_SIGN = true;
  SMART_CA = true;

  _userInfo: UserInformationsItem;
  set userInfo(value) { this._userInfo = value; }
  get userInfo(): UserInformationsItem { return this.controlAccount.userInfo; };

  get partyInfo(): InfoPartyObject { return this.controlAccount.partyInfo; }

  constructor(
    private auth: AuthService,
    private aasAuth: AasAuthService,
    public controlAccount: ControlAccount,
    private modalService: NgbModal,
    private activedRoute: ActivatedRoute,
    private alert: AlertControl
  ) {

  }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.controlAccount.requestAPIGetAccountInfo(this.auth.userId, this.auth.partyId, () => {
      this.controlAccount.getAccountInfo();
      this.reInit();
      
      setTimeout(() => {
        switch (this.tabName) {
          case "VNPT_SIGN":
            this.vnptsignTab.nativeElement.click();
            break;
          case "SMARTCA": 
            this.smartcaTab.nativeElement.click();
            break;
          case "USBTOKEN":
            this.usbtokenTab.nativeElement.click();
            break;
          case "ESIGN":
            this.esignTab.nativeElement.click();
            break;
          default:
            this.usbtokenTab.nativeElement.click();
            break;
        }
      }, 1)
    })
  }

  reInit() {
  }

  // //MARK: Popup thông báo
  // alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
  //   const modalRef = this.modalService.open(AlertComponent);
  //   modalRef.componentInstance.message = msg;
  //   modalRef.componentInstance.typeAlert = type;
  //   modalRef.componentInstance.twoBtn = twoBtn;
  //   modalRef.componentInstance.iconBtn1 = iconBtn1;
  //   modalRef.componentInstance.iconBtn2 = iconBtn2;
  //   modalRef.componentInstance.titleButton1 = titleBtn1;
  //   modalRef.componentInstance.titleButton2 = titleBtn2;
  //   modalRef.componentInstance.callback1 = actBtn1;
  //   modalRef.componentInstance.callback2 = actBtn2;
  // }

  // //MARK: Handle Error
  // handleError(err, errorMsg) {
  //   if (err.error && err.error.message && errorMsg[err.error.message]) {
  //     this.alert(errorMsg[err.error.message], 'error');
  //   } else if (err.error && err.error.error) {
  //     this.alert(err.error.error, 'error');
  //   } else {
  //     this.alert('Lỗi không xác định!', 'error');
  //   }
  // }

  //MARK: Networking
  /**
  * Lấy thông tin user đăng nhập
  * @param id 
  */
  getUserInfo(id) {
    this.aasAuth.getUserInfo(id).subscribe((res) => {
      // this.userInfo = new UserInformationsResponse(res).object;

      console.log(this.userInfo);
    }, err => {
      console.log(err);
      this.alert.showErrorHandled(err, ResponseMsg.MESSAGES.MANAGEMENT_SERVICE);
    }, () => {
    })
  }
}
