
<app-spinner></app-spinner>
<div class="wrapper">
    <div class="preview-overlay">

        <div class="preview-container">
            <div class="preview-header d-flex">
                <div (click)="goToBack()"
                    class="ct-header-btn-back d-flex justify-content-center align-items-center cursor-pointer">
                    <span class="material-icons">arrow_back_ios</span>
                </div>
                <div class="ct-title flex-grow-1 d-flex align-items-center px-3">
                    <h4 class="mb-0"> {{ title }}</h4>
                </div>
                <div class="ct-toolbar-btn d-flex align-items-center">
                    <button (click)="actClose()"
                        class="btn btn-outline-primary d-flex align-items-center mr-3">
                        <span class="material-icons mr-2">close</span>
                        {{ 'btn_cancel' | translate }}
                    </button>
                    <button (click)="actBtnSave()"
                        class="btn btn-primary d-flex align-items-center mr-3">
                        <span class="material-icons mr-2">save</span> 
                        {{ 'btn_save' | translate }}
                    </button>
                </div>
            </div>

            <div class="preview-body">
                <div class="pv ct">
                    <div class="pv-body">
                        <div class="ctpv-container">
                            <div class="ctpv-content">
                                <div class="ct-container">
                                    <div class="ct-thumb-page">
                                        <div class="ct-vs enable-scroll">
                                            <ol id="thumbnail-page" class="ct-vs-list mt-3">
                                            </ol>
                                        </div>
                                    </div>
                                    <div id="ppi"
                                        style="height: 1in; width: 1in; position: absolute; top: -100%; left: -100%;">
                                    </div>
                                    <div class="ct-content">
                                        <div id="parentView" class="ct-doc-loaded enable-scroll">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="ct ctpv-tool-bar">
                            <div class="ctpv-tool-sidebar">
                                <div class="ctpv-tabbar">
                                    <div class="ctpv-main">
                                        <button class="ctpv-btn active"> 
                                            <span class="material-icons-outlined">info</span>
                                        </button>
                                    </div>
                                </div>
                                <div class="ctpv-footer">

                                </div>
                            </div>
                            <div class="ctpv-tool-content">
                                <div class="ctpv-content-wrapper enable-scroll px-3">
                                    <div id="tab-info" class="ctpv-scroll-content">
                                        <div class="row justify-content-center mt-3">
                                            <div class="col-12 tab-step fade show">
                                                <div class="d-flex align-items-center mt-2" style="min-height: 2.4rem">
                                                    <h5 class="font-weight-bold m-0">{{ 'ReviewSignBox_h_1' | translate }}</h5>
                                                </div>

                                                <label class="text-primary text-weigth-bold my-2 mt-3">{{ 'ReviewSignBox_h_2' | translate }}</label>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div class="ec-block-body">
                                                                    <table class="table">
                                                                        <thead>
                                                                            <tr class="bg-tb-header">
                                                                                <th>{{ 'PartList_label_stt' | translate }}</th>
                                                                                <th>{{ 'ServiceContractDetail_page_of_signature' | translate }}</th>
                                                                                <th>{{ 'ServiceContractDetail_action' | translate }}</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr *ngFor="let item of userSignBox.signBoxes, let i = index">
                                                                                <td>{{ i + 1 }}</td>
                                                                                <td>Trang {{ item.page }}</td>
                                                                                <td>
                                                                                    <button class="btn btn-outline-primary mr-2" (click)="viewPositionSignBox(item)">{{ 'ServiceContract_viewPosition' | translate }}</button>
                                                                                    <button class="btn btn-outline-danger mr-2" (click)="userSignBox.removeSignBox(i)">{{ 'btn_delete' | translate }}</button>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div class="ec-block-footer">
                                                                    <a class="text-decoration-underline text-primary cursor-pointer" (click)="userSignBox.addSignBox()">+ {{ 'ServiceContract_addSignPosition' | translate }}</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>