import { Component, OnInit, ViewChild } from '@angular/core';
import { ApprovedConsumerPartnerComponent } from './approved-consumer-partner/approved-consumer-partner.component';
import { RejectedConsumerPartnerComponent } from './rejected-consumer-partner/rejected-consumer-partner.component';

@Component({
  selector: 'app-consumer-partner-verification',
  templateUrl: './consumer-partner-verification.component.html',
  styleUrls: ['./consumer-partner-verification.component.scss']
})
export class ConsumerPartnerVerificationComponent implements OnInit {
  @ViewChild(ApprovedConsumerPartnerComponent) approvedConsumer: ApprovedConsumerPartnerComponent;
  @ViewChild(RejectedConsumerPartnerComponent) rejectConsumer: RejectedConsumerPartnerComponent;
  
  constructor() { }

  ngOnInit(): void {
  }

  handleReloadApprove(e) {
    this.approvedConsumer.reload();
  }

  handleReloadReject(e) {
    this.rejectConsumer.reload();
  }

}
