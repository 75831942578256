<div class="ct-wv-content ct-enable-scroll">
    <div class="ct-wv-container container">
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-md-auto">
                        <h1 class="m-0 text-dark"><label>Danh sách hợp đồng</label></h1>
                    </div>
                    <!-- /.col -->
                    <div class="col-md-auto ml-auto">
                        <!-- <button class="btn btn-outline-primary btn-light d-inline-flex mr-3"><span
                                class="material-icons mr-1">publish</span> Import Excel</button> -->
                                <!-- *showWithRoles="['DRCDOF']" -->
                        <!-- <button *ngIf="controlAccount.isAccLevel2" (click)="addContract()" class="btn btn-primary d-inline-flex mr-3"><span
                                class="material-icons mr-1">add</span> Thêm mới</button> -->
                    </div>
                    <!-- /.col -->
                </div>
                <!-- /.row -->
            </div>
            <!-- /.container-fluid -->
        </div>
        
        <section class="content">
            <div class="container-fluid">
                <div class="content-right-sidebar tab-bar-top-custom">
                    <ul class="nav nav-tabs" id="tabSignAction" role="tablist">
                        <li *ngIf="controlAccount.isAccLevel2" class="nav-item nav-material" [class.active]="controlAccount.isAccLevel2">
                            <a class="nav-link d-flex" [class.active]="controlAccount.isAccLevel2" id="all-tab" data-toggle="pill"
                                href="#all" role="tab" aria-controls="all" [attr.aria-selected]="controlAccount.isAccLevel2" (click)="selectTab(1)">
                                Hợp đồng gửi
                            </a>
                        </li>
                        <li class="nav-item nav-material" [class.active]="!controlAccount.isAccLevel2">
                            <a class="nav-link d-flex" [class.active]="!controlAccount.isAccLevel2" id="waitingForSign-tab" data-toggle="pill" href="#waitingForSign" role="tab"
                                aria-controls="waitingForSign" [attr.aria-selected]="!controlAccount.isAccLevel2" (click)="selectTab(2)">
                                Hợp đồng nhận
                            </a>
                        </li>
                    </ul>
                </div>
                <div class="tab-content contract-tab-body" id="tabSignAction">
                    <!--MARK Hợp đồng đã gửi -->
                    <div class="position-relative tab-pane fade" id="all" role="tabpanel" aria-labelledby="all"
                        [class.show]="controlAccount.isAccLevel2" [class.active]="controlAccount.isAccLevel2">
                        <app-wv-contracts-list #ownerList by="OWNER"></app-wv-contracts-list>
                    </div>
                
                    <!--MARK Hợp đồng đã nhận  -->
                    <div class="position-relative tab-pane fade" id="waitingForSign" role="tabpanel" aria-labelledby="waitingForSign"
                    [class.show]="!controlAccount.isAccLevel2" [class.active]="!controlAccount.isAccLevel2">
                        <app-wv-contracts-list #assigneeList by="ASSIGN"></app-wv-contracts-list>
                    </div>
                </div>
            </div>
        </section>
        
    </div>
</div>