<div #content class="modal-header border-0 d-flex align-items-center">
    <img *ngIf="typeAlert !== ''" class="mr-2" src="{{ imgAlert[typeAlert] }}" width="24" height="24">
    <h5 class="modal-title" style="font-weight: 700; margin-right: auto;">{{ title | translate }}</h5>
    <button *ngIf="!noClose" type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true" class="btn-light btn-alert-exit bg-exit-btn d-inline-flex p-2 rounded">
            <img src="assets/img/icon/icon-close.svg" width="18" height="18">
        </span>
    </button>
</div>
<div class="modal-body text-left py-0 enable-scroll">
    <!-- <img *ngIf="typeAlert !== ''" class="mb-2" src="{{ imgAlert[typeAlert] }}" width="40" height="40"> -->
    <div [innerHTML]="message | translate" class="mb-0"></div>
</div>
<div class="modal-footer border-0 d-flex justify-content-end">
    <button *ngIf="twoBtn" type="button" class="btn btn-outline-primary d-inline-flex"
        (click)="activeModal.close('Close click'); actButton1()"><span *ngIf="iconBtn1" class="material-icons mr-2">{{ iconBtn1 }}</span>{{ titleButton1 | translate }}</button>
    <button type="button" class="btn btn-primary d-inline-flex" [class.ml-3]="twoBtn" ngbAutofocus
        (click)="activeModal.close('Close click'); actButton2()"><span *ngIf="iconBtn2" class="material-icons mr-2">{{ iconBtn2 }}</span>{{ titleButton2 | translate }}</button>
</div>