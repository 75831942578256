export class SignupConsumerObject {
    userId: string = '';
    disable: number = 0;
    daiDien: boolean = false;
    username: string = '';
    created: string = '';
    modified: string = '';
    email: string = '';
    sdt: string = '';
    initPassword: string = '';
    domain: string = '';
    identityLevel: number = 0;

    constructor(obj = null) {
        obj = obj || {};
        this.userId = obj.userId || '';
        this.disable = obj.disable;
        this.daiDien = obj.daiDien || '';
        this.username = obj.username || '';
        this.created = obj.created || '';
        this.modified = obj.modified || '';
        this.email = obj.email || '';
        this.sdt = obj.sdt || '';
        this.initPassword = obj.initPassword || '';
        this.domain = obj.domain || '';
        this.identityLevel = obj.identityLevel;
    }
}