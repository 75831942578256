import { Component, OnInit,ViewChild,ElementRef,Directive } from '@angular/core';
import { Options } from 'select2';
import { Select2OptionData } from 'ng-select2';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { ListUserGroupItem } from 'src/app/utils/models/user-manager/user-groups/list-user-group-item';
import { PermissionGroup } from 'src/app/utils/models/aas-models/business-package-manager/business-package-list/list-all-permission/permission-group'
import { ListAllPermisionReponse } from 'src/app/utils/models/aas-models/business-package-manager/business-package-list/list-all-permission/list-all-permission-response';
import { DualListItem } from 'src/app/utils/models/aas-models/business-package-manager/business-package-list/duaList-item.model'
import { StringUtils } from 'src/app/utils/common/StringUtils';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { ValidateMessage } from 'src/app/utils/common/validate-msg-aas';
import { share } from 'rxjs/operators';
import { NgModalComponent } from 'src/app/layout/extensions/ng-modal/ng-modal.component';
import { LoadingService } from 'src/app/utils/services/loading.service';
import { BusinessPackageService } from 'src/app/utils/services/aas-network/business-package.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { CATEGORY_PACKAFE } from 'src/app/utils/common/constant-vaildate-msg'
import { Router } from '@angular/router';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { StatusCode } from 'src/app/utils/common/status-code';
import { localize } from 'src/app/utils/localize/localize';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';

@Component({
  selector: 'app-create-category-package',
  templateUrl: './create-category-package.component.html',
  styleUrls: ['./create-category-package.component.scss']
})
export class CreateCategoryPackageComponent implements OnInit {

  
  @ViewChild('searchNotAssigned') searchNotAssigned: ElementRef;
  @ViewChild('searchAssigned') searchAssigned: ElementRef;

 

  dropListCategoryPackage:  Array<Select2OptionData> = [
 
    {
      id: 'CHUCNANG',
      text: 'Cấu hình chức năng'
    },
    {
      id: 'HATANG',
      text: 'Hạ tầng'
    }
    
  ];



  listdua : Array<any> =[]
  form: FormGroup;

  loadingServiceIsVisiable = new BehaviorSubject<boolean>(false);
  loadingListUserGroup = new BehaviorSubject<boolean>(false);
  loadingForm = new BehaviorSubject<boolean>(false);

  listPermissionGroup: PermissionGroup[] = [];
  listAssigned: PermissionGroup[] = [];

  formSearch: FormGroup;
  ErrorMsg = new ValidateMessage();

  dualList1: DualListItem[] = [];
  dualList2: DualListItem[] = [];
  selectedAll1: boolean = false;
  selectedAll2: boolean = false;

  baseList: DualListItem[] = new Array<DualListItem>();
  dualList: DualListItem[][] = [new Array<DualListItem>(), new Array<DualListItem>()];   

  oriList: DualListItem[][] = [new Array<DualListItem>(), new Array<DualListItem>()];
  selectedAll = [false, false];
  filter = [false, false];
  assignedAll = [false, false]

  isCheckedDuration = false;
  isCheckedContractNum = false;

  selectAllItem = false;
  selectChild = false;

  public optionUserType: Options;
  public options: Options;

  validateMsg = CATEGORY_PACKAFE;
  isSubmit: boolean = false;
  constructor(
    private fb: FormBuilder,
    private loadingService: LoadingService,
    private modalService: NgbModal,
    private packageService: BusinessPackageService,
    private router: Router,
    private myAlert: AlertControl
  ) { }

  ngOnInit(): void {
    this.makeForm()
    this.getAllPrivileges();
    this.isCheckedDuration = true;
    this.isCheckedContractNum = false;
 
    this.options = {
      multiple: false,
      width: '100%',
      closeOnSelect: true,
      language: 'vi',
    };
  }

  makeForm() {
    this.form = this.fb.group({
      name : ['',Validators.required],
      typeService: ['', Validators.required],
    })
  }

  get name() {
    return this.form.get('name');
  }
  get typeService() {
    return this.form.get('typeService');
  }
 


  loadingListUserGroupIsVisiable(): Observable<boolean> {
    return this.loadingListUserGroup.asObservable().pipe(share());
  }

  loadingFormIsVisiable(): Observable<boolean> {
    return this.loadingForm.asObservable().pipe(share());
  }

  loadingIsVisiable(): Observable<boolean> {
    return this.loadingServiceIsVisiable.asObservable().pipe(share());
  }

  //MARK: Chức năng danh sách, tìm kiếm nhóm người dùng
  /**
   * Tạo from tìm kiếm
   */
  makeFormSearch() {
    this.formSearch = this.fb.group({
      search: new FormControl('')
    });
  }

  get keySearch() { return this.formSearch.get('search'); }

  /**
   * EventEmitter: Sự kiện nhập key trên form search
   * @param event 
   */
  eventInputSearch(event) {
  }
  
  //MARK: Chức năng gán quyền DualList
  setupDualList(listAssigned: PermissionGroup[]) {
    this.dualList[0] = this.cloneObject(this.baseList);
    this.dualList[1] = [];
    this.backupDualList();

    listAssigned.forEach((a, i) => {
      const indexGroup = this.dualList[0].findIndex(res => { return res.id === a.id });
      if (indexGroup >= 0) {
        a.privileges.forEach((b, j) => {
          const indexChild = this.dualList[0][indexGroup].child.findIndex(res => { return res.id === b.id });
          if (indexGroup >= 0 && indexChild >= 0) {
            console.log("OK => idGroup: ", a.id, "; idChild: ", b.id);
            this.assign(indexGroup, indexChild, 0, 1);
          }
        })
      }
    });

    
    this.filter = [false, false];
    this.selectedAll = [false, false];
  }

  /**
   * Set base list tất cả các quyền
   */
  setBaseList() {
    this.listPermissionGroup.forEach((item, index) => {
      this.baseList.push(new DualListItem({
        id: item.id,
        name: item.name,
        index: index
      }))
      this.baseList[index].child = [];

      item.privileges.forEach((item1, index1) => {
        this.baseList[index].child.push(new DualListItem({
          id: item1.id,
          name: item1.description,
          index: index1
        }))
      })
    });
  }

  selectedParentItem(indexGroup, indexList, manualSet = false, defaultValue = false) {
    //show treeview
    this.selectAllItem = true
    this.dualList[indexList][indexGroup].showChild = true;
    if (manualSet) {
      this.dualList[indexList][indexGroup].selected = defaultValue;
    } else {
      this.dualList[indexList][indexGroup].selected = !this.dualList[indexList][indexGroup].selected;
    }

    this.dualList[indexList][indexGroup].child.forEach((item, index) => {
      item.selected = this.dualList[indexList][indexGroup].selected;
    });
    if (!this.dualList[indexList][indexGroup].selected) 
    {
      this.selectedAll[indexList] = false;
      this.selectAllItem = false;
    }
    this.resetStatusItem(indexGroup, indexList);

  }

  selectedChildItem(indexGroup, indexChild, indexList) {
    this.dualList[indexList][indexGroup].child[indexChild].selected = !this.dualList[indexList][indexGroup].child[indexChild].selected;
    if (!this.dualList[indexList][indexGroup].child[indexChild].selected) 
    {
      this.selectedAll[indexList] = false;
      this.selectAllItem = false;
      this.selectChild = true;
    }
    this.resetStatusItem(indexGroup, indexList);
  }

  selectAllBtn(indexList) {
    this.selectedAll[indexList] = !this.selectedAll[indexList];
    this.dualList[indexList].forEach((item, index) => {
      item.selected = this.selectedAll[indexList];
      item.child.forEach((x, index) => {
        x.selected = this.selectedAll[indexList];
      });
    })

    console.log(this.dualList)
  }

  get countAssigned() {
    var count = 0;
    this.dualList[1].forEach((a, i) => {
      a.child.forEach((b, j) => {
        count++;
      });
    });
    return count;
  }

  get countNotAssigned() {
    var count = 0;
    this.dualList[0].forEach((a, i) => {
      a.child.forEach((b, j) => {
        count++;
      });
    });
    return count;
  }

  assign(indexGroup, indexChild, from, to) {
    const parent = this.dualList[from][indexGroup];
    const child = parent.child[indexChild];

    const iParent = this.dualList[to].findIndex(res => {
      return res.id === parent.id;
    })
   
    if(from == 0){
      if (iParent >= 0) {
        child.selected = true;
        const iChild = this.dualList[to][iParent].child.findIndex(res => {
          return res.id === child.id;
        })
        if(iChild < 0){
          this.dualList[to][iParent].child.push(child);          
          this.dualList[from][indexGroup].child[indexChild].assigned = true;
          this.dualList[from][indexGroup].child[indexChild].selected = true;        
        }
        else{           
          this.dualList[from][indexGroup].child[indexChild].assigned = true;
          this.dualList[from][indexGroup].child[indexChild].selected = true;    
        }
      } else {
        this.dualList[to].push(new DualListItem({
          id: parent.id,
          name: parent.name,
          index: parent.index,
          showChild: parent.showChild,
        }));

        this.dualList[to][this.dualList[to].length - 1].child.push(child);        
        this.dualList[from][indexGroup].child[indexChild].assigned = true;
        this.dualList[from][indexGroup].child[indexChild].selected = true;    
      }   
      let item = this.dualList[from][indexGroup].child;
      let numSelected = 0;
      let numAssigned = 0;
      for (var i = item.length - 1; i >= 0; i--) {
        if(this.dualList[from][indexGroup].child[indexChild].selected == true)
        {
          numSelected ++;
        }
        if(this.dualList[from][indexGroup].child[indexChild].assigned == false)
        {
          numAssigned ++;
        }
      }    
      if(numSelected == 0)  
      {
        this.dualList[from][indexGroup].selected = false;
      }
      if(numAssigned == 0)  
      {        
        this.dualList[from][indexGroup].assigned = true;
      } 
    }

   this.logAssigned(parent.id, child.id, from, to);
    
    if(from == 1)
    {  
      this.selectedAll[to] = false    
      const checkGroupID = this.dualList[to].findIndex(res => {
        return res.id === this.dualList[from][indexGroup].id;
      })
      const checkChildID = this.dualList[to][checkGroupID].child.findIndex(res => {
        return res.id === this.dualList[from][indexGroup].child[indexChild].id;
      })
      if((checkGroupID >= 0) && (checkChildID >= 0))
      {
        this.dualList[to][checkGroupID].child[checkChildID].assigned = false;
        this.dualList[to][checkGroupID].child[checkChildID].selected = false;
        this.dualList[from][indexGroup].child.splice(indexChild, 1);
        this.dualList[to][checkGroupID].assigned = false;
        // this.dualList[to][checkGroupID].selected = false;
        if (this.dualList[from][indexGroup].child.length <= 0) {
          this.dualList[from].splice(indexGroup, 1);   
          this.dualList[to][checkGroupID].assigned = false;
          this.dualList[to][checkGroupID].selected = false;
        }
      }
    }
    this.sortList();
  }

  assignMoreSelected(from, to) {
    this.selectedAll[from] = false
    this.selectedAll[to] = false
    this.assignedAll[from] =  false;
    for (var i = this.dualList[from].length - 1; i >= 0; i--) {
      let item = this.dualList[from][i];
      if (item.selected) {
        // item.selected = false;
        if(from == 0){
          item.assigned = true;
        }else{item.assigned = false}

        for (var j = item.child.length - 1; j >= 0; j--) {
          let x = item.child[j];
          if (x.selected) {
            x.selected = false;
            x.assigned = true;
              this.assign(i, j, from, to);
          }
        }
      }
    }

    this.sortList();

    let item = this.dualList[from];
    let numNotSelected = 0;
    let numAssigned = 0;
    for (var i = item.length - 1; i >= 0; i--) {
      if(this.dualList[from][i].selected == false)
      {
        numNotSelected ++;
      }
      if(this.dualList[from][i].assigned == false)
      {
        numAssigned ++;
      }
    }    
    if(numNotSelected == 0)  
    {
      this.selectedAll[from] = true;
    }
    if(numAssigned == 0)  
    {        
      this.assignedAll[from] = true;
    } 
  }

  assignGroup(indexGroup, from, to) {
    let item = this.dualList[from][indexGroup].child;
    for (var i = item.length - 1; i >= 0; i--) {
      this.assign(indexGroup, i, from, to);
    }    
  }

  logAssigned(idGroup, idChild, from, to) {
    console.log("log Assign => idGroup: ", idGroup, "; idChild: ", idChild);
    const indexParent = this.oriList[from].findIndex(res => { return res.id === idGroup; });
    const indexChild = this.oriList[from][indexParent].child.findIndex(res => { return res.id === idChild; });
    const parent = this.oriList[from][indexParent];
    const child = parent.child[indexChild];
    const iParent = this.oriList[to].findIndex(res => { return res.id === idGroup; })

    if (iParent >= 0) {
      child.selected = false;
      this.oriList[to][iParent].child.push(child);
    } else {
      this.oriList[to].push(new DualListItem({
        id: parent.id,
        name: parent.name,
        index: parent.index
      }));

      child.selected = false;
      this.oriList[to][this.oriList[to].length - 1].child.push(child);
    }

    // this.oriList[from][indexParent].child.splice(indexChild, 1);
    // if (this.oriList[from][indexParent].child.length <= 0) {
    //   this.oriList[from].splice(indexParent, 1);
    // }

    this.sortList();
    console.log("ORI AFTER ASSIGNED: ", this.oriList);
  }

  resetStatusItem(indexGroup, indexList) {
    let ok = false;
    this.dualList[indexList][indexGroup].child.forEach((item, index) => {
      if (item.selected) ok = true;
    });
    this.dualList[indexList][indexGroup].selected = ok;

    ok = true;
    this.dualList[indexList].forEach((item, index) => {
      if (!item.selected) ok = false;
      item.child.forEach((x, index) => {
        if (!x.selected) ok = false;
      });
    })

    this.selectedAll[indexList] = ok;
  }

  sortList() {
    this.dualList.forEach((item, index) => {
      item.sort((a, b) => {
        return a.index - b.index;
      });

      item.forEach((x, y) => {
        x.child.sort((a, b) => {
          return a.index - b.index;
        });
      })
    })

    this.oriList.forEach((item, index) => {
      item.sort((a, b) => {
        return a.index - b.index;
      });

      item.forEach((x, y) => {
        x.child.sort((a, b) => {
          return a.index - b.index;
        });
      })
    })
  }

  backupDualList() {
    this.oriList = this.cloneObject(this.dualList);
  }

  cloneObject(object: any) {
    return JSON.parse(JSON.stringify(object));
  }

  enableBtnAssign(indexList): boolean {
    var disable = true;
    this.dualList[indexList].forEach((item) => {
      if (item.selected) disable = false;
    })
    return disable
  }

  filterSearch($event, indexList) {
    let value = StringUtils.removeAccents($event.target.value);
    let filter = [];
    this.filter[indexList] = $event.target.value !== "";
    let self = this;

    if (this.filter[indexList]) {
      this.oriList[indexList].forEach(function (x) {
        let parentOK = false;
        let childOK = false;
        let tmp = self.cloneObject(x);

        let tmp_name = StringUtils.removeAccents(tmp.name);
        if (tmp_name.toUpperCase().match(new RegExp(value.toUpperCase(), "g"))) {
          parentOK = true;
        };

        for (var i = tmp.child.length - 1; i >= 0; i--) {
          let tmp_child_name = StringUtils.removeAccents(tmp.child[i].name);
          if (!(tmp_child_name.toUpperCase().match(new RegExp(value.toUpperCase(), "g")))) {
            tmp.child.splice(i, 1);
          } else {
            childOK = true;
          }
        }
        if (parentOK && !childOK) filter.push(self.cloneObject(x));
        if (parentOK && childOK) filter.push(tmp);
        if (!parentOK && childOK) filter.push(tmp);
      });
      this.dualList[indexList] = this.cloneObject(filter);
    } else {
      this.dualList[indexList] = this.cloneObject(this.oriList[indexList]);
    }

    console.log("-------------------------------------");
    console.log("searched: ", filter);
    console.log("filtered ori: ", this.oriList);
    console.log("filter array: ", this.dualList);
    console.log("-------------------------------------");
  }

  showHideTreeView($event, indexParent, indexList) {
    $event.stopPropagation();
    this.dualList[indexList][indexParent].showChild = !this.dualList[indexList][indexParent].showChild
  }


  /**
   * Tạo param cập nhật quyền cho nhóm người dùng
   * @param id 
   * @param listRole 
   */
  makeParamUpdate( listRole: DualListItem[] = []) {
    var privileges: string[] = [];

    console.log(listRole);

    listRole.forEach((a, i) => {
      a.child.forEach((b, j) => {
        privileges.push(b.id);
      })
    });

    return {
      privileges: privileges
    }
  }

  /**
   * Action lưu thông tin cập nhật
   */
  actSaveInfo() {
    let data = this.form.value;

    this.myAlert.showAlert(localize.catalog_package_confirm_create, ConstantAlertType.BLANK, true, "", localize.btn_confirm, () => {
      var dataPrivileges = this.makeParamUpdate(this.oriList[1]);
      if(this.form.valid){
        let param ={
          "name" : data.name,
          "typeService": data.typeService,
          "privileges": dataPrivileges.privileges,
        }
       this.createPackage(param)
      }
    }, "", localize.btn_cancel);
  }

  // hủy dữ liệu vừa chọn
  clearData() { 
    let msg = localize.catalog_package_cancel_edit;

    this.myAlert.showAlert(msg, ConstantAlertType.BLANK, true, "", localize.btn_confirm, () => {
      this.makeForm();
      this. selectAllBtn(1)
      this.assignMoreSelected(1, 0)
    }, "", localize.btn_cancel);
  }

  /**
   * Get danh sách tất cả các quyền
   */
  getAllPrivileges() {
    var data: ListAllPermisionReponse;
    this.packageService.getAllPrivileges().subscribe(res => {
      data = new ListAllPermisionReponse(res);
      this.listPermissionGroup = data.object;
      this.setBaseList();
      this.dualList[0] = this.cloneObject(this.baseList);
      this.dualList[1] = [];
      this.backupDualList()
    }, err => {
      console.log(err);
      // this.handleError(err, ResponseMsg.MESSAGES.AUTH_SERVICE);
      this.myAlert.showErrorHandled(err);
    });
  }

   /**
   * tạo gói cho nhóm người dùng
   * @param param 
   */
  createPackage(param) {
    this.isSubmit = true;
    this.packageService.postCreateCategoryPackage(param).subscribe(res => {

      this.myAlert.showAlertOnlyNoti(localize.catalog_package_create_success, ConstantAlertType.SUCCESS, "", localize.btn_ok, () => {
        this.makeForm();
        this. selectAllBtn(1)
        this.assignMoreSelected(1, 0)
        this.isSubmit = false;
      });

      this.router.navigate([ConstantUrl.catalog_package_list]);
    }, err => {
      this.myAlert.showErrorHandled(err);
    });
  }
}
