import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { LandingCategoryItem } from 'src/app/utils/models/landing-page/landing-category-item.model';
import { LandingCategoryCollectionResponse } from 'src/app/utils/models/landing-page/landing-page-category-collection-response.model';
import { LandingpageService } from 'src/app/utils/services/aas-network/landingpage.service';

@Component({
  selector: 'app-service-landing-page-category',
  templateUrl: './service-landing-page-category.component.html',
  styleUrls: ['./service-landing-page-category.component.scss']
})
export class ServiceLandingPageCategoryComponent implements OnInit {

  categories: Array<LandingCategoryItem> = [];
  totalElement = 0;

  constructor(
    private landingService: LandingpageService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.getCategories();
  }

  getCategories(){
    this.landingService.getCategories()
    .subscribe(
      (res) => {
        let response = new LandingCategoryCollectionResponse(res);
        this.categories = response.data;
        this.totalElement = response.totalElement;        
      },
      (err) => {
        this.handleError(err, err);
      }
    )
  }

  onDeleteClick(id){
    this.alert(
      "Bạn thực sự muốn xóa?", 
      "", 
      true, 
      "", 
      "Xác nhận", 
      () => {
        this.deleteCategory(id)
      },
      "",
      "Hủy",
      () => {
        console.log('Người dùng hủy hành động');
      })
  }

  deleteCategory(id){
    this.landingService.deleteCategory(id)
    .subscribe(
      (res) => {
         this.alert("Xóa thành công", "success");
         this.getCategories();
      },
      (err) => {
        this.handleError(err, err);
      }
    )
  }

  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], 'error');
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, 'error');
    } else {
      this.alert('Lỗi không xác định! Vui lòng thử lại', 'error');
    }
  }

}
