import { InfoUserObject } from './info-user-object.model';
import { MainResponse } from '../../main-response.model';

export class InfoUserResponse extends MainResponse {
    object: InfoUserObject;
    
    constructor(obj = null) {
        super();
        obj = obj || {};
        this.init(obj);
        this.object = new InfoUserObject(obj.object || {});
    }
}