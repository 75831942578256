<app-spinner></app-spinner>
<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-auto">
                <h1 class="m-0 text-dark"><label>{{ 'VerifySignature_title' | translate }}</label></h1>
            </div>
            <!-- /.col -->
            <div class="col-md-auto ml-auto">
                <!-- <button (click)="addEmployee()" class="btn btn-primary d-inline-flex mr-3"><span
                    class="material-icons mr-1">add</span> Thêm mới</button> -->
            </div>
            <!-- <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>

<!--MARK top bar -->
<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-4">
                <div class="bg-white rounded">
                    <div class="p-3 border-bottom">
                        <h5 class="font-weight-bold"><label>{{ 'VerifySignature_file_have_signature' | translate }}</label></h5>

                        <div class="row">
                            <div class="col-md-12">
                                <div class="group-upload" (click)="fileInput.click()" appDragDrop
                                    (onFileDropped)="uploadFile($event)">
                                    <em class="fas fa-cloud-upload-alt text-grey-darkest" style="font-size: 50px;"></em>
                                    <p class="text-grey-darkest font-weight-bold mt-4 mb-0">{{ 'VerifySignature_dropFile' | translate }}</p>
                                    <button class="btn btn-primary mt-4">{{ 'VerifySignature_chooseFile' | translate }}</button>
                                    <input id="fileInput" hidden type="file" #fileInput
                                        (input)="uploadFile($event.target.files)">
                                </div>
                            </div>
                            <div class="col-md-12 mt-4">
                                <div id="filePreview">
                                    <div class="blockUpload group-uploaded-file">
                                        <div *ngFor="let item of arrFile; let i = index"
                                            class="preview-file rounded border {{ item.process == 100 && item.error == ''? 'border-success' : item.error != '' ? 'border-danger' : item.process < 100 ? 'border-primary' : '' }} p-2 mb-2">
                                            <span class="name-file">{{item.name}}</span>
                                            <div class="d-flex align-items-center">
                                                <div class="progress flex-grow-1 mr-2"
                                                    style="height: 4px; border-radius: 4px;">
                                                    <div class="progress-bar {{ item.process == 100 && item.error == ''? 'bg-success' : item.error != '' ? 'bg-danger' : item.process < 100 ? 'bg-primary' : '' }}"
                                                        role="progressbar" [style.width.%]="item.process"
                                                        attr.aria-valuenow="{{item.process}}" aria-valuemin="0"
                                                        aria-valuemax="100"></div>
                                                </div>
                                                <em class="fa fa-times-circle text-muted" (click)="removeFile(i)"></em>
                                            </div>
                                            <span *ngIf="item.error === ''">{{item.process}}%</span>
                                            <span *ngIf="item.error != ''" class="text-danger">{{item.error}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 p-4">
                            <p class="font-italic text-grey-darkest">{{ 'VerifySignature_verify_tutorials' | translate }}</p>
                            <p class="font-italic text-grey-darkest">{{ 'VerifySignature_verify_tutorials_line_1' | translate }}</p>
                            <p class="font-italic text-grey-darkest">{{ 'VerifySignature_verify_tutorials_line_2' | translate }}</p>
                            <p class="font-italic text-grey-darkest">{{ 'VerifySignature_verify_tutorials_line_3' | translate }}</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-8">
                <div class="bg-white rounded">
                    <div class="p-3">
                        <div class="d-flex">
                            <h5 class="font-weight-bold"><label>{{ 'VerifySignature_info_verify' | translate }}</label></h5>

                            <div class="d-flex ml-auto">
                                <button *ngIf="isVerifyAction" class="btn btn-outline-primary d-flex align-items-center mr-2" (click)="compare()">
                                    <span class="ct-icon-font icon-identity-request mr-2"></span>
                                    {{ 'VerifySignature_btn_commpare_cert' | translate }}
                                </button>
                                <button class="btn btn-outline-primary d-flex align-items-center" (click)="verify()">
                                    <span class="ct-icon-font icon-doc-accept mr-2"></span>
                                    {{ 'VerifySignature_btn_verify' | translate }}
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div *ngIf="isVerifyAction">
                                    <div class="row mb-3">
                                        <div class="col-md-3">
                                            <label class="font-weight-600">{{ 'VerifySignature_verify_name_file' | translate 
                                                }}</label>
                                        </div>
                                        <div class="col-md-9">
                                            <a class="text-primary text-decoration-underline">{{
                                                fileName || "" }}</a>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-md-3">
                                            <label class="font-weight-600">{{ 'VerifySignature_verify_toan_ven_file' | translate 
                                                }}</label>
                                        </div>
                                        <div class="col-md-9">
                                            <span *ngIf="fileInfo.hashok; else hashnotok" class="font-weight-600 text-success">{{ 'VerifySignature_verify_toan_ven_decrip' | translate  }}</span>
                                            <ng-template #hashnotok>
                                                <span class="font-weight-600 text-danger">{{  'VerifySignature_verify_ko_toan_ven_decrip' | translate  }}</span>
                                            </ng-template>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div *ngFor="let item of fileInfo.kySo; let i = index" class="col-12">
                                            <div class="signature-item mt-3">
                                                <div class="signature-item-header" [class.active]="item.expanded" (click)="expand('DSIGN', i)" data-toggle="collapse" [attr.data-target]="'#collapseExample_kyso_' + i" aria-expanded="false" [attr.aria-controls]="'collapseExample_kyso_' + i">
                                                    <div class="signature-item-info">
                                                        <label>{{ item.subject?.common_name }}</label>
                                                        <span>{{ 'VerifySignature_d_sign_type' | translate  }}</span>
                                                    </div>
                                                    <div class="signature-action-expand">
                                                        <span *ngIf="item.expanded; else expandedno" class="material-icons">expand_more</span>
                                                        <ng-template #expandedno>
                                                            <span class="material-icons">expand_less</span>
                                                        </ng-template>
                                                    </div>
                                                </div>
                                                <div class="signature-item-body collapse" [attr.id]="'collapseExample_kyso_' + i">
                                                    <div class="row mb-3">
                                                        <div class="col-md-3">
                                                            <label class="font-weight-600">{{ 'VerifySignature_sign_type' | translate
                                                                }}</label>
                                                        </div>
                                                        <div class="col-md-9">
                                                            <span class="font-weight-600 text-grey-darkest">{{ 'VerifySignature_d_sign_type' | translate }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-3">
                                                        <div class="col-md-3">
                                                            <label class="font-weight-600">{{ 'VerifySignature_sign_by' | translate
                                                                }}</label>
                                                        </div>
                                                        <div class="col-md-9">
                                                            <span class="font-weight-600 text-grey-darkest">
                                                                {{ item.subject?.common_name }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-3">
                                                        <div class="col-md-3">
                                                            <label class="font-weight-600">{{ 'VerifySignature_signer_taxnumber' | translate
                                                                }}</label>
                                                        </div>
                                                        <div class="col-md-9">
                                                            <span class="font-weight-600 text-grey-darkest">
                                                                {{ item.subject?.taxnumber || item.subject?.user_id }}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-3">
                                                        <div class="col-md-3">
                                                            <label class="font-weight-600">
                                                                {{ 'VerifySignature_sign_date' | translate }}
                                                            </label>
                                                        </div>
                                                        <div class="col-md-9">
                                                            <span class="font-weight-600 text-grey-darkest">{{ item.signDate }}</span>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-3">
                                                        <div class="col-md-3">
                                                            <label class="font-weight-600">
                                                                {{ 'VerifySignature_verify_toan_ven' | translate  }}
                                                            </label>
                                                        </div>
                                                        <div class="col-md-9">
                                                            <span *ngIf="item.signatureok; else signaturenotok" class="font-weight-600 text-success">{{  'VerifySignature_verify_signature_ok' | translate  }}</span>
                                                            <ng-template #signaturenotok>
                                                                <span class="font-weight-600 text-danger">{{  'VerifySignature_verify_signature_not_ok' | translate }}</span>
                                                            </ng-template>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="row mb-3">
                                                        <div class="col-md-3">
                                                            <label class="font-weight-600">
                                                                {{  'VerifySignature_signature_trust' | translate }}
                                                            </label>
                                                        </div>
                                                        <div class="col-md-9">
                                                            <span *ngIf="item.certok; else certnotok" class="font-weight-600 text-success">{{   'VerifySignature_verify_signature_trusted' | translate }}</span>
                                                            <ng-template #certnotok>
                                                                <span class="font-weight-600 text-danger">{{ 'VerifySignature_verify_signature_untrust' | translate  }}</span>
                                                            </ng-template>
                                                        </div>
                                                    </div> -->
                                                    <div *ngIf="isCompared" class="row mb-3">
                                                        <div class="col-md-3">
                                                            <label class="font-weight-600">
                                                                {{ 'VerifySignature_result_compare_signature' | translate }}
                                                            </label>
                                                        </div>
                                                        <div class="col-md-9">
                                                            <span *ngIf="item.compareok; else comparenotok" class="font-weight-600 text-success">{{  'VerifySignatureresult_compare_ok' | translate }}</span>
                                                            <ng-template #comparenotok>
                                                                <span class="font-weight-600 text-danger">{{ 'VerifySignatureresult_result_compare_notok' | translate }}</span>
                                                            </ng-template>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div *ngFor="let item of fileInfo.kyDienTu; let i = index" class="col-12">
                                            <div class="signature-item mt-3">
                                                <div class="signature-item-header" [class.active]="item.expanded" (click)="expand('ESIGN', i)" data-toggle="collapse" [attr.data-target]="'#collapseExample_kydientu_' + i" aria-expanded="false" [attr.aria-controls]="'collapseExample_kydientu_' + i">
                                                    <div class="signature-item-info">
                                                        <label>{{ item?.fullName }}</label>
                                                        <span>{{ item.type === "EKYC" ? ('VerifySignatureresult_e_sign_ekyc_type' | translate) : ('VerifySignatureresult_e_sign_otp_type' | translate) }}</span>
                                                    </div>
                                                    <div class="signature-action-expand">
                                                        <span *ngIf="item.expanded; else expandedno" class="material-icons">expand_more</span>
                                                        <ng-template #expandedno>
                                                            <span class="material-icons">expand_less</span>
                                                        </ng-template>
                                                    </div>
                                                </div>
                                                <div class="signature-item-body collapse" [attr.id]="'collapseExample_kydientu_' + i">
                                                    <div class="row">
                                                        <div class="col-md-8">
                                                            <div class="row mb-3">
                                                                <div class="col-md-3">
                                                                    <label class="font-weight-600">{{ 'VerifySignature_sign_type' | translate }}</label>
                                                                </div>
                                                                <div class="col-md-9">
                                                                    <span class="font-weight-600 text-grey-darkest">
                                                                        {{ item.type === "EKYC" ? ('VerifySignatureresult_e_sign_ekyc_type' | translate) : ('VerifySignatureresult_e_sign_otp_type' | translate) }}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-3">
                                                                <div class="col-md-3">
                                                                    <label class="font-weight-600">{{ 'VerifySignatureresult_signer_name' | translate
                                                                        }}</label>
                                                                </div>
                                                                <div class="col-md-9">
                                                                    <span class="font-weight-600 text-grey-darkest">
                                                                        {{ item?.fullName }}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-3">
                                                                <div class="col-md-3">
                                                                    <label class="font-weight-600">{{ 'VerifySignatureresult_signer_phone_number' | translate
                                                                        }}</label>
                                                                </div>
                                                                <div class="col-md-9">
                                                                    <span class="font-weight-600 text-grey-darkest">
                                                                        {{ item?.sdt }}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="item?.cmnd" class="row mb-3">
                                                                <div class="col-md-3">
                                                                    <label class="font-weight-600">{{ 'VerifySignatureresult_signer_personal_id' | translate
                                                                        }}</label>
                                                                </div>
                                                                <div class="col-md-9">
                                                                    <span class="font-weight-600 text-grey-darkest">
                                                                        {{ item?.cmnd }}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-3">
                                                                <div class="col-md-3">
                                                                    <label class="font-weight-600">
                                                                        {{ 'VerifySignature_sign_date' | translate }}
                                                                    </label>
                                                                </div>
                                                                <div class="col-md-9">
                                                                    <span class="font-weight-600 text-grey-darkest">{{ item.signDate }}</span>
                                                                </div>
                                                            </div>
                                                            <div class="row mb-3">
                                                                <div class="col-md-3">
                                                                    <label class="font-weight-600">
                                                                        {{ 'VerifySignature_verify_toan_ven' | translate }}
                                                                    </label>
                                                                </div>
                                                                <div class="col-md-9">
                                                                    <span *ngIf="item.signatureok; else signaturenotok" class="font-weight-600 text-success">{{ 'VerifySignature_verify_signature_ok' | translate }}</span>
                                                                    <ng-template #signaturenotok>
                                                                        <span class="font-weight-600 text-danger">{{  'VerifySignature_verify_signature_not_ok' | translate }}</span>
                                                                    </ng-template>
                                                                </div>
                                                            </div>
                                                            <!-- <div class="row mb-3">
                                                                <div class="col-md-3">
                                                                    <label class="font-weight-600">
                                                                        {{ 'VerifySignature_signature_trust' | translate  }}
                                                                    </label>
                                                                </div>
                                                                <div class="col-md-9">
                                                                    <span *ngIf="item.certok; else certnotok" class="font-weight-600 text-success">{{  'VerifySignature_verify_signature_trusted' | translate  }}</span>
                                                                    <ng-template #certnotok>
                                                                        <span class="font-weight-600 text-danger">{{ 'VerifySignature_verify_signature_untrust' | translate }}</span>
                                                                    </ng-template>
                                                                </div>
                                                            </div> -->
                                                            <div *ngIf="isCompared" class="row mb-3">
                                                                <div class="col-md-3">
                                                                    <label class="font-weight-600">
                                                                        {{ 'VerifySignature_result_compare_signature' | translate }}
                                                                    </label>
                                                                </div>
                                                                <div class="col-md-9">
                                                                    <span *ngIf="item.compareok; else comparenotok" class="font-weight-600 text-success">{{ 'VerifySignatureresult_compare_ok' | translate }}</span>
                                                                    <ng-template #comparenotok>
                                                                        <span class="font-weight-600 text-danger">{{ 'VerifySignatureresult_result_compare_notok' | translate }}</span>
                                                                    </ng-template>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div class="col-md-4">
                                                            <div *ngIf="item.imageSign" class="row mb-3">
                                                                <div class="col-md-12">
                                                                    <label class="font-weight-600">{{ 'VerifySignatureresult_image_signature' | translate
                                                                        }}</label>
                                                                </div>
                                                                <div class="col-md-12 mt-2">
                                                                    <img class="border-primary" [src]="item.imageSign?.link" style="width: 200px; max-width: 100%; height: auto;"/>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="item.imageSigners" class="row mb-3">
                                                                <div class="col-md-12">
                                                                    <label class="font-weight-600">{{ 'VerifySignatureresult_image_signer' | translate
                                                                        }}</label>
                                                                </div>
                                                                <div class="col-md-12 mt-2">
                                                                    <img *ngFor="let item of item.imageSigners" class="rounded mr-2 mb-2" [src]="item.link" style="width: 200px; max-width: 100%; height: auto;"/>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>