import { Component, OnInit, ViewChild } from '@angular/core';
import { EnterpriseVipApprovedComponent } from './enterprise-vip-approved/enterprise-vip-approved.component';
import { EnterpriseVipRejectedComponent } from './enterprise-vip-rejected/enterprise-vip-rejected.component';
import { EnterpriseVipWaitingComponent } from './enterprise-vip-waiting/enterprise-vip-waiting.component';

@Component({
  selector: 'app-service-enterprise-vip-manager',
  templateUrl: './service-enterprise-vip-manager.component.html',
  styleUrls: ['./service-enterprise-vip-manager.component.scss']
})
export class ServiceEnterpriseVipManagerComponent implements OnInit {
  @ViewChild('waiting') scrWaiting: EnterpriseVipWaitingComponent;
  @ViewChild('rejected') scrRejected: EnterpriseVipRejectedComponent;
  @ViewChild('approved') scrApproved: EnterpriseVipApprovedComponent;
  
  constructor() { }

  ngOnInit(): void {
  }

  onReload(event) {
    // alert(event);
    if (event && event.type) {
      switch (event.type) {
        case 'REJECT':
          this.scrRejected.loadPage(1);
          break;
        case 'APPROVE':
          this.scrApproved.loadPage(1);
          break;
      }
    }
  }
}
