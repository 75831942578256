import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { AccountManagerService } from "src/app/utils/services/aas-network/account-manager.service";
import { PersonalDetailObject } from "src/app/utils/models/aas-models/account-manager/personal-detail/personal-detail-object";
import { PersonalDetailResponse } from "src/app/utils/models/aas-models/account-manager/personal-detail/personal-detail-response";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { NgModalComponent } from "src/app/layout/extensions/ng-modal/ng-modal.component";
import {Router} from "@angular/router"
import { AasConsumerManagerService } from 'src/app/utils/services/aas-network/aas-consumer-manager.service';
import { Constant } from './../../../../../utils/common/constant_videocall';
import { localize } from "src/app/utils/localize/localize";
import { AlertControl } from "src/app/utils/alert/alert-control";
import { ConstantAlertType } from "src/app/utils/common/constant-alert-type";

@Component({
  selector: 'app-service-account-edit',
  templateUrl: './service-account-edit.component.html',
  styleUrls: ['./service-account-edit.component.scss']
})
export class ServiceAccountEditComponent implements OnInit {
  partyId = this.router.snapshot.paramMap.get("id");

  localize = localize;

  personalDetailObject : PersonalDetailObject;
  personalDetailResponse: PersonalDetailResponse;
  form: FormGroup;
  isSubmit: boolean = false;

  url: any = null;
  imageFirst: any = null;
  imageSecond: any = null;
  imageUrls: string[] = [];

  validateMsg = {
    tenTaiKhoan: {
      required: localize.FORM_USERNAME_REQUIRED
    }
  };

  constructor(
    private fb: FormBuilder,
    private router: ActivatedRoute,
    private accountManagerService: AccountManagerService,
    private modalService: NgbModal,
    private routerA: Router,
    private aasConsumerManagerService: AasConsumerManagerService,
    private myAlert: AlertControl
  ) {}

  ngOnInit(): void {
    this.detailEnterprise();
    this.form =this.fb.group({
      tenTaiKhoan: ['', Validators.required],
    });
  }

  // get tenTaiKhoan() { return this.form.get('tenTaiKhoan'); }
  get f() { return this.form.controls; }

  detailEnterprise() {
    this.accountManagerService.getPersonalById(this.partyId).subscribe(
      (res) => {
        this.personalDetailResponse= new PersonalDetailResponse(res);
        this.personalDetailObject = this.personalDetailResponse.object;
        this.form.controls['tenTaiKhoan'].setValue(this.personalDetailObject.username);

        this.url = null;
        this.imageFirst = null;
        this.imageSecond = null;
        this.imageUrls = [];
        this.getImage(this.personalDetailObject.userId, 'EKYC_CHANDUNG');
        this.getImage(this.personalDetailObject.userId, 'EKYC_MATTRUOC');
        this.getImage(this.personalDetailObject.userId, 'EKYC_MATSAU');

      },
      (err) => {
        if (!!err.error.error) {
          console.log(err.error.error[0]);
        }
      }
    );
  }


  onClickSave() {
    this.isSubmit = true;
    if (this.form.invalid) {
      return;
    }
    if (this.form.valid) {
      console.log(this.form.value)
      const data ={
        partyId: this.partyId,
        username: this.form.value.tenTaiKhoan
      }
      this.updateAccount(data)
    } else {
      this.isSubmit = false;
    }
  }

  updateAccount(data) {
    this.myAlert.showAlert(this.localize.admin_save_account_info_confirm, ConstantAlertType.BLANK, true, "", localize.btn_confirm, () => {
      this.accountManagerService.postUpdatePerson(data)
      .subscribe(
        (res) => {
          this.myAlert.showAlertOnlyNoti(localize.admin_save_account_info_success, ConstantAlertType.SUCCESS, "", localize.btn_close, () => {
            this.routerA.navigate(['/console/account-manager/detail', this.partyId]);
          })
        },
        (err) => {
          this.myAlert.showErrorHandled(err);
        }
      );
    }, "", localize.btn_close);
  }

  async getImage(id, type) {
    this.aasConsumerManagerService.downloadImage(id, type).subscribe(
      (res) => {
        console.log("image", res);
        if (res) {
          const blob: any = new Blob([res], {
            type: "image/png",
          });
          var reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            if (type === "EKYC_CHANDUNG") {
              this.url = reader.result;
              localStorage.setItem(Constant.IMAGE_FACE, this.url);
            } else if (type === "EKYC_MATTRUOC") {
              this.imageFirst = reader.result;
              this.imageUrls.push(this.imageFirst);
            } else {
              this.imageSecond = reader.result;
              this.imageUrls.push(this.imageSecond);
            }
          };
        }
      },
      (err) => {
        if (type === "EKYC_CHANDUNG") {
          this.url = null;
        } else if (type === "EKYC_MATTRUOC") {
          this.imageFirst = null;
        } else {
          this.imageSecond = null;
        }
      },
      () => {}
    );
  }

}
