export class ReportPackagelItem{
    loaiKhachHang: string;
    goiDichVu: string;
    trangThai: string;
    soLuong: number;
   

    constructor(obj = null) {
        obj = obj || {};
        this.loaiKhachHang = obj.partyType || '';
        this.goiDichVu = obj.packageName || '';
        this.trangThai = obj.disabled || '';
        this.soLuong = Number(obj.partyNumber) || 0;
    }

}