import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DashboardService } from 'src/app/utils/services/aas-network/dashboard/dashboard.service';

@Component({
  selector: 'app-stats-by-time',
  templateUrl: './stats-by-time.component.html',
  styleUrls: ['./stats-by-time.component.scss']
})
export class StatsByTimeComponent implements OnInit {
  nowYear = new Date().getFullYear();

  constructor(private dashboardService: DashboardService) { }

  ngOnInit(): void {
    this.initData(this.nowYear);
    this.initYears();
  }

  years = [];
  yearForm: FormControl = new FormControl(this.nowYear);

  //

  type = 'ColumnChart';
  months=
    {
      "jan": "T1",
      "feb": "T2",
      "mar": "T3",
      "apr": "T4",
      "may": "T5",
      "jun": "T6",
      "jul": "T7",
      "aug": "T8",
      "sep": "T9",
      "oct": "T10",
      "nov": "T11",
      "dec": "T12"
  }
  data = [];
  options = {
    legend: 'none',
    colors: [
      '#567DF4'
    ]
  };
  width = 550;
  height = 400;
  dynamicResize = true;
  initData(year){
    this.dashboardService.getContractStatsByTime(year).subscribe((res:any)=>{
      if(res && res.object instanceof Array){
        this.data = Object.keys(res.object[0]).map((key) => [
          this.months[key],
          res.object[0][key] == null ? 0 : res.object[0][key],
        ]);
      }
    }, error=>{})
  }
  changeYear(event){
    this.initData(event.value)
  }

  // init years from year 2021 to now
  initYears() {
    for(let year = 2021; year <= this.nowYear; year++) {
      this.years.push({"label": year, "value": year});
    }
  }
}
