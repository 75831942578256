import { Injectable } from '@angular/core';
import { ServicePath } from 'src/app/utils/common/constant-service-api';
import { API } from '../../api';

const UPLOAD_DOCUMENT_FILE = ServicePath.MANAGEMENT_SERVICE + "/v2/contracts/upload-documents";
const DOCUMENT = ServicePath.MANAGEMENT_SERVICE + "/contracts";

@Injectable({
  providedIn: 'root'
})
export class DocumentInContractService {

  constructor(
    private api: API
  ) { }

  // upload file
  postUploadFileDocument(formData) {
    const url = UPLOAD_DOCUMENT_FILE;
    return this.api.postMultiPartJSON(url, formData);
  }

  // create document
  createDocument(contractId, data) {
    const url = DOCUMENT + `/${contractId}/create-document`;
    return this.api.post(url, data);
  }

  // edit document
  editDocument(contractId, documentId, data) {
    const url = DOCUMENT + `/${contractId}/edit-document/${documentId}`;
    return this.api.post(url, data);
  }

  // delete document
  deleteDocument(contractId, documentId) {
    const url = DOCUMENT + `/${contractId}/delete-document/${documentId}`;
    return this.api.post(url, null);
  }

  // download document
  downloadDocument(contractId, documentId) {
    const url = DOCUMENT + `/${contractId}/download-documents?documentType=DOCUMENT&fileKey=${documentId}`;
    return this.api.download(url);
  }
}
