export class FileUploadObject {
    etag: string;
    fileKey: string;
    mimeType: string;
    originalName: string;
    size: number;

    constructor(obj = null) {
        obj = obj || {};
        this.etag = obj.etag || '';
        this.fileKey = obj.fileKey || '';
        this.mimeType = obj.mimeType || '';
        this.originalName = obj.originalName || '';
        this.size = Number(obj.size) || null;
    }
}