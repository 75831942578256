export class InfoSignatureConfigObject {
    userId: string;
    signerName: string;
    textColor: string;
    visibleType: number;
    base64Image: string;
    fontSize: number;
    signBy: boolean;
    signDate: boolean;
    certId: string;

    constructor(obj = null) {
        obj = obj || {};
        this.userId = obj.userId || '';
        this.signerName = obj.signerName || '';
        this.textColor = obj.textColor || '';
        this.visibleType = obj.visibleType;
        this.base64Image = obj.base64Image || '';
        this.fontSize = obj.fontSize || '';
        this.signBy = obj.signBy;
        this.signDate = obj.signDate;
        this.certId = obj.certId || '';
    }
}