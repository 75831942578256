import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { DateUtils } from 'src/app/utils/common/DateUtils';
import { REGEXP_DATE_INPUT } from 'src/app/utils/common/regexp';
import { localize } from 'src/app/utils/localize/localize';
import { RequestPreoder } from 'src/app/utils/models/aas-models/one-sme/request-preorder';
import { OneSmeService } from 'src/app/utils/services/aas-network/one-sme/one-sme.service';

@Component({
  selector: 'app-requests-service',
  templateUrl: './requests-service.component.html',
  styleUrls: ['./requests-service.component.scss']
})
export class RequestsServiceComponent implements OnInit {
  @ViewChild('fromDatePicker') _fromDate: ElementRef;
  @ViewChild('toDatePicker') _toDate: ElementRef;
  
  localize = localize;

  formSearch: FormGroup;
  formFilter: FormGroup;
  isSubmit = false;
  dateType = {
    CREATED_FROM: 'CREATED_FROM',
    CREATED_TO: 'CREATED_TO'
  };
  validateSearch = {
    fromDate: {
      pattern: "Sai định dạng ngày tháng năm!",
      minDate: 'Từ ngày không được lớn hơn đến ngày và không được là ngày tương lai!'
    },
    toDate: {
      pattern: "Sai định dạng ngày tháng năm!"
    }
  };
  optionsCleave = {
    date: true,
    delimiter: '/',
    datePattern: ['d', 'm', 'Y']
  };

  currentDate = new Date();

  page: number = 1;
  maxSize: number = 10;
  sort: string = "DESC";
  propertiesSort: string = "modifiedAt";
  totalElement: number = 0;
  listRequest: RequestPreoder[] = [];

  listConfigColTable: Array<any> = [
    {
      label: localize.company_name,
      name: "tenDoanhNghiep"
    },
    {
      label: localize.tax_number,
      name: "maSoThue"
    },
    {
      label: localize.representative,
      name: "nguoiDaiDien"
    },
    {
      label: localize.recieve_date,
      name: "ngayTiepNhan"
    },
    {
      label: localize.handler,
      name: "nguoiXuLy"
    },
    {
      label: "Trạng thái",
      name: "status"
    },
  ];
  configColTable: any = {
    status: true,
    tenDoanhNghiep: true,
    maSoThue: true,
    nguoiDaiDien: true,
    ngayTiepNhan: true,
    nguoiXuLy: true,
    trangThai: true
  }

  sortColConfig = {
    title: null,
    partnersCount: null,
    modified: false,
    currentStage: null,
  }

  statusList = [
    { id: "", name: "Tất cả" },
    { id: "SME_RECEIVERED", name: "Đã tiếp nhận" },
    { id: "SME_INPROCESS", name: "Đang xử lý" },
    { id: "SME_DONE", name: "Hoàn thành" },
    { id: "SME_ERROR", name: "Lỗi kích hoạt" }
  ]

  // SME_RECEIVERED,
  // SME_INPROCESS,
  // SME_DONE,
  // SME_ERROR,

  constructor(
    private fb: FormBuilder,
    private oneSmeApi: OneSmeService,
    private myAlert: AlertControl,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.makeFormSearch();
    this.makeFormFilter();

    this.loadData(1);
  }

  //MARK: Khởi tạo form search
  makeFormSearch() {
    this.formSearch = this.fb.group({
      search: new FormControl('')
    });
  }
  get keySearch() { return this.formSearch.get('search'); }

  /**
   * Khoi tao form filter
   */
  makeFormFilter() {
    this.formFilter = this.fb.group({
      customerType: new FormControl(''),
      toDate: new FormControl('', [Validators.pattern(REGEXP_DATE_INPUT)]),
      fromDate: new FormControl('', [Validators.pattern(REGEXP_DATE_INPUT)]),
      status: new FormControl('')
    });
  }

  actConfigColTableView(e, field) {
    e.stopPropagation();
    if (this.configColTable[field] !== null && this.configColTable[field] !== undefined) {
      this.configColTable[field] = !this.configColTable[field];
    }
  }

  /**
   * Thay doi ngay tren filter
   * @param value 
   * @param type 
   */
  changeDateFilter(event, type) {
    switch (type) {
      case this.dateType.CREATED_FROM:
        this.formFilter.controls.fromDate.setValue(DateUtils.convertObjtoDate(event));
        break;
      case this.dateType.CREATED_TO:
        this.formFilter.controls.toDate.setValue(DateUtils.convertObjtoDate(event));
        break;
    }
  }

  get fromDate() {
    return DateUtils.convertInputToFormat(this.formFilter.value.fromDate, DateUtils.format.API);
  }
  get toDate() {
    return DateUtils.convertInputToFormat(this.formFilter.value.toDate, DateUtils.format.API);
  }

  cleanFilter() {
    this.isSubmit = false;
    this._fromDate.nativeElement.value = '';
    this._toDate.nativeElement.value = '';
    this.formFilter.reset();
    this.formFilter.controls.customerType.setValue('');
    this.formFilter.controls.fromDate.setValue('');
    this.formFilter.controls.toDate.setValue('');
    this.formFilter.controls.status.setValue('');
    this.formSearch.reset();
    this.searchWithFilter()
  }

  searchWithFilter() {
    this.isSubmit = true;

    if (this.formFilter.valid) {
      let dateFrom = this.formFilter.value.fromDate.length > 0 ? new Date(DateUtils.convertStringtoDateSearch(this.formFilter.value.fromDate)).getTime() : -1;
      let dateTo = this.formFilter.value.toDate.length > 0 ? new Date(DateUtils.convertStringtoDateSearch(this.formFilter.value.toDate)).getTime() : -1;
      // console.log('from => ', dateFrom, ' to => ', dateTo);
      if ((dateTo > 0 && dateFrom > 0 && dateFrom > dateTo)) {
        this.formFilter.controls.fromDate.setErrors({ minDate: true });
      }
    }

    if (this.formFilter.valid) {
      console.log(this.formFilter.value);
      this.loadData();
    }
  }

  /**
   * Sap xem theo cot
   * @param key 
   */
  sortCol(key) {
    if (this.sortColConfig[key] == null) {
      this.sortColConfig[key] = true;
    } else if (this.sortColConfig[key] == false) {
      this.sortColConfig[key] = null;
    } else {
      this.sortColConfig[key] = !this.sortColConfig[key];
    }

    // this.loadData(this.page);
  }

  actSearch(event) {
    this.page = 1;
    this.loadData();
  }

  loadData(page = 1) {
    this.page = page;
    this.getRequestPreoder(
      this.page, 
      this.maxSize, 
      this.propertiesSort, 
      this.sort, 
      this.formSearch.value.search, 
      this.fromDate, 
      this.toDate, 
      this.formFilter.value.status);
  }

  gotoDetail(id) {
    this.router.navigate([ConstantUrl.requests_service_detail, id]);
  }

  getRequestPreoder(page, maxSize, propertiesSort, sort, fullText, fromDate, toDate, orderStatus) {
    this.oneSmeApi.getRequestPreoder(page, maxSize, propertiesSort, sort, fullText, fromDate, toDate, orderStatus).subscribe((res: any) => {
      console.log(res);
      this.listRequest = [];
      if (res.object?.data && res.object?.data instanceof Array) {
        res.object?.data.forEach((x) => {
          this.listRequest.push(new RequestPreoder(x));
        })
      }
      this.totalElement = res.object?.totalElement || 0;
    }, err => {
      this.myAlert.showErrorHandled(err);
    });
  }

  getNameStatus(status) {
    switch (status) {
      case "SME_RECEIVERED":
        return "Đã tiếp nhận";
      case "SME_INPROCESS":
        return "Đang xử lý";
      case "SME_DONE":
        return "Hoàn thành";
      case "SME_ERROR":
        return "Lỗi kích hoạt";
      default:
        return "";
    }
  }

  getStyleStatus(status) {
    switch (status) {
      case "SME_RECEIVERED":
        return "badge-primary-new";
      case "SME_INPROCESS":
        return "badge-warning-new";
      case "SME_DONE":
        return "badge-success-new";
      case "SME_ERROR":
        return "badge-danger-new";
      default:
        return "badge-light";
    }
  }
}
