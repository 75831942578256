<div class="card shadow-none">
    <div class="card-body">
        <div class="row">
            <div class="col-md-auto align-self-end">
                <label class="text-lg">{{ 'SignUpV2StepTwo_bo_sung_tt' | translate }}</label>
            </div>
        </div>
        <hr class="py-2 mt-2">
        <div class="">
            <app-signup-consumer *ngIf="isUserType(constantUserType.CONSUMER, userType || '')" [dieukhoan]="dieuKhoan" (next)="handleNext($event)" (back)="handleBack($event)"></app-signup-consumer>
            <app-signup-business *ngIf="isUserType(constantUserType.BUSINESS, userType || '')" [dieukhoan]="dieuKhoan" (next)="handleNext($event)" (back)="handleBack($event)"></app-signup-business>
            <app-signup-enterprise *ngIf="isUserType(constantUserType.ENTERPRISE, userType || '')" [dieukhoan]="dieuKhoan" (next)="handleNext($event)" (back)="handleBack($event)"></app-signup-enterprise>
        </div>
    </div>
</div>