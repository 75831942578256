export class ListSignatureObject {
    kyDienTu: Array<ESignature> = [];
    kySo: Array<DSignature> = [];

    constructor(data = null) {
        data = data ||{};
        
        if (data.kyDienTu && data.kyDienTu instanceof Array) {
            data.kyDienTu.forEach(item => {
                this.kyDienTu.push(new ESignature(item));
            });
        }

        if (data.kySo && data.kySo instanceof Array) {
            data.kySo.forEach(item => {
                this.kySo.push(new DSignature(item));
            });
        }
    }
}


export class ESignature {
    fullname: string = '';
    signDate: string = '';
    idCard: string = '';
    base64Image: string = '';

    constructor(data = null) {
        data = data || {};
        this.fullname = data.fullname || '';
        this.signDate = data.signDate || '';
        this.idCard = data.idCard || '';
        this.base64Image = data.base64Image || '';
    }
}

export class DSignature {
    issuer: Issuer;
    subject: Subject;
    not_after: string = '';
    not_before: string = '';
    signDate: string = '';

    constructor(data = null) {
        data = data || {};
        this.issuer = new Issuer(data.issuer);
        this.subject = new Subject(data.subject);
        this.not_after = data.not_after || '';
        this.not_before = data.not_before || '';
        this.signDate = data.signDate || '';
    }
}

export class Issuer {
    common_name: string = '';
    country_name: string = '';
    organization_name: string = '';

    constructor(data = null) {
        data = data || {};
        this.common_name = data.common_name || '';
        this.country_name = data.country_name || '';
        this.organization_name = data.organization_name || '';
    }
}

export class Subject {
    common_name: string = '';
    country_name: string = '';
    locality_name: string = '';
    organization_name: string = '';
    state_or_province_name: string = '';
    user_id: string = '';

    constructor(data = null) {
        data = data || {};
        this.common_name = data.common_name || '';
        this.country_name = data.country_name || '';
        this.locality_name = data.locality_name || '';
        this.organization_name = data.organization_name || '';
        this.state_or_province_name = data.state_or_province_name || '';
        this.user_id = data.user_id || '';
    }
}
