export class TokenItem {
    access_token: string;
    expires_in: Number;
    refresh_token: string;
    scope: string;
    token_type: string;

    constructor(obj = null) {
        obj = obj || {};
        this.access_token = obj.access_token || '';
        this.expires_in = obj.expires_in;
        this.refresh_token = obj.refresh_token || '';
        this.scope = obj.scope || '';
        this.token_type = obj.token_type || '';
    }
}