<app-spinner></app-spinner>
<div class="landingpage">
    <div class="register">
        <section class="register-ss1">
            <img src="assets/img/home/v4/Image/Logo eContract_change color.png" alt="" class="img-logo-ipadpro">
            <div class="content col-12 p-0">
                <div class="row swap-col">
                    <div class="col-auto-new p-0">
                        <div class="row">
                            <div class="col-12">
                                <div class="content-left">
                                    <div class="row">
                                        <div class="col-left-auto">
                                            <div class="body">
                                                <span class="logo block">
                                                    MỘT SẢN PHẨM CỦA TẬP ĐOÀN
                                                    <img src="../../../assets/img/home/v4/Image/logo vnpt.png" alt="">
                                                </span>
                                                <p class="title">
                                                    Hợp đồng điện tử<br />
                                                    VNPT eContract
                                                </p>
                                                <span class="dec">
                                                    Cung cấp dịch vụ hợp đồng điện tử cho phép các bên giao kết các loại
                                                    hợp đồng
                                                    bằng phương tiện điện tử.
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-left-auto">
                                            <img src="assets/img/home/v4/Image/Group 177362.png" alt=""
                                                class="d-xl-block imgbg">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-auto-new p-0">
                        <div class="content-right">
                            <div class="row justify-content-center">
                                <div class="col-10 col-md-6 col-xl-9">
                                    <div class="body">
                                        <img src="assets/img/home/v4/Image/LOGO261.png" alt="" class="img-logo">
                                        <h3 class="title">{{ 'ServiceContractDetail_xacNhanOTP' | translate }}</h3>
                                        <div *ngIf="destinationOfOtp != null">
                                            <span *ngIf="emailOrPhoneNumber" class="note pb-4">
                                                {{ 'ServiceDashboardEnduser_greeting' | translate }} <span
                                                    class="username">{{ username }}</span>! <br>
                                                {{ 'VerifyOTPLogin_label_3' | translate : {email: destinationOfOtp} }}
                                            </span>
                                            <span *ngIf="!emailOrPhoneNumber" class="note pb-4">
                                                {{ 'ServiceDashboardEnduser_greeting' | translate }} <span
                                                    class="username">{{ username }}</span>! <br>
                                                {{ 'VerifyOTPLogin_label_4' | translate : {phoneNumber:
                                                destinationOfOtp} }}
                                            </span>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-10 col-md-6 col-xl-9">
                                    <!-- <label for="">khách hàng</label>
                                    <ul class="nav nav-tabs" role="tablist">
                                        <li class="nav-item">
                                            <a class="nav-link active" href="#profile" role="tab" data-toggle="tab">
                                                <img src="./icon/user.png" alt="">
                                                Cá nhân</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" href="#buzz" role="tab" data-toggle="tab">
                                                <img src="./icon/Group.png" alt="">
                                                Tổ chức</a>
                                        </li>
                                    </ul> -->

                                    <div class="login-form">
                                        <form [formGroup]="formOTP">
                                            <div class="form-group">
                                                <!-- <label for="">{{ 'Login_account' | translate }}<span class="text-danger">*</span></label> -->
                                                <input appOnlyNumber class="form-control" formControlName="otp"
                                                    placeholder="{{ 'OtpForgotPassword_h_4' | translate }}" type="text"
                                                    maxlength="6">
                                                <!-- <app-show-validate-errors [isSubmit]="isSubmit"
                                                    [errorMessages]="ServiceMsg.otp" [control]="otp"
                                                    [detail]="{ name: 'otp' }">
                                                </app-show-validate-errors> -->
                                            </div>

                                            <h6 *ngIf="resend == false" class="footer-otp">{{ 'OtpForgotPassword_h_1' |
                                                translate }} <a *ngIf="count === 0" (click)="resendOTP()"
                                                    class="cursor-pointer text-primary text-decoration-underline">{{
                                                    'OtpForgotPassword_h_2' | translate }}</a>
                                                <span class="text-muted" *ngIf="count > 0">{{ 'VerifyOTPLogin_label_1' |
                                                    translate: {counter: counter$ | async} }}</span>
                                            </h6>
                                            <span *ngIf="resend == true" class="note">
                                                {{ 'VerifyOTPLogin_label_2' | translate }}
                                            </span>


                                            <button [disabled]="disabledButton" (click)="verifyOTP()"
                                                [ngClass]="{'disabled-button': disabledButton}" class="btn_reg">{{
                                                'btn_continue' | translate }}</button>
                                        </form>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
<a href="" class="btn-backhome">
    <img src="assets/img/home/v4/icon/backicon.png" alt="">
</a>