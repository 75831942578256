export class UserInformationsItem {
    userId: string;
    disable: string;
    daiDien: boolean;
    created: string;
    modified: string;
    ten: string;
    chucVu: string;
    cmnd: string;
    ngayCap: string;
    noiCap: string;
    ngaySinh: string;
    gioiTinh: string;
    gioiTinhId: number;
    soNha: string;
    duong: string;
    thanhPho: string;
    diaChiThuongTru: string;
    email: string;
    sdt: string;
    partyId: string;
    tinh: string;
    tinhId: any;
    huyen: string;
    huyenId: any;
    xa: string;
    xaId: any;

    constructor(obj = null) {
        obj = obj || {};
        this.userId = obj.userId || '';
        this.disable = obj.disable || false;
        this.daiDien = obj.daiDien || '';
        this.created = obj.created || '';
        this.modified = obj.modified || '';
        this.ten = obj.ten || '';
        this.chucVu = obj.chucVu || '';
        this.cmnd = obj.cmnd || '';
        this.ngayCap = obj.ngayCap || '';
        this.noiCap = obj.noiCap || '';
        this.ngaySinh = obj.ngaySinh || '';
        this.gioiTinh = obj.gioiTinh || '';
        this.gioiTinhId = Number(obj.gioiTinhId) || null;
        this.soNha = obj.soNha || '';
        this.duong = obj.duong || '';
        this.thanhPho = obj.thanhPho || '';
        this.email = obj.email || '';
        this.sdt = obj.sdt || '';
        this.partyId = obj.partyId || '';
        this.tinhId = obj.tinhId;
        this.tinh = obj.tinh || '';
        this.huyen = obj.huyen || '';
        this.huyenId = obj.huyenId;
        this.xa = obj.xa || '';
        this.xaId = obj.xaId;

        this.diaChiThuongTru = obj.diaChiThuongTru || this.diaChi || '';
    }

    get diaChi() {
        var diaChi = '';
        if (this.soNha !== '') {
            diaChi += this.soNha + ' ';
        }

        if (this.duong !== '') {
            diaChi += this.duong + ', ';
        }

        if (this.xa !== '') {
            diaChi += this.xa + ', ';
        }

        if (this.huyen !== '') {
            diaChi += this.huyen + ', ';
        }

        if (this.tinh !== '') {
            diaChi += this.tinh + '.';
        }

        return diaChi;
    }
}