import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertMsg } from 'src/app/utils/common/constant-alert-msg';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { DateUtils } from 'src/app/utils/common/DateUtils';
import {
  REGEXP_DATE_INPUT,
  REGEXP_EMAIL,
  REGEXP_NAME,
  REGEXP_PHONE_NUMBER,
  REGEXP_USERNAME,
  REGEX_PARY_NAME,
} from 'src/app/utils/common/regexp';
import { StringUtils } from 'src/app/utils/common/StringUtils';
import { ValidatorsExtension } from 'src/app/utils/common/validators-extension';
import { localize } from 'src/app/utils/localize/localize';
import { PackageDetail } from 'src/app/utils/models/aas-models/package-manager/package-detail';
import { ListHuyenResponse } from 'src/app/utils/models/catalog/list-huyen/list-huyen-response.model';
import { ListTinhResponse } from 'src/app/utils/models/catalog/list-tinh/list-tinh-response.model';
import { ListXaResponse } from 'src/app/utils/models/catalog/list-xa/list-xa-response.model';
import { AccountManagerService } from 'src/app/utils/services/aas-network/account-manager.service';
import { BusinessPackageService } from 'src/app/utils/services/aas-network/business-package.service';
import { CatalogService } from 'src/app/utils/services/aas-network/catalog.service';
import { PackageServiceService } from 'src/app/utils/services/aas-network/package-service/package-service.service';
import { TemplateService } from 'src/app/utils/services/aas-network/template.service';

declare var $: any;
@Component({
  selector: 'app-add-business-customer',
  templateUrl: './add-business-customer.component.html',
  styleUrls: ['./add-business-customer.component.scss'],
})
export class AddBusinessCustomerComponent implements OnInit, AfterViewInit {
  @ViewChild('birthday') _birthday: ElementRef;
  localize = localize;

  form: FormGroup;
  isSubmit = false;
  validateMsg = {
    username: {
      required: 'Vui lòng nhập tên tài khoản',
      pattern:
        'Tên tài khoản phải viết liền không dấu, có ít nhất 6-50 ký tự, không chứa ký tự khoảng trống',
      unique: 'Tên đăng nhập đã tồn tại',
    },
    identityLevel: {
      required: 'Vui lòng chọn loại tài khoản',
    },
    tb_code: {
      required: 'Vui lòng nhập mã thuê bao',
    },
    tb_type: {
      required: 'Vui lòng nhập loại thuê bao',
    },
    tinh_code: {
      required: 'Vui lòng nhập mã tỉnh',
      maxlength: 'Mã tỉnh không được dài quá 10 ký tự',
    },
    fullName: {
      required: 'Vui lòng nhập họ và tên người đại diện',
      pattern: 'Họ và tên chỉ cho phép các ký tự chữ, số và khoảng trống',
    },
    hrm_am: {
      required: 'Vui lòng nhập mã HRM AM',
    },
    email: {
      required: 'Vui lòng nhập email',
      pattern: 'Email sai định dạng',
    },
    shortName: {
      required: 'Vui lòng nhập tên viết tắt',
      pattern:
        'Tên viết tắt chỉ cho phép các ký tự chữ, số, khoảng trống và một số ký tự đặc biệt: % & , ( ) . - + : \\ / ; "',
    },
    taxNumber: {
      required: 'Vui lòng nhập mã số thuế',
      pattern: 'Sai định dạng mã số thuế',
    },
    partyName: {
      required: 'Vui lòng nhập tên doanh nghiệp',
      pattern:
        'Tên doanh nghiệp chỉ cho phép các ký tự chữ, số, khoảng trống và một số ký tự đặc biệt: % & , ( ) . - + : \\ / ; "',
    },
    phoneNumber: {
      required: 'Vui lòng nhập số điện thoại',
      pattern: 'Số điện thoại sai định dạng',
    },
    birthday: {
      required: 'Vui lòng nhập ngày sinh',
      pattern: 'Ngày sinh sai định dạng',
    },
    tinhId: {
      required: 'Vui lòng chọn Tỉnh/Thành phố',
    },
    huyenId: {
      required: 'Vui lòng chọn Quận/Huyện',
    },
    xaId: {
      required: 'Vui lòng chọn Xã/Phường',
    },
    duong: {
      required: 'Vui lòng nhập đường',
    },
    sonha: {
      required: 'Vui lòng nhập số nhà',
    },
    file: {
      required: 'Vui lòng đính kèm file',
      type: 'File không đúng định dạng',
    },
    packageId: {
      required: 'Vui lòng chọn gói cước',
    },
    gioiTinhId: {
      required: 'Vui lòng chọn giới tính',
    },
  };

  inputFileLabel = localize.select_attach_file;

  identityLevels = [
    { id: 1, label: 'Tài khoản định danh cấp 1' },
    { id: 2, label: 'Tài khoản định danh cấp 2' },
  ];
  packageIds = [];
  trialPackageIds = [];
  fullPackageIds = [];

  optionsCleave = {
    date: true,
    delimiter: '/',
    datePattern: ['d', 'm', 'Y'],
  };
  currentDate = new Date();

  tinhs = [];
  xas = [];
  huyens = [];

  showFile = false;
  pdfFile = null;
  attachFile = null;
  fileType = [
    'image/jpeg',
    'image/png',
    'image/jpg',
    'application/pdf',
    // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ];

  gioiTinhs = [
    { id: 1, label: 'Nữ' },
    { id: 2, label: 'Nam' },
  ];

  showCustomizeInfraInfo = false;
  packageInfo: PackageDetail = null;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private accApi: AccountManagerService,
    private catalog: CatalogService,
    private templateApi: TemplateService,
    private myAlert: AlertControl,
    private packageApi: PackageServiceService,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.viewDidLoad();
  }

  ngAfterViewInit(): void {
    // $('input#fullNameInput').on('keypress', function (event) {
    //   var regex = new RegExp(/^[a-zA-Z0-9\s]+$/g);
    //   var value = StringUtils.replaceUnicodeChar(event.target.value);
    //   if (!regex.test(value)) {
    //      event.preventDefault();
    //      return false;
    //   }
    // });
    console.log(this._birthday);

    $('input#fullNameInput').on('input', function (event) {
      var regex = new RegExp(/^[a-zA-Z0-9\s]+$/g);
      var value = StringUtils.replaceUnicodeChar(event.target.value);
      var old_value = $(this).val();
      console.log(old_value, value);
      if (!regex.test(value)) {
        // $(this).val(old_value);
        event.preventDefault();
        return false;
      }
    });
    this.cd.detectChanges();
  }

  viewDidLoad() {
    this.makeForm();
    this.getDmTinh();
    this.getDataPackage(1, 10000, 'created', '', '', 'BUSINESS');
  }

  makeForm() {
    this.form = this.fb.group({
      username: ['', [Validators.required, Validators.pattern(REGEXP_USERNAME)]],
      identityLevel: [null, [Validators.required]],
      packageId: [null, [Validators.required]],
      tb_code: ['', [Validators.required]],
      hrm_am: ['', [Validators.required]],
      tb_type: ['', [Validators.required]],
      tinh_code: ['', [Validators.required, Validators.maxLength(10)]],
      partyName: ['', [Validators.required, Validators.pattern(/^[%&,().\-+.\\/;"\p{L}\s\d]*$/u)]],
      email: ['', [Validators.required, Validators.pattern(REGEXP_EMAIL)]],
      shortName: [
        '',
        [Validators.required, Validators.pattern(/^[ \w\%\&\,\(\)\.\-\+\:\\\/\;\"]*$/)],
      ],
      taxNumber: ['', [Validators.required, Validators.pattern(/^\d{10}-\d{3}$|^\d{10}$/i)]],
      fullName: ['', [Validators.required, ValidatorsExtension.validateFullName]],
      phoneNumber: ['', [Validators.required, Validators.pattern(REGEXP_PHONE_NUMBER)]],
      birthday: ['', [Validators.required, Validators.pattern(REGEXP_DATE_INPUT)]],
      tinhId: [null, [Validators.required]],
      huyenId: [null, [Validators.required]],
      xaId: [null, [Validators.required]],
      duong: ['', [Validators.required]],
      sonha: ['', [Validators.required]],
      file: [null, []],
      gioiTinhId: [1, [Validators.required]],
      hrm_creator: [''],
    });
  }

  onAttachFileChanged(event) {
    let file = null;
    file = event.target.files && event.target.files.item(0);
    if (file && file !== null && file !== undefined) {
      if (!this.fileType.includes(file.type)) {
        this.form.controls.file.setErrors({ type: true });
        this.inputFileLabel = file.name;
      } else if (file.size > 5000000) {
        this.myAlert.showAlertOnlyNoti(localize.file_size_too_large, '');
      } else {
        this.form.controls.file.setValue(file);
        this.inputFileLabel = file.name;

        if (file.type === 'application/pdf') {
          this.pdfFile = file;
        } else {
          let data = new FormData();
          data.append('attachFile', file);
          this.postConvertToPDF(data);
        }
      }
    } else {
      this.pdfFile = null;
      this.form.controls.file.setErrors(null);
      if (this.form.get('identityLevel').value == 2) {
        this.form.controls.file.setErrors({ required: true });
      }
      this.inputFileLabel = localize.select_attach_file;
    }
  }

  showFilePreview() {
    this.showFile = true;
  }

  closePreview() {
    this.showFile = false;
  }

  changedIdentityLevel(event) {
    if (event) {
      if (event.id === 1) {
        this.form.controls['file'].clearValidators();
        this.form.controls['file'].updateValueAndValidity();
        // this.form.controls['packageId'].setValue(this.getDefaultLevel1());
        this.packageIds = this.trialPackageIds;
      } else {
        this.form.controls['file'].clearValidators();
        this.form.controls['file'].setValidators([Validators.required]);
        this.form.controls['file'].updateValueAndValidity();
        this.packageIds = this.fullPackageIds;
      }
    }
  }

  getDefaultLevel1() {
    let x = this.packageIds.find((x) => {
      return x.packageType === 'TRIAL_TYPE' && x.packageCode === 'BUSINESS_1';
    });
    if (x) {
      return x.packInfoId;
    } else {
      return '';
    }
  }

  changeDateFilter(event) {
    this.form.controls.birthday.setValue(DateUtils.convertObjtoDate(event));
  }

  changedTinh(event) {
    if (event) {
      this.form.controls.huyenId.setValue(null);
      this.form.controls.xaId.setValue(null);
      this.getDmHuyen(event.id);
    }
  }

  changedHuyen(event) {
    if (event) {
      this.form.controls.xaId.setValue(null);
      this.getDmXa(event.id);
    }
  }

  cancel() {
    this.router.navigate(['console/account-manager']);
  }

  save() {
    this.isSubmit = true;
    if (this.form.valid) {
      this.myAlert.showAlert(
        localize.confirm_add_account_customer,
        '',
        true,
        '',
        localize.btn_confirm,
        () => {
          this.addAccount();
        },
        '',
        localize.btn_cancel,
        null,
        localize.confirm
      );
    }
  }
  reset() {
    // this.form.reset()
    // setTimeout(() => {
    //   this.form.controls.birthday.setValue('');
    // }, 300);
    this.form.patchValue({
      username: '',
      identityLevel: null,
      packageId: null,
      tb_code: '',
      hrm_am: '',
      tb_type: '',
      tinh_code: '',
      partyName: '',
      email: '',
      shortName: '',
      taxNumber: '',
      fullName: '',
      phoneNumber: '',
      tinhId: null,
      huyenId: null,
      xaId: null,
      duong: '',
      sonha: '',
      gioiTinhId: 1,
      hrm_creator: '',
      // birthday: '',
    });
    // this._birthday.nativeElement.value = '';
    this.pdfFile = null;
    this.form.controls.file.setErrors(null);
    this.inputFileLabel = localize.select_attach_file;
  }

  addAccount() {
    let data = new FormData();
    if (this.form.value.file) {
      data.append('files', this.form.value.file, this.form.value.file.name || this.inputFileLabel);
    }
    data.append('partyType', 'BUSINESS');

    let packageData = {
      packageId: this.form.value.packageId || '',
      infrastructures: this.packageInfo.listPackageInfrastructure.infrastructures.map((x) => {
        return { param: x.param, unitNumber: `${x.unitNumber || 0}` };
      }),
      price: this.packageInfo.totalPrice || 0,
    };

    let daiDien = {
      username: this.form.value.username || '',
      ten: this.form.value.fullName || '',
      sdt: this.form.value.phoneNumber || '',
      ngaySinh: this.form.value.birthday || '',
      gioiTinhId: this.form.value.gioiTinhId || null,
      tinhId: this.form.value.tinhId,
      huyenId: this.form.value.huyenId,
      xaId: this.form.value.xaId,
      duong: this.form.value.duong || '',
      soNha: this.form.value.sonha || '',
    };

    let toChuc = {
      tenToChuc: this.form.value.partyName || '',
      tenRutGon: this.form.value.shortName || '',
      email: this.form.value.email || '',
      maSoThue: this.form.value.taxNumber || '',
      packageData: packageData,
      identityLevel: this.form.value.identityLevel || null,
      provinceCode: this.form.value.tinh_code || '',
      subscriptionCode: this.form.value.tb_code || '',
      subscriptionType: this.form.value.tb_type || '',
      requesterCode: this.form.value.hrm_am || '',
      creatorCode: this.form.value.hrm_creator || '',
    };

    data.append('daiDien', JSON.stringify(daiDien));
    data.append('toChuc', JSON.stringify(toChuc));

    this.postAddAccount(data);
  }

  getDmTinh() {
    this.catalog.getListTinh().subscribe(
      (res) => {
        let dmTinh = new ListTinhResponse(res);
        this.tinhs = dmTinh.object.map((item) => {
          return { id: item.tinhId.toString(), label: item.tenTinh };
        });
      },
      (err) => {}
    );
  }

  getDmHuyen(idTinh) {
    this.catalog.getListHuyen(idTinh).subscribe(
      (res) => {
        let dmHuyen = new ListHuyenResponse(res);
        this.huyens = dmHuyen.object.map((item) => {
          return { id: item.huyenId.toString(), label: item.tenHuyen };
        });
      },
      (err) => {}
    );
  }

  getDmXa(idHuyen) {
    this.catalog.getListXa(idHuyen).subscribe(
      (res) => {
        let dmXa = new ListXaResponse(res);
        this.xas = dmXa.object.map((item) => {
          return { id: item.xaId.toString(), label: item.tenXa };
        });
      },
      (err) => {}
    );
  }

  getDataPackage(page, maxSize, propertiesSort, packageType, status, userType) {
    this.accApi
      .getDataPackage(page, maxSize, propertiesSort, packageType, status, userType)
      .subscribe(
        (res: any) => {
          this.fullPackageIds = res.object || [];
          this.fullPackageIds.forEach((content) => {
            if (content.packageType && content.packageType == 'TRIAL_TYPE') {
              this.trialPackageIds.push(content);
            }
          });
          console.log(this.packageIds);
        },
        (err) => {}
      );
  }

  postConvertToPDF(data: FormData) {
    this.templateApi.postConvertToPdf(data).subscribe(
      (fileData) => {
        const blob: any = new Blob([fileData], { type: 'application/pdf' });
        this.pdfFile = blob;
      },
      (err) => {
        console.log(err);
        this.myAlert.showErrorHandled(err);
      }
    );
  }

  postAddAccount(param) {
    this.accApi.postAddAccount(param).subscribe(
      (res: any) => {
        console.log(res);
        this.myAlert.showAlertOnlyNoti(
          localize.add_account_customer_success,
          ConstantAlertType.SUCCESS
        );
        this.router.navigate(['console/account-manager']);
      },
      (err) => {
        console.log(err);
        this.myAlert.showErrorHandled(err);
      }
    );
  }

  //MARK: Them tinh nang customize thong tin ha tang cho khach hang
  /**
   * Sự kiện thay đổi gói dịch vụ
   */
  selectedPackage(e) {
    console.log(e);
    if (e) {
      this.getPackage(e?.packInfoId);
    } else {
      this.packageInfo = null;
    }
  }

  /**
   * Lấy thông tin gói dịch vụ
   * @param packageId
   */
  getPackage(packageId) {
    this.packageApi.getDetailPackage(packageId).subscribe(
      (res: any) => {
        this.packageInfo = new PackageDetail(res?.object);
        console.log(this.packageInfo);
      },
      (err) => {
        console.log(err);
        this.myAlert.showErrorHandled(err);
      },
      () => {}
    );
  }

  /**
   * Nút tuỳ chỉnh cấu hình hạ tầng
   */
  customizeInfraConfig() {
    if (this.form.controls.packageId.valid) {
      this.showCustomizeInfraInfo = true;
    } else {
      this.myAlert.showAlertOnlyNoti(
        'Vui lòng chọn gói dịch vụ trước khi tuỳ chỉnh thông tin hạ tầng!',
        ''
      );
    }
  }

  /**
   * Bắt sự kiến đóng màn hình tuỳ chỉnh hạ tầng
   * @param e
   */
  handleBackEvent(e) {
    if (e) {
      this.showCustomizeInfraInfo = false;
    }
  }

  /**
   * Bắt sự kiện trả dữ liệu đã lưu từ Tuỳ chỉnh hạ tầng
   * @param e
   */
  handleSavedData(e) {
    if (e) {
      console.log(e);
      this.packageInfo = new PackageDetail(e);
    }
  }
}
