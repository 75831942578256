import { Injectable } from '@angular/core';
import { ServicePath } from 'src/app/utils/common/constant-service-api';
import { API } from '../../api';

const EMPLOYEE_LIST = ServicePath.MANAGEMENT_SERVICE + "/parties/list-employee";
const EMPLOYEE_ADD = ServicePath.MANAGEMENT_SERVICE + "/parties/create-employee";
const EMPLOYEE_DETAIL = ServicePath.MANAGEMENT_SERVICE + "/parties/employee";
const EMPLOYEE_UPDATE = ServicePath.MANAGEMENT_SERVICE + "/parties";
const EMPLOYEE_IN_PART = ServicePath.MANAGEMENT_SERVICE + "/parties";
const EMPLOYEE_FINISH_SIGNUP = ServicePath.MANAGEMENT_SERVICE + "/user-informations/finish-signup";
const EMPLOYEE_BY_PARTYID = ServicePath.MANAGEMENT_SERVICE + "/contracts";
const EMPLOYEE_IN_PART_V2 = ServicePath.MANAGEMENT_SERVICE + "/parties";
const EMPLOYEES_IN_PARTY = ServicePath.MANAGEMENT_SERVICE + "/users";

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {
  public static instance: EmployeeService;

  constructor(private api: API) { 
    if (!EmployeeService.instance) {
      EmployeeService.instance = this;
    }
    return EmployeeService.instance;
  }

  getEmployeeList(page, maxSize, propertiesSort, sort, status, partId, keySearch) {
    const url = EMPLOYEE_LIST + `?page=${page}&maxSize=${maxSize}&=propertiesSort=${propertiesSort}&sort=${sort}&status=${status}&partyId=${partId}&keySearch=${keySearch}`;
    return this.api.get(url);
  }

  postAddEmployee(param) {
    const url = EMPLOYEE_ADD;
    return this.api.post(url, param);
  }

  getEmployee(id) {
    const url = EMPLOYEE_DETAIL + `/${id}`;
    return this.api.get(url);
  }

  putUpdateEmployee(partyId, userId, param) {
    const url = EMPLOYEE_UPDATE + `/${partyId}/update-employee-info/${userId}`;
    return this.api.put(url, param);
  }

  getListEmployeeInPart(partId, page, maxSize = 10, propertiesSort= "created", sort = "DESC", status = "", keySearch = "") {
    const url = EMPLOYEE_IN_PART + `/${partId}/list-employee?page=${page}&maxSize=${maxSize}&=propertiesSort=${propertiesSort}&sort=${sort}&status=${status}&partyId=${partId}&keySearch=${keySearch}`
    return this.api.get(url);
  }

  postFinishSignup(token, param) {
    const url = EMPLOYEE_FINISH_SIGNUP + `/${token}`;
    return this.api.post(url, param);
  }

  getListUserByPartyId(contractId, partyId) {
    const url = EMPLOYEE_BY_PARTYID + `/${contractId}/get-list-user-transfer?partyId=${partyId}`;
    return this.api.get(url);
  }

  getListEmployeeInPartV2(partId, page, maxSize = 10, propertiesSort= "created", sort = "DESC", status = "", keySearch = "") {
    const url = EMPLOYEE_IN_PART_V2 + `/${partId}/v2/list-employee?page=${page}&maxSize=${maxSize}&=propertiesSort=${propertiesSort}&sort=${sort}&status=${status}&partyId=${partId}&keySearch=${keySearch}`
    return this.api.get(url);
  }

  getListEmployeesInParty(page, maxSize = -1, propertiesSort = "modified", sort = "DESC", status = "", keySearch ="") {
    const url = EMPLOYEES_IN_PARTY + `/employees-in-party?page=${page}&maxSize=${maxSize}&propertiesSort=${propertiesSort}&sort=${sort}&status=${status}&keySearch=${keySearch}`
    return this.api.get(url)
  }
}
