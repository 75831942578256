<div class="comment-popup">
    <div class="comment-container">
        <div class="comment-popup-header">
            <div *ngIf="notePoint && notePoint?.saved == true" class="comment-popup-redirect-cmt">
                <button class="btn-popup-header" (click)="goToPrevNotePoint()"><span class="material-icons">arrow_back_ios</span></button>
                <button class="btn-popup-header" (click)="goToNextNotePoint()"><span class="material-icons">arrow_forward_ios</span></button>
            </div>
            <button class="btn-popup-header comment-exit-popup" (click)="exitPopup()"><span class="material-icons">close</span></button>
        </div>

        <div class="comment-popup-body">
            <div *ngIf="notePoint && notePoint?.saved == true" class="comment-item">
                <div class="comment-item-body">
                    <div class="comment-author">
                        <span>{{ notePoint?.comment?.fullName }} - {{ notePoint?.comment?.email }}</span>
                    </div>
                    <div class="comment-meta">
                        <span>{{ dateUtil.formatDate(notePoint?.comment?.createdDate, translate.currentLang) }}</span>
                    </div>
                    <div class="comment-content">
                        <p>{{ notePoint?.comment?.value }}</p>
                        <div class="comment-item-action">
                            <button *ngIf="notePoint.isOwnerUser" class="delete-comment" (click)="deletePost(notePoint)">
                                <span class="material-icons">delete</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngFor="let item of notePoint?.replies" class="comment-item">
                <div class="comment-item-body">
                    <div class="comment-author">
                        <span>{{ item.fullName }} - {{ item.email }}</span>
                    </div>
                    <div class="comment-meta">
                        <span>{{ dateUtil.formatDate(item.createdDate, translate.currentLang) }}</span>
                    </div>
                    <div class="comment-content">
                        <p>{{ item.value }}</p>
                        <div class="comment-item-action">
                            <button *ngIf="item.userId == auth.userId" class="delete-comment" (click)="deleteReply(notePoint, item)">
                                <span class="material-icons">delete</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="contract.allowComment" class="comment-popup-footer">
            <div *ngIf="!notePoint || notePoint?.saved == false" class="comment-box">
                <form [formGroup]="formComment">
                    <div class="form-group comment-form">
                        <input formControlName="value" class="form-control" placeholder="{{ 'CommentPopup_input_placeholder_comment' | translate }}" [attr.disabled]="(isSubmiting() | async) ? true : null">
                        <button class="comment-send" [attr.disabled]="(isSubmiting() | async) ? true : null"
                            (click)="submitComment()">
                            <span class="material-icons">send</span>
                        </button>
                    </div>
                </form>
            </div>

            <div *ngIf="notePoint && notePoint?.saved == true" class="comment-box">
                <form [formGroup]="formComment">
                    <div class="form-group comment-form">
                        <input formControlName="value" class="form-control" placeholder="{{ 'CommentPopup_input_placeholder_reply' | translate }}" [attr.disabled]="(isSubmiting() | async) ? true : null">
                        <button class="comment-send" [attr.disabled]="(isSubmiting() | async) ? true : null"
                            (click)="submitReplyComment()">
                            <span class="material-icons">send</span>
                        </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>