<!-- Header -->
<div class="header">
    <nav id="navbar" class="navbar navbar-expand-lg bg-nav fixed-top nav-app-custom shadow">
        <div class="container ct-nav-container">
            <a class="navbar-brand" routerLink="/home">
                <h2 class="font-weight-bold"><span class="text-gray">e</span><span class="text-primary">Contract</span></h2>
                <!-- <img src="assets/img/login/Logo.png" height="30" class="d-inline-block align-top" alt="img-logo"> -->
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="material-icons">menu</span>
            </button>
            <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a class="nav-link font-weight-600" >Giải pháp</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link font-weight-600" (click)="scroll('features')">Tính năng</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link font-weight-600" (click)="scroll('partner')">Đối tác</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link font-weight-600">Gói cước</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</div>