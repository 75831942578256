<app-spinner></app-spinner>
<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-auto">
                <h1 class="m-0 text-dark"><label>{{ localize.add_service_catalog }}</label></h1>
            </div>
            <!-- /.col -->
            <div *showWithRoles="[]" class="col-md-auto ml-auto">
            </div>
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>

<!--MARK top bar -->
<section class="content">
    <div class="container-fluid">
        <div class="card ec-card shadow-none">
            <div class="card-header">
                <div class="d-flex align-items-center">
                    <div class="card-title-path flex-grow-1">
                        <h3 class="m-0 text-dark card-title-sub"><label class="m-0">{{ localize.general_infomation
                                }}</label></h3>
                    </div>
                    <div class="card-btn-path ml-auto text-right">
                        <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="cancel()">
                            <span class="material-icons mr-1">close</span>
                            {{ localize.btn_cancel }}
                        </button>
                        <button class="btn btn-primary d-inline-flex" (click)="save()">
                            <span class="material-icons mr-1">save</span>
                            {{ localize.btn_save }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <form [formGroup]="form">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ localize.catalog_name }} <span class="text-danger">*</span></label>
                                <input appTrimValue class="form-control" formControlName="name" placeholder="{{ localize.catalog_name_placeholder }}">
                                <app-show-validate-errors [isSubmit]="isSubmit" [control]="form.controls.name" [errorMessages]="validateMsg.name" [detail]="{name:'name'}"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="form-group">
                                <label for="serviceType">{{ localize.catalog_type }} <span class="text-danger">*</span></label>
                                <ng-select labelForId="serviceType" formControlName="serviceType" 
                                    [items]="serviceType"
                                    bindValue="value" bindLabel="label"
                                    [searchable]="false"
                                    [clearable]="false" 
                                    placeholder="{{ localize.select_catalog_type }}">
                                </ng-select>
                                <app-show-validate-errors [isSubmit]="isSubmit" [control]="form.controls.serviceType" [errorMessages]="validateMsg.serviceType" [detail]="{name:'serviceType'}"></app-show-validate-errors>
                            </div>
                        </div>
                    </div>
                </form>
                
                <div *ngIf="form.value.serviceType === 'CHUCNANG'" class="show-features mt-3">
                    <div class="card-title-path flex-grow-1">
                        <h3 class="m-0 text-dark card-title-sub">
                            <label class="m-0">{{ localize.permission_information }}</label>
                        </h3>
                    </div>
                    <hr class="my-3">
                    <app-dual-list #duallist 
                        [items]="items_dualist" 
                        [listAssigned]="items_dualist_assigned" 
                        (changed)="getListAssigned($event)">
                    </app-dual-list>
                </div>

                <div *ngIf="form.value.serviceType === 'HATANG'" class="show-infra mt-3">
                    <div class="card-title-path flex-grow-1">
                        <h3 class="m-0 text-dark card-title-sub">
                            <label class="m-0">{{ localize.infrastructure_information }}</label>
                        </h3>
                    </div>
                    <hr class="my-3">
                    <div class="row">
                        <div class="col-md-10">
                            <div class="table-responsive">
                                <table class="table mb-0">
                                    <thead>
                                        <tr class="bg-tb-header border-top text-left">
                                            <th>{{ localize.stt }}</th>
                                            <th>{{ localize.parameter }}</th>
                                            <th>{{ localize.unit }}</th>
                                            <!-- <th>{{ localize.quantity }}</th> -->
                                            <th>{{ localize.unit_price }}</th>
                                            <!-- <th>{{ localize.into_money }}</th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of infraConfigs; let i = index">
                                            <td>{{ item.stt }}</td>
                                            <td>{{ item.name }}</td>
                                            <td>{{ item.unit }}</td>
                                            <!-- <td>
                                                <input appOnlyNumber class="form-control" type="number" [(ngModel)]="item.unitNumber" (input)="item.getPrice(); getTotalMoney()">
                                            </td> -->
                                            <td>
                                                <input *ngIf="item.param != 'STORAGE_TIME'" appOnlyNumber class="form-control" type="number" [(ngModel)]="item.unitPrice" (input)="item.getPrice(); getTotalMoney()">
                                            </td>
                                            <!-- <td>
                                                {{ item.getPrice() }}
                                            </td> -->
                                        </tr>
                                        <!-- <tr>
                                            <td></td>
                                            <td colspan="4">Tổng cộng</td>
                                            <td>{{ totalPrice }}</td>
                                        </tr> -->
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>