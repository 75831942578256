import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { ConstantAlertBtn, ConstantAlertMsg } from 'src/app/utils/common/constant-alert-msg';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { Input } from '@angular/core';
import { Config } from 'src/app/config';
import { UserTypeConstant } from 'src/app/utils/common/constant';
import { LocationExtension } from 'src/app/utils/common/LocationExtension';
import { REQUEST_OTP } from 'src/app/utils/common/constant-vaildate-msg';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { localize } from 'src/app/utils/localize/localize';

@Component({
  selector: 'app-signup-v2-step-three',
  templateUrl: './signup-v2-step-three.component.html',
  styleUrls: ['./signup-v2-step-three.component.scss']
})
export class SignupV2StepThreeComponent implements OnInit {
  @Input('id') id: string = '';
  @Input('userType') userType: string = '';
  @Input('dataForm') _dataForm: BehaviorSubject<any> = new BehaviorSubject<any>({});
  @Input()
  set dataForm(value) {
    this._dataForm.next(value);
  };
  get dataForm() {
    return this._dataForm.getValue();
  }

  @Output('next') next = new EventEmitter<any>();
  @Output('back') back = new EventEmitter<any>();

  config = Config;
  userTypeConstant = UserTypeConstant;
  hostName = LocationExtension.HOSTNAME;

  formOTP: FormGroup;
  isSubmit = false;
  ServiceMsg = REQUEST_OTP;

  tokenConfirm = '';

  maxRequestOTP = 3;
  counter$: Observable<number>;
  count = 0;

  registerSuccess: boolean = false;

  constructor(
    private route: Router,
    private authService: AasAuthService,
    private modalService: NgbModal,
    private myAlert: AlertControl
  ) {
    // this.resetTimeout();
  }

  ngOnInit(): void {
    this.makeSendOTPForm();
  }

  //MARK: Timout resend
  resetTimeout() {
    this.count = 30;
    this.counter$ = timer(0, 1000).pipe(
      take(this.count),
      map(() => --this.count)
    );
  }

  //MARK: Khởi tạo form send email
  makeSendOTPForm() {
    this.formOTP = new FormGroup({
      otp: new FormControl(null, [Validators.required, Validators.minLength(6), Validators.maxLength(6)]),
    });
  }

  get otp() { return this.formOTP.get('otp') }

  //MARK: Action chức năng OTP
  /**
   * Sự kiện nhấn nút xác nhận OTP
   */
  verifyOTP() {
    this.isSubmit = true;
    this.clearSpace();
    if (this.formOTP.valid) {
      switch (this.userType) {
        case UserTypeConstant.CONSUMER:
          this.postVerifyOTP(this.id, this.otp.value);
          break;
        case UserTypeConstant.BUSINESS:
          this.postVerifyEmail(this.id, this.otp.value);
          break;
        case UserTypeConstant.ENTERPRISE:
          // this.postVerifyEmail(this.id, this.otp.value);
          this.postVerifyVipEmail(this.id, this.otp.value);
          break;
      }
    }
  }

  /**
   * Sự kiện nhấn nút gửi lại OTP
   */
  resendOTP() {
    this.clearSpace();
    switch (this.userType) {
      case UserTypeConstant.CONSUMER:
        this.postResendOTP(this.id);
        break;
      case UserTypeConstant.BUSINESS:
        this.postResendEmail(this.id);
        break;
      case UserTypeConstant.ENTERPRISE:
        this.postResendEmail(this.id);
        break;
    }
  }

  /**
   * Handle verify otp failed
   */
  verifyOTPFailed() {
    this.myAlert.showAlert(ConstantAlertMsg.OTP_VERIFY_MAXIMUM, ConstantAlertType.ERROR, false, '', ConstantAlertBtn.OK, () => {
      location.reload();
    });
  }

  /**
   * Handle verify otp success
   */
  verifyOTPSuccess() {
    this.registerSuccess = true;
    this.next.emit(this.dataForm);
  }

  /**
   * Điều kiện để enable nút "Xác nhận"
   */
  get enableBtnSubmit(): boolean {
    return this.otp.invalid;
  }

  /**
   * Bắt sự kiện nhập OTP
   * @param event 
   */
  handleInputOTP(event) {
    const regex = new RegExp(/^\d+$/);
    if (regex.test(event.target.value) && event.target.value.length <= 6) {
      return;
    } else {
      this.otp.setValue(event.target.value.slice(0, -1));
    }
  }

  //MARK: Trim value formControl
  clearSpace() {
    let str = `${this.otp.value || ''}`;
    this.otp.setValue(`${str.trim()}`);
  }

  //MARK: Popup thông báo
  // customAlert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = ConstantAlertBtn.OK, actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = ConstantAlertBtn.CANCEL, actBtn1 = () => { }) {
  //   const modalRef = this.modalService.open(AlertComponent);
  //   modalRef.componentInstance.message = msg;
  //   modalRef.componentInstance.typeAlert = type;
  //   modalRef.componentInstance.twoBtn = twoBtn;
  //   modalRef.componentInstance.iconBtn1 = iconBtn1;
  //   modalRef.componentInstance.iconBtn2 = iconBtn2;
  //   modalRef.componentInstance.titleButton1 = titleBtn1;
  //   modalRef.componentInstance.titleButton2 = titleBtn2;
  //   modalRef.componentInstance.callback1 = actBtn1;
  //   modalRef.componentInstance.callback2 = actBtn2;
  // }

  // //MARK: Handle Error
  // handleError(err, errorMsg) {
  //   if (err.error && err.error.message && errorMsg[err.error.message]) {
  //     this.customAlert(errorMsg[err.error.message], ConstantAlertType.ERROR);
  //   } else if (err.error && err.error.error) {
  //     this.customAlert(err.error.error, ConstantAlertType.ERROR);
  //   } else {
  //     this.customAlert(ConstantAlertMsg.DEFAULT_ERROR_REQUEST, ConstantAlertType.ERROR);
  //   }
  // }

  //MARK: NETWORKING
  /**
   * Verify request quên mật khẩu bằng email
   * @param id 
   * @param otp 
   */
  postVerifyEmail(id, otp) {
    this.authService.verifyEmail(id, otp).subscribe(res => {
      this.verifyOTPSuccess();
    }, err => {
      console.log(err);
      this.maxRequestOTP--;
      console.log(this.maxRequestOTP);
      this.otp.setErrors({ notFound: true });
      if (this.maxRequestOTP <= 0) {
        this.verifyOTPFailed();
      } else {
        this.myAlert.showAlert(localize.signup_verify_otp_failed.replace("${solan}", `${this.maxRequestOTP}`), ConstantAlertType.BLANK, false, '', ConstantAlertBtn.CLOSE);
      }
    });
  }

  /**
   * Verify request quên mật khẩu bằng sdt
   * @param id 
   * @param otp 
   */
  postVerifyOTP(id, otp) {
    this.authService.verifyOTP(id, otp).subscribe(res => {
      this.verifyOTPSuccess();
    }, err => {
      console.log(err);
      this.maxRequestOTP--;
      console.log(this.maxRequestOTP);
      this.otp.setErrors({ notFound: true });
      if (this.maxRequestOTP <= 0) {
        this.verifyOTPFailed();
      } else {
        this.myAlert.showAlert(`Mã OTP không chính xác. Vui lòng thử lại! (Bạn còn lại ${this.maxRequestOTP})`, ConstantAlertType.BLANK, false, '', ConstantAlertBtn.CLOSE);
      }
    });
  }

  /**
   * Verify request quên mật khẩu bằng email
   * @param id 
   * @param otp 
   */
  postVerifyVipEmail(id, otp) {
    this.authService.verifyVipEmail(id, otp).subscribe(res => {
      this.verifyOTPSuccess();
    }, err => {
      console.log(err);
      this.maxRequestOTP--;
      console.log(this.maxRequestOTP);
      this.otp.setErrors({ notFound: true });
      if (this.maxRequestOTP <= 0) {
        this.verifyOTPFailed();
      } else {
        this.myAlert.showAlert(`Mã OTP không chính xác. Vui lòng thử lại! (Bạn còn lại ${this.maxRequestOTP})`, ConstantAlertType.BLANK, false, '', ConstantAlertBtn.CLOSE);
      }
    });
  }

  

  /**
   * Gửi lại OTP vào email
   * @param id 
   */
  postResendEmail(id) {
    this.authService.resendEmail(id).subscribe(res => {
      this.resetTimeout();
    }, err => {
      this.myAlert.showErrorHandled(err, ResponseMsg.MESSAGES.REQUEST_OTP);
    }, () => {

    });
  }

  /**
   * Gửi lại OTP vào số đt
   * @param id 
   */
  postResendOTP(id) {
    this.authService.resendOTP(id).subscribe(res => {
      this.resetTimeout();
    }, err => {
      console.log(err);
      this.myAlert.showErrorHandled(err, ResponseMsg.MESSAGES.REQUEST_OTP);
    }, () => {

    });
  }
}
