export class ResponseMsg {
    public static MESSAGES = {
        ALL_IN_ONE: {
            "": "Đã có lỗi xảy ra! Không thể ký hợp đồng bằng VNPT ký số!",
            "ECT-00000403": "Bạn không có quyền truy cập chức năng này!",
            "IDG-00000500": "Đã có lỗi xảy ra! Không thể ký hợp đồng bằng VNPT ký số!",
            "ECT-00000500": "Đã có lỗi xảy ra! Không thể ký hợp đồng bằng VNPT ký số!",
            "ECT-00000001": "Các tham số bắt buộc không được rỗng",
            "ECT-00000002": "Tham số truyền vào không đúng",
            "ECT-00000003": "Hết hiệu lực phiên đăng nhập hợp đồng điện tử",
            "ECT-00000004": "Không có quyền truy cập dịch vụ ký số",
            "ECT-00000005": "Lỗi sự cố dịch vụ ký số",
            "ECT-00000006": "Bạn chưa cấu hình tài khoản ký số",
            "ECT-00000007": "Bạn chưa cấu hình hiển thị chữ ký số",
            "ECT-00000008": "Lấy thông tin tài khoản ký số thất bại",
            "ECT-00000009": "Đăng nhập không thành công do sai email hoặc mật khẩu!",
            "ECT-00000010": "VNPT ký số: Ứng dụng ký số hết hạn hoặc hết lượt ký số",
            "ECT-00000011": "VNPT ký số: Không hỗ hợ định dạng file hợp đồng",
            "ECT-00000012": "VNPT ký số: Không tìm thấy chữ ký số",
            "ECT-00000013": "VNPT ký số: Chữ ký số và account không liên kết",
            "ECT-00000014": "VNPT ký số: Chữ ký số hết hiệu lực",
            "ECT-00000015": "VNPT ký số: Lỗi không tạo được chữ ký",
            "ECT-00000016": "VNPT ký số: Phiên đăng nhập ký số hết hiệu lực",
            "ECT-00000017": "VNPT ký số: Tham số truyền vào lỗi",
            "ECT-00000018": "VNPT ký số: Tài khoản ký số hết hiệu lực",
            "ECT-00000019": "VNPT ký số: Tài khoản ký số bị khóa",
            "ECT-00000020": "VNPT ký số: Lỗi sự cố không có dịch vụ",
            "ECT-00000030": "Hợp đồng không tồn tại hoặc chưa được ký điện tử1",
            "ECT-00003000": "Không tìm thấy chữ ký!",
            "ECT-00003001": "Hợp đồng đã bị thay đổi!",
            "ECT-00003002": "Chữ ký chưa được xác thực OTP!",
            "ECT-00003003": "Không tìm thấy cấu hình chữ ký điện tử!",
            "ECT-00003004": "Không tạo được hình ảnh chữ ký!",
            "ECT-00003005": "Không thể hoàn thành việc ký!",
            "ECT-00001203": "Mã OTP không chính xác. Vui lòng thử lại",
            "ECT-00001204": "Mã OTP đã xác nhận trước đó",
            "ECT-00001205": "Mã OTP đã hết hạn",
            "ECT-00001100": "Không tìm thấy dữ liệu!",
            "ECT-00001101": "Không thể xóa dữ liệu đang được liên kết!",
            "ECT-00001102": "REQUEST_TIMEOUT",
            "ECT-00001103": "INPUT_DATA_WRONG",
            "ECT-00001104": "Dữ liệu đã tồn tại!",
            "ECT-00001111": "Tài khoản đã bị khóa!",
            "ECT-00001113": "Dữ liệu không đúng định dạng!",
            "ECT-00001200": "Email tài khoản đã tồn tại!",
            "ECT-00001201": "Số điện thoại đã tồn tại!",
            "ECT-00001202": "Tên đăng nhập đã tồn tại!",
            "ECT-00002000": "Không tìm thấy thông tin!",
            "ECT-00000071": "Không tìm thấy mẫu hợp đồng!",
            "ECT-00000072": "Mẫu hợp đồng chưa được kích hoạt!",
            "ECT-00002403": "Không tìm thấy thông tin người dùng!",
            "ECT-00002100": "Sản phẩm dịch vụ đã tồn tại!",
            "ECT-00002101": "File không đúng theo mẫu đã cung cấp!",
            "ECT-00002102": "Không tìm thấy sản phẩm dịch vụ!",
            "ECT-00002103": "Sản phẩm dịch vụ đã được sử dụng!",
            "ECT-00002104": "Mã sản phẩm dịch vụ đã tồn tại!",
            "ECT-00002105": "Tên sản phẩm dịch vụ đã tồn tại!",
            "ECT-00002106":	"Chưa nhập đủ thông tin các trường bắt buộc!",
            "ECT-00002107": "Mã sản phẩm dịch vụ chỉ cho phép chữ, số và một số ký tự đặc biệt: ()/\._&",
            "ECT-00002200": "Tài liệu đã tồn tại!",
            "ECT-00002202": "Không tìm thấy tài liệu!",
            "ECT-00002203": "Tài liệu đã được sử dụng!",

            "DUPLICATE_USER_EMAIL": "Email này đã tồn tại!",
            "DUPLICATE_USER_PHONE_NUMBER": "Số điện thoại này đã tồn tại!"
        },
        SIGN_SERVER: {
            "": "SIGN_SERVER_response_empty",
            "ECT-00000403": "SIGN_SERVER_response_00000403",
            "IDG-00000500": "SIGN_SERVER_response_IDG_00000500",
            "ECT-00000500": "SIGN_SERVER_response_00000500",
            "ECT-00000001": "SIGN_SERVER_response_00000001",
            "ECT-00000002": "SIGN_SERVER_response_00000002",
            "ECT-00000003": "SIGN_SERVER_response_00000003",
            "ECT-00000004": "SIGN_SERVER_response_00000004",
            "ECT-00000005": "SIGN_SERVER_response_00000005",
            "ECT-00000006": "SIGN_SERVER_response_00000006",
            "ECT-00000007": "SIGN_SERVER_response_00000007",
            "ECT-00000008": "SIGN_SERVER_response_00000008",
            "ECT-00000009": "SIGN_SERVER_response_00000009",
            "ECT-00000010": "SIGN_SERVER_response_00000010",
            "ECT-00000011": "SIGN_SERVER_response_00000011",
            "ECT-00000012": "SIGN_SERVER_response_00000012",
            "ECT-00000013": "SIGN_SERVER_response_00000013",
            "ECT-00000014": "SIGN_SERVER_response_00000014",
            "ECT-00000015": "SIGN_SERVER_response_00000015",
            "ECT-00000016": "SIGN_SERVER_response_00000016",
            "ECT-00000017": "SIGN_SERVER_response_00000017",
            "ECT-00000018": "SIGN_SERVER_response_00000018",
            "ECT-00000019": "SIGN_SERVER_response_00000019",
            "ECT-00000020": "SIGN_SERVER_response_00000020"
        },
        ESIGN_SERVICE: {
            "ECT-00000403": "Bạn không có quyền truy cập chức năng này!",
            "ECT-00000030": "Hợp đồng không tồn tại hoặc chưa được ký điện tử1",
            "ECT-00003000": "Không tìm thấy chữ ký!",
            "ECT-00003001": "Hợp đồng đã bị thay đổi!",
            "ECT-00003002": "Chữ ký chưa được xác thực OTP!",
            "ECT-00003003": "Không tìm thấy cấu hình chữ ký điện tử!",
            "ECT-00003004": "Không tạo được hình ảnh chữ ký!",
            "ECT-00003005": "Không thể hoàn thành việc ký!",
            "ECT-00001203": "Mã OTP không chính xác. Vui lòng thử lại",
            "ECT-00001204": "Mã OTP đã xác nhận trước đó",
            "ECT-00001205": "Mã OTP đã hết hạn"
        },
        MANAGEMENT_SERVICE: {
            "ECT-00000401": "MANAGEMENT_SERVICE_response_00000401",
            "ECT-00000403": "MANAGEMENT_SERVICE_response_00000403",
            "ECT-00001200": "MANAGEMENT_SERVICE_response_00001200",
            "ECT-00001201": "MANAGEMENT_SERVICE_response_00001201",
            "ECT-00001202": "MANAGEMENT_SERVICE_response_00001202",
            "ECT-00002000": "MANAGEMENT_SERVICE_response_00002000",
        },
        AUTH_SERVICE: {
            "ECT-00000403": "Bạn không có quyền truy cập chức năng này!",
            "ECT-00001100": "Không tìm thấy dữ liệu!",
            "ECT-00001101": "Không thể xóa dữ liệu đang được liên kết!",
            "ECT-00001102": "Yêu cầu hết hạn!",
            "ECT-00001103": "Dữ liệu nhập vào không chính xác!",
            "ECT-00001104": "Dữ liệu đã tồn tại!",
            "ECT-00001111": "Tài khoản đã bị khóa!",
            "ECT-00001113": "Dữ liệu không đúng định dạng!",
            "ECT-00001110": "Bạn chưa đăng nhập hoặc phiên đã hết hiệu lực!",
            "ECT-00001203": "Mã OTP không chính xác. Vui lòng thử lại!",
            "ECT-00001204": "Mã OTP đã được xác nhận trước đó!",
            "ECT-00001205": "Mã OTP đã hết hạn!"
        },
        PARTY_USER: {
            "ECT-00000403": "Bạn không có quyền truy cập chức năng này!",
            "ECT-00001200": "Email tài khoản đã tồn tại!",
            "ECT-00001201": "Số điện thoại đã tồn tại!",
            "ECT-00001202": "Tên đăng nhập đã tồn tại!",
            "ECT-00001208": 'Mã số thuế đã tồn tại!'
        },
        FORGOT_PASSWORD: {
            "ECT-00000403": "ECT-00000403",
            "ECT-00001100": "ForgotPassword_err_4",
        },
        REQUEST_OTP: {
            "ECT-00000403": "ECT-00000403",
            "ECT-00001103": "OtpForgotPassword_err_6",
            "ECT-00001203": "OtpForgotPassword_err_7",
            "ECT-00001204": "OtpForgotPassword_err_8",
            "ECT-00001205": "OtpForgotPassword_err_9",
            "BRE-00000001": "BRE-00000001",
            "BRE-00000030": "BRE-00000030",
            "BRE-00000021": "BRE-00000021",
            "BRE-00000007": "BRE-00000007",
            "BRE-00000014": "BRE-00000014",
            "BRE-00000010": "BRE-00000010",
            "BRE-00000009": "BRE-00000009",
            "BRE-00000011": "BRE-00000011",
            "BRE-00000031": "BRE-00000031",
            "BRE-00000037": "BRE-00000037",
            "BRE-00000038": "BRE-00000038",
            "BRE-00000034": "BRE-00000034",
            "BRE-00000002": "BRE-00000002",
            "BRE-00000003": "BRE-00000003",
            "BRE-00000004": "BRE-00000004",
            "BRE-00000005": "BRE-00000005",
            "BRE-00000006": "BRE-00000006",
            "BRE-00000008": "BRE-00000008",
            "BRE-00000012": "BRE-00000012",
            "BRE-00000013": "BRE-00000013",
            "BRE-00000015": "BRE-00000015",
            "BRE-00000016": "BRE-00000016",
            "BRE-00000017": "BRE-00000017",
            "BRE-00000018": "BRE-00000018",
            "BRE-00000019": "BRE-00000019",
            "BRE-00000020": "BRE-00000020",
            "BRE-00000022": "BRE-00000022",
            "BRE-00000023": "BRE-00000023",
            "BRE-00000024": "BRE-00000024",
            "BRE-00000025": "BRE-00000025",
            "BRE-00000026": "BRE-00000026",
            "BRE-00000027": "BRE-00000027",
            "BRE-00000028": "BRE-00000028",
            "BRE-00000029": "BRE-00000029",
            "BRE-00000032": "BRE-00000032",
            "BRE-00000033": "BRE-00000033",
            "BRE-00000039": "BRE-00000039",
            "BRE-00000040": "BRE-00000040",
        },
        VERIFY_BUSINESS: {
            "ECT-00000403": "Bạn không có quyền truy cập chức năng này!",
            "ECT-00000500": "Không tìm thấy chữ ký trên file đính kèm!"
        },
        CONTRACT_MANAGER: {
            "ECT-00000403": "Bạn không có quyền truy cập chức năng này!",
            "ECT-00002000": "Không tìm thấy thông tin!",
            // "ECT-00000400": "Tài liệu đã bị ký!",
        },
        TEMPLATE: {
            "ECT-00000403": "Bạn không có quyền truy cập chức năng này!",
            "ECT-00000071": "Không tìm thấy mẫu hợp đồng!",
            "ECT-00000072": "Mẫu hợp đồng chưa được kích hoạt!",
            "ECT-00002403": "Không tìm thấy thông tin người dùng!",
            "ECT-00002100": "Sản phẩm dịch vụ đã tồn tại!",
            "ECT-00002101": "File không đúng theo mẫu đã cung cấp!",
            "ECT-00002102": "Không tìm thấy sản phẩm dịch vụ!",
            "ECT-00002103": "Sản phẩm dịch vụ đã được sử dụng!",
            "ECT-00002104": "Mã sản phẩm dịch vụ đã tồn tại!",
            "ECT-00002105": "Tên sản phẩm dịch vụ đã tồn tại!",
            "ECT-00002106":	"Chưa nhập đủ thông tin các trường bắt buộc!",
            "ECT-00002107": "Mã sản phẩm dịch vụ chỉ cho phép chữ, số và một số ký tự đặc biệt: ()/\._&",
            "ECT-00002200": "Tài liệu đã tồn tại!",
            "ECT-00002202": "Không tìm thấy tài liệu!",
            "ECT-00002203": "Tài liệu đã được sử dụng!",
            "ECT-00002302": "Không tìm thấy mẫu hợp đồng!"
        },
        COMMENT_MANAGER: {
            "ECT-00000403": "Bạn không có quyền truy cập chức năng này!",
        },
        VIDEO_CALL: {
            "IDG-20000001": "Not null",
            "IDG-20000004": "Kích thước từ 0-255",  
            "IDG-20000003": "Kích thước từ 0-4000",
            "IDG-20000005": "Kích thước từ 0-300",
            "IDG-20000006": "Không thuộc định dạng devicetype",
            "IDG-20000007": "Không thuộc định dạng statuscaller",
            "IDG-20000008": "Không thuộc kiểu enum môi trường",
            "IDG-20000100": "Không tìm thấy thiết bị người nhận",
            "IDG-20000201": "Cuộc gọi đang thực hiện",
            "IDG-20000202": "Không tìm thấy cuộc gọi",
            "IDG-20000203": "Người nhận đang trong cuộc gọi khác",
            "IDG_20000101": "Thiết bị người nhận và người gọi là 1",
            "IDG-20000210": "Không tim thấy host",
            "IDG-20000404": "Không tim thấy token",
            "IDG-20000211": "Không tim thấy room",
            "IDG-20000213": "Không phải thiết bị nguồn",
            "IDG_20000214": "Không phải thiết bị đích",
            "IDG_20000215": "Không tồn tại cấu hình của token",
            "IDG_20000216": "Đã có lỗi xảy ra!"
        },
        ENTERPRISE_VIP_MANAGER: {
            
        },
        DOCUMENT_MANAGER: {
            "ECT-00000403": "Bạn không có quyền truy cập chức năng này!",
            "ECT-00002200": "Tài liệu đã tồn tại!",
            "ECT-00002202": "Không tìm thấy tài liệu!",
            "ECT-00002203": "Tài liệu đã được sử dụng!",
            "ECT-00002204": "Mã tài liệu đã tồn tại",
            "ECT-00002205": "Tên tài liệu đã tồn tại",
            "ECT-00002206": "Chưa nhập đủ thông tin các trường bắt buộc",
            "ECT-00002207": "Mã tài liệu chỉ cho phép chữ, số và một số ký tự đặc biệt: ()/\\._&",
        },
            CHANGE_EMAIL_SDT: {
            "WRONG_FORMAT_EMAIL": "Email sai định dạng",
            "WRONG_FORMAT_PHONE_NUMBER": "Số điện thoại sai định dạng",
            "WRONG_OTP_FORMAT": "OTP sai định dạng",
            "EXIST_EMAIL": "Email này đã được sử dụng bởi 1 tài khoản khác.<br>Vui lòng thử lại!",
            "EXIST_PHONE_NUMBER": "Số điện thoại đã tồn tại trên hệ thống",
            "ACCOUNT_HAD_EMAIL": "Tài khoản đã có dữ liệu Email",
            "ACCOUNT_HAD_PHONE_NUMBER": "Tài khoản đã có dữ liệu Số điện thoại",
            "OUT_OF_SENT_OTP_UPDATE_EMAIL": "Bạn đã sử dụng quá số lần gửi mã OTP. \nVui lòng thử lại quá trình cập nhật email sau 24h nữa!",
            "OUT_OF_SENT_OTP_UPDATE_PHONE_NUMBER": "Bạn đã sử dụng quá số lần gửi mã OTP. \nVui lòng thử lại quá trình cập nhật số điện thoại sau 24h nữa!",
            "OTP_INVALID": "Mã OTP không chính xác. Vui lòng thử lại",
            "OTP_TIMEOUT": "Mã OTP này đã hết hiệu lực. Vui lòng chọn gửi lại mã OTP và nhập mã OTP mới để tiếp tục quá trình cập nhật email.",
            "INFO_NOT_FOUND": "Không tìm thấy thông tin",
            "REQUIRE_IDENTITYLEVEL_2": "Tài khoản cần định danh cấp 2 để thực hiện thao tác",
            "WRONG_OTP_2": "Mã OTP không chính xác. Vui lòng thử lại! \n(Bạn còn 2 lần xác nhận mã OTP)",
            "WRONG_OTP_1": "Mã OTP không chính xác. Vui lòng thử lại! \n(Bạn còn 1 lần xác nhận mã OTP)",
            "WRONG_OTP_0": "Bạn đã nhập sai OTP quá 3 lần. Vui lòng thử lại sau."
        }
    }
}