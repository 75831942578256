<form [formGroup]="formSubmit">
    <div class="row">
        <div class="col-md-auto align-self-end">
            <label class="text-lg">{{ 'ProfileBusiness_title' | translate }}</label>
        </div>
        <div class="col-md-auto ml-auto">
            <button (click)="actBtnCancel($event)" class="btn btn-outline-primary btn-light d-inline-flex mr-4"><span
                    class="material-icons">clear</span> {{ 'btn_cancel' | translate }}</button>
            <button (click)="submit($event)" class="btn btn-primary d-inline-flex"><span
                    class="material-icons mr-1">save</span> {{ 'btn_save' | translate }}</button>
        </div>
    </div>
    <hr class="my-3">

    <label class="text-primary">{{ 'ProfileBusiness_party' | translate }}</label>
    <hr>
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label>{{ 'ProfileBusiness_party_name' | translate }} <span class="text-danger">*</span>:</label>
                <input class="form-control" formControlName="tenDoanhNghiep" placeholder="{{ 'ProfileBusiness_party_input_name' | translate }}">
                <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.tenDoanhNghiep"
                    [control]="tenDoanhNghiep" [detail]="{ name: 'tenDoanhNghiep' }"></app-show-validate-errors>
            </div>
        </div>
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ 'ProfileBusiness_party_short' | translate }} <span class="text-danger">*</span>:</label>
                        <input class="form-control" formControlName="tenVietTat" placeholder="{{ 'ProfileBusiness_party_input_short' | translate }}">
                        <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.tenVietTat"
                            [control]="tenVietTat" [detail]="{ name: 'tenVietTat' }"></app-show-validate-errors>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ 'ProfileBusiness_party_taxcode' | translate }} <span class="text-danger">*</span>:</label>
                        <input disabled class="form-control" formControlName="maSoThue" placeholder="{{ 'ProfileBusiness_party_input_taxcode' | translate }}">
                        <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.maSoThue"
                            [control]="maSoThue" [detail]="{ name: 'maSoThue' }"></app-show-validate-errors>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-12">
            <label class="text-secondary">{{ 'ProfileBusiness_party_license' | translate }}</label>
        </div>
        <div class="col-12 mb-3">
            <a class="text-decoration-underline text-primary" style="cursor: pointer; font-weight: 600;" (click)="actDownloadGPKD(partyInfo.partyId)">
                {{partyInfo?.gpkdFilename}}
            </a>
        </div>
    </div>

    <label class="text-primary">{{ 'ProfileBusiness_agent' | translate }}</label>
    <hr>
    <div class="row">
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ 'ProfileBusiness_agent_name' | translate }} <span class="text-danger">*</span>:</label>
                        <input class="form-control" formControlName="hoVaTen" placeholder="{{ 'ProfileBusiness_agent_input_name' | translate }}">
                        <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.hoVaTen"
                            [control]="hoVaTen" [detail]="{ name: 'hoVaTen' }"></app-show-validate-errors>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ 'ProfileBusiness_agent_email' | translate }} <span class="text-danger">*</span>:</label>
                        <input disabled class="form-control" formControlName="email"
                            placeholder="{{ 'ProfileBusiness_agent_input_email' | translate }}">
                        <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.email"
                            [control]="email" [detail]="{ name: 'email' }"></app-show-validate-errors>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ 'ProfileBusiness_agent_phone' | translate }}</label>
                        <input class="form-control" formControlName="sdt" placeholder="{{ 'ProfileBusiness_agent_input_phone' | translate }}"
                            (input)="handleInputSdt($event)">
                        <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.sdt" [control]="sdt"
                            [detail]="{ name: 'sdt' }"></app-show-validate-errors>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ 'ProfileBusiness_agent_birth' | translate }}</label>
                        <!-- (input)="dateInput($event, ngaySinh)" -->
                        <input appInputMark [options]="optionsDateFormat" 
                            [control]="formSubmit.controls.ngaySinh"
                            [value]="formSubmit.value.ngaySinh" type="text" class="form-control date"
                            placeholder="{{ 'ProfileBusiness_agent_input_birth' | translate }}" id="ngaySinh">
                        <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.ngaySinh"
                            [control]="ngaySinh" [detail]="{ name: 'ngaySinh' }"></app-show-validate-errors>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12 col-lg-6">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="gender">{{ 'ProfileBusiness_agent_gender' | translate }}</label>
                        <ng-select labelForId="gender" [searchable]="false" placeholder="{{ 'ProfileBusiness_agent_input_gender' | translate }}"
                            formControlName="gender">
                            <ng-option [value]="2">{{ 'ProfileBusiness_agent_male' | translate }}</ng-option>
                            <ng-option [value]="1">{{ 'ProfileBusiness_agent_female' | translate }}</ng-option>
                        </ng-select>
                        <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.gender"
                            [control]="gender" [detail]="{ name: 'gender' }">
                        </app-show-validate-errors>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 col-lg-6">

        </div>
    </div>

    <div class="row">
        <div class="col-md-12 col-lg-6">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="tinhId">{{ 'ProfileBusiness_agent_city' | translate }}</label>
                        <ng-select labelForId="tinhId" placeholder="{{ 'ProfileBusiness_agent_input_city' | translate }}" formControlName="tinhId"
                            (change)="tinhChange($event)">
                            <ng-option *ngFor="let tinh of tinhBindData" [value]="tinh.id">{{ tinh.label }}</ng-option>
                        </ng-select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="huyenId">{{ 'ProfileBusiness_agent_district' | translate }}</label>
                        <ng-select labelForId="huyenId" placeholder="{{ 'ProfileBusiness_agent_input_district' | translate }}" formControlName="huyenId"
                            (change)="huyenChange($event)">
                            <ng-option *ngFor="let huyen of huyenBindData" [value]="huyen.id">{{ huyen.label }}
                            </ng-option>
                        </ng-select>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 col-lg-6">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label for="xaId">{{ 'ProfileBusiness_agent_wards' | translate }}</label>
                        <ng-select labelForId="xaId" placeholder="{{ 'ProfileBusiness_agent_input_wards' | translate }}" formControlName="xaId"
                            (change)="xaChange($event)">
                            <ng-option *ngFor="let xa of xaBindData" [value]="xa.id">{{ xa.label }}</ng-option>
                        </ng-select>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ 'ProfileBusiness_agent_number' | translate }}</label>
                        <input formControlName="soNha" type="text" class="form-control" placeholder="{{ 'ProfileBusiness_agent_number' | translate }}" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12 col-lg-6">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ 'ProfileBusiness_agent_road' | translate }}</label>
                        <input formControlName="duong" type="text" class="form-control" placeholder="{{ 'ProfileBusiness_agent_input_road' | translate }}" />
                    </div>
                </div>
            </div>
        </div>

    </div>

    <label class="text-primary">{{ 'ProfileBusiness_account' | translate }}</label>
    <hr>
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label>{{ 'ProfileBusiness_account_name' | translate }} <span class="text-danger">*</span>:</label>
                <input class="form-control" formControlName="username" placeholder="{{ 'ProfileBusiness_account_input_name' | translate }}">
                <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.username"
                    [control]="username" [detail]="{ name: 'username' }"></app-show-validate-errors>
            </div>
        </div>
    </div>
</form>
<!-- <app-mini-spinner *ngIf="loadingService.isVisible() | async"></app-mini-spinner> -->
<app-preview-modal *ngIf="showPreviewGPKD" [file]="fileGPKD" [name]="partyInfo.gpkdFilename" (close)="handleClosePreview()"></app-preview-modal>