import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UserTypeConstant, VerificationType } from 'src/app/utils/common/constant';
import { PartyJoinContract } from 'src/app/utils/models/aas-models/contract/party-join-contract/party-join-contract';
import { InfoPartyObject } from 'src/app/utils/models/aas-models/info-party/info-party-object';
import { Options, Select2AjaxOptions } from 'select2';
import { Select2OptionData } from 'ng-select2';
import { Config } from 'src/app/config';
import { ServicePath } from 'src/app/utils/common/constant-service-api';
import { AuthService } from 'src/app/utils/services/auth.service';
import { stat } from 'fs';
import { Form, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { REGEXP_DATE_INPUT, REGEXP_NUMBER } from 'src/app/utils/common/regexp';
import { ROW_PARTY_JOIN_CONTRACT } from 'src/app/utils/common/constant-vaildate-msg';
import { PackageSessionService } from 'src/app/utils/services/aas-network/package-session/package-session.service';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { localize } from 'src/app/utils/localize/localize';
import { PartnerService } from 'src/app/utils/services/aas-network/partner.service';
import { ControlAccount } from 'src/app/utils/common/control-account';
import { AasContractService } from 'src/app/utils/services/aas-network/aas-contract.service';

const signFormsApproval = ["USB_TOKEN", "SIGN_SERVER", "SMART_CA"];
declare var $: any;
declare var Cleave: any;

@Component({
  selector: 'app-request-resign',
  templateUrl: './request-resign.component.html',
  styleUrls: ['./request-resign.component.scss']
})
export class RequestResignComponent implements OnInit {

  @Input('partyInfo') partyInfo: InfoPartyObject = new InfoPartyObject();
  @Input('partiesJoin') partiesJoin: PartyJoinContract[] = [];
  @Input('partyIdWantEdit') partyIdWantEdit = "";

  @Output('changed') changed: EventEmitter<any> = new EventEmitter<{}>();
  @Output('closed') closed: EventEmitter<any> = new EventEmitter<{}>();
  @Output('sendRequestResignEvent') sendRequestResignEvent: EventEmitter<any> = new EventEmitter<{}>();

  @ViewChild('pickParty') pickParty: HTMLElement;

  public hinhThucKyData: Array<Select2OptionData> = [];
  public optionsHinhThucKy: Options;

  public nguoiNhanData: Array<Select2OptionData> = [];
  public optionsTemplate: Options;
  public ajaxOptions: Select2AjaxOptions;

  public options: Options;

  optionsCleave: any;

  hinhThucXacMinh = [
    { id: VerificationType.VIDEO_CALL, text: 'Xác minh' },
    { id: VerificationType.NONE, text: 'Không xác minh' }
  ]
  userTypeData = [
    { id: UserTypeConstant.CONSUMER, text: 'Cá nhân' },
    { id: UserTypeConstant.BUSINESS, text: 'Doanh nghiệp' },
  ]
  hinhThucKy_consumer = [
    { id: 'NO_AUTHEN', text: 'Cấp 1: Ký ảnh' },
    { id: 'OTP', text: 'Cấp 2: SMS OTP' },
    { id: 'OTP_EMAIL', text: "Cấp 2: Email OTP"},
    // { id: 'EKYC', text: 'Cấp 3: SMS OTP và eKYC' },
    // { id: 'EKYC_EMAIL', text: "Cấp 3: Email OTP và eKYC"},
    { id: 'USB_TOKEN', text: 'Cấp 4: Usb-Token' },
    { id: 'SIGN_SERVER', text: 'Cấp 4: VNPT ký số' },
    { id: 'SMART_CA', text: 'Cấp 4: VNPT SmartCA' }
  ];

  hinhThucKy_business = [
    { id: 'NO_AUTHEN', text: 'Cấp 1: Ký ảnh' },
    { id: 'OTP', text: 'Cấp 2: SMS OTP' },
    { id: 'OTP_EMAIL', text: "Cấp 2: Email OTP"},
    // { id: 'EKYC', text: 'Cấp 3: SMS OTP và eKYC' },
    // { id: 'EKYC_EMAIL', text: "Cấp 3: Email OTP và eKYC"},
    { id: 'USB_TOKEN', text: 'Cấp 4: Usb-Token' },
    { id: 'SIGN_SERVER', text: 'Cấp 4: VNPT ký số' },
    { id: 'SMART_CA', text: 'Cấp 4: VNPT SmartCA' }
  ]

  parties: PartyJoinContract[] = [];
  selectedParty: Select2OptionData;

  userTable: FormGroup;
  control: FormArray;
  validateTable = ROW_PARTY_JOIN_CONTRACT;

  isSubmit: boolean = false;

  constructor(
    private contractApi: AasContractService,
    public controlAccount: ControlAccount,
  ) {
    // if (this.packageSession.packageType === "TRIAL_TYPE") {
    //   this.hinhThucXacMinh.shift();
    // }
    console.log(this.partiesJoin);
  }

  ngOnInit(): void {
    // this.makeSelect2HinhThucKy();
    // this.makeSelect2PartyJoin();
    // this.makeSelect2Default();
    // this.makeCleave();

    // this.createTableForm();

    this.setData();
    // this.setDataFormArray();
  }

  ngAfterOnInit() {
  }

  ngAfterViewInit(): void {
  }

  /**
   * Gan du lieu 
   */
  setData() {
    this.isSubmit = false;
    this.parties = this.partiesJoin; console.log(this.partiesJoin)
    this.parties.forEach((item) => {
      if (this.partyIdWantEdit != item.partyId) {
        item.allowEdit = false;
      }
    })
  }

  changeUserType($event, i) {
    var value = $event;
    console.log(value);
    this.parties[i].signForm = '';
    this.parties[i].changedHinhThucKy(value);
  }

  close() {
    this.closed.emit(null);
  }

  save() {
    console.log(this.parties);
    this.changed.emit(this.parties);
  }
  

  get validRequired() {
    for (var i = 0; i < this.parties.length; i++) {
      if (this.parties[i].hasGeneralError('required')) {
        return false;
      }
    }

    return true;
  }

  /**
   * Lấy label của các loại hình thức xác minh
   * @param type 
   * @returns 
   */
  showHinhThucXacMinh(type) {
    switch (type) {
      case VerificationType.VIDEO_CALL:
        return "Xác minh";
      case VerificationType.NONE:
        return "Không xác minh";
      default: return "";
    }
  }

  /**
   * Gửi yêu cầu ký lại
   */
  sendRequestResign() { 
    const party: PartyJoinContract = this.parties.find((item) => { return item.partyId == this.partyIdWantEdit});
    let data = {
      signForm: party.signForm
    }

    console.log(this.partyIdWantEdit, party);

    this.sendRequestResignEvent.emit({
      partyId: party.partyId,
      data: data
    })
  }
}
