<!-- <app-spinner></app-spinner> -->
<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0 text-dark"><label>Danh sách API</label></h1>
            </div>
            <!-- /.col -->
            <div class="col-sm-6">
                <!-- <button class="btn btn-primary float-sm-right nav-material" 
          ><em class="material-icons">add</em>
          Thêm mới</button> -->
            </div>
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>

<section class="content">
    <div class="container-fluid">
        <div class="card shadow-none">
            <div class="card-body">
                <div class="row">
                    <div class="col-lg-3 col-md-5">
                        <form class="input-group border rounded" [formGroup]="searchForm">
                            <div class="input-group-prepend border-0">
                                <button class="btn d-inline-flex pr-2 pl-2" type="submit" (click)="submitSearch($event)"><span
                                        class="material-icons">search</span></button>
                            </div>
                            <input type="text" class="form-control border-0" placeholder="Nhập tên chức năng..."
                                formControlName="searchString" aria-describedby="basic-addon1" #searchFieldLicense>
                        </form>
                    </div>
                    <div class="ml-auto ">
                        <button class="btn btn-primary d-inline-flex mr-3" (click)="addApi()">
                            <span class="material-icons">add</span>
                            Thêm mới
                        </button>
                    </div>
                </div>
            </div>
            <div class="table-responsive table table-scroll mb-0">
                <table class="table main-table border border-right-0 border-left-0 mb-0">
                    <thead>
                        <tr>
                            <th>STT</th>
                            <th>Chức năng</th>
                            <th>URL</th>
                            <th>Phương thức</th>
                            <th>Mô tả</th>
                            <th>Thao tác</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of apis; let i = index">
                            <td>
                                {{ i + 1 }}
                            </td>
                            <td>
                                <a class="text-primary text-decoration-underline cursor-pointer"
                                    [routerLink]="['/console/api/manager/detail', item.apiId]">{{ item.privilegesName || 'NULL' }}</a>
                            </td>
                            <td>{{ item.pathName }}</td>
                            <td><span class="badge {{ getStyleBadge(item.method) }} p-2">{{ item.method }}</span></td>
                            <td>{{ item.description }}</td>
                            <td>
                                <div class="dropdown">
                                    <a class="btn btn-dropdown dropdown-toggle" href="#" role="button"
                                        id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        <em class="material-icons">more_vert</em>
                                    </a>

                                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                                        <!-- [routerLink]="['/console/licenses-manager/detail/', item.licenseId]" -->
                                        <a class="dropdown-item nav-material" style="cursor: pointer;"
                                            [routerLink]="[constantUrl.ADMIN_API_DETAIL, item.apiId]"
                                            routerLinkActive="router-link-active">
                                            <em class="material-icons">info</em>
                                            Thông tin chi tiết</a>
                                        <a class="dropdown-item nav-material" style="cursor: pointer;"
                                            [routerLink]="[constantUrl.ADMIN_API_EDIT, item.apiId]"
                                            routerLinkActive="router-link-active">
                                            <em class="material-icons">autorenew</em>
                                            Cập nhật thông tin</a>
                                        <a class="dropdown-item nav-material" style="cursor: pointer;" (click)="actionDeleteApi(item.apiId)">
                                            <em class="material-icons-outlined">delete_outline</em>
                                            Xóa</a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="d-flex pb-3">
            <span *ngIf="totalElement == 0" class="flex-grow-1 ml-1">Không tìm thấy kết quả</span>
            <span *ngIf="totalElement > 0" class="flex-grow-1 ml-1">Hiển thị 1 đến {{apis.length}} trong tổng số
                {{totalElement}} kết quả</span>
            <div *ngIf="totalElement > maxSize" class="pagination justify-content-end pages aas-pagination">
                <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="10" [rotate]="true"
                    [ellipses]="false" [pageSize]="maxSize" (pageChange)="loadPage($event)" [boundaryLinks]="true"
                    aria-label="Default pagination">
                    <ng-template ngbPaginationFirst>Đầu</ng-template>
                    <ng-template ngbPaginationLast>Cuối</ng-template>
                    <ng-template ngbPaginationPrevious>&laquo;</ng-template>
                    <ng-template ngbPaginationNext>&raquo;</ng-template>
                    <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                </ngb-pagination>
            </div>
        </div>

    </div>
</section>