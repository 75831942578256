<app-spinner></app-spinner>
<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card ec-card shadow-none">
                    <div class="card-header">
                        <div class="d-flex">
                            <div class="card-title-path flex-grow-1">
                                <h1 class="m-0 text-dark card-title"><label>{{ 'ContractTypes_add_title' | translate
                                        }}</label></h1>
                            </div>
                            <div class="card-btn-path ml-auto text-right">
                                <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="cancel()"><span
                                        class="material-icons mr-1">close</span>{{ 'btn_cancel' | translate }}</button>
                                <button class="btn btn-primary d-inline-flex" (click)="save()"><span
                                        class="material-icons mr-1">save</span>{{ 'btn_save' | translate }}</button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <form [formGroup]="form">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>{{ 'ContractTypes_name' | translate }} <span
                                                        class="text-danger">*</span>:</label>
                                                <input appTrimValue class="form-control"
                                                    placeholder="{{ 'ContractTypes_ph_name' | translate }}"
                                                    formControlName="name">
                                                <app-show-validate-errors [isSubmit]="isSubmit"
                                                    [errorMessages]="validateError.name" [control]="form.controls.name"
                                                    [detail]="{ name: 'name' }"></app-show-validate-errors>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>