export class PackageInfo {
    capacity: string = '';
    contractNumber: string = '';
    created: string = '';
    description: string = '';
    duration: number ;
    formContractId: string = '';
    modified: string = '';
    name: string = '';
    orderNumber: number;
    packInfoId: string = '';
    packageType: string = '';
    price: number;
    roleId: string = '';
    status: string = '';
    userAmount: number;
    userType: string = '';

    issueDate: string = '';
    expirationDate: string = '';

    constructor(data = null) {
        data = data || {};

        this.capacity = data.capacity || '';
        this.contractNumber = data.contractNumber || '';
        this.created = data.created || '';
        this.description = data.description || '';
        this.duration = data.duration || 0;
        this.formContractId = data.formContractId || '';
        this.modified = data.modified || '';
        this.name = data.name || '';
        this.orderNumber = data.orderNumber;
        this.packInfoId = data.packInfoId || '';
        this.packageType = data.packageType || '';
        this.price = data.price;
        this.roleId = data.roleId || '';
        this.status = data.status || '';
        this.userAmount = data.userAmount;
        this.userType = data.userType || '';

        this.issueDate = data.issueDate || '';
        this.expirationDate = data.expirationDate || '';

    }
}