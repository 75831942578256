export const Config = {
  API_URL:  window["env"]?.API_URL,
  CLIENT_APP: window["env"]?.CLIENT_APP,
  CLIENT_SECRET: window["env"]?.CLIENT_SECRET,
  GRANT_TYPE: window["env"]?.GRANT_TYPE,
  DOMAIN_SUB: window["env"]?.DOMAIN_SUB,
  IDG_VIDEOCALL: window["env"]?.IDG_VIDEOCALL,
  KEY_PLUGIN_CA: window["env"]?.KEY_PLUGIN_CA,
  HOME_DOMAIN: window["env"]?.HOME_DOMAIN,
  CHATHUB_URL: window["env"]?.CHATHUB_URL
}
