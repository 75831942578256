import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ContractNumberConfigService } from 'src/app/utils/services/aas-network/contract-number-config/contract-number-config.service';


@Component({
  selector: 'app-add-contract-number-config',
  templateUrl: './add-contract-number-config.component.html',
  styleUrls: ['./add-contract-number-config.component.scss']
})
export class AddContractNumberConfigComponent implements OnInit, AfterViewInit {

  configDefault = false;

  constructor(
    private cncService: ContractNumberConfigService,
    private alert: AlertControl,
    private router: Router,
    private translate: TranslateService
  ) { }

  // Option thời điểm phát sinh số hợp đồng
  action: string = 'CREATE';
  actionOptions: Array<object> = [
    { value: 'CREATE', label: "ListContractNumberConfig_filter_2" },
    { value: 'SUBMIT', label: "ListContractNumberConfig_filter_3" },
    { value: 'SIGN', label: "ListContractNumberConfig_filter_4" }
  ];

  // Mô phỏng số hợp đồng hiện tại
  cncSimulator: string = "";
  mapPrefixType: object = {
    "SLASH": '/',
    "DASH": '_',
    "SPACE": ' ',
    "": ''
  };
  cncSimulcast() {
    this.cncSimulator = "";
    this.sequenceLine.forEach((value) => {
      this.cncSimulator = this.cncSimulator + this.mapPrefixType[value.prefixType];

      if (value.suffixType == 'NUMBER') {
        this.cncSimulator = this.cncSimulator + value.startNumber;
      }
      else if (value.suffixType == 'STRING') {
        this.cncSimulator = this.cncSimulator + value.suffix;
      }
      else if (value.suffixType == 'VARIABLE') {
        if (value.suffix == 'PARTYNAME') {
          this.cncSimulator = this.cncSimulator + this.translate.instant('AddContractNumberConfig_partA');
        }
        else {
          this.cncSimulator = this.cncSimulator + (this.suffixValue[value.suffix] ?? '');
        }
      }
    });
  }

  // Option thành phần
  ingredientOptions: Array<object> = [
    { value: 'NUMBER', label: 'AddContractNumberConfig_ingredient_1' },
    { value: 'VARIABLE', label: 'AddContractNumberConfig_ingredient_2' },
    { value: 'STRING', label: 'AddContractNumberConfig_ingredient_3' }
  ];

  /**
   * Event khi thay đổi Suffix Type
   * @param sequence 
   * @param suffixType 
   */
  changeSuffixType(sequence: number, suffixType: string) {
    if (suffixType == 'STRING') {
      this.sequenceLine[sequence - 1].suffix = "";
    }
    else if (suffixType == 'VARIABLE') {
      this.sequenceLine[sequence - 1].suffix = "ROOTPATYNAME";
    }
  }

  // Option dấu ngăn cách
  separatorOptions: Array<object> = [
    { value: 'SLASH', label: '/' },
    { value: 'DASH', label: '_' },
    { value: 'SPACE', label: 'AddContractNumberConfig_separator_space' }
  ];

  // Option cấu hình (Thành phần Số thứ tự)
  paddingOptions: Array<object> = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    { value: 8, label: "8" },
    { value: 9, label: "9" }
  ];
  startNumberFormat: Array<any> = [
    [
      { value: "1", label: "1" },
      { value: "001", label: "001" }
    ]
  ];
  /**
   * Event when change padding
   * @param sequence 
   * @param padding 
   */
  changePadding(sequence: number, padding: number) {
    let newFormat;
    if (padding == 1) {
      newFormat = [
        { value: "1", label: "1" }
      ];
    }
    else {
      newFormat = [
        { value: "1", label: "1" },
        { value: "1".padStart(padding, '0'), label: "1".padStart(padding, '0') }
      ];
    }
    this.startNumberFormat[sequence - 1] = newFormat;
    this.sequenceLine[sequence - 1].startNumber = "1";
  }

  // Option reset type
  resetTypeOptions: Array<object> = [
    { value: 'MONTH', label: "AddContractNumberConfig_auto_reset_month" },
    { value: 'YEAR', label: "AddContractNumberConfig_auto_reset_year" },
    { value: '', label: "AddContractNumberConfig_no_auto_reset" },
  ];

  // Option Biến (VARIABLE)
  variableOptions: Array<object> = [
    { value: 'ROOTPATYNAME', label: 'AddContractNumberConfig_variable_1' },
    { value: 'PARTYNAME', label: 'AddContractNumberConfig_variable_2' },
    { value: 'YEAR', label: 'AddContractNumberConfig_variable_3' },
    { value: 'MONTH', label: 'AddContractNumberConfig_variable_4' },
  ];

  sequenceLine: Array<any> = [
    {
      sequence: 1,
      prefixType: "",
      suffixType: "NUMBER",
      suffix: "",
      padding: 3,
      startNumber: "1",
      resetType: ""
    }
  ];

  /**
     * Thêm mới thành phần
     */
  addIngredient() {
    const currentLength = this.sequenceLine.length;
    let prefixType = "SLASH";
    if (currentLength == 0) {
      prefixType = "";
    }
    this.startNumberFormat.push([
      { value: "1", label: "1" },
      { value: "001", label: "001" }
    ]);
    this.sequenceLine.push({
      sequence: currentLength + 1,
      prefixType: prefixType,
      suffixType: "NUMBER",
      suffix: "",
      padding: 3,
      startNumber: "1",
      resetType: ""
    });
    this.cncSimulcast();
  }

  /**
   * Xóa 1 thành phần cấu hình
   * @param index 
   */
  removeIngredient(index: number) {
    this.sequenceLine.splice(index, 1);
    for (let i = index; i < this.sequenceLine.length; i++) {
      this.sequenceLine[i].sequence--;
    }
    this.startNumberFormat.splice(index, 1);
    if (index == 0 && this.sequenceLine.length > 0) {
      this.sequenceLine[0].prefixType = "";
    }
    this.cncSimulcast();
  }

  ngOnInit(): void {
    this.cncSimulcast();
    this.getSuffixValue();
  }

  ngAfterViewInit(): void {

  }

  /**
   * Thêm mới cấu hình số hợp đồng
   */
  save() {
    this.alert.showAlert(this.translate.instant('AddContractNumberConfig_alert_1'), ConstantAlertType.BLANK, true, "",
      this.translate.instant('btn_addnew'), () => {
        if (this.sequenceLine.length <= 0) {
          this.alert.showAlertOnlyNoti(this.translate.instant("AddContractNumberConfig_alert_2"), ConstantAlertType.ERROR);
        }
        else {
          const data = {
            disable: false,
            default: this.configDefault,
            action: this.action,
            sequenceLine: this.sequenceLine
          };
          this.cncService.createContractNumberConfig(data).subscribe((res: any) => {
            this.router.navigate([`app/console/configure-contract-number`]);
          }, err => {
            this.alert.showErrorHandled(err);
          });
        }
      }, "", this.translate.instant('btn_close'), () => { }, this.translate.instant('btn_confirm'))
  }

  /**
   * Action of cancel button
   */
  cancel() {
    // Reset sequenceLine
    this.sequenceLine = [
      {
        sequence: 1,
        prefixType: "",
        suffixType: "NUMBER",
        suffix: "",
        padding: 3,
        startNumber: "1",
        resetType: ""
      }
    ];

    // Reset start number
    this.startNumberFormat = [
      [
        { value: "1", label: "1" },
        { value: "001", label: "001" }
      ]
    ];

    // Reset cncSimulator
    this.cncSimulcast();
  }

  /**
   * lấy giá trị suffix
   */
  suffixValue: any = {
    ROOTPATYNAME: '',
    PARTYNAME: '',
    YEAR: '',
    MONTH: ''
  };
  getSuffixValue() {
    this.cncService.getSuffixValue("ROOTPATYNAME").subscribe((res: any) => {
      this.suffixValue.ROOTPATYNAME = res.object ?? '';
    });
    this.cncService.getSuffixValue("YEAR").subscribe((res: any) => {
      this.suffixValue.YEAR = res.object ?? '';
    });
    this.cncService.getSuffixValue("MONTH").subscribe((res: any) => {
      this.suffixValue.MONTH = res.object ?? '';
    });
  }

  changeCheckConfigDefault() {
    this.configDefault = !this.configDefault;
  }
}
