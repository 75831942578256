import { MainResponse } from "../main-response.model";
import { LandingCategoryItem } from "./landing-category-item.model";

export class LandingCategoryResponse extends MainResponse{
  constructor(obj = null){
    super();
    obj = obj || {};
    this.init(obj);
    if (obj.object && obj.object instanceof Array) {
      obj.object.forEach((item) => {
          this.object = new LandingCategoryItem(item);
      })
    }
  }
}