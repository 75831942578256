<app-spinner></app-spinner>
<div class="content-header">
	<div class="container-fluid">
		<div class="row mb-2">
			<div class="col-md-auto">
				<h1 class="m-0 text-dark"><label>Thêm mới gói dịch vụ</label></h1>
			</div>
			<!-- /.col -->
			<div class="col-md-auto ml-auto">
				<!-- <button (click)="addFlow()" class="btn btn-primary d-inline-flex mr-3"><span
                      class="material-icons mr-1">add</span> Thêm mới</button> -->
			</div>
			<!-- <!-- /.col -->
		</div>
		<!-- /.row -->
	</div>
	<!-- /.container-fluid -->
</div>

<section class="content">
	<div class="container-fluid">
		<div class="card shadow-none mb-4">
			<div class="card-body">

				<div class="d-flex">
					<h5 class="text-primary font-weight-bold m-0">Thông tin chung</h5>
					<div class="col-md-auto ml-auto">
						<button class="btn btn-outline-primary d-inline-flex mr-3" (click)="clearData()">
							<em class="material-icons nav-icon mr-1">clear</em>
							Hủy
						</button>
						<button class="btn btn-primary d-inline-flex" (click)="actSaveInfo()">
							<em class="material-icons nav-icon mr-1">save</em>
							Lưu
						</button>
					</div>
				</div>
				<hr class="my-4">

				<div class="row">
					<div class="col-12">
						<form [formGroup]="form">
							<div class="row">
								<div class="col-md-6">
									<div class="form-group">
										<label>Tên gói dịch vụ <span class="text-danger">*</span></label>
										<input appTrimValue type="text" formControlName="name"
											class="form-control" placeholder="Nhập tên gói cước" />
										<app-show-validate-errors [useSubmit]="false"
											[isSubmit]="isSubmit" [errorMessages]="validateMsg.name"
											[control]="form.controls.name" [detail]="{ name: 'name' }">
										</app-show-validate-errors>
									</div>
								</div>
								<div class="col-md-6">
									<div class="form-group">
										<label>Mô tả <span class="text-danger">*</span></label>
										<input appTrimValue type="text" formControlName="description"
											class="form-control"
											placeholder="Nhập thông tin mô tả của gói cước" />
										<app-show-validate-errors [useSubmit]="false"
											[isSubmit]="isSubmit"
											[errorMessages]="validateMsg.description"
											[control]="form.controls.description"
											[detail]="{ name: 'description' }">
										</app-show-validate-errors>
									</div>
								</div>
							</div>

							<div class="row">
								<div class="col-md-6">
									<div class="row">
										<div class="col-md-6">
											<div class="form-group">
												<label>Loại gói dịch vụ <span class="text-danger">*</span></label>
												<ng-select
													formControlName="packageType"
													[items]="packageTypeData"
													bindLabel="label"
													bindValue="value"
													placeholder="Vui lòng chọn loại gói dịch vụ"
												>
												</ng-select>
												<app-show-validate-errors [useSubmit]="false"
													[isSubmit]="isSubmit"
													[errorMessages]="validateMsg.packageType"
													[control]="form.controls.packageType"
													[detail]="{ name: 'packageType' }">
												</app-show-validate-errors>
											</div>
										</div>
										<div class="col-md-6">
											<div class="form-group">
												<label>Loại khách hàng áp dụng <span
														class="text-danger">*</span></label>
												<ng-select
													formControlName="userType"
													[items]="dropListsUserType"
													bindLabel="text"
													bindValue="id"
													(change)="changeUserType($event)"
													[placeholder]="'Chọn loại khách hàng áp dụng cho gói cước'"
												></ng-select>
												<app-show-validate-errors [useSubmit]="false"
													[isSubmit]="isSubmit" [errorMessages]="validateMsg.userType"
													[control]="form.controls.userType"
													[detail]="{ name: 'userType' }">
												</app-show-validate-errors>
											</div>
										</div>
									</div>
								</div>
								<div class="col-md-6">
									<div class="row">
										<div class="col-md-6">
											<div class="form-group">
												<label>Thời hạn sử dụng (tháng) <span class="text-danger">*</span></label>
												<input appTrimValue type="text" formControlName="duration"
													class="form-control" placeholder="Nhập thời hạn sử dụng" />
												<app-show-validate-errors [useSubmit]="false"
													[isSubmit]="isSubmit" [errorMessages]="validateMsg.duration"
													[control]="form.controls.duration"
													[detail]="{ name: 'duration' }">
												</app-show-validate-errors>
											</div>
										</div>
										<div class="col-md-6">
											<div class="form-group">
												<label>Thứ tự hiển thị <span class="text-danger">*</span></label>
												<input type="text" formControlName="orderNumber"
													class="form-control"
													[placeholder]="'Nhập thứ tự hiển thị'" />
												<a class="" routerLinkActive="router-link-active"
													[routerLink]="" (click)="openModal()"><u>Xem danh sách gói
														cước đang hiển thị</u></a>
												<app-show-validate-errors [useSubmit]="false"
													[isSubmit]="isSubmit"
													[errorMessages]="validateMsg.orderNumber"
													[control]="form.controls.orderNumber"
													[detail]="{ name: 'orderNumber' }">
												</app-show-validate-errors>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="row">
								<div class="col-md-6">
									<div class="row">
										<div class="col-md-6">
											<div class="form-group">
												<label>Giá tiền (VNĐ) <span class="text-danger">*</span></label>
												<input appTrimValue type="text" formControlName="price"
													class="form-control" placeholder="Nhập giá tiền" />
												<app-show-validate-errors [useSubmit]="false"
													[isSubmit]="isSubmit" [errorMessages]="validateMsg.price"
													[control]="form.controls.price" [detail]="{ name: 'price' }">
												</app-show-validate-errors>

												<div class="tcheck-primary">
													<input type="checkbox" id="same_infra" [checked]="sameInfra">
													<label for="same_infra" class="w-100" [class.checked]="sameInfra"
														(click)="changeSameInfra()">
														<em>Lấy theo giá tiền của hạ tầng</em>
													</label>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>

				<div class="d-flex">
					<h5 class="text-primary font-weight-bold m-0">Thông tin hạ tầng</h5>
					<div class="col-md-auto ml-auto">
					</div>
				</div>
				<hr class="my-4">
				<div class="row">
					<div class="col-md-12">
						<div class="row">
							<div class="col-md-6">
								<div class="form-group">
									<label>Loại danh mục hạ tầng <span class="text-danger">*</span></label>
									<ng-select
										[(ngModel)]="dmHaTang"
										[items]="danhMucHaTang"
										bindLabel="name"
										bindValue="serviceInfoId"
										(change)="eventChangeDmHaTang($event)"
										[clearable]="true"
										[placeholder]="'Chọn loại danh mục hạ tầng'"
									></ng-select>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="d-flex">
					<h5 class="text-primary font-weight-bold m-0">Thông tin chức năng</h5>
					<div class="col-md-auto ml-auto">
					</div>
				</div>
				<hr class="my-4">
				<div class="row">
					<div class="col-md-12">
						<div class="row">
							<div class="col-md-6">
								<div class="form-group">
									<label>Loại danh mục chức năng <span class="text-danger">*</span></label>
									<ng-select
										[(ngModel)]="dmChucNang"
										[items]="danhMucChucNang"
										bindLabel="name"
										bindValue="serviceInfoId"
										(change)="eventChangeDmChucNang($event)"
										[clearable]="true"
										[placeholder]="'Chọn loại danh mục chức năng'"
									></ng-select>
								</div>
							</div>
						</div>
					</div>
				</div>









				<div class="row mt-3" *ngIf="userType.value ==='CONSUMER' || userType.value === 'BUSINESS' ">
					<div class="col-md-12">
						<label class="text-primary">
							Thông tin chức năng
						</label>
						<hr>
					</div>
					<div class="card-body pt-0">
						<div class="row">
							<div class="col-md-12 border px-3 py-2">
								<div class="row pt-2 pb-2">
									<div class="col-md-auto align-self-end">
										<p class="mb-2"><label class="mb-0">Danh sách chức năng</label></p>
									</div>
								</div>

								<div class="input-group mb-3 col-md-6">
									<div class="input-group-prepend bg-white">
										<span class="input-group-text bg-white border-right-0 pl-1 pr-1"><span
												class="material-icons">search</span></span>
									</div>
									<input #searchNotAssigned (input)="filterSearch($event, 0)" type="text"
										class="form-control border-left-0 pl-1" placeholder="Nhập tên chức năng">

								</div>

								<div class="list-permission enable-scroll">
									<ul class="content-list-permisson">
										<li *ngIf="dualList[0].length > 0">
											<div class="permission-group">
												<span class="material-icons text-white p-2">check</span>
												<div class="tcheck-primary w-100 d-inline-block">
													<!-- <input (input)="selectAllBtn(0)" type="checkbox" id="select-all-assign" [attr.checked]="selectedAll[0]"> -->
													<label for="select-all-assign" class="w-100"
														[class.checked]="selectedAll[0]">
														Các chức năng nội bộ
													</label>
												</div>
												<span
													class="material-icons icon-set-permission text-white p-2">arrow_forward</span>
											</div>
										</li>
										<li *ngFor="let li of dualList[0]; let i = index">
											<div class="permission-group">
												<span (click)="showHideTreeView($event, i, 0)"
													class="material-icons p-2 input-group-prepend"
													style="cursor: pointer;"
													[style.transform]="isExpandedFrom.checked || li.showChild  ? 'rotate(90deg)' : 'rotate(0deg)'">
													<input type="checkbox" #isExpandedFrom hidden>
													chevron_right</span>

												<div class="tcheck-primary w-100 d-inline-block">
													<!-- <input (input)="selectedParentItem(i, 0)" data-checkbox-permission-parent-checked-if="li.selected"
                                    data-checkbox-permission-parent-indeterminate-if="li.child.selected" 
                                    [attr.checked]="li.selected"
                                          type="checkbox" 
                                          id="parent-{{li.id}}"> -->
													<label for="parent-{{li.id}}" class="w-100" style="cursor: auto;"
														[class.checked]="li.selected">
														{{li.name}}
													</label>
												</div>
												<!-- <span *ngIf="li.assigned == false" (click)="assignGroup(i, 0, 1)"
                                      class="material-icons icon-set-permission p-2 " style="cursor: pointer;">arrow_forward</span> -->
											</div>

											<ul *ngIf="li.child.length > 0 && li.showChild" class="permission-child "
												data-target="checkbox-permission">
												<li *ngFor="let subli of li.child; let j = index">
													<div class="tcheck-primary w-100 d-inline-block">
														<!-- <input (input)="selectedChildItem(i, j, 0)"
                                              [attr.checked]="subli.selected" type="checkbox"
                                              id="child-{{subli.id}}"> -->
														<label for="child-{{subli.id}}" class="w-100"
															style="cursor: auto;" [class.checked]="subli.selected">
															{{subli.name}}
														</label>
													</div>
													<!-- <span *ngIf="subli.assigned == false" (click)="assign(i, j, 0, 1)"
                                          class="material-icons icon-set-permission p-2">arrow_forward</span> -->
												</li>
											</ul>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row mt-3" *ngIf="userType.value === 'ENTERPRISE'">
					<div class="col-md-12">
						<label class="text-primary">
							Thông tin danh mục dịch vụ
						</label>
						<hr>
					</div>
					<div class="card-body pt-0">
						<div class="row">
							<div class="col-md-6 border px-3 py-2">
								<div class="row pt-2 pb-2">
									<div class="col-md-auto align-self-end">
										<p class="mb-2"><label class="mb-0">Danh mục dịch vụ</label></p>
									</div>
									<div class="col-md-auto ml-auto">
										<button (click)="assignMoreSelected(0, 1)" class="btn btn-primary d-inline-flex"
											[attr.disabled]="enableBtnAssign(0) ? '' : null">Gán<span
												class="material-icons ml-1">arrow_forward</span></button>
									</div>
								</div>

								<div class="input-group mb-3">
									<div class="input-group-prepend bg-white">
										<span class="input-group-text bg-white border-right-0 pl-1 pr-1"><span
												class="material-icons">search</span></span>
									</div>
									<input #searchNotAssigned (input)="filterSearch($event, 0)" type="text"
										class="form-control border-left-0 pl-1" placeholder="Nhập tên chức năng">

								</div>
								<div class="list-permission enable-scroll">
									<ul class="content-list-permisson">
										<li *ngIf="dualList[0].length > 0">
											<div class="permission-group">
												<div class="tcheck-primary w-100 d-inline-block">
													<input (input)="selectAllBtn(0)" type="checkbox"
														id="select-all-assign" [attr.checked]="selectedAll[0]">
													<label for="select-all-assign" class="w-100"
														[class.checked]="selectedAll[0]">
														Tất cả
													</label>
												</div>
												<span
													class="material-icons icon-set-permission text-white p-2">arrow_forward</span>
											</div>
										</li>
										<li *ngFor="let li of dualList[0]; let i = index">
											<div class="permission-group">

												<div class="tcheck-primary w-100 d-inline-block">
													<input (input)="selectedParentItem(i, 0)"
														[attr.checked]="li.selected" type="checkbox"
														id="parent-{{li.id}}">
													<label for="parent-{{li.id}}" class="w-100" style="cursor: auto;"
														[class.checked]="li.selected">
														{{li.name}}
													</label>
												</div>
												<span *ngIf="li.assigned == false" (click)="assignGroup(i, 0, 1)"
													class="material-icons icon-set-permission p-2 "
													style="cursor: pointer;">arrow_forward</span>
											</div>
										</li>
									</ul>
								</div>
							</div>
							<div class="col-md-6 border px-3 py-2">
								<div class="row pt-2 pb-2">
									<div class="col-md-auto align-self-end">
										<p class="mb-2"><label class="mb-0">Danh mục dịch vụ đã chọn</label></p>
									</div>
								</div>
								<div class="input-group mb-3">
									<div class="input-group-prepend bg-white">
										<span class="input-group-text bg-white border-right-0 pl-1 pr-1">
											<span class="material-icons">search
											</span>
										</span>
									</div>
									<input #searchAssigned (input)="filterSearch($event, 1)" type="text"
										class="form-control border-left-0 pl-1" placeholder="Nhập tên chức năng">
								</div>

								<div class="list-permission">
									<ul class="content-list-permisson">
										<li *ngFor="let li of dualList[1]; let i = index">
											<div class="permission-group">
												<div class="tcheck-primary w-100 d-inline-block">
													<label for="assigned-parent-{{li.id}}" class="w-100"
														style="cursor: auto;" [class.checked]="li.selected">
														{{li.name}}
													</label>
												</div>
												<span (click)="assignGroup(i, 1, 0)"
													class="material-icons icon-set-permission p-2"
													style="cursor: pointer;">clear</span>
											</div>

										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</section>


<ng-template #showInfoPackageOrder let-c="close" let-d="dismiss">
	<div class="modal-header border-0 pb-0 mb-0">
		<button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
			<span class="material-icons">
				cancel
			</span>
		</button>
	</div>
	<div class="modal-body py-0">
		<div class="table-responsive table table-scroll">
			<table class="table main-table border">
				<thead>
					<tr style="background-color: rgba(213, 213, 213, 0.16);">
						<th>STT</th>
						<th>Tên gói dịch vụ</th>
						<th>Thứ tự hiển thị</th>
					</tr>
				</thead>
				<tbody *ngIf="listBusinesPackage.length > 0">
					<tr *ngFor="let item of listBusinesPackage; let i = index">
						<td>{{i + 1}}</td>
						<td>{{item.name}}
							<span *ngIf="item.packageType === 'TRIAL_TYPE'">(mặc định)</span>
						</td>
						<td>{{item.orderNumber}}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</ng-template>