import { UtilsFunc } from "src/app/utils/common/utils-func";
import { NotePoint } from "./note-point.model";

declare var $: any;
export class CommentPdf {
    currentPage = 1;
    navSelectedPage = 0;
    listPage = [];
    listNotePoint: NotePoint[] = [];
    noteSelected: NotePoint = null;

    constructor() {

    }

    /**
     * Lắng nghe sự kiện click vào nội dung file pdf
     * @param callBack 
     */
    setEventClickOnPDF(callBack = (data) => {}) {
        let self = this;
        $(".page-view").on("click", function (e) {
            let pageIndex = $(this).attr("data-page-index");

            // Loại bỏ những thành phần chưa được lưu
            self.removeNotePointNotSaved();

            callBack(self.progressClickData(e, pageIndex));
        })
    }

    /**
     * Dừng lắng nghe sự kiện click vòa nội dụng file PDF
     */
    removeEventClickOnPDF() {
        $(".page-view").on("click", function (e) {return});
    }

    /**
     * Xoá bỏ tất cả note point trên màn hình
     */
    removeAllNotePoint() {
        this.noteSelected = null;
        // Loại bỏ các element trên DOM
        this.listNotePoint.forEach((x) => {
            $('#np-' + x.id).remove();
        });
        // Loại bỏ thông tin các element trong mảng lưu vết
        this.listNotePoint = [];
    }

    /**
     * Loại bỏ những vị trí chưa được lưu trên màn hinh
     */
    removeNotePointNotSaved() {
        // Loại bỏ các element chưa lưu trên DOM
        this.listNotePoint.forEach((x) => {
            if (x.saved == false) {
                $('#np-' + x.id).remove();
            }
        });
        // Loại bỏ thông tin các element trong mảng lưu vết
        this.listNotePoint = this.listNotePoint.filter((x) => { return x.saved == true; });

        console.log(this.listNotePoint);
    }

    /**
     * Xử lý dữ liệu khi click
     * @param e 
     * @param pageIndex 
     * @returns 
     */
    progressClickData(e, pageIndex) {
        var notePoint = this.getNotePointNoScale(e, pageIndex);

        let data = {
            event: e,
            pageIndex: pageIndex,
            notePoint: notePoint
        }

        return data;
    }

    /**
     * Lấy thông tin toạ độ thật
     * @param e 
     * @param pageIndex 
     * @returns 
     */
    getNotePointNoScale(e, pageIndex) {
        if (e && pageIndex) {
            var scale = this.getScale('page-pdf-' + pageIndex, pageIndex);
    
            var notePoint = new NotePoint({
                page: pageIndex,
                x: Math.floor(e.offsetX / scale),
                y: Math.floor(e.offsetY / scale)
            });

            return notePoint;
        }

        return null;
    }

    /**
     * Thêm note vào pdf
     * @param e 
     * @param pageIndex 
     */
    addNotePoint(e, pageIndex, data = null, callBackClick = null) {
        console.log("called");
        var pdf_page = $('#page-pdf-' + pageIndex); 
        var scale = this.getScale('page-pdf-' + pageIndex, pageIndex);
        var offsetX = e?.offsetX || (data?.notePoint?.x ? Math.floor(data?.notePoint?.x * scale) : 0);
        var offsetY = e?.offsetY || (data?.notePoint?.y ? Math.floor(data?.notePoint?.y * scale) : 0);
        // console.log(offsetX, offsetY);

        var notePoint: NotePoint = null;
        if (!e || e == null || e == undefined) {
            // Trường hợp không thêm từ event click
            notePoint = data?.notePoint
        } else {
            // Trường hợp thêm từ event click
            notePoint = new NotePoint({
                id: data?.notePoint?.id || "",
                page: pageIndex,
                x: Math.floor(offsetX / scale),
                y: Math.floor(offsetY / scale),
                contractId: data?.contractId || "",
                traceId: data?.traceId || "",
            });
        }
        this.listNotePoint.push(notePoint);
        this.noteSelected = notePoint;

        // Tạo thành phần note mới trên giao diện
        var div = $('<div class="ec-note-point" id="np-' + notePoint.id + '">');
        var boundX = pdf_page[0].offsetLeft;
        var boundY = pdf_page[0].offsetTop;
        div.css({
            "left": (offsetX + boundX - 16) + 'px',
            "top": (offsetY + boundY - 16) + 'px',
            'position': 'absolute'
        })
        .appendTo($("#parentView"));

        if (notePoint.saved == true) {
            this.setEventWhenClickNotePoint(notePoint.id, callBackClick);
        }
    }

    /**
     * Tạo handle sự kiện click vào các note point
     * @param idNotePoint 
     * @param callback 
     */
    setEventWhenClickNotePoint(idNotePoint: string, callback = null) {
        if (idNotePoint) {
            let self = this;
            $("#np-" + idNotePoint).on("click", function(e) {
                if (callback) {
                    self.removeNotePointNotSaved();
                    callback(idNotePoint);
                }
            })
        }
    }

    exitPopupComment() {
        if (this.noteSelected.saved == true) {

        } else if (this.noteSelected.saved == false) {
            let index = this.listNotePoint.findIndex((x) => {return x.id == this.noteSelected.id});
            if (index >= 0) {
                $('#np-' + this.noteSelected.id).remove();
                this.listNotePoint.splice(index, 1);
            }
        }
    }

    /**
     * Đánh dấu đã lưu 
     */
    tickSavedNotePoint(idSaved: string, dataSaved: NotePoint = null) {
        console.log("tickSavedNotePoint ", idSaved, dataSaved);
        console.log("tickSavedNotePoint ", this.listNotePoint);
        console.log("tickSavedNotePoint ", this.noteSelected);
        
        var notePoint = this.listNotePoint.find((x) => {return x.id == this.noteSelected.id});
        console.log("tickSavedNotePoint", notePoint);
        if (notePoint) {
            $('#np-' + notePoint.id).attr("id", "np-" + idSaved);
            if (dataSaved) {
                notePoint = dataSaved;
            }
            notePoint.saved = true;
        }
    }

    /**
     * Lấy tỉ lệ co giãn theo kích thước thật của file so với hiển thị
     * @param self 
     * @param pageIndex 
     * @returns 
     */
    getScale(self = null, pageIndex) {
        var padding = 40;
        if (self !== null && document.getElementById(self) !== null && document.getElementById(self) !== undefined) {
            var offsetWidth = document.getElementById(self).offsetWidth;
            // console.log(this.listPage[pageIndex - 1]);
            return offsetWidth / this.listPage[pageIndex - 1].width;
        }
    }
    
}