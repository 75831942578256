import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbDatepicker, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { ConstantAlertBtn, ConstantAlertMsg } from 'src/app/utils/common/constant-alert-msg';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { API_KEY_FILTER_FORM, API_KEY_SEND_MAIL_FORM } from 'src/app/utils/common/constant-vaildate-msg';
import { DateUtils } from 'src/app/utils/common/DateUtils';
import { REGEXP_DATE_INPUT, REGEXP_EMAIL } from 'src/app/utils/common/regexp';
import { ApiKeyItem } from 'src/app/utils/models/api-key/api-key-list/api-key-item';
import { ApiKeyListObject } from 'src/app/utils/models/api-key/api-key-list/api-key-list-object.model';
import { ApiKeyService } from 'src/app/utils/services/aas-network/api-key.service';
import { Select2OptionData } from 'ng-select2';
import { Options } from 'select2';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { API_KEY_STATUS } from 'src/app/utils/common/constant';

declare var Cleave: any;
@Component({
  selector: 'app-api-key-manager',
  templateUrl: './api-key-manager.component.html',
  styleUrls: ['./api-key-manager.component.scss']
})
export class ApiKeyManagerComponent implements OnInit, AfterViewInit {
  @ViewChild('issueDateForm') issueDateForm: ElementRef;
  @ViewChild('expriationDateForm') expriationDateForm: ElementRef;
  @ViewChild('issueDateFormPickDate') issueDateFormPickDate: NgbDatepicker
  @ViewChild('sendMailContent') _sendMail: ElementRef;
  
  formFilter: FormGroup;
  isSubmitFilterForm: boolean = false;
  formFilterValidateMsg: any = API_KEY_FILTER_FORM;

  formSendMail: FormGroup;
  isSubmitFormSendMail: boolean = false;
  validateSendMailMsg = API_KEY_SEND_MAIL_FORM;
  modalSendMail: any;

  page: number = 1;
  maxSize: number = 10;
  propertiesSort: string = 'created';
  sort: string = 'DESC';
  totalElement: number = 0;
  customerType: string = 'ENTERPRISE';//'BUSINESS';

  applyIssueDate: string = '';
  applyExpirationDate: string = '';
  applyStatus: string = '';

  currentDate = new Date();
  dateType = {
    ISSUE: 'ISSUE',
    EXPRIRED: 'EXPRIRED'
  }

  apiKeys: Array<ApiKeyItem> = [];

  statusType = {
    ENABLED: 'ENABLED',
    DISABLED: 'DISABLED'
  }
  statusOptions = [
    { id: 'ALL', text: 'Tất cả'},
    { id: 'ENABLED', text: 'Đang hoạt động'},
    { id: 'DISABLED', text: 'Đã khóa'}
  ]
  optionsStatus: Options;
  constantStatus = {
    ENABLED: {
      text: 'Đang hoạt động',
      class: 'badge-success-new'
    },
    DISABLED: {
      text: 'Đã khóa',
      class: 'badge-danger-new'
    },
    DEFAULT: {
      text: '',
      class: 'd-none'
    }
  }

  dropdownConfigTable = [
    { id: 'tenKhachHang', text: 'Tên tổ chức', show: true },
    { id: 'email', text: 'Email tài khoản tổ chức', show: true },
    { id: 'apiKey', text: 'API Key', show: true },
    { id: 'packageName', text: 'Gói cước', show: true },
    { id: 'issueDate', text: 'Ngày bắt đầu', show: true },
    { id: 'expirationDate', text: 'Ngày hết hạn', show: true },
    { id: 'status', text: 'Trạng thái', show: true }
  ]
  configTable = {
    tenKhachHang: true,
    email: true,
    apiKey: true,
    packageName: true,
    issueDate: true,
    expirationDate: true,
    status: true
  }

  lockButtonTitle = {
    ENABLED: 'Khóa',
    DISABLED: 'Mở khóa',
    '': 'Khóa'
  }

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private router: Router,
    private apiKeyService: ApiKeyService
  ) { }

  ngOnInit(): void {
    this.makeFormFilter();
    this.makeOptionsSelector();
    this.makeFormSendMail();

    this.loadPage(1);
  }

  ngAfterViewInit(): void {
    this.makeAutoFormatDate();
  }

  /**
   * Khoi tao form filter
   */
  makeFormFilter() {
    this.formFilter = this.fb.group({
      search: [''],
      issueDate: ['', [Validators.pattern(REGEXP_DATE_INPUT)]],
      expirationDate: ['', [Validators.pattern(REGEXP_DATE_INPUT)]],
      status: ['ALL']
    })
  }

  /**
   * Handle nhap ngay thang nam
   */
  makeAutoFormatDate() {
    let self = this;
    let idIssueDate = '#issueDate-api-key-admin';
    new Cleave(idIssueDate, {
      date: true,
      delimiter: '/',
      datePattern: ['d', 'm', 'Y'],
      onValueChanged: function (e) {
        self.formFilter.controls.issueDate.setValue(e.target.value);
      }
    });

    let idExpriationDate = '#expriationDate-api-key-admin';
    new Cleave(idExpriationDate, {
      date: true,
      delimiter: '/',
      datePattern: ['d', 'm', 'Y'],
      onValueChanged: function (e) {
        self.formFilter.controls.expirationDate.setValue(e.target.value);
      }
    });
  }

  makeOptionsSelector() {
    this.optionsStatus = {
      // minimumResultsForSearch: Infinity,
      dropdownAutoWidth: true,
      width: '100%',
      closeOnSelect: true,
      language: "vi"
    }
  }

    /**
   * Khoi tao form gui mail
   */
  makeFormSendMail() {
    this.formSendMail = this.fb.group({
      licenseId: [''],
      email: ['', [Validators.required ,Validators.pattern(REGEXP_EMAIL)]],
      username: ['']
    });
  }

  /**
   * DatePicker change
   * @param event 
   * @param type 
   */
  changeDate(event, type) {
    switch (type) {
      case this.dateType.ISSUE: 
        this.formFilter.controls.issueDate.setValue(DateUtils.convertObjtoDate(event));
        break;
      case this.dateType.EXPRIRED:
        this.formFilter.controls.expirationDate.setValue(DateUtils.convertObjtoDate(event));
        break;
    }
  }

  get searchString() {
    return this.formFilter.value.search || '';
  }

  get issueDate() {
    return DateUtils.convertInputToFormat(this.formFilter.value.issueDate, DateUtils.format.API);
  }

  get expirationDate() {
    return DateUtils.convertInputToFormat(this.formFilter.value.expirationDate, DateUtils.format.API);
  }

  get status() {
    return this.formFilter.value.status === 'ALL' ? '' : this.formFilter.value.status;
  }

  /**
   * An hien cot tren table
   * @param e 
   * @param element 
   */
  actConfigTable(e, element) {
    e.stopPropagation();
    this.configTable[element] = !this.configTable[element];
  }

  /**
   * Tim kiem
   * @param searchString 
   */
  actSearch(searchString) {
    this.loadPage(1);
  }

  /**
   * Dat lai gia tri filter
   */
  cleanFilter() {
    this.isSubmitFilterForm = false;

    this.issueDateForm.nativeElement.value = '';
    this.expriationDateForm.nativeElement.value = '';

    this.formFilter.controls.issueDate.reset();
    this.formFilter.controls.expirationDate.reset();
    this.formFilter.controls.status.reset();

    this.formFilter.controls.search.reset();

    //Select vao option tat ca
    this.formFilter.patchValue({
      status: 'ALL'
    });

    this.applyIssueDate = this.issueDate;
    this.applyExpirationDate = this.expirationDate;
    this.applyStatus = this.status;

    this.dropdownConfigTable = [
      { id: 'tenKhachHang', text: 'Tên tổ chức', show: true },
      { id: 'email', text: 'Email tài khoản tổ chức', show: true },
      { id: 'apiKey', text: 'API Key', show: true },
      { id: 'packageName', text: 'Gói cước', show: true },
      { id: 'issueDate', text: 'Ngày bắt đầu', show: true },
      { id: 'expirationDate', text: 'Ngày hết hạn', show: true },
      { id: 'status', text: 'Trạng thái', show: true }
    ]
    this.configTable = {
      tenKhachHang: true,
      email: true,
      apiKey: true,
      packageName: true,
      issueDate: true,
      expirationDate: true,
      status: true
    }

    this.loadPage(1);
  }

  /**
   * Tim kiem voi filter
   */
  searchWithFilter() {
    this.isSubmitFilterForm = true;
    this.applyIssueDate = this.issueDate;
    this.applyExpirationDate = this.expirationDate;
    this.applyStatus = this.status;

    if (this.formFilter.valid) {
      if (this.issueDate.length > 0 && this.expirationDate.length > 0) {
        let issueDate = new Date(this.issueDate).getTime() || -1;
        let expirationDate = new Date(this.expirationDate).getTime() || -1;

        if (issueDate > 0 && expirationDate > 0 && issueDate > expirationDate) {
          this.formFilter.controls.issueDate.setErrors({ minDate: true });
        }
      }
    }

    if (this.formFilter.valid) {
      this.loadPage(1);
    }
  }

  /**
   * Tai trang du lieu
   * @param page 
   */
  loadPage(page) {
    this.page = page;
    this.getListApiKeyAdmin(this.page, this.maxSize, this.sort, this.propertiesSort, 
      this.searchString, this.applyIssueDate, this.applyExpirationDate, this.applyStatus, this.customerType);
  }

  /**
   * Chuyen trang xem chi tiet
   * @param id 
   */
  goToDetailApiKey(id) {
    this.router.navigate([ConstantUrl.ADMIN_API_KEY_DETAIL, id]);
  }

  sendMail(id, email, username) {
    this.createPopupSendMail(id, email, username);
  }

  /**
   * Tao popup gui mail
   */
  createPopupSendMail(id, email, username) {
    this.isSubmitFormSendMail = false;
    this.formSendMail.reset();
    this.formSendMail.patchValue({
      licenseId: id,
      email: email,
      username: username
    });

    this.modalSendMail = this.modalService.open(this._sendMail, {
      size: 'md',
      backdrop: 'static',
      keyboard: false
    });
  }

  /**
   * Button Xac nhan gui mail
   */
  confirmSendMail() {
    this.isSubmitFormSendMail = true;
    if (this.formSendMail.valid) {
      this.alert(ConstantAlertMsg.API_KEY_SEND_MAIL_CONFIRM, ConstantAlertType.BLANK, true, '', ConstantAlertBtn.CONFIRM, () => {
        this.modalSendMail && this.modalSendMail.close();
        this.postSendMail(this.formSendMail.value.licenseId, this.formSendMail.value.email, this.formSendMail.value.username);
      })
    }
  }

  /**
   * Khoa
   */
  lockApiKey(id) {
    this.alert(ConstantAlertMsg.API_KEY_LOCK, ConstantAlertType.BLANK, true, '', ConstantAlertBtn.CONFIRM, () => {
      this.putChangeStatusApiKey(id, API_KEY_STATUS.DISABLED);
    })
  }

  /**
   * Mo khoa
   */
  unlockApiKey(id) {
    this.alert(ConstantAlertMsg.API_KEY_UNLOCK, ConstantAlertType.BLANK, true, '', ConstantAlertBtn.CONFIRM, () => {
      this.putChangeStatusApiKey(id, API_KEY_STATUS.ENABLED);
    })
  }

  /**
   * Doi trang thai api key
   * @param oldStatus 
   */
  changeStatus(id, oldStatus) {
    console.log("Change status");
    switch(oldStatus) {
      case API_KEY_STATUS.ENABLED:
        this.lockApiKey(id);
        break;
      case API_KEY_STATUS.DISABLED:
        this.unlockApiKey(id);
        break;
    }
  }

  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = ConstantAlertBtn.OK, actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = ConstantAlertBtn.CANCEL, actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], ConstantAlertType.ERROR);
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, ConstantAlertType.ERROR);
    } else {
      this.alert(ConstantAlertMsg.DEFAULT_ERROR_REQUEST, ConstantAlertType.ERROR);
    }
  }

  //MARK: NETWORKING
  /**
   * Danh sach api key
   * @param page 
   * @param maxSize 
   * @param sort 
   * @param propertiesSort 
   * @param searchString 
   * @param issueDate 
   * @param expirationDate 
   * @param status 
   * @param customerType 
   */
  getListApiKeyAdmin(page, maxSize, sort, propertiesSort, searchString, issueDate, expirationDate, status, customerType) {
    this.apiKeyService.getListApiKeyAdmin(page, maxSize, sort, propertiesSort, searchString, issueDate, expirationDate, status, customerType).subscribe((res: any) => {
      let result = new ApiKeyListObject(res.object || {});

      this.apiKeys = result.data || [];
      this.totalElement = result.totalElement;
    }, err => {
    });
  }

  /**
   * Gui mail api key
   * @param id 
   * @param email 
   */
  postSendMail(id, email, username) {
    this.apiKeyService.postSendMail(id, email, username).subscribe((res: any) => {
      this.modalSendMail && this.modalSendMail.close();
      this.alert(ConstantAlertMsg.API_KEY_SEND_MAIL_SUCCESS, ConstantAlertType.SUCCESS);
    }, err => {
      this.handleError(err, ResponseMsg.MESSAGES.MANAGEMENT_SERVICE);
    });
  }

  /**
   * Doi trang thai api key
   * @param id 
   * @param status 
   */
  putChangeStatusApiKey(id, status) {
    this.apiKeyService.putChangeStatusApiKey(id, status).subscribe((res: any) => {
      switch(status) {
        case API_KEY_STATUS.ENABLED:
          this.alert(ConstantAlertMsg.API_KEY_UNLOCK_SUCCESS, ConstantAlertType.SUCCESS);
          break;
        case API_KEY_STATUS.DISABLED:
          this.alert(ConstantAlertMsg.API_KEY_LOCK_SUCCESS, ConstantAlertType.SUCCESS);
          break;
      }
      this.getListApiKeyAdmin(this.page, this.maxSize, this.sort, this.propertiesSort, 
        this.searchString, this.applyIssueDate, this.applyExpirationDate, this.applyStatus, this.customerType);
    }, err => {
      this.handleError(err, ResponseMsg.MESSAGES.MANAGEMENT_SERVICE);
    })
  }
}
