<div class="feature">
    <section class="feature-ss1">
        <div class="container">
            <div class="row">
                    <div class="col-md-6 col-xl-5">
                    <div class="row row_feature-ss1">
                        <div class="content-left">
                            <img src="{{ digitalCertSignFeature?.blogs[0]?.avatar }}" alt="">
                            <h3 class="title">{{ digitalCertSignFeature?.category?.categoryName }}</h3>
                            <span>{{ digitalCertSignFeature?.category?.description }}</span>
                        </div>
                    </div>
                </div>
                    <div class="col-md-6 col-xl-7">
                    <div class="row col-img">
                        <img src="{{ digitalCertSignFeature?.blogs[1]?.avatar }}" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="feature-ss2">
        <div class="container">
            <div class="row column-reverse">
                    <div class="col-md-6 col-xl-7">
                    <div class="row col-img">
                        <img src="{{ electronicSignFeature?.blogs[1]?.avatar }}" alt="">
                    </div>
                </div>
                    <div class="col-md-6 col-xl-5">
                    <div class="row row_feature-ss1 ml-md-3">
                        <div class="content-left">
                            <img src="{{ electronicSignFeature?.blogs[0]?.avatar }}" alt="">
                            <h3 class="title">{{ electronicSignFeature?.category?.categoryName }}</h3>
                            <span>{{ electronicSignFeature?.category?.description }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="feature-ss3">
        <div class="container">
            <div class="row">
                    <div class="col-md-6 col-xl-5">
                    <div class="row row_feature-ss1">
                        <div class="content-left">
                            <img src="{{ eNegotiationFeature?.blogs[0]?.avatar }}" alt="">
                            <h3 class="title">{{ eNegotiationFeature?.category?.categoryName }}</h3>
                            <span>{{ eNegotiationFeature?.category?.description }}</span>
                        </div>
                    </div>
                </div>
                    <div class="col-md-6 col-xl-7">
                    <div class="row col-img">
                        <img src="{{ eNegotiationFeature?.blogs[1]?.avatar }}" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="feature-ss4">
        <div class="container">
            <div class="row column-reverse">
                    <div class="col-md-6 col-xl-7">
                    <div class="row col-img">
                        <img src="{{ eKYCFeature?.blogs[1]?.avatar }}" alt="">
                    </div>
                </div>
                    <div class="col-md-6 col-xl-5">
                    <div class="row row_feature-ss1 ml-md-3">
                        <div class="content-left">
                            <img src="{{ eKYCFeature?.blogs[0]?.avatar }}" alt="">
                            <h3 class="title">{{ eKYCFeature?.category?.categoryName }}</h3>
                            <span>{{ eKYCFeature?.category?.description }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="feature-ss5">
        <div class="container">
            <div class="row">
                    <div class="col-md-6 col-xl-5">
                    <div class="row row_feature-ss1">
                        <div class="content-left">
                            <img src="{{ encryptFeature?.blogs[0]?.avatar }}" alt="">
                            <h3 class="title">{{ encryptFeature?.category?.categoryName }}</h3>
                            <span>{{ encryptFeature?.category?.description }}</span>
                        </div>
                    </div>
                </div>
                    <div class="col-md-6 col-xl-7">
                    <div class="row col-img">
                        <img src="{{ encryptFeature?.blogs[1]?.avatar }}" alt="">
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="feature-ss6">
        <div class="container">
            <div class="row column-reverse">
                    <div class="col-md-6 col-xl-7">
                    <div class="row col-img">
                        <img src="{{ blockchainFeature?.blogs[1]?.avatar }}" alt="">
                    </div>
                </div>
                    <div class="col-md-6 col-xl-5">
                    <div class="row row_feature-ss1 ml-md-3">
                        <div class="content-left">
                            <img src="{{ blockchainFeature?.blogs[0]?.avatar }}" alt="">
                            <h3 class="title">{{ blockchainFeature?.category?.categoryName }}</h3>
                            <span>{{ blockchainFeature?.category?.description }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class="section-8">
        <div class="container" style="    position: relative;">
            <div class="row body">
               <div class="col-md-7">
                    <div class="row">
                        <div class="left-content">
                            <p class="title">
                                {{ experience?.category?.categoryName }}<br />
                                {{ experience?.category?.description }}

                            </p>
                            <a routerLink="/app/signup" class="btn-trial">
                                <span>{{ experience?.blogs[1]?.title }}</span>
                                <img src="{{ experience?.blogs[1]?.avatar }}" alt="">
                            </a>
                            <ul class="list-item d-none d-sm-block">
                                <li>
                                    <img src="{{ experience?.blogs[2]?.avatar }}" alt="">
                                    <span>{{ experience?.blogs[2]?.title }}</span>
                                </li>
                                <li>
                                    <img src="{{ experience?.blogs[3]?.avatar }}" alt="">
                                    <span>{{ experience?.blogs[3]?.title }}</span>
                                </li>
                                <li>
                                    <img src="{{ experience?.blogs[4]?.avatar }}" alt="">
                                    <span>{{ experience?.blogs[4]?.title }}</span>
                                </li>
                                <li>
                                    <img src="{{ experience?.blogs[5]?.avatar }}" alt="">
                                    <span>{{ experience?.blogs[5]?.title }}</span>
                                </li>
                            </ul>
                            <div class="list-button">
                                <a href="{{ link.iosLink }}">
                                    <div class="img">
                                        <img src="{{ appLink?.blogs[1]?.avatar }}" alt="">
                                    </div>
                                </a>
                                <a href="{{ link.androidLink }}">
                                    <div class="img">
                                        <img src="{{ appLink?.blogs[2]?.avatar }}" alt="">
                                    </div>
                                </a>
                                <!-- <a href="{{ configs[10]?.value }}">
                                    <img src="{{ appLink?.blogs[1]?.avatar }}" alt="">
                                </a>
                                <a href="{{ configs[3]?.value }}">
                                    <img src="{{ appLink?.blogs[2]?.avatar }}" alt="" style="margin: 0px 12px">
                                </a> -->
                                <a href="{{ link.qrcodeLink }}"  class="d-none d-xl-block">
                                    <img src="{{ appLink?.blogs[3]?.avatar }}" alt="">
                                    <!-- <img style="width: 150px;" src="{{ appLink?.blogs[3]?.avatar }}" alt=""> -->
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                    <!--  <img src="./Image/Group 1773s6.png" alt="" class="img-right d-none d-sm-block"> -->
            </div>
        </div>
    </section>
</div>