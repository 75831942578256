import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ContractNumberConfigService } from 'src/app/utils/services/aas-network/contract-number-config/contract-number-config.service';

@Component({
  selector: 'app-list-contract-number-config',
  templateUrl: './list-contract-number-config.component.html',
  styleUrls: ['./list-contract-number-config.component.scss']
})
export class ListContractNumberConfigComponent implements OnInit {

  constructor(
    private cncService: ContractNumberConfigService,
    private router: Router,
    private translate: TranslateService,
    private alert: AlertControl
  ) { }

  actionOptions: Array<object> = [
    { value: '', label: "ListContractNumberConfig_filter_1" },
    { value: 'CREATE', label: "ListContractNumberConfig_filter_2" },
    { value: 'SUBMIT', label: "ListContractNumberConfig_filter_3" },
    { value: 'SIGN', label: "ListContractNumberConfig_filter_4" }
  ];

  ngOnInit(): void {
    this.getListOfSequence(this.page, this.maxSize, this.searchString, this.action, this.propertiesSort, this.sort);
  }

  /**
   * Lấy danh sách số hợp đồng
   * @param page 
   * @param maxSize 
   * @param searchString 
   * @param action 
   * @param propertiesSort 
   * @param sort 
   */
  listSequence: Array<any> = [];
  page: number = 1;
  maxSize: number = 10;
  searchString: string = "";
  action: string = "";
  propertiesSort: string = "";
  sort: string = "";
  totalElement: number = 0;
  getListOfSequence(page: number = 1, maxSize: number = 10,
    searchString: string, action: string, propertiesSort: string, sort: string) {
    this.cncService.getListContractNumberConfig(page, maxSize, searchString, action, propertiesSort, sort)
      .subscribe((res: any) => {
        this.totalElement = res.object.totalElement ?? 0;
        this.listSequence = res.object.data ?? [];

      }, err => {
        this.listSequence = [];
      });
  }

  /**
   * Lấy label của thời điểm phát sinh số hợp đồng
   * @param action 
   */
  getActionLabel(action: string) {
    if (typeof (action) == 'string') {
      switch (action) {
        case 'CREATE':
          return this.translate.instant('ListContractNumberConfig_filter_2');
        case 'SUBMIT':
          return this.translate.instant('ListContractNumberConfig_filter_3');
        case 'SIGN':
          return this.translate.instant('ListContractNumberConfig_filter_4');
        default:
          return "";
      }
    }
    else {
      return "";
    }
  }

  /**
   * Trả về style và label của status
   * @param disable 
   */
  getStyleAndLabelBadge(disable: string): string[] {
    if (typeof (disable) == 'string') {
      switch (disable) {
        case 'N':
          return ['badge-success-new', this.translate.instant('status_running')];
        case 'Y':
          return ['badge-danger-new', this.translate.instant('status_stopped')];
      }
    }
    else {
      return ["", ""];
    }
  }

  /**
   * Reload page
   * @param page
   */
  loadPage(page) {
    this.page = page;
    this.getListOfSequence(page, this.maxSize, this.searchString, this.action, this.propertiesSort, this.sort);
  }

  applyFilter() {
    this.getListOfSequence(this.page, this.maxSize, this.searchString, this.action, this.propertiesSort, this.sort);
  }

  resetFilter() {
    this.searchString = "";
    this.action = "";
    this.applyFilter();
  }

  sortColConfig = {
    name: null,
    action: null,
    disable: null
  }

  /**
   * Init propertiesSort & sort
   * @param key 
   */
  oldKey: string = "name";
  sortCol(key) {
    if (this.sortColConfig[key] == null) {
      this.sortColConfig[this.oldKey] = null;
      this.sortColConfig[key] = true;
      this.oldKey = key;
    } else if (this.sortColConfig[key] == true) {
      this.sortColConfig[key] = false;
    } else {
      this.sortColConfig[key] = null;
    }

    if (this.sortColConfig[this.oldKey] == true) {
      this.propertiesSort = `-${this.oldKey}`;
      this.sort = 'ASC';
    }
    else if (this.sortColConfig[this.oldKey] == false) {
      this.propertiesSort = `+${this.oldKey}`;
      this.sort = 'DESC';
    }
    else if (this.sortColConfig[this.oldKey] == null) {
      this.propertiesSort = "";
      this.sort = "";
    }

    this.loadPage(this.page);
  }

  /**
   * Go to details of the config
   * @param id 
   */
  details(id: string = '') {
    this.router.navigate([`app/console/configure-contract-number/detail`, id]);
  }

  /**
   * Chuyển đến màn chỉnh sửa
   */
  directToEdit(id: string = '') {
    this.router.navigate([`app/console/configure-contract-number/edit`, id]);
  }

  /**
   * Chuyển số hợp đồng thành mặc định
   * @param id 
   */
  setToDefault(id: string) {
    if (id != undefined) {
      this.alert.showAlert(this.translate.instant('ContractNumberDetail_setDefault_confirm'), ConstantAlertType.BLANK, true,
      "", this.translate.instant('SignServerManager_label_14'), () => {

        this.cncService.setConfigToDefault(id).subscribe((res: any) => {
          this.loadPage(this.page);
        }, err => {
          this.alert.showErrorHandled(err);
        });

      }, "", this.translate.instant('btn_close'), null, this.translate.instant('btn_confirm'));
    }
  }
}
