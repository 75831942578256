import { Component, OnInit } from "@angular/core";
import * as $ from "jquery";
import {
    FormGroup,
    FormBuilder,
    FormControl,
    Validators,
} from "@angular/forms";
import { ValidatorsExtension } from "src/app/utils/common/validators-extension";
import {
    REGEXP_EMAIL,
    REGEXP_PHONE_NUMBER,
} from "./../../../utils/common/regexp";
import { HttpClient } from "@angular/common/http";
import { AlertControl } from "src/app/utils/alert/alert-control";
import { ConstantAlertType } from "src/app/utils/common/constant-alert-type";
import { ConstantUrl } from "src/app/utils/common/constant-url";
import { API } from "../../../../app/utils/services/api";
import { forkJoin } from "rxjs";
import { HomeService } from "src/app/utils/services/home/home.service";
import { HomeV4Component } from "../home-v4/home-v4.component";

@Component({
    selector: "app-contact",
    templateUrl: "./contact.component.html",
    styleUrls: ["./contact.component.scss"],
})
export class ContactComponent implements OnInit {
    formContact: FormGroup;
    isSubmit = false;
    listLinhVuc = [];
    constantUrl = ConstantUrl;

    ValidMsg = {
        email: {
            required: "Vui lòng nhập email!",
            email: "Email không đúng định dạng!",
            pattern: "Email không đúng định dạng!",
            unique: "Email tài khoản đã tồn tại!",
        },
        sdt: {
            required: "Vui lòng nhập số điện thoại!",
            pattern: "Số điện thoại không đúng định dạng!",
            unique: "Số điện thoại đã tồn tại!",
        },
        hoVaTen: {
            required: "Vui lòng nhập họ và tên!",
            pattern: "Tên doanh nghiệp không đúng định dạng!",
        },
        noiDung: {
            required: "Vui lòng nhập nội dung!",
            pattern: "Tên doanh nghiệp không đúng định dạng!",
        },
        congTy: {
            required: "Vui lòng nhập tên công ty!",
            pattern: "Tên doanh nghiệp không đúng định dạng!",
        },
        linhVucId: {
            required: "Vui lòng chọn lĩnh vực!",
            pattern: "Tên doanh nghiệp không đúng định dạng!",
        },
    };
    constructor(
        private fb: FormBuilder,
        private http: HttpClient,
        private myAlert: AlertControl,
        private api: API,
        private homeService: HomeService,
        private homeV4: HomeV4Component 
    ) {}

    contactUs: any = null;
    address: any = null;


    ngOnInit(): void {
        this.makeFormContact();
        this.getLinhVuc();
        this.getContactData(this.homeV4.translate.currentLang);
    }

    // getData
    getContactData(language) {
        forkJoin([
            this.homeService.getDataByCodeCategory("contactUs", language),
            this.homeService.getDataByCodeCategory("address", language)
        ]).subscribe((res: any) => {
            this.contactUs = res[0]?.object?.item;
            this.address = res[1]?.object?.item;
        })
    }

    reload(language) {
        this.getContactData(language);
    }

    makeFormContact() {
        this.formContact = this.fb.group({
            hoVaTen: new FormControl("", [Validators.required]),
            email: new FormControl("", [
                Validators.required,
                Validators.pattern(REGEXP_EMAIL),
            ]),
            sdt: new FormControl("", [
                Validators.required,
                Validators.pattern(REGEXP_PHONE_NUMBER),
                ValidatorsExtension.phoneNumber,
            ]),
            congTy: new FormControl("", [Validators.required]),
            linhVucId: new FormControl("", [Validators.required]),
            noiDung: new FormControl("", [Validators.required]),
        });
    }

    get email() {
        return this.formContact.get("email");
    }

    get hoVaTen() {
        return this.formContact.get("hoVaTen");
    }

    get sdt() {
        return this.formContact.get("sdt");
    }

    get congTy() {
        return this.formContact.get("congTy");
    }

    get noiDung() {
        return this.formContact.get("noiDung");
    }

    get linhVucId() {
        return this.formContact.get("linhVucId");
    }

    submitMessage() {
        this.isSubmit = true;
        console.log(this.formContact);

        const data = {
            hoTen: this.formContact.value.hoVaTen,
            email: this.formContact.value.email,
            soDienThoai: this.formContact.value.sdt,
            congTy: this.formContact.value.congTy,
            linhVucId: this.formContact.value.linhVucId,
            noiDung: this.formContact.value.noiDung,
        };
        if (this.formContact.valid) {
            try {
                this.api
                    .post(
                        "/landingpage-service/api/Catalog/contact",
                        data
                    )
                    .subscribe(
                        (res: any) => {
                            if (res.message === "IDG-00000000") {
                                this.myAlert.showAlert(
                                    "Chúng tôi đã ghi nhận thông tin của Quý khách và sẽ phản hồi trong thời gian sớm nhất!",
                                    ConstantAlertType.SUCCESS
                                );
                                this.makeFormContact();
                                this.isSubmit = false;
                            }
                        },
                        (error) => {
                            if (error.error.message === "NCO-00000011") {
                                this.myAlert.showAlert(
                                    "Quý khách đã gửi một liên hệ trong hôm nay, vui lòng đợi chúng tôi liên hệ lại. Xin cảm ơn!",
                                    ConstantAlertType.ERROR
                                );
                            } else {
                                this.myAlert.showAlert(
                                    "Đã có lỗi xảy ra trong quá trình gửi tin!",
                                    ConstantAlertType.ERROR
                                );
                            }
                        }
                    );
            } catch (e) {
                console.log(e);
            }
        }
    }

    getLinhVuc() {
        this.api
            .get(
                "/landingpage-service/api/Catalog/linh-vuc"
            )
            .subscribe((res: any) => {
                if (res.state) {
                    this.listLinhVuc = res.object.data;
                    // this.makeFormContact();
                }
            });
    }

    ngAfterViewInit() {
        var pushy = $(".pushy"), //menu css class
            body = $("body"),
            container = $("#container"),
            push = $(".push"),
            pushyRight = "pushy-right",
            pushyOpenLeft = "pushy-open-left",
            pushyOpenRight = "pushy-open-right",
            siteOverlay = $(".site-overlay"),
            menuBtn = $(".menu-btn, .pushy-link"),
            menuBtnFocus = $(".menu-btn");
        var _this = this;
        menuBtn.on("click", function () {
            $("#menu_portal li").removeClass("active");
            $("#menu_portal li a").removeClass("active");
            $(this).children("a").addClass("active");
            $(this).addClass("active");
            _this.togglePushy();
        });
        //close menu when clicking site overlay
        siteOverlay.on("click", function () {
            _this.togglePushy();
        });
    }

    togglePushy() {
        var pushy = $(".pushy"), //menu css class
            body = $("body"),
            container = $("#container"),
            push = $(".push"),
            pushyRight = "pushy-right",
            pushyOpenLeft = "pushy-open-left",
            pushyOpenRight = "pushy-open-right",
            siteOverlay = $(".site-overlay"),
            menuBtn = $(".menu-btn"),
            menuItem = $(".pushy-link"),
            menuLinkFocus = $(pushy.data("focus")),
            menuBtnFocus = $(".menu-btn");

        body.toggleClass(pushyOpenRight);

        if (menuLinkFocus) {
            pushy.one("transitionend", function () {
                menuLinkFocus.focus();
            });
        }
    }
}
