import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { localize } from 'src/app/utils/localize/localize';
import { PartnerConsumer } from 'src/app/utils/models/aas-models/partner/partner-verification/partner-consumer/partner-consumer';
import { AasConsumerManagerService } from 'src/app/utils/services/aas-network/aas-consumer-manager.service';
import { AccountManagerService } from 'src/app/utils/services/aas-network/account-manager.service';
import { AuthService } from 'src/app/utils/services/auth.service';

@Component({
  selector: 'app-trusted-consumer-partner-detail',
  templateUrl: './trusted-consumer-partner-detail.component.html',
  styleUrls: ['./trusted-consumer-partner-detail.component.scss'],
})
export class TrustedConsumerPartnerDetailComponent implements OnInit {

  constructor(
    private activedRoute: ActivatedRoute,
    private accApi: AccountManagerService,
    private router: Router,
    private aasConsumerManagerService: AasConsumerManagerService,
    private myAlert: AlertControl,
    private auth: AuthService
  ) {}
  detail: PartnerConsumer = null;
  id = this.activedRoute.snapshot.paramMap.get('id');
  portraitImage: any = null;
  frontImage: any = null;
  backImage: any = null;
  partyId= this.auth.partyId;
  ngOnInit(): void {
    this.getConsumerTrustedPartnerDetails(this.id);
  }
  getConsumerTrustedPartnerDetails(id) {
    this.accApi.getConsumerTrustedPartnerDetails(id).subscribe(
      (res: any) => {
        if (res && res.object instanceof Object) {
          this.detail = new PartnerConsumer(res.object);
          console.log(this.detail);
          
          this.getImage(this.detail.userId, 'EKYC_CHANDUNG');
          this.getImage(this.detail.userId, 'EKYC_MATTRUOC');
          this.getImage(this.detail.userId, 'EKYC_MATSAU');
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  async getImage(id, type) {
    this.aasConsumerManagerService.downloadImage(id, type).subscribe(
      (res) => {
        if (res) {
          const blob: any = new Blob([res], {
            type: 'image/png',
          });
          var reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            switch (type) {
              case 'EKYC_CHANDUNG':
                this.portraitImage = reader.result;
                break;
              case 'EKYC_MATTRUOC':
                this.frontImage = reader.result;
                break;
              case 'EKYC_MATSAU':
                this.backImage = reader.result;
                break;
              default:
            }
          };
        }
      },
      (err) => {
        if (type === 'EKYC_CHANDUNG') {
          this.portraitImage = null;
        } else if (type === 'EKYC_MATTRUOC') {
          this.frontImage = null;
        } else {
          this.backImage = null;
        }
      },
      () => {}
    );
  }
  resendInfo(id) {
    this.accApi.resendInvite(id).subscribe(
      (res: any) => {
        console.log('resend success');
        this.myAlert.showAlertOnlyNoti(
          'Gửi lại thông báo tài khoản thành công!',
          ConstantAlertType.SUCCESS,
          '',
          'OK',
          () => {}
        );
      },
      (error) => {
        console.log(error);
      }
    );
  }
  goToEdit(id) {
    this.router.navigate(['/app/console/partner/trusted/edit-consumer', id]);
  }
}
