<app-spinner></app-spinner>
<div class="bg-white">
    <section class="p-3">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <form class="input-group border rounded" [formGroup]="formSearch"
                    (ngSubmit)="actSearch(formSearch.value.search)">
                    <div class="input-group-prepend border-0">
                        <button class="btn d-inline-flex pr-2 pl-2" type="submit"><span
                                class="material-icons">search</span></button>
                    </div>
                    <input type="text" class="form-control border-0" formControlName="search"
                        placeholder="{{ 'btn_search' | translate }}" aria-label="" aria-describedby="basic-addon1">
                </form>
            </div>
        </div>

    </section>
    <hr class="">

    <section class="px-3 my-2">
        <form>
            <div class="row">
                <div class="col-md-2">
                    <div class="form-group">
                        <label for="statusGCS">{{ 'TrustedPartnerList_loai_dinh_danh_tai_khoan' | translate }}</label>
                        <ng-select [(ngModel)]="verificationType" [items]="verificationTypes" bindValue="id"
                            bindLabel="text" [searchable]="false" [clearable]="false"
                            [ngModelOptions]="{standalone: true}">
                            <ng-template ng-label-tmp let-item="item">
                                <span>{{ item.text | translate }}</span>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item">
                                <span>{{ item.text | translate }}</span>
                            </ng-template>
                            <!-- <option *ngFor="let item of verificationTypes" [ngValue]="item.id">{{ item.text }}</option> -->
                            <!-- <option value="">Tất cả</option>
                        <option value="VIDEO_CALL">Video Call</option>
                        <option value="NONE">Không sử dụng định danh</option> -->
                        </ng-select>
                    </div>
                </div>
                <div class="col-md-auto ml-auto mt-auto pb-3">
                    <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="cleanFilter()">{{ 'btn_reset' |
                        translate }}</button>
                    <button class="btn btn-primary d-inline-flex" (click)="searchWithFilter()">{{ 'btn_apply' |
                        translate }}</button>
                </div>
            </div>
        </form>

    </section>

    <hr class="">

    <section class="card-body p-0">
        <div class="table-responsive enable-scroll">
            <!-- Doi tac ca nhan -->
            <table class="table table-sort-col mb-0" *ngIf="userType == 'CONSUMER'">
                <thead>
                    <tr class="bg-tb-header">
                        <th style="min-width: 30px;">{{ 'TrustedPartnerList_stt' | translate }}</th>
                        <th style="min-width: 150px;" (click)="sortCol('username')">
                            <span class="d-flex align-self-center align-items-center">
                                {{ 'TrustedPartnerDetail_party_account' | translate }}
                                <span *ngIf="sortColConfig['username'] == null" class="material-icons ml-auto">
                                    unfold_more
                                </span>
                                <span *ngIf="sortColConfig['username'] == true" class="material-icons ml-auto">
                                    expand_less
                                </span>
                                <span *ngIf="sortColConfig['username'] == false" class="material-icons ml-auto">
                                    expand_more
                                </span>
                            </span>
                        </th>
                        <th style="min-width: 200px;" (click)="sortCol('ten')">
                            <span class="d-flex align-self-center align-items-center">
                                {{ 'TrustedPartnerList_ho_va_ten' | translate }}
                                <span *ngIf="sortColConfig['ten'] == null" class="material-icons ml-auto">
                                    unfold_more
                                </span>
                                <span *ngIf="sortColConfig['ten'] == true" class="material-icons ml-auto">
                                    expand_less
                                </span>
                                <span *ngIf="sortColConfig['ten'] == false" class="material-icons ml-auto">
                                    expand_more
                                </span>
                            </span>
                        </th>
                        <th style="min-width: 220px;" (click)="sortCol('email')">
                            <span class="d-flex align-self-center align-items-center">
                                {{ 'EmployeeInPart_email' | translate }}
                                <span *ngIf="sortColConfig['email'] == null" class="material-icons ml-auto">
                                    unfold_more
                                </span>
                                <span *ngIf="sortColConfig['email'] == true" class="material-icons ml-auto">
                                    expand_less
                                </span>
                                <span *ngIf="sortColConfig['email'] == false" class="material-icons ml-auto">
                                    expand_more
                                </span>
                            </span>
                        </th>
                        <th style="min-width: 150px;" (click)="sortCol('sdt')">
                            <span class="d-flex align-self-center align-items-center">
                                {{ 'TrustedPartnerList_so_dien_thoai' | translate }}
                                <span *ngIf="sortColConfig['sdt'] == null" class="material-icons ml-auto">
                                    unfold_more
                                </span>
                                <span *ngIf="sortColConfig['sdt'] == true" class="material-icons ml-auto">
                                    expand_less
                                </span>
                                <span *ngIf="sortColConfig['sdt'] == false" class="material-icons ml-auto">
                                    expand_more
                                </span>
                            </span>
                        </th>
                        <th style="min-width: 150px;" (click)="sortCol('cmnd')">
                            <span class="d-flex align-self-center align-items-center">
                                {{ 'TrustedPartnerAdd_id' | translate }}
                                <span *ngIf="sortColConfig['cmnd'] == null" class="material-icons ml-auto">
                                    unfold_more
                                </span>
                                <span *ngIf="sortColConfig['cmnd'] == true" class="material-icons ml-auto">
                                    expand_less
                                </span>
                                <span *ngIf="sortColConfig['cmnd'] == false" class="material-icons ml-auto">
                                    expand_more
                                </span>
                            </span>
                        </th>
                        <th style="min-width: 150px;" (click)="sortCol('ngaySinh')">
                            <span class="d-flex align-self-center align-items-center">
                                {{ 'TrustedPartnerList_ngay_sinh' | translate }}
                                <span *ngIf="sortColConfig['ngaySinh'] == null" class="material-icons ml-auto">
                                    unfold_more
                                </span>
                                <span *ngIf="sortColConfig['ngaySinh'] == true" class="material-icons ml-auto">
                                    expand_less
                                </span>
                                <span *ngIf="sortColConfig['ngaySinh'] == false" class="material-icons ml-auto">
                                    expand_more
                                </span>
                            </span>
                        </th>
                        <th style="min-width: 150px;" (click)="sortCol('identityLevel')">
                            <span class="d-flex align-self-center align-items-center">
                                {{ 'TrustedPartnerDetail_identifier_type' | translate }}
                                <span *ngIf="sortColConfig['identityLevel'] == null" class="material-icons ml-auto">
                                    unfold_more
                                </span>
                                <span *ngIf="sortColConfig['identityLevel'] == true" class="material-icons ml-auto">
                                    expand_less
                                </span>
                                <span *ngIf="sortColConfig['identityLevel'] == false" class="material-icons ml-auto">
                                    expand_more
                                </span>
                            </span>
                        </th>
                        <th style="min-width: 250px;" (click)="sortCol('refTenToChuc')">
                            <span class="d-flex align-self-center align-items-center">
                                {{ 'TrustedPartnerList_nhan_loi_moi_tu' | translate }}
                                <span *ngIf="sortColConfig['refTenToChuc'] == null" class="material-icons ml-auto">
                                    unfold_more
                                </span>
                                <span *ngIf="sortColConfig['refTenToChuc'] == true" class="material-icons ml-auto">
                                    expand_less
                                </span>
                                <span *ngIf="sortColConfig['refTenToChuc'] == false" class="material-icons ml-auto">
                                    expand_more
                                </span>
                            </span>
                        </th>
                        <th style="min-width: 100px;">{{ 'PartList_label_action' | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of partners; let i = index">
                        <td><span *ngIf="i < 9">0</span>{{ i + 1 }}</td>
                        <!-- [routerLink]="['/console/report-customer/detail', item.userId]" -->
                        <td>
                            <a class="text-primary text-decoration-underline cursor-pointer"
                                (click)="gotoDetail(item?.trustedPartnerId, item.userType)">{{ item.username || ''
                                }}</a>
                        </td>
                        <td>{{ item.ten || '' }}</td>
                        <td>{{ item.email || '' }}</td>
                        <td [class.d-none]="!configColTable.sdt">{{ item.sdt || '' }}</td>
                        <td [class.d-none]="!configColTable.cmnd">{{ item.cmnd || '' }}</td>
                        <td [class.d-none]="!configColTable.ngaySinh">{{ item.ngaySinh || '' | date: 'dd/MM/yyyy' }}
                        </td>
                        <!-- <td [class.d-none]="!configColTable.username">{{ item.username || '' }}</td> -->
                        <td [class.d-none]="!configColTable.verificationType">{{ 'label_level' | translate }} {{ item.identityLevel || '1' }}</td>
                        <td>{{ item.refTenToChuc || '' }}</td>
                        <td class="td-content-centered">
                            <div class="dropdown">
                                <a class="btn btn-dropdown dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <em class="material-icons">more_vert</em>
                                </a>
                                <!-- [routerLink]="['/console/report-customer/detail', item.userId]" -->
                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                                    <!-- [routerLink]="['/console/licenses-manager/detail/', item.licenseId]" -->
                                    <a class="dropdown-item nav-material d-inline-flex" style="cursor: pointer;"
                                        (click)="gotoDetail(item?.trustedPartnerId, item.userType)">
                                        <em class="material-icons-outlined">info</em>
                                        {{ 'TrustedPartnerList_thong_tin_chi_tiet' | translate }}</a>
                                    <a *ngIf="item.refPartyId == partyId"
                                        class="dropdown-item nav-material d-inline-flex" style="cursor: pointer;"
                                        (click)="gotoEdit(item?.trustedPartnerId, item.userType)">
                                        <em class="material-icons">edit</em>
                                        {{ 'TrustedPartnerList_chinh_sua' | translate }}</a>
                                    <a *ngIf="item.refPartyId == partyId"
                                        class="dropdown-item nav-material d-inline-flex" style="cursor: pointer;"
                                        routerLinkActive="router-link-active"
                                        (click)="resendInvite(item.trustedPartnerId)">
                                        <em class="material-icons">mail</em>
                                        {{ 'TrustedPartnerList_gui_lai' | translate }}</a>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>

            <!-- Doi tac to chuc -->
            <table class="table table-sort-col mb-0" *ngIf="userType == 'BUSINESS'">
                <thead>
                    <tr class="bg-tb-header">
                        <th style="min-width: 30px;">{{ 'TrustedPartnerList_stt' | translate }}</th>
                        <th style="min-width: 250px;" (click)="sortCol('tenToChuc')">
                            <span class="d-flex align-self-center align-items-center">
                                {{ 'TrustedPartnerList_ten_to_chuc' | translate }}
                                <span *ngIf="sortColConfig['tenToChuc'] == null" class="material-icons ml-auto">
                                    unfold_more
                                </span>
                                <span *ngIf="sortColConfig['tenToChuc'] == true" class="material-icons ml-auto">
                                    expand_less
                                </span>
                                <span *ngIf="sortColConfig['tenToChuc'] == false" class="material-icons ml-auto">
                                    expand_more
                                </span>
                            </span>
                        </th>
                        <th style="min-width: 150px;" (click)="sortCol('maSoThue')">
                            <span class="d-flex align-self-center align-items-center">
                                {{ 'TrustedPartnerList_mst' | translate }}
                                <span *ngIf="sortColConfig['maSoThue'] == null" class="material-icons ml-auto">
                                    unfold_more
                                </span>
                                <span *ngIf="sortColConfig['maSoThue'] == true" class="material-icons ml-auto">
                                    expand_less
                                </span>
                                <span *ngIf="sortColConfig['maSoThue'] == false" class="material-icons ml-auto">
                                    expand_more
                                </span>
                            </span>
                        </th>
                        <th style="min-width: 150px;" (click)="sortCol('ten')">
                            <span class="d-flex align-self-center align-items-center">
                                {{ 'TrustedPartnerList_nguoi_dai_dien' | translate }}
                                <span *ngIf="sortColConfig['ten'] == null" class="material-icons ml-auto">
                                    unfold_more
                                </span>
                                <span *ngIf="sortColConfig['ten'] == true" class="material-icons ml-auto">
                                    expand_less
                                </span>
                                <span *ngIf="sortColConfig['ten'] == false" class="material-icons ml-auto">
                                    expand_more
                                </span>
                            </span>
                        </th>
                        <th style="min-width: 220px;" (click)="sortCol('email')">
                            <span class="d-flex align-self-center align-items-center">
                                {{ 'EmployeeInPart_email' | translate }}
                                <span *ngIf="sortColConfig['email'] == null" class="material-icons ml-auto">
                                    unfold_more
                                </span>
                                <span *ngIf="sortColConfig['email'] == true" class="material-icons ml-auto">
                                    expand_less
                                </span>
                                <span *ngIf="sortColConfig['email'] == false" class="material-icons ml-auto">
                                    expand_more
                                </span>
                            </span>
                        </th>
                        <th style="min-width: 150px;" (click)="sortCol('identityLevel')">
                            <span class="d-flex align-self-center align-items-center">
                                {{ 'TrustedPartnerList_loai_tai_khoan' | translate }}
                                <span *ngIf="sortColConfig['identityLevel'] == null" class="material-icons ml-auto">
                                    unfold_more
                                </span>
                                <span *ngIf="sortColConfig['identityLevel'] == true" class="material-icons ml-auto">
                                    expand_less
                                </span>
                                <span *ngIf="sortColConfig['identityLevel'] == false" class="material-icons ml-auto">
                                    expand_more
                                </span>
                            </span>
                        </th>
                        <th style="min-width: 250px;" (click)="sortCol('refTenToChuc')">
                            <span class="d-flex align-self-center align-items-center">
                                {{ 'TrustedPartnerList_nhan_loi_moi_tu' | translate }}
                                <span *ngIf="sortColConfig['refTenToChuc'] == null" class="material-icons ml-auto">
                                    unfold_more
                                </span>
                                <span *ngIf="sortColConfig['refTenToChuc'] == true" class="material-icons ml-auto">
                                    expand_less
                                </span>
                                <span *ngIf="sortColConfig['refTenToChuc'] == false" class="material-icons ml-auto">
                                    expand_more
                                </span>
                            </span>
                        </th>
                        <th style="min-width: 100px;">{{ 'PartList_label_action' | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of partners; let i = index">
                        <td><span *ngIf="i < 9">0</span>{{ i + 1 }}</td>
                        <!-- [routerLink]="['/console/report-customer/detail', item.userId]" -->
                        <td>
                            <a class="text-primary text-decoration-underline cursor-pointer"
                                (click)="gotoDetail(item?.trustedPartnerId, item.userType)">{{ item.tenToChuc || ''
                                }}</a>
                        </td>
                        <td>{{ item.maSoThue || '' }}</td>
                        <td>{{ item.ten || '' }}</td>
                        <td [class.d-none]="!configColTable.sdt">{{ item.email || '' }}</td>
                        <td [class.d-none]="!configColTable.verificationType">Tài khoản định danh cấp {{
                            item.identityLevel || '1' }}</td>
                        <td [class.d-none]="!configColTable.cmnd">{{ item.refTenToChuc || '' }}</td>
                        <td class="td-content-centered">
                            <div class="dropdown">
                                <a class="btn btn-dropdown dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <em class="material-icons">more_vert</em>
                                </a>
                                <!-- [routerLink]="['/console/report-customer/detail', item.userId]" -->
                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                                    <!-- [routerLink]="['/console/licenses-manager/detail/', item.licenseId]" -->
                                    <a class="dropdown-item nav-material d-inline-flex" style="cursor: pointer;"
                                        (click)="gotoDetail(item?.trustedPartnerId, item.userType)">
                                        <em class="material-icons-outlined">info</em>
                                        {{ 'TrustedPartnerList_thong_tin_chi_tiet' | translate }}</a>
                                    <a *ngIf="item.refPartyId == partyId"
                                        class="dropdown-item nav-material d-inline-flex" style="cursor: pointer;"
                                        (click)="gotoEdit(item?.trustedPartnerId, item.userType)">
                                        <em class="material-icons">edit</em>
                                        {{ 'TrustedPartnerList_chinh_sua' | translate }}</a>
                                    <a *ngIf="checkRefParty(item.refPartyId)"
                                        class="dropdown-item nav-material d-inline-flex" style="cursor: pointer;"
                                        routerLinkActive="router-link-active"
                                        (click)="resendInvite(item.trustedPartnerId)">
                                        <em class="material-icons">mail</em>
                                        {{ 'TrustedPartnerList_gui_lai' | translate }}</a>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="bg-tb-header p-3">
            <div class="row align-items-center">
                <span *ngIf="totalElement == 0" class="col-md">{{ 'text_no_results' | translate }}</span>
                <span *ngIf="totalElement > 0" class="col-md">{{ 'TrustedPartnerList_hien_thi_1_den' | translate
                    }}{{partners.length}} {{ 'TrustedPartnerList_trong_tong_so' | translate }} {{ totalElement }} {{
                    'TrustedPartnerList_ket_qua' | translate }}</span>
                <div class="col-md" *ngIf="totalElement > 0">
                    <div class="d-flex justify-content-end">
                        <div class="d-flex justify-content-end align-items-center">
                            <span class="mr-2">{{ 'text_number_of_lines' | translate }}:</span>
                            <ng-select class="no-bg no-border" [(ngModel)]="maxSize" [searchable]="false"
                                [clearable]="false" placeholder="..." (change)="loadPage(1)">
                                <ng-option [value]="10">10</ng-option>
                                <ng-option [value]="20">20</ng-option>
                                <ng-option [value]="30">30</ng-option>
                            </ng-select>
                        </div>
                        <div class="pagination pages no-border-pagination ml-2">
                            <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="5"
                                [rotate]="true" [ellipses]="false" [pageSize]="maxSize" (pageChange)="loadPage($event)"
                                [boundaryLinks]="true" aria-label="Default pagination">
                                <ng-template ngbPaginationFirst>{{ 'text_first' | translate }}</ng-template>
                                <ng-template ngbPaginationLast>{{ 'text_last' | translate }}</ng-template>
                                <ng-template ngbPaginationPrevious>&laquo;</ng-template>
                                <ng-template ngbPaginationNext>&raquo;</ng-template>
                                <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                            </ngb-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>