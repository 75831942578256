import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { ControlAccount } from 'src/app/utils/common/control-account';
import { ContractTypeService } from '../../services/contract-type.service';

@Component({
  selector: 'app-contract-type-list',
  templateUrl: './contract-type-list.component.html',
  styleUrls: ['./contract-type-list.component.scss']
})
export class ContractTypeListComponent implements OnInit {

  statusList = [
    { value: "", label: "status_all"},
    { value: "Y", label: "status_running"},
    { value: "N", label: "status_stopped"}
  ]
  formFilter: FormGroup;
  keySearch: string = "";

  page = 1;
  maxSize = 10;
  totalElement = 0;
  propertiesSort = "modified";
  sort = "DESC";

  sortColConfig = {
    name: null,
    status: null
  }

  types = [];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    public controlAcc: ControlAccount,
    private contractTypeService: ContractTypeService,
    private myAlert: AlertControl,
  ) { }

  ngOnInit(): void {
    this.formFilter = this.fb.group({
      status: ['']
    })
    this.loadPage(1);
  }

  enterSearch() {
    this.loadPage(1);
  }

  cleanFilter() {
    this.formFilter.reset();
    this.formFilter.controls.status.setValue("");
    this.keySearch = "";
    this.loadPage(1);
  }

  searchWithFilter() {
    this.loadPage(1);
  }

  addType() {
    this.router.navigate([ConstantUrl.add_contract_type]);
  }

  loadPage(page) {
    this.page = page;
    this.getListContractTypes(this.page, this.maxSize, this.propertiesSort, this.sort, this.formFilter.value.status, this.keySearch);
  }

  sortCol(key) {
    if (this.sortColConfig[key] == null) {
      this.sortColConfig[key] = true;
    } else if (this.sortColConfig[key] == false) {
      this.sortColConfig[key] = null;
    } else {
      this.sortColConfig[key] = !this.sortColConfig[key];
    }
  }

  /**
   * Lay danh sach loai hop dong
   * @param page 
   * @param maxSize 
   * @param propertiesSort 
   * @param sort 
   * @param status 
   * @param searchString 
   */
  getListContractTypes(page = 1, maxSize = 10, propertiesSort = "modified", sort = "DESC", status = "", searchString = "") {
    this.contractTypeService.getListContractTypes(page, maxSize, propertiesSort, sort, status, searchString).subscribe((res: any) => {
      this.types = res.object?.data || [];
      this.totalElement = res.object?.totalElement || 0;
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }

  detail(id) {
    this.router.navigate([ConstantUrl.contract_type_detail, id]);
  }

  edit(id) {
    this.router.navigate([ConstantUrl.edit_contract_type, id]);
  }

  changeTypeState(id, state) {
    var stateObj = {
      status : ""
    }
    stateObj.status = state;
    this.contractTypeService.editType(id, stateObj).subscribe((res: any) => {
      this.loadPage(1);
    }, err => {
      this.myAlert.showErrorHandled(err);
    });
  }

}
