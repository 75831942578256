
<!-- <section class="content">
  <div class="container-fluid"> -->
    <div class="card shadow-none">
      <div class="card-body" *ngIf="customerInfo">
        <div class="row">
          <div class="col-6">
            <label class="text-primary">Thông tin chung</label>
            <hr>
            <div class="col-md-12">
              <div class="mb-3">
                <label class="col-md-3">Tên doanh nghiệp: </label>
                <span class="col-md-9">{{customerInfo.tenDN}}</span>
              </div>
              <div class="mb-3">
                <label class="col-md-3">Tên viết tắt: </label>
                <span class="col-md-9">{{customerInfo.tenVietTat}}</span>
              </div>
              <div class="mb-3">
                <label class="col-md-3">Mã số thuế: </label>
                <span class="col-md-9">{{customerInfo.mst}}</span>
              </div>
              <div class="mb-3">
                <label class="col-md-3">Giấy phép kinh doanh: </label>
                <span class="col-md-9">
                  <a class="text-decoration-underline text-primary" style="cursor: pointer;" (click)="actDownloadGPKD(customerInfo.partyId)">
                    {{customerInfo.file}}
                  </a>
                </span>
              </div>
            </div>
            <div class="col-d-12">
              <label class="text-primary mt-3">Thông tin đăng ký</label>
              <hr>
              <div class="mb-3">
                <label class="col-md-3">Ngày đăng ký: </label>
                <span class="col-md-9">{{customerInfo.ngayDK}}</span>
              </div>
              <div class="mb-3">
                <label class="col-md-3">Người phê duyệt: </label>
                <span class="col-md-9">{{customerInfo.nguoiPheDuyet}}</span>
              </div>
              <div class="mb-3">
                <label class="col-md-3">Ngày phê duyệt: </label>
                <span class="col-md-9">{{customerInfo.ngayPheDuyet}}</span>
              </div>
            </div>

          </div>
          <div class="col-6">
            <label class="text-primary ">Thông tin người đại diện</label>
              <hr>
            <div class="col-md-12">
              <div class="mb-3">
                <label class="col-md-3">Họ và tên: </label>
                <span class="col-md-9">{{customerInfo.userInfo.hoTen}}</span>
              </div>
              <div class="mb-3">
                <label class="col-md-3">Email: </label>
                <span class="col-md-9">{{customerInfo.userInfo.email}}</span>
              </div>
              <div class="mb-3">
                <label class="col-md-3">Số điện thoại: </label>
                <span class="col-md-9">{{customerInfo.userInfo.sdt}}</span>
              </div>
              <div class="mb-3">
                <label class="col-md-3">Ngày sinh: </label>
                <span class="col-md-9">{{customerInfo.userInfo.ngaySinh}}</span>
              </div>
              <div class="mb-3">
                <label class="col-md-3">Giới tính: </label>
                <span class="col-md-9">{{customerInfo.userInfo.gioiTinh}}</span>
              </div>
              <div class="mb-3">
                <label class="col-md-3">Địa chỉ: </label>
                <span class="col-md-9">{{customerInfo.userInfo.diaChi}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!customerInfo">
        <app-empty-block></app-empty-block>
      </div>
    </div>
  <!-- </div>
</section> -->

<app-preview-modal *ngIf="showPreviewGPKD" [file]="fileGPKD" [name]="customerInfo.file" (close)="handleClosePreview()"></app-preview-modal>