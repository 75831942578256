import { Injectable } from '@angular/core';
import { ServicePath } from 'src/app/utils/common/constant-service-api';
import { API } from '../../api';

const ADD_FLOW_CONTRACT = ServicePath.MANAGEMENT_SERVICE + "/contract/flow/create";
const LIST_FLOWS = ServicePath.MANAGEMENT_SERVICE + "/contract/flow";
const DETAIL_FLOW = ServicePath.MANAGEMENT_SERVICE + "/contract/flow";
const UPDATE_FLOW = ServicePath.MANAGEMENT_SERVICE + "/contract/flow";
const PREVIEW_FILE = ServicePath.MANAGEMENT_SERVICE + "/contract/flow";
const DELETE_FLOW = ServicePath.MANAGEMENT_SERVICE + "/contract/flow";
const UPDATE_INTERNAL_DISCUSS = ServicePath.MANAGEMENT_SERVICE + "/contracts"; 
const UPDATE_PARTNER_DISCUSS = ServicePath.MANAGEMENT_SERVICE + "/contracts"; 
const UPDATE_SIGNFLOW = ServicePath.MANAGEMENT_SERVICE + "/contracts"; 
const UPDATE_SIGNFLOWTYPE = ServicePath.MANAGEMENT_SERVICE + "/contracts"; 

@Injectable({
  providedIn: 'root'
})
export class FlowContractService {

  constructor(
    private api: API
  ) { }

  postAddFlowContract(userId, data: FormData) {
    const url = ADD_FLOW_CONTRACT + `/${userId}`;
    return this.api.postMultiPartJSON(url, data);
  }

  getListFlowContract(page = 1, maxSize = 10, propertiesSort = "created", sort = "DESC", status = "", keySearch = "", discuss: any = null) {
    const url = LIST_FLOWS + `/?keySearch=${keySearch}&disable=${status}&page=${page}&maxSize=${maxSize}&propertiesSort=${propertiesSort}&sort=${sort}` + (discuss == null ? '' : `&discuss=${discuss}`);
    return this.api.get(url);
  }

  getDetailFlowContract(id: string) {
    const url = DETAIL_FLOW + `/${id}`;
    return this.api.get(url);
  }

  postUpdateFlowContract(id, data: FormData) {
    const url = UPDATE_FLOW + `/${id}`;
    return this.api.putMultiPart(url, data);
  }

  getPreviewContractSample(id) {
    const url = PREVIEW_FILE + `/${id}/preview`;
    return this.api.download(url);
  }

  postDelete(id) {
    const url = DELETE_FLOW + `/${id}/delete`;
    return this.api.post(url, null);
  }

  getUrltockFile(id) {
    const url = ServicePath.MANAGEMENT_SERVICE + `/contract/flow/${id}/download`;
    return this.api.get(url);
  }

  downloadWithURL(url) {
    return this.api.downloadByFullUrl(url);
  }
  
  postUpdateInteralDiscuss(contractId, param) {
    const url = UPDATE_INTERNAL_DISCUSS + `/${contractId}/update-internal-discuss`;
    return this.api.post(url, param);
  }

  postUpdatePartnerDiscuss(contractId, param) {
    const url = UPDATE_PARTNER_DISCUSS + `/${contractId}/update-partner-discuss`;
    return this.api.post(url, param);
  }

  postUpdateSignFlow(contractId, param) {
    const url = UPDATE_SIGNFLOW + `/${contractId}/update-sign-flow`;
    return this.api.post(url, param);
  }

  getDataDropListFlowContract(page = 1, maxSize = 10, propertiesSort = "created", sort = "DESC", status: any = "", keySearch = "", discuss: any = null) {
    const url = LIST_FLOWS + `/?keySearch=${keySearch}&disable=${status}&page=${page}&maxSize=${maxSize}&propertiesSort=${propertiesSort}&sort=${sort}` + (discuss == null ? '' : `&discuss=${discuss}`);
    return this.api.get(url);
  }

  postUpdateSignFlowType(contractId, signFlowType) {
    const url = UPDATE_SIGNFLOWTYPE + `/${contractId}/update-sign-flow-type?signFlowType=${signFlowType}`;
    return this.api.post(url, null);
  }
}
