import { ConstantUrl } from './../../../../utils/common/constant-url';
import { REGEXP_PASSWORD, REGEXP_PHONE_NUMBER, REGEXP_USERNAME } from './../../../../utils/common/regexp';
import { TrimValueDirective } from './../../../../utils/directives/trim-value.directive';
import { map } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AasValidateMessage } from 'src/app/utils/common/validate-msg-aas';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { LocationExtension } from 'src/app/utils/common/LocationExtension';
import { Router, ActivatedRoute } from '@angular/router';
import { SignupConsumerResponse } from 'src/app/utils/models/aas-models/signup-consumer/signup-consumer-response';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ValidatorsExtension } from 'src/app/utils/common/validators-extension';

@Component({
  selector: 'app-service-signup',
  templateUrl: './service-signup.component.html',
  styleUrls: ['./service-signup.component.scss']
})
export class ServiceSignupComponent implements OnInit {
  _hoVaTen: string = this.activeRoute.snapshot.queryParamMap.get('hoVaTen') || '';
  _email: string = this.activeRoute.snapshot.queryParamMap.get('email') || '';
  _sdt: string = this.activeRoute.snapshot.queryParamMap.get('sdt') || '';

  constantUrl = ConstantUrl;

  formConsumer: FormGroup;
  formBusiness: FormGroup;
  isSubmitConsumer = false;
  isSubmitBusiness = false;
  ValidMsg = AasValidateMessage.SIGN_ON;
  activeConsumer = true;
  activeBusiness = false;
  activeBusinessVIP = false;

  constructor(
    private fb: FormBuilder,
    private route: Router,
    private activeRoute: ActivatedRoute,
    private auth: AasAuthService,
    private modalService: NgbModal
  ) {
  }

  ngOnInit(): void {
    this.makeFormConsumer();
    this.makeFormBusiness();

    this.setData();
  }

  //MARK: Khởi tạo form đăng ký cho cá nhân
  makeFormConsumer() {
    this.formConsumer = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.pattern(/^[a-zA-Z][a-zA-Z0-9_\.\-]{5,32}@[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,4}){1,2}$/)]),
      sdt: new FormControl('', [Validators.required, Validators.pattern(REGEXP_PHONE_NUMBER), ValidatorsExtension.phoneNumber]),
      username: new FormControl('', [Validators.required, Validators.pattern(REGEXP_USERNAME)]),
      password: new FormControl('', [Validators.required, Validators.pattern(REGEXP_PASSWORD)])
    });
  }

  get email() { return this.formConsumer.get('email'); }
  get sdt() { return this.formConsumer.get('sdt'); }
  get username() { return this.formConsumer.get('username'); }
  get password() { return this.formConsumer.get('password'); }

  //MARK: Khởi tạo form đăng ký doanh nghiệp
  makeFormBusiness() {
    this.formBusiness = this.fb.group({
      tenDoanhNghiep: new FormControl('', [Validators.required, ValidatorsExtension.validateCompanyName]),
      tenVietTat: new FormControl('', [Validators.required, ValidatorsExtension.validateShortCompanyName]),
      maSoThue: new FormControl('', [Validators.required, Validators.pattern(/^\d{10}-\d{3}$|^\d{10}$/i)])
    });
  }

  get tenDoanhNghiep() { return this.formBusiness.get('tenDoanhNghiep'); }
  get tenVietTat() { return this.formBusiness.get('tenVietTat'); }
  get maSoThue() { return this.formBusiness.get('maSoThue'); }

  //MARK: Set du lieu tu query param
  setData() {
    this.email.setValue(this._email);
    this.sdt.setValue(this._sdt);
  }

  //MARK: Chức năng chuyển đổi giữa đăng ký cá nhân và tổ chức
  /**
   * Chuyển tab
   * @param type 
   */
  changeFormActive(type: string) {
    this.resetAllForm();

    switch (type) {
      case 'BUSINESSVIP': 
        this.activeBusinessVIP = true;
        break;
      case 'BUSINESS':
        this.activeBusiness = true;
        break;
      case 'CONSUMER':
        this.activeConsumer = true;
        break;
      default:
        this.activeConsumer = true;
        break;
    }
  }

  /**
   * Reset trạng thái các tab
   */
  resetAllForm() {
    this.activeBusinessVIP = false;
    this.activeBusiness = false;
    this.activeConsumer = false;
    this.isSubmitConsumer = false;
    this.isSubmitBusiness = false
  }

  goToSignUpVIP() {
    this.route.navigate([ConstantUrl.SIGNUP_BUSINESS_VIP]);
  }

  //MARK: CHỨC NĂNG ĐĂNG KÝ
  /**
   * Submit form đăng ký tài khoản cá nhân
   */
  submitConsumer() {
    this.isSubmitConsumer = true;
    this.clearSpace();
    if (this.formConsumer.valid) {
      const param = {
        domain: LocationExtension.HOSTNAME,
        email: this.formConsumer.value.email,
        sdt: this.formConsumer.value.sdt,
        username: this.formConsumer.value.username,
        password: this.formConsumer.value.password
      }

      this.postRegisterConsumer(param);
    }
  }

  /**
   * Handle input sdt
   * @param event 
   */
  handleInputSdt(event) {
    const regex = new RegExp(/^\d+$/);
    if (regex.test(event.target.value) && event.target.value.length <= 10) {
      return;
    } else {
      this.sdt.setValue(event.target.value.slice(0, -1));
    }
  }

  clearSpace() {
    Object.keys(this.formConsumer.value).forEach((key) => {
      console.log(key);
      if (key !== 'password') {
        this.formConsumer.controls[key].setValue((this.formConsumer.controls[key].value || '').trim());
      }
    })
  }

  /**
   * Submit form đăng ký tài khoản tổ chức (thường + VIP)
   */
  submitBusiness() {
    this.isSubmitBusiness = true;
    if (this.formBusiness.valid) {
      let url = '';
      if (this.activeBusinessVIP) url = ConstantUrl.SIGNUP_BUSINESS_VIP;
      if (this.activeBusiness) url = ConstantUrl.SIGNUP_BUSINESS_SME;

      this.route.navigate([url], { queryParams: { 'tenDoanhNghiep': this.tenDoanhNghiep.value, 'tenVietTat': this.tenVietTat.value, 'maSoThue': this.maSoThue.value } })
    }
  }

  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], 'error');
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, 'error');
    } else {
      this.alert('Lỗi không xác định!', 'error');
    }
  }

  //MARK: Networking
  postRegisterConsumer(param) {
    this.auth.postRegisterConsumer(param).subscribe(res => {
      const data = new SignupConsumerResponse(res);
      console.log(data);
      this.route.navigate(['/app/request-otp'], { queryParams: { 'type': 'CONSUMER', 'redirectTo': 'REGISTERACCOUNT', 'user': data.object.username, 'id': data.object.userId } });
    }, err => {
      console.log(err);
      if (err.error && err.error.message) {
        switch (err.error.message) {
          case 'ECT-00001200':
            this.email.setErrors({ unique: true });
            break;
          case 'ECT-00001201':
            this.sdt.setErrors({ unique: true });
            break
          case 'ECT-00001202':
            this.username.setErrors({ unique: true });
            break;
          default:
            this.handleError(err, ResponseMsg.MESSAGES.PARTY_USER);
        }
      }
    }, () => {

    });
  }
}
