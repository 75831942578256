<app-spinner></app-spinner>
<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-auto">
                <h1 class="m-0 text-dark"><label>{{ 'DetailsBusinessTrustedPartner_title' | translate }}</label></h1>
            </div>
            <!-- /.col -->
            <div *ngIf="detailInformation?.refPartyId == partyId" class="col-md-auto ml-auto">
                <button class="btn btn-primary d-inline-flex" (click)="goToEdit()">
                    <span class="material-icons mr-1">edit</span>{{ 'btn_edit' | translate }}
                </button>
            </div>
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>

<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-7">
                <div class="card shadow-none">
                    <div class="card-body position-relative">
                        <label class="text-primary">{{ 'ProfileBusiness_party' | translate }}</label>
                        <hr>
                        <div>
                            <!-- Ten doanh nghiep -->
                            <div class="e-block-field row">
                                <div class="col-md-4 col-xl-3">
                                    <label class="e-lb-field">{{ 'ProfileBusiness_party_name' | translate }}</label>
                                </div>
                                <div class="col-md-8">
                                    <span class="">{{ detailInformation?.tenToChuc }}</span>
                                </div>
                            </div>
                            <!-- Ten viet tat -->
                            <div class="e-block-field row">
                                <div class="col-md-4 col-xl-3">
                                    <label class="e-lb-field">{{ 'ProfileBusiness_party_short' | translate }}</label>
                                </div>
                                <div class="col-md-8">
                                    <span class="">{{ detailInformation?.tenRutGon }}</span>
                                </div>
                            </div>
                            <!-- Ma so thue -->
                            <div class="e-block-field row">
                                <div class="col-md-4 col-xl-3">
                                    <label class="e-lb-field">{{ 'ProfileBusiness_party_taxcode' | translate }}</label>
                                </div>
                                <div class="col-md-8">
                                    <span class="">{{ detailInformation?.maSoThue }}</span>
                                </div>
                            </div>
                            <!-- GPKD -->
                            <div class="e-block-field row">
                                <div class="col-md-4 col-xl-3">
                                    <label class="e-lb-field">{{ 'WaitPartyPartner_party_license' | translate }}</label>
                                </div>
                                <div class="col-md-8">
                                    <span class="">
                                        <a class="text-decoration-underline text-primary" style="cursor: pointer;" (click)="actDownloadGPKD(detailInformation?.partyId)">
                                            {{ detailInformation?.gpkdFilename }}
                                        </a>
                                    </span>
                                </div>
                            </div>
                            <!-- Dia chi -->
                            <div class="e-block-field row">
                                <div class="col-md-4 col-xl-3">
                                    <label class="e-lb-field">{{ 'TrustedPartnerDetail_agent_address' | translate }}</label>
                                </div>
                                <div class="col-md-8">
                                    <span class="">{{ address }}</span>
                                </div>
                            </div>
                        </div>
        
                        <label class="text-primary">{{ 'ProfileBusiness_agent' | translate }}</label>
                        <hr>
                        <div>
                            <!-- Ho va ten -->
                            <div class="e-block-field row">
                                <div class="col-md-4 col-xl-3">
                                    <label class="e-lb-field">{{ 'ProfileBusiness_agent_name' | translate }}</label>
                                </div>
                                <div class="col-md-8">
                                    <span class="">{{ detailInformation?.ten }}</span>
                                </div>
                            </div>
                            <!-- Email -->
                            <div class="e-block-field row">
                                <div class="col-md-4 col-xl-3">
                                    <label class="e-lb-field">{{ 'ProfileBusiness_agent_email' | translate }}</label>
                                </div>
                                <div class="col-md-8">
                                    <span class="">{{ detailInformation?.email }}</span>
                                </div>
                            </div>
                            <!-- So dien thoai -->
                            <div class="e-block-field row">
                                <div class="col-md-4 col-xl-3">
                                    <label class="e-lb-field">{{ 'SignUpConsumer_input_so_dien_thoai' | translate }}</label>
                                </div>
                                <div class="col-md-8">
                                    <span class="">{{ detailInformation?.sdt }}</span>
                                </div>
                            </div>
                            <!-- Ngay sinh -->
                            <div class="e-block-field row">
                                <div class="col-md-4 col-xl-3">
                                    <label class="e-lb-field">{{ 'TrustedPartnerList_ngay_sinh' | translate }}</label>
                                </div>
                                <div class="col-md-8">
                                    <span class="">{{ detailInformation?.ngaySinh | date: 'dd/MM/yyyy' }}</span>
                                </div>
                            </div>
                            <!-- Gioi tinh -->
                            <div class="e-block-field row">
                                <div class="col-md-4 col-xl-3">
                                    <label class="e-lb-field">{{ 'WaitConsumerPartner_gender' | translate }}</label>
                                </div>
                                <div class="col-md-8">
                                    <span class="">{{ detailInformation?.gioiTinhName }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="card shadow-none">
                    <div class="card-body position-relative">
                        <label class="text-primary">{{ 'TrustedPartnerDetail_info' | translate }}</label>
                        <hr>
                        <div>
                            <div class="e-block-field row">
                                <div class="col-md-5">
                                    <label class="e-lb-field">{{ 'TrustedPartnerDetail_party_account' | translate }}</label>
                                </div>
                                <div class="col-md-7">
                                    <span class="">{{ detailInformation?.username }}</span>
                                </div>
                            </div>
                            <div class="e-block-field row">
                                <div class="col-md-5">
                                    <label class="e-lb-field">{{ 'DetailsBusinessTrustedPartner_label_1' | translate }}</label>
                                </div>
                                <div class="col-md-7">
                                    <span class="">{{ 'ServiceProfileManager_title3' | translate }} {{ detailInformation?.identityLevel }}</span>
                                </div>
                            </div>
                            
                        </div>
        
                        
                    </div>
                </div>
            </div>
        </div>
        
        <app-preview-modal *ngIf="showPreviewGPKD" [file]="fileGPKD" [name]="detailInformation?.gpkdFilename" (close)="handleClosePreview()"></app-preview-modal>
        
    </div>
</section>