import { ppid } from 'process';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { API } from '../api';
import { sign } from 'crypto';
import { ServicePath } from '../../common/constant-service-api';
import { ContractFileInfo } from '../../models/aas-models/contract/contract-file/contract-file.model';
import { Observable } from 'rxjs';

const SERVICE_GET_LIST_CONTRACT = ServicePath.MANAGEMENT_SERVICE + '/user-informations';
const SERVICE_CREATE_DRAFT_ONLY_FILE =
  ServicePath.MANAGEMENT_SERVICE + '/contracts/create-draft-only-file';
const TEMPLATE_CONVERT_TO_PDF = ServicePath.TEMPATE_SERVICE + '/api/templates/convert-to-pdf';
const SERVICE_SEARCH_CUSTOMER = ServicePath.MANAGEMENT_SERVICE + '/parties/customer-search';
const SERVICE_GET_CONTRACT = ServicePath.MANAGEMENT_SERVICE + '/contracts';
const SERVICE_DOWNLOAD_CONTRACT_FILE = ServicePath.MANAGEMENT_SERVICE + '/contracts';
const SERVICE_UPDATE_CONTRACT = ServicePath.MANAGEMENT_SERVICE + '/contracts';
const SERVICE_SIGN_USB_TOKEN = ServicePath.MANAGEMENT_SERVICE + '/contracts';
const SERVICE_SIGN_SERVER = ServicePath.SIGN_SERVICE + '/api/sign/v2/manual';
const SERVICE_E_SIGN = ServicePath.ESIGNATURE_SERVICE + '/esign';
const SERVICE_REQUEST_E_SIGN = ServicePath.MANAGEMENT_SERVICE + '/contracts';
const SERVICE_CONFIRM_OTP_E_SIGN = ServicePath.ESIGNATURE_SERVICE + '/esign';
const SERVICE_GET_LIST_SIGNATURE = ServicePath.ESIGNATURE_SERVICE + '/dsign/information-signature';
const SERVICE_CANCEL_CONTRACT = ServicePath.MANAGEMENT_SERVICE + '/contracts';
const SERVICE_CONVERT_HTML_TO_PDF = ServicePath.TEMPATE_SERVICE + '/api/templates/convert-to-pdf';
const SERVICE_CREATE_DRAFT_ONLY_FILE_VIP =
  ServicePath.MANAGEMENT_SERVICE + '/contracts/create-draft-only-file-vip';
const SERVICE_UPDATE_CONTRACT_VIP = ServicePath.MANAGEMENT_SERVICE + '/contracts';
const GET_CONTRACT_FILE = ServicePath.MANAGEMENT_SERVICE + '/contracts';
const SERVICE_DELETE_CONTRACT = ServicePath.MANAGEMENT_SERVICE + '/contracts';
const SERVICE_RETURN_CONTRACT = ServicePath.MANAGEMENT_SERVICE + '/contracts';
const SERVICE_SUBMIT_SEND_DARFT = ServicePath.MANAGEMENT_SERVICE + '/contracts';
const SERVICE_SUBMIT_CONFIRM_DARFT = ServicePath.MANAGEMENT_SERVICE + '/contracts';
const SERVICE_CONTRACT_VIP = ServicePath.MANAGEMENT_SERVICE + '/contracts/list-contract-vip';
const DOWNLOAD_ENCODE_FILE =
  ServicePath.ESIGNATURE_SERVICE + '/dsign/v1/download-documents-return-url';
const CONFIRM_OTP_SIGN_SERVER = ServicePath.SIGN_SERVICE + '/api/sign/otp-confirm';
const DIEU_KHOAN_URL = ServicePath.MANAGEMENT_SERVICE + '/esign-term.html';
const UPDATE_DRAFT_CONTRACT = ServicePath.MANAGEMENT_SERVICE + '/contracts';
const SEND_CENSORSHIP_INTERNAL = ServicePath.MANAGEMENT_SERVICE + '/contracts';
const APPROVE_CONTRACT_INTERNAL_NOSIGN = ServicePath.MANAGEMENT_SERVICE + '/contracts';
const REJECT_CONTRACT_INTERNAL = ServicePath.MANAGEMENT_SERVICE + '/contracts';
const SUBMIT_SIGN_INTERNAL = ServicePath.MANAGEMENT_SERVICE + '/contracts';
const FINISH_CENSORSHIP_INTERNAL = ServicePath.MANAGEMENT_SERVICE + '/contracts';
const CREATE_CONTRACT_WITH_TEMPATE =
  ServicePath.MANAGEMENT_SERVICE + '/contracts/create-template-contract';
const SERVICE_GET_LIST_CONTRACT_SIGN_MULTI = ServicePath.MANAGEMENT_SERVICE + '/user-informations';
const GET_SIGN_COUNT = ServicePath.MANAGEMENT_SERVICE + "/contracts";
const UNDO_CANCEL_CONTRACT = ServicePath.MANAGEMENT_SERVICE + '/contracts';
const SIGN_CONTRACT_NUMBER = ServicePath.ESIGNATURE_SERVICE + '/dsign/sign-contract-number';
const ALLOW_SIGNATURE = ServicePath.MANAGEMENT_SERVICE + '/contracts';
const REQUEST_SIGN_AGAIN = ServicePath.MANAGEMENT_SERVICE + '/contracts/{{contractId}}/request-resign/{{partyId}}'


const LIST_CONTRACT_APPROVE_WITHOUT_SIGN = ServicePath.MANAGEMENT_SERVICE + '/contracts/list-approve-not-sign';
const BATCH_APPROVE_WITHOUT_SIGN = ServicePath.MANAGEMENT_SERVICE + '/contracts/approve-not-sign';
const LIST_CONTRACT_NOSIGN = ServicePath.MANAGEMENT_SERVICE + '/contracts/list-approve-not-sign';

// URL for contract version
const GET_VERSION_BY_CONTRACT_ID = ServicePath.MANAGEMENT_SERVICE + '/contracts/$contractId/trace-changes';
const DELETE_VERSION_BY_ID = ServicePath.MANAGEMENT_SERVICE + '/contract-trace-changes';
const RESTORE_VERSION = ServicePath.MANAGEMENT_SERVICE + '/contracts/$contractId/trace-changes/$traceId/apply';
const CHANGE_VERSION_NAME_BY_ID = ServicePath.MANAGEMENT_SERVICE + '/contract-trace-changes';
const DOWNLOAD_FILE_BY_VERSION = ServicePath.MANAGEMENT_SERVICE + "/contracts/{{contractId}}/download-documents?documentType=TRACE&fileKey={{fileKey}}"

const WV_SIGN_USB_LICENSE = ServicePath.MANAGEMENT_SERVICE + "/ap-key"
@Injectable({
  providedIn: 'root',
})
export class AasContractService {

  public static instance: AasContractService;

  constructor(
    private api: API
  ) { 
    if (!AasContractService.instance) {
      AasContractService.instance = this;
    }
    return AasContractService.instance;
  }

  /**
   * GET Request lấy danh sách hợp đồng
   * @param partyId
   * @param partnerId
   * @param serviceId
   * @param fromDate
   * @param toDate
   * @param status
   * @param keySearch
   * @param page
   * @param maxSize
   * @param sort
   * @param propertiesSort
   */
  getListContact(partyId, partnerId, serviceId, fromDate, toDate, status, keySearch, page, maxSize, sort, propertiesSort, signTurn = '') {
    const url = SERVICE_GET_LIST_CONTRACT + `/${partyId}/contract-list?partnerId=${partnerId}&serviceId=${serviceId}&fromDate=${fromDate}&toDate=${toDate}&status=${status}&keySearch=${keySearch}&page=${page}&maxSize=${maxSize}&sort=${sort}&propertiesSort=${propertiesSort}&signTurn=${signTurn}`;
    return this.api.get(url);
  }

  getListContactByOwner(partyId, partnerId, partnerType,  serviceId, fromDate, toDate, status, keySearch, page, maxSize, sort, propertiesSort, signTurn = '', contractTypeId = '', processUserId = '', processTypeId = '') {
    const url = SERVICE_GET_LIST_CONTRACT + `/${partyId}/contract-list-by-owner?keySearch=${keySearch}&partnerId=${partnerId}&partnerType=${partnerType}&serviceId=${serviceId}&fromDate=${fromDate}&toDate=${toDate}&status=${status}&page=${page}&maxSize=${maxSize}&sort=${sort}&propertiesSort=${propertiesSort}&signTurn=${signTurn}&contractTypeId=${contractTypeId}&processUserId=${processUserId}&processTypeId=${processTypeId}`;
    return this.api.get(url);
  }

  getListContactByAssign(partyId, partnerId, partnerType, serviceId, fromDate, toDate, status, keySearch, page, maxSize, sort, propertiesSort, signTurn = '', contractTypeId = '') {
    const url = SERVICE_GET_LIST_CONTRACT + `/${partyId}/contract-list-by-assign?keySearch=${keySearch}&partnerId=${partnerId}&partnerType=${partnerType}&serviceId=${serviceId}&fromDate=${fromDate}&toDate=${toDate}&status=${status}&page=${page}&maxSize=${maxSize}&propertiesSort=${propertiesSort}&signTurn=${signTurn}&contractTypeId=${contractTypeId}`;
    return this.api.get(url);
  }

  /**
   * Khởi tạo hợp đồng
   * @param data
   */
  postCreateDraftOnlyFile(data: FormData) {
    const url = SERVICE_CREATE_DRAFT_ONLY_FILE;
    return this.api.postMultiPartJSON(url, data);
  }

  /**
   * Request convert file docx sang pdf
   * @param data
   */
  postConvertToPdf(data: FormData) {
    const url = TEMPLATE_CONVERT_TO_PDF;
    return this.api.postMultiPart(url, data);
  }

  /**
   * Tìm kiếm khách hàng
   * @param key
   */
  searchCustomer(key) {
    const url = SERVICE_SEARCH_CUSTOMER + `?string=${key}`;
    return this.api.get(url);
  }

  /**
   * Láy thông tin hợp đồng
   * @param id
   */
  getContract(id) {
    const url = SERVICE_GET_CONTRACT + `/${id}`;
    return this.api.get(url);
  }

  /**
   * Tải xuống file hợp đồng
   * @param id
   * @param type
   */
  downloadFileContract(id, type) {
    const url =
      SERVICE_DOWNLOAD_CONTRACT_FILE +
      `/${id}/download-documents?documentType=${type}&fileKey=${id}`;
    return this.api.download(url);
  }

  /**
   * Tải xuống file hợp đồng
   * @param id
   * @param type
   */
  downloadFileWithHeader(id, type) {
    const url =
      SERVICE_DOWNLOAD_CONTRACT_FILE +
      `/${id}/download-documents?documentType=${type}&fileKey=${id}`;
    return this.api.requestDownload(url);
  }

  /**
   * Lay thong tin file trong hop dong
   * @param id
   * @param type
   * @param fileKey
   */
  getContractFileInfo(id, type, fileKey) {
    const url =
      GET_CONTRACT_FILE +
      `/${id}/download-documents-return-url?documentType=${type}&fileKey=${fileKey}`;
    return this.api.get(url);
  }

  /**
   * Tai file hop dong tu url
   * @param url
   */
  downloadContractFileByUrl(url) {
    return this.api.downloadByFullUrl(url);
  }

  //Cập nhật thông tin hợp đồng
  postUpdateContract(id, data: FormData) {
    const url = SERVICE_UPDATE_CONTRACT + `/${id}/update-draft-only-file`;
    return this.api.postMultiPartJSON(url, data);
  }

  postUpdateContractVip(id, data: FormData) {
    const url = SERVICE_UPDATE_CONTRACT_VIP + `/${id}/update-draft-only-file-vip`;
    return this.api.postMultiPartJSON(url, data);
  }

  //Gửi hợp đồng ký bằng usb token
  postSendContract(id, data: FormData) {
    const url = SERVICE_SIGN_USB_TOKEN + `/${id}/digital-sign`;
    return this.api.postMultiPartJSON(url, data);
  }

  //Request ký bằng sign server
  postSignServer(data) {
    const url = SERVICE_SIGN_SERVER;
    return this.api.post(url, data);
  }

  // Gửi yêu cầu ký điện tử
  postRequestElectronicSign(contractId, signForm = 'OTP') {
    const url = SERVICE_REQUEST_E_SIGN + `/${contractId}/electronic-sign?signForm=${signForm}`;
    return this.api.post(url, null);
  }

  //Confirm OTP ký điện tử
  postConfirmOTPESign(idSign, param) {
    const url = SERVICE_CONFIRM_OTP_E_SIGN + `/${idSign}/verify`;
    return this.api.post(url, param);
  }

  //Post ký điện tử
  postESign(id, data: FormData) {
    const url = SERVICE_E_SIGN + `/${id}/sign-by-userId`;
    return this.api.postMultiPart(url, data);
  }

  //Download file từ url
  dowloadFile(url) {
    return this.api.downloadOnlyURL(url);
  }

  //Lấy danh sách chữ ký trong hợp đồng
  getListSignatureByContract(contractId, documentType) {
    const url =
      SERVICE_GET_LIST_SIGNATURE + `?contractId=${contractId}&documentType=${documentType}`;
    return this.api.get(url);
  }

  //Huỷ hợp đồng
  postCancelContract(contractId, param) {
    const url = SERVICE_CANCEL_CONTRACT + `/${contractId}/cancel-draft`;
    return this.api.post(url, param);
  }

  //Convert HTML To PDF
  postConvertHTMLToPdf(data: FormData) {
    const url = SERVICE_CONVERT_HTML_TO_PDF;
    return this.api.postMultiPart(url, data);
  }

  /**
   * Khởi tạo hợp đồng cho tổ chức vip
   * @param data
   */
  postCreateDraftOnlyFileVip(data: FormData) {
    const url = SERVICE_CREATE_DRAFT_ONLY_FILE_VIP;
    return this.api.postMultiPartJSON(url, data);
  }

  /**
   * xóa hợp đồng
   * @param
   */
  postDeleteContract(contractId) {
    const url = SERVICE_DELETE_CONTRACT + `/${contractId}/delete-draft`;
    return this.api.post(url, null);
  }

  /**
   * trả lại hợp đồng
   * @param data
   */
  postReturnContract(contractId, param) {
    const url = SERVICE_RETURN_CONTRACT + `/${contractId}/cancel-draft`;
    return this.api.post(url, param);
  }

  /**
   * từ chối thỏa thuận
   * @param data
   */
  postCancelDeal(contractId) {
    const url = SERVICE_RETURN_CONTRACT + `/${contractId}/deny-deal-draft`;
    return this.api.post(url, null);
  }

  /**
   * đồng ý thỏa thuận
   * @param data
   */
  postAcceptDeal(contractId) {
    const url = SERVICE_RETURN_CONTRACT + `/${contractId}/deal-draft`;
    return this.api.post(url, null);
  }

  /**
   * thay đổi nội dung
   * @param data
   */
  postUpdateContent(contractId, param) {
    const url = SERVICE_RETURN_CONTRACT + `/${contractId}/update-draft-only-file`;
    return this.api.post(url, null);
  }

  /**
   * Submit gui ban nhap hop dong den cac ben
   * @param contractId
   */
  postSubmitSendDraft(contractId) {
    const url = SERVICE_SUBMIT_SEND_DARFT + `/${contractId}/submit-draft-sign`;
    return this.api.post(url, null);
  }

  /**
   * Dong ty chot hop dong
   * @param contractId
   */
  postAcceptContract(contractId, type = 'PARTNER', footer = '') {
    const url = SERVICE_SUBMIT_CONFIRM_DARFT + `/${contractId}/deal-draft?type=${type}&footer=${footer}`;
    return this.api.post(url, null);
  }

  /**
   * Tu choi chot hop dong
   * @param contractId
   */
  postDenyContract(contractId, type = 'PARTNER', reason = '', footer = '') {
    const url =
      SERVICE_SUBMIT_CONFIRM_DARFT + `/${contractId}/deny-deal-draft?type=${type}&footer=${footer}&reason=${reason}`;
    return this.api.post(url, null);
  }

  /**
   * Danh sách Hợp đồng cho admin
   * @param partyId
   * @param partnerId
   * @param partnerType
   * @param serviceId
   * @param fromDate
   * @param toDate
   * @param status
   * @param keySearch
   * @param page
   * @param maxSize
   * @param sort
   * @param propertiesSort
   */
  getListContractVip(
    partyId,
    partnerId,
    partnerType,
    serviceId,
    fromDate,
    toDate,
    status,
    keySearch,
    page,
    maxSize,
    sort,
    propertiesSort
  ) {
    const url =
      SERVICE_CONTRACT_VIP +
      `?partnerId=${partnerId}&partnerType=${partnerType}&serviceId=${serviceId}&fromDate=${fromDate}&toDate=${toDate}&status=${status}&keySearch=${keySearch}&page=${page}&maxSize=${maxSize}&sort=${sort}&propertiesSort=${propertiesSort}`;
    return this.api.get(url);
  }

  /**
   * Tai file khong
   * @param contracId
   */
  downloadEncodeContract(contracId) {
    const url =
      DOWNLOAD_ENCODE_FILE + `?documentType=CONTRACT&view=attachment&fileKey=${contracId}`;
    return this.api.download(url);
  }

  /**
   * Confirm OTP khi ky signserver
   * @param data
   */
  postConfirmOTPSignServer(data) {
    const url = CONFIRM_OTP_SIGN_SERVER;
    return this.api.post(url, data);
  }

  getDieuKhoan() {
    const url = DIEU_KHOAN_URL;
    return this.api.download(url);
  }

  postUpdateDraftContract(id, data: FormData) {
    const url = UPDATE_DRAFT_CONTRACT + `/${id}/update-draft-reset`;
    return this.api.postMultiPartJSON(url, data);
  }

  postSendCensorshipInternal(contractId, userId) {
    const url = SEND_CENSORSHIP_INTERNAL + `/${contractId}/transfer-user-internal?userId=${userId}`;
    return this.api.post(url, null);
  }

  postApproveInternalContract(contractId) {
    const url = APPROVE_CONTRACT_INTERNAL_NOSIGN + `/${contractId}/approve-not-sign`;
    return this.api.post(url, null);
  }

  postRejectedInternalContract(contractId, param) {
    const url = REJECT_CONTRACT_INTERNAL + `/${contractId}/reject-internal?reason=${param}`;
    return this.api.post(url, param);
  }

  postSubmitSignInternal(contractId, formData: FormData) {
    const url = SUBMIT_SIGN_INTERNAL + `/${contractId}/temporary-sign`;
    return this.api.postMultiPartJSON(url, formData);
  }

  postFinishCensorshipInternal(contractId) {
    const url = FINISH_CENSORSHIP_INTERNAL + `/${contractId}/internal-finish`;
    return this.api.post(url, null);
  }

  /**
   * Khởi tạo hợp đồng với mẫu
   * @param data
   */
  postCreateContractWithTemplate(data: FormData) {
    const url = CREATE_CONTRACT_WITH_TEMPATE;
    return this.api.postMultiPartJSON(url, data);
  }

  //Post ký điện tử
  postESignV2(id, data: FormData) {
    const url = SERVICE_E_SIGN + `/${id}/v2/sign-by-userId`;
    return this.api.postMultiPart(url, data);
  }

  getListContactSignMultiple(
    partyId,
    partnerType,
    fromDate,
    toDate,
    status,
    keySearch,
    page,
    maxSize,
    sort,
    propertiesSort,
    signForm, 
    contractTypeId =''
  ) {
    const url =
      SERVICE_GET_LIST_CONTRACT_SIGN_MULTI +
      `/${partyId}/contract-list-by-series-sign?partnerType=${partnerType}&fromDate=${fromDate}&toDate=${toDate}&status=${status}&page=${page}&maxSize=${maxSize}&sort=${sort}&propertiesSort=${propertiesSort}&signForm=${signForm}&keySearch=${keySearch}&contractTypeId=${contractTypeId}`;
    return this.api.get(url);
  }

  /**
   * Lấy số lượt ký còn lại
   * @param contractId 
   * @returns 
   */
  getSignCount(contractId = "") {
    const url = GET_SIGN_COUNT + `/${contractId}/get-sign-number`;
    return this.api.get(url);
  }
  
  postUndoCancleContract(id) {
    const url = UNDO_CANCEL_CONTRACT + `/${id}/rollback-cancel`;
    return this.api.post(url,'');
  }
  
  getListContractsWithoutSign(partyId, partnerType, fromDate, toDate, status, keySearch, page, maxSize, sort, propertiesSort, contractTypeId = "") {
    const url = LIST_CONTRACT_APPROVE_WITHOUT_SIGN + `?partnerType=${partnerType}&fromDate=${fromDate}&toDate=${toDate}&status=${status}&page=${page}&maxSize=${maxSize}&sort=${sort}&propertiesSort=${propertiesSort}&keySearch=${keySearch}&contractTypeId=${contractTypeId}`;
    return this.api.get(url);
  }

  batchApproveWithoutSign(data: any) {
    return this.api.post(BATCH_APPROVE_WITHOUT_SIGN, data);
  }

  /**
   * Lấy danh sách hợp đồng đã được duyệt ko ký
   * @param requestId 
   */
  getListNoSignContract(requestId: string, page: number, maxSize: number) {
    return this.api.get(LIST_CONTRACT_NOSIGN + `/${requestId}?page=${page}&maxSize=${maxSize}`);
  }

  // Chức năng danh sách phiên bản

  getVersionByContractId(contractId: string, type: string) {
    return this.api.get(GET_VERSION_BY_CONTRACT_ID.replace('$contractId', contractId) + `?type=${type}`);
  }

  deleteVersionById(id: string) {
    return this.api.delete(DELETE_VERSION_BY_ID + `/${id}`);
  }

  restoreVersionByContractIdAndTraceId(contractId: string, tractId: string) {
    return this.api.post(RESTORE_VERSION.replace('$contractId', contractId).replace('$traceId', tractId), null);
  }

  changeVersionNameById(traceId: string, name: string) {
    return this.api.patch(CHANGE_VERSION_NAME_BY_ID + `/${traceId}`, {
      name: name
    });
  }

  downloadContractFileByVersion(contractId, versionId) {
    var url = DOWNLOAD_FILE_BY_VERSION.replace("{{contractId}}", contractId)
      .replace("{{fileKey}}", versionId);
    return this.api.download(url);
  }
  //Post ký điện tử
  postSignContractNumber(data: FormData) {
    const url = SIGN_CONTRACT_NUMBER ;
    return this.api.postMultiPart(url, data);
  }
  
  getListDownloadContractSignMultipleByOwner(partyId, partnerId, partnerType, serviceId, fromDate, toDate, status, keySearch, page, maxSize, sort, propertiesSort){
    const url= SERVICE_GET_LIST_CONTRACT_SIGN_MULTI + `/contract-list-by-owner-download?keySearch=${keySearch}&partnerId=${partnerId}&partnerType=${partnerType}&serviceId=${serviceId}&fromDate=${fromDate}&toDate=${toDate}&status=${status}&page=${page}&maxSize=${maxSize}&sort=${sort}&propertiesSort=${propertiesSort}`
    return this.api.get(url)
  }
  getListDownloadContractSignMultipleByAssign(partyId, partnerId, partnerType, serviceId, fromDate, toDate, status, keySearch, page, maxSize, sort, propertiesSort){
    const url= SERVICE_GET_LIST_CONTRACT_SIGN_MULTI + `/contract-list-by-assign-download?keySearch=${keySearch}&partnerId=${partnerId}&partnerType=${partnerType}&serviceId=${serviceId}&fromDate=${fromDate}&toDate=${toDate}&status=${status}&page=${page}&maxSize=${maxSize}&sort=${sort}&propertiesSort=${propertiesSort}`
    return this.api.get(url)
  }

  /**
   * trả lại hợp đồng
   * @param data
   */
  postReturnContractV2(contractId, param) {
    const url = SERVICE_RETURN_CONTRACT + `/${contractId}/deny-sign-draft`;
    return this.api.post(url, param);
  }

  getUSBLicenseKey(clientId) {
    const url = WV_SIGN_USB_LICENSE + `/get-sign-digital-license?tokenId=${clientId}`;
    return this.api.get(url);
  }

  allowSignature(contractId, signatureId) {
    const url = ALLOW_SIGNATURE + `/${contractId}/allow-signature/${signatureId}`;
    return this.api.post(url, null);
  }

  /**
   * Gửi yêu cầu ký lại
   * @param contractId Id hợp đồng
   * @param partyId id tổ chức
   * @param data dữ liệu đính kèm
   * @returns 
   */
  requestSignAgain(contractId, partyId, data) {
    let url = REQUEST_SIGN_AGAIN.replace("{{contractId}}", contractId).replace("{{partyId}}", partyId);
    return this.api.post(url, data);
  }
}
