<div class="bg-white">
    <section class="p-3">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <form class="input-group border rounded">
                    <div class="input-group-prepend border-0">
                        <button class="btn d-inline-flex pr-2 pl-2" type="submit"><span
                                class="material-icons">search</span></button>
                    </div>
                    <input [(ngModel)]="keySearch" [ngModelOptions]="{standalone: true}" type="text"
                    class="form-control border-0" aria-describedby="basic-addon1" #searchField
                    (keydown.enter)="searchWithFilter()">
                </form>
            </div>
            <div class="col-md-auto ml-auto">
                <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="downloadSample()">
                    <span class="material-icons mr-1">file_download</span>Xuất Excel
                </button>
                <div class="btn-group">
                    <div ngbDropdown container="body" [autoClose]="'outside'" [placement]="'bottom-right'" [display]="'dynamic'">
                        <button ngbDropdownToggle class="btn btn-outline-primary dropdown-toggle d-inline-flex align-items-center">
                            <span class="material-icons mr-1">visibility</span>Hiển thị
                        </button>
                        <div ngbDropdownMenu>
                            <div ngbDropdownItem *ngFor="let item of listConfigColTable" class="p-0">
                                <div  class="tcheck-primary px-3">
                                    <input type="checkbox" id="{{ item.name }}" [checked]="configColTable[item.name]">
                                    <label for="{{ item.name }}" class="w-100" [class.checked]="configColTable[item.name]"
                                        (click)="actConfigColTableView($event, item.name)">
                                        {{ item.label }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </section>
    <hr class="">

    <section class="px-3 my-2">
        <form [formGroup]="formFilter">
            <div class="row">
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="status">Hình thức sử dụng</label>
                        <div class="input-group">
                         <select formControlName="packageType" class="form-control"
                        (change)="changePackageType($event)">
                            <option value="" selected>Tất cả</option>
                            <option value="TRIAL_TYPE">Dùng thử</option>
                            <option value="NORMAL_TYPE">Chính thức</option>
                        </select>
                        <!-- <ng-select2 formControlName="status" [data]="statusOptions" [options]="optionsStatus"
                                    [placeholder]="'Chọn trạng thái'">
                        </ng-select2> -->
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group">
                        <label for="status">Tỉnh/Thành phố</label>
                        <!-- <ng-select placeholder="Chọn tỉnh/thành phố" formControlName="tinhId" class="input-custom-control">
                            <ng-option *ngFor="let tinh of tinhBindData" [value]="tinh.id">{{ tinh.label }}</ng-option>
                        </ng-select>
                        <app-show-validate-errors [isSubmit]="isSubmit"
                            [control]="formFilter.controls.tinhId"
                            [detail]="{ name: 'tinhId' }">
                        </app-show-validate-errors> -->
                        <div>         
                            <ng-select [items]="tinhBindData"
                                [clearSearchOnAdd]="true"
                                [closeOnSelect]="false" 
                                [multiple]="true" 
                                labelForId="state"
                                placeholder="Chọn tỉnh/thành phố"
                                clearAllText="Clear"
                                formControlName="tinhId"
                                [bindLabel]="'label'"
                                bindValue="id"
                                >
                                    
                                    <ng-template ng-header-tmp>
                                        <div class="custom-control custom-checkbox">
                                        <input type="checkbox" [ngModel]="selectedAllTinh" [ngModelOptions]="{standalone: true}"
                                            class="custom-control-input" id="selectallTinh" (click)="selectAllTinh()"/>
                                        <label class="form-check-label ml-2 custom-control-label" for="selectallTinh">Tất cả</label>
                                        </div>
                                    </ng-template>

                                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                                        <div class="custom-control custom-checkbox">
                                            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}" />
                                            {{item['label']}}
                                        </div>
                                    </ng-template>
                                    <ng-template ng-multi-label-tmp let-items="items">
                                        <div class="ng-value" *ngIf="items.length == 64;else other_content">
                                            <span class="ng-value-label">Tất cả</span>
                                        </div>
                                        <ng-template #other_content>
                                            <div class="ng-value" *ngFor="let item of items | slice:0:2">
                                                {{item['label']}}
                                            </div>
                                            <div class="ng-value" *ngIf="items.length > 2 && items.length < 64">
                                                <span class="ng-value-label">{{items.length - 2}} ...</span>
                                            </div>
                                        </ng-template>
                                    </ng-template>
                            </ng-select>
                            
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group">
                        <label for="">Từ ngày khởi tạo</label>
                        <div class="input-group">
                            <input #fromDatePicker class="form-control" [control]="formFilter.controls.fromDate"
                                [value]="formFilter.value.fromDate"
                                (dateSelect)="changeDateFilter($event, dateType.CREATED_FROM)"
                                placeholder="dd/MM/yyyy" ngbDatepicker #createdFromDate="ngbDatepicker"
                                [maxDate]="{year: currentDate.getFullYear(), month: currentDate.getMonth()+1, day: currentDate.getDate()}"
                                appInputMark [options]="optionsCleave">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" (click)="createdFromDate.toggle()"
                                    type="button"><em class="fas fa-calendar-alt"></em></button>
                            </div>
                            <app-show-validate-errors [useSubmit]="false"
                                [errorMessages]="validateSearch.fromDate"
                                [control]="formFilter.controls.fromDate"
                                [detail]="{ name: 'fromDate' }">
                            </app-show-validate-errors>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group">
                        <label for="">Đến ngày khởi tạo</label>
                        <div class="input-group">
                            <input #toDatePicker class="form-control" [control]="formFilter.controls.toDate"
                                [value]="formFilter.value.toDate"
                                (dateSelect)="changeDateFilter($event, dateType.CREATED_TO)"
                                placeholder="dd/MM/yyyy" ngbDatepicker #createdToDate="ngbDatepicker"
                                [maxDate]="{year: currentDate.getFullYear(), month: currentDate.getMonth()+1, day: currentDate.getDate()}"
                                appInputMark [options]="optionsCleave">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" (click)="createdToDate.toggle()"
                                    type="button"><em class="fas fa-calendar-alt"></em></button>
                            </div>
                            <app-show-validate-errors [useSubmit]="false"
                                [errorMessages]="validateSearch.toDate"
                                [control]="formFilter.controls.toDate"
                                [detail]="{ name: 'toDate' }">
                            </app-show-validate-errors>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group">
                        <label for="status">Trạng thái</label>
                        <div class="input-group">
                         <select formControlName="disabled" class="form-control"
                        (change)="changeDisabled($event)">
                            <option value="" selected>Tất cả</option>
                            <option value="N">Đang hoạt động</option>
                            <option value="Y">Ngưng hoạt động</option>
                        </select>
                        <!-- <ng-select2 formControlName="status" [data]="statusOptions" [options]="optionsStatus"
                                    [placeholder]="'Chọn trạng thái'">
                        </ng-select2> -->
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="form-group">
                        <label for="status">Tình trạng sử dụng</label>
                        <div>
                            <ng-select class="custom"
                            [items]="statusOptions"
                            [multiple]="true"
                            [closeOnSelect]="false"
                            placeholder="Nhập tình trạng sử dụng"
                            autofocus [(ngModel)]="selectedStatus"
                            (change)="changeStatus($event)"
                            formControlName="statusUse"
                            >
                          
                            <ng-template ng-multi-label-tmp>
                              <span *ngIf="selectedStatus.length == statusOptions.length; else notall">Tất cả</span>
                              <ng-template #notall>
                                <span *ngFor="let item of selectedStatus">{{item}}</span>
                              </ng-template>
                            </ng-template>
                            <ng-template ng-header-tmp>
                              <div class="custom-control custom-checkbox">
                                <input type="checkbox" [ngModel]="selectedAllStatus" [ngModelOptions]="{standalone: true}"
                                  class="custom-control-input" id="selectallStatus" (click)="selectAllStatus()"/>
                                <label class="form-check-label ml-2 custom-control-label" for="selectallStatus">Tất cả</label>
                              </div>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" 
                              let-index="index" let-item$="item$">
                              <div class="custom-control custom-checkbox">
                                  <input type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{standalone: true}"
                                    class="custom-control-input"/>
                                  <label class="form-check-label ml-2 custom-control-label">{{item}}</label>
                              </div>
                            </ng-template>
                            </ng-select>
                        </div>
                    </div>
                </div>
        
                <div class="col-md-auto ml-auto mt-auto pb-3">
                    <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="cleanFilter()"> Đặt lại</button>
                    <button class="btn btn-primary d-inline-flex" (click)="submitFilter()"> Áp dụng</button>
                </div>
            </div>
        </form>
    </section>
    <hr class="">

    <section class="card-body p-0">
        <div class="table-responsive">
            <table class="table table-sort-col mb-0">
                <thead>
                    <tr class="bg-tb-header">
                        <th>STT</th>
                        <th [class.d-none]="!configColTable.tenThueBao" (click)="sortCol('tenThueBao')">
                            <span class="d-flex align-self-center align-items-center">
                                Tên thuê bao
                                <span *ngIf="this.sortColConfig['tenThueBao'] == null" class="material-icons ml-auto">
                                    unfold_more
                                </span>
                                <span *ngIf="this.sortColConfig['tenThueBao'] == true" class="material-icons ml-auto">
                                    expand_less
                                </span>
                                <span *ngIf="this.sortColConfig['tenThueBao'] == false" class="material-icons ml-auto">
                                    expand_more
                                </span>
                            </span>
                        </th>
                        <th [class.d-none]="!configColTable.maSoThue" (click)="sortCol('maSoThue')">
                            <span class="d-flex align-self-center align-items-center">
                                Mã số thuế
                                <span *ngIf="this.sortColConfig['maSoThue'] == null" class="material-icons ml-auto">
                                    unfold_more
                                </span>
                                <span *ngIf="this.sortColConfig['maSoThue'] == true" class="material-icons ml-auto">
                                    expand_less
                                </span>
                                <span *ngIf="this.sortColConfig['maSoThue'] == false" class="material-icons ml-auto">
                                    expand_more
                                </span>
                            </span>
                        </th>
                        <th [class.d-none]="!configColTable.diaChi" (click)="sortCol('diaChi')">
                            <span class="d-flex align-self-center align-items-center">
                                Địa chỉ

                            </span>
                        </th>
                        <th [class.d-none]="!configColTable.hinhThucSuDung" (click)="sortCol('hinhThucSuDung')">
                            <span class="d-flex align-self-center align-items-center">
                                Hình thức sử dụng
                                <span *ngIf="this.sortColConfig['nhanVienBanHang'] == null" class="material-icons ml-auto">
                                    unfold_more
                                </span>
                                <span *ngIf="this.sortColConfig['nhanVienBanHang'] == true" class="material-icons ml-auto">
                                    expand_less
                                </span>
                                <span *ngIf="this.sortColConfig['nhanVienBanHang'] == false" class="material-icons ml-auto">
                                    expand_more
                                </span>
                            </span>
                        </th>
                        <th [class.d-none]="!configColTable.nhanVienBanHang" (click)="sortCol('nhanVienBanHang')">
                            <span class="d-flex align-self-center align-items-center">
                                Nhân viên bán hàng
                                <span *ngIf="this.sortColConfig['nhanVienBanHang'] == null" class="material-icons ml-auto">
                                    unfold_more
                                </span>
                                <span *ngIf="this.sortColConfig['nhanVienBanHang'] == true" class="material-icons ml-auto">
                                    expand_less
                                </span>
                                <span *ngIf="this.sortColConfig['nhanVienBanHang'] == false" class="material-icons ml-auto">
                                    expand_more
                                </span>
                            </span>
                        </th>
                        <th [class.d-none]="!configColTable.tinhTP" (click)="sortCol('tinhTP')">
                            <span class="d-flex align-self-center align-items-center">
                                Tỉnh/TP
                                <span *ngIf="this.sortColConfig['tinhTP'] == null" class="material-icons ml-auto">
                                    unfold_more
                                </span>
                                <span *ngIf="this.sortColConfig['tinhTP'] == true" class="material-icons ml-auto">
                                    expand_less
                                </span>
                                <span *ngIf="this.sortColConfig['tinhTP'] == false" class="material-icons ml-auto">
                                    expand_more
                                </span>
                            </span>
                        </th>
                        <th [class.d-none]="!configColTable.ngayKhoiTao" (click)="sortCol('ngayKhoiTao')">
                            <span class="d-flex align-self-center align-items-center">
                                Ngày khởi tạo
                                <span *ngIf="this.sortColConfig['ngayKhoiTao'] == null" class="material-icons ml-auto">
                                    unfold_more
                                </span>
                                <span *ngIf="this.sortColConfig['ngayKhoiTao'] == true" class="material-icons ml-auto">
                                    expand_less
                                </span>
                                <span *ngIf="this.sortColConfig['ngayKhoiTao'] == false" class="material-icons ml-auto">
                                    expand_more
                                </span>
                            </span>
                        </th>
                        
                        <th [class.d-none]="!configColTable.trangThai" (click)="sortCol('trangThai')">
                            <span class="d-flex align-self-center align-items-center">
                                Trạng thái
                                <span *ngIf="this.sortColConfig['trangThai'] == null" class="material-icons ml-auto">
                                    unfold_more
                                </span>
                                <span *ngIf="this.sortColConfig['trangThai'] == true" class="material-icons ml-auto">
                                    expand_less
                                </span>
                                <span *ngIf="this.sortColConfig['trangThai'] == false" class="material-icons ml-auto">
                                    expand_more
                                </span>
                            </span>
                        </th>
                        <th [class.d-none]="!configColTable.soLuongHopDong" (click)="sortCol('soLuongHopDong')">
                            <span class="d-flex align-self-center align-items-center">
                                Số lượng hợp đồng
                                <span *ngIf="this.sortColConfig['soLuongHopDong'] == null" class="material-icons ml-auto">
                                    unfold_more
                                </span>
                                <span *ngIf="this.sortColConfig['soLuongHopDong'] == true" class="material-icons ml-auto">
                                    expand_less
                                </span>
                                <span *ngIf="this.sortColConfig['soLuongHopDong'] == false" class="material-icons ml-auto">
                                    expand_more
                                </span>
                            </span>
                        </th>
                        <th [class.d-none]="!configColTable.soLuotSMSOTP" (click)="sortCol('soLuotSMSOTP')">
                            <span class="d-flex align-self-center align-items-center">
                                Số lượt ký OTP
                                <span *ngIf="this.sortColConfig['soLuotSMSOTP'] == null" class="material-icons ml-auto">
                                    unfold_more
                                </span>
                                <span *ngIf="this.sortColConfig['soLuotSMSOTP'] == true" class="material-icons ml-auto">
                                    expand_less
                                </span>
                                <span *ngIf="this.sortColConfig['soLuotSMSOTP'] == false" class="material-icons ml-auto">
                                    expand_more
                                </span>
                            </span>
                        </th>
                        <th [class.d-none]="!configColTable.soLuotEKYC" (click)="sortCol('soLuotEKYC')">
                            <span class="d-flex align-self-center align-items-center">
                                Số lượt eKYC
                                <span *ngIf="this.sortColConfig['soLuotEKYC'] == null" class="material-icons ml-auto">
                                    unfold_more
                                </span>
                                <span *ngIf="this.sortColConfig['soLuotEKYC'] == true" class="material-icons ml-auto">
                                    expand_less
                                </span>
                                <span *ngIf="this.sortColConfig['soLuotEKYC'] == false" class="material-icons ml-auto">
                                    expand_more
                                </span>
                            </span>
                        </th>
                        <th [class.d-none]="!configColTable.thoiGianLuuTru" (click)="sortCol('thoiGianLuuTru')">
                            <span class="d-flex align-self-center align-items-center">
                                Thời hạn lưu trữ(Tháng)
                                <span *ngIf="this.sortColConfig['thoiGianLuuTru'] == null" class="material-icons ml-auto">
                                    unfold_more
                                </span>
                                <span *ngIf="this.sortColConfig['thoiGianLuuTru'] == true" class="material-icons ml-auto">
                                    expand_less
                                </span>
                                <span *ngIf="this.sortColConfig['thoiGianLuuTru'] == false" class="material-icons ml-auto">
                                    expand_more
                                </span>
                            </span>
                        </th>
                        <th [class.d-none]="!configColTable.dungLuongLuuTru" (click)="sortCol('dungLuongLuuTru')">
                            <span class="d-flex align-self-center align-items-center">
                                Dung lượng lưu trữ(GB)
                                <span *ngIf="this.sortColConfig['packageNum'] == null" class="material-icons ml-auto">
                                    unfold_more
                                </span>
                                <span *ngIf="this.sortColConfig['packageNum'] == true" class="material-icons ml-auto">
                                    expand_less
                                </span>
                                <span *ngIf="this.sortColConfig['packageNum'] == false" class="material-icons ml-auto">
                                    expand_more
                                </span>
                            </span>
                        </th>
                        <th [class.d-none]="!configColTable.dungLuongLuuTru" (click)="sortCol('dungLuongLuuTru')">
                            <span class="d-flex align-self-center align-items-center">
                                Số lượt SMS
                                <span *ngIf="this.sortColConfig['packageNum'] == null" class="material-icons ml-auto">
                                    unfold_more
                                </span>
                                <span *ngIf="this.sortColConfig['packageNum'] == true" class="material-icons ml-auto">
                                    expand_less
                                </span>
                                <span *ngIf="this.sortColConfig['packageNum'] == false" class="material-icons ml-auto">
                                    expand_more
                                </span>
                            </span>
                        </th>
                        <th [class.d-none]="!configColTable.soLuotSMS" (click)="sortCol('soLuotSMS')">
                            <span class="d-flex align-self-center align-items-center">
                                Số lượt SMS
                                <span *ngIf="this.sortColConfig['sms'] == null" class="material-icons ml-auto">
                                    unfold_more
                                </span>
                                <span *ngIf="this.sortColConfig['sms'] == true" class="material-icons ml-auto">
                                    expand_less
                                </span>
                                <span *ngIf="this.sortColConfig['sms'] == false" class="material-icons ml-auto">
                                    expand_more
                                </span>
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of listCustomers; let i = index">
                        <td>
                            {{ ((page - 1) * maxSize) + i + 1 }}
                        </td>
                        <!-- <td [class.d-none]="!configColTable.tenThueBao">
                            <a class="text-primary text-decoration-underline cursor-pointer"
                                    [routerLink]="['/console/report-customer/detail', item.userId]">{{ item.ten || 'NULL' }}</a>
                        </td> -->
                        <td [class.d-none]="!configColTable.tenThueBao">
                            {{ item.tenToChuc }}
                        </td>
                        <td [class.d-none]="!configColTable.maSoThue">
                            {{ item.maSoThue }}
                        </td>
                        <td [class.d-none]="!configColTable.diaChi">
                            {{ item.address }}
                        </td>
                        <td [class.d-none]="!configColTable.hinhThucSuDung">
                            {{ getPackageTypeLabel(item.packageType) }}
                        </td>
                        <td [class.d-none]="!configColTable.nhanVienBanHang">
                            {{ item.requesterName}}-{{ item.requesterCode}}
                        </td>
                        <td [class.d-none]="!configColTable.tinhTP">
                            {{ item.tenTinh}}
                        </td>
                        <td [class.d-none]="!configColTable.ngayKhoiTao">
                            {{ item.createdDate}}
                        </td>
                        <td [class.d-none]="!configColTable.trangThai">
                            <span class="badge {{ getClassStatus(item.disabled) }} p-2">{{ getTextStatus(item.disabled) }}</span>
                        </td>
                        <td [class.d-none]="!configColTable.soLuongHopDong">
                            {{ item.usedContract}}/{{ item.allowedContract}}
                            <span [style.color]="checkColor(item.usedContract,item.allowedContract)">({{ item.usedContract != null && item.usedContract != 0  ? item.usedContract / item.allowedContract *100 : 100 }}%)</span>
                        </td>
                        <td [class.d-none]="!configColTable.soLuotSMSOTP">
                            {{ item.usedSmsOTP }}/{{ item.allowedSMSOTP}}
                            <span [style.color]="checkColor(item.usedSmsOTP,item.allowedSMSOTP)">({{ item.usedSmsOTP != null && item.usedSmsOTP != 0  ? item.usedSmsOTP / item.allowedSMSOTP *100 : 100 }}%)</span>
                        </td>
                        <td [class.d-none]="!configColTable.soLuotEKYC">
                            {{ item.allowedeKYC }}/{{ item.usedEkyc}}
                            <span [style.color]="checkColor(item.usedEkyc,item.allowedeKYC)">({{ item.usedEkyc != null && item.usedEkyc != 0  ? item.usedEkyc / item.allowedeKYC *100 : 100 }}%)</span>
                        </td>
                        <td [class.d-none]="!configColTable.thoiGianLuuTru">
                            {{ item.numberOfMonthStorageUsed }}/ {{ item.numberOfMonthStorage }}
                            <span [style.color]="checkColor(item.numberOfMonthStorageUsed,item.numberOfMonthStorage)">({{ item.numberOfMonthStorageUsed != null && item.numberOfMonthStorageUsed != 0  ? item.numberOfMonthStorageUsed / item.numberOfMonthStorage *100 : 100 }}%)</span>
                        </td>
                        <td [class.d-none]="!configColTable.dungLuongLuuTru">
                            {{ item.usedData}}/{{ item.allowedData}}
                            <span [style.color]="checkColor(item.usedData,item.allowedData)">({{ item.usedData != null && item.usedData != 0  ? item.usedData / item.allowedData *100 : 100 }}%)</span>
                        </td>
                        <td [class.d-none]="!configColTable.soLuotSMS">
                            {{ item.usedSMS}}/{{ item.allowedSMS}}
                            <span [style.color]="checkColor(item.usedSMS,item.allowedSMS)">({{ item.usedSMS != null && item.usedSMS != 0  ? item.usedSMS / item.allowedSMS *100 : 100 }}%)</span>
                        </td>
                        <!-- <td [class.d-none]="!configColTable.trangThai">
                            <span class="badge {{ getStyleAndValueBadge(item.packageNum)[0] }} p-2">{{ getStyleAndValueBadge(item.packageNum)[1] }}</span>
                        </td> -->
                        <!-- <td>
                            <div class="dropdown">
                                <a class="btn btn-dropdown dropdown-toggle" href="#" role="button"
                                    id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                    aria-expanded="false">
                                    <em class="material-icons">more_vert</em>
                                </a>

                                 <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                                    <a class="dropdown-item nav-material" style="cursor: pointer;"
                                    [routerLink]="['/console/report-customer/detail', item.userId]"
                                        routerLinkActive="router-link-active">
                                        <em class="material-icons">info</em>
                                        Thông tin chi tiết</a>
                                    
                                </div>
                            </div>
                        </td> -->
                    </tr>
                </tbody>
            </table>
        </div>
        
        <div class="bg-tb-header p-3">
            <div class="row align-items-center">
                <span *ngIf="totalElement == 0" class="col-md">Không tìm thấy kết quả</span>
                <span *ngIf="totalElement > 0" class="col-md">Hiển thị 1 đến {{ listCustomers.length }} trong tổng số
                    {{totalElement}} kết quả</span>
                <div class="col-md">
                    <div class="d-flex justify-content-end">
                        <div *ngIf="totalElement > maxSize" class="d-flex justify-content-end align-items-center">
                            <span class="mr-2">Số dòng hiển thị:</span>
                            <ng-select class="no-bg no-border" [(ngModel)]="maxSize" [searchable]="false" [clearable]="false" placeholder="..." (change)="getListCustomers()">
                                <ng-option [value]="10">10</ng-option>
                                <ng-option [value]="20">20</ng-option>
                                <ng-option [value]="30">30</ng-option>
                            </ng-select>
                        </div>
                        <div *ngIf="totalElement > maxSize" class="pagination pages no-border-pagination ml-2">
                            <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="false"
                                [pageSize]="maxSize" (pageChange)="getListCustomers($event)" [boundaryLinks]="true" aria-label="Default pagination">
                                <ng-template ngbPaginationFirst>Đầu</ng-template>
                                <ng-template ngbPaginationLast>Cuối</ng-template>
                                <ng-template ngbPaginationPrevious>&laquo;</ng-template>
                                <ng-template ngbPaginationNext>&raquo;</ng-template>
                                <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                            </ngb-pagination>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
        
    </section> 
    
   
     
</div>

