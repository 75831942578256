import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ConsumerSignupApprovedComponent } from '../layout/consumer-signup-approved/consumer-signup-approved.component';
import { ConsumerSignupRejectedComponent } from '../layout/consumer-signup-rejected/consumer-signup-rejected.component';

@Component({
	selector: 'app-consumer-signup-manager',
	templateUrl: './consumer-signup-manager.component.html',
	styleUrls: ['./consumer-signup-manager.component.scss']
})
export class ConsumerSignupManagerComponent implements OnInit {
	@ViewChild(ConsumerSignupApprovedComponent) reject: ConsumerSignupApprovedComponent;
	@ViewChild(ConsumerSignupRejectedComponent) approved: ConsumerSignupRejectedComponent;

	constructor() { }

	ngOnInit(): void { }

	ngAfterViewInit() {
		setTimeout(
			() =>
			(this.reloadChildFunction = () => {
				this.reject.reloadData();
				this.approved.reloadData();
			}),
			0
		);
	}

	reloadChildFunction() {
		this.reject.reloadData();
		this.approved.reloadData();
	}
}
