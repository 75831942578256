<div class="pt-2 pb-2">
    <div class="col-md-auto ml-auto pb-2">
        <button
            class="btn btn-outline-primary btn-light d-inline-flex mr-3"
            (click)="open(content)"
        >
            <span class="material-icons pr-2">clear</span>
            Từ chối
        </button>
        <button
            class="btn btn-outline-primary btn-light d-inline-flex mr-3"
            (click)="approveRegistration()"
        >
            <span class="material-icons pr-2">done_outline</span>
            Phê duyệt
        </button>
        <button class="btn btn-primary d-inline-flex" (click)="capture()">
            <span class="material-icons pr-2">videocam</span> Chụp màn hình
        </button>
    </div>
    <div id="meet"></div>

    <div class="mt-2">
        <h2 class="no-background">
            <span><b class="f-16">ẢNH CHỤP KHUÔN MẶT</b></span>
        </h2>
    </div>
    <div class="images mt-2">
        <div>
            <img src="{{ imageFace }}" width="150" alt="" />
        </div>
    </div>
    <div class="images mt-2">
        <div>
            <img src="{{ imageCapture }}" width="150" alt="" />
        </div>
    </div>
    <div class="mt-2">
        <h2 class="no-background">
            <span><b class="f-16">ẢNH CHỤP GIẤY TỜ</b></span>
        </h2>
    </div>
    <div class="images mt-2" *ngFor="let item of cardImages">
        <img src="{{ item }}" width="150" alt="" />
    </div>
</div>

<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Từ chối yêu cầu</h4>
        <button
            type="button"
            class="close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click')"
        >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="modal-body">
        <form [formGroup]="inputDenyReason">
            <div class="form-group">
                <label for="denyReason">Lý do từ chối</label>
                <textarea formControlName="denyReason" id="denyReason" #denyReason placeholder="Vui lòng nhập lý do từ chối phê duyệt" name="denyReason" rows="5"
                        class="form-control"></textarea>
                        <app-show-errors [control]="inputDenyReason.controls.denyReason"></app-show-errors>
                <!-- <div class="input-group"> -->
                    <!-- <input -->
                    <!--     formControlName="denyReason" -->
                    <!--     id="denyReason" -->
                    <!--     class="form-control" -->
                    <!--     type="text" -->
                    <!-- /> -->
                <!-- </div> -->
            </div>
        </form>
    </div>
    <!-- <div class="modal-body"> -->
    <!--     <form [formGroup]="inputDenyReason"> -->
    <!--         <div class="form-group"> -->
    <!--             <label for="denyReason">Lý do từ chối</label> -->
    <!--             <div class="input-group"> -->
    <!--             <textarea formControlName="denyReason" id="denyReason" #denyReason placeholder="Vui lòng nhập lý do từ chối phê duyệt" name="denyReason" rows="5" -->
    <!--                     class="form-control"></textarea> -->
    <!--                     <app-show-errors [control]="inputDenyReason.controls.denyReason"></app-show-errors> -->
    <!--                 <!-1- <input -1-> -->
    <!--                 <!-1-     formControlName="denyReason" -1-> -->
    <!--                 <!-1-     id="denyReason" -1-> -->
    <!--                 <!-1-     class="form-control" -1-> -->
    <!--                 <!-1-     type="text" -1-> -->
    <!--                 <!-1- /> -1-> -->
    <!--             </div> -->
    <!--         </div> -->
    <!--     </form> -->
    <!-- </div> -->
    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-outline-danger mr-2"
            (click)="modal.dismiss('Cross click')"
        >
            Huỷ
        </button>
        <button
            ngbAutofocus
            type="button"
            class="btn btn-primary"
            (click)="modal.close('Save click'); rejectRegistration()"
            [disabled]="inputDenyReason.invalid || inputDenyReason.pending"
        >
            Xác nhận
        </button>
    </div>
</ng-template>
