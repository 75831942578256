import { MainResponse } from '../../main-response.model';
import { TinhItem } from './tinh-item.model';

export class ListTinhResponse extends MainResponse {
    object: TinhItem[] = [];
     
    constructor(obj = null) {
        super();
        obj = obj || {};
        this.init(obj);
        this.object = [];
        if (obj.object && obj.object instanceof Array) {
            obj.object.forEach((item) => {
                this.object.push(new TinhItem(item));
            })
        }
    }
}