import { Injectable } from '@angular/core';
import { ServicePath } from '../../common/constant-service-api';
import { API } from '../api';

const LIST_PARTNER_VERIFICATION = ServicePath.MANAGEMENT_SERVICE + '/partner-verifications';
const DETAIL_CONSUMER_PARTNER = ServicePath.MANAGEMENT_SERVICE + '/partner-verifications/consumer';
const DETAIL_BUSINESS_PARTNER = ServicePath.MANAGEMENT_SERVICE + '/partner-verifications/business';
const LIST_TRUSTED_PARTNER = ServicePath.MANAGEMENT_SERVICE + '/trusted-partners';
const DETAIL_TRUSTED_CONSUMER = ServicePath.MANAGEMENT_SERVICE + '/trusted-partners/consumer';
const DETAIL_TRUSTED_BUSINESS = ServicePath.MANAGEMENT_SERVICE + '/trusted-partners/business';
const APPROVE_PARTNER = ServicePath.MANAGEMENT_SERVICE + '/partner-verifications'; //id/approve
const REJECT_PARTNER = ServicePath.MANAGEMENT_SERVICE + '/partner-verifications'; //id/reject
const DOWNLOAD_GPKD = ServicePath.MANAGEMENT_SERVICE + '/parties';
const DOWNLOAD_IMAGE = ServicePath.MANAGEMENT_SERVICE + '/user-informations';
const CREATE_BUSSINESS_TRUSTED_PARTNER = ServicePath.MANAGEMENT_SERVICE + '/trusted-partners/business/create';
const DETAILS_BUSSINESS_TRUSTED_PARTNER = ServicePath.MANAGEMENT_SERVICE + '/trusted-partners/v1/business';
const UPDATE_BUSINESS_TRUSTED_PARTNER = ServicePath.MANAGEMENT_SERVICE + '/trusted-partners/business';
const CHECK_TAX_NUMBER = ServicePath.MANAGEMENT_SERVICE + '/trusted-partners/business/check-ma-so-thue';
const CHECK_PHONE_NUMBER = ServicePath.MANAGEMENT_SERVICE  + '/trusted-partners/consumer/check-phone-number';
const RESEND_PARTNER_INFOR = ServicePath.MANAGEMENT_SERVICE + '/trusted-partners/v1/resend-invite';
const CUSTOMER_SEARCH = ServicePath.MANAGEMENT_SERVICE + '/parties/customer-search';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {
  public static instance: PartnerService;

  constructor(
    private api: API
  ) { 
    if (!PartnerService.instance) {
      PartnerService.instance = this;
    }
    return PartnerService.instance;
  }

  /**
   * Lay danh sach khach hang can xac minh, da xac mimh, da tu choi
   * @param verificationStatus 
   * @param searchString 
   * @param maxSize 
   * @param page 
   * @param userType 
   */
  getListPartnerVerification(verificationStatus, searchString = '', maxSize = 10, page = 10, userType = 'CONSUMER') {
    const url = LIST_PARTNER_VERIFICATION + `?verificationStatus=${verificationStatus}&searchString=${searchString}&maxSize=${maxSize}&page=${page}&userType=${userType}`;
    return this.api.get(url);
  }

  /**
   * Lay thong tin chi tiet doi tac ca nhan
   * @param id 
   */
  getDetailConsumerPartner(id) {
    const url = DETAIL_CONSUMER_PARTNER + `/${id}`;
    return this.api.get(url);
  }

  /**
   * Lay thong tin chi tiet doi tac to chuc
   * @param id 
   */
  getDetailBusinessPartner(id) {
    const url = DETAIL_BUSINESS_PARTNER + `/${id}`;
    return this.api.get(url);
  }

  /**
   * Xac nhan doi tac
   * @param id 
   */
  postApprovePartner(id) {
    const url = APPROVE_PARTNER + `/${id}/approve`;
    return this.api.post(url, null);
  }

  /**
   * Tu choi doi tac
   * @param id 
   */
  postRejectPartner(id, param) {
    const url = REJECT_PARTNER + `/${id}/reject`;
    return this.api.post(url, param);
  }

  /**
   * Lay danh sach khach hang tin cay
   * @param identityLevel 
   * @param searchString 
   * @param maxSize 
   * @param page 
   * @param userType 
   */
  getListTrustedPartner(identityLevel = 2, searchString = '', maxSize = 5, page = 1, userType = 'CONSUMER', sortInfo: string = "") {
    const url = LIST_TRUSTED_PARTNER + `?identityLevel=${identityLevel}&searchString=${searchString}&maxSize=${maxSize}&page=${page}&userType=${userType}&sortInfo=${sortInfo}`;
    return this.api.get(url);
  }

  /**
   * Lay thong tin doi tac tin cay la ca nhan
   * @param id 
   */
  getDetailTrustedConsumerPartner(id) {
    const url = DETAIL_TRUSTED_CONSUMER + `/${id}`;
    return this.api.get(url);
  }

  /**
   * Lay thong tin doi tac tin cay la to chuc
   * @param id 
   */
  getDetailTrustedBusinessPartner(id) {
    const url = DETAIL_TRUSTED_BUSINESS + `/${id}`;
    return this.api.get(url);
  }

  /**
   * Tai file GPKD
   * @param partyId 
   */
  downloadGPKD(partyId) {
    const url = DOWNLOAD_GPKD + `/${partyId}/download-gpkd`;
    return this.api.download(url);
  }

  /**
   * Tai hinh anh
   * @param id 
   * @param type 
   */
  downloadImage(id, type) {
    const url = DOWNLOAD_IMAGE + `/${id}/download-asset?assetType=${type}`;
    return this.api.download(url);
  }

  /**
   * Add new bussiness trusted partner
   * @param data 
   * @returns 
   */
  createBusinessTrustedPartner(data: FormData) {
    return this.api.postMultiPartJSON(CREATE_BUSSINESS_TRUSTED_PARTNER, data);
  }

  /**
   * Get details of business trusted partner
   * @param id 
   * @returns 
   */
  getDetailsOfBussinessTrustedPartner(id: string) {
    return this.api.get(DETAILS_BUSSINESS_TRUSTED_PARTNER + `/${id}`);
  }

  /**
   * Update business trusted partner
   * @param id 
   * @param data 
   * @returns 
   */
  updateBusinessTrustedPartner(id: string, data: FormData) {
    return this.api.putMultiPart(UPDATE_BUSINESS_TRUSTED_PARTNER + `/${id}`, data);
  }

  /**
   * Check if tax number already exists
   * @param taxNumber 
   * @returns 
   */
  checkTaxNumber(taxNumber: string) {
    return this.api.post(CHECK_TAX_NUMBER + `?maSoThue=${taxNumber}`, null);
  }

  /**
   * Check if phone number already exists
   * @param phoneNumber 
   * @returns 
   */
  checkPhoneNumber(phoneNumber: string) {
    return this.api.post(CHECK_PHONE_NUMBER + `?phoneNumber=${phoneNumber}`, null);
  }

  /**
   * Resend partner information
   * @param id 
   * @returns 
   */
  resendPartnerInfor(id: string) {
    return this.api.post(RESEND_PARTNER_INFOR + `/${id}`, '');
  }

  /**
   * Lay danh sach doi tac co the tham vao ben tham gia hop dong
   * @param string 
   * @returns 
   */
  getListPartner(string = '') {
    const url = CUSTOMER_SEARCH + `?string=${string}`;
    return this.api.get(url);
  }
}
