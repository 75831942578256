<div class="container-fluid">
    <div class="row">
        <div class="col d-flex align-items-center justify-content-between mb-3">
            <label>
                <h5 class="font-weight-bold m-0">
                    Hướng dẫn sử dụng
                </h5>
            </label>
            <div class="btn-group d-flex align-items-center">
                <button class="btn btn-prev rounded-circle d-flex" style="background-color: #CBD5E0;" (click)="prev(1)">
                    <span class="material-icons-outlined m-auto text-dark">
                        arrow_back_ios
                    </span>
                </button>
                <span class="page mx-3">
                    {{currentIdx }} / {{blogs.length}}
                </span>
                <button class="btn btn-next rounded-circle d-flex" style="background-color: #CBD5E0;" (click)="next(1)">
                    <span class="material-icons-outlined m-auto text-dark">
                        arrow_forward_ios
                    </span>
                </button>
            </div>
        </div>
    </div>
    <div class="row d-flex">
        <div class="col slide" style="z-index: 4; height: 161px;" *ngFor="let blog of blogs; first as isFirst"
            [class.show]="isFirst">
            <div class="p-3 rounded-lg position-relative bg-white" style="overflow: hidden">
                <div class="position-absolute rounded-circle"
                    style="height: 452px;width: 452px;top: -210%; left: -25%; z-index: 1; background-color: rgba(86, 125, 244, 0.16);">
                </div>
                <div class="position-absolute rounded-circle"
                    style="height: 189px;width: 189px;top: 70%; left: 85%; z-index: 1; background-color: rgba(245, 166, 35, 0.16);">
                </div>
                <div class="container-fluid ">
                    <div class="row position-relative d-flex align-items-center" style="z-index: 2">
                        <div class="col-md-3 p-0">
                            <img src="{{ blog?.avatar }}" class="rounded-lg float-left image" height="129px" width="100%"
                                style="overflow: hidden" />
                        </div>
                        <div class="col-md-9 pl-3 d-flex flex-column align-items-baseline" style="height: 129px">
                            <div>
                                <p class="text-dark font-weight-bold title">{{ blog?.title
                                    }}</p>
                                <p class="text-dark sub-title">
                                    {{ blog?.subTitle }}
                                </p>
                            </div>

                            <button class="btn btn-primary d-flex align-items-center btn-action mt-auto" (click)="openHDSD(blog.id)">
                                Xem hướng dẫn
                                <span class="material-icons-outlined ml-3">
                                    trending_flat
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
