export class PartnerBusiness {
  cmnd: string = '';
  created: string = '';
  email: string = '';
  gioiTinhId: any = '';
  gpkdFilename: string = '';
  identityLevel: number;
  maSoThue: string = '';
  ngayCap: string = '';
  ngayCapSoDkdn: string = '';
  ngaySinh: string = '';
  noiCap: string = '';
  noiCapSoDkdn: string = '';
  partnerId: string = '';
  partnerVerificationId: string = '';
  partyId: string = '';
  reason: string = '';
  sdt: string = '';
  soDkdn: string = '';
  ten: string = '';
  tenRutGon: string = '';
  tenToChuc: string = '';
  userId: string = '';
  userType: string = '';
  verificationDate: string = '';
  verificationStatus: string = '';
  verificationType: string = '';
  diaChi: string = '';
  customerId: string = '';
  verificationUser: string = '';
  username: string = '';

  fileName: string = '';
  title: string = '';
  contractId: string = '';
  currentStage: string = '';
  dkhktt: string = '';
  verificationUserTen: string = '';
 
  

  constructor(obj = null) {
    obj = obj || {};

    this.cmnd = obj.cmnd || '';
    this.created = obj.created || '';
    this.email = obj.email || '';
    this.gioiTinhId = obj.gioiTinhId;
    this.gpkdFilename = obj.gpkdFilename || '';
    this.identityLevel = obj.identityLevel;
    this.maSoThue = obj.maSoThue || '';
    this.ngayCap = obj.ngayCap || '';
    this.ngayCapSoDkdn = obj.ngayCapSoDkdn || '';
    this.ngaySinh = obj.ngaySinh || '';
    this.noiCap = obj.noiCap || '';
    this.noiCapSoDkdn = obj.noiCapSoDkdn || '';
    this.partnerId = obj.partnerId || '';
    this.partnerVerificationId = obj.partnerVerificationId || '';
    this.partyId = obj.partyId || '';
    this.reason = obj.reason || '';
    this.sdt = obj.sdt || '';
    this.soDkdn = obj.soDkdn || '';
    this.ten = obj.ten || '';
    this.tenRutGon = obj.tenRutGon || '';
    this.tenToChuc = obj.tenToChuc || '';
    this.userId = obj.userId || '';
    this.userType = obj.userType || '';
    this.verificationDate = obj.verificationDate || '';
    this.verificationStatus = obj.verificationStatus || '';
    this.verificationType = obj.verificationType || '';
    this.diaChi = obj.diaChi || '';
    this.customerId = obj.customerId || '';
    this.verificationUser = obj.verificationUser || '';
    this.username = obj.username || '';

    this.fileName = obj.fileName || '';
    this.title = obj.title || '';
    this.contractId = obj.contractId || '';
    this.currentStage = obj.currentStage || '';
    this.dkhktt = obj.dkhktt || '';
    this.verificationUserTen = obj.verificationUserTen || '';
  }
}