import { UserInformationsItem } from './user-info-item.model';
import { MainResponse } from '../main-response.model';

export class UserInformationsResponse extends MainResponse {
    object: UserInformationsItem;
    
    constructor(obj = null) {
        super();
        obj = obj || {};
        this.init(obj);
        this.object = new UserInformationsItem(obj.object || {});
    }
}
