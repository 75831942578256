export const SignFlowTypes = {
    REQUIRE_FLOW_STAMP: "REQUIRE_FLOW_STAMP",
    REQUIRE_FLOW: "REQUIRE_FLOW",
    NO_REQUIRE: "NO_REQUIRE"
}

export const ConstSignType = {
    DRAFT: "DRAFT",
    APPROVAL: "APPROVAL",
    STAMP: "STAMP"
}

export const InternalDiscussTypes = {
    REQUIRE_FLOW: "REQUIRE_FLOW",
    NO_REQUIRE_FLOW: "REQUIRE",
    NO_REQUIRE: "NO_REQUIRE"
}