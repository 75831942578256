import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { API } from './api';
import { ResponseHttp } from '../models/example/example-response.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppService {
  public user = {
    firstName: 'Alexander',
    lastName: 'Pierce',
    image: 'assets/img/user2-160x160.jpg'
  };

  constructor(private router: Router,
    private api: API) { }

  login() {
    localStorage.setItem('token', 'LOGGED_IN');
    this.router.navigate(['/']);
  }

  logout() {
    localStorage.removeItem('token');
    this.router.navigate(['/login']);
  }
}
