<div class="container-fluid">
    <div class="row">
        <div class="col-md-4">
            <div class="card shadow-none">
                <div class="card-body pb-0">
                    <div class="row pt-2 pb-2">
                        <div class="col-md-auto">
                            <label class="text-lg">{{ 'WaitPartyPartner_title' | translate }}</label>
                        </div>

                        <div class="input-group mt-3">
                            <form [formGroup]="searchForm" class="input-group"
                                (ngSubmit)="searchData(searchForm.value.searchInput)">
                                <div class="input-group-prepend">
                                    <button class="btn btn-primary d-inline-flex pr-2 pl-2" type="submit">
                                        <span class="material-icons">search</span>
                                    </button>
                                </div>
                                <input type="text" class="form-control" formControlName="searchInput" id="searchInput"
                                    placeholder="{{ 'WaitPartyPartner_search_placeholder' | translate }}" aria-label=""
                                    aria-describedby="basic-addon1" />
                            </form>
                        </div>
                    </div>
                </div>

                <div class="position-relative">
                    <ng-template #noData>
                        <app-empty-block></app-empty-block>
                    </ng-template>

                    <div>
                        <div class="user-group-item content-list-item px-0 pt-2">
                            <div *ngIf="partners.length > 0; else noData" class="content-list fade show">
                                <div *ngFor="let item of partners" (click)="selectPartner(item.partnerVerificationId)"
                                    class="user-group-item content-list-item px-4 pt-2"
                                    [class.active]="item.partnerVerificationId === partner.partnerVerificationId">
                                    <div class="border-bottom d-flex">
                                        <span class="material-icons icon-item pr-3">business</span>
                                        <div class="flex-grow-1 mb-2">
                                            <label class="title-item">{{ item.tenToChuc || ('ServiceDashboardEnduser_noData' | translate) }}</label>
                                            <div class="row-sub hide-text">
                                                <span *ngIf="item.maSoThue" class="badge badge-light-new p-2 mr-2">{{
                                                    item.maSoThue }} </span>
                                                <span class="badge badge-light-new p-2">{{ item.email }} </span>
                                            </div>
                                        </div>
                                        <!-- <a class="text-decoration-underline text-primary pl-4" style="cursor: pointer;" (click)="callVideo(item.userId)">Gọi video</a> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="totalElement > maxSize" class="pagination justify-content-end pages">
                <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="5" [rotate]="true"
                    [ellipses]="false" [pageSize]="maxSize" (pageChange)="loadPage($event)" [boundaryLinks]="true"
                    aria-label="Default pagination">
                    <ng-template ngbPaginationFirst>{{ 'text_first' | translate }}</ng-template>
                    <ng-template ngbPaginationLast>{{ 'text_last' | translate }}</ng-template>
                    <ng-template ngbPaginationPrevious>&laquo;</ng-template>
                    <ng-template ngbPaginationNext>&raquo;</ng-template>
                    <ng-template ngbPaginationNumber let-page>{{ page }}
                    </ng-template>
                </ngb-pagination>
            </div>
        </div>
        <div class="col-md-8">
            <div *ngIf="partnerVerificationId" class="card shadow-none">
                <div class="card-body position-relative">
                    <div class="row">
                        <div class="col-md-auto align-self-end">
                            <label class="text-lg">{{ 'WaitPartyPartner_partner_info' | translate }}</label>
                        </div>
                        <div *ngIf="partner.partnerVerificationId" class="col-md-auto ml-auto">
                            <button class="btn btn-outline-primary d-inline-flex mr-3" disabled
                                (click)="callVideo(partner.userId)" disabled>
                                <span class="material-icons pr-2">videocam</span>{{ 'btn_video' | translate }}
                            </button>
                            <button class="btn btn-outline-primary d-inline-flex mr-3"
                                (click)="openRejectPopup(rejectContent)">
                                <span class="material-icons pr-2">clear</span>
                                {{ 'btn_reject' | translate }}
                            </button>
                            <button class="btn btn-primary d-inline-flex"
                                (click)="approvePartner(partner.partnerVerificationId)">
                                <span class="material-icons pr-2">done</span>
                                {{ 'btn_approved' | translate }}
                            </button>

                        </div>
                    </div>
                    <hr class="my-3" />

                    <label for="" class="text-primary">{{ 'WaitPartyPartner_party_info' | translate }}</label>
                    <hr class="mt-0">
                    <div>
                        <div class="row">
                            <div class="col-md-2">
                                <label for="">{{ 'WaitPartyPartner_party_name' | translate}}</label>
                            </div>
                            <div class="col-md-10">
                                {{ partner?.tenToChuc }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2">
                                <label for="">{{ 'WaitPartyPartner_party_short_name' | translate}}</label>
                            </div>
                            <div class="col-md-10">
                                {{ partner?.tenRutGon }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2">
                                <label for="">{{ 'WaitPartyPartner_party_tax_code' | translate}}</label>
                            </div>
                            <div class="col-md-10">
                                {{ partner?.maSoThue }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2">
                                <label for="">{{ 'WaitPartyPartner_party_license' | translate}}</label>
                            </div>
                            <div class="col-md-10">
                                <a class="text-decoration-underline text-primary" style="cursor: pointer;"
                                    (click)="actDownloadGPKD(partner.partnerId)">
                                    {{partner?.gpkdFilename}}
                                </a>
                            </div>
                        </div>
                    </div>

                    <label for="" class="text-primary mt-3">{{ 'WaitPartyPartner_agent_info' | translate }}</label>
                    <hr class="mt-0">
                    <div>
                        <div class="row">
                            <div class="col-md-2">
                                <label for="">{{ 'WaitPartyPartner_agent_name' | translate}}</label>
                            </div>
                            <div class="col-md-10">
                                {{ partner?.ten }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2">
                                <label for="">{{ 'WaitPartyPartner_agent_gender' | translate}}</label>
                            </div>
                            <div class="col-md-10">
                                {{ (gender[partner?.gioiTinhId] | translate) || ''  }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2">
                                <label for="">{{ 'WaitPartyPartner_agent_birth' | translate}}</label>
                            </div>
                            <div class="col-md-10">
                                {{ partner?.ngaySinh | date: 'dd/MM/yyyy' }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2">
                                <label for="">{{ 'WaitPartyPartner_agent_place_register' | translate}}</label>
                            </div>
                            <div class="col-md-10">
                                {{ partner?.dkhktt }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2">
                                <label for="">{{ 'WaitPartyPartner_agent_phone_number' | translate}}</label>
                            </div>
                            <div class="col-md-10">
                                {{ partner?.sdt }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-2">
                                <label for="">{{ 'WaitPartyPartner_agent_email' | translate}}</label>
                            </div>
                            <div class="col-md-10">
                                {{ partner?.email }}
                            </div>
                        </div>
                    </div>

                    <label for="" class="text-primary mt-3">{{ 'WaitPartyPartner_account_info' | translate }}</label>
                    <hr class="mt-0">
                    <div class="row">
                        <div class="col-md-2">
                            <label for="">{{ 'WaitPartyPartner_account_name' | translate }}</label>
                        </div>
                        <div class="col-md-10">
                            {{ partner?.username }}
                        </div>
                    </div>

                    <label for="" class="text-primary mt-3">{{ 'WaitPartyPartner_contract_info' | translate }}</label>
                    <hr class="mt-0">
                    <div class="row">
                        <div class="col-md-2">
                            <label>{{ 'WaitPartyPartner_contract_name' | translate }}</label>
                        </div>
                        <div class="col-md-10">
                            {{ partner?.title }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-2">
                            <label>{{ 'WaitPartyPartner_contract_file' | translate }}</label>
                        </div>
                        <div class="col-md-10">
                            <!-- <span class="file-name" (click)="getFileContract(partner.contractId, partner.currentStage)">
                                {{ partner.fileName }}</span> -->
                            <span class="file-name" (click)="detailContract(partner.contractId)">
                                {{ partner.fileName }}</span>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="col-md-6">
                            <label class="text-primary">Thông tin đối tác</label>
                            <hr>
                            <div class="row">
                                <div class="col-md-4">
                                    <label for="">Tên doanh nghiệp</label>
                                </div>
                                <div class="col-md-8">
                                    {{ partner?.tenToChuc }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <p class="text-secondary">Tên rút gọn</p>
                                </div>
                                <div class="col-md-8">
                                    {{ partner?.tenRutGon }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <p class="text-secondary">Mã số thuế</p>
                                </div>
                                <div class="col-md-8">
                                    {{ partner?.maSoThue }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <p class="text-secondary">Giấy phép kinh doanh</p>
                                </div>
                                <div class="col-md-8">
                                    <a class="text-decoration-underline text-primary" style="cursor: pointer;" (click)="actDownloadGPKD(partner.partnerId)">
                                        {{partner?.gpkdFilename}}
                                    </a>
                                </div>
                            </div>

                            <label class="text-primary">Thông tin người đại diện</label>
                            <hr>
                            <div class="row">
                                <div class="col-md-4">
                                    <p class="text-secondary">Họ và tên</p>
                                </div>
                                <div class="col-md-8">
                                    {{ partner?.ten }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <p class="text-secondary">Email</p>
                                </div>
                                <div class="col-md-8">
                                    {{ partner?.email }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <p class="text-secondary">Số điện thoại</p>
                                </div>
                                <div class="col-md-8">
                                    {{ partner?.sdt }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <p class="text-secondary">Ngày sinh</p>
                                </div>
                                <div class="col-md-8">
                                    {{ partner?.ngaySinh | date: 'dd/MM/yyyy' }}
                                </div>
                            </div>
                            <div *ngIf="partner?.gioiTinhId" class="row">
                                <div class="col-md-4">
                                    <p class="text-secondary">Giới tính</p>
                                </div>
                                <div class="col-md-8">
                                    {{ gender[partner?.gioiTinhId] || '' }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <p class="text-secondary">Địa chỉ</p>
                                </div>
                                <div class="col-md-8">
                                    {{ partner?.diaChi || 'Không có thông tin'}}
                                </div>
                            </div>

                            <label class="text-primary">Thông tin tài khoản</label>
                            <hr>
                            <div class="row">
                                <div class="col-md-4">
                                    <p class="text-secondary">Tên tài khoản</p>
                                </div>
                                <div class="col-md-8">
                                    {{ partner?.username }}
                                </div>
                            </div>

                        </div>
                        <div class="col-md-6">
                                <p>Video đã ghi lại</p>
                                <div class="row">
                                    <div class="col-md-6">
                                        <video class="img-cmnd" controls>
                                            <source
                                                src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4"
                                                type="video/mp4">
                                            <source
                                                src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ForBiggerEscapes.mp4"
                                                type="video/ogg">
                                            Your browser does not support the video tag.
                                        </video>
                                    </div>
                                </div>
                            </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>

<app-preview-gpkd *ngIf="showPreviewGPKD" [file]="fileGPKD" [gpkdName]="partner.gpkdFilename"
    (close)="handleClosePreview()"></app-preview-gpkd>

<ng-template #rejectContent let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">{{ 'WaitPartyPartner_reject_title' | translate }}</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form [formGroup]="denyForm">
            <div class="form-group">
                <label for="denyReason">{{ 'WaitPartyPartner_reject_label' | translate }} <span class="text-danger">*</span></label>
                <textarea formControlName="denyReason" id="denyReason" #denyReason
                    placeholder="{{ 'WaitPartyPartner_reject_placeholder' | translate }}" name="denyReason" rows="5"
                    class="form-control"></textarea>
                <app-show-validate-errors [isSubmit]="isSubmitDenyForm" [errorMessages]="validateDenyMsg.denyReason"
                    [control]="denyForm.controls.denyReason" [detail]="{ name: 'denyReason' }">
                </app-show-validate-errors>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger mr-2" (click)="modal.dismiss('Cross click')">
            {{ 'btn_cancel' | translate }}
        </button>
        <button ngbAutofocus type="button" class="btn btn-primary"
            (click)="rejectRegistration(partner.partnerVerificationId)"
            [disabled]="denyReason.invalid || denyReason.pending">
            {{ 'btn_confirm' | translate }}
        </button>
    </div>
</ng-template>

<ng-template #calling let-modal>
    <div class="modal-body">
        <div class="row justify-content-center mb-3">
            <div class="">
                <div class="avater-call-modal" [ngStyle]="{backgroundImage: 'url(' + ava_img + ')'}"></div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mb-2 text-center">
                <h4><label>{{ partner.ten }}</label></h4>
            </div>
            <div class="col-12 mb-2 text-center">
                <span><i>{{ 'WaitPartyPartner_calling_act' | translate }}</i></span>
            </div>
            <div class="col-12 mb-2 text-center">
                <span class="text-danger">{{ 'WaitPartyPartner_calling_note' | translate }}</span>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="">
                <a class="end-call-modal" (click)="actBtnEndCall()">
                    <div class="ring">
                        <div class="vdc-alo-phone vdc-alo-green vdc-alo-show">
                            <div class="vdc-alo-ph-circle"></div>
                            <div class="vdc-alo-ph-circle-fill"></div>
                            <div class="vdc-alo-ph-img-circle"></div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</ng-template>

<!-- <ng-template #approve let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Phê duyệt yêu cầu</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <label for="denyReason">Bạn cần thực hiện cuộc gọi video trước khi phê duyệt yêu cầu
                đăng ký của khách hàng, vẫn tiến hành phê duyệt?</label
            >
        </div>
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="btn btn-outline-danger mr-2"
            (click)="modal.dismiss('Cross click')">Huỷ</button>
        <button
            ngbAutofocus
            type="button"
            class="btn btn-primary"
            (click)="modal.close('Save click'); approveRegistration(partner)">
            Phê duyệt
        </button>
    </div>
</ng-template> -->