export class BlogTypeItem{
  public id: number;
  public name: string;

  constructor(obj = null){
    obj = obj || {};
    this.id = obj.id || 0;
    this.name = obj.name || '';
  }

}