import { Injectable } from "@angular/core";
import { AasConsumerManagerService } from "src/app/utils/services/aas-network/aas-consumer-manager.service";
import { Constant } from "../../../utils/common/constant_videocall";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { NgModalComponent } from "src/app/layout/extensions/ng-modal/ng-modal.component";
import { Observable, from, EMPTY, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

export interface ConfirmOptions {
  message: string;
  title?: string;
  errorOnClose?: boolean;
}

@Injectable({
  providedIn: "root",
})
export class VideoCallFirebaseMassageService {

  modalRef: any;

  constructor(
    private aasConsumerManagerService: AasConsumerManagerService,
    private modalService: NgbModal
  ) {}

  message: any;
  window: any;

  acceptCall(data) {
    console.log("nhan cuoc goi");
    this.aasConsumerManagerService.acceptCall(data).subscribe(
      (res) => {
        console.log("accept", res);
      },
      (error) => {}
    );
  }

  rejectCall(data) {
    console.log("tu choi cuoc goi");
    this.aasConsumerManagerService.rejectCall(data).subscribe(
      (res) => {
        console.log("accept", res);
      },
      (error) => {}
    );
  }

  openWindowCall() {
    const dualScreenLeft =
      window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop =
      window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width;
    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - 980) / 2 / systemZoom + dualScreenLeft;
    const top = (height - 500) / 2 / systemZoom + dualScreenTop;

    console.log("openvideo call");
    this.window = window.open("call" ,
      "",
      `width=1280,height=600,top=${top},left=${left}`,
      true
    );
  }

  closeWindow() {
    this.window && this.window.close();
  }

  showFinishAlert() {
    this.alert("Cuộc gọi đã kết thúc", "error");
  }

  showTimeoutAlert() {
    this.alert("Phiên cuộc gọi hết hạn", "error");
  }

  alert(msg, type, callBack = () => {}) {
    this.modalRef = this.modalService.open(NgModalComponent);
    this.modalRef.componentInstance.message = msg;
    this.modalRef.componentInstance.typeAlert = type;
    this.modalRef.componentInstance.notification = true;
    this.modalRef.componentInstance.callback = callBack;
  }

  // Confirm modal
  modal(msg, callBack = () => {}, callBackHuy = () => {}) {
    this.modalRef = this.modalService.open(NgModalComponent);
    this.modalRef.componentInstance.message = msg;
    this.modalRef.componentInstance.callback = callBack;
    this.modalRef.componentInstance.callback1 = callBackHuy;
  }

  closeModel() {
    this.modalRef && this.modalRef.close();
  }
}
