import { Component, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/utils/services/aas-network/dashboard/dashboard.service';

@Component({
  selector: 'app-number-of-partners-admin',
  templateUrl: './number-of-partners-admin.component.html',
  styleUrls: ['./number-of-partners-admin.component.scss'],
})
export class NumberOfPartnersAdminComponent implements OnInit {
  constructor(private dashboardService: DashboardService) {}

  ngOnInit(): void {
    this.initData(new Date().getFullYear());
  }
  months = {
    jan: 'T1',
    feb: 'T2',
    mar: 'T3',
    apr: 'T4',
    may: 'T5',
    jun: 'T6',
    jul: 'T7',
    aug: 'T8',
    sep: 'T9',
    oct: 'T10',
    nov: 'T11',
    dec: 'T12',
  };
  type = 'LineChart';
  data = [
    // ["T2", 600, 400, 0],
    // ["T3", 800, 400, 200],
    // ["T4", 400, 500, 0],
    // ["T5", 500, 600, 20],
    // ["T6", 600, 400, 200],
    // ["T7", 400, 500, 0],
  ];
  options = {
    // hAxis: {
    //   title: 'Month'
    // },
    // vAxis: {
    //   title: 'Temperature'
    // },
    pointSize: 10,
    series: {
      0: { pointShape: 'circle', color: '#29D395' },
      // 1: { pointShape: 'square', color: '#F5A623' },
      // 2: { pointShape: 'triangle', color: '#567DF4' },
    },
    legend: 'none',
    chartArea: {
      left: 35,
      right: 10,
    },
  };
  width = 550;
  height = 400;
  totalOfEnterprise = 0;
  // totalOfBussiness = 0;

  dynamicResize = true;
  initData(year) {
    this.dashboardService.getContractStatSByPartner(year).subscribe(
      (res: any) => {
        if (res && res.object instanceof Array) {
          if (res.object.length == 0) {
            this.data = [
              ['T1', 0],
              ['T2', 0],
              ['T3', 0],
              ['T4', 0],
              ['T5', 0],
              ['T6', 0],
              ['T7', 0],
              ['T8', 0],
              ['T9', 0],
              ['T10', 0],
              ['T11', 0],
              ['T12', 0],
            ];
          } else {
            Object.keys(res.object[0]).forEach((key) => {
              if (res.object[0][key] && key != 'partyType') {
                this.totalOfEnterprise += res.object[0][key];
              }
            });
            this.data = Object.keys(res.object[0]).map((key) => [
              this.months[key],
              res.object[0][key] == null ? 0 : res.object[0][key],
            ]);
            this.data.shift();
          }
        }
      },
      (error) => {}
    );
  }
}
