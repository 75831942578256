import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { ControlAccount } from 'src/app/utils/common/control-account';
import { localize } from 'src/app/utils/localize/localize';
import { FlowContractService } from 'src/app/utils/services/aas-network/flow-contract/flow-contract.service';

@Component({
  selector: 'app-contract-flow-list',
  templateUrl: './contract-flow-list.component.html',
  styleUrls: ['./contract-flow-list.component.scss']
})
export class ContractFlowListComponent implements OnInit {

  statusList = [
    { value: "", label: "status_all"},
    { value: 0, label: "status_running"},
    { value: 1, label: "status_stopped"}
  ]
  formFilter: FormGroup;
  keySearch: string = "";

  page = 1;
  maxSize = 10;
  totalElement = 0;
  propertiesSort = "created";
  sort = "DESC";

  sortColConfig = {
    name: null,
    description: null,
    status: null
  }

  flows = [];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    public controlAcc: ControlAccount,
    private flowContractService: FlowContractService,
    private myAlert: AlertControl,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.makeForm();
    this.loadPage(1);
  }

  makeForm() {
    this.formFilter = this.fb.group({
      status: ['']
    })
  }

  enterSearch() {
    this.loadPage(1);
  }

  cleanFilter() {
    this.formFilter.reset();
    this.formFilter.controls.status.setValue("");
    this.keySearch = "";
    this.loadPage(1);
  }

  searchWithFilter() {
    this.loadPage(1);
  }

  addFlow() {
    this.router.navigate([ConstantUrl.contract_flow_add]);
  }

  loadPage(page) {
    this.page = page;
    this.getListFlowContract(this.page, this.maxSize, this.propertiesSort, this.sort, this.formFilter.value.status, this.keySearch);
  }

  sortCol(key) {
    if (this.sortColConfig[key] == null) {
      this.sortColConfig[key] = true;
    } else if (this.sortColConfig[key] == false) {
      this.sortColConfig[key] = null;
    } else {
      this.sortColConfig[key] = !this.sortColConfig[key];
    }
    // this.getListTemplateV2(this.formFilter.value.userId || "", this.auth.partyId, this.page, this.maxSize, this.getPropertiesSort(), this.keySearch, this.formFilter.value.status);
  }

  getPropertiesSort() {
    let arr = [];
    Object.keys(this.sortColConfig).forEach((item: string) => {
      let p = this.sortColConfig[item];
      let a = "";
      console.log(item);
      if (p == true) a = `${item}`;
      if (p == false) a = `-${item}`;
      if (p == null) a = "";
      if (p != null) {
        arr.push(a);
      }
    });
    return arr;
  }

  getListFlowContract(page = 1, maxSize = 10, propertiesSort = "created", sort = "DESC", status = "", keySearch = "") {
    this.flowContractService.getListFlowContract(page, maxSize, propertiesSort, sort, status, keySearch).subscribe((res: any) => {
      console.log(res);
      this.flows = res.object?.data || [];
      this.totalElement = res.object?.totalElement || 0;
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }

  detail(id) {
    this.router.navigate([ConstantUrl.contract_flow_detail, id]);
  }

  delete(item) {
    if (item.disable === false) {
      this.myAlert.showAlertOnlyNoti(this.translate.instant('ContractFlowList_cannot_delete_contract_flow_active'), ConstantAlertType.ERROR);
      return;
    }

    this.myAlert.showAlert(this.translate.instant('ContractFlowList_delete_contract_flow_confirm'), ConstantAlertType.BLANK, true, "", this.translate.instant('btn_delete'), () => {
      this.postDelete(item.contractFlowTemplateId);
    }, "", this.translate.instant('btn_close'));
  }

  edit(id) {
    this.router.navigate([ConstantUrl.contract_flow_edit, id]);
  }

  postDelete(id) {
    this.flowContractService.postDelete(id).subscribe((res: any) => {
      this.myAlert.showAlertOnlyNoti(this.translate.instant('ContractFlowList_delele_contract_flow_success'), ConstantAlertType.SUCCESS);
      this.loadPage(this.page);
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }
}
