import { Component, OnInit, ViewChild } from '@angular/core';
import { ApprovedPartyPartnerComponent } from './approved-party-partner/approved-party-partner.component';
import { RejectedPartyPartnerComponent } from './rejected-party-partner/rejected-party-partner.component';

@Component({
  selector: 'app-party-partner-verification',
  templateUrl: './party-partner-verification.component.html',
  styleUrls: ['./party-partner-verification.component.scss']
})
export class PartyPartnerVerificationComponent implements OnInit {
  @ViewChild(ApprovedPartyPartnerComponent) approvedParty: ApprovedPartyPartnerComponent;
  @ViewChild(RejectedPartyPartnerComponent) rejectParty: RejectedPartyPartnerComponent;
  
  constructor() { }

  ngOnInit(): void {
  }

  handleReloadApprove(e) {
    this.approvedParty.reload();
  }

  handleReloadReject(e) {
    this.rejectParty.reload();
  }

}
