import { Component, OnInit } from '@angular/core';
import { TemplateService } from 'src/app/utils/services/aas-network/template.service';

@Component({
  selector: 'app-dieu-khoan',
  templateUrl: './dieu-khoan.component.html',
  styleUrls: ['./dieu-khoan.component.scss']
})
export class DieuKhoanComponent implements OnInit {

  dieuKhoan: string = "";
  constructor(
    private templateSv: TemplateService
  ) { }

  ngOnInit(): void {
    this.getDieuKhoan();
  }

  getDieuKhoan() {
    this.templateSv.getDieuKhoan().subscribe((res: any) => {
      this.dieuKhoan = res.object?.content;
      console.log(res.object?.content)
    }, err => {
      console.log(err);
    })
  }
}
