<div class="row" [formGroup]="formSubmit" [hidden]="isUpdateEmailSDT">
    <div class="content-header col-md-12 d-flex justify-content-between">
        <div class="mb-2">
            <div class="col-md-auto">
                <h1 class="m-0 text-dark"><label>{{ 'ServiceProfileManager_title1' | translate }}</label></h1>
            </div>
        </div>
        <div class="d-flex align-items-center mr-4">
            <button *ngIf="isEdit == false && userInfo.identityLevel <= 1" (click)="updateVerifyAccount()" class="btn btn-primary d-inline-flex "><span class="material-icons mr-1">how_to_reg</span> Nâng cấp định danh</button>

            <button *ngIf="isEdit == false" (click)="isEdit = true" class="btn btn-outline-primary d-inline-flex ml-3"><span
                class="material-icons mr-1">edit</span> {{ 'btn_edit' | translate }}</button>

            <button *ngIf="isEdit == true" (click)="isEdit = false;actBtnCancel($event)" class="btn btn-outline-primary btn-light d-inline-flex mr-4"><span
                class="material-icons">clear</span> {{ 'btn_cancel' | translate }}</button>
            <button *ngIf="isEdit == true" (click)="submit($event)" class="btn btn-primary d-inline-flex"><span
                    class="material-icons mr-1">save</span> {{ 'btn_save' | translate }}</button>
        </div>
        <!-- /.container-fluid -->
    </div>
    <div class="content w-100">
        <div class="card mr-4">
            <div *ngIf="!isEdit" class="card-body row">
                <div class="col-md-3">
                    <label class="text-primary">Ảnh đại diện</label>
                    <hr class="my-0">
                    <div>
                        <img class="img-circle d-block m-auto" src="assets/img/icon-avatar-default.png" width="150" height="150" />
                    </div>
                </div>
                <div class="col-md-9">
                    <!-- Thông tin tài khoản -->
                    <label class="text-primary">{{ 'ProfileBusiness_account' | translate }}</label>
                    <hr class="mt-0">
                    <div class="row">
                        <div class="col-md-3"><label>Tên tài khoản</label></div>
                        <div class="col-md-9"> {{ username.value }}</div>
                    </div>
                    <div class="row">
                        <div class="col-md-3"><label>Loại tài khoản</label></div>
                        <div class="col-md-9"> {{ 'ServiceProfileManager_title3' | translate }} {{ userInfo.identityLevel }} </div>
                    </div>
                    
                    <!-- Thông tin cá nhân -->
                    <label class="text-primary mt-3">Thông tin cá nhân</label>
                    <hr class="mt-0">
                    <!-- Hiển thị thông tin KHCN định danh 0, 1 -->
                    <div *ngIf="userInfo.identityLevel == 0 || userInfo.identityLevel == 1">
                        <div class="row">
                            <div class="col-md-3"><label>Email</label><span class="text-danger">*</span>:</div>
                            <div class="col-md-9 d-flex justify-content-between"> 
                                <div>{{ email.value }}</div>
                                <a *ngIf="partyInfo.isActiveUpdateEmail" (click)="updateEmailSDT('email')" class="text-primary text-decoration-underline cursor-pointer mr-4">Cập nhật</a>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3"><label>Số điện thoại</label><span class="text-danger">*</span>:</div>
                            <div class="col-md-9 d-flex justify-content-between"> 
                                <div>{{ phone.value }}</div>
                                <a *ngIf="partyInfo.isActiveUpdatePhoneNumber" (click)="updateEmailSDT('sdt')" class="text-primary text-decoration-underline cursor-pointer mr-4">Cập nhật</a>
                            </div>
                        </div>
                    </div>
                    
                    <!-- Hiển thị thông tin KHCN định danh 2 -->
                    <div *ngIf="userInfo.identityLevel == 2">
                        <div class="row">
                            <div class="col-md-3"><label>Họ và tên</label></div>
                            <div class="col-md-9"> {{ fullname.value }}</div>
                        </div>
                        <div class="row">
                            <div class="col-md-3"><label>Số giấy tờ</label></div>
                            <div class="col-md-9"> {{ soGiayTo.value }}</div>
                        </div>
                        <div class="row">
                            <div class="col-md-3"><label>Ngày sinh</label></div>
                            <div class="col-md-9"> {{ dateOfBirth.value }}</div>
                        </div>
                        <div class="row">
                            <div class="col-md-3"><label>Nơi sinh</label></div>
                            <div class="col-md-9"> {{ soGiayTo.value }}</div>
                        </div>
                        <div class="row">
                            <div class="col-md-3"><label>Giới tính</label></div>
                            <div class="col-md-9"> 
                                <span *ngIf="gender.value == 2">{{ 'WaitPartyPartner_agent_gender_2' | translate }}</span>
                                <span *ngIf="gender.value == 1">{{ 'WaitPartyPartner_agent_gender_1' | translate }}</span>
                                <span *ngIf="gender.value == 3">{{ 'ProfileConsumer_label_7' | translate }}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3"><label>Nơi đăng ký HKTT</label></div>
                            <div class="col-md-9"> {{ noiDKHKTT.value }}</div>
                        </div>
                        <div class="row">
                            <div class="col-md-3"><label>Ngày cấp</label></div>
                            <div class="col-md-9"> {{ ngayCap.value }}</div>
                        </div>
                        <div class="row">
                            <div class="col-md-3"><label>Nơi cấp</label></div>
                            <div class="col-md-9"> {{ noiCap.value }}</div>
                        </div>
                        <div class="row">
                            <div class="col-md-3"><label>Có giá trị đến</label></div>
                            <div class="col-md-9"> </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3"><label>Email</label></div>
                            <div class="col-md-9 d-flex justify-content-between"> 
                                <div>{{ email.value }}</div>
                                <a *ngIf="partyInfo.isActiveUpdateEmail" (click)="updateEmailSDT('email')" class="text-primary text-decoration-underline cursor-pointer mr-4">Cập nhật</a>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3"><label>Số điện thoại</label></div>
                            <div class="col-md-9 d-flex justify-content-between"> 
                                <div>{{ phone.value }}</div>
                                <a *ngIf="partyInfo.isActiveUpdatePhoneNumber" (click)="updateEmailSDT('sdt')" class="text-primary text-decoration-underline cursor-pointer mr-4">Cập nhật</a>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-3"><label>Địa chỉ</label></div>
                            <div class="col-md-9"> {{ userInfo.diaChi }} </div>
                        </div>
                    </div>
                </div>
            </div>
            

            <div [hidden]="!isEdit" class="card-body row" >
                <!-- Ảnh đại diện -->
                <div class="col-md-12">
                    <label class="text-primary">Ảnh đại diện</label>
                    <hr class="my-0">
                    <div class="row">
                        <div>
                            <img class="img-circle" src="assets/img/icon-avatar-default.png" width="150" height="150" />
                        </div>
                        <div class="d-flex flex-column justify-content-center">
                            <div>
                                <button class="btn btn-outline-primary d-inline-flex ml-3"><span
                                    class="material-icons mr-1">file_upload</span> Tải ảnh lên
                                </button>
                            </div>
                            <div class="mt-3 ml-3">
                                <p class="font-italic text-grey-darkest">File đính kèm phải có định dạng .jpeg hoặc .png và dung lượng không vượt quá 5MB</p>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Thông tin tài khoản -->
                <div class="col-md-12">
                    <label class="text-primary">{{ 'ProfileBusiness_account' | translate }}</label>
                    <hr class="mt-0">
                    <div class="row" >
                        <div class="col-md-6" [formGroup]="formSubmit">
                            <div class="form-group">
                                <label>{{ 'EmployeeInPart_username' | translate }} <span class="text-danger">*</span>:</label>
                                <input class="form-control" formControlName="username" placeholder="{{ 'ProfileBusiness_account_input_name' | translate }}">
                                <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.username"
                                    [control]="username" [detail]="{ name: 'username' }"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Loại tài khoản :</label>
                                <input class="form-control" value="{{ 'ServiceProfileManager_title3' | translate }} {{ userInfo.identityLevel }}" disabled>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Thông tin cá nhân cho cấp 1 chưa định danh-->
                <div class="col-md-12" *ngIf="userInfo.identityLevel == 0">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group" [formGroup]="formSubmit">
                                <label>{{ 'SignUpConsumer_input_email' | translate }} <span class="text-danger">*</span>:</label>
                                <div class="input-group">
                                    <input disabled class="form-control"
                                    formControlName="email" placeholder="{{ 'EmployeeAdd_ph_2' | translate }}">
                                    <div class="input-group-append" *ngIf="partyInfo.isActiveUpdateEmail">
                                        <button (click)="updateEmailSDT('email')" class="btn btn-outline-primary d-inline-flex ml-3" style="border-radius: 4px !important;"><span
                                            class="material-icons mr-1">edit</span> Cập nhật</button>
                                    </div>
                                </div>
                                <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.email"
                                    [control]="email" [detail]="{ name: 'email' }"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group" [formGroup]="formSubmit">
                                <label>Số điện thoại <span class="text-danger">*</span>:</label>
                                <div class="input-group">
                                    <input disabled class="form-control"
                                    formControlName="phone" placeholder="{{ 'EmployeeAdd_ph_3' | translate }}">
                                    <div class="input-group-append" *ngIf="partyInfo.isActiveUpdatePhoneNumber">
                                        <button (click)="updateEmailSDT('sdt')" class="btn btn-outline-primary d-inline-flex ml-3" style="border-radius: 4px !important;"><span
                                            class="material-icons mr-1">edit</span> Cập nhật</button>
                                    </div>
                                </div>
                                <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.phone"
                                    [control]="phone" [detail]="{ name: 'phone' }"></app-show-validate-errors>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Thông tin cá nhân cho cấp 1, 2-->
                <div class="col-md-12" *ngIf="userInfo.identityLevel == 1 || userInfo.identityLevel == 2">
                    <label class="text-primary">{{ 'ProfileConsumer_label_1' | translate }}</label>
                    <hr class="mt-0">
                    <div class="row" [formGroup]="formIdentity">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ 'full_name' | translate }} :</label>
                                <input [attr.disabled]="!isIdentityAgain ? '': null" class="form-control" formControlName="fullname"
                                    placeholder="{{ 'ProfileBusiness_agent_input_name' | translate }}">
                                <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.fullname"
                                    [control]="fullname" [detail]="{ name: 'fullname' }"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ 'ProfileConsumer_label_3' | translate }} :</label>
                                <input [attr.disabled]="!isIdentityAgain ? '': null" class="form-control"
                                    formControlName="soGiayTo" placeholder="{{ 'ProfileConsumer_label_4' | translate }}">
                                <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.soGiayTo"
                                    [control]="soGiayTo" [detail]="{ name: 'soGiayTo' }"></app-show-validate-errors>
                            </div>
                        </div>
                    </div>

                    <div class="row" [formGroup]="formIdentity">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ 'ProfileBusiness_agent_birth' | translate }} :</label>
                                <input disabled (input)="dateInput($event, dateOfBirth)"
                                    type="text" formControlName="dateOfBirth" class="form-control date" placeholder="dd/MM/yyyy"
                                    id="dateOfBirth">
                                <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.dateOfBirth"
                                    [control]="dateOfBirth" [detail]="{ name: 'dateOfBirth' }"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label> Nơi sinh:</label>
                                <input disabled (input)="dateInput($event, dateOfBirth)"
                                    type="text" formControlName="dateOfBirth" class="form-control date" placeholder="dd/MM/yyyy"
                                    id="dateOfBirth">
                                <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.dateOfBirth"
                                    [control]="dateOfBirth" [detail]="{ name: 'dateOfBirth' }"></app-show-validate-errors>
                            </div>
                        </div>
                    </div>

                    <div class="row" [formGroup]="formIdentity">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ 'ProfileBusiness_agent_gender' | translate }} :</label>
                                <input disabled class="form-control" disabled
                                    [value]="userInfo.gioiTinh" placeholder="{{ 'EmployeeAdd_ph_2' | translate }}">
                                <!-- <ng-select 
                                [(ngModel)]="gender" placeholder="{{ 'ProfileBusiness_agent_input_gender' | translate }}">
                                    <ng-option value="2">{{ 'WaitPartyPartner_agent_gender_2' | translate }}</ng-option>
                                    <ng-option value="1">{{ 'WaitPartyPartner_agent_gender_1' | translate }}</ng-option>
                                    <ng-option value="3">{{ 'ProfileConsumer_label_7' | translate }}</ng-option>
                                </ng-select> -->
                                <!-- <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.gender"
                                    [control]="gender" [detail]="{ name: 'gender' }"></app-show-validate-errors> -->
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ 'ProfileConsumer_label_2' | translate }} :</label>
                                <input disabled class="form-control" formControlName="noiDKHKTT"
                                    placeholder="{{ 'ProfileConsumer_label_6' | translate }}">
                                <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.noiDKHKTT"
                                    [control]="noiDKHKTT" [detail]="{ name: 'noiDKHKTT' }"></app-show-validate-errors>
                            </div>
                        </div>
                    </div>

                    <div class="row" [formGroup]="formIdentity">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ 'WaitConsumerPartner_date_range' | translate }} :</label>
                                <input disabled (input)="dateInput($event, ngayCap)"
                                    type="text" formControlName="ngayCap" class="form-control date" placeholder="dd/MM/yyyy"
                                    id="ngayCap">
                                <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.ngayCap"
                                    [control]="ngayCap" [detail]="{ name: 'ngayCap' }"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ 'WaitConsumerPartner_issue' | translate }} :</label>
                                <input disabled class="form-control"
                                    formControlName="noiCap" placeholder="{{ 'ProfileConsumer_label_5' | translate }}">
                                <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.noiCap"
                                    [control]="noiCap" [detail]="{ name: 'noiCap' }"></app-show-validate-errors>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Có giá trị đến</label>
                                <input disabled class="form-control" disabled value="">
                                <!-- <input disabled (input)="dateInput($event, ngayCap)"
                                    type="text" formControlName="ngayCap" class="form-control date" placeholder="dd/MM/yyyy"
                                    id="ngayCap">
                                <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.ngayCap"
                                    [control]="ngayCap" [detail]="{ name: 'ngayCap' }"></app-show-validate-errors> -->
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group" [formGroup]="formSubmit">
                                <label>{{ 'SignUpConsumer_input_email' | translate }} <span class="text-danger">*</span>:</label>
                                <div class="input-group">
                                    <input disabled class="form-control"
                                    formControlName="email" placeholder="{{ 'EmployeeAdd_ph_2' | translate }}">
                                    <div class="input-group-append" *ngIf="partyInfo.isActiveUpdateEmail">
                                        <button (click)="updateEmailSDT('email')" class="btn btn-outline-primary d-inline-flex ml-3" style="border-radius: 4px !important;"><span
                                            class="material-icons mr-1">edit</span> Cập nhật</button>
                                    </div>
                                </div>
                                <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.email"
                                    [control]="email" [detail]="{ name: 'email' }"></app-show-validate-errors>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group" [formGroup]="formSubmit">
                                <label>Số điện thoại <span class="text-danger">*</span>:</label>
                                <div class="input-group">
                                    <input disabled class="form-control"
                                    formControlName="phone" placeholder="{{ 'EmployeeAdd_ph_3' | translate }}">
                                    <div class="input-group-append" *ngIf="partyInfo.isActiveUpdatePhoneNumber">
                                        <button (click)="updateEmailSDT('sdt')" class="btn btn-outline-primary d-inline-flex ml-3" style="border-radius: 4px !important;"><span
                                            class="material-icons mr-1">edit</span> Cập nhật</button>
                                    </div>
                                </div>
                                <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.phone"
                                    [control]="phone" [detail]="{ name: 'phone' }"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Địa chỉ :</label>
                                <input disabled class="form-control" [value]="userInfo.diaChi" >
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>

        </div>

    </div>
</div>

<app-change-email-sdt 
    *ngIf="isUpdateEmailSDT"
    [oldValue]="oldValue" 
    [updateForm]="updateForm"
    (onActionCancel)="actionCancel()"
    (confirmOTPSuccess)="actionSuccess()"
    >
</app-change-email-sdt>
<!-- <app-mini-spinner *ngIf="loadingService.isVisible() | async"></app-mini-spinner> -->