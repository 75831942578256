import {DetailInfoItem} from "./detail-info-item.model"
import { MainResponse } from '../../../../main-response.model';

export class DetailItemResponse extends MainResponse {
    
    constructor(obj = null) {
        super();
        obj = obj || {};
        this.init(obj);
        if (obj.object && obj.object instanceof Array) {
            obj.object.forEach((item) => {
                this.object = new DetailInfoItem(item);

            })
        }
    }
}