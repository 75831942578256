import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgModalComponent } from 'src/app/layout/extensions/ng-modal/ng-modal.component';
import { UsbTokenCertItem } from 'src/app/utils/models/signature/user-info/usb-token-cert-item.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { UsbTokenCertResponse } from 'src/app/utils/models/signature/user-info/usb-token-cert-item-response.model';
import { AuthService } from 'src/app/utils/services/auth.service';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { SignatureService } from 'src/app/utils/services/aas-network/signature.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { share } from 'rxjs/operators';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { CertificateItem, InfoCASignServer } from 'src/app/utils/models/aas-models/signature/info-ca-signserver/info-ca-signserver-object';
import { InfoCASignServerResponse } from 'src/app/utils/models/aas-models/signature/info-ca-signserver/info-ca-signserver-response';
import { GlobalConfig } from 'src/app/utils/common/global-config/global-config';
import { InfoSignatureConfigObject } from 'src/app/utils/models/aas-models/signature/signature-config/info-signature-config-object';
import { InfoSignatureConfigResponse } from 'src/app/utils/models/aas-models/signature/signature-config/info-signature-config-response';
import { AasValidateMessage } from 'src/app/utils/common/validate-msg-aas';
import { REGEXP_EMAIL } from 'src/app/utils/common/regexp';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { TranslateService } from '@ngx-translate/core';

declare var vnpt_plugin: any;
declare var default_img: any;
@Component({
  selector: 'app-sign-server-manager',
  templateUrl: './sign-server-manager.component.html',
  styleUrls: ['./sign-server-manager.component.scss']
})
export class SignServerManagerComponent implements OnInit {
  @ViewChild('contractLoginSignServer') contractLoginSignServer: ElementRef;
  
  _default_img = default_img;

  spinnerR = new BehaviorSubject<boolean>(false);
  spinnerL = new BehaviorSubject<boolean>(false);
  spinnerLg = new BehaviorSubject<boolean>(false);

  userId = this.authService.userId;
  // listUsbTokenCertificate: UsbTokenCertItem[] = [];

  infoCA: InfoCASignServer = new InfoCASignServer();
  infoCABase: InfoCASignServer = new InfoCASignServer();
  listCABase: Array<CertificateItem> = [];

  selectedCert: CertificateItem = new CertificateItem();
  isFilter: boolean = false;
  configCA: InfoSignatureConfigObject = new InfoSignatureConfigObject();

  //Edit config
  editSignatureConfig: boolean = false;
  editIdSignaTure: string = '';

  totalElement: number = 0;
  maxSize: number = 5;
  page: number = 1;

  formSearch: FormGroup;
  isfilter: boolean = false;

  //Modal
  ngModalLoginSignServer: any;
  formLoginSignServer: FormGroup;
  validateMsgLoginSignServer = AasValidateMessage.LOGIN_SIGN_SERVER;
  isSubmitLoginSignServer: boolean = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private aasAuth: AasAuthService,
    private signatureService: SignatureService,
    private modalService: NgbModal,
    private alert: AlertControl,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.createFormLoginSignServer();
    this.makeFormSearch();
    this.spinnerLg.next(true);
    this.getInfoCASignServer(this.userId);
  }

  //MARK: Spinner
  spinnerRShow(): Observable<boolean> {
    return this.spinnerR.asObservable().pipe(share());
  }

  spinnerLShow(): Observable<boolean> {
    return this.spinnerL.asObservable().pipe(share());
  }

  spinnerLgShow(): Observable<boolean> {
    return this.spinnerLg.asObservable().pipe(share());
  }

  //MARK: Make form
  makeFormSearch() {
    this.formSearch = this.fb.group({
      search: new FormControl('')
    });
  }

  get keySearch() { return this.formSearch.get('search'); }

  //MARK: Action Search
  eventInputSearch(event) {
    let key = event.target.value || '';
    this.isfilter = true;
    this.infoCA.certificateList = this.listCABase.filter(x => {
      return x.accountEmail.toUpperCase().match(new RegExp(key.toUpperCase(), "g"))
    })
  }

  searchData(keySearch: string) {
    let key = keySearch;
    this.isfilter = true;
    this.infoCA.certificateList = this.listCABase.filter(x => {
      return x.accountEmail.toUpperCase().match(new RegExp(key.toUpperCase(), "g"))
    })
  }

  loadPage(page) {

  }

  //MARK: ĐĂNG NHẬP VNPT CA
  addCASignServer() {
    // var url = `${GlobalConfig.VNPT_CA_LOGIN_URL}${location.origin}/app/console/signature-manager/sign-server-login&scope`;
    // // https://hopdong-dev.vnptit3.vn/portal/signature/login?code=78adb71d33834dce8505ca2e90d895108fd679d02635479ab541c6b0c132a844  
    // //http://localhost:4200/app/console/signature-manager/sign-server-login?code=78adb71d33834dce8505ca2e90d895108fd679d02635479ab541c6b0c132a844 
    // var anchor = document.createElement("a");
    // anchor.href = url;
    // anchor.setAttribute('target', '_blank');
    // anchor.click();

    this.createModalLoginSignServer();
  }

  /**
   * Khởi tạo form đăng nhập sign-server
   */
  createFormLoginSignServer() {
    this.formLoginSignServer = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.pattern(REGEXP_EMAIL)]),
      password: new FormControl('', [Validators.required])
    })
  }

  get email() { return this.formLoginSignServer.controls.email; } 
  get password() { return this.formLoginSignServer.controls.password; }

  /**
   * Tạo modal form login sign-server
   */
  createModalLoginSignServer() {
    this.isSubmitLoginSignServer = false;
    this.ngModalLoginSignServer = this.modalService.open(this.contractLoginSignServer, { backdrop: 'static' });
  }

  /**
   * Đóng modal form login sign-server
   */
  closeModalLoginSignServer() {
    this.ngModalLoginSignServer && this.ngModalLoginSignServer.close();
  }

  /**
   * Submit form login sign server
   */
  submitLoginSignServer() {
    this.isSubmitLoginSignServer = true;
    if (this.formLoginSignServer.valid) {
      this.closeModalLoginSignServer();
      const param = {
        username: this.email.value,
        password: this.password.value
      };

      this.postLoginSignServer(param);
    }
  }

  //MARK: Xem thông tin CA
  selectCert(item: CertificateItem) {
    this.resetEditForm();

    this.selectedCert = item;
    this.getInfoSignatureConfig(item.id);
  }

  // MARK đăng xuất phiên chữ kí  
  logoutSignServer() {
    this.alert.showAlert(this.translate.instant('SmartcaManager_ts_4'), '', true, '', this.translate.instant('btn_confirm'), () => {
      this.putLogoutSignServer(this.userId);
    }, '', this.translate.instant('btn_cancel'), () => { });
  }

  //MARK: Chức năg cập nhật cấu hình chữ ký số
  resetEditForm() {
    this.editSignatureConfig = false;
    this.editIdSignaTure = '';
  }

  actEditSignatureConfig(certId: string) {
    if (certId !== '') {
      this.resetEditForm();

      this.editSignatureConfig = true;
      this.editIdSignaTure = certId;
    } else {

    }
  }

  actCancelUpdate($event) {
    this.resetEditForm();
  }

  //MARK: Chức năng đặt chứng thư số mặc định
  setDefault(certId) {
    this.alert.showAlert(this.translate.instant('SignServerManager_ts_4'), '', true, '', this.translate.instant('btn_confirm'), () => {
      this.postSetDefaultSignature(this.userId, certId);
    });
  }

  // //MARK: Popup thông báo
  // alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
  //   const modalRef = this.modalService.open(AlertComponent);
  //   modalRef.componentInstance.message = msg;
  //   modalRef.componentInstance.typeAlert = type;
  //   modalRef.componentInstance.twoBtn = twoBtn;
  //   modalRef.componentInstance.iconBtn1 = iconBtn1;
  //   modalRef.componentInstance.iconBtn2 = iconBtn2;
  //   modalRef.componentInstance.titleButton1 = titleBtn1;
  //   modalRef.componentInstance.titleButton2 = titleBtn2;
  //   modalRef.componentInstance.callback1 = actBtn1;
  //   modalRef.componentInstance.callback2 = actBtn2;
  // }

  // //MARK: Handle Error
  // handleError(err, errorMsg) {
  //   if (err.error && err.error.message && errorMsg[err.error.message]) {
  //     this.alert(errorMsg[err.error.message], 'error');
  //   } else if (err.error && err.error.error) {
  //     this.alert(err.error.error, 'error');
  //   } else {
  //     this.alert('Lỗi không xác định!', 'error');
  //   }
  // }

  //MARK: NETWORKING
  getInfoCASignServer(id) {
    this.spinnerL.next(true);
    this.signatureService.getInfoCASignServer(id).subscribe(res => {
      this.spinnerL.next(false);
      this.spinnerLg.next(false);
      console.log(res);

      this.infoCA = new InfoCASignServerResponse(res).object;
      this.infoCABase = this.infoCA;
      this.listCABase = this.infoCA.certificateList.slice();

      if (this.infoCA.certificateList.length > 0 && this.selectedCert.id === '') {
        this.selectCert(this.infoCA.certificateList[0]);
      }
    }, err => {
      this.spinnerL.next(false);
      this.spinnerLg.next(false);
      console.log(err);
      this.alert.showErrorHandled(err, ResponseMsg.MESSAGES.SIGN_SERVER);
    }, () => {
      this.spinnerL.next(false);
      this.spinnerLg.next(false);
    })
  }

  putLogoutSignServer(userId) {
    this.spinnerLg.next(true);
    this.signatureService.putLogoutSignServer(userId).subscribe(res => {
      this.spinnerLg.next(false);

      this.getInfoCASignServer(userId);
    }, err => {
      this.spinnerLg.next(false);
      console.log(err);
      this.alert.showErrorHandled(err, ResponseMsg.MESSAGES.SIGN_SERVER);
    })
  }

  /**
   * Lấy thông tin cấu hình chữ ký số
   * @param id 
   */
  getInfoSignatureConfig(id) {
    this.spinnerR.next(true);
    this.signatureService.getInfoSignatureConfig(id).subscribe(res => {
      this.configCA = new InfoSignatureConfigResponse(res).object;
    }, err => {
      this.spinnerR.next(false);
      console.log(err);
      this.alert.showErrorHandled(err, ResponseMsg.MESSAGES.SIGN_SERVER);
    })
  }

  /**
   * Set mặc định chữ ký số
   * @param userId 
   * @param certId 
   */
  postSetDefaultSignature(userId, certId) {
    this.spinnerR.next(true);
    this.signatureService.postSetDefaultSignature(userId, certId).subscribe(res => {
      this.alert.showAlertOnlyNoti(this.translate.instant('SignServerManager_ts_5'), 'success');
    }, err => {
      this.spinnerR.next(false);
      console.log(err);
      this.alert.showErrorHandled(err, ResponseMsg.MESSAGES.SIGN_SERVER);
    })
  }

  /**
   * Đăng nhập tài khoản ký số sign-server
   * @param param 
   */
  postLoginSignServer(param) {
    this.spinnerLg.next(true);
    this.signatureService.postLoginSignServer(param).subscribe((res) => {
      this.spinnerLg.next(false);
      this.alert.showAlertOnlyNoti(this.translate.instant('SignServerManager_ts_6'), 'success');
      this.closeModalLoginSignServer();
      this.getInfoCASignServer(this.userId);
    }, err => {
      this.spinnerLg.next(false);
      console.log(err);
      this.createModalLoginSignServer();
      this.alert.showErrorHandled(err, ResponseMsg.MESSAGES.SIGN_SERVER);
    })
  }
}
