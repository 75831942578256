import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgModalComponent } from 'src/app/layout/extensions/ng-modal/ng-modal.component';
import { UserInformationsItem } from 'src/app/utils/models/profile/user-info-item.model';
import { AuthService } from 'src/app/utils/services/auth.service';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { UserInformationsResponse } from 'src/app/utils/models/profile/user-info-response.model';
import { Observable, BehaviorSubject } from 'rxjs';
import { share } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { ConstantAlertMsg } from 'src/app/utils/common/constant-alert-msg';
import { AlertControl } from 'src/app/utils/alert/alert-control';

@Component({
  selector: 'app-service-profile-manager',
  templateUrl: './service-profile-manager.component.html',
  styleUrls: ['./service-profile-manager.component.scss']
})
export class ServiceProfileManagerComponent implements OnInit {
  data: BehaviorSubject<UserInformationsItem> = new BehaviorSubject<UserInformationsItem>(new UserInformationsItem());

  userInfo = new UserInformationsItem();
  imageLevel = ['assets/img/services/icon-account-level.png', 'assets/img/services/icon-account-level.png', 'assets/img/services/icon-account-level-2.png']

  loadingLeft = new BehaviorSubject<boolean>(false);
  loadingRight = new BehaviorSubject<boolean>(false);

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private auth: AuthService,
    private aasAuth: AasAuthService,
    private myAlert: AlertControl
  ) { }

  ngOnInit(): void {
    this.getUserInfo(this.auth.userId);
  }

  //MARK: Loading
  loadingLeftIsVisiable(): Observable<boolean> {
    return this.loadingLeft.asObservable().pipe(share());
  }

  loadingRightVisiable(): Observable<boolean> {
    return this.loadingRight.asObservable().pipe(share());
  }

  actionSave(event) {
    console.log(event);
  }

  //MARK: Chức năng nâng cấp định danh
  updateVerifyAccount() {
    if (this.userInfo.userType === 'CONSUMER') {
      this.myAlert.showAlertOnlyNoti(ConstantAlertMsg.UP_IDENTITY, '');
    } else if (this.userInfo.userType === 'BUSINESS') {
      this.router.navigate([ConstantUrl.ACC_UPLOAD_GPKD]);
    } else if (this.userInfo.userType === 'ENTERPRISE') {
      this.router.navigate([ConstantUrl.ACC_UPLOAD_GPKD]);
    }
  }

  //MARK: Popup thông báo
  modal(msg, callBack = () => { }) {
    const modalRef = this.modalService.open(NgModalComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.callback = callBack;
  }

  alert(msg, type, callBack = () => { }) {
    const modalRef = this.modalService.open(NgModalComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.notification = true;
    modalRef.componentInstance.callback = callBack;
  }

  //MARK: Networking
  /**
  * Lấy thông tin user đăng nhập
  * @param id 
  */
  getUserInfo(id) {
    this.loadingLeft.next(true);
    this.loadingRight.next(true);
    this.aasAuth.getUserInfo(id).subscribe((res) => {
      this.loadingLeft.next(false);
      this.loadingRight.next(false);

      this.userInfo = new UserInformationsResponse(res).object
      this.data.next(this.userInfo);
      console.log(this.userInfo);

      if (this.userInfo.userType === 'BUSINESS') {
        
      }

      if (this.userInfo.userType === 'ENTERPRISE') {
        // if (this.userInfo.verificationStatus === 'APPROVE_NON_CONTRACT' || this.userInfo.verificationStatus === 'APPROVE_CONTRACT' || this.userInfo.verificationStatus === 'APPROVE' ) {
          
        // } else {
        //   this.router.navigate(['/app/verify-business-vip']);
        // }
      }
    }, err => {
      this.loadingLeft.next(false);
      this.loadingRight.next(false);

      console.log(err);
      this.alert(err.message, 'error', () => { })
    }, () => {
    })
  }
}
