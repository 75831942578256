<app-spinner></app-spinner>
<input #output id="output" class="" value="" type="hidden">
<input #filebase id="fileBase" class="" type="hidden">
<input #filename id="fileName" class="" value="{{ fileName }}">
<div class="wrapper">
    <div class="preview-overlay">

        <div class="preview-container">
            <div class="preview-header d-flex">
                <div 
                    class="ct-header-btn-back d-flex justify-content-center align-items-center cursor-pointer">
                    <span class="material-icons" (click)="goToBack()">clear</span>
                </div>
                <div class="ct-title flex-grow-1 d-flex align-items-center px-3">
                    <h4 class="mb-0">Hóa đơn điện tử</h4>
                </div>
                <!-- <div class="ct-toolbar-btn d-flex align-items-center">
                    <button 
                        class="btn btn-outline-primary d-flex align-items-center mr-3"><span
                            class="material-icons mr-2">delete</span>Hủy hóa đơn</button>
                </div> -->
                <div class="ct-toolbar-btn d-flex align-items-center">
                    <button (click)="showPopupMail(einvoicesDetailObject.cusEmail)"
                        class="btn btn-outline-primary d-flex align-items-center mr-3"><span
                            class="material-icons mr-2">email</span>Gửi email</button>
                </div>
                <div class="ct-toolbar-btn d-flex align-items-center">
                    <button (click)='downloadFile()'
                        class="btn btn-outline-primary d-flex align-items-center mr-3"><span
                            class="material-icons mr-2">get_app</span> Tải xuống</button>
                </div>
            </div>

            <div class="preview-body">
                <div class="pv ct">
                    <div class="pv-body">
                        <div class="ctpv-container">
                            <div class="ctpv-content">
                                <div class="ct-container">
                                    <div class="ct-thumb-page">
                                        <div class="ct-vs ct-enable-scroll">
                                            <ol id="thumbnail-page" class="ct-vs-list mt-3">
                                            </ol>
                                        </div>
                                    </div>
                                    <div class="ct-content">
                                        <div id="parentView" class="ct-doc-loaded ct-enable-scroll">
                                            <iframe #iframe style="width: 100%; height: inherit;"></iframe>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="ct ctpv-tool-bar">
                            <div class="ctpv-tool-sidebar">
                                <div class="ctpv-tabbar">
                                    <div class="ctpv-main">
                                        <button (click)="selectTab('INFO')" class="ctpv-btn"
                                            [class.active]="activeTab == 'INFO'"> <span
                                                class="material-icons-outlined">info</span>
                                        </button>
                                    </div>
                                </div>
                                <div class="ctpv-footer">

                                </div>
                            </div>
                            <div class="ctpv-tool-content">
                                <div class="ctpv-content-wrapper px-3" *ngIf="einvoicesDetailObject">
                                    <div id="tab-info" class="ctpv-scroll-content"
                                        [class.d-none]="!(activeTab === 'INFO')">
                                        <h5 class="mt-3">Thông tin hóa đơn</h5>
                                        <div class="row mt-3">
                                            <!-- <div class="col-lg-6">
                                                <p><label>Tên hóa đơn</label></p>
                                                <p>{{ einvoicesDetailObject.tenHoaDon}}</p>
                                            </div> -->
                                            <div class="col-lg-6">
                                                <p><label>Ngày phát hành</label></p>
                                                <p>{{ einvoicesDetailObject.ngayPhatHanh | date: 'dd/MM/yyyy'}}</p>
                                            </div>
                                            <div class="col-lg-6">
                                                <p><label>Trạng thái</label></p>
                                                <span class="badge {{ status[einvoicesDetailObject.trangThai].style }} badge-pd">{{ status[einvoicesDetailObject.trangThai].label }}</span>
                                            </div>
                                        </div>

                                        <div class="row mt-3">
                                            <div class="col-lg-6">
                                                <p><label>Ghi chú</label></p>
                                                <p>{{ einvoicesDetailObject.ghiChu}}</p>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #sendEmailDetail let-c="close" let-d="dismiss">
    <div class="modal-header border-0 pb-0 mb-0">
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span class="material-icons">
                cancel
            </span>
        </button>
    </div>
    <div class="modal-body py-0">
        <h4 class="modal-title w-100 text-center mb-3" id="modal-basic-title" >Gửi email thông báo</h4>
        <p class="modal-title w-100 text-center mb-3" id="modal-basic-title">Một thông báo thông tin hóa đơn sẽ trả về email của khách hàng. Vui lòng kiểm tra lại thông tin. </p>
        <form [formGroup]="formEmail">
            <div class="form-group">
                <label>Email khách hàng: </label>
                <input formControlName="email" class="form-control" type="text" placeholder="Nhập email khách hàng">
            </div>
        </form>
    </div>
    <div class="modal-footer border-0 d-flex justify-content-center">
      <button type="button" class="btn btn-outline-primary mr-3" (click)="d('Cross click')">Hủy</button>
        <button type="button" class="btn btn-primary" (click)="btnClickResendEmail(email.value)" >Xác nhận</button>
    </div>
</ng-template>

