<div class="landingpage">
    <header id="myheader">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <nav class="navbar navbar-expand-lg navbar-lightrounded">
                            <a class="navbar-brand" href="#">
                                <div class="logo">
                                    <img src="../../../assets/img/home/v4/img/LOGO.png">
                                </div>
                            </a>
                            <button class="navbar-toggler collapsed" type="button" data-toggle="collapse"
                                data-target="#navbarsExample09" aria-controls="navbarsExample09" aria-expanded="false"
                                aria-label="Toggle navigation">
                                <span class="navbar-toggler-icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-justify"
                                        viewBox="0 0 16 16" id="justify">
                                        <path fill-rule="evenodd"
                                            d="M2 12.5a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5zm0-3a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5zm0-3a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5zm0-3a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5z">
                                        </path>
                                    </svg>
                                </span>
                            </button>
                            <div class="menu-btn">
                                <img src="../../../assets/img/home/v4/icon/close.png" alt="">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi bi-justify"
                                    viewBox="0 0 16 16" id="justify">
                                    <path fill-rule="evenodd"
                                        d="M2 12.5a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5zm0-3a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5zm0-3a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5zm0-3a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11a.5.5 0 01-.5-.5z">
                                    </path>
                                </svg>
                            </div>
                            <div class="navbar-collapse collapse pushy pushy-right" id="navbarsExample09">
                                <ul class="navbar-nav mr-auto" id="menu_portal">
                                    <li [ngClass]="{'active': homeMode == 0}" class="nav-item active pushy-link">
                                        <a [ngClass]="{'active': homeMode == 0}" class="nav-link active"
                                            [routerLink]='"."' (click)="changeMode(0)">{{ 'home' | translate }}</a>
                                    </li>
                                    <li [ngClass]="{'active': homeMode == 4}" class="nav-item pushy-link">
                                        <a [ngClass]="{'active': homeMode == 4}" class="nav-link" [routerLink]='"."'
                                            (click)="changeMode(4); reloadSubPage(4, translate.currentLang)"
                                            [fragment]="'feature'">{{ 'features' | translate }}</a>
                                    </li>
                                    <li [ngClass]="{'active': homeMode == 2}" class="nav-item pushy-link">
                                        <a [ngClass]="{'active': homeMode == 2}" class="nav-link" [routerLink]='"."'
                                            (click)="changeMode(2); reloadSubPage(2, translate.currentLang)"
                                            [fragment]="'pricelist'">{{ 'price_list' | translate }}</a>
                                    </li>
                                    <li [ngClass]="{'active': homeMode == 3 || homeMode == 5}" class="nav-item pushy-link">
                                        <!-- <a class="nav-link" routerLink="/contact">Hỗ trợ</a> -->
                                        <a [ngClass]="{'active': homeMode == 3 || homeMode == 5}" class="nav-link" [routerLink]='"."'
                                            (click)="changeMode(3); reloadSubPage(3, translate.currentLang)"
                                            [fragment]="'support'">{{ 'support' | translate }}</a>
                                    </li>
                                    <li [ngClass]="{'active': homeMode == 1}" class="nav-item pushy-link">
                                        <!-- <a class="nav-link" >Liên hệ</a> -->
                                        <a [ngClass]="{'active': homeMode == 1}" class="nav-link" [routerLink]='"."'
                                            (click)="changeMode(1); reloadSubPage(1, translate.currentLang)"
                                            [fragment]="'contact'">{{ 'contact' | translate }}</a>
                                    </li>
                                </ul>
                                <div class="login">
                                    <span class="selectlanguage  selectlanguagemobi">
                                        <img *ngIf="translate.currentLang === 'vi'"
                                            src="../../../assets/img/home/v4/Image/image 119.png" alt="" width="26"
                                            height="24">
                                        <img *ngIf="translate.currentLang === 'en'"
                                            src="../../../assets/img/home/v4/Image/icon-en.png" alt="" width="26"
                                            height="24">
                                        <span *ngIf="translate.currentLang === 'vi'"> Việt Nam</span>
                                        <span *ngIf="translate.currentLang === 'en'"> English</span>
                                        <img src="../../../assets/img/home/v4/icon/showmenu.png" alt=""
                                            class="iconshow">

                                        <ul class="mutilanguage">
                                            <li
                                                (click)="translate.use('vi'); getData('vi'); reloadSubPage(homeMode, 'vi')">
                                                <img src="../../../assets/img/home/v4/Image/image 119.png" width="26"
                                                    height="24" alt=""> Việt Nam</li>
                                            <li
                                                (click)="translate.use('en'); getData('en'); reloadSubPage(homeMode, 'en')">
                                                <img src="../../../assets/img/home/v4/Image/icon-en.png" width="26"
                                                    height="24" alt=""> English</li>
                                        </ul>
                                    </span>
                                    <a routerLink="/app/login" class="btn_login">{{ 'signin' | translate }}</a>
                                    <a routerLink="/app/signup" class="btn_reg">{{ 'signup_trial' | translate }}</a>
                                    <span class="selectlanguage d-none-mobi991">
                                        <img *ngIf="translate.currentLang === 'vi'"
                                            src="../../../assets/img/home/v4/Image/image 119.png" alt="" width="26"
                                            height="24">
                                        <img *ngIf="translate.currentLang === 'en'"
                                            src="../../../assets/img/home/v4/Image/icon-en.png" alt="" width="26"
                                            height="24">
                                        <img src="../../../assets/img/home/v4/icon/showmenu.png" alt="">
                                        <ul class="mutilanguage">
                                            <li
                                                (click)="translate.use('vi'); getData('vi'); reloadSubPage(homeMode, 'vi')">
                                                <img src="../../../assets/img/home/v4/Image/image 119.png" width="26"
                                                    height="24" alt=""> Việt Nam</li>
                                            <li
                                                (click)="translate.use('en'); getData('en'); reloadSubPage(homeMode, 'en')">
                                                <img src="../../../assets/img/home/v4/Image/icon-en.png" width="26"
                                                    height="24" alt=""> English</li>
                                        </ul>
                                    </span>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </header>
    <div class="site-overlay"></div>
    <div [hidden]="homeMode != 0">
        <section id="section1" class="block-section">
            <div class="container">
                <div class="row">
                    <div class="banner-text-left">
                        <div class="sul_">
                            <span>{{ homeData?.banner?.blogs[0]?.subTitle}}</span>
                            <img src="{{ homeData?.banner?.blogs[0]?.avatar }}" alt="">
                        </div>
                        <b class="title">
                            {{homeData?.banner?.blogs[0]?.title}}
                        </b>
                        <span class="decrip">
                            {{ homeData?.banner?.blogs[0]?.description}}
                        </span>
                        <a routerLink="/app/signup" class="btn-trial">
                            <span>{{ homeData?.banner?.blogs[1]?.title}}</span>
                            <img class="ml-lg-auto" src="{{ homeData?.banner?.blogs[1]?.avatar }}" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <section class="section-2">
            <div class="container">
                <div class="row itemcenter">
                    <div class="col-lg-6 col-md-6">
                        <div class="row">
                            <div class="title">
                                <img src="{{ homeData?.appLink?.blogs[0]?.avatar }}" alt="">
                                <span>
                                    {{ homeData?.appLink?.blogs[0]?.title}}
                                    {{ homeData?.appLink?.blogs[0]?.subTitle }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="row">
                            <div class="list-button">
                                <a href="{{ link.iosLink }}">
                                    <div class="img">
                                        <img src="{{ homeData?.appLink?.blogs[1]?.avatar }}" alt="">
                                    </div>
                               
                                </a>
                                <a href="{{ link.androidLink }}">
                                    <div class="img">
                                        <img src="{{ homeData?.appLink?.blogs[2]?.avatar }}" alt="">
                                    </div>
                                </a>
                                <a href="{{ link.qrcodeLink }}" class="d-sm-none d-xl-block">
                                    <img src="{{ homeData?.appLink?.blogs[3]?.avatar }}" alt="">
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section-3">
            <div class="container">
                <div class="row">
                    <h3 class="title-section">
                        {{ homeData?.giaTriMangLai?.category?.categoryName }}
                    </h3>
                    <span class="description-section">
                        {{ homeData?.giaTriMangLai?.category?.description }}
                    </span>

                </div>
                <div class="row list-item-col slidemobileitem">
                    <div class="listitemslide col-md-3 col-lg-3 mb-md-3 mb-sm-3">
                        <div class="col-list-item col1">
                            <img src="{{ homeData?.giaTriMangLai?.blogs[0]?.avatar }}" alt="">
                            <span class="title">
                                {{ homeData?.giaTriMangLai?.blogs[0]?.title }}
                            </span>
                            <span class="decription">
                                {{ homeData?.giaTriMangLai?.blogs[0]?.description }}
                            </span>
                        </div>
                    </div>
                    <div class="listitemslide col-md-3 col-sm-3 col-lg-3 mb-md-3 mb-sm-3 " id="">
                        <div class="col-list-item col2">
                            <img src="{{ homeData?.giaTriMangLai?.blogs[1]?.avatar }}" alt="">
                            <span class="title">
                                {{ homeData?.giaTriMangLai?.blogs[1]?.title }}
                            </span>
                            <span class="decription">
                                {{ homeData?.giaTriMangLai?.blogs[1]?.description }}
                            </span>
                        </div>
                    </div>
                    <div class="listitemslide col-md-3 col-sm-3 col-lg-3 mb-md-3 mb-sm-3 " id="">
                        <div class="col-list-item col3">
                            <!-- <div class="img-hear">
                            <img src="../../../assets/img/home/v4/Image/Group 17730 (1).png" alt="" class="img-item">
                            <img src="../../../assets/img/home/v4/Image/user 2.png" alt="">
                        </div>  -->
                            <img src="{{ homeData?.giaTriMangLai?.blogs[2]?.avatar }}" alt="">
                            <span class="title">
                                {{ homeData?.giaTriMangLai?.blogs[2]?.title }}
                            </span>
                            <span class="decription">
                                {{ homeData?.giaTriMangLai?.blogs[2]?.description }}
                            </span>
                        </div>
                    </div>
                    <div class="listitemslide col-md-3 col-sm-3 col-lg-3 mb-md-3 mb-sm-3" id="">
                        <div class="col-list-item col4">
                            <img src="{{ homeData?.giaTriMangLai?.blogs[3]?.avatar }}" alt="">
                            <span class="title">
                                {{ homeData?.giaTriMangLai?.blogs[3]?.title }}
                            </span>
                            <span class="decription">
                                {{ homeData?.giaTriMangLai?.blogs[3]?.description }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <h3 class="title-section title-section2">
                        {{ homeData?.doiTac?.category?.categoryName}} <br />
                    </h3>

                </div>
                <div class="row list-item-logo">
                    <div *ngFor="let doiTac of homeData?.doiTac?.blogs; let i = index" class="col-md-4 col-lg-3 col-6">
                        <div class="item-img">
                            <img src="{{ homeData?.doiTac?.blogs[i]?.avatar }}" alt="">
                        </div>
                    </div>
                    <!-- <div class="col-md-3">
                        <div class="item-img">
                            <img src="../../../assets/img/home/v4/Image/Group 16332.png" alt="">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="item-img">
                            <img src="../../../assets/img/home/v4/Image/image 125.png" alt="">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="item-img">
                            <img src="../../../assets/img/home/v4/Image/Group 16333.png" alt="">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="item-img">
                            <img src="../../../assets/img/home/v4/Image/image 123.png" alt="">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="item-img">
                            <img src="../../../assets/img/home/v4/Image/image 123.png" alt="">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="item-img">
                            <img src="../../../assets/img/home/v4/Image/image 124.png" alt="">
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="item-img">
                            <img src="../../../assets/img/home/v4/Image/Group 16329.png" alt="">
                        </div>
                    </div> -->
                </div>
            </div>
        </section>
        <section class="section-4">
            <div class="container">
                <div class="row">
                    <div class="img-banner">
                        <img src="{{ homeData?.tinhNangChinh?.blogs[4]?.avatar }}" alt="">
                    </div>
                </div>
                <div class="row">
                    <h3 class="title-section">
                        {{ homeData?.tinhNangChinh?.category?.categoryName }}
                    </h3>
                    <span class="description-section">
                        {{ homeData?.tinhNangChinh?.category?.description }}
                    </span>

                </div>
                <div class="row list-item-col slidemobileitem">
                    <div class="listitemslide col-md-3 col-lg-3 mb-md-3">
                        <div class="col-list-item col1">
                            <img src="{{ homeData?.tinhNangChinh?.blogs[0]?.avatar }}" alt="">
                            <span class="title">
                                {{ homeData?.tinhNangChinh?.blogs[0]?.title }}
                            </span>
                            <span class="decription">
                                {{ homeData?.tinhNangChinh?.blogs[0]?.description }}
                            </span>
                        </div>
                    </div>
                    <div class="listitemslide col-md-3 col-lg-3 mb-md-3">
                        <div class=" col-list-item col2">
                            <img src="{{ homeData?.tinhNangChinh?.blogs[1]?.avatar }}" alt="">
                            <span class="title">
                                {{ homeData?.tinhNangChinh?.blogs[1]?.title }}
                            </span>
                            <span class="decription">
                                {{ homeData?.tinhNangChinh?.blogs[1]?.description }}
                            </span>
                        </div>
                    </div>
                    <div class="listitemslide col-md-3 col-lg-3 mb-md-3">
                        <div class="col-list-item col3">
                            <img src="{{ homeData?.tinhNangChinh?.blogs[2]?.avatar }}" alt="">
                            <span class="title">
                                {{ homeData?.tinhNangChinh?.blogs[2]?.title }}
                            </span>
                            <span class="decription">
                                {{ homeData?.tinhNangChinh?.blogs[2]?.description }}
                            </span>
                        </div>
                    </div>
                    <div class="listitemslide col-md-3 col-lg-3 mb-md-3">
                        <div class="col-list-item col4">
                            <img src="{{ homeData?.tinhNangChinh?.blogs[3]?.avatar }}" alt="">
                            <span class="title">
                                {{ homeData?.tinhNangChinh?.blogs[3]?.title }}
                            </span>
                            <span class="decription">
                                {{ homeData?.tinhNangChinh?.blogs[3]?.description }}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="btn-review">
                        <a [routerLink]='"."' (click)="changeMode(4); reloadSubPage(4, translate.currentLang)"
                            [fragment]="'feature'" title="" class="">
                            <span> {{ homeData?.tinhNangChinh?.blogs[5]?.title }}</span>
                            <img src="{{ homeData?.tinhNangChinh?.blogs[5]?.avatar }}" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <section class="section-5">
            <div class="container">
                <div class="row">
                    <h3 class="title-section">
                        {{ homeData?.anToanBaoMat?.category?.categoryName }}
                    </h3>
                    <span class="description-section">
                        {{ homeData?.anToanBaoMat?.category?.description }}
                    </span>
                    <div class="row body">
                        <div class="col-md-6 col-lg-6 left-content">
                            <div class="row slideantaonbaomat">
                                <div class="col-md-6 pattern-col col-3">
                                    <div class="col-item">
                                        <img src="{{ homeData?.anToanBaoMat?.blogs[0]?.avatar }}" alt="">
                                        <span>
                                            {{ homeData?.anToanBaoMat?.blogs[0]?.subTitle }}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-6 pattern-col col-3">
                                    <div class="col-item">
                                        <img src="{{ homeData?.anToanBaoMat?.blogs[1]?.avatar }}" alt="">
                                        <span>
                                            {{ homeData?.anToanBaoMat?.blogs[1]?.subTitle }}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-6 pattern-col col-3">
                                    <div class="col-item">
                                        <img src="{{ homeData?.anToanBaoMat?.blogs[2]?.avatar }}" alt="">
                                        <span>
                                            {{ homeData?.anToanBaoMat?.blogs[2]?.subTitle }}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-6 pattern-col col-3">
                                    <div class="col-item">
                                        <img src="{{ homeData?.anToanBaoMat?.blogs[3]?.avatar }}" alt="">
                                        <span>
                                            {{ homeData?.anToanBaoMat?.blogs[3]?.subTitle }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row d-none d-xl-block">
                                <div class="btn-review">
                                    <a [routerLink]='"."'
                                        (click)="changeMode(4); reloadSubPage(4, translate.currentLang)"
                                        [fragment]="'feature'" title="" class="">
                                        <span>{{ homeData?.anToanBaoMat?.blogs[4]?.title }}</span>
                                        <img src="{{ homeData?.anToanBaoMat?.blogs[4]?.avatar }}" alt="">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-6">
                            <div class="row">
                                <div class="right-content">
                                    <img src="{{ homeData?.anToanBaoMat?.blogs[5]?.avatar }}" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section-6">
            <div class="container pb-3">
                <div class="row body-section-6">
                    <div class="col-md-4">
                        <div class="row" style="height: 100%;">
                            <div class="content-left">

                                <img src="{{ homeData?.phapLy?.blogs[3]?.avatar }}" alt="">
                                <div>
                                    <h3 class="title">{{ homeData?.phapLy?.category?.categoryName }}</h3>

                                    <span>{{ homeData?.phapLy?.category?.description }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 item-col-text">
                        <p>
                            <b>{{ homeData?.phapLy?.blogs[0]?.title }}</b> {{ homeData?.phapLy?.blogs[0]?.description }}
                        </p>
                    </div>
                    <div class="col-md-4 item-col-text right-item">
                        <p>
                            <b>{{ homeData?.phapLy?.blogs[1]?.title }}</b> {{ homeData?.phapLy?.blogs[1]?.description }}
                        </p>
                        <!--  <div class="btn-review">
                        <a href="#" title="" class="">
                            <span>Tìm hiểu thêm</span>
                            <img src="Image/Vector (1).png" alt="">
                        </a>
                    </div> -->
                    </div>
                </div>
            </div>
        </section>
        <section class="section-7">
            <div class="container">
                <div class="">
                    <div class="row">
                        <h3 class="title-section">
                            {{ homeData?.quyTrinhKy?.category?.categoryName }}
                        </h3>
                        <span class="description-section">
                            {{ homeData?.quyTrinhKy?.category?.description }}
                        </span>
                    </div>
                    <div class="row body">
                        <div class="col-md-6 col-lg-5 left-content">
                            <div class="row">
                                <ul class="nav nav-pills left-list-item">
                                    <li class="nav-item">
                                        <a class="nav-link active" data-toggle="pill" href="#home">
                                            <div class="item">
                                                <span class="stt">01</span>
                                                <b class="title">{{ homeData?.quyTrinhKy?.blogs[0]?.title }}</b>
                                                <span class="text">{{ homeData?.quyTrinhKy?.blogs[0]?.description
                                                    }}</span>
                                            </div>
                                            <img src="{{ homeData?.quyTrinhKy?.blogs[0]?.avatar }}" alt=""
                                                class="img-mobi-main">
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-toggle="pill" href="#menu1">
                                            <div class="item">
                                                <span class="stt">02</span>
                                                <b class="title">{{ homeData?.quyTrinhKy?.blogs[1]?.title }}</b>
                                                <span class="text">{{ homeData?.quyTrinhKy?.blogs[1]?.description
                                                    }}</span>
                                            </div>
                                            <img src="{{ homeData?.quyTrinhKy?.blogs[1]?.avatar }}" alt=""
                                                class="img-mobi-main">
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-toggle="pill" href="#menu2">
                                            <div class="item">
                                                <span class="stt">03</span>
                                                <b class="title">{{ homeData?.quyTrinhKy?.blogs[2]?.title }}</b>
                                                <span class="text">{{ homeData?.quyTrinhKy?.blogs[2]?.description
                                                    }}</span>
                                            </div>
                                            <img src="{{ homeData?.quyTrinhKy?.blogs[2]?.avatar }}" alt=""
                                                class="img-mobi-main">
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" data-toggle="pill" href="#menu3">
                                            <div class="item">
                                                <span class="stt">04</span>
                                                <b class="title">{{ homeData?.quyTrinhKy?.blogs[3]?.title }}</b>
                                                <span class="text">{{ homeData?.quyTrinhKy?.blogs[3]?.description
                                                    }}</span>
                                            </div>
                                            <img src="{{ homeData?.quyTrinhKy?.blogs[3]?.avatar }}" alt=""
                                                class="img-mobi-main">
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-7 col-md-6 content-right">
                            <!-- Tab panes -->
                            <div class="tab-content row">
                                <div class="tab-pane container active" id="home">
                                    <img src="{{ homeData?.quyTrinhKy?.blogs[0]?.avatar }}" alt="">
                                </div>
                                <div class="tab-pane container fade" id="menu1">
                                    <img src="{{ homeData?.quyTrinhKy?.blogs[1]?.avatar }}" alt="">
                                </div>
                                <div class="tab-pane container fade" id="menu2">
                                    <img src="{{ homeData?.quyTrinhKy?.blogs[2]?.avatar }}" alt="">
                                </div>
                                <div class="tab-pane container fade" id="menu3">
                                    <img src="{{ homeData?.quyTrinhKy?.blogs[3]?.avatar }}" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section-8">
            <div class="container" style="    position: relative;">
                <div class="row body">
                    <div class="col-md-7">
                        <div class="row">
                            <div class="left-content">
                                <p class="title title_mobi">
                                    {{ homeData?.traiNghiem?.category?.categoryName }}<br />
                                    {{ homeData?.traiNghiem?.category?.description }}
                                </p>
                                <a routerLink="/app/signup" class="btn-trial">
                                    <span>{{ homeData?.traiNghiem?.blogs[1]?.title }}</span>
                                    <img src="{{ homeData?.traiNghiem?.blogs[1]?.avatar }}" alt="">
                                </a>
                                <ul class="list-item d-none d-sm-block">
                                    <li>
                                        <img src="{{ homeData?.traiNghiem?.blogs[2]?.avatar }}" alt="">
                                        <span>{{ homeData?.traiNghiem?.blogs[2]?.title }}</span>
                                    </li>
                                    <li>
                                        <img src="{{ homeData?.traiNghiem?.blogs[3]?.avatar }}" alt="">
                                        <span>{{ homeData?.traiNghiem?.blogs[3]?.title }}</span>
                                    </li>
                                    <li>
                                        <img src="{{ homeData?.traiNghiem?.blogs[4]?.avatar }}" alt="">
                                        <span>{{ homeData?.traiNghiem?.blogs[4]?.title }}</span>
                                    </li>
                                    <li>
                                        <img src="{{ homeData?.traiNghiem.blogs[5].avatar }}" alt="">
                                        <span>{{ homeData?.traiNghiem.blogs[5].title }}</span>
                                    </li>
                                </ul>
                                <div class="list-button">
                                    <a href="{{ link.iosLink }}">
                                        <div class="img">
                                            <img src="{{ homeData?.appLink?.blogs[1]?.avatar }}" alt="">
                                        </div>
                                    </a>
                                    <a href="{{ link.androidLink }}">
                                        <div class="img">
                                            <img src="{{ homeData?.appLink?.blogs[2]?.avatar }}" alt="">
                                        </div>
                                    </a>
                                    <!-- <a href="{{ configs[10]?.value }}">
                                        <img src="{{ homeData?.appLink?.blogs[1]?.avatar }}" alt="">
                                    </a>

                                    <a href="{{ configs[3]?.value }}">
                                        <img src="{{ homeData?.appLink?.blogs[2]?.avatar }}" alt="" style="margin: 0px 12px">
                                    </a> -->
                                    <a href="{{ link.qrcodeLink }}" class="d-none d-xl-block">
                                        <!-- <img style="width: 150px;" src="{{ homeData?.appLink?.blogs[3]?.avatar }}"
                                            alt=""> -->
                                            <img src="{{ homeData?.appLink?.blogs[3]?.avatar }}" alt="">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <img src="{{ homeData?.traiNghiem?.blogs[0]?.avatar }}" alt="" class="img-right d-none-mobi767"> -->
                </div>
            </div>
        </section>
        <section class="section-9">
            <div class="container">
                <div class="row">
                    <h3 class="title-section">
                        {{ homeData?.heSinhThai?.category?.categoryName }}
                    </h3>
                    <span class="description-section">
                        {{ homeData?.heSinhThai?.category?.description }}
                    </span>
                </div>
                <div class="row body">
                    <div class="col-lg-4">
                        <div class="row">
                            <ul class="left-list-item nav nav-pills" id="Ecosystem">
                                <li class="nav-item">
                                    <a class="nav-link active" data-img="img1" data-toggle="pill" href="#home1">
                                        <b class="title">{{ homeData?.heSinhThai?.blogs[2]?.title }}</b>
                                        <span class="text">{{ homeData?.heSinhThai?.blogs[2]?.description }}
                                            <span href="#" class="detailt-btn">{{ 'details' | translate}}</span>
                                        </span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-img="img2" data-toggle="pill" href="#menu11">
                                        <b class="title">{{ homeData?.heSinhThai?.blogs[3]?.title }}</b>
                                        <span class="text">{{ homeData?.heSinhThai?.blogs[3]?.description }}
                                            <span href="#" class="detailt-btn">{{ 'details' | translate}}</span>
                                        </span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-img="img3" data-toggle="pill" href="#menu21">
                                        <b class="title">{{ homeData?.heSinhThai?.blogs[4]?.title }}</b>
                                        <span class="text">{{ homeData?.heSinhThai?.blogs[4]?.description }}
                                            <span href="#" class="detailt-btn">{{ 'details' | translate}}</span>
                                        </span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-img="img4" data-toggle="pill" href="#menu31">
                                        <b class="title">{{ homeData?.heSinhThai?.blogs[5]?.title }}</b>
                                        <span class="text">{{ homeData?.heSinhThai?.blogs[5]?.description }}
                                            <span href="#" class="detailt-btn">{{ 'details' | translate}}</span>
                                        </span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-img="img5" data-toggle="pill" href="#menu31">
                                        <b class="title">{{ homeData?.heSinhThai?.blogs[6]?.title }}</b>
                                        <span class="text">{{ homeData?.heSinhThai?.blogs[6]?.description }}
                                            <span href="#" class="detailt-btn">{{ 'details' | translate}}</span>
                                        </span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-img="img6" data-toggle="pill" href="#menu32">
                                        <b class="title">{{ homeData?.heSinhThai?.blogs[7]?.title }}</b>
                                        <span class="text">{{ homeData?.heSinhThai?.blogs[7]?.description }}
                                            <span href="#" class="detailt-btn">{{ 'details' | translate}}</span>
                                        </span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-img="img7" data-toggle="pill" href="#menu33">
                                        <b class="title">{{ homeData?.heSinhThai?.blogs[8]?.title }}</b>
                                        <span class="text">{{ homeData?.heSinhThai?.blogs[8]?.description }}
                                            <span href="#" class="detailt-btn">{{ 'details' | translate}}</span>
                                        </span>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-img="img8" data-toggle="pill" href="#menu34">
                                        <b class="title">{{ homeData?.heSinhThai?.blogs[9]?.title }}</b>
                                        <span class="text">{{ homeData?.heSinhThai?.blogs[9]?.description }}
                                            <span href="#" class="detailt-btn">{{ 'details' | translate}}</span>
                                        </span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <div class="row">
                            <div class="list-ecosystem">
                                <img src="{{ homeData?.heSinhThai?.blogs[0]?.avatar }}" alt="">
                                <img src="{{ homeData?.heSinhThai?.blogs[1]?.avatar }}" alt="" class="item-img-chil">
                                <a href="https://econtract.vnpt.vn/home" target="_blank" title="" data-img="img1" class="imgitem img1">
                                    <div class="item active">
                                        <!--  <img src="../../../assets/img/home/v4/Image/Ellipse 5.png" alt="" class="img-back"> -->
                                        <img src="{{ homeData?.heSinhThai?.blogs[2]?.avatar }}" alt=""
                                            class="img-index">
                                    </div>
                                </a>
                                <a href="https://onesme.vn/sme-portal" target="_blank" title="" data-img="img2" class="imgitem img2">
                                    <!-- <img src="../../../assets/img/home/v4/Image/VNPT eContract.png" alt=""> -->
                                    <div class="item">
                                        <!-- <img src="../../../assets/img/home/v4/Image/Ellipse 5.png" alt="" class="img-back"> -->
                                        <img src="{{ homeData?.heSinhThai?.blogs[3]?.avatar }}" alt=""
                                            class="img-index">
                                        <!-- <span>VNPT<br/>ASME</span> -->
                                    </div>
                                </a>
                                <a href="https://ekyc.vnpt.vn/" target="_blank" title="" data-img="img3" class="imgitem img3">
                                    <!-- <img src="../../../assets/img/home/v4/Image/VNPT eContract.png" alt=""> -->
                                    <div class="item">
                                        <!-- <img src="../../../assets/img/home/v4/Image/Ellipse 5.png" alt="" class="img-back"> -->
                                        <img src="{{ homeData?.heSinhThai?.blogs[4]?.avatar }}" alt=""
                                            class="img-index">
                                    </div>
                                </a>
                                <a href="https://vnpt.com.vn/doanh-nghiep/san-pham-dich-vu/he-thong-quan-ly-van-ban-dieu-hanh-vnpt-iofffice/" target="_blank" title="" data-img="img4" class="imgitem img4">
                                    <div class="item">
                                        <!-- <img src="../../../assets/img/home/v4/Image/Ellipse 5.png" alt="" class="img-back"> -->
                                        <img src="{{ homeData?.heSinhThai?.blogs[5]?.avatar }}" alt=""
                                            class="img-index">
                                        <!-- <span>VNPT<br />iOffice</span> -->
                                    </div>
                                </a>
                                <a href="https://invoice.vnpt.vn/" target="_blank" title="" data-img="img5" class="imgitem img5">
                                    <!-- <img src="../../../assets/img/home/v4/Image/VNPT eContract.png" alt=""> -->
                                    <div class="item">
                                        <!-- <img src="../../../assets/img/home/v4/Image/Ellipse 5.png" alt="" class="img-back"> -->
                                        <img src="{{ homeData?.heSinhThai?.blogs[6]?.avatar }}" alt=""
                                            class="img-index">
                                        <!-- <span>VNPT<br />ASME</span> -->
                                    </div>
                                </a>
                                <a href="https://vnpt.com.vn/doanh-nghiep/san-pham-dich-vu/sms-brandname/" target="_blank" title="" data-img="img6" class="imgitem img6">
                                    <!-- <img src="../../../assets/img/home/v4/Image/VNPT eContract.png" alt=""> -->
                                    <div class="item">
                                        <!-- <img src="../../../assets/img/home/v4/Image/Ellipse 5.png" alt="" class="img-back"> -->
                                        <img src="{{ homeData?.heSinhThai?.blogs[7]?.avatar }}" alt=""
                                            class="img-index">
                                        <!-- <span>VNPT<br />PAY</span> -->
                                    </div>
                                </a>
                                <a href="https://kyso.vnpt-ca.vn/" target="_blank" title="" data-img="img7" class="imgitem img7">
                                    <!-- <img src="../../../assets/img/home/v4/Image/VNPT eContract.png" alt=""> -->
                                    <div class="item">
                                        <!-- <img src="../../../assets/img/home/v4/Image/Ellipse 5.png" alt="" class="img-back"> -->
                                        <img src="{{ homeData?.heSinhThai?.blogs[8]?.avatar }}" alt=""
                                            class="img-index">
                                        <!-- <span>SMS<br />Brand<br/>Name</span> -->
                                    </div>
                                </a>
                                <a href="https://vnptpay.vn/" target="_blank" title="" data-img="img8" class="imgitem img8">
                                    <!-- <img src="../../../assets/img/home/v4/Image/VNPT eContract.png" alt=""> -->
                                    <div class="item">
                                        <!-- <img src="../../../assets/img/home/v4/Image/Ellipse 5.png" alt="" class="img-back"> -->
                                        <img src="{{ homeData?.heSinhThai?.blogs[9]?.avatar }}" alt=""
                                            class="img-index">
                                        <!-- <span>VNPT<br />iOffice</span> -->
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section class="section-10">
            <div class="container">
                <div class="row">
                    <h3 class="title-section">
                        {{ homeData?.giaiThuong?.category?.categoryName }}
                    </h3>
                    <span class="description-section">
                        {{ homeData?.giaiThuong?.category?.description }}
                    </span>
                </div>
                <div class="autoplayslide justify-content-center">
                    <div *ngFor="let item of homeData?.giaiThuong?.blogs">
                        <div class="giaithuong">
                            <div class="img-title">
                                <img src="{{ item?.avatar }}" alt="">
                            </div>
                            <span>{{ item?.title }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
    <div [hidden]="homeMode !=1">
        <app-contact #contact></app-contact>
    </div>

    <div [hidden]="homeMode !=2">
        <app-pricelist #pricelistView [experience]="homeData?.traiNghiem" [appLink]="homeData?.appLink" [link]="link"
            [configs]="configs"></app-pricelist>
    </div>
    <div [hidden]="homeMode !=3">
        <app-support #supportView [experience]="homeData?.traiNghiem" [appLink]="homeData?.appLink" [link]="link" [configs]="configs">
        </app-support>
    </div>
    <div [hidden]="homeMode !=4">
        <app-feature #featureView [experience]="homeData?.traiNghiem" [appLink]="homeData?.appLink" [link]="link" [configs]="configs">
        </app-feature>
    </div>
    <div [hidden]="homeMode != 5">
        <app-post #post></app-post>
    </div>

    <section class="section-11">
        <div class="container">
            <footer>
                <div class="">
                    <div class="row">
                        <div class="col-md-12 col-lg-7">
                            <div class="row">
                                <div class="col-md-6 col-6 col-lg-7">
                                    <div class="row">
                                        <div class="left">
                                            <img src="../../../assets/img/home/v4/Image/vnpt_logo.png" alt=""
                                                class="logo-footer">
                                            <div class="right-address">
                                                <div>
                                                    <img src="../../../assets/img/home/v4/Image/location.svg"
                                                        alt="">
                                                    <!-- <span>57 Huỳnh Thúc Kháng, Đống Đa, Hà Nội</span> -->
                                                    <span>{{ headquaterAddress?.blogs[0]?.description }}</span>
                                                </div>
                                                <div>
                                                    <img src="../../../assets/img/home/v4/icon/call icon.png" alt="">
                                                    <span>Hotline: 18001260</span>
                                                </div>
                                                <div>
                                                    <img src="../../../assets/img/home/v4/icon/message_icon.png" alt="">
                                                    <span>econtract@vnpt.vn</span>
                                                </div>
                                                <div>
                                                    <img src="../../../assets/img/home/v4/icon/internet_icon.png"
                                                        alt="">
                                                    <span>https://vnpt.vn</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-5 col-6 col-md-6">
                                    <div class="row">
                                        <div class="col-footer">
                                            <span class="title">{{ 'policy' | translate }}</span>
                                            <div>
                                                <span>{{ 'shopping_guide' | translate }}</span>
                                            </div>
                                            <div>
                                                <span>{{ 'trading_conditions' | translate }}</span>
                                            </div>
                                            <div>
                                                <span>{{ 'terms_of_use' | translate }}</span>
                                            </div>
                                            <div>
                                                <span>{{ 'payment_methods' | translate }}</span>
                                            </div>
                                            <div>
                                                <span>{{ 'privacy_policy' | translate }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-12">
                            <div class="right">
                                <div class="row">
                                    <div class="col-lg-6 col-6">
                                        <div class="row">
                                            <div class="col-footer">
                                                <span class="title">{{ 'download' | translate }}</span>
                                                <div>
                                                    <a href="{{ link.androidLink }}">
                                                        <img src="../../../assets/img/home/v4/Image/Google Play Badge.png"
                                                            alt="">
                                                    </a>

                                                </div>
                                                <div>
                                                    <a href="{{ link.iosLink }}">
                                                        <img src="../../../assets/img/home/v4/Image/appstort.png" alt=""
                                                            style="margin-top: 5px;">
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-6">
                                        <div class="row">
                                            <div class="col-footer">
                                                <span class="title">{{ 'media' | translate }}</span>
                                                <div class="list-img">
                                                    <img src="../../../assets/img/home/v4/Image/Social Icons.png"
                                                        alt="">
                                                    <img src="../../../assets/img/home/v4/Image/Social Icons (2).png"
                                                        alt="">
                                                    <img src="../../../assets/img/home/v4/Image/Social Icons (1).png"
                                                        alt="">
                                                </div>
                                                <div>
                                                    <img src="../../../assets/img/home/v4/Image/image 117.png" class="bct" alt=""
                                                        style="margin-top: 12px;">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
        <div class="copy-right">
            <div class="container">
                <div class="row">
                    <div class="col-md-12">
                        <p class="Reserved">© VNPT-IT 2020. All Rights Reserved.</p>
                    </div>
                    <!--  <div class="col-md-6">
                        <div class="right">
                            <p>Kênh truyền thông: </p>
                            <img src="../../../assets/img/home/v4/icon/facebook_icon.png" alt="">
                            <img src="../../../assets/img/home/v4/icon/youtube_icon.png" alt="">
                            <img src="../../../assets/img/home/v4/icon/linkedin_icon.png" alt="">
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </section>
</div>