import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { GlobalConfig } from '../common/global-config/global-config';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AasNonAuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {

    let redirectUrl = next.queryParamMap.get('redirectUrl') || '';
    // if (GlobalConfig.ORIGIN_SERVICE.includes(location.hostname)) {
      
    // } else {
    //   this.router.navigate(['/login']);
    //   return false;
    // }

    if (localStorage.getItem('token') && localStorage.getItem('token') !== undefined && localStorage.getItem('token') !== null) {
      var urlSplit = redirectUrl.split('/');
      urlSplit.splice(0, 3);
      redirectUrl !== '' ? this.router.navigateByUrl(urlSplit.join('/')) : this.checkRoleAdmin ? this.router.navigate(['/console/report-customer']) : this.router.navigate(['/app/console']);
      return false;
    } else {
      return true;
    }
  }

  get checkRoleAdmin() {
    const token = this.authService.getToken();
    const token_decode = this.authService.jwtDecode(token);
    const scope = ["AASADMIN"];

    let ok = false;

    token_decode.scope.forEach((item) => {
      if (scope.includes(item)) {
        ok = true;
      }
    })

    return ok;
  }

}
