import { Component, OnInit,ViewChild,ElementRef,Directive } from '@angular/core';
import { Select2OptionData } from 'ng-select2';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { Options, Select2AjaxOptions } from 'select2';
import { Config } from 'src/app/config';
import { AuthService } from 'src/app/utils/services/auth.service';
import { ValidateMessage, AasValidateMessage , ServiceMsg} from 'src/app/utils/common/validate-msg-aas';
import { AasEinvoicesService } from 'src/app/utils/services/aas-network/aas-einvoices.service'
import { InfoSellerPartyResponse } from 'src/app/utils/models/aas-models/einvoices-manager/einvoices-info/einvoices-info-seller-response.model';
import { InfoSellerParty } from 'src/app/utils/models/aas-models/einvoices-manager/einvoices-info/einvoices-info-seller.model';
import { DateUtils } from 'src/app/utils/common/DateUtils';
import { SeriesItem } from 'src/app/utils/models/aas-models/einvoices-manager/einvoices-series-list/series-item.model';
import { ListSeriesResponse } from 'src/app/utils/models/aas-models/einvoices-manager/einvoices-series-list/series-list-response.model';
import { PaymentMethodItem } from 'src/app/utils/models/aas-models/einvoices-manager/einvoices-payment-method/payment-method-item.model';
import { ListPaymentMethodResponse } from 'src/app/utils/models/aas-models/einvoices-manager/einvoices-payment-method/payment-method-list-response.model';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ENVOICES_CREATE } from 'src/app/utils/common/constant-vaildate-msg';
import {  REGEXP_COST, REGEXP_EMAIL,REGEXP_MST,REGEXP_PHONE_NUMBER } from 'src/app/utils/common/regexp';
import { ActivatedRoute, Router } from '@angular/router';
import { ServicePath } from 'src/app/utils/common/constant-service-api';
declare var $: any;
declare var Cleave: any;

@Component({
  selector: 'app-service-envoices-create',
  templateUrl: './service-envoices-create.component.html',
  styleUrls: ['./service-envoices-create.component.scss']
})
export class ServiceEnvoicesCreateComponent implements OnInit {

  formInvoicesInfo: FormGroup;
  formCustomerInfo: FormGroup;
  formTotal: FormGroup;

  public options: Options;
  public optionsTemplate: Options;

  infoSellerPartyRes : InfoSellerPartyResponse;
  infoSellerParty : InfoSellerParty;

 

  public ajaxOptions: Select2AjaxOptions;
  validEnvoicesMsg: any = ENVOICES_CREATE.ENVOICES_INFO;
  validEnvoicesCusMsg: any = ENVOICES_CREATE.CUSTOMER_INFO;
  validEnvoicesTotalMsg: any = ENVOICES_CREATE.ENVOICES_TOTAL;

  public mauSoData: Observable<Array<Select2OptionData>>;
  public hinhThucThanhToanData: Observable<Array<Select2OptionData>>;

  tenSanPham : string[] = [];
  donViTinh : string[] = [];
  soLuong : Number[] = [];
  donGia : Number[] = [];
  thanhTien : Number[] = [];
  thue = 0;

  seriesItem : SeriesItem;
  listSeriesResponse : ListSeriesResponse

  paymentMethodItem : PaymentMethodItem;
  listPaymentMethodResponse: ListPaymentMethodResponse;
  public khachHangDaTa: Array<Select2OptionData> = [];


  documents: Array<any> = []
  isSubmit = false;

  constructor(
    private fb : FormBuilder,
    private authService: AuthService,
    private einvoicesService: AasEinvoicesService,
    private modalService: NgbModal,
    private router: ActivatedRoute,
    private route: Router,
  ) { }

  ngOnInit(): void {
  
    this.makeFormInvoicesInfo();
    this.makeFormCustomer();
    this.makeFormTotal();
    this.setupSelect2();
    this.getListSeries();
    this.getPaymentMethod();
    this.getInfoSaleParty()
  }
  // danh sách
  getListSeries(){
    this.einvoicesService.getListSeries().subscribe((res:any)=>{
      this.listSeriesResponse = new ListSeriesResponse(res)
      this.mauSoData = this.makeMauSoData();
    })
  }
  // đổ droplist hình thức thanh toán
  getPaymentMethod(){
    this.einvoicesService.getListPaymentMethod().subscribe((res:any)=>{
      this.listPaymentMethodResponse = new ListPaymentMethodResponse(res)
      this.hinhThucThanhToanData = this.makeHinhThucThanhToanData();
    })
  }
// đổ droplist mẫu số hóa đơn
  makeMauSoData(): Observable<Array<Select2OptionData>> {
    let data: Array<Select2OptionData> = [];
    if (this.listSeriesResponse.data && this.listSeriesResponse.data instanceof Array) {
      this.listSeriesResponse.data.forEach((item) => {
        data.push({ id: item.taxCode.toString(), text: item.serial });
      });
    }
    return Observable.create((obs) => {
      obs.next(data);
      obs.complete();
    });
  }
// đổ droplist hình thức thanh toán
  makeHinhThucThanhToanData(): Observable<Array<Select2OptionData>> {
    let data: Array<Select2OptionData> = [];
    if (this.listPaymentMethodResponse.data && this.listPaymentMethodResponse.data instanceof Array) {
      this.listPaymentMethodResponse.data.forEach((item) => {
        data.push({ id: item.key.toString(), text: item.name });
      });
    }
    return Observable.create((obs) => {
      obs.next(data);
      obs.complete();
    });
  }
// khởi tạo form thông tin hóa đơn
  makeFormInvoicesInfo(){
    var today = new Date();
    // var date = today.getDate()+'/'+(today.getMonth()+1)+'/'+today.getFullYear();
     var date = this.getFormattedDate(today)
    console.log(date, '==========getFormattedDate')
    this.formInvoicesInfo = this.fb.group({
      // tenHoaDon: ['', [Validators.required]],
      mauSo : ['', [Validators.required]],
      kyHieu: ['', [Validators.required]],
      ngayPhatHanh: [date || '']
    })
  }
  // get thời điển xuất hóa đơn
  getFormattedDate(date) {
    var year = date.getFullYear();
  
    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;
  
    var day = date.getDate().toString();
    day = day.length > 1 ? day : '0' + day;
    
    return day + '/' + month + '/' + year;
  }

  //khởi tạo form khách hàng
  makeFormCustomer(){
    this.formCustomerInfo  = this.fb.group({
      tenKhachHang: ['', [Validators.required]],
      tenDonVi : ['',[Validators.required]],
      diaChi: [''],
      maSoThue: ['',[Validators.pattern(REGEXP_MST)]],
      maKhachHang: ['', [Validators.required]],
      dienThoai: ['',[Validators.pattern(REGEXP_PHONE_NUMBER)]],
      email : ['', [Validators.pattern(REGEXP_EMAIL)]],
      tenNganHang: [''],
      soTaiKhoan: [''],
      hinhThucThanhToan : [''],
    })
  }

  // khởi tạo form tính tổng
  makeFormTotal(){
    this.formTotal = this.fb.group({
      thueSuat: ['',[Validators.required, Validators.pattern(REGEXP_COST)]],
      tienThue: [0,[Validators.required]],
      tongTien: ['',[Validators.required]],
      soTienChu: ['',[Validators.required]]
    })
  }
  // form tính tiền
  get thueSuat() { return this.formTotal.get('thueSuat'); }
  get tienThue() { return this.formTotal.get('tienThue'); }
  get tongTien() { return this.formTotal.get('tongTien'); }
  get soTienChu() { return this.formTotal.get('soTienChu'); }
  // form thông tin hóa đơn
  get tenHoaDon() { return this.formInvoicesInfo.get('tenHoaDon'); }
  get mauSo() { return this.formInvoicesInfo.get('mauSo'); }
  get kyHieu() { return this.formInvoicesInfo.get('kyHieu'); }
  get ngayPhatHanh() { return this.formInvoicesInfo.get('ngayPhatHanh'); }

  // form thông tin khách hàng
  get tenKhachHang() { return this.formCustomerInfo.get('tenKhachHang'); }
  get tenDonVi() { return this.formCustomerInfo.get('tenDonVi'); }
  get diaChi() { return this.formCustomerInfo.get('diaChi'); }
  get maSoThue() { return this.formCustomerInfo.get('maSoThue'); }
  get maKhachHang() { return this.formCustomerInfo.get('maKhachHang'); }
  get dienThoai() { return this.formCustomerInfo.get('dienThoai'); }
  get email() { return this.formCustomerInfo.get('email'); }
  get tenNganHang() { return this.formCustomerInfo.get('tenNganHang'); }
  get soTaiKhoan() { return this.formCustomerInfo.get('soTaiKhoan'); }
  // get loaiTienTe() { return this.formCustomerInfo.get('loaiTienTe'); }
  get hinhThucThanhToan() { return this.formCustomerInfo.get('hinhThucThanhToan'); }

  // get thông tin tổ chức bán hàng
  getInfoSaleParty(){
    this.einvoicesService.getInfoSeller(this.authService.partyId).subscribe(res=>{
      this.infoSellerPartyRes = new InfoSellerPartyResponse(res);
      this.infoSellerParty = this.infoSellerPartyRes.object;

    })

  }
   //MARK: SETUP SELECT2
   setupSelect2() {
    this.options = {
      dropdownAutoWidth: true,
      width: '100%',
      closeOnSelect: true,
      language: "vi"
    };

    const self = this;
    this.ajaxOptions = {
      url: Config.API_URL + ServicePath.MANAGEMENT_SERVICE + '/parties/customer-search',
      dataType: 'json',
      type: "GET",
      delay: 250,
      cache: false,
      data: (params: any) => {
        console.log("AA", params);
        return {
          string: params.term
        }
      },
      // processData: false,
      beforeSend: function (xhr) {
        xhr.setRequestHeader('Authorization', `Bearer ${self.authService.getToken()}`);
      },
      processResults: (data: any, params: any) => {
        console.log('data: ', data);
        return {
          results: $.map(data.object, function (item) {
            console.log("item: ", item);
            return {
              id: item.tenDaiDien && item.tenDaiDien !== '' ? item.tenDaiDien : '',
              text: item.tenDaiDien && item.tenDaiDien !== '' ? item.tenDaiDien : '',
              additional: {
                partyId: item.userId,
                email: item.email,
                name:  item.tenToChuc && item.tenToChuc !== '' ? item.tenToChuc : item.tenDaiDien || '',
                type_customer: item.partyType === 'CONSUMER' ? 'Cá nhân' : 'Doanh nghiệp',
                type_cutomer_label: item.partyType,
                sgt: item.maSoThue || '',
              }
              
            };
          })
        };
      },
    };

    this.optionsTemplate = {
      width: '100%',
      dropdownAutoWidth: true,
      templateResult: this.templateResult,
      templateSelection: this.templateSelection,
      closeOnSelect: true,
      language: "vi",
      tags: true,
      createTag: function (params) {
        return {
          id: params.term,
          text: params.term,
          newOption: true
        }
      },
      ajax: this.ajaxOptions
    };

  }

   // Đổ dữ liệu vào droplist 
   public templateResult = (state: Select2OptionData): any => {
    if (!state.id || !state.additional) {
      return state.text;
    }

    let el =
      '<div class="">' +
      ' <div class="select-customer">' +
      '  <span>' + state.text + '</span><br>' +
      '  <span class="select-name-cutomner">' + state.additional.email + '</span><br>' +
      '  <span class="badge badge-light-new p-2">' + state.additional.type_customer + '</span>' +
      '  <span class="badge badge-light-new p-2">' + state.additional.sgt + '</span>' +
      '  <hr  class="mt-2 mb-0" >' +
      ' </div>' +
      '</div>';

    return $(el);
  }

  // function for selection template
  public templateSelection = (state: Select2OptionData): any => {
    if (!state.id) {
      return state.text;
    }
    
    if(state.additional ){
      this.tenDonVi.setValue(state.additional.name);  
      this.maSoThue.setValue(state.additional.sgt);  
      this.email.setValue(state.additional.email)
    }
    

    return $('<span>' + state.text + '</span>');
  }

  

  changedKhachHang(event) {
    console.log(event);

  }
  // xóa 1 dòng trong bảng
  /**
   * Remove document
   * @param index
   * @param item
   */
  removeDocument( i,item) {
    this.donGia.splice(i,1);
    this.thanhTien.splice(i,1);
    this.chageThueSuat(this.thue)
    this.documents = this.documents.filter((document) => {
      return document !== item
    })
  }
  addColumnToTable() {
    this.documents.push({
    });
  }
  // format tiền
  convertCurency(value: any) {
    let curency: any
    value = value.replace(/,/g, '')
    curency = value.replace(/VNĐ/g, '')
    curency = Math.abs(curency);
    return curency;
  }

  // get tên sản phẩm
  changeName(i,value){
    this.tenSanPham[i] = value? value :0;
  }
  // get đơn vị tính
  changeDonViTinh(i,value){
    this.donViTinh[i] = value? value :0;
  }
  // get giá trị số lượng
  changeSoluong(i,value){
    
    this.soLuong[i] = value? Number(value):0;
  }
  // get giá trị đơn giá
  changeDonGia(i,value){
    new Cleave(`#donGiaValue-${i}`, {
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
    })
    value = this.convertCurency(value)
    this.donGia[i] = value? Number(value) :0;
  }
  // get giá trị tiền covert về format
  changeThanhTien(i,value){
    new Cleave(`#thanhTienValue-${i}`, {
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
    })
    value = this.convertCurency(value)
    this.thanhTien[i] = value? Number(value) :0;
    this.chageThueSuat(this.thue)
  }
  // get giá trị của thuế
  chageThueSuat(value){
    this.thue = value? Number(value) : 0;
    let tienThue = 0;
    let tongTienSp = 0;
    let tongTienThanhToan = 0;
    let tienChu = "";
    for( let i = 0; i < this.thanhTien.length ; i++){
      tongTienSp =tongTienSp + Number(this.thanhTien[i]) 
    }
    tienThue = Math.ceil(tongTienSp*this.thue/100);
    this.tienThue.setValue(tienThue);
    new Cleave('#tienThueValue', {
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
    })


    tongTienThanhToan =  Math.ceil(tongTienSp + tienThue);
    this.tongTien.setValue(tongTienThanhToan);
    new Cleave('#tongTienValue', {
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
    })

  
    tienChu = this.readMoney(tongTienThanhToan.toString());
    this.soTienChu.setValue(tienChu)

  }

  // cover từ tiền số sang tiền chũ
  readGroup(group){
   let readDigit: string[] = [" Không", " Một", " Hai", " Ba", " Bốn", " Năm", " Sáu", " Bảy", " Tám", " Chín" ];
    let temp = "";
    if (group == "000") return "";
    //read number hundreds
    temp = readDigit[parseInt(group.substring(0,1))] + " Trăm";
    //read number tens
    if (group.substring(1,2) === "0")
        if (group.substring(2,3)=== "0") return temp;
        else
        {
            temp += " Lẻ" + readDigit[parseInt(group.substring(2,3))];
            return temp;
        }
    else
        temp += readDigit[parseInt(group.substring(1,2))] + " Mươi";
    //read number

    if (group.substring(2,3) == "5") temp += " Lăm";
    else if (group.substring(2,3) != "0") temp += readDigit[parseInt(group.substring(2,3))];
    return temp;
  }

  readMoney( num){
    if((num==null)||(num === "")) return "";
    let temp = "";

    //length <= 18
    while (num.length < 18)
    {
      num = "0" + num;
    }

    let g1 = num.substring(0, 3);
    let g2 = num.substring(3, 6);
    let g3 = num.substring(6, 9);
    let g4 = num.substring(9, 12);
    let g5 = num.substring(12,15);
    let g6 = num.substring(15,18);

    //read group1 ---------------------
    if ( g1 !== "000"){//!g1.equals("000")
        temp = this.readGroup(g1);
        temp += " Triệu";
    }
    //read group2-----------------------
    if (g2 !== "000"){
        temp += this.readGroup(g2);
        temp += " Nghìn";
    }
    //read group3 ---------------------
    if (g3 !== "000"){
        temp += this.readGroup(g3);
        temp += " Tỷ";
    } else if(temp != ""){
      //!"".equals(temp)
      temp += " Tỷ";
    }
    
    //read group2-----------------------
    if (g4 !== "000"){
        temp += this.readGroup(g4);
        temp += " Triệu";
    }
    //---------------------------------
    if (g5 !== "000"){
        temp += this.readGroup(g5);
        temp += " Nghìn";
    }
    //-----------------------------------

    temp = temp + this.readGroup(g6);
    //---------------------------------
    // Refine
    temp = temp.replace(/Một Mươi/g, 'Mười');
    temp = temp.trim();
    temp = temp.replace(/Không Trăm/g, "");
    // if (temp.indexOf("Không Trăm") == 0) temp = temp.substring(10);
    temp = temp.trim();
    temp = temp.replace(/Mười Không/g, "Mười");
    temp = temp.trim();
    temp = temp.replace(/Mươi Không/g, "Mươi");
    temp = temp.trim();
    if (temp.indexOf("Lẻ")==0) temp = temp.substring(2);
    temp = temp.trim();
    temp = temp.replace(/Mươi Một/g, "Mươi Mốt");
    temp = temp.trim();
    
    //Change Case
    return temp.substring(0, 1).toUpperCase() + temp.substring(1).toLowerCase() + " đồng chẵn.";
  }

  // reset form sau khi gửi
  resetForm(){
    this.makeFormCustomer();
    this.makeFormInvoicesInfo();
    this.makeFormTotal();
    this.documents = [];
  }

  // save đata của bảng đổ vào file json
  saveData(){
    this.isSubmit = true;
    let products = [];
    let tongTienTruocThue = 0
    for(let i = 0 ; i < this.tenSanPham.length ; i++){
      if(this.tenSanPham[i] != ''&& this.tenSanPham[i]){
        let tienSauThue = 0
        tongTienTruocThue = Math.ceil(tongTienTruocThue + Number(this.thanhTien[i])) 
        tienSauThue = Math.ceil(Number(this.thanhTien[i])*Number(this.thueSuat.value)/100);
        products.push(
          {
          "product":{
          "prodName": this.tenSanPham[i] || '',
          "prodUnit": this.donViTinh[i] || '',
          "prodQuantity": this.soLuong[i] || '',
          "prodPrice": this.donGia[i] || '',
          "amount":  Number(this.thanhTien[i]),
        }
       }
      )
      }
    }

    let dataCustomerInfo = this.formCustomerInfo.value;
    let dataInvoicesInfo = this.formInvoicesInfo.value;
    let dataTotal = this.formTotal.value;
    let param = {
      "cusCode": dataCustomerInfo.maKhachHang || "",
      "cusName": dataCustomerInfo.tenDonVi  || "",
      "cusAddress": dataCustomerInfo.diaChi || "",
      "cusPhone": dataCustomerInfo.dienThoai || "",
      "cusTaxCode": dataCustomerInfo.maSoThue || "",
      "paymentMethod": dataCustomerInfo.hinhThucThanhToan || "",
      "emailDeliver": dataCustomerInfo.email || "",
      "total": tongTienTruocThue || "",
      "vatrate": Number(dataTotal.thueSuat) || "",
      "vatamount": dataTotal.tienThue || "",
      "amount": dataTotal.tongTien || "",
      "amountInWords": dataTotal.soTienChu || "",
      "arisingDate": dataInvoicesInfo.ngayPhatHanh,
      "buyer": dataCustomerInfo.tenKhachHang || "",
      "products": products || [],
  }

  console.log(param, '==========param')
  let taxCode = dataInvoicesInfo.mauSo
  // if(this.formCustomerInfo.valid && this.formInvoicesInfo.valid && this.formTotal.valid){
    this.postEinvoices(param, taxCode)
  // }
  
  }

  // rap api post thông tin hóa đơn
  postEinvoices(data, taxCode){
    this.alert('Bạn có chắc chắn muốn xuất hóa đơn này', '', true, '', 'Đồng ý', () =>{
      this.einvoicesService.postCreateEinvoice(data, taxCode).subscribe((res:any)=>{
        this.isSubmit = false;
        this.alert('Xuất hóa đơn thành công!', 'success', false, '', 'OK', () =>{
          this.resetForm();
          this.route.navigate(['/app/console/einvoices/list']);
          
        });
      },err => {
        this.handleError(err, err)
      })
    })
  }

  
  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], 'error');
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, 'error');
    } else {
      this.alert('Lỗi không xác định! Vui lòng thử lại', 'error');
    }
    }
}
