import { ConstLocalStorage } from "./constant";

export class ContractEncode {
    // {
    //     "<user+id>": {
    //         "enc_list": [
    //             "<contract_id>"
    //         ]
    //     }
    // }

    userId: string = localStorage.getItem(ConstLocalStorage.USERID);

    public addEncodeContract(contractId) {
        var db = JSON.parse(localStorage.getItem(ConstLocalStorage.ENCODE) || "{}") || {};
        if (db[this.userId]) {
            if (db[this.userId].enc_list) {
                if (db[this.userId].enc_list instanceof Array && db[this.userId].enc_list.length > 0) {
                    db[this.userId].enc_list.push(contractId);
                } else {
                    db[this.userId].enc_list = [contractId];
                }
            } else {
                db[this.userId]["enc_list"] = [contractId];
            }
        } else {
            db[this.userId] = new Object();
            db[this.userId]["enc_list"] = [contractId];
        }

        localStorage.setItem(ConstLocalStorage.ENCODE, JSON.stringify(db));
    }

    public getEncodeListByUserId(userId) {
        var db = JSON.parse(localStorage.getItem(ConstLocalStorage.ENCODE) || "{}") as Object || {};
        if (db[userId]) {
            if (db[userId].enc_list) {
                if (db[userId].enc_list instanceof Array && db[userId].enc_list.length > 0) {
                    return db[userId].enc_list;
                } else {
                    return [];
                }
            } else {
                return [];
            }
        } else {
            return [];
        }
    }

    public hasDecode(contractId) {
        var db = this.getEncodeListByUserId(this.userId) as Array<any>;
        if (!contractId) return false;
        return db.includes(contractId);
    }
}