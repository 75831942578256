<div #content class="modal-header border-0">
  <!-- <h5 class="modal-title text-lg text-center">Thông báo</h5> -->
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true"><span class="material-icons">
      cancel
      </span></span>
  </button>
</div>
<div class="modal-body text-center py-0">
  <img *ngIf="notification && typeAlert !== ''" class="mb-2" src="{{ imgAlert[typeAlert] }}" width="40" height="40">
  <p [innerHTML]="message"></p>
</div>
<div class="modal-footer border-0 d-flex justify-content-center">
  <button *ngIf="notification; else notNoti" type="button" class="btn btn-primary" ngbAutofocus (click)="activeModal.close('Close click'); action1()">OK</button>
  <ng-template #notNoti>
    <button type="button" class="btn btn-outline-primary" (click)="activeModal.close('Close click'); action2()">Huỷ</button>
    <button type="button" class="btn btn-primary ml-3" ngbAutofocus (click)="activeModal.close('Close click'); action1()">Đồng ý</button>
  </ng-template>
</div>
