<app-spinner></app-spinner>
<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-sm-6">
        <h1 class="m-0 text-dark"><label>Danh sách gói dịch vụ</label></h1>
      </div>
      <!-- /.col -->
      <div class="col-sm-6 pr-4">
        <button class="btn btn-primary float-sm-right d-inline-flex" routerLinkActive="router-link-active"
          [routerLink]="['/console/package-manager/add']"><em class="material-icons mr-2">add</em>
          <!-- [routerLink]="['/portal/customers/create']" -->
          Thêm mới</button>
      </div>
      <!-- /.col -->
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>

<section class="content">
  <div class="container-fluid">
    <div class="card shadow-none mb-0">
      <div class="card-body">
        <div class="row">
          <div class="col-lg-6 col-md-6">
            <form class="input-group border rounded mb-3">
              <div class="input-group-prepend border-0">
                <button class="btn d-inline-flex pr-2 pl-2" type="submit"><span
                    class="material-icons">search</span></button>
              </div>
              <input [(ngModel)]="searchString" [ngModelOptions]="{standalone: true}" type="text"
                class="form-control border-0" placeholder="Tìm kiếm theo tên gói dịch vụ"
                aria-describedby="basic-addon1" (keydown.enter)="searchWithFilter()">
            </form>
          </div>
        </div>
        <hr>
        <form [formGroup]="formFilter">
          <div class="row">
            <div class="col-md-3">
              <div class="form-group pb-0 mb-0">
                <label for="status">Loại khách hàng</label>
                <select class="form-control" formControlName="userType" (change)="changeUserType($event)">
                  <option value="">Tất cả</option>
                  <option value="CONSUMER">Khách hàng cá nhân</option>
                  <option value="BUSINESS">Khách hàng tổ chức</option>
                  <option value="ENTERPRISE">Khách hàng tích hợp</option>
                </select>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group pb-0 mb-0">
                <label for="status">Loại gói dịch vụ</label>
                <select class="form-control" formControlName="packageType" (change)="changePackageType($event)">
                  <option value="" selected>Tất cả</option>
                  <option value="TRIAL_TYPE">Gói thử nghiệm</option>
                  <option value="NORMAL_TYPE">Gói chính thức</option>
                  <option value="EXTEND_TYPE">Gói hạ tầng mở rộng</option>
                </select>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group pb-0 mb-0">
                <label for="status">Trạng thái</label>
                <select class="form-control" formControlName="status" (change)="changeStatus($event)">
                  <option value="" selected>Tất cả</option>
                  <option value="ENABLED">Đang hoạt động</option>
                  <option value="DISABLED">Ngưng hoạt động</option>
                </select>
              </div>
            </div>
            <div class="mt-auto ml-auto ">
              <button class="btn btn-outline-primary  mr-3" (click)="cleanFilter()">
                Đặt lại
              </button>
              <button class="btn btn-primary" (click)="searchWithFilter()">
                Áp dụng
              </button>
            </div>
          </div>
        </form>
      </div>
      <div class="table-responsive">
        <table class="table table-sort-col mb-0">
          <thead>
            <tr class="bg-tb-header">
              <th>STT</th>
              <th (click)="sortCol('name')">
                <span class="d-flex align-self-center align-items-center">
                  Tên gói dịch vụ
                  <span *ngIf="sortColConfig['name'] == null" class="material-icons ml-auto">
                    unfold_more
                  </span>
                  <span *ngIf="sortColConfig['name'] == true" class="material-icons ml-auto">
                    expand_less
                  </span>
                  <span *ngIf="sortColConfig['name'] == false" class="material-icons ml-auto">
                    expand_more
                  </span>
                </span>
              </th>
              <th (click)="sortCol('description')">
                <span class="d-flex align-self-center align-items-center">
                  Mô tả
                  <span *ngIf="sortColConfig['description'] == null" class="material-icons ml-auto">
                    unfold_more
                  </span>
                  <span *ngIf="sortColConfig['description'] == true" class="material-icons ml-auto">
                    expand_less
                  </span>
                  <span *ngIf="sortColConfig['description'] == false" class="material-icons ml-auto">
                    expand_more
                  </span>
                </span>
              </th>
              <th (click)="sortCol('userType')">
                <span class="d-flex align-self-center align-items-center">
                  Loại khách hàng
                  <span *ngIf="sortColConfig['userType'] == null" class="material-icons ml-auto">
                    unfold_more
                  </span>
                  <span *ngIf="sortColConfig['userType'] == true" class="material-icons ml-auto">
                    expand_less
                  </span>
                  <span *ngIf="sortColConfig['userType'] == false" class="material-icons ml-auto">
                    expand_more
                  </span>
                </span>
              </th>
              <th (click)="sortCol('packageType')">
                <span class="d-flex align-self-center align-items-center">
                  Loại gói dịch vụ
                  <span *ngIf="sortColConfig['packageType'] == null" class="material-icons ml-auto">
                    unfold_more
                  </span>
                  <span *ngIf="sortColConfig['packageType'] == true" class="material-icons ml-auto">
                    expand_less
                  </span>
                  <span *ngIf="sortColConfig['packageType'] == false" class="material-icons ml-auto">
                    expand_more
                  </span>
                </span>
              </th>
              <th (click)="sortCol('status')">
                <span class="d-flex align-self-center align-items-center">
                  Trạng thái
                  <span *ngIf="sortColConfig['status'] == null" class="material-icons ml-auto">
                    unfold_more
                  </span>
                  <span *ngIf="sortColConfig['status'] == true" class="material-icons ml-auto">
                    expand_less
                  </span>
                  <span *ngIf="sortColConfig['status'] == false" class="material-icons ml-auto">
                    expand_more
                  </span>
                </span>
              </th>
              <th>Thao tác</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of listPackages; let i = index">
              <td>
                {{ ((page - 1) * maxSize) + i + 1 }}
              </td>
              <td>
                <a class="text-primary text-decoration-underline cursor-pointer"
                routerLinkActive="router-link-active" [routerLink]="['/console/package-manager/detail/',item.packInfoId]">
                  {{ item.name || 'NULL' }}
                </a>
              </td>
              <td>
                {{ item.description }}
              </td>
              <td>
                {{ getUserTypeLabel(item.userType) }}
              </td>
              <td>
                {{ getPackageTypeLabel(item.packageType) }}
              </td>
              <td>
                <span class="badge {{ getStatusLabelAndStyle(item.status)[0] }} p-2">{{
                  getStatusLabelAndStyle(item.status)[1] }}</span>
              </td>
              <td>
                <div class="dropdown">
                  <a class="btn btn-dropdown dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <em class="material-icons">more_vert</em>
                  </a>

                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                    <!-- [routerLink]="['/console/licenses-manager/detail/', item.licenseId]" -->
                    <a class="dropdown-item nav-material" style="cursor: pointer;"
                    routerLinkActive="router-link-active" [routerLink]="['/console/package-manager/detail/',item.packInfoId]">
                      <em class="material-icons">info</em>
                      Thông tin chi tiết</a>
                    <a class="dropdown-item nav-material" style="cursor: pointer;"
                    [routerLink]="['/console/package-manager/edit/', item.packInfoId]"
                    routerLinkActive="router-link-active">
                      <em class="material-icons">edit</em>
                      Cập nhật</a>
                    <a *ngIf="getCurrentStatus(item.status)" class="dropdown-item nav-material"
                      style="cursor: pointer;" (click)="putStatus(item.packInfoId, 'DISABLED')">
                      <em class="material-icons">https</em>
                      Ngưng hoạt động</a>
                    <a *ngIf="!getCurrentStatus(item.status)" class="dropdown-item nav-material"
                      style="cursor: pointer;" (click)="putStatus(item.packInfoId, 'ENABLED')">
                      <em class="material-icons">https</em>
                      Đang hoạt động</a>
                    <a class="dropdown-item nav-material" style="cursor: pointer;"
                    (click)="removePackage(item.packInfoId, item.status)">
                      <em class="material-icons">delete</em>
                      Xóa</a>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="bg-tb-header p-3">
        <div class="row align-items-center">
          <span *ngIf="totalElement == 0" class="col-md">Không tìm thấy kết quả</span>
          <span *ngIf="totalElement > 0" class="col-md">Hiển thị 1 đến {{ listPackages.length }} trong tổng số
            {{totalElement}} kết quả</span>
          <div class="col-md">
            <div class="d-flex justify-content-end">
              <div class="d-flex justify-content-end align-items-center">
                <span class="mr-2">Số dòng hiển thị:</span>
                <ng-select class="no-bg no-border" [(ngModel)]="maxSize" [searchable]="false" [clearable]="false"
                  placeholder="..." (change)="getListPackages()">
                  <ng-option [value]="10">10</ng-option>
                  <ng-option [value]="20">20</ng-option>
                  <ng-option [value]="30">30</ng-option>
                </ng-select>
              </div>
              <div class="pagination pages no-border-pagination ml-2">
                <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="5" [rotate]="true"
                  [ellipses]="false" [pageSize]="maxSize" (pageChange)="getListPackages()" [boundaryLinks]="true"
                  aria-label="Default pagination">
                  <ng-template ngbPaginationFirst>Đầu</ng-template>
                  <ng-template ngbPaginationLast>Cuối</ng-template>
                  <ng-template ngbPaginationPrevious>&laquo;</ng-template>
                  <ng-template ngbPaginationNext>&raquo;</ng-template>
                  <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                </ngb-pagination>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>

<section class="table">
  <div class="container-fluid">

  </div>


</section>