import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { PartListItem } from 'src/app/utils/models/part/part-list-item.model';
import { PartService } from 'src/app/utils/services/aas-network/part/part.service';

@Component({
  selector: 'app-part-list',
  templateUrl: './part-list.component.html',
  styleUrls: ['./part-list.component.scss']
})
export class PartListComponent implements OnInit {

  parts: PartListItem[] = [];
  page = 1;
  maxSize = 10;
  propertiesSort = "createdDate";
  sort = "DESC";
  totalElement = 0;

  keySearch: string = "";
  status: string = "";

  maxSizeDropDown = [10, 20, 30, 40];

  constructor(
    private router: Router,
    private partService: PartService,
    public translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.loadPage(1);
  }

  getClassStatus(status) {
    var st = `${status}`;

    if (st === "1") return "badge-danger-new";
    if (st === "0") return "badge-success-new";
    return "";
  }

  getTextStatus(status) {
    var st = `${status}`;

    if (st === "1") return this.translate.instant('status_stopped');
    if (st === "0") return this.translate.instant('status_running');
    return "";
  }

  cleanFilter() {
    this.keySearch = "";
    this.status = "";
    this.loadPage(1);
  }
  
  searchWithFilter() {
    this.loadPage(1);
  }

  enterSearch() {
    console.log("enterSearch");
    this.loadPage(1);
  }

  changeMaxSize(maxSize) {
    this.maxSize = maxSize;
    this.loadPage(1);
  }

  loadPage(page) {
    if (page > 0) {
      this.page = page;
      this.getParts(this.page, this.maxSize, this.propertiesSort, this.sort, this.status, this.keySearch)
    }
  }

  detailPart(partyId) {
    this.router.navigate([ConstantUrl.PART_DETAIL, partyId]);
  }

  editPart(partyId) {
    this.router.navigate([ConstantUrl.PART_EDIT, partyId]);
  }

  addPart() {
    this.router.navigate([ConstantUrl.PART_ADD]);
  }

  getParts(page, maxSize, propertiesSort, sort, status, keySearch) {
    this.partService.getParts(page, maxSize, propertiesSort, sort, status, keySearch).subscribe((res: any) => {
      this.parts = [];
      if (res.object && res.object.data && res.object.data instanceof Array) {
        res.object.data.forEach(item => {
          this.parts.push(new PartListItem(item));
        });
      }

      this.totalElement = res?.object?.totalElement || 0;
    })
  }
}
