<!-- <div class="landingpage">
    <div class="register">
        <section class="register-ss1">
            <a href="" class="btn-backhome">
                <img src="icon/backicon.png" alt="">
            </a>
            <img src="../../../assets/img/home/v4/Image/Logo eContract_change color.png" alt=""
                class="img-logo-ipadpro">
            <img src="../../../assets/img/home/v4/Image/LOGO261.png" alt="" class="img-logo d-block d-sm-none">
            <div class="col-md-12">
                <div class="row veri column-reverse-mobi">
                    <div class="col-md-12 col-xl-6">
                        <div class="row">
                            <div class="content-left">
                                <div class="body">
                                    <span class="logo block">
                                        MỘT SẢN PHẨM CỦA TẬP ĐOÀN
                                        <img src="../../../assets/img/home/v4/Image/logo vnpt.png" alt="">
                                    </span>
                                    <p class="title">
                                        Hợp đồng điện tử<br />
                                        VNPT eContract
                                    </p>
                                    <span class="dec">
                                        Cung cấp dịch vụ hợp đồng điện tử cho phép các bên giao kết các loại hợp đồng
                                        bằng
                                        phương tiện điện tử.
                                    </span>
                                </div>
                                <img src="../../../assets/img/home/v4/Image/Group 177362.png" alt=""
                                    class="d-none d-xl-block imgbg">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 col-xl-6">
                        <div class="row">
                            <div class="content-right">
                                <div class="body">
                                    <img src="../../../assets/img/home/v4/Image/LOGO261.png" alt=""
                                        class="img-logo d-none d-xl-block">
                                    <h3 class="title">Xác nhận tài khoản</h3>
                                    <span class="dec">
                                        Vui lòng nhập mã OTP gồm 6 chữ số đã được gửi đến email của bạn
                                    </span>
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Nhập mã OTP">
                                    </div>
                                    <span class="footer">
                                        Bạn chưa nhận được OTP? <a href="">Gửi lại OTP</a>
                                    </span>
                                    <button type="button" class="btn_reg">Đăng ký</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div> -->



<app-spinner></app-spinner>
<div class="landingpage">
    <div class="register">

        <section class="register-ss1">
            <img src="assets/img/home/v4/Image/Logo eContract_change color.png" alt="" class="img-logo-ipadpro">
            <div class="content col-12 p-0">
                <div class="row swap-col">
                    <div class="col-auto-new p-0">
                        <div class="row">
                            <div class="col-12">
                                <div class="content-left">
                                    <div class="row">
                                        <div class="col-left-auto">
                                            <div class="body">
                                                <span class="logo block">
                                                    MỘT SẢN PHẨM CỦA TẬP ĐOÀN
                                                    <img src="../../../assets/img/home/v4/Image/logo vnpt.png" alt="">
                                                </span>
                                                <p class="title">
                                                    Hợp đồng điện tử<br />
                                                    VNPT eContract
                                                </p>
                                                <span class="dec">
                                                    Cung cấp dịch vụ hợp đồng điện tử cho phép các bên giao kết các loại
                                                    hợp đồng
                                                    bằng phương tiện điện tử.
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-left-auto">
                                            <img src="assets/img/home/v4/Image/Group 177362.png" alt=""
                                                class="d-xl-block imgbg">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-auto-new p-0">
                        <div class="content-right">
                            <div class="row justify-content-center">
                                <div class="col-10 col-md-6 col-xl-9">
                                    <div class="body">
                                        <img src="assets/img/home/v4/Image/LOGO261.png" alt="" class="img-logo">
                                        <h3 class="title">{{ 'RegisterBusiness_h_14' | translate }}</h3>
                                        <span class="note pb-4">
                                            {{ 'VerifyOTP_desc' | translate}}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-10 col-md-6 col-xl-9">
                                   
                                    <div class="login-form">

                                        <form [formGroup]="formOTP">
                                            <div class="form-group">
                                                <input formControlName="otp" type="text" class="form-control"
                                                    placeholder="{{ 'OtpForgotPassword_h_4' | translate }}" (input)="handleInputOTP($event)">
                                                <app-show-validate-errors [isSubmit]="isSubmit"
                                                    [errorMessages]="ServiceMsg.otp" [control]="otp"
                                                    [detail]="{ name: 'otp' }">
                                                </app-show-validate-errors>
                                            </div>
                                        </form>


                                        <h6 class="footer-otp">{{ 'OtpForgotPassword_h_1' | translate}} <a *ngIf="count === 0"
                                            (click)="resendOTP()"
                                            class="cursor-pointer text-primary text-decoration-underline">{{ 'OtpForgotPassword_h_2' | translate }}</a></h6>
                                    <span class="text-muted" *ngIf="count > 0">{{ 'OtpForgotPassword_h_3' | translate: {counter: counter$ | async} }}</span>


                                        <!-- <span class="footer" *ngIf="count === 0">
                                            Bạn chưa nhận được OTP? <a
                                                class="text-primary text-decoration-underline cursor-pointer"
                                                (click)="resendOTP()">Gửi lại OTP</a>
                                        </span>
                                        <span class="text-muted" *ngIf="count > 0">Có thể gửi lại sau
                                            {{counter$ | async}}s</span> -->
                                        <button type="button" class="btn_reg" [disabled]="enableBtnSubmit"
                                            (click)="verifyOTP()">{{ 'Register_title' | translate }}</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>

    <!-- Modal -->
    <div [ngClass]="{'show': success}" class="modal fade" backdrop="true" id="modal-customs" role="dialog" aria-labelledby="exampleModalLabel">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <img src="../../../../assets/img/home/v4/icon/Group 17760.png" alt="">
                     <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <h4 class="title block">{{ 'VerifyOTP_register_successful' | translate }}</h4>
                    <span class="content">
                        {{ 'VerifyOTP_congra' | translate }}
                    </span>
                    <a href="/app/login" class="btn-back">{{ 'signin' | translate }}</a>
                    <span class="notmail">
                        {{ 'VerifyOTP_no_email' | translate }}
                        <a href="#">{{ 'VerifyOTP_resend_email' | translate }}</a>
                    </span>
                </div>
               <!--  <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="button" class="btn btn-primary">Save changes</button>
                </div> -->
            </div>
        </div>
    </div>
</div>
<a href="" class="btn-backhome">
    <img src="assets/img/home/v4/icon/backicon.png" alt="">
</a>


