import { ConstantAlertType } from './../../../../../../utils/common/constant-alert-type';
import { Component, OnInit, AfterViewInit, Renderer2, ViewChild, ElementRef, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/utils/services/auth.service';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { AasContractService } from 'src/app/utils/services/aas-network/aas-contract.service';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { ContractListItem } from 'src/app/utils/models/aas-models/contract/contract-list/contract-list-item.model';
import { ContractListResponse } from 'src/app/utils/models/aas-models/contract/contract-list/contract-list-response.model';
import { ContractStatus, UserTypeConstant } from 'src/app/utils/common/constant';
import { DateUtils } from 'src/app/utils/common/DateUtils';
import { AasValidateMessage } from 'src/app/utils/common/validate-msg-aas';
import { Router } from '@angular/router';
import { ParamAddition } from 'src/app/utils/models/aas-models/api-manager/param/param.model';
import { REGEXP_DATE_INPUT } from 'src/app/utils/common/regexp';
import { ConstantAlertMsg, ConstantAlertBtn } from 'src/app/utils/common/constant-alert-msg';
import { ContractFileInfo } from 'src/app/utils/models/aas-models/contract/contract-file/contract-file.model';
import { EncodeService } from 'src/app/utils/common/encode-service';
import { ContractEncode } from 'src/app/utils/common/contract-encode';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { localize } from 'src/app/utils/localize/localize';
import { UtilsFunc } from 'src/app/utils/common/utils-func';
import { TranslateService } from '@ngx-translate/core';
import { ContractTypeService } from '../../../contract-type-management/services/contract-type.service';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';
import { LoadingService } from 'src/app/utils/services/loading.service';
import { ContractResponse } from 'src/app/utils/models/aas-models/contract/contract/contract-response.model';
import { ContractObject } from 'src/app/utils/models/aas-models/contract/contract/contract-object.model';
import { PartyJoinContract } from 'src/app/utils/models/aas-models/contract/party-join-contract/party-join-contract';
import { ControlAccount } from 'src/app/utils/common/control-account';
import { EmployeeService } from 'src/app/utils/services/aas-network/employee/employee.service';

declare var Cleave: any;
declare var $: any;
@Component({
  selector: 'app-service-contract-list-v2',
  templateUrl: './service-contract-list-v2.component.html',
  styleUrls: ['./service-contract-list-v2.component.scss']
})
export class ServiceContractListV2Component implements OnInit,AfterViewInit {
  @Input('by') _by: string = 'OWNER';
  @Input('isAccLevel2') _isAccLevel2: boolean = null;

  @ViewChild('fromDatePicker') _fromDate: ElementRef;
  @ViewChild('toDatePicker') _toDate: ElementRef;
  @ViewChild('customerTypePicker') _customerType: ElementRef;
  @ViewChild('statusPicker') _statusPicker: ElementRef;
  @ViewChild('contentCancel') contentCancel: ElementRef;
  @ViewChild('contentReturn') contentReturn: ElementRef;
  @ViewChild('updateContractFile') updateContractFile: ElementRef;

  formCancel: FormGroup;
  formReturn: FormGroup;
  validMsgCancel = {
    reason: {
      required: 'ServiceContractListV2_alert_1',
    }
  };
  isCancelSubmit: boolean = false;
  contractIdSelected: string =  '';

  partyId: string = this.auth.partyId;
  userId: string = this.auth.user?.userId;

  formSearch: FormGroup;
  formFilter: FormGroup;
  formAdvancedFilter: FormGroup;
  dateType = {
    CREATED_FROM: 'CREATED_FROM',
    CREATED_TO: 'CREATED_TO'
  };
  validateSearch = {
    fromDate: {
      pattern: "ServiceContractListV2_validateSearch_date_1",
      minDate: 'ServiceContractListV2_validateSearch_date_2'
    },
    toDate: {
      pattern: "ServiceContractListV2_validateSearch_date_1"
    }
  };
  optionsCleave = {
    date: true,
    delimiter: '/',
    datePattern: ['d', 'm', 'Y']
  };

  currentDate = new Date();
  contractStatus: string = 'ALL';

  listConfigColTable: Array<any> = [
    {
      label: "status",
      name: "status"
    },
    {
      label: "ServiceContractListV2_table_header_3",
      name: "soBenThamGia"
    },
    {
      label: "ServiceContractListV2_table_header_2",
      name: "tenHopDong"
    },
    {
      label: "ServiceContractListV2_table_header_4",
      name: "ngayChinhSua"
    },
    {
      label: "ServiceContractListV2_table_header_6",
      name: "soHopDong"
    }
  ];
  configColTable: any = {
    status: true,
    soBenThamGia: true,
    ngayChinhSua: true,
    tenHopDong: true,
    soHopDong: true
  }

  dataTableExpand: Array<any> = [];
  page: number = 1;
  maxSize: number = 10;
  sort: string = "DESC";
  propertiesSort: string = "modified";
  totalElement: number = 0;
  listContract: Array<ContractListItem> = [];
  currentStage = ContractStatus;

  partnerId: string = '';
  serviceId: string = '';
  customerType: string = "";

  customerTypeFilter: string = '';
  fromDateFilter: string = '';
  toDateFilter: string = '';
  saveKeySearch: string = '';
  isSubmit: boolean = false;
  ServiceMsg: any = AasValidateMessage.CONTRACT_MANAGER_FILTER;

  selectedAll: boolean = false;

  zIndex = 5;

  contractEncode = new ContractEncode();

  //Cap nhat file hop dong moi
  formUpdateContractFile: FormGroup;
  validMsgUpdateContractFile = {
    file: {
      required: "ServiceContractListV2_validMsgUpdateContractFile_1",
      type: 'ServiceContractListV2_validMsgUpdateContractFile_2',
      size: 'ServiceContractListV2_validMsgUpdateContractFile_3'
    }
  };
  modalUpdateContractFile = null;
  lbFieldContractFile: string = "ServiceContractListV2_lbFieldContractFile";
  isSubmitFormUpdateContractFile = false;
  fileContractNew: File = null;

  inputFileLabel: string = 'Chọn file';
  fileType: Array<string> = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
  fileName: string = '';

  sortColConfig = {
    title: null,
    partnersCount: null,
    modified: false,
    currentStage: null,
    contractNumber: null
  }

  /** Đồng ý từ chối thẩm định */
  formDenyDiscuss: FormGroup;
  isSubmitFormDenyDiscuss = false;
  validateMsgFormDenyDiscuss = {
    reason: {
      required: localize.reason_reject_required,
      maxlength: localize.max_length_string
    }
  }

  signTurnOptions: Array<object> = [
    {value: '', label: 'ServiceContractListV2_all'},
    {value: 'INTERNAL', label: 'ServiceContractListV2_sign_turn_internal'},
    {value: 'EXTERNAL', label: 'ServiceContractListV2_sign_turn_partner'}
  ];

  dropListTypeData: Observable<any>;
  typesLoading = false;

  changeType($event) {
  }
  checkValue: number = 4;
  processTypeId: number = 4;
  isDisabled: boolean = true;
  email: string = this.auth.user?.email;
  customDropList: Array<object> = [
    { userId: '', email: 'Tất cả' },
    { userId: this.userId, email: this.email },
  ];
  
  dropListUserData: Observable<any>;
  usersLoading = false;
  dropListEmployeePage = 1;
  endOfDropListEmployeePage = false;
  keySearchDropListEmployee = "";
  employeesInput$ = new Subject<string>();

  //checkbox add footer
  checkbox_addfooter: boolean = true;
  
  constructor(
    private fb: FormBuilder,
    public auth: AuthService,
    private aasAuth: AasAuthService,
    private aasContract: AasContractService,
    private modalService: NgbModal,
    private renderer2: Renderer2,
    private router: Router,
    private myAlert: AlertControl,
    private translate: TranslateService,
    private myLoader: LoadingService,
    private contractTypeService: ContractTypeService,
    public controlAcc: ControlAccount,
    private employeeService: EmployeeService
  ) {
    this.renderer2.listen('window', 'click', (e: Event) => {
      var all = document.getElementsByClassName('table-sticky-col2');
      for (var i = 0; i < all.length; i++) {
        all[i].removeAttribute('style');
      }
    });
  }

  ngOnInit(): void {
    this.makeFormSearch();
    this.makeFormFilter();
    this.makeFormCancel();
    this.makeFormReturn();
    this.makeFormUpdateContractFile();
    this.makeFormDenyDiscuss();
    this.makeFormAdvancedFilter();
    this.getSessionFilter();
    this.lazyLoadDropListType();
    this.lazyLoadDropListEmployee();
  }

  ngAfterViewInit(): void {
    this.makeAutoFormatDate();

    // this._fromDate.nativeElement.value = sessionStorage.getItem('aas-contract-from-date-' + this._by) || '';
    // this._toDate.nativeElement.value = sessionStorage.getItem('aas-contract-to-date-' + this._by) || '';
    // this._customerType.nativeElement.value = sessionStorage.getItem('aas-contract-customer-type-' + this._by) || '';
    // this._statusPicker.nativeElement.value = sessionStorage.getItem('aas-contract-status-' + this._by) || '';
  }

  // La khach hang doanh nghiep
  get isCompany(): boolean {
    let userType = this.auth.userInfo.value.userType;
    return userType === UserTypeConstant.BUSINESS || userType === UserTypeConstant.ENTERPRISE;
  }

  // La nhan su
  get isInternal(): boolean {
    let userType = this.auth.userInfo.value.userType;
    return userType === UserTypeConstant.INTERNAL
  }

  // La khach hang ca nhan
  get isConsumer(): boolean {
    let userType = this.auth.userInfo.value.userType;
    return userType === UserTypeConstant.CONSUMER;
  }

  reload() {
    this.loadData();
  }
  
  //MARK: khởi tạo form huỷ
  makeFormCancel() {
    this.formCancel = this.fb.group({
      reason: new FormControl('')
    });
  }

  get reason() { return this.formCancel.get('reason'); }

   //MARK: khởi tạo form trả lại
   makeFormReturn() {
    this.formReturn = this.fb.group({
      reasonReturn: new FormControl('')
    });
  }

  get reasonReturn() { return this.formReturn.get('reasonReturn'); }

  //MARK: Make input date format
  makeAutoFormatDate() {
  }

   // Hiển thị hợp đồng của khách hàng
   isShow = false
   showContract(item){
    item.isShow = !item.isShow;
    this.isShow= !this.isShow;
  }

  //MARK: GET session filter
  getSessionFilter() {
    // this.keySearch.setValue(sessionStorage.getItem('aas-contract-key-search-' + this._by) || '');
    // this.fromDate = sessionStorage.getItem('aas-contract-from-date-' + this._by) || '';
    // this.toDate = sessionStorage.getItem('aas-contract-to-date-' + this._by) || '';
    // this.customerType = sessionStorage.getItem('aas-contract-customer-type-' + this._by) || '';
    // this.status = sessionStorage.getItem('aas-contract-status-' + this._by) || '';
  }

  //MARK: Khởi tạo form search
  makeFormSearch() {
    this.formSearch = this.fb.group({
      search: new FormControl('')
    });
  }
  get keySearch() { return this.formSearch.get('search'); }

  /**
   * Khoi tao form filter
   */
  makeFormFilter() {
    this.formFilter = this.fb.group({
      customerType: new FormControl(''),
      toDate: new FormControl('', [Validators.pattern(REGEXP_DATE_INPUT)]),
      fromDate: new FormControl('', [Validators.pattern(REGEXP_DATE_INPUT)]),
      status: new FormControl(''),
      signTurn: new FormControl(''),
      typeId: new FormControl('')
    });
  }

  get fromDate() {
    return DateUtils.convertInputToFormat(this.formFilter.value.fromDate, DateUtils.format.API);
  }
  get toDate() {
    return DateUtils.convertInputToFormat(this.formFilter.value.toDate, DateUtils.format.API);
  }
  get status() {
    return this.formFilter.value.status;
  }

  get signTurn() {
    return this.formFilter.value.signTurn;
  }

  get typeId() {
    return this.formFilter.value.typeId;
  }

  get processUserId() {
    return this.formAdvancedFilter.value.processUserId;
  }

    /**
   * Thay doi ngay tren filter
   * @param value 
   * @param type 
   */
  changeDateFilter(event, type) {
    switch (type) {
      case this.dateType.CREATED_FROM: 
        this.formFilter.controls.fromDate.setValue(DateUtils.convertObjtoDate(event));
        break;
      case this.dateType.CREATED_TO:
        this.formFilter.controls.toDate.setValue(DateUtils.convertObjtoDate(event));
        break;
    }
  }

  //MARK: Chức năg tìm kiếm
  eventInputSearch(event) {

  }

  actSearch(event) {
    this.page = 1;
    // this.saveKeySearch = this.keySearch.value.trim();
    // sessionStorage.setItem('aas-contract-key-search-' + this._by, this.saveKeySearch);
    this.loadData();
  }

  //MARK: Chức năng lọc dữ liệu

  //MARK: Apply filter
  searchWithFilter() {
    this.isSubmit = true;

    if (this.formFilter.valid) {
      let dateFrom = this.formFilter.value.fromDate.length > 0 ? new Date(DateUtils.convertStringtoDateSearch(this.formFilter.value.fromDate)).getTime() : -1;
      let dateTo = this.formFilter.value.toDate.length > 0 ? new Date(DateUtils.convertStringtoDateSearch(this.formFilter.value.toDate)).getTime() : -1;
      // console.log('from => ', dateFrom, ' to => ', dateTo);
      if ((dateTo > 0 && dateFrom > 0 && dateFrom > dateTo)) {
        this.formFilter.controls.fromDate.setErrors({ minDate: true });
      }
    }

    if (this.formFilter.valid) {
      // sessionStorage.setItem('aas-contract-from-date-' + this._by, this.fromDate);
      // sessionStorage.setItem('aas-contract-to-date-' + this._by, this.toDate);
      // sessionStorage.setItem('aas-contract-status-' + this._by, this.status);
      // sessionStorage.setItem('aas-contract-customer-type-' + this._by, this.customerType);
      console.log(this.formFilter.value);
      if (this.signTurn != '') {
        this.formFilter.controls.status.setValue('LC_DRAFT_SUBMIT');
      }
      this.loadData();
    }
  }

  cleanFilter() {
    this.isSubmit = false;
    this._fromDate.nativeElement.value = '';
    this._toDate.nativeElement.value = '';
    this.formFilter.reset();
    this.formFilter.controls.customerType.setValue('');
    this.formFilter.controls.fromDate.setValue('');
    this.formFilter.controls.toDate.setValue('');
    this.formFilter.controls.status.setValue('');
    this.formFilter.controls.signTurn.setValue('');
    this.formFilter.controls.typeId.setValue('');
    this.formSearch.reset();
    this.cleanAdvancedFilter(); // reset form advanced filter
    this.searchWithFilter();
  }

  //MARK: Loadpage
  loadData(page = 1) {
    this.page = page;
    this.getListContact(
      this.userId,
      this.partnerId,
      this.serviceId,
      this.fromDate,
      this.toDate,
      this.status,
      this.keySearch.value || "",
      this.page,
      this.maxSize,
      this.sort,
      UtilsFunc.getPropertiesSort(this.sortColConfig),
      this.signTurn,
      this.typeId || "",
      this.processUserId || "",
      this.processTypeId
      );
  }

  hasDecode(contractId) {
    return this.contractEncode.hasDecode(contractId);
  } 

  turnOnEncode(contractId) {
    this.myAlert.showAlert(this.translate.instant('ServiceContractListV2_msg_1'), ConstantAlertType.BLANK, true, "", this.translate.instant('ServiceContractListV2_msg_2'), () => {
      this.contractEncode.addEncodeContract(contractId);
    }, "", this.translate.instant('btn_cancel'), null, this.translate.instant('ServiceContractListV2_msg_3'));
  }

  progressTableData(listContract: Array<ContractListItem>) {
    this.dataTableExpand = [];
    listContract.forEach((contract, index) => {
      let param: any = new Object(contract);
      param.stt = (this.page - 1) * this.maxSize + index + 1;
      param.isParent = true;
      param.isChild = false;
      param.isHeader = false;
      param.isBody = false;
      param.hidden = true;
      this.dataTableExpand.push(contract);

      if (contract.partners.length > 0) {
        //Them header phan expand
        this.dataTableExpand.push({
          "tr_1": "",
          "tr_2": "",
          "tr_3": "ServiceContractListV2_tr_3",
          "tr_4": "ServiceContractListV2_tr_4",
          "tr_5": "ServiceContractListV2_tr_5",
          "tr_6": "",
          "tr_7": "",
          "isParent": false,
          "isChild": true,
          "isHeader": true,
          "isBody": false,
          "hidden": true
        });

        //Them body phan expand
        for(let partner of contract.partners) {
          let param: any = new  Object(partner);

          param.isParent = false;
          param.isChild = true;
          param.isHeader = false;
          param.isBody = true;
          param.hidden = true;

          this.dataTableExpand.push(param);
        }
      }
    });
  }

  //MARK: Action cấu hình hiển thị các hình thức filter
  // actConfigFilter(e, field) {
  //   e.stopPropagation();
  //   if (this.configFilter[field] !== null && this.configFilter[field] !== undefined) {
  //     this.configFilter[field] = !this.configFilter[field];
  //   }
  // }

  //MARK: Action cấu hình hiển thị cột của bảng
  actConfigColTableView(e, field) {
    e.stopPropagation();
    if (this.configColTable[field] !== null && this.configColTable[field] !== undefined) {
      this.configColTable[field] = !this.configColTable[field];
    }
  }

  //MARK: Action dropdown từng dòng
  openDropdown(event) {
    console.log(event);
    // var all = document.getElementsByClassName('table-sticky-col2');
    // for (var i = 0; i < all.length; i++) {
    //   all[i].removeAttribute('style');
    // }

    event.path[3].setAttribute("style", "z-index: " + ++this.zIndex + ";");
    // event.path[3].children[1].classList.add('show');
  }

  //MARK: Chọn dòng
  selectRow(event, id) {
    if (event.toElement.classList.contains('checked')) {
      event.toElement.classList.remove('checked');
      this.selectedAll = false;
    } else {
      event.toElement.classList.add('checked');
    }
  }

  //MARK: Chọn tất cả các dòng
  selectAll(event) {
    this.selectedAll = !this.selectedAll;

    this.listContract.forEach((item, index) => {
      var el = document.getElementById('label-for-contract-' + item.contractId);
      this.selectedAll ? el.classList.add('checked') : el.classList.remove('checked');
    })
  }

  //Xem chi tiet
  goToDetailContract(id) {
    this.router.navigate(['/app/console/contract', id]);
  }

  //Ký hợp đồng
  goToSign(id) {
    this.router.navigate(['/app/console/contract', id], { queryParams: { 'action': 'SIGN' } })
  }

  //downloadFileContract
  getFileContract(id, status) {
    // if (status === 'LC_CONTRACT_VALID') {
    //   this.downloadFileContract(id, 'CONTRACT');
    // } else {
      // this.downloadFileContract(id, 'DRAFT');
    // }

    if (status === 'LC_CONTRACT_VALID') {
      // this.downloadFileContract(id, 'CONTRACT');
      // this.getContractFileInfo(id, 'CONTRACT', id);
      // this.getListSignatureByContract(id, 'CONTRACT');
      if (this.hasDecode(id)) {
        this.getContractFileInfo(id, 'CONTRACT', id);
      } else {
        this.myAlert.showAlert(this.translate.instant('ServiceContractListV2_msg_4'), ConstantAlertType.BLANK, true, "", this.translate.instant('ServiceContractListV2_msg_5'), () => {
          this.downloadEnCodeFile(id);
        }, "", this.translate.instant('btn_cancel'), null, this.translate.instant('ServiceContractListV2_msg_6'));
      }
    } else {
      // this.downloadFileContract(id, 'DRAFT');
      this.getContractFileInfo(id, 'DRAFT', id);
      // this.getListSignatureByContract(id, 'DRAFT');
    }
  }

  /**
   * Lay thogn tin file hop dong
   * @param id 
   * @param type 
   * @param fileKey 
   */
  getContractFileInfo(id, type, fileKey) {
    this.aasContract.getContractFileInfo(id, type, fileKey).subscribe((res: any) => {
      var contractFileInfo = new ContractFileInfo(res.object);
      var keySend = contractFileInfo.keySend;

      this.downloadContractFileByUrl(contractFileInfo.url, type, keySend, id);
    }, err => {
      console.log(err);
      this.myAlert.showErrorHandled(err);
    })
  }

  /**
   * Tai file hop dong tu url
   * @param url 
   */
  downloadContractFileByUrl(url, type, keySend, id) {
    this.aasContract.downloadContractFileByUrl(url).subscribe((res: any) => {
      const blob: any = new Blob([res]);

      if (keySend) {
        //Neu ton tai keySend
        //Tien hanh giai ma
        let secretKey = EncodeService.rsa.decrypt(this.auth.key, keySend);
        this.loadFile(secretKey, blob, id);
      } else {
        //Neu khong ton tai keySend
        //Tien hanh luu file file PDF
        var url = window.URL.createObjectURL(blob);
        var anchor = document.createElement("a");
        anchor.download = `${id}.pdf`;
        anchor.href = url;
        anchor.click();
      }
    }, err => {
      console.log(err);
      this.myAlert.showErrorHandled(err);
    })
  }

  /**
   * Giai ma file
   * @param secretKey 
   * @param fileEncoded 
   */
  loadFile(secretKey, fileEncoded, id) {
    var self = this;
    var reader = new FileReader();
    reader.onload = function () {
      var result: any = reader.result;
      var _base64DecodeRes = Buffer.from(result, 'base64');
      var iv = _base64DecodeRes.slice(0, 16);
      var dataEncrypt = _base64DecodeRes.slice(16, _base64DecodeRes.byteLength);

      // console.log(result, result.slice(0, 16));
      var typedArray = EncodeService.aes.decrypt(dataEncrypt, secretKey, iv);

      const bufferpdf = Buffer.from(typedArray);
      const blob: any = new Blob([bufferpdf], { type: 'application/pdf' });
      var url = window.URL.createObjectURL(blob);
      var anchor = document.createElement("a");
      anchor.download = `${id}.pdf`;
      anchor.href = url;
      anchor.click();
    }
    reader.readAsText(fileEncoded);
  }

  //MARK: CHUC NANG HUY HOP DONG
  //Show popup confirm OTP
  openModalCancel(content, contractId) {
    this.formCancel.controls.reason.setValue("")
    this.contractIdSelected = contractId;
    this.reason.setValue('');
    this.myAlert.showAlert('Bạn chắc chắn muốn hủy hợp đồng này?', '', true,'', 'Hủy hợp đồng',()=>{
      this.submitCancel()
    },'','Đóng',null)
    // this.modalService.open(content, {
    //   size: 'md',
    //   backdrop: 'static',
    //   keyboard: false
    // });
  }

  //Submit huy hop dong
  submitCancel() {
    this.isCancelSubmit = true;
    console.log(this.formCancel.value);
    let param = {
      "cancelReason": this.reason.value
    }
    this.postCancelContract(this.contractIdSelected, param);
    // if (this.formCancel.valid) {
    //   let param = {
    //     "cancelReason": this.reason.value
    //   }
    //   this.postCancelContract(this.contractIdSelected, param);
    // }
  }

 //MARK: CHUC NANG TRẢ LẠI HOP DONG
  //Show popup confirm OTP
  openModalReturn(content, contractId) {
    this.formReturn.controls.reasonReturn.setValue("")
    this.contractIdSelected = contractId;
    this.reason.setValue('');
    this.modalService.open(content, {
      size: 'md',
      backdrop: 'static',
      keyboard: false
    });
  }

  //Submit huy hop dong
  submitReturn() {
    this.isCancelSubmit = true;
    console.log(this.formReturn.value);
    if (this.formReturn.valid) {
      let param = {
        "cancelReason": this.formReturn.value.reasonReturn
      }
      this.postReturnContract(this.contractIdSelected, param);
    }
  }

  //Trả lại hợp đồng
  postReturnContract(contractId, param){
    this.myAlert.showAlert(this.translate.instant('ServiceContractListV2_msg_7'), '', true, '', this.translate.instant('btn_ok'), () => {
      this.aasContract.postReturnContract(contractId, param).subscribe(res=>{
        this.loadData();
        this.myAlert.showAlert(this.translate.instant('ServiceContractListV2_msg_8'), 'success', false, '', 'OK', () => {
          this.modalService.dismissAll();
        });
      }, err => {
        console.log(err);
        this.myAlert.showErrorHandled(err);
      })
    });
  }

   // Xóa hợp đồng
   deleteContract(contractId){
    this.myAlert.showAlert(this.translate.instant('ServiceContractListV2_msg_9'), '', true, '', this.translate.instant('btn_ok'), () => {
      this.aasContract.postDeleteContract(contractId).subscribe(res=>{
        this.loadData();
        this.myAlert.showAlert(this.translate.instant('ServiceContractListV2_msg_10'), 'success', false, '', 'OK', () => {
        });
      }, err => {
        console.log(err);
        this.myAlert.showErrorHandled(err);
      })
    });
  } 

   // đồng ý thỏa thuận 
   postAcceptDeal(contractId){
    this.myAlert.showAlert(this.translate.instant('ServiceContractListV2_msg_11'), '', true, '', this.translate.instant('btn_ok'), () => {
      this.aasContract.postAcceptContract(contractId).subscribe(res=>{
        this.loadData();
        this.myAlert.showAlert(this.translate.instant('ServiceContractListV2_msg_12'), 'success', false, '', 'OK', () => {
        });
      }, err => {
        console.log(err);
        this.myAlert.showErrorHandled(err);
      })
    });
  } 

   // từ chối thỏa thuận
   postCancelDeal(contractId){
    this.myAlert.showAlert(this.translate.instant('ServiceContractListV2_msg_13'), '', true, '', this.translate.instant('btn_ok'), () => {
      this.aasContract.postCancelDeal(contractId).subscribe(res=>{
        this.loadData();
        this.myAlert.showAlert(this.translate.instant('ServiceContractListV2_msg_14'), 'success', false, '', 'OK', () => {
        });
      }, err => {
        console.log(err);
        this.myAlert.showErrorHandled(err);
      })
    });
  } 

  //Gửi hợp đồng ký usbtoken
  sendContract(contract) {
    if (contract.currentStage === "LC_DRAFT_CREATE") {
      this.myAlert.showAlert(this.translate.instant('ServiceContractDetail_confirm_send_draft'), ConstantAlertType.BLANK, true, '', this.translate.instant('btn_confirm'), () => {
        //Gui hop dong nhap
        this.postSubmitSendDraft(contract.contractId);
      }, '', this.translate.instant('btn_cancel'));
    }
  }

  /**
   * Submit gui ban nhap hop dong den cac ben
   * @param contractId 
   */
  postSubmitSendDraft(contractId) {
    this.aasContract.postSubmitSendDraft(contractId).subscribe((res) => {
      this.myAlert.showAlert(this.translate.instant('ServiceContractListV2_msg_15'), 'success', false, '', 'OK', () => {
      });
      this.loadData(this.page);
    }, err => {
      console.log(err);
      this.myAlert.showErrorHandled(err);
    })
  }


  //MARK: THAY DOI NOI DUNG HOP DONG
  makeFormUpdateContractFile() {
    this.formUpdateContractFile = this.fb.group({
      file: ['', [Validators.required]]
    });
  }

  openPopupUpdateContractFile(contractId) {
    this.contractIdSelected = contractId;
    this.isSubmitFormUpdateContractFile = false;
    this.fileContractNew = null;
    this.fileName = "";
    this.lbFieldContractFile = "ServiceContractListV2_lbFieldContractFile";
    this.formUpdateContractFile.reset();
    this.modalUpdateContractFile = this.modalService.open(this.updateContractFile);
  } 

  confirmUpdateContractFile() {
    this.isSubmitFormUpdateContractFile = true;
    if (this.formUpdateContractFile.valid) {
      this.myAlert.showAlert(this.translate.instant('ServiceContractDetail_update_contract_file_confirm'), ConstantAlertType.BLANK, true, "", this.translate.instant('btn_confirm'), () => {
        this.callBackUpdateContractFile();
      })
    }
  }

  callBackUpdateContractFile() {
    if (this.formUpdateContractFile.valid) {
      let formData: FormData = new FormData();
      formData.append('file', this.fileContractNew, this.fileContractNew.name || "");
      this.postUpdateDraftContract(this.contractIdSelected, formData);
    }
  }

  /**
   * Chon file tu hop dong
   * @param e 
   */
  handleFileInput(e) {
    this.fileContractNew = e.target.files && e.target.files.item(0);
    this.formUpdateContractFile.get('file').setErrors(null);

    if (this.fileContractNew && this.fileContractNew !== null && this.fileContractNew !== undefined) {
      if (!this.fileType.includes(this.fileContractNew.type)) {
        this.formUpdateContractFile.get('file').setErrors({ type: true });
        this.lbFieldContractFile = this.fileContractNew.name
      } else if (this.fileContractNew.size > 5000000) {
        this.myAlert.showAlertOnlyNoti(this.translate.instant('ServiceContract_FILE_SIZE_TOO_LARGE'), ConstantAlertType.ERROR);
      } else {
        this.lbFieldContractFile = this.fileContractNew.name;
        if (this.fileContractNew.type === 'application/pdf') {
          this.fileName = this.fileContractNew.name;
          // PDFSign.preview(this.fileContractNew);
        } else {
          let fileSplit = this.fileContractNew.name.split('.');
          fileSplit.pop();
          this.fileName = `${fileSplit.join('.')}.pdf`;

          let data = new FormData();
          data.append('attachFile', this.fileContractNew);
          this.postConvertToPDF(data);
        }
      }
    } else {
      this.lbFieldContractFile = 'ServiceContractListV2_lbFieldContractFile';
    }
  }

  //Convert docx sang pdf
  postConvertToPDF(data: FormData) {
    this.aasContract.postConvertToPdf(data).subscribe(fileData => {
      const blob: any = new Blob([fileData], { type: 'application/pdf' });
      // PDFSign.preview(blob);
      this.fileContractNew = blob;

      // this.getBase64String(blob, this.handle);
    }, err => {
      console.log(err);
      this.myAlert.showErrorHandled(err);
    });
  }

  postUpdateDraftContract(id, data) {
    this.aasContract.postUpdateDraftContract(id, data).subscribe((res: any)=> {
      this.modalUpdateContractFile && this.modalUpdateContractFile.close();
      this.loadData(this.page);
      this.myAlert.showAlertOnlyNoti(this.translate.instant('UPDATE_CONTRACT_FILE_SUCCESS'), ConstantAlertType.SUCCESS);
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }
  
  // tạo bản sao
  createCoppies(contractId){
  }

  // //MARK: Popup thông báo
  // alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = this.translate.instant('btn_cancel'), actBtn1 = () => { }) {
  //   this.myAlert.showAlert(msg, type, twoBtn, iconBtn2, titleBtn2, actBtn2, iconBtn2, titleBtn1, actBtn1);
  // }

  // //MARK: Handle Error
  // handleError(err, errorMsg) {
  //   this.myAlert.showErrorHandled(err, errorMsg);
  // }

  //MARK: NETWORKING
  getListContact(partyId, partnerId, serviceId, fromDate, toDate, status, keySearch, page, maxSize, sort, propertiesSort, signTurn, typeId, processUserId, processTypeId) {

    if (this._by === 'OWNER') {
      this.getListContactByOwner(
        partyId,
        partnerId,
        this.formFilter.value.customerType,
        serviceId,
        fromDate,
        toDate,
        status,
        keySearch,
        page,
        maxSize,
        sort,
        propertiesSort, 
        signTurn,
        typeId,
        processUserId,
        processTypeId);
    } else if (this._by === 'ASSIGN') {
      this.getListContactByAssign(
        partyId,
        partnerId,
        this.formFilter.value.customerType,
        serviceId,
        fromDate,
        toDate,
        status,
        keySearch,
        page,
        maxSize,
        sort,
        propertiesSort, 
        signTurn,
        typeId);
    } else {
      this.aasContract.getListContact(partyId, partnerId, serviceId, fromDate, toDate, status, keySearch, page, maxSize, sort, propertiesSort, signTurn).subscribe(res => {
        var response = new ContractListResponse(res);

        this.listContract = response.object.data;
        // this.listContract.push(this.listContract[0]);
        // this.listContract.push(this.listContract[0]);
        // this.listContract.push(this.listContract[0]);
        // this.listContract.push(this.listContract[0]);

        this.totalElement = response.object.totalElement;

        

      }, err => {
        console.log(err);
        this.myAlert.showErrorHandled(err);
      });
    }
  }

  toggleShow(index) {
    this.dataTableExpand[index].hidden = !this.dataTableExpand[index].hidden;
    for (var i = index + 1; i < this.dataTableExpand.length; i++) {
      if (this.dataTableExpand[i].isParent) {
        break;
      }
      if (this.dataTableExpand[i].isChild) {
        this.dataTableExpand[i].hidden = this.dataTableExpand[index].hidden;
      }
    }
  }

  getListContactByOwner(partyId, partnerId, partnerType, serviceId, fromDate, toDate, status, keySearch, page, maxSize, sort, propertiesSort, signTurn, typeId, processUserId, processTypeId) {
    this.aasContract.getListContactByOwner(partyId, partnerId, partnerType, serviceId, fromDate, toDate, status, keySearch, page, maxSize, sort, propertiesSort, signTurn, typeId, processUserId, processTypeId).subscribe(res => {
      var response = new ContractListResponse(res);

      this.listContract = response.object.data;

      this.progressTableData(this.listContract);

      this.totalElement = response.object.totalElement;
      console.log(this.listContract);
      

    }, err => {
      console.log(err);
      this.listContract = [];
      this.dataTableExpand = [];
      this.totalElement = 0;
      // this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    });
  }

  getListContactByAssign(partyId, partnerId, partnerType, serviceId, fromDate, toDate, status, keySearch, page, maxSize, sort, propertiesSort, signTurn, typeId) {
    this.aasContract.getListContactByAssign(partyId, partnerId, partnerType, serviceId, fromDate, toDate, status, keySearch, page, maxSize, sort, propertiesSort, signTurn, typeId).subscribe(res => {
      
      
      var response = new ContractListResponse(res);

      this.listContract = response.object.data;
      this.progressTableData(this.listContract);

      this.totalElement = response.object.totalElement;
      console.log(this.listContract);

    }, err => {
      console.log(err);
      this.listContract = [];
      this.dataTableExpand = [];
      this.totalElement = 0;
      // this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    });
  }

  //Tải file hợp đồng 
  downloadFileContract(id, type) {
    this.aasContract.downloadFileContract(id, type).subscribe((res) => {
      const blob: any = new Blob([res], { type: 'application/pdf' });
      var url = window.URL.createObjectURL(blob);
      var anchor = document.createElement("a");
      anchor.download = `${id}.pdf`;
      anchor.href = url;
      anchor.click();
    }, err => {
      console.log(err);
      this.myAlert.showErrorHandled(err);
    })
  }

  //Huỷ hợp đồng
  postCancelContract(contractId, param) {
    this.aasContract.postCancelContract(contractId, param).subscribe(res => {
      this.loadData();
      this.myAlert.showAlert(this.translate.instant('ServiceContractListV2_msg_16'), 'success', false, '', 'OK', () => {
        this.modalService.dismissAll();
      });
    }, err => {
      console.log(err);
      this.myAlert.showErrorHandled(err);
    })
  }

  downloadEnCodeFile(contractId) {
    this.aasContract.downloadEncodeContract(contractId).subscribe((res) => {
      const blob: any = new Blob([res], { type: 'application/pdf' });
      var url = window.URL.createObjectURL(blob);
      var anchor = document.createElement("a");
      anchor.download = `${contractId}.pdf`;
      anchor.href = url;
      anchor.click();
    }, err => {
      console.log(err);
      this.myAlert.showErrorHandled(err);
    })
  }

  /**
   * Sap xem theo cot
   * @param key 
   */
  sortCol(key) {
    if (this.sortColConfig[key] == null) {
      this.sortColConfig[key] = true;
    } else if (this.sortColConfig[key] == false) {
      this.sortColConfig[key] = null;
    } else {
      this.sortColConfig[key] = !this.sortColConfig[key];
    }

    this.loadData(this.page);
  }
  
  //Ký hợp đồng thoa thuan
  goToSignAgreement(id) {
    this.router.navigate(['/app/console/contract/sign-agreement', id])
  }

  showSignAgreement() {
    return this.auth.username == "demo_vnpt" || this.auth.username == "demo_laocai";
  }

  //Tu choi dam phan moi
  makeFormDenyDiscuss() {//Khoi tao form tu choi dam phan, tham dinh
    this.formDenyDiscuss = this.fb.group({
      reason: ['', [Validators.maxLength(4000)]]
    })
  }

  modalPartnerDiscuss = null;
  openModalDenyPartnerDiscuss(content, contractId) {
    this.contractIdSelected = contractId;
    this.formDenyDiscuss.reset();
    this.modalPartnerDiscuss = this.modalService.open(content, {
      size: 'md',
      backdrop: 'static',
      keyboard: false
    });
  }

  /**
   * Tu choi hop dong
   */
  confirmDenyContract() {
    if (this.formDenyDiscuss.valid) {
      this.myAlert.showAlert(this.translate.instant('ServiceContractDetail_confirm_deny_draft'), ConstantAlertType.BLANK, true, '', this.translate.instant('btn_confirm'), () => {
        this.modalPartnerDiscuss && this.modalPartnerDiscuss.close();
        this.postDenyContract(this.contractIdSelected, this.formDenyDiscuss.value.reason || "");
      }, '', this.translate.instant('btn_close'), null, this.translate.instant('btn_confirm'));
    }
  }

  /**
   * Tu choi chot hop dong
   * @param contractId 
   */
  postDenyContract(contractId, reason) {
    this.aasContract.postDenyContract(contractId, "PARTNER", reason).subscribe((res: any) => {
      this.myAlert.showAlert(this.translate.instant('ServiceContractListV2_msg_17'), 'success', false, '', 'OK', () => {
      });
      this.loadData(this.page);
    }, err => {
      console.log(err);
      this.myAlert.showErrorHandled(err);
    })
  }


  /**
   * MARK: Đồng ý từ chối thẩm định
   */

  modalInternalDiscuss = null;
  openModalDenyInternalDiscuss(content, contractId) {//Mo popup tu choi tham dinh
    this.formDenyDiscuss.reset();
    this.checkbox_addfooter = true;
    this.contractIdSelected = contractId;
    this.modalInternalDiscuss = this.modalService.open(content, {
      size: 'md',
      backdrop: 'static',
      keyboard: false
    });
  }

  //Mo popup dong y tham dinh
  openModalDealInternalDiscuss(content, contractId) {//Mo popup tu choi tham dinh
    this.checkbox_addfooter = true;
    this.contractIdSelected = contractId;
    this.modalInternalDiscuss = this.modalService.open(content, {
      size: 'md',
      backdrop: 'static',
      keyboard: false
    });
  }

  /**
   * Tu choi hop dong
   */
  confirmDenyInternalDiscus() {
    if (this.formDenyDiscuss.valid) {
      this.myAlert.showAlert(this.translate.instant('contract_confirm_deny_internal_discuss'), ConstantAlertType.BLANK, true, '', this.translate.instant('btn_confirm'), () => {
        this.modalInternalDiscuss && this.modalInternalDiscuss.close();
        let footer = this.checkbox_addfooter == true ? 'Y' : 'N';
        this.postDenyInternalDicuss(this.contractIdSelected, this.formDenyDiscuss.value.reason || "", footer);
      }, '', this.translate.instant('btn_cancel'), null, this.translate.instant('btn_confirm'));
    }
  }

  /**
   * Dong y tham dinh hop dong
   */
  confirmDealInternalDiscus() {
    this.modalInternalDiscuss && this.modalInternalDiscuss.close();
    let footer = this.checkbox_addfooter == true ? 'Y' : 'N';
    this.postAcceptInternalDicuss(this.contractIdSelected, footer);
  }

  /**
   * Tu choi chot hop dong
   * @param contractId 
   */
  postDenyInternalDicuss(contractId, reason, footer) {
    this.aasContract.postDenyContract(contractId, "INTERNAL", reason, footer).subscribe((res: any) => {
      this.loadData(this.page);
      this.myAlert.showAlert(this.translate.instant('ServiceContractListV2_msg_17'), 'success', false, '', 'OK', () => {
      });
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }

  /**
   * Dong y hop dong
   */
   confirmAcceptInternalDiscus(contractId) {
    this.contractIdSelected = contractId;
    this.myAlert.showAlert(this.translate.instant('contract_confirm_accept_internal_discuss'), ConstantAlertType.BLANK, true, '', this.translate.instant('btn_confirm'), () => {
      this.postAcceptInternalDicuss(contractId, '');
    }, '', this.translate.instant('btn_cancel'), null, this.translate.instant('btn_confirm'));
  }

  /**
   * Dong ty chot hop dong
   * @param contractId 
   */
   postAcceptInternalDicuss(contractId, footer) {
    this.aasContract.postAcceptContract(contractId, "INTERNAL", footer).subscribe((res: any) => {
      this.loadData(this.page);
      this.myAlert.showAlert(this.translate.instant('ServiceContractListV2_msg_18'), 'success', false, '', 'OK', () => {
      });
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }

  /**
   * Đính kèm tài liệu
   * @param id 
   */
  goToAttachDocument(id) {
    this.router.navigate(['/app/console/contract', id], { queryParams: { 'action': 'DOCUMENT' } })
  }
  
  goToDetail(contractId, isVerificated) {
    if (isVerificated == 0 && this._by === "ASSIGN") {
      this.myAlert.showAlertOnlyNoti(this.translate.instant('ServiceContractListV2_msg_19'), ConstantAlertType.ERROR);
    }
    else {
      this.router.navigate([`/app/console/contract/${contractId}`]);
    }
  }
  cancelContract(contract){
      this.myAlert.showAlert('Bạn có chắc chắn muốn hủy hợp đồng này', ConstantAlertType.BLANK, true, '', ConstantAlertBtn.CONFIRM, () => {
        //Gui hop dong nhap
        this.postCancelContract(contract.contractId,'');
      }, '', "btn_cancel");
  }
  postUndoCancelContract(id){
    this.aasContract.postUndoCancleContract(id).subscribe((res:any)=>{
      this.myAlert.showAlertOnlyNoti("ContractListV2_undo_contract_success", 'success', '', 'btn_ok', () => {
      });
      this.loadData(this.page)
    }, error=>{
      console.log('hoan tac ko thanh cong');
      this.myAlert.showErrorHandled(error);
    })
  }
  undoContract(contract){
    if (contract.currentStage === "LC_DRAFT_CANCEL") {
      this.myAlert.showAlert("ContractListV2_undo_contract_confirm", ConstantAlertType.BLANK, true, '', "btn_confirm", () => {
        //Gui hop dong nhap
        this.postUndoCancelContract(contract.contractId);
      }, '', "btn_cancel");
    }
  }

  //hide download button 
  hideDownloadBtn(contract) {
    return (contract.currentStage != "LC_CONTRACT_VALID" && contract.ownerPartyId != this.auth.partyId);
  }

  // MARK: Xử lý chữ ký lỗi
  contract: ContractObject = null;
  showRequestResignModal = false;
  partiesJoin: Array<PartyJoinContract> = []; //getPartyJoin
  partyIdWantEdit = "";

  /**
   * Yêu cầu kí lại đối với chữ ký bị lỗi
   * @param contractId Id hợp đồng
   */
  requestReSign(contractId: string) {
    this.getContract(contractId, (res) => {
      this.contract = new ContractResponse(res).object;
      this.partyIdWantEdit = this.contract.getPartyHaveWarningSignature();
      this.partiesJoin = this.contract.getPartyJoin();

      this.openRequestResignModal(true);
    })
  }

  /**
   * Mở giao diện yêu cầu kí lại
   * @param e 
   */
  openRequestResignModal(e) {
    this.showRequestResignModal = true;
  }

  /**
   * Đóng giao diện yêu cầu kí lại
   * @param event 
   */
  closeRequestResign(event) {
    this.showRequestResignModal = false;
  }

  /**
   * Handle action nút Gửi yêu cầu ký lại hợp đồng
   * @param event Dữ liệu trả về từ RequestResignComponent
   */
  sendRequestResignEvent(event) {
    if (event) {
      this.myAlert.showAlert("ContractDetail_confirm_send_requestResign", "", true, "", "btn_confirm", () => {
        this.aasContract.requestSignAgain(this.contract.contractId, event?.partyId, event?.data).subscribe((res) => {
          this.myAlert.showAlertOnlyNoti("ContractDetail_success_send_requestResign", "");
          this.closeRequestResign(true);
          this.loadData(this.page);
        }, err => {
          this.myAlert.showErrorHandled(err);
        })
      }, "", "btn_close")
    }
  }

  /**
   * Chấp nhận chữ ký bị cảnh báo
   * @param contractId Id hợp đồng
   */
  acceptSignature(contractId: string) {
    this.getContract(contractId, (res) => {
      this.contract = new ContractResponse(res).object;
      this.allowSignature(contractId, this.contract.getSignatureWarningId());
    })
  }

  /**
   * Gửi API chấp nhận chữ kí
   */
  allowSignature(contractId: string, signId: string) {
    this.myAlert.showAlert('Bạn có chắn chắn muốn chấp nhận chữ ký này?', '', true, '', 'btn_ok', () => {
      this.aasContract.allowSignature(contractId, signId).subscribe((res: any) => {
        this.loadData(this.page);
        this.myAlert.showAlertOnlyNoti("Chấp nhận chữ ký thành công!", ConstantAlertType.SUCCESS);
      }, err => {
        this.myAlert.showErrorHandled(err);
      })
    });
  }

  /**
   * Lấy thông tin chi tiết hợp đồng
   * @param id id hợp đồng
   */
  getContract(id, callback = null) {
    this.aasContract.getContract(id).subscribe(res => {
      if (callback) {
        callback(res)
      }
    }, err => {
      console.log(err);
      this.myAlert.showErrorHandled(err);
    })
  }

  /**
   * Su kien scroll xuong duoi cung cua droplist
   * @returns 
   */
  onScrollToEnd() {
    var nextPage = this.dropListTypePage + 1;
    if (this.endOfDropListTypePage) return;
    this.dropListTypePage = nextPage;
    this.getTypeList(nextPage, 10);
  }

  /**
   * Lay du lieu loai hop dong ||  Get list of types
   * @param page 
   * @param maxSize 
   * @param propertiesSort 
   * @param sort 
   * @param status 
   */
  getTypeList(page, maxSize, propertiesSort = "modified", sort = "DESC", status = "") {
    this.typesLoading = true;
    this.contractTypeService.getListContractTypes(page, maxSize, propertiesSort, sort, status, this.keySearchDropListEmployee).subscribe((res: any) => {
      this.dropListTypeData.pipe(tap((data: any) => {
        return [...data, (res?.object?.data || [])];
      }))
      this.typesLoading = false;
    }, err => {
      this.typesLoading = false;
    });
  }

  trackByFn1(item: any) {
    return item;
  }

  dropListTypePage = 1;
  endOfDropListTypePage = false;
  typeInput$ = new Subject<string>();
  allLabel: Array<object> = [
    { contractTypeId: '', name: 'Tất cả' }
  ];
  lazyLoadDropListType() {
    this.dropListTypeData = concat(
      this.typeInput$.pipe(
        startWith(''),
        debounceTime(400),
        distinctUntilChanged(),
        tap(() => { this.typesLoading = true; this.myLoader.disabledLoader(this.typesLoading); }),
        switchMap((term) => {
          this.keySearchDropListEmployee = term || "";
          this.dropListTypePage = 1;
          this.endOfDropListTypePage = false;
          let res = this.contractTypeService.getListContractTypes(this.dropListTypePage, -1, "", "DESC", "", term || "").pipe(
            map((res: any) => {
              if (res?.object?.data == null) this.endOfDropListTypePage = true;
              return this.allLabel.concat(res?.object?.data) || [];
            }),
            catchError(() => of([])), // empty list on error
            tap(() => { this.typesLoading = false; this.myLoader.disabledLoader(this.typesLoading); })
          );
          return res;
        })
      ));
  }

  //Mo popup loc nang cao
  modalAdvancedFilter = null;
  openModalAdvancedFilter(content) {
    this.modalAdvancedFilter = this.modalService.open(content, {
      size: 'md',
      backdrop: 'static',
      keyboard: false
    });
  }

  makeFormAdvancedFilter() {
    this.isDisabled = true;
    this.formAdvancedFilter = this.fb.group({
      processUserId: new FormControl(''),
    });
  }

  cleanAdvancedFilter() {
    this.formAdvancedFilter.reset();
    this.formAdvancedFilter.controls.processUserId.setValue('');
    this.isDisabled = true;
  }

  searchWithAdvancedFilter() {
    if (this.formAdvancedFilter.valid) {
      this.modalAdvancedFilter && this.modalAdvancedFilter.close();
      this.loadData();
    }
  }

  selectValue(value: number) {
    this.checkValue = value;
    this.processTypeId = value;
  }

  lazyLoadDropListEmployee() {
    this.dropListUserData = concat(
      this.employeesInput$.pipe(
        startWith(''),
        debounceTime(400),
        distinctUntilChanged(),
        tap(() => { this.usersLoading = true; this.myLoader.disabledLoader(this.usersLoading); }),
        switchMap((term) => {
          this.keySearchDropListEmployee = term || "";
          this.dropListEmployeePage = 1;
          this.endOfDropListEmployeePage = false;
          let res = this.employeeService.getListEmployeesInParty(this.dropListEmployeePage, -1, "createdDate", "DESC", "", term || "").pipe(
            map((res: any) => {
              if (res?.object?.data == null || res?.object?.data == []) this.endOfDropListEmployeePage = true;
              return this.customDropList.concat(res?.object?.data.filter((x) => { return x.userId != this.userId })) || [];
            }),
            catchError(() => of([])), // empty list on error
            tap(() => { this.usersLoading = false; this.myLoader.disabledLoader(this.usersLoading); })
          );
          return res;
        })
      ));
  }

  getEmployeeList(page, maxSize = 10, propertiesSort = "createdDate", sort = "DESC", status = "", partId = "") {
    this.usersLoading = true;
    this.employeeService.getEmployeeList(page, maxSize, propertiesSort, sort, status, partId, this.keySearchDropListEmployee).subscribe((res: any) => {
      this.dropListUserData.pipe(tap((data: any) => {
        return [...data, (res?.object?.data || [])];
      }))
      this.usersLoading = false;
    }, err => {
      this.usersLoading = false;
    });
  }

  changeUser($event) {
    if (this.isDisabled == true) {
      this.isDisabled = false;
    }
  }

  // Su kien scroll xuong duoi cung cua droplist
  onScrollToEndUser() {
    var nextPage = this.dropListEmployeePage + 1;
    if (this.endOfDropListEmployeePage) return;
    this.dropListEmployeePage = nextPage;
    this.getEmployeeList(nextPage, 10);
  }
}
