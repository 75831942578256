import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DualListComponent } from 'src/app/layout/extensions/dual-list/dual-list.component';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { StatusCode } from 'src/app/utils/common/status-code';
import { StringUtils } from 'src/app/utils/common/StringUtils';
import { localize } from 'src/app/utils/localize/localize';
import { InfraConfig } from 'src/app/utils/models/aas-models/service-catalog/infra-config';
import { ServiceCatalog } from 'src/app/utils/models/aas-models/service-catalog/service-catalog';
import { DualListItem } from 'src/app/utils/models/duallist/duallist-item';
import { PackageServiceService } from 'src/app/utils/services/package-service/package-service.service';

@Component({
  selector: 'app-catalog-service-detail',
  templateUrl: './catalog-service-detail.component.html',
  styleUrls: ['./catalog-service-detail.component.scss']
})
export class CatalogServiceDetailComponent implements OnInit {
  @ViewChild('duallist') duallist: DualListComponent;

  id = this.activatedRoute.snapshot.paramMap.get('id');

  localize = localize;

  form: FormGroup;
  isSubmit = false;
  validateMsg = {
    name: {
      required: localize.required_catalog_name
    },
    serviceType: {
      required: localize.required_service_type
    },
    status: {
      required: localize.required_status
    }
  }

  statusList = [
    // { value: "", label: localize.all },
    { value: "ENABLED", label: localize.active },
    { value: "DISABLED", label: localize.deactive}
  ];

  serviceType = [
    // { value: "", label: localize.all },
    { value: "HATANG", label: localize.infrastructure },
    { value: "CHUCNANG", label: localize.feature}
  ];

  items_dualist = [];
  items_dualist_assigned = [];

  infraConfigs: InfraConfig[] = [];
  totalPrice = "";

  serviceCatalog: ServiceCatalog = new ServiceCatalog();

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private myAlert: AlertControl,
    private packageApi: PackageServiceService
  ) { }

  ngOnInit(): void {
    this.makeForm();
    this.setData();
    this.getAllRole();
    this.getServiceCatalog(this.id);
  }

  makeForm() {
    this.form = this.fb.group({
      name: ["", [Validators.required]],
      serviceType: ["CHUCNANG", [Validators.required]],
      status: [null, [Validators.required]]
    })
  }

  delete() {
    this.myAlert.showAlert(localize.confirm_delete_service_catalog, "", true, "", localize.btn_delete, () => {
      this.deleteServiceCatalog(this.id);
    }, "", localize.btn_cancel, null, localize.confirm);
  }

  edit() {
    this.router.navigate([ConstantUrl.service_catalog_edit, this.id]);
  }

  /**
   * GET ds quyen chuc nang enduser
   */
   getAllRole() {
    this.packageApi.getAllRole().subscribe((res: any) => {
      console.log(res);
      if (res.object && res.object instanceof Array) {
        res.object.forEach((item, index) => {
          this.items_dualist.push({
            id: item.name || "",
            name: item.description || "",
            additions: {
              ...item
            },
            child: []
          })
        });

        this.duallist.setBaseList();
        this.duallist.setupDualList([]);
      }
    }, err => {

    })
  }

  getListAssigned(event) {
    console.log(event);
    if (event && event instanceof Array) {
      this.items_dualist_assigned = [].concat(event);
    }
  }

  setData() {
    this.infraConfigs.push(new InfraConfig({
      "stt":1,
      "param":"contractNumber",
      "name": "Số lượng hợp đồng",
      "unit": "Hợp đồng",
      "unitContract": "",
      "unitNumber": "0",
      "unitPrice": "0",
      "price": "0"
    }));
    this.infraConfigs.push(new InfraConfig({
      "stt":2,
      "param":"SMS_OTP",
      "name": "Số lượt SMS OTP",
      "unit": "Lượt",
      "unitContract": "5",
      "unitNumber": "0",
      "unitPrice": "0",
      "price": "0"
    }));
    this.infraConfigs.push(new InfraConfig({
      "stt":3,
      "param":"EKYC",
      "name": "Số lượt eKYC",
      "unit": "Lượt",
      "unitContract": "3",
      "unitNumber": "0",
      "unitPrice": "0",
      "price": "0"
    }));
    this.infraConfigs.push(new InfraConfig({
      "stt":4,
      "param":"STORAGE_TIME",
      "name": "Thời gian lưu trữ",
      "unit": "Năm",
      "unitContract": "5",
      "unitNumber": "0",
      "unitPrice": "0",
      "price": "0"
    }));
    this.infraConfigs.push(new InfraConfig({
      "stt":5,
      "param":"CA_PLUGIN",
      "name": "CA Plugin",
      "unit": "Lience",
      "unitContract": "1",
      "unitNumber": "0",
      "unitPrice": "0",
      "price": "0"
    }));
  }

  makeParam() {
    let param =
    {
      "name": this.form.value.name || "",
      "typeService": this.form.value.serviceType || "",
      "privileges": [
      ],
      "totalPrice": "",
      "infrastructures": [
      ],
      "status": this.form.value.status
    };

    switch (this.form.value.serviceType) {
      case "CHUCNANG":
        param.privileges = [];
        this.items_dualist_assigned.forEach((item: any) => {
          param.privileges.push(item.id || "");
        });

        return param;
      case "HATANG":
        this.getTotalMoney();
        param.totalPrice = this.totalPrice == "" || this.totalPrice == null ? "0" : this.totalPrice;
        param.infrastructures = [];
        this.infraConfigs.forEach((item) => {
          let x = {
            "stt": item.stt,
            "param": item.param || "",
            "name": item.name,
            "unit": item.unit,
            "unitContract": item.unitContract == "" || item.unitContract == null ? "0" : item.unitContract,
            "unitNumber": item.unitNumber == "" || item.unitNumber == null ? "0" : item.unitNumber,
            "unitPrice": item.unitPrice == "" || item.unitPrice == null ? "0" : item.unitPrice,
            "price": item.price == "" || item.price == null ? "0" : item.price,
          }
          param.infrastructures.push(x);
        })
        return param;
    }
  }

  getTotalMoney() {
    var sum = '0';
    this.infraConfigs.forEach((item) => {
      if (!isNaN(parseInt(item.price)) && !isNaN(parseInt(sum))) {
        sum = `${Number(sum) + Number(item.price)}`;
      }
    })

    this.totalPrice = sum;
  }

  putUpdateServiceCatalog(id, param) {
    this.packageApi.putUpdateServiceCatalog(id, param).subscribe((res) => {
      this.myAlert.showAlertOnlyNoti(localize.edit_service_catalog_success, "", "", localize.btn_close, null, localize.NOTIFICATION);
      this.router.navigate([ConstantUrl.service_catalog_list]);
    }, err => {
      this.myAlert.showErrorHandled(err);
    });
  }

  getServiceCatalog(id) {
    this.packageApi.getServiceCatalog(id).subscribe((res: any) => {
      console.log(res);
      this.serviceCatalog = new ServiceCatalog(res.object || {});
      this.form.patchValue({
        name: this.serviceCatalog.name,
        serviceType: this.serviceCatalog.typeService,
        status: this.serviceCatalog.status
      });

      this.items_dualist_assigned = [];
      this.serviceCatalog.privileges.forEach((item) => {
        this.items_dualist_assigned.push({
          id: item.name,
          name: item.description,
          additions: {
            ...item
          },
          child: []
        })
      });
      this.duallist.setupDualList(this.items_dualist_assigned);

      if (this.serviceCatalog.infrastructures.length > 0) {
        this.infraConfigs = Array.from(this.serviceCatalog.infrastructures);
        this.infraConfigs = this.infraConfigs.sort((a, b) => { return a.stt - b.stt; })
      }

      this.getTotalMoney();
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }

  deleteServiceCatalog(id) {
    this.packageApi.deleteServiceCalalog(id).subscribe((res: any) => {
      this.myAlert.showAlertOnlyNoti(localize.delete_service_catalog_success, "", "", localize.btn_close);
      this.router.navigate([ConstantUrl.service_catalog_list]);
    }, err => {
      if (err.error && err.error.code == 'SERVICE_INFOMATION_USED') {
        this.myAlert.showAlertOnlyNoti(StatusCode.SERVICE_INFOMATION_USED, "", "", localize.btn_close, () => {}, "Lỗi xóa danh mục dịch vụ");
      } else {
        this.myAlert.showErrorHandled(err);
      }
    })
  }
}
