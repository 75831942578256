<app-spinner></app-spinner>
<div class="landingpage" *ngIf="isValid">
    <div class="register">
        <section class="register-ss1">
            <img src="assets/img/home/v4/Image/Logo eContract_change color.png" alt="" class="img-logo-ipadpro">
            <div class="content col-12 p-0">
                <div class="row swap-col">
                    <div class="col-auto-new p-0">
                        <div class="row">
                            <div class="col-12">
                                <div class="content-left">
                                    <div class="row">
                                        <div class="col-left-auto">
                                            <div class="body">
                                                <span class="logo block">
                                                    MỘT SẢN PHẨM CỦA TẬP ĐOÀN
                                                    <img src="../../../assets/img/home/v4/Image/logo vnpt.png" alt="">
                                                </span>
                                                <p class="title">
                                                    Hợp đồng điện tử<br />
                                                    VNPT eContract
                                                </p>
                                                <span class="dec">
                                                    Cung cấp dịch vụ hợp đồng điện tử cho phép các bên giao kết các loại
                                                    hợp đồng
                                                    bằng phương tiện điện tử.
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-left-auto">
                                            <img src="assets/img/home/v4/Image/Group 177362.png" alt=""
                                                class="d-xl-block imgbg">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-auto-new p-0">
                        <div class="content-right">
                            <div class="row justify-content-center">
                                <div class="col-10 col-md-6 col-xl-9">
                                    <div class="body">
                                        <img src="assets/img/home/v4/Image/LOGO261.png" alt="" class="img-logo">
                                        <h3 class="title">{{ 'RenewPassword_title' | translate }}</h3>
                                        <span class="note pb-4">
                                            {{ 'RenewPassword_h_1' | translate }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-10 col-md-6 col-xl-9">


                                    <div class="login-form">
                                        <form [formGroup]="renewForm">
                                            <div class="form-group">
                                                <label for="">{{ 'RenewPassword_h2' | translate }}<span class="text-danger">*</span></label>
                                                <div class="d-block position-relative">
                                                    <input appInputPassword formControlName="password" type="password"
                                                        class="form-control" placeholder="{{ 'RenewPassword_h3' | translate }}">
                                                </div>
                                                <app-show-validate-errors [isSubmit]="isSubmit"
                                                    [errorMessages]="ValidateMsg.password" [control]="password"
                                                    [detail]="{ name: 'password' }"></app-show-validate-errors>


                                            </div>
                                            <div class="form-group">
                                                <label for="">{{ 'RenewPassword_h4' | translate }}<span
                                                        class="text-danger">*</span></label>
                                                <div class="d-block position-relative">
                                                    <input appInputPassword formControlName="passwordConfirm"
                                                        type="password" class="form-control"
                                                        placeholder="{{ 'RenewPassword_h5' | translate }}">
                                                </div>
                                                <app-show-validate-errors [isSubmit]="isSubmit"
                                                    [errorMessages]="ValidateMsg.passwordConfirm"
                                                    [control]="passwordConfirm" [detail]="{ name: 'passwordConfirm' }">
                                                </app-show-validate-errors>

                                            </div>
                                            <span class="text-muted">{{ 'RenewPassword_h6' | translate }}</span>

                                            <button [disabled]="checkEdited" (click)="actConfirm()" type="submit" class="btn_reg">{{ 'btn_continue' | translate }}</button>
                                        </form>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
<a href="" class="btn-backhome" *ngIf="isValid">
    <img src="assets/img/home/v4/icon/backicon.png" alt="">
</a>