export class SeriesItem {
    taxCode: string;
    serial: string;
    pattern : string;

    constructor(obj = null) {
        obj = obj || {};
        this.taxCode = obj.taxCode || '';
        this.serial = obj.serial || '';
        this.pattern = obj.pattern || '';
    }
}