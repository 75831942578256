import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { EconToastControl } from 'src/app/utils/econ-toast/econ-toast-control';
import { localize } from 'src/app/utils/localize/localize';
import { ContractTemplate } from 'src/app/utils/models/aas-models/template/template';
import { TemplateVar } from 'src/app/utils/models/aas-models/template/template-var';
import { TemplateDetailObject } from 'src/app/utils/models/template/template-detail/template-detail-object.model';
import { TemplateService } from 'src/app/utils/services/aas-network/template.service';

@Component({
  selector: 'app-contract-template-detail',
  templateUrl: './contract-template-detail.component.html',
  styleUrls: ['./contract-template-detail.component.scss']
})
export class ContractTemplateDetailComponent implements OnInit {
  @ViewChild('templateFile') templateFile: ElementRef;

  id = this.activatedRoute.snapshot.paramMap.get('id');
  template: ContractTemplate = new ContractTemplate();

  localize = localize;
  localizeC = localize;
  templateForm: FormGroup;
  isSubmit = false;
  inputFileLabel = "ContractTemplateDetail_choose_file";
  attachFile = null;
  validateMsgForm = {
    templateName : {
      required: "ContractTemplateAdd_alert_4"
    },
    file: {
      required: "ContractTemplateAdd_alert_5",
      maxsize: "ContractTemplateAdd_alert_6"
    },
    templateType: {
      required: "ContractTemplateAdd_alert_7"
    },
    status: {
      required: "ContractTemplateAdd_alert_8"
    }
  }

  statusData = [
    { label: "status_activated", value: 'ACTIVE' },
    { label: "status_not_activated", value: 'DEACTIVE' }
  ];

  templateVars: TemplateVar[] = [];
  mapVars: any[] = [];

  showFile = false;
  pdfFile = null;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private templateService: TemplateService,
    private myAlert: AlertControl,
    private myEconToast: EconToastControl,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.viewDidLoad();
  }

  viewDidLoad() {
    this.makeFormTemplate();
    this.getListMapModel();
    this.getDetailTemplate(this.id);
    // this.getFieldsTemplate(this.id);
  }

  makeFormTemplate() {
    this.templateForm = this.fb.group({
      templateName: ["", [Validators.required]],
      file: [null, [Validators.required]],
      templateType: ["", [Validators.required]],
      status: [ { value: 'ACTIVE', disabled: true } , [Validators.required]],
      id: [""]
    });
  }

  delete() {
    if (this.template.isUsed) {
      this.myAlert.showAlertOnlyNoti(this.translate.instant('ContractTemplateList_template_cannot_delete_isactived'), ConstantAlertType.ERROR, "", this.translate.instant('btn_close'));
      return;
    }
    this.myAlert.showAlert(this.translate.instant('ContractTemplateList_template_delete_confirm'), ConstantAlertType.BLANK, true, "", this.translate.instant('btn_delete'), () => {
      this.deleteTemplate(this.id);
    }, "", this.translate.instant('btn_close'));
  }

  edit() {
    this.router.navigate([ConstantUrl.contract_template_edit, this.id]);
  }

  cancel() {
    this.templateForm.reset();
    this.templateForm.patchValue({
      templateName: "",
      file: null,
      templateType: "",
      status: 'ACTIVE'
    });

    this.attachFile = null;
    this.inputFileLabel = "ContractTemplateDetail_choose_file";
    this.templateVars = [];
  }

  save() {
    this.isSubmit = true;
    if (this.templateForm.valid && this.attachFile) {
      let formData = new FormData();
      formData.append("templateName", this.templateForm.value.templateName);
      formData.append("templateType", this.templateForm.value.templateType);
      formData.append("status", this.templateForm.value.status);
      formData.append("attachFile", this.attachFile);
      formData.append("fields", JSON.stringify(this.templateVars));

      this.myAlert.showAlert(this.translate.instant('ContractTemplateAdd_alert_1'), ConstantAlertType.BLANK, true, "", this.translate.instant('btn_confirm'), () => {
        this.postCreateTemplate(formData);
      }, );
    }
  }

  viewFile() {
    this.getFileTemplate(this.id);
  }

  onClosePreview(data) {
    this.showFile = false;
    this.pdfFile = null;
  }

  onAttachFileChanged(event) {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0]
      this.attachFile = file
      this.inputFileLabel = file.name

      this.templateFile.nativeElement.value = "";
      this.templateForm.controls.file.setErrors(null);

      this.templateVars = [];

      // Getting all variables
      this.postFileTemplate(this.attachFile);
    } else {
      this.inputFileLabel = "ContractTemplateDetail_choose_file";
      this.templateForm.controls.file.setErrors({ required: true });
    }
  }

  postFileTemplate(file) {
    const formData = new FormData()
    formData.append('attachFile', file)
    this.templateService.postUploadFileTempate(formData).subscribe((res: any) => {
      if (res && res.object && res.object instanceof Array) {
        res.object.forEach((item) => {
          let tv = new TemplateVar(item);
          if (item.listVar && item.listVar instanceof Array && item.listVar.length > 0) {
            tv.listVar.forEach((x) => {
              x.varMaps = this.mapVars.concat([{ label: x.nameMap, value: x.nameMap }])
            })
          }
          tv.varMaps = this.mapVars.concat([{ label: tv.nameMap, value: tv.nameMap }]);

          this.templateVars.push(tv);
        });
      }
      console.log(this.templateVars);
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }

  getListMapModel() {
    this.templateService.getListMapModel().subscribe((res: any) => {
      console.log(res);
      if (res.object) {
        Object.keys(res.object).forEach((item) => {
          this.mapVars.push({
            label: res.object[item],
            value: item
          })
        })
      }
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }

  postConvertToPDF(data: FormData) {
    this.templateService.postConvertToPdf(data).subscribe(fileData => {
      const blob: any = new Blob([fileData], { type: 'application/pdf' });
      this.pdfFile = blob;
      this.showFile = true;
    }, err => {
      console.log(err);
      this.myAlert.showAlertOnlyNoti(this.translate.instant('ContractTemplateAdd_alert_2'), ConstantAlertType.ERROR);
    });
  }

  postCreateTemplate(data: FormData) {
    this.templateService.postCreateTemplatev2(data).subscribe((res: any)=> {
      this.router.navigate([ConstantUrl.contract_template_detail, res?.object?.id]);
      this.myAlert.showAlertOnlyNoti(this.translate.instant('ContractTemplateAdd_alert_3'), ConstantAlertType.SUCCESS);
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }

  getDetailTemplate(id: string) {
    this.templateService.getDetailTemplate(id).subscribe((res: any) => {
      console.log(res);
      this.template = new ContractTemplate(res?.object);

      this.templateForm.patchValue({
        templateName: this.template.templateName,
        file: "",
        templateType: this.template.templateType,
        status: this.template.status,
        id: this.template.id
      });

      this.inputFileLabel = this.template.fileName;

      this.getFieldsTemplate(this.template.templateFields);

    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }

  getFieldsTemplate(id: string) {
    this.templateService.getFieldsTemplate(id).subscribe((res: any) => {
      console.log(res);
      if (res && res.object && res.object instanceof Array) {
        res.object.forEach((item) => {
          let tv = new TemplateVar(item);
          if (item.listVar && item.listVar instanceof Array && item.listVar.length > 0) {
            tv.listVar.forEach((x) => {
              x.varMaps = this.mapVars.concat([{ label: x.nameMap, value: x.nameMap }])
            })
          }
          tv.varMaps = this.mapVars.concat([{ label: tv.nameMap, value: tv.nameMap }]);

          this.templateVars.push(tv);
        });
      }
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }

  getFileTemplate(id: string) {
    this.templateService.getFileTemplate(id).subscribe((res) => {
      const blob: any = new Blob([res], { type: 'application/pdf' });
      this.pdfFile = blob;
      console.log(this.pdfFile);
      setTimeout(() => {
        this.showFile = true;
      }, 1000) 
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }

  deleteTemplate(id: string) {
    this.templateService.deleteDeactiveTemplate(id).subscribe((res: any) => {
      this.router.navigate([ConstantUrl.contract_template_list]);
      this.myAlert.showAlertOnlyNoti(this.translate.instant('ContractTemplateList_remove_success'), ConstantAlertType.SUCCESS);
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }

  downStockFileTemplate($event) {
    this.templateService.getStockFileTemplate(this.id).subscribe((res: any) => {
      const blob: any = new Blob([res]);
      var url = window.URL.createObjectURL(blob);
      var anchor = document.createElement("a");
      anchor.download = this.template.fileName || 'file';
      anchor.href = url;
      anchor.click();
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }

  copyClipboard(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    // this.myEconToast.toast(`<b>Đã sao chép</b> \"${val}\" <b> vào bộ nhớ tạm</b>`);
    this.myEconToast.toast(`<b>${this.translate.instant('ContractTemplateDetail_copy', {val: val})}</b>`);

  }
}
