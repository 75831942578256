
import { Component, OnInit, ViewChild, ElementRef,Renderer2,AfterViewInit } from '@angular/core';
import { Options } from 'select2';
import { Select2OptionData } from 'ng-select2';
import { Observable, from } from 'rxjs';
// import { ReportAnalyticsService } from "src/app/utils/services/report-analytics.service";
import { AuthService } from 'src/app/utils/services/auth.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateUtils } from 'src/app/utils/common/DateUtils';
import { API_KEY_FILTER_FORM } from 'src/app/utils/common/constant-vaildate-msg';
import { REGEXP_DATE_INPUT, REGEXP_EMAIL } from 'src/app/utils/common/regexp';
import { ListRequestConvertResponse } from 'src/app/utils/models/aas-models/business-package-manager/business-package-list/list-request-convert-package/list-request-convert-response.model';
import { ResquestConvertItem } from 'src/app/utils/models/aas-models/business-package-manager/business-package-list/list-request-convert-package/request-convert-item.model';
import { BusinessPackageService } from 'src/app/utils/services/aas-network/business-package.service';
import { ListDataPackageNewResponse} from 'src/app/utils/models/aas-models/quantity-report/data-droplist/list-data-package-new-response.model';
import { PackageNewDataItem } from 'src/app/utils/models/aas-models/quantity-report/data-droplist/data-item.model';
import {
  map,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap
} from "rxjs/operators";
import { Package }  from 'src/app/utils/common/constant';
import { ActivatedRoute, Router } from '@angular/router';
import { ConstantUrl } from 'src/app/utils/common/constant-url'

declare var Cleave: any;
declare var $: any;
@Component({
  selector: 'app-service-package-conversion-list',
  templateUrl: './service-package-conversion-list.component.html',
  styleUrls: ['./service-package-conversion-list.component.scss']
})
export class ServicePackageConversionListComponent implements OnInit {
  @ViewChild('fromDatePicker') _fromDate: ElementRef;
  @ViewChild('toDatePicker') _toDate: ElementRef;
  @ViewChild('confirmInfo') confirmInfo: ElementRef;
  @ViewChild('confirmCancel') confirmCancel: ElementRef;
  @ViewChild("searchFieldPackage") searchField: ElementRef;


  userId: string = this.auth.userId;

  formFilter: FormGroup;
  listRequestResponse : ListRequestConvertResponse;
  listRequest : Array<ResquestConvertItem> = [];
  public packageNewData: Observable<Array<Select2OptionData>>;
  listDataPackageNewRes: ListDataPackageNewResponse;
  currentDate = new Date();
  formFilterValidateMsg: any = API_KEY_FILTER_FORM;
  lineSelected: ResquestConvertItem = new ResquestConvertItem();

  status = Package

  url = ConstantUrl

   // vẽ biểu đồ tròn thống kê hợp đồng
   public doughnutChartType: string = 'doughnut';

   public doughnutChartDatasets: Array<any> = [];
 
   public doughnutChartLabels: Array<any> = [];
 
   public doughnutCharOption = {
   }
   
  configColTable: any = {
    tenKhachHang: true,
    goiDangSuDung: true,
    goiDangKyMoi: true,
    loaiKhachHang: true,
    ngayYeuCau: true,
    trangThai: true
  }

  listConfigColTable: Array<any> = [
    {
      label: "Tên khách hàng",
      name: "tenKhachHang"
    },
    {
      label: "Gói đang sử dụng",
      name: "goiDangSuDung"
    },
    {
      label: "Gói đăng ký mới ",
      name: "goiDangKyMoi"
    },
    {
      label: "Loại khách hàng",
      name: "loaiKhachHang"
    },
    {
      label: "Ngày yêu cầu",
      name: "ngayYeuCau"
    },
    {
      label: "Trạng thái",
      name: "trangThai"
    }, 
  ];
  

  dropListsUserType:  Array<Select2OptionData> = [
    {
      id: 'ALL',
      text: 'Tất cả'
    },
    {
      id: 'CONSUMER',
      text: 'Cá nhân'
    },
    {
      id: 'BUSINESS',
      text: 'Tổ chức dịch vụ'
    },
    {
      id: 'ENTERPRISE',
      text: 'Tổ chức giải pháp'
    },
    
  ];

  userTypes = {
    CONSUMER : {
      lable: 'Cá Nhân'
    },
    BUSINESS : {
      lable: 'Tổ chức dịch vụ'
    },
    ENTERPRISE : {
      lable: 'Tổ chức giải pháp'
    },
    '' : {
      lable: 'Không xác định'
    },

  }

  dropListsNewPackages:  Array<Select2OptionData> = [
    {
      id: 'ALL',
      text: 'Tất cả'
    },
    {
      id: 'TRIAL_TYPE',
      text: 'Gói dùng thử'
    },
    {
      id: 'NORMAL_TYPE',
      text: 'Gói cơ bản'
    }
    
  ];

  packageType = {
    TRIAL_TYPE : {
      id: 'TRIAL_TYPE',
      text: 'Gói dùng thử'
    },
    NORMAL_TYPE : {
      id: 'NORMAL_TYPE',
      text: 'Gói cơ bản'
    }
  };



  partnerId: string = '';
  serviceId: string = '';
  customerType: string = "";
  package = '';
  fromDateFilter: string = '';
  toDateFilter: string = '';
  isSubmit: boolean = false;

  total: number = 0;

  zIndex = 5;
  public options: Options;

  formConfirmInfo : FormGroup;
  formCancel : FormGroup;

  formSearch: FormGroup;
  searchFields: FormControl;
  private results ;

  saveKeySearch = '';
  keySearch = "";

  page = 1;
  maxsize = 10;
  propertiesSort = 'created';
  sort = 'DESC';
  totalElement= 0;

  tenKhachHang: string = ''

  validateSearch = {
    fromDate: {
      pattern: "Sai định dạng ngày tháng năm!",
      minDate: 'Từ ngày không được lớn hơn đến ngày và không được là ngày tương lai!'
    },
    toDate: {
      pattern: "Sai định dạng ngày tháng năm!"
    }
  };

  dateType = {
    CREATED_FROM: 'CREATED_FROM',
    CREATED_TO: 'CREATED_TO'
  };

  optionsCleave = {
    date: true,
    delimiter: '/',
    datePattern: ['d', 'm', 'Y']
  };

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private modalService: NgbModal,
    private renderer2: Renderer2,
    private packageService: BusinessPackageService,
    private router: ActivatedRoute,
    private route: Router,
  ) {
    this.renderer2.listen('window', 'click', (e: Event) => {
      var all = document.getElementsByClassName('table-sticky-col2');
      for (var i = 0; i < all.length; i++) {
        all[i].removeAttribute('style');
      }       
    });
  }

  ngOnInit(): void {
    this.tenKhachHang = ''
    this.makeFormFilter();
    this.makeFormPoup();
    this.makeFormSearch()
    this.makeAutoFormatDate();

    this.getListPackage();
    this.getPackageNewData();
    this.searchFields = new FormControl();
    this.results = this.searchFields.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(_ => (this.search(this.searchField.nativeElement.value))),
    ).subscribe();
  
  }

  inputKeySearch(e) {
    this.saveKeySearch = e.target.value;
  }

  search(keyWord) {
    this.page = 1;
    this.keySearch = keyWord.trim();
    this.getListPackage();
  }

  loadPage(page){
    this.page = page;
    this.getListPackage();
  }  


   //MARK: Khởi tạo form search
   makeFormSearch() {
    this.formSearch = this.fb.group({
      search: new FormControl('')
    });
  }
  makeFormPoup(){
    this.formCancel = this.fb.group({
      description: new FormControl(''),
    });

    this.formConfirmInfo = this.fb.group({
      packageOld: new FormControl(''),
      packageNew: new FormControl(''),
    })
  }
  getListPackage(){
    this.total = 0;
    let userType = this.userType.value === 'ALL'? '':  this.userType.value;
    let packageNewType = this.packageNewType.value === 'ALL'? '':  this.packageNewType.value;
    // let fromDate = this.convertStringToDate(this.fromDate)?this.convertStringToDate(this.fromDate):'';
    // let toDate = this.convertStringToDate(this.toDate)?this.convertStringToDate(this.toDate):'';

    this.packageService.getListChangePackage(this.maxsize,this.page,this.propertiesSort,this.sort, 
      packageNewType, userType,this.keySearch.normalize('NFC'),this.fromDate,this.toDate).subscribe(res=>{
      this.listRequestResponse = new ListRequestConvertResponse(res['object']);
      this.listRequest = this.listRequestResponse.data
      this.totalElement = this.listRequestResponse.totalElement
      this.maxsize = this.listRequestResponse.maxSize
      this.page = this.listRequestResponse.page
    })

  
  }

  getPackageNewData(){
    this.packageService.getListChangePackage(1000).subscribe((res:any)=>{
      this.listDataPackageNewRes = new ListDataPackageNewResponse(res['object'])
      this.packageNewData = this.makePackageData();
    })
  }

  makePackageData(): Observable<Array<Select2OptionData>> {
    let data: Array<Select2OptionData> = [];
    data.push({ id: "ALL", text: "Tất cả" });
    if (this.listDataPackageNewRes.data && this.listDataPackageNewRes.data instanceof Array) {
      this.listDataPackageNewRes.data.forEach((item) => {
        if( item.packageName != '' && item.packageName != null){
          data.push({ id: item.packageId.toString(), text: item.packageName });
        }
      });
    }
    return Observable.create((obs) => {
      obs.next(data);
      obs.complete();
    });
  }

  
  // cover time to format dd/MM/YYYY
  convertToDateObj(dataString) {
    let d = new Date(dataString);
    let localTiming = d.toLocaleDateString('en-GB');
    return localTiming;
  }

  //MARK: Make input date format
  makeAutoFormatDate() {
  }


  makeFormFilter() {
    this.formFilter = this.fb.group({
      userType : ['ALL'],
      packageNewType: ['ALL'],
      toDate: new FormControl('',  [Validators.pattern(REGEXP_DATE_INPUT)]),
      fromDate: new FormControl('', [Validators.pattern(REGEXP_DATE_INPUT)])
    });
    this.options = {
      multiple: false,
      width: '100%',
      closeOnSelect: true,
      language: 'vi',
    };
  }




  get userType() {
    return this.formFilter.get('userType');
  }
  get packageNewType() {
    return this.formFilter.get('packageNewType');
  }

  get packageOld() {
    return this.formConfirmInfo.get('packageOld');
  }
  get packageNew() {
    return this.formConfirmInfo.get('packageNew');
  }

  get description() {
    return this.formCancel.get('description');
  }

  get fromDate() {
    return DateUtils.convertInputToFormat(this.formFilter.value.fromDate, DateUtils.format.API);
  }
  get toDate() {
    return DateUtils.convertInputToFormat(this.formFilter.value.toDate, DateUtils.format.API);
  }

  openModalConfirmInfo(content, item:any) {
    this.tenKhachHang = item.tenKhachHang;
    this.lineSelected = item;
    this.packageOld.setValue(item.goiDangSuDung)
    this.packageNew.setValue(item.goiDangKyMoi)
    this.modalService.open(content, {
      size: 'md',
      backdrop: 'static',
      keyboard: false
    });
  }  

  partyId : string = '';
  openModalCancel(content, item:any) {
    this.partyId = item.partyId
    this.tenKhachHang = item.tenKhachHang
    this.modalService.open(content, {
      size: 'md',
      backdrop: 'static',
      keyboard: false
    });
  } 

  openContract(){
    this.route.navigate([this.url.ADMIN_CONTRACT_CREATE], { queryParams: { packageId: this.lineSelected.packageTempId, partyId: this.lineSelected.partyId, from: "LIST_REQUEST_CHANGE_PACKAGE" }})
    this.modalService.dismissAll();
  }

    /**
   * Thay doi ngay tren filter
   * @param value 
   * @param type 
   */
  changeDateFilter(event, type) {
    switch (type) {
      case this.dateType.CREATED_FROM: 
        this.formFilter.controls.fromDate.setValue(DateUtils.convertObjtoDate(event));
        break;
      case this.dateType.CREATED_TO:
        this.formFilter.controls.toDate.setValue(DateUtils.convertObjtoDate(event));
        break;
    }
  }

  //MARK: Apply filter
  searchWithFilter() {
    this.isSubmit = true;
    if (this.formFilter.valid) {
      let dateFrom = this.formFilter.value.fromDate.length > 0 ? new Date(DateUtils.convertStringtoDateSearch(this.formFilter.value.fromDate)).getTime() : -1;
      let dateTo = this.formFilter.value.toDate.length > 0 ? new Date(DateUtils.convertStringtoDateSearch(this.formFilter.value.toDate)).getTime() : -1;
      if ((dateTo > 0 && dateFrom > 0 && dateFrom > dateTo)) {
        this.formFilter.controls.fromDate.setErrors({ minDate: true });
      }
    }

    if (this.formFilter.valid) {
      this.getListPackage();
    }
  }

  cleanFilter() {
    this.isSubmit = false;
    this._fromDate.nativeElement.value = '';
    this._toDate.nativeElement.value = '';
    this.formFilter.reset();
    
    this.formFilter.controls.userType.setValue('ALL');
    this.formFilter.controls.fromDate.setValue('');
    this.formFilter.controls.toDate.setValue('');
    this.formFilter.controls.packageNewType.setValue('ALL');
    this.makeFormFilter()

    this.searchWithFilter()
  }


  //MARK: Action cấu hình hiển thị cột của bảng
  actConfigColTableView(e, field) {
    e.stopPropagation();
    if (this.configColTable[field] !== null && this.configColTable[field] !== undefined) {
      this.configColTable[field] = !this.configColTable[field];
    }
  }

  //MARK: Action dropdown từng dòng
  openDropdown(event) {
    event.path[3].setAttribute("style", "z-index: " + ++this.zIndex + ";");
  }


  convertStringToDate(str: string) {
    if(str !== '' && str !== null && str !== undefined) {
        var values = str.split('/');
        if(values.length >= 3) {
            return `${values[2]}-${values[1]}-${values[0]}`;
        } else {
            return null;
        }
    } else {
        return null;
    }
  }

  cancelRequest(){
    let data={
      rejectReason: this.description.value
    }
    this.packageService.cancelChange(this.partyId, data ).subscribe(res=>{
      this.getListPackage()
      this.modalService.dismissAll();
    })

  }


  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], 'error');
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, 'error');
    } else {
      this.alert('Lỗi không xác định!', 'error');
    }
  }


}
