<app-spinner></app-spinner>
<div class="wrapper">
    <div class="preview-overlay">

        <div class="preview-container">
            <div class="preview-header d-flex">
                <div (click)="goToBack()"
                    class="ct-header-btn-back d-flex justify-content-center align-items-center cursor-pointer">
                    <span class="material-icons">close</span>
                </div>
                <div class="ct-title d-flex align-items-start justify-content-center flex-column px-3">
                    <h4 class="mb-0">{{ 'SignMultipleContracts_h_1' | translate }}</h4>
                    <label *ngIf="signForm == 'IMAGE'" class="text-weight-bold text-primary mb-0">{{ 'SignMultipleContracts_h_2' | translate }}</label>
                    <label *ngIf="signForm == 'SMARTCA'" class="text-weight-bold text-primary mb-0">{{ 'SignMultipleContracts_h_3' | translate }}</label>
                </div>
                <div class="ct-toolbar-btn d-flex align-items-center ml-auto">
                    <div class="d-flex align-items-center">
                        <!-- <button
                            class="btn btn-primary d-flex align-items-center mr-3" placement="left" ngbTooltip="Gửi hợp đồng cho các bên"><span
                            class="material-icons mr-2">send</span> Nút</button> -->
                    </div>
                </div>
            </div>

            <div class="preview-body">
                <div class="pv ct">
                    <div class="pv-body">
                        <div class="ctpv-container">
                            <div class="ctpv-content">
                                <div class="ct-container">
                                    <div class="ct-content ct-enable-scroll">
                                        <div class="ct-doc-loaded">
                                            <div class="ec-tab-sign-multiple">
                                                <div class="row mx-0">
                                                    <div class="col-12">
                                                        <div class="row">
                                                            <div class="ec-tab-item"
                                                                [class.col-md-3]="signForm == 'SMARTCA'"
                                                                [class.col-md-4]="signForm == 'IMAGE'" 
                                                                [class.active]="steps[0].active == true"
                                                                [class.done]="steps[0].done == true">
                                                                <div class="ec-tab-index">1</div>
                                                                <div class="ec-tab-meta">
                                                                    <label class="text-weight-bold mb-0">{{ 'SignMultipleContracts_h_4' | translate }}</label>
                                                                    <label *ngIf="steps[0].active == false && steps[0].done == false" class="text-weight-bold text-grey-default mb-0">{{ 'SignMultipleContracts_h_5' | translate }}</label>
                                                                    <label *ngIf="steps[0].active == true && steps[0].done == false" class="text-weight-bold text-primary mb-0">{{ 'SignMultipleContracts_h_6' | translate }}</label>
                                                                    <label *ngIf="steps[0].done == true" class="text-weight-bold text-success mb-0">{{ 'SignMultipleContracts_h_7' | translate }}</label>
                                                                </div>
                                                            </div>
                                                            <div class="ec-tab-item"
                                                                [class.col-md-3]="signForm == 'SMARTCA'"
                                                                [class.col-md-4]="signForm == 'IMAGE'" 
                                                                [class.active]="steps[1].active == true"
                                                                [class.done]="steps[1].done == true">
                                                                <div class="ec-tab-index">2</div>
                                                                <div class="ec-tab-meta">
                                                                    <label class="text-weight-bold mb-0">{{ 'SignMultipleContracts_h_8' | translate }}</label>
                                                                    <label *ngIf="steps[1].active == false && steps[1].done == false" class="text-weight-bold text-grey-default mb-0">{{ 'SignMultipleContracts_h_5' | translate }}</label>
                                                                    <label *ngIf="steps[1].active == true && steps[1].done == false" class="text-weight-bold text-primary mb-0">{{ 'SignMultipleContracts_h_6' | translate }}</label>
                                                                    <label *ngIf="steps[1].done == true" class="text-weight-bold text-success mb-0">{{ 'SignMultipleContracts_h_7' | translate }}</label>
                                                                </div>
                                                            </div>
                                                            <div class="ec-tab-item"
                                                                [class.col-md-3]="signForm == 'SMARTCA'"
                                                                [class.col-md-4]="signForm == 'IMAGE'" 
                                                                [class.active]="steps[2].active == true"
                                                                [class.done]="steps[2].done == true">
                                                                <div class="ec-tab-index">3</div>
                                                                <div class="ec-tab-meta">
                                                                    <label class="text-weight-bold mb-0">{{ 'SignMultipleContracts_h_9' | translate }}</label>
                                                                    <label *ngIf="steps[2].active == false && steps[2].done == false" class="text-weight-bold text-grey-default mb-0">{{ 'SignMultipleContracts_h_5' | translate }}</label>
                                                                    <label *ngIf="steps[2].active == true && steps[2].done == false" class="text-weight-bold text-primary mb-0">{{ 'SignMultipleContracts_h_6' | translate }}</label>
                                                                    <label *ngIf="steps[2].done == true" class="text-weight-bold text-success mb-0">{{ 'SignMultipleContracts_h_7' | translate }}</label>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="signForm == 'SMARTCA'" class="col-md-3 ec-tab-item"
                                                                [class.active]="steps[3].active == true"
                                                                [class.done]="steps[3].done == true">
                                                                <div class="ec-tab-index">4</div>
                                                                <div class="ec-tab-meta">
                                                                    <label class="text-weight-bold mb-0">{{ 'SignMultipleContracts_h_10' | translate }}</label>
                                                                    <label *ngIf="steps[3].active == false && steps[3].done == false" class="text-weight-bold text-grey-default mb-0">{{ 'SignMultipleContracts_h_5' | translate }}</label>
                                                                    <label *ngIf="steps[3].active == true && steps[3].done == false" class="text-weight-bold text-primary mb-0">{{ 'SignMultipleContracts_h_6' | translate }}</label>
                                                                    <label *ngIf="steps[3].done == true" class="text-weight-bold text-success mb-0">{{ 'SignMultipleContracts_h_7' | translate }}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="ec-tab-sign-multiple-content">
                                                <div *ngIf="currentStep == 0" class="ec-tab-step">
                                                    <div class="row">
                                                        <div class="col-md-8">
                                                            <div class="bg-white">
                                                                <section class="p-3">
                                                                    <div class="row">
                                                                        <div class="col-lg-4 col-md-6">
                                                                            <form [formGroup]="formSearch" (ngSubmit)="actSearch(formSearch.value.search)"
                                                                                class="input-group border rounded mb-3">
                                                                                <div class="input-group-prepend border-0">
                                                                                    <button class="btn d-inline-flex pr-2 pl-2" type="submit"><span
                                                                                            class="material-icons">search</span></button>
                                                                                </div>
                                                                                <input type="text" class="form-control border-0"
                                                                                    formControlName="search" placeholder="{{ 'PartList_label_search' | translate }}..." aria-label=""
                                                                                    aria-describedby="basic-addon1">
                                                                            </form>
                                                                        </div>
                                                                        <div class="col-md-auto ml-auto">
                                                                            <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="cleanFilter()"> {{ 'btn_reset' | translate }}</button>
                                                                            <button class="btn btn-primary d-inline-flex" (click)="searchWithFilter()"> {{ 'btn_apply' | translate }}</button>
                                                                        </div>
                                                                    </div>
                                                                    <hr class="my-2">
                                                                </section>
                                                                <section class="px-3 my-2">
                                                                    <form [formGroup]="formFilter">
                                                                        <div class="row">
                                                                            <div class="col-md-3" >
                                                                                <div class="form-group">
                                                                                    <label for="statusGCS">{{ 'ServiceContract_customerType' | translate }}</label>
                                                                                    <select formControlName="customerType" class="form-control">
                                                                                        <option value="">{{ 'EmployeeList_tat_ca' | translate }}</option>
                                                                                        <option value="CONSUMER" >{{ 'individual' | translate }}</option>
                                                                                        <option value="BUSINESS">{{ 'organization' | translate }}</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-3">
                                                                                <div class="form-group">
                                                                                    <label for="">{{ 'USBTokenManager_tu_ngay' | translate }}</label>
                                                                                    <div class="input-group">
                                                                                        <input #fromDatePicker class="form-control" [control]="formFilter.controls.fromDate"
                                                                                            [value]="formFilter.value.fromDate"
                                                                                            (dateSelect)="changeDateFilter($event, dateType.CREATED_FROM)"
                                                                                            placeholder="dd/MM/yyyy" ngbDatepicker #createdFromDate="ngbDatepicker"
                                                                                            [maxDate]="{year: currentDate.getFullYear(), month: currentDate.getMonth()+1, day: currentDate.getDate()}"
                                                                                            appInputMark [options]="optionsCleave">
                                                                                        <div class="input-group-append">
                                                                                            <button class="btn btn-outline-secondary" (click)="createdFromDate.toggle()"
                                                                                                type="button"><em class="fas fa-calendar-alt"></em></button>
                                                                                        </div>
                                                                                        <app-show-validate-errors [useSubmit]="false"
                                                                                            [errorMessages]="validateSearch.fromDate"
                                                                                            [control]="formFilter.controls.fromDate"
                                                                                            [detail]="{ name: 'fromDate' }">
                                                                                        </app-show-validate-errors>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-3">
                                                                                <div class="form-group">
                                                                                    <label for="">{{ 'ServiceContractListV2_date_to' | translate }}</label>
                                                                                    <div class="input-group">
                                                                                        <input #toDatePicker class="form-control" [control]="formFilter.controls.toDate"
                                                                                            [value]="formFilter.value.toDate"
                                                                                            (dateSelect)="changeDateFilter($event, dateType.CREATED_TO)"
                                                                                            placeholder="dd/MM/yyyy" ngbDatepicker #createdToDate="ngbDatepicker"
                                                                                            [maxDate]="{year: currentDate.getFullYear(), month: currentDate.getMonth()+1, day: currentDate.getDate()}"
                                                                                            appInputMark [options]="optionsCleave">
                                                                                        <div class="input-group-append">
                                                                                            <button class="btn btn-outline-secondary" (click)="createdToDate.toggle()"
                                                                                                type="button"><em class="fas fa-calendar-alt"></em></button>
                                                                                        </div>
                                                                                        <app-show-validate-errors [useSubmit]="false"
                                                                                            [errorMessages]="validateSearch.toDate"
                                                                                            [control]="formFilter.controls.toDate"
                                                                                            [detail]="{ name: 'toDate' }">
                                                                                        </app-show-validate-errors>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-3">
                                                                                <div class="form-group">
                                                                                    <label for="status">{{ 'status' | translate }}</label>
                                                                                    <select formControlName="status" class="form-control">
                                                                                        <option value="" selected>{{ 'status_all' | translate }}</option>
                                                                                        <option value="LC_DRAFT_CREATE">{{ 'SignMultipleContracts_h_23' | translate }}</option>
                                                                                        <option value="LC_DRAFT_DISCUSS">{{ 'SignMultipleContracts_h_24' | translate }}</option>
                                                                                        <option value="LC_DRAFT_DEAL">{{ 'SignMultipleContracts_h_25' | translate }}</option>
                                                                                        <option value="LC_DRAFT_SUBMIT">{{ 'SignMultipleContracts_h_26' | translate }}</option>
                                                                                        <option value="LC_DRAFT_CANCEL">{{ 'SignMultipleContracts_h_27' | translate }}</option>
                                                                                        <option value="LC_CONTRACT_VALID">{{ 'SignMultipleContracts_h_28' | translate }}</option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row">
                                                                            <!-- Chọn loại hợp đồng -->
                                                                            <!-- <div class="col-md-3">
                                                                                <div class="form-group">
                                                                                    <label>{{ 'ContractTypes_list_title' | translate }}</label>
                                                                                    <ng-select formControlName="typeId" [items]="dropListTypeData | async" bindValue="contractTypeId" bindLabel="name"
                                                                                        [clearable]="false" [trackByFn]="trackByFn1"
                                                                                        [loading]="typesLoading" [typeahead]="typeInput$" (change)="changeType($event)" (scrollToEnd)="onScrollToEnd()">
                                                                                    </ng-select>
                                                                                </div>
                                                                            </div> -->
                                                                        </div>
                                                                    </form>
                                                                </section>
                                                               
                                                                <section class="card-body p-0">
                                                                    <div class="table-responsive">
                                                                        <table class="table table-sort-col mb-0">
                                                                            <thead>
                                                                                <tr class="bg-tb-header">
                                                                                    <th>
                                                                                        <div class="form-check">
                                                                                            <input class="form-check-input" [(ngModel)]="isCheckAll" type="checkbox" value="" id="all" (click)="checkAll($event)">
                                                                                            <label class="form-check-label" for="all">
                                                                                            </label>
                                                                                        </div>
                                                                                    </th>
                                                                                    <th>{{ 'ServiceContractListV2_table_header_1' | translate }}</th>
                                                                                    <th (click)="sortCol('title')">
                                                                                        <span class="d-flex align-self-center align-items-center">
                                                                                            {{ 'ServiceContractListV2_table_header_2' | translate }}
                                                                                            <span *ngIf="this.sortColConfig['title'] == null" class="material-icons ml-auto">
                                                                                                unfold_more
                                                                                            </span>
                                                                                            <span *ngIf="this.sortColConfig['title'] == true" class="material-icons ml-auto">
                                                                                                expand_less
                                                                                            </span>
                                                                                            <span *ngIf="this.sortColConfig['title'] == false" class="material-icons ml-auto">
                                                                                                expand_more
                                                                                            </span>
                                                                                        </span>
                                                                                    </th>
                                                                                    <th>
                                                                                        <span class="d-flex align-self-center align-items-center">
                                                                                            {{ 'ServiceContractListV2_table_header_3' | translate }}
                                                                                            <!-- <span *ngIf="this.sortColConfig['partnersCount'] == null" class="material-icons ml-auto">
                                                                                                unfold_more
                                                                                            </span>
                                                                                            <span *ngIf="this.sortColConfig['partnersCount'] == true" class="material-icons ml-auto">
                                                                                                expand_less
                                                                                            </span>
                                                                                            <span *ngIf="this.sortColConfig['partnersCount'] == false" class="material-icons ml-auto">
                                                                                                expand_more
                                                                                            </span> -->
                                                                                        </span>
                                                                                    </th>
                                                                                    <th (click)="sortCol('modified')">
                                                                                        <span class="d-flex align-self-center align-items-center" >
                                                                                            {{ 'ServiceContractListV2_table_header_4' | translate }}
                                                                                            <span *ngIf="this.sortColConfig['modified'] == null" class="material-icons ml-auto">
                                                                                                unfold_more
                                                                                            </span>
                                                                                            <span *ngIf="this.sortColConfig['modified'] == true" class="material-icons ml-auto">
                                                                                                expand_less
                                                                                            </span>
                                                                                            <span *ngIf="this.sortColConfig['modified'] == false" class="material-icons ml-auto">
                                                                                                expand_more
                                                                                            </span>
                                                                                        </span>
                                                                                    </th>
                                                                                    <th (click)="sortCol('currentStage')">
                                                                                        <span class="d-flex align-self-center align-items-center" >
                                                                                            {{ 'status' | translate }}
                                                                                            <span *ngIf="this.sortColConfig['currentStage'] == null" class="material-icons ml-auto">
                                                                                                unfold_more
                                                                                            </span>
                                                                                            <span *ngIf="this.sortColConfig['currentStage'] == true" class="material-icons ml-auto">
                                                                                                expand_less
                                                                                            </span>
                                                                                            <span *ngIf="this.sortColConfig['currentStage'] == false" class="material-icons ml-auto">
                                                                                                expand_more
                                                                                            </span>
                                                                                        </span>
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr *ngFor="let item of listContract; let i = index">
                                                                                    <td>
                                                                                        <div class="form-check">
                                                                                            <input class="form-check-input" [(ngModel)]="item.selected" type="checkbox" value="" id="all" (click)="selectRecord(i, $event)">
                                                                                            <label class="form-check-label" for="all">
                                                                                            </label>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        {{ (page - 1)*maxSize + i + 1 }}
                                                                                    </td>
                                                                                    <td>
                                                                                        <div class="d-flex">
                                                                                            <span class="title-hyperlink">{{ item.title }}</span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        {{ item.partyTotal }}
                                                                                    </td>
                                                                                    <td>
                                                                                        {{ item.modified | date: 'dd/MM/yyyy' }}
                                                                                    </td>
                                                                                    <td>
                                                                                        <span class="badge {{ currentStage[item.currentStage].style || '' }} p-2">{{ (currentStage[item.currentStage].label || '') | translate }}</span>
                                                                                        <br><span class="d-block mt-1">{{ item.signerEmail }}</span>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div class="bg-tb-header p-3">
                                                                        <div class="row align-items-center">
                                                                            <span *ngIf="totalElement == 0" class="col-md text-left">{{ 'text_no_results' | translate }}</span>
                                                                            <span *ngIf="totalElement > 0" class="col-md text-left">{{ 'text_number_of_results_displayed' | translate: {from: 1, to: listContract.length, total: totalElement} }}</span>
                                                                            <div class="col-md">
                                                                                <div class="d-flex justify-content-end">
                                                                                    <div *ngIf="totalElement > 0" class="d-flex justify-content-end align-items-center">
                                                                                        <span class="mr-2">{{ 'text_number_of_lines' | translate }}:</span>
                                                                                        <ng-select class="no-bg no-border" [(ngModel)]="maxSize" [searchable]="false" [clearable]="false" placeholder="..." (change)="searchWithFilter()">
                                                                                            <ng-option [value]="10">10</ng-option>
                                                                                            <ng-option [value]="20">20</ng-option>
                                                                                            <ng-option [value]="30">30</ng-option>
                                                                                            <ng-option [value]="50">50</ng-option>
                                                                                        </ng-select>
                                                                                    </div>
                                                                                    <div *ngIf="totalElement > maxSize" class="pagination pages no-border-pagination ml-2">
                                                                                        <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="false"
                                                                                            [pageSize]="maxSize" (pageChange)="loadData($event)" [boundaryLinks]="true" aria-label="Default pagination">
                                                                                            <ng-template ngbPaginationFirst>{{ 'text_first' | translate }}</ng-template>
                                                                                            <ng-template ngbPaginationLast>{{ 'text_last' | translate }}</ng-template>
                                                                                            <ng-template ngbPaginationPrevious>&laquo;</ng-template>
                                                                                            <ng-template ngbPaginationNext>&raquo;</ng-template>
                                                                                            <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                                                                                        </ngb-pagination>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                </section> 
                                                            </div>
                                                        </div>

                                                        <div class="col-md-4">
                                                            <div class="bg-white">
                                                                <div class="block-action p-3">
                                                                    <div class="d-flex align-items-center justify-content-start mb-2">
                                                                        <h5 class="title-block">{{ 'SignMultipleContracts_h_4' | translate }}</h5>
                                                                    </div>
                                                                    <p class="mt-3">{{ signForm == 'IMAGE' ? ('SignMultipleContracts_h_11' | translate) : ('SignMultipleContracts_h_38' | translate) }}</p>
                                                                    <label class="text-weight-bold mt-3">{{ 'SignMultipleContracts_h_12' | translate }}</label>
                                                                    <p>{{ totalRecordSelected}}/{{ maxFileSelect }}</p>
                                                                    <label class="text-weight-bold mt-3">{{ 'SignMultipleContracts_h_13' | translate }}</label>
                                                                    <div class="progress rounded">
                                                                        <div class="progress-bar" role="progressbar" [attr.aria-valuenow]="progressValueNow" aria-valuemin="0" aria-valuemax="100"
                                                                            [style]="{ 'width': progressValueNow + '%'}"
                                                                        ></div>
                                                                    </div>
                                                                    <div class="d-flex align-items-center my-2">
                                                                        <span class="mr-auto">{{ progressValueNow }}%</span>
                                                                        <span class="ml-auto">{{ totalSizeFile }} / {{ maxSizeFile }} MB</span>
                                                                    </div>
                                                                    <button [disabled]="totalRecordSelected <= 0" class="btn btn-lg btn-primary w-100 d-flex align-items-center justify-content-center" (click)="nextStep2()">
                                                                        {{ 'SignMultipleContracts_h_14' | translate }}
                                                                        <span class="material-icons ml-3">arrow_forward</span>
                                                                    </button>      
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="currentStep == 1" class="ec-tab-step">
                                                    <div class="row">
                                                        <div class="col-md-8">
                                                            <div class="bg-white">
                                                                <section class="p-3" >
                                                                    <h3 class="font-weight-bold text-primary">{{ 'SignMultipleContracts_h_15' | translate }}</h3>
                                                                    <div class="mt-3" [innerHTML]="dieuKhoan">

                                                                    </div>
                                                                </section>
                                                            </div>
                                                        </div>

                                                        <div class="col-md-4">
                                                            <div class="bg-white">
                                                                <div class="block-action p-3">
                                                                    <div class="d-flex align-items-center justify-content-start mb-2">
                                                                        <span class="material-icons cursor-pointer p-2 mr-3" (click)="backStep()">arrow_back</span>
                                                                        <h5 class="title-block">{{ 'SignMultipleContracts_h_14' | translate }}</h5>
                                                                    </div>
                                                                    
                                                                    <p>{{ 'SignMultipleContracts_h_16' | translate }}</p>
                                                                    <div class="form-check mb-3">
                                                                        <input [(ngModel)]="checkboxDk1" class="form-check-input" type="checkbox" value="" id="phanly">
                                                                        <label class="form-check-label" for="phanly">
                                                                            {{ 'SignMultipleContracts_h_17' | translate }}
                                                                        </label>
                                                                    </div>
                                                                    <div class="form-check mb-4">
                                                                        <input [(ngModel)]="checkboxDk2" class="form-check-input" type="checkbox" value="" id="chapnhan">
                                                                        <label class="form-check-label" for="chapnhan">
                                                                            {{ 'SignMultipleContracts_h_18' | translate }}
                                                                        </label>
                                                                    </div>
                                                                    <button [disabled]="checkboxDk1 == false || checkboxDk2 == false" class="btn btn-lg btn-primary w-100 d-flex align-items-center justify-content-center" (click)="nextStep3()">
                                                                        {{ 'SignMultipleContracts_h_9' | translate }}
                                                                        <span class="material-icons ml-3">arrow_forward</span>
                                                                    </button>      
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div *ngIf="currentStep == 2 || currentStep == 3" class="ec-tab-step">
                                                    <div *ngIf="endSignJob != true" class="row">
                                                        <div class="col-md-8">
                                                            <div class="bg-white">
                                                                <div class="p-3">
                                                                    <h4><label>{{ 'SignMultipleContracts_h_19' | translate }} ({{ totalRecordSelected }})</label></h4>
                                                                </div>
                                                                <section class="card-body p-0">
                                                                    <div class="table-responsive">
                                                                        <table class="table table-sort-col mb-0">
                                                                            <thead>
                                                                                <tr class="bg-tb-header">
                                                                                    <th>{{ 'ESign_label_3' | translate }}</th>
                                                                                    <th>{{ 'ServiceContract_name' | translate }}</th>
                                                                                    <th>{{ 'ESign_label_5' | translate }}</th>
                                                                                    <!-- <th>Trạng thái xử lý</th> -->
                                                                                    <th>{{ 'ESign_label_6' | translate }}</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr *ngFor="let item of contractsSelected; let i = index">
                                                                                    <td>{{ (_page - 1) * _maxSize + i + 1 }}</td>
                                                                                    <td>
                                                                                        <div class="d-flex">
                                                                                            <span class="title-hyperlink">{{ item.title }}</span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>{{ item.listPageHaveSignBox }}</td>
                                                                                    <!-- <td></td> -->
                                                                                    <td>
                                                                                        <button class="btn btn-outline-primary mr-3" (click)="reviewSignBox(item)">{{ 'SignMultipleContracts_h_29' | translate }}</button>
                                                                                        <button class="btn btn-outline-danger" (click)="removeContract(i)">{{ 'btn_delete' | translate }}</button>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div class="bg-tb-header p-3">
                                                                        <!-- <div class="row align-items-center">
                                                                            <span *ngIf="totalElement == 0" class="col-md text-left">Không tìm thấy kết quả</span>
                                                                            <span *ngIf="totalElement > 0" class="col-md text-left">Hiển thị 1 đến {{listContract.length}} trong tổng số
                                                                                {{totalElement}} kết quả</span>
                                                                            <div class="col-md">
                                                                                <div class="d-flex justify-content-end">
                                                                                    <div *ngIf="totalElement > 0" class="d-flex justify-content-end align-items-center">
                                                                                        <span class="mr-2">Số dòng hiển thị:</span>
                                                                                        <ng-select class="no-bg no-border" [(ngModel)]="maxSize" [searchable]="false" [clearable]="false" placeholder="..." (change)="loadData(1)">
                                                                                            <ng-option [value]="10">10</ng-option>
                                                                                            <ng-option [value]="20">20</ng-option>
                                                                                            <ng-option [value]="30">30</ng-option>
                                                                                        </ng-select>
                                                                                    </div>
                                                                                    <div *ngIf="totalElement > maxSize" class="pagination pages no-border-pagination ml-2">
                                                                                        <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="false"
                                                                                            [pageSize]="maxSize" (pageChange)="loadData($event)" [boundaryLinks]="true" aria-label="Default pagination">
                                                                                            <ng-template ngbPaginationFirst>Đầu</ng-template>
                                                                                            <ng-template ngbPaginationLast>Cuối</ng-template>
                                                                                            <ng-template ngbPaginationPrevious>&laquo;</ng-template>
                                                                                            <ng-template ngbPaginationNext>&raquo;</ng-template>
                                                                                            <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                                                                                        </ngb-pagination>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div> -->
                                                                    </div>
                                                                </section> 
                                                            </div>
                                                        </div>

                                                        <div class="col-md-4">
                                                            <div class="bg-white">
                                                                <div *ngIf="signForm == 'IMAGE' && inProcessSignJob != true" class="block-action p-3">
                                                                    <div class="d-flex align-items-center justify-content-start mb-2">
                                                                        <span class="material-icons cursor-pointer p-2 mr-3" (click)="backStep()">arrow_back</span>
                                                                        <h5 class="title-block">{{ 'SignMultipleContracts_h_9' | translate }}</h5>
                                                                    </div>
                                                                    
                                                                    <p>{{ 'SignMultipleContracts_h_20' | translate }}</p>
                                                                    <div class="row mt-3">
                                                                        <div class="col-12">
                                                                            <label class="text-primary">{{ 'ESign_label_8' | translate }}</label>
                                                                        </div>
                                                                        <div class="col-md-12 mt-3">
                                                                            <form [formGroup]="formDSign">
                                                                                <div class="form-group">
                                                                                    <label>{{ 'ESign_label_9' | translate }} <span
                                                                                            class="text-danger">*</span>:</label>
                                                                                    <div class="input-group mb-1">
                                                                                        <div class="custom-file custom-file-icon">
                                                                                            <label class="custom-file-label"
                                                                                                for="inputGroupFile03">{{ inputImgESignLabel | translate }}</label>
                                                                                            <input formControlName="imageFile"
                                                                                                (input)="handleFileImgESignInput($event)"
                                                                                                type="file" accept="image/*"
                                                                                                class="custom-file-input"
                                                                                                id="inputGroupFile03"
                                                                                                aria-describedby="inputGroupFileAddon01">
                                                                                            <span
                                                                                                class="icon-file material-icons">attach_file</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <app-show-validate-errors [useSubmit]="false" [isSubmit]="isESignSubmit"
                                                                                        [errorMessages]="validMsgESign.imageFile"
                                                                                        [control]="formDSign.controls.imageFile"
                                                                                        [detail]="{ name: 'imageFile' }">
                                                                                    </app-show-validate-errors>
                                                                                </div>
                                                                            </form>
                                                        
                                                                            <img *ngIf="imgEsignConfig" 
                                                                                class="border border-primary mb-3"
                                                                                width="200"
                                                                                style="max-width: 100%;"
                                                                                [src]="imgEsignConfig">
                                                                        </div>
                                                                    </div>
                                                                    <button class="btn btn-lg btn-primary w-100 d-flex align-items-center justify-content-center" (click)="clickBtnSignImage()">
                                                                        {{ 'SignMultipleContracts_h_21' | translate }}
                                                                    </button>      
                                                                </div>

                                                                <div *ngIf="signForm == 'SMARTCA' && inProcessSignJob != true" class="block-action p-3">
                                                                    <div class="d-flex align-items-center justify-content-start mb-2">
                                                                        <span class="material-icons cursor-pointer p-2 mr-3" (click)="backStep()">arrow_back</span>
                                                                        <h5 *ngIf="currentStep == 2" class="title-block">{{ 'SignMultipleContracts_h_9' | translate }}</h5>
                                                                        <h5 *ngIf="currentStep == 3" class="title-block">{{ 'SignMultipleContracts_h_10' | translate }}</h5>
                                                                    </div>
                                                                    
                                                                    <p *ngIf="!showLayoutConfirmSmartCA" >{{ 'SignMultipleContracts_h_22' | translate }}</p>
                                                                    <div class="row mt-3">
                                                                        <div class="col-12">
                                                                            <app-smart-ca-multiple
                                                                                #smartCASign
                                                                                [sessionId]="sessionSmartCA"
                                                                                [contracts]="contractsSelected"
                                                                                (savedSignature)="savedSignatureFromSmartCA($event)"
                                                                                (expiredSessionSmartCA)="expiredSessionSmartCA($event)"
                                                                                (isWaitingConfirmSmartCA)="isWaitingConfirmSmartCA($event)"
                                                                            ></app-smart-ca-multiple>
                                                                        </div>
                                                                    </div>
                                                                    <button *ngIf="showLayoutConfirmSmartCA == false" class="btn btn-lg btn-primary w-100 d-flex align-items-center justify-content-center" (click)="clickBtnSignSmartCA()">
                                                                        {{ 'SignMultipleContracts_h_21' | translate }}
                                                                    </button>      
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div *ngIf="endSignJob == true" class="row">
                                                        <div class="col-md-8">
                                                            <div class="bg-white">
                                                                <div class="p-3">
                                                                    <h4><label>{{ 'SignMultipleContracts_h_19' | translate }} ({{ totalRecordSelected }})</label></h4>
                                                                </div>
                                                                <section class="card-body p-0">
                                                                    <div class="table-responsive">
                                                                        <table class="table table-sort-col mb-0">
                                                                            <thead>
                                                                                <tr class="bg-tb-header">
                                                                                    <th>{{ 'ESign_label_3' | translate }}</th>
                                                                                    <th>{{ 'ServiceContract_name' | translate }}</th>
                                                                                    <th>{{ 'ESign_label_5' | translate }}</th>
                                                                                    <th>{{ 'SignMultipleContracts_h_30' | translate }}</th>
                                                                                    <th>{{ 'PartList_label_action' | translate }}</th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr *ngFor="let item of contractsInSignJob; let i = index">
                                                                                    <td>{{ (_page - 1) * _maxSize + i + 1 }}</td>
                                                                                    <td>
                                                                                        <div class="d-flex">
                                                                                            <span class="title-hyperlink">{{ item.title }}</span>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>{{ item.listPageHaveSignBox }}</td>
                                                                                    <td>
                                                                                        <span *ngIf="item.error == '' && item.signFlag == true" class="badge badge-success-new">{{ 'SignMultipleContracts_h_34' | translate }}</span>
                                                                                        <span *ngIf="item.error != '' && item.signFlag == true" class="badge badge-danger-new">{{ 'SignMultipleContracts_h_35' | translate }}</span>
                                                                                    </td>
                                                                                    <td>
                                                                                        <button *ngIf="item.error !== '' && item.signFlag == true" class="btn btn-outline-primary mr-3" (click)="showErrorSign(item.error)">Chi tiết lỗi</button>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    <div class="bg-tb-header p-3">
                                                                        <div class="row align-items-center">
                                                                            <span *ngIf="__totalElement == 0" class="col-md text-left">{{ 'text_no_results' | translate }}</span>
                                                                            <span *ngIf="__totalElement > 0" class="col-md text-left">{{ 'text_number_of_results_displayed' | translate: {from: 1, to: contractsInSignJob.length, total: __totalElement} }}</span>
                                                                            <div class="col-md">
                                                                                <div class="d-flex justify-content-end">
                                                                                    <div *ngIf="__totalElement > 0" class="d-flex justify-content-end align-items-center">
                                                                                        <span class="mr-2">{{ 'text_number_of_lines' | translate }}:</span>
                                                                                        <ng-select class="no-bg no-border" [(ngModel)]="__maxSize" [searchable]="false" [clearable]="false" placeholder="..." (change)="loadPageSignJob(1)">
                                                                                            <ng-option [value]="10">10</ng-option>
                                                                                            <ng-option [value]="20">20</ng-option>
                                                                                            <ng-option [value]="30">30</ng-option>
                                                                                        </ng-select>
                                                                                    </div>
                                                                                    <div *ngIf="totalElement > maxSize" class="pagination pages no-border-pagination ml-2">
                                                                                        <ngb-pagination [collectionSize]="__totalElement" [(page)]="__page" [maxSize]="5" [rotate]="true" [ellipses]="false"
                                                                                            [pageSize]="__maxSize" (pageChange)="loadPageSignJob($event)" [boundaryLinks]="true" aria-label="Default pagination">
                                                                                            <ng-template ngbPaginationFirst>{{ 'text_first' | translate }}</ng-template>
                                                                                            <ng-template ngbPaginationLast>{{ 'text_last' | translate }}</ng-template>
                                                                                            <ng-template ngbPaginationPrevious>&laquo;</ng-template>
                                                                                            <ng-template ngbPaginationNext>&raquo;</ng-template>
                                                                                            <ng-template ngbPaginationNumber let-page>{{ __page }}</ng-template>
                                                                                        </ngb-pagination>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </section> 
                                                            </div>
                                                        </div>

                                                        <div class="col-md-4">
                                                            <div class="bg-white">
                                                                <div *ngIf="endSignJob == true" class="block-action p-3">
                                                                    <div class="d-flex align-items-center justify-content-start mb-2">
                                                                        <h5 class="title-block">{{ 'SignMultipleContracts_h_31' | translate }} </h5>
                                                                    </div>
                                                                    
                                                                    <p>{{ 'SignMultipleContracts_h_32' | translate }}</p>
                                                                    <div class="row mt-3">
                                                                        <div class="col-md-4">
                                                                            <label class="text-weight-bold">{{ 'SignMultipleContracts_h_33' | translate }}</label>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                            <label class="text-weight-bold">{{ totalContractInSignJob }}</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row mt-2">
                                                                        <div class="col-md-4">
                                                                            <label class="text-weight-bold">{{ 'SignMultipleContracts_h_34' | translate }}</label>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                            <label class="text-weight-bold">{{ totalSignedContractInSignJob }}</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row mt-2">
                                                                        <div class="col-md-4">
                                                                            <label class="text-weight-bold">{{ 'SignMultipleContracts_h_35' | translate }}</label>
                                                                        </div>
                                                                        <div class="col-md-6">
                                                                            <label class="text-weight-bold">{{ totalSignedFailedContractInSignJob }}</label>
                                                                        </div>
                                                                    </div>
                                                                    <button class="btn btn-lg btn-primary w-100 d-flex align-items-center justify-content-center mt-3" (click)="finish()">
                                                                        {{ 'ServiceContractDetail_btn_finish' | translate }}
                                                                    </button>      
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

<app-review-sign-box 
    *ngIf="showReviewSignBox" 
    [pdfFile]="filePDF" 
    [title]="contractReview.title" 
    [signBoxs]="contractReview.signerSignFrame"
    [imageSign]=""
    (saveChanged)="handleSaveChanged($event)"
    (cancel)="handleCancel($event)">
</app-review-sign-box>

<ng-template #popupsignjob let-c="close" let-d="dismiss">
    <div  class="modal-header border-0">
        <h5 class="modal-title" style="font-weight: 700; margin-right: auto;">{{ 'SignMultipleContracts_h_31' | translate }}</h5>
    </div>
    <div class="modal-body text-left py-0 enable-scroll">
        <p>{{ 'SignMultipleContracts_h_36' | translate }}</p>
        <div class="progress rounded">
            <div class="progress-bar bg-success" role="progressbar" [attr.aria-valuenow]="progressSignJobValue" aria-valuemin="0" aria-valuemax="100"
                [style]="{ 'width': progressSignJobValue + '%'}"
            ></div>
        </div>
        <div class="d-flex align-items-center my-2">
            <span class="mr-auto">{{ progressSignJobValue }}%</span>
            <span class="ml-auto">{{ countContractSigned }}/{{ totalContractInSignJob }} {{ 'SignMultipleContracts_h_37' | translate }}</span>
        </div>
    </div>
    <div class="modal-footer border-0 d-flex justify-content-end">
        
    </div>
</ng-template>