<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-auto">
                <h1 class="m-0 text-dark"><label>{{ localize.edit_customer }} </label></h1>
            </div>
            <!-- /.col -->
            <div class="col-md-auto ml-auto">
                <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="cancel()">
                    <span class="material-icons mr-1">close</span> {{ localize.btn_cancel }}
                </button>
                <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="setValue(party)"><span class="material-icons-outlined mr-1">
                    refresh
                    </span>{{ 'btn_reset' | translate }}</button>
                <button class="btn btn-primary d-inline-flex mr-3" (click)="save()">
                    <span class="material-icons mr-1">save</span> {{ localize.btn_save }}
                </button>
            </div>
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>

<section class="content">
    <div class="container-fluid">
        <div class="card shadow-none">
            <div class="card-body">
                <form [formGroup]="form">

                    <label class="text-primary font-weight-bold m-0">Thông tin kinh doanh</label>
                    <hr class="my-3">
                    <div class="row">
                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                                <label>{{ localize.tb_code }} <span class="text-danger">*</span></label>
                                <input class="form-control" placeholder="{{ localize.fill_tb_code }}"
                                    formControlName="tb_code">
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="form.controls.tb_code" [detail]="{ name: 'tb_code'}"
                                    [errorMessages]="validateMsg.tb_code"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                                <label>{{ localize.tb_type }} <span class="text-danger">*</span></label>
                                <input class="form-control" placeholder="{{ localize.fill_tb_type }}"
                                    formControlName="tb_type">
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="form.controls.tb_type" [detail]="{ name: 'tb_type'}"
                                    [errorMessages]="validateMsg.tb_type"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                                <label>{{ localize.tinh_code }} <span class="text-danger">*</span></label>
                                <input class="form-control" placeholder="{{ localize.fill_tinh_code }}"
                                    formControlName="tinh_code">
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="form.controls.tinh_code" [detail]="{ name: 'tinh_code'}"
                                    [errorMessages]="validateMsg.tinh_code"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                                <label>Mã HRM AM <span class="text-danger">*</span></label>
                                <input appTrimValue class="form-control" placeholder="Nhập mã HRM AM"
                                    formControlName="hrm_am">
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="form.controls.hrm_am" [detail]="{ name: 'hrm_am'}"
                                    [errorMessages]="validateMsg.hrm_am"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                                <label>Mã HRM người tạo phiếu</label>
                                <input appTrimValue class="form-control" placeholder="Nhập mã HRM người tạo phiếu"
                                    formControlName="hrm_creator">
                                <!-- <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="form.controls.tinh_code" [detail]="{ name: 'tinh_code'}"
                                    [errorMessages]="validateMsg.tinh_code"></app-show-validate-errors> -->
                            </div>
                        </div>
                    </div>

                    <label class="text-primary font-weight-bold m-0">{{ localize.account_information }}</label>
                    <hr class="my-3">
                    <div class="row">
                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                                <label>{{ localize.username }} <span class="text-danger">*</span></label>
                                <input class="form-control" placeholder="{{ localize.fill_username }}"
                                    formControlName="username">
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="form.controls.username" [detail]="{ name: 'username'}"
                                    [errorMessages]="validateMsg.username"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                                <label class="d-flex align-items-center">
                                    <span>{{ localize.account_type }} <span class="text-danger">*</span></span>
                                    <span class="info-note d-flex ml-auto">
                                        <span class="material-icons text-grey-darkest">info</span>
                                        <span class="info-tooltip-desc">
                                            <ul>
                                                <li>
                                                    Cấp 1: Chỉ được chọn Gói dùng thử,
                                                    không bắt buộc upload GPKD.
                                                </li>
                                                <li>
                                                    Cấp 2: Tùy chọn gói dịch vụ,
                                                    bắt buộc upload GPKD.
                                                </li>
                                            </ul>
                                        </span>
                                    </span>
                                </label>
                                <ng-select formControlName="identityLevel" [items]="identityLevels" bindValue="id"
                                    bindLabel="label" [placeholder]="localize.fill_account_type"
                                    (change)="changedIdentityLevel($event)">
                                </ng-select>
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="form.controls.identityLevel" [detail]="{ name: 'identityLevel'}"
                                    [errorMessages]="validateMsg.identityLevel"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                                <label>{{ localize.package_service }} <span class="text-danger">*</span></label>
                                <ng-select formControlName="packageId" [items]="packageIds" bindValue="packInfoId"
                                    bindLabel="name"
                                    [placeholder]="localize.select_package_service">
                                </ng-select>
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="form.controls.packageId" [detail]="{ name: 'packageId'}"
                                    [errorMessages]="validateMsg.packageId"></app-show-validate-errors>
                            </div>
                        </div>
                        <!-- draft -->
                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                                <label>Trạng thái <span class="text-danger">*</span></label>
                                <ng-select formControlName="disable" [items]="statusOptions" bindValue="value"
                                    bindLabel="label" [clearable]="false"
                                    >
                                </ng-select>
                                <!-- <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="form.controls.packageId" [detail]="{ name: 'packageId'}"
                                    [errorMessages]="validateMsg.packageId"></app-show-validate-errors> -->
                            </div>
                        </div>
                        
                    </div>

                    <label class="text-primary font-weight-bold m-0">{{ localize.consumer_informations }}</label>
                    <hr class="my-3">

                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ localize.fullname }} <span class="text-danger">*</span></label>
                                <input class="form-control" placeholder="{{ localize.fill_fullName }}"
                                    formControlName="fullName">
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="form.controls.fullName" [detail]="{ name: 'fullName'}"
                                    [errorMessages]="validateMsg.fullName"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                                <label>{{ localize.personal_id }} <span class="text-danger">*</span></label>
                                <input class="form-control" placeholder="{{ localize.personal_id_placeholder }}"
                                    formControlName="personalId">
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="form.controls.personalId" [detail]="{ name: 'personalId'}"
                                    [errorMessages]="validateMsg.personalId"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                                <label>{{ localize.birthday }} <span class="text-danger">*</span></label>
                                <div class="input-group">
                                    <input class="form-control" ngbDatepicker #birthday="ngbDatepicker"
                                        [control]="form.controls.birthday" [value]="form.value.birthday"
                                        (dateSelect)="changeDateFilter($event, 'birthday')" placeholder="dd/MM/yyyy"
                                        [maxDate]="{year: currentDate.getFullYear(), month: currentDate.getMonth()+1, day: currentDate.getDate()}"
                                        appInputMark [options]="optionsCleave">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary" (click)="birthday.toggle()"
                                            type="button"><em class="fas fa-calendar-alt"></em></button>
                                    </div>
                                    <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                        [control]="form.controls.birthday" [detail]="{ name: 'birthday'}"
                                        [errorMessages]="validateMsg.birthday"></app-show-validate-errors>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ localize.place_of_birth }} <span class="text-danger">*</span></label>
                                <input class="form-control" placeholder="{{ localize.fill_place_of_birth }}"
                                    formControlName="placeOfBirth">
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="form.controls.placeOfBirth" [detail]="{ name: 'placeOfBirth'}"
                                    [errorMessages]="validateMsg.placeOfBirth"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                                <label>{{ localize.gender }} <span class="text-danger">*</span></label>
                                <ng-select formControlName="gioiTinhId" [items]="gioiTinhs" bindValue="id"
                                    bindLabel="label" [placeholder]="localize.select_gender">
                                </ng-select>
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="form.controls.gioiTinhId" [detail]="{ name: 'gioiTinhId'}"
                                    [errorMessages]="validateMsg.gioiTinhId"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                                <label>{{ localize.issue_date }} <span class="text-danger">*</span></label>
                                <div class="input-group">
                                    <input class="form-control" ngbDatepicker #issueDate="ngbDatepicker"
                                        [control]="form.controls.issueDate" [value]="form.value.issueDate"
                                        (dateSelect)="changeDateFilter($event, 'issue_date')" placeholder="dd/MM/yyyy"
                                        [maxDate]="{year: currentDate.getFullYear(), month: currentDate.getMonth()+1, day: currentDate.getDate()}"
                                        appInputMark [options]="optionsCleave">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary" (click)="issueDate.toggle()"
                                            type="button"><em class="fas fa-calendar-alt"></em></button>
                                    </div>
                                    <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                        [control]="form.controls.issueDate" [detail]="{ name: 'issueDate'}"
                                        [errorMessages]="validateMsg.issueDate"></app-show-validate-errors>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ localize.issue_place }} <span class="text-danger">*</span></label>
                                <input class="form-control" placeholder="{{ localize.fill_issue_place }}"
                                    formControlName="issuePlace">
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="form.controls.issuePlace" [detail]="{ name: 'issuePlace'}"
                                    [errorMessages]="validateMsg.issuePlace"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ localize.registered_place_of_permanent_residence }}</label>
                                <input class="form-control"
                                    placeholder="{{ localize.fill_registered_place_of_permanent_residence }}"
                                    formControlName="noiDKHKTT">
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="form.controls.noiDKHKTT" [detail]="{ name: 'noiDKHKTT'}"
                                    [errorMessages]="validateMsg.noiDKHKTT"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                                <label>{{ localize.valid_to }} <span class="text-danger">*</span></label>
                                <div class="input-group">
                                    <input class="form-control" ngbDatepicker #validTo="ngbDatepicker"
                                        [control]="form.controls.validTo" [value]="form.value.validTo"
                                        (dateSelect)="changeDateFilter($event, 'valid_to')" placeholder="dd/MM/yyyy"
                                        [maxDate]="{year: currentDate.getFullYear()+10, month: currentDate.getMonth()+1, day: currentDate.getDate()}"
                                        appInputMark [options]="optionsCleave">
                                    <div class="input-group-append">
                                        <button class="btn btn-outline-secondary" (click)="validTo.toggle()"
                                            type="button"><em class="fas fa-calendar-alt"></em></button>
                                    </div>
                                    <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                        [control]="form.controls.validTo" [detail]="{ name: 'validTo'}"
                                        [errorMessages]="validateMsg.validTo"></app-show-validate-errors>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                                <label>{{ localize.phone_number }} <span class="text-danger">*</span></label>
                                <input class="form-control" placeholder="{{ localize.fill_phone_number }}"
                                    formControlName="phoneNumber">
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="form.controls.phoneNumber" [detail]="{ name: 'phoneNumber'}"
                                    [errorMessages]="validateMsg.phoneNumber"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{ localize.email }} <span class="text-danger">*</span></label>
                                <input class="form-control" placeholder="{{ localize.fill_email }}"
                                    formControlName="email">
                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="form.controls.email" [detail]="{ name: 'email'}"
                                    [errorMessages]="validateMsg.email"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                                <label>{{ localize.tinh }}</label>
                                <ng-select formControlName="tinhId" [items]="tinhs" bindValue="id" bindLabel="label"
                                    [placeholder]="localize.select_tinh" (change)="changedTinh($event)">
                                </ng-select>
                                <!-- <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="form.controls.tinhId" [detail]="{ name: 'tinhId'}"
                                    [errorMessages]="validateMsg.tinhId"></app-show-validate-errors> -->
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                                <label>{{ localize.huyen }}</label>
                                <ng-select formControlName="huyenId" [items]="huyens" bindValue="id" bindLabel="label"
                                    [placeholder]="localize.select_huyen" (change)="changedHuyen($event)">
                                </ng-select>
                                <!-- <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="form.controls.huyenId" [detail]="{ name: 'huyenId'}"
                                    [errorMessages]="validateMsg.huyenId"></app-show-validate-errors> -->
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                                <label>{{ localize.xa }}</label>
                                <ng-select formControlName="xaId" [items]="xas" bindValue="id" bindLabel="label"
                                    [placeholder]="localize.select_xa">
                                </ng-select>
                                <!-- <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="form.controls.xaId" [detail]="{ name: 'xaId'}"
                                    [errorMessages]="validateMsg.xaId"></app-show-validate-errors> -->
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                                <label>{{ localize.street }}</label>
                                <input class="form-control" placeholder="{{ localize.fill_street }}"
                                    formControlName="duong">
                                <!-- <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="form.controls.duong" [detail]="{ name: 'duong'}"
                                    [errorMessages]="validateMsg.duong"></app-show-validate-errors> -->
                            </div>
                        </div>
                        <div class="col-md-4 col-lg-3">
                            <div class="form-group">
                                <label>{{ localize.sonha }}</label>
                                <input class="form-control" placeholder="{{ localize.fill_sonha }}"
                                    formControlName="sonha">
                                <!-- <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                    [control]="form.controls.sonha" [detail]="{ name: 'sonha'}"
                                    [errorMessages]="validateMsg.sonha"></app-show-validate-errors> -->
                            </div>
                        </div>
                    </div>

                    <label class="text-primary font-weight-bold m-0">{{ localize.taked_photo }}</label>
                    <hr class="my-3">
                    <div class="row">
                        <div class="col-md-3">
                            <label>Hình ảnh mặt trước <span *ngIf="form.value.identityLevel === 2"
                                    class="text-danger">*</span></label>
                            <div class="upload-image d-block">
                                <div class="group-upload" (click)="frontImage.click()" appDragDrop
                                    (onFileDropped)="uploadFile($event, 'FRONT')">
                                    <div class="p-2 mx-auto" style="width: 70px; height: 70px">
                                        <img src="assets/img/img-front-icon.svg" class="w-100">
                                    </div>
                                    <p class="text-black mt-4 mb-0">Kéo thả hình ảnh <br> hoặc</p>
                                    <a class="text-primary text-decoration-underline mt-4">Chọn file</a>
                                    <input id="fileInput" hidden type="file" #frontImage accept="image/png, image/jpeg"
                                        (input)="uploadFile($event.target.files, 'FRONT')">
                                </div>
                                <div *ngIf="base64FrontImage" class="image-selected" (click)="frontImage.click()">
                                    <img src="{{ base64FrontImage }}">
                                    <span class="remove-img-selected material-icons"
                                        (click)="removeImage('FRONT'); $event.preventDefault()">cancel</span>
                                </div>
                            </div>
                            <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                [control]="form.controls.frontImage" [detail]="{ name: 'frontImage'}"
                                [errorMessages]="validateMsg.frontImage"></app-show-validate-errors>
                        </div>
                        <div class="col-md-1"></div>
                        <div class="col-md-3">
                            <label>Hình ảnh mặt sau</label>
                            <div class="upload-image d-block">
                                <div class="group-upload" (click)="backImage.click()" appDragDrop
                                    (onFileDropped)="uploadFile($event, 'BACK')">
                                    <div class="p-2 mx-auto" style="width: 70px; height: 70px">
                                        <img src="assets/img/img-back-icon.svg" class="w-100">
                                    </div>
                                    <p class="text-black mt-4 mb-0">Kéo thả hình ảnh <br> hoặc</p>
                                    <a class="text-primary text-decoration-underline mt-4">Chọn file</a>
                                    <input id="fileInput" hidden type="file" #backImage accept="image/png, image/jpeg"
                                        (input)="uploadFile($event.target.files, 'BACK')">
                                </div>
                                <div *ngIf="base64BackImage" class="image-selected" (click)="backImage.click()">
                                    <img src="{{ base64BackImage }}">
                                    <span class="remove-img-selected material-icons"
                                        (click)="removeImage('BACK'); $event.preventDefault()">cancel</span>
                                </div>
                            </div>
                            <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                [control]="form.controls.backImage" [detail]="{ name: 'backImage'}"
                                [errorMessages]="validateMsg.backImage"></app-show-validate-errors>
                        </div>
                        <div class="col-md-1"></div>
                        <div class="col-md-3">
                            <label>Hình ảnh chân dung <span *ngIf="form.value.identityLevel === 2"
                                    class="text-danger">*</span></label>
                            <div class="upload-image d-block">
                                <div class="group-upload" (click)="portraitImage.click()" appDragDrop
                                    (onFileDropped)="uploadFile($event, 'PORTRAIT')">
                                    <div class="p-2 mx-auto" style="width: 70px; height: 70px">
                                        <img src="assets/img/img-portrait-icon.svg" class="w-100">
                                    </div>
                                    <p class="text-black mt-4 mb-0">Kéo thả hình ảnh <br> hoặc</p>
                                    <a class="text-primary text-decoration-underline mt-4">Chọn file</a>
                                    <input id="fileInput" hidden type="file" #portraitImage
                                        accept="image/png, image/jpeg"
                                        (input)="uploadFile($event.target.files, 'PORTRAIT')">
                                </div>
                                <div *ngIf="base64PortraitImage" class="image-selected" (click)="portraitImage.click()">
                                    <img src="{{ base64PortraitImage }}">
                                    <span class="remove-img-selected material-icons"
                                        (click)="removeImage('PORTRAIT'); $event.preventDefault()">cancel</span>
                                </div>
                            </div>
                            <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                [control]="form.controls.portraitImage" [detail]="{ name: 'portraitImage'}"
                                [errorMessages]="validateMsg.portraitImage"></app-show-validate-errors>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>

<app-spinner></app-spinner>

<app-preview-modal *ngIf="showFile" [file]="pdfFile" [name]="inputFileLabel" [useDownloadEvent]="attachFile == null"
    (close)="closePreview()"></app-preview-modal>
