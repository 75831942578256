import { MainResponse } from '../../../main-response.model';
import { DigitalSignatureItem } from './digital-signature-item';

export class DigitalSignatureResponse extends MainResponse {
    object: Array<DigitalSignatureItem> = [];

    constructor(obj = null) {
        super();
        obj = obj || {};
        if (obj.object && obj.object instanceof Array) {
            obj.object.forEach(item => {
                this.object.push(new DigitalSignatureItem(item));
            });
        }
    }
}