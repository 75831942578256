<!-- <app-spinner></app-spinner> -->
<div class="ct-wv-content ct-enable-scroll ">
    <div class="ct-wv-container">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <!-- <div class="row justify-content-center mt-5 pt-5 pb-2 mb-2">
                    <div class="col-md-10">
                        <div class="text-center">
                            <h1 class="font-weight-bold">Chào mừng bạn đến với hệ thống VNPT eContract</h1>
                            <h5 class="text-muted">Chào mừng bạn đến với hệ thống VNPT eContract</h5>
                        </div>
                    </div>
                </div> -->
                <div class="row justify-content-center my-3 py-5">
                    <div class="col-xl-8 col-lg-9 col-md-10 col-sm-11">
                        <h2 class="font-weight-bold text-center mb-5">Đăng nhập bằng OTP</h2>
                        
                        <div class="card bg-white shadow-lg rounded-2">
                            <form [formGroup]="loginForm" class="card-body pt-5">
                                <div class="input-group input-custom mb-4">
                                    <span class="input-custom-label"><span class="text-danger">*</span> Tài khoản</span>
                                    <input appTrimValue formControlName="username" type="text"
                                        class="form-control form-control-lg input-custom-control" placeholder="Tài khoản" />
                                    <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="validateMsg.username"
                                        [control]="username" [detail]="{ name: 'username' }"></app-show-validate-errors>
                                </div>
                                <div class="row">
                                    <div class="col-12 mb-4">
                                        <button [disabled]="disabledButton" (click)="login()" type="submit"
                                            class="btn btn-lg btn-primary btn-block b-radius-5px">
                                            Gửi mã OTP
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>