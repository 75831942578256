import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ValidatorsExtension } from 'src/app/utils/common/validators-extension';
import { REGEXP_EMAIL, REGEXP_MST, REGEXP_PASSWORD, REGEXP_USERNAME } from 'src/app/utils/common/regexp';
import { SIGN_ON_BUSINESS } from 'src/app/utils/common/constant-vaildate-msg';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { Config } from 'src/app/config';

@Component({
  selector: 'app-signup-step-one',
  templateUrl: './signup-step-one.component.html',
  styleUrls: ['./signup-step-one.component.scss']
})
export class SignupStepOneComponent implements OnInit {
  @Output('next') next: EventEmitter<any> = new EventEmitter<any>();

  constantUrl = ConstantUrl;
  config = Config;

  formSubmit: FormGroup;
  isSubmit: boolean = false;
  validateMsg = SIGN_ON_BUSINESS;

  constructor(
    private fb: FormBuilder,
    private activedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.makeFormSubmit(); this.setDataTest();
    this.getDataFromQueryParams();
  }

  /**
   * Lấy thông tin truyền từ queryParam
   */
  getDataFromQueryParams() {
    this.formSubmit.controls.tenDoanhNghiep.setValue(this.activedRoute.snapshot.queryParamMap.get('tenDoanhNghiep') || '');
    this.formSubmit.controls.tenVietTat.setValue(this.activedRoute.snapshot.queryParamMap.get('tenVietTat') || '');
    this.formSubmit.controls.maSoThue.setValue(this.activedRoute.snapshot.queryParamMap.get('maSoThue') || '');
  }

  makeFormSubmit() {
    this.formSubmit = this.fb.group({
      tenDoanhNghiep: new FormControl('', [Validators.required, ValidatorsExtension.validateCompanyName]),
      tenVietTat: new FormControl('', [Validators.required, ValidatorsExtension.validateShortCompanyName]),
      maSoThue: new FormControl('', [Validators.required, Validators.pattern(REGEXP_MST)]),

      hoVaTen: new FormControl('', [Validators.required, ValidatorsExtension.validateFullName]),
      email: new FormControl('', [Validators.required, Validators.pattern(REGEXP_EMAIL)]),

      username: new FormControl('', [Validators.required, Validators.pattern(REGEXP_USERNAME)]),
      password: new FormControl('', [Validators.required, Validators.pattern(REGEXP_PASSWORD)]),

      domain: new FormControl('', [Validators.required])
    });
  }

  setDataTest() {
    this.formSubmit.patchValue({
      hoVaTen: 'Doanh nghiep mot',
      email: 'dnghiep.001@gmail.com',
      username: 'dnghiep.001',
      password: 'Abc@12345',
      domain: 'dnghiep001'
    })
  }

  actNext() {
    this.isSubmit = true;
    if (this.formSubmit.valid) {
      this.next.emit(this.formSubmit.value);
    }
  }
}
