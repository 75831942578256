import {InfoSellerParty} from "./einvoices-info-seller.model"
import { MainResponse } from '../../../main-response.model';

export class InfoSellerPartyResponse extends MainResponse {
    
    constructor(obj = null) {
        super();
        obj = obj || {};
        this.init(obj);
        if (obj.object && obj.object instanceof Array) {
                this.object = new InfoSellerParty(obj.object);

        }
    }
}