<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-auto">
                <h1 class="m-0 text-dark"><label>{{ 'ConfigurationInformation_title' | translate }}</label></h1>
            </div>
            <div class="col-md-auto ml-auto">
                <button (click)="edit()" class="btn btn-primary d-inline-flex mr-3"><span
                    class="material-icons mr-1">edit</span>{{ 'btn_edit' | translate }}</button>
            </div>
        </div>
    </div>
</div>


<section class="content">
    <div class="container-fluid">
        <div class="">
            <div class="content-right-sidebar tab-bar-border-bottom border-tab mb-4">
                <ul class="nav nav-tabs" id="tabSignAction" role="tablist">
                    <li class="nav-item nav-material">
                        <a class="nav-link cursor-pointer d-flex px-3 py-3" [class.active]="tabSelected === 'CMND'"
                            (click)="selectTab('CMND')">
                            <span class="material-icons">card_membership</span>
                            {{ 'ConfigurationInformation_tab1' | translate }}
                        </a>
                    </li>
                    <li class="nav-item nav-material">
                        <a class="nav-link cursor-pointer d-flex px-3 py-3" [class.active]="tabSelected === 'PASSPORT'"
                            (click)="selectTab('PASSPORT')">
                            <span class="material-icons">card_membership</span>
                            {{ 'ConfigurationInformation_tab2' | translate }}
                        </a>
                    </li>

                    <li class="nav-item nav-material">
                        <a class="nav-link cursor-pointer d-flex px-3 py-3" [class.active]="tabSelected === 'CCCD'"
                            (click)="selectTab('CCCD')">
                            <span class="material-icons">memory</span>
                            {{ 'ConfigurationInformation_tab3' | translate }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <div class="tab-content contract-tab-body" id="tabSignAction">
            <div class="tab-pane fade" [class.active]="tabSelected === 'CMND'" [class.show]="tabSelected === 'CMND'">
                <app-idcard></app-idcard>
            </div>
            <div class="tab-pane fade" [class.active]="tabSelected === 'PASSPORT'" [class.show]="tabSelected === 'PASSPORT'">
                <app-passport></app-passport>
            </div>
            <div class="tab-pane fade" [class.active]="tabSelected === 'CCCD'" [class.show]="tabSelected === 'CCCD'">
                <app-citizen-id-with-chip></app-citizen-id-with-chip>
            </div>
        </div>
    </div>
</section>