import { ListSignatureResponse } from 'src/app/utils/models/aas-models/contract/list-signature/list-signature-response.model';
import { ListSignatureObject } from 'src/app/utils/models/aas-models/contract/list-signature/list-signature-object.model';
import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Options, Select2AjaxOptions } from 'select2';
import { Select2OptionData } from 'ng-select2';
import { FormGroup, FormBuilder, FormControl, Validators, Form, NgModel } from '@angular/forms';
import { ValidateMessage, ServiceMsg } from 'src/app/utils/common/validate-msg-aas';
import { AasContractService } from 'src/app/utils/services/aas-network/aas-contract.service';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { Config } from 'src/app/config';
import { AuthService } from 'src/app/utils/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location, DatePipe } from '@angular/common';
import { ContractObject } from 'src/app/utils/models/aas-models/contract/contract/contract-object.model';
import { ContractResponse } from 'src/app/utils/models/aas-models/contract/contract/contract-response.model';
import { InfoSignatureConfigObject } from 'src/app/utils/models/aas-models/signature/signature-config/info-signature-config-object';
import { ContractStatus } from 'src/app/utils/common/constant';
import { DigitalSignatureItem } from 'src/app/utils/models/aas-models/signature/digital-signature/digital-signature-item';
import { UserInformationsResponse } from 'src/app/utils/models/profile/user-info-response.model';
import { UserInformationsItem } from 'src/app/utils/models/profile/user-info-item.model';
import { AasLicensesService } from 'src/app/utils/services/aas-network/aas-licenses-manager.service'

declare var PDFSign: any;
declare var $: any;
@Component({
  selector: 'app-contract-info',
  templateUrl: './contract-info.component.html',
  styleUrls: ['./contract-info.component.scss'],
  providers: [DatePipe]
})
export class ContractInfoComponent implements OnInit {
  @ViewChild('output') output: ElementRef;
  @ViewChild('filename') filename: ElementRef;
  @ViewChild('filebase') filebase: ElementRef;
  @ViewChild('contentConfirmOTP') contentConfirmOTP: ElementRef;

  public exampleData: Array<Select2OptionData> = [];
  public nguoiNhanData: Array<Select2OptionData> = [];
  public hinhThucKyData: Array<Select2OptionData> = [];
  public options: Options;
  public optionsTemplate: Options;
  public optionsHinhThucKy: Options;
  public ajaxOptions: Select2AjaxOptions;

  action: string = this.activeRoute.snapshot.queryParamMap.get('action') || '';
  contractId: string;
  licenseId = this.activeRoute.snapshot.paramMap.get('id');

  contract: ContractObject = new ContractObject();

  //mark: user
  userInfo: UserInformationsItem = new UserInformationsItem();
  userType: string = '';

  //mark: nhóm chức năng khởi tạo thông tin hợp đồng
  filePDF: File = null;
  inputFileLabel: string = 'Chọn file';
  fileType: Array<string> = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
  selectedFile: boolean = false;
  fileName: string = '';
  typeSelected: number = 1;

  formContract: FormGroup;
  validContractMsg: any = ServiceMsg.FORM_CREATE_CONTRACT;
  isSubmitContract: boolean = false;

  currentStage = ContractStatus;

  //mark: nhóm chức năng ký usb-token
  timeCheck: any;

  //mark: nhóm chức năng ký hợp đồng bằng sign server
  formSign: FormGroup;
  isSubmit: boolean = false;
  validMsg: any = ServiceMsg.SIGNATURE_CONFIG;
  inputFileImageLabel: string = 'Chọn file';
  img_prefix = "data:image/png;base64,";
  imgContent: string = '';
  imgSignServer: File = null;
  imgType = ['image/jpeg', 'image/png', 'image/jpg'];
  pdfSigned: Blob = null;
  keyTest: string = Config.KEY_PLUGIN_CA;

  //mark: phương thức ký
  selectedWayOfSign: string = '';
  //Config CA
  currentSignBoxStyle: number = 5;
  configCA: InfoSignatureConfigObject = new InfoSignatureConfigObject();

  //mark: nhóm chức năng ký điện tử
  formDSign: FormGroup;
  isESignSubmit: boolean = false;
  validMsgESign = ServiceMsg.E_SIGNATURE_CONFIG;
  imgESignContent: string = '';
  inputImgESignLabel: string = 'Chọn file';
  imgESign: File = null;
  //cert ký điện tử đã chọn
  eCertSelected: DigitalSignatureItem = new DigitalSignatureItem();
  //form confirm top e-sign
  formOTP: FormGroup;
  isOTPSubmit: Boolean = false;
  validMsgOTP = ServiceMsg.CONFIRM_OTP_E_SIGN;
  idSign: string = '';

  //Chi tiet chu ky
  listSignature: ListSignatureObject = new ListSignatureObject();


  //Tab controll
  activeTab: string = 'INFO';

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private fb: FormBuilder,
    private contractService: AasContractService,
    private authService: AuthService,
    private modalService: NgbModal,
    private datePipe: DatePipe,
    private licensesService: AasLicensesService
  ) {
    this.timeCheck = this.datePipe.transform(new Date(), "yyyy-MM-dd'T'HH:mm:ss");
  }

  ngOnInit(): void {
    PDFSign.removeAllSignBox();

    // this.makeFormSign();
    this.makeFormDSign();

    this.imgContent = this.img_prefix + PDFSign.getData().imageSrc;

    this.getUserInfo(this.authService.userId);
    this.getContract(this.licenseId);
  }

  //MARK: KHỞI TẠO FORM KÝ ĐIỆN TỬ
  makeFormDSign() {
    this.formDSign = this.fb.group({
      imageFile: new FormControl(''),
    })
  }

  get imageFileESign() { return this.formDSign.get('imageFile'); }

  //MARK: CHỨC NĂNG ĐIỀU KHIỂN TAB
  //Action chọn tab
  selectTab(nameTab: string) {
    this.activeTab = nameTab;

    // if (nameTab == 'SIGN') {
    //   this.selectWayOfSign('SIGN_SERVER');
    // }
  }

  //MARK: THAY ĐỔI GIÁ TRỊ DROPLIST
  changedHinhThucKy(event: Array<any>) {
  }

  //Lấy và hiển thị trạng thái hợp đồng
  get getStatusContract() {
    if (this.currentStage[this.contract.currentStage] !== undefined && this.currentStage[this.contract.currentStage] !== null) {
      return `<span class="badge ${this.currentStage[this.contract.currentStage].style || ''} p-2">${this.currentStage[this.contract.currentStage].label || ''}</span>`
    } else {
      return '';
    }
  }

  //Tải xuống file hợp đồng
  saveContract() {
    if (this.contract.currentStage === 'LC_CONTRACT_VALID') {
      this.saveFileContract(this.contractId, 'CONTRACT');
    } else {
      this.saveFileContract(this.contractId, 'DRAFT');
    }
  }

  //Trở lại trang trước
  goToBack() {
    // this._location.back();
    this.router.navigate(['/console/licenses-manager/list']);
  }

  //Đến cập nhật hợp đồng
  goToEdit() {
    this.router.navigate(['/app/console/contract/update', this.contractId]);
  }

  //MARK: COMMON FUNC
  //Kiểm tra form đã có dữ liệu chưa
  get editedForm() {
    return false;
  }


  getBase64String(blob, callback) {
    var self = this;
    var reader = new FileReader();
    reader.onload = function (e) {
      callback(reader.result, self);
    }

    reader.readAsDataURL(blob);
  }

  //handel cho file pdf
  handle(str, self) {
    $('#fileBase').val(str.split(',')[1]);
  }

  //Handle cho file hình sign server
  handleImgSignServer(str, self) {
    self.imgContent = str;
    self.base64Image.setValue(str.split(',')[1]);
    PDFSign.setImage(str.split(',')[1]);
  }

  //Lấy thông tin hợp đồng
  getContract(id) {
    this.licensesService.getContractInfo(id).subscribe(res => {
      this.contract = new ContractResponse(res).object;
      this.contractId = this.contract.contractId
      if (this.contract.currentStage === 'LC_CONTRACT_VALID') {
        this.downloadFileContract(this.contractId, 'CONTRACT');
        this.getListSignatureByContract(this.contractId, 'CONTRACT');
      }
      else {
        this.downloadFileContract(this.contractId, 'DRAFT');
        this.getListSignatureByContract(this.contractId, 'DRAFT');
      }

      if (this.action === 'SIGN') {
        this.selectTab(this.action);
      }
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    })
  }

  //Tải file hợp đồng 
  downloadFileContract(id, type) {
    this.contractService.downloadFileContract(id, type).subscribe((res) => {
      const blob: any = new Blob([res], { type: 'application/pdf' });
      this.filePDF = blob;
      PDFSign.preview(blob);
      this.fileName = `${id}.pdf`;
      this.getBase64String(this.filePDF, this.handle);
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    })
  }

  //Tải và lưu file hợp đồng 
  saveFileContract(id, type) {
    this.contractService.downloadFileContract(id, type).subscribe((res) => {
      const blob: any = new Blob([res], { type: 'application/pdf' });
      var url = window.URL.createObjectURL(blob);
      var anchor = document.createElement("a");
      anchor.download = `${id}.pdf`;
      anchor.href = url;
      anchor.click();
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    })
  }

  // download file pdf hợp đồng=> render file HĐ
  downloadFile(url) {
    this.contractService.dowloadFile(url).subscribe(res => {
      const blob: any = new Blob([res], { type: 'application/pdf' });
      this.pdfSigned = blob;
      PDFSign.preview(blob);
      this.fileName = `${this.contractId}_signed.pdf`;
      // this.getBase64String(this.filePDF, this.handle);
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.MANAGEMENT_SERVICE);
    })
  }

  //Lấy danh sách chữ ký trên hđ
  getListSignatureByContract(contractId, documentType) {
    this.contractService.getListSignatureByContract(contractId, documentType).subscribe(res => {
      console.log(res);
      this.listSignature = new ListSignatureResponse(res).object;
    }, err => {
      console.log(err);
      // this.handleError(err, ResponseMsg.MESSAGES.ESIGN_SERVICE);
    })
  }

  //Lấy thông tin user đăng nhập
  getUserInfo(id) {
    this.authService.getUserInfo(id).subscribe((res) => {
      this.userInfo = new UserInformationsResponse(res).object;
      this.userType = this.userInfo.userType;
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.AUTH_SERVICE);
    }, () => {
    })
  }

  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], 'error');
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, 'error');
    } else {
      this.alert('Lỗi không xác định! Vui lòng thử lại', 'error');
    }
    }
  

}
