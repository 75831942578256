export class EinvoicesDetailObject {
    tenHoaDon : string;
    ngayPhatHanh : string;
    trangThai: string;
    ghiChu : string;
    taxCode : string;
    cusEmail: string;
    fkey: string;
    companyTaxCode: string;
    cusCode : string;
    constructor(obj = null) {
        this.tenHoaDon = obj.name || '';
        this.ngayPhatHanh = obj.createDate || '';
        this.trangThai = obj.paymentStatus || '';
        this.ghiChu = obj.note || '';
        this.taxCode = obj.cusTaxCode || '';
        this.cusEmail = obj.cusEmail || '';
        this.fkey = obj.fkey || '';
        this.companyTaxCode = obj.companyTaxCode || '';
        this.cusCode = obj.cusCode || '';
    }
}