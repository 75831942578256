import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Select2OptionData } from 'ng-select2';
import { Options } from 'select2';
import { Param } from 'src/app/utils/models/aas-models/api-manager/param/param.model';
import { ApiManagerService } from 'src/app/utils/services/aas-network/api-manager.service';
import { API_FORM } from 'src/app/utils/common/constant-vaildate-msg';
import { ActivatedRoute, Router } from '@angular/router';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { ConstantAlertBtn, ConstantAlertMsg } from 'src/app/utils/common/constant-alert-msg';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ListAllPermisionReponse } from 'src/app/utils/models/user-manager/list-all-permission/list-all-permission-response';
import { PermissionGroup } from 'src/app/utils/models/user-manager/list-all-permission/permission-group';
import { ApiDetailObject } from 'src/app/utils/models/aas-models/api-manager/api-detail/api-detail-object.model';
import { ApiDetailResponse } from 'src/app/utils/models/aas-models/api-manager/api-detail/api-detail-response.model';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';

const ELEMENT_HEADER = "header";
const ELEMENT_BODY = "body";
const ELEMENT_PARAM = "param";
const ELEMENT_OUTPUT_SUCCESS = "outputSuccess";
const ELEMENT_OUTPUT_ERROR = "outputError";
@Component({
  selector: 'app-service-api-editor',
  templateUrl: './service-api-editor.component.html',
  styleUrls: ['./service-api-editor.component.scss']
})
export class ServiceApiEditorComponent implements OnInit {
  @ViewChild('tableParamsHeader', { static: false }) tableParamsHeader: ElementRef;

  apiId: string = this.activeRoute.snapshot.paramMap.get('id');
  apiDetail: ApiDetailObject = new ApiDetailObject();
  roleId: string = "";
  
  apiForm: FormGroup;
  isSubmitApiForm: boolean = false;
  apiFormMsg = API_FORM;

  allFeatures: PermissionGroup[] = [];

  optionsMethod: Options;
  methods: Array<Select2OptionData> = [];

  optionsFeature: Options;
  features: Array<Select2OptionData> = [];

  optionsObligatory: Options;
  obligatories: Array<Select2OptionData> = [];

  paramsHeader: Array<Param> = [];
  paramsBody: Array<Param> = [];
  paramsParam: Array<Param> = [];
  paramsOutputSuccess: Array<Param> = [];
  paramsOutputError: Array<Param> = [];

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private apiService: ApiManagerService,
    private authService: AasAuthService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.makeApiForm();
    this.makeMethodSelector();
    this.makeFeatureSelector();
    this.makeObligatorySelector();

    this.getAllPrivileges();
    // this.getAccountRoles();

    if (this.apiId) {
      this.getDetailApi(this.apiId);
    } else {
      this.initData();
    }
  }

  /**
   * Khoi tao form api
   */
  makeApiForm() {
    this.apiForm = this.fb.group({
      pathName: ['', [Validators.required]],
      method: ['', [Validators.required]],
      description: ['', [Validators.required]],
      roleId: [''],
      privilegesId: ['', [Validators.required]],
      privilegesName: [''],
      exampleHeader: [''],
      exampleBody: [''],
      exampleParam: ['', [Validators.required]],
      exampleOutput: ['', [Validators.required]],
      paramError: ['', [Validators.required]],
    })
  }

  /**
   * Khoi tao ngselect2 method
   */
  makeMethodSelector() {
    this.methods = [
      {
        id: 'POST',
        text: 'POST'
      },
      {
        id: 'GET',
        text: 'GET'
      },
      {
        id: 'PUT',
        text: 'PUT'
      },
      {
        id: 'DELETE',
        text: 'DELETE'
      }
    ]

    this.optionsMethod = {
      minimumResultsForSearch: Infinity,
      dropdownAutoWidth: true,
      width: '100%',
      closeOnSelect: true,
      language: "vi"
    }
  }

  /**
   * Khoi tao ngselect2 feature
   */
  makeFeatureSelector() {
    this.optionsFeature = {
      // minimumResultsForSearch: Infinity,
      dropdownAutoWidth: true,
      width: '100%',
      closeOnSelect: true,
      language: "vi"
    }
  }

  /**
   * Khoi tao ngselect obligatory
   */
  makeObligatorySelector() {
    this.obligatories = [
      {
        id: 'true',
        text: 'Có'
      },
      {
        id: 'false',
        text: 'Không'
      }
    ];

    this.optionsObligatory = {
      // minimumResultsForSearch: Infinity,
      dropdownAutoWidth: true,
      width: '100%',
      closeOnSelect: true,
      language: "vi"
    }
  }

  /**
   * Khoi tao du lieu ban dau
   */
  initData() {
    this.paramsHeader.push(new Param({
      element: ELEMENT_HEADER,
      obligatory: 'false'
    }));

    this.paramsBody.push(new Param({
      element: ELEMENT_BODY,
      obligatory: 'false'
    }));

    this.paramsParam.push(new Param({
      element: ELEMENT_PARAM,
      obligatory: 'false'
    }));

    this.paramsOutputSuccess.push(new Param({
      element: ELEMENT_OUTPUT_SUCCESS,
      obligatory: 'false'
    }));

    this.paramsOutputError.push(new Param({
      element: ELEMENT_OUTPUT_ERROR,
      obligatory: 'false'
    }));
  }

  /**
   * Set du lieu cu
   * @param data 
   */
  initDataOld(data: ApiDetailObject) {
    this.apiForm.patchValue({
      pathName: data.apiObject.pathName,
      method: data.apiObject.method,
      description: data.apiObject.description,
      roleId: data.apiObject.roleId,
      privilegesId: data.apiObject.privilegesId,
      privilegesName: data.apiObject.privilegesName,
      exampleHeader: data.apiObject.exampleHeader,
      exampleBody: data.apiObject.exampleBody,
      exampleParam: data.apiObject.exampleParam,
      exampleOutput: data.apiObject.exampleOutput
    });

    data.listParamInfo.forEach((item) => {
      switch(item.element) {
        case ELEMENT_HEADER:
          this.paramsHeader.push(new Param(item));
          break;
        case ELEMENT_BODY:
          this.paramsBody.push(new Param(item));
          break;
        case ELEMENT_PARAM:
          this.paramsParam.push(new Param(item));
          break;
        case ELEMENT_OUTPUT_SUCCESS:
          this.paramsOutputSuccess.push(new Param(item));
          break;
        case ELEMENT_OUTPUT_ERROR:
          this.paramsOutputError.push(new Param(item));
          break;
      }
    });
  }

  /**
   * Chuyen doi giua trang thai chinh sua va hien thi tren table
   * @param type 
   * @param index 
   * @param allowEdit 
   */
  changeEditMode(type: string = '', index, allowEdit: boolean = false, fieldEdit: string = '') {
    switch (type) {
      case ELEMENT_HEADER:
        this.paramsHeader[index].addition[fieldEdit] = allowEdit;
        break;
      case ELEMENT_BODY:
        this.paramsBody[index].addition[fieldEdit] = allowEdit;
        break;
      case ELEMENT_PARAM:
        this.paramsParam[index].addition[fieldEdit] = allowEdit;
        break;
      case ELEMENT_OUTPUT_SUCCESS:
        this.paramsOutputSuccess[index].addition[fieldEdit] = allowEdit;
        break;
      case ELEMENT_OUTPUT_ERROR:
        this.paramsOutputError[index].addition[fieldEdit] = allowEdit;
        break;
      default:
        break;
    }

    console.log('blur');
  }

  /**
   * Them dong cho bang
   * @param type 
   */
  addRowTable(type: string = '') {
    switch (type) {
      case ELEMENT_HEADER:
        this.paramsHeader.push(new Param({ element: ELEMENT_HEADER, obligatory: 'false' }));
        break;
      case ELEMENT_BODY:
        this.paramsBody.push(new Param({ element: ELEMENT_BODY, obligatory: 'false' }));
        break;
      case ELEMENT_PARAM:
        this.paramsParam.push(new Param({ element: ELEMENT_PARAM, obligatory: 'false' }));
        break;
      case ELEMENT_OUTPUT_SUCCESS:
        this.paramsOutputSuccess.push(new Param({ element: ELEMENT_OUTPUT_SUCCESS, obligatory: 'false' }));
        break;
      case ELEMENT_OUTPUT_ERROR:
        this.paramsOutputError.push(new Param({ element: ELEMENT_OUTPUT_ERROR, obligatory: 'false' }));
        break;
      default:
        break;
    }
  }

  /**
   * Xoa 1 dong tren bang
   * @param type 
   * @param index 
   */
  removeRowTable(type: string = '', index) {
    switch (type) {
      case ELEMENT_HEADER:
        this.paramsHeader.splice(index, 1);
        break;
      case ELEMENT_BODY:
        this.paramsBody.splice(index, 1);
        break;
      case ELEMENT_PARAM:
        this.paramsParam.splice(index, 1);
        break;
      case ELEMENT_OUTPUT_SUCCESS:
        this.paramsOutputSuccess.splice(index, 1);
        break;
      case ELEMENT_OUTPUT_ERROR:
        this.paramsOutputError.splice(index, 1);
        break;
      default:
        break;
    }
  }

  /**
   * Lay data tu codeblock Request Body
   * @param e 
   */
  getDataExampleBody(e) {
    this.apiForm.controls.exampleBody.setValue(e);
    console.log(this.apiForm.controls.exampleBody.value);
  }

  /**
   * Lay data tu codeblock Request Param
   * @param e 
   */
  getDataExampleParam(e) {
    this.apiForm.controls.exampleParam.setValue(e);
    console.log(this.apiForm.controls.exampleParam.value);
  }

  /**
   * Lay data tu codeblock Response
   * @param e 
   */
  getDataExampleOutput(e) {
    this.apiForm.controls.exampleOutput.setValue(e);
    console.log(this.apiForm.controls.exampleOutput.value);
  }

  /**
   * Kiem tra du lieu table
   */
  checkValidParam() {
    let countRowNoValue = 0;
    this.paramsOutputError.forEach((item) => {
      if (!item.paramName || item.paramName === '') {
        countRowNoValue++;
      }
    })
    if (countRowNoValue === this.paramsOutputError.length) {
      this.apiForm.controls.paramError.setErrors({required: true})
    } else {
      this.apiForm.patchValue({
        paramError: JSON.stringify(this.paramsOutputError)
      })
    }
  }

  /**
   * Nut luu thong tin
   * @param event 
   */
  save(event) {
    this.isSubmitApiForm = true;
    this.checkValidParam();

    let privilegeGroup = this.allFeatures.find((item) => {
      let prIndex = item.privileges.findIndex((pr) => {
        return pr.id === this.apiForm.value.privilegesId
      })

      if (prIndex >= 0) {
        this.apiForm.controls.privilegesName.setValue(item.privileges[prIndex].description);
        return true;
      } else {
        return false;
      }
    });

    if (this.apiForm.valid) {
      var param = {
        apiMenu: {
          pathName: this.apiForm.value.pathName,
          method: this.apiForm.value.method,
          description: this.apiForm.value.description,
          privilegesId: this.apiForm.value.privilegesId,
          privilegesName: this.apiForm.value.privilegesName,
          roleId: this.roleId, //privilegeGroup.id || '',
          exampleHeader: null,
          exampleBody: this.apiForm.value.exampleBody,
          exampleParam: this.apiForm.value.exampleParam, 
          exampleOutput: this.apiForm.value.exampleOutput
        },
        listParamInfo: []
      };

      if (param.listParamInfo && param.listParamInfo instanceof Array) {
        this.paramsHeader.forEach((item) => {
          param.listParamInfo.push({
            paramName: item.paramName,
            type: item.type,
            description: item.description,
            element: item.element,
            obligatory: item.obligatory
          })
        })

        this.paramsBody.forEach((item) => {
          param.listParamInfo.push({
            paramName: item.paramName,
            type: item.type,
            description: item.description,
            element: item.element,
            obligatory: item.obligatory
          })
        })

        this.paramsParam.forEach((item) => {
          param.listParamInfo.push({
            paramName: item.paramName,
            type: item.type,
            description: item.description,
            element: item.element,
            obligatory: item.obligatory
          })
        })

        this.paramsOutputSuccess.forEach((item) => {
          param.listParamInfo.push({
            paramName: item.paramName,
            type: item.type,
            description: item.description,
            element: item.element,
            obligatory: item.obligatory
          })
        })

        this.paramsOutputError.forEach((item) => {
          param.listParamInfo.push({
            paramName: item.paramName,
            type: item.type,
            description: item.description,
            element: item.element,
            obligatory: item.obligatory
          })
        })
      }

      console.log(param);

      if (this.apiId) {
        this.updateApi(this.apiId, param);
      } else {
        this.createApi(param);
      }
    }
  }

  /**
   * Tao moi api
   * @param param 
   */
  createApi(param) {
    this.alert(ConstantAlertMsg.CREAT_API_CONFIRM, '', true, '', ConstantAlertBtn.CONFIRM, () => {
      this.postCreateApi(param);
    }, '', ConstantAlertBtn.CANCEL)
  }

  /**
   * Cap nhat api
   * @param id 
   * @param param 
   */
  updateApi(id, param) {
    this.alert(ConstantAlertMsg.UPDATE_API_CONFIRM, '', true, '', ConstantAlertBtn.CONFIRM, () => {
      this.putUpdateApi(id, param);
    }, '', ConstantAlertBtn.CANCEL)
  }

  check(e) {
    console.log(e);
    var parent = this.features.find((item) => {
      let ok = false;
      item.children.forEach((child) => {
        if (child.id === e) ok = true;
      });
      return ok;
    })

    if (parent) {
      this.roleId = parent.id;
    }
  }

  /**
   * Nut huy luu thong tin
   * @param event 
   */
  cancel(event) {
    // this.router.navigate([ConstantUrl.ADMIN_API_LIST]);
    this.resetForm();
    if (this.apiId) {
      this.getDetailApi(this.apiId);
    }
    console.log("canceled");
  }

  /**
   * Reset lai form
   */
  resetForm() {
    this.apiForm.reset();
    this.isSubmitApiForm = false;
  }

  //MARK: NETWORKING
  /**
   * Lấy thông tin chi tiết api
   * @param id 
   */
  getDetailApi(id) {
    this.apiService.getDetailApi(id).subscribe((res: any) => {
      this.apiDetail = new ApiDetailResponse(res).object;
      this.initDataOld(this.apiDetail);
    }, err => {
      this.handleError(err, ResponseMsg.MESSAGES.ALL_IN_ONE);
    })
  }
  
  /**
   * Lay danh sach tat cac ca quyen
   */
  getAllPrivileges() {
    this.authService.getAllPrivileges().subscribe((res: any) => {
      this.allFeatures = new ListAllPermisionReponse(res).object;

      this.features = this.allFeatures.map((item) => {
        let childs = item.privileges.map((pr) => {
          return {
            id: pr.id,
            parentId: item.id,
            text: pr.description
          }
        })
        return { 
          id: item.id,
          text: item.name,
          children: childs
        }
      })

      console.log(this.features);
      // this.createMultiApi();
    }, err => {
      this.handleError(err, ResponseMsg.MESSAGES.ALL_IN_ONE);
    })
  }

  /**
   * Tạo tu dong hang loat thong tin api
   */
  createMultiApi() {
    for (var i = 0; i < this.features.length; i++) {
      for (var j = 0; j < this.features[i].children.length; j++) {
        let param = {
          "apiMenu": {
              "pathName": "/api/demo",
              "method": "GET",
              "description": this.features[i].children[j].text,
              "privilegesId": this.features[i].children[j].id,
              "privilegesName": this.features[i].children[j].text,
              "roleId": this.features[i].id,
              "exampleHeader": null,
              "exampleBody": "{\n\t\"inputKey1\": \"string\",\n\t\"inputKey2\": \"string\"\n}",
              "exampleParam": "{\n\t\"inputKey1\": \"string\",\n\t\"inputKey2\": \"string\"\n}",
              "exampleOutput": "{\n\t\"outputKey1\": \"string\",\n\t\"outputKey2\": \"string\"\n}"
          },
          "listParamInfo": [
              {
                  "paramName": "accessToken",
                  "type": "",
                  "description": "Access Token",
                  "element": "header",
                  "obligatory": "false"
              },
              {
                  "paramName": "inputKey1",
                  "type": "string",
                  "description": "",
                  "element": "body",
                  "obligatory": "false"
              },
              {
                  "paramName": "inputKey2",
                  "type": "string",
                  "description": "",
                  "element": "body",
                  "obligatory": "false"
              },
              {
                  "paramName": "inputKey1",
                  "type": "string",
                  "description": "",
                  "element": "param",
                  "obligatory": "true"
              },
              {
                  "paramName": "inputKey2",
                  "type": "string",
                  "description": "",
                  "element": "param",
                  "obligatory": "true"
              },
              {
                  "paramName": "outputKey1",
                  "type": "string",
                  "description": "",
                  "element": "outputSuccess",
                  "obligatory": "false"
              },
              {
                  "paramName": "outputKey2",
                  "type": "string",
                  "description": "",
                  "element": "outputSuccess",
                  "obligatory": "false"
              },
              {
                  "paramName": "ECT-00000000",
                  "type": "",
                  "description": "Thành công",
                  "element": "outputError",
                  "obligatory": "false"
              },
              {
                  "paramName": "ECT-00000400",
                  "type": "",
                  "description": "Thất bại",
                  "element": "outputError",
                  "obligatory": "false"
              }
          ]
        }

        this.apiService.postCreateApi(param).subscribe((res) => {

        }, err => {
          console.log();
        });
      }
    }
  }
  
  /**
   * Khoi tao thong tin api
   * @param param 
   */
  postCreateApi(param) {
    this.apiService.postCreateApi(param).subscribe((res: any) => {
      this.alert(ConstantAlertMsg.CREATED_API_SUCCESS, ConstantAlertType.SUCCESS, false, '', ConstantAlertBtn.OK, () => {
        this.router.navigate([ConstantUrl.ADMIN_API_DETAIL, res.object.apiObject.apiId]);
      });
    }, err => {
      this.handleError(err, ResponseMsg.MESSAGES.ALL_IN_ONE);
    });
  }

  /**
   * Cap nhat thong tin api
   * @param id 
   * @param param 
   */
  putUpdateApi(id, param) {
    this.apiService.putUpdateApi(id, param).subscribe((res: any) => {
      this.alert(ConstantAlertMsg.UPDATED_API_SUCCESS, ConstantAlertType.SUCCESS);
    }, err => {
      this.handleError(err, ResponseMsg.MESSAGES.ALL_IN_ONE);
    });
  }

  /**
   * GEt account roles
   */
  getAccountRoles() {
    this.authService.getAccountRoles().subscribe((res: any) => {
      if (res.object && res.object instanceof Array && res.object.length > 0) {
        this.roleId = res.object[0].uuidRole;
      }
    }, err => {

    })
  }

  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], ConstantAlertType.ERROR);
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, ConstantAlertType.ERROR);
    } else {
      this.alert(ConstantAlertMsg.DEFAULT_ERROR_REQUEST, ConstantAlertType.ERROR);
    }
  }
}
