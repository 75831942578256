<app-spinner></app-spinner>
<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card ec-card shadow-none">
                    <div class="card-header">
                        <div class="d-flex">
                            <div class="card-title-path flex-grow-1">
                                <h1 class="m-0 text-dark card-title"><label>{{ 'ContractTemplateAdd_title' | translate }}</label></h1>
                            </div>
                            <div class="card-btn-path ml-auto text-right">
                                <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="cancel()"><span
                                        class="material-icons mr-1">close</span>{{ 'btn_cancel' | translate }}</button>
                                <button class="btn btn-primary d-inline-flex" (click)="save()"><span
                                        class="material-icons mr-1">save</span>{{ 'btn_save' | translate }}</button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <form [formGroup]="templateForm">
                            <div class="row">
                                <div class="col-lg-4 col-md-3">
                                    <div class="form-group">
                                        <label>{{ 'ContractTemplateAdd_templateName' | translate }} <span class="text-danger">*</span>:</label>
                                        <input appTrimValue class="form-control" placeholder="{{ 'ContractTemplateAdd_ph_templateName' | translate }}" formControlName="templateName">
                                        <app-show-validate-errors [isSubmit]="isSubmit"
                                            [errorMessages]="validateMsgForm.templateName" [control]="templateForm.controls.templateName"
                                            [detail]="{ name: 'templateName' }"></app-show-validate-errors>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-3">
                                    <div class="form-group">
                                        <label>{{ 'ContractTemplateAdd_attachedFile' | translate }} <span class="text-danger">*</span>:</label>
                                        <div class="input-group">
                                            <div class="custom-file custom-file-icon">
                                                <input #templateFile class="custom-file-input" id="inputGroupFile01"
                                                    placeholder="{{ 'ContractTemplateAdd_ph_attachedFile' | translate }}" 
                                                    (input)="onAttachFileChanged($event)"
                                                    formControlName="file" type="file" accept=".docx">
                                                <label class="custom-file-label"
                                                        for="inputGroupFile01">{{ inputFileLabel | translate }}</label>
                                                <span class="icon-file material-icons">attach_file</span>
                                            </div>
                                        </div>
                                        
                                        <a class="text-decoration-underline text-primary cursor-pointer" (click)="viewFile()">{{ 'ContractTemplateAdd_view_attachment' | translate }}</a>
                                        <app-show-validate-errors [isSubmit]="isSubmit"
                                            [errorMessages]="validateMsgForm.file" [control]="templateForm.controls.file"
                                            [detail]="{ name: 'file' }"></app-show-validate-errors>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-3">
                                    <div class="form-group">
                                        <label>{{ 'ContractTemplateAdd_templateType' | translate }} <span class="text-danger">*</span>:</label>
                                        <input appTrimValue class="form-control" placeholder="{{ 'ContractTemplateAdd_ph_templateType' | translate }}" formControlName="templateType">
                                        <app-show-validate-errors [isSubmit]="isSubmit"
                                            [errorMessages]="validateMsgForm.templateType" [control]="templateForm.controls.templateType"
                                            [detail]="{ name: 'templateType' }"></app-show-validate-errors>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-4 col-md-3">
                                    <div class="form-group">
                                        <label>{{ 'status' | translate }} <span class="text-danger">*</span>:</label>
                                        <ng-select formControlName="status" [items]="statusData" bindValue="value"
                                            placeholder="{{ 'ContractTemplateAdd_select_status' | translate }}">
                                            <ng-template ng-label-tmp let-item="item">
                                                <span>{{ item.label | translate }}</span>
                                            </ng-template>
                                            <ng-template ng-option-tmp let-item="item">
                                                <span>{{ item.label | translate }}</span>
                                            </ng-template>
                                    
                                        </ng-select>
                                        <app-show-validate-errors [isSubmit]="isSubmit"
                                            [errorMessages]="validateMsgForm.status" [control]="templateForm.controls.status"
                                            [detail]="{ name: 'status' }"></app-show-validate-errors>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="table-responsive">
                            <table class="table mb-0">
                                <thead>
                                    <tr class="bg-tb-header border-top text-left">
                                        <th>{{ 'ContractTemplateAdd_stt' | translate }}</th>
                                        <th>{{ 'ContractTemplateAdd_variable' | translate }}</th>
                                        <th>{{ 'ContractTemplateAdd_dataType' | translate }}</th>
                                        <th>{{ 'ContractTemplateAdd_varName' | translate }}</th>
                                        <th style="max-width: 300px">{{ 'ContractTemplateAdd_mapping' | translate }}</th>
                                    </tr>
                                </thead>
                                <tbody *ngFor="let item of templateVars; let i = index">
                                    <tr *ngIf="item.listVar.length <= 0">
                                        <td>{{ i + 1 }}</td>
                                        <td>{{ item.name }}</td>
                                        <td>{{ item.type }}</td>
                                        <td>
                                            <input appTrimValue class="form-control" [(ngModel)]="item.label">
                                        </td>
                                        <td>
                                            <ng-select [(ngModel)]="item.nameMap" [items]="item.varMaps"
                                                bindLabel="label"
                                                bindValue="value"
                                                appendTo="body"
                                                placeholder="{{ 'ContractTemplateDetail_select_status' | translate }}">
                                            </ng-select>
                                        </td>
                                    </tr>
                                    <tr *ngFor="let x of item.listVar; let j = index">
                                        <td *ngIf="j == 0" [attr.rowspan]="item.listVar.length">{{ i + 1 }}</td>
                                        <td>{{ x.name }}</td>
                                        <td>{{ x.type }}</td>
                                        <td>
                                            <input appTrimValue class="form-control" [(ngModel)]="x.label">
                                        </td>
                                        <td>
                                            <ng-select [(ngModel)]="x.nameMap" [items]="x.varMaps"
                                                bindLabel="label"
                                                bindValue="value"
                                                appendTo="body"
                                                placeholder="{{ 'ContractTemplateDetail_select_status' | translate }}">
                                            </ng-select>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>
<app-preview-modal *ngIf="showFile" [file]="pdfFile" [name]="inputFileLabel" (close)="onClosePreview($event)"></app-preview-modal>