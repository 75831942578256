import { Injectable } from '@angular/core';
import { ServicePath } from '../../common/constant-service-api';
import { ServiceMsg } from '../../common/validate-msg-aas';
import { API } from '../api';

const AUTHORIZATION_URL = ServicePath.SIGN_SERVICE + '/api/oautho/';
const SIGN_URL = ServicePath.SIGN_SERVICE + '/api/sign';
const TEXTLOCATION_URL = ServicePath.SIGN_SERVICE + '/api/pdfworker';
const USERINFO_URL = ServicePath.SIGN_SERVICE + '/api/user';
const SIGNATURE_CONFIG_URL = ServicePath.SIGN_SERVICE + '/api/sign-config';
const SIGN_USB_TOKEN_URL = ServicePath.SIGN_SERVICE + '/api/ca-token';
const USER_INFO_URL = ServicePath.MANAGEMENT_SERVICE + '/hierarchies/user-hierarchy?';
const SERVICE_USB_TOKEN_LIST = ServicePath.SIGN_SERVICE + '/api/ca-token';
const SERVICE_UPDATE_USB_TOKEN = ServicePath.SIGN_SERVICE + '/api/ca-token';
const SERVICE_DELETE_USB_TOKEN = ServicePath.SIGN_SERVICE + '/api/ca-token';
const SERVICE_SIGN_SERVER_INFO = ServicePath.SIGN_SERVICE + '/api/user';
const SERVICE_AUTHORIZATION_URL = ServicePath.SIGN_SERVICE + '/api/oautho/';
const SERVICE_LOGOUT_SIGNSERVER = ServicePath.SIGN_SERVICE + '/api/user';
const SERVICE_SIGNATURE_CONFIG = ServicePath.SIGN_SERVICE + '/api/sign-config';
const SERVICE_UPDATE_SIGNATURE_CONFIG = ServicePath.SIGN_SERVICE + '/api/sign-config';
const SERVICE_DSIGN_CONFIG = ServicePath.ESIGNATURE_SERVICE + '/api/sign-config/';
const SERVICE_SET_DEFAULT_SIGNATURE = ServicePath.SIGN_SERVICE + '/api/user/cert-default';
const GET_INFO_CUSTOMER_URL = ServicePath.MANAGEMENT_SERVICE + '/parties';
const DYNAMIC_SIGN_URL = ServicePath.MANAGEMENT_SERVICE + '/key-provinces';
const LOGIN_SIGN_SERVER = ServicePath.SIGN_SERVICE + '/api/oautho/v3/token';
const GET_IMAGE_SIGN = ServicePath.ESIGNATURE_SERVICE + '/esign';
const UPDATE_CONFIG_USBTOKEN = ServicePath.SIGN_SERVICE + '/api/ca-token';
const E_SIGN_RESEND_OTP = ServicePath.ESIGNATURE_SERVICE + '/esign/{{idSign}}/reset-otp';
const VERIFY_PDF_L1 = ServicePath.ESIGNATURE_SERVICE + '/esign/l1/information-signature';
const VERIFY_PDF_L2 = ServicePath.ESIGNATURE_SERVICE + '/esign/l2/information-signature';
const LOGIN_SMART_CA = ServicePath.SIGN_SERVICE + '/smartca/token';
const GET_CREDENTIAL = ServicePath.SIGN_SERVICE + "/smartca/credential";
const GET_CERTIFICATE = ServicePath.SIGN_SERVICE + "/smartca/certificate";
const GET_SMART_CA_LOGIN_SESSION = ServicePath.SIGN_SERVICE + "/smartca";
const LOGOUT_SMARTCA = ServicePath.SIGN_SERVICE + "/smartca/signout";
const SIGN_SMART_CA = ServicePath.SIGN_SERVICE + "/smartca/sign";
const CONFIRM_SIGN_SMART_CA = ServicePath.SIGN_SERVICE + "/smartca/sign";
const SIGN_MULTI_IMAGE_JOB = ServicePath.ESIGNATURE_SERVICE + "/esign";
const REQUEST_SESSION_MULTI_SIGN = ServicePath.MANAGEMENT_SERVICE + "/contracts/electronic-sign";
const SIGN_MULTI_IMAGE_JOB_INFO = ServicePath.ESIGNATURE_SERVICE + "/esign";
const REQUEST_SESSION_MULTI_SIGN_SMARTCA = ServicePath.MANAGEMENT_SERVICE + "/contracts/smartca-sign";
const SUBMIT_MULTI_SIGN_SMARTCA = ServicePath.SIGN_SERVICE + "/api/smartca/multisign";
const SIGN_MULTI_SMARTCA_JOB_INFO = ServicePath.SIGN_SERVICE + "/api/smartca/multisign";
const SIGN_MULTI_SMARTCA_JOB_INFO_V2 = ServicePath.MANAGEMENT_SERVICE + "/contracts/request/";
const CHECK_TRANS_MULTISIGN_SMARTCA = ServicePath.SIGN_SERVICE +  "/api/smartca/multisign/status?tranId=";
const LIST_POSITION = ServicePath.ESIGNATURE_SERVICE + "/api/list-position";
const CHECK_TRANS_SIGN_SMARTCA = ServicePath.SIGN_SERVICE +  "/smartca";

//SmartCA v2
const CHECK_LOGIN = ServicePath.SIGN_SERVICE + "/smart-ca/v2/check-login";
const CERT_LIST = ServicePath.SIGN_SERVICE + "/smart-ca/v2/certificates";
const GET_DEFAULT_CERT = ServicePath.SIGN_SERVICE + '/smart-ca/v2/default-certificates';
const SIGN_SMART_CA_V2 = ServicePath.SIGN_SERVICE + "/smart-ca/v2/login";
const SET_DEFAULT_CERT = ServicePath.SIGN_SERVICE + '/smart-ca/v2/default-certificates';
const LOGOUT_SMARTCA_V2 = ServicePath.SIGN_SERVICE + "/smart-ca/v2/logout";
const CERT_CONFIG = ServicePath.SIGN_SERVICE + '/smart-ca/v2/sign-configs';
const SUBMIT_SIGN_SMARTCA_V2 = ServicePath.SIGN_SERVICE + '/smartca/vnp/v2/sign';
const CLEAR_DEFAULT_CERT = ServicePath.SIGN_SERVICE + '/smart-ca/v2/delete-default-certificates';
const SET_TOTP = ServicePath.SIGN_SERVICE + '/smart-ca/v2/t-otp-secret';
const CHECK_TOTP = ServicePath.SIGN_SERVICE + '/smart-ca/v2/check-t-otp-secret?certId=';
const SIGN_AUTO = ServicePath.SIGN_SERVICE + '/smart-ca/v2/sign-auto';


@Injectable({
  providedIn: 'root'
})
export class SignatureService {

  constructor(
    private api: API
  ) { }

  /**
     * GET sign server URL to login
     * @param page 
     * @param maxSize 
     * @param propertiesSort 
     * @param sort 
     */
  getSignServerURL() {
    const url = AUTHORIZATION_URL;
    return this.api.get(url);
  }

  /**
     * POST code to get token
     * @param code 
     * @param userId 
     */
  postSignServerCode(code, userId) {
    const url = AUTHORIZATION_URL + `token?code=${code}&userId=${userId}`;
    return this.api.post(url, null);
  }

  /**
     * GET user info by user email
     * @param email
     */
  getUserInfoByEmail(email) {
    const url = USERINFO_URL + `/${email}/email`;
    return this.api.get(url);
  }

  /**
     * GET user info by user id
     * @param id
     */
  getUserInfoById(id) {
    const url = USERINFO_URL + `/${id}/id`;
    return this.api.get(url);
  }

  /**
     * GET signature config by signature id
     * @param id
     */
  getSignatureConfig(id) {
    const url = SIGNATURE_CONFIG_URL + `/${id}`;
    return this.api.get(url);
  }

  /**
     * POST set default signature by user id and cert id
     * @param userId
     * @param certId
     */
  postDefaultSignature(userId, certId) {
    const url = USERINFO_URL + `/cert-default?userId=${userId}&certId=${certId}`;
    return this.api.request('POST', url, null);
  }  


  getDetailInfo(partyId) {
    const url = GET_INFO_CUSTOMER_URL + `/${partyId}`;
    return this.api.get(url);
  }

  
  getDynamicSignature(partyId){
    const url = DYNAMIC_SIGN_URL + `?filter={"where":{"partyId":"${partyId}"}}`;
    return this.api.get(url);
  }

  putDynamicSignature(keyProvinceId, data){
    const url = DYNAMIC_SIGN_URL + `/${keyProvinceId}`;
    return this.api.patch( url, data);
  }


/**
   * POST set default signature by user id and cert id
   * @param certId
   * @param signerName
   * @param textColor
   * @param visibleType
   * @param image
   */    
  putSignatureConfig(formData) {
    const url = SIGNATURE_CONFIG_URL + `/`;
    return this.api.putMultiPart(url, formData);
  }

  putSignOut(id) {
    const url = USERINFO_URL + `/${id}`;
    return this.api.put(url, null);
  }

  postInfoUsbToken(data) {
    const url = SIGN_USB_TOKEN_URL + `/`;
    return this.api.post(url, data);
  }

  /**
  * GET info USB Token
  * @param id 
  */
  getInfoUsbToken(id) {
    const url = SIGN_USB_TOKEN_URL + `/${id}/userid`;
    return this.api.get(url);
  }

  /**
   * DELETE Xoá UsbToken sign info 
   * @param uuid 
   */
  deleteInfoUsbToken(uuid) {
    const url = SIGN_USB_TOKEN_URL + `/${uuid}`;
    return this.api.put(url, {});
  }
  
  getUserInfoServer(userId){
    const url = USER_INFO_URL + `userId=${userId}`;
    return this.api.get(url);
  }

  /**
   * Request lấy list usb token
   * @param userId 
   */
  getUsbTokenOfUser(userId) {
    const url = SERVICE_USB_TOKEN_LIST + `/${userId}/userid`;
    return this.api.get(url);
  }

  /**
   * Request update thông tin usb token
   * @param data 
   */
  postUpdateUsbToken(data) {
    const url = SERVICE_UPDATE_USB_TOKEN + '/';
    return this.api.post(url, data);
  }

  /**
   * Request Xóa usb token
   * @param id 
   */
  putDeleteUsbToken(id) {
    const url = SERVICE_DELETE_USB_TOKEN + `/${id}`;
    return this.api.put(url, null);
  }

  /**
   * Request danh sach chu ky so sign server
   * @param id 
   */
  getInfoCASignServer(id) {
    const url = SERVICE_SIGN_SERVER_INFO + `/${id}/id`;
    return this.api.get(url);
  }

  /**
   * POST code to get token
   * @param code 
   * @param userId 
   */
  postCASignServerCode(code, userId) {
    const url = SERVICE_AUTHORIZATION_URL + `token?code=${code}&userId=${userId}`;
    return this.api.post(url, null);
  }

  /**
   * Request thoat phien dang nhap sign server
   * @param id 
   */
  putLogoutSignServer(id) {
    const url = SERVICE_LOGOUT_SIGNSERVER + `/${id}`;
    return this.api.put(url, null);
  }

  /**
   * GET signature config by signature id
   * @param id
   */
  getInfoSignatureConfig(id) {
    const url = SERVICE_SIGNATURE_CONFIG + `/${id}`;
    return this.api.get(url);
  }

  /**
   * POST Update & Add signature config
   * @param data 
   */
  postUpdateSignatureConfig(data) {
    const url = SERVICE_UPDATE_SIGNATURE_CONFIG + `/`;
    return this.api.postMultiPartJSON(url, data);
  }

  /**
   * POST request set chữ ký số mặc định
   * @param userId 
   * @param certId 
   */
  postSetDefaultSignature(userId, certId) {
    const url = SERVICE_SET_DEFAULT_SIGNATURE + `?userId=${userId}&certId=${certId}`;
    return this.api.post(url, null);
  }

  /**
   * Request lấy thông tin chữ ký điện tử
   */
  getListDigitalSignature() {
    const url = SERVICE_DSIGN_CONFIG;
    return this.api.get(url);
  }

  /**
   * Request cập nhật config chữ ký điện tử
   * @param data 
   */
  postDigitalSignature(data) {
    const url = SERVICE_DSIGN_CONFIG;
    return this.api.postMultiPartJSON(url, data);
  }

  /**
   * Gửi yêu cầu đăng nhập tài khoản sign-server
   * @param param 
   */
  postLoginSignServer(param) {
    const url = LOGIN_SIGN_SERVER;
    return this.api.post(url, param);
  }

  postGetImageSign(idSessionSign: string, data: FormData) {
    const url = GET_IMAGE_SIGN + `/${idSessionSign}/create-image`;
    // return this.api.postMultiPart(url, data);
    return this.api.postMultiPartJSON(url, data);
  }

  /**
   * Cap nhat cau hinh chu ky usbtoken
   * @param param 
   * @returns 
   */
   postUdpateUsbTokenConfig(param) {
    const url = UPDATE_CONFIG_USBTOKEN;
    return this.api.post(url, param);
  }

  /**
   * Gui lai OTP ky dien tu
   * @param idSign 
   * @returns 
   */
  getEsignResendOTP(idSign) {
    const url = E_SIGN_RESEND_OTP.replace("{{idSign}}", idSign);
    return this.api.get(url);
  }

  postVerifySignatureL1(data: FormData) {
    const url = VERIFY_PDF_L1;
    return this.api.uploadFile(url, data)
  }

  postVerifySignatureL2(data: FormData) {
    const url = VERIFY_PDF_L2;
    return this.api.postMultiPartJSON(url, data)
  }

  //MARK: SmartCA
  postLoginSmartCA(param) {
    const url = LOGIN_SMART_CA;
    return this.api.post(url, param)
  }

  getCredential() {
    const url = GET_CREDENTIAL;
    return this.api.get(url);
  }

  getCertificate() {
    const url = GET_CERTIFICATE;
    return this.api.get(url);
  }

  getSessionSmartCA() {
    const url = GET_SMART_CA_LOGIN_SESSION;
    return this.api.get(url);
  }

  postSignOutSmartCA(username) {
    const url = LOGOUT_SMARTCA + `/${username}`;
    return this.api.post(url, null);
  }

  postSignSmartCA(param) {
    const url = SIGN_SMART_CA;
    return this.api.post(url, param);
  }

  postConfirmSignSmartCA(contractId) {
    const url = CONFIRM_SIGN_SMART_CA + `/${contractId}`;
    return this.api.put(url, null);
  }

  /**
   * Gủi yêu cầu ký ảnh hàng loạt
   * @param sessionId 
   * @param param 
   * @returns 
   */
  postMultiSignImage(sessionId, param: FormData) {
    const url = SIGN_MULTI_IMAGE_JOB + `/${sessionId}/v1/sign-job`;
    return this.api.postMultiPartJSON(url, param);
  }

  /**
   * Gửi yêu cầu tạo phiên ký hàng loạt 
   * @param param 
   * @returns 
   */
  postRequestSessionMultiSign(param) {
    const url = REQUEST_SESSION_MULTI_SIGN;
    return this.api.post(url, param);
  }

  /**
   * Lấy thông tin quá trình ký hàng loạt
   * @param sessionId 
   * @returns 
   */
  getInfoSignJob(sessionId) {
    const url = SIGN_MULTI_IMAGE_JOB_INFO + `/${sessionId}/v1/sign-job-info`;
    return this.api.get(url);
  }

  /**
   * Gửi yêu cầu tạo session ký smartca
   * @param param 
   * @returns 
   */
  postRequestSessionMultiSignSmartCA(param) {
    const url = REQUEST_SESSION_MULTI_SIGN_SMARTCA;
    return this.api.post(url, param);
  }

  /**
   * Gửi yêu cầu ký hàng loạt smartca
   * @param param 
   * @returns 
   */
  postMultiSignSmartCA(param) {
    const url = SUBMIT_MULTI_SIGN_SMARTCA;
    return this.api.post(url, param);
  }

  getInfoSignJobSmartCA(sessionId, page = 1, propertiesSort = "", sort = "", maxSize = 10) {
    const url = SIGN_MULTI_SMARTCA_JOB_INFO + `?tranId=${sessionId}&page=${page}&propertiesSort=${propertiesSort}&sort=${sort}&maxSize=${maxSize}`;
    return this.api.get(url);
  }

  /**
   * Kiểm tra trạng thái transaction của phiên confirm SMARTCA
   * @param tracId 
   * @returns 
   */
  getCheckTransactionMultiSignSmartCA(tranId = "") {
    const url = CHECK_TRANS_MULTISIGN_SMARTCA + tranId;
    return this.api.get(url);
  }

  /**
   * Lấy danh sách các vị trị toạ độ của @{x}
   * @param data 
   * @returns 
   */
  getListPosition(data: FormData) {
    const url = LIST_POSITION;
    return this.api.postMultiPartJSON(url, data);
  }
  getInfoSignJobSmartCAV2(sessionId, page = 1, propertiesSort = "", sort = "", maxSize = 100000) {
    const url = SIGN_MULTI_SMARTCA_JOB_INFO_V2 + `${sessionId}?page=${page}&propertiesSort=${propertiesSort}&sort=${sort}&maxSize=${maxSize}`;
    return this.api.get(url);
  }

  /**
   * Lấy ảnh chữ ký: Ký ảnh, SMS OTP, SMS OTP + eKYC, Email OTP, Email OTP + eKYC
   */
  downloadSignatureImage(url) {
    return this.api.download(url);
  }

  /**
   * Kiểm tra trạng thái transaction của phiên confirm SMARTCA
   * @param tracId 
   * @returns 
   */
  getCheckTransactionSignSmartCA(tranId = "") {
    const url = CHECK_TRANS_SIGN_SMARTCA + `/${tranId}/smartca-transaction-info`;
    return this.api.get(url);
  }


  //SmartCA v2
  checkLogin() {
    return this.api.get(CHECK_LOGIN);
  }

  postLoginSmartCAV2(param) {
    const url = SIGN_SMART_CA_V2;
    return this.api.post(url, param)
  }

  getSessionSmartCAV2() {
    const url = CERT_LIST;
    return this.api.get(url);
  }

  postSignOutSmartCAV2() {
    const url = LOGOUT_SMARTCA_V2;
    return this.api.post(url, null);
  }

  getDefaultCert() {
    return this.api.get(GET_DEFAULT_CERT);
  }

  setDefaultCert(params: any) {
    const url = SET_DEFAULT_CERT;
    return this.api.post(url, params);
  }

  updateCertConfigs(formData) {
    const url = CERT_CONFIG;
    return this.api.postMultiPartJSON(url, formData);
  }

  signSmartCAv2(params: any) {
    return this.api.post(SUBMIT_SIGN_SMARTCA_V2, params);
  }

  clearDefaultCert() {
    const url = CLEAR_DEFAULT_CERT;
    return this.api.post(url, null);
  }

  postTOTP(data){
    const url = SET_TOTP
    return this.api.post(url, data)
  }

  checkTOTP(certId){
    const url = CHECK_TOTP + `${certId}`
    return this.api.get(url)
  }

}
