import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ValidatorsExtension } from 'src/app/utils/common/validators-extension';
import { REGEXP_EMAIL, REGEXP_MST, REGEXP_PASSWORD, REGEXP_PHONE_NUMBER, REGEXP_USERNAME } from 'src/app/utils/common/regexp';
import { SIGNUP_CONSUMER, SIGN_ON_BUSINESS } from 'src/app/utils/common/constant-vaildate-msg';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { Config } from 'src/app/config';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ConstantAlertBtn, ConstantAlertMsg } from 'src/app/utils/common/constant-alert-msg';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocationExtension } from 'src/app/utils/common/LocationExtension';
import { SignupConsumerResponse } from 'src/app/utils/models/aas-models/signup-consumer/signup-consumer-response';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { CatalogService } from 'src/app/utils/services/aas-network/catalog.service';
import { ListTinhResponse } from 'src/app/utils/models/catalog/list-tinh/list-tinh-response.model';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { localize } from 'src/app/utils/localize/localize';

@Component({
  selector: 'app-signup-consumer',
  templateUrl: './signup-consumer.component.html',
  styleUrls: ['./signup-consumer.component.scss']
})
export class SignupConsumerComponent implements OnInit {
  @Output('next') next: EventEmitter<any> = new EventEmitter<any>();
  @Output('back') back: EventEmitter<any> = new EventEmitter<any>();
  @Input('dieukhoan')
  set dieukhoan(value) {
    this._dieukhoan = value;
  }
  get dieukhoan() {
    return this._dieukhoan;
  }
  _dieukhoan = "";

  customerType = this.activedRoute.snapshot.queryParamMap.get('userType') || '';
  email = this.activedRoute.snapshot.queryParamMap.get('email') || '';
  id = this.activedRoute.snapshot.queryParamMap.get('id') || "";

  notBack = false;

  constantUrl = ConstantUrl;
  config = Config;

  formSubmit: FormGroup;
  isSubmit: boolean = false;
  validateMsg = SIGNUP_CONSUMER;

  tinhBindData = [];

  checkbox_dieukhoan = false;

  constructor(
    private fb: FormBuilder,
    private activedRoute: ActivatedRoute,
    private aasAuth: AasAuthService,
    private modalService: NgbModal,
    private catalog: CatalogService,
    private myAlert: AlertControl
  ) { }

  ngOnInit(): void {
    this.makeFormSubmit();

    if (this.customerType && this.email) {
      this.notBack = true;
    }

    if (this.email) {
      this.formSubmit.controls.email.setValue(this.email);
    }

    this.getDmTinh();
  }

  /**
   * Init form thong tin ca nhan
   */
  makeFormSubmit() {
    this.formSubmit = this.fb.group({
      email: new FormControl('', [Validators.required, Validators.pattern(REGEXP_EMAIL)]),
      sdt: new FormControl('', [Validators.required, Validators.pattern(REGEXP_PHONE_NUMBER), ValidatorsExtension.phoneNumber]),
      username: new FormControl('', [Validators.required, Validators.pattern(REGEXP_USERNAME)]),
      password: new FormControl('', [Validators.required, Validators.pattern(REGEXP_PASSWORD)]),
      tinhId: [null]
    });
  }

  /**
   * Tao data test
   */
  setDataTest() {
    this.formSubmit.patchValue({
      hoVaTen: 'Doanh nghiep mot',
      email: 'dnghiep.001@gmail.com',
      username: 'dnghiep.001',
      password: 'Abc@12345',
      domain: 'dnghiep001'
    })
  }

  /**
   * Handle input sdt
   * @param event 
   */
  handleInputSdt(event) {
    const regex = new RegExp(/^\d+$/);
    if (regex.test(event.target.value) && event.target.value.length <= 10) {
      return;
    } else {
      this.formSubmit.controls.sdt.setValue(event.target.value.slice(0, -1));
    }
  }

  /**
   * Nhan nut Next
   */
  actNext() {
    this.isSubmit = true;
    if (this.formSubmit.valid) {
      if (!this.checkbox_dieukhoan) {
        this.myAlert.showAlertOnlyNoti(localize.localize_signup_alert_confirm, "", "", localize.btn_close);
        return;
      }
      const param = {
        domain: LocationExtension.HOSTNAME,
        email: this.formSubmit.value.email,
        sdt: this.formSubmit.value.sdt,
        username: this.formSubmit.value.username,
        password: this.formSubmit.value.password,
        tinhId: this.formSubmit.value.tinhId || null
      }

        if (this.id) {
          this.postRegisterConsumerByInvited(this.id, param);
        } else {
          this.postRegisterConsumer(param);
      }
    }
  }

  /**
   * Nhan nut Back
   */
  actBack() {
    this.back.emit({});
  }

  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = ConstantAlertBtn.OK, actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = ConstantAlertBtn.CANCEL, actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], ConstantAlertType.ERROR);
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, ConstantAlertType.ERROR);
    } else {
      this.alert(ConstantAlertMsg.DEFAULT_ERROR_REQUEST, ConstantAlertType.ERROR);
    }
  }

  //MARK: NETWORKING
  /**
   * Gui yeu dang ky khach hang ca nhan
   * @param param 
   */
  postRegisterConsumer(param) {
    this.aasAuth.postRegisterConsumer(param).subscribe(res => {
      const data = new SignupConsumerResponse(res);
      console.log(data);

      var emitParam = this.formSubmit.value;
      emitParam['userId'] = data.object.userId;
      this.next.emit(emitParam);
    }, err => {
      console.log(err);
      if (err.error && err.error.message) {
        switch (err.error.message) {
          case 'ECT-00001200':
            this.formSubmit.controls.email.setErrors({ unique: true });
            break;
          case 'ECT-00001201':
            this.formSubmit.controls.sdt.setErrors({ unique: true });
            break
          case 'ECT-00001202':
            this.formSubmit.controls.username.setErrors({ unique: true });
            break;
          default:
            this.handleError(err, ResponseMsg.MESSAGES.PARTY_USER);
        }
      }
    });
  }


/**
   * Gui yeu dang ky khach hang ca nhan duoc moi
   * @param param 
   */
 postRegisterConsumerByInvited(id, param) {
  this.aasAuth.postRegisterConsumerByInvited(id, param).subscribe(res => {
    const data = new SignupConsumerResponse(res);
    console.log(data);

    var emitParam = this.formSubmit.value;
    emitParam['userId'] = data.object.userId;
    this.next.emit(emitParam);
  }, err => {
    console.log(err);
    if (err.error && err.error.message) {
      switch (err.error.message) {
        case 'ECT-00001200':
          this.formSubmit.controls.email.setErrors({ unique: true });
          break;
        case 'ECT-00001201':
          this.formSubmit.controls.sdt.setErrors({ unique: true });
          break
        case 'ECT-00001202':
          this.formSubmit.controls.username.setErrors({ unique: true });
          break;
        default:
          this.handleError(err, ResponseMsg.MESSAGES.PARTY_USER);
      }
    }
  });
}

  getDmTinh() {
    this.catalog.getListTinh().subscribe((res) => {
      let dmTinh = new ListTinhResponse(res);
      this.tinhBindData = dmTinh.object.map(item => {
        return { id: item.tinhId, label: item.tenTinh };
      });
    }, err => {
      console.log(err);
    });
  }

  /**
   * Hien thi dieu khoan su dung
   */
  showDieuKhoan() {
    let self = this;
    const content = "<div class='e-dieukhoan'>" + this.dieukhoan + "</div>";
      setTimeout(() => {
        self.myAlert.showAlertOnlyNoti(
          content,
          ConstantAlertType.BLANK,
          "",
          ConstantAlertBtn.CLOSE,
          null,
          localize.modal_dkdv_title,
          { size: 'lg' }
        );
      });
  }
}
