<div class="bg-white">
    <section class="p-3">
        <div class="row">
            <div class="col-lg-3 col-md-5">
                <form [formGroup]="formSearch" (ngSubmit)="actSearch(formSearch.value.search)"
                    class="input-group border rounded mb-3">
                    <div class="input-group-prepend border-0">
                        <button class="btn d-inline-flex pr-2 pl-2" type="submit"><span
                                class="material-icons">search</span></button>
                    </div>
                    <input (input)="eventInputSearch($event)" type="text" class="form-control border-0"
                        formControlName="search" placeholder="Tìm kiếm..." aria-label=""
                        aria-describedby="basic-addon1">
                </form>
            </div>
            <div class="col-md-auto ml-auto">
                <!-- <div class="btn-group">
                    <button class="btn btn-outline-primary dropdown-toggle d-inline-flex align-items-center mr-3"
                        data-toggle="dropdown" id="configfilter"><span class="material-icons mr-1">tune</span>Bộ
                        lọc</button>
                    <div class="dropdown-menu border-0" aria-labelledby="configfilter">
                        <div *ngFor="let item of listFitler" class="tcheck-primary d-inline-block px-3">
                            <input type="checkbox" id="{{ item.name }}" [checked]="configFilter[item.name]">
                            <label for="{{ item.name }}" class="w-100" [class.checked]="configFilter[item.name]"
                                (click)="actConfigFilter($event, item.name)">
                                {{ item.label }}
                            </label>
                        </div>
                    </div>
                </div> -->
                <div class="btn-group">
                    <div ngbDropdown container="body" [autoClose]="'outside'" [placement]="'bottom-right'" [display]="'dynamic'">
                        <button ngbDropdownToggle class="btn btn-outline-primary dropdown-toggle d-inline-flex align-items-center mr-3">
                            <span class="material-icons mr-1">visibility</span>Hiển thị
                        </button>
                        <div ngbDropdownMenu>
                            <div ngbDropdownItem *ngFor="let item of listConfigColTable" class="p-0">
                                <div  class="tcheck-primary px-3">
                                    <input type="checkbox" id="{{ item.name }}" [checked]="configColTable[item.name]">
                                    <label for="{{ item.name }}" class="w-100" [class.checked]="configColTable[item.name]"
                                        (click)="actConfigColTableView($event, item.name)">
                                        {{ item.label }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="cleanFilter()"> Đặt lại</button>
                <button class="btn btn-primary d-inline-flex mr-3" (click)="searchWithFilter()"> Áp dụng</button>
            </div>
        </div>
        <hr class="my-2">
    </section>
    <section class="px-3 my-2">
        <form [formGroup]="formFilter">
            <div class="row">
                <div class="col-md-2" >
                    <div class="form-group">
                        <label for="statusGCS">Loại khách hàng</label>
                        <select formControlName="customerType" class="form-control">
                            <option value="">Tất cả</option>
                            <option value="CONSUMER" >Cá nhân</option>
                            <option value="BUSINESS">Doanh nghiệp</option>
                        </select>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <label for="">Từ ngày</label>
                        <div class="input-group">
                            <input #fromDatePicker class="form-control" [control]="formFilter.controls.fromDate"
                                [value]="formFilter.value.fromDate"
                                (dateSelect)="changeDateFilter($event, dateType.CREATED_FROM)"
                                placeholder="dd/MM/yyyy" ngbDatepicker #createdFromDate="ngbDatepicker"
                                [maxDate]="{year: currentDate.getFullYear(), month: currentDate.getMonth()+1, day: currentDate.getDate()}"
                                appInputMark [options]="optionsCleave">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" (click)="createdFromDate.toggle()"
                                    type="button"><em class="fas fa-calendar-alt"></em></button>
                            </div>
                            <app-show-validate-errors [useSubmit]="false"
                                [errorMessages]="validateSearch.fromDate"
                                [control]="formFilter.controls.fromDate"
                                [detail]="{ name: 'fromDate' }">
                            </app-show-validate-errors>
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <label for="">Đến ngày</label>
                        <div class="input-group">
                            <input #toDatePicker class="form-control" [control]="formFilter.controls.toDate"
                                [value]="formFilter.value.toDate"
                                (dateSelect)="changeDateFilter($event, dateType.CREATED_TO)"
                                placeholder="dd/MM/yyyy" ngbDatepicker #createdToDate="ngbDatepicker"
                                [maxDate]="{year: currentDate.getFullYear(), month: currentDate.getMonth()+1, day: currentDate.getDate()}"
                                appInputMark [options]="optionsCleave">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" (click)="createdToDate.toggle()"
                                    type="button"><em class="fas fa-calendar-alt"></em></button>
                            </div>
                            <app-show-validate-errors [useSubmit]="false"
                                [errorMessages]="validateSearch.toDate"
                                [control]="formFilter.controls.toDate"
                                [detail]="{ name: 'toDate' }">
                            </app-show-validate-errors>
                        </div>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="form-group">
                        <label for="status">Trạng thái</label>
                        <select formControlName="status" class="form-control">
                            <option value="" selected>Tất cả</option>
                            <option value="LC_DRAFT_CREATE">Bản nháp</option>
                            <option value="LC_DRAFT_DISCUSS">Đàm phán (Chưa đồng ý thỏa thuận)</option>
                            <option value="LC_DRAFT_DEAL">Đàm phán (Đã có bên đồng ý thỏa thuận)</option>
                            <option value="LC_DRAFT_SUBMIT">Chờ ký</option>
                            <option value="LC_DRAFT_CANCEL">Đã từ chối</option>
                            <option value="LC_CONTRACT_VALID">Có hiệu lực</option>
                        </select>
                    </div>
                </div>
            </div>
        </form>
    </section>
   
    <section class="card-body p-0">
        <div class="table-responsive">
            <table class="table mb-0">
                <thead>
                    <tr class="bg-tb-header border-top">
                        <th></th>
                        <th>STT</th>
                        <th [class.d-none]="!configColTable.tenHopDong">Tên hợp đồng</th>
                        <th [class.d-none]="!configColTable.soBenThamGia">Số bên tham gia</th>
                        <th [class.d-none]="!configColTable.ngayChinhSua">Ngày chỉnh sửa</th>
                        <th [class.d-none]="!configColTable.status">Trạng thái</th>
                        <th>Thao tác</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of dataTableExpand; let i = index" 
                        [class.bg-tb-header]="item.isChild && item.isBody"
                        [class.d-none]="item.isChild && item.hidden">
                        <td>
                            <span *ngIf="item.isParent" (click)="toggleShow(i)"  class="material-icons" style="cursor: pointer;"
                                [style.transform]="item.hidden ? 'rotate(0deg)' : 'rotate(90deg)'">
                                chevron_right
                            </span>
                        </td>
                        <td>
                            <span *ngIf="item.isParent">{{ item.stt }}</span>
                        </td>
                        <td [class.d-none]="!configColTable.tenHopDong" class="d-flex">
                            <a *ngIf="item.isParent" class="title-hyperlink" routerLinkActive="router-link-active" [routerLink]="['/wv/contracts',item.contractId]">{{ item.title }}</a>
                            <span *ngIf="item.isChild && item.isHeader" class="font-weight-bold">{{ item.tr_3 }}</span>
                            <span *ngIf="item.isChild && item.isBody">{{ item.donViQuanLy || item.doiTac || 'Không tên' }}</span>
                        </td>
                        <td [class.d-none]="!configColTable.soBenThamGia">
                            <span *ngIf="item.isParent">{{ item.partners.length }}</span>
                            <span *ngIf="item.isChild && item.isHeader" class="font-weight-bold">{{ item.tr_4 }}</span>
                            <span *ngIf="item.isChild && item.isBody">{{ item.loaiKhachHang === 'CONSUMER' ? 'Cá nhân' : 'Doanh nghiệp' }}</span>
                        </td>
                        <td [class.d-none]="!configColTable.ngayChinhSua">
                            <span *ngIf="item.isParent">{{ item.modified | date: "dd/MM/yyyy" }}</span>
                            <span *ngIf="item.isChild && item.isHeader" class="font-weight-bold">{{ item.tr_5 }}</span>
                            <span *ngIf="item.isChild && item.isBody">{{ item.email }}</span>
                        </td>
                        <td [class.d-none]="!configColTable.status">
                            <span *ngIf="item.isParent" class="badge {{ currentStage[item.currentStage].style || '' }} p-2">{{ currentStage[item.currentStage].label | translate }}</span>
                            <br><span *ngIf="item.isParent" class="d-block mt-1">{{ item.signerEmail }}</span>
                        </td>
                        <td>
                            <div *ngIf="item.isParent" ngbDropdown container="body" placement="top-right">
                                <span class="material-icons cursor-pointer" ngbDropdownToggle id="settingTable">more_vert</span>
                                <!-- (click)="openDropdown($event)" -->
                                <div ngbDropdownMenu class="border-0">
                                    <a ngbDropdownItem (click)="goToDetailContract(item.contractId)"
                                        class="dropdown-item cursor-pointer d-flex">
                                        <span class="material-icons-outlined mr-3">info</span>
                                        Thông tin chi tiết</a>
                            
                                    <!-- && (item.currentStage == 'LC_DRAFT_SUBMIT' || item.currentStage == 'LC_DRAFT_SIGNED') -->
                                    <a ngbDropdownItem
                                        *ngIf="(item.readyforsign === 1 )"
                                        (click)="goToSign(item.contractId)" class="dropdown-item cursor-pointer d-flex"><span
                                            class="ct-icon-font icon-custom_signature mr-3"></span>Ký hợp đồng</a>
                            
                                    <a ngbDropdownItem *ngIf="_by === 'OWNER' && item.currentStage == 'LC_DRAFT_CREATE'"
                                        (click)="sendContract(item)" class="dropdown-item cursor-pointer d-flex"><span
                                            class="material-icons mr-3">send</span>Gửi hợp đồng</a>
                                    <!-- && (item.currentStage == 'LC_DRAFT_SUBMIT' || item.currentStage == 'LC_DRAFT_SIGNED') -->
                                    <a ngbDropdownItem *ngIf="item.readyforreturn === 1"
                                        class="dropdown-item cursor-pointer d-flex" (click)="openModalReturn(contentReturn, item.contractId)"><span
                                            class="material-icons-outlined mr-3">reply</span>Trả lại</a>
                                    <!-- (item.currentStage == 'LC_DRAFT_DISCUSS' || item.currentStage == 'LC_DRAFT_DEAL') -->
                                    <a ngbDropdownItem *ngIf="item.readyfordeal === 1"
                                        (click)="postAcceptDeal(item.contractId)" class="dropdown-item cursor-pointer d-flex"><span
                                            class="material-icons-outlined mr-3">done</span>Đồng ý thỏa thuận</a>
                                    <!-- && item.currentStage == 'LC_DRAFT_DISCUSS' -->
                                    <a ngbDropdownItem *ngIf="item.readyforupdatecontent === 1 && _by === 'OWNER'"
                                        (click)="postAcceptDeal(item.contractId)" class="dropdown-item cursor-pointer d-flex"><span
                                            class="material-icons-outlined mr-3">update</span>Thay đổi nội dung</a>
                                    <!-- ( item.currentStage == 'LC_DRAFT_DEAL') -->
                                    <a ngbDropdownItem *ngIf="item.readyfordeny === 1" (click)="postCancelDeal(item.contractId)"
                                        class="dropdown-item cursor-pointer d-flex"><span class="material-icons-outlined mr-3">clear</span>Từ chối
                                        thỏa thuận</a>
                            
                                    <a ngbDropdownItem (click)="getFileContract(item.contractId, item.currentStage)"
                                        class="dropdown-item cursor-pointer d-flex"><span class="material-icons-outlined mr-3">download</span>Tải
                                        hợp đồng</a>
                                    
                                    <a *ngIf="!hasDecode(item.contractId) && item.currentStage === 'LC_CONTRACT_VALID'" ngbDropdownItem  (click)="turnOnEncode(item.contractId)"
                                        class="dropdown-item cursor-pointer d-flex"><span class="material-icons-outlined mr-3">line_style</span>Giải mã hợp đồng</a>
                                    
                                    <!-- && item.currentStage == 'LC_DRAFT_CREATE' -->
                                    <a ngbDropdownItem *ngIf="item.readyfordelete === 1 &&  _by === 'OWNER' "
                                        (click)="deleteContract(item.contractId)" class="dropdown-item cursor-pointer d-flex"><span
                                            class="material-icons-outlined mr-3">delete</span>Xóa hợp đồng</a>
                            
                                    <!-- <a *ngIf="this._by === 'OWNER'" (click)="createCoppies(item.contractId)" class="dropdown-item cursor-pointer d-flex"><span
                                                                    class="material-icons-outlined mr-3">control_point_duplicate</span>Tạo bản sao</a> -->
                                    <!-- && (item.currentStage == 'LC_DRAFT_DEAL' || item.currentStage == 'LC_DRAFT_DISCUSS') -->
                                    <!-- <a ngbDropdownItem 
                                        *ngIf="item.readyforcancel === 1 && _by === 'OWNER'"
                                        (click)="openModalCancel(contentCancel, item.contractId)" class="dropdown-item cursor-pointer d-flex"><span
                                            class="material-icons-outlined mr-3">block</span>Hủy hợp đồng</a> -->
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="bg-tb-header p-3">
            <div class="row align-items-center">
                <span *ngIf="totalElement == 0" class="col-md">Không tìm thấy kết quả</span>
                <span *ngIf="totalElement > 0" class="col-md">Hiển thị 1 đến {{listContract.length}} trong tổng số
                    {{totalElement}} kết quả</span>
                <div class="col-md">
                    <div class="d-flex justify-content-end">
                        <div *ngIf="totalElement > 0" class="d-flex justify-content-end align-items-center">
                            <span class="mr-2">Số dòng hiển thị:</span>
                            <ng-select class="no-bg no-border" [(ngModel)]="maxSize" [searchable]="false" [clearable]="false" placeholder="..." (change)="loadData(1)">
                                <ng-option [value]="10">10</ng-option>
                                <ng-option [value]="20">20</ng-option>
                                <ng-option [value]="30">30</ng-option>
                            </ng-select>
                        </div>
                        <div *ngIf="totalElement > maxSize" class="pagination pages no-border-pagination ml-2">
                            <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="false"
                                [pageSize]="maxSize" (pageChange)="loadData($event)" [boundaryLinks]="true" aria-label="Default pagination">
                                <ng-template ngbPaginationFirst>Đầu</ng-template>
                                <ng-template ngbPaginationLast>Cuối</ng-template>
                                <ng-template ngbPaginationPrevious>&laquo;</ng-template>
                                <ng-template ngbPaginationNext>&raquo;</ng-template>
                                <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                            </ngb-pagination>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </section> 
</div>

<!-- <div class="d-flex justify-content-end mt-3">
    <span *ngIf="totalElement == 0" class="flex-grow-1 ml-1 mb-3">Không tìm thấy kết quả</span>
    <span *ngIf="totalElement > 0" class="flex-grow-1 ml-1 mb-3">Hiển thị 1 đến {{listContract.length}} trong tổng số
        {{totalElement}} kết quả</span>
    <div *ngIf="totalElement > maxSize" class="pagination pages aas-pagination">
        <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="false"
            [pageSize]="maxSize" (pageChange)="loadData($event)" [boundaryLinks]="true" aria-label="Default pagination">
            <ng-template ngbPaginationFirst>Đầu</ng-template>
            <ng-template ngbPaginationLast>Cuối</ng-template>
            <ng-template ngbPaginationPrevious>&laquo;</ng-template>
            <ng-template ngbPaginationNext>&raquo;</ng-template>
            <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
    </div>
</div> -->

<ng-template #contentCancel let-c="close" let-d="dismiss">
    <div class="modal-header border-0 pb-0 mb-0">
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span class="material-icons">
                cancel
            </span>
        </button>
    </div>
    <div class="modal-body py-0">
        <h5 class="modal-title w-100 text-center mb-3" id="modal-basic-title">Huỷ hợp đồng</h5>
        <form [formGroup]="formCancel">
            <div class="form-group">
                <label>Lý do huỷ: </label>
                <textarea formControlName="reason" class="form-control" rows="5" placeholder="Vui lòng nhập lý do huỷ hợp đồng"></textarea>
                <app-show-validate-errors [isSubmit]="isCancelSubmit" [errorMessages]="validMsgCancel.reason"
                    [control]="formCancel.controls.reason" [detail]="{ name: 'reason' }"></app-show-validate-errors>
            </div>
        </form>
    </div>
    <div class="modal-footer border-0 d-flex justify-content-center">
        <button type="button" class="btn btn-outline-primary" (click)="c('Close')">Huỷ</button>
        <button type="button" class="btn btn-primary" (click)="submitCancel()">Xác nhận</button>
    </div>
</ng-template>
<ng-template #contentReturn let-c="close" let-d="dismiss">
    <div  class="modal-header border-0">
        <h5 class="modal-title" style="font-weight: 600; margin-right: auto;">Trả lại hợp đồng</h5>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="btn btn-light bg-exit-btn d-inline-flex"><span class="material-icons">
                    close
                </span></span>
        </button>
    </div>
    <div class="modal-body py-0">
        <form [formGroup]="formReturn">
            <div class="form-group">
                <label>Lý do trả lại: </label>
                <textarea formControlName="reasonReturn" class="form-control" rows="5" placeholder="Vui lòng nhập lý do trả lại hợp đồng"></textarea>
                <app-show-validate-errors [isSubmit]="isCancelSubmit" [errorMessages]="validMsgCancel.reason"
                    [control]="formReturn.controls.reasonReturn" [detail]="{ name: 'reason' }"></app-show-validate-errors>
            </div>
        </form>
    </div>
    <div class="modal-footer border-0 d-flex justify-content-end">
        <button type="button" class="btn btn-outline-primary" (click)="c('Close')">Huỷ</button>
        <button type="button" class="btn btn-primary" (click)="submitReturn()">Xác nhận</button>
    </div>
</ng-template>

<ng-template #updateContractFile let-c="close" let-d="dismiss">
    <div  class="modal-header border-0">
        <h5 class="modal-title" style="font-weight: 600; margin-right: auto;">Thay đổi nội dung hợp đồng</h5>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="btn btn-light bg-exit-btn d-inline-flex"><span class="material-icons">
                    close
                </span></span>
        </button>
    </div>
    <div class="modal-body text-left py-0 enable-scroll">
        <div class="mb-0">
            <form [formGroup]="formUpdateContractFile">
                <p>Vui lòng cập nhật hợp đồng bằng cách chọn lại file đính kèm.</p>
                <div class="form-group">
                    <label>File hợp đồng:</label>
                    <div class="input-group mb-1">
                        <div class="custom-file custom-file-icon">
                            <span
                                class="icon-file material-icons">attach_file</span>
                            <input formControlName="file"
                                (input)="handleFileInput($event)" type="file"
                                accept=".pdf,.docx" class="custom-file-input"
                                id="inputGroupFile01"
                                aria-describedby="inputGroupFileAddon01">
                            <label class="custom-file-label"
                                for="inputGroupFile01">{{ lbFieldContractFile }}</label>
                        </div>
                    </div>
                    <app-show-validate-errors [isSubmit]="isSubmitFormUpdateContractFile"
                        [errorMessages]="validMsgUpdateContractFile.file"
                        [control]="formUpdateContractFile.controls.file"
                        [detail]="{ name: 'file' }"></app-show-validate-errors>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer border-0 d-flex justify-content-end">
        <button type="button" class="btn btn-outline-primary d-inline-flex"
            (click)="c('Cross click')">Hủy</button>
        <button type="button" class="btn btn-primary d-inline-flex ml-3" ngbAutofocus
            (click)="confirmUpdateContractFile()">Tải lên</button>
    </div>
</ng-template>