import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { concat, forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { ConstSignType, InternalDiscussTypes, SignFlowTypes } from 'src/app/utils/common/constants/sign-form';
import { localize } from 'src/app/utils/localize/localize';
import { ContractObject } from 'src/app/utils/models/aas-models/contract/contract/contract-object.model';
import { FlowContract } from 'src/app/utils/models/aas-models/flow-contract/flow-contract.model';
import { InternalDiscuss } from 'src/app/utils/models/aas-models/flow-contract/internal-discuss.model';
import { PartnerDiscuss } from 'src/app/utils/models/aas-models/flow-contract/partner-discuss.model';
import { SignFlow } from 'src/app/utils/models/aas-models/flow-contract/sign-flow.model';
import { EmployeeService } from 'src/app/utils/services/aas-network/employee/employee.service';
import { FlowContractService } from 'src/app/utils/services/aas-network/flow-contract/flow-contract.service';
import { PartService } from 'src/app/utils/services/aas-network/part/part.service';
import { TemplateService } from 'src/app/utils/services/aas-network/template.service';
import { AuthService } from 'src/app/utils/services/auth.service';
import { LoadingService } from 'src/app/utils/services/loading.service';

const signFormsApproval = ["USB_TOKEN", "SIGN_SERVER", "SMART_CA"];
@Component({
  selector: 'app-flow-settings',
  templateUrl: './flow-settings.component.html',
  styleUrls: ['./flow-settings.component.scss']
})
export class FlowSettingsComponent implements OnInit {
  @Input('id')
  set id(value) { this._id = value; }
  get id() { return this._id; }
  _id: string = "";

  @Input('flowContract')
  set flowContract(value) { this._flowContract = new FlowContract(value); }
  get flowContract(): FlowContract { return this._flowContract; }
  _flowContract: FlowContract = new FlowContract();

  @Input('notEditInfo')
  set notEditInfo(value) { this._notEditInfo = value; }
  get notEditInfo() { return this._notEditInfo; }
  _notEditInfo: string = "";

  @Input('contract')
  set contract(value) { this._contract = new ContractObject(value); }
  get contract() { return this._contract; }
  _contract: ContractObject = new ContractObject();

  @Input('internalEditable')
  set internalEditable(value) { this._internalEditable = value; }
  get internalEditable() { return this._internalEditable; }
  _internalEditable: boolean = false;

  @Input('partnerEditable')
  set partnerEditable(value) { this._partnerEditable = value; }
  get partnerEditable() { return this._partnerEditable; }
  _partnerEditable: boolean = false;

  @Input('signFlowEditable')
  set signFlowEditable(value) { this._signFlowEditable = value; }
  get signFlowEditable() { return this._signFlowEditable; }
  _signFlowEditable: boolean = false;

  @Output() closed = new EventEmitter();

  localize = localize;

  form: FormGroup;
  validateError = {
    name: {
      required: "clc_form_name_required"
    },
    disable: {
      required: "clc_form_status_required"
    },
    file: {
      required: "clc_form_file_required",
      pattern: "clc_file_invalid_type"
    },
    type: {
      required: "clc_form_type_required"
    },
    discuss: {
      required: "clc_form_discuss_required"
    },
    description: {

    },
    contractFlowTemplateId: {
      required: "please_select_contract_flow"
    }
  };
  isSubmit = false;
  dropListStatus = [
    { value: false, text: "dropListStatus_true" },
    { value: true, text: "dropListStatus_false" }
  ];
  dropListDiscuss = [
    { value: true, text: "dropListDiscuss_co"},
    { value: false, text: "dropListDiscuss_khong"}
  ];
  dropListType = [
    { value: "ELECTRONIC", text: "dropListType_ELECTRONIC"},
    { value: "PAPER", text: "dropListType_PAPER"}
  ];
  attachFile: any = null;
  inputFileLabel = "select_contract_template_file";
  pdfFile = null;
  showFile = false;
  fileType: Array<string> = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

  internalDiscuss: InternalDiscuss[] = [];
  partnerDiscuss: PartnerDiscuss[] = [];
  signFlow: SignFlow[] = [];
  internalDiscussError: any = null;
  partnerDiscussError: any = null;
  signFlowError: any = null;
  
  dropListFlow: Observable<any>;
  flowLoading = false;
  flowInput$ = new Subject<string>();
  flowContractSelected: FlowContract = null;
  flowPage = 1;
  endDataFlow = false;
  flowKeySearch = "";

  /**
   * Thêm luồng thẩm định nội bộ đối với hợp đồng không đàm phán
   */
  internalDiscussTypes = InternalDiscussTypes;
  internalDiscussType = this.internalDiscussTypes.NO_REQUIRE_FLOW;

  constructor(
    private fb: FormBuilder,
    private partService: PartService,
    private employeeService: EmployeeService,
    private templateService: TemplateService,
    private myAlert: AlertControl,
    private flowContractService: FlowContractService,
    private authService: AuthService,
    private router: Router,
    private activedRoute: ActivatedRoute,
    private loadingService: LoadingService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.viewDidLoad();
  }

  viewDidLoad() {
    this.flowContractSelected = new FlowContract(this.flowContract);
    
    this.makeForm();
    this.setData(this.flowContract);
    this.lazyLoadEFlow();
  }

  makeForm() {
    this.form = this.fb.group({
      contractFlowTemplateId: [null, [Validators.required]],
      type: ['ELECTRONIC', [Validators.required]],
      discuss: [false, [Validators.required]],
    });
  }

  setData(param: FlowContract) {
    this.form.patchValue({
      contractFlowTemplateId: param.contractFlowTemplateId || null,
      type: param.type,
      discuss: param.discuss,
    });
    
    this.signFlowType = param.signFlowType;
    this.inputFileLabel = param.fileName;
    this.internalDiscussType = param.internalDiscussType;

    param.internalDiscuss.forEach((item) => {
      let i = new InternalDiscuss(item);
      i.setPerAction();
      this.internalDiscuss.push(i);
    });

    param.partnerDiscuss.forEach((item) => {
      let i = new PartnerDiscuss(item);
      i.setPerAction();
      this.partnerDiscuss.push(i);
    });

    param.signFlow.forEach((item) => {
      let i = new SignFlow(item);
      this.signFlow.push(i);
    });
  }

  showFilePreview() {
    this.getPreviewContractSample(this.id);
  }

  /**
   * Dong man hinh preview file
   * @param data 
   */
  onClosePreview(data) {
    this.showFile = false;
    this.pdfFile = null;
  }

  addInternalDiscuss() {
    this.internalDiscuss.push(new InternalDiscuss({ sequence: this.internalDiscuss.length + 1 }));
  }

  removeInternalDiscuss(index) {
    let minSequence = this.internalDiscuss[index].sequence;
    this.internalDiscuss.splice(index, 1);
    this.internalDiscuss.forEach((item) => {
      if (item.sequence >= minSequence) {
        item.sequence = item.sequence - 1;
      }
    })
  }

  addPartnerDiscuss() {
    this.partnerDiscuss.push(new PartnerDiscuss());
  }

  removePartnerDiscuss(index) {
    this.partnerDiscuss.splice(index, 1);
  }

  addSignFlow() {
    if (this.signFlowType === this.signFlowTypes.REQUIRE_FLOW_STAMP) {
      this.addSignFlowStamp();
      return
    }
    let endItem = this.signFlow[this.signFlow.length - 1];
    let topItems: any[] = this.signFlow.splice(0, this.signFlow.length - 1);
    let newItem = new SignFlow({ signType: "DRAFT" });
    newItem.sequence = endItem.sequence;
    endItem.sequence++;

    this.signFlow = [...topItems, newItem, endItem];
  }

  removeSignFlow(index) {
    let minSequence = this.signFlow[index].sequence;
    this.signFlow.splice(index, 1);
    this.signFlow.forEach((item) => {
      if (item.sequence >= minSequence) {
        if (this.signFlowType === this.signFlowTypes.REQUIRE_FLOW_STAMP) {
          if (item.signType === ConstSignType.APPROVAL) {
            item.sequence = this.signFlow.length - 1;
          } else if (item.signType === ConstSignType.STAMP) {
            item.sequence = this.signFlow.length;
          } else {
            item.sequence = item.sequence - 1;
          }
        } else {
          if (item.signType === ConstSignType.APPROVAL) {
            item.sequence = this.signFlow.length;
          } else {
            item.sequence = item.sequence - 1;
          }
        }
      }
    })
  }

  save() {
    this.isSubmit = true;
    this.checkValidate();

    console.log(this.internalDiscussError);
    console.log(this.partnerDiscussError);
    console.log(this.signFlowError);

    if (this.form.invalid) return;
    if (this.signFlowError) return;
    if (this.form.value.discuss === true && (this.internalDiscussError || this.partnerDiscussError)) return;
    if (this.form.value.discuss === false && this.internalDiscussError) return;

    let approve_sign_type = false;
    let approve_sign = this.signFlow.find((x: any) => { return x.signType === "APPROVAL" });
    if (approve_sign) {
      approve_sign.signForm.forEach((x: any) => {
        if (signFormsApproval.includes(x)) {
          approve_sign_type = true;
        }
      })
    } else {
      approve_sign_type = true;
    }

    let stamp_sign_type = false;
    let stamp_sign = this.signFlow.find((x: any) => { return x.signType === "STAMP" });
    if (stamp_sign) {
      stamp_sign.signForm.forEach((x: any) => {
        if (signFormsApproval.includes(x)) {
          stamp_sign_type = true;
        }
      })
    } else {
      stamp_sign_type = true;
    }

    if (approve_sign_type && stamp_sign_type) {
      this.saveAfterConfirm();
    } else {
      this.myAlert.showAlert(this.translate.instant('confirm_allow_signform_level1_for_approval'), ConstantAlertType.BLANK, true, "", this.translate.instant('btn_save'), () => {
        this.saveAfterConfirm();
      }, "", this.translate.instant('btn_cancel'), null, this.translate.instant('confirm'));
    }

  }

  saveAfterConfirm() {
    let flowContract: FlowContract = new FlowContract(this.flowContractSelected);
    flowContract.internalDiscuss = this.internalDiscuss;
    flowContract.partnerDiscuss = this.partnerDiscuss;
    flowContract.signFlow = this.signFlow;
    flowContract.signFlowType = this.signFlowType;
    flowContract.internalDiscussType = this.internalDiscussType;

    if (this.contract.contractId) {
      let arrReq = [];
      if (this.flowContractSelected.discuss) {
        if (this.isCreator || this.internalEditable) {
          arrReq.push(this.postUpdateInteralDiscuss(this.contract.contractId, this.getInternalDiscussParam()));
        }
        if (this.isCreator || this.partnerEditable) {
          arrReq.push(this.postUpdatePartnerDiscuss(this.contract.contractId, this.getPartnerDiscussParam()));
        }
      }
      if ((this.isCreator || this.signFlowEditable) && this.changedSignFlowType) {
        arrReq.push(this.postUpdateSignFlowType(this.contract.contractId, this.signFlowType));
      }
      if (this.isCreator || this.signFlowEditable) {
        arrReq.push(this.postUpdateSignFlow(this.contract.contractId, this.getSignFlowParam()));
      }
      if (arrReq.length > 0) {
        this.myAlert.showAlert(this.translate.instant('confirm_update_flow_contract_config'), ConstantAlertType.BLANK, true, "", this.translate.instant('btn_confirm'), () => {
          this.postUpdateFlowContract(arrReq);
        }, "", this.translate.instant('btn_cancel'));
      }
    } else {
      this.closed.emit(flowContract);
    }
  }

  getInternalDiscussParam() {
    let res = [];
    this.internalDiscuss.forEach((item) => {
      res.push({
        "userId": item.userId || "",
        "departmentId": item.partyId || "",
        "permissions": item.permissions || []
      });
    })
    return res;
  }

  getPartnerDiscussParam() {
    let res = [];

    if (this.form.value.discuss == false && this.internalDiscussType == this.internalDiscussTypes.NO_REQUIRE) {
      return res;
    }

    this.internalDiscuss.forEach((item) => {
      let p = {
        "userId": item.userId || "",
        "departmentId": item.partyId || "",
        "permissions": item.permissions || []
      };
      if (this.form.value.discuss == false && this.internalDiscussType == this.internalDiscussTypes.REQUIRE_FLOW) {
        p['sequence'] = +item.sequence;
      }
      res.push(p);
    })
    return res;
  }

  getSignFlowParam() {
    let res = []; 
    this.signFlow.forEach((item) => {
      res.push({
        "signType": item.signType || "",
        "departmentId": item.partyId || "",
        "userId": item.userId || "",
        "sequence": +item.sequence,
        "limitDate": +item.limitDate,
        "signForm": item.signForm
     });
    })

    return res;
  }

  /**
   * Kiem tra du lieu cac bang
   */
  checkValidate() {
    this.internalDiscussError = null;
    this.partnerDiscussError = null;
    this.signFlowError = null;

    if (this.form.value.discuss === true) {
      this.internalDiscuss.forEach((item) => {
        item.checkValidate();
        this.internalDiscussError = {...this.internalDiscussError, ...item.generalErrorValidate};
      });

      let arr = [];
      this.partnerDiscuss.forEach((item, index) => {
        item.checkValidate();
        arr.push({
          ...item,
          index: index
        })
        this.partnerDiscussError = {...this.partnerDiscussError, ...item.generalErrorValidate};
      });
    }

    if (this.form.value.discuss == false) {
      // Nếu luồng không đàm phán
      if (this.internalDiscussType == this.internalDiscussTypes.REQUIRE_FLOW) {
        // Nếu có sử dụng thẩm định nội bộ cho luồng không đàm phán

        /**
         * Kiểm tra valid dữ liệu từng dòng
         */
        let arr = [];
        this.internalDiscuss.forEach((item, index) => {
          item.checkValidate(true);
          arr.push({
            ...item,
            index: index
          })
          this.internalDiscussError = {...this.internalDiscussError, ...item.generalErrorValidate};
        });

        /**
         * Kiểm tra valid dữ liệu sequence từng dòng
         */
        arr.sort((a, b) => { return a.sequence - b.sequence});
        for (let i = 0; i < arr.length; i++) {
          if (/[^0-9]*/g.test(arr[i].sequence)) {
            let s = Number(arr[i].sequence);
            if (s > arr.length) {
                this.internalDiscuss[arr[i].index].setError({ sequence: { max: true }});
                this.internalDiscuss[arr[i].index].setGeneralError({  maxSequence: true });
            }
    
            if (i - 1 >= 0 && /[^0-9]*/g.test(arr[i - 1].sequence)) {
              let prev_s = Number(arr[i - 1].sequence);
              if (s === prev_s) {
                this.internalDiscuss[arr[i].index].setError({ sequence: { unique: true }});
                this.internalDiscuss[arr[i].index].setGeneralError({  uniqueSequence: true });
              }
            }
            
          }
          this.internalDiscussError = {...this.internalDiscussError, ...this.internalDiscuss[arr[i].index].generalErrorValidate};
        }
      }
      /**
       * Kiểm tra thẩm định đã có người có quyền "đồng ý/ từ chối" chưa
       */
      if (this.internalDiscussType != this.internalDiscussTypes.NO_REQUIRE) {
        if (this.internalDiscuss.length < 0) {
          // Thêm lỗi không có người có quyền đồng ý thẩm định
          let err = { "requireAcceptPermission": true };
          this.internalDiscussError = {...this.internalDiscussError, ...err};
        }
        let ok = this.internalDiscuss.findIndex((x) => { return x.per_action["INTERNAL_ACCEPTABLE"] == true; });
        if (ok < 0) {
          // Thêm lỗi không có người có quyền đồng ý thẩm định
          let err = { "requireAcceptPermission": true };
          this.internalDiscussError = {...this.internalDiscussError, ...err};
        };
      }
    }

    let arr = [];
    this.signFlow.forEach((item, index) => {
      item.checkValidate(true);
      arr.push({
        ...item,
        index: index
      })
      this.signFlowError = {...this.signFlowError, ...item.generalErrorValidate};
    });

    arr.sort((a, b) => { return a.sequence - b.sequence});
    for (let i = 0; i < arr.length; i++) {
      if (/[^0-9]*/g.test(arr[i].sequence)) {
        let s = Number(arr[i].sequence);
        if (s > arr.length) {
            this.signFlow[arr[i].index].setError({ sequence: { max: true }});
            this.signFlow[arr[i].index].setGeneralError({  maxSequence: true });
            this.signFlowError = {...this.signFlowError, ...this.signFlow[arr[i].index].generalErrorValidate};
        }
        if (this.signFlowType === this.signFlowTypes.REQUIRE_FLOW_STAMP) {
          if (s === arr.length && arr[i].signType !== ConstSignType.STAMP) {
            this.signFlow[arr[i].index].setError({ sequence: { unique: true }});
            this.signFlow[arr[i].index].setGeneralError({  uniqueSequence: true });
          }
          if (s === arr.length - 1 && arr[i].signType !== ConstSignType.APPROVAL) {
            this.signFlow[arr[i].index].setError({ sequence: { unique: true }});
            this.signFlow[arr[i].index].setGeneralError({  uniqueSequence: true });
          }
        } else {
          if (s === arr.length && arr[i].signType !== ConstSignType.APPROVAL) {
            this.signFlow[arr[i].index].setError({ sequence: { unique: true }});
            this.signFlow[arr[i].index].setGeneralError({  uniqueSequence: true });
          }
        }

        if (i - 1 >= 0 && /[^0-9]*/g.test(arr[i - 1].sequence)) {
          let prev_s = Number(arr[i - 1].sequence);
          if (s === prev_s) {
            if (arr[i].signType === ConstSignType.APPROVAL || arr[i].signType === ConstSignType.STAMP) {
              this.signFlow[arr[i - 1].index].setError({ sequence: { unique: true }});
              this.signFlow[arr[i - 1].index].setGeneralError({  uniqueSequence: true });
            } else {
              this.signFlow[arr[i].index].setError({ sequence: { unique: true }});
              this.signFlow[arr[i].index].setGeneralError({  uniqueSequence: true });
            }
          }
        }
      }
    }

    this.internalDiscussError && Object.keys(this.internalDiscussError).length === 0 ? this.internalDiscussError = null : this.internalDiscussError;
    this.partnerDiscussError  && Object.keys(this.partnerDiscussError).length === 0 ? this.partnerDiscussError = null : this.partnerDiscussError;
    this.signFlowError && Object.keys(this.signFlowError).length === 0 ? this.signFlowError = null : this.signFlowError;

  }

  /**
   * Gui yeu cau lay thong tin luong hop dong
   * @param id 
   */
  getDetailFlowContract(id: string) {
    this.flowContractService.getDetailFlowContract(id).subscribe((res: any) => {
      console.log(res);
      this.flowContractSelected = new FlowContract(res?.object || {});
      this.setData(this.flowContractSelected);
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }

  getPreviewContractSample(id) {
    this.flowContractService.getPreviewContractSample(id).subscribe((res: any) => {
      const blob: any = new Blob([res], { type: 'application/pdf' });
      this.pdfFile = blob;
      this.showFile = true;
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }

  actBtnSave() {
    this.save();
  }

  goToBack() {
    this.closed.emit(null);
  }

  trackByFnFlow($event) {
    return $event;
  }

  changeFlow(event) {
    this.internalDiscuss = [];
    this.partnerDiscuss = [];
    this.signFlow = [];
    this.internalDiscussError = null;
    this.partnerDiscussError = null;
    this.signFlowError = null;

    if (event && event.contractFlowTemplateId)
      this.getDetailFlowContract(event.contractFlowTemplateId);
  }

  addToDropList(data = [] ,param) {
    if (param === null) return data;
    let index = data.findIndex((item) => {
        return param.partyId === item.partyId;
    });
    if (index >= 0) return data;
    console.log([...data, param])
    return [...data, param]; 
  }

  lazyLoadEFlow() {
    this.dropListFlow = concat(
      this.flowInput$.pipe(
        startWith(''),
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => { this.flowLoading = true; LoadingService.instance.disabledLoader(this.flowLoading); }),
        switchMap((term: any) => {
          this.flowPage = 1;
          this.endDataFlow = false;
          this.flowKeySearch = term;
          let res = this.flowContractService.getDataDropListFlowContract(1, 20, "created", "DESC", 0, term || "", this.form.value.discuss ? 1 : 0).pipe(
            map((res: any) => {
              return this.addToDropList(res?.object?.data, this.flowContract.contractFlowTemplateId ? { userId: this.flowContract.contractFlowTemplateId, fullName: this.flowContract.name } : null)
            }),
            catchError(() => of([])), // empty list on error
            tap(() => { this.flowLoading = false; LoadingService.instance.disabledLoader(this.flowLoading); })
          );
          return res;
        })
      ));
  }

  /**
  * Su kien scroll xuong duoi cung cua droplist
  * @returns 
  */
  onScrollToEndDropListFlow() {
    if (this.endDataFlow) return;

    var nextPage = this.flowPage + 1;
    this.flowPage = nextPage;
    this.loadMoreFlow(this.flowPage);
  }

  /**
   * Tai them du lieu droplist Luong hop dong
   * @param page 
   */
  loadMoreFlow(page) {
    this.flowLoading = true; LoadingService.instance.disabledLoader(this.flowLoading);
    this.flowContractService.getDataDropListFlowContract(page, 20, "created", "DESC", 0, this.flowKeySearch || "", this.form.value.discuss ? 1 : 0).subscribe((res: any) => {
      console.log(res);
      if (res?.object?.data.length <= 0) {
        this.endDataFlow = true;
      }
      
      this.dropListFlow.subscribe((value) => {
        this.dropListFlow = of(value.concat(res?.object?.data || []));
      });

      this.flowLoading = false; LoadingService.instance.disabledLoader(this.flowLoading);
    }, err => {
      this.flowLoading = false; LoadingService.instance.disabledLoader(this.flowLoading);
    })
  }

  /**
   * Quyen edit luong ky noi bo
   */
  get isCreator() {
    let isAdmin = this.authService.isAdmin;
    let isAm = this.authService.userId == this.contract.amId;
    return isAdmin || isAm;
  }


  postUpdateInteralDiscuss(contractId, param) {
    return this.flowContractService.postUpdateInteralDiscuss(contractId, param);
  }

  postUpdatePartnerDiscuss(contractId, param) {
    return this.flowContractService.postUpdatePartnerDiscuss(contractId, param);
  }

  postUpdateSignFlow(contractId, param) {
    return this.flowContractService.postUpdateSignFlow(contractId, param);
  }

  postUpdateFlowContract(arrReq: any[]) {
    if (arrReq.length > 0) {
      forkJoin((arrReq)).subscribe((res: any) => {
        this.myAlert.showAlertOnlyNoti(localize.update_flow_contract_config_success, ConstantAlertType.SUCCESS);

        let flowContract: FlowContract = new FlowContract(this.flowContractSelected);
        flowContract.internalDiscuss = this.internalDiscuss;
        flowContract.partnerDiscuss = this.partnerDiscuss;
        flowContract.signFlow = this.signFlow;
        this.closed.emit(flowContract);
      }, err => {
        this.myAlert.showErrorHandled(err);
      })
    }
  }

  changeDiscussType(e) {
    this.lazyLoadEFlow();
  }

  //UPDATE Lan 1
  signFlowType = "REQUIRE_FLOW";
  signFlowTypes = SignFlowTypes;
  changedSignFlowType = false;
  chooseSignFlowType(e) {
    console.log(this.signFlowType);
    this.changedSignFlowType = true;
    if (this.signFlowType === this.signFlowTypes.REQUIRE_FLOW_STAMP) {
      
      let endItem = this.signFlow[this.signFlow.length - 1];
      let newItem = new SignFlow({ signType: ConstSignType.STAMP });
      newItem.sequence = endItem.sequence + 1;
  
      this.signFlow = [...this.signFlow, newItem];
    } else {
      let stampItem = this.signFlow.findIndex((x) => { return x.signType === ConstSignType.STAMP});
      if (stampItem >= 0) {
        this.signFlow.splice(stampItem, 1);
      } 
    }
  } 

  addSignFlowStamp() {
    let endIndexItem = this.signFlow.length - 1;
    let newItem = new SignFlow({ signType: ConstSignType.DRAFT });
    this.signFlow[endIndexItem].sequence++;
    this.signFlow[endIndexItem - 1].sequence++;
    newItem.sequence = this.signFlow[endIndexItem - 1].sequence - 1;

    this.signFlow.splice(this.signFlow.length - 2, 0, newItem);
  }

  postUpdateSignFlowType(contractId, signFlowType) {
    return this.flowContractService.postUpdateSignFlowType(contractId, signFlowType);
  }

  /** 
   * MARK: THẨM ĐỊNH HỢP ĐỒNG TRƯỚC KÝ
  */

  /**
   * Chọn loại thẩm định
   * @param e 
   */
   chooseInternalDiscussType(e) {
    console.log(e);
  }

  /**
   * Check điều kiện hiển thị bản nhân sự thẩm định
   */
  get showTableInternalDiscuss() {
    return this.internalDiscussType !== this.internalDiscussTypes.NO_REQUIRE || this.form.value.discuss === true;
  }
}
