<form [formGroup]="formSubmit">
    <div class="row">
        <div class="col-md-auto align-self-end">
            <label class="text-lg">{{ 'ProfileConsumer_title' | translate }}</label>
        </div>
        <div class="col-md-auto ml-auto">
            <!-- <button *ngIf="userInfo.identityLevel > 1" (click)="actRequestChangeIdentity()"
                class="btn btn-outline-primary d-inline-flex mr-4"><span class="material-icons mr-1">how_to_reg</span>
                Thay đổi định danh</button> -->
            <button (click)="actBtnCancel($event)" class="btn btn-outline-primary btn-light d-inline-flex mr-4"><span
                    class="material-icons">clear</span> {{ 'btn_cancel' | translate }}</button>
            <button (click)="submit($event)" class="btn btn-primary d-inline-flex"><span
                    class="material-icons mr-1">save</span> {{ 'btn_save' | translate }}</button>
        </div>
    </div>
    <hr class="my-3">
    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label>{{ 'EmployeeInPart_username' | translate }} <span class="text-danger">*</span>:</label>
                <input class="form-control" formControlName="username" placeholder="{{ 'ProfileBusiness_account_input_name' | translate }}">
                <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.username"
                    [control]="username" [detail]="{ name: 'username' }"></app-show-validate-errors>
            </div>
        </div>
        <div class="col-md-6">
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ 'SignUpConsumer_input_email' | translate }} <span class="text-danger">*</span>:</label>
                        <input [attr.disabled]="!isIdentityAgain ? '': null" class="form-control"
                            formControlName="email" placeholder="{{ 'EmployeeAdd_ph_2' | translate }}">
                        <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.email"
                            [control]="email" [detail]="{ name: 'email' }"></app-show-validate-errors>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>{{ 'ESignOtp_sms_signForm' | translate }} <span class="text-danger">*</span>:</label>
                        <input [attr.disabled]="!isIdentityAgain ? '': null" class="form-control"
                            formControlName="phone" placeholder="{{ 'EmployeeAdd_ph_3' | translate }}">
                        <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.phone"
                            [control]="phone" [detail]="{ name: 'phone' }"></app-show-validate-errors>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
<form [formGroup]="formIdentity" [class.d-none]="userInfo.identityLevel <= 1">
    <label class="text-primary">{{ 'ProfileConsumer_label_1' | translate }}</label>
    <hr>
    <div *ngIf="userInfo.loaiGtId === '0' || userInfo.loaiGtId === '1'">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{ 'full_name' | translate }} <span class="text-danger">*</span>:</label>
                    <input [attr.disabled]="!isIdentityAgain ? '': null" class="form-control" formControlName="fullname"
                        placeholder="{{ 'ProfileBusiness_agent_input_name' | translate }}">
                    <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.fullname"
                        [control]="fullname" [detail]="{ name: 'fullname' }"></app-show-validate-errors>
                </div>
            </div>
            <div class="col-md-12 col-lg-6">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ 'ProfileBusiness_agent_birth' | translate }} <span class="text-danger">*</span>:</label>
                            <input [attr.disabled]="!isIdentityAgain ? '': null" (input)="dateInput($event, dateOfBirth)"
                                type="text" formControlName="dateOfBirth" class="form-control date" placeholder="dd/MM/yyyy"
                                id="dateOfBirth">
                            <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.dateOfBirth"
                                [control]="dateOfBirth" [detail]="{ name: 'dateOfBirth' }"></app-show-validate-errors>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ 'ProfileConsumer_label_3' | translate }} <span class="text-danger">*</span>:</label>
                            <input [attr.disabled]="!isIdentityAgain ? '': null" class="form-control"
                                formControlName="soGiayTo" placeholder="{{ 'ProfileConsumer_label_4' | translate }}">
                            <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.soGiayTo"
                                [control]="soGiayTo" [detail]="{ name: 'soGiayTo' }"></app-show-validate-errors>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 col-lg-6">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ 'WaitConsumerPartner_date_range' | translate }} <span class="text-danger">*</span>:</label>
                            <input [attr.disabled]="!isIdentityAgain ? '': null" (input)="dateInput($event, ngayCap)"
                                type="text" formControlName="ngayCap" class="form-control date" placeholder="dd/MM/yyyy"
                                id="ngayCap">
                            <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.ngayCap"
                                [control]="ngayCap" [detail]="{ name: 'ngayCap' }"></app-show-validate-errors>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ 'WaitConsumerPartner_issue' | translate }} <span class="text-danger">*</span>:</label>
                            <input [attr.disabled]="!isIdentityAgain ? '': null" class="form-control"
                                formControlName="noiCap" placeholder="{{ 'ProfileConsumer_label_5' | translate }}">
                            <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.noiCap"
                                [control]="noiCap" [detail]="{ name: 'noiCap' }"></app-show-validate-errors>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-6">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>{{ 'ProfileConsumer_label_2' | translate }} <span class="text-danger">*</span>:</label>
                            <input [attr.disabled]="!isIdentityAgain ? '': null" class="form-control" formControlName="noiDKHKTT"
                                placeholder="{{ 'ProfileConsumer_label_6' | translate }}">
                            <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.noiDKHKTT"
                                [control]="noiDKHKTT" [detail]="{ name: 'noiDKHKTT' }"></app-show-validate-errors>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ng-template *ngIf="userInfo.loaiGtId === null || userInfo.loaiGtId === '2'">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{ 'full_name' | translate }} <span class="text-danger">*</span>:</label>
                    <input [attr.disabled]="!isIdentityAgain ? '': null" class="form-control" formControlName="fullname"
                        placeholder="{{ 'ProfileBusiness_agent_input_name' | translate }}">
                    <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.fullname"
                        [control]="fullname" [detail]="{ name: 'fullname' }"></app-show-validate-errors>
                </div>
            </div>
            <div class="col-md-12 col-lg-6">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ 'ProfileBusiness_agent_gender' | translate }} <span class="text-danger">*</span>:</label>
                            <ng-select [attr.disabled]="!isIdentityAgain ? '': null"
                                formControlName="gender" placeholder="{{ 'ProfileBusiness_agent_input_gender' | translate }}">
                                <ng-option value="2">{{ 'WaitPartyPartner_agent_gender_2' | translate }}</ng-option>
                                <ng-option value="1">{{ 'WaitPartyPartner_agent_gender_1' | translate }}</ng-option>
                                <ng-option value="3">{{ 'ProfileConsumer_label_7' | translate }}</ng-option>
                            </ng-select>
                            <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.gender"
                                [control]="gender" [detail]="{ name: 'gender' }"></app-show-validate-errors>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ 'ProfileBusiness_agent_birth' | translate }} <span class="text-danger">*</span>:</label>
                            <input [attr.disabled]="!isIdentityAgain ? '': null" (input)="dateInput($event, dateOfBirth)"
                                type="text" formControlName="dateOfBirth" class="form-control date" placeholder="dd/MM/yyyy"
                                id="dateOfBirth">
                            <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.dateOfBirth"
                                [control]="dateOfBirth" [detail]="{ name: 'dateOfBirth' }"></app-show-validate-errors>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 col-lg-6">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ 'WaitConsumerPartner_id_passport' | translate }}:</label>
                            <input [attr.disabled]="!isIdentityAgain ? '': null" class="form-control"
                                formControlName="soGiayTo" placeholder="{{ 'ProfileConsumer_label_4' | translate }}">
                            <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.soGiayTo"
                                [control]="soGiayTo" [detail]="{ name: 'soGiayTo' }"></app-show-validate-errors>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ 'WaitConsumerPartner_date_range' | translate }} <span class="text-danger">*</span>:</label>
                            <input [attr.disabled]="!isIdentityAgain ? '': null" (input)="dateInput($event, ngayCap)"
                                type="text" formControlName="ngayCap" class="form-control date" placeholder="dd/MM/yyyy"
                                id="ngayCap">
                            <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.ngayCap"
                                [control]="ngayCap" [detail]="{ name: 'ngayCap' }"></app-show-validate-errors>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 col-lg-6">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label>{{ 'WaitConsumerPartner_issue' | translate }} <span class="text-danger">*</span>:</label>
                            <input [attr.disabled]="!isIdentityAgain ? '': null" class="form-control"
                                formControlName="noiCap" placeholder="{{ 'ProfileConsumer_label_5' | translate }}">
                            <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.noiCap"
                                [control]="noiCap" [detail]="{ name: 'noiCap' }"></app-show-validate-errors>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{ 'ProfileConsumer_label_2' | translate }} <span class="text-danger">*</span>:</label>
                    <input [attr.disabled]="!isIdentityAgain ? '': null" class="form-control" formControlName="noiDKHKTT"
                        placeholder="{{ 'ProfileConsumer_label_6' | translate }}">
                    <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.noiDKHKTT"
                        [control]="noiDKHKTT" [detail]="{ name: 'noiDKHKTT' }"></app-show-validate-errors>
                </div>
            </div>
        </div>
    </ng-template>
</form>
<!-- <app-mini-spinner *ngIf="loadingFormIsVisiable() | async"></app-mini-spinner> -->