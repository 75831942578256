import {
  Component,
  OnInit,
  HostListener,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from "@angular/core";
import * as $ from "jquery";
import { Router, ActivatedRoute } from "@angular/router";
import { Config } from "src/app/config";
import {
  FormGroup,
  FormBuilder,
  Validators,
  FormControl,
} from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { HomeService } from "src/app/utils/services/home/home.service";
import { FeatureComponent } from "../feature/feature.component";
import { PricelistComponent } from "../pricelist/pricelist.component";
import { SupportComponent } from "../support/support.component";
import { ContactComponent } from "../contact/contact.component";

import { forkJoin } from "rxjs";
import { PostComponent } from "../post/post.component";
@Component({
  selector: 'app-home-v4',
  templateUrl: './home-v4.component.html',
  styleUrls: ['./home-v4.component.scss']
})
export class HomeV4Component implements OnInit {

  @ViewChild('featureView', {static: true}) private featureView: FeatureComponent;
  @ViewChild('pricelistView', {static: true}) private pricelistView: PricelistComponent;
  @ViewChild('supportView', {static: true}) private supportView: SupportComponent;
  @ViewChild('contact', {static: true}) private contact: ContactComponent
  @ViewChild('post', {static: true}) private post: PostComponent

  fragment: string = "";
  homeMode: number = 0;
  homeData: any = null;
  configs: any = [];
  link = {
    iosLink: "",
    androidLink:  "",
    qrcodeLink: ""
  }
  headquaterAddress: any = null;
  

  param = {value: "+1.000"}
  footerData = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private homeService: HomeService
    ) {
    this.fragment = this.route.snapshot.fragment;
  }

  ngOnInit(): void {
    // if (this.fragment && this.fragment == "contact") {
    //   this.changeMode(1);
    // }
    // if (this.fragment && this.fragment == "pricelist") {
    //   this.changeMode(2);
    // }
    // if (this.fragment && this.fragment == "support") {
    //   this.changeMode(3);
    // }
    // if (this.fragment && this.fragment == "feature") {
    //   this.changeMode(4);
    // }
    // if (this.fragment && this.fragment == "post") {
    //   this.changeMode(5);
    // }
    this.getData(this.translate.currentLang);
  }

  // GET ALL DATA OF LANDING PAGE
  getData(language) {
    forkJoin([
      this.homeService.getData(language),
      this.homeService.getConfigs(language),
      this.homeService.getDataByCodeCategory("headquaterAddress", language)
    ]).subscribe((res: any) => {
      // get data
      this.homeData = res[0]?.object;
      // get config
      this.configs = res[1]?.object || [];
      for (let i = 0; i < this.configs.length; i++) {
        if (this.configs[i].key == 'ios') {
          this.link.iosLink = this.configs[i].value;
          continue;
        }
        if (this.configs[i].key == 'android') {
          this.link.androidLink = this.configs[i].value;
          continue;
        }
        if (this.configs[i].key == 'qrcode') {
          this.link.qrcodeLink = this.configs[i].value;
          continue;
        }
      }
      // get address
      this.headquaterAddress = res[2]?.object?.item;
      console.log(this.headquaterAddress)
      this.footerData = {
        description: this.headquaterAddress?.blogs[0]?.description,
        androidLink: this.link?.androidLink,
        iosLink: this.link?.iosLink
      }
    });
    
  }


  changeMode(mode) {
    this.homeMode = mode;
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 300);
  }

  

  reloadSubPage(mode, language) {
    switch (mode) {
      case 4:{
        this.featureView.reload(language);
        break;
      };
      case 3:{
        this.supportView.reload(language);
        break;
      };
      case 2:{
        this.pricelistView.reload(language);
        break;
      };
      case 1:{
        this.contact.reload(language);
        break;
      };
      
    }
  }


  ngAfterViewInit() {
    var _this = this;
    var pushy = $(".pushy"), //menu css class
      body = $("div.landingpage"),
      container = $("#container"),
      push = $(".push"),
      pushyRight = "pushy-right",
      pushyOpenLeft = "pushy-open-left",
      pushyOpenRight = "pushy-open-right",
      siteOverlay = $(".site-overlay"),
      menuBtn = $(".menu-btn"),
      menuItem = $(".pushy-link"),
      menuLinkFocus = $(pushy.data("focus")),
      menuBtnFocus = $(".menu-btn");

    menuBtn.on("click", function () {
      _this.togglePushy();
    });
    menuItem.on("click", function () {
      $("#menu_portal li").removeClass("active");
      $("#menu_portal li a").removeClass("active");
      $(this).children("a").addClass("active");
      $(this).addClass("active");
      _this.togglePushy();
    });
   

    //close menu when clicking site overlay
    siteOverlay.on("click", function () {
      _this.togglePushy();
    });
    setTimeout(function () {
    

    }, 1500);



  }
  togglePushy() {
    var pushy = $(".pushy"), //menu css class
      body = $("div.landingpage"),
      container = $("#container"),
      push = $(".push"),
      pushyRight = "pushy-right",
      pushyOpenLeft = "pushy-open-left",
      pushyOpenRight = "pushy-open-right",
      siteOverlay = $(".site-overlay"),
      menuBtn = $(".menu-btn"),
      menuItem = $(".pushy-link"),
      menuLinkFocus = $(pushy.data("focus")),
      menuBtnFocus = $(".menu-btn");

    body.toggleClass(pushyOpenRight);

   
  }

  //Bat su kien click menu
  changedMode(x) {
    this.changeMode(x);
    this.reloadSubPage(x, this.translate.currentLang);
  }

  //Bat su kien thay doi ngon ngu
  changedLanguage(x) {
    this.getData(x); 
    this.reloadSubPage(this.homeMode, x)
  }
}
