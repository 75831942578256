import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { AuthService } from 'src/app/utils/services/auth.service';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { SignatureService } from 'src/app/utils/services/aas-network/signature.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AasValidateMessage } from 'src/app/utils/common/validate-msg-aas';
import { DigitalSignatureItem } from 'src/app/utils/models/aas-models/signature/digital-signature/digital-signature-item';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { DigitalSignatureResponse } from 'src/app/utils/models/aas-models/signature/digital-signature/digital-signature-response';
import { localize } from 'src/app/utils/localize/localize';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { TranslateService } from '@ngx-translate/core';

declare var $: any;
@Component({
  selector: 'app-digital-signature-manager',
  templateUrl: './digital-signature-manager.component.html',
  styleUrls: ['./digital-signature-manager.component.scss']
})
export class DigitalSignatureManagerComponent implements OnInit {
  @ViewChild('inputFile') _inputFile: ElementRef;
  @ViewChild('esign_draw_canvas') esign_draw_canvas: ElementRef;

  spinnerR = new BehaviorSubject<boolean>(false);
  spinnerL = new BehaviorSubject<boolean>(false);
  spinnerLg = new BehaviorSubject<boolean>(false);

  digitalSignList: Array<DigitalSignatureItem> = [];
  selectedCert: DigitalSignatureItem = new DigitalSignatureItem();

  formUpdateConfig: FormGroup;
  inputFileLabel: string = 'ESign_ts_1';
  fileType = ['image/jpeg', 'image/png', 'image/jpg'];
  fileImg: any;
  imgContent: string = '';
  isSubmit: boolean = false;
  addDSign: boolean = false;
  newImgContent: string = "";

  validMsg = AasValidateMessage.E_SIGNATURE_CONFIG;

  totalElement: number = 0;
  maxSize: number = 5;
  page: number = 1;

  localize = localize;
  typeCreateImg = "0";

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private aasAuth: AasAuthService,
    private signatureService: SignatureService,
    private modalService: NgbModal,
    private alert: AlertControl,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.makeFormUpdateconfig();
    this.getListDigitalSignature();
  }

  //MARK: Khởi tạo form cập nhật config
  makeFormUpdateconfig() {
    this.formUpdateConfig = this.fb.group({
      imageFile: new FormControl(''),
    })
  }
  get imageFile() { return this.formUpdateConfig.get('imageFile'); }

  //MARK: Spinner
  spinnerRShow(): Observable<boolean> {
    return this.spinnerR.asObservable().pipe(share());
  }

  spinnerLShow(): Observable<boolean> {
    return this.spinnerL.asObservable().pipe(share());
  }

  spinnerLgShow(): Observable<boolean> {
    return this.spinnerLg.asObservable().pipe(share());
  }

  //MARK: Extension
  loadPage(page) {

  }

  selectType(type) {
    console.log(this.typeCreateImg);
  }

  //MARK: Input file
  handleFileInput(e) {
    this.fileImg = e.target.files && e.target.files.item(0);
    console.log(this.fileImg);
    if (this.fileImg && this.fileImg !== null && this.fileImg !== undefined) {
      this.inputFileLabel = this.fileImg.name;

      if (!this.fileType.includes(this.fileImg.type)) {
        this.imageFile.setErrors({ type: true });
        this.newImgContent = '';
        this.inputFileLabel = 'ESign_ts_1';
        this.fileImg = null;
      } else if (this.fileImg.size > 5000000) {
        this.imageFile.setErrors({ size: true });
        this.newImgContent = '';
        this.inputFileLabel = 'ESign_ts_1';
        this.fileImg = null;
      } else {
        this.getBase64String(this.fileImg, this.handle);

        var self = this;
        var u = URL.createObjectURL(this.fileImg);
        var img = new Image;
        img.src = u;
        img.onload = function () {
          self.resizeImage(img, 500, 500);
        }
      }
    } else {
      this.imageFile.setErrors({ required: true });
      this.newImgContent = '';
      this.inputFileLabel = 'ESign_ts_1';
    }
    this._inputFile.nativeElement.value = '';
  }

  getBase64String(blob, callback) {
    let self = this;
    var reader = new FileReader();
    reader.onload = function (e) {
      callback(reader.result, self);
    }

    reader.readAsDataURL(blob);
  }

  handle(str, self) {
    console.log(str);
    self.newImgContent = str;
  }

  // convert imgUrl to file object
  urltoFile(url, filename, mimeType) {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  // resize image 
  resizeImage(img, max_width, max_height) {
    var width = img.width;
    var height = img.height;

    // calculate the width and height, constraining the proportions
    if (width > height) {
      if (width > max_width) {
        //height *= max_width / width;
        height = Math.round(height *= max_width / width);
        width = max_width;
      }
    } else {
      if (height > max_height) {
        //width *= max_height / height;
        width = Math.round(width *= max_height / height);
        height = max_height;
      }
    }
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');
    canvas.width = width;
    canvas.height = height;
    ctx.drawImage(img, 0, 0, width, height);
    var self = this;
    let imgURL = ctx.canvas.toDataURL(this.fileImg.type, 1);
    this.urltoFile(imgURL, this.fileImg.name, this.fileImg.type).then(function (file) {
      self.fileImg = file;
    });
    canvas.remove();
  }

  //MARK: Them chu ky dien tu
  addDigitalSignature() {
    this.addDSign = true;

  }

  //MARK: Chọn thông tin chữ ký
  selectCert(item: DigitalSignatureItem) {
    this.selectedCert = item;
    this.imgContent = 'data:image/jpg;base64,' + item.base64Image;
  }

  //MARK: Luu thong tin chu ky so
  submitSave() {
    this.isSubmit = true;
    if (this.typeCreateImg === '1') {
      var base64 = this.convertCanvasToImage(this.canvas);
      this.fileImg = this.base64ToBlob(base64.split(',')[1], "image/png");
    }
    if (this.typeCreateImg === '0' && !this.formUpdateConfig.valid) return;
    if (this.fileImg && this.fileImg != null && this.fileImg != undefined) {
      let data = new FormData();
      data.append('image', this.fileImg);
      this.postDigitalSignature(data);
    }
  }

  base64ToBlob(base64, type = "application/pdf") {//Convert base64 to blob
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; ++i) {
        bytes[i] = binaryString.charCodeAt(i);
    }

    return new Blob([bytes], { type: type });
  }

  //MARK: Action Cancel
  actCancel() {
    console.log("clean");
    this.imgContent = '';
    this.inputFileLabel = 'ESign_ts_1';
    this.typeCreateImg = '0';
    this.newImgContent = '';
    this.formUpdateConfig.reset();
    this.clearCanvas();
    this.resetData();

    this.selectCert(this.selectedCert);
  }

  // //MARK: Popup thông báo
  // alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
  //   const modalRef = this.modalService.open(AlertComponent);
  //   modalRef.componentInstance.message = msg;
  //   modalRef.componentInstance.typeAlert = type;
  //   modalRef.componentInstance.twoBtn = twoBtn;
  //   modalRef.componentInstance.iconBtn1 = iconBtn1;
  //   modalRef.componentInstance.iconBtn2 = iconBtn2;
  //   modalRef.componentInstance.titleButton1 = titleBtn1;
  //   modalRef.componentInstance.titleButton2 = titleBtn2;
  //   modalRef.componentInstance.callback1 = actBtn1;
  //   modalRef.componentInstance.callback2 = actBtn2;
  // }

  // //MARK: Handle Error
  // handleError(err, errorMsg) {
  //   if (err.error && err.error.message && errorMsg[err.error.message]) {
  //     this.alert(errorMsg[err.error.message], 'error');
  //   } else if (err.error && err.error.error) {
  //     this.alert(err.error.error, 'error');
  //   } else {
  //     this.alert('Lỗi không xác định!', 'error');
  //   }
  // }

  //MARK: NETWORKING
  /**
 * Request lấy thông tin chữ ký điện tử
 */
  getListDigitalSignature() {
    this.spinnerL.next(true);
    this.signatureService.getListDigitalSignature().subscribe(res => {
      this.spinnerL.next(false);

      this.digitalSignList = new DigitalSignatureResponse(res).object;
      if (this.digitalSignList.length > 0) {
        this.selectCert(this.digitalSignList[0]);
      }
    }, err => {
      this.spinnerL.next(false);
      console.log(err);
      this.alert.showErrorHandled(err);
    });
  }

  /**
   * Request cập nhật config chữ ký điện tử
   * @param data 
   */
  postDigitalSignature(data: FormData) {
    this.spinnerR.next(true);
    this.signatureService.postDigitalSignature(data).subscribe(res => {
      this.spinnerR.next(false);

      this.imgContent = '';
      this.newImgContent = '';
      this.fileImg = null;
      this.inputFileLabel = 'ESign_ts_1';

      this.clearCanvas();
      
      this.alert.showAlertOnlyNoti(this.translate.instant('DigitalSignatureManager_ts_1'), 'success');
      this.getListDigitalSignature();
    }, err => {
      this.spinnerR.next(false);
      console.log(err);
      this.alert.showErrorHandled(err);
    })
  }

  canvas = null;
  drawing = false;
  startPoint = [0,0];
  context = null;
  drawed = false;

  resetData() {//Reset data
    this.canvas =  null;
    this.drawing = false;
    this.startPoint = [0,0];
    this.context = null;
    this.drawed = false;
  }

  clearCanvas() {
    this.drawing = false;
    this.startPoint = [0,0];
    this.drawed = false;
    
    if (this.canvas) {
      this.context = this.canvas.getContext('2d');
      this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
    }
  }
  
  loadCanvas() {//Reload su kien popup ve chu ky
    var self = this;
    this.resetData();
    setTimeout(() => {
      var drawCanvas = document.getElementById("esign-draw-canvas");
      this.canvas = document.getElementById('esign-canvas-signature');
      this.canvas.width = drawCanvas.offsetWidth;
      this.canvas.height = drawCanvas.offsetHeight;
      this.context = this.canvas.getContext('2d');
      this.context.lineWidth = 3;

      if(this.isMobileDevice()) {
        this.canvas.addEventListener('touchstart', (event) => {
          this.handleTouchStart(event, self);
        });
        this.canvas.addEventListener('touchmove', (event) => {
          this.handleTouchMove(event, self);
        });
        document.addEventListener('touchend', (event) => {
          this.handleTouchEnd(event, self);
        });
      } else {
        // khai báo các sự kiện (event)
        this.canvas.addEventListener('mousedown', (event) => {
          this.handleMouseDown(event, self)
        });
        this.canvas.addEventListener('mousemove', (event) => {
          this.handleMouseMove(event, self)
        });
        document.addEventListener('mouseup', (event) => {
          this.handleMouseUp(event, self)
        });
      }
    }, 1);
  }

  convertCanvasToImage(canvas) {//Chuyen canvas thanh hinh anh
    return canvas.toDataURL("image/png");
  }

  getPointOfCanvas(event) {//Get toa do tren canvas
    console.log(event);
    // xác định toạ độ của canvas
    const { x, y } = this.canvas.getBoundingClientRect();
    const { offsetX, offsetY } = event;
    // const scrollLeft = $(document).scrollLeft();
    // const scrollTop = $(document).scrollTop();
    return [offsetX , offsetY];
  }

  handleMouseDown(event, self) {//Su kien nhan chuot xuong
    self.drawing = true;
    self.startPoint = self.getPointOfCanvas(event);
    self.drawed = true;
  }

  handleMouseUp(event, self) {//Su kien tha chuot len
    self.drawing = false;
    self.startPoint = [0, 0];
  }

  handleMouseMove(event, self) {//Su kien di chuyen chuot trong canvas
    console.log("move");
    // không làm gì nếu chưa trong tiến trình vẽ
    if (!self.drawing) return;
    const nextPoint = self.getPointOfCanvas(event);
    self.context.beginPath();
    self.context.moveTo(...self.startPoint);
    self.context.lineTo(...nextPoint);
    self.context.stroke();
    self.context.closePath();
    // B sẽ trở thành điểm bắt đầu
    self.startPoint = [...nextPoint];
  }

  isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  getPointTouchOnCanvas(event) {
    var rect = this.canvas.getBoundingClientRect();
    return [
      event.touches[0].clientX - rect.left,
      event.touches[0].clientY - rect.top
    ];
  }

  handleTouchStart(event, self) {//Su kien nhan chuot xuong
    self.drawing = true;
    self.startPoint = self.getPointTouchOnCanvas(event); console.log(self.startPoint);
  }

  handleTouchEnd(event, self) {//Su kien tha chuot len
    self.drawing = false;
    self.startPoint = [0, 0];
  }

  handleTouchMove(event, self) {//Su kien di chuyen chuot trong canvas
    console.log("move");
    // không làm gì nếu chưa trong tiến trình vẽ
    if (!self.drawing) return;
    self.drawed = true;
    const nextPoint = self.getPointTouchOnCanvas(event);
    self.context.beginPath();
    self.context.moveTo(...self.startPoint);
    self.context.lineTo(...nextPoint);
    self.context.stroke();
    self.context.closePath();
    // B sẽ trở thành điểm bắt đầu
    self.startPoint = [...nextPoint];
  }

  isShowFullScreen = false;
  setShowFullScreen() {
    this.isShowFullScreen = !this.isShowFullScreen;
    if (this.isShowFullScreen) {
      $('html, body').css({
        overflow: 'hidden'
      });
    } else {
      $('html, body').css({
        overflow: 'auto'
      });
    }
  }

  saveOnPopup() {
    if (this.drawed == false) {
      this.alert.showAlertOnlyNoti("drawing_to_save", "");
      return;
    }

    this.submitSave(); 
    this.setShowFullScreen();
  }
}
