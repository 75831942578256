import { Component, Input, OnInit } from '@angular/core';
import { translate } from '@angular/localize/src/utils';
import { forkJoin } from 'rxjs';
import { HomeService } from 'src/app/utils/services/home/home.service';
import { HomeV4Component } from '../home-v4/home-v4.component';

@Component({
  selector: 'app-feature',
  templateUrl: './feature.component.html',
  styleUrls: ['./feature.component.scss']
})
export class FeatureComponent implements OnInit {

  // _experience: any = null;
  // get experience() {
  //   return this._experience;
  // }
  // @Input('')set experience2(value) {
  //   this._experience = value;
  // }
  
  // get appLink() {
  //   return this._appLink;
  // }
  // _appLink: any = null;
  // @Input() set appLink2(value) {
  //   this._appLink = value;
  // }
  @Input() experience: any = null;
  @Input() appLink: any = null;
  @Input() configs: any = [];
  @Input() link: any = null;

  

  constructor(
    private homeV4: HomeV4Component,
    private homeService: HomeService
  ) { }

  digitalCertSignFeature: any = null;
  electronicSignFeature: any = null;
  eNegotiationFeature: any = null;
  eKYCFeature: any = null;
  encryptFeature: any = null;
  blockchainFeature: any = null;

  ngOnInit(): void {
    this.getData(this.homeV4.translate.currentLang);
  }

  getData(language) {
    forkJoin([
      this.homeService.getDataByCodeCategory("digitalCertSignFeature", language),
      this.homeService.getDataByCodeCategory("electronicSignFeature", language),
      this.homeService.getDataByCodeCategory("eNegotiationFeature", language),
      this.homeService.getDataByCodeCategory("eKYCFeature", language),
      this.homeService.getDataByCodeCategory("encryptFeature", language),
      this.homeService.getDataByCodeCategory("blockchainFeature", language)
    ]).subscribe((res: any) => {
      this.digitalCertSignFeature = res[0]?.object?.item;
      this.electronicSignFeature = res[1]?.object?.item;
      this.eNegotiationFeature = res[2]?.object?.item;
      this.eKYCFeature = res[3]?.object?.item;
      this.encryptFeature = res[4]?.object?.item;
      this.blockchainFeature = res[5]?.object?.item;
    });
  }
  
  reload(language) {
    this.getData(language)
  }

}
