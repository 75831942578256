export class ContractTemplateItem {
  public id: string;
  public templateName: string;
  public templatePath: string;
  public templateSigns: string;
  public templateType: string;
  public templateFields: string;
  public documents: object;
  public products: object;
  public status: string;
  public dateUpload: string;
  public isUsed: any;
  public fileName: string = "";
  public username: string = "";
  public dateCreate: string = "";
  public fullName: string = "";

  constructor(obj = null) {
    obj = obj  || {};
    this.id = obj.id || '';
    this.templateName = obj.templateName || '';
    this.templatePath = obj.templatePath || '';
    this.templateSigns = obj.templateSigns || '';
    this.templateType = obj.templateType || '';
    this.templateFields = obj.templateFields || '';
    this.documents = obj.documents || '';
    this.products = obj.products || '';
    this.status = obj.status || '';
    this.dateUpload = obj.dateUpload || '';
    this.isUsed = obj.isUsed || false;
    this.fileName = obj.fileName || "";
    this.username = obj.username || "";
    this.dateCreate = obj.dateCreate || "";
    this.fullName = obj.fullName || "";
  }
}
