export class PackageActive {
    packageInfo: PackageDataItem;

    constructor(data = null) {
        data = data || {};
        this.packageInfo = new PackageDataItem(data.packageInfo);
    }
}
export class PackageDataItem {
    packInfoId: string;
    name: string;
    packageName : string;

    constructor(data = null) {
        data = data || {};
        this.packInfoId = data.packInfoId || '';
        this.name = data.name || '';
        this.packageName = data.packageName || '';

    }
}
export class StatusContractDataItem {
    statusId: string;
    name: string;

    constructor(obj = null) {
        obj = obj || {};
        this.statusId = obj.statusId || '';
        this.name = obj.name || '';
    }
}

export class PackageNewDataItem {
    packageId: string;
    packageName : string;

    constructor(data = null) {
        data = data || {};
        this.packageId = data.packageTempId || '';
        this.packageName = data.packageTempName || '';

    }
}