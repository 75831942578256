<app-spinner></app-spinner>
<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card ec-card shadow-none">
                    <div class="card-header">
                        <div class="d-flex">
                            <div class="card-title-path flex-grow-1">
                                <h1 class="m-0 text-dark card-title"><label>{{ 'ContractFlowDetail_title' | translate }}</label></h1>
                            </div>
                            <div class="card-btn-path ml-auto text-right">
                                <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="delete()"><span
                                        class="material-icons mr-1">delete</span>{{ 'btn_delete' | translate }}</button>
                                <button class="btn btn-primary d-inline-flex" (click)="edit()"><span
                                        class="material-icons mr-1">edit</span>{{ 'btn_edit' | translate }}</button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="text-primary">{{ 'ContractFlowAdd_generalInfor' | translate }}</label>
                                <hr class="mt-0 mb-2">
                                <form>
                                    <div class="mb-3">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <label class="font-weigth-bold">{{ 'ContractFlowAdd_type' | translate }}</label>
                                            </div>
                                            <div class="col-md-9">
                                                {{ flowContract.name }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <label class="font-weigth-bold">{{ 'status' | translate }}</label>
                                            </div>
                                            <div class="col-md-9">
                                                <span *ngIf="flowContract.disable == false" class="badge badge-success-new p-2">{{ 'status_running' | translate }}</span>
                                                <span *ngIf="flowContract.disable == true" class="badge badge-danger-new p-2">{{ 'status_stopped' | translate }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <label class="font-weigth-bold">{{ 'ContractFlowAdd_form' | translate }}</label>
                                            </div>
                                            <div class="col-md-9">
                                                <span *ngIf="flowContract.type == 'ELECTRONIC'">{{ 'ContractFlowDetail_electronic_contract' | translate }}</span>
                                                <span *ngIf="flowContract.type == 'PAPER'">{{ 'ContractFlowDetail_paper_contract' | translate }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <label class="font-weigth-bold">{{ "ContractFlowDetail_contract_sample_v1" | translate }}</label>
                                            </div>
                                            <div class="col-md-9">
                                                <a class="text-primary text-decoration-underline cursor-pointer" (click)="showFilePreview()">{{inputFileLabel}}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <label class="font-weigth-bold">Cấu hình số hợp đồng tự động</label>
                                            </div>
                                            <div class="col-md-9">
                                                {{ flowContract.sequenceName }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <label class="font-weigth-bold">{{ 'ContractFlowAdd_nego' | translate }}</label>
                                            </div>
                                            <div class="col-md-9">
                                                <span *ngIf="flowContract.discuss == true">{{ 'ContractFlowDetail_contract_have_discuss' | translate }}</span>
                                                <span *ngIf="flowContract.discuss == false">{{ 'ContractFlowDetail_contract_non_discuss' | translate }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-3">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <label class="font-weigth-bold">{{ 'ContractFlowAdd_desc' | translate }}</label>
                                            </div>
                                            <div class="col-md-9">
                                                {{ flowContract.description }}
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col">
                                        <label class="text-primary">{{ 'ContractFlowAdd_employeePermission' | translate }}</label>
                                        <hr class="mt-0 mb-2">
                                        <div *ngIf="flowContract.discuss === false" class="row my-3">
                                            <div class="col-md-3">
                                                <label class="font-weigth-bold">{{ 'ContractFlowDetail_internaldiscuss_type' | translate }}</label>
                                            </div>
                                            <div class="col-md-9">
                                                <span *ngIf="flowContract.internalDiscussType === internalDiscussTypes.REQUIRE_FLOW">{{ 'ContractFlowAdd_sequentially' | translate }}</span>
                                                <span *ngIf="flowContract.internalDiscussType === internalDiscussTypes.NO_REQUIRE_FLOW">{{ 'ContractFlowAdd_nonSequentially' | translate }}</span>
                                                <span *ngIf="flowContract.internalDiscussType === internalDiscussTypes.NO_REQUIRE">{{ 'ContractFlowAdd_nonSequentiallyEx' | translate }}</span>
                                            </div>
                                        </div>
                                        <table *ngIf="showTableInternalDiscuss" class="table table-bordered">
                                            <thead class="bg-tb-header">
                                                <tr>
                                                    <th>{{ 'ContractFlowAdd_stt' | translate }}</th>
                                                    <th>{{ 'ContractFlowAdd_party' | translate }}</th>
                                                    <th>{{ 'ContractFlowAdd_personel' | translate }}</th>
                                                    <th *ngIf="flowContract.discuss === false">{{ 'ContractFlowAdd_appraisalOrder' | translate }}</th>
                                                    <th>{{ 'ContractFlowAdd_action' | translate }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of internalDiscuss; let i = index">
                                                    <td>{{ i + 1 }}</td>
                                                    <td>{{ item.partyName }}</td>
                                                    <td>{{item.fullName}}</td>
                                                    <td *ngIf="flowContract.discuss === false">
                                                        {{ item.sequence }}
                                                    </td>
                                                    <td>
                                                        <ul>
                                                            <li *ngFor="let x of item.permissions">
                                                                <span *ngIf="x === 'INTERNAL_DISCUSSABLE'">{{ 'ContractFlowAdd_joinDiscuss' | translate }}</span>
                                                                <span *ngIf="x === 'INTERNAL_ACCEPTABLE'">{{ 'ContractFlowAdd_acceptDenyConfirm' | translate }}</span>
                                                                <span *ngIf="x === 'INTERNAL_EDITABLE'">{{ 'ContractFlowAdd_addRemoveEmployee' | translate }}</span>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                
                                <div *ngIf="flowContract.discuss === true"  class="row">
                                    <div class="col">
                                        <label class="text-primary">{{ 'ContractFlowAdd_clc_internal_employee_join_discuss' | translate }}</label>
                                        <hr class="mt-0 mb-2">
                                        <table class="table table-bordered">
                                            <thead class="bg-tb-header">
                                                <tr>
                                                    <th>{{ 'ContractFlowAdd_stt' | translate }}</th>
                                                    <th>{{ 'ContractFlowAdd_party' | translate }}</th>
                                                    <th>{{ 'ContractFlowAdd_personel' | translate }}</th>
                                                    <th>{{ 'ContractFlowAdd_action' | translate }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of partnerDiscuss; let i = index">
                                                    <td>{{ i + 1 }}</td>
                                                    <td>{{ item.partyName }}</td>
                                                    <td>{{item.fullName}}</td>
                                                    <td>
                                                        <ul>
                                                            <li *ngFor="let x of item.permissions">
                                                                <span *ngIf="x === 'PARTNER_DISCUSSABLE'">{{ 'ContractFlowAdd_joinDiscuss' | translate }}</span>
                                                                <span *ngIf="x === 'PARTNER_ACCEPTABLE'">{{ 'ContractFlowAdd_acceptDenyConfirm' | translate }}</span>
                                                                <span *ngIf="x === 'PARTNER_EDITABLE'">{{ 'ContractFlowAdd_addRemoveEmployee' | translate }}</span>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col">
                                        <label class="text-primary">{{ 'ContractFlowAdd_clc_internal_employee_join_sign' | translate }}</label>
                                        <hr class="mt-0 mb-2">
                                        <div class="row my-3">
                                            <div class="col-md-3">
                                                <label class="font-weigth-bold">{{ 'ContractFlowDetail_draft_approval_sign_type' | translate }}</label>
                                            </div>
                                            <div class="col-md-9">
                                                <span *ngIf="flowContract.signFlowType === signFlowTypes.REQUIRE_FLOW_STAMP">{{ 'ContractFlowAdd_required_flow_and_stamp' | translate }}</span>
                                                <span *ngIf="flowContract.signFlowType === signFlowTypes.REQUIRE_FLOW">{{ 'ContractFlowAdd_required_flow' | translate }}</span>
                                                <span *ngIf="flowContract.signFlowType === signFlowTypes.NO_REQUIRE">{{ 'ContractFlowAdd_no_required_flow' | translate }}</span>
                                            </div>
                                        </div>
                                        <table class="table table-bordered">
                                            <thead class="bg-tb-header">
                                                <tr>
                                                    <th>{{ 'ContractFlowAdd_stt' | translate }}</th>
                                                    <th>{{ 'ContractFlowAdd_clc_tb_sign_type' | translate }}</th>
                                                    <th>{{ 'ContractFlowAdd_party' | translate }}</th>
                                                    <th>{{ 'ContractFlowAdd_personel' | translate }}</th>
                                                    <th>{{ 'ContractFlowAdd_clc_tb_sign_th' | translate }}</th>
                                                    <th>{{ 'ContractFlowAdd_clc_tb_progress_time' | translate }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let item of signFlow; let i = index">
                                                    <td>{{ i + 1 }}</td>
                                                    <td>
                                                        {{ item.signTypeLabel | translate }}
                                                    </td>
                                                    <td>{{ item.partyName }}</td>
                                                    <td>{{item.fullName}}</td>
                                                    <td>{{ item.sequence }}</td>
                                                    <td>{{ item.limitDate }} {{ 'ContractFlowAdd_day' | translate }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>
<app-preview-modal *ngIf="showFile" [file]="pdfFile" [name]="inputFileLabel" [useDownloadEvent]="true" (close)="onClosePreview($event)" (download)="downloadStockFile($event)"></app-preview-modal>