export class HuyenItem {
    huyenId: number;
    maHuyen: string;
    tenHuyen: string;
    tinhId: number;

    constructor(obj = null) {
        obj = obj || {};
        this.huyenId = obj.huyenId;
        this.maHuyen = obj.maHuyen  || '';
        this.tenHuyen = obj.tenHuyen || '';
        this.tinhId = obj.tinhId;
    }
}