import { Directive, HostListener, Output , EventEmitter} from '@angular/core';
// import { EventEmitter } from 'events';

@Directive({
  selector: '[appDetectChangeValue]'
})
export class DetectChangeValueDirective {
  @Output('changed') changed: EventEmitter<any> = new EventEmitter();

  constructor() { }

  @HostListener('input') onInput($event) {
    console.log('on input');
    this.changed.emit($event);
  }
}
