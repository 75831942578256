<input #output id="output" class="" value="" type="hidden">
<input #filebase id="fileBase" class="" type="hidden">
<input #filename id="fileName" class="" value="{{ fileName }}" type="hidden">
<div class="wrapper">
    <!-- <app-mini-spinner *ngIf="loadingService.isVisible() | async"></app-mini-spinner> -->
    <div class="preview-overlay">

        <div class="preview-container">

            <div class="preview-body">
                <div class="pv ct">
                    <div class="pv-body">
                        <div class="ctpv-container">
                            <div class="ctpv-content">
                                <div class="ct-container">
                                    <div class="ct-thumb-page">
                                        <div class="ct-vs ct-enable-scroll">
                                            <ol id="thumbnail-page" class="ct-vs-list mt-3">
                                            </ol>
                                        </div>
                                    </div>
                                    <div id="ppi"
                                        style="height: 1in; width: 1in; position: absolute; top: -100%; left: -100%;">
                                    </div>
                                    <div class="ct-content">
                                        <div id="parentView" class="ct-doc-loaded ct-enable-scroll">

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="ct ctpv-tool-bar">
                            <div class="ctpv-tool-sidebar">
                                <div class="ctpv-tabbar">
                                    <div class="ctpv-main">
                                        <button (click)="selectTab('INFO')" class="ctpv-btn"
                                            [class.active]="activeTab == 'INFO'"> <span
                                                class="material-icons-outlined">info</span></button>
                                        <button (click)="selectTab('SIGN')" class="ctpv-btn"
                                            [class.active]="activeTab == 'SIGN'"> <span
                                                class="material-icons">gesture</span></button>
                                    </div>
                                </div>
                                <div class="ctpv-footer">

                                </div>
                            </div>
                            <div class="ctpv-tool-content">
                                <div class="ctpv-content-wrapper px-3">
                                    <div id="tab-info" class="ctpv-scroll-content"
                                        [class.d-none]="!(activeTab === 'INFO')">
                                        
                                        <div class="w-100 d-flex mt-3">
                                            <h5 class=""><label>Thông tin giấy phép kinh doanh</label></h5>
                                            <button [disabled]="!hasSignature" (click)="onNext()" class="btn btn-primary d-inline-flex align-items-center ml-auto"><span
                                                class="material-icons mr-2">save</span> Lưu</button>
                                        </div>

                                        <div class="row mt-3">
                                            <div class="col-md-12">
                                                <form [formGroup]="fileForm">
                                                    <div class="form-group">
                                                        <label>File giấy phép kinh doanh (định dạnh .docx hoặc .pdf) <span class="text-danger">*</span>:</label>
                                                        <div class="input-group mb-1">
                                                            <div class="custom-file custom-file-icon">
                                                                <span class="icon-file material-icons">attach_file</span>
                                                                <label class="custom-file-label"
                                                                    for="inputGroupFile01">{{ inputFileLabel }}</label>
                                                                <input formControlName="file"
                                                                    (input)="handleFileInput($event)" type="file"
                                                                    accept=".pdf,.docx" class="custom-file-input"
                                                                    id="inputGroupFile01"
                                                                    aria-describedby="inputGroupFileAddon01">
                                                            </div>
                                                        </div>
                                                        <app-show-validate-errors [isSubmit]="selectedFile"
                                                            [errorMessages]="validMsgGPKD.file"
                                                            [control]="fileForm.controls.file" [detail]="{ name: 'file' }">
                                                        </app-show-validate-errors>
                                                        <!-- <p class="text-muted">File đính kèm phải có định dạng .docx hoặc
                                                            .pdf</p> -->
                                                    </div>
                                                </form>
                                            </div>
                                        </div>


                                    </div>

                                    <div id="tab-sign" class="ctpv-scroll-content"
                                        [class.d-none]="!(activeTab === 'SIGN')">
                                        <div id="action-SIGN" class="">
                                            <div class="w-100 d-flex my-3">
                                                <h5 class=""><label>Ký số giấy phép kinh doanh</label></h5>
                                                <button [disabled]="!hasSignature" (click)="onNext()" class="btn btn-primary d-inline-flex align-items-center ml-auto"><span
                                                    class="material-icons mr-2">save</span> Lưu</button>
                                            </div>

                                            <p>Vui lòng thực hiện ký số lên GPKD.</p>

                                            <div class="row">
                                                <div class="col-12 d-flex-block">
                                                    <div class="w-100 d-flex">
                                                        <button (click)="selectWayOfSign('USB_TOKEN')"
                                                            [class.btn-outline-primary]="selectedWayOfSign !== 'USB_TOKEN'"
                                                            [class.btn-primary]="selectedWayOfSign === 'USB_TOKEN'"
                                                            class="btn btn-outline-primary d-flex align-items-center mr-1"><span
                                                                class="material-icons mr-1">usb</span>Ký số
                                                            USB-Token</button>
                                                        <button disabled (click)="selectWayOfSign('SIGN_SERVER')"
                                                            [class.btn-outline-primary]="selectedWayOfSign !== 'SIGN_SERVER'"
                                                            [class.btn-primary]="selectedWayOfSign === 'SIGN_SERVER'"
                                                            class="btn btn-outline-primary d-flex align-items-center mr-1"><span
                                                                class="material-icons mr-1">gesture</span>Ký số
                                                            sign-server</button>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="content-tab">
                                                <div id="usb-token" [class.d-none]="selectedWayOfSign !== 'USB_TOKEN'">
                                                    <!--- USB TOKEN -->
                                                </div>

                                                <div id="sign-server"
                                                    [class.d-none]="selectedWayOfSign !== 'SIGN_SERVER'">
                                                    <div class="mt-3">
                                                        <label class="text-primary">Danh sách chữ ký số:</label>
                                                        <hr class="mb-3">
                                                        <table id="list-signbox"
                                                            class="list-signature table table-sm table-bordered header-freeze">
                                                            <thead>
                                                                <tr>
                                                                    <th>STT</th>
                                                                    <th class="th_sig_rectangle">Thông số toạ độ</th>
                                                                    <th>Trang hiển thị chữ ký</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                            </tbody>
                                                        </table>
                                                        <button role="button"
                                                            class="btn d-flex align-items-center text-primary px-0"
                                                            (click)="addSignBox()">
                                                            <span class="material-icons">add</span>Thêm chữ
                                                            ký</button>
                                                    </div>

                                                    <div class="row mt-3">
                                                        <form [formGroup]="formSign" class="col-md-12 row">
                                                            <div class="col-xl-6">
                                                                <div class="infor-box-header d-flex">
                                                                    <label class="text-primary flex-grow-1 mb-0">Nội
                                                                        dung mô
                                                                        tả chữ
                                                                        ký số</label>
                                                                </div>
                                                                <hr>
                                                                <div class="row">
                                                                    <div class="col-xl-12">
                                                                        <div
                                                                            class="ccheck-primary w-100 d-inline-block">
                                                                            <input (change)="changedDateSign($event)"
                                                                                formControlName="signDate"
                                                                                type="checkbox" id="signDate">
                                                                            <label for="signDate" class="w-100">
                                                                                Thời gian ký
                                                                            </label>
                                                                        </div>
                                                                        <div
                                                                            class="ccheck-primary w-100 d-inline-block">
                                                                            <input (change)="changedSignBy($event)"
                                                                                formControlName="signBy" type="checkbox"
                                                                                id="signBy">
                                                                            <label for="signBy" class="w-100">
                                                                                Ký bởi
                                                                            </label>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                                <div class="row mt-3">
                                                                    <div class="col-xl-12 align-items-end d-flex">
                                                                        <div class="form-group w-100">
                                                                            <label>Cỡ chữ:</label>
                                                                            <input formControlName="fontSize"
                                                                                (input)="inputFontSize($event)"
                                                                                type="text" class="form-control"
                                                                                placeholder="Nhập cỡ chữ">
                                                                            <app-show-validate-errors
                                                                                [isSubmit]="isSubmit"
                                                                                [errorMessages]="validMsg.fontSize"
                                                                                [control]="formSign.controls.fontSize"
                                                                                [detail]="{ name: 'fontSize' }">
                                                                            </app-show-validate-errors>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div class="infor-box-header d-flex mt-3">
                                                                    <label class="text-primary flex-grow-1 mb-0">Hình
                                                                        thức hiển thị chữ ký số</label>
                                                                </div>
                                                                <hr>
                                                                <div class="row">
                                                                    <div class="col-xl-12">
                                                                        <label for="signType">Chọn hình thức hiển
                                                                            thị:</label>
                                                                        <select (change)="selectStyleSignBox($event)"
                                                                            #visibleType name="visibleType"
                                                                            id="visibleType" class="form-control"
                                                                            formControlName="visibleType">
                                                                            <option value="" disabled>Chọn hình thức
                                                                                hiển
                                                                                thị
                                                                            </option>
                                                                            <option *ngFor="let opt of hinhThucHienThi"
                                                                                value="{{ opt.value }}">
                                                                                {{ opt.name }}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-xl-6">
                                                                <div class="infor-box-header">
                                                                    <label class="text-primary flex-grow-1 mb-0">Hình
                                                                        ảnh
                                                                        chữ ký
                                                                        số</label>
                                                                </div>
                                                                <hr>
                                                                <div class="row">
                                                                    <div class="col-md-12">
                                                                        <div class="form-group">
                                                                            <label>File đính kèm:</label>
                                                                            <div class="input-group mb-1">
                                                                                <div
                                                                                    class="custom-file custom-file-icon">
                                                                                    <span
                                                                                        class="icon-file material-icons">attach_file</span>
                                                                                    <label class="custom-file-label"
                                                                                        for="inputFileImage">{{ inputFileImageLabel }}</label>
                                                                                    <input formControlName="imageFile"
                                                                                        (input)="handleFileImageInput($event)"
                                                                                        type="file" accept="image/*"
                                                                                        class="custom-file-input"
                                                                                        id="inputFileImage"
                                                                                        aria-describedby="inputGroupFileAddon01">
                                                                                </div>
                                                                            </div>
                                                                            <app-show-validate-errors
                                                                                [isSubmit]="isSubmit"
                                                                                [errorMessages]="validMsg.imageFile"
                                                                                [control]="formSign.controls.imageFile"
                                                                                [detail]="{ name: 'imageFile' }">
                                                                            </app-show-validate-errors>
                                                                        </div>
                                                                        <img *ngIf="imgContent" class="w-100"
                                                                            [src]="imgContent">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>