<div class="container-fluid">
    <div class="row">
        <div class="col-md-4">
            <div class="card shadow-none">
                <div class="card-body pb-0">
                    <div class="row pt-2 pb-2">
                        <div class="col-md-auto">
                            <label class="text-lg">{{ 'ApprovedConsumerPartner_title' | translate }}</label>
                        </div>

                        <div class="input-group mt-3">
                            <form [formGroup]="searchForm" class="input-group"
                                (ngSubmit)="searchData(searchForm.value.searchInput)">
                                <div class="input-group-prepend">
                                    <button class="btn btn-primary d-inline-flex pr-2 pl-2" type="submit">
                                        <span class="material-icons">search</span>
                                    </button>
                                </div>
                                <input type="text" class="form-control" formControlName="searchInput" id="searchInput"
                                    placeholder="{{ 'WaitConsumerPartner_search_placeholder' | translate }}" aria-label=""
                                    aria-describedby="basic-addon1" />
                            </form>
                        </div>
                    </div>
                </div>

                <div class="position-relative">
                    <ng-template #noData>
                        <app-empty-block></app-empty-block>
                    </ng-template>

                    <div>
                        <div class="user-group-item content-list-item px-0 pt-2">
                            <div *ngIf="partners.length > 0; else noData" class="content-list fade show">
                                <div *ngFor="let item of partners" (click)="selectPartner(item.partnerVerificationId)"
                                    class="user-group-item content-list-item px-4 pt-2"
                                    [class.active]="item.partnerVerificationId === partner.partnerVerificationId">
                                    <div class="border-bottom d-flex">
                                        <span class="material-icons icon-item pr-3">group</span>
                                        <div class="flex-grow-1">
                                            <label class="title-item">{{ item.ten !== '' ? item.ten : item.email
                                                }}</label>
                                            <p class="text-secondary">
                                                {{ 'WaitConsumerPartner_item' | translate }}: {{ item.cmnd }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="totalElement > maxSize" class="pagination justify-content-end pages">
                <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="5" [rotate]="true"
                    [ellipses]="false" [pageSize]="maxSize" (pageChange)="loadPage($event)" [boundaryLinks]="true"
                    aria-label="Default pagination">
                    <ng-template ngbPaginationFirst>{{ 'text_first' | translate }}</ng-template>
                    <ng-template ngbPaginationLast>{{ 'text_last' | translate }}</ng-template>
                    <ng-template ngbPaginationPrevious>&laquo;</ng-template>
                    <ng-template ngbPaginationNext>&raquo;</ng-template>
                    <ng-template ngbPaginationNumber let-page>{{ page }}
                    </ng-template>
                </ngb-pagination>
            </div>
        </div>
        <div class="col-md-8">
            <div *ngIf="partnerVerificationId" class="card shadow-none">
                <div class="card-body position-relative">
                    <div class="row pt-2 pb-0">
                        <div class="col-md-auto align-self-end">
                            <label class="text-lg">{{ 'WaitConsumerPartner_partner_info' | translate }}</label>
                        </div>
                        <div *ngIf="partner.partnerVerificationId" class="col-md-auto ml-auto">
                        </div>
                    </div>
                    <hr />
                    <!-- <label class="text-primary">{{ 'WaitConsumerPartner_info' | translate }}</label>
                    <hr class="mt-0"> -->
                    <div class="row">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-4">
                                    <label>{{ 'WaitConsumerPartner_full_name' | translate}}</label>
                                </div>
                                <div class="col-md-8">
                                    {{ partner.ten }}
                                </div>
                            </div>

                            <!-- CMND -->
                            <div *ngIf="partner.loaiGtId == '0' && !partner.gioiTinhId">
                                <div  class="row">
                                    <div class="col-md-4">
                                        <label>{{ 'WaitConsumerPartner_id_card' | translate}}</label>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partner.cmnd }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>{{ 'WaitConsumerPartner_birth' | translate}}</label>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partner.ngaySinh | date: 'dd/MM/yyy' }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>{{ 'WaitConsumerPartner_place_register' | translate}}</label>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partner.dkhktt }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>{{ 'WaitConsumerPartner_date_range' | translate}}</label>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partner.ngayCap | date: 'dd/MM/yyy' }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>{{ 'WaitConsumerPartner_issue' | translate}}</label>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partner.noiCap }}
                                    </div>
                                </div>
                            </div>

                            <!-- CCCD -->
                            <div *ngIf="partner.loaiGtId == '0' && partner.gioiTinhId">
                                <div  class="row">
                                    <div class="col-md-4">
                                        <label>{{ 'WaitConsumerPartner_id_ccdc' | translate}}</label>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partner.cmnd }}
                                    </div>
                                </div>
                                <!-- <div class="row">
                                    <div class="col-md-4">
                                        <label>{{ 'WaitConsumerPartner_gender' | translate }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        <span *ngIf="partner.gioiTinhId == 1">{{ 'female' | translate }}</span>
                                        <span *ngIf="partner.gioiTinhId == 2">{{ 'male' | translate }}</span>
                                    </div>
                                </div> -->
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>{{ 'WaitConsumerPartner_birth' | translate}}</label>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partner.ngaySinh | date: 'dd/MM/yyy' }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>{{ 'WaitConsumerPartner_place_register' | translate }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partner.dkhktt }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>{{ 'WaitConsumerPartner_date_range' | translate }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partner.ngayCap | date: 'dd/MM/yyy' }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>{{ 'WaitConsumerPartner_issue' | translate }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partner.noiCap }}
                                    </div>
                                </div>
                                
                            </div>

                            <!-- PASSPORT -->
                            <div *ngIf="partner.loaiGtId == '1'">
                                <div  class="row">
                                    <div class="col-md-4">
                                        <label>{{ 'WaitConsumerPartner_id_passport' | translate }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partner.cmnd }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>{{ 'WaitConsumerPartner_birth' | translate}}</label>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partner.ngaySinh | date: 'dd/MM/yyy' }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>{{ 'WaitConsumerPartner_place_of_birth' | translate }}Nơi sinh</label>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partner.noiSinh }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>{{ 'WaitConsumerPartner_gender' | translate }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        <span *ngIf="partner.gioiTinhId == 1">{{ 'female' | translate }}</span>
                                        <span *ngIf="partner.gioiTinhId == 2">{{ 'male' | translate }}</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>{{ 'WaitConsumerPartner_date_range' | translate }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partner.ngayCap | date: 'dd/MM/yyy' }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>{{ 'WaitConsumerPartner_issue' | translate }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partner.noiCap }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label>{{ 'WaitConsumerPartner_expired_date' | translate }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partner.expiryDate | date: 'dd/MM/yyy' }}
                                    </div>
                                </div>
                            </div>
                            
                            <div class="row">
                                <div class="col-md-4">
                                    <label>{{ 'WaitConsumerPartner_phone_number' | translate }}</label>
                                </div>
                                <div class="col-md-8">
                                    {{ partner.sdt }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <label>{{ 'WaitConsumerPartner_email' | translate }}</label>
                                </div>
                                <div class="col-md-8">
                                    {{ partner.email }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <label>{{ 'WaitConsumerPartner_account_name' | translate }}</label>
                                </div>
                                <div class="col-md-8">
                                    {{ partner.username }}
                                </div>
                            </div>

                            
                            

                        </div>

                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-6">
                                    <div *ngIf="imgFront">
                                        <label>{{ 'WaitConsumerPartner_front_photo' | translate }}</label>
                                        <div>
                                            <img class="img-cmnd" [src]="imgFront" />
                                        </div>
                                    </div>
                                    <div *ngIf="imgFace">
                                        <label>{{ 'WaitConsumerPartner_face' | translate }}</label>
                                        <div>
                                            <img class="img-cmnd" [src]="imgFace" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div *ngIf="imgBack">
                                        <label>{{ 'WaitConsumerPartner_back_photo' | translate }}</label>
                                        <div>
                                            <img class="img-cmnd" [src]="imgBack" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <label class="text-primary mt-3">{{ 'ApprovedPartyPartner_verify_info' | translate}}</label>
                    <hr class="mt-0">
                    <div class="row">
                        <div class="col-md-4">
                            <label>{{'ApprovedConsumerPartner_approver' | translate }}</label>
                        </div>
                        <div class="col-md-8">
                            {{ partner?.verificationUserTen }}
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <label>{{'ApprovedConsumerPartner_approval_date' | translate }}</label>
                        </div>
                        <div class="col-md-8">
                            {{ partner?.verificationDate | date: 'dd/MM/yyyy' }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>