<!-- Navbar -->
<nav class="main-header navbar navbar-expand navbar-primary navbar-dark">
  <!-- Left navbar links -->
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link" (click)="toggleMenuSidebar.emit()"> <i class="fas fa-bars"></i></a>
    </li>
    <!-- <li class="nav-item d-none d-sm-inline-block">
      <a [routerLink]="['/']" class="nav-link">Home</a>
    </li>
    <li class="nav-item d-none d-sm-inline-block">
      <a [routerLink]="['/blank']" class="nav-link">Contact</a>
    </li> -->
  </ul>

  <!-- SEARCH FORM -->
  <form class="form-inline ml-3" [formGroup]="searchForm">
  </form>

  <!-- Right navbar links -->
  <ul class="navbar-nav ml-auto">
    <!-- Messages Dropdown Menu -->
    <!-- <app-messages-dropdown-menu></app-messages-dropdown-menu> -->
    <!-- Notifications Dropdown Menu -->
    <!-- <app-notifications-dropdown-menu></app-notifications-dropdown-menu> -->
    <li class="nav-item">
      <img class="img-circle" src="assets/img/icon-avatar-default.png" width="30" height="30"
        style="margin-top: 5px;" />
    </li>
    <li class="nav-item dropdown">
      <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false">
        {{ getUsername }}
      </a>
      <div *ngIf="path === 'portal'" class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
        <a class="dropdown-item" [routerLink]="['/portal/profile']"
         >Thông tin tài khoản</a>
        <a class="dropdown-item" [routerLink]="['/portal/change-password']">Thay đổi mật khẩu</a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" class="btn" (click)="logout()">Đăng xuất</a>
      </div>

      <div *ngIf="path === 'app'" class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
        <a class="dropdown-item d-flex" [routerLink]="['/app/console/profile-manager']"><em class="material-icons-outlined mr-3">perm_identity</em>Thông tin tài khoản</a>
        <!-- <a class="dropdown-item d-flex" [routerLink]="['/portal/change-password']"><em class="material-icons-outlined mr-3">https</em>Thay đổi mật khẩu</a> -->
        <div class="dropdown-divider"></div>
        <a class="dropdown-item cursor-pointer d-flex" (click)="logout()"><em class="material-icons mr-3">input</em>Đăng xuất</a>
      </div>
    </li>
    <!-- <li class="nav-item">
      <a
        class="nav-link"
        (click)="logout()"
      >
        <i class="fas fa-power-off"></i>
      </a>
    </li> -->
  </ul>
</nav>
<!-- /.navbar -->