<app-spinner></app-spinner>
<div class="bg-white pb-3">
    <section class="p-3">
        <div class="row">
            <div class="col-lg-3 col-md-5">
                <form [formGroup]="formSearch" (ngSubmit)="actSearch(formSearch.value.search)"
                    class="input-group border rounded mb-3">
                    <div class="input-group-prepend border-0">
                        <button class="btn d-inline-flex pr-2 pl-2" type="submit"><span
                                class="material-icons">search</span></button>
                    </div>
                    <input (input)="eventInputSearch($event)" type="text" class="form-control border-0"
                        formControlName="search" placeholder="Tìm kiếm..." aria-label=""
                        aria-describedby="basic-addon1">
                </form>
            </div>
            <div class="col-md-auto ml-auto">
                <div class="btn-group">
                    <button class="btn btn-outline-primary dropdown-toggle d-inline-flex align-items-center mr-3"
                        data-toggle="dropdown" id="configfilter"><span class="material-icons mr-1">tune</span>Bộ
                        lọc</button>
                    <div class="dropdown-menu border-0" aria-labelledby="configfilter">
                        <div *ngFor="let item of listFitler" class="tcheck-primary d-inline-block px-3">
                            <input type="checkbox" id="{{ item.name }}" [checked]="configFilter[item.name]">
                            <label for="{{ item.name }}" class="w-100" [class.checked]="configFilter[item.name]"
                                (click)="actConfigFilter($event, item.name)">
                                {{ item.label }}
                            </label>
                        </div>
                    </div>
                </div>
                <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="cleanFilter()"> Đặt lại</button>
                <button class="btn btn-primary d-inline-flex mr-3" (click)="searchWithFilter()"> Áp dụng</button>
            </div>
        </div>
        <hr class="my-2">
    </section>
    <section class="px-3 my-2">
        <div class="row">
            <!-- <div class="col-md" [class.d-none]="!configFilter.donViQuanLy">
                <div class="form-group">
                    <label for="statusGCS">Đơn vị quản lý</label>
                    <select #ttkdIdPicker name="ttkdIdPicker" id="ttkdIdPicker" (change)="changeTtkdId($event)"
                        class="form-control">
                    </select>
                </div>
            </div>
            <div class="col-md" [class.d-none]="!configFilter.loaiHopDong">
                <div class="form-group">
                    <label for="statusGCS">Loại hợp đồng</label>
                    <select #typeIdPicker name="typeIdPicker" id="typeIdPicker" (change)="changeTypeId($event)"
                        class="form-control">
                    </select>
                </div>
            </div> -->
            <div class="col" [class.d-none]="!configFilter.loaiKhachHang">
                <div class="form-group">
                    <label for="statusGCS">Loại khách hàng</label>
                    <select #customerTypePicker name="customerTypePicker-{{_by}}" id="customerTypePicker-{{_by}}"
                        (change)="changeCustomerType($event)" class="form-control">
                        <option value="" [selected]="customerType === ''">Tất cả</option>
                        <option value="CONSUMER" [selected]="customerType === 'CONSUMER'">Cá nhân</option>
                        <option value="BUSINESS" [selected]="customerType === 'BUSINESS'">Doanh nghiệp</option>
                    </select>
                </div>
            </div>
            <!-- </div> -->
            <!-- <div class="row"> -->
            <div class="col-md" [class.d-none]="!configFilter.tuNgay">
                <div class="form-group">
                    <label for="">Từ ngày</label>
                    <div class="input-group">
                        <input id="fromDateReport-{{_by}}" #fromDatePicker name="fromDatePicker-{{_by}}"
                            (input)="dateInput($event, 'fromDate')" (dateSelect)="changeDate($event, 'from')"
                            class="form-control" placeholder="dd/MM/yyyy" ngbDatepicker #pickDate="ngbDatepicker"
                            [minDate]="{year: 1970, month: 1, day: 1}"
                            [maxDate]="{year: currentDate.getFullYear(), month: currentDate.getMonth()+1, day: currentDate.getDate()}">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary" (click)="pickDate.toggle()" type="button"><em
                                    class="fas fa-calendar-alt"></em></button>
                        </div>
                    </div>
                    <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.fromDate"
                        [control]="f_fromDate" [detail]="{ name: 'fromDate' }"></app-show-validate-errors>
                </div>
            </div>
            <div class="col-md" [class.d-none]="!configFilter.denNgay">
                <div class="form-group">
                    <label for="">Đến ngày</label>
                    <div class="input-group">
                        <input id="toDateReport-{{_by}}" #toDatePicker name="toDatePicker-{{_by}}"
                            (input)="dateInput($event, 'toDate')" (dateSelect)="changeDate($event, 'to')"
                            class="form-control" placeholder="dd/MM/yyyy" ngbDatepicker #pickDate1="ngbDatepicker"
                            [minDate]="{year: 1970, month: 1, day: 1}"
                            [maxDate]="{year: currentDate.getFullYear(), month: currentDate.getMonth()+1, day: currentDate.getDate()}">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary" (click)="pickDate1.toggle()" type="button"><em
                                    class="fas fa-calendar-alt"></em></button>
                        </div>
                    </div>
                    <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.toDate"
                        [control]="f_toDate" [detail]="{ name: 'toDate' }"></app-show-validate-errors>
                </div>
            </div>
            <div class="col-md" [class.d-none]="!configFilter.trangThai">
                <div class="form-group">
                    <label for="status">Trạng thái</label>
                    <select #statusPicker name="statusPicker-{{_by}}" id="statusPicker-{{_by}}" class="form-control"
                        (change)="changeStatus($event)">
                        <option value="" selected>Tất cả</option>
                        <!-- <option value="LC_DRAFT_CREATE">Đã tạo hợp đồng</option> -->
                        <option value="LC_DRAFT_SUBMIT_SIGNED">Chờ ký</option>
                        <option value="LC_CONTRACT_VALID">Có hiệu lực</option>
                        <option value="LC_DRAFT_CANCEL">Đã huỷ</option>
                    </select>
                </div>
            </div>
        </div>
    </section>
    <section>
        <div id="table-scroll" class="table table-scroll">
            <table id="main-table" class="table main-table border">
                <thead>
                    <tr>
                        <th class="table-sticky-col" scope="col">
                            <div class="tcheck-primary m-0">
                                <input type="checkbox" id="select-all-assign">
                                <label for="select-all-assign" class="m-0" (click)="selectAll($event)"
                                    [class.checked]="selectedAll">
                                </label>
                            </div>
                        </th>
                        <th class="table-sticky-col1" scope="col">Tên hợp đồng</th>
                        <th class="table-sticky-col2" scope="col">
                            <div class="d-flex align-items-end">
                                <span class="flex-grow-1 mr-4">Tên khách hàng</span>
                                <div class="dropdown">
                                    <span (click)="openDropdown($event)"
                                        class="material-icons cursor-pointer dropdown-toggle" data-toggle="dropdown"
                                        id="settingTable">settings</span>
                                    <div class="dropdown-menu border-0" aria-labelledby="dropdownMenuButton">
                                        <div *ngFor="let item of listConfigColTable"
                                            class="tcheck-primary d-inline-block px-3">
                                            <input type="checkbox" id="{{ item.name }}"
                                                [checked]="configColTable[item.name]">
                                            <label for="{{ item.name }}" class="w-100"
                                                [class.checked]="configColTable[item.name]"
                                                (click)="actConfigColTableView($event, item.name)">
                                                {{ item.label }}
                                            </label>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </th>
                        <th scope="col" [class.d-none]="!configColTable.status">Trạng thái</th>
                        <th scope="col" [class.d-none]="!configColTable.donViQuanLy">Đơn vị quản lý</th>
                        <th scope="col" [class.d-none]="!configColTable.ngayChinhSua">Ngày chỉnh sửa</th>
                        <th scope="col" [class.d-none]="!configColTable.ngayKhoiTao">Ngày khởi tạo</th>
                        <th scope="col" [class.d-none]="!configColTable.nguoiPheDuyet">Email</th>
                        <th scope="col" [class.d-none]="!configColTable.nguoiKhoiTao">Người khởi tạo</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of listContract">
                        <td class="table-sticky-col">
                            <div class="tcheck-primary m-0">
                                <input type="checkbox" id="contract-{{ item.contractId }}">
                                <label for="contract-{{ item.contractId }}"
                                    id="label-for-contract-{{ item.contractId }}" class="m-0"
                                    (click)="selectRow($event, item.contractId)">
                                </label>
                            </div>
                        </td>
                        <td class="table-sticky-col1">
                            <div>
                                <p>{{ item.title }}</p>
                                <!-- <span class="badge badge-new-success">áđâsdá</span> -->
                            </div>
                        </td>
                        <td class="table-sticky-col2">
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <p>{{ item.doiTac !== '' ? item.doiTac : item.email }}</p>
                                    <span class="badge badge-light-new px-3 py-2">{{ item.loaiKhachHang === 'CONSUMER' ? 'Cá nhân' : 'Doanh nghiệp' }}</span>
                                    
                                </div>
                                <div class="dropdown">
                                    <span class="material-icons cursor-pointer dropdown-toggle" data-toggle="dropdown"
                                        id="settingTable" (click)="openDropdown($event)">more_vert</span>
                                    <div class="dropdown-menu border-0" aria-labelledby="dropdownMenuButton">
                                        <a (click)="goToDetailContract(item.contractId)"
                                            class="dropdown-item cursor-pointer d-flex"><span
                                                class="material-icons-outlined mr-3">description</span>Thông tin chi
                                            tiết</a>
                                        <a *ngIf="item.readyforsign === 1 && item.currentStage !== 'LC_DRAFT_CANCEL' && item.currentStage !== 'LC_CONTRACT_VALID'"
                                            (click)="goToSign(item.contractId)" class="dropdown-item cursor-pointer d-flex"><span
                                                class="material-icons-outlined mr-3">gesture</span>Ký hợp đồng</a>
                                        <a *ngIf="item.readyforsign === 1 && item.currentStage !== 'LC_DRAFT_CANCEL' && item.currentStage !== 'LC_CONTRACT_VALID'"
                                            (click)="openModalCancel(contentCancel, item.contractId)" class="dropdown-item cursor-pointer d-flex"><span
                                                class="material-icons-outlined mr-3">cancel</span>Hủy hợp đồng</a>
                                        <a (click)="getFileContract(item.contractId, item.currentStage)"
                                            class="dropdown-item cursor-pointer d-flex"><span
                                                class="material-icons-outlined mr-3">download</span>Tải hợp đồng</a>
                                    </div>
                                </div>
                            </div>

                        </td>
                        <td [class.d-none]="!configColTable.status">
                            <span
                                class="badge {{ currentStage[item.currentStage].style || '' }} p-2">{{ currentStage[item.currentStage].label || '' }}</span>
                        </td>
                        <td [class.d-none]="!configColTable.donViQuanLy">{{ item.donViQuanLy }}</td>
                        <td [class.d-none]="!configColTable.ngayChinhSua">{{ item.modified | date : 'dd/MM/yyy' }}</td>
                        <td [class.d-none]="!configColTable.ngayKhoiTao">{{ item.created | date : 'dd/MM/yyy' }}</td>
                        <td [class.d-none]="!configColTable.nguoiPheDuyet">{{ item.email }}</td>
                        <td [class.d-none]="!configColTable.nguoiKhoiTao"></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </section>
</div>

<div class="d-flex justify-content-end mt-3">
    <span *ngIf="totalElement == 0" class="flex-grow-1 ml-1">Không tìm thấy kết quả</span>
    <!-- <span *ngIf="totalElement > 0" class="flex-grow-1 ml-1">Hiển thị 1 đến {{listContrat.length}} trong tổng số
        {{totalElement}} kết quả</span> -->
    <div *ngIf="totalElement > maxSize" class="pagination pages aas-pagination">
        <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="false"
            [pageSize]="maxSize" (pageChange)="loadPage($event)" [boundaryLinks]="true" aria-label="Default pagination">
            <ng-template ngbPaginationFirst>Đầu</ng-template>
            <ng-template ngbPaginationLast>Cuối</ng-template>
            <ng-template ngbPaginationPrevious>&laquo;</ng-template>
            <ng-template ngbPaginationNext>&raquo;</ng-template>
            <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
    </div>
</div>

<ng-template #contentCancel let-c="close" let-d="dismiss">
    <div class="modal-header border-0 pb-0 mb-0">
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span class="material-icons">
                cancel
            </span>
        </button>
    </div>
    <div class="modal-body py-0">
        <h5 class="modal-title w-100 text-center mb-3" id="modal-basic-title">Huỷ hợp đồng</h5>
        <form [formGroup]="formCancel">
            <div class="form-group">
                <label>Lý do huỷ: </label>
                <textarea formControlName="reason" class="form-control" rows="5" placeholder="Vui lòng nhập lý do huỷ hợp đồng"></textarea>
                <app-show-validate-errors [isSubmit]="isCancelSubmit" [errorMessages]="validMsgCancel.reason"
                    [control]="formCancel.controls.reason" [detail]="{ name: 'reason' }"></app-show-validate-errors>
            </div>
        </form>
    </div>
    <div class="modal-footer border-0 d-flex justify-content-center">
        <button type="button" class="btn btn-outline-primary" (click)="c('Close')">Huỷ</button>
        <button type="button" class="btn btn-primary" (click)="submitCancel()">Xác nhận</button>
    </div>
</ng-template>