import { concat, Observable, of, Subject } from "rxjs";
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap, tap } from "rxjs/operators";
import { localize } from "src/app/utils/localize/localize";
import { EmployeeService } from "src/app/utils/services/aas-network/employee/employee.service";
import { PartService } from "src/app/utils/services/aas-network/part/part.service";
import { LoadingService } from "src/app/utils/services/loading.service";
import { SignBox } from "../signbox/sign-box";

export class SignFlow {
    signType: string = "";
    partyId = "";
    partyName = "";
    userId = "";
    fullName = "";
    sequence: any = 0;
    limitDate = "";
    email = "" ;
    delete = true;

    dropListPartData: Observable<any>;
    partsLoading = false;
    partsInput$ = new Subject<string>();
    partKeySearch = "";
    endDataPart = false;
    partPage = 1;
    myLoader: LoadingService;

    dropListEmployeeData : Observable<any>;
    employeesLoading = false;
    employeesInput$ = new Subject<string>();
    employeeKeySearch = "";
    endDataEmployee = false;
    employeePage = 1;

    errorValidate: any = null;
    generalErrorValidate: any = null;

    signForm = [];
    signFormData = [
        { id: 'NO_AUTHEN', text: 'PartyJoin_text_5' },
        { id: 'OTP', text: 'PartyJoin_text_6' },
        { id: 'OTP_EMAIL', text: "PartyJoin_text_7"},
        // { id: 'EKYC', text: 'PartyJoin_text_8' },
        // { id: 'EKYC_EMAIL', text: "PartyJoin_text_9"},
        { id: 'USB_TOKEN', text: 'PartyJoin_text_10' },
        { id: 'SIGN_SERVER', text: 'PartyJoin_text_11' },
        { id: 'SMART_CA', text: 'PartyJoin_text_12' }
    ];
    signFrame: SignBox[] = [];

    constructor(d = null) {
        d = d || {};
        this.signType = d.signType || "";
        this.partyId = d.partyId || d.departmentId || null;
        this.partyName = d.partyName || d.departmentName || "";
        this.userId = d.userId || null;
        this.fullName = d.fullName || "";
        this.sequence = d.sequence || 0;
        this.limitDate = d.limitDate || "";
        this.email = d.email || "";
        this.delete = d.delete !== undefined && d.delete !== null ? d.delete : true;
        this.signType === "APPROVAL" || this.signType === "STAMP" ? this.delete = false : this.delete = true;
        this.signForm = d.signForm || [];
        this.signFrame = [];
        if (d.signFrame && d.signFrame instanceof Array) {
            d.signFrame.forEach((item) => {
                this.signFrame.push(new SignBox(item));
            })
        }

        this.lazyLoadPart();
        this.lazyLoadEmployee();
    }
    
    get signTypeLabel() {
        switch (this.signType) {
            case "DRAFT": 
                return "sign_draft";
                break;
            case "APPROVAL":
                return "sign_approve";
                break;
            case "STAMP":
                return "sign_stamp";
        }
    }

    /**
     * Bat su kien thay doi bo phan
     */
    changedParty(event) {   
        if (event) {
            this.partyId = event.partyId || this.partyId;
            this.partyName = event.partyName || this.partyName;
        }

        this.userId = null;
        this.fullName = "";
        this.lazyLoadEmployee();
    }

    trackByFn(item: any) {
        return item;
    }

    /**
     * Tim kiem realtime droplist bo phan
     */
    lazyLoadPart() {
        this.dropListPartData = concat(
            this.partsInput$.pipe(
                startWith(''),
                debounceTime(400),
                distinctUntilChanged(),
                tap(() => { this.partsLoading = true; LoadingService.instance.disabledLoader(this.partsLoading); }),
                switchMap((term) => {
                    this.partPage = 1;
                    this.endDataPart = false;
                    this.partKeySearch = term;
                    let res = PartService.instance.getPartsV2(1, 10, "", "", "0", term || "").pipe(
                        map((res: any) => {
                            return this.addToDropList(res?.object?.data, this.partyId ? { partyId: this.partyId, partyName: this.partyName } : null)
                        }),
                        catchError(() => of(this.addToDropList([],  this.partyId ? { partyId: this.partyId, partyName: this.partyName } : null))), // empty list on error
                        tap(() => { this.partsLoading = false; LoadingService.instance.disabledLoader(this.partsLoading); })
                    );
                    return res;
                })
            ));
    }

    /**
    * Su kien scroll xuong duoi cung cua droplist
    * @returns 
    */
    onScrollToEndDropListPart() {
        if (this.endDataPart) return;

        var nextPage = this.partPage + 1;
        this.partPage = nextPage;
        this.loadMorePart(this.partPage);
    }

    /**
     * Tai them du lieu droplist Luong hop dong
     * @param page 
     */
    loadMorePart(page) {
        this.partsLoading = true; LoadingService.instance.disabledLoader(this.partsLoading);
        PartService.instance.getPartsV2(page, 10, "", "", "", this.partKeySearch || "").subscribe((res: any) => {
            console.log(res);
            if (res?.object?.data.length <= 0) {
                this.endDataPart = true;
            }

            this.dropListPartData.subscribe((value) => {
                this.dropListPartData = of(value.concat(res?.object?.data || []));
            });

            this.partsLoading = false; LoadingService.instance.disabledLoader(this.partsLoading);
        }, err => {
            this.partsLoading = false; LoadingService.instance.disabledLoader(this.partsLoading);
        })
    }

    addToDropList(data = [] ,param, key = "partyId") {
        key = key || "id";
        if (param === null) return data;
        let index = data.findIndex((item) => {
            return param[key] === item[key];
        });
        if (index >= 0) return data;
        return [...data, param]; 
    }

    setGeneralError(error) {
        if (error === null) { this.generalErrorValidate = null; return; }
        this.generalErrorValidate = { ...this.generalErrorValidate, ...error };
        this.generalErrorValidate && Object.keys(this.generalErrorValidate).length === 0 ? this.generalErrorValidate = null : this.generalErrorValidate;
    }

    hasGeneralError() {
        return this.generalErrorValidate !== null && this.generalErrorValidate !== undefined && this.generalErrorValidate !== {};
    }

    setError(error) {
        if (error === null) { this.errorValidate = null; return; }
        this.errorValidate = { ...this.errorValidate, ...error };
        this.errorValidate && Object.keys(this.errorValidate).length === 0 ? this.errorValidate = null : this.errorValidate;
    }

    hasError(control, error = "") {
        if (error != "") {
            return this.errorValidate && this.errorValidate[control] && this.errorValidate[control][error];
        }
        return this.errorValidate && this.errorValidate[control];
    }

    get inValid() {
        return this.errorValidate !== null && this.errorValidate !== undefined && this.errorValidate !== {};
    }

    get valid() {
        return this.errorValidate == null || this.errorValidate == undefined || this.errorValidate !== {};
    }

    checkValidate(haveUser = false) {
        this.setError(null);
        this.setGeneralError(null);

        if (this.partyId === null || this.partyId === "") {
            this.setError({ partyId: { required: true }});
            this.setGeneralError({ required: true });
        }

        if (haveUser && (this.userId === null || this.userId === "")) {
            this.setError({ userId: { required: true }});
            this.setGeneralError({ required: true });
        }

        if (this.sequence == null || this.sequence === undefined) {
            this.setError({ sequence: { required: true }});
            this.setGeneralError({ required: true });
        } else {
            if (/[^0-9]*/g.test(this.sequence)) {
                let s = Number(this.sequence);
                if (s <= 0) {
                    this.setError({ sequence: { min: true }});
                    this.setGeneralError({ minSequence: true });
                }
            } else {
                this.setError({ sequence: { pattern: true }});
                this.setGeneralError({ patternSequence: true });
            }
        }

        if (this.limitDate == null || this.limitDate === undefined) {
            this.setError({ limitDate: { required: true }});
            this.setGeneralError({ required: true });
        } else {
            if (/[^0-9]*/g.test(this.limitDate)) {
                let s = Number(this.limitDate);
                if (s <= 0) {
                    this.setError({ limitDate: { min: true }});
                    this.setGeneralError({ minLimitDate: true });
                }
            } else {
                this.setError({ limitDate: { pattern: true }});
                this.setGeneralError({ patternLimitDate: true });
            }
        }

        if (this.signForm == null || this.signForm == []) {
            this.setError({ signForm: { required: true } });
            this.setGeneralError({ required: true })
        }
    }

    /**
     * Bat su kien thay doi nhan su
     */
    changedEmployee(event) {
        if (event) {
            this.userId = event.userId || this.userId;
            this.fullName = event.fullName || this.fullName;
        }
    }

    trackByFnEmployee(item: any) {
        return item;
    }

    lazyLoadEmployee() {
        this.dropListEmployeeData = concat(
            this.employeesInput$.pipe(
                startWith(''),
                debounceTime(400),
                distinctUntilChanged(),
                tap(() => { this.employeesLoading = true; LoadingService.instance.disabledLoader(this.employeesLoading); }),
                switchMap((term) => {
                    this.employeePage = 1;
                    this.endDataEmployee = false;
                    this.employeeKeySearch = term;
                    if (this.partyId == null || this.partyId == "") {
                        this.employeesLoading = false; LoadingService.instance.disabledLoader(this.employeesLoading); 
                        return [];
                    }
                    let res = EmployeeService.instance.getListEmployeeInPartV2(this.partyId, 1, 10, "created", "DESC", "0" ,term || "").pipe(
                        map((res: any) => {
                            return this.addToDropList(res?.object?.data, this.userId ? { userId: this.userId, fullName: this.fullName } : null, "userId")
                        }),
                        catchError(() => of(this.addToDropList([], this.userId ? { userId: this.userId, fullName: this.fullName } : null, "userId"))), // empty list on error
                        tap(() => { this.employeesLoading = false; LoadingService.instance.disabledLoader(this.employeesLoading); })
                    );
                    return res;
                })
            ));
    }

    /**
    * Su kien scroll xuong duoi cung cua droplist
    * @returns 
    */
    onScrollToEndDropListEmployee() {
        if (this.endDataEmployee) return;

        var nextPage = this.employeePage + 1;
        this.employeePage = nextPage;
        this.loadMoreEmployee(this.employeePage);
    }

    /**
     * Tai them du lieu droplist Luong hop dong
     * @param page 
     */
    loadMoreEmployee(page) {
        this.employeesLoading = true; LoadingService.instance.disabledLoader(this.employeesLoading);
        EmployeeService.instance.getListEmployeeInPartV2(this.partyId, page, 10, "created", "DESC", "0", this.employeeKeySearch || "").subscribe((res: any) => {
            console.log(res);
            if (res?.object?.data.length <= 0) {
                this.endDataEmployee = true;
            }

            this.dropListEmployeeData.subscribe((value) => {
                this.dropListEmployeeData = of(value.concat(res?.object?.data || []));
            });

            this.employeesLoading = false; LoadingService.instance.disabledLoader(this.employeesLoading);
        }, err => {
            this.employeesLoading = false; LoadingService.instance.disabledLoader(this.employeesLoading);
        })
    }
}