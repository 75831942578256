import {PermissionGroup} from './list-all-permission/permission-group'

export class BusinessPackageItem {
    packInfoId: string;
    name : string;
    description : string;
    price: string;
    packageType: string;
    userType:  string;
    duration: string;
    contractNumber: number;
    status: string;
    roleId: string;
    created: number;
    modified: string;
    listPackageService: PackageService[] = [];
    formContractId: string;
    orderNumber : number;
    capacity: number;
    userAmount : number;
    using: string = "";
    listPackageInfrastructure: InfraInfo[] = [];

    constructor(obj = null) {
        obj = obj || {};
        this.packInfoId = obj.packageInfo?.packInfoId || '';
        this.name = obj.packageInfo?.name || '';
        this.description = obj.packageInfo?.description || '';
        this.price = obj.packageInfo?.price || '';
        this.packageType = obj.packageInfo?.packageType || '';
        this.status = obj.packageInfo?.status || '';
        this.roleId = obj.packageInfo?.roleId || '';
        this.created = obj.packageInfo?.created || '';
        this.modified = obj.packageInfo?.modified || '';
        this.userType = obj.packageInfo?.userType || '';
        this.formContractId = obj.packageInfo?.formContractId || '';
        this.orderNumber = obj.packageInfo?.orderNumber;
        this.capacity = obj.packageInfo?.capacity;
        this.userAmount = obj.packageInfo?.userAmount;
        this.duration = obj.packageInfo?.duration ;
        this.contractNumber = obj.packageInfo?.contractNumber ;

        if (obj.listPackageService && obj.listPackageService instanceof Array) {
            obj.listPackageService.forEach((item) => {
                this.listPackageService.push(new PackageService(item));
            });
        }

        this.using = obj.packageInfo?.using || "";

        if (obj.listPacakgeInfra && obj.listPacakgeInfra instanceof Array) {
            obj.listPacakgeInfra.forEach((item) => {
                this.listPackageInfrastructure.push(new InfraInfo(item));
            });
        }
    }
}

export class PackageService {
    public id: string = "";
    public name: string = "";
    public description: string = "";
    public index: number = -1;
    public selected: boolean = false;
    public assigned: boolean = false;
    
    constructor(obj = null) {
        obj = obj || {};
        this.id = obj.serviceInfoId || "";
        this.name = obj.name || "";
    }
}

export class InfraInfo {
    serviceInfoId: string = "";
    value: string = "";
    name: string = "";
    packInfoId: string = "";

    constructor(obj = null) {
        obj = obj || {};
        this.serviceInfoId = obj.serviceInfoId || "";
        this.value = obj.value || "";
        this.name = obj.name || "";
        this.packInfoId = obj.packInfoId || "";
    }
}
