export class UsbTokenCertItem {
    
    id: string;
    subject: string;
    issuer: string;
    validFrom: string;
    validTo: string;
    serialNumber: string;
    certBase64: string;
    accountEmail: string;
    status: string;
    taxNumber: string;
    personalId: string;
    passport: string;
    isClickShowItem: boolean;

    constructor(obj = null) {
        obj = obj || {};
        this.id = obj.id || '';
        this.subject = obj.subjectCN || '';
        this.issuer = obj.issuerCN || '';
        this.validFrom = obj.notBefore || '';
        this.validTo = obj.notAfter || '';
        this.serialNumber = obj.serial || '';
        this.certBase64 = obj.base64 || '';
        this.accountEmail = obj.accountEmail || '';
        this.status = obj.status || '';
        this.taxNumber = obj.taxNumber || '';
        this.personalId = obj.personalId || '';
        this.passport = obj.passport || '';
        this.isClickShowItem = obj.isClickShowItem || false;
    }
}