<app-spinner></app-spinner>
<div class="landingpage" *ngIf="!isSubmitSDT">
    <div class="register">
        <section class="register-ss1">
            <img src="assets/img/home/v4/Image/Logo eContract_change color.png" alt="" class="img-logo-ipadpro">
            <div class="content col-12 p-0">
                <div class="row swap-col">
                    <div class="col-auto-new p-0">
                        <div class="row">
                            <div class="col-12">
                                <div class="content-left">
                                    <div class="row">
                                        <div class="col-left-auto">
                                            <div class="body">
                                                <span class="logo block">
                                                    MỘT SẢN PHẨM CỦA TẬP ĐOÀN
                                                    <img src="../../../assets/img/home/v4/Image/logo vnpt.png" alt="">
                                                </span>
                                                <p class="title">
                                                    Hợp đồng điện tử<br />
                                                    VNPT eContract
                                                </p>
                                                <span class="dec">
                                                    Cung cấp dịch vụ hợp đồng điện tử cho phép các bên giao kết các loại
                                                    hợp đồng
                                                    bằng phương tiện điện tử.
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-left-auto">
                                            <img src="assets/img/home/v4/Image/Group 177362.png" alt=""
                                                class="d-xl-block imgbg">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-auto-new p-0">
                        <div class="content-right">
                            <div class="row justify-content-center">
                                <div class="col-10 col-md-6 col-xl-9">
                                    <div class="body">
                                        <img src="assets/img/home/v4/Image/LOGO261.png" alt="" class="img-logo">
                                        <div *ngIf="!isSubmitEmail">
                                            <h3 class="title">{{ 'ForgotPassword_title' | translate }}</h3>
                                            <span class="note pb-4">
                                                {{ 'ForgotPassword_h_1' | translate }}
                                            </span>
                                        </div>
                                        <div *ngIf="isSubmitEmail">
                                            <h3 class="title">Đặt  lại mật khẩu</h3>
                                            <span class="note pb-4" style="max-width: 70%">
                                                {{ 'ForgotPassword_h_3' | translate }} 
                                                {{ emailOrPhone.value}}. 
                                                {{ 'ForgotPassword_h_4' | translate }}
                                            </span>

                                            <button (click)="backHome()" type="button" class="btn_reg mt-1">Quay lại  trang chủ</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-center" *ngIf="!isSubmitEmail">
                                <div class="col-10 col-md-6 col-xl-9">

                                    <div class="login-form">
                                        <form [formGroup]="inputEmailForm">
                                            <div class="form-group">
                                                <input appTrimValue appLowercaseValue class="form-control" formControlName="emailOrPhone"
                                                    placeholder="{{ 'ForgotPassword_h_2' | translate }}" type="text">
                                                <app-show-validate-errors isSubmit="false"
                                                    [errorMessages]="ServiceMsg.email" [control]="emailOrPhone"
                                                    [detail]="{ name: 'email' }"></app-show-validate-errors>
                                            </div>

                                            <p class="error text-danger" *ngIf="sendEmailFailed">{{
                                                ServiceMsg.email.notFound }}</p>


                                            <button (click)="sendEmail()" type="submit" class="btn_reg mt-1">{{ 'btn_continue' | translate }}</button>
                                        </form>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
<a href="" class="btn-backhome" *ngIf="!isSubmitSDT">
    <img src="assets/img/home/v4/icon/backicon.png" alt="">
</a>
<app-otp-forgot-password *ngIf="isSubmitSDT" [user]="emailOrPhone.value" [remainingOtp]="remainingOtp" ></app-otp-forgot-password>