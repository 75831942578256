import { Component, OnInit, ViewChild, ElementRef,Renderer2,AfterViewInit } from '@angular/core';
import { Options } from 'select2';
import { Select2OptionData } from 'ng-select2';
import { Observable, from } from 'rxjs';
// import { ReportAnalyticsService } from "src/app/utils/services/report-analytics.service";
import { AuthService } from 'src/app/utils/services/auth.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateUtils } from 'src/app/utils/common/DateUtils';
import { ServiceMsg } from 'src/app/utils/common/validate-msg-aas';
import { AasQuantityReportService } from 'src/app/utils/services/aas-network/aas-quantity-report.service';
import { ReportServicelItem } from 'src/app/utils/models/aas-models/quantity-report/report-service/report-service-item.model';
import { ListServicetResponse } from 'src/app/utils/models/aas-models/quantity-report/report-service/list-report-service-response.model';


declare var Cleave: any;
declare var $: any;
@Component({
  selector: 'app-report-service',
  templateUrl: './report-service.component.html',
  styleUrls: ['./report-service.component.scss']
})
export class ReportServiceComponent implements OnInit {
  @ViewChild('fromDateFilter') _fromDate: ElementRef;
  @ViewChild('toDateFilter') _toDate: ElementRef;

  partyId: string = this.auth.partyId;
  userId: string = this.auth.userId;

  formFilter: FormGroup;

  currentDate = new Date();
  listReportService : ReportServicelItem[] = [];
  listServicetResponse : ListServicetResponse;

   // vẽ biểu đồ tròn thống kê hợp đồng
   public doughnutChartType: string = 'doughnut';

   public doughnutChartDatasets: Array<any> = [];
 
   public doughnutChartLabels: Array<any> = [];
 
   public doughnutCharOption = {
   }

   totalBusiness: number = 0;
   totalConsumer: number = 0;
   totalEnterprise: number = 0;
   total : number = 0;

   dataDoughnutChar: Array<any> = []


  listConfigColTable: Array<any> = [
    {
      label: "Danh mục chức năng",
      name: "danhMucChucNang"
    },
    {
      label: "Cá nhân",
      name: "caNhan"
    },
    {
      label: "Dịch vụ",
      name: "dichVu"
    },
    {
      label: "Giải pháp",
      name: "giaiPhap"
    },
    {
      label: "Tổng số",
      name: "tongSo"
    }
    
  ];
  configColTable: any = {
    danhMucChucNang: true,
    caNhan: true,
    dichVu: true,
    giaiPhap: true,
    tongSo: true,
  }

  listFitler: Array<any> = [
    {
      label: "Từ ngày",
      name: "tuNgay"
    },
    {
      label: "Đến ngày",
      name: "denNgay"
    },
  ];
  configFilter: any = {
    tuNgay: true,
    denNgay: true,
  }

  fromDate: string = '';
  toDate: string = '';

  fromDateFilter: string = '';
  toDateFilter: string = '';
  isSubmit: boolean = false;
  ServiceMsg: any = ServiceMsg.CONTRACT_MANAGER_FILTER;


  zIndex = 5;
  public options: Options;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private modalService: NgbModal,
    private renderer2: Renderer2,
    private quantityService: AasQuantityReportService
  ) {
    
    this.renderer2.listen('window', 'click', (e: Event) => {
      var all = document.getElementsByClassName('table-sticky-col2');
      for (var i = 0; i < all.length; i++) {
        all[i].removeAttribute('style');
      }
    });
  }

  ngOnInit(): void {
    this.makeFormFilter()
    this.makeAutoFormatDate();
    this.getListReport()
  
  }

  // get danh sách báo cáo dịch vụ
  getListReport(){
    this.totalBusiness = 0;
    this.totalConsumer = 0;
    this.totalEnterprise = 0;
    let fromDate = this.convertStringToDate(this.fromDate)? this.convertStringToDate(this.fromDate):'';
    let toDate = this.convertStringToDate(this.toDate)?this.convertStringToDate(this.toDate):'';
    this.quantityService.getListService(fromDate,toDate).subscribe(res=>{
      this.listServicetResponse = new ListServicetResponse(res)
      this.listReportService = this.listServicetResponse.data;
      this.listReportService.forEach(item=>{
        this.totalBusiness = this.totalBusiness + item.dichVu;
        this.totalConsumer = this.totalConsumer + item.caNhan;
        this.totalEnterprise = this.totalEnterprise + item.giaiPhap;
      })
      this.total =  this.totalBusiness + this.totalConsumer + this.totalEnterprise

    // add data cho doughnut char
      this.dataDoughnutChar= []
      this.dataDoughnutChar.push(this.totalBusiness)
      this.dataDoughnutChar.push(this.totalConsumer)
      this.dataDoughnutChar.push(this.totalEnterprise)
      let backgroundColor : Array<any> = ['#FFB800','#20c997', '#0062FF']
      let hoverBackgroundColor : Array<any> = ['#cccdd6','#cccdd6','#cccdd6']
      this.doughnutChartLabels = ['Dịch vụ', 'Cá nhân', 'Giải pháp'];
      let total = 0
  
      this.doughnutChartDatasets = [
        { data: this.dataDoughnutChar, label: 'Thống kê số lượng theo khách hàng', backgroundColor: backgroundColor,
        hoverBackgroundColor: hoverBackgroundColor }
      ];
      this.doughnutCharOption = {
        responsive: true,
        width:600,
        height:600,
        cutoutPercentage: 70,
        elements: {
        center: {
          text: total.toString(),
          color: '#000000', 
          fontStyle: 'Arial', 
          sidePadding: 20, 
          minFontSize: 25,
          lineHeight: 25 
        }},
        legend: {
          display: true,
          position: 'bottom'
        },
      }
    })
   
  
  }
  convertStringToDate(str: string) {
    if(str !== '' && str !== null && str !== undefined) {
        var values = str.split('/');
        if(values.length >= 3) {
            return `${values[2]}-${values[1]}-${values[0]}`;
        } else {
            return null;
        }
    } else {
        return null;
    }
  }

  chartHovered(e){

  }
  chartClicked(e){

  }

  
  // cover time to format dd/MM/YYYY
  convertToDateObj(dataString) {
    let d = new Date(dataString);
    let localTiming = d.toLocaleDateString('en-GB');
    return localTiming;
  }



  //MARK: Make input date format
  makeAutoFormatDate() {
    let self = this;
    new Cleave('#fromDateReport', {
      date: true,
      delimiter: '/',
      datePattern: ['d', 'm', 'Y'],
      onValueChanged: function(e) {
        self.fromDate = e.target.value;
        self.f_fromDate.setValue(e.target.value);
      }
    });

    new Cleave('#toDateReport', {
      date: true,
      delimiter: '/',
      datePattern: ['d', 'm', 'Y'],
      onValueChanged: function(e) {
        self.toDate = e.target.value;
        self.f_toDate.setValue(e.target.value);
      }
    });
  }


  makeFormFilter() {
    this.formFilter = this.fb.group({
      toDate: new FormControl('', [Validators.pattern(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/)]),
      fromDate: new FormControl('', [Validators.pattern(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/)])
    });
  }


  get f_toDate() { return this.formFilter.get('toDate'); };
  get f_fromDate() { return this.formFilter.get('fromDate'); };

  
  dateInput(e, type) {
    if (e.target.value.length > 10) {
      var str = e.target.value;
      e.target.value = str.substring(0, str.length - 1);
      if (type === 'fromDate') {
        this.fromDate = e.target.value;
        this.f_fromDate.setValue(e.target.value);
      } else if (type === 'toDate') {
        this.toDate = e.target.value;
        this.f_toDate.setValue(e.target.value);
      }
    } else {
      if (type === 'fromDate') {
        this.fromDate = e.target.value;
        this.f_fromDate.setValue(e.target.value);
      } else if (type === 'toDate') {
        this.toDate = e.target.value;
        this.f_toDate.setValue(e.target.value);
      }
    }
  }

  changeDate(event, type) {
    if (type == 'to') {
      this.toDate = DateUtils.convertObjtoDate(event);
      this.f_toDate.setValue(this.toDate);
      this._toDate.nativeElement.value = this.toDate;
    }
    else {
      this.fromDate = DateUtils.convertObjtoDate(event);
      this.f_fromDate.setValue(this.fromDate);
      this._fromDate.nativeElement.value = this.fromDate;
    }
  }

  //MARK: Apply filter
  searchWithFilter() {
    this.isSubmit = true;

    if (this.formFilter.valid) {
      let dateFrom = this.fromDate.length > 0 ? new Date(DateUtils.convertStringtoDateSearch(this.fromDate)).getTime() : -1;
      let dateTo = this.toDate.length > 0 ? new Date(DateUtils.convertStringtoDateSearch(this.toDate)).getTime() : -1;
      if ((dateTo > 0 && dateFrom > 0 && dateFrom > dateTo)) {
        this.f_fromDate.setErrors({minDate: true});
      }
    }

    if (this.formFilter.valid) {
      this.getListReport();
    }
  }

  cleanFilter() {
    this.isSubmit = false;
    this._fromDate.nativeElement.value = '';
    this._toDate.nativeElement.value = '';

    this.fromDate = '';
    this.f_fromDate.setValue('');
    this.toDate = '';
    this.f_toDate.setValue('');

    this.configColTable = {
      danhMucChucNang: true,
      caNhan: true,
      dichVu: true,
      giaiPhap: true,
      tongSo: true,
    }

    this.configFilter = {
      tuNgay: true,
      denNgay: true,
    }

    this.searchWithFilter()


   
  }

  //MARK: Action cấu hình hiển thị các hình thức filter
  actConfigFilter(e, field) {
    e.stopPropagation();
    if (this.configFilter[field] !== null && this.configFilter[field] !== undefined) {
      this.configFilter[field] = !this.configFilter[field];
    }
  }

  //MARK: Action cấu hình hiển thị cột của bảng
  actConfigColTableView(e, field) {
    e.stopPropagation();
    if (this.configColTable[field] !== null && this.configColTable[field] !== undefined) {
      this.configColTable[field] = !this.configColTable[field];
    }
  }

  //MARK: Action dropdown từng dòng
  openDropdown(event) {
    event.path[3].setAttribute("style", "z-index: " + ++this.zIndex + ";");
  }

 


  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], 'error');
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, 'error');
    } else {
      this.alert('Lỗi không xác định!', 'error');
    }
  }

}
