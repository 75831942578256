import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { UserTypeConstant } from 'src/app/utils/common/constant';
import { ConstantAlertBtn, ConstantAlertMsg } from 'src/app/utils/common/constant-alert-msg';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { InfoPartyObject } from 'src/app/utils/models/aas-models/info-party/info-party-object';
import { Package } from 'src/app/utils/models/aas-models/package-manager-enduser/package-detail/package-detail.model';
import { PackageService } from 'src/app/utils/models/aas-models/package-manager-enduser/package-detail/package-service.model';
import { ServicePackage } from 'src/app/utils/models/aas-models/package-manager-enduser/service-package-detail/service-package-detail.model';
import { UserInformationsItem } from 'src/app/utils/models/profile/user-info-item.model';
import { ListAllPermisionReponse } from 'src/app/utils/models/user-manager/list-all-permission/list-all-permission-response';
import { PermissionGroup } from 'src/app/utils/models/user-manager/list-all-permission/permission-group';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { BusinessPackageService } from 'src/app/utils/services/aas-network/business-package.service';
import { AuthService } from 'src/app/utils/services/auth.service';

@Component({
  selector: 'app-enduser-package-detail',
  templateUrl: './enduser-package-detail.component.html',
  styleUrls: ['./enduser-package-detail.component.scss']
})
export class EnduserPackageDetailComponent implements OnInit {

  packageId = this.activeRoute.snapshot.paramMap.get('id');
  statusPackage = this.activeRoute.snapshot.paramMap.get('status');

  userInfo: UserInformationsItem = new UserInformationsItem();

  partyInfo: InfoPartyObject = new InfoPartyObject();
  
  package: Package = new Package();
  service: ServicePackage = new ServicePackage();

  listPermissionGroup: PermissionGroup[] = [];

  packageType = {
    TRIAL_TYPE : {
      id: 'TRIAL_TYPE',
      text: 'Gói dùng thử'
    },
    NORMAL_TYPE : {
      id: 'NORMAL_TYPE',
      text: 'Gói cơ bản'
    },
    NUMBER_CONTRACT_TYPE : {
      id: 'NUMBER_CONTRACT_TYPE',
      text: 'số hợp đồng'
    },
    DURATION_TYPE : {
      id: 'DURATION_TYPE',
      text: 'thời hạn gói'
    },
    '' : {
      id: '',
      text: ''
    }
  };

  userTypeConst = UserTypeConstant;

  constructor(
    private activeRoute: ActivatedRoute,
    private auth: AuthService,
    private aasAuth: AasAuthService,
    private packageService: BusinessPackageService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.statusPackage = this.statusPackage === 'null'? '': this.statusPackage
    console.log(this.statusPackage, 'hhhfdgf')
    this.getInfoPackage()
  }

  /**
   * La package dang duoc su dung => true
   * @param packageId 
   */
  isPackageUsed(packageId) {
    console.log(packageId, this.packageId);
    return packageId === this.packageId;
  }

  getInfoPackage(){

    if(this.statusPackage === ''){
      this.getPackageDetail(this.packageId);
    }
    else{
      this.getPackageByPartyId(this.auth.partyId, this.statusPackage);
    }

  }

  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = ConstantAlertBtn.OK, actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = ConstantAlertBtn.CANCEL, actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], ConstantAlertType.ERROR);
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, ConstantAlertType.ERROR);
    } else {
      this.alert(ConstantAlertMsg.DEFAULT_ERROR_REQUEST, ConstantAlertType.ERROR);
    }
  }

  //MARK: NETWORKING
  /**
   * Lay thong tin chi tiet goi dich vu bat ky
   * @param packageId 
   */
  getPackageDetail(packageId) {
    this.packageService.getPackage(packageId).subscribe((res: any) => {
      this.package = new Package(res.object || {});
      console.log(res.object, 'ftghrt')
      console.log(this.package.packageInfo, 'this.package.packageInfo')

      if (this.package.listPackageService.length > 0) {
        this.getInfoPackageService(this.package.listPackageService[0].serviceInfoId);
      }
      if (this.package.packageInfo.userType === UserTypeConstant.CONSUMER || this.package.packageInfo.userType === UserTypeConstant.BUSINESS) {
        this.getAllPrivileges()
      }
    }, err => {
      this.handleError(err, ResponseMsg.MESSAGES.MANAGEMENT_SERVICE);
    })
  }

  /**
   * Lay thong tin goi cuoc da dang ky
   * @param partyId 
   */
  getPackageByPartyId(partyId, status) {
    this.packageService.getPackageByPartyId(partyId,status).subscribe((res: any) => {
      this.package = new Package(res.object || {});
      

      if (this.package.listPackageService.length > 0) {
        this.getInfoPackageService(this.package.listPackageService[0].serviceInfoId);
      }
      if (this.package.packageInfo.userType === UserTypeConstant.CONSUMER || this.package.packageInfo.userType === UserTypeConstant.BUSINESS) {
        this.getAllPrivileges()
      }
    }, err => {
      this.handleError(err, ResponseMsg.MESSAGES.MANAGEMENT_SERVICE);
    });
  }

  /**
   * Thong tin user dang login
   * @param userId 
   */
  getUserInfo(userId) {
    this.aasAuth.getUserInfo(userId).subscribe((res: any) => {
      this.userInfo = new UserInformationsItem(res.object || {});
      
    }, err => {
      this.handleError(err, ResponseMsg.MESSAGES.PARTY_USER);
    })
  }

 

  /**
   * Lay thog tin dich vu cua goi cuoc
   * @param serviceId 
   */
  getInfoPackageService(serviceId) {
    this.packageService.getInfoPackageService(serviceId).subscribe((res: any) => {
      this.service = new ServicePackage(res.object || {});

      console.log(this.service);
    }, err => {
      this.handleError(err, ResponseMsg.MESSAGES.MANAGEMENT_SERVICE);
    })
  }

  getAllPrivileges() {
    this.packageService.getAllPrivileges().subscribe(res => {
      var data = new ListAllPermisionReponse(res);
      this.listPermissionGroup = data.object;
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.AUTH_SERVICE);
    });
  }
}
