<app-spinner></app-spinner>
<div class="content-header">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-auto">
                <h1 class="m-0 text-dark"><label>{{ 'TrustedPartnerAdd_title' | translate }}</label></h1>
            </div>
            <!-- /.col -->
            <div class="col-md-auto ml-auto text-right">
                <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="cancel()"><span
                        class="material-icons mr-1">close</span>{{ 'btn_cancel' | translate }}</button>
                <button class="btn btn-primary d-inline-flex" (click)="save()"><span
                        class="material-icons mr-1">save</span>{{ 'btn_save' | translate }}</button>
            </div>

        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>
<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card ec-card shadow-none m-0">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <form [formGroup]="form">
                                    <label class="text-primary">{{ 'TrustedPartnerAdd_accountTitle' | translate
                                        }}</label>
                                    <hr class="mt-0 mb-2">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label>{{ 'TrustedPartnerAdd_accountName' | translate }} <span
                                                                class="text-danger">*</span></label>
                                                        <input appTrimValue class="form-control"
                                                            formControlName="username"
                                                            placeholder="{{ 'TrustedPartnerAdd_placeholderAccountName' | translate }}">
                                                        <app-show-validate-errors [isSubmit]="isSubmit"
                                                            [errorMessages]="validateMsg.username"
                                                            [control]="form.controls.username"
                                                            [detail]="{ name: 'username' }">
                                                        </app-show-validate-errors>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label>{{ 'TrustedPartnerAdd_phone' | translate }} <span
                                                                class="text-danger">*</span></label>
                                                        <input appTrimValue class="form-control" formControlName="sdt"
                                                            placeholder="{{ 'TrustedPartnerAdd_placeholderPhone' | translate }}"
                                                            maxlength="10" id="phone">
                                                        <app-show-validate-errors [isSubmit]="isSubmit"
                                                            [errorMessages]="validateMsg.sdt"
                                                            [control]="form.controls.sdt" [detail]="{ name: 'sdt' }">
                                                        </app-show-validate-errors>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <label>Email <span *ngIf="hasEmail" class="text-danger">*</span></label>
                                                <input appTrimValue class="form-control" formControlName="email"
                                                    placeholder="{{ 'TrustedPartnerAdd_placeholderEmail' | translate }}">
                                                <app-show-validate-errors [isSubmit]="isSubmit" 
                                                    [errorMessages]="validateMsg.email" [control]="form.controls.email"
                                                    [detail]="{ name: 'email' }">
                                                </app-show-validate-errors>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-check ccheck-primary">
                                        <input class="form-check-input" [(ngModel)]="hasIndentifyInfo"
                                            [ngModelOptions]="{standalone: true}" type="checkbox" value=""
                                            id="hasIndentifyInfo" (click)="toggleForm()" />
                                        <label class="form-check-label" for="hasIndentifyInfo">{{
                                            'TrustedPartnerAdd_hasIndentity' |
                                            translate}}</label>
                                    </div>
                                    <div class="mt-3" *ngIf="hasIndentifyInfo">
                                        <label class="text-primary">{{ 'TrustedPartnerAdd_inforTitle' |
                                            translate}}</label>
                                        <hr class="mt-0 mb-2">
                                        <div class="row">
                                            <div class="col-md-6">
                                                <div class="row">
                                                    <div class="col">
                                                        <div class="form-group">
                                                            <label>{{ 'TrustedPartnerAdd_name' |
                                                                translate}}<span class="text-danger">*</span></label>
                                                            <input appTrimValue class="form-control"
                                                                formControlName="ten"
                                                                placeholder="{{ 'TrustedPartnerAdd_placeholderName' | translate }}">
                                                            <app-show-validate-errors [isSubmit]="isSubmit"
                                                                [errorMessages]="validateMsg.ten"
                                                                [control]="form.controls.ten"
                                                                [detail]="{ name: 'ten' }"
                                                                *ngIf="hasIndentifyInfo">
                                                            </app-show-validate-errors>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col">
                                                        <div class="form-group">
                                                            <label>{{ 'TrustedPartnerAdd_bornPlace' | translate
                                                                }}</label>
                                                            <input appTrimValue class="form-control"
                                                                formControlName="noisinh"
                                                                placeholder="{{ 'TrustedPartnerAdd_placeholderBornPlace' | translate }}">
                                                            <!-- <app-show-validate-errors [isSubmit]="isSubmit"
                                                                [errorMessages]="validateMsg.noisinh"
                                                                [control]="form.controls.noisinh"
                                                                [detail]="{ name: 'noisinh' }">
                                                            </app-show-validate-errors> -->
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col">
                                                        <div class="form-group">
                                                            <label>{{ 'TrustedPartnerAdd_issuePlace' | translate }}<span
                                                                    class="text-danger">*</span></label>
                                                            <input appTrimValue class="form-control"
                                                                formControlName="noicap"
                                                                placeholder="{{ 'TrustedPartnerAdd_placeholderissuePlace' | translate }}">
                                                            <app-show-validate-errors [isSubmit]="isSubmit"
                                                                [errorMessages]="validateMsg.noicap"
                                                                [control]="form.controls.noicap"
                                                                [detail]="{ name: 'noicap' }"
                                                                *ngIf="hasIndentifyInfo">
                                                            </app-show-validate-errors>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>{{ 'TrustedPartnerAdd_expiryDate' | translate
                                                                }}</label>
                                                            <div class="input-group">
                                                                <input class="form-control" ngbDatepicker
                                                                    #expiryDate="ngbDatepicker"
                                                                    [control]="form.controls.expiryDate"
                                                                    [maxDate]="{year: currentDate.getFullYear()+10, month: currentDate.getMonth()+1, day: currentDate.getDate()}"
                                                                    [options]="optionsCleave"
                                                                    [value]="form.value.expiryDate"
                                                                    (dateSelect)="changeDateFilter($event, 'expiryDate')"
                                                                    placeholder="dd/MM/yyyy" appInputMark>
                                                                <div class="input-group-append">
                                                                    <button class="btn btn-outline-secondary"
                                                                        (click)="expiryDate.toggle()" type="button"><em
                                                                            class="fas fa-calendar-alt"></em></button>
                                                                </div>
                                                                <!-- <app-show-validate-errors [isSubmit]="isSubmit"
                                                                    [errorMessages]="validateMsg.expiryDate"
                                                                    [control]="form.controls.expiryDate"
                                                                    [detail]="{ name: 'expiryDate' }">
                                                                </app-show-validate-errors> -->
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>{{ 'TrustedPartnerAdd_id' | translate }}<span
                                                                    class="text-danger">*</span></label>
                                                            <input appTrimValue class="form-control"
                                                                formControlName="cmnd"
                                                                placeholder="{{ 'TrustedPartnerAdd_placeholderid' | translate }}">
                                                            <app-show-validate-errors [isSubmit]="isSubmit"
                                                                [errorMessages]="validateMsg.cmnd"
                                                                [control]="form.controls.cmnd"
                                                                [detail]="{ name: 'cmnd' }"
                                                                *ngIf="hasIndentifyInfo"></app-show-validate-errors>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>{{ 'TrustedPartnerAdd_dateOfBirth' | translate
                                                                }}<span class="text-danger">*</span></label>
                                                            <div class="input-group">
                                                                <input class="form-control" ngbDatepicker
                                                                    #ngaysinh="ngbDatepicker"
                                                                    [control]="form.controls.ngaysinh"
                                                                    [maxDate]="{year: currentDate.getFullYear(), month: currentDate.getMonth()+1, day: currentDate.getDate()}"
                                                                    [options]="optionsCleave"
                                                                    [value]="form.value.ngaysinh"
                                                                    (dateSelect)="changeDateFilter($event, 'ngaysinh')"
                                                                    placeholder="dd/MM/yyyy" appInputMark>
                                                                <div class="input-group-append">
                                                                    <button class="btn btn-outline-secondary"
                                                                        (click)="ngaysinh.toggle()" type="button"><em
                                                                            class="fas fa-calendar-alt"></em></button>
                                                                </div>
                                                                <app-show-validate-errors [isSubmit]="isSubmit"
                                                                    [errorMessages]="validateMsg.ngaysinh"
                                                                    [control]="form.controls.ngaysinh"
                                                                    [detail]="{ name: 'ngaysinh' }"
                                                                    *ngIf="hasIndentifyInfo">
                                                                </app-show-validate-errors>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>{{ 'TrustedPartnerAdd_sex' | translate }}<span
                                                                    class="text-danger">*</span></label>
                                                            <ng-select bindValue="id" bindLabel="label"
                                                                [items]="gioiTinhs" formControlName="gioitinhId"
                                                                placeholder="Chọn giới tính">
                                                            </ng-select>
                                                            <app-show-validate-errors [isSubmit]="isSubmit"
                                                                [errorMessages]="validateMsg.gioitinhId"
                                                                [control]="form.controls.gioitinhId"
                                                                [detail]="{ name: 'gioitinhId' }"
                                                                *ngIf="hasIndentifyInfo">
                                                            </app-show-validate-errors>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">

                                                        <div class="form-group">
                                                            <label>{{ 'TrustedPartnerAdd_issueDate' | translate }}<span
                                                                    class="text-danger">*</span></label>
                                                            <div class="input-group">
                                                                <input class="form-control" ngbDatepicker
                                                                    #ngaycap="ngbDatepicker"
                                                                    [control]="form.controls.ngaycap"
                                                                    [maxDate]="{year: currentDate.getFullYear(), month: currentDate.getMonth()+1, day: currentDate.getDate()}"
                                                                    [options]="optionsCleave"
                                                                    [value]="form.value.ngaycap"
                                                                    (dateSelect)="changeDateFilter($event, 'ngaycap')"
                                                                    placeholder="dd/MM/yyyy" appInputMark>
                                                                <div class="input-group-append">
                                                                    <button class="btn btn-outline-secondary"
                                                                        (click)="ngaycap.toggle()" type="button"><em
                                                                            class="fas fa-calendar-alt"></em></button>
                                                                </div>
                                                                <app-show-validate-errors [isSubmit]="isSubmit"
                                                                    [errorMessages]="validateMsg.ngaycap"
                                                                    [control]="form.controls.ngaycap"
                                                                    [detail]="{ name: 'ngaycap' }"
                                                                    *ngIf="hasIndentifyInfo">
                                                                </app-show-validate-errors>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col">
                                                        <div class="form-group">
                                                            <label>{{ 'TrustedPartnerAdd_hktt' | translate }}</label>
                                                            <input appTrimValue class="form-control"
                                                                formControlName="dkhktt"
                                                                placeholder="{{ 'TrustedPartnerAdd_plhdhktt' | translate }}">
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <label class="text-primary">{{ 'TrustedPartnerAdd_pictureTitle' |
                                            translate}}</label>
                                        <hr class="mt-0 mb-2">
                                        <div class="row">
                                            <div class="col-md-3">
                                                <label>{{ 'TrustedPartnerAdd_frontImg' | translate }}<span
                                                        class="text-danger">*</span></label>
                                                <div class="upload-image d-block">
                                                    <div class="group-upload" (click)="frontImage.click()" appDragDrop
                                                        (onFileDropped)="uploadFile($event, 'FRONT')">
                                                        <div class="p-2 mx-auto" style="width: 70px; height: 70px">
                                                            <img src="assets/img/img-front-icon.svg" class="w-100">
                                                        </div>
                                                        <p class="text-black mt-4 mb-0">Kéo thả hình ảnh <br> hoặc</p>
                                                        <a class="text-primary text-decoration-underline mt-4">Chọn
                                                            file</a>
                                                        <input id="fileInput" hidden type="file" #frontImage
                                                            accept="image/png, image/jpeg"
                                                            (input)="uploadFile($event.target.files, 'FRONT')">
                                                    </div>
                                                    <div *ngIf="form.value.frontImage" class="image-selected"
                                                        (click)="frontImage.click()">
                                                        <img src="{{ base64FrontImage }}">
                                                        <span class="remove-img-selected material-icons"
                                                            (click)="removeImage('FRONT'); $event.preventDefault()">cancel</span>
                                                    </div>
                                                </div>
                                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                                    [control]="form.controls.frontImage"
                                                    [detail]="{ name: 'frontImage'}"
                                                    [errorMessages]="validateMsg.frontImage"
                                                    *ngIf="hasIndentifyInfo"></app-show-validate-errors>
                                            </div>
                                            <div class="col-md-1"></div>
                                            <div class="col-md-3">
                                                <label>{{ 'TrustedPartnerAdd_backImg' | translate }}<span
                                                        class="text-danger">*</span></label>
                                                <div class="upload-image d-block">
                                                    <div class="group-upload" (click)="backImage.click()" appDragDrop
                                                        (onFileDropped)="uploadFile($event, 'BACK')">
                                                        <div class="p-2 mx-auto" style="width: 70px; height: 70px">
                                                            <img src="assets/img/img-back-icon.svg" class="w-100">
                                                        </div>
                                                        <p class="text-black mt-4 mb-0">Kéo thả hình ảnh <br> hoặc</p>
                                                        <a class="text-primary text-decoration-underline mt-4">Chọn
                                                            file</a>
                                                        <input id="fileInput" hidden type="file" #backImage
                                                            accept="image/png, image/jpeg"
                                                            (input)="uploadFile($event.target.files, 'BACK')">
                                                    </div>
                                                    <div *ngIf="form.value.backImage" class="image-selected"
                                                        (click)="backImage.click()">
                                                        <img src="{{ base64BackImage }}">
                                                        <span class="remove-img-selected material-icons"
                                                            (click)="removeImage('BACK'); $event.preventDefault()">cancel</span>
                                                    </div>
                                                </div>
                                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                                    [control]="form.controls.backImage" [detail]="{ name: 'backImage'}"
                                                    [errorMessages]="validateMsg.backImage"
                                                    *ngIf="hasIndentifyInfo"></app-show-validate-errors>
                                            </div>
                                            <div class="col-md-1"></div>
                                            <div class="col-md-3">
                                                <label>{{ 'TrustedPartnerAdd_portraitImg' | translate }}<span
                                                        class="text-danger">*</span></label>
                                                <div class="upload-image d-block">
                                                    <div class="group-upload" (click)="portraitImage.click()"
                                                        appDragDrop (onFileDropped)="uploadFile($event, 'PORTRAIT')">
                                                        <div class="p-2 mx-auto" style="width: 70px; height: 70px">
                                                            <img src="assets/img/img-portrait-icon.svg" class="w-100">
                                                        </div>
                                                        <p class="text-black mt-4 mb-0">Kéo thả hình ảnh <br> hoặc</p>
                                                        <a class="text-primary text-decoration-underline mt-4">Chọn
                                                            file</a>
                                                        <input id="fileInput" hidden type="file" #portraitImage
                                                            accept="image/png, image/jpeg"
                                                            (input)="uploadFile($event.target.files, 'PORTRAIT')">
                                                    </div>
                                                    <div *ngIf="form.value.portraitImage" class="image-selected"
                                                        (click)="portraitImage.click()">
                                                        <img src="{{ base64PortraitImage }}">
                                                        <span class="remove-img-selected material-icons"
                                                            (click)="removeImage('PORTRAIT'); $event.preventDefault()">cancel</span>
                                                    </div>
                                                </div>
                                                <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit"
                                                    [control]="form.controls.portraitImage"
                                                    [detail]="{ name: 'portraitImage'}"
                                                    [errorMessages]="validateMsg.portraitImage"
                                                    *ngIf="hasIndentifyInfo">
                                                </app-show-validate-errors>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>