import { UtilsFunc } from "src/app/utils/common/utils-func";

export class SmartCACert {
    certificateId: string = "";
    id: string = '';
    status: string = '';
    certBase64: string = '';
    serialNumber: string = '';
    issuerDN: string = '';
    validTo: string = '';
    validFrom: string = '';
    subjectDN: string = '';
    subject: string = '';
    accountEmail: string = '';
    CN: string = '';
    username: string = '';
    issuer: string = '';
    secret: string
    signType: number

    constructor(obj = null) {
        obj = obj || {};
        this.id = obj.id || obj.certificateId || '';
        this.certificateId = obj.certificateId || '';
        this.status = obj.status || '';
        this.certBase64 = obj.certBase64 || '';
        this.serialNumber = obj.serialNumber || '';
        this.issuerDN = obj.issuerDN || obj.issuer || '';
        this.issuer = obj.issuer || '';
        this.validTo = obj.validTo || '';
        this.validFrom = obj.validFrom || '';
        this.subjectDN = obj.subjectDN || obj.subject || '';
        this.subject = obj.subject || ''
        this.accountEmail = obj.accountEmail || '';
        this.CN = UtilsFunc.getSubStringCAInfo(this.subjectDN, "CN=")
        this.username = obj.username || '';
        this.secret = obj.secret
        this.signType = obj.signType
    }

    getSmartCAName(subjectDN) {
        if (subjectDN) {
            return subjectDN.split(',').find(item => item.includes('CN=')).slice(3)
        } else return ''
    }

}