import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerManagerService } from 'src/app/utils/services/aas-network/customer-manager/customer-manager.service';

@Component({
  selector: 'app-expension-history-tab',
  templateUrl: './expension-history-tab.component.html',
  styleUrls: ['./expension-history-tab.component.scss']
})
export class ExpensionHistoryTabComponent implements OnInit {

  partyId = this.router.snapshot.paramMap.get("id");

  constructor(
    private router: ActivatedRoute,
    private customerManagerService: CustomerManagerService
  ) { }

  history: Array<any> = new Array();

  ngOnInit(): void {
    this.getHistory();
  }

  // Get upgrade infrastructure history
  getHistory() {
    this.customerManagerService.getPackageHistory(this.partyId).subscribe((res: any) => {
      this.history = res?.object || [];
      this.history.reverse();
      this.history.forEach((content) => {
        content?.infrastructures.sort((a, b) => (a.stt > b.stt ? 1 : -1));
      });
    });
  }

  formatPrice(amount) {
    const number: Number = new Number(amount);
    const amountStr: string = number.toString();
    let amountArr: Array<string> = amountStr.split('');
    let count: number = 0;
    for (let i = amountArr.length - 1; i >= 0; i--) {
      count++;
      if (count == 3 && i > 0) {
        amountArr.splice(i, 0, ',');
        count = 0;
      }
    }
    const res: string = amountArr.join("");
    return res;
  }

}
