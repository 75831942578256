<div class="price_list">
    <section class="price_list-ss1">
        <div class="container">
            <div class="row">
                <div class="body">
                    <h3 class="title-section">{{ 'packages'| translate }}</h3>
                    <div class="content">
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" data-toggle="tab" href="#goicuoc1">{{ 'organization' |
                                    translate }}</a>
                            </li>
                            <li class="nav-item disabled disabled-goicuoc">
                                <a href="javascript:void(0)" class="nav-link"> {{ 'individual' | translate }}</a>
                            </li>

                        </ul>
                        <div class="tab-content">
                            <div id="goicuoc1" class="container active tab-pane">
                                <div class="row">
                                    <div class="col-md-4" *ngFor="let item of organizationData?.blogs; let i = index">
                                        <div class="package package{{i+1}}"
                                            [ngClass]="{'active': item?.subTitle == 'most_popular'}">
                                            <span class="hotnew">{{ 'most_popular' | translate }}</span>
                                            <span class="packof">{{ item?.title }}</span>
                                            <div class="body" [innerHTML]="item?.description">

                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div class="col-md-4">
                                            <div class="package package2 active">
                                                <span class="hotnew">{{ 'most_popular' | translate }}</span>
                                                <span class="packof">GÓI BASIC</span>
                                                <div class="body">
                                                    <h3 class="title">Liên hệ</h3>
                                                    <span class="content-title">
                                                        Gói cơ bản dành cho cá nhân có số lượng hợp đồng nhỏ
                                                    </span>
                                                    <a href="/app/signup" class="btn-reg">Đăng ký mua
                                                    </a>
                                                    <ul class="list-item">
                                                        <li>
                                                            <img src="https://storage-econtract.vnpt.vn/landingpage-dev/2021-11-01/checkicon.png" alt="">
                                                            <span>1 tháng</span>
                                                        </li>
                                                        <li>
                                                            <img src="https://storage-econtract.vnpt.vn/landingpage-dev/2021-11-01/checkicon.png" alt="">
                                                            <span>10 hợp đồng</span>
                                                        </li>
                                                        <li>
                                                            <img src="https://storage-econtract.vnpt.vn/landingpage-dev/2021-11-01/checkicon.png" alt="">
                                                            <span>Ký số USB token, Sign Server, SmartCA</span>
                                                        </li>
                                                        <li>
                                                            <img src="https://storage-econtract.vnpt.vn/landingpage-dev/2021-11-01/checkicon.png" alt="">
                                                            <span>Chia sẻ hợp đồng điện từ với đối tác</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="package package3">
                                                <span class="hotnew">{{ 'most_popular' | translate }}</span>
                                                <span class="packof">LIÊN HỆ</span>
                                                <div class="body">
                                                    <h3 class="title">Miễn phí</h3>
                                                    <span class="content-title">
                                                        Gói nâng cao dành cho cá nhân có số lượng hợp đồng lớn
                                                    </span>
                                                    <a href="/app/signup" class="btn-reg">Đăng ký mua
                                                    </a>
                                                    <ul class="list-item">
                                                        <li>
                                                            <img src="https://storage-econtract.vnpt.vn/landingpage-dev/2021-11-01/checkicon.png" alt="">
                                                            <span>1 tháng</span>
                                                        </li>
                                                        <li>
                                                            <img src="https://storage-econtract.vnpt.vn/landingpage-dev/2021-11-01/checkicon.png" alt="">
                                                            <span>1 tháng</span>
                                                        </li>
                                                        <li>
                                                            <img src="https://storage-econtract.vnpt.vn/landingpage-dev/2021-11-01/checkicon.png" alt="">
                                                            <span>1 tháng</span>
                                                        </li>
                                                        <li>
                                                            <img src="https://storage-econtract.vnpt.vn/landingpage-dev/2021-11-01/checkicon.png" alt="">
                                                            <span>1 tháng</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div> -->
                                </div>
                            </div>
                            <!-- <div id="goicuoc2" class="container tab-pane fade">
                                   <div class="row">
                                        <div class="col-md-4" *ngFor="let item of organizationData?.blogs; let i = index"> 
                                            <div class="package package{{i+1}}" [ngClass]="{'active': item.subTitle == 'most_popular'}">
                                                <span class="hotnew">{{ 'most_popular' | translate }}</span>
                                                <span class="packof">{{ item?.title }}</span>
                                                <div class="body" [innerHTML]="item?.description">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="bg">
        <section class="price_list-ss2" id="price_list">
            <div class="container">
                <div class="row center-item">
                    <div class="col-md-12 col-lg-5 col-xl-6">
                        <div class="content-left">
                            <h3>{{ registerToBuy?.category?.categoryName }}</h3>
                            <span>
                                {{ registerToBuy?.category?.description }}
                            </span>
                        </div>
                    </div>
                    <div class="col-md-12 col-lg-7 col-xl-6">
                        <div class="row">
                            <div class="form-dk">
                                <form>
                                    <span class="title">
                                        {{ 'package_information' | translate }}
                                    </span>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <select name="" class="form-control">
                                                <option value="">{{ 'individual' | translate }}</option>
                                            </select>
                                        </div>
                                        <div class="col-md-6">
                                            <select name="" class="form-control">
                                                <option value="">{{ 'individual' | translate }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <span class="title mt-5">
                                        {{ 'subscriber_information' | translate }}
                                    </span>
                                    <div class="form-group">
                                        <input type="text" class="form-control"
                                            placeholder="{{ 'full_name' | translate }}">
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control"
                                                    placeholder="{{ 'phone_number' | translate }}">
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-group">
                                                <input type="text" class="form-control" placeholder="Email">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <select name="" class="form-control">
                                            <option value="">Tỉnh/Thành phố</option>
                                        </select>
                                    </div>
                                    <p class="mony">{{ registerToBuy?.blogs[0]?.subTitle }}: <b>{{
                                            registerToBuy?.blogs[0]?.description }}</b></p>
                                    <div class="mt-5 list-button">
                                        <button type="submit" class="btn-dk">{{ 'register_to_buy' | translate
                                            }}</button>
                                        <button type="submit" class="btn-tv">{{ 'advise_me' | translate }}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section class="section-8">
            <div class="container" style="    position: relative;">
                <div class="row body">
                    <div class="col-md-7">
                        <div class="row">
                            <div class="left-content">
                                <p class="title">
                                    {{ experience?.category?.categoryName }}<br />
                                    {{ experience?.category?.description }}

                                </p>
                                <a routerLink="/app/signup" class="btn-trial">
                                    <span>{{ experience?.blogs[1]?.title }}</span>
                                    <img src="{{ experience?.blogs[1]?.avatar }}" alt="">
                                </a>
                                <ul class="list-item d-none d-sm-block">
                                    <li>
                                        <img src="{{ experience?.blogs[2]?.avatar }}" alt="">
                                        <span>{{ experience?.blogs[2]?.title }}</span>
                                    </li>
                                    <li>
                                        <img src="{{ experience?.blogs[3]?.avatar }}" alt="">
                                        <span>{{ experience?.blogs[3]?.title }}</span>
                                    </li>
                                    <li>
                                        <img src="{{ experience?.blogs[4]?.avatar }}" alt="">
                                        <span>{{ experience?.blogs[4]?.title }}</span>
                                    </li>
                                    <li>
                                        <img src="{{ experience?.blogs[5]?.avatar }}" alt="">
                                        <span>{{ experience?.blogs[5]?.title }}</span>
                                    </li>
                                </ul>
                                <div class="list-button">
                                    <a href="{{ link.iosLink }}">
                                        <div class="img">
                                            <img src="{{ appLink?.blogs[1]?.avatar }}" alt="">
                                        </div>
                                    </a>
                                    <a href="{{ link.androidLink }}">
                                        <div class="img">
                                            <img src="{{ appLink?.blogs[2]?.avatar }}" alt="">
                                        </div>
                                    </a>
                                    <!-- <a href="{{ configs[10]?.value }}">
                                        <img src="{{ appLink?.blogs[1]?.avatar }}" alt="">
                                    </a>
                                    <a href="{{ configs[3]?.value }}">
                                        <img src="{{ appLink?.blogs[2]?.avatar }}" alt="" style="margin: 0px 12px">
                                    </a> -->
                                    <a href="{{ link.qrcodeLink }}" class="d-none d-xl-block">
                                        <!-- <img style="width: 150px;" src="{{ appLink?.blogs[3]?.avatar }}" alt=""> -->
                                        <img src="{{ appLink?.blogs[3]?.avatar }}" alt="">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--  <img src="./Image/Group 1773s6.png" alt="" class="img-right d-none d-sm-block"> -->
                </div>
            </div>
        </section>
    </div>
</div>