import { ContractListItem } from './contract-list-item.model';

export class ContractListObject {
    data: ContractListItem[];
    page: number = 1;
    maxSize: number = 0;
    propertiesSort: string = '';
    totalElement: number = 0;
    sort: string = '';
    keySearch: string = '';

    constructor(obj = null) {
        obj = obj || {};
        this.page = obj.page;
        this.maxSize = obj.maxSize;
        this.propertiesSort = obj.propertiesSort || '';
        this.totalElement = obj.totalElement;
        this.sort = obj.sort || '';
        this.data = [];
        if (obj.data && obj.data instanceof Array) {
            obj.data.forEach((item) => {
                this.data.push(new ContractListItem(item));
            });
        }
        this.keySearch = obj.keySearch || '';
    }
}