import { PrivilegeType } from './privileges/privilege-type.model';

export class PackageItem {
    id: string = '';
    packInfoId: string = '';
    name: string = '';
    created: string = '';
    modified: string = '';
    description: string = '';
    price: number;
    packageType: string;
    duration: string = '';
    contractNumber: number;
    status: string = '';
    roleId: string = '';
    userType: string = '';
    privileges: Array<PrivilegeType> = [];

    constructor(data = null) {
        data = data || {};
        this.id = data.packInfoId || '';
        this.packInfoId = data.packInfoId || '';
        this.name = data.name || '';
        this.created = data.created || '';
        this.modified = data.modified || '';
        this.description = data.description || '';
        this.price = data.price;
        this.packageType = data.packageType || '';
        this.duration = data.duration || '';
        this.contractNumber = data.contractNumber;
        this.status = data.status || '';
        this.roleId = data.roleId || '';
        this.userType = data.userType || '';

        this.privileges = [];
        if (data.privileges && data.privileges instanceof Array) {
            data.privileges.forEach(item => {
                this.privileges.push(new PrivilegeType(item));
            });
        }
    }
}