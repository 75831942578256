export class PartListItem {
    created: string = "";
    disable: number = 0;
    descr: string = "";
    modified: string = "";
    partyId: string = "";
    phoneNumber: string = "";
    fax: string = "";
    partyName: string = "";

    constructor(d = null) {
        d = d || {};
        this.created = d.created || "";
        this.disable = d.disable;
        this.descr = d.descr || "";
        this.modified = d.modified || "";
        this.partyId = d.partyId || "";
        this.phoneNumber = d.phoneNumber || "";
        this.fax = d.fax || "";
        this.partyName = d.partyName || "";
    }
}