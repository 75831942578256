import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aas-footer',
  templateUrl: './aas-footer.component.html',
  styleUrls: ['./aas-footer.component.scss']
})
export class AasFooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
