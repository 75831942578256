<app-spinner></app-spinner>
<!-- <app-spinner *ngIf="loadingServiceIsVisible() | async"></app-spinner> -->
<div class="content-header">
  <div class="container-fluid">
    <div class="row mb-2">
      <div class="col-md-auto">
        <h1 class="m-0 text-dark"><label>Quản lý sản phẩm dịch vụ</label></h1>
      </div>
      <!-- /.col -->
      <div class="col-md-auto ml-auto">
        <!-- <button (click)="uploader.click()" class="btn btn-outline-primary btn-light d-inline-flex mr-4"><span
            class="material-icons mr-1">publish</span> Import Excel</button>
        <input hidden type="file" #uploader (click)="uploader.value = null" value="" (change)="uploadFile($event)" accept=".xlsx, .xls, .csv" />
        <button (click)="downloadSample()" class="btn btn-outline-primary btn-light d-inline-flex mr-3"><span
            class="material-icons mr-1">get_app</span> Tải file mẫu</button> -->
      </div>
      <!-- <!-- /.col -->
    </div>
    <!-- /.row -->
  </div>
  <!-- /.container-fluid -->
</div>

<!-- Main content -->
<section class="content dashboard">
  <div class="container-fluid">
    <div class="row">
      <div class="col-4">
        <div class="card shadow-none h-100">
          <div class="card-header border-0 py-3 pt-3">
            <label class="title">Danh sách sản phẩm dịch vụ</label>
            <button class="btn btn-primary d-inline-flex float-right mb-3" (click)="addNew()">
              <span class="material-icons">add</span> Thêm mới
            </button>

            <div class="input-group mt-3">
              <form (ngSubmit)="searchData(searchField.value)" class="input-group">
                <div class="input-group-prepend">
                  <button class="btn btn-primary d-inline-flex pr-2 pl-2" type="submit"><span
                      class="material-icons">search</span></button>
                </div>
                <input #searchField type="text" class="form-control" placeholder="Nhập tên sản phẩm dịch vụ..."
                  aria-label="" aria-describedby="basic-addon1">
              </form>
            </div>
          </div>

          <div class="position-relative">
            <div *ngIf="!!categories && categories.length; else emptyBlock" class="content-list fade show">
              <div *ngFor="let item of categories; let i = index" (click)="editCategory(item)"
                class="user-group-item content-list-item px-4 pt-2" [class.active]="item.id === form.value.id">
                <div class="border-bottom d-flex">
                  <span class="material-icons icon-item pr-3">layers</span>
                  <div class="flex-grow-1">
                    <label class="title-item">{{ item.productName || 'NULL' }}</label>
                    <p class="text-secondary">Ngày chỉnh sửa:
                      {{ item.dateUpload | date: 'dd/MM/yyyy' }}</p>
                  </div>
                  <a *ngIf="!item.isUsed" class="text-decoration-underline text-primary pl-4"
                    (click)="deleteCategory($event, item)" style="cursor: pointer;">Xóa</a>
                </div>
              </div>
            </div>

            <!-- <div *ngIf="!!categories && categories.length; else emptyBlock" class="table-responsive">
              <table class="table table-hover mb-0">
                <tbody>
                  <tr *ngFor="let item of categories; let i = index" [class.active]="item.id === form.value.id">
                    <td class="py-0 px-2 handle" [class.active]="item.id === form.value.id"
                      (click)="editCategory(item)">
                      <p class="px-0 py-2 my-0 product-name">
                        <i class="fas fa-layer-group mr-3"></i> {{ item.productName || 'NULL' }}
                      </p>
                      <p class="p-0 m-0">
                        <small>Ngày chỉnh sửa: {{ item.dateUpload | date: 'dd/MM/yyyy' }}</small>
                      </p>
                    </td>
                    <td>
                      <button *ngIf="!item.isUsed" type="button" (click)="deleteCategory(item)"
                        class="btn btn-link float-right">
                        <small><u>Xoá</u></small>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div> -->

            <ng-template #emptyBlock>
                <app-empty-block></app-empty-block>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="col-8">
        <div class="card shadow-none">
          <div class="card-header border-0 py-3 pt-3">
            <label class="title">Thông tin sản phẩm dịch vụ</label>
            <!-- [disabled]="form.invalid" -->
            <button type="button" class="btn rounded-lg btn-primary d-inline-flex float-right ml-4" (click)="saveCategory()">
              <span class="material-icons mr-1">save</span> Lưu
            </button>
            <button type="button" class="btn rounded-lg btn-outline-primary d-inline-flex float-right" (click)="resetForm()"><span
              class="material-icons mr-1">clear</span> Huỷ
            </button>
            <hr class="mb-0 mt-3">
          </div>
          <div class="card-body pt-0">
            <form [formGroup]="form">
              <div class="row">
                <div class="col-6">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label>Tên sản phẩm dịch vụ <span class="text-danger">*</span>:</label>
                        <input appTrimValue [attr.disabled]="currentCategory.isUsed || null" type="text" formControlName="productName" class="form-control text-dark"
                          placeholder="Nhập tên sản phẩm dịch vụ" />
                        <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit" [errorMessages]="validateMsg.productName"
                          [control]="form.controls.productName" [detail]="{ name: 'productName' }">
                        </app-show-validate-errors>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <label>Mã sản phẩm dịch vụ <span class="text-danger">*</span>:</label>
                        <input appTrimValue [attr.disabled]="currentCategory.isUsed || null" type="text" formControlName="productId" class="form-control text-dark"
                          placeholder="Nhập mã sản phẩm dịch vụ" />
                        <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit" [errorMessages]="validateMsg.productId"
                          [control]="form.controls.productId" [detail]="{ name: 'productId' }">
                        </app-show-validate-errors>
                      </div>
                    </div>
                    <!-- <div class="col-6">
                      <div class="form-group">
                        <label>Nhóm sản phẩm dịch vụ <span class="text-danger">*</span>:</label>
                        <ng-select2 [disabled]="currentCategory.isUsed" formControlName="productService" [data]="dropListProductServicesData"
                          [options]="options" [placeholder]="'Chọn nhóm SPDV'"></ng-select2>
                        <app-show-validate-errors [useSubmit]="true" [isSubmit]="isSubmit" [errorMessages]="validateMsg.productService"
                          [control]="form.controls.productService" [detail]="{ name: 'productService' }">
                        </app-show-validate-errors>
                      </div>
                    </div> -->
                  </div>
                </div>
                <div class="col-6">
                  <div class="form-group">
                    <label>Ghi chú:</label>
                    <textarea [attr.disabled]="currentCategory.isUsed || null" formControlName="productNote" class="form-control text-dark" rows="5"
                      placeholder="Nhập thông tin mô tả sản phẩm dịch vụ" style="height: 109px;"></textarea>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="col-4 d-flex justify-content-xl-end">
        <div *ngIf="totalElement > maxSize" class="pagination justify-content-end pages mt-3">
          <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="5" [rotate]="true"
            [ellipses]="false" [pageSize]="maxSize" (pageChange)="loadPage($event)" [boundaryLinks]="true"
            aria-label="Default pagination">
            <ng-template ngbPaginationFirst>Đầu</ng-template>
            <ng-template ngbPaginationLast>Cuối</ng-template>
            <ng-template ngbPaginationPrevious>&laquo;</ng-template>
            <ng-template ngbPaginationNext>&raquo;</ng-template>
            <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
          </ngb-pagination>
        </div>
      </div>
    </div>
  </div>
</section>
