export class DSignItem {
    // certok: false
    // issuer: {common_name: "eContract CA"}
    // not_after: "01:43:37 15/04/2021"
    // not_before: "01:43:37 15/04/2020"
    // signDate: "08:42:21 09/12/2020"
    // signatureok: true
    // subject: {common_name: "eContract VNPT 2", country_name: "VN", locality_name: "VNPT IT3", organization_name: "econtract.vnpt.vn", state_or_province_name: "DN"}

    certok: any;
    issuer: any;
    not_after: string;
    not_before: string;
    signDate: string;
    signatureok: any;
    subject: any;
    expanded = false;
    compareok: any;

    constructor(d = null) {
        d = d || {};
        this.certok = d.certok || false;
        this.issuer = d.issuer || {};
        this.not_after = d.not_after || "";
        this.not_before = d.not_before || "";
        this.signDate = d.signDate || "";
        this.signatureok = d.signatureok || false;
        this.subject = d.subject || {};
        this.compareok = d.compareok || false;
    }
}