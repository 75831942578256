export class SignUpBusinessObject {
    created: string = '';
    daiDien: boolean = true;
    domain: string = '';
    email: string = '';
    gioiTinhId: number;
    identityLevel: number;
    modified: string = '';
    ngaySinh: string = '';
    partyId:  string = '';
    sdt: string = '';
    ten: string = '';
    userId: string = '';
    userType:  string = '';
    username: string = '';
    // daiDien: DaiDien;
    toChuc: ToChuc;

    constructor(obj = null) {
        obj = obj || {};
        // this.daiDien = new DaiDien(obj.daiDien);
        this.toChuc = new ToChuc(obj.toChuc);
        this.created = obj.created || '';
        this.daiDien = obj.daiDien || '';
        this.domain = obj.domain || '';
        this.email = obj.email || '';
        this.gioiTinhId = Number(obj.gioiTinhId);
        this.identityLevel = Number(obj.identityLevel);
        this.modified = obj.modified || '';
        this.ngaySinh = obj.ngaySinh || '';
        this.partyId = obj.partyId || '';
        this.sdt = obj.sdt || '';
        this.ten = obj.ten || '';
        this.userId = obj.userId || '';
        this.userType = obj.userType || '';
        this.username = obj.username || '';
    }
}

export class DaiDien {
    userId: string = '';
    disable: boolean = false;
    daiDien: string = '';
    userType: string = '';
    username: string = '';
    created: string = '';
    modified: string = '';
    ten: string = '';
    ngaySinh: string = '';
    gioiTinhId: string = '';
    email: string = '';
    sdt: string = '';
    initPassword: string = '';
    domain: string = '';
    identityLevel: number = 0;
    ekyc: boolean = false;
    partyId: string = '';

    constructor(obj = null) {
        obj = obj || {};
        this.userId = obj.userId || '';
        this.disable = obj.disable;
        this.daiDien = obj.daiDien || '';
        this.userType = obj.userType || '';
        this.username = obj.username || '';
        this.created = obj.created || '';
        this.modified = obj.modified || '';
        this.ten = obj.ten || '';
        this.ngaySinh = obj.ngaySinh || '';
        this.gioiTinhId = obj.gioiTinhId;
        this.email = obj.email || '';
        this.sdt = obj.sdt || '';
        this.initPassword = obj.initPassword || '';
        this.domain = obj.domain || '';
        this.identityLevel = obj.identityLevel;
        this.ekyc = obj.ekyc;
        this.partyId = obj.partyId || '';
    }
}

export class ToChuc {
    partyId: string = '';
    domain: string = '';
    disable: boolean = false;
    partyType: string = '';
    created: string = '';
    modified: string = '';
    tenToChuc: string = '';
    tenRutGon: string = '';
    maSoThue: string = '';
    identityLevel: number = 0;

    constructor(obj = null) {
        obj = obj || {};
        this.partyId = obj.partyId || '';
        this.domain = obj.domain || '';
        this.disable = obj.disable;
        this.partyType = obj.partyType || '';
        this.created = obj.created || '';
        this.modified = obj.modified || '';
        this.tenToChuc = obj.tenToChuc || '';
        this.tenRutGon = obj.tenRutGon || '';
        this.maSoThue = obj.maSoThue || '';
        this.identityLevel = obj.identityLevel;
    }
}