import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { Part } from 'src/app/utils/models/part/part.model';
import { PartService } from 'src/app/utils/services/aas-network/part/part.service';
import { EmployeeInPartComponent } from '../employee-in-part/employee-in-part.component';

@Component({
  selector: 'app-part-detail',
  templateUrl: './part-detail.component.html',
  styleUrls: ['./part-detail.component.scss']
})
export class PartDetailComponent implements OnInit {
  @ViewChild('employeeInPart') employeeInPart: ElementRef;
  @ViewChild('appEmployeeInPart') appEmployeeInPart: EmployeeInPartComponent;

  id = this.activedRoute.snapshot.paramMap.get('id');
  part: Part = new Part();

  modelRef: NgbModalRef;

  constructor(
    private router: Router,
    private activedRoute: ActivatedRoute,
    private partService: PartService,
    private ngbModal: NgbModal,
    public translate: TranslateService,

  ) { }

  ngOnInit(): void {
    this.getPart(this.id);
  }

  update(id) {
    this.router.navigate([ConstantUrl.PART_EDIT, id]);
  }

  getClassStatus(status) {
    // var st = `${status}`;
    var st = status;

    if (st === true) return "badge-danger-new";
    if (st === false) return "badge-success-new";
    return "";
  }

  getTextStatus(status) {
    // var st = `${status}`;
    var st = status;
    
    if (st === true) return this.translate.instant('status_stopped');
    if (st === false) return this.translate.instant('status_running');
    return "";
  }

  showListEmployee(event, id) { 
    event.stopPropagation();
    this.modelRef = this.ngbModal.open(this.employeeInPart, { size: 'xl' });
    this.appEmployeeInPart && this.appEmployeeInPart.reload();
  }

  showEmployeesInPart() {
    this.modelRef = this.ngbModal.open(this.employeeInPart);
    this.appEmployeeInPart && this.appEmployeeInPart.reload();
  }

  handleGoToDetailEmployee(e) {
    this.modelRef && this.modelRef.close();
  }

  //MARK: NETWORK
  getPart(id) {
    this.partService.getPart(id).subscribe((res: any) => {
      this.part = new Part(res?.object?.data);
      console.log(res);
    })
  }
}
