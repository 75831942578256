import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { AuthService } from 'src/app/utils/services/auth.service';
import { ValidatorsExtension } from 'src/app/utils/common/validators-extension';
import { DateUtils } from 'src/app/utils/common/DateUtils';
import { share } from 'rxjs/operators';
import { UserInformationsResponse } from 'src/app/utils/models/profile/user-info-response.model';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { UserInformationsItem } from 'src/app/utils/models/profile/user-info-item.model';
import { AasValidateMessage } from 'src/app/utils/common/validate-msg-aas';
import { InfoPartyObject } from 'src/app/utils/models/aas-models/info-party/info-party-object';
import { InfoPartyResponse } from 'src/app/utils/models/aas-models/info-party/info-party-response';
import { ListHuyenResponse } from 'src/app/utils/models/catalog/list-huyen/list-huyen-response.model';
import { ListTinhResponse } from 'src/app/utils/models/catalog/list-tinh/list-tinh-response.model';
import { ListXaResponse } from 'src/app/utils/models/catalog/list-xa/list-xa-response.model';
import { Select2OptionData } from 'ng-select2';
import { Options } from 'select2';
import { CatalogService } from 'src/app/utils/services/aas-network/catalog.service';
import { LoadingService } from 'src/app/utils/services/loading.service';

declare var Cleave: any;
@Component({
  selector: 'app-step-one-verify-vip',
  templateUrl: './step-one-verify-vip.component.html',
  styleUrls: ['./step-one-verify-vip.component.scss']
})
export class StepOneVerifyVipComponent implements OnInit {
  @Input('data') _data: BehaviorSubject<UserInformationsItem> = new BehaviorSubject<UserInformationsItem>(new UserInformationsItem());
  @Input('partyInfor') partyInfo: InfoPartyObject = new InfoPartyObject();
  @Output() public onActionSave: EventEmitter<any> = new EventEmitter<any>();
  @Output() next = new EventEmitter<any>();
  @Output() back = new EventEmitter<any>();
  @Output('outputPartyInfo') outputPartyInfo = new EventEmitter<InfoPartyObject>();

  public tinhData: Array<Select2OptionData> = [];
  public huyenData: Array<Select2OptionData> = [];
  public xaData: Array<Select2OptionData> = [];
  public options: Options;
  public optionsHuyen: Options;
  public optionsXa: Options;

  private requests: Observable<Object>[] = [];

  dmTinh = new ListTinhResponse();
  dmHuyen = new ListHuyenResponse();
  dmXa = new ListXaResponse();

  id: string = this.auth.userId;
  partyId: string = this.auth.partyId;

  loadingForm = new BehaviorSubject<boolean>(false);
  userInfo: UserInformationsItem = new UserInformationsItem();
  // partyInfo: InfoPartyObject = new InfoPartyObject();

  formSubmit: FormGroup;
  isSubmit: boolean = false;
  ServiceMsg = AasValidateMessage.PROFILE_MANAGER_BUSINESS;
  loaded: Array<boolean> = [false, false, false];

  // "partyId": "ce4efc8f-c2f0-4e4d-b1a1-ac92e9500f50"
  // "userId": "ce4efc8f-c2f0-4e4d-b1a1-ac92e9500f50",
  // eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZFVzZXIiOiI5OGQ1ZDg0MC1iYWYwLTRlNjMtOWZiOC0wYjRiNzkwMTA0NDYiLCJhdWQiOlsicmVzdHNlcnZpY2UiXSwidXNlcl9uYW1lIjoidGhhbmhkcHR4MiIsInNjb3BlIjpbInJlYWQiXSwiZG9tYWluIjoiZWNvbnRyYWN0LWRldi52bnB0aXQzLnZuIiwiZXhwIjoxNTk3MjI4MzA2LCJhdXRob3JpdGllcyI6WyJBQ0NPVUwyIiwiQUNDT1IiLCJBQ0NPVUwxIl0sImp0aSI6IjViODdjNmZjLWI4NWEtNDE3ZS05MjI4LWJhZjY5YjkyZTNhOSIsImNsaWVudF9pZCI6ImNsaWVudGFwcCJ9.O9Vluu7mXb_xVgg6RdpqD1FHUu3pRD-g8_CxHT_W_Z-JWquWWdbVQRVUppFI5mkg-yBn8nrum-vqLumhyqKKz1-tMhJu_L26obrFlGFMjqVaHTJmu6r_nP8HNYyLfF_QRJxPf7-NmEC0Od_tjSzzH-FsudC4uMkM2NAlSTFQ7kNhEyK6SUyHHKp1MhyZSPX8t0Z1Qa5-NAaFzpKJWv74n0vxSHFM1C2rIMSDPGO9NxJrFhT34AYSI6Rc1msi6ZrTHMN1NwaqcKmKK7IUbW6SGfRqIscAtU8MMoL4J5HmykZBMMZtrfy9NUz2Mf6qmiKH0tMX-eIEQcjqZ1D0yMaEsQ

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private auth: AuthService,
    private aasAuth: AasAuthService,
    private catalog: CatalogService,
    public loadingService: LoadingService
  ) { }

  @Input()
  set data(value) {
    this._data.next(value);
  };

  get data() {
    return this._data.getValue();
  }

  ngOnInit(): void {
    this.makeFormGroup();
    this.makeAutoFormatDate();

    this.options = {
      // containerCssClass: 'select2-lg-custom',
      dropdownAutoWidth: true,
      width: '100%',
      closeOnSelect: true,
      language: "vi"
    };

    this.optionsHuyen = {
      dropdownAutoWidth: true,
      width: '100%',
      closeOnSelect: true,
      language: "vi"
    }

    this.optionsXa = {
      dropdownAutoWidth: true,
      width: '100%',
      closeOnSelect: true,
      language: "vi"
    }

    this.getOldData();
    this.getDmTinh();
  }

  //MARK: Loading
  loadingFormIsVisiable(): Observable<boolean> {
    return this.loadingForm.asObservable().pipe(share());
  }

  //MARK: Khởi tạo form
  makeFormGroup() {
    this.formSubmit = this.fb.group({
      tenDoanhNghiep: new FormControl('', [Validators.required, ValidatorsExtension.validateCompanyName]),
      tenVietTat: new FormControl('', [Validators.required, ValidatorsExtension.validateShortCompanyName]),
      maSoThue: new FormControl('', [Validators.required, Validators.pattern(/^\d{10}-\d{3}$|^\d{10}$/i)]),
      hoVaTen: new FormControl('', [Validators.required, ValidatorsExtension.validateFullName]),
      email: new FormControl('', [Validators.required, Validators.email]),
      sdt: new FormControl('', [Validators.required, ValidatorsExtension.phoneNumber]),
      ngaySinh: new FormControl('', [Validators.pattern(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i)]),
      gender: new FormControl('1', [Validators.required]),

      tinhId: new FormControl(''),
      huyenId: new FormControl(''),
      xaId: new FormControl(''),
      soNha: new FormControl(''),
      duong: new FormControl(''),

      username: new FormControl('', [Validators.required]),
      domain: new FormControl('')
    })
  }

  setValue(data: InfoPartyObject) {
    this.loaded = [false, false, false];

    console.log(this.loaded);
    this.tenDoanhNghiep.setValue(data.tenToChuc);
    this.tenVietTat.setValue(data.tenRutGon);
    this.maSoThue.setValue(data.maSoThue);
    this.hoVaTen.setValue(data.userInformations.ten);
    this.email.setValue(data.userInformations.email);
    this.sdt.setValue(data.userInformations.sdt);
    this.ngaySinh.setValue(DateUtils.convert(data.userInformations.ngaySinh, DateUtils.format.INPUT));
    this.gender.setValue(data.userInformations.gioiTinhId);
    this.tinhId.setValue(data.userInformations.tinhId.toString(), {onlySelf: true, emitEvent: false});
    this.huyenId.setValue(data.userInformations.huyenId.toString(), {onlySelf: true, emitEvent: false});
    this.xaId.setValue(data.userInformations.xaId.toString(), {onlySelf: true, emitEvent: false});
    this.soNha.setValue(data.userInformations.soNha);
    this.duong.setValue(data.userInformations.duong);
    this.username.setValue(data.userInformations.username);
    this.domain.setValue(data.userInformations.domain);

    console.log("setValue => ", this.formSubmit);
  }

  get tenDoanhNghiep() { return this.formSubmit.get('tenDoanhNghiep'); }
  get tenVietTat() { return this.formSubmit.get('tenVietTat'); }
  get maSoThue() { return this.formSubmit.get('maSoThue'); }
  get hoVaTen() { return this.formSubmit.get('hoVaTen'); }
  get email() { return this.formSubmit.get('email'); }
  get sdt() { return this.formSubmit.get('sdt'); }
  get ngaySinh() { return this.formSubmit.get('ngaySinh'); }
  get gender() { return this.formSubmit.get('gender'); }
  get tinhId() { return this.formSubmit.get('tinhId'); }
  get huyenId() { return this.formSubmit.get('huyenId'); }
  get xaId() { return this.formSubmit.get('xaId'); }
  get soNha() { return this.formSubmit.get('soNha'); }
  get duong() { return this.formSubmit.get('duong'); }
  get username() { return this.formSubmit.get('username'); }
  get domain() { return this.formSubmit.get('domain'); }

  //MARK: Chức năng input ngày sinh, ngày cấp giấy tờ
  makeAutoFormatDate() {
    let ngaySinh = this.ngaySinh
    var cleaveNgaySinh = new Cleave('#ngaySinh', {
      date: true,
      // delimiter: '/',
      datePattern: ['d', 'm', 'Y'],
      onValueChanged: function (e) {
        ngaySinh.setValue(e.target.value);
      }
    });
  }

  /**
   * Bắt sự kiện nhập ngày
   * @param e 
   * @param fc 
   */
  dateInput(e, fc) {
    if (e.target.value.length > 10) {
      var str = e.target.value;
      e.target.value = str.substring(0, str.length - 1);
      fc.setValue(e.target.value);
      console.log(e.target.value);
    }
  }

  /**
   * Bắt sự kiện nhập Số điện thoại
   * @param event 
   */
  handleInputSdt(event) {
    const regex = new RegExp(/^\d+$/);
    if (regex.test(event.target.value) && event.target.value.length <= 10) {
      return;
    } else {
      this.sdt.setValue(event.target.value.slice(0, -1));
    }
  }

  //MARK: Chức năng cập nhật dữ liệu profile
  actBtnCancel(e) {
    e.stopPropagation();
    this.isSubmit = false;
    this.resetData();

    // if (this.partyInfo.userInformations.tinhId) this.getDmHuyen(this.partyInfo.userInformations.tinhId);
    // if (this.partyInfo.userInformations.huyenId) this.getDmXa(this.partyInfo.userInformations.huyenId);

    // this.setValue(this.partyInfo);
    this.getOldData();
  }

  resetData() {
    this.formSubmit.reset();
    this.huyenData = [];
    this.xaData = [];

    console.log("resetData => ", this.formSubmit.value);
  }

  actBtnSave(e) {
  }

  /**
   * Action save btn
   * @param e 
   */
  submit(e) {
    // e.stopPropagation();
    this.isSubmit = true;
    console.log(this.formSubmit);

    if (this.formSubmit.valid) {
      this.alert("Bạn chắc chắn muốn lưu thông tin tài khoản?", '', true, '', 'Xác nhận', () => {
        this.postUpdateBusiness(this.partyId, this.makeParam());
      }, '', 'Hủy', () => { });
    }
  }

  makeParam(): any {
    return {
      "daiDien": {
        "username": this.username.value,
        "ten": this.hoVaTen.value,
        "sdt": this.sdt.value,
        "ngaySinh": DateUtils.convertInputToFormat(this.ngaySinh.value, DateUtils.format.API),
        "gioiTinhId": Number(this.gender.value),
        "soNha": this.soNha.value,
        "duong": this.duong.value,
        "xaId": Number(this.xaId.value) || null,
        "huyenId": Number(this.huyenId.value) || null,
        "tinhId": Number(this.tinhId.value) || null
      },
      "toChuc": {
        "tenToChuc": this.tenDoanhNghiep.value,
        "tenRutGon": this.tenVietTat.value
      }
    }
  }

  //MARK: Action chon select2
  tinhChange(event) {
    console.log("tinh => ", event);
    if (event && event !== '') {
      // this.getDmHuyen(event);
      // this.huyenData = [];
      // this.huyenId.setValue('');
      // this.xaData = [];
      // this.xaId.setValue('');

      if (this.loaded[0]) {
        this.getDmHuyen(event);
        this.huyenData = [];
        this.huyenId.setValue('');
        this.xaData = [];
        this.xaId.setValue('');
      } else {
        this.loaded[0] = true;
        this.getDmHuyen(event);
      }
    }
  }

  huyenChange(event) {
    console.log("huyen => ", event);
    if (event && event !== '') {
      // this.getDmXa(event);
      // this.xaData = [];
      // this.xaId.setValue('');

      if (this.loaded[1]) {
        this.getDmXa(event);
        this.xaData = [];
        this.xaId.setValue('');
      } else {
        this.loaded[1] = true;
        this.getDmXa(event);
      }
    }
  }

  xaChange(event) {
    console.log("xa => ", event);
  }

  //MARK: OUTPUT EVENT
  onNext() {
    this.next.emit({
      done: true
    });
  }

  onBack() {
    this.back.emit("ok back");
  }

  //MARK: NETWORKING
  /**
   * Tải dữ liệu cũ
   */
  getOldData() {
    this.loadingForm.next(true);

    this.aasAuth.getPartyInfo(this.partyId).subscribe(res => {
      this.partyInfo = new InfoPartyResponse(res).object;

      if (this.partyInfo.userInformations.tinhId) this.getDmHuyen(this.partyInfo.userInformations.tinhId);
      if (this.partyInfo.userInformations.huyenId) this.getDmXa(this.partyInfo.userInformations.huyenId);

      this.setValue(this.partyInfo);

      this.outputPartyInfo.emit(this.partyInfo);
    })
  }

  getPartyInfo(partyId) {
    this.loadingForm.next(true);
    this.aasAuth.getPartyInfo(partyId).subscribe(res => {
      this.loadingForm.next(true);
      this.partyInfo = new InfoPartyResponse(res).object;
    }, err => {
      this.loadingForm.next(false);
      this.handleError(err, ResponseMsg.MESSAGES.MANAGEMENT_SERVICE);
    }, () => {
      this.loadingForm.next(false);
    })
  }

  postUpdateBusiness(id, param) {
    this.loadingForm.next(true);
    this.aasAuth.postUpdateBusiness(id, param).subscribe(res => {
      this.loadingForm.next(false);
      this.getPartyInfo(this.partyId);
      this.onNext();
      // this.alert('Cập nhật thông tin tổ chức thành công!', 'success');
    }, err => {
      this.loadingForm.next(false);
      if (err.error && err.error.message) {
        switch (err.error.message) {
          case 'ECT-00001200':
            this.email.setErrors({ unique: true });
            break;
          case 'ECT-00001201':
            this.sdt.setErrors({ unique: true });
            break
          case 'ECT-00001202':
            this.username.setErrors({ unique: true });
            break;
          default:
            this.handleError(err, ResponseMsg.MESSAGES.MANAGEMENT_SERVICE);
        }
      }
    }, () => {
      this.loadingForm.next(false);
    })
  }

  getDmTinh() {
    this.loadingForm.next(true);
    this.catalog.getListTinh().subscribe((res) => {
      this.loadingForm.next(false);

      this.dmTinh = new ListTinhResponse(res);
      this.tinhData = this.dmTinh.object.map(item => {
        return { id: item.tinhId.toString(), text: item.tenTinh };
      })
    }, err => {
      this.loadingForm.next(false);
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.MANAGEMENT_SERVICE);
    }, () => {
    });
  }

  getDmHuyen(idTinh) {
    this.loadingForm.next(true);
    this.catalog.getListHuyen(idTinh).subscribe((res) => {
      this.loadingForm.next(false);

      this.dmHuyen = new ListHuyenResponse(res);
      this.huyenData = this.dmHuyen.object.map(item => {
        return { id: item.huyenId.toString(), text: item.tenHuyen };
      })

      console.log(this.huyenData);
    }, err => {
      this.loadingForm.next(false);
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.MANAGEMENT_SERVICE);
    }, () => {
    });
  }

  getDmXa(idHuyen) {
    this.loadingForm.next(true);
    this.catalog.getListXa(idHuyen).subscribe((res) => {
      this.loadingForm.next(false);

      this.dmXa = new ListXaResponse(res);
      this.xaData = this.dmXa.object.map(item => {
        return { id: item.xaId.toString(), text: item.tenXa };
      })
    }, err => {
      this.loadingForm.next(false);
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.MANAGEMENT_SERVICE);
    }, () => {
    });
  }

  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], 'error');
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, 'error');
    } else {
      this.alert('Lỗi không xác định!', 'error');
    }
  }
}
