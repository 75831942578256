<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-auto">
                <h1 *ngIf="userType === 'CONSUMER'" class="m-0 text-dark"><label>{{ 'TrustedPartnerDetail_title_consumer' | translate }}</label></h1>
                <h1 *ngIf="userType === 'BUSINESS'" class="m-0 text-dark"><label>{{ 'TrustedPartnerDetail_title_business' | translate }}</label></h1>
            </div>
            <!-- /.col -->
            <div class="col-md-auto ml-auto">
            </div>
            <!-- <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>

<section class="content">
    <div class="container-fluid">
       <div class="row">
            <div class="col-lg-8">
                <div *ngIf="userType === 'CONSUMER'" class="card shadow-none border-radius-8px">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-auto align-self-end">
                                <label class="text-lg">{{ 'TrustedPartnerDetail_info' | translate }}</label>
                            </div>
                        </div>
                        <hr />
                        <div class="row">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-4">
                                        <p class="text-secondary">{{ 'TrustedPartnerDetail_full_name' | translate }}</p>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partnerConsumer.ten }}
                                    </div>
                                </div>
    
                                <div class="row">
                                    <div class="col-md-4">
                                        <p class="text-secondary">{{ 'TrustedPartnerDetail_id_card' | translate }}</p>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partnerConsumer.cmnd }}
                                    </div>
                                </div>
    
                                <div class="row">
                                    <div class="col-md-4">
                                        <p class="text-secondary">{{ 'TrustedPartnerDetail_birth' | translate }}</p>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partnerConsumer.ngaySinh | date: 'dd/MM/yyy' }}
                                    </div>
                                </div>
    
                                <div class="row">
                                    <div class="col-md-4">
                                        <p class="text-secondary">{{ 'TrustedPartnerDetail_gender' | translate }}</p>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partnerConsumer.gioiTinh }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <p class="text-secondary">{{ 'TrustedPartnerDetail_place_register' | translate }}</p>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partnerConsumer.duong + partnerConsumer.soNha + partnerConsumer.xa + partnerConsumer.huyen + partnerConsumer.tinh }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <p class="text-secondary">{{ 'TrustedPartnerDetail_date_range' | translate }}</p>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partnerConsumer.ngayCap | date: 'dd/MM/yyy' }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <p class="text-secondary">{{ 'TrustedPartnerDetail_issue' | translate }}</p>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partnerConsumer.noiCap }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <p class="text-secondary">{{ 'TrustedPartnerDetail_phone_number' | translate }}</p>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partnerConsumer.sdt }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <p class="text-secondary">{{ 'TrustedPartnerDetail_email' | translate }}</p>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partnerConsumer.email }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <p class="text-secondary">{{ 'TrustedPartnerDetail_identifier_type' | translate }}</p>
                                    </div>
                                    <div class="col-md-8">
                                        Cấp {{ partnerConsumer.identityLevel || '0' }}
                                    </div>
                                </div>
                            </div>
    
                            <div class="col-md-6">
                                <p class="text-secondary">{{ 'TrustedPartnerDetail_photo' | translate }}</p>
                                <div class="row mb-3">
                                    <div *ngIf="imgFront" class="col-md-6">
                                        <img class="img-cmnd" [src]="imgFront" />
                                    </div>
                                    <div *ngIf="imgBack" class="col-md-6">
                                        <img class="img-cmnd" [src]="imgBack" />
                                    </div>
                                </div>
                                
                                <p class="text-secondary">{{ 'TrustedPartnerDetail_face' | translate }}</p>
                                <div class="row mb-3">
                                    <div *ngIf="imgFace" class="col-md-6">
                                        <img class="img-cmnd" [src]="imgFace" />
                                    </div>
                                </div>
                                
                                <div class="row">
                                    <p class="text-secondary">{{ 'TrustedPartnerDetail_esign_photo' | translate }}</p>
                                    <div *ngIf="imgCks" class="col-md-6">
                                        <img class="img-cmnd" [src]="imgCks" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="userType === 'BUSINESS'" class="card shadow-none border-radius-8px">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-auto align-self-end">
                                <label class="text-lg">{{ 'TrustedPartnerDetail_info' | translate }}</label>
                            </div>
                        </div>
                        <hr class="my-3"/>
                        <div class="row">
                            <div class="col-md-6">
                                <label class="text-primary">{{ 'TrustedPartnerDetail_party_info' | translate }}</label>
                                <hr>
                                <div class="row">
                                    <div class="col-md-4">
                                        <p class="text-secondary">{{ 'TrustedPartnerDetail_party_name' | translate }}</p>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partnerBusiness?.tenToChuc }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <p class="text-secondary">{{ 'TrustedPartnerDetail_party_short_name' | translate }}</p>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partnerBusiness?.tenRutGon }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <p class="text-secondary">{{ 'TrustedPartnerDetail_party_tax_code' | translate }}</p>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partnerBusiness?.maSoThue }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <p class="text-secondary">{{ 'TrustedPartnerDetail_party_license' | translate }}</p>
                                    </div>
                                    <div class="col-md-8">
                                        <a class="text-decoration-underline text-primary" style="cursor: pointer;" (click)="actDownloadGPKD(partnerBusiness.userId)">
                                            {{partnerBusiness?.gpkdFilename}}
                                        </a>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <p class="text-secondary">{{ 'TrustedPartnerDetail_party_account' | translate }}</p>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partnerBusiness?.username }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <p class="text-secondary">{{ 'TrustedPartnerDetail_identifier_type' | translate }}</p>
                                    </div>
                                    <div class="col-md-8">
                                        Cấp {{ partnerBusiness?.identityLevel || '0' }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="text-primary">{{ 'TrustedPartnerDetail_agent_info' | translate }}</label>
                                <hr>
                                <div class="row">
                                    <div class="col-md-4">
                                        <p class="text-secondary">{{ 'TrustedPartnerDetail_agent_name' | translate }}</p>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partnerBusiness?.ten }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <p class="text-secondary">{{ 'TrustedPartnerDetail_agent_email' | translate }}</p>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partnerBusiness?.email }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <p class="text-secondary">{{ 'TrustedPartnerDetail_agent_phone' | translate }}</p>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partnerBusiness?.sdt }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <p class="text-secondary">{{ 'TrustedPartnerDetail_agent_birth' | translate }}</p>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partnerBusiness?.ngaySinh | date: 'dd/MM/yyyy' }}
                                    </div>
                                </div>
                                <div *ngIf="partnerBusiness?.gioiTinhId" class="row">
                                    <div class="col-md-4">
                                        <p class="text-secondary">{{ 'TrustedPartnerDetail_agent_gender' | translate }}</p>
                                    </div>
                                    <div class="col-md-8">
                                        {{ (gender[partnerBusiness?.gioiTinhId] | translate) || '' }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <p class="text-secondary">{{ 'TrustedPartnerDetail_agent_address' | translate }}</p>
                                    </div>
                                    <div class="col-md-8">
                                        {{ partnerBusiness?.diaChi || ('ServiceDashboardEnduser_noData' | translate) }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       </div>
    </div>
</section>

<app-preview-gpkd *ngIf="showPreviewGPKD" [file]="fileGPKD" [gpkdName]="partnerBusiness.gpkdFilename" (close)="handleClosePreview()"></app-preview-gpkd>