import { Component, OnInit, ElementRef, ViewChild, Output, Input, AfterViewInit } from '@angular/core';
import { Options, Select2AjaxOptions } from 'select2';
import { Select2OptionData } from 'ng-select2';
import { FormGroup, FormBuilder, FormControl, Validators, Form, NgModel } from '@angular/forms';
import { ValidateMessage, AasValidateMessage } from 'src/app/utils/common/validate-msg-aas';
import { AasContractService } from 'src/app/utils/services/aas-network/aas-contract.service';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { DateUtils } from 'src/app/utils/common/DateUtils';
import { TemplateService } from 'src/app/utils/services/aas-network/template.service';
import { LoadingService } from 'src/app/utils/services/loading.service';
import { Config } from 'src/app/config';
import { AuthService } from 'src/app/utils/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ContractObject } from 'src/app/utils/models/aas-models/contract/contract/contract-object.model';
import { ContractResponse } from 'src/app/utils/models/aas-models/contract/contract/contract-response.model';
import * as ClassicEditor from '../../../../../../../assets/js/editor/ckeditor';
import { EventEmitter } from '@angular/core';
import { InfoPartyObject } from 'src/app/utils/models/aas-models/info-party/info-party-object';
import { ServicePath } from 'src/app/utils/common/constant-service-api';

declare var PDFSign: any;
declare var $: any;
declare var Cleave: any;
@Component({
  selector: 'app-create-contract-approve',
  templateUrl: './create-contract-approve.component.html',
  styleUrls: ['./create-contract-approve.component.scss']
})
export class CreateContractApproveComponent implements OnInit, AfterViewInit {
  @ViewChild('output') output: ElementRef;
  @ViewChild('filename') filename: ElementRef;
  @ViewChild('filebase') filebase: ElementRef;
  @ViewChild('myEditor') myEditor: any;

  @Input('party') partyInfo: InfoPartyObject = new InfoPartyObject();
  @Output('close') close: EventEmitter<any> = new EventEmitter<any>();
  @Output('createdContract') createdContract: EventEmitter<any> = new EventEmitter<any>();

  public Editor = ClassicEditor;
  public exampleData: Array<Select2OptionData> = [];
  public nguoiNhanData: Array<Select2OptionData> = [];
  public hinhThucKyData: Array<Select2OptionData> = [];
  public options: Options;
  public optionsTemplate: Options;
  public optionsHinhThucKy: Options;
  public ajaxOptions: Select2AjaxOptions;

  contractId: string = this.activeRoute.snapshot.paramMap.get('id') || '';

  contract: ContractObject = new ContractObject();

  //mark: nhóm chức năng khởi tạo thông tin hợp đồng
  filePDF: File = null;
  inputFileLabel: string = 'Chọn file';
  fileType: Array<string> = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
  selectedFile: boolean = false;
  fileName: string = '';
  typeSelected: number = 1;

  formContract: FormGroup;
  validContractMsg: any = AasValidateMessage.FORM_CREATE_CONTRACT;
  isSubmitContract: boolean = false;

  hinhThucHienThi = [
    { value: 0, name: "Không hiển thị" },
    { value: 1, name: "Chỉ hiển thị mô tả" },
    { value: 2, name: "Hiển thị mô tả và hình ảnh bên trái" },
    { value: 3, name: "Chỉ hiển thị hình ảnh" },
    { value: 4, name: "Hiển thị mô tả và hình ảnh phía trên" },
    { value: 5, name: "Hiển thị mô tả và hình ảnh dạng hình nền" }
  ]

  selectedAllHinhThucKy: boolean = false;
  hinhThucKy_consumer = [
    { id: 'OTP', text: 'OTP' },
    { id: 'ONE_TIME_CA', text: 'Ký số 1 lần' },
    { id: 'EKYC', text: 'OTP và eKYC' }
  ];

  hinhThucKy_business = [
    { id: 'USB_TOKEN', text: 'Usb-Token' },
    { id: 'SIGN_SERVER', text: 'VNPT ký số' }
  ]

  //Tab controll
  activeTab: string = 'INFO';

  constructor(
    private _location: Location,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private fb: FormBuilder,
    private contractService: AasContractService,
    private authService: AuthService,
    private modalService: NgbModal,
    private loaderSerive: LoadingService,
  ) { }

  ngOnInit(): void {
    this.makeFormContract();

    this.setupSelect2();
    this.makeAutoFormatDate();
    this.makeAutoFormatCost();

    console.log(this.contractId);
    // if (this.contractId !== '') {
    //   this.getContract(this.contractId);
    // }
    console.log(this.partyInfo);

    this.setDatav1();
  }

  ngAfterViewInit() {
    
  }

  //MARK: KHỞI TẠO FORM TẠO HỢP ĐỒNG
  makeFormContract() {
    this.formContract = this.fb.group({
      tenHopDong: new FormControl('', [Validators.required]),
      file: new FormControl(''),
      ngayHieuLuc: new FormControl('', [Validators.required, Validators.pattern(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i)]),
      ngayHetHieuLuc: new FormControl('', [Validators.required, Validators.pattern(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i)]),
      tuGiaHan: new FormControl(true, [Validators.required]),
      giaTriHd: new FormControl('', []),
      nguoiNhan: new FormControl({ value: '', disabled: true}, [Validators.required]),
      loaiKh: new FormControl('ENTERPRISE', [Validators.required]),
      hinhThucKy: new FormControl('', ), //[Validators.required]
      thoiHanKy: new FormControl('', ), //[Validators.required]
      ghiChu: new FormControl('')
    });
  }

  get tenHopDong() { return this.formContract.get('tenHopDong'); }
  get file() { return this.formContract.get('file'); }
  get ngayHieuLuc() { return this.formContract.get('ngayHieuLuc'); }
  get ngayHetHieuLuc() { return this.formContract.get('ngayHetHieuLuc'); }
  get tuGiaHan() { return this.formContract.get('tuGiaHan'); }
  get giaTriHd() { return this.formContract.get('giaTriHd'); }
  get nguoiNhan() { return this.formContract.get('nguoiNhan'); }
  get loaiKh() { return this.formContract.get('loaiKh'); }
  get hinhThucKy() { return this.formContract.get('hinhThucKy'); }
  get thoiHanKy() { return this.formContract.get('thoiHanKy'); }
  get ghiChu() { return this.formContract.get('ghiChu'); }

  //Set data cũ
  setData() {
    this.tenHopDong.setValue(this.contract.title);
    this.ngayHieuLuc.setValue(DateUtils.convert(this.contract.validDate, DateUtils.format.INPUT));
    this.ngayHetHieuLuc.setValue(DateUtils.convert(this.contract.endDate, DateUtils.format.INPUT))
    this.giaTriHd.setValue(this.contract.contractValue);
    this.thoiHanKy.setValue(this.contract.signInDays);
    this.nguoiNhan.setValue(this.contract.aPartyId);
    this.loaiKh.setValue(this.contract.customerType);
    this.hinhThucKy.setValue(this.contract.signForm);
    this.ghiChu.setValue(this.contract.creationNote);
    this.tuGiaHan.setValue(this.contract.autoRenew);

    this.nguoiNhanData = [
      {
        id: this.contract.customer.userId,
        text: this.contract.customer.email,
        additional: {
          partyId: this.contract.customer.userId,
          type_customer: this.contract.customerType === 'CONSUMER' ? 'Cá nhân' : 'Doanh nghiệp',
          type_cutomer_label: this.contract.customerType,
          email: this.contract.customer.email,
        }
      }
    ];
  }

  setDatav1() {
    this.nguoiNhan.setValue(this.partyInfo.partyId);
    this.nguoiNhanData = [
      {
        id: this.partyInfo.partyId,
        text: this.partyInfo.tenToChuc,
        additional: {
          partyId: this.partyInfo.userInformations.userId,
          email: this.partyInfo.email,
          name: this.partyInfo.tenToChuc && this.partyInfo.tenToChuc !== '' ? this.partyInfo.tenToChuc : this.partyInfo.userInformations.ten || '',
          type_customer: this.partyInfo.partyType === 'CONSUMER' ? 'Cá nhân' : this.partyInfo.partyType === 'BUSINESS' ? 'Tổ chức SME' : 'Tổ chức',
          type_cutomer_label: this.partyInfo.partyType,
          sgt: this.partyInfo.maSoThue || this.partyInfo.userInformations.cmnd || ''
        }
      }
    ];

    console.log(this.nguoiNhanData);
  }

  //MARK: CHỨC NĂNG INPUT NGÀY THÁNG NĂM 
  makeAutoFormatDate() {
    let ngayHieuLuc = this.ngayHieuLuc;
    let c_ngayHieuLuc = new Cleave('#ngayHieuLuc', {
      date: true,
      // delimiter: '/',
      datePattern: ['d', 'm', 'Y'],
      onValueChanged: function (e) {
        ngayHieuLuc.setValue(e.target.value);
      }
    });

    let ngayHetHieuLuc = this.ngayHetHieuLuc;
    let c_ngayHetHieuLuc = new Cleave('#ngayHetHieuLuc', {
      date: true,
      // delimiter: '/',
      datePattern: ['d', 'm', 'Y'],
      onValueChanged: function (e) {
        ngayHetHieuLuc.setValue(e.target.value);
      }
    });

    // let thoiHanKy = this.thoiHanKy;
    // let c_thoiHanKy = new Cleave('#thoiHanKy', {
    //   date: true,
    //   // delimiter: '/',
    //   datePattern: ['d', 'm', 'Y'],
    //   onValueChanged: function (e) {
    //     thoiHanKy.setValue(e.target.value);
    //   }
    // });
  }

  makeAutoFormatCost() {
    //Auto format giá trị hợp đồng
    let contractValue = this.formContract.controls.giaTriHd;
    new Cleave('#contractValueInput', {
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
      // onValueChanged: function (e) {
      //   contractValue.setValue(e.target.value)
      // }
    })
  }

  convertCurency(value: any) {
    let curency: any
    value = value.replace(/,/g, '')
    curency = value.replace(/VNĐ/g, '')
    console.log(curency, '==============curency')
    curency = Math.abs(curency);
    return curency;
  }

  //MARK: SETUP SELECT2
  setupSelect2() {
    this.options = {
      dropdownAutoWidth: true,
      width: '100%',
      closeOnSelect: true,
      language: "vi"
    };

    const self = this;
    this.ajaxOptions = {
      url: Config.API_URL + ServicePath.MANAGEMENT_SERVICE +  '/parties/customer-search',
      dataType: 'json',
      type: "GET",
      delay: 250,
      cache: false,
      data: (params: any) => {
        console.log("AA", params);
        return {
          string: params.term
        }
      },
      // processData: false,
      beforeSend: function (xhr) {
        xhr.setRequestHeader('Authorization', `Bearer ${self.authService.getToken()}`);
      },
      processResults: (data: any, params: any) => {
        console.log('data: ', data);
        return {
          results: $.map(data.object, function (item) {
            console.log("item: ", item);
            return {
              id: item.partyId,
              text: item.email,
              additional: {
                partyId: item.userId,
                email: item.email,
                name: item.tenToChuc && item.tenToChuc !== '' ? item.tenToChuc : item.tenDaiDien || '',
                type_customer: item.partyType === 'CONSUMER' ? 'Cá nhân' : 'Doanh nghiệp',
                type_cutomer_label: item.partyType,
                sgt: item.maSoThue || item.cmnd || ''
              }
            };
          })
        };
      },
    };

    this.optionsTemplate = {
      width: '100%',
      dropdownAutoWidth: true,
      templateResult: this.templateResult,
      templateSelection: this.templateSelection,
      closeOnSelect: true,
      language: "vi",
      tags: true,
      createTag: function (params) {
        return {
          id: params.term,
          text: params.term,
          newOption: true
        }
      },
      // ajax: this.ajaxOptions
    };

    this.optionsHinhThucKy = {
      dropdownAutoWidth: true,
      width: '100%',
      multiple: true,
      closeOnSelect: true,
      language: "vi"
    }

    this.hinhThucKyData = this.hinhThucKy_business;

  }

  // function for result template
  public templateResult = (state: Select2OptionData): any => {
    if (!state.id || !state.additional) {
      return state.text;
    }

    let el =
      '<div class="">' +
      ' <div class="select-customer">' +
      '  <span>' + state.text + '</span><br>' +
      '  <span class="select-name-cutomner">' + state.additional.name + '</span><br>' +
      '  <span class="badge badge-light-new p-2">' + state.additional.type_customer + '</span>' +
      '  <span class="badge badge-light-new p-2">' + state.additional.sgt + '</span>' +
      '  <hr  class="mt-2 mb-0" >' +
      ' </div>' +
      '</div>';

    return $(el);
  }

  // function for selection template
  public templateSelection = (state: Select2OptionData): any => {
    if (!state.id) {
      return state.text;
    }

    if (state.additional && state.additional.type_cutomer_label !== '') {
      this.loaiKh.setValue(state.additional.type_cutomer_label);
      // if (state.additional.type_cutomer_label === 'CONSUMER') {
      //   this.hinhThucKyData = this.hinhThucKy_consumer.map(item => {
      //     return item;
      //   });
      // } else if (state.additional.type_cutomer_label === 'BUSINESS' || state.additional.type_cutomer_label === "ENTERPRISE") {
      //   this.hinhThucKyData = this.hinhThucKy_business.map(item => {
      //     return item;
      //   });;
      // }
    }

    return $('<span>' + state.text + '</span>');
  }

  //MARK: CHỨC NĂNG ĐIỀU KHIỂN TAB
  //Action chọn tab
  selectTab(nameTab: string) {
    this.activeTab = nameTab;
  }

  //MARK: THAY ĐỔI GIÁ TRỊ DROPLIST
  changedHinhThucKy(event: Array<any>) {
  }

  //Select all hình thứ ký
  selelectAllHinhThucKy(event) {
    this.selectedAllHinhThucKy = !this.selectedAllHinhThucKy;
    if (this.selectedAllHinhThucKy) {
      if (this.loaiKh.value === 'CONSUMER') {
        let arr: Array<string> = [];
        this.hinhThucKy_consumer.forEach(item => {
          arr.push(item.id);
        })
  
        this.hinhThucKy.setValue(arr);
      } else if (this.loaiKh.value === 'BUSINESS' || this.loaiKh.value === "ENTERPRISE") {
        let arr: Array<string> = [];
        this.hinhThucKy_business.forEach(item => {
          arr.push(item.id);
        })
  
        this.hinhThucKy.setValue(arr);
      }
    } else {
      this.hinhThucKy.setValue([]);
    }
  }


  changedNguoiNhan(event) {
    console.log(event);
  }

  changeLoaiKh(event) {
    if (event.target.value === 'CONSUMER') {
      this.hinhThucKyData = this.hinhThucKy_consumer.map((item) => {
        return item;
      });
    } else if (event.target.value === 'BUSINESS' || event.target.value === 'ENTEPRISE') {
      this.hinhThucKyData = this.hinhThucKy_business.map((item) => {
        return item;
      });
    }

    console.log(this.hinhThucKyData);
  }

  //MARK: NHÓM CHỨC NĂNG SOẠN THẢO HỢP ĐỒNG
  public getEditorContent() {
    console.log(this.myEditor.editorInstance.getData());
  }

  //MARK: NHÓM CHỨC NĂNG KHỞI TẠO HỢP ĐỒNG
  //Lưu hợp đồng
  actBtnSave(event) {
    this.isSubmitContract = true;

    if (this.typeSelected === 1 || this.typeSelected === 3) {
      if (this.filePDF == null || this.filePDF == undefined) {
        this.formContract.get('file').setErrors({ required: true });
      }
  
      console.log(this.formContract.value);
      if (this.formContract.valid && this.filePDF !== null && this.filePDF !== undefined) {
        this.alert('Bạn chắc chắn muốn lưu thông tin hợp đồng?', '', true, '', 'Đồng ý', () => {
          this.saveContract();
        }, '', 'Hủy');
      }
    } else {
      if (this.formContract.valid) {
        this.alert('Bạn chắc chắn muốn lưu thông tin hợp đồng?', '', true, '', 'Đồng ý', () => {
          this.convertHtmlToPdf();
        }, '', 'Hủy');
      }
    }
  }

  //Convert html to pdf
  convertHtmlToPdf() {
    let html = this.myEditor.editorInstance.getData();
    let formData = new FormData();
    formData.append('htmlString', html);

    this.postConvertHTMLToPdf(formData);
  }

  /**
   * Gửi sự kiện output khi tạo hợp đồng thành công
   */
  createdContractSuccess(value = null) {
    this.createdContract.emit(value || {});
  }

  //Call Api save contract
  saveContract() {
    let data = new FormData();
    data.append('title', this.tenHopDong.value || '');
    data.append('validDate', DateUtils.convertInputToFormat(this.ngayHieuLuc.value, DateUtils.format.API) || '');
    data.append('endDate', DateUtils.convertInputToFormat(this.ngayHetHieuLuc.value, DateUtils.format.API) || '');
    data.append('contractValue', this.convertCurency(this.giaTriHd.value || '') || '');
    data.append('signInDays', this.thoiHanKy.value || '');
    data.append('customerIdOrEmail', this.nguoiNhan.value || '');
    data.append('customerType', this.loaiKh.value || '');
    data.append('signForm', JSON.stringify(this.hinhThucKy.value) || '');
    data.append('creationNote', this.ghiChu.value);
    data.append('draftFile', this.filePDF, this.filePDF.name);
    data.append('autoRenew', this.tuGiaHan.value);

    if (this.typeSelected === 3) {
      data.append('validContract', "true");
    }

    if (this.contractId !== '') {
      if (this.checkAllowEdit()) {
        this.postUpdateContract(this.contractId, data);
      } else {
        this.alert('Bạn không có quyền chỉnh sửa hợp đồng này!', 'error');
      }
    } else {
      this.postCreateDraftOnlyFileVip(data);
    }
  }

  actCancelBtn(event) {
    if (this.contractId === '') {
      this.alert('Bạn chắc chắn muốn hủy khởi tạo hợp đồng?', '', true, '', 'Đồng ý', () => {
        this.router.navigate(['/app/console/contract-manager']);
      }, '', 'Hủy');
    } else {
      this.alert('Bạn chắc chắn muốn hủy lưu thay đổi thông tin hợp đồng?', '', true, '', 'Đồng ý', () => {
        this.router.navigate(['/app/console/contract-manager']);
      }, '', 'Hủy');
    }
  }

  //Chọn phương thức upload hợp đồng
  selectType(value) {
    this.typeSelected = value;

    if (value === 3) {
      this.formContract.controls.hinhThucKy.setValue(["USB_TOKEN", "SIGN_SERVER"]);
      this.formContract.controls.thoiHanKy.setValue(30);
    }
  }

  //Chọn file hợp đồng
  handleFileInput(e) {
    this.filePDF = e.target.files && e.target.files.item(0);
    console.log(this.filePDF);
    this.formContract.get('file').setErrors(null);
    if (this.filePDF && this.filePDF !== null && this.filePDF !== undefined) {
      if (this.fileType.includes(this.filePDF.type)) {
        this.inputFileLabel = this.filePDF.name;

        if (this.filePDF.type === 'application/pdf') {
          this.fileName = this.filePDF.name;
          PDFSign.preview(this.filePDF);

          this.getBase64String(this.filePDF, this.handle);
        } else {
          let fileSplit = this.filePDF.name.split('.');
          fileSplit.pop();
          this.fileName = `${fileSplit.join('.')}.pdf`;

          let data = new FormData();
          data.append('attachFile', this.filePDF);
          this.postConvertToPDF(data);
        }

      } else {
        this.formContract.get('file').setErrors({ type: true });
        this.inputFileLabel = this.filePDF.name
      }
    } else {
      // this.fileForm.get('file').setErrors({ required: true });
      this.inputFileLabel = 'Chọn file';
    }
  }

  //Kiểm tra form đã có dữ liệu chưa
  get editedForm() {
    if (this.tenHopDong.value !== '' && this.tenHopDong.value !== null && this.tenHopDong.value !== undefined) return false;
    if (this.ngayHieuLuc.value !== '' && this.ngayHieuLuc.value !== null && this.ngayHieuLuc.value !== undefined) return false;
    if (this.ngayHetHieuLuc.value !== '' && this.ngayHetHieuLuc.value !== null && this.ngayHetHieuLuc.value !== undefined) return false;
    if (this.giaTriHd.value !== '' && this.giaTriHd.value !== null && this.giaTriHd.value !== undefined) return false;
    if (this.thoiHanKy.value !== '' && this.thoiHanKy.value !== null && this.thoiHanKy.value !== undefined) return false;
    if (this.nguoiNhan.value !== '' && this.nguoiNhan.value !== null && this.nguoiNhan.value !== undefined) return false;
    if (this.loaiKh.value !== '' && this.loaiKh.value !== null && this.loaiKh.value !== undefined) return false;
    if (JSON.stringify(this.hinhThucKy.value) !== '' && JSON.stringify(this.hinhThucKy.value) !== '[]' && this.hinhThucKy.value !== null && this.hinhThucKy.value !== undefined) return false;
    if (this.ghiChu.value !== '' && this.ghiChu.value !== null && this.ghiChu.value !== undefined) return false;
    if (JSON.stringify(this.tuGiaHan.value) !== '' && this.tuGiaHan.value !== null && this.tuGiaHan.value !== undefined) return false;
    if (this.filePDF !== null && this.filePDF !== undefined) return false;
    return true;
  }

  getBase64String(blob, callback) {
    var self = this;
    var reader = new FileReader();
    reader.onload = function (e) {
      callback(reader.result, self);
    }

    reader.readAsDataURL(blob);
  }

  //handel cho file pdf
  handle(str, self) {
    $('#fileBase').val(str.split(',')[1]);
  }

  //Handle cho file hình sign server
  handleImgSignServer(str, self) {
    self.imgContent = str;
    PDFSign.setImage(str.split(',')[1]);
  }

  //Kiểm tra quyền cập nhật
  checkAllowEdit() {
    let userId = this.authService.userId;
    let ownerPartyId = this.contract.ownerPartyId;
    let status = this.contract.currentStage;

    for (var i = 0; i < this.contract.signatures.length; i++) {
      if (this.contract.signatures[i].userId === userId) {
        return false;
      }
    }

    if (userId === ownerPartyId && status === 'LC_DRAFT_CREATE') {
      return true;
    }

    return false;
  }

  //Trở về trang trước
  goToBack() {
    this.close.emit({});
    // this._location.back();
    // this.router.navigate(['/app/console/contract-manager']);
  }

  //Tải xuống file hợp đồng
  saveAsContract() {
    if (this.contractId !== '') {
      if (this.contract.currentStage === 'LC_CONTRACT_VALID') {
        this.saveFileContract(this.contractId, 'CONTRACT');
      } else {
        this.saveFileContract(this.contractId, 'DRAFT');
      }
    } else {
      var url = window.URL.createObjectURL(this.filePDF);
      var anchor = document.createElement("a");
      anchor.download = this.fileName;
      anchor.href = url;
      anchor.click();
    }
  }

  /**
   * Disabled trường chọn file hđ nếu phương thức là nhập = tay
   */
  get isDisabledInputFile() {
    return this.typeSelected === 2;
  }

  get isUploadFinalContract() {
    return this.typeSelected === 3;
  }

  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], 'error');
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, 'error');
    } else {
      this.alert('Lỗi không xác định!', 'error');
    }
  }

  //MARK: NETWORKING
  postCreateDraftOnlyFileVip(data: FormData) {
    this.contractService.postCreateDraftOnlyFileVip(data).subscribe((res: any) => {
      this.createdContractSuccess({ contractId: res.object.contractId });
      this.goToBack();
      this.alert('Lưu hợp đồng thành công!', 'success', false, '', 'OK', () => {
        // this.location.replaceState(
        //   this.router.createUrlTree(
        //     ['/app/console/contract', res['object'].contractId],
        //     {
        //       queryParams: {
        //         'contractId': 'asdasd'
        //       }
        //     }
        //   ).toString()
        // );
        // this.router.navigate(['/app/console/contract', res['object'].contractId])
      });
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    }, () => {

    });
  }

  //Convert docx to pdf
  postConvertToPDF(data: FormData) {
    this.contractService.postConvertToPdf(data).subscribe(fileData => {
      const blob: any = new Blob([fileData], { type: 'application/pdf' });
      PDFSign.preview(blob);
      this.filePDF = blob;

      this.getBase64String(blob, this.handle);
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.ESIGN_SERVICE);
    });
  }

  //Tim kiem khach hang
  searchCustomer(key) {
    this.contractService.searchCustomer(key).subscribe(res => {

    }, err => {

    })
  }

  //Lay thong tin hop dong
  getContract(id) {
    this.contractService.getContract(id).subscribe(res => {
      this.contract = new ContractResponse(res).object;
      this.setData();
      this.downloadFileContract(id, 'DRAFT');
      if (!this.checkAllowEdit()) {
        this.alert('Bạn không thể chỉnh sửa hợp đồng này!', 'error');
      }
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    })
  }

  //Tai file hop dong
  downloadFileContract(id, type) {
    this.contractService.downloadFileContract(id, type).subscribe(fileData => {
      const blob: any = new Blob([fileData], { type: 'application/pdf' });
      this.filePDF = blob;
      PDFSign.preview(blob);
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    })
  }

  //Cap nhat hop dong
  postUpdateContract(id, data) {
    this.contractService.postUpdateContract(id, data).subscribe((res: any) => {
      this.createdContractSuccess({ contractId: id });
      this.goToBack();
      this.alert('Lưu hợp đồng thành công!', 'success', false, '', 'OK', () => {
        // this.router.navigate(['/app/console/contract', id])
      });
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    }, () => {

    });
  }

  //Tải file hợp đồng 
  saveFileContract(id, type) {
    this.contractService.downloadFileContract(id, type).subscribe((res) => {
      const blob: any = new Blob([res], { type: 'application/pdf' });
      var url = window.URL.createObjectURL(blob);
      var anchor = document.createElement("a");
      anchor.download = `${id}.pdf`;
      anchor.href = url;
      anchor.click();
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    })
  }

  //convert html sang pdf
  postConvertHTMLToPdf(data) {
    this.contractService.postConvertHTMLToPdf(data).subscribe(res => {
      const blob: any = new Blob([res], { type: 'application/pdf' });
      this.filePDF = blob;
      this.getBase64String(blob, this.handle);
      this.saveContract();
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.ESIGN_SERVICE);
    });
  }

}
