<app-spinner></app-spinner>
<div class="pt-3">
    <section class="content">
        <div class="container-fluid">
            <div class="card shadow-none">
                <div class="card-body padding-md">
                    <h4 class="text-dark" style="text-align: center;"><label>{{ postContent?.title }}</label> </h4>
                    <!-- [innerHTML]="postContent?.description" -->
                    <div class="manual-body" [innerHTML]="postContent?.description">
                    </div>

                </div>
            </div>
        </div>
    </section>
</div>