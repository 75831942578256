import { Injectable } from '@angular/core';
import { ServicePath } from '../../common/constant-service-api';
import { API } from "../api";
const CONTRACT_COMMENT_URL =  ServicePath.COMMENT_SERVICE ;
const UPLOAD_FILE_URL = ServicePath.MANAGEMENT_SERVICE + '/v2/contracts/upload-documents';
const USER_INFO_URL = ServicePath.MANAGEMENT_SERVICE + '/v2/user-informations'
const CONTRACT_INTERNAL_COMMENT_URL =  ServicePath.COMMENT_SERVICE + '/contract';
const DOWNLOAD_FILE_V2 = ServicePath.COMMENT_SERVICE + "/contract";

@Injectable({
    providedIn: 'root'
  })
  
  export class AasCommentService {
  
    constructor(
      private api: API
    ) { }


      // tạo phản hồi
  postContractPost(data) {
    const url = CONTRACT_COMMENT_URL + '/posts/create'
    return this.api.postMultiPart(url, data)
  }

  // tạo trả lời phản hồi
  postContractComment(data) {
    const url = CONTRACT_COMMENT_URL + '/comments/create'
    return this.api.postMultiPart(url, data)
  }

  // cập nhập phản hồi
  postEditPost(data, postId) {
    const url = CONTRACT_COMMENT_URL + '/post' + `/${postId}/edit`
    // return this.api.putMultiPart(url, data)
    return this.api.postMultiPart(url, data)
  }

  // cập nhập trả lời phản hồi
  postEditComment(data, commentId) {
    const url = CONTRACT_COMMENT_URL + '/comment' + `/${commentId}/edit`
    // return this.api.putMultiPart(url, data)
    return this.api.postMultiPart(url, data)
  }

  uploadFileContract(formData) {
    const url = UPLOAD_FILE_URL
    return this.api.uploadFile(url, formData)
  }

  // method API cho phản hồi
  getCommentDetail(contractId) {
    const url = CONTRACT_COMMENT_URL +  `/contract/${contractId}/get-list-post`
    return this.api.get(url)
  }

  downloadDocument(contractId, original, fileKey) {
    const url =
      CONTRACT_COMMENT_URL + `/contract/${contractId}/download-documents?original=${original}&fileKey=${fileKey}`
    return this.api.download(url)
  }

  getUserInfo(userId){
    const url = USER_INFO_URL + `/${userId}`;
    return this.api.get(url)
  }

  /**
   * Get data chat noi bo
   * @param contractId 
   * @param partyId 
   * @returns 
   */
  getPostDataInternal(contractId, partyId) {
    const url = CONTRACT_INTERNAL_COMMENT_URL + `/${contractId}/get-list-internal-post/${partyId}`;
    return this.api.get(url);
  }

  downloadFile_v2(contractId, original, fileKey) {
    const url = DOWNLOAD_FILE_V2 + `/${contractId}/download-attachment?original=${original}&fileKey=${fileKey}`;
    return this.api.download(url)
  }
}