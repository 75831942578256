import { MainResponse } from '../../../main-response.model';
import { ContractObject } from './contract-object.model';

export class ContractResponse extends MainResponse {
    object: ContractObject;

    constructor(data = null) {
        super();
        data = data || {};
        this.object = new ContractObject(data.object);
    } 
}