import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { ConstantAlertBtn, ConstantAlertMsg } from 'src/app/utils/common/constant-alert-msg';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { Input } from '@angular/core';
import { Config } from 'src/app/config';
import { UserTypeConstant } from 'src/app/utils/common/constant';
import { LocationExtension } from 'src/app/utils/common/LocationExtension';
import { REQUEST_OTP } from 'src/app/utils/common/constant-vaildate-msg';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { localize } from 'src/app/utils/localize/localize';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.scss']
})
export class VerifyOTPComponent implements OnInit {

  // id = this.router.snapshot.queryParamMap.get('id');
  // userType = this.router.snapshot.queryParamMap.get('userType');

  @Input('userId') id: string = '';
  @Input('userType') userType: string = '';

  success: boolean = false;

  constructor(
    private router: ActivatedRoute,
    private route: Router,
    private authService: AasAuthService,
    private modalService: NgbModal,
    private myAlert: AlertControl,
    private translate: TranslateService
  ) { }

  formOTP: FormGroup;
  isSubmit = false;
  registerSuccess: boolean = false;
  maxRequestOTP = 3;
  counter$: Observable<number>;
  count = 0;
  ServiceMsg = REQUEST_OTP;



  ngOnInit(): void {
    this.makeSendOTPForm();

  }

  //MARK: Khởi tạo form send email
  makeSendOTPForm() {
    this.formOTP = new FormGroup({
      otp: new FormControl(null, [Validators.required, Validators.minLength(6), Validators.maxLength(6)]),
    });
  }
  get otp() { return this.formOTP.get('otp') }

  //MARK: Action chức năng OTP
  /**
   * Sự kiện nhấn nút xác nhận OTP
   */
  verifyOTP() {
    this.isSubmit = true;
    this.clearSpace();
    if (this.formOTP.valid) {
      switch (this.userType) {
        case UserTypeConstant.CONSUMER:
          this.postVerifyOTP(this.id, this.otp.value);
          break;
        case UserTypeConstant.BUSINESS:
          this.postVerifyEmail(this.id, this.otp.value);
          break;
      }
    }
  }

  /**
   * Sự kiện nhấn nút gửi lại OTP
   */
   resendOTP() {
    this.clearSpace();
    switch (this.userType) {
      case UserTypeConstant.CONSUMER:
        this.postResendOTP(this.id);
        break;
      case UserTypeConstant.BUSINESS:
        this.postResendEmail(this.id);
        break;
    }
  }

  //MARK: Trim value formControl
  clearSpace() {
    let str = `${this.otp.value || ''}`;
    this.otp.setValue(`${str.trim()}`);
  }

  /**
   * Điều kiện để enable nút "Xác nhận"
   */
   get enableBtnSubmit(): boolean {
    return this.otp.invalid;
  }

  /**
   * Bắt sự kiện nhập OTP
   * @param event 
   */
   handleInputOTP(event) {
    const regex = new RegExp(/^\d+$/);
    if (regex.test(event.target.value) && event.target.value.length <= 6) {
      return;
    } else {
      this.otp.setValue(event.target.value.slice(0, -1));
    }
  }

  //MARK: Timout resend
  resetTimeout() {
    this.count = 30;
    this.counter$ = timer(0, 1000).pipe(
      take(this.count),
      map(() => --this.count)
    );
  }

  /**
  * Verify request quên mật khẩu bằng sdt
  * @param id 
  * @param otp 
  */
  postVerifyOTP(id, otp) {
    this.authService.verifyOTP(id, otp).subscribe(res => {
      this.verifyOTPSuccess();
    }, err => {
      console.log(err);
      this.maxRequestOTP--;
      console.log(this.maxRequestOTP);
      this.otp.setErrors({ notFound: true });
      if (this.maxRequestOTP <= 0) {
        this.verifyOTPFailed();
      } else {
        this.myAlert.showAlert(this.translate.instant('VerifyOTP_noti_1', {count: this.maxRequestOTP}), ConstantAlertType.BLANK, false, '', this.translate.instant('btn_close'));
      }
    });
  }

  //MARK: NETWORKING
  /**
   * Verify request quên mật khẩu bằng email
   * @param id 
   * @param otp 
   */
  postVerifyEmail(id, otp) {
    this.authService.verifyEmail(id, otp).subscribe(res => {
      this.verifyOTPSuccess();
    }, err => {
      console.log(err);
      this.maxRequestOTP--;
      console.log(this.maxRequestOTP);
      this.otp.setErrors({ notFound: true });
      if (this.maxRequestOTP <= 0) {
        this.verifyOTPFailed();
      } else {
        this.myAlert.showAlert(this.translate.instant('VerifyOTP_signup_verify_otp_failed', {count: this.maxRequestOTP}), ConstantAlertType.BLANK, false, '', this.translate.instant('btn_close'));
      }
    });
  }


  /**
* Handle verify otp success
*/
  verifyOTPSuccess() {
    this.registerSuccess = true;
    // this.next.emit(this.dataForm);
    this.success = true;
  }

  /**
   * Handle verify otp failed
   */
  verifyOTPFailed() {
    this.myAlert.showAlert(this.translate.instant('VerifyOTP_OTP_VERIFY_MAXIMUM'), ConstantAlertType.ERROR, false, '', this.translate.instant('btn_ok'), () => {
      location.reload();
    });
  }


  /**
   * Gửi lại OTP vào email
   * @param id 
   */
   postResendEmail(id) {
    this.authService.resendEmail(id).subscribe(res => {
      this.resetTimeout();
    }, err => {
      this.myAlert.showErrorHandled(err, ResponseMsg.MESSAGES.REQUEST_OTP);
    }, () => {

    });
  }

  /**
   * Gửi lại OTP vào số đt
   * @param id 
   */
  postResendOTP(id) {
    this.authService.resendOTP(id).subscribe(res => {
      this.resetTimeout();
    }, err => {
      console.log(err);
      this.myAlert.showErrorHandled(err, ResponseMsg.MESSAGES.REQUEST_OTP);
    }, () => {

    });
  }
}
