import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AddDocumentComponent } from './layout/add-document/add-document.component';
import { EditDocumentComponent } from './layout/edit-document/edit-document.component';

@Component({
  selector: 'app-document-in-contract',
  templateUrl: './document-in-contract.component.html',
  styleUrls: ['./document-in-contract.component.scss']
})
export class DocumentInContractComponent implements OnInit {

  // add-document component
  @ViewChild('addDocument', {static: false}) addDocument: AddDocumentComponent;
  // edit-document component
  @ViewChild('editingDocument', {static: false}) editingDocument: EditDocumentComponent;

  // List of document take from service-contract-detail component
  @Input() documents: Array<any> = new Array();
  // Id of contract take from service-contract-detail component
  @Input() contractId: string ="";
  // Check is owner party
  @Input() isOwnerParty: boolean = null;

  // In Tab Document, there are 3 mode (list, add, edit).
  // This is EventEmitter (function at service-contract-detail component)
  // for reset back to list screen (the first screen when click on this tab).
  @Output() resetTabDocument = new EventEmitter();

  // This is EventEmtter to view Edit screen.
  @Output() changeToEdit = new EventEmitter();

  // View detail document
  @Output() viewDetailDocument = new EventEmitter<string>();


  constructor() { }

  // This mode is use to decide which child will be show.
  // There are 3 mode, list, add, and edit.
  mode: string = "list";

  // Prepare to export to edit-document component.
  // This is document that need edit.
  documentDetail: any = null;

  ngOnInit(): void {
    // Default, show list mode.
    this.mode = "list";
  }

  // Change mode to add.
  addNewDocument() {
    this.mode = "add";
  }

  // Change mode to edit, transfer document clicked to edit-document component.
  editDocument(document) {
    this.documentDetail = document;
    this.mode = "edit";
  }

  // Reset to list mode, call by service-contract-detail component.
  resetTab() {
    this.mode = "list"
  }

  // Calling save/cancel in add-document component.
  saveDocument() {
    this.addDocument.save();
  }
  cancelAdd() {
    this.addDocument.cancel();
  }

  // Calling save/cancel in edit-document component.
  saveEditingDocument() {
    this.editingDocument.saveEditedDocument();
  }
  cancelEditing() {
    this.editingDocument.cancel();
  }

  // Reload contract data in service-contract-detail component,
  // and reset TabDocument to list mode.
  // Will be call after edit or add a document.
  parentReload() {
    this.resetTabDocument.emit();
  }

  // Change to edit mode in service-contract-detail from here.
  parentChangeToEdit() {
    this.changeToEdit.emit();
  }

  detailDocument(documentId) {
    this.viewDetailDocument.emit(documentId);
  }
}
