import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { NotePoint } from 'src/app/shared/plugins/note-point.model';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ControlAccount } from 'src/app/utils/common/control-account';
import { DateUtils } from 'src/app/utils/common/DateUtils';
import { ContractObject } from 'src/app/utils/models/aas-models/contract/contract/contract-object.model';
import { VersionContract } from 'src/app/utils/models/aas-models/track-change/version-contract.model';
import { CommentService } from 'src/app/utils/services/aas-network/comment/comment.service';
import { AuthService } from 'src/app/utils/services/auth.service';

declare var $: any;
declare var listPage: any; // List size page from pdf-sign.js
@Component({
  selector: 'app-comment-popup',
  templateUrl: './comment-popup.component.html',
  styleUrls: ['./comment-popup.component.scss']
})
export class CommentPopupComponent implements OnInit {
  @Input()
  set contractId(value) { this._contractId = value; }
  get contractId() { return this._contractId }
  _contractId = "";
  @Input() 
  set contract(value) { this._contract = value; }
  get contract() { return this._contract; }
  _contract: ContractObject = new ContractObject();
  @Input()
  set version(value) { this._version = value; }
  get version() { return this._version }
  _version: VersionContract = null;

  @Output('submitCommentSuccess') submitCommentSuccess: EventEmitter<any> = new EventEmitter(null);
  @Output('submitReplySuccess') submitReplySuccess: EventEmitter<any> = new EventEmitter(null);
  @Output('exit') exit: EventEmitter<any> = new EventEmitter(null);
  @Output('deletedComment') deletedComment: EventEmitter<any> = new EventEmitter(null);
  @Output('deletedReply') deletedReply: EventEmitter<any> = new EventEmitter(null);
  @Output('nextNotePoint') nextNotePoint: EventEmitter<any> = new EventEmitter(null);
  @Output('prevNotePoint') prevNotePoint: EventEmitter<any> = new EventEmitter(null);

  comments: any[] = [];
  isSubmitingComment: BehaviorSubject<any> = new BehaviorSubject<boolean>(false);
  formComment: FormGroup;
  notePoint: NotePoint = null;
  currentUserId = this.auth.userId;

  dateUtil = DateUtils;

  constructor(
    private fb: FormBuilder,
    private commentApi: CommentService,
    private myAlert: AlertControl,
    public auth: AuthService,
    private account: ControlAccount,
    public translate: TranslateService
  ) {
  }

  ngOnInit(): void {
    // this.creatSampleComment();
    this.makeFormComment();
  }

  /**
   * Khởi tạo form bình luận
   */
  makeFormComment() {
    this.formComment = this.fb.group({
      value: ['', Validators.required]
    })
  }

  creatSampleComment() {
    for(var i = 0; i < 10; i++) {
      this.comments.push(i);
    }
  }

  /**
   * Hiển thị popup để nhận, xem bình luận
   * @param e 
   */
  showPopup(e) {
    // console.log("showPopup", e);
    this.formComment.reset();
    this.version = e?.version;
    this.notePoint = e?.notePoint;

    // console.log(this.notePoint);

    var pdf_page = $('#page-pdf-' + e?.pageIndex); 
    var scale = this.getScale('page-pdf-' + e?.pageIndex, e?.pageIndex);
    var offsetX = e?.event?.offsetX || (e?.notePoint?.x ? Math.floor(e?.notePoint?.x * scale) : 0);
    var offsetY = e?.event?.offsetY || (e?.notePoint?.y ? Math.floor(e?.notePoint?.y * scale) : 0);
    var boundX = pdf_page[0].offsetLeft;
    var boundY = pdf_page[0].offsetTop;

    $('.comment-popup').css({
      "left": (offsetX + boundX - 16) + 'px',
      "top": (offsetY + boundY - 16) + 'px',
      'position': 'absolute',
      'display': 'inline-block'
    })
  }

  /**
   * Ẩn popup
   */
  hidePopup() {
    $('.comment-popup').hide();
  }

  /**
   * Gửi comment
   */
  submitComment() {
    if (this.formComment.valid) {
      let data = new FormData();
      data.append("fullName", this.account.userInfo.ten);
      data.append("email", this.account.userInfo.email || this.account.partyInfo.email || "");
      data.append("value", this.formComment.value.value || "");
      data.append("page", this.notePoint?.page);
      data.append("x", "" + this.notePoint?.x);
      data.append("y", "" + this.notePoint?.y);
      data.append("orgId", this.auth.partyId || "");
      data.append("userId", this.auth.userId);

      this.isSubmitingComment.next(true);
      this.commentApi.postCreatPost(this.contractId, this.version.traceId, data).subscribe((res: any) => {
        console.log(res);
        this.submitCommentSuccess.emit(res);
        this.isSubmitingComment.next(false);
      }, err => {
        this.myAlert.showErrorHandled(err);
        this.isSubmitingComment.next(false);
      })
    }
  }

  /**
   * Trả lời bình luận
   */
  submitReplyComment() {
    if (this.formComment.valid) {
      let data = new FormData();
      data.append("fullName", this.account.userInfo.ten);
      data.append("email", this.account.userInfo.email || this.account.partyInfo.email || "");
      data.append("value", this.formComment.value.value || "");
      data.append("orgId", this.auth.partyId || "");
      data.append("userId", this.auth.userId);

      this.isSubmitingComment.next(true);
      this.commentApi.postCreatReply(this.contractId, this.version.traceId, this.notePoint.id, data).subscribe((res: any) => {
        console.log(res);
        this.submitReplySuccess.emit(res);
        this.isSubmitingComment.next(false);
      }, err => {
        this.myAlert.showErrorHandled(err);
        this.isSubmitingComment.next(false);
      })
    }
  }

  /**
   * Kiểm tra đang gửi bình luận chưa
   * @returns 
   */
  isSubmiting(): Observable<boolean> {
    return this.isSubmitingComment.asObservable().pipe(share());
  }

  /**
   * Đóng popup
   */
  exitPopup() {
    $('.comment-popup').hide();
    this.exit.emit(true);
  }

  /**
   * Đến bình luận tiếp theo
   */
  goToNextNotePoint() {
    this.nextNotePoint.emit(true);
  }

  /**
   * Trở về bình luận trước
   */
  goToPrevNotePoint() {
    this.prevNotePoint.emit(true);
  }

  /**
   * Xoá bình luận
   * @param notePoint 
   */
  deletePost(notePoint: NotePoint) {
    let data = {
      userId: this.auth.userId
    }
    this.commentApi.deleltePost(notePoint.contractId, notePoint.traceId, notePoint.id, data).subscribe((res: any) => {
      this.deletedComment.emit(true);
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }

  /**
   * Xoá trả lời bình luận
   * @param reply 
   */
  deleteReply(notePoint, reply: any) {
    let data = {
      userId: this.auth.userId
    }
    this.commentApi.deleteReply(notePoint.contractId, notePoint.traceId, notePoint.id, reply.id, data).subscribe((res: any) => {
      this.deletedReply.emit(true);
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }


  /**
   * Lấy tỉ lệ co giãn theo kích thước thật của file so với hiển thị
   * @param self 
   * @param pageIndex 
   * @returns 
   */
  getScale(self = null, pageIndex) {
    var padding = 40;
    if (self !== null && document.getElementById(self) !== null && document.getElementById(self) !== undefined) {
        var offsetWidth = document.getElementById(self).offsetWidth;
        console.log(listPage[pageIndex - 1]);
        return offsetWidth / listPage[pageIndex - 1].width;
    }
  }
}
