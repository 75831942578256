<form [formGroup]="formSubmit">
    <div class="input-group input-custom mb-4">
        <span class="input-custom-label">Email <span class="text-danger">*</span></span>
        <input appTrimValue appLowercaseValue formControlName="email" type="text"
            class="form-control form-control-lg input-custom-control"
            placeholder="Nhập email" [attr.disabled]="notBack ? true : null" />
        <app-show-validate-errors [isSubmit]="isSubmit"
            [errorMessages]="validateMsg.email" [control]="formSubmit.controls.email"
            [detail]="{ name: 'email' }">
        </app-show-validate-errors>
    </div>
    
    <div class="input-group input-custom mb-4">
        <span class="input-custom-label">{{ 'SignUpConsumer_input_so_dien_thoai' | translate }} <span class="text-danger">*</span></span>
        <input formControlName="sdt" type="text"
            class="form-control form-control-lg input-custom-control" style="position: relative;"
            placeholder="{{ 'SignUpConsumer_input_so_dien_thoai' | translate }}" (input)="handleInputSdt($event)" />
        <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="validateMsg.sdt"
            [control]="formSubmit.controls.sdt" [detail]="{ name: 'sdt' }"></app-show-validate-errors>
    </div>

    <div class="input-group input-custom mb-4">
        <span class="input-custom-label">{{ 'SignUpConsumer_input_tinh_thanh_pho' | translate }}</span>
        <ng-select placeholder="{{ 'SignUpConsumer_input_tinh_thanh_pho' | translate }}" formControlName="tinhId" class="input-custom-control">
            <ng-option *ngFor="let tinh of tinhBindData" [value]="tinh.id">{{ tinh.label }}</ng-option>
        </ng-select>
        <app-show-validate-errors [isSubmit]="isSubmit"
            [errorMessages]="validateMsg.tinhId" [control]="formSubmit.controls.tinhId"
            [detail]="{ name: 'tinhId' }">
        </app-show-validate-errors>
    </div>
    
    <div class="input-group input-custom mb-4">
        <span class="input-custom-label">{{ 'SignUpConsumer_input_ten_tai_khoan' | translate }} <span class="text-danger">*</span></span>
        <input appTrimValue appLowercaseValue formControlName="username" type="text"
            class="form-control form-control-lg input-custom-control"
            placeholder="{{ 'SignUpConsumer_input_ten_tai_khoan' | translate }}" />
        <app-show-validate-errors [isSubmit]="isSubmit"
            [errorMessages]="validateMsg.username" [control]="formSubmit.controls.username"
            [detail]="{ name: 'username' }">
        </app-show-validate-errors>
    </div>
    
    <div class="input-group input-custom mb-4">
        <span class="input-custom-label">{{ 'SignUpConsumer_input_mat_khau' | translate }} <span class="text-danger">*</span></span>
        <input appInputPassword formControlName="password" type="password"
            class="form-control form-control-lg input-custom-control"
            placeholder="{{ 'SignUpConsumer_input_mat_khau' | translate }}" />
        <app-show-validate-errors [isSubmit]="isSubmit"
            [errorMessages]="validateMsg.password" [control]="formSubmit.controls.password"
            [detail]="{ name: 'password' }">
        </app-show-validate-errors>
    </div>
    <div class="row mb-4">
        <div class="col-12">
            <div class="form-check">
                <input [(ngModel)]="checkbox_dieukhoan" [ngModelOptions]="{standalone: true}" class="form-check-input" type="checkbox" value="false" id="dieukhoan_sudung">
                <label class="form-check-label" for="dieukhoan_sudung">
                    {{ 'SignUpConsumer_dong_y_noi_dung' | translate }} <a class="text-primary text-decoration-underline cursor-pointer" (click)="showDieuKhoan(); $event.preventDefault()">{{ 'SignUpConsumer_dieu_khoan_su_dung' | translate }}</a>
                </label>
            </div>
        </div>
    </div>
    <div class="d-flex">
        <a *ngIf="!notBack" class="btn-lg text-primary cursor-pointer flex-grow-1 d-flex align-items-center" (click)="actBack()">
            <span class="material-icons" style="margin-right: 8px">arrow_back</span> {{ 'SignUpConsumer_quay_ve' | translate }}
        </a>
    
        <button class="btn btn-lg btn-primary flex-grow-1" [class.col-md-6]="notBack" [class.ml-auto]="notBack" (click)="actNext()">{{ 'SignUpConsumer_tiep_theo' | translate }}</button>
    </div>
</form>