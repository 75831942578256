import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AasValidateMessage } from 'src/app/utils/common/validate-msg-aas';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { Location } from '@angular/common';
import { NgModalComponent } from 'src/app/layout/extensions/ng-modal/ng-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, timer } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { LocationExtension } from 'src/app/utils/common/LocationExtension';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { ConstantUrl } from 'src/app/utils/common/constant-url';

@Component({
  selector: 'app-service-request-otp',
  templateUrl: './service-request-otp.component.html',
  styleUrls: ['./service-request-otp.component.scss']
})
export class ServiceRequestOtpComponent implements OnInit, AfterViewInit {
  formOTP: FormGroup;
  isSubmit = false;
  ServiceMsg = AasValidateMessage.REQUEST_OTP;

  id = this.activedRoute.snapshot.queryParamMap.get('id') || '';
  user = this.activedRoute.snapshot.queryParamMap.get('user') || '';
  redirectTo = this.activedRoute.snapshot.queryParamMap.get('redirectTo') || '';
  type = this.activedRoute.snapshot.queryParamMap.get('type') || '';
  tokenConfirm = '';

  maxRequestOTP = 3;
  counter$: Observable<number>;
  count = 0;

  // http://localhost:4200/app/request-otp?type=BUSINESS&redirectTo=REGISTERACCOUNT&user=thanhdptx2&id=98d5d840-baf0-4e63-9fb8-0b4b79010446
  //FORGOTPASSWORD
  //REGISTERACCOUNT

  constructor(
    private route: Router,
    private activedRoute: ActivatedRoute,
    private authService: AasAuthService,
    private location: Location,
    private modalService: NgbModal
  ) {
    // this.resetTimeout();
  }

  ngOnInit(): void {
    this.makeSendOTPForm();
  }

  ngAfterViewInit(): void {
    var videos = document.getElementsByTagName("video");
    for (var i = 0; i < videos.length; i++) {
      videos[i].muted = true;
      videos[i].play();
    }
  }

  //MARK: Timout resend
  resetTimeout() {
    this.count = 90;
    this.counter$ = timer(0, 1000).pipe(
      take(this.count),
      map(() => --this.count)
    );
  }

  //MARK: Khởi tạo form send email
  makeSendOTPForm() {
    this.formOTP = new FormGroup({
      otp: new FormControl(null, [Validators.minLength(6), Validators.maxLength(6)]),
    });
  }

  get otp() { return this.formOTP.get('otp') }

  //MARK: Action chức năng OTP
  /**
   * Sự kiện nhấn nút xác nhận OTP
   */
  verifyOTP(event) {
    if (event.target.value.length == 6) {
      if (this.formOTP.valid && this.isSubmit == false) {
        this.isSubmit = true;
        this.clearSpace();
        if (this.redirectTo === 'REGISTERACCOUNT') {
          switch (this.type) {
            case 'CONSUMER':
              this.postVerifyOTP(this.id, this.otp.value);
              break;
            case 'BUSINESS':
              this.postVerifyEmail(this.id, this.otp.value);
              break;
          }
        } else if (this.redirectTo === 'FORGOTPASSWORD') {
          let otp = this.otp.value;
          this.clearInputOTP();
          this.postConfirmOTPForgotPasswordV2(this.user, otp);
        }
      }
    }
  }

  /**
   * Sự kiện nhấn nút gửi lại OTP
   */
  resendOTP() {
    this.clearSpace();
    if (this.redirectTo === 'REGISTERACCOUNT') {
      switch (this.type) {
        case 'CONSUMER':
          this.postResendOTP(this.id);
          break;
        case 'BUSINESS':
          this.postResendEmail(this.id);
          break;
      }
    } else if (this.redirectTo === 'FORGOTPASSWORD') {
      this.postCreateForgotPasswordV2(this.user);
    }
  }

  /**
   * Handle verify otp failed
   */
  verifyOTPFailed() {
    this.alert('Bạn đã nhập sai OTP quá ba lần. Vui lòng thử lại sau!', '', () => {
      switch (this.redirectTo) {
        case 'REGISTERACCOUNT':
          this.route.navigate([ConstantUrl.SIGNUP_V2]);
          break;
        case 'FORGOTPASSWORD':
          this.route.navigate([ConstantUrl.FORGOT_PASSWORD]);
          break;
        default:
          this.route.navigate([ConstantUrl.LOGIN]);
          break
      }
    });
  }

  /**
   * Handle verify otp success
   */
  verifyOTPSuccess() {
    switch (this.redirectTo) {
      case 'REGISTERACCOUNT':
        this.route.navigate([ConstantUrl.SIGNUP_SUCCESS], { queryParams: { 'type': this.type, 'user': this.user } });
        break;
      default:
        this.route.navigate([ConstantUrl.LOGIN]);
        break
    }
  }

  /**
   * Điều kiện để enable nút "Xác nhận"
   */
  get enableBtnSubmit(): boolean {
    return this.otp.invalid;
  }

  /**
   * Bắt sự kiện nhập OTP
   * @param event 
   */
  handleInputOTP(event) {
    const regex = new RegExp(/^\d+$/);
    // if (regex.test(event.target.value) && event.target.value.length <= 6) {
    //   return;
    // } else {
    //   this.otp.setValue(event.target.value.slice(0, -1));
    // }
  }

  clearInputOTP() {
    this.otp.setValue('');
  }

  //MARK: Trim value formControl
  clearSpace() {
    let str = `${this.otp.value || ''}`;
    this.otp.setValue(`${str.trim()}`);
  }

  //MARK: Popup thông báo
  modal(msg, callBack = () => { }) {
    const modalRef = this.modalService.open(NgModalComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.callback = callBack;
  }

  alert(msg, type, callBack = () => { }) {
    const modalRef = this.modalService.open(NgModalComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.notification = true;
    modalRef.componentInstance.callback = callBack;
  }

  //MARK: Networking
  /**
   * Verify request quên mật khẩu bằng sdt
   * @param id 
   * @param otp 
   */
  postVerifyOTP(id, otp) {
    this.authService.verifyOTP(id, otp).subscribe(res => {
      this.verifyOTPSuccess();
    }, err => {
      console.log(err);
      this.maxRequestOTP--;
      console.log(this.maxRequestOTP);
      this.otp.setErrors({ notFound: true });
      if (this.maxRequestOTP <= 0) {
        this.verifyOTPFailed();
      }
    }, () => {

    });
  }

  /**
   * Gửi lại OTP vào số đt
   * @param id 
   */
  postResendOTP(id) {
    this.authService.resendOTP(id).subscribe(res => {
      this.resetTimeout();
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.REQUEST_OTP);
    }, () => {

    });
  }

  /**
   * Verify request quên mật khẩu bằng email
   * @param id 
   * @param otp 
   */
  postVerifyEmail(id, otp) {
    this.authService.verifyEmail(id, otp).subscribe(res => {
      this.verifyOTPSuccess();
    }, err => {
      console.log(err);
      this.maxRequestOTP--;
      console.log(this.maxRequestOTP);
      this.otp.setErrors({ notFound: true });
      if (this.maxRequestOTP <= 0) {
        this.verifyOTPFailed();
      }
    }, () => {

    });
  }

  /**
   * Gửi lại OTP vào email
   * @param id 
   */
  postResendEmail(id) {
    this.authService.resendEmail(id).subscribe(res => {
      this.resetTimeout();
    }, err => {
      this.handleError(err, ResponseMsg.MESSAGES.REQUEST_OTP);
    }, () => {

    });
  }

  // /**
  //  * Confirm OTP
  //  * @param phoneNumber 
  //  * @param otp 
  //  * @param domain 
  //  * @param param 
  //  */
  // postConfirmOTPForgotPassword(phoneNumber, otp, domain = LocationExtension.HOSTNAME, param = null) {
  //   this.authService.postConfirmForgotPassword(phoneNumber, otp, domain, param).subscribe(res => {
  //     this.route.navigate(['/app/renew-password'], { queryParams: { 'phoneNumber': this.user, 'token': res['object']['confirm-token'] } });
  //   }, err => {
  //     if (err.error.message && err.error.message === 'ECT-00001103') {
  //       this.otp.setErrors({ notFound: true });
  //       this.maxRequestOTP--;
  //       if (this.maxRequestOTP <= 0) {
  //         this.verifyOTPFailed();
  //       }
  //     } else {
  //       this.handleError(err, ResponseMsg.MESSAGES.REQUEST_OTP);
  //     }
  //   }, () => {

  //   });
  // }

  /**
   * Confirm OTP V2
   * @param email 
   * @param otp 
   * @param domain 
   */
  postConfirmOTPForgotPasswordV2(email, otp, domain = LocationExtension.HOSTNAME) {
    this.authService.postConfirmForgotPasswordV2(email, otp, domain).subscribe((res: any) => {
      this.route.navigate(['/app/renew-password'], { queryParams: { 'email': email, 'token': res['object']['confirmationToken'] } });
    }, err => {
      if (err.error.message && err.error.message === 'ECT-00001103') {
        this.otp.setErrors({ notFound: true });
        this.maxRequestOTP--;
        if (this.maxRequestOTP <= 0) {
          this.verifyOTPFailed();
        }
      } else {
        this.handleError(err, ResponseMsg.MESSAGES.REQUEST_OTP);
      }
      this.isSubmit = false;
    }, () => {

    });
  }

  // /**
  //  * Tạo yêu cầu quên mật khẩu
  //  * @param type 
  //  * @param user 
  //  * @param domain 
  //  */
  // postCreateForgotPassword(type, user, domain = LocationExtension.HOSTNAME) {
  //   this.authService.postCreateForgotPassword(type, user, domain).subscribe(res => {
  //     this.resetTimeout();
  //   }, err => {
  //     console.log(err);
  //     this.handleError(err, ResponseMsg.MESSAGES.FORGOT_PASSWORD);
  //   }, () => {

  //   });
  // }

  /**
   * Tạo yêu cầu quên mật khẩu V2
   * @param type 
   * @param user 
   * @param domain 
   */
  postCreateForgotPasswordV2(email, domain = LocationExtension.HOSTNAME) {
    this.authService.postCreateForgotPasswordV2(email, domain).subscribe(res => {
      this.resetTimeout();
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.FORGOT_PASSWORD);
    }, () => {

    });
  }

  //MARK: Popup thông báo
  customAlert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], 'error');
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, 'error');
    } else {
      this.alert('Đã có lỗi xảy ra! Vui lòng thử lại!', 'error');
    }
  }
}
