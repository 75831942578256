import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { Employee } from 'src/app/utils/models/employee/employee.model';
import { PermissionGroup } from 'src/app/utils/models/user-manager/list-all-permission/permission-group';
import { UserGroup } from 'src/app/utils/models/user-manager/user-group/user-group.model';
import { EmployeeService } from 'src/app/utils/services/aas-network/employee/employee.service';
import { PartService } from 'src/app/utils/services/aas-network/part/part.service';
import { UserManagerService } from 'src/app/utils/services/aas-network/user-manager.service';

@Component({
  selector: 'app-employee-detail',
  templateUrl: './employee-detail.component.html',
  styleUrls: ['./employee-detail.component.scss']
})
export class EmployeeDetailComponent implements OnInit {

  id = this.activedRoute.snapshot.paramMap.get('id');
  employee: Employee = new Employee();

  privileges: PermissionGroup[] = [];

  constructor(
    private router: Router,
    private activedRoute: ActivatedRoute,
    private partService: PartService,
    private employeeService: EmployeeService,
    private userManagerService: UserManagerService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.getEmployee(this.id);
  }

  update(id) {
    this.router.navigate([ConstantUrl.EMPLOYEE_EDIT, id]);
  }

  getClassStatus(status) {
    // var st = `${status}`;
    var st = status;

    if (st === true) return "badge-danger-new";
    if (st === false) return "badge-success-new";
    return "";
  }

  getTextStatus(status) {
    // var st = `${status}`;
    var st = status;

    if (st === true) return this.translate.instant('status_stopped');
    if (st === false) return this.translate.instant('status_running');
    return "";
  }

  showListEmployee(event, id) { 
    event.stopPropagation();
    
  }

  //MARK: NETWORK
  getEmployee(id) {
    this.employeeService.getEmployee(id).subscribe((res: any) => {
      this.employee = new Employee(res?.object?.data);
      this.getPrivilegesUserGroup(this.employee.roleId);
    })
  }

  /**
   * get
   * @param id 
   */
  getPrivilegesUserGroup(id) {
    var data: UserGroup;
    this.userManagerService.getPrivilegesUserGroupV2(id).subscribe((res: any) => {
      data = new UserGroup(res.object);
      this.privileges = data.privileges;
    }, err => {
    }, () => {
    });
  }
}
