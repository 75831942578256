import { Component, OnInit,ViewChild,ElementRef,AfterViewInit } from '@angular/core';
import { Options } from 'select2';
import { Select2OptionData } from 'ng-select2';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import {
  map,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap
} from "rxjs/operators";
import { DateUtils } from 'src/app/utils/common/DateUtils';
import { AasEinvoicesService } from 'src/app/utils/services/aas-network/aas-einvoices.service';
import { EinvoicesItem } from 'src/app/utils/models/aas-models/einvoices-manager/einvoices-list/einvoices-item.model';
import { ListEinvoicesResponse } from 'src/app/utils/models/aas-models/einvoices-manager/einvoices-list/einvoices-list-response';
import { ListStatusResponse } from 'src/app/utils/models/aas-models/einvoices-manager/einvoices-payment-state/payment-status-list-response.model'
import { StatusItem } from 'src/app/utils/models/aas-models/einvoices-manager/einvoices-payment-state/payment-status-object.model'
import { BehaviorSubject, from, Observable } from 'rxjs';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { cpuUsage } from 'process';

declare var Cleave: any;
declare var $: any;

@Component({
  selector: 'app-service-einvoices-list',
  templateUrl: './service-einvoices-list.component.html',
  styleUrls: ['./service-einvoices-list.component.scss']
})
export class ServiceEinvoicesListComponent implements OnInit {
  @ViewChild("searchFieldEinvoices") searchField: ElementRef;
  @ViewChild('statusPicker') _statusPicker: ElementRef; 
  @ViewChild('sendEmail') sendEmail: ElementRef;
  @ViewChild('tuNgayPhatHanh') _fromDate: ElementRef; 
  @ViewChild('denNgayPhatHanh') _toDate: ElementRef; 

  listConfigColTable: Array<any> = [
    {
      label: "Số hóa đơn",
      name: "soHoaDon"
    },
    {
      label: "Ký hiệu",
      name: "kyHieu"
    },

    {
      label: "Tên khách hàng",
      name: "tenKhachHang"
    },
    {
      label: "Mã số thuế" ,
      name: "maSoThue"
    },
    {
      label:  "Địa chỉ",
      name: "diaChi"
    },
    {
      label: "Ngày phát hành",
      name: "ngayPhatHanh"
    },
    {
      label: "Tổng tiền",
      name: "tongTien"
    },
    {
      label: "Trạng thái",
      name: "trangThai"
    },
  ];

  configColTable: any = {
    soHoaDon: true,
    kyHieu: true,
    tenKhachHang: true,
    maSoThue: true,
    diaChi: true,
    ngayPhatHanh: true,
    tongTien: true,
    trangThai: true
  }

  

  paymentStatus = {
    CREATED: {
      style: 'badge-success-new',
      label: 'Chưa thanh toán',
    },
    PAID: {
      style: 'badge-danger-new',
      label: 'Đã thanh toán',
    },
  }

  page: number = 1;
  maxSize: number = 10;
  sort: string = "DESC";
  propertiesSort: string = "modifiedOn";
  totalElement: number = 0;

  public options: Options;
  formFilter: FormGroup;
  searchFields: FormControl;
  formEmail : FormGroup;

  saveKeySearch = '';
  keySearch = "";
  formSearch: FormGroup; 
  fromDate: string = '';
  toDate: string = '';
  statusFilter: string = 'ALL';

  listEinvoices :  EinvoicesItem[] = [];
  listEinvoicesRes : ListEinvoicesResponse;

  statusData:  Observable<Array<Select2OptionData>>;

  listStatusRes : ListStatusResponse
  statusItem: StatusItem

  private results ;

  
 
  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private einvoicesService: AasEinvoicesService,
  ) { }

  ngOnInit(): void {
    this.makeFormFilter()
    this.makeAutoFormatDate();
    this.makeFormEmail();
    this.toDate = '';
    this.fromDate  = '';
    this.statusFilter = '';
    this.searchFields = new FormControl();
    this.getListStatus()
    this.getEinvoiceList();

    this.results = this.searchFields.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(_ => (this.search(this.searchField.nativeElement.value))),
    ).subscribe();
    this.options = {
      multiple: false,
      width: '100%',
      closeOnSelect: true,
      language: 'vi',
    };
  }



  makeFormFilter() {
    this.formFilter = this.fb.group({
      tuNgayPhatHanh : [''],
      denNgayPhatHanh: [''],
      status: ["ALL"],
    })
    this.status.setValue('ALL')
  }

  makeFormEmail(){
    this.formEmail = this.fb.group({
      email : ['', [Validators.required,Validators.email ]]
    })
  }

  get tuNgayPhatHanh() {
    return this.formFilter.get('tuNgayPhatHanh');
  }
  get denNgayPhatHanh() {
    return this.formFilter.get('denNgayPhatHanh');
  }
  get status() {
    return this.formFilter.get('status');
  }
  get email(){
    return this.formEmail.get('email')
  }

  invoiceId = '';
  showPopupMail(email,id){
    this.invoiceId = id;
    this.email.setValue(email);
    this.openModalConfirmEmail(this.sendEmail)
  }

  btnClickResendEmail(email){
    let data = {
      email: email
    }
    this.einvoicesService.postSendEmail(this.invoiceId, data).subscribe(res=>{
      this.modalService.dismissAll();
      this.alert("Gửi thông báo thành công!", 'success');
    }, err => {
      console.log(err);
      this.handleError(err, err);
    })
  }

  updateStatusEinvoices(item){
    let param = {
      uuidInvoice : item.uuid,
      paymentStatus: item.trangThai === 'CREATED'? "PAID":"CREATED",
      note: ""
    }
    this.alert('Bạn có chắc chắn muốn thay đổi trạng thái của hóa đơn này', '', true, '', 'Đồng ý', () =>{
      this.einvoicesService.putStatusEinvoices(param, item.uuid).subscribe(res=>{
        console.log(res)
        this.alert("Cập nhập thành công trạng thái!",'success', false, '', 'OK', ()=>{
          this.getEinvoiceList()
        })
      })
    } )
    
  }


   //MARK: Khởi tạo form search
   makeFormSearch() {
    this.formSearch = this.fb.group({
      search: new FormControl('')
    });
  }

  // get danh sachs hoa donw
  getEinvoiceList(){
    this.einvoicesService.getListEinvoices(this.page, this.maxSize, this.sort, this.propertiesSort,  DateUtils.convertStringtoDateSearch(this.fromDate),
      DateUtils.convertStringtoDateSearch(this.toDate) , this.keySearch, this.statusFilter).subscribe((res: any)=>{
      this.listEinvoicesRes = new ListEinvoicesResponse(res['object']);
      this.listEinvoices = this.listEinvoicesRes.data;
      this. page = this.listEinvoicesRes.page;
      this.maxSize = this.listEinvoicesRes.maxSize;
      this.totalElement = this.listEinvoicesRes.totalElement
    })

  }

  getListStatus(){
    this.einvoicesService.getListStatusEinvoices().subscribe((res:any)=>{
      this.listStatusRes = new ListStatusResponse(res);
      this.statusData = this.makeStatusData();
    })
  }

  makeStatusData(): Observable<Array<Select2OptionData>> {
    let data: Array<Select2OptionData> = [];
    if (this.listStatusRes.data && this.listStatusRes.data instanceof Array) {
      this.listStatusRes.data.forEach((item) => {
        data.push({ id: item.key.toString(), text: item.name });
      });
    }
    return Observable.create((obs) => {
      obs.next(data);
      obs.complete();
    });
  }


  //Show popup confirm OTP
  openModalConfirmEmail(content) {
    this.modalService.open(content, {
      size: 'md',
      backdrop: 'static',
      keyboard: false
    });
  }

  
  inputKeySearch(e) {
    this.saveKeySearch = e.target.value;
  }

  search(keyWord) {
    this.page = 1;
    this.keySearch = keyWord.trim();
    this.getEinvoiceList();
  }

  cleanFilter(){
    this.makeFormFilter();
    this.toDate = '';
    this.fromDate  = '';
    this._fromDate.nativeElement.value = '';
    this._toDate.nativeElement.value = '';
    this.tuNgayPhatHanh.setValue("")
    this.denNgayPhatHanh.setValue("")
    this.keySearch = '';
    this.saveKeySearch = this.keySearch;
    this.searchFields.reset();
    this.page = 1;
    // this.statusFilter = '';
    // this._statusPicker.nativeElement.value = ''
    this.status.setValue("ALL")
    this.searchWithFilter();
    this.getEinvoiceList()
  }
  searchWithFilter(){
    this.statusFilter = this.formFilter.value.status;
    this.fromDate = this._fromDate.nativeElement.value;
    this.toDate = this._toDate.nativeElement.value;
    this.getEinvoiceList()
    
  }
  loadPage(page){
    this.page = page;
    this.getEinvoiceList()
  }

  changeDate(event, type) {
    if (type == 'to') this.toDate = DateUtils.convertObjtoDate(event);
    else this.fromDate = DateUtils.convertObjtoDate(event);
  }

  changeStatus(e) {
    this.status.setValue(e)
  }


   //MARK: Action cấu hình hiển thị cột của bảng
  actConfigColTableView(e, field) {
    e.stopPropagation();
    if (this.configColTable[field] !== null && this.configColTable[field] !== undefined) {
      this.configColTable[field] = !this.configColTable[field];
    }
  }

  downloadFile(cusCode, fkey) {
    this.einvoicesService.downloadEinvoices(cusCode, fkey).subscribe((res:any) => {
      const blob: any = new Blob([res], { type: 'application/pdf' });
      var url = window.URL.createObjectURL(blob);
      var anchor = document.createElement("a");
      anchor.download = `${fkey}.pdf`;
      anchor.href = url;
      anchor.click();
    }, err => {
      console.log(err);
      // this.handleError(err, ResponseMsg.MESSAGES.ALL_IN_ONE);
    })
  }
 

  //MARK: Make input date format
  makeAutoFormatDate() {
    let self = this;
    new Cleave('#tuNgayPhatHanh', {
      date: true,
      delimiter: '/',
      datePattern: ['d', 'm', 'Y'],
      onValueChanged: function(e) {
        self.fromDate = e.target.value;
        self.tuNgayPhatHanh.setValue(e.target.value);
      }
    });

    new Cleave('#denNgayPhatHanh', {
      date: true,
      delimiter: '/',
      datePattern: ['d', 'm', 'Y'],
      onValueChanged: function(e) {
        self.toDate = e.target.value;
        self.denNgayPhatHanh.setValue(e.target.value);
      }
    });
  }


  dateInput(e, type) {
    if (e.target.value.length > 10) {
      var str = e.target.value;
      e.target.value = str.substring(0, str.length - 1);
      if (type === 'tuNgayPhatHanh') {
        this.fromDate = e.target.value;
        this.tuNgayPhatHanh.setValue(e.target.value);
      } else if (type === 'denNgayPhatHanh') {
        this.toDate = e.target.value;
        this.denNgayPhatHanh.setValue(e.target.value);
      }
    } else {
      if (type === 'tuNgayPhatHanh') {
        this.fromDate = e.target.value;
        this.tuNgayPhatHanh.setValue(e.target.value);
      } else if (type === 'denNgayPhatHanh') {
        this.toDate = e.target.value;
        this.denNgayPhatHanh.setValue(e.target.value);
      }
    }
  }

  convertToDateObj(dataString) {
    let d = new Date(dataString);
    let localTiming = d.toLocaleDateString('en-GB');
    return localTiming;
  }

  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], 'error');
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, 'error');
    } else {
      this.alert('Lỗi không xác định!', 'error');
    }
  }


}
