import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { catchError } from 'rxjs/operators';
import { API } from './../api';
import { HttpHeaders, HttpClient, HttpRequest } from '@angular/common/http';
import { throwError } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { Constant } from './../../../utils/common/constant_videocall';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { ServicePath } from '../../common/constant-service-api';

const base_url = 'https://explorer.idg.vnpt.vn/router-service/api/';
const USER_LIST_URL = ServicePath.MANAGEMENT_SERVICE + '/user-informations';
const TOKEN =
    'bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOlsicmVzdHNlcnZpY2UiXSwidXNlcl9uYW1lIjoiZG9hbnBoYW50aGFuaDEzMDVAZ21haWwuY29tIiwiYXV0aG9yaXRpZXMiOlsiVVNFUiJdLCJqdGkiOiI3OTEzNjRjZi01OTFjLTQ2YmYtOGQ0Ny0yOTYzOTdiYWQ1NTQiLCJjbGllbnRfaWQiOiJhZG1pbmFwcCIsInNjb3BlIjpbInJlYWQiXX0.6EwQhluXY9ak1SLEqAvLxnFtqzBUXl-Dz3AdgIBuwmZwvQwTijm3GNbXmDvv0dAilk1_BUzKJDGyb8uvjgEv63NbCt22m04ox2RfejfiOO6_I7GqjD8HaQKYV8NyinluxObECBV7pUHzSY9oo9XtER1p0zoG5p4CdB2W_3rd1i-uxkSiKrOK_ifx4UPHxMP-2owrtPb9mK3ds-mfvO7bE_AMSrpykA35yXafPwOYOcgbnUWUSmVlhasqSDyan4EQHJ66l477P2uQXpA8sEF_oV3vfJ9daJlOyVVmoal2KuoU1pUGlG0lhdJJGmjxkGyXnyaKUxz58PSHZ_mJzq9qTw';
const TOKEN_ID = 'aaa17433-e3c2-064f-e053-604fc10aab28';
const TOKEN_KEY =
    'MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAMlGceImXOf3AUE800nc/NfpIBNHT4nR1FaRSFADcyyMyXI8P9N8QWTsQnS5QrOGr5Sp2tP22xOfk+dpyRhlNzsCAwEAAQ==';

@Injectable({
    providedIn: 'root',
})
export class AasConsumerManagerService {
    currentMessage = new BehaviorSubject(null);
    // reloadData = new BehaviorSubject(null);

    constructor(
        private api: API,
        private angularFireMessaging: AngularFireMessaging,
        private db: AngularFireDatabase,
        private afAuth: AngularFireAuth,
        private http: HttpClient
    ) {
    }

    //MARK: FIREBASE
    // receiveMessage() {
    //     this.angularFireMessaging.messages.subscribe((payload) => {
    //         console.log('new message received. ', payload);
    //         this.currentMessage.next(payload);
    //     });
    // }

    // async requestPermission(uuid) {
    //     this.angularFireMessaging.requestToken.subscribe(
    //         async (token) => {
    //             console.log('token firebase', token);
    //             await this.registerDevice(token, uuid);
    //         },
    //         (err) => {
    //             console.error('Unable to get permission to notify.', err);
    //         }
    //     );
    // }


    getListAccountAM(id, verificationStatus, searchString, maxSize, page, sort = "DESC", propertiesSort = 'created') {
        const url =
            USER_LIST_URL +
            `/${id}/registration-list?verificationStatus=${verificationStatus}&maxSize=${maxSize}&searchString=${searchString}&page=${page}&sort=${sort}&propertiesSort=${propertiesSort}`;
        return this.api.get(url);
    }

    getDetailUser(id) {
        const url = ServicePath.MANAGEMENT_SERVICE + '/v2/user-informations' + `/${id}`;
        return this.api.get(url);
    }

    rejectRegistration(id, reason) {
        const url = USER_LIST_URL + `/${id}/registration-reject`;
        return this.api.post(url, reason);
    }

    downloadImage(id, type) {
        const url = USER_LIST_URL + `/${id}/download-asset?assetType=${type}`;
        return this.api.download(url);
    }

    approveRegistration(id) {
        const url = USER_LIST_URL + `/${id}/registration-approve`;
        return this.api.post(url, null);
    }

    saveCustomerId(id) {
        const url = USER_LIST_URL + `/${id}/videocall-save-customer-id`;
        return this.api.post(url, { customerId: id });
    }

    removeLocalStorage() {
        localStorage.removeItem(Constant.ROOM_INFO);
    }

    getDeviceIdByCustomerId(uuidCustomer) {
        // const userInfo = JSON.parse(
        //     localStorage.getItem(Constant.UNIT_INFO)
        // )[0];
        return this.http
            .get(
                base_url + 'get-one/' + TOKEN_ID + '/' + uuidCustomer,
                this.setHeader()
            )
            .pipe(catchError(this.formatErrors));
    }

    endCall(data) {
        return this.http
            .post(base_url + 'end-call', data)
            .pipe(catchError(this.formatErrors));
    }

    uploadFile(body, uuid) {
        const url = USER_LIST_URL + `/${uuid}/upload-asset?assetType=CHANDUNG`;
        return this.api.postMultiPart(url, body);
    }

    acceptCall(data) {
        console.log('params', data);
        const deviceIds = data.deviceIds.split(';');
        const param = {
            deviceId: localStorage.getItem(Constant.UUID),
            deviceIds: deviceIds,
            idgTokenId: TOKEN_ID,
            roomId: data.roomId,
        };
        return this.http
            .post(base_url + 'accept-call', param)
            .pipe(catchError(this.formatErrors));
    }

    rejectCall(data) {
        console.log('params', data);
        const deviceIds = data.deviceIds.split(';');
        const param = {
            deviceId: localStorage.getItem(Constant.UUID),
            deviceIds: deviceIds,
            idgTokenId: TOKEN_ID,
            roomId: data.roomId,
        };
        return this.http
            .post(base_url + 'reject-call', param)
            .pipe(catchError(this.formatErrors));
    }

    createCall(detailUser, deviceIds, caller) {
        const param = {
            callerName: caller,
            deviceIdSrc: localStorage.getItem(Constant.UUID),
            deviceIds: deviceIds,
            idgTokenId: TOKEN_ID,
            recevicerName: detailUser.ten,
        };
        return this.http
            .post(base_url + 'create-call', param)
            .pipe(catchError(this.formatErrors));
    }

    registerDevice(token: any, econtract_uuid: any) {
        console.log('uuid', localStorage.getItem(Constant.UUID));
        const body = {
            // deviceId: idUser,
            deviceId: localStorage.getItem(Constant.UUID),
            deviceToken: token,
            deviceTypeId: 'WEB',
            idgTokenId: TOKEN_ID,
            lastDate: new Date(),
            persionIdApp: econtract_uuid,
            topicUsing: 'string',
        };
        this.saveCustomerId(econtract_uuid).subscribe((res) => {
            console.log(res);
        });
        return this.http
            .post(base_url + 'register-device', body)
            .toPromise();
    }

    // async registerDevice(token: any, econtract_uuid: any) {
    //     console.log('uuid', localStorage.getItem(Constant.UUID));
    //     const body = {
    //         // deviceId: idUser,
    //         deviceId: localStorage.getItem(Constant.UUID),
    //         deviceToken: token,
    //         deviceTypeId: 'WEB',
    //         idgTokenId: TOKEN_ID,
    //         lastDate: new Date(),
    //         persionIdApp: econtract_uuid,
    //         topicUsing: 'string',
    //     };
    //     this.saveCustomerId(econtract_uuid).subscribe((res) => {
    //         console.log(res);
    //     });
    //     return await this.http
    //         .post(base_url + 'register-device', body)
    //         .toPromise();
    // }

    removeDevice() {
        console.log('uuid', localStorage.getItem(Constant.UUID));
        let param = {
            deviceId: localStorage.getItem(Constant.UUID),
            idgTokenId: TOKEN_ID
        }
        const req = new HttpRequest('DELETE', base_url + 'remove-device', param);
        return this.http.request(req);
    }

    setCurrentMessage(data: any) {
        this.currentMessage.next(data);
        // return this.responseData = value;
    }

    // setReloadData(value) {
    //     console.log(value);
    //     this.reloadData.next(value);
    // }

    setHeader() {
        // const userInfo = JSON.parse(
        //     localStorage.getItem(Constant.UNIT_INFO)
        // )[0];
        const header = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json',
                'Token-id': TOKEN_ID,
                'Token-key': TOKEN_KEY,
                'mac-address': 'WEB-001',
                Authorization: TOKEN,
            }),
        };
        return header;
    }

    private formatErrors(error: any) {
        if (error.error instanceof ErrorEvent) {
            console.error('An error occurred:', error.error.message);
        } else {
            console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
        }
        
        return throwError(error);
    }
}
