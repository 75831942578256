<app-spinner></app-spinner>
<input #output id="output" class="" value="" type="hidden">
<input #filebase id="fileBase" class="" type="hidden">
<input #filename id="fileName" class="" value="{{ fileName }}">
<div class="wrapper">
    <div class="preview-overlay">

        <div class="preview-container">
            <div class="preview-header d-flex">
                <div (click)="goToBack()"
                    class="ct-header-btn-back d-flex justify-content-center align-items-center cursor-pointer">
                    <span class="material-icons">arrow_back_ios</span>
                </div>
                <div class="ct-title flex-grow-1 d-flex align-items-center px-3">
                    <h4 class="mb-0">{{ contract.title || 'ServiceContractDetail_econtract' | translate }}</h4>
                </div>
                <div class="ct-toolbar-btn d-flex align-items-center">
                    <div *ngIf="activeTab == 'INFO'" class="d-flex align-items-center">
                        <!-- <button (click)="saveContract()"
                        class="btn btn-outline-primary d-flex align-items-center mr-3"><span
                            class="material-icons mr-2">get_app</span> Tải xuống</button> -->

                        <button 
                            *ngIf="!hideDownloadBtn()"
                            (click)="getFileContract(contract.contractId, contract.currentStage)"
                            class="btn btn-outline-primary d-flex align-items-center mr-3">
                            <span class="material-icons-outlined mr-2">download</span>
                            {{'ServiceContractDetail_btn_download' | translate}}
                        </button>
                        
                        <button *ngIf="!hasDecode(contract.contractId) && contract.currentStage === 'LC_CONTRACT_VALID' && !controlAccount.userInfo.isViewer" 
                            (click)="turnOnEncode(contract.contractId)"
                            class="btn btn-primary d-flex align-items-center mr-3">
                            <span class="material-icons-outlined mr-2">line_style</span>
                            {{'ServiceContractDetail_btn_decode_contract' | translate}}
                        </button>
                        <!-- <button class="btn btn-outline-primary d-flex align-items-center mr-3"><span
                                class="material-icons mr-2">clear</span> Hủy</button> -->
                        <button *ngIf="checkAllowEdit()" (click)="goToEdit()"
                            class="btn btn-outline-primary d-flex align-items-center mr-3"><span
                                class="material-icons mr-2">create</span> {{'ServiceContractDetail_btn_edit' | translate}}</button>
                        <!-- <button (click)="actBtnSave($event)" [disabled]="editedForm"
                            class="btn btn-outline-primary d-flex align-items-center mr-3"><span
                                class="material-icons mr-2">save</span> Lưu</button> -->
                        <!-- && !notHaveSignTypeAllow -->
                        <button *ngIf="allowConfigFlow" (click)="settingFlow()"
                            class="btn btn-outline-primary d-flex align-items-center mr-3">
                            <span class="material-icons mr-2">settings</span> {{'ServiceContractDetail_config_flow' | translate}}
                        </button>
                        <button *ngIf="allowCancelContract" (click)="openModalReturn(contentReturn, contractId)"
                            class="btn btn-outline-primary d-flex align-items-center mr-3">
                            <span class="material-icons mr-2">reply</span>
                            {{'ServiceContractDetail_btn_return' | translate}}
                        </button>

                        <button 
                            *ngIf="checkAllowSign()" 
                            (click)="selectTab('SIGN')"
                            class="btn btn-primary d-flex align-items-center mr-3" >
                            <span class="ct-icon-font icon-custom_signature mr-2"></span> 
                            {{'ServiceContractDetail_btn_sign' | translate}}
                        </button>

                        <button *ngIf="allowSendContract" (click)="sendContract()"
                            class="btn btn-primary d-flex align-items-center mr-3" placement="left" ngbTooltip="{{ 'ServiceContractDetail_tooltip_3' | translate }}"><span
                            class="material-icons mr-2">send</span> {{'ServiceContractDetail_btn_send' | translate}}</button>
                    </div>

                    <div *ngIf="activeTab == 'CHAT'" class="d-flex align-items-center">
                        <button *ngIf="allowPartnerDiscussConfig" (click)="openPartnerDiscussSetting()"
                            class="btn btn-outline-primary d-flex align-items-center mr-3"><span
                                class="material-icons mr-2">add</span> {{'ServiceContractDetail_btn_add_employee' | translate}} </button>

                        <!-- <button *ngIf="allowConfirmContract && !endInternalDiscuss"
                            class="btn btn-outline-primary d-flex align-items-center mr-3 disabled"
                            placement="auto" ngbTooltip="Bạn có thể thao tác sau khi kết thúc thẩm định hợp đồng"><span
                                class="material-icons mr-2">clear</span> Từ chối</button>

                        <button *ngIf="allowConfirmContract && !endInternalDiscuss"
                            class="btn btn-primary d-flex align-items-center mr-3 disabled"
                            placement="auto" ngbTooltip="Bạn có thể thao tác sau khi kết thúc thẩm định hợp đồng"><span
                                class="material-icons mr-2">done</span> Đồng ý</button> -->

                        <button *ngIf="allowConfirmContract && endInternalDiscuss"
                            (click)="openModalDenyPartnerDiscuss(partnerDiscussDeny)"
                            class="btn btn-outline-primary d-flex align-items-center mr-3"><span
                                class="material-icons mr-2">clear</span> {{'ServiceContractDetail_btn_reject' | translate}}</button>

                        <button *ngIf="allowConfirmContract && isAcceptInternalDiscuss"
                            (click)="confirmAcceptContract()"
                            class="btn btn-primary d-flex align-items-center mr-3"><span
                                class="material-icons mr-2">done</span> {{'ServiceContractDetail_btn_accept' | translate}}</button>
                        
                        <button *ngIf="allowUpdateFileContractInDiscuss" (click)="openPopupUpdateContractFile()"
                            class="btn btn-primary d-flex align-items-center mr-3"><span
                                class="material-icons mr-2">update</span> {{'ServiceContractDetail_btn_modify_content' | translate}}</button>
                    </div>

                    <div *ngIf="activeTab == 'INTERNAL_DISCUSS'" class="d-flex align-items-center">
                        <button *ngIf="allowInternalDiscussConfig" (click)="openInternalDiscussSetting()"
                            class="btn btn-outline-primary d-flex align-items-center mr-3"><span
                                class="material-icons mr-2">add</span> {{'ServiceContractDetail_btn_add_employee' | translate}} </button>

                        <button *ngIf="allowConfirmInternalDiscuss"
                            (click)="openModalDenyInternalDiscuss(internalDiscussDeny)"
                            class="btn btn-outline-primary d-flex align-items-center mr-3"><span
                                class="material-icons mr-2">clear</span> {{'ServiceContractDetail_btn_reject' | translate}}</button>

                        <button *ngIf="allowConfirmInternalDiscuss" (click)="openModalDealInternalDiscuss(internalDiscussDeal)"
                            class="btn btn-primary d-flex align-items-center mr-3"><span class="material-icons mr-2">done</span>
                            {{'ServiceContractDetail_btn_accept' | translate}}</button>
                    </div>

                    <div *ngIf="activeTab == 'SIGN'" class="d-flex align-items-center">
                        <!-- <button
                            class="btn btn-outline-primary d-flex align-items-center mr-3"
                            (click)="saveContract()">
                            <span class="material-icons mr-2">get_app</span> 
                            Tải xuống
                        </button> -->
                        <button *ngIf="allowCancelContract && currentStepSign === 0"
                            (click)="openModalReturn(contentReturn, contractId)"
                            class="btn btn-outline-primary d-flex align-items-center mr-3">
                            <span class="material-icons mr-2">reply</span>
                            {{'ServiceContractDetail_btn_return' | translate}}
                        </button>
                        
                        <button *ngIf="contract.allowSendRequestResign" type="button" class="btn btn-outline-primary d-flex align-items-center mr-3" (click)="cancelContract(modalCancelContract)">
                            <span class="material-icons mr-2">clear</span>
                            Hủy hợp đồng
                        </button>
                        <button *ngIf="contract.allowSendRequestResign" type="button" class="btn btn-primary d-flex align-items-center mr-3" (click)="openRequestResignModal(contract?.partySignFailed?.partyId || '')">
                            <span class="material-icons-outlined mr-2">replay</span>
                            Yêu cầu ký lại
                        </button>

                        <div *ngIf="checkAllowSign()" class="sign-toolbar">
                            <div *ngIf="currentStepSign === 0" class="select-sign-type">
                                <div *ngIf="!haveSessionEsign"
                                    ngbDropdown container="body">
                                    <button class="btn btn-primary d-flex align-items-center mr-3" ngbDropdownToggle>
                                        <span class="ct-icon-font icon-custom_signature mr-2"></span>
                                        {{'ServiceContractDetail_sign_contract' | translate}}
                                    </button>
                                    <div ngbDropdownMenu class="border-0">
                                        <a *ngIf="allowActionSignType('NO_AUTHEN')"
                                            ngbDropdownItem
                                            class="dropdown-item cursor-pointer d-flex" (click)="selectWayOfSign('E_SIGN', 'NO_AUTHEN')">
                                            {{'ServiceContractDetail_lv1_sign_image' | translate}}
                                        </a>
                                        <a *ngIf="allowActionSignType('OTP')"
                                            ngbDropdownItem
                                            class="dropdown-item cursor-pointer d-flex" (click)="selectWayOfSign('E_SIGN', 'OTP')">
                                            {{'ServiceContractDetail_lv2_sign_otp' | translate}}
                                        </a>
                                        <a *ngIf="allowActionSignType('OTP_EMAIL')"
                                            ngbDropdownItem
                                            class="dropdown-item cursor-pointer d-flex" (click)="selectWayOfSign('E_SIGN', 'OTP_EMAIL')">
                                            {{'ServiceContractDetail_lv2_sign_mail_otp' | translate}}
                                        </a>
                                        <a *ngIf="allowActionSignType('USB_TOKEN')"
                                            ngbDropdownItem
                                            class="dropdown-item cursor-pointer d-flex" (click)="signApproval('USB_TOKEN')">
                                            {{'ServiceContractDetail_lv4_sign_usbtoken' | translate}}
                                        </a>
                                        <a *ngIf="allowActionSignType('SIGN_SERVER')"
                                            ngbDropdownItem 
                                            class="dropdown-item cursor-pointer d-flex" (click)="signApproval('SIGN_SERVER')">
                                            {{'ServiceContractDetail_lv4_sign_vnpt' | translate}}
                                        </a>
                                        <a *ngIf="allowActionSignType('SMART_CA')"
                                            ngbDropdownItem 
                                            class="dropdown-item cursor-pointer d-flex" (click)="signApproval('SMARTCA')">
                                            {{'ServiceContractDetail_lv4_sign_smartca' | translate}}
                                        </a>
                                    </div>
                                </div>
                                <button
                                    *ngIf="currentStepSign == 0 && selectedWayOfSign === 'E_SIGN' && haveSessionEsign"
                                    class="btn btn-primary d-flex align-items-center mr-3"
                                    (click)="callActionSubmitOTP()">
                                    {{'ServiceContractDetail_confirm' | translate}}
                                </button>
                            </div>
                            <div *ngIf="currentStepSign === 1" class="config-sign">
                                <div class="w-100 d-flex">
                                    <button *ngIf="!(selectedWayOfSign == 'SMARTCA' && showLayoutConfirmSmartCA)"
                                        (click)="cancelSign(currentStepSign)" id="cancel-sign-session"
                                        class="btn btn-outline-primary d-flex align-items-center mr-3">
                                        <span class="material-icons mr-2">close</span>
                                        {{'ServiceContractDetail_cancel' | translate}}
                                    </button>
                                    <button *ngIf="selectedWayOfSign == 'SIGN_SERVER'" (click)="finishSignServer()"
                                        id="save-sign-server" class="btn btn-primary d-flex align-items-center mr-3">
                                        <span class="material-icons mr-2">save</span>
                                        {{'ServiceContractDetail_save' | translate}}
                                    </button>
                                    <button *ngIf="selectedWayOfSign == 'E_SIGN'" (click)="sendRequestElectronicSign()"
                                        id="save-esign" class="btn btn-primary d-flex align-items-center mr-3">
                                        <span class="material-icons mr-2">save</span>
                                        {{'ServiceContractDetail_save' | translate}}
                                    </button>
                                    <button *ngIf="selectedWayOfSign == 'SMARTCA' && !showLayoutConfirmSmartCA"
                                        (click)="signBySmartCA()" id="save-smartca"
                                        class="btn btn-primary d-flex align-items-center mr-3">
                                        <span class="material-icons mr-2">save</span>
                                        {{'ServiceContractDetail_save' | translate}}
                                    </button>
                                    <button *ngIf="selectedWayOfSign == 'USB_TOKEN'" (click)="signUsbToken()"
                                        class="btn btn-primary d-flex align-items-center mr-3">
                                        <span class="material-icons mr-2">save</span>
                                        {{'ServiceContractDetail_save' | translate}}
                                    </button>
                                    <button *ngIf="timeoutSmartCa" (click)="backStepSign(currentStepSign)"
                                        class="btn btn-primary d-flex align-items-center mr-3">
                                        <span class="ct-icon-font icon-custom_signature mr-2"></span>
                                        {{'ServiceContractDetail_sign_contract_again' | translate}}
                                    </button>
                                </div>
                            </div>
                            <div *ngIf="currentStepSign === 2" class="config-sign">
                                <div class="w-100 d-flex">
                                    <button (click)="resign()" id="resign"
                                        class="btn btn-outline-primary d-flex align-items-center mr-3">
                                        <span class="material-icons mr-2">refresh</span>
                                        {{'ServiceContractDetail_sign_again' | translate}}
                                    </button>
                                    <button *ngIf="selectedWayOfSign == 'USB_TOKEN'" (click)="pushSigUsbToken()"
                                        class="btn btn-outline-primary d-flex align-items-center mr-3">
                                        <span class="material-icons mr-2">add</span>
                                        {{'ServiceContractDetail_add_signature' | translate}}
                                    </button>
                                    <button (click)="sendContract()" id="finish-submit-signed-file"
                                        class="btn btn-primary d-flex align-items-center mr-3">
                                        <span class="material-icons mr-2">check</span>
                                        {{'ServiceContractDetail_btn_finish' | translate}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="activeTab == 'CENSORSHIP' && checkAllowActionApproveInternal" id="action-tab-CENSORSHIP" class="d-flex align-items-center">
                        <button
                            *ngIf="!hideDownloadBtn()"
                            class="btn btn-outline-primary d-flex align-items-center mr-3"
                            (click)="saveContract()">
                            <span class="material-icons mr-2">get_app</span> 
                            {{'ServiceContractDetail_btn_download' | translate}}
                        </button>

                        <div *ngIf="allowSendInternal" class="d-flex align-items-center">
                            <button *ngIf="checkAllowFinishCensorship_v2" (click)="finishCensorship()" class="btn btn-outline-primary d-flex align-items-center mr-3" 
                            placement="bottom" ngbTooltip="{{ 'ServiceContractDetail_tooltip_2' | translate }}">
                                <span class="material-icons mr-2">check</span> 
                                {{'ServiceContractDetail_end_department_sign' | translate}}
                            </button>
                            <button (click)="sendToCensorship()" class="btn btn-primary d-flex align-items-center mr-3" 
                            placement="bottom" ngbTooltip="{{ 'ServiceContractDetail_tooltip_1' | translate }}">
                                <span class="material-icons mr-2">send</span> 
                                {{'ServiceContractDetail_send_department_sign' | translate}}
                            </button>
                        </div>

                        <div *ngIf="allowActionSignInternal && !haveSessionEsignInternal"
                            class="d-flex align-items-center">
                            <button *ngIf="!checkAllowApproveSign"
                                class="btn btn-outline-primary d-flex align-items-center mr-3" (click)="tuChoiDuyet()">
                                <span class="material-icons mr-2">reply</span>
                                {{'ServiceContractDetail_btn_return' | translate}}
                            </button>
                            <button *ngIf="checkAllowApproveSign" (click)="selectTab('SIGN')"
                                class="btn btn-primary d-flex align-items-center mr-3">
                                <span class="ct-icon-font icon-custom_signature mr-2"></span>
                                {{'ServiceContractDetail_sign_contract' | translate}}
                            </button>
                            <div *ngIf="checkAllowDraftSign"  ngbDropdown container="body">
                                <button class="btn btn-primary d-flex align-items-center mr-3" ngbDropdownToggle>
                                    <span class="ct-icon-font icon-custom_signature mr-2"></span>
                                    {{'ServiceContractDetail_sign_contract' | translate}}
                                </button>
                                <div ngbDropdownMenu class="border-0">
                                    <a *ngIf="allowActionSignFormInternal('NO_AUTHEN')" ngbDropdownItem
                                        class="dropdown-item cursor-pointer d-flex" (click)="kyDienTu('NO_AUTHEN')">
                                        {{'ServiceContractDetail_lv1_sign_image' | translate}}
                                    </a>
                                    <a *ngIf="allowActionSignFormInternal('OTP')" ngbDropdownItem
                                        class="dropdown-item cursor-pointer d-flex" (click)="kyDienTu('OTP')">
                                        {{'ServiceContractDetail_lv2_sign_otp' | translate}}
                                    </a>
                                    <a *ngIf="allowActionSignFormInternal('OTP_EMAIL')" ngbDropdownItem
                                        class="dropdown-item cursor-pointer d-flex" (click)="kyDienTu('OTP_EMAIL')">
                                        {{'ServiceContractDetail_lv2_sign_mail_otp' | translate}}
                                    </a>
                                    <a 
                                        *ngIf="allowActionSignFormInternal('USB_TOKEN')"
                                        ngbDropdownItem class="dropdown-item cursor-pointer d-flex" (click)="kyNhayUsbToken()">
                                        {{'ServiceContractDetail_lv4_sign_usbtoken' | translate}}
                                    </a>
                                    <a 
                                        *ngIf="allowActionSignFormInternal('SIGN_SERVER')"
                                        ngbDropdownItem class="dropdown-item cursor-pointer d-flex" (click)="kyNhayVNPTKySo()">
                                        {{'ServiceContractDetail_lv4_sign_vnpt' | translate}}
                                    </a>
                                    <a *ngIf="allowActionSignFormInternal('SMART_CA')" ngbDropdownItem
                                        class="dropdown-item cursor-pointer d-flex" (click)="kySmartCA()">
                                        {{'ServiceContractDetail_lv4_sign_smartca' | translate}}
                                    </a>
                                    <a ngbDropdownItem class="dropdown-item cursor-pointer d-flex" (click)="duyetKhongKy()">
                                        {{'ServiceContractDetail_approve_no_sign' | translate}}
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="checkAllowStartApproveInternal" class="d-flex align-items-center">
                            <div #step0EsignInternal *ngIf="currentStepSignInternal === 0 && haveSessionEsignInternal"
                                class="d-flex">
                                <button class="btn btn-primary d-flex align-items-center mr-3"
                                    (click)="confirmOTPInternalSign()">
                                    {{'ServiceContractDetail_confirm' | translate}}
                                </button>
                            </div>

                            <div #step1EsignInternal *ngIf="currentStepSignInternal === 1" class="d-flex">
                                <button *ngIf="!isWaitingConfirmSmartCAInternal"
                                    (click)="cancelSignInternal(currentStepSignInternal)"
                                    class="btn btn-outline-primary d-flex align-items-center mr-3">
                                    <span class="material-icons mr-2">close</span>
                                    {{'ServiceContractDetail_cancel' | translate}}
                                </button>
                                <button *ngIf="signTypeInternal != 'E_SIGN' && !isWaitingConfirmSmartCAInternal"
                                    (click)="saveSignInternal()" class="btn btn-primary d-flex align-items-center mr-3">
                                    <span class="material-icons mr-2">save</span>
                                    {{'ServiceContractDetail_save' | translate}}
                                </button>
                                <button *ngIf="signTypeInternal == 'E_SIGN'" (click)="saveESignInternal()"
                                    class="btn btn-primary d-flex align-items-center mr-3">
                                    <span class="material-icons mr-2">save</span>
                                    {{'ServiceContractDetail_save' | translate}}
                                </button>
                                <button *ngIf="isExpiredSmartCAInternal"
                                    (click)="backStepSignInternal(currentStepSignInternal)"
                                    class="btn btn-primary d-flex align-items-center mr-3">
                                    <span class="ct-icon-font icon-custom_signature mr-2"></span>
                                    {{'ServiceContractDetail_sign_contract_again' | translate}}
                                </button>
                            </div>

                            <div #step2EsignInternal *ngIf="currentStepSignInternal === 2" class="d-flex">
                                <button (click)="resignInternal(currentStepSignInternal)"
                                    class="btn btn-outline-primary d-flex align-items-center mr-3">
                                    <span class="material-icons mr-2">undo</span>
                                    {{'ServiceContractDetail_sign_again' | translate}}
                                </button>
                                <button *ngIf="checkSignType('USB_TOKEN')" (click)="pushSigUsbToken_internal()"
                                    class="btn btn-outline-primary d-flex align-items-center mr-3">
                                    <span class="material-icons mr-2">add</span>
                                    {{'ServiceContractDetail_add_signature' | translate}}
                                </button>
                                <button (click)="endSignInternal()"
                                    class="btn btn-primary d-flex align-items-center mr-3">
                                    <span class="material-icons mr-2">check</span>
                                    {{'ServiceContractDetail_btn_finish' | translate}}
                                </button>
                            </div>
                        </div>
                    </div>

                    <!-- FOR DOCUMENT -->
                    <div *ngIf="activeTab == 'DOCUMENT' && modeDocument === 'list'" class="d-flex align-items-center">
                        <button *ngIf="false"
                            class="btn btn-outline-primary d-flex align-items-center mr-3"
                            (click)="downloadDocument()">
                            <span class="material-icons mr-2">get_app</span> 
                            {{'ServiceContractDetail_btn_download' | translate}}
                        </button>
                        <button *ngIf="!controlAccount.userInfo.isViewer"
                            class="btn btn-primary d-flex align-items-center mr-3"
                            (click)="addNewDocument()">
                            <span class="material-icons mr-2">add</span> 
                            {{'ServiceContractDetail_add_document' | translate}}
                        </button>
                    </div>
                    <div *ngIf="activeTab == 'DOCUMENT' && modeDocument === 'add'" class="d-flex align-items-center">
                        <button
                            class="btn btn-outline-primary d-flex align-items-center mr-3"
                            (click)="cancelAddingDocument()">
                            <span class="material-icons mr-2">clear</span> 
                            {{'ServiceContractDetail_cancel' | translate}}
                        </button>
                        <button
                            class="btn btn-primary d-flex align-items-center mr-3"
                            (click)="saveDocument()">
                            <span class="material-icons mr-2">save</span> 
                            {{'ServiceContractDetail_save' | translate}}
                        </button>
                    </div>
                    <div *ngIf="activeTab == 'DOCUMENT' && modeDocument === 'edit'" class="d-flex align-items-center">
                        <button
                            class="btn btn-outline-primary d-flex align-items-center mr-3"
                            (click)="cancelEditingDocument()">
                            <span class="material-icons mr-2">clear</span> 
                            {{'ServiceContractDetail_cancel' | translate}}
                        </button>
                        <button
                            class="btn btn-primary d-flex align-items-center mr-3"
                            (click)="editDocument()">
                            <span class="material-icons mr-2">save</span> 
                            {{'ServiceContractDetail_save' | translate}}
                        </button>
                    </div>

                    <!-- FOR LIST VERSION -->



                </div>
            </div>

            <div class="preview-body">
                <div class="pv ct">
                    <div class="pv-body">
                        <div class="ctpv-container">
                            <div class="ctpv-content">
                                <div class="ct-container">
                                    <div class="ct-thumb-page">
                                        <div class="ct-vs ct-enable-scroll">
                                            <ol id="thumbnail-page" class="ct-vs-list mt-3">
                                            </ol>
                                        </div>
                                    </div>
                                    <div id="ppi"
                                        style="height: 1in; width: 1in; position: absolute; top: -100%; left: -100%;">
                                    </div>
                                    <div class="ct-content">
                                        <div id="parentView" class="ct-doc-loaded ct-enable-scroll">
                                            <app-comment-popup #commentPopup id="contract-comment-popup" 
                                                [contractId]="contractId" 
                                                [contract]="contract"
                                                [version]="versionSelected" 
                                                (exit)="calledExit($event)"
                                                (submitCommentSuccess)="submitCommentSuccess($event)"
                                                (submitReplySuccess)="submitReplySuccess($event)"
                                                (deletedComment)="callBackDeletedComment($event)"
                                                (deletedReply)="callBackDeletedReply($event)"
                                                (nextNotePoint)="nextNotePoint($event)"
                                                (prevNotePoint)="prevNotePoint($event)"></app-comment-popup>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="ct ctpv-tool-bar">
                            <div class="ctpv-tool-sidebar">
                                <div class="ctpv-tabbar">
                                    <div class="ctpv-main">
                                        <button (click)="selectTab('INFO')" class="ctpv-btn"
                                            [class.active]="activeTab == 'INFO'">
                                            <span class="material-icons-outlined">info</span>
                                        </button>
                                        <!-- *ngIf="allowDiscuss" -->
                                        <button *ngIf="allowShowDiscussTab" (click)="selectTab('CHAT')" class="ctpv-btn"
                                            [class.active]="activeTab == 'CHAT'">
                                            <span class="material-icons">forum</span>
                                        </button>
                                        <button *ngIf="allowShowInternalDiscussTab"
                                            (click)="selectTab('INTERNAL_DISCUSS')" class="ctpv-btn"
                                            [class.active]="activeTab == 'INTERNAL_DISCUSS'">
                                            <span class="material-icons">gavel</span>
                                        </button>
                                        <button *ngIf="allowShowSignTab" (click)="selectTab('SIGN')" class="ctpv-btn"
                                            [class.active]="activeTab == 'SIGN'">
                                            <span class="ct-icon-font icon-custom_signature"></span>
                                        </button>
                                        <button *ngIf="allowApproveInternal && !controlAccount.isConsumer"
                                            (click)="selectTab('CENSORSHIP')" class="ctpv-btn"
                                            [class.active]="activeTab == 'CENSORSHIP'">
                                            <span class="material-icons">swap_horizontal_circle</span>
                                        </button>
                                        <button (click)="selectTab('DOCUMENT'); resetTabDocument()" class="ctpv-btn"
                                            [class.active]="activeTab == 'DOCUMENT'"> 
                                            <span class="material-icons">attach_file</span>
                                        </button>
                                        <button *ngIf="contract.isOwnerParty" (click)="selectTab('VERSION')" class="ctpv-btn"
                                            [class.active]="activeTab == 'VERSION'"> 
                                            <span class="material-icons-outlined">
                                                track_changes
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <div class="ctpv-footer">

                                </div>
                            </div>
                            <div class="ctpv-tool-content">
                                <div class="ctpv-content-wrapper enable-scroll px-3">
                                    <div id="tab-info" class="ctpv-scroll-content ec-tab-content"
                                        [class.d-none]="!(activeTab === 'INFO')">
                                        <div *ngIf="contract.currentStage == 'LC_DRAFT_CREATE' && allowGenContractNumber" class="mt-3">
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <span>{{ 'ServiceContract_contractNumberDescSend' | translate }}</span>
                                                </div>
                                                <div class="col-sm-12 mt-3">
                                                    <div class="contract-number-form d-flex flex-column mb-3">
                                                        <label>{{ 'ServiceContract_contractNumber' | translate }}</label>
                                                        <div class="form-group d-flex mb-1">
                                                            <input [(ngModel)]="contractNumberBox!.text" class="form-control"
                                                                type="text" disabled>
                                                            <div class="d-flex justify-content-center align-items-center">
                                                                <button class="btn btn-secondary btn-icon mr-1"
                                                                (click)="getNextContractNumber()" >
                                                                <span class="material-icons-outlined">
                                                                    arrow_drop_up
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <h5 class="title-block-info">{{'ServiceContractDetail_contractinfo' | translate}}</h5>
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <label>{{'ServiceContractDetail_contract_creation_type' | translate}}</label>
                                                <p *ngIf="contract.creationType == 'FILE'">{{'ServiceContractDetail_upload_file' | translate}}</p>
                                                <p *ngIf="contract.creationType == 'TEMPLATE'">{{'ServiceContractDetail_create_by_template' | translate}}</p>
                                                <p *ngIf="contract.creationType == 'DIRECT'">{{'ServiceContractDetail_create_by_editor' | translate}}</p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <label>{{'ServiceContractDetail_flow_contract' | translate}}</label>
                                                <p>{{ contract.contractFlowName || "" }}</p>
                                            </div>
                                        </div>
                                        
                                        <div class="row">
                                            <div class="col-lg-12">
                                                <label>{{'ServiceContract_contractNumber' | translate}}</label>
                                                <p>{{ contract.contractNumber || "" }}</p>
                                            </div>
                                        </div>

                                        <div class="row" style="word-break: break-word;">
                                            <div class="col-lg-6">
                                                <label>{{'ServiceContractDetail_contract_name' | translate}}</label>
                                                <p>{{ contract.title }}</p>
                                            </div>
                                            <div class="col-lg-6">
                                                <label>{{'ServiceContractDetail_contract_file' | translate}}</label>
                                                <p>{{ contract.fileName }}</p>
                                            </div>
                                        </div>

                                        <!-- Loai hop dong -->
                                        <!-- <div class="row">
                                            <div class="col-lg-12">
                                                <label>{{'ContractTypes_list_title' | translate}}</label>
                                                <p>{{ contract.typeName || "" }}</p>
                                            </div>
                                        </div> -->

                                        <div class="row">
                                            <div class="col-lg-6">
                                                <label>{{'ServiceContractDetail_valid_from' | translate}}</label>
                                                <p class="min-h-21">{{ contract.validDate | date: 'dd/MM/yyyy'}}</p>
                                            </div>
                                            <div class="col-lg-6">
                                                <label>{{'ServiceContractDetail_valid_to' | translate}}</label>
                                                <p class="min-h-21">{{ contract.endDate | date: 'dd/MM/yyyy' }}</p>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-lg-6">
                                                <label>{{'ServiceContractDetail_auto_renew' | translate}}</label>
                                                <p class="min-h-21">{{ (contract.autoRenew ? 'ServiceContractDetail_auto_renew_1' : 'ServiceContractDetail_auto_renew_0') | translate }}</p>
                                            </div>
                                            <div class="col-lg-6">
                                                <label>{{'ServiceContractDetail_contract_value' | translate}}</label>
                                                <p class="min-h-21">{{ contract.contractValue | number }}</p>
                                            </div>
                                        </div>

                                        <div class="row">
                                            <div class="col-lg-6">
                                                <label>{{'ServiceContractDetail_status' | translate}}</label>
                                                <p class="min-h-21" [innerHtml]="getStatusContract"></p>
                                            </div>
                                            <div class="col-lg-6">
                                                <label>{{'ServiceContractDetail_note' | translate}}</label>
                                                <p class="min-h-21">{{ contract.creationNote }}</p>
                                            </div>
                                        </div>

                                        <h5 class="title-block-info">{{'ServiceContractDetail_party_join_contract' | translate}}</h5>
                                        <div class="list-party">
                                            <div *ngFor="let item of partiesJoin; let i = index"
                                                class="list-party-item">
                                                <div *ngIf="i === 0; else otherParty" class="owner-party">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <label>{{'ServiceContractDetail_participant' | translate}}</label>
                                                            <p class="min-h-21" [class.text-over]="!item.tenToChuc && !item.tenDaiDien && item.email">{{ item.tenToChuc || item.tenDaiDien || item.email || item.sdt }}</p>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <label>{{'ServiceContractDetail_customer_type' | translate}}</label>
                                                            <p *ngIf="item.userType === userTypeConst.CONSUMER" class="min-h-21">{{'ServiceContractDetail_consumer' | translate}}</p>
                                                            <p *ngIf="item.userType === userTypeConst.BUSINESS" class="min-h-21">{{'ServiceContractDetail_party' | translate}}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <ng-template #otherParty>
                                                    <hr>
                                                    <div class="row mt-3">
                                                        <div class="col-md-6">
                                                            <label>{{'ServiceContractDetail_participant' | translate}}</label>
                                                            <p [class.text-over]="!item.tenToChuc && !item.tenDaiDien && item.email" class="min-h-21">{{ item.tenToChuc || item.tenDaiDien || item.email || item.sdt }}</p>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <label>{{'ServiceContractDetail_customer_type' | translate}}</label>
                                                            <p *ngIf="item.userType === userTypeConst.CONSUMER" class="min-h-21">{{'ServiceContractDetail_consumer' | translate}}</p>
                                                            <p *ngIf="item.userType === userTypeConst.BUSINESS" class="min-h-21">{{'ServiceContractDetail_party' | translate}}</p>
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <div class="col-lg-6">
                                                            <label>{{'ServiceContractDetail_verification_type' | translate}}</label>
                                                            <p class="min-h-21">{{ constVerificationType[item.verificationType || ""] | translate }}</p>
                                                        </div>
                                                        <div class="col-lg-6">
                                                            <label>{{ 'clc_tb_sign_type' | translate }}</label>
                                                            <p class="min-h-21">
                                                                <span *ngFor="let _item of item.signForm; let i = index">
                                                                    {{ (_item === 'NO_AUTHEN' ? 'ServiceContractDetail_lv1_sign_image' : '') | translate }}
                                                                    {{ (_item === 'OTP' ? 'ServiceContractDetail_lv2_sign_otp' : '') | translate }}
                                                                    {{ (_item === 'OTP_EMAIL' ? 'ServiceContractDetail_lv2_sign_mail_otp' : '') | translate }}
                                                                    {{ (_item === 'EKYC' ? 'ServiceContractDetail_lv3_sign_sms_otp_ekyc' : '') | translate }}
                                                                    {{ (_item === 'EKYC_EMAIL' ? 'ServiceContractDetail_lv3_sign_mail_otp_ekyc' : '') | translate }}
                                                                    {{ _item === 'ONE_TIME_CA' ? ('ServiceContractDetail_sign_one_time' | translate) : '' }}
                                                                    {{ (_item === 'USB_TOKEN' ? 'ServiceContractDetail_lv4_sign_usbtoken' : '') | translate }}
                                                                    {{ (_item === 'SIGN_SERVER' ? 'ServiceContractDetail_lv4_sign_vnpt' : '') | translate }}
                                                                    {{ (_item === 'SMART_CA' ? 'ServiceContractDetail_lv4_sign_smartca' : '') | translate }}
                                                                    {{ i < item.signForm.length - 1 ? ', ' : ''}}
                                                                </span>
                                                            </p>

                                                        </div>

                                                        <!-- <div class="col-lg-6">
                                                            <p><label>Thời hạn ký</label></p>
                                                            <p>{{ item.endDate | date: 'dd/MM/yyyy' }}</p>
                                                        </div> -->
                                                    </div>
                                                </ng-template>

                                            </div>

                                        </div>
                                    </div>
                                    <div id="tab-sign" class="ctpv-scroll-content ec-tab-content"
                                        [class.d-none]="!(activeTab === 'SIGN') || !allowShowSignTab">
                                        <div *ngIf="isActionSign; else notAllowSign" id="action-SIGN" class="">
                                            <div class="w-100 d-flex mt-3">
                                                <div *ngIf="selectedWayOfSign !== 'SMARTCA' || (selectedWayOfSign === 'SMARTCA' && !showLayoutConfirmSmartCA && currentStepSign != 2)" class="sign-back" (click)="backStepSignV2(currentStepSign)">
                                                    <span class="material-icons">
                                                        arrow_back
                                                    </span>
                                                </div>
                                                
                                                <h5 *ngIf="selectedWayOfSign !== 'SMARTCA' || (selectedWayOfSign === 'SMARTCA' && !showLayoutConfirmSmartCA)" class="font-weight-bold">{{'ServiceContractDetail_sign_contract' | translate}}</h5>
                                                <!-- <h5 *ngIf="selectedWayOfSign == 'SMARTCA' && showLayoutConfirmSmartCA" class="font-weight-bold">Xác nhận chữ ký SmartCA</h5> -->
                                            </div>
                                            <div *ngIf="currentStepSign === 0" class="sign-step mt-3">
                                                <app-e-sign-otp #eSignOTP
                                                    [class.d-none]="selectedWayOfSign !== 'E_SIGN'"
                                                    [sdt]="userInfo.sdt || ''" [idSign]="idSign"
                                                    [signForm]="signFormSelected"
                                                    [limitResendOTP]="limitResendOTP"
                                                    (confirmOTPSuccess)="confirmOTPSuccess($event)"
                                                    (limitFailed)="handleLimitFailedOTP($event)"
                                                    (minusResendOTP)="handleMinusResendOTP($event)">
                                                </app-e-sign-otp>
                                            </div>
                                            <div *ngIf="currentStepSign === 1" class="sign-step mt-3">
                                                <p *ngIf="selectedWayOfSign !== 'SMARTCA'" class="text-grey-darkest">{{'ServiceContractDetail_please_config_signature' | translate}}</p>
    
                                                <div class="row">
                                                    <div class="col-12 d-flex-block">
                                                        <div *ngIf="notHaveSignTypeAllow" class="text-grey-darkest w-100 d-flex">
                                                            <p class="text-danger">{{'ServiceContractDetail_dont_have_signform_av' | translate}}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div *ngIf="!notHaveSignTypeAllow" class="content-tab">
                                                    <div id="usb-token"
                                                        [class.d-none]="selectedWayOfSign !== 'USB_TOKEN'">
                                                        <!--- USB TOKEN -->
                                                        <div class="">
                                                            <table class="table table-bordered header-freeze">
                                                                <thead class="bg-tb-header">
                                                                    <tr>
                                                                        <th>{{'ServiceContractDetail_page_of_signature' | translate}}</th>
                                                                        <th>{{'ServiceContractDetail_action' | translate}}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            {{ 'ContractCensorship_label_13' | translate }} {{ signatureUsb.addedSignBox === true ? signatureUsb.page : getCurrentPage() }}
                                                                        </td>
                                                                        <td>
                                                                            <button *ngIf="!signatureUsb.addedSignBox" class="btn btn-primary" (click)="usbtokenAddSignBox()">{{'ServiceContractDetail_create_signature' | translate}}</button>
                                                                            <button *ngIf="signatureUsb.addedSignBox" class="btn btn-danger btn-icon text-white" (click)="removeSignBoxUsbtoken()">
                                                                                <span class="material-icons" style="font-size: 1.4rem;">delete</span>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <em class="mt-1">{{'ServiceContractDetail_create_signature_tips' | translate}}</em>
                                                        </div>

                                                        <div class="row mt-3">
                                                            <form [formGroup]="formSign" class="col-md-12 row">
                                                                <div class="col-12">
                                                                    <div class="infor-box-header d-flex">
                                                                        <label class="text-primary flex-grow-1 mb-0">
                                                                            {{'ServiceContractDetail_content_signature' | translate}}</label>
                                                                    </div>
                                                                    <hr>
                                                                    <div class="row">
                                                                        <div class="col-xl-6">
                                                                            <div class="ccheck-primary d-inline-block">
                                                                                <input (change)="changedSignBy($event)"
                                                                                    formControlName="signBy" type="checkbox"
                                                                                    id="signBy_usbToken">
                                                                                <label for="signBy_usbToken" class="w-100">
                                                                                    {{'ServiceContractDetail_signby' | translate}}
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-xl-6">
                                                                            <div class="ccheck-primary d-inline-block">
                                                                                <input
                                                                                    (change)="changedDateSign($event)"
                                                                                    formControlName="signDate"
                                                                                    type="checkbox" id="signDate_usbToken">
                                                                                <label for="signDate_usbToken" class="w-100">
                                                                                    {{'ServiceContractDetail_signdate' | translate}}
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12">
                                                                    <div class="row mt-3">
                                                                        <div class="col-xl-12 align-items-end d-flex">
                                                                            <div class="form-group w-100">
                                                                                <label>{{'ServiceContractDetail_fontsize' | translate}}:</label>
                                                                                <input formControlName="fontSize"
                                                                                    (input)="inputFontSize($event)"
                                                                                    type="text" class="form-control"
                                                                                    placeholder="Nhập cỡ chữ">
                                                                                <app-show-validate-errors
                                                                                    [isSubmit]="isSubmit"
                                                                                    [errorMessages]="validMsg.fontSize"
                                                                                    [control]="formSign.controls.fontSize"
                                                                                    [detail]="{ name: 'fontSize' }">
                                                                                </app-show-validate-errors>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12">
                                                                    <div class="infor-box-header d-flex mt-3">
                                                                        <label class="text-primary flex-grow-1 mb-0">
                                                                            {{'ServiceContractDetail_displaySignatureType' | translate}}
                                                                        </label>
                                                                    </div>
                                                                    <hr>
                                                                    <div class="row">
                                                                        <div class="col-xl-12">
                                                                            <label for="signType">
                                                                                {{'ServiceContractDetail_selectDisplaySignatureType' | translate}}:
                                                                            </label>
                                                                            <ng-select
                                                                                [items]="hinhThucHienThi_usbtoken"
                                                                                bindLabel="name" bindValue="value"
                                                                                [clearable]="false"
                                                                                (change)="selectStyleSignBox($event)"
                                                                                placeholder="{{'ServiceContractDetail_selectDisplaySignatureType' | translate}}"
                                                                                formControlName="visibleType">
                                                                                <ng-template ng-label-tmp let-item="item">
                                                                                    {{item.name | translate}}
                                                                                </ng-template>
                                                                                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                                                                    <span>{{item.name | translate}}</span>
                                                                                </ng-template>
                                                                            </ng-select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12">
                                                                    <div class="infor-box-header mt-3">
                                                                        <label class="text-primary flex-grow-1 mb-0">
                                                                            {{'ServiceContractDetail_imgSignature' | translate}}
                                                                        </label>
                                                                    </div>
                                                                    <hr>
                                                                    <div class="row">
                                                                        <div class="col-md-12">
                                                                            <div class="form-group">
                                                                                <label>{{'ServiceContractDetail_fileAttachment' | translate}}:</label>
                                                                                <div class="input-group mb-1">
                                                                                    <div class="custom-file custom-file-icon">
                                                                                        <label class="custom-file-label"
                                                                                            for="inputFileImage">{{ inputFileImageLabel }}</label>
                                                                                        <input formControlName="imageFile"
                                                                                            (input)="handleFileImageInput($event)"
                                                                                            type="file" accept="image/*"
                                                                                            class="custom-file-input"
                                                                                            id="inputFileImage"
                                                                                            aria-describedby="inputGroupFileAddon01">
                                                                                        <span class="icon-file material-icons">attach_file</span>
                                                                                    </div>
                                                                                </div>
                                                                                <app-show-validate-errors
                                                                                    [useSubmit]="false"
                                                                                    [isSubmit]="isSubmit"
                                                                                    [errorMessages]="validMsg.imageFile"
                                                                                    [control]="formSign.controls.imageFile"
                                                                                    [detail]="{ name: 'imageFile' }">
                                                                                </app-show-validate-errors>
                                                                            </div>
                                                                            <img *ngIf="imgContent" class="border border-primary w-100" style="max-width: 200px;"
                                                                                [src]="imgContent">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
    
                                                    <div id="sign-server"
                                                        [class.d-none]="selectedWayOfSign !== 'SIGN_SERVER'">
                                                        <!-- VNPT CA -->
                                                        <div class="mt-3">
                                                            <label class="text-primary">{{'ServiceContractDetail_signatureList' | translate}}:</label>
                                                            <hr class="mb-3">
                                                            <table id="list-signbox"
                                                                class="list-signature table header-freeze">
                                                                <thead>
                                                                    <tr class="bg-tb-header">
                                                                        <th>{{'ServiceContractDetail_tb_index' | translate}}</th>
                                                                        <th class="th_sig_rectangle">{{'ServiceContractDetail_thongsotoado' | translate}}</th>
                                                                        <th>{{'ServiceContractDetail_signaturePage' | translate}}</th>
                                                                        <th></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                </tbody>
                                                            </table>
                                                            <button *ngIf="contract.allowAddSignBox" role="button"
                                                                class="btn d-flex align-items-center text-primary px-0"
                                                                (click)="addSignBox()">
                                                                <span class="material-icons">add</span>
                                                                {{'ServiceContractDetail_add_signature' | translate}}
                                                            </button>
                                                        </div>
    
                                                        <div class="row mt-3">
                                                            <form [formGroup]="formSign" class="col-md-12 row">
                                                                <div class="col-xl-6">
                                                                    <div class="infor-box-header d-flex">
                                                                        <label class="text-primary flex-grow-1 mb-0">
                                                                            {{'ServiceContractDetail_signatureDecrip' | translate}}
                                                                        </label>
                                                                    </div>
                                                                    <hr>
                                                                    <div class="row">
                                                                        <div class="col-xl-12">
                                                                            <div
                                                                                class="ccheck-primary w-100 d-inline-block">
                                                                                <input (change)="changedDateSign($event)"
                                                                                    formControlName="signDate"
                                                                                    type="checkbox" id="signDate_VnptCa">
                                                                                <label for="signDate_VnptCa" class="w-100">
                                                                                    {{'ServiceContractDetail_signdate' | translate}}
                                                                                </label>
                                                                            </div>
                                                                            <div
                                                                                class="ccheck-primary w-100 d-inline-block">
                                                                                <input (change)="changedSignBy($event)"
                                                                                    formControlName="signBy" type="checkbox"
                                                                                    id="signBy_VnptCa">
                                                                                <label for="signBy_VnptCa" class="w-100">
                                                                                    {{'ServiceContractDetail_signby' | translate}}
                                                                                </label>
                                                                            </div>
                                                                        </div>
    
                                                                    </div>
    
                                                                    <div class="row mt-3">
                                                                        <div class="col-xl-12 align-items-end d-flex">
                                                                            <div class="form-group w-100">
                                                                                <label>{{'ServiceContractDetail_fontsize' | translate}}:</label>
                                                                                <input formControlName="fontSize"
                                                                                    (input)="inputFontSize($event)"
                                                                                    type="text" class="form-control"
                                                                                    placeholder="{{'ServiceContractDetail_fontsize_placeholder' | translate}}">
                                                                                <app-show-validate-errors
                                                                                    [isSubmit]="isSubmit"
                                                                                    [errorMessages]="validMsg.fontSize"
                                                                                    [control]="formSign.controls.fontSize"
                                                                                    [detail]="{ name: 'fontSize' }">
                                                                                </app-show-validate-errors>
                                                                            </div>
                                                                        </div>
                                                                    </div>
    
    
                                                                    <div class="infor-box-header d-flex mt-3">
                                                                        <label class="text-primary flex-grow-1 mb-0">
                                                                            {{'ServiceContractDetail_displaySignatureType' | translate}}
                                                                        </label>
                                                                    </div>
                                                                    <hr>
                                                                    <div class="row">
                                                                        <div class="col-xl-12">
                                                                            <label for="signType">{{'ServiceContractDetail_selectDisplaySignatureType' | translate}}:</label>
                                                                            <ng-select 
                                                                                [items]="hinhThucHienThi"
                                                                                bindLabel="name"
                                                                                bindValue="value"
                                                                                [clearable]="false"
                                                                                (change)="selectStyleSignBox($event)"
                                                                                placeholder="{{'ServiceContractDetail_selectDisplaySignatureType' | translate}}"
                                                                                formControlName="visibleType">
                                                                                <ng-template ng-label-tmp let-item="item">
                                                                                    {{item.name | translate}}
                                                                                </ng-template>
                                                                                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                                                                    <span>{{item.name | translate}}</span>
                                                                                </ng-template>
                                                                            </ng-select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-xl-6">
                                                                    <div class="infor-box-header">
                                                                        <label class="text-primary flex-grow-1 mb-0">
                                                                            {{'ServiceContractDetail_imgSignature' | translate}}
                                                                        </label>
                                                                    </div>
                                                                    <hr>
                                                                    <div class="row">
                                                                        <div class="col-md-12">
                                                                            <div class="form-group">
                                                                                <label>{{'ServiceContractDetail_fileAttachment' | translate}}:</label>
                                                                                <div class="input-group mb-1">
                                                                                    <div
                                                                                        class="custom-file custom-file-icon">
                                                                                        <label class="custom-file-label"
                                                                                            for="inputFileImage">{{
                                                                                            inputFileImageLabel
                                                                                            }}</label>
                                                                                        <input
                                                                                            formControlName="imageFile"
                                                                                            (input)="handleFileImageInput($event)"
                                                                                            type="file" accept="image/*"
                                                                                            class="custom-file-input"
                                                                                            id="inputFileImage"
                                                                                            aria-describedby="inputGroupFileAddon01">
                                                                                        <span
                                                                                            class="icon-file material-icons">attach_file</span>
                                                                                    </div>
                                                                                </div>
                                                                                <app-show-validate-errors
                                                                                    [useSubmit]="false"
                                                                                    [isSubmit]="isSubmit"
                                                                                    [errorMessages]="validMsg.imageFile"
                                                                                    [control]="formSign.controls.imageFile"
                                                                                    [detail]="{ name: 'imageFile' }">
                                                                                </app-show-validate-errors>
                                                                            </div>
                                                                            <img *ngIf="imgContent"
                                                                                class="border border-primary w-100"
                                                                                style="max-width: 200px;"
                                                                                [src]="imgContent">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>

                                                    <div id="digital-sign"
                                                        [class.d-none]="selectedWayOfSign !== 'E_SIGN'">
                                                        <!--- DIGITAL SIGN -->
                                                        <div class="mt-3">
                                                            <label class="text-primary">{{'ServiceContractDetail_signatureList' | translate}}:</label>
                                                            <hr class="mb-3">
                                                            <table id="list-signbox"
                                                                class="list-signature table table-sm table-bordered header-freeze">
                                                                <thead class="bg-tb-header">
                                                                    <tr>
                                                                        <th>{{'ServiceContractDetail_tb_index' | translate}}</th>
                                                                        <th class="th_sig_rectangle">{{'ServiceContractDetail_thongsotoado' | translate}}</th>
                                                                        <th>{{'ServiceContractDetail_signaturePage' | translate}}</th>
                                                                        <th>{{'ServiceContractDetail_action' | translate}}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                </tbody>
                                                            </table>
                                                            <button *ngIf="contract.allowAddSignBox" role="button"
                                                                class="btn d-flex align-items-center text-primary px-0 text-decoration-underline"
                                                                (click)="addSignBox()"> + 
                                                                {{'ServiceContractDetail_add_signature' | translate}}
                                                            </button>
                                                        </div>

                                                        <div class="row mt-3">
                                                            <div class="col-12">
                                                                <label class="text-primary">{{'ServiceContractDetail_imgSignature' | translate}}</label>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <form [formGroup]="formDSign">
                                                                    <div class="form-group">
                                                                        <label>{{'ServiceContractDetail_fileAttachment' | translate}}:<span
                                                                                class="text-danger">*</span>:</label>
                                                                        <div class="input-group mb-1">
                                                                            <div class="custom-file custom-file-icon">
                                                                                <label class="custom-file-label"
                                                                                    for="inputGroupFile03">{{ inputImgESignLabel | translate }}</label>
                                                                                <input formControlName="imageFile"
                                                                                    (input)="handleFileImgESignInput($event)"
                                                                                    type="file" accept="image/*"
                                                                                    class="custom-file-input"
                                                                                    id="inputGroupFile03"
                                                                                    aria-describedby="inputGroupFileAddon01">
                                                                                <span
                                                                                    class="icon-file material-icons">attach_file</span>
                                                                            </div>
                                                                        </div>
                                                                        <app-show-validate-errors [useSubmit]="false"
                                                                            [isSubmit]="isESignSubmit"
                                                                            [errorMessages]="validMsgESign.imageFile"
                                                                            [control]="formDSign.controls.imageFile"
                                                                            [detail]="{ name: 'imageFile' }">
                                                                        </app-show-validate-errors>
                                                                    </div>
                                                                </form>

                                                                <img *ngIf="imgEsignConfig" class="w-100"
                                                                    [src]="imgEsignConfig">
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div *ngIf="selectedWayOfSign == 'SMARTCA'" id="smartca">
                                                        <!-- VNPT CA -->
                                                        <app-smart-ca
                                                            #smartCASign
                                                            [contractData]="contract"
                                                            [contractId]="contractId"
                                                            (savedSignature)="savedSignatureFromSmartCA($event)"
                                                            (expiredSessionSmartCA)="expiredSessionSmartCA($event)"
                                                            (isWaitingConfirmSmartCA)="isWaitingConfirmSmartCA($event)"
                                                            (getCAInfo)="getCAInfo($event)"
                                                        ></app-smart-ca>
                                                    </div>
                                                </div>
                                            </div>
                                            <div *ngIf="currentStepSign === 2" class="sign-step">
                                                <p *ngIf="!(selectedWayOfSign == 'SMARTCA')">{{'ServiceContractDetail_descripBtnFinishSign' | translate}}</p>
                                                <p *ngIf="selectedWayOfSign == 'SMARTCA'">{{'ServiceContractDetail_checkInforSign' | translate}}</p>
                                            </div>
                                        </div>

                                        <ng-template #notAllowSign>
                                            <div id="detail-SIGN" class="">
                                                <div *ngIf="contract.currentStage != 'LC_DRAFT_CREATE' && allowGenContractNumber" class="mt-3">
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <span>{{ 'ServiceContract_contractNumberDesc' | translate }}</span>
                                                        </div>
                                                        <div class="col-sm-12 mt-3">
                                                            <div class="contract-number-form d-flex flex-column mb-3">
                                                                <label>{{ 'ServiceContract_contractNumber' | translate }}</label>
                                                                <div class="form-group d-flex mb-1">
                                                                    <input [(ngModel)]="contractNumberBox!.text" class="form-control"
                                                                        type="text" disabled>
                                                                    <div class="d-flex justify-content-center align-items-center">
                                                                        <button class="btn btn-secondary btn-icon mr-1"
                                                                        (click)="getNextContractNumber()" >
                                                                        <span class="material-icons-outlined">
                                                                            arrow_drop_up
                                                                            </span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <h5 class="ec-tab-content-title mt-3">{{'ServiceContractDetail_inforSignature' | translate}}</h5>
                                                <span class="my-3 d-flex text-grey-darkest">{{ contract.contractPartysData.length }} {{'ServiceContractDetail_partyJoin_1' | translate}} - {{ contract.contractPartysData.length }} {{'ServiceContractDetail_member' | translate}}</span>
                                                <div>
                                                    <span *ngIf="currentPartySign && currentPartySign.email">{{'ServiceContractDetail_currentUserSign' | translate}} {{ currentPartySign.email }}</span>
                                                    <p *ngIf="currentSequence > 0">{{'ServiceContractDetail_yourIndexSign' | translate}} {{ currentSequence }}</p>
                                                </div>
                                                <div *ngFor="let item of signatureParties; let i = index" class="mb-3">
                                                    <div class="signature-information-block"
                                                        [class.border-success]="item.isSigned && item.cancelDate == ''"
                                                        [class.border-primary]="!item.isSigned && currentPartySign && currentPartySign.email === item.email && item.cancelDate == ''"
                                                        [class.border-secondary]="!item.isSigned && currentPartySign && currentPartySign.email !== item.email && item.cancelDate == ''"
                                                        [class.border-danger]="item.cancelDate != '' || (item.isSigned && item.approvalSignStatus == 'FAIL')">
                                                        <div class="signature-title-block px-3 py-2"
                                                            [class.signature-title-background]="expandMoreSignInfor[i]">
                                                            <div class="row" (click)="expandSignInfor(i)">
                                                                <div class="col-md-1">
                                                                    <span class="span-status mr-2"
                                                                        [class.bg-success-new]="item.isSigned && item.approvalSignStatus == 'VALID'"
                                                                        [class.bg-warning-new]="item.isSigned && item.approvalSignStatus == 'WARNING'"
                                                                        [class.bg-primary-new]="!item.isSigned && currentPartySign && currentPartySign.email === item.email && item.cancelDate == ''"
                                                                        [class.bg-secondary]="!item.isSigned && currentPartySign && currentPartySign.email !== item.email && item.cancelDate == ''"
                                                                        [class.bg-danger]="item.cancelDate != '' || (item.isSigned && item.approvalSignStatus == 'FAIL')"
                                                                        style="width: 24px; height: 24px; border-radius: 50%;">
                                                                        <span
                                                                            *ngIf="item.isSigned && item.approvalSignStatus == 'VALID'"
                                                                            class="material-icons">done</span>
                                                                        <span
                                                                            *ngIf="item.isSigned && item.approvalSignStatus == 'WARNING'">i</span>
                                                                        <span
                                                                            *ngIf="item.isSigned && item.approvalSignStatus == 'FAIL'" class="text-white">x</span>
                                                                        <span
                                                                            *ngIf="!item.isSigned && currentPartySign && item.cancelDate == ''"
                                                                            class="text-white">{{
                                                                            item.sequence }}</span>
                                                                        <span
                                                                            *ngIf="item.cancelDate != '' && !item.isSigned"
                                                                            class="material-icons-outlined text-white">
                                                                            clear
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <div class="col-md-10">
                                                                    <span class="font-weight-bold">{{ item.email || item.sdt }}
                                                                    </span>
                                                                    -
                                                                    <span *ngIf="item.isSigned && item.approvalSignStatus != 'FAIL'" class="text-success">
                                                                        Đã ký hợp đồng
                                                                    </span>
                                                                    <span *ngIf="item.isSigned && item.approvalSignStatus == 'FAIL'" class="text-danger">
                                                                        Ký thất bại
                                                                    </span>
                                                                    <span *ngIf="!item.isSigned && item.cancelDate == ''" class="">
                                                                        Chưa ký hợp đồng
                                                                    </span>
                                                                    <span *ngIf="item.cancelDate != ''" class="text-danger"> 
                                                                        Đã từ chối ký hợp đồng
                                                                    </span>
                                                                    <br>
                                                                    <!-- <span class="ec-part-name">{{ item.tenToChuc ||
                                                                        item.fullName }}</span> -->
                                                                    <span class="party-name">{{ item.tenToChuc ||
                                                                        item.fullName }}</span>
                                                                    <br>
                                                                    <!-- Ký success, warning -->
                                                                    <span *ngIf="item.isSigned && item.signatureApproval" class="signature-form-label">
                                                                        <span class="signature-form">
                                                                            {{ getSignFormAndSignDateLabel(item.signatureApproval)[0] }}
                                                                        </span>
                                                                        -
                                                                        {{getSignFormAndSignDateLabel(item.signatureApproval)[1] | date: 'dd/MM/yyy'}}
                                                                    </span>
                                                                    <!-- Từ chối ký -->
                                                                    <span *ngIf="item.cancelDate != ''"
                                                                        class="signature-form-label">
                                                                        {{ item?.cancelDate
                                                                        | date: 'dd/MM/yyy'}}</span>
                                                                </div>
                                                                <div class="col-md-1 area-expand-icon">
                                                                    <span
                                                                        *ngIf="item.signatures && item.signatures.length > 0"
                                                                        class="material-icons-outlined"
                                                                        style="cursor: pointer;"
                                                                        [style.transform]="expandMoreSignInfor[i] ? 'rotate(180deg)' : 'rotate(0deg)'">
                                                                        keyboard_arrow_down
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <!-- Đã ký hợp đồng -->
                                                        <div *ngIf="item.signatures && item.signatures.length > 0" class="signature-body-block">
                                                            <app-expanded-signature-information
                                                                *ngIf="expandMoreSignInfor[i]"
                                                                [signatures]="item.signatures"
                                                                [show]="expandMoreSignInfor[i]"
                                                                [contract]="contract"
                                                                [userType]="item.userType"
                                                                (checkApprovalSignStatus)="checkApprovalSignStatus($event)"
                                                                (openRequestResignModalEvent)="openRequestResignModal($event)"
                                                                (eventReload)="handleReloadFormSignInfo($event)">
                                                            </app-expanded-signature-information>
                                                        </div>
                                                        <!-- Đã từ chối ký hợp đồng -->
                                                        <div *ngIf="item.cancelDate != '' && expandMoreSignInfor[i]"
                                                            class="signature-body-block">
                                                            <div class="signature-body-block px-3 pt-3">
                                                                <div class="row">
                                                                    <div class="col-md-1"></div>
                                                                    <div class="col-md-10">
                                                                        <label>Lý do từ chối</label>
                                                                        <p class="word-break">{{ item.cancelReason ||
                                                                            "Không có lý do"
                                                                            }}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <!-- <div class="ec-block-status-icon">
                                                            <span class="span-status mr-2"
                                                                [class.bg-success-new]="item.isSigned"
                                                                [class.bg-primary-new]="!item.isSigned && currentPartySign && currentPartySign.email === item.email && item.cancelDate == ''"
                                                                [class.bg-secondary]="!item.isSigned && currentPartySign && currentPartySign.email !== item.email && item.cancelDate == ''"
                                                                [class.bg-danger]="item.cancelDate != ''"
                                                                style="width: 24px; height: 24px; border-radius: 50%;">
                                                                <span *ngIf="item.isSigned"
                                                                    class="material-icons">done</span>
                                                                <span *ngIf="!item.isSigned" class="text-white">{{
                                                                    item.sequence }}</span>
                                                            </span>
                                                        </div> -->
                                                        <div *ngIf="false" class="ec-block-status-content">
                                                            <!-- <div class="sign-title d-flex">
                                                                <div class="row">
                                                                    <div class="col-md-auto">
                                                                        <p>
                                                                            <span class="font-weight-bold">{{ item.email }} </span>
                                                                            -
                                                                            <span *ngIf="item.isSigned" class="text-success"> Đã ký hợp đồng</span>
                                                                            <span *ngIf="!item.isSigned && item.cancelDate == ''" class=""> Chưa ký hợp đồng</span>
                                                                            <span *ngIf="item.cancelDate != ''" class="text-danger"> Từ chối ký hợp đồng</span>
                                                            <span class="ec-part-name">{{ item.tenToChuc || item.fullName }}</span>

                                                                        </p>
                                                                    </div>
                                                                    <div class="col-md-1 ml-auto">
                                                                        <span class="material-icons-outlined">
                                                                            keyboard_arrow_down
                                                                            </span>
                                                                    </div>
                                                                </div>
                                                            </div> -->
                                                            <!-- <div class="sign-title mr-2">
                                                                <div class="row">
                                                                    <div class="col-md-11 area-title-text">
                                                                        
                                                                    </div>
                                                                    <div class="col-md-1 area-expand-icon">
                                                                       
                                                                    </div>
                                                                </div>
                                                            </div> -->


                                                            <!-- item.isSigned && item.userType === userTypeConst.CONSUMER  -->
                                                            <div *ngIf="item.cancelDate != ''" class="w-100">
                                                                <hr class="my-2">
                                                                <div class="row">
                                                                    <div class="e-block-field reason-deny col-12 mb-2">
                                                                        <label class="e-lb-field">{{'ServiceContractDetail_reasonCancel' | translate}}</label>
                                                                        <span>{{ item.cancelReason || ('ServiceContractDetail_noReasonCancel' | translate) }}</span>
                                                                    </div>
                                                                </div>
                                                            </div>




                                                            <!-- <div *ngIf="false" class="esign">
                                                                <hr class="my-3">
                                                                <div class="row">
                                                                    <div class="col-xl-6">
                                                                        <label>{{'ServiceContractDetail_personNameSign' | translate}}</label>
                                                                        <p>{{ item.fullName }}</p>
                                                                    </div>
                                                                    <div class="col-xl-6">
                                                                        <label>{{'ServiceContractDetail_personId' | translate}}</label>
                                                                        <p>{{ item.cmnd }}</p>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-xl-6">
                                                                        <label>{{'ServiceContractDetail_signdate' | translate}}</label>
                                                                        <p>{{ item.signDate }}</p>
                                                                    </div>
                                                                    <div class="col-xl-6">
                                                                        <label>{{'ServiceContractDetail_image' | translate}}</label>
                                                                        <img *ngIf="item.imageSign !== ''" class="w-100"
                                                                            [src]="img_prefix + item.imageSign">
                                                                    </div>
                                                                </div>
                                                            </div> -->
                                                            <!-- falseitem.isSigned && (item.userType === userTypeConst.BUSINESS || item.userType === userTypeConst.ENTERPRISE) -->
                                                            <!-- <div *ngIf="false" class="dsign">
                                                                <hr class="my-3">
                                                                <div class="row">
                                                                    <div class="col-xl-6">
                                                                        <label>{{'ServiceContractDetail_ownerCert' | translate}}</label>
                                                                        <p>{{ item.common_name }}</p>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-xl-6">
                                                                        <label>{{'ServiceContractDetail_sogiayto' | translate}}</label>
                                                                        <p>{{ item.cmnd || item.maSoThue }}</p>
                                                                    </div>
                                                                    <div class="col-xl-6">
                                                                        <label>{{'ServiceContractDetail_nhaCungCap' | translate}}</label>
                                                                        <p>{{ item.owner_name }}</p>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-xl-6">
                                                                        <label>{{'ServiceContractDetail_validDate' | translate}}</label>
                                                                        <p>{{ item.not_before }}</p>
                                                                    </div>
                                                                    <div class="col-xl-6">
                                                                        <label>{{'ServiceContractDetail_expireDate' | translate}}</label>
                                                                        <p>{{ item.not_after }}</p>
                                                                    </div>
                                                                </div>
                                                                <div class="row">
                                                                    <div class="col-xl-6">
                                                                        <label>{{'ServiceContractDetail_signdate' | translate}}</label>
                                                                        <p>{{ item.signDate | date: 'dd/MM/yyyy'}}</p>
                                                                    </div>
                                                                </div>
                                                            </div> -->
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- <div *ngFor="let item of signatureParties" class="border-bottom mb-3">
                                                    <div class="d-flex align-content-center mb-3">
                                                        <span class="span-status mr-2" 
                                                        [class.bg-success-new]="item.isSigned" 
                                                        [class.bg-primary-new]="!item.isSigned && currentPartySign && currentPartySign.email === item.email" 
                                                        [class.bg-secondary]="!item.isSigned && currentPartySign && currentPartySign.email !== item.email" 
                                                        style="width: 24px; height: 24px; border-radius: 50%;">
                                                            <span *ngIf="item.isSigned" class="material-icons">done</span>
                                                            <span *ngIf="!item.isSigned" class="">{{ item.sequence }}</span>
                                                        </span>
                                                        <span>{{ item.email }}</span>
                                                    </div>
                                                    <div *ngIf="item.isSigned && item.userType === userTypeConst.CONSUMER" class="esign">
                                                        <div class="row">
                                                            <div class="col-xl-6">
                                                                <label>Tên người ký</label>
                                                                <p>{{ item.fullName }}</p>
                                                            </div>
                                                            <div class="col-xl-6">
                                                                <label>Số CMND/CCCD/Hộ chiếu</label>
                                                                <p>{{ item.cmnd }}</p>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-xl-6">
                                                                <label>Ngày ký</label>
                                                                <p>{{ item.signDate }}</p>
                                                            </div>
                                                            <div class="col-xl-6">
                                                                <label>Hình ảnh</label>
                                                                <img *ngIf="item.imageSign !== ''" class="w-100"
                                                                    [src]="img_prefix + item.imageSign">
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="item.isSigned && (item.userType === userTypeConst.BUSINESS || item.userType === userTypeConst.ENTERPRISE)" class="dsign">
                                                        <div class="row">
                                                            <div class="col-xl-6">
                                                                <label>Chủ chứng thư</label>
                                                                <p>{{ item.common_name }}</p>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-xl-6">
                                                                <label>Số giấy tờ</label>
                                                                <p>{{ item.cmnd || item.maSoThue }}</p>
                                                            </div>
                                                            <div class="col-xl-6">
                                                                <label>Nhà cung cấp</label>
                                                                <p>{{ item.owner_name }}</p>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-xl-6">
                                                                <label>Ngày hiệu lực</label>
                                                                <p>{{ item.not_before }}</p>
                                                            </div>
                                                            <div class="col-xl-6">
                                                                <label>Ngày hết hạn</label>
                                                                <p>{{ item.not_after }}</p>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-xl-6">
                                                                <label>Ngày ký</label>
                                                                <p>{{ item.signDate | date: 'dd/MM/yyyy'}}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> -->
                                                <div *ngIf="checkAllowSign()">
                                                    <p>{{'ServiceContractDetail_terms_descrip_1' | translate}}</p>
                                                    <div class="form-check">
                                                        <input [(ngModel)]="checkboxDk1" class="form-check-input"
                                                            type="checkbox" value="" id="phanly">
                                                        <label class="form-check-label" for="phanly">
                                                            {{'ServiceContractDetail_terms_descrip_2' | translate}} <a class="text-primary text-decoration-underline cursor-pointer" (click)="showDieuKhoan($event)">{{'ServiceContractDetail_terms' | translate}}</a>
                                                        </label>
                                                    </div>
                                                    <div class="form-check">
                                                        <input [(ngModel)]="checkboxDk2" class="form-check-input"
                                                            type="checkbox" value="" id="chapnhan">
                                                        <label class="form-check-label" for="chapnhan">
                                                            {{'ServiceContractDetail_terms_descrip_3' | translate}}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </div>
                                    <div id="tab-chat" class="ctpv-scroll-content ec-tab-content"
                                        [class.d-none]="!(activeTab === 'CHAT') || !allowShowDiscussTab">
                                        <div>
                                            <h5 class="pt-3 ec-tab-content-title">{{'ServiceContractDetail_damphanhopdong' | translate}}</h5>
                                            <p>{{ListContractPartysData.length}} {{'ServiceContractDetail_benthamgiadamphan' | translate}} - {{ListContractPartysData.length}} {{'ServiceContractDetail_member' | translate}}</p>
                                        </div>
                                        <app-chat-manager *ngIf="activeTab === 'CHAT'" [contractId]="contractId"
                                            [currentTab]="activeTab" [contractData]="contract"
                                            [isDiscuss]="contract.userPermissions[USER_PERMISSION.PARTNER_DISCUSSABLE] || isCreator">
                                        </app-chat-manager>
                                        <h5 class="ec-tab-content-title mb-3">{{'ServiceContractDetail_ketQuaDamPhan' | translate}}</h5>
                                        <div *ngFor="let item of ListContractPartysData, let i = index">
                                            <div class="ec-block-status {{ getClassBorder(item.deal) }} d-block">
                                                <div class="ec-block-header" [class.active]="item.expanded"
                                                    (click)="item.expand()" data-toggle="collapse"
                                                    [attr.data-target]="'#collapseExample_partnerdiscuss_' + i"
                                                    aria-expanded="false"
                                                    [attr.aria-controls]="'collapseExample_partnerdiscuss_' + i">
                                                    <div class="ec-block-status-icon">
                                                        <span class="span-status mr-2"
                                                            [class.text-secondary]="item.deal == null"
                                                            [class.text-success]="item.deal == true"
                                                            [class.text-danger]="item.deal == false"
                                                            style="width: 24px; height: 24px; border-radius: 50%;">
                                                            <span class="material-icons">{{ imgStatus(item.deal)
                                                                }}</span>
                                                        </span>
                                                    </div>
                                                    <div class="ec-block-status-content">
                                                        <p *ngIf="item.deal === null"><span class="font-weight-bold">{{ item.email || item.fullName || item.common_name }}</span> - {{'ServiceContractDetail_chuaDongyDamPhan' | translate}}</p>
                                                        <p *ngIf="item.deal === true"><span class="font-weight-bold">{{ item.email || item.fullName || item.common_name }}</span> - <span class="text-success">{{'ServiceContractDetail_daDongyDamPhan' | translate}}</span></p>
                                                        <p *ngIf="item.deal === false"><span class="font-weight-bold">{{ item.email || item.fullName || item.common_name }}</span> - <span class="text-danger">{{'ServiceContractDetail_daTuChoiDamPhan' | translate}}</span></p>
                                                        <span class="ec-part-name">{{ item.fullName || item.common_name }}</span> 
                                                    </div>
                                                    <div class="ec-block-status-expand">
                                                        <em class="material-icons">{{ !item.expanded ? 'expand_more' :
                                                            'expand_less' }}</em>
                                                        <!-- <ng-template #expandedno>
                                                            <em class="material-icons">expand_less</em>
                                                        </ng-template> -->
                                                    </div>
                                                </div>
                                                <div class="ec-block-body collapse"
                                                    [attr.id]="'collapseExample_partnerdiscuss_' + i">
                                                    <div *ngFor="let partner of item.partnerDiscuss, let i = index"
                                                        class="ec-block-body-item">
                                                        <div class="ec-block-status-icon">
                                                            <span class="span-status mr-2"
                                                                [class.text-secondary]="partner.deal == null"
                                                                [class.text-success]="partner.deal == true"
                                                                [class.text-danger]="partner.deal == false"
                                                                style="width: 24px; height: 24px; border-radius: 50%;">
                                                                <span class="material-icons">{{ imgStatus(partner.deal)
                                                                    }}</span>
                                                            </span>
                                                        </div>
                                                        <div class="ec-block-status-content">
                                                            <p *ngIf="partner.deal === null"><span class="font-weight-bold">{{ partner.email || partner.fullName }}</span> - {{'ServiceContractDetail_chuaDongyThoaThuan' | translate}}</p>
                                                            <p *ngIf="partner.deal === true"><span class="font-weight-bold">{{ partner.email || partner.fullName }}</span> - <span class="text-success">{{'ServiceContractDetail_daDongyThoaThuan' | translate}}</span></p>
                                                            <p *ngIf="partner.deal === false"><span class="font-weight-bold">{{ partner.email || partner.fullName }}</span> - <span class="text-danger">{{'ServiceContractDetail_daTuChoiThoaThuan' | translate}}</span></p>
                                                            <span class="ec-part-name">{{ partner.fullName }}</span> 
                                                            <div *ngIf="partner.deal === false" class="reason-deny">
                                                                <hr class="my-2">
                                                                <label class="font-weight-bold">{{'ServiceContractDetail_lyDoTuChoi' | translate}}</label>
                                                                <p>{{ partner.reason }}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="tab-internal-discuss" class="ctpv-scroll-content ec-tab-content"
                                        [class.d-none]="!(activeTab === 'INTERNAL_DISCUSS') || !allowShowInternalDiscussTab">
                                        <div>
                                            <h5 class="pt-3 ec-tab-content-title">{{'ServiceContractDetail_thamDinhHopDong' | translate}}</h5>
                                            <p>{{contract.internalDiscuss.length}} {{'ServiceContractDetail_member' | translate}}</p>
                                        </div>
                                        <app-chat-manager *ngIf="activeTab === 'INTERNAL_DISCUSS'"
                                            [contractId]="contractId" [currentTab]="activeTab" [contractData]="contract"
                                            [isDiscuss]="contract.userPermissions[USER_PERMISSION.INTERNAL_DISCUSSABLE] || isCreator"
                                            [typeDiscuss]="'internal'">
                                        </app-chat-manager>
                                        <hr>
                                        <h5 class="ec-tab-content-title mb-3">{{'ServiceContractDetail_ketQuaThamDinh' | translate}}</h5>
                                        <div *ngFor="let item of contract.internalDiscuss; let i = index">
                                            <div
                                                class="ec-block-status {{ getClassBorder(item.deal, item.userId, item.sequence) }}">
                                                <div class="ec-block-header">
                                                    <div class="ec-block-status-icon">
                                                        <span class="span-status mr-2"
                                                            [class.text-secondary]="item.deal == null"
                                                            [class.text-success]="item.deal == true"
                                                            [class.text-danger]="item.deal == false"
                                                            style="width: 24px; height: 24px; border-radius: 50%;">
                                                            <span class="material-icons">
                                                                {{ imgStatus(item.deal) }}
                                                            </span>
                                                        </span>
                                                    </div>
                                                    <div class="ec-block-status-content">
                                                        <p *ngIf="item.deal === null"><span class="font-weight-bold">{{ item.email || item.fullName || item.common_name }}</span> - {{'ServiceContractDetail_chuaDongyThoaThuan' | translate}}</p>
                                                        <p *ngIf="item.deal === true"><span class="font-weight-bold">{{ item.email || item.fullName || item.common_name }}</span> - <span class="text-success">{{'ServiceContractDetail_daDongyThoaThuan' | translate}}</span></p>
                                                        <p *ngIf="item.deal === false"><span class="font-weight-bold">{{ item.email || item.fullName || item.common_name }}</span> - <span class="text-danger">{{'ServiceContractDetail_daTuChoiThoaThuan' | translate}}</span></p>
                                                        <span class="ec-part-name">{{ item.fullName || "" }}</span> 
                                                        <div *ngIf="item.deal === false" class="reason-deny">
                                                            <hr class="my-2">
                                                            <label class="font-weight-bold">{{'ServiceContractDetail_lyDoTuChoi' | translate}}</label>
                                                            <p>{{ item.reason }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="tab-censorship" class="ctpv-scroll-content ec-tab-content"
                                        *ngIf="activeTab === 'CENSORSHIP'">
                                        <app-contract-censorship #contractCensorship *ngIf="activeTab === 'CENSORSHIP'"
                                            [contractData]="contract" [fileContract]="filePDF" [sdt]="userInfo.sdt"
                                            (reload)="handleEventCensorship($event)"
                                            (haveSessionEsign)="haveSessionEsignEvent($event)"
                                            (changedSignType)="changedSignTypeEvent($event)"
                                            (signStepChanged)="signStepChanged($event)"
                                            (changedSignForm)="changedSignForm($event)"
                                            (expiredSessionSmartCA)="handleEventExpiredSessionSmartCAInternal($event)"
                                            (isWaitingConfirmSmartCA)="handleEventIsWaitingConfirmSmartCAInternal($event)">
                                        </app-contract-censorship>
                                    </div>
                                    <div id="tab-document" class="ctpv-scroll-content ec-tab-content"
                                    *ngIf="activeTab === 'DOCUMENT'">
                                        <app-document-in-contract
                                        #document
                                        [documents]="documents" [contractId]="contractId" (resetTabDocument)="resetTabDocument()"
                                        (changeToEdit)="changeToEdit()" [isOwnerParty]="contract.isOwnerParty" (viewDetailDocument)="viewDetailDocument($event)"
                                        ></app-document-in-contract>
                                    </div>
                                    <div id="tab-version" class="ctpv-scroll-content ec-tab-content"
                                    *ngIf="activeTab === 'VERSION'">
                                        <app-version-in-contract 
                                            #versionInContract
                                            [contractId]="contractId" 
                                            [contract]="contract"
                                            (listVersion)="getListVersion($event)" 
                                            (versionSelected)="getVersionSelected($event)"
                                            (selectedPost)="selectedPost($event)"
                                            (calledHidePopop)="calledHidePopop($event)">
                                        </app-version-in-contract>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>

<ng-template #contentConfirmOTP let-c="close" let-d="dismiss">
    <div class="modal-header border-0 pb-0 mb-0">
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span class="material-icons">
                cancel
            </span>
        </button>
    </div>
    <div class="modal-body py-0">
        <h5 class="modal-title w-100 text-center mb-3" id="modal-basic-title">{{'ServiceContractDetail_xacNhanOTP' | translate}}</h5>
        <form [formGroup]="formOTP">
            <div class="form-group">
                <label>{{'ServiceContractDetail_maOTP' | translate}} <span class="text-danger">*</span>: </label>
                <input formControlName="otp" class="form-control" type="text" placeholder="{{'ServiceContractDetail_nhapMaOTP' | translate}}">
                <app-show-validate-errors [isSubmit]="isOTPSubmit" [errorMessages]="validMsgOTP.otp"
                    [control]="formOTP.controls.otp" [detail]="{ name: 'isOTPSubmit' }"></app-show-validate-errors>
            </div>
        </form>
    </div>
    <div class="modal-footer border-0 d-flex justify-content-center">
        <button type="button" class="btn btn-primary" (click)="submitOTP()">{{'ServiceContractDetail_btn_xacNhan' | translate}}</button>
    </div>
</ng-template>

<ng-template #otpConfirmSign let-c="close" let-d="dismiss">
    <div class="modal-header border-0 pb-0 mb-0">
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span class="material-icons">
                cancel
            </span>
        </button>
    </div>
    <form [formGroup]="otpSignForm">
        <div class="modal-body py-0">
            <h5 class="modal-title w-100 text-center mb-3" id="modal-basic-title"><label>{{'ServiceContractDetail_xacNhanOTP' | translate}}</label></h5>

            <div class="form-group">
                <label>{{'ServiceContractDetail_maOTP' | translate}}<span class="text-danger">*</span>: </label>
                <!-- appInputPassword -->
                <input formControlName="otp" class="form-control form-control-lg" type="text" placeholder="{{'ServiceContractDetail_nhapMaOTP' | translate}}"
                    (input)="handleInputOTP($event)">
                <app-show-validate-errors [isSubmit]="otpSignIsSubmit" [errorMessages]="validateMsgOTPSign.otp"
                    [control]="otpSignForm.controls.otp" [detail]="{ name: 'otp' }"></app-show-validate-errors>
            </div>
        </div>
        <div class="modal-footer border-0 d-flex justify-content-center">
            <button [disabled]="this.otpSignForm.invalid" type="submit" class="btn btn-lg btn-primary"
                (click)="submitConfirmOTPSign()">{{'ServiceContractDetail_btn_xacNhan' | translate}}</button>
        </div>
    </form>
</ng-template>

<ng-template #updateContractFile let-c="close" let-d="dismiss">
    <div  class="modal-header border-0">
        <h5 class="modal-title" style="font-weight: 600; margin-right: auto;">{{'ServiceContractDetail_thayDoiNDhopDong' | translate}}</h5>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="btn btn-light bg-exit-btn d-inline-flex"><span class="material-icons">
                    close
                </span></span>
        </button>
    </div>
    <div class="modal-body text-left py-0 enable-scroll">
        <div class="mb-0">
            <form [formGroup]="formUpdateContractFile">
                <p>{{'ServiceContractDetail_capNhatFileHopDong_mota' | translate}}</p>
                <div class="form-group">
                    <label>{{'ServiceContractDetail_contractFile' | translate}}</label>
                    <div class="input-group mb-1">
                        <div class="custom-file custom-file-icon">
                            <input formControlName="file" (input)="handleFileInput($event)" type="file"
                                accept=".pdf,.docx" class="custom-file-input" id="inputGroupFile01"
                                aria-describedby="inputGroupFileAddon01">
                            <label class="custom-file-label"
                                for="inputGroupFile01">{{ lbFieldContractFile | translate }}</label>
                            <span
                                class="icon-file material-icons">attach_file</span>
                        </div>
                    </div>
                    <app-show-validate-errors [isSubmit]="isSubmitFormUpdateContractFile"
                        [errorMessages]="validMsgUpdateContractFile.file"
                        [control]="formUpdateContractFile.controls.file" [detail]="{ name: 'file' }">
                    </app-show-validate-errors>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer border-0 d-flex justify-content-end">
        <button type="button" class="btn btn-outline-primary d-inline-flex"
            (click)="c('Cross click')">{{'btn_cancel' | translate}}</button>
        <button type="button" class="btn btn-primary d-inline-flex ml-3" ngbAutofocus
            (click)="confirmUpdateContractFile()">{{'ServiceContractDetail_btn_upload' | translate}}</button>
    </div>
</ng-template>

<ng-template #contentReturn let-c="close" let-d="dismiss">
    <div  class="modal-header border-0">
        <h5 class="modal-title" style="font-weight: 600; margin-right: auto;">{{'ServiceContractDetail_traLaiHopDong' | translate}}</h5>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="btn btn-light bg-exit-btn d-inline-flex"><span class="material-icons">
                    close
                </span></span>
        </button>
    </div>
    <div class="modal-body text-left py-0 enable-scroll">
        <form [formGroup]="formReturn">
            <div class="form-group">
                <label>{{'ServiceContractDetail_lyDoTraLai' | translate}} </label>
                <textarea formControlName="reasonReturn" class="form-control" rows="5" placeholder="{{'ServiceContractDetail_vuiLongNhapLyDoTraLai' | translate}}"></textarea>
                <app-show-validate-errors [isSubmit]="isCancelSubmit" [errorMessages]="validateMsgFormDenyDiscuss.reason"
                    [control]="formReturn.controls.reasonReturn" [detail]="{ name: 'reasonReturn' }"></app-show-validate-errors>
            </div>
        </form>
    </div>
    <div class="modal-footer border-0 d-flex justify-content-end">
        <button type="button" class="btn btn-outline-primary" (click)="c('Close')">{{'btn_cancel' | translate}}</button>
        <button type="button" class="btn btn-primary" (click)="submitReturn()">{{'btn_confirm' | translate}}</button>
    </div>
</ng-template>

<app-flow-settings *ngIf="showFlowSetting" [flowContract]="flowContractSelected" [id]="contract.contractFlowTemplateId"
    [notEditInfo]="true" [contract]="contract"
    [internalEditable]="contract.userPermissions[USER_PERMISSION.INTERNAL_EDITABLE] || isCreator"
    [partnerEditable]="contract.userPermissions[USER_PERMISSION.PARTNER_EDITABLE] || isCreator"
    [signFlowEditable]="signFlowEditable || isCreator" (closed)="flowSettingsClosed($event)">
</app-flow-settings>

<app-partner-discuss-modal *ngIf="showPartnerDiscussSetting" [flowContract]="flowContractSelected"
    [id]="contract.contractFlowTemplateId" [contract]="contract"
    [partnerEditable]="contract.userPermissions[USER_PERMISSION.PARTNER_EDITABLE] || isCreator"
    (closed)="partnerDiscussSettingsClosed($event)">
</app-partner-discuss-modal>
<app-internal-discuss-modal *ngIf="showInternalDiscussSetting" [flowContract]="flowContractSelected"
    [id]="contract.contractFlowTemplateId" [contract]="contract"
    [internalEditable]="contract.userPermissions[USER_PERMISSION.INTERNAL_EDITABLE] || isCreator"
    (closed)="intenralDiscussSettingsClosed($event)">
</app-internal-discuss-modal>

<ng-template #partnerDiscussDeny let-c="close" let-d="dismiss">
    <div  class="modal-header border-0">
        <h5 class="modal-title" style="font-weight: 600; margin-right: auto;">{{ 'ServiceContractDetail_tuChoiHopDong' | translate }}</h5>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="btn btn-light bg-exit-btn d-inline-flex"><span class="material-icons">
                    close
                </span></span>
        </button>
    </div>
    <div class="modal-body text-left py-0 enable-scroll">
        <form [formGroup]="formDenyDiscuss">
            <div class="form-group">
                <label>{{ 'ServiceContractDetail_lyDoTuChoiHopDong' | translate }}</label>
                <textarea formControlName="reason" class="form-control" rows="5" placeholder="{{ 'ServiceContractDetail_tuChoiHopDong_placeholder' | translate }}"></textarea>
                <app-show-validate-errors [useSubmit]="false" [errorMessages]="validateMsgFormDenyDiscuss.reason"
                    [control]="formDenyDiscuss.controls.reason" [detail]="{ name: 'reason' }">
                </app-show-validate-errors>
            </div>
        </form>
    </div>
    <div class="modal-footer border-0 d-flex justify-content-end">
        <button type="button" class="btn btn-outline-primary" (click)="c('Close')">{{ 'btn_cancel' | translate }}</button>
        <button type="button" class="btn btn-primary" [disabled]="formDenyDiscuss.invalid" (click)="confirmDenyContract()">{{ 'btn_confirm' | translate }}</button>
    </div>
</ng-template>

<ng-template #internalDiscussDeny let-c="close" let-d="dismiss">
    <div  class="modal-header border-0">
        <h5 class="modal-title" style="font-weight: 600; margin-right: auto;">{{ 'ServiceContractDetail_tuChoiHopDong' | translate }}</h5>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="btn btn-light bg-exit-btn d-inline-flex"><span class="material-icons">
                    close
                </span></span>
        </button>
    </div>
    <div class="modal-body text-left py-0 enable-scroll">
        <form [formGroup]="formDenyDiscuss">
            <div class="form-group">
                <label>{{ 'ServiceContractDetail_lyDoTuChoiHopDong' | translate }}</label>
                <textarea formControlName="reason" class="form-control" rows="5" placeholder="{{ 'ServiceContractDetail_tuChoiHopDong_placeholder' | translate }}"></textarea>
                <app-show-validate-errors [useSubmit]="false" [errorMessages]="validateMsgFormDenyDiscuss.reason"
                    [control]="formDenyDiscuss.controls.reason" [detail]="{ name: 'reason' }">
                </app-show-validate-errors>
            </div>
        </form>
        <div class="form-check">
            <input type="checkbox" [(ngModel)]="checkbox_addfooter" class="form-check-input" id="checkbox_addfooter">
            <label for="checkbox_addfooter">
                {{ 'ServiceContractDetail_internalDiscussDeny' | translate }}
            </label>
        </div>
    </div>
    <div class="modal-footer border-0 d-flex justify-content-end">
        <button type="button" class="btn btn-outline-primary" (click)="c('Close')">{{ 'btn_cancel' | translate }}</button>
        <button type="button" class="btn btn-primary" [disabled]="formDenyDiscuss.invalid" (click)="confirmDenyInternalDiscus()">{{ 'btn_confirm' | translate }}</button>
    </div>
</ng-template>

<app-request-resign 
    *ngIf="showRequestResignModal" 
    [partyInfo]="partyInfo" 
    [partiesJoin]="partiesJoin" 
    [partyIdWantEdit]="partyIdWantEdit" 
    (closed)="closeRequestResign($event)"
    (sendRequestResignEvent)="sendRequestResignEvent($event)">
</app-request-resign>

<ng-template #internalDiscussDeal let-c="close" let-d="dismiss">
    <div  class="modal-header border-0">
        <h5 class="modal-title" style="font-weight: 600; margin-right: auto;">{{ 'ServiceContractDetail_confirm' | translate }}</h5>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="btn btn-light bg-exit-btn d-inline-flex"><span class="material-icons">
                close
            </span></span>
        </button>
    </div>
    <div class="modal-body text-left py-0">
        <div> {{ 'contract_confirm_accept_internal_discuss' | translate}} </div>
        <div class="form-check">
            <input type="checkbox" [(ngModel)]="checkbox_addfooter" class="form-check-input" id="checkbox_addfooter">
            <label for="checkbox_addfooter">
                {{ 'ServiceContractDetail_internalDiscussDeal' | translate }}
            </label>
        </div>
    </div>
    <div class="modal-footer border-0 d-flex justify-content-end">
        <button type="button" class="btn btn-outline-primary" (click)="c('Close')">{{ 'btn_cancel' | translate }}</button>
        <button type="button" class="btn btn-primary" (click)="confirmDealInternalDiscus()">{{ 'btn_confirm' | translate }}</button>
    </div>
</ng-template>

<ng-template #modalCancelContract let-c="close" let-d="dismiss">
    <div class="modal-header border-0">
        <h5 class="modal-title" style="font-weight: 600; margin-right: auto;">Hủy hợp đồng</h5>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="btn btn-light bg-exit-btn d-inline-flex"><span class="material-icons">
                    close
                </span></span>
        </button>
    </div>
    <div class="modal-body text-left py-0 enable-scroll">
        <form [formGroup]="formReturn">
            <div class="form-group">
                <label>Lý do hủy hợp đồng: </label>
                <textarea formControlName="reasonReturn" class="form-control" rows="5"
                    placeholder="Vui lòng nhập lý do hủy hợp đồng"></textarea>
                <app-show-validate-errors [isSubmit]="isCancelSubmit"
                    [errorMessages]="validateMsgFormDenyDiscuss.reason" [control]="formReturn.controls.reasonReturn"
                    [detail]="{ name: 'reasonReturn' }"></app-show-validate-errors>
            </div>
        </form>
    </div>
    <div class="modal-footer border-0 d-flex justify-content-end">
        <button type="button" class="btn btn-outline-primary" (click)="c('Close')">Huỷ</button>
        <button type="button" class="btn btn-primary" (click)="submitReturn()">Xác nhận</button>
    </div>
</ng-template>