export class CategoryItem {
  public id: string;
  public productId: string;
  public productName: string;
  public productNote: string;
  public productService: Array<string>;
  public dateUpload: string;
  public isUsed: boolean = false;

  constructor(obj = null) {
    obj = obj || {};
    this.id = obj.id || '';
    this.productId = obj.productId || '';
    this.productName = obj.productName || '';
    this.productNote = obj.productNote || '';
    this.productService = obj.productService || [];
    this.dateUpload = obj.dateUpload || '';
    this.isUsed = obj.isUsed || false;
  }
}
