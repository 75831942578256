import { Injectable } from "@angular/core";
import { forkJoin } from "rxjs";
import { InfoPartyObject } from "../models/aas-models/info-party/info-party-object";
import { UserInformationsItem } from "../models/profile/user-info-item.model";
import { AuthService } from "../services/auth.service";
import { UserTypeConstant, VerifyStatusConst, ConstLocalStorage } from "./constant";

@Injectable({
    providedIn: 'root'
})
export class ControlAccount {

    public partyInfo = new InfoPartyObject();
    public userInfo = new UserInformationsItem();

    constructor(
        public authService: AuthService
    ) {

    }

    requestAPIGetAccountInfo(userId = this.authService.userId, partyId = this.authService.partyId, callBack = null) {
        if (this.authService.getToken()) {
            forkJoin([
                this.authService.getPartyInfo(this.authService.partyId),
                this.authService.getUserInfo(this.authService.userId)
              ]).subscribe((res: any) => {
                this.authService.setPartyInfo(new InfoPartyObject(res[0].object || {}));
                this.authService.setUserInfo(new UserInformationsItem(res[1].object || {}));

                this.partyInfo = new InfoPartyObject(res[0].object || {});
                this.userInfo = new UserInformationsItem(res[1].object || {});

                localStorage.setItem(ConstLocalStorage.USER_INFO, JSON.stringify(this.userInfo));
                localStorage.setItem(ConstLocalStorage.PARTY_INFO, JSON.stringify(this.partyInfo));

                callBack && callBack()
              }, err => {
                console.log(err);
              }, () => {
              });
        }
    }

    getAccountInfo() {
        this.authService.partyInfo.subscribe((res: InfoPartyObject) => {
            this.partyInfo = res;
        })

        this.authService.userInfo.subscribe((res: UserInformationsItem) => {
            this.userInfo = res;
        })
    }

    //MARK: Dieu kien hien thi menu theo level acc
    get isAccLevel2() {
        var uploadedGPKD = true; //this.partyInfo.gpkdFilename !== "";
        var level = `${this.partyInfo.identityLevel}` === "2";
        var level2 = `${this.userInfo.identityLevel}` === "2";
        
        let status = this.partyInfo.verificationStatus;
        let isApprivedBusiness = status === VerifyStatusConst.APPROVE || status === VerifyStatusConst.APPROVE_CONTRACT || status === VerifyStatusConst.APPROVE_NON_CONTRACT;

        let status1 = this.userInfo.verificationStatus;
        let isApprovedConsumer = status1 === VerifyStatusConst.APPROVE || status1 === VerifyStatusConst.APPROVE_CONTRACT || status1 === VerifyStatusConst.APPROVE_NON_CONTRACT;

        let isConsumer = this.userInfo.userType === UserTypeConstant.CONSUMER;
        let isBusiness = this.partyInfo.partyType === UserTypeConstant.BUSINESS || this.partyInfo.partyType === UserTypeConstant.ENTERPRISE;
        
        return (isConsumer && isApprovedConsumer && level2) || (isBusiness && isApprivedBusiness && uploadedGPKD && level);
    }

    get isConsumer() {
        return this.userInfo.userType === UserTypeConstant.CONSUMER;
    }

    //MARK: Request get data
    /**
    * Lấy thông tin user đăng nhập
    * @param id 
    */
    getUserInfo(id) {
        this.authService.getUserInfo(id).subscribe((res: any) => {
            this.authService.setUserInfo(new UserInformationsItem(res.object || {}));
        }, err => {
        });
    }

    /**
     * Thong tin party dang login
     * @param partyId 
     */
    getPartyInfo(partyId) {
        this.authService.getPartyInfo(partyId).subscribe((res: any) => {
            this.authService.setPartyInfo(new InfoPartyObject(res.object || {}));
        }, err => {
        });
    }

    public checkRoles(condition) {
        const token = this.authService.getToken();
        const token_decode = this.authService.jwtDecode(token);
    
        let ok = false;
    
        if (condition.length <= 0) return true;
    
        token_decode.authorities.forEach((item) => {
          if (condition.includes(item)) {
            ok = true
          }
        })
    
        return ok;
      }
}