<app-spinner></app-spinner>
<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6">
                <div class="card ec-card shadow-none">
                    <div class="card-header">
                        <div class="d-flex">
                            <div class="card-title-path flex-grow-1">
                                <h1 class="m-0 text-dark card-title"><label>{{ 'PartEdit_title' | translate }}</label></h1>
                            </div>
                            <div class="card-btn-path ml-auto text-right">
                                <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="cancel()"><span
                                        class="material-icons mr-1">close</span> {{ 'btn_cancel' | translate }}</button>
                                <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="setData(part)"><span class="material-icons-outlined mr-1">
                                            refresh
                                            </span>{{ 'btn_reset' | translate }}</button>
                                <button class="btn btn-primary d-inline-flex" (click)="save()"><span
                                        class="material-icons mr-1">save</span> {{ 'btn_save' | translate }}</button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <form [formGroup]="partForm">
                            <div class="form-group">
                                <label>{{ 'PartList_label_department_name' | translate }} <span class="text-danger">*</span>:</label>
                                <input appTrimValue class="form-control" placeholder="{{ 'PartEdit_enter_name' | translate }}" formControlName="partyName">
                                <app-show-validate-errors [isSubmit]="isSubmit"
                                    [errorMessages]="validatePartForm.partyName" [control]="partForm.controls.partyName"
                                    [detail]="{ name: 'tenBoPhan' }"></app-show-validate-errors>
                            </div>
                            <div class="form-group">
                                <label>{{ 'PartList_label_description' | translate }}:</label>
                                <textarea appTrimValue class="form-control" rows="5" formControlName="descr" placeholder="{{ 'PartEdit_enter_desc' | translate }}"></textarea>
                            </div>
                            <div class="form-group">
                                <label>{{ 'PartList_label_phone_number' | translate }}:</label>
                                <input appTrackerPhoneNumber onKeyPress="if(this.value.length==10) return false;" class="form-control" placeholder="{{ 'PartEdit_enter_phone_number' | translate }}" formControlName="phoneNumber">
                                <app-show-validate-errors [isSubmit]="isSubmit"
                                    [errorMessages]="validatePartForm.phoneNumber" [control]="partForm.controls.phoneNumber"
                                    [detail]="{ name: 'sdt' }"></app-show-validate-errors>
                            </div>
                            <div class="form-group">
                                <label>{{ 'PartList_label_fax_number' | translate }}:</label>
                                <input appTrimValue class="form-control" placeholder="{{ 'PartEdit_enter_fax_number' | translate }}" formControlName="fax">
                            </div>
                            <div class="form-group">
                                <label>{{ 'status' | translate }} <span class="text-danger">*</span>:</label>
                                <ng-select [searchable]="false" placeholder="{{ 'PartList_select_status' | translate }}" [clearable]="false"
                                    formControlName="disable">
                                    <ng-option [value]="false">{{ 'status_running' | translate }}</ng-option>
                                    <ng-option [value]="true">{{ 'status_stopped' | translate }}</ng-option>
                                </ng-select>
                                <app-show-validate-errors [isSubmit]="isSubmit"
                                    [errorMessages]="validatePartForm.disable" [control]="partForm.controls.disable"
                                    [detail]="{ name: 'disable' }"></app-show-validate-errors>
                            </div>

                            <div class="row mb-2">
                                <div class="col">
                                    <a  class="text-primary text-decoration-underline cursor-pointer" (click)="showListEmployee($event, part.id)">{{ 'PartEdit_label_view_list_personnel' | translate }}</a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<ng-template #employeeInPart let-c="close" let-d="dismiss">
    <div class="modal-header border-0 mb-0">
        <h5 class="modal-title" style="font-weight: 600; margin-right: auto;">{{ 'PartEdit_label_list_personnel_in_department' | translate }}
        </h5>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="btn btn-light bg-exit-btn d-inline-flex"><span class="material-icons">
                close
            </span></span>
        </button>
    </div>
    <div class="modal-body py-0">
        <app-employee-in-part #appEmployeeInPart [partyId]="id"></app-employee-in-part>
    </div>
</ng-template>