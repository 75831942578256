<app-spinner></app-spinner>
<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-auto">
                <h1 class="m-0 text-dark"><label>{{ 'PartList_title' | translate }}</label></h1>
            </div>
            <!-- /.col -->
            <div class="col-md-auto ml-auto">
                <button (click)="addPart()" class="btn btn-primary d-inline-flex mr-3"><span
                    class="material-icons mr-1">add</span> {{ 'btn_addnew' | translate }}</button>
            </div>
            <!-- <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>

<!--MARK top bar -->
<section class="content">
    <div class="container-fluid">
        <div class="bg-white">
            <section class="p-3">
                <div class="row">
                    <div class="col-lg-3 col-md-5">
                        <div class="input-group border rounded mb-3">
                            <div class="input-group-prepend border-0">
                                <button class="btn d-inline-flex pr-2 pl-2" type="submit"><span
                                        class="material-icons">search</span></button>
                            </div>
                            <input (keyup.enter)="enterSearch()" type="text" class="form-control border-0"
                                [(ngModel)]="keySearch" placeholder="{{ 'PartList_label_search' | translate }}..." aria-label=""
                                aria-describedby="basic-addon1">
                        </div>
                    </div>
                    <div class="col-md-auto ml-auto">
                        <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="cleanFilter()"> {{ 'btn_reset' | translate }}</button>
                        <button class="btn btn-primary d-inline-flex mr-3" (click)="searchWithFilter()"> {{ 'btn_apply' | translate }}</button>
                    </div>
                </div>
            </section>
            <hr class="my-2">
            <section class="px-3 my-2">
                <div class="row">
                    <div class="col-md-2" >
                        <div class="form-group">
                            <label for="status">{{ 'status' | translate }}</label>
                            <ng-select labelForId="status" [(ngModel)]="status" [searchable]="false" [clearable]="false" placeholder="{{ 'PartList_select_status' | translate }}">
                                <ng-option [value]="''">{{ 'status_all' | translate }}</ng-option>
                                <ng-option [value]="0">{{ 'status_running' | translate }}</ng-option>
                                <ng-option [value]="1">{{ 'status_stopped' | translate }}</ng-option>
                            </ng-select>
                        </div>
                    </div>
                </div>
            </section>
           
            <section class="card-body p-0">
                <div class="table-responsive">
                    <table class="table mb-0">
                        <thead>
                            <tr class="bg-tb-header border-top text-left">
                                <th>{{ 'PartList_label_stt' | translate }}</th>
                                <th>{{ 'PartList_label_department_name' | translate }}</th>
                                <th>{{ 'PartList_label_description' | translate }}</th>
                                <th>{{ 'PartList_label_phone_number' | translate }}</th>
                                <th>{{ 'PartList_label_fax_number' | translate }}</th>
                                <th>{{ 'PartList_status' | translate }}</th>
                                <th>{{ 'PartList_label_action' | translate }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of parts; let i = index" class="text-left">
                                <td>{{ (page - 1)*maxSize + i + 1 }}</td>
                                <td><a class="text-decoration-underline text-primary cursor-pointer" (click)="detailPart(item.partyId)">{{ item.partyName || "" }}</a></td>
                                <td>{{ item.descr || "" }}</td>
                                <td>{{ item.phoneNumber || "" }}</td>
                                <td>{{ item.fax || "" }}</td>
                                <td><span class="badge {{ getClassStatus(item.disable) }} p-2">{{ getTextStatus(item.disable) }}</span></td>
                                <td class="text-left">
                                    <div ngbDropdown container="body">
                                        <span class="material-icons cursor-pointer" ngbDropdownToggle id="settingTable">more_vert</span>
                                        <div ngbDropdownMenu class="border-0">
                                            <a ngbDropdownItem (click)="detailPart(item.partyId)"
                                                class="dropdown-item cursor-pointer d-flex">
                                                <span class="material-icons-outlined mr-3">info</span>{{ 'PartList_label_detail' | translate }}</a>

                                            <a ngbDropdownItem (click)="editPart(item.partyId)" class="dropdown-item cursor-pointer d-flex"><span
                                                    class="material-icons mr-3">edit</span>{{ 'PartList_label_edit' | translate }}</a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="bg-tb-header p-3">
                    <div class="row align-items-center">
                        <span *ngIf="totalElement == 0" class="col-md">{{ 'PartList_label_no_results' | translate }}</span>
                        <span *ngIf="totalElement > 0" class="col-md">{{ 'PartList_label_amount_result' | translate : {from: 1, to: parts.length, total: totalElement} }}</span>
                        <div class="col-md">
                            <div class="d-flex justify-content-end">
                                <div *ngIf="totalElement > maxSize" class="d-flex justify-content-end align-items-center">
                                    <span class="mr-2">{{ 'PartList_label_number_of_lines' | translate }}:</span>
                                    <ng-select class="no-bg no-border" [(ngModel)]="maxSize" [searchable]="false" [clearable]="false" placeholder="..." (change)="loadPage(1)">
                                        <ng-option [value]="10">10</ng-option>
                                        <ng-option [value]="20">20</ng-option>
                                        <ng-option [value]="30">30</ng-option>
                                    </ng-select>
                                </div>
                                <div *ngIf="totalElement > maxSize" class="pagination pages no-border-pagination ml-2">
                                    <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="false"
                                        [pageSize]="maxSize" (pageChange)="loadPage($event)" [boundaryLinks]="true" aria-label="Default pagination">
                                        <ng-template ngbPaginationFirst>{{ 'PartList_label_first' | translate }}</ng-template>
                                        <ng-template ngbPaginationLast>{{ 'PartList_label_last' | translate }}</ng-template>
                                        <ng-template ngbPaginationPrevious>&laquo;</ng-template>
                                        <ng-template ngbPaginationNext>&raquo;</ng-template>
                                        <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                                    </ngb-pagination>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </section> 
        </div>
    </div>
</section>