import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Token } from 'src/app/utils/models/token.model';
import { AuthService } from 'src/app/utils/services/auth.service';
import { LocalStorageKey, LoginType, QueryParamKey } from './wv-ultils/wv-const';
import { WebviewURL } from './wv-ultils/wv-const-url';

@Component({
  selector: 'app-wv-app',
  templateUrl: './wv-app.component.html',
  styleUrls: ['./wv-app.component.scss']
})
export class WvAppComponent implements OnInit {

  public backFn: any = null;
  public isExit: boolean = false;
  path = '';

  constructor(
    private activeRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.authService.removeAllLocalStorage(); //Remove cache old session
    this.path = this.activeRoute.snapshot.url[0].path;

    let param = {};
    param[QueryParamKey.CONTRACTGROUPID] = this.activeRoute.snapshot.queryParamMap.get(QueryParamKey.CONTRACTGROUPID) || "";
    param[QueryParamKey.DOCUMENTID] = this.activeRoute.snapshot.queryParamMap.get(QueryParamKey.DOCUMENTID) || "";
    param[QueryParamKey.ACTION] = this.activeRoute.snapshot.queryParamMap.get(QueryParamKey.ACTION) || "";
    param[QueryParamKey.CLIENTID] = this.activeRoute.snapshot.queryParamMap.get(QueryParamKey.CLIENTID) || "";
    param[QueryParamKey.CLIENT_SECRET] = this.activeRoute.snapshot.queryParamMap.get(QueryParamKey.CLIENT_SECRET) || "";
    param[QueryParamKey.LOGIN_TYPE] = this.activeRoute.snapshot.queryParamMap.get(QueryParamKey.LOGIN_TYPE) || "";
    
    localStorage.setItem(LocalStorageKey.queryParam, JSON.stringify(param));

    if (param[QueryParamKey.LOGIN_TYPE] == LoginType.OTP) {
      this.router.navigate([WebviewURL.login_otp]);
    }

  }

  get getUsername() {
    return this.authService.username;
  }

  isLogin() {
    return this.authService.getToken();
  }

  wvLogout() {
    this.authService.wvLogout();
  }

  goToBack() {
    this.backFn && this.backFn();
  }
}
