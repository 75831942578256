import {ListUserObject} from './list-user-object.model';
import {MainResponse} from './../../main-response.model';

export class ListUserResponse extends MainResponse {
    object: ListUserObject;

    constructor(obj = null) {
        super();
        obj = obj || {};

        this.init(obj);
        this.object = new ListUserObject(obj.object);
    }
}
