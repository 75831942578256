export class Employee {
    disable: any;
    email: string = "";
    partyId: string = "";
    phoneNumber: string = "";
    fullName: string = "";
    partyName: string = "";
    userId: string = "";
    username: string = "";
    roleId: string = "";
    roleName: string = "";

    constructor(d = null) {
        d = d || {};

        this.disable = d.disable;
        this.email = d.email || "";
        this.partyId = d.partyId || "";
        this.phoneNumber = d.phoneNumber || "";
        this.fullName = d.fullName || "";
        this.partyName = d.partyName || "";
        this.userId = d.userId || "";
        this.username = d.username || "";
        this.roleId = d.roleId || "";
        this.roleName = d.roleName || "";
    }
}