<app-spinner></app-spinner>
<div class="container-fluid">
    <div class="row login-grid">
        <div class="col-md-7 left-view-bg">
            <video #videobg id="videobg" loop="" muted="" playsinline="" oncanplay="this.play()">
                <source [attr.data-src]="'assets/img/home/vid-bg.mp4'" [attr.video-src]="'assets/img/home/vid-bg.mp4'" src="assets/img/home/vid-bg.mp4" type="video/mp4">
                    <source [attr.data-src]="'assets/img/home/vid-bg.mp4'" [attr.video-src]="'assets/img/home/vid-bg.webm'" src="assets/img/home/vid-bg.mp4" type="video/mp4">
            </video>
            <div class="content-on-video">
                <div class="col-12 text-center">
                    <div class="mb-4">
                        <img src="assets/img/econtract-logo.svg" width="100" height="100" style="background: white; border-radius: 16px">
                        <h1 class="headline-1">VNPT eContract</h1>
                        <p class="description-1">Tiên phong nền tảng hợp đồng số</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-5 right-view-login p-4 bg-white">
            <div *ngIf="!createSendEmailSuccess" class="row justify-content-center mt-5 pt-5 pb-2 mb-2">
                <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10">
                    <div class="text-center mb-5">
                        <h2 class="font-weight-bold">Quên mật khẩu</h2>
                    </div>
                    <!-- <div class="row mb-4">
                        <div class="col-md-6 mb-4">
                            <button (click)="changeFormActive('CONSUMER')" class="btn btn-lg d-inline-flex w-100"
                                [class.btn-outline-secondary]="!activeConsumer"
                                [class.btn-outline-primary]="activeConsumer" [class.active]="activeConsumer">
                                <span class="material-icons material-icons-lg">person</span>
                                <span class="mx-auto">Cá nhân</span>
                                // cmt <span *ngIf="activeConsumer"
                                    class="material-icons material-icons-lg">check_circle</span> // cmt
                                <span class="material-icons material-icons-lg ml-1 text-white">check_circle</span>
                            </button>
                        </div>
                        <div class="col-md-6">
                            <button (click)="changeFormActive('BUSINESS')" class="btn btn-lg d-inline-flex w-100"
                                [class.btn-outline-secondary]="!activeBusiness"
                                [class.btn-outline-primary]="activeBusiness" [class.active]="activeBusiness">
                                <span class="material-icons material-icons-lg">domain</span>
                                <span class="mx-auto">Tổ chức</span>
                                // cmt <span *ngIf="activeBusiness"
                                    class="material-icons material-icons-lg">check_circle</span> // cmt
                                <span class="material-icons material-icons-lg ml-1 text-white">check_circle</span>
                            </button>
                        </div>
                    </div> -->
                    <div class="text-center" >
                        <!-- <h5 *ngIf="activeConsumer" class="text-muted">Vui lòng nhập thông tin số điện thoại để khôi phục mật khẩu.</h5>
                        <h5 *ngIf="activeBusiness" class="text-muted">Vui lòng nhập thông tin email để khôi phục mật khẩu.</h5> -->
                        <h6>Vui lòng nhập email đăng ký tài khoản của bạn để khôi phục mật khẩu</h6>
                    </div>
                </div>
            </div>

            <div *ngIf="!createSendEmailSuccess" class="row justify-content-center py-4">
                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9">
                    <!-- <form *ngIf="activeConsumer" [formGroup]="inputSdtForm">
                        <div class="input-group input-custom mb-4">
                            <span class="input-custom-label"><span class="text-danger">*</span> Số điện thoại</span>
                            <input appTrimValue (input)="handleInputSdt($event)" formControlName="sdt" type="text"
                                class="form-control form-control-lg input-custom-control"
                                placeholder="Vui lòng nhập số điện thoại của bạn" />
                            <app-show-validate-errors [isSubmit]="isSubmitConsumer" [errorMessages]="ServiceMsg.sdt"
                                [control]="sdt" [detail]="{ name: 'sdt' }"></app-show-validate-errors>
                        </div>
                        <p class="error text-danger" *ngIf="sendSdtFailed">{{ ServiceMsg.sdt.notFound }}</p>
                        <div class="row">
                            <div class="col-12 mb-4">
                                <button (click)="sendSdt()" type="submit"
                                    class="btn btn-lg btn-primary btn-block b-radius-5px">Tiếp tục</button>
                            </div>
                        </div>
                    </form> -->

                    <form [formGroup]="inputEmailForm">
                        <div class="input-group input-custom mb-4">
                            <span class="input-custom-label blue-label">Email <span class="text-danger">*</span></span>
                            <input appTrimValue formControlName="email" type="email"
                                class="form-control form-control-lg input-custom-control"
                                placeholder="Nhập email" />
                            <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.email"
                                [control]="email" [detail]="{ name: 'email' }"></app-show-validate-errors>
                        </div>
                        <p class="error text-danger" *ngIf="sendEmailFailed">{{ ServiceMsg.email.notFound }}</p>
                        <div class="row">
                            <div class="col-12 mb-4">
                                <button (click)="sendEmail()" type="submit"
                                    class="btn btn-lg btn-primary btn-block b-radius-5px">Tiếp tục</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div *ngIf="createSendEmailSuccess" class="row justify-content-center pt-5 pb-2 mb-2">
                <div class="col-md-10">
                    <h1 class="font-weight-bold">Quên mật khẩu</h1>
                    <div class="text-lg mt-4">
                        <p class="text-muted">Một email khôi phục mật khẩu đã được gửi đến bạn . Vui lòng kiểm tra
                            email.</p>
                        <p class="text-muted">Chưa nhận được email khôi phục mật khẩu?</p>
                        <a class="text-primary align-self-center font-weight-bold ml-auto"
                            [routerLink]="['/app/forgot-password']" (click)="sendEmail()">Gửi lại</a>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>