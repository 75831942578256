export class DocumentItem {
    id : string;
    documentName: string;
    documentId: string;
    documentType: string;
    documentNote: string;
    documents : Array<string>;
    userId: string;
    dateUpload: string;
    isUsed: boolean = false;
    
    constructor(obj = null) {
        obj = obj || {};
        this.id = obj.id || '';
        this.documentName = obj.documentName || '';
        this.documentId = obj.documentId || '';
        this.documentType = obj.documentType || '';
        this.documentNote = obj.documentNote || '';
        this.documents = obj.documents || '';
        this.userId = obj.userId || '';
        this.dateUpload = obj.dateUpload || '';
        this.isUsed = obj.isUsed;
    }
}
