import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DualListComponent } from 'src/app/layout/extensions/dual-list/dual-list.component';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { StringUtils } from 'src/app/utils/common/StringUtils';
import { localize } from 'src/app/utils/localize/localize';
import { InfraConfig } from 'src/app/utils/models/aas-models/service-catalog/infra-config';
import { DualListItem } from 'src/app/utils/models/duallist/duallist-item';
import { PackageServiceService } from 'src/app/utils/services/package-service/package-service.service';

@Component({
  selector: 'app-catalog-service-add',
  templateUrl: './catalog-service-add.component.html',
  styleUrls: ['./catalog-service-add.component.scss']
})
export class CatalogServiceAddComponent implements OnInit {
  @ViewChild('duallist') duallist: DualListComponent;

  localize = localize;

  form: FormGroup;
  isSubmit = false;
  validateMsg = {
    name: {
      required: localize.required_catalog_name
    },
    serviceType: {
      required: localize.required_service_type
    }
  }

  statusList = [
    { value: "", label: localize.all },
    { value: "ENABLED", label: localize.active },
    { value: "DISABLED", label: localize.deactive}
  ];

  serviceType = [
    // { value: "", label: localize.all },
    { value: "HATANG", label: localize.infrastructure },
    { value: "CHUCNANG", label: localize.feature}
  ];

  items_dualist = [];
  items_dualist_assigned = [];

  infraConfigs: InfraConfig[] = [];
  totalPrice = "";

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private myAlert: AlertControl,
    private packageApi: PackageServiceService
  ) { }

  ngOnInit(): void {
    this.makeForm();
    this.getAllRole();
    this.setData();
  }

  makeForm() {
    this.form = this.fb.group({
      name: ["", [Validators.required]],
      serviceType: [null, [Validators.required]]
    })
  }

  cancel() {
    this.router.navigate([ConstantUrl.service_catalog_list]);
  }

  save() {
    console.log(this.makeParam());
    this.isSubmit = true;
    if (this.form.valid) {
      this.myAlert.showAlert(localize.confirm_create_service_catalog, "", true, "", localize.add_new, () => {
        this.postCreateServiceCatalog(this.makeParam());
      }, "", localize.btn_cancel, null, localize.CONFIRM);
    }
  }

  /**
   * GET ds quyen chuc nang enduser
   */
   getAllRole() {
    this.packageApi.getAllRole().subscribe((res: any) => {
      console.log(res);
      if (res.object && res.object instanceof Array) {
        res.object.forEach((item, index) => {
          this.items_dualist.push({
            id: item.name || "",
            name: item.description || "",
            additions: {
              ...item
            },
            child: []
          })
        });

        this.duallist.setBaseList();
        this.duallist.setupDualList([]);
      }
    }, err => {

    })
  }

  getListAssigned(event) {
    console.log(event);
    if (event && event instanceof Array) {
      this.items_dualist_assigned = [].concat(event);
    }
  }

  setData() {
    this.infraConfigs.push(new InfraConfig({
      "stt":1,
      "param":"contractNumber",
      "name": "Số lượng hợp đồng",
      "unit": "Hợp đồng",
      "unitContract": "",
      "unitNumber": "0",
      "unitPrice": "0",
      "price": "0"
    }));
    this.infraConfigs.push(new InfraConfig({
      "stt":2,
      "param":"SMS_OTP",
      "name": "Số lượt ký OTP",
      "unit": "Lượt",
      "unitContract": "5",
      "unitNumber": "0",
      "unitPrice": "0",
      "price": "0"
    }));
    this.infraConfigs.push(new InfraConfig({
      "stt":3,
      "param":"EKYC",
      "name": "Số lượt eKYC",
      "unit": "Lượt",
      "unitContract": "3",
      "unitNumber": "0",
      "unitPrice": "0",
      "price": "0"
    }));
    this.infraConfigs.push(new InfraConfig({
      "stt":4,
      "param":"SMS",
      "name": "Số lượt SMS",
      "unit": "Lượt",
      "unitContract": "1",
      "unitNumber": "0",
      "unitPrice": "0",
      "price": "0"
    }));
    this.infraConfigs.push(new InfraConfig({
      "stt":5,
      "param":"STORAGE_TIME",
      "name": "Thời gian lưu trữ",
      "unit": "Tháng",
      "unitContract": "5",
      "unitNumber": "0",
      "unitPrice": "0",
      "price": "0"
    }));
    this.infraConfigs.push(new InfraConfig({
      "stt":6,
      "param":"STORAGE_CAPACITY",
      "name": "Dung lượng lưu trữ",
      "unit": "GB",
      "unitContract": "1",
      "unitNumber": "0",
      "unitPrice": "0",
      "price": "0"
    }));
  }

  makeParam() {
    console.log(this.items_dualist_assigned);
    let param =
    {
      "name": this.form.value.name || "",
      "typeService": this.form.value.serviceType || "",
      "privileges": [
      ],
      "totalPrice": "",
      "infrastructures": [
      ]
    };

    switch (this.form.value.serviceType) {
      case "CHUCNANG":
        param.privileges = [];
        this.items_dualist_assigned.forEach((item: any) => {
          param.privileges.push(item.id || "");
        });

        return param;
      case "HATANG":
        this.getTotalMoney();
        param.totalPrice = this.totalPrice == "" || this.totalPrice == null ? "0" : this.totalPrice;
        param.infrastructures = [];
        this.infraConfigs.forEach((item) => {
          let x = {
            "stt": item.stt,
            "param": item.param || "",
            "name": item.name,
            "unit": item.unit,
            "unitContract": item.unitContract == "" || item.unitContract == null ? "0" : "" + item.unitContract,
            "unitNumber": item.unitNumber == "" || item.unitNumber == null ? "0" : "" + item.unitNumber,
            "unitPrice": item.unitPrice == "" || item.unitPrice == null ? "0" : "" + item.unitPrice,
            "price": item.price == "" || item.price == null ? "0" : "" + item.price,
          }
          param.infrastructures.push(x);
        })
        return param;
    }
  }

  getTotalMoney() {
    var sum = '0';
    this.infraConfigs.forEach((item) => {
      if (!isNaN(parseInt(item.price)) && !isNaN(parseInt(sum))) {
        sum = `${Number(sum) + Number(item.price)}`;
      }
    })

    this.totalPrice = sum;
  }

  postCreateServiceCatalog(param) {
    this.packageApi.postCreateServiceCatalog(param).subscribe((res) => {
      this.myAlert.showAlertOnlyNoti(localize.create_service_catalog_success, "", "", localize.btn_close, () => {
      }, localize.NOTIFICATION);
      this.router.navigate([ConstantUrl.service_catalog_list]);
    }, err => {
      this.myAlert.showErrorHandled(err);
    });
  }
}
