import { Component, OnInit, Input, ElementRef } from '@angular/core';

@Component({
  selector: 'app-attach-file',
  templateUrl: './attach-file.component.html',
  styleUrls: ['./attach-file.component.scss']
})
export class AttachFileComponent implements OnInit {

  @Input() postFileObj: any;
  @Input() postFileItem: any;
  @Input() fileInput: ElementRef;
  constructor() { }

  ngOnInit(): void {
  }

  removeFile(i){
    this.postFileItem.removeAt(i);
    this.postFileObj.splice(i,1);
    this.fileInput.nativeElement.value = ''
  }

  dowloadFile(fileKey, i){

  }
}
