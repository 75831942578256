import { Injectable } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { AlertComponent } from "src/app/layout/extensions/alert/alert.component";
import { ConstantAlertBtn, ConstantAlertMsg } from "../common/constant-alert-msg";
import { ConstantAlertType } from "../common/constant-alert-type";
import { ResponseMsg } from "../common/response-msg-aas";
import { StatusCode } from "../common/status-code";
import { localize } from "../localize/localize";

@Injectable({
    providedIn: 'root'
})
export class AlertControl {
    private timeoutAlert: NgbModalRef = null;
    private timeoutTokenAlert: NgbModalRef = null;
    constructor(private modalService: NgbModal) {
        
    }

    // showAlert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = "btn_ok", actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = "btn_cancel", actBtn1 = () => { }, title: string = "modal_title_noti", config = {}) {
    //     const modalRef = this.modalService.open(AlertComponent, config);
    //     modalRef.componentInstance.title = title;
    //     modalRef.componentInstance.message = msg;
    //     modalRef.componentInstance.typeAlert = type;
    //     modalRef.componentInstance.twoBtn = twoBtn;
    //     modalRef.componentInstance.iconBtn1 = iconBtn1;
    //     modalRef.componentInstance.iconBtn2 = iconBtn2;
    //     modalRef.componentInstance.titleButton1 = titleBtn1;
    //     modalRef.componentInstance.titleButton2 = titleBtn2;
    //     modalRef.componentInstance.callback1 = actBtn1;
    //     modalRef.componentInstance.callback2 = actBtn2;
    // }
    showAlert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = "btn_ok", actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = "btn_cancel", actBtn1 = () => { }, title: string = "modal_title_noti", config = {},
    close = () => {}, dimiss = () => {}) {
    const modalRef = this.modalService.open(AlertComponent, config);
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
    modalRef.result.then((result) => {
        if (close) {
            close();
        }
    }, (reason) => {
        if (dimiss) {
            dimiss();
        }
    });
}

    showAlertNoClose(msg, type, iconBtn = '', titleBtn = "btn_ok", actBtn = () => { }, title: string = "modal_title_noti") {
        const modalRef = this.modalService.open(AlertComponent, {backdrop: 'static'});
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.message = msg;
        modalRef.componentInstance.typeAlert = type;
        modalRef.componentInstance.twoBtn = false;
        modalRef.componentInstance.iconBtn2 = iconBtn;
        modalRef.componentInstance.titleButton2 = titleBtn;
        modalRef.componentInstance.callback2 = actBtn;
        modalRef.componentInstance.noClose = true;
    }

    showAlertOnlyNoti(msg, type, iconBtn = '', titleBtn = "btn_close", actBtn = () => { }, title: string = "modal_title_noti", config = {}, 
        close = () => {}, dimiss = () => {}) {
        const modalRef = this.modalService.open(AlertComponent, config);
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.message = msg;
        modalRef.componentInstance.typeAlert = type;
        modalRef.componentInstance.twoBtn = false;
        modalRef.componentInstance.iconBtn2 = iconBtn;
        modalRef.componentInstance.titleButton2 = titleBtn;
        modalRef.componentInstance.callback2 = actBtn;
        modalRef.componentInstance.noClose = false;
        modalRef.result.then((result) => {
            if (close) {
                close();
            }
        }, (reason) => {
            if (dimiss) {
                dimiss();
            }
        });
    }

    showTimeOutAlert() {
        console.log("showTimeOutAlert: ", this.timeoutAlert);
        if (this.timeoutAlert == null) {
            this.timeoutAlert = this.modalService.open(AlertComponent);
            this.timeoutAlert.componentInstance.message = ConstantAlertMsg.REQUEST_TIMEOUT;
            this.timeoutAlert.componentInstance.typeAlert = ConstantAlertType.ERROR;
            this.timeoutAlert.componentInstance.twoBtn = false;
            this.timeoutAlert.componentInstance.iconBtn2 = "";
            this.timeoutAlert.componentInstance.titleButton2 = ConstantAlertBtn.CLOSE;
            this.timeoutAlert.result.then((res) => {
                this.timeoutAlert = null;
            }, (reason) => {
                this.timeoutAlert = null;
            })
        }
    }

    showTimeOutTokenAlert(msg, type, iconBtn = '', titleBtn = "btn_ok", actBtn = () => { }) {
        console.log("showTimeOutAlert: ", this.timeoutTokenAlert);
        if (this.timeoutTokenAlert == null) {
            this.timeoutTokenAlert = this.modalService.open(AlertComponent, {backdrop: 'static'});
            this.timeoutTokenAlert.componentInstance.message = msg;
            this.timeoutTokenAlert.componentInstance.typeAlert = type;
            this.timeoutTokenAlert.componentInstance.twoBtn = false;
            this.timeoutTokenAlert.componentInstance.iconBtn2 = iconBtn;
            this.timeoutTokenAlert.componentInstance.titleButton2 = titleBtn;
            this.timeoutTokenAlert.componentInstance.callback2 = actBtn;
            this.timeoutTokenAlert.componentInstance.noClose = true;
            this.timeoutTokenAlert.result.then((res) => {
                this.timeoutTokenAlert = null;
            }, (reason) => {
                this.timeoutTokenAlert = null;
            })
        }
    }

    //MARK: Handle Error
    showErrorHandled(err, errorMsg: any = StatusCode) {
        if (err.error && err.error.error && JSON.stringify(err.error.error).toLowerCase() === "access denied") {
            this.showAlertOnlyNoti(localize.access_denied, ConstantAlertType.ERROR);
        } else if (err.error && err.error.countWrongRequest == 1 && err.error.requestRemain == 3) {
            this.showAlertOnlyNoti(`${err.error.error?.toString()}`, ConstantAlertType.ERROR);
        } else if (err.error && err.error.code && errorMsg[err.error.code]) {
            this.showAlertOnlyNoti(errorMsg[err.error.code], ConstantAlertType.ERROR);
        } else if (err.error && err.error.message && errorMsg[err.error.message]) {
            this.showAlertOnlyNoti(errorMsg[err.error.message], ConstantAlertType.ERROR);
        }  else if (err.error && err.error.error) {
            this.showAlertOnlyNoti(err.error.error?.toString(), ConstantAlertType.ERROR);
        } else {
            this.showAlertOnlyNoti(ConstantAlertMsg.DEFAULT_ERROR_REQUEST, ConstantAlertType.ERROR);
        }
    }
}