<!-- <app-spinner></app-spinner> -->
<div class="ct-wv-content ct-enable-scroll ">
    <div class="ct-wv-container">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <!-- <div class="row justify-content-center mt-5 pt-5 pb-2 mb-2">
                    <div class="col-md-10">
                        <div class="text-center">
                            <h1 class="font-weight-bold">Chào mừng bạn đến với hệ thống VNPT eContract</h1>
                            <h5 class="text-muted">Chào mừng bạn đến với hệ thống VNPT eContract</h5>
                        </div>
                    </div>
                </div> -->
                <div class="row justify-content-center my-3 py-5">
                    <div class="col-xl-6 col-lg-7 col-md-8 col-sm-10">
                        <h2 class="font-weight-bold text-center mb-5">Đăng nhập</h2>
                        
                        <div class="card bg-white shadow-lg rounded-2">
                            <form [formGroup]="loginForm" class="card-body pt-5">
                                <div class="input-group input-custom mb-4">
                                    <span class="input-custom-label"><span class="text-danger">*</span> Tài khoản</span>
                                    <input appTrimValue formControlName="email" type="text"
                                        class="form-control form-control-lg input-custom-control" placeholder="Tài khoản" />
                                    <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="validateMsg.username"
                                        [control]="email" [detail]="{ name: 'email' }"></app-show-validate-errors>
                                </div>
                                <div class="input-group input-custom mb-4">
                                    <span class="input-custom-label"><span class="text-danger">*</span> Mật khẩu</span>
                                    <input appInputPassword formControlName="password" type="password"
                                        class="form-control form-control-lg input-custom-control" placeholder="Mật khẩu" />
                                    <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="validateMsg.password"
                                        [control]="password" [detail]="{ name: 'password' }"></app-show-validate-errors>
                                    <p class="error text-danger" *ngIf="loginFailed && loginForm.valid">Tài khoản hoặc mật khẩu
                                        không đúng!</p>
                                </div>
                                <div class="row">
                                    <!-- /.col -->
                                    <div class="col-12 d-flex mb-4">
                                        <!-- <a class="text-primary align-self-center font-weight-bold ml-auto"
                                            [routerLink]="['/app/forgot-password']">Quên mật
                                            khẩu?</a> -->
                                    </div>
                                    <div class="col-12 mb-4">
                                        <button [disabled]="checkEdited" (click)="login()" type="submit"
                                            class="btn btn-lg btn-primary btn-block b-radius-5px">
                                            Đăng nhập
                                        </button>
                                    </div>
                                    <div class="col-12">
                                        <a routerLink="/wv/login-with-otp" class="text-decoration-underline">Đăng nhập bằng OTP</a>
                                    </div>
                                    <!-- <div class="col-12 text-center">
                                        <span>Chưa có tài khoản? <a class="text-primary text-center font-weight-bold"
                                                [routerLink]="['/app/signup']">Đăng ký</a></span>
                                    </div> -->
                                    <!-- /.col -->
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>