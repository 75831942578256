<!-- <br/> -->
<div *ngIf="postFileItem" id="filePreview" class="rounded mt-3 {{ postFileItem.controls.length > 0 ? 'border' : '' }} p-2">
    <div class="blockUpload group-uploaded-file">
    <div *ngFor="let file of postFileItem.controls; let i = index"
        class="preview-file px-3 py-1 rounded border {{ file.value.old ? 'border-primary' : file.value.process == 100 && file.value.error == ''? 'border-success' : file.value.error != '' ? 'border-danger' : file.value.process < 100 ? 'border-primary' : '' }} mb-2">
        <span class="name-file" >{{file.value.name}}</span>
        <div class="d-flex align-items-center" >
        <div class="progress flex-grow-1 mr-2" style="height: 5px;" >
            <div
            class="progress-bar {{ file.value.old ? 'bg-primary' : file.value.process == 100 && file.value.error == ''? 'bg-success' : file.value.error != '' ? 'bg-danger' : file.value.process < 100 ? 'bg-primary' : '' }}"
            role="progressbar" [style.width.%]="file.value.process" attr.aria-valuenow="{{file.value.process}}"
            aria-valuemin="0" aria-valuemax="100" >
            </div>
            
        </div>
        <em class="fa fa-times-circle text-muted" (click)="removeFile(i)" style="cursor: pointer; "></em>
        </div>
        <!-- <span *ngIf="file.value.old"><a class="text-primary" style="cursor: pointer;" (click)="dowloadFile(file.value.fileKey, i)">Táº£i vá»</a></span> -->
        <span *ngIf="!file.value.old && file.value.error === ''">{{file.value.process}}%</span>
        <span *ngIf="!file.value.old && file.value.error != ''" class="text-danger">{{file.value.error}}</span>
    </div>
    </div>
</div>