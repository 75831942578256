import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContractObject } from 'src/app/utils/models/aas-models/contract/contract/contract-object.model';
import { SignAgreementService } from 'src/app/utils/services/aas-network/sign-agreement/sign-agreement.service';
import { Fireworks } from 'fireworks-js'
import { Observable, timer } from 'rxjs';
import { map, take } from 'rxjs/operators';

declare var $: any;
declare var pdfShowMark: any;
declare var PDFSign: any;

@Component({
  selector: 'app-sign-agreement',
  templateUrl: './sign-agreement.component.html',
  styleUrls: ['./sign-agreement.component.scss']
})
export class SignAgreementComponent implements OnInit, OnDestroy {

  id = this.activedRoute.snapshot.paramMap.get("id");

  contractPdf: any = null;
  contract: ContractObject = new ContractObject();
  locations = [];
  currentDate = new Date();
  place = "Lào Cai";

  //firework
  container: any = null;
  fireworks: any = null;

  currentStatus = "";
  interval: any = null;

  img_prefix = "data:image/png;base64,";

  constructor(
    private activedRoute: ActivatedRoute,
    private signAgreeService: SignAgreementService
  ) { }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  ngOnInit(): void {
    this.viewDidLoad();
  }

  viewDidLoad() {
    // this.downloadFileContract(this.id);
    this.getContractDetail(this.id);
    // this.getPositionPageSignature(this.id);
    // this.loadFireWork();
    this.reloadCheckStatus();
  }

  loadFireWork() {
    this.container = document.querySelector('.fireworks-example')
    this.fireworks = new Fireworks(this.container, {
      rocketsPoint: 50,
      hue: { min: 0, max: 360 },
      delay: { min: 30, max: 100 },
      speed: 2,
      acceleration: 1.05,
      friction: 0.95,
      gravity: 4,
      particles: 800,
      trace: 10,
      explosion: 4,
      autoresize: true,
      brightness: {
        min: 20,
        max: 60,
        decay: { min: 0.015, max: 0.03 }
      },
      mouse: {
        click: false,
        move: false,
        max: 3
      },
      boundaries: {
        x: 50,
        y: 50,
        width: this.container.clientWidth,
        height: this.container.clientHeight
      },
      sound: {
        enable: true,
        files: [
          'explosion0.mp3',
          'explosion1.mp3',
          'explosion2.mp3'
        ],
        volume: { min: 1, max: 2 },
      }
    });
  }

  runFireWork() {
    $(".fireworks-example").fireworks();
    $(".fireworks-example").before($("canvas"));
  }

  get getHeightPageView() {
    let res = "";
    let el: any = document.getElementsByClassName('page-view');
    if (el && el.length > 0) {
      res = "height: " + (el[0].offsetHeight + 0) + "px";
    }

    return res;
  }

  scrollPageHavaSignature() {
    let page = this.getLastPageHaveSignature();
    if (this.contractPdf != null && page > 0) {
      this.scrollTo("page-pdf-" + page);
    }
  }

  scrollTo(name_el: string) {
    var el = document.getElementById(name_el);
    el && el.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  getLastPageHaveSignature() {
    let maxPage = -1;
    this.locations.forEach((item, index) => {
      if (item.page + 1 > maxPage) {
        maxPage = item.page;
      }
    })

    return maxPage;
  }

  downloadFileContract(id, type) {
    this.signAgreeService.downloadFileContract(id, type).subscribe((data: any) => {
      const blob: any = new Blob([data], { type: 'application/pdf' });
      this.contractPdf = blob;
      // pdfShowMark.renderPdfWithPageNumber(blob, [3], []);
      PDFSign.preview(blob);
      this.scrollPageHavaSignature();
    })
  }

  getContractDetail(id) {
    this.signAgreeService.getContractDetail(id).subscribe((res: any) => {
      this.contract = new ContractObject(res.object);
      this.currentStatus = this.contract.currentStage;
      console.log(this.contract);
      // if (this.contract.currentStage === 'LC_CONTRACT_VALID') {
      //   this.downloadFileContract(id, 'CONTRACT');
      // } else {
      //   this.downloadFileContract(id, 'DRAFT');
      // }
      if (this.contract.currentStage === 'LC_CONTRACT_VALID') {
        this.runFireWork();
        clearInterval(this.interval);
      }
    })
  }

  getPositionPageSignature(id) {
    this.signAgreeService.getPositionPageSignature(id).subscribe((res: any) => {
      if (res.object && res.object.location && res.object.location instanceof Array) {
        this.locations = res.object.location;

        this.scrollPageHavaSignature();
      }
    })
  }

  reloadCheckStatus() {
    clearInterval(this.interval);
    this.interval = setInterval(() => { 
      this.getContractDetailCheckStatus(this.id); 
    }, 2000);
  }

  getContractDetailCheckStatus(id) {
    this.signAgreeService.getContractDetail(id).subscribe((res: any) => {
      this.contract = new ContractObject(res.object);
      if (this.contract.currentStage != this.currentStatus) {
        this.currentStatus = this.contract.currentStage;
        if (this.contract.currentStage === 'LC_CONTRACT_VALID') {
          // this.downloadFileContract(id, 'CONTRACT');
          // this.fireworks.start();
          this.runFireWork();
          clearInterval(this.interval);
        } else {
          // this.downloadFileContract(id, 'DRAFT');
        }
      }
    })
  }

  getImageSign(partyName) {
    let img = "";
    for (let i = 0; i < this.contract.contractPartysData.length; i++) {
      let item = this.contract.contractPartysData[i];
      if (item.partyName == partyName) {
        if (item.imageSign !== "" && item.imageSign != null && item.imageSign != undefined) {
          img = item.imageSign;
        }
      }
    }

    return img;
  }
}
