import { Component, Input, OnInit } from '@angular/core';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { PackageSessionService } from 'src/app/utils/services/aas-network/package-session/package-session.service';

const SCREEN_TYPE = {
  ENDUSER_DASHBOARD: "ENDUSER_DASHBOARD",
  ENDUSER_PACKAGE_MANAGER: "ENDUSER_PACKAGE_MANAGER"
}

@Component({
  selector: 'app-package-noti-block',
  templateUrl: './package-noti-block.component.html',
  styleUrls: ['./package-noti-block.component.scss']
})
export class PackageNotiBlockComponent implements OnInit {
  @Input('screen') screen: string = SCREEN_TYPE.ENDUSER_DASHBOARD;

  screenType = SCREEN_TYPE;
  constUrl = ConstantUrl;

  constructor(
    public packageSessionService: PackageSessionService
  ) { }

  ngOnInit(): void {
  }

  get isValidAndWarning() {
    return this.packageSessionService.isValid && this.packageSessionService.isWarning && !this.packageSessionService.isHidden;
  }

  get isValid() {
    return this.packageSessionService.isValid && !this.packageSessionService.isHidden;
  }

  get isNotValid() {
    return this.packageSessionService.isNotValid && !this.packageSessionService.isHidden;
  }

  dismiss() {
    console.log("clicked");
    this.packageSessionService.setHidden(true);
    console.log(this.packageSessionService.isHidden);
  }
}
