import { MainResponse } from '../../main-response.model';
import { AccountManagerObject } from './account-manager-object';

export class AccountManagerResponse extends MainResponse {
    object: AccountManagerObject;
    constructor(obj = null) {
        super();
        obj = obj || {};
        this.init(obj);
        this.object = new AccountManagerObject(obj.object);
    }
}