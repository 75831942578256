import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ReportService } from 'src/app/utils/services/aas-network/report/report.service';
import { REGEXP_DATE_INPUT } from 'src/app/utils/common/regexp';
import { DateUtils } from 'src/app/utils/common/DateUtils';
import { UtilsFunc } from 'src/app/utils/common/utils-func';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent implements OnInit {
  @ViewChild('fromDatePicker') _fromDate: ElementRef;
  @ViewChild('toDatePicker') _toDate: ElementRef;

  constructor(
    private fb: FormBuilder,
    private reportService: ReportService
  ) { }

  ngOnInit(): void {
    this.makeFormFilter();
    this.getListCustomers();
  }

  
  // List Customers
  listCustomers: Array<any> = [];
  // Đối số getListCustomers()
  keySearch = ""
  page: number = 1;
  maxSize: number = 10;
  sort = "DESC"
  propertiesSort = "created"
  userType = "BUSINESS"
  totalElement: number = 0;

  // config col table
  listConfigColTable: Array<any> = [
    {
      label: "Tên tổ chức",
      name: "tenToChuc"
    },
    {
      label: "Mã số thuế",
      name: "maSoThue"
    },
    {
      label: "Ngày yêu cầu",
      name: "ngayYeuCau"
    },
    {
      label: "Thời gian sử dụng",
      name: "thoiGianSuDung"
    },
    {
      label: "Đơn vị yêu cầu",
      name: "donViYeuCau"
    },
    {
      label: "Mã HRM nhân viên",
      name: "maHrmNhanVien"
    },
    {
      label: "Trạng thái sử dụng",
      name: "trangThaiSuDung"
    }
  ];
  configColTable: any = {
    tenToChuc: true,
    maSoThue: true,
    ngayYeuCau: true,
    thoiGianSuDung: true,
    donViYeuCau: true,
    maHrmNhanVien: true,
    trangThaiSuDung: true
  }

  formFilter: FormGroup;
  dateType = {
    CREATED_FROM: 'CREATED_FROM',
    CREATED_TO: 'CREATED_TO'
  };
  currentDate = new Date();
  optionsCleave = {
    date: true,
    delimiter: '/',
    datePattern: ['d', 'm', 'Y']
  };
  validateSearch = {
    fromDate: {
      pattern: "Sai định dạng ngày tháng năm!",
      minDate: 'Từ ngày không được lớn hơn đến ngày và không được là ngày tương lai!'
    },
    toDate: {
      pattern: "Sai định dạng ngày tháng năm!"
    }
  };
  isSubmit: boolean = false;

  

  sortColConfig = {
    tenToChuc: null,
    maSoThue: null,
    created: null,
    duration: null,
    AMPartyName: null,
    maNv: null,
    packageNum: null
  }


  // keySearch = "", page = 1, maxSize = 10, sort = "DESC", propertiesSort = "created",
  // userType = "BUSINESS", fromDate = "", toDate = "", packageNum = ""
  // -----------------------------------------------------

  // Lấy dữ liệu từ BE
  getListCustomers(page = 1) {
    this.reportService.getListOrganizationCustomers(
      this.keySearch, this.page, this.maxSize, this.sort, UtilsFunc.getPropertiesSort(this.sortColConfig), this.userType, this.fromDate, this.toDate, this.status
    ).subscribe((res: any) => {
      this.listCustomers = res.object?.data || [];
      this.totalElement = res.object?.totalElement || 0;
    })
  }
  /**
   * Tim kiem voi bo loc
   */
   searchWithFilter() {
    this.loadPage(1);
  }

  /**
   * Tai trang
   * @param page 
   */
   loadPage(page) {
    this.page = page;
    this.getListCustomers(this.page);
  }

  /**
   * Tra ve style cho span method
   * @param packageNum 
   */
   getStyleAndValueBadge(packageNum): string[] {
    if (packageNum == "0") {
      return ['badge-primary-new', 'Dùng thử'];
    }
    return ['badge-success-new', 'Chính thức'];
  }

  //MARK: Action cấu hình hiển thị cột của bảng
  actConfigColTableView(e, field) {
    e.stopPropagation();
    if (this.configColTable[field] !== null && this.configColTable[field] !== undefined) {
      this.configColTable[field] = !this.configColTable[field];
    }
  }

 /**
   * Khoi tao form filter
   */
  makeFormFilter() {
    this.formFilter = this.fb.group({
      fromDate: new FormControl('', [Validators.pattern(REGEXP_DATE_INPUT)]),
      toDate: new FormControl('', [Validators.pattern(REGEXP_DATE_INPUT)]),
      status: new FormControl('-1')
    });
  }

  get fromDate() {
    return DateUtils.convertInputToFormat(this.formFilter.value.fromDate, DateUtils.format.API);
  }
  get toDate() {
    return DateUtils.convertInputToFormat(this.formFilter.value.toDate, DateUtils.format.API);
  }
  get status() {
    return this.formFilter.value.status;
  }

  /**
  * Thay doi ngay tren filter
  * @param value 
  * @param type 
  */
    changeDateFilter(event, type) {
    switch (type) {
      case this.dateType.CREATED_FROM: 
        this.formFilter.controls.fromDate.setValue(DateUtils.convertObjtoDate(event));
        break;
      case this.dateType.CREATED_TO:
        this.formFilter.controls.toDate.setValue(DateUtils.convertObjtoDate(event));
        break;
    }
  }

  // Thay doi trang thai tren filter
  changeStatus(event){
    this.formFilter.controls.status.setValue(event.target.value)
  }

  submitFilter() {
    this.isSubmit = true;

    if (this.formFilter.valid) {
        let dateFrom = this.formFilter.value.fromDate.length > 0 ? new Date(DateUtils.convertStringtoDateSearch(this.formFilter.value.fromDate)).getTime() : -1;
        let dateTo = this.formFilter.value.toDate.length > 0 ? new Date(DateUtils.convertStringtoDateSearch(this.formFilter.value.toDate)).getTime() : -1;
      if ((dateTo > 0 && dateFrom > 0 && dateFrom > dateTo)) {
        this.formFilter.controls.fromDate.setErrors({ minDate: true });
        }
    }

    if (this.formFilter.valid) {
      console.log(this.formFilter.value);
      this.searchWithFilter();
    }
  }

  cleanFilter() {
    this.isSubmit = false;
    this._fromDate.nativeElement.value = '';
    this._toDate.nativeElement.value = '';
    this.keySearch = "";
    this.formFilter.reset();
    this.formFilter.controls.fromDate.setValue('');
    this.formFilter.controls.toDate.setValue('');
    this.formFilter.controls.status.setValue('-1');
    this.searchWithFilter()
  }

  /**
   * Sap xem theo cot
   * @param key 
   */
   sortCol(key) {
    if (this.sortColConfig[key] == null) {
      this.sortColConfig[key] = true;
    } else if (this.sortColConfig[key] == false) {
      this.sortColConfig[key] = null;
    } else {
      this.sortColConfig[key] = !this.sortColConfig[key];
    }
    this.loadPage(this.page);
  }

}
