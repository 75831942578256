export const localize_account_manager = {
    account_manager_page_title: "Quản lý khách hàng",
    account_manager_tab_consumer: "Cá nhân",
    account_manager_tab_business: "Tổ chức",
    account_detail_title: "Thông tin chi tiết",
    account_identity_level_detail: "Tài khoản định danh cấp",

    admin_save_account_info_confirm: "Bạn có chắc chắn muốn lưu thông tin tài khoản?",
    admin_save_account_info_success: "Lưu thông tin tài khoản thành công!",

    add_new_customer: "Thêm mới khách hàng",
    account_information: "Thông tin tài khoản",
    fill_username: "Nhập tên tài khoản",
    account_type: "Loại tài khoản",
    fill_account_type: "Chọn loại tài khoản",
    package_service: "Gói dịch vụ",
    select_package_service: "Chọn gói dịch vụ",
    tb_code: "Mã thuê bao",
    fill_tb_code: "Nhập mã thuê bao",
    tb_type: "Loại hình thuê bao",
    fill_tb_type: "Nhập loại hình thuê bao",
    tinh_code: "Mã tỉnh",
    fill_tinh_code: "Nhập mã tỉnh",
    business_information: "Thông tin tổ chức",
    company_name: "Tên doanh nghiệp",
    fill_company_name: "Nhập tên doanh nghiệp",
    email: "Email",
    fill_email: "Nhập email",
    short_name: "Tên viết tắt",
    fill_short_name: "Nhập tên viết tắt",
    tax_number: "Mã số thuế",
    fill_tax_number: "Nhập mã số thuế",
    gpkd_full: "Giấy phép kinh doanh",
    see_file: "Xem file",
    select_attach_file: "Chọn file đính kèm",
    note_gpkd_file_type: "File đính kèm có định dạng jpeg, jpg, png, pdf",
    represent_person_information: "Thông tin người đại diện",
    fullname: "Họ và tên",
    fill_fullName: "Nhập họ và tên người đại diện",
    phone_number: "Số điện thoại",
    fill_phone_number: "Nhập số điện thoại",
    birthday: "Ngày sinh",
    fill_birtday: "Nhập ngày sinh",
    gender: "Giới tính",
    select_gender: "Chọn giới tính",
    tinh: "Tỉnh/Thành phố",
    select_tinh: "Chọn tỉnh",
    huyen: "Quận/Huyện",
    select_huyen: "Chọn huyện",
    xa: "Xã/Phường",
    select_xa: "Chọn xã",
    street: "Đường",
    fill_street: "Nhập tên đường",
    sonha: "Số nhà",
    fill_sonha: "Nhập số nhà",
    file_size_too_large: "File đính kèm dung lượng không được vượt quá 5Mb!",
    confirm_add_account_customer: "Bạn chắc chắn muốn thêm mới tài khoản này?",
    add_account_customer_success: "Thêm mới tài khoản thành công!",
    edit_customer: "Cập nhật khách hàng",
    confirm_edit_account_customer: "Bạn chắc chắn muốn cập nhật thông tin tài khoản này?",
    edit_account_customer_success: "Cập nhật thông tin tài khoản thành công!",
    consumer_informations: "Thông tin cá nhân",
    place_of_birth: "Nơi sinh",
    fill_place_of_birth: "Nhập nơi sinh",
    issue_date: "Ngày cấp",
    issue_place: "Nơi cấp",
    fill_issue_place: "Nhập nơi cấp",
    registered_place_of_permanent_residence: "Nơi ĐK HKTT",
    fill_registered_place_of_permanent_residence: "Nhập nơi ĐK HKTT",
    valid_to: "Có giá trị đến",
    taked_photo: "Ảnh chụp",
    confirm_edit_account_customer_with_changed_email: "Email là thông tin định danh của tài khoản. Bạn chắc chắn muốn thay đổi thông tin email của khách hàng này?",
}