import { SignBox } from "../../signbox/sign-box";

export class ContractInternal {
    action: string = "";
    created: string = "";
    email: string = "";
    reason: string = "";
    rejectDate: string = "";
    signDate: string = "";
    ten: string = "";
    userId: string = "";
    departmentName: string = "";
    departmentId: string = "";
    limitDate: any;
    sequence: any;
    signType: string = "";
    fullName: string = "";
    isCurrentUserSign: boolean = false;
    signForm: any[] = [];
    signFrame: SignBox[] = [];

    constructor(d = null) {
        d = d || {};

        this.action = d.action || "";
        this.created = d.created || "";
        this.email = d.email || "";
        this.reason = d.reason || "";
        this.rejectDate = d.rejectDate || "";
        this.signDate = d.signDate || "";
        this.ten = d.ten || d.fullName || "";
        this.userId = d.userId || "";
        this.departmentName = d.departmentName || d.partyName || "";
        this.departmentId = d.departmentId || d.partyId || "";
        this.limitDate = d.limitDate || 0;
        this.sequence = d.sequence || 0;
        this.signType = d.signType || "";
        this.fullName = d.fullName || "";
        this.signForm = d.signForm || [];
        this.signFrame = [];
        if (d.signFrame && d.signFrame instanceof Array) {
            d.signFrame.forEach((item) => {
                this.signFrame.push(new SignBox(item));
            })
        }

    }
}