<div class="container-fluid">
    <div class="row">
        <div class="col-md-4">
            <div class="card shadow-none">
                <div class="card-body pb-0">
                    <div class="row pt-2 pb-2">
                        <div class="col-md-auto ">
                            <label class="text-lg">Danh sách chờ phê duyệt</label>
                        </div>

                        <div class="input-group mt-3">
                            <!-- <form class="input-group"> -->
                            <div class="input-group-prepend">
                                <button class="btn btn-primary d-inline-flex pr-2 pl-2" type="submit"
                                    (click)="clickSearchBtn()">
                                    <span class="material-icons">search</span>
                                </button>
                            </div>
                            <input type="text" class="form-control" placeholder="Nhập tên đơn vị..." (input)="inputKeySearch($event)" #searchField aria-label=""
                                aria-describedby="basic-addon1" (keyup.enter)="clickSearchBtn()">
                            <!-- </form> -->
                        </div>
                    </div>
                </div>

                <div class="position-relative">
                    <!-- <app-mini-spinner *ngIf="loadingListUserGroupIsVisiable() | async"></app-mini-spinner> -->
                    <ng-template #noData>
                        <div class="no-content-list">
                            <img class="mb-2" src="assets/img/icon/icon-no-user-group.png" width="100" />
                            <p class="mb-1">
                                Không có dữ liệu.
                            </p>
                        </div>
                    </ng-template>

                    <div>
                        <div class="user-group-item content-list-item px-0 pt-2">
                            <div *ngIf="!!enterprises && enterprises.length; else noData"
                                class="content-list fade show">
                                <div *ngFor="let item of enterprises; let i = index" (click)="selectEnterprise(item)"
                                    class="user-group-item content-list-item px-4 pt-2"
                                    [class.active]="enterpriseSelected.partyId === item.partyId">
                                    <div class="border-bottom d-flex">
                                        <span class="material-icons icon-item pr-3">business</span>
                                        <div class="flex-grow-1 mb-2">
                                            <label class="title-item">{{ item.tenToChuc || 'NULL' }}</label>
                                            <div class="row-sub hide-text">
                                                <span class="badge badge-light-new badge-pd-md mr-2">{{item.maSoThue}} </span>
                                                <span class="badge badge-light-new badge-pd-md">{{item.email}} </span>
                                            </div>
                                        </div>
                                        <!-- <a *ngIf="!item.isUsed"
                                            class="text-decoration-underline text-primary pl-4"
                                            (click)="deleteDocument($event, item)"
                                            style="cursor: pointer;">Xóa</a> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- ====================================
      tạo page tài liệu
      ==================================== -->
            <div *ngIf="totalElement > maxSize" class="pagination justify-content-end pages mt-3">
                <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="5" [rotate]="true"
                    [ellipses]="false" [pageSize]="maxSize" (pageChange)="loadPage($event)"
                    [boundaryLinks]="true" aria-label="Default pagination">
                    <ng-template ngbPaginationFirst>Đầu</ng-template>
                    <ng-template ngbPaginationLast>Cuối</ng-template>
                    <ng-template ngbPaginationPrevious>&laquo;</ng-template>
                    <ng-template ngbPaginationNext>&raquo;</ng-template>
                    <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                </ngb-pagination>
            </div>
        </div>
        <div class="col-md-8">
            <div class="card shadow-none">
                <div class="card-body position-relative">
                    <!-- <form > -->
                    <!-- <form [formGroup]="form"> -->
                    <div class="row pt-2 pb-2">
                        <div class="col-md-auto align-self-end">
                            <label class="text-lg">Thông tin tài khoản</label>
                        </div>
                        <div *ngIf="partyInfo.partyId" class="col-md-auto ml-auto">
                            <button class="btn btn-outline-primary d-inline-flex mr-3"
                                (click)="rejectEnterprise(rejectModalContent)">
                                <span class="material-icons pr-2">clear</span> Từ chối</button>
                            <button class="btn btn-primary d-inline-flex" (click)="approveNoContract(partyInfo.partyId)">
                                <span class="material-icons pr-2">done_outline</span> Phê duyệt
                            </button>

                            <!-- <div class="dropdown d-inline-flex">
                                <button class="btn btn-primary dropdown-toggle mr-3" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Phê duyệt
                                </button>
                                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                  <a class="dropdown-item cursor-pointer d-flex" (click)="approveNoContract(partyInfo.partyId)"><span class="material-icons mr-2">done</span>Phê duyệt</a>
                                  <a class="dropdown-item cursor-pointer d-flex" (click)="approveHaveContract(partyInfo.partyId)"><span class="material-icons mr-2">add</span>Tạo hợp đồng</a>
                                </div>
                            </div> -->
                        </div>
                    </div>
                    <hr>
                    <div *ngIf="partyInfo.partyId" class="">
                        <div class="row">
                            <div class="col-md-6">
                                <label class="text-primary">Thông tin tổ chức</label>
                                <hr class="mt-0 mb-2">
                                <div class="row mb-2">
                                    <div class="col-md-5">
                                        <label class="text-secondary">Tên doanh nghiệp</label>
                                    </div>
                                    <div class="col-md-7">
                                        <span>{{ partyInfo.tenToChuc }}</span>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-5">
                                        <label class="text-secondary">Tên viết tắt</label>
                                    </div>
                                    <div class="col-md-7">
                                        <span>{{ partyInfo.tenRutGon }}</span>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-5">
                                        <label class="text-secondary">Mã số thuế</label>
                                    </div>
                                    <div class="col-md-7">
                                        <span>{{ partyInfo.maSoThue }}</span>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-5">
                                        <label class="text-secondary">Giấy phép kinh doanh</label>
                                    </div>
                                    <div class="col-md-7">
                                        <a class="text-decoration-underline text-primary" style="cursor: pointer;" (click)="actDownloadGPKD(partyInfo.partyId)">
                                            {{partyInfo?.gpkdFilename}}
                                        </a>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-5">
                                        <label class="text-secondary">Tên miền</label>
                                    </div>
                                    <div class="col-md-7">
                                        <a class="" href="https://{{ partyInfo.userInformations.domain }}" target="blank">{{ partyInfo.userInformations.domain }}</a>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-5">
                                        <label class="text-secondary">Gói cước</label>
                                    </div>
                                    <div class="col-md-7">
                                        <span>{{ partyInfo.packageName }}</span>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-5">
                                        <label class="text-secondary">Ngày đăng ký</label>
                                    </div>
                                    <div class="col-md-7">
                                        <span>{{ partyInfo.created | date:'dd/MM/yyyy' }}</span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <label class="text-primary">Thông tin người đại diện</label>
                                <hr class="mt-0 mb-2">
                                <div class="row mb-2">
                                    <div class="col-md-5">
                                        <label class="text-secondary">Họ và tên</label>
                                    </div>
                                    <div class="col-md-7">
                                        <span>{{ partyInfo.userInformations.ten }}</span>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-5">
                                        <label class="text-secondary">Email</label>
                                    </div>
                                    <div class="col-md-7">
                                        <span>{{ partyInfo.userInformations.email }}</span>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-5">
                                        <label class="text-secondary">Số điện thoại</label>
                                    </div>
                                    <div class="col-md-7">
                                        <span>{{ partyInfo.userInformations.sdt }}</span>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-5">
                                        <label class="text-secondary">Ngày sinh</label>
                                    </div>
                                    <div class="col-md-7">
                                        <span>{{ partyInfo.userInformations.ngaySinh | date:'dd/MM/yyyy'}}</span>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-5">
                                        <label class="text-secondary">Giới tính</label>
                                    </div>
                                    <div class="col-md-7">
                                        <span>{{ partyInfo.userInformations.gioiTinh }}</span>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-5">
                                        <label class="text-secondary">Địa chỉ</label>
                                    </div>
                                    <div class="col-md-7">
                                        <span>{{ partyInfo.userInformations.diaChi }}</span>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <label class="text-primary">Thông tin tài khoản</label>
                                <hr class="mt-0 mb-2">
                                <div class="row mb-2">
                                    <div class="col-md-5">
                                        <label class="text-secondary">Tên tài khoản</label>
                                    </div>
                                    <div class="col-md-7">
                                        <span>{{ partyInfo.userInformations.username }}</span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <!-- </form> -->
                <!-- <app-mini-spinner *ngIf="loadingFormIsVisiable() | async"></app-mini-spinner> -->
            </div>
        </div>
    </div>
</div>

<app-create-contract-approve *ngIf="createContractIsVisible() | async" [party]="partyInfo" (close)="closeCreateContractModal($event)" (createdContract)="onCreatedContract($event)"></app-create-contract-approve>
<app-detail-contract-approve *ngIf="detailContractIsVisible() | async" [contractId]="enterpriseSelected.contractVipId" (close)="closeDetailContractModal($event)"></app-detail-contract-approve>
<ng-template #rejectModalContent let-modal>
    <div class="modal-header text-center border-0">
        <h4 class="modal-title" style="margin: 0 auto;" id="modal-basic-title">Từ chối yêu cầu</h4>
        <button type="button" class="btn" style="color: #DADADA" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">
                <span class="material-icons">
                    cancel
                </span>
            </span>
        </button>
    </div>
    <div class="modal-body py-0" style="padding: 20px;">
        <form [formGroup]="formReject">
            <div class="form-group">
                <label for="reason">Lý do từ chối</label>
                <textarea formControlName="rejectReason" id="reason" #reson
                    placeholder="Vui lòng nhập lý do từ chối phê duyệt" name="reson" rows="5"
                    class="form-control"></textarea>
                <app-show-errors [control]="formReject.controls.rejectReason"></app-show-errors>
            </div>
        </form>
    </div>
    <div class="modal-footer border-0 text-center">
        <button type="button" class="btn btn-outline-primary" #closebutton
            (click)="modal.close('Close click')">Huỷ</button>
        <button type="button" [disabled]="formReject.invalid || formReject.pending" class="btn btn-primary" ngbAutofocus
            (click)="confirmReject(partyInfo.partyId, formReject.value)">Xác nhận</button>
        <!-- <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button> -->
    </div>
</ng-template>

<app-preview-modal *ngIf="showPreviewGPKD" [file]="fileGPKD" [name]="partyInfo.gpkdFilename" (close)="handleClosePreview()"></app-preview-modal>