<div class="guide-tour">
    <svg width="100%" height="100%">
        <defs>
            <mask id="mask" x="0" y="0" width="100%" height="100%">
                <rect x="0" y="0" width="100%" height="100%" fill="white" fill-opacity="50%"></rect>
                <rect x="0" y="0" width="0" height="0" rx="4" id="box" />
            </mask>
        </defs>
        <rect x="0" y="0" width="100%" height="100%" mask="url(#mask)" fill="black"></rect>
    </svg>

    <div class="modal" [class.introduce]="currentStep?.type=='introduce'">
        <div class="modal-header border-0 d-flex">
            <div class="d-flex flex-column align-items-center" [class.title-introduce]="currentStep?.type=='introduce'">
                <img *ngIf="currentStep?.type=='introduce'"
                    src="assets/img/home/v4/img/logo-techlab.png"
                    alt="" srcset="" width="80px" height="20px">
                <label class="modal-title text-center font-weight-bold mt-2"></label>
            </div>
            <a *ngIf="currentStep?.type!='introduce'" class="modal-header__close-btn cursor-pointer"
                (click)="closeGuide()">
                <span class="material-icons-outlined text-mute">
                    close
                </span>
            </a>
        </div>
        <div class="modal-body py-0 enable-scroll" [class.text-center]="currentStep?.type=='introduce'">
        </div>
        <div class="modal-footer d-flex justify-content-center align-items-center w-100">
            <ng-container *ngIf="currentStep?.type=='introduce'; else elseStep">
                <div class="d-flex flex-column align-items-cente justify-content-center w-100">
                    <button class="btn btn-primary mb-2 w-100" (click)="nextStep()">Bắt đầu</button>
                    <a class="text-mute cursor-pointer mx-auto" (click)="closeGuide()">Bỏ qua</a>
                </div>
            </ng-container>
            <ng-template #elseStep>
                <div class="d-flex align-items-center w-100">
                    <button class="modal-footer_btn-back btn btn-outline-primary mr-auto" (click)="backStep()"
                        [hidden]="currentIdx > steps.length - 2 || currentIdx == 1">
                        <span class="mr-1">&#8592;</span>
                        Quay lại
                    </button>
                    <button class="modal-footer_btn-forward btn btn-primary ml-auto" (click)="nextStep()"
                        [hidden]="currentIdx > steps.length - 2">
                        Tiếp theo
                        <span class="ml-1">&#8594;</span>
                    </button>
                    <button class="modal-footer_btn-forward btn btn-primary ml-auto" (click)="closeGuide()"
                        [hidden]="currentIdx <= steps.length - 2">
                        Tôi đã hiểu
                    </button>
                </div>
            </ng-template>
        </div>
    </div>
</div>