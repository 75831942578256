import { Component, OnInit } from '@angular/core';
import { localize } from "src/app/utils/localize/localize";
import { Router } from "@angular/router";

@Component({
  selector: 'app-report-quantity-v1',
  templateUrl: './report-quantity-v1.component.html',
  styleUrls: ['./report-quantity-v1.component.scss']
})
export class ReportQuantityV1Component implements OnInit {

  localize = localize;

  totalElementConsumer = 0;
  totalElementBusiness = 0;
  totalElementEnterprise = 0;

  constructor(
    private router: Router
  ) {}

  ngOnInit(): void {
  }

}
