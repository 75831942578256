<!-- <app-spinner></app-spinner> -->
<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0 text-dark"><label>{{ 'PartyPartnerVerification_title' | translate }}</label></h1>
            </div>
        </div>
    </div>
</div>

<!--MARK top bar -->
<section class="content">
    <div class="container-fluid">
        <div class="content-right-sidebar tab-bar-border-bottom border-tab mb-4">
            <ul class="nav nav-tabs" id="tabVerification" role="tablist">
                <li class="nav-item nav-material">
                    <a class="nav-link d-flex active" id="waiting-tab" data-toggle="pill" href="#waiting" role="tab"
                        aria-controls="waiting" aria-selected="true">
                        <span class="material-icons">query_builder</span>
                        {{ 'PartyPartnerVerification_tab1' | translate }}
                    </a>
                </li>
                <li class="nav-item nav-material">
                    <a class="nav-link d-flex" id="approved-tab" data-toggle="pill" href="#approved" role="tab"
                        aria-controls="approved" aria-selected="false">
                        <span class="material-icons">check_circle_outline</span>
                        {{ 'PartyPartnerVerification_tab2' | translate }}
                    </a>
                </li>

                <li class="nav-item nav-material">
                    <a class="nav-link d-flex" id="reject-tab" data-toggle="pill" href="#reject" role="tab"
                        aria-controls="reject" aria-selected="false">
                        <span class="material-icons">cancel_outline</span>
                        {{ 'PartyPartnerVerification_tab3' | translate }}
                    </a>
                </li>
            </ul>
        </div>
        <div class="tab-content contract-tab-body" id="tabVerification">
            <div class="tab-pane fade show active" id="waiting" role="tabpanel" aria-labelledby="waiting">
                <app-wait-party-partner (reloadReject)="handleReloadReject($event)" (reloadApprove)="handleReloadApprove($event)"></app-wait-party-partner>
            </div>

            <div class="tab-pane fade" id="approved" role="tabpanel" aria-labelledby="approved">
                <app-approved-party-partner #approvedParty></app-approved-party-partner>
            </div>

            <div class="tab-pane fade" id="reject" role="tabpanel" aria-labelledby="reject">
                <app-rejected-party-partner #rejectParty></app-rejected-party-partner>
            </div>
        </div>
    </div>
</section>