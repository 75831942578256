import { invalid } from '@angular/compiler/src/render3/view/util';

export class DateUtils {

    public static format = {
        SYS: "YYYY-MM-DDTHH:mm:ss.sssZ",
        API: "yyyy-MM-dd",
        INPUT: "dd/MM/yyyy",
        LOCALE: "MM/dd/yyy"
    }

    public static convert(dateStr: string, toFormat: string) {
        if (!dateStr || dateStr === '' || dateStr === null || dateStr == undefined) return '';
        let date = new Date(dateStr);
        if (date === null || date.toString() === "Invalid Date" || date === undefined) return '';
        switch (toFormat) {
            case this.format.API:
                return `${date.getFullYear()}-${date.getMonth() + 1 > 9 ? '' : '0'}${date.getMonth() + 1}-${date.getDate() > 9 ? '' : '0'}${date.getDate()}`;
            case this.format.LOCALE:
                let localTiming = date.toLocaleDateString('en-GB');
                return localTiming;
            case this.format.INPUT:
                return `${date.getDate() > 9 ? '' : '0'}${date.getDate()}/${date.getMonth() + 1 > 9 ? '' : '0'}${date.getMonth() + 1}/${date.getFullYear()}`;
            case this.format.SYS:
                return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + "T" + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds() + "." + date.getMilliseconds() + "Z";
            default:
                return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
        }
    }

    //Convert from format dd/MM/yyyy to Date
    public static convertInputToFormat(str: string, toFormat: string) {
        if (!str || str === null) return '';
        
        var values = str.split('/');
        if (values.length == 3 && values[0].length > 0 && values[1].length > 0 && values[2].length > 0) {
            var date = new Date(parseInt(values[2]), parseInt(values[1]) - 1, parseInt(values[0]));
            switch (toFormat) {
                case this.format.API:
                    return `${date.getFullYear()}-${date.getMonth() + 1 > 9 ? '' : '0'}${date.getMonth() + 1}-${date.getDate() > 9 ? '' : '0'}${date.getDate()}`;
                case this.format.LOCALE:
                    let localTiming = date.toLocaleDateString('en-GB');
                    return localTiming;
                case this.format.INPUT:
                    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
                case this.format.SYS:
                    return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + "T" + date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds() + "." + date.getMilliseconds() + "Z";
                default:
                    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
            }
        } else return '';
    }

    public static convertToDateObj(dataString) {
        let d = new Date(dataString);
        let localTiming = d.toLocaleDateString('en-GB');
        return localTiming;
    }


    public static convertStringtoDateSearch(str: string) {
        var values = str.split('/');
        if (values.length == 3 && values[2].length > 0) {
            var date = new Date(parseInt(values[2]), parseInt(values[1]) - 1, parseInt(values[0]));
            if (date instanceof Date && !isNaN(date.getTime()))
                return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
            else
                return '';
        } else return '';
    }

    public static convertObjtoDate(obj) {
        let day = obj.day > 9 ? obj.day : '0' + obj.day;

        let month = obj.month > 9 ? obj.month : '0' + obj.month;

        return `${day}/${month}/${obj.year}`;
    }

    public static convertStringToDate(str: string) {
        if (str !== null) {
            var values = str.split('/');
            if (values.length === 3) {
                return `${values[2]}-${values[1]}-${values[0]}`; //T00:00:00+07:00
            } else {
                return '';
            }
        } else {
            return '';
        }

    }

    /**
     * Định dạng ngày, chia theo ngôn ngữ
     * @param date 
     * @param lang 
     * @returns 
     */
     public static formatDate(date: string, lang: string): string {
        const dateOrigin = new Date(date);

        if (isNaN(dateOrigin.getMonth())) {
            return date;
        }

        if (lang === 'vi') {
            return (dateOrigin.getHours() < 10 ? "0" + dateOrigin.getHours() : dateOrigin.getHours())
                + ":" + (dateOrigin.getMinutes() < 10 ? "0" + dateOrigin.getMinutes() : dateOrigin.getMinutes())
                + " - " + dateOrigin.getDate()
                + " tháng " + (dateOrigin.getMonth() + 1);
        }
        else if (lang === 'en') {
            return (dateOrigin.getHours() < 10 ? "0" + dateOrigin.getHours() : dateOrigin.getHours())
                + ":" + (dateOrigin.getMinutes() < 10 ? "0" + dateOrigin.getMinutes() : dateOrigin.getMinutes())
                + " - " + this.translateMonthToEnglish(dateOrigin.getMonth() + 1)
                + " " + dateOrigin.getDate();
        }
    }
    
    public static translateMonthToEnglish(month: any): string {
        switch (month.toString()) {
            case "1":
            return "January";
            case "2":
            return "February";
            case "3":
            return "March";
            case "4":
            return "April";
            case "5":
            return "May";
            case "6":
            return "June";
            case "7":
            return "July";
            case "8":
            return "August";
            case "9":
            return "September";
            case "10":
            return "October";
            case "11":
            return "November";
            case "12":
            return "December";
            default:
            return ""
        }
    }
}