import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-landing-header',
  templateUrl: './landing-header.component.html',
  styleUrls: ['./landing-header.component.scss']
})
export class LandingHeaderComponent implements OnInit {
  @Input() callBackLanding;

  constructor() { }

  ngOnInit(): void {
  }

  scroll(name_el: string) {
    // if (this.callBackLanding instanceof Function) {
    //   this.callBackLanding(name_el);
    // }
  }

}
