import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { LocationExtension } from 'src/app/utils/common/LocationExtension';
import { REGEXP_EMAIL_SDT, REGEXP_EMAIL, REGEXP_PHONE_NUMBER } from 'src/app/utils/common/regexp';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { AasValidateMessage } from 'src/app/utils/common/validate-msg-aas';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  ServiceMsg = AasValidateMessage.FORGOT_PASSWORD;
  inputEmailForm: FormGroup;
  isSubmitEmail = false; // gửi email thành công
  sendEmailFailed = false;
  regexEmail = REGEXP_EMAIL
  regexSDT = REGEXP_PHONE_NUMBER
  remainingOtp = 2
  isSubmitSDT = false


  constructor(
    private aasAuth: AasAuthService,
    private router: Router,
    private alert: AlertControl
  ) { }

  ngOnInit(): void {
    this.makeSendEmailForm();
  }

  // Khởi tạo email form
  makeSendEmailForm() {
    this.inputEmailForm = new FormGroup({
      emailOrPhone: new FormControl(null, [Validators.required, Validators.pattern(REGEXP_EMAIL_SDT)]),
    });
  }

  // Lấy giá trị email
  get emailOrPhone() { return this.inputEmailForm.get('emailOrPhone') }

  sendEmail() {
    // this.isSubmit = true;
    this.sendEmailFailed = false;
    this.clearSpace();
    if (this.inputEmailForm.valid) {
      let type = 0
      if (this.regexEmail.test(this.emailOrPhone.value)){
        type = 1
      }
      else if(this.regexSDT.test(this.emailOrPhone.value)) {
        type = 2
      }

      type != 0 ? this.postCreateForgotPasswordV3(this.inputEmailForm.value.emailOrPhone,type) : ''
    }
  }

  clearSpace() {
    let str = `${this.emailOrPhone.value || ''}`;
    this.emailOrPhone.setValue(`${str.trim()}`);
  }


  postCreateForgotPasswordV3(emailOrPhone, type, domain = LocationExtension.HOSTNAME) {
    this.aasAuth.postCreateForgotPasswordV3(emailOrPhone,type, domain).subscribe((res: any) => {
      this.remainingOtp = res.object?.remainingOtp || 0
      // nếu là email thì gửi link lấy lại mk và thông báo
      if (type == 1){
        this.isSubmitEmail = true
      }
      // nếu là sdt thì gửi otp vào request otp
      else if (type == 2){
        // this.router.navigate(['/app/forgot-password/request-otp'], { queryParams: { 'user': emailOrPhone } });
        this.isSubmitSDT = true
        this.remainingOtp = res.object?.remainingOtp || ''
      }

    }, err => {

      if (err.error.message && ResponseMsg.MESSAGES.REQUEST_OTP[err.error.message]) {
        this.alert.showAlertOnlyNoti(ResponseMsg.MESSAGES.REQUEST_OTP[err.error.message], 'error');
      } else {
        this.alert.showAlertOnlyNoti(err.error.message, 'error');
      }
      
    }, () => { });
  }

  backHome(){
    window.location.href = ''
  }

}
