<!-- Footer -->
<div class="footer">
    <div class="container">
        <div class="row">
            <div class="col-md-4 ft-widget">
                <!-- <img class="logo-icon" height="35" src="assets/img/login/Logo.png" class="d-inline-block align-top"
                    alt="img-logo"> -->
                <h2 class="font-weight-bold"><span class="text-gray">e</span><span class="text-primary">Contract</span>
                </h2>
                <p class="nav-link pr-0 pl-0 mb-0">© VNPT 2020. All Rights Reserved.</p>
            </div>
            <div class="col-md ft-widget">
                <label class="title-wg">Product</label>
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <a class="nav-link pr-0 pl-0 active">Product</a>
                    </li>
                </ul>
            </div>
            <div class="col-md ft-widget">
                <label class="title-wg ">Features</label>
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <a class="nav-link pr-0 pl-0 active" href="#">Feature</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link pr-0 pl-0 active" href="#">Feature</a>
                    </li>
                </ul>
            </div>
            <div class="col-md ft-widget">
                <label class="title-wg ">Resources</label>
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <span class="nav-link pr-0 pl-0 active">Resource</span>
                    </li>
                    <!-- <li class="nav-item">
                        <span class="nav-link pr-0 pl-0 active" href="#"><em class="material-icons">mail</em>
                            vnptit@vnpt.vn</span>
                    </li>
                    <li class="nav-item">
                        <span class="nav-link pr-0 pl-0 active" href="#"><em class="material-icons">phone</em>
                            0243.553.3388</span>
                    </li> -->
                </ul>
            </div>
            <div class="col-md ft-widget">
                <label class="title-wg ">Company</label>
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <span class="nav-link pr-0 pl-0 active">Company</span>
                    </li>
                    <li class="nav-item">
                        <span class="nav-link pr-0 pl-0 active">Company</span>
                    </li>
                    <li class="nav-item">
                        <span class="nav-link pr-0 pl-0 active">Company</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>