export class RequestPreoder {
    createdAt: string = "";
    data: any = {};
    history: any = {};
    kafkaMessageId: string = "";
    modifiedAt: string = "";
    orderId: string = "";
    smeName: string = "";
    status: string = "";
    taxCode: string = "";

    constructor(d = null) {
        d = d || {};
        this.createdAt = d.createdAt || "";
        this.data = d.data || {};
        this.history = d.history || {};
        this.kafkaMessageId = d.kafkaMessageId || "";
        this.modifiedAt = d.modifiedAt || "";
        this.orderId = d.orderId || "";
        this.smeName = d.smeName || "";
        this.status = d.status || "";
        this.taxCode = d.taxCode || "";
    }
}