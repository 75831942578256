import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertBtn, ConstantAlertMsg } from 'src/app/utils/common/constant-alert-msg';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { REGEXP_EMAIL, REGEXP_NAME, REGEXP_PHONE_NUMBER, REGEXP_USERNAME } from 'src/app/utils/common/regexp';
import { PartListItem } from 'src/app/utils/models/part/part-list-item.model';
import { EmployeeService } from 'src/app/utils/services/aas-network/employee/employee.service';
import { PartService } from 'src/app/utils/services/aas-network/part/part.service';
import { Employee } from 'src/app/utils/models/employee/employee.model';
import { localize } from 'src/app/utils/localize/localize';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';
import { UserManagerService } from 'src/app/utils/services/aas-network/user-manager.service';
import { LoadingService } from 'src/app/utils/services/loading.service';
import { UserGroup } from 'src/app/utils/models/user-manager/user-group/user-group.model';
import { PermissionGroup } from 'src/app/utils/models/user-manager/list-all-permission/permission-group';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-employee-edit',
  templateUrl: './employee-edit.component.html',
  styleUrls: ['./employee-edit.component.scss']
})
export class EmployeeEditComponent implements OnInit {

  id = this.activedRoute.snapshot.paramMap.get('id');

  localize = localize;

  employee: Employee = new Employee();

  employeeForm: FormGroup;
  isSubmit = false;
  validateEmployeeForm = {
    fullName: {
      required: "EmployeeAdd_ts_1",
      pattern: "EmployeeAdd_ts_2"
    },
    email: {
      required: "EmployeeAdd_ts_3",
      pattern: "EmployeeAdd_ts_4"
    },
    phoneNumber: {
      pattern: "EmployeeAdd_ts_5",
      required: "EmployeeAdd_ts_6"
    },
    partyId: {
      required: "EmployeeAdd_ts_7"
    },
    username: {
      required: "EmployeeEdit_ts_1",
      pattern: "EmployeeEdit_ts_2"
    },
    disable: {
      required: "EmployeeEdit_ts_3"
    },
    roleId: {
      required: "EmployeeAdd_ts_8"
    }
  };

  parts: PartListItem[] = [];
  partsPage = 1;
  partsMaxsize = 10;
  partsTotalElement = 0;
  loading = false;
  numberOfItemsFromEndBeforeFetchingMore = 5;

  dropListRoleId : Observable<any>;
  roleIdLoading = false;
  roleNameInput$ = new Subject<string>();
  minLengthTerm = 3;

  privileges: PermissionGroup[] = [];

  partyNameSelected = "";

  constructor(
    private fb: FormBuilder,
    private activedRoute: ActivatedRoute,
    private partService: PartService,
    private router: Router,
    private employeeService: EmployeeService,
    private myAlert: AlertControl,
    private userManagerService: UserManagerService,
    private myLoader: LoadingService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.viewDidLoad();
  }

  viewDidLoad() {
    this.makeEmployeeForm();
    this.loadPartsDropList(false, 1);
    this.getEmployee(this.id);
    this.getDropListRoles();
  }

  makeEmployeeForm() {
    this.employeeForm = this.fb.group({
      fullName: ['', [Validators.required, Validators.pattern(REGEXP_NAME)]],
      email: ['', [Validators.required, Validators.pattern(REGEXP_EMAIL)]],
      phoneNumber: ['', [Validators.required, Validators.pattern(REGEXP_PHONE_NUMBER)]],
      partyId: [null, [Validators.required]],
      username: [{value: "", disabled: true}, [Validators.pattern(REGEXP_USERNAME)]],
      disable: [null],
      roleId: [null, [Validators.required]],
      roleName: [""]
    })
  }

  setData(data: Employee) {
    this.employeeForm.patchValue({
      fullName: data.fullName || "",
      email: data.email || "",
      phoneNumber: data.phoneNumber || "",
      partyId: data.partyId || null,
      username: data.username || "",
      disable: data.disable,
      roleId: data.roleId  || null,
      roleName: data.roleName
    })

    this.partyNameSelected = this.employee.partyName;
    this.getPrivilegesUserGroup(this.employee.roleId);
  }

  save() {
    this.isSubmit = true;
    console.log(this.employeeForm.value);
    if (this.employeeForm.valid) {
      this.myAlert.showAlert(this.translate.instant('EmployeeEdit_ts_4'), ConstantAlertType.BLANK, true, '', this.translate.instant('btn_confirm'), () => {
        let param = {
          "fullName": this.employeeForm.value.fullName,
          "email": this.employeeForm.value.email,
          "phoneNumber": this.employeeForm.value.phoneNumber,
          "disable": this.employeeForm.value.disable,
          "roleId": this.employeeForm.value.roleId,
          "partyId": this.employeeForm.value.partyId,
          "roleName": this.employeeForm.value.roleName
        };
        this.putUpdateEmployee(this.employeeForm.value.partyId, this.id, param);
      }, '', this.translate.instant('btn_close'), null, this.translate.instant('model_title_confirm'));
    }
  }

  cancel() {
    this.router.navigate([ConstantUrl.EMPLOYEE_LIST]);
    // this.isSubmit = false;
    // this.employeeForm.reset();
    // this.setData(this.employee);
  }

  /**
 * Load droplist chon bo phan
 * @param page 
 */
  private loadPartsDropList(isLoadMore, page) {
    this.partsPage = page;
    this.getParts(isLoadMore, this.partsPage, this.partsMaxsize, "", "DESC", "", "");
  }

  /**
   * Su kien scroll xuong duoi cung cua droplist
   * @returns 
   */
  onScrollToEnd() {
    var nextPage = this.partsPage + 1;
    var nextLength = nextPage * this.partsMaxsize;

    if ((this.parts.length >= this.partsTotalElement) || (nextLength - this.partsTotalElement >= this.partsMaxsize)) return;
    this.loadPartsDropList(true, this.partsPage + 1);
  }

  onScroll({ end }) {
  }

  //MARK: UTILS
  hideSpinner() {
    return !this.loading;
  }

  //MARK: NETWORK
  getParts(isLoadMore = false, page, maxSize, propertiesSort, sort, status, keySearch) {
    this.loading = isLoadMore;
    var currentParts = [];
    this.partService.getParts(page, maxSize, propertiesSort, sort, status, keySearch).subscribe((res: any) => {
      if (res.object && res.object.data && res.object.data instanceof Array) {
        res.object.data.forEach(item => {
          currentParts.push(new PartListItem(item));
        });
      }
      this.parts = this.parts.concat(currentParts);
      this.partsTotalElement = res?.object?.totalElement || 0;
      this.loading = false;
    }, error => {
      this.loading = false;
    }, () => {
      this.loading = false;
    });
  }

  putUpdateEmployee(partyId, userId, param) {
    this.employeeService.putUpdateEmployee(partyId, userId, param).subscribe((res: any) => {
      this.myAlert.showAlert(this.translate.instant('EmployeeEdit_ts_5'), ConstantAlertType.SUCCESS,false, '', this.translate.instant('btn_confirm'), () => {
        this.router.navigate([ConstantUrl.EMPLOYEE_DETAIL, this.id])
      });
      
      this.router.navigate([ConstantUrl.EMPLOYEE_DETAIL, this.id]);
    }, err => {
      if (err.error && err.error.code && err.error.code === "CONTRACT_INTERNAL_ASSIGN") {
        this.myAlert.showAlert(this.translate.instant('EmployeeEdit_ts_6'), ConstantAlertType.ERROR);
      } else if (err.error && err.error.code && err.error.code === "DEPARTMENT_STILL_DISABLE") {
        let msg = this.translate.instant('EmployeeEdit_ts_7');
        msg = msg.replace("${namePart}", this.partyNameSelected);
        this.myAlert.showAlert(msg, ConstantAlertType.ERROR);
      } else {
        this.myAlert.showErrorHandled(err);
      }
    })
  }

  getEmployee(id) {
    this.employeeService.getEmployee(id).subscribe((res: any) => {
      this.employee = new Employee(res?.object?.data);
      this.getPrivilegesUserGroup(this.employee.roleId);
      this.setData(this.employee);
    }, err => {

    });
  }

  /**
   * Load ds droplist nhom nguoi dung
   */
  getDropListRoles() {
    this.dropListRoleId = concat(
      this.roleNameInput$.pipe(
      startWith(''),
      debounceTime(800),
      distinctUntilChanged(),
      tap(() => { this.roleIdLoading = true; this.myLoader.disabledLoader(this.roleIdLoading); }),
      switchMap((term) => {
        let res = this.userManagerService.getDropListRoles(term).pipe(
          map((res: any) => res?.object || []),
          catchError(() => of([])), // empty list on error
          tap(() => { this.roleIdLoading = false; this.myLoader.disabledLoader(this.roleIdLoading); })
        );
        return res;
      })
    ));
  }

  trackByFn(item: any) {
    return item.roleId;
  }

  changedRoleId($event) {
    if ($event) {
      this.employeeForm.controls.roleName.setValue($event.name)
      this.getPrivilegesUserGroup($event.uuidRole);
    }
  }

  /**
   * get
   * @param id 
   */
   getPrivilegesUserGroup(id) {
    var data: UserGroup;
    this.userManagerService.getPrivilegesUserGroupV2(id).subscribe((res: any) => {
      data = new UserGroup(res.object);
      this.privileges = data.privileges;
    }, err => {
    }, () => {
    });
  }

  changedParty(e) {
    if (e) {
      this.partyNameSelected = e?.partyName || "";
    }
  }

  clearParty(e) {
    this.partyNameSelected = "";
  }
}
