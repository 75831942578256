import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing-main',
  templateUrl: './landing-main.component.html',
  styleUrls: ['./landing-main.component.scss']
})
export class LandingMainComponent implements OnInit {

  componentReference: any;

  constructor() { }

  ngOnInit(): void {
  }

  onActivate(componentReference) {
    // console.log(componentReference)
    // this.componentReference = componentReference;
    // console.log(this.componentReference);
    // componentReference.anyFunction();
  }

  callBackLanding(name_el: string) {
    // console.log(this.componentReference);
    // this.componentReference.scrollTo(name_el);
  }

}
