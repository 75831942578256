<app-spinner></app-spinner>
<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-auto">
                <h1 class="m-0 text-dark"><label>{{ 'ServiceProfileManager_title1' | translate }}</label></h1>
            </div>
            <!-- /.col -->
            <div class="col-md-auto ml-auto">
                <!-- <button class="btn btn-outline-primary btn-light d-inline-flex mr-3"><span
                        class="material-icons mr-1">publish</span> Import Excel</button>
                <button class="btn btn-outline-primary btn-light d-inline-flex mr-3"><span
                        class="material-icons mr-1">get_app</span> Tải file mẫu</button> -->
            </div>
            <!-- <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>

<!--MARK top bar -->
<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-4">
                <div class="card shadow-none">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-auto align-self-end">
                                <label class="text-lg">{{ 'ServiceProfileManager_title2' | translate }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="position-relative">
                        <!-- <app-mini-spinner *ngIf="loadingListUserGroupIsVisiable() | async"></app-mini-spinner> -->
                        <div class="no-content-list">
                            <img class="mb-2" src="{{ imageLevel[userInfo.identityLevel] }}" width="100">
                            <p class="mb-1">{{ 'ServiceProfileManager_title3' | translate }} {{ userInfo.identityLevel }}</p>

                            <button *ngIf="userInfo.identityLevel <= 1" (click)="updateVerifyAccount()" class="btn btn-primary d-inline-flex mt-3"><span class="material-icons mr-1">how_to_reg</span> Nâng cấp định danh</button>
                        </div>
                    </div>
                </div>
                <!-- <app-mini-spinner *ngIf="loadingLeftIsVisiable() | async"></app-mini-spinner> -->
            </div>
            <div class="col-md-8">
                <!-- <div *ngIf="isLoading | async" class="d-flex justify-content-center mt-3">
                    <div class="spinner-grow text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div> -->
                <div class="card shadow-none">
                    <div class="card-body position-relative">
                        <app-profile-consumer #profileConsumer *ngIf="userInfo.userType === 'CONSUMER'" [userInfo]="userInfo" (onActionSave)='actionSave($event)'></app-profile-consumer>
                        <app-profile-business #profileBusiness *ngIf="userInfo.userType === 'BUSINESS' || userInfo.userType === 'ENTERPRISE'" [userInfo]="userInfo" (onActionSave)='actionSave($event)'></app-profile-business>
                        <!-- <app-profile-business [data]='data | async ' (onActionSave)='actionSave($event)'></app-profile-business> -->
                        <!-- <app-mini-spinner *ngIf="loadingRightVisiable() | async"></app-mini-spinner> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>