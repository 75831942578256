<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-auto">
                <h1 class="m-0 text-dark"><label>Danh sách hợp đồng</label></h1>
            </div>
            <!-- /.col -->
            <div class="col-md-auto ml-auto">
                <!-- <button class="btn btn-outline-primary btn-light d-inline-flex mr-3"><span
                        class="material-icons mr-1">publish</span> Import Excel</button> -->
                        <!-- *showWithRoles="['DRCDOF']" -->
                <button  (click)="addContract()" class="btn btn-primary d-inline-flex mr-3"><span
                        class="material-icons mr-1">add</span> Thêm mới</button>
            </div>
            <!-- <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>

<section class="content">
    <div class="container-fluid">
        <div class="tab-content contract-tab-body" id="tabSignAction">
            <!--MARK Hợp đồng đã gửi -->
            <div class="position-relative tab-pane fade show active ">
                <app-admin-contract-list by="OWNER"></app-admin-contract-list>
            </div>
        </div>
    </div>
</section>

<ng-template #modalCreateContract let-c="close" let-d="dismiss">
    <div class="modal-header border-0 mb-0">
        <h5 class="modal-title font-weight-bold">Xác nhận thông tin</h5>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span class="material-icons">
                close
            </span>
        </button>
    </div>
    <div class="modal-body py-0">
        <div class="modal-desc text-left">
            <p>Bạn đang thực hiện tạo hợp đồng cho yêu cầu chuyển đổi dịch vụ của khách hàng. Vui lòng kiểm tra lại thông tin trước tạo hợp đồng.</p>
        </div>
        <form [formGroup]="formGetPackage">
            <!-- <div class="form-group">
                <label>Gói dịch vụ đang sử dụng </label>
                <input class="form-control" value="Gói dùng thử" disabled>
            </div> -->
            <div class="form-group">
                <label>Gói dịch vụ đăng ký mới: </label>
                <ng-select2 formControlName="packageId" [options]="options"
                    [data]="packageData" [placeholder]="'Chọn gói dịch vụ'">
                </ng-select2>
                <app-show-validate-errors [isSubmit]="isSubmit"
                    [errorMessages]="validContractMsg.packageId"
                    [control]="formGetPackage.controls.packageId"
                    [detail]="{ name: 'packageId' }"></app-show-validate-errors>
            </div>
        </form>


    </div>
    <div class="modal-footer border-0 d-flex justify-content-end">
        <button type="button" class="btn btn-outline-primary" (click)="c('Close')">Huỷ</button>
        <button type="button" class="btn btn-primary" (click)="submitReturn()">Tiếp tục</button>
    </div>
</ng-template>