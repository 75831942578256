import { Injectable } from '@angular/core';
import { API } from '../../api';
import { ServicePath } from 'src/app/utils/common/constant-service-api';

const GET_LIST_PACKAGE_URL = ServicePath.MANAGEMENT_SERVICE + '/package-information/v1/list';
const GET_DETAIL_PACKAGE_URL = ServicePath.MANAGEMENT_SERVICE + '/package-information/v1';
const GET_INFRA_INFOR_URL = ServicePath.REPORT_SERVICE + '/quantity-info';
const ASSIGN_PACKAGE_URL = ServicePath.MANAGEMENT_SERVICE + '/package-information/admin/approve-package';
const GET_HIS_USER_PACKAGE = ServicePath.MANAGEMENT_SERVICE + '/licenses/package-history-of-user?partyId=';

@Injectable({
  providedIn: 'root'
})
export class PackageServiceService {

  constructor(
    private api: API
  ) { }

  // Get list of package
  getListPackage(page: number = 1, maxSize: number = 10000, propertiesSort: any = '',
    packageType: any = '', status: any = '', userType: any = '', sort: string = 'DESC', searchString: string = '') {
      const url = GET_LIST_PACKAGE_URL + `?page=${page}&maxSize=${maxSize}&propertiesSort=${propertiesSort}&packageType=${packageType}&status=${status}&userType=${userType}&sort=${sort}&searchString=${searchString}`;
      
      return this.api.get(url);
  }

  // Get detail of package
  getDetailPackage(packageId) {
    const url = GET_DETAIL_PACKAGE_URL + `/${packageId}`;
    return this.api.get(url);
  }

  // Get information of infrastructure
  getInfraInfor(partyId) {
    const url = GET_INFRA_INFOR_URL + `/${partyId}`;
    return this.api.get(url);
  }

  // Assign package to enduser
  assignPackage(partyId: string, packageId: string, price: number, infrastructures: Array<any>) {
    const data = {
      "partyId": partyId,
      "packageId": packageId,
      "price": price,
      "infrastructures": infrastructures
    };
    const url = ASSIGN_PACKAGE_URL;
    return this.api.post(url, data);
  }

  /**
   * Lấy thông tin dữ liệu gói dịch vụ hiện tại của 1 tổ chức
   * @param partyId 
   * @returns 
   */
  getLastInfoUserPackage(partyId) {
    const url = GET_HIS_USER_PACKAGE + `${partyId}`;
    return this.api.get(url);
  }
}
