import { FileUploadObject } from './../../file/fileupload-response/fileupload-object.model'
import { SignatureItem } from './../list-contract/signature-item.model'
export class DetailContractItem {
  contractId: string
  contractNumber: string
  title: string
  type: string
  validDate: string
  legalStatus: string
  currentStage: string
  currentSideStage: object
  signerName: string
  signatures: SignatureItem[]
  documents: FileUploadObject[] = []
  created: string
  modified: string
  templateId: string
  lifecycleId: string
  denyReason: string
  signerId: string
  amId: string
  posts: ContractPostObj[]

  constructor(obj = null) {
    obj = obj || {}
    this.contractId = obj.contractId || ''
    this.contractNumber = obj.contractNumber || ''
    this.title = obj.title || ''
    this.type = obj.type || ''
    this.validDate = obj.validDate || ''
    this.signerId = obj.signerId || ''
    this.legalStatus = obj.legalStatus || ''
    this.currentStage = obj.currentStage || ''
    this.currentSideStage = obj.currentSideStage || {
      SIDE_A: '',
      SIDE_B: ''
    }
    this.signerName = obj.signerName || ''
    this.documents = []
    if (obj.documents && obj.documents instanceof Array) {
      obj.documents.forEach((item) => {
        this.documents.push(new FileUploadObject(item))
      })
    }
    this.created = obj.created || ''
    this.amId = obj.amId || ''
    this.modified = obj.modified || ''
    this.templateId = obj.templateId || ''
    this.lifecycleId = obj.lifecycleId || ''
    this.signatures = []
    if (obj.signatures && obj.signatures instanceof Array) {
      obj.signatures.forEach((item) => {
        this.signatures.push(new SignatureItem(item))
      })
    }
    this.denyReason = obj.denyReason || ''
    this.posts = []
    if (obj.posts && obj.posts instanceof Array) {
      obj.posts.forEach((item) => {
        this.posts.push(new ContractPostObj(item))
      })
    }
  }
}

export class ContractPostObj {
  id: string
  createdDate: string
  contractId: string
  userId: string
  value: string
  lastUpdate: string
  type: string
  isEdit: boolean
  isReply: boolean
  documents: FileUploadObject[] = []
  comments: ContractCommentObj[] = []
  email: string;
  username: string
  constructor(obj = null) {
    obj = obj || {}
    this.id = obj.id || ''
    this.createdDate = obj.createdDate || ''
    this.contractId = obj.contractId || ''
    this.userId = obj.userId || ''
    this.value = obj.value || ''
    this.lastUpdate = obj.lastUpdate || ''
    this.type = obj.type || ''
    this.isEdit = obj.isEdit || ''
    this.isReply = obj.isReply || ''
    this.username = obj.username || ''
    this.email = obj.email || ''
    this.documents = []
    if (obj.documents && obj.documents instanceof Array) {
      obj.documents.forEach((item) => {
        this.documents.push(new FileUploadObject(item))
      })
    }
    this.comments = []
    if (obj.comments && obj.comments instanceof Array) {
      obj.comments.forEach((item) => {
        this.comments.push(new ContractCommentObj(item))
      })
    }
  }
}

export class ContractCommentObj {
  id: string
  createdDate: string
  contractId: string
  userId: string
  value: string
  lastUpdate: string
  postId: string
  type: string
  isEdit: boolean
  isReply: boolean
  username: string
  documents: FileUploadObject[] = []
  email: string;
  tagEmail: string;
  tagName: string;
  constructor(obj = null) {
    obj = obj || {}
    this.id = obj.id || ''
    this.createdDate = obj.createdDate || ''
    this.contractId = obj.contractId || ''
    this.userId = obj.userId || ''
    this.value = obj.value || ''
    this.type = obj.type || ''
    this.isEdit = obj.isEdit || ''
    this.isReply = obj.isReply || ''
    this.lastUpdate = obj.lastUpdate || ''
    this.username = obj.username || ''
    this.tagEmail = obj.tagEmail || ''
    this.tagName = obj.tagName || ''
    this.email = obj.email || ''
    this.documents = []
    if (obj.documents && obj.documents instanceof Array) {
      obj.documents.forEach((item) => {
        this.documents.push(new FileUploadObject(item))
      })
    }
    this.postId = obj.postId || ''
  }
}

export class ContractActivityObj {
  id: string
  timestamp: string
  action: string
  type: string
  newStatus: string
  oldStatus: string
  objectId: string
  objectName: string
  ownerId: string
  subjectId: string
  subjectName: string

  constructor(obj = null) {
    obj = obj || {}
    this.id = obj.id || ''
    this.timestamp = obj.timestamp || ''
    this.action = obj.action || ''
    this.type = obj.type || ''
    this.newStatus = obj.newStatus || ''
    this.oldStatus = obj.oldStatus || ''
    this.objectId = obj.objectId || ''
    this.objectName = obj.objectName || ''
    this.ownerId = obj.ownerId || ''
    this.subjectId = obj.subjectId || ''
    this.subjectName = obj.subjectName || ''
  }
}
