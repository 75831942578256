import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select2OptionData, Options } from 'select2';
import { BehaviorSubject } from 'rxjs';
import { ValidateMsg } from 'src/app/utils/common/constant-einvoices';
import { ListUserGroupItem } from 'src/app/utils/models/user-manager/user-groups/list-user-group-item';
import { UserManagerService } from 'src/app/utils/services/aas-network/user-manager.service';
import { LoadingService } from 'src/app/utils/services/loading.service';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { REGEXP_ID } from 'src/app/utils/common/regexp';
import { NgModalComponent } from 'src/app/layout/extensions/ng-modal/ng-modal.component';
import { ListUserGroupResponse } from 'src/app/utils/models/user-manager/user-groups/list-user-group-response';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { localize } from 'src/app/utils/localize/localize';
import { DualListItem } from 'src/app/utils/models/duallist/duallist-item';
import { PermissionGroup } from 'src/app/utils/models/user-manager/list-all-permission/permission-group';
import { ListAllPermisionReponse } from 'src/app/utils/models/user-manager/list-all-permission/list-all-permission-response';
import { StringUtils } from 'src/app/utils/common/StringUtils';
import { UserGroup } from 'src/app/utils/models/user-manager/user-group/user-group.model';
import { TranslateService } from '@ngx-translate/core';

declare var $: any;
@Component({
  selector: 'app-group-permissions',
  templateUrl: './group-permissions.component.html',
  styleUrls: ['./group-permissions.component.scss']
})
export class GroupPermissionsComponent implements OnInit {
  @ViewChild('searchNotAssigned') searchNotAssigned: ElementRef;
  @ViewChild('searchAssigned') searchAssigned: ElementRef;

  componentName: string = "GroupPermissions";

  public disableData: Array<Select2OptionData> = [];
  public options: Options;

  page = 1;
  maxSize = 5;
  search = "";
  totalElement = 0;
  propertiesSort = "";
  sort = "";
  loadingServiceIsVisiable = new BehaviorSubject<boolean>(false);
  loadingListUserGroup = new BehaviorSubject<boolean>(false);
  loadingForm = new BehaviorSubject<boolean>(false);

  listUserGroup: ListUserGroupItem[] = [];

  formSearch: FormGroup;
  formUserGroup: FormGroup;
  ErrorMsg = {
    required: "GroupPermissions_required",
    name: {
      required: "GroupPermissions_nameRequired",
      pattern: "GroupPermissions_namePattern",
      unique: "GroupPermissions_nameUnique"
    },
    roleCode: {
      required: "GroupPermissions_roleCodeRequired",
      pattern: "GroupPermissions_roleCodePattern",
      unique: "GroupPermissions_roleCodeUnique"
    },
    disable: {
      required: "GroupPermissions_disableRequired"
    }
  };
  isSubmit = false;
  isCreateForm = true;
  isUpdateForm = false;
  descriptionValue = "";
  selectedUserGroup = new ListUserGroupItem();

  //Assign permission
  listPermissionGroup: PermissionGroup[] = [];
  listAssigned: PermissionGroup[] = [];

  dualList1: DualListItem[] = [];
  dualList2: DualListItem[] = [];
  selectedAll1: boolean = false;
  selectedAll2: boolean = false;

  baseList: DualListItem[] = new Array<DualListItem>();
  dualList: DualListItem[][] = [new Array<DualListItem>(), new Array<DualListItem>()];
  oriList: DualListItem[][] = [new Array<DualListItem>(), new Array<DualListItem>()];
  selectedAll = [false, false];
  filter = [false, false];

  constructor(
    private fb: FormBuilder,
    private loadingService: LoadingService,
    private userManagerService: UserManagerService,
    private modalService: NgbModal,
    private myAlert: AlertControl,
    private translate: TranslateService
    ) { }

  ngOnInit(): void {
    this.makeFormSearch();
    this.makeFormUserGroup();

    this.options = {
      dropdownAutoWidth: true,
      minimumResultsForSearch: Infinity,
      width: '100%',
      closeOnSelect: true,
      language: "vi"
    };

    this.disableData = [
      {
        id: '0',
        text: 'Đang hoạt động'
      },
      {
        id: '1',
        text: 'Ngưng hoạt động'
      }
    ];

    this.getListUserGroupV2(this.page, this.maxSize, this.search);
    this.getAllPrivileges();
  }

  ngAfterViewInit(): void {
    this.initJqueryClickRow();
  }

  //MARK: EventEmitter Loading
  loadingListUserGroupIsVisiable(): Observable<boolean> {
    return this.loadingListUserGroup.asObservable().pipe(share());
  }

  loadingFormIsVisiable(): Observable<boolean> {
    return this.loadingForm.asObservable().pipe(share());
  }

  //MARK: Chức năng danh sách, tìm kiếm nhóm người dùng
  /**
   * Tạo from tìm kiếm
   */
  makeFormSearch() {
    this.formSearch = this.fb.group({
      search: new FormControl('')
    });
  }

  get keySearch() { return this.formSearch.get('search'); }

  /**
   * EventEmitter: Sự kiện nhập key trên form search
   * @param event
   */
  eventInputSearch(event) {
  }

  /**
   * Search nhóm người dùng
   * @param keySearch
   */
  searchData(keySearch: string) {
    this.page = 1;
    this.getListUserGroupV2(this.page, this.maxSize, keySearch);
  }

  /**
   * EventEmitter: Load trang mới
   * @param e
   */
  loadPage(page) {
    this.page = page;
    this.getListUserGroupV2(this.page, this.maxSize, this.keySearch.value);
  }

  //MARK: Chức năng thêm mới nhóm người dùng
  makeFormUserGroup() {
    this.formUserGroup = this.fb.group({
      name: new FormControl('', [Validators.required, Validators.pattern(/^[^~@#$%^*=+|\}\{\[\]\;\'\"\,\>\<?`]*$/)]),
      description: new FormControl(''),
      roleCode: new FormControl('', [Validators.required, Validators.pattern(REGEXP_ID)]),
      disable: new FormControl(0, [Validators.required]),
      uuidRole: new FormControl('')
    });
    // this.resetForm();
  }

  get name() { return this.formUserGroup.get('name'); }
  get description() { return this.formUserGroup.get('description'); }
  get roleCode() { return this.formUserGroup.get('roleCode'); }
  get disable() { return this.formUserGroup.get('disable'); }
  get uuidRole() { return this.formUserGroup.get('uuidRole'); }

  /**
   * Event click nút thêm
   */
  actBtnAddd() {
    this.resetAction();
    this.isCreateForm = true;

    this.resetForm();
    this.listAssigned = [];
    this.setupDualList(this.listAssigned);
  }

  /**
   * Event Thay đổi nội dung trường mô tả
   * @param value
   */
  changeDescription(value) {
    this.description.setValue(value);
    this.descriptionValue = value;
  }

  /**
   * Set trạng thái cho nhóm người dùng
   * @param event
   */
  changedStatus(event) {
    this.disable.setValue(event.target.value);
  }

  /**
   * Submit Khởi tạo nhóm người dùng
   */
  submitCreatedForm() {
    this.isSubmit = true;
    if (this.formUserGroup.valid) {
      var param = this.makeParamSubmit(this.selectedUserGroup.id, this.oriList[1]);
      console.log(param);

      this.myAlert.showAlert(this.translate.instant('GroupPermissions_confirm_add_group'), ConstantAlertType.BLANK, true, "", this.translate.instant('btn_confirm'), () => {
        this.postCreatedUserGroup(param);
      }, "", this.translate.instant('btn_cancel'));
    }
  }

  /**
   * Tạo param lưu thông tin nhóm người dùng
   * @param id 
   * @param listRole 
   */
   makeParamSubmit(id: string, listRole: DualListItem[] = []) {
    var privileges: string[] = [];

    console.log(listRole);

    listRole.forEach((a, i) => {
      a.child.forEach((b, j) => {
        privileges.push(b.id);
      })
    });

    return {
      "description": this.formUserGroup.value.description,
      "disable": Number(this.formUserGroup.value.disable),
      "name": this.formUserGroup.value.name,
      "roleCode": this.formUserGroup.value.roleCode,
      "uuidPrivileges": privileges
    }
  }

  //MARK: Cập nhật thông tin nhóm người dùng
  initJqueryClickRow() {
    $('.user-group-item').click(function () {
      alert("ok");
      $('.user-group-item').removeClass('active');
      $(this).addClass('active');
    })
  }

  selectUserGroup(userGroup: ListUserGroupItem) {
    this.resetAction();
    this.isUpdateForm = true;

    this.resetForm();
    this.name.setValue(userGroup.name);
    this.description.setValue(userGroup.description);
    this.descriptionValue = userGroup.description;
    this.roleCode.setValue(userGroup.roleCode);
    this.disable.setValue(userGroup.disable);
    this.uuidRole.setValue(userGroup.uuidRole);

    this.selectedUserGroup = userGroup;

    //Setting dual list assign permission
    this.filter = [false, false];
    this.searchNotAssigned.nativeElement.value = "";
    this.searchAssigned.nativeElement.value = "";
    this.getPrivilegesUserGroup(userGroup.id);
  }

  /**
   * Submit Cập nhật thông tin nhóm người dùng
   */
  submitUpdateForm() {
    this.isSubmit = true;
    if (this.formUserGroup.valid) {
      var param = this.makeParamSubmit(this.selectedUserGroup.id, this.oriList[1]);
      console.log(param);

      this.myAlert.showAlert(this.translate.instant('GroupPermissions_confirm_save_info_group'), ConstantAlertType.BLANK, true, "", this.translate.instant('btn_confirm'), () => {
        this.putUpdateUserGroup(this.formUserGroup.value.uuidRole, param);
      }, "", this.translate.instant('btn_cancel'));
    }
  }

  //MARK: Chức năng xóa nhóm người dùng
  actBtnDelete($event, uuidRole) {
    $event.stopPropagation();
    this.myAlert.showAlert(this.translate.instant('GroupPermissions_confirm_delete_group'), ConstantAlertType.BLANK, true, "", this.translate.instant('btn_confirm'), () => {
      this.deleteUserGroup(uuidRole);
    }, "", this.translate.instant('btn_cancel'));
  }

  //MARK: Chức năng nút lưu thông tin
  resetForm() {
    this.isSubmit = false;
    this.name.setValue("");
    this.description.setValue("");
    this.descriptionValue = this.description.value;
    this.roleCode.setValue("");
    this.disable.setValue(Number(0));
    this.uuidRole.setValue("");

    this.dualList1 = [];
    this.dualList2 = [];
    this.listAssigned = [];
    this.setupDualList(this.listAssigned);
  }

  resetAction() {
    this.isCreateForm = false;
    this.isUpdateForm = false;
  }

  /**
   * Event Action nút lưu thông tin
   */
  actBtnSave() {
    if (this.isCreateForm) {
      this.submitCreatedForm();
    } else if (this.isUpdateForm) {
      this.submitUpdateForm();
    }
  }

  /**
   * Event Action nút hủy
   */
  actBtnCancel() {
    if (this.isUpdateForm) {
      this.selectUserGroup(this.selectedUserGroup);
    } else if (this.isCreateForm) {
      this.resetAction();
      this.isCreateForm = true;

      this.resetForm();
    }
  }

  //MARK: Network API
  /**
 * Network: Get danh sach nhóm người dùng
 * @param page
 * @param maxSize
 * @param search
 */
  getListUserGroup(page, maxSize, search) {
    this.loadingListUserGroup.next(true);
    var data = new ListUserGroupResponse();
    this.userManagerService.getListUserGroup(page, maxSize, search).subscribe(res => {
      this.loadingListUserGroup.next(false);
      data = new ListUserGroupResponse(res);
      this.listUserGroup = data.object.data;
      this.totalElement = data.object.totalElement;
      console.log(this.listUserGroup);

      if (this.listUserGroup && this.listUserGroup.length > 0) {
        this.selectUserGroup(this.listUserGroup[0]);
      }
    }, err => {
      console.log(err);
      this.loadingListUserGroup.next(false);
      this.myAlert.showErrorHandled(err);
    }, () => {
      this.loadingListUserGroup.next(false);
    })
  }

  /**
   * GET danh sach nhom nguoi dung (v2)
   * @param page 
   * @param maxSize 
   * @param search 
   */
  getListUserGroupV2(page, maxSize, search) {
    this.loadingListUserGroup.next(true);
    var data = new ListUserGroupResponse();
    this.userManagerService.getListUserGroupV2(page, maxSize, search).subscribe(res => {
      this.loadingListUserGroup.next(false);
      data = new ListUserGroupResponse(res);
      this.listUserGroup = data.object.data;
      this.totalElement = data.object.totalElement;
      console.log(this.listUserGroup);

      if (this.listUserGroup && this.listUserGroup.length > 0) {
        this.selectUserGroup(this.listUserGroup[0]);
      }
    }, err => {
      console.log(err);
      this.loadingListUserGroup.next(false);
      this.myAlert.showErrorHandled(err);
    }, () => {
      this.loadingListUserGroup.next(false);
    })
  }

  /**
   * POST created user group
   * @param param
   */
  postCreatedUserGroup(param) {
    this.loadingForm.next(true);
    this.userManagerService.postCreatedUserGroupV2(param).subscribe(res => {
      this.loadingForm.next(false);
      console.log(res);
      this.page = 1;
      this.keySearch.setValue("");
      this.getListUserGroupV2(this.page, this.maxSize, this.keySearch.value);
      this.actBtnCancel();
      this.myAlert.showAlertOnlyNoti(this.translate.instant('GroupPermissions_add_info_group_success'), ConstantAlertType.SUCCESS, "", this.translate.instant('btn_close'));
    }, err => {
      this.loadingForm.next(false);
      console.log(err);
      if (err.error && err.error.messageFields && err.error.messageFields instanceof Array) {
        let msgs = err.error.messageFields;
        msgs.forEach(item => {
          if (item.fieldName && item.fieldName === 'roleCode' && item.message === 'ECT-00001104') {
            this.roleCode.setErrors({ unique: true });
          }

          if (item.fieldName && item.fieldName === 'name' && item.message === 'ECT-00001104') {
            this.name.setErrors({ unique: true });
          }
        });
      } else {
        this.myAlert.showErrorHandled(err);
      }
    }, () => {
      this.loadingForm.next(false);
    })
  }

  /**
   * PUT Cập nhật nhóm người dùng
   * @param id
   * @param param
   */
  putUpdateUserGroup(id, param) {
    this.loadingForm.next(true);
    this.userManagerService.putUpdateUserGroupV2(id, param).subscribe(res => {
      this.loadingForm.next(false);
      this.getListUserGroupV2(this.page, this.maxSize, this.keySearch.value);
      this.myAlert.showAlertOnlyNoti(this.translate.instant('GroupPermissions_save_info_group_success'), ConstantAlertType.SUCCESS, "", this.translate.instant('btn_close'));
    }, err => {
      console.log(err);
      this.loadingForm.next(false);
      if (err && err.error && err.error.message === "ECT-00001101") {
        this.myAlert.showAlertOnlyNoti(this.translate.instant('GroupPermissions_cannot_disabled_group'), ConstantAlertType.ERROR, "", this.translate.instant('btn_close'));
      } else if (err.error && err.error.messageFields && err.error.messageFields instanceof Array) {
        let msgs = err.error.messageFields;
        msgs.forEach(item => {
          if (item.fieldName && item.fieldName === 'roleCode' && item.message === 'ECT-00001104') {
            this.roleCode.setErrors({ unique: true });
          }

          if (item.fieldName && item.fieldName === 'name' && item.message === 'ECT-00001104') {
            this.name.setErrors({ unique: true });
          }
        });
      } else {
        this.myAlert.showErrorHandled(err);
      }
    }, () => {
      this.loadingForm.next(false);
    })
  }

  /**
   * DELETE Xóa nhóm người dùng
   * @param id
   */
  deleteUserGroup(id) {
    this.loadingListUserGroup.next(true);
    var data: any;
    this.userManagerService.deleteUserGroupV2(id).subscribe(res => {
      this.loadingListUserGroup.next(false);
      data = res;
      this.page = 1;
      this.keySearch.setValue("");
      this.getListUserGroupV2(this.page, this.maxSize, this.keySearch.value);
      this.myAlert.showAlertOnlyNoti(this.translate.instant('GroupPermissions_delete_group_success'), ConstantAlertType.SUCCESS, "", this.translate.instant('btn_close'));
    }, err => {
      console.log(err);
      this.loadingListUserGroup.next(false);
      if (err && err.error && err.error.message === "ECT-00001101") {
        this.myAlert.showAlertOnlyNoti(this.translate.instant('GroupPermissions_move_all_member_before_delete'), ConstantAlertType.ERROR, "", this.translate.instant('btn_close'));
      } else {
        this.myAlert.showErrorHandled(err);
      }
    }, () => {
      this.loadingListUserGroup.next(false);
    })
  }


  //MARK: DUAL LIST ASSIGN PERMISSION
  /**
   * An hien danh sach con
   * @param $event 
   * @param indexParent 
   * @param indexList 
   */
  showHideTreeView($event, indexParent, indexList) {
    $event.stopPropagation();
    this.dualList[indexList][indexParent].showChild = !this.dualList[indexList][indexParent].showChild
  }

  /**
   * Thao tac chon tat ca
   * @param indexList 
   */
  selectAllBtn(indexList) {
    this.selectedAll[indexList] = !this.selectedAll[indexList];
    this.dualList[indexList].forEach((item, index) => {
      item.selected = this.selectedAll[indexList];
      item.child.forEach((x, index) => {
        x.selected = this.selectedAll[indexList];
      });
    })

    console.log(this.dualList)
  }

  /**
   * Thao tac chon goc cua cay
   * @param indexGroup 
   * @param indexList 
   * @param manualSet 
   * @param defaultValue 
   */
  selectedParentItem(indexGroup, indexList, manualSet = false, defaultValue = false) {
    //show treeview
    this.dualList[indexList][indexGroup].showChild = true;
    if (manualSet) {
      this.dualList[indexList][indexGroup].selected = defaultValue;
    } else {
      this.dualList[indexList][indexGroup].selected = !this.dualList[indexList][indexGroup].selected;
    }

    this.dualList[indexList][indexGroup].child.forEach((item, index) => {
      item.selected = this.dualList[indexList][indexGroup].selected;
    });
    if (!this.dualList[indexList][indexGroup].selected) this.selectedAll[indexList] = false;
    this.resetStatusItem(indexGroup, indexList);
  }

  /**
   * Cap nhat la trang thai cua dong (da chon hay chua)
   * @param indexGroup 
   * @param indexList 
   */
  resetStatusItem(indexGroup, indexList) {
    let ok = false;
    this.dualList[indexList][indexGroup].child.forEach((item, index) => {
      if (item.selected) ok = true;
    });
    this.dualList[indexList][indexGroup].selected = ok;

    ok = true;
    this.dualList[indexList].forEach((item, index) => {
      if (!item.selected) ok = false;
      item.child.forEach((x, index) => {
        if (!x.selected) ok = false;
      });
    })

    this.selectedAll[indexList] = ok;
  }

  /**
   * Thao tac chon dong con
   * @param indexGroup 
   * @param indexChild 
   * @param indexList 
   */
  selectedChildItem(indexGroup, indexChild, indexList) {
    this.dualList[indexList][indexGroup].child[indexChild].selected = !this.dualList[indexList][indexGroup].child[indexChild].selected;
    if (!this.dualList[indexList][indexGroup].child[indexChild].selected) this.selectedAll[indexList] = false;
    this.resetStatusItem(indexGroup, indexList);
  }

  /**
   * Set base list tất cả các quyền
   */
  setBaseList() {
    this.listPermissionGroup.forEach((item, index) => {
      this.baseList.push(new DualListItem({
        id: item.id,
        name: item.name,
        index: index,
        keyLanguage: item.keyLanguage
      }))
      this.baseList[index].child = [];

      item.privileges.forEach((item1, index1) => {
        this.baseList[index].child.push(new DualListItem({
          id: item1.id,
          name: item1.description,
          index: index1,
          keyLanguage: item1.keyLanguage
        }))
      })
    });
  }
  
  /**
   * Cai dai dual list truoc khi su dung
   * @param listAssigned 
   */
   setupDualList(listAssigned: PermissionGroup[]) {
    this.dualList[0] = this.cloneObject(this.baseList);
    this.dualList[1] = [];
    this.backupDualList();

    listAssigned.forEach((a, i) => {
      const indexGroup = this.dualList[0].findIndex(res => { return res.id === a.id });
      if (indexGroup >= 0) {
        a.privileges.forEach((b, j) => {
          const indexChild = this.dualList[0][indexGroup].child.findIndex(res => { return res.id === b.id });
          if (indexGroup >= 0 && indexChild >= 0) {
            console.log("OK => idGroup: ", a.id, "; idChild: ", b.id);
            this.assign(indexGroup, indexChild, 0, 1);
          }
        })
      }
    });

    this.filter = [false, false];
    this.selectedAll = [false, false];
  }

  /**
   * Kiem tra dieu kien enable nut gan
   * @param indexList 
   * @returns 
   */
  enableBtnAssign(indexList): boolean {
    var disable = true;
    this.dualList[indexList].forEach((item) => {
      if (item.selected) disable = false;
    })
    return disable
  }

  /**
   * Gan tung dong
   * @param indexGroup 
   * @param indexChild 
   * @param from 
   * @param to 
   */
  assign(indexGroup, indexChild, from, to) {
    const parent = this.dualList[from][indexGroup];
    const child = parent.child[indexChild];

    const iParent = this.dualList[to].findIndex(res => {
      return res.id === parent.id;
    })

    if (iParent >= 0) {
      child.selected = false;
      this.dualList[to][iParent].child.push(child);
    } else {
      this.dualList[to].push(new DualListItem({
        id: parent.id,
        name: parent.name,
        index: parent.index,
        showChild: parent.showChild,
        keyLanguage: parent.keyLanguage
      }));

      child.selected = false;
      this.dualList[to][this.dualList[to].length - 1].child.push(child);
    }

    this.logAssigned(parent.id, child.id, from, to);

    this.dualList[from][indexGroup].child.splice(indexChild, 1);
    if (this.dualList[from][indexGroup].child.length <= 0) {
      this.dualList[from].splice(indexGroup, 1);
    }

    this.sortList();
  }

  /**
   * Gan nhieu hon 1 dong
   * @param from 
   * @param to 
   */
  assignMoreSelected(from, to) {
    this.selectedAll[from] = this.selectedAll[to] = false;

    for (var i = this.dualList[from].length - 1; i >= 0; i--) {
      let item = this.dualList[from][i];
      if (item.selected) {
        item.selected = false;
        for (var j = item.child.length - 1; j >= 0; j--) {
          let x = item.child[j];
          if (x.selected) {
            x.selected = false;
            this.assign(i, j, from, to);
          }
        }
      }
    }

    this.sortList();
  }

  /**
   * Gan 1 group
   * @param indexGroup 
   * @param from 
   * @param to 
   */
  assignGroup(indexGroup, from, to) {
    // this.selectedParentItem(indexGroup, from, true, true);
    // this.assignMoreSelected(from, to);
    let item = this.dualList[from][indexGroup].child;
    for (var i = item.length - 1; i >= 0; i--) {
      item[i].selected = false;
      this.assign(indexGroup, i, from, to);
    }
  }

  /**
   * Tim kiem trong dual list
   * @param $event 
   * @param indexList 
   */
  filterSearch($event, indexList) {
    let value = StringUtils.removeAccents($event.target.value);
    let filter = [];
    this.filter[indexList] = $event.target.value !== "";
    let self = this;

    if (this.filter[indexList]) {
      this.oriList[indexList].forEach(function (x) {
        let parentOK = false;
        let childOK = false;
        let tmp = self.cloneObject(x);

        let tmp_name = StringUtils.removeAccents(tmp.name);
        if (tmp_name.toUpperCase().match(new RegExp(value.toUpperCase(), "g"))) {
          parentOK = true;
        };

        for (var i = tmp.child.length - 1; i >= 0; i--) {
          let tmp_child_name = StringUtils.removeAccents(tmp.child[i].name);
          if (!(tmp_child_name.toUpperCase().match(new RegExp(value.toUpperCase(), "g")))) {
            tmp.child.splice(i, 1);
          } else {
            childOK = true;
          }
        }
        if (parentOK && !childOK) filter.push(self.cloneObject(x));
        if (parentOK && childOK) filter.push(tmp);
        if (!parentOK && childOK) filter.push(tmp);
      });
      this.dualList[indexList] = this.cloneObject(filter);
    } else {
      this.dualList[indexList] = this.cloneObject(this.oriList[indexList]);
    }

    console.log("-------------------------------------");
    console.log("searched: ", filter);
    console.log("filtered ori: ", this.oriList);
    console.log("filter array: ", this.dualList);
    console.log("-------------------------------------");
  }

  /**
   * Sao luu list mac dinh
   */
  backupDualList() {
    this.oriList = this.cloneObject(this.dualList);
  }

  /**
   * Sap xem lai list
   */
  sortList() {
    this.dualList.forEach((item, index) => {
      item.sort((a, b) => {
        return a.index - b.index;
      });

      item.forEach((x, y) => {
        x.child.sort((a, b) => {
          return a.index - b.index;
        });
      })
    })

    this.oriList.forEach((item, index) => {
      item.sort((a, b) => {
        return a.index - b.index;
      });

      item.forEach((x, y) => {
        x.child.sort((a, b) => {
          return a.index - b.index;
        });
      })
    })
  }

  /**
   * Log lai nhung dong da assign
   * @param idGroup 
   * @param idChild 
   * @param from 
   * @param to 
   */
  logAssigned(idGroup, idChild, from, to) {
    console.log("log Assign => idGroup: ", idGroup, "; idChild: ", idChild);
    const indexParent = this.oriList[from].findIndex(res => { return res.id === idGroup; });
    const indexChild = this.oriList[from][indexParent].child.findIndex(res => { return res.id === idChild; });
    const parent = this.oriList[from][indexParent];
    const child = parent.child[indexChild];
    const iParent = this.oriList[to].findIndex(res => { return res.id === idGroup; })

    if (iParent >= 0) {
      child.selected = false;
      this.oriList[to][iParent].child.push(child);
    } else {
      this.oriList[to].push(new DualListItem({
        id: parent.id,
        name: parent.name,
        index: parent.index
      }));

      child.selected = false;
      this.oriList[to][this.oriList[to].length - 1].child.push(child);
    }

    this.oriList[from][indexParent].child.splice(indexChild, 1);
    if (this.oriList[from][indexParent].child.length <= 0) {
      this.oriList[from].splice(indexParent, 1);
    }

    this.sortList();
    console.log("ORI AFTER ASSIGNED: ", this.oriList);
  }

  /**
   * Clone lai 1 object (tao vung du lieu moi k anh huong bien hien tai)
   * @param object 
   * @returns 
   */
  cloneObject(object: any) {
    return JSON.parse(JSON.stringify(object));
  }

  /**
   * Dem so dong da gan
   */
  get countAssigned() {
    var count = 0;
    this.dualList[1].forEach((a, i) => {
      a.child.forEach((b, j) => {
        count++;
      });
    });
    return count;
  }

  /**
   * Dem so dong chua gan
   */
  get countNotAssigned() {
    var count = 0;
    this.dualList[0].forEach((a, i) => {
      a.child.forEach((b, j) => {
        count++;
      });
    });
    return count;
  }

  /**
   * Get danh sách tất cả các quyền
   */
  getAllPrivileges() {
    var data: ListAllPermisionReponse;
    this.userManagerService.getAllPrivileges().subscribe(res => {

      data = new ListAllPermisionReponse(res);
      this.listPermissionGroup = data.object;
      this.setBaseList();
      console.log(this.listPermissionGroup);

      this.setupDualList(this.listAssigned);

      if (this.listUserGroup && this.listUserGroup.length > 0) {
        this.selectUserGroup(this.listUserGroup[0]);
      }
    }, err => {
      console.log(err);
      this.myAlert.showErrorHandled(err);
    }, () => {
    });
  }

  /**
   * get
   * @param id 
   */
  getPrivilegesUserGroup(id) {
    var data: UserGroup;
    this.userManagerService.getPrivilegesUserGroupV2(id).subscribe((res: any) => {
      data = new UserGroup(res.object);
      this.setupDualList(data.privileges);
      console.log(data);
    }, err => {
      this.loadingForm.next(false);
      console.log(err);
      this.myAlert.showErrorHandled(err);
    }, () => {
    });
  }
}
