import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { ControlAccount } from 'src/app/utils/common/control-account';
import { localize } from 'src/app/utils/localize/localize';
import { TemplateVar } from 'src/app/utils/models/aas-models/template/template-var';
import { EmployeeService } from 'src/app/utils/services/aas-network/employee/employee.service';
import { PartService } from 'src/app/utils/services/aas-network/part/part.service';
import { TemplateService } from 'src/app/utils/services/aas-network/template.service';
import { AuthService } from 'src/app/utils/services/auth.service';

@Component({
  selector: 'app-contract-template-add',
  templateUrl: './contract-template-add.component.html',
  styleUrls: ['./contract-template-add.component.scss']
})
export class ContractTemplateAddComponent implements OnInit {
  @ViewChild('templateFile') templateFile: ElementRef;

  localize = localize;
  localizeC = localize;
  templateForm: FormGroup;
  isSubmit = false;
  inputFileLabel = "ContractTemplateAdd_upload";
  attachFile = null;
  validateMsgForm = {
    templateName : {
      required: "ContractTemplateAdd_alert_4"
    },
    file: {
      required: "ContractTemplateAdd_alert_5",
      maxsize: "ContractTemplateAdd_alert_6"
    },
    templateType: {
      required: "ContractTemplateAdd_alert_7"
    },
    status: {
      required: "ContractTemplateAdd_alert_8"
    }
  }

  statusData = [
    { label: "status_activated", value: 'ACTIVE' },
    { label: "status_not_activated", value: 'DEACTIVE' }
  ];

  templateVars: TemplateVar[] = [];
  mapVars: any[] = [];

  showFile = false;
  pdfFile = null;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private templateService: TemplateService,
    private myAlert: AlertControl,
    private controlAccount: ControlAccount,
    private auth: AuthService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.viewDidLoad();
  }

  viewDidLoad() {
    this.makeFormTemplate();
    this.getListMapModel();
    this.controlAccount.getAccountInfo();
  }

  makeFormTemplate() {
    this.templateForm = this.fb.group({
      templateName: ["", [Validators.required]],
      file: [null, [Validators.required]],
      templateType: ["", [Validators.required]],
      status: ['ACTIVE', [Validators.required]]
    });
  }

  cancel() {
    this.templateForm.reset();
    this.templateForm.patchValue({
      templateName: "",
      file: null,
      templateType: "",
      status: 'ACTIVE'
    });

    this.attachFile = null;
    this.inputFileLabel = "ContractTemplateAdd_upload";
    this.templateVars = [];
  }

  save() {
    this.isSubmit = true;
    if (this.templateForm.valid && this.attachFile) {
      let formData = new FormData();
      formData.append("templateName", this.templateForm.value.templateName);
      formData.append("templateType", this.templateForm.value.templateType);
      formData.append("status", this.templateForm.value.status);
      formData.append("attachFile", this.attachFile);
      formData.append("fields", JSON.stringify(this.templateVars));
      formData.append("fullName", this.controlAccount.userInfo.ten);

      this.myAlert.showAlert(this.translate.instant('ContractTemplateAdd_alert_1'), ConstantAlertType.BLANK, true, "", this.translate.instant('btn_confirm'), () => {
        this.postCreateTemplate(formData);
      }, );
    }
  }

  viewFile() {
    if (this.attachFile) {
      var data = new FormData();
      data.append("attachFile", this.attachFile);
      this.postConvertToPDF(data);
    } else {
      this.myAlert.showAlertOnlyNoti(this.translate.instant('ContractTemplateAdd_file_not_found'), ConstantAlertType.ERROR);
    }
  }

  onClosePreview(data) {
    this.showFile = false;
    this.pdfFile = null;
  }

  onAttachFileChanged(event) {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0]
      this.attachFile = file
      this.inputFileLabel = file.name

      this.templateFile.nativeElement.value = "";
      this.templateForm.controls.file.setErrors(null);

      this.templateVars = [];

      // Getting all variables
      this.postFileTemplate(this.attachFile);
    } else {
      this.inputFileLabel = "ContractTemplateAdd_upload";
      this.templateForm.controls.file.setErrors({ required: true });
    }
  }

  postFileTemplate(file) {
    const formData = new FormData()
    formData.append('attachFile', file)
    this.templateService.postUploadFileTempate(formData).subscribe((res: any) => {
      if (res && res.object && res.object instanceof Array) {
        res.object.forEach((item) => {
          let tv = new TemplateVar(item);
          if (item.listVar && item.listVar instanceof Array && item.listVar.length > 0) {
            tv.listVar.forEach((x) => {
              x.varMaps = this.mapVars.concat([{ label: x.nameMap, value: x.nameMap }])
            })
          }
          tv.varMaps = this.mapVars.concat([{ label: tv.nameMap, value: tv.nameMap }]);

          this.templateVars.push(tv);
        });
      }
      console.log(this.templateVars);
    }, err => {
      this.attachFile = null;
      this.inputFileLabel = "ContractTemplateAdd_upload";
      this.templateForm.controls.file.setErrors({ required: true });
      this.myAlert.showErrorHandled(err);
    })
  }

  getListMapModel() {
    this.templateService.getListMapModel().subscribe((res: any) => {
      console.log(res);
      if (res.object) {
        Object.keys(res.object).forEach((item) => {
          this.mapVars.push({
            label: res.object[item],
            value: item
          })
        })
      }
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }

  postConvertToPDF(data: FormData) {
    this.templateService.postConvertToPdf(data).subscribe(fileData => {
      const blob: any = new Blob([fileData], { type: 'application/pdf' });
      this.pdfFile = blob;
      this.showFile = true;
    }, err => {
      console.log(err);
      this.myAlert.showAlertOnlyNoti(this.translate.instant('ContractTemplateAdd_alert_2'), ConstantAlertType.ERROR);
    });
  }

  async postCreateTemplate(data: FormData) {
    this.templateService.postCreateTemplatev2(data).subscribe((res: any)=> {
      let re = this.putUpdateFieldTemplate(res?.object?.templateFields, this.templateVars);
      this.router.navigate([ConstantUrl.contract_template_detail, res?.object?.id]);
      this.myAlert.showAlertOnlyNoti(this.translate.instant('ContractTemplateAdd_alert_3'), ConstantAlertType.SUCCESS);
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }

  async putUpdateFieldTemplate(id, data) {
    let re = await this.templateService.putUpdateFieldTemplate(id, data).toPromise();
    return re;
  }
}
