export class ValidateMessage {
  public message = {
    required: 'Vui lòng nhập thông tin!',
    maSoThue: {
      pattern: 'Mã số thuế phải có định dạng 1234567890 hoặc 1234567890-123'
    },
    cmnd: {
      pattern: 'Số CCCD/CMND/Hộ chiếu sai định dạng!'
    },
    sdt: {
      pattern: 'Số điện thoại phải từ 5-15 kí tự số!'
    },
    email: {
      email: 'Sai định dạng email'
    },
    date: {
      pattern: 'Sai định dạng ngày tháng năm!'
    }
  };

  public createUserGroup = {
    required: 'Vui lòng nhập thông tin!',
    name: {
      required: 'Tên nhóm không được để trống!',
      pattern: 'Tên nhóm không bao gồm ký tự đặc biệt ngoại trừ các ký tự: ()/\\._&',
      unique: 'Tên nhóm đã tồn tại!'
    },
    roleCode: {
      required: 'Mã nhóm không được để trống!',
      pattern: 'Mã nhóm không bao gồm ký tự đặc biệt ngoại trừ các ký tự: ()/\\._&',
      unique: 'Mã nhóm đã tồn tại!'
    },
    disable: {
      required: 'Trạng thái không được để trống!'
    }
  };
}

export const AasValidateMessage = {
  LOGIN: {
    username: {
      required: 'Vui lòng nhập thông tin!',
      pattern: 'Tên tài khoản phải viết liền không dấu, có ít nhất 6-50 ký tự, không chứa ký tự khoảng trống!'
    },
    password: {
      required: 'Vui lòng nhập thông tin!',
      notTrue: 'Tài khoản hoặc mật khẩu không đúng!'
    }
  },
  CHANGE_PASSWORD: {
    password: {
      pattern: 'ChangePassword_note_1',
    }
  },
  PROFILE_MANAGER_BUSINESS: {
    tenDoanhNghiep: {
      required: 'ProfileConsumer_msg_1',
      pattern: 'ProfileBusiness_msg_1'
    },
    tenVietTat: {
      required: 'ProfileConsumer_msg_1',
      pattern: 'ProfileBusiness_msg_2'
    },
    maSoThue: {
      required: 'ProfileConsumer_msg_1',
      pattern: 'ProfileBusiness_msg_3'
    },
    hoVaTen: {
      required: 'ProfileConsumer_msg_1',
      pattern: 'ProfileBusiness_msg_4'
    },
    email: {
      required: 'ProfileConsumer_msg_1',
      email: 'ProfileBusiness_msg_5',
      unique: 'ProfileBusiness_msg_unique_email'
    },
    sdt: {
      required: 'ProfileConsumer_msg_1',
      pattern: 'ProfileBusiness_msg_6',
      unique: 'ProfileBusiness_msg_unique_sdt'
    },
    ngaySinh: {
      pattern: 'ProfileConsumer_msg_4'
    },
    gender: {},
    tinhId: {},
    huyenId: {},
    xaId: {},
    soNha: {},
    duong: {},
    username: {
      required: 'ProfileConsumer_msg_1',
      unique: 'ProfileBusiness_msg_7'
    }
  },
  PROFILE_MANAGER: {
    username: {
      required: 'ProfileConsumer_msg_1',
      unique: 'ProfileConsumer_msg_2'
    },
    fullname: {
      required: 'ProfileConsumer_msg_1',
      pattern: 'ProfileConsumer_msg_3'
    },
    email: {
      required: 'ProfileConsumer_msg_1',
    },
    phone: {
      required: 'ProfileConsumer_msg_1',
    },
    gender: {
      required: 'ProfileConsumer_msg_1',
    },
    dateOfBirth: {
      required: 'ProfileConsumer_msg_1',
      pattern: 'ProfileConsumer_msg_4',
    },
    noiDKHKTT: {
      required: 'ProfileConsumer_msg_1',
    },
    cmnd: {
      required: 'ProfileConsumer_msg_1',
      pattern: 'ProfileConsumer_msg_6',
    },
    hoChieu: {
      required: 'ProfileConsumer_msg_1',
      pattern: 'ProfileConsumer_msg_5',
    },
    ngayCap: {
      required: 'ProfileConsumer_msg_1',
      pattern: 'ProfileConsumer_msg_4',
    },
    noiCap: {
      required: 'ProfileConsumer_msg_1',
    },
    tenDoanhNghiep: {
      required: 'ProfileConsumer_msg_1',
    },
    tenVietTat: {
      required: 'ProfileConsumer_msg_1',
    },
    maSoThue: {
      pattern: 'ProfileConsumer_msg_7',
    },
    giayPhepKD: {
      required: 'ProfileConsumer_msg_1',
    },
    soGiayTo: {
      pattern: 'ProfileConsumer_msg_8',
    }
  },
  SIGN_ON: {
    email: {
      required: 'Vui lòng nhập email!',
      email: 'Email không đúng định dạng!',
      pattern: 'Email không đúng định dạng!',
      unique: 'Email tài khoản đã tồn tại!'
    },
    sdt: {
      required: 'Vui lòng nhập số điện thoại!',
      pattern: 'Số điện thoại không đúng định dạng!',
      unique: 'Số điện thoại đã tồn tại!'
    },
    username: {
      required: 'Vui lòng nhập tên tài khoản!',
      pattern: 'Tên tài khoản phải viết liền không dấu, có ít nhất 6-50 ký tự, không chứa ký tự khoảng trống!',
      unique: 'Tên đăng nhập đã tồn tại!'
    },
    password: {
      required: 'Vui lòng nhập mật khẩu!',
      pattern: 'Mật khẩu có độ dài lớn hơn hoặc bằng 8 ký tự bao gồm ký tự hoa, thường, số và ký tự đặc biệt!'
    },
    tenDoanhNghiep: {
      required: 'Vui lòng nhập tên doanh nghiệp!',
      pattern: 'Tên doanh nghiệp không đúng định dạng!'
    },
    tenVietTat: {
      required: 'Vui lòng nhập tên viết tắt!',
      pattern: 'Tên viết tắt không đúng định dạng!'
    },
    maSoThue: {
      required: 'Vui lòng nhập mã số thuế!',
      pattern: 'Mã số thuế không đúng định dạng!'
    }
  },
  FORGOT_PASSWORD: {
    email: {
      required: 'ForgotPassword_err_1',
      email: 'ForgotPassword_err_2',
      pattern: 'ForgotPassword_err_2',
      notFound: 'ForgotPassword_err_3'
    },
    sdt: {
      required: 'Vui lòng nhập số điện thoại!',
      pattern: 'Số điện thoại không đúng đinh dạng!',
      notFound: 'Tài khoản không tồn tại!'
    }
  },
  SING_ON_BUSINESS: {
    tenDoanhNghiep: {
      required: 'Vui lòng nhập tên doanh nghiệp!',
      pattern: 'Tên doanh nghiệp không đúng định dạng!'
    },
    tenVietTat: {
      required: 'Vui lòng nhập tên viết tắt!',
      pattern: 'Tên viết tắt không đúng định dạng!'
    },
    maSoThue: {
      required: 'Vui lòng nhập mã số thuế!',
      pattern: 'Mã số thuế không đúng định dạng!'
    },
    hoVaTen: {
      required: 'Vui lòng nhập họ và tên!',
      pattern: 'Họ và tên không đúng định dạng!'
    },
    email: {
      required: 'Vui lòng nhập email!',
      email: 'Email không đúng định dạng!',
      pattern: 'Email không đúng định dạng!',
      unique: 'Email tài khoản đã tồn tại!'
    },
    sdt: {
      pattern: 'Số điện thoại không đúng định dạng!',
      unique: 'Số điện thoại đã tồn tại!'
    },
    ngaySinh: {
      pattern: 'Sai định dạng ngày tháng năm!',
    },
    gioiTinh: {},
    diaChi: {},
    tinhId: {},
    huyenId: {},
    xaId: {},
    soNha: {},
    duong: {},
    username: {
      required: 'Vui lòng nhập tên tài khoản!',
      pattern: 'Tên tài khoản phải viết liền không dấu, có ít nhất 6-50 ký tự, không chứa ký tự khoảng trống!',
      unique: 'Tên đăng nhập đã tồn tại!'
    },
    password: {
      required: 'Vui lòng nhập mật khẩu!',
      pattern: 'Mật khẩu có độ dài lớn hơn hoặc bằng 8 ký tự bao gồm ký tự hoa, thường, số và ký tự đặc biệt!'
    },
    domain: {
      required: 'Vui lòng nhập tên domain!',
    }
  },
  REQUEST_OTP: {
    otp: {
      required: 'OtpForgotPassword_err_1',
      notFound: 'OtpForgotPassword_err_2'
    }
  },
  RENEW_PASSWORD: {
    password: {
      required: 'RenewPassword_err_1',
      pattern: 'RenewPassword_err_2'
    },
    passwordConfirm: {
      required: 'RenewPassword_err_3',
      notSame: 'RenewPassword_err_4'
    }
  },
  VERIFY_BUSINESS: {
    file: {
      required: 'Vui lòng nhập thông tin!',
      type: 'File đính kèm không đúng định dạng!'
    }
  },
  SIGNATURE_CONFIG: {
    signerName: {},
    textColor: {},
    visibleType: {},
    base64Image: {},
    fontSize: {
      min: 'SIGNATURE_CONFIG_fontSize_min',
      max: 'SIGNATURE_CONFIG_fontSize_max',
      pattern: 'SIGNATURE_CONFIG_fontSize_pattern'
    },
    signBy: {},
    signDate: {},
    imageFile: {
      type: 'SIGNATURE_CONFIG_imageFile_type',
      size: 'SIGNATURE_CONFIG_imageFile_size'
    }
  },
  E_SIGNATURE_CONFIG: {
    imageFile: {
      type: 'ServiceContractDetail_file_requried',
      size: 'ServiceContractDetail_file_maxsize_invalid'
    }
  },
  CONTRACT_MANAGER_FILTER: {
    toDate: {
      pattern: 'Sai định dạng ngày tháng năm!'
    },
    fromDate: {
      pattern: 'Sai định dạng ngày tháng năm!',
      minDate: 'Từ ngày không được lớn hơn đến ngày và không được là ngày tương lai!'
    }
  },
  FORM_CREATE_CONTRACT: {
    tenHopDong: {
      required: 'Vui lòng nhập tên hợp đồng!'
    },
    file: {
      required: 'Vui lòng tải lên file hợp đồng!',
      type: 'File không đúng định dạng!',
      size: 'Kích thước file quá lớn!',
    },
    ngayHieuLuc: {
      required: 'Vui lòng nhập ngày hiệu lực!',
      pattern: 'Sai định dạng ngày tháng năm!',
    },
    ngayHetHieuLuc: {
      required: 'Vui lòng nhập ngày hết hiệu lực!',
      pattern: 'Sai định dạng ngày tháng năm!',
    },
    tuGiaHan: {
      required: 'Vui lòng chọn hình thức gia hạn!'
    },
    giaTriHd: {
      pattern: 'Giá trị hợp đồng phải có định dạng số!',
    },
    nguoiNhan: {
      required: 'Vui lòng chọn người nhận!'
    },
    loaiKh: {
      required: 'Vui lòng chọn loại khách hàng!'
    },
    hinhThucKy: {
      required: 'Vui lòng chọn hình thức ký!'
    },
    thoiHanKy: {
      required: 'Vui lòng nhập thời hạn ký!',
      pattern: 'Sai định dạng ngày tháng năm!',
    },
    verificationType: {
      required: 'Vui lòng chọn hình thức xác minh khách hàng!'
    },
    discuss: {
      required: "Vui lòng chọn đàm phán hợp đồng!"
    }
  },
  CONFIRM_OTP_E_SIGN: {
    otp: {
      required: 'ESign_err_1',
      notTrue: 'ESign_err_2'
    }
  },
  CANCEL_CONTRACT: {
    reason: {
      required: 'Vui lòng nhập lý do huỷ!',
    }
  },
  LOGIN_SIGN_SERVER: {
    email: {
        required: 'SignServerManager_ts_1',
        pattern: 'SignServerManager_ts_2'
    },
    password: {
        required: 'SignServerManager_ts_3'
    }
  },
  CONTRACT_FORM_SELECT_PACKAGE: {
    packageId: {
      required: 'Vui lòng chọn gói dịch vụ đăng ký mới!'
    }
  }
};

//Tạm ẩn lỗi (Không dùng)
export const ServiceMsg = {
  LOGIN: {
    username: {
      required: 'Vui lòng nhập thông tin!',
      pattern: 'Tên tài khoản phải viết liền không dấu, có ít nhất 6-50 ký tự, không chứa ký tự khoảng trống!'
    },
    password: {
      required: 'Vui lòng nhập thông tin!',
      notTrue: 'Tài khoản hoặc mật khẩu không đúng!'
    }
  },
  PROFILE_MANAGER_BUSINESS: {
    tenDoanhNghiep: {
      required: 'Vui lòng nhập thông tin!',
    },
    tenVietTat: {
      required: 'Vui lòng nhập thông tin!'
    },
    maSoThue: {
      required: 'Vui lòng nhập thông tin!',
      pattern: 'Mã số thuế không đúng định dạng!'
    },
    hoVaTen: {
      required: 'Vui lòng nhập thông tin!'
    },
    email: {
      required: 'Vui lòng nhập thông tin!',
      email: 'Email không đúng định dạng!'
    },
    sdt: {
      pattern: 'Số điện thoại không đúng định dạng!',
    },
    ngaySinh: {
      pattern: 'Sai định dạng ngày tháng năm!'
    },
    gender: {},
    tinhId: {},
    huyenId: {},
    xaId: {},
    soNha: {},
    duong: {},
    username: {
      required: 'Vui lòng nhập thông tin!',
      unique: 'Tên đăng nhập đã tồn tại!'
    }
  },
  PROFILE_MANAGER: {
    username: {
      required: 'Vui lòng nhập thông tin!',
      unique: 'Tên đăng nhập đã tồn tại!'
    },
    fullname: {
      required: 'Vui lòng nhập thông tin!',
    },
    email: {
      required: 'Vui lòng nhập thông tin!',
    },
    phone: {
      required: 'Vui lòng nhập thông tin!',
    },
    gender: {
      required: 'Vui lòng nhập thông tin!',
    },
    dateOfBirth: {
      required: 'Vui lòng nhập thông tin!',
      pattern: 'Sai định dạng ngày tháng năm!',
    },
    noiDKHKTT: {
      required: 'Vui lòng nhập thông tin!',
    },
    cmnd: {
      required: 'Vui lòng nhập thông tin!',
      pattern: 'Sai định dạng số cmnd!',
    },
    hoChieu: {
      required: 'Vui lòng nhập thông tin!',
      pattern: 'Sai định dạng số hộ chiếu!',
    },
    ngayCap: {
      required: 'Vui lòng nhập thông tin!',
      pattern: 'Sai định dạng ngày tháng năm!',
    },
    noiCap: {
      required: 'Vui lòng nhập thông tin!',
    },
    tenDoanhNghiep: {
      required: 'Vui lòng nhập thông tin!',
    },
    tenVietTat: {
      required: 'Vui lòng nhập thông tin!',
    },
    maSoThue: {
      pattern: 'Sai định dạng mã số thuế!',
    },
    giayPhepKD: {
      required: 'Vui lòng nhập thông tin!',
    },
    soGiayTo: {
      pattern: 'Số CCCD/CMND/Hộ chiếu sai định dạng!',
    }
  },
  SIGN_ON: {
    email: {
      required: 'Vui lòng nhập email!',
      email: 'Email không đúng định dạng!',
      pattern: 'Email không đúng định dạng!',
      unique: 'Email tài khoản đã tồn tại!'
    },
    sdt: {
      required: 'Vui lòng nhập số điện thoại!',
      pattern: 'Số điện thoại không đúng định dạng!',
      unique: 'Số điện thoại đã tồn tại!'
    },
    username: {
      required: 'Vui lòng nhập tên tài khoản!',
      pattern: 'Tên tài khoản phải viết liền không dấu, có ít nhất 6-50 ký tự, không chứa ký tự khoảng trống!',
      unique: 'Tên đăng nhập đã tồn tại!'
    },
    password: {
      required: 'Vui lòng nhập mật khẩu!',
      pattern: 'Mật khẩu có độ dài lớn hơn hoặc bằng 8 ký tự bao gồm ký tự hoa, thường, số và ký tự đặc biệt!'
    },
    tenDoanhNghiep: {
      required: 'Vui lòng nhập tên doanh nghiệp!',
    },
    tenVietTat: {
      required: 'Vui lòng nhập tên viết tắt!',
    },
    maSoThue: {
      required: 'Vui lòng nhập mã số thuế!',
      pattern: 'Mã số thuế không đúng định dạng!'
    }
  },
  FORGOT_PASSWORD: {
    email: {
      required: 'Vui lòng nhập email!',
      email: 'Email không đúng đinh dạng!',
      pattern: 'Email không đúng định dạng!',
      notFound: 'Tài khoản không tồn tại!'
    },
    sdt: {
      required: 'Vui lòng nhập số điện thoại!',
      pattern: 'Số điện thoại không đúng đinh dạng!',
      notFound: 'Tài khoản không tồn tại!'
    }
  },
  SING_ON_BUSINESS: {
    tenDoanhNghiep: {
      required: 'Vui lòng nhập tên doanh nghiệp!',
    },
    tenVietTat: {
      required: 'Vui lòng nhập tên viết tắt!',
    },
    maSoThue: {
      required: 'Vui lòng nhập mã số thuế!',
      pattern: 'Mã số thuế không đúng định dạng!'
    },
    hoVaTen: {
      required: 'Vui lòng nhập họ và tên!',
      pattern: 'Họ và tên không đúng định dạng!'
    },
    email: {
      required: 'Vui lòng nhập email!',
      email: 'Email không đúng định dạng!',
      pattern: 'Email không đúng định dạng!',
      unique: 'Email tài khoản đã tồn tại!'
    },
    sdt: {
      pattern: 'Số điện thoại không đúng định dạng!',
      unique: 'Số điện thoại đã tồn tại!'
    },
    ngaySinh: {
      pattern: 'Sai định dạng ngày tháng năm!',
    },
    gioiTinh: {},
    diaChi: {},
    tinhId: {},
    huyenId: {},
    xaId: {},
    soNha: {},
    duong: {},
    username: {
      required: 'Vui lòng nhập tên tài khoản!',
      pattern: 'Tên tài khoản phải viết liền không dấu, có ít nhất 6-50 ký tự, không chứa ký tự khoảng trống!',
      unique: 'Tên đăng nhập đã tồn tại!'
    },
    password: {
      required: 'Vui lòng nhập mật khẩu!',
      pattern: 'Mật khẩu có độ dài lớn hơn hoặc bằng 8 ký tự bao gồm ký tự hoa, thường, số và ký tự đặc biệt!'
    }
  },
  REQUEST_OTP: {
    otp: {
      required: 'Vui lòng nhập mã OTP!',
      notFound: 'Mã OTP không chính xác!'
    }
  },
  RENEW_PASSWORD: {
    password: {
      required: 'Vui lòng nhập mật khẩu mới!',
      pattern: 'Mật khẩu có độ dài lớn hơn hoặc bằng 8 ký tự bao gồm ký tự hoa, thường, số và ký tự đặc biệt!'
    },
    passwordConfirm: {
      required: 'Vui lòng nhập xác nhận mật khẩu!',
      notSame: 'Mật khẩu không trùng khớp!'
    }
  },
  VERIFY_BUSINESS: {
    file: {
      required: 'Vui lòng nhập thông tin!',
      type: 'File đính kèm không đúng định dạng!'
    }
  },
  SIGNATURE_CONFIG: {
    signerName: {},
    textColor: {},
    visibleType: {},
    base64Image: {},
    fontSize: {
      min: 'Giá trị nhỏ nhất là 8 và không vượt quá 20!',
      max: 'Giá trị nhỏ nhất là 8 và không vượt quá 20!',
      pattern: 'Vui lòng nhập đúng định dạng số!'
    },
    signBy: {},
    signDate: {},
    imageFile: {
      type: 'File đính kèm không đúng định dạng!',
      size: 'Dung lượng file không được phép vượt quá 2Mb!'
    }
  },
  E_SIGNATURE_CONFIG: {
    imageFile: {
      type: 'File đính kèm không đúng định dạng!',
      size: 'Dung lượng file không được phép vượt quá 2Mb!'
    }
  },
  CONTRACT_MANAGER_FILTER: {
    toDate: {
      pattern: 'Sai định dạng ngày tháng năm!'
    },
    fromDate: {
      pattern: 'Sai định dạng ngày tháng năm!',
      minDate: 'Từ ngày không được lớn hơn đến ngày và không được là ngày tương lai!'
    }
  },
  FORM_CREATE_CONTRACT: {
    tenHopDong: {
      required: 'Vui lòng nhập tên hợp đồng!'
    },
    file: {
      required: 'Vui lòng tải lên file hợp đồng!',
      type: 'File không đúng định dạng!',
      size: 'Kích thước file quá lớn!',
    },
    ngayHieuLuc: {
      required: 'Vui lòng nhập ngày hiệu lực!',
      pattern: 'Sai định dạng ngày tháng năm!',
    },
    ngayHetHieuLuc: {
      required: 'Vui lòng nhập ngày hết hiệu lực!',
      pattern: 'Sai định dạng ngày tháng năm!',
    },
    tuGiaHan: {
      required: 'Vui lòng chọn hình thức gia hạn!'
    },
    giaTriHd: {
      pattern: 'Giá trị hợp đồng phải có định dạng số!',
    },
    nguoiNhan: {
      required: 'Vui lòng chọn người nhận!'
    },
    loaiKh: {
      required: 'Vui lòng chọn loại khách hàng!'
    },
    hinhThucKy: {
      required: 'Vui lòng chọn hình thức ký!'
    },
    thoiHanKy: {
      required: 'Vui lòng nhập thời hạn ký!',
      pattern: 'Sai định dạng ngày tháng năm!',
    }
  },
  CONFIRM_OTP_E_SIGN: {
    otp: {
      required: 'Vui lòng nhập mã OTP!',
      notTrue: 'Mã OTP không chính xác!'
    }
  },
  CANCEL_CONTRACT: {
    reason: {
      required: 'Vui lòng nhập lý do huỷ!',
    }
  },
  PACKAGE_MANAGER:{
    name: {
      required: 'Vui lòng nhập tên gói cước!',
    },
    price: {
      required: 'Vui lòng nhập giá tiền!',
    },
    status: {
      required: 'Vui lòng chọn trạng thái!',
    },
    description: {
      required: 'Vui lòng nhập mô tả!',
    },
    userType: {
      required: 'Vui lòng chọn loại khách hàng áp dụng!',
    },
    contractNumber:{
      required: 'Vui lòng chọn số lượng hợp đồng!',
      pattern: 'Sai định dạng số lượng hợp đồng'
    },
    duration:{
      required: 'Vui lòng chọn thời hạn hợp đồng!',
      pattern: 'Sai định dạng số thời hạn hợp đồng'
    },
    orderNumber:{
      pattern: 'Sai định dạng số hiển thị'
    },
    userAmount:{
      pattern: 'Sai định dạng số người dùng hiển thị'
    },
    capacity:{
      pattern: 'Sai định dạng số dung lượng lưu trữ'
    }
  },
  EINVOICES_MANAGER_FILTER: {
    tuNgayPhatHanh: {
      pattern: 'Sai định dạng ngày tháng năm!'
    },
    denNgayPhatHanh: {
      pattern: 'Sai định dạng ngày tháng năm!',
      minDate: 'Từ ngày không được lớn hơn đến ngày và không được là ngày tương lai!'
    }
  },
  ENVOICES_CREATE: {
    ENVOICES_INFO :{
      mauSo: {
        required: 'Vui lòng chọn mẫu số!',
      },
      kyHieu: {
        required: 'Vui lòng nhập ký hiệu!',
      },
    },
  
    CUSTOMER_INFO :{
      tenKhachHang: {
        required: 'Vui lòng nhập tên khách hàng!',
      },
      tenDonVi: {
        required: 'Vui lòng nhập tên đơn vị!',
      },
      maKhachHang: {
        required: 'Vui lòng nhập mã khách hàng!',
      },
    },
  },
  EINVOICES_CUSTOMER_CREATE: {
    companyName: {
      required: 'Vui lòng nhập tên công ty!',
    },
    taxCode: {
      required: 'Vui lòng nhập mã số thuế!',
    },
    accountInvoice: {
      required: 'Vui lòng nhập tài khoản đăng nhập!',
    },
    passwordInvoice: {
      required: 'Vui lòng nhập mật khẩu!',
    },
    url: {
      required: 'Vui lòng nhập URL service!',
    },
    serviceAccount: {
      required: 'Vui lòng nhập tài khoản service!',
    },
    servicePassword: {
      required: 'Vui lòng nhập mật khẩu service!',
    },
    pattern: {
      required: 'Vui lòng nhập mẫu hóa đơn!',
    },
    serial: {
      required: 'Vui lòng nhập series hóa đơn!',
    },
    

  }
};