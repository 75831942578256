import { concat, Observable, of, Subject } from "rxjs";
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap, tap } from "rxjs/operators";
import { EmployeeService } from "src/app/utils/services/aas-network/employee/employee.service";
import { PartService } from "src/app/utils/services/aas-network/part/part.service";
import { LoadingService } from "src/app/utils/services/loading.service";

export class PartnerDiscuss {
    partyId = "";
    partyName = "";
    userId = "";
    fullName = "";
    permissions = [];
    per_action = {
        "PARTNER_DISCUSSABLE": false,
        "PARTNER_ACCEPTABLE": false,
        "PARTNER_EDITABLE": false
    }

    dropListPartData: Observable<any>;
    partsLoading = false;
    partsInput$ = new Subject<string>();
    dropListEmployeeData : Observable<any>;
    employeesLoading = false;
    employeesInput$ = new Subject<string>();
    partKeySearch = "";
    endDataPart = false;
    partPage = 1;
    employeeKeySearch = "";
    endDataEmployee = false;
    employeePage = 1;

    myLoader: LoadingService;

    errorValidate: any = null;
    generalErrorValidate: any = null;

    deal: boolean = null;
    email: string = "";
    reason: string = "";

    constructor(d = null) {
        d = d || {};
        this.partyId = d.partyId || d.departmentId || null;
        this.partyName = d.partyName || d.departmentName || "";
        this.userId = d.userId || null;
        this.fullName = d.fullName || "";
        this.permissions = d.permissions || [];
        this.deal = d.deal;
        this.email = d.email || "";
        this.reason = d.reason || "";
        this.setPerAction();
        this.lazyLoadPart();
        this.lazyLoadEmployee();
    }

    public setPerAction() {
        this.permissions.forEach((item) => {
            this.per_action[item] = true;
        })
    }

    public changedAction(action: string = "") {
        if (this.per_action[action] == undefined) return;
        this.per_action[action] = !this.per_action[action];
        this.setPermission();
    }

    setPermission() {
        let arr = [];
        Object.keys(this.per_action).forEach((key) => {
            if (this.per_action[key] == true) arr.push(key);
        });
        this.permissions = arr;
    }

    /**
     * Bat su kien thay doi bo phan
     */
    changedParty(event) {
        console.log(event);
        if (event) {
            this.partyId = event.partyId || this.partyId;
            this.partyName = event.partyName || this.partyName;
        }
        this.userId = null;
        this.fullName = "";
        this.lazyLoadEmployee();
    }

    trackByFn(item: any) {
        return item;
    }

    /**
     * Tim kiem realtime droplist bo phan
     */
    lazyLoadPart() {
        this.dropListPartData = concat(
            this.partsInput$.pipe(
                startWith(''),
                debounceTime(400),
                distinctUntilChanged(),
                tap(() => { this.partsLoading = true; LoadingService.instance.disabledLoader(this.partsLoading); }),
                switchMap((term) => {
                    this.partPage = 1;
                    this.endDataPart = false;
                    this.partKeySearch = term;
                    let res = PartService.instance.getPartsV2(1, 10, "", "", "0", term).pipe(
                        map((res: any) => {
                            return this.addToDropList(res?.object?.data, this.partyId ? { partyId: this.partyId, partyName: this.partyName } : null)
                        }),
                        catchError(() => of(this.addToDropList([], this.partyId ? { partyId: this.partyId, partyName: this.partyName } : null))), // empty list on error
                        tap(() => { this.partsLoading = false; LoadingService.instance.disabledLoader(this.partsLoading); })
                    );
                    return res;
                })
            ));
    }

    /**
    * Su kien scroll xuong duoi cung cua droplist
    * @returns 
    */
     onScrollToEndDropListPart() {
        if (this.endDataPart) return;

        var nextPage = this.partPage + 1;
        this.partPage = nextPage;
        this.loadMorePart(this.partPage);
    }

    /**
     * Tai them du lieu droplist Luong hop dong
     * @param page 
     */
    loadMorePart(page) {
        this.partsLoading = true; LoadingService.instance.disabledLoader(this.partsLoading);
        PartService.instance.getPartsV2(page, 10, "", "", "", this.partKeySearch || "").subscribe((res: any) => {
            console.log(res);
            if (res?.object?.data.length <= 0) {
                this.endDataPart = true;
            }

            this.dropListPartData.subscribe((value) => {
                this.dropListPartData = of(value.concat(res?.object?.data || []));
            });

            this.partsLoading = false; LoadingService.instance.disabledLoader(this.partsLoading);
        }, err => {
            this.partsLoading = false; LoadingService.instance.disabledLoader(this.partsLoading);
        })
    }

    addToDropList(data = [] ,param, key = "partyId") {
        key = key || "id";
        if (param === null) return data;
        let index = data.findIndex((item) => {
            return param[key] === item[key];
        });
        if (index >= 0) return data;
        return [...data, param]; 
    }

    /**
     * Bat su kien thay doi nhan su
     */
    changedEmployee(event) {
        if (event) {
            this.userId = event.userId || this.userId;
            this.fullName = event.fullName || this.fullName;
        }
    }

    trackByFnEmployee(item: any) {
        return item;
    }

    lazyLoadEmployee() {
        this.dropListEmployeeData = concat(
            this.employeesInput$.pipe(
                startWith(''),
                debounceTime(400),
                distinctUntilChanged(),
                tap(() => { this.employeesLoading = true; LoadingService.instance.disabledLoader(this.employeesLoading); }),
                switchMap((term) => {
                    this.employeePage = 1;
                    this.endDataEmployee = false;
                    this.employeeKeySearch = term;
                    if (this.partyId == null || this.partyId == "") {
                        this.employeesLoading = false; LoadingService.instance.disabledLoader(this.employeesLoading); 
                        return [];
                    }
                    let res = EmployeeService.instance.getListEmployeeInPartV2(this.partyId, 1, 10, "created", "DESC", "0" ,term).pipe(
                        map((res: any) => {
                            return this.addToDropList(res?.object?.data, this.userId ? { userId: this.userId, fullName: this.fullName } : null, "userId")
                        }),
                        catchError(() => of(this.addToDropList([], this.userId ? { userId: this.userId, fullName: this.fullName } : null, "userId"))), // empty list on error
                        tap(() => { this.employeesLoading = false; LoadingService.instance.disabledLoader(this.employeesLoading); })
                    );
                    return res;
                })
            ));
    }

    /**
    * Su kien scroll xuong duoi cung cua droplist
    * @returns 
    */
     onScrollToEndDropListEmployee() {
        if (this.endDataEmployee) return;

        var nextPage = this.employeePage + 1;
        this.employeePage = nextPage;
        this.loadMoreEmployee(this.employeePage);
    }

    /**
     * Tai them du lieu droplist Luong hop dong
     * @param page 
     */
    loadMoreEmployee(page) {
        this.employeesLoading = true; LoadingService.instance.disabledLoader(this.employeesLoading);
        EmployeeService.instance.getListEmployeeInPartV2(this.partyId, page, 10, "created", "DESC", "0" , this.employeeKeySearch || "").subscribe((res: any) => {
            console.log(res);
            if (res?.object?.data.length <= 0) {
                this.endDataEmployee = true;
            }

            this.dropListEmployeeData.subscribe((value) => {
                this.dropListEmployeeData = of(value.concat(res?.object?.data || []));
            });

            this.employeesLoading = false; LoadingService.instance.disabledLoader(this.employeesLoading);
        }, err => {
            this.employeesLoading = false; LoadingService.instance.disabledLoader(this.employeesLoading);
        })
    }

    setGeneralError(error) {
        if (error === null) { this.generalErrorValidate = null; return; }
        this.generalErrorValidate = { ...this.generalErrorValidate, ...error };
        this.generalErrorValidate && Object.keys(this.generalErrorValidate).length === 0 ? this.generalErrorValidate = null : this.generalErrorValidate;
    }

    hasGeneralError() {
        return this.generalErrorValidate !== null && this.generalErrorValidate !== undefined && this.generalErrorValidate !== {};
    }

    setError(error) {
        if (error === null) { this.errorValidate = null; return; }
        this.errorValidate = { ...this.errorValidate, ...error };
        this.errorValidate && Object.keys(this.errorValidate).length === 0 ? this.errorValidate = null : this.errorValidate;
    }

    hasError(control, error = "") {
        if (error != "") {
            return this.errorValidate && this.errorValidate[control] && this.errorValidate[control][error];
        }
        return this.errorValidate && this.errorValidate[control];
    }

    get inValid() {
        return this.errorValidate !== null && this.errorValidate !== undefined && this.errorValidate !== {};
    }

    get valid() {
        return this.errorValidate == null || this.errorValidate == undefined || this.errorValidate !== {};
    }

    checkValidate() {
        this.setError(null);
        this.setGeneralError(null);

        if (this.partyId === null || this.partyId === "") {
            this.setError({ partyId: { required: true }});
            this.setGeneralError({ required: true });
        }
        if (this.userId === null || this.userId === "") {
            this.setError({ userId: { required: true }});
            this.setGeneralError({ required: true });
        }
        if (this.permissions.length <= 0) {
            this.setError({ permissions: { required: true }});
            this.setGeneralError({ required: true });
        }
    }
}