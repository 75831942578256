import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { Base64 } from 'src/app/utils/common/base64';
import { ConstantAlertMsg } from 'src/app/utils/common/constant-alert-msg';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { Constant } from 'src/app/utils/common/constant_videocall';
import { DateUtils } from 'src/app/utils/common/DateUtils';
import { REGEXP_DATE_INPUT, REGEXP_EMAIL, REGEXP_MST, REGEXP_PHONE_NUMBER, REGEXP_USERNAME } from 'src/app/utils/common/regexp';
import { localize } from 'src/app/utils/localize/localize';
import { InfoPartyObject } from 'src/app/utils/models/aas-models/info-party/info-party-object';
import { PackageDetail } from 'src/app/utils/models/aas-models/package-manager/package-detail';
import { ListHuyenResponse } from 'src/app/utils/models/catalog/list-huyen/list-huyen-response.model';
import { ListTinhResponse } from 'src/app/utils/models/catalog/list-tinh/list-tinh-response.model';
import { ListXaResponse } from 'src/app/utils/models/catalog/list-xa/list-xa-response.model';
import { AccountManagerService } from 'src/app/utils/services/aas-network/account-manager.service';
import { CatalogService } from 'src/app/utils/services/aas-network/catalog.service';
import { PackageServiceService } from 'src/app/utils/services/aas-network/package-service/package-service.service';
import { TemplateService } from 'src/app/utils/services/aas-network/template.service';

@Component({
  selector: 'app-edit-consumer-customer',
  templateUrl: './edit-consumer-customer.component.html',
  styleUrls: ['./edit-consumer-customer.component.scss']
})
export class EditConsumerCustomerComponent implements OnInit {
  id = this.activatedRoute.snapshot.paramMap.get("id");

  localize = localize;

  form: FormGroup;
  isSubmit = false;
  validateMsg = {
    username: {
      required: "Vui lòng nhập tên tài khoản",
      pattern: 'Tên tài khoản phải viết liền không dấu, có ít nhất 6-50 ký tự, không chứa ký tự khoảng trống',
      unique: 'Tên đăng nhập đã tồn tại'
    },
    identityLevel: {
      required: "Vui lòng chọn loại tài khoản"
    },
    tb_code: {
      required: "Vui lòng nhập mã thuê bao"
    },
    tb_type: {
      required: "Vui lòng nhập loại thuê bao",
    },
    hrm_am: {
      required: "Vui lòng nhập mã HRM AM"
    },
    tinh_code: {
      required: "Vui lòng nhập mã tỉnh",
      maxlength: "Mã tỉnh không được dài quá 10 ký tự"
    },
    fullName: {
      required: "Vui lòng nhập họ và tên người đại diện"
    },
    email: {
      required: "Vui lòng nhập email",
      pattern: "Email sai định dạng"
    },
    shortName: {
      required: "Vui lòng nhập tên viết tắt"
    },
    taxNumber: {
      required: "Vui lòng nhập mã số thuế"
    },
    partyName: {
      required: "Vui lòng nhập tên doanh nghiệp"
    },
    phoneNumber: {
      required: "Vui lòng nhập số điện thoại",
      pattern: "Số điện thoại sai định dạng"
    },
    birthday: {
      required: "Vui lòng nhập ngày sinh",
      pattern: "Ngày sinh sai định dạng"
    },
    // tinhId: {
    //   required: "Vui lòng chọn Tỉnh/Thành phố"
    // },
    // huyenId: {
    //   required: "Vui lòng chọn Quận/Huyện"
    // },
    // xaId: {
    //   required: "Vui lòng chọn Xã/Phường"
    // },
    // duong: {
    //   required: "Vui lòng nhập đường"
    // },
    // sonha: {
    //   required: "Vui lòng nhập số nhà"
    // },
    file: {
      required: "Vui lòng đính kèm file",
      type: "File không đúng định dạng"
    },
    packageId: {
      required: "Vui lòng chọn gói cước"
    },
    gioiTinhId: {
      required: "Vui lòng chọn giới tính"
    },
    personalId: {
      required: "Vui lòng nhập số giấy tờ"
    },
    placeOfBirth: {
      required: "Vui lòng nhập nơi sinh"
    },
    issueDate: {
      required: "Vui lòng nhập ngày cấp",
      pattern: "Ngày cấp sai định dạng"
    },
    issuePlace: {
      required: "Vui lòng nhập nơi cấp",
    },
    noiDKHKTT: {

    },
    validTo: {
      required: "Vui lòng nhập có giá trị đến",
      pattern: "Có giá trị đến sai định dạng"
    },
    frontImage: {
      required: "Vui lòng đính kèm ảnh mặt trước"
    },
    backImage: {
      required: "Vui lòng đính kèm ảnh mặt sau"
    },
    portraitImage: {
      required: "Vui lòng đính kèm ảnh chân dung"
    }
  }

  statusOptions: Array<any> = [
    { value: "N", label: "Đang hoạt động" },
    { value: "Y", label: "Ngưng hoạt động" }
  ]

  inputFileLabel = localize.select_attach_file;

  identityLevels = [
    { id: 1, label: "Tài khoản định danh cấp 1" },
    { id: 2, label: "Tài khoản định danh cấp 2" },
  ]
  packageIds = [];
  trialPackageIds = [];
  fullPackageIds = [];

  optionsCleave = {
    date: true,
    delimiter: '/',
    datePattern: ['d', 'm', 'Y']
  };
  currentDate = new Date();

  tinhs = [];
  xas = [];
  huyens = [];

  showFile = false;
  pdfFile = null;
  attachFile = null;
  fileType = [
    'image/jpeg',
    'image/png',
    'image/jpg',
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ];

  gioiTinhs = [
    { id: 1, label: "Nữ" },
    { id: 2, label: "Nam" }
  ]

  party: InfoPartyObject = new InfoPartyObject();

  editedFile = false;

  packageInfo: PackageDetail = null;
  loadedAfterInit = false;

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private accApi: AccountManagerService,
    private catalog: CatalogService,
    private templateApi: TemplateService,
    private myAlert: AlertControl,
    private packageApi: PackageServiceService
  ) { }

  ngOnInit(): void {
    this.viewDidLoad();
  }

  viewDidLoad() {
    this.makeForm();
    this.getDetailParty(this.id);
    this.getDmTinh();
    this.getImage(this.id, "EKYC_CHANDUNG");
    this.getImage(this.id, "EKYC_MATTRUOC");
    this.getImage(this.id, "EKYC_MATSAU");
    this.getImage(this.id, "CHUKY");
  }

  makeForm() {
    this.form = this.fb.group({
      username: ["", [Validators.required, Validators.pattern(REGEXP_USERNAME)]],
      identityLevel: [null, [Validators.required]],
      packageId: [null, [Validators.required]],
      tb_code: ["", [Validators.required]],
      tb_type: ["", [Validators.required]],
      tinh_code: ["", [Validators.required, Validators.maxLength(10)]],

      email: ["", [Validators.required, Validators.pattern(REGEXP_EMAIL)]],
      fullName: ["", [Validators.required]],
      phoneNumber: ["", [Validators.required, Validators.pattern(REGEXP_PHONE_NUMBER)]],
      birthday: ["", [Validators.required, Validators.pattern(REGEXP_DATE_INPUT)]],
      tinhId: [null],
      huyenId: [null],
      xaId: [null],
      duong: [""],
      sonha: [""],
      file: [null, []],
      gioiTinhId: [null, [Validators.required]],

      personalId: ["", [Validators.required]],
      placeOfBirth: ["", [Validators.required]],
      issueDate: ["", [Validators.required, Validators.pattern(REGEXP_DATE_INPUT)]],
      issuePlace: ["", [Validators.required]],
      noiDKHKTT: ["", []],
      validTo: ["", [Validators.pattern(REGEXP_DATE_INPUT)]],

      frontImage: [null],
      backImage: [null],
      portraitImage: [null],
      hrm_am: ["", [Validators.required]],
      hrm_creator: [""],
      disable: [""]
    });
  }

  setValue(party: InfoPartyObject) {
    this.form.patchValue({
      username: party.userInformations.username,
      identityLevel: party.identityLevel,
      packageId: party.packageTempId,
      tb_code: party.subscriptionCode,
      tb_type: party.subscriptionType,
      tinh_code: party.provinceCode,
      partyName: party.tenToChuc,
      email: party.userInformations.email,
      shortName: party.tenRutGon,
      taxNumber: party.maSoThue,
      fullName: party.userInformations.ten,
      phoneNumber: party.userInformations.sdt,
      birthday: DateUtils.convert(party.userInformations.ngaySinh, DateUtils.format.INPUT),
      tinhId: party.userInformations.tinhId.toString() || null,
      huyenId: party.userInformations.huyenId.toString() || null,
      xaId: party.userInformations.xaId.toString() || null,
      duong: party.userInformations.duong,
      sonha: party.userInformations.soNha,
      file: party.gpkdFilename,
      hrm_am: party.requesterCode || null,
      hrm_creator: party.creatorCode || null,
      disable: party.disable || null,
      validTo: DateUtils.convert(party.userInformations.expiryDate, DateUtils.format.INPUT),
      gioiTinhId: party.userInformations.gioiTinhId || null,
      personalId: party.userInformations.cmnd || "",
      noiDKHKTT: party.userInformations.noiDangKy || "",
      issueDate: DateUtils.convert(party.userInformations.ngayCap, DateUtils.format.INPUT),
      issuePlace: party.userInformations.noiCap || "",
      placeOfBirth: party.userInformations.noiSinh || ""
    });

    this.inputFileLabel = party.gpkdFilename || "";

    this.getImage(this.id, "EKYC_CHANDUNG");
    this.getImage(this.id, "EKYC_MATTRUOC");
    this.getImage(this.id, "EKYC_MATSAU");
    this.getImage(this.id, "CHUKY");
    console.log(this.form);
  }

  prefix = "data:image/png;base64,";
  base64FrontImage = null;
  base64BackImage = null;
  base64PortraitImage = null;
  async uploadFile(event, type = "") {
    if (!event || event == null || event == undefined) return;


    for (var i = 0; i < event.length; i++) {
      const fileItem = event[i];
      let item = {
        name: fileItem.name,
        size: fileItem.size,
        type: fileItem.type,
      }

      console.log(fileItem);

      if (type === "FRONT") {
        this.form.controls.frontImage.setValue(fileItem);
        var self = this;
        var reader = new FileReader();
        reader.onload = function (e) {
          self.base64FrontImage = reader.result
        }
        reader.readAsDataURL(fileItem);
      } else if (type === "BACK") {
        this.form.controls.backImage.setValue(fileItem);
        var self = this;
        var reader = new FileReader();
        reader.onload = function (e) {
          self.base64BackImage = reader.result
        }
        reader.readAsDataURL(fileItem);
      } else if (type === "PORTRAIT") {
        this.form.controls.portraitImage.setValue(fileItem);
        var self = this;
        var reader = new FileReader();
        reader.onload = function (e) {
          self.base64PortraitImage = reader.result
        }
        reader.readAsDataURL(fileItem);
      }

    }
  }

  removeImage(type = "") {
    if (type === "FRONT") {
      this.form.controls.frontImage.setValue(null);
      this.base64FrontImage = "";
    } else if (type === "BACK") {
      this.form.controls.backImage.setValue(null);
      this.base64BackImage = "";
    } else if (type === "PORTRAIT") {
      this.form.controls.portraitImage.setValue(null);
      this.base64PortraitImage = "";
    }
  }

  onAttachFileChanged(event) {
    let file = null;
    file = event.target.files && event.target.files.item(0);
    if (file && file !== null && file !== undefined) {
      if (!this.fileType.includes(file.type)) {
        this.form.controls.file.setErrors({ type: true });
        this.inputFileLabel = file.name
      } else if (file.size > 5000000) {
        this.myAlert.showAlertOnlyNoti(localize.file_size_too_large, "");
      } else {
        this.form.controls.file.setValue(file);
        this.inputFileLabel = file.name;

        if (file.type === 'application/pdf') {
          this.pdfFile = file;
        } else {
          let data = new FormData();
          data.append('attachFile', file);
          this.postConvertToPDF(data);
        }
      }
    } else {
      this.pdfFile = null;
      this.form.controls.file.setErrors({ required: true });
      this.inputFileLabel = localize.select_attach_file;
    }
  }

  showFilePreview() {
    this.showFile = true;
  }

  closePreview() {
    this.showFile = false;
  }

  changedIdentityLevel(event) {
    if (event) {
      if (event.id === 1) {
        this.form.controls['frontImage'].clearValidators();
        this.form.controls['frontImage'].updateValueAndValidity()
        this.form.controls['portraitImage'].clearValidators();
        this.form.controls['portraitImage'].updateValueAndValidity()
        this.form.controls['packageId'].setValue(this.getDefaultLevel1());
      } else {
        this.form.controls['frontImage'].clearValidators();
        this.form.controls['frontImage'].setValidators([Validators.required])
        this.form.controls['frontImage'].updateValueAndValidity()
        this.form.controls['portraitImage'].clearValidators();
        this.form.controls['portraitImage'].setValidators([Validators.required])
        this.form.controls['portraitImage'].updateValueAndValidity()
      }
    }
  }

  getDefaultLevel1() {
    let x = this.packageIds.find((x) => { return x.packageType === "TRIAL_TYPE" && x.packageCode === "BUSINESS_1" });
    if (x) {
      return x.packInfoId;
    } else {
      return "";
    }
  }

  changeDateFilter(event, type) {
    switch (type) {
      case "birtday":
        this.form.controls.birthday.setValue(DateUtils.convertObjtoDate(event));
        break;
      case "issue_date":
        this.form.controls.issueDate.setValue(DateUtils.convertObjtoDate(event));
        break;
      case "valid_to":
        this.form.controls.validTo.setValue(DateUtils.convertObjtoDate(event));
        break;
    }
  }

  changedTinh(event) {
    if (event) {
      this.form.controls.huyenId.setValue(null);
      this.form.controls.xaId.setValue(null);
      this.getDmHuyen(event.id);
    }
  }

  changedHuyen(event) {
    if (event) {
      this.form.controls.xaId.setValue(null);
      this.getDmXa(event.id);
    }
  }

  cancel() {
    this.router.navigate(['console/account-manager'])
  }

  save() {
    this.isSubmit = true;
    console.log(this.form);
    if (this.form.valid) {
      let msg = localize.confirm_edit_account_customer;
      if (this.form.value.email !== this.party.userInformations.email) {
        msg = localize.confirm_edit_account_customer_with_changed_email;
      }
      this.myAlert.showAlert(msg, "", true, "", localize.btn_save_changed, () => {
        this.editAccount();
      }, "", localize.btn_cancel, null, localize.confirm)
    }
  }

  editAccount() {
    let data = new FormData();
    data.append("partyType", "CONSUMER");

    let packageData = {
      packageId: this.form.value.packageId || ""
    }

    let daiDien = {
      "username": this.form.value.username || "",
      "ten": this.form.value.fullName || "",
      "sdt": this.form.value.phoneNumber || "",
      "ngaySinh": this.form.value.birthday || "",
      "gioiTinhId": this.form.value.gioiTinhId || null,
      "tinhId": this.form.value.tinhId,
      "huyenId": this.form.value.huyenId,
      "xaId": this.form.value.xaId,
      "duong": this.form.value.duong || "",
      "soNha": this.form.value.sonha || "",
      "noiCap": this.form.value.issuePlace || "",
      "ngayCap": this.form.value.issueDate || "",
      "cmnd": this.form.value.personalId || "",
      "noiDangKy": this.form.value.noiDKHKTT || "",
      "expiryDate": this.form.value.validTo, 
      "noiSinh": this.form.value.placeOfBirth || ""
    }

    let toChuc = {
      "tenToChuc": this.form.value.partyName || "",
      "tenRutGon": this.form.value.shortName || "",
      "email": this.form.value.email || "",
      "maSoThue": this.form.value.taxNumber || "",
      "packageData": packageData,
      "identityLevel": this.form.value.identityLevel || null,
      "provinceCode": this.form.value.tinh_code || "",
      "subscriptionCode": this.form.value.tb_code || "",
      "subscriptionType": this.form.value.tb_type || "",
      "requesterCode": this.form.value.hrm_am || "",
      "creatorCode": this.form.value.hrm_creator || "",
      "disable": this.form.value.disable || "N"

    }

    data.append("daiDien", JSON.stringify(daiDien));
    data.append("toChuc", JSON.stringify(toChuc));
    if (this.form.value.portraitImage) data.append("EKYC_CHANDUNG", this.form.value.portraitImage, this.form.value.portraitImage.name ?? this.genNameFile(this.id, "portrait"));
    if (this.form.value.frontImage) data.append("EKYC_MATTRUOC", this.form.value.frontImage, this.form.value.frontImage.name ?? this.genNameFile(this.id, "front"));
    if (this.form.value.backImage) data.append("EKYC_MATSAU", this.form.value.backImage, this.form.value.backImage.name ?? this.genNameFile(this.id, "back"));
    this.postEditAccount(this.id, data);
  }

  getDmTinh() {
    this.catalog.getListTinh().subscribe((res) => {
      let dmTinh = new ListTinhResponse(res);
      this.tinhs = dmTinh.object.map(item => {
        return { id: item.tinhId.toString(), label: item.tenTinh };
      });
    }, err => {

    });
  }

  getDmHuyen(idTinh) {
    this.catalog.getListHuyen(idTinh).subscribe((res) => {
      let dmHuyen = new ListHuyenResponse(res);
      this.huyens = dmHuyen.object.map(item => {
        return { id: item.huyenId.toString(), label: item.tenHuyen };
      });
    }, err => {
    });
  }

  getDmXa(idHuyen) {
    this.catalog.getListXa(idHuyen).subscribe((res) => {
      let dmXa = new ListXaResponse(res);
      this.xas = dmXa.object.map(item => {
        return { id: item.xaId.toString(), label: item.tenXa };
      });
    }, err => {
    });
  }

  getDataPackage(page, maxSize, propertiesSort, packageType, status, userType) {
    this.accApi.getDataPackage(page, maxSize, propertiesSort, packageType, status, userType).subscribe((res: any) => {
      this.fullPackageIds = res.object || [];
      this.fullPackageIds.forEach((content) => {
        if (content.packageType && content.packageType == "TRIAL_TYPE") {
          this.trialPackageIds.push(content);
        }
      });
      if (this.form.controls.identityLevel.value == 1) {
        this.packageIds = this.trialPackageIds;
      }
      else if (this.form.controls.identityLevel.value == 2) {
        this.packageIds = this.fullPackageIds;
      }
      console.log(this.packageIds);
    }, err => {

    });
  }

  postConvertToPDF(data: FormData) {
    this.templateApi.postConvertToPdf(data).subscribe(fileData => {
      const blob: any = new Blob([fileData], { type: 'application/pdf' });
      this.pdfFile = blob;
    }, err => {
      console.log(err);
      this.myAlert.showErrorHandled(err);
    });
  }

  getDetailParty(id) {
    this.accApi.getDetailParty(id).subscribe((res: any) => {
      console.log(res);
      this.party = new InfoPartyObject(res?.object || {});

      if (this.party.userInformations.tinhId) this.getDmHuyen(this.party.userInformations.tinhId);
      if (this.party.userInformations.huyenId) this.getDmXa(this.party.userInformations.huyenId);

      this.setValue(this.party);
      
      this.getImage(this.party.userInformations.userId, 'EKYC_CHANDUNG');
      this.getImage(this.party.userInformations.userId, 'EKYC_MATTRUOC');
      this.getImage(this.party.userInformations.userId, 'EKYC_MATSAU');

      this.getStockPackage(this.id);

      this.getDataPackage(1, 10000, "created", "", "", "CONSUMER");

    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }

  /**
   * Lấy thông tin dữ liệu gọi dịch vụ gốc
   * @param id 
   */
  getStockPackage(id) {
    this.accApi.getStockPackage(id).subscribe((res: any) => {
      console.log(res);
      this.form.controls.packageId.setValue(res.object?.packageInfo?.packInfoMenuId || null);

      this.getPackage(res.object?.packageInfo?.packInfoMenuId);
    })
  }

  postEditAccount(id, param) {
    this.accApi.postEditAccount(id, param).subscribe((res: any) => {
      console.log(res);
      this.myAlert.showAlertOnlyNoti(localize.edit_account_customer_success, ConstantAlertType.SUCCESS);
      this.router.navigate(['console/account-manager']);
    }, err => {
      console.log(err);
      this.myAlert.showErrorHandled(err);
    })
  }
  
  /**
   * Lấy thông tin gói dịch vụ
   * @param packageId 
   */
  getPackage(packageId){
    this.packageApi.getDetailPackage(packageId).subscribe((res: any)=>{
      this.packageInfo = new PackageDetail(res?.object);
      console.log(this.packageInfo);

      if (!this.loadedAfterInit) {
        this.getLastInfoUserPackage(this.id);
        this.loadedAfterInit = true;
      }
    }, err => {
      console.log(err);
      this.myAlert.showErrorHandled(err);
    }, () => {
    });
  }

  /**
   * Tải ảnh thông tin cá nhân
   * @param id 
   * @param type 
   */
  getImage(id, type) {
    this.accApi.downloadImage(id, type).subscribe(
      (res) => {
        if (res) {
          const blob: any = new Blob([res], {
            type: "image/png",
          });
          console.log(blob);
          let self = this;
          var reader = new FileReader();
          reader.onload = function (e) {
            switch (type) {
              case "EKYC_CHANDUNG":
                self.form.controls.portraitImage.setValue(blob);
                self.base64PortraitImage = reader.result;
                break;
              case "EKYC_MATTRUOC":
                self.form.controls.frontImage.setValue(blob);
                self.base64FrontImage = reader.result;
                break;
              case "EKYC_MATSAU":
                self.form.controls.backImage.setValue(blob);
                self.base64BackImage = reader.result;
                break;
              default:
            }

            console.log(self.form.value);
          }
          reader.readAsDataURL(blob);
          
        }
      },
      (err) => {
        if (type === "EKYC_CHANDUNG") {
          this.base64PortraitImage = null;
        } else if (type === "EKYC_MATTRUOC") {
          this.base64FrontImage = null;
        } else if (type === "EKYC_MATSAU") {
          this.base64BackImage = null;
        }
      },
      () => { }
    );
  }

  /**
   * Lấy thông tin dữ liệu gói dịch vụ hiện tại của 1 tổ chức
   * @param partyId 
   */
  getLastInfoUserPackage(partyId) {
    this.packageApi.getLastInfoUserPackage(partyId).subscribe((res: any)=>{
      console.log(res);
      if (res?.object && res?.object instanceof Array && res?.object.length > 0) {
        this.packageInfo.listPackageInfrastructure.infrastructures = [].concat(res?.object[0]?.infrastructures || []);
        this.packageInfo.totalPrice = res?.object[0]?.price || 0;
      }
    }, err => {
      console.log(err);
      this.myAlert.showErrorHandled(err);
    }, () => {
    });
  }

  genNameFile(id, prefix) {
    return `${id}_${prefix}.png`;
  }
}
