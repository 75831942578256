import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-service-signup-business-vip-step',
  templateUrl: './service-signup-business-vip-step.component.html',
  styleUrls: ['./service-signup-business-vip-step.component.scss']
})
export class ServiceSignupBusinessVipStepComponent implements OnInit {

  stepControl: Array<any> = [
    {
      active: true,
      done: false,
      disabled: false
    },
    {
      active: true,
      done: false,
      disabled: true
    },
    {
      active: true,
      done: false,
      disabled: true
    }
  ];

  currentStep = 0;
  startStep = 0;
  endStep = 2;
  countStep = 4;

  dataForm: any = {};

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * Back action
   */
  backStep() {
    if (this.currentStep - 1 >= this.startStep) {
      this.currentStep--;
    }
  }

  /**
   * Next action
   */
  nextStep() {
    if (this.currentStep + 1 <= this.endStep) {
      this.currentStep++;
    }
  }

  /**
   * Handle action tiep tuc tu step
   * @param e 
   * @param step 
   */
  handleNext(e, step: number) {
    console.log(e);
    this.dataForm = e;

    this.stepControl[this.currentStep].done = true;
    console.log(this.stepControl[this.currentStep]);
    this.nextStep();
  }

  /**
   * Handle action tro lai step
   * @param e 
   * @param step 
   */
  handleBack(e, step: number) {
    this.backStep();
  }
}
