import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { UtilsFunc } from 'src/app/utils/common/utils-func';
import { localize } from 'src/app/utils/localize/localize';
import { ContractObject } from 'src/app/utils/models/aas-models/contract/contract/contract-object.model';
import { AasContractService } from 'src/app/utils/services/aas-network/aas-contract.service';

@Component({
  selector: 'app-modal-ekyc-information',
  templateUrl: './modal-ekyc-information.component.html',
  styleUrls: ['./modal-ekyc-information.component.scss']
})
export class ModalEkycInformationComponent implements OnInit {

  @Input()
  set ekycInfo(value) { this._ekycInfo = value; }
  get ekycInfo() { return this._ekycInfo; }
  _ekycInfo = null;

  @Input()
  set status(value) { this._status = value; }
  get status() { return this._status; }
  _status = null;

  @Input()
  set contractId(value) { this._contractId = value; }
  get contractId() { return this._contractId; }
  _contractId = null;

  @Input()
  set signId(value) { this._signId = value; }
  get signId() { return this._signId; }
  _signId = null;

  @Input() 
  set contract(value) { this._contract = new ContractObject(value); }
  get contract() { return this._contract; }
  _contract: ContractObject = null;

  @Output('reloadEvent') reloadEvent: EventEmitter<any> = new EventEmitter();

  formReturn: FormGroup;
  isCancelSubmit = false;
  validateMsgFormDenyDiscuss = {
    reason: {
      required: localize.reason_cancel_required,
      maxlength: localize.max_length_string
    }
  };
  modelReturnContract = null;

  callBackBtnRequestResign = null;
  callBackReload = null;

  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private contractService: AasContractService,
    private myAlert: AlertControl,
    private ngbModal: NgbModal
  ) { }

  ngOnInit(): void {
    console.log(this.ekycInfo);
    this.makeFormReturn();
  }

  /**
   * Nhấn nút hủy hợp đồng
   * @param content 
   */
  cancelContract(content) {
    this.modelReturnContract = this.ngbModal.open(content, {
      size: 'md',
      backdrop: 'static',
      keyboard: false
    });
  }

  /**
   * Nhấn nút "Gửi ký lại"
   */
  resignRequest() {
    this.callBackBtnRequestResign && this.callBackBtnRequestResign();
  }

  /**
   * Nhấn nút "Chấp nhận chữ ký"
   */
  allowSignature() {
    this.myAlert.showAlert('Bạn có chắn chắn muốn chấp nhận chữ ký này?', '', true, '', 'btn_ok', () => {
      this.contractService.allowSignature(this.contractId, this.signId).subscribe((res: any) => {
        this.reloadEvent.emit(true);
        this.callBackReload && this.callBackReload();
        this.activeModal.close();
        this.myAlert.showAlertOnlyNoti("Chấp nhận chữ ký thành công!", ConstantAlertType.SUCCESS);
      }, err => {
        this.myAlert.showErrorHandled(err);
      })
    });

  }

  // Get style and label of status field.
  geStatusLabel(status: string) {
    if (status === "VALID") {
      return "Hợp lệ";
    }
    else if (status === "WARNING") {
      return "Cảnh báo";
    }
    return "";
  }


  //MARK: HỦY HỢP ĐỒNG
  /**
   * Khởi tạo form hủy hợp đồng
   */
  makeFormReturn() {
    this.formReturn = this.fb.group({
      reasonReturn: new FormControl('', [Validators.maxLength(4000)])
    });
  }

  /**
   * Nhấn nút submit hủy hợp đồng
   */
  submitReturn() {
    this.isCancelSubmit = true;
    console.log(this.formReturn.value);
    if (this.formReturn.valid) {
      let param = {
        "cancelReason": this.formReturn.value.reasonReturn
      }
      this.postReturnContract(this.contractId, param);
    }
  }

  /**
   * Trả lại hợp đồng
   * @param contractId 
   * @param param 
   */
  postReturnContract(contractId, param) {
    this.myAlert.showAlert('Bạn có chắn chắn muốn trả lại hợp đồng?', '', true, '', 'Đồng ý', () => {
      this.contractService.postReturnContract(contractId, param).subscribe(res => {
        this.reloadEvent.emit(true);
        this.callBackReload && this.callBackReload();
        this.activeModal.close();
        this.myAlert.showAlertOnlyNoti('Hủy hợp đồng thành công!', 'success', '', 'OK', () => {
          this.modelReturnContract && this.modelReturnContract.close();
        });
      }, err => {
        console.log(err);
        this.myAlert.showErrorHandled(err);
      })
    });
  }

  /**
   * Kiểm tra điều kiện có được phép thao tác hay không
   * @param status 
   * @returns 
   */
  allowActionWhenWarningEKYC(status) {
    if (!this.contract.isCreator) return false;
    if (status == "VALID") return false;
    if (this.contract.currentStage === "LC_DRAFT_CANCEL") return false;
    return true;
  }
}
