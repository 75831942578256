export class SignBox {
    id: any = "";
    x: any = 0;
    y: any = 0;
    w: any = 0;
    h: any = 0;
    page: any = 0;

    constructor(data = null) {
        data = data || {};
        this.id = data.id || "";
        this.x = data.x;
        this.y = data.y;
        this.w = data.w;
        this.h = data.h;
        this.page = data.page;
    }

    changedSizeSignBox(signBox: any) {
        this.x = signBox?.x;
        this.y = signBox?.y;
        this.w = signBox?.w;
        this.h = signBox?.h;
        this.page = signBox?.page;
        this.id = signBox?.id;
    }
}