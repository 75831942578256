import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, timer } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstLocalStorage } from 'src/app/utils/common/constant';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ControlAccount } from 'src/app/utils/common/control-account';
import { StatusCode } from 'src/app/utils/common/status-code';
import { AasValidateMessage } from 'src/app/utils/common/validate-msg-aas';
import { localize } from 'src/app/utils/localize/localize';
import { ContractSignMultipleItem } from 'src/app/utils/models/aas-models/contract/sign-multiple-contracts/contract-sign-multiple-item';
import { InfoSignatureConfigObject } from 'src/app/utils/models/aas-models/signature/signature-config/info-signature-config-object';
import { InfoSignatureConfigResponse } from 'src/app/utils/models/aas-models/signature/signature-config/info-signature-config-response';
import { SmartCAAuth } from 'src/app/utils/models/aas-models/signature/smart-ca/smart-ca-auth';
import { SmartCACert } from 'src/app/utils/models/aas-models/signature/smart-ca/smart-ca-cert';
import { SignatureService } from 'src/app/utils/services/aas-network/signature.service';
import { AuthService } from 'src/app/utils/services/auth.service';
import { ErrorService } from 'src/app/utils/services/error/error.service';
import { LoadingService } from 'src/app/utils/services/loading.service';

declare var PDFSign: any;
declare var default_img: any;
@Component({
  selector: 'app-smart-ca-multiple',
  templateUrl: './smart-ca-multiple.component.html',
  styleUrls: ['./smart-ca-multiple.component.scss']
})
export class SmartCaMultipleComponent implements OnInit {
  @ViewChild('filebase') filebase!: ElementRef;

  @Input()
  set sessionId(value) { this._sessionId = value; }
  get sessionId() { return this._sessionId; }
  _sessionId = "";

  @Input()
  set contracts(value) { this._contracts = value; }
  get contracts() { return this._contracts; }
  _contracts: Array<ContractSignMultipleItem> = [];

  @Output() savedSignature: EventEmitter<any> = new EventEmitter();
  @Output() expiredSessionSmartCA: EventEmitter<any> = new EventEmitter();
  @Output() isWaitingConfirmSmartCA: EventEmitter<any> = new EventEmitter();

  localize = localize;

  currentStepSign = 0;
  formSign: FormGroup;
  isSubmit: boolean = false;
  validMsg: any = AasValidateMessage.SIGNATURE_CONFIG;
  inputFileImageLabel: string = 'ServiceContractDetail_select_image';
  img_prefix = "data:image/png;base64,";
  imgBase64: string = this.img_prefix + default_img;
  imgFile: File = null;
  imgType = ['image/jpeg', 'image/png', 'image/jpg'];
  pdfSigned: Blob = null;
  // keyTest: string = Config.KEY_PLUGIN_CA;
  //mark: phương thức ký
  signForm: string = '';
  hinhThucHienThi = [
    { value: 0, name: "ContractCensorship_ts_10" },
    { value: 1, name: "ContractCensorship_ts_11" },
    { value: 2, name: "ContractCensorship_ts_12" },
    { value: 3, name: "ContractCensorship_ts_13" },
    { value: 4, name: "ContractCensorship_ts_14" },
    { value: 5, name: "ContractCensorship_ts_15" }
  ]

  oAuthCert: SmartCAAuth = null;
  smartCert: SmartCACert = new SmartCACert();
  configSmartCA: InfoSignatureConfigObject = new InfoSignatureConfigObject();
  confirmSmartCA = false;
  showLayoutConfirmSmartCA = false;
  counter$: Observable<any>;
  count = 0;
  timeoutSmartCa = false;
  reqSignSmartCA = null;

  msgError = "SmartCa_ts_1";

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private signatureService: SignatureService,
    private myAlert: AlertControl,
    private authService: AuthService,
    private controlAccount: ControlAccount,
    private errorService: ErrorService,
    private loaderService: LoadingService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.makeFormSign();
  }

  //Khoi tao form ky
  makeFormSign() {
    this.formSign = this.fb.group({
      cert: [null],
      signerName: new FormControl('',),
      textColor: new FormControl('',),
      visibleType: new FormControl(5),
      base64Image: new FormControl(""),
      fontSize: new FormControl("13", [Validators.min(8), Validators.max(20), Validators.pattern(/^[0-9]+$/)]),
      signBy: new FormControl(true),
      signDate: new FormControl(true),
      imageFile: new FormControl(""),
    });
  }

  //Reset du lieu
  resetSmartCA() {
    this.oAuthCert = null;
    this.smartCert = null;
    this.configSmartCA = new InfoSignatureConfigObject();
    this.confirmSmartCA = false;
    this.showLayoutConfirmSmartCA = false;
    this.isWaitingConfirmSmartCA.emit(false);
    this.timeoutSmartCa = false;
    this.expiredSessionSmartCA.emit(false);
  }

  //Bat dau phiem ky smartCA
  startSignSmartCA() {
    this.resetSmartCA();

    // PDFSign.setStyleSelectedSignBox(this.formSign.value.visibleType);
    if (this.imgBase64 !== '') {
      PDFSign.setImage(this.imgBase64.split(',')[1]);
    } else {
      PDFSign.setImageDefault();
    }
    PDFSign.removeAllSignBox();

    this.getSessionSmartCA();

    this.currentStepSign = 1;//Danh dau buoc tiep theo
  }

  //Ky lai
  resign() {
    console.log("called resign smartCA");
    this.startSignSmartCA();
  }

  //Check box ngày ký
  changedDateSign(event) {
    PDFSign.setShowDateSign(this.formSign.value.signDate);
  }

  //Check box ký bởi
  changedSignBy(event) {
    PDFSign.setShowSignBy(this.formSign.value.signBy);
  }

  //Nhập font size chữ ký
  inputFontSize(event) {
    PDFSign.setFontSize(event.target.value)
  }

  //Chọn hình thức hiển thị chữ ký
  selectStyleSignBox(e) {
    PDFSign.setStyleSelectedSignBox(Number(e.value));
  }

  //Them khung chu ky
  addSignBox() {
    // if ((!this.imgESign && this.selectedWayOfSign === "E_SIGN") || (!this.imgContent && this.selectedWayOfSign == "SIGN_SERVER")) {
    //   this.myAlert.showAlertOnlyNoti(localize.contract_alert_no_image_esign, "");
    // } else {
      PDFSign.addSignBox();
    // }
  }

  //Upload ảnh chữ ký
  handleFileImageInput(e) {
    this.imgFile = e.target.files && e.target.files.item(0);
    if (this.imgFile && this.imgFile !== null && this.imgFile !== undefined) {
      this.inputFileImageLabel = this.imgFile.name;

      if (!this.imgType.includes(this.imgFile.type)) {
        //Sai dinh dang
        this.formSign.controls.imageFile.setErrors({ type: true });
      } else if (this.imgFile.size > 2000000) {
        //Dung luong file lon
        this.formSign.controls.imageFile.setErrors({ size: true });
      } else {
        //Dam bao ca tieu chi

        //Chuyen file thanh base64
        this.getBase64String(this.imgFile, this.handleImgSignServer);
      }

    } else {
      this.formSign.controls.imageFile.setErrors({ required: true });
      this.imgBase64 = '';
      this.inputFileImageLabel = 'ServiceContractDetail_select_image';
    }
  }

  //Chuyen Base64 sang blob
  converBase64toBlob(content, contentType): Blob {
    //blob = converBase64toBlob(response.content, 'application/pdf');
    contentType = contentType || '';
    var sliceSize = 512;
    var byteCharacters = window.atob(content); //method which converts base64 to binary
    var byteArrays = [
    ];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, {
      type: contentType,
    }); //statement which creates the blob
    return blob;
  }

  //Chuyen file sang base64
  getBase64String(blob, callback) {
    var self = this;
    var reader = new FileReader();
    reader.onload = function (e) {
      callback(reader.result, self);
    }

    reader.readAsDataURL(blob);
  }

  //Handle base64 cua file
  handleImgSignServer(str, self) {
    self.imgBase64 = str;
    self.formSign.controls.base64Image.setValue(str.split(',')[1]);
    PDFSign.setImage(str.split(',')[1]);
  }

  //Set dữ liệu config mặc định
  setConfigSign(signConfig: InfoSignatureConfigObject) {
    console.log(signConfig);
    this.formSign.controls.signerName.setValue(signConfig.signerName || "");

    this.formSign.controls.visibleType.setValue(signConfig.visibleType || 5);
    PDFSign.setStyleSelectedSignBox(Number(this.formSign.controls.visibleType.value) || 5);

    this.formSign.controls.base64Image.setValue(signConfig.base64Image || default_img);
    PDFSign.setImage(this.formSign.controls.base64Image.value || default_img);
    this.imgBase64 = this.img_prefix + (signConfig.base64Image || default_img);

    let imgTag: any = document.getElementById("img-smart-ca");
    if (imgTag) imgTag.src = this.imgBase64;

    this.formSign.controls.fontSize.setValue(signConfig.fontSize || "13");
    PDFSign.setFontSize(this.formSign.controls.fontSize.value || "13")

    this.formSign.controls.signBy.setValue(signConfig.signBy || false);
    PDFSign.setShowSignBy(this.formSign.controls.signBy.value || false);

    this.formSign.controls.signDate.setValue(signConfig.signDate || false);
    PDFSign.setShowDateSign(this.formSign.controls.signDate.value || false);

    console.log("Smart CA: ",this.formSign.value);
  }

  //Kiem tra session login smartCA
  listCert: SmartCACert[] = [];
  getSessionSmartCA() {
    this.listCert = []
    this.signatureService.getSessionSmartCAV2().subscribe((res: any) => {
      if (res && res.object instanceof Array && res.object.length > 0) {
        res.object.forEach(item => {
          this.listCert.push(new SmartCACert(item));
        })
        this.smartCert = new SmartCACert(this.listCert[0]);
        this.formSign.controls.cert.setValue(this.smartCert.certificateId)
        this.getConfigSmartCA(this.smartCert.id);
      }
      // console.log(res);
      // if (res.object?.oauth !== null) {
      //   this.oAuthCert = new SmartCAAuth(res.object?.oauth);
      //   this.smartCert = new SmartCACert(res.object?.certificate);
      //   this.formSign.controls.cert.setValue(this.smartCert.id)
      //   this.getConfigSmartCA(this.smartCert.id);
      // } 
      else {
        this.myAlert.showAlert(this.translate.instant('SmartCa_token_timeout'), "", true, "", this.translate.instant('SmartCa_loginSmart'), () => {
          const url = this.router.createUrlTree(['/app/console/signature-manager'], { queryParams: { tabName: "SMARTCA" } })
          window.open(url.toString(), '_blank')
        }, "", this.translate.instant('btn_cancel'), null, this.translate.instant('modal_title_noti'));
      }
    }, err => {
      // this.myAlert.showErrorHandled(err);
      this.myAlert.showAlert(this.translate.instant('SmartCa_token_timeout'), "", true, "", this.translate.instant('btn_login'), () => {
        const url = this.router.createUrlTree(['/app/console/signature-manager'], { queryParams: { tabName: "SMARTCA" } })
        window.open(url.toString(), '_blank')
      }, "", this.translate.instant('btn_cancel'), null, this.translate.instant('modal_title_noti'));
    })
  }

  /**
   * Lấy thông tin cấu hình chữ ký số SmartCA
   * @param id
   */
  getConfigSmartCA(id) {
    this.signatureService.getInfoSignatureConfig(id).subscribe(res => {
      this.configSmartCA = new InfoSignatureConfigResponse(res).object;
      this.setConfigSign(this.configSmartCA);
    }, err => {
      console.log(err);
      this.myAlert.showErrorHandled(err);
    })
  }

  /**
   * Thao tác ký smartCA
   */
  signBySmartCA() {
    console.log("smart ca: ", this.formSign.value);

    let param =
    {
      // "tranId": this.sessionId,
      "type": "SMART_CA",
      "signerName": this.controlAccount.userInfo.ten || this.authService.username || this.controlAccount.userInfo.sdt || "",
      "userId": this.authService.userId,
      "signerBy": this.formSign.controls.signBy.value,
      "signerDate": this.formSign.controls.signDate.value,
      "fontSize": Number(this.formSign.controls.fontSize.value),
      "visibleType": Number(this.formSign.controls.visibleType.value),
      "base64Image": this.formSign.controls.base64Image.value,
      "contracts": [],
      "certificateId": this.formSign.value.cert,
      "authOrganization": false,
    }

    // Lấy ds contractId truyền vào param api
    this.contracts.forEach((item) => {
      let p = {
        "contractId": item.contractId,
        "contractName": item.title,
        "signatures": [
        ]
      };

      item.signerSignFrame.forEach((item1: any) => {
        let x = item1.x;
        let y = item1.y - item1.h;
        let w = item1.w + x;
        let h = item1.h + y;

        p.signatures.push({
          page: item1.page,
          position: `${x},${y},${w},${h}`
        })
      })

      param.contracts.push(p);
    })

    this.showLayoutConfirmSmartCA = true;
    this.confirmSmartCA = false;
    this.isWaitingConfirmSmartCA.emit(true);

    //post sign
    this.postMultiSignSmartCA_v2(param);
  }

  /**
   * Gửi yêu cầu tạo sessionId cho phiên ký hàng loạt bằng SmartCA
   */
   postMultiSignSmartCA_v2(param) {
    this.errorService.disable();
    this.signatureService.postRequestSessionMultiSignSmartCA(param).subscribe((res: any) => {
      console.log(res);
      if (res?.object?.tranId) {
        this.sessionId = res?.object?.tranId;
        this.resetTimeoutSignSmartCA();
        this.showLayoutConfirmSmartCA = true;
        localStorage.setItem(ConstLocalStorage.SESSION_SIGN_JOB_SMARTCA, this.sessionId);
      }
    }, err => {
      if (this.count == 0) {
        this.msgError = "ECT-00000042";
      } else if (err.error && err.error.message && err.error.message == "ECT-00000035") {
        this.msgError = this.msgError;
      } else if (err.error && err.error.message && err.error.message == "ECT-00000042") {
        this.msgError = "ECT-00000042";
      } else {
        this.myAlert.showErrorHandled(err);
      }
      this.timeoutSmartCa = true;
      this.expiredSessionSmartCA.emit(true);
      this.count = 0;
      this.errorService.enable();
    })

  }

  /**
   * Submit ký hàng loạt SmartCA
   * @param param 
   */
  postMultiSignSmartCA(param) {
    this.errorService.disable();
    // this.resetTimeoutSignSmartCA();
    this.showLayoutConfirmSmartCA = true;
    this.reqSignSmartCA = this.signatureService.postMultiSignSmartCA(param)
    this.reqSignSmartCA.subscribe((res: any) => {
      console.log(res);
      // this.savedSignature.emit(true);
      // this.showLayoutConfirmSmartCA = false;
      // this.isWaitingConfirmSmartCA.emit(false);
      // this.currentStepSign = 2;
      // this.count = 0;
      // this.errorService.enable();
    }, err => {
      if (this.count == 0) {
        this.msgError = "ECT-00000042";
      } else if (err.error && err.error.message && err.error.message == "ECT-00000035") {
        this.msgError = this.msgError;
      } else if (err.error && err.error.message && err.error.message == "ECT-00000042") {
        this.msgError = "ECT-00000042";
      } else {
        this.myAlert.showErrorHandled(err);
      }
      this.timeoutSmartCa = true;
      this.expiredSessionSmartCA.emit(true);
      this.count = 0;
      this.errorService.enable();
    })
  }

  //Bo dem timeout request ky smartCA
  resetTimeoutSignSmartCA() {
    this.count = 240;
    this.timeoutSmartCa = false;
    this.expiredSessionSmartCA.emit(false);
    this.counter$ = timer(0, 1000).pipe(
      take(this.count),
      map(() => {
        --this.count;
        //API kiểm trang trạng thái transactionId
        this.getCheckTransactionMultiSignSmartCA(this.sessionId);
        if (this.count == 0 && this.reqSignSmartCA) { this.resetTimeoutSignSmartCA = null; this.timeoutSmartCa = true; this.expiredSessionSmartCA.emit(true) }
        return this.getTimer;
      })
    );
  }

  str_pad_left(string,pad,length) {
    return (new Array(length+1).join(pad)+string).slice(-length);
  }

  get getTimer() {
    var minutes = Math.floor(this.count / 60);
    var seconds = this.count - minutes * 60;

    var finalTime = this.str_pad_left(minutes,'0',2) + ':' + this.str_pad_left(seconds,'0',2);

    return finalTime;
  }

  /**
   * Set sessionID
   * @param sessionId 
   */
  setSessionId(sessionId) {
    this.sessionId = sessionId;
  }

  /**
   * Kiểm tra trạng thái transactionId
   * @param tranId 
   */
  getCheckTransactionMultiSignSmartCA(tranId = "") {
    this.loaderService.disabledLoader(true);
    this.signatureService.getCheckTransactionMultiSignSmartCA(tranId).subscribe((res: any) => {
      if (res?.object && res?.object?.signStatus == "DONE") {
        this.savedSignature.emit(true);
        this.showLayoutConfirmSmartCA = null;
        this.isWaitingConfirmSmartCA.emit(false);
        this.currentStepSign = 2;
        this.count = 0;
        this.errorService.enable();
      } else if (res?.object && res?.object?.signStatus == "ERROR") {
        this.count = 0;
        this.resetTimeoutSignSmartCA = null;
        this.timeoutSmartCa = true; 
        this.expiredSessionSmartCA.emit(true);
        this.msgError = "SmartCaMultiple_ts_1";
        this.errorService.enable();
        localStorage.removeItem(ConstLocalStorage.SESSION_SIGN_JOB_SMARTCA);
      }
      this.loaderService.disabledLoader(false);
    }, err => {
      console.log(err);
      this.loaderService.disabledLoader(false);
    })
  }
}
