export class DetailInfoItem {
    licenseId: string;
    tenKhachHang : string;
    packageName : string;
    issueDate: string;
    expirationDate : string;
    status: string;
    constructor(obj = null) {
        this.licenseId = obj.licenseId || '';
        this.tenKhachHang = obj.tenKhachHang || '';
        this.packageName = obj.packageName || '';
        this.issueDate = obj.issueDate || '';
        this.expirationDate = obj.expirationDate || '';
        this.status = obj.status || '';
    }
}

export class ContractYearItem {
    value0: Number;
    value1 : Number;
    value2: Number;
    value3 : Number;
    value4 : Number;
    value5 : Number;
    value6 : Number;
    value7 : Number;
    value8 : Number;
    value9 : Number;
    value10 : Number;
    value11: Number;
    value12: Number;
    constructor(obj = null) {
        this.value0 = obj[0];
        this.value1 = obj[1];
        this.value2 =  obj[2];
        this.value3 =  obj[3];
        this.value4 =  obj[4];
        this.value5 =  obj[5];
        this.value6 =  obj[6];
        this.value7 =  obj[7];
        this.value8 =  obj[8];
        this.value9 =  obj[9];
        this.value10 =  obj[10];
        this.value11 =  obj[11];
        this.value12 =  obj[11];

    }
}


export class ContractReportItem {
    count: number;
    currentStage: string;
    
    constructor(obj = null) {
        obj = obj || {};
        this.count = obj.count || 0
        this.currentStage = obj.currentStage || '';
    }
}