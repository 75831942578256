<app-spinner *ngIf="hideSpinner()"></app-spinner>
<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card ec-card shadow-none">
                    <div class="card-header">
                        <div class="d-flex">
                            <div class="card-title-path flex-grow-1">
                                <h1 class="m-0 text-dark card-title"><label>{{ 'EmployeeEdit_title' | translate }}</label></h1>
                            </div>
                            <div class="card-btn-path ml-auto text-right">
                                <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="cancel()"><span
                                        class="material-icons mr-1">close</span>{{ 'btn_cancel' | translate }}</button>
                                <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="setData(employee)"><span class="material-icons-outlined mr-1">
                                            refresh
                                            </span>{{ 'btn_reset' | translate }}</button>
                                <button class="btn btn-primary d-inline-flex" (click)="save()"><span
                                        class="material-icons mr-1">save</span>{{ 'btn_save' | translate }}</button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">
                                <form [formGroup]="employeeForm">
                                    <div class="form-group">
                                        <label>{{ 'full_name' | translate }} <span class="text-danger">*</span>:</label>
                                        <input appTrimValue class="form-control" placeholder="{{ 'EmployeeEdit_ph_1' | translate }}" formControlName="fullName">
                                        <app-show-validate-errors [isSubmit]="isSubmit"
                                            [errorMessages]="validateEmployeeForm.fullName" [control]="employeeForm.controls.fullName"
                                            [detail]="{ name: 'fullName' }"></app-show-validate-errors>
                                    </div>
                                    <div class="form-group">
                                        <label>{{ 'EmployeeInPart_email' | translate }} <span class="text-danger">*</span>:</label>
                                        <input appLowercaseValue appTrimValue class="form-control" placeholder="{{ 'EmployeeAdd_ph_2' | translate }}" formControlName="email">
                                        <app-show-validate-errors [isSubmit]="isSubmit"
                                            [errorMessages]="validateEmployeeForm.email" [control]="employeeForm.controls.email"
                                            [detail]="{ name: 'email' }"></app-show-validate-errors>
                                    </div>
                                    <div class="form-group">
                                        <label>{{ 'EmployeeInPart_phone_number' | translate }}  <span class="text-danger">*</span>:</label>
                                        <input appTrackerPhoneNumber onKeyPress="if(this.value.length==10) return false;" class="form-control" placeholder="{{ 'EmployeeAdd_ph_3' | translate }}" formControlName="phoneNumber">
                                        <app-show-validate-errors [isSubmit]="isSubmit"
                                            [errorMessages]="validateEmployeeForm.phoneNumber" [control]="employeeForm.controls.phoneNumber"
                                            [detail]="{ name: 'phoneNumber' }"></app-show-validate-errors>
                                    </div>
                                    <div class="form-group">
                                        <label>{{ 'EmployeeInPart_department' | translate }} <span class="text-danger">*</span>:</label>
                                        <ng-select formControlName="partyId" [items]="parts"
                                            [virtualScroll]="true"
                                            [loading]="loading"
                                            bindLabel="partyName"
                                            bindValue="partyId"
                                            placeholder="{{ 'EmployeeAdd_ph_4' | translate }}"
                                            (scroll)="onScroll($event)"
                                            (scrollToEnd)="onScrollToEnd()"
                                            (change)="changedParty($event)"
                                            (clear)="clearParty($event)">
                                            <ng-template ng-header-tmp>
                                                <small class="form-text text-muted">{{ 'EmployeeList_hien_thi_da_tai' | translate }}</small>
                                            </ng-template>
                                            <ng-template ng-option-tmp let-item="item" let-index="index">
                                                {{item.partyName}}
                                            </ng-template>
                                            <ng-template *ngIf="parts.length >= partsTotalElement" ng-footer-tmp>
                                                <small class="form-text text-muted">{{ 'EmployeeList_da_tai_het' | translate }}</small>
                                            </ng-template>
                                        </ng-select>
                                        <app-show-validate-errors [isSubmit]="isSubmit"
                                            [errorMessages]="validateEmployeeForm.partyId" [control]="employeeForm.controls.partyId"
                                            [detail]="{ name: 'partyId' }"></app-show-validate-errors>
                                    </div>
                                    <div class="form-group">
                                        <label>{{ 'EmployeeAdd_label_1' | translate }} <span class="text-danger">*</span>:</label>
                                        <ng-select 
                                            formControlName="roleId" 
                                            [items]="dropListRoleId | async" 
                                            bindLabel="name"
                                            bindValue="uuidRole" 
                                            placeholder="{{ 'EmployeeAdd_ph_5' | translate }}" 
                                            [trackByFn]="trackByFn" 
                                            [loading]="roleIdLoading"
                                            [typeahead]="roleNameInput$" 
                                            (change)="changedRoleId($event)">
                                        </ng-select>
                                        <app-show-validate-errors [isSubmit]="isSubmit"
                                            [errorMessages]="validateEmployeeForm.roleId" [control]="employeeForm.controls.roleId"
                                            [detail]="{ name: 'roleId' }"></app-show-validate-errors>
                                    </div>
                                    <div class="form-group">
                                        <label>{{ 'EmployeeDetail_label_1' | translate }}:</label>
                                        <input appTrimValue class="form-control" placeholder="{{ 'SmartcaManager_ph_2' | translate }}" formControlName="username">
                                        <app-show-validate-errors [isSubmit]="isSubmit"
                                            [errorMessages]="validateEmployeeForm.username" [control]="employeeForm.controls.username"
                                            [detail]="{ name: 'username' }"></app-show-validate-errors>
                                    </div>
                                    <div class="form-group">
                                        <label>{{ 'status' | translate }} <span class="text-danger">*</span>:</label>
                                        <ng-select [searchable]="false" placeholder="{{ 'PartList_select_status' | translate }}"
                                            formControlName="disable">
                                            <ng-option [value]="false">{{ 'status_running' | translate }}</ng-option>
                                            <ng-option [value]="true">{{ 'status_stopped' | translate }}</ng-option>
                                        </ng-select>
                                    </div>
                                </form>
                            </div>
                            <div class="col-md-6">
                                <label class="text-primary">{{ 'GroupPermissions_permission_infor' | translate }}</label>
                                <hr class="mt-0 mb-2">
                                <ul class="privilege-tree">
                                    <li *ngFor="let privilegeType of privileges">
                                        <div class="privilegeType">
                                            <span class="material-icons mr-2">expand_more</span><span>{{ ('GroupPermissions' + '_' + privilegeType.keyLanguage  + '_name') | translate }}</span>
                                        </div>
                                        <ul>
                                            <li *ngFor="let privilege of privilegeType.privileges">
                                                <span>{{ ('GroupPermissions' + '_' + privilege.keyLanguage  + '_description') | translate  }}</span>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>