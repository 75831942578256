<div class="page404content">
    <div class="col-md-11 col-lg-9 col-xl-8 lg-width">
        <div class="card shadow">
            <div class="row">
                <div class="col-md-7 left-view-bg text-center px-0">
                    <video #videobg id="videobg" loop="" muted="" playsinline="" oncanplay="this.play()">
                        <source [attr.data-src]="'assets/img/home/vid-bg.mp4'" [attr.video-src]="'assets/img/home/vid-bg.mp4'" src="assets/img/home/vid-bg.mp4" type="video/mp4">
                            <source [attr.data-src]="'assets/img/home/vid-bg.mp4'" [attr.video-src]="'assets/img/home/vid-bg.webm'" src="assets/img/home/vid-bg.mp4" type="video/mp4">
                    </video>
                    <div class="content-on-video">
                        <div class="col-12 text-center">
                            <div class="mb-4">
                                <img src="assets/img/econtract-logo.svg" width="100" height="100" style="background: white; border-radius: 16px">
                                <h1 class="headline-1">NBW eContract</h1>
                                <p class="description-1">Tiên phong nền tảng hợp đồng số</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="block-page-not-found text-center text-md-right">
                        <h1>404</h1>
                        <h1>Không tìm thấy trang</h1>
                        <p>Xin lỗi, trang bạn đang truy cập không tồn tại. Vui lòng chọn truy cập trang khác hoặc quay về trang chủ</p>
                        <button class="goto-home" (click)="goHome()">Quay về trang chủ</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</div>