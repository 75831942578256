<nav class="mt-2 mb-2">
  <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
      <li *ngIf="false"lass="nav-item has-treeview">
          <a href="#" class="nav-link nav-material">
              <span class="material-icons nav-icon">
                  recent_actors
              </span>
              <p>
                  Quản lý đăng ký
                  <i class="right fas fa-angle-left"></i>
              </p>
          </a>
          <ul class="nav nav-treeview">
              <li class="nav-item">
                  <a [routerLink]="['/console/register-manager']" routerLinkActive="active"
                      class="nav-link nav-material">
                      <span class="material-icons nav-icon">
                          fiber_manual_record
                      </span>
                      <p>Khách hàng cá nhân</p>
                  </a>
              </li>
              <li class="nav-item">
                  <a [routerLink]="['/console/enterprise-manager']" routerLinkActive="active"
                      class="nav-link nav-material">
                      <span class="material-icons nav-icon">
                          fiber_manual_record
                      </span>
                      <p>Khách hàng tổ chức</p>
                  </a>
              </li>
          </ul>
      </li>
      <li *ngIf="false" class="nav-item">
          <a [routerLink]="['/console/account-manager']" routerLinkActive="active"
              class="nav-link nav-material">
              <em class="material-icons">folder_shared</em>
              <p>
                  Quản lý khách hàng
              </p>
          </a>
      </li>

      <li *ngIf="false" class="nav-item has-treeview">
          <a href="#" class="nav-link nav-material">
              <span class="ct-icon-font icon-package_manage">
              </span>
              <p>
                  Quản lý gói dịch vụ
                  <i class="right fas fa-angle-left"></i>
              </p>
          </a>
          <ul class="nav nav-treeview">
              <li class="nav-item">
                  <a [routerLink]="[constantUrl.service_catalog_list]" routerLinkActive="active"
                      class="nav-link nav-material">
                      <span class="material-icons nav-icon">
                          fiber_manual_record
                      </span>
                      <p>{{ localize.package_service_list }}</p>
                  </a>
              </li>
              <li class="nav-item">
                  <a [routerLink]="[constantUrl.list_package]" routerLinkActive="active"
                      class="nav-link nav-material">
                      <span class="material-icons nav-icon">
                          fiber_manual_record
                      </span>
                      <p>Danh sách gói dịch vụ</p>
                  </a>
              </li>
          </ul>
      </li>

      <li class="nav-item has-treeview">
          <a href="#" class="nav-link nav-material">
              <span class="ct-icon-font icon-package_manage">
              </span>
              <p>
                  Báo cáo sản lượng
                  <i class="right fas fa-angle-left"></i>
              </p>
          </a>

          <ul class="nav nav-treeview">
              <li class="nav-item">
                  <a [routerLink]="['/console/report-customer']" routerLinkActive="active"
                      class="nav-link nav-material">
                      <span class="material-icons nav-icon">
                          fiber_manual_record
                      </span>
                      <p>Báo cáo thông tin KH</p>
                  </a>
              </li>
              <li class="nav-item">
                  <a [routerLink]="['/console/report-quantity-v1']" routerLinkActive="active"
                      class="nav-link nav-material">
                      <span class="material-icons nav-icon">
                          fiber_manual_record
                      </span>
                      <p>Báo cáo sản lượng</p>
                  </a>
              </li>
          </ul>
      </li>
      
      <li *ngIf="false" class="nav-item">
          <a [routerLink]="[constantUrl.administrative_directory]" routerLinkActive="active"
              class="nav-link nav-material">
              <span class="material-icons">
                  settings
              </span>
              <p>
                  Danh mục hành chính
              </p>
          </a>
      </li>
      
  </ul>
</nav>