import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { InfoPartyObject } from 'src/app/utils/models/aas-models/info-party/info-party-object';
import { InfoPartyResponse } from 'src/app/utils/models/aas-models/info-party/info-party-response';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { AuthService } from 'src/app/utils/services/auth.service';
import { LoadingService } from 'src/app/utils/services/loading.service';

@Component({
  selector: 'app-step-four-verify-vip',
  templateUrl: './step-four-verify-vip.component.html',
  styleUrls: ['./step-four-verify-vip.component.scss']
})
export class StepFourVerifyVipComponent implements OnInit {

  partyInfo: InfoPartyObject;

  constructor(
    private auth: AuthService,
    private aasAuth: AasAuthService,
    private modalService: NgbModal,
    public loadingService: LoadingService
  ) { }

  ngOnInit(): void {
  }

  get partyId() {
    return this.auth.partyId;
  }

  /**
   * Cho phép viewparent gọi vào để set thông tin tổ chức
   * @param value 
   */
  setPartyInfo(value) {
    this.partyInfo = new InfoPartyObject(value);
  }

  /**
   * Gọi để realod thông tin tổ chức
   */
  callBackGetPartyInfo() {
    this.getPartyInfo(this.auth.partyId);
  }

  //MARK: NETWORKING
  /**
   * Lấy thông tin tổ chức
   * @param partyId 
   */
  getPartyInfo(partyId) {
    this.aasAuth.getPartyInfo(partyId).subscribe(res => {
      this.partyInfo = new InfoPartyResponse(res).object;
    }, err => {
      this.handleError(err, ResponseMsg.MESSAGES.MANAGEMENT_SERVICE);
    }, () => {
    })
  }

  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], 'error');
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, 'error');
    } else {
      this.alert('Lỗi không xác định! Vui lòng thử lại', 'error');
    }
  }
}
