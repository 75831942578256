export class ApiListAllItem {
    apiId: string = '';
    created: string = '';
    description: string = '';
    exampleBody: string = '';
    exampleHeader: string = '';
    exampleOutput: string = '';
    exampleParam: string = '';
    method: string = '';
    modified: string = '';
    pathName: string = '';
    privilegesId: string = '';
    privilegesName: string = '';
    roleId: string = '';

    constructor(data = null) {
        data = data || {};

        this.apiId = data.apiId || '';
        this.created = data.created || '';
        this.description = data.description || '';
        this.exampleBody = data.exampleBody || '';
        this.exampleHeader = data.exampleHeader || '';
        this.exampleParam = data.exampleParam || '';
        this.exampleOutput = data.exampleOutput || '';
        this.method = data.method || '';
        this.modified = data.modified || '';
        this.pathName = data.pathName || '';
        this.privilegesId = data.privilegesId || '';
        this.privilegesName = data.privilegesName || '';
        this.roleId = data.roleId || '';
    }
}