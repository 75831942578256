export class ReportServicelItem{
    danhMucChucNang: string;
    caNhan: number;
    dichVu: number;
    giaiPhap: number;
    tongSo: number;
   

    constructor(obj = null) {
        obj = obj || {};
        this.danhMucChucNang = obj.danhmuc || '';
        this.caNhan = Number(obj.CONSUMER) || 0;
        this.dichVu = Number(obj.BUSINESS) || 0;
        this.giaiPhap = Number(obj.ENTERPRISE) || 0;
        this.tongSo = Number(obj.total) || 0;
    }

}