import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { StringUtils } from 'src/app/utils/common/StringUtils';
import { ListPackageObject } from 'src/app/utils/models/aas-models/verify-business/list-package/list-package-object.model';
import { ListPackageResponse } from 'src/app/utils/models/aas-models/verify-business/list-package/list-package-response.model';
import { PackageItem } from 'src/app/utils/models/aas-models/verify-business/list-package/package-item.model';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { BusinessPackageService } from 'src/app/utils/services/aas-network/business-package.service';
import { AuthService } from 'src/app/utils/services/auth.service';
import { LoadingService } from 'src/app/utils/services/loading.service';

@Component({
  selector: 'app-step-three-verify-vip',
  templateUrl: './step-three-verify-vip.component.html',
  styleUrls: ['./step-three-verify-vip.component.scss']
})
export class StepThreeVerifyVipComponent implements OnInit {
  @Output('next') next = new EventEmitter<any>();
  @Output('back') back = new EventEmitter<any>();

  page = 1;
  maxSize = 3;
  propertiesSort = 'created';
  sort = 'DESC';
  packageType = 'NUMBER_CONTRACT_TYPE';
  status = 'ENABLED';
  userType = 'ENTERPRISE';
  searchString = '';

  listPackage: Array<PackageItem> = [];
  listHeader: Array<any> = [];
  listBody: Array<any> = [];

  paid: boolean = false;
  packageIdSeleced: string = '';

  constructor(
    public loadingService: LoadingService,
    public packageService: BusinessPackageService,
    private aasAuth: AasAuthService,
    private authService: AuthService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.getListPackageForEndUser();
  }

  //MARK: HANDLE OUPUT
  onNext() {
    this.next.emit({
      done: true
    })
  }

  onBack() {
    this.back.emit("ok back");
  }

  onClickNextBtn() {
    const param = {
      partyId: this.authService.partyId,
      packageId: this.packageIdSeleced
    }
    this.postRegisterWaitingApprove(param);
  }

  /**
   * Chọn mua gói dịch vụ
   * @param packInfoId 
   */
  purchasePackage(packInfoId: string) {
    this.alert('Bạn chắc chắn muốn sử dụng gói dịch vụ này?', '', true, '', 'Đồng ý', () => {
      this.packageIdSeleced = packInfoId;
      this.paid = true;
    }, '', 'Hủy', () => {

    });
  }

  /**
   * Xữ lý dữ liệu gói dịch vụ hiển thị UI
   * @param data 
   */
  progressData(data: Array<PackageItem> = []) {
    let listHeader: Array<any> = [];
    let listBody: Array<any> = [];

    data.forEach((item, index) => {
      listHeader.push(item);

      item.privileges.forEach((priv, i) => {
        //Khởi tạo mảng đánh dấu chức năng active trong gói
        let activeRole = new Array<boolean>(data.length);
        for (var i = 0; i < data.length; i++) activeRole[i] = false;
        activeRole[index] = true;

        console.log(activeRole);
        let indexOld = listBody.findIndex((item) => {
          if (item.uuidPType) {
            return item.uuidPType === priv.uuidPType;
          }
        });

        if (indexOld >= 0) {
          if (listBody[indexOld] && listBody[indexOld].activeRole && listBody[indexOld].activeRole instanceof Array) {
            if (index > listBody[indexOld].activeRole.length - 1) {
              listBody[indexOld].activeRole.push(true);
            } else {
              listBody[indexOld].activeRole[index] = true;
            }
          }
        } else {
          listBody.push({
            name: priv.name,
            activeRole: activeRole
          })
        }
      })

      console.log(listBody);
    })

    this.listHeader = listHeader;
    this.listBody = listBody;
  }

  //MARK: NETWORKING
  /**
   * Lấy ds gói dịch vụ enduser có thể đăng kí sử dụng
   * @param page 
   * @param maxSize 
   * @param propertiesSort 
   * @param sort 
   * @param packageType 
   * @param status 
   * @param userType 
   * @param searchString 
   */
  getListPackageForEndUser(page = 1, maxSize = 3, propertiesSort = 'created', sort = 'DESC', packageType = 'NUMBER_CONTRACT_TYPE', status = 'ENABLED', userType = 'ENTERPRISE', searchString = '') {
    this.packageService.getListPackageForEndUser(page, maxSize, propertiesSort, sort, packageType, status, userType, searchString).subscribe((res) => {
      let data = new ListPackageResponse(res);
      this.listPackage = data.object.data;
      console.log(data);

      this.progressData(this.listPackage);
    }, err => {
      this.handleError(err, ResponseMsg.MESSAGES.ALL_IN_ONE);
    });
  }

  /**
   * Gửi yêu cầu duyệt đăng ký tổ chức VIP
   * @param data 
   */
  postRegisterWaitingApprove(data) {
    this.aasAuth.postRegisterWaitingApprove(data).subscribe(res => {
      this.onNext();
    }, err => {
      this.handleError(err, ResponseMsg.MESSAGES.ALL_IN_ONE);
    })
  }

  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], 'error');
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, 'error');
    } else {
      this.alert('Lỗi không xác định! Vui lòng thử lại', 'error');
    }
  }

}
