import { MainResponse } from './../../main-response.model';
export class BBoxOfKeyResponse extends MainResponse {
    object: BBoxKey[] = [];
    constructor(obj = null) {
        super();
        obj = obj || {};
        this.init(obj);

        this.object = [];
        if (obj.object && obj.object instanceof Array) {
            obj.object.forEach(item => {
                this.object.push(new BBoxKey(item));
            });
        }
    }
}

export class BBoxKey {
    bbox: number[] = [];
    page: number;

    constructor(obj = null) {
        obj = obj || {};
        this.bbox = [];
        if (obj.bbox && obj.bbox instanceof Array) {
            obj.bbox.forEach(item => {
                this.bbox.push(Number(item));
            });
        }
        this.page = Number(obj.page);
    }
}