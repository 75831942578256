<app-spinner></app-spinner>
<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-12">
                <div class="card ec-card shadow-none">
                    <div class="card-header">
                        <div class="d-flex">
                            <div class="card-title-path flex-grow-1">
                                <h1 class="m-0 text-dark card-title"><label>{{ 'EmployeeDetail_title' | translate }}</label></h1>
                            </div>
                            <div class="card-btn-path ml-auto text-right">
                                <button class="btn btn-primary d-inline-flex" (click)="update(id)"><span
                                        class="material-icons mr-1">edit</span>{{ 'btn_update' | translate }}</button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="row mb-2">
                                    <div class="col-md-4 mb-1">
                                        <label class="ec-lb-field">{{ 'full_name' | translate }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        <span class="ec-sp-field">{{ employee.fullName }}</span>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-4 mb-1">
                                        <label class="ec-lb-field">{{ 'EmployeeInPart_email' | translate }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        <span class="ec-sp-field">{{ employee.email }}</span>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-4 mb-1">
                                        <label class="ec-lb-field">{{ 'EmployeeInPart_phone_number' | translate }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        <span class="ec-sp-field">{{ employee.phoneNumber }}</span>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-4 mb-1">
                                        <label class="ec-lb-field">{{ 'EmployeeInPart_department' | translate }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        <span class="ec-sp-field">{{ employee.partyName }}</span>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-4 mb-1">
                                        <label class="ec-lb-field">{{ 'EmployeeAdd_label_1' | translate }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        <span class="ec-sp-field">{{ employee.roleName }}</span>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-4 mb-1">
                                        <label class="ec-lb-field">{{ 'EmployeeDetail_label_1' | translate }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        <span class="ec-sp-field">{{ employee.username || "" }}</span>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="col-md-4 mb-1">
                                        <label class="ec-lb-field">{{ 'status' | translate }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        <span class="ec-sp-field"><span class="badge {{ getClassStatus(employee.disable) }} p-2">{{ getTextStatus(employee.disable) }}</span></span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="text-primary">{{ 'GroupPermissions_permission_infor' | translate }}</label>
                                <hr class="mt-0 mb-2">
                                <ul class="privilege-tree">
                                    <li *ngFor="let privilegeType of privileges">
                                        <div class="privilegeType">
                                            <span class="material-icons mr-2">expand_more</span><span>{{ ('GroupPermissions' + '_' + privilegeType.keyLanguage  + '_name') | translate }}</span>
                                        </div>
                                        <ul>
                                            <li *ngFor="let privilege of privilegeType.privileges">
                                                <span>{{ ('GroupPermissions' + '_' + privilege.keyLanguage  + '_description') | translate  }}</span>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>