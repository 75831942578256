import { environment } from './../../../../../environments/environment.prod';
import { Injectable } from '@angular/core';
import { API } from '../../api';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { AasConsumerManagerService } from '../aas-consumer-manager.service';
import { NotificationsService } from '../notifications.service';

const WEB_PUSH_CERT_KEY_PAIR = 'BG267JFHAuQG1nwT9Wk7WNbWgbjWnL0kbAD_4spDok--L7xDkG62V1jRXIX4-pkm9dQWhwYzZPukNF3UN707Tk0';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  currentMessage = new BehaviorSubject(null);

  constructor(
    private angularFireMessaging: AngularFireMessaging,
    private aasConsumerManagerService: AasConsumerManagerService,
    private notiService: NotificationsService
  ) {
    this.angularFireMessaging.messaging.subscribe((_messaging: any) => {
      _messaging.onMessage = _messaging.onMessage.bind(_messaging);
      _messaging._next = (payload: any) => {
        console.log("Message received. ", payload);
        this.currentMessage.next(payload);
      };

      _messaging.onTokenRefresh = _messaging.onTokenRefresh.bind(
        _messaging
      );
    });
  }

  //MARK: firebase messaging
  /**
   * Request Token
   */
  requestPermission() {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log('Token firebase: ', token);

      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  /**
   * Request Register device
   * @param uuid userid
   */
  requestRegisterDevive(uuid) {
    this.angularFireMessaging.getToken.subscribe((token) => {
      // this.aasConsumerManagerService.registerDevice(token, uuid);
      const param = {
        token: token
      }
      this.notiService.postRegisterDevice(param).subscribe((res: any) => { console.log("ok")});
    },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      })
  }

  /**
   * Xoa device nhan thong bao hop dong
   * @param uuid 
   */
  removeDevice(uuid) {
    this.angularFireMessaging.getToken.subscribe((token) => {
      const param = {
        token: token
      }
      this.notiService.deleteDevice(param).subscribe((res: any) => { console.log("ok")});
    },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      })
  }

  /**
   * Request permission and eegister device
   * @param uuid userid
   */
  requestPermissionAndRegisterDevice(uuid) {
    this.angularFireMessaging.requestToken.subscribe(
      (token) => {
        console.log('Token firebase: ', token);
        // this.aasConsumerManagerService.registerDevice(token, uuid);
      },
      (err) => {
        console.error('Unable to get permission to notify.', err);
      }
    );
  }

  /** Receive message from firebase */
  receiveMessage() {
    this.angularFireMessaging.messages.subscribe(
      (payload) => {
        console.log("Message received. ", payload);
        this.currentMessage.next(payload);
      });
  }
}
