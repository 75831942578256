import { Injectable,EventEmitter } from '@angular/core';
import { HubConnection, HubConnectionBuilder, IHttpConnectionOptions, LogLevel  } from '@microsoft/signalr';
import { AuthService } from 'src/app/utils/services/auth.service'
import { Config } from 'src/app/config';

const url = Config.CHATHUB_URL;
const DEFAULT_TIMEOUT_IN_MS: number = 120 * 1000;
const DEFAULT_PING_INTERVAL_IN_MS: number = 45 * 1000;

@Injectable({
  providedIn: 'root'
})
export class AasWebsocketService {
  connectionEstablished = new EventEmitter<Boolean>();
  joinSuccessNotify = new EventEmitter<Boolean>();
  messageReceivedNotify = new EventEmitter<Boolean>();
  // reconnectingNotify = new EventEmitter<Boolean>();
  reconnectedNotify = new EventEmitter<Boolean>();
  contractId:string

  private connectionIsEstablished = false;
  private _hubConnection: HubConnection;
  authService: AuthService;
  constructor( authService: AuthService){
    this.createConnection(authService);
    this.authService = authService
    this.joinSuccess();
    this.registerOnServerEvents();
    this.startConnection();
    
  }
  
  // khởi tạo connection hub signalR
  createConnection(authService:AuthService){
    const options: IHttpConnectionOptions = {
      accessTokenFactory: () => {
        return authService.getToken();
      }
    };
    if(!this._hubConnection || this._hubConnection == null || this._hubConnection == undefined) return;
    if(url == '' || url == null || url == undefined) return;
    this._hubConnection = new HubConnectionBuilder()
      .withUrl(url,options)
      .withAutomaticReconnect([0, 2000,4000,6000,8000,10000,15000,30000])
      .configureLogging(LogLevel.Information)
      .build();

    this._hubConnection.serverTimeoutInMilliseconds = DEFAULT_TIMEOUT_IN_MS;
    this._hubConnection.keepAliveIntervalInMilliseconds = DEFAULT_PING_INTERVAL_IN_MS;
  }
  
  // connect tới signalR
  startConnection(){
    if(!this._hubConnection || this._hubConnection == null || this._hubConnection == undefined) return;
    this._hubConnection
    .start()
    .then(() => {
      this.connectionIsEstablished = true;
      // console.log('Hub connection started');
      console.log(`SignalR connection success! connectionId: ${this._hubConnection?.connectionId}`);
      this.connectionEstablished.emit(true);
    })
    .catch(err => {
      console.log('Error while establishing connection, retrying...');
      setTimeout(()=> { 
        // this.createConnection(this.authService);
        this.startConnection();
      }, 5000);
    });
  }

  stopConnection(){
    if (this._hubConnection) {
      this._hubConnection?.stop();
      this._hubConnection = null;
    }
  }

  joinRoom(contractId: string) {
    this._hubConnection?.invoke('JoinRoom', contractId).then().catch(err=>
      setTimeout(()=> this.joinRoom(contractId),2000)
      );
  }

  joinSuccess(){
    this._hubConnection?.on('JoinSuccess', (data: any,msg:any) => {
      // console.log('=============================> SUCCESS')
      this.joinSuccessNotify.emit(true);
    });
  }

  async sendMessage(message: any) {
    if (this._hubConnection?.state == 'Connected'){
      this._hubConnection?.invoke('SendMessage', message[0], message[1]);
    }
    else if (this._hubConnection?.state == 'Disconnected'){
      this.startConnection();
    }
  }

  private registerOnServerEvents(): void {
    this._hubConnection?.on('AddMessage', (data: any,msg:any) => {
      // console.log('=============>',data)
      // console.log('<=============',msg)
      this.messageReceivedNotify.emit(true);
    });
    this._hubConnection?.on('LeaveRoom', (data: any) => {
      // console.log('=============>',data)
      // this.messageReceived.emit(data);
    });
    this._hubConnection?.onclose(err=>{
    })
    // this._hubConnection.onreconnecting(err=>{
    //   this.reconnectingNotify.emit(true);
    // });
    this._hubConnection?.onreconnected(connectionId=>{
      // console.log('=============> Reconnected');
      this.reconnectedNotify.emit(true);
    })
  }

  leaveRoom(contractId){
    this._hubConnection?.invoke('LeaveRoom', contractId);
  }


}
