<app-spinner></app-spinner>
<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-auto">
                <h1 class="m-0 text-dark"><label>Danh sách thông tin API</label></h1>
            </div>
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>

<section class="content">
    <div class="container-fluid pb-3">
        <div class="content-row">
            <div class="nav-sidebar-list-api">
                <form class="mb-3 p-3">
                    <div class="input-group border rounded">
                        <div class="input-group-prepend border-0">
                            <button class="btn d-inline-flex pr-2 pl-2" type="submit"><span
                                    class="material-icons">search</span></button>
                        </div>
                        <input type="text" class="form-control border-0" placeholder="Tìm kiếm..." aria-label=""
                            aria-describedby="basic-addon1" (input)="filterMenu($event)">
                    </div>
                </form>
                <div class="nav-sidebar-list">
                    <ul class="nav-tree">
                        <li *ngFor="let item of apiMenuData" class="nav-item-api has-tree" (click)="openRoleGroup($event)">
                            <a class="nav-item-api-link">{{ item.roleName }}
                                <em class="right fas fa-angle-left"></em>
                            </a>

                            <ul *ngIf="item.listApi && item.listApi.length > 0" class="nav-tree nav-treeview">
                                <li *ngFor="let api of item.listApi" class="nav-item-api" [class.active]="apiDetail && apiDetail.apiObject && apiDetail.apiObject.apiId === api.apiId">
                                    <a class="nav-item-api-link" (click)="selectApiItem($event, api.apiId)">{{ api.description }}</a>
                                </li>
                            </ul>
                        </li>

                        <!-- <li class="nav-item-api">
                            <a class="nav-item-api-link">Chức năng 1</a>
                        </li>
                        <li class="nav-item-api has-tree">
                            <a class="nav-item-api-link">Chức năng 1
                                <em class="right fas fa-angle-left"></em>
                            </a>

                            <ul class="nav-tree nav-treeview">
                                <li class="nav-item-api">
                                    <a class="nav-item-api-link">Chức năng 1</a>
                                </li>
                                <li class="nav-item-api">
                                    <a class="nav-item-api-link">Chức năng 1</a>
                                </li>
                                <li class="nav-item-api has-tree">
                                    <a class="nav-item-api-link">Chức năng 1
                                        <em class="right fas fa-angle-left"></em>
                                    </a>
                                    <ul class="nav-tree nav-treeview">
                                        <li class="nav-item-api">
                                            <a class="nav-item-api-link">Chức năng 1</a>
                                        </li>
                                        <li class="nav-item-api">
                                            <a class="nav-item-api-link">Chức năng 1</a>
                                        </li>
                                        <li class="nav-item-api">
                                            <a class="nav-item-api-link">Chức năng 1</a>
                                        </li>
                                    </ul>
                                </li>
                                <li class="nav-item-api">
                                    <a class="nav-item-api-link">Chức năng 1</a>
                                </li>
                            </ul>
                        </li>
                        <li class="nav-item-api">
                            <a class="nav-item-api-link">Chức năng 1</a>
                        </li> -->
                    </ul>
                </div>
            </div>
            <div class="method-content">
                <div *ngIf="apiDetail && apiDetail.apiObject.apiId" class="method-detail">
                    <div class="row mt-3">
                        <div class="col-md-auto align-self-end">
                            <h3><label>{{ apiDetail.apiObject.description }}</label></h3>
                        </div>
                    </div>
                    <label>Mô tả</label>
                    <div class="description-ct" [innerHTML]="apiDetail.apiObject.description"></div>
                    <br>

                    <!-- Thông tin đầu vào -->
                    <label>Input</label>
                    <p>- URL: {{ apiDetail.apiObject.pathName }}</p>
                    <p>- Phương thức: <span class="badge badge-primary-new p-2">{{ apiDetail.apiObject.method | uppercase }}</span></p>
                    <p>- Header:</p>
                    <div class="tb-param mt-3">
                        <table class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Tham số</th>
                                    <th>Mô tả</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of paramsHeader">
                                    <td>{{ item.paramName }}</td>
                                    <td>{{ item.description }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <app-codeblock-viewer [method]="apiDetail.apiObject.method" [url]="apiDetail.apiObject.pathName"
                        [code]="apiDetail.apiObject.exampleBody" [type]="'javascript'"></app-codeblock-viewer>
                    <div class="tb-param mt-3">
                        <table class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Tham số</th>
                                    <th>Kiểu dữ liệu</th>
                                    <th>Bắt buộc</th>
                                    <th>Mô tả</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of paramsBody">
                                    <td>{{ item.paramName }}</td>
                                    <td>{{ item.type }}</td>
                                    <td>{{ item.obligatory ? 'Có' : 'Không' }}</td>
                                    <td>{{ item.description }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <br>

                    <!-- Thông tin đầu ra -->
                    <label>Output</label>
                    <p>- Trường hợp thành công:</p>
                    <app-codeblock-viewer [method]="'RESPONSE'"
                        [code]="apiDetail.apiObject.exampleOutput" [type]="'json'"></app-codeblock-viewer>
                    <div class="tb-param mt-3">
                        <table class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Tham số</th>
                                    <th>Kiểu dữ liệu</th>
                                    <th>Bắt buộc</th>
                                    <th>Mô tả</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of paramsOutputSuccess">
                                    <td>{{ item.paramName }}</td>
                                    <td>{{ item.type }}</td>
                                    <td>{{ item.obligatory ? 'Có' : 'Không' }}</td>
                                    <td>{{ item.description }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <br>

                    <!-- Thông tin mã lỗi -->
                    <p>- Mã lỗi:</p>
                    <div class="tb-param mt-3">
                        <table class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>Mã lỗi</th>
                                    <th>Mô tả</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of paramsOutputError">
                                    <td>{{ item.paramName }}</td>
                                    <td>{{ item.description }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>