export class ApiDetailObject {
    apiObject: ApiObject = null;
    listParamInfo: ListParamInforItem[] = [];

    constructor(data = null) {
        data = data || {};
        this.apiObject = new ApiObject(data.apiObject);

        this.listParamInfo = [];
        if (data.listParamInfo && data.listParamInfo instanceof Array) {
            data.listParamInfo.forEach((item) => {
                this.listParamInfo.push(new ListParamInforItem(item));
            })
        }
    }
}

export class ApiObject {
    apiId: string = '';
    pathName: string = '';
    method: string = '';
    description: string = '';
    createDate: string = '';
    updateDate: string = '';
    roleId: string = '';
    privilegesId: string = '';
    privilegesName: string = '';
    exampleBody: any = '';
    exampleParam: any = '';
    exampleHeader: any = '';
    exampleOutput: any = '';

    constructor(data = null) {
        data = data || {};
        this.apiId = data.apiId || '';
        this.pathName = data.pathName || '';
        this.method = data.method || '';
        this.description = data.description || '';
        this.createDate = data.createDate || '';
        this.updateDate = data.updateDate || '';
        this.roleId = data.roleId || '';
        this.privilegesId = data.privilegesId || '';
        this.privilegesName = data.privilegesName || '';
        this.exampleBody = data.exampleBody || '';
        this.exampleParam = data.exampleParam || '';
        this.exampleHeader = data.exampleHeader || '';
        this.exampleOutput = data.exampleOutput || '';
    }
}

export class ListParamInforItem {
    apiId: string = '';
    paramId: string = '';
    paramName: string = '';
    type: string = '';
    description: string = '';
    obligatory: string = '';
    element: string = '';

    constructor(data = null) {
        data = data || {};
        this.apiId = data.apiId || '';
        this.paramId = data.paramId || '';
        this.paramName = data.paramName || '';
        this.type = data.type || '';
        this.description = data.description || '';
        this.obligatory = data.obligatory || '';
        this.element = data.element || '';
    }
}