<app-spinner></app-spinner>
<div class="content-header">
	<div class="container-fluid">
		<div class="row mb-2">
			<div class="col-md-auto">
				<h1 class="m-0 text-dark"><label>{{ localize.title_detail_package_service }}</label></h1>
			</div>
			<!-- /.col -->
			<div class="col-md-auto ml-auto">
				<!-- <button (click)="addFlow()" class="btn btn-primary d-inline-flex mr-3"><span
                      class="material-icons mr-1">add</span> Thêm mới</button> -->
			</div>
			<!-- <!-- /.col -->
		</div>
		<!-- /.row -->
	</div>
	<!-- /.container-fluid -->
</div>

<section class="content">
	<div class="container-fluid">
		<div class="card shadow-none mb-4">
			<div class="card-body">

				<div class="row align-items-center">
                    <div class="col-md-6">
                        <div class="form-group mb-0">
                            <ng-select
								[(ngModel)]="packInfoId"
                                [items]="dropListPackageData | async"
                                bindLabel="label"
                                bindValue="id"
                                placeholder="Chọn gói dịch vụ"
                                (change)="changedPackage($event)"
								[trackByFn]="trackByFn" 
								[loading]="packageLoading"
								[typeahead]="packageInput$"
								(scrollToEnd)="onScrollToEndDropListPackage()"
                            ></ng-select>
                        </div>
                    </div>
					<div class="col-md-auto ml-auto">
                        <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="viewUserIsUsing()">
							<em class="material-icons nav-icon mr-1">visibility</em>
							Khách hàng sử dụng
						</button>
						<button class="btn btn-outline-primary d-inline-flex mr-3" (click)="actDelete()">
							<em class="material-icons nav-icon mr-1">delete</em>
							Xóa
						</button>
						<button class="btn btn-primary d-inline-flex" (click)="actEdit()">
							<em class="material-icons nav-icon mr-1">edit</em>
							Chỉnh sửa
						</button>
					</div>
				</div>
				<hr class="my-4">

                <div class="d-flex mt-3">
					<h6 class="text-primary font-weight-bold m-0">Thông tin chung</h6>
					<div class="col-md-auto ml-auto">
					</div>
				</div>
				<hr class="my-3">
				<div class="row mb-2">
					<div class="col-md-4 col-lg-3">
                        <label class="font-weight-bold">Tên gói dịch vụ</label>
					</div>
                    <div class="col-md-8 col-lg-9">
                        <span>{{ package?.packageInfo?.name }}</span>
					</div>
				</div>
                <div class="row mb-2">
					<div class="col-md-4 col-lg-3">
                        <label class="font-weight-bold">Loại gói dịch vụ</label>
					</div>
                    <div class="col-md-8 col-lg-9">
                        <span>{{ constPackageType[package?.packageInfo?.packageType] || "" }}</span>
					</div>
				</div>
                <div class="row mb-2">
					<div class="col-md-4 col-lg-3">
                        <label class="font-weight-bold">Loại khách hàng áp dụng</label>
					</div>
                    <div class="col-md-8 col-lg-9">
                        <span>{{ constCustomerType[package?.packageInfo?.userType] || "" }}</span>
					</div>
				</div>
                <div class="row mb-2">
					<div class="col-md-4 col-lg-3">
                        <label class="font-weight-bold">Thời hạn sử dụng</label>
					</div>
                    <div class="col-md-8 col-lg-9">
                        <span>{{ package?.packageInfo?.duration || 0 }} tháng</span>
					</div>
				</div>
                <div class="row mb-2">
					<div class="col-md-4 col-lg-3">
                        <label class="font-weight-bold">Thứ tự hiển thị</label>
					</div>
                    <div class="col-md-8 col-lg-9">
                        <span>{{ package?.packageInfo?.orderNumber }}</span>
					</div>
				</div>
                <div class="row mb-2">
					<div class="col-md-4 col-lg-3">
                        <label class="font-weight-bold">Giá tiền</label>
					</div>
                    <div class="col-md-8 col-lg-9">
                        <span>{{ package?.packageInfo?.price | number }}</span>
					</div>
				</div>
                <div class="row mb-2">
					<div class="col-md-4 col-lg-3">
                        <label class="font-weight-bold">Mô tả</label>
					</div>
                    <div class="col-md-8 col-lg-9">
                        <span>{{ package?.packageInfo?.description }}</span>
					</div>
				</div>
                <div class="row mb-2">
					<div class="col-md-4 col-lg-3">
                        <label class="font-weight-bold">Trạng thái</label>
					</div>
                    <div class="col-md-8 col-lg-9">
                        <span [innerHTML]="getStatusSpan(package?.packageInfo?.status)"></span>
					</div>
				</div>
                <div class="row mb-2">
					<div class="col-md-4 col-lg-3">
                        <label class="font-weight-bold">Thời gian cập nhật</label>
					</div>
                    <div class="col-md-8 col-lg-9">
                        <span>{{ package?.packageInfo?.modified | date: "dd/MM/yyyy" }}</span>
					</div>
				</div>
                <div class="row mb-2">
					<div class="col-md-4 col-lg-3">
                        <label class="font-weight-bold">Người cập nhật</label>
					</div>
                    <div class="col-md-8 col-lg-9">
                        <span>{{ package?.packageInfo?.createUser }}</span>
					</div>
				</div>

				<div class="d-flex mt-3">
					<h6 class="text-primary font-weight-bold m-0">Thông tin hạ tầng</h6>
					<div class="col-md-auto ml-auto">
					</div>
				</div>
				<hr class="my-3">
				<div class="row mb-2">
					<div class="col-md-4 col-lg-3">
                        <label class="font-weight-bold">Loại danh mục hạ tầng</label>
					</div>
                    <div class="col-md-8 col-lg-9">
                        <span>{{ package?.listPackageInfrastructure?.name }}</span>
					</div>
				</div>
				<div class="row">
					<div class="col-md-12">
                        <div *ngIf="package?.listPackageInfrastructure?.infrastructures?.length > 0" class="row">
                            <div class="col-md-12">
                                <div class="table-responsive">
                                    <table class="table mb-0">
                                        <thead>
                                            <tr class="bg-tb-header border-top text-left">
                                                <th>{{ localize.stt }}</th>
                                                <th>{{ localize.parameter }}</th>
                                                <th>{{ localize.unit }}</th>
                                                <th>{{ localize.quantity }}</th>
                                                <th>{{ localize.unit_price }} (VNĐ)</th>
                                                <th>{{ localize.into_money }}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of package?.listPackageInfrastructure?.infrastructures; let i = index">
                                                <td>{{ item.stt }}</td>
                                                <td>{{ item.name }}</td>
                                                <td>{{ item.unit }}</td>
                                                <td>
                                                    <span>{{ item.unitNumber }}</span>
                                                </td>
                                                <td>
                                                    <span *ngIf="item.param !== 'STORAGE_TIME'">{{ item.unitPrice }}</span>
                                                </td>
                                                <td>
                                                    <span *ngIf="item.param !== 'STORAGE_TIME'">{{ item.price }}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td colspan="4">Tổng cộng</td>
                                                <td>{{ totalPrice }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
					</div>
				</div>

				<div class="d-flex mt-3">
					<h6 class="text-primary font-weight-bold m-0">Thông tin chức năng</h6>
					<div class="col-md-auto ml-auto">
					</div>
				</div>
				<hr class="my-3">
				<div class="row">
					<div class="col-md-12">
						<span *ngIf="package?.packageInfo?.packageType === 'EXTEND_TYPE'">Thông tin chức năng của gói mở rộng sẽ được lấy theo thông tin chức năng hiện tại của khách hàng </span>
						<div *ngIf="package?.packageInfo?.packageType !== 'EXTEND_TYPE'" class="row">
							<div class="col-12">
								<app-dual-list #duallist 
									[items]="items_dualist" 
									[listAssigned]="items_dualist_assigned" 
									[disabled]="true"
									[onlyView]="true">
								</app-dual-list>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>

</section>

<ng-template #userIsUsing let-c="close" let-d="dismiss">
	<div class="modal-header border-0">
		<h5 class="modal-title" style="font-weight: 700; margin-right: auto;">Danh sách khách hàng đang dùng gói</h5>
		<button type="button" class="close" aria-label="Close"  (click)="d('Cross click')">
			<span aria-hidden="true" class="btn-light btn-alert-exit bg-exit-btn d-inline-flex p-2 rounded">
				<img src="assets/img/icon/icon-close.svg" width="18" height="18">
			</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="table-responsive table table-scroll mb-0">
			<table class="table main-table border mb-0">
				<thead>
					<tr class="bg-tb-header">
						<th>STT</th>
						<th>Tên tổ chức</th>
						<th>Email</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let item of usersAreUsing; let i = index">
						<td>{{ ((pageUser - 1) * maxSizeUser) + i + 1 }}</td>
						<td>{{ item?.TENTOCHUC || "" }}</td>
						<td>{{ item?.EMAIL || "" }}</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="bg-tb-header p-3">
			<div class="row align-items-center">
				<span *ngIf="totalElementUser == 0" class="col-md">Không tìm thấy kết quả</span>
				<span *ngIf="totalElementUser > 0" class="col-md">Hiển thị 1 đến {{ usersAreUsing.length }} trong tổng số
					{{totalElementUser}} kết quả</span>
				<div class="col-md">
					<div class="d-flex justify-content-end">
						<!-- <div class="pagination pages no-border-pagination ml-2">
							<ngb-pagination [collectionSize]="totalElementUser" [(page)]="pageUser" [maxSize]="5" [rotate]="true"
								[ellipses]="false" [pageSize]="maxSizeUser" (pageChange)="loadListUsed($event)" [boundaryLinks]="true"
								aria-label="Default pagination">
								<ng-template ngbPaginationFirst>Đầu</ng-template>
								<ng-template ngbPaginationLast>Cuối</ng-template>
								<ng-template ngbPaginationPrevious>&laquo;</ng-template>
								<ng-template ngbPaginationNext>&raquo;</ng-template>
								<ng-template ngbPaginationNumber let-page>{{ pageUser }}</ng-template>
							</ngb-pagination>
						</div> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</ng-template>