export const localize_verify_signature = {
    page_verify_signature_title: "Xác minh chữ ký",
    file_have_signature: "File có chữ ký xác minh",
    info_verify: "Thông tin xác minh",
    not_allow_file_type: "File đính kèm không đùng định dạng",
    verify_name_file: "Tên file",
    verify_result: "Kết quả xác minh",
    verify_result_decrip: "VNPT eContract không tin tưởng chữ ký này",
    verify_toan_ven: "Tính toàn vẹn",
    verify_toan_ven_decrip: "File đính kèm không bị thay đổi nội dung sau khi ký",
    verify_ko_toan_ven_decrip: "File đính kèm đã bị thay đổi nội dung sau khi ký",
    d_sign_type: "Ký số",
    e_sign_ekyc_type: "OTP+eKYC",
    e_sign_otp_type: "OTP",
    verify_tutorials: "Hướng dẫn",
    verify_tutorials_line_1: "1. Click vào ô để upload file hợp đồng có chữ ký cần xác minh (file có định dạng PDF)",
    verify_tutorials_line_2: "2. Click vào nút xác minh để xem thông tin xác minh chữ ký và tính toàn vẹn của file đính kèm, thông tin sẽ được hiển thị ở bên phải màn hình.",
    verify_tutorials_line_3: "3. Nếu chữ ký hợp lệ, tiếp tục click vào nút Đối chiếu thông tin để kiểm tra chéo với thông tin khách hàng đó ở hệ thống eContract",
    btn_commpare_cert: "Đối chiếu thông tin",
    btn_verify: "Xác minh",
    sign_type: "Hình thức chữ ký",
    signer_name: "Người ký",
    signer_phone_number: "Số điện thoại",
    signer_personal_id: "Số giấy tờ",
    sign_date: "Ngày ký",
    verify_toan_ven_file: "Tính toàn vẹn của file",
    signer_taxnumber: "Mã số thuế",
    sign_by: "Ký bởi",
    verify_signature_ok: "Chữ ký không bị thay đổi sau khi ký",
    verify_signature_not_ok: "Chữ ký đã bị thay đổi sau khi ký",
    signature_trust: "Độ tin cậy",
    verify_signature_trusted: "Chữ ký được eContract tin cậy",
    verify_signature_untrust: "Chữ ký không được eContract tin cậy",
    result_compare_signature: "Kết quả đối chiếu",
    result_compare_ok: "Các thông tin họ tên, số điện thoại của bên ký trùng khớp với thông tin đăng ký tại VNPT eContract",
    result_compare_notok: "Không tồn tại tài khoản nào ở VNPT eContract trùng khớp với thông tin bên ký",
    image_signature: "Ảnh chữ ký",
    image_signer: "Ảnh người ký"
}