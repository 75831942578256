<div class="wrapper">
    <app-aas-header (toggleMenuSidebar)="toggleMenuSidebar()">
    </app-aas-header>

    <app-aas-menu-sidebar (mainSidebarHeight)="mainSidebarHeight($event)">
    </app-aas-menu-sidebar>

    <div #contentWrapper class="content-wrapper">
        <router-outlet></router-outlet>
    </div>

    <app-aas-footer>
    </app-aas-footer>
    <aside class="control-sidebar control-sidebar-dark"></aside>
    <div id="sidebar-overlay" (click)="toggleMenuSidebar()"></div>
</div>
<div id="ppi" style="height: 1in; width: 1in; position: absolute; top: -100%; left: -100%;">
</div>