import { MainResponse } from '../../../../main-response.model';
import { CategoryPackageItem } from './category-package-item.model';


export class CategoryPackageObjectResponse extends MainResponse {
    constructor(obj = null){
        super();
        obj = obj || {};
        this.init(obj);
        this.object = new CategoryPackageItem(obj.object);
    }
}