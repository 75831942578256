<div class="wrapper">
    <div class="preview-overlay">

        <div class="preview-container">
            <div class="preview-body">
                <div class="pv ct">
                    <div class="pv-body">
                        <div class="ctpv-container">
                            <div class="ctpv-content">
                                <div class="ct-container">
                                    <div class="ct-content" style="left: 0px;">
                                        <div class="fireworks-example"></div>
                                        <div class="ct-sub-content" style="left: 0px;">
                                            <!-- <div class="l-view-backdrop">
                                                <div class="l-view-content" [style]="getHeightPageView">
                                                    <div id="parentView" class="ct-doc-loaded ct-enable-scroll">
        
                                                    </div>
                                                    <div id="thumbnail-page" class="d-none">
        
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="r-view-backdrop">
                                                <div class="r-view-content">
                                                    <div class="d-flex justify-content-between">
                                                        <div class="l-logo">
                                                            <img src="assets/img/vn-logo.png" width="160" height="160">
                                                        </div>
            
                                                        <div class="r-logo">
                                                            <img src="assets/img/vnpt-logo.png" width="110" height="166">
                                                        </div>
                                                    </div>
                                                    <div class="center-text text-center">
                                                        <div class="">
                                                            <h1 class="header-1">Lễ ký kết thỏa thuận hợp tác</h1>
                                                            <h1 class="header-2">giữa ubnd tỉnh lào cai và tập đoàn bưu chính viễn thông việt nam
                                                                giai đoạn 2021-2026</h1>
                                                            <p class="date-view">{{ place }}, ngày {{ currentDate.getDay() + 1 }} tháng {{ currentDate.getMonth() + 1 }} năm {{ currentDate.getFullYear() }}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                
                                            </div> -->
                                            <div class="center-view-backdrop">
                                                <div class="r-view-content">
                                                    <div class="block-view d-flex justify-content-between">
                                                        <div class="l-logo">
                                                            <img src="assets/img/laocai-logo.png" width="160" height="160">
                                                            <span class="party-name">ỦY BAN NHÂN DÂN TỈNH LÀO CAI</span>
                                                        </div>
            
                                                        <div class="r-logo">
                                                            <img src="assets/img/vnpt-logo-white.png" width="110" height="166">
                                                            <span class="party-name">TẬP ĐOÀN BƯU CHÍNH VIỄN THÔNG VIỆT NAM</span>
                                                        </div>
                                                    </div>
                                                    <div class="block-view center-text text-center px-5">
                                                        <div class="">
                                                            <h1 class="header-1">Lễ ký kết</h1>
                                                            <h1 class="header-2">thỏa thuận hợp tác giữa ubnd tỉnh lào cai <br> và tập đoàn bưu chính viễn thông việt nam
                                                            </h1>
                                                            <p class="date-view">{{ place }}, ngày 05 tháng 8 năm 2021</p>
                                                        </div>
                                                    </div>
                                                    <div class="block-view d-flex justify-content-center mt-5">
                                                        <div class="view-signbox mr-2">
                                                            <div class="img-sign-title">
                                                                ĐẠI DIỆN UBND TỈNH LÀO CAI
                                                            </div>
                                                            <div class="flex-grow-1">
                                                                <div class="img-sign d-flex justify-content-center align-items-center w-100 h-100 bg-white">
                                                                    <img *ngIf="getImageSign('demo_laocai')" src="{{ img_prefix + getImageSign('demo_laocai') }}" width="200" height="auto"> 
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="view-signbox ml-2">
                                                            <div class="img-sign-title">
                                                                ĐẠI DIỆN TẬP ĐOÀN BƯU CHÍNH VIỄN THÔNG VIỆT NAM
                                                            </div>
                                                            <div class="flex-grow-1">
                                                                <div class="img-sign d-flex justify-content-center align-items-center w-100 h-100 bg-white">
                                                                    <img *ngIf="getImageSign('demo_vnpt')" src="{{ img_prefix + getImageSign('demo_vnpt') }}" width="200" height="auto"> 
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<div id="ppi"
style="height: 1in; width: 1in; position: absolute; top: -100%; left: -100%;">
</div>
<div id="mark-ppi" style="height: 1in; width: 1in; position: absolute; top: -100%; left: -100%;"></div>