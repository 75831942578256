import { ServiceCatalog } from "../service-catalog/service-catalog";

export class PackageDetail {
    packageInfo: PackageInfo = null;
    listPackageService: any = [];
    listPackageInfrastructure: ServiceCatalog = null;
    totalPrice: any = 0;

    constructor(d = null) {
        d = d || {};
        this.packageInfo = new PackageInfo(d.packageInfo || {});
        if (d.listPackageService && d.listPackageService instanceof Array) {
            this.listPackageService = d.listPackageService || [];
        }
        this.listPackageInfrastructure = new ServiceCatalog(d.listPackageInfrastructure);
        this.totalPrice = d.totalPrice  || 0;
    }
}

export class PackageInfo {
    capacity: any = "";
    contractNumber: any = 0;
    createUser: any = "";
    created: any = "";
    description: any = "";
    duration: any = 0;
    formContractId: any = "";
    modified: any = "";
    name: any = "";
    orderNumber: any = 0;
    packInfoId: any = "";
    packageCode: any = "";
    packageType: any = "";
    price: any = 0;
    roleId: any = "";
    status: any = "";
    userAmount: any = "";
    userType: any = "";

    constructor(d = null) {
        d = d || {};
        this.capacity = d.capacity || "";
        this.contractNumber = d.contractNumber;
        this.createUser = d.createUser || "";
        this.created = d.created || "";
        this.description = d.description || "";
        this.duration = d.duration;
        this.formContractId = d.formContractId || "";
        this.modified = d.modified || "";
        this.name = d.name || "";
        this.orderNumber = d.orderNumber;
        this.packInfoId = d.packInfoId || "";
        this.packageCode = d.packageCode || "";
        this.packageType = d.packageType || "";
        this.price = d.price;
        this.roleId = d.roleId || "";
        this.status = d.status || "";
        this.userAmount = d.userAmount || "";
        this.userType = d.userType || "";
    }
}