import { Injectable } from '@angular/core';
import { API } from '../api';
import { ServicePath } from '../../common/constant-service-api';
import { LanguageItem } from '../../models/landing-page/language-item.model';

//category
const ADD_CATEGORY_URI = ServicePath.LANDING_PAGE_SERVICE + '/api/v1/Admin/add-category';
const EDIT_CATEGORY_URI = ServicePath.LANDING_PAGE_SERVICE + '/api/v1/Admin/edit-category';
const GET_CATEGORY_BY_ID_URI = ServicePath.LANDING_PAGE_SERVICE + '/api/v1/Admin/get-category';
const GET_CATEGORY_TREE_URI = ServicePath.LANDING_PAGE_SERVICE + '/api/v1/Admin/get-categories-tree';
const GET_CATEGORY_URI = ServicePath.LANDING_PAGE_SERVICE + '/api/v1/Admin/get-categories';
const DELETE_CATEGORY_URI = ServicePath.LANDING_PAGE_SERVICE + '/api/v1/Admin/delete-category';
const GET_CATEGORY_WITHOUT_ID_URI = ServicePath.LANDING_PAGE_SERVICE + '/api/v1/Admin/get-categories-without-id';
//blog type
const ADD_BLOG_TYPE_URI = ServicePath.LANDING_PAGE_SERVICE + '/api/v1/Admin/add-blog-type';
const EDIT_BLOG_TYPE_URI = ServicePath.LANDING_PAGE_SERVICE + '/api/v1/Admin/edit-blog-type';
const DELETE_BLOG_TYPE_URI = ServicePath.LANDING_PAGE_SERVICE + '/api/v1/Admin/delete-blog-type';
const GET_BLOG_TYPE_BY_ID_URI = ServicePath.LANDING_PAGE_SERVICE + '/api/v1/Admin/get-blog-type';
const GET_BLOG_TYPE_URI = ServicePath.LANDING_PAGE_SERVICE + '/api/v1/Admin/get-blog-types';
//blog
const ADD_BLOG_URI = ServicePath.LANDING_PAGE_SERVICE + '/api/v1/Admin/add-blog';
const EDIT_BLOG_URI = ServicePath.LANDING_PAGE_SERVICE + '/api/v1/Admin/edit-blog';
const DELETE_BLOG_URI = ServicePath.LANDING_PAGE_SERVICE + '/api/v1/Admin/delete-blog';
const GET_BLOG_BY_ID_URI = ServicePath.LANDING_PAGE_SERVICE + '/api/v1/Admin/get-blog-detail';
const GET_BLOG_URI = ServicePath.LANDING_PAGE_SERVICE + '/api/v1/Admin/get-blogs';
//config
const ADD_CONFIG_URI = ServicePath.LANDING_PAGE_SERVICE + '/api/v1/Admin/add-config';
const EDIT_CONFIG_URI = ServicePath.LANDING_PAGE_SERVICE + '/api/v1/Admin/edit-config';
const DELETE_CONFIG_URI = ServicePath.LANDING_PAGE_SERVICE + '/api/v1/Admin/delete-config';
const GET_CONFIG_BY_KEY_URI = ServicePath.LANDING_PAGE_SERVICE + '/api/v1/Admin/get-config';
const GET_CONFIG_URI = ServicePath.LANDING_PAGE_SERVICE + '/api/v1/Admin/get-configs';
//upload
const UPLOAD_URI = ServicePath.LANDING_PAGE_SERVICE + '/api/v1/Admin/upload';
//CATegory
const GET_CATEGORIES_BY_PARENT_CODE =
  ServicePath.LANDING_PAGE_SERVICE +
  '/api/Catalog/get-child-category-by-parent-code?code=';
const GET_BLOGS_BY_CODE =
  ServicePath.LANDING_PAGE_SERVICE + '/api/Catalog/get-category-by-code?key=';


@Injectable({
  providedIn: 'root'
})
export class LandingpageService {

  constructor(
    private api: API
  ) { } 

  /*
  Danh mục tin
   */
  addCategory(data){
    return this.api.post(ADD_CATEGORY_URI, data);
  }

  editCategory(data){
    return this.api.put(EDIT_CATEGORY_URI, data);
  }

  getCategoryById(id){
    return this.api.get(GET_CATEGORY_BY_ID_URI + `/${id}`);
  }

  getCategoryTree(){
    return this.api.get(GET_CATEGORY_TREE_URI);
  }

  getCategories(){
    return this.api.get(GET_CATEGORY_URI);
  }

  deleteCategory(id){
    return this.api.delete(DELETE_CATEGORY_URI + `/${id}`);
  }

  getCategoriesWithoutId(id){
    return this.api.get(GET_CATEGORY_WITHOUT_ID_URI + `/${id}`);
  }
  /*
  Loại tin
   */

  addBlogType(data){
    return this.api.post(ADD_BLOG_TYPE_URI, data);
  }

  editBlogType(data){
    return this.api.put(EDIT_BLOG_TYPE_URI, data);
  }

  getBlogTypeById(id){
    return this.api.get(GET_BLOG_TYPE_BY_ID_URI + `/${id}`);
  }

  getBlogTypes(){
    return this.api.get(GET_BLOG_TYPE_URI);
  }

  deleteBlogType(id){
    return this.api.delete(DELETE_BLOG_TYPE_URI + `/${id}`);
  }

  /*Danh sách tin bài*/
  addBlog(data){
    return this.api.post(ADD_BLOG_URI, data);
  }

  editBlog(data){
    return this.api.put(EDIT_BLOG_URI, data);
  }

  getBlogById(id){
    return this.api.get(GET_BLOG_BY_ID_URI + `/${id}`);
  }

  getBlogs(page = 1, pageSize = 10, keyword = "", category = "", blogType = -1){
    return this.api.get(GET_BLOG_URI+`?PageNumber=${page}&PageSize=${pageSize}&Keyword=${keyword}&category=${category}&blogType=${blogType}`);
  }

  deleteBlog(id){
    return this.api.delete(DELETE_BLOG_URI + `/${id}`);
  }

  /*Danh sách cấu hình*/
  addConfig(data){
    return this.api.post(ADD_CONFIG_URI, data);
  }

  editConfig(data){
    return this.api.put(EDIT_CONFIG_URI, data);
  }

  getConfigByKey(key){
    return this.api.get(GET_CONFIG_BY_KEY_URI + `/${key}`);
  }

  getConfigs(){
    return this.api.get(GET_CONFIG_URI);
  }

  deleteConfig(key){
    return this.api.delete(DELETE_CONFIG_URI + `/${key}`);
  }

  //upload file
  uploadFile(file: File){
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.api.postMultiPartJSON(UPLOAD_URI, formData);
  }

  getLanguage(): Array<LanguageItem>{
    let items = Array<LanguageItem>();
    items.push(new LanguageItem({key: 'vi', value: 'Tiếng Việt'}))
    items.push(new LanguageItem({key: 'en', value: 'Tiếng Anh'}))
    return items;
  }

    //catalog
    getChildCatagoryByParentCode(code, language) {
      const url = GET_CATEGORIES_BY_PARENT_CODE + `${code}&language=${language}`;
      return this.api.get(url);
    }
    getBlogsByParentKey(key, language) {
      const url = GET_BLOGS_BY_CODE + `${key}&language=${language}`;
      return this.api.get(url);
    }
  
}
