<app-spinner></app-spinner>
<section class="container-fluid px-0">
    <div class="row m-0">
        <div class="col-xl-9 col-lg-8 col-md-7">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-md-auto">
                            <h1 class="m-0 text-dark"><label>Dashboard</label></h1>
                        </div>
                    </div>
                    <!-- /.row -->
                </div>
                <!-- /.container-fluid -->
            </div>
        
            <!-- <section class="content">
                <div class="container-fluid">
                    <div class="col-12">
                        <app-package-noti-block [screen]="'ENDUSER_DASHBOARD'"></app-package-noti-block>
                    </div>
                    <div *ngIf="isStepEkyc" class="update-gpkd-block">
                        <div class="card b-radirus-8px shadow-none">
                            <div class="card-body">
                                <div class="d-flex align-items-center">
                                    <div class="desc-block flex-grow-1">
                                        <h3><label>{{ 'ServiceDashboardEnduser_greeting' | translate }} {{ partyInfo.userInformations.ten || partyInfo.userInformations.username }},</label></h3>
                                        <p class="">{{ 'ServiceDashboardEnduser_checkEmailPlease' | translate }}</p>
                                        <a href="https://appstore.hcmtelecom.vn/econtract/" target="_blank">
                                            <button class="btn btn-orange d-flex align-items-center">
                                                <span class="material-icons mr-2">file_download</span> {{ 'ServiceDashboardEnduser_downloadApp' | translate }}
                                            </button>
                                        </a>
                                    </div>
                                    <div class="img-thumb-block d-none d-md-inline text-center py-3">
                                        <img src="assets/img/verify-account.svg" width="200">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="isStepUploadGPKD" class="update-gpkd-block">
                        <div class="card b-radirus-8px shadow-none">
                            <div class="card-body">
                                <div class="d-flex align-items-center">
                                    <div class="desc-block flex-grow-1">
                                        <h3><label>{{ 'ServiceDashboardEnduser_greeting' | translate }} {{ partyInfo.userInformations.ten || partyInfo.userInformations.username }},</label></h3>
                                        <p class="">{{ 'ServiceDashboardEnduser_welcome1' | translate: {tenToChuc: partyInfo.tenToChuc} }}</p>
                                        <button (click)="goToUploadGPKD()" class="btn btn-orange d-flex align-items-center">
                                            <span class="material-icons mr-2">publish</span> {{ 'ServiceDashboardEnduser_uploadGpkd' | translate }}
                                        </button>
                                    </div>
                                    <div class="img-thumb-block d-none d-md-inline text-center py-3">
                                        <img src="assets/img/verify-account.svg" width="200">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div *ngIf="haveContract" class="contract-block">
                        <div class="card b-radirus-8px shadow-none">
                            <div class="card-body">
                                <div class="d-flex align-items-center">
                                    <div class="desc-block flex-grow-1">
                                        <h3><label>{{ 'ServiceDashboardEnduser_greeting' | translate }} {{ partyInfo.userInformations.ten || partyInfo.userInformations.username }},</label></h3>
                                        <p class="">{{ 'ServiceDashboardEnduser_welcome2' | translate }}</p>
                                        <button class="btn btn-orange d-flex align-items-center" (click)="signContract(partyInfo.contractVipId)">
                                            <span class="ct-icon-font icon-pen mr-2"></span> {{ 'ServiceDashboardEnduser_signContract' | translate }}
                                        </button>
                                    </div>
                                    <div class="img-thumb-block d-none d-md-inline text-center py-3">
                                        <img src="assets/img/verify-account.svg" width="200">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    <div *ngIf="approvedBlock" class="approved-block">
                        <div class="card b-radirus-8px shadow-none">
                            <div class="card-body">
                                <div class="d-flex align-items-center">
                                    <div class="desc-block flex-grow-1">
                                        <h3><label>{{ 'ServiceDashboardEnduser_greeting' | translate }} {{ userInfo.ten || userInfo.username }},</label></h3>
                                        <p class="">{{ 'ServiceDashboardEnduser_welcome3' | translate }}</p>
                                        
                                    </div>
                                    <div class="img-thumb-block d-none d-md-inline text-center py-3">
                                        <img src="assets/img/verify-account.svg" width="200">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="waitApproveBlock" class="default-block">
                        <div class="card b-radirus-8px shadow-none">
                            <div class="card-body">
                                <div class="d-flex align-items-center">
                                    <div class="desc-block flex-grow-1">
                                        <h3><label>{{ 'ServiceDashboardEnduser_greeting' | translate }} {{ userInfo.ten || userInfo.username }},</label></h3>
                                        <p class="">{{ 'ServiceDashboardEnduser_welcome4' | translate }}</p>
                                        
                                    </div>
                                    <div class="img-thumb-block d-none d-md-inline text-center py-3">
                                        <img src="assets/img/verify-account.svg" width="200">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> -->

            <section class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-4" *ngIf="quantityInfo">
                            <div class="card  shadow-none">
                                <div class="card-body">
                                    <div class="d-flex justify-content-between">
                                        <div class="p-2 bd-highlight"><label>{{ 'ServiceDashboardEnduser_contractAmount' | translate }}</label></div>
                                        <div class="p-2 bd-highlight">
                                            <div class="badge badge-primary-new badge-pd"><span class="text-primary">{{totalContract}} {{ 'ServiceDashboardEnduser_contract' | translate }}</span></div>
                                        </div>
                                    </div>
                                    <canvas baseChart 
                                    [labels]="barChartLabels" 
                                    [datasets]="barChartData"
                                    [options]="barChartOption" 
                                    [chartType]="barChartType">
                                    </canvas>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 " *ngIf="quantityInfo">
                            <div class="card  shadow-none">
                                <div class="card-body">
                                    <div class="d-flex justify-content-between">
                                        <div class="p-2 bd-highlight"><label>{{ 'ServiceDashboardEnduser_accountAmount' | translate }}</label></div>
                                        <div class="p-2 bd-highlight">
                                            <div class="badge badge-primary-new badge-pd"><span class="text-primary">{{totalAccount}} {{ 'ServiceDashboardEnduser_account' | translate }}</span></div>
                                        </div>
                                    </div>
                                    <canvas baseChart 
                                    [chartType]="accountChartType" [datasets]="accountChartData"
                                    [labels]="accountChartLabels" [options]="accountChartOption" >
                                    </canvas>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 " *ngIf="quantityInfo">
                            <div class="card  shadow-none">
                                <div class="card-body">
                                    <div class="d-flex justify-content-between">
                                        <div class="p-2 bd-highlight"><label>{{ 'ServiceDashboardEnduser_storage' | translate }}</label></div>
                                        <div class="p-2 bd-highlight">
                                            <div class="badge badge-primary-new badge-pd"><span class="text-primary">{{totalData}}GB</span></div>
                                        </div>
                                    </div>
                                    <canvas baseChart 
                                    [chartType]="capacityChartType" [datasets]="capacityChartData"
                                    [labels]="capacityChartLabels" [options]="capacityChartOption" >
                                    </canvas>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="content">
                <div class="container-fluid pb-3">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-4">
                                    <app-cwv-contract-admin [type]="1"></app-cwv-contract-admin>
                                </div>
                                <div class="col-md-4">
                                    <app-cwv-contract-admin [type]="2"></app-cwv-contract-admin>
                                </div>
                                <div class="col-md-4">
                                    <app-cwv-contract-admin [type]="3"></app-cwv-contract-admin>
                                </div>
                            </div>
                            <div>
                                <app-stats-by-time-admin></app-stats-by-time-admin>
                            </div>
                        </div>
                        <div class="col-md-4">
                                <app-stats-by-status-admin></app-stats-by-status-admin>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-5">
                            <app-number-of-partners-admin></app-number-of-partners-admin>
                        </div>
                        <div class="col-md-7">
                            <app-used-capacity-admin></app-used-capacity-admin>
                        </div>
                    </div>
                    
                </div>
            </section>
        </div>
    
        <div class="col-xl-3 col-lg-4 col-md-5 bg-white d-none d-md-inline">
            <div class="user-info-block">
                <div class="p-3">
                    <label class="mb-3">{{ 'ServiceDashboardEnduser_accountInfor' | translate }}</label>
                    <br>
                    <div class="row mb-3" *ngIf="userInfo.ten">
                        <div class="col-md-6 text-md-left">
                            <span class="text-secondary">{{ 'full_name' | translate }} </span>
                        </div>
                        <div class="col-md-6 text-md-right text-overload">
                            <span class="">{{ userInfo.ten || 'ServiceDashboardEnduser_noData' | translate }}</span>
                        </div>
                    </div>

                    <div class="row mb-3" *ngIf="userInfo.email">
                        <div class="col-md-6 text-md-left">
                            <span class="text-secondary">{{ 'ServiceDashboardEnduser_email' | translate }} </span>
                        </div>
                        <div class="col-md-6 text-md-right text-overload">
                            <span class="">{{ userInfo.email || 'ServiceDashboardEnduser_noData' | translate }}</span>
                        </div>
                    </div>
                    <div class="row mb-3" *ngIf="userInfo.sdt">
                        <div class="col-md-6 text-md-left">
                            <span class="text-secondary">{{ 'ESignOtp_sms_signForm' | translate }} </span>
                        </div>
                        <div class="col-md-6 text-md-right text-overload">
                            <span class="">{{ userInfo.sdt || 'ServiceDashboardEnduser_noData' | translate }}</span>
                        </div>
                    </div>
                    <div *ngIf="isAdmin" class="company-block">
                        <div class="row mb-3" *ngIf="partyInfo.tenToChuc">
                            <div class="col-md-6 text-md-left">
                                <span class="text-secondary">{{ 'ServiceDashboardEnduser_businessName' | translate }} </span>
                            </div>
                            <div class="col-md-6 text-md-right text-overload">
                                <span class="">{{ partyInfo.tenToChuc || 'ServiceDashboardEnduser_noData' | translate }}</span>
                            </div>
                        </div>
                        <div class="row mb-3" *ngIf="partyInfo.tenRutGon">
                            <div class="col-md-6 text-md-left">
                                <span class="text-secondary">{{ 'ServiceDashboardEnduser_businessShorcut' | translate }} </span>
                            </div>
                            <div class="col-md-6 text-md-right text-overload">
                                <span class="">{{ partyInfo.tenRutGon || 'ServiceDashboardEnduser_noData' | translate }}</span>
                            </div>
                        </div>
                        <div class="row mb-3" *ngIf="partyInfo.maSoThue">
                            <div class="col-md-6 text-md-left">
                                <span class="text-secondary">{{ 'ServiceDashboardEnduser_tax' | translate }} </span>
                            </div>
                            <div class="col-md-6 text-md-right text-overload">
                                <span class="">{{ partyInfo.maSoThue || 'ServiceDashboardEnduser_noData' | translate }}</span>
                            </div>
                        </div>
                        <div class="row mb-3" *ngIf="partyInfo.userInformations.daiDien">
                            <div class="col-md-6 text-md-left">
                                <span class="text-secondary">{{ 'RegisterBusiness_h_6' | translate }} </span>
                            </div>
                            <div class="col-md-6 text-md-right text-overload">
                                <span class="">{{ partyInfo.userInformations.daiDien || 'ServiceDashboardEnduser_noData' | translate }}</span>
                            </div>
                        </div>
                        <div class="row mb-3" *ngIf="partyInfo.userInformations.email">
                            <div class="col-md-6 text-md-left">
                                <span class="text-secondary">{{ 'ServiceDashboardEnduser_email' | translate }} </span>
                            </div>
                            <div class="col-md-6 text-md-right text-overload">
                                <span class="">{{ partyInfo.userInformations.email || 'ServiceDashboardEnduser_noData' | translate }}</span>
                            </div>
                        </div>
                        <div class="row mb-3" *ngIf="partyInfo.userInformations.username">
                            <div class="col-md-6 text-md-left">
                                <span class="text-secondary">{{ 'ServiceDashboardEnduser_accountName' | translate }} </span>
                            </div>
                            <div class="col-md-6 text-md-right text-overload">
                                <span class="">{{ partyInfo.userInformations.username || 'ServiceDashboardEnduser_noData' | translate }}</span>
                            </div>
                        </div>
                    </div>

                    <hr>

                    <!-- <div class="row">
                        <div class="col-md-auto">
                            <label class="mb-3">Danh sách hợp đồng chờ ký</label>
                        </div>
                        <div class="col-md-auto ml-auto">
                            <a class="text-primary text-decoration-underline">Chi tiết</a>
                        </div>
                    </div> -->

                    <!-- <div class="contract-item p-3" *ngFor="let item of listContract">
                        <label class="text-primary contract-title">Hợp đồng dịch vụ VNPT eInvoice</label>
                        <div class="row">
                            <div class="col-md-8">
                                <div class="row">
                                    <div class="col-md-6">
                                        <label class="font-label">Bên tham gia</label>
                                    </div>
                                    <div class="col-md-6">
                                        <span class="font-label">{{item.partners.length}}</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label class="font-label">Ngày chỉnh sửa</label>
                                    </div>
                                    <div class="col-md-6">
                                        <span class="font-label">{{item.modified | date: "dd/MM/yyyy"}}</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label class="font-label">Trạng thái</label>
                                    </div>
                                    <div class="col-md-6">
                                        <span class="text-warning font-label">{{
                                            (currentStage[item.currentStage].label | translate) || '' }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" style="text-align: right;">
                                <button class="btn btn-primary d-inline-flex font-button" (click)="goToSign(item.contractId)">Ký hợp đồng</button>
                            </div>
                        </div>
                    </div> -->

                </div>

            </div>

            
        </div>
    </div>
</section>
