
import {EnterpriseManagerItem} from './enterprise-manager-item'
export class EnterpriseManagerObject {
    data: EnterpriseManagerItem[];
    page: number;
    maxSize: number;
    propertiesSort: string;
    totalElement: number;
    sort: string;

    constructor(obj = null) {
        obj = obj || {};
        this.page = obj.page || 1;
        this.maxSize = obj.maxSize || 20;
        this.propertiesSort = obj.propertiesSort || '';
        this.totalElement = obj.totalElement || 0;
        this.sort = obj.sort || '';
        this.data = [];
        if (obj.data && obj.data instanceof Array) {
            obj.data.forEach((item) => {
                this.data.push(new EnterpriseManagerItem(item));
            });
        }
    }
}