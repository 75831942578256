
export class CopyrightItem {
    licenseId: string;
    packInfoId : string;
    tenKhachHang : string;
    partyType : string;
    packageName: string;
    issueDate: string;
    status: string;
    partyId: string;
    constructor(obj = null) {
        this.licenseId = obj.licenseId || '';
        this.tenKhachHang = obj.tenKhachHang || '';
        this.partyType = obj.partyType || '';
        this.packageName = obj.packageName || '';
        this.issueDate = obj.issueDate? this.convertToDateObj(obj.issueDate) : '';
        this.status = obj.status || '';
        this.packInfoId = obj.packInfoId || '';
        this.partyId = obj.partyId || "";
    }
    convertToDateObj(dataString) {
        let d = new Date(dataString);
        let localTiming = d.toLocaleDateString('en-GB');
        return localTiming;
      }
}