<app-spinner></app-spinner>
<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0 text-dark"><label>Người dùng cá nhân</label></h1>
            </div>
        </div>
    </div>
</div>

<!--MARK top bar -->
<section class="content">
    <div class="container-fluid">
        <div class="content-right-sidebar border-tab mb-4">
            <ul class="nav nav-tabs" id="tabSignAction" role="tablist">
                <li class="nav-item nav-material">
                    <a class="nav-link d-flex active" id="waiting-tab" data-toggle="pill" href="#waiting" role="tab"
                        aria-controls="waiting" aria-selected="true">
                        <!-- <span class="material-icons">query_builder</span> -->
                        <span class="ct-icon-font icon-identity-request">
                        </span>
                        Chờ phê duyệt
                    </a>
                </li>
                <li class="nav-item nav-material">
                    <a class="nav-link d-flex" id="approved-tab" data-toggle="pill" href="#approved" role="tab"
                        aria-controls="approved" aria-selected="false">
                        <!-- <span class="material-icons">check_circle_outline</span> -->
                        <span class="ct-icon-font icon-identity_accepted"></span>
                        Đã phê duyệt
                    </a>
                </li>

                <li class="nav-item nav-material">
                    <a class="nav-link d-flex" id="reject-tab" data-toggle="pill" href="#reject" role="tab"
                        aria-controls="reject" aria-selected="false">
                        <!-- <span class="material-icons">cancel_outline</span> -->
                        <span class="ct-icon-font icon-identity-rejected"></span>
                        Từ chối phê duyệt
                    </a>
                </li>
            </ul>
        </div>
        <div class="tab-content contract-tab-body" id="tabSignAction">
            <!--MARK chữ kí số USB-Token -->
            <div class="tab-pane fade show active" id="waiting" role="tabpanel" aria-labelledby="waiting">
                <app-consumer-signup-wait [callback]="reloadChildFunction"></app-consumer-signup-wait>
            </div>

            <div class="tab-pane fade" id="approved" role="tabpanel" aria-labelledby="approved">
                <app-consumer-signup-approved></app-consumer-signup-approved>
            </div>

            <div class="tab-pane fade" id="reject" role="tabpanel" aria-labelledby="reject">
                <app-consumer-signup-rejected></app-consumer-signup-rejected>
            </div>
        </div>
    </div>
</section>