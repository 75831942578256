<app-mini-spinner *ngIf="spinnerLgShow() | async"></app-mini-spinner>
<!-- MARK màn hình khi chưa có chữ kí số -->
<section *ngIf="digitalSignList.length == 0 && !addDSign" class="content">
    <div class="text-center">
        <img src="assets/img/services/emty-signature-img.png" style="margin-top: 5px;" class="col-md-3">
        <div class="mb-2">
            <p class="mb-1">{{ 'DigitalSignatureManager_label_1' | translate }}</p>
            <p>{{ 'DigitalSignatureManager_label_2' | translate }}</p>
        </div>
        <div class="px-md-5 p-3 ">
            <a class="text-primary cursor-pointer" (click)="addDigitalSignature()">{{ 'btn_addnew' | translate }}</a>
        </div>
    </div>
</section>

<!-- MARK đã có dữ liệu chữ ký số USB-Token -->
<section *ngIf="digitalSignList.length > 0 || addDSign">
    <div class="row">
        <!-- <div class="col-md-4">
            <div class="card shadow-none pb-2 position-relative">
                <app-mini-spinner *ngIf="spinnerLShow() | async"></app-mini-spinner>
                <div class="card-body">
                    <div class="row pt-2 pb-2">
                        <div class="col-md-auto align-self-end">
                            <label class="text-lg">Danh sách chữ ký số</label>
                        </div>
                        <div class="col-md-auto ml-auto">
                        </div>
                    </div>
                </div>
                <div class="position-relative">
                    <ng-template #noData>
                        <div class="no-content-list">
                            <img class="mb-2" src="assets/img/icon/icon-no-user-group.png" width="100">
                            <p class="mb-1">Bạn chưa có thông tin chữ ký điện tử.</p>
                        </div>
                    </ng-template>

                    <div *ngIf="digitalSignList.length > 0; else noData" class="content-list fade show">
                        <div *ngFor="let item of digitalSignList; let i = index" (click)="selectCert(item)"
                            class="user-group-item content-list-item px-4 pt-2"
                            [class.active]="selectedCert.id === item.id">
                            <div class="border-bottom d-flex">
                                <span class="material-icons icon-item pr-3">gesture</span>
                                <div class="flex-grow-1">
                                    <label class="title-item">{{ item.email }}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="totalElement > maxSize" class="pagination justify-content-end pages">
                <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="5" [rotate]="true"
                    [ellipses]="false" [pageSize]="maxSize" (pageChange)="loadPage($event)" [boundaryLinks]="true"
                    aria-label="Default pagination">
                    <ng-template ngbPaginationFirst>Đầu</ng-template>
                    <ng-template ngbPaginationLast>Cuối</ng-template>
                    <ng-template ngbPaginationPrevious>&laquo;</ng-template>
                    <ng-template ngbPaginationNext>&raquo;</ng-template>
                    <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                </ngb-pagination>
            </div>
        </div> -->
        
        <div class="col-md-12">
            <div>
                <div class="card shadow-none">
                    <div class="card-body position-relative">
                        <div class="row">
                            <div class="col-md-auto align-self-end">
                                <label class="text-lg">{{ 'DigitalSignatureManager_label_3' | translate }}</label>
                            </div>
                            <div *ngIf="!isMobileDevice() || typeCreateImg == '0'" class="col-md-auto ml-auto">
                                <button class="btn btn-outline-primary d-inline-flex mr-4" (click)="actCancel()"><span
                                        class="material-icons mr-2">clear</span>{{ 'btn_cancel' | translate }}</button>
                                <button class="btn btn-primary d-inline-flex" (click)="submitSave()"><span
                                        class="material-icons mr-2">save</span>{{ 'btn_save' | translate }}</button>
                            </div>
                        </div>
                        <hr>

                        <div class="row">
                            <div class="col-md-5">
                                <div class="row mt-4">
                                    <div class="col-sm-4">
                                        <div class="rcheck-primary d-inline px-0">
                                            <input checked [(ngModel)]="typeCreateImg" value="0" type="radio" name="typeCreateImg" id="rr0">
                                            <label for="rr0">
                                                {{ 'DigitalSignatureManager_label_4' | translate }}
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-sm-4">
                                        <div class="rcheck-primary d-inline px-0">
                                            <input [(ngModel)]="typeCreateImg" value="1" type="radio" name="typeCreateImg" id="rr1">
                                            <label for="rr1" (click)="loadCanvas()">
                                                {{ 'DigitalSignatureManager_label_5' | translate }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="typeCreateImg == '0'" class="row mt-4">
                                    <div class="col-12">
                                        <form [formGroup]="formUpdateConfig">
                                            <div class="form-group">
                                                <label>{{ 'DigitalSignatureManager_label_6' | translate }} <span class="text-danger">*</span>:</label>
                                                <div class="input-group mb-1">
                                                    <div class="custom-file custom-file-icon">
                                                        <span class="icon-file material-icons">attach_file</span>
                                                        <label class="custom-file-label"
                                                            for="inputGroupFile01">{{ inputFileLabel | translate }}</label>
                                                        <input #inputFile formControlName="imageFile" (input)="handleFileInput($event)" type="file"
                                                            accept="image/*" class="custom-file-input" id="inputGroupFile01"
                                                            aria-describedby="inputGroupFileAddon01">
                                                    </div>
                                                </div>
                                                <span><em>{{ 'desc_img_file_e_signature' | translate }}</em></span>
                                                <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="validMsg.imageFile"
                                                    [control]="formUpdateConfig.controls.imageFile" [detail]="{ name: 'imageFile' }">
                                                </app-show-validate-errors>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div class="row mt-4" [class.d-none]="typeCreateImg != '1'">
                                    <div class="col-12">
                                        <div *ngIf="isShowFullScreen" class="draw-area-overlay"></div>
                                        <div class="draw-area" [class.show-fullscreen]="isShowFullScreen">
                                            <div class="draw-area-header d-flex p-3">
                                                <a *ngIf="isMobileDevice() && !isShowFullScreen" class="btn btn-icon btn-icon-gray ml-auto" 
                                                    (click)="setShowFullScreen(); loadCanvas();">
                                                    <span class="material-icons">
                                                        fullscreen
                                                    </span>
                                                </a>
                                                <a *ngIf="isShowFullScreen" class="btn btn-icon btn-icon-gray mr-2 ml-auto" 
                                                    (click)="setShowFullScreen(); loadCanvas();">
                                                    <span class="material-icons">
                                                        close
                                                    </span>
                                                </a>
                                                <a *ngIf="!isMobileDevice() || isShowFullScreen" class="cursor-pointer" [class.ml-auto]="!isMobileDevice()" [class.mr-2]="isShowFullScreen" (click)="clearCanvas()">
                                                    <img src="assets/img/icon/clear-canvas.svg" height="34" width="34">
                                                </a>
                                                <button *ngIf="isShowFullScreen" class="btn btn-primary" (click)="saveOnPopup()">
                                                    {{ 'btn_save' | translate }}
                                                </button>
                                            </div>
                                            <div #esign_draw_canvas id="esign-draw-canvas">
                                                <canvas #esign_canvas_signature id="esign-canvas-signature"></canvas>
                                                <div class="overlay-block-draw-on-mobile" [class.d-block]="isMobileDevice() && !isShowFullScreen"
                                                    (click)="setShowFullScreen(); loadCanvas();"
                                                >
                                                    Chạm để vẽ chữ ký
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-5">
                                <div class="form-group">
                                    <label class="w-100">{{ 'DigitalSignatureManager_label_7' | translate }}</label>
                                    <img *ngIf="imgContent" class="img-signature mt-2" width="200" style="max-width: 100%" [src]="imgContent">
                                </div>
                    
                                <div class="form-group" [class.d-none]="typeCreateImg == '1'">
                                    <label class="w-100">{{ 'DigitalSignatureManager_label_8' | translate }}</label>
                                    <img *ngIf="newImgContent" class="img-signature mt-2" width="200" style="max-width: 100%" [src]="newImgContent">
                                </div>
                            </div>
                        </div>

                        <app-mini-spinner *ngIf="spinnerRShow() | async"></app-mini-spinner>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>