import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ChartType } from 'chart.js';
import { Label, MultiDataSet } from 'ng2-charts';
import { DashboardService } from 'src/app/utils/services/aas-network/dashboard/dashboard.service';

@Component({
  selector: 'app-stats-by-status-admin',
  templateUrl: './stats-by-status-admin.component.html',
  styleUrls: ['./stats-by-status-admin.component.scss'],
})
export class StatsByStatusAdminComponent implements OnInit {
  constructor(private dashboardService: DashboardService) {}

  ngOnInit(): void {
    this.initPercentData(new Date().getFullYear());
  }

  title = 'Browser';
  type = 'PieChart';
  data = [
    // ['Có hiệu lực', 517],
    // ['Chờ ký', 297],
    // ['Bản nháp', 26],
    // ['Đã hủy', 143],
    // ['Khác', 271],
  ];
  statusContract = {
    LC_DRAFT_CREATE: 'Bản nháp',
    LC_DRAFT_UPDATE: 'Mới khởi tạo',
    LC_DRAFT_DENY: 'Đã trả lại',
    LC_DRAFT_CANCEL: 'Đã huỷ',
    LC_DRAFT_SUBMIT: 'Chờ ký',
    LC_DRAFT_SUBMIT_EXTERNAL: 'Chờ ký',
    LC_DRAFT_SUBMIT_INTERNAL: 'Chờ ký',
    LC_DRAFT_ONE_SIGN: 'Chờ NCC ký',
    LC_CONTRACT_VALID: 'Có hiệu lực',
    LC_DRAFT_SUBMIT_SIGNED: 'Chờ ký',
    LC_DRAFT_VALID: 'Hai bên đã ký',
    LC_DRAFT_SIGNED: 'Chờ ký',
    LC_DRAFT_CHANGE_REQUEST: 'Đang yêu cầu chỉnh sửa',
    LC_DRAFT_SUBMIT_THIRD_PARTY: 'Chờ ký',
    LC_CONTRACT_EXPIRED_VALID: 'Hết hiệu lực',
    LC_CONTRACT_CANCEL: 'Đã huỷ',
    LC_DRAFT_DISCUSS: 'Đàm phán',
    LC_DRAFT_DEAL: 'Đồng ý thoả thuận',
    LC_DRAFT_EXPIRED: 'Quá hạn ký',
    LC_DRAFT_WAIT_CONFIRM: 'Chờ thẩm định',
    LC_DRAFT_SIGN_FAIL: 'Ký lỗi',
    LC_DRAFT_WARNING: 'Cảnh báo',
  };

  total: number = 0;

  percentData = [];

  columnNames = ['Browser', 'Percentage'];
  options = {
    pieHole: 0.8,
    legend: 'none',
    pieSliceText: 'none',
    chartArea: {
      top: 10,
      bottom: 10,
    },
    colors: ['#ff4700', '#ff4700', '#dc3545', '#FFB800', '#567DF4'],
    // colors: ['#29D395', '#F5A623', '#718096', '#FF7474', '#567DF4'],
  };

  // color = ['#29D395', '#F5A623', '#718096', '#FF7474', '#567DF4']
  color = ['#ff4700', '#ff4700', '#dc3545', '#FFB800', '#567DF4'];

  height = 400;
  dynamicResize = true;
  percentSigned = 0;
  totalSigned = 0;
  yearChange(event) {
    this.total = 0;
    this.percentSigned = 0;
    this.totalSigned = 0;
    this.percentData=[]
    this.data=[]
    this.initPercentData(event.value);
  }
  initPercentData(year) {
    this.dashboardService.getContractStatsByStatus(year).subscribe(
      (res: any) => {
        if (res && res.object instanceof Array) {
          if (res.object.length == 0 || res.object[0].contracts === null) {
            this.total = 0;
            this.percentData = [0, 0, 0, 0, 0];
            this.data = [
              ['Có hiệu lực', 0],
              ['Chờ ký', 0],
              ['Bản nháp', 0],
              ['Đã hủy', 0],
              ['Khác', 0],
            ];
          } else {
            res.object.forEach((item) => {
              this.total += item.contracts;
            });
            res.object.forEach((percent) => {
              // if (this.statusContract[percent['currentStage']] == 'Chờ ký') {
              //   // this.percentSigned += Math.round((percent.contracts / this.total) * 100 * 10) / 10;
              //   this.totalSigned += percent['contracts'];
              // } else {
              if (this.total == 0) {
                this.percentData = [0, 0, 0, 0, 0];
              } else {
                this.percentData.push(Math.round((percent.contracts / this.total) * 100 * 10) / 10);
              }
              // }
            });
            this.data = Object.values(res.object).map((vals) => [
              this.statusContract[vals['currentStage']],
              vals['contracts'],
            ]);
            // this.percentData.splice(2, 0, Math.round((this.totalSigned / this.total) * 100 * 10) / 10);
            // this.data.splice(2, 2, ['Chờ ký', this.totalSigned]);
          }
          // console.log(this.data);
        }
      },
      (error) => {}
    );
  }

  //

  years = [
    { label: 2021, value: 2021 },
    { label: 2022, value: 2022 },
  ];

  yearForm: FormControl = new FormControl(2022);

  //

  doughnutChartLabels: Label[] = ['BMW', 'Ford', 'Tesla'];
  doughnutChartData: MultiDataSet = [[55, 25, 20]];
  doughnutChartType: ChartType = 'doughnut';

  doptions = {
    legend: {
      position: 'bottom',
      textAlign: 'left',
    },
  };
}
