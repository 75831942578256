import { timeStamp } from 'console';

export class ListPartnerBusiness {
    cmnd: string = '';
    created: string = '';
    email: string = '';
    gioiTinhId: string = '';
    identityLevel: number;
    ngaySinh: string = '';
    partnerId: string = '';
    partnerVerificationId: string = '';
    reason: string = '';
    sdt: string = '';
    ten: string = '';
    userId: string = '';
    userType: string = '';
    verificationDate: string = '';
    verificationStatus: string = '';
    verificationType: string = '';
    tenToChuc: string = '';
    maSoThue: string = '';

    constructor(data = null) {
        data = data || {};

        this.cmnd = data.cmnd || '';
        this.created = data.created || '';
        this.email = data.email || '';
        this.gioiTinhId = data.gioiTinhId || '';
        this.identityLevel = data.identityLevel;
        this.ngaySinh = data.ngaySinh || '';
        this.partnerId = data.partnerId || '';
        this.partnerVerificationId = data.partnerVerificationId || '';
        this.reason = data.reason || '';
        this.sdt = data.sdt || '';
        this.ten = data.ten || '';
        this.userId = data.userId || '';
        this.userType = data.userType || '';
        this.verificationStatus = data.verificationStatus || '';
        this.verificationDate = data.verificationDate || '';
        this.verificationType = data.verificationType || '';
        this.tenToChuc = data.tenToChuc || '';
        this.maSoThue = data.maSoThue || '';
    }
}