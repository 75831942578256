
<div class="wrapper">
    <div class="preview-overlay">

        <div class="preview-container">
            <div class="preview-header d-flex">
                <div (click)="close()"
                    class="ct-header-btn-back d-flex justify-content-center align-items-center cursor-pointer">
                    <span class="material-icons">close</span>
                </div>
                <div class="ct-title flex-grow-1 d-flex align-items-center px-3">
                    <h4 class="mb-0">{{'PartyJoin_title' | translate}}</h4>
                </div>
                <div class="ct-toolbar-btn d-flex align-items-center">
                    <button (click)="saveForm()" class="btn btn-primary d-flex align-items-center mr-3"><span
                            class="material-icons mr-2">save</span> {{'btn_save' | translate}}</button>
                </div>
            </div>

            <div class="preview-body">
                <div class="pv ct">
                    <div class="pv-body">
                        <div class="ctpv-container">
                            <div class="ctpv-content">
                                <div class="ct-container">
                                    <div class="ct-content" style="left: 0;">
                                        <div class="ct-content-enable-scroll enable-scroll">
                                            <div class="card ec-card shadow-none p-3">
                                                <div class="table-responsive">
                                                    <div class="">
                                                        <form [formGroup]="userTable">
                                                            <table class="table table-bordered custom-table">
                                                                <thead class="bg-light">
                                                                    <tr>
                                                                        <th tyle="width: 100px;">{{'PartyJoin_stt' | translate}}</th>
                                                                        <th style="min-width: 200px;">{{'PartyJoin_party' | translate}}</th>
                                                                        <th>{{'PartyJoin_customerType' | translate}}</th>
                                                                        <th>{{'PartyJoin_formVerify' | translate}}</th>
                                                                        <th>{{'PartyJoin_formSign' | translate}}</th>
                                                                        <th style="width: 150px;">{{'PartyJoin_signOrder' | translate}}</th>
                                                                        <!-- <th>Thời hạn ký</th> -->
                                                                        <th>{{'PartyJoin_action' | translate}}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <!-- <tr *ngFor="let item of parties; let i = index">
                                                                        <td>{{ i + 1 }}</td>
                                                                        <td>
                                                                            <div class="form-group mb-0">
                                                                                <ng-select2 [disabled]="item.isOwner" [(ngModel)]="item.partyId" [ngModelOptions]="{ standalone: true }"
                                                                                    [data]="item.nguoiNhanData" [options]="optionsTemplate"
                                                                                    [placeholder]="'Chọn bên tham gia'" [allowClear]="true"
                                                                                    (valueChanged)="changePartySelect2($event, i)">
                                                                                    <option></option>
                                                                                </ng-select2>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <ng-select2 [disabled]="item.isOwner || !item.allowEditUserType" [data]="userTypeData" [(ngModel)]="item.userType"
                                                                                [ngModelOptions]="{ standalone: true }" [options]="options"
                                                                                [placeholder]="'Chọn loại khách hàng'"
                                                                                (valueChanged)="changeUserType($event, i)">
                                                                            </ng-select2>
                                                                        </td>
                                                                        <td>
                                                                            <ng-select2 *ngIf="!item.isOwner" [disabled]="!item.allowEdit" [data]="hinhThucXacMinh" [(ngModel)]="item.verificationType"
                                                                                [ngModelOptions]="{ standalone: true }" [options]="options"
                                                                                [placeholder]="'Chọn hình thức xác minh'">
                                                                            </ng-select2>
                                                                        </td>
                                                                        <td>
                                                                            <div class="form-group mb-0">
                                                                                <ng-select2 *ngIf="!item.isOwner" [disabled]="!item.allowEdit" [(ngModel)]="item.signForm" [ngModelOptions]="{ standalone: true }"
                                                                                    [options]="optionsHinhThucKy" [data]="item.hinhThucKyData"
                                                                                    [placeholder]="'Chọn hình thức ký'">
                                                                                </ng-select2>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <input *ngIf="!item.isOwner" [disabled]="!item.allowEdit" class="form-control mb-0 date-input" type="text"
                                                                                placeholder="dd/MM/yyyy" appInputMark [options]="optionsCleave" (onValueChanged)="onChangedDate($event, i)" [(ngModel)]="item.endDate">
                                                                        </td>
                                                                        <td><a *ngIf="!item.isOwner && item.allowDetete" class="text-primary text-decoration-underline cursor-pointer">Xóa</a></td>
                                                                    </tr> -->
                                                                    <ng-container formArrayName="tableRows" *ngFor="let group of getFormControls.controls ; let i=index">
                                                                        <tr [formGroupName]="i">
                                                                            <td>{{ i + 1 }}</td>
                                                                            <td [class.bg-danger-tb]="isSubmit && fromGroup(i).controls.partyId.invalid">
                                                                                <div class="form-group mb-0">
                                                                                    <!-- <ng-select2 [disabled]="group.value.isOwner" formControlName="partyId"
                                                                                        [data]="group.value.nguoiNhanData" [options]="optionsTemplate"
                                                                                        placeholder="{{'PartyJoin_ph_party' | translate}}" [allowClear]="true"
                                                                                        (valueChanged)="changePartySelect2Form($event, i)">
                                                                                        <option></option>
                                                                                    </ng-select2> -->
                                                                                    <ng-select
                                                                                        [items]="group.value.func.dropListPartData | async"
                                                                                        bindLabel="text"
                                                                                        bindValue="id"
                                                                                        [trackByFn]="group.value.func.trackByFn" 
                                                                                        [loading]="group.value.func.partsLoading"
                                                                                        [typeahead]="group.value.func.partsInput$"
                                                                                        formControlName="partyId"
                                                                                        (change)="group.value.func.changedParty($event); changePartyNgSelect($event, i)"
                                                                                        placeholder="{{'PartyJoin_ph_party' | translate}}"
                                                                                        appendTo="body"
                                                                                        [disabled]="group.value.isOwner"
                                                                                        [readonly]="group.value.isOwner"
                                                                                    >
                                                                                        <ng-template *ngIf="group.value.func.noResultParty == true" ng-header-tmp>
                                                                                            <a class="form-text text-primary mb-2" [routerLink]="enumUrl.add_trusted_business_partner" target="_blank">{{ '+ Thêm đối tác tổ chức' | translate }}</a>
                                                                                            <a class="form-text text-primary" [routerLink]="enumUrl.add_trusted_consumer_partner" target="_blank">{{ '+ Thêm đối tác cá nhân' | translate }}</a>
                                                                                        </ng-template>
                                                                                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                                                                            <div class="select-customer">  
                                                                                                <span class="ect-email-item d-flex">{{ item?.text || item?.additional?.email || item?.additional?.sdt  }}</span>
                                                                                                <span *ngIf="item?.additional?.name" class="select-name-cutomner d-flex">{{ item?.additional?.name }}</span>
                                                                                                <span *ngIf="item?.additional?.type_customer" class="badge badge-light-new p-2 mr-2">{{ item?.additional?.type_customer }}</span>  
                                                                                                <span *ngIf="item?.additional?.sgt" class="badge badge-light-new p-2">{{ item?.additional?.sgt }}</span>  
                                                                                                <hr class="mt-2 mb-0"> 
                                                                                            </div>
                                                                                        </ng-template>
                                                                                        <ng-template ng-label-tmp let-item="item">
                                                                                            <span class="ng-selected-tag select-tag-primary" [class.select-tag-primary]="item?.additional?.isTrustedPartner === 'Y'">
                                                                                                {{ item?.text || item?.additional?.email || item?.additional?.sdt  }}
                                                                                            </span>
                                                                                        </ng-template>
                                                                                    </ng-select>
                                                                                </div>
                                                                            </td>
                                                                            <td [class.bg-danger-tb]="isSubmit && fromGroup(i).controls.userType.invalid">
                                                                                <ng-select2 [disabled]="group.value.isOwner || !group.value.allowEditUserType" [data]="userTypeData" 
                                                                                    formControlName="userType"
                                                                                    [options]="options"
                                                                                    placeholder="{{'PartyJoin_ph_customerType' | translate}}"
                                                                                    (valueChanged)="changeUserTypeForm($event, group)">
                                                                                </ng-select2>
                                                                                <!-- <app-show-validate-errors [useSubmit]="false"
                                                                                    [errorMessages]="validateTable.userType"
                                                                                    [control]="group.controls.userType"
                                                                                    [detail]="{ name: 'userType' }"></app-show-validate-errors> -->
                                                                            </td>
                                                                            <td [class.bg-danger-tb]="isSubmit && fromGroup(i).controls.verificationType.invalid">
                                                                                <ng-select2 *ngIf="!group.value.isOwner" [disabled]="!group.value.allowEdit" [data]="hinhThucXacMinh" 
                                                                                    formControlName="verificationType"
                                                                                    [options]="options"
                                                                                    placeholder="{{'PartyJoin_ph_formVerify' | translate}}">
                                                                                </ng-select2>
                                                                                <!-- <app-show-validate-errors [useSubmit]="false"
                                                                                    [errorMessages]="validateTable.verificationType"
                                                                                    [control]="group.controls.verificationType"
                                                                                    [detail]="{ name: 'verificationType' }"></app-show-validate-errors> -->
                                                                            </td>
                                                                            <td [class.bg-danger-tb]="isSubmit && fromGroup(i).controls.signForm.invalid">
                                                                                <div class="form-group mb-0">
                                                                                    <!-- <ng-select2 *ngIf="!group.value.isOwner" [disabled]="!group.value.allowEdit"
                                                                                        formControlName="signForm"    
                                                                                        [options]="optionsHinhThucKy" 
                                                                                        [data]="group.value.hinhThucKyData"
                                                                                        [placeholder]="'Chọn hình thức ký'">
                                                                                    </ng-select2> -->
                                                                                    <ng-select
                                                                                        *ngIf="!group.value.isOwner"
                                                                                        [items]="group.value.hinhThucKyData"
                                                                                        bindLabel="text"
                                                                                        bindValue="id"
                                                                                        formControlName="signForm"
                                                                                        [multiple]="true"
                                                                                        [readonly]="!group.value.allowEdit"
                                                                                        placeholder="{{'PartyJoin_ph_formSign' | translate}}"
                                                                                        appendTo="body"
                                                                                    >
                                                                                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                                                                            <span class="ng-value-label">{{item.text}}</span>
                                                                                            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                                                                                        </ng-template>
                                                                                    </ng-select>
                                                                                    <!-- <app-show-validate-errors [useSubmit]="false"
                                                                                        [errorMessages]="validateTable.signForm"
                                                                                        [control]="group.controls.signForm"
                                                                                        [detail]="{ name: 'signForm' }"></app-show-validate-errors> -->
                                                                                </div>
                                                                            </td>
                                                                            <td [class.bg-danger-tb]="isSubmit && fromGroup(i).controls.sequence.invalid">
                                                                                <div class="form-group mb-0">
                                                                                    <input appOnlyNumber formControlName="sequence" placeholder="{{'PartyJoin_ph_signOrder' | translate}}"
                                                                                        class="form-control text-right mb-0" type="text" (input)="checkValidSequence()">
                                                                                </div>
                                                                            </td>
                                                                            <!-- <td>
                                                                                <input *ngIf="!group.value.isOwner" 
                                                                                    [attr.disabled]="!group.value.allowEdit ? '' : null" 
                                                                                    formControlName="endDate"  
                                                                                    class="form-control mb-0 date-input" type="text"
                                                                                    placeholder="dd/MM/yyyy" 
                                                                                    appInputMark [options]="optionsCleave">
                                                                            </td> -->
                                                                            <td>
                                                                                <button *ngIf="!group.value.isOwner && group.value.allowDetete" 
                                                                                    class="btn btn-icon btn-delete"
                                                                                    (click)="deleteRow(i)">
                                                                                    <span class="material-icons">delete</span>
                                                                                </button>
                                                                        </tr>
                                                                    </ng-container>
                                                                    
                                                                </tbody>
                                                            </table>
                                                        </form>
                                                    </div>
                                                </div>
                                    
                                                <div *ngIf="isSubmit" class="d-flex flex-column w-100 border-top pt-3 px-3">
                                                    <span *ngIf="!validRequired" class="w-100 text-danger">{{'PartyJoin_danger1' | translate}}</span>
                                                    <span *ngIf="!validSequence" class="w-100 text-danger">{{'PartyJoin_danger2' | translate}} </span>
                                                </div>
                                    
                                                <div class="d-flex w-100 modal-toolbar-table">
                                                    <a (click)="addRowPartyJoin(); addRow()" class="text-decoration-underline cursor-pointer text-primary mr-auto mt-3">
                                                        <span>+ {{'PartyJoin_addNew' | translate}}</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>