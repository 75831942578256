import { HttpClient } from "@angular/common/http";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { AasConsumerManagerService } from "src/app/utils/services/aas-network/aas-consumer-manager.service";
import { Constant } from "./../../../../../utils/common/constant_videocall";

declare var JitsiMeetExternalAPI: any;
declare let L;

@Component({
    selector: "app-web2web-videocall",
    templateUrl: "./web2web-videocall.component.html",
    styleUrls: ["./web2web-videocall.component.scss"],
})
export class Web2webVideocallComponent implements OnInit {
    @ViewChild("screenVideoShot", { static: false }) screen: ElementRef;

    options: any;
    api: any;
    params;
    userDetail: any = {};

    constructor(
        private router: Router,
        private http: HttpClient,
        private aasConsumerManagerService: AasConsumerManagerService
    ) {}

    ngOnInit(): void {}

    ngAfterViewInit(): void {
        // this.aasConsumerManagerService.currentMessage.subscribe((message) => {
        //   console.log('call vide-ms', message)
        // })
        this.getUserDetail();
        this.initVideoCall();
    }

    getUserDetail() {
        this.userDetail = JSON.parse(localStorage.getItem(Constant.CALLER));
    }

    initVideoCall() {
        const roomInfo = JSON.parse(localStorage.getItem(Constant.ROOM_INFO));
        this.params = roomInfo;
        const options = {
            roomName: roomInfo["roomId"] ? roomInfo["roomId"] : "vnptit3",
            width: "100%",
            height: 590,
            jwt: roomInfo["token"],
            configOverwrite: { subject: " " }, // set roomName
            userInfo: {
                email: localStorage.getItem(Constant.UUID),
                displayName: this.userDetail,
            },
            parentNode: document.querySelector("#meet"),
        };
        this.api = new JitsiMeetExternalAPI(
            roomInfo["domain"].replace("https://", ""),
            options
        );

        console.log(options);
        this.api.addEventListener("readyToClose", () => {
            window.open("", "_self").close();
        });
    }
}
