
<div class="card shadow-none">
    <div class="card-body position-relative">
        <form [formGroup]="formSubmit">
            <div class="row">
                <div class="col-md-auto align-self-end">
                    <label class="text-lg">Thông tin tài khoản tổ chức</label>
                </div>
                <div class="col-md-auto ml-auto">
                    <button (click)="actBtnCancel($event)" class="btn btn-outline-primary d-inline-flex mr-4"><span
                            class="material-icons mr-2">close</span> Hủy</button>
                    <button (click)="submit($event)" class="btn btn-primary d-inline-flex"><span
                        class="material-icons mr-2">save</span> Lưu</button>
                </div>
            </div>
            <hr class="my-3">
        
            <label class="text-primary">Thông tin doanh nghiệp</label>
            <hr>
            <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Tên doanh nghiệp <span class="text-danger">*</span>:</label>
                        <input class="form-control" formControlName="tenDoanhNghiep" placeholder="Nhập tên doanh nghiệp">
                        <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.tenDoanhNghiep"
                            [control]="tenDoanhNghiep" [detail]="{ name: 'tenDoanhNghiep' }"></app-show-validate-errors>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Tên viết tắt <span class="text-danger">*</span>:</label>
                                <input class="form-control" formControlName="tenVietTat" placeholder="Nhập tên viết tắt">
                                <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.tenVietTat"
                                    [control]="tenVietTat" [detail]="{ name: 'tenVietTat' }"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Mã số thuế <span class="text-danger">*</span>:</label>
                                <input disabled class="form-control" formControlName="maSoThue" placeholder="Nhập mã số thuế">
                                <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.maSoThue"
                                    [control]="maSoThue" [detail]="{ name: 'maSoThue' }"></app-show-validate-errors>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
            <label class="text-primary">Thông tin người đại diện</label>
            <hr>
            <div class="row">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Họ và tên <span class="text-danger">*</span>:</label>
                                <input class="form-control" formControlName="hoVaTen" placeholder="Nhập họ và tên">
                                <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.hoVaTen"
                                    [control]="hoVaTen" [detail]="{ name: 'hoVaTen' }"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Email <span class="text-danger">*</span>:</label>
                                <input disabled class="form-control" formControlName="email"
                                    placeholder="Nhập email người đại diện">
                                <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.email"
                                    [control]="email" [detail]="{ name: 'email' }"></app-show-validate-errors>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Số điện thoại <span class="text-danger">*</span>:</label>
                                <input class="form-control" formControlName="sdt" placeholder="Nhập số điện thoại" (input)="handleInputSdt($event)">
                                <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.sdt" [control]="sdt"
                                    [detail]="{ name: 'sdt' }"></app-show-validate-errors>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Ngày sinh:</label>
                                <input (input)="dateInput($event, ngaySinh)" type="text" formControlName="ngaySinh"
                                    class="form-control date" placeholder="dd/MM/yyyy" id="ngaySinh">
                                <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.ngaySinh"
                                    [control]="ngaySinh" [detail]="{ name: 'ngaySinh' }"></app-show-validate-errors>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
            <div class="row">
                <div class="col-md-12 col-lg-6">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Giới tính</label>
                                <select class="form-control" formControlName="gender" placeholder="Chọn giới tính">
                                    <option [selected]="+gender.value === 2" value="2">Nam</option>
                                    <option [selected]="+gender.value === 1" value="1">Nữ</option>
                                </select>
                                <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.gender"
                                    [control]="gender" [detail]="{ name: 'gender' }">
                                </app-show-validate-errors>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-6">
        
                </div>
            </div>
        
            <div class="row">
                <div class="col-md-12 col-lg-6">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="tinhId">Tỉnh/Thành phố</label>
                                <ng-select2 formControlName="tinhId" [data]="tinhData" [options]="options"
                                    (valueChanged)="tinhChange($event)" [placeholder]="'Chọn tỉnh/thành phố'">
                                </ng-select2>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="huyenId">Huyện/Quận</label>
                                <ng-select2 formControlName="huyenId" [data]="huyenData" [options]="optionsHuyen"
                                    (valueChanged)="huyenChange($event)" [placeholder]="'Chọn huyện/quận'">
                                </ng-select2>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 col-lg-6">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label for="xaId">Xã/Phường</label>
                                <ng-select2 formControlName="xaId" [data]="xaData" [options]="optionsXa"
                                    (valueChanged)="xaChange($event)" [placeholder]="'Chọn xã/phường'"></ng-select2>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Số nhà</label>
                                <input formControlName="soNha" type="text" class="form-control" placeholder="Nhập số nhà" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
            <div class="row">
                <div class="col-md-12 col-lg-6">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Đường</label>
                                <input formControlName="duong" type="text" class="form-control" placeholder="Nhập tên đường" />
                            </div>
                        </div>
                    </div>
                </div>
        
            </div>
        
            <label class="text-primary">Thông tin tài khoản</label>
            <hr>
            <div class="row">
                <div class="col-md-3">
                    <div class="form-group">
                        <label>Tên tài khoản <span class="text-danger">*</span>:</label>
                        <input class="form-control" formControlName="username" placeholder="Nhập tên tài khoản" disabled>
                        <!-- <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.username"
                            [control]="username" [detail]="{ name: 'username' }"></app-show-validate-errors> -->
                    </div>
                </div>
                <div *ngIf="partyInfo.partyType === 'ENTERPRISE'" class="col-md-3">
                    <div class="form-group">
                        <label>Tên miền <span class="text-danger">*</span>:</label>
                        <input class="form-control" formControlName="domain" placeholder="Nhập tên miền" disabled>
                        <!-- <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ServiceMsg.username"
                            [control]="username" [detail]="{ name: 'username' }"></app-show-validate-errors> -->
                    </div>
                </div>
            </div>
        </form>
        <!-- <app-mini-spinner *ngIf="loadingService.isVisible() | async"></app-mini-spinner> -->
    </div>
</div>