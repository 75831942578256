import { Component, OnInit, Input } from '@angular/core';
import { AbstractControlDirective, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-show-errors',
  templateUrl: './show-errors.component.html',
  styleUrls: ['./show-errors.component.scss']
})
export class ShowErrorsComponent implements OnInit {
  private static readonly errorMessages = {
    required: () => 'Vui lòng nhập thông tin!',
    email: () => 'Vui lòng nhập email!',
    minlength: (params) => 'Số lượng ký tự tối thiểu được phép là ' + params.requiredLength,
    maxlength: (params) => 'Số lượng ký tự tối đa được phép là ' + params.requiredLength,
    pattern: (params) => 'Vui lòng nhập đúng mẫu!',
    unavailable: (params) => params.message
  }

  @Input() private control: AbstractControlDirective | AbstractControl

  constructor() {}

  ngOnInit(): void {}

  shouldShowErrors(): boolean {
    return this.control && this.control.errors && (this.control.dirty || this.control.touched)
  }

  listOfErrors(): string[] {
    return Object.keys(this.control.errors).map((field) => this.getMessage(field, this.control.errors[field]))
  }

  private getMessage(type: string, params: any) {
    return ShowErrorsComponent.errorMessages[type](params)
  }
}
