export class ReportDetailItem{
    tenHopDong: string;
    benTao: string;
    loaiKhachHang: string;
    goiDichVu: string;
    benNhan: string;
    trangThai: string;
    ngayCapNhap: string;

    constructor(obj = null) {
        obj = obj || {};
        this.tenHopDong = obj.contractName || '';
        this.benTao = obj.createdPartyName || '';
        this.loaiKhachHang = obj.partyType || '';
        this.goiDichVu = obj.packageName || '';
        this.benNhan = obj.receivedPartyName || '';
        this.trangThai = obj.currentStage || '';
        this.ngayCapNhap = obj.writeDate || '';
    }

}