export class DigitalSignatureItem {
    id: string = '';
    // textColor: string;
    // visibleType: number;
    base64Image: string = '';
    // fontSize: number;
    // signBy: boolean;
    email: string = '';

    constructor(obj = null) {
        obj = obj || {};
        this.id = obj.id || '';
        // this.textColor = obj.textColor || '';
        // this.visibleType = obj.visibleType || '';
        this.base64Image = obj.base64Image || '';
        // this.fontSize = obj.fontSize || '';
        // this.signBy = obj.signBy;
        this.email = obj.email || '';
    }
}