import { MainResponse } from '../../../main-response.model';
import { ContractListObject } from './contract-list-object.model';

export class ContractListResponse extends MainResponse {
    object: ContractListObject;

    constructor(obj = null){
        super();
        obj = obj || '';
        this.object = new ContractListObject(obj.object);
    }
}