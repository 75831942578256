import { Template } from "@angular/compiler/src/render3/r3_ast";

export class TemplateContract {
    id: string;
    templateName: string;
    constructor(obj = null) {
        obj = obj || {};
        this.id = obj.id || '';
        this.templateName = obj.templateName || '';
    }
}