import { MainResponse } from '../../../main-response.model';
import { ListEnterpriseVipObject } from './list-enterprise-vip-object.model';

export class ListEnterpriseVipResponse extends MainResponse{
    object: ListEnterpriseVipObject;

    constructor(res = null) {
        super();
        res = res || {};
        this.init(res);
        this.object = new ListEnterpriseVipObject(res.object);
    }
}