<app-spinner></app-spinner>
<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0 text-dark"><label>Người dùng cá nhân</label></h1>
            </div>
        </div>
    </div>
</div>

<!--MARK top bar -->
<section class="content">
    <div class="container-fluid">
        <div class="content-right-sidebar border-tab mb-4">
            <ul class="nav nav-tabs" id="tabSignAction" role="tablist">
                <li class="nav-item nav-material">
                    <a class="nav-link d-flex active" id="waiting-tab" data-toggle="pill" href="#waiting" role="tab"
                        aria-controls="waiting" aria-selected="true">
                        <span class="material-icons">query_builder</span>
                        Chờ phê duyệt
                    </a>
                </li>
                <li class="nav-item nav-material">
                    <a class="nav-link d-flex" id="approved-tab" data-toggle="pill" href="#approved" role="tab"
                        aria-controls="approved" aria-selected="false">
                        <span class="material-icons">check_circle_outline</span>
                        Đã phê duyệt
                    </a>
                </li>

                <li class="nav-item nav-material">
                    <a class="nav-link d-flex" id="reject-tab" data-toggle="pill" href="#reject" role="tab"
                        aria-controls="reject" aria-selected="false">
                        <span class="material-icons">cancel_outline</span>
                        Từ chối phê duyệt
                    </a>
                </li>
                <!-- <li class="nav-item nav-material">
                        <a class="nav-link d-flex" id="tabSignServer-tab" data-toggle="pill" href="#tabSignServer"
                            role="tab" aria-controls="tabSignServer" aria-selected="false">
                            <span class="material-icons">wifi_tethering</span>
                            Thay đổi định danh
                        </a>
                    </li> -->
            </ul>
        </div>
        <!-- <div class="content-right-sidebar border mb-4"> -->
        <!--     <ul class="nav nav-tabs" id="tabSignAction" role="tablist"> -->
        <!--         <li class="nav-item nav-material active"> -->
        <!--             <a -->
        <!--                 class="nav-link d-flex active" -->
        <!--                 id="waiting-tab" -->
        <!--                 data-toggle="pill" -->
        <!--                 href="#waiting" -->
        <!--                 role="tab" -->
        <!--                 aria-controls="waiting" -->
        <!--                 aria-selected="true" -->
        <!--             > -->
        <!--                 <em class="material-icons">usb</em> -->
        <!--                 Chờ phê duyệt -->
        <!--             </a> -->
        <!--         </li> -->
        <!--         <li class="nav-item nav-material"> -->
        <!--             <a -->
        <!--                 class="nav-link d-flex" -->
        <!--                 id="approved-tab" -->
        <!--                 data-toggle="pill" -->
        <!--                 href="#approved" -->
        <!--                 role="tab" -->
        <!--                 aria-controls="approved" -->
        <!--                 aria-selected="false" -->
        <!--             > -->
        <!--                 <em class="material-icons">wifi_tethering</em> -->
        <!--                 Đã phê duyệt -->
        <!--             </a> -->
        <!--         </li> -->

        <!--         <li class="nav-item nav-material"> -->
        <!--             <a -->
        <!--                 class="nav-link d-flex" -->
        <!--                 id="reject-tab" -->
        <!--                 data-toggle="pill" -->
        <!--                 href="#reject" -->
        <!--                 role="tab" -->
        <!--                 aria-controls="reject" -->
        <!--                 aria-selected="false" -->
        <!--             > -->
        <!--                 <em class="material-icons">wifi_tethering</em> -->
        <!--                 Từ chối phê duyệt -->
        <!--             </a> -->
        <!--         </li> -->
        <!--         <!-1- <li class="nav-item nav-material"> -1-> -->
        <!--         <!-1-     <a class="nav-link d-flex" id="tabSignServer-tab" data-toggle="pill" href="#tabSignServer" role="tab" -1-> -->
        <!--         <!-1-         aria-controls="tabSignServer" aria-selected="false"> -1-> -->
        <!--         <!-1-         <em class="material-icons">wifi_tethering</em> -1-> -->
        <!--         <!-1- Thay đổi định danh -1-> -->
        <!--         <!-1-     </a> -1-> -->
        <!--         <!-1- </li> -1-> -->
        <!--     </ul> -->
        <!-- </div> -->
        <div class="tab-content contract-tab-body" id="tabSignAction">
            <!--MARK chữ kí số USB-Token -->
            <div class="tab-pane fade show active" id="waiting" role="tabpanel" aria-labelledby="waiting">
                <app-waiting [callback]="reloadChildFunction"></app-waiting>
            </div>

            <div class="tab-pane fade" id="approved" role="tabpanel" aria-labelledby="approved">
                <app-approved></app-approved>
            </div>

            <div class="tab-pane fade" id="reject" role="tabpanel" aria-labelledby="reject">
                <app-reject></app-reject>
            </div>
        </div>
    </div>
</section>