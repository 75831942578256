import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { EmployeeListItem } from 'src/app/utils/models/employee/employee-list-item.model';
import { PartListItem } from 'src/app/utils/models/part/part-list-item.model';
import { EmployeeService } from 'src/app/utils/services/aas-network/employee/employee.service';
import { PartService } from 'src/app/utils/services/aas-network/part/part.service';

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.scss']
})
export class EmployeeListComponent implements OnInit {

  didInit = false;

  parts: PartListItem[] = [];
  partsPage = 1;
  partsMaxsize = 10;
  partsTotalElement = 0;
  loading = false;
  numberOfItemsFromEndBeforeFetchingMore = 5;

  employees: EmployeeListItem[] = [];
  page = 1;
  maxSize = 10;
  propertiesSort = "modified";
  sort = "DESC";
  totalElement = 0;

  keySearch: string = "";
  status: string = "";
  partId: string = null;

  maxSizeDropDown = [10, 20, 30, 40];

  constructor(
    private router: Router,
    private partService: PartService,
    private employeeService: EmployeeService
  ) { }

  ngOnInit(): void {
    this.viewDidLoad();
  }

  viewDidLoad() {
    this.loadPage(1);
    this.loadPartsDropList(false, 1);
  }

  getClassStatus(status) {
    var st = `${status}`;

    if (st === "1") return "badge-danger-new";
    if (st === "0") return "badge-success-new";
    return "";
  }

  getTextStatus(status) {
    var st = `${status}`;

    if (st === "1") return "Ngưng hoạt động";
    if (st === "0") return "Đang hoạt động";
    return "";
  }

  cleanFilter() {
    // this.keySearch = "";
    this.status = "";
    this.partId = null;
    this.keySearch = "";
    this.loadPage(1);
  }

  searchWithFilter() {
    this.loadPage(1);
  }

  enterSearch() {
    console.log("enterSearch");
    this.loadPage(1);
  }

  changeMaxSize(maxSize) {
    this.maxSize = maxSize;
    this.loadPage(1);
  }

  loadPage(page) {
    if (page > 0) {
      this.page = page;
      this.getEmployeeList(this.page, this.maxSize, this.propertiesSort, this.sort, this.status, this.partId || "", this.keySearch)
    }
  }

  detailEmployee(partyId) {
    this.router.navigate([ConstantUrl.EMPLOYEE_DETAIL, partyId]);
  }

  editEmployee(partyId) {
    this.router.navigate([ConstantUrl.EMPLOYEE_EDIT, partyId]);
  }

  addEmployee() {
    this.router.navigate([ConstantUrl.EMPLOYEE_ADD]);
  }

  /**
   * Load droplist chon bo phan
   * @param page 
   */
  private loadPartsDropList(isLoadMore, page) {
    this.partsPage = page;
    this.getParts(isLoadMore, this.partsPage, this.partsMaxsize, "", "DESC", "", "");
  }
  
  /**
   * Su kien scroll xuong duoi cung cua droplist
   * @returns 
   */
  onScrollToEnd() {
    var nextPage = this.partsPage + 1;
    var nextLength = nextPage * this.partsMaxsize;

    if ((this.parts.length >= this.partsTotalElement) || (nextLength - this.partsTotalElement >= this.partsMaxsize)) return;
    this.loadPartsDropList(true, this.partsPage + 1);
  }

  onScroll({ end }) {
  }

  //MARK: UTILS
  hideSpinner() {
    return !this.loading;
  }

  //MARK: NETWOKR
  getParts(isLoadMore = false, page, maxSize, propertiesSort, sort, status, keySearch) {
    this.loading = isLoadMore;
    var currentParts = [];
    this.partService.getParts(page, maxSize, propertiesSort, sort, status, keySearch).subscribe((res: any) => {
      if (res.object && res.object.data && res.object.data instanceof Array) {
        res.object.data.forEach(item => {
          currentParts.push(new PartListItem(item));
        });
      }
      this.parts = this.parts.concat(currentParts);
      console.log(this.parts);
      this.partsTotalElement = res?.object?.totalElement || 0;
      this.loading = false;
    }, error => {
      this.loading = false;
    });
  }

  getEmployeeList(page, maxSize, propertiesSort, sort, status, partId, keySearch) {
    this.employeeService.getEmployeeList(page, maxSize, propertiesSort, sort, status, partId, keySearch).subscribe((res: any) => {
      this.employees = [];

      if (res.object && res.object.data && res.object.data instanceof Array) {
        res.object.data.forEach(item => {
          this.employees.push(new EmployeeListItem(item));
        });
      }

      this.totalElement = res?.object?.totalElement || 0;
    });
  }
}
