import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Options } from 'select2';
import { Select2OptionData } from 'ng-select2';
import numeral from 'numeral';
import { ChartDataSets, ChartOptions, ChartType} from 'chart.js';
import { Color, Label, MultiDataSet, SingleDataSet} from 'ng2-charts';
import { ActivatedRoute, Router } from '@angular/router';
import {ContractReportItem,ContractYearItem,DetailInfoItem} from 'src/app/utils/models/aas-models/copyright-manager/copyright-detail/detail-info/detail-info-item.model'
import { ContractYearItemResponse } from 'src/app/utils/models/aas-models/copyright-manager/copyright-detail/detail-info/contract-year-response.model'
import { ListContractReportResponse } from 'src/app/utils/models/aas-models/copyright-manager/copyright-detail/detail-info/list-contract-report-response.modes'
import { DetailItemResponse } from 'src/app/utils/models/aas-models/copyright-manager/copyright-detail/detail-info/detail-info-response.model'
import { AasLicensesService } from 'src/app/utils/services/aas-network/aas-licenses-manager.service' 
import { ContractStatus } from 'src/app/utils/common/constant';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';


@Component({
  selector: 'app-detail-info',
  templateUrl: './detail-info.component.html',
  styleUrls: ['./detail-info.component.scss']
})
export class DetailInfoComponent implements OnInit {
  licenseId = this.router.snapshot.paramMap.get('id');
  
  dropListStatus: Array<Select2OptionData> = [
    {
      id: 'ENABLED',
      text: 'Đang hoạt động'
    },
    {
      id: 'DISABLED',
      text: 'Khóa'
    }
  ];
  dataLineChar: Array<any>= [];

  // vẽ biểu đồ line chart
  chartType: string = 'line';

  chartDatasets: Array<any> = [];

  chartLabels: Array<any> = [];

  chartColors: Array<any> = [ ];

  chartOptions: any = {
    responsive: true
   
  };
  ///////////////////////////////////
   // vẽ biểu đồ tròn thống kê hợp đồng
   public doughnutChartType: string = 'doughnut';

   public doughnutChartDatasets: Array<any> = [];
 
   public doughnutChartLabels: Array<any> = [];
 
   public doughnutCharOption = {
   }
 //////////////////////////////////////////////


 listContractReportRes : ListContractReportResponse;
 listContractReport : ContractReportItem[] = [];
 contractYear: ContractYearItem;
 listContractYear: Array<any> = [];
 contractYearRes: ContractYearItemResponse;
 detailInfo : DetailInfoItem;
 detailInfoRes : DetailItemResponse;


 year : any;
 currentStage= ContractStatus;


  public options: Options;
  form: FormGroup;
  constructor(
    private fb: FormBuilder,
    private router: ActivatedRoute,
    private route: Router,
    private licensesService: AasLicensesService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.makeForm();
    this.getListInfo()
    this.options = {
      multiple: false,
      width: '100%',
      closeOnSelect: true,
      language: 'vi',
      dropdownAutoWidth: true,
    };
  }

  makeForm() {
    this.form = this.fb.group({
      licenseId: [''],
      packageName: ['', Validators.required],
      tenKhachHang: ['', Validators.required],
      issueDate: ['', Validators.required],
      expirationDate: ['', Validators.required],
      status: ['ENABLED', Validators.required]
    })
  }
  getListInfo(){
    this.getLicensesInfo();
    this.getListContractYear();
    this.getListContractRepor();
  
  }


  // danh sách giá trị hợp đồng 12 tháng trong năm(vẽ line chart)
  getListContractYear(){
    var today = new Date();
    this.year = today.getFullYear()
    this.licensesService.getContractYear(this.licenseId, this.year).subscribe((res)=>{
      console.log(res)
      this.contractYearRes = new ContractYearItemResponse(res) ;
      this.contractYear = this.contractYearRes.object;
      this.listContractYear.push(this.contractYear.value1)
      this.listContractYear.push(this.contractYear.value2)
      this.listContractYear.push(this.contractYear.value3)
      this.listContractYear.push(this.contractYear.value4)
      this.listContractYear.push(this.contractYear.value5)
      this.listContractYear.push(this.contractYear.value6)
      this.listContractYear.push(this.contractYear.value7)
      this.listContractYear.push(this.contractYear.value8)
      this.listContractYear.push(this.contractYear.value9)
      this.listContractYear.push(this.contractYear.value10)
      this.listContractYear.push(this.contractYear.value11)
      this.listContractYear.push(this.contractYear.value12)
       // add data cho line char
      this.chartType = 'line';
      this.chartLabels = ['Tháng 1', 'Tháng 2', 'Tháng 3', 'Tháng 4', 'Tháng 5', 'Tháng 6', 'Tháng 7','Tháng 8', 'Tháng 9', 'Tháng 10', 'Tháng 11', 'Tháng 12'];
      this.chartColors = [
        {
          borderColor: '#0062FF',
          borderWidth: 2,
          borderRadius: 10,
        },
        
      ];
      this.listContractYear.forEach(Item=>{
        this.dataLineChar.push(Item)
      })
      // this.dataLineChar = [50,60,45,70,0,67,0,20,0,30,68,40]
      // this.dataLineChar = [0,0,0,0,0,0,0,0,0,0,0,0]
      this.chartDatasets = [
        { data: this.dataLineChar, label: 'Tổng giá trị hợp đồng ' },
      ];

      this.chartOptions = {
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              callback: function(value, index, values) {
                let val: any;
                val = value/1000000
                if(value > 1)
                {
                  if(parseInt(val) >= 1000){
                    // return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + 'T';
                    let num = numeral(val).format('0,0')
                    return num.toString() + 'T';
                  }
                  else {
                    return val.toString() + 'T';
                  }
                }
                else
                {
                  return value.toString() + 'T';
                }
                
               
              }
            }
          }]
        },

        tooltips: {
          callbacks: {
            label: function (tooltipItem, data) {
              var label = data.datasets[tooltipItem.datasetIndex].label || ''
      
              if (label) {
                label += ': '
              }
              label += numeral(tooltipItem.yLabel).format('0,0')
              return label + 'VNĐ'
            },
          },
        },
      }
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.ALL_IN_ONE);
    })
  }

  // danh sách thống kê hợp đồng(vẽ biểu đồ doughnut)
  getListContractRepor(){
    this.licensesService.getContractReport(this.licenseId).subscribe((res)=>{
      this.listContractReportRes = new ListContractReportResponse(res);
      this.listContractReport = this.listContractReportRes.data

      // add data cho doughnut char
    let dataDoughnutChar: Array<number> = []
    let backgroundColor : Array<any> = []
    let hoverBackgroundColor : Array<any> = []
    let total = 0

    this.listContractReport.forEach(Item=>{
      total = total + Item.count;
      dataDoughnutChar.push(Item.count)
      this.doughnutChartLabels.push(this.currentStage[Item.currentStage].label)
      backgroundColor.push(this.currentStage[Item.currentStage].color)
    })

    for(let i= 0 ; i < dataDoughnutChar.length; i++ ){
      hoverBackgroundColor.push('#A3A3A3')
    }
  
    this.doughnutChartDatasets = [
      { data: dataDoughnutChar, label: 'Thống kê hợp đồng', backgroundColor: backgroundColor,
      hoverBackgroundColor: hoverBackgroundColor }
    ];
    this.doughnutCharOption = {
      responsive: true,
      width:600,
      height:600,
      cutoutPercentage: 70,
      elements: {
      center: {
        text: total.toString(),
        color: '#000000', 
        fontStyle: 'Arial', 
        sidePadding: 20, 
        minFontSize: 25,
        lineHeight: 25 
      }},
      legend: {
        display: true,
        position: 'bottom'
      },
    }

  })
    
  }

  //get thông tin chung bản quyền gói dv
  getLicensesInfo(){
    this.licensesService.getLicensesInfo(this.licenseId).subscribe((res)=>{
      this.detailInfoRes = new DetailItemResponse(res);
      this.detailInfo = this.detailInfoRes.object;

      this.form.setValue({
        licenseId : this.detailInfo.licenseId,
        tenKhachHang : this.detailInfo.tenKhachHang,
        packageName: this.detailInfo.packageName,
        issueDate : this.convertToDateObj(this.detailInfo.issueDate),
        expirationDate: this.convertToDateObj(this.detailInfo.expirationDate),
        status: this.detailInfo.status,

      })

    })
  }
  chartClicked(e: any) { }
  chartHovered(e: any) { }

  convertToDateObj(dataString) {
    let d = new Date(dataString);
    let localTiming = d.toLocaleDateString('en-GB');
    return localTiming;
  }

    //MARK: Popup thông báo
    alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
      const modalRef = this.modalService.open(AlertComponent);
      modalRef.componentInstance.message = msg;
      modalRef.componentInstance.typeAlert = type;
      modalRef.componentInstance.twoBtn = twoBtn;
      modalRef.componentInstance.iconBtn1 = iconBtn1;
      modalRef.componentInstance.iconBtn2 = iconBtn2;
      modalRef.componentInstance.titleButton1 = titleBtn1;
      modalRef.componentInstance.titleButton2 = titleBtn2;
      modalRef.componentInstance.callback1 = actBtn1;
      modalRef.componentInstance.callback2 = actBtn2;
    }

   //MARK: Handle Error
   handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], 'error');
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, 'error');
    } else {
      this.alert('Lỗi không xác định! Vui lòng thử lại', 'error');
    }
  }


}
