<div class="pb-3">
    <div class="card card-body">
        <div class="row">
            <div class="col-md-8">
                <label class="text-primary">Thông tin chung</label>
                <hr>
                <div class="e-block-field row">
                    <div class="col-md-5 col-xl-4">
                        <label class="e-lb-field">Tên gói dịch vụ</label>
                    </div>
                    <div class="col-md-7">
                        <span class=""> {{detail?.name}} </span>
                    </div>
                </div>
                <div class="e-block-field row">
                    <div class="col-md-5 col-xl-4">
                        <label class="e-lb-field">Mô tả</label>
                    </div>
                    <div class="col-md-7">
                        <span class=""> {{ detail?.description }} </span>
                    </div>
                </div>
                <!-- <div class="e-block-field row">
                    <div class="col-md-5 col-xl-4">
                        <label class="e-lb-field">Thời hạn sử dụng</label>
                    </div>
                    <div class="col-md-7">
                        <span *ngIf="detail?.packageType == 'NORMAL_TYPE'" class="">Vĩnh
                            viễn</span>
                        <span *ngIf="detail?.packageType != 'NORMAL_TYPE'" class="">
                            {{ detail?.duration }} tháng </span>
                    </div>
                </div> -->
                <div class="e-block-field row">
                    <div class="col-md-5 col-xl-4">
                        <label class="e-lb-field">Giá tiền</label>
                    </div>
                    <div class="col-md-7">
                        <span class=""> {{ formatPrice(detail?.price) }} VND </span>
                    </div>
                </div>
                <div class="e-block-field row">
                    <div class="col-md-5 col-xl-4">
                        <label class="e-lb-field">Trạng thái</label>
                    </div>
                    <div class="col-md-7">
                        <span class="badge {{ getStyleAndValueBadge(detail?.status)[0] }} p-2">
                            {{ getStyleAndValueBadge(detail?.status)[1] }}
                        </span>
                    </div>
                </div>

                <label class="text-primary">Thông tin hạ tầng</label>
                <hr>
                <div class="table-responsive" style="max-width: 600px"
                    *ngIf="listPackageInfra && listPackageInfra.length > 0">
                    <table class="table table-sort-col mb-0">
                        <thead>
                            <tr class="table-header">
                                <th>STT</th>
                                <th>
                                    <span class="d-flex align-self-center align-items-center"
                                        (click)="sortCol('thongSo')">
                                        Thông số
                                        <span *ngIf="this.sortColConfig['thongSo'] == null"
                                            class="material-icons ml-auto">
                                            unfold_more
                                        </span>
                                        <span *ngIf="this.sortColConfig['thongSo'] == true"
                                            class="material-icons ml-auto">
                                            expand_less
                                        </span>
                                        <span *ngIf="this.sortColConfig['thongSo'] == false"
                                            class="material-icons ml-auto">
                                            expand_more
                                        </span>
                                    </span>
                                </th>
                                <th>
                                    <span class="d-flex align-self-center align-items-center"
                                        (click)="sortCol('donViTinh')">
                                        Đơn vị tính
                                        <span *ngIf="this.sortColConfig['donViTinh'] == null"
                                            class="material-icons ml-auto">
                                            unfold_more
                                        </span>
                                        <span *ngIf="this.sortColConfig['donViTinh'] == true"
                                            class="material-icons ml-auto">
                                            expand_less
                                        </span>
                                        <span *ngIf="this.sortColConfig['donViTinh'] == false"
                                            class="material-icons ml-auto">
                                            expand_more
                                        </span>
                                    </span>
                                </th>
                                <th>
                                    <span class="d-flex align-self-center align-items-center"
                                        (click)="sortCol('soLuong')">
                                        Số lượng
                                        <span *ngIf="this.sortColConfig['soLuong'] == null"
                                            class="material-icons ml-auto">
                                            unfold_more
                                        </span>
                                        <span *ngIf="this.sortColConfig['soLuong'] == true"
                                            class="material-icons ml-auto">
                                            expand_less
                                        </span>
                                        <span *ngIf="this.sortColConfig['soLuong'] == false"
                                            class="material-icons ml-auto">
                                            expand_more
                                        </span>
                                    </span>
                                </th>
                                <th>
                                    <span class="d-flex align-self-center align-items-center"
                                        (click)="sortCol('trangThai')">
                                        Trạng thái sử dụng
                                        <span *ngIf="this.sortColConfig['trangThai'] == null"
                                            class="material-icons ml-auto">
                                            unfold_more
                                        </span>
                                        <span *ngIf="this.sortColConfig['trangThai'] == true"
                                            class="material-icons ml-auto">
                                            expand_less
                                        </span>
                                        <span *ngIf="this.sortColConfig['trangThai'] == false"
                                            class="material-icons ml-auto">
                                            expand_more
                                        </span>
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of listPackageInfra; let i = index">
                                <td>
                                    <span *ngIf="i < 9">0</span>{{ i + 1 }}
                                </td>
                                <td>
                                    {{ item?.name }}
                                </td>
                                <td>
                                    {{ item?.unit }}
                                </td>
                                <td style="text-align: center;">
                                    {{ item?.allowed }}
                                </td>
                                <td style="text-align: center;">
                                    {{ item?.used }}/{{ item?.allowed }} <span
                                        class="{{getStyleOfRate(item?.rate)}}">
                                        ({{ item?.rate }}%)</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <label class="text-primary pt-4">Thông tin chức năng</label>
                <hr>
                <div class="row">
                    <div class="col-md-9">
                        <div *ngIf="listService.length > 0">
                            <div *ngFor="let item of listService; let i = index">
                                <div class="parent pt-1">
                                    <span class="material-icons expand-icon p-2"
                                        [style.transform]="item.privilegesHidden ? 'rotate(0deg)' : 'rotate(90deg)'"
                                        style="cursor: pointer;"
                                        (click)="toggleService(i)">chevron_right</span>

                                    <p class="d-inline-block service-label">{{ item?.name }}</p>
                                </div>
                                <div *ngIf="!item.privilegesHidden">
                                    <div *ngFor="let child of item.privileges">
                                        <div class="children">
                                            <p class="d-inline-block service-label child-label pt-3">{{
                                                child?.description }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</div>
