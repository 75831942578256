
<div class="wrapper">
    <div class="preview-overlay">

        <div class="preview-container">
            <div class="preview-header d-flex">
                <div (click)="close()"
                    class="ct-header-btn-back d-flex justify-content-center align-items-center cursor-pointer">
                    <span class="material-icons">close</span>
                </div>
                <div class="ct-title flex-grow-1 d-flex align-items-center px-3">
                    <h4 class="mb-0">Thông tin các bên tham gia hợp đồng</h4>
                </div>
                <div class="ct-toolbar-btn d-flex align-items-center">
                    <button (click)="saveForm()" class="btn btn-primary d-flex align-items-center mr-3"><span
                            class="material-icons mr-2">save</span> Lưu</button>
                </div>
            </div>

            <div class="preview-body">
                <div class="pv ct">
                    <div class="pv-body">
                        <div class="ctpv-container">
                            <div class="ctpv-content">
                                <div class="ct-container">
                                    <div class="ct-content" style="left: 0;">
                                        <div class="ct-content-enable-scroll enable-scroll">
                                            <div class="card ec-card shadow-none p-3">
                                                <div class="table-responsive">
                                                    <div class="">
                                                        
                                                        <table class="table table-bordered custom-table">
                                                            <thead class="bg-light">
                                                                <tr>
                                                                    <th tyle="width: 100px;">STT</th>
                                                                    <th style="min-width: 200px;">Bên tham gia</th>
                                                                    <th>Loại khách hàng</th>
                                                                    <th>Hình thức xác minh</th>
                                                                    <th>Hình thức ký</th>
                                                                    <th style="width: 150px;">Thứ tự ký</th>
                                                                    <!-- <th>Thời hạn ký</th> -->
                                                                    <th>Thao tác</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let item of parties; let i = index">
                                                                    <td>{{ i + 1 }}</td>
                                                                    <td [class.bg-danger-tb]="isSubmit && item.hasError('partyId')">
                                                                        <div class="form-group mb-0">
                                                                            <ng-select
                                                                                [disabled]="item.isOwner"
                                                                                [(ngModel)]="item.partyId"
                                                                                [ngModelOptions]="{ standalone: true }"
                                                                                [items]="item.dropListPartData | async"
                                                                                bindLabel="email"
                                                                                bindValue="partyId"
                                                                                [trackByFn]="item.trackByFn" 
                                                                                [loading]="item.partsLoading"
                                                                                [typeahead]="item.partsInput$"
                                                                                [placeholder]="'Chọn bên tham gia'"
                                                                                (change)="item.changeParty($event)"
                                                                                appendTo=".wrapper"
                                                                            >
                                                                                <ng-template ng-label-tmp let-item="item">
                                                                                    <span *ngIf="item.isTrustedPartner == 'Y'" class="badge bg-primary text-white p-2">{{item.email}}</span>
                                                                                    <span *ngIf="item.isTrustedPartner == 'N'">{{item.email}}</span>
                                                                                </ng-template>
                                                                                <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                                                                    <div>
                                                                                        <span class="font-weight-bold">{{ item.email }}</span><br>
                                                                                        <span *ngIf="item.tenToChuc || item.tenDaiDien">{{ item.tenToChuc || item.tenDaiDien }}</span><br>
                                                                                        <span *ngIf="item.partyType == 'CONSUMER'" class="badge badge-light-new text-grey-darkest p-2">Cá nhân</span>
                                                                                        <span *ngIf="item.partyType == 'BUSINESS'" class="badge badge-light-new text-grey-darkest p-2">Tổ chức</span>
                                                                                        <span *ngIf="item.cmnd || item.maSoThue" class="badge badge-light-new text-grey-darkest p-2 ml-2">{{ item.cmnd || item.maSoThue }}</span>
                                                                                    </div>
                                                                                </ng-template>
                                                                                
                                                                            </ng-select>
                                                                        </div>
                                                                    </td>
                                                                    <td [class.bg-danger-tb]="isSubmit && item.hasError('userType')">
                                                                        <ng-select
                                                                            [disabled]="item.isOwner || !item.allowEditUserType"
                                                                            [(ngModel)]="item.userType"
                                                                            [items]="item.userTypeData"
                                                                            bindLabel="text"
                                                                            bindValue="id"
                                                                            [ngModelOptions]="{ standalone: true }"
                                                                            [placeholder]="'Chọn loại khách hàng'"
                                                                            (change)="item.changeUserType($event)"
                                                                            appendTo=".wrapper"
                                                                        >

                                                                        </ng-select>
                                                                    </td>
                                                                    <td [class.bg-danger-tb]="isSubmit && item.hasError('verificationType')">
                                                                        <ng-select
                                                                            *ngIf="!item.isOwner"
                                                                            [disabled]="!item.allowEdit"
                                                                            [(ngModel)]="item.verificationType"
                                                                            [items]="item.hinhThucXacMinh"
                                                                            bindLabel="text"
                                                                            bindValue="id"
                                                                            [ngModelOptions]="{ standalone: true }"
                                                                            [placeholder]="'Chọn hình thức xác minh'"
                                                                            (change)="item.changeVerificationType($event)"
                                                                            appendTo=".wrapper"
                                                                        >

                                                                        </ng-select>
                                                                    </td>
                                                                    <td [class.bg-danger-tb]="isSubmit && item.hasError('signForm')">
                                                                        <div class="form-group mb-0">
                                                                            <ng-select
                                                                                *ngIf="!item.isOwner"
                                                                                [disabled]="!item.allowEdit"
                                                                                [(ngModel)]="item.signForm"
                                                                                [items]="item.hinhThucKyData"
                                                                                bindLabel="text"
                                                                                bindValue="id"
                                                                                [ngModelOptions]="{ standalone: true }"
                                                                                [placeholder]="'Chọn hình thức ký'"
                                                                                (change)="item.changeSignForm($event)"
                                                                                appendTo=".wrapper"
                                                                            >

                                                                            </ng-select>
                                                                        </div>
                                                                    </td>
                                                                    <td [class.bg-danger-tb]="isSubmit && item.hasError('sequence')">
                                                                        <input appOnlyNumber [(ngModel)]="item.sequence" placeholder="Nhập số thứ tự"
                                                                            class="form-control text-right mb-0" type="text" (input)="checkValidSequence()">
                                                                    </td>
                                                                    <td>
                                                                        <button *ngIf="!item.isOwner && item.allowDetete" 
                                                                                class="btn btn-icon btn-delete"
                                                                                (click)="deleteRow(i)">
                                                                                <span class="material-icons">delete</span>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                    
                                                <div *ngIf="isSubmit" class="d-flex flex-column w-100 border-top pt-3 px-3">
                                                    <span *ngIf="!validRequired" class="w-100 text-danger">• Vui lòng nhập đầy đủ thông tin của các bên tham gia hợp đồng</span>
                                                    <span *ngIf="!validSequence" class="w-100 text-danger">• Thứ tự ký không được trùng nhau, phải lớn hơn 0 và phải nhỏ hơn hoặc bằng số bên tham gia hợp đồng </span>
                                                </div>
                                    
                                                <div class="d-flex w-100 modal-toolbar-table">
                                                    <a (click)="addRowPartyJoin();" class="text-decoration-underline cursor-pointer text-primary mr-auto mt-3">
                                                        <span>+ Thêm mới</span>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>