import { Injectable } from '@angular/core';
import { ServicePath } from '../../common/constant-service-api';
import { API } from '../api';

const CATALOG_GENDER = ServicePath.MANAGEMENT_SERVICE + '/dm-gioi-tinh';
const CATALOG_TINH = ServicePath.MANAGEMENT_SERVICE + '/dm-tinh';
const CATALOG_HUYEN = ServicePath.MANAGEMENT_SERVICE + '/dm-huyen';
const CATALOG_XA = ServicePath.MANAGEMENT_SERVICE + '/dm-xa';

@Injectable({
  providedIn: 'root'
})
export class CatalogService {

  constructor(private api: API) { }

  /**
   * GET Danh mục giới tính
   */
  getListGender() {
    const url = CATALOG_GENDER;
    return this.api.get(url);
  }

  /**
   * GET Danh mục tỉnh
   */
  getListTinh() {
    const url = CATALOG_TINH;
    return this.api.get(url);
  }

  /**
   * GET danh mục huyện theo tỉnh
   * @param idTinh 
   */
  getListHuyen(idTinh) {
    const url = CATALOG_TINH + `/${idTinh}/dm-huyen`;
    return this.api.get(url)
  }

  /**
   * GET danh mục xã theo huyện
   * @param idHuyen 
   */
  getListXa(idHuyen) {
    const url = CATALOG_HUYEN + `/${idHuyen}/dm-xa`;
    return this.api.get(url);
  }

  /**
   * GET chi tiết tỉnh
   * @param idTinh 
   */
  getDetailTinh(idTinh) {
    const url = CATALOG_TINH + `/${idTinh}`;
    return this.api.get(url);
  }

  /**
   * GET chi tiết huyện
   * @param idHuyen 
   */
  getDetailHuyen(idHuyen) {
    const url = CATALOG_HUYEN + `/${idHuyen}`;
    return this.api.get(url);
  }

  /**
   * GET chi tiết xã
   * @param idXa 
   */
  getDetailXa(idXa) {
    const url = CATALOG_XA + `/${idXa}`;
    return this.api.get(url);
  }
}
