<app-spinner></app-spinner>
<div class="container-fluid">
    <div class="row login-grid">
        <div class="col-md-5 left-view-bg">

        </div>
        <div class="col-md-7 right-view-login p-4 bg-white">
            <div class="row justify-content-center my-3 py-5">
                <div class="col-md-10">
                    <h2 class="font-weight-bold text-left mb-5">Đăng ký tài khoản tổ chức</h2>

                    <form [formGroup]="formBusiness">
                        <h4 class="mb-4">Thông tin tổ chức</h4>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="input-group input-custom mb-5">
                                    <span class="input-custom-label">Tên doanh
                                        nghiệp <span class="text-danger">*</span></span>
                                    <input appTrimValue formControlName="tenDoanhNghiep" type="text"
                                        class="form-control form-control-lg input-custom-control"
                                        placeholder="Nhập tên doanh nghiệp" />
                                    <app-show-validate-errors [isSubmit]="isSubmit"
                                        [errorMessages]="validMsg.tenDoanhNghiep" [control]="tenDoanhNghiep"
                                        [detail]="{ name: 'tenDoanhNghiep' }">
                                    </app-show-validate-errors>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input-group input-custom mb-5">
                                    <span class="input-custom-label">Tên viết
                                        tắt <span class="text-danger">*</span></span>
                                    <input appTrimValue formControlName="tenVietTat" type="text"
                                        class="form-control form-control-lg input-custom-control"
                                        placeholder="Nhập tên viết tắt" />
                                    <app-show-validate-errors [isSubmit]="isSubmit"
                                        [errorMessages]="validMsg.tenVietTat" [control]="tenVietTat"
                                        [detail]="{ name: 'tenVietTat' }">
                                    </app-show-validate-errors>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-md-6">
                                <div class="input-group input-custom mb-5">
                                    <span class="input-custom-label">Mã số
                                        thuế <span class="text-danger">*</span></span>
                                    <input appTrimValue formControlName="maSoThue" type="text"
                                        class="form-control form-control-lg input-custom-control"
                                        placeholder="Nhập mã số thuế" />
                                    <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="validMsg.maSoThue"
                                        [control]="maSoThue" [detail]="{ name: 'maSoThue' }">
                                    </app-show-validate-errors>
                                </div>
                            </div>
                        </div>

                        <h4 class="mb-4">Thông tin người đại diện</h4>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="input-group input-custom mb-5">
                                    <span class="input-custom-label">Họ và tên <span class="text-danger">*</span></span>
                                    <input appTrimValue formControlName="hoVaTen" type="text"
                                        class="form-control form-control-lg input-custom-control"
                                        placeholder="Nhập họ và tên" />
                                    <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="validMsg.hoVaTen"
                                        [control]="hoVaTen" [detail]="{ name: 'hoVaTen' }">
                                    </app-show-validate-errors>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input-group input-custom mb-5">
                                    <span class="input-custom-label">Email <span class="text-danger">*</span></span>
                                    <input appTrimValue formControlName="email" type="email"
                                        class="form-control form-control-lg input-custom-control"
                                        placeholder="Nhập email" />
                                    <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="validMsg.email"
                                        [control]="email" [detail]="{ name: 'email' }">
                                    </app-show-validate-errors>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 col-lg-6">
                                <div class="input-group input-custom mb-5">
                                    <span class="input-custom-label">Số điện thoại</span>
                                    <input appTrimValue (input)="handleInputSdt($event)" formControlName="sdt" type="text"
                                        class="form-control form-control-lg input-custom-control"
                                        placeholder="Nhập số điện thoại" />
                                    <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="validMsg.sdt"
                                        [control]="sdt" [detail]="{ name: 'sdt' }">
                                    </app-show-validate-errors>
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-6">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="input-group input-custom mb-5">
                                            <span class="input-custom-label">Ngày sinh:</span>
                                            <input (input)="dateInput($event, ngaySinh)" type="text"
                                                formControlName="ngaySinh"
                                                class="form-control form-control-lg input-custom-control date"
                                                placeholder="dd/MM/yyyy" id="ngaySinh">
                                            <app-show-validate-errors [isSubmit]="isSubmit"
                                                [errorMessages]="validMsg.ngaySinh" [control]="ngaySinh"
                                                [detail]="{ name: 'ngaySinh' }">
                                            </app-show-validate-errors>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="input-group input-custom mb-5">
                                            <span class="input-custom-label">Giới tính</span>
                                            <select class="form-control form-control-lg input-custom-control"
                                                formControlName="gioiTinh" placeholder="Chọn giới tính">
                                                <option value="1">Nam</option>
                                                <option value="0">Nữ</option>
                                            </select>
                                            <app-show-validate-errors [isSubmit]="isSubmit"
                                                [errorMessages]="validMsg.gioiTinh" [control]="gioiTinh"
                                                [detail]="{ name: 'gioiTinh' }">
                                            </app-show-validate-errors>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 col-lg-6">
                                <div class="form-group input-custom mb-5">
                                    <span for="tinhId" class="input-custom-label">Tỉnh/Thành phố</span>
                                    <ng-select2 formControlName="tinhId" [data]="tinhData | async" [options]="options"
                                        (valueChanged)="tinhChange($event)" [placeholder]="'Chọn tỉnh/thành phố'">
                                    </ng-select2>
                                </div>
                                <!-- <div class="input-group input-custom mb-5">
                                    <span class="input-custom-label">Địa chỉ</span>
                                    <input formControlName="diaChi" type="text"
                                        class="form-control form-control-lg input-custom-control"
                                        placeholder="Nhập địa chỉ" />
                                    <div class="w-100"
                                        *ngIf="isSubmit && diaChi.invalid && (diaChi.touched || diaChi.dirty || isSubmit)">
                                        <span class="error err-diaChi text-danger"
                                            *ngIf="diaChi.hasError('required')">{{ validMsg.required }}
                                        </span>
                                    </div>
                                </div> -->
                            </div>
                            <div class="col-md-12 col-lg-6">
                                <div class="form-group input-custom mb-5">
                                    <span for="huyenId" class="input-custom-label">Huyện/Quận</span>
                                    <ng-select2 formControlName="huyenId" [data]="huyenData | async" [options]="options"
                                        (valueChanged)="huyenChange($event)" [placeholder]="'Chọn huyện/quận'">
                                    </ng-select2>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="form-group input-custom mb-5">
                                    <span for="xaId" class="input-custom-label">Xã/Phường</span>
                                    <ng-select2 formControlName="xaId" [data]="xaData | async" [options]="options"
                                        (valueChanged)="xaChange($event)" [placeholder]="'Chọn xã/phường'"></ng-select2>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input-group input-custom mb-5">
                                    <span class="input-custom-label">Số nhà</span>
                                    <input appTrimValue formControlName="soNha" type="text"
                                        class="form-control form-control-lg input-custom-control"
                                        placeholder="Nhập số nhà" />
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <div class="input-group input-custom mb-5">
                                    <span class="input-custom-label">Đường</span>
                                    <input appTrimValue formControlName="duong" type="text"
                                        class="form-control form-control-lg input-custom-control"
                                        placeholder="Nhập tên đường" />
                                </div>
                            </div>
                        </div>

                        <h4 class="mb-4">Thông tin tài khoản</h4>
                        <div class="row mb-4">
                            <div class="col-md-6">
                                <div class="input-group input-custom mb-4">
                                    <span class="input-custom-label">Tên tài
                                        khoản <span class="text-danger">*</span></span>
                                    <input appTrimValue formControlName="username" type="text"
                                        class="form-control form-control-lg input-custom-control"
                                        placeholder="Nhập tên tài khoản" />
                                    <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="validMsg.username"
                                        [control]="username" [detail]="{ name: 'username' }">
                                    </app-show-validate-errors>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="input-group input-custom mb-4">
                                    <span class="input-custom-label">Mật khẩu <span class="text-danger">*</span></span>
                                    <input appInputPassword formControlName="password" type="password"
                                        class="form-control form-control-lg input-custom-control"
                                        placeholder="Nhập mật khẩu khẩu" />
                                    <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="validMsg.password"
                                        [control]="password" [detail]="{ name: 'password' }">
                                    </app-show-validate-errors>
                                </div>
                            </div>
                        </div>

                        <h4 class="mb-4">Thông tin Domain</h4>
                        <div class="row mb-4">
                            <div class="col-md-6">
                                <div class="input-group input-custom mb-4">
                                    <span class="input-custom-label">Tên Domain <span class="text-danger">*</span></span>
                                    <input appTrimValue formControlName="domain" type="text"
                                        class="form-control form-control-lg input-custom-control"
                                        placeholder="Nhập tên domain" />
                                    <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="validMsg.domain"
                                        [control]="domain" [detail]="{ name: 'domain' }">
                                    </app-show-validate-errors>
                                </div>
                                <p>Your LMS address will be: http://{{ domain.value }}.econtract.vnpt.vn</p>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 d-flex mb-5">
                                <button (click)="submitRegister()"
                                    class="btn btn-lg btn-primary col-md-4 col-lg-3 b-radius-5px mr-auto">
                                    Hoàn thành
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>