import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AuthService } from 'src/app/utils/services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-aas-header',
  templateUrl: './aas-header.component.html',
  styleUrls: ['./aas-header.component.scss']
})
export class AasHeaderComponent implements OnInit {
  @Output() toggleMenuSidebar: EventEmitter<any> = new EventEmitter<any>();

  public searchForm: FormGroup;
  path = '';
  
  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    public translate: TranslateService) {}

  ngOnInit() {
    this.path = this.activatedRoute.snapshot.url[0].path;
    this.searchForm = new FormGroup({
      search: new FormControl(null)
    });
  }

  logout() {
    this.authService.logout();
  }

  get getUsername() {
    return this.authService.username;
    // return this.authService.jwtDecode(this.authService.getToken()).email;
  }

}
