import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { localize } from 'src/app/utils/localize/localize';
import { DocumentInContractService } from 'src/app/utils/services/aas-network/document-in-contract/document-in-contract.service';

@Component({
  selector: 'app-edit-document',
  templateUrl: './edit-document.component.html',
  styleUrls: ['./edit-document.component.scss']
})
export class EditDocumentComponent implements OnInit {

  @Input() documentDetail: any = null;
  @Input() contractId: string = "";
  @Input() documents: Array<any> = new Array();
  @Output() parentReload = new EventEmitter();

  constructor(
    private documentInContractService: DocumentInContractService,
    private myAlert: AlertControl,
    private fb: FormBuilder
  ) { }

  isSubmit = false;
  localize = localize;
  validateMsgForm = {
    documentName : {
      required: "EditDocument_document_in_contract_name_required"
    },
    fileKey: {
      required: "EditDocument_document_in_contract_file_required"
    },
    documentType: {
      required: "EditDocument_document_in_contract_type_required"
    },
    originalName: {
      required: this.localize.document_in_link_required
    }
  }

  // Var for attach file
  inputFileLabel = "EditDocument_inputFileLabel";
  fileOrLink: boolean = true; // True is File, False is Link

  // Document form
  documentForm: FormGroup;
  documentId: string = "";

  documentTypeOptions = [
    {value: "OTHER", label: "EditDocument_label_1"},
    {value: "REPORT", label: "EditDocument_label_2"},
    {value: "HANDOVER", label: "EditDocument_label_3"},
    {value: "LIQUIDATE", label: "EditDocument_label_4"},
    {value: "ADDENDUM", label: "EditDocument_label_5"},
  ]

  ngOnInit(): void {
    this.makeDocumentForm();

    this.inputFileLabel = this.documentDetail.originalName || 'EditDocument_inputFileLabel';
    this.documentId = this.documentDetail.documentId || '';
  }

  // Chọn hình thức upload tài liệu là File hay Link
  selectFileOrLink(value: boolean) {
    this.fileOrLink = value;

    this.documentForm.controls.fileKey.setValue("");
    this.documentForm.controls.originalName.setValue("");
    this.documentForm.controls.mimeType.setValue("");

    if (this.fileOrLink == true) {
      this.documentForm.controls['originalName'].clearValidators();
      this.documentForm.controls['originalName'].updateValueAndValidity();
      this.documentForm.controls['fileKey'].setValidators([Validators.required]);
      this.documentForm.controls['fileKey'].updateValueAndValidity();
    }
    else {
      this.documentForm.controls['fileKey'].clearValidators();
      this.documentForm.controls['fileKey'].updateValueAndValidity();
      this.documentForm.controls['originalName'].setValidators([Validators.required]);
      this.documentForm.controls['originalName'].updateValueAndValidity();
    }

    this.isSubmit = false;
  }

  makeDocumentForm() {

    this.documentForm = this.fb.group({
      documentName: new FormControl("", [Validators.required]),
      documentType: new FormControl("ADDENDUM", [Validators.required]),
      fileKey: new FormControl(""),
      originalName: new FormControl(""),
      mimeType: new FormControl(""),
      creationNote: new FormControl(""),
    });

    // Xác định tài liệu dạng File hay Link để set required cho đúng trường của documentForm
    if (this.documentDetail.mimeType == null) {
      this.selectFileOrLink(false);
    }
    else {
      this.selectFileOrLink(true);
    }

    this.documentForm.controls.documentName.setValue(this.documentDetail.documentName || '');
    this.documentForm.controls.documentType.setValue(this.documentDetail.documentType || '');
    this.documentForm.controls.fileKey.setValue(this.documentDetail.fileKey || '');
    this.documentForm.controls.originalName.setValue(this.documentDetail.originalName || '');
    this.documentForm.controls.mimeType.setValue(this.documentDetail.mimeType || '');
    this.documentForm.controls.creationNote.setValue(this.documentDetail.creationNote || '');
  }

  resetDocumentForm() {
    this.documentForm.controls.documentName.setValue(this.documentDetail.documentName || '');
    this.documentForm.controls.documentType.setValue(this.documentDetail.documentType || '');
    this.documentForm.controls.fileKey.setValue(this.documentDetail.fileKey || '');
    this.documentForm.controls.originalName.setValue(this.documentDetail.originalName || '');
    this.documentForm.controls.mimeType.setValue(this.documentDetail.mimeType || '');
    this.documentForm.controls.creationNote.setValue(this.documentDetail.creationNote || '');
  }

  onAttachFileChanged(event) {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0]
      this.inputFileLabel = file.name

      this.postFileTemplate(file);
    } else {
      this.inputFileLabel = "EditDocument_inputFileLabel";
      // this.templateForm.controls.file.setErrors({ required: true });
    }
  }

  postFileTemplate(file) {
    const formData = new FormData()
    formData.append('attachFile', file)
    this.documentInContractService.postUploadFileDocument(formData).subscribe((res: any) => {
      if (res && res.object ) {
        this.documentForm.controls.fileKey.setValue(res.object.fileKey);
        this.documentForm.controls.originalName.setValue(res.object.originalName);
        this.documentForm.controls.mimeType.setValue(res.object.mimeType);
      }
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }

  cancel() {
    this.resetDocumentForm();
    this.inputFileLabel = this.documentDetail.originalName || 'EditDocument_inputFileLabel';
    this.isSubmit = false;
  }

  saveEditedDocument() {
    this.isSubmit = true;
    if (this.documentForm.valid) {
      const data = {
        "documentName": this.documentForm.get('documentName').value,
        "documentType": this.documentForm.get('documentType').value,
        "fileKey": this.documentForm.get('fileKey').value,
        "originalName": this.documentForm.get('originalName').value,
        "mimeType": this.documentForm.get('mimeType').value,
        "creationNote": this.documentForm.get('creationNote').value
      };
      this.documentInContractService.editDocument(this.contractId, this.documentId, data).subscribe((res: any) => {
        this.parentReload.emit();
      }, err => {
        this.myAlert.showErrorHandled(err);
      });
    }
  }
}
