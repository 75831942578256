import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Select2OptionData } from 'ng-select2';
import { Options } from 'select2';
import { Param } from 'src/app/utils/models/aas-models/api-manager/param/param.model';
import { ApiManagerService } from 'src/app/utils/services/aas-network/api-manager.service';
import { API_FORM } from 'src/app/utils/common/constant-vaildate-msg';
import { ActivatedRoute, Router } from '@angular/router';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { ConstantAlertMsg } from 'src/app/utils/common/constant-alert-msg';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ApiDetailObject } from 'src/app/utils/models/aas-models/api-manager/api-detail/api-detail-object.model';
import { ApiDetailResponse } from 'src/app/utils/models/aas-models/api-manager/api-detail/api-detail-response.model';
import { ListAllPermisionReponse } from 'src/app/utils/models/user-manager/list-all-permission/list-all-permission-response';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';

const ELEMENT_HEADER = "header";
const ELEMENT_BODY = "body";
const ELEMENT_PARAM = "param";
const ELEMENT_OUTPUT_SUCCESS = "outputSuccess";
const ELEMENT_OUTPUT_ERROR = "outputError";
@Component({
  selector: 'app-service-api-detail',
  templateUrl: './service-api-detail.component.html',
  styleUrls: ['./service-api-detail.component.scss']
})
export class ServiceApiDetailComponent implements OnInit {
  @ViewChild('tableParamsHeader', { static: false }) tableParamsHeader: ElementRef;
  
  apiId = this.activeRoute.snapshot.paramMap.get('id');

  apiForm: FormGroup;
  isSubmitApiForm: boolean = false;
  apiFormMsg = API_FORM;

  optionsMethod: Options;
  methods: Array<Select2OptionData> = [];

  optionsFeature: Options;
  features: Array<Select2OptionData> = [];

  optionsObligatory: Options;
  obligatories: Array<Select2OptionData> = [];

  apiDetail: ApiDetailObject = new ApiDetailObject();
  paramsHeader: Array<Param> = [];
  paramsBody: Array<Param> = [];
  paramsParam: Array<Param> = [];
  paramsOutputSuccess: Array<Param> = [];
  paramsOutputError: Array<Param> = [];

  constructor(
    private fb: FormBuilder,
    private apiService: ApiManagerService,
    private authService: AasAuthService,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.makeApiForm();
    this.makeMethodSelector();
    this.makeFeatureSelector();
    this.makeObligatorySelector();

    this.getDetailApi(this.apiId);
    this.getAllPrivileges();
  }

  /**
   * Khoi tao form api
   */
  makeApiForm() {
    this.apiForm = this.fb.group({
      pathName: ['', [Validators.required]],
      method: ['', [Validators.required]],
      description: ['', [Validators.required]],
      privilegesId: ['', [Validators.required]],
      privilegesName: [''],
      roleId: ['', [Validators.required]],
      exampleHeader: [''],
      exampleBody: ['', [Validators.required]],
      exampleParam: [''],
      exampleOutput: ['']
    })
  }

  /**
   * Khoi tao ngselect2 method
   */
  makeMethodSelector() {
    this.methods = [
      {
        id: 'POST',
        text: 'POST'
      },
      {
        id: 'GET',
        text: 'GET'
      },
      {
        id: 'PUT',
        text: 'PUT'
      },
      {
        id: 'DELETE',
        text: 'DELETE'
      }
    ]

    this.optionsMethod = {
      minimumResultsForSearch: Infinity,
      dropdownAutoWidth: true,
      width: '100%',
      closeOnSelect: true,
      language: "vi"
    }
  }

  /**
   * Khoi tao ngselect2 feature
   */
  makeFeatureSelector() {
    this.optionsFeature = {
      // minimumResultsForSearch: Infinity,
      dropdownAutoWidth: true,
      width: '100%',
      closeOnSelect: true,
      language: "vi"
    }
  }

  /**
   * Khoi tao ngselect obligatory
   */
  makeObligatorySelector() {
    this.obligatories = [
      {
        id: 'true',
        text: 'Có'
      },
      {
        id: 'false',
        text: 'Không'
      }
    ];

    this.optionsObligatory = {
      // minimumResultsForSearch: Infinity,
      dropdownAutoWidth: true,
      width: '100%',
      closeOnSelect: true,
      language: "vi"
    }
  }

  /**
   * Khoi tao du lieu ban dau
   */
  initData(data: ApiDetailObject) {
    this.apiForm.patchValue({
      pathName: data.apiObject.pathName,
      method: data.apiObject.method,
      description: data.apiObject.description,
      roleId: data.apiObject.roleId,
      privilegesId: data.apiObject.privilegesId,
      privilegesName: data.apiObject.privilegesName,
      exampleHeader: data.apiObject.exampleHeader,
      exampleBody: data.apiObject.exampleBody,
      exampleParam: data.apiObject.exampleParam,
      exampleOutput: data.apiObject.exampleOutput
    });

    data.listParamInfo.forEach((item) => {
      switch(item.element) {
        case ELEMENT_HEADER:
          this.paramsHeader.push(new Param(item));
          break;
        case ELEMENT_BODY:
          this.paramsBody.push(new Param(item));
          break;
        case ELEMENT_PARAM:
          this.paramsParam.push(new Param(item));
          break;
        case ELEMENT_OUTPUT_SUCCESS:
          this.paramsOutputSuccess.push(new Param(item));
          break;
        case ELEMENT_OUTPUT_ERROR:
          this.paramsOutputError.push(new Param(item));
          break;
      }
    });
  }

  /**
   * Chuyen doi giua trang thai chinh sua va hien thi tren table
   * @param type 
   * @param index 
   * @param allowEdit 
   */
  changeEditMode(type: string = '', index, allowEdit: boolean = false, fieldEdit: string = '') {
    switch (type) {
      case ELEMENT_HEADER:
        this.paramsHeader[index].addition[fieldEdit] = allowEdit;
        break;
      case ELEMENT_BODY:
        this.paramsBody[index].addition[fieldEdit] = allowEdit;
        break;
      case ELEMENT_PARAM:
        this.paramsParam[index].addition[fieldEdit] = allowEdit;
        break;
      case ELEMENT_OUTPUT_SUCCESS:
        this.paramsOutputSuccess[index].addition[fieldEdit] = allowEdit;
        break;
      case ELEMENT_OUTPUT_ERROR:
        this.paramsOutputError[index].addition[fieldEdit] = allowEdit;
        break;
      default:
        break;
    }
  }

  /**
   * Them dong cho bang
   * @param type 
   */
  addRowTable(type: string = '') {
    switch (type) {
      case ELEMENT_HEADER:
        this.paramsHeader.push(new Param({ element: ELEMENT_HEADER, required: 'false' }));
        break;
      case ELEMENT_BODY:
        this.paramsBody.push(new Param({ element: ELEMENT_BODY, required: 'false' }));
        break;
      case ELEMENT_PARAM:
        this.paramsParam.push(new Param({ element: ELEMENT_PARAM,required: 'false' }));
        break;
      case ELEMENT_OUTPUT_SUCCESS:
        this.paramsOutputSuccess.push(new Param({ element: ELEMENT_OUTPUT_SUCCESS, required: 'false' }));
        break;
      case ELEMENT_OUTPUT_ERROR:
        this.paramsOutputError.push(new Param({ element: ELEMENT_OUTPUT_ERROR, required: 'false' }));
        break;
      default:
        break;
    }
  }

  /**
   * Xoa 1 dong tren bang
   * @param type 
   * @param index 
   */
  removeRowTable(type: string = '', index) {
    switch (type) {
      case ELEMENT_HEADER:
        this.paramsHeader.splice(index, 1);
        break;
      case ELEMENT_BODY:
        this.paramsBody.splice(index, 1);
        break;
      case ELEMENT_PARAM:
        this.paramsParam.splice(index, 1);
        break;
      case ELEMENT_OUTPUT_SUCCESS:
        this.paramsOutputSuccess.splice(index, 1);
        break;
      case ELEMENT_OUTPUT_ERROR:
        this.paramsOutputSuccess.splice(index, 1);
        break;
      default:
        break;
    }
  }

  /**
   * Lay data tu codeblock Request Body
   * @param e 
   */
  getDataExampleBody(e) {
    this.apiForm.controls.exampleBody.setValue(e);
    console.log(this.apiForm.controls.exampleBody.value);
  }

  /**
   * Lay data tu codeblock Request Param
   * @param e 
   */
  getDataExampleParam(e) {
    this.apiForm.controls.exampleParam.setValue(e);
    console.log(this.apiForm.controls.exampleParam.value);
  }

  /**
   * Lay data tu codeblock Response
   * @param e 
   */
  getDataExampleOutput(e) {
    this.apiForm.controls.exampleOutput.setValue(e);
    console.log(this.apiForm.controls.exampleOutput.value);
  }

  /**
   * Nut luu thong tin
   * @param event 
   */
  goToEdit(event) {
    this.router.navigate([ConstantUrl.ADMIN_API_EDIT, this.apiId]);
  }

  /**
   * Nut huy luu thong tin
   * @param event 
   */
  cancel(event) {
    this.router.navigate([ConstantUrl.ADMIN_API_LIST]);
    console.log("canceled");
  }

  //MARK: NETWORKING
  /**
   * Lấy thông tin chi tiết api
   * @param id 
   */
  getDetailApi(id) {
    this.apiService.getDetailApi(id).subscribe((res: any) => {
      this.apiDetail = new ApiDetailResponse(res).object;
      this.initData(this.apiDetail);
    }, err => {
      this.handleError(err, ResponseMsg.MESSAGES.ALL_IN_ONE);
    })
  }

  /**
   * Lay danh sach tat cac ca quyen
   */
  getAllPrivileges() {
    this.authService.getAllPrivileges().subscribe((res: any) => {
      let data = new ListAllPermisionReponse(res).object;

      this.features = data.map((item) => {
        let childs = item.privileges.map((pr) => {
          return {
            id: pr.id,
            text: pr.description
          }
        })
        return { 
          id: item.id,
          text: item.name,
          children: childs
        }
      })

      console.log(this.features);
    }, err => {
      this.handleError(err, ResponseMsg.MESSAGES.ALL_IN_ONE);
    })
  }

  /**
   * Khoi tao thong tin api
   * @param param 
   */
  postCreateApi(param) {
    this.apiService.postCreateApi(param).subscribe((res: any) => {
      this.alert(ConstantAlertMsg.CREATED_API_SUCCESS, ConstantAlertType.SUCCESS);
    }, err => {
      this.handleError(err, ResponseMsg.MESSAGES.ALL_IN_ONE);
    });
  }

  /**
   * Cap nhat thong tin api
   * @param id 
   * @param param 
   */
  putUpdateApi(id, param) {
    this.apiService.putUpdateApi(id, param).subscribe((res: any) => {
      this.alert(ConstantAlertMsg.UPDATED_API_SUCCESS, ConstantAlertType.SUCCESS);
    }, err => {
      this.handleError(err, ResponseMsg.MESSAGES.ALL_IN_ONE);
    });
  }

  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], ConstantAlertType.ERROR);
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, ConstantAlertType.ERROR);
    } else {
      this.alert(ConstantAlertMsg.DEFAULT_ERROR_REQUEST, ConstantAlertType.ERROR);
    }
  }
}
