import { AuthService } from 'src/app/utils/services/auth.service';
import {
  Component,
  OnInit,
  AfterViewInit,
  Renderer2,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter
} from '@angular/core';
import { AppService } from 'src/app/utils/services/app.service';
import * as AdminLte from 'admin-lte';
import { ActivatedRoute, Router } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-menu-sidebar',
  templateUrl: './menu-sidebar.component.html',
  styleUrls: ['./menu-sidebar.component.scss']
})
export class MenuSidebarComponent implements OnInit, AfterViewInit {
  @ViewChild('mainSidebar', { static: false }) mainSidebar;
  @Output() mainSidebarHeight: EventEmitter<any> = new EventEmitter<any>();

  username = '';
  path = '';

  constructor(
    public appService: AppService,
    private authService: AuthService,
    private router : Router,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    this.path = this.activatedRoute.snapshot.url[0].path;
    this.username = this.getUsername;
  }

  ngAfterViewInit() {
    this.mainSidebarHeight.emit(this.mainSidebar.nativeElement.offsetHeight);
    $('[data-widget="treeview"]').each(function () {
      AdminLte.Treeview._jQueryInterface.call($(this), 'init');
    });
  }

  get getUsername() {
    return this.authService.jwtDecode(this.authService.getToken()).user_name;
  }

  logout() {
    this.authService.logout();
  }
}
