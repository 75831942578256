<app-spinner></app-spinner>
<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6">
                <div class="card ec-card shadow-none">
                    <div class="card-header">
                        <div class="d-flex">
                            <div class="card-title-path flex-grow-1">
                                <h1 class="m-0 text-dark card-title"><label>{{ 'PartDetail_title' | translate }}</label></h1>
                            </div>
                            <div class="card-btn-path ml-auto text-right">
                                <button class="btn btn-primary d-inline-flex" (click)="update(id)"><span
                                        class="material-icons mr-1">edit</span>{{ 'btn_update' | translate }}</button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row mb-2">
                            <div class="col-md-4 mb-1">
                                <label class="ec-lb-field">{{ 'PartDetail_label_department_name' | translate }}</label>
                            </div>
                            <div class="col-md-8">
                                <span class="ec-sp-field">{{ part.partyName }}</span>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-4 mb-1">
                                <label class="ec-lb-field">{{ 'PartDetail_label_description' | translate }}</label>
                            </div>
                            <div class="col-md-8">
                                <span class="ec-sp-field">{{ part.descr }}</span>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-4 mb-1">
                                <label class="ec-lb-field">{{ 'PartDetail_label_phone_number' | translate }}</label>
                            </div>
                            <div class="col-md-8">
                                <span class="ec-sp-field">{{ part.phoneNumber }}</span>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-4 mb-1">
                                <label class="ec-lb-field">{{ 'PartDetail_label_fax_number' | translate }}</label>
                            </div>
                            <div class="col-md-8">
                                <span class="ec-sp-field">{{ part.fax }}</span>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-md-4 mb-1">
                                <label class="ec-lb-field">{{ 'status' | translate }}</label>
                            </div>
                            <div class="col-md-8">
                                <span class="ec-sp-field"><span class="badge {{ getClassStatus(part.disable) }} p-2">{{ getTextStatus(part.disable) }}</span></span>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col">
                                <a  class="text-primary text-decoration-underline cursor-pointer" (click)="showListEmployee($event, part.id)">{{ 'PartDetail_label_view_list_personnel' | translate }}</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<ng-template #employeeInPart let-c="close" let-d="dismiss">
    <div class="modal-header border-0 mb-0">
        <h5 class="modal-title" style="font-weight: 600; margin-right: auto;">{{ 'PartDetail_label_list_personnel_in_department' | translate }}
        </h5>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="btn btn-light bg-exit-btn d-inline-flex"><span class="material-icons">
                close
            </span></span>
        </button>
    </div>
    <div class="modal-body py-0">
        <app-employee-in-part #appEmployeeInPart [partyId]="id" (viewDetail)="handleGoToDetailEmployee($event)"></app-employee-in-part>
    </div>
</ng-template>