import { ApiMenusItem } from './api-menus-item.model';
import { MainResponse } from './../../../main-response.model';
export class ApiMenusResponse extends MainResponse {
    object: ApiMenusItem[] = [];

    constructor(data = null) {
        super();
        this.init(data);

        this.object = [];
        if (data.object && data.object instanceof Array) {
            data.object.forEach((item) => {
                this.object.push(new ApiMenusItem(item));
            })
        }
    }
}