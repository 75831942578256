import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { BlogTypeItem } from 'src/app/utils/models/landing-page/blog-type-item.model';
import { BlogTypeResponse } from 'src/app/utils/models/landing-page/blog-type-response.model';
import { LandingpageService } from 'src/app/utils/services/aas-network/landingpage.service';
import { BLOG_TYPE_FORM } from 'src/app/utils/common/constant-vaildate-msg';

@Component({
  selector: 'app-service-landing-page-blog-type-edit',
  templateUrl: './service-landing-page-blog-type-edit.component.html',
  styleUrls: ['./service-landing-page-blog-type-edit.component.scss']
})
export class ServiceLandingPageBlogTypeEditComponent implements OnInit {

  blogTypeId = this.activatedRoute.snapshot.paramMap.get('id');
  blogTypeItem: BlogTypeItem;
  formEditBlogType: FormGroup;
  isSubmit = false;
  validBlogTypeMsg: any = BLOG_TYPE_FORM.EDIT;
  
  constructor(
    private modalService: NgbModal,
    private landingService: LandingpageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder) { }

  ngOnInit(): void {
    this.makeFormEdit();
    this.getBlogType();
  }

  getBlogType(){
    this.landingService.getBlogTypeById(this.blogTypeId)
    .subscribe(
      (res) => {
        let response = new BlogTypeResponse(res);
        //set value
        this.blogTypeItem = response.object;
        //set form value
        this.formEditBlogType.patchValue({ //patchValue: không lỗi nếu sai key
          name: this.blogTypeItem.name
        });
        console.log(this.formEditBlogType);
      },
      (err) => {
        this.handleError(err, err);
      }
    )
  }

  makeFormEdit(){
    this.formEditBlogType = this.fb.group({
      name: ['', Validators.required]
    })
  }

  onSubmitForm(){
    if(this.formEditBlogType.valid){
      this.isSubmit = true;
      let data = this.formEditBlogType.value;
      data.id = this.blogTypeId;
      this.landingService.editBlogType(data)
      .subscribe(
        (res) => {
          this.isSubmit = false;
          this.alert("Cập nhật thành công", "success", false, "", "OK", () =>{
            this.router.navigate(['/console/landing-page/blog-type']);
          })          
        },
        (err) => {
          this.isSubmit = false;
          this.handleError(err, err);
        }
      )
    }
  }

  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], 'error');
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, 'error');
    } else {
      this.alert('Lỗi không xác định! Vui lòng thử lại', 'error');
    }
  }

}
