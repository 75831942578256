import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Options, Select2AjaxOptions } from 'select2';
import { Select2OptionData } from 'ng-select2';
import { FormGroup, FormBuilder, FormControl, Validators, Form, NgModel, AbstractControl, FormArray } from '@angular/forms';
import { ValidateMessage, AasValidateMessage } from 'src/app/utils/common/validate-msg-aas';
import { AasContractService } from 'src/app/utils/services/aas-network/aas-contract.service';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { DateUtils } from 'src/app/utils/common/DateUtils';
import { LoadingService } from 'src/app/utils/services/loading.service';
import { Config } from 'src/app/config';
import { AuthService } from 'src/app/utils/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { ContractObject } from 'src/app/utils/models/aas-models/contract/contract/contract-object.model';
import { ContractResponse } from 'src/app/utils/models/aas-models/contract/contract/contract-response.model';
import * as ClassicEditor from '../../../../../../assets/js/editor/ckeditor';
import { UserTypeConstant, VerificationType } from 'src/app/utils/common/constant';
import { InfoPartyObject } from 'src/app/utils/models/aas-models/info-party/info-party-object';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { PartyJoinContract } from 'src/app/utils/models/aas-models/contract/party-join-contract/party-join-contract';
import { REGEXP_DATE_INPUT, REGEXP_NUMBER } from 'src/app/utils/common/regexp';
import { PackageSessionService } from 'src/app/utils/services/aas-network/package-session/package-session.service';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertBtn, ConstantAlertMsg } from 'src/app/utils/common/constant-alert-msg';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { WebviewURL } from '../../../wv-ultils/wv-const-url';

declare var PDFSign: any;
declare var $: any;
declare var Cleave: any;
declare var vnpt_plugin: any;
declare var PdfSigner: any;
@Component({
  selector: 'app-wv-contract-add',
  templateUrl: './wv-contract-add.component.html',
  styleUrls: ['./wv-contract-add.component.scss']
})
export class WvContractAddComponent implements OnInit {
  @ViewChild('output') output: ElementRef;
  @ViewChild('filename') filename: ElementRef;
  @ViewChild('filebase') filebase: ElementRef;
  @ViewChild('myEditor') myEditor: any;
  @ViewChild('check_non_discuss') set content(content: ElementRef) {
    if(content) { // initially setter gets called with undefined
      this.check_non_discuss = content;
    }
  }
  check_non_discuss: ElementRef;

  public Editor = ClassicEditor;
  public exampleData: Array<Select2OptionData> = [];
  public nguoiNhanData: Array<Select2OptionData> = [];
  public hinhThucKyData: Array<Select2OptionData> = [];
  public options: Options;
  public optionsTemplate: Options;
  public optionsHinhThucKy: Options;
  public ajaxOptions: Select2AjaxOptions;
  public optionsContractTemplate: Options;
  public contractTemplateData: Array<Select2OptionData> = [];

  contractId: string = this.activeRoute.snapshot.paramMap.get('id') || '';
  isUpdate: boolean = false;
  contract: ContractObject = new ContractObject();

  //mark: nhóm chức năng khởi tạo thông tin hợp đồng
  filePDF: File = null;
  inputFileLabel: string = 'File đính kèm';
  fileType: Array<string> = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
  selectedFile: boolean = false;
  fileName: string = '';
  typeSelected: number = 1;

  formContract: FormGroup;
  validContractMsg: any = AasValidateMessage.FORM_CREATE_CONTRACT;
  isSubmitContract: boolean = false;

  hinhThucHienThi = [
    { value: 0, name: "Không hiển thị" },
    { value: 1, name: "Chỉ hiển thị mô tả" },
    { value: 2, name: "Hiển thị mô tả và hình ảnh bên trái" },
    { value: 3, name: "Chỉ hiển thị hình ảnh" },
    { value: 4, name: "Hiển thị mô tả và hình ảnh phía trên" },
    { value: 5, name: "Hiển thị mô tả và hình ảnh dạng hình nền" }
  ]

  selectedAllHinhThucKy: boolean = false;
  hinhThucKy_consumer = [
    { id: 'OTP', text: 'OTP' },
    // { id: 'ONE_TIME_CA', text: 'Ký số 1 lần' },
    { id: 'EKYC', text: 'OTP và eKYC' }
  ];

  hinhThucKy_business = [
    { id: 'USB_TOKEN', text: 'Usb-Token' },
    { id: 'SIGN_SERVER', text: 'VNPT ký số' }
  ]

  hinhThucXacMinh = [
    { id: VerificationType.VIDEO_CALL, text: 'Xác minh' },
    { id: VerificationType.NONE, text: 'Không xác minh' }
  ]

  //Tab controll
  activeTab: string = 'INFO';

  currentDate = new Date();
  dateType = {
    ISSUE: 'ISSUE',
    EXPRIRED: 'EXPRIRED'
  }

  partyInfo: InfoPartyObject = new InfoPartyObject();
  partiesJoin: Array<PartyJoinContract> = [];
  showPartyJoin: boolean = false;

  userTypeConst = UserTypeConstant;

  optionsCleave: any;
  optionsCleaveCost: any;

  partiesFormArray: FormArray;
  constructor(
    private _location: Location,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private fb: FormBuilder,
    private contractService: AasContractService,
    private authService: AuthService,
    private modalService: NgbModal,
    private loaderSerive: LoadingService,
    private aasAuth: AasAuthService,
    private packageSession: PackageSessionService,
    private myAlert: AlertControl
  ) { 
    // if (this.packageSession.packageType === "TRIAL_TYPE") {
    //   this.hinhThucXacMinh.shift();
    // }
  }

  ngOnInit(): void {
    this.makeFormContract();
    this.makeAutoFormatDate();
    this.makeAutoFormatCost();

    this.partiesFormArray = this.fb.array([]);

    this.getPartyInfo(this.authService.partyId);

    console.log(this.contractId);
    if (this.contractId !== '') {
      this.isUpdate = true;
      this.getContract(this.contractId);
    }
  }

  //MARK: KHỞI TẠO FORM TẠO HỢP ĐỒNG
  makeFormContract() {
    this.formContract = this.fb.group({
      tenHopDong: new FormControl('', [Validators.required]),
      file: new FormControl(''),
      ngayHieuLuc: new FormControl('', [Validators.pattern(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i)]),
      ngayHetHieuLuc: new FormControl('', [Validators.pattern(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i)]),
      tuGiaHan: new FormControl(true, [Validators.required]),
      giaTriHd: new FormControl('', []),
      ghiChu: new FormControl(''),
      discuss: new FormControl(false, [Validators.required])
    });
  }

  get tenHopDong() { return this.formContract.get('tenHopDong'); }
  get file() { return this.formContract.get('file'); }
  get ngayHieuLuc() { return this.formContract.get('ngayHieuLuc'); }
  get ngayHetHieuLuc() { return this.formContract.get('ngayHetHieuLuc'); }
  get tuGiaHan() { return this.formContract.get('tuGiaHan'); }
  get giaTriHd() { return this.formContract.get('giaTriHd'); }
  get thoiHanKy() { return this.formContract.get('thoiHanKy'); }
  get ghiChu() { return this.formContract.get('ghiChu'); }

  /**
   * Set du lieu cu
   */
  setData() {
    this.tenHopDong.setValue(this.contract.title);
    this.ngayHieuLuc.setValue(DateUtils.convert(this.contract.validDate, DateUtils.format.INPUT));
    this.ngayHetHieuLuc.setValue(DateUtils.convert(this.contract.endDate, DateUtils.format.INPUT))
    this.giaTriHd.setValue(this.contract.contractValue);
    this.ghiChu.setValue(this.contract.creationNote);
    this.tuGiaHan.setValue(this.contract.autoRenew);

    this.inputFileLabel = `${this.contractId}.pdf`;
  
    console.log(this.contract.contractPartysData);
    this.contract.contractPartysData.forEach((item) => {
      var party: FormGroup;
      var isTrustedPartner = '';

      if (item.partyId === this.authService.partyId) {
        party = this.initFormNoValid();
        isTrustedPartner = 'Y';
        party.patchValue({
          allowDetete: false,
          allowEditUserType: false,
          allowEditPartyId: false,
          isOwner: true
        });
      } else {
        party = this.initForm();
        isTrustedPartner = item.isTrustedPartner;
      }

      party.patchValue({
        partyId: item.partyId,
        tenToChuc: item.tenToChuc,
        userType: item.userType,
        email: item.email,
        tenDaiDien: item.tenDaiDien,
        endDate: DateUtils.convert(item.endDate, DateUtils.format.INPUT),
        signForm: item.signForm,
        isTrustedPartner: isTrustedPartner,
        verificationType: item.verificationType,
        sequence: item.sequence,
        hinhThucKyData: item.partyType === this.userTypeConst.CONSUMER ? this.hinhThucKy_consumer : this.hinhThucKy_business,
        nguoiNhanData: [
          {
            id: item.partyId,
            text: item.email,
            additional: {
              partyId: item.partyId,
              type_customer: item.partyType === this.userTypeConst.CONSUMER ? 'Cá nhân' : 'Doanh nghiệp',
              type_cutomer_label: item.userType,
              email: item.email,
              isTrustedPartner: isTrustedPartner
            }
          }
        ]
      })

      this.partiesFormArray.push(party);
    });

    console.log(this.partiesFormArray.value);
  }

  //MARK: CHỨC NĂNG INPUT NGÀY THÁNG NĂM 
  /**
   * Cai data option Cleave nhap ngay thang nam
   */
  makeAutoFormatDate() {
    this.optionsCleave = {
      date: true,
      delimiter: '/',
      datePattern: ['d', 'm', 'Y'],
    }
  }

  /**
   * Cai dat option Cleave nhap so tien
   */
  makeAutoFormatCost() {
    this.optionsCleaveCost = {
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
    }
  }

  convertCurency(value: any) {
    let curency: any

    console.log(value);
    value = value.replace(/,/g, '')
    curency = value.replace(/VNĐ/g, '')
    console.log(curency, '==============curency')
    curency = Math.abs(curency);
    return curency;
  }

  //MARK: CHỨC NĂNG ĐIỀU KHIỂN TAB
  //Action chọn tab
  selectTab(nameTab: string) {
    this.activeTab = nameTab;
  }

  //MARK: NHÓM CHỨC NĂNG SOẠN THẢO HỢP ĐỒNG
  public getEditorContent() {
    console.log(this.myEditor.editorInstance.getData());
  }

  //MARK: NHÓM CHỨC NĂNG KHỞI TẠO HỢP ĐỒNG
  //Lưu hợp đồng
  actBtnSave(event) {
    this.isSubmitContract = true;

    if (this.typeSelected === 1) {
      if (this.filePDF == null || this.filePDF == undefined) {
        this.formContract.get('file').setErrors({ required: true });
      }

      console.log(this.formContract.value);
      if (this.formContract.valid && this.filePDF !== null && this.filePDF !== undefined) {
        this.alert('Bạn chắc chắn muốn lưu thông tin hợp đồng?', '', true, '', 'Đồng ý', () => {
          this.saveContract();
        }, '', 'Hủy');
      }
    } else {
      if (this.formContract.valid) {
        this.alert('Bạn chắc chắn muốn lưu thông tin hợp đồng?', '', true, '', 'Đồng ý', () => {
          this.convertHtmlToPdf();
        }, '', 'Hủy');
      }
    }
  }

  //Convert html to pdf
  convertHtmlToPdf(andSend: boolean = false) {
    let html = this.myEditor.editorInstance.getData();
    let formData = new FormData();
    formData.append('htmlString', html);

    this.postConvertHTMLToPdf(formData, andSend);
  }

  //Lưu và gửi hợp đồng
  saveAndSendContract() {
    // this.postSubmitSendDraft(this.contractId);
    this.isSubmitContract = true;

    if (this.typeSelected === 1) {
      if (this.filePDF == null || this.filePDF == undefined) {
        this.formContract.get('file').setErrors({ required: true });
      }

      console.log(this.formContract.value);
      if (this.formContract.valid && this.filePDF !== null && this.filePDF !== undefined) {
        this.alert('Bạn chắc chắn muốn lưu và gửi thông tin hợp đồng?', '', true, '', 'Đồng ý', () => {
          this.saveContract(true);
        }, '', 'Hủy');
      }
    } else {
      if (this.formContract.valid) {
        this.alert('Bạn chắc chắn muốn lưu và gửi thông tin hợp đồng?', '', true, '', 'Đồng ý', () => {
          this.convertHtmlToPdf(true);
        }, '', 'Hủy');
      }
    }
  }

  /**
   * Call Api save contract
   */
  saveContract(andSend: boolean = false) {
    let data = new FormData();
    data.append('title', this.tenHopDong.value || '');
    data.append('validDate', DateUtils.convertInputToFormat(this.ngayHieuLuc.value, DateUtils.format.API) || "");
    data.append('endDate', DateUtils.convertInputToFormat(this.ngayHetHieuLuc.value, DateUtils.format.API) || "");
    data.append('contractValue', this.convertCurency(this.giaTriHd.value || '') || '');
    data.append('creationNote', this.ghiChu.value);
    data.append('draftFile', this.filePDF, this.filePDF.name);
    data.append('autoRenew', this.tuGiaHan.value);
    data.append('discuss', this.formContract.value.discuss);

    var paritesParam = [];
    this.partiesFormArray.value.forEach((item) => {
      if (item.partyId !== this.partyInfo.partyId) {
        paritesParam.push({
          customerIdOrEmail: item.partyId, 
          customerType: item.userType, 
          verificationType: item.verificationType,
          signForm: item.signForm, 
          // endDate: DateUtils.convertInputToFormat(item.endDate, DateUtils.format.API),
          sequence: Number(item.sequence)
        })
      } else {
        paritesParam.push({
          customerIdOrEmail: item.partyId,
          customerType: this.partyInfo.partyType,
          sequence: Number(item.sequence)
        })
      }
    })

    data.append('partys', JSON.stringify(paritesParam));

    if (this.contractId !== '') {
      if (this.checkAllowEdit()) {
        this.postUpdateContract(this.contractId, data, andSend);
      } else {
        this.alert('Bạn không có quyền chỉnh sửa hợp đồng này!', 'error');
      }
    } else {
      this.postCreateDraftOnlyFile(data, andSend);
    }
  }

  /**
   * Action huy khoi tao hop dong
   * @param event 
   */
  actCancelBtn(event) {
    if (this.contractId === '') {
      this.alert('Bạn chắc chắn muốn hủy khởi tạo hợp đồng?', '', true, '', 'Đồng ý', () => {
        this.router.navigate([WebviewURL.contract_list]);
      }, '', 'Hủy');
    } else {
      this.alert('Bạn chắc chắn muốn hủy lưu thay đổi thông tin hợp đồng?', '', true, '', 'Đồng ý', () => {
        this.router.navigate([WebviewURL.contract_list]);
      }, '', 'Hủy');
    }
  }

  /**
   * Chon phuong thuc tao file hop dong
   * @param value 
   */
  selectType(value) {
    this.typeSelected = value;
    if (value === 0 || value === 2) {
      // this.changeDiscussStatus(false);
      this.check_non_discuss && this.check_non_discuss.nativeElement.click();
    }
  }

  /**
   * Chon file tu hop dong
   * @param e 
   */
  handleFileInput(e) {
    this.filePDF = e.target.files && e.target.files.item(0);
    console.log(this.filePDF);
    this.formContract.get('file').setErrors(null);
    if (this.filePDF && this.filePDF !== null && this.filePDF !== undefined) {
      if (!this.fileType.includes(this.filePDF.type)) {
        this.formContract.get('file').setErrors({ type: true });
        this.inputFileLabel = this.filePDF.name
      } else if (this.filePDF.size > 5000000) {
        this.myAlert.showAlertOnlyNoti(ConstantAlertMsg.FILE_SIZE_TOO_LARGE, ConstantAlertType.ERROR);
      } else {
        this.inputFileLabel = this.filePDF.name;
        if (this.filePDF.type === 'application/pdf') {
          this.fileName = this.filePDF.name;
          PDFSign.preview(this.filePDF);

          this.getBase64String(this.filePDF, this.handle);
        } else {
          let fileSplit = this.filePDF.name.split('.');
          fileSplit.pop();
          this.fileName = `${fileSplit.join('.')}.pdf`;

          let data = new FormData();
          data.append('attachFile', this.filePDF);
          this.postConvertToPDF(data);
        }

      }
    } else {
      // this.fileForm.get('file').setErrors({ required: true });
      this.inputFileLabel = 'File đính kèm';
    }
  }

  /**
   * Thay doi gia tri ngay input
   * @param event 
   * @param type 
   */
  changeDate(event, type) {
    switch (type) {
      case this.dateType.ISSUE: 
        this.formContract.controls.ngayHieuLuc.setValue(DateUtils.convertObjtoDate(event));
        break;
      case this.dateType.EXPRIRED:
        this.formContract.controls.ngayHetHieuLuc.setValue(DateUtils.convertObjtoDate(event));
        break;
    }
  }

  /**
   * 
   * @param value 
   */
  changeDiscussStatus(value) {
    this.formContract.controls.discuss.setValue(value);
  }

  /**
   * Diabled input truong dam phan hop dong
   */
  get disableDiscussStatus() {
    // return true;
    return this.typeSelected === 0 || this.typeSelected === 2;
  }

  //Kiểm tra form đã có dữ liệu chưa
  get editedForm() {
    if (this.tenHopDong.value !== '' && this.tenHopDong.value !== null && this.tenHopDong.value !== undefined) return false;
    if (this.ngayHieuLuc.value !== '' && this.ngayHieuLuc.value !== null && this.ngayHieuLuc.value !== undefined) return false;
    if (this.ngayHetHieuLuc.value !== '' && this.ngayHetHieuLuc.value !== null && this.ngayHetHieuLuc.value !== undefined) return false;
    if (this.giaTriHd.value !== '' && this.giaTriHd.value !== null && this.giaTriHd.value !== undefined) return false;
    if (this.ghiChu.value !== '' && this.ghiChu.value !== null && this.ghiChu.value !== undefined) return false;
    if (JSON.stringify(this.tuGiaHan.value) !== '' && this.tuGiaHan.value !== null && this.tuGiaHan.value !== undefined) return false;
    if (this.filePDF !== null && this.filePDF !== undefined) return false;
    return true;
  }

  getBase64String(blob, callback) {
    var self = this;
    var reader = new FileReader();
    reader.onload = function (e) {
      callback(reader.result, self);
    }

    reader.readAsDataURL(blob);
  }

  //handel cho file pdf
  handle(str, self) {
    $('#fileBase').val(str.split(',')[1]);
  }

  //Handle cho file hình sign server
  handleImgSignServer(str, self) {
    self.imgContent = str;
    PDFSign.setImage(str.split(',')[1]);
  }

  //Kiểm tra quyền cập nhật
  checkAllowEdit() {
    let userId = this.authService.userId;
    let ownerPartyId = this.contract.ownerPartyId;
    let status = this.contract.currentStage;

    for (var i = 0; i < this.contract.signatures.length; i++) {
      if (this.contract.signatures[i].userId === userId) {
        return false;
      }
    }

    if (userId === ownerPartyId && status === 'LC_DRAFT_CREATE') {
      return true;
    }

    return false;
  }

  //Trở về trang trước
  goToBack() {
    // this._location.back();
    this.router.navigate([WebviewURL.contract_list]);
  }

  get allowSendContract() {
    return true;
  }

  //Tải xuống file hợp đồng
  saveAsContract() {
    if (this.contractId !== '') {
      if (this.contract.currentStage === 'LC_CONTRACT_VALID') {
        this.saveFileContract(this.contractId, 'CONTRACT');
      } else {
        this.saveFileContract(this.contractId, 'DRAFT');
      }
    } else {
      var url = window.URL.createObjectURL(this.filePDF);
      var anchor = document.createElement("a");
      anchor.download = this.fileName;
      anchor.href = url;
      anchor.click();
    }
  }

  addPartyJoin() {
    this.showPartyJoin = true;
  }

  closedPartyJoinModal(e) {
    this.showPartyJoin = false;
  }

  callBackSetDataPartyJoin() {
    var party = new PartyJoinContract({
      partyId: this.partyInfo.partyId,
      tenToChuc: this.partyInfo.tenToChuc,
      userType: this.partyInfo.partyType,
      email: this.partyInfo.userInformations.email,
      username: this.partyInfo.userInformations.username,
      tenDaiDien: this.partyInfo.userInformations.ten,
      endDate: '',
      isTrustedPartner: 'Y',
      nguoiNhanData: [
        {
          id: this.partyInfo.partyId,
          text: this.partyInfo.userInformations.email,
          additional: {
            partyId: this.partyInfo.partyId,
            type_customer: this.partyInfo.partyType === this.userTypeConst.CONSUMER ? 'Cá nhân' : 'Doanh nghiệp',
            type_cutomer_label: this.partyInfo.partyType,
            email: this.partyInfo.userInformations.email,
            isTrustedPartner: 'Y'
          }
        }
      ]
    });
    party.allowDetete = false;
    party.allowEditUserType = false;
    party.allowEditPartyId = false;
    party.isOwner = true;

    this.partiesJoin.push(party);
  }
  
  /**
   * Set du lieu cho FormArray
   */
  callbackSetFormArray() {
    this.partiesFormArray = this.fb.array([]) as FormArray;
    var party = this.initFormNoValid();

    party.patchValue({
      partyId: this.partyInfo.partyId,
      tenToChuc: this.partyInfo.tenToChuc,
      userType: this.partyInfo.partyType,
      email: this.partyInfo.userInformations.email,
      username: this.partyInfo.userInformations.username,
      tenDaiDien: this.partyInfo.userInformations.ten,
      endDate: '',
      isTrustedPartner: 'Y',
      sequence: 1,
      nguoiNhanData: [
        {
          id: this.partyInfo.partyId,
          text: this.partyInfo.userInformations.email,
          additional: {
            partyId: this.partyInfo.partyId,
            type_customer: this.partyInfo.partyType === this.userTypeConst.CONSUMER ? 'Cá nhân' : 'Doanh nghiệp',
            type_cutomer_label: this.partyInfo.partyType,
            email: this.partyInfo.userInformations.email,
            isTrustedPartner: 'Y'
          }
        }
      ],
      allowDetete: false,
      allowEditUserType: false,
      allowEditPartyId: false,
      isOwner: true
    })

    this.partiesFormArray.push(party);
  }

  /**
   * Init FormGroup
   */
  initForm(): FormGroup {
    return this.fb.group({
      partyId: ['', [Validators.required]],
      tenToChuc: [''],
      email: [''],
      userType: ['', [Validators.required]],
      verificationType: ['', [Validators.required]],
      signForm: ['', [Validators.required]],
      username: [''],
      tenDaiDien: [''],
      endDate: ['', [Validators.pattern(REGEXP_DATE_INPUT)]],
      isTrustedPartner: [false],
      allowDetete: [true],
      allowEdit: [true],
      allowEditUserType: [true],
      allowEditPartyId: [true],
      isOwner: [false],
      sequence: ['', [Validators.pattern(REGEXP_NUMBER)]],

      hinhThucKyData: [[]],
      nguoiNhanData: [[]]
    })
  }

  /**
   * Init FormGroup
   */
  initFormNoValid(): FormGroup {
    return this.fb.group({
      partyId: [''],
      tenToChuc: [''],
      email: [''],
      userType: [''],
      verificationType: [''],
      signForm: [''],
      username: [''],
      tenDaiDien: [''],
      endDate: [''],
      isTrustedPartner: [false],
      allowDetete: [true],
      allowEdit: [true],
      allowEditUserType: [true],
      allowEditPartyId: [true],
      isOwner: [false],
      sequence: [''],

      hinhThucKyData: [[]],
      nguoiNhanData: [[]]
    })
  }

  /**
   * Nhan event du lieu tu modal nhap thong tin cac ben
   * @param $event 
   */
  changePartyJoin($event) {
    // this.partiesJoin = $event || [];
    this.partiesFormArray = $event as FormArray;
    this.showPartyJoin = false;
  }

  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], 'error');
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, 'error');
    } else {
      this.alert('Lỗi không xác định!', 'error');
    }
  }

  //MARK: NETWORKING
  postCreateDraftOnlyFile(data: FormData, andSend: boolean = false) {
    this.contractService.postCreateDraftOnlyFile(data).subscribe((res: any) => {
      if (andSend) {
        this.loaderSerive.show();
        setTimeout(() => {
          this.postSubmitSendDraft(res.object.contractId);
        }, 2000)
      } else {
        this.alert('Lưu hợp đồng thành công!', 'success', false, '', 'OK', () => {
          this.router.navigate([WebviewURL.contract_detail, res['object'].contractId])
        });
      }
    }, err => {
      console.log(err);
      if (err.error && err.error.error 
        && err.error.error instanceof Array 
        && err.error.error.length > 0 
        && err.error.error[0] === "Cannot verify signature contract") {
        this.alert("File đính kèm đã có chữ ký. Vui lòng sử dụng file không có chữ ký!", 'error');
      } else {
        this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
      }
    }, () => {

    });
  }

  //Convert docx to pdf
  postConvertToPDF(data: FormData) {
    this.contractService.postConvertToPdf(data).subscribe(fileData => {
      const blob: any = new Blob([fileData], { type: 'application/pdf' });
      PDFSign.preview(blob);
      this.filePDF = blob;

      this.getBase64String(blob, this.handle);
    }, err => {
      console.log(err);
      this.myAlert.showAlertOnlyNoti(ConstantAlertMsg.CONVERT_PDF_FAILED, ConstantAlertType.ERROR);
      // this.handleError(err, ResponseMsg.MESSAGES.ESIGN_SERVICE);
    });
  }

  //Tim kiem khach hang
  searchCustomer(key) {
    this.contractService.searchCustomer(key).subscribe(res => {

    }, err => {

    })
  }

  //Lay thong tin hop dong
  getContract(id) {
    this.contractService.getContract(id).subscribe(res => {
      this.contract = new ContractResponse(res).object;
      this.setData();
      this.downloadFileContract(id, 'DRAFT');
      if (!this.checkAllowEdit()) {
        this.alert('Bạn không thể chỉnh sửa hợp đồng này!', 'error');
      }
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    })
  }

  //Tai file hop dong
  downloadFileContract(id, type) {
    this.contractService.downloadFileContract(id, type).subscribe(fileData => {
      const blob: any = new Blob([fileData], { type: 'application/pdf' });
      this.filePDF = blob;
      PDFSign.preview(blob);
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    })
  }

  //Cap nhat hop dong
  postUpdateContract(id, data, andSend: boolean = false) {
    this.contractService.postUpdateContract(id, data).subscribe(res => {
      if (andSend) {
        this.postSubmitSendDraft(id);
      } else {
        this.alert('Lưu hợp đồng thành công!', 'success', false, '', 'OK', () => {
          this.router.navigate([WebviewURL.contract_detail, id])
        });
      }
    }, err => {
      console.log(err);
      if (err.error && err.error.error 
        && err.error.error instanceof Array 
        && err.error.error.length > 0 
        && err.error.error[0] === "Cannot verify signature contract") {
        this.alert("File đính kèm đã có chữ ký. Vui lòng sử dụng file không có chữ ký!", 'error');
      } else {
        this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
      }
    }, () => {

    });
  }

  //Tải file hợp đồng 
  saveFileContract(id, type) {
    this.contractService.downloadFileContract(id, type).subscribe((res) => {
      const blob: any = new Blob([res], { type: 'application/pdf' });
      var url = window.URL.createObjectURL(blob);
      var anchor = document.createElement("a");
      anchor.download = `${id}.pdf`;
      anchor.href = url;
      anchor.click();
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    })
  }

  //convert html sang pdf
  postConvertHTMLToPdf(data, andSend: boolean = false) {
    this.contractService.postConvertHTMLToPdf(data).subscribe(res => {
      const blob: any = new Blob([res], { type: 'application/pdf' });
      this.filePDF = blob;
      this.getBase64String(blob, this.handle);
      this.saveContract(andSend);
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.ESIGN_SERVICE);
    });
  }

  /**
   * Thong tin party dang login
   * @param partyId 
   */
  getPartyInfo(partyId) {
    this.aasAuth.getPartyInfo(partyId).subscribe((res: any) => {
      this.partyInfo = new InfoPartyObject(res.object || {});
      if (this.contractId == '') {
        // this.callBackSetDataPartyJoin();
        this.callbackSetFormArray();
      }
    }, err => {
      this.handleError(err, ResponseMsg.MESSAGES.PARTY_USER);
    })
  }

    /**
   * Submit gui ban nhap hop dong den cac ben
   * @param contractId 
   */
  postSubmitSendDraft(contractId) {
    this.contractService.postSubmitSendDraft(contractId).subscribe((res) => {
      this.alert('Lưu và gửi hợp đồng thành công!', 'success', false, '', 'OK', () => {
        // this.getContract(contractId);
        this.router.navigate([WebviewURL.contract_detail, contractId]);
      });
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    })
  }

}
