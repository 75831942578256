export class DownloadContract {
  id: string;
  partyId: string;
  created: string;
  expiredDate: string;
  url: string;
  status: string;

  constructor(obj = null) {
    obj = obj || {};
    this.id = obj.id || '';
    this.partyId = obj.partyId || '';
    this.created = obj.created || '';
    this.expiredDate = obj.expiredDate || '';
    this.url = obj.url || '';
    this.status = obj.status || '';
  }
}
