import { Injectable } from '@angular/core';
import { API } from '../api';
import { ServicePath } from '../../common/constant-service-api';

const UPDATE_EMAIL_URL = ServicePath.MANAGEMENT_SERVICE + '/user-informations/update-email';
const UPDATE_SDT_URL = ServicePath.MANAGEMENT_SERVICE + '/user-informations/update-phone-number';
const VERIFY_EMAIL_OTP_URL = ServicePath.MANAGEMENT_SERVICE + '/user-informations/update-email/verify-otp';
const VERIFY_SDT_OTP_URL = ServicePath.MANAGEMENT_SERVICE + '/user-informations/update-phone-number/verify-otp';


@Injectable({
  providedIn: 'root'
})
export class ChangeEmailSdtService {

  constructor(private api: API) { }

  /**
   * Update email
   * @param newEmail
   */
   updateEmail(newEmail) {
    return this.api.post(UPDATE_EMAIL_URL + `?email=${newEmail}`, {});
  }

  /**
   * Update sdt
   * @param newSDT
   */
   updateSDT(newSDT) {
    return this.api.post(UPDATE_SDT_URL + `?phoneNumber=${newSDT}`, {});
  }

  /**
   * Verify OTP email
   * @param email
   */
  verifyOTP_Email(email,otp){
    return this.api.post(VERIFY_EMAIL_OTP_URL + `?email=${email}&otp=${otp}`, {});
  }

  /**
   * Update sdt
   * @param newSDT
   */
  verifyOTP_SDT(sdt, otp){
    return this.api.post(VERIFY_SDT_OTP_URL + `?phoneNumber=${sdt}&otp=${otp}`, {});
  }
}
