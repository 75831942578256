import { DetailContractItem, ContractPostObj, ContractCommentObj, ContractActivityObj } from './detail-contract-item.model';
import { MainResponse } from './../../main-response.model';
export class DetailContractResponse extends MainResponse {
    constructor(obj = null) {
        super();
        obj = obj || {};
        this.init(obj);
        this.object = new DetailContractItem(obj.object);
    }
}

export class ContractPostResponse extends MainResponse {
    object: ContractPostObj;
    constructor(obj = null) {
        super();
        obj = obj || {};
        this.init(obj);
        this.object = new ContractPostObj(obj);
    }
}

export class ContractCommentResponse extends MainResponse {
    object: ContractCommentObj;
    constructor(obj = null) {
        super();
        obj = obj || {};
        this.init(obj);
        this.object = new ContractCommentObj(obj);
    }
}

export class ContractActivityResponse extends MainResponse {
    object: ContractActivityObj;
    constructor(obj = null) {
        super();
        obj = obj || {};
        this.init(obj);
        this.object = new ContractActivityObj(obj);
    }
}