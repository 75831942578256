import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { Route } from '@angular/compiler/src/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/utils/services/auth.service';
import { InfoPartyObject } from 'src/app/utils/models/aas-models/info-party/info-party-object';
import { ControlAccount } from 'src/app/utils/common/control-account';
import { UserInformationsItem } from 'src/app/utils/models/profile/user-info-item.model';
import { UserTypeConstant, VerifyStatusConst } from 'src/app/utils/common/constant';
import { forkJoin } from 'rxjs';
import { ServiceContractListV2Component } from 'src/app/service-pages/service-console/service-enduser-console/contract-manager/service-contract-manager/service-contract-list-v2/service-contract-list-v2.component';
import { WvAppComponent } from '../../../wv-app.component';
import { WebviewURL } from '../../../wv-ultils/wv-const-url';

@Component({
  selector: 'app-wv-contracts-manager',
  templateUrl: './wv-contracts-manager.component.html',
  styleUrls: ['./wv-contracts-manager.component.scss']
})
export class WvContractsManagerComponent implements OnInit {
  @ViewChild('ownerList') ownerList: ServiceContractListV2Component;
  @ViewChild('assigneeList') assigneeList: ServiceContractListV2Component;

  selectedTab = 0;

  partyInfo: InfoPartyObject = new InfoPartyObject();
  userInfo: UserInformationsItem = new UserInformationsItem();
  
  constructor(
    private router: Router,
    public controlAccount: ControlAccount,
    private authService: AuthService,
    @Inject(WvAppComponent) private parent: WvAppComponent
  ) {
    controlAccount.getAccountInfo();
  }

  ngOnInit(): void {
    this.parent.backFn = () => {
      var daddy = window.self;
      daddy.opener = window.self;
      daddy.close();
    }
    this.parent.isExit = false;

    forkJoin([
      this.authService.getPartyInfo(this.authService.partyId),
      this.authService.getUserInfo(this.authService.userId)
    ]).subscribe((res: any) => {
      this.partyInfo = new InfoPartyObject(res[0].object || {});
      this.userInfo = new UserInformationsItem(res[1].object || {});

      this.isAccLevel2 ? this.selectTab(1) : this.selectTab(2);
    }, err => {
      console.log(err);
    }, () => {
    });
  }

  ngAfterViewInit(): void {
    // this.selectTab(1);
  }

  addContract() {
    this.router.navigate([WebviewURL.contract_add]);
  }

  selectTab(value) {
    this.selectedTab = value;
    switch (value) {
      case 1:
        this.ownerList && this.ownerList.reload();
        break;
      case 2:
        this.assigneeList && this.assigneeList.reload();
        break;
    }
  }

  get isAccLevel2() {
    var uploadedGPKD = this.partyInfo.gpkdFilename !== "";
    var level = `${this.partyInfo.identityLevel}` === "2";
    var level2 = `${this.userInfo.identityLevel}` === "2";

    let status = this.partyInfo.verificationStatus;
    let isApprivedBusiness = status === VerifyStatusConst.APPROVE || status === VerifyStatusConst.APPROVE_CONTRACT || status === VerifyStatusConst.APPROVE_NON_CONTRACT;

    let status1 = this.userInfo.verificationStatus;
    let isApprovedConsumer = status1 === VerifyStatusConst.APPROVE || status1 === VerifyStatusConst.APPROVE_CONTRACT || status1 === VerifyStatusConst.APPROVE_NON_CONTRACT;

    let isConsumer = this.userInfo.userType === UserTypeConstant.CONSUMER;
    let isBusiness = this.partyInfo.partyType === UserTypeConstant.BUSINESS || this.partyInfo.partyType === UserTypeConstant.ENTERPRISE;
    
    return (isConsumer && isApprovedConsumer && level2) || (isBusiness && isApprivedBusiness && uploadedGPKD && level);
  }

}
