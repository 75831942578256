import { Injectable } from '@angular/core';
import { API } from '../../api';
import { ServicePath } from 'src/app/utils/common/constant-service-api';

const ADMINISTRATIVE_DIRECTORY_URL = ServicePath.REPORT_SERVICE + '/country/country-subdivision';
const ADMINISTRATIVE_FILTER_URL = ServicePath.REPORT_SERVICE + '/country/search-country-subdivision';
const EXPORT_URL = ServicePath.REPORT_SERVICE + '/country/country-subdivision-export';


@Injectable({
  providedIn: 'root'
})
export class AdministrativeDirectoryService {

  constructor(
    private api: API,
  ) { }

  // GET PROVINCE (DISTRICT, WARD) DIRECTORY
  getDirectory(type, subdivisionReferTo, page, maxSize, propertiesSort, sort) {
    const url = ADMINISTRATIVE_DIRECTORY_URL + `?type=${type}&subdivisionReferTo=${subdivisionReferTo}&page=${page}&maxSize=${maxSize}&propertiesSort=${propertiesSort}&sort=${sort}`;
    return this.api.get(url);
  }

  getDirectoryWithFilter(subdivisionReferTo, keySearch) {
    const url = ADMINISTRATIVE_FILTER_URL + `?subdivisionReferTo=${subdivisionReferTo}&keySearch=${keySearch}`;
    return this.api.get(url);
  }

  // ADD PROVINCE (DISTRICT, WARD)
  addDistrictAndWard(subdivisionCode, type, areaCode, isActive, name, subdivisionReferTo, subdivisionId = 21, countryCode = "VN"){
    const url = ADMINISTRATIVE_DIRECTORY_URL;
    const data = {
      "subdivisionCode": subdivisionCode,
      "subdivisionId": subdivisionId,
      "type": type,
      "countryCode": countryCode,
      "areaCode": areaCode,
      "isActive": isActive,
      "name": name,
      "subdivisionReferTo": subdivisionReferTo
    };
    return this.api.post(url, data);
  }

  editArea(id, subdivisionCode, areaCode, isActive, name) {
    const url = ADMINISTRATIVE_DIRECTORY_URL + `/${id}`;
    const data = {
      "subdivisionCode": subdivisionCode,
      "areaCode": areaCode,
      "isActive": isActive,
      "name": name
    };
    return this.api.put(url, data);
  }

  // Export excel
  exportExcel(subdivisionReferTo, type, option) {
    const url = EXPORT_URL + `?subdivisionReferTo=${subdivisionReferTo}&type=${type}&option=${option}`;
    return this.api.download(url);
  }
}
