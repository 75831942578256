import { Component, OnInit,ViewChild,ElementRef,Directive } from '@angular/core';
import { Options } from 'select2';
import { Select2OptionData } from 'ng-select2';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { PermissionGroup } from 'src/app/utils/models/aas-models/business-package-manager/business-package-list/list-all-permission/permission-group'
import { ListAllPermisionReponse } from 'src/app/utils/models/aas-models/business-package-manager/business-package-list/list-all-permission/list-all-permission-response';
import { DualListItem } from 'src/app/utils/models/aas-models/business-package-manager/business-package-list/duaList-item.model'
import { StringUtils } from 'src/app/utils/common/StringUtils';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { PACKAGE_MANAGER } from 'src/app/utils/common/constant-vaildate-msg';
import { share } from 'rxjs/operators';
import { NgModalComponent } from 'src/app/layout/extensions/ng-modal/ng-modal.component';
import { LoadingService } from 'src/app/utils/services/loading.service';
import { BusinessPackageService } from 'src/app/utils/services/aas-network/business-package.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { CategoryPackageItem } from 'src/app/utils/models/aas-models/business-package-manager/business-package-list/category-package-manager/category-package-item.model'
import { ListCategoryPackageResponse } from 'src/app/utils/models/aas-models/business-package-manager/business-package-list/category-package-manager/list-category-package-response.model'
import { ListTemplateContractResponse } from 'src/app/utils/models/aas-models/business-package-manager/business-package-list/template-contract/list-template-contract-response.model'
import { TemplateContract } from 'src/app/utils/models/aas-models/business-package-manager/business-package-list/template-contract/template-contract-item.model';
import { BusinessPackageItem } from 'src/app/utils/models/aas-models/business-package-manager/business-package-list/business-package-item.model'
import { ListPackageOrderResponse } from 'src/app/utils/models/aas-models/business-package-manager/business-package-list/list-package-in-order-response.model'
import {  REGEXP_COST} from 'src/app/utils/common/regexp'
import { AlertControl } from 'src/app/utils/alert/alert-control';

declare var Cleave: any;
@Component({
  selector: 'app-create-service-pakage',
  templateUrl: './create-service-pakage.component.html',
  styleUrls: ['./create-service-pakage.component.scss']
})
export class CreateServicePakageComponent implements OnInit {
  
  @ViewChild('searchNotAssigned') searchNotAssigned: ElementRef;
  @ViewChild('searchAssigned') searchAssigned: ElementRef;
  @ViewChild('showInfoPackageOrder') showPackageOrder: ElementRef;

  dropListStatus: Array<Select2OptionData> = [
    {
      id: 'ENABLED',
      text: 'Đang hoạt động'
    },
    {
      id: 'DISABLED',
      text: 'Ngưng hoạt động'
    }
  ];

  dropListsUserType = [
    {
      id: 'CONSUMER',
      text: 'Khách hàng cá nhân'
    },
    {
      id: 'BUSINESS',
      text: 'Khách hàng tổ chức dịch vụ'
    },
    {
      id: 'ENTERPRISE',
      text: 'khách hàng tổ chức giải pháp'
    },
    
  ];

  dropListContractTemplate: Observable<Array<Select2OptionData>>;
  listdua : Array<any> =[]
  form: FormGroup;

  page = 1;
  maxSize = 5;
  search = "";
  totalElement = 0;
  propertiesSort = "";
  sort = "";
  loadingServiceIsVisiable = new BehaviorSubject<boolean>(false);
  loadingListUserGroup = new BehaviorSubject<boolean>(false);
  loadingForm = new BehaviorSubject<boolean>(false);

  listPermissionGroup: PermissionGroup[] = [];
  listAssigned: PermissionGroup[] = [];

  formSearch: FormGroup;

  dualList1: DualListItem[] = [];
  dualList2: DualListItem[] = [];
  selectedAll1: boolean = false;
  selectedAll2: boolean = false;

  baseList: DualListItem[] = new Array<DualListItem>();
  dualList: DualListItem[][] = [new Array<DualListItem>(), new Array<DualListItem>()];   

  oriList: DualListItem[][] = [new Array<DualListItem>(), new Array<DualListItem>()];
  selectedAll = [false, false];
  filter = [false, false];
  assignedAll = [false, false]


  selectAllItem = false;
  selectChild = false;

  public optionUserType: Options;
  public options: Options;

  validateMsg = PACKAGE_MANAGER;
  isSubmit: boolean = false;

  listCategoryPackageRes = new ListCategoryPackageResponse()
  listCategoryPackage : CategoryPackageItem[] = []
  businesPackageItem : CategoryPackageItem

  listTemplateContractRes : ListTemplateContractResponse;
  listTemplateContract : TemplateContract[] = []

  listBusinessPackageRes = new ListPackageOrderResponse()
  listBusinesPackage : BusinessPackageItem[] = []
  PackageItem : BusinessPackageItem;

  formInfra: FormGroup;
  listFieldInfra: CategoryPackageItem[] = [];
  validateMsgInfra: any = {};

  packageTypeData = [
    { value: "TRIAL_TYPE", label: "Gói dùng thử" },
    { value: "NORMAL_TYPE", label: "Gói chính thức" },
    { value: "EXTEND_TYPE", label: "Gói mở rộng" }
  ];

  sameInfra = true;

  constructor(
    private fb: FormBuilder,
    private loadingService: LoadingService,
    private modalService: NgbModal,
    private packageService: BusinessPackageService,
    private myAlert: AlertControl
  ) { }

  ngOnInit(): void {
    this.makeForm()
    this.getAllTemplateContract();
  
    this.options = {
      multiple: false,
      width: '100%',
      closeOnSelect: true,
      language: 'vi',
    };
    
    this.getServicePackageCatalog("HATANG");
    this.getServicePackageCatalog("CHUCNANG");
  }

  makeForm() {
    this.form = this.fb.group({
      status: ['ENABLED',Validators.required],
      contractNumber: ['',[Validators.pattern(REGEXP_COST)]],
      formContractId: [''],
      userAmount: ['',[Validators.pattern(REGEXP_COST)]],
      capacity: ['',[Validators.pattern(REGEXP_COST)]],

      
      name : ['',Validators.required],
      description: ['',Validators.required],
      packageType: [null, [Validators.required]],
      duration: ['',[Validators.pattern(REGEXP_COST)]],
      orderNumber: ['',[Validators.pattern(REGEXP_COST)]],
      price: ['',Validators.required],
      userType: [null, Validators.required],
    })

    this.formInfra = this.fb.group({});
  }

  get name() {
    return this.form.get('name');
  }
  get price() {
    return this.form.get('price');
  }
  get status() {
    return this.form.get('status');
  }
  get description() {
    return this.form.get('description');
  }
  get packageType() {
    return this.form.get('packageType');
  }
  get duration() {
    return this.form.get('duration');
  }
  get contractNumber() {
    return this.form.get('contractNumber');
  }

  get userType(){
    return this.form.get('userType')
  }

  get formContractId(){
    return this.form.get('formContractId')
  }
  get userAmount(){
    return this.form.get('userAmount')
  }
  get capacity(){
    return this.form.get('capacity')
  }

  get orderNumber(){
    return this.form.get('orderNumber')
  }

  getAllTemplateContract(){
    this.packageService.getAllTemplateContract().subscribe(res=>{
      this.listTemplateContractRes = new ListTemplateContractResponse(res['object']);
      this.dropListContractTemplate = this.makeSeriesData()
      console.log(this.dropListContractTemplate)
    })
  }

  makeSeriesData(): Observable<Array<Select2OptionData>> {
    let data: Array<Select2OptionData> = [];
    if (this.listTemplateContractRes.data && this.listTemplateContractRes.data instanceof Array) {
      this.listTemplateContractRes.data.forEach((item) => {
        data.push({ id: item.id.toString(), text: item.templateName });
      });
    }
    return Observable.create((obs) => {
      obs.next(data);
      obs.complete();
    });
  }


  loadingListUserGroupIsVisiable(): Observable<boolean> {
    return this.loadingListUserGroup.asObservable().pipe(share());
  }

  loadingFormIsVisiable(): Observable<boolean> {
    return this.loadingForm.asObservable().pipe(share());
  }

  loadingIsVisiable(): Observable<boolean> {
    return this.loadingServiceIsVisiable.asObservable().pipe(share());
  }

  //MARK: Chức năng danh sách, tìm kiếm nhóm người dùng
  /**
   * Tạo from tìm kiếm
   */
  makeFormSearch() {
    this.formSearch = this.fb.group({
      search: new FormControl('')
    });
  }

  get keySearch() { return this.formSearch.get('search'); }

  /**
   * EventEmitter: Sự kiện nhập key trên form search
   * @param event 
   */
  eventInputSearch(event) {
  }
  
  //MARK: Chức năng gán quyền DualList
  setupDualList(listAssigned: PermissionGroup[]) {
    this.dualList[0] = this.cloneObject(this.baseList);
    this.dualList[1] = [];
    this.backupDualList();

    listAssigned.forEach((a, i) => {
      const indexGroup = this.dualList[0].findIndex(res => { return res.id === a.id });
      if (indexGroup >= 0) {
        a.privileges.forEach((b, j) => {
          const indexChild = this.dualList[0][indexGroup].child.findIndex(res => { return res.id === b.id });
          if (indexGroup >= 0 && indexChild >= 0) {
            console.log("OK => idGroup: ", a.id, "; idChild: ", b.id);
            this.assign(indexGroup, indexChild, 0, 1);
          }
        })
      }
    });

    
    this.filter = [false, false];
    this.selectedAll = [false, false];
  }

  /**
   * Set base list tất cả các quyền
   */
  setBaseList() {
    this.baseList = new Array<DualListItem>()
    this.listPermissionGroup.forEach((item, index) => {
      this.baseList.push(new DualListItem({
        id: item.id,
        name: item.name,
        index: index
      }))
      this.baseList[index].child = [];

      item.privileges.forEach((item1, index1) => {
        this.baseList[index].child.push(new DualListItem({
          id: item1.id,
          name: item1.description,
          index: index1
        }))
      })
    });
  }

  selectedParentItem(indexGroup, indexList, manualSet = false, defaultValue = false) {
    //show treeview
    this.selectAllItem = true
    // this.dualList[indexList][indexGroup].showChild = true;
    if (manualSet) {
      this.dualList[indexList][indexGroup].selected = defaultValue;
    } else {
      this.dualList[indexList][indexGroup].selected = !this.dualList[indexList][indexGroup].selected;
    }

    this.dualList[indexList][indexGroup].child.forEach((item, index) => {
      item.selected = this.dualList[indexList][indexGroup].selected;
    });
    if (!this.dualList[indexList][indexGroup].selected) 
    {
      this.selectedAll[indexList] = false;
      this.selectAllItem = false;
    }
    this.resetStatusItem(indexGroup, indexList);

  }

  selectedChildItem(indexGroup, indexChild, indexList) {
    this.dualList[indexList][indexGroup].child[indexChild].selected = !this.dualList[indexList][indexGroup].child[indexChild].selected;
    if (!this.dualList[indexList][indexGroup].child[indexChild].selected) 
    {
      this.selectedAll[indexList] = false;
      this.selectAllItem = false;
      this.selectChild = true;
    }
    this.resetStatusItem(indexGroup, indexList);
  }

  selectAllBtn(indexList) {
    this.selectedAll[indexList] = !this.selectedAll[indexList];
    this.dualList[indexList].forEach((item, index) => {
      item.selected = this.selectedAll[indexList];
      item.child.forEach((x, index) => {
        x.selected = this.selectedAll[indexList];
      });
    })

    console.log(this.dualList)
  }

  get countAssigned() {
    var count = 0;
    this.dualList[1].forEach((a, i) => {
      a.child.forEach((b, j) => {
        count++;
      });
    });
    return count;
  }

  get countNotAssigned() {
    var count = 0;
    this.dualList[0].forEach((a, i) => {
      a.child.forEach((b, j) => {
        count++;
      });
    });
    return count;
  }

  assign(indexGroup, indexChild, from, to) {
    const parent = this.dualList[from][indexGroup];
    const child = parent.child[indexChild];

    const iParent = this.dualList[to].findIndex(res => {
      return res.id === parent.id;
    })
   
    if(from == 0){
      if (iParent >= 0) {
        child.selected = true;
        const iChild = this.dualList[to][iParent].child.findIndex(res => {
          return res.id === child.id;
        })
        if(iChild < 0){
          this.dualList[to][iParent].child.push(child);          
          this.dualList[from][indexGroup].child[indexChild].assigned = true;
          this.dualList[from][indexGroup].child[indexChild].selected = true;        
        }
        else{           
          this.dualList[from][indexGroup].child[indexChild].assigned = true;
          this.dualList[from][indexGroup].child[indexChild].selected = true;    
        }
      } else {
        this.dualList[to].push(new DualListItem({
          id: parent.id,
          name: parent.name,
          index: parent.index,
          showChild: parent.showChild,
        }));

        this.dualList[to][this.dualList[to].length - 1].child.push(child);        
        this.dualList[from][indexGroup].child[indexChild].assigned = true;
        this.dualList[from][indexGroup].child[indexChild].selected = true;    
      }   
      let item = this.dualList[from][indexGroup].child;
      let numSelected = 0;
      let numAssigned = 0;
      for (var i = item.length - 1; i >= 0; i--) {
        if(this.dualList[from][indexGroup].child[indexChild].selected == true)
        {
          numSelected ++;
        }
        if(this.dualList[from][indexGroup].child[indexChild].assigned == false)
        {
          numAssigned ++;
        }
      }    
      if(numSelected == 0)  
      {
        this.dualList[from][indexGroup].selected = false;
      }
      if(numAssigned == 0)  
      {        
        this.dualList[from][indexGroup].assigned = true;
      } 
    }

   this.logAssigned(parent.id, child.id, from, to);
    
    if(from == 1)
    {  
      this.selectedAll[to] = false    
      const checkGroupID = this.dualList[to].findIndex(res => {
        return res.id === this.dualList[from][indexGroup].id;
      })
      const checkChildID = this.dualList[to][checkGroupID].child.findIndex(res => {
        return res.id === this.dualList[from][indexGroup].child[indexChild].id;
      })
      if((checkGroupID >= 0) && (checkChildID >= 0))
      {
        this.dualList[to][checkGroupID].child[checkChildID].assigned = false;
        this.dualList[to][checkGroupID].child[checkChildID].selected = false;
        this.dualList[from][indexGroup].child.splice(indexChild, 1);
        this.dualList[to][checkGroupID].assigned = false;
        // this.dualList[to][checkGroupID].selected = false;
        if (this.dualList[from][indexGroup].child.length <= 0) {
          this.dualList[from].splice(indexGroup, 1);   
          this.dualList[to][checkGroupID].assigned = false;
          this.dualList[to][checkGroupID].selected = false;
        }
      }
    }
    this.sortList();
  }

  assignMoreSelected(from, to) {
    for (var i = this.dualList[from].length - 1; i >= 0; i--) {
      let item = this.dualList[from][i];
      if (item.selected && !item.assigned) {
        if(from == 0){
          this.dualList[to].push(new DualListItem({
            id: item.id,
            name: item.name,
            index: item.index
          }))
          item.assigned = true;
          this.oriList[to].push(new DualListItem({
            id: item.id,
            name: item.name,
            index: item.index
          }))

        }else{
          item.assigned = false;
          item.selected = false;
          this.dualList[from].splice(i,1)
          this.oriList[from].splice(i,1)
        }
      }

    }

    this.sortList();
  }

  assignGroup(indexGroup, from, to) {
    let item = this.dualList[from][indexGroup];
    item.selected = true;

    if(from == 0)
    {
      this.dualList[to].push(new DualListItem({
        id: item.id,
        name: item.name,
        index: item.index
      }))
      item.assigned = true;
      this.oriList[to].push(new DualListItem({
        id: item.id,
        name: item.name,
        index: item.index
      }))
    }
    else{
      this.dualList[from].splice(indexGroup,1)
      this.oriList[from].splice(indexGroup,1)
      this.dualList[to][item.index].selected = false;
      this.dualList[to][item.index].assigned = false;
      this.selectedAll[to] = false;
    }
    this.resetStatusItem(indexGroup, 0);
  }

  logAssigned(idGroup, idChild, from, to) {
    console.log("log Assign => idGroup: ", idGroup, "; idChild: ", idChild);
    const indexParent = this.oriList[from].findIndex(res => { return res.id === idGroup; });
    const indexChild = this.oriList[from][indexParent].child.findIndex(res => { return res.id === idChild; });
    const parent = this.oriList[from][indexParent];
    const child = parent.child[indexChild];
    const iParent = this.oriList[to].findIndex(res => { return res.id === idGroup; })

    if (iParent >= 0) {
      child.selected = false;
      this.oriList[to][iParent].child.push(child);
    } else {
      this.oriList[to].push(new DualListItem({
        id: parent.id,
        name: parent.name,
        index: parent.index
      }));

      child.selected = false;
      this.oriList[to][this.oriList[to].length - 1].child.push(child);
    }

    this.sortList();
    console.log("ORI AFTER ASSIGNED: ", this.oriList);
  }

  resetStatusItem(indexGroup, indexList) {
    // let ok = false;
    // this.dualList[indexList][indexGroup].child.forEach((item, index) => {
    //   if (item.selected) ok = true;
    // });
    // this.dualList[indexList][indexGroup].selected = ok;

    let ok = true;
    this.dualList[indexList].forEach((item, index) => {
      if (!item.selected) ok = false;
      item.child.forEach((x, index) => {
        if (!x.selected) ok = false;
      });
    })

    this.selectedAll[indexList] = ok;
  }

  sortList() {
    this.dualList.forEach((item, index) => {
      item.sort((a, b) => {
        return a.index - b.index;
      });

      item.forEach((x, y) => {
        x.child.sort((a, b) => {
          return a.index - b.index;
        });
      })
    })

    this.oriList.forEach((item, index) => {
      item.sort((a, b) => {
        return a.index - b.index;
      });

      item.forEach((x, y) => {
        x.child.sort((a, b) => {
          return a.index - b.index;
        });
      })
    })
  }

  backupDualList() {
    this.oriList = this.cloneObject(this.dualList);
  }

  cloneObject(object: any) {
    return JSON.parse(JSON.stringify(object));
  }

  enableBtnAssign(indexList): boolean {
    var disable = true;
    this.dualList[indexList].forEach((item) => {
      if (item.selected) disable = false;
    })
    return disable
  }

  filterSearch($event, indexList) {
    let value = StringUtils.removeAccents($event.target.value);
    let filter = [];
    this.filter[indexList] = $event.target.value !== "";
    let self = this;
    console.log(this.oriList[indexList], '==========this.oriList[indexList]')

    if (this.filter[indexList]) {
      this.oriList[indexList].forEach(function (x) {
        let parentOK = false;
        let childOK = false;
        let tmp = self.cloneObject(x);

        let tmp_name = StringUtils.removeAccents(tmp.name);
        if (tmp_name.toUpperCase().match(new RegExp(value.toUpperCase(), "g"))) {
          parentOK = true;
        };

        for (var i = tmp.child.length - 1; i >= 0; i--) {
          let tmp_child_name = StringUtils.removeAccents(tmp.child[i].name);
          if (!(tmp_child_name.toUpperCase().match(new RegExp(value.toUpperCase(), "g")))) {
            tmp.child.splice(i, 1);
          } else {
            childOK = true;
          }
        }
        if (parentOK && !childOK) filter.push(self.cloneObject(x));
        if (parentOK && childOK) filter.push(tmp);
        if (!parentOK && childOK) filter.push(tmp);
      });
      this.dualList[indexList] = this.cloneObject(filter);
    } else {
      this.dualList[indexList] = this.cloneObject(this.oriList[indexList]);
    }

    console.log("-------------------------------------");
    console.log("searched: ", filter);
    console.log("filtered ori: ", this.oriList);
    console.log("filter array: ", this.dualList);
    console.log("-------------------------------------");
  }

  showHideTreeView($event, indexParent, indexList) {
    $event.stopPropagation();
    this.dualList[indexList][indexParent].showChild = !this.dualList[indexList][indexParent].showChild
  }


  /**
   * Tạo param cập nhật quyền cho nhóm người dùng
   * @param id 
   * @param listRole 
   */
  makeParamUpdate( listRole: DualListItem[] = []) {
    var listPackageService: any[] = [];

    console.log(listRole);

    listRole.forEach((a, i) => {
        let data = {
          serviceInfoId : a.id
        }
        listPackageService.push(data)
    }
    
    );
    return listPackageService;
  }



  /**
   * Action lưu thông tin cập nhật
   */
  actSaveInfo() {
    this.isSubmit = true;
    let data = this.form.value
    if(this.form.valid && this.formInfra.valid) {
      this.modal("Bạn chắc chắn muốn khởi tạo gói dịch vụ này?", () => {
        var listPackageService = this.makeParamUpdate(this.dualList[1]);
        var listPackageInfrastructure = [];
        this.listFieldInfra.forEach((item) => {
          if (this.formInfra.controls[item.serviceInfoId]) {
            listPackageInfrastructure.push({
              "serviceInfoId": item.serviceInfoId,
              "value": this.formInfra.controls[item.serviceInfoId].value
            })
          }
        });

        let param =
          {
            "packageInfo" : {
            "name" : data.name,
            "description": data.description,
            "status": data.status,
            "price" : data.price ? Number(this.convertCurency(data.price)) : 0,
            "packageType": data.packageType? 'TRIAL_TYPE':'',
            "duration":  data.duration ? Number(data.duration) : 0,
            "contractNumber": data.contractNumber ? Number(data.contractNumber) : 0,
            "userType": data.userType,
            "formContractId": data.formContractId,
            "userAmount": data.userAmount? Number(data.userAmount) : 0,
            "capacity": data.capacity ? Number(data.capacity) : 0,
            "orderNumber": data.orderNumber? Number(data.orderNumber) : 0,
          },
          "listPackageService" : listPackageService,
          "listPackageInfrastructure": listPackageInfrastructure
        }
  
        console.log(param, '========')
        this.createPackage(param);

      });
    }
  }

  openModal(){
    this.openModalShowInfo(this.showPackageOrder)
  }

  openModalShowInfo(content) {
    if(this.form.value.orderNumber != "" ){
      this.getListPackageInOrder()
      this.modalService.open(content, {
        size: 'md',
        backdrop: 'static',
        keyboard: false
      });
    }
    
  }

  getListPackageInOrder(){
    this.packageService.getListPackageInOrder(this.userType.value).subscribe((res)=>{
        this.listBusinessPackageRes = new ListPackageOrderResponse(res['object']);
        this.listBusinesPackage = this.listBusinessPackageRes.data
      })

  }

  // hủy dữ liệu vừa chọn
  clearData()
  { let msg = `Bạn chắc chắn muốn hủy phiên khởi tạo của dịch vụ này? <br /> (Toàn bộ thông tin đã nhập sẽ bị mất hết)`;
    this.modal(msg,()=>{
      this.makeForm();
      this.selectAllBtn(1)
      this.assignMoreSelected(1, 0)
    })
   
  }

  
  changeUserType(type){
    this.getAllPrivileges(type)
  }

  changeCurrency(){
    new Cleave('#packageValue', {
      numeral: true,
      numeralThousandsGroupStyle: 'thousand',
    })
  }

  isSelectPackage = false;
  selectPackageType(value){
    this.isSelectPackage = !this.isSelectPackage;
  }

  
  convertCurency(value: any) {
    let curency: any
    value = value.replace(/,/g, '')
    curency = value.replace(/VNĐ/g, '')
    console.log(curency, '==============curency')
    curency = Math.abs(curency);
    return curency;
  } 

  /**
   * Get danh sách tất cả các quyền
   */
  getAllPrivileges(type) {
    this.loadingServiceIsVisiable.next(true);
    var data: ListAllPermisionReponse;
    this.dualList = [new Array<DualListItem>(), new Array<DualListItem>()];  
    if(type === 'CONSUMER' ||  type === 'BUSINESS'){
      this.packageService.getAllPrivileges().subscribe(res => {
        this.loadingServiceIsVisiable.next(false);
        data = new ListAllPermisionReponse(res);
        this.listPermissionGroup = data.object;
        this.setBaseList();
        this.dualList[0] = this.cloneObject(this.baseList);
        this.dualList[1] = [];
        this.backupDualList()
      }, err => {
        console.log(err);
        this.loadingServiceIsVisiable.next(false);
        this.handleError(err, ResponseMsg.MESSAGES.AUTH_SERVICE);
      }, () => {
        this.loadingServiceIsVisiable.next(false);
      });
    }
    if(type === 'ENTERPRISE'){
      this.packageService.getAllCategoryPackage().subscribe(res=>{
        this.listCategoryPackageRes = new ListCategoryPackageResponse(res['object']);
        this.listCategoryPackage = this.listCategoryPackageRes.data;
        this.setBaseListCategory()
        this.dualList[0] = this.cloneObject(this.baseList);
        this.dualList[1] = [];
        this.backupDualList()
      }, err => {
        console.log(err);
        this.loadingServiceIsVisiable.next(false);
        this.handleError(err, ResponseMsg.MESSAGES.AUTH_SERVICE);
      }, () => {
        this.loadingServiceIsVisiable.next(false);
      })

      this.packageService.getAllCategoryPackage("HATANG").subscribe((res: any) => {
        let list = new ListCategoryPackageResponse(res?.object);
        this.listFieldInfra = list.data;
        this.setFormInfra(this.listFieldInfra);
      }, err => {
        console.log(err);
      })
    }
  }

  setBaseListCategory() {
    this.baseList = new Array<DualListItem>()
    this.listCategoryPackage.forEach((item, index) => {
      this.baseList.push(new DualListItem({
        id: item.serviceInfoId,
        name: item.name,
        index: index
      }))
    });
  }

  setFormInfra(data: CategoryPackageItem[]) {
    console.log(data);
    let group: any = {};
    data.forEach((item, index) => {
      group[item.serviceInfoId] = new FormControl("", Validators.required)
      this.validateMsgInfra[item.serviceInfoId] = {
        required: "Vui lòng nhập thông tin " + item.name + "!"
      }
    });

    this.formInfra = this.fb.group(group);
    console.log(this.formInfra);
  }

  isEmptyObj(obj: any) {
    return Object.keys(obj).length === 0;
  }


   /**
   * tạo gói cho nhóm người dùng
   * @param param 
   */
  createPackage(param) {
    this.isSubmit = true;
    this.loadingForm.next(true);
    this.packageService.postCreatePackage(param).subscribe(res => {
      this.loadingForm.next(false);
      this.alert("Tạo gói dịch vụ thành công!", 'success', false, '', 'OK', () => {
        this.makeForm();
        this. selectAllBtn(1)
        this.assignMoreSelected(1, 0)
        this.isSubmit = false;
      });
      

    }, err => {
      this.loadingForm.next(false);
      this.handleError(err, ResponseMsg.MESSAGES.ALL_IN_ONE);
    }, () => {
      this.loadingForm.next(false);
    });
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], 'error');
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, 'error');
    } else {
      this.alert('Lỗi không xác định! Vui lòng thử lại', 'error');
    }
  }

  //MARK: Popup thông báo
  modal(msg, callBack = () => { }) {
    const modalRef = this.modalService.open(NgModalComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.callback = callBack;
  }

   //MARK: Popup thông báo
   alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }


  danhMucHaTang = [];
  danhMucChucNang = [];
  dmHaTang = null;
  dmChucNang = null;

  changeSameInfra() {
    this.sameInfra = !this.sameInfra;
  }

  eventChangeDmHaTang(e) {
    console.log(e);
  }

  eventChangeDmChucNang(e) {
    console.log(e);
  }

  getServicePackageCatalog(packageType = "") {
    this.packageService.getListCategoryPackage(1, 1000000, "DESC",
      "modified", packageType, "ENABLED" , "").subscribe((res: any) => {
        if (packageType == "HATANG") this.danhMucHaTang = res?.object?.data || [];
        if (packageType == "CHUCNANG") this.danhMucChucNang = res?.object?.data || [];
      }, err => {
        this.myAlert.showErrorHandled(err);
      })
  }

}
