export const localize_e_sign_otp = {
    otp_description: "Vui lòng nhập mã xác nhận được gửi về  ${{signForm}} ${{sdtEmail} <br> Mã OTP sẽ hiết hiệu lực sau 5 phút.",
    otp_required: 'Mã OTP không được để trống!',
    otp_minlength: 'Mã OTP bao gồm 6 ký tự số!',
    otp_maxlength: 'Mã OTP bao gồm 6 ký tự số!',
    not_found_sign_session: "Không tìm thấy phiên yêu cầu ký! Vui lòng gửi yêu cầu mới!",
    a_resend_otp_esign: "Gửi lại mã OTP",
    confirm_otp_failed: "Mã OTP không chính xác. Vui lòng thử lại! (Bạn còn {{solan}} lần nhập mã OTP)",
    confirm_otp_failed_limit: "Bạn đã sử dụng quá số lần xác nhận OTP. Vui lòng thực hiện lại quá trình ký điện tử.",
    email_signForm: "email",
    sms_signForm: "số điện thoại"
}