import { ApiDetailResponse } from './../../../../../utils/models/aas-models/api-manager/api-detail/api-detail-response.model';
import { ApiDetailObject, ListParamInforItem } from './../../../../../utils/models/aas-models/api-manager/api-detail/api-detail-object.model';
import { ApiMenusResponse } from './../../../../../utils/models/aas-models/api-manager/api-menus/api-menus-response.model';
import { ApiMenusItem, ApiItem } from './../../../../../utils/models/aas-models/api-manager/api-menus/api-menus-item.model';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiManagerService } from './../../../../../utils/services/aas-network/api-manager.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import * as ClassicEditor from '../../../../../../assets/js/editor/ckeditor';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { AuthService } from 'src/app/utils/services/auth.service';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ConstantAlertMsg } from 'src/app/utils/common/constant-alert-msg';
import { ConstantAlertBtn } from 'src/app/utils/common/constant-alert-msg';
import { StringUtils } from 'src/app/utils/common/StringUtils';

@Component({
  selector: 'app-service-api-manager',
  templateUrl: './service-api-manager.component.html',
  styleUrls: ['./service-api-manager.component.scss']
})
export class ServiceApiManagerComponent implements OnInit {
  public Editor = ClassicEditor;
  @ViewChild('myEditor') myEditor: ElementRef;

  content_pre = {
    "key": "123123"
  };
  content_string = JSON.stringify(this.content_pre);

  apiMenus: ApiMenusItem[] = [];
  apiDetail: ApiDetailObject = new ApiDetailObject();
  apiMenuData: Array<ApiMenusItem> = [];
  baseApiMenu: Array<ApiMenusItem> = [];

  paramsHeader: ListParamInforItem[] = [];
  paramsBody: ListParamInforItem[] = [];
  paramsOutputSuccess: ListParamInforItem[] = [];
  paramsOutputError: ListParamInforItem[] = [];

  constructor(
    private authService: AuthService,
    private apiService: ApiManagerService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.getListApiByUser(this.authService.userId);
  }

  /**
   * Mo rong menu
   * @param e 
   */
  openRoleGroup(e) {
    console.log(e);
    e.stopPropagation();
    let element = e.target as Element;
    let parentElement = element.parentElement;

    if (parentElement.classList.contains("nav-item-open")) {
      parentElement.classList.remove("nav-item-open");
    } else {
      parentElement.classList.add("nav-item-open");
    }
  }
  
  /**
   * Chon api
   * @param id 
   */
  selectApiItem(e, id) {
    e.stopPropagation();
    this.getDetailApi(id);
  }
  
  /**
   * filter api menu
   * @param event 
   */
  filterMenu(event) {
    let value = event.target.value;

    //Neu input value khong co gia tri
    if (value === "" && value === null) {
      this.apiMenuData = JSON.parse(JSON.stringify(this.baseApiMenu)) as Array<ApiMenusItem>;
      return;
    }

    this.apiMenuData = this.baseApiMenu.map((item) => {
      let tmp = JSON.parse(JSON.stringify(item)) as ApiMenusItem;
      let tmp_name = StringUtils.removeAccents(tmp.roleName);

      //Loai bo api khong trung thong tin
      for (var i = tmp.listApi.length - 1; i >= 0 ; i--) {
        let api_name = StringUtils.removeAccents(tmp.listApi[i].description);
        if (!api_name.toUpperCase().match(new RegExp(value.toUpperCase(), "g"))) {
          tmp.listApi.splice(i, 1);
        };
      }

      if (tmp.listApi.length <= 0) {
        if (tmp_name.toUpperCase().match(new RegExp(value.toUpperCase(), "g"))) {
          tmp.listApi = [];
          tmp.listApi = JSON.parse(JSON.stringify(item.listApi)) as Array<ApiItem>;
        } else {
          tmp = null;
        };
      }

      return tmp;
    })
  }

  /**
   * Phan loai danh sach param
   * @param list 
   */
  setListParamByType(list: ListParamInforItem[]) {
    this.paramsHeader = list.filter((item) => {
      return item.element == 'header'
    });

    this.paramsBody = list.filter((item) => {
      return item.element == 'body'
    });

    this.paramsOutputSuccess = list.filter((item) => {
      return item.element == 'outputSuccess';
    });

    this.paramsOutputError = list.filter((item) => {
      return item.element == 'outputError';
    });
  }

  //MARK: NETWORKING
  /**
   * Lay danh sach api theo user
   * @param userId 
   */
  getListApiByUser(userId) {
    this.apiService.getListApiByUser(userId).subscribe((res: any) => {
      console.log(res);
      this.apiMenus = new ApiMenusResponse(res).object;

      this.apiMenuData = this.apiMenus.filter((item) => {
        return item.listApi.length > 0;
      });

      this.baseApiMenu = JSON.parse(JSON.stringify(this.apiMenuData));
    }, err => {
      this.handleError(err, ResponseMsg.MESSAGES.ALL_IN_ONE);
    })
  }

  /**
   * Lay thong tin chi tiet cua api
   * @param id 
   */
  getDetailApi(id) {
    this.apiService.getDetailApi(id).subscribe((res: any) => {
      console.log(res);

      this.apiDetail = new ApiDetailResponse(res).object;

      //Phan loai danh sach param
      this.setListParamByType(this.apiDetail.listParamInfo);
      
    }, err => {
      this.handleError(err, ResponseMsg.MESSAGES.ALL_IN_ONE);
    })
  }

  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = ConstantAlertBtn.OK, actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = ConstantAlertBtn.CANCEL, actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], ConstantAlertType.ERROR);
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, ConstantAlertType.ERROR);
    } else {
      this.alert(ConstantAlertMsg.DEFAULT_ERROR_REQUEST, ConstantAlertType.ERROR);
    }
  }
}
