<app-spinner></app-spinner>
<div class="landingpage">
    <div class="register">
        <section class="register-ss1">
            <img src="assets/img/home/v4/Image/Logo eContract_change color.png" alt=""
                class="img-logo-ipadpro">
            <div class="content col-12 p-0">
                <div class="row swap-col">
                    <div class="col-auto-new p-0">
                        <div class="row">
                            <div class="col-12">
                                <div class="content-left">
                                    <div class="row">
                                        <div class="col-left-auto">
                                            <div class="body">
                                                <span class="logo block">
                                                    MỘT SẢN PHẨM CỦA TẬP ĐOÀN
                                                    <img src="../../../assets/img/home/v4/Image/logo vnpt.png" alt="">
                                                </span>
                                                <p class="title">
                                                    Hợp đồng điện tử<br />
                                                    NBW eContract
                                                </p>
                                                <span class="dec">
                                                    Cung cấp dịch vụ hợp đồng điện tử cho phép các bên giao kết các loại hợp đồng
                                                    bằng phương tiện điện tử.
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-left-auto">
                                            <img src="assets/img/home/v4/Image/Group 177362.png" alt="" class="d-xl-block imgbg">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-auto-new p-0">
                        <div class="content-right">
                            <div class="row justify-content-center">
                                <div class="col-10 col-md-6 col-xl-9">
                                    <div class="body">
                                        <img src="assets/img/home/v4/Image/logo-techlab.png" alt="" class="img-logo">
                                        <h3 class="title">{{ 'LoginWithOTP_sign_in' | translate }}</h3>
                                       
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-10 col-md-6 col-xl-9">
                                    <!-- <label for="">khách hàng</label>
                                    <ul class="nav nav-tabs" role="tablist">
                                        <li class="nav-item">
                                            <a class="nav-link active" href="#profile" role="tab" data-toggle="tab">
                                                <img src="./icon/user.png" alt="">
                                                Cá nhân</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" href="#buzz" role="tab" data-toggle="tab">
                                                <img src="./icon/Group.png" alt="">
                                                Tổ chức</a>
                                        </li>
                                    </ul> -->
    
                                    <div class="login-form">
                                        <form [formGroup]="loginForm">
                                            <div class="form-group">
                                                <label for="">{{ 'Login_account' | translate }}<span class="text-danger">*</span></label>
                                                <input appTrimValue appLowercaseValue formControlName="username" type="text"
                                                    class="form-control" placeholder="{{ 'Login_account' | translate }}">
                                                <!-- <app-show-validate-errors [isSubmit]="isSubmit"
                                                    [errorMessages]="validateMsg.username" [control]="username"
                                                    [detail]="{ name: 'username' }"></app-show-validate-errors> -->
                                            </div>
                                            
                                            <button (click)="login()" [disabled]="disabledButton" [ngClass]="{'disabled-button': disabledButton}"
                                                class="btn_reg">{{ 'LoginWithOTP_send_OTP' | translate }}</button>
                                        </form>
                                        <div class="text-left pb-3">
                                            <span><a class="text-primary text-center text-decoration-underline"
                                                    [routerLink]="['/app/login']">{{ 'LoginWithOTP_redirect_normal_login' | translate }}</a></span>
                                        </div>
                                        <div *ngIf="false" class="text-left">
                                            <span>{{ 'Login_not_yet_account' | translate }} <a class="text-primary text-center text-decoration-underline"
                                                    [routerLink]="['/app/signup']">{{ 'Login_sign_up' | translate }}</a></span>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
<a href="" class="btn-backhome">
    <img src="assets/img/home/v4/icon/backicon.png" alt="">
</a>