export class PermissionGroup {
    public id: string = "";
    public name: string = "";
    public partyLevel: string = "";
    public privileges: Permission[] = [];
    public index: number = -1;
    public selected: boolean = false;
    public assigned: boolean = false;

    constructor(obj = null) {
        obj = obj || {};
        this.id = obj.uuidPType || "";
        this.name = obj.name || "";
        this.partyLevel = obj.partyLevel || "";
        this.privileges = [];
        if (obj.privileges && obj.privileges instanceof Array) {
            obj.privileges.forEach((item, index) => {
                this.privileges.push( new Permission(item));
                this.privileges[index].index = index;
            });
        }
    }
}

export class Permission {
    public id: string = "";
    public name: string = "";
    public description: string = "";
    public index: number = -1;
    public selected: boolean = false;
    public assigned: boolean = false;
    
    constructor(obj = null) {
        obj = obj || {};
        this.id = obj.uuidPrivilege || "";
        this.name = obj.name || "";
        this.description = obj.description || "";
    }
}