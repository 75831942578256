import { Component, OnInit, Input, AfterContentChecked, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstantAlertBtn } from 'src/app/utils/common/constant-alert-msg';
import { ViewEncapsulation } from '@angular/core';


@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AlertComponent implements OnInit, AfterContentChecked, AfterViewInit {
  @Input() title: string = 'Thông báo';
  @Input() typeAlert: string = '';
  @Input() message: string = '';
  @Input() twoBtn: boolean = false;
  @Input() iconBtn1: string = '';
  @Input() iconBtn2: string = '';
  @Input() titleButton1: string = ConstantAlertBtn.CANCEL;
  @Input() titleButton2: string = ConstantAlertBtn.OK;
  @Input() callback1;
  @Input() callback2;
  @Input() noClose: boolean = false;

  imgAlert = {
    'success': '/assets/img/icon/success-icon.png',
    'error': '/assets/img/icon/error-icon.png'
  }

  constructor(public activeModal: NgbActiveModal, private changeDetector: ChangeDetectorRef) {}
  
  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
  
  ngOnInit() {
    this.changeDetector.detectChanges();
  }

  ngAfterViewInit() {
    this.changeDetector.detectChanges();
  }

  actButton1() {
    if (this.callback1 instanceof Function) {
      this.callback1();
    }
  }

  actButton2() {
    if (this.callback2 instanceof Function) {
      this.callback2();
    }
  }
}
