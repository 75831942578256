<app-spinner></app-spinner>
<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0 text-dark"><label>{{ 'ServiceSignatureManager_title' | translate }}</label></h1>
            </div>
            <!-- /.col -->
            <!-- <div class="col-sm-6">
                <button class="btn btn-primary float-sm-right" [routerLink]="['/portal/customers/create']"
                    *showWithRoles="['TTKD_AM']">Tạo khách hàng</button>
            </div> -->
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>

<!--MARK top bar -->
<section class="content">
    <div class="container-fluid">
        <div class="content-right-sidebar tab-bar-custom border-0 mb-4">
            <ul class="nav nav-tabs border-0" id="tabSignAction" role="tablist">
                <li *ngIf="USB_TOKEN" class="nav-item nav-material">
                    <a #usbtokentab class="nav-link d-flex" id="tabSignUsbToken-tab" data-toggle="pill"
                        href="#tabSignUsbToken" role="tab" aria-controls="tabSignUsbToken" aria-selected="true">
                        <em class="material-icons">usb</em>
                        {{ 'ServiceSignatureManager_tab_1' | translate }}
                    </a>
                </li>
                <li *ngIf="SMART_CA" class="nav-item nav-material">
                    <a #smartcatab class="nav-link d-flex align-items-center" id="tabSignSmartCa-tab" data-toggle="pill" href="#tabsmartca" fragment="tabsmartca" role="tab"
                        aria-controls="tabsmartca" aria-selected="false">
                        <em class="ct-icon-font icon-smart_ca mr-2"></em>
                        {{ 'ServiceSignatureManager_tab_2' | translate }}
                    </a>
                </li>
                <li *ngIf="SIGN_SERVER && controlAccount.checkRoles(['DSIGN'])" class="nav-item nav-material">
                    <a #vnptsigntab class="nav-link d-flex" id="tabSignServer-tab" data-toggle="pill" href="#tabSignServer" role="tab"
                        aria-controls="tabSignServer" aria-selected="false">
                        <em class="material-icons">wifi_tethering</em>
                        {{ 'ServiceSignatureManager_tab_3' | translate }}
                    </a>
                </li>
                <li *ngIf="E_SIGN && controlAccount.checkRoles(['ESIGN'])" class="nav-item nav-material">
                    <a #esigntab class="nav-link d-flex" id="tabDigitalSign-tab" data-toggle="pill" href="#tabDigitalSign" role="tab"
                        aria-controls="tabDigitalSign" aria-selected="false">
                        <em class="material-icons">gesture</em>
                        {{ 'ServiceSignatureManager_tab_4' | translate }}
                    </a>
                </li>
            </ul>
        </div>
        <div class="tab-content contract-tab-body" id="tabSignAction">
            <!--MARK chữ kí số USB-Token -->
            <div *ngIf="USB_TOKEN" class="position-relative tab-pane fade" id="tabSignUsbToken" role="tabpanel" aria-labelledby="tabSignUsbToken">
                <app-usb-token-manager></app-usb-token-manager>
            </div>

            <div *ngIf="SMART_CA" class="position-relative tab-pane fade" id="tabsmartca" role="tabpanel" aria-labelledby="tabsmartca">
                <app-smartca-manager></app-smartca-manager>
            </div>
        
            <!--MARK chữ kí sign-server  -->
            <div *ngIf="SIGN_SERVER" class="position-relative tab-pane fade" id="tabSignServer" role="tabpanel" aria-labelledby="tabSignServer">
                <app-sign-server-manager></app-sign-server-manager>
            </div>

            <div *ngIf="E_SIGN" class="position-relative tab-pane fade" id="tabDigitalSign" role="tabpanel" aria-labelledby="tabDigitalSign">
                <app-digital-signature-manager></app-digital-signature-manager>
            </div>
        </div>
    </div>
</section>