 <!-- VNPT CA -->
 <div *ngIf="showLayoutConfirmSmartCA == false" class="">
    <div class="row mt-3">
        <form [formGroup]="formSign" class="col-md-12 row">
            <div class="col-12">
                <div class="infor-box-header d-flex">
                    <label class="text-primary flex-grow-1 mb-0">{{ 'SmartcaManager_label_17' | translate }}</label>
                </div>
                <hr>
                <div class="row">
                    <div class="col-xl-6">
                        <div
                            class="ccheck-primary w-100 d-inline-block">
                            <input (change)="changedDateSign($event)"
                                formControlName="signDate"
                                type="checkbox" id="smartca_signDate">
                            <label for="smartca_signDate" class="w-100">
                                {{ 'SmartcaManager_label_19' | translate }}
                            </label>
                        </div>
                    </div>
                    <div class="col-xl-6">
                        <div
                            class="ccheck-primary w-100 d-inline-block">
                            <input (change)="changedSignBy($event)"
                                formControlName="signBy" type="checkbox"
                                id="smartca_signBy">
                            <label for="smartca_signBy" class="w-100">
                                {{ 'SmartcaManager_label_20' | translate }}
                            </label>
                        </div>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-xl-12 align-items-end d-flex">
                        <div class="form-group w-100">
                            <label>{{ 'USBTokenManager_co_chu' | translate }}:</label>
                            <input formControlName="fontSize"
                                (input)="inputFontSize($event)"
                                type="text" class="form-control"
                                placeholder="{{ 'ContractCensorship_ph_1' | translate }}">
                            <app-show-validate-errors
                                [isSubmit]="isSubmit"
                                [errorMessages]="validMsg.fontSize"
                                [control]="formSign.controls.fontSize"
                                [detail]="{ name: 'fontSize' }">
                            </app-show-validate-errors>
                        </div>
                    </div>
                </div>


                <div class="infor-box-header d-flex mt-3">
                    <label class="text-primary flex-grow-1 mb-0">{{ 'ServiceContractDetail_displaySignatureType' | translate }}</label>
                </div>
                <hr>
                <div class="row">
                    <div class="col-xl-12">
                        <label for="signType">{{ 'SmartCa_displaySignatureTypeSelecte' | translate }}:</label>
                        <ng-select 
                            [items]="hinhThucHienThi"
                            bindLabel="name"
                            bindValue="value"
                            [clearable]="false"
                            (change)="selectStyleSignBox($event)"
                            placeholder="{{ 'ServiceContractDetail_selectDisplaySignatureType' | translate }}"
                            formControlName="visibleType">
                            <ng-template ng-label-tmp let-item="item">
                                <span>{{ item.name | translate }}</span>
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item">
                                <span>{{ item.name | translate }}</span>
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="col-12 mt-3">
                <div class="infor-box-header">
                    <label class="text-primary flex-grow-1 mb-0">
                        {{ 'SmartCa_imgSignature' | translate }}
                    </label>
                </div>
                <hr>
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>{{ 'SmartCa_fileAttachment' | translate }}:</label>
                            <div class="input-group mb-1">
                                <div
                                    class="custom-file custom-file-icon">
                                    <label class="custom-file-label"
                                        for="inputFileImage">{{ inputFileImageLabel | translate }}</label>
                                    <input formControlName="imageFile"
                                        (input)="handleFileImageInput($event)"
                                        type="file" accept="image/*"
                                        class="custom-file-input"
                                        id="inputFileImage"
                                        aria-describedby="inputGroupFileAddon01">
                                    
                                    <span
                                    class="icon-file material-icons">attach_file</span>
                                </div>
                            </div>
                            <app-show-validate-errors
                                [useSubmit]="false"
                                [isSubmit]="isSubmit"
                                [errorMessages]="validMsg.imageFile"
                                [control]="formSign.controls.imageFile"
                                [detail]="{ name: 'imageFile' }">
                            </app-show-validate-errors>
                        </div>
                        <img id="img-smart-ca" [hidden]="imgBase64 == '' || imgBase64 == null || imgBase64 == undefined" class="w-100" style="max-width: 200px;"
                            [src]="imgBase64">
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>

<div *ngIf="showLayoutConfirmSmartCA == true" class="">
    <div *ngIf="count > 0" class="ticker-warning mb-3">
        <h5 class="font-weight-bold">{{ 'SmartCa_verifySmartCA' | translate }}</h5>
        <p >{{ 'SmartCa_verifySmartCADescrip' | translate }} </p>
        <p>{{ 'SmartCa_transactionExpireAfter' | translate }}</p>
        <span class="font-weight-bold" style="font-size: 2.5rem;">{{ counter$ | async }}</span>
    </div>
    <div *ngIf="count == 0" class="ticker-danger mb-3">
        <h5 class="font-weight-bold">{{ msgError | translate }}</h5>
        <p class="mb-0">{{ 'SmartCa_resign_pls' | translate }}</p>
    </div>
    <div class="ticker-primary mb-3">
        <div class="d-flex justify-content-center align-items-center">
            <img src="assets/img/qrcode-app-smart-ca.png" width="80" height="80">
            <p class="mb-0 ml-3">{{ 'SmartCa_resign_pls' | translate }}</p>
        </div>
    </div>
</div>