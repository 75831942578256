import { Component, OnInit,ViewChild,ElementRef,AfterViewInit } from '@angular/core';
import { Options } from 'select2';
import { Select2OptionData } from 'ng-select2';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import {
  map,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap
} from "rxjs/operators";
import { DateUtils } from 'src/app/utils/common/DateUtils';
import { AasEinvoicesService } from 'src/app/utils/services/aas-network/aas-einvoices.service';
import { AccountItem } from 'src/app/utils/models/aas-models/einvoices-manager/account-einvoices/account-item.model';
import { ListAccountResponse } from 'src/app/utils/models/aas-models/einvoices-manager/account-einvoices/account-list-response.model';
import { NgModalComponent } from 'src/app/layout/extensions/ng-modal/ng-modal.component';
import { from,Observable } from 'rxjs';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ConstantAlertBtn, ConstantAlertMsg } from 'src/app/utils/common/constant-alert-msg';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { SeriesItem } from 'src/app/utils/models/aas-models/einvoices-manager/einvoices-series-list/series-item.model';
import { ListSeriesResponse } from 'src/app/utils/models/aas-models/einvoices-manager/einvoices-series-list/series-list-response.model';

declare var Cleave: any;
declare var $: any;

@Component({
  selector: 'app-accout-einvoices-list',
  templateUrl: './accout-einvoices-list.component.html',
  styleUrls: ['./accout-einvoices-list.component.scss']
})
export class AccoutEinvoicesListComponent implements OnInit {
  @ViewChild("searchFieldLicense") searchField: ElementRef;
 
  constantUrl = ConstantUrl;

  page: number = 1;
  maxSize: number = 10;
  sort: string = "DESC";
  propertiesSort: string = "companyName";
  totalElement: number = 0;

  public options: Options;
  formFilter: FormGroup;
  searchFields: FormControl;
  formEmail : FormGroup;

  saveKeySearch = '';
  keySearch = "";
  formSearch: FormGroup; 
  
  listEinvoicesAccount :  AccountItem[] = [];
  listEinvoicesAccountRes : ListAccountResponse;

  seriesItem : SeriesItem;
  listSeriesResponse : ListSeriesResponse

  public seriesData: Observable<Array<Select2OptionData>>;
  public templateData: Observable<Array<Select2OptionData>>;

  private results ;
 
  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private einvoicesService: AasEinvoicesService,
  ) { }

  ngOnInit(): void {
    this.getAccEinvoiceList();
    this.makeFormFilter()
    this.getListSeries();
    this.searchFields = new FormControl();
    this.results = this.searchFields.valueChanges.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      tap(_ => (this.search(this.searchField.nativeElement.value))),
    ).subscribe();
    this.options = {
      multiple: false,
      width: '100%',
      closeOnSelect: true,
      language: 'vi',
    };
  }

  // ngAfterViewInit() {
  // }

  makeFormFilter() {
    this.formFilter = this.fb.group({
      pattern : ['ALL'],
      serise: ['ALL'],
    })
  }

  get pattern() {
    return this.formFilter.get('pattern');
  }
  get series() {
    return this.formFilter.get('series');
  }


   //MARK: Khởi tạo form search
   makeFormSearch() {
    this.formSearch = this.fb.group({
      search: new FormControl('')
    });
  }

  // get danh sachs hoa don
  getAccEinvoiceList(){
    this.einvoicesService.getAccountList(this.page, this.maxSize).subscribe((res)=>{
      this.listEinvoicesAccountRes = new ListAccountResponse(res['object']);
      this.listEinvoicesAccount = this.listEinvoicesAccountRes.data;
      console.log(this.listEinvoicesAccountRes, ' ==========listEinvoices')
      this. page = this.listEinvoicesAccountRes.page;
      this.maxSize = this.listEinvoicesAccountRes.maxSize;
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.ALL_IN_ONE);
    })

  }

  // get danh sách series hóa đơn
  getListSeries(){
    this.einvoicesService.getListSeries().subscribe((res:any)=>{
      this.listSeriesResponse = new ListSeriesResponse(res)
      this.seriesData = this.makeSeriesData();
      this.templateData = this.makeTemplateData();
    })
  }

  // đổ dữ liệu vào droplist danh sách series
  makeSeriesData(): Observable<Array<Select2OptionData>> {
    let data: Array<Select2OptionData> = [];
    if (this.listSeriesResponse.data && this.listSeriesResponse.data instanceof Array) {
      this.listSeriesResponse.data.forEach((item) => {
        data.push({ id: item.serial.toString(), text: item.serial });
      });
    }
    return Observable.create((obs) => {
      obs.next(data);
      obs.complete();
    });
  }
  // đổ dữ liệu vào droplist
  makeTemplateData(): Observable<Array<Select2OptionData>> {
    let data: Array<Select2OptionData> = [];
    if (this.listSeriesResponse.data && this.listSeriesResponse.data instanceof Array) {
      this.listSeriesResponse.data.forEach((item) => {
        data.push({ id: item.pattern.toString(), text: item.pattern });
      });
    }
    return Observable.create((obs) => {
      obs.next(data);
      obs.complete();
    });
  }

  // xóa acc 
  deleteAcc(id){
    this.modal("Bạn có chắc chắn muốn xóa tài khoản hóa đơn điện tử này không",()=>{
      this.einvoicesService.deleteCustomer(id).subscribe(res=>{
        console.log(res);
        this.alert('Cập nhật tài khoản hóa đơn thành công','success', false, '', 'OK', ()=>{
          this.getAccEinvoiceList();
        })

      }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.ALL_IN_ONE);
    })
    })
  }


  //Show popup confirm OTP
  openModalConfirmEmail(content) {
    this.modalService.open(content, {
      size: 'md',
      backdrop: 'static',
      keyboard: false
    });
  }

  
  inputKeySearch(e) {
    this.saveKeySearch = e.target.value;
  }

  // search thông tin hóa đơn
  search(keyWord) {
    this.page = 1;
    this.keySearch = keyWord.trim();
    this.getAccEinvoiceList();
  }

  // clear dữ liệu filter
  cleanFilter(){
    this.makeFormFilter();
    this.keySearch = '';
    this.saveKeySearch = this.keySearch;
    this.searchFields.reset();
    this.formFilter.reset();
    this.pattern.setValue('');
    this.series.setValue('');
    this.page = 1;
    this.searchWithFilter();
  }
  // serch filter
  searchWithFilter(){
    console.log('============dsjhfuerwif')
    console.log(this.formFilter.value, '=====================hanh')
    this.getAccEinvoiceList();
    

  }
  // load lại danh sách khi đổi page
  loadPage(page){
    this.page = page;
  }

  //MARK: Popup thông báo
  modal(msg, callBack = () => { }) {
    const modalRef = this.modalService.open(NgModalComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.callback = callBack;
  }

   //MARK: Popup thông báo
   alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = ConstantAlertBtn.OK, actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = ConstantAlertBtn.CANCEL, actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

   //MARK: Handle Error
   handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], ConstantAlertType.ERROR);
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, ConstantAlertType.ERROR);
    } else {
      this.alert(ConstantAlertMsg.DEFAULT_ERROR_REQUEST, ConstantAlertType.ERROR);
    }
  }

 

 



}
