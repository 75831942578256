import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { CategoryItem } from 'src/app/utils/models/category/category-item.model';
import { BlogItem } from 'src/app/utils/models/landing-page/blog-item.model';
import { LandingpageService } from 'src/app/utils/services/aas-network/landingpage.service';

@Component({
  selector: 'app-huong-dan-su-dung',
  templateUrl: './huong-dan-su-dung.component.html',
  styleUrls: ['./huong-dan-su-dung.component.scss'],
})
export class HuongDanSuDungComponent implements OnInit {
  categories: CategoryItem[] = [
    // {
    //   title: 'Hợp đồng điện tử',
    //   content:
    //     'Cung cấp dịch vụ hợp đồng điện tử cho phép các bên giao kết các loại hợp đồng bằng phương tiện điện tử.',
    //   image: 'https://media.istockphoto.com/photos/playful-happy-smiling-pet-dog-running-in-the-grass-picture-id1320018473?b=1&k=20&m=1320018473&s=170667a&w=0&h=Q-U9yI4JjCJYSAzXZwpnM4HuaXPzo4K-vBsgO7lanyo=',
    //   btnTitle: 'Tạo hợp đồng',
    // },
    // {
    //   title: 'Gửi các bên tham gia ký hợp đồng',
    //   content:
    //     'Gửi lời mời cho các bên cần ký hợp đồng bằng email, quy định hình thức ký, xác minh danh tính hoặc pháp nhân bên được mời, quy định vị trí chữ ký ...',
    //   image: 'https://thumbs.dreamstime.com/b/golden-retriever-dog-21668976.jpg',
    //   btnTitle: 'Tìm hiểu thêm',
    // },
    // {
    //   title: 'Đàm phán trực tuyến',
    //   content:
    //     'Cho phép các bên thảo luận, ghi nhận thay đổi nội dung hợp đồng, đàm phán các điều khoản và điều chỉnh nội dung hợp đồng hoàn toàn trực tuyến.',
    //   image: 'https://c8.alamy.com/comp/HN6W1D/jack-russell-terrier-HN6W1D.jpg',
    //   btnTitle: 'Tìm hiểu thêm',
    // },
    // {
    //   title: 'Ký kết hợp đồng',
    //   content:
    //     'Cho phép ký nháy, ký duyệt và ký văn thư đóng dấu; quy định thứ tự ký; có 04 hình thức ký từ ký điện tử đến ký số phù hợp với cả pháp nhân và thể nhân.',
    //   image: 'https://c8.alamy.com/comp/RB12TT/jack-russell-terrier-puppy-dog-with-long-hair-play-orange-ball-in-green-grass-RB12TT.jpg',
    //   btnTitle: 'Tìm hiểu thêm',
    // },
  ];
  blogs: BlogItem[] = [];
  currentIdx: number = 1;
  postDetail: BlogItem = null;
  constructor(private landingpageService: LandingpageService, private myAlert: AlertControl) {}

  ngOnInit(): void {
    this.getBlogsByParentKey('hdsd_dashboard');
  }
  getBlogByCatalog() {
    this.landingpageService.getChildCatagoryByParentCode('hdsd_dashboard', 'vi').subscribe(
      (res: any) => {
        if (res && res.object instanceof Object && res.object.childrens instanceof Array) {
          this.categories = [];
          res.object.childrens.forEach((child) => {
            this.categories.push(new CategoryItem(child));
            console.log(this.categories);
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  getBlogsByParentKey(key) {
    this.landingpageService.getBlogsByParentKey(key, 'vi').subscribe((res: any) => {
      if (res && res.object.item instanceof Object && res.object.item.blogs instanceof Array) {
        this.blogs = [];
        res.object.item.blogs.forEach((child) => {
          this.blogs.push(new BlogItem(child));
        });
        console.log(this.blogs);
      }
    });
  }
  openHDSD(id) {
    this.landingpageService.getBlogById(id).subscribe(
      (res: any) => {
        if (res && res.object instanceof Object) {
          this.postDetail = null;
          this.postDetail = res.object;
          this.myAlert.showAlert(
            `${this.postDetail.description}`,
            ConstantAlertType.BLANK,
            false,
            '',
            'Đóng',
            null,
            '',
            '',
            null,
            `${this.postDetail.title}`,
            { size: 'xl' }
          );
          // let s = document.createElement('div');
          // s.innerHTML = this.postDetail.description;
          // const mainContent = document.querySelector<HTMLElement>('.main-content');
          // mainContent.innerHTML = '';
          // mainContent.prepend(s);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  showSlide(n) {
    let i;
    let slides = document.querySelectorAll<HTMLElement>('.slide');
    if (n > slides.length) {
      this.currentIdx = 1;
    }
    if (n < 1) {
      this.currentIdx = slides.length;
    }
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = 'none';
    }
    slides[this.currentIdx - 1].style.display = 'block';
  }
  next(n) {
    this.showSlide((this.currentIdx += n));
  }
  prev(n) {
    this.showSlide((this.currentIdx -= n));
  }
}
