import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { RejectComponent } from './reject/reject.component';
import { ApprovedComponent } from './approved/approved.component';

@Component({
    selector: 'app-service-register-manager',
    templateUrl: './service-register-manager.component.html',
    styleUrls: ['./service-register-manager.component.scss'],
})
export class ServiceRegisterManagerComponent implements OnInit, AfterViewInit {
    @ViewChild(RejectComponent) reject: RejectComponent;
    @ViewChild(ApprovedComponent) approved: ApprovedComponent;

    constructor() {}

    ngOnInit(): void {}

    ngAfterViewInit() {
        setTimeout(
            () =>
                (this.reloadChildFunction = () => {
                    this.reject.reloadData();
                    this.approved.reloadData();
                }),
            0
        );
    }

    reloadChildFunction() {
        this.reject.reloadData();
        this.approved.reloadData();
    }
}
