import { MainResponse } from './../../main-response.model';
import { ListUserGroupObject } from './list-user-group-object';
export class ListUserGroupResponse extends MainResponse {
    object: ListUserGroupObject;
    constructor(obj = null) {
        super();
        obj = obj || {};
        this.init(obj);
        this.object = new ListUserGroupObject(obj.object);
    }
}



