
  <app-spinner></app-spinner>
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0 text-dark"><label>ông tin tài khoản hóa đơn điện tử</label></h1>
        </div>
       
      </div>
    </div>
  </div>

  <section class="content">
    <div class="container-fluid">
			<div class="row">
				<div class="col-md-6">
					<div class="card shadow-none mb-4">
						<div class="card-title">
							
						</div>
						<div class="card-body ">
							<div class="row">
								<label class=" col-sm-6 ">Thông tin chi tiết</label>
								<div class="col-sm-6 d-flex justify-content-end" >
                  <button class="btn btn-primary float-sm-right d-inline-flex" 
                  routerLinkActive="router-link-active" [routerLink]="['/app/console/einvoices/invoicesCustomer/update',uuid]"
									><em class="material-icons">edit</em>
									Chỉnh sửa</button>
								</div>
							</div>
							<hr>
							<div class="card-body">
								<form [formGroup]= "form">
									<div class="row">
										<div class="col-md-12">
											<div class="form-group">
												<label>Tên công ty <span class="text-danger">*</span>:</label>
												<input appTrimValue type="text" formControlName="companyName" class="form-control" disabled
													placeholder="Nhập tên công ty" />
												<app-show-validate-errors [useSubmit]="false" [isSubmit]="isSubmit" [errorMessages]="validateMsg.companyName"
                          [control]="form.controls.companyName" [detail]="{ name: 'companyName' }">
                      </app-show-validate-errors>
											</div>
										</div>
										<div class="col-md-6">
											<div class="form-group">
												<label>Mã số thuế <span class="text-danger">*</span>:</label>
												<input appTrimValue type="text" formControlName="taxCode" class="form-control" disabled
													placeholder="Nhập mã số thuế" />
												<app-show-validate-errors [useSubmit]="false" [isSubmit]="isSubmit" [errorMessages]="validateMsg.taxCode"
                          [control]="form.controls.taxCode" [detail]="{ name: 'taxCode' }">
												</app-show-validate-errors>
											</div>
										</div>
										<div class="col-md-6">
											
										</div>
										<div class="col-md-6">
											<div class="form-group">
												<label>Tài khoản <span class="text-danger">*</span>:</label>
												<input appTrimValue type="text" formControlName="accountInvoice" class="form-control" disabled
													placeholder="Nhập tài khoản đăng ký trên hệ thống eInvoice" />
												<app-show-validate-errors [useSubmit]="false" [isSubmit]="isSubmit" [errorMessages]="validateMsg.accountInvoice"
                          [control]="form.controls.accountInvoice" [detail]="{ name: 'accountInvoice' }">
												</app-show-validate-errors>
											</div>
										</div>
										<div class="col-md-6">
											<div class="form-group input-custom">
												<label>Mật khẩu <span class="text-danger">*</span>:</label>
												<div class="input-custom input-group">
                          <input appTrimValue appInputPassword type="password" formControlName="passwordInvoice" 
                          class="form-control form-control-lg input-custom-control" disabled
														/>
												</div>
												<app-show-validate-errors [useSubmit]="false" [isSubmit]="isSubmit" [errorMessages]="validateMsg.passwordInvoice"
                          [control]="form.controls.passwordInvoice" [detail]="{ name: 'passwordInvoice' }">
												</app-show-validate-errors>
											</div>
										</div>
										<div class="col-md-12">
											<div class="form-group">
												<label>URL service <span class="text-danger">*</span>:</label>
												<input appTrimValue type="text" formControlName="url" class="form-control" disabled
													placeholder="Nhập URL service" />
												<app-show-validate-errors [useSubmit]="false" [isSubmit]="isSubmit" [errorMessages]="validateMsg.url"
                          [control]="form.controls.url" [detail]="{ name: 'url' }">
												</app-show-validate-errors>
											</div>
										</div>
										<div class="col-md-6">
											<div class="form-group">
												<label>Tài khoản service <span class="text-danger">*</span>:</label>
												<input appTrimValue type="text" formControlName="serviceAccount" class="form-control" disabled
													placeholder="Nhập tài khoản service" />
												<app-show-validate-errors [useSubmit]="false" [isSubmit]="isSubmit" [errorMessages]="validateMsg.serviceAccount"
                          [control]="form.controls.serviceAccount" [detail]="{ name: 'serviceAccount' }">
											</app-show-validate-errors>
											</div>
										</div>
										<div class="col-md-6">
											<div class="form-group ">
												<label>Mật khẩu service <span class="text-danger">*</span>:</label>
												<div class="input-custom input-group">
													<input appTrimValue appInputPassword type="password" formControlName="servicePassword" class="form-control form-control-lg input-custom-control" disabled
														/>
												</div>
												<app-show-validate-errors [useSubmit]="false" [isSubmit]="isSubmit" [errorMessages]="validateMsg.servicePassword"
                          [control]="form.controls.servicePassword" [detail]="{ name: 'servicePassword' }">
											</app-show-validate-errors>
											</div>
										</div>
										<div class="col-md-6">
											<div class="form-group">
												<label>Mẫu hóa đơn <span class="text-danger">*</span>:</label>
												<input appTrimValue type="text" formControlName="pattern" class="form-control" disabled
													placeholder="Nhập mẫu hóa đơn" />
												<app-show-validate-errors [useSubmit]="false" [isSubmit]="isSubmit" [errorMessages]="validateMsg.pattern"
                          [control]="form.controls.pattern" [detail]="{ name: 'pattern' }">
											</app-show-validate-errors>
											</div>
										</div>
										<div class="col-md-6">
											<div class="form-group">
												<label>Series hóa đơn <span class="text-danger">*</span>:</label>
												<input appTrimValue type="text" formControlName="serial" class="form-control" disabled
													placeholder="Nhập series hóa đơn" />
												<app-show-validate-errors [useSubmit]="false" [isSubmit]="isSubmit" [errorMessages]="validateMsg.serial"
                          [control]="form.controls.serial" [detail]="{ name: 'serial' }">
											</app-show-validate-errors>
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
    </div>
  </section>
          