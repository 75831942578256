<!-- <app-spinner *ngIf="loadingServiceIsVisible() | async"></app-spinner> -->
<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-auto">
                <h1 class="m-0 text-dark"><label>{{ 'GroupPermissions_title' | translate }}</label></h1>
            </div>
            <!-- /.col -->
            <div class="col-md-auto ml-auto">
                <!-- <button class="btn btn-outline-primary btn-light d-inline-flex mr-3"><span
                        class="material-icons mr-1">publish</span> Import Excel</button>
                <button class="btn btn-outline-primary btn-light d-inline-flex mr-3"><span
                        class="material-icons mr-1">get_app</span> Tải file mẫu</button> -->
            </div>
        </div>
    </div>
</div>

<!--MARK top bar -->
<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-4">
                <div class="card shadow-none">
                    <div class="card-header border-0 py-3 pt-3">
                        <div class="row py-1">
                            <div class="col-md-auto align-self-end">
                                <label class="text-lg">{{ 'GroupPermissions_list_group' | translate }}</label>
                            </div>
                            <div class="col-md-auto ml-auto">
                                <button class="btn btn-primary d-inline-flex" (click)="actBtnAddd()"><span
                                        class="material-icons">add</span>
                                    {{ 'GroupPermissions_add_group' | translate }}</button>
                            </div>
                        </div>
                        <div class="input-group mt-3">
                            <form [formGroup]="formSearch" (ngSubmit)="searchData(formSearch.value.search)"
                                class="input-group">
                                <div class="input-group-prepend">
                                    <button class="btn btn-primary d-inline-flex pr-2 pl-2" type="submit"><span
                                            class="material-icons">search</span></button>
                                </div>
                                <input (input)="eventInputSearch($event)" type="text" class="form-control"
                                    formControlName="search" placeholder="{{ 'GroupPermissions_ph_enter_group_name' | translate }}" aria-label=""
                                    aria-describedby="basic-addon1">
                            </form>
                        </div>

                    </div>
                    <div class="position-relative">
                        <ng-template #noData>
                            <div class="no-content-list">
                                <img class="mb-2" src="assets/img/icon/icon-no-user-group.png" width="100">
                                <p class="mb-1">{{ 'GroupPermissions_no_data' | translate }}</p>
                            </div>
                        </ng-template>

                        <div *ngIf="listUserGroup.length > 0; else noData" class="content-list fade show">
                            <div *ngFor="let item of listUserGroup" (click)="selectUserGroup(item)"
                                class="user-group-item content-list-item px-4 pt-2"
                                [class.active]="item.uuidRole === uuidRole.value">
                                <div class="border-bottom d-flex">
                                    <span class="material-icons icon-item pr-3">group</span>
                                    <div class="flex-grow-1">
                                        <label class="title-item">{{ item.name }}</label>
                                        <p class="text-secondary">Ngày chỉnh sửa:
                                            {{ item.modifiedDate | date: 'dd/MM/yyyy' || '' }}</p>
                                    </div>
                                    <a class="text-decoration-underline text-primary pl-4"
                                        (click)="actBtnDelete($event, item.uuidRole)" style="cursor: pointer;">{{ 'GroupPermissions_lb_remove' | translate }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="totalElement > maxSize" class="pagination justify-content-xl-end pages">
                    <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="5" [rotate]="true"
                        [ellipses]="false" [pageSize]="maxSize" (pageChange)="loadPage($event)" [boundaryLinks]="true"
                        aria-label="Default pagination">
                        <ng-template ngbPaginationFirst>{{ 'GroupPermissions_first' | translate }}</ng-template>
                        <ng-template ngbPaginationLast>{{ 'GroupPermissions_last' | translate }}</ng-template>
                        <ng-template ngbPaginationPrevious>&laquo;</ng-template>
                        <ng-template ngbPaginationNext>&raquo;</ng-template>
                        <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                    </ngb-pagination>
                </div>
            </div>
            <div class="col-md-8">
                <!-- <div *ngIf="isLoading | async" class="d-flex justify-content-center mt-3">
                    <div class="spinner-grow text-primary" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div> -->
                <div class="card position-relative shadow-none">
                    <div class="card-header border-0 py-3 pt-3">
                        <div class="row py-1">
                            <div class="col-md-auto align-self-end">
                                <label class="text-lg">{{ 'GroupPermissions_group_infor' | translate }}</label>
                            </div>
                            <div class="col-md-auto ml-auto">
                                <button (click)="actBtnCancel()"
                                    class="btn btn-outline-primary btn-light d-inline-flex mr-4"><span
                                        class="material-icons">clear</span> {{ 'btn_cancel' | translate }}</button>
                                <button (click)="actBtnSave()" class="btn btn-primary d-inline-flex"><span
                                        class="material-icons mr-1">save</span> {{ 'btn_save' | translate }}</button>
                            </div>
                        </div>
                        <hr class="mb-0 mt-3">
                    </div>
                    <div class="card-body pt-0">
                        <form [formGroup]="formUserGroup">
                            <label class="text-primary">{{ 'GroupPermissions_details' | translate }}</label>
                            <hr>
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>{{ 'GroupPermissions_group_name' | translate }} <span class="text-danger">*</span>:</label>
                                        <input appTrimValue class="form-control" formControlName="name"
                                            placeholder="{{ 'GroupPermissions_ph_group_name' | translate }}">
                                        <div class="w-100">
                                            <app-show-validate-errors [isSubmit]="isSubmit"
                                                [errorMessages]="ErrorMsg.name"
                                                [control]="formUserGroup.controls.name"
                                                [detail]="{ name: 'name' }">
                                            </app-show-validate-errors>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>{{ 'status' | translate }} <span class="text-danger">*</span>:</label>
                                        <select class="form-control" formControlName="disable"
                                            (change)="changedStatus($event)">
                                            <option value="0" [selected]="+disable.value === 0">{{ 'status_running' | translate }}
                                            </option>
                                            <option value="1" [selected]="+disable.value === 1">{{ 'status_stopped' | translate }}
                                            </option>
                                        </select>
                                        <div class="w-100">
                                            <app-show-validate-errors [isSubmit]="isSubmit"
                                                [errorMessages]="ErrorMsg.disable"
                                                [control]="formUserGroup.controls.disable"
                                                [detail]="{ name: 'disable' }">
                                            </app-show-validate-errors>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>{{ 'GroupPermissions_group_code' | translate }} <span class="text-danger">*</span>:</label>
                                        <input appTrimValue class="form-control" formControlName="roleCode"
                                            placeholder="{{ 'GroupPermissions_ph_group_code' | translate }}">
                                        <div class="w-100">
                                            <app-show-validate-errors [isSubmit]="isSubmit"
                                                [errorMessages]="ErrorMsg.roleCode"
                                                [control]="formUserGroup.controls.roleCode"
                                                [detail]="{ name: 'roleCode' }">
                                            </app-show-validate-errors>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label>{{ 'GroupPermissions_desc' | translate }}:</label>
                                        <textarea style="height: 34px;"
                                            (input)="changeDescription($event.target.value)"
                                            [(ngModel)]="descriptionValue" [ngModelOptions]="{standalone: true}"
                                            class="form-control" placeholder="{{ 'GroupPermissions_ph_desc' | translate }}"></textarea>
                                    </div>
                                </div>
                            </div>

                            <label class="text-primary">{{ 'GroupPermissions_permission_infor' | translate }}</label>
                            <hr>
                            <div class="row">
                                <div class="col-md-6 px-3 py-2">
                                    <div class="row pt-2 pb-2">
                                        <div class="col-md-auto align-self-end">
                                            <p class="mb-2"><label class="mb-0">{{ 'GroupPermissions_permission_unassigned' | translate }} ({{countNotAssigned}})</label></p>
                                        </div>
                                        <div class="col-md-auto ml-auto">
                                            <button (click)="assignMoreSelected(0, 1)" class="btn btn-primary d-inline-flex"
                                                [attr.disabled]="enableBtnAssign(0) ? '' : null">{{ 'GroupPermissions_assign' | translate }}<span
                                                    class="material-icons ml-1">arrow_forward</span></button>
                                        </div>
                                    </div>
    
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend bg-white">
                                            <span class="input-group-text bg-white border-right-0 pl-1 pr-1"><span
                                                    class="material-icons">search</span></span>
                                        </div>
                                        <input #searchNotAssigned (input)="filterSearch($event, 0)" type="text" class="form-control border-left-0 pl-1"
                                            placeholder="{{ 'GroupPermissions_ph_permission' | translate }}">
    
                                    </div>
    
                                    <div class="list-permission enable-scroll">
                                        <ul class="content-list-permisson">
                                            <li *ngIf="dualList[0].length > 0">
                                                <div class="permission-group">
                                                    <span class="material-icons text-white p-2">check</span>
                                                    <div class="tcheck-primary w-100 d-inline-block">
                                                        <input (input)="selectAllBtn(0)" type="checkbox" id="select-all-assign" [attr.checked]="selectedAll[0] ? '' : null">
                                                        <label for="select-all-assign" class="w-100" [class.checked]="selectedAll[0]">
                                                            {{ 'GroupPermissions_lb_all' | translate }}
                                                        </label>
                                                    </div>
                                                    <span
                                                        class="material-icons icon-set-permission text-white p-2">arrow_forward</span>
                                                </div>
                                            </li>
                                            <li *ngFor="let li of dualList[0]; let i = index">
                                                <div class="permission-group">
                                                    <span (click)="showHideTreeView($event, i, 0)" class="material-icons p-2" style="cursor: pointer;">chevron_right</span>
                                                    <div class="tcheck-primary w-100 d-inline-block">
                                                        <input (input)="selectedParentItem(i, 0)"
                                                            [attr.checked]="li.selected ? '' : null" type="checkbox"
                                                            id="parent-{{li.id}}">
                                                        <label for="parent-{{li.id}}" class="w-100"
                                                            [class.checked]="li.selected">
                                                            {{ (componentName +'_' + li.keyLanguage + '_name') | translate }}
                                                        </label>
                                                    </div>
                                                    <span (click)="assignGroup(i, 0, 1)"
                                                        class="material-icons icon-set-permission p-2" style="cursor: pointer;">arrow_forward</span>
                                                </div>
                                                <ul *ngIf="li.child.length > 0 && li.showChild" class="permission-child">
                                                    <li *ngFor="let subli of li.child; let j = index">
                                                        <div class="tcheck-primary w-100 d-inline-block">
                                                            <input (input)="selectedChildItem(i, j, 0)"
                                                                [attr.checked]="subli.selected ? '' : null" type="checkbox"
                                                                id="child-{{subli.id}}">
                                                            <label for="child-{{subli.id}}" class="w-100"
                                                                [class.checked]="subli.selected">
                                                                {{ (componentName + '_' + subli.keyLanguage + '_description') | translate }}
                                                            </label>
                                                        </div>
                                                        <span (click)="assign(i, j, 0, 1)"
                                                            class="material-icons icon-set-permission p-2">arrow_forward</span>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="col-md-6 px-3 py-2">
                                    <div class="row pt-2 pb-2">
                                        <div class="col-md-auto align-self-end">
                                            <p class="mb-2"><label class="mb-0">{{ 'GroupPermissions_permission_assigned' | translate }} ({{countAssigned}})</label></p>
                                        </div>
                                        <div class="col-md-auto ml-auto">
                                            <button (click)="assignMoreSelected(1, 0)" class="btn btn-primary d-inline-flex"
                                                [attr.disabled]="enableBtnAssign(1) ? '' : null">{{ 'GroupPermissions_unassign' | translate }}<span
                                                    class="material-icons ml-1">clear</span> </button>
                                        </div>
                                    </div>
    
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend bg-white">
                                            <span class="input-group-text bg-white border-right-0 pl-1 pr-1"><span
                                                    class="material-icons">search</span></span>
                                        </div>
                                        <input #searchAssigned (input)="filterSearch($event, 1)" type="text" class="form-control border-left-0 pl-1"
                                            placeholder="{{ 'GroupPermissions_ph_permission' | translate }}">
                                    </div>
    
                                    <div class="list-permission">
                                        <ul class="content-list-permisson">
                                            <li *ngIf="dualList[1].length > 0">
                                                <div class="permission-group">
                                                    <span class="material-icons text-white p-2">check</span>
                                                    <div class="tcheck-primary w-100 d-inline-block">
                                                        <input (input)="selectAllBtn(1)" type="checkbox" id="select-all-unassign" [attr.checked]="selectedAll[1] ? '' : null">
                                                        <label for="select-all-unassign" class="w-100" [class.checked]="selectedAll[1]">
                                                            {{ 'GroupPermissions_lb_all' | translate }}
                                                        </label>
                                                    </div>
                                                    <span
                                                        class="material-icons icon-set-permission text-white p-2">arrow_forward</span>
                                                </div>
                                            </li>
                                            <li *ngFor="let li of dualList[1]; let i = index">
                                                <div class="permission-group">
                                                    <span (click)="showHideTreeView($event, i, 1)" class="material-icons p-2" style="cursor: pointer;">chevron_right</span>
                                                    <div class="tcheck-primary w-100 d-inline-block">
                                                        <input (input)="selectedParentItem(i, 1)"
                                                            [attr.checked]="li.selected ? '' : null" type="checkbox"
                                                            id="assigned-parent-{{li.id}}">
                                                        <label for="assigned-parent-{{li.id}}" class="w-100"
                                                            [class.checked]="li.selected">
                                                            {{ (componentName +'_' + li.keyLanguage + '_name') | translate }}
                                                        </label>
                                                    </div>
                                                    <span (click)="assignGroup(i, 1, 0)"
                                                        class="material-icons icon-set-permission p-2" style="cursor: pointer;">clear</span>
                                                </div>
                                                <ul *ngIf="li.child.length > 0 && li.showChild" class="permission-child">
                                                    <li *ngFor="let subli of li.child; let j = index">
                                                        <div class="tcheck-primary w-100 d-inline-block">
                                                            <input (input)="selectedChildItem(i, j, 1)"
                                                                [attr.checked]="subli.selected ? '' : null" type="checkbox"
                                                                id="assigned-child-{{subli.id}}">
                                                            <label for="assigned-child-{{subli.id}}" class="w-100"
                                                                [class.checked]="subli.selected">
                                                                {{ (componentName + '_' + subli.keyLanguage + '_description') | translate }}
                                                            </label>
                                                        </div>
                                                        <span (click)="assign(i, j, 1, 0)"
                                                            class="material-icons icon-set-permission p-2">clear</span>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>