import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProductsService } from 'src/app/utils/services/aas-network/products.service';
import { CategoryResponse } from 'src/app/utils/models/category/category-response.model';
import { Options } from 'select2';
import { Select2OptionData } from 'ng-select2';
import { CategoryItem } from 'src/app/utils/models/category/category-item.model';
import { ListCategoryResponse } from 'src/app/utils/models/category/list-category-response.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgModalComponent } from 'src/app/layout/extensions/ng-modal/ng-modal.component';
import { API } from 'src/app/utils/services/api';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { REGEXP_ID } from 'src/app/utils/common/regexp';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { ConstantAlertBtn } from 'src/app/utils/common/constant-alert-msg';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { DANHMUCSPDV } from 'src/app/utils/common/constant-vaildate-msg';

@Component({
  selector: 'app-service-products-manager',
  templateUrl: './service-products-manager.component.html',
  styleUrls: ['./service-products-manager.component.scss']
})
export class ServiceProductsManagerComponent implements OnInit {
  @ViewChild('searchField') searchField: ElementRef;
  @ViewChild('uploader') uploader: ElementRef;

  categories: Array<CategoryItem> = [];
  form: FormGroup;
  categoryResponse: CategoryResponse;
  options: Options;

  dropListProductServicesData: Array<Select2OptionData> = [
    {
      id: 'Viễn thông',
      text: 'Viễn thông'
    },
    {
      id: 'Công nghệ thông tin',
      text: 'Công nghệ thông tin'
    },
    {
      id: 'Giá trị gia tăng',
      text: 'Giá trị gia tăng'
    },
    {
      id: 'Gói tích hợp',
      text: 'Gói tích hợp'
    },
    {
      id: 'Khác',
      text: 'Khác'
    }
  ];

  page: number = 1;
  maxSize: number = 5;
  propertiesSort: string = 'dateUpload';
  sort: string = 'DESC';
  totalElement: number = 0;
  category: any;
  currentCategory: CategoryItem = new CategoryItem();

  validateMsg = DANHMUCSPDV;
  isSubmit: boolean = false;

  constructor(
    private fb: FormBuilder,
    private api: API,
    private productService: ProductsService,
    private modalService: NgbModal
  ) {
    this.makeForm();
    this.setupSelect2();
  }

  ngOnInit(): void {
    this.loadPage(1);
  }

  ngAfterViewInit() {
    this.searchField.nativeElement.value = sessionStorage.getItem('categoryListKeySearch') || '';
  }

  /**
   * Khoi tao form
   */
  makeForm() {
    this.form = this.fb.group({
      id: [''],
      productName: ['', [Validators.required]],
      productId: ['', [Validators.required, Validators.pattern(REGEXP_ID)]],
      // productService: [[], [Validators.required]],
      productNote: ['']
    });
  }

  /**
   * Cai dai select2
   */
  setupSelect2() {
    this.options = {
      multiple: true,
      width: '100%',
      closeOnSelect: true,
      language: 'vi'
    };
  }

  /**
   * Get key search
   */
  get keySearch(): any {
    return sessionStorage.getItem('categoryListKeySearch') || '';
  }

  //MARK: Tìm kiếm
  //Action tìm kiếm
  searchData(value: string) {
    const searchValue = value;
    sessionStorage.setItem('categoryListKeySearch', searchValue);
    this.loadPage(1);
  }

  loadPage(page: number) {
    this.page = page;
    this.getListCategories(page, this.maxSize, this.propertiesSort, this.sort, this.keySearch);
  }

  //MARK: NETWORKING
  /**
   * Getting list category
   */
  getListCategories(page, maxSize, propertiesSort, sort, keySearch) {
    this.productService.getListCategory(page, maxSize, propertiesSort, sort, keySearch).subscribe(
      (res) => {
        this.categoryResponse = new ListCategoryResponse(res);
        this.categories = this.categoryResponse.object.data;
        this.page = this.categoryResponse.object.page;
        this.totalElement = this.categoryResponse.object.totalElement;

        if (this.categories && this.categories.length > 0) {
          this.editCategory(this.categories[0]);
        }
      },
      (err) => {
        this.handleError(err, ResponseMsg.MESSAGES.TEMPLATE);
      }
    );
  }

  /**
   * Create category
   */
  saveCategory() {
    const data = this.form.value;
    const categoryId = data.id;
    if (!!categoryId) {
      this.updateCategory(categoryId, data);
    } else {
      this.createCategory(data);
    }
  }

  /**
   * Create category
   * @param data
   */
  createCategory(data) {
    this.isSubmit = true;
    if (this.form.valid) {
      this.alert('Bạn chắc chắn muốn khởi tạo danh mục sản phẩm dịch vụ này?', ConstantAlertType.BLANK, true, '', ConstantAlertBtn.CONFIRM, () => {
        this.productService.postCreateCategory(data).subscribe(
          (res) => {
            this.categoryResponse = new CategoryResponse(res);
            this.alert('Khởi tạo danh mục thành công!', 'success');
            this.form.reset();
            this.isSubmit = false;
            this.loadPage(1);
          },
          (err) => {
            if (err.error && err.error.message && err.error.message === 'ECT-00002105') {
              this.form.controls.productName.setErrors({ 'unique': true });
            } else if (err.error && err.error.message && err.error.message === 'ECT-00002104') {
              this.form.controls.productId.setErrors({ 'unique': true });
            } else {
              this.handleError(err, ResponseMsg.MESSAGES.TEMPLATE);
            }
          }
        );
      });
    }
  }

  /**
   * Edit category
   * @param category
   */
  editCategory(category) {
    this.category = category;
    this.currentCategory = category;

    this.form.patchValue({
      id: this.category.id,
      productName: category.productName,
      productId: category.productId,
      // productService: category.productService,
      productNote: category.productNote
    })

    // if (category.isUsed) {
    //   this.form.disable();
    // } else {
    //   this.form.enable();
    // }
  }

  /**
   * Update category
   * @param categoryId
   * @param data
   */
  updateCategory(categoryId, data) {
    this.isSubmit = true;
    // this.form.controls.productName.setErrors(null);
    // this.form.controls.productId.setErrors(null);
    console.log(this.form);
    if (this.form.valid) {
      this.alert('Bạn chắc chắn muốn lưu thay đổi cho danh mục sản phẩm dịch vụ này?', ConstantAlertType.BLANK, true, '', ConstantAlertBtn.CONFIRM, () => {
        delete data.id;
        this.productService.putUpdateCategory(categoryId, data).subscribe(
          (res) => {
            this.categoryResponse = new CategoryResponse(res);
            this.alert('Chỉnh sửa danh mục thành công!', 'success');
            this.form.reset();
            this.isSubmit = false;
            this.loadPage(this.page);
          },
          (err) => {
            if (err.error && err.error.message && err.error.message === 'ECT-00002105') {
              this.form.controls.productName.setErrors({ 'unique': true });
            } else if (err.error && err.error.message && err.error.message === 'ECT-00002104') {
              this.form.controls.productId.setErrors({ 'unique': true });
            } else {
              this.handleError(err, ResponseMsg.MESSAGES.TEMPLATE);
            }
          }
        );
      });
    }
  }

  /**
   * Delete category
   * @param category
   */
  deleteCategory(event, category) {
    event.stopPropagation();
    this.alert('Bạn có chắc chắn muốn xoá danh mục?', () => {
      this.productService.deleteCategory(category.id).subscribe(
        (res) => {
          console.log(res);
          this.form.reset();
          this.alert('Xoá danh mục thành công!', ConstantAlertType.SUCCESS, false, '', ConstantAlertBtn.CONFIRM, () => {
            this.loadPage(this.page);
          });
        },
        (err) => {
          this.handleError(err, ResponseMsg.MESSAGES.TEMPLATE);
        }
      );
    });
  }

  /**
   * Upload file
   * @param event
   */
  uploadFile(event) {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      const formData = new FormData();
      formData.append('attachFile', file);
      this.uploader.nativeElement.value = "";
      this.alert("Bạn chắc chắn muốn khởi tạo lô sản phẩm dịch vụ?", () => {
        this.productService.uploadExcel(formData).subscribe(
          (res: any) => {
            console.log(res);
            if (!!res && !!res.body && !!res.body.object) {
              if (res.body.object.error == 0) {
                this.alert(
                  `Thành công! <br /> Success: ${res.body.object.success} <br /> Error: ${res.body.object.error}`,
                  'success'
                );
              } else {
                const errorMessages = res.body.object.result.join('<br />');
                let msg = `Thành công: ${res.body.object.success} <br /> Lỗi: ${res.body.object.error} <br /> Lý do: <br />`;
                res.body.object.result.forEach(item => {
                  msg += `Dòng ${item.row}: `;
                  if (item.msg && item.msg === "ECT-00002104") msg += `Mã sản phẩm dịch vụ đã tồn tại!`;
                  else if (item.msg && item.msg === "ECT-00002105") msg += `Tên SPDV đã tồn tại`;
                  else if (item.msg && item.msg === "ECT-00002106") msg += `Chưa nhập đủ thông tin bắt buộc`;
                  else if (item.msg && item.msg === "ECT-00002107") msg += `Mã sản phẩm dịch vụ chỉ cho phép chữ, số và một số ký tự đặc biệt: ()/\._&`;
                  else if (item.msg !== "") msg += item.msg;
                  else msg += `Lỗi dữ liệu nhập vào`;
                  msg += `<br>`;
                });
                this.alert(msg, 'error');
              }
              this.loadPage(1);
            }
          },
          (err) => {
            console.log(err);
            this.handleError(err, ResponseMsg.MESSAGES.TEMPLATE);
          }
        );
      });
    }
  }

  /**
   * Download sample excel file
   */
  downloadSample() {
    this.productService.downloadSample().subscribe((fileData) => {
      const blob: any = new Blob([fileData], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });
      var url = window.URL.createObjectURL(fileData);
      var anchor = document.createElement('a');
      anchor.download = 'category_sample.xlsx';
      anchor.href = url;
      anchor.click();
    });
  }

  /**
   * Create new
   */
  addNew() {
    this.category = new CategoryItem();
    this.category.isUsed = false;

    this.currentCategory = this.category;
    this.isSubmit = false;
    this.form.reset()
    this.form.enable();
  }

  /**
   * Reset form
   */
  resetForm() {
    this.isSubmit = false;
    if (this.category) {
      this.editCategory(this.category)
    } else {
      this.form.reset();
    }
  }

  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = ConstantAlertBtn.OK, actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = ConstantAlertBtn.CANCEL, actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], 'error');
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, 'error');
    } else {
      this.alert('Lỗi không xác định! Vui lòng thử lại', 'error');
    }
  }

}
