import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ImageProgress } from 'src/app/utils/common/image-progress';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { AasValidateMessage } from 'src/app/utils/common/validate-msg-aas';
import { localize } from 'src/app/utils/localize/localize';
import { ContractObject } from 'src/app/utils/models/aas-models/contract/contract/contract-object.model';
import { DigitalSignatureItem } from 'src/app/utils/models/aas-models/signature/digital-signature/digital-signature-item';
import { DigitalSignatureResponse } from 'src/app/utils/models/aas-models/signature/digital-signature/digital-signature-response';
import { SignBox } from 'src/app/utils/models/aas-models/signbox/sign-box';
import { AasContractService } from 'src/app/utils/services/aas-network/aas-contract.service';
import { EmployeeService } from 'src/app/utils/services/aas-network/employee/employee.service';
import { PartService } from 'src/app/utils/services/aas-network/part/part.service';
import { SignatureService } from 'src/app/utils/services/aas-network/signature.service';
import { AuthService } from 'src/app/utils/services/auth.service';
import { LoadingService } from 'src/app/utils/services/loading.service';
import { ESignOtpComponent } from '../../service-contract-detail/layout/e-sign-otp/e-sign-otp.component';

declare var PDFSign: any;
declare var default_img: any;
declare var vnpt_plugin: any;
declare var PdfSigner: any;
declare var $: any;
@Component({
  selector: 'app-e-sign',
  templateUrl: './e-sign.component.html',
  styleUrls: ['./e-sign.component.scss']
})
export class ESignComponent implements OnInit {
  @ViewChild('eSignOTP') set eSignOTP(content: ESignOtpComponent) {
    if (content) {
      this._eSignOTP = content;
    }
  }
  private _eSignOTP: ESignOtpComponent;

  @Input()
  get contractData() { return this.contract; }
  set contractData(value) { this.contract = new ContractObject(value); }
  private contract: ContractObject = new ContractObject();
  @Input()
  get fileContract() { return this.filePDF; }
  set fileContract(value) { this.filePDF = value; }
  filePDF: File = null;
  @Input()
  get sdt() { return this._sdt; }
  set sdt(value) { this._sdt = value; }
  _sdt: any = "";

  @Output() reload: EventEmitter<any> = new EventEmitter();
  @Output() signStepChanged: EventEmitter<any> = new EventEmitter();
  @Output() haveSessionEsign: EventEmitter<any> = new EventEmitter();
  @Output() changedSignType: EventEmitter<any> = new EventEmitter();
  @Output() returnSignedFile: EventEmitter<any> = new EventEmitter();

  get output(): HTMLInputElement {
    return document.getElementById("output") as HTMLInputElement;
  }
  get filebase(): HTMLInputElement {
    return document.getElementById("fileBase") as HTMLInputElement;
  }
  get filename(): HTMLInputElement {
    return document.getElementById("fileName") as HTMLInputElement;
  }

  localize = localize;

  signType = "E_SIGN";
  signFormSelected: any = "";

  //mark: nhóm chức năng ký điện tử
  formDSign: FormGroup;
  isESignSubmit: boolean = false;
  validMsgESign = AasValidateMessage.E_SIGNATURE_CONFIG;
  imgESignContent: string = '';
  imgEsignConfig: string = "";
  inputImgESignLabel: string = 'ESign_ts_1';
  imgESign: any = null;
  //cert ký điện tử đã chọn
  eCertSelected: DigitalSignatureItem = new DigitalSignatureItem();
  //form confirm top e-sign
  formOTP: FormGroup;
  isOTPSubmit: Boolean = false;
  validMsgOTP = AasValidateMessage.CONFIRM_OTP_E_SIGN;
  idSign: string = '';
  _haveSessionEsign = false;

  currentStepSign = -1;
  pdfSigned = null;

  imgSignServer: File = null;
  imgType = ['image/jpeg', 'image/png', 'image/jpg'];
  signFrame: SignBox[] = [];

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private contractService: AasContractService,
    private partService: PartService,
    private employeeService: EmployeeService,
    private authService: AuthService,
    private signatureService: SignatureService,
    private modalService: NgbModal,
    public myLoader: LoadingService,
    private myAlert: AlertControl,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.makeFormDSign();
  }

  resetSignSession() {
    this.signType = "";
    this.signFormSelected = "";
    this.currentStepSign = 0;
    this._haveSessionEsign = false;
    this.changedSignType.emit(this.currentStepSign);
    this.haveSessionEsign.emit(false);
  }

  makeFormDSign() {
    this.formDSign = this.fb.group({
      imageFile: new FormControl(''),
    })
  }
  get imageFileESign() { return this.formDSign.get('imageFile'); }

  //MARK: CONTROL SIGNBOX
  /**
   * Them 1 chu ky len preview
   */
  addSignBox() {
    PDFSign.addSignBox();
  }

  /**
 * Gan lai buoc cua phien ky
 * @param index 
 */
  setStepSign(index) {
    this.currentStepSign = index;
    if (index === 0) {
      this.signType = "";
      this.changedSignType.emit(this.signType);
    };
    PDFSign.removeAllSignBox();

    this.signStepChanged.emit(this.currentStepSign);
  }

  /**
   * Tro lai buoc ky truoc
   * @param index 
   */
  backStepSign(index) {
    if ((this.currentStepSign === 0 || this.currentStepSign === 1)) {
      this.signType = "";
      this.signFormSelected = "";
      this.changedSignType.emit(this.signType);
      this._haveSessionEsign = false;
      this.haveSessionEsign.emit(false);
    }
    if (index === 2) {
      PDFSign.preview(this.fileContract);
    };
    if (index - 1 >= 0) this.setStepSign(index - 1);
    if (index - 1 == 0) {
      this.signType = "";
      this.changedSignType.emit(this.signType);
    }
  }

  /**
   * Chuyen sang buoc ky tiep theo
   * @param index 
   */
  nextStepSign(index) {
    this.setStepSign(index + 1);
  }

  /**
   * Luu phien them chu ky va chuyen sang buoc tiep theo
   */
   savedSignature() {
    this.nextStepSign(this.currentStepSign);
  }

  //Chon hinh thu ky dien tu
  kyDienTu(signForm) {
    this.signFormSelected = signForm
    this.signType = "E_SIGN";
    this.changedSignType.emit(this.signType);
    this._haveSessionEsign = true;
    this.haveSessionEsign.emit(true);

    //Setup ca thong so default cho ky dien tu
    PDFSign.setStyleSelectedSignBox(6);
    PDFSign.removeAllSignBox();
    this.postRequestElectronicSign(this.contractData.contractId, this.signFormSelected);
  }

  //Submit OTP
  callActionSubmitOTP() {
    this._eSignOTP.submitOTP();
  }

  //Event confirm OTP success
  confirmOTPSuccess($event) {
    //Chuyen man hinh ky dien tu khi OTP thanh cong
    this.nextStepSign(this.currentStepSign);

    if (this.eCertSelected.id === '') {
      this.getListESignature();
    }

    //Setup ca thong so default cho ky dien tu
    PDFSign.setStyleSelectedSignBox(6);
    PDFSign.removeAllSignBox();

    this.showTemplateSignBox(this.signFrame);
  }

  //Ky lai
  resign() {
    if (this.currentStepSign === 2) {
      PDFSign.preview(this.filePDF);

      if (this.signType === "E_SIGN") {
        this.kyDienTu(this.signFormSelected);
        this.currentStepSign = 0;
        return;
      }
    };

    this.backStepSign(this.currentStepSign);
  }

  //lấy thông tin chử ký điện thử
  getListESignature() {
    this.signatureService.getListDigitalSignature().subscribe(res => {
      let digitalSignList = new DigitalSignatureResponse(res).object;
      if (digitalSignList.length > 0) {
        this.selectCert(digitalSignList[0]);
      } else {
        this.selectDefaultEsign();
      }
    }, err => {
      console.log(err);
      this.myAlert.showErrorHandled(err);
    });
  }

  //handle file hình chữ ký điện tử
  handleFileImgESignInput(e) {
    this.imgESign = e.target.files && e.target.files.item(0);
    if (this.imgESign && this.imgESign !== null && this.imgESign !== undefined) {

      this.myAlert.showAlert(this.translate.instant('ESign_ts_2'), "", true, "", this.translate.instant('btn_save'), () => {
        this.inputImgESignLabel = this.imgESign.name;

        if (!this.imgType.includes(this.imgESign.type)) {
          this.imageFileESign.setErrors({ type: true });
          // this.imgESignContent = '';
        } else if (this.imgESign.size > 2000000) {
          this.imageFileESign.setErrors({ size: true });
          ImageProgress.getBase64String(this, this.imgESign, this.handelimgESign);
        } else {
          ImageProgress.getBase64String(this, this.imgESign, this.handelimgESign);

          var self = this;
          var u = URL.createObjectURL(this.imgESign);
          var img = new Image;
          img.src = u;
          img.onload = function () {
            self.resizeImage(img, self.imgESign, 500, 500, 'DSIGN');
          }

          let data = new FormData();
          data.append('image', this.imgESign);
          this.postDigitalSignature(data);

          let formData = new FormData();
          formData.append("signImg1", this.imgESign, "signImg1.png");
          this.postGetImageSign(this.idSign, formData);
        }
      }, "", this.translate.instant('btn_not_now'));
    } else {
      this.imageFileESign.setErrors({ required: true });
      this.imgESignContent = '';
      this.imgEsignConfig = '';
      this.inputImgESignLabel = 'ESign_ts_1';
    }
    // this._inputFile.nativeElement.value = '';
  }

  handelimgESign(str, self) {
    self.imgESignContent = str;
    self.imgEsignConfig = str;
    PDFSign.setImage(str.split(',')[1]);
  }

  //lấy thông tin cấu hình chữ ký điện tử
  selectCert(item: DigitalSignatureItem) {
    this.eCertSelected = item;
    this.imgESignContent = 'data:image/jpg;base64,' + item.base64Image;
    this.imgEsignConfig = 'data:image/jpg;base64,' + item.base64Image;

    let blob: any = ImageProgress.converBase64toBlob(item.base64Image, 'image/jpg');
    blob.lastModifiedDate = new Date();
    blob.name = 'signImg1.jpeg';
    this.imgESign = <File>blob;

    // PDFSign.setImage(item.base64Image);
    let formData = new FormData();
    formData.append("signImg1", blob, "signImg1.png");
    this.postGetImageSign(this.idSign, formData);
  }

  //Set hình ảnh ý default
  selectDefaultEsign() {
    this.imgESignContent = 'data:image/jpg;base64,' + default_img;
    this.imgEsignConfig = 'data:image/jpg;base64,' + default_img;

    let blob: any = ImageProgress.converBase64toBlob(default_img, 'image/jpg');
    blob.lastModifiedDate = new Date();
    blob.name = 'signImg1.jpeg';
    this.imgESign = <File>blob;

    // PDFSign.setImage(item.base64Image);
    let formData = new FormData();
    formData.append("signImg1", blob, "signImg1.png");
    this.postGetImageSign(this.idSign, formData);
  }

  //Gửi yêu cầu ký điện tử
  sendRequestElectronicSign() {
    // this.isOTPSubmit = false;
    let options: Array<any> = PDFSign.getOptions();
    // let listPosition: Array<any> = [];

    // if (!this.checkAllowSign()) {
    //   this.alert('Bạn không thể ký hợp đồng này!', 'error', false);
    //   return;
    // }

    // if (options.length <= 0) {
    //   this.alert('Bạn cần thêm ít nhất 1 chữ ký!', 'error', false);
    //   return;
    // }

    this.actESign();
  }

  //Ký điện tử
  actESign() {
    if (!this.imgESign) {
      this.myAlert.showAlertOnlyNoti(this.translate.instant('ESign_ts_3'), "");
    } else {
      let options: Array<any> = PDFSign.getOptions();
      let multiSign: Array<any> = [];
      let data: FormData = new FormData();

      for (var i = 0; i < options.length; i++) {
        let x = options[i].x + options[i].w;
        let y = options[i].y;
        let x2 = options[i].x;
        let y2 = options[i].y - options[i].h;
        let page = options[i].page;

        multiSign.push({
          pageSign: page - 1,
          bboxSign: [x2, y2, x, y]
        })
      }

      data.append('signImg1', this.imgESign, "signImg1.png");
      data.append('multiSign', JSON.stringify(multiSign));
      data.append('documentHash', "");

      this.postESign(this.idSign, data);
    }

  }

  //Lay hinh anh chu ky se hien thi sau khi ky
  postGetImageSign(idSessionSign: string, data: FormData) {
    this.signatureService.postGetImageSign(idSessionSign, data).subscribe((res: any) => {
      // const blob: any = new Blob([res], { type: 'image/jpeg' });
      // this.imgESign = blob;
      // this.getBase64String(this.imgESign, this.handelimgESign)
      this.imgESignContent = 'data:image/jpg;base64,' + res?.object?.base64Image;
      PDFSign.setImage(res?.object?.base64Image);
      // this.imgESign = this.converBase64toBlob(res?.object?.base64Image, "image/jpg");
    }, err => {
      console.log(err);
      PDFSign.setImageDefault();
    })
  }

  //Gửi yêu cầu ký điện tử
  postRequestElectronicSign(contractId, signForm) {
    this.contractService.postRequestElectronicSign(contractId, signForm).subscribe((res: any) => {
      this.idSign = res && res.object && res.object.idSign ? res.object.idSign : '';
      this.currentStepSign = 0;
      if (this.idSign !== '') {
        if (signForm === "NO_AUTHEN") {
          console.log(signForm, this.currentStepSign)
          this.confirmOTPSuccess(null);
        } else {
          this.isOTPSubmit = false;
          this.signStepChanged.emit(this.currentStepSign);
          setTimeout(() => {
            this._eSignOTP.show();
          }, 2)
        }
      } else {
        this.myAlert.showAlertOnlyNoti(this.translate.instant('ESign_ts_4'), 'error');
      }
    }, err => {
      console.log(err);
      this.myAlert.showErrorHandled(err);
    })
  }

  //Ký điện tử
  postESign(idSign, data: FormData) {
    this.contractService.postESignV2(idSign, data).subscribe((res: any) => {
      PDFSign.removeAllSignBox();
      const blob: any = new Blob([res], { type: 'application/pdf' });
      this.pdfSigned = blob;
      this.returnSignedFile.emit(this.pdfSigned);
      PDFSign.preview(blob);
      PDFSign.removeAllSignBox();
      this.savedSignature();
      this.myAlert.showAlert(this.translate.instant('ESign_ts_5'), ConstantAlertType.SUCCESS, false, '', this.translate.instant('btn_ok'), () => {
      });
    }, err => {
      console.log(err);
      this.myAlert.showErrorHandled(err);
    })
  }

  //Cập nhật ảnh chữ ký điện tử
  postDigitalSignature(data: FormData) {
    this.signatureService.postDigitalSignature(data).subscribe(res => {
      // this.alert('Lưu thông tin thành công!', 'success');
    }, err => {
      console.log(err);
      this.myAlert.showErrorHandled(err);
    })
  }

  //MARK: XU LY ANH
  //convert imgUrl to file object
  urltoFile(url, filename, mimeType) {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  // resize image 
  resizeImage(img, fileImg, max_width, max_height, resize_for = 'SIGN_SERVER') {
    var width = img.width;
    var height = img.height;

    // calculate the width and height, constraining the proportions
    if (width > height) {
      if (width > max_width) {
        //height *= max_width / width;
        height = Math.round(height *= max_width / width);
        width = max_width;
      }
    } else {
      if (height > max_height) {
        //width *= max_height / height;
        width = Math.round(width *= max_height / height);
        height = max_height;
      }
    }
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');
    canvas.width = width;
    canvas.height = height;
    ctx.drawImage(img, 0, 0, width, height);
    var self = this;
    let imgURL = ctx.canvas.toDataURL(fileImg.type, 1);
    this.urltoFile(imgURL, fileImg.name, fileImg.type).then(function (file) {
      if (resize_for === 'SIGN_SERVER') {
        self.imgSignServer = file;
      } else {
        self.imgESign = file;
      }

    });
    canvas.remove();
  }

  /**
   * Hien thi goi ky signbox
   * @param signFrame 
   */
  showTemplateSignBox(signFrame = []) {
    console.log("kyDienTu ", signFrame);
    if (signFrame.length > 0) {
      signFrame.forEach((item: SignBox) => {
        PDFSign.addSignBox(item, this.contract.allowAddSignBox);
      })
      
      setTimeout(() => {
        PDFSign.reloadTable(null, this.contract.allowAddSignBox);
      }, 100);
    }
  }
}
