export class ApiKeyItem {
  licenseId: string = '';
  partyType: string = '';
  tenKhachHang: string = '';
  packageName: string = '';
  packageType: string = '';
  issueDate: string = '';
  expirationDate: string = '';
  status: string = '';
  created: string = '';
  modified: string = '';
  partyId: string = '';
  packInfoId: string = '';
  email: string = '';
  username: string = '';

  constructor(obj = null) {
      obj = obj || {};
      this.licenseId = obj.licenseId || '';
      this.partyType = obj.partyType || '';
      this.tenKhachHang = obj.tenKhachHang || '';
      this.packageName = obj.packageName || '';
      this.packageType = obj.packageType || '';
      this.issueDate = obj.issueDate || '';
      this.expirationDate = obj.expirationDate || '';
      this.status = obj.status || '';
      this.created = obj.created || '';
      this.modified = obj.modified || '';
      this.partyId = obj.partyId || '';
      this.packInfoId = obj.packInfoId || '';
      this.email = obj.email || '';
      this.username = obj.userName || '';
  }
}