<h5 class="ec-tab-content-title mt-3">{{ 'ListDocument_title' | translate }}</h5>

<div *ngFor="let document of documents">
    <div class="document mt-4">
        <div class="document-header" [ngClass]="{'active': selectedDocument == document.documentId}">
            <div class="row">
                <div class="col" (click)="clickDocument(document)">
                    <div class="document-title px-3 pt-2 pb-2">
                        <label class="document-name" style="cursor: pointer;">{{ document?.documentName }}</label>
                        <div class="d-flex">
                            <span *ngIf="document.mimeType == null" class="material-icons-outlined mr-1">
                                link
                            </span>
                            <span *ngIf="document.mimeType && document.mimeType.length > 0" class="material-icons-outlined mr-1">
                                attach_file
                            </span>
                            <span class="file-name" [class.link-name]="document.mimeType == null">{{ document?.originalName }}</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-auto ml-auto">
                    <div class="menu-link mt-3">
                        <div class="dropdown">
                            <a class="btn btn-dropdown dropdown-toggle btnMenuLink" href="#" role="button"
                                id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <em class="material-icons">more_vert</em>
                            </a>

                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                                <!-- [routerLink]="['/console/licenses-manager/detail/', item.licenseId]" -->
                                <a class="dropdown-item nav-material d-inline-flex" style="cursor: pointer;"
                                    (click)="clickDocument(document)">
                                    <em class="material-icons">info</em>
                                    {{ 'ListDocument_details' | translate }}</a>
                                <a *ngIf="isOwnerParty || checkDocCreator(document?.userId)" class="dropdown-item nav-material d-inline-flex" style="cursor: pointer;"
                                    (click)="goToEditDocument(document)">
                                    <em class="material-icons">edit</em>
                                    {{ 'ListDocument_updating' | translate }}</a>
                                <a *ngIf="isOwnerParty || checkDocCreator(document?.userId)" class="dropdown-item nav-material d-inline-flex" style="cursor: pointer;"
                                    (click)="deleteDocument(document?.documentId)">
                                    <em class="material-icons">delete</em>
                                    {{ 'ListDocument_removing' | translate }}</a>
                                <a *ngIf="document.mimeType && document.mimeType.length > 0" class="dropdown-item nav-material d-inline-flex" style="cursor: pointer;"
                                    (click)="downloadDocument(document?.documentId, document?.originalName)">
                                    <em class="material-icons">get_app</em>
                                    {{ 'ListDocument_downloading' | translate }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <hr class="mb-0 mt-0" style="color: #A0AEC0;">
        <div class="document-infor">
            <div class="document-type px-3 pt-2">
                <div class="row">
                    <div class="col-md-4">
                        <label>{{ 'ListDocument_type' | translate }}</label>
                    </div>
                    <div class="col-md-8">
                        <span>{{ getDocumentTypeLabel(document.documentType) | translate }}</span>
                    </div>
                </div>
            </div>
            <div class="document-note px-3 pt-2 note-background">
                <div class="row">
                    <div class="col-md-4">
                        <label>{{ 'ListDocument_note' | translate }}</label>
                    </div>
                    <div class="col-md-8">
                        <span>{{ document.creationNote }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>