<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-6">
                <h1 class="m-0 text-dark"><label>{{ 'ChangePassword_title' | translate }}</label></h1>
            </div>
            <div class="col-sm-6">
                <button class="btn btn-primary float-sm-right d-inline-flex" [disabled]="checkEdited" (click)="save()"
                    type="submit"><em class="material-icons mr-2">save</em>
                    {{ 'btn_save' | translate }}</button>
            </div>
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>

<section class="content">
    <div class="container-fluid">
        <div class="bg-white p-3">
            <div>
                <form [formGroup]="form">
                    <div class="form-group">
                        <div class="row">
                            <div class="col-md-6">
                                <label>{{ 'ChangePassword_old' | translate }} <span class="text-danger">*</span></label>
                            </div>
                            <div *ngIf="false" class="col-sm-6">
                                <a class="linkForgotPassword float-sm-right text-primary text-decoration-underline cursor-pointer" routerLinkActive="router-link-active"
                                    (click)="forgotPassword()">{{ 'ChangePassword_forgot' | translate }}</a>
                            </div>
                        </div>
                        <div class="input-group">
                            <input appInputPassword class="form-control form-control-lg" type="password"
                                placeholder="{{ 'ChangePassword_input_old' | translate }}" formControlName="passwordOld">
                            <!-- <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="validateMsg.password"
                            [control]="form.controls.passwordOld" [detail]="{ name: 'password' }">
                        </app-show-validate-errors> -->
                        </div>
                        <p class="error text-danger" *ngIf="isFailed.wrongPasswordOld && form.valid">{{ 'ChangePassword_wrong' | translate }}</p>
                    </div>
                    <div class="form-group">
                        <div>
                            <label>{{ 'ChangePassword_new' | translate }} <span class="text-danger">*</span></label>
                        </div>
                        <div class="input-group">
                            <input appInputPassword class="form-control form-control-lg" type="password"
                                placeholder="{{ 'ChangePassword_input_new' | translate }}" formControlName="password">
                        </div>
                        <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="validateMsg.password"
                                [control]="form.controls.password" [detail]="{ name: 'password' }">
                            </app-show-validate-errors>
                            <p class="error text-danger" *ngIf="isFailed.sameAsPasswordOld && form.valid">{{ 'ChangePassword_not_same' | translate }}</p>
                    </div>
                    <div class="form-group">
                        <div>
                            <label>{{ 'ChangePassword_confirm_new' | translate }} <span class="text-danger">*</span></label>
                        </div>
                        <div class="input-group">
                            <input appInputPassword class="form-control form-control-lg" type="password"
                                placeholder="{{ 'ChangePassword_input_confirm' | translate }}" formControlName="passwordConfirm">
                            
                            <!-- <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="validateMsg.password"
                            [control]="form.controls.passwordConfirm" [detail]="{ name: 'password' }">
                        </app-show-validate-errors> -->
                        </div>
                        <p class="error text-danger" *ngIf="isFailed.passwordConfirmNotMatch && form.valid">{{ 'ChangePassword_not_match' | translate }}</p>
                    </div>
                </form>
                <p class="note">{{ 'ChangePassword_note' | translate }}</p>
            </div>

        </div>
    </div>
</section>