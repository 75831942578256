<div class="wrapper">
    <!-- <app-mini-spinner *ngIf="loadingService.isVisible() | async"></app-mini-spinner> -->
    <div *ngIf="partyInfo && !partyInfo.contractVipId" class="row">
        <div class="col-md-6">
            <div *ngIf="partyInfo && partyInfo.verificationStatus === 'WAIT'" class="card shadow-none position-relative">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-auto align-self-end">
                            <label class="text-lg">Kết quả phê duyệt</label>
                        </div>
                        <!-- <div class="col-md-auto ml-auto">
                            <button (click)="actBtnCancel($event)" class="btn btn-outline-primary d-inline-flex mr-4"><span
                                    class="material-icons mr-2">close</span> Hủy</button>
                            <button (click)="submit($event)" class="btn btn-primary d-inline-flex"><span
                                    class="material-icons mr-2">save</span> Lưu</button>
                        </div> -->
                    </div>
                    <hr class="my-3">
            
                    <p class="mb-4">Yêu cầu phê duyệt của bạn đã được gửi thành công. Bạn sẽ nhận được thông báo ngay khi yêu cầu này được xử lý!</p>
    
                    <!-- <p class="mb-4">Tài khoản của bạn đã bị từ chối phê duyệt. Vui lòng cập nhật lại thông tin.</p> -->
                    <!-- <div  class="row">
                        <div class="col-md-12">
                            <table>
                                <tbody>
                                    <tr>
                                        <td><span class="text-secondary">Người từ chối</span></td>
                                        <td><span class="ml-2">local_ttkd_am@vnpt.vn</span></td>
                                    </tr>
                                    <tr>
                                        <td><span class="text-secondary">Ngày từ chối</span></td>
                                        <td><span class="ml-2">22/05/2020</span></td>
                                    </tr>
                                    <tr>
                                        <td><span class="text-secondary">Lý do từ chối</span></td>
                                        <td><span class="ml-2">Giấy phép kinh doanh hết hạn sử dụng</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div> -->
                    <!-- <app-mini-spinner *ngIf="loadingService.isVisible() | async"></app-mini-spinner> -->
                </div>
            </div>
    
            <div *ngIf="partyInfo && partyInfo.verificationStatus === 'REJECT_VIP'" class="card shadow-none position-relative">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-auto align-self-end">
                            <label class="text-lg">Kết quả phê duyệt</label>
                        </div>
                    </div>
                    <hr class="my-3">
        
                    <p class="mb-4">Tài khoản của bạn đã bị từ chối phê duyệt. Vui lòng cập nhật lại thông tin.</p>
                    <div  class="row">
                        <div class="col-md-12">
                            <table>
                                <tbody>
                                    <tr>
                                        <td><span class="text-secondary">Người từ chối</span></td>
                                        <td><span class="ml-2">{{ partyInfo.approverName }}</span></td>
                                    </tr>
                                    <tr>
                                        <td><span class="text-secondary">Ngày từ chối</span></td>
                                        <td><span class="ml-2">{{ partyInfo.approveDate | date:'dd/MM/yyyy' }}</span></td>
                                    </tr>
                                    <tr>
                                        <td><span class="text-secondary">Lý do từ chối</span></td>
                                        <td><span class="ml-2">{{ partyInfo.rejectReason }}</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <!-- <app-mini-spinner *ngIf="loadingService.isVisible() | async"></app-mini-spinner> -->
                </div>
            </div>
        </div>
    </div>

    <div class="content-step" *ngIf="partyInfo && partyInfo.contractVipId">
        <app-step-four-contract-detail [contractId]="partyInfo.contractVipId"></app-step-four-contract-detail>
    </div>
</div>
