import { InfraConfig } from "./infra-config";

export class ServiceCatalog {
    created: string = "";
    infrastructures: InfraConfig[] = [];
    modified: string = "";
    name: string = "";
    privileges = [];
    roleIdstring = "";
    serviceInfoId: string = "";
    status: string = "";
    typeService: string = "";
    description: string = "";

    constructor(d = null) {
        d = d || {};
        this.created = d.created || "";
        if (d.infrastructures && d.infrastructures instanceof Array) {
            d.infrastructures.forEach(x => {
                this.infrastructures.push(new InfraConfig(x));
            })
        }
        this.modified  = d.modified || "";
        this.name = d.name || "";
        this.privileges = d.privileges || [];
        this.roleIdstring = d.roleIdstring || "";
        this.status = d.status || "";
        this.typeService = d.typeService || "";
        this.serviceInfoId = d.serviceInfoId|| "";
        this.description = d.description || "";
    }
}