export class AccountItem {
    uuid: string;
    companyName: string;
    taxCode: string;
    url: string;
    account: string;
    companyTaxCode: string;
    createdOn: string;
    password: string;
    serviceAccount: string;
    servicePassword: string;
    pattern: string;
    serial: string;
    constructor(obj = null) {
        this.uuid = obj.uuid || '';
        this.companyName = obj.companyName || '';
        this.taxCode = obj.taxCode || '';
        this.url =  obj.url;
        this.account = obj.account || '';
        this.password = obj.password || '';
        this.serviceAccount = obj.serviceAccount || '';
        this.servicePassword =  obj.servicePassword;
        this.pattern = obj.pattern || '';
        this.serial = obj.serial || '';
        this.companyTaxCode = obj.companyTaxCode || '';
        this.createdOn = obj.createdOn || '';
    }

}