import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DashboardService } from 'src/app/utils/services/aas-network/dashboard/dashboard.service';

@Component({
  selector: 'app-cwv-contract-admin',
  templateUrl: './cwv-contract-admin.component.html',
  styleUrls: ['./cwv-contract-admin.component.scss']
})
export class CwvContractAdminComponent implements OnInit {

  
  /**
   * This component serve for 3 case:
   * - Cancelled contract
   * - Waiting contract
   * - Valid contract
   */

   @Input('type') type: number;

  constructor(
    private translate: TranslateService,
    private dashboardService: DashboardService

  ) { }

  label: string = null;
  contracts=0;
  rating=0
  ngOnInit(): void {
    if (this.type) {
      this.label = this.getLabel(this.type);
      this.initData(this.type)
    }
  }

  getLabel(type: number) {
    switch (type) {
      case 1:
        return "CwvContract_label_1";
      case 2:
        return "CwvContract_label_2";
      case 3:
        return "CwvContract_label_3";
    }
  }
  initData(type){
    this.dashboardService.getContractByType(type).subscribe((res:any)=>{
      if(res && res.object instanceof Array){
        this.contracts= res.object[0].contracts;
        this.rating= res.object[0].rating;
      }
    }, error=>{
      console.log(error);
      
    })
  }
}
