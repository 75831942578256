import { Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { ConstantAlertBtn, ConstantAlertMsg } from 'src/app/utils/common/constant-alert-msg';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ListPartnerVerification } from 'src/app/utils/models/aas-models/partner/partner-verification/list-partner-verification/list-partner-verification';
import { ListPartnerConsumer } from 'src/app/utils/models/aas-models/partner/partner-verification/list-partner-verification/list-partner-consumer';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { PartnerService } from 'src/app/utils/services/aas-network/partner.service';
import { AuthService } from 'src/app/utils/services/auth.service';
import { PartnerConsumer } from 'src/app/utils/models/aas-models/partner/partner-verification/partner-consumer/partner-consumer';
import { ValidateMessage } from 'src/app/utils/common/validate-msg-aas';
import { PARTNER_DENY_FORM } from 'src/app/utils/common/constant-vaildate-msg';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { VideoCallService } from 'src/app/utils/services/aas-network/video-call.service';
import { Constant } from 'src/app/utils/common/constant_videocall';
import { FirebaseService } from 'src/app/utils/services/aas-network/firebase/firebase.service';
import { UserInformations } from 'src/app/utils/models/aas-models/info-party/info-party-object';
import { UserDetailObject } from 'src/app/utils/models/aas-models/list-register-user/user-detail-object.model';
import { AasContractService } from 'src/app/utils/services/aas-network/aas-contract.service';
import { EncodeService } from 'src/app/utils/common/encode-service';
import { ContractFileInfo } from 'src/app/utils/models/aas-models/contract/contract-file/contract-file.model';
import { ContractEncode } from 'src/app/utils/common/contract-encode';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { localize } from 'src/app/utils/localize/localize';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-wait-consumer-partner',
  templateUrl: './wait-consumer-partner.component.html',
  styleUrls: ['./wait-consumer-partner.component.scss']
})
export class WaitConsumerPartnerComponent implements OnInit {
  @ViewChild('rejectContent') public rejectContent: TemplateRef<any>;
  @ViewChild('calling') public calling: TemplateRef<any>;
  @ViewChild('approve') public approve: TemplateRef<any>;

  @Output('reloadApprove') reloadApprove: EventEmitter<any> = new EventEmitter<any>();
  @Output('reloadReject') reloadReject: EventEmitter<any> = new EventEmitter<any>();
  
  default_ava_img: string = 'assets/img/services/user-incall-ava-default.png';
  ava_img: string = '';
  
  searchForm: FormGroup;
  denyForm: FormGroup;
  isSubmitDenyForm: boolean = false;
  validateDenyMsg: any = PARTNER_DENY_FORM;

  verificationStatus: string = 'WAIT';
  // searchString: string = '';
  page: number = 1;
  maxSize: number = 5;
  userType: string = 'CONSUMER';
  totalElement: number = 0;

  partners: ListPartnerConsumer[] = [];
  partner: PartnerConsumer = new PartnerConsumer();
  partnerVerificationId: string = '';

  imgFront: any = '';
  imgBack: any = '';
  imgFace: any = '';

  modalReject: any = null;
  pendingCallModal: any = null;
  
  personalInfo: string = '';
  roomId: string = '';

  keySend: string = '';
  contractFileInfo: ContractFileInfo = new ContractFileInfo();
  contractEncode = new ContractEncode();


  constructor(
    private router: Router,
    private fb: FormBuilder,
    private partnerService: PartnerService,
    private auth: AuthService,
    private aasAuth: AasAuthService,
    private videoCallService: VideoCallService,
    private firebaseService: FirebaseService,
    private modalService: NgbModal,
    private contractService: AasContractService,
    private alert: AlertControl,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.makeFormSearch();
    this.makeDenyForm();
    this.loadPage(1);
  }

  /**
   * Khoi tao form tim kiem
   */
  makeFormSearch() {
    this.searchForm = this.fb.group({
      searchInput: ['']
    });
  }

  /**
   * Khoi tao form tu choi
   */
  makeDenyForm() {
    this.denyForm = new FormGroup({
        denyReason: new FormControl(null, [Validators.required]),
    });
  }

  get searchString() {
    return this.searchForm.value.searchInput;
  }
  

  /**
   * Tim kiem
   * @param value 
   */
  searchData(value) {
    this.loadPage(1);
  }

  /**
   * Tai trang
   * @param page 
   */
  loadPage(page) {
    this.page = page;
    this.getListPartnerVerification(this.verificationStatus, this.searchString, this.maxSize, page, this.userType);
  }

  reload() {
    this.loadPage(this.page);
    this.partner = new PartnerConsumer();
    this.partnerVerificationId = '';
  }

  /**
   * Xem chi tiet
   * @param id 
   */
  selectPartner(id) {
    this.partnerVerificationId = id;
    this.getDetailConsumerPartner(id);
  }

  /**
   * Mo popup tu choi phe duyet
   * @param content 
   */
  openRejectPopup(content) {
    this.isSubmitDenyForm = false;
    this.denyForm.reset();
    this.modalReject = this.modalService
        .open(content, { backdrop: "static" });
  }

  /**
   * Tu choi
   * @param id 
   */
  rejectRegistration(id) {
    this.isSubmitDenyForm = true;
    if (this.denyForm.valid) {
      this.alert.showAlert(this.translate.instant('WaitConsumerPartner_note_5'), ConstantAlertType.BLANK, true, '', this.translate.instant('btn_confirm'), () => {
        let param = {
          reason : this.denyForm.value.denyReason
        }
        if(param.reason.trim() == ""){
          this.alert.showAlertOnlyNoti("Vui lòng nhập lý do từ chối!",ConstantAlertType.ERROR,'', 'Đóng',()=>{})
        }else{
          this.postRejectPartner(id, param);
        }
        // if(this.denyForm.value.denyReason.trim() == ""){
        //   this.alert.showAlertOnlyNoti("Vui lòng nhập lý do từ chối!",ConstantAlertType.ERROR,'', 'Đóng',()=>{})
        // }
      })
    }
  }

  /**
   * Phe duyet
   * @param id 
   */
  approvePartner(id) {
    this.alert.showAlert(this.translate.instant('WaitConsumerPartner_note_6'), ConstantAlertType.BLANK, true, '', this.translate.instant('btn_confirm'), () => {
      this.postApprovePartner(id);
    });
  }

    /**
   * Bắt sự kiện cuộc gọi từ firebase
   */
  handleMessagesFromVideoCall() {
    this.firebaseService.currentMessage.subscribe((message) => {
      console.log('waiting screen...')
      if (message) {
        switch (message["data"]["title"]) {
          case Constant.ACCEPTED:
            console.log("waiting screen...ACCEPTED");
            this.pendingCallModal && this.pendingCallModal.close()

            localStorage.setItem(
              Constant.CALLER,
              JSON.stringify(this.auth.username)
            );
            localStorage.setItem(
              Constant.ROOM_INFO,
              JSON.stringify({
                roomId: message.data.roomId,
                token: message.data.token,
                domain: message.data.domain,
              })
            );
          case Constant.PENDING:
            return;
          case Constant.REJECTED:
            console.log("waiting screen...REJECTED");
            this.pendingCallModal && this.pendingCallModal.close()
            return;
          case Constant.FINISHED:
            console.log("waiting screen...FINISHED");
            this.pendingCallModal && this.pendingCallModal.close()
            return;
          case Constant.TIMEOUT:
            console.log("waiting screen...timeout");
            localStorage.removeItem(Constant.CONSUMER);
            this.pendingCallModal && this.pendingCallModal.close()
            return;
          default:
            return;
        }
      }
    });
  }

  /**
   * Ket thuc cuoc goi
   */
  actBtnEndCall() {
    this.pendingCallModal && this.pendingCallModal.close();

    const param = {
      "deviceId": localStorage.getItem(Constant.UUID),
      "idgTokenId": Constant.TOKEN_ID,
      "roomId": this.roomId
    }
    this.endCall(param);
  }

  /**
   * Goi video
   * @param uuidCustomer 
   */
  callVideo(uuidCustomer) {
    return;
    this.getUserInfo(uuidCustomer);
    // this.openWindowCall();
    // console.log('customerId', uuidCustomer);
    // // this.openWaitingCallPopup();
    // this.getDeviceIdByCustomerId(uuidCustomer);
  }

  /**
   * Handel goi video
   */
  handleCallVideo() {
    const deviceIds = this.personalInfo['DeviceIdSet'].split(';');
    console.log('deviceIds', deviceIds);
    this.createCall(deviceIds);
  }

  /**
   * 
   * @param data 
   */
  acceptCall(data) {
    this.videoCallService.acceptCall(data).subscribe(
      (res) => {
        console.log('accept', res);
      },
      (error) => {
        this.alert.showErrorHandled(error);
      }
    );
  }

  /**
   * Mo popup dang goi
   */
  openWaitingCallPopup() {
    this.pendingCallModal = this.modalService.open(this.calling, { backdrop: 'static', size: 'sm' });
    this.pendingCallModal.result.then(
      (result) => {
        // this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  // //MARK: Popup thông báo
  // alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = ConstantAlertBtn.OK, actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = ConstantAlertBtn.CANCEL, actBtn1 = () => { }) {
  //   const modalRef = this.modalService.open(AlertComponent);
  //   modalRef.componentInstance.message = msg;
  //   modalRef.componentInstance.typeAlert = type;
  //   modalRef.componentInstance.twoBtn = twoBtn;
  //   modalRef.componentInstance.iconBtn1 = iconBtn1;
  //   modalRef.componentInstance.iconBtn2 = iconBtn2;
  //   modalRef.componentInstance.titleButton1 = titleBtn1;
  //   modalRef.componentInstance.titleButton2 = titleBtn2;
  //   modalRef.componentInstance.callback1 = actBtn1;
  //   modalRef.componentInstance.callback2 = actBtn2;
  // }

  // //MARK: Handle Error
  // handleError(err, errorMsg) {
  //   if (err.error && err.error.message && errorMsg[err.error.message]) {
  //     this.alert(errorMsg[err.error.message], ConstantAlertType.ERROR);
  //   } else if (err.error && err.error.error) {
  //     this.alert(err.error.error, ConstantAlertType.ERROR);
  //   } else {
  //     this.alert(ConstantAlertMsg.DEFAULT_ERROR_REQUEST, ConstantAlertType.ERROR);
  //   }
  // }

  //MARK: NETWORKING
  /**
   * Lay danh sach doi tac cho xac minh
   * @param verificationStatus 
   * @param searchString 
   * @param maxSize 
   * @param page 
   * @param userType 
   */
  getListPartnerVerification(verificationStatus, searchString = '', maxSize = 10, page = 10, userType = 'CONSUMER') {
    this.partnerService.getListPartnerVerification(verificationStatus, searchString, maxSize, page, userType).subscribe((res: any) => {
      let result = new ListPartnerVerification(res.object || {});
      this.totalElement = result.totalElement;
      this.partners = result.data;
      console.log(this.partners);
      
      if (this.partners.length > 0) this.selectPartner(this.partners[0].partnerVerificationId);
    }, err => {

    });
  }

  /**
   * Lay thong tin chi tiet doi tac
   * @param id 
   */
  getDetailConsumerPartner(id) {
    this.partnerService.getDetailConsumerPartner(id).subscribe((res: any) => {
      
      this.partner = new PartnerConsumer(res.object || {});

      this.getImage(this.partner.partnerId, 'EKYC_CHANDUNG');
      this.getImage(this.partner.partnerId, 'EKYC_MATTRUOC');
      this.getImage(this.partner.partnerId, 'EKYC_MATSAU');
    }, err => {

    })
  }

  /**
   * Xac nhan doi tac
   * @param id 
   */
  postApprovePartner(id) {
    this.partnerService.postApprovePartner(id).subscribe((res: any) => {
      this.reloadApprove.emit({});
      this.reload();
    }, err => {
      this.alert.showErrorHandled(err, ResponseMsg.MESSAGES.MANAGEMENT_SERVICE);
    })
  }

  /**
   * Tu choi doi tac
   * @param id 
   */
  postRejectPartner(id, param) {
    this.partnerService.postRejectPartner(id, param).subscribe((res: any) => {
      this.modalReject && this.modalReject.close();
      this.reloadReject.emit();
      this.reload();
    }, err => {
      this.alert.showErrorHandled(err, ResponseMsg.MESSAGES.MANAGEMENT_SERVICE);
    })
  }

    /**
   * Lay danh sach cac thiet bi da dang ki
   * @param uuidCustomer 
   */
  getDeviceIdByCustomerId(uuidCustomer) {
    // this.openWindowCall();
    // console.log('customerId', uuidCustomer);
    // this.openWaitingCallPopup();
    this.videoCallService.getDeviceIdByCustomerId(uuidCustomer)
      .subscribe((response: any) => {
        // console.log('aa', response);
        if (response['message'] === 'IDG-00000000') {
          if (!response['object']['DeviceIdSet']) {
            this.alert.showAlertOnlyNoti(
              this.translate.instant('WaitConsumerPartner_note_7'),
              'error'
            );
            return;
          }
          this.personalInfo = response['object'];
          this.handleCallVideo();
        }
      }, (error) => {
        this.alert.showErrorHandled(error);
      });
  }

  /**
   * Tao cuoc goi
   * @param deviceIds 
   */
  createCall(deviceIds) {
    this.videoCallService.createCall(this.partner, deviceIds, this.auth.username).subscribe((response: any) => {
          // console.log('create call', response);
          if (response) {
            if (response.object && response.object.roomId) {
              this.roomId = response.object.roomId;
            }
          }
          this.openWaitingCallPopup();
          // this.reponseCreateCall = response;
        },
        (error) => {
          this.alert.showErrorHandled(error);
          // this.modal_confirm = false;
        }
      );
  }

  /**
   * Kết thúc cuộc gọi
   * @param data 
   */
  endCall(data) {
    this.videoCallService.endCall(data).subscribe(res => {

    }, err => {
      this.alert.showErrorHandled(err);
    })
  }

  /**
   * Download Hinh anh
   * @param id 
   * @param type 
   */
  getImage(id, type) {
    this.partnerService.downloadImage(id, type).subscribe(
        (res) => {
            // console.log('image', res);
            if (res) {
                const blob: any = new Blob([res], {
                    type: 'image/png',
                });
                var reader = new FileReader();
                let base64data: any;
                reader.readAsDataURL(blob);
                reader.onloadend = () => {
                    if (type === 'EKYC_CHANDUNG') {
                        this.imgFace = reader.result;
                        this.ava_img = this.imgFace || this.default_ava_img;
                        localStorage.setItem(Constant.IMAGE_FACE, this.imgFace);
                    } else if (type === 'EKYC_MATTRUOC') {
                        this.imgFront = reader.result;
                        // this.imageUrls.push(this.imageFirst);
                    } else {
                        this.imgBack = reader.result;
                        // this.imageUrls.push(this.imageSecond);
                    }
                };
            }
        },
        (err) => {
            if (type === 'EKYC_CHANDUNG') {
                this.imgFace = null;
            } else if (type === 'EKYC_MATTRUOC') {
                this.imgFront = null;
            } else {
                this.imgBack = null;
            }
        },
        () => { }
    );
  }

  /**
   * Lay thong tin user
   * @param userId 
   */
  getUserInfo(userId) {
    this.aasAuth.getUserInfo(userId).subscribe((res: any) => {
      var userinfo = new UserDetailObject(res.object || {});

      // this.openWindowCall();
      // console.log('customerId', userinfo.customerId);
      // this.openWaitingCallPopup();
      this.getDeviceIdByCustomerId(userinfo.customerId);

    }, err => {

    }); 
  }

  // Download Contract
  hasDecode(contractId) {
    return this.contractEncode.hasDecode(contractId);
  } 

  // getFileContract(id, status) {
  //   if (status === 'LC_CONTRACT_VALID') {
  //     if (this.hasDecode(id)) {
  //       this.getContractFileInfoDownload(id, 'CONTRACT', id);
  //     } else {
  //       var msg = `File hợp đồng tải xuống sẽ được mã hóa và chỉ có thể xem bằng ứng dụng eContract.<br><span class="font-italic text-secondary">(Để tải file hợp đồng định dạng PDF, vui lòng thực hiện giải mã hợp đồng trước khi tải)</span>`
  //       this.myAlert.showAlert(msg, ConstantAlertType.BLANK, true, "", "Tải hợp đồng", () => {
  //         this.downloadEnCodeFile(id);
  //       }, "", localize.btn_cancel, null, "Xác nhận tải hợp đồng");
  //     }
  //   } else {
  //     this.getContractFileInfoDownload(id, 'DRAFT', id);
  //   }
  // }
  
  detailContract(contractId) {
    this.router.navigate([`/app/console/contract/${contractId}`]);
  }

  downloadEnCodeFile(contractId) {
    this.contractService.downloadEncodeContract(contractId).subscribe((res) => {
      const blob: any = new Blob([res], { type: 'application/pdf' });
      var url = window.URL.createObjectURL(blob);
      var anchor = document.createElement("a");
      anchor.download = `${contractId}.pdf`;
      anchor.href = url;
      anchor.click();
    }, err => {
      // console.log(err);
      this.alert.showErrorHandled(err);
    })
  }
   /**
 * Lay thogn tin file hop dong
 * @param id 
 * @param type 
 * @param fileKey 
 */
    getContractFileInfoDownload(id, type, fileKey) {
      this.contractService.getContractFileInfo(id, type, fileKey).subscribe((res: any) => {
        this.contractFileInfo = new ContractFileInfo(res.object);
        this.keySend = this.contractFileInfo.keySend;
  
        this.downloadContractFileByUrlForSave(this.contractFileInfo.url, type, this.keySend, id);
      }, err => {
        // console.log(err);
        this.alert.showErrorHandled(err);
      })
    }

  /**
   * Tai file hop dong tu url
   * @param url 
   */
   downloadContractFileByUrlForSave(url, type, keySend, id) {
    this.contractService.downloadContractFileByUrl(url).subscribe((res: any) => {
      const blob: any = new Blob([res]);

      if (keySend) {
        //Neu ton tai keySend
        //Tien hanh giai ma
        let secretKey = EncodeService.rsa.decrypt(this.auth.key, this.keySend);
        this.loadFileForSave(secretKey, blob, id);
      } else {
        //Neu khong ton tai keySend
        //Tien hanh luu file file PDF
        var url = window.URL.createObjectURL(blob);
        var anchor = document.createElement("a");
        anchor.download = `${id}.pdf`;
        anchor.href = url;
        anchor.click();
      }
    }, err => {
      // console.log(err);
      this.alert.showErrorHandled(err);
    })
  }

   /**
 * Giai ma file
 * @param secretKey 
 * @param fileEncoded 
 */
    loadFileForSave(secretKey, fileEncoded, id) {
      var self = this;
      var reader = new FileReader();
      reader.onload = function () {
        var result: any = reader.result;
        var _base64DecodeRes = Buffer.from(result, 'base64');
        var iv = _base64DecodeRes.slice(0, 16);
        var dataEncrypt = _base64DecodeRes.slice(16, _base64DecodeRes.byteLength);
  
        // console.log(result, result.slice(0, 16));
        var typedArray = EncodeService.aes.decrypt(dataEncrypt, secretKey, iv);
  
        const bufferpdf = Buffer.from(typedArray);
        const blob: any = new Blob([bufferpdf], { type: 'application/pdf' });
        var url = window.URL.createObjectURL(blob);
        var anchor = document.createElement("a");
        anchor.download = `${id}.pdf`;
        anchor.href = url;
        anchor.click();
      }
      reader.readAsText(fileEncoded);
    }
}
