import { ConfigTemplateManagerItem } from '../models/template/config-template/config-template-manager-item.model';
import { ThemeService } from 'ng2-charts';
import { ɵConsole } from '@angular/core';
import { TemplateVar } from '../models/aas-models/template/template-var';

export class TemplateManager {
    public static shared = new TemplateManager();

    configList: TemplateVar[] = [];
    tableList: string[] = [];
    tableObjectList: ListTable = {};
    textBoxList: ConfigItem[] = [];
    checkBoxes: string[] = [];
    checkBoxList: ListCheckBox = {};
    configParam: any = {};

    tableListNoFilled: string[] = [];
    tableObjectListNoFilled: ListTable = {};
    textBoxListNoFilled: ConfigItem[] = [];
    checkBoxesNoFilled: string[] = [];
    checkBoxListNoFilled: ListCheckBox = {};

    constructor() {
        console.log('ok');
    }

    reset() {
        this.configList = [];
        this.tableList = [];
        this.tableObjectList = {};
        this.textBoxList = [];
        this.checkBoxList = {};
        this.configParam = {};

        this.tableListNoFilled = [];
        this.tableObjectListNoFilled = {};
        this.textBoxListNoFilled = [];
        this.checkBoxListNoFilled = {};
    }

    getTextBoxList() {
        this.textBoxList = [];
        this.configList.forEach((item) => {
            if (item.isTextBox) {
                var tmp = {
                    'key': item.name,
                    'label': item.label,
                    'nameMap': item.nameMap,
                    'type': item.type
                };
                this.textBoxList.push(new ConfigItem(tmp));
            }
        })
    }

    getCheckBoxList() {
        this.checkBoxList = {};
        this.checkBoxes = [];
        this.configList.forEach((item) => {
            if (item.type === 'checkbox') {
                var cbName = item.name;
                if (this.checkBoxList[cbName] === null || this.checkBoxList[cbName] === undefined) {
                    this.checkBoxList[cbName] = [];
                    this.checkBoxes.push(cbName);
                }

                item.listVar.forEach(res => {
                    var tmp = {
                        'key': res.name,
                        'label': res.label,
                        'nameMap': res.nameMap,
                        'type': item.type
                    };
                    this.checkBoxList[cbName].push(new ConfigItem(tmp));
                })
            }
        })
    }

    getTableList() {
        this.tableObjectList = {};
        this.tableList = [];
        this.configList.forEach((item) => {
            if (item.type === 'table') {
                var tblName = item.name;
                if (this.tableObjectList[tblName] === null || this.tableObjectList[tblName] === undefined) {
                    this.tableObjectList[tblName] = [];
                    this.tableList.push(tblName);
                }

                item.listVar.forEach(res => {
                    var tmp = {
                        'key': res.name,
                        'label': res.label,
                        'nameMap': res.nameMap,
                        'type': item.type
                    };
                    this.tableObjectList[tblName].push(new ConfigItem(tmp));
                })
            }
        })
    }

    getAllTypeList() {
        this.textBoxList = [];
        this.checkBoxList = {};
        this.checkBoxes = [];
        this.tableObjectList = {};
        this.tableList = [];

        this.configList.forEach((item) => {
            var tmp = {
                'key': item.name,
                'label': item.label,
                'nameMap': item.nameMap,
                'type': item.type
            };

            if (item.isTextBox) {
                this.textBoxList.push(new ConfigItem(tmp));
            }

            if (item.isCheckBox) {
                // this.checkBoxList.push(new ConfigItem(tmp));
                var cbName = item.name;
                if (this.checkBoxList[cbName] === null || this.checkBoxList[cbName] === undefined) {
                    this.checkBoxList[cbName] = [];
                    this.checkBoxes.push(cbName);
                }

                item.listVar.forEach(res => {
                    var tmp = {
                        'key': res.name,
                        'label': res.label,
                        'nameMap': res.nameMap,
                        'type': item.type
                    };
                    this.checkBoxList[cbName].push(new ConfigItem(tmp));
                })
            }

            if (item.isTable) {
                var tblName = item.name;
                if (this.tableObjectList[tblName] === null || this.tableObjectList[tblName] === undefined) {
                    this.tableObjectList[tblName] = [];
                    this.tableList.push(tblName);
                }

                item.listVar.forEach(res => {
                    var tmp = {
                        'key': res.name,
                        'label': res.label,
                        'nameMap': res.nameMap,
                        'type': item.type
                    };
                    this.tableObjectList[tblName].push(new ConfigItem(tmp));
                });
            }
        });
    }

    makeConfigParam() {
        this.textBoxList.forEach((item) => {
            this.configParam[item.key] = '';
        })

        this.checkBoxes.forEach((item) => {
            this.configParam[item] = {};
            this.checkBoxList[item].forEach(x => {
                this.configParam[item][x.key] = 0;
            })
        })

        this.tableList.forEach((item) => {
            this.configParam[item] = [];
        })

        console.log(this.configParam);
    }

    getConfigParam() {
        var paramRender = new Object();

        this.textBoxList.forEach((item) => {
            paramRender[item.key] = '';
        })

        this.checkBoxes.forEach((item) => {
            paramRender[item] = {};
            this.checkBoxList[item].forEach(x => {
                paramRender[item][x.key] = 0;
            })
        })

        this.tableList.forEach((item) => {
            paramRender[item] = [];
        })

        return paramRender;
    }

    makeParamRender(dataRender) {
        console.log(dataRender);
        var paramRender = new Object();

        this.textBoxList.forEach((item) => {
            if (dataRender[item.key] !== '') {
                paramRender[item.key] = dataRender[item.key];
            }
        })

        this.checkBoxes.forEach((item) => {
            paramRender[item] = {};
            this.checkBoxList[item].forEach(x => {
                paramRender[item][x.key] = dataRender[item][x.key] || 0;
            })
        })

        this.tableList.forEach((item) => {
            paramRender[item] = [];
            if (dataRender[item] instanceof Array && dataRender[item].length > 0) {
                paramRender[item] = dataRender[item];
            }
        })

        return paramRender;
    }

    makeParamSubmit(dataRender) {
        var paramRender = new Object();

        this.textBoxList.forEach((item) => {
            paramRender[item.key] = dataRender[item.key];
        })


        this.checkBoxes.forEach((item) => {
            paramRender[item] = {};
            this.checkBoxList[item].forEach(x => {
                if (dataRender[item] != null && dataRender[item] != undefined 
                    && dataRender[item][x.key] != null && dataRender[item][x.key] != undefined) {
                    paramRender[item][x.key] = dataRender[item][x.key] || 0;
                } else {
                    paramRender[item][x.key] = 0;
                }
            })
        })

        this.tableList.forEach((item) => {
            paramRender[item] = [];
            if (dataRender[item] instanceof Array && dataRender[item].length > 0) {
                dataRender[item].forEach((sub_item) => {
                    console.log(sub_item);
                    this.tableObjectList[item].forEach((_item) => {
                        if (sub_item[_item.key] === '') sub_item[_item.key] = ' ';
                    })
                    paramRender[item].push(new Object(sub_item));
                })
            } else {
                paramRender[item] = [];
                var tmp = new Object();
                this.tableObjectList[item].forEach((_item) => {
                    tmp[_item.key] = ' ';
                })
                paramRender[item].push(tmp);
            }
        })

        return paramRender;
    }

    setData(dataRender) {
        var paramRender = new Object();

        this.textBoxList.forEach((item) => {
            paramRender[item.key] = dataRender[item.key] || '';
        })

        this.checkBoxes.forEach((item) => {
            paramRender[item] = {};
            this.checkBoxList[item].forEach(x => {
                if (dataRender[item] != null && dataRender[item] != undefined 
                    && dataRender[item][x.key] != null && dataRender[item][x.key] != undefined) {
                    paramRender[item][x.key] = dataRender[item][x.key] || 0;
                } else {
                    paramRender[item][x.key] = 0;
                }
            })
        })

        this.tableList.forEach((item) => {
            paramRender[item] = [];
            if (dataRender[item] instanceof Array && dataRender[item].length > 0) {
                dataRender[item].forEach((sub_item) => {
                    paramRender[item].push(new Object(sub_item));
                })
            } else {
            }
        })

        console.log(paramRender);

        return paramRender;
    }
}

export class ConfigItem {
    key: string;
    label: string;
    nameMap: string;
    type: string = "";

    constructor(obj = null) {
        obj = obj || {};
        this.key = obj.key || '';
        this.label = obj.label || '';
        this.nameMap = obj.nameMap || '';
        this.type = obj.type || "";
    }
}


export class ListTable {
    [key: string]: ConfigItem[];
}

export class ListCheckBox {
    [key: string]: ConfigItem[];
}