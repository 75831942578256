import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, timer } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { LocationExtension } from 'src/app/utils/common/LocationExtension';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { AasValidateMessage } from 'src/app/utils/common/validate-msg-aas';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { REGEXP_EMAIL, REGEXP_PHONE_NUMBER } from 'src/app/utils/common/regexp';

@Component({
  selector: 'app-otp-forgot-password',
  templateUrl: './otp-forgot-password.component.html',
  styleUrls: ['./otp-forgot-password.component.scss']
})
export class OtpForgotPasswordComponent implements OnInit {
  @Input() user: string;
  @Input() remainingOtp: Number = 0;
  // user = this.activedRoute.snapshot.queryParamMap.get('user') || '';

  formOTP: FormGroup;
  isSubmit = false;
  ServiceMsg = AasValidateMessage.REQUEST_OTP;

  
  tokenConfirm = '';

  maxRequestOTP = 3;
  maxWrongOTP = 3
  counter$: Observable<number>;
  count = 0;
  regexEmail = REGEXP_EMAIL
  regexSDT = REGEXP_PHONE_NUMBER
  // tgian đếm ngược
  remainSecond = 30 

  constructor(
    private route: Router,
    private activedRoute: ActivatedRoute,
    private authService: AasAuthService,
    private location: Location,
    private alert: AlertControl,
    private translate: TranslateService
  ) {
    // this.resetTimeout();
  }

  ngOnInit(): void {
    this.makeSendOTPForm();
  }

  //MARK: Khởi tạo form send email
  makeSendOTPForm() {
    this.formOTP = new FormGroup({
      otp: new FormControl(null, [Validators.minLength(6), Validators.maxLength(6)]),
    });
  }

  get otp() { return this.formOTP.get('otp') }

  //MARK: Action chức năng OTP
  /**
   * Sự kiện nhấn nút xác nhận OTP
   */
  verifyOTP(event) {
    this.isSubmit = false;
    if (event.target.value.length == 6) {
      if (this.formOTP.valid && this.isSubmit == false) {
        this.isSubmit = true;
        this.clearSpace();
        let otp = this.otp.value;
        this.clearInputOTP();
        this.postConfirmOTPForgotPasswordV2(this.user, otp);
      }
    }
  }

  //MARK: Timout resend
  resetTimeout() {
    this.count = this.remainSecond;
    this.counter$ = timer(0, 1000).pipe(
      take(this.count),
      map(() => --this.count)
    );
  }

  



  /**
   * Sự kiện nhấn nút gửi lại OTP
   */
  resendOTP() {
    this.alert.showAlert(this.translate.instant("OtpForgotPassword_h_5",{otpRemain: this.remainingOtp ?? 0}), '', true, '', "btn_confirm", () => {
      this.clearSpace();
      this.postCreateForgotPasswordV3(this.user);
    }, '', "btn_cancel",()=>{},"OtpForgotPassword_h_6");
    
  }

  /**
   * Handle verify otp failed
   */
  verifyOTPFailed() {
    // this.alert.showAlertOnlyNoti('Bạn đã nhập sai OTP quá ba lần. Vui lòng thử lại sau!', 'error', '', 'OK', () => {
    //   this.route.navigate([ConstantUrl.FORGOT_PASSWORD]);
    // });
    this.alert.showAlertOnlyNoti(this.translate.instant('OtpForgotPassword_err_5'), 'error', '', 'OK', () => {
      this.route.navigate([ConstantUrl.FORGOT_PASSWORD]);
    });
  }

  /**
   * Điều kiện để enable nút "Xác nhận"
   */
  get enableBtnSubmit(): boolean {
    return this.otp.invalid;
  }

  clearInputOTP() {
    this.otp.setValue('');
  }

  //MARK: Trim value formControl
  clearSpace() {
    let str = `${this.otp.value || ''}`;
    this.otp.setValue(`${str.trim()}`);
  }

  /**
   * Confirm OTP V2
   * @param email 
   * @param otp 
   * @param domain 
   */
  postConfirmOTPForgotPasswordV2(emailOrPhone, otp, domain = LocationExtension.HOSTNAME) {
    let type = 0
    if (this.regexEmail.test(emailOrPhone)){
      type = 1
    }
    else if(this.regexSDT.test(emailOrPhone)) {
      type = 2
    }
    this.authService.postConfirmForgotPasswordV3(emailOrPhone,type, otp, domain).subscribe((res: any) => {
      if (type == 1){
        this.route.navigate(['/app/renew-password'], { queryParams: { 'email': emailOrPhone, 'token': res['object']['confirmationToken'] } });
      }
      else if (type == 2){
        this.route.navigate(['/app/renew-password'], { queryParams: { 'phoneNumber': emailOrPhone, 'token': res['object']['confirmationToken'] } });
      }
      
    }, err => {
      if (err.error.message && err.error.message === 'ECT-00001203') {
        // this.otp.setErrors({ notFound: true });
        // this.maxRequestOTP--;
        // if (this.maxRequestOTP <= 0) {
        //   this.verifyOTPFailed();
        // }
        this.alert.showAlertOnlyNoti(this.translate.instant('OtpForgotPassword_err_3'), 'error');
      } else if (err.error.message && err.error.message === 'ECT-00001122') {
        this.verifyOTPFailed();
      } else if (err.error.message && err.error.message === 'ECT-00001123') {
        this.alert.showAlertOnlyNoti(this.translate.instant('OtpForgotPassword_err_4'), 'success', '', 'OK', () => {
          this.route.navigate([ConstantUrl.FORGOT_PASSWORD]);
        });
      } else if (err.error.message && err.error.message === 'BRE-00000011'){
        // trường hợp quá 3 lần wrong otp mà vẫn mã code 'BRE-00000011'
        if (err.error?.countWrongRequest >= 3){
          this.alert.showAlertOnlyNoti(this.translate.instant("BRE-00000037"), 'error',"",this.translate.instant('btn_close'),()=>{
            this.route.navigate([ConstantUrl.LOGIN]);
          });
          return
        }
        this.alert.showAlertOnlyNoti(this.translate.instant("BRE-00000011",{remainWrongOTP: (this.maxWrongOTP - err.error?.countWrongRequest) || 0}), 'error');
      } 
      // Trường hợp quá 3 lần wrong OTP => quay lại trang đăng nhập
      else if (err.error.message && err.error.message === 'BRE-00000037'){
        this.alert.showAlertOnlyNoti(this.translate.instant("BRE-00000037"), 'error',"",this.translate.instant('btn_close'),()=>{
          this.route.navigate([ConstantUrl.LOGIN]);
        });
      }
      else {
        this.alert.showErrorHandled(err, ResponseMsg.MESSAGES.REQUEST_OTP);
      }
    }, () => {

    });
  }

  /**
   * Tạo yêu cầu quên mật khẩu V2
   * @param type 
   * @param user 
   * @param domain 
   */
  postCreateForgotPasswordV3(emailOrPhone, domain = LocationExtension.HOSTNAME) {
    let type = 0
    if (this.regexEmail.test(emailOrPhone)){
      type = 1
    }
    else if(this.regexSDT.test(emailOrPhone)) {
      type = 2
    }
    if (type != 0){
      this.authService.postCreateForgotPasswordV3(emailOrPhone, type, domain).subscribe((res:any) => {
        this.remainingOtp = res.object?.remainingOtp || 0
        this.resetTimeout();
      }, err => {
        console.log(err);
        this.alert.showErrorHandled(err, ResponseMsg.MESSAGES.REQUEST_OTP);
      }, () => {

      });
    }
  }

}
