import {PackageActive} from './data-item.model';

export class ListDataPackageResponse {
 
    data: Array<PackageActive> = [];

    constructor(obj = null) {
        obj = obj || {};

        this.data = [];
        if (obj.data && obj.data instanceof Array) {
            obj.data.forEach((item) => {
                this.data.push(new PackageActive(item));
            })
        }
    }
}
