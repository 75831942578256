import { Injectable } from '@angular/core';
import { ServicePath } from 'src/app/utils/common/constant-service-api';
import { API } from '../../api';

const EKYC_CONFIG = ServicePath.ESIGNATURE_SERVICE + "/config/config-ekyc"

@Injectable({
  providedIn: 'root'
})
export class EkycService {

  constructor(private api: API) { }

  getEkycConfig(identityType, partyId) {
    const url = EKYC_CONFIG + `?identityType=${identityType}&partyId=${partyId}`;
    return this.api.get(url);
  }

  updateEkycConfig(identityType, partyId, data) {
    const url = EKYC_CONFIG + `?identityType=${identityType}&partyId=${partyId}`;
    return this.api.put(url, data);
  }

}
