import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ContractStatus } from 'src/app/utils/common/constant';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { DateUtils } from 'src/app/utils/common/DateUtils';
import { REGEXP_DATE_INPUT } from 'src/app/utils/common/regexp';
import { UtilsFunc } from 'src/app/utils/common/utils-func';
import { ContractSignMultipleItem } from 'src/app/utils/models/aas-models/contract/sign-multiple-contracts/contract-sign-multiple-item';
import { AasContractService } from 'src/app/utils/services/aas-network/aas-contract.service';
import { AuthService } from 'src/app/utils/services/auth.service';
import * as $ from 'jquery';
import { exit, title } from 'process';
import { ConstantAlertBtn } from 'src/app/utils/common/constant-alert-msg';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ContractTypeService } from '../../contract-type-management/services/contract-type.service';
import { LoadingService } from 'src/app/utils/services/loading.service';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-nosign-multiple',
  templateUrl: './nosign-multiple.component.html',
  styleUrls: ['./nosign-multiple.component.scss']
})
export class NosignMultipleComponent implements OnInit {

  @ViewChild('fromDatePicker') _fromDate: ElementRef;
  @ViewChild('toDatePicker') _toDate: ElementRef;

  constructor(
    private fb: FormBuilder,
    private contractService: AasContractService,
    private myAlert: AlertControl,
    private auth: AuthService,
    private router: Router,
    private contractTypeService: ContractTypeService,
    private myLoader: LoadingService
  ) { }
  isSubmit = false;

  isCheckAll: boolean = false;

  listContract: Array<any> = [];
  /**
   * Đếm số lượng dòng đã chọn
   */
  get totalRecordSelected() {
    return this.contractsSelected.length;
  }

  page = 1;
  maxSize = 10;
  formFilter: FormGroup;
  dateType = {
    CREATED_FROM: 'CREATED_FROM',
    CREATED_TO: 'CREATED_TO'
  };
  currentDate = new Date();
  optionsCleave = {
    date: true,
    delimiter: '/',
    datePattern: ['d', 'm', 'Y']
  };
  validateSearch = {
    fromDate: {
      pattern: "Sai định dạng ngày tháng năm!",
      minDate: 'Từ ngày không được lớn hơn đến ngày và không được là ngày tương lai!'
    },
    toDate: {
      pattern: "Sai định dạng ngày tháng năm!"
    }
  };

  contractsSelected: Array<any> = [];
  totalElement: number = 0;
  userId: string = this.auth.userId;
  formSearch: FormGroup;
  sort: string = "";
  propertySort: string = ""
  currentStage = ContractStatus;

  // Check if approved
  endSignJob: boolean = false;
  // Id for get list contract after approve
  requestId: string = "";
  // List contract after approve
  listNoSignContracts: Array<any> = [];
  approvedPage: number = 1;
  approvedMaxSize: number = 10;
  approvedTotalElement: number = 0;
  approvedSuccessContracts: number = 0;
  approvedErrorContracts: number = 0;

  ngOnInit(): void {
    this.makeFormFilter();
    this.makeFormSearch();
    this.sortCol('modified');
    this.lazyLoadDropListType();
  }

  /**
   * Khởi tạo form tìm kiếm
   */
  makeFormSearch() {
    this.formSearch = this.fb.group({
      search: new FormControl('')
    });
  }
  get keySearch() { return this.formSearch.get('search'); }

  /**
   * Tải trang hợp đồng
   * @param page 
   */
  loadData(page = 1) {
    this.page = page;
    this.getListContractsWithoutSign(
      this.userId,
      this.formFilter.value.customerType || "",
      this.fromDate,
      this.toDate,
      this.formFilter.value.status,
      this.keySearch.value,
      this.page,
      this.maxSize,
      this.sort,
      this.propertySort,
      this.typeId || ""
    )
  }

  /**
  * Khoi tao form filter
  */
  makeFormFilter() {
    this.formFilter = this.fb.group({
      customerType: new FormControl(''),
      toDate: new FormControl('', [Validators.pattern(REGEXP_DATE_INPUT)]),
      fromDate: new FormControl('', [Validators.pattern(REGEXP_DATE_INPUT)]),
      status: new FormControl(''),
      typeId: new FormControl('')
    });
  }
  get fromDate() {
    return DateUtils.convertInputToFormat(this.formFilter.value.fromDate, DateUtils.format.API);
  }
  get toDate() {
    return DateUtils.convertInputToFormat(this.formFilter.value.toDate, DateUtils.format.API);
  }
  get status() {
    return this.formFilter.value.status;
  }

  get typeId() {
    return this.formFilter.value.typeId;
  }

  /**
   * Gửi yêu cầu lấy danh sách hợp đồng để duyệt không ký hàng loạt
   */
  getListContractsWithoutSign(partyId, partnerType, fromDate, toDate, status, keySearch, page, maxSize, sort, propertiesSort, typeId) {
    this.contractService.getListContractsWithoutSign(partyId, partnerType, fromDate, toDate, status, keySearch, page, maxSize, sort, propertiesSort, typeId).subscribe((res: any) => {
      if (res && res.object.data && res.object.data instanceof Array) {
        this.listContract = res.object.data ?? [];
        if (this.listContract.length > 0) {
          this.listContract.forEach(value => {
            value.totalParty = value.partner.length ?? 0;
            value.selected = false;
          });
        }
        // res.object.data.forEach((item: any) => {
        //   this.listContract.push(new ContractSignMultipleItem(item));
        // })

        this.reupdateCheckboxListContract();
      }
      this.totalElement = res?.object?.totalElement;
    }, err => {
      this.listContract = [];
      this.myAlert.showErrorHandled(err);
    })
  }

  /**
   * Load lại dữ liệu hợp đồng đã được chọn khi chuyển trang
   */
  reupdateCheckboxListContract() {
    this.isCheckAll = false;
    this.contractsSelected.forEach((item: any) => {
      let x = this.listContract.find((e) => { return e.contractId == item.contractId; });
      if (x != null) {
        x.selected = true;
        this.isCheckAll = true;
        this.listContract.forEach((item: any) => {
          if (item.selected == false) {
            this.isCheckAll = false;
          }
        })
      }
    })
  }

  /**
   * Thoát khỏi màn hình chức năng
   */
  goToBack() {
    this.router.navigate([ConstantUrl.CONTRACT_MANAGER]);
  }

  /**
  * Chọn tất cả hợp đồng
  */
  checkAll() {
    // // Đã đủ số lượng
    // if (this.isCheckAll == false && this.contractsSelected.length >= this.maxFileSelect) {
    //   this.myAlert.showAlertOnlyNoti("Bạn không thể tiếp tục chọn vì đã đạt đến giới hạn về số lượng file hợp đồng hoặc tổng dung lượng file hợp đồng cho phép.", "");
    //   $(`#contractNoSignAll`).prop('checked', false);
    //   return;
    // }
    // Chưa đủ số lượng


    if (this.isCheckAll == false) {
      // let checkout = false;
      this.listContract.forEach((item) => {
        if (item.selected == false) {
          // if (this.contractsSelected.length >= this.maxFileSelect) {
          //   this.isCheckAll = false;
          //   this.myAlert.showAlertOnlyNoti("Bạn không thể tiếp tục chọn vì đã đạt đến giới hạn về số lượng file hợp đồng hoặc tổng dung lượng file hợp đồng cho phép.", "");
          //   checkout = true;
          //   return;
          // }
          item.selected = true;
          this.contractsSelected.push(item);
        }
      });
      // if (checkout) {
      //   $(`#contractNoSignAll`).prop('checked', false);
      //   return;
      // }
      // else {
      //   this.isCheckAll = true;
      // }
      this.isCheckAll = true;

    } else {
      this.listContract.forEach((item) => {
        if (item.selected == true) {
          item.selected = false;
          this.contractsSelected = this.contractsSelected.filter((value: any) => {
            return value.contractId != item.contractId;
          })
        }
        // 
      })
    }
  }

  sortColConfig = {
    title: null,
    partnersCount: null,
    modified: null,
    currentStage: null,
  }
  /**
   * Sắp xếp theo cột
   * @param key 
   */
  currentSortKey: string = "";
  sortCol(key) {
    switch (this.sortColConfig[key]) {
      case null:
        if (this.sortColConfig[this.currentSortKey] != undefined) {
          this.sortColConfig[this.currentSortKey] = null;
        }
        this.sortColConfig[key] = true;
        this.sort = "DESC";
        this.propertySort = key;
        this.currentSortKey = key;
        break;
      case true:
        this.sortColConfig[key] = false;
        this.sort = "ASC";
        this.propertySort = key;
        break;
      case false:
        this.sortColConfig[key] = null;
        this.sort = "";
        this.propertySort = "";
        break;
      default:
        break;
    }

    this.loadData(this.page);
  }

  /**
   * Chọn 1 dòng
   * @param i 
   */
  selectRecord(i) {
    // if (this.listContract[i].selected == false && this.contractsSelected.length >= this.maxFileSelect) {
    //   this.myAlert.showAlertOnlyNoti("Bạn không thể tiếp tục chọn vì đã đạt đến giới hạn về số lượng file hợp đồng hoặc tổng dung lượng file hợp đồng cho phép.", "");
    //   $(`#contractNoSign-${i}`).prop('checked', false);
    //   return;
    // }
    this.listContract[i].selected = !this.listContract[i].selected;
    if (this.listContract[i].selected == true) {
      this.contractsSelected.push(this.listContract[i]);
    } else {
      this.contractsSelected = this.contractsSelected.filter((item: any) => {
        return item?.contractId != this.listContract[i].contractId;
      })
    }

    this.isCheckAll = true;
    for (let i = 0; i < this.listContract.length; i++) {
      if (this.listContract[i].selected == false) {
        this.isCheckAll = false;
        break;
      }
    }
  }

  /**
   * Lấy số lượng file đã chọn
   */
  get progressValueNow() {
    return Number(Number(this.totalSizeFile) / this.maxSizeFile * 100).toFixed(1);
  }

  maxSizeFile = 200;

  /**
   * Lấy thông số dung lượng file đã chọn
   */
  get totalSizeFile() {
    let sum = 0;
    this.contractsSelected.forEach((item) => {
      sum = sum + item.sizeFile;
    })

    return sum.toFixed(1);
  }

  /**
   * Đặt lại bộ lọc, tìm kiếm
   */
  cleanFilter() {
    this.isSubmit = false;
    this._fromDate.nativeElement.value = '';
    this._toDate.nativeElement.value = '';
    this.formFilter.reset();
    this.formFilter.controls.customerType.setValue('');
    this.formFilter.controls.fromDate.setValue('');
    this.formFilter.controls.toDate.setValue('');
    this.formFilter.controls.status.setValue('');
    this.formFilter.controls.typeId.setValue('');
    this.formSearch.reset();
    this.keySearch.setValue('');
    this.searchWithFilter()
  }


  /**
   * Tìm kiếm với bộ lọc
   */
  searchWithFilter() {
    this.isSubmit = true;

    if (this.formFilter.valid) {
      // Nếu ngày nhập vào đúng định dạng thì check thêm điều kiện từ ngày phải nhỏ hơn đến ngày và không đc là ngày tương lai
      let dateFrom = this.formFilter.value.fromDate.length > 0 ? new Date(DateUtils.convertStringtoDateSearch(this.formFilter.value.fromDate)).getTime() : -1;
      let dateTo = this.formFilter.value.toDate.length > 0 ? new Date(DateUtils.convertStringtoDateSearch(this.formFilter.value.toDate)).getTime() : -1;
      if ((dateTo > 0 && dateFrom > 0 && dateFrom > dateTo)) {
        this.formFilter.controls.fromDate.setErrors({ minDate: true });
      }
    }

    if (this.formFilter.valid) {
      console.log(this.formFilter.value);
      this.loadData();
    }
  }

  /**
   * Thay doi ngay tren filter
   * @param value 
   * @param type 
   */
  changeDateFilter(event, type) {
    switch (type) {
      case this.dateType.CREATED_FROM:
        this.formFilter.controls.fromDate.setValue(DateUtils.convertObjtoDate(event));
        break;
      case this.dateType.CREATED_TO:
        this.formFilter.controls.toDate.setValue(DateUtils.convertObjtoDate(event));
        break;
    }
  }

  /**
   * Tìm kiếm với key
   * @param event 
   */
  actSearch(event) {
    this.page = 1;
    this.loadData();
  }

  approve() {
    if (this.contractsSelected.length > 0) {
      let data = {
        contracts: []
      };
      this.contractsSelected.forEach(value => {
        data.contracts.push(value.contractId ?? '');
      });

      this.contractService.batchApproveWithoutSign(data).subscribe((res: any) => {

        this.myAlert.showAlert("Bạn đã hoàn thành quá trình duyệt không ký hợp đồng hàng loạt.", "", false, "", ConstantAlertBtn.CLOSE, () => {
          // this.router.navigate([ConstantUrl.CONTRACT_MANAGER]);
        }, "", "", null, "Thông báo");

        if (res.object.requestId) {
          this.requestId = res.object.requestId;
          this.getListNoSignContract();
          this.endSignJob = true;
        }

      }, err => {
        this.myAlert.showErrorHandled(err);
      })
    }

  }

  getListNoSignContract() {
    this.contractService.getListNoSignContract(this.requestId, this.approvedPage, this.approvedMaxSize)
      .subscribe((res: any) => {
        this.listNoSignContracts = res.object.data ?? [];
        this.approvedTotalElement = res.object.totalElement ?? 0;
        if (this.listNoSignContracts.length > 0 && this.approvedTotalElement > 0) {
          this.approvedSuccessContracts = this.listNoSignContracts[0].successContract ?? 0;
          this.approvedErrorContracts = this.approvedTotalElement - this.approvedSuccessContracts;
        }
      }, err => {
        this.myAlert.showErrorHandled(err);
      });
  }

  viewErrorDetail(index: number) {
    let msg = 
    `<div class="row"><div class="col-md-4"><label class="text-weight-bold">Tên hợp đồng</label></div><div class="col-md-8"><span>${this.listNoSignContracts[index].title}</span></div></div>`
    + `<div class="row mt-2"><div class="col-md-4"><label class="text-weight-bold">Nội dung lỗi</label></div><div class="col-md-8"><span>${this.listNoSignContracts[index].error}</span></div></div>`;
    this.myAlert.showAlertOnlyNoti(msg, ConstantAlertType.BLANK, "", "Đóng", null, "Chi tiết lỗi");
  }

  finish() {
    this.router.navigate([`app/console/contract-manager`]);
  }
  
  dropListTypeData: Observable<any>;
  typesLoading = false;
  
  /**
   * Lay du lieu loai hop dong ||  Get list of types
   * @param page 
   * @param maxSize 
   * @param propertiesSort 
   * @param sort 
   * @param status 
   */
  getTypeList(page, maxSize, propertiesSort = "modified", sort = "DESC", status = "") {
    this.typesLoading = true;
    this.contractTypeService.getListContractTypes(page, maxSize, propertiesSort, sort, status, this.keySearchDropListEmployee).subscribe((res: any) => {
      this.dropListTypeData.pipe(tap((data: any) => {
        return [...data, (res?.object?.data || [])];
      }))
      this.typesLoading = false;
    }, err => {
      this.typesLoading = false;
    });
  }

  dropListTypePage = 1;
  endOfDropListTypePage = false;
  keySearchDropListEmployee = "";
  typeInput$ = new Subject<string>();
  allLabel: Array<object> = [
    { contractTypeId: '', name: 'Tất cả' }
  ];
  lazyLoadDropListType() {
    this.dropListTypeData = concat(
      this.typeInput$.pipe(
        startWith(''),
        debounceTime(400),
        distinctUntilChanged(),
        tap(() => { this.typesLoading = true; this.myLoader.disabledLoader(this.typesLoading); }),
        switchMap((term) => {
          this.keySearchDropListEmployee = term || "";
          this.dropListTypePage = 1;
          this.endOfDropListTypePage = false;
          let res = this.contractTypeService.getListContractTypes(this.dropListTypePage, -1, "", "DESC", "", term || "").pipe(
            map((res: any) => {
              if (res?.object?.data == null) this.endOfDropListTypePage = true;
              return this.allLabel.concat(res?.object?.data) || [];
            }),
            catchError(() => of([])), // empty list on error
            tap(() => { this.typesLoading = false; this.myLoader.disabledLoader(this.typesLoading); })
          );
          return res;
        })
      ));
  }

  trackByFn1(item: any) {
    return item;
  }

  changeType($event) {
  }

  /**
   * Su kien scroll xuong duoi cung cua droplist
   * @returns 
   */
  onScrollToEnd() {
    var nextPage = this.dropListTypePage + 1;
    if (this.endOfDropListTypePage) return;
    this.dropListTypePage = nextPage;
    this.getTypeList(nextPage, 10);
  }
}
