import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { TranslateService } from '@ngx-translate/core';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ContractTypeService } from '../../services/contract-type.service';

@Component({
  selector: 'app-create-contract-type',
  templateUrl: './create-contract-type.component.html',
  styleUrls: ['./create-contract-type.component.scss']
})
export class CreateContractTypeComponent implements OnInit {

  form: FormGroup;
  isSubmit = false;
  validateError = {
    name: {
      required: 'ContractTypes_clc_form_name_required'
    },
  };

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private myAlert: AlertControl,
    private translate: TranslateService,
    private contractTypeService: ContractTypeService
  ) { }

  ngOnInit(): void {
    this.makeForm();
  }

  makeForm() {
    this.form = this.fb.group({
      name: ['', [Validators.required]],
    });
  }

  cancel() {
    this.router.navigate([ConstantUrl.contract_type_management])
  }

  save() {
    this.myAlert.showAlert(this.translate.instant('ContractTypes_add_contract_type_confirm'), '', true, '', this.translate.instant('btn_addnew'), () => {
      this.isSubmit = true;
      if (this.form.invalid) return;
      this.postCheckNameExists(this.form.controls.name.value)
    }, '', this.translate.instant('btn_close'), null, this.translate.instant('btn_confirm'));
  }

  /**
   * Kiem tra ten loai hop dong da ton tai
   * @param param 
   */
  postCheckNameExists(name) {
    var data = {
      "name" : name
    }
    this.contractTypeService.postCheckNameExists(data).subscribe(
      (res: any) => {
        if (res.object.exists && res.object.exists == true) {
          this.myAlert.showAlert(
            this.translate.instant('ContractTypes_add_type_exist_confirm'),
            ConstantAlertType.BLANK,
            true,
            '',
            this.translate.instant('btn_addnew'),
            () => {
              this.postAddContractType(this.form.value);
            },
            '',
            this.translate.instant('btn_cancel')
          );
        } else {
          this.postAddContractType(this.form.value);
        }
      }, (err) => {
        this.myAlert.showErrorHandled(err);
      }
    )
  }

  /**
   * Tao loai hop dong moi 
   * @param data 
   */
  postAddContractType(data) {
    this.contractTypeService.postAddContractType(data).subscribe(
      () => {
        this.myAlert.showAlertOnlyNoti(
          this.translate.instant('ContractTypes_add_type_success'),
          ConstantAlertType.SUCCESS,
          '',
          this.translate.instant('btn_close')
        );
        this.router.navigate([ConstantUrl.contract_type_management]);
      },
      (err) => {
        this.myAlert.showErrorHandled(err);
      }
    );
  }
}
