import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ValidateMessage, AasValidateMessage } from 'src/app/utils/common/validate-msg-aas';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgModalComponent } from 'src/app/layout/extensions/ng-modal/ng-modal.component';
import { BehaviorSubject, Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { AuthService } from 'src/app/utils/services/auth.service';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { UserInformationsItem } from 'src/app/utils/models/profile/user-info-item.model';
import { UserInformationsResponse } from 'src/app/utils/models/profile/user-info-response.model';
import { DateUtils } from 'src/app/utils/common/DateUtils';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { ValidatorsExtension } from 'src/app/utils/common/validators-extension';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertBtn, ConstantAlertMsg } from 'src/app/utils/common/constant-alert-msg';
import { ErrorHandling } from 'src/app/utils/error/error-handling';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { TranslateService } from '@ngx-translate/core';

declare var Cleave: any;
@Component({
  selector: 'app-profile-consumer',
  templateUrl: './profile-consumer.component.html',
  styleUrls: ['./profile-consumer.component.scss']
})
export class ProfileConsumerComponent implements OnInit {
  @Input('data') _data: BehaviorSubject<UserInformationsItem> = new BehaviorSubject<UserInformationsItem>(new UserInformationsItem());
  @Output() public onActionSave: EventEmitter<any> = new EventEmitter<any>();
  @Input('userInfo') userInfo: UserInformationsItem = new UserInformationsItem();

  id: string = this.auth.userId;
  partyId: string = this.auth.partyId;

  loadingForm = new BehaviorSubject<boolean>(false);
  // : UserInformationsItem = new UserInformationsItem();

  formSubmit: FormGroup;
  formIdentity: FormGroup;
  isIdentityAgain: boolean = false;

  isSubmit = false;
  ServiceMsg = AasValidateMessage.PROFILE_MANAGER;


  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private auth: AuthService,
    private aasAuth: AasAuthService,
    private alert: AlertControl,
    private translate: TranslateService
  ) { }

  @Input()
  set data(value) {
    this._data.next(value);
  };

  get data() {
    return this._data.getValue();
  }

  ngOnInit(): void {
    this.makeFormGroup();
    // this._data
    //   .subscribe(x => {
    //     this.userInfo = this.data;
    //     if (this.userInfo.identityLevel > 0) {
    //       this.makeAutoFormatDate();
    //     }

    //     console.log(this.userInfo);

    //     this.setValue(this.userInfo);
    //   });
  }

  //MARK: Khởi tạo form
  makeFormGroup() {
    this.formSubmit = this.fb.group({
      username: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required, Validators.pattern(/^([0-9]{10,11})$/i)]),
    })

    this.formIdentity = this.fb.group({
      fullname: new FormControl('', [Validators.required, ValidatorsExtension.validateFullName]),
      gender: new FormControl('1', [Validators.required]),
      dateOfBirth: new FormControl('', [Validators.required, Validators.pattern(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i)]),
      noiDKHKTT: new FormControl('', [Validators.required]),
      cmnd: new FormControl('', [Validators.pattern(/^[0-9]{9,12}$/i)]),
      hoChieu: new FormControl('', [Validators.pattern(/^(?!^0+$)[a-zA-Z0-9]{6,9}$|^[0-9]{9}$|^[0-9]{12}$/i)]),
      soGiayTo: new FormControl('', [Validators.pattern(/^(?!^0+$)[a-zA-Z0-9]{6,9}$|^[0-9]{9}$|^[0-9]{12}$/i)]),
      ngayCap: new FormControl('', [Validators.required, Validators.pattern(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/i)]),
      noiCap: new FormControl('', [Validators.required])
    })

    this.setValue(this.userInfo);
  }

  reload(data: UserInformationsItem) {
    this.userInfo = data;
    if (this.userInfo.identityLevel > 0) {
      this.makeAutoFormatDate();
    }

    this.setValue(data);
  }

  setValue(data: UserInformationsItem) {
    this.username.setValue(data.username || '');
    this.email.setValue(data.email || '');
    this.phone.setValue(data.sdt || '');
    this.fullname.setValue(data.ten || '');
    this.gender.setValue(data.gioiTinhId || '');
    this.dateOfBirth.setValue(DateUtils.convert(data.ngaySinh, DateUtils.format.INPUT));

    this.soGiayTo.setValue(data.cmnd);
    this.noiCap.setValue(data.noiCap);
    this.noiDKHKTT.setValue(data.diaChiThuongTru || data.diaChi);
    this.ngayCap.setValue(DateUtils.convert(data.ngayCap, DateUtils.format.INPUT))
  }

  get username() { return this.formSubmit.get('username'); }
  get email() { return this.formSubmit.get('email'); }
  get phone() { return this.formSubmit.get('phone'); }
  get fullname() { return this.formIdentity.get('fullname'); }
  get gender() { return this.formIdentity.get('gender'); }
  get dateOfBirth() { return this.formIdentity.get('dateOfBirth'); }
  get noiDKHKTT() { return this.formIdentity.get('noiDKHKTT'); }
  get cmnd() { return this.formIdentity.get('cmnd'); }
  get hoChieu() { return this.formIdentity.get('hoChieu'); }
  get ngayCap() { return this.formIdentity.get('ngayCap'); }
  get noiCap() { return this.formIdentity.get('noiCap'); }
  get soGiayTo() { return this.formIdentity.get('soGiayTo'); }

  //MARK: Chức năng input ngày sinh, ngày cấp giấy tờ
  makeAutoFormatDate() {
    let dateOfBirth = this.dateOfBirth
    var cleavedateOfBirth = new Cleave('#dateOfBirth', {
      date: true,
      // delimiter: '/',
      datePattern: ['d', 'm', 'Y'],
      onValueChanged: function (e) {
        dateOfBirth.setValue(e.target.value);
      }
    });

    let ngayCap = this.ngayCap
    var cleaveNgayCap = new Cleave('#ngayCap', {
      date: true,
      // delimiter: '/',
      datePattern: ['d', 'm', 'Y'],
      onValueChanged: function (e) {
        ngayCap.setValue(e.target.value);
      }
    });
  }
  /**
   * Bắt sự kiện nhập ngày
   * @param e 
   * @param fc 
   */
  dateInput(e, fc) {
    if (e.target.value.length > 10) {
      var str = e.target.value;
      e.target.value = str.substring(0, str.length - 1);
      fc.setValue(e.target.value);
      console.log(e.target.value);
    }
  }


  //MARK: Chức năng cập nhật dữ liệu profile
  actBtnCancel(e) {
    e.stopPropagation();
    this.isSubmit = false;
    this.setValue(this.userInfo);
  }

  actBtnSave(e) {
  }

  submit(e) {
    this.isSubmit = true;
    if (this.userInfo.identityLevel > 0 && this.isIdentityAgain) {
      this.submitWithIdentity();
    } else {
      this.submitNoIdentity();
    }
  }

  submitNoIdentity() {
    if (this.formSubmit.valid) {
      const param = {
        "username": this.username.value
      }

      this.alert.showAlert(this.translate.instant('ProfileConsumer_ts_1'), '', true, '', this.translate.instant('btn_save'), () => {
        this.postUpdateConsumer(this.id, param);
      }, '', this.translate.instant('btn_close'), () => { });
    }
  }

  submitWithIdentity() {
    if (this.formSubmit.valid && this.formIdentity.valid) {
      // const param = {
      //   "username": this.username.value,
      //   "ten": this.fullname.value,
      //   "gioiTinhId": this.gender.value,
      //   "ngaySinh": ,
      //   "cmnd": "205590390",
      //   "soNha": "soNha",
      //   "duong": "duong",
      //   "xaId": 8238482,
      //   "huyenId": 83359,
      //   "tinhId": 918,
      //   "ngayCap": "2020-03-26",
      //   "noiCap": "TP Đà Nẵng"
      // }

      this.alert.showAlert(this.translate.instant('ProfileConsumer_ts_1'), '', true, '', this.translate.instant('btn_confirm'), () => {
        // this.postUpdateConsumer(this.id, param);
      }, '', this.translate.instant('btn_save'), () => { });
    }
  }

  //MARK: Thay đổi định danh
  actRequestChangeIdentity() {
    this.isIdentityAgain = true;
  }

  //MARK: Loading
  loadingFormIsVisiable(): Observable<boolean> {
    return this.loadingForm.asObservable().pipe(share());
  }

  //MARK: Networking
  /**
   * Request update thong tin tai khoan ca nhan
   * @param id 
   * @param param 
   */
  postUpdateConsumer(id, param) {
    this.aasAuth.postUpdateConsumer(id, param).subscribe(res => {
      this.getUserInfo(id);
      this.alert.showAlert(this.translate.instant('ProfileConsumer_ts_2'), ConstantAlertType.SUCCESS);
    }, err => {
      console.log(err);
      if (err.error && err.error.message) {
        switch (err.error.message) {
          case 'ECT-00001200':
            this.email.setErrors({ unique: true });
            break;
          case 'ECT-00001202':
            this.username.setErrors({ unique: true });
            break;
          default:
            this.alert.showErrorHandled(err);
        }
      }
    }, () => {

    });
  }

  /**
  * Lấy thông tin user đăng nhập
  * @param id 
  */
  getUserInfo(id) {
    this.loadingForm.next(true);
    this.aasAuth.getUserInfo(id).subscribe((res) => {
      this.loadingForm.next(false);

      this.userInfo = new UserInformationsResponse(res).object;
      console.log(this.userInfo);
    }, err => {
      this.loadingForm.next(false);
      console.log(err);

      this.alert.showErrorHandled(err);
    }, () => {
    })
  }
}
