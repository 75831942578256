import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { EnterpriseVipItem } from 'src/app/utils/models/aas-models/enterprise-vip-manager/list-enterprise-vip/enterprise-vip-item.model';
import { ListEnterpriseVipResponse } from 'src/app/utils/models/aas-models/enterprise-vip-manager/list-enterprise-vip/list-enterprise-vip-response.model';
import { InfoPartyObject } from 'src/app/utils/models/aas-models/info-party/info-party-object';
import { InfoPartyResponse } from 'src/app/utils/models/aas-models/info-party/info-party-response';
import { PartyItemResponse } from 'src/app/utils/models/party-manager/party-item-response.model';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { EnterpriseVipService } from 'src/app/utils/services/aas-network/enterprise-vip.service';

@Component({
  selector: 'app-enterprise-vip-waiting',
  templateUrl: './enterprise-vip-waiting.component.html',
  styleUrls: ['./enterprise-vip-waiting.component.scss']
})
export class EnterpriseVipWaitingComponent implements OnInit, AfterViewInit {
  @Output('reload') reload: EventEmitter<any> = new EventEmitter<any>();

  keySearch: string = '';
  enterprises: Array<EnterpriseVipItem> = [];
  totalElement: number = 0;
  maxSize: number = 5;
  page: number = 1;
  status: string = 'WAIT';
  propertiesSort: string = 'created';
  sort: string = 'DESC';
  searchString: string = '';

  enterpriseSelected: EnterpriseVipItem = new EnterpriseVipItem();
  partyInfo: InfoPartyObject = new InfoPartyObject();
  reloadAfterCreatedContract: boolean = false;

  formReject: FormGroup;

  modalReject: any = null;
  // createContract: boolean = false;
  // detailContract: boolean = false;

  private createContract = new BehaviorSubject<boolean>(false);
  private detailContract = new BehaviorSubject<boolean>(false);

  fileGPKD: Blob = null;
  showPreviewGPKD: boolean = false;

  constructor(
    private fb: FormBuilder,
    private aasAuth: AasAuthService,
    private modalService: NgbModal,
    private enterpriseVipService: EnterpriseVipService
  ) { }

  ngOnInit(): void {
    this.formReject = this.fb.group({
      rejectReason: new FormControl('')
    })

    this.loadPage(this.page = 1);
  }

  ngAfterViewInit(): void {
    // this.reload.emit({type : 'REJECT'});
  }

  createContractIsVisible(): Observable<boolean> {
    return this.createContract.asObservable().pipe(share());
  }

  detailContractIsVisible(): Observable<boolean> {
    return this.detailContract.asObservable().pipe(share());
  }

  clickSearchBtn() {
    this.loadPage(this.page = 1);
  }

  inputKeySearch(event) {
    this.searchString = event.target.value;
  }

  /**
   * Tải trang dữ liệu tài khoản tổ chức sử dụng giải pháp
   * @param page 
   */
  loadPage(page) {
    this.page = page;
    this.partyInfo = new InfoPartyObject();
    this.getListUserEnterpriseVip(this.status, this.page, this.maxSize, this.propertiesSort, this.sort, this.searchString);
  }

  /**
   * Thao tác chọn tổ chức từ danh sách
   * @param enterprise 
   */
  selectEnterprise(enterprise: EnterpriseVipItem) {
    this.enterpriseSelected = enterprise;
    this.getDetailEnterpriseVip(enterprise.partyId);
  }

  //MARK: TỪ CHỐI
  /**
   * Chọn từ chối duyệt
   * @param content 
   */
  rejectEnterprise(content) {
    this.modalReject = this.modalService.open(content, {
      ariaLabelledBy: "modal-basic-title",
      backdrop: "static",
    });
    
    this.modalReject.result.then(
      (result) => {
        console.log(result);
      },
      (reason) => {
        console.log(reason);
      }
    );
  }

  /**
   * Confirm Reject
   * @param partyId 
   * @param param 
   */
  confirmReject(partyId, param) {
    this.postRejectEnterpriseVip(partyId, param);
  }

  //MARK: PHÊ DUYỆT
  /**
   * Phê duyệt không hợp đồng
   * @param partyId 
   */
  approveNoContract(partyId) {
    this.customAlert("Bạn chắc chắn muốn phê duyệt tài khoản này?", "", true, "", "Xác nhận", () => {
      this.postApproveNoContract(partyId);
    }, "", "Hủy")
  }

  /**
   * Chọn approve có hợp đồng
   * @param partyId 
   */
  approveHaveContract(partyId) {
    if (this.enterpriseSelected.contractVipId) {
      this.detailContract.next(true);
    } else {
      this.createContract.next(true);  
    }
  }

  /**
   * Lắng nghe sự kiện đóng modal tạo hợp đồng
   * @param event 
   */
  closeCreateContractModal(event) {
    this.createContract.next(false);
  }

  /**
   * Lắng nghe sự kiện đóng modal chi tiết hợp đồng
   * @param event 
   */
  closeDetailContractModal(event) {
    this.detailContract.next(false);
  }

  /**
   * Lắng nghe sự kiện tạo hợp đồng VIP thành công
   * @param event 
   */
  onCreatedContract(event: any) {
    // this.createContract.next(false);
    this.reloadAfterCreatedContract = true;
    this.enterpriseSelected.contractVipId = event.contractId || "";
    this.detailContract.next(true);
    
    this.getDetailEnterpriseVip(this.enterpriseSelected.partyId);
    // this.selectEnterprise(this.enterpriseSelected);
  }

  reloadEmit(param) {
    this.reload.emit(param);
  }

  actDownloadGPKD(id) {
    this.downloadGPKD(id);
  }

  handleClosePreview() {
    this.showPreviewGPKD = false;
  }

  //MARK: NETWORKING
  /**
   * Lấy danh sách tài khoản tổ chức sử dụng giải pháp
   * @param status 
   * @param page 
   * @param maxSize 
   * @param propertiesSort 
   * @param sort 
   * @param searchString 
   */
  getListUserEnterpriseVip(status, page, maxSize, propertiesSort, sort, searchString) {
    this.enterpriseVipService.getListUserEnterpriseVip(status, page, maxSize, propertiesSort, sort, searchString).subscribe((res) => {
      console.log(res);
      let respo = new ListEnterpriseVipResponse(res);
      this.enterprises = respo.object.data;
      if (this.enterprises.length > 0) {
        this.selectEnterprise(this.enterprises[0]);
      }
      this.totalElement = respo.object.totalElement;
    }, err => {
      this.handleError(err, ResponseMsg.MESSAGES.ALL_IN_ONE);
    })
  }

  /**
   * Lấy thông tin chi tiết tổ chức sử dụng giải pháp
   * @param partId 
   */
  getDetailEnterpriseVip(partId) {
    this.enterpriseVipService.getDetailEnterpriseVip(partId).subscribe((res) => {
      console.log(res);
      let respo = new InfoPartyResponse(res);
      this.partyInfo = respo.object;
      if (!this.reloadAfterCreatedContract || !this.enterpriseSelected.contractVipId) {
        this.enterpriseSelected.contractVipId = this.partyInfo.contractVipId;
      }
      this.enterpriseSelected.packageId = this.partyInfo.packageId;
    }, err => {
      // this.handleError(err, ResponseMsg.MESSAGES.ALL_IN_ONE);
    })
  }

  /**
   * Từ chối phê duyệt tổ chức sử dụng giải pháp
   * @param partyId 
   * @param param 
   */
  postRejectEnterpriseVip(partyId, param) {
    this.enterpriseVipService.postRejectEnterpriseVip(partyId, param).subscribe((res) => {
      console.log(res);
      this.reloadEmit({ type: 'REJECT' });
      if (this.modalReject) this.modalReject.close();
      this.loadPage(1);
      this.customAlert("Từ chối duyệt tổ chức thành công!", "success");
    }, err => {
      this.handleError(err, ResponseMsg.MESSAGES.ALL_IN_ONE);
      if (this.modalReject) this.modalReject.close();
    })
  }

  /**
   * Phê duyệt không hợp đồng
   */
  postApproveNoContract(partyId) {
    this.enterpriseVipService.postApproveNoContract(partyId).subscribe((res) => {
      console.log(res);
      this.reloadEmit({ type: 'APPROVE' });
      this.loadPage(1);
      this.customAlert("Phê duyệt tổ chức thành công!", "success");
    }, err =>{
      this.handleError(err, ResponseMsg.MESSAGES.ENTERPRISE_VIP_MANAGER)
    })
  }
  

  /**
   * Tai file GPKD
   * @param partyId 
   */
  downloadGPKD(partyId) {
    this.aasAuth.downloadGPKD(partyId).subscribe((res: any) => {
      this.fileGPKD = new Blob([res], { type: 'application/pdf' });
      this.showPreviewGPKD = true;
    }, err => {

    })
  }

  /**
   * Custom modal alert
   * @param msg 
   * @param type 
   * @param twoBtn 
   * @param iconBtn2 
   * @param titleBtn2 
   * @param actBtn2 
   * @param iconBtn1 
   * @param titleBtn1 
   * @param actBtn1 
   */
  customAlert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.customAlert(errorMsg[err.error.message], 'error');
    } else if (err.error && err.error.error) {
      this.customAlert(err.error.error, 'error');
    } else {
      this.customAlert('Lỗi không xác định! Vui lòng thử lại', 'error');
    }
  }
}
