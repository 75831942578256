import { Component, OnInit } from '@angular/core';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { DateUtils } from 'src/app/utils/common/DateUtils';
// import { TemplateService } from 'src/app/utils/services/template.service';
import { LoadingService } from 'src/app/utils/services/loading.service';
import { Config } from 'src/app/config';
import { AuthService } from 'src/app/utils/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { CustomerInfo } from 'src/app/utils/models/aas-models/copyright-manager/copyright-detail/customer-info/customer-item.model'
import { CustomerInfoResponse } from 'src/app/utils/models/aas-models/copyright-manager/copyright-detail/customer-info/customer-detail-response.model'
import { AasLicensesService } from 'src/app/utils/services/aas-network/aas-licenses-manager.service'
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
@Component({


  selector: 'app-customer-info',
  templateUrl: './customer-info.component.html',
  styleUrls: ['./customer-info.component.scss']
})
export class CustomerInfoComponent implements OnInit {
  licenseId = this.router.snapshot.paramMap.get('id');

  customerInfo : CustomerInfo;
  customerInfoRes: CustomerInfoResponse;

  showPreviewGPKD = false;
  fileGPKD = null;

  constructor(
    private router: ActivatedRoute,
    private route: Router,
    private authService: AuthService,
    private modalService: NgbModal,
    private loaderSerive: LoadingService,
    private licensesService: AasLicensesService,
    private aasAuth: AasAuthService
  ) { }

  ngOnInit(): void {
    this.getCustomerInfo();
  }

  // get thông tin khách hàng
  getCustomerInfo(){
    this.licensesService.getCustomerInfo(this.licenseId).subscribe((res)=>{
      this.customerInfoRes = new CustomerInfoResponse(res);
      this.customerInfo = this.customerInfoRes.object;
    }, err => {
      console.log(err);
      // this.loadingServiceIsVisiable.next(false);
      this.handleError(err, ResponseMsg.MESSAGES.ALL_IN_ONE);
    })
  }
  convertToDateObj(dataString) {
    let d = new Date(dataString);
    let localTiming = d.toLocaleDateString('en-GB');
    return localTiming;
  }

  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], 'error');
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, 'error');
    } else {
      this.alert('Lỗi không xác định! Vui lòng thử lại', 'error');
    }
  }

  actDownloadGPKD(id) {
    this.downloadGPKD(id);
  }

  handleClosePreview() {
    this.showPreviewGPKD = false;
  }

  /**
   * Tai file GPKD
   * @param partyId 
   */
  downloadGPKD(partyId) {
    this.aasAuth.downloadGPKD(partyId).subscribe((res: any) => {
      this.fileGPKD = new Blob([res], { type: 'application/pdf' });
      this.showPreviewGPKD = true;
    }, err => {

    })
  }

}
