import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DashboardService } from 'src/app/utils/services/aas-network/dashboard/dashboard.service';

@Component({
  selector: 'app-stats-by-time-admin',
  templateUrl: './stats-by-time-admin.component.html',
  styleUrls: ['./stats-by-time-admin.component.scss'],
})
export class StatsByTimeAdminComponent implements OnInit {
  constructor(private dashboardService: DashboardService) {}

  ngOnInit(): void {
    this.initData(new Date().getFullYear());
  }

  years = [
    { label: 2021, value: 2021 },
    { label: 2022, value: 2022 },
  ];

  yearForm: FormControl = new FormControl(2022);

  //

  type = 'ColumnChart';
  months = {
    jan: 'T1',
    feb: 'T2',
    mar: 'T3',
    apr: 'T4',
    may: 'T5',
    jun: 'T6',
    jul: 'T7',
    aug: 'T8',
    sep: 'T9',
    oct: 'T10',
    nov: 'T11',
    dec: 'T12',
  };
  data = [
    // ["T1", 54],
    // ["T2", 292],
    // ["T3", 188],
    // ["T4", 421],
    // ["T5", 115],
    // ["T6", 178],
    // ["T7", 46],
    // ["T8", 0],
    // ["T9", 0],
    // ["T10", 0],
    // ["T11", 0],
    // ["T12", 0],
  ];
  options = {
    legend: 'none',
    colors: ['#567DF4'],
  };
  width = 550;
  height = 400;
  dynamicResize = true;
  initData(year) {
    this.dashboardService.getContractStatsByTime(year).subscribe(
      (res: any) => {
        if (res && res.object instanceof Array) {
          this.data = Object.keys(res.object[0]).map((key) => [
            this.months[key],
            res.object[0][key] == null ? 0 : res.object[0][key],
          ]);

          // console.log(this.data);
        }
      },
      (error) => {}
    );
  }
  changeYear(event) {
    this.initData(event.value);
  }
}
