<section class="card-body p-0">
    <div class="table-responsive bg-white">
        <table class="table mb-0">
            <thead>
                <tr class="bg-tb-header border-bottom text-left">
                    <th>{{ 'Idcard_stt' | translate }}</th>
                    <th>{{ 'Idcard_field' | translate }}</th>
                    <th>{{ 'Idcard_type' | translate }}</th>
                    <th>{{ 'Idcard_description' | translate }}</th>
                    <th>{{ 'Idcard_warning' | translate }}</th>
                    <th>{{ 'Idcard_success' | translate }}</th>
                    <th>{{ 'Idcard_status' | translate }}</th>
                </tr>
            </thead>
            <tbody *ngIf="ekycConfig">
                <tr *ngFor="let item of ekycConfig; let i = index" class="text-left border-bottom">
                    <td>{{ (page - 1)*maxSize + i + 1 }}</td>
                    <td>{{ (componentName + '_' + item.code + '_' + 'name' | translate) || "" }}</td>
                    <td>{{ item.typeField || "" }}</td>
                    <td>{{ (componentName + '_' + item.code + '_' + 'desc' | translate) || "" }}</td>
                    <td>{{ item.warning }}</td>
                    <td>{{ item.success }}</td>
                    <td><span class="badge {{ getClassStatus(item.status) }} p-2">{{getStatus(item.status)}}</span></td>
                </tr>
            </tbody>
        </table>
    </div>
</section>

