<app-spinner></app-spinner>
<div class="landingpage">
    <div class="register">
        <section class="register-ss1">
            <img src="assets/img/home/v4/Image/Logo eContract_change color.png" alt=""
                class="img-logo-ipadpro">
            <div class="content col-12 p-0">
                <div class="row swap-col">
                    <div class="col-auto-new p-0">
                        <div class="row">
                            <div class="col-12">
                                <div class="content-left">
                                    <div class="row">
                                        <div class="col-left-auto">
                                            <div class="body">
                                                <span class="logo block">
                                                    MỘT SẢN PHẨM CỦA TẬP ĐOÀN
                                                    <img src="../../../assets/img/home/v4/Image/logo vnpt.png" alt="">
                                                </span>
                                                <p class="title">
                                                    Hợp đồng điện tử<br />
                                                    NBW eContract
                                                </p>
                                                <span class="dec">
                                                    Cung cấp dịch vụ hợp đồng điện tử cho phép các bên giao kết các loại hợp đồng
                                                    bằng phương tiện điện tử.
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-left-auto">
                                            <img src="assets/img/home/v4/Image/Group 177362.png" alt="" class="d-xl-block imgbg">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-auto-new p-0">
                        <div class="content-right">
                            <div class="row justify-content-center">
                                <div class="col-10 col-md-6 col-xl-9">
                                    <div class="body">
                                        <img src="assets/img/home/v4/Image/logo-techlab.png" alt="" class="img-logo">
                                        <h3 class="title">{{ 'Login_sign_in' | translate }}</h3>
                                       
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-10 col-md-6 col-xl-9">
                                    <!-- <label for="">khách hàng</label>
                                    <ul class="nav nav-tabs" role="tablist">
                                        <li class="nav-item">
                                            <a class="nav-link active" href="#profile" role="tab" data-toggle="tab">
                                                <img src="./icon/user.png" alt="">
                                                Cá nhân</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" href="#buzz" role="tab" data-toggle="tab">
                                                <img src="./icon/Group.png" alt="">
                                                Tổ chức</a>
                                        </li>
                                    </ul> -->
    
                                    <div class="login-form">
                                        <form [formGroup]="loginForm">
                                            <div class="form-group">
                                                <label for="">{{ 'Login_account' | translate }}<span class="text-danger">*</span></label>
                                                <input appTrimValue appLowercaseValue formControlName="email" type="text"
                                                    class="form-control" placeholder="{{ 'Login_account' | translate }}">
                                                <app-show-validate-errors [isSubmit]="isSubmit"
                                                    [errorMessages]="validateMsg.username" [control]="email"
                                                    [detail]="{ name: 'email' }"></app-show-validate-errors>
                                            </div>
                                            <div class="form-group">
                                                <label for="">{{ 'Login_password' | translate }}<span class="text-danger">*</span></label>
                                                <div class="d-block position-relative">
                                                    <input appInputPassword formControlName="password" type="password"
                                                        class="form-control" placeholder="{{ 'Login_password' | translate }}">
                                                </div>
                                                <app-show-validate-errors [isSubmit]="isSubmit"
                                                    [errorMessages]="validateMsg.password" [control]="password"
                                                    [detail]="{ name: 'password' }"></app-show-validate-errors>
                                                <p class="error text-danger" *ngIf="loginFailed && loginForm.valid">{{ 'Login_wrong_account_password' | translate }}</p>
                                            </div>
                                            <!-- <div class="row">
                                                <div class="col-12 d-flex mb-0">
                                                    <a class="text-primary align-self-center ml-auto"
                                                        [routerLink]="['/app/forgot-password']">{{ 'Login_forget_password' | translate }}</a>
                                                </div>
                                            </div> -->
                                            <button [disabled]="checkEdited" (click)="login()" type="submit"
                                                class="btn_reg">{{ 'Login_sign_in' | translate }}</button>
                                        </form>
                                        <div class="text-left pb-3">
                                            <span><a class="text-primary text-center text-decoration-underline"
                                                    [routerLink]="['/app/login-with-OTP']">{{ 'Login_redirect_login_with_OTP' | translate }}</a></span>
                                        </div>
                                        <!-- <div class="text-left">
                                            <span>{{ 'Login_not_yet_account' | translate }} <a class="text-primary text-center text-decoration-underline"
                                                    [routerLink]="['/app/signup']">{{ 'Login_sign_up' | translate }}</a></span>
                                        </div> -->
                                    </div>
                                    <!-- Tab panes -->
                                    <!-- <div class="tab-content">
                                    <div role="tabpanel" class="tab-pane active" id="profile">
                                         <div class="form-group">
                                            <label for="">Email/số điện thoại*<span class="red">*</span></label>
                                            <input type="password" class="form-control" placeholder="Email/số điện thoại">
                                        </div>
                                        <div class="form-group">
                                            <label for="">mật khẩu*<span class="red">*</span></label>
                                            <input type="password" class="form-control" placeholder="Mật khẩu">
                                        </div>
                                          <button type="button" class="btn_reg">Đăng nhập</button>
                                    
                                    </div>
                                    <div role="tabpanel" class="tab-pane fade" id="buzz">
                                         <div class="form-group">
                                            <label for="">Email/số điện thoại*<span class="red">*</span></label>
                                            <input type="password" class="form-control" placeholder="Email/số điện thoại">
                                        </div>
                                        <div class="form-group">
                                            <label for="">mật khẩu*<span class="red">*</span></label>
                                            <input type="password" class="form-control" placeholder="Mật khẩu">
                                        </div>
                                          <button type="button" class="btn_reg">Đăng nhập</button>
                                    </div>
                                </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
<a href="" class="btn-backhome">
    <img src="assets/img/home/v4/icon/backicon.png" alt="">
</a>