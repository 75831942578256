<app-spinner></app-spinner>
<div class="wrapper">
    <div class="preview-overlay down-index">

        <div class="preview-container">
            <div class="preview-header d-flex">
                <div (click)="goToBack()"
                    class="ct-header-btn-back d-flex justify-content-center align-items-center cursor-pointer">
                    <span *ngIf="!isExit; else isExit" class="material-icons">arrow_back_ios</span>
                    <ng-template #isExit>
                        <span class="material-icons">close</span>
                    </ng-template>
                </div>
                <div class="ct-title flex-grow-1 d-flex align-items-center px-3">
                    <!-- <h4 class="mb-0">VNPT eContract</h4> -->
                </div>
                <div *ngIf="isLogin()" class="ct-toolbar-btn d-flex align-items-center">
                    <img class="img-circle" src="assets/img/icon-avatar-default.png" width="30" height="30" />
                    <div ngbDropdown container="body" display="dynamic">
                        <a ngbDropdownToggle class="btn link-primary dropdown-toggle d-inline-flex align-items-center mr-3">
                            {{ getUsername }}
                        </a>
                        <div ngbDropdownMenu class="dropdown-menu">
                            <div ngbDropdownItem>
                                <div class="dropdown-item cursor-pointer d-flex p-0" [routerLink]="['/wv/user-settings']">
                                    {{ 'ProfileManager_change_password' | translate }}
                                </div>
                            </div>
                            <div class="dropdown-divider"></div>
                            <div ngbDropdownItem>
                                <div class="dropdown-item cursor-pointer d-flex p-0" (click)="wvLogout()">
                                    {{ 'logout' | translate }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <button class="btn btn-primary d-flex align-items-center mr-3" placement="left"
                                        ngbTooltip="Lưu và gửi hợp đồng cho các bên"><span class="material-icons mr-2">send</span> Gửi</button> -->
                </div>
            </div>

            <div class="preview-body">
                <div class="pv ct">
                    <div class="pv-body">
                        <div class="ctpv-container">
                            <div class="ctpv-content">
                                <div class="ct-container">
                                    <router-outlet></router-outlet>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>