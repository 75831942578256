import { AfterViewInit, Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/utils/services/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-aas-main',
  templateUrl: './aas-main.component.html',
  styleUrls: ['./aas-main.component.scss']
})
export class AasMainComponent implements OnInit, AfterViewInit {
  public sidebarMenuOpened = true;
  @ViewChild('contentWrapper', { static: false }) contentWrapper;

  public  path = '';
  public  authService: AuthService;
  public  activatedRoute: ActivatedRoute;

  constructor(
    private renderer: Renderer2
  ) {}

  ngOnInit() {}

  mainSidebarHeight(height) {
    // this.renderer.setStyle(
    //   this.contentWrapper.nativeElement,
    //   'min-height',
    //   height - 114 + 'px'
    // );
  }

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.resizeEvent();
    let self = this;
    window.addEventListener("resize", function() {
      if (document.body.clientWidth < 992) {
        document.body.classList.add("sidebar-collapse");
        document.body.classList.remove("sidebar-open");
        self.sidebarMenuOpened = false;
      }
    })
  }

  resizeEvent() {
    if (document.body.clientWidth < 992) {
      document.body.classList.add("sidebar-collapse");
      document.body.classList.remove("sidebar-open");
      this.sidebarMenuOpened = false;
    }
  }

  toggleMenuSidebar() {
    console.log('sidebarMenuCollapsed', this.sidebarMenuOpened);
    if (this.sidebarMenuOpened) {
      this.renderer.removeClass(document.body, 'sidebar-open');
      this.renderer.addClass(document.body, 'sidebar-collapse');
      this.sidebarMenuOpened = false;
    } else {
      this.renderer.removeClass(document.body, 'sidebar-collapse');
      this.renderer.addClass(document.body, 'sidebar-open');
      this.sidebarMenuOpened = true;
    }
  }

  logout() {
    this.path = this.activatedRoute.snapshot.url[0].path;
    this.authService.logout();
  }
}
