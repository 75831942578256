import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { EmployeeListItem } from 'src/app/utils/models/employee/employee-list-item.model';
import { EmployeeService } from 'src/app/utils/services/aas-network/employee/employee.service';
import { PartService } from 'src/app/utils/services/aas-network/part/part.service';

@Component({
  selector: 'app-employee-in-part',
  templateUrl: './employee-in-part.component.html',
  styleUrls: ['./employee-in-part.component.scss']
})
export class EmployeeInPartComponent implements OnInit {
  @Input('partyId') partyId: string = "";

  @Output() viewDetail = new EventEmitter();

  employees: EmployeeListItem[] = [];
  page = 1;
  maxSize = 10;
  propertiesSort = "createdDate";
  sort = "DESC";
  totalElement = 100;

  keySearch: string = "";
  status: string = "";
  partId: string = null;

  maxSizeDropDown = [10, 20, 30, 40];

  constructor(
    private router: Router,
    private partService: PartService,
    private employeeService: EmployeeService,
    public translate: TranslateService,

  ) { }

  ngOnInit(): void {
    this.viewDidLoad();
  }

  viewDidLoad() {
    this.loadPage(1);
  }

  reload() {
    this.loadPage(1);
  }

  getClassStatus(status) {
    var st = `${status}`;

    if (st === "1") return "badge-danger-new";
    if (st === "0") return "badge-success-new";
    return "";
  }

  getTextStatus(status) {
    var st = `${status}`;

    if (st === "1") return this.translate.instant('status_stopped');
    if (st === "0") return this.translate.instant('status_running');
    return "";
  }

  loadPage(page) {
    if (page > 0) {
      this.page = page;
      this.getListEmployeeInPart(this.partyId, this.page, this.maxSize, this.propertiesSort, this.sort, this.status, this.keySearch)
    }
  }

  detailEmployee(partyId) {
    this.viewDetail.emit(null);
    this.router.navigate([ConstantUrl.EMPLOYEE_DETAIL, partyId]);
  }
  
  //MARK: NETWOKR
  getListEmployeeInPart(partyId, page, maxSize, propertiesSort, sort, status, keySearch) {
    this.employeeService.getListEmployeeInPart(partyId, page, maxSize, propertiesSort, sort, status, keySearch).subscribe((res: any) => {
      this.employees = [];

      if (res.object && res.object.data && res.object.data instanceof Array) {
        res.object.data.forEach(item => {
          this.employees.push(new EmployeeListItem(item));
        });
      }

      this.totalElement = res?.object?.totalElement || 0;
    });
  }
}
