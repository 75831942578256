import { Injectable } from '@angular/core';
import { ServicePath } from '../../../common/constant-service-api';
import { API } from '../../api';
const STATS_BY_STATUS = ServicePath.REPORT_SERVICE + '/dashboard/eso/stage-contract?year=';
const STATS_BY_TIME= ServicePath.REPORT_SERVICE +'/dashboard/eso/time-contract?year='
const STATS_BY_PARTNER= ServicePath.REPORT_SERVICE +'/dashboard/eso/partner-number?year='
const USED_CAPACITY= ServicePath.REPORT_SERVICE +'/dashboard/eso/package-info'
const TYPE_CONTRACT= ServicePath.REPORT_SERVICE +'/dashboard/eso/rating-contract?type='
@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private api: API
  ) { }
  getContractStatsByStatus(year){
    const url = STATS_BY_STATUS + `${year}`;
    return this.api.get(url)
  }
  getContractStatsByTime(year){
    const url= STATS_BY_TIME +`${year}`
    return this.api.get(url)
  }
  getContractStatSByPartner(year){
    const url= STATS_BY_PARTNER +`${year}`
    return this.api.get(url)
  }
  getUsedCapacity(){
    const url= USED_CAPACITY;
    return this.api.get(url)
  }
  getContractByType(type){
    const url= TYPE_CONTRACT + `${type}`;
    return this.api.get(url)
  }
}
