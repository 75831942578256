import { Injectable } from '@angular/core';
import { ServicePath } from '../../common/constant-service-api';
import { API } from '../api';

const TEMPLATE_GROUP_LIST = ServicePath.TEMPATE_SERVICE + '/api/templates-groups/list';
const TEMPLATE_LIST = ServicePath.TEMPATE_SERVICE + '/api/templates/list';
const TEMPLATE_URL = ServicePath.TEMPATE_SERVICE + '/api/templates';
const CREATE_TEMPLATE_URL = ServicePath.TEMPATE_SERVICE + '/api/templates/';
const PREVIEW_URL = ServicePath.TEMPATE_SERVICE + '/api/templates/preview';
const TEMPLATE_LIST_ALL = ServicePath.TEMPATE_SERVICE + '/api/templates/all-templates';
const TEMPLATE_PREVIEW_BY_ID_URL = ServicePath.TEMPATE_SERVICE + '/api/templates';
const TEMPLATE_TYPES_URL = ServicePath.TEMPATE_SERVICE + '/api/types/all';
const TEMPLATE_GROUP = ServicePath.TEMPATE_SERVICE + '/api/templates-groups';
const TEMPLATE_GROUP_ALL = ServicePath.TEMPATE_SERVICE + '/api/templates-groups/all-templates';
const TEMPLATE_CONVERT_TO_PDF = ServicePath.TEMPATE_SERVICE + '/api/templates/convert-to-pdf';
const TEMPLATE_LIST_V2 = ServicePath.TEMPATE_SERVICE + '/api/templates/v2/list';
const UPLOAD_CONTRACT_TEMPLATE_URL = ServicePath.TEMPATE_SERVICE + '/api/templates/upload';
const TEMPLATE_LIST_MAP_MODEL = ServicePath.TEMPATE_SERVICE + '/api/templates/list-map-model';
const TEMPLATE_GET_FIELDS = ServicePath.TEMPATE_SERVICE + '/api/fields';
const DIEU_KHOAN = ServicePath.TEMPATE_SERVICE + '/api/templates/v1/dieu-khoan-su-dung';
const DROPLIST_TEMPLATE = ServicePath.TEMPATE_SERVICE + '/api/templates/v1/droplist-template';

@Injectable({
  providedIn: 'root'
})
export class TemplateService {

  constructor(private api: API) { }

  /**
   * Get list template
   * @param partyId 
   * @param page 
   * @param maxSize 
   * @param propertiesSort 
   * @param sort 
   */
  getListTemplate(partyId, page, maxSize, propertiesSort, sort) {
    const url = TEMPLATE_LIST + `?partyId=${partyId}&page=${page}&maxSize=${maxSize}&propertiesSort=${propertiesSort}&sort=${sort}`;
    return this.api.get(url);
  }

  /**
   * POST create template
   * @param groupId 
   * @param data 
   */
  postCreateTemplate(groupId, data) {
    console.log(data);
    const url = CREATE_TEMPLATE_URL + `?groupId=${groupId}`;
    return this.api.post(url, data);
  }

  /**
   * Get config Template
   * @param id 
   */
  getConfigTemplate(id) {
    const url = TEMPLATE_URL + `/${id}/all-config`;
    return this.api.get(url);
  }

  /**
   * POST upload template
   * @param id 
   * @param formData 
   */
  postUploadTempate(id, formData) {
    const url = TEMPLATE_URL + `/${id}/upload`;
    return this.api.postMultiPart(url, formData);
  }

  /**
   * POST kích hoạt template
   * @param id 
   */
  postActiveTemplate(id) {
    const url = TEMPLATE_URL + `/${id}/active`;
    return this.api.get(url);
  }

  /**
   * POST huỷ kích hoạt template
   * @param id 
   */
  postDeactiveTemplate(id) {
    const url = TEMPLATE_URL + `/${id}/deactive`;
    return this.api.get(url);
  }

  /**
   * DELETE Xoá Template
   * @param id 
   */
  deleteDeactiveTemplate(id) {
    const url = TEMPLATE_URL + `/${id}`;
    return this.api.delete(url);
  }

  /**
   * POST MULTI/PART preview
   * @param formData 
   */
  previewTemplate(formData) {
    return this.api.postMultiPart(PREVIEW_URL, formData);
  }

  /**
   * Get Preview Template by id
   * @param id 
   */
  getPreviewTemplateById(id) {
    const url = TEMPLATE_PREVIEW_BY_ID_URL + `/${id}/preview`;
    return this.api.download(url);
  }

  /**
   * Get drop list template
   * @param partyId 
   */
  getDropListTemplate(partyId) {
    const url = TEMPLATE_LIST_ALL + `?partyId=${partyId}`;
    return this.api.get(url);
  }

  /**
   * Get Droplist Template type
   */
  getDropListTemplateType(partyId) {
    const url = TEMPLATE_TYPES_URL + `?partyId=${partyId}`;
    return this.api.get(url);
  }

  /**
   * POST Render template with value
   * @param id 
   * @param data 
   */
  postRenderTemplate(id, data) {
    const url = TEMPLATE_URL + `/${id}/render`;
    return this.api.postDownload(url, data);
  }

  /**
   * PUT Update template
   * @param id 
   * @param data 
   */
  putUpdateTemplate(id, data) {
    const url = TEMPLATE_URL + `/${id}`;
    return this.api.putMultiPart(url, data);
  }

  /**
   * PUT Update template
   * @param id 
   * @param data 
   */
  putUpdateFieldTemplate(id, data) {
    const url = TEMPLATE_GET_FIELDS + `/${id}`;
    return this.api.put(url, data);
  }

  //MARK: Group template
  /**
   * Get list group template
   * @param partyId 
   * @param page 
   * @param maxSize 
   * @param propertiesSort 
   * @param sort 
   */
  getListGroupTemplate(partyId, page, maxSize, propertiesSort, sort, key = '', status = '') {
    const url = TEMPLATE_GROUP_LIST + `?partyId=${partyId}&page=${page}&maxSize=${maxSize}&propertiesSort=${propertiesSort}&sort=${sort}&searchString=${key}&status=${status}`;
    return this.api.get(url);
  }

  /**
   * GET Chi tiết template group
   * @param partyId 
   * @param id 
   */
  getDetailTemplateGroup(partyId = '', id) {
    const url = TEMPLATE_GROUP + `/${id}?partyId=${partyId}`;
    return this.api.get(url);
  }

  /**
   * GET Danh sách droplist template group
   * @param partyId 
   */
  getDropListTemplateGroup(partyId, status = '') {
    const url = TEMPLATE_GROUP_ALL + `?partyId=${partyId}&status=${status}`;
    return this.api.get(url);
  }

  /**
   * POST Tạo template group
   * @param data 
   */
  postCreateTemplateGroup(data) {
    const url = TEMPLATE_GROUP + '/';
    return this.api.post(url, data);
  }

  /**
   * POST kích hoạt template group
   * @param id 
   */
  postActiveTemplateGroup(id) {
    const url = TEMPLATE_GROUP + `/${id}/active`;
    return this.api.get(url);
  }

  /**
   * POST huỷ kích hoạt template group
   * @param id 
   */
  postDeactiveTemplateGroup(id) {
    const url = TEMPLATE_GROUP + `/${id}/deactive`;
    return this.api.get(url);
  }

  /**
   * Xoá template group
   * @param id 
   */
  deleteDeactiveTemplateGroup(id) {
    const url = TEMPLATE_GROUP + `/${id}`;
    return this.api.delete(url);
  }

  /**
   * PUT update Template group
   * @param id 
   * @param data 
   */
  putUpdateTemplateGroup(id, data) {
    const url = TEMPLATE_GROUP + `/${id}`;
    return this.api.putMultiPart(url, data);
  }

  /**
   * Lấy vị trí của biến trên pdf
   * @param id 
   * @param key 
   */
  getBoxOfKey(id, param) {
    const url = TEMPLATE_URL + "/" + id + "/bbox";
    return this.api.post(url, param);
  }

  /**
   * Request convert file docx sang pdf
   * @param data 
   */
  postConvertToPdf(data: FormData) {
    const url = TEMPLATE_CONVERT_TO_PDF;
    return this.api.postMultiPart(url, data);
  }

  /**
   * Get list template
   * @param partyId 
   * @param page 
   * @param maxSize 
   * @param propertiesSort 
   */
  getListTemplateV2(userId, partyId, page, maxSize, propertiesSort, keySearch, status, createdDate) {
    const url = TEMPLATE_LIST_V2 + `?userId=${userId}&partyId=${partyId}&page=${page}&maxSize=${maxSize}&propertiesSort=${propertiesSort}&keySearch=${keySearch}&status=${status}&createdDate=${createdDate}`;
    return this.api.get(url);
  }

  /**
   * POST upload template
   * @param id 
   * @param formData 
   */
  postUploadFileTempate(formData) {
    const url = UPLOAD_CONTRACT_TEMPLATE_URL;
    return this.api.postMultiPartJSON(url, formData);
  }

  getListMapModel() {
    const url = TEMPLATE_LIST_MAP_MODEL;
    return this.api.get(url);
  }

  postCreateTemplatev2(data: FormData) {
    const url = TEMPLATE_URL;
    return this.api.postMultiPartJSON(url, data);
  }

  getDetailTemplate(id: string) {
    const url = TEMPLATE_URL + `/${id}`;
    return this.api.get(url);
  }

  getFieldsTemplate(id: string) {
    const url = TEMPLATE_GET_FIELDS + `/${id}`;
    return this.api.get(url);
  }

  getFileTemplate(id: string) {
    const url = TEMPLATE_URL + `/${id}/preview`;
    return this.api.download(url);
  }

  /**
   * Lay thong tin dieu khoan su dung
   * @returns 
   */
  getDieuKhoan() {
    const url = DIEU_KHOAN;
    return this.api.download(url); 
  }

  getDropListTemplateV1(page, maxSize, searchString) {
    const url = DROPLIST_TEMPLATE + `?page=${page}&maxSize=${maxSize}&searchString=${searchString}`;
    return this.api.get(url);
  }

  getStockFileTemplate(id: string) {
    const url = TEMPLATE_URL + `/${id}/download`;
    return this.api.download(url);
  }
}
