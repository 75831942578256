import { Directive, Input, HostListener, ElementRef, forwardRef, Renderer2 } from '@angular/core';
import { DefaultValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[appLowercaseValue]'
})
export class LowercaseValueDirective {

  constructor(
    private renderer2: Renderer2,
    private eleRef: ElementRef<HTMLInputElement>
  ) { }

  @HostListener('input') onChange() {
    const {value} = this.eleRef.nativeElement;
    console.log(`Lower Case Directive :  ${value}`);
    this.renderer2.setProperty(this.eleRef.nativeElement, 'value', value.toLowerCase());
  }

}
