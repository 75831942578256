import { UtilsFunc } from "src/app/utils/common/utils-func";

export class SignatureItem {
    signId: string = '';
    userId: string = '';
    contractId: string = '';
    documentId: string = '';
    signForm: string = '';
    role: string = '';
    signHash: string = '';
    signDate: string = '';
    tranId: string = '';
    partyId: string = '';

    ekycInfo: any = null;
    identifierCode: any = null;
    name: any = null;
    pathImg: any = "";
    signType: any = "";
    status: any = "";
    taxCode: any = "";
    email: string = "";
    phone: string = "";
    provider: string = "";
    
    constructor(data = null) {
        data = data || {};
        this.signId = data.signId || '';
        this.userId = data.userId || '';
        this.documentId = data.documentId || '';
        this.contractId = data.contractId || '';
        this.signForm = data.signForm || '';
        this.role = data.role || '';
        this.signHash = data.signHash || '';
        this.signDate = data.signDate || '';
        this.tranId = data.tranId || '';
        this.partyId = data.partyId || '';
        this.ekycInfo = data.ekycInfo || null;
        this.identifierCode = this.convertJson(data.identifierCode) || ""; //UtilsFunc.isValidJSON(data.identifierCode) ? JSON.parse(data.identifierCode) : null;
        this.name = this.convertJson(data.name) || ""; //UtilsFunc.isValidJSON(data.name) ? JSON.parse(data.name) : null;
        this.signType = data.signType || "";
        this.status = data.status || "";
        this.taxCode = data.taxCode || "";
        this.email = data.email || "";
        this.phone = data.phone || "";
        this.provider = data.provider || "";
        this.pathImg = data.pathImg || "";
        
        this.progressEKycInfo();
    }

    /**
     * Xỷ lý ekycInfo đưa vè dạng full json
     */
    progressEKycInfo() {
        // console.log(this.ekycInfo);
        if (this.ekycInfo == null || this.ekycInfo == undefined || this.ekycInfo == {}) return;
        if (this.ekycInfo instanceof Object) {
            Object.keys(this.ekycInfo).forEach((x) => {
                if ((typeof this.ekycInfo[x] === 'string' || this.ekycInfo[x] instanceof String) && UtilsFunc.isValidJSON(this.ekycInfo[x])) {
                    this.ekycInfo[x] = JSON.parse(this.ekycInfo[x]);
                }
            })
        }
    }

    /**
     * Lấy thông tin url ảnh chữ ký ekyc
     * @returns 
     */
    getSignImage() {
        return this.pathImg ?? "";
        if (this.signForm == "EKYC" || this.signForm == "EKYC_EMAIL" ) {
            return this.pathImg ?? "";
            return this.ekycInfo?.pathImg?.signImg1 ?? "";
        }
        return "";
    }

    /**
     * Chuyển thông tin string thành json nếu là jsonstring
     * @param jsonStr 
     * @returns 
     */
    convertJson(jsonStr) {
        try {  
            const json = JSON.parse(jsonStr);  
            return json;
        } catch (e) {  
            return jsonStr;
        }
    }
}