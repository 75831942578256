import { MainResponse } from "../main-response.model";
import { LandingConfigItem } from "./landing-config-item.model";

export class LandingConfigResponse extends MainResponse{
  constructor(obj = null) {
    super();
    obj = obj || {};
    this.init(obj);
    if (obj.object && obj.object instanceof Array) {
        obj.object.forEach((item) => {
            this.object = new LandingConfigItem(item);
        })
    }
 }
}