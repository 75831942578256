<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-auto">
                <h1 class="m-0 text-dark"><label>{{ 'EnduserPackagesManager_title' | translate}}</label></h1>
            </div>
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>

<section class="content">
    <div class="container-fluid pb-3">
        <app-package-noti-block [screen]="'ENDUSER_PACKAGE_MANAGER'"></app-package-noti-block>

        <div class="row">

            <div *ngFor="let package of packages" class="col-xl-3 col-lg-4 col-md-6 mb-3">
                <div class="card package-wg h-100 mb-0">
                    <div class="card-body d-flex flex-column">
                        <div class="d-flex">
                            <label class="flex-grow-1">{{ package.packageInfo.name }}</label>
                            <span *ngIf="package.statusPackage === 'APPROVE'" 
                            class="badge badge-success-new p-2 ml-auto d-flex align-items-center" style="height: fit-content;">{{ 'EnduserPackagesManager_inuse' | translate}}</span>
                            <span *ngIf="package.statusPackage === 'WAIT'" class="badge badge-warning-new d-flex align-items-center p-2" style="height: fit-content;">{{ 'EnduserPackagesManager_waitActive' | translate}}</span>
                        </div>

                        <div class="package-desc">
                            <p>
                                {{ package.packageInfo.description }}. 
                                <a class=" text-decoration-underline text-primary cursor-pointer" (click)="goToDetail(package.packageInfo.packInfoId, package.statusPackage)">{{ 'EnduserPackagesManager_viewDetails' | translate}}</a>
                            </p>
                        </div>

                        <div class="package-info mt-auto">
                            <ul>
                                <li class="d-flex align-items-center"><span class="material-icons checked" style="font-size: 25px;">check_circle</span> {{ 'EnduserPackagesManager_amount' | translate}}: {{ package.packageInfo.contractNumber }}</li>
                                <li class="d-flex align-items-center"><span class="material-icons checked" style="font-size: 25px;">check_circle</span> {{ 'EnduserPackagesManager_duration' | translate}}: {{ package.packageInfo.duration }}</li>
                            </ul>
                        </div>

                        <div class="package-price">
                            <h4 class="text-primary"><label>{{ package.packageInfo.price }}{{ 'EnduserPackagesManager_unit' | translate}}</label></h4>
                        </div>
                        <!-- {{ package.packageInfo.packInfoId === partyInfo.packageTempId ? 'Gói cước đã chọn' : 'Chọn gói' }} -->
                        <!-- isWaitting === true ||  package.statusPackage === 'APPROVE'   -->
                        <button class="btn btn-lg btn-primary w-100" [disabled]="isWaitting === true ||  package.statusPackage === 'APPROVE'" (click)="selectPackage(package.packageInfo.packInfoId,package.packageInfo.name)">{{ 'EnduserPackagesManager_choosePackage' | translate}}</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>