import { Injectable } from '@angular/core';
import { ServicePath } from '../../common/constant-service-api';
import { API } from "../api";

const LIST_ACCOUNT_EINVOICES = ServicePath.EPAYMENT_SERVICE + '/api/invoicecustomer?';
const DETAIL_EINVOICES = ServicePath.EPAYMENT_SERVICE + '/api/invoice/get-view-invoice-nopay/';
const CREATE_EINVOICES = ServicePath.EPAYMENT_SERVICE + '/api/invoice/publish-invoice/';
const CREATE_CUSTOMER_ACC_EINVOICES = ServicePath.EPAYMENT_SERVICE + '/api/invoicecustomer/';
const DETAIL_CUSTOMER_ACC_EINVOICES = ServicePath.EPAYMENT_SERVICE + '/api/invoicecustomer/';
const DELETE_CUSTOMER_ACC_EINVOICES = ServicePath.EPAYMENT_SERVICE + '/api/invoicecustomer/';
const INFO_SELLER_PARTY = ServicePath.MANAGEMENT_SERVICE + '/user-informations/';
const LIST_SERIES_URL = ServicePath.EPAYMENT_SERVICE + '/api/invoicecustomer/get-all-by-user';
const LIST_PAYMENT_METHOD_URL = ServicePath.EPAYMENT_SERVICE + '/api/invoice/get-payment-method';
const LISST_EINVOICES_URL = ServicePath.EPAYMENT_SERVICE + '/api/invoice?';
const INFO_EINVOICES_URL = ServicePath.EPAYMENT_SERVICE + '/api/invoice/get-info-invoice/';
const STATUS_EINVOICES_URL = ServicePath.EPAYMENT_SERVICE + '/api/invoice/get-payment-status';
const DOWNLOAD_EINVOICES_URL = ServicePath.EPAYMENT_SERVICE + '/api/invoice/dowload-invoice/';
const PAYMENT_EINVOICES_URL = ServicePath.EPAYMENT_SERVICE + '/api/invoice/payment-status/';
const SEND_EMAIL_URL = ServicePath.EPAYMENT_SERVICE + '/api/invoice/email-to-client/';

@Injectable({
    providedIn: 'root'
  })
  
  export class AasEinvoicesService {
  
    constructor(
      private api: API
    ) { }

    /**
   * GET list mẫu số
   */
    getListSeries(){
      const url = LIST_SERIES_URL  
      return this.api.get(url)
    }

    /**
   * GET Request list trạng thái hóa đơn
   */
    getListStatusEinvoices(){
      const url = STATUS_EINVOICES_URL 
      return this.api.get(url)
    }

    /**
   * GET Request list hình thức thanh toán
   */
    getListPaymentMethod(){
      const url = LIST_PAYMENT_METHOD_URL 
      return this.api.get(url)
    }

   /**
   * GET Request danh sách hóa đơn điện tử
   * @param page 
   *  @param size 
   */
    getListEinvoices(page = 1, size = 10,sort='DESC',propertiesSort='modifiedOn',fromDate='',toDate='',searchString='',paymentStatus=''){
      const url = LISST_EINVOICES_URL + `page=${page}&maxSize=${size}&sort=${sort}&propertiesSort=${propertiesSort}&fromDate=${fromDate}&toDate=${toDate}&searchString=${searchString}&paymentStatus=${paymentStatus}` 
      return this.api.get(url)
    }

   /**
   * GET Request thông tin chi tiết hóa đơn điện tử
   * @param uuid 
   */
    getEinvoiceInfoDetail( uuid){
    const url = INFO_EINVOICES_URL + `${uuid}` 
    return this.api.get(url)
    }

    /**
   * GET Request danh sách taif khoanr hóa đơn điện tử
   * @param page 
   *  @param size 
   */
    getAccountList(page = 1, size = 10,sort='DESC',propertiesSort='companyName'){
        const url = LIST_ACCOUNT_EINVOICES + `page=${page}&maxSize=${10}&sort=${sort}&propertiesSort=${propertiesSort}` 
        return this.api.get(url)
    }

    /**
   * GET Request chi tiết hóa đơn điện tử
   * @param taxCode
   * @param uuid 
   */
    downloadFileWithHeader(taxCode, uuid){
      const url = DETAIL_EINVOICES + `${taxCode}/${uuid}` 
      return this.api.download(url)
    }

    /**
   * Tải xuống file hợp đồng
   * @param id 
   * @param type 
   */
    downloadFileEinvoices(taxCode, uuid) {
      const url = DETAIL_EINVOICES + `${taxCode}/${uuid}`;
      return this.api.download(url);
    }

    /**
   * POST Request tạo hóa đơn điện tử
   * @param data
   */
    postCreateEinvoice(data, taxCode){
    const url = CREATE_EINVOICES + `${taxCode}`
    return this.api.post(url,data)
    }

    /**
   * POST Request tạo taif khoanr hóa đơn điện tử
   * @param data
   */
    postCreateAccEinvoice(data){
    const url = CREATE_CUSTOMER_ACC_EINVOICES 
    return this.api.post(url,data)
    }

    /**
   * GET Request chi tiết tài khoản hóa đơn điện tử
   * @param uuid 
   */
    getAccEinvoiceDetail(uuid){
    const url = DETAIL_CUSTOMER_ACC_EINVOICES + `${uuid}` 
    return this.api.get(url)
    }

    /**
   * POST Request tạo taif khoanr hóa đơn điện tử
   * @param data
   */
    putUpdateCustomer(data){
    const url = DELETE_CUSTOMER_ACC_EINVOICES 
    return this.api.put(url,data)
    }

   /**
   * DELETE Request xóa tài khoản hóa đơn điện tử
   * @param uuid
   */
   deleteCustomer(uuid){
    const url = CREATE_CUSTOMER_ACC_EINVOICES + `${uuid}`
    return this.api.delete(url)
    }

    /**
     * GET Request thông tin đơn vị bán hàng
     * @param partyId
     */
    getInfoSeller(partyId){
      const url = INFO_SELLER_PARTY + `${partyId}/org-info`
      return this.api.get(url)
    }

    /**
     * PUT Request cập nhập trạng thái hóa đơn
     * @param partyId
     */
    putStatusEinvoices(data,uuid){
      const url = PAYMENT_EINVOICES_URL + `${uuid}`;
      return this.api.put(url,data)
    }

    /**
     * POST Request sent email hóa đơn
     * @param partyId
     */
    postSendEmail(invoiceId, data){
      const url = SEND_EMAIL_URL + `${invoiceId}`;
      return this.api.post(url,data)
    }

    /**
     * DOWNLOAD Request download hóa đơn
     * @param partyId
     */
    downloadEinvoices( cusCode,fkey){
      const url = DOWNLOAD_EINVOICES_URL + `${cusCode}/${fkey}`;
      return this.api.download(url)
    }


}