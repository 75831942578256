<app-spinner></app-spinner>
<div class="wrapper">
    <div class="preview-overlay">

        <div class="preview-container">
            <div class="preview-header d-flex">
                <div (click)="goToBack()"
                    class="ct-header-btn-back d-flex justify-content-center align-items-center cursor-pointer">
                    <span class="material-icons">arrow_back_ios</span>
                </div>
                <div class="ct-title flex-grow-1 d-flex align-items-center px-3">
                    <h4 class="mb-0"> {{ contractId !== "" && contractId !== null ? "Chỉnh sửa hợp đồng" : "Tạo hợp đồng" }}</h4>
                </div>
                <div class="ct-toolbar-btn d-flex align-items-center">
                    <!-- <button (click)="saveAsContract()"
                        class="btn btn-outline-primary d-flex align-items-center mr-3"><span
                            class="material-icons mr-2">get_app</span> Tải xuống</button> -->
                    <button (click)="actCancelBtn($event)"
                        class="btn btn-outline-primary d-flex align-items-center mr-3"><span
                            class="material-icons mr-2">clear</span> Hủy</button>
                    <button (click)="actBtnSave($event)" [disabled]="editedForm"
                        class="btn btn-outline-primary d-flex align-items-center mr-3"><span
                            class="material-icons mr-2">save</span> Lưu</button>
                    <button *ngIf="allowSendContract" (click)="saveAndSendContract()"
                            class="btn btn-primary d-flex align-items-center mr-3" placement="left" ngbTooltip="Lưu và gửi hợp đồng cho các bên"><span
                                class="material-icons mr-2">send</span> Gửi</button>
                </div>
            </div>

            <div class="preview-body">
                <div class="pv ct">
                    <div class="pv-body">
                        <div class="ctpv-container">
                            <div class="ctpv-content">
                                <div class="ct-container">
                                    <div class="ct-thumb-page">
                                        <div class="ct-vs ct-enable-scroll">
                                            <ol id="thumbnail-page" class="ct-vs-list mt-3"
                                                [class.d-none]="typeSelected !== 1">
                                            </ol>
                                        </div>
                                    </div>
                                    <div id="ppi"
                                        style="height: 1in; width: 1in; position: absolute; top: -100%; left: -100%;">
                                    </div>
                                    <div class="ct-content">
                                        <div id="parentView" class="ct-doc-loaded ct-enable-scroll"
                                            [class.d-none]="typeSelected !== 1">

                                        </div>

                                        <div class="ct-doc-loaded ct-enable-scroll" [class.d-none]="typeSelected !== 2">
                                            <ckeditor #myEditor [editor]="Editor" data="<p>Nội dung hợp đồng!</p>">
                                            </ckeditor>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="ct ctpv-tool-bar">
                            <div class="ctpv-tool-sidebar">
                                <div class="ctpv-tabbar">
                                    <div class="ctpv-main">
                                        <button (click)="selectTab('INFO')" class="ctpv-btn"
                                            [class.active]="activeTab == 'INFO'"> <span
                                                class="material-icons-outlined">info</span></button>
                                        <!-- <button (click)="selectTab('GESTURE')" class="ctpv-btn"
                                            [class.active]="activeTab == 'GESTURE'"> <span
                                                class="material-icons">gesture</span></button> -->
                                    </div>
                                </div>
                                <div class="ctpv-footer">

                                </div>
                            </div>
                            <div class="ctpv-tool-content">
                                <div class="ctpv-content-wrapper px-3">
                                    <div id="tab-info" class="ctpv-scroll-content"
                                        [class.d-none]="!(activeTab === 'INFO')">
                                        <h5 class="mt-3">Thông tin hợp đồng</h5>

                                        <form [formGroup]="formContract">
                                            <div class="row mt-4">
                                                <div class="col-sm-4">
                                                    <div class="rcheck-primary d-inline">
                                                        <input disabled type="radio" name="type" id="rr0">
                                                        <label for="rr0" (click)="selectType(0)">
                                                            Tạo hợp đồng từ mẫu
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-4">
                                                    <div class="rcheck-primary d-inline">
                                                        <input checked type="radio" name="type" id="rr1">
                                                        <label for="rr1" (click)="selectType(1)">
                                                            Tải file đính kèm
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="col-sm-4">
                                                    <div class="rcheck-primary d-inline">
                                                        <input type="radio" name="type" id="rr2">
                                                        <label for="rr2" (click)="selectType(2)">
                                                            Soạn hợp đồng trực tiếp
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row mt-3">
                                                <div class="col-sm-12">
                                                    <div class="form-group mb-0">
                                                        <label>Tên hợp đồng <span class="text-danger">*</span>: </label>
                                                        <input formControlName="tenHopDong" class="form-control"
                                                            type="text" placeholder="Nhập tên hợp đồng">

                                                        <app-show-validate-errors [isSubmit]="isSubmitContract"
                                                            [errorMessages]="validContractMsg.tenHopDong"
                                                            [control]="formContract.controls.tenHopDong"
                                                            [detail]="{ name: 'tenHopDong' }">
                                                        </app-show-validate-errors>
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="typeSelected === 0" class="row mt-3">
                                                <div class="col-md-12">
                                                    <div class="form-group mb-3">
                                                        <label>Tạo hợp đồng từ mẫu <span
                                                                class="text-danger">*</span>:</label>
                                                        <ng-select2 [data]="contractTemplateData"
                                                            [options]="optionsContractTemplate"
                                                            [placeholder]="'Chọn hợp đồng mẫu...'">
                                                        </ng-select2>
                                                    </div>
                                                </div>
                                            </div>

                                            <div *ngIf="typeSelected === 1" class="row mt-3">
                                                <div class="col-sm-12">
                                                    <div class="form-group mb-3">
                                                        <input #output id="output" class="" value="" type="hidden">
                                                        <input #filebase id="fileBase" class="" type="hidden">
                                                        <input #filename id="fileName" class="" value="{{ fileName }}"
                                                            type="hidden">
                                                        <label>File đính kèm <span class="text-danger">*</span>:</label>
                                                        <div class="input-group mb-1">
                                                            <div class="custom-file custom-file-icon">
                                                                <span
                                                                    class="icon-file material-icons">attach_file</span>
                                                                <input [attr.disabled]="typeSelected !== 1 ? '' : null"
                                                                    formControlName="file"
                                                                    (input)="handleFileInput($event)" type="file"
                                                                    accept=".pdf,.docx" class="custom-file-input"
                                                                    id="inputGroupFile01"
                                                                    aria-describedby="inputGroupFileAddon01">
                                                                <label class="custom-file-label"
                                                                    for="inputGroupFile01">{{ inputFileLabel }}</label>
                                                            </div>
                                                        </div>
                                                        <app-show-validate-errors [isSubmit]="isSubmitContract"
                                                            [errorMessages]="validContractMsg.file"
                                                            [control]="formContract.controls.file"
                                                            [detail]="{ name: 'file' }"></app-show-validate-errors>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row mt-3">
                                                <div class="col-xl-6">
                                                    <div class="form-group">
                                                        <label>Hợp đồng có thời hạn từ ngày:
                                                        </label>
                                                        <div class="input-group">
                                                            <!-- formControlName="ngayHieuLuc" [value]="formContract.value.ngayHieuLuc" -->
                                                            <input id="ngayHieuLuc" class="form-control"
                                                                [control]="formContract.controls.ngayHieuLuc"
                                                                [value]="formContract.value.ngayHieuLuc"
                                                                (dateSelect)="changeDate($event, dateType.ISSUE)"
                                                                placeholder="dd/MM/yyyy" ngbDatepicker
                                                                #ngayHieuLucContract="ngbDatepicker"
                                                                [minDate]="{year: currentDate.getFullYear(), month: currentDate.getMonth()+1, day: currentDate.getDate()}"
                                                                appInputMark [options]="optionsCleave">
                                                            <div class="input-group-append">
                                                                <button class="btn btn-outline-secondary"
                                                                    (click)="ngayHieuLucContract.toggle()"
                                                                    type="button"><em
                                                                        class="fas fa-calendar-alt"></em></button>
                                                            </div>
                                                        </div>
                                                        <app-show-validate-errors [isSubmit]="isSubmitContract"
                                                            [errorMessages]="validContractMsg.ngayHieuLuc"
                                                            [control]="formContract.controls.ngayHieuLuc"
                                                            [detail]="{ name: 'ngayHieuLuc' }">
                                                        </app-show-validate-errors>
                                                    </div>
                                                </div>

                                                <div class="col-xl-6">
                                                    <div class="form-group">
                                                        <label>Hợp đồng có thời hạn đến ngày:
                                                        </label>
                                                        <div class="input-group">
                                                            <!-- formControlName="ngayHetHieuLuc" [value]="formContract.value.ngayHetHieuLuc" -->
                                                            <input id="ngayHetHieuLuc" class="form-control"
                                                                [control]="formContract.controls.ngayHetHieuLuc"
                                                                [value]="formContract.value.ngayHetHieuLuc"
                                                                (dateSelect)="changeDate($event, dateType.EXPRIRED)"
                                                                placeholder="dd/MM/yyyy" ngbDatepicker
                                                                #ngayHetHieuLucContract="ngbDatepicker"
                                                                [minDate]="{year: currentDate.getFullYear(), month: currentDate.getMonth()+1, day: currentDate.getDate()}"
                                                                appInputMark [options]="optionsCleave">
                                                            <div class="input-group-append">
                                                                <button class="btn btn-outline-secondary"
                                                                    (click)="ngayHetHieuLucContract.toggle()"
                                                                    type="button"><em
                                                                        class="fas fa-calendar-alt"></em></button>
                                                            </div>
                                                        </div>
                                                        <app-show-validate-errors [isSubmit]="isSubmitContract"
                                                            [errorMessages]="validContractMsg.ngayHetHieuLuc"
                                                            [control]="formContract.controls.ngayHetHieuLuc"
                                                            [detail]="{ name: 'ngayHetHieuLuc' }">
                                                        </app-show-validate-errors>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-xl-6">
                                                    <div class="form-group">
                                                        <label>Tự động gia hạn hợp đồng <span
                                                                class="text-danger">*</span>: </label>
                                                        <select formControlName="tuGiaHan" class="form-control">
                                                            <option value="true">Có</option>
                                                            <option value="false">Không</option>
                                                        </select>
                                                        <app-show-validate-errors [isSubmit]="isSubmitContract"
                                                            [errorMessages]="validContractMsg.tuGiaHan"
                                                            [control]="formContract.controls.tuGiaHan"
                                                            [detail]="{ name: 'tuGiaHan' }">
                                                        </app-show-validate-errors>
                                                    </div>
                                                </div>

                                                <div class="col-xl-6">
                                                    <div class="form-group">
                                                        <label>Giá trị hợp đồng:</label>
                                                        <input id="contractValueInput" formControlName="giaTriHd"
                                                            class="form-control" type="text"
                                                            placeholder="Nhập giá trị hợp đồng" appInputMark
                                                            [options]="optionsCleaveCost">
                                                        <app-show-validate-errors [isSubmit]="isSubmitContract"
                                                            [errorMessages]="validContractMsg.giaTriHd"
                                                            [control]="formContract.controls.giaTriHd"
                                                            [detail]="{ name: 'giaTriHd' }"></app-show-validate-errors>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="form-group">
                                                        <label>Đàm phán có hợp đồng <span
                                                            class="text-danger">*</span>:</label>
                                                        <div class="row">
                                                            <div class="col-sm-4">
                                                                <div class="rcheck-primary d-inline">
                                                                    <input [attr.disabled]="disableDiscussStatus ? '' : null"
                                                                        [attr.checked]="formContract.value.discuss ? '' : null"
                                                                        type="radio" name="discuss" id="discuss0">
                                                                    <label #check_have_discuss for="discuss0" (click)="changeDiscussStatus(true)">
                                                                        Có đàm phán
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-4">
                                                                <div class="rcheck-primary d-inline">
                                                                    <input [attr.disabled]="disableDiscussStatus ? '' : null"
                                                                        [attr.checked]="!formContract.value.discuss ? '' : null"
                                                                        type="radio" name="discuss" id="discuss1">
                                                                    <label #check_non_discuss for="discuss1" (click)="changeDiscussStatus(false)">
                                                                        Không đàm phán
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="row mt-4">
                                                <div class="col-xl-12">
                                                    <div class="form-group">
                                                        <label>Ghi chú: </label>
                                                        <input formControlName="ghiChu" class="form-control" type="text"
                                                            placeholder="Nhập nội dung ghi chú">
                                                        <app-show-validate-errors [isSubmit]="isSubmitContract"
                                                            [errorMessages]="validContractMsg.ghiChu"
                                                            [control]="formContract.controls.ghiChu"
                                                            [detail]="{ name: 'ghiChu' }"></app-show-validate-errors>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="d-flex">
                                                <label class="text-primary flex-grow-1">Bên tham gia hợp đồng</label>
                                                <a (click)="addPartyJoin()"
                                                    class="cursor-pointer text-primary text-decoration-underline ml-auto">
                                                    <span>Chỉnh sửa</span>
                                                </a>
                                            </div>
                                            <hr>
                                            <div class="list-party">
                                                <div *ngFor="let item of partiesFormArray.value" class="row">
                                                    <div class="col-md-6">
                                                        <label>Bên tham gia</label>
                                                        <p>{{ item.tenToChuc || item.tenDaiDien || item.email }}</p>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <label>Loại khách hàng</label>
                                                        <p *ngIf="item.userType === userTypeConst.CONSUMER">Cá nhân</p>
                                                        <p *ngIf="item.userType === userTypeConst.BUSINESS">Tổ chức</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<app-party-join *ngIf="showPartyJoin" [partyInfo]="partyInfo" [partiesJoin]="partiesJoin"
    [partiesFormArray]="partiesFormArray" (closed)="closedPartyJoinModal($event)" (changed)="changePartyJoin($event)">
</app-party-join>