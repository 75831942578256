import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-footer',
  templateUrl: './home-footer.component.html',
  styleUrls: ['./home-footer.component.scss']
})
export class HomeFooterComponent implements OnInit {
  @Input('data')
  set data(value) { this._data = value; };
  get data() { return this._data };
  _data = null;

  constructor() { }

  ngOnInit(): void {
  }

}
