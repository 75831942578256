export class ContractTemplate {
    dateCreate: string = "";
    fileName: string = "";
    fullName: string = "";
    id: string = "";
    isUsed: any;
    products: any = null;
    status: string = "";
    templateFields: string = "";
    templateName: string = "";
    templatePath: string = "";
    templateType: string = "";
    username: string = "";

    constructor(d = null) {
        d = d || {};
        this.dateCreate = d.dateCreate || "";
        this.fileName = d.fileName || "";
        this.fullName = d.fullName || "";
        this.id = d.id || "";
        this.isUsed = d.isUsed;
        this.products = d.products || [];
        this.status = d.status || "";
        this.templateFields = d.templateFields || "";
        this.templateName = d.templateName || "";
        this.templatePath = d.templatePath || "";
        this.templateType = d.templateType || "";
        this.username = d.username || "";
    }

}