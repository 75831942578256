<app-spinner></app-spinner>
<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0 text-dark"><label>Người dùng tổ chức</label></h1>
            </div>
        </div>
    </div>
</div>

<!--MARK top bar -->
<section class="content">
    <div class="container-fluid">
        <!-- <div class="row">
                <div class="col-md-24"> -->
        <div class="container-fluid">
            <div class="content-right-sidebar tab-bar-border-bottom border-tab mb-4">
                <ul class="nav nav-tabs" id="tabSignAction" role="tablist">
                    <li class="nav-item nav-material">
                        <a class="nav-link d-flex active" id="waiting-tab" data-toggle="pill" href="#waiting" role="tab"
                            aria-controls="waiting" aria-selected="true">
                            <span class="material-icons">query_builder</span>
                            Chờ phê duyệt
                        </a>
                    </li>
                    <li class="nav-item nav-material">
                        <a class="nav-link d-flex" id="approved-tab" data-toggle="pill" href="#approved" role="tab"
                            aria-controls="approved" aria-selected="false">
                            <span class="material-icons">check_circle_outline</span>
                            Đã phê duyệt
                        </a>
                    </li>

                    <li class="nav-item nav-material">
                        <a class="nav-link d-flex" id="reject-tab" data-toggle="pill" href="#reject" role="tab"
                            aria-controls="reject" aria-selected="false">
                            <span class="material-icons">cancel_outline</span>
                            Từ chối phê duyệt
                        </a>
                    </li>
                </ul>
            </div>
        </div>
        <!-- </div>
            </div> -->

        <div class="tab-content contract-tab-body" id="tabSignAction">
            <!--MARK chữ kí số USB-Token -->
            <div class="tab-pane fade show active " id="waiting" role="tabpanel" aria-labelledby="waiting">
                <app-enterprise-vip-waiting #waiting (reload)="onReload($event)"></app-enterprise-vip-waiting>
            </div>

            <!--MARK chữ kí sign-server  -->
            <div class="tab-pane fade" id="approved" role="tabpanel" aria-labelledby="approved">
                <app-enterprise-vip-approved #approved></app-enterprise-vip-approved>
            </div>
            <div class="tab-pane fade" id="reject" role="tabpanel" aria-labelledby="reject">
                <!-- <app-reject-enterprise></app-reject-enterprise> -->
                <app-enterprise-vip-rejected #rejected></app-enterprise-vip-rejected>
            </div>
        </div>
    </div>
</section>


<!-- ====================================
    Chưa có thông tin
==================================== -->
<ng-template #emptyBlock>
    <div class="no-content-list">
        <img
            class="mb-2"
            src="assets/img/icon/icon-no-user-group.png"
            width="100"
        />
        <p class="mb-1">
            Không có dữ liệu.
        </p>
    </div>
</ng-template>