export class RequestPreoderEmployee {
    birthDate: string = "";
    currentPlace: string = "";
    department: string = "";
    email: string = "";
    firstName: string = "";
    gender: string = "";
    id: any = null;
    identityNo: string = "";
    identityTypeName: string = "";
    isAdmin: any;
    isCreated: any = 0;
    lastName: string = "";
    name: string = "";
    nationalityName: string = "";
    phoneNumber: string = "";
    placeOfHouse: string = "";
    roles: string = "";

    constructor(d = null) {
        d = d || {};
        this.birthDate = d.birthDate || "";
        this.currentPlace = d.currentPlace || "";
        this.department = d.department || "";
        this.email = d.email || "";
        this.firstName = d.firstName || "";
        this.gender = d.gender || "";
        this.id = d.id;
        this.identityNo = d.identityNo || "";
        this.identityTypeName = d.identityTypeName || "";
        this.isAdmin = d.isAdmin;
        this.isCreated = d.isCreated;
        this.lastName = d.lastName || "";
        this.name = d.name || "";
        this.nationalityName = d.nationalityName || "";
        this.phoneNumber = d.phoneNumber || "";
        this.placeOfHouse = d.placeOfHouse || "";
        this.roles = d.roles || "";
    }

}