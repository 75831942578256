export class ListUserGroupItem {
    public id: string = "";
    public uuidRole: string = "";
    public name: string = "";
    public description: string = "";
    public applicationName: string = "";
    public roleCode: string = "";
    public disable: any = 0;
    public idParant: string = "";
    public modifiedDate: string = "";

    constructor(obj = null) {
        obj = obj || {};
        this.id = obj.uuidRole || "";
        this.uuidRole = obj.uuidRole || "";
        this.name = obj.name || "";
        this.description = obj.description || "";
        this.applicationName = obj.applicationName || "";
        this.roleCode = obj.roleCode || "";
        this.disable = Number(obj.disable);
        this.idParant = obj.idParent || "";
        this.modifiedDate = obj.modifiedDate || "";
    }
}