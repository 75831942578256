import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  ElementRef,
  Renderer2
} from '@angular/core';
import { Router } from '@angular/router';
import { ConstLocalStorage, NotificationType } from 'src/app/utils/common/constant';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { ControlAccount } from 'src/app/utils/common/control-account';
import { ListNotification } from 'src/app/utils/models/aas-models/notifications/list-notification.model';
import { Notification } from 'src/app/utils/models/aas-models/notifications/notification.model';
import { AuthLoginResponse } from 'src/app/utils/models/auth/auth-login/auth-login-response.model';
import { FirebaseService } from 'src/app/utils/services/aas-network/firebase/firebase.service';
import { NotificationsService } from 'src/app/utils/services/aas-network/notifications.service';
import { AuthService } from 'src/app/utils/services/auth.service';
import { LoadingService } from 'src/app/utils/services/loading.service';
import { ToastControl } from 'src/app/utils/toastr/toast-control';

@Component({
  selector: 'app-notifications-dropdown-menu',
  templateUrl: './notifications-dropdown-menu.component.html',
  styleUrls: ['./notifications-dropdown-menu.component.scss']
})
export class NotificationsDropdownMenuComponent implements OnInit {
  @ViewChild('dropdownMenu', { static: false }) dropdownMenu;
  @ViewChild('listnoti', { static: false }) listnoti;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.hideDropdownMenu();
      this.showScrollbarNotiList();
    }
  }
  
  notiType = NotificationType;
  notifications: Array<Notification> = [];
  page = 1;
  maxSize = 10;
  sort = 'DESC';
  propertiesSort = 'sentAt';
  totalElement = 0;

  countNoti: number = 0;

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private router: Router,
    private notiService: NotificationsService,
    private firebaseService: FirebaseService,
    public loadingService: LoadingService,
    private controlAccount: ControlAccount,
    private auth: AuthService,
    private myToast: ToastControl) { }

  ngOnInit() {
    this.receiveMessageCall();
    this.loadPage(this.page);
    this.getNumberUnread();
  }

  showLoader() {
    this.loadingService.disabledLoader(true);
  }

  hideLoader() {
    this.loadingService.disabledLoader(false);
  }

  checkFinishedScroll() {
    let page = this.page + 1;
    let countPage = page * this.maxSize;
    if (countPage > this.totalElement && (countPage - this.totalElement > this.maxSize)) {
      return;
    } else {
      this.loadPage(page);
    }
  }

  receiveMessageCall() {
    this.firebaseService.currentMessage.subscribe((message: any) => {
      if (message) {
        // this.myToast.toast(message.data.body, message.data.titleDetail, this.myToast.toastType.blank);
        switch (message["data"]["title"]) {
          case "NOTIFICATION_MESSAGE":
            // this.loadPage(1);
            // this.getNumberUnread();
            break;
          default:
            return;
        }

        switch (message["data"]["notificationType"]) {
          case "APPROVED_SECOND_LEVEL":
            this.controlAccount.requestAPIGetAccountInfo(this.auth.userId, this.auth.partyId, () => {
              this.controlAccount.getAccountInfo();
            });
            break;
          case "VERIFIED_EKYC":
            this.controlAccount.requestAPIGetAccountInfo(this.auth.userId, this.auth.partyId, () => {
              this.controlAccount.getAccountInfo();
            });
            this.postRefeshToken(localStorage.getItem(ConstLocalStorage.REFRESH_TOKEN));
            break;
          default:
            return;
        }
      }
    });
  }

  toggleDropdownMenu() {
    // if (this.dropdownMenu.nativeElement.classList.contains('show')) {
    //   this.hideDropdownMenu();
    // } else {
    //   this.showDropdownMenu();
    // }
  }

  showDropdownMenu() {
    this.renderer.addClass(this.dropdownMenu.nativeElement, 'show');
  }

  hideDropdownMenu() {
    this.renderer.removeClass(this.dropdownMenu.nativeElement, 'show');
  }

  toggleDropdownNotiRow() {
    if (this.listnoti.nativeElement.classList.contains('hide-scrollbar')) {
      this.showScrollbarNotiList();
    } else {
      this.hideScrollbarNotiList();
    }
  }

  hideScrollbarNotiList() {
    this.renderer.addClass(this.listnoti.nativeElement, 'hide-scrollbar');
  }

  showScrollbarNotiList() {
    this.renderer.removeClass(this.listnoti.nativeElement, 'hide-scrollbar');
  }

  markAsRead(id) {
    this.postMarkAsRead(id);
  }

  markAsUnRead(id) {
    this.postMarkAsUnread(id);
  }

  deleteNoti(id) {
    this.deteleNotification(id);
  }

  markAllAsRead() {
    this.postMarkAllAsRead();
  }

  deleteAllNoti() {
    this.deleteAllNotification();
  }

  clickNoti(noti: Notification) {
    switch(noti.notificationType) {
      case NotificationType.NEW_CONTRACT:
        this.router.navigate([ConstantUrl.CONTRACT_DETAIL, noti.data]);
        this.markAsRead(noti.notificationId);
        break;
      case NotificationType.SIGNED_CONTRACT:
        this.router.navigate([ConstantUrl.CONTRACT_DETAIL, noti.data])
        this.markAsRead(noti.notificationId);
        break;
      case NotificationType.CANCEL_CONTRACT:
        this.router.navigate([ConstantUrl.CONTRACT_DETAIL, noti.data])
        this.markAsRead(noti.notificationId);
        break;
      case NotificationType.ABOUT_TO_EXPIRE_CONTRACT:
        this.router.navigate([ConstantUrl.CONTRACT_DETAIL, noti.data])
        this.markAsRead(noti.notificationId);
        break;
      case NotificationType.APPROVED_SECOND_LEVEL:
        this.router.navigate([ConstantUrl.PROFILE_URL]);
        this.markAsRead(noti.notificationId);
        break;
      case NotificationType.VERIFIED_EKYC:
        this.router.navigate([ConstantUrl.PROFILE_URL]);
        this.markAsRead(noti.notificationId);
        break;
      case NotificationType.REGISTERED_SMART_OTP:
        this.router.navigate([ConstantUrl.PROFILE_URL]);
        this.markAsRead(noti.notificationId);
        break;
      case NotificationType.REGISTERED_ONETIME_CA:
        this.router.navigate([ConstantUrl.PROFILE_URL]);
        this.markAsRead(noti.notificationId);
        break;
      case NotificationType.TIME_EXPIRE_PACKAGE:
        this.router.navigate([ConstantUrl.ENDUSER_PACKAGE_MANAGER]);
        this.markAsRead(noti.notificationId);
      case NotificationType.DOWNLOAD_MULTI_CONTRACT_SUCESSS:
        this.router.navigate(['/app/console/contract-download']);
        this.markAsRead(noti.notificationId);
      default:
        this.markAsRead(noti.notificationId);
    }
  }

  /**
   * Tai trang
   * @param page 
   */
  loadPage(page = 1) {
    this.page = page;
    this.getAllNotifications(page, this.maxSize, this.sort, this.propertiesSort);
  }

  //MARK: NETWORKING
  /**
   * Lay danh sach thong bao
   * @param page 
   * @param maxSize 
   * @param sort 
   * @param propertiesSort 
   */
  getAllNotifications(page = 1, maxSize = 10, sort = 'DESC', propertiesSort = 'sentAt') {
    this.showLoader();
    this.notiService.getAllNotifications(page, maxSize , sort, propertiesSort).subscribe((res: any) => {
      let result = new ListNotification(res.object || '');
      if (this.notifications.length <= 0 || page === 1) {
        this.notifications = result.data;
      } else {
        result.data.forEach((item) => {
          this.notifications.push(item);
        })
      }
      this.totalElement = result.totalElement;
      this.hideLoader();
    }, err => {
      console.log(err);
      this.hideLoader();
    })
  }

  /**
   * Lay so luong thong bao chua doc
   */
  getNumberUnread() {
    this.showLoader();
    this.notiService.getNumberUnread().subscribe((res: any) => {
      this.countNoti = res.object.count || 0;
      this.hideLoader();
    }, err => {
      this.hideLoader();
    });
  }

  /**
   * Danh dau da doc thong bao
   * @param id 
   */
  postMarkAsRead(id) {
    this.showLoader();
    this.notiService.postMarkAsRead(id).subscribe((res: any) => {
      let noti = this.notifications.find((item) => { return item.notificationId === id });
      if (noti) {
        noti.status = 'READ';
      }
      this.getNumberUnread();
      this.hideLoader();
    }, err => {
      this.hideLoader();
    })
  }

  /**
   * Danh dau chua doc thong bao
   * @param id 
   */
  postMarkAsUnread(id) {
    this.showLoader();
    this.notiService.postMarkAsUnread(id).subscribe((res: any) => {
      let noti = this.notifications.find((item) => { return item.notificationId === id });
      if (noti) {
        noti.status = 'UNREAD';
      }
      this.getNumberUnread();
      this.hideLoader();
    }, err => {
      this.hideLoader();
    });
  }

  /**
   * Xoa thong bao
   * @param id 
   */
  deteleNotification(id) {
    this.showLoader();
    this.notiService.deteleNotification(id).subscribe((res: any) => {
      this.loadPage(1);
      this.getNumberUnread();
      this.hideLoader();
    }, err => {
      this.hideLoader();
    })
  }

  /**
   * Danh dau tat ca da doc
   */
  postMarkAllAsRead() {
    this.showLoader();
    this.notiService.postMarkAllAsRead().subscribe((res: any) => {
      this.loadPage(1);
      this.getNumberUnread();
      this.hideLoader();
    }, err => {
      this.hideLoader();
    })
  }

  /**
   * Xoa tat ca thong bao
   */
  deleteAllNotification() {
    this.showLoader();
    this.notiService.deleteAllNotification().subscribe((res: any) => {
      this.loadPage(1);
      this.getNumberUnread();
      this.hideLoader();
    }, err => {
      this.hideLoader();
    })
  }

  postRefeshToken(refreshToken: string = "") {
    const param = {
        "refresh_token": refreshToken,
        "grant_type": "refresh_token",
        "client_id": "clientapp",
        "client_secret": "password"
    }
    this.auth.postOauthToken(param).subscribe((res: any) => {
      var result = new AuthLoginResponse(res);
      this.auth.setKey(result.key);

      var token = new AuthLoginResponse(res);
      let jwtToken = this.auth.jwtDecode(token.access_token);

      this.auth.setToken(token.access_token);
      this.auth.setUserId(jwtToken.idUser);
      this.auth.setUsername(jwtToken.user_name);
      localStorage.setItem(ConstLocalStorage.REFRESH_TOKEN, result.refresh_token);

      this.controlAccount.requestAPIGetAccountInfo(this.auth.userId, this.auth.partyId, () => {
        this.controlAccount.getAccountInfo();
      });
    })
  }
}
