
<div class="wrapper">
    <div class="preview-overlay">

        <div class="preview-container">
            <div class="preview-header d-flex">
                <div (click)="goToBack()"
                    class="ct-header-btn-back d-flex justify-content-center align-items-center cursor-pointer">
                    <span class="material-icons">arrow_back_ios</span>
                </div>
                <div class="ct-title flex-grow-1 d-flex align-items-center px-3">
                    <h4 class="mb-0">{{'FlowSettings_title' | translate}}</h4>
                </div>
                <div class="ct-toolbar-btn d-flex align-items-center">
                    <button (click)="actBtnSave()" class="btn btn-outline-primary d-flex align-items-center mr-3"><span
                            class="material-icons mr-2">save</span> {{'btn_save' | translate}}</button>
                </div>
            </div>

            <div class="preview-body">
                <div class="pv ct">
                    <div class="pv-body">
                        <div class="ctpv-container">
                            <div class="ctpv-content">
                                <div class="ct-container">
                                    <div class="ct-content" style="left: 0;">
                                        <div class="ct-content-enable-scroll enable-scroll">
                                            <div class="card ec-card shadow-none">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-md-3">
                                                            <label class="text-primary">{{ 'FlowSettings_generalInfo' | translate }}</label>
                                                            <hr class="mt-0 mb-2">
                                                            <form *ngIf="!notEditInfo" [formGroup]="form">
                                                                <div class="form-group">
                                                                    <label>{{ 'FlowSettings_flowContract' | translate }} <span class="text-danger">*</span></label>
                                                                    <ng-select
                                                                        [items]="dropListFlow | async"
                                                                        bindLabel="name"
                                                                        bindValue="contractFlowTemplateId"
                                                                        [trackByFn]="trackByFnFlow" 
                                                                        [loading]="flowLoading"
                                                                        [typeahead]="flowInput$"
                                                                        (change)="changeFlow($event)"
                                                                        (scrollToEnd)="onScrollToEndDropListFlow()"
                                                                        formControlName="contractFlowTemplateId"
                                                                        class="flex-grow-1"
                                                                        placeholder="{{ 'FlowSettings_flowContract_placholder' | translate }}"
                                                                    >
                                                                    </ng-select>
                                                                    <app-show-validate-errors [isSubmit]="isSubmit"
                                                                        [errorMessages]="validateError.contractFlowTemplateId" [control]="form.controls.contractFlowTemplateId"
                                                                        [detail]="{ name: 'contractFlowTemplateId' }"></app-show-validate-errors>
                                                                </div>
                                                                <div class="form-group">
                                                                    <label class="d-flex align-items-center">
                                                                        {{ 'FlowSettings_templateContract' | translate }} <span class="text-danger">*</span>
                                                                        <a class="btn text-primary text-decoration-underline ml-auto" (click)="showFilePreview()">{{ 'FlowSettings_showFile' | translate }}</a>
                                                                    </label>
                                                                    <input disabled class="form-control" value="{{ inputFileLabel | translate}}">
                                                                </div>
                                                                <div class="form-group">
                                                                    <label>{{ 'FlowSettings_contractForm' | translate }} <span class="text-danger">*</span></label>
                                                                    <ng-select
                                                                        [items]="dropListType"
                                                                        bindLabel="text"
                                                                        bindValue="value"
                                                                        placeholder="{{ 'FlowSettings_contractForm_placeholder' | translate }}"
                                                                        formControlName="type"
                                                                        [clearable]="false">
                                                                        <ng-template ng-label-tmp let-item="item">
                                                                            {{item.text | translate}}
                                                                        </ng-template>
                                                                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                                                            <span>{{item.text | translate}}</span>
                                                                        </ng-template>
                                                                    </ng-select>
                                                                    <app-show-validate-errors [isSubmit]="isSubmit"
                                                                        [errorMessages]="validateError.type" [control]="form.controls.type"
                                                                        [detail]="{ name: 'type' }"></app-show-validate-errors>
                                                                </div>
                                                                <div class="form-group">
                                                                    <label>{{ 'FlowSettings_damPhanHopDong' | translate }} <span class="text-danger">*</span></label>
                                                                    <ng-select
                                                                        [items]="dropListDiscuss"
                                                                        bindLabel="text"
                                                                        bindValue="value"
                                                                        placeholder="{{ 'FlowSettings_damPhanHopDong_placeholder' | translate }}"
                                                                        formControlName="discuss"
                                                                        (change)="changeDiscussType($event)"
                                                                        [clearable]="false">
                                                                        <ng-template ng-label-tmp let-item="item">
                                                                            {{item.text | translate}}
                                                                        </ng-template>
                                                                        <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                                                            <span>{{item.text | translate}}</span>
                                                                        </ng-template>
                                                                    </ng-select>
                                                                    <app-show-validate-errors [isSubmit]="isSubmit"
                                                                        [errorMessages]="validateError.discuss" [control]="form.controls.discuss"
                                                                        [detail]="{ name: 'discuss' }"></app-show-validate-errors>
                                                                </div>
                                                            </form>

                                                            <div *ngIf="notEditInfo">
                                                                <div class="row mb-2">
                                                                    <div class="col-md-6">
                                                                        <label class="font-weigth-bold">{{ 'flow_contract' | translate }}</label>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <span>{{ flowContract.name }}</span>
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <div class="col-md-6">
                                                                        <label class="font-weigth-bold">{{ 'status' | translate }}</label>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <span *ngIf="flowContract.disable == false" class="badge badge-success-new font-weight-bold p-2">{{ 'FlowSettings_active' | translate }}</span>
                                                                        <span *ngIf="flowContract.disable == true" class="badge badge-danger-new font-weight-bold p-2">{{ 'FlowSettings_deactive' | translate }}</span>
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <div class="col-md-6">
                                                                        <label class="font-weigth-bold">{{ 'contract_sample_v1' | translate }}</label>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <a class="text-primary text-decoration-underline cursor-pointer" (click)="showFilePreview()">{{ flowContract.fileName }}</a>
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <div class="col-md-6">
                                                                        <label class="font-weigth-bold">{{ 'FlowSettings_contractForm' | translate }}</label>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <span *ngIf="flowContract.type == 'ELECTRONIC'">{{ 'dropListType_ELECTRONIC' | translate }}</span>
                                                                        <span *ngIf="flowContract.type == 'PAPER'">{{ 'dropListType_PAPER' | translate }}</span>
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <div class="col-md-6">
                                                                        <label class="font-weigth-bold">{{ 'FlowSettings_discuss_contract' | translate }}</label>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        <span *ngIf="flowContract.discuss == true">{{ 'FlowSettings_contract_have_discuss' | translate }}</span>
                                                                        <span *ngIf="flowContract.discuss == false">{{ 'FlowSettings_contract_non_discuss' | translate }}</span>
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-2">
                                                                    <div class="col-md-6">
                                                                        <label class="font-weigth-bold">{{ 'FlowSettings_description' | translate }}</label>
                                                                    </div>
                                                                    <div class="col-md-6">
                                                                        {{ flowContract.description }}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-9">
                                                            <div class="row">
                                                                <div *ngIf="internalEditable || isCreator" class="col">
                                                                    <label class="text-primary">{{ 'clc_internal_employee_join' | translate }}</label>
                                                                    <hr class="mt-0 mb-2">
                                                                    <div *ngIf="form.value.discuss === false" class="row my-3">
                                                                        <div class="col-md-4">
                                                                            <div class="cover-form-check h-100">
                                                                                <div class="form-check">
                                                                                    <input [(ngModel)]="internalDiscussType" [value]="internalDiscussTypes.REQUIRE_FLOW" 
                                                                                        class="form-check-input" type="radio" 
                                                                                        name="internalDiscussType" 
                                                                                        id="internalDiscussType1" 
                                                                                        (change)="chooseInternalDiscussType($event)">
                                                                                    <label class="form-check-label ml-2" for="internalDiscussType1">
                                                                                        <p class="font-weight-bold">{{ 'required_internaldiscuss_step_by_step' | translate }}</p>
                                                                                        <span>{{ 'required_internaldiscuss_step_by_step_desc' | translate }}</span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-4">
                                                                            <div class="cover-form-check h-100">
                                                                                <div class="form-check">
                                                                                    <input [(ngModel)]="internalDiscussType" [value]="internalDiscussTypes.NO_REQUIRE_FLOW" 
                                                                                        class="form-check-input" type="radio" 
                                                                                        name="internalDiscussType" 
                                                                                        id="internalDiscussType2"
                                                                                        (change)="chooseInternalDiscussType($event)">
                                                                                    <label class="form-check-label ml-2" for="internalDiscussType2">
                                                                                        <p class="font-weight-bold">{{ 'required_internaldiscuss_no_step_by_step' | translate }}</p>
                                                                                        <span>{{ 'required_internaldiscuss_no_step_by_step_desc' | translate }}</span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-4">
                                                                            <div class="cover-form-check h-100">
                                                                                <div class="form-check">
                                                                                    <input [(ngModel)]="internalDiscussType" [value]="internalDiscussTypes.NO_REQUIRE" 
                                                                                        class="form-check-input" type="radio" 
                                                                                        name="internalDiscussType" 
                                                                                        id="internalDiscussType3"
                                                                                        (change)="chooseInternalDiscussType($event)">
                                                                                    <label class="form-check-label ml-2" for="internalDiscussType3">
                                                                                        <p class="font-weight-bold">{{ 'no_internaldiscuss' | translate }}</p>
                                                                                        <span>{{ 'no_internaldiscuss_desc' | translate }}</span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <table *ngIf="showTableInternalDiscuss" class="table table-bordered">
                                                                        <thead class="bg-tb-header">
                                                                            <tr>
                                                                                <th>{{ 'clc_tb_stt' | translate }}</th>
                                                                                <th>{{ 'clc_tb_party_join' | translate }}</th>
                                                                                <th>{{ 'clc_tb_employee_join' | translate }}</th>
                                                                                <th *ngIf="this.form.value.discuss === false">{{ 'internaldiscuss_sequence' | translate }}</th>
                                                                                <th>{{ 'clc_tb_action' | translate }}</th>
                                                                                <th></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr *ngFor="let item of internalDiscuss; let i = index">
                                                                                <td>{{ i + 1 }}</td>
                                                                                <td [class.bg-danger-tb]="item.hasError('partyId')">
                                                                                    <ng-select
                                                                                        [items]="item.dropListPartData | async"
                                                                                        bindLabel="partyName"
                                                                                        bindValue="partyId"
                                                                                        [trackByFn]="item.trackByFn" 
                                                                                        [loading]="item.partsLoading"
                                                                                        [typeahead]="item.partsInput$"
                                                                                        [(ngModel)]="item.partyId"
                                                                                        (change)="item.changedParty($event)"
                                                                                        (scrollToEnd)="item.onScrollToEndDropListPart()"
                                                                                        placeholder="{{ 'select_party_join' | translate }}"
                                                                                    >
                                                                                    </ng-select>
                                                                                </td>
                                                                                <td [class.bg-danger-tb]="item.hasError('userId')">
                                                                                    <ng-select
                                                                                        [items]="item.dropListEmployeeData | async"
                                                                                        bindLabel="fullName"
                                                                                        bindValue="userId"
                                                                                        [trackByFn]="item.trackByFnEmployee" 
                                                                                        [loading]="item.employeesLoading"
                                                                                        [typeahead]="item.employeesInput$"
                                                                                        [(ngModel)]="item.userId"
                                                                                        (change)="item.changedEmployee($event)"
                                                                                        (scrollToEnd)="item.onScrollToEndDropListEmployee()"
                                                                                        placeholder="{{ 'select_employee_join' | translate }}"
                                                                                    >
                                                                                    </ng-select>
                                                                                </td>
                                                                                <td *ngIf="this.form.value.discuss === false" [class.bg-danger-tb]="item.hasError('sequence')">
                                                                                    <input *ngIf="internalDiscussType != internalDiscussTypes.NO_REQUIRE_FLOW" appTrimValue appOnlyNumber [(ngModel)]="item.sequence" class="form-control" placeholder="{{ 'internaldiscuss_sequence_placeholder' | translate }}">
                                                                                    <input *ngIf="internalDiscussType == internalDiscussTypes.NO_REQUIRE_FLOW" class="form-control" placeholder="{{ 'internaldiscuss_sequence_placeholder' | translate }}" disabled>
                                                                                </td>
                                                                                <td [class.bg-danger-tb]="item.hasError('permissions')">
                                                                                    <div class="icheck-primary mb-3" style="width: fit-content;">
                                                                                        <input [checked]="item.per_action.INTERNAL_DISCUSSABLE" type="checkbox"
                                                                                            id="checkboxPrimary-INTERNAL_DISCUSSABLE-{{i}}"
                                                                                            (change)="item.changedAction('INTERNAL_DISCUSSABLE')">
                                                                                        <label for="checkboxPrimary-INTERNAL_DISCUSSABLE-{{i}}">
                                                                                            {{ 'join_discuss' | translate }}
                                                                                        </label>
                                                                                    </div>
                                                                                    <div class="icheck-primary mb-3" style="width: fit-content;">
                                                                                        <input [checked]="item.per_action.INTERNAL_ACCEPTABLE" type="checkbox"
                                                                                            id="checkboxPrimary-INTERNAL_ACCEPTABLE-{{i}}"
                                                                                            (change)="item.changedAction('INTERNAL_ACCEPTABLE')">
                                                                                        <label for="checkboxPrimary-INTERNAL_ACCEPTABLE-{{i}}">
                                                                                            {{ 'accept_deny_confirm' | translate }}
                                                                                        </label>
                                                                                    </div>
                                                                                    <div *ngIf="this.form.value.discuss === true" class="icheck-primary mb-3" style="width: fit-content;">
                                                                                        <input [checked]="item.per_action.INTERNAL_EDITABLE" type="checkbox"
                                                                                            id="checkboxPrimary-INTERNAL_EDITABLE-{{i}}"
                                                                                            (change)="item.changedAction('INTERNAL_EDITABLE')">
                                                                                        <label for="checkboxPrimary-INTERNAL_EDITABLE-{{i}}">
                                                                                            {{ 'add_remove_employee' | translate }}
                                                                                        </label>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <a class="btn text-primary text-decoration-underline" (click)="removeInternalDiscuss(i)">{{ 'btn_delete' | translate }}</a>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <div *ngIf="showTableInternalDiscuss && internalDiscussError" class="">
                                                                        <ol class="pl-3 text-danger">
                                                                            <li *ngIf="internalDiscussError['required']">
                                                                                <p class="text-danger">{{ 'FlowSettings_fill_full_info' | translate }}</p>
                                                                            </li>
                                                                            <li *ngIf="internalDiscussError['minSequence'] || internalDiscussError['maxSequence'] || internalDiscussError['uniqueSequence']">
                                                                                <p class="text-danger">{{ 'FlowSettings_invalid_index_row' | translate }}</p>
                                                                            </li>
                                                                            <li *ngIf="internalDiscussError['requireAcceptPermission']">
                                                                                <p class="text-danger">{{ 'FlowSettings_invalid_permission_of_row' | translate }}</p>
                                                                            </li>
                                                                        </ol>
                                                                    </div>
                                                                    <p *ngIf="showTableInternalDiscuss">
                                                                        <a class="btn text-primary text-decoration-underline" (click)="addInternalDiscuss()">+ {{ 'btn_addnew' | translate }}</a>
                                                                    </p>
                                                                </div>
                                                                <div *ngIf="!internalEditable && !isCreator" class="col">
                                                                    <div *ngIf="flowContract.discuss === false" class="row my-3">
                                                                        <div class="col-md-3">
                                                                            <label class="font-weigth-bold">{{ 'FlowSettingsl_internaldiscuss_type' | translate }}</label>
                                                                        </div>
                                                                        <div class="col-md-9">
                                                                            <span *ngIf="flowContract.internalDiscussType === internalDiscussTypes.REQUIRE_FLOW">{{ 'required_internaldiscuss_step_by_step' | translate }}</span>
                                                                            <span *ngIf="flowContract.internalDiscussType === internalDiscussTypes.NO_REQUIRE_FLOW">{{ 'required_internaldiscuss_no_step_by_step' | translate }}</span>
                                                                            <span *ngIf="flowContract.internalDiscussType === internalDiscussTypes.NO_REQUIRE">{{ 'required_internaldiscuss_no_step_by_step_desc' | translate }}</span>
                                                                        </div>
                                                                    </div>
                                                                    <table *ngIf="showTableInternalDiscuss" class="table table-bordered">
                                                                        <thead class="bg-tb-header">
                                                                            <tr>
                                                                                <th>{{ 'clc_tb_stt' | translate }}</th>
                                                                                <th>{{ 'clc_tb_party_join' | translate }}</th>
                                                                                <th>{{ 'clc_tb_employee_join' | translate }}</th>
                                                                                <th *ngIf="flowContract.discuss === false">{{ 'internaldiscuss_sequence' | translate }}</th>
                                                                                <th>{{ 'clc_tb_action' | translate }}</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr *ngFor="let item of internalDiscuss; let i = index">
                                                                                <td>{{ i + 1 }}</td>
                                                                                <td>{{ item.partyName }}</td>
                                                                                <td>{{item.fullName}}</td>
                                                                                <td *ngIf="flowContract.discuss === false">
                                                                                    {{ item.sequence }}
                                                                                </td>
                                                                                <td>
                                                                                    <ul>
                                                                                        <li *ngFor="let x of item.permissions">
                                                                                            <span *ngIf="x === 'INTERNAL_DISCUSSABLE'">{{ 'join_discuss' | translate }}</span>
                                                                                            <span *ngIf="x === 'INTERNAL_ACCEPTABLE'">{{ 'accept_deny_confirm' | translate }}</span>
                                                                                            <span *ngIf="x === 'INTERNAL_EDITABLE'">{{ 'add_remove_employee' | translate }}</span>
                                                                                        </li>
                                                                                    </ul>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                            
                                                            <div *ngIf="form.value.discuss === true"  class="row">
                                                                <div *ngIf="partnerEditable || isCreator" class="col">
                                                                    <label class="text-primary">{{ 'clc_internal_employee_join_discuss' | translate }}</label>
                                                                    <hr class="mt-0 mb-2">
                                                                    <table class="table table-bordered">
                                                                        <thead class="bg-tb-header">
                                                                            <tr>
                                                                                <th>{{ 'clc_tb_stt' | translate }}</th>
                                                                                <th>{{ 'clc_tb_party_join' | translate }}</th>
                                                                                <th>{{ 'clc_tb_employee_join' | translate }}</th>
                                                                                <th>{{ 'clc_tb_action' | translate }}</th>
                                                                                <th></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr *ngFor="let item of partnerDiscuss; let i = index">
                                                                                <td>{{ i + 1 }}</td>
                                                                                <td [class.bg-danger-tb]="item.hasError('partyId')">
                                                                                    <ng-select
                                                                                        [items]="item.dropListPartData | async"
                                                                                        bindLabel="partyName"
                                                                                        bindValue="partyId"
                                                                                        [trackByFn]="item.trackByFn" 
                                                                                        [loading]="item.partsLoading"
                                                                                        [typeahead]="item.partsInput$"
                                                                                        [(ngModel)]="item.partyId"
                                                                                        (change)="item.changedParty($event)"
                                                                                        (scrollToEnd)="item.onScrollToEndDropListPart()"
                                                                                        placeholder="{{ 'select_party_join' | translate }}"
                                                                                    >
                                                                                    </ng-select>
                                                                                </td>
                                                                                <td [class.bg-danger-tb]="item.hasError('userId')">
                                                                                    <ng-select
                                                                                        [items]="item.dropListEmployeeData | async"
                                                                                        bindLabel="fullName"
                                                                                        bindValue="userId"
                                                                                        [trackByFn]="item.trackByFnEmployee" 
                                                                                        [loading]="item.employeesLoading"
                                                                                        [typeahead]="item.employeesInput$"
                                                                                        [(ngModel)]="item.userId"
                                                                                        (change)="item.changedEmployee($event)"
                                                                                        (scrollToEnd)="item.onScrollToEndDropListEmployee()"
                                                                                        placeholder="{{ 'select_employee_join' | translate }}"
                                                                                    >
                                                                                    </ng-select>
                                                                                </td>
                                                                                <td [class.bg-danger-tb]="item.hasError('permissions')">
                                                                                    <div class="icheck-primary mb-3" style="width: fit-content;">
                                                                                        <input [checked]="item.per_action.PARTNER_DISCUSSABLE" type="checkbox"
                                                                                            id="checkboxPrimary-PARTNER_DISCUSSABLE-{{i}}"
                                                                                            (change)="item.changedAction('PARTNER_DISCUSSABLE')">
                                                                                        <label for="checkboxPrimary-PARTNER_DISCUSSABLE-{{i}}">
                                                                                            {{ 'join_discuss' | translate }}
                                                                                        </label>
                                                                                    </div>
                                                                                    <div class="icheck-primary mb-3" style="width: fit-content;">
                                                                                        <input [checked]="item.per_action.PARTNER_ACCEPTABLE" type="checkbox"
                                                                                            id="checkboxPrimary-PARTNER_ACCEPTABLE-{{i}}"
                                                                                            (change)="item.changedAction('PARTNER_ACCEPTABLE')">
                                                                                        <label for="checkboxPrimary-PARTNER_ACCEPTABLE-{{i}}">
                                                                                            {{ 'accept_deny_confirm' | translate }}
                                                                                        </label>
                                                                                    </div>
                                                                                    <div class="icheck-primary mb-3" style="width: fit-content;">
                                                                                        <input [checked]="item.per_action.PARTNER_EDITABLE" type="checkbox"
                                                                                            id="checkboxPrimary-PARTNER_EDITABLE-{{i}}"
                                                                                            (change)="item.changedAction('PARTNER_EDITABLE')">
                                                                                        <label for="checkboxPrimary-PARTNER_EDITABLE-{{i}}">
                                                                                            {{ 'add_remove_employee' | translate }}
                                                                                        </label>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    <a class="btn text-primary text-decoration-underline" (click)="removePartnerDiscuss(i)">{{ 'btn_delete' | translate }}</a>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <div *ngIf="partnerDiscussError" class="">
                                                                        <ol class="pl-3 text-danger">
                                                                            <li *ngIf="partnerDiscussError['required']">
                                                                                <p class="text-danger">{{ 'FlowSettings_fill_full_info' | translate }}</p>
                                                                            </li>
                                                                        </ol>
                                                                    </div>
                                                                    <p>
                                                                        <a class="btn text-primary text-decoration-underline" (click)="addPartnerDiscuss()">+ {{ 'btn_addnew' | translate }}</a>
                                                                    </p>
                                                                </div>
                                                                <div *ngIf="!partnerEditable && !isCreator" class="col">
                                                                    <label class="text-primary">{{ 'clc_internal_employee_join_discuss' | translate }}</label>
                                                                    <hr class="mt-0 mb-2">
                                                                    <table class="table table-bordered">
                                                                        <thead class="bg-tb-header">
                                                                            <tr>
                                                                                <th>{{ 'clc_tb_stt' | translate }}</th>
                                                                                <th>{{ 'clc_tb_party_join' | translate }}</th>
                                                                                <th>{{ 'clc_tb_employee_join' | translate }}</th>
                                                                                <th>{{ 'clc_tb_action' | translate }}</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr *ngFor="let item of partnerDiscuss; let i = index">
                                                                                <td>{{ i + 1 }}</td>
                                                                                <td>{{ item.partyName }}</td>
                                                                                <td>{{item.fullName}}</td>
                                                                                <td>
                                                                                    <ul>
                                                                                        <li *ngFor="let x of item.permissions">
                                                                                            <span *ngIf="x === 'PARTNER_DISCUSSABLE'">{{ 'join_discuss' | translate }}</span>
                                                                                            <span *ngIf="x === 'PARTNER_ACCEPTABLE'">{{ 'accept_deny_confirm' | translate }}</span>
                                                                                            <span *ngIf="x === 'PARTNER_EDITABLE'">{{ 'add_remove_employee' | translate }}</span>
                                                                                        </li>
                                                                                    </ul>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                            
                                                            <div *ngIf="signFlowEditable || isCreator" class="row">
                                                                <div class="col">
                                                                    <label class="text-primary">{{ 'clc_internal_employee_join_sign' | translate }}</label>
                                                                    <hr class="mt-0 mb-2">
                                                                    <div class="row my-3">
                                                                        <div class="col-md-4">
                                                                            <div class="cover-form-check">
                                                                                <div class="form-check">
                                                                                    <input [(ngModel)]="signFlowType" [value]="signFlowTypes.REQUIRE_FLOW_STAMP" 
                                                                                        class="form-check-input" type="radio" 
                                                                                        name="flexRadioDefault" 
                                                                                        id="flexRadioDefault1" 
                                                                                        (change)="chooseSignFlowType($event)">
                                                                                    <label class="form-check-label ml-2" for="flexRadioDefault1">
                                                                                        <p class="font-weight-bold">{{ 'required_flow_and_stamp' | translate }}</p>
                                                                                        <span>{{ 'required_flow_and_stamp_desc' | translate }}</span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-4">
                                                                            <div class="cover-form-check">
                                                                                <div class="form-check">
                                                                                    <input [(ngModel)]="signFlowType" [value]="signFlowTypes.REQUIRE_FLOW" 
                                                                                        class="form-check-input" type="radio" 
                                                                                        name="flexRadioDefault" 
                                                                                        id="flexRadioDefault2"
                                                                                        (change)="chooseSignFlowType($event)">
                                                                                    <label class="form-check-label ml-2" for="flexRadioDefault2">
                                                                                        <p class="font-weight-bold">{{ 'FlowSettings_required_flow' | translate }}</p>
                                                                                        <span>{{ 'FlowSettings_required_flow_desc' | translate }}</span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-md-4">
                                                                            <div class="cover-form-check">
                                                                                <div class="form-check">
                                                                                    <input [(ngModel)]="signFlowType" [value]="signFlowTypes.NO_REQUIRE" 
                                                                                        class="form-check-input" type="radio" 
                                                                                        name="flexRadioDefault" 
                                                                                        id="flexRadioDefault3"
                                                                                        (change)="chooseSignFlowType($event)">
                                                                                    <label class="form-check-label ml-2" for="flexRadioDefault3">
                                                                                        <p class="font-weight-bold">{{ 'FlowSettings_no_required_flow' | translate }}</p>
                                                                                        <span>{{ 'FlowSettings_no_required_flow_desc' | translate }}</span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <table class="table table-bordered">
                                                                        <thead class="bg-tb-header">
                                                                            <tr>
                                                                                <th>{{ 'clc_tb_stt' | translate }}</th>
                                                                                <th>{{ 'clc_tb_sign_type' | translate }}</th>
                                                                                <th>{{ 'FlowSettings_signature_type' | translate }}</th>
                                                                                <th>{{ 'clc_tb_party_join' | translate }}</th>
                                                                                <th>{{ 'clc_tb_employee_join' | translate }}</th>
                                                                                <th>{{ 'clc_tb_sign_th' | translate }}</th>
                                                                                <th>{{ 'clc_tb_progress_time' | translate }}</th>
                                                                                <th>{{ 'FlowSettings_actions' | translate }}</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr *ngFor="let item of signFlow; let i = index">
                                                                                <td>{{ i + 1 }}</td>
                                                                                <td>
                                                                                    <input appTrimValue class="form-control" disabled placeholder="" value="{{ item.signTypeLabel | translate }}">
                                                                                </td>
                                                                                <td [class.bg-danger-tb]="item.hasError('signForm')">
                                                                                    <ng-select
                                                                                        [items]="item.signFormData"
                                                                                        bindLabel="text"
                                                                                        bindValue="id"
                                                                                        [(ngModel)]="item.signForm"
                                                                                        [multiple]="true"
                                                                                        placeholder="{{ 'FlowSettings_placeholder_signform' | translate }}"
                                                                                    >
                                                                                        <!-- <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                                                                            <span class="ng-value-label">{{item.text}}</span>
                                                                                            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                                                                                        </ng-template> -->

                                                                                        <ng-template ng-label-tmp let-item="item" let-clear="clear">
                                                                                            <span class="ng-value-label">{{ item.text | translate }}</span>
                                                                                            <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                                                                                        </ng-template>
                                                                                        <ng-template ng-option-tmp let-item="item">
                                                                                            <span>{{ item.text | translate }}</span>
                                                                                        </ng-template>
                                                                                    </ng-select>
                                                                                </td>
                                                                                <td [class.bg-danger-tb]="item.hasError('partyId')">
                                                                                    <ng-select
                                                                                        [items]="item.dropListPartData | async"
                                                                                        bindLabel="partyName"
                                                                                        bindValue="partyId"
                                                                                        [trackByFn]="item.trackByFn" 
                                                                                        [loading]="item.partsLoading"
                                                                                        [typeahead]="item.partsInput$"
                                                                                        [(ngModel)]="item.partyId"
                                                                                        (change)="item.changedParty($event)"
                                                                                        (scrollToEnd)="item.onScrollToEndDropListPart()"
                                                                                        placeholder="{{ 'select_party_join' | translate }}"
                                                                                    >
                                                                                    </ng-select>
                                                                                </td>
                                                                                <td [class.bg-danger-tb]="item.hasError('userId')">
                                                                                    <ng-select
                                                                                        [items]="item.dropListEmployeeData | async"
                                                                                        bindLabel="fullName"
                                                                                        bindValue="userId"
                                                                                        [trackByFn]="item.trackByFnEmployee" 
                                                                                        [loading]="item.employeesLoading"
                                                                                        [typeahead]="item.employeesInput$"
                                                                                        [(ngModel)]="item.userId"
                                                                                        (change)="item.changedEmployee($event)"
                                                                                        (scrollToEnd)="item.onScrollToEndDropListEmployee()"
                                                                                        placeholder="{{ 'select_employee_join' | translate }}"
                                                                                    >
                                                                                    </ng-select>
                                                                                </td>
                                                                                <td [class.bg-danger-tb]="item.hasError('sequence')">
                                                                                    <input appTrimValue *ngIf="item.signType === 'APPROVAL'" appOnlyNumber class="form-control" placeholder="{{ 'clc_tb_sign_th' | translate }}" disabled [value]="item.sequence">
                                                                                    <input appTrimValue *ngIf="item.signType === 'DRAFT'" appOnlyNumber class="form-control" placeholder="{{ 'clc_tb_sign_th' | translate }}" [(ngModel)]="item.sequence">
                                                                                    <input appTrimValue *ngIf="item.signType === 'STAMP'" appOnlyNumber class="form-control" placeholder="{{ 'clc_tb_sign_th' | translate }}" disabled [value]="item.sequence">
                                                                                </td>
                                                                                <td class="d-flex" [class.bg-danger-tb]="item.hasError('limitDate')">
                                                                                    <input appOnlyNumber class="form-control" placeholder="{{ 'clc_tb_progress_time' | translate }}" [(ngModel)]="item.limitDate"> <span class="align-self-center pl-2"> {{ 'FlowSettings_day' | translate }}</span>
                                                                                </td>
                                                                                <td>
                                                                                    <a *ngIf="item.delete" class="btn text-primary text-decoration-underline" (click)="removeSignFlow(i)">{{'btn_delete' | translate}}</a>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                    <div *ngIf="signFlowError" class="">
                                                                        <ol class="pl-3 text-danger">
                                                                            <li *ngIf="signFlowError['required']">
                                                                                <p class="text-danger">{{'FlowSettings_fill_full_info' | translate}}</p>
                                                                            </li>
                                                                            <li *ngIf="signFlowError['minSequence'] || signFlowError['maxSequence'] || signFlowError['uniqueSequence']">
                                                                                <p class="text-danger">{{'FlowSettings_invalid_index_row' | translate}}</p>
                                                                            </li>
                                                                            <li *ngIf="signFlowError['minLimitDate']">
                                                                                <p class="text-danger">{{'FlowSettings_invalid_expire_progress' | translate}}</p>
                                                                            </li>
                                                                        </ol>
                                                                    </div>
                                                                    <p>
                                                                        <a class="btn text-primary text-decoration-underline" (click)="addSignFlow()">+ {{'btn_addnew' | translate}}</a>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="!signFlowEditable && !isCreator" class="row">
                                                                <div class="col">
                                                                    <label class="text-primary">{{ 'clc_internal_employee_join_sign' | translate }}</label>
                                                                    <hr class="mt-0 mb-2">
                                                                    <table class="table table-bordered">
                                                                        <thead class="bg-tb-header">
                                                                            <tr>
                                                                                <th>{{ 'clc_tb_stt' | translate }}</th>
                                                                                <th>{{ 'clc_tb_sign_type' | translate }}</th>
                                                                                <th>{{ 'clc_tb_party_join' | translate }}</th>
                                                                                <th>{{ 'clc_tb_employee_join' | translate }}</th>
                                                                                <th>{{ 'clc_tb_sign_th' | translate }}</th>
                                                                                <th>{{ 'clc_tb_progress_time' | translate }}</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr *ngFor="let item of signFlow; let i = index">
                                                                                <td>{{ i + 1 }}</td>
                                                                                <td>
                                                                                    {{ item.signTypeLabel }}
                                                                                </td>
                                                                                <td>{{ item.partyName }}</td>
                                                                                <td>{{ item.sequence }}</td>
                                                                                <td>{{ item.email || item.fullName }}</td>
                                                                                <td>{{ localize.day }}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-preview-modal *ngIf="showFile" [file]="pdfFile" [name]="inputFileLabel" (close)="onClosePreview($event)"></app-preview-modal>