import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-trusted-partner-manager',
  templateUrl: './trusted-partner-manager.component.html',
  styleUrls: ['./trusted-partner-manager.component.scss']
})
export class TrustedPartnerManagerComponent implements OnInit {

  consumerTotal: number = 0;
  businessTotal: number = 0;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  addContract() {

  }

  addPartner(type: string) {
    switch (type) {
      case "BUSINESS":
        this.router.navigate([`app/console/partner/add-business-trusted-partner`]);
        break;
      case "CONSUMER":
        this.router.navigate([`app/console/partner/trusted/add-consumer`]);
        break;
      default:
        break;
    }
  }

  makeConsumerTotal(event) {
    this.consumerTotal = event;
  }

  makeBusinessTotal(event) {
    this.businessTotal = event;
  }
}
