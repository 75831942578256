<form [formGroup]="formSubmit">
    <div class="input-group input-custom mb-4">
        <span class="input-custom-label">{{ 'SignUpBussiness_ten_doanh_nghiep' | translate }} <span class="text-danger">*</span></span>
        <input appTrimValue formControlName="tenDoanhNghiep" type="text"
            class="form-control form-control-lg input-custom-control"
            placeholder="{{ 'SignUpBussiness_ten_doanh_nghiep' | translate }}" />
        <app-show-validate-errors [isSubmit]="isSubmit"
            [errorMessages]="validateMsg.tenDoanhNghiep" [control]="formSubmit.controls.tenDoanhNghiep"
            [detail]="{ name: 'tenDoanhNghiep' }">
        </app-show-validate-errors>
    </div>

    <div class="input-group input-custom mb-4">
        <span class="input-custom-label">{{ 'SignUpBussiness_ten_viet_tat' | translate }} <span class="text-danger">*</span></span>
        <input appTrimValue formControlName="tenVietTat" type="text"
            class="form-control form-control-lg input-custom-control"
            placeholder="{{ 'SignUpBussiness_ten_viet_tat' | translate }}" />
        <app-show-validate-errors [isSubmit]="isSubmit"
            [errorMessages]="validateMsg.tenVietTat" [control]="formSubmit.controls.tenVietTat"
            [detail]="{ name: 'tenVietTat' }">
        </app-show-validate-errors>
    </div>

    <div class="input-group input-custom mb-4">
        <span class="input-custom-label">{{ 'SignUpBussiness_ma_so_thue' | translate }} <span class="text-danger">*</span></span>
        <input appTrimValue formControlName="maSoThue" type="text"
            class="form-control form-control-lg input-custom-control"
            placeholder="{{ 'SignUpBussiness_ma_so_thue' | translate }}" />
        <app-show-validate-errors [isSubmit]="isSubmit"
            [errorMessages]="validateMsg.maSoThue" [control]="formSubmit.controls.maSoThue"
            [detail]="{ name: 'maSoThue' }">
        </app-show-validate-errors>
    </div>

    <div class="input-group input-custom mb-4">
        <span class="input-custom-label">{{ 'SignUpBussiness_nguoi_dai_dien' | translate }} <span class="text-danger">*</span></span>
        <input appTrimValue formControlName="hoVaTen" type="text"
            class="form-control form-control-lg input-custom-control"
            placeholder="{{ 'SignUpBussiness_nguoi_dai_dien' | translate }}" />
        <app-show-validate-errors [isSubmit]="isSubmit"
            [errorMessages]="validateMsg.hoVaTen" [control]="formSubmit.controls.hoVaTen"
            [detail]="{ name: 'hoVaTen' }">
        </app-show-validate-errors>
    </div>

    <div class="input-group input-custom mb-4">
        <span class="input-custom-label">{{ 'SignUpBussiness_email' | translate }} <span class="text-danger">*</span></span>
        <input appTrimValue appLowercaseValue formControlName="email" type="text"
            class="form-control form-control-lg input-custom-control"
            placeholder="{{ 'SignUpBussiness_email' | translate }}" [attr.disabled]="notBack ? true : null"/>
        <app-show-validate-errors [isSubmit]="isSubmit"
            [errorMessages]="validateMsg.email" [control]="formSubmit.controls.email"
            [detail]="{ name: 'email' }">
        </app-show-validate-errors>
    </div>
    
    <div class="input-group input-custom mb-4">
        <span class="input-custom-label">{{ 'SignUpBussiness_tinh_thanh_pho' | translate }}</span>
        <ng-select placeholder="{{ 'SignUpBussiness_tinh_thanh_pho' | translate }}ố" formControlName="tinhId" class="input-custom-control">
            <ng-option *ngFor="let tinh of tinhBindData" [value]="tinh.id">{{ tinh.label }}</ng-option>
        </ng-select>
        <app-show-validate-errors [isSubmit]="isSubmit"
            [errorMessages]="validateMsg.tinhId" [control]="formSubmit.controls.tinhId"
            [detail]="{ name: 'tinhId' }">
        </app-show-validate-errors>
    </div>

    <div class="input-group input-custom mb-4">
        <span class="input-custom-label">{{ 'SignUpBussiness_ten_tai_khoan' | translate }} <span class="text-danger">*</span></span>
        <input appTrimValue appLowercaseValue formControlName="username" type="text"
            class="form-control form-control-lg input-custom-control"
            placeholder="{{ 'SignUpBussiness_ten_tai_khoan' | translate }}" />
        <app-show-validate-errors [isSubmit]="isSubmit"
            [errorMessages]="validateMsg.username" [control]="formSubmit.controls.username"
            [detail]="{ name: 'username' }">
        </app-show-validate-errors>
    </div>

    <div class="input-group input-custom mb-4">
        <span class="input-custom-label">{{ 'SignUpBussiness_mat_khau' | translate }} <span class="text-danger">*</span></span>
        <input appInputPassword formControlName="password" type="password"
            class="form-control form-control-lg input-custom-control"
            placeholder="{{ 'SignUpBussiness_mat_khau' | translate }}" />
        <app-show-validate-errors [isSubmit]="isSubmit"
            [errorMessages]="validateMsg.password" [control]="formSubmit.controls.password"
            [detail]="{ name: 'password' }">
        </app-show-validate-errors>
    </div>

    <div class="row mb-4">
        <div class="col-12">
            <div class="form-check">
                <input [(ngModel)]="checkbox_dieukhoan" [ngModelOptions]="{standalone: true}" class="form-check-input" type="checkbox" value="false" id="dieukhoan_sudung">
                <label class="form-check-label" for="dieukhoan_sudung">
                    {{ 'SignUpBussiness_dong_y_noi_dung' | translate }} <a class="text-primary text-decoration-underline cursor-pointer" (click)="showDieuKhoan(); $event.preventDefault()">{{ 'SignUpBussiness_dieu_khoan_su_dung' | translate }}</a>
                </label>
            </div>
        </div>
    </div>

    <div class="d-flex">
        <a *ngIf="!notBack" class="btn-lg text-primary cursor-pointer flex-grow-1 d-flex align-items-center" (click)="actBack()">
            <span class="material-icons" style="margin-right: 8px">arrow_back</span> {{ 'btn_quay_ve' | translate }}
        </a>
    
        <button class="btn btn-lg btn-primary flex-grow-1" [class.col-md-6]="notBack" [class.ml-auto]="notBack" (click)="actNext()">{{ 'btn_tiep_theo' | translate }}</button>
    </div>
</form>