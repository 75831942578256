<div class="bg-white">
    <section class="p-3">
        <div class="row">
            <div class="col-lg-3 col-md-5">
                <form [formGroup]="formSearch" (ngSubmit)="actSearch(formSearch.value.search)"
                    class="input-group border rounded mb-3">
                    <div class="input-group-prepend border-0">
                        <button class="btn d-inline-flex pr-2 pl-2" type="submit"><span
                                class="material-icons">search</span></button>
                    </div>
                    <input (input)="eventInputSearch($event)" type="text" class="form-control border-0"
                        formControlName="search" placeholder="{{ 'ServiceContractListV2_ph_search' | translate }}"
                        aria-label="" aria-describedby="basic-addon1">
                </form>
            </div>
            <div class="col-md-auto ml-auto">
                <!-- <div class="btn-group">
                    <button class="btn btn-outline-primary dropdown-toggle d-inline-flex align-items-center mr-3"
                        data-toggle="dropdown" id="configfilter"><span class="material-icons mr-1">tune</span>Bộ
                        lọc</button>
                    <div class="dropdown-menu border-0" aria-labelledby="configfilter">
                        <div *ngFor="let item of listFitler" class="tcheck-primary d-inline-block px-3">
                            <input type="checkbox" id="{{ item.name }}" [checked]="configFilter[item.name]">
                            <label for="{{ item.name }}" class="w-100" [class.checked]="configFilter[item.name]"
                                (click)="actConfigFilter($event, item.name)">
                                {{ item.label }}
                            </label>
                        </div>
                    </div>
                </div> -->
                <div class="btn-group mx-2" *ngIf="isCompany || isInternal">
                    <button *ngIf="_by === 'OWNER'" class="btn btn-outline-primary d-inline-flex"
                        (click)="openModalAdvancedFilter(advancedFilter)">
                        <span class="material-icons mr-1">tune</span>{{ 'btn_advancedFilter' |
                        translate }}</button>
                </div>
                <div class="btn-group">
                    <div ngbDropdown container="body" [autoClose]="'outside'" [placement]="'bottom-right'"
                        [display]="'dynamic'">
                        <button ngbDropdownToggle
                            class="btn btn-outline-primary dropdown-toggle d-inline-flex align-items-center mr-3">
                            <span class="material-icons mr-1">visibility</span>{{ 'ServiceContractListV2_visibility' |
                            translate }}
                        </button>
                        <div ngbDropdownMenu>
                            <div ngbDropdownItem *ngFor="let item of listConfigColTable" class="p-0">
                                <div class="tcheck-primary px-3">
                                    <input type="checkbox" id="{{ item.name }}" [checked]="configColTable[item.name]">
                                    <label for="{{ item.name }}" class="w-100"
                                        [class.checked]="configColTable[item.name]"
                                        (click)="actConfigColTableView($event, item.name)">
                                        {{ item.label | translate }}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr class="my-2">
    </section>
    <section class="px-3 my-2">
        <form [formGroup]="formFilter">
            <div class="row">
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="statusGCS">{{ 'ServiceContractListV2_customer_type' | translate }}</label>
                        <select formControlName="customerType" class="form-control">
                            <option value="">{{ 'ServiceContractListV2_all' | translate }}</option>
                            <option value="CONSUMER">{{ 'ServiceContractListV2_customer_consumer' | translate }}
                            </option>
                            <option value="BUSINESS">{{ 'ServiceContractListV2_customer_business' | translate }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="">{{ 'ServiceContractListV2_date_from' | translate }}</label>
                        <div class="input-group">
                            <input #fromDatePicker class="form-control" [control]="formFilter.controls.fromDate"
                                [value]="formFilter.value.fromDate"
                                (dateSelect)="changeDateFilter($event, dateType.CREATED_FROM)" placeholder="dd/MM/yyyy"
                                ngbDatepicker #createdFromDate="ngbDatepicker"
                                [maxDate]="{year: currentDate.getFullYear(), month: currentDate.getMonth()+1, day: currentDate.getDate()}"
                                appInputMark [options]="optionsCleave">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" (click)="createdFromDate.toggle()"
                                    type="button"><em class="fas fa-calendar-alt"></em></button>
                            </div>
                            <app-show-validate-errors [useSubmit]="false" [errorMessages]="validateSearch.fromDate"
                                [control]="formFilter.controls.fromDate" [detail]="{ name: 'fromDate' }">
                            </app-show-validate-errors>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="">{{ 'ServiceContractListV2_date_to' | translate }}</label>
                        <div class="input-group">
                            <input #toDatePicker class="form-control" [control]="formFilter.controls.toDate"
                                [value]="formFilter.value.toDate"
                                (dateSelect)="changeDateFilter($event, dateType.CREATED_TO)" placeholder="dd/MM/yyyy"
                                ngbDatepicker #createdToDate="ngbDatepicker"
                                [maxDate]="{year: currentDate.getFullYear(), month: currentDate.getMonth()+1, day: currentDate.getDate()}"
                                appInputMark [options]="optionsCleave">
                            <div class="input-group-append">
                                <button class="btn btn-outline-secondary" (click)="createdToDate.toggle()"
                                    type="button"><em class="fas fa-calendar-alt"></em></button>
                            </div>
                            <app-show-validate-errors [useSubmit]="false" [errorMessages]="validateSearch.toDate"
                                [control]="formFilter.controls.toDate" [detail]="{ name: 'toDate' }">
                            </app-show-validate-errors>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="status">{{ 'status' | translate }}</label>
                        <select formControlName="status" class="form-control">
                            <option value="" selected>{{ 'ServiceContractListV2_all' | translate }}</option>
                            <option value="LC_DRAFT_CREATE">{{ 'ServiceContractListV2_stt_1' | translate }}</option>
                            <option value="LC_DRAFT_DISCUSS">{{ 'ServiceContractListV2_stt_2' | translate }}</option>
                            <option value="LC_DRAFT_DEAL">{{ 'ServiceContractListV2_stt_3' | translate }}</option>
                            <option value="LC_DRAFT_SUBMIT">{{ 'ServiceContractListV2_stt_4' | translate }}</option>
                            <option value="LC_DRAFT_CANCEL">{{ 'ServiceContractListV2_stt_5' | translate }}</option>
                            <option value="LC_CONTRACT_VALID">{{ 'ServiceContractListV2_stt_6' | translate }}</option>
                        </select>
                    </div>
                </div>

                <!-- Chọn loại hợp đồng -->
                <!-- <div *ngIf="_isAccLevel2 && _by === 'OWNER'" class="col-md-3">
                    <div class="form-group">
                        <label for="status">{{ 'ContractTypes_list_title' | translate }}</label>
                        <ng-select formControlName="typeId" [items]="dropListTypeData | async"
                            bindValue="contractTypeId" bindLabel="name"
                            [clearable]="false" [trackByFn]="trackByFn1" [loading]="typesLoading"
                            [typeahead]="typeInput$" (change)="changeType($event)"
                            (scrollToEnd)="onScrollToEnd()">
                        </ng-select>
                    </div>
                </div> -->

                <!-- Chọn lượt ký -->
                <div class="col-md-3">
                    <div class="form-group">
                        <label for="signTure">{{ 'ServiceContractListV2_sign_turn' | translate }}</label>
                        <ng-select formControlName="signTurn" [items]="signTurnOptions" bindValue="value"
                            bindLabel="label" [searchable]="false" [clearable]="false">
                            <ng-template ng-label-tmp let-item="item">
                                {{item.label | translate}}
                            </ng-template>
                            <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
                                <span>{{item.label | translate}}</span>
                            </ng-template>
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-12">
                    <div class="d-flex">
                        <div class="ml-auto">
                            <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="cleanFilter()"> {{
                                'btn_reset' | translate }}</button>
                            <button class="btn btn-primary d-inline-flex" (click)="searchWithFilter()"> {{ 'btn_apply' |
                                translate }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </section>

    <section class="card-body p-0">
        <div class="table-responsive">
            <table class="table table-sort-col mb-0">
                <thead>
                    <tr class="bg-tb-header">
                        <th></th>
                        <th>{{ 'ServiceContractListV2_table_header_1' | translate }}</th>
                        <th [class.d-none]="!configColTable.soHopDong" (click)="sortCol('contractNumber')">
                            <span class="d-flex align-self-center align-items-center">
                                Số hợp đồng
                                <span *ngIf="this.sortColConfig['contractNumber'] == null" class="material-icons ml-auto">
                                    unfold_more
                                </span>
                                <span *ngIf="this.sortColConfig['contractNumber'] == true" class="material-icons ml-auto">
                                    expand_less
                                </span>
                                <span *ngIf="this.sortColConfig['contractNumber'] == false" class="material-icons ml-auto">
                                    expand_more
                                </span>
                            </span>
                        </th>
                        <th [class.d-none]="!configColTable.tenHopDong" (click)="sortCol('title')">
                            <span class="d-flex align-self-center align-items-center">
                                {{ 'ServiceContractListV2_table_header_2' | translate }}
                                <span *ngIf="this.sortColConfig['title'] == null" class="material-icons ml-auto">
                                    unfold_more
                                </span>
                                <span *ngIf="this.sortColConfig['title'] == true" class="material-icons ml-auto">
                                    expand_less
                                </span>
                                <span *ngIf="this.sortColConfig['title'] == false" class="material-icons ml-auto">
                                    expand_more
                                </span>
                            </span>
                        </th>
                        <!-- (click)="sortCol('partnersCount')" -->
                        <th [class.d-none]="!configColTable.soBenThamGia">
                            <span class="d-flex align-self-center align-items-center">
                                {{ 'ServiceContractListV2_table_header_3' | translate }}
                                <!-- <span *ngIf="this.sortColConfig['partnersCount'] == null" class="material-icons ml-auto">
                                    unfold_more
                                </span>
                                <span *ngIf="this.sortColConfig['partnersCount'] == true" class="material-icons ml-auto">
                                    expand_less
                                </span>
                                <span *ngIf="this.sortColConfig['partnersCount'] == false" class="material-icons ml-auto">
                                    expand_more
                                </span> -->
                            </span>
                        </th>
                        <th [class.d-none]="!configColTable.ngayChinhSua" (click)="sortCol('modified')">
                            <span class="d-flex align-self-center align-items-center">
                                {{ 'ServiceContractListV2_table_header_4' | translate }}
                                <span *ngIf="this.sortColConfig['modified'] == null" class="material-icons ml-auto">
                                    unfold_more
                                </span>
                                <span *ngIf="this.sortColConfig['modified'] == true" class="material-icons ml-auto">
                                    expand_less
                                </span>
                                <span *ngIf="this.sortColConfig['modified'] == false" class="material-icons ml-auto">
                                    expand_more
                                </span>
                            </span>
                        </th>
                        <th [class.d-none]="!configColTable.status" (click)="sortCol('currentStage')">
                            <span class="d-flex align-self-center align-items-center">
                                {{ 'status' | translate }}
                                <span *ngIf="this.sortColConfig['currentStage'] == null" class="material-icons ml-auto">
                                    unfold_more
                                </span>
                                <span *ngIf="this.sortColConfig['currentStage'] == true" class="material-icons ml-auto">
                                    expand_less
                                </span>
                                <span *ngIf="this.sortColConfig['currentStage'] == false"
                                    class="material-icons ml-auto">
                                    expand_more
                                </span>
                            </span>
                        </th>
                        <th>{{ 'ServiceContractListV2_table_header_5' | translate }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of dataTableExpand; let i = index"
                        [class.bg-tb-header]="item.isChild && item.isBody" [class.d-none]="item.isChild && item.hidden">
                        <td>
                            <button *ngIf="item.isParent" (click)="toggleShow(i)" class="btn btn-only-icon">
                                <span class="material-icons"
                                    [style.transform]="item.hidden ? 'rotate(0deg)' : 'rotate(90deg)'">
                                    chevron_right
                                </span>
                            </button>
                        </td>
                        <td>
                            <span *ngIf="item.isParent">{{ item.stt }}</span>
                        </td>
                        <td [class.d-none]="!configColTable.soHopDong">
                            <span *ngIf="item.isParent">{{ item?.contractNumber }}</span>
                        </td>
                        <td [class.d-none]="!configColTable.tenHopDong">
                            <div class="d-flex">
                                <a *ngIf="item.isParent" class="title-hyperlink" routerLinkActive="router-link-active"
                                    [routerLink]="['/app/console/contract',item.contractId]">{{ item.title }}</a>
                                <span *ngIf="item.attachment && item.attachment == 1"
                                    class="material-icons-outlined ml-1">
                                    attach_file
                                </span>
                            </div>
                            <span *ngIf="item.isChild && item.isHeader" class="font-weight-bold">{{ item.tr_3 |
                                translate }}</span>
                            <span *ngIf="item.isChild && item.isBody">{{ (item.donViQuanLy || item.doiTac ||
                                'ServiceContractListV2_no_name') | translate }}</span>

                        </td>
                        <td [class.d-none]="!configColTable.soBenThamGia">
                            <span *ngIf="item.isParent">{{ item.partners.length }}</span>
                            <span *ngIf="item.isChild && item.isHeader" class="font-weight-bold">{{ item.tr_4 |
                                translate }}</span>
                            <span *ngIf="item.isChild && item.isBody">{{ (item.loaiKhachHang === 'CONSUMER' ?
                                'ServiceContractListV2_customer_consumer' : 'ServiceContractListV2_customer_business') |
                                translate }}</span>
                        </td>
                        <td [class.d-none]="!configColTable.ngayChinhSua">
                            <span *ngIf="item.isParent">{{ item.modified | date: "dd/MM/yyyy" }}</span>
                            <span *ngIf="item.isChild && item.isHeader" class="font-weight-bold">{{ item.tr_5 |
                                translate}}</span>
                            <span *ngIf="item.isChild && item.isBody">{{ item.email }}</span>
                        </td>
                        <td [class.d-none]="!configColTable.status">
                            <span *ngIf="item.isParent"
                                class="badge {{ currentStage[item.currentStage].style || '' }} p-2">{{
                                (currentStage[item.currentStage].label | translate) || '' }}</span>
                            <br><span *ngIf="item.isParent" class="d-block mt-1">{{ item.signerEmail }}</span>
                        </td>
                        <td>
                            <div *ngIf="item.isParent" ngbDropdown container="body" placement="top-right">
                                <span class="material-icons cursor-pointer" ngbDropdownToggle
                                    id="settingTable">more_vert</span>
                                <!-- (click)="openDropdown($event)" -->
                                <div ngbDropdownMenu class="border-0">
                                    <a ngbDropdownItem (click)="goToDetailContract(item.contractId)"
                                        class="dropdown-item cursor-pointer d-flex">
                                        <span class="material-icons-outlined mr-3">info</span>
                                        {{ 'ServiceContractListV2_details' | translate }}</a>

                                    <a ngbDropdownItem *ngIf="item.currentStage == 'LC_DRAFT_WARNING' && _by == 'OWNER'" (click)="requestReSign(item.contractId)"
                                        class="dropdown-item cursor-pointer d-flex">
                                        <span class="material-icons-outlined mr-3">reply</span>
                                        {{ "ContractList_request_resign" | translate }}</a>
                                    <a ngbDropdownItem *ngIf="item.currentStage == 'LC_DRAFT_WARNING' && _by == 'OWNER'" (click)="acceptSignature(item.contractId)"
                                        class="dropdown-item cursor-pointer d-flex">
                                        <span class="material-icons-outlined mr-3">done</span>
                                        {{ "ContractList_Accept_signature" | translate }}</a>
                            
                                    <!-- && (item.currentStage == 'LC_DRAFT_SUBMIT' || item.currentStage == 'LC_DRAFT_SIGNED') -->
                                    <a ngbDropdownItem *ngIf="(item.readyforsign === 1 ) && !controlAcc.userInfo.isViewer"
                                        (click)="goToSign(item.contractId)"
                                        class="dropdown-item cursor-pointer d-flex"><span
                                            class="ct-icon-font icon-custom_signature mr-3"></span>{{
                                        'ServiceContractListV2_action_1' | translate }}</a>

                                    <a ngbDropdownItem *ngIf="(showSignAgreement()) && !controlAcc.userInfo.isViewer"
                                        (click)="goToSignAgreement(item.contractId)"
                                        class="dropdown-item cursor-pointer d-flex"><span
                                            class="ct-icon-font icon-custom_signature mr-3"></span>{{
                                        'ServiceContractListV2_action_2' | translate }}</a>

                                    <a ngbDropdownItem *ngIf="_by === 'OWNER' && item.currentStage == 'LC_DRAFT_CREATE' && !controlAcc.userInfo.isViewer"
                                        (click)="sendContract(item)" class="dropdown-item cursor-pointer d-flex"><span
                                            class="material-icons mr-3">send</span>{{ 'ServiceContractListV2_action_3' |
                                        translate }}</a>
                                    <!-- && (item.currentStage == 'LC_DRAFT_SUBMIT' || item.currentStage == 'LC_DRAFT_SIGNED') -->
                                    <a ngbDropdownItem *ngIf="item.readyforreturn === 1 && !controlAcc.userInfo.isViewer"
                                        class="dropdown-item cursor-pointer d-flex"
                                        (click)="openModalReturn(contentReturn, item.contractId)"><span
                                            class="material-icons-outlined mr-3">reply</span>{{
                                        'ServiceContractListV2_action_4' | translate }}</a>
                                    <!-- (item.currentStage == 'LC_DRAFT_DISCUSS' || item.currentStage == 'LC_DRAFT_DEAL') -->
                                    <a ngbDropdownItem *ngIf="item.readyfordeal === 1 && !controlAcc.userInfo.isViewer"
                                        (click)="postAcceptDeal(item.contractId)"
                                        class="dropdown-item cursor-pointer d-flex"><span
                                            class="material-icons-outlined mr-3">done</span>{{
                                        'ServiceContractListV2_action_5' | translate }}</a>
                                    <!-- && item.currentStage == 'LC_DRAFT_DISCUSS' -->
                                    <a ngbDropdownItem *ngIf="item.readyforupdatecontent === 1 && _by === 'OWNER' && !controlAcc.userInfo.isViewer"
                                        (click)="openPopupUpdateContractFile(item.contractId)"
                                        class="dropdown-item cursor-pointer d-flex"><span
                                            class="material-icons-outlined mr-3">update</span>{{
                                        'ServiceContractListV2_action_6' | translate }}</a>
                                    <!-- ( item.currentStage == 'LC_DRAFT_DEAL') -->
                                    <a ngbDropdownItem *ngIf="item.readyfordeny === 1 && !controlAcc.userInfo.isViewer"
                                        (click)="openModalDenyPartnerDiscuss(partnerDiscussDeny, item.contractId)"
                                        class="dropdown-item cursor-pointer d-flex"><span
                                            class="material-icons-outlined mr-3">clear</span>{{
                                        'ServiceContractListV2_action_7' | translate }}</a>

                                    <a ngbDropdownItem *ngIf="item.readyfordealInternal === 1 && !controlAcc.userInfo.isViewer"
                                        (click)="openModalDenyInternalDiscuss(internalDiscussDeny, item.contractId)"
                                        class="dropdown-item cursor-pointer d-flex"><span
                                            class="material-icons-outlined mr-3">clear</span>{{
                                        'ServiceContractListV2_action_8' | translate }}</a>

                                    <a ngbDropdownItem *ngIf="item.readyfordealInternal === 1 && !controlAcc.userInfo.isViewer"
                                        (click)="openModalDealInternalDiscuss(internalDiscussDeal, item.contractId)"
                                        class="dropdown-item cursor-pointer d-flex"><span
                                            class="material-icons-outlined mr-3">done</span>{{
                                        'ServiceContractListV2_action_9' | translate }}</a>

                                    <a ngbDropdownItem *ngIf="!hideDownloadBtn(item) && !controlAcc.userInfo.isViewer" (click)="getFileContract(item.contractId, item.currentStage)"
                                        class="dropdown-item cursor-pointer d-flex"><span
                                            class="material-icons-outlined mr-3">download</span>{{
                                        'ServiceContractListV2_action_10' | translate }}</a>

                                    <a *ngIf="!hasDecode(item.contractId) && item.currentStage === 'LC_CONTRACT_VALID' && !controlAcc.userInfo.isViewer"
                                        ngbDropdownItem (click)="turnOnEncode(item.contractId)"
                                        class="dropdown-item cursor-pointer d-flex"><span
                                            class="material-icons-outlined mr-3">line_style</span>{{
                                        'ServiceContractListV2_action_11' | translate }}</a>

                                    <a *ngIf="!controlAcc.userInfo.isViewer"
                                        ngbDropdownItem (click)="goToAttachDocument(item.contractId)"
                                        class="dropdown-item cursor-pointer d-flex"><span class="material-icons-outlined mr-3">attach_file</span>Đính kèm tài liệu</a>
                                    <a ngbDropdownItem *ngIf="item.readyforcancel=== 1 && auth.checkRoles(['ESO2008']) &&  _by === 'OWNER'&& !controlAcc.userInfo.isViewer" 
                                        class="dropdown-item cursor-pointer d-flex" (click)="openModalCancel(contentCancel,item.contractId)"><span class="material-icons-outlined mr-3">
                                        block
                                        </span>{{ "ContractListV2_cancel_contract" | translate }}</a>
                                    <a ngbDropdownItem *ngIf="item.currentStage == 'LC_DRAFT_CANCEL' &&  _by === 'OWNER' && auth.checkRoles(['ESO2009']) && !controlAcc.userInfo.isViewer" 
                                        class="dropdown-item cursor-pointer d-flex" (click)="undoContract(item)"><span class="material-icons-outlined mr-3">
                                        restore
                                        </span>{{ "ContractListV2_undo_contract_action" | translate }}</a>
                                    <!-- && item.currentStage == 'LC_DRAFT_CREATE' -->
                                    <a ngbDropdownItem *ngIf="item.readyfordelete === 1 &&  _by === 'OWNER' && auth.checkRoles(['ESO2007']) && !controlAcc.userInfo.isViewer"
                                        (click)="deleteContract(item.contractId)" class="dropdown-item cursor-pointer d-flex"><span
                                            class="material-icons-outlined mr-3">delete</span>{{ 'ServiceContractListV2_action_13' | translate }}</a>
                            
                                    <!-- <a *ngIf="this._by === 'OWNER'" (click)="createCoppies(item.contractId)" class="dropdown-item cursor-pointer d-flex"><span
                                                                    class="material-icons-outlined mr-3">control_point_duplicate</span>Tạo bản sao</a> -->
                                    <!-- && (item.currentStage == 'LC_DRAFT_DEAL' || item.currentStage == 'LC_DRAFT_DISCUSS') -->
                                    <!-- <a ngbDropdownItem 
                                        *ngIf="item.readyforcancel === 1 && _by === 'OWNER'"
                                        (click)="openModalCancel(contentCancel, item.contractId)" class="dropdown-item cursor-pointer d-flex"><span
                                            class="material-icons-outlined mr-3">block</span>Hủy hợp đồng</a> -->
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="bg-tb-header p-3">
            <div class="row align-items-center">
                <span *ngIf="totalElement == 0" class="col-md">{{ 'text_no_results' | translate }}</span>
                <span *ngIf="totalElement > 0" class="col-md">{{ 'text_number_of_results_displayed' | translate: {from:
                    1, to: listContract.length, total: totalElement} }}</span>
                <div class="col-md">
                    <div class="d-flex justify-content-end">
                        <div *ngIf="totalElement > 0" class="d-flex justify-content-end align-items-center">
                            <span class="mr-2">{{ 'text_number_of_lines' | translate }}:</span>
                            <ng-select class="no-bg no-border" [(ngModel)]="maxSize" [searchable]="false"
                                [clearable]="false" placeholder="..." (change)="loadData(1)">
                                <ng-option [value]="10">10</ng-option>
                                <ng-option [value]="20">20</ng-option>
                                <ng-option [value]="30">30</ng-option>
                            </ng-select>
                        </div>
                        <div *ngIf="totalElement > maxSize" class="pagination pages no-border-pagination ml-2">
                            <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="5"
                                [rotate]="true" [ellipses]="false" [pageSize]="maxSize" (pageChange)="loadData($event)"
                                [boundaryLinks]="true" aria-label="Default pagination">
                                <ng-template ngbPaginationFirst>{{ 'text_first' | translate }}</ng-template>
                                <ng-template ngbPaginationLast>{{ 'text_last' | translate }}</ng-template>
                                <ng-template ngbPaginationPrevious>&laquo;</ng-template>
                                <ng-template ngbPaginationNext>&raquo;</ng-template>
                                <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                            </ngb-pagination>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </section>
</div>

<!-- <div class="d-flex justify-content-end mt-3">
    <span *ngIf="totalElement == 0" class="flex-grow-1 ml-1 mb-3">Không tìm thấy kết quả</span>
    <span *ngIf="totalElement > 0" class="flex-grow-1 ml-1 mb-3">Hiển thị 1 đến {{listContract.length}} trong tổng số
        {{totalElement}} kết quả</span>
    <div *ngIf="totalElement > maxSize" class="pagination pages aas-pagination">
        <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="false"
            [pageSize]="maxSize" (pageChange)="loadData($event)" [boundaryLinks]="true" aria-label="Default pagination">
            <ng-template ngbPaginationFirst>Đầu</ng-template>
            <ng-template ngbPaginationLast>Cuối</ng-template>
            <ng-template ngbPaginationPrevious>&laquo;</ng-template>
            <ng-template ngbPaginationNext>&raquo;</ng-template>
            <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
        </ngb-pagination>
    </div>
</div> -->

<ng-template #contentCancel let-c="close" let-d="dismiss">
    <div class="modal-header border-0 pb-0 mb-0">
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span class="material-icons">
                cancel
            </span>
        </button>
    </div>
    <div class="modal-body py-0">
        <h5 class="modal-title w-100 text-center mb-3" id="modal-basic-title">{{ 'ServiceContractListV2_cancel' |
            translate }}</h5>
        <form [formGroup]="formCancel">
            <div class="form-group">
                <label>{{ 'ServiceContractListV2_reason_cancel' | translate }}: </label>
                <textarea formControlName="reason" class="form-control" rows="5"
                    placeholder="{{ 'ServiceContractListV2_ph_1' | translate }}"></textarea>
                <app-show-validate-errors [isSubmit]="isCancelSubmit" [errorMessages]="validMsgCancel.reason"
                    [control]="formCancel.controls.reason" [detail]="{ name: 'reason' }"></app-show-validate-errors>
            </div>
        </form>
    </div>
    <div class="modal-footer border-0 d-flex justify-content-center">
        <button type="button" class="btn btn-outline-primary" (click)="c('Close')">{{ 'btn_cancel' | translate
            }}</button>
        <button type="button" class="btn btn-primary" (click)="submitCancel()">{{ 'btn_confirm' | translate }}</button>
    </div>
</ng-template>
<ng-template #contentReturn let-c="close" let-d="dismiss">
    <div class="modal-header border-0">
        <h5 class="modal-title" style="font-weight: 600; margin-right: auto;">{{ 'ServiceContractListV2_back' |
            translate }}</h5>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="btn btn-light bg-exit-btn d-inline-flex"><span class="material-icons">
                    close
                </span></span>
        </button>
    </div>
    <div class="modal-body py-0">
        <form [formGroup]="formReturn">
            <div class="form-group">
                <label>{{ 'ServiceContractListV2_reason_back' | translate }}: </label>
                <textarea formControlName="reasonReturn" class="form-control" rows="5"
                    placeholder="{{ 'ServiceContractListV2_ph_2' | translate }}"></textarea>
                <app-show-validate-errors [isSubmit]="isCancelSubmit" [errorMessages]="validMsgCancel.reason"
                    [control]="formReturn.controls.reasonReturn" [detail]="{ name: 'reason' }">
                </app-show-validate-errors>
            </div>
        </form>
    </div>
    <div class="modal-footer border-0 d-flex justify-content-end">
        <button type="button" class="btn btn-outline-primary" (click)="c('Close')">{{ 'btn_cancel' | translate
            }}</button>
        <button type="button" class="btn btn-primary" (click)="submitReturn()">{{ 'btn_confirm' | translate }}</button>
    </div>
</ng-template>

<ng-template #updateContractFile let-c="close" let-d="dismiss">
    <div class="modal-header border-0">
        <h5 class="modal-title" style="font-weight: 600; margin-right: auto;">{{ 'ServiceContractListV2_change_content'
            | translate }}</h5>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="btn btn-light bg-exit-btn d-inline-flex"><span class="material-icons">
                    close
                </span></span>
        </button>
    </div>
    <div class="modal-body text-left py-0 enable-scroll">
        <div class="mb-0">
            <form [formGroup]="formUpdateContractFile">
                <p>{{ 'ServiceContractListV2_please' | translate }}.</p>
                <div class="form-group">
                    <label>{{ 'ServiceContractListV2_contract_file' | translate }}:</label>
                    <div class="input-group mb-1">
                        <div class="custom-file custom-file-icon">
                            <span class="icon-file material-icons">attach_file</span>
                            <input formControlName="file" (input)="handleFileInput($event)" type="file"
                                accept=".pdf,.docx" class="custom-file-input" id="inputGroupFile01"
                                aria-describedby="inputGroupFileAddon01">
                            <label class="custom-file-label" for="inputGroupFile01">{{ lbFieldContractFile | translate
                                }}</label>
                        </div>
                    </div>
                    <app-show-validate-errors [isSubmit]="isSubmitFormUpdateContractFile"
                        [errorMessages]="validMsgUpdateContractFile.file"
                        [control]="formUpdateContractFile.controls.file" [detail]="{ name: 'file' }">
                    </app-show-validate-errors>
                </div>
            </form>
        </div>
    </div>
    <div class="modal-footer border-0 d-flex justify-content-end">
        <button type="button" class="btn btn-outline-primary d-inline-flex" (click)="c('Cross click')">{{ 'btn_cancel' |
            translate }}</button>
        <button type="button" class="btn btn-primary d-inline-flex ml-3" ngbAutofocus
            (click)="confirmUpdateContractFile()">{{ 'ServiceContractListV2_btn_upload' | translate }}</button>
    </div>
</ng-template>

<ng-template #partnerDiscussDeny let-c="close" let-d="dismiss">
    <div class="modal-header border-0">
        <h5 class="modal-title" style="font-weight: 600; margin-right: auto;">{{ 'ServiceContractListV2_deny' |
            translate }}</h5>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="btn btn-light bg-exit-btn d-inline-flex"><span class="material-icons">
                    close
                </span></span>
        </button>
    </div>
    <div class="modal-body text-left py-0 enable-scroll">
        <form [formGroup]="formDenyDiscuss">
            <div class="form-group">
                <label>{{ 'ServiceContractListV2_reason_deny' | translate }}: </label>
                <textarea formControlName="reason" class="form-control" rows="5"
                    placeholder="{{ 'ServiceContractListV2_ph_3' | translate }}"></textarea>
                <app-show-validate-errors [useSubmit]="false" [errorMessages]="validateMsgFormDenyDiscuss.reason"
                    [control]="formDenyDiscuss.controls.reason" [detail]="{ name: 'reason' }">
                </app-show-validate-errors>
            </div>
        </form>
    </div>
    <div class="modal-footer border-0 d-flex justify-content-end">
        <button type="button" class="btn btn-outline-primary" (click)="c('Close')">{{ 'btn_cancel' | translate
            }}</button>
        <button type="button" class="btn btn-primary" [disabled]="formDenyDiscuss.invalid"
            (click)="confirmDenyContract()">{{ 'btn_confirm' | translate }}</button>
    </div>
</ng-template>

<ng-template #internalDiscussDeny let-c="close" let-d="dismiss">
    <div class="modal-header border-0">
        <h5 class="modal-title" style="font-weight: 600; margin-right: auto;">{{ 'ServiceContractListV2_deny' |
            translate }}</h5>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="btn btn-light bg-exit-btn d-inline-flex"><span class="material-icons">
                    close
                </span></span>
        </button>
    </div>
    <div class="modal-body text-left py-0 enable-scroll">
        <form [formGroup]="formDenyDiscuss">
            <div class="form-group">
                <label>{{ 'ServiceContractListV2_reason_deny' | translate }}: </label>
                <textarea formControlName="reason" class="form-control" rows="5"
                    placeholder="{{ 'ServiceContractListV2_ph_3' | translate }}"></textarea>
                <app-show-validate-errors [useSubmit]="false" [errorMessages]="validateMsgFormDenyDiscuss.reason"
                    [control]="formDenyDiscuss.controls.reason" [detail]="{ name: 'reason' }">
                </app-show-validate-errors>
            </div>
        </form>
        <div class="form-check">
            <input type="checkbox" [(ngModel)]="checkbox_addfooter" class="form-check-input" id="checkbox_addfooter">
            <label for="checkbox_addfooter">
                {{ 'ServiceContractListV2_internalDiscussDeny' | translate }}
            </label>
        </div>
    </div>
    <div class="modal-footer border-0 d-flex justify-content-end">
        <button type="button" class="btn btn-outline-primary" (click)="c('Close')">{{ 'btn_cancel' | translate
            }}</button>
        <button type="button" class="btn btn-primary" [disabled]="formDenyDiscuss.invalid"
            (click)="confirmDenyInternalDiscus()">{{ 'btn_confirm' | translate }}</button>
    </div>
</ng-template>

<app-request-resign 
    *ngIf="showRequestResignModal" 
    [partiesJoin]="partiesJoin" 
    [partyIdWantEdit]="partyIdWantEdit" 
    (closed)="closeRequestResign($event)"
    (sendRequestResignEvent)="sendRequestResignEvent($event)">
</app-request-resign>

<!-- Loc nang cao template || Advanced Filter template  -->
<ng-template #advancedFilter let-c="close" let-d="dismiss">
    <div style="min-width: 448px; min-height: auto;">
        <div class="modal-header border-0">
            <h5 class="modal-title" style="font-weight: 600; margin-right: auto;">{{
                'advancedFilter_title' |
                translate }}</h5>
            <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
                <span aria-hidden="true" class="btn btn-light bg-exit-btn d-inline-flex"><span class="material-icons">
                        close
                    </span></span>
            </button>
        </div>
        <div class="modal-body text-left py-0">
            <hr class="mt-0">
            <form [formGroup]="formAdvancedFilter">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label for="processUserId">{{ 'ServiceContractListV2_userSign' | translate }}</label>
                            <ng-select formControlName="processUserId" [items]="dropListUserData | async" bindValue="userId"
                                bindLabel="email" [trackByFn]="trackByFn1" [loading]="usersLoading" [typeahead]="employeesInput$"
                                (change)="changeUser($event)" (scrollToEnd)="onScrollToEndUser()" [clearable]="false">
                            </ng-select>
                        </div>
                    </div>
                </div>
            </form>
            <div class="row">
                <div class="col-md-12">
                    <label for="status">{{ 'ServiceContractListV2_signTurn_info' | translate }}</label>
                    <div class="rcheck-primary">
                        <input
                        [attr.disabled]="isDisabled ? '' : null"
                        [attr.checked]="checkValue == 4 ? '' : null" type="radio" id="rr4" name="type">
                        <label for="rr4" (click)="selectValue(4)">
                            Tất cả
                        </label>
                    </div>
                    <div class="rcheck-primary">
                        <input 
                        [attr.disabled]="isDisabled ? '' : null" 
                        [attr.checked]="checkValue == 1 ? '' : null" 
                        type="radio" 
                        name="type"
                        id="rr1" >
                        <label for="rr1" (click)="selectValue(1)">
                            Đã ký
                        </label>
                    </div>
                    <div class="rcheck-primary">
                        <input 
                        [attr.disabled]="isDisabled ? '' : null"
                        [attr.checked]="checkValue == 2 ? '' : null" type="radio" id="rr2" name="type">
                        <label for="rr2" (click)="selectValue(2)">
                            Chờ ký và đang đến lượt ký
                        </label>
                    </div>
                    <div class="rcheck-primary">
                        <input
                        [attr.disabled]="isDisabled ? '' : null"
                        [attr.checked]="checkValue == 3 ? '' : null" type="radio" id="rr3" name="type">
                        <label for="rr3" (click)="selectValue(3)">
                            Chờ ký và chưa đến lượt ký
                        </label>
                    </div>
                </div>
            </div>
            <hr class="my-2">
        </div>
        <div class="modal-footer border-0 d-flex justify-content-between">
            <div class="row w-100">
                <button class="col btn btn-outline-primary d-inline-flex mr-3 justify-content-center" style="width: 40%;" (click)="cleanAdvancedFilter()">
                    <span class="material-icons-outlined mr-2">
                    refresh
                    </span>{{
                    'btn_reset' | translate }}</button>
                <button class="col btn btn-primary d-inline-flex justify-content-center"  style="width: 40%;" (click)="searchWithAdvancedFilter()"> {{ 'btn_apply' |
                    translate }}</button>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #internalDiscussDeal let-c="close" let-d="dismiss">
    <div class="modal-header border-0">
        <h5 class="modal-title" style="font-weight: 600; margin-right: auto;">{{
            'ServiceContractListV2_internalDiscussDeal_confirm' |
            translate }}</h5>
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true" class="btn btn-light bg-exit-btn d-inline-flex"><span class="material-icons">
                    close
                </span></span>
        </button>
    </div>
    <div class="modal-body text-left py-0">
        <div> {{ 'contract_confirm_accept_internal_discuss' | translate}} </div>
        <div class="form-check">
            <input type="checkbox" [(ngModel)]="checkbox_addfooter" class="form-check-input" id="checkbox_addfooter">
            <label for="checkbox_addfooter">
                {{ 'ServiceContractListV2_internalDiscussDeal' | translate }}
            </label>
        </div>
    </div>
    <div class="modal-footer border-0 d-flex justify-content-end">
        <button type="button" class="btn btn-outline-primary" (click)="c('Close')">{{ 'btn_cancel' | translate
            }}</button>
        <button type="button" class="btn btn-primary" (click)="confirmDealInternalDiscus()">{{ 'btn_confirm' | translate
            }}</button>
    </div>
</ng-template>
