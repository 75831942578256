export class PartnerConsumer {
    trustedPartnerId: string;
    userId: string;
    partyId: string;
    ten: string;
    ngaySinh: string;
    email: string;
    soNha: string;
    duong: string;
    xa: string;
    huyen: string;
    tinh: string;
    donVi: string;
    chucVu: string;
    fullDiaChi: string;
    sdt: string;
    disable: any;
    cmnd: string;
    created: any;
    gioiTinh: any;
    ngayCap: any;
    noiCap: any;
    rejectReason: string;
    approveDate: any;
    videocall: any;
    customerId: any;
    approverEmail: any;
    partnerId: string = '';
    partnerVerificationId: string = '';
    verificationDate: string = '';
    verificationStatus: string = '';
    verificationType : string = '';
    verificationUser: string = '';
    identityLevel: number;
    username : string = '';
    fileName: string = '';
    title: string = '';
    contractId: string = '';
    currentStage: string = '';
    dkhktt: string = '';
    loaiGtId: string = '';
    gioiTinhId: number = 0;
    expiryDate: string = '';
    noiSinh: string = '';
    reason: string = '';
    refPartyId: string='';
    verificationUserTen: string = '';
    info: any;

    constructor(obj = null) {
        obj = obj || {};
        this.trustedPartnerId= obj.trustedPartnerId || "";
        this.userId = obj.userId || "";
        this.partyId = obj.partyId || "";
        this.ten = obj.ten || "";
        this.ngaySinh = obj.ngaySinh || "";
        this.email = obj.email || "";
        this.soNha = obj.soNha || "";
        this.duong = obj.duong || "";
        this.xa = obj.xa || "";
        this.huyen = obj.huyen || "";
        this.tinh = obj.tinh || "";
        this.donVi = obj.donVi || "";
        this.sdt = obj.sdt || "";
        this.chucVu = obj.chucVu || "";
        this.cmnd = obj.cmnd || "";
        this.created = obj.created || "";
        this.gioiTinh = obj.gioiTinh || "";
        this.ngayCap = obj.ngayCap || "";
        this.noiCap = obj.noiCap || "";
        this.rejectReason = obj.rejectReason || "";
        this.approveDate = obj.approveDate || "";
        this.videocall = obj.videocall || "";
        this.customerId = obj.customerId || "";
        this.approverEmail = obj.approverEmail || "";
        this.username = obj.username || "";
        this.info= obj.info || null;
        this.refPartyId=obj.refPartyId ||'';

        if (obj.disable === "Y" || obj.disable === true) {
            this.disable = true;
        } else {
            this.disable = false;
        }

        this.fullDiaChi = this.diaChi;

        this.partnerId = obj.partnerId || '';
        this.partnerVerificationId = obj.partnerVerificationId || '';
        this.verificationDate = obj.verificationDate || '';
        this.verificationStatus = obj.verificationStatus || '';
        this.verificationType = obj.verificationType || '';
        this.verificationUser = obj.verificationUser || '';
        this.identityLevel = obj.identityLevel;

        this.fileName = obj.fileName || '';
        this.title = obj.title || '';
        this.contractId = obj.contractId || '';
        this.currentStage = obj.currentStage || '';

        this.dkhktt = obj.dkhktt || '';
        this.loaiGtId = obj.loaiGtId || '';
        this.gioiTinhId = obj.gioiTinhId || 0;
        this.expiryDate = obj.expiryDate || '';
        this.noiSinh = obj.noiSinh || '';
        this.reason = obj.reason || '';
        this.verificationUserTen = obj.verificationUserTen || '';

    }

    get diaChi() {
        var diaChi = "";
        if (this.soNha !== "") {
            diaChi += this.soNha + " ";
        }

        if (this.duong !== "") {
            diaChi += this.duong + ", ";
        }

        if (this.xa !== "") {
            diaChi += this.xa + ", ";
        }

        if (this.huyen !== "") {
            diaChi += this.huyen + ", ";
        }

        if (this.tinh !== "") {
            diaChi += this.tinh + ".";
        }

        return diaChi;
    }
}