<div class="pt-3">
    <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-7">
                    <div class="card shadow-none">
                        <div class="card-body border-0">
                            <div class="row">
                                <div class="col-md-auto align-self-end">
                                    <label class="text-lg">Xác nhận đăng ký gói hạ tầng mở rộng</label>
                                </div>
                                <div class="col-md-auto ml-auto">
                                    <button class="btn btn-outline-primary d-inline-flex mr-3">
                                        <em class="material-icons nav-icon mr-2">arrow_back</em>
                                        Chọn gói khác
                                    </button>
                                    <button class="btn btn-primary d-inline-flex">
                                        <em class="material-icons nav-icon mr-2">done</em>
                                        Xác nhận
                                    </button>
                                </div>
                            </div>
                            
                            <div class="general-information mt-3">
                                <label class="text-primary">Thông tin chung</label>
                                <hr>
                                <div class="e-block-field row">
                                    <div class="col-md-5">
                                        <label class="e-lb-field">Tên gói dịch vụ</label>
                                    </div>
                                    <div class="col-md-7">
                                        <span class="">Gói nâng cấp cơ bản</span>
                                    </div>
                                </div>
                                <div class="e-block-field row">
                                    <div class="col-md-5">
                                        <label class="e-lb-field">Mô tả</label>
                                    </div>
                                    <div class="col-md-7">
                                        <span class="">Gói dịch vụ cơ bản cho khách hàng cá nhân</span>
                                    </div>
                                </div>
                                <div class="e-block-field row">
                                    <div class="col-md-5">
                                        <label class="e-lb-field">Giá tạm tính</label>
                                    </div>
                                    <div class="col-md-7">
                                        <span class="">234,000 đ</span>
                                    </div>
                                </div>
                                <div class="e-block-field row">
                                    <div class="col-md-5">
                                        <label class="e-lb-field">Giá tiền</label>
                                    </div>
                                    <div class="col-md-7">
                                        <span class="">400,000 đ</span>
                                    </div>
                                </div>
                            </div>
                            <div class="infra-information">
                                <label class="text-primary">Thông tin hạ tầng</label>
                                <hr>
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr class="table-header">
                                                <th>STT</th>
                                                <th>Thông số</th>
                                                <th>Đơn vị tính</th>
                                                <th>Số lượng</th>
                                                <th>Thành tiền</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr class="tr-body" *ngFor="let item of infraInformation; let i = index">
                                                <td><span *ngIf="i < 9">0</span>{{ i + 1 }}</td>
                                                <td>{{ item.thongSo }}</td>
                                                <td>{{ item.donViTinh }}</td>
                                                <td>
                                                    <form class="input-group border rounded" style="max-width: 120px;">
                                                        <input class="form-control border-0" type="text" style="text-align: right;"
                                                            placeholder="" [formControl]="soLuong">
                                                    </form>
                                                </td>
                                                <td>{{ item.thanhTien }}</td>
                                            </tr>
                                            <tr class="tr-body">
                                                <td></td>
                                                <td>Tổng cộng</td>
                                                <td></td>
                                                <td></td>
                                                <td>544,000</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="card shadow-none">
                        <div class="card-body border-0">
                           
                            <div class="using-infra-information">
                                <label class="text-primary">Thông tin hạ tầng đang sử dụng</label>
                                <hr>
                                <div class="e-block-field row">
                                    <div class="col-md-5">
                                        <label class="e-lb-field">Số lượng hợp đồng</label>
                                    </div>
                                    <div class="col-md-7">
                                        <span class="">61/100
                                            <span class="{{ getStyleOfRate(61) }}">(61%)</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="e-block-field row">
                                    <div class="col-md-5">
                                        <label class="e-lb-field">Số lượng SMS OTP</label>
                                    </div>
                                    <div class="col-md-7">
                                        <span class="">195/300
                                            <span class="{{ getStyleOfRate(65) }}">(65%)</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="e-block-field row">
                                    <div class="col-md-5">
                                        <label class="e-lb-field">Số lượng eKYC</label>
                                    </div>
                                    <div class="col-md-7">
                                        <span class="">118/150
                                            <span class="{{ getStyleOfRate(78) }}">(78%)</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="e-block-field row">
                                    <div class="col-md-5">
                                        <label class="e-lb-field">Thời gian lưu trữ (Tháng)</label>
                                    </div>
                                    <div class="col-md-7">
                                        <span class="">1/5
                                            <span class="{{ getStyleOfRate(20) }}">(20%)</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="e-block-field row">
                                    <div class="col-md-5">
                                        <label class="e-lb-field">Dung lượng lưu trữ (GB)</label>
                                    </div>
                                    <div class="col-md-7">
                                        <span class="">7/10
                                            <span class="{{ getStyleOfRate(70) }}">(70%)</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>
</div>