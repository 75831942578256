export class InfoSellerParty{
    tenToChuc: string;
    maSoThue: string;
    diaChi: string;

    constructor(obj = null) {
        this.tenToChuc = obj.tenToChuc || '';
        this.maSoThue = obj.maSoThue || '';
        this.diaChi = obj.diaChi || '';
    }
}