import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/utils/services/auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Output() toggleMenuSidebar: EventEmitter<any> = new EventEmitter<any>();

  public searchForm: FormGroup;
  path = '';
  
  constructor(
    private authService: AuthService,
    private activatedRoute: ActivatedRoute) {}

  ngOnInit() {
    this.path = this.activatedRoute.snapshot.url[0].path;
    this.searchForm = new FormGroup({
      search: new FormControl(null)
    });
  }

  logout() {
    this.authService.logout();
  }

  get getUsername() {
    return this.authService.username;
    // return this.authService.jwtDecode(this.authService.getToken()).email;
  }
}
