export class LandingConfigItem{
  public id: number;
  public configKey: string;
  public configValue: string;

  constructor(obj = null){
    obj = obj || {};
    this.id = obj.id || 0;
    this.configKey = obj.configKey || '';
    this.configValue = obj.configValue || '';
  }

}