import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appInputPassword]'
})
export class InputPasswordDirective {
  private _shown = false;

  constructor(
    private el: ElementRef
  ) { 
    this.setup();
  }

  setup() {
    const parent = this.el.nativeElement.parentNode;
    const span = document.createElement('span');
    const input = parent.getElementsByTagName('input');
    input[0].style.setProperty('padding-right', '2.5rem');
    span.classList.add('material-icons');
    span.classList.add('material-icons-lg');
    span.classList.add('icon-show-password');
    span.classList.add('icon-show-password-lg');
    span.classList.add('text-secondary');
    span.innerHTML = `visibility_off`;
    span.addEventListener('click', (event) => {
      this.toggle(span);
    });
    parent.appendChild(span);
  }

  toggle(span: HTMLElement) {
    this._shown = !this._shown;
    if (this._shown) {
      this.el.nativeElement.setAttribute('type', 'text');
      span.innerHTML = 'visibility';
    } else {
      this.el.nativeElement.setAttribute('type', 'password');
      span.innerHTML = 'visibility_off';
    }
  }
}
