import { ConstantAlertType } from './../../../../../../utils/common/constant-alert-type';
import { Component, OnInit, AfterViewInit, Renderer2, ViewChild, ElementRef, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/utils/services/auth.service';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { AasContractService } from 'src/app/utils/services/aas-network/aas-contract.service';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { ContractListItem } from 'src/app/utils/models/aas-models/contract/contract-list/contract-list-item.model';
import { ContractListResponse } from 'src/app/utils/models/aas-models/contract/contract-list/contract-list-response.model';
import { ContractStatus } from 'src/app/utils/common/constant';
import { DateUtils } from 'src/app/utils/common/DateUtils';
import { AasValidateMessage } from 'src/app/utils/common/validate-msg-aas';
import { Router } from '@angular/router';
import { ParamAddition } from 'src/app/utils/models/aas-models/api-manager/param/param.model';
import { REGEXP_DATE_INPUT } from 'src/app/utils/common/regexp';
import { ConstantAlertMsg, ConstantAlertBtn } from 'src/app/utils/common/constant-alert-msg';
import { ContractFileInfo } from 'src/app/utils/models/aas-models/contract/contract-file/contract-file.model';
import { EncodeService } from 'src/app/utils/common/encode-service';

declare var Cleave: any;
declare var $: any;
@Component({
  selector: 'app-admin-contract-list',
  templateUrl: './admin-contract-list.component.html',
  styleUrls: ['./admin-contract-list.component.scss']
})
export class AdminContractListComponent implements OnInit, AfterViewInit {
  @Input('by') _by: string = 'OWNER';

  @ViewChild('fromDatePicker') _fromDate: ElementRef;
  @ViewChild('toDatePicker') _toDate: ElementRef;
  @ViewChild('customerTypePicker') _customerType: ElementRef;
  @ViewChild('statusPicker') _statusPicker: ElementRef;
  @ViewChild('contentCancel') contentCancel: ElementRef;
  @ViewChild('contentReturn') contentReturn: ElementRef;

  formCancel: FormGroup;
  formReturn: FormGroup;
  validMsgCancel = AasValidateMessage.CANCEL_CONTRACT;
  isCancelSubmit: boolean = false;
  contractIdSelected: string =  '';

  partyId: string = this.auth.partyId;
  userId: string = this.auth.userId;

  formSearch: FormGroup;
  formFilter: FormGroup;
  dateType = {
    CREATED_FROM: 'CREATED_FROM',
    CREATED_TO: 'CREATED_TO'
  };
  validateSearch = {
    fromDate: {
      pattern: "Sai định dạng ngày tháng năm!",
      minDate: 'Từ ngày không được lớn hơn đến ngày và không được là ngày tương lai!'
    },
    toDate: {
      pattern: "Sai định dạng ngày tháng năm!"
    }
  };
  optionsCleave = {
    date: true,
    delimiter: '/',
    datePattern: ['d', 'm', 'Y']
  };

  currentDate = new Date();
  contractStatus: string = 'ALL';

  listConfigColTable: Array<any> = [
    {
      label: "Trạng thái",
      name: "status"
    },
    {
      label: "Số bên tham gia",
      name: "soBenThamGia"
    },
    {
      label: "Tên hợp đồng",
      name: "tenHopDong"
    },
    {
      label: "Ngày chỉnh sửa",
      name: "ngayChinhSua"
    }
  ];
  configColTable: any = {
    status: true,
    soBenThamGia: true,
    ngayChinhSua: true,
    tenHopDong: true,
  }

  dataTableExpand: Array<any> = [];
  page: number = 1;
  maxSize: number = 10;
  sort: string = "DESC";
  propertiesSort: string = "modified";
  totalElement: number = 0;
  listContract: Array<ContractListItem> = [];
  currentStage = ContractStatus;

  partnerId: string = '';
  serviceId: string = '';
  customerType: string = "";

  customerTypeFilter: string = '';
  fromDateFilter: string = '';
  toDateFilter: string = '';
  saveKeySearch: string = '';
  isSubmit: boolean = false;
  ServiceMsg: any = AasValidateMessage.CONTRACT_MANAGER_FILTER;

  selectedAll: boolean = false;

  zIndex = 5;

  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private aasAuth: AasAuthService,
    private aasContract: AasContractService,
    private modalService: NgbModal,
    private renderer2: Renderer2,
    private router: Router
  ) {
    this.renderer2.listen('window', 'click', (e: Event) => {
      var all = document.getElementsByClassName('table-sticky-col2');
      for (var i = 0; i < all.length; i++) {
        all[i].removeAttribute('style');
      }
    });
  }

  ngOnInit(): void {
    this.makeFormSearch();
    this.makeFormFilter();
    this.makeFormCancel();
    this.makeFormReturn();

    this.getSessionFilter();
    this.loadData()
  }

  ngAfterViewInit(): void {
    this.makeAutoFormatDate();

    // this._fromDate.nativeElement.value = sessionStorage.getItem('aas-contract-from-date-' + this._by) || '';
    // this._toDate.nativeElement.value = sessionStorage.getItem('aas-contract-to-date-' + this._by) || '';
    // this._customerType.nativeElement.value = sessionStorage.getItem('aas-contract-customer-type-' + this._by) || '';
    // this._statusPicker.nativeElement.value = sessionStorage.getItem('aas-contract-status-' + this._by) || '';
  }

  //MARK: khởi tạo form huỷ
  makeFormCancel() {
    this.formCancel = this.fb.group({
      reason: new FormControl('')
    });
  }

  get reason() { return this.formCancel.get('reason'); }

   //MARK: khởi tạo form trả lại
   makeFormReturn() {
    this.formReturn = this.fb.group({
      reasonReturn: new FormControl('')
    });
  }

  get reasonReturn() { return this.formReturn.get('reasonReturn'); }

  //MARK: Make input date format
  makeAutoFormatDate() {
  }

   // Hiển thị hợp đồng của khách hàng
   isShow = false
   showContract(item){
    item.isShow = !item.isShow;
    this.isShow= !this.isShow;
  }

  //MARK: GET session filter
  getSessionFilter() {
    // this.keySearch.setValue(sessionStorage.getItem('aas-contract-key-search-' + this._by) || '');
    // this.fromDate = sessionStorage.getItem('aas-contract-from-date-' + this._by) || '';
    // this.toDate = sessionStorage.getItem('aas-contract-to-date-' + this._by) || '';
    // this.customerType = sessionStorage.getItem('aas-contract-customer-type-' + this._by) || '';
    // this.status = sessionStorage.getItem('aas-contract-status-' + this._by) || '';
  }

  //MARK: Khởi tạo form search
  makeFormSearch() {
    this.formSearch = this.fb.group({
      search: new FormControl('')
    });
  }
  get keySearch() { return this.formSearch.get('search'); }

  /**
   * Khoi tao form filter
   */
  makeFormFilter() {
    this.formFilter = this.fb.group({
      customerType: new FormControl(''),
      toDate: new FormControl('', [Validators.pattern(REGEXP_DATE_INPUT)]),
      fromDate: new FormControl('', [Validators.pattern(REGEXP_DATE_INPUT)]),
      status: new FormControl('')
    });
  }

  get fromDate() {
    return DateUtils.convertInputToFormat(this.formFilter.value.fromDate, DateUtils.format.API);
  }
  get toDate() {
    return DateUtils.convertInputToFormat(this.formFilter.value.toDate, DateUtils.format.API);
  }
  get status() {
    return this.formFilter.value.status;
  }

    /**
   * Thay doi ngay tren filter
   * @param value 
   * @param type 
   */
  changeDateFilter(event, type) {
    switch (type) {
      case this.dateType.CREATED_FROM: 
        this.formFilter.controls.fromDate.setValue(DateUtils.convertObjtoDate(event));
        break;
      case this.dateType.CREATED_TO:
        this.formFilter.controls.toDate.setValue(DateUtils.convertObjtoDate(event));
        break;
    }
  }

  //MARK: Chức năg tìm kiếm
  eventInputSearch(event) {

  }

  actSearch(event) {
    this.page = 1;
    // this.saveKeySearch = this.keySearch.value.trim();
    // sessionStorage.setItem('aas-contract-key-search-' + this._by, this.saveKeySearch);
    this.loadData();
  }

  //MARK: Chức năng lọc dữ liệu

  //MARK: Apply filter
  searchWithFilter() {
    this.isSubmit = true;

    if (this.formFilter.valid) {
      let dateFrom = this.formFilter.value.fromDate.length > 0 ? new Date(DateUtils.convertStringtoDateSearch(this.formFilter.value.fromDate)).getTime() : -1;
      let dateTo = this.formFilter.value.toDate.length > 0 ? new Date(DateUtils.convertStringtoDateSearch(this.formFilter.value.toDate)).getTime() : -1;
      // console.log('from => ', dateFrom, ' to => ', dateTo);
      if ((dateTo > 0 && dateFrom > 0 && dateFrom > dateTo)) {
        this.formFilter.controls.fromDate.setErrors({ minDate: true });
      }
    }

    if (this.formFilter.valid) {
      // sessionStorage.setItem('aas-contract-from-date-' + this._by, this.fromDate);
      // sessionStorage.setItem('aas-contract-to-date-' + this._by, this.toDate);
      // sessionStorage.setItem('aas-contract-status-' + this._by, this.status);
      // sessionStorage.setItem('aas-contract-customer-type-' + this._by, this.customerType);
      console.log(this.formFilter.value);
      this.loadData();
    }
  }

  cleanFilter() {
    this.isSubmit = false;
    this._fromDate.nativeElement.value = '';
    this._toDate.nativeElement.value = '';
    this.formFilter.reset();
    this.formFilter.controls.customerType.setValue('');
    this.formFilter.controls.fromDate.setValue('');
    this.formFilter.controls.toDate.setValue('');
    this.formFilter.controls.status.setValue('');
    this.searchWithFilter()
  }

  //MARK: Loadpage
  loadData(page = 1) {
    this.page = page;
    this.getListContact(
      this.userId,
      this.partnerId,
      this.serviceId,
      this.fromDate,
      this.toDate,
      this.status,
      this.keySearch.value,
      this.page,
      this.maxSize,
      this.sort,
      this.propertiesSort);
  }

  progressTableData(listContract: Array<ContractListItem>) {
    this.dataTableExpand = [];
    listContract.forEach((contract, index) => {
      let param: any = new Object(contract);
      param.stt = (this.page - 1) * 10 + index + 1;
      param.isParent = true;
      param.isChild = false;
      param.isHeader = false;
      param.isBody = false;
      param.hidden = true;
      this.dataTableExpand.push(contract);

      if (contract.partners.length > 0) {
        //Them header phan expand
        this.dataTableExpand.push({
          "tr_1": "",
          "tr_2": "",
          "tr_3": "Tên bên tham gia hợp đồng",
          "tr_4": "Loại khách hàng",
          "tr_5": "Email",
          "tr_6": "",
          "tr_7": "",
          "isParent": false,
          "isChild": true,
          "isHeader": true,
          "isBody": false,
          "hidden": true
        });

        //Them body phan expand
        for(let partner of contract.partners) {
          let param: any = new  Object(partner);

          param.isParent = false;
          param.isChild = true;
          param.isHeader = false;
          param.isBody = true;
          param.hidden = true;

          this.dataTableExpand.push(param);
        }
      }
    });
  }

  //MARK: Action cấu hình hiển thị các hình thức filter
  // actConfigFilter(e, field) {
  //   e.stopPropagation();
  //   if (this.configFilter[field] !== null && this.configFilter[field] !== undefined) {
  //     this.configFilter[field] = !this.configFilter[field];
  //   }
  // }

  //MARK: Action cấu hình hiển thị cột của bảng
  actConfigColTableView(e, field) {
    e.stopPropagation();
    if (this.configColTable[field] !== null && this.configColTable[field] !== undefined) {
      this.configColTable[field] = !this.configColTable[field];
    }
  }

  //MARK: Action dropdown từng dòng
  openDropdown(event) {
    console.log(event);
    // var all = document.getElementsByClassName('table-sticky-col2');
    // for (var i = 0; i < all.length; i++) {
    //   all[i].removeAttribute('style');
    // }

    event.path[3].setAttribute("style", "z-index: " + ++this.zIndex + ";");
    // event.path[3].children[1].classList.add('show');
  }

  //MARK: Chọn dòng
  selectRow(event, id) {
    if (event.toElement.classList.contains('checked')) {
      event.toElement.classList.remove('checked');
      this.selectedAll = false;
    } else {
      event.toElement.classList.add('checked');
    }
  }

  //MARK: Chọn tất cả các dòng
  selectAll(event) {
    this.selectedAll = !this.selectedAll;

    this.listContract.forEach((item, index) => {
      var el = document.getElementById('label-for-contract-' + item.contractId);
      this.selectedAll ? el.classList.add('checked') : el.classList.remove('checked');
    })
  }

  //Xem chi tiet
  goToDetailContract(id) {
    this.router.navigate(['/console/contract', id]);
  }

  //Ký hợp đồng
  goToSign(id) {
    this.router.navigate(['/console/contract', id], { queryParams: { 'action': 'SIGN' } })
  }

  //downloadFileContract
  getFileContract(id, status) {
    // if (status === 'LC_CONTRACT_VALID') {
    //   this.downloadFileContract(id, 'CONTRACT');
    // } else {
      // this.downloadFileContract(id, 'DRAFT');
    // }

    if (status === 'LC_CONTRACT_VALID') {
      // this.downloadFileContract(id, 'CONTRACT');
      this.getContractFileInfo(id, 'CONTRACT', id);
      // this.getListSignatureByContract(id, 'CONTRACT');
    } else {
      // this.downloadFileContract(id, 'DRAFT');
      this.getContractFileInfo(id, 'DRAFT', id);
      // this.getListSignatureByContract(id, 'DRAFT');
    }
  }

  /**
   * Lay thogn tin file hop dong
   * @param id 
   * @param type 
   * @param fileKey 
   */
  getContractFileInfo(id, type, fileKey) {
    this.aasContract.getContractFileInfo(id, type, fileKey).subscribe((res: any) => {
      var contractFileInfo = new ContractFileInfo(res.object);
      var keySend = contractFileInfo.keySend;

      this.downloadContractFileByUrl(contractFileInfo.url, type, keySend, id);
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    })
  }

  /**
   * Tai file hop dong tu url
   * @param url 
   */
  downloadContractFileByUrl(url, type, keySend, id) {
    this.aasContract.downloadContractFileByUrl(url).subscribe((res: any) => {
      const blob: any = new Blob([res]);

      if (keySend) {
        //Neu ton tai keySend
        //Tien hanh giai ma
        let secretKey = EncodeService.rsa.decrypt(this.auth.key, keySend);
        this.loadFile(secretKey, blob, id);
      } else {
        //Neu khong ton tai keySend
        //Tien hanh luu file file PDF
        var url = window.URL.createObjectURL(blob);
        var anchor = document.createElement("a");
        anchor.download = `${id}.pdf`;
        anchor.href = url;
        anchor.click();
      }
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    })
  }

  /**
   * Giai ma file
   * @param secretKey 
   * @param fileEncoded 
   */
  loadFile(secretKey, fileEncoded, id) {
    var self = this;
    var reader = new FileReader();
    reader.onload = function () {
      var result: any = reader.result;
      var _base64DecodeRes = Buffer.from(result, 'base64');
      var iv = _base64DecodeRes.slice(0, 16);
      var dataEncrypt = _base64DecodeRes.slice(16, _base64DecodeRes.byteLength);

      // console.log(result, result.slice(0, 16));
      var typedArray = EncodeService.aes.decrypt(dataEncrypt, secretKey, iv);

      const bufferpdf = Buffer.from(typedArray);
      const blob: any = new Blob([bufferpdf], { type: 'application/pdf' });
      var url = window.URL.createObjectURL(blob);
      var anchor = document.createElement("a");
      anchor.download = `${id}.pdf`;
      anchor.href = url;
      anchor.click();
    }
    reader.readAsText(fileEncoded);
  }

  //MARK: CHUC NANG HUY HOP DONG
  //Show popup confirm OTP
  openModalCancel(content, contractId) {
    this.contractIdSelected = contractId;
    this.reason.setValue('');
    this.modalService.open(content, {
      size: 'md',
      backdrop: 'static',
      keyboard: false
    });
  }

  //Submit huy hop dong
  submitCancel() {
    this.isCancelSubmit = true;
    console.log(this.formCancel.value);
    if (this.formCancel.valid) {
      let param = {
        "cancelReason": this.reason.value
      }
      this.postCancelContract(this.contractIdSelected, param);
    }
  }

 //MARK: CHUC NANG TRẢ LẠI HOP DONG
  //Show popup confirm OTP
  openModalReturn(content, contractId) {
    this.contractIdSelected = contractId;
    this.reason.setValue('');
    this.modalService.open(content, {
      size: 'md',
      backdrop: 'static',
      // keyboard: false
    });
  }

  //Submit huy hop dong
  submitReturn() {
    this.isCancelSubmit = true;
    console.log(this.formReturn.value);
    if (this.formReturn.valid) {
      let param = {
        "denyReason": this.reasonReturn.value
      }
      this.postReturnContract(this.contractIdSelected, param);
    }
  }

  //Trả lại hợp đồng
  postReturnContract(contractId, param){
    this.alert('Bạn có chắn chắn muốn trả lại hợp đồng?', '', true, '', 'Đồng ý', () => {
      this.aasContract.postReturnContract(contractId, param).subscribe(res=>{
        this.loadData();
        this.alert('Trả lại hợp đồng thành công!', 'success', false, '', 'OK', () => {
          // this.modalService.dismissAll();
        });
      }, err => {
        console.log(err);
        this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
      })
    });
  }

   // Xóa hợp đồng
   deleteContract(contractId){
    this.alert('Bạn có chắn chắn xóa hợp đồng này?', '', true, '', 'Đồng ý', () => {
      this.aasContract.postDeleteContract(contractId).subscribe(res=>{
        this.loadData();
        this.alert('Xóa hợp đồng thành công!', 'success', false, '', 'OK', () => {
        });
      }, err => {
        console.log(err);
        this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
      })
    });
  } 

   // đồng ý thỏa thuận 
   postAcceptDeal(contractId){
    this.alert('Bạn có chắn chắn đồng ý thỏa thuận cho hợp đồng này?', '', true, '', 'Đồng ý', () => {
      this.aasContract.postAcceptDeal(contractId).subscribe(res=>{
        this.loadData();
        this.alert('Đồng ý thỏa thuận hợp đồng thành công!', 'success', false, '', 'OK', () => {
        });
      }, err => {
        console.log(err);
        this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
      })
    });
  } 

   // từ chối thỏa thuận
   postCancelDeal(contractId){
    this.alert('Bạn có chắn chắn từ chối thỏa thuận cho hợp đồng này?', '', true, '', 'Đồng ý', () => {
      this.aasContract.postCancelDeal(contractId).subscribe(res=>{
        this.loadData();
        this.alert('Từ chối thỏa thuận hợp đồng thành công!', 'success', false, '', 'OK', () => {
        });
      }, err => {
        console.log(err);
        this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
      })
    });
  } 

  //Gửi hợp đồng ký usbtoken
  sendContract(contract) {
    if (contract.currentStage === "LC_DRAFT_CREATE") {
      this.alert(ConstantAlertMsg.CONTRACT_CONFIRM_SEND_DRAFT, ConstantAlertType.BLANK, true, '', ConstantAlertBtn.CONFIRM, () => {
        //Gui hop dong nhap
        this.postSubmitSendDraft(contract.contractId);
      }, '', ConstantAlertBtn.CANCEL);
    }
  }

  /**
   * Submit gui ban nhap hop dong den cac ben
   * @param contractId 
   */
  postSubmitSendDraft(contractId) {
    this.aasContract.postSubmitSendDraft(contractId).subscribe((res) => {
      this.alert('Gửi hợp đồng thành công!', 'success', false, '', 'OK', () => {
      });
      this.loadData(this.page);
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    })
  }

  
  // tạo bản sao
  createCoppies(contractId){

  } 
  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], 'error');
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, 'error');
    } else {
      this.alert('Lỗi không xác định!', 'error');
    }
  }

  //MARK: NETWORKING
  getListContact(partyId, partnerId, serviceId, fromDate, toDate, status, keySearch, page, maxSize, sort, propertiesSort) {
    this.getListContractVip(
      partyId,
      partnerId,
      this.formFilter.value.customerType,
      serviceId,
      fromDate,
      toDate,
      status,
      keySearch,
      page,
      maxSize,
      sort,
      propertiesSort);
  }

  toggleShow(index) {
    this.dataTableExpand[index].hidden = !this.dataTableExpand[index].hidden;
    for (var i = index + 1; i < this.dataTableExpand.length; i++) {
      if (this.dataTableExpand[i].isParent) {
        break;
      }
      if (this.dataTableExpand[i].isChild) {
        this.dataTableExpand[i].hidden = this.dataTableExpand[index].hidden;
      }
    }
  }

  /**
   * Get danh sach hop dong admin
   * @param partyId 
   * @param partnerId 
   * @param partnerType 
   * @param serviceId 
   * @param fromDate 
   * @param toDate 
   * @param status 
   * @param keySearch 
   * @param page 
   * @param maxSize 
   * @param sort 
   * @param propertiesSort 
   */
  getListContractVip(partyId, partnerId, partnerType, serviceId, fromDate, toDate, status, keySearch, page, maxSize, sort, propertiesSort) {
    this.aasContract.getListContractVip(partyId, partnerId, partnerType, serviceId, fromDate, toDate, status, keySearch, page, maxSize, sort, propertiesSort).subscribe(res => {
      var response = new ContractListResponse(res);

      this.listContract = response.object.data;

      this.progressTableData(this.listContract);

      this.totalElement = response.object.totalElement;

    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    });
  }

  getListContactByOwner(partyId, partnerId, partnerType, serviceId, fromDate, toDate, status, keySearch, page, maxSize, sort, propertiesSort) {
    this.aasContract.getListContactByOwner(partyId, partnerId, partnerType, serviceId, fromDate, toDate, status, keySearch, page, maxSize, sort, propertiesSort).subscribe(res => {
      var response = new ContractListResponse(res);

      this.listContract = response.object.data;

      this.progressTableData(this.listContract);

      this.totalElement = response.object.totalElement;

    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    });
  }

  getListContactByAssign(partyId, partnerId, partnerType, serviceId, fromDate, toDate, status, keySearch, page, maxSize, sort, propertiesSort) {
    this.aasContract.getListContactByAssign(partyId, partnerId, partnerType, serviceId, fromDate, toDate, status, keySearch, page, maxSize, sort, propertiesSort).subscribe(res => {
      var response = new ContractListResponse(res);

      this.listContract = response.object.data;
      this.progressTableData(this.listContract);

      this.totalElement = response.object.totalElement;

    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    });
  }

  //Tải file hợp đồng 
  downloadFileContract(id, type) {
    this.aasContract.downloadFileContract(id, type).subscribe((res) => {
      const blob: any = new Blob([res], { type: 'application/pdf' });
      var url = window.URL.createObjectURL(blob);
      var anchor = document.createElement("a");
      anchor.download = `${id}.pdf`;
      anchor.href = url;
      anchor.click();
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    })
  }

  //Huỷ hợp đồng
  postCancelContract(contractId, param) {
    this.aasContract.postCancelContract(contractId, param).subscribe(res => {
      this.loadData();
      this.alert('Huỷ hợp đồng thành công!', 'success', false, '', 'OK', () => {
        this.modalService.dismissAll();
      });
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    })
  }
}