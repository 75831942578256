import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfigItem, ListCheckBox, ListTable } from 'src/app/utils/common/TemplateManager';
import { BBoxOfKeyResponse } from 'src/app/utils/models/template/bbox-of-key/bbox-of-key-response.model';
import { TemplateService } from 'src/app/utils/services/aas-network/template.service';

const _markViewerHTML =
  '<div id="mark-viewer-page" class="mark-viewer-page">' +
  ' <div class="mark-viewer-content">' +
  '   <div class="mark-viewer-header">' +
  '     <em class="mark-viewer-exit fas fa-times-circle" style="cursor: pointer;"></em>' +
  '   </div>' +
  '   <div class="mark-viewer-body">' +
  '     <div class="d-flex justify-content-center">' +
  '       <div class="mark-loading spinner-grow text-primary" role="status">' +
  '         <span class="sr-only">Loading...</span>' +
  '       </div>' +
  '     </div>' +
  // '     <div id="mark-ppi" style="height: 1in; width: 1in; position: absolute; top: -100%; left: -100%;"></div>' +
  '     <div id="markView" class="page-viewer if-previewpdf" style="height: 365px">' +
  '     </div>' +
  '     </div>' +
  '   </div>' +
  '</div>';

declare var $: any;
declare var pdfShowMark: any;
@Component({
  selector: 'app-fill-data-contract-template',
  templateUrl: './fill-data-contract-template.component.html',
  styleUrls: ['./fill-data-contract-template.component.scss']
})
export class FillDataContractTemplateComponent implements OnInit {
  @Input('tableListNoFilled') tableListNoFilled: string[] = [];
  @Input('tableObjectListNoFilled') tableObjectListNoFilled: ListTable = {};
  @Input('textBoxListNoFilled') textBoxListNoFilled: ConfigItem[] = [];
  @Input('checkBoxesNoFilled') checkBoxesNoFilled: string[] = [];
  @Input('checkBoxListNoFilled') checkBoxListNoFilled: ListCheckBox = {};
  @Input('paramRenderByStep') paramRenderByStep: Object[] = [new Object(), new Object(), new Object(), new Object(), new Object(), new Object()];
  @Input('currentStep') currentStep: any = 4;
  @Input('idTemplate') idTemplate: string = "";
  @Input('pdfFile') pdfFile: Blob = new Blob();

  @Output() public onActionNextButton: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onActionBackButton: EventEmitter<any> = new EventEmitter<any>();

  fileBlob = new Blob();

  constructor(
    private templateService: TemplateService
  ) { }

  ngOnInit(): void {
    pdfShowMark.initPlugin();
    $('#mark-viewer-page').remove();
  }

  ngAfterViewInit(): void {
    this.initMarkEvent();
  }

  /**
   * Replace giá trị từ file excel vào form
   * @param param 
   */
  replaceValueWithExcel(param) {
    for (const [key, value] of Object.entries(param)) {
      if (this.paramRenderByStep[this.currentStep][key] != null && this.paramRenderByStep[this.currentStep][key] != undefined) {
        // && this.paramRenderByStep[this.currentStep][key] == ""
        if (param[key] != null && param[key] != undefined && param[key] != "") {
          if (key.charAt(0) === '#') {
            
          } else if (key.charAt(0) === '$') {
            //Tìm config có key trùng với key trong json
            let config = this.textBoxListNoFilled.find((item) => {
              return item.key === key;
            });

            //Nếu chưa có mapping với biến khác
            if (config) {
              // Tạm bỏ điều kiện không có mapping
              // && config.key === config.nameMap
              this.paramRenderByStep[this.currentStep][key] = value as any;
            }
          }
        }
      }
    }
  }

  inputConfig(key, value) {
    var paramRender = this.paramRenderByStep[this.currentStep];
    paramRender[key] = value;
  }

  actionCheckbox(group, key) {
    var paramRender = this.paramRenderByStep[this.currentStep];
    if (paramRender[group][key] === '') paramRender[group][key] = 1;
    else {
      if (paramRender[group][key] === 1) paramRender[group][key] = 0;
      else {
        paramRender[group][key] = 1;
      }
    }
  }

  /**
   * Xóa 1 dòng table
   * @param table 
   * @param index 
   */
  deleteRow(table, index) {
    var paramRender = this.paramRenderByStep[this.currentStep];
    paramRender[table].splice(index, 1);
  }

  /**
   * Thêm 1 dòng table
   * @param table 
   */
  addColumnToTable(table) {
    var tmp = new Object();
    var paramRender = this.paramRenderByStep[this.currentStep];
    this.tableObjectListNoFilled[table].forEach((item) => {
      tmp[item.key] = '';
    })
    paramRender[table].push(tmp);
  }

  inputTable(e, table, i, key) {
    var paramRender = this.paramRenderByStep[this.currentStep];
    paramRender[table][i][key] = `${e.target.textContent}`.trim();
    e.target.textContent = `${e.target.textContent}`.trim();
  }

  //MAKR: Next, Back feature
  nextAction() {
    console.log("Next child", this.paramRenderByStep[this.currentStep]);
    this.onActionNextButton.emit({
      currentStep: this.currentStep,
      paramRender: this.paramRenderByStep[this.currentStep]
    })
  }

  backAction() {
    this.onActionBackButton.emit({
      currentStep: this.currentStep,
      paramRender: this.paramRenderByStep[this.currentStep]
    })
  }

  public getParamRender() {
    return this.paramRenderByStep[this.currentStep];
  }

  //MARK: Hiển thị đánh dấu vị trí biến cần điền giá trị
  showMark(key) {
    this.templateService.getBoxOfKey(this.idTemplate, {
      'key': key
    }).subscribe((res) => {
      const response = new BBoxOfKeyResponse(res);
      var arrPages = [];
      response.object.forEach(item => {
        let temp = arrPages.findIndex((page) => { return page === item.page + 1 });
        if (temp < 0) {
          arrPages.push(item.page + 1);
        }
      });

      pdfShowMark.renderPdfWithPageNumber(this.pdfFile, arrPages, response.object);

      $('.mark-loading').hide();
    }, err => {
      console.log(err);
      $('.mark-loading').hide();
    }, () => {
      $('.mark-loading').hide();
    });
    // pdfShowMark.renderPdfWithPageNumber(this.pdfFile, [1]);
  }

  /**
   * Init jquery event kích vào icon định vị biến
   */
  initMarkEvent() {
    var self = this;
    $('body').append(_markViewerHTML);

    $('.btn-show-mark').click(function (e) {
      e.stopPropagation();
      self.initMarkView();

      var maxw = 500;
      var left = $(this).offset().left;
      var top = $(this).offset().top;
      var w = $(this).parent().parent().width();
      var h = $(this).parent().parent().height();
      left = $(this).parent().parent().offset().left;
      if (maxw > w) {
        left = left;
      } else if (maxw <= w) {
        left = left + (w - maxw);
      }
      top = top + h + 5;

      $("#mark-viewer-page").css({
        'display': 'inline-block',
        'width': maxw,
        'left': left,
        'top': top
      });
    });

    $('.item-show-mark-tb').click(function (e) {
      e.stopPropagation();
      self.initMarkView();

      var maxw = 500;
      var left = $(this).parent().offset().left;
      var top = $(this).parent().offset().top;
      var w = $(this).parent().parent().width();
      var h = $(this).parent().parent().height();
      left = $(this).parent().parent().offset().left;
      if (maxw > w) {
        left = left - (maxw - w) >= 250 ? left - (maxw - w) : left;
      } else if (maxw <= w) {
        left = left + (w - maxw) >= 250 ? left - (w - maxw) : left;
      }
      top = top + h + 5;

      $("#mark-viewer-page").css({
        'display': 'inline-block',
        'width': maxw,
        'left': left,
        'top': top
      });
    });

    $('.btn-show-mark-checkbox').click(function (e) {
      e.stopPropagation();
      self.initMarkView();

      var maxw = 500;
      var left = $(this).parent().offset().left;
      var top = $(this).parent().offset().top;
      var w = $(this).parent().parent().width();
      var h = $(this).parent().parent().height();
      left = $(this).parent().parent().offset().left;
      console.log("left ", left);
      console.log("top ", top);
      console.log("w ", w);
      console.log("h ", h);
      // if (maxw > w) {
      //   left = left - (maxw - w) >= 250 ? left - (maxw - w) : left;
      // } else if (maxw <= w) {
      //   left = left + (w - maxw) >= 250 ? left - (w - maxw) : left;
      // }
      top = top + h + 5;

      $("#mark-viewer-page").css({
        'display': 'inline-block',
        'width': maxw,
        'left': left,
        'top': top
      });
    });

    $("#mark-viewer-page").click(function (e) {
      e.stopPropagation();
    })

    $(document).click(function () {
      $("#mark-viewer-page").hide(); //click came from somewhere else
    });

    $('.mark-viewer-exit').click(function () {
      $("#mark-viewer-page").hide(); //click exit
    });
  }

  /**
   * Init markView trước khi load xong data
   */
  initMarkView() {
    document.getElementById('markView').innerHTML = '';
    // $('#markView').css({
    //   'height': "50px"
    // });
    $('.mark-loading').show();
  }

  goToBack() {
    this.onActionBackButton.emit(null);
  }
}
