import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LandingConfigItem } from 'src/app/utils/models/landing-page/landing-config-item.model';
import { LandingpageService } from 'src/app/utils/services/aas-network/landingpage.service';
import { LANDING_CONFIG_FORM } from 'src/app/utils/common/constant-vaildate-msg';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LandingConfigResponse } from 'src/app/utils/models/landing-page/landing-config-response.model';

@Component({
  selector: 'app-service-landing-page-config-edit',
  templateUrl: './service-landing-page-config-edit.component.html',
  styleUrls: ['./service-landing-page-config-edit.component.scss']
})
export class ServiceLandingPageConfigEditComponent implements OnInit {

  configKey = this.activatedRouter.snapshot.paramMap.get('id');

  formEdit: FormGroup;
  configItem: LandingConfigItem;
  validMsg = LANDING_CONFIG_FORM.EDIT;
  isSubmit = false;

  constructor(
    private fb: FormBuilder,
    private landingService: LandingpageService,
    private activatedRouter: ActivatedRoute,
    private modalService: NgbModal,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.makeFormEdit();
    this.getConfig();
  }

  makeFormEdit(){
    this.formEdit = this.fb.group({
      key: [''],
      value: ['', Validators.required]
    });
  }

  getConfig(){
    this.landingService.getConfigByKey(this.configKey)
    .subscribe(
      (res) => {
        let response = new LandingConfigResponse(res);
        this.configItem = response.object;
        this.formEdit.patchValue({
          key: this.configItem.configKey,
          value: this.configItem.configValue
        });
      },
      (err) => {
        this.handleError(err, err);
      }
    )
  }

  onSubmit(){
    if(this.formEdit.valid){
      this.isSubmit = true;
      let data = this.formEdit.value;
      this.landingService.editConfig(data)
      .subscribe(
        (res) => {
          this.alert('Cập nhật thành công', 'success', false, '', 'OK', () => {
            this.router.navigate(['/console/landing-page/config']);
          });
        },
        (err) => {
          this.handleError(err, err);
        }
      )
    }
    
  }

  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], 'error');
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, 'error');
    } else {
      this.alert('Lỗi không xác định! Vui lòng thử lại', 'error');
    }
  }

}
