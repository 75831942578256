import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { ConstantAlertBtn, ConstantAlertMsg } from 'src/app/utils/common/constant-alert-msg';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { InfoPartyObject } from 'src/app/utils/models/aas-models/info-party/info-party-object';
import { ListPackageActiveResponse } from 'src/app/utils/models/aas-models/package-manager-enduser/list-package-active/list-package-active-response.model';
import { PackageActive } from 'src/app/utils/models/aas-models/package-manager-enduser/list-package-active/package-active-item.model';
import { ListPackageResponse } from 'src/app/utils/models/aas-models/verify-business/list-package/list-package-response.model';
import { PackageItem } from 'src/app/utils/models/aas-models/verify-business/list-package/package-item.model';
import { UserInformationsItem } from 'src/app/utils/models/profile/user-info-item.model';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { BusinessPackageService } from 'src/app/utils/services/aas-network/business-package.service';
import { PackageSessionService } from 'src/app/utils/services/aas-network/package-session/package-session.service';
import { AuthService } from 'src/app/utils/services/auth.service';

@Component({
  selector: 'app-enduser-packages-manager',
  templateUrl: './enduser-packages-manager.component.html',
  styleUrls: ['./enduser-packages-manager.component.scss']
})
export class EnduserPackagesManagerComponent implements OnInit {

  // packages: PackageActive[] = [];
  packages: Array<any> = [];
  page = 1;
  maxSize = 10;

  userInfo: UserInformationsItem = new UserInformationsItem();

  partyInfo: InfoPartyObject = new InfoPartyObject();

  constructor(
    private packageService: BusinessPackageService,
    private auth: AuthService,
    private aasAuth: AasAuthService,
    private router: Router,
    private modalService: NgbModal,
    public packageSessionService: PackageSessionService
  ) { }

  ngOnInit(): void {
    // this.getPartyInfo(this.auth.partyId);
    // this.getUserInfo(this.auth.userId)
    this.getListPackageOrderByUser(this.page, this.maxSize);
  }

  reload() {
    // this.getPartyInfo(this.auth.partyId);
    // this.getUserInfo(this.auth.userId)
  }

  /**
   * Chon goi cuoc
   * @param packageId 
   */
  selectPackage(packageId, name) {
    // let msg = `Bạn có chắc muốn chuyển từ gói  `
    this.alert(ConstantAlertMsg.ENDUSER_SELECT_PACKAGE_CONFIRM, ConstantAlertType.BLANK, true, '', ConstantAlertBtn.CONFIRM, () => {
      let param = {
        partyId: this.auth.partyId,
        packageId: packageId
      }

      this.postSelectPackage(param);
    });
  }

  /**
   * Xem chi tiet goi cuoc
   * @param packageId 
   */
  goToDetail(packageId,statusPackage) {
    statusPackage = statusPackage === '' ? 'null': statusPackage;
    this.router.navigate([ConstantUrl.ENDUSER_PACKAGE_DETAIL, packageId,statusPackage ]);
  }

  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = ConstantAlertBtn.OK, actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = ConstantAlertBtn.CANCEL, actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], ConstantAlertType.ERROR);
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, ConstantAlertType.ERROR);
    } else {
      this.alert(ConstantAlertMsg.DEFAULT_ERROR_REQUEST, ConstantAlertType.ERROR);
    }
  }

  isWaitting = false;
  packageUsed = '';
  //MARK: NETWORKING
  // /**
  //  * Danh sach goi cuoc ho enduser
  //  * @param userType 
  //  */
  // getListPackageActiveEndUser(partyId, userType) {
  //   this.packageService.getListPackageActiveEndUserV2(partyId, userType).subscribe((res: any) => {
  //     let result = new ListPackageActiveResponse(res);
  //     this.packages = result.object.data;
  //     console.log(this.packages, 'dighrui')
  //     this.packages.forEach(item => {
  //       // if(item.statusPackage === 'APROVE'){
  //       //   this.packageUsed = item.packageInfo.name;
  //       // }
  //       if(item.statusPackage === 'WAIT'){
  //         this.isWaitting = true;
  //       }
  //     });
  //   }, err => {
  //     this.handleError(err, ResponseMsg.MESSAGES.MANAGEMENT_SERVICE)
  //   })
  // }

  // /**
  //  * Thong tin user dang login
  //  * @param userId 
  //  */
  // getUserInfo(userId) {
  //   this.aasAuth.getUserInfo(userId).subscribe((res: any) => {
  //     this.userInfo = new UserInformationsItem(res.object || {});
      
  //     this.getListPackageActiveEndUser(this.userInfo.partyId, this.userInfo.userType);
  //   }, err => {
  //     this.handleError(err, ResponseMsg.MESSAGES.PARTY_USER);
  //   })
  // }

  getListPackageOrderByUser(page, maxSize, packageType = "") {
    this.packageService.getListPackageOrderByUser(packageType, page, maxSize).subscribe((res: any) => {
      const object = res?.object || {};
      this.packages = object?.data || [];
      console.log(this.packages)
    });
  }

  /**
   * Thong tin party dang login
   * @param partyId 
   */
  getPartyInfo(partyId) {
    this.aasAuth.getPartyInfo(partyId).subscribe((res: any) => {
      this.partyInfo = new InfoPartyObject(res.object || {});
      console.log(this.partyInfo, 'hsánhdegdsfewy')
      // this.getListPackageActiveEndUser(this.partyInfo.partyId);
    }, err => {
      this.handleError(err, ResponseMsg.MESSAGES.PARTY_USER);
    })
  }

    /**
   * Gửi yêu cầu duyệt đăng ký tổ chức VIP
   * @param data 
   */
  postSelectPackage(data) {
    this.packageService.postSelectPackage(data).subscribe(res => {
      this.alert(ConstantAlertMsg.ENDUSER_SELECT_PACKAGE_SUCCESS, ConstantAlertType.SUCCESS);
      this.reload();
    }, err => {
      this.handleError(err, ResponseMsg.MESSAGES.ALL_IN_ONE);
    })
  }
}
