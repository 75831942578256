import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';
import { DualListComponent } from 'src/app/layout/extensions/dual-list/dual-list.component';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertBtn } from 'src/app/utils/common/constant-alert-msg';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { localize } from 'src/app/utils/localize/localize';
import { PackageDetail } from 'src/app/utils/models/aas-models/package-manager/package-detail';
import { ServiceCatalog } from 'src/app/utils/models/aas-models/service-catalog/service-catalog';
import { LoadingService } from 'src/app/utils/services/loading.service';
import { PackageServiceService } from 'src/app/utils/services/package-service/package-service.service';

@Component({
  selector: 'app-detail-package',
  templateUrl: './detail-package.component.html',
  styleUrls: ['./detail-package.component.scss']
})
export class DetailPackageComponent implements OnInit {
  @ViewChild('duallist') duallist: DualListComponent;
  @ViewChild('userIsUsing') userIsUsingTemplate: ElementRef;
  
  id = this.activeRoute.snapshot.paramMap.get('id');
  packInfoId = this.id;

  localize = localize;
  constPackageType = {
    TRIAL_TYPE: "Gói dùng thử",
    NORMAL_TYPE: "Gói chính thức",
    EXTEND_TYPE: "Gói mở rộng"
  };
  constCustomerType = {
    CONSUMER: "Khách hàng cá nhân",
    BUSINESS: "Khách hàng tổ chức",
  };
  package: PackageDetail = null;

  packages: any[] = [];
  page = 1;
  maxSize = 20;
  endOfList = false;
  keySearch = "";
  dropListPackageData: Observable<any>;
  packageLoading = false;
  packageInput$ = new Subject<string>();

  totalPrice = "";
  items_dualist = [];
  items_dualist_assigned = [];

  usersAreUsing = [];
  pageUser = 1;
  maxSizeUser = 10;
  propertiesSortUser = "created";
  sortUser = "DESC";
  totalElementUser = 0;
  
  modalListUsed = null;

  constructor(
    private activeRoute: ActivatedRoute,
    private packageApi: PackageServiceService,
    private myAlert: AlertControl,
    private loader: LoadingService,
    private router: Router,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.packInfoId = this.id;
    console.log(this.packInfoId);
    this.getRolesInCatalogs();
    this.getDetailPackage(this.id);
  }

  //Hien thi trang thai goi
  getStatusSpan(status) {
    let className = "";
    let label = "";
    
    switch(status) {
      case "ENABLED":
        className = "badge-success-new";
        label = "Đang hoạt động";
        break;
      case "DISABLED":
        className = "badge-danger-new";
        label = "Ngưng hoạt động";
        break;
    };

    if (className != "" && label != "") {
      let span = `<span class="badge ${className}">${label}</span>`;
      return span;
    } else {
      return "";
    }
  }

  viewUserIsUsing() {
    this.loadListUsed(1);
    this.modalListUsed = this.modalService.open(this.userIsUsingTemplate, { backdrop: 'static', size: 'lg' });
  }

  loadListUsed(page) {
    this.page = page;
    this.getListUsed(this.id, this.pageUser, this.maxSizeUser, this.propertiesSortUser, this.sortUser);
  }

  actDelete() {
    // if (this.package.packageInfo.status == "ENABLED" || this.package.packageInfo.status == "USING") {
    //   let msg_error = "Bạn không thể xóa danh mục dịch vụ đã được sử dụng. Vui lòng kiểm tra lại";
    //   this.myAlert.showAlert(msg_error, ConstantAlertType.BLANK, false, "", ConstantAlertBtn.CLOSE, () => { });
    // } else {
      let msg = "Bạn chắc chắn muốn xóa gói dịch vụ này?";
      let msg_confirm = "Gói dịch vụ đã được xóa khỏi hệ thống!";
      this.myAlert.showAlert(msg, ConstantAlertType.BLANK, true, '', ConstantAlertBtn.REMOVE, () => {
        this.packageApi.deletePackageV2(this.id).subscribe(res => {
          this.myAlert.showAlert(msg_confirm, ConstantAlertType.BLANK, false, "", ConstantAlertBtn.CLOSE, () => {
            this.router.navigate([ConstantUrl.list_package]);
          });
        }, err => {
          this.myAlert.showErrorHandled(err);
        });
      }, '', ConstantAlertBtn.CLOSE, () => {}, "Xác nhận");
    // }
  }

  actEdit() {
    this.router.navigate([ConstantUrl.package_edit, this.id]);
  }

  changedPackage(event) {
    console.log(event);
    console.log(this.packInfoId);
    if (event) {
      this.router.navigate([ConstantUrl.package_detail, this.packInfoId]);
      // this.ngOnInit();
    }
  }

  //MARK droplist goi dich vu
  trackByFn(item: any) {
    return item;
  }

  //Tim kiem realtime droplist bo phan
  lazyLoadPackage() {
    this.dropListPackageData = concat(
      this.packageInput$.pipe(
        startWith(''),
        debounceTime(200),
        distinctUntilChanged(),
        tap(() => { this.packageLoading = true; this.loader.disabledLoader(this.packageLoading); }),
        switchMap((term) => {
          this.page = 1;
          this.endOfList = false;
          this.keySearch = term;
          let res = this.packageApi.getListPackage(this.page, this.maxSize, "created", "DESC", "", "", "", this.keySearch).pipe(
            map((res: any) => {
              //Tao mang du lieu droplist
              let arr = [] = res?.object?.data.map((e: any) => {
                return {
                  id: e?.packInfoId,
                  label: e?.name
                }
              });
              console.log(arr);
              let param = { id: this.package?.packageInfo?.packInfoId, label: this.package?.packageInfo?.name };
              return this.addToDropList(arr, this.package ? param : null, "id")
            }),
            catchError(() => of(this.addToDropList([], this.package ? { id: this.package?.packageInfo?.packInfoId, label: this.package?.packageInfo?.name } : null, "id"))), // empty list on error
            tap(() => { this.packageLoading = false; this.loader.disabledLoader(this.packageLoading); })
          );
          return res;
        })
      ));
  }

  //Su kien scroll xuong duoi cung cua droplist
  onScrollToEndDropListPackage() {
    if (this.endOfList) return;

    var nextPage = this.page + 1;
    this.page = nextPage;
    this.loadMorePackage(this.page);
  }

  //Tai them du lieu droplist ds goi dich vu
  loadMorePackage(page) {
    this.packageLoading = true; this.loader.disabledLoader(this.packageLoading);
    this.packageApi.getListPackage(page, this.maxSize, "created", "DESC", "", "", "", this.keySearch).subscribe((res: any) => {
      console.log(res);
      if (res?.object?.data.length <= 0) {
        this.endOfList = true;
      }

      //Tao mang du lieu droplist
      let arr = res?.object?.data.map((e: any) => {
        return {
          id: e?.packInfoId,
          label: e?.name
        }
      });

      this.dropListPackageData.subscribe((value) => {
        this.dropListPackageData = of(value.concat(arr || []));
      });

      this.packageLoading = false; this.loader.disabledLoader(this.packageLoading);
    }, err => {
      this.packageLoading = false; this.loader.disabledLoader(this.packageLoading);
    })
  }

  addToDropList(data = [] ,param, key = "partyId") {
      key = key || "id";
      if (param === null) return data;
      let index = data.findIndex((item) => {
          return param[key] === item[key];
      });
      if (index >= 0) return data;
      return [...data, param]; 
  }

  //Tinh tong tien
  getTotalMoney() {
    console.log("getTotalMoney");
    var sum = '0';
    this.package.listPackageInfrastructure.infrastructures.forEach((item) => {
      if (!isNaN(parseInt(item.price)) && !isNaN(parseInt(sum))) {
        sum = `${Number(sum) + Number(item.price)}`;
      }
    });

    this.totalPrice = sum;
  }

  getDetailPackage(id) {
    this.packageApi.getDetailPackage(id).subscribe((res: any) => {
      console.log(res);
      this.package = new PackageDetail(res?.object);
      this.getTotalMoney();

      //Set data danh sach danh muc chuc nang da chon
      this.items_dualist_assigned = [];
      this.package.listPackageService.forEach((item) => {
        let param = {
          id: item.serviceInfoId || "",
          name: item.name || "",
          additions: {
            ...item
          },
          child: []
        };

        item.privileges.forEach((x, index) => {
          param.child.push({
            id: x.name || "",
            name: x.description || "",
          })
        });

        this.items_dualist_assigned.push(param);
      });
      this.duallist.setupDualList(this.items_dualist_assigned);

      this.lazyLoadPackage();

      console.log(this.package);
    }, err => {
      console.log(err);
    });
  }


  //Danh sach quyen theo danh muc
  getRolesInCatalogs() {
    this.packageApi.getRolesInCatalogs().subscribe((res: any) => {
      let arrCatalog: ServiceCatalog[] = [];
      if (res.object && res.object instanceof Array) {
        res.object.forEach((item: any) => {
          arrCatalog.push(new ServiceCatalog(item));
        })

        //chuan bi du lieu cho duallist
        arrCatalog.forEach((item, index) => {
          let param = {
            id: item.serviceInfoId || "",
            name: item.name || "",
            additions: {
              ...item
            },
            child: []
          };

          item.privileges.forEach((x, index) => {
            param.child.push({
              id: x.name || "",
              name: x.description || "",
            })
          });

          this.items_dualist.push(param);
        });

        this.duallist.setBaseList();
        this.duallist.setupDualList([]);
      }
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }

  //Lay danh sach khach hang dang su dung goi cuoc
  getListUsed(id, page, maxSize, propertiesSort, sort) {
    this.packageApi.getListUsed(id, page, maxSize, propertiesSort, sort).subscribe((res: any) => {
      this.usersAreUsing = res?.object?.data || [];
      this.totalElementUser = res?.object?.totalElement;
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }
}
