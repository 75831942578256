<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-auto">
                <h1 class="m-0 text-dark"><label>Báo cáo thông tin khách hàng</label></h1>
            </div>
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>

<section class="content">
    <div class="container-fluid">
        <div class="content-right-sidebar tab-bar-custom">
            <ul class="nav nav-tabs" id="tabSignAction" role="tablist">
                <li class="nav-item nav-material active">
                    <a class="nav-link d-flex active" id="individual-tab" data-toggle="pill"
                        href="#individual" role="tab" aria-controls="individual"  aria-selected="true">
                        Khách hàng cá nhân
                    </a>
                </li>
                <li class="nav-item nav-material">
                    <a class="nav-link d-flex" id="organization-tab" data-toggle="pill"
                        href="#organization" role="tab" aria-controls="organization"  aria-selected="false">
                        Khách hàng tổ chức
                    </a>
                </li>
            </ul>
        </div>
        <div class="tab-content contract-tab-body" id="tabSignAction">
            <!--MARK Khách hàng cá nhân -->
            <div class="tab-pane fade show active" id="individual" role="tabpanel" aria-labelledby="individual">
                <app-individual></app-individual>
            </div>

            <!--MARK Khách hàng tổ chức -->
            <div class="tab-pane fade show" id="organization" role="tabpanel" aria-labelledby="organization">
                <app-organization></app-organization>
            </div>
        
            <!--MARK Hợp đồng đã nhận  -->
            <!-- <div class="position-relative tab-pane fade" id="waitingForSign" role="tabpanel" aria-labelledby="waitingForSign"
                [class.show]="!controlAccount.isAccLevel2" [class.active]="!controlAccount.isAccLevel2">
                <app-service-contract-list-v2 #assigneeList by="ASSIGN"></app-service-contract-list-v2>
            </div> -->
        </div>
    </div>
</section>