import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { TemplateService } from 'src/app/utils/services/aas-network/template.service';
import { AasValidateMessage } from 'src/app/utils/common/validate-msg-aas';
import { Observable } from 'rxjs';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { NgModalComponent } from 'src/app/layout/extensions/ng-modal/ng-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/utils/services/auth.service';
import { Config } from 'src/app/config';
import { LoadingService } from 'src/app/utils/services/loading.service';
import { UploadGpkdObject } from 'src/app/utils/models/aas-models/upload-gpkd/upload-gpkd-object';
import { UploadGpkdResponse } from 'src/app/utils/models/aas-models/upload-gpkd/upload-gpkd-response';
import { UserInformationsResponse } from 'src/app/utils/models/profile/user-info-response.model';
import { UserInformationsItem } from 'src/app/utils/models/profile/user-info-item.model';
import { InfoUserResponse } from 'src/app/utils/models/aas-models/info-user/info-user-response.model';
import { InfoUserObject } from 'src/app/utils/models/aas-models/info-user/info-user-object.model';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { Route } from '@angular/compiler/src/core';
import { Router } from '@angular/router';
import { ConstantAlertMsg } from 'src/app/utils/common/constant-alert-msg';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { AuthLoginResponse } from 'src/app/utils/models/auth/auth-login/auth-login-response.model';
import { ConstLocalStorage } from 'src/app/utils/common/constant';
import { ControlAccount } from 'src/app/utils/common/control-account';
import { localize } from 'src/app/utils/localize/localize';

declare var $: any;
declare var PDFLoader: any;
declare var vnpt_plugin: any;
declare var PdfSigner: any;
@Component({
  selector: 'app-servive-verify-business',
  templateUrl: './servive-verify-business.component.html',
  styleUrls: ['./servive-verify-business.component.scss']
})
export class ServiveVerifyBusinessComponent implements OnInit {
  @ViewChild('output') output: ElementRef;
  @ViewChild('filename') filename: ElementRef;
  @ViewChild('filebase') filebase: ElementRef;
  @ViewChild('iframe') iframe: ElementRef;

  userInfo: InfoUserObject = new InfoUserObject();

  fileForm: FormGroup;
  inputFileLabel: string = 'Chọn file';
  fileType: Array<string> = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
  isSubmit: boolean = false;
  selectedFile: boolean = false;
  fileValid: boolean = false;
  fileGPKD: File;

  validMsg = AasValidateMessage.VERIFY_BUSINESS;
  hasSignature: boolean = false;
  maSoThueStatus: boolean = false;
  uploadFileRes: UploadGpkdObject = new UploadGpkdObject();

  base64String: string = '';
  certInfo: string = '';
  keyTest: string = Config.KEY_PLUGIN_CA;
  isSigned: boolean = false;
  fileName: string = '';
  pdfSigned: Blob;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private auth: AuthService,
    private aasAuth: AasAuthService,
    private modalService: NgbModal,
    public loaderSerive: LoadingService,
    private templateSerive: TemplateService,
    private myAlert: AlertControl,
    private controlAccount: ControlAccount
  ) { }

  ngOnInit(): void {
    this.makeFileForm();

    this.checkPlugin();
  }

  makeFileForm() {
    this.fileForm = this.fb.group({
      file: new FormControl('', [Validators.required])
    });
  }

  get file() { return this.fileForm.get('file'); }

  handleFileInput(e) {
    this.fileValid = false;
    this.selectedFile = true;
    this.hasSignature = false;
    this.maSoThueStatus = false;
    this.fileGPKD = e.target.files && e.target.files.item(0);
    console.log(this.fileGPKD);
    if (this.fileGPKD && this.fileGPKD !== null && this.fileGPKD !== undefined) {
      if (!this.fileType.includes(this.fileGPKD.type)) {
        this.fileForm.get('file').setErrors({ type: true });
        this.inputFileLabel = this.fileGPKD.name
      } else if (this.fileGPKD.size > 5000000) {
        this.myAlert.showAlertOnlyNoti(ConstantAlertMsg.FILE_SIZE_TOO_LARGE, ConstantAlertType.ERROR);
      } else {
        this.fileValid = true;
        this.inputFileLabel = this.fileGPKD.name;

        if (this.fileGPKD.type === 'application/pdf') {
          this.fileName = this.fileGPKD.name;
          PDFLoader.preview(this.fileGPKD);

          this.getBase64String(this.fileGPKD, this.handle);

          let data = new FormData();
          data.append('file', this.fileGPKD);
          this.postUploadGPKD(this.auth.partyId, data);
        } else {
          let fileSplit = this.fileGPKD.name.split('.');
          fileSplit.pop();
          this.fileName = `${fileSplit.join('.')}.pdf`;

          let data = new FormData();
          data.append('attachFile', this.fileGPKD);
          this.postConvertToPDF(data);
        }        
      }
    } else {
      this.fileForm.get('file').setErrors({ required: true });
      this.inputFileLabel = 'Chọn file';
    }
  }

  actBtnComplete() {
    if (true) {
      let formData = new FormData();
      formData.append('file', this.fileGPKD, this.fileName);
      this.postCompleteVerifyGPKD(this.auth.partyId, formData);
    } else {
      this.alert('Thông tin trên giấy phép kinh doanh của bạn không trùng khớp với thông tin đăng ký! Vui lòng kiểm tra lại.', 'error');
    }
  }

  callBackComplete(user: InfoUserObject) {
    if (user.verificationStatus === "WAIT") {
      // Vui lòng thực hiện video call theo như hướng dẫn của chúng tôi để hoàn thành việc xác minh và tham gia trải nghiệm VNPT - eContract
      this.alert(localize.upload_gpkd_success_wait, 'success', false, '', localize.btn_back_to_dashboard, () => {
        this.router.navigate(['/app/console']);
      });
    } else if (user.verificationStatus === "APPROVE") {
      this.alert(localize.update_gpkd_success_approve, 'success', false, '', localize.btn_back_to_dashboard, () => {
        this.router.navigate(['/app/console']);
      });
    } else if (user.verificationStatus === "REJECT") {
      // Lý do từ chối: Từ chối vì abcdf
      this.alert(localize.update_gpkd_success_rejected, 'error', false, '', localize.btn_back_to_dashboard, () => {
        this.router.navigate(['/app/console']);
      });
    }
  }

  //MARK: Plugin CA
  checkPlugin() {
    const key = this.keyTest
    var self = this;
    vnpt_plugin
      .checkPlugin()
      .then(function (data) {
        if (data === '1') {
          vnpt_plugin.setLicenseKey(key).then((data) => {
            try {
              var jsonParse = JSON.parse(data);
              if (jsonParse && jsonParse.code === -1) {
                self.alert(localize.license_vnpt_sign_timeout, "");
              }
            } catch (e) {
              console.log(e);
              return;
            }
          })
          console.log('ok')
        }
      })
      .catch(function (e) {
        console.log(e)
        self.alert(localize.dont_install_vnpt_plugin, "");
      })
  }

  converBase64toBlob(content, contentType): Blob {
    //blob = converBase64toBlob(response.content, 'application/pdf');
    contentType = contentType || '';
    var sliceSize = 512;
    var byteCharacters = window.atob(content); //method which converts base64 to binary
    var byteArrays = [
    ];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, {
      type: contentType,
    }); //statement which creates the blob
    return blob;
  }

  getBase64String(blob, callback) {
    var reader = new FileReader();
    reader.onload = function (e) {
      callback(reader.result);
    }

    reader.readAsDataURL(blob);
  }

  handle(str) {
    $('#fileBase').val(str.split(',')[1]);
  }

  sign() {
    this.loaderSerive.showElement();
    // if (!this.isSigned) {

    // }
    this.output.nativeElement.value = "";
    var dataInput = this.filebase.nativeElement.value;
    if (dataInput.length === 0) {
      this.loaderSerive.hideElement();
      this.alert('Không tìm thấy file cần kí!', 'error');
      return;
    }
    var fileName = this.filename.nativeElement.value;
    console.log(fileName);
    var fileSplit = fileName.split(".");
    var fileExtension = fileSplit[fileSplit.length - 1].toLowerCase();
    if (fileExtension !== "xml" && fileExtension !== "pdf" && fileExtension !== "docx"
      && fileExtension !== "xlsx" && fileExtension !== "pptx" && fileExtension !== "txt") {
      this.loaderSerive.hideElement();
      this.alert('Định dạng file không được hỗ trợ kí số!', 'error');
      return;
    }
    var sigOptions = null;
    if (fileExtension === "pdf") {
      sigOptions = new PdfSigner();
      sigOptions.AdvancedCustom = true;
    }
    this.SignAdvanced(dataInput, fileExtension, sigOptions, this.showMessage);
  }

  SignAdvanced(data, type, option, callback) {
    var dataJS = {};
    var arrData = [];
    // 1			
    dataJS["data"] = data;
    dataJS["type"] = type;
    dataJS["sigOptions"] = JSON.stringify(option);

    var jsData = "";
    jsData += JSON.stringify(dataJS);
    //
    arrData.push(jsData);

    var vm = this;
    vnpt_plugin.CheckToken().then((data) => {
      console.log("check token ==> ", data);
      if (data === "1") {
        vnpt_plugin.getCertInfo(null, true).then((data) => {
          if (data) {
            let dataJSON = JSON.parse(data);
            var serial = dataJSON['serial'] || '';
            
            vnpt_plugin
                .signArrDataAdvanced(arrData, serial, true)
                .then((data) => {
                  vm.showMessage(data)
                })
                .catch(function (e) {
                  // alert(e)
                  this.loaderSerive.hideElement()
                })
          } else {
            this.loaderSerive.hideElement();
            this.alert("Bạn đã hủy phiên ký.", 'error');
          }
        })
      } else {
        this.loaderSerive.hideElement();
        this.alert("Bạn cần cắm token trước khi ký số.", 'error');
      }
    });
  }

  showMessage(data) {
    this.loaderSerive.hideElement();
    var jsOb = JSON.parse(JSON.parse(data)[0]);
    var err = '';
    switch (jsOb.code) {
      case 0:
        this.pdfSigned = this.converBase64toBlob(jsOb.data, 'application/pdf');
        err = "Ký tài liệu thành công!";
        break;
      case 1:
        err = "Dữ liệu đầu vào không hợp lệ!";
        break;
      case 2:
        err = "Không thể lấy chứng thư số!";
        break;
      case 3:
        err = "Ký không thành công";
        break;
      case 4:
        err = "Not found private key";
        break;
      case 5:
        err = "Dịch vụ lỗi, vui lòng thử lại!";
        break;
      case 6:
        err = "Pdf signing: not found page number parameter";
        break;
      case 7:
        err = "Pdf signing: page number illegal";
        break;
      case 8:
        err = "Xml signing: not found signing tag";
        break;
      case 9:
        err = "Xml signing: not found if of signing tag";
        break;
      case 10:
        err = "Data contain one or more signatures invalid";
        break;
      case 11:
        err = "Bạn đã hủy phiên ký";
        break;
      case 13:
        err = "Dữ liệu null!";
        break;
      default:
        err = "Dịch vụ lỗi, vui lòng thử lại!";
        break;
    }
    if (jsOb.code !== 0) {
      this.output.nativeElement.value = err;
      this.alert(err, 'error');
    } else {
      this.alert(err, 'success', false, '', 'OK', () => {
        //Reupload 
        let data = new FormData();
        data.append('file', this.pdfSigned);
        PDFLoader.preview(this.pdfSigned);
        this.postUploadGPKD(this.auth.partyId, data);
      });
    }
  }

  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], 'error');
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, 'error');
    } else {
      this.alert('Lỗi không xác định!', 'error');
    }
  }

  //MARK: NETWOKRING
  getUserInfo(id) {
    this.aasAuth.getUserInfo(id).subscribe((res) => {
      this.userInfo = new InfoUserResponse(res).object;

      this.callBackComplete(this.userInfo);
    }, err => {
      this.handleError(err, ResponseMsg.MESSAGES.AUTH_SERVICE);
    }, () => {
    })
  }

  postConvertToPDF(data: FormData) {
    this.templateSerive.postConvertToPdf(data).subscribe(fileData => {
      const blob: any = new Blob([fileData], { type: 'application/pdf' });
      PDFLoader.preview(blob);
      this.fileGPKD = blob; 

      this.getBase64String(blob, this.handle);

      let data = new FormData();
      data.append('file', blob, this.fileName);
      this.postUploadGPKD(this.auth.partyId, data);
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.ESIGN_SERVICE);
    });
  }

  postUploadGPKD(id, data: FormData) {
    this.aasAuth.postUploadGPKD(id, data).subscribe(res => {
      this.uploadFileRes = new UploadGpkdResponse(res).object;
      console.log(this.uploadFileRes);
      this.hasSignature = this.uploadFileRes.signatureStatus.toString() === "VALID";
      this.maSoThueStatus = this.uploadFileRes.maSoThueStatus.toString() === "VALID";
      console.log(this.hasSignature);
      console.log(this.maSoThueStatus);
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.VERIFY_BUSINESS);
    }, () => {

    })
  }

  postCompleteVerifyGPKD(id, data: FormData) {
    this.aasAuth.postCompleteVerifyGPKD(id, data).subscribe(res => {
      this.getUserInfo(this.auth.userId);
      this.postRefeshToken(localStorage.getItem(ConstLocalStorage.REFRESH_TOKEN));
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.VERIFY_BUSINESS);
    }, () => {
    });
  }

  postRefeshToken(refreshToken: string = "") {
    const param = {
        "refresh_token": refreshToken,
        "grant_type": "refresh_token",
        "client_id": "clientapp",
        "client_secret": "password"
    }
    this.auth.postOauthToken(param).subscribe((res: any) => {
      var result = new AuthLoginResponse(res);
      this.auth.setKey(result.key);

      var token = new AuthLoginResponse(res);
      let jwtToken = this.auth.jwtDecode(token.access_token);

      this.auth.setToken(token.access_token);
      this.auth.setUserId(jwtToken.idUser);
      this.auth.setUsername(jwtToken.user_name);
      localStorage.setItem(ConstLocalStorage.REFRESH_TOKEN, result.refresh_token);

      this.controlAccount.requestAPIGetAccountInfo(this.auth.userId, this.auth.partyId, () => {
        this.controlAccount.getAccountInfo();
      });
    })
  }
}