export class ContractFileInfo {
    keySend: string;
    url: string;

    constructor(obj = null) {
        obj = obj || {};

        this.keySend = obj.keySend || '';
        this.url = obj.url || '';
    }
}