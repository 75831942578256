import { ApiDetailObject } from './api-detail-object.model';
import { MainResponse } from './../../../main-response.model';
export class ApiDetailResponse extends MainResponse {
    object: ApiDetailObject;

    constructor(data = null) {
        super()
        data = data || {};
        this.init(data);

        this.object = new ApiDetailObject(data.object);
    }
}