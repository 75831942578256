import { PackageService } from './package-service.model';
import { PackageInfo } from './package-info.model';

export class Package {
    packageInfo: PackageInfo;

    listPackageService: Array<PackageService> = [];

    constructor(data = null) {
        data = data || {};

        this.packageInfo = new PackageInfo(data.packageInfo);

        this.listPackageService = [];
        if (data.listPackageService && data.listPackageService instanceof Array) {
            data.listPackageService.forEach(item => {
                this.listPackageService.push(new PackageService(item));
            })
        }
    }
}