export class ReportContractItem{
    tenGoiDichVu: string;
    loaiKhachHang: string;
    status: string;
    soLuong: number;

    constructor(obj = null) {
        obj = obj || {};
        this.tenGoiDichVu = obj.packageName || '';
        this.loaiKhachHang = obj.partyType || '';
        this.status = obj.currentStage || '';
        this.soLuong = Number(obj.contractNumber) || 0;
    }

}