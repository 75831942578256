import { Injectable } from '@angular/core';
import { ServicePath } from '../../common/constant-service-api';
import { API } from '../api';

const SERVICE_ENTERPRISE_VIP_LIST = ServicePath.MANAGEMENT_SERVICE + '/parties/list-registry-party-by-status';
const SERVICE_DETAIL_ENTERPRISE_VIP = ServicePath.MANAGEMENT_SERVICE + '/parties';
const SERVICE_REJECT_ENTERPRISE_VIP = ServicePath.MANAGEMENT_SERVICE + '/parties';
const SERVICE_APPROVE_NO_CONTRACT = ServicePath.MANAGEMENT_SERVICE + '/parties';
const SERVICE_GET_CONTRACT_VIP = ServicePath.MANAGEMENT_SERVICE + '/contracts';
const SERVICE_SEND_CONTRACT = ServicePath.MANAGEMENT_SERVICE + '/contracts';
const SERVICE_DOWNLOAD_CONTRACT_FILE = ServicePath.MANAGEMENT_SERVICE + '/contracts';

@Injectable({
  providedIn: 'root'
})
export class EnterpriseVipService {

  constructor(
    private api: API
  ) { }

  /**
   * Lấy danh sách các tài khoản tổ chức sử dụng giải pháp
   * @param status 
   * @param page 
   * @param maxSize 
   * @param propertiesSort 
   * @param sort 
   * @param searchString 
   */
  getListUserEnterpriseVip(status, page, maxSize, propertiesSort, sort, searchString) {
    const url = SERVICE_ENTERPRISE_VIP_LIST + `?verificationStatus=${status}&searchString=${searchString}&maxSize=${maxSize}&page=${page}&propertiesSort=${propertiesSort}&sort=${sort}`;
    return this.api.get(url);
  }

  /**
   * Lấy thông tin chi tiết tổ chức sử dụng giải pháp
   * @param partId 
   */
  getDetailEnterpriseVip(partId) {
    const url = SERVICE_DETAIL_ENTERPRISE_VIP + `/${partId}/`;
    return this.api.get(url);
  }

  /**
   * Từ chối duyệt tài khoản tổ chức sử dụng giải pháp
   * @param partyId 
   * @param param 
   */
  postRejectEnterpriseVip(partyId, param) {
    const url = SERVICE_REJECT_ENTERPRISE_VIP + `/${partyId}/registration-vip-reject`;
    return this.api.post(url, param);
  }

  /**
   * Phê duyệt tổ chức sử sụng giải phải không hợp đồng
   * @param partyId 
   */
  postApproveNoContract(partyId) {
    const url = SERVICE_APPROVE_NO_CONTRACT + `/${partyId}/registration-vip-approve-non-contract`;
    return this.api.post(url, null);
  }

  /**
 * Láy thông tin hợp đồng
 * @param id 
 */
  getContract(id) {
    const url = SERVICE_GET_CONTRACT_VIP + `/${id}/vip`;
    return this.api.get(url);
  }

  //Gửi hợp đồng ký bằng usb token
  postSendContract(id, data: FormData) {
    const url = SERVICE_SEND_CONTRACT + `/${id}/digital-sign-vip`;
    return this.api.postMultiPartJSON(url, data);
  }

  //Download file từ url
  dowloadFile(url) {
    return this.api.downloadOnlyURL(url);
  }

  /**
   * Tải xuống file hợp đồng
   * @param id 
   * @param type 
   */
  downloadFileContract(id, type) {
    const url = SERVICE_DOWNLOAD_CONTRACT_FILE + `/${id}/download-documents-vip?documentType=${type}&fileKey=${id}`;
    return this.api.download(url);
  }

  /**
   * Tải xuống file hợp đồng
   * @param id 
   * @param type 
   */
  downloadFileWithHeader(id, type) {
    const url = SERVICE_DOWNLOAD_CONTRACT_FILE + `/${id}/download-documents-vip?documentType=${type}&fileKey=${id}`;
    return this.api.requestDownload(url);
  }
}
