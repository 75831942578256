import { EnterpriseVipItem } from './enterprise-vip-item.model';

export class ListEnterpriseVipObject {
    data: Array<EnterpriseVipItem> = [];
    maxSize: number;
    page: number;
    propertiesSort: string = '';
    sort: string = '';
    totalElement: number;

    constructor(data = null) {
        data = data || {};
        this.data = [];
        if (data.data && data.data instanceof Array) {
            data.data.forEach((item) => {
                this.data.push(new EnterpriseVipItem(item));
            })
        }

        this.maxSize = data.maxSize;
        this.page = data.page;
        this.propertiesSort = data.propertiesSort || '';
        this.sort = data.sort || '';
        this.totalElement = data.totalElement;
    }
}