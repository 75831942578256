<app-spinner></app-spinner>
<div class="landingpage">
    <div class="register">
        <section class="register-ss1">
            <img src="assets/img/home/v4/Image/Logo eContract_change color.png" alt="" class="img-logo-ipadpro">
            <div class="content col-12 p-0">
                <div class="row swap-col">
                    <div class="col-auto-new p-0">
                        <div class="row">
                            <div class="col-12">
                                <div class="content-left">
                                    <div class="row">
                                        <div class="col-left-auto">
                                            <div class="body">
                                                <span class="logo block">
                                                    MỘT SẢN PHẨM CỦA TẬP ĐOÀN
                                                    <img src="../../../assets/img/home/v4/Image/logo vnpt.png" alt="">
                                                </span>
                                                <p class="title">
                                                    Hợp đồng điện tử<br />
                                                    VNPT eContract
                                                </p>
                                                <span class="dec">
                                                    Cung cấp dịch vụ hợp đồng điện tử cho phép các bên giao kết các loại
                                                    hợp đồng
                                                    bằng phương tiện điện tử.
                                                </span>
                                            </div>
                                        </div>
                                        <div class="col-left-auto">
                                            <img src="assets/img/home/v4/Image/Group 177362.png" alt=""
                                                class="d-xl-block imgbg">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-auto-new p-0">
                        <div class="content-right">
                            <div class="row justify-content-center">
                                <div class="col-10 col-md-6 col-xl-9">
                                    <div class="body">
                                        <img src="assets/img/home/v4/Image/LOGO261.png" alt="" class="img-logo">
                                        <h3 class="title">{{ 'OtpForgotPassword_title' | translate }}</h3>
                                        <span class="note pb-4">
                                            {{ 'OtpForgotPassword_description' | translate }} {{user}}
                                        </span>

                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-center">
                                <div class="col-10 col-md-6 col-xl-9">

                                    <div class="login-form">
                                        <form [formGroup]="formOTP">
                                            <div class="form-group">
                                                <input appOnlyNumber class="form-control" formControlName="otp"
                                                    placeholder="{{ 'OtpForgotPassword_h_4' | translate }}" type="text" maxlength="6"
                                                    (keyup)="verifyOTP($event)">
                                                <app-show-validate-errors [isSubmit]="isSubmit"
                                                    [errorMessages]="ServiceMsg.otp" [control]="otp"
                                                    [detail]="{ name: 'otp' }">
                                                </app-show-validate-errors>
                                            </div>

                                            <!-- <p class="error text-danger" *ngIf="sendEmailFailed">{{
                                                ServiceMsg.email.notFound }}</p> -->

                                            <h6 class="footer-otp">{{ 'OtpForgotPassword_h_1' | translate }} <a *ngIf="count === 0"
                                                    (click)="resendOTP()"
                                                    class="cursor-pointer text-primary text-decoration-underline">{{ 'OtpForgotPassword_h_2' | translate }}</a></h6>
                                            <span class="text-muted" *ngIf="count > 0">{{ 'OtpForgotPassword_h_3' | translate: {counter: counter$ | async} }}</span>
                                        </form>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
<a href="" class="btn-backhome">
    <img src="assets/img/home/v4/icon/backicon.png" alt="">
</a>