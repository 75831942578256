import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appTrackerPhoneNumber]'
})
export class TrackerPhoneNumberDirective {

  constructor(private _el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event) {
    const initalValue: string = this._el.nativeElement.value;

    this._el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
    this._el.nativeElement.value = initalValue.length > 10 ? initalValue.substring(0, initalValue.length - 1) : this._el.nativeElement.value;
    if ( initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }

}
