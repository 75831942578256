<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-auto">
                <h1 class="m-0 text-dark"><label>Thông tin API</label></h1>
            </div>
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>

<section class="content">
    <div class="container-fluid pb-3">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-auto align-self-end">
                        <label class="text-lg">Thông tin chi tiết</label>
                    </div>
                    <div class="col-md-auto ml-auto">
                        <button (click)="goToEdit($event)" class="btn btn-primary d-inline-flex"><span
                                class="material-icons mr-2">edit</span> Chỉnh sửa</button>
                    </div>
                </div>

                <form [formGroup]="apiForm">
                    <label class="text-primary">Thông tin Input</label>
                    <hr>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="method">Phương thức <span class="text-danger">*</span>: </label>
                                        <ng-select2 [disabled]="true" formControlName="method" [data]="methods" [options]="optionsMethod"
                                            [placeholder]="'Chọn phương thức gọi API'">
                                        </ng-select2>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>URL <span class="text-danger">*</span>: </label>
                                        <input disabled formControlName="pathName" class="form-control"
                                            placeholder="Nhập URL của API">
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="method">Chức năng <span class="text-danger">*</span>: </label>
                                        <ng-select2 [disabled]="true" formControlName="privilegesId" [data]="features"
                                            [options]="optionsFeature" [placeholder]="'Chọn phương chức năng'">
                                        </ng-select2>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Mô tả <span class="text-danger">*</span>: </label>
                                <textarea disabled formControlName="description" class="form-control" style="height: 109px;"></textarea>
                            </div>
                        </div>
                    </div>
                </form>

                <label>Header:</label>
                <div class="mb-3">
                    <table #tableParamsHeader class="table table-striped table-bordered ">
                        <thead>
                            <tr>
                                <th>STT</th>
                                <th>Tham số</th>
                                <th>Mô tả</th>
                                <th>Thao tác</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of paramsHeader; let i = index">
                                <td>
                                    {{ i + 1 }}
                                </td>
                                <td>{{ item.paramName }}</td>
                                <td>{{ item.description }}</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="request-body mt-5">
                    <label class="text-primary">Thông tin body</label>
                    <hr>
                    <label>Request body:</label>
                    <app-codeblock-viewer [method]="'JSON'" [type]="'javascript'" [code]="apiForm.value.exampleBody"></app-codeblock-viewer>
                    <div class="my-3">
                        <table class="table table-striped table-bordered ">
                            <thead>
                                <tr>
                                    <th>STT</th>
                                    <th>Tham số</th>
                                    <th>Kiểu dữ liệu</th>
                                    <th>Bắt buộc</th>
                                    <th>Mô tả</th>
                                    <th>Thao tác</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of paramsBody; let i = index">
                                    <td>
                                        {{ i + 1 }}
                                    </td>
                                    <td>{{ item.paramName }}</td>
                                    <td>{{ item.type }}</td>
                                    <td>{{ item.obligatory === 'true' ? 'Có' : 'Không' }}</td>
                                    <td>{{ item.description }}</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <label>Request Param <span class="text-danger">*</span>:</label>
                    <app-codeblock-viewer [method]="'JSON'" [type]="'javascript'" [code]="apiForm.value.exampleParam"></app-codeblock-viewer>
                    <div class="my-3">
                        <table class="table table-striped table-bordered ">
                            <thead>
                                <tr>
                                    <th>STT</th>
                                    <th>Tham số</th>
                                    <th>Kiểu dữ liệu</th>
                                    <th>Bắt buộc</th>
                                    <th>Mô tả</th>
                                    <th>Thao tác</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of paramsParam; let i = index">
                                    <td>
                                        {{ i + 1 }}
                                    </td>
                                    <td>{{ item.paramName }}</td>
                                    <td>{{ item.type }}</td>
                                    <td>{{ item.obligatory === 'true' ? 'Có' : 'Không' }}</td>
                                    <td>{{ item.description }}</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div class="request-response mt-5">
                    <label class="text-primary">Thông tin output</label>
                    <hr>
                    <label>Response <span class="text-danger">*</span>:</label>
                    <app-codeblock-viewer [method]="'JSON'" [type]="'javascript'" [code]="apiForm.value.exampleOutput"></app-codeblock-viewer>
                    <div class="my-3">
                        <table class="table table-striped table-bordered ">
                            <thead>
                                <tr>
                                    <th>STT</th>
                                    <th>Tham số</th>
                                    <th>Kiểu dữ liệu</th>
                                    <th>Bắt buộc</th>
                                    <th>Mô tả</th>
                                    <th>Thao tác</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tbody>
                                <tr *ngFor="let item of paramsOutputSuccess; let i = index">
                                    <td>
                                        {{ i + 1 }}
                                    </td>
                                    <td>{{ item.paramName }}</td>
                                    <td>{{ item.type }}</td>
                                    <td>{{ item.obligatory === 'true' ? 'Có' : 'Không' }}</td>
                                    <td>{{ item.description }}</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <label>Mã lỗi <span class="text-danger">*</span>:</label>
                    <div class="my-3">
                        <table class="table table-striped table-bordered ">
                            <thead>
                                <tr>
                                    <th>STT</th>
                                    <th>Mã lỗi</th>
                                    <th>Mô tả</th>
                                    <th>Thao tác</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of paramsOutputError; let i = index">
                                    <td>
                                        {{ i + 1 }}
                                    </td>
                                    <td>{{ item.paramName }}</td>
                                    <td>{{ item.description }}</td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>