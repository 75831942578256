import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, CanActivateChild } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { GlobalConfig } from '../common/global-config/global-config';
import { ConstantUrl } from '../common/constant-url';
import { Token } from '../models/token.model';
import { ControlAccount } from '../common/control-account';
import { AlertControl } from '../alert/alert-control';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class AasAuthGuard implements CanActivate, CanActivateChild {
  constructor(private router: Router,
    private auth: AuthService,
    private controlAccount: ControlAccount,
    private myAlert: AlertControl,
    private translate: TranslateService) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    var token = this.auth.getToken();
    if (token && token !== "" && token !== undefined && token !== null) {
      if (next.url[0] && next.url[0].path === "console") {
        let objToken: Token = new Token(this.auth.jwtDecode(token));
        const scope = ["AASADMIN"];
        let ok = false;

        for(let i = 0; i < objToken.scope.length; i++) {
          if (scope.includes(objToken.scope[i])) {
            ok = true;
            break;
          }
        }

        if (!ok) {
          this.router.navigate([ConstantUrl.HOME]);
        }
        return ok;
      }

      return true;
    } else {
      this.auth.removeAllLocalStorage();
      this.router.navigate([ConstantUrl.LOGIN]);
      return false;
    }
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const token = this.auth.getToken();
    const token_decode = this.auth.jwtDecode(token);
    const allowRoles = next.data.roles || [];

    let ok = false;

    token_decode.authorities.forEach((item) => {
      if (allowRoles.includes(item)) {
        ok = true;
      }
    })

    if (allowRoles.length == 0) {
      ok = true;
    }

    if (!ok) {
      this.auth.removeAllLocalStorage();
      this.router.navigate([ConstantUrl.LOGIN]);
    } else {
      var checkAccountInfo = () => {
        let allowBusiness = (!this.auth.user?.ten || !this.auth.user?.email) 
          && this.auth.isAdmin
          && this.auth.party?.partyType == "BUSINESS";
        let allowConsumer = (!this.auth.user?.ten || !this.auth.user?.sdt || !this.auth.user?.email) 
          && this.auth.isAdmin 
          && this.auth.user?.userType == "CONSUMER";

        if (allowBusiness) {
          if (next.url[0].path != "profile-manager") {
            this.myAlert.showAlertNoClose(
              this.translate.instant("onesme_no_userinformation"), 
              "", 
              "", 
              this.translate.instant("onesme_btn_goto_update_info"), 
              () => {
                this.router.navigate([ConstantUrl.PROFILE_URL]);
              })
          }
        }
      }

      if (this.auth.user && this.auth.party) {
        checkAccountInfo();
      } else {
        // Kiểm tra thông tin người đại diện
        // this.controlAccount.requestAPIGetAccountInfo(this.auth.userId, this.auth.partyId, () => {
          // checkAccountInfo();
        // })
      }
    }

    return ok;
  }

}
