export class OrganizationManagerItem {
    partyId: string = "";
    tenToChuc: string = "";
    maSoThue: string = "";
    email: string = "";
    tenDaiDien: string = "";
    username: string = "";
    identityLevel: number = 0;
    modified: string = "";
    locked: string = "";
    referenceName: string = "";
    referenceId: string = "";
  
    constructor(obj = null) {
      obj = obj || {};
      this.partyId = obj.partyId || "";
      this.tenToChuc = obj.tenToChuc || "";
      this.maSoThue = obj.maSoThue || "";
      this.email = obj.email || "";
      this.tenDaiDien = obj.tenDaiDien || "";
      this.username = obj.username || "";
      this.identityLevel = obj.identitylevel || "";
      this.modified = obj.modified || "";
      this.locked = obj.locked || "";
      this.referenceName = obj.referenceName || "";
      this.referenceId = obj.referenceId || "";
    }
  }
  