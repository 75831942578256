<app-spinner></app-spinner>
<div class="container-fluid" *ngIf="detail">
    <div class="row">
        <div class="col-md-8">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-auto">
                            <h1 class="m-0 text-dark"><label>{{ 'TrustedConsumerPartnerDetail_title' | translate
                                    }}</label></h1>
                        </div>
                        <!-- /.col -->
                        <div class="col-md-auto ml-auto text-right">
                            <button *ngIf="detail.refPartyId==partyId" class="btn btn-outline-primary d-inline-flex mr-3" (click)="resendInfo(detail.trustedPartnerId)"><span
                                    class="material-icons-outlined mr-1">
                                    email
                                </span>Gửi lại thông tin tài khoản</button>
                            <button *ngIf="detail.refPartyId==partyId" class=" btn btn-primary d-inline-flex" (click)="goToEdit(detail.trustedPartnerId)"><span
                                    class="material-icons mr-1">
                                    edit
                                </span>Chỉnh sửa</button>
                        </div>

                    </div>
                    <!-- /.row -->
                </div>
                <!-- /.container-fluid -->
            </div>
            <section class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card ec-card shadow-none m-0 border-radius-8px">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <label class="text-primary">{{ 'TrustedPartnerAdd_accountTitle' | translate
                                                }}</label>
                                            <hr class="mt-0 mb-2">
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <p class="font-weight-bold">
                                                        Tên tài khoản
                                                    </p>
                                                </div>
                                                <div class="col-md-3">
                                                    {{detail.username}}
                                                </div>
                                                <div class="col-md-6">

                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <p class="font-weight-bold">
                                                        Số điện thoại
                                                    </p>
                                                </div>
                                                <div class="col-md-3">
                                                    {{detail.sdt}}
                                                </div>
                                                <div class="col-md-6 text-warning d-flex justify-content-end">
                                                    <span class="material-icons-outlined mr-3">
                                                        warning
                                                    </span>
                                                    Chưa xác thực
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <p class="font-weight-bold">
                                                        Email
                                                    </p>
                                                </div>
                                                <div class="col-md-3">
                                                    {{detail.email}}
                                                </div>
                                                <div class="col-md-6 d-flex justify-content-end text-warning">
                                                    <span class="material-icons-outlined mr-3">
                                                        warning
                                                    </span>
                                                    Chưa xác thực
                                                </div>
                                            </div>
                                            <div class="">
                                                <label class="text-primary">{{ 'TrustedPartnerAdd_inforTitle' |
                                                    translate}}</label>
                                                <hr class="mt-0 mb-2">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <p class="font-weight-bold">
                                                            Họ và tên
                                                        </p>
                                                    </div>
                                                    <div class="col-md-9">
                                                        {{detail.ten}}
                                                    </div>

                                                </div>
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <p class="font-weight-bold">
                                                            Số giấy tờ
                                                        </p>
                                                    </div>
                                                    <div class="col-md-9">
                                                        {{detail.cmnd}}
                                                    </div>

                                                </div>
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <p class="font-weight-bold">
                                                            Ngày sinh
                                                        </p>
                                                    </div>
                                                    <div class="col-md-9">
                                                        {{detail.ngaySinh | date:'dd/MM/yyyy'}}
                                                    </div>

                                                </div>
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <p class="font-weight-bold">
                                                            Nơi sinh
                                                        </p>
                                                    </div>
                                                    <div class="col-md-9">
                                                        {{detail.noiSinh}}
                                                    </div>

                                                </div>
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <p class="font-weight-bold">
                                                            Giới tính
                                                        </p>
                                                    </div>
                                                    <div class="col-md-9">
                                                        {{detail.gioiTinhId === 1 ? "Nữ" : "Nam"}}
                                                    </div>

                                                </div>
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <p class="font-weight-bold">
                                                            Nơi ĐK HKTT
                                                        </p>
                                                    </div>
                                                    <div class="col-md-9">
                                                        {{detail.dkhktt}}
                                                    </div>

                                                </div>
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <p class="font-weight-bold">
                                                            Ngày cấp
                                                        </p>
                                                    </div>
                                                    <div class="col-md-9">
                                                        {{detail.ngayCap | date: 'dd/MM/yyyy'}}
                                                    </div>

                                                </div>
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <p class="font-weight-bold">
                                                            Nơi cấp
                                                        </p>
                                                    </div>
                                                    <div class="col-md-9">
                                                        {{detail.noiCap}}
                                                    </div>

                                                </div>
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <p class="font-weight-bold">
                                                            Có giá trị đến
                                                        </p>
                                                    </div>
                                                    <div class="col-md-9">
                                                        {{detail.expiryDate| date:'dd/MM/yyyy'}}
                                                    </div>

                                                </div>
                                                <label class="text-primary">{{ 'TrustedPartnerAdd_pictureTitle' |
                                                    translate}}</label>
                                                <hr class="mt-0 mb-2">
                                                <div class="row">
                                                    <div class="col-md-3">
                                                        <label>Ảnh giấy tờ mặt trước</label>
                                                        <div style="width: 150px; height: 100px">
                                                            <img src="{{frontImage}}"
                                                                class="w-100">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <label>Ảnh giấy tờ mặt sau</label>
                                                        <div style="width: 150px; height: 100px">
                                                            <img src="{{backImage}}"
                                                                class="w-100">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <label>Ảnh chân dung</label>
                                                        <div style="width: 150px; height: 100px">
                                                            <img src="{{portraitImage}}"
                                                                class="w-100">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>