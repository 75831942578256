export const Constant = {
    UUID: 'Uuid',
    DEVICE_TOKEN: 'deviceToken',
    PENDING: 'PENDING',
    ACCEPTED: 'ACCEPTED',
    REJECTED: 'REJECTED',
    FINISHED: 'FINISHED',
    TIMEOUT: 'TIMEOUT',
    DENIED: 'DENIED',
    LEAVE: 'LEAVE',
    URL_VIDEO_CALL: 'video-call',
    URL_LOGIN: 'login',
    IDG_TOKEN_ID: '6af3add6-4023-4185-abfe-17b6aaa16b0f',
    RES_SUCCESS: 'IDG-00000000',
    IS_LOGIN: 'isLogin',
    ACCESS_TOKEN: 'access_token',
    ROOM_INFO: 'roomInfo',
    BANK: 'bank',
    VIETCOMBANK: 'vietcombank',
    VIETINBANK: 'vietinbank',
    HDBANK: 'hdbank',
    ACBBANK: 'acb',
    MSBBANK: 'msbbank',
    MBBANK: 'mbbank',
    SCBBANK: 'scbbank',
    TECHCOMBANK: 'techcombank',
    SHBBANK: 'shbbank',
    VNPOST: 'vnpost',
    USER_DETAIL: 'user_detail',
    CALLER: 'caller',
    CONSUMER: 'consumer',
    IMAGE_URL: 'image_urls',
    IMAGE_FACE: 'image_face',
    USER_ROOMID: 'users_roomId',
    ASSET_VIETCOMBANK: 'assets/image/logo_vietcombank.png',
    ASSET_VIETTINBANK: 'assets/image/logo_vietinbank.svg',
    ASSET_HDBANK: 'assets/image/logo_hdbank.png',
    ASSET_ACBBANK: 'assets/image/logo_acb.svg',
    ASSET_MSBBANK: 'assets/image/logo_msb.png',
    ASSET_MBBANK: 'assets/image/logo_mbbank.png',
    ASSET_SCBBANK: 'assets/image/logo_scbbank.png',
    ASSET_TECHCOMBANK: 'assets/image/logo_techcombank.png',
    ASSET_SHBBANK: 'assets/image/logo_shbbank.png',
    ASSET_VNPOST: 'assets/image/logo_vnpost.png',
    UNIT_INFO: 'unit_info',
    APPROVE: 'approve',

    TOKEN: 'bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOlsicmVzdHNlcnZpY2UiXSwidXNlcl9uYW1lIjoiZG9hbnBoYW50aGFuaDEzMDVAZ21haWwuY29tIiwiYXV0aG9yaXRpZXMiOlsiVVNFUiJdLCJqdGkiOiI3OTEzNjRjZi01OTFjLTQ2YmYtOGQ0Ny0yOTYzOTdiYWQ1NTQiLCJjbGllbnRfaWQiOiJhZG1pbmFwcCIsInNjb3BlIjpbInJlYWQiXX0.6EwQhluXY9ak1SLEqAvLxnFtqzBUXl-Dz3AdgIBuwmZwvQwTijm3GNbXmDvv0dAilk1_BUzKJDGyb8uvjgEv63NbCt22m04ox2RfejfiOO6_I7GqjD8HaQKYV8NyinluxObECBV7pUHzSY9oo9XtER1p0zoG5p4CdB2W_3rd1i-uxkSiKrOK_ifx4UPHxMP-2owrtPb9mK3ds-mfvO7bE_AMSrpykA35yXafPwOYOcgbnUWUSmVlhasqSDyan4EQHJ66l477P2uQXpA8sEF_oV3vfJ9daJlOyVVmoal2KuoU1pUGlG0lhdJJGmjxkGyXnyaKUxz58PSHZ_mJzq9qTw',
    TOKEN_ID: 'aaa17433-e3c2-064f-e053-604fc10aab28',
    TOKEN_KEY: 'MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBAMlGceImXOf3AUE800nc/NfpIBNHT4nR1FaRSFADcyyMyXI8P9N8QWTsQnS5QrOGr5Sp2tP22xOfk+dpyRhlNzsCAwEAAQ=='
};
