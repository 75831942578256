<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-auto">
                <h1 class="m-0 text-dark"><label>Thông tin API Key</label></h1>
            </div>
        </div>
    </div>
</div>

<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-8">
                <div class="card shadow-none">
                    <div class="card-body">
                        <div class="row pt-2 pb-2">
                            <div class="col-md-auto align-self-end">
                                <label class="text-lg">Thông tin chi tiết</label>
                            </div>
                            <div class="col-md-auto ml-auto">
                                <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="changeStatus(apiKey.status)">
                                    {{ apiKey.status ? lockButtonTitle[apiKey.status] : lockButtonTitle[''] }}
                                    <span class="material-icons-outlined ml-2">lock</span> 
                                </button>
                                <button class="btn btn-primary d-inline-flex" (click)="sendMail()">Gửi email thông báo
                                    <span class="material-icons ml-2">email</span>
                                </button>
                            </div>
                        </div>
                        <hr>
                        <div class="row mb-3">
                            <div class="col-lg-2 col-md-3">
                                <span>Tên tổ chức</span>
                            </div>
                            <div class="col-lg-10 col-md-9">
                                <span>{{ apiKey.tenKhachHang }}</span>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-lg-2 col-md-3">
                                <span>Tên tài khoản</span>
                            </div>
                            <div class="col-lg-10 col-md-9">
                                <span>{{ apiKey.username }}</span>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-lg-2 col-md-3">
                                <span>Email</span>
                            </div>
                            <div class="col-lg-10 col-md-9">
                                <span>{{ apiKey.email }}</span>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-lg-2 col-md-3">
                                <span>API Key</span>
                            </div>
                            <div class="col-lg-10 col-md-9">
                                <span>{{ apiKey.licenseId }}</span>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-lg-2 col-md-3">
                                <span>Gói cước</span>
                            </div>
                            <div class="col-lg-10 col-md-9">
                                <span>{{ apiKey.packageName }}</span>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-lg-2 col-md-3">
                                <span>Thời hạn</span>
                            </div>
                            <div class="col-lg-10 col-md-9">
                                <span>{{ apiKey.duration + ' tháng' }}</span>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-lg-2 col-md-3">
                                <span>Ngày bắt đầu</span>
                            </div>
                            <div class="col-lg-10 col-md-9">
                                <span>{{ apiKey.issueDate | date : 'dd/MM/yyyy' }}</span>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-lg-2 col-md-3">
                                <span>Ngày kết thúc</span>
                            </div>
                            <div class="col-lg-10 col-md-9">
                                <span>{{ apiKey.expirationDate | date: 'dd/MM/yyyy' }}</span>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-lg-2 col-md-3">
                                <span>Trạng thái</span>
                            </div>
                            <div class="col-lg-10 col-md-9">
                                <span class="badge {{ constantStatus[apiKey.status || 'DEFAULT'].class }}">{{ constantStatus[apiKey.status || 'DEFAULT'].text }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<ng-template #sendMailContent let-c="close" let-d="dismiss">
    <div class="modal-header border-0 pb-0 mb-0">
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span class="material-icons">
                cancel
            </span>
        </button>
    </div>
    <div class="modal-body py-0">
        <h5 class="modal-title w-100 text-center mb-3" id="modal-basic-title">Gửi email thông báo</h5>
        <p>Thông tin API Key sẽ được gửi đến email của khách hàng. Vui lòng kiểm tra lại thông tin.</p>
        <form [formGroup]="formSendMail">
            <div class="form-group">
                <label>Email khách hàng <span class="text-danger">*</span>: </label>
                <input formControlName="email" class="form-control" type="text" placeholder="Nhập email">
                <app-show-validate-errors [isSubmit]="isSubmitFormSendMail" [errorMessages]="validateSendMailMsg.email"
                    [control]="formSendMail.controls.email" [detail]="{ name: 'email' }"></app-show-validate-errors>
            </div>
        </form>
    </div>
    <div class="modal-footer border-0 d-flex justify-content-center">
        <button type="button" class="btn btn-outline-primary" (click)="d('Cross click')">Hủy</button>
        <button type="button" class="btn btn-primary" (click)="confirmSendMail()">Xác nhận</button>
    </div>
</ng-template>