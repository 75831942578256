import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommentNotePoint } from 'src/app/shared/plugins/comment-note-point.model';
import { NotePoint } from 'src/app/shared/plugins/note-point.model';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { DateUtils } from 'src/app/utils/common/DateUtils';
import { ContractObject } from 'src/app/utils/models/aas-models/contract/contract/contract-object.model';
import { VersionContract } from 'src/app/utils/models/aas-models/track-change/version-contract.model';
import { AasContractService } from 'src/app/utils/services/aas-network/aas-contract.service';
import { CommentService } from 'src/app/utils/services/aas-network/comment/comment.service';

declare var PDFSign: any;
@Component({
  selector: 'app-version-in-contract',
  templateUrl: './version-in-contract.component.html',
  styleUrls: ['./version-in-contract.component.scss']
})
export class VersionInContractComponent implements OnInit {

  @Input() contractId: string = null;
  @Input() 
  set contract(value) { this._contract = value; }
  get contract() { return this._contract; }
  _contract: ContractObject = new ContractObject();

  @Output('listVersion') listVersion: EventEmitter<any> = new EventEmitter(null);
  @Output('versionSelected') versionSelected: EventEmitter<any> = new EventEmitter(null);
  @Output('selectedPost') selectedPost: EventEmitter<any> = new EventEmitter(null);
  @Output('calledHidePopop') calledHidePopop: EventEmitter<any> = new EventEmitter(null);

  listContractVersion: Array<any> = [];
  dateUtil = DateUtils;
  versionSelectedInfo: any = null
  notePointSelected: NotePoint = null;

  constructor(
    private contractService: AasContractService,
    public translate: TranslateService,
    private alert: AlertControl,
    private commentApi: CommentService
  ) { }

  selectedFilter: string = 'ALL';
  filterOptions: Array<any> = [
    { value: 'ALL', label: 'VersionInContract_filter_1' },
    { value: 'NAME', label: 'VersionInContract_filter_2' },
  ]

  ngOnInit(): void {
    this.getVersionByContractId();
  }

  /**
   * Lấy danh sách phiên bản bằng Contract ID,
   * Sắp xếp theo thứ tự created (ngày tạo) tăng dần,
   * Định dạng lại ngày tháng theo đúng UI và ngôn ngữ,
   * Kiểm tra nếu phiên bản chưa được đặt tên thì hiển thị tên theo định dạng ngày,
   * Thêm trường boolean để check sự kiện update tên phiên bản.
   */
  getVersionByContractId(hidePopup = true) {
    if (this.contractId != null) {
      this.contractService.getVersionByContractId(this.contractId, this.selectedFilter).subscribe((res: any) => {
        this.listContractVersion = [];

        if (res.object && res.object instanceof Array) {
          res.object.forEach((x) => {
            this.listContractVersion.push(new VersionContract(x));
          }) 
        }

        // this.listContractVersion.sort((a, b) => {
        //   return (Date.parse(a.created)) - (Date.parse(b.created));
        // });

        this.listVersion.emit(this.listContractVersion);

        if (this.versionSelectedInfo) {
          let i = this.listContractVersion.findIndex(x => { return x.traceId == this.versionSelectedInfo.traceId});
          if (i >= 0) {
            this.selectVersion(this.listContractVersion[i], hidePopup);
          }
        } else if (this.listContractVersion.length > 0) {
          this.selectVersion(this.listContractVersion[0], hidePopup);
        }

      }, err => {

      });
    }
  }

  downloadPDFVersion(contractId, versionId, callBack = null) {
    this.contractService.downloadContractFileByVersion(contractId, versionId).subscribe((res: any) => {
      console.log(res);
      const blob: any = new Blob([res]);
      PDFSign.preview(blob);
      if (callBack) {
        callBack(contractId, versionId);
      }
    }, err => {
      this.alert.showErrorHandled(err);
    })
  }

  /**
   * Click chọn phiên bản
   * @param version thông tin biên bản
   */
  selectVersion(version, hidePopup = true) {
    this.calledHidePopop.emit(hidePopup);
    this.versionSelectedInfo = version;
    if (version.expanded == false) {
      this.listContractVersion.forEach((x) => { x.expanded = false; });
      this.downloadPDFVersion(this.contractId, version.traceId, this.getPostByVersion.bind(this));
      version.expanded = true;
    } else {
      version.expanded = false;
    }
  }

  /**
   * Lấy danh sách các bình luận trong version
   * @param contractId 
   * @param versionId 
   */
  getPostByVersion(contractId, versionId) {
    this.commentApi.getListPost(contractId, versionId).subscribe((res: any) => {
      // console.log(res);

      if (res?.object && res?.object instanceof Array) {
        let posts = [];
        res?.object.forEach((x: any) => {
          posts.push(new NotePoint({...x, comment: x, saved: true}));
        });
        
        this.listContractVersion.find((x) => {
          if (x.traceId == versionId) {
            x.posts = posts;
          }
        });

        let x = this.listContractVersion.find((x) => { return x.traceId == versionId; })
        this.versionSelected.emit(x);
      }
    }, err => {
      this.alert.showErrorHandled(err);
    })
  }

  getDetailPost(contractId, versionId, postId) {
    this.commentApi.getDetailPost(contractId, versionId, postId).subscribe((res: any) => {
      console.log(res);
    })
  }

  /**
   * Lấy thông tin bình luận trong version
   * @param versionId 
   * @param postId 
   * @returns 
   */
  getPostInfor(versionId, postId): NotePoint {
    // console.log("getPostInfor", versionId, postId);
    let version: VersionContract = this.listContractVersion.find((x) => {return x.traceId == versionId});
    let post: NotePoint = version.posts.find((x) => {return x.id == postId});
    return post;
  }

  /**
   * Xóa bỏ phiên bản
   * @param id 
   */
  deleteVersionById(id: string = null) {
    if (typeof id == 'string') {
      this.alert.showAlert(this.translate.instant('VersionInContract_alert_1'), ConstantAlertType.BLANK, true, "", this.translate.instant('VersionInContract_btn_1'), () => {
        this.contractService.deleteVersionById(id).subscribe((res: any) => {
          this.getVersionByContractId();
        }, err => {

        });
      }, "", this.translate.instant('btn_close'), () => { }, this.translate.instant('btn_confirm'));
    }
  }

  /**
   * Khôi phục sử dụng phiên bản
   * @param traceId 
   */
  restoreVersionByContractIdAndTraceId(traceId: string = null) {
    if (typeof (traceId) === 'string') {
      this.alert.showAlert(this.translate.instant('VersionInContract_alert_2'), ConstantAlertType.BLANK, true, "", this.translate.instant('VersionInContract_btn_2'), () => {
          this.contractService.restoreVersionByContractIdAndTraceId(this.contractId, traceId).subscribe((res: any) => {
            this.getVersionByContractId();
          }, err => {

          });
        }, "", this.translate.instant('btn_close'), () => { }, this.translate.instant('btn_confirm'));
    }
  }

  /**
   * Tiến hành sửa tên phiên bản
   * @param index 
   */
  actionChangeVersionName(index: number) {
    this.listContractVersion[index].updating = !this.listContractVersion[index].updating;
  }

  /**
   * Sửa tên phiên bản
   * @param index 
   */
  changeVersionNameById(index: number) {
    this.contractService.changeVersionNameById(this.listContractVersion[index].traceId, this.listContractVersion[index].tempName).subscribe((res: any) => {
      this.listContractVersion[index].updating = false;
      this.listContractVersion[index].name = this.listContractVersion[index].tempName;
    }, err => {

    })
  }

  /**
   * Hủy chỉnh sửa tên phiên bản
   * @param index 
   */
  actionClearChange(index: number) {
    this.listContractVersion[index].updating = false;
    this.listContractVersion[index].tempName = this.listContractVersion[index].name;
  }


  /**
   * Chặn sự kiện click parent
   * @param e 
   */
  onEvent(e) {
    e.stopPropagation();
  }

  /**
   * Chọn hiển thị chi tiết bình luận trên pdf
   */
  selectPost(post: NotePoint) {
    this.notePointSelected = post;
    var e = document.getElementById("np-" + post.id);
    e.scrollIntoView({block: "center", inline: "center"});

    let data = {
      version: this.versionSelected,
      notePoint: post,
      event: null,
      pageIndex: post.page
    }
    this.selectedPost.emit(data);
  }

  /**
   * Đến bình luận tiếp theo
   */
  nextNotePoint() {
    let indexNotePoint = this.versionSelectedInfo?.posts.findIndex((x) => { return x.id == this.notePointSelected.id});
    if (indexNotePoint >= 0) {
      if (indexNotePoint + 1 < this.versionSelectedInfo?.posts.length) {
        this.selectPost(this.versionSelectedInfo?.posts[indexNotePoint + 1]);
      }
    }
  }

  /**
   * Về bình luận trước
   */
  prevNotePoint() {
    let indexNotePoint = this.versionSelectedInfo?.posts.findIndex((x) => { return x.id == this.notePointSelected.id});
    if (indexNotePoint >= 0) {
      if (indexNotePoint - 1 >= 0) {
        this.selectPost(this.versionSelectedInfo?.posts[indexNotePoint - 1]);
      }
    }
  }
}
