<app-spinner></app-spinner>
<div class="container-fluid">
    <div class="row login-grid">
        <div class="col-md-7 left-view-bg">
            <video #videobg id="videobg" loop="" muted="" playsinline="" oncanplay="this.play()">
                <source [attr.data-src]="'assets/img/home/vid-bg.mp4'" [attr.video-src]="'assets/img/home/vid-bg.mp4'" src="assets/img/home/vid-bg.mp4" type="video/mp4">
                    <source [attr.data-src]="'assets/img/home/vid-bg.mp4'" [attr.video-src]="'assets/img/home/vid-bg.webm'" src="assets/img/home/vid-bg.mp4" type="video/mp4">
            </video>
            <div class="content-on-video">
                <div class="col-12 text-center">
                    <div class="mb-4">
                        <img src="assets/img/econtract-logo.svg" width="100" height="100" style="background: white; border-radius: 16px">
                        <h1 class="headline-1">VNPT eContract</h1>
                        <p class="description-1">Tiên phong nền tảng hợp đồng số</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-5 right-view-login p-4 bg-white">
            <div class="row justify-content-center mt-5 pt-5 pb-2 mb-2">
                <div class="col-xl-10 col-lg-10 col-md-10 col-sm-10">
                    <div class="text-center mb-5">
                        <h2 class="font-weight-bold">Đặt lại mật khẩu</h2>
                    </div>
                    <div class="text-center" >
                        <h6>Vui lòng nhập mật khẩu mới và xác nhận mật khẩu mới của bạn</h6>
                    </div>
                    <!-- <div class="text-center">
                        <h1 class="font-weight-bold">Quên mật khẩu</h1>
                        <p class="text-muted">Vui lòng nhập mật khẩu mới</p>
                    </div> -->
                </div>
            </div>
            <div class="row justify-content-center my-3 py-5">
                <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9">
                    <form [formGroup]="renewForm">
                        <div class="input-group input-custom mb-4">
                            <span class="input-custom-label blue-label">Mật khẩu mới <span class="text-danger">*</span></span>
                            <input appInputPassword formControlName="password" type="password"
                                class="form-control form-control-lg input-custom-control"
                                placeholder="Nhập mật khẩu mới" />
                            <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="ValidateMsg.password"
                                [control]="password" [detail]="{ name: 'password' }"></app-show-validate-errors>
                        </div>
                        <div class="input-group input-custom mb-4">
                            <span class="input-custom-label blue-label">Xác nhận mật khẩu mới <span
                                    class="text-danger">*</span></span>
                            <input appInputPassword formControlName="passwordConfirm" type="password"
                                class="form-control form-control-lg input-custom-control"
                                placeholder="Nhập xác nhận mật khẩu mới" />
                            <app-show-validate-errors [isSubmit]="isSubmit"
                                [errorMessages]="ValidateMsg.passwordConfirm" [control]="passwordConfirm"
                                [detail]="{ name: 'passwordConfirm' }"></app-show-validate-errors>
                        </div>
                        <div class="row">
                            <div class="col-12 mb-4">
                                <p class="note">Lưu ý: Mật khẩu phải có độ dài ít nhất 8 ký tự, bao gồm ký tự hoa, thường, số và ký tự
                                    đặc biệt</p>  
                                <button [disabled]="checkEdited" (click)="actConfirm()" type="submit"
                                    class="btn btn-lg btn-primary btn-block b-radius-5px">
                                    Tiếp tục
                                </button>
                            </div>
                        </div>
                    </form>
                      
                </div>
            </div>
        </div>
    </div>
</div>