export class PackageService {
    serviceInfoId: string = '';
    packInfoId: string = '';

    constructor(data = null) {
        data = data || {};

        this.serviceInfoId = data.serviceInfoId || '';
        this.packInfoId = data.packInfoId || '';
    }
}