export class Part {
    id: string = "";
    partyName: string = "";
    descr: string = "";
    phoneNumber: string = "";
    fax: string = "";
    disable: any = null;

    constructor(d = null) {
        d = d || {};

        this.id = d.id || "";
        this.partyName = d.partyName || "";
        this.descr = d.descr || "";
        this.phoneNumber = d.phoneNumber || "";
        this.fax = d.fax || "";
        this.disable = d.disable;
    }
}