import { PartnerDiscuss } from "../../flow-contract/partner-discuss.model";
import { SignBox } from "../../signbox/sign-box";
import { SignatureItem } from "./signature-item.model";

export class ContractParty {
    contractPartyId: string = '';
    email: string = '';
    endDate: string = '';
    partyId: string = '';
    partyType: string = '';
    signForm: Array<string> = [];
    userId: string = '';
    verificationType: string = '';
    tenToChuc: string = '';
    tenDaiDien: string = '';
    isTrustedPartner: string = '';
    userType: string = "";
    sequence: number;


    cmnd: string = "";
    common_name: string = '';
    deal: any;
    dealDate: string = "";
    fullName: string = "";
    imageSign: string = "";
    maSoThue: string = "";
    not_after: string = "";
    not_before: string = "";
    owner_name: string = "";
    signDate: string = "";
    signerId: string = "";
    
    internalSignerId: string = "";
    internalFinish: boolean = false;

    partyName: string = "";
    expanded: boolean = false;

    partnerDiscuss: PartnerDiscuss[] = [];
    signFrame: SignBox[] = [];

    cancelDate: string = "";
    cancelReason: string = "";

    sdt = "";
    signatures: Array<SignatureItem> = [];
    approvalSignStatus = ""; 
    isSigned = false;

    constructor(object = null) {
        object = object || {};
        
        this.contractPartyId = object.contractPartyId || '';
        this.email = object.email || '';
        this.endDate = object.endDate || '';
        this.partyId = object.partyId || '';
        this.partyType = object.partyType || '';
        this.signForm = object.signForm || [];
        this.userId = object.userId || '';
        this.verificationType = object.verificationType || '';
        this.tenToChuc = object.tenToChuc || '';
        this.tenDaiDien = object.tenDaiDien || '';
        this.isTrustedPartner = object.isTrustedPartner || '';
        this.userType = object.userType || "";
        this.sequence = object.sequence;

        this.cmnd = object.cmnd || '';
        this.common_name = object.common_name || '';
        this.deal =  object.deal;
        this.dealDate = object.dealDate || '';
        this.fullName = object.fullName || '';
        this.imageSign = object.imageSign || '';
        this.maSoThue = object.maSoThue || '';
        this.not_after = object.not_after || '';
        this.not_before = object.not_before || '';
        this.owner_name = object.owner_name || '';
        this.signDate = object.signDate || '';
        this.signerId = object.signerId || '';

        this.internalSignerId = object.internalSignerId || "";
        this.internalFinish = object.internalFinish || false;

        this.partyName = object.partyName || "";
        if (object.partnerDiscuss && object.partnerDiscuss instanceof Array) {
            object.partnerDiscuss.forEach((item) => {
                this.partnerDiscuss.push(new PartnerDiscuss(item))
            })
        }

        this.signFrame = [];
        if (object.signFrame && object.signFrame instanceof Array) {
            object.signFrame.forEach((item) => {
                this.signFrame.push(new SignBox(item));
            })
        }
        this.cancelDate = object.cancelDate || "";
        this.cancelReason = object.cancelReason || "";
        this.sdt = object.sdt || "";

        this.signatures = [];
        if (object.signatures && object.signatures instanceof Array) {
            object.signatures.forEach((item) => {
                this.signatures.push(new SignatureItem(item));
            })
        }
    }

    public expand() {
        this.expanded = !this.expanded;
    }

    /**
     * Lấy thông tin chữ ký duyệt, ký dấu
     */
    get signatureApproval() {
        return this.signatures.find(res => {
            return res.signType === "APPROVAL";
        });
    }
}