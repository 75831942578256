import {QuantityInfoItem} from './quantity-info-item.model';

export class QuantityInfoObjectResponse {
  
    data: QuantityInfoItem

    constructor(obj = null) {
        obj = obj || {};
        if (obj.object && obj.object instanceof Array) {
            obj.object.forEach((item) => {
                this.data = new QuantityInfoItem(item);
            })
        }
    }
}
