
<app-spinner></app-spinner>
<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-auto">
                <h1 class="m-0 text-dark"><label>Chi tiết khách hàng</label></h1>
            </div>
            <!-- /.col -->
            <div class="col-md-auto ml-auto">
                <button class="btn btn-primary d-inline-flex mr-3"
                (click)="moveToAssignPackageScreen()">
                    <img class="mr-2 mt-1" src="..\..\..\..\..\..\..\assets\img\icon\database_upgrade_24px.svg" alt="">
                    Gán gói hạ tầng mở rộng
                </button>
                <button
                    class="btn btn-primary d-inline-flex"
                    [routerLink]="['/console/account-manager/edit/business/', partyId]">
                    <span class="material-icons mr-2">edit</span> 
                    Chỉnh sửa
                </button>
            </div>
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>

<section class="content">
    <div class="container-fluid">
        <div class="content-right-sidebar border-tab">
            <ul class="nav nav-tabs" id="tabSignAction" role="tablist">
                <li class="nav-item nav-material active">
                    <a class="nav-link d-flex active" id="customer-info-tab" data-toggle="pill" href="#customer-info"
                        role="tab" aria-controls="customer-info" aria-selected="true">
                        <em class="material-icons-outlined">info</em>
                        Thông tin khách hàng
                    </a>
                </li>

                <li class="nav-item nav-material">
                    <a class="nav-link d-flex align-items-center" id="package-info-tab" data-toggle="pill"
                        href="#package-info" role="tab" aria-controls="package-info" aria-selected="false">
                        <span class="ct-icon-font icon-custom_bill_outlined"></span>
                        Thông tin gói dịch vụ
                    </a>
                </li>

                <li class="nav-item nav-material">
                    <a class="nav-link d-flex" id="expansion-history-tab" data-toggle="pill" href="#expansion-history"
                        role="tab" aria-controls="expansion-history" aria-selected="false">
                        <em class="material-icons">history</em>
                        Lịch sử mở rộng hạ tầng
                    </a>
                </li>
            </ul>
        </div>
        <div class="tab-content contract-tab-body mt-3" id="tabSignAction">
            <div class="tab-pane fade show active" id="customer-info" role="tabpanel" aria-labelledby="customer-info">
                <!-- <app-customer-info-tab></app-customer-info-tab> -->
                <app-business-customer-info-tab></app-business-customer-info-tab>
            </div>
            <div class="tab-pane fade show" id="package-info" role="tabpanel" aria-labelledby="package-info">
                <!-- <app-package-info-tab></app-package-info-tab> -->
                <app-business-package-info-tab></app-business-package-info-tab>
            </div>
            <div class="tab-pane fade show" id="expansion-history" role="tabpanel" aria-labelledby="expansion-history">
                <!-- <app-expension-history-tab></app-expension-history-tab> -->
                <app-business-expension-history-tab></app-business-expension-history-tab>
            </div>
        </div>
    </div>
</section>

<!-- <section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-7">
                <div class="card shadow-none">
                    <div class="card-body position-relative">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="sub-title mb-2">Thông tin tổ chức</div>
                                <div class="e-block-field row">
                                    <div class="col-md-4 col-xl-3">
                                        <label class="e-lb-field">{{ localize.business_name }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        <span class="e-txt-field">{{ personalDetailObject?.tenToChuc }}</span>
                                    </div>
                                </div>
                                <div class="e-block-field row">
                                    <div class="col-md-4 col-xl-3">
                                        <label class="e-lb-field">{{ localize.business_sort_name }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        <span class="e-txt-field">{{ personalDetailObject?.tenRutGon }}</span>
                                    </div>
                                </div>
                                <div class="e-block-field row">
                                    <div class="col-md-4 col-xl-3">
                                        <label class="e-lb-field">{{ localize.taxnumber }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        <span class="e-txt-field">{{ personalDetailObject?.maSoThue }}</span>
                                    </div>
                                </div>
                                <div class="e-block-field row">
                                    <div class="col-md-4 col-xl-3">
                                        <label class="e-lb-field">{{ localize.gpkd }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        <span class="e-txt-field">
                                            <a class="text-decoration-underline text-primary" style="cursor: pointer;" (click)="actDownloadGPKD(personalDetailObject?.partyId)">
                                                {{personalDetailObject?.gpkdFilename}}
                                            </a>
                                        </span>
                                    </div>
                                </div>

                                <div class="sub-title mb-2">Thông tin người đại diện</div>
                                <div class="e-block-field row">
                                    <div class="col-md-4 col-xl-3">
                                        <label class="e-lb-field">{{ localize.fullname }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        <span class="e-txt-field">{{ personalDetailObject?.userInformations.ten }}</span>
                                    </div>
                                </div>
                                <div class="e-block-field row">
                                    <div class="col-md-4 col-xl-3">
                                        <label class="e-lb-field">{{ localize.phone_number }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        <span class="e-txt-field">{{ personalDetailObject?.userInformations.sdt }}</span>
                                    </div>
                                </div>
                                <div class="e-block-field row">
                                    <div class="col-md-4 col-xl-3">
                                        <label class="e-lb-field">{{ localize.email }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        <span class="e-txt-field">{{ personalDetailObject?.userInformations.email }}</span>
                                    </div>
                                </div>
                                <div class="e-block-field row">
                                    <div class="col-md-4 col-xl-3">
                                        <label class="e-lb-field">{{ localize.birthday }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        <span class="e-txt-field">{{ personalDetailObject?.userInformations.ngaySinh | date: 'dd/MM/yyyy' }}</span>
                                    </div>
                                </div>
                                <div class="e-block-field row">
                                    <div class="col-md-4 col-xl-3">
                                        <label class="e-lb-field">{{ localize.sex }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        <span class="e-txt-field">{{ personalDetailObject?.userInformations.gioiTinh }}</span>
                                    </div>
                                </div>
                                <div class="e-block-field row">
                                    <div class="col-md-4 col-xl-3">
                                        <label class="e-lb-field">{{ localize.addresss }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        <span class="e-txt-field">{{ personalDetailObject?.userInformations.diaChi }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="card shadow-none">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="sub-title mb-2">Thông tin tài khoản</div>
                                <div class="e-block-field row">
                                    <div class="col-md-4 col-xl-3">
                                        <label class="e-lb-field">{{ localize.username }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        <span class="e-txt-field">{{ personalDetailObject?.userInformations.username }}</span>
                                    </div>
                                </div>
                                <div class="e-block-field row">
                                    <div class="col-md-4 col-xl-3">
                                        <label class="e-lb-field">{{ localize.account_type_identity }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        <span class="e-txt-field">Tài khoản định danh cấp {{ personalDetailObject?.identityLevel }}</span>
                                    </div>
                                </div>
                                <div class="e-block-field row">
                                    <div class="col-md-4 col-xl-3">
                                        <label class="e-lb-field">{{ localize.package_service }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        <span class="e-txt-field">{{ personalDetailObject?.packageName }}</span>
                                    </div>
                                </div>
                                <div class="e-block-field row">
                                    <div class="col-md-4 col-xl-3">
                                        <label class="e-lb-field">{{ localize.status }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        <span *ngIf="personalDetailObject?.disable !== ''" class="badge p-2" [class.badge-success-new]="personalDetailObject?.disable === 'N'" [class.badge-danger-new]="personalDetailObject?.disable === 'Y'">
                                            {{ personalDetailObject?.disable == 'Y' ? "Ngưng hoạt động" : "Đang hoạt động" }}
                                        </span>
                                    </div>
                                </div>
                                <div class="e-block-field row">
                                    <div class="col-md-4 col-xl-3">
                                        <label class="e-lb-field">{{ localize.tb_code }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        <span class="e-txt-field">{{ personalDetailObject?.subscriptionCode }}</span>
                                    </div>
                                </div>
                                <div class="e-block-field row">
                                    <div class="col-md-4 col-xl-3">
                                        <label class="e-lb-field">{{ localize.tb_type }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        <span class="e-txt-field">{{ personalDetailObject?.subscriptionType }}</span>
                                    </div>
                                </div>
                                <div class="e-block-field row">
                                    <div class="col-md-4 col-xl-3">
                                        <label class="e-lb-field">{{ localize.tinh_code }}</label>
                                    </div>
                                    <div class="col-md-8">
                                        <span class="e-txt-field">{{ personalDetailObject?.provinceCode }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-preview-modal *ngIf="showPreviewGPKD" [file]="fileGPKD" [name]="personalDetailObject?.gpkdFilename" (close)="handleClosePreview()"></app-preview-modal>
 -->
