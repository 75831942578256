import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { InfoPartyObject } from 'src/app/utils/models/aas-models/info-party/info-party-object';
import { InfoPartyResponse } from 'src/app/utils/models/aas-models/info-party/info-party-response';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { AuthService } from 'src/app/utils/services/auth.service';
import { LoadingService } from 'src/app/utils/services/loading.service';
import { MiniLoaderService } from 'src/app/utils/services/mini-loader/mini-loader.service';
import { StepFourVerifyVipComponent } from './step-four-verify-vip/step-four-verify-vip.component';

@Component({
  selector: 'app-service-verify-business-vip',
  templateUrl: './service-verify-business-vip.component.html',
  styleUrls: ['./service-verify-business-vip.component.scss']
})
export class ServiceVerifyBusinessVipComponent implements OnInit, AfterViewInit {
  @ViewChild(StepFourVerifyVipComponent) stepfour: StepFourVerifyVipComponent;

  miniLoader: MiniLoaderService = new MiniLoaderService();

  stepControl: Array<any> = [
    {
      active: true,
      done: false,
      disabled: false
    },
    {
      active: true,
      done: false,
      disabled: true
    },
    {
      active: true,
      done: false,
      disabled: true
    },
    {
      active: true,
      done: false,
      disabled: true
    }
  ];

  currentStep = 0;
  startStep = 0;
  endStep = 3;
  countStep = 4;

  partyInfo: InfoPartyObject = new InfoPartyObject();

  constructor(
    private authService: AuthService,
    private aasAuth: AasAuthService,
    private modalService: NgbModal,
    public loadingService: LoadingService
  ) { }

  ngOnInit(): void {
    this.getPartyInfo(this.authService.partyId);
  }

  ngAfterViewInit(): void {
  }

  /**
   * Get username
   */
  get getUsername() {
    return this.authService.username;
  }

  /**
   * Đăng xuất khỏi hệ thống
   */
  logout() {
    this.authService.logout();
  }

  /**
   * Reset trạng thại các tab
   */
  resetStatusStep(): void {
    for (var i = 0; i < this.stepControl.length; ++i) {
      this.stepControl[i]['active'] = false;
    }
  }

  /**
   * Action chọn 1 tab
   */
  changedStep(event, step: any) {
    console.log(this.stepControl[step]);
    if (this.stepControl[step].done || step === 0 || (step > 0 && this.stepControl[step - 1].done)) {
      this.resetStatusStep();
      this.currentStep = step;
      this.stepControl[step].active = true;
      this.stepControl[step].disabled = false;
    }
  }

  /**
   * Chuyển trạng thái tab thành hoàn thành
   * @param step 
   */
  changedDoneStep(step: any) {
    this.stepControl[step].done = true;
  }

  /**
   * Next event
   */
  nextStep() {
    if (this.currentStep + 1 <= this.endStep) {
      if (this.stepControl[this.currentStep].done) {
        this.currentStep++;
        this.stepControl[this.currentStep].disabled = false;

        /**
         * Nếu là bước cuối thì reload lại thông tin tổ chức
         */
        if (this.currentStep === this.endStep) {
          this.stepfour.callBackGetPartyInfo();
        }
      }
    }
  }

  /**
   * Back event
   */
  backStep() {
    if (this.currentStep - 1 >= this.startStep) {
      this.currentStep--;
    }
  }

  handlePartyInfo(event) {
    // event ? this.partyInfo = new InfoPartyObject(event) : '' ;
    switch (this.partyInfo.verificationStatus) {
      case 'WAIT':
        if (this.partyInfo.packageId) {
          this.stepControl = [
            {
              active: false,
              done: true,
              disabled: true
            },
            {
              active: false,
              done: true,
              disabled: true
            },
            {
              active: false,
              done: true,
              disabled: true
            },
            {
              active: true,
              done: false,
              disabled: false
            }
          ];

          this.currentStep = 3;
          this.stepfour.callBackGetPartyInfo();
        }
        break;
      case 'REJECT_VIP':
        this.stepControl = [
          {
            active: false,
            done: true,
            disabled: true
          },
          {
            active: false,
            done: true,
            disabled: true
          },
          {
            active: false,
            done: true,
            disabled: true
          },
          {
            active: true,
            done: false,
            disabled: false
          }
        ]

        this.currentStep = 3;
        this.stepfour.callBackGetPartyInfo();
        break;
      case 'APPROVE_NON_CONTRACT':
        break;
      case 'APPROVE_CONTRACT':
        break;
      default:
        break;
    }
  }

  //MARK: HANDLE EMMIT FROM CHILD COMPONENT
  /**
   * Handle next event from child component
   * @param step 
   * @param event 
   */
  handleNextEvent(step, event) {
    if (event && event['done']) {
      switch (step) {
        case 0:

        case 1:

        case 2:

        case 3:

        default:
          break;
      }
      this.stepControl[step].done = true;
      this.nextStep();
    }
  }

  /**
   * Handle back event from child component
   * @param step 
   * @param event 
   */
  handleBackEvent(step, event) {
    switch (step) {
      case 0:

      case 1:

      case 2:

      case 3:

      default:
        break;
    }

    this.backStep();
  }

  showStep(step) {
    console.log(this.partyInfo.verificationStatus, ' ', this.partyInfo.contractVipId);
    return (this.partyInfo.verificationStatus === 'WAIT' && this.partyInfo.contractVipId) || (this.stepControl[step].active && this.stepControl[step].done && this.stepControl[step].disabled);
  }

  //MARK: NETWORKING
  /**
   * Lấy thông tin tổ chức
   * @param partyId 
   */
  getPartyInfo(partyId) {
    this.miniLoader.showLoader();
    this.aasAuth.getPartyInfo(partyId).subscribe(res => {
      this.partyInfo = new InfoPartyResponse(res).object;
      this.handlePartyInfo(null);
      this.miniLoader.hideLoader();
    }, err => {
      this.miniLoader.hideLoader();
      this.handleError(err, ResponseMsg.MESSAGES.PARTY_USER);
    }, () => {
    })
  }

  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], 'error');
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, 'error');
    } else {
      this.alert('Lỗi không xác định! Vui lòng thử lại', 'error');
    }
  }
}
