<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-auto">
                <h1 class="m-0 text-dark"><label>Cấu hình danh mục hành chính</label></h1>
            </div>
            <!-- /.col -->
            <!-- <div class="col-md-auto ml-auto">
                <button class="btn btn-outline-primary d-inline-flex"><em class="material-icons mr-2">file_upload</em>
                    Import Excel</button>
            </div> -->
            <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>

<section class="content">
    <div class="container-fluid">
        <div class="card shadow-none mb-4">
            <div class="card-body pb-0">
                <div class="row">
                    <div class="col-md-4 mt-auto">
                        <form class="input-group border rounded mb-3" [formGroup]="formFilter">
                            <div class="input-group-prepend border-0">
                                <button class="btn d-inline-flex pr-2 pl-2" type="submit"><span
                                        class="material-icons">search</span></button>
                            </div>
                            <input type="text" class="form-control border-0" placeholder="Tìm kiếm"
                                aria-describedby="basic-addon1" formControlName="keySearch">
                        </form>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group" [formGroup]="formFilter">
                            <label for="type">Tỉnh/Thành phố</label>
                            <ng-select formControlName="subdivisionReferTo" [items]="provinceOptions"
                            bindValue="value" bindLabel="label" [clearable]="false">
                            </ng-select>
                        </div>
                    </div>
                    
                    <div class="col-md-auto ml-auto mt-auto pb-3">
                        <button class="btn btn-outline-primary  mr-3" (click)="clearFilter()">
                            Đặt lại
                        </button>
                        <button class="btn btn-primary" (click)="applyFilter()">
                            Áp dụng
                        </button>
                    </div>
                </div>
            </div>
            <hr>
            <div class="card-body pt-0 pb-3">
                <div class="row">
                    <div class="col-md-6">
                        <label class="text-lg">Danh sách Tỉnh/Thành phố</label>
                    </div>
                    <!-- <div class="col-md-auto ml-auto">
                        <button class="btn btn-outline-primary d-inline-flex" (click)="exportExcel()">Xuất Excel
                        <em class="material-icons ml-2">arrow_drop_down</em></button>
                    </div> -->
                    <div class="btn-group col-md-auto ml-auto">
                        <button type="button" class="btn btn-outline-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          Xuất Excel
                        </button>
                        <div class="dropdown-menu dropdown-menu-right">
                          <button class="dropdown-item" type="button" (click)="exportExcel('', 'PROVINCE', '')">Tỉnh/Thành phố</button>
                          <button class="dropdown-item" type="button" (click)="exportExcel('', 'PROVINCE', 'FULL')">Tỉnh/Thành phố & Đơn vị con</button>
                        </div>
                    </div>
                </div>
            </div>

            <!-- PROVINCE TABLE -->
            <div class="table-responsive">
                <table class="table table-sort-col mb-0 f-table">
                    <!-- PROVINCE HEADER -->
                    <thead>
                        <tr class="bg-tb-header">
                            <th></th>
                            <th>STT</th>
                            <th (click)="sortCol('SUBDIVISIONCODE')">
                                <span class="d-flex align-self-center align-items-center">
                                    Mã đơn vị
                                    <span *ngIf="this.sortColConfig['SUBDIVISIONCODE'] == null" class="material-icons ml-auto">
                                        unfold_more
                                    </span>
                                    <span *ngIf="this.sortColConfig['SUBDIVISIONCODE'] == true" class="material-icons ml-auto">
                                        expand_less
                                    </span>
                                    <span *ngIf="this.sortColConfig['SUBDIVISIONCODE'] == false" class="material-icons ml-auto">
                                        expand_more
                                    </span>
                                </span>
                            </th>
                            <th (click)="sortCol('name')">
                                <span class="d-flex align-self-center align-items-center">
                                    Tên đơn vị hành chính
                                    <span *ngIf="this.sortColConfig['name'] == null"
                                        class="material-icons ml-auto">
                                        unfold_more
                                    </span>
                                    <span *ngIf="this.sortColConfig['name'] == true"
                                        class="material-icons ml-auto">
                                        expand_less
                                    </span>
                                    <span *ngIf="this.sortColConfig['name'] == false"
                                        class="material-icons ml-auto">
                                        expand_more
                                    </span>
                                </span>
                            </th>
                            <th (click)="sortCol('areaCode')">
                                <span class="d-flex align-self-center align-items-center">
                                    Mã địa bàn
                                    <span *ngIf="this.sortColConfig['areaCode'] == null" class="material-icons ml-auto">
                                        unfold_more
                                    </span>
                                    <span *ngIf="this.sortColConfig['areaCode'] == true" class="material-icons ml-auto">
                                        expand_less
                                    </span>
                                    <span *ngIf="this.sortColConfig['areaCode'] == false"
                                        class="material-icons ml-auto">
                                        expand_more
                                    </span>
                                </span>
                            </th>
                            <th (click)="sortCol('isActive')">
                                <span class="d-flex align-self-center align-items-center">
                                    Trạng thái
                                    <span *ngIf="this.sortColConfig['isActive'] == null"
                                        class="material-icons ml-auto">
                                        unfold_more
                                    </span>
                                    <span *ngIf="this.sortColConfig['isActive'] == true"
                                        class="material-icons ml-auto">
                                        expand_less
                                    </span>
                                    <span *ngIf="this.sortColConfig['isActive'] == false"
                                        class="material-icons ml-auto">
                                        expand_more
                                    </span>
                                </span>
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <!-- PROVINCE BODY -->
                    <tbody *ngIf="provinceDirectory.length > 0">
                        <ng-container *ngFor="let province of provinceDirectory; let i = index">
                            <tr>
                                <td class="td-icon">
                                    <button class="btn btn-only-icon" (click)="toggleOnProvince(i)">
                                        <span class="material-icons icon"
                                            [style.transform]="province.childHidden ? 'rotate(0deg)' : 'rotate(90deg)'">
                                            arrow_forward_ios
                                        </span>
                                    </button>
                                </td>
                                <td>
                                    <span *ngIf="i < 9">0</span>{{i + 1}}
                                </td>
                                <td >
                                    <span *ngIf="province.value.subdivisionCode && province.value.subdivisionCode.length == 1">0</span>{{ province?.value?.subdivisionCode }}
                                </td>
                                <td>
                                    {{ province?.value?.name }}
                                </td>
                                <td>
                                    {{ province?.value?.areaCode }}
                                </td>
                                <td>
                                    {{ getStatus(province?.value?.isActive) }}
                                </td>
                                <td class="td-icon">
                                    <button class="btn btn-only-icon" (click)="toggleEditProvince(i)">
                                        <span class="material-icons icon">
                                            edit
                                        </span>
                                    </button>
                                </td>
                            </tr>
                            <tr *ngIf="!province.editHidden">
                                <td></td>
                                <td></td>
                                <td>
                                    <form [formGroup]="editProvinceForm"
                                        class="input-group border rounded">
                                        <input class="form-control border-0" type="text"
                                            placeholder="Nhập mã"
                                            formControlName="maDonVi">
                                    </form>
                                </td>
                                <td>
                                    <form [formGroup]="editProvinceForm"
                                        class="input-group border rounded">
                                        <input class="form-control border-0" type="text"
                                            placeholder="Nhập tên đơn vị hành chính"
                                            formControlName="tenDvhc">
                                    </form>
                                </td>
                                <td>
                                    <form [formGroup]="editProvinceForm"
                                        class="input-group border rounded">
                                        <input class="form-control border-0" type="text"
                                            placeholder="Nhập mã địa bàn"
                                            formControlName="maDiaBan">
                                    </form>
                                </td>
                                <td>
                                    <form [formGroup]="editProvinceForm"
                                        class="input-group border rounded">
                                        <select formControlName="trangThai"
                                            class="form-control border-0"
                                            >
                                            <option value="Y">Đang sử dụng</option>
                                            <option value="N">Ngưng sử dụng</option>
                                        </select>
                                    </form>
                                </td>
                                <td class="td-icon">
                                    <span class="material-icons pr-0 icon-done"
                                        (click)="editProvince(i)">done</span>
                                    <span class="material-icons pl-0 icon-clear"
                                        (click)="closeEditProvinceForm(i)">clear</span>
                                </td>
                            </tr>
                            
                            <!-- DISTRICT TABLE -->
                            <ng-template [ngIf]="!province.childHidden">
                                <tr class="bg-white">
                                    <td colspan="7" class="pb-0 pt-0 pr-0">
                                        <div class="div-child">
                                            <div>
                                                <div class="container-fluid">
                                                    <div class="row pl-2 py-2 pr-1 district-title">
                                                        <div class="col-md-6">
                                                            <label class="text-lg">Danh sách Huyện/Quận</label>
                                                        </div>
                                                        <div class="col-md-auto ml-auto">
                                                            <!-- <button class="btn btn-outline-primary d-inline-flex mr-3">Xuất Excel
                                                            <em class="material-icons ml-2">arrow_drop_down</em></button> -->
                                                            <div class="btn-group mr-3">
                                                                <button type="button" class="btn btn-outline-primary" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                  Xuất Excel
                                                                </button>
                                                                <div class="dropdown-menu dropdown-menu-right">
                                                                  <button class="dropdown-item" type="button" (click)="exportExcel(province?.value?.id, 'DISTRICT', '')">Huyện/Quận</button>
                                                                  <button class="dropdown-item" type="button" (click)="exportExcel(province?.value?.id, 'DISTRICT', 'FULL')">Huyện/Quận & Đơn vị con</button>
                                                                </div>
                                                            </div>
                                                            <button class="btn btn-primary" (click)="toggleAddDistrict(i)">
                                                                Thêm mới
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="table-response">
                                                    <table class="table table-sort-col mb-0 pb-0 s-table ml-0">
                                                        <!-- DISTRICT HEADER -->
                                                        <thead>
                                                            <tr class="bg-s-tb-header">
                                                                <th></th>
                                                                <th>STT</th>
                                                                <th (click)="sortCol('maDonVi')">
                                                                    <span class="d-flex align-self-center align-items-center">
                                                                        Mã đơn vị
                                                                        <span *ngIf="this.sortColConfig['maDonVi'] == null"
                                                                            class="material-icons ml-auto">
                                                                            unfold_more
                                                                        </span>
                                                                        <span *ngIf="this.sortColConfig['maDonVi'] == true"
                                                                            class="material-icons ml-auto">
                                                                            expand_less
                                                                        </span>
                                                                        <span *ngIf="this.sortColConfig['maDonVi'] == false"
                                                                            class="material-icons ml-auto">
                                                                            expand_more
                                                                        </span>
                                                                    </span>
                                                                </th>
                                                                <th (click)="sortCol('tenDonViHanhChinh')">
                                                                    <span class="d-flex align-self-center align-items-center">
                                                                        Tên đơn vị hành chính
                                                                        <span
                                                                            *ngIf="this.sortColConfig['tenDonViHanhChinh'] == null"
                                                                            class="material-icons ml-auto">
                                                                            unfold_more
                                                                        </span>
                                                                        <span
                                                                            *ngIf="this.sortColConfig['tenDonViHanhChinh'] == true"
                                                                            class="material-icons ml-auto">
                                                                            expand_less
                                                                        </span>
                                                                        <span
                                                                            *ngIf="this.sortColConfig['tenDonViHanhChinh'] == false"
                                                                            class="material-icons ml-auto">
                                                                            expand_more
                                                                        </span>
                                                                    </span>
                                                                </th>
                                                                <th (click)="sortCol('maDiaBan')">
                                                                    <span class="d-flex align-self-center align-items-center">
                                                                        Mã địa bàn
                                                                        <span *ngIf="this.sortColConfig['maDiaBan'] == null"
                                                                            class="material-icons ml-auto">
                                                                            unfold_more
                                                                        </span>
                                                                        <span *ngIf="this.sortColConfig['maDiaBan'] == true"
                                                                            class="material-icons ml-auto">
                                                                            expand_less
                                                                        </span>
                                                                        <span *ngIf="this.sortColConfig['maDiaBan'] == false"
                                                                            class="material-icons ml-auto">
                                                                            expand_more
                                                                        </span>
                                                                    </span>
                                                                </th>
                                                                <th (click)="sortCol('trangThai')">
                                                                    <span class="d-flex align-self-center align-items-center">
                                                                        Trạng thái
                                                                        <span *ngIf="this.sortColConfig['trangThai'] == null"
                                                                            class="material-icons ml-auto">
                                                                            unfold_more
                                                                        </span>
                                                                        <span *ngIf="this.sortColConfig['trangThai'] == true"
                                                                            class="material-icons ml-auto">
                                                                            expand_less
                                                                        </span>
                                                                        <span *ngIf="this.sortColConfig['trangThai'] == false"
                                                                            class="material-icons ml-auto">
                                                                            expand_more
                                                                        </span>
                                                                    </span>
                                                                </th>
                                                                <th></th>
                                                            </tr>
                                                        </thead>
                                                        <!-- DISTRICT BODY -->
                                                        <tbody class="bg-white">
                                                            <tr *ngIf="!province.addChildHidden">
                                                                <td></td>
                                                                <td></td>
                                                                <td>
                                                                    <form [formGroup]="addDistrictForm" class="input-group border rounded">
                                                                        <input class="form-control border-0" type="text" placeholder="Nhập mã"
                                                                        formControlName="maDonVi">
                                                                    </form>
                                                                </td>
                                                                <td>
                                                                    <form [formGroup]="addDistrictForm" class="input-group border rounded">
                                                                        <input class="form-control border-0" type="text" placeholder="Nhập tên đơn vị hành chính"
                                                                        formControlName="tenDvhc">
                                                                    </form>
                                                                </td>
                                                                <td>
                                                                    <form [formGroup]="addDistrictForm" class="input-group border rounded">
                                                                        <input class="form-control border-0" type="text" placeholder="Nhập mã địa bàn"
                                                                        formControlName="maDiaBan">
                                                                    </form>
                                                                </td>
                                                                <td>
                                                                    <form [formGroup]="addDistrictForm" class="input-group border rounded">
                                                                        <select formControlName="trangThai" class="form-control border-0"
                                                                        >
                                                                            <option value="Y">Đang sử dụng</option>
                                                                            <option value="N">Ngưng sử dụng</option>
                                                                        </select>
                                                                    </form>
                                                                </td>
                                                                <td class="td-icon">
                                                                    <span class="material-icons pr-1 icon-done" (click)="addDistrict(i)">done</span>
                                                                    <span class="material-icons pl-1 icon-clear" (click)="closeAddDistrictForm(i)">clear</span>
                                                                </td>
                                                            </tr>
                                                            <ng-container *ngFor="let district of province.child; let j = index">
                                                                <tr>
                                                                    <td class="td-icon">
                                                                        <button class="btn btn-only-icon" (click)="toggleOnDistrict(i, j)">
                                                                            <span class="material-icons icon"
                                                                                [style.transform]="district.childHidden ? 'rotate(0deg)' : 'rotate(90deg)'">
                                                                                arrow_forward_ios
                                                                            </span>
                                                                        </button>
                                                                    </td>
                                                                    <td>
                                                                        {{ j + 1 }}
                                                                    </td>
                                                                    <td>
                                                                        {{ district?.value?.subdivisionCode }}
                                                                    </td>
                                                                    <td>
                                                                        {{ district?.value?.name }}
                                                                    </td>
                                                                    <td>
                                                                        {{ district?.value?.areaCode }}
                                                                    </td>
                                                                    <td>
                                                                        {{ getStatus(district?.value?.isActive) }}
                                                                    </td>
                                                                    <td class="td-icon">
                                                                        <button class="btn btn-only-icon" (click)="toggleEditDistrict(i, j)">
                                                                            <span class="material-icons icon">
                                                                                edit
                                                                            </span>
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="!district.editHidden">
                                                                    <td></td>
                                                                    <td></td>
                                                                    <td>
                                                                        <form [formGroup]="editDistrictForm"
                                                                            class="input-group border rounded">
                                                                            <input class="form-control border-0" type="text"
                                                                                placeholder="Nhập mã"
                                                                                formControlName="maDonVi">
                                                                        </form>
                                                                    </td>
                                                                    <td>
                                                                        <form [formGroup]="editDistrictForm"
                                                                            class="input-group border rounded">
                                                                            <input class="form-control border-0" type="text"
                                                                                placeholder="Nhập tên đơn vị hành chính"
                                                                                formControlName="tenDvhc">
                                                                        </form>
                                                                    </td>
                                                                    <td>
                                                                        <form [formGroup]="editDistrictForm"
                                                                            class="input-group border rounded">
                                                                            <input class="form-control border-0" type="text"
                                                                                placeholder="Nhập mã địa bàn"
                                                                                formControlName="maDiaBan">
                                                                        </form>
                                                                    </td>
                                                                    <td>
                                                                        <form [formGroup]="editDistrictForm"
                                                                            class="input-group border rounded">
                                                                            <select formControlName="trangThai"
                                                                                class="form-control border-0"
                                                                                >
                                                                                <option value="Y">Đang sử dụng</option>
                                                                                <option value="N">Ngưng sử dụng</option>
                                                                            </select>
                                                                        </form>
                                                                    </td>
                                                                    <td class="td-icon">
                                                                        <span class="material-icons pr-0 icon-done"
                                                                            (click)="editDistrict(i, j)">done</span>
                                                                        <span class="material-icons pl-0 icon-clear"
                                                                            (click)="closeEditDistrictForm(i, j)">clear</span>
                                                                    </td>
                                                                </tr>
    
                                                                <!-- WARD TABLE -->
                                                                <ng-template [ngIf]="!district.childHidden">
                                                                    <tr class="bg-white">
                                                                        <td colspan="7" class="pb-0 pt-0 pr-0">
                                                                            <div class="div-child">
                                                                                <div>
                                                                                    <div class="container-fluid">
                                                                                        <div class="row pl-2 py-2 pr-1 ward-title">
                                                                                            <div class="col-md-6">
                                                                                                <label class="text-lg">Danh sách Xã/Phường</label>
                                                                                            </div>
                                                                                            <div class="col-md-auto ml-auto">
                                                                                                <!-- <button class="btn btn-outline-primary d-inline-flex mr-3">Xuất Excel
                                                                                                <em class="material-icons ml-2">arrow_drop_down</em></button> -->
                                                                                                <div class="btn-group mr-3">
                                                                                                    <button type="button" class="btn btn-outline-primary" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                                      Xuất Excel
                                                                                                    </button>
                                                                                                    <div class="dropdown-menu dropdown-menu-right">
                                                                                                      <button class="dropdown-item" type="button" (click)="exportExcel(district?.value?.id, 'WARD', '')">Xã/Phường</button>
                                                                                                      
                                                                                                    </div>
                                                                                                </div>
                                                                                                <button class="btn btn-primary" (click)="toggleAddWard(i, j)">
                                                                                                    Thêm mới
                                                                                                </button>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="table-response">
                                                                                        <table class="table table-sort-col mb-0 pb-0 t-table ml-0">
                                                                                            <!-- WARD HEADER -->
                                                                                            <thead>
                                                                                                <tr class="bg-t-tb-header">
                                                                                                    <th></th>
                                                                                                    <th>STT</th>
                                                                                                    <th (click)="sortCol('maDonVi')">
                                                                                                        <span class="d-flex align-self-center align-items-center">
                                                                                                            Mã đơn vị
                                                                                                            <span *ngIf="this.sortColConfig['maDonVi'] == null"
                                                                                                                class="material-icons ml-auto">
                                                                                                                unfold_more
                                                                                                            </span>
                                                                                                            <span *ngIf="this.sortColConfig['maDonVi'] == true"
                                                                                                                class="material-icons ml-auto">
                                                                                                                expand_less
                                                                                                            </span>
                                                                                                            <span *ngIf="this.sortColConfig['maDonVi'] == false"
                                                                                                                class="material-icons ml-auto">
                                                                                                                expand_more
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </th>
                                                                                                    <th (click)="sortCol('tenDonViHanhChinh')">
                                                                                                        <span class="d-flex align-self-center align-items-center">
                                                                                                            Tên đơn vị hành chính
                                                                                                            <span
                                                                                                                *ngIf="this.sortColConfig['tenDonViHanhChinh'] == null"
                                                                                                                class="material-icons ml-auto">
                                                                                                                unfold_more
                                                                                                            </span>
                                                                                                            <span
                                                                                                                *ngIf="this.sortColConfig['tenDonViHanhChinh'] == true"
                                                                                                                class="material-icons ml-auto">
                                                                                                                expand_less
                                                                                                            </span>
                                                                                                            <span
                                                                                                                *ngIf="this.sortColConfig['tenDonViHanhChinh'] == false"
                                                                                                                class="material-icons ml-auto">
                                                                                                                expand_more
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </th>
                                                                                                    <th (click)="sortCol('maDiaBan')">
                                                                                                        <span class="d-flex align-self-center align-items-center">
                                                                                                            Mã địa bàn
                                                                                                            <span *ngIf="this.sortColConfig['maDiaBan'] == null"
                                                                                                                class="material-icons ml-auto">
                                                                                                                unfold_more
                                                                                                            </span>
                                                                                                            <span *ngIf="this.sortColConfig['maDiaBan'] == true"
                                                                                                                class="material-icons ml-auto">
                                                                                                                expand_less
                                                                                                            </span>
                                                                                                            <span *ngIf="this.sortColConfig['maDiaBan'] == false"
                                                                                                                class="material-icons ml-auto">
                                                                                                                expand_more
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </th>
                                                                                                    <th (click)="sortCol('trangThai')">
                                                                                                        <span class="d-flex align-self-center align-items-center">
                                                                                                            Trạng thái
                                                                                                            <span *ngIf="this.sortColConfig['trangThai'] == null"
                                                                                                                class="material-icons ml-auto">
                                                                                                                unfold_more
                                                                                                            </span>
                                                                                                            <span *ngIf="this.sortColConfig['trangThai'] == true"
                                                                                                                class="material-icons ml-auto">
                                                                                                                expand_less
                                                                                                            </span>
                                                                                                            <span *ngIf="this.sortColConfig['trangThai'] == false"
                                                                                                                class="material-icons ml-auto">
                                                                                                                expand_more
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </th>
                                                                                                    <th></th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <!-- WARD BODY -->
                                                                                            <tbody class="bg-white">
                                                                                                <tr *ngIf="!district.addChildHidden">
                                                                                                    <td></td>
                                                                                                    <td></td>
                                                                                                    <td>
                                                                                                        <div [formGroup]="addWardForm" class="input-group border rounded">
                                                                                                            <input class="form-control border-0" type="text" placeholder="Nhập mã"
                                                                                                            formControlName="maDonVi">
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div [formGroup]="addWardForm" class="input-group border rounded">
                                                                                                            <input class="form-control border-0" type="text" placeholder="Nhập tên đơn vị hành chính"
                                                                                                            formControlName="tenDvhc">
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div [formGroup]="addWardForm" class="input-group border rounded">
                                                                                                            <input class="form-control border-0" type="text" placeholder="Nhập mã địa bàn"
                                                                                                            formControlName="maDiaBan">
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <div [formGroup]="addWardForm" class="input-group border rounded">
                                                                                                            <select formControlName="trangThai" class="form-control border-0"
                                                                                                            >
                                                                                                                <option value="Y">Đang sử dụng</option>
                                                                                                                <option value="N">Ngưng sử dụng</option>
                                                                                                            </select>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td class="td-icon">
                                                                                                        <span class="material-icons pr-1 icon-done" (click)="addWard(i, j)">done</span>
                                                                                                        <span class="material-icons pl-1 icon-clear" (click)="closeAddWardForm(i, j)">clear</span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <ng-container *ngFor="let ward of district.child; let k = index">
                                                                                                    <tr>
                                                                                                        <td class="td-icon">
                                                                                                            
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {{ k + 1 }}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {{ ward?.value?.subdivisionCode }}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {{ ward?.value?.name }}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {{ ward?.value?.areaCode }}
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            {{ getStatus(ward?.value?.isActive) }}
                                                                                                        </td>
                                                                                                        <td class="td-icon">
                                                                                                            <button class="btn btn-only-icon" (click)="toggleEditWard(i, j, k)">
                                                                                                                <span class="material-icons icon">
                                                                                                                    edit
                                                                                                                </span>
                                                                                                            </button>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                    <tr *ngIf="!ward.editHidden">
                                                                                                        <td></td>
                                                                                                        <td></td>
                                                                                                        <td>
                                                                                                            <form [formGroup]="editWardForm"
                                                                                                                class="input-group border rounded">
                                                                                                                <input class="form-control border-0" type="text"
                                                                                                                    placeholder="Nhập mã"
                                                                                                                    formControlName="maDonVi">
                                                                                                            </form>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <form [formGroup]="editWardForm"
                                                                                                                class="input-group border rounded">
                                                                                                                <input class="form-control border-0" type="text"
                                                                                                                    placeholder="Nhập tên đơn vị hành chính"
                                                                                                                    formControlName="tenDvhc">
                                                                                                            </form>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <form [formGroup]="editWardForm"
                                                                                                                class="input-group border rounded">
                                                                                                                <input class="form-control border-0" type="text"
                                                                                                                    placeholder="Nhập mã địa bàn"
                                                                                                                    formControlName="maDiaBan">
                                                                                                            </form>
                                                                                                        </td>
                                                                                                        <td>
                                                                                                            <form [formGroup]="editWardForm"
                                                                                                                class="input-group border rounded">
                                                                                                                <select formControlName="trangThai"
                                                                                                                    class="form-control border-0"
                                                                                                                    >
                                                                                                                    <option value="Y">Đang sử dụng</option>
                                                                                                                    <option value="N">Ngưng sử dụng</option>
                                                                                                                </select>
                                                                                                            </form>
                                                                                                        </td>
                                                                                                        <td class="td-icon">
                                                                                                            <span class="material-icons pr-0 icon-done"
                                                                                                                (click)="editWard(i, j, k)">done</span>
                                                                                                            <span class="material-icons pl-0 icon-clear"
                                                                                                                (click)="closeEditWardForm(i, j, k)">clear</span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-container>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </ng-container>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr> 
                            </ng-template>
                        </ng-container>
                    </tbody>
                </table>
                <div *ngIf="provinceDirectory.length == 0" class="p-3">
                    <span>Không tìm thấy kết quả</span>
                </div>
            </div>
        </div> 
    </div>
</section>