import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AccountManagerService } from "src/app/utils/services/aas-network/account-manager.service";
import { PersonalDetailObject } from "src/app/utils/models/aas-models/account-manager/personal-detail/personal-detail-object";
import { PersonalDetailResponse } from "src/app/utils/models/aas-models/account-manager/personal-detail/personal-detail-response";
import { AasConsumerManagerService } from "src/app/utils/services/aas-network/aas-consumer-manager.service";
import { Constant } from "./../../../../../utils/common/constant_videocall";
import { localize } from "src/app/utils/localize/localize";

@Component({
  selector: "app-service-account-detail",
  templateUrl: "./service-account-detail.component.html",
  styleUrls: ["./service-account-detail.component.scss"],
})
export class ServiceAccountDetailComponent implements OnInit {
  partyId = this.router.snapshot.paramMap.get("id");
  personalDetailObject: PersonalDetailObject;
  personalDetailResponse: PersonalDetailResponse;

  url: any = null;
  imageFirst: any = null;
  imageSecond: any = null;
  imageUrls: string[] = [];
  imageSign: any = null;

  localize = localize;

  constructor(
    private router: ActivatedRoute,
    private accountManagerService: AccountManagerService,
    private aasConsumerManagerService: AasConsumerManagerService,
    private route: Router
  ) { }

  ngOnInit(): void {
    this.detailEnterprise();
  }

  detailEnterprise() {
    this.accountManagerService.getPersonalById(this.partyId).subscribe(
      (res) => {
        this.personalDetailResponse = new PersonalDetailResponse(res);
        this.personalDetailObject = this.personalDetailResponse.object;
        this.url = null;
        this.imageFirst = null;
        this.imageSecond = null;
        // this.imageUrls = [];
        this.getImage(this.personalDetailObject.userId, "EKYC_CHANDUNG");
        this.getImage(this.personalDetailObject.userId, "EKYC_MATTRUOC");
        this.getImage(this.personalDetailObject.userId, "EKYC_MATSAU");
        this.getImage(this.personalDetailObject.userId, "CHUKY");
      },
      (err) => {
        if (!!err.error.error) {
          // this.alert(err.error.error[0], 'error');
          console.log(err.error.error[0]);
        }
      }
    );
  }

  async getImage(id, type) {
    this.aasConsumerManagerService.downloadImage(id, type).subscribe(
      (res) => {
        if (res) {
          const blob: any = new Blob([res], {
            type: "image/png",
          });
          var reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            switch (type) {
              case "EKYC_CHANDUNG":
                this.url = reader.result;
                // localStorage.setItem(Constant.IMAGE_FACE, this.url);
                break;
              case "EKYC_MATTRUOC":
                this.imageFirst = reader.result;
                break;
              case "EKYC_MATSAU":
                this.imageSecond = reader.result;
                break;
              case "CHUKY":
                this.imageSign = reader.result;
                break;
              default:
            }
          };
        }
      },
      (err) => {
        if (type === "EKYC_CHANDUNG") {
          this.url = null;
        } else if (type === "EKYC_MATTRUOC") {
          this.imageFirst = null;
        } else if (type === "CHUKY") {
          this.imageSign = null;
        } else {
          this.imageSecond = null;
        }
      },
      () => { }
    );
  }

  getStyleAndValueBadge(disable): string[] {
    if (disable == "N") {
      return ['badge-success-new', 'Đang hoạt động'];
    } else if (disable == "Y") {
      return ['badge-danger-new', 'Ngưng hoạt động'];
    }
    return ['', ''];
  }

  // Move to assign-to-enduser.component.ts
  moveToAssignPackageScreen() {
    this.route.navigate(
      [`/console/package-manager/assign/${this.partyId}`],
      { queryParams: { 'userType': 'individual' } }
    );
  }
}
