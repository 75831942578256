<div class="wrapper">
    <nav class="navbar navbar-expand navbar-primary navbar-dark">
        <a class="navbar-brand" href="">
            <h4 class="ml-3 font-weight-bold mb-0">eContract</h4>
        </a>
    </nav>

    <div class="wrapper">
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-center mt-3">
                    <div class="col-lg-5 col-md-7 col-sm-10">
                        <div class="card shadow-none">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-auto align-self-end">
                                        <label class="text-lg">{{ 'EmployeeFinishSignup_label_1' | translate }}</label>
                                    </div>
                                </div>
                                <hr class="py-2 mt-2">
                                <div class="">
                                    <form [formGroup]="form">
                                        <div class="input-group input-custom mb-4">
                                            <span class="input-custom-label">{{ 'EmployeeInPart_email' | translate }} <span
                                                    class="text-danger">*</span></span>
                                            <input appTrimValue formControlName="email" type="text"
                                                class="form-control form-control-lg input-custom-control"
                                                placeholder="{{ 'EmployeeAdd_ph_2' | translate }}" disabled />
                                            <app-show-validate-errors [isSubmit]="isSubmit"
                                                [errorMessages]="vatidateForm.email" [control]="form.controls.email"
                                                [detail]="{ name: 'email' }">
                                            </app-show-validate-errors>
                                        </div>
                                        <div class="input-group input-custom mb-4">
                                            <span class="input-custom-label">{{ 'EmployeeInPart_phone_number' | translate }} <span
                                                    class="text-danger">*</span></span>
                                            <input appTrimValue appTrackerPhoneNumber formControlName="sdt" type="text"
                                                class="form-control form-control-lg input-custom-control"
                                                placeholder="{{ 'EmployeeAdd_ph_3' | translate }}" disabled />
                                            <app-show-validate-errors [isSubmit]="isSubmit"
                                                [errorMessages]="vatidateForm.sdt" [control]="form.controls.sdt"
                                                [detail]="{ name: 'sdt' }">
                                            </app-show-validate-errors>
                                        </div>
                                        <div class="input-group input-custom mb-4">
                                            <span class="input-custom-label">{{ 'EmployeeInPart_username' | translate }} <span
                                                    class="text-danger">*</span></span>
                                            <input appTrimValue formControlName="username" type="text"
                                                class="form-control form-control-lg input-custom-control"
                                                placeholder="{{ 'SmartcaManager_ph_2' | translate }}" />
                                            <app-show-validate-errors [isSubmit]="isSubmit"
                                                [errorMessages]="vatidateForm.username"
                                                [control]="form.controls.username" [detail]="{ name: 'username' }">
                                            </app-show-validate-errors>
                                        </div>
                                        <div class="input-group input-custom mb-4">
                                            <span class="input-custom-label">{{ 'Login_password' | translate }} <span
                                                    class="text-danger">*</span></span>
                                            <input appInputPassword formControlName="password" type="password"
                                                class="form-control form-control-lg input-custom-control"
                                                placeholder="{{ 'SmartcaManager_ph_3' | translate }}" />
                                            <app-show-validate-errors [isSubmit]="isSubmit"
                                                [errorMessages]="vatidateForm.password"
                                                [control]="form.controls.password" [detail]="{ name: 'password' }">
                                            </app-show-validate-errors>

                                            <p class="mt-3 text-gray-dark" style="font-style: italic;">{{ 'ChangePassword_note' | translate }}</p>
                                        </div>

                                        <div class="d-flex justify-content-center">
                                            <button class="btn btn-lg btn-primary" (click)="signup()">{{ 'EmployeeFinishSignup_label_2' | translate }}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>