import { Component, OnInit, ViewChild } from '@angular/core';
import { UpdateIdcardComponent } from './update-idcard/update-idcard.component';
import { EkycService } from 'src/app/utils/services/aas-service/ekyc-service/ekyc.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UpdateCitizenidComponent } from './update-citizenid/update-citizenid.component';
import { UpdatePassportComponent } from './update-passport/update-passport.component';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { localize } from 'src/app/utils/localize/localize';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-update-configuration-information',
  templateUrl: './update-configuration-information.component.html',
  styleUrls: ['./update-configuration-information.component.scss']
})
export class UpdateConfigurationInformationComponent implements OnInit {
  @ViewChild(UpdateIdcardComponent) private updateIdCard: UpdateIdcardComponent;
  @ViewChild(UpdateCitizenidComponent) private updateCitizenId: UpdateCitizenidComponent;
  @ViewChild(UpdatePassportComponent) private updatePassport: UpdatePassportComponent;

  tabSelected = this.activedRoute.snapshot.queryParamMap.get('type') || "CMND"; //CCCD, PASSPORT

  constructor(
    private ekycService: EkycService,
    private router: Router,
    private activedRoute: ActivatedRoute,
    private myAlert: AlertControl,
    private translate: TranslateService
  ) { }

  ngAfterViewInit() {
    
  }

  ngOnInit(): void {
    
  }
 

  cancel() {
    let itOK = false;
    switch (this.tabSelected) {
      case "CMND": 
        itOK = !this.updateIdCard.isEdited;
        break;
      case "CCCD":
        itOK = !this.updateCitizenId.isEdited;
        break;
      case "PASSPORT":
        itOK = !this.updatePassport.isEdited;
        break;
    }

    if (!itOK) {
      this.myAlert.showAlert(this.translate.instant('UpdateConfigurationInformation_ts_1'), "", true, "", this.translate.instant('UpdateConfigurationInformation_btn_leave'), () => {
        this.router.navigate([ConstantUrl.enduser_ekyc_config], { queryParams: { 'type':  this.tabSelected }});
      }, "", this.translate.instant('btn_cancel'), () => { return }, this.translate.instant('UpdateConfigurationInformation_btn_leave_2'), {backdrop: 'static'});
    } else {
      this.router.navigate([ConstantUrl.enduser_ekyc_config], { queryParams: { 'type':  this.tabSelected }});
    }
    
  }

  selectTab(value) {
    let itOK = false;
    switch (this.tabSelected) {
      case "CMND": 
        itOK = !this.updateIdCard.isEdited;
        break;
      case "CCCD":
        itOK = !this.updateCitizenId.isEdited;
        break;
      case "PASSPORT":
        itOK = !this.updatePassport.isEdited;
        break;
    }

    if (!itOK) {
      this.myAlert.showAlert(this.translate.instant('UpdateConfigurationInformation_ts_1'), "", true, "", this.translate.instant('UpdateConfigurationInformation_btn_leave'), () => {
        this.tabSelected = value;
      }, "", this.translate.instant('btn_cancel'), () => { return }, this.translate.instant('UpdateConfigurationInformation_btn_leave_2'), {backdrop: 'static'});
    } else {
      this.tabSelected = value;
    }
  }

  save() {
    switch (this.tabSelected) {
      case "CMND": 
        this.updateIdCard.save();
        break;
      case "CCCD":
        this.updateCitizenId.save();
        break;
      case "PASSPORT":
        this.updatePassport.save();
        break;
    }
  }
}
