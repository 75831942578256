import { NgModalComponent } from './layout/extensions/ng-modal/ng-modal.component';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgSelect2Module } from 'ng-select2';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

//Plugin
import {
    NgbModule,
    NgbPaginationModule,
    NgbDateParserFormatter,
} from '@ng-bootstrap/ng-bootstrap';
import { ToastContainerModule, ToastNoAnimationModule, ToastrModule } from 'ngx-toastr';
import { ChartsModule } from 'ng2-charts';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

//Service
// import { TemplateService } from './utils/services/template.service';
import { AppService } from './utils/services/app.service';
import { API } from './utils/services/api';
import { AuthService } from './utils/services/auth.service';
// import { CustomerService } from './utils/services/customer.service';
// import { ReportService } from './utils/services/report.service';
// import { CatalogService } from './utils/services/catalog.service';
import { LoadingService } from './utils/services/loading.service';

//Component
import { MainComponent } from './layout/main/main.component';
import { HeaderComponent } from './layout/main/header/header.component';
import { FooterComponent } from './layout/main/footer/footer.component';
import { MenuSidebarComponent } from './layout/main/menu-sidebar/menu-sidebar.component';
import { MessagesDropdownMenuComponent } from './layout/main/header/messages-dropdown-menu/messages-dropdown-menu.component';
import { ShowWithPermissionsDirective } from './utils/directives/show-with-permissions.directive';
import { SpinnerComponent } from './layout/extensions/spinner/spinner.component';
import { CounterDirective } from './utils/directives/counter.directive';
import { NgbDateFRParserFormatter } from './utils/common/ngb-date-fr-parser-formatter';
import { LoadingInterceptor } from './utils/interceptors/loading.interceptor';
import { DragDropDirective } from './utils/directives/drag-drop.directive';
import { ShowErrorsComponent } from './layout/extensions/show-errors/show-errors.component';
import { MiniSpinnerComponent } from './layout/extensions/mini-spinner/mini-spinner.component';
import { ServiceProfileManagerComponent } from './service-pages/service-console/service-enduser-console/profile-manager/service-profile-manager/service-profile-manager.component';
import { ServiceDashboardComponent } from './service-pages/service-console/service-dashboard/service-dashboard.component';
import { ProfileConsumerComponent } from './service-pages/service-console/service-enduser-console/profile-manager/service-profile-manager/profile-consumer/profile-consumer.component';
import { ProfileBusinessComponent } from './service-pages/service-console/service-enduser-console/profile-manager/service-profile-manager/profile-business/profile-business.component';
import { ServiceLoginComponent } from './service-pages/service-auth/service-login/service-login.component';
import { ServiceSignupComponent } from './service-pages/service-auth/service-register/service-signup/service-signup.component';
import { ServiceSignupBusinessComponent } from './service-pages/service-auth/service-register/service-signup-business/service-signup-business.component';
import { ServiceRequestOtpComponent } from './service-pages/service-auth/service-request-otp/service-request-otp.component';
import { ServiceForgotPasswordComponent } from './service-pages/service-auth/service-forgot-password/service-forgot-password.component';
import { InputPasswordDirective } from './utils/directives/input-password.directive';
import { ServiceRenewPasswordComponent } from './service-pages/service-auth/service-forgot-password/service-renew-password/service-renew-password.component';
import { AasAuthService } from './utils/services/aas-network/aas-auth.service';
import { ServiceSignupSuccessComponent } from './service-pages/service-auth/service-register/service-signup-success/service-signup-success.component';
import { ServiceRegisterManagerComponent } from './service-pages/service-console/service-admin-console/service-register-manager/service-register-manager.component';
import { ShowValidateErrorsComponent } from './layout/extensions/show-validate-errors/show-validate-errors.component';
import { ServiveVerifyBusinessComponent } from './service-pages/service-console/service-enduser-console/profile-manager/servive-verify-business/servive-verify-business.component';
import { ServiceSignatureManagerComponent } from './service-pages/service-console/service-enduser-console/signature-manager/service-signature-manager/service-signature-manager.component';
import { UsbTokenManagerComponent } from './service-pages/service-console/service-enduser-console/signature-manager/service-signature-manager/usb-token-manager/usb-token-manager.component';
import { SignServerManagerComponent } from './service-pages/service-console/service-enduser-console/signature-manager/service-signature-manager/sign-server-manager/sign-server-manager.component';
import { DigitalSignatureManagerComponent } from './service-pages/service-console/service-enduser-console/signature-manager/service-signature-manager/digital-signature-manager/digital-signature-manager.component';
import { EnterpriseManagerComponent } from './service-pages/service-console/service-admin-console/enterprise-manager/enterprise-manager.component';
import { AlertComponent } from './layout/extensions/alert/alert.component';
import { ServiceRedirectVnptcaLoginComponent } from './service-pages/service-console/service-enduser-console/signature-manager/service-signature-manager/service-redirect-vnptca-login/service-redirect-vnptca-login.component';
import { WaitingComponent } from './service-pages/service-console/service-admin-console/service-register-manager/waiting/waiting.component';
import { ApprovedComponent } from './service-pages/service-console/service-admin-console/service-register-manager/approved/approved.component';
import { RejectComponent } from './service-pages/service-console/service-admin-console/service-register-manager/reject/reject.component';
import { ServiceSignatureConfigComponent } from './service-pages/service-console/service-enduser-console/signature-manager/service-signature-manager/sign-server-manager/service-signature-config/service-signature-config.component';
import { ServiceContractManagerComponent } from './service-pages/service-console/service-enduser-console/contract-manager/service-contract-manager/service-contract-manager.component';
import { ServiceContractListComponent } from './service-pages/service-console/service-enduser-console/contract-manager/service-contract-manager/service-contract-list/service-contract-list.component';
import { ApproveComponent } from './service-pages/service-console/service-admin-console/enterprise-manager/approve/approve.component';
import { RejectEnterpriseComponent } from './service-pages/service-console/service-admin-console/enterprise-manager/reject-enterprise/reject-enterprise.component';
import { ServiceAccountManagerComponent } from './service-pages/service-console/service-admin-console/service-account-manager/service-account-manager.component';
import { MatTableModule } from '@angular/material/table';
import { ServiceAccountDetailComponent } from './service-pages/service-console/service-admin-console/service-account-manager/service-account-detail/service-account-detail.component';
import { HomeComponentV3 } from './service-pages/home/home-v3.component';
import { VideoCallComponent } from './service-pages/service-console/service-admin-console/service-register-manager/video-call/video-call.component';
import { AasConsumerManagerService } from 'src/app/utils/services/aas-network/aas-consumer-manager.service';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { LandingMainComponent } from './layout/landing-main/landing-main.component';
import { LandingHeaderComponent } from './layout/landing-main/landing-header/landing-header.component';
import { LandingFooterComponent } from './layout/landing-main/landing-footer/landing-footer.component';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Web2webVideocallComponent } from './service-pages/service-console/service-admin-console/enterprise-manager/web2web-videocall/web2web-videocall.component';
import { AasMainComponent } from './layout/aas-main/aas-main.component';
import { AasMenuSidebarComponent } from './layout/aas-main/aas-menu-sidebar/aas-menu-sidebar.component';
import { AasHeaderComponent } from './layout/aas-main/aas-header/aas-header.component';
import { AasFooterComponent } from './layout/aas-main/aas-footer/aas-footer.component';
import { ServiceContractDetailComponent } from './service-pages/service-console/service-enduser-console/contract-manager/service-contract-detail/service-contract-detail.component';
import { ServiceAccountEditComponent } from './service-pages/service-console/service-admin-console/service-account-manager/service-account-edit/service-account-edit.component';
import { ServiceOrganizationDetailComponent } from './service-pages/service-console/service-admin-console/service-account-manager/service-organization/service-organization-detail/service-organization-detail.component';
import { ServiceOrganizationUpdateComponent } from './service-pages/service-console/service-admin-console/service-account-manager/service-organization/service-organization-update/service-organization-update.component';
import { ServiceContractComponent } from './service-pages/service-console/service-enduser-console/contract-manager/service-contract/service-contract.component';
import { TrimValueDirective } from './utils/directives/trim-value.directive';
import { RequestErrorComponent } from './layout/extensions/request-error/request-error.component';
import { CommonModule, CurrencyPipe, AsyncPipe} from '@angular/common';
import { environment } from 'src/environments/environment';
import { FirebaseService } from './utils/services/aas-network/firebase/firebase.service';
import { ServicePackageListComponent } from './service-pages/service-console/service-admin-console/service-package-manager/service-package-list/service-package-list.component';
import { ServicePackageDetailComponent } from './service-pages/service-console/service-admin-console/service-package-manager/service-package-detail/service-package-detail.component';
import { CreateServicePakageComponent } from './service-pages/service-console/service-admin-console/service-package-manager/create-service-pakage/create-service-pakage.component';
import { CopyrightListComponent } from './service-pages/service-console/service-admin-console/service-copyright-manager/copyright-list/copyright-list.component';
import { CopyrightDetailComponent } from './service-pages/service-console/service-admin-console/service-copyright-manager/copyright-detail/copyright-detail.component';
import { DetailInfoComponent } from './service-pages/service-console/service-admin-console/service-copyright-manager/copyright-detail/detail-info/detail-info.component';
import { CustomerInfoComponent } from './service-pages/service-console/service-admin-console/service-copyright-manager/copyright-detail/customer-info/customer-info.component';
import { BusinessInfoComponent } from './service-pages/service-console/service-admin-console/service-copyright-manager/copyright-detail/business-info/business-info.component';
import { ContractInfoComponent } from './service-pages/service-console/service-admin-console/service-copyright-manager/copyright-detail/contract-info/contract-info.component';
import { UpdateServicePackageComponent } from './service-pages/service-console/service-admin-console/service-package-manager/update-service-package/update-service-package.component';
import { ServiceSignupBusinessVipComponent } from './service-pages/service-auth/service-register/service-signup-business-vip/service-signup-business-vip.component';
import { ServiceVerifyBusinessVipComponent } from './service-pages/service-console/service-enduser-console/service-verify-business-vip/service-verify-business-vip.component';
import { StepOneVerifyVipComponent } from './service-pages/service-console/service-enduser-console/service-verify-business-vip/step-one-verify-vip/step-one-verify-vip.component';
import { StepTwoVerifyVipComponent } from './service-pages/service-console/service-enduser-console/service-verify-business-vip/step-two-verify-vip/step-two-verify-vip.component';
import { StepThreeVerifyVipComponent } from './service-pages/service-console/service-enduser-console/service-verify-business-vip/step-three-verify-vip/step-three-verify-vip.component';
import { StepFourVerifyVipComponent } from './service-pages/service-console/service-enduser-console/service-verify-business-vip/step-four-verify-vip/step-four-verify-vip.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { ServiceEnterpriseVipManagerComponent } from './service-pages/service-console/service-admin-console/service-enterprise-vip-manager/service-enterprise-vip-manager.component';
import { EnterpriseVipWaitingComponent } from './service-pages/service-console/service-admin-console/service-enterprise-vip-manager/enterprise-vip-waiting/enterprise-vip-waiting.component';
import { EnterpriseVipRejectedComponent } from './service-pages/service-console/service-admin-console/service-enterprise-vip-manager/enterprise-vip-rejected/enterprise-vip-rejected.component';
import { EnterpriseVipApprovedComponent } from './service-pages/service-console/service-admin-console/service-enterprise-vip-manager/enterprise-vip-approved/enterprise-vip-approved.component';
import { ServiceContractTemplateGroupComponent } from './service-pages/service-console/service-admin-console/service-package-manager/service-contract-template-group/service-contract-template-group.component';
import { CreateContractApproveComponent } from './service-pages/service-console/service-admin-console/service-enterprise-vip-manager/enterprise-vip-waiting/create-contract-approve/create-contract-approve.component';
import { DetailContractApproveComponent } from './service-pages/service-console/service-admin-console/service-enterprise-vip-manager/enterprise-vip-waiting/detail-contract-approve/detail-contract-approve.component';
import { StepFourContractDetailComponent } from './service-pages/service-console/service-enduser-console/service-verify-business-vip/step-four-verify-vip/step-four-contract-detail/step-four-contract-detail.component';
import { ServiceEinvoicesListComponent } from './service-pages/service-console/service-enduser-console/einvoices-manager/service-einvoices-list/service-einvoices-list.component';
import { ServiceEnvoicesDetailComponent } from './service-pages/service-console/service-enduser-console/einvoices-manager/service-envoices-detail/service-envoices-detail.component';
import { ServiceEnvoicesCreateComponent } from './service-pages/service-console/service-enduser-console/einvoices-manager/service-envoices-create/service-envoices-create.component';
import { AccoutEinvoicesListComponent } from './service-pages/service-console/service-enduser-console/einvoices-manager/accout-einvoices-list/accout-einvoices-list.component';
import { AccountEinvoicesCreateComponent } from './service-pages/service-console/service-enduser-console/einvoices-manager/account-einvoices-create/account-einvoices-create.component';
import { AccountEnvoicesDetailComponent } from './service-pages/service-console/service-enduser-console/einvoices-manager/account-envoices-detail/account-envoices-detail.component';
import { AccountEcvoicesUpdateComponent } from './service-pages/service-console/service-enduser-console/einvoices-manager/account-ecvoices-update/account-ecvoices-update.component';
import { ServiceApiManagerComponent } from './service-pages/service-console/service-enduser-console/api-manager/service-api-manager/service-api-manager.component';
import { CodeblockViewerComponent } from './layout/extensions/codeblock-viewer/codeblock-viewer.component';
import { CodeblockEditorComponent } from './layout/extensions/codeblock-editor/codeblock-editor.component';
import { ServiceApiListComponent } from './service-pages/service-console/service-admin-console/service-api-manager/service-api-list/service-api-list.component';
import { ServiceApiEditorComponent } from './service-pages/service-console/service-admin-console/service-api-manager/service-api-editor/service-api-editor.component';
import { AutoFocusDirective } from './utils/directives/auto-focus.directive';
import { ServiceApiDetailComponent } from './service-pages/service-console/service-admin-console/service-api-manager/service-api-detail/service-api-detail.component';
import { ServiceSignupBusinessVipStepComponent } from './service-pages/service-auth/service-register/service-signup-business-vip-step/service-signup-business-vip-step.component';
import { SignupStepOneComponent } from './service-pages/service-auth/service-register/service-signup-business-vip-step/signup-step-one/signup-step-one.component';
import { SignupStepTwoComponent } from './service-pages/service-auth/service-register/service-signup-business-vip-step/signup-step-two/signup-step-two.component';
import { SignupStepThreeComponent } from './service-pages/service-auth/service-register/service-signup-business-vip-step/signup-step-three/signup-step-three.component';
import { WrongPageComponent } from './service-pages/wrong-page/wrong-page.component';
import { EmptyBlockComponent } from './layout/extensions/empty-block/empty-block.component';
import { ListCategoryPackageComponent } from './service-pages/service-console/service-admin-console/service-package-manager/service-category-package/list-category-package/list-category-package.component';
import { CreateCategoryPackageComponent } from './service-pages/service-console/service-admin-console/service-package-manager/service-category-package/create-category-package/create-category-package.component';
import { DetailCategoryPackageComponent } from './service-pages/service-console/service-admin-console/service-package-manager/service-category-package/detail-category-package/detail-category-package.component';
import { UpdateCategoryPackageComponent } from './service-pages/service-console/service-admin-console/service-package-manager/service-category-package/update-category-package/update-category-package.component';
import { ServiceSignupV2Component } from './service-pages/service-auth/service-register/service-signup-v2/service-signup-v2.component';
import { SignupV2StepOneComponent } from './service-pages/service-auth/service-register/service-signup-v2/signup-v2-step-one/signup-v2-step-one.component';
import { SignupV2StepTwoComponent } from './service-pages/service-auth/service-register/service-signup-v2/signup-v2-step-two/signup-v2-step-two.component';
import { SignupV2StepThreeComponent } from './service-pages/service-auth/service-register/service-signup-v2/signup-v2-step-three/signup-v2-step-three.component';
import { SignupConsumerComponent } from './service-pages/service-auth/service-register/service-signup-v2/signup-v2-step-two/signup-consumer/signup-consumer.component';
import { SignupBusinessComponent } from './service-pages/service-auth/service-register/service-signup-v2/signup-v2-step-two/signup-business/signup-business.component';
import { SignupEnterpriseComponent } from './service-pages/service-auth/service-register/service-signup-v2/signup-v2-step-two/signup-enterprise/signup-enterprise.component';
import { ServiceProductsManagerComponent } from './service-pages/service-console/service-enduser-console/products-manager/service-products-manager/service-products-manager.component';
import { EnduserPackagesManagerComponent } from './service-pages/service-console/service-enduser-console/packages-manager/enduser-packages-manager/enduser-packages-manager.component';
import { EnduserPackageDetailComponent } from './service-pages/service-console/service-enduser-console/packages-manager/enduser-package-detail/enduser-package-detail.component';
import { NotificationsDropdownMenuComponent } from './layout/aas-main/aas-header/notifications-dropdown-menu/notifications-dropdown-menu.component';
import { DocumentsManagerComponent } from './service-pages/service-console/service-enduser-console/documents-manager/documents-manager.component';
import { ServiceDashboardEnduserComponent } from './service-pages/service-console/service-enduser-console/dashboard/service-dashboard-enduser/service-dashboard-enduser.component';
import { ConsumerPartnerVerificationComponent } from './service-pages/service-console/service-enduser-console/partner/consumer-partner-verification/consumer-partner-verification.component';
import { PartyPartnerVerificationComponent } from './service-pages/service-console/service-enduser-console/partner/party-partner-verification/party-partner-verification.component';
import { TrustedPartnerManagerComponent } from './service-pages/service-console/service-enduser-console/partner/trusted-partner-manager/trusted-partner-manager.component';
import { WaitConsumerPartnerComponent } from './service-pages/service-console/service-enduser-console/partner/consumer-partner-verification/wait-consumer-partner/wait-consumer-partner.component';
import { ApprovedConsumerPartnerComponent } from './service-pages/service-console/service-enduser-console/partner/consumer-partner-verification/approved-consumer-partner/approved-consumer-partner.component';
import { RejectedConsumerPartnerComponent } from './service-pages/service-console/service-enduser-console/partner/consumer-partner-verification/rejected-consumer-partner/rejected-consumer-partner.component';
import { WaitPartyPartnerComponent } from './service-pages/service-console/service-enduser-console/partner/party-partner-verification/wait-party-partner/wait-party-partner.component';
import { ApprovedPartyPartnerComponent } from './service-pages/service-console/service-enduser-console/partner/party-partner-verification/approved-party-partner/approved-party-partner.component';
import { RejectedPartyPartnerComponent } from './service-pages/service-console/service-enduser-console/partner/party-partner-verification/rejected-party-partner/rejected-party-partner.component';
import { PreviewGpkdComponent } from './service-pages/service-console/service-enduser-console/partner/preview-gpkd/preview-gpkd.component';
import { TrustedPartnerListComponent } from './service-pages/service-console/service-enduser-console/partner/trusted-partner-manager/trusted-partner-list/trusted-partner-list.component';
import { TrustedPartnerDetailComponent } from './service-pages/service-console/service-enduser-console/partner/trusted-partner-detail/trusted-partner-detail.component';
import { PreviewModalComponent } from './layout/extensions/preview-modal/preview-modal.component';
import { ScrollTrackerDirective } from './utils/directives/scroll-tracker.directive';
import { ReportContractComponent } from './service-pages/service-console/service-admin-console/service-quantity-report/report-contract/report-contract.component';
import { ReportDetailContractComponent } from './service-pages/service-console/service-admin-console/service-quantity-report/report-detail-contract/report-detail-contract.component';
import { ReportServiceComponent } from './service-pages/service-console/service-admin-console/service-quantity-report/report-service/report-service.component';
import { ReportPackageComponent } from './service-pages/service-console/service-admin-console/service-quantity-report/report-package/report-package.component';
import { ApiKeyManagerComponent } from './service-pages/service-console/service-admin-console/service-api-key-manager/api-key-manager/api-key-manager.component';
import { ApiKeyDetailComponent } from './service-pages/service-console/service-admin-console/service-api-key-manager/api-key-detail/api-key-detail.component';
import { PartyJoinComponent } from './service-pages/service-console/service-enduser-console/contract-manager/service-contract/party-join/party-join.component';
import { InputMarkDirective } from './utils/directives/input-mark.directive';
import { ChatManagerComponent } from './service-pages/service-console/service-enduser-console/chat-manager/chat-manager.component';
import { AttachFileComponent } from './service-pages/service-console/service-enduser-console/chat-manager/attach-file/attach-file.component';
import { ServiceContractListV2Component } from './service-pages/service-console/service-enduser-console/contract-manager/service-contract-manager/service-contract-list-v2/service-contract-list-v2.component';
import { OnlyNumberDirective } from './utils/directives/only-number.directive';
import { AdminContractManagerComponent } from './service-pages/service-console/service-admin-console/admin-contract/admin-contract-manager/admin-contract-manager.component';
import { AdminContractComponent } from './service-pages/service-console/service-admin-console/admin-contract/admin-contract/admin-contract.component';
import { AdminContractDetailComponent } from './service-pages/service-console/service-admin-console/admin-contract/admin-contract-detail/admin-contract-detail.component';
import { AdminContractListComponent } from './service-pages/service-console/service-admin-console/admin-contract/admin-contract-manager/admin-contract-list/admin-contract-list.component';
import { AdminContractPartyjoinComponent } from './service-pages/service-console/service-admin-console/admin-contract/admin-contract/admin-contract-partyjoin/admin-contract-partyjoin.component';
import { PackageNotiBlockComponent } from './layout/extensions/package-session/package-noti-block/package-noti-block.component';
import { ServicePackageConversionListComponent } from './service-pages/service-console/service-admin-console/service-package-manager/service-package-conversion-list/service-package-conversion-list.component';
import { NgSelectConfig, NgSelectModule } from '@ng-select/ng-select';
import { AlertControl } from './utils/alert/alert-control';
import { PartListComponent } from './service-pages/service-console/service-enduser-console/part-manager/part-list/part-list.component';
import { PartAddComponent } from './service-pages/service-console/service-enduser-console/part-manager/part-add/part-add.component';
import { PartEditComponent } from './service-pages/service-console/service-enduser-console/part-manager/part-edit/part-edit.component';
import { PartDetailComponent } from './service-pages/service-console/service-enduser-console/part-manager/part-detail/part-detail.component';
import { TrackerPhoneNumberDirective } from './utils/directives/tracker-phone-number/tracker-phone-number.directive';
import { EmployeeListComponent } from './service-pages/service-console/service-enduser-console/employee/employee-list/employee-list.component';
import { EmployeeAddComponent } from './service-pages/service-console/service-enduser-console/employee/employee-add/employee-add.component';
import { EmployeeEditComponent } from './service-pages/service-console/service-enduser-console/employee/employee-edit/employee-edit.component';
import { EmployeeDetailComponent } from './service-pages/service-console/service-enduser-console/employee/employee-detail/employee-detail.component';
import { EmployeeFinishSignupComponent } from './service-pages/service-console/service-enduser-console/employee/employee-finish-signup/employee-finish-signup.component';
import { EmployeeInPartComponent } from './service-pages/service-console/service-enduser-console/part-manager/employee-in-part/employee-in-part.component';
import { ServiceLandingPageCategoryComponent } from './service-pages/service-console/service-landing-page/service-landing-page-category/service-landing-page-category.component';
import { ServiceLandingPageBlogComponent } from './service-pages/service-console/service-landing-page/service-landing-page-blog/service-landing-page-blog.component';
import { ServiceLandingPageConfigComponent } from './service-pages/service-console/service-landing-page/service-landing-page-config/service-landing-page-config.component';
import { ServiceLandingPageBlogTypeComponent } from './service-pages/service-console/service-landing-page/service-landing-page-blog-type/service-landing-page-blog-type.component';
import { ServiceLandingPageBlogAddComponent } from './service-pages/service-console/service-landing-page/service-landing-page-blog/service-landing-page-blog-add/service-landing-page-blog-add/service-landing-page-blog-add.component';
import { ServiceLandingPageBlogEditComponent } from './service-pages/service-console/service-landing-page/service-landing-page-blog/service-landing-page-blog-edit/service-landing-page-blog-edit/service-landing-page-blog-edit.component';
import { ServiceLandingPageBlogTypeAddComponent } from './service-pages/service-console/service-landing-page/service-landing-page-blog-type/service-landing-page-blog-type-add/service-landing-page-blog-type-add/service-landing-page-blog-type-add.component';
import { ServiceLandingPageBlogTypeEditComponent } from './service-pages/service-console/service-landing-page/service-landing-page-blog-type/service-landing-page-blog-type-edit/service-landing-page-blog-type-edit/service-landing-page-blog-type-edit.component';
import { ServiceLandingPageCategoryAddComponent } from './service-pages/service-console/service-landing-page/service-landing-page-category/service-landing-page-category-add/service-landing-page-category-add/service-landing-page-category-add.component';
import { ServiceLandingPageCategoryEditComponent } from './service-pages/service-console/service-landing-page/service-landing-page-category/service-landing-page-category-edit/service-landing-page-category-edit/service-landing-page-category-edit.component';
import { ServiceLandingPageConfigEditComponent } from './service-pages/service-console/service-landing-page/service-landing-page-config/service-landing-page-config-edit/service-landing-page-config-edit/service-landing-page-config-edit.component';
import { ServiceLandingPageConfigAddComponent } from './service-pages/service-console/service-landing-page/service-landing-page-config/service-landing-page-config-add/service-landing-page-config-add/service-landing-page-config-add.component';
import { LandingpageService } from './utils/services/aas-network/landingpage.service';
import { ConsumerSignupManagerComponent } from './service-pages/service-console/service-admin-console/admin-account-signup-manager/consumer-signup/consumer-signup-manager/consumer-signup-manager.component';
import { ConsumerSignupWaitComponent } from './service-pages/service-console/service-admin-console/admin-account-signup-manager/consumer-signup/layout/consumer-signup-wait/consumer-signup-wait.component';
import { ConsumerSignupApprovedComponent } from './service-pages/service-console/service-admin-console/admin-account-signup-manager/consumer-signup/layout/consumer-signup-approved/consumer-signup-approved.component';
import { ConsumerSignupRejectedComponent } from './service-pages/service-console/service-admin-console/admin-account-signup-manager/consumer-signup/layout/consumer-signup-rejected/consumer-signup-rejected.component';
import { ContractCensorshipComponent } from './service-pages/service-console/service-enduser-console/contract-manager/layout/contract-censorship/contract-censorship.component';
import { AccountBusinessListComponent } from './service-pages/service-console/service-admin-console/service-account-manager/layout/account-business-manager/account-business-list/account-business-list.component';
import { AccountConsumerListComponent } from './service-pages/service-console/service-admin-console/service-account-manager/layout/account-consumer-manager/account-consumer-list/account-consumer-list.component';
import { WvAppComponent } from './webview/wv-app/wv-app.component';
import { WvLoginComponent } from './webview/wv-app/wv-page/wv-login/wv-login.component';
import { WvContractsManagerComponent } from './webview/wv-app/wv-page/wv-contracts/wv-contracts-manager/wv-contracts-manager.component';
import { WvContractsListComponent } from './webview/wv-app/wv-page/wv-contracts/wv-contracts-manager/layout/wv-contracts-list/wv-contracts-list.component';
import { WvContractAddComponent } from './webview/wv-app/wv-page/wv-contracts/wv-contract-add/wv-contract-add.component';
import { WvContractDetailComponent } from './webview/wv-app/wv-page/wv-contracts/wv-contract-detail/wv-contract-detail.component';
import { WvContractEditComponent } from './webview/wv-app/wv-page/wv-contracts/wv-contract-edit/wv-contract-edit.component';
import { WvContractCensorshipComponent } from './webview/wv-app/wv-page/wv-contracts/wv-contract-detail/layout/wv-contract-censorship/wv-contract-censorship.component';
import { UsbTokenConfigComponent } from './service-pages/service-console/service-enduser-console/signature-manager/service-signature-manager/usb-token-manager/layout/usb-token-config/usb-token-config.component';
import { CustomerToastComponent } from './utils/toastr/layout/customer-toast.toast';
import { ContractTemplateListComponent } from './service-pages/service-console/service-enduser-console/contract-template-manager/contract-template-list/contract-template-list.component';
import { ContractTemplateAddComponent } from './service-pages/service-console/service-enduser-console/contract-template-manager/contract-template-add/contract-template-add.component';
import { ContractTemplateDetailComponent } from './service-pages/service-console/service-enduser-console/contract-template-manager/contract-template-detail/contract-template-detail.component';
import { ContractTemplateEditComponent } from './service-pages/service-console/service-enduser-console/contract-template-manager/contract-template-edit/contract-template-edit.component';
import { ESignOtpComponent } from './service-pages/service-console/service-enduser-console/contract-manager/service-contract-detail/layout/e-sign-otp/e-sign-otp.component';
import { FillDataContractTemplateComponent } from './service-pages/service-console/service-enduser-console/contract-manager/layout/fill-data-contract-template/fill-data-contract-template.component';
import { DieuKhoanComponent } from './service-pages/service-console/ec/dieu-khoan/dieu-khoan.component';
import { DetectChangeValueDirective } from './utils/directives/detect-change-value/detect-change-value.directive';
import { VerifySignatureComponent } from './service-pages/service-console/service-enduser-console/signature-manager/verify-signature/verify-signature.component';
import { HomeVerifySignatureComponent } from './service-pages/home/layout/home-verify-signature/home-verify-signature.component';
import { GroupPermissionsComponent } from './service-pages/service-console/service-enduser-console/permission-manager/group-permissions/group-permissions.component';
import { ContractFlowAddComponent } from './service-pages/service-console/service-enduser-console/contract-flow-manager/contract-flow-add/contract-flow-add.component';
import { ContractFlowListComponent } from './service-pages/service-console/service-enduser-console/contract-flow-manager/contract-flow-list/contract-flow-list.component';
import { ContractFlowEditComponent } from './service-pages/service-console/service-enduser-console/contract-flow-manager/contract-flow-edit/contract-flow-edit.component';
import { ContractFlowDetailComponent } from './service-pages/service-console/service-enduser-console/contract-flow-manager/contract-flow-detail/contract-flow-detail.component';
import { FlowSettingsComponent } from './service-pages/service-console/service-enduser-console/contract-manager/layout/flow-settings/flow-settings.component';
import { SignAgreementComponent } from './service-pages/service-console/service-enduser-console/contract-manager/sign-agreement/sign-agreement.component';
import { SignAgreementService } from './utils/services/aas-network/sign-agreement/sign-agreement.service';
import { PartnerDiscussModalComponent } from './service-pages/service-console/service-enduser-console/contract-manager/layout/partner-discuss-modal/partner-discuss-modal.component';
import { InternalDiscussModalComponent } from './service-pages/service-console/service-enduser-console/contract-manager/layout/internal-discuss-modal/internal-discuss-modal.component';
import { ContactComponent } from './service-pages/home/contact/contact.component';
import { RequestsServiceComponent } from './service-pages/service-console/service-admin-console/requests-service/requests-service/requests-service.component';
import { RequestsServiceDetailComponent } from './service-pages/service-console/service-admin-console/requests-service/requests-service-detail/requests-service-detail.component';
import { TableSortHeaderComponent } from './layout/extensions/table-sort-header/table-sort-header.component';
import { CatalogServiceListComponent } from './service-pages/service-console/service-admin-console/package-service-manager/catalog-service/catalog-service-list/catalog-service-list.component';
import { CatalogServiceDetailComponent } from './service-pages/service-console/service-admin-console/package-service-manager/catalog-service/catalog-service-detail/catalog-service-detail.component';
import { CatalogServiceEditComponent } from './service-pages/service-console/service-admin-console/package-service-manager/catalog-service/catalog-service-edit/catalog-service-edit.component';
import { CatalogServiceAddComponent } from './service-pages/service-console/service-admin-console/package-service-manager/catalog-service/catalog-service-add/catalog-service-add.component';
import { DualListComponent } from './layout/extensions/dual-list/dual-list.component';
import { AddPackageComponent } from './service-pages/service-console/service-admin-console/package-service-manager/package-manager/add-package/add-package.component';
import { ReportQuantityCustomerComponent } from './service-pages/service-console/service-admin-console/report/report-quantity-customer/report-quantity-customer.component';
import { ReportCustomerDetailComponent } from './service-pages/service-console/service-admin-console/report/report-customer-detail/report-customer-detail.component';
import { IndividualComponent } from './service-pages/service-console/service-admin-console/report/report-quantity-customer/layout/individual/individual.component';
import { OrganizationComponent } from './service-pages/service-console/service-admin-console/report/report-quantity-customer/layout/organization/organization.component';
import { SmartcaManagerComponent } from './service-pages/service-console/service-enduser-console/signature-manager/service-signature-manager/smartca-manager/smartca-manager.component';
import { SmartcaConfigComponent } from './service-pages/service-console/service-enduser-console/signature-manager/service-signature-manager/smartca-manager/layout/smartca-config/smartca-config.component';
import { ProfileManagerComponent } from './service-pages/service-auth/profile-manager/profile-manager/profile-manager.component';
import { ChangePasswordComponent } from './service-pages/service-auth/profile-manager/layout/change-password/change-password.component';

import { AddBusinessCustomerComponent } from './service-pages/service-console/service-admin-console/service-account-manager/add-business-customer/add-business-customer.component';
import { EditBusinessCustomerComponent } from './service-pages/service-console/service-admin-console/service-account-manager/edit-business-customer/edit-business-customer.component';
import { AddConsumerCustomerComponent } from './service-pages/service-console/service-admin-console/service-account-manager/add-consumer-customer/add-consumer-customer.component';
import { EditConsumerCustomerComponent } from './service-pages/service-console/service-admin-console/service-account-manager/edit-consumer-customer/edit-consumer-customer.component';
import { ESignComponent } from './service-pages/service-console/service-enduser-console/contract-manager/layout/e-sign/e-sign.component';
import { HomeV4Component } from './service-pages/home/home-v4/home-v4.component';
import { PricelistComponent } from './service-pages/home/pricelist/pricelist.component';
import { SupportComponent } from './service-pages/home/support/support.component';
import { FeatureComponent } from './service-pages/home/feature/feature.component';
import { RegisterComponent } from './service-pages/home/register/register.component';
import { I18nModule } from './i18n/i18n.module';
import { SelectLanguageComponent } from './layout/extensions/select-language/select-language.component';

import { HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateCacheModule, TranslateCacheSettings, TranslateCacheService } from 'ngx-translate-cache';
import { LoginComponent } from './service-pages/home/login/login.component';
import { VerifyOTPComponent } from './service-pages/home/verify-otp/verify-otp.component';
import { RegisterForBusinessComponent } from './service-pages/service-console/service-enduser-console/user-manual/register-for-business/register-for-business.component';
import { RegisterForConsumerComponent } from './service-pages/service-console/service-enduser-console/user-manual/register-for-consumer/register-for-consumer.component';
import { PostComponent } from './service-pages/home/post/post.component';
import { SmartCaComponent } from './service-pages/service-console/service-enduser-console/contract-manager/layout/smart-ca/smart-ca.component';
import { SignMultipleContractsComponent } from './service-pages/service-console/service-enduser-console/contract-manager/sign-multiple-contracts/sign-multiple-contracts.component';
import { ReviewSignBoxComponent } from './service-pages/service-console/service-enduser-console/contract-manager/sign-multiple-contracts/layout/review-sign-box/review-sign-box.component';
import { SmartCaMultipleComponent } from './service-pages/service-console/service-enduser-console/contract-manager/sign-multiple-contracts/layout/smart-ca-multiple/smart-ca-multiple.component';
import { DocumentInContractComponent } from './service-pages/service-console/service-enduser-console/contract-manager/layout/document-in-contract/document-in-contract.component';
import { ListDocumentComponent } from './service-pages/service-console/service-enduser-console/contract-manager/layout/document-in-contract/layout/list-document/list-document.component';
import { AddDocumentComponent } from './service-pages/service-console/service-enduser-console/contract-manager/layout/document-in-contract/layout/add-document/add-document.component';
import { EditDocumentComponent } from './service-pages/service-console/service-enduser-console/contract-manager/layout/document-in-contract/layout/edit-document/edit-document.component';
import { AssignToEnduserComponent } from './service-pages/service-console/service-admin-console/package-service-manager/package-manager/assign-to-enduser/assign-to-enduser.component';
import { ConfirmAssignComponent } from './service-pages/service-console/service-admin-console/package-service-manager/package-manager/confirm-assign/confirm-assign.component';
import { DetailPackageComponent } from './service-pages/service-console/service-admin-console/package-service-manager/package-manager/detail-package/detail-package.component';
import { ListPackageComponent } from './service-pages/service-console/service-admin-console/package-service-manager/package-manager/list-package/list-package.component';
import { EditPackageComponent } from './service-pages/service-console/service-admin-console/package-service-manager/package-manager/edit-package/edit-package.component';
import { CustomerInfoTabComponent } from './service-pages/service-console/service-admin-console/service-account-manager/service-account-detail/layout/customer-info-tab/customer-info-tab.component';
import { PackageInfoTabComponent } from './service-pages/service-console/service-admin-console/service-account-manager/service-account-detail/layout/package-info-tab/package-info-tab.component';
import { ExpensionHistoryTabComponent } from './service-pages/service-console/service-admin-console/service-account-manager/service-account-detail/layout/expension-history-tab/expension-history-tab.component';
import { BusinessCustomerInfoTabComponent } from './service-pages/service-console/service-admin-console/service-account-manager/service-organization/service-organization-detail/layout/business-customer-info-tab/business-customer-info-tab.component';
import { BusinessPackageInfoTabComponent } from './service-pages/service-console/service-admin-console/service-account-manager/service-organization/service-organization-detail/layout/business-package-info-tab/business-package-info-tab.component';
import { BusinessExpensionHistoryTabComponent } from './service-pages/service-console/service-admin-console/service-account-manager/service-organization/service-organization-detail/layout/business-expension-history-tab/business-expension-history-tab.component';
import { HomeFooterComponent } from './layout/main-home/home-footer/home-footer.component';
import { HomeMainComponent } from './layout/main-home/home-main/home-main.component';
import { HomeHeaderComponent } from './layout/main-home/home-header/home-header.component';
import { RegisterConsumerComponent } from './service-pages/home/register/layout/register-consumer/register-consumer.component';
import { RegisterBusinessComponent } from './service-pages/home/register/layout/register-business/register-business.component';
import { ForgotPasswordComponent } from './service-pages/home/forgot-password/forgot-password.component';

import { ConfigurationInformationComponent } from './service-pages/service-console/service-enduser-console/ekyc-specification/configuration-information/configuration-information.component';
import { IdcardComponent } from './service-pages/service-console/service-enduser-console/ekyc-specification/configuration-information/idcard/idcard.component';
import { PassportComponent } from './service-pages/service-console/service-enduser-console/ekyc-specification/configuration-information/passport/passport.component';
import { CitizenIdWithChipComponent } from './service-pages/service-console/service-enduser-console/ekyc-specification/configuration-information/citizen-id-with-chip/citizen-id-with-chip.component';
import { UpdateConfigurationInformationComponent } from './service-pages/service-console/service-enduser-console/ekyc-specification/update-configuration-information/update-configuration-information.component';
import { UpdateIdcardComponent } from './service-pages/service-console/service-enduser-console/ekyc-specification/update-configuration-information/update-idcard/update-idcard.component';
import { UpdateCitizenidComponent } from './service-pages/service-console/service-enduser-console/ekyc-specification/update-configuration-information/update-citizenid/update-citizenid.component';
import { UpdatePassportComponent } from './service-pages/service-console/service-enduser-console/ekyc-specification/update-configuration-information/update-passport/update-passport.component';
import { EnduserPackageDetailV2Component } from './service-pages/service-console/service-enduser-console/packages-manager/enduser-package-detail-v2/enduser-package-detail-v2.component';
import { AdministrativeDirectoryComponent } from './service-pages/service-console/service-admin-console/administrative-directory/administrative-directory.component';
import { UserManualComponent } from './service-pages/service-console/service-enduser-console/user-manual/user-manual.component';
import { LowercaseValueDirective } from './utils/directives/lowercase-value/lowercase-value.directive';
import { CustomizeInfraConfigComponent } from './service-pages/service-console/service-admin-console/service-account-manager/shared/customize-infra-config/customize-infra-config.component';
import { OtpForgotPasswordComponent } from './service-pages/home/forgot-password/otp-forgot-password/otp-forgot-password.component';
import { RenewPasswordComponent } from './service-pages/home/forgot-password/renew-password/renew-password.component';
import { NosignMultipleComponent } from './service-pages/service-console/service-enduser-console/contract-manager/nosign-multiple/nosign-multiple.component';
import { LoginWithOTPComponent } from './service-pages/home/login-with-otp/login-with-otp.component';
import { VerifyOTPLoginComponent } from './service-pages/home/verify-otp-login/verify-otp-login.component';
import { VersionInContractComponent } from './service-pages/service-console/service-enduser-console/contract-manager/layout/version-in-contract/version-in-contract.component';
import { CommentPopupComponent } from './service-pages/service-console/service-enduser-console/contract-manager/service-contract-detail/layout/comment-popup/comment-popup.component';
import { ListContractNumberConfigComponent } from './service-pages/service-console/service-enduser-console/contract-number-config/list-contract-number-config/list-contract-number-config.component';
import { AddContractNumberConfigComponent } from './service-pages/service-console/service-enduser-console/contract-number-config/add-contract-number-config/add-contract-number-config.component';
import { EditContractNumberConfigComponent } from './service-pages/service-console/service-enduser-console/contract-number-config/edit-contract-number-config/edit-contract-number-config.component';
import { DetailContractNumberConfigComponent } from './service-pages/service-console/service-enduser-console/contract-number-config/detail-contract-number-config/detail-contract-number-config.component';
import { GuideTourComponent } from './layout/extensions/guide-tour/guide-tour.component';
import { ReportQuantityV1Component } from './service-pages/service-console/service-admin-console/report/report-quantity-v1/report-quantity-v1.component';
import { ReportQuantityV1BusinessComponent } from './service-pages/service-console/service-admin-console/report/report-quantity-v1/layout/report-quantity-v1-business/report-quantity-v1-business.component';
import { ReportQuantityV1ConsumerComponent } from './service-pages/service-console/service-admin-console/report/report-quantity-v1/layout/report-quantity-v1-consumer/report-quantity-v1-consumer.component';
import { ReportQuantityV1EnterpriseComponent } from './service-pages/service-console/service-admin-console/report/report-quantity-v1/layout/report-quantity-v1-enterprise/report-quantity-v1-enterprise.component';
import { WvLoginOtpComponent } from './webview/wv-app/wv-page/wv-login-otp/wv-login-otp/wv-login-otp.component';
import { WvVerifyOtpLoginComponent } from './webview/wv-app/wv-page/wv-login-otp/wv-verify-otp-login/wv-verify-otp-login.component';
import { SignMultiDownloadContractComponent } from './service-pages/service-console/service-enduser-console/contract-manager/sign-multi-download-contract/sign-multi-download-contract.component';
import { ListDownloadContractComponent } from './service-pages/service-console/service-enduser-console/contract-manager/list-download-contract/list-download-contract.component';
import { CwvContractComponent } from './service-pages/service-console/service-enduser-console/dashboard/service-dashboard-enduser/layout/cwv-contract/cwv-contract.component';
import { HuongDanSuDungComponent } from './service-pages/service-console/service-enduser-console/dashboard/service-dashboard-enduser/layout/huong-dan-su-dung/huong-dan-su-dung.component';
import { NumberOfPartnersComponent } from './service-pages/service-console/service-enduser-console/dashboard/service-dashboard-enduser/layout/number-of-partners/number-of-partners.component';
import { StatsByStatusComponent } from './service-pages/service-console/service-enduser-console/dashboard/service-dashboard-enduser/layout/stats-by-status/stats-by-status.component';
import { StatsByTimeComponent } from './service-pages/service-console/service-enduser-console/dashboard/service-dashboard-enduser/layout/stats-by-time/stats-by-time.component';
import { UsedCapacityComponent } from './service-pages/service-console/service-enduser-console/dashboard/service-dashboard-enduser/layout/used-capacity/used-capacity.component';
import { ServiceDashboardAdminComponent } from './service-pages/service-console/service-admin-console/dashboard/service-dashboard-admin/service-dashboard-admin.component';
import { CwvContractAdminComponent } from './service-pages/service-console/service-admin-console/dashboard/layout/cwv-contract-admin/cwv-contract-admin.component';
import { NumberOfPartnersAdminComponent } from './service-pages/service-console/service-admin-console/dashboard/layout/number-of-partners-admin/number-of-partners-admin.component';
import { StatsByStatusAdminComponent } from './service-pages/service-console/service-admin-console/dashboard/layout/stats-by-status-admin/stats-by-status-admin.component';
import { StatsByTimeAdminComponent } from './service-pages/service-console/service-admin-console/dashboard/layout/stats-by-time-admin/stats-by-time-admin.component';
import { UsedCapacityAdminComponent } from './service-pages/service-console/service-admin-console/dashboard/layout/used-capacity-admin/used-capacity-admin.component';
import { GoogleChartsModule } from 'angular-google-charts';
import { TrustedPartnerAddComponent } from './service-pages/service-console/service-enduser-console/partner/trusted-partner-manager/trusted-partner-add/trusted-partner-add.component';
import { TrustedConsumerPartnerDetailComponent } from './service-pages/service-console/service-enduser-console/partner/trusted-partner-manager/trusted-consumer-partner-detail/trusted-consumer-partner-detail.component';
import { TrustedPartnerEditComponent } from './service-pages/service-console/service-enduser-console/partner/trusted-partner-manager/trusted-partner-edit/trusted-partner-edit.component';
import { AddBusinessTrustedPartnerComponent } from './service-pages/service-console/service-enduser-console/partner/business-trusted-partner/add-business-trusted-partner/add-business-trusted-partner.component';
import { DetailsBusinessTrustedPartnerComponent } from './service-pages/service-console/service-enduser-console/partner/business-trusted-partner/details-business-trusted-partner/details-business-trusted-partner.component';
import { UpdateBusinessTrustedPartnerComponent } from './service-pages/service-console/service-enduser-console/partner/business-trusted-partner/update-business-trusted-partner/update-business-trusted-partner.component';
import { RouterExtService } from './utils/services/router-ext/router-ext.service';

import { NotificationComponent } from './service-pages/service-auth/profile-manager/layout/notification/notification.component'
import { ChangeEmailSdtComponent } from './service-pages/service-auth/profile-manager/layout/change-email-sdt/change-email-sdt.component';
import { ProfileConsumerV2Component } from './service-pages/service-auth/profile-manager/layout/profile-consumer-v2/profile-consumer-v2.component';
import { ProfileBusinessV2Component } from './service-pages/service-auth/profile-manager/layout/profile-business-v2/profile-business-v2.component';
import { ContractTypeListComponent } from './service-pages/service-console/service-enduser-console/contract-type-management/components/contract-type-list/contract-type-list.component';
import { CreateContractTypeComponent } from './service-pages/service-console/service-enduser-console/contract-type-management/components/create-contract-type/create-contract-type.component';
import { ContractTypeDetailComponent } from './service-pages/service-console/service-enduser-console/contract-type-management/components/contract-type-detail/contract-type-detail.component';
import { EditContractTypeComponent } from './service-pages/service-console/service-enduser-console/contract-type-management/components/edit-contract-type/edit-contract-type.component';
import { WvProfileManagerComponent } from './webview/wv-app/wv-page/wv-profile-manager/wv-profile-manager.component';
import { ExpandedSignatureInformationComponent } from './service-pages/service-console/service-enduser-console/contract-manager/service-contract-detail/layout/expanded-signature-information/expanded-signature-information.component';
import { ModalEkycInformationComponent } from './service-pages/service-console/service-enduser-console/contract-manager/service-contract-detail/layout/modal-ekyc-information/modal-ekyc-information.component';
import { PartyJoinV2Component } from './service-pages/service-console/service-enduser-console/contract-manager/layout/party-join-v2/party-join-v2.component';
import { RequestResignComponent } from './service-pages/service-console/service-enduser-console/contract-manager/service-contract-detail/layout/request-resign/request-resign.component';
import { ProfileViewerComponent } from './service-pages/service-auth/profile-manager/layout/profile-viewer/profile-viewer.component';
import { AdminMenuComponent } from './layout/aas-main/aas-menu-sidebar/admin-menu/admin-menu.component';
import { EnduserMenuComponent } from './layout/aas-main/aas-menu-sidebar/enduser-menu/enduser-menu.component';

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        HeaderComponent,
        FooterComponent,
        MenuSidebarComponent,
        MessagesDropdownMenuComponent,
        ShowWithPermissionsDirective,
        SpinnerComponent,
        NgModalComponent,
        CounterDirective,
        DragDropDirective,
        ShowErrorsComponent,
        MiniSpinnerComponent,
        ServiceProfileManagerComponent,
        ServiceDashboardComponent,
        ProfileConsumerComponent,
        ProfileBusinessComponent,
        ServiceLoginComponent,
        ServiceSignupComponent,
        ServiceSignupBusinessComponent,
        ServiceRequestOtpComponent,
        ServiceForgotPasswordComponent,
        InputPasswordDirective,
        ServiceRenewPasswordComponent,
        ServiceSignupSuccessComponent,
        ServiceRegisterManagerComponent,
        ShowValidateErrorsComponent,
        ServiveVerifyBusinessComponent,
        ServiceSignatureManagerComponent,
        UsbTokenManagerComponent,
        SignServerManagerComponent,
        DigitalSignatureManagerComponent,
        EnterpriseManagerComponent,
        AlertComponent,
        ServiceRedirectVnptcaLoginComponent,
        WaitingComponent,
        ApprovedComponent,
        RejectComponent,
        ServiceSignatureConfigComponent,
        ServiceContractManagerComponent,
        ServiceContractListComponent,
        ApproveComponent,
        RejectEnterpriseComponent,
        ServiceAccountManagerComponent,
        ServiceAccountDetailComponent,
        HomeComponentV3,
        VideoCallComponent,
        LandingMainComponent,
        LandingHeaderComponent,
        LandingFooterComponent,
        Web2webVideocallComponent,
        AasMainComponent,
        AasMenuSidebarComponent,
        AasHeaderComponent,
        AasFooterComponent,
        ServiceAccountEditComponent,
        ServiceOrganizationDetailComponent,
        ServiceOrganizationUpdateComponent,
        ServiceContractDetailComponent,
        ServiceAccountEditComponent,
        ServiceContractComponent,
        TrimValueDirective,
        RequestErrorComponent,
        ServicePackageListComponent,
        ServicePackageDetailComponent,
        CreateServicePakageComponent,
        CopyrightListComponent,
        CopyrightDetailComponent,
        DetailInfoComponent,
        CustomerInfoComponent,
        BusinessInfoComponent,
        ContractInfoComponent,
        UpdateServicePackageComponent,
        ServiceSignupBusinessVipComponent,
        ServiceVerifyBusinessVipComponent,
        StepOneVerifyVipComponent,
        StepTwoVerifyVipComponent,
        StepThreeVerifyVipComponent,
        StepFourVerifyVipComponent,
        ServiceEnterpriseVipManagerComponent,
        EnterpriseVipWaitingComponent,
        EnterpriseVipRejectedComponent,
        EnterpriseVipApprovedComponent,
        ServiceContractTemplateGroupComponent,
        CreateContractApproveComponent,
        DetailContractApproveComponent,
        StepFourContractDetailComponent,
        ServiceEinvoicesListComponent,
        ServiceEnvoicesDetailComponent,
        ServiceEnvoicesCreateComponent,
        AccoutEinvoicesListComponent,
        AccountEinvoicesCreateComponent,
        AccountEnvoicesDetailComponent,
        AccountEcvoicesUpdateComponent,
        ServiceApiManagerComponent,
        CodeblockViewerComponent,
        CodeblockEditorComponent,
        ServiceApiListComponent,
        ServiceApiEditorComponent,
        AutoFocusDirective,
        ServiceApiDetailComponent,
        ServiceSignupBusinessVipStepComponent,
        SignupStepOneComponent,
        SignupStepTwoComponent,
        SignupStepThreeComponent,
        WrongPageComponent,
        EmptyBlockComponent,
        ListCategoryPackageComponent,
        CreateCategoryPackageComponent,
        DetailCategoryPackageComponent,
        UpdateCategoryPackageComponent,
        ServiceSignupV2Component,
        SignupV2StepOneComponent,
        SignupV2StepTwoComponent,
        SignupV2StepThreeComponent,
        SignupConsumerComponent,
        SignupBusinessComponent,
        SignupEnterpriseComponent,
        ServiceProductsManagerComponent,
        EnduserPackagesManagerComponent,
        EnduserPackageDetailComponent,
        NotificationsDropdownMenuComponent,
        DocumentsManagerComponent,
        ServiceDashboardEnduserComponent,
        ConsumerPartnerVerificationComponent,
        PartyPartnerVerificationComponent,
        TrustedPartnerManagerComponent,
        WaitConsumerPartnerComponent,
        ApprovedConsumerPartnerComponent,
        RejectedConsumerPartnerComponent,
        WaitPartyPartnerComponent,
        ApprovedPartyPartnerComponent,
        RejectedPartyPartnerComponent,
        PreviewGpkdComponent,
        TrustedPartnerListComponent,
        TrustedPartnerDetailComponent,
        PreviewModalComponent,
        ScrollTrackerDirective,
        ReportContractComponent,
        ReportDetailContractComponent,
        ReportServiceComponent,
        ReportPackageComponent,
        ApiKeyManagerComponent,
        ApiKeyDetailComponent,
        PartyJoinComponent,
        InputMarkDirective,
        ChatManagerComponent,
        AttachFileComponent,
        ServiceContractListV2Component,
        ServicePackageConversionListComponent,
        OnlyNumberDirective,
        AdminContractManagerComponent,
        AdminContractComponent,
        AdminContractDetailComponent,
        AdminContractListComponent,
        AdminContractPartyjoinComponent,
        PackageNotiBlockComponent,
        PartListComponent,
        PartAddComponent,
        PartEditComponent,
        PartDetailComponent,
        TrackerPhoneNumberDirective,
        EmployeeListComponent,
        EmployeeAddComponent,
        EmployeeEditComponent,
        EmployeeDetailComponent,
        EmployeeFinishSignupComponent,
        EmployeeInPartComponent,
        ServiceLandingPageCategoryComponent,
        ServiceLandingPageBlogComponent,
        ServiceLandingPageConfigComponent,
        ServiceLandingPageBlogTypeComponent,
        ServiceLandingPageBlogAddComponent,
        ServiceLandingPageBlogEditComponent,
        ServiceLandingPageBlogTypeAddComponent,
        ServiceLandingPageBlogTypeEditComponent,
        ServiceLandingPageCategoryAddComponent,
        ServiceLandingPageCategoryEditComponent,
        ServiceLandingPageConfigEditComponent,
        ServiceLandingPageConfigAddComponent,
        ConsumerSignupManagerComponent,
        ConsumerSignupWaitComponent,
        ConsumerSignupApprovedComponent,
        ConsumerSignupRejectedComponent,
        ContractCensorshipComponent,
        AccountBusinessListComponent,
        AccountConsumerListComponent,
        WvAppComponent,
        WvLoginComponent,
        WvContractsManagerComponent,
        WvContractsListComponent,
        WvContractAddComponent,
        WvContractDetailComponent,
        WvContractEditComponent,
        WvContractCensorshipComponent,
        UsbTokenConfigComponent,
        CustomerToastComponent,
        ContractTemplateListComponent,
        ContractTemplateAddComponent,
        ContractTemplateDetailComponent,
        ContractTemplateEditComponent,
        ESignOtpComponent,
        FillDataContractTemplateComponent,
        DieuKhoanComponent,
        DetectChangeValueDirective,
        VerifySignatureComponent,
        VerifySignatureComponent,
        HomeVerifySignatureComponent,
        GroupPermissionsComponent,
        ContractFlowAddComponent,
        ContractFlowListComponent,
        ContractFlowEditComponent,
        ContractFlowDetailComponent,
        FlowSettingsComponent,
        SignAgreementComponent,
        PartnerDiscussModalComponent,
        InternalDiscussModalComponent,
        ContactComponent,
        RequestsServiceComponent,
        RequestsServiceDetailComponent,
        TableSortHeaderComponent,
        CatalogServiceListComponent,
        CatalogServiceDetailComponent,
        CatalogServiceEditComponent,
        CatalogServiceAddComponent,
        DualListComponent,
        AddPackageComponent,
        ReportQuantityCustomerComponent,
        ReportCustomerDetailComponent,
        IndividualComponent,
        OrganizationComponent,
        SmartcaManagerComponent,
        SmartcaConfigComponent,
        ProfileManagerComponent,
        ChangePasswordComponent,
        AddBusinessCustomerComponent,
        EditBusinessCustomerComponent,
        AddConsumerCustomerComponent,
        EditConsumerCustomerComponent,
        ESignComponent,
        HomeV4Component,
        PricelistComponent,
        SupportComponent,
        FeatureComponent,
        RegisterComponent,
        SelectLanguageComponent,
        RegisterForBusinessComponent,
        RegisterForConsumerComponent,
        PostComponent,
        LoginComponent,
        VerifyOTPComponent,
        SmartCaComponent,
        SignMultipleContractsComponent,
        ReviewSignBoxComponent,
        SmartCaMultipleComponent,
        ConfigurationInformationComponent,
        IdcardComponent,
        PassportComponent,
        CitizenIdWithChipComponent,
        UpdateConfigurationInformationComponent,
        UpdateIdcardComponent,
        UpdateCitizenidComponent,
        UpdatePassportComponent,
        DocumentInContractComponent,
        ListDocumentComponent,
        AddDocumentComponent,
        EditDocumentComponent,
        AssignToEnduserComponent,
        ConfirmAssignComponent,
        DetailPackageComponent,
        ListPackageComponent,
        EditPackageComponent,
        EnduserPackageDetailV2Component,
        CustomerInfoTabComponent,
        PackageInfoTabComponent,
        ExpensionHistoryTabComponent,
        BusinessCustomerInfoTabComponent,
        BusinessPackageInfoTabComponent,
        BusinessExpensionHistoryTabComponent,
        AdministrativeDirectoryComponent,
        UserManualComponent,
        LowercaseValueDirective,
        CustomizeInfraConfigComponent,
        HomeHeaderComponent,
        HomeFooterComponent,
        HomeMainComponent,
        RegisterConsumerComponent,
        RegisterBusinessComponent,
        ForgotPasswordComponent,
        OtpForgotPasswordComponent,
        RenewPasswordComponent,
        NosignMultipleComponent,
        LoginWithOTPComponent,
        VerifyOTPLoginComponent,
        VersionInContractComponent,
        CommentPopupComponent,
        ListContractNumberConfigComponent,
        AddContractNumberConfigComponent,
        EditContractNumberConfigComponent,
        DetailContractNumberConfigComponent,
        GuideTourComponent,
        ReportQuantityV1Component,
        ReportQuantityV1BusinessComponent,
        ReportQuantityV1ConsumerComponent,
        ReportQuantityV1EnterpriseComponent,
        WvLoginOtpComponent,
        WvVerifyOtpLoginComponent,
        SignMultiDownloadContractComponent,
        ListDownloadContractComponent,
        CwvContractComponent,
        HuongDanSuDungComponent,
        NumberOfPartnersComponent,
        StatsByStatusComponent,
        StatsByTimeComponent,
        UsedCapacityComponent,
        ServiceDashboardAdminComponent,
        CwvContractAdminComponent,
        NumberOfPartnersAdminComponent,
        StatsByStatusAdminComponent,
        StatsByTimeAdminComponent,
        UsedCapacityAdminComponent,

        TrustedPartnerAddComponent,
        TrustedConsumerPartnerDetailComponent,
        TrustedPartnerEditComponent,
        AddBusinessTrustedPartnerComponent,
        DetailsBusinessTrustedPartnerComponent,
        UpdateBusinessTrustedPartnerComponent,

        NotificationComponent,
        ChangeEmailSdtComponent,
        ProfileConsumerV2Component,
        ProfileBusinessV2Component,
        ContractTypeListComponent,
        CreateContractTypeComponent,
        ContractTypeDetailComponent,
        EditContractTypeComponent,

        ExpandedSignatureInformationComponent,
        ModalEkycInformationComponent,
        PartyJoinV2Component,
        RequestResignComponent,
        ProfileViewerComponent,

        WvProfileManagerComponent,

        AdminMenuComponent,

        EnduserMenuComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        NgbModule,
        ToastNoAnimationModule,
        ToastrModule.forRoot(),
        ToastContainerModule,
        ChartsModule,
        NgbPaginationModule,
        NgSelect2Module,
        CKEditorModule,
        MatCheckboxModule,
        MatTableModule,
        AngularFireDatabaseModule,
        AngularFireAuthModule,
        AngularFireMessagingModule,
        AngularFireModule.initializeApp(environment.firebase),
        CommonModule,
        NgSelectModule,
        GoogleChartsModule,
        // I18nModule
        TranslateModule.forRoot({
          defaultLanguage: 'vi',
          loader: {
            provide: TranslateLoader,
            useFactory: translateLoaderFactory,
            deps: [HttpClient]
          }
        }), // unchanged
        TranslateCacheModule.forRoot({
          cacheService: {
            provide: TranslateCacheService,
            useFactory: translateCacheFactory,
            deps: [TranslateService, TranslateCacheSettings]
          },
          cacheMechanism: 'LocalStorage'
        })
    ],
    entryComponents: [NgModalComponent, CustomerToastComponent],
    providers: [
        AngularFireMessaging,
        AasConsumerManagerService,
        AppService,
        AuthService,
        // CustomerService,
        // TemplateService,
        API,
        // ReportService,
        NgbActiveModal,
        // CatalogService,
        {
            provide: NgbDateParserFormatter,
            useClass: NgbDateFRParserFormatter,
        },
        LoadingService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingInterceptor,
            multi: true,
        },
        AasAuthService,
        CurrencyPipe,
        FirebaseService,
        AsyncPipe,
        AlertControl,
        LandingpageService,
        SignAgreementService
    ],
    bootstrap: [AppComponent],
    exports: [
        SpinnerComponent
    ]
})
export class AppModule {
    constructor(private config: NgSelectConfig,
        translate: TranslateService,
        translateCacheService: TranslateCacheService,
        private routerExtService: RouterExtService) {
        this.config.notFoundText = 'Không tìm thấy kết quả';
        this.config.loadingText = 'Đang tìm...';
        this.config.typeToSearchText = 'Gõ từ khóa để tìm kiếm';
        // set the bindValue to global config when you use the same 
        // bindValue in most of the place. 
        // You can also override bindValue for the specified template 
        // by defining `bindValue` as property
        // Eg : <ng-select bindValue="some-new-value"></ng-select>
        this.config.bindValue = 'value';

        translateCacheService.init();
        translate.addLangs(['en', 'vi']);
        const browserLang = translateCacheService.getCachedLanguage() || "vi";
        translate.use(browserLang.match(/en|vi/) ? browserLang : 'vi');
    }
}

export function translateCacheFactory(
    translateService: TranslateService,
    translateCacheSettings: TranslateCacheSettings
  ) {
    return new TranslateCacheService(translateService, translateCacheSettings);
  }
  
  export function translateLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient);
  }