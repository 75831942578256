export class XaItem {
    xaId: number;
    maXa: string;
    tenXa: string;
    huyenId: number;

    constructor(obj = null) {
        obj = obj || {};
        this.xaId = obj.xaId;
        this.maXa = obj.maXa  || '';
        this.tenXa = obj.tenXa || '';
        this.huyenId = obj.huyenId;
    }
}