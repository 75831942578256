export class ListPartnerConsumer {
    userId: string;
    partyId: string;
    ten: string;
    ngaySinh: string;
    email: string;
    soNha: string;
    duong: string;
    xa: string;
    huyen: string;
    tinh: string;
    donVi: string;
    chucVu: string;
    fullDiaChi: string;
    sdt: string;
    disable: any;
    cmnd: string;
    created: any;
    customerId: any;
    gioiTinh: any;
    ngayCap: any;
    noiCap: any;
    rejectReason: string;
    approveDate: any;
    videocall: any;

    gioiTinhId: number;
    identityLevel: number;
    partnerId: string = '';
    partnerVerificationId: string = '';
    verificationDate: string = '';
    verificationType: string = '';
    verificationStatus: string = '';

    constructor(obj = null) {
        obj = obj || {};
        this.userId = obj.userId || "";
        this.partyId = obj.partyId || "";
        this.ten = obj.ten || "";
        this.ngaySinh = obj.ngaySinh || "";
        this.email = obj.email || "";
        this.soNha = obj.soNha || "";
        this.duong = obj.duong || "";
        this.xa = obj.xa || "";
        this.huyen = obj.huyen || "";
        this.tinh = obj.tinh || "";
        this.donVi = obj.donVi || "";
        this.sdt = obj.sdt || "";
        this.chucVu = obj.chucVu || "";
        this.cmnd = obj.cmnd || "";
        this.created = obj.created || "";
        this.customerId = obj.customerId || "";
        this.ngayCap = obj.ngayCap || "";
        this.noiCap = obj.noiCap || "";
        this.rejectReason = obj.rejectReason || "";
        this.approveDate = obj.approveDate || "";
        this.videocall = obj.videocall || "";

        if (obj.disable === "Y" || obj.disable === true) {
            this.disable = true;
        } else {
            this.disable = false;
        }

        this.fullDiaChi = this.diaChi;

        this.gioiTinhId = obj.gioiTinhId;
        this.identityLevel = obj.identityLevel || '';
        this.partnerId = obj.partnerId || '';
        this.partnerVerificationId = obj.partnerVerificationId || '';
        this.verificationStatus = obj.verificationStatus || '';
        this.verificationDate = obj.verficaitionDate || '';
        this.verificationType = obj.verificationType || '';
    }

    get diaChi() {
        var diaChi = "";
        if (this.soNha !== "") {
            diaChi += this.soNha + " ";
        }

        if (this.duong !== "") {
            diaChi += this.duong + ", ";
        }

        if (this.xa !== "") {
            diaChi += this.xa + ", ";
        }

        if (this.huyen !== "") {
            diaChi += this.huyen + ", ";
        }

        if (this.tinh !== "") {
            diaChi += this.tinh + ".";
        }

        return diaChi;
    }
}