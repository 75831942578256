
  <app-spinner></app-spinner>
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0 text-dark"><label>Danh sách hóa đơn điện tử</label></h1>
        </div>
        <!-- /.col -->
        <div class="col-sm-6" >
            <button class="btn btn-primary float-sm-right d-inline-flex" routerLinkActive="router-link-active" [routerLink]="['/app/console/einvoices/create']"
              ><em class="material-icons">add</em>
              <!-- [routerLink]="['/portal/customers/create']" -->
              Thêm mới</button>
          </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </div>
  <section class="content">
    <div class="container-fluid">
      <div class="bg-white pb-3">
          <section class="p-3">
              <div class="row">
                    <div class="col-lg-3 col-md-5">
                        <form   class="input-group border rounded mb-3">
                        <div class="input-group-prepend border-0">
                            <button class="btn d-inline-flex pr-2 pl-2" type="submit"><span
                                    class="material-icons">search</span></button>
                        </div>
                        <input type="text" class="form-control border-0"
                        placeholder="Nhập hóa đơn, tên khách hàng, MST..."   [formControl]="searchFields"
                            aria-describedby="basic-addon1" #searchFieldEinvoices>
                        </form>
                    </div>
                  <!-- </div> -->
                  <div class="col-md-auto ml-auto">
                      <div  class="btn-group">
                        <button class="btn btn-outline-primary dropdown-toggle d-inline-flex align-items-center mr-3"
                              data-toggle="dropdown" id="configColTable">
                              <span class="material-icons mr-1">visibility</span>
                              Hiển thị
                        </button>
                          <div class="dropdown-menu border-0" aria-labelledby="configColTable" style="width: 190px;">
                              <div *ngFor="let item of listConfigColTable" class="tcheck-primary d-inline-block px-3">
                                  <input type="checkbox" id="{{ item.name }}" [checked]="configColTable[item.name]">
                                  <label for="{{ item.name }}" class="w-100" [class.checked]="configColTable[item.name]"
                                      (click)="actConfigColTableView($event, item.name)">
                                      {{ item.label }}
                                  </label>
                              </div>
                          </div>
                      </div>
                      <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="cleanFilter()"> Đặt lại</button>
                      <button class="btn btn-primary d-inline-flex mr-3" (click)="searchWithFilter()"> Áp dụng</button>
                  </div>
              </div>
              <!-- <hr class="my-2"> -->
            </section>
          <section class="px-3 my-2 row">
            <div class="col-md-2">
              <div class="form-group">
                <label for="">Từ ngày</label>
                <div class="input-group">
                  <input id="tuNgayPhatHanh" #tuNgayPhatHanh name="tuNgayPhatHanh"
                    (input)="dateInput($event, 'tuNgayPhatHanh')" (dateSelect)="changeDate($event, 'from')"
                    class="form-control" placeholder="dd/MM/yyyy" ngbDatepicker #pickDate="ngbDatepicker"
                    >
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary" (click)="pickDate.toggle()" type="button"><em
                        class="fas fa-calendar-alt"></em></button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group">
                <label for="">Đến ngày</label>
                <div class="input-group">
                  <input id="denNgayPhatHanh" #denNgayPhatHanh name="denNgayPhatHanh"
                    (input)="dateInput($event, 'toDate')" (dateSelect)="changeDate($event, 'to')"
                    class="form-control" placeholder="dd/MM/yyyy" ngbDatepicker #pickDate1="ngbDatepicker"
                  >
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary" (click)="pickDate1.toggle()" type="button"><em
                        class="fas fa-calendar-alt"></em>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="col-md-2">
            <label>Trạng thái</label>
            <form [formGroup]= "formFilter">
            <ng-select2 [data]="statusData | async"  formControlName="status"  #statusPicker (valueChanged)="changeStatus($event)"
              [options]="options" [placeholder]="'Nhập trạng thái'">
            </ng-select2>
            </form>
            </div>
          </section>
          <section>
              <div id="table-scroll" class="table table-scroll table-responsive">
                  <table id="main-table" class="table main-table border">
                      <thead>
                          <tr >
                              <th class="table-sticky-col1" scope="col">STT</th>
                              <th scope="col" [class.d-none]="!configColTable.soHoaDon">Số hóa đơn</th>
                              <th scope="col" [class.d-none]="!configColTable.kyHieu">Ký hiệu</th>
                              <th scope="col" [class.d-none]="!configColTable.tenKhachHang">Tên khách hàng</th>
                              <th scope="col" [class.d-none]="!configColTable.maSoThue">Mã số thuế</th>
                              <th scope="col" [class.d-none]="!configColTable.diaChi">Địa chỉ </th>
                              <th scope="col" [class.d-none]="!configColTable.ngayPhatHanh">Ngày phát hành</th>
                              <th scope="col" [class.d-none]="!configColTable.tongTien">Tổng tiền</th>
                              <th scope="col" [class.d-none]="!configColTable.trangThai">Trạng thái</th>
                              <th class="table-sticky-col1" scope="col">Thao tác</th>
                          </tr>
                      </thead>
                      <tbody>
                        <tr  *ngFor="let item of listEinvoices, let i = index">
                              <td >{{ (page - 1)*10 + i + 1  }}</td>
                              <td [class.d-none]="!configColTable.soHoaDon">{{item.soHoaDon}}</td>
                              <td [class.d-none]="!configColTable.kyHieu">{{item.kyHieu}}</td>
                              <td [class.d-none]="!configColTable.tenKhachHang">{{item.tenKhachHang}}</td>
                              <td [class.d-none]="!configColTable.maSoThue">{{item.maSoThue}}</td>
                              <td [class.d-none]="!configColTable.diaChi">{{item.diaChi}}</td>
                              <td [class.d-none]="!configColTable.ngayPhatHanh">{{ item.ngayPhatHanh | date : 'dd/MM/yyy' }}</td>
                              <td [class.d-none]="!configColTable.tongTien">{{ item.tongTien}}</td>
                              <td [class.d-none]="!configColTable.trangThai">
                                <span *ngIf="item.trangThai === 'CREATED'"
                                    class="badge badge-danger-new p-2">Chưa thanh toán
                                </span>
                                <span *ngIf="item.trangThai === 'PAID'"
                                    class="badge badge-success-new p-2">Đã thanh toán
                                </span>
                              </td>
                              <td >
                                <div class="dropdown">
                                    <a class="btn btn-dropdown dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                      <em class="material-icons">more_vert</em>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right " aria-labelledby="dropdownMenuLink">
                                      <a class="dropdown-item nav-material" style="cursor: pointer;"
                                        [routerLink]="['/app/console/einvoices/detail', item.uuid, item.taxCode]" routerLinkActive="router-link-active">
                                        <em class="material-icons">info_outline</em>
                                        Thông tin chi tiết</a>
                                      <a  class="dropdown-item nav-material" style="cursor: pointer;" (click)="updateStatusEinvoices(item)"
                                        [routerLink]="" routerLinkActive="router-link-active">
                                        <em class="material-icons-outlined">autorenew</em>
                                        Cập nhật trạng thái</a>
                                      <a class="dropdown-item dropdown-danger nav-material" style="cursor: pointer;" data-toggle="modal" (click)="downloadFile(item.cusCode, item.fkey)"
                                        data-target="#notiReject" >
                                        <em class="material-icons-outlined">download</em>
                                        Tải hóa đơn</a>
                                      <a class="dropdown-item nav-material" style="cursor: pointer;" (click)="showPopupMail(item.email, item.uuid)"
                                        >
                                        <em class="material-icons-outlined" >mail_outline</em>
                                        Gửi email thông báo</a>
                                    </div>
                                  </div>
                              </td>
                          </tr>
                      </tbody>
                  </table>
              </div>
          </section>
      </div>
      <div class="d-flex justify-content-end mt-3">
          <span *ngIf="totalElement == 0" class="flex-grow-1 ml-1">Không tìm thấy kết quả</span>
          <span *ngIf="totalElement > 0" class="flex-grow-1 ml-1">Hiển thị 1 đến {{listEinvoices.length}} trong tổng số
              {{totalElement}} kết quả</span>
          <div *ngIf="totalElement > maxSize" class="pagination pages aas-pagination">
              <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="false"
                  [pageSize]="maxSize" (pageChange)="loadPage($event)" [boundaryLinks]="true" aria-label="Default pagination">
                  <ng-template ngbPaginationFirst>Đầu</ng-template>
                  <ng-template ngbPaginationLast>Cuối</ng-template>
                  <ng-template ngbPaginationPrevious>&laquo;</ng-template>
                  <ng-template ngbPaginationNext>&raquo;</ng-template>
                  <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
              </ngb-pagination>
          </div>
      </div>
    </div>
  </section>

  <ng-template #sendEmail let-c="close" let-d="dismiss">
    <div class="modal-header border-0 pb-0 mb-0">
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span class="material-icons">
                cancel
            </span>
        </button>
    </div>
    <div class="modal-body py-0">
      <h4 class="modal-title w-100 text-center mb-3" id="modal-basic-title" >Gửi email thông báo</h4>
        <p class="modal-title w-100 text-center mb-3" id="modal-basic-title">Một thông báo thông tin hóa đơn sẽ trả về email của khách hàng. Vui lòng kiểm tra lại thông tin. </p>
        <form [formGroup]="formEmail">
            <div class="form-group">
                <label>Email khách hàng: </label>
                <input formControlName="email" class="form-control" type="text" placeholder="Nhập email khách hàng">
            </div>
        </form>
    </div>
    <div class="modal-footer border-0 d-flex justify-content-center">
      <button type="button" class="btn btn-outline-primary mr-3" (click)="d('Cross click')">Hủy</button>
        <button type="button" class="btn btn-primary" (click)="btnClickResendEmail(email.value)" >Xác nhận</button>
    </div>
</ng-template>