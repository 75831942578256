<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-auto">
                <h1 *ngIf="!apiId" class="m-0 text-dark"><label>Thêm mới thông tin API</label></h1>
                <h1 *ngIf="apiId" class="m-0 text-dark"><label>Cập nhật thông tin API</label></h1>
            </div>
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>

<section class="content">
    <div class="container-fluid pb-3">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-auto align-self-end">
                        <label class="text-lg">Thông tin chi tiết</label>
                    </div>
                    <div class="col-md-auto ml-auto">
                        <button (click)="cancel($event)" class="btn btn-outline-primary d-inline-flex mr-4"><span
                                class="material-icons mr-2">close</span> Hủy</button>
                        <button (click)="save($event)" class="btn btn-primary d-inline-flex"><span
                                class="material-icons mr-2">save</span> Lưu</button>
                    </div>
                </div>

                <form [formGroup]="apiForm">
                    <label class="text-primary">Thông tin Input</label>
                    <hr>
                    <div class="row">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="method">Phương thức <span class="text-danger">*</span>: </label>
                                        <ng-select2 formControlName="method" [data]="methods" [options]="optionsMethod"
                                            [placeholder]="'Chọn phương thức gọi API'">
                                        </ng-select2>
                                        <app-show-validate-errors [isSubmit]="isSubmitApiForm"
                                            [errorMessages]="apiFormMsg.method" [control]="apiForm.controls.method"
                                            [detail]="{ name: 'method' }"></app-show-validate-errors>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label>URL <span class="text-danger">*</span>: </label>
                                        <input formControlName="pathName" class="form-control"
                                            placeholder="Nhập URL của API">
                                        <app-show-validate-errors [isSubmit]="isSubmitApiForm"
                                            [errorMessages]="apiFormMsg.pathName" [control]="apiForm.controls.pathName"
                                            [detail]="{ name: 'pathName' }"></app-show-validate-errors>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label for="method">Chức năng <span class="text-danger">*</span>: </label>
                                        <ng-select2 formControlName="privilegesId" [data]="features"
                                            [options]="optionsFeature" [placeholder]="'Chọn chức năng...'" (valueChanged)="check($event)">
                                        </ng-select2>
                                        <app-show-validate-errors [isSubmit]="isSubmitApiForm"
                                            [errorMessages]="apiFormMsg.privilegesId" [control]="apiForm.controls.privilegesId"
                                            [detail]="{ name: 'privilegesId' }"></app-show-validate-errors>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div class="form-group">
                                <label>Mô tả <span class="text-danger">*</span>: </label>
                                <textarea formControlName="description" class="form-control" style="height: 109px;"></textarea>
                                <app-show-validate-errors [isSubmit]="isSubmitApiForm"
                                    [errorMessages]="apiFormMsg.description" [control]="apiForm.controls.description"
                                    [detail]="{ name: 'description' }"></app-show-validate-errors>
                            </div>
                        </div>
                    </div>
                </form>

                <label>Header:</label>
                <div class="mb-3">
                    <table #tableParamsHeader class="table table-striped table-bordered ">
                        <thead>
                            <tr>
                                <th>STT</th>
                                <th>Tham số</th>
                                <th>Mô tả</th>
                                <th>Thao tác</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of paramsHeader; let i = index">
                                <td>
                                    {{ i + 1 }}
                                </td>
                                <td (click)="changeEditMode('header', i, true, 'editParamName')">
                                    <span *ngIf="!item.addition.editParamName">{{ item.paramName }}</span>
                                    <input *ngIf="item.addition.editParamName" class="form-control"
                                        [(ngModel)]="item.paramName"
                                        (blur)="changeEditMode('header', i, false, 'editParamName')" appAutoFocus>
                                </td>
                                <td (click)="changeEditMode('header', i, true, 'editDescription')">
                                    <span *ngIf="!item.addition.editDescription">{{ item.description }}</span>
                                    <input *ngIf="item.addition.editDescription" class="form-control"
                                        [(ngModel)]="item.description"
                                        (blur)="changeEditMode('header', i, false, 'editDescription')" appAutoFocus>
                                </td>
                                <td><a class="text-primary text-decoration-underline cursor-pointer"
                                        (click)="removeRowTable('header', i)">Xóa</a></td>
                            </tr>
                        </tbody>
                    </table>
                    <a class="text-primary text-decoration-underline cursor-pointer"
                        (click)="addRowTable('header')">Thêm tham số</a>
                </div>

                <div class="request-body mt-5">
                    <label class="text-primary">Thông tin body</label>
                    <hr>
                    <label>Request body:</label>
                    <app-codeblock-editor [method]="'JSON'" [type]="'javascript'" [code]="apiForm.value.exampleBody" (onGetData)="getDataExampleBody($event)"></app-codeblock-editor>
                    <div class="my-3">
                        <table class="table table-striped table-bordered ">
                            <thead>
                                <tr>
                                    <th>STT</th>
                                    <th>Tham số</th>
                                    <th>Kiểu dữ liệu</th>
                                    <th>Bắt buộc</th>
                                    <th>Mô tả</th>
                                    <th>Thao tác</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of paramsBody; let i = index">
                                    <td>
                                        {{ i + 1 }}
                                    </td>
                                    <td (click)="changeEditMode('body', i, true, 'editParamName')">
                                        <span *ngIf="!item.addition.editParamName">{{ item.paramName }}</span>
                                        <input *ngIf="item.addition.editParamName" class="form-control"
                                            [(ngModel)]="item.paramName"
                                            (blur)="changeEditMode('body', i, false, 'editParamName')" appAutoFocus>
                                    </td>
                                    <td (click)="changeEditMode('body', i, true, 'editType')">
                                        <span *ngIf="!item.addition.editType">{{ item.type }}</span>
                                        <input *ngIf="item.addition.editType" class="form-control"
                                            [(ngModel)]="item.type"
                                            (blur)="changeEditMode('body', i, false, 'editType')" appAutoFocus>
                                    </td>
                                    <td (click)="changeEditMode('body', i, true, 'editObligatory')">
                                        <span *ngIf="!item.addition.editObligatory">{{ item.obligatory === 'true' ? 'Có' : 'Không' }}</span>
                                        <!-- <ng-select2 appAutoFocus *ngIf="item.addition.editObligatory"
                                            [(ngModel)]="item.obligatory" [ngModelOptions]="{ standalone: true }"
                                            [data]="obligatories" [options]="optionsObligatory"
                                            [placeholder]="'Chọn phương thức gọi API'"
                                            (blur)="changeEditMode('body', i, false, 'editObligatory')">
                                        </ng-select2> -->
                                        <select appAutoFocus *ngIf="item.addition.editObligatory" [(ngModel)]="item.obligatory" class="form-control" (blur)="changeEditMode('body', i, false, 'editObligatory')">
                                            <option *ngFor="let obligatory of obligatories" value="{{ obligatory.id }}">{{ obligatory.text }}</option>
                                        </select>
                                    </td>
                                    <td (click)="changeEditMode('body', i, true, 'editDescription')">
                                        <span *ngIf="!item.addition.editDescription">{{ item.description }}</span>
                                        <input *ngIf="item.addition.editDescription" class="form-control"
                                            [(ngModel)]="item.description"
                                            (blur)="changeEditMode('body', i, false, 'editDescription')" appAutoFocus>
                                    </td>
                                    <td><a class="text-primary text-decoration-underline cursor-pointer"
                                            (click)="removeRowTable('body', i)">Xóa</a></td>
                                </tr>
                            </tbody>
                        </table>
                        <a class="text-primary text-decoration-underline" (click)="addRowTable('body')">Thêm tham số</a>
                    </div>
                    <label>Request Param <span class="text-danger">*</span>:</label>
                    <app-show-validate-errors [isSubmit]="isSubmitApiForm"
                        [errorMessages]="apiFormMsg.exampleParam" [control]="apiForm.controls.exampleParam"
                        [detail]="{ name: 'exampleParam' }"></app-show-validate-errors>
                    <app-codeblock-editor [method]="'JSON'" [type]="'javascript'" [code]="apiForm.value.exampleParam" (onGetData)="getDataExampleParam($event)"></app-codeblock-editor>
                    <div class="my-3">
                        <table class="table table-striped table-bordered ">
                            <thead>
                                <tr>
                                    <th>STT</th>
                                    <th>Tham số</th>
                                    <th>Kiểu dữ liệu</th>
                                    <th>Bắt buộc</th>
                                    <th>Mô tả</th>
                                    <th>Thao tác</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of paramsParam; let i = index">
                                    <td>
                                        {{ i + 1 }}
                                    </td>
                                    <td (click)="changeEditMode('param', i, true, 'editParamName')">
                                        <span *ngIf="!item.addition.editParamName">{{ item.paramName }}</span>
                                        <input *ngIf="item.addition.editParamName" class="form-control"
                                            [(ngModel)]="item.paramName"
                                            (blur)="changeEditMode('param', i, false, 'editParamName')" appAutoFocus>
                                    </td>
                                    <td (click)="changeEditMode('param', i, true, 'editType')">
                                        <span *ngIf="!item.addition.editType">{{ item.type }}</span>
                                        <input *ngIf="item.addition.editType" class="form-control"
                                            [(ngModel)]="item.type"
                                            (blur)="changeEditMode('param', i, false, 'editType')" appAutoFocus>
                                    </td>
                                    <td (click)="changeEditMode('param', i, true, 'editObligatory')">
                                        <span *ngIf="!item.addition.editObligatory">{{ item.obligatory === 'true' ? 'Có' : 'Không' }}</span>
                                        <!-- <ng-select2 appAutoFocus *ngIf="item.addition.editObligatory"
                                            [(ngModel)]="item.obligatory" [ngModelOptions]="{ standalone: true }"
                                            [data]="obligatories" [options]="optionsObligatory"
                                            [placeholder]="'Chọn phương thức gọi API'"
                                            (blur)="changeEditMode('param', i, false, 'editObligatory')">
                                        </ng-select2> -->
                                        <select appAutoFocus *ngIf="item.addition.editObligatory" [(ngModel)]="item.obligatory" class="form-control" (blur)="changeEditMode('param', i, false, 'editObligatory')">
                                            <option *ngFor="let obligatory of obligatories" value="{{ obligatory.id }}">{{ obligatory.text }}</option>
                                        </select>
                                    </td>
                                    <td (click)="changeEditMode('param', i, true, 'editDescription')">
                                        <span *ngIf="!item.addition.editDescription">{{ item.description }}</span>
                                        <input *ngIf="item.addition.editDescription" class="form-control"
                                            [(ngModel)]="item.description"
                                            (blur)="changeEditMode('param', i, false, 'editDescription')" appAutoFocus>
                                    </td>
                                    <td><a class="text-primary text-decoration-underline cursor-pointer"
                                            (click)="removeRowTable('param', i)">Xóa</a></td>
                                </tr>
                            </tbody>
                        </table>
                        <a class="text-primary text-decoration-underline" (click)="addRowTable('param')">Thêm tham số</a>
                    </div>
                </div>

                <div class="request-response mt-5">
                    <label class="text-primary">Thông tin output</label>
                    <hr>
                    <label>Response <span class="text-danger">*</span>:</label>
                    <app-show-validate-errors [isSubmit]="isSubmitApiForm"
                        [errorMessages]="apiFormMsg.exampleOutput" [control]="apiForm.controls.exampleOutput"
                        [detail]="{ name: 'exampleOutput' }"></app-show-validate-errors>
                    <app-codeblock-editor [method]="'JSON'" [type]="'javascript'" [code]="apiForm.value.exampleOutput" (onGetData)="getDataExampleOutput($event)"></app-codeblock-editor>
                    <div class="my-3">
                        <table class="table table-striped table-bordered ">
                            <thead>
                                <tr>
                                    <th>STT</th>
                                    <th>Tham số</th>
                                    <th>Kiểu dữ liệu</th>
                                    <th>Bắt buộc</th>
                                    <th>Mô tả</th>
                                    <th>Thao tác</th>
                                </tr>
                            </thead>
                            <tbody>
                            <tbody>
                                <tr *ngFor="let item of paramsOutputSuccess; let i = index">
                                    <td>
                                        {{ i + 1 }}
                                    </td>
                                    <td (click)="changeEditMode('outputSuccess', i, true, 'editParamName')">
                                        <span *ngIf="!item.addition.editParamName">{{ item.paramName }}</span>
                                        <input *ngIf="item.addition.editParamName" class="form-control"
                                            [(ngModel)]="item.paramName"
                                            (blur)="changeEditMode('outputSuccess', i, false, 'editParamName')"
                                            appAutoFocus>
                                    </td>
                                    <td (click)="changeEditMode('outputSuccess', i, true, 'editType')">
                                        <span *ngIf="!item.addition.editType">{{ item.type }}</span>
                                        <input *ngIf="item.addition.editType" class="form-control"
                                            [(ngModel)]="item.type"
                                            (blur)="changeEditMode('outputSuccess', i, false, 'editType')" appAutoFocus>
                                    </td>
                                    <td (click)="changeEditMode('outputSuccess', i, true, 'editObligatory')">
                                        <span *ngIf="!item.addition.editObligatory">{{ item.obligatory === 'true' ? 'Có' : 'Không' }}</span>
                                        <!-- <ng-select2 appAutoFocus *ngIf="item.addition.editObligatory"
                                            [(ngModel)]="item.obligatory" [ngModelOptions]="{ standalone: true }"
                                            [data]="obligatories" [options]="optionsObligatory"
                                            [placeholder]="'Chọn phương thức gọi API'"
                                            (blur)="changeEditMode('outputSuccess', i, false, 'editObligatory')">
                                        </ng-select2> -->
                                        <select appAutoFocus *ngIf="item.addition.editObligatory" [(ngModel)]="item.obligatory" class="form-control" (blur)="changeEditMode('outputSuccess', i, false, 'editObligatory')">
                                            <option *ngFor="let obligatory of obligatories" value="{{ obligatory.id }}">{{ obligatory.text }}</option>
                                        </select>
                                    </td>
                                    <td (click)="changeEditMode('outputSuccess', i, true, 'editDescription')">
                                        <span *ngIf="!item.addition.editDescription">{{ item.description }}</span>
                                        <input *ngIf="item.addition.editDescription" class="form-control"
                                            [(ngModel)]="item.description"
                                            (blur)="changeEditMode('outputSuccess', i, false, 'editDescription')"
                                            appAutoFocus>
                                    </td>
                                    <td><a class="text-primary text-decoration-underline cursor-pointer"
                                            (click)="removeRowTable('outputSuccess', i)">Xóa</a></td>
                                </tr>
                            </tbody>
                        </table>
                        <a class="text-primary text-decoration-underline" (click)="addRowTable('outputSuccess')">Thêm
                            tham số</a>
                    </div>

                    <label>Mã lỗi <span class="text-danger">*</span>:</label>
                    <app-show-validate-errors [isSubmit]="isSubmitApiForm"
                        [errorMessages]="apiFormMsg.paramError" [control]="apiForm.controls.paramError"
                        [detail]="{ name: 'paramError' }"></app-show-validate-errors>
                    <div class="my-3">
                        <table class="table table-striped table-bordered ">
                            <thead>
                                <tr>
                                    <th>STT</th>
                                    <th>Mã lỗi</th>
                                    <th>Mô tả</th>
                                    <th>Thao tác</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item of paramsOutputError; let i = index">
                                    <td>
                                        {{ i + 1 }}
                                    </td>
                                    <td (click)="changeEditMode('outputError', i, true, 'editParamName')">
                                        <span *ngIf="!item.addition.editParamName">{{ item.paramName }}</span>
                                        <input *ngIf="item.addition.editParamName" class="form-control"
                                            [(ngModel)]="item.paramName"
                                            (blur)="changeEditMode('outputError', i, false, 'editParamName')"
                                            appAutoFocus>
                                    </td>
                                    <td (click)="changeEditMode('outputError', i, true, 'editDescription')">
                                        <span *ngIf="!item.addition.editDescription">{{ item.description }}</span>
                                        <input *ngIf="item.addition.editDescription" class="form-control"
                                            [(ngModel)]="item.description"
                                            (blur)="changeEditMode('outputError', i, false, 'editDescription')"
                                            appAutoFocus>
                                    </td>
                                    <td><a class="text-primary text-decoration-underline cursor-pointer"
                                            (click)="removeRowTable('outputError', i)">Xóa</a></td>
                                </tr>
                            </tbody>
                        </table>
                        <a class="text-primary text-decoration-underline cursor-pointer"
                            (click)="addRowTable('outputError')">Thêm tham số</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>