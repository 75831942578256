<app-spinner></app-spinner>
<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-auto">
                <h1 class="m-0 text-dark"><label>{{ 'ContractTemplateList_title' | translate }}</label></h1>
            </div>
            <!-- /.col -->
            <div *showWithRoles="['ECT2301']" class="col-md-auto ml-auto">
                <button (click)="addTemplate()" class="btn btn-primary d-inline-flex mr-3"><span
                        class="material-icons mr-1">add</span> {{ 'btn_addnew' | translate }}</button>
            </div>
            <!-- <!-- /.col -->
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>

<!--MARK top bar -->
<section class="content">
    <div class="container-fluid">
        <div class="bg-white">
            <section class="p-3">
                <div class="row">
                    <div class="col-lg-3 col-md-5">
                        <div class="input-group border rounded mb-3">
                            <div class="input-group-prepend border-0">
                                <button class="btn d-inline-flex pr-2 pl-2" type="submit"><span
                                        class="material-icons">search</span></button>
                            </div>
                            <input (keyup.enter)="enterSearch()" type="text" class="form-control border-0"
                                [(ngModel)]="keySearch" placeholder="{{ 'ContractTemplateList_ph_search' | translate }}..." aria-label=""
                                aria-describedby="basic-addon1">
                        </div>
                    </div>
                    <div class="col-md-auto ml-auto">
                        <div class="btn-group">
                            <div ngbDropdown container="body" [autoClose]="'outside'" [placement]="'bottom-right'"
                                [display]="'dynamic'">
                                <button ngbDropdownToggle
                                    class="btn btn-outline-primary dropdown-toggle d-inline-flex align-items-center mr-3">
                                    <span class="material-icons mr-1">visibility</span>{{ 'ContractTemplateList_visibility' | translate }}
                                </button>
                                <div ngbDropdownMenu>
                                    <div ngbDropdownItem *ngFor="let item of listConfigColTable" class="p-0">
                                        <div class="tcheck-primary px-3">
                                            <input type="checkbox" id="{{ item.name }}"
                                                [checked]="configColTable[item.name]">
                                            <label for="{{ item.name }}" class="w-100"
                                                [class.checked]="configColTable[item.name]"
                                                (click)="actConfigColTableView($event, item.name)">
                                                {{ item.label | translate }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <hr class="my-2">
            <section class="px-3 my-2">
                <form [formGroup]="formFilter">
                    <div class="row">
                        <div class="col-12">
                            <div class="row">
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label for="status">{{ 'status' | translate }}</label>
                                        <ng-select labelForId="status" formControlName="status" [items]="statusList"
                                            bindValue="value" bindLabel="label" [searchable]="false" [clearable]="false"
                                            placeholder="{{ 'ContractTemplateList_select_status' | translate }}">
                                            <ng-template ng-label-tmp let-item="item">
                                                <span>{{ item.label | translate }}</span>
                                            </ng-template>
                                            <ng-template ng-option-tmp let-item="item" >
                                                <span>{{ item.label | translate }}</span>
                                            </ng-template>
                                        </ng-select>
                                        
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label for="status">{{ 'ContractTemplateList_initDate' | translate }}</label>
                                        <div class="input-group">
                                            <input #fromDatePicker class="form-control"
                                                [control]="formFilter.controls.createdDate"
                                                [value]="formFilter.value.createdDate"
                                                (dateSelect)="changeDateFilter($event)" placeholder="dd/MM/yyyy"
                                                ngbDatepicker #createdFromDate="ngbDatepicker"
                                                [maxDate]="{year: currentDate.getFullYear(), month: currentDate.getMonth()+1, day: currentDate.getDate()}"
                                                appInputMark [options]="optionsCleave">
                                            <div class="input-group-append">
                                                <button class="btn btn-outline-secondary"
                                                    (click)="createdFromDate.toggle()" type="button"><em
                                                        class="fas fa-calendar-alt"></em></button>
                                            </div>
                                            <app-show-validate-errors [useSubmit]="false"
                                                [errorMessages]="validateSearch.createdDate"
                                                [control]="formFilter.controls.createdDate"
                                                [detail]="{ name: 'createdDate' }">
                                            </app-show-validate-errors>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label for="status">{{ 'ContractTemplateList_creator' | translate }}</label>
                                        <ng-select formControlName="userId" [items]="dropListUserData | async"
                                            bindValue="userId" bindLabel="fullName" placeholder="{{ 'ContractTemplateEdit_ph_select_1' | translate }}"
                                            [trackByFn]="trackByFn1" [loading]="usersLoading"
                                            [typeahead]="employeesInput$" (change)="changeUser($event)"
                                            (scrollToEnd)="onScrollToEnd()">
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="align-self-end mb-3 ml-auto">
                                    <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="cleanFilter()">
                                        {{ 'btn_reset' | translate }}</button>
                                    <button class="btn btn-primary d-inline-flex mr-3" (click)="searchWithFilter()">{{ 'btn_apply' | translate }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </section>

            <section class="card-body p-0">
                <div class="table-responsive">
                    <table class="table mb-0">
                        <thead>
                            <tr class="bg-tb-header border-top text-left">
                                <th>{{ 'ContractTemplateList_stt' | translate }}</th>
                                <th *ngIf="configColTable['templateName']" class="cursor-pointer"
                                    (click)="sortCol('templateName')">
                                    <span class="d-flex align-self-center align-items-center">
                                        {{ 'ContractTemplateList_templateName' | translate }}
                                        <span *ngIf="this.sortColConfig['templateName'] == null"
                                            class="material-icons ml-auto">
                                            unfold_more
                                        </span>
                                        <span *ngIf="this.sortColConfig['templateName'] == true"
                                            class="material-icons ml-auto">
                                            expand_less
                                        </span>
                                        <span *ngIf="this.sortColConfig['templateName'] == false"
                                            class="material-icons ml-auto">
                                            expand_more
                                        </span>
                                    </span>
                                </th>
                                <th *ngIf="configColTable['templateType']" class="cursor-pointer"
                                    (click)="sortCol('templateType')">
                                    <span class="d-flex align-self-center align-items-center">
                                        {{ 'ContractTemplateList_templateType' | translate }}
                                        <span *ngIf="this.sortColConfig['templateType'] == null"
                                            class="material-icons ml-auto">
                                            unfold_more
                                        </span>
                                        <span *ngIf="this.sortColConfig['templateType'] == true"
                                            class="material-icons ml-auto">
                                            expand_less
                                        </span>
                                        <span *ngIf="this.sortColConfig['templateType'] == false"
                                            class="material-icons ml-auto">
                                            expand_more
                                        </span>
                                    </span>
                                </th>
                                <th *ngIf="configColTable['id']" class="cursor-pointer" (click)="sortCol('id')"
                                    style="width: 270px;">
                                    <span class="d-flex align-self-center align-items-center">
                                        {{ 'ContractTemplateList_templateId' | translate }}
                                        <span *ngIf="this.sortColConfig['id'] == null" class="material-icons ml-auto">
                                            unfold_more
                                        </span>
                                        <span *ngIf="this.sortColConfig['id'] == true" class="material-icons ml-auto">
                                            expand_less
                                        </span>
                                        <span *ngIf="this.sortColConfig['id'] == false" class="material-icons ml-auto">
                                            expand_more
                                        </span>
                                    </span>
                                </th>
                                <th *ngIf="configColTable['fileName']" class="cursor-pointer"
                                    (click)="sortCol('fileName')">
                                    <span class="d-flex align-self-center align-items-center">
                                        {{ 'ContractTemplateList_fileName' | translate }}
                                        <span *ngIf="this.sortColConfig['fileName'] == null"
                                            class="material-icons ml-auto">
                                            unfold_more
                                        </span>
                                        <span *ngIf="this.sortColConfig['fileName'] == true"
                                            class="material-icons ml-auto">
                                            expand_less
                                        </span>
                                        <span *ngIf="this.sortColConfig['fileName'] == false"
                                            class="material-icons ml-auto">
                                            expand_more
                                        </span>
                                    </span>
                                </th>
                                <th *ngIf="configColTable['fullName']" class="cursor-pointer"
                                    (click)="sortCol('fullName')">
                                    <span class="d-flex align-self-center align-items-center">
                                        {{ 'ContractTemplateList_creator' | translate }}
                                        <span *ngIf="this.sortColConfig['fullName'] == null"
                                            class="material-icons ml-auto">
                                            unfold_more
                                        </span>
                                        <span *ngIf="this.sortColConfig['fullName'] == true"
                                            class="material-icons ml-auto">
                                            expand_less
                                        </span>
                                        <span *ngIf="this.sortColConfig['fullName'] == false"
                                            class="material-icons ml-auto">
                                            expand_more
                                        </span>
                                    </span>
                                </th>
                                <th *ngIf="configColTable['dateCreate']" class="cursor-pointer"
                                    (click)="sortCol('dateCreate')">
                                    <span class="d-flex align-self-center align-items-center">
                                        {{ 'ContractTemplateList_initDate' | translate }}
                                        <span *ngIf="this.sortColConfig['dateCreate'] == null"
                                            class="material-icons ml-auto">
                                            unfold_more
                                        </span>
                                        <span *ngIf="this.sortColConfig['dateCreate'] == true"
                                            class="material-icons ml-auto">
                                            expand_less
                                        </span>
                                        <span *ngIf="this.sortColConfig['dateCreate'] == false"
                                            class="material-icons ml-auto">
                                            expand_more
                                        </span>
                                    </span>
                                </th>
                                <th *ngIf="configColTable['status']" class="cursor-pointer" (click)="sortCol('status')">
                                    <span class="d-flex align-self-center align-items-center">
                                        {{ 'status' | translate }}
                                        <span *ngIf="this.sortColConfig['status'] == null"
                                            class="material-icons ml-auto">
                                            unfold_more
                                        </span>
                                        <span *ngIf="this.sortColConfig['status'] == true"
                                            class="material-icons ml-auto">
                                            expand_less
                                        </span>
                                        <span *ngIf="this.sortColConfig['status'] == false"
                                            class="material-icons ml-auto">
                                            expand_more
                                        </span>
                                    </span>
                                <th>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of templates; let i = index">
                                <td>{{ (page - 1) * maxSize + i + 1 }}</td>
                                <td *ngIf="configColTable['templateName']">{{ item.templateName }}</td>
                                <td *ngIf="configColTable['templateType']">{{ item.templateType }}</td>
                                <td *ngIf="configColTable['id']" class="d-flex align-items-center">
                                    {{ item.id }}
                                    <span class="info-note d-flex ml-auto">
                                        <span class="material-icons bg-copy-icon p-2" style="cursor: pointer;"
                                            (click)="copyClipboard(item.id)">
                                            content_copy
                                        </span>
                                        <span class="info-tooltip-desc bg-hidden-noti">
                                            {{ 'ContractTemplateList_text_coppyId' | translate }}
                                        </span>
                                    </span>
                                </td>
                                <td *ngIf="configColTable['fileName']">{{ item.fileName }}</td>
                                <td *ngIf="configColTable['fullName']">{{ item.fullName }}</td>
                                <td *ngIf="configColTable['dateCreate']">{{ item.dateCreate | date: 'dd/MM/yyyy' }}</td>
                                <td *ngIf="configColTable['status']">
                                    <span *ngIf="item.status === 'ACTIVE'" class="badge badge-success-new p-2">{{ 'ContractTemplateList_status_active' | translate }}</span>
                                    <span *ngIf="item.status === 'DEACTIVE'" class="badge badge-danger-new p-2">{{ 'ContractTemplateList_status_nonActive' | translate }}</span>
                                </td>
                                <td class="text-center">
                                    <div ngbDropdown container="body">
                                        <span class="material-icons cursor-pointer" ngbDropdownToggle
                                            id="settingTable">more_vert</span>
                                        <div ngbDropdownMenu class="border-0">
                                            <a ngbDropdownItem
                                                class="dropdown-item align-items-center cursor-pointer d-flex"
                                                (click)="gotoDetail(item.id)">
                                                <span class="material-icons text-icon-dropdown-size mr-2">info</span>
                                                {{ 'ContractTemplateList_details' | translate }}</a>

                                            <a ngbDropdownItem *ngIf="controlAcc.checkRoles(['ECT2301'])"
                                                class="dropdown-item align-items-center cursor-pointer d-flex"
                                                (click)="gotoEdit(item.id)"><span
                                                    class="material-icons text-icon-dropdown-size mr-2">edit</span>{{ 'ContractTemplateList_edit' | translate }}</a>
                                            <a ngbDropdownItem *ngIf="controlAcc.checkRoles(['ECT2301'])"
                                                class="dropdown-item align-items-center cursor-pointer d-flex"
                                                (click)="deleteTemplate(item)"><span
                                                    class="material-icons text-icon-dropdown-size mr-2">delete</span>{{ 'ContractTemplateList_remove' | translate }}</a>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="bg-tb-header p-3">
                    <div class="row align-items-center">
                        <span *ngIf="totalElement == 0" class="col-md">{{ 'text_no_results' | translate }}</span>
                        <span *ngIf="totalElement > 0" class="col-md">{{ 'text_number_of_results_displayed' | translate: {from: 1, to: templates.length, total: totalElement} }}</span>
                        <div class="col-md">
                            <div class="d-flex justify-content-end">
                                <div *ngIf="totalElement > maxSize"
                                    class="d-flex justify-content-end align-items-center">
                                    <span class="mr-2">{{ 'text_number_of_lines' | translate }}:</span>
                                    <ng-select [(ngModel)]="maxSize" [searchable]="false" [clearable]="false"
                                        placeholder="..." (change)="loadPage(1)">
                                        <ng-option [value]="10">10</ng-option>
                                        <ng-option [value]="20">20</ng-option>
                                        <ng-option [value]="30">30</ng-option>
                                    </ng-select>
                                </div>
                                <div *ngIf="totalElement > maxSize" class="pagination pages no-border-pagination ml-2">
                                    <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="5"
                                        [rotate]="true" [ellipses]="false" [pageSize]="maxSize"
                                        (pageChange)="loadPage($event)" [boundaryLinks]="true"
                                        aria-label="Default pagination">
                                        <ng-template ngbPaginationFirst>{{ 'text_first' | translate }}</ng-template>
                                        <ng-template ngbPaginationLast>{{ 'text_last' | translate }}</ng-template>
                                        <ng-template ngbPaginationPrevious>&laquo;</ng-template>
                                        <ng-template ngbPaginationNext>&raquo;</ng-template>
                                        <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                                    </ngb-pagination>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    </div>
</section>