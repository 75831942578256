<div *ngIf="screen === screenType.ENDUSER_DASHBOARD" class="row">
    <div *ngIf="packageSessionService.isTrial; else noTrial" class="col-12">
        <div *ngIf="isValid" class="ect-alert ect-alert-warning" role="alert">
            <span>
                {{ 'PackageNotiBlock_01' | translate: {time: packageSessionService.timeExpired} }} 
                <a class="btn-update-package ml-3" [routerLink]="[constUrl.ENDUSER_PACKAGE_MANAGER]" routerLinkActive="routelink-active" >{{ 'PackageNotiBlock_02' | translate }}</a>
            </span>
            
            <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="dismiss()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div *ngIf="isNotValid" class="ect-alert ect-alert-warning" role="alert">
            <span>
                {{ 'PackageNotiBlock_03' | translate }} 
                <a class="btn-update-package ml-3" [routerLink]="[constUrl.ENDUSER_PACKAGE_MANAGER]" routerLinkActive="router-link-active" >{{ 'PackageNotiBlock_02' | translate }}</a>
            </span>
           
            <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="dismiss()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    
    <ng-template #noTrial>
        <div class="col-12">
            <div *ngIf="isValidAndWarning" class="ect-alert ect-alert-warning" role="alert">
                <span>
                    {{ 'PackageNotiBlock_04' | translate: {packageName: packageSessionService.getPackageName(), time: packageSessionService.timeExpired} }} 
                    <a class="btn-update-package ml-3" [routerLink]="[constUrl.ENDUSER_PACKAGE_MANAGER]" routerLinkActive="router-link-active" >{{ 'PackageNotiBlock_05' | translate }}</a>
                </span>
            </div>
            <div *ngIf="isNotValid" class="ect-alert ect-alert-warning" role="alert">
                <span>
                    {{ 'PackageNotiBlock_06' | translate: {packageName: packageSessionService.getPackageName()} }} 
                    <a class="btn-update-package ml-3" [routerLink]="[constUrl.ENDUSER_PACKAGE_MANAGER]" routerLinkActive="router-link-active" >{{ 'PackageNotiBlock_05' | translate }}</a>
                </span>
            </div>
        </div>
    </ng-template>
</div>

<div *ngIf="screen === screenType.ENDUSER_PACKAGE_MANAGER" class="row">
    <div *ngIf="packageSessionService.isTrial; else noTrial" class="col-12">
        <div *ngIf="isValid" class="ect-alert ect-alert-warning" role="alert">
            <span>
                {{ 'PackageNotiBlock_01' | translate: {time: packageSessionService.timeExpired} }} 
                <a class="btn-update-package ml-3" [routerLink]="[constUrl.ENDUSER_PACKAGE_MANAGER]" routerLinkActive="router-link-active" >{{ 'PackageNotiBlock_02' | translate }}</a>
            </span>
            
            <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="dismiss()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div *ngIf="isNotValid" class="ect-alert ect-alert-warning" role="alert">
            <span>
                {{ 'PackageNotiBlock_03' | translate }} 
                <a class="btn-update-package ml-3" [routerLink]="[constUrl.ENDUSER_PACKAGE_MANAGER]" routerLinkActive="router-link-active" >{{ 'PackageNotiBlock_02' | translate }}</a>
            </span>
            
            <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="dismiss()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    
    <ng-template #noTrial>
        <div class="col-12">
            <div *ngIf="isValidAndWarning" class="ect-alert ect-alert-warning" role="alert">
                <span>
                    {{ 'PackageNotiBlock_04' | translate: {packageName: packageSessionService.getPackageName(), time: packageSessionService.timeExpired} }} 
                    <a class="btn-update-package ml-3" [routerLink]="[constUrl.ENDUSER_PACKAGE_MANAGER]" routerLinkActive="router-link-active" >{{ 'PackageNotiBlock_05' | translate }}</a>
                </span>
            </div>
            <div *ngIf="isNotValid" class="ect-alert ect-alert-warning" role="alert">
                <span>
                    {{ 'PackageNotiBlock_06' | translate: {packageName: packageSessionService.getPackageName()} }} 
                    <a class="btn-update-package ml-3" [routerLink]="[constUrl.ENDUSER_PACKAGE_MANAGER]" routerLinkActive="router-link-active" >{{ 'PackageNotiBlock_05' | translate }}</a>
                </span>
            </div>
        </div>
    </ng-template>
</div>


