<app-spinner></app-spinner>
<div class="wrapper">
  <div class="preview-overlay">
    <div class="preview-container">
      <div class="preview-header d-flex">
        <div (click)="goToBack()"
          class="ct-header-btn-back d-flex justify-content-center align-items-center cursor-pointer">
          <span class="material-icons">close</span>
        </div>
        <div class="ct-title d-flex align-items-start justify-content-center flex-column px-3">
          <h4 class="mb-0">Tải hợp đồng hàng loạt</h4>
          <ng-container *ngIf="by === 'OWNER'; else elseTemplate">
            <label class="text-weight-bold text-primary mb-0">Hợp đồng gửi</label>
          </ng-container>
          <ng-template #elseTemplate>
            <label class="text-weight-bold text-primary mb-0">Hợp đồng nhận</label>
          </ng-template>
        </div>
        <div class="ct-toolbar-btn d-flex align-items-center ml-auto">
          <div class="d-flex align-items-center"></div>
        </div>
      </div>

      <div class="preview-body">
        <div class="pv ct">
          <div class="pv-body">
            <div class="ctpv-container">
              <div class="ctpv-content">
                <div class="ct-container">
                  <div class="ct-content ct-enable-scroll">
                    <div class="ct-doc-loaded">
                      <div class="ec-tab-sign-multiple-content">
                        <div class="ec-tab-step">
                          <div class="row">
                            <div class="col-md-8">
                              <div class="bg-white">
                                <section class="p-3" *ngIf="!isRequested">
                                  <div class="row">
                                    <div class="col-lg-6 col-md-6">
                                      <form [formGroup]="formSearch" class="input-group border rounded mb-3"
                                        (ngSubmit)="actSearch(formSearch.value.search)">
                                        <div class="input-group-prepend border-0">
                                          <button class="btn d-inline-flex pr-2 pl-2" type="submit">
                                            <span class="material-icons">search</span>
                                          </button>
                                        </div>
                                        <input type="text" class="form-control border-0" formControlName="search"
                                          placeholder="Tìm theo Tên hợp đồng, loại hợp đồng, tên file" aria-label=""
                                          aria-describedby="basic-addon1" />
                                      </form>
                                    </div>
                                    <div class="col-md-auto ml-auto">
                                      <button class="btn btn-outline-primary d-inline-flex mr-3"
                                        (click)="cleanFilter()">
                                        Đặt lại
                                      </button>
                                      <button class="btn btn-primary d-inline-flex" (click)="searchWithFilter()">
                                        Áp dụng
                                      </button>
                                    </div>
                                  </div>
                                  <hr class="my-2" />
                                </section>
                                <section class="px-3 my-2" *ngIf="!isRequested">
                                  <form [formGroup]="formFilter">
                                    <div class="row">
                                      <div class="col-md-3">
                                        <div class="form-group">
                                          <label for="statusGCS">Loại khách hàng</label>
                                          <select formControlName="customerType" class="form-control">
                                            <option value="">Tất cả</option>
                                            <option value="CONSUMER">Cá nhân</option>
                                            <option value="BUSINESS">Doanh nghiệp</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div class="col-md-3">
                                        <div class="form-group">
                                          <label for="">Từ ngày</label>
                                          <div class="input-group">
                                            <input #fromDatePicker class="form-control"
                                              [control]="formFilter.controls.fromDate"
                                              [value]="formFilter.value.fromDate"
                                              (dateSelect)="changeDateFilter($event,dateType.CREATED_FROM)"
                                              placeholder="dd/MM/yyyy" ngbDatepicker #createdFromDate="ngbDatepicker"
                                              [maxDate]="{
                                                year: currentDate.getFullYear(),
                                                month:
                                                  currentDate.getMonth() + 1,
                                                day: currentDate.getDate()
                                              }" appInputMark [options]="optionsCleave" />
                                            <div class="input-group-append">
                                              <button class="btn btn-outline-secondary"
                                                (click)="createdFromDate.toggle()" type="button">
                                                <em class="fas fa-calendar-alt"></em>
                                              </button>
                                            </div>
                                            <app-show-validate-errors [useSubmit]="false"
                                              [errorMessages]="validateSearch.fromDate" [detail]="{ name: 'fromDate' }">
                                            </app-show-validate-errors>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-3">
                                        <div class="form-group">
                                          <label for="">Đến ngày</label>
                                          <div class="input-group">
                                            <input #toDatePicker class="form-control"
                                              [control]="formFilter.controls.toDate" [value]="formFilter.value.toDate"
                                              (dateSelect)="changeDateFilter($event,dateType.CREATED_TO)"
                                              placeholder="dd/MM/yyyy" ngbDatepicker #createdToDate="ngbDatepicker"
                                              [maxDate]="{
                                                year: currentDate.getFullYear(),
                                                month:
                                                  currentDate.getMonth() + 1,
                                                day: currentDate.getDate()
                                              }" appInputMark [options]="optionsCleave" />
                                            <div class="input-group-append">
                                              <button class="btn btn-outline-secondary" (click)="createdToDate.toggle()"
                                                type="button">
                                                <em class="fas fa-calendar-alt"></em>
                                              </button>
                                            </div>
                                            <app-show-validate-errors [useSubmit]="false"
                                              [errorMessages]="validateSearch.toDate" [detail]="{ name: 'toDate' }">
                                            </app-show-validate-errors>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-md-3">
                                        <div class="form-group">
                                          <label for="status">Trạng thái</label>
                                          <select formControlName="status" class="form-control">
                                            <option value="" selected>Tất cả</option>
                                            <option value="LC_DRAFT_CREATE">Bản nháp</option>
                                            <option value="LC_DRAFT_DISCUSS">Đàm phán (Chưa đồng ý thỏa thuận)</option>
                                            <option value="LC_DRAFT_DEAL">Đàm phán (Đã có bên đồng ý thỏa thuận)
                                            </option>
                                            <option value="LC_DRAFT_SUBMIT">Chờ ký</option>
                                            <option value="LC_DRAFT_CANCEL">Đã từ chối</option>
                                            <option value="LC_CONTRACT_VALID">Có hiệu lực</option>
                                            <option value="LC_DRAFT_WAIT_CONFIRM">Chờ thẩm định</option>
                                            <option value="LC_DRAFT_SIGN_FAIL">Ký lỗi</option>
                                            <option value="LC_DRAFT_WARNING">Cảnh báo</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </section>

                                <section class="card-body p-0">
                                  <div class="table-responsive">
                                    <table class="table table-sort-col mb-0">
                                      <thead>
                                        <tr class="bg-tb-header">
                                          <th *ngIf="!isRequested && requestedContracts.length === 0">
                                            <div class="form-check ccheck-primary">
                                              <input [(ngModel)]="isCheckAll" class="form-check-input" type="checkbox"
                                                value="" id="all" (click)="checkAll()" />
                                              <label class="form-check-label" for="all"></label>
                                            </div>
                                          </th>
                                          <th>STT</th>
                                          <th (click)="sortCol('title')">
                                            <span class="d-flex align-self-center align-items-center">
                                              Tên hợp đồng
                                              <span *ngIf="this.sortColConfig['title'] ==null"
                                                class="material-icons ml-auto">
                                                unfold_more
                                              </span>
                                              <span *ngIf="this.sortColConfig['title'] ==true"
                                                class="material-icons ml-auto">
                                                expand_less
                                              </span>
                                              <span *ngIf="this.sortColConfig['title'] ==false"
                                                class="material-icons ml-auto">
                                                expand_more
                                              </span>
                                            </span>
                                          </th>
                                          <th (click)="sortCol('partnersCount')">
                                            <span class="d-flex align-self-center align-items-center">
                                              Số bên tham gia
                                              <span *ngIf="this.sortColConfig['partnersCount'] == null"
                                                class="material-icons ml-auto">
                                                unfold_more
                                              </span>
                                              <span *ngIf="this.sortColConfig['partnersCount'] == true"
                                                class="material-icons ml-auto">
                                                expand_less
                                              </span>
                                              <span *ngIf="this.sortColConfig['partnersCount'] == false"
                                                class="material-icons ml-auto">
                                                expand_more
                                              </span>
                                            </span>
                                          </th>
                                          <th (click)="sortCol('modified')">
                                            <span class="d-flex align-self-center align-items-center">
                                              Ngày chỉnh sửa
                                              <span *ngIf="this.sortColConfig['modified'] == null"
                                                class="material-icons ml-auto">
                                                unfold_more
                                              </span>
                                              <span *ngIf="this.sortColConfig['modified'] == true"
                                                class="material-icons ml-auto">
                                                expand_less
                                              </span>
                                              <span *ngIf="this.sortColConfig['modified'] == false"
                                                class="material-icons ml-auto">
                                                expand_more
                                              </span>
                                            </span>
                                          </th>
                                          <th (click)="sortCol('currentStage')">
                                            <span class="d-flex align-self-center align-items-center">
                                              Trạng thái
                                              <span *ngIf="this.sortColConfig['currentStage'] == null"
                                                class="material-icons ml-auto">
                                                unfold_more
                                              </span>
                                              <span *ngIf="this.sortColConfig['currentStage'] == true"
                                                class="material-icons ml-auto">
                                                expand_less
                                              </span>
                                              <span *ngIf="this.sortColConfig['currentStage'] == false"
                                                class="material-icons ml-auto">
                                                expand_more
                                              </span>
                                            </span>
                                          </th>
                                        </tr>
                                      </thead>
                                      <ng-container
                                        *ngIf="isRequested || requestedContracts.length >0;else elseNoSubmitTable">
                                        <tbody>
                                          <tr *ngFor="let item of requestedContracts;let i = index">
                                            <td>{{ i + 1 }}</td>
                                            <td>
                                              <a class="" routerLinkActive="router-link-active"
                                                [routerLink]="['/app/console/contract',item.contractId]">{{ item.title
                                                }}</a>
                                            </td>
                                            <td>{{ item.partners.length }}</td>
                                            <td>{{item.modified| date: 'dd/MM/yyyy'}}</td>
                                            <td>
                                              <span class="badge {{currentStage[item.currentStage].style || ''}} p-2">
                                                {{currentStage[item.currentStage].label | translate}}</span>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </ng-container>
                                      <ng-template #elseNoSubmitTable>
                                        <tbody>
                                          <tr *ngFor="let item of listContract;let i = index"
                                            [class.activated]="item.selected">
                                            <td *ngIf="!isRequested">
                                              <div class="form-check ccheck-primary">
                                                <input class="form-check-input" id="{{i}}" [(ngModel)]="item.selected"
                                                  type="checkbox" value="" (click)="selectRecord(i)" />
                                                <label class="form-check-label" for="{{i}}"></label>
                                              </div>
                                            </td>
                                            <td>{{ (page - 1) * maxSize + i + 1 }}</td>
                                            <td>
                                              <a class="text-decoration-underline" routerLinkActive="router-link-active"
                                                [routerLink]="['/app/console/contract',item.contractId]">{{ item.title
                                                }}</a>
                                            </td>
                                            <td>{{ item.partners.length }}</td>
                                            <td>{{item.modified| date: 'dd/MM/yyyy'}}</td>
                                            <td>
                                              <span class="badge {{currentStage[item.currentStage].style || ''}} p-2">
                                                {{currentStage[item.currentStage].label | translate}}</span>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </ng-template>
                                    </table>
                                  </div>
                                  <div class="bg-tb-header p-3" *ngIf="!isRequested">
                                    <div class="row align-items-center">
                                      <span *ngIf="totalElement == 0" class="col-md-5 text-left">Không tìm thấy kết
                                        quả</span>
                                      <span *ngIf="totalElement > 0" class="col-md-5 text-left">Hiển thị 1 đến {{
                                        listContract.length }} trong tổng số {{ totalElement }} kết quả</span>
                                      <div class="col-md-7">
                                        <div class="d-flex justify-content-end">
                                          <div *ngIf="totalElement > 0"
                                            class="d-flex justify-content-end align-items-center">
                                            <span class="mr-2">Số dòng hiển thị:</span>
                                            <ng-select class="no-bg no-border" [(ngModel)]="maxSize"
                                              [searchable]="false" [clearable]="false" placeholder="..."
                                              (change)="loadData(1)">
                                              <ng-option [value]="10">10</ng-option>
                                              <ng-option [value]="20">20</ng-option>
                                              <ng-option [value]="30">30</ng-option>
                                            </ng-select>
                                          </div>
                                          <div *ngIf="totalElement > maxSize"
                                            class="pagination pages no-border-pagination ml-2">
                                            <ngb-pagination [collectionSize]="totalElement" [(page)]="page"
                                              [maxSize]="5" [rotate]="true" [ellipses]="false" [pageSize]="maxSize"
                                              (pageChange)="loadData($event)" [boundaryLinks]="true"
                                              aria-label="Default pagination">
                                              <ng-template ngbPaginationFirst>Đầu</ng-template>
                                              <ng-template ngbPaginationLast>Cuối</ng-template>
                                              <ng-template ngbPaginationPrevious>&laquo;</ng-template>
                                              <ng-template ngbPaginationNext>&raquo;</ng-template>
                                              <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                                            </ngb-pagination>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </section>
                              </div>
                            </div>
                            <ng-container *ngIf="isRequested || requestedContracts.length >0; else elseNoSubmitCol">
                              <div class="col-md-4">
                                <div class="bg-white">
                                  <div class="block-action p-3">
                                    <div class="d-flex align-items-center justify-content-start mb-2">
                                      <h5 class="title-block">Tiếp nhận yêu cầu tải hợp đồng thành công!</h5>
                                    </div>
                                    <p class="mt-3">
                                      Yêu cầu tải hợp đồng của Quý khách đã tiếp nhận và xử lý. Quý khách vui lòng truy
                                      cập chức năng “<span><b>Danh sách tải xuống</b></span>” để thực hiện tải về sau ít
                                      phút!
                                    </p>
                                    <button (click)="goToListDownload()"
                                      class="btn btn-lg btn-primary w-100 d-flex align-items-center justify-content-center">
                                      Xem danh sách tải xuống
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </ng-container>
                            <ng-template #elseNoSubmitCol>
                              <div class="col-md-4">
                                <div class="bg-white">
                                  <div class="block-action p-3">
                                    <div class="d-flex align-items-center justify-content-start mb-2">
                                      <h5 class="title-block">Chọn hợp đồng cần tải</h5>
                                    </div>
                                    <p class="mt-3">Vui lòng chọn các hợp đồng cần tải xuống</p>
                                    <p>
                                      <i class="text-secondary">Lưu ý: Để tải xuống các hợp đồng đang ở trạng thái có
                                        hiệu lực, hệ thống sẽ tự động giải mã các hợp đồng này. Hợp đồng đã giải mã có
                                        thể được xem bằng các ứng
                                        dụng khác nhưng không thể mã hóa lại.</i>
                                    </p>
                                    <label class="text-weight-bold mt-3">Số lượng file hợp đồng đã chọn</label>
                                    <p>
                                      {{ totalRecordSelected }}/{{maxFileSelect}}
                                    </p>
                                    <label class="text-weight-bold mt-3">Tổng dung lượng file hợp đồng đã chọn</label>
                                    <div class="progress rounded">
                                      <div class="progress-bar bg-success" role="progressbar"
                                        [attr.aria-valuenow]="progressValueNow" aria-valuemin="0" aria-valuemax="100"
                                        [style]="{ 'width': progressValueNow + '%'}"></div>
                                    </div>
                                    <div class="d-flex align-items-center my-2">
                                      <span class="mr-auto">{{ progressValueNow }}%</span>
                                      <span class="ml-auto">{{ totalSizeFile }} / {{ maxSizeFile }} MB</span>
                                    </div>
                                    <button (click)="requestDownload()" [disabled]="totalRecordSelected <= 0"
                                      class="btn btn-lg btn-primary w-100 d-flex align-items-center justify-content-center">
                                      Tải xuống<span class="material-icons-outlined ml-3">download</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </ng-template>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>