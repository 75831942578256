import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Route } from '@angular/compiler/src/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/utils/services/auth.service';
import { InfoPartyObject } from 'src/app/utils/models/aas-models/info-party/info-party-object';
import { ControlAccount } from 'src/app/utils/common/control-account';
import { ServiceContractListV2Component } from './service-contract-list-v2/service-contract-list-v2.component';
import { UserInformationsItem } from 'src/app/utils/models/profile/user-info-item.model';
import {
  UserTypeConstant,
  VerifyStatusConst,
} from 'src/app/utils/common/constant';
import { forkJoin } from 'rxjs';
import { ConstantUrl } from 'src/app/utils/common/constant-url';

@Component({
  selector: 'app-service-contract-manager',
  templateUrl: './service-contract-manager.component.html',
  styleUrls: ['./service-contract-manager.component.scss'],
})
export class ServiceContractManagerComponent implements OnInit, AfterViewInit {
  @ViewChild('ownerList') ownerList: ServiceContractListV2Component;
  @ViewChild('assigneeList') assigneeList: ServiceContractListV2Component;

  selectedTab = 0;
  by: string = '';

  partyInfo: InfoPartyObject = new InfoPartyObject();
  userInfo: UserInformationsItem = new UserInformationsItem();

  constructor(
    private router: Router,
    public controlAccount: ControlAccount,
    private authService: AuthService
  ) {
    controlAccount.getAccountInfo();
  }

  ngOnInit(): void {
    window.localStorage.removeItem('requestedContracts')
    forkJoin([
      this.authService.getPartyInfo(this.authService.partyId),
      this.authService.getUserInfo(this.authService.userId),
    ]).subscribe(
      (res: any) => {
        this.partyInfo = new InfoPartyObject(res[0].object || {});
        this.userInfo = new UserInformationsItem(res[1].object || {});

        this.isAccLevel2 ? this.selectTab(1) : this.selectTab(2);
      },
      (err) => {
        console.log(err);
      },
      () => {}
    );
  }

  ngAfterViewInit(): void {
    // this.authService.userInfo.subscribe((res) => {
    //   console.log("called");
    //   this.controlAccount.isAccLevel2 ? this.selectTab(1) : this.selectTab(2);
    // })
    // this.selectTab(1);
  }

  addContract() {
    this.router.navigate(['/app/console/contract/create']);
  }

  selectTab(value) {
    this.selectedTab = value;
    switch (value) {
      case 1:
        this.by = 'OWNER';
        this.ownerList && this.ownerList.reload();
        break;
      case 2:
        this.by = 'ASSIGN';
        this.assigneeList && this.assigneeList.reload();
        break;
    }
  }

  get isAccLevel2() {
    return this.controlAccount.isAccLevel2;
    var uploadedGPKD = this.partyInfo.gpkdFilename !== '';
    var level = `${this.partyInfo.identityLevel}` === '2';
    var level2 = `${this.userInfo.identityLevel}` === '2';

    let status = this.partyInfo.verificationStatus;
    let isApprivedBusiness =
      status === VerifyStatusConst.APPROVE ||
      status === VerifyStatusConst.APPROVE_CONTRACT ||
      status === VerifyStatusConst.APPROVE_NON_CONTRACT;

    let status1 = this.userInfo.verificationStatus;
    let isApprovedConsumer =
      status1 === VerifyStatusConst.APPROVE ||
      status1 === VerifyStatusConst.APPROVE_CONTRACT ||
      status1 === VerifyStatusConst.APPROVE_NON_CONTRACT;

    let isConsumer = this.userInfo.userType === UserTypeConstant.CONSUMER;
    let isBusiness =
      this.partyInfo.partyType === UserTypeConstant.BUSINESS ||
      this.partyInfo.partyType === UserTypeConstant.ENTERPRISE;

    return (
      (isConsumer && isApprovedConsumer && level2) ||
      (isBusiness && isApprivedBusiness && uploadedGPKD && level)
    );
  }

  //MARK: Ky hang loat
  goToSignMultiple(type: string) {
    this.router.navigate([ConstantUrl.sign_multiple_contracts, type]);
  }

  // Duyệt không ký hàng loạt
  goToNoSignMultiple() {
    this.router.navigate(['app/console/approve-without-sign'])
  }

  goToMultiDownloadSign(by) {
    this.router.navigate([ConstantUrl.sign_multi_download_contract, by]);
  }
}
