<form [formGroup]="formSubmit">
    <div class="form-group">
        <label for="">{{ 'RegisterBusiness_h_1' | translate }}<span
                class="text-danger">*</span></label>
        <input appTrimValue formControlName="tenDoanhNghiep" type="text"
            class="form-control" placeholder="{{ 'RegisterBusiness_place_1' | translate }}">
            <app-show-validate-errors [isSubmit]="isSubmit"
            [errorMessages]="validateMsg.tenDoanhNghiep" [control]="formSubmit.controls.tenDoanhNghiep"
            [detail]="{ name: 'tenDoanhNghiep' }">
        </app-show-validate-errors>
    </div>
    <div class="row pl-0 pr-0">
        <div class="col-md-6">
            <div class="form-group">
                <label for="">{{ 'RegisterBusiness_h_2' | translate }}<span
                        class="text-danger">*</span></label>
                <input appTrimValue formControlName="tenVietTat" type="text"
                    class="form-control" placeholder="{{ 'RegisterBusiness_place_2' | translate }}">
                    <app-show-validate-errors [isSubmit]="isSubmit"
                    [errorMessages]="validateMsg.tenVietTat" [control]="formSubmit.controls.tenVietTat"
                    [detail]="{ name: 'tenVietTat' }">
                </app-show-validate-errors>
            </div>
        </div>

        <div class="col-md-6">
            <div class="form-group">
                <label for="">{{ 'RegisterBusiness_h_3' | translate }}<span
                        class="text-danger">*</span></label>
                <input appTrimValue formControlName="maSoThue" type="text"
                    class="form-control" placeholder="{{ 'RegisterBusiness_place_3' | translate }}">
                    <app-show-validate-errors [isSubmit]="isSubmit"
                    [errorMessages]="validateMsg.maSoThue" [control]="formSubmit.controls.maSoThue"
                    [detail]="{ name: 'maSoThue' }">
                </app-show-validate-errors>
            </div>
        </div>
    </div>
    <div class="form-group">
        <label for="">{{ 'RegisterBusiness_h_4' | translate }}<span
                class="text-danger">*</span></label>
        <select class="form-control" formControlName="tinhId"
            >
            <option *ngFor="let tinh of tinhBindData" [value]="tinh.id"
                >{{
                tinh.label }}</option>
        </select>
        <app-show-validate-errors [isSubmit]="isSubmit"
            [errorMessages]="validateMsg.tinhId" [control]="formSubmit.controls.tinhId"
            [detail]="{ name: 'tinhId' }">
        </app-show-validate-errors>
    </div>
    <div class="row pl-0 pr-0">
        <div class="col-md-6">
            <div class="form-group">
                <label for="">{{ 'RegisterBusiness_h_5' | translate }}<span
                        class="text-danger">*</span></label>
                <input appTrimValue formControlName="email" type="text"
                    class="form-control" placeholder="{{ 'RegisterBusiness_place_4' | translate }}">
                    <app-show-validate-errors [isSubmit]="isSubmit"
            [errorMessages]="validateMsg.email" [control]="formSubmit.controls.email"
            [detail]="{ name: 'email' }">
        </app-show-validate-errors>
            </div>
        </div>

        <div class="col-md-6">
            <div class="form-group">
                <label for="">{{ 'RegisterBusiness_h_6' | translate }}<span
                        class="text-danger">*</span></label>
                <input appTrimValue formControlName="hoVaTen" type="text"
                    class="form-control" placeholder="{{ 'RegisterBusiness_place_5' | translate }}">
                    <app-show-validate-errors [isSubmit]="isSubmit"
            [errorMessages]="validateMsg.hoVaTen" [control]="formSubmit.controls.hoVaTen"
            [detail]="{ name: 'hoVaTen' }">
        </app-show-validate-errors>
            </div>
        </div>
    </div>
    <div class="form-group">
        <label for="">{{ 'RegisterBusiness_h_7' | translate }}<span
                class="text-danger">*</span></label>
        <input appTrimValue formControlName="username" type="text"
            class="form-control" placeholder="{{ 'RegisterBusiness_place_6' | translate }}">
            <app-show-validate-errors [isSubmit]="isSubmit"
            [errorMessages]="validateMsg.username" [control]="formSubmit.controls.username"
            [detail]="{ name: 'username' }">
        </app-show-validate-errors>
    </div>
    <div class="form-group">
        <label for="">{{ 'RegisterBusiness_h_8' | translate }}<span class="text-danger">*</span></label>
        <div class="d-block position-relative">
            <input appInputPassword formControlName="password"
                type="password" class="form-control"
                placeholder="{{ 'RegisterBusiness_place_7' | translate }}">
        </div>
        <app-show-validate-errors [isSubmit]="isSubmit"
            [errorMessages]="validateMsg.password" [control]="formSubmit.controls.password"
            [detail]="{ name: 'password' }">
        </app-show-validate-errors>
    </div>
    

    <span class="footer first">
        <input type="checkbox" value="false"
            [(ngModel)]="checkbox_dieukhoan"
            [ngModelOptions]="{standalone: true}"> {{ 'RegisterBusiness_h_9' | translate }}&nbsp;<span
            (click)="showDieuKhoan()"
            class="text-primary text-decoration-underline cursor-pointer">{{ 'RegisterBusiness_h_10' | translate }}</span>&nbsp;{{ 'RegisterBusiness_h_11' | translate }}
    </span>
    <span class="footer">
        {{ 'RegisterBusiness_h_12' | translate }} <a href="">{{ 'RegisterBusiness_h_13' | translate }}</a>
    </span>
    <button type="button" class="btn_reg" (click)="submit()">{{ 'RegisterBusiness_h_14' | translate }}</button>
</form>