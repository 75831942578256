
<div class="wrapper">
    <div class="preview-overlay">

        <div class="preview-container">
            <div class="preview-header d-flex">
                <div (click)="goToBack()"
                    class="ct-header-btn-back d-flex justify-content-center align-items-center cursor-pointer">
                    <span class="material-icons">close</span>
                </div>
                <div class="ct-title flex-grow-1 d-flex align-items-center px-3">
                    <h4 class="mb-0">{{ 'FillDataContractTemplate_label_1' | translate }}</h4>
                </div>
                <div class="ct-toolbar-btn d-flex align-items-center">
                    <button (click)="backAction()"
                        class="btn btn-outline-primary d-flex align-items-center mr-3"><span
                            class="material-icons mr-2">clear</span> {{ 'btn_cancel' | translate }}</button>
                    <button (click)="nextAction()"
                        class="btn btn-outline-primary d-flex align-items-center mr-3"><span
                            class="material-icons mr-2">save</span> {{ 'btn_save' | translate }}</button>
                </div>
            </div>

            <div class="preview-body">
                <div class="pv ct">
                    <div class="pv-body">
                        <div class="ctpv-container">
                            <div class="ctpv-content">
                                <div class="ct-container">
                                    <div class="ct-content" style="left: 0;">
                                        <div class="ct-content-scroll enable-scroll">
                                            <div class="container">
                                                <div class="card border-0 shadow-none mt-3">
                                                    <div class="card-header">
                                                        <label class="card-title text-primary mb-0">{{ 'FillDataContractTemplate_label_2' | translate }}</label>
                                                    </div>
                                                    <div class="card-body">

                                                        <div class="table-responsive">
                                                            <table class="table table-bordered">
                                                                <thead>
                                                                    <tr class="bg-tb-header">
                                                                        <th>{{ 'FillDataContractTemplate_label_3' | translate }}</th>
                                                                        <th>{{ 'FillDataContractTemplate_label_4' | translate }}</th>
                                                                        <th>{{ 'FillDataContractTemplate_label_5' | translate }}</th>
                                                                        <th>{{ 'FillDataContractTemplate_label_6' | translate }}</th>
                                                                        <th>{{ 'FillDataContractTemplate_label_7' | translate }}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr *ngFor="let item of textBoxListNoFilled; let i = index;">
                                                                        <td>{{ i + 1 }}</td>
                                                                        <td>{{ item.label }}</td>
                                                                        <td>{{ item.type }}</td>
                                                                        <td>
                                                                            <input 
                                                                                class="form-control" 
                                                                                type="text"
                                                                                (input)="inputConfig(item.key, $event.target.value)"
                                                                                value="{{ paramRenderByStep[currentStep][item.key] }}" 
                                                                                placeholder="{{ 'FillDataContractTemplate_ph_1' | translate }}"/>
                                                                        </td>
                                                                        <td>
                                                                            <button 
                                                                                class="btn btn-sm rounded-lg text-primary d-inline-flex align-items-center btn-show-mark"
                                                                                style="background-color: rgba(0, 98, 255, 0.16);" 
                                                                                (click)="showMark(item.key)" [attr.data-key]="item.key">
                                                                                <em class="material-icons m-0">visibility</em>
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        
                                                        <div *ngIf="checkBoxesNoFilled.length > 0" class="form-group">
                                                            <!-- <div *ngFor="let item of checkBoxListNoFilled" class="icheck-primary mb-3">
                                                                <input [checked]="paramRenderByStep[currentStep][item.key] === '1'" type="checkbox"
                                                                    id="checkboxPrimary{{item.key}}" (click)="actionCheckbox(item.key)">
                                                                <label for="checkboxPrimary{{item.key}}">
                                                                    {{item.label}}
                                                                </label>
                                                            </div> -->
                                                            <div *ngFor="let item of checkBoxesNoFilled" class="row mb-5">
                                                                <div class="col-12">
                                                                    <div class="row">
                                                                        <div class="col-12">
                                                                            <label class="font-weight-bold mb-3 card-title text-primary">{{ 'FillDataContractTemplate_label_8' | translate: {item: item} }}:</label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div *ngFor="let x of checkBoxListNoFilled[item]"  class="col-12">
                                                                            <div class="d-flex align-items-center mb-2">
                                                                                <button 
                                                                                    class="btn btn-sm btn-icon btn-show-mark-checkbox rounded-lg text-primary d-inline-flex align-items-center mr-2"
                                                                                    style="background-color: rgba(0, 98, 255, 0.16);" 
                                                                                    (click)="showMark(x.key)" [attr.data-key]="x.key">
                                                                                    <em class="material-icons m-0">visibility</em>
                                                                                </button>
                                                                                <div class="icheck-primary" style="width: fit-content;">
                                                                                    <input [checked]="paramRenderByStep[currentStep][item][x.key] === 1" type="checkbox"
                                                                                        id="checkboxPrimary{{x.key}}" (click)="actionCheckbox(item, x.key)">
                                                                                    <label for="checkboxPrimary{{x.key}}">
                                                                                        {{x.label}}
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div *ngFor="let item of tableListNoFilled" class="mb-5">
                                                            <div class="">
                                                                <label class="mb-3 card-title text-primary">{{ 'FillDataContractTemplate_label_9' | translate: {item: item} }}:</label>
                                                                <div class="enable-scroll table-responsive">
                                                                    <table class="table table-bordered border mb-1">
                                                                        <thead class="bg-tb-header">
                                                                            <th *ngFor="let th of tableObjectListNoFilled[item]">
                                                                                <div class="d-flex">
                                                                                    <span class="mr-auto"> {{ th.label }}</span>
                                                                                    <span (click)="showMark(th.key)" [attr.data-key]="th.key" class="item-show-mark-tb material-icons icon-location-color mr-2" style="cursor: pointer;">gps_fixed</span>
                                                                                </div>
                                                                            </th>
                                                                            <th></th>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr *ngFor="let tr of paramRenderByStep[currentStep][item]; let i = index">
                                                                                <td contenteditable *ngFor="let th of tableObjectListNoFilled[item]"
                                                                                    (blur)="inputTable($event, item, i, th.key)">
                                                                                    {{tr[th.label]}}
                                                                                </td>
                                                                                <td>
                                                                                    <a class="text-danger" style="cursor: pointer;" (click)="deleteRow(item, i)">
                                                                                        <span class="material-icons-outlined">delete</span>    
                                                                                    </a>
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                                <div class="d-flex justify-content-start">
                                                                    <a class="d-flex align-items-end text-primary" (click)="addColumnToTable(item)" style="cursor: pointer;">
                                                                        <span class="material-icons">add</span> 
                                                                        <span>{{ 'FillDataContractTemplate_label_10' | translate }}</span>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>      
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<div id="mark-ppi" style="height: 1in; width: 1in; position: absolute; top: -100%; left: -100%;"></div>
<!-- <div id="mark-viewer-page" class="mark-viewer-page" style="display: none;">
</div> -->