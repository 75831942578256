import { Injectable } from '@angular/core';
import { ServicePath } from '../../common/constant-service-api';
import { API } from '../api';

const LIST_USER_GROUP_URL = ServicePath.AUTH_SERVICE + "/role/list-paging";
const CREATED_USER_GROUP = ServicePath.AUTH_SERVICE + "/role";
const UPDATE_USER_GROUP = ServicePath.AUTH_SERVICE + "/role";
const DELETE_USER_GROUP = ServicePath.AUTH_SERVICE + "/role";
const GET_ALL_PRIVILEGE = ServicePath.AUTH_SERVICE + "/privilege/all-privileges";
const GET_PRIVILEGE_USER_GROUP = ServicePath.AUTH_SERVICE + '/role';
const UPDATE_PRIVILEGE_OF_USERGROUP = ServicePath.AUTH_SERVICE + '/role/assign-role-to-privilege';
const CREATED_USER_GROUP_V2 = ServicePath.AUTH_SERVICE + "/v2/role";
const DELETE_USER_GROUP_V2 = ServicePath.AUTH_SERVICE + "/v2/role";
const UPDATE_USER_GROUP_V2 = ServicePath.AUTH_SERVICE + "/v2/role";
const LIST_USER_GROUP_URL_V2 = ServicePath.AUTH_SERVICE + "/v2/role/list-paging";
const GET_PRIVILEGE_USER_GROUP_V2 = ServicePath.AUTH_SERVICE + '/v2/role';
const ROLE_USER_URL = ServicePath.AUTH_SERVICE + '/v2/role/filter';

@Injectable({
  providedIn: 'root'
})
export class UserManagerService {

  constructor(private api: API) { }

  /**
   * Get danh sach nhom nguoi dung (nhom quyen)
   */
  getListUserGroup(page, maxSize, search) {
    const url = LIST_USER_GROUP_URL + `?page=${page}&maxSize=${maxSize}&search=${search}`;
    return this.api.get(url);
  }

  /**
   * Khởi tạo nhóm người dùng
   * @param param 
   */
  postCreatedUserGroup(param) {
    const url = CREATED_USER_GROUP;
    return this.api.post(url, param);
  }

  /**
   * Cập nhật nhóm người dùng
   * @param id 
   * @param param 
   */
  putUpdateUserGroup(id, param) {
    const url = UPDATE_USER_GROUP + `/${id}`;
    return this.api.put(url, param);
  }

  /**
   * Xóa nhóm người dùng
   * @param id 
   */
  deleteUserGroup(id) {
    const url = DELETE_USER_GROUP  + `/${id}`;
    return this.api.delete(url);
  }

  /**
   * Get Danh sách tất cả các quyền
   */
  getAllPrivileges() {
    const url = GET_ALL_PRIVILEGE;
    return this.api.get(url);
  }

  /**
   * Get danh sách quyền của 1 nhóm người dùng
   * @param id 
   */
  getPrivilegesUserGroup(id) {
    const url = GET_PRIVILEGE_USER_GROUP + `/${id}/privilege`;
    return this.api.get(url);
  }

  /**
   * Cập nhật quyền cho 1 nhóm người dùng
   * @param param 
   */
  updatePrivilegeUserGroup(param) {
    const url = UPDATE_PRIVILEGE_OF_USERGROUP;
    return this.api.put(url, param);
  }

  /**
   * Khởi tạo nhóm người dùng
   * @param param 
   */
  postCreatedUserGroupV2(param) {
    const url = CREATED_USER_GROUP_V2;
    return this.api.post(url, param);
  }

  /**
   * Xóa nhóm người dùng
   * @param id 
   */
   deleteUserGroupV2(id) {
    const url = DELETE_USER_GROUP_V2  + `/${id}`;
    return this.api.delete(url);
  }

  /**
   * Cập nhật nhóm người dùng
   * @param id 
   * @param param 
   */
   putUpdateUserGroupV2(id, param) {
    const url = UPDATE_USER_GROUP_V2 + `/${id}`;
    return this.api.put(url, param);
  }

  /**
   * Get danh sach nhom nguoi dung (nhom quyen)
   */
   getListUserGroupV2(page, maxSize, search) {
    const url = LIST_USER_GROUP_URL_V2 + `?page=${page}&maxSize=${maxSize}&search=${search}`;
    return this.api.get(url);
  }

  /**
   * Get danh sách quyền của 1 nhóm người dùng
   * @param id 
   */
   getPrivilegesUserGroupV2(id) {
    const url = GET_PRIVILEGE_USER_GROUP_V2 + `/${id}/privilege`;  
    return this.api.get(url);
  }
  
  /** 
   * GET đơn vị quản lý Employees
   */
  getDropListRoles(search: string = ""){
    const url = ROLE_USER_URL + `?search=${search}`;
    return this.api.get(url);
  }
}
