import { MainResponse } from '../../models/main-response.model';
import { DocumentItem } from './document-item-object';

export class DocumentResponse extends MainResponse {
  constructor(obj = null) {
    super();
    this.init(obj);
    this.object = new DocumentItem(obj.object);
  }
}
