import { ConstantAlertMsg } from "../common/constant-alert-msg";
import { localize_account_manager } from "./localize-account-manager";
import { localize_econtract } from "./localize-contract";
import { localize_e_sign_otp } from "./localize-e-sign-otp";
import { localize_modal } from "./localize-modal";
import { localize_package_manager } from "./localize-package-manager";
import { localize_signup } from "./localize-signup";
import { localize_verify_signature } from "./localize-verify-signature";
import { localize_permission_manager } from "./localize-permission-manager";
import { localize_contract_lifecycle } from "./localize-contract-lifecycle";
import { localize_request_service } from "./localize-request-service";

export const localize = {
    ...localize_account_manager,
    ...localize_package_manager,
    ...ConstantAlertMsg,
    ...localize_e_sign_otp,
    ...localize_modal,
    ...localize_econtract,
    ...localize_signup,
    ...localize_verify_signature,

    ...localize_permission_manager,
    ...localize_contract_lifecycle,
    
    ...localize_signup,
    ...localize_request_service,

    access_denied: "Bạn không thể thực hiện thao tác này! Vui lòng thử lại.",

    FORM_REQUIRED: "Vui lòng nhập thông tin!",
    FORM_PATTERN: "Sai định dạng!",
    FORM_EMAIL_REQUIRED: "Vui lòng nhập email!",
    FORM_EMAIL_PATTERN: "Email chưa đúng định dạng!",
    FORM_PHONE_NUMBER_REQUIRED: "Vui lòng nhập số điện thoại!",
    FORM_PHONE_NUMBER_PATTERN: "Số điện thoại bắt đầu bằng '0', chỉ chứa các ký tự số và có 10 ký tự!",
    FORM_USERNAME_REQUIRED: "Vui lòng nhập tên tài khoản!",
    FORM_USERNAME_PATTERN: "Tên tài khoản phải viết liền không dấu, có ít nhất 6-50 ký tự, không chứa ký tự khoảng trống!",
    FORM_STATUS_REQUIRED: "Vui lòng nhập trạng thái!",
    FORM_HOTEN_REQUIRED: "Vui lòng nhập họ và tên!",
    FORM_HOTEN_PATTERN: "Họ và tên chưa đúng định dạng!",
    FORM_PASSWORD_REQUIRED: "Vui lòng nhập mật khẩu!",
    FORM_PASSWORD_PATTERN: "Mật khẩu có độ dài lớn hơn hoặc bằng 8 ký tự bao gồm ký tự hoa, thường, số và ký tự đặc biệt!",
    CHANGE_PASSWORD_SUCCESSFULLY: "Mật khẩu của bạn đã được cập nhập thành công. Vui lòng đăng nhập lại để tiếp tục sử dụng hệ thống.",

    FORM_PART_NAME_REQUIRED: "Vui lòng nhập tên bộ phận!",
    FORM_PART_SDT_PATTERN: "Số điện thoại bắt đầu bằng '0', chỉ chứa các ký tự số và có 10 ký tự!",
    form_part_sdt_required: "Vui lòng nhập số điện thoại!",

    FORM_EMPLOYEE_PART_REQUIRED: "Vui lòng chọn bộ phận!",
    form_employe_roleId_required: "Vui lòng chọn nhóm người dùng!",

    FORM_SEND_CENSORSHIP_PART_REQUIRED: "Vui lòng chọn bộ phận kiểm duyệt!",
    FORM_SEND_CENSORSHIP_USER_REQUIRED: "Vui lòng chọn tài khoản duyệt!",
    OTP_REQUIRED: "Vui lòng nhập mã OTP!",
    OTP_MINLENGTH: "Mã OTP phải bao gồm 6 ký tự số!",
    OTP_MAXLENGTH: "Mã OTP phải bao gồm 6 ký tự số!",

    INTERNAL_SIGN_APPROVED_CONTRACT: "đã ký nháy",
    INTERNAL_NO_SIGN_APPROVED_CONTRACT: "đã duyệt không ký",
    INTERNAL_REJECT_CONTRACT: "đã từ chối ký nháy",
    INTERNAL_NOACTION_CONTRACT: "chưa thực hiện ký nháy",

    APPROVE_CONTRACT_INTERNAL_NOSIGN: "Duyệt không ký hợp đồng thành công!",
    APPROVE_CONTRACT_INTERNAL_FAILED: "Duyệt không ký hợp đồng không thành công! Vui lòng thử lại",
    approve_contract_internal_confirm: "Bạn chắc chắn muốn duyệt không ký hợp đồng này?",
    license_vnpt_sign_timeout: "License ký số VNPT Plugin đã hết hạn. Vui lòng thông báo cho admin để được hỗ trợ.",
    dont_install_vnpt_plugin: "Bạn chưa cài đặt VNPT Plugin. Vui lòng truy cập kyso.vnpt-ca.vn để tải bộ cài đặt trước khi thực hiện ký số",
    dont_see_file_signed: "Không tìm thấy file cần kí!",
    dont_support_file_extension_vnpt_sign: "Định dạng file không được hỗ trợ kí số!",
    not_found_usb_token: "Bạn cần cắm token trước khi ký số.",
    select_img_sign: 'Chọn ảnh chữ ký',
    reject_contract_internal_confirm: "Bạn chắc chắn muốn từ chối kiểm duyệt hợp đồng này? Vui lòng nhập lý do từ chối kiểm duyệt.",
    reject_contract_modal_title: "Từ chối kiểm duyệt hợp đồng",
    reject_contract_field_reason: "Lý do từ chối kiểm duyệt",
    reject_contract_internal_success: "Từ chối kiểm duyệt hợp đồng thành công!",
    reject_contract_internal_failed: "Từ chối kiểm duyệt hợp đồng thất bại!",
    form_reasion_required: "Vui lòng nhập lý do từ chối!",
    finish_sign_internal_success: "Ký nháy kiểm duyệt hợp đồng thành công!",
    finish_sign_internal_failed: "Ký nháy kiểm duyệt hợp đồng không thành công!",
    finish_sign_approval_internal_success: "Ký duyệt kiểm duyệt hợp đồng thành công!",
    not_found_contract_file_signed: "Không tìm thấy file hợp đồng đã ký",
    finish_censorship_internal_confirm: "Bạn chắn chắn muốn kết thúc kiểm duyệt hợp đồng này?",
    finish_censorship_internal_success: "Kết thúc kiểm duyệt hợp đồng thành công!",
    access_denied_end_approved_contract_internal: "Bạn không được phép kết thúc kiểm duyệt nội bộ. Vui lòng kiểm tra lại!",
    form_reject_business_acc_reason_required: "Vui lòng nhập lý do từ chối!",
    form_reject_business_acc_reason_maxlength: "Số lượng ký tự không được vượt quá 4000!",
    desc_img_file_e_signature: "File đính kèm phải có định dạng .jpeg hoặc .png và dung lượng không vượt quá 5MB",

    signup_verify_otp_failed: "Mã OTP không chính xác. Vui lòng thử lại! (Bạn còn ${solan} lần nhập mã OTP)",
    upload_gpkd_success_wait: "<p>Giấy phép kinh doanh của bạn đã được upload thành công.</p><br><p>Để thực hiện ký kết hợp đồng với các đối tác khác, vui lòng đăng nhập lại để cập nhật danh sách chức năng.</p><br><p>Để hoàn thành việc xác minh và tham gia trải nghiệm tất cả các dịch vụ vủa VNPT-eContract, vui lòng thực hiện video call theo như hướng dẫn được gửi về email</p>",
    update_gpkd_success_approve: "Đã hoàn thành việc xác minh. Vui lòng đăng nhập lại để trải nghiệm dịch vụ của chúng tôi.",
    update_gpkd_success_rejected: "Bạn bị từ chối phê duyệt đăng ký sử dụng dịch vụ. Vui lòng thực hiện kiểm tra và đăng ý lại thông tin.",

    part_add_confirm: "Bạn chắc chắn muốn khởi tạo bộ phận này?",
    part_add_success: "Khởi tạo bộ phận thành công!",
    part_add_failed: "Khởi tạo bộ phận thất bại!",
    part_update_confirm: "Bạn chắc chắn muốn cập nhật thông tin của bộ phận này?",
    part_update_success: "Cập nhật thông tin bộ phận thành công!",
    part_update_failed: "Cập nhật thông tin bộ phận không thành công!",
    part_update_failed_status: "Bạn không thể chuyển trạng thái của bộ phận này sang <b>Ngưng hoạt động</b> vì có tài khoản nhân sự thuộc bộ phận đang ở trạng thái <b>Đang hoạt động</b>. Vui lòng kiểm tra lại.",

    employee_add_confirm: "Bạn chắc chắn muốn khởi tạo nhân sự này?",
    employee_add_success: "Khởi tạo nhân sự thành công!",
    employee_add_failed: "Khởi tạo nhân sự thất bại!",
    employee_update_confirm: "Bạn chắc chắn muốn cập nhật thông tin của nhân sự này?",
    employee_update_success: "Cập nhật thông tin nhân sự thành công!",
    pemployee_update_failed: "Cập nhật thông tin nhân sự không thành công!",
    employee_update_failed_status_contract: "Nhân sự này đang tham gia duyệt hợp đồng. Bạn không thể thực hiện cập nhật trạng thái nhân sự này thành <b>Ngưng hoạt động</b>.",
    employee_update_failed_status_by_part_status: "$partName toán đang trong trạng thái Ngưng hoạt động. Vui lòng kích hoạt lại bộ phận trước khi thay đổi trạng thái của nhân sự này.",
    employee_finished_signup_success: "Đăng ký tài khoản thành công!",
    employee_still_active: "Bạn không thể chuyển trạng thái của bộ phận <b>${namePart}</b> sang <b>Ngưng hoạt động</b> vì có tài khoản nhân sự thuộc bộ phận đang ở trạng thái <b>Đang hoạt động</b>. Vui lòng kiểm tra lại.",
    department_still_disable: "Bộ phận ${namePart} đang trong trạng thái Ngưng hoạt động. Vui lòng kích hoạt lại bộ phận trước khi thay đổi trạng thái của nhân viên này.",

    username_placeholder: "Nhập tên tài khoản",
    fullname_placeholder: "Nhập họ và tên",
    cmnd_cccd_placeholder: "Nhập số CCCD/CMND",
    passport_placeholder: "Nhập số hộ chiếu",
    personal_id_placeholder: "Nhập số giấy tờ",
    personal_id_all_placeholder: "Nhập số CCCD/CMND/Hộ chiếu",
    birthday_placeholder: "Nhập ngày sinh",
    sex_placeholder: "Chọn giới tính",
    address_signup_placeholder: "Nhập nơi ĐKHK TT",
    issue_date_placeholder: "Nhập ngày cấp",
    issue_place_placeholder: "Nhập nơi cấp",
    phone_number_placeholder: "Nhập số điện thoại",
    email_placeholder: "Nhập email",

    fullname: "Họ và tên",
    cmnd_cccd: "Số CCCD/CMND",
    passport: "Hộ chiếu",
    personal_id: "Số giấy tờ",
    personal_id_all: "Số CCCD/CMND/Hộ chiếu",
    birthday: "Ngày sinh",
    sex: "Giới tính",
    address_signup: "Nơi ĐKHK TT",
    issue_date: "Ngày cấp",
    issue_place: "Nơi cấp",
    phone_number: "Số điện thoại",
    email: "Email",
    username: "Tên tài khoản",
    business_name: "Tên doanh nghiệp",
    business_sort_name: "Tên viết tắt",
    taxnumber: "Mã số thuế",
    gpkd: "GPKD",
    package_service: "Gói cước",
    addresss: "Địa chỉ",

    stt: "STT",
    template_add_tile: "Thêm mới hợp đồng mẫu",
    template_form_name_label: "Tên hợp đồng mẫu",
    template_form_file_label: "File đính kèm",
    template_form_template_type_label: "Loại hợp đồng mẫu",
    template_form_id: "ID hợp đồng mẫu",
    template_form_status_label: "Trạng thái",
    template_form_name_placeholder: "Nhập tên hợp đồng mẫu",
    template_form_file_placeholder: "Chọn file đính kèm",
    template_form_template_type_placeholder: "Nhập loại hợp đồng mẫu",
    template_form_status_placeholder: "Chọn trạng thái",
    template_form_name_required: "Vui lòng nhập tên hợp đồng mẫu!",
    template_form_file_required: "Vui lòng chọn tệp đính kèm!",
    template_form_file_max_size: "Tệp đính kèm có dung lượng không quá 5Mb!",
    template_form_type_required: "Vui lòng nhập loại hợp đồng mẫu!",
    template_form_status_required: "Vui lòng chọn trạng thái!",
    template_form_view_file: "Xem file đính kèm",
    template_var_tb_hd_title_stt: "STT",
    template_var_tb_hd_title_var: "Biến",
    template_var_tb_hd_title_type: "Loại dữ liệu",
    template_var_tb_hd_title_varname: "Tên biến",
    template_var_tb_hd_title_mapping: "Mapping",
    convert_pdf_failed: "Chuyển định dạng PDF không thành công! Vui lòng kiểm tra lại nội dung tệp hoặc chọn tệp khác.",
    file_cant_found: "Không tìm thấy file!",
    template_create_success: "Thêm mới hợp đồng mẫu thành công!",
    template_create_confim: "Bạn chắc chắn muốn thêm mới hợp đồng mẫu này?",
    template_detail_title: "Thông tin chi tiết hợp đồng mẫu",
    template_delete_confirm: "Bạn chắc chắn muốn xóa hợp đồng mẫu này?",
    template_dalete_success: "Xóa hợp đồng mẫu thành công!",
    template_edit_title: "Cập nhật hợp đồng mẫu",
    template_edit_confirm: "Bạn chắc chắn muốn cập nhật thông tin hợp đồng mẫu?",
    template_edit_success: "Cập nhật thông tin hợp đồng mẫu thành công!",
    template_cannot_delete_isactived: "Bạn không thể xóa hợp đồng mẫu đang ở trạng thái Kích hoạt.",
    contract_not_enough_partner: "Hợp đồng phải có ít nhất 2 bên tham gia! Vui lòng thêm bên tham gia hợp đồng!",
    modal_dkdv_title: "Điều khoản dịch vụ",
    add_new: "Thêm mới",
    actions: "Thao tác",
    delete: "Xóa",
    internal_no_draft_sign: "chưa thực hiện ký nháy",
    internal_no_approve_sign: "chưa thực hiện ký duyệt",
    update_success: "Cập nhật thành công!",
    signed_approve: "đã ký duyệt",
    max_length_string: "Số lượng ký tự không được vượt quá 4000!",
    reason_reject_required: "Vui lòng nhập lý do từ chối",
    account_type_identity: "Loại tài khoản",
    internal_no_stamp_sign: "chưa thực hiện ký dấu",
    internal_stamp_signed: "đã ký dấu",
    
    confirm_logout_smartca: "Bạn chắc chắn muốn thoát phiên ký số?",
    logout_smartca_success: "Bạn đã đăng xuất phiên ký số thành công!",
    sign_form_not_allow_for_sign_multiple: "Hình thức ký không được hỗ trợ ký hàng loạt!",

    understood: "Đã hiểu",
    leave: "Rời đi",

    document_in_contract_name_required: "Vui lòng nhập tên tài liệu!",
    document_in_contract_file_required: "Vui lòng chọn file đính kèm!",
    document_in_contract_type_required: "Vui lòng nhập loại tài liệu!",
    document_in_link_required: "Vui lòng nhập đường dẫn đính kèm!",
    format_not_support: "Định dạng file chưa được hỗ trợ xem online. Vui lòng tải về!",
    required_status: "Vui lòng chọn trạng thái!",


    // Danh muc hanh chinh
    ERROR_DUPLICATE_SUBDIVISION: "Mã đơn vị đã tồn tại. Vui lòng thử lại!",
    ERROR_DUPLICATE_AREACODE: "Mã địa bàn đã tồn tại. Vui lòng thử lại!",
    reason_cancel_required: "Vui lòng nhập lý do hủy hợp đồng",
}