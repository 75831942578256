export const ConstantUrl = {
    LOGIN: '/app/login',
    HOME: '/home',
    SIGNUP_V2: '/app/signup',
    FORGOT_PASSWORD: '/app/forgot-password',
    SIGNUP_BUSINESS_VIP: '/app/signup-business-vip',
    SIGNUP_BUSINESS_SME: '/app/register-business-account',
    ACC_UPLOAD_GPKD: '/app/console/profile-manager/update-gpkd',
    SIGNUP_SUCCESS: '/app/signup-success',

    ADMIN_API_LIST: '/console/api/manager/list',
    ADMIN_API_CREATE: '/console/api/manager/create',
    ADMIN_API_DETAIL: '/console/api/manager/detail',
    ADMIN_API_EDIT: '/console/api/manager/edit',

    EINVOICE_CUSTOMER_DETAIL: '/app/console/einvoices/invoicesCustomer/detail',

    PRODUCT_MANAGER: '/app/console/products',

    CONTRACT_MANAGER: '/app/console/contract-manager',
    CONTRACT_DETAIL: '/app/console/contract',
    DOWNLOAD_CONTRACT: '/app/console/contract-download',

    CA_MANAGER: '/app/console/signature-manager',

    ENDUSER_PACKAGE_DETAIL: '/app/console/package/detail',
    ENDUSER_PACKAGE_MANAGER: '/app/console/package',

    PROFILE_URL: '/app/console/profile-manager',
    DOCUMENT_MANAGER: '/app/console/documents',
    DASHBOARD_ENDUSER: '/app/console/dashboard',

    PARTNER_CONSUMER_VERIFY: '/app/console/partner/consumer-verify',
    PARTNER_PARTY_VERIFY: '/app/console/partner/party-verify',
    PARTNER_TRUSTED: '/app/console/partner/trusted',
    PARTNER_TRUSTED_DETAIL: '/app/console/partner/trusted', 
    CONSUMER_TRUSTED_DETAIL: '/app/console/partner/trusted/consumer-detail',

    ADMIN_API_KEY_LIST: '/console/api-key/list',
    ADMIN_API_KEY_DETAIL: '/console/api-key/detail',

    ADMIN_CONTRACT_MANAGER: '/console/contract-manager',
    ADMIN_CONTRACT_CREATE: '/console/contract/create',
    ADMIN_CONTRACT_EDIT: '/console/contract/edit',
    ADMIN_CONTRACT_DETAIL: '/console/contract',
    ADMIN_REQUEST_CHANGE_PACKAGE: '/console/request-change-package',

    PART_LIST: '/app/console/parts',
    PART_ADD: '/app/console/parts/add',
    PART_DETAIL: '/app/console/parts/detail',
    PART_EDIT: '/app/console/parts/edit',

    EMPLOYEE_LIST: '/app/console/employees',
    EMPLOYEE_ADD: '/app/console/employees/add',
    EMPLOYEE_DETAIL: '/app/console/employees/detail',
    EMPLOYEE_EDIT: '/app/console/employees/edit',
    EMPLOYEE_FINISH_SIGNUP: '/app/console/employees/finish-signup',

    catalog_package_list: '/console/category-package/list',
    catalog_package_create: '/console/category-package/create',
    catalog_package_detail: '/console/category-package/detail',
    calalog_package_update: '/console/category-package/update',
    
    contract_template_list: '/app/console/contract-templates',
    contract_template_add: '/app/console/contract-templates/add',
    contract_template_edit: '/app/console/contract-templates/edit',
    contract_template_detail: '/app/console/contract-templates/detail',

    verify_signature: '/app/console/verify-signature',

    contract_lifecycle_list: '/app/console/contract-flow/list',
    contract_flow_add: '/app/console/contract-flow/add',
    contract_flow_detail: '/app/console/contract-flow/detail',
    contract_flow_edit: '/app/console/contract-flow/edit',
    
    group_permission_manager: '/app/console/group-users',

    requests_service: '/console/requests-service',
    requests_service_detail: '/console/requests-service',
    service_catalog_list: "/console/service-catalog/list",
    service_catalog_add: "/console/service-catalog/add",
    service_catalog_edit: "/console/service-catalog/edit",
    service_catalog_detail: "/console/service-catalog",

    package_add: "/console/package-manager/add",
    user_settings: 'app/console/user-settings',
    admin_add_account_business: "/console/account-manager/add/business",
    admin_edit_account_business: "/console/account-manager/edit/business",
    admin_add_account_consumer: "/console/account-manager/add/consumer",
    admin_edit_account_consumer: "/console/account-manager/edit/consumer",
    sign_multiple_contracts: "/app/console/sign-multiple-contracts",
    sign_multi_download_contract: "/app/console/sign-multi-download-contract",
    list_download_contract: "/app/console/contract-download",
    
    enduser_ekyc_config: '/app/console/ekyc-config',
    enduser_ekyc_config_update: '/app/console/ekyc-config/update',

    list_package: "/console/package-manager/list",
    package_edit: "/console/package-manager/edit",
    package_detail: "/console/package-manager/detail",
    administrative_directory: '/console/administrative-directory',
    user_manual: '/app/console/user-manual',

    contract_number_config: '/app/console/configure-contract-number',
    add_trusted_business_partner: '/app/console/partner/add-business-trusted-partner',
    add_trusted_consumer_partner: '/app/console/partner/trusted/add-consumer',

    contract_type_management: '/app/console/contract-types/list',
    add_contract_type: '/app/console/contract-types/add',
    edit_contract_type: '/app/console/contract-types/edit',
    contract_type_detail: '/app/console/contract-types/detail'

}