import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl, AbstractControlDirective, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-show-validate-errors',
  templateUrl: './show-validate-errors.component.html',
  styleUrls: ['./show-validate-errors.component.scss']
})
export class ShowValidateErrorsComponent implements OnInit {
  @Input() isSubmit: boolean = false;
  @Input() useSubmit: boolean = true;
  @Input() detail: any = {};
  @Input() private errorMessages: any = {};
  @Input() private control: AbstractControlDirective | AbstractControl;

  // <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="validateMsg" [control]="email"
  //                               [detail]="{ name: 'email' }"></app-show-validate-errors>
  constructor() { }

  ngOnInit(): void { }

  shouldShowErrors(): boolean {
    if (this.useSubmit) {
      return this.control && this.control.errors && (this.control.dirty || this.control.touched || this.isSubmit) && this.isSubmit;
    } else {
      return this.control && this.control.errors && (this.control.dirty || this.control.touched);
    }
  }

  listOfErrors(): string[] {
    return Object.keys(this.control.errors).map((field) => this.getMessage(field, this.control.errors[field]));
  }

  private getMessage(type: string, params: any) {
    // console.log(this.detail.name, " - ", type, "  -  ", params);
    return this.errorMessages[type] || '';
  }
}
