export class LandingCategoryItem{
  public id: string;
  public categoryName: string;
  public parentId: string;
  public parentName: string;
  public categoryCode: string;
  public description: string;
  public language: string;

  constructor(obj = null){
    obj = obj || {};
    this.id = obj.id || '';
    this.categoryName = obj.categoryName || '';
    this.parentId = obj.parentId || '';
    this.parentName = obj.parentName || '';
    this.categoryCode = obj.categoryCode || '';
    this.description = obj.description || '';
    this.language = obj.language || 'vi';
  }

}