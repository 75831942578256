import { PermissionGroup } from "../list-all-permission/permission-group"

export class UserGroup {
    createdDate: "2021-07-06T07:59:21.834+0000"
    description: "Nhóm test 02"
    disable: 0
    modifiedDate: "2021-07-06T07:59:21.855+0000"
    name: "ROLE_TEST_0456"
    partyId: "8ef7758c-c5ed-4546-864b-dfacc5d52883"
    roleCode: "ROLE_TEST_0456"
    uuidRole: "12869b28-de30-11eb-a8bc-b35cc35eb8f4"
    privileges: PermissionGroup[] = [];

    constructor(d = null) {
        d = d || {};

        this.createdDate = d.createdDate || "";
        this.description = d.description || "";
        this.disable = d.disable;
        this.modifiedDate = d.modifiedDate || "";
        this.name = d.name || "";
        this.partyId = d.partyId || "";
        this.roleCode = d.roleCode || "";
        this.uuidRole = d.uuidRole || "";
        if (d.privileges && d.privileges instanceof Array) {
            d.privileges.forEach((item) => {
                this.privileges.push(new PermissionGroup(item));
            })
        }
    }
}