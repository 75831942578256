// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: window['env']?.DEBUG ? false : true,
  firebase: {
    apiKey: 'AIzaSyAA1N5EmGoDwVQMn2GJ499XpGSkPd2TlVQ',
    authDomain: 'econtract-32592.firebaseapp.com',
    databaseURL: 'https://econtract-32592.firebaseio.com',
    projectId: 'econtract-32592',
    storageBucket: 'econtract-32592.appspot.com',
    messagingSenderId: '517111013569',
    appId: '1:517111013569:web:fd45393750094a2281f440',
    measurementId: 'G-LVTYYCKR7R',
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.


// <script src="https://www.gstatic.com/firebasejs/7.19.0/firebase-app.js"></script>

// <script>
//   // Your web app's Firebase configuration
//   var firebaseConfig = {
//     apiKey: "AIzaSyBjugkgnNg2k4dllpZFsnFrUkZ2Srd6gOQ",
//     authDomain: "angular-video-call.firebaseapp.com",
//     databaseURL: "https://angular-video-call.firebaseio.com",
//     projectId: "angular-video-call",
//     storageBucket: "angular-video-call.appspot.com",
//     messagingSenderId: "446404107056",
//     appId: "1:446404107056:web:ab82ae41400aaf409886f5"
//   };
//   // Initialize Firebase
//   firebase.initializeApp(firebaseConfig);
// </script>