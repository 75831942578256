<div *ngIf="type" class="">
    <div class="card shadow-none">
        <div class="card-body p-3">
            <label class="grey-label">{{ label | translate }}</label><br>
            <div class="staticstic-wrapper">
                <div><span class="staticstic-number mr-2">{{contracts}}</span></div>
                <div><span class="badge badge-success-new p-2" ngbTooltip="{{rating > 0 ? 'Tăng' : 'Giảm'}} {{rating > 0 ? rating : -rating}} hợp đồng so với cùng kỳ tháng trước">{{rating > 0 ? '+' : ''}}{{rating}}</span></div>
            </div>
        </div>
    </div>
</div>
