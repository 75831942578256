import { MainResponse } from "../main-response.model";
import { LandingConfigItem } from "./landing-config-item.model";

export class LandingConfigCollectionResponse extends MainResponse{
  data: LandingConfigItem[];
  page: number;
  maxSize: number;
  propertiesSort: string;
  totalElement: number;
  sort: string;

  constructor(obj = null) {
      super();
      obj = obj.object || {};
      this.page = obj.page || 1;
      this.maxSize = obj.maxSize || 10;
      this.propertiesSort = obj.propertiesSort || '';
      this.totalElement = obj.totalElement || 0;
      this.sort = obj.sort || '';
      this.data = []; 
      if (obj.data && obj.data instanceof Array) {
          obj.data.forEach((item) => {
              this.data.push(new LandingConfigItem(item));
          });
      }
  }
}