import { Injectable } from '@angular/core';
import { ServicePath } from 'src/app/utils/common/constant-service-api';
import { API } from '../api';

const LANDING_PAGE_URL = ServicePath.LANDING_PAGE_SERVICE + '/api/Catalog/get-data';
const BY_CATEGORY_URL = ServicePath.LANDING_PAGE_SERVICE + '/api/Catalog/get-category-by-code';
const CONFIGS_URL = ServicePath.LANDING_PAGE_SERVICE + '/api/Catalog/get-configs';
const GET_POST_BY_ID_URL = ServicePath.LANDING_PAGE_SERVICE + '/api/catalog/get-blog-by-id';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(
    private api: API
  ) { }

  // GET DATA
  getData(language = "vi") {
    const url = LANDING_PAGE_URL + `?language=${language}`;
    return this.api.get(url);
  }

  getDataByCodeCategory(key, language = "vi") {
    const url = BY_CATEGORY_URL + `?key=${key}&language=${language}`;
    return this.api.get(url);
  }

  getConfigs(language) {
    const url = CONFIGS_URL + `?language=${language}`;
    return this.api.get(url);
  }

  // Get post by id
  getPostById(id) {
    const url = GET_POST_BY_ID_URL + `?id=${id}`;
    return this.api.get(url);
  }

  // Download file
  downloadFile(url) {
    return this.api.downloadByFullUrl(url);
  }
}
