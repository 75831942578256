import { Component, ElementRef, OnInit, ViewChild, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateUtils } from 'src/app/utils/common/DateUtils';
import { REGEXP_DATE_INPUT } from 'src/app/utils/common/regexp';
import { UtilsFunc } from 'src/app/utils/common/utils-func';
import { ReportService } from 'src/app/utils/services/aas-network/report/report.service';
import { Options } from 'select2';
import { CatalogService } from 'src/app/utils/services/aas-network/catalog.service';
import { ListTinhResponse } from 'src/app/utils/models/catalog/list-tinh/list-tinh-response.model';
import { ReportQuantityV1Component } from '../../report-quantity-v1.component';
@Component({
  selector: 'app-report-quantity-v1-business',
  templateUrl: './report-quantity-v1-business.component.html',
  styleUrls: ['./report-quantity-v1-business.component.scss']
})
export class ReportQuantityV1BusinessComponent implements OnInit {
  @ViewChild('fromDatePicker') _fromDate: ElementRef;
  @ViewChild('toDatePicker') _toDate: ElementRef;

  constructor(
    private fb: FormBuilder,
    private reportService: ReportService,
    private catalog: CatalogService,
    @Inject(ReportQuantityV1Component) private parent: ReportQuantityV1Component
  ) { }

  

  ngOnInit(): void {
    this.makeFormFilter();
    this.getDmTinh();
    // this.getListCustomers();
  }
  selectedStatus: any[] = [];
  selectedAllStatus = false;
  statusOptions = ['Đã hết số lượng hợp đồng',
                    'Đã hết số lượt ký OTP',
                    'Đã hết số lượng eKYC',
                    'Đã hết thời gian lưu trữ',
                    'Đã hết dung lượng lưu trữ',
                    'Đã hết số lượt SMS',
                    'Còn lại'];
  changeStatus(e) {
    if (this.selectedStatus.length == this.statusOptions.length) this.selectedAllStatus = true;
    else this.selectedAllStatus = false;
  }

  selectAllStatus() {
    if (this.selectedAllStatus == false) {
      this.selectedStatus = [].concat(this.statusOptions);
      this.selectedAllStatus = true;
    } else {
      this.selectedStatus = [].concat([]);
      this.selectedAllStatus = false;
    }
  }

  selectedAllTinh = false;
  tinhBindData = [];

  selectedTinh: any[] = [];
  // selectedAllTinh = false;
  // statusOptions = ['Đã hết số lượng hợp đồng',
  //                   'Đã hết số lượng SMS OTP',
  //                   'Đã hết số lượng eKYC',
  //                   'Đã hết thời gian lưu trữ',
  //                   'Đã hết dung lượng lưu trữ',
  //                   'Còn lại'];
  // changeTinh(e) {
  //   if (this.selectedTinh.length == this.tinhBindData.length) this.selectedAllTinh = true;
  //   else this.selectedAllTinh = false;
  // }

  selectAllTinh() {
    if (this.selectedAllTinh == false) {
      // this.selectedTinh = [].concat(this.tinhBindData);
      
      var idAllTinh = this.tinhBindData.map(function(item) {
        return item['id'];
      });
      

      this.formFilter.get("tinhId").setValue(idAllTinh);
      this.selectedAllTinh = true;
    } else {
      this.formFilter.get("tinhId").setValue([]);
      this.selectedAllTinh = false;
    }
  }
  // selectAllTinh() {
  //   this.formFilter.get("tinhId").setValue(this.tinhBindData);
  //   // this.formFilter.value.tinhId = this.tinhBindData;
  // }

  // unselectAllTinh() {
  //   this.formFilter.get("tinhId").setValue([]);
  // }

  // List Customers
  listCustomers: Array<any> = [];
  // Đối số getListCustomers()
  keySearch = ""
  page: number = 1;
  maxSize: number = 10;
  sort = "DESC"
  // propertiesSort = "created"
  userType = "CONSUMER"
  totalElement: number = 0;

  // config col table
  listConfigColTable: Array<any> = [
    {
      label: "Tên thuê bao",
      name: "tenThueBao"
    },
    {
      label: "Mã số thuế",
      name: "maSoThue"
    },
    {
      label: "Địa chỉ",
      name: "diaChi"
    },
    {
      label: "Hình thức sử dụng",
      name: "hinhThucSuDung"
    },
    {
      label: "Nhân viên bán hàng",
      name: "nhanVienBanHang"
    },
    {
      label: "Tỉnh/TP",
      name: "tinhTP"
    },
    {
      label: "Ngày khởi tạo",
      name: "ngayKhoiTao"
    },
    {
      label: "Trạng thái",
      name: "trangThai"
    },
    {
      label: "Số lượng hợp đồng",
      name: "soLuongHopDong"
    },
    {
      label: "Số lượt SMS OTP",
      name: "soLuotSMSOTP"
    },
    {
      label: "Số lượt eKYC",
      name: "soLuotEKYC"
    },
    {
      label: "Thời gian lưu trữ(Tháng)",
      name: "thoiGianLuuTru"
    },
    {
      label: "Dung lượng lưu trữ(GB)",
      name: "dungLuongLuuTru"
    },
    {
      label: "Số lượt SMS",
      name: "soLuotSMS"
    }
  ];

  // statusOptions = ['adam', 'nicole', 'alba'];

  optionsStatus: Options;

  configColTable: any = {
    tenThueBao: true,
    maSoThue: true,
    diaChi: true,
    hinhThucSuDung: true,
    nhanVienBanHang: true,
    tinhTP: true,
    ngayKhoiTao: true,
    trangThai: true,
    soLuongHopDong: true,
    soLuotSMSOTP: true,
    soLuotEKYC: true,
    thoiGianLuuTru: true,
    dungLuongLuuTru: true,
    soLuotSMS: true
  }

  formFilter: FormGroup;
  dateType = {
    CREATED_FROM: 'CREATED_FROM',
    CREATED_TO: 'CREATED_TO'
  };
  currentDate = new Date();
  optionsCleave = {
    date: true,
    delimiter: '/',
    datePattern: ['d', 'm', 'Y']
  };
  validateSearch = {
    fromDate: {
      pattern: "Sai định dạng ngày tháng năm!",
      minDate: 'Từ ngày không được lớn hơn đến ngày và không được là ngày tương lai!'
    },
    toDate: {
      pattern: "Sai định dạng ngày tháng năm!"
    }
  };
  isSubmit: boolean = false;

  
  getDmTinh() {
    this.catalog.getListTinh().subscribe((res) => {
      let dmTinh = new ListTinhResponse(res);
      this.tinhBindData = dmTinh.object.map(item => {
        return { id: item.tinhId, label: item.tenTinh };
      });
    }, err => {
      console.log(err);
    },()=>{
      this.cleanFilter()
    });
  };

  sortColConfig = {
    tenToChuc: null,
    maSoThue: null,
    address: null,
    packageType: null,
    requesterName: null,
    tenTinh: null,
    createdDate: null,
    status: null,
  }

  priority = []


  // keySearch = "", page = 1, maxSize = 10, sort = "DESC", propertiesSort = "created",
  // userType = "CONSUMER", fromDate = "", toDate = "", packageNum = "-1"
  // -----------------------------------------------------

  get genSortInfo() {
    let arr = [];
    this.priority.forEach((item: string) => {
      let p = this.sortColConfig[item];
      let a = "";
      console.log(item);
      if (p == true) a = `${item}:DESC`;
      if (p == false) a = `${item}:ASC`;
      // if (p == null ) a = "";
      if (p != null) {
        arr.push(a);
      }
    });
    return arr;
  }

  // Lấy dữ liệu từ BE
  getListCustomers(page = 1) {
    this.reportService.getListOrgInfo(this.formFilter.value.tinhId, this.formFilter.value.fromDate, this.formFilter.value.toDate,
      this.keySearch,"BUSINESS",
       this.page, this.maxSize, this.sort, UtilsFunc.getPropertiesSort(this.sortColConfig),
       this.formFilter.value.status, this.formFilter.value.statusUse, this.formFilter.value.packageType, this.genSortInfo.toString()
    ).subscribe((res: any) => {
      this.listCustomers = res.object?.data || [];
      this.totalElement = res.object?.totalElement || 0;
      this.parent.totalElementBusiness = this.totalElement;
    })
 // GET 'http://localhost:3000//org-info?
  // tinhId=&fromDate=&toDate=&keySearch=&partyType=BUSINESS&
  // page=1&maxSize=10&sort=DESC&propertiesSort=createdDate&
  // status=N&statusUse=[0,0,0,0,0,0]&packageType'
  }
  /**
   * Tim kiem voi bo loc
   */
  searchWithFilter() {
    this.loadPage(1);
  }

  /**
   * Tai trang
   * @param page 
   */
   loadPage(page) {
    this.page = page;
    this.getListCustomers(this.page);
  }

  /**
   * Tra ve style cho span method
   * @param packageNum 
   */
   getStyleAndValueBadge(packageNum): string[] {
    if (packageNum == "0") {
      return ['badge-primary-new', 'Dùng thử'];
    }
    return ['badge-success-new', 'Chính thức'];
  }

  //MARK: Action cấu hình hiển thị cột của bảng
  actConfigColTableView(e, field) {
    e.stopPropagation();
    if (this.configColTable[field] !== null && this.configColTable[field] !== undefined) {
      this.configColTable[field] = !this.configColTable[field];
    }
  }

 /**
   * Khoi tao form filter
   */
  makeFormFilter() {
    var idAllTinh = this.tinhBindData.map(function(item) {
        return item['id'];
      });
    this.formFilter = this.fb.group({
      fromDate: new FormControl('', [Validators.pattern(REGEXP_DATE_INPUT)]),
      toDate: new FormControl('', [Validators.pattern(REGEXP_DATE_INPUT)]),
      status: new FormControl(''),
      statusUse: new FormControl(this.statusOptions),
      packageType: new FormControl(''),
      tinhId: new FormControl(idAllTinh),

    //   this.formFilter.controls.statusUse.setValue(this.statusOptions);
    // var idAllTinh = this.tinhBindData.map(function(item) {
    //   return item['id'];
    // });
    // this.formFilter.controls.tinhId.setValue(idAllTinh);
    });
  }

  getPackageTypeLabel(packageType) {
    if (packageType === "TRIAL_TYPE") {
      return "Gói thử nghiệm";
    } else if (packageType === "NORMAL_TYPE") {
      return "Gói chính thức";
    } else if (packageType === "EXTEND_TYPE") {
      return "Gói hạ tầng mở rộng";
    }
    return "";
  }

  getClassStatus(status) {
    var st = `${status}`;

    if (st === "Y" || st === "DISABLED") return "badge-danger-new";
    if (st === "N" || st === "ENABLED") return "badge-success-new";
    return "";
  }

  getTextStatus(status) {
    var st = `${status}`;

    if (st === "Y" || st === "DISABLED") return "Ngưng hoạt động";
    if (st === "N" || st === "ENABLED") return "Đang hoạt động";
    return "";
  }

  get fromDate() {
    return DateUtils.convertInputToFormat(this.formFilter.value.fromDate, DateUtils.format.API);
  }
  get toDate() {
    return DateUtils.convertInputToFormat(this.formFilter.value.toDate, DateUtils.format.API);
  }
  get status() {
    return this.formFilter.value.status;
  }

  /**
  * Thay doi ngay tren filter
  * @param value 
  * @param type 
  */
    changeDateFilter(event, type) {
    switch (type) {
      case this.dateType.CREATED_FROM: 
        this.formFilter.controls.fromDate.setValue(DateUtils.convertObjtoDate(event));
        break;
      case this.dateType.CREATED_TO:
        this.formFilter.controls.toDate.setValue(DateUtils.convertObjtoDate(event));
        break;
    }
  }

  // Thay doi trang thai tren filter
  changePackageType(event){
    this.formFilter.controls.packageType.setValue(event.target.value)
  }

  changeDisabled(event){
    this.formFilter.controls.status.setValue(event.target.value)
  }

  submitFilter() {
    this.formFilter.value.statusUse = [];
    // this.formFilter.value.statusUse.push("[");
    
    for (var i = 0; i < this.statusOptions.length; i++) {
      if (
        this.selectedStatus.findIndex((x) => {
          return x == this.statusOptions[i];
        }) >= 0
      ) {
          this.formFilter.value.statusUse.push("1");
      } else {
          this.formFilter.value.statusUse.push(0);
      }
    }

    this.isSubmit = true;

    if (this.formFilter.valid) {
        let dateFrom = this.formFilter.value.fromDate.length > 0 ? new Date(DateUtils.convertStringtoDateSearch(this.formFilter.value.fromDate)).getTime() : -1;
        let dateTo = this.formFilter.value.toDate.length > 0 ? new Date(DateUtils.convertStringtoDateSearch(this.formFilter.value.toDate)).getTime() : -1;
      if ((dateTo > 0 && dateFrom > 0 && dateFrom > dateTo)) {
        this.formFilter.controls.fromDate.setErrors({ minDate: true });
        }
    }

    if (this.formFilter.valid) {
      console.log(this.formFilter.value);
      this.searchWithFilter();
    }
  }

  cleanFilter() {
    this.resetSort()
    this.keySearch = ''
    this.isSubmit = false;
    this._fromDate ? this._fromDate.nativeElement.value = '' : '';
    this._toDate ? this._toDate.nativeElement.value = '' : '';
    this.formFilter.reset();
    this.formFilter.controls.fromDate.setValue('');
    this.formFilter.controls.toDate.setValue('');
    this.formFilter.controls.packageType.setValue('');
    this.formFilter.controls.status.setValue('');
    this.formFilter.controls.statusUse.setValue(this.statusOptions);
    var idAllTinh = this.tinhBindData.map(function(item) {
      return item['id'];
    });
    this.formFilter.controls.tinhId.setValue(idAllTinh);
    this.submitFilter()
  }

  /** 
   * Sap xem theo cot
   * @param key 
   */
   sortCol(key) {
    if (this.sortColConfig[key] == null) {
      this.sortColConfig[key] = true;
      this.addPriority(key)
    } else if (this.sortColConfig[key] == false) {
      this.sortColConfig[key] = null;
      this.removePriority(key)
    } else {
      this.sortColConfig[key] = !this.sortColConfig[key];
      this.addPriority(key)
    }

    this.loadPage(this.page);
  }

  // sắp xếp multi sort theo ưu tiên trước sau
  addPriority(key){
    !this.priority.includes(key) ? this.priority.push(key) : ''
  }
  removePriority(key){
    let ind = this.priority.findIndex(a=>a == key)
    ind != -1 ? this.priority.splice(ind,1) : ''
  }

  // reset sort khi đặt lại 
  resetSort(){
    this.priority = []
    Object.keys(this.sortColConfig).forEach(key=>this.sortColConfig[key] = null)
  }

  
  downloadSample() {
    this.reportService.exportListOrgInfo(this.formFilter.value.tinhId, this.formFilter.value.fromDate, this.formFilter.value.toDate,
      this.keySearch,"BUSINESS",
       this.formFilter.value.status, this.formFilter.value.statusUse , this.formFilter.value.packageType
    ).subscribe((res: any) => {
      const blob: any = new Blob([res], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      var url = window.URL.createObjectURL(blob);
      var anchor = document.createElement("a");
      anchor.download = `document.xlsx`;
      anchor.href = url;
      anchor.click();
      })   
  }

  checkColor(divide,total){
    let green = '#29D395'
    let red = '#FF7474'
    let yellow = "#f5a623"
    if (divide == 0 || total == 0){
      return green
    }
    if (divide/total*100 >= 75){
      return red
    }
    else if (divide/total*100 > 50){
      return yellow
    }
    return green
  }

  roundedFunc(used,total){
    if (total == 0 || total == undefined || total == null){
      return 
    }
    return '(' + Math.round(((used || 0)/total)* 100) + '%)'
  }

    
}
