<section class="card-body p-0 mb-3">
    <div class="table-responsive">
        <table class="table mb-0">
            <thead>
                <tr class="bg-tb-header border-top text-left">
                    <th>{{ 'EmployeeInPart_stt' | translate }}</th>
                    <th>{{ 'EmployeeInPart_full_name' | translate }}</th>
                    <th>{{ 'EmployeeInPart_email' | translate }}</th>
                    <th>{{ 'EmployeeInPart_phone_number' | translate }}</th>
                    <th>{{ 'EmployeeInPart_department' | translate }}</th>
                    <th>{{ 'EmployeeInPart_username' | translate }}</th>
                    <th>{{ 'status' | translate }}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of employees; let i = index" class="text-left">
                    <td>{{ (page - 1)*10 + i + 1 }}</td>
                    <td><a class="text-decoration-underline text-primary cursor-pointer" (click)="detailEmployee(item.userId)">{{ item.fullName || "" }}</a></td>
                    <td>{{ item.email || "" }}</td>
                    <td>{{ item.phoneNumber || "" }}</td>
                    <td>{{ item.partyName || "" }}</td>
                    <td>{{ item.username }}</td>
                    <td><span class="badge {{ getClassStatus(item.disable) }} p-2">{{ getTextStatus(item.disable) }}</span></td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="bg-tb-header p-3">
        <div class="row">
            <span *ngIf="totalElement == 0" class="col-md">{{ 'EmployeeInPart_no_results' | translate }}</span>
            <span *ngIf="totalElement > 0" class="col-md">{{ 'EmployeeInPart_amount_result' | translate : {from: 1, to: employees.length, total: totalElement} }}</span>
            <div class="col-md">
                <div class="d-flex justify-content-end">
                    <div class="d-flex justify-content-end align-items-center">
                        <span class="mr-2">{{ 'EmployeeInPart_number_of_lines' | translate }}:</span>
                        <ng-select [(ngModel)]="maxSize" [searchable]="false" [clearable]="false" placeholder="..." (change)="loadPage(1)">
                            <ng-option [value]="10">10</ng-option>
                            <ng-option [value]="20">20</ng-option>
                            <ng-option [value]="30">30</ng-option>
                        </ng-select>
                    </div>
                    <div *ngIf="totalElement > maxSize" class="pagination pages no-border-pagination ml-2">
                        <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="false"
                            [pageSize]="maxSize" (pageChange)="loadPage($event)" [boundaryLinks]="true" aria-label="Default pagination">
                            <ng-template ngbPaginationFirst>{{ 'EmployeeInPart_first' | translate }}</ng-template>
                            <ng-template ngbPaginationLast>{{ 'EmployeeInPart_last' | translate }}</ng-template>
                            <ng-template ngbPaginationPrevious>&laquo;</ng-template>
                            <ng-template ngbPaginationNext>&raquo;</ng-template>
                            <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                        </ngb-pagination>
                    </div>
                </div>
            </div>
            
        </div>
    </div>
</section>