import { MainResponse } from '../main-response.model';
import { ListContractTemplateObject } from './list-contract-template-object.model';

export class ListContractTemplateResponse extends MainResponse {
  object: ListContractTemplateObject;
  constructor(obj = null) {
    super();
    this.init(obj);
    this.object = new ListContractTemplateObject(obj.object);
  }
}
