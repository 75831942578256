import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { LANDING_CATEGORY_FORM } from 'src/app/utils/common/constant-vaildate-msg';
import { LandingCategoryItem } from 'src/app/utils/models/landing-page/landing-category-item.model';
import { LandingCategoryCollectionResponse } from 'src/app/utils/models/landing-page/landing-page-category-collection-response.model';
import { LanguageItem } from 'src/app/utils/models/landing-page/language-item.model';
import { LandingpageService } from 'src/app/utils/services/aas-network/landingpage.service';

@Component({
  selector: 'app-service-landing-page-category-add',
  templateUrl: './service-landing-page-category-add.component.html',
  styleUrls: ['./service-landing-page-category-add.component.scss']
})
export class ServiceLandingPageCategoryAddComponent implements OnInit {

  categories: Array<LandingCategoryItem> = [];
  languages: Array<LanguageItem> = [];
  formAddCategory: FormGroup;
  isSubmit = false;
  validCategoryMsg = LANDING_CATEGORY_FORM.CREATE;

  constructor(
    private fb: FormBuilder,
    private landingService: LandingpageService,
    private modalService: NgbModal,
    private router: Router
  ) { }

  ngOnInit(): void {    
    this.getCategories();
    this.getLanguage();
    this.makeFormAdd();
  }

  makeFormAdd(){
    this.formAddCategory = this.fb.group({
      categoryName: ['', Validators.required],
      categoryCode: [''],
      parentId: [''],
      description: [''],
      language: ['']
    });
  }

  getCategories(){
    this.landingService.getCategories()
    .subscribe(
      (res) => {
        let response = new LandingCategoryCollectionResponse(res);
        this.categories = response.data;
      },
      (err) =>{
        this.handleError(err, err);
      }
    )
  }

  onSubmit(){
    if(this.formAddCategory.valid){
      let data = this.formAddCategory.value;
      this.landingService.addCategory(data)
      .subscribe(
        (res) => {
          this.alert("Thêm mới thành công", "success", false, "", "OK", () => {
            this.router.navigate(["/console/landing-page/category"]);
          })
        },
        (err) => {
          this.handleError(err,err);
        }
      )
    }
  }

  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], 'error');
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, 'error');
    } else {
      this.alert('Lỗi không xác định! Vui lòng thử lại', 'error');
    }
  }
  getLanguage(){
    this.languages = this.landingService.getLanguage();
  }

}
