<div class="container-fluid">
    <div class="row">
        <div class="col-md-4">
            <div class="card shadow-none">
                <div class="card-body pb-0">
                    <div class="row pt-2 pb-2">
                        <div class="col-md-auto">
                            <label class="text-lg">Danh sách chờ phê duyệt</label>
                        </div>

                        <div class="input-group mt-3">
                            <form [formGroup]="searchForm" class="input-group"
                                (ngSubmit)="searchData(searchForm.value.searchInput)">
                                <div class="input-group-prepend">
                                    <button class="btn btn-primary d-inline-flex pr-2 pl-2" type="submit">
                                        <span class="material-icons">search</span>
                                    </button>
                                </div>
                                <input type="text" class="form-control" formControlName="searchInput" id="searchInput"
                                    placeholder="Nhập tài khoản cá nhân..." aria-label=""
                                    aria-describedby="basic-addon1" />
                            </form>
                        </div>
                    </div>
                </div>

                <div class="position-relative">
                    <app-mini-spinner *ngIf="loadingListUserGroupIsVisiable() | async"></app-mini-spinner>
                    <ng-template #noData>
                        <div class="no-content-list">
                            <img class="mb-2" src="assets/img/icon/icon-no-user-group.png" width="100" />
                            <p class="mb-1">
                                Không có dữ liệu.
                            </p>
                        </div>
                    </ng-template>

                    <div>
                        <div class="user-group-item content-list-item px-0 pt-2">
                            <div *ngIf="listUserGroup.length > 0; else noData" class="content-list fade show">
                                <div *ngFor="let item of listUserGroup" (click)="selectUserGroup(item)"
                                    class="user-group-item content-list-item px-4 pt-2" [class.active]="
                                        item.userId === detailUser.userId
                                    ">
                                    <div class="border-bottom d-flex">
                                        <span class="material-icons icon-item pr-3">group</span>
                                        <div class="flex-grow-1">
                                            <label class="title-item">{{
                                                item.ten !== '' ? item.ten : item.email
                                                }}</label>
                                            <p class="text-secondary">
                                                Số giấy tờ: {{ item.cmnd }}
                                            </p>
                                        </div>
                                        <!-- <a class="text-decoration-underline text-primary pl-4" style="cursor: pointer;"
                                            (click)="callVideo(item.customerId)">Gọi video</a> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="totalElement > maxSize" class="pagination justify-content-end pages">
                <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="5" [rotate]="true"
                    [ellipses]="false" [pageSize]="maxSize" (pageChange)="loadPage($event)" [boundaryLinks]="true"
                    aria-label="Default pagination">
                    <ng-template ngbPaginationFirst>Đầu</ng-template>
                    <ng-template ngbPaginationLast>Cuối</ng-template>
                    <ng-template ngbPaginationPrevious>&laquo;</ng-template>
                    <ng-template ngbPaginationNext>&raquo;</ng-template>
                    <ng-template ngbPaginationNumber let-page>{{
                        page
                        }}</ng-template>
                </ngb-pagination>
            </div>
        </div>
        <div class="col-md-8">
            <div class="card shadow-none">
                <div class="card-body pb-0 position-relative">
                    <!-- <form> -->
                    <!-- <form [formGroup]="formUserGroup"> -->
                    <div class="row pt-2 pb-0">
                        <div class="col-md-auto align-self-end">
                            <label class="text-lg">Thông tin tài khoản</label>
                        </div>
                        <div *ngIf="detailUser.userId" class="col-md-auto ml-auto">
                            <button class="btn btn-outline-primary btn-light d-inline-flex mr-3"
                                (click)="open(content)">
                                <span class="material-icons pr-2">clear</span>
                                Từ chối
                            </button>
                            <!-- (click)="approveRegistration(detailUser)" -->
                            <button class="btn btn-outline-primary btn-light d-inline-flex mr-3"
                                (click)="approveRegistration(detailUser)">
                                <span class="material-icons pr-2">done_outline</span>
                                Phê duyệt
                            </button>
                            <button disabled class="btn btn-primary d-inline-flex"
                                (click)="callVideo(detailUser.customerId)">
                                <span class="material-icons pr-2">videocam</span>Gọi video
                            </button>
                        </div>
                    </div>
                    <hr />
                    <div class="row">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-4">
                                    <p>Họ và tên</p>
                                </div>
                                <div class="col-md-8">
                                    {{ detailUser.ten }}
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-4">
                                    <p>Số CMND</p>
                                </div>
                                <div class="col-md-8">
                                    {{ detailUser.cmnd }}
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-4">
                                    <p>Ngày sinh</p>
                                </div>
                                <div class="col-md-8">
                                    {{
                                    detailUser.ngaySinh | date: 'dd/MM/yyy'
                                    }}
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-4">
                                    <p>Giới tính</p>
                                </div>
                                <div class="col-md-8">
                                    {{ detailUser.gioiTinh || '' }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <p>Nơi ĐKHKTT</p>
                                </div>
                                <div class="col-md-8">
                                    {{
                                    detailUser.diaChiThuongTru
                                    }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <p>Ngày cấp</p>
                                </div>
                                <div class="col-md-8">
                                    {{ detailUser.ngayCap | date: 'dd/MM/yyy' }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <p>Nơi cấp</p>
                                </div>
                                <div class="col-md-8">
                                    {{ detailUser.noiCap }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <p>Số điện thoại</p>
                                </div>
                                <div class="col-md-8">
                                    {{ detailUser.sdt }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <p>Email</p>
                                </div>
                                <div class="col-md-8">
                                    {{ detailUser.email }}
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <p>Ngày đăng ký</p>
                                </div>
                                <div class="col-md-8">
                                    {{ detailUser.created | date: 'dd/MM/yyy' }}
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <p>Ảnh chụp CMT</p>
                            <div class="row">
                                <div *ngIf="imageFirst" class="col-md-6">
                                    <img class="img-cmnd" [src]="imageFirst" />
                                </div>
                                <div *ngIf="imageSecond" class="col-md-6">
                                    <img class="img-cmnd" [src]="imageSecond" />
                                </div>
                            </div>
                            <p>Ảnh chụp khuôn mặt</p>
                            <div class="row">
                                <div *ngIf="url" class="col-md-6">
                                    <img class="img-cmnd" [src]="url" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- </form> -->

                    <!-- <app-mini-spinner *ngIf="loadingFormIsVisiable() | async"></app-mini-spinner> -->
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #content let-modal>
    <div #content class="modal-header border-0">
        <h5 class="modal-title" style="font-weight: 600; margin-right: auto;">Xác nhận từ chối yêu cầu</h5>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true" class="btn btn-light bg-exit-btn d-inline-flex"><span class="material-icons">
                close
            </span></span>
        </button>
    </div>
    <div class="modal-body text-left py-0">
        <form [formGroup]="inputDenyReason">
            <div class="form-group">
                <label for="denyReason">Lý do từ chối</label>
                <textarea formControlName="denyReason" id="denyReason" #denyReason
                    placeholder="Vui lòng nhập lý do từ chối phê duyệt" name="denyReason" rows="5"
                    class="form-control"></textarea>
                <app-show-errors [control]="inputDenyReason.controls.denyReason"></app-show-errors>
            </div>
        </form>
    </div>
    <div class="modal-footer border-0 d-flex justify-content-end">
        <button type="button" class="btn btn-outline-primary d-inline-flex" (click)="modal.dismiss('Cross click')">
            Đóng
        </button>
        <button ngbAutofocus type="button" class="btn btn-primary d-inline-flex ml-3"
            (click)="modal.close('Save click'); rejectRegistration(detailUser)"
            [disabled]="inputDenyReason.invalid || inputDenyReason.pending">
            Xác nhận
        </button>
    </div>
</ng-template>

<ng-template #calling let-modal>
    <div class="modal-body">
        <div class="row justify-content-center mb-3">
            <div class="">
                <div class="avater-call-modal" [ngStyle]="{backgroundImage: 'url(' + ava_img + ')'}"></div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mb-2 text-center">
                <h4><label>{{ detailUser.ten }}</label></h4>
            </div>
            <div class="col-12 mb-2 text-center">
                <span><i>Đang gọi...</i></span>
            </div>
            <div class="col-12 mb-2 text-center">
                <span class="text-danger">Cuộc gọi sẽ được tự động ghi lại</span>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="">
                <a class="end-call-modal" (click)="actBtnEndCall()">
                    <!-- <img src="assets/img/services/end-call.png"> -->
                    <div class="ring">
                        <div class="vdc-alo-phone vdc-alo-green vdc-alo-show">
                            <div class="vdc-alo-ph-circle"></div>
                            <div class="vdc-alo-ph-circle-fill"></div>
                            <div class="vdc-alo-ph-img-circle"></div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #approve let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Phê duyệt yêu cầu</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="form-group">
            <label for="denyReason">Bạn cần thực hiện cuộc gọi video trước khi phê duyệt yêu cầu
                đăng ký của khách hàng, vẫn tiến hành phê duyệt?</label>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-danger mr-2" (click)="modal.dismiss('Cross click')">
            Huỷ
        </button>
        <button ngbAutofocus type="button" class="btn btn-primary"
            (click)="modal.close('Save click'); approveRegistration(detailUser)">
            Phê duyệt
        </button>
    </div>
</ng-template>