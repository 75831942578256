<div class="pt-3">
    <section class="content">
        <div class="container-fluid">
            <div class="card shadow-none">
                <div class="card-body padding-md">
                    <h4 class="text-dark" style="text-align: center;"><label>HƯỚNG DẪN ĐĂNG KÝ KHÁCH HÀNG TỔ CHỨC TRÊN
                            WEBSITE</label> </h4>
                    <!-- [innerHTML]="data?.blogs[1]?.description" -->
                    <div class="manual-body" [innerHTML]="data?.blogs[1]?.description">

                        <!-- <div class="pb-4">
                            <p><span class="bold">Bước 1:</span> Truy cập địa chỉ website
                                <a href="https://econtract.vnpt.vn/home">https://econtract.vnpt.vn/home</a>
                            </p>
                        </div>

                        <div class="pb-4">
                            <p><span class="bold">Bước 2:</span> Chọn chức năng <span class="bold">“Đăng ký”</span> để
                                thực hiện đăng ký tài khoản</p>
                            <img class="picture pb-2"
                                src="https://storage-econtract.vnpt.vn/landingpage-dev/2021-11-23/buoc2(1).png" alt="">
                        </div>

                        <div class="pb-4">
                            <p><span class="bold">Bước 3:</span> Tại bước <span class="bold">“Chọn loại khách
                                    hàng”</span>, chọn <span class="bold">“Dịch vụ”</span> để đăng ký tài khoản khách
                                hàng tổ chức</p>
                        </div>

                        <div class="pb-4">
                            <p><span class="bold">Bước 4:</span> Nhấn nút <span class="bold">“Tiếp tục”</span> để chuyển
                                đến bước <span class="bold">“Thông tin đăng ký”</span></p>
                            <img class="picture pb-2"
                                src="https://storage-econtract.vnpt.vn/landingpage-dev/2021-11-23/buoc4(2).png" alt="">
                        </div>

                        <div class="pb-4">
                            <p><span class="bold">Bước 5:</span> Tại bước <span class="bold">“Thông tin đăng ký”</span>,
                                thực hiện nhập các thông tin phù hợp để đăng ký tài khoản khách hàng tổ chức</p>
                            <img class="picture pb-2"
                                src="https://storage-econtract.vnpt.vn/landingpage-dev/2021-11-23/buoc5(2).png" alt="">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr class="table-header">
                                            <th style="width: 120px">Tên trường</th>
                                            <th style="width: 80px">Bắt buộc</th>
                                            <th>Mô tả</th>
                                            <th>Ví dụ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Tên doanh nghiệp </td>
                                            <td style="text-align: center;">Có</td>
                                            <td>Tên doanh nghiệp của khách hàng tổ chức</td>
                                            <td>Công ty TNHH A&B</td>
                                        </tr>
                                        <tr>
                                            <td>Tên viết tắt</td>
                                            <td style="text-align: center;">Có</td>
                                            <td>Tên viết tắt của doanh nghiệp</td>
                                            <td>A&B</td>
                                        </tr>
                                        <tr>
                                            <td>Mã số thuế</td>
                                            <td style="text-align: center;">Không</td>
                                            <td>Mã số thuế của doanh nghiệp (bao gồm 10 ký tự số)</td>
                                            <td>0290520211</td>
                                        </tr>
                                        <tr>
                                            <td>Người đại diện</td>
                                            <td style="text-align: center;">Có</td>
                                            <td>Họ và tên của chủ tài khoản</td>
                                            <td>Trần Văn A</td>
                                        </tr>
                                        <tr>
                                            <td>Email</td>
                                            <td style="text-align: center;">Có</td>
                                            <td>Email của chủ tài khoản</td>
                                            <td>tranvana@yopmail.com</td>
                                        </tr>
                                        <tr>
                                            <td>Tỉnh/Thành phố</td>
                                            <td style="text-align: center;">Không</td>
                                            <td>Tỉnh/Thành phố mà chủ tài khoản sinh sống</td>
                                            <td>Đà Nẵng</td>
                                        </tr>
                                        <tr>
                                            <td>Tên tài khoản</td>
                                            <td style="text-align: center;">Có</td>
                                            <td>Tên tài khoản của khách hàng (6 đến 30 ký tự, là duy nhất trong hệ
                                                thống)</td>
                                            <td>A&B_tranvana</td>
                                        </tr>
                                        <tr>
                                            <td>Mật khẩu</td>
                                            <td style="text-align: center;">Có</td>
                                            <td>Mật khẩu của tài khoản (lớn hơn hoặc bằng 8 ký tự bao gồm ký tự hoa,
                                                thường, số và ký tự đặc biệt)</td>
                                            <td>tranvanA@12345</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="pb-4">
                            <p><span class="bold">Bước 6:</span> Nhấn nút <span class="bold">“Tiếp theo”</span> để
                                chuyển đến bước <span class="bold">“Xác minh tài khoản”</span></p>
                            <img class="picture pb-2"
                                src="https://storage-econtract.vnpt.vn/landingpage-dev/2021-11-23/buoc6(1).png" alt="">
                        </div>


                        <div class="pb-4">
                            <p><span class="bold">Bước 7:</span> Truy cập email đã nhập khi đăng ký tài khoản ở bước
                                <span class="bold">“Thông tin đăng ký”</span>, lấy OTP được hệ thống gửi về để thực hiện
                                xác minh tài khoản
                            </p>
                            <p>Có 2 trường hợp xảy ra:</p>
                            <p>- <span class="bold">Trường hợp 1:</span> Khách hàng nhận được email gửi mã OTP của hệ
                                thống</p>
                            <img class="picture pb-2"
                                src="https://storage-econtract.vnpt.vn/landingpage-dev/2021-11-23/buoc7-1(1).png"
                                alt="">
                            <p>- <span class="bold">Trường hợp 2:</span> Khách hàng chưa nhận được email gửi mã OTP của
                                hệ thống</p>
                            <p><span class="material-icons arrow_right_icon">arrow_right_alt</span> Nhấn link <span
                                    class="link-style">“Chưa nhận được mã OTP? Gửi lại OTP”</span> để hệ thống thực hiện
                                gửi lại mã OTP về email của khách hàng</p>
                            <img class="picture pb-2"
                                src="https://storage-econtract.vnpt.vn/landingpage-dev/2021-11-23/buoc7-2(1).png"
                                alt="">
                        </div>


                        <div class="pb-4">
                            <p><span class="bold">Bước 8:</span> Nhập mã OTP để hoàn thành xác minh tài khoản</p>
                            <p>Có 2 trường hợp xảy ra:</p>
                            <p>- <span class="bold">Trường hợp 1:</span> Nhập đúng mã OTP</p>
                            <img class="picture pb-2"
                                src="https://storage-econtract.vnpt.vn/landingpage-dev/2021-11-23/buoc8-1(1).png"
                                alt="">
                            <p>- <span class="bold">Trường hợp 2:</span> Nhập sai mã OTP</p>
                            <img class="picture pb-2"
                                src="https://storage-econtract.vnpt.vn/landingpage-dev/2021-11-23/buoc8-2(1).png"
                                alt="">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr class="table-header">
                                            <th style="width: 120px">Tên trường</th>
                                            <th style="width: 80px">Bắt buộc</th>
                                            <th>Mô tả</th>
                                            <th>Ví dụ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Mã OTP</td>
                                            <td style="text-align: center;">Có</td>
                                            <td>Mã OTP được gửi về email (Địa chỉ gửi email của VNPT:
                                                no-reply.econtract@vnpt.vn)</td>
                                            <td>295023</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="pb-4">
                            <p><span class="bold">Bước 9:</span> Nhấn nút <span class="bold">“Hoàn thành”</span> để hoàn
                                thành đăng ký tài khoản
                                và chuyển đến màn hình <span class="bold">“Đăng ký thành công”</span></p>
                            <img class="picture pb-2"
                                src="https://storage-econtract.vnpt.vn/landingpage-dev/2021-11-23/buoc9(1).png" alt="">
                        </div>

                        <div class="pb-4">
                            <p><span class="bold">Bước 10:</span> Nhấn vào link "<a
                                    href="https://econtract.vnpt.vn/app/login">https://econtract.vnpt.vn/app/login</a>"
                                tại màn hình <span class="bold">“Đăng ký thành công”</span> để thực hiện đăng nhập tài
                                khoản vừa đăng ký</p>
                            <img class="picture pb-2"
                                src="https://storage-econtract.vnpt.vn/landingpage-dev/2021-11-23/buoc10(1).png" alt="">
                            <p class="red-italic">**Khách hàng lưu ý bảo vệ Tài khoản và mật khẩu, tránh lộ thông tin
                                cho đối tượng bên ngoài biết và sử dụng hệ thống.</p>
                        </div> -->

                    </div>

                </div>


            </div>
        </div>
    </section>
</div>