import { localize } from "src/app/utils/localize/localize";
import { InternalDiscuss } from "./internal-discuss.model";
import { PartnerDiscuss } from "./partner-discuss.model";
import { SignFlow } from "./sign-flow.model";

export class FlowContract {
    name: string = "";
    description: string = "";
    file: any = null;
    fileName: any = "";
    type: string = null;
    discuss = false;
    internalDiscuss: InternalDiscuss[] =[];
    partnerDiscuss: PartnerDiscuss[] = [];
    signFlow: SignFlow[] = [];
    disable = false;
    contractFlowTemplateId: string = "";

    internalDiscussError: any = null;

    signFlowType = "";
    internalDiscussType = "";
    sequenceId = "";
    sequenceName = "";

    constructor(d = null) {
        d = d || {};
        this.contractFlowTemplateId = d.contractFlowTemplateId || "";
        this.name = d.name || "";
        this.file = d.file || null;
        this.fileName = d.fileName || "";
        this.type = d.type || null;
        this.discuss = d.discuss || false;
        if (d.internalDiscuss && d.internalDiscuss instanceof Array) {
            d.internalDiscuss.forEach((item) => {
                this.internalDiscuss.push(new InternalDiscuss(item))
            })
        }
        if (d.partnerDiscuss && d.partnerDiscuss instanceof Array) {
            d.partnerDiscuss.forEach((item) => {
                this.partnerDiscuss.push(new PartnerDiscuss(item))
            })
        }
        if (d.signFlow && d.signFlow instanceof Array) {
            d.signFlow.forEach((item) => {
                this.signFlow.push(new SignFlow(item))
            })
        }
        this.disable = d.disable || false;
        this.description = d.description || "";

        this.signFlowType = d.signFlowType || "";
        this.internalDiscussType = d.internalDiscussType || "";
        this.sequenceId = d.sequenceId || "";
        this.sequenceName = d.sequenceName || "";
    }
}
