import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConstantAlertBtn, ConstantAlertMsg } from 'src/app/utils/common/constant-alert-msg';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { AdministrativeDirectoryService } from 'src/app/utils/services/aas-network/administrative-directory/administrative-directory.service';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { localize } from 'src/app/utils/localize/localize';

@Component({
  selector: 'app-administrative-directory',
  templateUrl: './administrative-directory.component.html',
  styleUrls: ['./administrative-directory.component.scss']
})
export class AdministrativeDirectoryComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private administrativeDirectoryService: AdministrativeDirectoryService,
    private modalService: NgbModal,
    private myAlert: AlertControl
  ) { }

  page: number = 1;
  maxSize: number = 10000;
  propertiesSort: string = 'SUBDIVISIONCODE';
  sort: string = 'ASC';

  // List of provinces
  provinceDirectory: Array<any> = new Array();
  tempDirectory: Array<any> = new Array();

  // Province options, serve for selection box.
  // Initialized when load page.
  provinceOptions: Array<any> = new Array();

  // Form for data filtering,
  // including "subdivisionReferTo" and "keySearch".
  formFilter: FormGroup;

  // Form for adding new district.
  addDistrictForm: FormGroup;
  addDistrictFormSwitch: number = -1;
  // Form for adding new ward.
  addWardForm: FormGroup;
  addWardFormSwitch: number[] = [-1, -1];

  // Form for editing province
  editProvinceForm: FormGroup;
  editProvinceFormSwitch: number = -1;
  // Form for editing district
  editDistrictForm: FormGroup;
  editDistrictFormSwitch: number[] = [-1, -1];
  // Form for editing ward
  editWardForm: FormGroup;
  editWardFormSwitch: number[] = [-1, -1, -1]

  // Form for exporting excel file
  formExport: FormGroup;

  // Init component
  ngOnInit(): void {
    this.loadPage();
    this.makeFormFilter();
    // this.makeDistrictForm();
    // this.makeWardForm();
    // this.getProvinceDirectory();

    // draft
    // this.makeFormSearch();
  }

  // Load page function. Execute when load page.
  // Get all provinces, and build province selection box.
  loadPage() {
    // Call API get all provinces with type is PROVINCE and empty "subdivisionReferTo".
    this.administrativeDirectoryService.getDirectory("PROVINCE", "", this.page, this.maxSize, this.propertiesSort, this.sort).subscribe((res: any) => {
      // Empty list of provinces.
      this.provinceDirectory = [];
      // Get object, it is also list of provinces from api.
      const object = res?.object?.data || [];
      // Push label selection of all province into provinceOptions.
      this.provinceOptions = [];
      this.provinceOptions.push({"value": "", "label": "Tất cả"});
      // Iterate list of provinces, add extra data and push into "provinceDirectory".
      // Simultaneously, build the provinceOptions.
      object.forEach((content) => {
        const data = {
          value: content,
          child: [],
          childHidden: true,
          addChildHidden: true,
          editHidden: true,
        };
        this.provinceOptions.push({"value": content?.id, "label": content?.name});
        this.provinceDirectory.push(data);
      });
      console.log(this.provinceOptions);
    });
  }

  // Get province again. Execute after edit province success.
  reloadProvince() {
    // Call API get all provinces with type is PROVINCE and empty "subdivisionReferTo".
    this.administrativeDirectoryService.getDirectory("PROVINCE", "", this.page, this.maxSize, this.propertiesSort, this.sort).subscribe((res: any) => {
      // Empty list of provinces.
      this.provinceDirectory = [];
      // Get object, it is also list of provinces from api.
      const object = res?.object?.data || [];
      // Iterate list of provinces, add extra data and push into "provinceDirectory".
      object.forEach((content) => {
        const data = {
          value: content,
          child: [],
          childHidden: true,
          addChildHidden: true,
          editHidden: true,
        };
        this.provinceDirectory.push(data);
      });
    });
  }

  // When click on, get and display list of districts of province selected.
  toggleOnProvince(i) {
    if (this.provinceDirectory[i].child.length == 0 && this.provinceDirectory[i].childHidden == true) {
      this.getDistrictBelowProvince(i);
    }
    this.provinceDirectory[i].childHidden = !this.provinceDirectory[i].childHidden;
  }

  // Get list of districts of the province by "provinceId".
  getDistrictBelowProvince(indexProvince){
    // Get "provinceId" from index of province selected.
    const provinceId = this.provinceDirectory[indexProvince].value.id;
    // Call api get list of districts.
    this.administrativeDirectoryService.getDirectory("DISTRICT", provinceId, this.page, this.maxSize, this.propertiesSort, this.sort).subscribe((res: any) => {
      // Empty the old list.
      this.provinceDirectory[indexProvince].child = [];
      // Get object.
      const object = res?.object?.data || [];
      // Iterate object, add extra data.
      object.forEach((content) => {
        const data = {
          value: content,
          child: [],
          childHidden: true,
          addChildHidden: true,
          editHidden: true
        };
        this.provinceDirectory[indexProvince].child.push(data);
      });
    });
  }

  // When click on, get list of wards of district selected.
  toggleOnDistrict(i, j) {
    if (this.provinceDirectory[i].child[j].child.length == 0 && this.provinceDirectory[i].child[j].childHidden == true) {
      this.getWardBelowDistrict(i, j);
    }
    this.provinceDirectory[i].child[j].childHidden = !this.provinceDirectory[i].child[j].childHidden;
  }

  // Get list of wards of the district by "districtId".
  getWardBelowDistrict(indexProvince, indexDistrict) {
    // Get "districtId" from index of province and index of district.
    const districtId = this.provinceDirectory[indexProvince].child[indexDistrict].value.id;
    this.administrativeDirectoryService.getDirectory("WARD", districtId, this.page, this.maxSize, this.propertiesSort, this.sort).subscribe((res: any) => {
      // Empty the old list.
      this.provinceDirectory[indexProvince].child[indexDistrict].child = [];
      // Get object.
      const object = res?.object?.data || [];
      // Iterate object, add extra data and push in list of ward.
      object.forEach((content) => {
        const data = {
          value: content,
          editHidden: true
        }
        this.provinceDirectory[indexProvince].child[indexDistrict].child.push(data);
      });
    });
  }

  // Convert statusCode to label.
  getStatus(statusCode) {
    if (statusCode === "Y") {
      return "Đang sử dụng";
    }
    return "Ngưng sử dụng";
  }

  // Init form filter.
  makeFormFilter() {
    this.formFilter = this.fb.group({
      subdivisionReferTo: new FormControl(""),
      keySearch: new FormControl("")
    })
  }
  get subdivisionReferTo() { return this.formFilter.value.subdivisionReferTo; }
  get keySearch() { return this.formFilter.value.keySearch; }

  // Action of Apply Filter Button.
  applyFilter() {
    if (this.subdivisionReferTo == "" && this.keySearch == "") {
      this.reloadProvince();
    }
    else {
      this.administrativeDirectoryService.getDirectoryWithFilter(this.subdivisionReferTo, this.keySearch).subscribe((res: any) => {
        // Empty the old list.
        this.provinceDirectory = [];
        this.tempDirectory = [];
        // Get object, it is also administrative list with filter return from api.
        const object = res?.object || [];
        object.forEach((content) => {
          const data = {
            value: content,
            childHidden: true,
            addChildHidden: true,
            editHidden: true
          };
          this.tempDirectory.push(data);
        });
        // Convert this list to tree and assign to provinceDirectory.
        this.provinceDirectory = this.list_to_tree(this.tempDirectory);
        this.provinceDirectory.forEach(content => {
          if (content.child.length > 0) {
            content.childHidden = false;
            content.child.forEach(childContent => {
              if (childContent.child.length > 0) {
                childContent.childHidden = false;
              }
            })
          }
        })
      });
    }
  }

  // Clear all value of filter.
  clearFilter() {
    this.formFilter.controls.subdivisionReferTo.setValue("");
    this.formFilter.controls.keySearch.setValue("");
  }

  // List to tree Algorithm.
  list_to_tree(list) {
    var map = {}, node, roots = [], i;
    
    for (i = 0; i < list.length; i += 1) {
      map[list[i].value.id] = i; // initialize the map
      list[i].child = []; // initialize the children
      // extra data
    }
    
    for (i = 0; i < list.length; i += 1) {
      node = list[i];
      if (node.value.subdivisionReferTo !== "VN") {
        // if you have dangling branches check that map[node.parentId] exists
        list[map[node.value.subdivisionReferTo]].child.push(node);
      } else {
        roots.push(node);
      }
    }
    return roots;
  }

  // When click on, close previous addDistrictForm, display form at province selected (add district below province).
  // Change status of addChildHidden of this province (open or close),
  // endly move switch to current index.
  toggleAddDistrict(indexProvince) {
    if (this.addDistrictFormSwitch != -1 && this.addDistrictFormSwitch != indexProvince) {
      this.closeAddDistrictForm(this.addDistrictFormSwitch);
    }
    if (this.provinceDirectory[indexProvince].addChildHidden == true) {
      this.addDistrictForm = this.fb.group({
        maDonVi: new FormControl('', Validators.required),
        tenDvhc: new FormControl('', Validators.required),
        maDiaBan: new FormControl('', Validators.required),
        trangThai: new FormControl('Y', Validators.required)
      });
    }
    this.provinceDirectory[indexProvince].addChildHidden = !this.provinceDirectory[indexProvince].addChildHidden;
    this.addDistrictFormSwitch = indexProvince;
  }

  addDistrict(indexProvince) {
    if (this.addDistrictForm.valid) {
      const subdivisionCode = this.addDistrictForm.get('maDonVi').value;
      const type = "DISTRICT";
      const areaCode = this.addDistrictForm.get('maDiaBan').value;
      const isActive = this.addDistrictForm.get('trangThai').value;
      const name = this.addDistrictForm.get('tenDvhc').value;
      const provinceId = this.provinceDirectory[indexProvince].value.id;
      this.administrativeDirectoryService.addDistrictAndWard(subdivisionCode, type, areaCode, isActive, name, provinceId).subscribe((res: any) => {
        this.closeAddDistrictForm(indexProvince);
        this.getDistrictBelowProvince(indexProvince);
      }, err => {
        if (err.error.message == "ECT-00005001") {
          this.myAlert.showAlertOnlyNoti(localize.ERROR_DUPLICATE_SUBDIVISION, ConstantAlertType.ERROR);
        }
        else if (err.error.message == "ECT-00005003") {
          this.myAlert.showAlertOnlyNoti(localize.ERROR_DUPLICATE_AREACODE, ConstantAlertType.ERROR);
        }
      });
    };
  }

  // Change status of addChildHidden to true.
  closeAddDistrictForm(indexProvince) {
    this.provinceDirectory[indexProvince].addChildHidden = true;
  }

  // When click on, close previous addDistrictForm, display form at province selected (add district below province).
  // Change status of addChildHidden of this province (open or close),
  // endly move switch to current index.
  toggleAddWard(indexProvince, indexDistrict) {
    if (this.addWardFormSwitch[0] != -1 && this.addWardFormSwitch[1] != -1
      && (this.addWardFormSwitch[0] != indexProvince || this.addWardFormSwitch[1] != indexDistrict)
      ) {
      this.closeAddWardForm(this.addWardFormSwitch[0], this.addWardFormSwitch[1]);
    }
    if (this.provinceDirectory[indexProvince].child[indexDistrict].addChildHidden == true) {
      this.addWardForm = this.fb.group({
        maDonVi: new FormControl('', Validators.required),
        tenDvhc: new FormControl('', Validators.required),
        maDiaBan: new FormControl('', Validators.required),
        trangThai: new FormControl('Y', Validators.required)
      });
    }
    this.provinceDirectory[indexProvince].child[indexDistrict].addChildHidden
    = !this.provinceDirectory[indexProvince].child[indexDistrict].addChildHidden;
    this.addWardFormSwitch[0] = indexProvince;
    this.addWardFormSwitch[1] = indexDistrict;
  }

  addWard(indexProvince, indexDistrict) {
    if (this.addWardForm.valid) {
      const subdivisionCode = this.addWardForm.get('maDonVi').value;
      const type = "WARD";
      const areaCode = this.addWardForm.get('maDiaBan').value;
      const isActive = this.addWardForm.get('trangThai').value;
      const name = this.addWardForm.get('tenDvhc').value;
      const districtId = this.provinceDirectory[indexProvince].child[indexDistrict].value.id;
      this.administrativeDirectoryService.addDistrictAndWard(subdivisionCode, type, areaCode, isActive, name, districtId)
      .subscribe((res: any) => {
        this.closeAddWardForm(indexProvince, indexDistrict);
        this.getWardBelowDistrict(indexProvince, indexDistrict);
      }, err => {
        if (err.error.message == "ECT-00005001") {
          this.myAlert.showAlertOnlyNoti(localize.ERROR_DUPLICATE_SUBDIVISION, ConstantAlertType.ERROR);
        }
        else if (err.error.message == "ECT-00005003") {
          this.myAlert.showAlertOnlyNoti(localize.ERROR_DUPLICATE_AREACODE, ConstantAlertType.ERROR);
        }
      });
    };
  }

  // Change status of addChildHidden to true.
  closeAddWardForm(indexProvince, indexDistrict) {
    this.provinceDirectory[indexProvince].child[indexDistrict].addChildHidden = true;
  }

  // EDIT PROVINCE
  // When click on, close previous editProvinceForm, display form at province selected.
  // Change status of editHidden of this province (open or close),
  // endly move switch to current index.
  toggleEditProvince(indexProvince) {
    if (this.editProvinceFormSwitch != -1 && this.editProvinceFormSwitch != indexProvince) {
      this.closeEditProvinceForm(this.editProvinceFormSwitch);
    }
    if (this.provinceDirectory[indexProvince].editHidden == true) {
      const province = this.provinceDirectory[indexProvince];
      this.editProvinceForm = this.fb.group({
        maDonVi: new FormControl(province?.value?.subdivisionCode, Validators.required),
        tenDvhc: new FormControl(province?.value?.name, Validators.required),
        maDiaBan: new FormControl(province?.value?.areaCode),
        trangThai: new FormControl(province?.value?.isActive, Validators.required)
      });
    }
    
    this.provinceDirectory[indexProvince].editHidden
    = !this.provinceDirectory[indexProvince].editHidden;
    this.editProvinceFormSwitch = indexProvince;
  }
  editProvince(indexProvince) {
    if (this.editProvinceForm.valid) {
      const id = this.provinceDirectory[indexProvince]?.value?.id;
      const subdivisionCode = this.editProvinceForm.get('maDonVi').value;
      const name = this.editProvinceForm.get('tenDvhc').value;
      const areaCode = this.editProvinceForm.get('maDiaBan').value;
      const isActive = this.editProvinceForm.get('trangThai').value;
      this.administrativeDirectoryService.editArea(id, subdivisionCode, areaCode, isActive, name).subscribe((res: any) => {
        this.reloadProvince();
        this.closeEditProvinceForm(indexProvince);
      });
    }
  }
  closeEditProvinceForm(indexProvince) {
    this.provinceDirectory[indexProvince].editHidden = true;
  }

  // EDIT DISTRICT
  toggleEditDistrict(indexProvince, indexDistrict) {
    if (this.editDistrictFormSwitch[0] != -1
      && this.editDistrictFormSwitch[1] != -1
      && (this.editDistrictFormSwitch[0] != indexProvince
        || this.editDistrictFormSwitch[1] != indexDistrict)
      ) {
      this.closeEditDistrictForm(this.editDistrictFormSwitch[0], this.editDistrictFormSwitch[1]);
    }
    if (this.provinceDirectory[indexProvince].child[indexDistrict].editHidden == true) {
      const district = this.provinceDirectory[indexProvince].child[indexDistrict];
      this.editDistrictForm = this.fb.group({
        maDonVi: new FormControl(district?.value?.subdivisionCode, Validators.required),
        tenDvhc: new FormControl(district?.value?.name, Validators.required),
        maDiaBan: new FormControl(district?.value?.areaCode),
        trangThai: new FormControl(district?.value?.isActive, Validators.required)
      });
    }
    
    this.provinceDirectory[indexProvince].child[indexDistrict].editHidden
    = !this.provinceDirectory[indexProvince].child[indexDistrict].editHidden;
    this.editDistrictFormSwitch[0] = indexProvince;
    this.editDistrictFormSwitch[1] = indexDistrict;
  }
  editDistrict(indexProvince, indexDistrict) {
    if (this.editDistrictForm.valid) {
      const id = this.provinceDirectory[indexProvince].child[indexDistrict]?.value?.id;
      const subdivisionCode = this.editDistrictForm.get('maDonVi').value;
      const name = this.editDistrictForm.get('tenDvhc').value;
      const areaCode = this.editDistrictForm.get('maDiaBan').value;
      const isActive = this.editDistrictForm.get('trangThai').value;
      this.administrativeDirectoryService.editArea(id, subdivisionCode, areaCode, isActive, name).subscribe((res: any) => {
        this.getDistrictBelowProvince(indexProvince);
        this.closeEditDistrictForm(indexProvince, indexDistrict);
      });
    }
  }
  closeEditDistrictForm(indexProvince, indexDistrict) {
    this.provinceDirectory[indexProvince].child[indexDistrict].editHidden = true;
  }

  // EDIT WARD
  toggleEditWard(indexProvince, indexDistrict, indexWard) {
    if (this.editWardFormSwitch[0] != -1
      && this.editWardFormSwitch[1] != -1
      && this.editWardFormSwitch[2] != -1
      && (this.editWardFormSwitch[0] != indexProvince
        || this.editWardFormSwitch[1] != indexDistrict
        || this.editWardFormSwitch[2] != indexWard)
      ) {
      this.closeEditWardForm(this.editWardFormSwitch[0], this.editWardFormSwitch[1], this.editWardFormSwitch[2]);
    }
    if (this.provinceDirectory[indexProvince].child[indexDistrict].child[indexWard].editHidden == true) {
      const ward = this.provinceDirectory[indexProvince].child[indexDistrict].child[indexWard];
      this.editWardForm = this.fb.group({
        maDonVi: new FormControl(ward?.value?.subdivisionCode, Validators.required),
        tenDvhc: new FormControl(ward?.value?.name, Validators.required),
        maDiaBan: new FormControl(ward?.value?.areaCode),
        trangThai: new FormControl(ward?.value?.isActive, Validators.required)
      });
    }
    
    this.provinceDirectory[indexProvince].child[indexDistrict].child[indexWard].editHidden
    = !this.provinceDirectory[indexProvince].child[indexDistrict].child[indexWard].editHidden;
    this.editWardFormSwitch[0] = indexProvince;
    this.editWardFormSwitch[1] = indexDistrict;
    this.editWardFormSwitch[2] = indexWard;
  }
  editWard(indexProvince, indexDistrict, indexWard) {
    if (this.editWardForm.valid) {
      const id = this.provinceDirectory[indexProvince].child[indexDistrict].child[indexWard]?.value?.id;
      const subdivisionCode = this.editWardForm.get('maDonVi').value;
      const name = this.editWardForm.get('tenDvhc').value;
      const areaCode = this.editWardForm.get('maDiaBan').value;
      const isActive = this.editWardForm.get('trangThai').value;
      this.administrativeDirectoryService.editArea(id, subdivisionCode, areaCode, isActive, name).subscribe((res: any) => {
        this.getWardBelowDistrict(indexProvince, indexDistrict);
        this.closeEditWardForm(indexProvince, indexDistrict, indexWard);
      });
    }
  }
  closeEditWardForm(indexProvince, indexDistrict, indexWard) {
    this.provinceDirectory[indexProvince].child[indexDistrict].child[indexWard].editHidden = true;
  }

  // EXPORT EXCEL FUNCTION
  exportExcel(subdivisionReferTo, type, option) {
    this.administrativeDirectoryService.exportExcel(subdivisionReferTo, type, option)
    .subscribe((res: any) => {
      const blob: any = new Blob([res], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      var url = window.URL.createObjectURL(blob);
      var anchor = document.createElement("a");
      anchor.download = `document.xlsx`;
      anchor.href = url;
      anchor.click();
    },err => {
      this.handleError(err, err);
    })
  }
  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], ConstantAlertType.ERROR);
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, ConstantAlertType.ERROR);
    } else {
      this.alert(ConstantAlertMsg.DEFAULT_ERROR_REQUEST, ConstantAlertType.ERROR);
    }
  }
  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = ConstantAlertBtn.OK, actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = ConstantAlertBtn.CANCEL, actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }
  

  // SORTCOL CONFIG
  sortColConfig = {
    SUBDIVISIONCODE: null,
    NAME: null,
    AREACODE: null,
    ISACTIVE: null,
    DEFAULT: null
  }

  currentKey: string = 'DEFAULT';

  sortCol(key) {
    if (this.sortColConfig[key] == null) {
      this.sortColConfig[key] = true;
      if (this.currentKey != key) {
        this.sortColConfig[this.currentKey] = null;
      }
      this.propertiesSort = key;
      this.sort = "ASC";
    }
    else if (this.sortColConfig[key] == true) {
      this.sortColConfig[key] = false;
      this.propertiesSort = key;
      this.sort = "DESC";
    }
    else if (this.sortColConfig[key] == false) {
      this.sortColConfig[key] = null;
      this.propertiesSort = "";
      this.sort = "";
    }

    this.currentKey = key;

    this.reloadProvince();
  }
}
