import { Injectable } from '@angular/core';
import { API } from "../api";
import { ServicePath } from '../../common/constant-service-api';


const LIST_PACKAGE_REPORT = ServicePath.REPORT_SERVICE + '/quanity-by-packge?';
const LIST_SERVICE_REPORT = ServicePath.REPORT_SERVICE + '/number-by-function?';
const LIST_CONTRACT_REPORT = ServicePath.REPORT_SERVICE + '/contract-by-packge?';
const LIST_CONTRACT_DETAIL_REPORT = ServicePath.REPORT_SERVICE +  '/contract-detail-by-filter?';
const DASHBOARD_ENDUSER = ServicePath.REPORT_SERVICE + '/quanity-info/'
const DROPLIST_PACKAGE = ServicePath.MANAGEMENT_SERVICE + '/package-information-service?'
@Injectable({
  providedIn: 'root'
})

export class AasQuantityReportService {

  constructor(
    private api: API
  ) { }

    /**
   * GET Request lấy danh sách danh sách các gói của hệ thống
   * @param userType 
   * @param packageType 
   * @param status 
   * @param fromDate 
   * @param toDate 
   */
  getListPackage( userType, packageType, status,fromDate,toDate) {
    const url = LIST_PACKAGE_REPORT + `partyType=${userType}&packageId=${packageType}&status=${status}&fromDate=${fromDate}&toDate=${toDate}`;
    return this.api.get(url);
  }

    /**
   * GET Request lấy danh sách các dịch vụ hệ thống đã sử dụng
   * @param userType 
   * @param packageType 
   * @param status 
   * @param fromDate 
   * @param toDate 
   */
  getListService( fromDate,toDate) {
    const url = LIST_SERVICE_REPORT + `fromDate=${fromDate}&toDate=${toDate}`;
    return this.api.get(url);
  }

    /**
   * GET Request lấy danh sách hợp đồng
   * @param userType 
   * @param packageType 
   * @param status 
   * @param fromDate 
   * @param toDate 
   */
  getListContract( userType, packageType, status,fromDate,toDate) {
    const url = LIST_CONTRACT_REPORT + `partyType=${userType}&packageId=${packageType}&status=${status}&fromDate=${fromDate}&toDate=${toDate}`;
    return this.api.get(url);
  }

    /**
   * GET Request lấy danh sách chi tiết hợp đồng
   *  @param page 
   * @param maxSize 
   * @param sort 
   * @param propertiesSort 
   * @param userType 
   * @param packageType 
   * @param status 
   * @param fromDate 
   * @param toDate 
   */
  getListContractDetail( page,maxSize,userType,sort,propertiesSort, packageType, status, fromDate, toDate, keySearch) {
    const url = LIST_CONTRACT_DETAIL_REPORT + `partyType=${userType}&packageId=${packageType}&currentStage=${status}&fromDate=${fromDate}&toDate=${toDate}&page=${page}&maxSize=${maxSize}&sort=${sort}&propertiesSort=${propertiesSort}&keySearch=${keySearch}`;
    return this.api.get(url);
  }

    /**
   * GET Request lấy dữ liệu dashboard enduser
   */
  getDashboarEnduser(partyId) {
    const url = DASHBOARD_ENDUSER + `${partyId}`;
    return this.api.get(url);
  }

    /**
   * GET Request danh sách gói dịch vụ
   */
  getDropListPackage() {
    const url = DROPLIST_PACKAGE;
    return this.api.get(url);
  }


  }