import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { localize } from 'src/app/utils/localize/localize';
import { DocumentInContractService } from 'src/app/utils/services/aas-network/document-in-contract/document-in-contract.service';
import { AuthService } from 'src/app/utils/services/auth.service';

@Component({
  selector: 'app-list-document',
  templateUrl: './list-document.component.html',
  styleUrls: ['./list-document.component.scss']
})
export class ListDocumentComponent implements OnInit {

  @Input() documents: Array<any> = new Array();
  @Input() isOwnerParty: boolean = null;
  @Output() editDocument = new EventEmitter<any>();
  @Input() contractId: string = "";
  @Output() parentChangeToEdit = new EventEmitter<any>();
  @Output() parentReload = new EventEmitter();
  @Output() detailDocument = new EventEmitter<string>();

  constructor(
    private documentInContractService: DocumentInContractService,
    private myAlert: AlertControl,
    private authService: AuthService,
    private translate: TranslateService
  ) { }

  selectedDocument: string = "";

  viewDetail: boolean = false;

  ngOnInit(): void {

  }

  documentTypeConst = {
    "OTHER": "EditDocument_label_1",
    "REPORT": "EditDocument_label_2",
    "HANDOVER": "EditDocument_label_3",
    "LIQUIDATE": "EditDocument_label_4",
    "ADDENDUM": "EditDocument_label_5",
  }

  clickDocument(doc) {
    if (doc.mimeType == null) {
      if (this.selectedDocument == doc.documentId) {
        this.selectedDocument = "";
      }
      else {
        window.open(doc.originalName, "_blank")
        this.selectedDocument = doc.documentId;
      }
    }
    else if (doc.originalName.slice(-3) == "pdf" || doc.originalName.slice(-3) == "PDF") {
      if (this.selectedDocument == doc.documentId) {
        this.selectedDocument = "";
      }
      else {
        this.selectedDocument = doc.documentId;
      }
      this.detailDocument.emit(this.selectedDocument);
    }
    else {
      this.myAlert.showAlert(this.translate.instant('ListDocument_format_not_support'), "", true, "", this.translate.instant('btn_download'), () => {
        this.downloadDocument(doc.documentId, doc.originalName);
      }, "", this.translate.instant('btn_cancel'), () => {}, this.translate.instant('ListDocument_noti'))
    }
  }

  goToEditDocument(document) {
    this.editDocument.emit(document);
    this.parentChangeToEdit.emit();
  }

  deleteDocument(documentId) {
    this.documentInContractService.deleteDocument(this.contractId, documentId).subscribe((res: any) => {
      this.parentReload.emit();
    }, err => {
      this.myAlert.showErrorHandled(err);
    });
  }

  downloadDocument(documentId, originalName) {
    this.documentInContractService.downloadDocument(this.contractId, documentId).subscribe((res: any) => {
      const blob: any = new Blob([res]);
      var url = window.URL.createObjectURL(blob);
      var anchor = document.createElement("a");
      anchor.download = `${originalName}`;
      anchor.href = url;
      anchor.click();
    }, err => {

    })
  }

  checkDocCreator(docUserId): boolean {
    if (this.authService.userId == docUserId) return true;
    return false;
  }

  getDocumentTypeLabel(documentType: string) {
    if (this.documentTypeConst[documentType]) {
      return this.documentTypeConst[documentType];
    }
    return documentType;
  }
}
