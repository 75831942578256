<app-spinner></app-spinner>
<div class="content-header">
    <div class="container-fluid">
    <!-- /.row -->
    <h1 class="m-0 text-dark"><label>Danh sách yêu cầu chuyển đổi dịch vụ</label></h1>
    
    </div>
    <!-- /.container-fluid -->
  </div>
  <div class="content">
        <div class="container-fluid">
            <div class="card shadow-none mb-4">
                <div class="card-body">
                    <section class="p-3">
                        <div class="row">
                            <div class="col-lg-3 col-md-5">
                                <form   class="input-group border rounded mb-3">
                                  <div class="input-group-prepend border-0">
                                      <button class="btn d-inline-flex pr-2 pl-2" type="submit"><span
                                              class="material-icons">search</span></button>
                                  </div>
                                  <input type="text" class="form-control border-0"
                                  placeholder="Nhập tên gói dịch vụ hoặc mô tả..."   [formControl]="searchFields"
                                      aria-describedby="basic-addon1" #searchFieldPackage>
                                </form>
                                </div>
                            <div class="col-md-auto ml-auto">
                                <div  class="btn-group">
                                    <button class="btn btn-outline-primary dropdown-toggle d-inline-flex align-items-center mr-3"
                                        data-toggle="dropdown" id="configColTable"><span class="material-icons mr-1">visibility</span>Hiển thị</button>
                                    <div class="dropdown-menu border-0" aria-labelledby="configColTable" style="width: 190px;">
                                        <div *ngFor="let item of listConfigColTable" class="tcheck-primary d-inline-block px-3">
                                            <input type="checkbox" id="{{ item.name }}" [checked]="configColTable[item.name]">
                                            <label for="{{ item.name }}" class="w-100" [class.checked]="configColTable[item.name]"
                                                (click)="actConfigColTableView($event, item.name)">
                                                {{ item.label }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                               
                                <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="cleanFilter()"> Đặt lại</button>
                                <button class="btn btn-primary d-inline-flex mr-3" (click)="searchWithFilter()"> Áp dụng</button>
                            </div>
                        </div>
                        <hr class="my-2">
                    </section>
                    <section class="px-3 my-2">
                        <form [formGroup]="formFilter">
                            <div class="row">
                                <div class="col-md-2" >
                                    <div class="form-group">
                                        <label for="statusGCS">Loại khách hàng</label>
                                        <ng-select2 formControlName="userType" [data]="dropListsUserType" 
                                        [options]="options" [placeholder]="'Chọn loại khách hoàng'"></ng-select2>
                                    </div>
                                </div>
                                <div class="col-md-2" >
                                    <div class="form-group">
                                        <label for="">Từ ngày yêu cầu</label>
                                        <div class="input-group">
                                            <input #fromDatePicker class="form-control" [control]="formFilter.controls.fromDate"
                                            [value]="formFilter.value.fromDate"
                                            (dateSelect)="changeDateFilter($event, dateType.CREATED_FROM)"
                                            placeholder="dd/MM/yyyy" ngbDatepicker #createdFromDate="ngbDatepicker"
                                            [maxDate]="{year: currentDate.getFullYear(), month: currentDate.getMonth()+1, day: currentDate.getDate()}"
                                            appInputMark [options]="optionsCleave">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary" (click)="createdFromDate.toggle()"
                                                type="button"><em class="fas fa-calendar-alt"></em></button>
                                        </div>
                                        <app-show-validate-errors [useSubmit]="false"
                                            [errorMessages]="validateSearch.fromDate"
                                            [control]="formFilter.controls.fromDate"
                                            [detail]="{ name: 'fromDate' }">
                                        </app-show-validate-errors>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2"  >
                                    <div class="form-group">
                                        <label for="">Đến ngày yêu cầu</label>
                                        <div class="input-group">
                                            <input #toDatePicker class="form-control" [control]="formFilter.controls.toDate"
                                            [value]="formFilter.value.toDate"
                                            (dateSelect)="changeDateFilter($event, dateType.CREATED_TO)"
                                            placeholder="dd/MM/yyyy" ngbDatepicker #createdToDate="ngbDatepicker"
                                            [maxDate]="{year: currentDate.getFullYear(), month: currentDate.getMonth()+1, day: currentDate.getDate()}"
                                            appInputMark [options]="optionsCleave">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary" (click)="createdToDate.toggle()"
                                                type="button"><em class="fas fa-calendar-alt"></em></button>
                                        </div>
                                        <app-show-validate-errors [useSubmit]="false"
                                            [errorMessages]="validateSearch.toDate"
                                            [control]="formFilter.controls.toDate"
                                            [detail]="{ name: 'toDate' }">
                                        </app-show-validate-errors>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2" >
                                    <div class="form-group">
                                        <label for="statusGCS">Gói đăng ký mới</label>
                                        <ng-select2 formControlName="packageNewType" [data]="packageNewData | async" 
                                        [options]="options" [placeholder]="'Chọn gói đăng ký mới'"></ng-select2>
                                    </div>
                                </div>
                               
                            </div>
                        </form>
                    </section>
                </div>
            </div>
            <div class="card shadow-none mb-4">
                    <div class="card-body">
                        <table id="header-table" class="table header-table border">
                            <thead>
                                <tr >
                                    <th class="table-sticky-col1" scope="col">STT</th>
                                    <th scope="col" [class.d-none]="!configColTable.tenKhachHang">Tên khách hàng</th>
                                    <th scope="col" [class.d-none]="!configColTable.goiDangSuDung">Gói đang sử dụng</th>
                                    <th scope="col" [class.d-none]="!configColTable.goiDangKyMoi">Gói đăng ký mới</th>
                                    <th scope="col" [class.d-none]="!configColTable.loaiKhachHang">Loại khách hàng</th>
                                    <th scope="col" [class.d-none]="!configColTable.ngayYeuCau">Ngày yêu cầu</th>
                                    <th scope="col" [class.d-none]="!configColTable.trangThai">Trạng thái</th>
                                    <th scope="col" >Thao tác</th>
                                </tr>
                            </thead>
                        </table>
                        <div id="table-scroll" class="table table-scroll table-responsive">
                            <table id="main-table" class="table main-table border">
                            <tbody >
                                <tr  *ngFor="let item of listRequest, let i = index" >
                                    <td class="table-sticky-col1" scope="col">{{i+1}}</td>
                                    <td  [class.d-none]="!configColTable.tenKhachHang">{{item.tenKhachHang}}</td>
                                    <td [class.d-none]="!configColTable.goiDangSuDung">{{item.goiDangSuDung}}</td>
                                    <td [class.d-none]="!configColTable.goiDangKyMoi">{{item.goiDangKyMoi}}</td>
                                    <td [class.d-none]="!configColTable.loaiKhachHang">{{userTypes[item.loaiKhachHang].lable || ''}}</td>
                                    <td  [class.d-none]="!configColTable.ngayYeuCau">{{item.ngayYeuCau | date:'dd/MM/yyyy'}}</td>
                                    <td  [class.d-none]="!configColTable.trangThai" >
                                        <span class="badge {{ status[item.trangThai].style || '' }} p-2 ">{{status[item.trangThai].label || ''}}</span>
                                    </td> 
                                    <td  >
                                        <div class="dropdown"  *ngIf="item.trangThai === 'APPROVE_CONTRACT' || item.trangThai === 'WAIT'">
                                            <a class="btn btn-dropdown dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <em class="material-icons">more_vert</em>
                                            </a>
                        
                                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink"   > 
                                                <a *ngIf="item.trangThai === 'APPROVE_CONTRACT' "
                                                class="dropdown-item nav-material" style="cursor: pointer;" [routerLink]="['/console/contract', item.contractId]" 
                                                routerLinkActive="router-link-active">
                                                    <em class="material-icons">info</em>               
                                                    Thông tin hợp đồng</a>
                                                <div *ngIf = "item.trangThai === 'WAIT' ">
                                                    <a class="dropdown-item nav-material" style="cursor: pointer;" (click)="openModalConfirmInfo(confirmInfo, item)"
                                                        routerLinkActive="router-link-active">
                                                        <em class="ct-icon-font icon-icon-add-doc"></em>
                                                        Tạo hợp đồng</a>
                                                    
                                                    <a class="dropdown-item nav-material" style="cursor: pointer;" (click)="openModalCancel(confirmCancel, item)">
                                                        <em class="material-icons">cancel</em>
                                                        Từ Chối</a>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            </table>
                        </div>
                    </div>
            </div>

            <div class="d-flex">
                <span *ngIf="totalElement == 0" class="flex-grow-1 ml-1">Không tìm thấy kết quả</span>
                <span *ngIf="totalElement > 0" class="flex-grow-1 ml-1">Hiển thị 1 đến {{listRequest.length}} trong tổng số
                  {{totalElement}} kết quả</span>
                <div *ngIf="totalElement > maxsize" class="pagination justify-content-end pages">
                  <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="10" [rotate]="true" [ellipses]="false"
                    [pageSize]="maxsize" (pageChange)="loadPage($event)" [boundaryLinks]="true" aria-label="Default pagination">
                    <ng-template ngbPaginationFirst>Đầu</ng-template>
                    <ng-template ngbPaginationLast>Cuối</ng-template>
                    <ng-template ngbPaginationPrevious>&laquo;</ng-template>
                    <ng-template ngbPaginationNext>&raquo;</ng-template>
                    <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
                  </ngb-pagination>
                </div>
              </div>
        </div>
  </div>

  <ng-template #confirmInfo let-c="close" let-d="dismiss">
    <div class="modal-header border-0 pb-0 mb-0">
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span class="material-icons">
                cancel
            </span>
        </button>
    </div>
    <div class="modal-body py-0">
        <label class="modal-title w-100 mb-3" id="modal-basic-title">Nhập mã xác nhận OTP</label>
        <p>Bạn đang thực hiện tạo hợp đồng cho yêu cầu chuyển đổi dịch vụ của khách hàng <label>{{tenKhachHang}}.</label> 
            Vui lòng kiểm tra lại thông tin tạo hợp đồng</p>
        <form [formGroup]="formConfirmInfo">
            <div class="form-group">
                <label>Gói dịch vụ đang sử dụng </label>
                <input formControlName="packageOld" class="form-control" type="text" placeholder="" disabled>
            </div>
            <div class="form-group">
                <label>Gói dịch vụ đăng ký mới </label>
                <input formControlName="packageNew" class="form-control" type="text" placeholder="" disabled>
            </div>
        </form>
    </div>
    <div class="modal-footer border-0 d-flex justify-content-end">
        <button type="button" class="btn btn-primary-outlined" (click)="d('Cross click')">Hủy</button>
        <button type="button" class="btn btn-primary"  (click)="openContract()"
        >Tiếp tục</button>
    </div>
</ng-template>

<ng-template #confirmCancel let-c="close" let-d="dismiss">
    <div class="modal-header border-0 pb-0 mb-0">
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span class="material-icons">
                cancel
            </span>
        </button>
    </div>
    <div class="modal-body py-0">
        <label class="modal-title w-100 mb-3" id="modal-basic-title">Từ chối yêu cầu</label>
        <p> Vui lòng nhập lý do từ chối yê cầu chuyển đổi dịch vụ của khách hàng "{{tenKhachHang}}"</p>
        <form [formGroup]="formCancel">
            <div class="form-group">
                <label>Gói dịch vụ đăng ký mới </label>
                <textarea formControlName="description" class="form-control" type="text" placeholder="Nhập lý do từ chối yêu cầu">
               </textarea>
            </div>
        </form>
    </div>
    <div class="modal-footer border-0 d-flex justify-content-center">
        <button type="button" class="btn btn-primary" (click)="d('Cross click')">Hủy</button>
        <button type="button" class="btn btn-primary" (click)="cancelRequest()">Xác nhận</button>
    </div>
</ng-template>
