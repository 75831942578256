import { Injectable } from '@angular/core';
import { ServicePath } from '../../common/constant-service-api';
import { ListToChucNoiBoResponse } from '../../models/auth/list-tochuc-noibo/list-tochuc-noibo-response.model';
import { API } from '../api';

const REGISTER_DEVICE = ServicePath.MANAGEMENT_SERVICE + '/notifications/add-device-token';
const REMOVE_DEVICE = ServicePath.MANAGEMENT_SERVICE + '/notifications/remove-device-token';
const GET_ALL_NOTI = ServicePath.MANAGEMENT_SERVICE + '/notifications';
const GET_DETAIL_NOTI = ServicePath.MANAGEMENT_SERVICE + '/notifications';
const GET_NUMBER_UNREAD = ServicePath.MANAGEMENT_SERVICE + '/notifications/number-unread';
const MARK_AS_READ = ServicePath.MANAGEMENT_SERVICE + '/notifications';
const MARK_AS_UNREAD = ServicePath.MANAGEMENT_SERVICE + '/notifications';
const MARK_ALL_AS_READ = ServicePath.MANAGEMENT_SERVICE + '/notifications/mark-all-as-read';
const DETELE_NOTI_BY_ID = ServicePath.MANAGEMENT_SERVICE + '/notifications';
const DELETE_ALL_NOTI = ServicePath.MANAGEMENT_SERVICE + '/notifications';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private api: API) { }

  /**
   * Dang ky thiet bi nhan thong bao
   * @param param 
   */
  postRegisterDevice(param) {
    const url = REGISTER_DEVICE;
    return this.api.post(url, param);
  }

  /**
   * Xoa thiet bi nhan thong bao
   * @param param 
   */
  deleteDevice(param) {
    const url = REMOVE_DEVICE;
    return this.api.delete(url, param);
  }

  /**
   * Lay danh sach notifications
   * @param page 
   * @param maxSize 
   * @param sort 
   * @param propertiesSort 
   */
  getAllNotifications(page = 1, maxSize = 10, sort = 'DESC', propertiesSort = 'sentAt') {
    const url = GET_ALL_NOTI + `?page=${page}&maxSize=${maxSize}&sort=${sort}&propertiesSort=${propertiesSort}`;
    return this.api.get(url);
  }

  /**
   * Lay chi tiet 1 thong bao
   * @param id 
   */
  getNotificationDetail(id) {
    const url = GET_DETAIL_NOTI + `/${id}`;
    return this.api.get(url);
  }

  /**
   * Lay so luong thong bao chua doc
   */
  getNumberUnread() {
    const url = GET_NUMBER_UNREAD;
    return this.api.get(url);
  }

  /**
   * Danh dau da doc thong bao
   * @param id 
   */
  postMarkAsRead(id) {
    const url = MARK_AS_READ + `/${id}/mark-as-read`;
    return this.api.post(url, null);
  }

  /**
   * Danh dau chua doc thong bao
   * @param id 
   */
  postMarkAsUnread(id) {
    const url = MARK_AS_UNREAD + `/${id}/mark-as-unread`;
    return this.api.post(url, null);
  }

  /**
   * Danh dau tat ca da doc
   */
  postMarkAllAsRead() {
    const url = MARK_ALL_AS_READ;
    return this.api.post(url, null);
  }

  /**
   * Xoa thong bao
   * @param id 
   */
  deteleNotification(id) {
    const url = DETELE_NOTI_BY_ID + `/${id}`;
    return this.api.delete(url);
  }

  /**
   * Xoa tat ca thong bao
   */
  deleteAllNotification() {
    const url = DELETE_ALL_NOTI;
    return this.api.delete(url);
  } 
}
