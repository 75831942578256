<app-spinner></app-spinner>
<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-auto">
                <h1 class="m-0 text-dark"><label>{{ 'ContractTypes_detail_title' | translate }}</label></h1>
            </div>
            <div class="col-md-auto ml-auto">
                <button class="btn btn-primary d-inline-flex align-item-center" (click)="edit(id)">
                    <span class="material-icons mr-1">
                        edit
                    </span>{{ 'btn_edit' | translate }}</button>
            </div>
        </div>
    </div>
</div>

<section class="content">
    <div class="container-fluid">
        <div class="bg-white">
            <section class="p-3">
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label>{{ 'ContractTypes_name' | translate }}</label>
                            <input class="form-control" [(ngModel)]="nameType" type="text" disabled>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-3">
                        <div class="form-group">
                            <label>{{ 'status' | translate }} <span class="text-danger">*</span></label>
                            <ng-select [(ngModel)]="status" [items]="statusOptions" bindValue="value"
                                [searchable]="false" [clearable]="false" [readonly]="true">
                                <ng-template ng-label-tmp let-item="item">
                                    <span>{{ item.label | translate }}</span>
                                </ng-template>
                                <ng-template ng-option-tmp let-item="item">
                                    <span>{{ item.label | translate }}</span>
                                </ng-template>
                            </ng-select>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</section>