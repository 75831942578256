import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UtilsFunc } from 'src/app/utils/common/utils-func';
import { SignBox } from 'src/app/utils/models/aas-models/signbox/sign-box';
import { UserSignBox } from 'src/app/utils/models/aas-models/signbox/user-sign-box';

declare var PDFSign: any;
declare var default_img: any;
@Component({
  selector: 'app-review-sign-box',
  templateUrl: './review-sign-box.component.html',
  styleUrls: ['./review-sign-box.component.scss']
})
export class ReviewSignBoxComponent implements OnInit {
  @Input()
  set pdfFile(value) { this._pdfFile = value; }
  get pdfFile() { return this._pdfFile; }
  _pdfFile = null;
  @Input()
  set title(value) { this._title = value; }
  get title() { return this._title; }
  _title = "";
  @Input()
  set signBoxs(value) { this._signBoxs = value; }
  get signBoxs(): SignBox[]  { return this._signBoxs; }
  _signBoxs: SignBox[] = [];
  @Input()
  set imageSign(value) { this._imageSign = value || default_img; }
  get imageSign() { return this._imageSign; }
  _imageSign = default_img;

  @Output('saveChanged') saveChanged: EventEmitter<any> = new EventEmitter(); 
  @Output('cancel') cancel: EventEmitter<any> = new EventEmitter(); 

  userSignBox: UserSignBox = new UserSignBox();

  constructor() { 

  }

  ngOnInit(): void {
    if (this.pdfFile) {
      PDFSign.preview(this.pdfFile);
    }

    this.signBoxs.forEach((x) => { x.id = UtilsFunc.uuidv4(); });
    this.userSignBox = new UserSignBox({
      signBoxes: this.signBoxs
    });

    /** Hiển thị danh sách chữ ký của hàng được chọn */
    setTimeout(() => {
      this.userSignBox.showListSignBox();
    }, 100)
  }

  goToBack() {
    this.cancel.emit(null);
  }

  actClose() {
    this.cancel.emit(null);
  }

  actBtnSave() {
    this.saveChanged.emit(this.userSignBox.signBoxes);
  }

  /**
   * Cuộn đến vị trí chữ ký cần xem
   * @param signBox 
   */
  viewPositionSignBox(signBox: SignBox) {
    console.log("viewPositionSignBox: ", signBox);
    var e = document.getElementById("dragSignature_" + signBox.id);
    e.scrollIntoView();
    e.classList.add('position-signbox');
    setTimeout(() => {
      e.classList.remove('position-signbox');
    }, 1400)
  }
}
