export class EkycConfig {
    code: string = "";
    description: string = "";
    key: string = "";
    nameField: string = "";
    status: any = false;
    success: any = 0;
    typeField: string = "";
    warning: any = 0;

    constructor(d = null) {
        d = d || {};
        this.code = d.code || "";
        this.description = d.description || "";
        this.key = d.key || "";
        this.nameField = d.nameField || "";
        this.status = d.status;
        this.success = d.success;
        this.typeField = d.typeField || "";
        this.warning = d.warning;
    }
}