import { Component, OnInit,ViewChild,ElementRef,Directive } from '@angular/core';
import { Options } from 'select2';
import { Select2OptionData } from 'ng-select2';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { ServiceMsg } from 'src/app/utils/common/validate-msg-aas';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { NgModalComponent } from 'src/app/layout/extensions/ng-modal/ng-modal.component';
import { AasEinvoicesService } from 'src/app/utils/services/aas-network/aas-einvoices.service';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-account-einvoices-create',
  templateUrl: './account-einvoices-create.component.html',
  styleUrls: ['./account-einvoices-create.component.scss']
})
export class AccountEinvoicesCreateComponent implements OnInit {


  validateMsg = ServiceMsg.EINVOICES_CUSTOMER_CREATE;
  isSubmit: boolean = false;
  form : FormGroup;
  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private einvoicesService: AasEinvoicesService,
    private router: ActivatedRoute,
    private route: Router,

  ) { }

  ngOnInit(): void {
    this.makeFormCustomerInvoice();
  }

  makeFormCustomerInvoice(){
    this.form = this.fb.group({
      companyName : ['',Validators.required],
      taxCode: ['',Validators.required],
      accountInvoice: ['',Validators.required],
      passwordInvoice: ['',Validators.required],
      url: ['',Validators.required],
      serviceAccount: ['',Validators.required],
      servicePassword: ['',Validators.required],
      pattern: ['',Validators.required],
      serial: ['',Validators.required]
    })
  }

  get companyName() {
    return this.form.get('companyName');
  }
  get taxCode() {
    return this.form.get('taxCode');
  }
  get accountInvoice() {
    return this.form.get('accountInvoice');
  }
  get passwordInvoice() {
    return this.form.get('passwordInvoice');
  }
  get url() {
    return this.form.get('url');
  }
  get serviceAccount() {
    return this.form.get('serviceAccount');
  }
  get servicePassword() {
    return this.form.get('servicePassword');
  }

  get pattern(){
    return this.form.get('pattern')
  }

  get serial(){
    return this.form.get('serial')
  }

// hủy thông tin tạo
  cancelData(){
    this.makeFormCustomerInvoice();
  }
  // lưu dữ liệu cập nhập
  saveData(){
    let data = this.form.value
    console.log(data, '=========data')
    let param = {
      companyName: data.companyName,
      taxCode: data.taxCode,
      url: data.url,
      account: data.accountInvoice,
      password: data.passwordInvoice,
      serviceAccount: data.serviceAccount,
      servicePassword: data.servicePassword,
      pattern: data.pattern,
      serial: data.serial
    }
    this.modal("Bạn chắc chắn muốn khởi tạo tài khoản hóa đơn điện tử này?", () => {
       this.createAccCustomer(param)

    });
  }
  // tạo tài khoản khách hàng mới
  createAccCustomer(data){
    this.einvoicesService.postCreateAccEinvoice(data).subscribe(res=>{
      console.log(res)
      this.alert('Khởi tạo tài khoản hóa đơn thành công','success', false, '', 'OK', ()=>{
        this.route.navigate(['/app/console/einvoices/invoicesCustomer/list']);
      })
    }, err => {
      console.log(err);
      this.handleError(err, err);
    })
  }

  //MARK: Popup thông báo
  modal(msg, callBack = () => { }) {
    const modalRef = this.modalService.open(NgModalComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.callback = callBack;
  }

   //MARK: Popup thông báo
   alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

   //MARK: Handle Error
   handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], 'error');
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, 'error');
    } else {
      this.alert('Lỗi không xác định!', 'error');
    }
  }



}
