import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-service-signup-success',
  templateUrl: './service-signup-success.component.html',
  styleUrls: ['./service-signup-success.component.scss']
})
export class ServiceSignupSuccessComponent implements OnInit {

  user = this.activedRoute.snapshot.queryParamMap.get('user') || '';
  typeSignUp = this.activedRoute.snapshot.queryParamMap.get('type') || ''; //CONSUMER BUSINESS

  constructor(
    private route: Router,
    private activedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
  }

  destroySession() {
    sessionStorage.removeItem('fullname');
    sessionStorage.removeItem('requestSuccess');
  }

  goToHome() {
    this.route.navigate(['/home']);
  }
}
