import { Component, OnInit } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { AasConsumerManagerService } from 'src/app/utils/services/aas-network/aas-consumer-manager.service';
import { AuthService } from './../../../../../../../utils/services/auth.service';
import { ListUserItem } from './../../../../../../../utils/models/aas-models/list-register-user/list-user-item.model';
import { ListUserResponse } from './../../../../../../../utils/models/aas-models/list-register-user/list-user-response.model';
import { share } from 'rxjs/operators';
import { UserDetailObject } from 'src/app/utils/models/aas-models/list-register-user/user-detail-object.model';
import { UserDetailResponse } from 'src/app/utils/models/aas-models/list-register-user/user-detail-response.model';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Constant } from './../../../../../../../utils/common/constant_videocall';

@Component({
  selector: 'app-consumer-signup-rejected',
  templateUrl: './consumer-signup-rejected.component.html',
  styleUrls: ['./consumer-signup-rejected.component.scss']
})
export class ConsumerSignupRejectedComponent implements OnInit {
  page = 1;
  maxSize = 5;
  search = '';
  totalElement = 0;
  propertiesSort = '';
  sort = '';
  status = 'REJECT';
  listUserGroup: ListUserItem[] = [];
  selectedUser: ListUserItem = new ListUserItem();
  loadingListUserGroup = new BehaviorSubject<boolean>(false);
  detailUser: UserDetailObject = new UserDetailObject();
  searchForm: FormGroup;

  url: any = null;
  imageFirst: any = null;
  imageSecond: any = null;

  imageUrls: string[] = [];

  constructor(
      private aasConsumerManagerService: AasConsumerManagerService,
      private authService: AuthService
  ) {
      this.makeSearchForm();
  }

  reloadData() {
      console.log('callreloadreject');
      this.getListUserGroup(
          this.status,
          this.search,
          this.maxSize,
          this.page
      );
  }

  ngOnInit(): void {
      this.getListUserGroup(
          this.status,
          this.search,
          this.maxSize,
          this.page
      );
  }

  //MARK: Loading
  loadingListUserGroupIsVisiable(): Observable<boolean> {
      return this.loadingListUserGroup.asObservable().pipe(share());
  }

  searchData(value) {
      this.page = 1;
      this.getListUserGroup(this.status, value || '', this.maxSize, this.page);
      console.log('data', value);
  }

  loadPage(page: number) {
      this.url = null;
      this.imageFirst = null;
      this.imageSecond = null;
      this.page = page;
      this.getListUserGroup(
          this.status,
          this.searchForm.value.searchInput || '',
          this.maxSize,
          this.page
      );
  }

  makeSearchForm() {
      this.searchForm = new FormGroup({
          searchInput: new FormControl(),
      });
  }

  getListUserGroup(status, search, maxSize, page) {
      this.loadingListUserGroup.next(true);
      var data = new ListUserResponse();
      this.aasConsumerManagerService
          .getListAccountAM(
              this.authService.userId,
              status,
              search,
              maxSize,
              page
          )
          .subscribe(
              (res) => {
                  this.loadingListUserGroup.next(false);
                  data = new ListUserResponse(res);
                  this.listUserGroup = data.object.data;
                  if (this.listUserGroup.length > 0) {
                      // this.detailUser = this.listUserGroup[0];
                      this.selectUserGroup(this.listUserGroup[0]);
                  }
                  this.totalElement = data.object.totalElement;
                  console.log('response', this.listUserGroup);
              },
              (err) => {
                  console.log(err);
                  this.loadingListUserGroup.next(false);
              },
              () => {
                  this.loadingListUserGroup.next(false);
              }
          );
  }

  selectUserGroup(item) {
      // this.loadingListUserGroup.next(true);
      var data = new UserDetailResponse();
      this.aasConsumerManagerService.getDetailUser(item.userId).subscribe(
          (res) => {
              // this.loadingListUserGroup.next(false);
              this.url = null;
              this.imageFirst = null;
              this.imageSecond = null;
              this.imageUrls = [];
              this.getImage(item.userId, 'EKYC_CHANDUNG');
              this.getImage(item.userId, 'EKYC_MATTRUOC');
              this.getImage(item.userId, 'EKYC_MATSAU');
              data = new UserDetailResponse(res);
              this.detailUser = data.object;
              // this.totalElement = data.object.totalElement;
              console.log('response', this.detailUser);
          },
          (err) => {
              console.log(err);
              // this.loadingListUserGroup.next(false);
          },
          () => {
              // this.loadingListUserGroup.next(false);
          }
      );
      this.selectedUser = item;
  }

  async getImage(id, type) {
      this.aasConsumerManagerService.downloadImage(id, type).subscribe(
          (res) => {
              console.log('image', res);
              if (res) {
                  const blob: any = new Blob([res], {
                      type: 'image/png',
                  });
                  var reader = new FileReader();
                  let base64data: any;
                  reader.readAsDataURL(blob);
                  reader.onloadend = () => {
                      if (type === 'EKYC_CHANDUNG') {
                          this.url = reader.result;
                          localStorage.setItem(Constant.IMAGE_FACE, this.url);
                      } else if (type === 'EKYC_MATTRUOC') {
                          this.imageFirst = reader.result;
                          this.imageUrls.push(this.imageFirst);
                      } else {
                          this.imageSecond = reader.result;
                          this.imageUrls.push(this.imageSecond);
                      }
                  };
              }
          },
          (err) => {
              if (type === 'EKYC_CHANDUNG') {
                  this.url = null;
              } else if (type === 'EKYC_MATTRUOC') {
                  this.imageFirst = null;
              } else {
                  this.imageSecond = null;
              }
          },
          () => {}
      );
  }
}
