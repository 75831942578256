import { MainResponse } from '../../../main-response.model';
import { VerifyCodeObject } from './verify-code-object';

export class VerifyCodeResponse extends MainResponse {
    object: VerifyCodeObject; 
    
    constructor(obj = null) {
        super();
        obj = obj || {};
        this.init(obj);
        this.object = new VerifyCodeObject(obj.object || {});
    }
}