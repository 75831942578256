<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-auto">
                <h1 class="m-0 text-dark"><label>Chi tiết thông tin khách hàng</label></h1>
            </div>
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>


<section *ngIf="customer.userType == consumerType" class="content">
    <div class="container-fluid pb-3">
        <div class="card card-body">
            <label class="text-primary">Thông tin khách hàng</label>
            <hr>
            <div class="e-block-field row">
                <div class="col-md-4 col-xl-3">
                    <label class="e-lb-field">Họ và tên</label>
                </div>
                <div class="col-md-8">
                    <span class=""> {{ customer.ten }} </span>
                </div>
            </div>
            <div class="e-block-field row">
                <div class="col-md-4 col-xl-3">
                    <label class="e-lb-field">Số giấy tờ</label>
                </div>
                <div class="col-md-8">
                    <span class=""> {{ customer.cmnd }} </span>
                </div>
            </div>
            <div class="e-block-field row">
                <div class="col-md-4 col-xl-3">
                    <label class="e-lb-field">Email</label>
                </div>
                <div class="col-md-8">
                    <span class=""> {{ customer.email }} </span>
                </div>
            </div>
            <div class="e-block-field row">
                <div class="col-md-4 col-xl-3">
                    <label class="e-lb-field">Số điện thoại</label>
                </div>
                <div class="col-md-8">
                    <span class=""> {{ customer.sdt }} </span>
                </div>
            </div>

            <label class="text-primary">Thông tin yêu cầu dịch vụ</label>
            <hr>
            <div class="e-block-field row">
                <div class="col-md-4 col-xl-3">
                    <label class="e-lb-field">Ngày yêu cầu</label>
                </div>
                <div class="col-md-8">
                    <span class=""> {{ customer.created }} </span>
                </div>
            </div>
            <div class="e-block-field row">
                <div class="col-md-4 col-xl-3">
                    <label class="e-lb-field">Ngày bắt đầu sử dụng</label>
                </div>
                <div class="col-md-8">
                    <span class=""> {{ customer.startDate }} </span>
                </div>
            </div>
            <div class="e-block-field row">
                <div class="col-md-4 col-xl-3">
                    <label class="e-lb-field">Ngày kết thúc</label>
                </div>
                <div class="col-md-8">
                    <span class=""> {{ customer.endDate }}</span>
                </div>
            </div>
            <div class="e-block-field row">
                <div class="col-md-4 col-xl-3">
                    <label class="e-lb-field">Thời gian sử dụng</label>
                </div>
                <div class="col-md-8">
                    <span class=""> {{ customer.duration }} tháng </span>
                </div>
            </div>
            <div class="e-block-field row">
                <div class="col-md-4 col-xl-3">
                    <label class="e-lb-field">Đơn vị yêu cầu</label>
                </div>
                <div class="col-md-8">
                    <span class=""> {{ customer.AMPartyName }} </span>
                </div>
            </div>
            <div class="e-block-field row">
                <div class="col-md-4 col-xl-3">
                    <label class="e-lb-field">Mã HRM nhân viên</label>
                </div>
                <div class="col-md-8">
                    <span class=""> {{ customer.maNv }} </span>
                </div>
            </div>
            <div class="e-block-field row">
                <div class="col-md-4 col-xl-3">
                    <label class="e-lb-field">Trạng thái sử dụng</label>
                </div>
                <div class="col-md-8">
                    <span class="badge {{ getStyleAndValueBadge(customer.packageNum)[0] }} p-2"> {{ getStyleAndValueBadge(customer.packageNum)[1] }} </span>
                </div>
            </div>
        </div>
    </div>
</section>

<section *ngIf="customer.userType == businessType" class="content">
    <div class="container-fluid pb-3">
        <div class="card card-body">
            <label class="text-primary">Thông tin khách hàng</label>
            <hr>
            <div class="e-block-field row">
                <div class="col-md-4 col-xl-3">
                    <label class="e-lb-field">Tên tổ chức</label>
                </div>
                <div class="col-md-8">
                    <span class=""> {{ customer.tenToChuc }} </span>
                </div>
            </div>
            <div class="e-block-field row">
                <div class="col-md-4 col-xl-3">
                    <label class="e-lb-field">Mã số thuế</label>
                </div>
                <div class="col-md-8">
                    <span class=""> {{ customer.maSoThue }} </span>
                </div>
            </div>
            <div class="e-block-field row">
                <div class="col-md-4 col-xl-3">
                    <label class="e-lb-field">Email</label>
                </div>
                <div class="col-md-8">
                    <span class=""> {{ customer.email }} </span>
                </div>
            </div>
            <div class="e-block-field row">
                <div class="col-md-4 col-xl-3">
                    <label class="e-lb-field">Số điện thoại</label>
                </div>
                <div class="col-md-8">
                    <span class=""> {{ customer.sdt }} </span>
                </div>
            </div>

            <label class="text-primary">Thông tin yêu cầu dịch vụ</label>
            <hr>
            <div class="e-block-field row">
                <div class="col-md-4 col-xl-3">
                    <label class="e-lb-field">Ngày yêu cầu</label>
                </div>
                <div class="col-md-8">
                    <span class=""> {{ customer.created }} </span>
                </div>
            </div>
            <div class="e-block-field row">
                <div class="col-md-4 col-xl-3">
                    <label class="e-lb-field">Ngày bắt đầu sử dụng</label>
                </div>
                <div class="col-md-8">
                    <span class=""> {{ customer.startDate }} </span>
                </div>
            </div>
            <div class="e-block-field row">
                <div class="col-md-4 col-xl-3">
                    <label class="e-lb-field">Ngày kết thúc</label>
                </div>
                <div class="col-md-8">
                    <span class=""> {{ customer.endDate }}</span>
                </div>
            </div>
            <div class="e-block-field row">
                <div class="col-md-4 col-xl-3">
                    <label class="e-lb-field">Thời gian sử dụng</label>
                </div>
                <div class="col-md-8">
                    <span class=""> {{ customer.duration }} tháng </span>
                </div>
            </div>
            <div class="e-block-field row">
                <div class="col-md-4 col-xl-3">
                    <label class="e-lb-field">Đơn vị yêu cầu</label>
                </div>
                <div class="col-md-8">
                    <span class=""> {{ customer.AMPartyName }} </span>
                </div>
            </div>
            <div class="e-block-field row">
                <div class="col-md-4 col-xl-3">
                    <label class="e-lb-field">Mã HRM nhân viên</label>
                </div>
                <div class="col-md-8">
                    <span class=""> {{ customer.maNv }} </span>
                </div>
            </div>
            <div class="e-block-field row">
                <div class="col-md-4 col-xl-3">
                    <label class="e-lb-field">Trạng thái sử dụng</label>
                </div>
                <div class="col-md-8">
                    <span class="badge {{ getStyleAndValueBadge(customer.packageNum)[0] }} p-2"> {{ getStyleAndValueBadge(customer.packageNum)[1] }} </span>
                </div>
            </div>
        </div>
    </div>
</section>

