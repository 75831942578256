import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { AasValidateMessage } from '../../../../utils/common/validate-msg-aas';
import { LocationExtension } from './../../../../utils/common/LocationExtension';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { GlobalConfig } from 'src/app/utils/common/global-config/global-config';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/utils/services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgModalComponent } from 'src/app/layout/extensions/ng-modal/ng-modal.component';
import { REGEXP_PASSWORD } from 'src/app/utils/common/regexp';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { localize } from 'src/app/utils/localize/localize';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';

@Component({
    selector: 'app-service-renew-password',
    templateUrl: './service-renew-password.component.html',
    styleUrls: ['./service-renew-password.component.scss']
})
export class ServiceRenewPasswordComponent implements OnInit, AfterViewInit {
    phoneNumber = this.activedRoute.snapshot.queryParamMap.get('phoneNumber') || '';
    email = this.activedRoute.snapshot.queryParamMap.get('email') || '';
    token = this.activedRoute.snapshot.queryParamMap.get('token') || '';

    renewForm: FormGroup;
    isSubmit = false;
    loginFailed = false;

    ValidateMsg = AasValidateMessage.RENEW_PASSWORD;

    constructor(
        private router: Router,
        private activedRoute: ActivatedRoute,
        private authService: AuthService,
        private aasAuth: AasAuthService,
        private modalService: NgbModal,
        private myAlert: AlertControl,
    ) { }

    ngOnInit(): void {
        // if (!GlobalConfig.ORIGIN_SERVICE.includes(location.hostname)) {
        //     this.router.navigate(['/reset-new-password'], { queryParams: { 'email': this.email, 'token': this.token } });
        // }
        this.makeLoginForm();
    }

    ngAfterViewInit(): void {
        var videos = document.getElementsByTagName("video");
        for (var i = 0; i < videos.length; i++) {
            videos[i].muted = true;
            videos[i].play();
        }
    }

    //MARK: Khởi tạo form login
    makeLoginForm() {
        this.renewForm = new FormGroup({
            password: new FormControl('', [Validators.required, Validators.pattern(REGEXP_PASSWORD)]),
            passwordConfirm: new FormControl('', Validators.required)
        });
    }

    get password() { return this.renewForm.get('password') }
    get passwordConfirm() { return this.renewForm.get('passwordConfirm') }

    //MARK: Chức năng đặt lại mật khẩu mới
    actConfirm() {
        this.isSubmit = true;
        this.checkValid();
        if (this.renewForm.valid) {
            // var param = {
            //     "emailOrPhone": this.email || this.phoneNumber || '',
            //     "password": this.password.value,
            //     "passwordConfirm": this.passwordConfirm.value
            // };

            this.postConfirmForgotPasswordV2(this.email, this.token, this.password.value, this.passwordConfirm.value);
        }
    }

    checkValid() {
        if (this.password.value !== this.passwordConfirm.value) {
            this.passwordConfirm.setErrors({ notSame: true });
        }
    }

    get checkEdited() {
        if (this.password.value === '') return true;
        if (this.passwordConfirm.value === '') return true;
        return false;
    }

    //MARK: Trim value formControl
    clearSpace() {
        let str = `${this.password.value || ''}`;
        this.password.setValue(`${str.trim()}`);
    }

    //MARK: Popup thông báo
    modal(msg, callBack = () => { }) {
        const modalRef = this.modalService.open(NgModalComponent);
        modalRef.componentInstance.message = msg;
        modalRef.componentInstance.callback = callBack;
    }

    alert(msg, type, callBack = () => { }) {
        const modalRef = this.modalService.open(NgModalComponent);
        modalRef.componentInstance.message = msg;
        modalRef.componentInstance.typeAlert = type;
        modalRef.componentInstance.notification = true;
        modalRef.componentInstance.callback = callBack;
    }

    //MARK: NETWORKING
    // postConfirmForgotPassword(email, phoneNumber, token, param) {
    //     this.aasAuth.postConfirmChangePassword(email, phoneNumber, token, param).subscribe(res => {
    //         console.log(res);
    //         this.alert('Đặt lại mật khẩu mới thành công!', 'success', () => {
    //             this.router.navigate([ConstantUrl.LOGIN]);
    //         })
    //     }, err => {
    //         console.log(err);
    //         this.alert(err.error.error, 'error');
    //     }, () => {

    //     })
    // }

    //MARK: NETWORKING
    postConfirmForgotPasswordV2(email, token, password, passwordConfirm) {
        this.aasAuth.postConfirmChangePasswordV2(email, token, password, passwordConfirm).subscribe(res => {
            console.log(res);
            this.myAlert.showAlert(localize.CHANGE_PASSWORD_SUCCESSFULLY, ConstantAlertType.BLANK, false, "", localize.btn_login, () => {
                this.router.navigate([ConstantUrl.LOGIN]);
              },"", "", () => {}, "Đổi mật khẩu thành công!");
        }, err => {
            console.log(err);
            this.alert(err.error.error, 'error');
        }, () => {

        })
    }
}
