<div *ngIf="currentStepSign === 0" class="sign-step">
    <app-e-sign-otp #eSignOTP 
        [class.d-none]="signType !== 'E_SIGN'" 
        [sdt]="sdt || ''" 
        [idSign]="idSign"
        [signForm]="signFormSelected"
        (confirmOTPSuccess)="confirmOTPSuccess($event)">
    </app-e-sign-otp>
</div>

<div *ngIf="currentStepSign === 1" class="sign-step">
    <p>{{ 'ESign_label_1' | translate }}</p>
    <div class="content-tab">
        <div id="digital-sign" [class.d-none]="signType !== 'E_SIGN'">
            <!--- DIGITAL SIGN -->
            <div class="mt-3">
                <label class="text-primary">{{ 'ESign_label_2' | translate }}:</label>
                <hr class="mb-3">
                <table id="list-signbox"
                    class="list-signature table table-sm table-bordered header-freeze">
                    <thead class="bg-tb-header">
                        <tr>
                            <th>{{ 'ESign_label_3' | translate }}</th>
                            <th class="th_sig_rectangle">{{ 'ESign_label_4' | translate }}</th>
                            <th>{{ 'ESign_label_5' | translate }}</th>
                            <th>{{ 'ESign_label_6' | translate }}</th>
                        </tr>
                    </thead>
                    <tbody>
                    </tbody>
                </table>
                <button *ngIf="contractData.allowAddSignBox" role="button"
                    class="btn d-flex align-items-center text-primary px-0 text-decoration-underline"
                    (click)="addSignBox()"> + {{ 'ESign_label_7' | translate }}</button>
            </div>

            <div class="row mt-3">
                <div class="col-12">
                    <label class="text-primary">{{ 'ESign_label_8' | translate }}</label>
                </div>
                <div class="col-md-6">
                    <form [formGroup]="formDSign">
                        <div class="form-group">
                            <label>{{ 'ESign_label_9' | translate }} <span
                                    class="text-danger">*</span>:</label>
                            <div class="input-group mb-1">
                                <div class="custom-file custom-file-icon">
                                    <label class="custom-file-label"
                                        for="inputGroupFile03">{{ inputImgESignLabel |translate }}</label>
                                    <input formControlName="imageFile"
                                        (input)="handleFileImgESignInput($event)"
                                        type="file" accept="image/*"
                                        class="custom-file-input"
                                        id="inputGroupFile03"
                                        aria-describedby="inputGroupFileAddon01">
                                    <span
                                        class="icon-file material-icons">attach_file</span>
                                </div>
                            </div>
                            <app-show-validate-errors [useSubmit]="false" [isSubmit]="isESignSubmit"
                                [errorMessages]="validMsgESign.imageFile"
                                [control]="formDSign.controls.imageFile"
                                [detail]="{ name: 'imageFile' }">
                            </app-show-validate-errors>
                        </div>
                    </form>

                    <img *ngIf="imgEsignConfig" class="w-100"
                        [src]="imgEsignConfig">
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="currentStepSign === 2" class="sign-step">
    <p>{{ 'ESign_label_10' | translate }}</p>
</div>