<div class="container-fluid">
    <div class="row">
        <div class="col-md-4">
            <div class="card shadow-none">
                <div class="card-body pb-0">
                    <div class="row pt-2 pb-2">
                        <div class="col-md-auto">
                            <label class="text-lg"
                                >Danh sách đã phê duyệt</label
                            >
                        </div>

                        <div class="input-group mt-3">
                            <form
                                [formGroup]="searchForm"
                                class="input-group"
                                (ngSubmit)="
                                    searchData(searchForm.value.searchInput)
                                "
                            >
                                <div class="input-group-prepend">
                                    <button
                                        class="btn btn-primary d-inline-flex pr-2 pl-2"
                                        type="submit"
                                    >
                                        <span class="material-icons"
                                            >search</span
                                        >
                                    </button>
                                </div>
                                <input
                                    type="text"
                                    class="form-control"
                                    formControlName="searchInput"
                                    id="searchInput"
                                    placeholder="Nhập tài khoản cá nhân..."
                                    aria-label=""
                                    aria-describedby="basic-addon1"
                                />
                            </form>
                        </div>
                    </div>
                </div>

                <div class="position-relative">
                    <app-mini-spinner
                        *ngIf="loadingListUserGroupIsVisiable() | async"
                    ></app-mini-spinner>
                    <ng-template #noData>
                        <div class="no-content-list">
                            <img
                                class="mb-2"
                                src="assets/img/icon/icon-no-user-group.png"
                                width="100"
                            />
                            <p class="mb-1">
                                Không có dữ liệu.
                            </p>
                        </div>
                    </ng-template>

                    <div>
                        <div
                            class="user-group-item content-list-item px-0 pt-2"
                        >
                            <div
                                *ngIf="listUserGroup.length > 0; else noData"
                                class="content-list fade show"
                            >
                                <div
                                    *ngFor="let item of listUserGroup"
                                    (click)="selectUserGroup(item)"
                                    class="user-group-item content-list-item px-4 pt-2"
                                    [class.active]="
                                        item.userId === detailUser.userId
                                    "
                                >
                                    <div class="border-bottom d-flex">
                                        <span
                                            class="material-icons icon-item pr-3"
                                            >group</span
                                        >
                                        <div class="flex-grow-1">
                                            <label class="title-item">{{
                                                item.ten !== '' ? item.ten : item.email
                                            }}</label>
                                            <p class="text-secondary">
                                                Số giấy tờ: {{ item.cmnd }}
                                            </p>
                                        </div>
                                        <!-- (click)="actBtnDelete($event, item.uuidRole)" -->
                                        <!-- <a -->
                                        <!--     class="text-decoration-underline text-primary pl-4" -->
                                        <!--     style="cursor: pointer;" -->
                                        <!--     >Gọi video</a -->
                                        <!-- > -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                *ngIf="totalElement > maxSize"
                class="pagination justify-content-end pages"
            >
                <ngb-pagination
                    [collectionSize]="totalElement"
                    [(page)]="page"
                    [maxSize]="5"
                    [rotate]="true"
                    [ellipses]="false"
                    [pageSize]="maxSize"
                    (pageChange)="loadPage($event)"
                    [boundaryLinks]="true"
                    aria-label="Default pagination"
                >
                    <ng-template ngbPaginationFirst>Đầu</ng-template>
                    <ng-template ngbPaginationLast>Cuối</ng-template>
                    <ng-template ngbPaginationPrevious>&laquo;</ng-template>
                    <ng-template ngbPaginationNext>&raquo;</ng-template>
                    <ng-template ngbPaginationNumber let-page>{{
                        page
                    }}</ng-template>
                </ngb-pagination>
            </div>
        </div>
        <div class="col-md-8">
            <div class="card shadow-none">
                <div class="card-body position-relative">
                    <form>
                        <!-- <form [formGroup]="formUserGroup"> -->
                        <div class="row pt-2 pb-2">
                            <div class="col-md-auto align-self-end">
                                <label class="text-lg"
                                    >Thông tin tài khoản</label
                                >
                            </div>
                            <!-- <div class="col-md-auto ml-auto"> -->
                            <!--     <button class="btn btn-outline-primary btn-light d-inline-flex mr-3"><span -->
                            <!--             class="material-icons pr-2">clear</span> Từ chối</button> -->
                            <!--     <button class="btn btn-outline-primary btn-light d-inline-flex mr-3"><span -->
                            <!--             class="material-icons pr-2">done_outline</span> Phê duyệt</button> -->
                            <!--     <button class="btn btn-primary d-inline-flex"><span -->
                            <!--             class="material-icons pr-2">videocam</span>Gọi video</button> -->
                            <!-- </div> -->
                        </div>
                        <hr />
                        <div class="row">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-4">
                                        <p>Họ và tên</p>
                                    </div>
                                    <div class="col-md-8">
                                        {{ detailUser.ten }}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-4">
                                        <p>Số CMND</p>
                                    </div>
                                    <div class="col-md-8">
                                        {{ detailUser.cmnd }}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-4">
                                        <p>Ngày sinh</p>
                                    </div>
                                    <div class="col-md-8">
                                        {{
                                            detailUser.ngaySinh
                                                | date: 'dd/MM/yyy'
                                        }}
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-4">
                                        <p>Giới tính</p>
                                    </div>
                                    <div class="col-md-8">
                                        {{ detailUser.gioiTinh || ''}}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <p>Nơi ĐKHKTT</p>
                                    </div>
                                    <div class="col-md-8">
                                        {{
                                            detailUser.diaChiThuongTru
                                        }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <p>Ngày cấp</p>
                                    </div>
                                    <div class="col-md-8">
                                        {{
                                            detailUser.ngayCap
                                                | date: 'dd/MM/yyy'
                                        }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <p>Nơi cấp</p>
                                    </div>
                                    <div class="col-md-8">
                                        {{ detailUser.noiCap }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <p>Số điện thoại</p>
                                    </div>
                                    <div class="col-md-8">
                                        {{ detailUser.sdt }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <p>Email</p>
                                    </div>
                                    <div class="col-md-8">
                                        {{ detailUser.email }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <p>Ngày đăng ký</p>
                                    </div>
                                    <div class="col-md-8">
                                        {{
                                            detailUser.created
                                                | date: 'dd/MM/yyy'
                                        }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <p>Người phê duyệt</p>
                                    </div>
                                    <div class="col-md-8">
                                        {{ detailUser.approverEmail }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-4">
                                        <p>Ngày phê duyệt</p>
                                    </div>
                                    <div class="col-md-8">
                                        {{
                                            detailUser.approveDate
                                                | date: 'dd/MM/yyy'
                                        }}
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6">
                                <p>Ảnh chụp CMT</p>
                                <div class="row">
                                    <div class="col-md-6">
                                        <img
                                            class="img-cmnd"
                                            [src]="imageFirst"
                                        />
                                    </div>
                                    <div class="col-md-6">
                                        <img
                                            class="img-cmnd"
                                            [src]="imageSecond"
                                        />
                                    </div>
                                </div>
                                <p>Ảnh chụp khuôn mặt</p>
                                <div class="row">
                                    <div class="col-md-6">
                                        <img class="img-cmnd" [src]="url" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>

                    <!-- <app-mini-spinner *ngIf="loadingFormIsVisiable() | async"></app-mini-spinner> -->
                </div>
            </div>
        </div>
    </div>
</div>
