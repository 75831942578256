import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-report-quantity-customer',
  templateUrl: './report-quantity-customer.component.html',
  styleUrls: ['./report-quantity-customer.component.scss']
})
export class ReportQuantityCustomerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
