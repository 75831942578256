import { Component, OnInit, ViewChild, ElementRef,Renderer2,AfterViewInit } from '@angular/core';
import { Options } from 'select2';
import { Select2OptionData } from 'ng-select2';
import { Observable, from } from 'rxjs';
// import { ReportAnalyticsService } from "src/app/utils/services/report-analytics.service";
import { AuthService } from 'src/app/utils/services/auth.service';
import { ListToChucResponse } from 'src/app/utils/models/organization/list-to-chuc/list-to-chuc-response.model';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { AasContractService } from 'src/app/utils/services/aas-network/aas-contract.service';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateUtils } from 'src/app/utils/common/DateUtils';
import { ReportContractItem } from 'src/app/utils/models/aas-models/quantity-report/report-contract/report-contract-item.model';
import { ListContractResponse } from 'src/app/utils/models/aas-models/quantity-report/report-contract/list-report-contract-response.model';
import { AasQuantityReportService } from 'src/app/utils/services/aas-network/aas-quantity-report.service'
import { ContractStatus } from 'src/app/utils/common/constant';
import { ListDataPackageResponse } from 'src/app/utils/models/aas-models/quantity-report/data-droplist/list-data-package-response.model';
import { API_KEY_FILTER_FORM } from 'src/app/utils/common/constant-vaildate-msg';
import { REGEXP_DATE_INPUT, REGEXP_EMAIL } from 'src/app/utils/common/regexp';
import * as Chart from "chart.js";

import { Item } from 'src/app/utils/models/example/example-item.model';

declare var Cleave: any;
declare var $: any;
@Component({
  selector: 'app-report-contract',
  templateUrl: './report-contract.component.html',
  styleUrls: ['./report-contract.component.scss']
})
export class ReportContractComponent implements OnInit {
  @ViewChild('fromDateFilter') _fromDate: ElementRef;
  @ViewChild('toDateFilter') _toDate: ElementRef;

  partyId: string = this.auth.partyId;
  userId: string = this.auth.userId;

  formFilter: FormGroup;
  listContractResponse : ListContractResponse;
  listContract : Array<ReportContractItem> = [];
  public packageData: Observable<Array<Select2OptionData>>;
  listDataPackageRes: ListDataPackageResponse;
  currentDate = new Date();
  formFilterValidateMsg: any = API_KEY_FILTER_FORM;

  constractStatus = ContractStatus

   // vẽ biểu đồ tròn thống kê hợp đồng
   public doughnutChartType: string = 'doughnut';

   public doughnutChartDatasets: Array<any> = [];
 
   public doughnutChartLabels: Array<any> = [];
 
   public doughnutCharOption = {
   }

   // config bảng cho phép hiển thị theo yêu cầu 
  listConfigColTable: Array<any> = [
    {
      label: "Loại khách hàng",
      name: "loaiKhachHang"
    },
    {
      label: "Gói dịch vụ",
      name: "goiDichVu"
    },
    {
      label: "Trạng thái",
      name: "trangThai"
    },
    {
      label: "Số lượng",
      name: "soLuong"
    }
    
  ];
  configColTable: any = {
    loaiKhachHang: true,
    goiDichVu: true,
    trangThai: true,
    soLuong: true
  }

  // config danh sách bộ lọc cho phép hiển thị theo yc
  listFitler: Array<any> = [
    {
      label: "Loại khách hàng",
      name: "loaiKhachHang"
    },
    {
      label: "Gói dịch vụ",
      name: "goiDichVu"
    },
    {
      label: "Trạng thái hợp đồng",
      name: "trangThaiHopDong"
    },
    {
      label: "Từ ngày",
      name: "tuNgay"
    },
    {
      label: "Đến ngày",
      name: "denNgay"
    },
  ];
  configFilter: any = {
    loaiKhachHang: true,
    goiDichVu: true,
    trangThaiHopDong: true,
    tuNgay: true,
    denNgay: true,
  }

  // danh sách loại khách hàng
  dropListsUserType:  Array<Select2OptionData> = [
    {
      id: 'ALL',
      text: 'Tất cả'
    },
    {
      id: 'CONSUMER',
      text: 'Cá nhân'
    },
    {
      id: 'BUSINESS',
      text: 'Tổ chức dịch vụ'
    },
    {
      id: 'ENTERPRISE',
      text: 'Tổ chức giải pháp'
    },
    
  ];

  // dánh sách loại tổ chức
  userTypes = {
    CONSUMER : {
      lable: 'Cá Nhân'
    },
    BUSINESS : {
      lable: 'Tổ chức dịch vụ'
    },
    ENTERPRISE : {
      lable: 'Tổ chức giải pháp'
    },

  }

  // các trạng thái HĐ
  dropListsContractStatus:  Array<Select2OptionData> = [
    {
      id: 'ALL',
      text: 'Tất cả'
    },
    {
      id: 'LC_DRAFT_CREATE',
      text: 'Bản Nháp'
    },
    {
      id: 'LC_DRAFT_DENY',
      text: 'Từ chối'
    },
    {
      id: 'LC_DRAFT_SUBMIT',
      text: 'Đã gửi'
    },
    {
      id: 'LC_DRAFT_SUBMIT_SIGNED',
      text: 'Chờ ký'
    },
    {
      id: 'LC_DRAFT_SIGNED',
      text: 'Đã ký'
    },
    {
      id: 'LC_DRAFT_VALID',
      text: 'Chờ xử lý'
    },
    {
      id: 'LC_DRAFT_ONE_SIGN',
      text: 'Chờ ký'
    },
    {
      id: 'LC_CONTRACT_VALID',
      text: 'Có hiệu lực'
    },
    {
      id: 'LC_CONTRACT_EXPIRED_VALID',
      text: 'Hết hiệu lực'
    },
    {
      id: 'LC_CONTRACT_CANCEL',
      text: 'Đã hủy'
    },
    
  ];

  partnerId: string = '';
  serviceId: string = '';
  fromDate: string = '';
  toDate: string = '';
  customerType: string = "";
  package = '';
  contractStatus = '';

  fromDateFilter: string = '';
  toDateFilter: string = '';
  isSubmit: boolean = false;

  total: number = 0;

  zIndex = 5;
  public options: Options;

  dataDoughnutChar :Array<any> = []


  constructor(
    private fb: FormBuilder,
    private auth: AuthService,
    private modalService: NgbModal,
    private renderer2: Renderer2,
    private quantityService: AasQuantityReportService
  ) {
    this.renderer2.listen('window', 'click', (e: Event) => {
      var all = document.getElementsByClassName('table-sticky-col2');
      for (var i = 0; i < all.length; i++) {
        all[i].removeAttribute('style');
      }       
    });
  }

  ngOnInit(): void {
    this.getPackageData();

    this.makeFormFilter();

    this.makeAutoFormatDate();

    this.getListReport();
  
  }

  // get dánh sách báo hợp đồng
  getListReport(){
    this.total = 0;
    let userType = this.userType.value === 'ALL'? '':  this.userType.value;
    let packageType = this.packageType.value === 'ALL'? '':  this.packageType.value;
    let status = this.status.value === 'ALL'? '':  this.status.value;
    let fromDate = this.convertStringToDate(this.fromDate)?this.convertStringToDate(this.fromDate):'';
    let toDate = this.convertStringToDate(this.toDate)?this.convertStringToDate(this.toDate):'';

    // gọi api danh sách và phận loại HĐ theo từng khác hàng
    this.quantityService.getListContract(userType,packageType,status,fromDate,toDate).subscribe(res=>{
      this.listContractResponse = new ListContractResponse(res);
      this.listContract = this.listContractResponse.data
      let dataBusiness = 0
      let dataConsumer = 0
      let dataEnterprise = 0
      this.listContract.forEach(item=>{
        if(item.loaiKhachHang === 'BUSINESS'){
          dataBusiness = dataBusiness + item.soLuong
        }
        if(item.loaiKhachHang === 'CONSUMER'){
          dataConsumer = dataConsumer + item.soLuong
        }
        if(item.loaiKhachHang === 'ENTERPRISE'){
          dataEnterprise = dataEnterprise + item.soLuong
        }
      })
      this.total = dataBusiness + dataConsumer + dataEnterprise
      console.log(this.total, '=========this.total')

      // add data cho doughnut char
      this.dataDoughnutChar = [];
      this.dataDoughnutChar.push(dataBusiness)
      this.dataDoughnutChar.push(dataConsumer)

      this.dataDoughnutChar.push(dataEnterprise)

      console.log(this.dataDoughnutChar,'============thisdataDoughnutChar')
      let backgroundColor : Array<any> = ['#FFB800','#20c997', '#0062FF']
      let hoverBackgroundColor : Array<any> = ['#cccdd6','#cccdd6','#cccdd6']
      this.doughnutChartLabels = ['Dịch vụ', 'Cá Nhân', 'Giải pháp'];

      this.doughnutChartDatasets = [
        { data: this.dataDoughnutChar, label: 'Thống kê hợp đồng', backgroundColor: backgroundColor,
        hoverBackgroundColor: hoverBackgroundColor }
      ];
      this.doughnutCharOption = {
        responsive: true,
        width:600,
        height:600,
        cutoutPercentage: 70,
        elements: {
        center: {
          text: this.total.toString(),
          color: '#000000', 
          fontStyle: 'Arial', 
          sidePadding: 20, 
          minFontSize: 25,
          lineHeight: 25 
        }},
        legend: {
          display: true,
          position: 'bottom'
        },
      }
    })
    
  
  
  }

  // covert click chart
  chartHovered(e){

  }
  chartClicked(e){
  }

  // get danh sách gói
  getPackageData(){
    this.quantityService.getDropListPackage().subscribe((res:any)=>{
      this.listDataPackageRes = new ListDataPackageResponse(res['object'])
      this.packageData = this.makePackageData();
    })
  }

  // đổ dữ liệu gói vào select 2
  makePackageData(): Observable<Array<Select2OptionData>> {
    let data: Array<Select2OptionData> = [];
    data.push({ id: "ALL", text: "Tất cả" });
    if (this.listDataPackageRes.data && this.listDataPackageRes.data instanceof Array) {
      this.listDataPackageRes.data.forEach((item) => {
        data.push({ id: item.packageInfo.packInfoId.toString(), text: item.packageInfo.name });
      });
    }
    return Observable.create((obs) => {
      obs.next(data);
      obs.complete();
    });
  }

  
  // cover time to format dd/MM/YYYY
  convertToDateObj(dataString) {
    let d = new Date(dataString);
    let localTiming = d.toLocaleDateString('en-GB');
    return localTiming;
  }

  //MARK: Make input date format
  makeAutoFormatDate() {
    let self = this;
    new Cleave('#fromDateReport', {
      date: true,
      delimiter: '/',
      datePattern: ['d', 'm', 'Y'],
      onValueChanged: function(e) {
        self.fromDate = e.target.value;
        self.f_fromDate.setValue(e.target.value);
      }
    });

    new Cleave('#toDateReport', {
      date: true,
      delimiter: '/',
      datePattern: ['d', 'm', 'Y'],
      onValueChanged: function(e) {
        self.toDate = e.target.value;
        self.f_toDate.setValue(e.target.value);
      }
    });
  }


  makeFormFilter() {
    this.formFilter = this.fb.group({
      userType : ['ALL'],
      packageType: ['ALL'],
      status: ['ALL'],
      toDate: new FormControl('',  [Validators.pattern(REGEXP_DATE_INPUT)]),
      fromDate: new FormControl('', [Validators.pattern(REGEXP_DATE_INPUT)])
    });
    this.options = {
      multiple: false,
      width: '100%',
      closeOnSelect: true,
      language: 'vi',
    };
  }

  get userType() {
    return this.formFilter.get('userType');
  }
  get packageType() {
    return this.formFilter.get('packageType');
  }
  get status() {
    return this.formFilter.get('status');
  }

  get f_toDate() { return this.formFilter.get('toDate'); };
  get f_fromDate() { return this.formFilter.get('fromDate'); };

  // config chọn ngày filter
  dateInput(e, type) {
    if (e.target.value.length > 10) {
      var str = e.target.value;
      e.target.value = str.substring(0, str.length - 1);
      if (type === 'fromDate') {
        this.fromDate = e.target.value;
        this.f_fromDate.setValue(e.target.value);
      } else if (type === 'toDate') {
        this.toDate = e.target.value;
        this.f_toDate.setValue(e.target.value);
      }
    } else {
      if (type === 'fromDate') {
        this.fromDate = e.target.value;
        this.f_fromDate.setValue(e.target.value);
      } else if (type === 'toDate') {
        this.toDate = e.target.value;
        this.f_toDate.setValue(e.target.value);
      }
    }
  }

  changeDate(event, type) {
    if (type == 'to') {
      this.toDate = DateUtils.convertObjtoDate(event);
      this.f_toDate.setValue(this.toDate);
      this._toDate.nativeElement.value = this.toDate;
    }
    else {
      this.fromDate = DateUtils.convertObjtoDate(event);
      this.f_fromDate.setValue(this.fromDate);
      this._fromDate.nativeElement.value = this.fromDate;
    }
  }

  //MARK: Apply filter
  searchWithFilter() {
    this.isSubmit = true;
    if (this.formFilter.valid) {
      let dateFrom = this.fromDate.length > 0 ? new Date(DateUtils.convertStringtoDateSearch(this.fromDate)).getTime() : -1;
      let dateTo = this.toDate.length > 0 ? new Date(DateUtils.convertStringtoDateSearch(this.toDate)).getTime() : -1;
      if ((dateTo > 0 && dateFrom > 0 && dateFrom > dateTo)) {
        this.f_fromDate.setErrors({minDate: true});
      }
    }

    if (this.formFilter.valid) {
      this.getListReport();
    }
  }

  cleanFilter() {
    this.isSubmit = false;
    this._fromDate.nativeElement.value = '';
    this._toDate.nativeElement.value = '';

    this.fromDate = '';
    this.f_fromDate.setValue('');
    this.toDate = '';
    this.f_toDate.setValue('');

    this.makeFormFilter();
    this.status.setValue('');
    this.packageType.setValue('');
    this.userType.setValue('');

    this.configColTable = {
      loaiKhachHang: true,
      goiDichVu: true,
      trangThai: true,
      soLuong: true
    }
  
    this.configFilter = {
      loaiKhachHang: true,
      goiDichVu: true,
      trangThaiHopDong: true,
      tuNgay: true,
      denNgay: true,
    }

    this.searchWithFilter()
  }

  //MARK: Action cấu hình hiển thị các hình thức filter
  actConfigFilter(e, field) {
    e.stopPropagation();
    if (this.configFilter[field] !== null && this.configFilter[field] !== undefined) {
      this.configFilter[field] = !this.configFilter[field];
    }
  }

  //MARK: Action cấu hình hiển thị cột của bảng
  actConfigColTableView(e, field) {
    e.stopPropagation();
    if (this.configColTable[field] !== null && this.configColTable[field] !== undefined) {
      this.configColTable[field] = !this.configColTable[field];
    }
  }

  //MARK: Action dropdown từng dòng
  openDropdown(event) {
    event.path[3].setAttribute("style", "z-index: " + ++this.zIndex + ";");
  }


  convertStringToDate(str: string) {
    if(str !== '' && str !== null && str !== undefined) {
        var values = str.split('/');
        if(values.length >= 3) {
            return `${values[2]}-${values[1]}-${values[0]}`;
        } else {
            return null;
        }
    } else {
        return null;
    }
  }
  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], 'error');
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, 'error');
    } else {
      this.alert('Lỗi không xác định!', 'error');
    }
  }


}
