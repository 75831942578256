import { MainResponse } from './../../main-response.model';
import { PermissionGroup } from './permission-group';

export class ListAllPermisionReponse extends MainResponse {
    object: PermissionGroup[] = [];
    constructor(obj = null) {
        super();
        obj = obj || {};
        this.init(obj);
        this.object = [];
        if (obj.object && obj.object instanceof Array) {
            obj.object.forEach((item, index) => {
                this.object.push(new PermissionGroup(item));
                this.object[index].index = index;
            })
        }
    }
}
