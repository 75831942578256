<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-auto">
                <h1 class="m-0 text-dark"><label>{{ localize.account_manager_page_title }} </label></h1>
            </div>
            <!-- /.col -->
            <div class="col-md-auto ml-auto">
                
                <div class="dropdown">
                    <button
                        class="btn btn-primary d-inline-flex mr-3" 
                        id="dropdownMenuLink" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <span class="material-icons mr-1">add</span> Thêm mới
                    </button>

                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                        <a class="dropdown-item" style="cursor: pointer;"
                        (click)="addCustomer('CONSUMER')">Khách hàng cá nhân</a>
                        <a class="dropdown-item" style="cursor: pointer;"
                        (click)="addCustomer()">Khách hàng tổ chức</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>

<section class="content">
    <div class="container-fluid">
        <div class="tab-bar-top-custom">
            <div class="content-right-sidebar tab-bar-custom">
                <ul class="nav nav-tabs" id="tabSignAction" role="tablist">
                    <li class="nav-item nav-material">
                        <a class="nav-link d-flex " id="waiting-tab" data-toggle="pill" href="#waiting" role="tab"
                            aria-controls="waiting" aria-selected="false">
                            {{ localize.account_manager_tab_consumer }} ({{totalElementConsumer}})
                        </a>
                    </li>
                    <li class="nav-item nav-material">
                        <a class="nav-link d-flex active" id="approved-tab" data-toggle="pill" href="#approved" role="tab"
                            aria-controls="approved" aria-selected="true">
                            {{ localize.account_manager_tab_business }} ({{totalElementBusiness}})
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="tab-content contract-tab-body" id="tabSignAction">
            <div class="tab-pane fade " id="waiting" role="tabpanel" aria-labelledby="waiting">
                <app-account-consumer-list></app-account-consumer-list>
            </div>
            <div class="tab-pane fade show active" id="approved" role="tabpanel" aria-labelledby="approved">
                <app-account-business-list></app-account-business-list>
            </div>
        </div>
    </div>
</section>

<app-spinner></app-spinner>