export const ServicePath = {
    TEMPATE_SERVICE: '/template-service',
    EPAYMENT_SERVICE: '/epayment-service',
    MANAGEMENT_SERVICE: '/esolution-service',
    SIGN_SERVICE: '/sign-service',
    ESIGNATURE_SERVICE: '/esignature-service',
    REPORT_SERVICE: '/report-service',
    COMMENT_SERVICE: '/comment-service',
    AUTH_SERVICE: '/auth-service',
    LANDING_PAGE_SERVICE:'/landingpage-service'
}