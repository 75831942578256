import { NotePoint } from "src/app/shared/plugins/note-point.model";

export class VersionContract {
    alreadyEdit: any = false;
    created = "";
    formattedDate = "";
    id = "";
    modified = "";
    name = "";
    nameUser = "";
    tempName = "";
    traceId = "";
    updating: any = false;
    userId = "";

    currentLang = localStorage.getItem("lang");

    posts: NotePoint[] = [];
    expanded = false;
    lasted = false;

    constructor(d = null) {
        d = d || {};
        this.alreadyEdit = d.alreadyEdit;
        this.created = d.created || "";
        this.formattedDate = d.formattedDate || "";
        this.id = d.id || "";
        this.modified = d.modified || "";
        this.name = d.name || "";
        this.nameUser = d.nameUser || "";
        this.tempName = d.tempName || "";
        this.traceId = d.traceId || "";
        this.updating = d.updating || "";
        this.userId = d.userId || "";
        this.lasted = d.lasted || false;

        this.progressData();
    }

    /**
     * Xử lý dữ liệu đầu vào
     */
    progressData() {
        this.formattedDate = this.formatDate(this.created, localStorage.getItem("lang"));
        if (!this.alreadyEdit) {
        this.name = this.formatDate(this.name, localStorage.getItem("lang"));
        }
        this.tempName = this.name;
        this.updating = false;
    }

    /**
     * Định dạng ngày, chia theo ngôn ngữ
     * @param date 
     * @param lang 
     * @returns 
     */
    formatDate(date: string, lang: string = this.currentLang): string {
        const dateOrigin = new Date(date);

        if (isNaN(dateOrigin.getMonth())) {
            return date;
        }

        if (lang === 'vi') {
            return (dateOrigin.getHours() < 10 ? "0" + dateOrigin.getHours() : dateOrigin.getHours())
                + ":" + (dateOrigin.getMinutes() < 10 ? "0" + dateOrigin.getMinutes() : dateOrigin.getMinutes())
                + " - " + dateOrigin.getDate()
                + " tháng " + (dateOrigin.getMonth() + 1);
        }
        else if (lang === 'en') {
            return (dateOrigin.getHours() < 10 ? "0" + dateOrigin.getHours() : dateOrigin.getHours())
                + ":" + (dateOrigin.getMinutes() < 10 ? "0" + dateOrigin.getMinutes() : dateOrigin.getMinutes())
                + " - " + this.translateMonthToEnglish(dateOrigin.getMonth() + 1)
                + " " + dateOrigin.getDate();
        }
    }

    translateMonthToEnglish(month: any): string {
        switch (month.toString()) {
          case "1":
            return "January";
          case "2":
            return "February";
          case "3":
            return "March";
          case "4":
            return "April";
          case "5":
            return "May";
          case "6":
            return "June";
          case "7":
            return "July";
          case "8":
            return "August";
          case "9":
            return "September";
          case "10":
            return "October";
          case "11":
            return "November";
          case "12":
            return "December";
          default:
            return ""
        }
    }
}