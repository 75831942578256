<div class="pt-3">
    <section class="content">
        <div class="container-fluid">
            <div class="card shadow-none">
                <div class="card-body padding-md">
                    <h4 class="text-dark" style="text-align: center;"><label>HƯỚNG DẪN ĐĂNG KÝ TÀI KHOẢN CÁ NHÂN TRÊN
                            WEBSITE</label> </h4>
                            <!-- [innerHTML]="data?.blogs[0]?.description" -->
                    <div class="manual-body" [innerHTML]="data?.blogs[0]?.description" >
                        
                        <!-- <div class="pb-4">
                            <p><span class="bold">Bước 1:</span> Người dùng truy cập website thông qua đường dẫn
                                "<a href="https://econtract.vnpt.vn/">https://econtract.vnpt.vn/</a>"" , chọn nút <span
                                    class="bold">“Đăng ký”</span>.</p>
                            <img class="picture pb-2"
                                src="https://storage-econtract.vnpt.vn/landingpage-dev/2021-11-23/buoc1.png" alt="">
                            <p>Sau đó chuyển đến màn hình <span class="bold">“Chọn loại khách hàng”</span>.</p>
                        </div>


                        
                        <div class="pb-4">
                            <p><span class="bold">Bước 2:</span> Màn hình <span class="bold">“Chọn loại khách
                                    hàng”</span>
                                hiển thị như hình sau:</p>
                            <img class="picture pb-2"
                                src="https://storage-econtract.vnpt.vn/landingpage-dev/2021-11-23/buoc2.png" alt="">
                            <p>Chọn thẻ <span class="bold">“Cá nhân”</span> để thực hiện đăng ký tài khoản, hệ thống
                                chuyển
                                đến màn hình <span class="bold">“Bổ sung thông tin đăng ký”</span>.</p>
                        </div>


                       
                        <div class="pb-4">
                            <p><span class="bold">Bước 3:</span> Màn hình <span class="bold">“Bổ sung thông tin đăng
                                    ký”</span> hiển thị như hình sau:</p>
                            <img class="picture pb-2"
                                src="https://storage-econtract.vnpt.vn/landingpage-dev/2021-11-23/buoc3.png" alt="">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr class="table-header">
                                            <th style="width: 120px">Tên trường</th>
                                            <th style="width: 80px">Bắt buộc</th>
                                            <th>Mô tả</th>
                                            <th>Ví dụ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Email</td>
                                            <td style="text-align: center;">Có</td>
                                            <td>Email có định dạng text@text.text (Email chưa đăng ký bất kỳ tài khoản
                                                KHCN nào)</td>
                                            <td>lethuy06@yopmail.com</td>
                                        </tr>
                                        <tr>
                                            <td>Số điện thoại</td>
                                            <td style="text-align: center;">Có</td>
                                            <td>Là số điện thoại của chủ tài khoản.<br>(Số điện thoại chưa đăng ký bất
                                                kỳ tài khoản KHCN nào)
                                            </td>
                                            <td>0814523658</td>
                                        </tr>
                                        <tr>
                                            <td>Tỉnh/Thành phố</td>
                                            <td style="text-align: center;">Không</td>
                                            <td>Tỉnh/Thành phố mà chủ tài khoản sinh sống
                                            </td>
                                            <td>Đà Nẵng</td>
                                        </tr>
                                        <tr>
                                            <td>Tên tài khoản</td>
                                            <td style="text-align: center;">Có</td>
                                            <td>Từ 6 đến 30 ký tự (Tên tài khoản là duy nhất trên hệ thống )
                                            </td>
                                            <td>canhan06</td>
                                        </tr>
                                        <tr>
                                            <td>Mật khẩu</td>
                                            <td style="text-align: center;">Có</td>
                                            <td>Có độ dài lớn hơn hoặc bằng 8 ký tự bao gồm ký tự hoa, thường, số và ký
                                                tự đặc biệt
                                            </td>
                                            <td>Abc@12345</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p>Chọn nút <span class="bold">“Tiếp theo”</span> để thực hiện xác minh tài khoản, chuyển
                                đến màn hình <span class="bold">“Xác minh OTP”</span>.</p>
                        </div>

                       
                        <div class="pb-4">
                            <p><span class="bold">Bước 4:</span> Nhập mã OTP để hoàn thành xác minh tài khoản</p>
                            <p>Có 2 trường hợp xảy ra:</p>
                            <p>- <span class="bold">Trường hợp 1:</span> Nhập đúng mã OTP</p>
                            <img class="picture pb-2"
                                src="https://storage-econtract.vnpt.vn/landingpage-dev/2021-11-23/buoc4-1.png" alt="">
                            <p>- <span class="bold">Trường hợp 2:</span> Nhập sai mã OTP</p>
                            <img class="picture pb-2"
                                src="https://storage-econtract.vnpt.vn/landingpage-dev/2021-11-23/buoc4-2.png" alt="">
                            <div class="table-responsive">
                                <table class="table">
                                    <thead>
                                        <tr class="table-header">
                                            <th style="width: 120px">Tên trường</th>
                                            <th style="width: 80px">Bắt buộc</th>
                                            <th>Mô tả</th>
                                            <th>Ví dụ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Mã OTP</td>
                                            <td style="text-align: center;">Có</td>
                                            <td>Mã OTP được gửi về số điện thoại từ đầu số 900</td>
                                            <td>295023</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        
                        <div class="pb-4">
                            <p><span class="bold">Bước 5:</span> Nhấn nút <span class="bold">“Hoàn thành”</span> để hoàn
                                thành đăng ký tài khoản và chuyển đến màn hình <span class="bold">“Đăng ký thành
                                    công”</span></p>
                            <img class="picture pb-2"
                                src="https://storage-econtract.vnpt.vn/landingpage-dev/2021-11-23/buoc5.png" alt="">
                            <p>Để hoàn thành việc xác minh tài khoản , người dùng thực hiện cài ứng dụng tại đường dẫn
                                được gửi qua email và thực hiện tiếp các bước xác minh tài khoản (xem tại mục <span
                                    class="bold">II.2.3.
                                    Nâng cấp định danh eKYC</span>)</p>
                            <p class="red-italic">**Khách hàng lưu ý bảo vệ Tài khoản và mật khẩu, tránh lộ thông tin
                                cho đối tượng bên ngoài biết và sử dụng hệ thống.</p>
                        </div> -->

                    </div>

                </div>


            </div>
        </div>
    </section>
</div>


<!-- <div class="pb-4">
                            <p><span style="font-weight: bold;">Bước 1:</span> Người dùng truy cập website thông qua đường dẫn
                                "<a href="https://econtract.vnpt.vn/">https://econtract.vnpt.vn/</a>"" , chọn nút <span
                                    style="font-weight: bold;">“Đăng ký”</span>.</p>
                            <img style="max-width: 100%;" class="pb-2"
                                src="https://storage-econtract.vnpt.vn/landingpage-dev/2021-11-23/buoc1.png" alt="">
                            <p>Sau đó chuyển đến màn hình <span style="font-weight: bold;">“Chọn loại khách hàng”</span>.</p>
                        </div>


                        
                        <div class="pb-4">
                            <p><span style="font-weight: bold;">Bước 2:</span> Màn hình <span style="font-weight: bold;">“Chọn loại khách
                                    hàng”</span>
                                hiển thị như hình sau:</p>
                            <img style="max-width: 100%;" class="pb-2"
                                src="https://storage-econtract.vnpt.vn/landingpage-dev/2021-11-23/buoc2.png" alt="">
                            <p>Chọn thẻ <span style="font-weight: bold;">“Cá nhân”</span> để thực hiện đăng ký tài khoản, hệ thống
                                chuyển
                                đến màn hình <span style="font-weight: bold;">“Bổ sung thông tin đăng ký”</span>.</p>
                        </div>


                       
                        <div class="pb-4">
                            <p><span style="font-weight: bold;">Bước 3:</span> Màn hình <span style="font-weight: bold;">“Bổ sung thông tin đăng
                                    ký”</span> hiển thị như hình sau:</p>
                            <img style="max-width: 100%;" class="pb-2"
                                src="https://storage-econtract.vnpt.vn/landingpage-dev/2021-11-23/buoc3.png" alt="">
                            <div class="table-responsive">
                                <table class="table" style="border-bottom: 1px solid #CBD5E0; border-left: 1px solid #CBD5E0; border-right: 1px solid #CBD5E0;">
                                    <thead>
                                        <tr style="background-color: #EDF2F7;">
                                            <th style="width: 120px">Tên trường</th>
                                            <th style="width: 80px">Bắt buộc</th>
                                            <th>Mô tả</th>
                                            <th>Ví dụ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Email</td>
                                            <td style="text-align: center;">Có</td>
                                            <td>Email có định dạng text@text.text (Email chưa đăng ký bất kỳ tài khoản
                                                KHCN nào)</td>
                                            <td>lethuy06@yopmail.com</td>
                                        </tr>
                                        <tr>
                                            <td>Số điện thoại</td>
                                            <td style="text-align: center;">Có</td>
                                            <td>Là số điện thoại của chủ tài khoản.<br>(Số điện thoại chưa đăng ký bất
                                                kỳ tài khoản KHCN nào)
                                            </td>
                                            <td>0814523658</td>
                                        </tr>
                                        <tr>
                                            <td>Tỉnh/Thành phố</td>
                                            <td style="text-align: center;">Không</td>
                                            <td>Tỉnh/Thành phố mà chủ tài khoản sinh sống
                                            </td>
                                            <td>Đà Nẵng</td>
                                        </tr>
                                        <tr>
                                            <td>Tên tài khoản</td>
                                            <td style="text-align: center;">Có</td>
                                            <td>Từ 6 đến 30 ký tự (Tên tài khoản là duy nhất trên hệ thống )
                                            </td>
                                            <td>canhan06</td>
                                        </tr>
                                        <tr>
                                            <td>Mật khẩu</td>
                                            <td style="text-align: center;">Có</td>
                                            <td>Có độ dài lớn hơn hoặc bằng 8 ký tự bao gồm ký tự hoa, thường, số và ký
                                                tự đặc biệt
                                            </td>
                                            <td>Abc@12345</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p>Chọn nút <span style="font-weight: bold;">“Tiếp theo”</span> để thực hiện xác minh tài khoản, chuyển
                                đến màn hình <span style="font-weight: bold;">“Xác minh OTP”</span>.</p>
                        </div>

                       
                        <div class="pb-4">
                            <p><span style="font-weight: bold;">Bước 4:</span> Nhập mã OTP để hoàn thành xác minh tài khoản</p>
                            <p>Có 2 trường hợp xảy ra:</p>
                            <p>- <span style="font-weight: bold;">Trường hợp 1:</span> Nhập đúng mã OTP</p>
                            <img style="max-width: 100%;" class="pb-2"
                                src="https://storage-econtract.vnpt.vn/landingpage-dev/2021-11-23/buoc4-1.png" alt="">
                            <p>- <span style="font-weight: bold;">Trường hợp 2:</span> Nhập sai mã OTP</p>
                            <img style="max-width: 100%;" class="pb-2"
                                src="https://storage-econtract.vnpt.vn/landingpage-dev/2021-11-23/buoc4-2.png" alt="">
                            <div class="table-responsive">
                                <table class="table" style="border-bottom: 1px solid #CBD5E0; border-left: 1px solid #CBD5E0; border-right: 1px solid #CBD5E0;">
                                    <thead>
                                        <tr style="background-color: #EDF2F7;">
                                            <th style="width: 120px">Tên trường</th>
                                            <th style="width: 80px">Bắt buộc</th>
                                            <th>Mô tả</th>
                                            <th>Ví dụ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Mã OTP</td>
                                            <td style="text-align: center;">Có</td>
                                            <td>Mã OTP được gửi về số điện thoại từ đầu số 900</td>
                                            <td>295023</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        
                        <div class="pb-4">
                            <p><span style="font-weight: bold;">Bước 5:</span> Nhấn nút <span style="font-weight: bold;">“Hoàn thành”</span> để hoàn
                                thành đăng ký tài khoản và chuyển đến màn hình <span style="font-weight: bold;">“Đăng ký thành
                                    công”</span></p>
                            <img style="max-width: 100%;" class="pb-2"
                                src="https://storage-econtract.vnpt.vn/landingpage-dev/2021-11-23/buoc5.png" alt="">
                            <p>Để hoàn thành việc xác minh tài khoản , người dùng thực hiện cài ứng dụng tại đường dẫn
                                được gửi qua email và thực hiện tiếp các bước xác minh tài khoản (xem tại mục <span
                                    style="font-weight: bold;">II.2.3.
                                    Nâng cấp định danh eKYC</span>)</p>
                            <p style="font-style: italic; color: red;">**Khách hàng lưu ý bảo vệ Tài khoản và mật khẩu, tránh lộ thông tin
                                cho đối tượng bên ngoài biết và sử dụng hệ thống.</p>
                        </div> -->