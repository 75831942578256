import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { PartnerService } from 'src/app/utils/services/aas-network/partner.service';
import { AuthService } from 'src/app/utils/services/auth.service';

@Component({
  selector: 'app-details-business-trusted-partner',
  templateUrl: './details-business-trusted-partner.component.html',
  styleUrls: ['./details-business-trusted-partner.component.scss']
})
export class DetailsBusinessTrustedPartnerComponent implements OnInit {

  id = this.activatedRoute.snapshot.paramMap.get('id') ?? null;

  constructor(
    private partnerService: PartnerService,
    private activatedRoute: ActivatedRoute,
    private aasAuth: AasAuthService,
    private router: Router,
    private auth: AuthService
  ) { }

  detailInformation: any = {};
  partyId = this.auth.partyId;

  ngOnInit(): void {
    this.getDetails();
  }

  getDetails() {
    if (this.id != null) {
      this.partnerService.getDetailsOfBussinessTrustedPartner(this.id).subscribe((res: any) => {
        this.detailInformation = res?.object ?? {};
        this.buildAddress();
      }, err => {
        this.detailInformation = {};
      });
    }
  }

  /**
   * Address component like apartment number, street ... are seperate
   * This function is used to join it into a string
   */
  address: string = "";
  buildAddress() {
    this.address = "";
    if (this.detailInformation.soNha) {
      this.address += this.detailInformation.soNha;
    }
    if (this.detailInformation.duong) {
      if (this.detailInformation.soNha) {
        this.address += " ";
      }
      this.address += this.detailInformation.duong;
    }
    if (this.detailInformation.tenXa) {
      this.address += `, ${this.detailInformation.tenXa}`;
    }
    if (this.detailInformation.tenHuyen) {
      this.address += `, ${this.detailInformation.tenHuyen}`;
    }
    if (this.detailInformation.tenTinh) {
      this.address += `, ${this.detailInformation.tenTinh}`;
    }
  }

  fileGPKD: Blob = null;
  showPreviewGPKD: boolean = false;

  actDownloadGPKD(id: string) {
    this.downloadGPKD(id);
  }

  /**
  * Tai file GPKD
  * @param partyId 
  */
  downloadGPKD(partyId: string) {
    this.aasAuth.downloadGPKD(partyId).subscribe((res: any) => {
      this.fileGPKD = new Blob([res], { type: 'application/pdf' });
      this.showPreviewGPKD = true;
    }, err => {

    })
  }

  handleClosePreview() {
    this.showPreviewGPKD = false;
  }

  goToEdit() {
    if (this.id) {
      this.router.navigate([`app/console/partner/update-business-trusted-partner`, this.id]);
    }
  }
}
