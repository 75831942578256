import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BusinessPackageService } from 'src/app/utils/services/aas-network/business-package.service';
import { CustomerManagerService } from 'src/app/utils/services/aas-network/customer-manager/customer-manager.service';
import { AuthService } from 'src/app/utils/services/auth.service';


@Component({
  selector: 'app-business-package-info-tab',
  templateUrl: './business-package-info-tab.component.html',
  styleUrls: ['./business-package-info-tab.component.scss']
})
export class BusinessPackageInfoTabComponent implements OnInit {

  partyId = this.router.snapshot.paramMap.get("id");

  constructor(
    private customerManagerService: CustomerManagerService,
    private auth: AuthService,
    private router: ActivatedRoute
  ) { }

  detail: any = {};
  listService: Array<any> = new Array();
  listPackageInfra: Array<any> = new Array();

  history: Array<any> = new Array();

  sortColConfig = {
    thongSo: null,
    donViTinh: null,
    soLuong: null,
    trangThai: null,
  }

  ngOnInit(): void {
    this.getDetailInfo();

  }

  // Get detail information of enduser package.
  getDetailInfo() {
    this.customerManagerService.getPackageInfo(this.partyId).subscribe((res: any) => {
      const object = res?.object || {};
      // Get package info
      this.detail = object?.packageInfo || {};
      // Get extend infra history if package is not trial type.
      // if (this.detail?.packageType != 'TRIAL_TYPE') {
      //   this.getUpgradeHistory();
      // }
      // Build list service tree.
      this.listService = [];
      object?.listPackageService.forEach(content => {
        content.privilegesHidden = true;
        this.listService.push(content);
      });
      // Build list package infrastructure.
      object?.listPackageInfrastructure.forEach(content => {
        content.used = 0;
        content.allowed = 0;
        content.rate = 0;
        this.listPackageInfra.push(content);
      });
      // Sort list infra.
      this.listPackageInfra.sort((a, b) => (a.stt > b.stt ? 1 : -1));
      this.customerManagerService.getQuantityInfo(this.partyId).subscribe((res2: any) => {
        const object2 = res2?.object || {};
        this.listPackageInfra.forEach(value => {
          if (value.param) {
            switch (value.param) {
              case "contractNumber":
                value.used = object2?.usedContract || 0;
                value.allowed = object2?.allowedContract || 0;
                break;
              case "SMS_OTP":
                value.used = object2?.usedSMSOTP || 0;
                value.allowed = object2?.allowedSMSOTP || 0;
                break;
              case "EKYC":
                value.used = object2?.usedeKYC || 0;
                value.allowed = object2?.allowedeKYC || 0;
                break;
              case "SMS":
                value.used = object2?.usedSMS || 0;
                value.allowed = object2?.allowedSMS || 0;
                break;
              case "STORAGE_TIME":
                value.used = object2?.numberOfMonthStorageUsed || 0;
                value.allowed = object2?.numberOfMonthStorage || 0;
                break;
              case "STORAGE_CAPACITY":
                value.used = object2?.usedData || 0;
                value.allowed = object2?.allowedData || 0;
                break;
            }
          }
        });
        // // 1st
        // this.listPackageInfra[0].used = object?.usedContract || 0;
        // this.listPackageInfra[0].allowed = object?.allowedContract || 0;

        // // 2nd
        // this.listPackageInfra[1].used = object?.usedSMSOTP || 0;
        // this.listPackageInfra[1].allowed = object?.allowedSMSOTP || 0;

        // // 3rd
        // this.listPackageInfra[2].used = object?.usedeKYC || 0;
        // this.listPackageInfra[2].allowed = object?.allowedeKYC || 0;

        // // 4th
        // this.listPackageInfra[3].used = object?.numberOfMonthStorageUsed || 0;
        // this.listPackageInfra[3].allowed = object?.numberOfMonthStorage || 0;

        // // 5th
        // this.listPackageInfra[4].used = object?.usedData || 0;
        // this.listPackageInfra[4].allowed = object?.allowedData || 0;

        this.listPackageInfra.forEach((content) => {
          if (content.allowed != 0 && content.allowed >= content.used) {
            content.rate = Math.round((content.used / content.allowed) * 100);
          }
          else {
            content.rate = 0;
          }
        });

      });
    });
  }

  // Get upgrade infrastructure history
  // getUpgradeHistory() {
  //   this.customerManagerService.getPackageHistory(this.partyId).subscribe((res: any) => {
  //     this.history = res?.object || [];
  //     this.history.reverse();
  //     this.history.forEach((content) => {
  //       content?.infrastructures.sort((a, b) => (a.stt > b.stt ? 1 : -1));
  //     });
  //   });
  // }

  formatPrice(amount) {
    const number: Number = new Number(amount);
    const amountStr: string = number.toString();
    let amountArr: Array<string> = amountStr.split('');
    let count: number = 0;
    for (let i = amountArr.length - 1; i >= 0; i--) {
      count++;
      if (count == 3 && i > 0) {
        amountArr.splice(i, 0, ',');
        count = 0;
      }
    }
    const res: string = amountArr.join("");
    return res;
  }

  toggleService(i) {
    this.listService[i].privilegesHidden = !this.listService[i].privilegesHidden;
  }

  getStyleOfRate(rate) {
    if (rate < 50) {
      return "success-color";
    }
    else if (rate >= 50 && rate <= 75) {
      return "warning-color";
    }
    else if (rate > 75) {
      return "danger-color";
    }
  }

  getStyleAndValueBadge(status) {
    if (status == "ENABLED") {
      return ['badge-success-new', 'Đang hoạt động'];
    }
    return ['badge-primary-new', 'Ngưng hoạt động'];
  }

  sortCol(key) {
    if (this.sortColConfig[key] == null) {
      this.sortColConfig[key] = true;
    } else if (this.sortColConfig[key] == false) {
      this.sortColConfig[key] = null;
    } else {
      this.sortColConfig[key] = !this.sortColConfig[key];
    }
  }

}
