import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { LocationExtension } from 'src/app/utils/common/LocationExtension';
import { REGEXP_USERNAME } from 'src/app/utils/common/regexp';
import { AasValidateMessage } from 'src/app/utils/common/validate-msg-aas';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { LocalStorageKey, QueryParamKey } from '../../../wv-ultils/wv-const';

@Component({
  selector: 'app-wv-login-otp',
  templateUrl: './wv-login-otp.component.html',
  styleUrls: ['./wv-login-otp.component.scss']
})
export class WvLoginOtpComponent implements OnInit {

  constructor(
    private aasAuthService: AasAuthService,
    private alert: AlertControl,
    private router: Router,
    private translate: TranslateService
  ) { }

  loginForm: FormGroup;
  validateMsg = AasValidateMessage.LOGIN;
  isSubmit = false;


  ngOnInit(): void {
    this.makeLoginForm();
  }

  loginByOtp() {
    if (this.loginForm.valid) {
      this.aasAuthService.loginByOtp(this.username.value, LocationExtension.HOSTNAME).subscribe((res: any) => {
        if (res.object.email) {
          sessionStorage.setItem('username', this.username.value);
          sessionStorage.setItem('email', res.object.email);
        }
        else if (res.object.phoneNumber) {
          sessionStorage.setItem('username', this.username.value);
          sessionStorage.setItem('phoneNumber', res.object.phoneNumber);
        }
        this.router.navigate([`wv/verify-otp-login`]);
      }, err => {
        if (err.error && err.error.message && err.error.message === 'ECT-00001100') {
          this.alert.showAlertOnlyNoti(this.translate.instant('ForgotPassword_err_4'), ConstantAlertType.BLANK);
        }
        else {
          this.alert.showErrorHandled(err);
        }
      })
    }
  }

  loginByOtpSDK(clientId: string, clientSecret: string) {
    if (this.loginForm.valid) {
      this.aasAuthService.sdkLoginByOtp(this.username.value, LocationExtension.HOSTNAME, clientId, clientSecret).subscribe((res: any) => {
        if (res.object.email) {
          sessionStorage.setItem('username', this.username.value);
          sessionStorage.setItem('email', res.object.email);
        }
        else if (res.object.phoneNumber) {
          sessionStorage.setItem('username', this.username.value);
          sessionStorage.setItem('phoneNumber', res.object.phoneNumber);
        }
        this.router.navigate([`wv/verify-otp-login`]);
      }, err => {
        if (err.error && err.error.message && err.error.message === 'ECT-00001100') {
          this.alert.showAlertOnlyNoti(this.translate.instant('ForgotPassword_err_4'), ConstantAlertType.BLANK);
        }
        else {
          this.alert.showErrorHandled(err);
        }
      })
    }
  }

  /**
     * Check valid button
     * Allow click if username form is valid.
     */
  get disabledButton() {
    if (this.loginForm.valid) {
      return false;
    }
    return true;
  }

  /**
   * Init login form
   */
  makeLoginForm() {
    this.loginForm = new FormGroup({
      username: new FormControl(null, [Validators.required, Validators.pattern(REGEXP_USERNAME)])
    });
  }

  get username() { return this.loginForm.get('username') }

  /**
   * Action when click button
   */
  login() {
    this.isSubmit = true;

    try {
      const data: any = JSON.parse(localStorage.getItem(LocalStorageKey.queryParam));

      if (data[QueryParamKey.CLIENTID]) {
        this.loginByOtpSDK(data[QueryParamKey.CLIENTID], '');
      } else {
        this.loginByOtp();
      }
    } catch (error) {
      alert(error);
    }
  }


}
