import { AfterViewInit, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { delay } from 'rxjs/operators';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { DateUtils } from 'src/app/utils/common/DateUtils';
import {
  REGEXP_DATE_INPUT,
  REGEXP_EMAIL,
  REGEXP_PHONE_NUMBER,
  REGEXP_USERNAME,
} from 'src/app/utils/common/regexp';
import { localize } from 'src/app/utils/localize/localize';
import { AccountManagerService } from 'src/app/utils/services/aas-network/account-manager.service';
import { AuthService } from 'src/app/utils/services/auth.service';

@Component({
  selector: 'app-trusted-partner-add',
  templateUrl: './trusted-partner-add.component.html',
  styleUrls: ['./trusted-partner-add.component.scss'],
})
export class TrustedPartnerAddComponent implements OnInit, AfterViewInit {
  constructor(
    private fb: FormBuilder,
    private myAlert: AlertControl,
    private accApi: AccountManagerService,
    private router: Router,
    private authService: AuthService
  ) {}
  partyId = this.authService.partyId;
  notiTypes: any[];
  hasEmail = false;
  form: FormGroup;
  isSubmit = false;
  hasIndentifyInfo = false;
  validateMsg = {
    ten: {
      required: 'Vui lòng nhập họ và tên',
    },
    username: {
      required: 'Vui lòng nhập tên tài khoản',
      pattern:
        'Tên tài khoản phải viết liền không dấu, có ít nhất 6-50 ký tự, không chứa ký tự khoảng trống',
      unique: 'Tên đăng nhập đã tồn tại',
    },
    sdt: {
      required: 'Vui lòng nhập số điện thoại',
      pattern: 'Số điện thoại sai định dạng',
    },
    email: {
      required: 'Vui lòng nhập email',
      pattern: 'Email sai định dạng',
    },
    ngaysinh: {
      required: 'Vui lòng nhập ngày sinh',
      pattern: 'Ngày sinh sai định dạng',
    },
    gioitinhId: {
      required: 'Vui lòng chọn giới tính',
    },
    cmnd: {
      required: 'Vui lòng nhập số giấy tờ',
    },
    noisinh: {
      required: 'Vui lòng nhập nơi sinh',
    },
    ngaycap: {
      required: 'Vui lòng nhập ngày cấp',
      pattern: 'Ngày cấp sai định dạng',
    },
    noicap: {
      required: 'Vui lòng nhập nơi cấp',
    },
    expiryDate: {
      required: 'Vui lòng nhập có giá trị đến',
      pattern: 'Có giá trị đến sai định dạng',
    },
    frontImage: {
      required: 'Vui lòng đính kèm ảnh mặt trước',
    },
    backImage: {
      required: 'Vui lòng đính kèm ảnh mặt sau',
    },
    portraitImage: {
      required: 'Vui lòng đính kèm ảnh chân dung',
    },
  };
  gioiTinhs = [
    { id: 1, label: 'Nữ' },
    { id: 2, label: 'Nam' },
  ];
  optionsCleave = {
    date: true,
    delimiter: '/',
    datePattern: ['d', 'm', 'Y'],
  };
  currentDate = new Date();
  prefix = 'data:image/png;base64,';
  base64FrontImage = null;
  base64BackImage = null;
  base64PortraitImage = null;
  listIndentifyFiled = [
    'ten',
    'cmnd',
    'gioitinhId',
    'ngaycap',
    'noicap',
    'ngaysinh',
    'frontImage',
    'backImage',
    'portraitImage',
  ];
  onceOpened: boolean = false;
  ngOnInit(): void {
    this.makeForm();
    this.authService.getUserInfoNew(this.partyId).subscribe((res: any) => {
      if (res && res.object.notifyPushType.includes('EMAIL')) {
        // this.notiTypes = res.object.notifyPushType;
        this.hasEmail = true;
        // if (this.notiTypes.includes('EMAIL')) {
          this.form.controls['email'].setValidators([
            Validators.required,
            Validators.pattern(REGEXP_EMAIL),
          ]);
          this.form.controls['email'].updateValueAndValidity();
        // }
      }
    });
  }
  ngAfterViewInit(): void {
    let phoneInput = document.querySelector<HTMLInputElement>('#phone');
    let self = this;
    phoneInput.addEventListener('keyup', function (e: any) {
      if (this.value.length >= 10) {
        self.accApi.checkPhoneConsumerTrustedPartner(e.target.value).subscribe(
          (res: any) => {
            if (res && res.object instanceof Object) {
              if (res.object.ten) {
                //   self.myAlert.showAlert(
                //     `<p>Số điện thoại này đã được sử dụng để đăng ký tài khoản trên hệ thống VNPT eContract bởi:</p><strong>${res.object.ten}</strong><br><p>Vui lòng kiểm tra lại thông tin mã số thuế hoặc tạo hợp đồng với đối tác này ngay bây giờ.</p>`,
                //     '',
                //     true,
                //     '',
                //     'Tạo hợp đồng',
                //     ()=>{
                //       this.router.navigate(['/app/console/contract/create'])
                //     },
                //     '',
                //     localize.btn_cancel,
                //     null,
                //     localize.confirm
                //   );
                self.gotoCreateContract(res.object.ten);
              }
            }
          },
          (error) => {
            console.log(error);
          }
        );
      }
    });
  }
  gotoCreateContract(ten) {
    if (this.onceOpened) return;
    this.onceOpened = true;
    this.myAlert.showAlert(
      `<p>Số điện thoại này đã được sử dụng để đăng ký tài khoản trên hệ thống NBW eContract bởi:</p><strong>${ten}</strong><br><p>Vui lòng kiểm tra lại thông tin số điện thoại hoặc tạo hợp đồng với đối tác này ngay bây giờ.</p>`,
      '',
      true,
      '',
      'Tạo hợp đồng',
      () => {
        this.router.navigate(['/app/console/contract/create']);
      },
      '',
      localize.btn_cancel,
      null,
      localize.confirm,
      '', () => { this.onceOpened = false; }, () => { this.onceOpened = false; }
    );
  }
  makeForm() {
    // if (this.notiTypes.includes('EMAIL')) {
    //   this.form = this.fb.group({
    //     username: ['', [Validators.required, Validators.pattern(REGEXP_USERNAME)]],
    //     email: ['', [Validators.required, Validators.pattern(REGEXP_EMAIL)]],
    //     sdt: ['', [Validators.required, Validators.pattern(REGEXP_PHONE_NUMBER)]],
    //     ten: ['', []],
    //     cmnd: ['', []],
    //     gioitinhId: ['', []],
    //     dkhktt: ['', []],
    //     noisinh: ['', []],
    //     ngaycap: ['', []],
    //     noicap: ['', []],
    //     ngaysinh: ['', []],
    //     expiryDate: ['', []],
    //     frontImage: [null, []],
    //     backImage: [null, []],
    //     portraitImage: [null, []],
    //   });
    // } else {
    this.form = this.fb.group({
      username: ['', [Validators.required, Validators.pattern(REGEXP_USERNAME)]],
      email: ['', [Validators.pattern(REGEXP_EMAIL)]],
      sdt: ['', [Validators.required, Validators.pattern(REGEXP_PHONE_NUMBER)]],
      ten: ['', []],
      cmnd: ['', []],
      gioitinhId: [1, []],
      dkhktt: ['', []],
      noisinh: ['', []],
      ngaycap: ['', [Validators.pattern(REGEXP_DATE_INPUT)]],
      noicap: ['', []],
      ngaysinh: ['', [Validators.pattern(REGEXP_DATE_INPUT)]],
      expiryDate: ['', [Validators.pattern(REGEXP_DATE_INPUT)]],
      frontImage: [null, []],
      backImage: [null, []],
      portraitImage: [null, []],
    });
    // }
  }
  toggleForm() {
    if (!this.hasIndentifyInfo) {
      this.listIndentifyFiled.forEach((item) => {
        if (item == 'ngaycap' || item == 'ngaysinh') {
          this.form
            .get(item)
            ?.setValidators([Validators.required, Validators.pattern(REGEXP_DATE_INPUT)]);
        } else if (item == 'gioitinhId') {
          this.form.get(item)?.setValidators([Validators.required]);
          this.form.get(item)?.setValue(1);
        } else {
          this.form.get(item)?.setValidators([Validators.required]);
        }
        this.form.get(item)?.updateValueAndValidity();
      });
    } else {
      this.listIndentifyFiled.forEach((item) => {
        this.form.controls[item].clearValidators();
        this.form.controls[item].updateValueAndValidity();
      });
    }
  }
  // setValue(detail: PartnerConsumer) {
  //   this.form.patchValue({
  //     ten: detail.ten,
  //     cmnd: detail.cmnd,
  //     gioitinh: detail.gioiTinhId,
  //     hktt: detail.dkhktt,
  //     noisinh: detail.noiSinh,
  //     ngaycap: detail.ngayCap,
  //     noicap: detail.noiCap,
  //     ngaysinh: detail.ngaySinh,
  //     username: detail.username,
  //     email:detail.email,
  //     sdt: detail.sdt,
  //     expiryDate: detail.expiryDate,
  //     // frontImage: [null, [Validators.required]],
  //     // backImage: [null, [Validators.required]],
  //     // portraitImage: [null, [Validators.required]],
  //   });
  // }
  async uploadFile(event, type = '') {
    if (!event || event == null || event == undefined) return;

    for (var i = 0; i < event.length; i++) {
      const fileItem = event[i];
      let item = {
        name: fileItem.name,
        size: fileItem.size,
        type: fileItem.type,
      };

      console.log(fileItem);

      if (type === 'FRONT') {
        this.form.controls.frontImage.setValue(fileItem);
        var self = this;
        var reader = new FileReader();
        reader.onload = function (e) {
          self.base64FrontImage = reader.result;
        };
        reader.readAsDataURL(fileItem);
      } else if (type === 'BACK') {
        this.form.controls.backImage.setValue(fileItem);
        var self = this;
        var reader = new FileReader();
        reader.onload = function (e) {
          self.base64BackImage = reader.result;
        };
        reader.readAsDataURL(fileItem);
      } else if (type === 'PORTRAIT') {
        this.form.controls.portraitImage.setValue(fileItem);
        var self = this;
        var reader = new FileReader();
        reader.onload = function (e) {
          self.base64PortraitImage = reader.result;
        };
        reader.readAsDataURL(fileItem);
      }
    }
  }
  removeImage(type = '') {
    if (type === 'FRONT') {
      this.form.controls.frontImage.setValue(null);
      this.base64FrontImage = '';
    } else if (type === 'BACK') {
      this.form.controls.backImage.setValue(null);
      this.base64BackImage = '';
    } else if (type === 'PORTRAIT') {
      this.form.controls.portraitImage.setValue(null);
      this.base64PortraitImage = '';
    }
  }
  changeDateFilter(event, type) {
    switch (type) {
      case 'ngaysinh':
        this.form.controls.ngaysinh.setValue(DateUtils.convertObjtoDate(event));
        break;
      case 'ngaycap':
        this.form.controls.ngaycap.setValue(DateUtils.convertObjtoDate(event));
        break;
      case 'expiryDate':
        this.form.controls.expiryDate.setValue(DateUtils.convertObjtoDate(event));
        break;
    }
  }
  cancel() {
    this.router.navigate(['/app/console/partner/trusted']);
  }
  save() {
    this.isSubmit = true;
    console.log(this.form.valid);
    if (this.form.valid) {
      this.myAlert.showAlert(
        '<p>Bạn chắc chắn muốn khởi tạo tài khoản cho đối tác này?</p><p><em>Thông tin tài khoản sẽ được gửi đến đối tác ngay khi bạn nhấn nút Khởi tạo.</em></p>',
        '',
        true,
        '',
        'Khởi tạo',
        () => {
          this.addConsumerTrustedPartner();
        },
        '',
        'Đóng',
        null,
        localize.confirm
      );
    }
  }
  addConsumerTrustedPartner() {
    if (!this.hasIndentifyInfo) {
      let data = new FormData();
      let username = this.form.value.username || '';
      let sdt = this.form.value.sdt || '';
      let email = this.form.value.email || '';
      data.append('username', username);
      data.append('sdt', sdt);
      data.append('email', email);
      this.postConsumerTrustedPartner(data);
    } else {
      let data = new FormData();
      let username = this.form.value.username || '';
      let sdt = this.form.value.sdt || '';
      let email = this.form.value.email || '';
      let info = {
        ten: this.form.value.ten || '',
        cmnd: this.form.value.cmnd || '',
        ngaySinh: this.form.value.ngaysinh || '',
        noiSinh: this.form.value.noisinh || '',
        gioiTinhId: this.form.value.gioitinhId || '',
        ngayCap: this.form.value.ngaycap || '',
        noiCap: this.form.value.noicap || '',
        dkhktt: this.form.value.dkhktt || '',
        expiryDate: this.form.value.expiryDate || '',
      };
      data.append('username', username);
      data.append('sdt', sdt);
      data.append('email', email);
      data.append('info', JSON.stringify(info));
      if (this.form.value.frontImage)
        data.append('EKYC_MATTRUOC', this.form.value.frontImage, this.form.value.frontImage.name);
      if (this.form.value.backImage)
        data.append('EKYC_MATSAU', this.form.value.backImage, this.form.value.backImage.name);
      if (this.form.value.portraitImage)
        data.append(
          'EKYC_CHANDUNG',
          this.form.value.portraitImage,
          this.form.value.portraitImage.name
        );
      this.postConsumerTrustedPartner(data);
    }
  }
  postConsumerTrustedPartner(data) {
    for (let val of data.values()) {
      console.log(val);
    }
    this.accApi.postAddConsumerTrustedPartner(data).subscribe(
      (res: any) => {
        this.myAlert.showAlertOnlyNoti(
          'Tài khoản đối tác đã được khởi tạo thành công!',
          ConstantAlertType.SUCCESS
        );
        this.router.navigate(['app/console/partner/trusted']);
      },
      (err) => {
        console.log(err);
        this.myAlert.showErrorHandled(err);
      }
    );
  }
}
