<div class="wrapper">
    <nav class="navbar navbar-expand navbar-primary navbar-dark">
        <a class="navbar-brand" href="#">
            <!-- <img src="assets/img/icon-logo.png" width="30" height="30" class="d-inline-block align-top" alt=""> -->
            <h4 class="ml-3 font-weight-bold mb-0">eContract</h4>
        </a>
    </nav>

    <div #contentWrapper class="wrapper">
        <section class="content">
            <div class="container-fluid">
                <div class="row justify-content-center">
                    <section class="col-md-5">
                        <div class="content-step-card">
                            <div class="content-step">
                                <ul class="stepbar">
                                    <li [class.done]="stepControl[0].done" [class.active]="currentStep === 0">Bổ sung thông tin</li>
                                    <li [class.done]="stepControl[1].done" [class.active]="currentStep === 1">Chọn gói cước</li>
                                    <li [class.done]="stepControl[2].done" [class.active]="currentStep === 2">Xác minh tài khoản</li>
                                </ul>
                            </div>
                        </div>
                    </section>
                </div>

                <div class="row justify-content-center mt-3">
                    <div class="col-md-5 tab-step fade show" [class.d-none]="currentStep !== 0">
                        <app-signup-step-one (next)="handleNext($event, 0)"></app-signup-step-one>
                    </div>

                    <div class="col-md-11 tab-step fade show" [class.d-none]="currentStep !== 1">
                        <app-signup-step-two [dataForm]="dataForm" (next)="handleNext($event, 1)"></app-signup-step-two>
                    </div>

                    <div class="col-md-5 tab-step fade show" [class.d-none]="currentStep !== 2">
                        <app-signup-step-three [id]="dataForm.id || ''" [dataForm]="dataForm" (next)="handleNext($event, 2)"></app-signup-step-three>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>

<div id="ppi" style="height: 1in; width: 1in; position: absolute; top: -100%; left: -100%;"></div>