// "userId": "7d288d28-8144-11ea-8b62-e304652be9f6",
// "partyId": "c9523a35-6245-4f60-8291-00432c83e1f2",
// "ten": "Nguyễn Nho Tuý",
// "ngaySinh": "1989-03-26T00:00:00.000Z",
// "email": "local_ttkd_admin@vnpt.vn",
// "soNha": "10",
// "duong": "Nguyễn Tri Phương",
// "xa": "Phường Hải Châu  I",
// "huyen": "Quận Hải Châu",
// "tinh": "Thành phố Đà Nẵng",
// "donVi": null,
// "chucVu": "Giám đốc",
// "disable": null

export class UserDetailObject {
    userId: string;
    partyId: string;
    ten: string;
    ngaySinh: string;
    email: string;
    soNha: string;
    duong: string;
    xa: string;
    huyen: string;
    tinh: string;
    donVi: string;
    chucVu: string;
    fullDiaChi: string;
    sdt: string;
    disable: any;
    cmnd: string;
    created: any;
    gioiTinh: any;
    ngayCap: any;
    noiCap: any;
    rejectReason: string;
    approveDate: any;
    videocall: any;
    customerId: any;
    approverEmail: any;
    diaChiThuongTru: string;
    username: string = "";
    loaiGtId: string = "";

    constructor(obj = null) {
        obj = obj || {};
        this.userId = obj.userId || "";
        this.partyId = obj.partyId || "";
        this.ten = obj.ten || "";
        this.ngaySinh = obj.ngaySinh || "";
        this.email = obj.email || "";
        this.soNha = obj.soNha || "";
        this.duong = obj.duong || "";
        this.xa = obj.xa || "";
        this.huyen = obj.huyen || "";
        this.tinh = obj.tinh || "";
        this.donVi = obj.donVi || "";
        this.sdt = obj.sdt || "";
        this.chucVu = obj.chucVu || "";
        this.cmnd = obj.cmnd || "";
        this.created = obj.created || "";
        this.gioiTinh = obj.gioiTinh || "";
        this.ngayCap = obj.ngayCap || "";
        this.noiCap = obj.noiCap || "";
        this.rejectReason = obj.rejectReason || "";
        this.approveDate = obj.approveDate || "";
        this.videocall = obj.videocall || "";
        this.customerId = obj.customerId || "";
        this.approverEmail = obj.approverEmail || "";

        if (obj.disable === "Y" || obj.disable === true) {
            this.disable = true;
        } else {
            this.disable = false;
        }

        this.fullDiaChi = this.diaChi;

        this.diaChiThuongTru = obj.diaChi || "";

        this.username = obj.username || "";
        this.loaiGtId = obj.loaiGtId || "";
    }

    get diaChi() {
        var diaChi = "";
        if (this.soNha !== "") {
            diaChi += this.soNha + " ";
        }

        if (this.duong !== "") {
            diaChi += this.duong + ", ";
        }

        if (this.xa !== "") {
            diaChi += this.xa + ", ";
        }

        if (this.huyen !== "") {
            diaChi += this.huyen + ", ";
        }

        if (this.tinh !== "") {
            diaChi += this.tinh + ".";
        }

        return diaChi;
    }
}
