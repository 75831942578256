export class TinhItem {
    tinhId: number;
    maTinh: string;
    tenTinh: string;

    constructor(obj = null) {
        obj = obj || {};
        this.tinhId = obj.tinhId;
        this.maTinh = obj.maTinh  || '';
        this.tenTinh = obj.tenTinh || '';
    }
}