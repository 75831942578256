import { DSignItem } from "./d-sign-item.model";
import { ESignItem } from "./e-sign-item.model";

export class DocumentSignatures {
    hashok: any;
    kyDienTu: ESignItem[] = [];
    kySo: DSignItem[] = [];
    totalKySo: any;

    constructor(d = null) {
        d = d || {};

        this.hashok = d.hashok || false;
        if (d.kyDienTu && d.kyDienTu instanceof Array) {
            d.kyDienTu.forEach((item) => {
                this.kyDienTu.push(new ESignItem(item))
            })
        }
        if (d.kySo && d.kySo instanceof Array) {
            d.kySo.forEach((item) => {
                this.kySo.push(new DSignItem(item))
            })
        }
        this.totalKySo = d.totalKySo || 0;
    }
}