<div class="method-example">
    <div class="method-example-topbar">
        <div class="method-example-title method-name method-{{ method | lowercase }} ">{{ method }} </div>
        <div class="method-example-url">{{ url }}</div>
        <!-- <div class="copyClipBoard" (click)="copyClipboard($event, codeContent)">Sao chép</div> -->
    </div>
    <div class="codeblock-content prism-live">
        <!-- <pre #preCode class="codeblock-pre prism-live" ><code #codeContent class="language-{{ type }}" [innerHTML]="highLightCode(code, type)"></code></pre> -->
        <textarea #textareaCode class="prism-live language-{{ type }} fill no-highlight" [(ngModel)]="mytext" (input)="inputTextarea($event)"></textarea>
        <!-- <pre class="codeblock-pre" ><code #codeContent class="language-{{ type }}" [innerHTML]="highLightCode(code, type)"></code></pre> -->
    </div>
</div>