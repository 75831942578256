<app-spinner></app-spinner>
<section class="content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-6">
                <div class="card ec-card shadow-none">
                    <div class="card-header">
                        <div class="d-flex">
                            <div class="card-title-path flex-grow-1">
                                <h1 class="m-0 text-dark card-title"><label>{{ 'PartAdd_title' | translate }}</label></h1>
                            </div>
                            <div class="card-btn-path ml-auto text-right">
                                <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="cancel()"><span
                                        class="material-icons mr-1">close</span>{{ 'btn_cancel' | translate }}</button>
                                <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="reset()"><span class="material-icons-outlined mr-1">
                                            refresh
                                            </span>{{ 'btn_reset' | translate }}</button>
                                <button class="btn btn-primary d-inline-flex" (click)="save()"><span
                                        class="material-icons mr-1">save</span>{{ 'btn_save' | translate }}</button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <form [formGroup]="partForm">
                            <div class="form-group">
                                <label>{{ 'PartAdd_label_department_name' | translate }} <span class="text-danger">*</span>:</label>
                                <input appTrimValue class="form-control" placeholder="{{ 'PartAdd_placeholder_department_name' | translate }}" formControlName="partyName">
                                <app-show-validate-errors [isSubmit]="isSubmit"
                                    [errorMessages]="validatePartForm.partyName" [control]="partForm.controls.partyName"
                                    [detail]="{ name: 'partyName' }"></app-show-validate-errors>
                            </div>
                            <div class="form-group">
                                <label>{{ 'PartAdd_label_description' | translate }}:</label>
                                <textarea appTrimValue class="form-control" rows="5" formControlName="descr" placeholder="{{ 'PartAdd_placeholder_description' | translate }}"></textarea>
                            </div>
                            <div class="form-group">
                                <label>{{ 'PartAdd_label_phone_number' | translate }}:</label>
                                <input appTrackerPhoneNumber onKeyPress="if(this.value.length==10) return false;" class="form-control" placeholder="{{ 'PartAdd_placeholder_phone_number' | translate }}" formControlName="phoneNumber">
                                <app-show-validate-errors [isSubmit]="isSubmit"
                                    [errorMessages]="validatePartForm.phoneNumber" [control]="partForm.controls.phoneNumber"
                                    [detail]="{ name: 'sdt' }"></app-show-validate-errors>
                            </div>
                            <div class="form-group">
                                <label>{{ 'PartAdd_label_fax_number' | translate }}:</label>
                                <input appTrimValue class="form-control" placeholder="{{ 'PartAdd_placeholder_fax_number' | translate }}" formControlName="fax">
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>