export class VerifyCodeObject {
    createdDate: Date;
    createdBy: string;
    modifiedDate: Date;
    modifiedBy: string;
    id: string;
    accessTokenExpiredTime: number;
    refreshTokenExpiredTime: number;
    email: string;
    groupDefault: GroupItem;
    groupList: Array<GroupItem> = [];
    certificateDefault: CertificateItem;
    certificateList: Array<CertificateItem> = [];
    
    constructor(obj = null) {
        obj = obj || {};
        this.createdDate = obj.createdDate || '';
        this.createdBy = obj.createdBy || '';
        this.modifiedDate = obj.modifiedDate || '';
        this.modifiedBy = obj.modifiedBy || '';
        this.id = obj.id || '';
        this.accessTokenExpiredTime = obj.accessTokenExpiredTime || '';
        this.refreshTokenExpiredTime = obj.refreshTokenExpiredTime || '';
        this.email = obj.email || '';
        
        this.groupDefault = new GroupItem(obj.groupDefault);
        this.groupList = [];
        if (obj.groupList && obj.groupList instanceof Array) {
            obj.groupList.forEach(item => {
                this.groupList.push(new GroupItem(item));
            });
        }

        this.certificateDefault = new CertificateItem(obj.certificateDefault);
        this.certificateList = [];
        if (obj.certificateList && obj.certificateList instanceof Array) {
            obj.certificateList.forEach(item => {
                this.certificateList.push(new CertificateItem(item));
            });
        }
    }
}

export class GroupItem {
    id: string = '';
    tenNhom: string = '';
    ngayHetHan: string = '';
    adminEmail: string = '';
    soLuotKyConLai: number = 0;

    constructor(obj = null) {
        obj = obj || {};
        this.id = obj.id || '';
        this.tenNhom = obj.tenNhom || '';
        this.ngayHetHan = obj.ngayHetHan || '';
        this.adminEmail = obj.adminEmail || '';
        this.soLuotKyConLai = Number(obj.soLuotKyConLai);
    }
}

export class CertificateItem {
    id: string = '';
    status: string = '';
    certBase64: string = '';
    serialNumber: string = '';
    isserDN: string = '';
    validTo: string = '';
    validFrom: string = '';
    subjectDN: string = '';
    accountEmail: string = '';

    constructor(obj = null) { 
        obj = obj || {};
        this.id = obj.id || '';
        this.status = obj.status || '';
        this.certBase64 = obj.certBase64 || '';
        this.serialNumber = obj.serialNumber || '';
        this.isserDN = obj.isserDN || '';
        this.validTo = obj.validTo || '';
        this.validFrom = obj.validFrom || '';
        this.subjectDN = obj.subjectDN || '';
        this.accountEmail = obj.accountEmail || '';
    }
}