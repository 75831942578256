import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HomeService } from 'src/app/utils/services/home/home.service';
import { HomeV4Component } from '../home-v4/home-v4.component';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {

  @Input() experience: any = null;
  @Input() appLink: any = null;
  @Input() configs: any = [];
  @Input() link: any = null;


  commonQuestion: any = null;
  guide: any = null;

  isPost: number = 0;

  constructor(
    private homeV4: HomeV4Component,
    private homeService: HomeService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.getCommonQuestion(this.homeV4.translate.currentLang);
    this.getGuide(this.homeV4.translate.currentLang);
  }

  getCommonQuestion(language) {
    this.homeService.getDataByCodeCategory("commonQuestion", language).subscribe((res: any) => {
      this.commonQuestion = res?.object?.item;
    });
  }

  getGuide(language) {
    this.homeService.getDataByCodeCategory("esolManual", language).subscribe((res: any) => {
      this.guide = res?.object?.item;
    });
  }

  reload(language) {
    this.getCommonQuestion(language);
    this.getGuide(language);
  }

  // Move to user-manual post.
  gotopost(a) {
    // const defaultShouldReuseRoute = this.router.routeReuseStrategy.shouldReuseRoute;
    // this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.navigate(['/post', a]);
    // setTimeout(() => {
    //   this.router.routeReuseStrategy.shouldReuseRoute = defaultShouldReuseRoute;
    // }, 100)
  }
}
