<form [formGroup]="formConsumerSubmit">
    <div class="form-group">
        <label for="">{{ 'RegisterBusiness_h_7' | translate }}<span class="text-danger">*</span></label>
        <input appTrimValue formControlName="username" type="text" class="form-control"
            placeholder="{{ 'RegisterBusiness_place_6' | translate }}">
        <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="validateMsg.username"
            [control]="formConsumerSubmit.controls.username" [detail]="{ name: 'username' }">
        </app-show-validate-errors>
    </div>
    <div class="row pl-0 pr-0">
        <div class="col-md-6">
            <div class="form-group">
                <label for="">{{ 'RegisterBusiness_h_5' | translate }}<span class="text-danger">*</span></label>
                <input appTrimValue formControlName="email" type="text" class="form-control" placeholder="{{ 'RegisterBusiness_place_4' | translate }}">
                <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="validateMsg.email"
                    [control]="formConsumerSubmit.controls.email" [detail]="{ name: 'email' }">
                </app-show-validate-errors>
            </div>
        </div>

        <div class="col-md-6">
            <div class="form-group">
                <label for="">{{ 'phone_number' | translate }}<span class="text-danger">*</span></label>
                <input formControlName="sdt" type="text" class="form-control" placeholder="{{ 'ProfileBusiness_agent_input_phone' | translate }}"
                    (input)="handleInputSdt($event)">
                <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="validateMsg.sdt"
                    [control]="formConsumerSubmit.controls.sdt" [detail]="{ name: 'sdt' }"></app-show-validate-errors>
            </div>
        </div>
    </div>
    <div class="form-group">
        <label for="">{{ 'RegisterBusiness_h_8' | translate }}<span class="text-danger">*</span></label>
        <div class="d-block position-relative">
            <input appInputPassword formControlName="password" type="password" class="form-control"
                placeholder="{{ 'RegisterBusiness_place_7' | translate }}">
        </div>
        <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="validateMsg.password"
            [control]="formConsumerSubmit.controls.password" [detail]="{ name: 'password' }">
        </app-show-validate-errors>
        <!-- <app-show-validate-errors [isSubmit]="isSubmit"
                [errorMessages]="validateMsg.password" [control]="password"
                [detail]="{ name: 'password' }"></app-show-validate-errors>
            <p class="error text-danger" *ngIf="loginFailed && loginForm.valid">Tài
                khoản hoặc mật khẩu
                không đúng!</p> -->
    </div>
    <div class="form-group">
        <label for="">{{ 'RegisterBusiness_h_4' | translate }}<span class="text-danger">*</span></label>
        <select class="form-control" formControlName="tinhId">
            <option *ngFor="let tinh of tinhBindData" [value]="tinh.id">{{
                tinh.label }}</option>
        </select>
        <app-show-validate-errors [isSubmit]="isSubmit" [errorMessages]="validateMsg.tinhId"
            [control]="formConsumerSubmit.controls.tinhId" [detail]="{ name: 'tinhId' }">
        </app-show-validate-errors>
    </div>

    <span class="footer first">
        <input type="checkbox" value="false" [(ngModel)]="checkbox_dieukhoan" [ngModelOptions]="{standalone: true}">
        {{ 'RegisterBusiness_h_9' | translate }}&nbsp;<span (click)="showDieuKhoan()"
            class="text-primary text-decoration-underline cursor-pointer">{{ 'RegisterBusiness_h_10' | translate }}</span>&nbsp;{{ 'RegisterBusiness_h_11' | translate }}
    </span>
    <span class="footer">
        {{ 'RegisterBusiness_h_12' | translate }} <a href="">{{ 'RegisterBusiness_h_13' | translate }}</a>
    </span>
    <button type="button" class="btn_reg" (click)="submit()">{{ 'RegisterBusiness_h_14' | translate }}</button>
</form>