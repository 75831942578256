import { ErrorService } from './../../../utils/services/error/error.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/utils/services/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit } from '@angular/core';
import { AlertComponent } from '../alert/alert.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GlobalConfig } from 'src/app/utils/common/global-config/global-config';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertBtn, ConstantAlertMsg } from 'src/app/utils/common/constant-alert-msg';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';

export const enum RequestErrorType {
  REQUEST_TIME_OUT = "REQUEST_TIME_OUT",
  TOKEN_TIME_OUT = "TOKEN_TIME_OUT",
  NORMAL = "NOMAL"
}

@Component({
  selector: 'app-request-error',
  templateUrl: './request-error.component.html',
  styleUrls: ['./request-error.component.scss']
})
export class RequestErrorComponent implements OnInit {

  private ngUnsubscribe = new Subject();

  constructor(
    public errorService: ErrorService,
    private auth: AuthService,
    private router: Router,
    public alertControl: AlertControl,
    private modalService: NgbModal) {
    this.initializeErrors();
  }

  ngOnInit(): void {

  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private initializeErrors() {
    this.errorService.isVisible().pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
        if (res !== null && res !== undefined) {
          this.modalService.dismissAll(); //Dismiss tất cả các modal khác trước khi show model error tổng

          if (res.type === RequestErrorType.REQUEST_TIME_OUT) {
            this.alertControl.showTimeOutAlert();
          }

          if (res.type === RequestErrorType.TOKEN_TIME_OUT) {
            this.alertControl.showTimeOutTokenAlert(ConstantAlertMsg.TOKEN_TIMEOUT, ConstantAlertType.ERROR, '', 'Đăng nhập lại', () => {
              this.auth.logout();
              this.router.navigate([ConstantUrl.LOGIN]);
            });
          }
        }
      });
  }
}
