<h5 class="ec-tab-content-title mt-3">{{ 'VersionInContract_title' | translate }}</h5>

<div class="mt-4">
    <ng-select [items]="filterOptions" [(ngModel)]="selectedFilter" bindValue="value"
        [clearable]="false" [searchable]="false" (change)="getVersionByContractId()">
        <ng-template ng-label-tmp let-item="item">
            <span>{{ item.label | translate }}</span>
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
            <span>{{ item.label | translate }}</span>
        </ng-template>
    </ng-select>
</div>

<div *ngIf="listContractVersion && listContractVersion.length > 0" class="mb-3">
    <div *ngFor="let item of listContractVersion; let i = index">
        <div class="version mt-3">
            <div class="version-header" [class.expaned]="item.expanded == true" (click)="selectVersion(item)">
                <div class="row">
                    <div class="col">
                        <div class="px-3 pt-2 pb-2">
                            <label *ngIf="!item.updating" class="mb-1 version-name">{{ item?.name }}</label>
                            <div *ngIf="item.updating" class="mb-1">
                                <div class="row">
                                    <div class="col">
                                        <input class="form-control" [(ngModel)]="item.tempName">
                                    </div>
                                    <div class="col-md-auto more-vert-wrapped">
                                        <span class="material-icons pr-1 icon-done" (click)="changeVersionNameById(i)">done</span>
                                        <span class="material-icons pl-1 icon-clear" (click)="actionClearChange(i)">clear</span>
                                    </div>
                                </div>
                            </div>
                            <p *ngIf="item.alreadyEdit" class="mb-1 version-creation-time">{{ item?.formattedDate }}
                            </p>
                            <p class="mb-1 version-creator">{{ item?.nameUser }}</p>
                        </div>
                    </div>
                    <div class="col-md-auto ml-auto more-vert-wrapped">
                        <div *ngIf="contract.allowEditVersionInfo" class="menu-link">
                            <div ngbDropdown class="dropdown" (click)="onEvent($event)">
                                <button ngbDropdownToggle class="btn btn-dropdown dropdown-toggle btnMenuLink"
                                    id="dropdownMenuLink">
                                    <em class="material-icons">more_vert</em>
                                </button>

                                <div ngbDropdownMenu aria-labelledby="dropdownMenuLink">
                                    <!-- [routerLink]="['/console/licenses-manager/detail/', item.licenseId]" -->
                                    <a ngbDropdownItem *ngIf="item.alreadyEdit" class="dropdown-item nav-material d-inline-flex"
                                        style="cursor: pointer;" (click)="actionChangeVersionName(i)">{{ 'VersionInContract_action_1' | translate }}</a>
                                    <a ngbDropdownItem *ngIf="!item.alreadyEdit" class="dropdown-item nav-material d-inline-flex"
                                        style="cursor: pointer;" (click)="actionChangeVersionName(i)">{{ 'VersionInContract_action_2' | translate }}</a>
                                    <a ngbDropdownItem *ngIf="i > 0" class="dropdown-item nav-material d-inline-flex"
                                        style="cursor: pointer;"
                                        (click)="restoreVersionByContractIdAndTraceId(item?.id)">{{ 'VersionInContract_action_3' | translate }}</a>
                                    <a ngbDropdownItem *ngIf="item?.lasted == false" class="dropdown-item nav-material d-inline-flex" style="cursor: pointer;"
                                        (click)="deleteVersionById(item?.id)">{{ 'VersionInContract_action_4' | translate }}</a>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div *ngIf="item.expanded == true" class="content-expanded">
                <hr *ngIf="item.posts.length > 0" class="mb-0 mt-0" style="color: #A0AEC0;">

                <div *ngFor="let post of item.posts" class="version-cmt" [class.active]="notePointSelected?.id == post.id" (click)="selectPost(post)">
                    <div class="px-3 pt-2 pb-2">
                        <label>{{ post.fullName }} - {{ post.email }}</label>
                        <p style="color: #718096;">{{ dateUtil.formatDate(post.createdDate, translate.currentLang) }}</p>
                        <p>{{ post.value }}</p>
                        <div *ngIf="post.replies?.length > 0" style="color: #718096;" class="d-inline-flex">
                            <span class="material-icons-outlined mr-2">
                                reply
                            </span>
                            <span>{{ post.replies?.length }} {{ 'VersionInContract_reply_count' | translate }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>