
<app-spinner></app-spinner>
<div class="wrapper">
    <div class="preview-overlay">

        <div class="preview-container">
            <div class="preview-header d-flex">
                <div (click)="goToBack()"
                    class="ct-header-btn-back d-flex justify-content-center align-items-center cursor-pointer">
                    <span class="material-icons">arrow_back_ios</span>
                </div>
                <div class="ct-title flex-grow-1 d-flex align-items-center px-3">
                    <h4 class="mb-0">
                        {{ contractId !== "" && contractId !== null ? ('ServiceContract_title1' | translate) : ('ServiceContract_title2' | translate ) }}</h4>
                </div>
                <div class="ct-toolbar-btn d-flex align-items-center">
                    <button (click)="startGuideTour()" style="background-color: #d3dfeb;"
                        data-guide="contract-end"
                        class="btn d-flex align-items-center mr-3"><span class="text-secondary material-icons-outlined">
                            help_outline
                            </span></button>
                    <button *ngIf="currentStepCreate == 0" (click)="actCancelBtn($event)"
                        class="btn btn-outline-primary d-flex align-items-center mr-3"><span
                            class="material-icons mr-2">clear</span> {{'btn_cancel' | translate}}</button>
                    <button *ngIf="currentStepCreate == 0" (click)="actNext()" [disabled]="editedForm && disabledNext"
                        class="btn btn-primary d-flex align-items-center mr-3">
                        {{'ServiceContract_next' | translate}}
                        <span class="material-icons ml-2">arrow_forward</span>
                    </button>
                    <button *ngIf="currentStepCreate == 1" (click)="actBack()" [disabled]="editedForm"
                        class="btn btn-outline-primary d-flex align-items-center mr-3">
                        <span class="material-icons mr-2">arrow_back</span>
                        {{'ServiceContract_back' | translate}}
                    </button>
                    <button *ngIf="currentStepCreate == 1" (click)="actBtnSave($event)" [disabled]="editedForm"
                        class="btn btn-primary d-flex align-items-center mr-3"><span
                            class="material-icons mr-2">save</span> {{'btn_save' | translate}}</button>
                    <!-- <button *ngIf="allowSendContract" (click)="saveAndSendContract()"
                            class="btn btn-primary d-flex align-items-center mr-3" placement="left" ngbTooltip="Lưu và gửi hợp đồng cho các bên"><span
                                class="material-icons mr-2">send</span> Gửi</button> -->
                </div>
            </div>

            <div class="preview-body">
                <div class="pv ct">
                    <div class="pv-body">
                        <div class="ctpv-container">
                            <div class="ctpv-content">
                                <div class="ct-container">
                                    <div class="ct-thumb-page">
                                        <div class="ct-vs enable-scroll">
                                            <ol id="thumbnail-page" class="ct-vs-list mt-3"
                                                [class.d-none]="typeSelected !== 1 && typeSelected !== 0">
                                            </ol>
                                        </div>
                                    </div>
                                    <div id="ppi"
                                        style="height: 1in; width: 1in; position: absolute; top: -100%; left: -100%;">
                                    </div>
                                    <div class="ct-content">
                                        <div id="parentView" class="ct-doc-loaded enable-scroll"
                                            [class.d-none]="typeSelected !== 1 && typeSelected !== 0">

                                        </div>

                                        <div class="ct-doc-loaded enable-scroll" [class.d-none]="typeSelected !== 2">
                                            <ckeditor #myEditor [editor]="Editor" data="<p>Nội dung hợp đồng!</p>">
                                            </ckeditor>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="ct ctpv-tool-bar">
                            <div class="ctpv-tool-sidebar">
                                <div class="ctpv-tabbar">
                                    <div class="ctpv-main">
                                        <button (click)="selectTab('INFO')" class="ctpv-btn"
                                            [class.active]="activeTab == 'INFO'"> <span
                                                class="material-icons-outlined">info</span></button>
                                        <!-- <button (click)="selectTab('GESTURE')" class="ctpv-btn"
                                            [class.active]="activeTab == 'GESTURE'"> <span
                                                class="material-icons">gesture</span></button> -->
                                    </div>
                                </div>
                                <div class="ctpv-footer">

                                </div>
                            </div>
                            <div class="ctpv-tool-content" >
                                <div class="ctpv-content-wrapper enable-scroll px-3">
                                    <div id="tab-info" class="ctpv-scroll-content"
                                        [class.d-none]="!(activeTab === 'INFO')">
                                        <div class="row justify-content-center">
                                            <section class="col-12">
                                                <div class="content-step-card">
                                                    <div class="content-step">
                                                        <ul class="stepbar">
                                                            <li [class.done]="stepControl[0].done" [class.active]="currentStepCreate === 0">
                                                                <span class="step-count">1</span>
                                                                <span class="mt-2">{{'ServiceContract_enterGeneralInfor' | translate}}</span>
                                                            </li>
                                                            <li [class.done]="stepControl[1].done" [class.active]="currentStepCreate === 1">
                                                                <span class="step-count">2</span>
                                                                <span class="mt-2">{{'ServiceContract_selectSignPosition' | translate}}</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </section>
                                        </div>
                        
                                        <div class="row justify-content-center mt-3">
                                            <div class="col-12 tab-step fade show" [class.d-none]="currentStepCreate !== 0">
                                                <div class="d-flex align-items-center mt-2" style="min-height: 2.4rem">
                                                    <h5 class="font-weight-bold m-0">{{'ServiceContract_infor' | translate}}</h5>
                                                    <!-- <button *ngIf="typeSelected === 0" class="btn btn-primary ml-auto" (click)="startFillData()">Điền biến</button> -->
                                                </div>
        
                                                <form [formGroup]="formContract">
                                                    <div class="row mt-4 mb-3"  data-guide="type-contract" >
                                                        <div class="col-sm-4" >
                                                            <div class="rcheck-primary d-inline">
                                                                <input [attr.checked]="typeSelected == 0 ? '' : null" type="radio" name="type" id="rr0">
                                                                <label for="rr0" (click)="selectType(0)">
                                                                    {{'ServiceContract_initFromSample' | translate}}
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-4">
                                                            <div class="rcheck-primary d-inline">
                                                                <input [attr.checked]="typeSelected == 1 ? '' : null" type="radio" name="type" id="rr1">
                                                                <label for="rr1" (click)="selectType(1)">
                                                                    {{'ServiceContract_downloadAttachment' | translate}}
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-4">
                                                            <div class="rcheck-primary d-inline">
                                                                <input [attr.checked]="typeSelected == 2 ? '' : null" type="radio" name="type" id="rr2">
                                                                <label for="rr2" (click)="selectType(2)">
                                                                    {{'ServiceContract_write' | translate}}
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
        
                                                    <div *showWithRoles="rolesCode.rolesQuanlyLuong" class="row">
                                                        <div class="col-md-12" data-guide="contract-flow">
                                                            <div class="d-flex flex-column">
                                                                <label>{{'ServiceContract_flow' | translate}}</label>
                                                                <div class="form-group d-flex">
                                                                    <ng-select
                                                                        [items]="dropListFlow | async"
                                                                        bindLabel="name"
                                                                        bindValue="contractFlowTemplateId"
                                                                        [trackByFn]="trackByFnFlow" 
                                                                        [loading]="flowLoading"
                                                                        [typeahead]="flowInput$"
                                                                        (change)="changeFlow($event)"
                                                                        (scrollToEnd)="onScrollToEndDropListFlow()"
                                                                        formControlName="flowTemplateId"
                                                                        class="flex-grow-1"
                                                                        placeholder="{{'ServiceContract_ph_flow' | translate}}"
                                                                    >
                                                                    </ng-select>
                                                                    <button class="btn btn-primary btn-icon ml-2" (click)="settingFlow()">
                                                                        <span class="material-icons">settings</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                    <div *showWithRoles="rolesCode.rolesQuanlyLuong">
                                                        <div *showWithRoles="rolesCode.rolesContractNumberConfig" class="row">
                                                            <div class="col-sm-12">
                                                                <div class="contract-number-form d-flex flex-column mb-3">
                                                                    <label>{{ 'ServiceContract_contractNumber' | translate }}</label>
                                                                    <div *ngIf="!contractNumberBox || contractNumberBox?.action == 'CREATE'" class="form-group d-flex mb-1">
                                                                        <input formControlName="sequenceName" class="form-control"
                                                                            type="text" disabled>
                                                                        <div class="d-flex justify-content-center align-items-center">
                                                                            <button class="btn btn-secondary btn-icon mr-1"
                                                                            (click)="getNextContractNumber()" >
                                                                            <span class="material-icons-outlined">
                                                                                arrow_drop_up
                                                                                </span>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    <span class="font-italic" *ngIf="sequenceId.value == null || sequenceId.value == ''">
                                                                        <span class="text-grey-darkest">{{ 'ServiceContract_flow_no_contractNumber' | translate }}</span> 
                                                                        <a class="text-decoration-underline text-primary" [routerLink]="['/app/console/configure-contract-number']" >
                                                                            {{ 'ServiceContract_config_contractNumber_here' | translate }}
                                                                        </a>
                                                                    </span>
                                                                    <span class="font-italic text-grey-darkest" *ngIf="contractNumberBox?.action == 'CREATE'">{{ 'ServiceContract_contractNumber_lb1' | translate }}</span>
                                                                    <span class="font-italic text-grey-darkest" *ngIf="contractNumberBox?.action == 'SUBMIT'">{{ 'ServiceContract_contractNumber_lb2' | translate }}</span>
                                                                    <span class="font-italic text-grey-darkest" *ngIf="contractNumberBox?.action == 'SIGN'">{{ 'ServiceContract_contractNumber_lb3' | translate }}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
        
                                                    <div class="row">
                                                        <div class="col-sm-12" data-guide="contract-name">
                                                            <div class="form-group">
                                                                <label>{{'ServiceContract_name' | translate}} <span class="text-danger">*</span></label>
                                                                <input formControlName="tenHopDong" class="form-control"
                                                                    type="text" placeholder="{{'ServiceContract_ph_name' | translate}}">
        
                                                                <app-show-validate-errors [isSubmit]="isSubmitContract"
                                                                    [errorMessages]="validContractMsg.tenHopDong"
                                                                    [control]="formContract.controls.tenHopDong"
                                                                    [detail]="{ name: 'tenHopDong' }">
                                                                </app-show-validate-errors>
                                                            </div>
                                                        </div>
                                                    </div>
        
                                                    <div *ngIf="typeSelected === 0" class="row">
                                                        <div class="col-md-12">
                                                            <div class="d-flex flex-column">
                                                                <label>{{'ServiceContract_sample' | translate}} <span class="text-danger">*</span></label>
                                                                <div class="form-group d-flex">
                                                                    <ng-select 
                                                                        [items]="contractTemplateData"
                                                                        bindLabel="templateName"
                                                                        bindValue="id"
                                                                        [clearable]="false"
                                                                        (change)="selectTemplate($event)"
                                                                        formControlName="templateId"
                                                                        placeholder="{{'ServiceContract_ph_sample' | translate}}..."
                                                                        class="flex-grow-1">
                                                                    </ng-select>
                                                                    <button class="btn btn-primary btn-icon ml-2" (click)="startFillData()">
                                                                        <span class="material-icons">playlist_add</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="typeSelected === 1" class="row">
                                                        <div class="col-sm-12" >
                                                            <div class="form-group">
                                                                <input #output id="output" class="" value="" type="hidden">
                                                                <input #filebase id="fileBase" class="" type="hidden">
                                                                <input #filename id="fileName" class="" value="{{ fileName }}"
                                                                    type="hidden">
                                                                <label>{{'ServiceContract_attachment' | translate}} <span class="text-danger">*</span>:</label>
                                                                <div class="input-group mb-1">
                                                                    <div class="custom-file custom-file-icon">
                                                                        <span
                                                                            class="icon-file material-icons">attach_file</span>
                                                                        <input [attr.disabled]="typeSelected !== 1 ? '' : null"
                                                                            formControlName="file"
                                                                            (input)="handleFileInput($event)" type="file"
                                                                            accept=".pdf,.docx" class="custom-file-input"
                                                                            id="inputGroupFile01"
                                                                            aria-describedby="inputGroupFileAddon01">
                                                                        <label class="custom-file-label"
                                                                            for="inputGroupFile01">{{ inputFileLabel | translate }}</label>
                                                                    </div>
                                                                </div>
                                                                <app-show-validate-errors [isSubmit]="isSubmitContract"
                                                                    [errorMessages]="validContractMsg.file"
                                                                    [control]="formContract.controls.file"
                                                                    [detail]="{ name: 'file' }"></app-show-validate-errors>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <!-- Chọn loại hợp đồng -->
                                                    <!-- <div *ngIf="typeSelected === 0 || typeSelected === 1" class="row">
                                                        <div class="col-sm-12">
                                                            <div class="form-group">
                                                                <label for="status">{{ 'ContractTypes_list_title' | translate }}</label>
                                                                <ng-select formControlName="typeId" [items]="dropListTypeData | async" bindValue="contractTypeId" bindLabel="name"
                                                                    placeholder="{{ 'ContractTypeList_ph_select' | translate }}" [clearable]="false" [trackByFn]="trackByFn1"
                                                                    [loading]="typesLoading" [typeahead]="typeInput$" (change)="changeType($event)" (scrollToEnd)="onScrollToEnd()">
                                                                </ng-select>
                                                            </div>
                                                        </div>
                                                    </div> -->
        
                                                    <div class="row">
                                                        <div class="col-xl-6">
                                                            <div class="form-group">
                                                                <label>{{'ServiceContract_fromDate' | translate}}
                                                                </label>
                                                                <div class="input-group">
                                                                    <!-- formControlName="ngayHieuLuc" [value]="formContract.value.ngayHieuLuc" -->
                                                                    <input id="ngayHieuLuc" class="form-control"
                                                                        [control]="formContract.controls.ngayHieuLuc"
                                                                        [value]="formContract.value.ngayHieuLuc"
                                                                        (dateSelect)="changeDate($event, dateType.ISSUE)"
                                                                        placeholder="dd/MM/yyyy" ngbDatepicker
                                                                        #ngayHieuLucContract="ngbDatepicker"
                                                                        appInputMark [options]="optionsCleave">
                                                                    <div class="input-group-append">
                                                                        <button class="btn btn-outline-secondary"
                                                                            (click)="ngayHieuLucContract.toggle()"
                                                                            type="button"><em
                                                                                class="fas fa-calendar-alt"></em></button>
                                                                    </div>
                                                                </div>
                                                                <app-show-validate-errors [isSubmit]="isSubmitContract"
                                                                    [errorMessages]="validContractMsg.ngayHieuLuc"
                                                                    [control]="formContract.controls.ngayHieuLuc"
                                                                    [detail]="{ name: 'ngayHieuLuc' }">
                                                                </app-show-validate-errors>
                                                            </div>
                                                        </div>
        
                                                        <div class="col-xl-6">
                                                            <div class="form-group">
                                                                <label>{{'ServiceContract_toDate' | translate}}
                                                                </label>
                                                                <div class="input-group">
                                                                    <!-- formControlName="ngayHetHieuLuc" [value]="formContract.value.ngayHetHieuLuc" -->
                                                                    <input id="ngayHetHieuLuc" class="form-control"
                                                                        [control]="formContract.controls.ngayHetHieuLuc"
                                                                        [value]="formContract.value.ngayHetHieuLuc"
                                                                        (dateSelect)="changeDate($event, dateType.EXPRIRED)"
                                                                        placeholder="dd/MM/yyyy" ngbDatepicker
                                                                        #ngayHetHieuLucContract="ngbDatepicker"
                                                                        appInputMark [options]="optionsCleave">
                                                                    <div class="input-group-append">
                                                                        <button class="btn btn-outline-secondary"
                                                                            (click)="ngayHetHieuLucContract.toggle()"
                                                                            type="button"><em
                                                                                class="fas fa-calendar-alt"></em></button>
                                                                    </div>
                                                                </div>
                                                                <app-show-validate-errors [isSubmit]="isSubmitContract"
                                                                    [errorMessages]="validContractMsg.ngayHetHieuLuc"
                                                                    [control]="formContract.controls.ngayHetHieuLuc"
                                                                    [detail]="{ name: 'ngayHetHieuLuc' }">
                                                                </app-show-validate-errors>
                                                            </div>
                                                        </div>
                                                    </div>
        
                                                    <div class="row">
                                                        <div class="col-xl-6">
                                                            <div class="form-group">
                                                                <label>{{'ServiceContract_auto' | translate}} <span
                                                                        class="text-danger">*</span></label>
                                                                <select formControlName="tuGiaHan" class="form-control">
                                                                    <option value="true">{{'ServiceContract_yes' | translate}}</option>
                                                                    <option value="false">{{'ServiceContract_no' | translate}}</option>
                                                                </select>
                                                                <app-show-validate-errors [isSubmit]="isSubmitContract"
                                                                    [errorMessages]="validContractMsg.tuGiaHan"
                                                                    [control]="formContract.controls.tuGiaHan"
                                                                    [detail]="{ name: 'tuGiaHan' }">
                                                                </app-show-validate-errors>
                                                            </div>
                                                        </div>
        
                                                        <div class="col-xl-6">
                                                            <div class="form-group">
                                                                <label>{{'ServiceContract_nego' | translate}} <span class="text-danger">*</span></label>
                                                                <ng-select 
                                                                    [items]="dropListDiscuss"
                                                                    bindLabel="text"
                                                                    bindValue="id"  
                                                                    formControlName="discuss"
                                                                    placeholder="{{'ServiceContract_nego' | translate}}..."
                                                                    (change)="changeDiscussType($event)"
                                                                    [clearable]="false"
                                                                    [readonly]="disableDiscussStatus">
                                                                    <ng-template ng-label-tmp let-item="item">
                                                                        <span>{{ item.text | translate }}</span>
                                                                    </ng-template>
                                                                    <ng-template ng-option-tmp let-item="item">
                                                                        <span>{{ item.text | translate }}</span>
                                                                    </ng-template>
                                                                </ng-select>
                                                                <app-show-validate-errors [isSubmit]="isSubmitContract"
                                                                    [errorMessages]="validContractMsg.discuss"
                                                                    [control]="formContract.controls.discuss"
                                                                    [detail]="{ name: 'discuss' }">
                                                                </app-show-validate-errors>
                                                            </div>
                                                        </div>
                                                    </div>
        
                                                    <div class="row">
                                                        <div class="col-xl-6">
                                                            <div class="form-group">
                                                                <label>{{'ServiceContract_value' | translate}}</label>
                                                                <input id="contractValueInput" formControlName="giaTriHd"
                                                                    class="form-control" type="text"
                                                                    placeholder="{{'ServiceContract__ph_value' | translate}}" appInputMark
                                                                    [options]="optionsCleaveCost">
                                                                <app-show-validate-errors [isSubmit]="isSubmitContract"
                                                                    [errorMessages]="validContractMsg.giaTriHd"
                                                                    [control]="formContract.controls.giaTriHd"
                                                                    [detail]="{ name: 'giaTriHd' }"></app-show-validate-errors>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-6">
                                                            <div class="form-group">
                                                                <label>{{'ServiceContract_note' | translate}}: </label>
                                                                <input formControlName="ghiChu" class="form-control" type="text"
                                                                    placeholder="{{'ServiceContract_ph_note' | translate}}">
                                                                <app-show-validate-errors [isSubmit]="isSubmitContract"
                                                                    [errorMessages]="validContractMsg.ghiChu"
                                                                    [control]="formContract.controls.ghiChu"
                                                                    [detail]="{ name: 'ghiChu' }"></app-show-validate-errors>
                                                            </div>
                                                        </div>
                                                    </div>
        
                                                    <div class="d-flex" data-guide="contract-partner">
                                                        <label class="font-weight-bold flex-grow-1">{{'ServiceContract_parties' | translate}}</label>
                                                        <button class="btn btn-primary btn-icon ml-auto" (click)="addPartyJoin()">
                                                            <span class="material-icons">add</span>
                                                        </button>
                                                    </div>
                                                    <hr>
                                                    <div class="list-party">
                                                        <div *ngFor="let item of partiesFormArray.value" class="row">
                                                            <div class="col-md-6">
                                                                <label>{{'ServiceContract_parties2' | translate}}</label>
                                                                <p [class.text-over]="!item.tenToChuc && !item.tenDaiDien && item.email">{{ item.tenToChuc || item.tenDaiDien || item.email || item.sdt }}</p>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <label>{{'ServiceContract_customerType' | translate}}</label>
                                                                <p *ngIf="item.userType === userTypeConst.CONSUMER">{{'ServiceContract_consumer' | translate}}</p>
                                                                <p *ngIf="item.userType === userTypeConst.BUSINESS">{{'ServiceContract_business' | translate}}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                        
                                            <div class="col-12 tab-step fade show" [class.d-none]="currentStepCreate !== 1">
                                                <div class="d-flex align-items-center mt-2" style="min-height: 2.4rem">
                                                    <h5 class="font-weight-bold m-0">{{'ServiceContract_selectSignPosition' | translate}}</h5>
                                                </div>
                                                <div class="row my-2">
                                                    <div class="col-12">
                                                        <div class="d-flex align-items-center">
                                                            <label class="text-weigth-bold mr-4 mb-0">{{ 'FixPosition' | translate }}</label>
                                                            <div class="icheck-primary" style="width: fit-content;">
                                                                <input [(ngModel)]="fixedPosition" class="form-control" type="checkbox" id="id-fixPosition">
                                                                <label for="id-fixPosition">
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <label class="text-primary text-weigth-bold my-2">{{'ServiceContract_internalPosition' | translate}}</label>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div *ngFor="let item of ownerSignBoxs; let i = index" class="ec-block-status ec-block-sign-box border-secondary">
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <div class="ec-block-header">
                                                                        <div class="ec-block-status-icon">
                                                                            <span class="span-status bg-prỉmary"
                                                                            style="width: 24px; height: 24px; border-radius: 50%;">
                                                                                <span class="">{{ i + 1 }}</span>
                                                                            </span>
                                                                        </div>
                                                                        <div class="ec-block-status-content">
                                                                            <p><span class="font-weight-bold">{{ item.email || item.fullName || item.sdt }}</span></p>
                                                                            <span class="ec-part-name">{{'ServiceContract_assign' | translate : {number: item.signBoxes.length} }}</span> 
                                                                        </div>
                                                                        <div class="ec-block-action">
                                                                            <button *ngIf="item.signBoxes.length <= 0" class="btn btn-outline-primary" (click)="addTemplateSignBox(item)">{{ 'ServiceContract_addSignPosition' | translate}}</button>
                                                                            <button *ngIf="item.signBoxes.length > 0 && item.showSignBoxes == true" class="btn btn-outline-primary" (click)="hideTemplateSignBox(item)">{{ 'ServiceContract_hideSignPosition' | translate }}</button>
                                                                            <button *ngIf="item.signBoxes.length > 0 && item.showSignBoxes == false" class="btn btn-outline-primary" (click)="showTemplateSignBox(item)">{{ 'ServiceContract_viewSignPosition' | translate}}</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <div *ngIf="item.showSignBoxes" class="ec-block-body">
                                                                        <div *ngFor="let signBox of item.signBoxes; let i = index" class="ec-block-body-item">
                                                                            <div class="ec-block-status-content d-flex align-items-center">
                                                                                <span class="">{{'ServiceContract_signPosition' | translate}} {{ i + 1 }}</span> 
                                                                                <div class="d-flex align-items-center ml-auto">
                                                                                    <a class="text-decoration-underline text-primary cursor-pointer mr-2" (click)="viewPositionSignBox(signBox)">{{'ServiceContract_viewPosition' | translate}}</a>
                                                                                    <a class="text-decoration-underline text-primary cursor-pointer" (click)="item.removeSignBox(i)">{{'ServiceContract_remove' | translate}}</a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <div *ngIf="item.showSignBoxes" class="ec-block-footer">
                                                                        <a class="text-decoration-underline text-primary cursor-pointer" (click)="item.addSignBox()">+ {{'ServiceContract_addSignPosition' | translate}}</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <label class="text-primary text-weigth-bold my-2 mt-3">{{'ServiceContract_partyPosition' | translate}}</label>
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div *ngFor="let item of partnerSignBox; let i = index" class="ec-block-status ec-block-sign-box border-secondary">
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <div class="ec-block-header">
                                                                        <div class="ec-block-status-icon">
                                                                            <span class="span-status bg-prỉmary"
                                                                            style="width: 24px; height: 24px; border-radius: 50%;">
                                                                                <span class="">{{ i + 1 }}</span>
                                                                            </span>
                                                                        </div>
                                                                        <div class="ec-block-status-content">
                                                                            <p><span class="font-weight-bold">{{ item.email || item.sdt }}</span></p>
                                                                            <span class="ec-part-name">{{'ServiceContract_assign' | translate : {number: item.signBoxes.length} }}</span> 
                                                                        </div>
                                                                        <div class="ec-block-action">
                                                                            <button *ngIf="item.signBoxes.length <= 0" class="btn btn-outline-primary" (click)="addTemplateSignBox(item)">{{'ServiceContract_addSignPosition' | translate}}</button>
                                                                            <button *ngIf="item.signBoxes.length > 0 && item.showSignBoxes == true" class="btn btn-outline-primary" (click)="hideTemplateSignBox(item)">{{'ServiceContract_hideSignPosition' | translate}}</button>
                                                                            <button *ngIf="item.signBoxes.length > 0 && item.showSignBoxes == false" class="btn btn-outline-primary" (click)="showTemplateSignBox(item)">{{'ServiceContract_viewSignPosition' | translate}}</button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <div *ngIf="item.showSignBoxes" class="ec-block-body">
                                                                        <div *ngFor="let signBox of item.signBoxes; let i = index" class="ec-block-body-item">
                                                                            <div class="ec-block-status-content d-flex align-items-center">
                                                                                <span class="">{{'ServiceContract_signPosition' | translate}} {{ i + 1 }}</span> 
                                                                                <div class="d-flex align-items-center ml-auto">
                                                                                    <a class="text-decoration-underline text-primary cursor-pointer mr-2" (click)="viewPositionSignBox(signBox)">{{'ServiceContract_viewPosition' | translate}}</a>
                                                                                    <a class="text-decoration-underline text-primary cursor-pointer" (click)="item.removeSignBox(i)">{{'ServiceContract_remove' | translate}}</a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <div *ngIf="item.showSignBoxes" class="ec-block-footer">
                                                                        <a class="text-decoration-underline text-primary cursor-pointer" (click)="item.addSignBox()">+ {{'ServiceContract_addSignPosition' | translate}}</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<app-party-join *ngIf="showPartyJoin" [partyInfo]="partyInfo" [partiesJoin]="partiesJoin"
    [partiesFormArray]="partiesFormArray" (closed)="closedPartyJoinModal($event)" (changed)="changePartyJoin($event)">
</app-party-join>

<!-- <app-party-join-v2 *ngIf="showPartyJoin" [partyInfo]="partyInfo" [partiesJoin]="partiesJoin"
    [partiesFormArray]="partiesFormArray" (closed)="closedPartyJoinModal($event)" (changed)="changePartyJoin($event)">
</app-party-join-v2> -->

<app-fill-data-contract-template #contentFillData *ngIf="showFillData" 
    [currentStep]="1"
    [pdfFile]="templateFile" [idTemplate]="templatedSelected.id"
    [tableListNoFilled]="tableListNoFilled"
    [textBoxListNoFilled]="textBoxListNoFilled"
    [checkBoxesNoFilled]="checkBoxesNoFilled"
    [checkBoxListNoFilled]="checkBoxListNoFilled"
    [tableObjectListNoFilled]="tableObjectListNoFilled"
    [paramRenderByStep]="paramRenders"
    (onActionNextButton)="onActionNextButton($event)"
    (onActionBackButton)="onActionBackButton($event)">
</app-fill-data-contract-template>

<app-flow-settings
    *ngIf="showFlowSetting"
    [flowContract]="flowContractSelected"
    [id]="formContract.value.flowTemplateId"
    [internalEditable]="true"
    [partnerEditable]="true"
    [signFlowEditable]="true"
    (closed)="flowSettingsClosed($event)"
>
</app-flow-settings>
<app-guide-tour #hdsd></app-guide-tour>