import { Injectable } from '@angular/core';
import { ServicePath } from '../../common/constant-service-api';
import { API } from '../api';

const CATALOG_SERVICE = ServicePath.MANAGEMENT_SERVICE + "/service-informations";
const GET_ALL_ROLEID_BY_CATALOG = ServicePath.MANAGEMENT_SERVICE + "/service-informations/v1/list-role";
const GET_ALL_ROLEID_ENDUSER = ServicePath.MANAGEMENT_SERVICE + "/service-informations/v1/all-privileges";
const POST_CREATE_SERVICE_CATALOG = ServicePath.MANAGEMENT_SERVICE + "/service-informations/v1/create";
const GET_DETAIL_SERVICE_CATALOG = ServicePath.MANAGEMENT_SERVICE + "/service-informations/v1";
const UPDATE_DETAIL_SERVICE_CATALOG = ServicePath.MANAGEMENT_SERVICE + "/service-informations/v1";
const DELETE_DETAIL_SERVICE_CATALOG = ServicePath.MANAGEMENT_SERVICE + "/service-informations/v1";
const GET_LIST_ROLE_BY_CATALOG = ServicePath.MANAGEMENT_SERVICE + "/service-informations/v1/list-role";
const GET_DETAIL_PACKAGE = ServicePath.MANAGEMENT_SERVICE + "/package-information/v1";
const LIST_PACKAGE_URL = ServicePath.MANAGEMENT_SERVICE + "/package-information-service";
const LIST_PARTY_USED = ServicePath.MANAGEMENT_SERVICE + "/package-information/v1"
const LIST_PACKAGE_URL_V2 = ServicePath.MANAGEMENT_SERVICE + "/package-information/v1/list";
const DELETE_PACKAGE_URL_V2 = ServicePath.MANAGEMENT_SERVICE + "/package-information/v1/";
const EDIT_PACKAGE_SERVICE = ServicePath.MANAGEMENT_SERVICE + "/package-information/v1";

@Injectable({
  providedIn: 'root'
})
export class PackageServiceService {

  constructor(
    private api: API
  ) { }

  getListCatalogService(page, maxSize, propertiesSort, sort, status, serviceType, searchString) {
    const url = CATALOG_SERVICE + `/v1/list?page=${page}&maxSize=${maxSize}&propertiesSort=${propertiesSort}&sort=${sort}&status=${status}&searchString=${searchString}&serviceType=${serviceType}`
    return this.api.get(url);
  }

  getAllRoleIdByCatalog() {
    const url = GET_ALL_ROLEID_BY_CATALOG;
    return this.api.get(url);
  }

  getAllRole() {
    const url = GET_ALL_ROLEID_ENDUSER;
    return this.api.get(url);
  }

  postCreateServiceCatalog(param) {
    const url = POST_CREATE_SERVICE_CATALOG;
    return this.api.post(url, param);
  }

  //Lay thong tin danh muc
  getServiceCatalog(id) {
    const url = GET_DETAIL_SERVICE_CATALOG + `/${id}`;
    return this.api.get(url);
  }

  //Cap nhat danh muc
  putUpdateServiceCatalog(id, param) {
    const url = UPDATE_DETAIL_SERVICE_CATALOG + `/${id}`;
    return this.api.put(url, param);
  }

  //Xoa danh muc
  deleteServiceCalalog(id) {
    const url = DELETE_DETAIL_SERVICE_CATALOG + `/${id}`;
    return this.api.delete(url);
  }

  //Danh sach quyen theo danh muc
  getRolesInCatalogs() {
    const url = GET_LIST_ROLE_BY_CATALOG;
    return this.api.get(url);
  }

  //Thong tin chi tiet goi dich vu
  getDetailPackage(id) {
    const url = GET_DETAIL_PACKAGE + `/${id}`;
    return this.api.get(url);
  }

  //Lay danh sach goi dich vu
  getListPackage(
    page,
    maxSize,
    propertiesSort,
    sort,
    packageType = "",
    status = "",
    userType = "",
    keySearch = ""
  ) {
    const url =
    LIST_PACKAGE_URL_V2 +
      `?page=${page}&maxSize=${maxSize}&sort=${sort}&propertiesSort=${propertiesSort}&packageType=${packageType}&status=${status}&userType=${userType}&searchString=${keySearch}`;
    return this.api.get(url);
  }

  //Lay danh sach user dang su dung goi dich vu
  getListUsed(id, page, maxSize, propertiesSort, sort) {
    const url = LIST_PARTY_USED + `/${id}/list-party-used?page=${page}&maxSize=${maxSize}&propertiesSort=${propertiesSort}&sort=${sort}`;
    return this.api.get(url);
  }

  deletePackageV2(packInfoId) {
    const url = DELETE_PACKAGE_URL_V2 + `${packInfoId}`;
    return this.api.delete(url);
  }

  //Chinh sua goi dich vu v1
  postUpdatePackageService(id, param) {
    const url = EDIT_PACKAGE_SERVICE + `/${id}`;
    return this.api.put(url, param);
  }
}
