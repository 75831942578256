<div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-md-auto">
          <h1 class="m-0 text-dark"><label>Quản lý tài liệu</label></h1>
        </div>
        <!-- /.col -->
        <!-- <div class="col-md-auto ml-auto">
          <input hidden type="file" #uploader (click)="uploader.value = null" value="" (change)="uploadFile($event)" accept=".xlsx, .xls, .csv" />
          <button (click)="uploader.click()" class="btn btn-outline-primary btn-light d-inline-flex mr-4"><span
              class="material-icons mr-1">publish</span> Import Excel</button>
          <button (click)="downloadSample()" class="btn btn-outline-primary btn-light d-inline-flex mr-3"><span
              class="material-icons mr-1">get_app</span> Tải file mẫu</button>
        </div> -->
        <!-- <!-- /.col -->
      </div>
    </div>
  </div>
  <!-- Main content -->
  <section class="content dashboard">
    <div class="container-fluid">
      <div class="row">
        <div class="col-4">
          <div class="card border-0 shadow-none h-100">
            <div class="card-header border-0 py-3 pt-3">
              <div class="row py-1">
                <div class="col-md-auto align-self-end">
                  <label class="text-lg">Danh sách tài liệu</label>
                </div>
                <div class="col-md-auto ml-auto">
                  <button class="btn btn-primary d-inline-flex" (click)="form.reset(); form.enable()"><span
                      class="material-icons">add</span>
                    Thêm mới</button>
                </div>
              </div>
  
              <div class="input-group rounded border border-primary border-sm mt-3">
                <div class="input-group input-group-prepend">
                  <button class="btn btn-primary input-no-border" (click)="clickSearchBtn()">
                    <em class="fa fa-search nav-icon"></em>
                  </button>
                  <input type="text" class="form-control text-dark flex-grow-1 input-no-border input-no-border-focus"
                    placeholder="Nhập tên tài liệu..." value="{{ keySearch }}" (input)="inputDocumentKeySearch($event)"
                    (keyup.enter)="clickSearchBtn()" #searchField />
                </div>
              </div>
            </div>
            <!-- ============================================= -->
            <!-- ================================================ -->
            <div class="">
              <div *ngIf="!!documents && documents.length; else emptyBlock" class="content-list fade show">
                <div *ngFor="let item of documents; let i = index" (click)="editDocument(item)"
                  class="user-group-item content-list-item px-4 pt-2" [class.active]="item.id === form.value.id">
                  <div class="border-bottom d-flex">
                    <span class="material-icons icon-item pr-3">description</span>
                    <div class="flex-grow-1">
                      <label class="title-item">{{ item.documentName || 'NULL' }}</label>
                      <p class="text-secondary">Ngày chỉnh sửa:
                        {{ item.dateUpload | date: 'dd/MM/yyyy' }}</p>
                    </div>
                    <a *ngIf="!item.isUsed" class="text-decoration-underline text-primary pl-4"
                      (click)="deleteDocument($event, item)" style="cursor: pointer;">Xóa</a>
                  </div>
                </div>
              </div>
  
              <!-- <div *ngIf="!!documents && documents.length; else emptyBlock" class="table-responsive">
                <table class="table table-hover mb-0">
                  <tbody>
                    <tr *ngFor="let item of documents; let i = index">
                      <td class="py-0 px-2 handle" [class.active]="item.id === form.value.id"
                        (click)="editDocument(item)">
                        <p class="px-0 py-2 my-0 product-name nav-material">
                          <em class="material-icons nav-icon">description</em> {{ item.documentName || 'NULL' }}
                        </p>
                        <p class="p-0 m-0">
                          <small>Ngày chỉnh sửa: {{ item.dateUpload | date: 'dd/MM/yyyy' }}</small>
                        </p>
                      </td>
                      <td>
                        <button *ngIf="!item.isUsed" type="button" (click)="deleteDocument(item)"
                          class="btn btn-link float-right">
                          <small><u>Xoá</u></small>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div> -->
              <!-- ====================================
                chưa có thông tin tài liệu
                ==================================== -->
              <ng-template #emptyBlock>
                <div class="d-flex justify-content-center mt-5">
                  <svg width="80" height="47" viewBox="0 0 80 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M40.0007 45.668H73.334C76.2795 45.668 78.6673 43.2802 78.6673 40.3346V27.4973C78.6676 26.3115 78.2721 25.1595 77.5433 24.224L61.6007 3.72664C60.5906 2.42815 59.0378 1.66847 57.3927 1.66797H22.6087C20.9635 1.66847 19.4107 2.42815 18.4007 3.72664L2.45798 24.224C1.72923 25.1595 1.33365 26.3115 1.33398 27.4973V40.3346C1.33398 43.2802 3.7218 45.668 6.66732 45.668H40.0007Z"
                      fill="#E8EDFC" />
                    <path
                      d="M77.5433 24.224L61.6006 3.72664C60.5905 2.42815 59.0377 1.66847 57.3926 1.66797H53.3926C55.0377 1.66847 56.5905 2.42815 57.6006 3.72664L73.5433 24.224C74.2714 25.1598 74.6669 26.3116 74.6673 27.4973V40.3346C74.6673 43.2802 72.2794 45.668 69.3339 45.668H73.3339C76.2794 45.668 78.6673 43.2802 78.6673 40.3346V27.4973C78.6669 26.3116 78.2714 25.1598 77.5433 24.224Z"
                      fill="#CAD9FC" />
                    <path
                      d="M73.3327 28.3333L57.3327 7H22.666L6.66602 28.3333H23.6847C24.6949 28.3334 25.6184 28.9043 26.07 29.808L28.5954 34.8587C29.0469 35.7624 29.9704 36.3333 30.9807 36.3333H49.018C50.0283 36.3333 50.9518 35.7624 51.4034 34.8587L53.9287 29.808C54.3803 28.9043 55.3038 28.3334 56.314 28.3333H73.3327Z"
                      fill="#0062FF" fill-opacity="0.16" />
                    <path
                      d="M6.66732 45.668H10.6673C7.7218 45.668 5.33398 43.2802 5.33398 40.3346V27.4973C5.33437 26.3116 5.72987 25.1598 6.45798 24.224L22.4007 3.72664C23.4107 2.42815 24.9635 1.66847 26.6087 1.66797H22.6087C20.9635 1.66847 19.4107 2.42815 18.4007 3.72664L2.45798 24.224C1.72987 25.1598 1.33437 26.3116 1.33398 27.4973V40.3346C1.33398 43.2802 3.7218 45.668 6.66732 45.668Z"
                      fill="white" />
                    <path d="M10.7852 11.334L17.3345 2.91799L19.4385 4.55532L12.8892 12.9713L10.7852 11.334Z"
                      fill="white" />
                    <path
                      d="M16.1769 8.75598C16.5094 8.33063 16.5525 7.74679 16.2862 7.2772C16.0199 6.8076 15.4967 6.54494 14.961 6.6119C14.4253 6.67886 13.9829 7.06223 13.8403 7.58293C13.6978 8.10363 13.8833 8.65888 14.3102 8.98932C14.8906 9.43855 15.725 9.33426 16.1769 8.75598Z"
                      fill="#0062FF" fill-opacity="0.8" />
                    <path
                      d="M62.6533 2.90736C61.3945 1.27818 59.4495 0.327132 57.3907 0.334022H22.6093C20.5505 0.327132 18.6055 1.27818 17.3467 2.90736C17.0415 3.28219 16.9631 3.79281 17.1417 4.24193C17.3204 4.69105 17.7281 5.00832 18.2073 5.07116C18.6866 5.134 19.1623 4.93257 19.4507 4.54469C20.2117 3.57359 21.3756 3.00463 22.6093 3.00069H57.3907C58.6244 3.00463 59.7883 3.57359 60.5493 4.54469L76.4907 25.042C77.035 25.7447 77.3314 26.6078 77.3333 27.4967V40.334C77.3333 42.5432 75.5425 44.334 73.3333 44.334H6.66667C4.45753 44.334 2.66667 42.5432 2.66667 40.334V27.4967C2.66865 26.6078 2.96497 25.7447 3.50933 25.042L12.9027 12.966C13.3324 12.3845 13.2202 11.5664 12.6499 11.122C12.0796 10.6775 11.2589 10.7686 10.8 11.3274L1.404 23.4007C0.494928 24.5727 0.00105417 26.0135 0 27.4967V40.334C0.00440816 44.0141 2.9866 46.9963 6.66667 47.0007H73.3333C77.0134 46.9963 79.9956 44.0141 80 40.334V27.4967C79.9984 26.0136 79.5046 24.5729 78.596 23.4007L62.6533 2.90736Z"
                      fill="#0062FF" fill-opacity="0.8" />
                    <path
                      d="M23.4673 5.93464C23.1844 5.72247 22.8288 5.63136 22.4788 5.68137C22.1287 5.73138 21.8128 5.91841 21.6007 6.20131L5.60066 27.5346C5.29764 27.9387 5.2489 28.4792 5.47476 28.9309C5.70061 29.3826 6.1623 29.668 6.66733 29.668H23.686C24.1914 29.6664 24.6538 29.9524 24.878 30.4053L27.4047 35.4573C28.0784 36.8157 29.4657 37.673 30.982 37.668H49.0193C50.5356 37.673 51.9229 36.8157 52.5967 35.4573L55.1233 30.4053C55.3476 29.9524 55.8099 29.6664 56.3153 29.668H62.6673C63.4037 29.668 64.0007 29.071 64.0007 28.3346C64.0007 27.5983 63.4037 27.0013 62.6673 27.0013H56.3153C54.7991 26.9963 53.4118 27.8536 52.738 29.212L50.2113 34.264C49.9871 34.7169 49.5248 35.0029 49.0193 35.0013H30.982C30.4766 35.0029 30.0142 34.7169 29.79 34.264L27.2633 29.212C26.5896 27.8536 25.2023 26.9963 23.686 27.0013H9.33399L23.734 7.80131C23.9462 7.51841 24.0373 7.16281 23.9873 6.81275C23.9373 6.46268 23.7502 6.14682 23.4673 5.93464Z"
                      fill="#0062FF" fill-opacity="0.8" />
                    <path
                      d="M56.6673 8.33464L72.2673 29.1346C72.7091 29.7237 73.5449 29.8431 74.134 29.4013C74.7231 28.9595 74.8425 28.1237 74.4007 27.5346L58.4007 6.2013C58.1488 5.86556 57.7537 5.66797 57.334 5.66797H30.6673C29.9309 5.66797 29.334 6.26492 29.334 7.0013C29.334 7.73768 29.9309 8.33464 30.6673 8.33464H56.6673Z"
                      fill="#0062FF" fill-opacity="0.8" />
                  </svg>
                </div>
                <p class="text-center mt-2">Không có dữ liệu</p>
              </ng-template>
            </div>
          </div>
        </div>
        <div class="col-8">
          <div class="card border-0 shadow-none">
            <div class="card-header border-0 py-3 pt-3">
              <div class="row py-1">
                <div class="col-md-auto">
                  <label class="title">Thông tin tài liệu</label>
                </div>
                <div class="col-md-auto ml-auto">
                  <button class="btn btn-outline-primary d-inline-flex mr-4" (click)="cancelAction()">
                    <em class="material-icons mr-1">clear</em>
                    Hủy
                  </button>
                  <button class="btn btn-primary d-inline-flex" (click)="saveDocument()"
                    [disabled]="form.invalid || form.pending">
                    <em class="material-icons mr-1">save</em>
                    Lưu
                  </button>
                </div>
              </div>
              <hr class="mb-0 mt-3">
            </div>
  
            <div class="card-body pt-0">
              <form [formGroup]="form">
                <div class="row">
                  <div class="col-6">
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <label>Tên tài liệu <span class="text-danger">*</span>:</label>
                          <input appTrimValue [attr.disabled]="currentDocument.isUsed || null" type="text" formControlName="documentName" class="form-control"
                            placeholder="Nhập tên tài liệu"/>
                          <!-- <app-show-errors [control]="form.controls.documentName"></app-show-errors> -->
                          <app-show-validate-errors [useSubmit]="false" [isSubmit]="isSubmit" [errorMessages]="validateMsg.documentName"
                            [control]="form.controls.documentName" [detail]="{ name: 'documentName' }">
                          </app-show-validate-errors>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label>Mã tài liệu <span class="text-danger">*</span>:</label>
                          <input appTrimValue [attr.disabled]="currentDocument.isUsed || null" type="text" formControlName="documentId" class="form-control"
                            placeholder="Nhập mã tài liệu"/>
                          <!-- <app-show-errors [control]="form.controls.documentId"></app-show-errors> -->
                          <app-show-validate-errors [useSubmit]="false" [isSubmit]="isSubmit" [errorMessages]="validateMsg.documentId"
                            [control]="form.controls.documentId" [detail]="{ name: 'documentId' }">
                          </app-show-validate-errors>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label>Loại tài liệu<span class="text-danger"> *</span>:</label>
                          <ng-select2 [disabled]="currentDocument.isUsed" formControlName="documentType" [data]="dropListDocumentType" [options]="options"
                            [placeholder]="'Chọn loại tài liệu'"></ng-select2>
                          <!-- <app-show-errors [control]="form.controls.documentType"></app-show-errors> -->
                          <app-show-validate-errors [useSubmit]="false" [isSubmit]="isSubmit" [errorMessages]="validateMsg.documentType"
                            [control]="form.controls.documentType" [detail]="{ name: 'documentType' }">
                          </app-show-validate-errors>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <label>Ghi chú:</label>
                      <textarea [attr.disabled]="currentDocument.isUsed || null" formControlName="documentNote" class="form-control" style="height: 109px;"
                        placeholder="Nhập ghi chú"></textarea>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-4 d-flex justify-content-xl-end">
          <div *ngIf="totalElement > maxSize" class="pagination justify-content-end pages mt-3">
            <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="false"
              [pageSize]="maxSize" (pageChange)="getListDocuments($event)" [boundaryLinks]="true"
              aria-label="Default pagination">
              <ng-template ngbPaginationFirst>Đầu</ng-template>
              <ng-template ngbPaginationLast>Cuối</ng-template>
              <ng-template ngbPaginationPrevious>&laquo;</ng-template>
              <ng-template ngbPaginationNext>&raquo;</ng-template>
              <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
            </ngb-pagination>
          </div>
        </div>
      </div>
    </div>
  </section>
  