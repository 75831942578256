
export class QuantityInfoItem{
    allowedAccount: number;
    allowedData: number;
    allowedNumber: number;
    usedAccount: number;
    usedData: number;
    usedNumber : number;

    constructor(obj = null) {
        obj = obj || {};
        this.allowedAccount = Number(obj.allowedAccount) || 0;
        this.usedAccount = Number(obj.usedAccount) || 0;

        this.allowedData = Number(obj.allowedData) || 0;
        this.usedData = Number(obj.usedData) || 0;

        this.allowedNumber = Number(obj.allowedNumber) || 0;
        this.usedNumber = Number(obj.usedNumber) || 0;
    }

}