import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { delay, share, startWith } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {
  private visible$ = new BehaviorSubject<boolean>(false);
  private notiLoading: boolean = false;
  public disabled: boolean = false;

  public static instance: LoadingService;

  constructor(
  ) { 
    if (!LoadingService.instance) {
      LoadingService.instance = this;
    }
    return LoadingService.instance;
  }

  show() {
    this.visible$.next(true);
  }

  hide() {
    setTimeout(() => {
      this.visible$.next(false);
    });
  }

  showElement() {
    var myElement: any = document.querySelectorAll('.ect-loader');
    if (myElement) {
      for (let i = 0; i < myElement.length; i++) {
        myElement[i].style.display = "block";
      }
    }
  }

  hideElement() {
    var myElement: any = document.querySelectorAll('.ect-loader');
    if (myElement) {
      for (let i = 0; i < myElement.length; i++) {
        myElement[i].style.display = "none";
      }
    }
  }

  disabledLoader(value: boolean) {
    var myElement: any = document.querySelectorAll('.spinner-place');
    if (myElement) {
      for (let i = 0; i < myElement.length; i++) {
        myElement[i].style.display = value ? "none" : "block";
      }
    }
    setTimeout(() => {
      this.disabled = value;
    });
  }

  setVisible(value: boolean) {
    if (value && !this.disabled) this.showElement();
    else this.hideElement();

    setTimeout(() => {
      this.visible$.next(value);
    });
  }

  isVisible(): Observable<boolean> {
    return this.visible$.asObservable().pipe(share());
  }

}
