import { Component, OnInit,ViewChild,ElementRef,Directive } from '@angular/core';
import { Options } from 'select2';
import { Select2OptionData } from 'ng-select2';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { PermissionGroup } from 'src/app/utils/models/aas-models/business-package-manager/business-package-list/list-all-permission/permission-group'
import { ListAllPermisionReponse } from 'src/app/utils/models/aas-models/business-package-manager/business-package-list/list-all-permission/list-all-permission-response';
import { DualListItem } from 'src/app/utils/models/aas-models/business-package-manager/business-package-list/duaList-item.model'
import { StringUtils } from 'src/app/utils/common/StringUtils';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { PACKAGE_MANAGER } from 'src/app/utils/common/constant-vaildate-msg';
import { share } from 'rxjs/operators';
import { NgModalComponent } from 'src/app/layout/extensions/ng-modal/ng-modal.component';
import { LoadingService } from 'src/app/utils/services/loading.service';
import { BusinessPackageService } from 'src/app/utils/services/aas-network/business-package.service'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { CategoryPackageItem } from 'src/app/utils/models/aas-models/business-package-manager/business-package-list/category-package-manager/category-package-item.model'
import { ListCategoryPackageResponse } from 'src/app/utils/models/aas-models/business-package-manager/business-package-list/category-package-manager/list-category-package-response.model'
import { ListTemplateContractResponse } from 'src/app/utils/models/aas-models/business-package-manager/business-package-list/template-contract/list-template-contract-response.model'
import { TemplateContract } from 'src/app/utils/models/aas-models/business-package-manager/business-package-list/template-contract/template-contract-item.model';
import { BusinessPackageItem } from 'src/app/utils/models/aas-models/business-package-manager/business-package-list/business-package-item.model'
import { ListPackageOrderResponse } from 'src/app/utils/models/aas-models/business-package-manager/business-package-list/list-package-in-order-response.model'
import {  REGEXP_COST, REGEXP_NUMBER} from 'src/app/utils/common/regexp'
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { InfraConfig } from 'src/app/utils/models/aas-models/service-catalog/infra-config';
import { PackageServiceService } from 'src/app/utils/services/package-service/package-service.service';
import { ServiceCatalog } from 'src/app/utils/models/aas-models/service-catalog/service-catalog';
import { localize } from 'src/app/utils/localize/localize';
import { DualListComponent } from 'src/app/layout/extensions/dual-list/dual-list.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { PackageDetail } from 'src/app/utils/models/aas-models/package-manager/package-detail';
import { UtilsFunc } from 'src/app/utils/common/utils-func';

const DURATION_TYPE = {
  "NOLIMIT": "NOLIMIT",
  "MONTH": "MONTH"
};

const VALIDATE_MSG = {
  name: {
    required: 'Vui lòng nhập tên gói cước!',
  },
  price: {
    required: 'Vui lòng nhập giá tiền!',
  },
  status: {
    required: 'Vui lòng chọn trạng thái!',
  },
  description: {
    required: 'Vui lòng nhập mô tả!',
  },
  userType: {
    required: 'Vui lòng chọn loại khách hàng áp dụng!',
  },
  contractNumber:{
    required: 'Vui lòng chọn số lượng hợp đồng!',
    pattern: 'Sai định dạng số lượng hợp đồng'
  },
  duration:{
    required: 'Vui lòng chọn thời hạn hợp đồng!',
    pattern: 'Sai định dạng số thời hạn hợp đồng'
  },
  orderNumber:{
    required: 'Vui lòng nhập thứ tự hiển thị!',
    pattern: 'Sai định dạng số hiển thị'
  },
  packageType: {
      required: "Vui lòng chọn loại gói dịch vụ"
  },
  packageCode: {
    required: "Vui lòng nhập mã gói dịch vụ!"
  },
  dmHaTang: {
    required: "Vui lòng chọn danh mục hạ tầng!"
  }
};

@Component({
  selector: 'app-edit-package',
  templateUrl: './edit-package.component.html',
  styleUrls: ['./edit-package.component.scss']
})
export class EditPackageComponent implements OnInit {
  @ViewChild('searchNotAssigned') searchNotAssigned: ElementRef;
  @ViewChild('searchAssigned') searchAssigned: ElementRef;
  @ViewChild('showInfoPackageOrder') showPackageOrder: ElementRef;
  @ViewChild('duallist') duallist: DualListComponent;
  
  id = this.activeRoute.snapshot.paramMap.get('id');
  
  dropListStatus = [
    {
      value: 'ENABLED',
      label: 'Đang hoạt động'
    },
    {
      value: 'DISABLED',
      label: 'Ngưng hoạt động'
    }
  ];

  dropListsUserType = [
    {
      id: 'CONSUMER',
      text: 'Khách hàng cá nhân'
    },
    {
      id: 'BUSINESS',
      text: 'Khách hàng tổ chức'
    }
  ];
  
  validateMsg = VALIDATE_MSG;
  
  localize = localize;
  durationType = DURATION_TYPE;

  package: PackageDetail = null;
  
  danhMucHaTang = [];
  danhMucChucNang = [];
  dmHaTang = null;
  dmChucNang = null;

  dmHaTangDaChon: ServiceCatalog = null;
  infraConfigs: InfraConfig[] = [];
  totalPrice = "";

  durationTypeSelected = "";

  items_dualist = [];
  items_dualist_assigned = [];

  optionsCurrency = {
    numeral: true,
    numeralThousandsGroupStyle: 'thousand',
  }

  form: FormGroup;
  isSubmit: boolean = false;

  listBusinessPackageRes = new ListPackageOrderResponse()
  listBusinesPackage : BusinessPackageItem[] = []
  PackageItem : BusinessPackageItem;
  pageListIndex = 1;
  totalListIndex = 0;
  maxSizeListIndex = 10;  

  formInfra: FormGroup;
  listFieldInfra: CategoryPackageItem[] = [];
  validateMsgInfra: any = {};

  packageTypeData = [
    { value: "TRIAL_TYPE", label: "Gói dùng thử" },
    { value: "NORMAL_TYPE", label: "Gói chính thức" },
    { value: "EXTEND_TYPE", label: "Gói mở rộng" }
  ];

  sameInfra = true;

  constructor(
    private fb: FormBuilder,
    private loadingService: LoadingService,
    private modalService: NgbModal,
    private packageService: BusinessPackageService,
    private myAlert: AlertControl,
    private packageApi: PackageServiceService,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.makeForm()
    this.changedDurationType(this.durationType.NOLIMIT);
    this.getServicePackageCatalog("HATANG");
    this.getServicePackageCatalog("CHUCNANG");
    this.getRolesInCatalogs();
    this.getDetailPackage(this.id);
  }

  makeForm() {
    this.form = this.fb.group({
      status: ['ENABLED',Validators.required],
      name : ['',Validators.required],
      description: ['',Validators.required],
      packageType: [null, [Validators.required]],
      duration: ['',[Validators.required, Validators.pattern(REGEXP_COST)]],
      price: ['',Validators.required],
      userType: [null, Validators.required],
      packageCode: ["", [Validators.required]],
      orderNumber: ["", [Validators.required, Validators.pattern(REGEXP_NUMBER)]],
      dmHaTang: [null, [Validators.required]]
    })
  }

  //Gan du lieu cu vao form
  setDataPackage(packageDetail: PackageDetail) {
    this.form.patchValue({
      status: packageDetail.packageInfo.status || null,
      name : packageDetail.packageInfo.name || "",
      description: packageDetail.packageInfo.description || "",
      packageType: packageDetail.packageInfo.packageType || null,
      duration: packageDetail.packageInfo.duration,
      price: packageDetail.packageInfo.price || "",
      userType: packageDetail.packageInfo.userType || null,
      packageCode: packageDetail.packageInfo.packageCode || "",
      orderNumber: packageDetail.packageInfo.orderNumber,
      dmHaTang: packageDetail.listPackageInfrastructure.serviceInfoId || null
    });

    //gan lai du lieu dm ha tang da chon
    this.dmHaTangDaChon = new ServiceCatalog({
      name: packageDetail.listPackageInfrastructure.name, 
      serviceInfoId: packageDetail.listPackageInfrastructure.serviceInfoId
    });

    //Set trang thai cho truong thoi han su dung
    this.durationTypeSelected = packageDetail.packageInfo.capacity || "";
    this.changePackageType({ value: packageDetail.packageInfo.packageType });

    //Gan du lieu ha tang
    if (packageDetail.listPackageInfrastructure.infrastructures.length > 0) {
      this.infraConfigs = Array.from(packageDetail.listPackageInfrastructure.infrastructures);
      this.infraConfigs = this.infraConfigs.sort((a, b) => { return a.stt - b.stt; })
    }
    this.getTotalMoney();

    //Set data danh sach danh muc chuc nang da chon
    this.items_dualist_assigned = [];
    packageDetail.listPackageService.forEach((item) => {
      let param = {
        id: item.serviceInfoId || "",
        name: item.name || "",
        additions: {
          ...item
        },
        child: []
      };

      item.privileges.forEach((x, index) => {
        param.child.push({
          id: x.name || "",
          name: x.description || "",
        })
      });

      this.items_dualist_assigned.push(param);
    });
    this.duallist.setupDualList(this.items_dualist_assigned);
  }

  /**
   * Action lưu thông tin cập nhật
   */
  actSaveInfo() {
    this.isSubmit = true;
    if(this.form.valid) {
      this.myAlert.showAlert(localize.confirm_update_package_service, ConstantAlertType.BLANK, true, "", localize.btn_add, () => {
        this.savePackage();
      }, "", localize.btn_cancel);
    }
  }

  //Luu thong tin goi dich vu moi
  savePackage() {
    console.log(this.form.value);
    let data = this.form.value;
    let listPackageService = [];
    if (this.form.value.packageType != 'EXTEND_TYPE') {
      listPackageService = this.items_dualist_assigned.map((x) => {
        return {
          serviceInfoId: x.id,
          name: x.name
        }
      });
    }
    
    let listPackageInfrastructure = {
      serviceInfoId: this.dmHaTangDaChon.serviceInfoId,
      name: this.dmHaTangDaChon.name,
      typeService: "HATANG",
      infrastructures: this.infraConfigs
    };
    let param = {
      packageInfo : {
        name: data.name,
        description: data.description,
        price: data.price ? Number(UtilsFunc.convertCurency(data.price)) : 0,
        packageType: data.packageType,
        duration: data.duration ? Number(data.duration) : 0,
        contractNumber: 100,
        status: data.status,
        userType: data.userType,
        orderNumber: data.orderNumber ? Number(data.orderNumber) : 0,
        packageCode: data.packageCode,
        capacity: this.durationTypeSelected || ""
      },
      listPackageService : listPackageService,
      listPackageInfrastructure: listPackageInfrastructure
    };

    console.log(param, '========')
    this.updatePackage(this.id, param);
  }

  //Mo danh sap xep hien thi cac goi cuoc
  openModal(){
    this.openModalShowInfo(this.showPackageOrder)
  }

  //hien thi modal thu tu cac goi cuoc
  openModalShowInfo(content) {
    // if(this.form.value.orderNumber != "" ){
      this.loadListIndex(1);
      this.modalService.open(content, {
        size: 'large',
        backdrop: 'static',
        keyboard: false
      });
    // }
    
  }

  /**
   * Load du lieu danh sach sắp xếp các gói dịch vụ
   * @param page 
   */
  loadListIndex(page = 1) {
    this.pageListIndex = page;
    this.getListPackageInOrder(page);
  }

  /**
   * Goi api tải dữ liệu danh sách sắp xếp thứ tự hiển thị các gói dịch vụ
   * @param page 
   */
  getListPackageInOrder(page){
    this.packageService.getListPackageInOrder(this.form.value.userType || "", page).subscribe((res)=>{
      this.listBusinessPackageRes = new ListPackageOrderResponse(res['object']);
      this.listBusinesPackage = this.listBusinessPackageRes.data
      this.totalListIndex = this.listBusinessPackageRes.totalElement;
    })
  }

  // hủy dữ liệu vừa chọn
  clearData() {
    this.router.navigate([ConstantUrl.list_package]);
  }

  isSelectPackage = false;
  selectPackageType(value){
    this.isSelectPackage = !this.isSelectPackage;
  }

  //Cap nhat thong tin goi dich vu
  updatePackage(id, param) {
    this.isSubmit = true;
    this.packageApi.postUpdatePackageService(id, param).subscribe(res => {
      this.myAlert.showAlert(localize.success_update_package_service, ConstantAlertType.SUCCESS, false, '', localize.btn_close, () => {
        this.router.navigate([ConstantUrl.list_package]);
      })
    }, err => {
      this.myAlert.showErrorHandled(err);
    });
  }




  //MARK: UPDATE V2
  changePackageType(event) {
    if (event) {
      switch (event.value) {
        case "TRIAL_TYPE":
          this.changedDurationType(this.durationType.MONTH);
          return;
        case "NORMAL_TYPE":
          this.changedDurationType(this.durationType.NOLIMIT);
          return;
        case "EXTEND_TYPE":
          this.changedDurationType(this.durationType.NOLIMIT);
          return;
      }
    }
  }

  //Lay mo ta ve loai thoi han goi
  get durationTypeLabel() {
    switch (this.durationTypeSelected) {
      case this.durationType.NOLIMIT: 
        return "Không giới hạn";
      case this.durationType.MONTH:
        return "Tháng"
    }
  }

  //Chon loai thoi han goi
  changedDurationType(type) {
    this.durationTypeSelected = type;
    if (type == this.durationType.MONTH) {
      this.form.controls['duration'].setErrors(null);
      this.form.controls['duration'].clearValidators();
      this.form.controls['duration'].setValidators([Validators.required, Validators.pattern(REGEXP_COST)]);
      this.form.controls['duration'].updateValueAndValidity();
    } else {
      this.form.controls['duration'].setErrors(null);
      this.form.controls['duration'].clearValidators();
      this.form.controls['duration'].updateValueAndValidity();
    }
  }

  changeSameInfra() {
    this.sameInfra = !this.sameInfra;
  }

  eventChangeDmHaTang(e) {
    console.log(e);
    if (e) {
      this.getServiceCatalog(e.serviceInfoId, "HATANG");
    }
  }

  eventChangeDmChucNang(e) {
    console.log(e);
    if (e) {
      this.getServiceCatalog(e.serviceInfoId, "CHUCNANG");
    }
  }

  setData() {
    this.infraConfigs.push(new InfraConfig({
      "stt":1,
      "param":"contractNumber",
      "name": "Số lượng hợp đồng",
      "unit": "Hợp đồng",
      "unitContract": "",
      "unitNumber": "0",
      "unitPrice": "0",
      "price": "0"
    }));
    this.infraConfigs.push(new InfraConfig({
      "stt":2,
      "param":"SMS_OTP",
      "name": "Số lượt SMS OTP",
      "unit": "Lượt",
      "unitContract": "5",
      "unitNumber": "0",
      "unitPrice": "0",
      "price": "0"
    }));
    this.infraConfigs.push(new InfraConfig({
      "stt":3,
      "param":"EKYC",
      "name": "Số lượt eKYC",
      "unit": "Lượt",
      "unitContract": "3",
      "unitNumber": "0",
      "unitPrice": "0",
      "price": "0"
    }));
    this.infraConfigs.push(new InfraConfig({
      "stt":4,
      "param":"STORAGE_TIME",
      "name": "Thời gian lưu trữ",
      "unit": "Tháng",
      "unitContract": "5",
      "unitNumber": "0",
      "unitPrice": "0",
      "price": "0"
    }));
    this.infraConfigs.push(new InfraConfig({
      "stt":5,
      "param":"STORAGE_CAPACITY",
      "name": "Dung lượng lưu trữ",
      "unit": "GB",
      "unitContract": "1",
      "unitNumber": "0",
      "unitPrice": "0",
      "price": "0"
    }));
  }

  //Xu ly tinh tien
  progressPrice(item: InfraConfig) {
    console.log("progressPrice");

    if (item.param == 'STORAGE_CAPACITY' || item.param == 'STORAGE_TIME') {
      let storeItem: InfraConfig = this.infraConfigs.find((x) => { return x.param == 'STORAGE_CAPACITY'; });
      let timeItem: InfraConfig = this.infraConfigs.find((x) => { return x.param == 'STORAGE_TIME'; });
      let price = "0";
      if (!isNaN(parseInt(storeItem.unitNumber)) && !isNaN(parseInt(storeItem.unitPrice)) && !isNaN(parseInt(timeItem.unitNumber))) {
        price = `${Number(storeItem.unitNumber) * Number(storeItem.unitPrice) * Number(timeItem.unitNumber)}`;
      } else {
        price = "0";
      }

      storeItem.price = price;
    } else {
      item.getPrice();
    }
  }

  //Tinh tong tien
  getTotalMoney() {
    console.log("getTotalMoney");
    var sum = '0';
    this.infraConfigs.forEach((item) => {
      if (!isNaN(parseInt(item.price)) && !isNaN(parseInt(sum))) {
        sum = `${Number(sum) + Number(item.price)}`;
      }
    })

    this.totalPrice = sum;
    if (this.sameInfra == true) {
      this.form.controls.price.setValue(this.totalPrice);
    }
  }


  //Bat su kien duallist thay doi
  getListAssigned(event) {
    console.log(event);
    if (event && event instanceof Array) {
      this.items_dualist_assigned = [].concat(event);
    }
  }

  //Lay danh sach tat ca danh muc dich vu
  getServicePackageCatalog(packageType = "") {
    this.packageService.getListCategoryPackage(1, 1000000, "DESC",
      "modified", packageType, "ENABLED" , "").subscribe((res: any) => {
        if (packageType == "HATANG") this.danhMucHaTang = res?.object?.data || [];
        if (packageType == "CHUCNANG") this.danhMucChucNang = res?.object?.data || [];
      }, err => {
        this.myAlert.showErrorHandled(err);
      })
  }

  //Lay thong tin chi tiet danh muc dich vu
  getServiceCatalog(id, packageType) {
    this.packageApi.getServiceCatalog(id).subscribe((res: any) => {
      console.log(res);
      if (packageType == "HATANG") {
        this.dmHaTangDaChon = new ServiceCatalog(res.object || {});
        if (this.dmHaTangDaChon.infrastructures.length > 0) {
          this.infraConfigs = Array.from(this.dmHaTangDaChon.infrastructures);
          this.infraConfigs = this.infraConfigs.sort((a, b) => { return a.stt - b.stt; })
        }
  
        this.getTotalMoney();
      }
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }

  //Danh sach quyen theo danh muc
  getRolesInCatalogs() {
    this.packageApi.getRolesInCatalogs().subscribe((res: any) => {
      let arrCatalog: ServiceCatalog[] = [];
      if (res.object && res.object instanceof Array) {
        res.object.forEach((item: any) => {
          arrCatalog.push(new ServiceCatalog(item));
        })

        //chuan bi du lieu cho duallist
        arrCatalog.forEach((item, index) => {
          let param = {
            id: item.serviceInfoId || "",
            name: item.name || "",
            additions: {
              ...item
            },
            child: []
          };

          item.privileges.forEach((x, index) => {
            param.child.push({
              id: x.name || "",
              name: x.description || "",
            })
          });

          this.items_dualist.push(param);
        });

        this.duallist.setBaseList();
        this.duallist.setupDualList([]);
      }
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }

  //Lay thong tin chi tiet goi dich vu
  getDetailPackage(id) {
    this.packageApi.getDetailPackage(id).subscribe((res: any) => {
      console.log(res);
      this.package = new PackageDetail(res?.object);

      this.setDataPackage(this.package);

      console.log(this.package);
    }, err => {
      console.log(err);
    });
  }
}
