import { FileUploadObject } from './fileupload-response/fileupload-object.model';
export class FileItem {
    name: string;
    process: number;
    type: string;
    id: string;
    error: string;
    size: number;
    fileUpload: FileUploadObject;
    old: boolean = false;

    constructor(object = null){
        object = object || {};
        this.name = object.name || '';
        this.size = object.size || 0;
        this.process = object.process || 0;
        this.type = object.type || '';
        this.id = object.id || '';
        this.error = object.error || '';
        this.fileUpload = new FileUploadObject(object.fileUpload || {});
        if (object.old !== null && object.old !== undefined) 
            this.old = object.old;
        else
            this.old = false;
    }

    get noSuccess() {
        if (this.process < 100 || this.id === "" || this.error !== "") return true;
        return false;
    }
}