
  <app-spinner></app-spinner>
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0 text-dark">Danh sách tài khoản hóa đơn điện tử</h1>
        </div>
        <!-- /.col -->
        <div class="col-sm-6" >
            <button class="btn btn-primary float-sm-right d-inline-flex" routerLinkActive="router-link-active"
             [routerLink]="['/app/console/einvoices/invoicesCustomer/create']"
              ><em class="material-icons">add</em>
              <!-- [routerLink]="['/portal/customers/create']" -->
              Thêm mới</button>
          </div>
        <!-- /.col -->
      </div>
      <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
  </div>
  <section class="content">
    <div class="container-fluid">
      <div class="bg-white pb-3">
          <section class="p-3">
              <div class="row">
                    <div class="col-lg-3 col-md-5">
                        <form   class="input-group border rounded mb-3">
                        <div class="input-group-prepend border-0">
                            <button class="btn d-inline-flex pr-2 pl-2" type="submit"><span
                                    class="material-icons">search</span></button>
                        </div>
                        <input type="text" class="form-control border-0"
                        placeholder="Nhập hóa đơn, tên khách hàng, MST..."   [formControl]="searchFields"
                            aria-describedby="basic-addon1" #searchFieldLicense>
                        </form>
                    </div>
                  <!-- </div> -->
                  <div class="col-md-auto ml-auto">
                      <!-- <div  class="btn-group">
                        <button class="btn btn-outline-primary dropdown-toggle d-inline-flex align-items-center mr-3"
                              data-toggle="dropdown" id="configColTable">
                              <span class="material-icons mr-1">visibility</span>
                              Hiển thị
                        </button>
                          <div class="dropdown-menu border-0" aria-labelledby="configColTable" style="width: 190px;">
                              <div *ngFor="let item of listConfigColTable" class="tcheck-primary d-inline-block px-3">
                                  <input type="checkbox" id="{{ item.name }}" [checked]="configColTable[item.name]">
                                  <label for="{{ item.name }}" class="w-100" [class.checked]="configColTable[item.name]"
                                      (click)="actConfigColTableView($event, item.name)">
                                      {{ item.label }}
                                  </label>
                              </div>
                          </div>
                      </div> -->
                      <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="cleanFilter()"> Đặt lại</button>
                      <button class="btn btn-primary d-inline-flex mr-3" (click)="searchWithFilter()"> Áp dụng</button>
                  </div>
              </div>
              <!-- <hr class="my-2"> -->
            </section>
          <section class="px-3 my-2 ">
            <form [formGroup] = 'formFilter'>
                <div class="row">
                    <div class="col-md-2">
                        <div class="form-group">
                            <label>Mẫu hóa đơn</label>
                            <ng-select2 [data]="templateData | async" formControlName ="pattern" 
                            [options]="options" [placeholder]="'Nhập mẫu hóa đơn'">
                            </ng-select2>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <label>Series hóa đơn</label>
                            <ng-select2 [data]="seriesData | async" formControlName ="serise" 
                                [options]="options" [placeholder]="'Nhập series hóa đơn'">
                            </ng-select2>
                        </div>
                    </div>
                </div>
            </form>
          </section>
          <section>
              <div id="table-scroll" class="table table-scroll table-responsive">
                  <table id="main-table" class="table main-table border">
                      <thead>
                          <tr >
                              <th >STT</th>
                              <th >Tên công ty</th>
                              <th >Mã số thuế</th>
                              <th >Url service</th>
                              <th >Mẫu hóa đơn</th>
                              <th >Series hóa đơn</th>
                              <th >Thao tác</th>
                          </tr>
                      </thead>
                      <tbody>
                        <tr  *ngFor="let item of listEinvoicesAccount, let i = index">
                              <td >{{ (page - 1)*10 + i + 1 }}</td>
                              <td >
                                  <a routerLinkActive="router-link-active" [routerLink]="[constantUrl.EINVOICE_CUSTOMER_DETAIL, item.uuid]">
                                  {{item.companyName}}
                                </a>
                                </td>
                              <td >{{item.taxCode}}</td>
                              <td >{{item.url}}</td>
                              <td >{{item.pattern}}</td>
                              <td >{{ item.serial}}</td>
                              <td >
                                <div class="dropdown">
                                    <a class="btn btn-dropdown dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                                      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                      <em class="material-icons">more_vert</em>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right " aria-labelledby="dropdownMenuLink" >
                                      <a class="dropdown-item nav-material" style="cursor: pointer;" 
                                      routerLinkActive="router-link-active" [routerLink]="[constantUrl.EINVOICE_CUSTOMER_DETAIL,item.uuid]" >
                                        <em class="material-icons">info_outline</em>
                                        Thông tin chi tiết</a>
                                      <a  class="dropdown-item nav-material" style="cursor: pointer;" 
                                        [routerLink]="['/app/console/einvoices/invoicesCustomer/update',item.uuid]" routerLinkActive="router-link-active">
                                        <em class="material-icons-outlined">autorenew</em>
                                        Cập nhật</a>
                                        <a  class="dropdown-item nav-material" style="cursor: pointer;" (click)="deleteAcc(item.uuid)"
                                        [routerLink]="" routerLinkActive="router-link-active">
                                        <em class="material-icons-outlined">delete_outline</em>
                                       Xóa tài khoản</a>
                                    </div>
                                  </div>
                              </td>
                          </tr>
                      </tbody>
                  </table>
              </div>
          </section>
      </div>
      <div class="d-flex justify-content-end mt-3">
          <span *ngIf="totalElement == 0" class="flex-grow-1 ml-1">Không tìm thấy kết quả</span>
          <!-- <span *ngIf="totalElement > 0" class="flex-grow-1 ml-1">Hiển thị 1 đến {{listContrat.length}} trong tổng số
              {{totalElement}} kết quả</span> -->
          <div *ngIf="totalElement > maxSize" class="pagination pages aas-pagination">
              <ngb-pagination [collectionSize]="totalElement" [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="false"
                  [pageSize]="maxSize" (pageChange)="loadPage($event)" [boundaryLinks]="true" aria-label="Default pagination">
                  <ng-template ngbPaginationFirst>Đầu</ng-template>
                  <ng-template ngbPaginationLast>Cuối</ng-template>
                  <ng-template ngbPaginationPrevious>&laquo;</ng-template>
                  <ng-template ngbPaginationNext>&raquo;</ng-template>
                  <ng-template ngbPaginationNumber let-page>{{ page }}</ng-template>
              </ngb-pagination>
          </div>
      </div>
    </div>
  </section>

  <ng-template #sendEmail let-c="close" let-d="dismiss">
    <div class="modal-header border-0 pb-0 mb-0">
        <button type="button" class="close" aria-label="Close" (click)="d('Cross click')">
            <span class="material-icons">
                cancel
            </span>
        </button>
    </div>
    <div class="modal-body py-0">
        <p class="modal-title w-100 text-center mb-3" id="modal-basic-title">Một thông báo thông tin hóa đơn sẽ trả về email của khách hàng. Vui lòng kiểm tra lại thông tin. </p>
        <form [formGroup]="formEmail">
            <div class="form-group">
                <label>Email khách hàng: </label>
                <input formControlName="email" class="form-control" type="text" placeholder="Nhập email khách hàng">
                <!-- <app-show-validate-errors [isSubmit]="isOTPSubmit" [errorMessages]="validMsgOTP.otp"
                    [control]="formOTP.controls.otp" [detail]="{ name: 'isOTPSubmit' }"></app-show-validate-errors> -->
            </div>
        </form>
    </div>
    <div class="modal-footer border-0 d-flex justify-content-center">
      <button type="button" class="btn btn-outline-primary mr-3" (click)="d('Cross click')">Hủy</button>
        <button type="button" class="btn btn-primary" >Xác nhận</button>
    </div>
</ng-template>