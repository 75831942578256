import {ReportContractItem} from './report-contract-item.model';

export class ListContractResponse {
    page: number;
    maxSize: number;
    sort: string;
    propertiesSort: string;
    totalElement: number;
    data: ReportContractItem[] = [];

    constructor(obj = null) {
        obj = obj || {};
        this.page = obj.page || 1;
        this.maxSize = obj.maxSize || 10;
        this.sort = obj.sort || '';
        this.propertiesSort = obj.propertiesSort || '';
        this.totalElement = obj.totalElement || 0;

        this.data = [];
        if (obj.object && obj.object instanceof Array) {
            obj.object.forEach((item) => {
                this.data.push(new ReportContractItem(item));
            })
        }
    }
}
