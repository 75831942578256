import {CustomerInfo} from "./customer-item.model"
import { MainResponse } from '../../../../main-response.model';

export class CustomerInfoResponse  extends MainResponse {
    
    constructor(obj = null) {
        super();
        obj = obj || {};
        this.init(obj);
        this.object = new CustomerInfo(obj.object || {});
    }
       
}