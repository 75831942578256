import { Component, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/utils/services/auth.service';
import { AppService } from 'src/app/utils/services/app.service';
import * as AdminLte from 'admin-lte';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { UserInformationsItem } from 'src/app/utils/models/profile/user-info-item.model';
import { InfoPartyObject } from 'src/app/utils/models/aas-models/info-party/info-party-object';
import { VerifyStatusConst } from 'src/app/utils/common/constant';
import { ControlAccount } from 'src/app/utils/common/control-account';
import { RoleCode } from 'src/app/utils/common/constants/roles-code';
import { localize } from 'src/app/utils/localize/localize';

declare var $: any;
@Component({
  selector: 'app-aas-menu-sidebar',
  templateUrl: './aas-menu-sidebar.component.html',
  styleUrls: ['./aas-menu-sidebar.component.scss']
})
export class AasMenuSidebarComponent implements OnInit {
  @ViewChild('mainSidebar', { static: false }) mainSidebar;
  @Output() mainSidebarHeight: EventEmitter<any> = new EventEmitter<any>();

  username = '';
  path = '';

  constantUrl = ConstantUrl;

  rolesQuanlyBoPhan = ['ESO4002', 'ESO4003'];
  rolesQuanLyNhanSu = ['ESO4004', 'ESO4004'];
  rolesQuanLyNhomNguoidung = ['ESO4001'];
  rolesQuanLyToChuc = this.rolesQuanlyBoPhan.concat(this.rolesQuanLyNhanSu).concat(this.rolesQuanLyNhomNguoidung);

  rolesQuanlyLuong = ['ESO2005']
  rolesContractNumberConfig = ['ESO2010'];
  rolesQuanlyHopDong = ['CTLIST', 'CTLSO', 'CTLSA', 'CTVDE', 'CTDOW', 'CTSIGN', 'ESO2001', 'ESO2002', 'CMT0001', 'ESO2003', 'ESO2004'];
  rolesDanhSachTaiXuong = ['ESO2011'];
  rolesQuanlyChungHopDong = this.rolesQuanlyHopDong.concat(this.rolesQuanlyLuong).concat(this.rolesContractNumberConfig).concat(this.rolesDanhSachTaiXuong);
  rolesQuanLyChuKy = ['ECT1049', 'ECT1050', 'ECT1053', 'DSIGN', 'ESIGN'];

  rolesQuanLyDoiTac = ['CMS2001', 'ESO3002', 'ESO3003', 'ESO3004', 'ESO3005'];
  rolesTrustPartner = ['CMS2002', 'ESO3001'];

  rolesDichVuEnduser = ['ESO1002'];

  rolesContractTemplate = ['ECT2300', 'ECT2301'];

  rolesELYCConfig = RoleCode.rolesELYCConfig;
  
  localize = localize;

  constructor(
    public appService: AppService,
    private authService: AuthService,
    private router : Router,
    private activatedRoute: ActivatedRoute,
    public controlAccount: ControlAccount
  ) {
    this.controlAccount.getAccountInfo();
  }

  ngOnInit() {
    this.path = this.activatedRoute.snapshot.url[0].path;
    this.username = this.getUsername;
  }

  ngAfterViewInit() {
    this.mainSidebarHeight.emit(this.mainSidebar.nativeElement.offsetHeight);
    $('[data-widget="treeview"]').each(function () {
      AdminLte.Treeview._jQueryInterface.call($(this), 'init');
    });
  }

  get isAccLevel2() {
    return this.controlAccount.isAccLevel2;
  }

  get getUsername() {
    return this.authService.jwtDecode(this.authService.getToken()).user_name;
  }

  logout() {
    this.authService.logout();
  }
}
