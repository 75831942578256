export class EnterpriseVipItem {
    contractVipId: string = '';
    created: string = '';
    customerId: string = '';
    domain: string = '';
    email: string = '';
    identityLevel: number;
    maSoThue: string = '';
    packageId: string = '';
    partyId: string = '';
    sdt: string = '';
    ten: string = '';
    tenToChuc: string = '';
    verificationStatus: string = '';

    constructor(data = null) {
        data = data || {};
        this.contractVipId = data.contractVipId || '';
        this.created = data.created || '';
        this.customerId = data.customerId || '';
        this.domain = data.domain || '';
        this.email = data.email || '';
        this.identityLevel = data.identityLevel;
        this.maSoThue = data.maSoThue || '';
        this.packageId = data.packageId || '';
        this.partyId = data.partyId || '';
        this.sdt = data.sdt || '';
        this.ten = data.ten || '';
        this.tenToChuc = data.tenToChuc || '';
        this.verificationStatus = data.verificationStatus || '';
    }
}