import { ListSignatureResponse } from './../../../../../utils/models/aas-models/contract/list-signature/list-signature-response.model';
import { ListSignatureObject } from './../../../../../utils/models/aas-models/contract/list-signature/list-signature-object.model';
import { SignatureService } from 'src/app/utils/services/aas-network/signature.service';
import { HttpResponse } from '@angular/common/http';
import { Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef, Renderer2 } from '@angular/core';
import { Options, Select2AjaxOptions } from 'select2';
import { Select2OptionData } from 'ng-select2';
import { FormGroup, FormBuilder, FormControl, Validators, Form, NgModel } from '@angular/forms';
import { ValidateMessage, AasValidateMessage } from 'src/app/utils/common/validate-msg-aas';
import { AasContractService } from 'src/app/utils/services/aas-network/aas-contract.service';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { NgbModal, NgbModalConfig, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { DateUtils } from 'src/app/utils/common/DateUtils';
import { LoadingService } from 'src/app/utils/services/loading.service';
import { Config } from 'src/app/config';
import { AuthService } from 'src/app/utils/services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Location, DatePipe } from '@angular/common';
import { ContractObject } from 'src/app/utils/models/aas-models/contract/contract/contract-object.model';
import { ContractResponse } from 'src/app/utils/models/aas-models/contract/contract/contract-response.model';
import { debug, timeStamp } from 'console';
import { InfoSignatureConfigResponse } from 'src/app/utils/models/aas-models/signature/signature-config/info-signature-config-response';
import { InfoSignatureConfigObject } from 'src/app/utils/models/aas-models/signature/signature-config/info-signature-config-object';
import { InfoCASignServerResponse } from 'src/app/utils/models/aas-models/signature/info-ca-signserver/info-ca-signserver-response';
import { ConstLocalStorage, ContractStatus, UserTypeCompany, UserTypeConstant, VerificationType } from 'src/app/utils/common/constant';
import { DigitalSignatureResponse } from 'src/app/utils/models/aas-models/signature/digital-signature/digital-signature-response';
import { DigitalSignatureItem } from 'src/app/utils/models/aas-models/signature/digital-signature/digital-signature-item';
import { UserInformationsResponse } from 'src/app/utils/models/profile/user-info-response.model';
import { UserInformationsItem } from 'src/app/utils/models/profile/user-info-item.model';
import { ContractFileInfo } from 'src/app/utils/models/aas-models/contract/contract-file/contract-file.model';
import { Contract } from 'src/app/utils/models/contract/contract-detail-update/contract-detail-update-object.model';
import { EncodeService } from 'src/app/utils/common/encode-service';
import { Base64 } from 'src/app/utils/common/base64';
import * as crypto from "crypto-browserify";
import { PartyJoinContract } from 'src/app/utils/models/aas-models/contract/party-join-contract/party-join-contract';
import { type } from 'os';
import { EnterpriseVipService } from 'src/app/utils/services/aas-network/enterprise-vip.service';
import { ConstantAlertBtn, ConstantAlertMsg } from 'src/app/utils/common/constant-alert-msg';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ContractParty } from 'src/app/utils/models/aas-models/contract/contract/contract-party.model';
import { OTP_CONFIRM_SIGN } from 'src/app/utils/common/constant-vaildate-msg';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ControlAccount } from 'src/app/utils/common/control-account';
import { Item } from 'src/app/utils/models/example/example-item.model';
import { localize } from 'src/app/utils/localize/localize';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { FlowContractService } from 'src/app/utils/services/aas-network/flow-contract/flow-contract.service';
import { FlowContract } from 'src/app/utils/models/aas-models/flow-contract/flow-contract.model';
import { UtilsFunc } from 'src/app/utils/common/utils-func';
import { USER_PERMISSION } from 'src/app/utils/common/constant-contract';
import { async } from '@angular/core/testing';
import { InternalDiscuss } from 'src/app/utils/models/aas-models/flow-contract/internal-discuss.model';
import { PartnerDiscuss } from 'src/app/utils/models/aas-models/flow-contract/partner-discuss.model';
import { ContractEncode } from 'src/app/utils/common/contract-encode';
import { SmartCAAuth } from 'src/app/utils/models/aas-models/signature/smart-ca/smart-ca-auth';
import { SmartCACert } from 'src/app/utils/models/aas-models/signature/smart-ca/smart-ca-cert';
import { map, take } from 'rxjs/operators';
import { InternalDiscussTypes, SignFlowTypes } from 'src/app/utils/common/constants/sign-form';
import { ListUsbTokenResponse } from 'src/app/utils/models/aas-models/signature/list-usb-token/list-usb-token-response';
import { UsbTokenItem } from 'src/app/utils/models/aas-models/signature/list-usb-token/usb-token-item';
import { SignBox } from 'src/app/utils/models/aas-models/signbox/sign-box';
import { DocumentInContractService } from 'src/app/utils/services/aas-network/document-in-contract/document-in-contract.service';
import { WebviewURL } from '../../../wv-ultils/wv-const-url';
import { ContractCensorshipComponent } from 'src/app/service-pages/service-console/service-enduser-console/contract-manager/layout/contract-censorship/contract-censorship.component';
import { ESignOtpComponent } from 'src/app/service-pages/service-console/service-enduser-console/contract-manager/service-contract-detail/layout/e-sign-otp/e-sign-otp.component';
import { SmartCaComponent } from 'src/app/service-pages/service-console/service-enduser-console/contract-manager/layout/smart-ca/smart-ca.component';
import { DocumentInContractComponent } from 'src/app/service-pages/service-console/service-enduser-console/contract-manager/layout/document-in-contract/document-in-contract.component';
import { TranslateService } from '@ngx-translate/core';
import { VersionInContractComponent } from 'src/app/service-pages/service-console/service-enduser-console/contract-manager/layout/version-in-contract/version-in-contract.component';
import { CommentPopupComponent } from 'src/app/service-pages/service-console/service-enduser-console/contract-manager/service-contract-detail/layout/comment-popup/comment-popup.component';
import { CommentPdf } from 'src/app/shared/plugins/comment-pdf';
import { NotePoint } from 'src/app/shared/plugins/note-point.model';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { ContractNumberBox } from 'src/app/utils/models/aas-models/signbox/contract-number-box';
import { VersionContract } from 'src/app/utils/models/aas-models/track-change/version-contract.model';
import { CommentService } from 'src/app/utils/services/aas-network/comment/comment.service';
import { ContractNumberConfigService } from 'src/app/utils/services/aas-network/contract-number-config/contract-number-config.service';

declare var PDFLoader: any;
declare var PDFSign: any;
declare var $: any;
declare var Cleave: any;
declare var vnpt_plugin: any;
declare var PdfSigner: any;
declare var default_img: any;
declare var listPage: any;
@Component({
  selector: 'app-wv-contract-detail',
  templateUrl: './wv-contract-detail.component.html',
  styleUrls: ['./wv-contract-detail.component.scss'],
  providers: [DatePipe]
})
export class WvContractDetailComponent implements OnInit {
  @ViewChild('output') output: ElementRef;
  @ViewChild('filename') filename: ElementRef;
  @ViewChild('filebase') filebase: ElementRef;
  @ViewChild('contentConfirmOTP') contentConfirmOTP: ElementRef;
  @ViewChild('otpConfirmSign') otpConfirmSign: ElementRef;
  @ViewChild('updateContractFile') updateContractFile: ElementRef;
  @ViewChild('contractCensorship') set content(content: ContractCensorshipComponent) {
    if (content) { // initially setter gets called with undefined
      this.contractCensorshipComponent = content;
    }
  }
  private contractCensorshipComponent: ContractCensorshipComponent;
  @ViewChild('eSignOTP') set eSignOTP(content: ESignOtpComponent) {
    if (content) {
      this._eSignOTP = content;
    }
  }
  private _eSignOTP: ESignOtpComponent = null;
  
  public smartCASign!: SmartCaComponent;
  @ViewChild('smartCASign', { static: false }) set AppSmartCASign(content: SmartCaComponent) {
    if (content) {
      this.smartCASign = content;
    }
  }

  @ViewChild('document', {static:false}) document: DocumentInContractComponent;
  @ViewChild('versionInContract', {static: false}) versionInContract: VersionInContractComponent;

  commentPdf: CommentPdf = new CommentPdf();

  public exampleData: Array<Select2OptionData> = [];
  public nguoiNhanData: Array<Select2OptionData> = [];
  public hinhThucKyData: Array<Select2OptionData> = [];
  public options: Options;
  public optionsTemplate: Options;
  public optionsHinhThucKy: Options;
  public ajaxOptions: Select2AjaxOptions;

  action: string = this.activeRoute.snapshot.queryParamMap.get('action') || '';
  contractId: string = this.activeRoute.snapshot.paramMap.get('id') || '';
  isContractVip: string = this.activeRoute.snapshot.queryParamMap.get('isContractVip') || '';
  contract: ContractObject = new ContractObject();
  signatureParties: Array<any> = [];

  //mark: user
  userInfo: UserInformationsItem = new UserInformationsItem();
  userType: string = '';
  hinhThucKy_consumer = [
    { id: 'NO_AUTHEN', text: 'Cấp 1: Ký ảnh' },
    { id: 'OTP', text: 'Cấp 2: SMS OTP' },
    { id: 'OTP_EMAIL', text: "Cấp 2: Email OTP"},
    // { id: 'EKYC', text: 'Cấp 3: SMS OTP và eKYC' },
    // { id: 'EKYC_EMAIL', text: "Cấp 3: Email OTP và eKYC"},
    { id: 'USB_TOKEN', text: 'Cấp 4: Usb-Token' },
    { id: 'SIGN_SERVER', text: 'Cấp 4: VNPT ký số' },
    { id: 'SMART_CA', text: 'VNPT SmartCA' }
  ];

  hinhThucKy_business = [
    { id: 'NO_AUTHEN', text: 'Cấp 1: Ký ảnh' },
    { id: 'OTP', text: 'Cấp 2: SMS OTP' },
    { id: 'OTP_EMAIL', text: "Cấp 2: Email OTP"},
    // { id: 'EKYC', text: 'Cấp 3: SMS OTP và eKYC' },
    // { id: 'EKYC_EMAIL', text: "Cấp 3: Email OTP và eKYC"},
    { id: 'USB_TOKEN', text: 'Cấp 4: Usb-Token' },
    { id: 'SIGN_SERVER', text: 'Cấp 4: VNPT ký số' },
    { id: 'SMART_CA', text: 'VNPT SmartCA' }
  ]

  //mark: nhóm chức năng khởi tạo thông tin hợp đồng
  filePDF: File = null;
  inputFileLabel: string = 'VerifySignature_chooseFile';
  fileType: Array<string> = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
  selectedFile: boolean = false;
  fileName: string = '';
  typeSelected: number = 1;

  formContract: FormGroup;
  validContractMsg: any = AasValidateMessage.FORM_CREATE_CONTRACT;
  isSubmitContract: boolean = false;

  currentStage = ContractStatus;

  //mark: nhóm chức năng ký usb-token
  timeCheck: any;

  //mark: nhóm chức năng ký hợp đồng bằng sign server
  formSign: FormGroup;
  isSubmit: boolean = false;
  validMsg: any = AasValidateMessage.SIGNATURE_CONFIG;
  inputFileImageLabel: string = 'Chọn file';
  img_prefix = "data:image/jpg;base64,";
  imgContent: string = '';
  imgSignServer: File = null;
  imgType = ['image/jpeg', 'image/png', 'image/jpg'];
  pdfSigned: Blob = null;
  keyTest: string = Config.KEY_PLUGIN_CA;
  //mark: phương thức ký
  selectedWayOfSign: string = '';
  //Config CA
  currentSignBoxStyle: number = 5;
  configCA: InfoSignatureConfigObject = new InfoSignatureConfigObject();


  //mark: nhóm chức năng ký điện tử
  formDSign: FormGroup;
  isESignSubmit: boolean = false;
  validMsgESign = AasValidateMessage.E_SIGNATURE_CONFIG;
  imgESignContent: string = '';
  imgEsignConfig: string = this.img_prefix + default_img;
  inputImgESignLabel: string = 'Chọn file';
  imgESign: any = null;
  //cert ký điện tử đã chọn
  eCertSelected: DigitalSignatureItem = new DigitalSignatureItem();
  //form confirm top e-sign
  formOTP: FormGroup;
  isOTPSubmit: Boolean = false;
  // validMsgOTP = AasValidateMessage.CONFIRM_OTP_E_SIGN;
  validMsgOTP = {
    otp: {
      required: 'ESignOtp_otp_required',
      notTrue: 'ESignOtp_confirm_otp_failed_no_limit'
    }
  }
  idSign: string = '';
  public haveSessionEsign = false;

  //Chi tiet chu ky
  listSignature: ListSignatureObject = new ListSignatureObject();

  //list party data
  ListContractPartysData: ContractParty[] = []

  hinhThucHienThi = [
    { value: 0, name: this.translate.instant("ContractCensorship_ts_10") },
    { value: 1, name: this.translate.instant("ContractCensorship_ts_11") },
    { value: 2, name: this.translate.instant("ContractCensorship_ts_12") },
    { value: 3, name: this.translate.instant("ContractCensorship_ts_13") },
    { value: 4, name: this.translate.instant("ContractCensorship_ts_14") },
    { value: 5, name: this.translate.instant("ContractCensorship_ts_15") }
  ]

  constVerificationType = {
    'VIDEO_CALL': "PartyJoin_text_1",
    'NONE': "PartyJoin_text_2",
    "": "PartyJoin_text_2"
  };

  userTypeConst = UserTypeConstant;
  userTypeCompany = UserTypeCompany;

  //Tab controll
  activeTab: string = 'INFO'; //'CENSORSHIP';

  //Ma hoa file
  contractFileInfo: ContractFileInfo = new ContractFileInfo();
  keySend: string = '';

  $encrypt: any; //JSEncrypt

  partiesJoin: Array<PartyJoinContract> = [];

  //Control step sign:
  currentStepSign: number = 0;

  checkboxDk1 = false;
  checkboxDk2 = false;

  //OTP modal confirm sign
  otpSignForm: FormGroup;
  otpSignIsSubmit: boolean = false;
  modalOtpSign: any;
  validateMsgOTPSign = OTP_CONFIRM_SIGN;

  //Dieu khoan
  contentDK: string = "";

  //Cap nhat file hop dong moi
  formUpdateContractFile: FormGroup;
  validMsgUpdateContractFile = {
    file: {
      required: "Vui lòng chọn file đính kèm",
      type: 'File không đúng định dạng!',
      size: 'Kích thước file quá lớn!'
    }
  };
  modalUpdateContractFile = null;
  lbFieldContractFile: string = "ServiceContractDetail_selectContractFile";
  isSubmitFormUpdateContractFile = false;
  fileContractNew: File = null;

  //MAKR: Duyet noi bo
  currentStepSignInternal: number = 0;

  //Tra lai hop dong
  formCancel: FormGroup;
  formReturn: FormGroup;
  validMsgCancel = AasValidateMessage.CANCEL_CONTRACT;
  isCancelSubmit: boolean = false;
  modelReturnContract = null;

  localize = localize;
  USER_PERMISSION = USER_PERMISSION;

  formDenyDiscuss: FormGroup;
  isSubmitFormDenyDiscuss = false;
  validateMsgFormDenyDiscuss = {
    reason: {
      required: "ServiceContractDetai_reason_reject_required",
      maxlength: "ServiceContractDetai_max_length_string"
    }
  }

  //Hinh thuc ky
  signFormSelected = "";

  haveSessionEsignInternal = false;

  currentUserId = this.authService.userId;
  internalDiscussTypes = InternalDiscussTypes;
  // DOCUMENT IN CONTRACT
  documents: Array<any> = new Array();
  signCount: any[] = [];
  limitResendOTP = 2;
  contractNumberBox: ContractNumberBox = null;
  contractNumberValue = "";
  pdfFileNoContractNumber: File = null;

  //add footer checkbox
  checkbox_addfooter: boolean = true;

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private fb: FormBuilder,
    private contractService: AasContractService,
    private authService: AuthService,
    private signatureService: SignatureService,
    private configModal: NgbModalConfig,
    private modalService: NgbModal,
    private loaderSerive: LoadingService,
    private datePipe: DatePipe,
    private enterpriseVipService: EnterpriseVipService,
    private myAlert: AlertControl,
    public controlAccount: ControlAccount,
    private flowContractService: FlowContractService,
    private changeDetectorRef: ChangeDetectorRef,
    private documentInContractService: DocumentInContractService,
    private translate: TranslateService,
    private renderer: Renderer2,
    private commentApi: CommentService,
    private cncService: ContractNumberConfigService
  ) {
    if (!this.controlAccount.checkRoles(['ESO2001'])) {
      this.router.navigate(['/app/console/dashboard']);
    }
    this.timeCheck = this.datePipe.transform(new Date(), "yyyy-MM-dd'T'HH:mm:ss");
    this.controlAccount.getAccountInfo();
  }

  ngOnInit(): void {
    PDFSign.removeAllSignBox(); console.log("remove all signbox");

    this.makeFormSign();
    this.makeFormDSign();
    this.makeConfirmOTPForm();
    this.makeOTPSignForm();
    this.makeFormUpdateContractFile();
    this.makeFormReturn();
    this.makeFormDenyDiscuss();

    //this.checkPlugin();
    this.checkClientId();
    this.imgContent = this.img_prefix + PDFSign.getData().imageSrc;

    this.getUserInfo(this.authService.userId);
    this.getContract(this.contractId);

    this.getListUsbToken(this.authService.userId);
    this.getSignCount(this.contractId);
  }

  setData() {
    this.partiesJoin = this.contract.contractPartysData.map((item) => {
      var party = new PartyJoinContract({
        partyId: item.partyId,
        tenToChuc: item.tenToChuc,
        userType: item.userType,
        email: item.email,
        tenDaiDien: item.tenDaiDien,
        endDate: item.endDate,
        isTrustedPartner: item.isTrustedPartner,
        signForm: item.signForm,
        verificationType: item.verificationType,
        nguoiNhanData: [
          {
            id: item.partyId,
            text: item.email,
            additional: {
              partyId: item.partyId,
              type_customer: item.userType === 'CONSUMER' ? 'Cá nhân' : 'Doanh nghiệp',
              type_cutomer_label: item.userType,
              email: item.email,
              isTrustedPartner: item.isTrustedPartner
            }
          }
        ]
      });

      if (item.partyId === this.authService.partyId) {
        party.allowDetete = false;
        party.allowEditUserType = false;
        party.allowEditPartyId = false;
        party.isOwner = true;
      }

      return party;
    })
  }

  //MARK: KHỞI TẠO FORM KÝ SIGN SERVER
  makeFormSign() {
    this.formSign = this.fb.group({
      signerName: new FormControl('',),
      textColor: new FormControl('',),
      visibleType: new FormControl(5),
      base64Image: new FormControl(""),
      fontSize: new FormControl("13", [Validators.min(8), Validators.max(20), Validators.pattern(/^[0-9]+$/)]),
      signBy: new FormControl(true),
      signDate: new FormControl(true),
      imageFile: new FormControl(""),
    });
  }

  get signerName() { return this.formSign.get('signerName'); }
  get textColor() { return this.formSign.get('textColor'); }
  get visibleType() { return this.formSign.get('visibleType'); }
  get base64Image() { return this.formSign.get('base64Image'); }
  get fontSize() { return this.formSign.get('fontSize'); }
  get signBy() { return this.formSign.get('signBy'); }
  get signDate() { return this.formSign.get('signDate'); }
  get imageFile() { return this.formSign.get('imageFile'); }

  //MARK: KHỞI TẠO FORM KÝ ĐIỆN TỬ
  makeFormDSign() {
    this.formDSign = this.fb.group({
      imageFile: new FormControl(''),
    })
  }

  get imageFileESign() { return this.formDSign.get('imageFile'); }

  //MARK: KHỞI TẠO FORM OTP
  makeConfirmOTPForm() {
    this.formOTP = this.fb.group({
      otp: new FormControl('', [Validators.required]),
      documentHash: new FormControl('')
    });
  }

  //OTP Form for signserer
  makeOTPSignForm() {
    this.otpSignForm = this.fb.group({
      tranId: [''],
      otp: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(6)]]
    });
  }

  makeFormUpdateContractFile() {
    this.formUpdateContractFile = this.fb.group({
      file: ['', [Validators.required]]
    });
  }

  get otp() { return this.formOTP.get('otp'); }

  //MARK: CHỨC NĂNG ĐIỀU KHIỂN TAB
  //Action chọn tab
  selectTab(nameTab: string) {
    if (this.activeTab == nameTab) return;
    this.activeTab = nameTab;
    // if (nameTab == 'SIGN') {
    //   this.selectWayOfSign('SIGN_SERVER');
    // }  
    if (nameTab === "CENSORSHIP") {
      this.currentStepSignInternal = 0;
    }

    if (nameTab == "VERSION") {
      this.callBackAfterRender(listPage);
    } else {
      this.commentPdf.removeEventClickOnPDF();
      this.commentPdf.removeAllNotePoint();
      this.commentPopup.hidePopup();
    }
    // this.processContractNumberOnPDFView();
  }

  //MARK: THAY ĐỔI GIÁ TRỊ DROPLIST
  changedHinhThucKy(event: Array<any>) {
  }

  //Lấy và hiển thị trạng thái hợp đồng
  get getStatusContract() {
    if (this.currentStage[this.contract.currentStage] !== undefined && this.currentStage[this.contract.currentStage] !== null) {
      let label: any = this.contract.currentStage ? this.translate.instant(this.contract.currentStage) : "";
      return `<span class="badge ${this.currentStage[this.contract.currentStage].style || ''} p-2">${label}</span>`
    } else {
      return '';
    }
  }

  //MARK: CHỨC NĂNG KÝ SỐ SIGN SERVER
  //Chọn phương thức ký
  selectWayOfSign(value: string, signForm = null) {
    this.signFormSelected = "";
    let self = this;
    if (this.checkboxDk1 && this.checkboxDk2) {
      this.signFormSelected = signForm;

      var fn = function() {
        if (value === 'USB_TOKEN') {
          this.removeSignBoxUsbtoken();
          this.getCertInforUsbtoken();
          PDFSign.setStyleSelectedSignBox(2);
          // this.sign();
        } else if (value === 'SIGN_SERVER') {
          this.selectedWayOfSign = value;
          PDFSign.setStyleSelectedSignBox(this.currentSignBoxStyle);
          if (this.imgContent !== '') {
            PDFSign.setImage(this.imgContent.split(',')[1]);
          } else {
            PDFSign.setImageDefault();
          }
          PDFSign.removeAllSignBox();
  
          console.log(this.configCA);
  
          if (this.configCA.certId === '') {
            this.getInfoCASignServer(this.authService.userId);
          }
          
          this.nextStepSign(this.currentStepSign);
          this.showTemplateSignBox(this.contract.getSignFrameAssignee());
        } else if (value === 'E_SIGN') {
          this.signFormSelected = signForm
          this.selectedWayOfSign = "E_SIGN";
          this.haveSessionEsign = true;
          if (signForm == 'OTP_EMAIL') this.limitResendOTP = 3;
          else if (signForm == 'OTP') this.limitResendOTP = 2;
          this.postRequestElectronicSign(this.contractId, this.signFormSelected);
        } else if (value === "SMARTCA") {
          this.selectedWayOfSign = value;
          this.signFormSelected = "SMART_CA";
  
          // this.resetSmartCA();
  
          // PDFSign.setStyleSelectedSignBox(this.currentSignBoxStyle);
          // if (this.imgContent !== '') {
          //   PDFSign.setImage(this.imgContent.split(',')[1]);
          // } else {
          //   PDFSign.setImageDefault();
          // }
          // PDFSign.removeAllSignBox();
  
          // this.getSessionSmartCA();
          
          this.nextStepSign(this.currentStepSign);
          
          this.changeDetectorRef.detectChanges();
          setTimeout(() => {
            this.smartCASign && this.smartCASign.startSignSmartCA();
            this.smartCASign && this.smartCASign.showTemplateSignBox(this.contract.getSignFrameAssignee());
          }, 1);
        }  
      }

      // Gắn số hợp đồng vào file PDF
      if (this.contract.allowGenAddContractNumber()) { //this.contract.allowGenAddContractNumber()
        this.addContractNumberIntoPDF((() => {
            setTimeout(() => {
              let callback = fn.bind(self);
              callback();
            }, 500)
          }).bind(self), (err) => {
          this.myAlert.showAlertNoClose("Đã có lỗi trong quá trình gắn số hợp đồng vào file hợp đồng!", ConstantAlertType.ERROR);
            return;
          });
        if (this.contractNumberBox?.isAddedBox == true) {
          this.contractNumberBox?.removeBox();
        }
      } else {
        let callback = fn.bind(self);
        callback();
      }
    } else {
      this.alert("Bạn cần đồng ý điều khoản trước khi bắt đầu ký!", ConstantAlertType.ERROR);
    }
  }

  /**
   * Set step sign
   * @param index 
   */
  setStepSign(index) {
    this.currentStepSign = index;
    PDFSign.removeAllSignBox(); console.log("remove all signbox");
    if (index === 0) {
      // this.selectWayOfSign('');
      this.selectedWayOfSign = '';

      // Remove chữ ký đã gắn và add lại signbox trên màn hình pdf preview
     
      setTimeout(() => {
        PDFSign.preview(this.pdfFileNoContractNumber); //Preview lại file hợp đồng chưa có contractnumber
      }, 500);
      this.addContractNumberBox();
    };
    // PDFSign.removeAllSignBox(); console.log("remove all signbox");
  } 

  backStepSign(index) {
    if ((this.currentStepSign === 0 || this.currentStepSign === 1) && this.selectedWayOfSign === "E_SIGN") {
      this.signFormSelected = "";
      this.selectedWayOfSign = "";
      this.haveSessionEsign = false;
      if (this.currentStepSign == 0) {
        this.removeContractNumberAndRollBackPreview();
      }
    }
    if (index === 2) {
      PDFSign.preview(this.filePDF);
      this.getBase64String(this.filePDF, this.handle);

      if (this.selectedWayOfSign === "E_SIGN") {
        this.selectWayOfSign('E_SIGN', this.signFormSelected);
        this.currentStepSign = 0;
        this.removeContractNumberAndRollBackPreview();
        return;
      }

      if (this.selectedWayOfSign === "SMARTCA") {
        this.smartCASign && this.smartCASign.resign();
        this.smartCASign && this.smartCASign.showTemplateSignBox(this.contract.getSignFrameAssignee());
        this.showLayoutConfirmSmartCA = false;
        this.timeoutSmartCa = false;
      }
      if (this.selectedWayOfSign == "USB_TOKEN") {
        this.removeSignBoxUsbtoken();
      }

      if (this.selectedWayOfSign == "SIGN_SERVER") {
        this.showTemplateSignBox(this.contract.getSignFrameAssignee());
      }
    };
    if (index - 1 >= 0) this.setStepSign(index - 1);
  }

  /**
   * Nút trở về trong quá trình ký _v2
   * @param stepSign 
   */
  backStepSignV2(stepSign) {
    if (this.selectedWayOfSign === "E_SIGN" && (this.signFormSelected == "OTP" || this.signFormSelected == "OTP_EMAIL") && stepSign == 0) {
      //Nếu là hình thức ký OTP và ở bước OTP
      let msg = this.signFormSelected == "OTP" ? "SMS_OTP_alert_back_when_sign" : "EMAIL_OTP_alert_back_when_sign";
      this.myAlert.showAlert(
        msg,
        "", true, "", "btn_confirm", () => {
          this.backStepSign(stepSign);
        })
    } else {
      this.backStepSign(stepSign);
    }
  }

  nextStepSign(index) {
    this.setStepSign(index + 1);
  }

  savedSignature() {
    this.nextStepSign(this.currentStepSign);
  }

  //Set dữ liệu config mặc định
  setConfigSign(signConfig: InfoSignatureConfigObject) {
    console.log(signConfig);
    this.signerName.setValue(signConfig.signerName || "");

    this.visibleType.setValue(signConfig.visibleType || 5);
    this.currentSignBoxStyle = Number(this.visibleType.value) || 5;
    PDFSign.setStyleSelectedSignBox(Number(this.visibleType.value) || 5);

    this.base64Image.setValue(signConfig.base64Image || default_img);
    PDFSign.setImage(this.base64Image.value || default_img);
    this.imgContent = this.img_prefix + (signConfig.base64Image || default_img);

    this.fontSize.setValue(signConfig.fontSize || "13");
    PDFSign.setFontSize(this.fontSize.value || "13")

    this.signBy.setValue(signConfig.signBy || false);
    PDFSign.setShowSignBy(this.signBy.value || false);

    this.signDate.setValue(signConfig.signDate || false);
    PDFSign.setShowDateSign(this.signDate.value || false);

    console.log(this.formSign.value);
  }

  //Upload ảnh chữ ký
  handleFileImageInput(e) {
    this.imgSignServer = e.target.files && e.target.files.item(0);
    if (this.imgSignServer && this.imgSignServer !== null && this.imgSignServer !== undefined) {
      this.inputFileImageLabel = this.imgSignServer.name;

      if (!this.imgType.includes(this.imgSignServer.type)) {
        this.imageFile.setErrors({ type: true });
        // this.imgContent = '';
      } else if (this.imgSignServer.size > 2000000) {
        this.imageFile.setErrors({ size: true });
        this.getBase64String(this.imgSignServer, this.handleImgSignServer);
      } else {
        this.getBase64String(this.imgSignServer, this.handleImgSignServer);

        var self = this;
        var u = URL.createObjectURL(this.imgSignServer);
        var img = new Image;
        img.src = u;
        img.onload = function () {
          self.resizeImage(img, self.imgSignServer, 100, 100, '');
        }
      }

    } else {
      this.imageFile.setErrors({ required: true });
      this.imgContent = '';
      this.inputFileImageLabel = 'Chọn hình ảnh';
    }
  }

  //thêm chữ ký
  addSignBox() {
    if ((!this.imgESign && this.selectedWayOfSign === "E_SIGN") || (!this.imgContent && this.selectedWayOfSign == "SIGN_SERVER")) {
      this.myAlert.showAlertOnlyNoti(this.translate.instant('contract_alert_no_image_esign'), "");
    } else {
      PDFSign.addSignBox();
    }
  }

  //Nhập font size chữ ký
  inputFontSize(event) {
    PDFSign.setFontSize(event.target.value)
  }

  //Check box ngày ký
  changedDateSign(event) {
    PDFSign.setShowDateSign(this.signDate.value);
  }

  //Check box ký bởi
  changedSignBy(event) {
    PDFSign.setShowSignBy(this.signBy.value);
  }

  //Chọn hình thức hiển thị chữ ký
  selectStyleSignBox(e) {
    this.currentSignBoxStyle = Number(e.value);
    if (this.selectedWayOfSign === "USB_TOKEN") {
      PDFSign.setStyleSelectedSignBox(Number(e.value) + 1);
    } else {
      PDFSign.setStyleSelectedSignBox(Number(e.value));
    }
  }

  //Hoàn thanh ký số sign server
  finishSignServer() {
    let options: Array<any> = PDFSign.getOptions();
    let listPosition: Array<any> = [];

    if (!this.checkAllowSign()) {
      this.myAlert.showAlertOnlyNoti(this.translate.instant('ServiceContractDetail_cantSignContract'), 'error');
      return;
    }

    if (options.length <= 0) {
      this.myAlert.showAlertOnlyNoti(this.translate.instant('ServiceContractDetail_notEnoughSignature'), 'error');
      return;
    }

    for (var i = 0; i < options.length; i++) {
      let x = options[i].x;
      let y = options[i].y - options[i].h;
      let w = options[i].w + x;
      let h = options[i].h + y;
      let page = options[i].page;

      listPosition.push({
        page: page,
        rectangle: `${x},${y},${w},${h}`
      })
    }

    console.log(this.formSign.value);
    let param =
    {
      "userId": this.authService.userId,
      "base64file": this.filebase.nativeElement.value,
      "listPosition": listPosition,
      "signerBy": this.signBy.value,
      "signerDate": this.signDate.value,
      "fontSize": Number(this.fontSize.value),
      "visibleType": Number(this.visibleType.value),
      "base64Image": this.base64Image.value
    }
    this.postSignServer(param);
  }

  //MARK: CHỨC NĂNG KÝ SỐ USB TOKEN
  //Check plugin ký usb token
  checkPlugin() {
    const key = this.keyTest; console.log(this.keyTest);
    var self = this;
    vnpt_plugin.checkPlugin().then(function (data) {
      if (data === "1") {
        vnpt_plugin.setLicenseKey(key).then((data) => {
          try {
            console.log(data);
            var jsonParse = JSON.parse(data);
            if (jsonParse && jsonParse.code === -1) {
              self.myAlert.showAlertOnlyNoti(this.translate.instant('ServiceContractDetail_expireLicenseVnptPlugin'), "");
            }
          } catch (e) {
            console.log(e);
            return;
          }
        });
        console.log("ok");
      }
    }).catch(function (e) {
      console.log(e);
      self.myAlert.showAlertOnlyNoti(this.translate.instant('ServiceContractDetail_didntInstallVnptPlugin'), "");
    });
  }

  checkClientId() {
    var qrParam = JSON.parse(localStorage.getItem('_qrParam'));
    var clientId = qrParam.clientId;
    if(clientId != null && clientId != '' && clientId != undefined) {
      this.getUSBLicenseKey(clientId);
    } else {
      this.checkPlugin();
    }
  }
  
  getUSBLicenseKey(clientId) {
    this.contractService.getUSBLicenseKey(clientId).subscribe((res: any) => {
      this.keyTest = res.object.signUSBLicense;
      this.checkPlugin();
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    })
  }

  showLoader() {
    this.loaderSerive.setVisible(true);
  }

  hideLoader() {
    this.loaderSerive.setVisible(false);
  }

  //Action ký usb token
  sign() {
    this.showLoader();
    // if (!this.isSigned) {

    // }
    this.output.nativeElement.value = "";
    var dataInput = this.filebase.nativeElement.value;
    if (dataInput.length === 0) {
      this.hideLoader();
      this.alert(this.translate.instant('ServiceContractDetail_NotFoundContractFile'), 'error');
      return;
    }
    var fileName = this.filename.nativeElement.value;
    console.log(fileName);
    var fileSplit = fileName.split(".");
    var fileExtension = fileSplit[fileSplit.length - 1].toLowerCase();
    if (fileExtension !== "xml" && fileExtension !== "pdf" && fileExtension !== "docx"
      && fileExtension !== "xlsx" && fileExtension !== "pptx" && fileExtension !== "txt") {
      this.hideLoader();
      this.alert(this.translate.instant('ServiceContractDetail_invalidTypeFileSign'), 'error');
      return;
    }
    var sigOptions = null;
    if (fileExtension === "pdf") {
      sigOptions = new PdfSigner();
      sigOptions.AdvancedCustom = false;
      sigOptions.SigTextSize = 13;
      sigOptions.ImageBase64 = this.base64Image.value;
      sigOptions.SigType = Number(this.visibleType.value) || 0;
      sigOptions.SigSignerVisible = this.signBy.value || false;
      sigOptions.SigSigningTimeVisible = this.signDate.value || false;
      sigOptions.page = this.signatureUsb.page;
      sigOptions.llx = this.signatureUsb.x;
      sigOptions.lly = this.signatureUsb.y - this.signatureUsb.h;
      sigOptions.urx = this.signatureUsb.x + this.signatureUsb.w;
      sigOptions.ury = this.signatureUsb.y;
      sigOptions.SigEmailVisible = false;

      console.log(sigOptions);
    }
    this.SignAdvanced(dataInput, fileExtension, sigOptions, this.showMessage);
  }

  //Bắt đầu kí
  SignAdvanced(data, type, option, callback) {
    var dataJS = {};
    var arrData = [];
    // 1			
    dataJS["data"] = data;
    dataJS["type"] = type;
    dataJS["sigOptions"] = JSON.stringify(option);

    var jsData = "";
    jsData += JSON.stringify(dataJS);
    //
    arrData.push(jsData);

    var vm = this;
    // vnpt_plugin.CheckToken().then((data) => {
    //   console.log("check token ==> ", data);
    //   if (data === "1") {
        vnpt_plugin.getCertInfo(null, true).then((data) => {
          if (data) {
            var certData = JSON.parse(data)
            console.log(certData)
            var serial = certData["serial"];
            // vnpt_plugin.CheckOCSP(serial, vm.timeCheck, null, null).then((result) => {
            //   console.log(result)
            //   console.log(serial, "    ", vm.timeCheck);

            //   if (result === "0") {
            //     vnpt_plugin.signArrDataAdvanced(arrData, serial, true).then((signData) => {
            //       vm.showMessage(signData);
            //     }).catch(function (e) {
            //       alert(e);
            //       vm.hideLoader();
            //     });
            //   } else {
            //     vm.showMessage(result);
            //   }
            // });

            vnpt_plugin.signArrDataAdvanced(arrData, serial, true).then((signData) => {
              vm.showMessage(signData);
            }).catch(function (e) {
              alert(e);
              vm.hideLoader();
            });
          } else {
            this.loaderSerive.hideElement();
            this.myAlert.showAlertOnlyNoti(this.translate.instant('ServiceContractDetail_youCanceledSignSession'), 'error');
          }
        })
    //   } else {
    //     this.hideLoader();
    //     this.alert("Bạn cần cắm token trước khi ký số.", 'error');
    //   }
    // });
  }

  //Show message response
  showMessage(data) {
    this.hideLoader();
    var jsOb = JSON.parse(JSON.parse(data)[0]);
    var err = '';
    switch (jsOb.code) {
      case 0:
        this.pdfSigned = this.converBase64toBlob(jsOb.data, 'application/pdf');

        err = "VNPT_CA_Plugin_0";
        break;
      case 1:
        err = "VNPT_CA_Plugin_1";						
        break;
      case 2:
        err = "VNPT_CA_Plugin_2";						
        break;
      case 3:
        err = "VNPT_CA_Plugin_3";
        break;
      case 4:
        err = "VNPT_CA_Plugin_4";						
        break;
      case 5:
        err = "VNPT_CA_Plugin_5";
        break;
      case 6:
        err = "VNPT_CA_Plugin_6";
        break;
      case 7:
        err = "VNPT_CA_Plugin_7";
        break;
      case 8:
        err = "VNPT_CA_Plugin_8";
        break;				
      case 9:
        err = "VNPT_CA_Plugin_9";
        break;
      case 10:
        err = "VNPT_CA_Plugin_10";
        break;
      case 11:
        err = "VNPT_CA_Plugin_11";
        break;
      case 13:
        err = "VNPT_CA_Plugin_13";
        break;
      default:
        err = "VNPT_CA_Plugin_errorDefault";
    }
    if (jsOb.code !== 0) {
      this.output.nativeElement.value = err;
      this.myAlert.showAlertOnlyNoti(err, 'error');
    } else {
      PDFSign.preview(this.pdfSigned);
      this.getBase64String(this.pdfSigned, this.handle);
      this.savedSignature();
      this.myAlert.showAlertOnlyNoti(err ? this.translate.instant(err) : err, 'success', '', this.translate.instant('btn_ok'), () => {
        //Reupload 
        // PDFSign.preview(this.pdfSigned);
        // this.savedSignature();
      });
    }
  }

  //MARK: CHỨC NĂNG KÝ ĐIỆN TỬ
  //handle file hình chữ ký điện tử
  handleFileImgESignInput(e) {
    this.imgESign = e.target.files && e.target.files.item(0);
    if (this.imgESign && this.imgESign !== null && this.imgESign !== undefined) {

      this.myAlert.showAlert(this.translate.instant('ServiceContractDetail_contract_confirm_save_img_esign'), "", true, "", this.translate.instant('btn_save'), () => {
               let data = new FormData();
        data.append('image', this.imgESign);
        this.postDigitalSignature(data);
      }, "", this.translate.instant('btn_not_now'));

      this.inputImgESignLabel = this.imgESign.name;

      if (!this.imgType.includes(this.imgESign.type)) {
        this.imageFileESign.setErrors({ type: true });
        // this.imgESignContent = '';
      } else if (this.imgESign.size > 2000000) {
        this.imageFileESign.setErrors({ size: true });
        this.getBase64String(this.imgESign, this.handelimgESign);
      } else {
        this.getBase64String(this.imgESign, this.handelimgESign);

        var self = this;
        var u = URL.createObjectURL(this.imgESign);
        var img = new Image;
        img.src = u;
        img.onload = function () {
          self.resizeImage(img, self.imgESign, 500, 500, 'DSIGN');
        }

        let formData = new FormData();
        formData.append("signImg1", this.imgESign, "signImg1.png");
        this.postGetImageSign(this.idSign, formData);
      }

    } else {
      this.imageFileESign.setErrors({ required: true });
      this.imgESignContent = '';
      this.imgEsignConfig = '';
      this.inputImgESignLabel = this.translate.instant('ServiceContractDetail_select_image');
    }
    // this._inputFile.nativeElement.value = '';
  }

  handelimgESign(str, self) {
    self.imgESignContent = str;
    self.imgEsignConfig = str;
    PDFSign.setImage(str.split(',')[1]);
  }

  //lấy thông tin cấu hình chữ ký điện tử
  selectCert(item: DigitalSignatureItem) {
    this.eCertSelected = item;
    this.imgESignContent = 'data:image/jpg;base64,' + item.base64Image;
    this.imgEsignConfig = 'data:image/jpg;base64,' + item.base64Image;

    let blob: any = this.converBase64toBlob(item.base64Image, 'image/jpg');
    blob.lastModifiedDate = new Date();
    blob.name = 'signImg1.jpeg';
    this.imgESign = <File>blob;

    // PDFSign.setImage(item.base64Image);
    let formData = new FormData();
    formData.append("signImg1", blob, "signImg1.png");
    this.postGetImageSign(this.idSign, formData);
  }

  //Set hình ảnh ý default
  selectDefaultEsign() {
    this.imgESignContent = 'data:image/jpg;base64,' + default_img;
    this.imgEsignConfig = 'data:image/jpg;base64,' + default_img;

    let blob: any = this.converBase64toBlob(default_img, 'image/jpg');
    blob.lastModifiedDate = new Date();
    blob.name = 'signImg1.jpeg';
    this.imgESign = <File>blob;

    // PDFSign.setImage(item.base64Image);
    let formData = new FormData();
    formData.append("signImg1", blob, "signImg1.png");
    this.postGetImageSign(this.idSign, formData);
  }

  //Gửi yêu cầu ký điện tử
  sendRequestElectronicSign() {
    // this.isOTPSubmit = false;
    let options: Array<any> = PDFSign.getOptions();
    // let listPosition: Array<any> = [];

    if (!this.checkAllowSign()) {
      this.myAlert.showAlertOnlyNoti(this.translate.instant('ServiceContractDetail_cantSignContract'), 'error');
      return;
    }

    if (options.length <= 0) {
      this.myAlert.showAlertOnlyNoti(this.translate.instant('ServiceContractDetail_notEnoughSignature'), 'error');
      return;
    }

    this.actESign();

    // this.postRequestElectronicSign(this.contractId);
  }

  //Show popup confirm OTP
  openModalConfirmOTP(content) {
    this.otp.setValue('');
    this.modalService.open(content, {
      size: 'md',
      backdrop: 'static',
      keyboard: false
    });
  }

  //Submit opt
  submitOTP() {
    this.isOTPSubmit = true;
    if (this.idSign === '') {
      this.myAlert.showAlertOnlyNoti(this.translate.instant('ServiceContractDetail_notFoundSignSession'), 'error');
      return;
    }
    if (this.formOTP.valid) {
      const param = {
        otp: this.otp.value,
        documentHash: ''
      }

      this.postConfirmOTPESign(this.idSign, param);
    }
  }

  //Ký điện tử
  actESign() {
    if (!this.imgESign) {
      this.myAlert.showAlertOnlyNoti(this.translate.instant('contract_alert_no_image_esign'), "");
    } else {
      let options: Array<any> = PDFSign.getOptions();
      let multiSign: Array<any> = [];
      let data: FormData = new FormData();
  
      for (var i = 0; i < options.length; i++) {
        let x = options[i].x + options[i].w;
        let y = options[i].y;
        let x2 = options[i].x;
        let y2 = options[i].y - options[i].h;
        let page = options[i].page;
  
        multiSign.push({
          pageSign: page - 1,
          bboxSign: [x2, y2, x, y]
        })
      }
  
      data.append('signImg1', this.imgESign, "signImg1.png");
      data.append('multiSign', JSON.stringify(multiSign));
      data.append('documentHash', "");
  
      this.postESign(this.idSign, data);
    }

  }

  //MARK: CHỨC NĂNG GỬI HỢP ĐỒNG
  //Gửi hợp đồng ký usbtoken
  sendContract() {
    var sendContractFn = () => {
      if (this.isContractVip === 'Y') {
        if (this.pdfSigned !== null && this.pdfSigned !== undefined) {
          let data = new FormData();
          data.append('file', this.pdfSigned);
  
          this.postSendContractVip(this.contractId, data);
        } else {
          this.alert('Không tìm thấy file hợp đồng đã ký!', 'error', false);
        }
      } else {
        this.alert(ConstantAlertMsg.CONTRACT_CONFIRM_SEND_DRAFT, ConstantAlertType.BLANK, true, '', ConstantAlertBtn.CONFIRM, () => {
          if (this.contract.currentStage === "LC_DRAFT_CREATE") {
            //Gui hop dong nhap
            this.postSubmitSendDraft(this.contractId);
          } else {
            //gui hop dong da ky
            if (this.pdfSigned !== null && this.pdfSigned !== undefined) {
              let data = new FormData();
              data.append('file', this.pdfSigned);
              data.append('data', JSON.stringify({ SignForm: this.signFormSelected }));
              this.postSendContract(this.contractId, data);
            } else {
              this.alert('Không tìm thấy file hợp đồng đã ký!', 'error', false);
            }
          }
        }, '', ConstantAlertBtn.CANCEL);
      }
    }
    
    
    if (this.allowGenContractNumber) {
      this.submitFileAddedContractNumber(this.contractNumberBox?.text, this.filePDF, sendContractFn.bind(this));
    } else {
      sendContractFn();
    }
  }

  //Tải xuống file hợp đồng
  saveContract() {
    if (this.contract.currentStage === 'LC_CONTRACT_VALID') {
      this.getContractFileInfoDownload(this.contractId, 'CONTRACT', this.contractId);
    } else {
      this.getContractFileInfoDownload(this.contractId, 'DRAFT', this.contractId);
    }
  }

  /**
 * Lay thogn tin file hop dong
 * @param id 
 * @param type 
 * @param fileKey 
 */
  getContractFileInfoDownload(id, type, fileKey) {
    this.contractService.getContractFileInfo(id, type, fileKey).subscribe((res: any) => {
      this.contractFileInfo = new ContractFileInfo(res.object);
      this.keySend = this.contractFileInfo.keySend;

      this.downloadContractFileByUrlForSave(this.contractFileInfo.url, type, this.keySend, id);
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    })
  }


  /**
   * Tai file hop dong tu url
   * @param url 
   */
  downloadContractFileByUrlForSave(url, type, keySend, id) {
    this.contractService.downloadContractFileByUrl(url).subscribe((res: any) => {
      const blob: any = new Blob([res]);

      if (keySend) {
        //Neu ton tai keySend
        //Tien hanh giai ma
        let secretKey = EncodeService.rsa.decrypt(this.authService.key, this.keySend);
        this.loadFileForSave(secretKey, blob, id);
      } else {
        //Neu khong ton tai keySend
        //Tien hanh luu file file PDF
        var url = window.URL.createObjectURL(blob);
        var anchor = document.createElement("a");
        anchor.download = `${id}.pdf`;
        anchor.href = url;
        anchor.click();
      }
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    })
  }

  /**
 * Giai ma file
 * @param secretKey 
 * @param fileEncoded 
 */
  loadFileForSave(secretKey, fileEncoded, id) {
    var self = this;
    var reader = new FileReader();
    reader.onload = function () {
      var result: any = reader.result;
      var _base64DecodeRes = Buffer.from(result, 'base64');
      var iv = _base64DecodeRes.slice(0, 16);
      var dataEncrypt = _base64DecodeRes.slice(16, _base64DecodeRes.byteLength);

      // console.log(result, result.slice(0, 16));
      var typedArray = EncodeService.aes.decrypt(dataEncrypt, secretKey, iv);

      const bufferpdf = Buffer.from(typedArray);
      const blob: any = new Blob([bufferpdf], { type: 'application/pdf' });
      var url = window.URL.createObjectURL(blob);
      var anchor = document.createElement("a");
      anchor.download = `${id}.pdf`;
      anchor.href = url;
      anchor.click();
    }
    reader.readAsText(fileEncoded);
  }

  //MARK: CHUYEN KIEM DUYET NOI BO
  sendToCensorship() {
    this.contractCensorshipComponent && this.contractCensorshipComponent.openPopupSendCensorship();
  }

  finishCensorship() {
    this.contractCensorshipComponent && this.contractCensorshipComponent.finishCensorship();
  }

  kyNhayUsbToken() {
    this.contractCensorshipComponent && this.contractCensorshipComponent.kyNhayUsbToken();
  }

  kyNhayVNPTKySo() {
    this.contractCensorshipComponent && this.contractCensorshipComponent.kyNhayVNPTKySo();
  }

  duyetKhongKy() {
    this.contractCensorshipComponent && this.contractCensorshipComponent.duyetKhongKy();
  }

  tuChoiDuyet() {
    this.contractCensorshipComponent && this.contractCensorshipComponent.tuChoiDuyet();
  }

  backStepSignInternal(step) {
    this.contractCensorshipComponent && this.contractCensorshipComponent.resign(step);
  }

  saveSignInternal() {
    this.contractCensorshipComponent && this.contractCensorshipComponent.saveSig();
  }

  endSignInternal() {
    this.contractCensorshipComponent && this.contractCensorshipComponent.endSignInternal();
  }

  resignInternal(step) {
    this.contractCensorshipComponent && this.contractCensorshipComponent.resign(step);
  }
  
  cancelSignInternal(step) {
    this.contractCensorshipComponent && this.contractCensorshipComponent.cancelSign(step);
  }

  /**
   * Hop dong khong dam phan moi duoc duyet noi bo
   */
  get allowApproveInternal() {
    return this.contract.allowApproveInternal;
  }

  get checkAllowActionAssignInternal() {
    return this.contract.checkAllowActionAssignInternal;
  }

  /**
   * Kiem tra co quyen ket thuc kiem duyet hay khong
   * @returns 
   */
  checkAllowFinishCensorship = new Promise<BehaviorSubject<Boolean>>((res) => {
    if (!this.contract.contractId) res(new BehaviorSubject(false));

    if (this.isOwnerParty && (this.contract.contractFlowTemplateId != null || this.contract.contractFlowTemplateId != "")) res(new BehaviorSubject(false));

    if (this.contract.contractInternalData.length <= 0) res(new BehaviorSubject(false));

    let rootPartyId = this.authService.partyId || "";
    if (rootPartyId === "") res(new BehaviorSubject(false));

    let partyAssignee = this.contract.signerId;
    if (partyAssignee !== rootPartyId) res(new BehaviorSubject(false));

    let currentParty = this.contract.contractPartysData.find((item) => { return item.partyId === rootPartyId });
    if (!currentParty) res(new BehaviorSubject(false));

    let currentPartyFinished = currentParty.internalFinish || false;
    if (currentPartyFinished) res(new BehaviorSubject(false));

    let currentUserAssignee = currentParty?.internalSignerId || "";
    if (currentUserAssignee !== "" && currentUserAssignee !== this.authService.userId) res(new BehaviorSubject(false));

    let allApprove = true;
    this.contract.contractInternalData.forEach((item, index) => {
      if (item.action === null || item.action === "") allApprove = false;
    });
    if (!allApprove) res(new BehaviorSubject(false));

    res(new BehaviorSubject(true));
  });

  /**
   * Kiem tra dieu kien duoc thao tac kiem duyet
   * @returns 
   */
  get checkAllowActionApproveInternal() {
    if (!this.allowApproveInternal) return false;

    if (!this.contract.contractId) return false;
    
    if (this.userType === "CONSUMNER") return false;

    return true;

    let rootPartyId = this.authService.partyId || "";
    if (rootPartyId === "") return false;

    let partyAssignee = this.contract.signerId;
    if (partyAssignee !== rootPartyId) return false;

    let currentParty = this.contract.contractPartysData.find((item) => { return item.partyId === rootPartyId });
    if (!currentParty) return false;

    let currentPartyFinished = currentParty.internalFinish || false;
    if (currentPartyFinished) return false;

    let contractValid = this.contract.currentStage === "LC_CONTRACT_VALID";
    if (contractValid) return false;
    
    let isDealing = this.contract.currentStage === "LC_DRAFT_DISCUSS" || this.contract.currentStage === "LC_DRAFT_DEAL";
    if (contractValid || isDealing) return false;

    return true;
  }

  /**
   * Kiem tra quyen den luot thuc hien kiem duyet
   */
  get checkAllowStartApproveInternal() {
    if (!this.allowApproveInternal) return false;

    if (!this.contract.contractId) return false;

    let contractValid = this.contract.currentStage === "LC_CONTRACT_VALID";
    if (contractValid) return false;

    let rootPartyId = this.authService.partyId || "";
    if (rootPartyId === "") return false;

    let partyAssignee = this.contract.signerId;
    if (partyAssignee !== rootPartyId) return false;

    let currentParty = this.contract.contractPartysData.find((item) => { return item.partyId === rootPartyId });
    if (!currentParty) return false;

    let currentPartyFinished = currentParty.internalFinish;
    if (currentPartyFinished === true) return false;

    let currentUserAssignee = currentParty.internalSignerId || "";
    if (currentUserAssignee !== this.authService.userId) return false;

    let indexAprrovalInternal = this.contract.contractInternalData.findIndex((item) => {return item.userId === currentUserAssignee && (item.action == "" || item.action == null);});
    if (indexAprrovalInternal < 0) return false;

    return true;
  }

  //MARK: NHÓM CHỨC NĂNG CHECK QUYỀN THAO TÁC VÀ ĐIỀU HƯỚNG
  //Kiểm tra quyền gửi hợp đồng
  get allowSendContract() {
    // if (this.contract.currentStage === 'LC_CONTRACT_VALID') return false;
    if (this.contract.currentStage !== 'LC_DRAFT_CREATE') return false;
    if (!this.contract.isOwnerParty) return false;
    if (!this.contract.isCreator) return false;
    return true;
    // return this.checkAllowSign();
  }

  //Kiểm tra quyền cập nhật
  checkAllowEdit() {
    let userId = this.authService.partyId;
    let ownerPartyId = this.contract.ownerPartyId;
    let status = this.contract.currentStage;

    if (status !== 'LC_DRAFT_CREATE') return false;
    
    if (this.authService.partyId !== ownerPartyId) {
      return false;
    }

    if (!this.isCreator) {
      return false;
    }

    return true;
  }

  //Kiểm tra quyền ký
  checkAllowSign() {
    return this.contract.isAllowedSign;
  }

  /**
   * Check co dang cho phuong thuc ky hay khong
   */
  get isActionSign() {
    if (!this.checkAllowSign()) return false;
    if (!this.selectedWayOfSign || this.selectedWayOfSign === "") return false;
    return true;
  }

  //Kiểm tra quyền sử dụng phương thức ký
  allowActionSignType(signType: string) {
    //Trang thai hop dong duoc phep ky
    if (!this.checkAllowSign()) return false;

    //Ton tai signType
    if (signType === '') return false;

    //La ben tao ra hop dong va la tai khoan admin
    if (this.contract.isOwnerParty && this.authService.isAdmin) return true;

    //La ben tao hop dong va khong phai tai khoan admin
    if (this.contract.isOwnerParty && !this.authService.isAdmin) {//Neu la ben tao hop dong va khong phai la admin
      let allow = this.allowActionSignFormInternal(signType);//Kiem tra neu la account noi bo ky
      if (!allow) return false;
    }

    //Kiem tra to chuc hien tai co tham gia hop dong hay khong
    var partJoined = this.contract.contractPartysData.find((item) => {
      return item.partyId === this.authService.partyId;
    });

    //Neu to chuc co tham gia hd: Kiem phuong thuc ky co kha dung voi to chuc hay khong
    if (partJoined) {
      if (!partJoined.signForm.includes(signType) && !this.contract.isOwnerParty) return false;
    } else {
      return false;
    }

    return true;
  }

  get notHaveSignTypeAllow() {
    let ok = true;
    if (this.contract.ownerPartyId === this.authService.partyId) return false;
    // console.log(this.contract.ownerPartyId , this.authService.partyId);

    //Kiem tra to chuc hien tai co tham gia hop dong hay khong
    var partJoined = this.contract.contractPartysData.find((item) => {
      return item.partyId === this.authService.partyId;
    });

    // console.log(partJoined);

    if (partJoined) {
      if (this.userType === UserTypeConstant.CONSUMER) {
        this.hinhThucKy_consumer.forEach(item => {
          if (partJoined.signForm.includes(item.id)) ok = false;
        })
      } else if (this.userType === UserTypeConstant.BUSINESS) {
        this.hinhThucKy_business.forEach(item => {
          if (partJoined.signForm.includes(item.id)) ok = false;
        })
      } else if (this.userType === UserTypeConstant.ENTERPRISE) {
        this.hinhThucKy_business.forEach(item => {
          if (partJoined.signForm.includes(item.id)) ok = false;
        })
      }
    } else {
      return true;
    }

    return ok;
  }

  /**
   * Dieu kien mo tab dam phan
   */
  get allowDiscuss() {
    if (this.contract.currentStage === 'LC_DRAFT_DISCUSS' || this.contract.currentStage === 'LC_DRAFT_DEAL') return true;
    return false;
  }

  //Trở lại trang trước
  goToBack() {
    // this._location.back();
    this.router.navigate([WebviewURL.contract_list]);
  }

  //Đến cập nhật hợp đồng
  goToEdit() {
    this.router.navigate([WebviewURL.contract_edit, this.contractId]);
  }

  //MARK: CHUC NANG CHOT HOP DONG
  /**
   * Dieu kien cho phep chot hop dong
   */
  get allowConfirmContract() {

    if (this.contract.discuss === false) return false;

    if (this.contract.currentStage !== "LC_DRAFT_DISCUSS" && this.contract.currentStage !== "LC_DRAFT_DEAL") return false;

    let isDiscuss = this.contract.userPermissions[USER_PERMISSION.PARTNER_ACCEPTABLE];
    if (!isDiscuss) return false;
    
    var currentUser: ContractParty = null;
    currentUser = this.contract.contractPartysData.find((x) => { return x.partyId === this.authService.partyId; });
    if (currentUser == null) return false;
    if (currentUser) {
      if (currentUser.deal === true || currentUser.deal === false) { return false; }
    }

    var currentEmployee: PartnerDiscuss = null;
    currentEmployee = this.contract.partnerDiscuss.find((x) => { return x.userId == this.authService.userId; });
    if (currentEmployee == null) return false;
    if (currentEmployee) {
      if (currentEmployee.deal === true || currentEmployee.deal === false) { return false; }
      if (currentEmployee.per_action.PARTNER_ACCEPTABLE === false) return false;
    }

    return true;
  }

  get allowShowDiscussTab() {
    if (this.contract.discuss === false) return false;

    if (this.contract.currentStage === "LC_DRAFT_CREATE") return false;

    let isCreator = this.isCreator;
    let isDiscuss = this.contract.userPermissions[USER_PERMISSION.PARTNER_DISCUSSABLE];
    let isAcceptDeny = this.contract.userPermissions[USER_PERMISSION.PARTNER_ACCEPTABLE];
    let isEditable = this.contract.userPermissions[USER_PERMISSION.PARTNER_EDITABLE];
    if (!isCreator && !isDiscuss && !isAcceptDeny && !isEditable) return false;

    return true;
  }

  get allowUpdateFileContractInDiscuss() {
    if (this.contract.amId !== this.authService.userId) return false;

    if (this.contract.currentStage != "LC_DRAFT_DISCUSS" && this.contract.currentStage != "LC_DRAFT_DEAL") return false;

    var haveDeny: ContractParty = null;
    haveDeny = this.contract.contractPartysData.find((x) => { return x.deal === false; });
    if (!haveDeny) return false;

    return true;
  }

  get allowShowSignTab() {
    if (this.contract.currentStage == "LC_DRAFT_DISCUSS" || this.contract.currentStage == "LC_DRAFT_DEAL") return false;
    return true;
  }

  /**
   * Dong y hop dong
   */
  confirmAcceptContract() {
    this.myAlert.showAlert(this.translate.instant('ServiceContractDetail_confirm_accept_draft'), ConstantAlertType.BLANK, true, '', this.translate.instant('btn_confirm'), () => {
      this.postAcceptContract(this.contractId);
    }, '', this.translate.instant('btn_close'), null, this.translate.instant('confirm'));
  }

  /**
   * Tu choi hop dong
   */
  confirmDenyContract() {
    if (this.formDenyDiscuss.valid) {
      this.myAlert.showAlert(this.translate.instant('ServiceContractDetail_confirm_deny_draft'), ConstantAlertType.BLANK, true, '', this.translate.instant('btn_confirm'), () => {
        this.modalPartnerDiscuss && this.modalPartnerDiscuss.close();
        this.postDenyContract(this.contractId, this.formDenyDiscuss.value.reason || "");
      }, '', this.translate.instant('btn_close'), null, this.translate.instant('confirm'));
    }
  }

  openPopupUpdateContractFile() {
    this.isSubmitFormUpdateContractFile = false;
    this.fileContractNew = null;
    this.modalUpdateContractFile = this.modalService.open(this.updateContractFile);
  }

  confirmUpdateContractFile() {
    this.isSubmitFormUpdateContractFile = true;
    if (this.formUpdateContractFile.valid) {
      this.myAlert.showAlert(this.translate.instant('ServiceContractDetail_update_contract_file_confirm'), ConstantAlertType.BLANK, true, "", this.translate.instant('btn_confirm'), () => {
        this.callBackUpdateContractFile();
      })
    }
  }

  callBackUpdateContractFile() {
    if (this.formUpdateContractFile.valid) {
      let formData: FormData = new FormData();
      formData.append('file', this.fileContractNew, this.fileContractNew.name || "");
      this.postUpdateDraftContract(this.contractId, formData);
    }
  }

  /**
   * Chon file tu hop dong
   * @param e 
   */
  handleFileInput(e) {
    this.fileContractNew = e.target.files && e.target.files.item(0);
    this.formUpdateContractFile.get('file').setErrors(null);

    if (this.fileContractNew && this.fileContractNew !== null && this.fileContractNew !== undefined) {
      if (!this.fileType.includes(this.fileContractNew.type)) {
        this.formUpdateContractFile.get('file').setErrors({ type: true });
        this.lbFieldContractFile = this.fileContractNew.name
      } else if (this.fileContractNew.size > 5000000) {
        this.myAlert.showAlertOnlyNoti(this.translate.instant('ServiceContractDetail_fileSizeTooLarge'), ConstantAlertType.ERROR);
      } else {
        this.lbFieldContractFile = this.fileContractNew.name;
        if (this.fileContractNew.type === 'application/pdf') {
          this.fileName = this.fileContractNew.name;
          // PDFSign.preview(this.fileContractNew);
        } else {
          let fileSplit = this.fileContractNew.name.split('.');
          fileSplit.pop();
          this.fileName = `${fileSplit.join('.')}.pdf`;

          let data = new FormData();
          data.append('attachFile', this.fileContractNew);
          this.postConvertToPDF(data);
        }
      }
    } else {
      this.lbFieldContractFile = 'ServiceContractDetail_selectContractFile';
    }
  }

  //MARK: TRA LAI, HUY HOP DONG

  makeFormReturn() {
    this.formReturn = this.fb.group({
      reasonReturn: new FormControl('', [Validators.maxLength(4000)])
    });
  }

  get allowCancelContract() {
    return this.checkAllowSign();
    if (this.authService.partyId !== this.contract.signerId) return false;
    if (this.authService.partyId === this.contract.signerId && this.authService.partyId === this.contract.ownerPartyId) return false;
    if (this.contract.currentStage !== "LC_DRAFT_SUBMIT" && this.contract.currentStage !== "LC_DRAFT_SIGNED") return false;
    if (!this.isOwnerParty) {
      if (!this.authService.isAdmin) return false;
    }
    console.log("allow return contract");
    return true;
  }

  get isOwner() {
    return true;
  }

  //Show popup confirm OTP
  openModalReturn(content, contractId) {
    this.formReturn.reset();
    if (this.contract.isFinishedInternalSign) {
      this.modelReturnContract = this.modalService.open(content, {
        size: 'md',
        backdrop: 'static',
        keyboard: false
      });
    } else {
      this.myAlert.showAlert(this.translate.instant('ServiceContractDetail_department_not_finish_draft_sign'),
        "", true, "", localize.return, () => {
          this.modelReturnContract = this.modalService.open(content, {
            size: 'md',
            backdrop: 'static',
            keyboard: false
          });
        },
        "", this.translate.instant('btn_cancel'), () => {}, this.translate.instant('confirm')
      );
    }
  }

  //Submit huy hop dong
  submitReturn() {
    this.isCancelSubmit = true;
    console.log(this.formReturn.value);
    if (this.formReturn.valid) {
      let param = {
        "cancelReason": this.formReturn.value.reasonReturn
      }
      this.postReturnContract(this.contractId, param);
    }
  }

  //Trả lại hợp đồng
  postReturnContract(contractId, param) {
    this.myAlert.showAlert(this.translate.instant('ServiceContractDetail_returnContractConfirm'), '', true, '', this.translate.instant('btn_ok'), () => {
      this.contractService.postReturnContract(contractId, param).subscribe(res => {
        this.getContract(this.contractId);
        this.myAlert.showAlertOnlyNoti(this.translate.instant('ServiceContractDetail_returnContractSuccess'), 'success', '', this.translate.instant("btn_close"), () => {
          this.modelReturnContract && this.modelReturnContract.close();
        });
      }, err => {
        console.log(err);
        this.myAlert.showErrorHandled(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
      })
    });
  }

  //MARK: COMMON FUNC
  //Kiểm tra form đã có dữ liệu chưa
  get editedForm() {
    return false;
  }

  //convert imgUrl to file object
  urltoFile(url, filename, mimeType) {
    return (fetch(url)
      .then(function (res) { return res.arrayBuffer(); })
      .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
    );
  }

  // resize image 
  resizeImage(img, fileImg, max_width, max_height, resize_for = 'SIGN_SERVER') {
    var width = img.width;
    var height = img.height;

    // calculate the width and height, constraining the proportions
    if (width > height) {
      if (width > max_width) {
        //height *= max_width / width;
        height = Math.round(height *= max_width / width);
        width = max_width;
      }
    } else {
      if (height > max_height) {
        //width *= max_height / height;
        width = Math.round(width *= max_height / height);
        height = max_height;
      }
    }
    var canvas = document.createElement('canvas');
    var ctx = canvas.getContext('2d');
    canvas.width = width;
    canvas.height = height;
    ctx.drawImage(img, 0, 0, width, height);
    var self = this;
    let imgURL = ctx.canvas.toDataURL(fileImg.type, 1);
    this.urltoFile(imgURL, fileImg.name, fileImg.type).then(function (file) {
      if (resize_for === 'SIGN_SERVER') {
        self.imgSignServer = file;
      } else {
        self.imgESign = file;
      }

    });
    canvas.remove();
  }

  converBase64toBlob(content, contentType): Blob {
    //blob = converBase64toBlob(response.content, 'application/pdf');
    contentType = contentType || '';
    var sliceSize = 512;
    var byteCharacters = window.atob(content); //method which converts base64 to binary
    var byteArrays = [
    ];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, {
      type: contentType,
    }); //statement which creates the blob
    return blob;
  }

  getBase64String(blob, callback) {
    var self = this;
    var reader = new FileReader();
    reader.onload = function (e) {
      callback(reader.result, self);
    }

    reader.readAsDataURL(blob);
  }

  //handel cho file pdf
  handle(str, self) {
    $('#fileBase').val(str.split(',')[1]);
  }

  //Handle cho file hình sign server
  handleImgSignServer(str, self) {
    self.imgContent = str;
    self.base64Image.setValue(str.split(',')[1]);
    PDFSign.setImage(str.split(',')[1]);
  }


  //MARK: KY SignServer co OTP
  /**
 * Tạo modal form otp sign-server
 */
  createModalOTPSignForm() {
    this.otpSignIsSubmit = false;
    this.modalOtpSign = this.modalService.open(this.otpConfirmSign, { backdrop: 'static' });
  }

  /**
   * Đóng modal form otp sign-server
   */
  closeModalOTPSignForm() {
    this.modalOtpSign && this.modalOtpSign.close();
  }

  /**
   * Submit comfirm OTP sign server
   */
  submitConfirmOTPSign() {
    this.otpSignIsSubmit = true;

    if (this.otpSignForm.valid) {
      this.closeModalOTPSignForm();
      this.postConfirmOTPSignServer(this.otpSignForm.value);
    }
  }

  /**
   * Bắt sự kiện nhập OTP
   * @param event 
   */
  handleInputOTP(event) {
    const regex = new RegExp(/^\d+$/);
    if (regex.test(event.target.value) && event.target.value.length <= 6) {
      return;
    } else {
      this.otpSignForm.controls.otp.setValue(event.target.value.slice(0, -1));
    }
  }

  //MARK: Hien thi dieu khoan phap ly
  /**
   * Hien thi dieu khoan su dung
   * @param $event 
   */
  showDieuKhoan($event) {
    $event.stopPropagation()
    $event.preventDefault();
    this.getDieuKhoan();
  }

  //MARK: Kiem duyet noi bo
  //Bat su kien reload sau khi hoan thanh phien ky
  handleEventCensorship($event) {
    console.log("censorship: ", $event);
    this.signTypeInternal = "";
    this.currentStepSignInternal = 0;
    this.haveSessionEsignInternal = false;
    this.getContract(this.contractId);
    // this.contractCensorshipComponent.viewDidLoad();
  }

  signStepChanged($event) {
    this.currentStepSignInternal = $event;
  }

  //MARK: Popup thông báo
  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    this.myAlert.showErrorHandled(err);
    // if (err.error && err.error.message && errorMsg[err.error.message]) {
    //   this.alert(errorMsg[err.error.message], 'error');
    // } else if (err.error && err.error.error) {
    //   this.alert(err.error.error, 'error');
    // } else {
    //   this.alert('Lỗi không xác định! Vui lòng thử lại', 'error');
    // }
  }

  //MARK: NETWORKING
  //Khởi tạo hợp đồng
  postCreateDraftOnlyFile(data: FormData) {
    this.contractService.postCreateDraftOnlyFile(data).subscribe(res => {
      this.myAlert.showAlertOnlyNoti(this.translate.instant('ServiceContractDetail_saveContractSuccess'), 'success');
    }, err => {
      console.log(err);
      this.myAlert.showErrorHandled(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    }, () => {

    });
  }

  //Convert docx sang pdf
  postConvertToPDF(data: FormData) {
    this.contractService.postConvertToPdf(data).subscribe(fileData => {
      const blob: any = new Blob([fileData], { type: 'application/pdf' });
      // PDFSign.preview(blob);
      this.fileContractNew = blob;

      // this.getBase64String(blob, this.handle);
    }, err => {
      console.log(err);
      this.myAlert.showErrorHandled(err, ResponseMsg.MESSAGES.ESIGN_SERVICE);
    });
  }

  //Tìm kiếm khách hàng
  searchCustomer(key) {
    this.contractService.searchCustomer(key).subscribe(res => {

    }, er => {

    })
  }

  currentSequence: number = 0;
  minSequence: number = 99999;
  currentPartySign: ContractParty = new ContractParty();
  progressSignatureData(contractPartysData: Array<ContractParty> = []) {
    this.currentSequence = 0;
    this.minSequence = 99999;
    this.currentPartySign = new ContractParty();
    this.signatureParties = [];

    for (let party of contractPartysData) {
      var param: any = {...party};
      var index: number = this.contract.contractPartysData.findIndex((item) => {
        return item.partyId === party.partyId;
      });

      /** Lay thu tu ky cua user dang login */
      if (party.partyId === this.authService.partyId) this.currentSequence = party.sequence;

      if (index >= 0 && this.contract.contractPartysData[index].signDate !== "" && this.contract.contractPartysData[index].signerId !== "") {
        param.isSigned = true;
      } else {
        param.isSigned = false;
        if (this.minSequence > party.sequence) {
          this.minSequence = party.sequence;
          this.currentPartySign = new ContractParty(party);
        }
      }

      this.signatureParties.push(param);
    }

    this.signatureParties.sort((a, b) => {
      return a.sequence - b.sequence;
    });
  }

  //Lấy thông tin hợp đồng
  getContract(id) {
    this.contractService.getContract(id).subscribe(res => {
      this.contract = new ContractResponse(res).object;
      console.log(this.contract);
      this.ListContractPartysData = this.contract.partner;
      this.progressSignatureData(this.contract.contractPartysData);
      this.setDataV2();
      // this.progressData();
      if (this.contract.currentStage === 'LC_CONTRACT_VALID') {
        // this.downloadFileContract(id, 'CONTRACT');
        this.getContractFileInfo(id, 'CONTRACT', id);
        // this.getListSignatureByContract(id, 'CONTRACT');
      } else {
        // this.downloadFileContract(id, 'DRAFT');
        this.getContractFileInfo(id, 'DRAFT', id);
        // this.getListSignatureByContract(id, 'DRAFT');
      }

      if (this.action === 'SIGN') {
        this.selectTab(this.action);
      } else if (this.action === 'DOCUMENT') {
        this.selectTab(this.action);
      }

      if (this.activeTab === 'CENSORSHIP') {
        // this.activeTab = "";
        // this.activeTab = "CENSORSHIP";
        this.contractCensorshipComponent.viewDidLoad();
        this.contractCensorshipComponent && this.contractCensorshipComponent.progressData_v2(this.contract);
      }

      // DOCUMENT IN CONTRACT
      this.documents = this.contract.documents;

      if (this.contract.allowGenAddContractNumber()) { //this.contract.allowGenAddContractNumber()
        this.getDetailContractNumberConfig(this.contract.sequenceId);
      }
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    })
  }

  //Tải file hợp đồng 
  downloadFileContract(id, type) {
    this.contractService.downloadFileWithHeader(id, type).subscribe((res: HttpResponse<any>) => {
      if (res instanceof HttpResponse) {
        // let headers = res.headers.get('X-Token');
        // console.log(headers);

        const blob: any = new Blob([res['body']], { type: 'application/pdf' });
        this.filePDF = blob;
        PDFSign.preview(blob);
        this.fileName = `${id}.pdf`;
        this.getBase64String(this.filePDF, this.handle);
      }

    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    })
  }

  /**
   * Lay thogn tin file hop dong
   * @param id 
   * @param type 
   * @param fileKey 
   */
  getContractFileInfo(id, type, fileKey) {
    this.contractService.getContractFileInfo(id, type, fileKey).subscribe((res: any) => {
      this.contractFileInfo = new ContractFileInfo(res.object);
      this.keySend = this.contractFileInfo.keySend;

      this.downloadContractFileByUrl(this.contractFileInfo.url, type, this.keySend);
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    })
  }

  /**
   * Tai file hop dong tu url
   * @param url 
   */
  downloadContractFileByUrl(url, type, keySend) {
    this.contractService.downloadContractFileByUrl(url).subscribe((res: any) => {
      const blob: any = new Blob([res]);

      if (keySend) {
        //Neu ton tai keySend
        //Tien hanh giai ma
        let secretKey = EncodeService.rsa.decrypt(this.authService.key, this.keySend);
        this.loadFile(secretKey, blob);
      } else {
        //Neu khong ton tai keySend
        //Tien hanh hien thi file PDF
        this.filePDF = blob;
        this.pdfFileNoContractNumber = blob;
        PDFSign.preview(blob);
        this.fileName = `${this.contractId}.pdf`;
        this.getBase64String(this.filePDF, this.handle);

        if (this.allowGenContractNumber) {
          setTimeout(() => {
            this.addContractNumberBox();
          }, 500);
        }
      }
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    })
  }

  /**
   * Giai ma file
   * @param secretKey 
   * @param fileEncoded 
   */
  loadFile(secretKey, fileEncoded) {
    var self = this;
    var reader = new FileReader();
    reader.onload = function () {
      var result: any = reader.result;
      var _base64DecodeRes = Buffer.from(result, 'base64');
      var iv = _base64DecodeRes.slice(0, 16);
      var dataEncrypt = _base64DecodeRes.slice(16, _base64DecodeRes.byteLength);

      // console.log(result, result.slice(0, 16));
      var typedArray = EncodeService.aes.decrypt(dataEncrypt, secretKey, iv);

      const bufferpdf = Buffer.from(typedArray);
      const blob: any = new Blob([bufferpdf], { type: 'application/pdf' });
      self.filePDF = blob;
      PDFSign.preview(blob);
    }
    reader.readAsText(fileEncoded);
  }

  convertWordArrayToUint8Array(wordArray) {
    var arrayOfWords = wordArray.hasOwnProperty("words") ? wordArray.words : [];
    var length = wordArray.hasOwnProperty("sigBytes") ? wordArray.sigBytes : arrayOfWords.length * 4;
    var uInt8Array = new Uint8Array(length), index = 0, word, i;
    for (i = 0; i < length; i++) {
      word = arrayOfWords[i];
      uInt8Array[index++] = word >> 24;
      uInt8Array[index++] = (word >> 16) & 0xff;
      uInt8Array[index++] = (word >> 8) & 0xff;
      uInt8Array[index++] = word & 0xff;
    }
    return uInt8Array;
  }

  //Tải và lưu file hợp đồng 
  saveFileContract(id, type) {
    this.contractService.downloadFileContract(id, type).subscribe((res) => {
      const blob: any = new Blob([res], { type: 'application/pdf' });
      var url = window.URL.createObjectURL(blob);
      var anchor = document.createElement("a");
      anchor.download = `${id}.pdf`;
      anchor.href = url;
      anchor.click();
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    })
  }

  //gửi Hợp đồng đã ký
  postSendContract(id, data) {
    this.contractService.postSendContract(id, data).subscribe(res => {
      // Gửi phản hồi về cho plugin js
      this.sendDataBackToWindowOpener({
        status: "SIGN_SUCCESS",
        contractId: this.contractId
      });
      
      // Load lại dữ liệu hợp đồng
      this.getContract(id);
      this.router.navigate([WebviewURL.contract_detail, id]);

      this.alert(this.translate.instant('ServiceContractDetail_sendContractSuccess'), 'success', false, '', 'Xong', () => {
        // Đóng popup webview
        this.closeWindowPopup();
      });
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    })
  }

  //Lấy thông tin chữ ký số user
  getInfoCASignServer(id) {
    if (this.userTypeCompany.includes(this.userType)) {
      this.signatureService.getInfoCASignServer(id).subscribe(res => {
        console.log(res);
        if (res['object'] == null) {
          this.alert(this.translate.instant('ServiceContractDetail_notConfigVNPTCAAccount'), 'error', true, '', this.translate.instant('ServiceContractDetail_gotoConfig'), () => {
            const url = this.router.createUrlTree(['/app/console/signature-manager'])
            window.open(url.toString(), '_blank')
          }, '', this.translate.instant('btn_close'));
        } else {
          let infoCA = new InfoCASignServerResponse(res).object;
          this.getInfoSignatureConfig(infoCA.certificateDefault.id);
        }
      }, err => {
        console.log(err);
        this.handleError(err, ResponseMsg.MESSAGES.SIGN_SERVER);
      }, () => {
      })
    }
  }

  //Lấy thông tin config chữ ký số sign server
  getInfoSignatureConfig(id) {
    this.signatureService.getInfoSignatureConfig(id).subscribe(res => {
      console.log(res);
      this.configCA = new InfoSignatureConfigResponse(res).object;
      this.setConfigSign(this.configCA);
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.SIGN_SERVER);
    })
  }

  //Hoàn thánh ký sign server
  postSignServer(data) {
    this.contractService.postSignServer(data).subscribe((res: any) => {
      if (res && res.object && res.object.status && res.object.status === 'OTP_CONFIRM') {
        this.otpSignForm.reset();
        this.otpSignForm.patchValue({
          tranId: res.object.tranId || ''
        });

        this.createModalOTPSignForm();
      } else {
        let fileBase64 = res['object']['fileBase64'];
        let blob = this.converBase64toBlob(fileBase64, 'application/pdf');
        this.pdfSigned = blob;
        PDFSign.preview(blob);
        PDFSign.removeAllSignBox(); console.log("remove all signbox");
        this.savedSignature();
        this.alert(this.translate.instant('ServiceContractDetail_saveSignatureSuccess'), 'success', false, '', 'OK', () => {
          // this.router.navigate(['/app/console/contract', id]);
        });
      }
    }, err => {
      console.log(err);
      if (err.error && err.error.message && err.error.message === 'ECT-00000016') {
        this.alert(this.translate.instant('ServiceContractDetail_VNPTCAAccountExpire'), 'error', true, '', this.translate.instant('ServiceContractDetail_relogin'), () => {
          // this.router.navigate(['/app/console/signature-manager']);
          window.open(location.origin + '/app/console/signature-manager', "_blank");
        }, '', this.translate.instant('btn_cancel'));
      } else {
        this.handleError(err, ResponseMsg.MESSAGES.SIGN_SERVER);
      }
    })
  }

  //lấy thông tin chử ký điện thử
  getListESignature() {
    this.signatureService.getListDigitalSignature().subscribe(res => {
      let digitalSignList = new DigitalSignatureResponse(res).object;
      if (digitalSignList.length > 0) {
        this.selectCert(digitalSignList[0]);
      } else {
        this.selectDefaultEsign();
      }
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.ESIGN_SERVICE);
    });
  }

  //Gửi yêu cầu ký điện tử
  postRequestElectronicSign(contractId, signForm) {
    this.contractService.postRequestElectronicSign(contractId, signForm).subscribe((res: any) => {
      this.idSign = res && res.object && res.object.idSign ? res.object.idSign : '';
      if (this.idSign !== '') {
        if (signForm === "NO_AUTHEN") {
          this.confirmOTPSuccess(null);
        } else {
          this.isOTPSubmit = false;
          this.minusSignCount(this.signFormSelected);
          this.limitResendOTP = (this.limitResendOTP - 1) < 0 ? 0 : this.limitResendOTP - 1;
          setTimeout(() => {
            this._eSignOTP.show();
          }, 2)
        }
        // this.openModalConfirmOTP(this.contentConfirmOTP);
      } else {
        this.alert(this.translate.instant('ServiceContractDetail_cantRequestSignSession'), 'error');
      }
    }, err => {
      console.log(err);
      this.myAlert.showErrorHandled(err);
    })
  }

  //Confirm OTP để ký điện tử
  postConfirmOTPESign(idSign, param) {
    this.contractService.postConfirmOTPESign(idSign, param).subscribe(res => {
      this.modalService.dismissAll();

      //Chuyen man hinh ky dien tu khi OTP thanh cong
      this.selectedWayOfSign = "E_SIGN";
      this.nextStepSign(this.currentStepSign);

      if (this.eCertSelected.id === '') {
        this.getListESignature();
      }

      //Setup ca thong so default cho ky dien tu
      PDFSign.setStyleSelectedSignBox(6);
      PDFSign.removeAllSignBox(); console.log("remove all signbox");
    }, err => {
      console.log(err);
      if (err.error && err.error.message && ResponseMsg.MESSAGES.ESIGN_SERVICE[err.error.message] && ResponseMsg.MESSAGES.ESIGN_SERVICE[err.error.message] === 'ECT-00001203') {
        this.otp.setErrors({ notTrue: true });
      }
      this.handleError(err, ResponseMsg.MESSAGES.ESIGN_SERVICE);
    })
  }

  //Ký điện tử
  postESign(idSign, data: FormData) {
    this.contractService.postESignV2(idSign, data).subscribe((res: any) => {
      PDFSign.removeAllSignBox(); console.log("remove all signbox");
      const blob: any = new Blob([res], { type: 'application/pdf' });
      this.pdfSigned = blob;
      PDFSign.preview(blob);
      PDFSign.removeAllSignBox(); console.log("remove all signbox");
      this.savedSignature();
      this.alert(this.translate.instant('ServiceContractDetail_saveSignatureSuccess'), ConstantAlertType.SUCCESS, false, '', this.translate.instant('btn_close'), () => {
      });
    }, async (error) => {
      const message = {
        error: JSON.parse(await error.error.text())
      };
      console.log(message);
      
      this.myAlert.showErrorHandled(message);
    })
  }

  downloadFile(url) {
    this.contractService.dowloadFile(url).subscribe(res => {
      const blob: any = new Blob([res], { type: 'application/pdf' });
      this.pdfSigned = blob;
      PDFSign.preview(blob);
      this.fileName = `${this.contractId}_signed.pdf`;
      // this.getBase64String(this.filePDF, this.handle);
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.MANAGEMENT_SERVICE);
    })
  }

  //Lấy danh sách chữ ký trên hđ
  getListSignatureByContract(contractId, documentType) {
    this.contractService.getListSignatureByContract(contractId, documentType).subscribe(res => {
      console.log(res);
      this.listSignature = new ListSignatureResponse(res).object;
    }, err => {
      console.log(err);
      // this.handleError(err, ResponseMsg.MESSAGES.ESIGN_SERVICE);
    })
  }

  //Lấy thông tin user đăng nhập
  getUserInfo(id) {
    this.authService.getUserInfo(id).subscribe((res) => {
      this.userInfo = new UserInformationsResponse(res).object;
      this.userType = this.userInfo.userType;
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.AUTH_SERVICE);
    }, () => {
    })
  }

  //Cập nhật ảnh chữ ký điện tử
  postDigitalSignature(data: FormData) {
    this.signatureService.postDigitalSignature(data).subscribe(res => {
      // this.alert('Lưu thông tin thành công!', 'success');
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.ESIGN_SERVICE);
    })
  }

  //gửi Hợp đồng đã ký (VIP)
  postSendContractVip(id, data) {
    this.enterpriseVipService.postSendContract(id, data).subscribe(res => {
      this.alert(this.translate.instant('ServiceContractDetail_sendContractSuccess'), 'success', false, '', this.translate.instant('btn_close'), () => {
        this.getContract(id);
        this.router.navigate([WebviewURL.contract_detail, id]);
      });
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    })
  }

  /**
   * Submit gui ban nhap hop dong den cac ben
   * @param contractId 
   */
  postSubmitSendDraft(contractId) {
    this.contractService.postSubmitSendDraft(contractId).subscribe((res) => {
      if (this.contractNumberBox && this.contractNumberBox?.action == "SUBMIT") {
        this.checkContractNumberAction((() => {
          this.alert('Gửi hợp đồng thành công!', 'success', false, '', 'OK', () => {
            this.getContract(contractId);
            this.router.navigate([WebviewURL.contract_detail, contractId]);
          });
        }).bind(this));
      } else {
        this.alert('Gửi hợp đồng thành công!', 'success', false, '', 'OK', () => {
          this.getContract(contractId);
          this.router.navigate([WebviewURL.contract_detail, contractId]);
        });
      }
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    })
  }

  /**
   * Dong ty chot hop dong
   * @param contractId 
   */
  postAcceptContract(contractId) {
    this.contractService.postAcceptContract(contractId).subscribe((res: any) => {
      this.alert(this.translate.instant('ServiceContractDetail_acceptContractSuccess'), 'success', false, '', this.translate.instant('btn_close'), () => {
        this.getContract(contractId);
        this.router.navigate([WebviewURL.contract_detail, contractId]);
      });
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    })
  }

  /**
   * Tu choi chot hop dong
   * @param contractId 
   */
  postDenyContract(contractId, reason) {
    this.contractService.postDenyContract(contractId, "PARTNER", reason).subscribe((res: any) => {
      this.alert(this.translate.instant('ServiceContractDetail_denyContractSuccess'), 'success', false, '', this.translate.instant('btn_close'), () => {
        this.getContract(contractId);
        this.router.navigate([WebviewURL.contract_detail, contractId]);
      });
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    })
  }

  /**
   * Confirm OTP khi ky sign server
   * @param param 
   */
  postConfirmOTPSignServer(param) {
    this.contractService.postConfirmOTPSignServer(param).subscribe((res: any) => {
      let fileBase64 = res['object']['fileBase64']
      let blob = this.converBase64toBlob(fileBase64, 'application/pdf')
      this.pdfSigned = blob
      PDFSign.preview(blob)
      PDFSign.removeAllSignBox()
      this.savedSignature();
      this.alert(this.translate.instant('ServiceContractDetail_saveSignatureSuccess'), 'success', false, '', this.translate.instant('btn_close'), () => {
        // this.router.navigate(['/app/console/contract', id]);
      });
    }, err => {
      console.log(err);
      this.createModalOTPSignForm();
      if (err.error && err.error.error) {
        this.alert(err.error.error, 'error');
      }
    })
  }

  getDieuKhoan() {
    var self = this;
    this.contractService.getDieuKhoan().subscribe((res: any) => {
      res.text().then((result) => {
        const content = "<div class='e-dieukhoan'>" + result + "</div>";
        setTimeout(() => {
          self.myAlert.showAlertOnlyNoti(
            content,
            ConstantAlertType.BLANK,
            "",
            this.translate.instant('btn_close'),
            null,
            this.translate.instant('DIEU_KHOAN_MODAL_TITLE'),
            { size: 'lg' }
          );
        });
      }).catch((err) => {
      });
    }, err => {
      console.log(err);
    })
  }

  postUpdateDraftContract(id, data) {
    this.contractService.postUpdateDraftContract(id, data).subscribe((res: any) => {
      this.modalUpdateContractFile && this.modalUpdateContractFile.close();
      this.getContract(this.contractId);
      this.myAlert.showAlertOnlyNoti(this.translate.instant('UPDATE_CONTRACT_FILE_SUCCESS'), ConstantAlertType.SUCCESS);
    }, err => {
      this.myAlert.showErrorHandled(err, ResponseMsg.MESSAGES.CONTRACT_MANAGER);
    })
  }

  postGetImageSign(idSessionSign: string, data: FormData) {
    this.signatureService.postGetImageSign(idSessionSign, data).subscribe((res: any) => {
      // const blob: any = new Blob([res], { type: 'image/jpeg' });
      // this.imgESign = blob;
      // this.getBase64String(this.imgESign, this.handelimgESign)
      this.imgESignContent = 'data:image/jpg;base64,' + res?.object?.base64Image;
      PDFSign.setImage(res?.object?.base64Image);
      // this.imgESign = this.converBase64toBlob(res?.object?.base64Image, "image/jpg");
    }, err => {
      console.log(err);
      PDFSign.setImageDefault();
    })
  }

  //MARK: new e-sign OTP Form
  callActionSubmitOTP() {
    this._eSignOTP.submitOTP();
  }

  confirmOTPSuccess($event) {
    //Chuyen man hinh ky dien tu khi OTP thanh cong
    this.selectedWayOfSign = "E_SIGN";
    this.nextStepSign(this.currentStepSign);

    if (this.eCertSelected.id === '') {
      this.getListESignature();
    }

    //Setup ca thong so default cho ky dien tu
    PDFSign.setStyleSelectedSignBox(6);
    PDFSign.removeAllSignBox(); console.log("remove all signbox");
    this.showTemplateSignBox(this.contract.getSignFrameAssignee());
  }

  resign() {
    if (this.selectedWayOfSign == "USB_TOKEN" && this.currentStepSign === 2) {
      //Neu la buoc 2 cua ky = usbtoken
      //Confirm truoc khi thao tac
      this.myAlert.showAlert(localize.confirm_resign, "", true, "", localize.resign, () => {
        this.backStepSign(this.currentStepSign);
      }, "", localize.btn_close, null, localize.confirm)
    } else {
      if (!this.contract.isOwnerParty) {
        // Nếu là các bên nhận hợp đồng
        if (this.signFormSelected == "OTP" || this.signFormSelected == "OTP_EMAIL") {
          // Nếu hình thức ký là OTP và MAIL OTP
          let count = this.getSignCountAvailable(this.signFormSelected);
          this.myAlert.showAlert(`Bạn chắc chắn muốn ký lại hợp đồng này? <br> <em>Bạn còn ${count} lượt ký lại</em>`, "", true, "", localize.btn_confirm, () => {
            this.backStepSign(this.currentStepSign);
          })
        } else {
          this.backStepSign(this.currentStepSign);
        }
      } else {  
        this.backStepSign(this.currentStepSign);
      }
    }
  }

  //UPDATE V2
  progressData() {
    let signFlow = this.contract.signFlow.sort((a, b) => { return a.sequence - b.sequence });
    let lengthInternalData = this.contract.contractInternalData.length;

    //Danh dau nguoi ky hien tai
    if (lengthInternalData > 0 && this.contract.contractInternalData[lengthInternalData - 1].action == "") {
      this.contract.contractInternalData[lengthInternalData - 1].isCurrentUserSign = true;
    }

    for (let i = 0; i < lengthInternalData; i++) {
      let x = this.contract.contractInternalData[i];
      let y = signFlow[i];

      x.limitDate = y.limitDate || 0;
      x.sequence = y.sequence || 0;
      x.signType = y.signType || "";
      x.fullName = y.fullName || "";
      x.signForm = y.signForm || [];
    }
    console.log(this.contract);
  }

  get owerUseFlowTemplate() {
    if (this.contract.ownerPartyId !== this.authService.partyId) return false;
    if (this.contract.contractFlowTemplateId == null || this.contract.contractFlowTemplateId == "") return false;
    return true;
  }

  get checkAllowApproveSign() {// Dieu kien hien thi nut ky duyet phia noi bo
    let checkAllowSign = this.checkAllowSign();
    
    return checkAllowSign && this.contract.isOwnerParty;
  }

  get checkAllowDraftSign() {//Dieu kien hien thi nut ky nhay noi bo
    return this.contract?.allowDraftSign();
    let lengthContractInternalData = this.contract?.contractInternalData.length;
    let currentInternalUserSign = this.contract?.contractInternalData[lengthContractInternalData - 1]
    let stepInternalApproveSign = currentInternalUserSign?.signType == "DRAFT";
    let isCurrentUserSign = currentInternalUserSign?.userId == this.authService.userId;

    if (stepInternalApproveSign && isCurrentUserSign) {
      return true;
    }

    return false;
  }

  //UPDATE V2
  flowContractSelected: FlowContract = new FlowContract();
  showFlowSetting = false;

  /**
   * Xu ly du lieu cu
   */
  setDataV2() {
    this.partiesJoin = this.contract.contractPartysData.map((item) => {
      var party = new PartyJoinContract({
        partyId: item.partyId,
        tenToChuc: item.tenToChuc,
        userType: item.userType,
        email: item.email,
        tenDaiDien: item.tenDaiDien,
        endDate: item.endDate,
        isTrustedPartner: item.isTrustedPartner,
        signForm: item.signForm,
        verificationType: item.verificationType,
        nguoiNhanData: [
          {
            id: item.partyId,
            text: item.email,
            additional: {
              partyId: item.partyId,
              type_customer: item.userType === 'CONSUMER' ? 'Cá nhân' : 'Doanh nghiệp',
              type_cutomer_label: item.userType,
              email: item.email,
              isTrustedPartner: item.isTrustedPartner
            }
          }
        ]
      });

      if (item.partyId === this.authService.partyId) {
        party.allowDetete = false;
        party.allowEditUserType = false;
        party.allowEditPartyId = false;
        party.isOwner = true;
      }

      return party;
    })

    this.getOldFlowContract(this.contract.contractFlowTemplateId);
    this.contract.sortInternalDiscussData();
  }

  /**
   * Lay thong tin luong hop dong
   * @param id 
   */
  getOldFlowContract(id) {
    if (this.isCreator && this.contract.contractFlowTemplateId) {
      this.flowContractService.getDetailFlowContract(id).subscribe((res: any) => {
        this.flowContractSelected = new FlowContract(res?.object || {});
  
        this.flowContractSelected.internalDiscuss = this.contract.internalDiscuss.concat([]);
        this.flowContractSelected.partnerDiscuss = this.contract.partnerDiscuss.concat([]);
        this.flowContractSelected.signFlow = this.contract.signFlow.concat([]);
    
        this.flowContractSelected.signFlow = this.flowContractSelected.signFlow.sort((a, b) => { return a.sequence - b.sequence; })
        this.flowContractSelected.signFlowType = this.contract.signFlowType;
      }, err => {
        this.myAlert.showErrorHandled(err);
      })
    } else {
      this.flowContractSelected = new FlowContract();
      this.flowContractSelected.contractFlowTemplateId = id;
      this.flowContractSelected.name = this.contract.contractFlowName;

      this.flowContractSelected.internalDiscuss = this.contract.internalDiscuss.concat([]);
      this.flowContractSelected.partnerDiscuss = this.contract.partnerDiscuss.concat([]);
      this.flowContractSelected.signFlow = this.contract.signFlow.concat([]);

      this.flowContractSelected.signFlowType = this.contract.signFlowType;
      this.flowContractSelected.signFlow = this.flowContractSelected.signFlow.sort((a, b) => { return a.sequence - b.sequence; })
    }
  }

  /**
   * Bat su kien dong popup setting flow
   * @param event 
   */
  flowSettingsClosed(event) {
    setTimeout(() => {
      this.showFlowSetting = false;
    }, 200);

    if (event !== null) {
      this.flowContractSelected = event;
    }
  }

  /**
   * Mo man hinh popup setting flow
   */
  settingFlow() {
    setTimeout(() => {
      this.showFlowSetting = true;
    }, 200)
  }

  /**
   * Quyen edit luong ky noi bo
   */
  get signFlowEditable() {
    return this.isCreator;
  }

  /**
   * la nguoi tao hop dong hoac admin
   */
  get isCreator() {
    let isAm = this.authService.userId == this.contract.amId;
    return isAm;
  }

  get isAdmin() {
    let isAdmin = this.authService.isAdmin;
    return isAdmin;
  }

  get allowConfigFlow() {
    let allowStatusContract = this.contract.currentStage == "LC_DRAFT_CREATE";
    if (!allowStatusContract) return false;

    if (this.contract.contractFlowTemplateId == "" || this.contract.contractFlowTemplateId == null) return false;

    if (!this.isCreator) return false;

    return true;
  }

  //UPDATE V2 PARTNER CONFIG
  showPartnerDiscussSetting = false;
  partnerDiscussSettingsClosed(event) {
    console.log(event);
    setTimeout(() => {
      this.showPartnerDiscussSetting = false;
    }, 200);

    if (event !== null) {
      this.flowContractSelected = event;
      this.getContract(this.contractId);
    }
  }

  openPartnerDiscussSetting() {
    setTimeout(() => {
      this.showPartnerDiscussSetting = true;
    }, 200)
  }

 get allowPartnerDiscussConfig() {
  if (this.userType === UserTypeConstant.CONSUMER) return false;

  let allowStatusContract = this.contract.currentStage == "LC_CONTRACT_VALID";
  if (allowStatusContract) return false;

  let endPartnerDiscuss = true;
  for(let i = 0; i < this.contract.partnerDiscuss.length; i++) {
    if (this.contract.partnerDiscuss[i].deal == null) {
      endPartnerDiscuss = false;
    }
  }
  if (this.contract.partnerDiscuss.length <= 0) endPartnerDiscuss = false; 
  
  if (endPartnerDiscuss) return false;

  let isCreator = this.isCreator;
  let isEditable = this.contract.userPermissions[USER_PERMISSION.PARTNER_EDITABLE];

   return isCreator || isEditable;
 }

 modalPartnerDiscuss = null;
 openModalDenyPartnerDiscuss(content) {
   this.formDenyDiscuss.reset();
   this.modalPartnerDiscuss = this.modalService.open(content, {
     size: 'md',
     backdrop: 'static',
     keyboard: false
   });
 }

  //UPDATE V2 DISCUSS INTERNAL
  /**
   * Dieu kien hien thi Tab Tham dinh
   */
  get allowShowInternalDiscussTab() {
    if (this.userType === UserTypeConstant.CONSUMER) return false;

    return true && this.contract.allowShowInternalDiscuss;
  }

  /**
   * Dieu kien hien thi nut them nhan su vao tham dinh
   */
  get allowInternalDiscussConfig() {
    if (this.userType === UserTypeConstant.CONSUMER) return false;

    return this.contract.allowInternalDiscussConfig;

    let allowStatusContract = this.contract.currentStage == "LC_CONTRACT_VALID";
    if (allowStatusContract) return false;

    let endInternalDiscuss = true;
    for(let i = 0; i < this.contract.internalDiscuss.length; i++) {
      if (this.contract.internalDiscuss[i].deal == null) {
        endInternalDiscuss = false;
      }
    }
    if (this.contract.internalDiscuss.length <= 0) endInternalDiscuss = false; 
    if (endInternalDiscuss) return false;

    let isCreator = this.isCreator;
    let isEditable = this.contract.userPermissions[USER_PERMISSION.INTERNAL_EDITABLE];
 
    return isCreator || isEditable;
  }

  showInternalDiscussSetting = false;
  intenralDiscussSettingsClosed(event) {
    console.log(event);
    setTimeout(() => {
      this.showInternalDiscussSetting = false;
    }, 200);

    if (event !== null) {
      this.flowContractSelected = event;
      this.getContract(this.contractId);
    }
  }

  openInternalDiscussSetting() {
    setTimeout(() => {
      this.showInternalDiscussSetting = true;
    }, 200)
  }

  /**
   * Dieu kien hien thi nut dong y/tu choi tham dinh
   */
  get allowConfirmInternalDiscuss() {
    return this.contract.allowConfirmInternalDiscuss;
  }

  /**
   * Dong y hop dong
   */
   confirmAcceptInternalDiscus() {
    this.myAlert.showAlert(this.translate.instant('contract_confirm_accept_internal_discuss'), ConstantAlertType.BLANK, true, '', this.translate.instant('btn_confirm'), () => {
      this.postAcceptInternalDicuss(this.contractId, '');
    }, '', this.translate.instant('btn_cancel'), null, this.translate.instant('confirm'));
  }

  /**
   * Tu choi hop dong
   */
  confirmDenyInternalDiscus() {
    if (this.formDenyDiscuss.valid) {
      this.myAlert.showAlert("contract_confirm_deny_internal_discuss", ConstantAlertType.BLANK, true, '', 'btn_confirm', () => {
        this.modalInternalDiscuss && this.modalInternalDiscuss.close();
        let footer = this.checkbox_addfooter == true ? 'Y' : 'N';
        this.postDenyInternalDicuss(this.contractId, this.formDenyDiscuss.value.reason || "", footer);
      }, '', 'btn_cancel', null, this.translate.instant('confirm'));
    }
  }

  /**
   * Dong y tham dinh hop dong
   */
  confirmDealInternalDiscuss() {
    this.modalInternalDiscuss && this.modalInternalDiscuss.close();
    let footer = this.checkbox_addfooter == true ? 'Y' : 'N';
    this.postAcceptInternalDicuss(this.contractId, footer);
  }

  /**
   * Dong ty chot hop dong
   * @param contractId 
   */
   postAcceptInternalDicuss(contractId, footer) {
    this.contractService.postAcceptContract(contractId, "INTERNAL", footer).subscribe((res: any) => {
      this.alert('ServiceContractDetail_acceptContractSuccess', 'success', false, '', 'btn_close', () => {
        this.getContract(contractId);
        this.router.navigate([WebviewURL.contract_detail, contractId]);
      });
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }

  /**
   * Tu choi chot hop dong
   * @param contractId 
   */
  postDenyInternalDicuss(contractId, reason, footer) {
    this.contractService.postDenyContract(contractId, "INTERNAL", reason, footer).subscribe((res: any) => {
      this.alert('ServiceContractDetail_denyContractSuccess', 'success', false, '', 'btn_close', () => {
        this.getContract(contractId);
        this.router.navigate([WebviewURL.contract_detail, contractId]);
      });
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }

  /**
   * Lay link anh icon status
   * @param status 
   * @returns 
   */
  imgStatus(status = null) {
    let msg = "watch_later";
    switch (status) {
      case null: 
        msg =  "watch_later";
        break;
      case true:
        msg = "check_circle";
        break;
      case false:
        msg = "cancel";
        break;
    }

    return msg;
  }

  /**
   * Lay class block trang thai phe duyet
   * @param status 
   * @returns 
   */
   getClassBorder(status = null, userId = "", sequence = null) {
    let msg = "";
    switch (status) {
      case null: 
        msg = "border-secondary";
        break;
      case true:
        msg = "border-success";
        break;
      case false:
        msg = "border-danger";
        break;
    }

    if (this.contract.internalDiscussType == InternalDiscussTypes.REQUIRE_FLOW 
        && this.contract.currentUserInternalDiscuss?.userId == userId
        && this.contract.currentUserInternalDiscuss?.sequence == sequence
        && status == null) {
        msg = "border-primary"
    }

    return msg;
  }

  makeFormDenyDiscuss() {//Khoi tao form tu choi dam phan, tham dinh
    this.formDenyDiscuss = this.fb.group({
      reason: ['', [Validators.maxLength(4000)]]
    })
  }

  modalInternalDiscuss = null;
  openModalDenyInternalDiscuss(content) {//Mo popup tu choi tham dinh
    this.formDenyDiscuss.reset();
    this.checkbox_addfooter = true;
    this.modalInternalDiscuss = this.modalService.open(content, {
      size: 'md',
      backdrop: 'static',
      keyboard: false
    });
  }

  //Mo popup dong y ham dinh
  openModalDealInternalDiscuss(content) {
    this.checkbox_addfooter = true;
    this.modalInternalDiscuss = this.modalService.open(content, {
      size: 'md',
      backdrop: 'static',
      keyboard: false
    });
  }

  get endInternalDiscuss() {
    if (this.userType === UserTypeConstant.CONSUMER) return true;
    for (let i = 0; i < this.contract.internalDiscuss.length; i++) {
      if (this.contract.internalDiscuss[i].deal == null) {
        return false;
      }

      if (this.contract.internalDiscuss[i].deal == false) {
        return true;
      }
    }
    // if (this.contract.internalDiscuss.length <= 0) return true;
    
    return true;
  }

  get isAcceptInternalDiscuss() {
    if (this.userType === UserTypeConstant.CONSUMER) return true;
    for (let i = 0; i < this.contract.internalDiscuss.length; i++) {
      if (this.contract.internalDiscuss[i].per_action[USER_PERMISSION.INTERNAL_ACCEPTABLE] === true) {
        if (this.contract.internalDiscuss[i].deal == null || this.contract.internalDiscuss[i].deal == false) {
          return false;
        }
  
        if (this.contract.internalDiscuss[i].deal == false) {
          return true;
        }
      }
    }
    return true;
  }

  //MARK: GIAI MA TAI VE
  contractEncode = new ContractEncode();
  turnOnEncode(contractId) {
    var msg = "ServiceContractDetail_decodeContractWarning";
    this.myAlert.showAlert(msg, ConstantAlertType.BLANK, true, "", "ServiceContractDetail_btn_decode", () => {
      this.contractEncode.addEncodeContract(contractId);
    }, "", "btn_cancel", null, "ServiceContractDetail_confirm_decode");
  }

  hasDecode(contractId) {
    return this.contractEncode.hasDecode(contractId);
  } 

  getFileContract(id, status) {
    if (status === 'LC_CONTRACT_VALID') {
      if (this.hasDecode(id)) {
        this.getContractFileInfoDownload(this.contractId, 'CONTRACT', this.contractId);
      } else {
        var msg = `ServiceContractDetail_downloadDecodeWarning`
        this.myAlert.showAlert(msg, ConstantAlertType.BLANK, true, "", "ServiceContractDetail_downloadContract", () => {
          this.downloadEnCodeFile(id);
        }, "", "btn_cancel", null, "ServiceContractDetail_confirmDownloadContract");
      }
    } else {
      this.getContractFileInfoDownload(this.contractId, 'DRAFT', this.contractId);
    }
  }

  downloadEnCodeFile(contractId) {
    this.contractService.downloadEncodeContract(contractId).subscribe((res) => {
      const blob: any = new Blob([res], { type: 'application/pdf' });
      var url = window.URL.createObjectURL(blob);
      var anchor = document.createElement("a");
      anchor.download = `${contractId}.pdf`;
      anchor.href = url;
      anchor.click();
    }, err => {
      console.log(err);
      this.handleError(err, ResponseMsg.MESSAGES.ESIGN_SERVICE);
    })
  }

  /**
   * Kiem tra party da ky duyet chua
   */
  get thisPartyApproved() {
    return this.contract.thisPartyFinishedSign;
    let currentParty = this.contract.contractPartysData.find((item) => { return item.partyId === this.authService.partyId});
    if (currentParty) {
      if (currentParty.signerId !== "" && currentParty.signDate !== "") return true;
    } 
    return false;
  }

  /**
   * Dieu kien hien thi nut "Chuyen", "Ket thuc" ky nhay noi bo
   */
  get allowSendInternal() {
    if (this.thisPartyApproved) return false;
    if (!(this.currentStepSignInternal === 0)) return false;
    return this.checkAllowActionAssignInternal && !this.owerUseFlowTemplate;
  }

  /**
   * Dieu kien hien thi action Ky noi bo
   */
  get allowActionSignInternal() {
    if (this.thisPartyApproved) return false;
    if (!(this.currentStepSignInternal === 0)) return false;
    return this.checkAllowStartApproveInternal;
  }

  /**
   * La to chuc tao hop dong
   */
  get isOwnerParty() {
    return this.contract.ownerPartyId === this.authService.partyId;
  }

  /**
   * Dieu kien hien thi nut Ket thuc kiem duyet
   * @returns 
   */
  get checkAllowFinishCensorship_v2() {
    if (this.currentStepSignInternal != 0) return false;

    return this.contract.thisUserAllowedFinishSignInternal;
  }

  /**
   * UPDATE V2: Xac nhan ky duyet khi ky nhay chua hoan thanh
   * @param type 
   */
  signApproval(type) {
    if (this.contract.isFinishedInternalSign || this.contract.signFlowType == SignFlowTypes.NO_REQUIRE) {
      this.selectWayOfSign(type, type);
    } else {
      this.myAlert.showAlert("ServiceContractDetail_warningNotEnoughDraftSign",
        "", true, "", localize.sign_approve, () => {
          this.selectWayOfSign(type, type);
        },
        "", localize.btn_cancel, () => {}, localize.confirm
      );
    }
  }

  //MARK: SMART CA
  showLayoutConfirmSmartCA = false;
  timeoutSmartCa = false;

  //Bat su kien da confirmSmartCA
  isWaitingConfirmSmartCA(e) {
    console.log("isWaitingconfirm: ", e);
    if (e !== null && e !== "" && e !== undefined) {
      this.showLayoutConfirmSmartCA = e;
    }
  }

  //Bat su kien het hang phien ky smartCA
  expiredSessionSmartCA(e) {
    console.log("expiredSessionSmartCA: ", e);
    if (e !== null && e !== "" && e !== undefined) {
      this.timeoutSmartCa = e;
    }
  }

  //Bat su kien da luu thanh cong chu ky = hinh thuc smartCA
  savedSignatureFromSmartCA(e) {
    if (e !== null && e !== "" && e !== undefined) {
      this.pdfSigned = e;
      PDFSign.preview(this.pdfSigned);
      PDFSign.removeAllSignBox(); console.log("remove all signbox");
      this.nextStepSign(this.currentStepSign);
    }
  }

  //Gui yeu cau ky smartca
  signBySmartCA() {
    this.smartCASign && this.smartCASign.signBySmartCA();
    return;
  }

  //UPDATE Ky noi bo
  signTypeInternal = "";

  kyDienTu(signForm) {
    this.signTypeInternal = "E_SIGN";
    this.contractCensorshipComponent && this.contractCensorshipComponent.kyDienTu(signForm);
  }

  confirmOTPInternalSign() {
    this.contractCensorshipComponent && this.contractCensorshipComponent.confirmOTPInternalSign();
  }

  haveSessionEsignEvent(e) {
    this.haveSessionEsignInternal = e;
  }

  changedSignTypeEvent(e) {
    this.signTypeInternal = e;
  }

  saveESignInternal() {
    this.contractCensorshipComponent && this.contractCensorshipComponent.sendRequestElectronicSign();
  }

  allowActionSignFormInternal(signForm) {
    return this.contract.allowActionSignForm(signForm);
  }

  //MARK Ky usbtoken cho Macos
  listSignatureUsb = [];
  signatureUsb = { 
    x: 0,
    y: 0,
    w: 0,
    h: 0,
    page: 1,
    addedSignBox: false 
  };
  currentPage = 1;
  certSerialSelected = "";
  hinhThucHienThi_usbtoken = [
    { value: 0, name: this.translate.instant("ServiceContractDetail_usbtoken_signType_onlyDescrip") },
    { value: 1, name: this.translate.instant("ServiceContractDetail_usbtoken_signType_imageDescrip") },
    { value: 2, name: this.translate.instant("ServiceContractDetail_usbtoken_signType_onlyImage") }
  ];

  usbtokenAddSignBox() {
    PDFSign.addSignBox();
    let options: Array<any> = PDFSign.getOptions();
    console.log(options);
    this.listSignatureUsb = options;
    if (this.listSignatureUsb.length > 0) {
      this.signatureUsb.x = this.listSignatureUsb[0].x || 0;
      this.signatureUsb.y = this.listSignatureUsb[0].y || 0;
      this.signatureUsb.w = this.listSignatureUsb[0].w || 0;
      this.signatureUsb.h = this.listSignatureUsb[0].h || 0;
      this.signatureUsb.page = this.listSignatureUsb[0].page || 0;
      this.signatureUsb.addedSignBox = true;
    }
  }

  getCurrentPage() {
    this.currentPage = PDFSign.getCurrentPage();
    return this.currentPage;
  }

  removeSignBoxUsbtoken() {
    PDFSign.removeAllSignBox(); console.log("remove all signbox");
    this.signatureUsb.addedSignBox = false;
  }

  pushSigUsbToken() {
    this.removeSignBoxUsbtoken();
    if (this.currentStepSign - 1 >= 0) this.setStepSign(this.currentStepSign - 1);
  }

  signUsbToken() {
    this.showLoader();
    this.output.nativeElement.value = "";
    var dataInput = this.filebase.nativeElement.value;
    if (dataInput.length === 0) {
      this.hideLoader();
      this.alert('ServiceContractDetail_NotFoundContractFile', 'error');
      return;
    }
    var fileName = this.filename.nativeElement.value;
    console.log(fileName);
    var fileSplit = fileName.split(".");
    var fileExtension = fileSplit[fileSplit.length - 1].toLowerCase();
    if (fileExtension !== "xml" && fileExtension !== "pdf" && fileExtension !== "docx"
      && fileExtension !== "xlsx" && fileExtension !== "pptx" && fileExtension !== "txt") {
      this.hideLoader();
      this.alert('ServiceContractDetail_invalidTypeFileSign', 'error');
      return;
    }
    var sigOptions = null;
    if (fileExtension === "pdf") {
      let options: Array<any> = PDFSign.getOptions();
      this.listSignatureUsb = options;
      if (this.listSignatureUsb.length > 0) {
        this.signatureUsb.x = this.listSignatureUsb[0].x || 0;
        this.signatureUsb.y = this.listSignatureUsb[0].y || 0;
        this.signatureUsb.w = this.listSignatureUsb[0].w || 0;
        this.signatureUsb.h = this.listSignatureUsb[0].h || 0;
        this.signatureUsb.page = this.listSignatureUsb[0].page || 0;
        this.signatureUsb.addedSignBox = true;
      } else {
        this.myAlert.showAlertOnlyNoti("CONTRACT_DONT_HAVE_SIGN_BOX", ConstantAlertType.ERROR);
        this.hideLoader();
        return;
      }

      sigOptions = new PdfSigner();
      sigOptions.AdvancedCustom = false;
      sigOptions.ValidationOption = false;
      sigOptions.SigTextSize = 13;
      sigOptions.ImageBase64 = this.base64Image.value;
      sigOptions.SigType = Number(this.visibleType.value) || 0;
      sigOptions.SigSignerVisible = this.signBy.value || false;
      sigOptions.SigSigningTimeVisible = this.signDate.value || false;
      sigOptions.page = this.signatureUsb.page;
      sigOptions.llx = this.signatureUsb.x;
      sigOptions.lly = this.signatureUsb.y - this.signatureUsb.h;
      sigOptions.urx = this.signatureUsb.x + this.signatureUsb.w;
      sigOptions.ury = this.signatureUsb.y;
      sigOptions.SigEmailVisible = false;
      // sigOptions.ValidationOption = true;

      console.log(sigOptions);
    }
    this.SignAdvanced_V2(dataInput, fileExtension, sigOptions, this.showMessage);
  }

  //Bắt đầu kí usbtoken
  SignAdvanced_V2(data, type, option, callback) { console.log("signAdvanced_v2 - option: ", option)
    var dataJS = {};
    var arrData = [];
    // 1			
    dataJS["data"] = data;
    dataJS["type"] = type;
    dataJS["sigOptions"] = JSON.stringify(option);

    var jsData = "";
    jsData += JSON.stringify(dataJS);
    //
    arrData.push(jsData);

    var vm = this;
    vnpt_plugin.signArrDataAdvanced(arrData, this.certSerialSelected, true).then((signData) => {
      vm.showMessage(signData);
    }).catch(function (e) {
      alert(e);
      vm.hideLoader();
    });
  }

  getCertInforUsbtoken() {//Lay thong tin chung thu so
    let vm = this;
    this.loaderSerive.showElement();
    vnpt_plugin.getCertInfo(null, true).then((data) => {
      if (data) {
        var certData = JSON.parse(data)
        console.log(certData);
        var serial = certData["serial"];
        vm.certSerialSelected = serial;
        vm.selectedWayOfSign = "USB_TOKEN";
        vm.nextStepSign(vm.currentStepSign);

        //Set cac gia trị mac dinh, khi chua co config
        this.imgContent = this.img_prefix + PDFSign.getData().imageSrc;
        this.formSign.patchValue({
          visibleType: 1,
          base64Image: default_img,
          fontSize: 8,
          signBy: true,
          signDate: true
        });
        // PDFSign.setStyleSelectedSignBox(Number(this.formSign.controls.visibleType.value) + 1);

        //Lay thong tin cau hinh da cai dat cho usbtoken
        var usbConfig = vm.listUsbToken.find((item, index) => {
          return (item.serial === serial);
        });
        console.log(usbConfig);

        if (usbConfig) {
          //Neu ton tai cai hinh thi set cau hinh hien thi
          this.formSign.patchValue({
            visibleType: usbConfig.signatureDefault.visibleType || 0,
            base64Image: usbConfig.signatureDefault.base64Image || default_img,
            fontSize: usbConfig.signatureDefault.fontSize || 8,
            signBy: usbConfig.signatureDefault.signBy || false,
            signDate: usbConfig.signatureDefault.signDate || false
          });
          usbConfig.signatureDefault.base64Image && (this.imgContent = this.img_prefix + usbConfig.signatureDefault.base64Image);
        }
        
        PDFSign.setImage(this.imgContent.split(',')[1]);
        PDFSign.setFontSize(this.formSign.value.fontSize);
        PDFSign.setShowSignBy(this.formSign.value.signBy);
        PDFSign.setShowDateSign(this.formSign.value.signDate);
        PDFSign.setStyleSelectedSignBox(Number(this.formSign.controls.visibleType.value) + 1);
      } else {
        vm.alert("ServiceContractDetail_youCanceledSignSession", 'error');
        vm.backStepSign(vm.currentStepSign);
      }
      this.loaderSerive.hideElement();
    })
  }

  checkSignType(signType) {
    return this.contractCensorshipComponent && this.contractCensorshipComponent.signType == signType;
  }

  pushSigUsbToken_internal() {
    this.contractCensorshipComponent && this.contractCensorshipComponent.pushSigUsbToken();
  }

  cancelSign(currentStepSign) {
    if (this.selectedWayOfSign == "USB_TOKEN") {
      this.selectedWayOfSign = "";
      this.currentStepSign = 0;
      PDFSign.preview(this.filePDF);
      this.getBase64String(this.filePDF, this.handle);
    } else {
      this.backStepSign(currentStepSign);
    }
  }

  //usb token config
  listUsbToken: UsbTokenItem[] = [];
  listUsbTokenBase: UsbTokenItem[] = [];
  usbTokenSelected: UsbTokenItem = new UsbTokenItem();

  getListUsbToken(id) {
    this.signatureService.getUsbTokenOfUser(id).subscribe(res => {
      this.listUsbToken = new ListUsbTokenResponse(res).data;
    }, err => {
      console.log(err);
    });
  }

  //MARK: SMART CA NOI BO
  signFormInternal = "";
  isWaitingConfirmSmartCAInternal = false;
  isExpiredSmartCAInternal = false;

  kySmartCA() {
    this.contractCensorshipComponent && this.contractCensorshipComponent.kySmartCA();
  }

  changedSignForm(e) {
    if (e != "" && e != null && e != undefined) {
      this.signFormInternal = e;
    }
  }

  handleEventExpiredSessionSmartCAInternal(e) {
    console.log("handleEventExpiredSessionSmartCAInternal: ", e);
    if (e !== null && e !== "" && e !== undefined) {
      this.isExpiredSmartCAInternal = e;
    }
  }

  handleEventIsWaitingConfirmSmartCAInternal(e) {
    console.log("handleEventIsWaitingConfirmSmartCAInternal: ", e);
    if (e !== null && e !== "" && e !== undefined) {
      this.isWaitingConfirmSmartCAInternal = e;
    }
  }

  /**
 * Hien thi goi ky signbox
 * @param signFrame 
 */
  showTemplateSignBox(signFrame = []) {
    console.log("kyDienTu ", signFrame);
    if (signFrame.length > 0) {
      signFrame.forEach((item: SignBox) => {
        PDFSign.addSignBox(item, this.contract.allowAddSignBox);
      })
      setTimeout(() => {
        PDFSign.reloadTable(null, this.contract.allowAddSignBox);
      }, 100)
    }
  }

  // ===== DOCUMENT ACTION =====
  // Mode to decide which screen button will be show above tab.
  modeDocument: string = "list";

  // Reset tab document to list mode, and reload contract data.
  resetTabDocument() {
    this.modeDocument = "list";
    this.document.resetTab();
    this.reloadContractData();
  }

  // Action when click on button "Thêm tài liệu",
  // change mode to add and execute addNewDocument in document-in-contract component.
  addNewDocument() {
    this.modeDocument = "add";
    this.document.addNewDocument();
  }

  // Change mode to edit, change button above the tab.
  // Only be call from list-document component
  changeToEdit() {
    this.modeDocument = "edit";
  }

  downloadDocument() {

  }

  // Calling save/cancel in add-document component.
  cancelAddingDocument() {
    this.document.cancelAdd();
  }
  saveDocument() {
    this.document.saveDocument();
  }

  // Calling save/cancel in edit-document component.
  cancelEditingDocument() {
    this.document.cancelEditing();
  }
  editDocument() {
    this.document.saveEditingDocument();
  }

  // Reload contract data, will be call after add or edit a document.
  reloadContractData() {
    this.getContract(this.contractId);
  }

  // View detail document
  viewDetailDocument(documentId) {
    if (documentId === "") {
      this.getContract(this.contractId);
    }
    else {
      this.documentInContractService.downloadDocument(this.contractId, documentId).subscribe(res => {
        const blob: any = new Blob([res], { type: 'application/pdf' });
        this.pdfSigned = blob;
        PDFSign.preview(blob);
      }, err => {
        
      })
    }
  }


  // MARK: Ràng buộc ký điện tử

  /**
   * Bắt sự hiện hết lượt xác nhận OTP
   * @param e 
   */
  handleLimitFailedOTP(e) {
    if (e) {
      this.router.navigate([WebviewURL.contract_detail, this.contractId])
      .then(() => {
        window.location.reload();
      });
    }
  }

  //MARK COMMENT POPUP
  @ViewChild('commentPopup') commentPopup: CommentPopupComponent;
  idEl = "contract-comment-popup";
  showCommentPopup = false;
  listContractVersion: Array<VersionContract> = [];
  versionSelected: VersionContract = null;
  postIdSelected = "";

  /**
   * Nhận lại danh sách phiên bản từ VersionContractComponent
   * @param e 
   */
  getListVersion(e) {
    if (e) {
      // console.log(e);
      this.listContractVersion = e;
    }
  }

  /**
   * Bắt sự kiện giảm số lượt request OTP;
   * @param e 
   */
  handleMinusResendOTP(e) {
    if (e == true) {
      this.limitResendOTP = this.limitResendOTP - 1;
    }
  }
  /**
   * Nhận thông tin phiên bản đang được chọn từ VersionContractComponent
   * @param e 
   */
  getVersionSelected(e) {
    if (e) {
      console.log(e);
      this.commentPdf.removeEventClickOnPDF();
      this.commentPdf.removeAllNotePoint();
      // this.commentPopup.hidePopup();

      this.versionSelected = e;
      this.versionSelected.posts.forEach((x) => {
        let data = {
          contractId: this.contractId,
          traceId: this.versionSelected,
          notePoint: x
        }
        this.commentPdf.addNotePoint(null, x.page, data, this.callBackClickNotePoint.bind(this));
      })

      this.callBackAfterRender(listPage);
    }
  }

  callBackAfterRender(listPage = []) {
    this.commentPdf.listPage = listPage;
    this.commentPdf.setEventClickOnPDF(this.callAddComment.bind(this));
  }
  
  /**
   * Thêm bình luận trên pdf
   * @param e 
   */
  callAddComment(e) {
    console.log("Add comment ", e);
    if (!this.contract.allowComment) return;

    this.showCommentPopup = true;
    
    this.commentPopup.showPopup({...e, version: this.versionSelected});
    let data = {
      contractId: this.contractId,
      traceId: this.versionSelected
    }
    this.commentPdf.addNotePoint(e.event, e.pageIndex, data);
  }

  /**
   * Bắt sự kiện click vào note point trên màn hình
   * @param e 
   */
  callBackClickNotePoint(e) {
    console.log(e);
    if (e) {
      let notePoint = this.versionInContract.getPostInfor(this.versionSelected.traceId, e);
      if (notePoint) {
        /**
         * Nếu tìm thấy notepoint trong list
         * Thì sẽ tự động chọn vào notepoint đó (post)
         */
        let data = {
          version: this.versionSelected,
          notePoint: notePoint,
          event: null,
          pageIndex: notePoint.page
        }

        this.selectedPost(data);
        this.versionInContract.notePointSelected = notePoint;
      }
    }
  }

  /**
   * Chọn một bình luận để hiện thị lên màn hình PDF view
   * @param e 
   */
  selectedPost(e) {
    this.postIdSelected = e.notePoint.id;
    this.commentPopup.showPopup({...e, version: this.versionSelected});
  }

  /**
   * Nhận sự kiện gọi ẩn popup
   * @param e 
   */
  calledHidePopop(e) {
    if (e) {
      console.log("calledHidePopup")
      this.commentPopup.hidePopup();
    }
  }

  /**
   * bắt sự kiện tắt popup
   * @param e 
   */
  calledExit(e) {
    this.commentPdf.exitPopupComment();
  }

  /**
   * Nhận sự kiện click nextNotePoint
   * @param e 
   */
  nextNotePoint(e) {
    if (e) {
      this.versionInContract.nextNotePoint();
    }
  } 

  /**
   * Lấy số lượt ký còn lại
   * @param contractId 
   */
  getSignCount(contractId) {
    this.contractService.getSignCount(contractId).subscribe((res: any) => {
      console.log(res);
      this.signCount = res;
    })
  }

  /**
   * Lấy số lượt ký còn lại của 1 hình thức ký
   * @param signForm 
   * @returns 
   */
  getSignCountAvailable(signForm) {
    if (this.signCount && this.signCount instanceof Array) {
      let signF = this.signCount.find((x) => {  return signForm == x.signForm; });
      if (signF) {
        if (signF.quantity > 0 && signF.number > 0 && signF.quantity > signF.number) {
          return signF.quantity - signF.number;
        } else {
          return 0;
        }
      } else {
        return 0
      }
    } else {
      return 0;
    }
  }

  /**
   * Trừ lượt ký
   * @param signForm 
   * @returns 
   */
  minusSignCount(signForm) {
    if (this.contract.isOwnerParty) { return; }

    if (this.signCount && this.signCount instanceof Array) {
      let signF = this.signCount.find((x) => {  return signForm == x.signForm; });
      if (signF) {
        if (signF.quantity > 0 && signF.number > 0 && signF.quantity > signF.number) {
          signF.number = signF.number + 1;
        }
      }
    }
  }

  /**
   * Kiểm tra số lượt ký còn lại
   * @param signForm 
   * @returns 
   */
  checkSignCount(signForm) {
    if (this.contract.isOwnerParty) { return true; }

    if (this.signCount && this.signCount instanceof Array) {
      let signF = this.signCount.find((x) => {  return signForm == x.signForm; });
      if (signF) {
        if (signF.quantity > 0 && signF.number > 0 && signF.quantity > signF.number) {
          return true;
        } else {
          return false;
        }
      } else {
        this.myAlert.showAlertOnlyNoti("Không tìm thấy thông tin số lượt ký còn lại của hình thức ký!", ConstantAlertType.ERROR);
        return null;
      }
    } else {
      this.myAlert.showAlertOnlyNoti("Không tìm thấy thông tin số lượt ký còn lại của hình thức ký!", ConstantAlertType.ERROR);
      return null;
    }
  }

  /**
   * Nhận sự kiện click prevNotePoint
   * @param e 
   */
  prevNotePoint(e) {
    if (e) {
      this.versionInContract.prevNotePoint();
    }
  }

  /**
   * Khi đã submit bình luận thành công
   * @param e 
   */
  submitCommentSuccess(e) {
    if (e) {
      this.commentPdf.removeEventClickOnPDF();
      this.commentPdf.removeAllNotePoint();
      this.commentPopup.hidePopup();
      this.versionInContract.getVersionByContractId(false);
      this.reloadDataPost(this.contractId, this.versionSelected.traceId, e?.object?.id);
    }
  }

  /**
   * Sau khi submit trả lời bình luận bất kì
   * @param e dữ liệu sự kiện trả về
   */
  submitReplySuccess(e) {
    if (e) {
      this.commentPdf.removeEventClickOnPDF();
      this.commentPdf.removeAllNotePoint();
      this.commentPopup.hidePopup();
      this.versionInContract.getVersionByContractId(false);
      this.reloadDataPost(this.contractId, this.versionSelected.traceId, this.postIdSelected);
    }
  }

  /**
   * Nhận sự kiện xoá bình luận
   * @param e 
   */
  callBackDeletedComment(e) {
    if (e) {
      this.commentPdf.removeEventClickOnPDF();
      this.commentPdf.removeAllNotePoint();
      this.commentPopup.hidePopup();
      this.versionInContract.getVersionByContractId();
      this.reloadDataPost(this.contractId, this.versionSelected.traceId, e?.object?.id);
    }
  }

  /**
   * Nhận sự kiện xoá trả lời bình luận
   * @param e 
   */
   callBackDeletedReply(e) {
    if (e) {
      this.commentPdf.removeEventClickOnPDF();
      this.commentPdf.removeAllNotePoint();
      this.commentPopup.hidePopup();
      this.versionInContract.getVersionByContractId();
      this.reloadDataPost(this.contractId, this.versionSelected.traceId, this.postIdSelected);
    }
  }

  /**
   * Reload dữ liệu post để mở lại
   * @param contractId 
   * @param versionId 
   * @param postId 
   */
  reloadDataPost(contractId, versionId, postId) {
    this.commentApi.getDetailPost(contractId, versionId, postId).subscribe((res: any) => {

      let notePoint = new NotePoint({...res?.object, comment: res?.object});
      notePoint.saved = true;
      let e = {
        version: this.versionSelected,
        notePoint: notePoint,
        event: null,
        pageIndex: notePoint.page
      }
      this.selectedPost(e);

    }, err => {{
      this.myAlert.showErrorHandled(err);
    }})
  }

  /**
   * Lấy thông tin chi tiết cấu hình số hợp đồng
   * @param contractNumberId 
   */
   getDetailContractNumberConfig(contractNumberId) {
    this.cncService.getDetails(this.contract.sequenceId).subscribe((res: any) => {
      this.contractNumberBox = new ContractNumberBox(res?.object);

      let bboxContractNumber = null;
      if (this.contract.contractNumberLocation) {
        bboxContractNumber = new SignBox(this.contract.contractNumberLocation);
      } else {
        bboxContractNumber = new SignBox({
          page: 1,
          w: 200,
          x: 10,
          y: 831,
          h: 24
        });
      }
      this.contractNumberBox.box = bboxContractNumber;

      this.genContractNumber();
    }, err => {
      console.log(err);
    })
  }

  /**
   * Gen contractNumber
   */
  genContractNumber(callback = null) {
    if (this.allowGenContractNumber) {
      this.getNextContractNumber(callback);
    }
  }

  /**
   * Kiểm tra điều kiện được phép gen contractNumber
   */
  get allowGenContractNumber() {
    if (this.contract.currentStage == "LC_DRAFT_CREATE") {
      /**
       * TK đang login được phép gửi hợp đồng
       * Thời điểm gen là lúc gửi hợp đồng đi
       */
      return this.contractNumberBox?.action == 'SUBMIT' && this.allowSendContract;
    } else {
      /**
       * TK đang login được phép kí duyệt
       * Thời điểm gen là lúc ký duyệt/ký dấu
       */
      return this.contract.isOwnerParty && this.contract.isAllowedSign && this.contractNumberBox?.action == 'SIGN';
    }
  }

  /**
   * Thay đổi số hợp đồng hiển thị trên khung mô phỏng
   * @param str 
   */
  changeText(str) {
    this.contractNumberBox?.changeText(str);
  }

  /**
   *  Lấy số hợp đồng tiếp theo
   */
  getNextContractNumber(callback = null) {
    if (this.contract.sequenceId) {
      this.cncService.getNextCodeBySequenceId(this.contract.sequenceId).subscribe((res: any) => {
        this.contractNumberBox.text = res?.object || "";
        this.changeText(this.contractNumberBox?.text);

        if (callback) {
          callback();
        }
      });
    }
  }

  /**
   * Gắn số hợp đồng vào file pdf
   */
  addContractNumberIntoPDF(callback = null, error = null) {
    this.contractNumberBox?.updateSignBox();

    let boxData = new FormData();
    boxData.append('contractNumber', this.contractNumberBox?.text);
    boxData.append('multiSign', JSON.stringify(this.contractNumberBox?.getBoxValue()));
    boxData.append('contractId', this.contract.contractId);
    boxData.append('documentType', "DRAFT");

    this.cncService.addContractNumberBox(boxData).subscribe((res) => {
      const blob: any = new Blob([res]);
      this.filePDF = blob;
      PDFSign.preview(blob);
      this.getBase64String(this.filePDF, this.handle);

      if (callback) {
        callback(res);
      }
    }, err => {
      if (error) {
        error(err);
      }
    });
  }

  /**
   * submit file đã gắn số hợp đồng
   * @param contractNumber 
   * @param file 
   * @param callback 
   */
  submitFileAddedContractNumber(contractNumber, file, callback = null) {
    let data = new FormData();
    data.append('contractNumber', contractNumber);
    data.append('file', file);

    this.cncService.submitFileAddedContractNumber(data, this.contractId).subscribe((res: any) => {
      if (callback) {
        callback();
      }
    }, err => {
      console.log(err);
      this.myAlert.showErrorHandled(err);
    })
  }

  /**
   * Kiểm tra trạng thái bước thực hiện gắn số hợp đồng để xử lý
   * @param callback Hàm gọi lại sau khi đã hoàn thành việc gắn số hợp đồng
   */
  checkContractNumberAction(callback = null) {
    if (this.contractNumberBox?.action == "SUBMIT") {
      this.addContractNumberIntoPDF((res: any) => {
        const blob: any = new Blob([res]);

        this.submitFileAddedContractNumber(this.contractNumberBox?.text, blob, callback);
      })
    }
  }
  
  /**
   * Thêm khung số hợp đồng vào pdf preview
   */
  addContractNumberBox() {
    if (this.contractNumberBox?.box) {
      this.contractNumberBox?.addSignBox();
    }
  }

  /**
   * Remove chữ ký đã gắn và add lại signbox trên màn hình pdf preview
   */
  removeContractNumberAndRollBackPreview() {
    // Remove chữ ký đã gắn và add lại signbox trên màn hình pdf preview
    PDFSign.preview(this.pdfFileNoContractNumber); //Preview lại file hợp đồng chưa có contractnumber
    setTimeout(() => {
      this.addContractNumberBox();
    }, 500);
  }

  //MARK: WEBVIEW TƯƠNG TÁC VỚI PLUGIN JS

  /**
   * Đóng cửa sổ popup sau khi ký thành công
   */
   closeWindowPopup() {
    var daddy = window.self;
    daddy.opener = window.self;
    daddy.close();
  }
  
  /**
   * Gửi dữ liệu về trang nguồn mở Window Popup
   */
  sendDataBackToWindowOpener(data) {
    window?.opener?.postMessage(data, "*");
    // window.opener.VNPTeContract?.callBackEndSign(data);
  }

}