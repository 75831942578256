import { Injectable } from '@angular/core';
import { ServicePath } from 'src/app/utils/common/constant-service-api';
import { API } from '../../api';

const SERVICE_DOWNLOAD_CONTRACT_FILE = ServicePath.MANAGEMENT_SERVICE + '/contracts';
const DETAIL_CONTRACT = ServicePath.MANAGEMENT_SERVICE + '/contracts';
const GET_POSITION_PAGE_SIGNATURE = ServicePath.ESIGNATURE_SERVICE + '/esign/demo/location-sign';

@Injectable({
  providedIn: 'root'
})
export class SignAgreementService {

  constructor(
    private api: API
  ) { }


  /**
   * Tải xuống file hợp đồng
   * @param id 
   * @param type 
   */
  downloadFileContract(id, type) {
    const url = SERVICE_DOWNLOAD_CONTRACT_FILE + `/${id}/download-documents?documentType=${type}&fileKey=${id}`;
    return this.api.download(url);
  }

  getContractDetail(id) {
    const url = DETAIL_CONTRACT + `/${id}/vip`;
    return this.api.get(url);
  }

  getPositionPageSignature(id) {
    const url = GET_POSITION_PAGE_SIGNATURE + `?contractId=${id}`;
    return this.api.get(url);
  }
}
