
  <app-spinner></app-spinner>
  <div class="content-header">
    <div class="container-fluid">
      <div class="row mb-2">
        <div class="col-sm-6">
          <h1 class="m-0 text-dark"><label>Thêm mới hóa đơn</label></h1>
        </div>
        <div class="col-sm-6" >
            <button class="btn btn-primary float-sm-right d-inline-flex" (click)="saveData()"
              ><em class="material-icons">add</em>
              Xuất hóa đơn</button>
          </div>
      </div>
    </div>
  </div>

  <section class="content">
    <div class="container-fluid">
      <div class="card shadow-none mb-4">
        <div class="card-body">
          <div class="row">
            <div class="col-md-8">
              <label class="text-primary">Thông tin đơn vị bán hàng</label>
              <hr>
              <div class="row" *ngIf="infoSellerParty">
                <div class="col-md-12">
                  <div class="mb-3">
                    <label class="col-md-3">Tên đơn vị: </label>
                    <span class="col-md-9">{{infoSellerParty.tenToChuc}}</span>
                  </div>
                  <div class="mb-3">
                    <label class="col-md-3">Mã số thuế: </label>
                    <span class="col-md-9">{{infoSellerParty.maSoThue}}</span>
                  </div>
                  <div class="mb-3">
                    <label class="col-md-3">Địa chỉ: </label>
                    <span class="col-md-9">{{infoSellerParty.diaChi}}</span>
                  </div>
                </div>
              </div>
              <form [formGroup]="formCustomerInfo">
                <div class="row">
                  <div class="col-12">
                    <label class="text-primary">Thông tin đơn vị mua hàng</label>
                    <hr>
                    <div class="row">
                      <div class="col-12">
                        <div class="mb-3 form-group">
                          <div class="row ">
                            <label class="col-md-3">Tên khách hàng <span class="text-danger">*</span>: </label>
                            <div class="col-md-9" >
                              <ng-select2 formControlName="tenKhachHang" [data]="khachHangDaTa"
                              [options]="optionsTemplate" placeholder="Nhập tên khách hàng" 
                              (valueChanged)="changedKhachHang($event)">
                            </ng-select2>
                            <app-show-validate-errors [useSubmit]="false" [isSubmit]="isSubmit" [errorMessages]="validEnvoicesCusMsg.tenKhachHang"
                            [control]="formCustomerInfo.controls.tenKhachHang" [detail]="{ name: 'tenKhachHang' }">
                          </app-show-validate-errors>
                            </div>
                          </div>
                        </div>
                        <div class="mb-3 form-group">
                          <div class="row">
                            <label class="col-md-3">Tên đơn vị <span class="text-danger">*</span>: </label>
                            <div class="col-md-9">
                              <input appTrimValue type="text" formControlName="tenDonVi"
                              class="form-control hiden-border-input" placeholder="Nhập tên đơn vị" />
                              <app-show-validate-errors [useSubmit]="false" [isSubmit]="isSubmit" [errorMessages]="validEnvoicesCusMsg.tenDonVi"
                              [control]="formCustomerInfo.controls.tenDonVi" [detail]="{ name: 'tenDonVi' }">
                            </app-show-validate-errors>
                            </div>
                          </div>
                        </div>
                        <div class="mb-3 form-group">
                          <div class="row">
                            <label class="col-md-3">Địa chỉ: </label>
                            <div class="col-md-9">
                              <input appTrimValue type="text" formControlName="diaChi"
                              class="form-control hiden-border-input" placeholder="Nhập địa chỉ khách hàng" />
                            </div>
                          </div>
                        </div>
                        <div class="mb-3 form-group">
                          <div class="row">
                            <label class="col-md-3">Mã số thuế: </label>
                            <div class="col-md-9">
                              <input appTrimValue type="text" formControlName="maSoThue"
                              class="form-control hiden-border-input" placeholder="Nhập mã số thuế" />
                              <app-show-validate-errors [useSubmit]="false" [isSubmit]="isSubmit" [errorMessages]="validEnvoicesCusMsg.maSoThue"
                                [control]="formCustomerInfo.controls.maSoThue" [detail]="{ name: 'maSoThue' }">
                              </app-show-validate-errors>
                            </div>
                          </div>
                        </div>
                        <div class="mb-3 form-group">
                          <div class="row">
                            <label class="col-md-3">Mã khách hàng <span class="text-danger">*</span>: </label>
                            <div class="col-md-9">
                              <input appTrimValue type="text" formControlName="maKhachHang"
                              class="form-control hiden-border-input" placeholder="Nhập mã khách hàng" />
                              <app-show-validate-errors [useSubmit]="false" [isSubmit]="isSubmit" [errorMessages]="validEnvoicesCusMsg.maKhachHang"
                              [control]="formCustomerInfo.controls.maKhachHang" [detail]="{ name: 'maKhachHang' }">
                            </app-show-validate-errors>
                            </div>
                          </div>
                        </div>
                        <div class="mb-3 form-group">
                          <div class="row">
                            <label class="col-md-3">Điện thoại: </label>
                            <div class="col-md-9">
                              <input appTrimValue type="text" formControlName="dienThoai"
                              class="form-control hiden-border-input" placeholder="Nhập mã số điện thoại" />
                              <app-show-validate-errors [useSubmit]="false" [isSubmit]="isSubmit" [errorMessages]="validEnvoicesCusMsg.dienThoai"
                              [control]="formCustomerInfo.controls.dienThoai" [detail]="{ name: 'dienThoai' }">
                            </app-show-validate-errors>
                            </div>
                          </div>
                        </div>
                        <div class="mb-3 form-group">
                          <div class="row">
                            <label class="col-md-3">Email: </label>
                            <div class="col-md-9">
                              <input appTrimValue type="text" formControlName="email"
                              class="form-control hiden-border-input" placeholder="Nhập email khách hàng" />
                              <app-show-validate-errors [useSubmit]="false" [isSubmit]="isSubmit" [errorMessages]="validEnvoicesCusMsg.email"
                              [control]="formCustomerInfo.controls.email" [detail]="{ name: 'email' }">
                            </app-show-validate-errors>
                            </div>
                          </div>
                        </div>
                        <div class="mb-3 form-group">
                          <div class="row">
                            <label class="col-md-3">Tên ngân hàng: </label>
                            <div class="col-md-9">
                              <input appTrimValue type="text" formControlName="tenNganHang"
                              class="form-control hiden-border-input" placeholder="Nhập tên ngân hàng" />
                            </div>
                          </div>
                        </div>
                        <div class="mb-3 form-group">
                          <div class="row">
                            <label class="col-md-3">Số tài khoản: </label>
                            <div class="col-md-9">
                              <input appTrimValue type="text" formControlName="soTaiKhoan"
                              class="form-control hiden-border-input" placeholder="Nhập số tài khoản" />
                            </div>
                          </div>
                        </div>
                        <!-- <div class="mb-3 form-group">
                          <div class="row">
                            <label class="col-md-3">Loại tiền tệ: </label>
                            <div class="col-md-9">
                              <ng-select2 formControlName="loaiTienTe" [data]="loaiTienTeData"
                              [options]="options" placeholder="Chọn loại tiền tệ"
                              (valueChanged)="changedKhachHang($event)">
                            </ng-select2>
                            </div>
                          </div>
                        </div> -->
                        <div class="mb-3 form-group">
                          <div class="row">
                            <label class="col-md-3">Hình thức thanh toán: </label>
                            <div class="col-md-9">
                              <ng-select2 formControlName="hinhThucThanhToan" [data]="hinhThucThanhToanData | async"
                              [options]="options" placeholder="Chọn hình thức thanh toán"
                              (valueChanged)="changedKhachHang($event)">
                            </ng-select2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
             </form>

            </div>
            <div class="col-md-4">
              <form [formGroup]="formInvoicesInfo">
                <label class="text-primary">Thông tin hóa đơn</label>
                <hr>
                <div class="row " >
                  <div class="col-12">
                    <div class="mb-3 form-group">
                      <div class="row" *ngIf="mauSoData">
                        <label class="col-md-4">Mẫu số <span class="text-danger">*</span>:</label>
                        <div class="col-md-8">
                          <ng-select2 formControlName="mauSo" [data]="mauSoData | async"
                          [options]="options" [placeholder]="'Chọn loại mẫu số'">
                          </ng-select2>
                          <app-show-validate-errors [useSubmit]="false" [isSubmit]="isSubmit" [errorMessages]="validEnvoicesMsg.mauSo"
                          [control]="formInvoicesInfo.controls.mauSo" [detail]="{ name: 'mauSo' }">
                        </app-show-validate-errors>
                        </div>
                      </div>
                    </div>
                     <div class="mb-3 form-group">
                      <div class="row">
                        <label class="col-md-4">Ký hiệu <span class="text-danger">*</span>: </label>
                        <div class="col-md-8" >
                          <input appTrimValue type="text" formControlName="kyHieu"
                          class="form-control hiden-border-input" placeholder="Nhập ký hiệu" />
                          <app-show-validate-errors [useSubmit]="false" [isSubmit]="isSubmit" [errorMessages]="validEnvoicesMsg.kyHieu"
                          [control]="formInvoicesInfo.controls.kyHieu" [detail]="{ name: 'kyHieu' }">
                        </app-show-validate-errors>
                        </div>
                      </div>
                    </div>
                    <div class="mb-3  form-group">
                      <div class="row">
                        <label class="col-md-4">Ngày phát hành: </label>
                        <div class="col-md-8">
                          <!-- <input appTrimValue type="text" formControlName="ngayPhatHanh" disabled
                          class="form-control" placeholder="Nhập ngày phát hành" /> -->
                          <span>{{ngayPhatHanh.value }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="enable-scroll table-responsive">
                <table class="table table-bordered border mb-1">
                    <thead>
                      <tr style="background-color: rgba(213, 213, 213, 0.32);">
                        <th>STT</th>
                        <th>Tên hàng hóa, dịch vụ</th>
                        <th>Đơn vị tính</th>
                        <th>Số lượng </th>
                        <th>Đơn giá</th>
                        <th>Thành tiền </th>
                        <th>Thao tác </th>
                      </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of documents; let i = index">
                            <td >{{ i + 1 }}</td>
                            <td >
                              <input  class="hiden-border-input-table" type="text" name="donViTinh"   (input)="changeName(i,$event.target.value)"/>
                            </td >
                            <td >
                              <input   class="hiden-border-input-table" type="text" name="donViTinh" (input)="changeDonViTinh(i,$event.target.value)"/>
                            </td>
                            <td >
                              <input   class="hiden-border-input-table" type="number" name="soLuong" (input)="changeSoluong(i,$event.target.value)"/>
                            </td>
                            <td >
                              <input   class="hiden-border-input-table"  type="text" name="donGia" id="donGiaValue-{{i}}" (input)="changeDonGia(i,$event.target.value)"/>
                            </td>
                            <td >
                              <input   class="hiden-border-input-table" type="text" name="thanhTien" id ="thanhTienValue-{{i}}"(input)="changeThanhTien(i,$event.target.value)"/>
                            </td>
                            <td>
                              <button
                                type="button"
                                (click)="removeDocument(i,item)"
                                class="btn btn-link float-right"
                              >
                                <small><u>Xoá</u></small>
                              </button>
                          </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="d-flex justify-content-start">
                <a class="text-primary mt-2 float-right" (click)="addColumnToTable()" style="cursor: pointer;"><em
                        class="fas fa-plus"></em> Thêm sản phẩm dịch vụ</a>
            </div>
            </div>
          </div>
          <div class="row ">
            <div class="col-3 ml-auto">
              <form [formGroup]="formTotal">
                <!-- <div class="row"> -->
                  <div class="mb-3 form-group">
                    <div class="row">
                      <label class="col-md-5">Thuế suất GTGT (%) <span class="text-danger">*</span>: </label>
                      <div class="col-md-7" >
                        <input appTrimValue  formControlName="thueSuat" type="text" (input)=chageThueSuat($event.target.value)
                        class="form-control hiden-border-input" placeholder="Nhập thuế GTGT" />
                      </div>
                      <app-show-validate-errors [useSubmit]="false" [isSubmit]="isSubmit" [errorMessages]="validEnvoicesTotalMsg.thueSuat"
                          [control]="formTotal.controls.thueSuat" [detail]="{ name: 'thueSuat' }">
                      </app-show-validate-errors>
                    </div>
                  </div>
                  <div class="mb-3 form-group">
                    <div class="row">
                      <label class="col-md-5">Tiền thuế GTGT <span class="text-danger">*</span>:</label>
                      <div class="col-md-7">
                        <input appTrimValue type="text" formControlName="tienThue" id="tienThueValue"
                        class="form-control hiden-border-input" placeholder="Nhập tiền thuế GTGT" />
                      </div>
                    </div>
                  </div>
                  <div class="mb-3  form-group">
                    <div class="row">
                      <label class="col-md-5">Tổng tiền thanh toán <span class="text-danger">*</span>:</label>
                      <div class="col-md-7">
                        <input appTrimValue type="text" formControlName="tongTien" id="tongTienValue"
                        class="form-control hiden-border-input"  />
                      </div>
                    </div>
                  </div>
                  <div class="mb-3  form-group">
                    <div class="row">
                      <label class="col-md-5">Số tiền bằng chữ <span class="text-danger">*</span>: </label>
                      <div class="col-md-7">
                        <textarea appTrimValue type="text" formControlName="soTienChu"
                        class="form-control hiden-border-input"  ></textarea>
                      </div>
                    </div>
                  </div>
                <!-- </div> -->
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>