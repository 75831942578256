import { Injectable } from '@angular/core';
import { API } from '../../api';
import { ServicePath } from 'src/app/utils/common/constant-service-api';

const LIST_CUSTOMERS = ServicePath.MANAGEMENT_SERVICE + "/parties/customer-list";
const CUSTOMER_DETAIL = ServicePath.MANAGEMENT_SERVICE + "/parties/customer";
const LIST_ORG_INFO = ServicePath.REPORT_SERVICE + "/org-info";
const EXPORT_LIST_ORG_INFO = ServicePath.REPORT_SERVICE + "/org-info-export";

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(
    private api: API
  ) { }

  getListIndividualCustomers(keySearch = "", page = 1, maxSize = 10, sort = "DESC", propertiesSort,
  userType = "CONSUMER", fromDate = "", toDate = "", packageNum = "-1"){
    const url = LIST_CUSTOMERS + `?keySearch=${keySearch}&page=${page}&maxSize=${maxSize}&sort=${sort}&propertiesSort=${propertiesSort}&userType=${userType}&fromDate=${fromDate}&toDate=${toDate}&packageNum=${packageNum}`;
    return this.api.get(url);
  }

  getListOrganizationCustomers(keySearch = "", page = 1, maxSize = 10, sort = "DESC", propertiesSort,
  userType = "BUSINESS", fromDate = "", toDate = "", packageNum = "-1"){
    const url = LIST_CUSTOMERS + `?keySearch=${keySearch}&page=${page}&maxSize=${maxSize}&sort=${sort}&propertiesSort=${propertiesSort}&userType=${userType}&fromDate=${fromDate}&toDate=${toDate}&packageNum=${packageNum}`;
    return this.api.get(url);
  }

  getCustomerDetail(userId) {
    const url = CUSTOMER_DETAIL + `/${userId}`;
    return this.api.get(url);
  }

  getListOrgInfo(tinhId = "", fromDate = "", toDate = "",
  keySearch = "",  partyType="BUSINESS",
  page = 1, maxSize = 10, sort = "DESC", propertiesSort, status, statusUse, packageType, sortInfo=''){
    const url = LIST_ORG_INFO + 
    `?tinhId=${tinhId}&fromDate=${fromDate}&toDate=${toDate}&keySearch=${keySearch}&partyType=${partyType}`+
    `&page=${page}&maxSize=${maxSize}&sort=${sort}&propertiesSort=${propertiesSort}`+
    `&status=${status}&statusUse=${statusUse}&packageType=${packageType}&sortInfo=${sortInfo}`;
    return this.api.get(url);
  }

  exportListOrgInfo(tinhId = "", fromDate = "", toDate = "",
  keySearch = "",  partyType="BUSINESS",
   status, statusUse, packageType){
    const url = EXPORT_LIST_ORG_INFO + 
    `?tinhId=${tinhId}&fromDate=${fromDate}&toDate=${toDate}&keySearch=${keySearch}&partyType=${partyType}`+
    `&status=${status}&statusUse=${statusUse}&packageType=${packageType}`;
    return this.api.download(url);
  }

  
  // GET 'http://localhost:3000//org-info?
  // tinhId=&fromDate=&toDate=&keySearch=&partyType=BUSINESS&
  // page=1&maxSize=10&sort=DESC&propertiesSort=createdDate&
  // disabled=N&statusUse=[0,0,0,0,0,0]&packageType'
}
