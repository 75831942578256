import { Injectable } from '@angular/core';
import { ServicePath } from '../../common/constant-service-api';
import { API } from '../api';

const SERVICE_API_MENU_ENDUSER = ServicePath.MANAGEMENT_SERVICE + '/api-menus/';
const SERVICE_API_MENU_ALL = ServicePath.MANAGEMENT_SERVICE + '/api-menus/';
const SERVICE_API_DETAIL = ServicePath.MANAGEMENT_SERVICE + '/api-menus/find-by-id';
const SERVICE_API_CREATE = ServicePath.MANAGEMENT_SERVICE + '/api-menus/';
const SERVICE_API_UPDATE = ServicePath.MANAGEMENT_SERVICE + '/api-menus/';
const SERVICE_API_DELETE = ServicePath.MANAGEMENT_SERVICE + '/api-menus/';

@Injectable({
  providedIn: 'root'
})
export class ApiManagerService {

  constructor(
    private api: API
  ) { }

  /**
   * Lấy danh sách menu api cho enduser
   */
  getListAllApi(userId, page = 1, maxSize = 10, propertiesSort: string = 'created', sort: string = 'DESC', searchString = '') {
    const url = SERVICE_API_MENU_ALL + `${userId}?page=${page}&maxSize=${maxSize}&propertiesSort=${propertiesSort}&sort=${sort}&searchString=${searchString}`;
    return this.api.get(url);
  }

  /**
   * Lay danh sach api theo user id
   * @param userId 
   */
  getListApiByUser(userId) {
    const url = SERVICE_API_MENU_ENDUSER + `${userId}/findAllRoleApi`;
    return this.api.get(url);
  }

  /**
   * Lấy thông tin chi tiết api
   * @param id 
   */
  getDetailApi(id) {
    const url = SERVICE_API_DETAIL + `/${id}`;
    return this.api.get(url);
  }

  /**
   * Tao moi api
   * @param param 
   */
  postCreateApi(param) {
    const url = SERVICE_API_CREATE;
    return this.api.post(url, param);
  }

  /**
   * Cap nhat thong tin api
   * @param id 
   * @param param 
   */
  putUpdateApi(id, param) {
    const url = SERVICE_API_UPDATE + `${id}`;
    return this.api.put(url, param);
  }

  /**
   * Xoa thong tin api
   * @param id 
   */
  deleteApi(id) {
    const url = SERVICE_API_DELETE + `${id}`;
    return this.api.delete(url); 
  }
}
