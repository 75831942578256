<app-spinner></app-spinner>
<form [formGroup]="formBlogTypeGroup" (ngSubmit)="addBlogType()">
<section class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6">
        <div class="card ec-card shadow-none">
          <div class="card-header">
            <div class="d-flex">
              <div class="card-title-path flex-grow-1">
                  <h1 class="m-0 text-dark card-title"><label>Thêm mới loại tin bài</label></h1>
              </div>
              <div class="card-btn-path ml-auto text-right">
                <a [routerLink]="['/console/landing-page/blog-type']" routerLinkActive="router-link-active" class="btn btn-outline-primary d-inline-flex mr-3">
                  <span class="material-icons mr-1">close</span>
                  Hủy
                </a>              
                <button class="btn btn-primary d-inline-flex" type="submit" [disabled]="isSubmit || !formBlogTypeGroup.valid"><span
                        class="material-icons mr-1">save</span>Lưu</button>
              </div>
            </div>        
          </div>
    
          <div class="card-body">
            <div class="row">     
              <div class="col-12">
                <div class="form-group">
                  <label for="">Tên loại tin<span class="text-danger">*</span>:</label>
                  <input type="text" class="form-control" formControlName="name" placeholder="Nhập tên loại tin">
                  <app-show-validate-errors [useSubmit]="false" [isSubmit]="isSubmit" [errorMessages]="validBlogTypeMsg.name"
                    [control]="formBlogTypeGroup.controls.name" [detail]="{ name: 'name' }">
                  </app-show-validate-errors>
                </div>                                                
              </div>                                                
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
</form>
