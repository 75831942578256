import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { localize } from 'src/app/utils/localize/localize';
import { ContractObject } from 'src/app/utils/models/aas-models/contract/contract/contract-object.model';
import { SignatureItem } from 'src/app/utils/models/aas-models/contract/contract/signature-item.model';
import { SignatureService } from 'src/app/utils/services/aas-network/signature.service';
import { ModalEkycInformationComponent } from '../modal-ekyc-information/modal-ekyc-information.component';

@Component({
  selector: 'app-expanded-signature-information',
  templateUrl: './expanded-signature-information.component.html',
  styleUrls: ['./expanded-signature-information.component.scss']
})
export class ExpandedSignatureInformationComponent implements OnInit {

  @Input() show: boolean = false;
  @Input()
  set signatures(value) { this._signatures = [].concat(value); }
  get signatures() { return this._signatures; }
  _signatures: Array<SignatureItem> = [];

  @Input() 
  set contract(value) { this._contract = new ContractObject(value); }
  get contract() { return this._contract; }
  _contract: ContractObject = null;
  @Input() userType: string = "";
  @Output() checkApprovalSignStatus = new EventEmitter<string>();
  @Output() openRequestResignModalEvent = new EventEmitter<any>();
  @Output() eventReload = new EventEmitter<any>();
  
  // Ký duyệt
  approvalSign: SignatureItem = null;

  modalRef = null;
  partyIdWantEdit = "";

  constructor(
    private modalService: NgbModal,
    private signatureService: SignatureService
  ) { }

  

  ngOnInit(): void {
    this.makeSignatureInformation();
  }

  // Init signatures
  makeSignatureInformation() {
    if (this.signatures && this.signatures.length > 0) {
      const indexApprovalSign = this.signatures.findIndex(res => {
        return res.signType === "APPROVAL";
      });
      if (indexApprovalSign >= 0) {
        // Get approval sign
        this.approvalSign = this.signatures[indexApprovalSign];
        this.checkApprovalSignStatus.emit(this.approvalSign.status ?? "");
        this.signatures.splice(indexApprovalSign, 1);
        // Rest is draft sign
        this.signatures.sort((a, b) => {
          return Date.parse(a.signDate) - Date.parse(b.signDate);
        });
      }
    }
  }

  // View detail ekyc information.
  viewDetail(ekycInfor, status, contractId, signId, partyId) {
    this.partyIdWantEdit = partyId;
    this.modalRef = this.modalService.open(ModalEkycInformationComponent);
    this.modalRef.componentInstance.ekycInfo = ekycInfor ?? {};
    this.modalRef.componentInstance.status = status ?? "";
    this.modalRef.componentInstance.contractId = contractId ?? "";
    this.modalRef.componentInstance.signId = signId ?? "";
    this.modalRef.componentInstance.contract = this.contract ?? null;
    this.modalRef.componentInstance.callBackBtnRequestResign = this.openRequestResignModal.bind(this);
    this.modalRef.componentInstance.callBackReload = this.callBackReload.bind(this);
    this.modalRef.result.then((result) => {
      this.partyIdWantEdit = "";
    }, (reason) => {
      this.partyIdWantEdit = "";
    });
  }

  // Get style and label of status field.
  getStyleAndLabelStatus(status: string) {
    if (status === "VALID") {
      return ["badge-success-new", "Hợp lệ"]
    }
    else if (status === "WARNING") {
      return ["badge-warning-new", "Cảnh báo"]
    }
    return ["", ""];
  }

  // Get sign form label
  getSignFormLabel(signForm: string) {
    switch (signForm) {
      case "OTP":
        return localize.sms_otp_sign;
      case "OTP_EMAIL":
        return localize.email_otp_sign;
      case "EKYC":
        return localize.ekyc;
      case "EKYC_EMAIL":
        return localize.ekyc_email;
      case "USB_TOKEN":
        return localize.lv4_usb_token;
      case "SIGN_SERVER":
        return localize.lv4_vnpt_sign;
      case "SMART_CA":
        return localize.sign_by_smart_ca;
      case "NO_AUTHEN":
        return localize.sign_no_authen;
      default:
        return "";
    }
  }

  downloadSignImage(url) {
    this.signatureService.downloadSignatureImage(url).subscribe((res: any) => {
      if (res) {
        const blob: any = new Blob([res], {
          type: 'image/png',
        });
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        let result;
        reader.onloadend = () => {
          result = reader.result;
        };
        return result;
      }
    });
  }

  /**
   * Báo event click nút yêu cầu ký lại
   */
  openRequestResignModal() {
    this.openRequestResignModalEvent.emit(this.partyIdWantEdit);
    this.modalRef && this.modalRef.close();
  }

  /**
   * Sự kiện reload
   */
  callBackReload() {
    this.eventReload.emit(true);
  }
}
