import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PartnerService } from 'src/app/utils/services/aas-network/partner.service';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { AuthService } from 'src/app/utils/services/auth.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ConstantAlertBtn, ConstantAlertMsg } from 'src/app/utils/common/constant-alert-msg';
import { Router } from '@angular/router';
import { AccountManagerService } from 'src/app/utils/services/aas-network/account-manager.service';
import { AlertControl } from 'src/app/utils/alert/alert-control';

@Component({
  selector: 'app-trusted-partner-list',
  templateUrl: './trusted-partner-list.component.html',
  styleUrls: ['./trusted-partner-list.component.scss']
})
export class TrustedPartnerListComponent implements OnInit {

  @Input('userType') userType: string = '';

  @Output() emitConsumerTotal: EventEmitter<number> = new EventEmitter<number>();
  @Output() emitBusinessTotal: EventEmitter<number> = new EventEmitter<number>();


  partyId= this.auth.partyId;
  partners: Array<any> = [];
  page: number = 1;
  maxSize: number = 10;
  totalElement: number = 0;
  identityLevel: number = 2;
  verificationType: string = '';

  verificationTypes: Array<Object> = [
    { id: '', text: 'status_all'},
    { id: '1', text: 'TrustedPartnerManager_ts_1'},
    { id: '2', text: 'TrustedPartnerManager_ts_2'}
  ]
  
  formSearch: FormGroup;

  listConfigColTable: Array<any> = [
    {
      label: "phone_number",
      name: "sdt"
    },
    {
      label: "TrustedPartnerList_so_cmnd",
      name: "cmnd"
    },
    {
      label: "TrustedPartnerList_ngay_sinh",
      name: "ngaySinh"
    },
    // {
    //   label: "Tên tài khoản",
    //   name: "username"
    // },
    {
      label: "TrustedPartnerList_loai_dinh_danh_tai_khoan",
      name: "verificationType"
    },
  ];

  configColTable: any = {
    sdt: true,
    cmnd: true,
    ngaySinh: true,
    username: true,
    verificationType: true
  }

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private auth: AuthService,
    private aasAuth: AasAuthService,
    private partnerService: PartnerService,
    private modalService: NgbModal,
    private accApi: AccountManagerService,
    private myAlert: AlertControl,
  ) { }

  ngOnInit(): void {
    this.determineSortColConfig();
    this.makeFormSearch();
    this.loadPage(1);
  }

  makeFormSearch() {
    this.formSearch = this.fb.group({
      search: ['']
    })
  }

  get searchString() {
    return this.formSearch.value.search ?? "";
  }

  /**
   * tai trang
   * @param page 
   */
  loadPage(page) {
    this.page = page;
    this.getListTrustedPartner(this.verificationType, this.searchString, this.maxSize, this.page, this.userType, this.propertiesSort);
  }

  cleanFilter() {
    this.formSearch.reset();
    this.formSearch.controls.search.setValue("")
    this.verificationType = '';
    this.loadPage(1);
  }

  actSearch(value) {
    this.loadPage(1);
  }

  searchWithFilter() {
    this.loadPage(1);
  }

  //MARK: Action cấu hình hiển thị cột của bảng
  actConfigColTableView(e, field) {
    e.stopPropagation();
    if (this.configColTable[field] !== null && this.configColTable[field] !== undefined) {
      this.configColTable[field] = !this.configColTable[field];
    }
  }

  /**
   * Redirect den trang xem chi tiet
   * @param id 
   * @param userType 
   */
  gotoDetail(id, userType) {
    // this.router.navigate([ConstantUrl.PARTNER_TRUSTED_DETAIL, userType, id]);
    if (userType === 'CONSUMER') {
      this.router.navigate(['app/console/partner/trusted/consumer-detail', id]);
    }
    else if (userType === 'BUSINESS') {
      this.router.navigate(['app/console/partner/business-trusted-partner-detail', id]);
    }
  }

  // //MARK: Popup thông báo
  // alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = ConstantAlertBtn.OK, actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = ConstantAlertBtn.CANCEL, actBtn1 = () => { }) {
  //   const modalRef = this.modalService.open(AlertComponent);
  //   modalRef.componentInstance.message = msg;
  //   modalRef.componentInstance.typeAlert = type;
  //   modalRef.componentInstance.twoBtn = twoBtn;
  //   modalRef.componentInstance.iconBtn1 = iconBtn1;
  //   modalRef.componentInstance.iconBtn2 = iconBtn2;
  //   modalRef.componentInstance.titleButton1 = titleBtn1;
  //   modalRef.componentInstance.titleButton2 = titleBtn2;
  //   modalRef.componentInstance.callback1 = actBtn1;
  //   modalRef.componentInstance.callback2 = actBtn2;
  // }

  // //MARK: Handle Error
  // handleError(err, errorMsg) {
  //   if (err.error && err.error.message && errorMsg[err.error.message]) {
  //     this.alert(errorMsg[err.error.message], ConstantAlertType.ERROR);
  //   } else if (err.error && err.error.error) {
  //     this.alert(err.error.error, ConstantAlertType.ERROR);
  //   } else {
  //     this.alert(ConstantAlertMsg.DEFAULT_ERROR_REQUEST, ConstantAlertType.ERROR);
  //   }
  // }

  //MARK: NETWORKING
  /**
   * Lay danh sach khach hang tin cay
   * @param identityLevel 
   * @param searchString 
   * @param maxSize 
   * @param page 
   * @param userType 
   */
  getListTrustedPartner(identityLevel: any = 2, searchString = '', maxSize = 5, page = 1, userType = 'CONSUMER', sortInfo) {
    this.partnerService.getListTrustedPartner(identityLevel, searchString, maxSize, page, userType, sortInfo).subscribe((res: any) => {
      this.partners = res.object.data || [];
      
      this.totalElement = res.object.totalElement;
      if (this.userType == 'CONSUMER') {
        this.emitConsumerTotal.emit(this.totalElement);
      }
      else if (this.userType == 'BUSINESS') {

        this.emitBusinessTotal.emit(this.totalElement);
      }
    }, err => {

    });
  }
  resendInvite(id){
    this.accApi.resendInvite(id).subscribe((res:any)=>{
      console.log('resend success');
      this.myAlert.showAlertOnlyNoti(
        'Gửi lại thông báo tài khoản thành công!',
        ConstantAlertType.SUCCESS,
        '',
        'OK',
        () => {}
      );
    }, error=>{
      console.log(error);
    })
  }

  // Sort
  // sort: string = "";
  sortColConfig = null;

  determineSortColConfig() {
    if (this.userType == 'CONSUMER') {
      this.sortColConfig = {
        username: null,
        ten: null,
        email: null,
        sdt: null,
        cmnd: null,
        ngaySinh: null,
        identityLevel: null,
        refTenToChuc: null
      };
    }
    else if (this.userType == 'BUSINESS') {
      this.sortColConfig = {
        tenToChuc: null,
        maSoThue: null,
        ten: null,
        email: null,
        refTenToChuc: null,
        identityLevel: null
      };
    }
  }

  currentKey: string = "";
  propertiesSort: string = "";
  sortCol(key) {
    if (this.sortColConfig[key] == null) {
      this.sortColConfig[this.currentKey] = null;
      this.sortColConfig[key] = true;
      this.currentKey = key;
    } else if (this.sortColConfig[key] == true) {
      this.sortColConfig[key] = false;
    } else {
      this.sortColConfig[key] = null;
    }

    if (this.sortColConfig[this.currentKey] == true) {
      this.propertiesSort = `${this.currentKey}:ASC`;
      // this.sort = 'ASC';
    }
    else if (this.sortColConfig[this.currentKey] == false) {
      this.propertiesSort = `${this.currentKey}:DESC`;
      // this.sort = 'DESC';
    }
    else if (this.sortColConfig[this.currentKey] == null) {
      this.propertiesSort = "";
      // this.sort = "";
    }

    this.loadPage(this.page);
  }


  /**
   * Redirect den trang xem chi tiet
   * @param id 
   * @param userType 
   */
   gotoEdit(id, userType) {
    // this.router.navigate([ConstantUrl.PARTNER_TRUSTED_DETAIL, userType, id]);
    if (userType === 'CONSUMER') {
      this.router.navigate(['app/console/partner/trusted/edit-consumer', id]);
    }
    else if (userType === 'BUSINESS') {
      this.router.navigate(['app/console/partner/update-business-trusted-partner', id]);
    }
  }

  checkRefParty(refId): boolean {
    if (refId == this.auth.partyId) return true;
    return false;
  }
}
