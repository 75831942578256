



<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-6">
                <h1 class="m-0 text-dark"><label>{{ 'TrustedPartnerManager_title' | translate }}</label></h1>
            </div>
            <div class="col-md-auto ml-auto">
                <div class="dropdown">
                    <button class="btn btn-primary d-flex" id="dropdownMenuLink" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <span class="material-icons mr-1">add</span>{{ 'btn_addnew' | translate }}
                    </button>

                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLink">
                        <a class="dropdown-item" style="cursor: pointer;" (click)="addPartner('CONSUMER')">{{ 'AasMenuSidebar_consumerParty' | translate }}</a>
                        <a class="dropdown-item" style="cursor: pointer;" (click)="addPartner('BUSINESS')">{{ 'AasMenuSidebar_businessParty' | translate }}</a>
                    </div>
                </div>
            </div>
        </div>
        <!-- /.row -->
    </div>
    <!-- /.container-fluid -->
</div>


<section class="content">
    <div class="container-fluid">
        <div class="content-right-sidebar border-tab">
            <ul class="nav nav-tabs" id="tabSignAction" role="tablist">
                <li class="nav-item nav-material active">
                    <a class="nav-link d-flex active" id="all-tab" data-toggle="pill" href="#all" role="tab"
                        aria-controls="all" aria-selected="true">
                        {{ 'TrustedPartnerManager_tab_1' | translate }} ({{consumerTotal}})
                    </a>
                </li>
                <li class="nav-item nav-material">
                    <a class="nav-link d-flex" id="waitingForSign-tab" data-toggle="pill" href="#waitingForSign"
                        role="tab" aria-controls="waitingForSign" aria-selected="false">
                        {{ 'TrustedPartnerManager_tab_2' | translate }} ({{businessTotal}})
                    </a>
                </li>
            </ul>
        </div>
        <div class="tab-content contract-tab-body" id="tabSignAction">
            <!--MARK chữ kí số USB-Token -->
            <div class="position-relative tab-pane fade show active " id="all" role="tabpanel" aria-labelledby="all">
                <app-trusted-partner-list userType="CONSUMER" (emitConsumerTotal)="makeConsumerTotal($event)"></app-trusted-partner-list>
            </div>

            <!--MARK chữ kí sign-server  -->
            <div class="position-relative tab-pane fade" id="waitingForSign" role="tabpanel"
                aria-labelledby="waitingForSign">
                <app-trusted-partner-list userType="BUSINESS" (emitBusinessTotal)="makeBusinessTotal($event)"></app-trusted-partner-list>
            </div>
        </div>
    </div>
</section>
