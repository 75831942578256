import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-service-signup-v2',
  templateUrl: './service-signup-v2.component.html',
  styleUrls: ['./service-signup-v2.component.scss']
})
export class ServiceSignupV2Component implements OnInit {

  customerType = this.activedRoute.snapshot.queryParamMap.get('userType') || '';
  email = this.activedRoute.snapshot.queryParamMap.get('email') || '';

  stepControl: Array<any> = [
    {
      active: true,
      done: false,
      disabled: false
    },
    {
      active: true,
      done: false,
      disabled: true
    },
    {
      active: true,
      done: false,
      disabled: true
    }
  ];

  currentStep = 0;
  startStep = 0;
  endStep = 2;
  countStep = 4;

  dataForm: any = {};

  constructor(
    private router: Router,
    private activedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    if (this.customerType) {
      this.handleNext({
        userType: this.customerType
      }, 0)
    }
  }

  /**
   * Back action
   */
  backStep() {
    if (this.currentStep - 1 >= this.startStep) {
      this.currentStep--;
    }
  }

  /**
   * Next action
   */
  nextStep() {
    if (this.currentStep + 1 <= this.endStep) {
      this.currentStep++;
    }
  }

  /**
   * Handle action tiep tuc tu step
   * @param e 
   * @param step 
   */
  handleNext(e, step: number) {
    console.log(e);
    this.dataForm = e;

    this.stepControl[this.currentStep].done = true;
    console.log(this.stepControl[this.currentStep]);
    this.nextStep();
  }

  /**
   * Handle action tro lai step
   * @param e 
   * @param step 
   */
  handleBack(e, step: number) {
    this.backStep();
  }

}
