<div class="party-join-overlay">
    <div class="">
        <div class="modal-list-party rounded">
            <div class="modal-header border-0">
                <label>Thông tin các bên tham gia hợp đồng</label>
                <button type="button" class="close" aria-label="Close" (click)="close()">
                    <span aria-hidden="true"><span class="material-icons">
                            cancel
                        </span></span>
                </button>
            </div>

            <div class="modal-body text-center py-0 table-responsive enable-scroll">
                <div class="">
                    <form [formGroup]="userTable">
                        <table class="table table-bordered custom-table">
                            <thead class="bg-light">
                                <tr>
                                    <th>STT</th>
                                    <th style="min-width: 200px;">Bên tham gia</th>
                                    <th>Loại khách hàng</th>
                                    <th>Hình thức xác minh</th>
                                    <th>Hình thức ký</th>
                                    <th>Thứ tự ký</th>
                                    <!-- <th>Thời hạn ký</th> -->
                                    <th>Thao tác</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- <tr *ngFor="let item of parties; let i = index">
                                    <td>{{ i + 1 }}</td>
                                    <td>
                                        <div class="form-group mb-0">
                                            <ng-select2 [disabled]="item.isOwner" [(ngModel)]="item.partyId" [ngModelOptions]="{ standalone: true }"
                                                [data]="item.nguoiNhanData" [options]="optionsTemplate"
                                                [placeholder]="'Chọn bên tham gia'" [allowClear]="true"
                                                (valueChanged)="changePartySelect2($event, i)">
                                                <option></option>
                                            </ng-select2>
                                        </div>
                                    </td>
                                    <td>
                                        <ng-select2 [disabled]="item.isOwner || !item.allowEditUserType" [data]="userTypeData" [(ngModel)]="item.userType"
                                            [ngModelOptions]="{ standalone: true }" [options]="options"
                                            [placeholder]="'Chọn loại khách hàng'"
                                            (valueChanged)="changeUserType($event, i)">
                                        </ng-select2>
                                    </td>
                                    <td>
                                        <ng-select2 *ngIf="!item.isOwner" [disabled]="!item.allowEdit" [data]="hinhThucXacMinh" [(ngModel)]="item.verificationType"
                                            [ngModelOptions]="{ standalone: true }" [options]="options"
                                            [placeholder]="'Chọn hình thức xác minh'">
                                        </ng-select2>
                                    </td>
                                    <td>
                                        <div class="form-group mb-0">
                                            <ng-select2 *ngIf="!item.isOwner" [disabled]="!item.allowEdit" [(ngModel)]="item.signForm" [ngModelOptions]="{ standalone: true }"
                                                [options]="optionsHinhThucKy" [data]="item.hinhThucKyData"
                                                [placeholder]="'Chọn hình thức ký'">
                                            </ng-select2>
                                        </div>
                                    </td>
                                    <td>
                                        <input *ngIf="!item.isOwner" [disabled]="!item.allowEdit" class="form-control mb-0 date-input" type="text"
                                            placeholder="dd/MM/yyyy" appInputMark [options]="optionsCleave" (onValueChanged)="onChangedDate($event, i)" [(ngModel)]="item.endDate">
                                    </td>
                                    <td><a *ngIf="!item.isOwner && item.allowDetete" class="text-primary text-decoration-underline cursor-pointer">Xóa</a></td>
                                </tr> -->
                                <ng-container formArrayName="tableRows" *ngFor="let group of getFormControls.controls ; let i=index">
                                    <tr [formGroupName]="i">
                                        <td>{{ i + 1 }}</td>
                                        <td [class.bg-danger-tb]="isSubmit && fromGroup(i).controls.partyId.invalid">
                                            <div class="form-group mb-0">
                                                <ng-select2 [disabled]="group.value.isOwner || !group.value.allowEditPartyId" formControlName="partyId"
                                                    [data]="group.value.nguoiNhanData" [options]="optionsTemplate"
                                                    [placeholder]="'Chọn bên tham gia'" [allowClear]="true"
                                                    (valueChanged)="changePartySelect2Form($event, i)">
                                                    <option></option>
                                                </ng-select2>
                                            </div>
                                        </td>
                                        <td [class.bg-danger-tb]="isSubmit && fromGroup(i).controls.userType.invalid">
                                            <ng-select2 [disabled]="group.value.isOwner || !group.value.allowEditUserType" [data]="userTypeData" 
                                                formControlName="userType"
                                                [options]="options"
                                                [placeholder]="'Chọn loại khách hàng'"
                                                (valueChanged)="changeUserTypeForm($event, group)">
                                            </ng-select2>
                                            <!-- <app-show-validate-errors [useSubmit]="false"
                                                [errorMessages]="validateTable.userType"
                                                [control]="group.controls.userType"
                                                [detail]="{ name: 'userType' }"></app-show-validate-errors> -->
                                        </td>
                                        <td [class.bg-danger-tb]="isSubmit && fromGroup(i).controls.verificationType.invalid">
                                            <ng-select2 *ngIf="!group.value.isOwner" [disabled]="!group.value.allowEdit" [data]="hinhThucXacMinh" 
                                                formControlName="verificationType"
                                                [options]="options"
                                                [placeholder]="'Chọn hình thức xác minh'">
                                            </ng-select2>
                                            <!-- <app-show-validate-errors [useSubmit]="false"
                                                [errorMessages]="validateTable.verificationType"
                                                [control]="group.controls.verificationType"
                                                [detail]="{ name: 'verificationType' }"></app-show-validate-errors> -->
                                        </td>
                                        <td [class.bg-danger-tb]="isSubmit && fromGroup(i).controls.signForm.invalid">
                                            <div class="form-group mb-0">
                                                <ng-select2 *ngIf="!group.value.isOwner" [disabled]="!group.value.allowEdit"
                                                    formControlName="signForm"    
                                                    [options]="optionsHinhThucKy" 
                                                    [data]="group.value.hinhThucKyData"
                                                    [placeholder]="'Chọn hình thức ký'">
                                                </ng-select2>
                                                <!-- <app-show-validate-errors [useSubmit]="false"
                                                    [errorMessages]="validateTable.signForm"
                                                    [control]="group.controls.signForm"
                                                    [detail]="{ name: 'signForm' }"></app-show-validate-errors> -->
                                            </div>
                                        </td>
                                        <td [class.bg-danger-tb]="isSubmit && fromGroup(i).controls.sequence.invalid">
                                            <div class="form-group mb-0">
                                                <input appOnlyNumber formControlName="sequence"  
                                                    class="form-control text-right mb-0" type="text" (input)="checkValidSequence()">
                                            </div>
                                        </td>
                                        <!-- <td>
                                            <input *ngIf="!group.value.isOwner" 
                                                [attr.disabled]="!group.value.allowEdit ? '' : null" 
                                                formControlName="endDate"  
                                                class="form-control mb-0 date-input" type="text"
                                                placeholder="dd/MM/yyyy" 
                                                appInputMark [options]="optionsCleave">
                                        </td> -->
                                        <td><a *ngIf="!group.value.isOwner && group.value.allowDetete" (click)="deleteRow(i)" class="text-primary text-decoration-underline cursor-pointer">Xóa</a></td>
                                    </tr>
                                </ng-container>
                                
                            </tbody>
                        </table>
                    </form>
                </div>
            </div>

            <div *ngIf="isSubmit" class="d-flex flex-column w-100 border-top pt-3 px-3">
                <span *ngIf="!validRequired" class="w-100 text-danger">• Vui lòng nhập đầy đủ thông tin của các bên tham gia hợp đồng</span>
                <span *ngIf="!validSequence" class="w-100 text-danger">• Thứ tự ký không được trùng nhau, phải lớn hơn 0 và phải nhỏ hơn số bên tham gia hợp đồng </span>
            </div>

            <div class="d-flex w-100 modal-toolbar-table">
                <a (click)="addRowPartyJoin(); addRow()" class="cursor-pointer text-primary mr-auto mt-3">
                    <span>+ Thêm mới</span>
                </a>
            </div>

            <div class="modal-footer border-0 d-flex">
                <hr class="w-100">
                <button (click)="close()" type="button" class="btn btn-outline-primary d-inline-flex mr-3"><span
                        class="material-icons mr-2">close</span>Hủy</button>
                <!-- <button (click)="save()" type="button" class="btn btn-primary d-inline-flex"><span
                        class="material-icons mr-2">save</span>Lưu</button> -->
                <button (click)="saveForm()" type="button" class="btn btn-primary d-inline-flex"><span
                        class="material-icons mr-2">save</span>Lưu</button>
            </div>

        </div>
    </div>
</div>