<app-spinner></app-spinner>
<div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-md-auto">
                <h1 class="m-0 text-dark"><label>{{ 'ContractTypes_updating' | translate }}</label></h1>
            </div>
            <div class="col-md-auto ml-auto">
                <button class="btn btn-outline-primary d-inline-flex mr-3" (click)="cancel()"><span
                        class="material-icons mr-1">close</span>{{ 'btn_cancel' | translate }}</button>
                <button class="btn btn-primary d-inline-flex" (click)="save()"><span
                        class="material-icons mr-1">save</span>{{ 'btn_save' | translate }}</button>
            </div>
        </div>
    </div>
</div>

<section class="content">
    <div class="container-fluid">
        <div class="bg-white">
            <div class="card-body">
                        <form [formGroup]="form">
                            <div class="row">
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label>{{ 'ContractTypes_name' | translate }} <span
                                                class="text-danger">*</span>:</label>
                                        <input appTrimValue [(ngModel)]="name" class="form-control"
                                            placeholder="{{ 'ContractTypes_ph_name' | translate }}"
                                            formControlName="name">
                                        <app-show-validate-errors [isSubmit]="isSubmit"
                                            [errorMessages]="validateError.name" [control]="form.controls.name"
                                            [detail]="{ name: 'name' }"></app-show-validate-errors>
                                    </div>
                                </div>
                                <div class="col-md-3">
                                    <div class="form-group">
                                        <label>{{ 'status' | translate }} <span
                                                class="text-danger">*</span>:</label>
                                        <ng-select formControlName="status" [items]="dropListStatus"
                                            bindLabel="text" bindValue="value" [clearable]="false">
                                            <ng-template ng-label-tmp let-item="item">
                                                <span>{{ item.text | translate }}</span>
                                            </ng-template>
                                            <ng-template ng-option-tmp let-item="item">
                                                <span>{{ item.text | translate }}</span>
                                            </ng-template>
                                        </ng-select>
                                    </div>
                                </div>
                            </div>
                        </form>
                </div>
        </div>
    </div>
</section>