import { Component, OnInit, ViewChild, ElementRef, TemplateRef } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { EnterpriseManagerObject } from "src/app/utils/models/aas-models/enterprise-manager/enterprise-manager-object";
import { EnterpriseManagerResponse } from "src/app/utils/models/aas-models/enterprise-manager/enterprise-manager-response";
import { EnterpriseManagerItem } from "src/app/utils/models/aas-models/enterprise-manager/enterprise-manager-item";
import { EnterpriseManagerService } from "src/app/utils/services/aas-network/enterprise-manager.service";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { NgModalComponent } from "src/app/layout/extensions/ng-modal/ng-modal.component";
import * as moment from "moment";
import { AasConsumerManagerService } from "src/app/utils/services/aas-network/aas-consumer-manager.service";
import { AuthService } from "../../../../utils/services/auth.service";
import { Constant } from "../../../../utils/common/constant_videocall";
import { ApproveComponent } from './approve/approve.component'
import { RejectEnterpriseComponent } from './reject-enterprise/reject-enterprise.component'
import { FirebaseService } from 'src/app/utils/services/aas-network/firebase/firebase.service';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { localize } from "src/app/utils/localize/localize";

@Component({
  selector: "app-enterprise-manager",
  templateUrl: "./enterprise-manager.component.html",
  styleUrls: ["./enterprise-manager.component.scss"],
})
export class EnterpriseManagerComponent implements OnInit {
  @ViewChild('calling') public calling: TemplateRef<any>;
  @ViewChild("searchField") searchField: ElementRef;
  @ViewChild("closebutton") closebutton;
  @ViewChild("callInfo") callInfo;
  @ViewChild(ApproveComponent) approveChild: ApproveComponent;
  @ViewChild(RejectEnterpriseComponent) rejectChild: RejectEnterpriseComponent;

  default_ava_img: string = 'assets/img/services/user-incall-ava-default.png';
  ava_img: string = this.default_ava_img;

  enterprises: Array<EnterpriseManagerItem> = [];
  enterpriseResponse: EnterpriseManagerResponse;
  enterpriseDetail: EnterpriseManagerItem = new EnterpriseManagerItem();
  modalReference: NgbModalRef;

  form: FormGroup;
  idRowActive: string = "";
  validateMsgFormReject = {
    reason: {
      required: localize.form_reject_business_acc_reason_required,
      maxlength: localize.form_reject_business_acc_reason_maxlength
    }
  }

  page: number = 1;
  maxSize: number = 5;
  propertiesSort: string = "created";
  sort: string = "DESC";
  totalElement: number = 0;
  keySearch = "";
  saveKeySearch = "";
  status: string = "WAIT";
  personalInfo: any;
  isCalling: boolean = true;

  roomId: string = '';
  pendingCallModal: any = null;

  fileGPKD: Blob = null;
  showPreviewGPKD: boolean = false;

  constructor(
    private enterpriseService: EnterpriseManagerService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private aasConsumerManagerService: AasConsumerManagerService,
    private firebaseService: FirebaseService,
    private authService: AuthService,
    private aasAuth: AasAuthService
  ) { }

  ngOnInit(): void {
    this.form = this.fb.group({ reason: ["", [Validators.required, Validators.maxLength(4000)]] });
    this.getListEnterprises();

    this.handleMessagesFromVideoCall();
  }

  handleMessagesFromVideoCall() {
    this.firebaseService.currentMessage.subscribe((message) => {
      console.log('local check')
      if (message && message["data"]["title"] === "PENDING") {
        this.isCalling = true;
      } else {
        this.isCalling = false;
        this.modalReference && this.modalReference.close();
        this.pendingCallModal && this.pendingCallModal.close();
      }
    });
  }

  /**
   * Getting list document
   */
  getListEnterprises(page = 1) {
    this.enterpriseService
      .getListEnterprises(
        this.status,
        this.page,
        this.maxSize,
        this.propertiesSort,
        this.sort,
        this.keySearch
      )
      .subscribe(
        (res) => {
          // console.log(res)
          this.enterpriseResponse = new EnterpriseManagerResponse(res);
          this.enterprises = this.enterpriseResponse.object.data;
          this.page = this.enterpriseResponse.object.page;
          this.totalElement = this.enterpriseResponse.object.totalElement;
          sessionStorage.setItem("employeeListKeySearch", this.keySearch);
          this.enterprises.length > 0 &&
            this.detailEnterprise(this.enterprises[0]);
          // console.log(this.enterprises);
          // console.log(this.totalElement)
        },
        (err) => {
          if (!!err.error.error) {
            // this.alert(err.error.error[0], 'error');
            console.log(err.error.error[0]);
          }
        }
      );
  }

  /**
   * search employees
   */
  clickSearchBtn() {
    this.page = 1;
    this.saveKeySearch = `${this.saveKeySearch}`.trim();
    this.keySearch = this.saveKeySearch;
    this.getListEnterprises();
  }

  inputKeySearch(e) {
    this.saveKeySearch = e.target.value;
  }

  detailEnterprise(data) {
    // console.log(data)
    this.idRowActive = data.partyId;
    this.enterpriseService.getEnterpriseById(data.partyId).subscribe(
      (res) => {
        const jsonRes = JSON.stringify(res);
        const jsonResOb = JSON.parse(jsonRes);
        const data = jsonResOb.object;
        const dataUser = data.userInformations;

        this.enterpriseDetail.created =
          !!data.created &&
          moment(data.created, "YYYY-MM-DD HH:mm:ss").format("DD-MM-YYYY");
        this.enterpriseDetail.partyId = data.partyId;
        this.enterpriseDetail.ten = dataUser.ten;
        this.enterpriseDetail.tenToChuc = data.tenToChuc;
        this.enterpriseDetail.tenRutGon = data.tenRutGon;
        this.enterpriseDetail.maSoThue = data.maSoThue;
        this.enterpriseDetail.giayPhepKinhDoanh = data.gpkdFilename;
        this.enterpriseDetail.gpkdFilename = data.gpkdFilename;
        this.enterpriseDetail.hoTenDaiDien = dataUser.ten;
        this.enterpriseDetail.email = dataUser.email;
        this.enterpriseDetail.sdt = dataUser.sdt;
        this.enterpriseDetail.ngaySinhDaiDien = dataUser.ngaySinh || "";
        this.enterpriseDetail.gioiTinhDaiDien = dataUser.gioiTinh || "";
        this.enterpriseDetail.diachiDaiDien =
          (dataUser.soNha ? dataUser.soNha + ", " : "") +
          (dataUser.duong ? dataUser.duong + ", " : "") +
          (dataUser.xa ? dataUser.xa + ", " : "" ) +
          (dataUser.huyen ? dataUser.huyen + ", ": "") +
          (dataUser.tinh || "");
        this.enterpriseDetail.username = dataUser.username || "";
        this.enterpriseDetail.packageName = data.packageName || "";
      },
      (err) => {
        if (!!err.error.error) {
          // this.alert(err.error.error[0], 'error');
          console.log(err.error.error[0]);
        }
      }
    );
  }

  onClickCancel(cancelModalContent) {
    this.form.reset();
    this.modalReference = this.modalService.open(cancelModalContent, {
      ariaLabelledBy: "modal-basic-title",
      backdrop: "static",
    });
    this.modalReference.result.then(
      (result) => {
        console.log(result);
      },
      (reason) => {
        console.log(reason);
      }
    );
  }

  confirmCancel(reason: any) {
    const data = {
      partyId: this.enterpriseDetail.partyId,
      rejectReason: reason,
    };
    this.enterpriseService.postRejectEnterprise(data).subscribe(
      (res) => {
        this.enterpriseResponse = new EnterpriseManagerResponse(res);
        this.alert("Từ chối thành công!", "success");
        this.getListEnterprises(this.page);
        this.rejectChild.getListEnterprises();
      },
      (err) => {
        if (!!err.error.error) {
          this.alert(err.error.error[0], "error");
        }
      }
    );
    this.modalReference.close();
  }

  onClickAccept() {
    this.modal("Bạn có chắc chắn muốn phê duyệt đơn vị này?", () => {
      this.enterpriseService
        .postApproveEnterprise(this.enterpriseDetail.partyId)
        .subscribe(
          (res) => {
            this.alert("Phê duyệt thành công!", "success", () => {
              this.getListEnterprises(this.page);
              this.approveChild.getListEnterprises();
            });
          },
          (err) => {
            if (!!err.error.error) {
              this.alert(err.error.error[0], "error");
            }
          }
        );
    });
  }

  dowloadFile() {
    this.enterpriseService
      .downLoadGPKD(this.enterpriseDetail.partyId)
      .subscribe();
  }

  onClickCallVideo(partyId) {
    return;
    // alert(partyId); // .getDeviceIdByCustomerId("2c6cba04-a11e-484f-b739-36fe655b5313")//("0d1bae0f-ba28-4199-aa74-38bdaa1ef536")
    this.aasConsumerManagerService
      .getDeviceIdByCustomerId(partyId)//("eca18848-4c61-44d2-9e8e-46d2cf200829")
      .subscribe(
        (response) => {
          if (response["message"] === "IDG-00000000") {
            if (!response["object"]["DeviceIdSet"]) {
              this.alert(`Khách hàng này chưa đăng ký thiết bị!!!`, "error");
              return;
            }
            this.personalInfo = response["object"];
            this.handleCallVideo();
          }
        },
        (error) => {
          this.handleError(error, ResponseMsg.MESSAGES.VIDEO_CALL);
        }
      );
  }

  handleCallVideo() {
    const deviceIds = this.personalInfo["DeviceIdSet"].split(";");
    console.log("deviceIds", deviceIds);
    this.createCall(deviceIds);
  }

  createCall(deviceIds) {
    this.isCalling = true;
    this.aasConsumerManagerService
      .createCall(this.enterpriseDetail, deviceIds, this.authService.username)
      .subscribe(
        (response: any) => {
          console.log("created call", response);
          const jsonRes = JSON.stringify(response);
          const jsonResOb = JSON.parse(jsonRes);

          localStorage.setItem(
            Constant.ROOM_INFO,
            JSON.stringify({
              roomId: jsonResOb.object.roomId,
              token: jsonResOb.object.token,
              domain: jsonResOb.object.domain,
            })
          );

          if (response) {
            if (response.object && response.object.roomId) {
              this.roomId = response.object.roomId;
            }
          }
          this.openWaitingCallPopup();
        },
        (error) => {
          console.log(error)
          this.isCalling = false;
          this.modalReference && this.modalReference.close();
          this.handleError(error, ResponseMsg.MESSAGES.VIDEO_CALL);
        }
      );
  }

  //MARK: Modal
  // Confirm modal
  modal(msg, callBack = () => { }) {
    const modalRef = this.modalService.open(NgModalComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.callback = callBack;
  }

  /**
   * Alert message
   */
  alert(msg, type, callBack = () => { }) {
    const modalRef = this.modalService.open(NgModalComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.notification = true;
    modalRef.componentInstance.callback = callBack;
  }

  alertError(msg, callBack = () => { }) {
    const modalRef = this.modalService.open(NgModalComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = "error";
    modalRef.componentInstance.notification = true;
    modalRef.componentInstance.callback = callBack;
  }

  alertSuccess(msg, callBack = () => { }) {
    const modalRef = this.modalService.open(NgModalComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = "success";
    modalRef.componentInstance.notification = true;
    modalRef.componentInstance.callback = callBack;
  }

  //MARK: KẾT THÚC CUỘC GỌI
  /**
   * Action khi nhấn nút kết thúc cuộc gọi tại màn hình chờ
   */
  actBtnEndCall() {
    //Ẩn modal màn hình chờ
    this.pendingCallModal && this.pendingCallModal.close();

    const param = {
      "deviceId": localStorage.getItem(Constant.UUID),
      "idgTokenId": Constant.TOKEN_ID,
      "roomId": this.roomId
    }
    this.endCall(param);
  }

  //MARK: NETWORKING
  /**
   * Kết thúc cuộc gọi
   * @param data 
   */
  endCall(data) {
    this.aasConsumerManagerService.endCall(data).subscribe(res => {

    }, err => {
      this.handleError(err, ResponseMsg.MESSAGES.VIDEO_CALL);
    })
  }

  //MARK: POPUP THÔNG BÁO
  /**
   * Hiển thị modal đang gọi
   */
  openWaitingCallPopup() {
    this.pendingCallModal = this.modalService.open(this.calling, { backdrop: 'static', size: 'sm' });
    this.pendingCallModal.result.then(
      (result) => {
        // this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  actDownloadGPKD(id) {
    this.downloadGPKD(id);
  }

  handleClosePreview() {
    this.showPreviewGPKD = false;
  }

  /**
   * Tai file GPKD
   * @param partyId 
   */
  downloadGPKD(partyId) {
    this.aasAuth.downloadGPKD(partyId).subscribe((res: any) => {
      this.fileGPKD = new Blob([res], { type: 'application/pdf' });
      this.showPreviewGPKD = true;
    }, err => {

    })
  }

  /**
   * Custom modal alert
   * @param msg 
   * @param type 
   * @param twoBtn 
   * @param iconBtn2 
   * @param titleBtn2 
   * @param actBtn2 
   * @param iconBtn1 
   * @param titleBtn1 
   * @param actBtn1 
   */
  customAlert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }

  //MARK: Handle Error
  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], 'error');
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, 'error');
    } else {
      this.alert('Lỗi không xác định! Vui lòng thử lại', 'error');
    }
  }
}
