import { Injectable } from "@angular/core";
import { EconToastService } from "./econ-toast.service";
import { EconToastComponent } from "./layout/econ-toast.component";

@Injectable({
    providedIn: 'root'
})
export class EconToastControl {
    constructor(
        private econToastService: EconToastService
    ) {}

    toast(msg = "Đây là nội dung thông báo của bạn.") {
        this.econToastService.config.component = EconToastComponent;
        this.econToastService.config.msg = msg;
        this.econToastService.config.timeout = 1500;
        this.econToastService.show(msg);
    }
}