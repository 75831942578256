import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap, tap } from 'rxjs/operators';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { ControlAccount } from 'src/app/utils/common/control-account';
import { DateUtils } from 'src/app/utils/common/DateUtils';
import { REGEXP_DATE_INPUT } from 'src/app/utils/common/regexp';
import { EconToastControl } from 'src/app/utils/econ-toast/econ-toast-control';
import { localize } from 'src/app/utils/localize/localize';
import { ContractTemplateItem } from 'src/app/utils/models/contract-template/contract-template-item.model';
import { ListContractTemplateResponse } from 'src/app/utils/models/contract-template/list-contract-template-response.model';
import { EmployeeListItem } from 'src/app/utils/models/employee/employee-list-item.model';
import { EmployeeService } from 'src/app/utils/services/aas-network/employee/employee.service';
import { PartService } from 'src/app/utils/services/aas-network/part/part.service';
import { TemplateService } from 'src/app/utils/services/aas-network/template.service';
import { AuthService } from 'src/app/utils/services/auth.service';
import { LoadingService } from 'src/app/utils/services/loading.service';
import { ToastControl } from 'src/app/utils/toastr/toast-control';

@Component({
  selector: 'app-contract-template-list',
  templateUrl: './contract-template-list.component.html',
  styleUrls: ['./contract-template-list.component.scss']
})
export class ContractTemplateListComponent implements OnInit {
  @ViewChild('fromDatePicker') fromDatePicker: ElementRef;

  page = 1;
  totalElement = 0;
  maxSize = 10;
  keySearch: string = "";
  status = '';
  validateSearch = {
    createdDate: {
      pattern: "ContractTemplateList_createdDate_pattern",
      minDate: "ContractTemplateList_createdDate_minDate"
    }
  }
  formFilter: FormGroup;
  isSubmit = false;
  currentDate = new Date();
  optionsCleave = {
    date: true,
    delimiter: '/',
    datePattern: ['d', 'm', 'Y']
  };
  listConfigColTable = [
    { label: "ContractTemplateList_label_1", name: "templateName" },
    { label: "ContractTemplateList_label_2", name: "templateType" },
    { label: "ContractTemplateList_label_3", name: "id" },
    { label: "ContractTemplateList_label_4", name: "fileName" },
    { label: "ContractTemplateList_label_5", name: "fullName" },
    { label: "ContractTemplateList_label_6", name: "dateCreate" },
    { label: "ContractTemplateList_label_7", name: "status" }
  ];
  configColTable = {
    templateName: true,
    templateType: true,
    id: true,
    fileName: true,
    fullName: true,
    dateCreate: true,
    status: true
  };

  templates: ContractTemplateItem[] = [];
  employees: EmployeeListItem[] = [];
  dropListUserData: Observable<any>;
  usersLoading = false;
  usersInputs = new Subject<string>();
  sortColConfig = {
    templateName: null,
    templateType: null,
    id: null,
    fileName: null,
    fullName: null,
    dateCreate: null,
    status: null
  }

  statusList = [
    { label: "ContractTemplateList_label_8", value: "" },
    { label: "ContractTemplateList_label_9", value: "ACTIVE" },
    { label: "ContractTemplateList_label_10", value: "DEACTIVE" }
  ]

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private auth: AuthService,
    private templateService: TemplateService,
    private partService: PartService,
    private employeeService: EmployeeService,
    public controlAcc: ControlAccount,
    private myAlert: AlertControl,
    private myLoader: LoadingService,
    private myToastr: ToastControl,
    private myEconToast: EconToastControl,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.formFilter = this.fb.group({
      status: [""],
      createdDate: ["", [Validators.pattern(REGEXP_DATE_INPUT)]],
      userId: [null]
    });
    this.viewDidLoad();
  }

  viewDidLoad() {
    this.lazyLoadDropListEmployee();
    this.loadPage(1);
  }

  /**
  * Thay doi ngay tren filter
  * @param value 
  * @param type 
  */
  changeDateFilter(event) {
    this.formFilter.controls.createdDate.setValue(DateUtils.convertObjtoDate(event));
  }

  get createdDate() {
    return DateUtils.convertInputToFormat(this.formFilter.value.createdDate || "", DateUtils.format.API);
  }

  actConfigColTableView($event, key) {
    this.configColTable[key] = !this.configColTable[key];
  }

  addTemplate() {
    this.router.navigate([ConstantUrl.contract_template_add]);
  }

  loadPage(page) {
    this.page = page;
    this.getListTemplateV2(this.formFilter.value.userId || "", this.auth.partyId, this.page, this.maxSize, this.getPropertiesSort(), this.keySearch, this.formFilter.value.status, this.createdDate);
  }

  getPropertiesSort() {
    let arr = [];
    Object.keys(this.sortColConfig).forEach((item: string) => {
      let p = this.sortColConfig[item];
      let a = "";
      console.log(item);
      if (p == true) a = `${item}`;
      if (p == false) a = `-${item}`;
      if (p == null) a = "";
      if (p != null) {
        arr.push(a);
      }
    });
    return arr;
  }

  sortCol(key) {
    if (this.sortColConfig[key] == null) {
      this.sortColConfig[key] = true;
    } else if (this.sortColConfig[key] == false) {
      this.sortColConfig[key] = null;
    } else {
      this.sortColConfig[key] = !this.sortColConfig[key];
    }
    this.getListTemplateV2(this.formFilter.value.userId || "", this.auth.partyId, this.page, this.maxSize, this.getPropertiesSort(), this.keySearch, this.formFilter.value.status, this.createdDate);
  }

  /**
   * Lang nghe su kien enter trong input search
   */
  enterSearch() {
    this.loadPage(1);
  }

  /**
   * Dat lai toan bo loc
   */
  cleanFilter() {
    this.keySearch = "";
    this.isSubmit = false;
    this.formFilter.reset();
    this.formFilter.patchValue({
      status: "",
      createdDate: "",
      userId: null
    });
    this.fromDatePicker.nativeElement.value = "";
    this.loadPage(1);
  }

  /**
   * Tim kiem voi loc
   */
  searchWithFilter() {
    this.isSubmit = true;

    if (this.formFilter.controls.createdDate.valid) {
      let createdDate = this.formFilter.value.createdDate.length > 0 ? new Date(DateUtils.convertStringtoDateSearch(this.formFilter.value.createdDate)).getTime() : -1;
      if ((createdDate > 0 && createdDate > new Date().getTime())) {
        this.formFilter.controls.createdDate.setErrors({ minDate: true });
      }
    }

    if (this.formFilter.valid) {
      this.loadPage(1);
    }
  }

  changeUser($event) {
  }

  trackByFn(item: any) {
    return item.partyId;
  }

  trackByFn1(item: any) {
    return item;
  }

  gotoDetail(id: string) {
    this.router.navigate([ConstantUrl.contract_template_detail, id]);
  }

  gotoEdit(id: string) {
    this.router.navigate([ConstantUrl.contract_template_edit, id]);
  }

  deleteTemplate(item: ContractTemplateItem) {
    if (item.isUsed) {
      this.myAlert.showAlertOnlyNoti(this.translate.instant('ContractTemplateList_template_cannot_delete_isactived'), ConstantAlertType.ERROR, "", this.translate.instant('btn_close'));
      return;
    }
    this.myAlert.showAlert(this.translate.instant('ContractTemplateList_template_delete_confirm'), ConstantAlertType.BLANK, true, "", this.translate.instant('btn_confirm'), () => {
      this.deleteTemplateRequest(item.id);
    }, "", this.translate.instant('btn_cancel'));
  }

  //NETWORK
  /**
   * Get list template
   * @param partyId 
   * @param page 
   * @param maxSize 
   * @param propertiesSort 
   * @param sort 
   */
  getListTemplateV2(userId, partyId, page, maxSize, propertiesSort, keySearch, status, createdDate) {
    this.templateService.getListTemplateV2(userId, partyId, page, maxSize, propertiesSort, keySearch, status, createdDate).subscribe((res: any) => {
      console.log(res);
      let resObj: ListContractTemplateResponse = new ListContractTemplateResponse(res);
      console.log(resObj.object.data);
      this.templates = resObj.object.data || [];
      this.totalElement = resObj.object.totalElement;
    }, err => {
      console.log(err);
    })
  }

  dropListEmployeePage = 1;
  endOfDropListEmployeePage = false;
  keySearchDropListEmployee = "";
  employeesInput$ = new Subject<string>();
  lazyLoadDropListEmployee() {
    this.dropListUserData = concat(
      this.employeesInput$.pipe(
        startWith(''),
        debounceTime(400),
        distinctUntilChanged(),
        tap(() => { this.usersLoading = true; this.myLoader.disabledLoader(this.usersLoading); }),
        switchMap((term) => {
          this.keySearchDropListEmployee = term || "";
          this.dropListEmployeePage = 1;
          this.endOfDropListEmployeePage = false;
          let res = this.employeeService.getEmployeeList(this.dropListEmployeePage, 10, "createdDate", "DESC", "", "", term || "").pipe(
            map((res: any) => {
              if (res?.object?.data == null || res?.object?.data == []) this.endOfDropListEmployeePage = true;
              return res?.object?.data || [];
            }),
            catchError(() => of([])), // empty list on error
            tap(() => { this.usersLoading = false; this.myLoader.disabledLoader(this.usersLoading); })
          );
          return res;
        })
      ));
  }

  getEmployeeList(page, maxSize = 10, propertiesSort = "createdDate", sort = "DESC", status = "", partId = "") {
    this.usersLoading = true;
    this.employeeService.getEmployeeList(page, maxSize, propertiesSort, sort, status, partId, this.keySearchDropListEmployee).subscribe((res: any) => {
      this.dropListUserData.pipe(tap((data: any) => {
        return [...data, (res?.object?.data || [])];
      }))
      this.usersLoading = false;
    }, err => {
      this.usersLoading = false;
    });
  }

  /**
   * Su kien scroll xuong duoi cung cua droplist
   * @returns 
   */
  onScrollToEnd() {
    var nextPage = this.dropListEmployeePage + 1;

    if (this.endOfDropListEmployeePage) return;

    this.dropListEmployeePage = nextPage;
    this.getEmployeeList(nextPage, 10);
  }

  deleteTemplateRequest(id: string) {
    this.templateService.deleteDeactiveTemplate(id).subscribe((res: any) => {
      this.loadPage(this.page);
      this.myAlert.showAlertOnlyNoti(this.translate.instant('ContractTemplateList_remove_success'), ConstantAlertType.SUCCESS, "", this.translate.instant('btn_close'));
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }


  copyClipboard(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.myEconToast.toast(this.translate.instant('ContractTemplateList_toast_copy', {templateId: val}));
  }

}
