import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-wrong-page',
  templateUrl: './wrong-page-v2.component.html',
  styleUrls: ['./wrong-page.component.scss']
})
export class WrongPageComponent implements OnInit, AfterViewInit {

  constructor(
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private router: Router
  ) { }

  ngOnInit(): void {
    const replaceUrl = this.activatedRoute.snapshot.queryParams['url'];
    if (replaceUrl) {
        this.location.replaceState(replaceUrl);
    }
  }

  ngAfterViewInit(): void {
    var videos = document.getElementsByTagName("video");
    for (var i = 0; i < videos.length; i++) {
      videos[i].muted = true;
      videos[i].play();
    } 
  }

  goHome() {
    this.router.navigate(['/']);
  }
}
