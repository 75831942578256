export const API_FORM = {
    pathName: {
        required: 'URL không được để trống!'
    },
    method: {
        required: 'Phương thức không được để trống!'
    },
    description: {
        required: 'Mô tả không được để trống!'
    },
    roleId: {
        required: 'Chức năng không được để trống!'
    },
    privilegesId: {
        required: 'Chức năng không được để trống!'
    },
    exampleHeader: {},
    exampleBody: {
        required: 'Request body không được để trống!'
    },
    exampleOutput: {
        required: 'Response không được để trống!'
    },
    exampleParam: {
        required: 'Request Param không được để trống!'
    },
    paramError: {
        required: 'Mã lỗi không được để trống!'
    }
};

export const SIGNUP_CONSUMER = {
    email: {
        required: 'RegisterBusiness_err_9',
        email: 'RegisterBusiness_err_10',
        pattern: 'RegisterBusiness_err_10',
        unique: 'RegisterBusiness_err_11'
    },
    sdt: {
        required: 'EmployeeAdd_ts_6',
        pattern: 'RegisterBusiness_err_12',
        unique: 'RegisterBusiness_err_13'
    },
    username: {
        required: 'RegisterBusiness_err_16',
        pattern: 'RegisterBusiness_err_17',
        unique: 'RegisterBusiness_err_18'
    },
    password: {
        required: 'RegisterBusiness_err_19',
        pattern: 'RegisterBusiness_err_20'
    },
    tinhId: {
        required: 'RegisterBusiness_err_15'
    }
}

export const SIGN_ON_BUSINESS = {
    tenDoanhNghiep: {
        required: 'RegisterBusiness_err_1',
        pattern: 'RegisterBusiness_err_2'
    },
    tenVietTat: {
        required: 'RegisterBusiness_err_3',
        pattern: 'RegisterBusiness_err_4'
    },
    maSoThue: {
        required: 'RegisterBusiness_err_5',
        pattern: 'RegisterBusiness_err_6'
    },
    hoVaTen: {
        required: 'RegisterBusiness_err_7',
        pattern: 'RegisterBusiness_err_8'
    },
    email: {
        required: 'RegisterBusiness_err_9',
        email: 'RegisterBusiness_err_10',
        pattern: 'RegisterBusiness_err_10',
        unique: 'RegisterBusiness_err_11'
    },
    sdt: {
        pattern: 'RegisterBusiness_err_12',
        unique: 'RegisterBusiness_err_13'
    },
    ngaySinh: {
        pattern: 'RegisterBusiness_err_14',
    },
    gioiTinh: {},
    diaChi: {},
    tinhId: {
        requird: 'RegisterBusiness_err_15'
    },
    huyenId: {},
    xaId: {},
    soNha: {},
    duong: {},
    username: {
        required: 'RegisterBusiness_err_16',
        pattern: 'RegisterBusiness_err_17',
        unique: 'RegisterBusiness_err_18'
    },
    password: {
        required: 'RegisterBusiness_err_19',
        pattern: 'RegisterBusiness_err_20'
    },
    domain: {
        required: 'RegisterBusiness_err_21',
    }
}; 

export const REQUEST_OTP = {
    otp: {
        required: 'Vui lòng nhập mã OTP!',
        // notFound: 'Mã OTP không chính xác!'
    }
};

export const ENVOICES_CREATE = {
    ENVOICES_INFO: {
        mauSo: {
            required: 'Vui lòng chọn mẫu số!',
        },
        kyHieu: {
            required: 'Vui lòng nhập ký hiệu!',
        },
    },

    CUSTOMER_INFO: {
        tenKhachHang: {
            required: 'Vui lòng nhập tên khách hàng!',
        },
        tenDonVi: {
            required: 'Vui lòng nhập tên đơn vị!',
        },
        maKhachHang: {
            required: 'Vui lòng nhập mã khách hàng!',
        },
        maSoThue: {
            pattern: 'Mã số thuế sai định dạng!',
        },
        dienThoai: {
            pattern: 'Số điện thoại sai định dạng!',
        },
        email: {
            pattern: 'Email sai định dạng!',
        },
    },

    ENVOICES_TOTAL: {
        thueSuat: {
            required: 'Vui lòng nhập thuế suất!',
            pattern: 'Thuế suất sai định dạng!'
        }
    },
};

export const EINVOICES_CUSTOMER_CREATE = {
    companyName: {
        required: 'Vui lòng nhập tên công ty!',
    },
    taxCode: {
        required: 'Vui lòng nhập mã số thuế!',
    },
    accountInvoice: {
        required: 'Vui lòng nhập tài khoản đăng nhập!',
    },
    passwordInvoice: {
        required: 'Vui lòng nhập mật khẩu!',
    },
    url: {
        required: 'Vui lòng nhập URL service!',
    },
    serviceAccount: {
        required: 'Vui lòng nhập tài khoản service!',
    },
    servicePassword: {
        required: 'Vui lòng nhập mật khẩu service!',
    },
    pattern: {
        required: 'Vui lòng nhập mẫu hóa đơn!',
    },
    serial: {
        required: 'Vui lòng nhập series hóa đơn!',
    },
};

export const CATEGORY_PACKAFE ={
    name: {
        required: 'Vui lòng nhập tên danh mục!',
    },
    typeService: {
        required: 'Vui lòng nhập loại danh mục!',
    }
}

export const  PACKAGE_MANAGER ={
    name: {
      required: 'Vui lòng nhập tên gói cước!',
    },
    price: {
      required: 'Vui lòng nhập giá tiền!',
    },
    status: {
      required: 'Vui lòng chọn trạng thái!',
    },
    description: {
      required: 'Vui lòng nhập mô tả!',
    },
    userType: {
      required: 'Vui lòng chọn loại khách hàng áp dụng!',
    },
    contractNumber:{
      required: 'Vui lòng chọn số lượng hợp đồng!',
      pattern: 'Sai định dạng số lượng hợp đồng'
    },
    duration:{
      required: 'Vui lòng chọn thời hạn hợp đồng!',
      pattern: 'Sai định dạng số thời hạn hợp đồng'
    },
    orderNumber:{
      pattern: 'Sai định dạng số hiển thị'
    },
    userAmount:{
      pattern: 'Sai định dạng số người dùng'
    },
    capacity:{
      pattern: 'Sai định dạng số dung lượng lưu trữ'
    },
    packageType: {
        required: "Vui lòng chọn loại gói dịch vụ"
    }
  }


export const PARTNER_DENY_FORM = {
    denyReason: {
        required: 'WaitConsumerPartner_require_reject_reason',
    }
}

export const API_KEY_FILTER_FORM = {
    issueDate: {
        pattern: 'Sai định dạng ngày tháng năm!',
        minDate: 'Ngày bắt đầu không được lớn hơn ngày hết hạn và không được là ngày tương lai!'
    },
    expirationDate: {
        pattern: 'Sai định dạng ngày tháng năm!'
    }
}

export const API_KEY_SEND_MAIL_FORM = {
    email: {
        required: 'Vui lòng nhập email khách hàng!',
        pattern: 'Email không đúng định dạng!'
    }
}

export const ROW_PARTY_JOIN_CONTRACT = {
    partyId: {
        required: 'Bên tham gia không được để trống!'
    },
    userType: {
        required: 'Loại khách hàng không được để trống!'
    },
    verificationType: {
        required: 'Hình thức xác minh không được để trống!'
    },
    signForm: {
        required: 'Hình thức ký không được để trống!'
    },
    endDate: {
        required: 'Thời hạn ký không dược để trống!',
        pattern: 'Thời hạn ký không đúng định dạng ngày tháng năm!'
    },
    sequence: {
        pattern: 'Thứ tự ký phải là chữ số!'
    }
}

export const OTP_CONFIRM_SIGN = {
    otp: {
        required: 'Mã OTP không được để trống!',
        minlength: 'Mã OTP bao gồm 6 ký tự số!',
        maxlength: 'Mã OTP bao gồm 6 ký tự số!'
    }
}

export const DANHMUCSPDV = {
    productName: {
        required: 'Tên sản phẩm dịch vụ không được để trống!',
        pattern: 'Tên SPDV không bao gồm ký tự đặc biệt ngoại trừ các ký tự: .-_/\\()!&',
        unique: 'Tên sản phẩm dịch vụ đã tồn tại!'
    },
    productId: {
        required: 'Mã sản phẩn dịch vụ không được để trống!',
        pattern: 'Mã SPDV không bao gồm chữ có dấu, khoảng trống, ký tự đặc biệt ngoại trừ các ký tự: .-_/\()!&',
        unique: 'Mã sản phẩm dịch vụ đã tồn tại!'
    },
    productService: {
        required: 'Loại dịch vụ không được để trống!',
    }
}

export const DOCUMENT_MANAGER_FORM = {
    documentName: {
        required: 'Tên tài liệu không được để trống!',
        pattern: 'Tên tài liệu không bao gồm ký tự đặc biệt ngoại trừ các ký tự: .-_/\\()!&',
        unique: 'Tên tài liệu đã tồn tại!'
    },
    documentId: {
        required: 'Mã tài liệu không được để trống!',
        pattern: 'Mã tài liệu không bao gồm chữ có dấu, khoảng trống, ký tự đặc biệt ngoại trừ các ký tự: .-_/\\()!&',
        unique: 'Mã tài liệu đã tồn tại!'
    },
    documentType: {
        required: 'Loại tài liệu không được để trống!',
    },
    documentNote: {}
}
export const BLOG_TYPE_FORM = {
    CREATE: {
        name: {
            required: 'Vui lòng nhập tên loại tin bài'
        }
    },
    EDIT: {
        name: {
            required: 'Vui lòng nhập tên loại tin bài'
        }
    }
}

export const LANDING_CATEGORY_FORM = {
    CREATE: {
        categoryName: {
            required: 'Vui lòng nhập tên danh mục'
        },
        categoryCode: {
            required: 'Vui lòng nhập mã định danh'
        },
        language: {
            required: 'Vui lòng chọn ngôn ngữ'
        },
    },
    EDIT: {
        categoryName: {
            required: 'Vui lòng nhập tên danh mục'
        },
        categoryCode: {
            required: 'Vui lòng nhập mã định danh'
        },
        language: {
            required: 'Vui lòng chọn ngôn ngữ'
        },
    }
}

export const LANDING_CONFIG_FORM = {
    CREATE: {
        key: {
            required: 'Vui lòng nhập mã cấu hình'
        },
        value: {
            required: 'Vui lòng nhập giá trị cấu hình'
        }
    },
    EDIT: {
        value: {
            required: 'Vui lòng nhập giá trị cấu hình'
        }
    }
}

export const LANDING_BLOG_FORM = {
    title: {
        required: 'Vui lòng nhập tiêu đề'
    },
    subTitle: {
        required: 'Vui lòng nhập tiêu đề phụ'
    },
    description: {
        required: 'Vui lòng nhập mô tả'
    },
    avatar: {
        required: 'Vui lòng nhập link hình ảnh hoặc video'
    },
    blogTypeId: {
        required: 'Vui lòng chọn kiểu tin'
    },
    categoryId: {
        required: 'Vui lòng chọn danh mục tin'
    },
    sortOrder: {
        required: 'Vui lòng nhập số thứ tự hiển thị',
        min: 'Số thứ tự phải lớn hơn 0'
    },
    language: {
        required: 'Vui lòng chọn ngôn ngữ'
    },
}