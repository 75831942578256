import { Component, OnInit } from '@angular/core';
import { ConstantUrl } from 'src/app/utils/common/constant-url';
import { ActivatedRoute } from '@angular/router';
import { ControlAccount } from 'src/app/utils/common/control-account';
import { RoleCode } from 'src/app/utils/common/constants/roles-code';

@Component({
  selector: 'app-enduser-menu',
  templateUrl: './enduser-menu.component.html',
  styleUrls: ['./enduser-menu.component.scss']
})
export class EnduserMenuComponent implements OnInit {
  constantUrl = ConstantUrl;
  rolesContractTemplate = ['ECT2300', 'ECT2301'];
  rolesQuanlyLuong = ['ESO2005']
  rolesContractNumberConfig = ['ESO2010'];
  rolesQuanlyHopDong = ['CTLIST', 'CTLSO', 'CTLSA', 'CTVDE', 'CTDOW', 'CTSIGN', 'ESO2001', 'ESO2002', 'CMT0001', 'ESO2003', 'ESO2004'];
  rolesDanhSachTaiXuong = ['ESO2011'];
  rolesQuanlyChungHopDong = this.rolesQuanlyHopDong.concat(this.rolesQuanlyLuong).concat(this.rolesContractNumberConfig).concat(this.rolesDanhSachTaiXuong);
  rolesQuanLyChuKy = ['ECT1049', 'ECT1050', 'ECT1053', 'DSIGN', 'ESIGN'];
  rolesQuanLyDoiTac = ['CMS2001', 'ESO3002', 'ESO3003', 'ESO3004', 'ESO3005'];
  rolesTrustPartner = ['CMS2002', 'ESO3001'];
  rolesQuanlyBoPhan = ['ESO4002', 'ESO4003'];
  rolesQuanLyNhanSu = ['ESO4004', 'ESO4004'];
  rolesQuanLyNhomNguoidung = ['ESO4001'];
  rolesQuanLyToChuc = this.rolesQuanlyBoPhan.concat(this.rolesQuanLyNhanSu).concat(this.rolesQuanLyNhomNguoidung);
  rolesDichVuEnduser = ['ESO1002'];
  rolesELYCConfig = RoleCode.rolesELYCConfig;

  constructor(
    private activatedRoute: ActivatedRoute,
    public controlAccount: ControlAccount,
  ) { }

  ngOnInit(): void {
  }

  get isAccLevel2() {
    return this.controlAccount.isAccLevel2;
  }

}
