<app-spinner></app-spinner>
<section class="container-fluid px-0">
    <div class="row m-0">
        <div class="col-xl-9 col-lg-8 col-md-7">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-md-auto">
                            <h1 class="m-0 text-dark"><label>{{ 'ServiceDashboardEnduser_title' | translate }}</label>
                            </h1>
                        </div>
                    </div>
                    <!-- /.row -->
                </div>
                <!-- /.container-fluid -->
            </div>

            <section class="content">
                <div class="container-fluid">
                    <div class="col-12">
                        <app-package-noti-block [screen]="'ENDUSER_DASHBOARD'"></app-package-noti-block>
                    </div>
                    <!-- <button class="btn btn-primary" (click)="showToart()">Toast</button> -->
                    <div *ngIf="isStepEkyc && userInfo.identityLevel === 1" class="update-gpkd-block">
                        <div class="card b-radirus-8px shadow-none">
                            <div class="card-body">
                                <div class="d-flex align-items-center">
                                    <div class="desc-block flex-grow-1">
                                        <h3><label>{{ 'ServiceDashboardEnduser_greeting' | translate }} {{
                                                partyInfo.userInformations.ten || partyInfo.userInformations.username
                                                }},</label></h3>
                                        <p class="">{{ 'ServiceDashboardEnduser_checkEmailPlease' | translate }}</p>
                                        <a href="https://appstore.vnptit.vn/econtract-demo/" target="_blank">
                                            <button class="btn btn-orange d-flex align-items-center">
                                                <span class="material-icons mr-2">file_download</span> {{
                                                'ServiceDashboardEnduser_downloadApp' | translate }}
                                            </button>
                                        </a>
                                    </div>
                                    <div class="img-thumb-block d-none d-md-inline text-center py-3">
                                        <img src="assets/img/verify-account.svg" width="200">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="isStepUploadGPKD && userInfo.identityLevel === 1" class="update-gpkd-block">
                        <div class="card b-radirus-8px shadow-none">
                            <div class="card-body">
                                <div class="d-flex align-items-center">
                                    <div class="desc-block flex-grow-1">
                                        <h3><label>{{ 'ServiceDashboardEnduser_greeting' | translate }} {{
                                                partyInfo.userInformations.ten || partyInfo.userInformations.username
                                                }},</label></h3>
                                        <p class="">{{ 'ServiceDashboardEnduser_welcome1' | translate: {tenToChuc:
                                            partyInfo.tenToChuc} }}</p>
                                        <button (click)="goToUploadGPKD()"
                                            class="btn btn-orange d-flex align-items-center">
                                            <span class="material-icons mr-2">publish</span> {{
                                            'ServiceDashboardEnduser_uploadGpkd' | translate }}
                                        </button>
                                    </div>
                                    <div class="img-thumb-block d-none d-md-inline text-center py-3">
                                        <img src="assets/img/verify-account.svg" width="200">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="haveContract && userInfo.identityLevel === 1" class="contract-block">
                        <div class="card b-radirus-8px shadow-none">
                            <div class="card-body">
                                <div class="d-flex align-items-center">
                                    <div class="desc-block flex-grow-1">
                                        <h3><label>{{ 'ServiceDashboardEnduser_greeting' | translate }} {{
                                                partyInfo.userInformations.ten || partyInfo.userInformations.username
                                                }},</label></h3>
                                        <p class="">{{ 'ServiceDashboardEnduser_welcome2' | translate }}</p>
                                        <button class="btn btn-orange d-flex align-items-center"
                                            (click)="signContract(partyInfo.contractVipId)">
                                            <span class="ct-icon-font icon-pen mr-2"></span> {{
                                            'ServiceDashboardEnduser_signContract' | translate }}
                                        </button>
                                    </div>
                                    <div class="img-thumb-block d-none d-md-inline text-center py-3">
                                        <img src="assets/img/verify-account.svg" width="200">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="approvedBlock && userInfo.identityLevel === 1" class="approved-block">
                        <div class="card b-radirus-8px shadow-none">
                            <div class="card-body">
                                <div class="d-flex align-items-center">
                                    <div class="desc-block flex-grow-1">
                                        <h3><label>{{ 'ServiceDashboardEnduser_greeting' | translate }} {{ userInfo.ten
                                                || userInfo.username }},</label></h3>
                                        <p class="">{{ 'ServiceDashboardEnduser_welcome3' | translate }}</p>
                                        <!-- <button class="btn btn-orange d-flex align-items-center">
                                            <span class="ct-icon-font icon-pen mr-2"></span> Ký hợp đồng
                                        </button> -->
                                    </div>
                                    <div class="img-thumb-block d-none d-md-inline text-center py-3">
                                        <img src="assets/img/verify-account.svg" width="200">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- !isStepUploadGPKD && !haveContract && !approvedBlock &&  -->
                    <div *ngIf="waitApproveBlock && userInfo.identityLevel === 1" class="default-block">
                        <div class="card b-radirus-8px shadow-none">
                            <div class="card-body">
                                <div class="d-flex align-items-center">
                                    <div class="desc-block flex-grow-1">
                                        <h3><label>{{ 'ServiceDashboardEnduser_greeting' | translate }} {{ userInfo.ten
                                                || userInfo.username }},</label></h3>
                                        <p class="">{{ 'ServiceDashboardEnduser_welcome4' | translate }}</p>
                                        <!-- <button class="btn btn-orange d-flex align-items-center">
                                            <span class="ct-icon-font icon-pen mr-2"></span> Ký hợp đồng
                                        </button> -->
                                    </div>
                                    <div class="img-thumb-block d-none d-md-inline text-center py-3">
                                        <img src="assets/img/verify-account.svg" width="200">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-md-4" *ngIf="quantityInfo">
                            <div class="card  shadow-none">
                                <div class="card-body">
                                    <div class="d-flex justify-content-between">
                                        <div class="p-2 bd-highlight"><label>{{ 'ServiceDashboardEnduser_contractAmount'
                                                | translate }}</label></div>
                                        <div class="p-2 bd-highlight">
                                            <div class="badge badge-primary-new badge-pd"><span
                                                    class="text-primary">{{totalContract}} {{
                                                    'ServiceDashboardEnduser_contract' | translate }}</span></div>
                                        </div>
                                    </div>
                                    <canvas baseChart [labels]="barChartLabels" [datasets]="barChartData"
                                        [options]="barChartOption" [chartType]="barChartType">
                                    </canvas>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 " *ngIf="quantityInfo">
                            <div class="card  shadow-none">
                                <div class="card-body">
                                    <div class="d-flex justify-content-between">
                                        <div class="p-2 bd-highlight"><label>{{ 'ServiceDashboardEnduser_accountAmount'
                                                | translate }}</label></div>
                                        <div class="p-2 bd-highlight">
                                            <div class="badge badge-primary-new badge-pd"><span
                                                    class="text-primary">{{totalAccount}} {{
                                                    'ServiceDashboardEnduser_account' | translate }}</span></div>
                                        </div>
                                    </div>
                                    <canvas baseChart [chartType]="accountChartType" [datasets]="accountChartData"
                                        [labels]="accountChartLabels" [options]="accountChartOption">
                                    </canvas>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 " *ngIf="quantityInfo">
                            <div class="card  shadow-none">
                                <div class="card-body">
                                    <div class="d-flex justify-content-between">
                                        <div class="p-2 bd-highlight"><label>{{ 'ServiceDashboardEnduser_storage' |
                                                translate }}</label></div>
                                        <div class="p-2 bd-highlight">
                                            <div class="badge badge-primary-new badge-pd"><span
                                                    class="text-primary">{{totalData}}GB</span></div>
                                        </div>
                                    </div>
                                    <canvas baseChart [chartType]="capacityChartType" [datasets]="capacityChartData"
                                        [labels]="capacityChartLabels" [options]="capacityChartOption">
                                    </canvas>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="content" *ngIf="partyInfo.verificationStatus">
                <div class="container-fluid pb-3">
                    <div class="row">
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-4">
                                    <app-cwv-contract [type]="1"></app-cwv-contract>
                                </div>
                                <div class="col-md-4">
                                    <app-cwv-contract [type]="2"></app-cwv-contract>
                                </div>
                                <div class="col-md-4">
                                    <app-cwv-contract [type]="3"></app-cwv-contract>
                                </div>
                            </div>
                            <div>
                                <app-stats-by-time></app-stats-by-time>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <app-stats-by-status></app-stats-by-status>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-5">
                            <app-number-of-partners></app-number-of-partners>
                        </div>
                        <div class="col-md-7">
                            <app-used-capacity></app-used-capacity>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col">
                            <app-huong-dan-su-dung></app-huong-dan-su-dung>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <div class="col-xl-3 col-lg-4 col-md-5 bg-white d-none d-md-inline">
            <div class="user-info-block">
                <div class="p-3">
                    <label class="mb-3">{{ 'ServiceDashboardEnduser_accountInfor' | translate }}</label>
                    <br>
                    <div class="row mb-3">
                        <div class="col-md-6 text-md-left">
                            <span class="text-secondary">{{ 'full_name' | translate }} </span>
                        </div>
                        <div class="col-md-6 text-md-right text-overload">
                            <span class="">{{ userInfo.ten || 'ServiceDashboardEnduser_noData' | translate }}</span>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-md-6 text-md-left">
                            <span class="text-secondary">{{ 'ServiceDashboardEnduser_email' | translate }} </span>
                        </div>
                        <div class="col-md-6 text-md-right text-overload">
                            <span class="">{{ userInfo.email || 'ServiceDashboardEnduser_noData' | translate }}</span>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-md-6 text-md-left">
                            <span class="text-secondary">{{ 'ESignOtp_sms_signForm' | translate }} </span>
                        </div>
                        <div class="col-md-6 text-md-right text-overload">
                            <span class="">{{ userInfo.sdt || 'ServiceDashboardEnduser_noData' | translate }}</span>
                        </div>
                    </div>
                    <div class="row mb-3" *ngIf="isConsumer">
                        <div class="col-md-6 text-md-left">
                            <span class="text-secondary">{{ 'ServiceDashboardEnduser_accountName' | translate }} </span>
                        </div>
                        <div class="col-md-6 text-md-right text-overload">
                            <span class="">{{ userInfo.username || 'ServiceDashboardEnduser_noData' | translate
                                }}</span>
                        </div>
                    </div>
                    <div *ngIf="isCompany || isInternal" class="company-block">
                        <div class="row mb-3" *ngIf="partyInfo.tenToChuc">
                            <div class="col-md-6 text-md-left">
                                <span class="text-secondary">{{ 'ServiceDashboardEnduser_businessName' | translate }}
                                </span>
                            </div>
                            <div class="col-md-6 text-md-right text-overload">
                                <span class="">{{ partyInfo.tenToChuc || 'ServiceDashboardEnduser_noData' | translate
                                    }}</span>
                            </div>
                        </div>
                        <div class="row mb-3" *ngIf="partyInfo.tenRutGon">
                            <div class="col-md-6 text-md-left">
                                <span class="text-secondary">{{ 'ServiceDashboardEnduser_businessShorcut' | translate }}
                                </span>
                            </div>
                            <div class="col-md-6 text-md-right text-overload">
                                <span class="">{{ partyInfo.tenRutGon || 'ServiceDashboardEnduser_noData' | translate
                                    }}</span>
                            </div>
                        </div>
                        <div class="row mb-3" *ngIf="partyInfo.maSoThue">
                            <div class="col-md-6 text-md-left">
                                <span class="text-secondary">{{ 'ServiceDashboardEnduser_tax' | translate }} </span>
                            </div>
                            <div class="col-md-6 text-md-right text-overload">
                                <span class="">{{ partyInfo.maSoThue || 'ServiceDashboardEnduser_noData' | translate
                                    }}</span>
                            </div>
                        </div>
                        <div class="row mb-3" *ngIf="partyInfo.userInformations.daiDien">
                            <div class="col-md-6 text-md-left">
                                <span class="text-secondary">{{ 'RegisterBusiness_h_6' | translate }} </span>
                            </div>
                            <div class="col-md-6 text-md-right text-overload">
                                <span class="">{{ partyInfo.userInformations.daiDien || 'ServiceDashboardEnduser_noData'
                                    | translate }}</span>
                            </div>
                        </div>
                        <ng-container *ngIf="isCompany; else elsesInternal">
                            <div class="row mb-3" *ngIf="partyInfo.email">
                                <div class="col-md-6 text-md-left">
                                    <span class="text-secondary">{{ 'ServiceDashboardEnduser_email' | translate }}
                                    </span>
                                </div>
                                <div class="col-md-6 text-md-right text-overload">
                                    <span class="">{{ partyInfo.email || 'ServiceDashboardEnduser_noData' | translate
                                        }}</span>
                                </div>
                            </div>
                        </ng-container>
                        <ng-template #elsesInternal>
                            <div class="row mb-3" *ngIf="partyInfo.userInformations.email">
                                <div class="col-md-6 text-md-left">
                                    <span class="text-secondary">{{ 'ServiceDashboardEnduser_email' | translate }}
                                    </span>
                                </div>
                                <div class="col-md-6 text-md-right text-overload">
                                    <span class="">{{ partyInfo.userInformations.email || 'ServiceDashboardEnduser_noData' | translate
                                        }}</span>
                                </div>
                            </div>
                        </ng-template>


                        <div class="row mb-3" *ngIf="partyInfo.userInformations.username">
                            <div class="col-md-6 text-md-left">
                                <span class="text-secondary">{{ 'ServiceDashboardEnduser_accountName' | translate }}
                                </span>
                            </div>
                            <div class="col-md-6 text-md-right text-overload">
                                <span class="">{{ partyInfo.userInformations.username ||
                                    'ServiceDashboardEnduser_noData' | translate }}</span>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="row mb-3">
                        <div class="col-md-6 text-md-left">
                            <span class="text-secondary">{{ 'ServiceDashboardEnduser_accountName' | translate }} </span>
                        </div>
                        <div class="col-md-6 text-md-right text-overload">
                            <span class="">{{ userInfo.username || 'ServiceDashboardEnduser_noData' | translate }}</span>
                        </div>
                    </div> -->

                    <hr>

                    <div class="row">
                        <div class="col-md-auto">
                            <label class="mb-3">Danh sách hợp đồng gửi chờ ký</label>
                        </div>
                        <div class="col-md-auto ml-auto">
                            <a class="text-primary text-decoration-underline"
                                routerLink="/app/console/contract-manager">Chi tiết</a>
                        </div>
                    </div>

                    <div class="contract-item p-3 mb-2" *ngFor="let item of listContract">
                        <label class="text-primary contract-title">{{item.title}}</label>
                        <div class="row">
                            <div class="col-md-8">
                                <div class="row">
                                    <div class="col-md-6">
                                        <label class="font-label">Bên tham gia</label>
                                    </div>
                                    <div class="col-md-6">
                                        <span class="font-label">{{item.partners.length}}</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label class="font-label">Ngày chỉnh sửa</label>
                                    </div>
                                    <div class="col-md-6">
                                        <span class="font-label">{{item.modified | date: "dd/MM/yyyy"}}</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <label class="font-label">Trạng thái</label>
                                    </div>
                                    <div class="col-md-6">
                                        <span class="text-warning font-label">{{
                                            (currentStage[item.currentStage].label | translate) || '' }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4" style="text-align: right;">
                                <button class="btn btn-primary d-inline-flex font-button"
                                    (click)="goToSign(item.contractId)">Ký hợp đồng</button>
                            </div>
                        </div>
                    </div>

                </div>

            </div>


        </div>
    </div>
</section>