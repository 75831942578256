import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { ContractNumberConfigService } from 'src/app/utils/services/aas-network/contract-number-config/contract-number-config.service';

@Component({
  selector: 'app-detail-contract-number-config',
  templateUrl: './detail-contract-number-config.component.html',
  styleUrls: ['./detail-contract-number-config.component.scss']
})
export class DetailContractNumberConfigComponent implements OnInit {

  id = this.activatedRoute.snapshot.paramMap.get('id') || null;

  // Option trạng thái
  disable: boolean = false;
  disableOptions: Array<object> = [
    { value: true, label: "status_stopped" },
    { value: false, label: "status_running" }
  ];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private cncService: ContractNumberConfigService,
    private translate: TranslateService,
    private alert: AlertControl
  ) { }

  // Option thời điểm phát sinh số hợp đồng
  action: string = '';
  actionOptions: Array<object> = [
    { value: 'CREATE', label: "ListContractNumberConfig_filter_2" },
    { value: 'SUBMIT', label: "ListContractNumberConfig_filter_3" },
    { value: 'SIGN', label: "ListContractNumberConfig_filter_4" },
    { value: '', label: "" }
  ];

  // Table cấu trúc thành phần
  sequenceLine: Array<any> = null;

  // Mô phỏng cấu hình
  cncSimulator: string = null;

  // Option thành phần
  ingredientOptions: Array<object> = [
    { value: 'NUMBER', label: 'AddContractNumberConfig_ingredient_1' },
    { value: 'VARIABLE', label: 'AddContractNumberConfig_ingredient_2' },
    { value: 'STRING', label: 'AddContractNumberConfig_ingredient_3' }
  ];

  // Option dấu ngăn cách
  separatorOptions: Array<object> = [
    { value: 'SLASH', label: '/' },
    { value: 'DASH', label: '_' },
    { value: 'SPACE', label: 'AddContractNumberConfig_separator_space' }
  ];

  // Option reset type
  resetTypeOptions: Array<object> = [
    { value: 'MONTH', label: "AddContractNumberConfig_auto_reset_month" },
    { value: 'YEAR', label: "AddContractNumberConfig_auto_reset_year" },
    { value: '', label: "AddContractNumberConfig_no_auto_reset" },
  ];

  // Option cấu hình (Thành phần Số thứ tự)
  paddingOptions: Array<object> = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    { value: 8, label: "8" },
    { value: 9, label: "9" }
  ];

  // Option Biến (VARIABLE)
  variableOptions: Array<object> = [
    { value: 'ROOTPATYNAME', label: 'AddContractNumberConfig_variable_1' },
    { value: 'PARTYNAME', label: 'AddContractNumberConfig_variable_2' },
    { value: 'YEAR', label: 'AddContractNumberConfig_variable_3' },
    { value: 'MONTH', label: 'AddContractNumberConfig_variable_4' },
  ];

  configDefault = false;

  ngOnInit(): void {
    this.getCncDetails();
  }

  /**
   * Lấy thông tin cấu hình số HĐ
   */
  getCncDetails() {
    this.cncService.getDetails(this.id).subscribe((res: any) => {
      const cncDetails = res.object ?? null;
      if (cncDetails != null) {
        this.action = cncDetails.action ?? '';
        this.sequenceLine = cncDetails.sequenceLine ?? [];
        this.cncSimulator = cncDetails.name ?? '';

        this.disable = cncDetails.disable ?? false;
        this.configDefault = cncDetails.default ?? false;
      }
    }, err => {

    });
  }

  /**
   * Chuyển đến màn chỉnh sửa
   */
  directToEdit() {
    if (typeof (this.id) == 'string') {
      this.router.navigate([`app/console/configure-contract-number/edit`, this.id]);
    }
  }

  /**
   * Set Cấu hình số hợp đồng hiện tại làm mặc định
   */
  setDefault() {
    // this.alert.showAlert(this.translate.instant('ContractNumberDetail_setDefault_confirm'), ConstantAlertType.BLANK, true, "",
    // this.translate.instant('btn_confirm'), () => {
    //     const data = {
    //       disable: this.disable,
    //       default: true,
    //       action: this.action,
    //       sequenceLine: this.sequenceLine
    //     };
    //     this.cncService.updateContractNumberConfig(this.id, data).subscribe((res: any) => {
    //       this.getCncDetails();
    //     }, err => {
    //       this.alert.showErrorHandled(err);
    //     });
    //   }, "", this.translate.instant('btn_close'), () => { }, this.translate.instant('btn_confirm'))
    if (this.id) {
      this.alert.showAlert(this.translate.instant('ContractNumberDetail_setDefault_confirm'), ConstantAlertType.BLANK, true,
        "", this.translate.instant('SignServerManager_label_14'), () => {

          this.cncService.setConfigToDefault(this.id).subscribe((res: any) => {
            this.getCncDetails();
          }, err => {
            this.alert.showErrorHandled(err);
          });

        }, "", this.translate.instant('btn_close'), null, this.translate.instant('btn_confirm'));
    }
  }


}