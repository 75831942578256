import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DualListComponent } from 'src/app/layout/extensions/dual-list/dual-list.component';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { localize } from 'src/app/utils/localize/localize';
import { PackageDetail } from 'src/app/utils/models/aas-models/package-manager/package-detail';
import { InfraConfig } from 'src/app/utils/models/aas-models/service-catalog/infra-config';
import { ServiceCatalog } from 'src/app/utils/models/aas-models/service-catalog/service-catalog';
import { PackageServiceService } from 'src/app/utils/services/package-service/package-service.service';

@Component({
  selector: 'app-customize-infra-config',
  templateUrl: './customize-infra-config.component.html',
  styleUrls: ['./customize-infra-config.component.scss']
})
export class CustomizeInfraConfigComponent implements OnInit {
  @ViewChild('duallist') duallist: DualListComponent;
  
  @Input()
  set packageInfo(value) { this._packageInfo = new PackageDetail(value); } 
  get packageInfo() { return this._packageInfo; }
  _packageInfo: PackageDetail = new PackageDetail();

  @Input()
  set dropListPackageData(value) { this._dropListPackageData = value; } 
  get dropListPackageData() { return this._dropListPackageData; }
  _dropListPackageData: any[] = [];
  

  @Output('backEvent') backEvent: EventEmitter<any> = new EventEmitter();
  @Output('savedData') savedData: EventEmitter<any> = new EventEmitter();

  localize = localize;
  constPackageType = {
    TRIAL_TYPE: "Gói dùng thử",
    NORMAL_TYPE: "Gói chính thức",
    EXTEND_TYPE: "Gói mở rộng"
  };
  constCustomerType = {
    CONSUMER: "Khách hàng cá nhân",
    BUSINESS: "Khách hàng tổ chức",
  };
  totalPrice = "";
  items_dualist = [];
  items_dualist_assigned = [];
  packInfoId = null;

  infraConfigs: InfraConfig[] = [];
  
  constructor(
    private myAlert: AlertControl,
    private packageApi: PackageServiceService
  ) { }

  ngOnInit(): void {
    this.progressDataPackageDetail();
    this.getRolesInCatalogs();

    console.log(this.dropListPackageData);
  }

  /**
   * Xử lý dữ liệu chi tiết gói dịch vụ
   */
  progressDataPackageDetail() {
    this.packInfoId = this.packageInfo.packageInfo.packInfoId || null;
    //Gan du lieu ha tang
    if (this.packageInfo.listPackageInfrastructure.infrastructures.length > 0) {
      this.infraConfigs = Array.from(this.packageInfo.listPackageInfrastructure.infrastructures);
      this.infraConfigs = this.infraConfigs.sort((a, b) => { return a.stt - b.stt; })
    }
    this.getTotalMoney();
  }

  /**
   * Xử lý dữ liẹu dualList phân quyền
   */
  progressDataDualList() {
    //Set data danh sach danh muc chuc nang da chon
    this.items_dualist_assigned = [];
    this.packageInfo.listPackageService.forEach((item) => {
      let param = {
        id: item.serviceInfoId || "",
        name: item.name || "",
        additions: {
          ...item
        },
        child: []
      };

      item.privileges.forEach((x, index) => {
        param.child.push({
          id: x.name || "",
          name: x.description || "",
        })
      });

      this.items_dualist_assigned.push(param);
    });
    this.duallist.setupDualList(this.items_dualist_assigned);
  }

  /**
   * Trở lại màn hình trước sự kiện
   */
  goToBack() {
    this.backEvent.emit(true);
  }

  /**
   * Lấy mô tả trạng thái gói
   * @param status 
   * @returns 
   */
  getStatusSpan(status) {
    let className = "";
    let label = "";
    
    switch(status) {
      case "ENABLED":
        className = "badge-success-new";
        label = "Đang hoạt động";
        break;
      case "DISABLED":
        className = "badge-danger-new";
        label = "Ngưng hoạt động";
        break;
    };

    if (className != "" && label != "") {
      let span = `<span class="badge ${className}">${label}</span>`;
      return span;
    } else {
      return "";
    }
  }

  //Tinh tong tien
  getTotalMoney() {
    console.log("getTotalMoney");
    var sum = '0';
    this.packageInfo.listPackageInfrastructure.infrastructures.forEach((item) => {
      if (!isNaN(parseInt(item.price)) && !isNaN(parseInt(sum))) {
        sum = `${Number(sum) + Number(item.price)}`;
      }
    });

    this.totalPrice = sum;
  }

  //Danh sach quyen theo danh muc
  getRolesInCatalogs() {
    this.packageApi.getRolesInCatalogs().subscribe((res: any) => {
      let arrCatalog: ServiceCatalog[] = [];
      if (res.object && res.object instanceof Array) {
        res.object.forEach((item: any) => {
          arrCatalog.push(new ServiceCatalog(item));
        })

        //chuan bi du lieu cho duallist
        arrCatalog.forEach((item, index) => {
          let param = {
            id: item.serviceInfoId || "",
            name: item.name || "",
            additions: {
              ...item
            },
            child: []
          };

          item.privileges.forEach((x, index) => {
            param.child.push({
              id: x.name || "",
              name: x.description || "",
            })
          });

          this.items_dualist.push(param);
        });

        this.duallist.setBaseList();
        this.duallist.setupDualList([]);

        this.progressDataDualList();
      }
    }, err => {
      this.myAlert.showErrorHandled(err);
    })
  }

  /**
   * Lấy thông tin gói dịch vụ
   * @param packageId 
   */
   getPackage(packageId){
    this.packageApi.getDetailPackage(packageId).subscribe((res: any)=>{
      this.packageInfo = new PackageDetail(res?.object);
      console.log(this.packageInfo);
      //Gan lai du lieu cu vao form
      // let group = {};
      // console.log(this.packageItem.listPackageInfrastructure);
      // this.packageItem.listPackageInfrastructure.forEach((item) => {
      //   group[item.serviceInfoId] = new FormControl(item.value, Validators.required)
      //   this.validateMsgInfra[item.serviceInfoId] = {
      //     required: "Vui lòng nhập thông tin " + item.name + "!"
      //   }
      // });
      // this.formInfra = this.fb.group(group);
    }, err => {
      console.log(err);
      this.myAlert.showErrorHandled(err);
    }, () => {
    });
  }

  /**
   * Thay đổi gói dịch vụ
   * @param e 
   */
  changedPackage(e) {
    console.log(e);
    if (e) {
      this.getPackage(e?.packInfoId);
    } else {
      this.packageInfo = null;
    }
  }

  //Xu ly tinh tien
  progressPrice(item: InfraConfig) {
    console.log("progressPrice");

    if (item.param == 'STORAGE_CAPACITY' || item.param == 'STORAGE_TIME') {
      let storeItem: InfraConfig = this.infraConfigs.find((x) => { return x.param == 'STORAGE_CAPACITY'; });
      let timeItem: InfraConfig = this.infraConfigs.find((x) => { return x.param == 'STORAGE_TIME'; });
      let price = "0";
      if (!isNaN(parseInt(storeItem.unitNumber)) && !isNaN(parseInt(storeItem.unitPrice)) && !isNaN(parseInt(timeItem.unitNumber))) {
        price = `${Number(storeItem.unitNumber) * Number(storeItem.unitPrice) * Number(timeItem.unitNumber)}`;
      } else {
        price = "0";
      }

      storeItem.price = price;
    } else {
      item.getPrice();
    }
  }

  /**
   * Nhấn nút lưu dữ liệu
   */
  btnSaveInfra() {
    let param: PackageDetail = new PackageDetail(this.packageInfo);
    param.listPackageInfrastructure.infrastructures = [].concat(this.infraConfigs);
    param.totalPrice = this.totalPrice;

    this.savedData.emit(param);
    this.backEvent.emit(true);
  }
}
