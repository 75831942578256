import { Component, OnInit, AfterViewInit, Renderer2, ViewChild, ElementRef, Input } from '@angular/core';
import { BusinessPackageItem } from 'src/app/utils/models/aas-models/business-package-manager/business-package-list/business-package-item.model'
import { ListBusinessPackageResponse } from 'src/app/utils/models/aas-models/business-package-manager/business-package-list/list-business-package-response.model'
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ContractStatus } from 'src/app/utils/common/constant';
import { DateUtils } from 'src/app/utils/common/DateUtils';
import { AasValidateMessage } from 'src/app/utils/common/validate-msg-aas';
import { AlertComponent } from 'src/app/layout/extensions/alert/alert.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponseMsg } from 'src/app/utils/common/response-msg-aas';
import { AuthService } from 'src/app/utils/services/auth.service';
import { AasAuthService } from 'src/app/utils/services/aas-network/aas-auth.service';
import { Router } from '@angular/router';
import { NgModalComponent } from 'src/app/layout/extensions/ng-modal/ng-modal.component';
import { BusinessPackageService } from 'src/app/utils/services/aas-network/business-package.service'
import {
  map,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  tap
} from "rxjs/operators";
import { localize } from 'src/app/utils/localize/localize';
import { ConstantAlertType } from 'src/app/utils/common/constant-alert-type';
import { AlertControl } from 'src/app/utils/alert/alert-control';
import { UtilsFunc } from 'src/app/utils/common/utils-func';
import { ConstantAlertBtn } from 'src/app/utils/common/constant-alert-msg';

@Component({
  selector: 'app-service-package-list',
  templateUrl: './service-package-list.component.html',
  styleUrls: ['./service-package-list.component.scss']
})
export class ServicePackageListComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private renderer2: Renderer2,
    private router: Router,
    private auth: AuthService,
    private aasAuth: AasAuthService,
    private packageService: BusinessPackageService,
    private myAlert: AlertControl
  ) { }

  listPackages: Array<any> = [];
  page: number = 1;
  maxSize: number = 10;
  // propertiesSort (không khai báo)
  searchString: string = "";
  sort: string = "DESC";
  totalElement: number = 0;
  formFilter: FormGroup;
  sortColConfig = {
    name: null,
    description: null,
    userType: null,
    packageType: null,
    status: null
  }

  makeFormFilter() {
    this.formFilter = this.fb.group({
      userType: new FormControl(''),
      packageType: new FormControl(''),
      status: new FormControl('')
    });
  }

  get userType() { return this.formFilter.value.userType; }
  get packageType() { return this.formFilter.value.packageType; }
  get status() { return this.formFilter.value.status; }

  ngOnInit() {
    this.makeFormFilter();
    this.getListPackages();
  }

  getListPackages() {
    this.packageService.getListPackageV2(this.page, this.maxSize, "", this.packageType, this.status, this.userType, this.sort, this.searchString).subscribe((res: any) => {
      this.listPackages = res.object.data;
      this.totalElement = res.object?.totalElement || 0;
    }, err => {
    
    }
    );
  }

  getUserTypeLabel(userType) {
    if (userType === "CONSUMER") {
      return "Khách hàng cá nhân";
    } else if (userType === "BUSINESS") {
      return "Khách hàng tổ chức";
    } else if (userType === "ENTERPRISE") {
      return "Khách hàng tích hợp";
    }
    return "";
  }

  getPackageTypeLabel(packageType) {
    if (packageType === "TRIAL_TYPE") {
      return "Gói thử nghiệm";
    } else if (packageType === "NORMAL_TYPE") {
      return "Gói chính thức";
    } else if (packageType === "EXTEND_TYPE") {
      return "Gói hạ tầng mở rộng";
    }
    return "";
  }

  getStatusLabelAndStyle(status) {
    if (status === "USING" || status === "ENABLED") {
      return ['badge-success-new', 'Đang hoạt động'];
    }
    return ['badge-danger-new', 'Ngưng hoạt động'];
  }

  getCurrentStatus(status) {
    if (status === "USING" || status === "ENABLED") {
      return true;
    }
    return false;
  }

  changeUserType(event) {
    this.formFilter.controls.userType.setValue(event.target.value);
  }

  changePackageType(event) {
    this.formFilter.controls.packageType.setValue(event.target.value);
  }

  changeStatus(event) {
    this.formFilter.controls.status.setValue(event.target.value);
  }

  searchWithFilter() {
    this.reloadListPackages();
  }

  reloadListPackages() {
    this.page = 1;
    this.getListPackages();
  }

  cleanFilter() {
    this.formFilter.controls.userType.setValue("");
    this.formFilter.controls.packageType.setValue("");
    this.formFilter.controls.status.setValue("");
    this.searchString = "";
    this.reloadListPackages();
  }

  sortCol(key) {
    if (this.sortColConfig[key] == null) {
      this.sortColConfig[key] = true;
    } else if (this.sortColConfig[key] == false) {
      this.sortColConfig[key] = null;
    } else {
      this.sortColConfig[key] = !this.sortColConfig[key];
    }
    this.reloadListPackages();
  }

  modal(msg, callBack = () => {}) {
    const modalRef = this.modalService.open(NgModalComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.callback = callBack;
  }

  alert(msg, type, twoBtn = false, iconBtn2 = '', titleBtn2 = 'OK', actBtn2 = () => { }, iconBtn1 = '', titleBtn1 = 'Hủy', actBtn1 = () => { }) {
    const modalRef = this.modalService.open(AlertComponent);
    modalRef.componentInstance.message = msg;
    modalRef.componentInstance.typeAlert = type;
    modalRef.componentInstance.twoBtn = twoBtn;
    modalRef.componentInstance.iconBtn1 = iconBtn1;
    modalRef.componentInstance.iconBtn2 = iconBtn2;
    modalRef.componentInstance.titleButton1 = titleBtn1;
    modalRef.componentInstance.titleButton2 = titleBtn2;
    modalRef.componentInstance.callback1 = actBtn1;
    modalRef.componentInstance.callback2 = actBtn2;
  }



  putStatus(packInfoId, newStatus){
    let msg = 'Bạn chắc chắn muốn thay đổi trạng thái gói dịch vụ này?';
    this.myAlert.showAlert(msg, ConstantAlertType.BLANK, true, '', "Đổi trạng thái", () => {
      this.packageService.getPackageV2(packInfoId).subscribe((res: any) => {
        let object = res.object;
        object.packageInfo.status = newStatus;
        this.packageService.putUpdatePackageV2(object, packInfoId).subscribe(res => {
          this.reloadListPackages();
        });
      }, err => {
        this.handleError(err, ResponseMsg.MESSAGES.ALL_IN_ONE)
      });
    }, '', ConstantAlertBtn.CLOSE, () => {}, "Xác nhận");
  }

  removePackage(packInfoId, status) {
    if (status == "ENABLED" || status == "USING") {
      let msg_error = "Bạn không thể xóa gói dịch vụ đã được sử dụng. Vui lòng kiểm tra lại";
      this.myAlert.showAlert(msg_error, ConstantAlertType.BLANK, false, "", ConstantAlertBtn.CLOSE, () => { });
    } else {
      let msg = "Bạn chắc chắn muốn xóa gói dịch vụ này?";
      let msg_confirm = "Gói dịch vụ đã được xóa khỏi hệ thống!";
      this.myAlert.showAlert(msg, ConstantAlertType.BLANK, true, '', ConstantAlertBtn.REMOVE, () => {
        this.packageService.deletePackageV2(packInfoId).subscribe(res => {
          this.myAlert.showAlert(msg_confirm, ConstantAlertType.BLANK, false, "", ConstantAlertBtn.CLOSE, () => {
            this.reloadListPackages();
          });
        }, err => {
          this.myAlert.showErrorHandled(err);
        });
      }, '', ConstantAlertBtn.CLOSE, () => {}, "Xác nhận");
    }
  }

  handleError(err, errorMsg) {
    if (err.error && err.error.message && errorMsg[err.error.message]) {
      this.alert(errorMsg[err.error.message], 'error');
    } else if (err.error && err.error.error) {
      this.alert(err.error.error, 'error');
    } else {
      this.alert('Lỗi không xác định!', 'error');
    }
  }

}
